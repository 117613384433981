import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 16c0 7.18-5.82 13-13 13S3 23.18 3 16 8.82 3 16 3s13 5.82 13 13zM9.137 17.502a1 1 0 0 0-1.734.997A2.99 2.99 0 0 0 10 20a2.99 2.99 0 0 0 2.597-1.502 1 1 0 0 0-1.734-.997A.99.99 0 0 1 10 18a.99.99 0 0 1-.863-.498zm14.139 6.248a1 1 0 1 0-1.322-1.5c-1.63 1.455-3.612 2.269-5.954 2.25-2.291.018-4.233-.761-5.85-2.16a1 1 0 1 0-1.3 1.52c1.883 1.591 4.481 2.622 7.15 2.64 2.727-.019 5.377-1.095 7.276-2.75zm-3.505-6.617a1 1 0 0 1 1.366.368A.99.99 0 0 0 22 18a.99.99 0 0 0 .863-.498 1 1 0 0 1 1.734.997A2.99 2.99 0 0 1 22 20a2.99 2.99 0 0 1-2.597-1.502 1 1 0 0 1 .369-1.365z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
