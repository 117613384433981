import React from 'react';
import { Resources } from '@matterapp/routing';
import {
  UserSettingsNotificationsPage
} from '../pages/user-settings';
import UserSettingsMainPage from 'routes/pages/user-settings/components/UserSettingsMainPage';
import UserSettingsCelebrationsComponent from 'routes/pages/user-settings/components/UserSettingsCelebrations';
import UserSettingsTeamsNotificationsComponent from 'routes/pages/user-settings/components/UserSettingsTeamsNotifications';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import { withPageViewTracking } from '../../hocs';
import { Route } from 'react-router-dom';

const userSettingsMainHoc = withPageViewTracking({
  pageTitle: 'user-settings/main',
});

const UserSettingsMainComponent = userSettingsMainHoc(UserSettingsMainPage);

export const UserSettingsMain = (
  <Route
    exact
    path={Resources.userSettingsMain.pattern}
    element={(
      <PrivateRoute> 
        <UserSettingsMainComponent />
      </PrivateRoute>
    )}
  />
);

const userSettingsNotificationsHoc = withPageViewTracking({
  pageTitle: 'user-settings/notifications',
});
const UserSettingsNotificationsPageComponent = userSettingsNotificationsHoc(UserSettingsNotificationsPage);

export const UserSettingsNotifications = (
  <Route
    exact
    path={Resources.userSettingsNotifications.pattern}
    element={(
      <PrivateRoute> 
        <UserSettingsNotificationsPageComponent />
      </PrivateRoute>
    )}
  />
);

export const UserSettingsCelebrations = (
  <Route
    exact
    path={Resources.userCelebrationsSettings.pattern}
    element={(
      <PrivateRoute> 
        <UserSettingsCelebrationsComponent />
      </PrivateRoute>
    )}
  />
);

export const UserSettingsTeamsNotifications = (
  <Route
    exact
    path={Resources.userTeamsNotificationsSettings.pattern}
    element={(
      <PrivateRoute> 
        <UserSettingsTeamsNotificationsComponent />
      </PrivateRoute>
    )}
  />
);

