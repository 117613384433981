import React from 'react';
import { SideBarTabs } from '@matterapp/matter-ui';
import { Resources } from '@matterapp/routing';

const ContainerNavigation = (
  <SideBarTabs
    title="Referral"
    tabList={[
      { name: 'Referral', url: Resources.userSettingsMain.path() },
    ]}
  />
);

export default ContainerNavigation;
