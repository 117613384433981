import React from 'react';
import PropTypes from 'prop-types';
import GatherFeedback from './GatherFeedback';
import SetupRecurring from './SetupRecurring';
import Flow from '../../../components/Flow';

export default class FeedbackFlow extends React.PureComponent {
  static propTypes = {
    /** If the next button in gather feedback can be clicked. */
    canClickNextGatherFeedback: PropTypes.bool,
    /** Additional style class. */
    className: PropTypes.string,
    /** The current step of the feedback flow. */
    currentStep: PropTypes.number,
    /** The feedback items to display/ */
    feedbackItems: PropTypes.arrayOf(PropTypes.node),
    /** Callback to go to next feedback item or if last feedback item, goes to gather feedback step. */
    onClickNextFeedback: PropTypes.func,
    /** Callback when return home button is clicked. */
    onClickReturnHome: PropTypes.func,
    /** Callback when start recurring button is clicked. */
    handleConfirmRecurring: PropTypes.func,
  };

  static defaultProps = {
    currentStep: 1,
    feedbackItems: [],
    onClickNextFeedback: () => null,
  };

  renderContents = (props) => {
    const {
      currentStep,
      hasFeedback,
      combinedFlow,
    } = props;

    if (!hasFeedback) {
      return null;
    }
    if (combinedFlow.length) {
      return combinedFlow[currentStep - 1];
    }
    return null;
  };

  renderHeader(props) {
    const {
      currentStep,
      hasFeedback,
      isFeedItem,
      isLastStep,
      totalNumSteps,
      onClickNextFeedback,
      onClickReturnHome,
    } = props;

    let headerText;
    if (isFeedItem) {
      headerText = 'Feedback';
    } else {
      headerText = 'Continuous';
    }
    if (!hasFeedback) {
      return null;
    }
    return (
      <Flow.Header
        hideLogoOnMobile
        renderLeft={() =>
          <Flow.HeaderStepText
            currentStep={currentStep}
            numTotalSteps={totalNumSteps}
            stepText={headerText}
            stacked
          />
        }
        renderRight={() => !isFeedItem ? null :
          <Flow.HeaderButton.Primary onClick={isLastStep ? onClickReturnHome : onClickNextFeedback}>
            Continue
          </Flow.HeaderButton.Primary>
        }
      />
    );
  }

  mergeFeedItemsAndRecurringStep() {
    const {
      currentStep,
      feedbackItems,
      onClickNextFeedback,
      onClickReturnHome,
      handleConfirmRecurring,
      handleTracking,
    } = this.props;

    const calcSteps = feedbackItems.length += GatherFeedback.numberOfSteps -1;
    let stepCounter = calcSteps;
    const givers = new Set();
    return feedbackItems.flatMap((feedItem) => {
      if (!feedItem) { return []; }
      const { feedItemsList } = feedItem.props;
      const { payload } = feedItemsList[0];
      const { __typename, feedback, giver } = payload;
      const { email, recurringFeedback } = giver;
      const canRecur = (
        (!recurringFeedback || !recurringFeedback?.id) &&
        __typename === 'LongformPayload' &&
        feedback.skillRatings.length &&
        !!email &&
        feedback.isInvitationTypeEmail &&
        !givers.has(email)
      );

      if (!givers.has(email)) {
        givers.add(email);
      }

      if (canRecur) {
        stepCounter++;
        return [
          feedItem,
          <SetupRecurring
            key={feedItem.id}
            currentStep={currentStep}
            totalNumSteps={stepCounter}
            feedbackReceived={payload}
            onClickNext={onClickNextFeedback}
            onClickReturnHome={onClickReturnHome}
            handleConfirmRecurring={handleConfirmRecurring}
            trackSkipRecurring={handleTracking.skipRecurring}
          />
        ];
      }
      return [feedItem];
    });
  }

  render() {
    const {
      className,
      currentStep,
    } = this.props;

    const mergedFlow = this.mergeFeedItemsAndRecurringStep();
    // We are filtering for the feeditems because on rerender, the array is filled with empty slots. 
    //TODO: figure out why feedbackItems are returning a sparse array on rerender
    const combinedFlow = mergedFlow.filter(feeditem => feeditem);
    const hasFeedback = combinedFlow.length > 0;
    const calcSteps = combinedFlow.length += GatherFeedback.numberOfSteps - 1;
    const totalNumSteps = calcSteps;
    const isLastStep = currentStep === totalNumSteps;
    const currentComponent = combinedFlow[currentStep - 1];
    const isFeedItem = currentComponent && !!currentComponent.props.isFeedItem;

    const props = {
      ...this.props,
      hasFeedback,
      combinedFlow,
      totalNumSteps,
      isLastStep,
      isFeedItem,
    };

    return (
      <Flow.Scrolling
        className={className}
        renderHeader={this.renderHeader}
        renderContents={this.renderContents}
        hideCard={!hasFeedback}
        totalNumSteps={totalNumSteps}
        {...props}
      />
    );
  }
}
