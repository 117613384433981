import styled from 'styled-components';
import Button from '../../../Button';
import { IconNew as Icon } from '../../../Icon';
import theme from '@matterapp/matter-theme';
import { CheckmarkToggle } from '../../../Checkbox';

export { CheckmarkToggle };

export const Container = styled.div`
  border: 1px solid ${theme.colors.blacks[10]};
  border-radius: ${theme.borderRadius.S};

  & [data-type='list-item'] {
    padding: ${theme.spacing.half} ${theme.spacing.single};

    &:last-child {
      border-bottom: 0;
      border-bottom-left-radius: ${theme.borderRadius.S};
      border-bottom-right-radius: ${theme.borderRadius.S};
    }
  }
`;

export const CheckmarkContainer = styled.div`
  pointer-events: none;

  & svg {
    height: ${theme.spacing.singleAndHalf};
    width: ${theme.spacing.singleAndHalf};
    margin: ${theme.spacing.half};
    
    & path {
      fill: ${theme.colors.blacks[90]};
    }
  }

  ${({ isSelected }) => {
    if (!isSelected) {
      return `
        & svg path {
          fill: transparent;
        }
      `;
    }
  }}
`;

export const CheckmarkIcon = styled(Icon).attrs(() => ({
  name: 'checkmark',
}))``;

export const FilterBox = styled.input`
  border: none;
  border-top-left-radius: ${theme.borderRadius.S};
  border-top-right-radius: ${theme.borderRadius.S};
  color: ${theme.colors.blacks[70]};
  display: flex;
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.L};
  padding: ${theme.spacing.single};
  width: 100%;
`;

export const SelectedText = styled.div`
  color: ${theme.colors.blacks[50]};
`;

export const SelectAllLink = styled(Button.Link).attrs(() => ({
  size: Button.buttonSizes.S,
  useDefaultNormalStateColor: true,
}))`
  margin: 0;
  padding: 0;
`;

export const Toolbar = styled.div`
  background: ${theme.colors.blacks[5]};
  border-top-left-radius: ${theme.borderRadius.S};
  border-top-right-radius: ${theme.borderRadius.S};
  padding: 1px;

  ${({ hasItems }) =>
    hasItems
      ? ''
      : `
    border-bottom-left-radius: ${theme.borderRadius.S};
    border-bottom-right-radius: ${theme.borderRadius.S};
  `}
`;

export const ToolbarWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: ${theme.fontSize.base};
  padding: ${theme.spacing.half} ${theme.spacing.single};
`;

export const NoResultsContainer = styled.div`
  color: ${theme.colors.blacks[30]};
  padding: ${theme.spacing.double};
  text-align: center;
`;

export const NoResultsHeader = styled.div`
  font-size: ${theme.fontSize.S};
  font-weight: ${theme.fontWeight.bold};
  text-transform: uppercase;
`;

export const NoResultsSubHeader = styled.div`
  font-size: ${theme.fontSize.S};

  ${NoResultsHeader} + & {
    margin-top: ${theme.spacing.single};
  }
`;
