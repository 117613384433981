import { createStore, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { ENV_VARS } from 'app-consts';
import { rootReducer } from 'reducers';

const { CONNECT_TO_DEV_TOOLS } = ENV_VARS;

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];

const composeEnhancers = CONNECT_TO_DEV_TOOLS
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose;

const composedEnhancers = composeEnhancers(...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

export default store;
