import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../../Avatar';
import Menu from '../../../../Menu';
import {
  StyledContainer,
  Wrapper,
  AvatarContainer,
  Heading,
  HeaderContainer,
  LeftContainer,
  MenuContainer,
  DEFAULT_AVATAR_SIZE,
} from './styles';

const WithAvatar = ({
  className,
  header,
  isInactive,
  deleteProps = {},
  subheader,
  photoUrl,
  badgeUrl,
  equalPadding,
  showMenu,
  onClickAvatar,
  onClickDelete,
  onClickRemove,
}) => {
  const { isMultipleReceiver, isAdmin } = deleteProps;
  const removeMention = {
    menuLabel: 'Remove Mention',
    handleAction: onClickRemove,
  };
  const deleteItem = {
    menuLabel: 'Delete Item',
    handleAction: onClickDelete,
  };

  let menuMap;
  if (isAdmin && isMultipleReceiver) {
    menuMap = [removeMention, deleteItem];
  } else if (isMultipleReceiver) {
    menuMap = [removeMention];
  } else {
    menuMap = [deleteItem];
  }
  
  return (
    <StyledContainer className={className} isEqualPadding={equalPadding}>
      <Wrapper>
        <LeftContainer>
          <AvatarContainer onClick={onClickAvatar}>
            <Avatar url={photoUrl} badgeUrl={badgeUrl} size={DEFAULT_AVATAR_SIZE} />
          </AvatarContainer>
          <Heading><HeaderContainer isInactive={isInactive}>{isInactive ? `${header} [inactive]` : header}</HeaderContainer> {subheader}</Heading>
        </LeftContainer>
        {showMenu && 
          <MenuContainer>
            <Menu.Dark menuMap={menuMap} />
          </MenuContainer>}
      </Wrapper>
    </StyledContainer>
  );
};

WithAvatar.defaultProps = {
  showMenu: true,
};

WithAvatar.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  subheader: PropTypes.node,
  photoUrl: PropTypes.string,
  /** default header bottom padding is 16px, this sets it to match top padding, 24px */
  equalPadding: PropTypes.bool,
  onClick: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default WithAvatar;
