import { useMutation } from '@apollo/client';
import {
  CREATE_PORTAL_SESSION
} from 'graphql-queries/queries';

export const useCreateStripePortalSession = () => {
  const [mutation] = useMutation(CREATE_PORTAL_SESSION);
  
  const onCreatePortalSession = async (workspaceId) => {
    return mutation({
      variables: {
        workspaceId,
      },
    });
  };

  return onCreatePortalSession;
};