import React, { forwardRef } from 'react';
import styled from 'styled-components';
import SVG, { Path } from '../../SVG';

const SVGComponent = styled(SVG)`
  background: linear-gradient(90deg, #6bbad6 40%, #6caee8 100%);
  color: #6ea3f8;
`;

const PathLight = styled(Path)`
  opacity: 0.7;
`;

const HEIGHT = 178;
const WIDTH = 594;

const BrushStrokes1 = forwardRef((props, forwardedRef) => {
  const ref = forwardedRef || {};
  return (
    <SVGComponent
      {...props}
      preserveAspectRatio="xMidYMid meet"
      width={WIDTH}
      height={HEIGHT}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
    >
      <g ref={ref.ref1}>
        <PathLight
          d={`M51.46,0c-0.33,0-0.67,0-1,0c-1.67,3.32-4.84,3.65-8,4c1.81,2.08,3.64,0.41,5.56,0.19C50.81,3.87,51.73,2.57,51.46,0z M37.46,0.5c0.67,1.23,1.38,2.41,3.01,2.49C39.77,1.8,39.12,0.54,37.46,0.5z`}
        />
        <Path
          d={`M42.46,4c-0.66-0.34-1.32-0.68-1.99-1.01c-1.13-0.67-1.86-1.84-3.01-2.49c-0.33-0.17-0.67-0.33-1-0.5c4.67,0,9.33,0,14,0c0.51,1.37-0.24,2.43-1.28,2.86C47.06,3.73,44.95,5.17,42.46,4z`}
        />
      </g>

      <g ref={ref.ref2}>
        <PathLight
          d={`M46.99,100.47L46.99,100.47c0.52,0.68,0.79,1.69,1.98,1.5c-0.01,0.49-0.02,0.98-0.03,1.47l0,0l0,0
              c-0.08,1.58-0.93,2.4-2.48,2.53h-0.01c0.11,1.2-0.8,1.79-1.51,2.49c-1.41,0.49-1.58,2.7-3.48,2.51c-0.01,0.49-0.02,0.98-0.02,1.48
              c-1.2,1.47-2.17,3.16-3.98,4.02l0,0c0,0.33,0,0.66-0.01,0.99l0,0c-0.86,2.03-2.83,3.12-4.09,4.83c-0.26,0.36-1,1.22-1.62,0.63
              c-0.6-0.57-0.37-1.49,0.28-1.97c2.39-1.79,2.59-4.99,4.6-7c0.22-0.22,0.55-0.33,0.83-0.48c0.76-2.83,1.74-5.67,3.82-7.7
              c2.33-2.29,3.07-5.23,4.03-8.04c0.74-2.15-1.3-4.12-3.82-4.24c1.58-2.18,4.27-2.18,5.97-0.01c1.47,0.65,2.08,2.89,1.22,4.66
              c-0.32,0.65-0.81,1.22-1.23,1.83l0,0C47.3,100.12,47.14,100.29,46.99,100.47z M5.96,121.59c0-1.63,0.1-2.67-0.02-3.69
              c-0.26-2.27-1.33-4.4-1.01-6.8c0.35-2.67-1.03-5.08-1.47-7.63c-0.33-0.01-0.65-0.01-0.98-0.02c-0.03,0.74,0.08,1.38,0.98,1.52l0,0
              c0.14,1.73-0.34,3.52,0.38,5.19c0.68,1.57,0.28,1.86-1.35,1.61c-2.97-0.45-4.73,1.58-3.63,4.38c0.94,2.41,1.19,4.81,1.11,7.31l0,0
              l0,0c-0.55,1.99,1.32,3.54,1.01,5.49l0,0c0.37,3.35-1.11,6.98,1.47,10.01c0-1.33,0-2.66,0.01-3.99c-0.34-1.33,0.89-3.06-0.99-4.01
              c0-0.66,0-1.33,0-1.99c0.17-1.87-0.17-3.69-0.49-5.51c-0.17-1.4-0.44-2.79-0.47-4.19s-0.53-3.23,1.88-3.29
              c2.15-0.05,2.65,1.28,2.6,3.11C4.96,119.75,4.78,120.52,5.96,121.59z M2.45,98.48c0-1.88,0.04-3.76,0-5.64
              c-0.02-0.97,0.2-1.92,1.05-2.33c1.62-0.79,1.51-2.14,1.46-3.54c-3.99,1.27-3.99,1.27-3.98,5.68c0,1.07-0.08,2.15,0.05,3.21
              C1.16,96.87,1.15,98.06,2.45,98.48z M58.48,105.99c-1.13,0.55-2.31,1.2-2-1.03c1.16,0.02,1.5-0.64,1.49-1.69
              c-0.02-1.11-0.1-2.13-1.5-2.32l-0.02,0.01c-1.91-2.91-3.14-3.28-5-1.52l0,0c-0.62,0.31-0.96,0.8-1.01,1.5
              c1.78-0.27,2.48,1.07,3.26,2.23c0.41,0.61,0.34,1.41-0.35,1.75c-1.64,0.82-1.9,2.38-2.32,3.88c-0.29,1.06-1.55,2.33-0.48,3.16
              c1.28,0.99,1.99-1,3.12-1.36c0.15-0.05,0.2-0.43,0.3-0.66C56.06,109.31,57.21,107.58,58.48,105.99z M4.97,83.47
              c1.13-0.53,1.97-1.36,2.5-2.5l0,0c1.66,0,3.33,0,4.99,0c-1.12-0.48-2.19-1.16-3.36-1.39c-1.22-0.24-2.76-0.56-3.14,1.38
              C4.53,81.37,5.08,82.55,4.97,83.47z M16.46,84.96c0.68,1.74,1.41,3.47,2.04,5.24c0.29,0.82,0.54,1.74,1.54,1.69
              c2.17-0.12,3.95,1.06,5.93,1.57c0.97,0.84-0.02,2.31,1,3.2c0.74-0.53,1.78-0.62,2.01-1.7l0,0l0,0c3.93-0.99,6.26,0.22,7.53,3.97
              c0.22,0.66,0.56,0.97,1.22,0.98c1.01,0.01,1.23-0.64,1.23-1.47c-0.2-1.04,0.91-1.56,1.01-2.48c-0.27-0.17-0.73-0.3-0.78-0.52
              c-0.68-2.77-2.67-3.51-5.2-3.47l0,0c-0.83-1.5-1.67-1.5-2.51,0l0,0c-0.66,0.34-1.41,0.59-1.5,1.5l0,0
              c-0.32,0.01-0.65,0.02-0.97,0.03l0,0l0,0c-0.54,0.28-1.02,1.39-1.59-0.1c-0.42-1.09-0.81-2.44-2.43-2.42l0,0
              c-1.41-2.26-3.61-2.61-6.01-2.49l0,0l0,0c-0.34-1.23,0.05-2.78-1.47-3.52c-0.02-1.86-1.43-1.41-2.51-1.51
              C15.04,84.37,15.8,84.62,16.46,84.96z M14.94,82.49c0.04-1.93-1.4-1.44-2.49-1.51C12.81,82.27,14.02,82.13,14.94,82.49z
              M2.48,140.93c-0.03,0.74,0.07,1.39,0.98,1.51C3.48,141.72,3.36,141.08,2.48,140.93z M92.47,118.74c-0.37-1.47-2.03-1.68-3.54-0.8
              c-0.57,0.33-1.21,0.55-1.85,0.77c-0.22,0.03-0.44,0.09-0.64,0.24c-0.17,0.33-0.34,0.66-0.51,0.99l0,0
              c-1.67-0.03-2.96,1.1-4.49,1.51c-2.47,0.48-4.98,0.81-7.39,1.5c-1.07,0.31-2.18,0.87-3.03,0.21c-0.71-0.55,0.73-1.35,0.43-2.21
              c-0.01-0.01-0.01-0.01-0.02-0.02c0,0,0,0,0.01,0l0,0l0.01,0.02c0.71-0.03,1.02-0.64,1.49-1.01c0.9-0.12,1.01-0.77,1.01-1.49
              c0.73,0,1.37-0.11,1.5-1c0.9-0.12,1.01-0.77,1-1.5l0,0c1.34-0.04,2.13-0.78,2.43-2.02c0.14-0.58-0.02-1.26-0.73-1.35
              c-0.99-0.12-2.19-0.61-2.7,0.87l0,0c-0.83,0.17-1.67,0.33-2.5,0.5c-0.78,0.44-1.62,0.54-2.5,0.49l0,0
              c0.17-0.33,0.34-0.66,0.51-0.99c0.48-0.36,0.77-1,1.48-1.01c1.13,0.12,1.63-0.61,2.01-1.5c0.6-0.07,0.93-0.4,1-1
              c0.89-0.13,1.01-0.77,1-1.5l0,0v-0.01c1.88-0.56,2.07-2.65,3.34-3.7c1.13-0.94,0.67-1.99,0.51-2.94c-0.18-1.09-1.28-0.8-2.08-0.85
              c-1.18-0.08-2.25,0.08-3.3,0.81c-0.81,0.57-1.97,1.02-1.98-0.81c0-0.83,0-1.66,0-2.5l0,0c0.34-1.34-1.39-1.43-1.5-2.5
              c-1.08,0.08-2.5-0.4-2.51,1.49l0,0c-0.16,0.18-0.32,0.35-0.47,0.53l0,0l0,0c-0.17,0.16-0.35,0.32-0.52,0.48
              c-1.55,0.17-2.53,1.03-3,2.5l0,0l0,0c-1.36,0.41-1.5,2.27-3,2.51c-1.61,0.09-2.33,1.26-3.02,2.48c0.98,0.04,1.84-0.24,2.51-0.99
              c0.5-0.01,1.01-0.11,1.49-0.01c0.7,0.14,1.19-1.32,1.91-0.29c0.53,0.76,0.3,1.57-0.21,2.32c-0.26,0.38-0.74,0.77-0.1,1.34
              c0.78-0.32,1.66-0.54,2.4-0.99c1.75-1.07,1.97-0.32,2.06,1.34c0.13,2.33-1.56,3.33-2.91,4.62c-1.4,1.33-3.29,2.56-0.33,4.23
              c0.17,0.1,0.38,0.5,0.33,0.56c-1.69,1.93,0.76,3.56,0.37,5.38c-1.25,0.14-1.96,1.13-2.28,2.06c-0.46,1.35-1.44,2.13-2.27,3.13
              c-1.44,1.75-3.78,2.36-5.02,4.27c-0.36,0.56-1.49,1.02-0.82,1.77c0.53,0.59,1.75,0.53,2.33,0.04c2.41-2.08,5.89-2.32,8.06-4.77
              c2.3-0.61,4.13-2.2,6.35-3.04c0.88-0.33,2.33-0.58,2.65-1.96l0,0c0.33,0,0.66,0,1,0c0.73,0.01,1.38-0.1,1.5-1l0,0
              c1.45-0.19,2.61,0.22,3.24,1.65c0.68,1.55-0.68,2.2-1.4,3.12c-1.76,2.24-4.62,3.47-5.83,6.23c0,0.33,0,0.66-0.01,0.99l0,0
              c-0.9,0.09-1.4,0.59-1.5,1.49c0.73,0.02,1.38-0.09,1.5-0.99c0.91-0.08,1.42-0.58,1.52-1.48l0,0c0.32-0.01,0.65-0.02,0.97-0.02
              c1.56-0.09,2.37-0.94,2.53-2.47l0,0c0.48-0.01,0.97-0.03,1.45-0.04c1.2-0.46,2-1.32,2.53-2.47l0,0l0,0l0,0l0,0l-0.01-0.01l0,0l0,0
              c1.05-0.61,2.48-0.84,2.52-2.48l0,0c0.32-0.01,0.65-0.01,0.97-0.02c1.06,0.06,1.56-0.44,1.51-1.49l-0.01-0.01
              c0.6-0.05,0.93-0.39,1.01-0.98c1.05,0.04,1.55-0.45,1.49-1.51l0,0l0,0c0.01-0.33,0.01-0.66,0.02-0.99
              C92.65,122.05,92.78,119.98,92.47,118.74z M24.19,153.95c-0.76-0.08-1.51,0.03-1.73,0.98c-1.4,0.19-2.51-0.28-3.4-1.41
              c-0.7-0.88-1.46-1.74-2.6-2.09l0,0c-0.09-1.07,0.41-2.48-1.47-2.5l0,0c-0.02-0.49-0.03-0.98-0.05-1.47l0,0
              c-0.49-0.84-0.98-1.69-1.48-2.53l0,0c-0.33-0.56-0.67-1.12-0.98-1.69c-0.26-0.47-0.57-0.88-1.14-0.49
              c-0.41,0.28-0.27,0.74-0.12,1.13c0.22,0.57,0.61,0.98,1.25,1.06c0,0.5,0,0.99-0.01,1.49l0,0c-0.49,1.54,0.96,2.6,1,4
              c-0.43,2.15,0.48,3.39,2.53,4.09c1.12,0.38,2.11,1.36,2.02,2.55c-0.06,0.83-0.7,1.88-1.97,1.91c-0.69,0.02-0.93,0.52-1.09,1.15
              c-0.5,2.02-1.98,1.39-3.16,0.96c-0.63-0.23-0.98-1.12-1.83-1.16c-0.02-0.74,0.07-1.53-0.64-2.04c-1.83-1.32-1.96-3.53-2.82-5.35
              c-0.44-0.92-0.2-2.21-1.53-2.61c0.16,2.25,0.27,4.49,0.64,6.75c0.41,2.52,1.87,4.69,1.87,7.23c1.14,2.13,3.33,3.24,5.1,2.94
              c2.43-0.42,2.59-2.01,2.39-3.92l0,0l0,0c3.91,1.58,4.08,1.54,6.48-1.51c1.88-0.95,0.67-2.67,0.99-4l0,0l0,0
              c0.01-0.33,0.02-0.65,0.03-0.98c2.57-0.14,5.32,0.62,7.48-1.52c0-0.34-0.01-0.67-0.01-1.01c0.02-0.49,0.04-0.98,0.06-1.46
              C28.21,153.52,26.32,154.19,24.19,153.95z M64.95,142.43c-0.92,0.16-1.37,0.95-2,1.5c1.14,0.01,2.29,0.06,2.51-1.48l0,0
              c1.31-0.63,2.52-1.4,3.48-2.52C67.01,139.8,65.6,140.51,64.95,142.43z M57.45,147.42c1.56-0.15,2.52-1.03,3.03-2.47
              C58.91,145.09,57.94,145.96,57.45,147.42z M38.96,155.94c-1.57-0.64-0.72-3.01-2.5-3.5c-0.06,1.89,0.33,3.5,2.49,4.02
              c0.85,1.47,1.69,1.48,2.53-0.01l0,0c1.34-0.09,2.89,0.29,3.51-1.5c-1.17,0.5-2.34,1-3.51,1.5
              C40.63,156.28,39.8,156.11,38.96,155.94z M68.95,139.93c1.21,0.13,1.78-0.8,2.5-1.5C70.36,138.51,69.29,138.6,68.95,139.93z
              M51.45,151.42c0.93-0.05,1.41-0.57,1.53-1.47C51.92,149.87,51.42,150.37,51.45,151.42z M53.95,149.93
              c0.74,0.02,1.38-0.1,1.49-1.01C54.73,148.93,54.08,149.04,53.95,149.93z M49.95,152.43c0.73,0.02,1.38-0.09,1.5-1
              C50.73,151.42,50.08,151.55,49.95,152.43z M32.41,103.05c-0.87,0.86-1.41,2.21-2.66,2.51c-1.73,0.42-2.43,1.23-2.36,3.06
              c0.05,1.45-1.68,2.74-0.04,5.19c0.59-0.9,1.15-1.48,1.38-2.17c0.99-2.94,3.83-4.56,5.1-7.27c0.25-0.53,1.27-0.99,0.59-1.65
              C33.81,102.15,32.99,102.47,32.41,103.05z`}
        />
        <Path
          d={`M62.95,143.94c-0.64,0.78-1.46,1.14-2.47,1.02c-0.99,0.85-2.06,1.59-3.03,2.47c-1.03,0.01-0.96,1.51-2.01,1.5
              c-0.37,0.53-1.11,0.5-1.49,1.01c-0.33,0.01-0.65,0.02-0.98,0.02c-0.56,0.43-1.05,0.95-1.53,1.47v0.01c-0.37,0.52-1.11,0.5-1.5,1
              c-1.62,0.91-3.05,2.2-4.97,2.52c-0.91,1.12-2.27,1.16-3.51,1.5h0.01c-0.88,0.76-1.67,0.36-2.45-0.19c-1.9-0.57-1.64-2.59-2.57-3.82
              c-0.22-0.99-0.23-1.77-1.85-1.28c-1.51,0.46-2.86,1.67-4.61,1.3c-0.93,1.68-2.42,2.24-4.26,2.23c-1.03-0.01-2.14-0.24-2.99,0.65
              c-1.83,0.82-3.18-0.03-4.42-1.26c-0.65-0.64-1.3-1.27-1.87-1.98c-0.18-0.16-0.32-0.35-0.44-0.55c-0.26-0.96-0.55-1.9-1.43-2.51
              c-0.27-0.37-0.33-0.82-0.42-1.25c-0.44-0.88-0.94-1.72-1.14-2.7c-0.07-0.21-0.2-0.39-0.32-0.57c0.04,0.22,0.2,0.37,0.28,0.59
              c0.2,0.45,0.2,0.92,0.24,1.39c-0.27,1.47,1.16,2.53,0.97,3.98c-0.21,1.59,0.39,2.41,1.98,3.11c3.28,1.44,3.44,3.98,0.65,6.24
              c-0.25,0.21-0.53,0.38-0.76,0.62c-2.36,2.45-3.48,2.42-6.36-0.12c-2.7-2.95-3.83-6.62-4.73-10.39c-0.02-2.59-2.28-4.74-1.52-7.49
              c-0.51-0.39-0.49-1.12-0.98-1.51c-0.01-0.66-0.02-1.33-0.03-1.99c-0.28-1.22-0.84-2.39-0.73-3.69c-0.1-1.46,0.29-2.98-0.45-4.38
              c-0.09-0.68-0.04-1.36-0.05-2.04c0.37-1.82-0.84-3.42-0.75-5.19c0.12-1.65-0.14-3.3-0.35-4.92c-0.2-1.53-0.36-2.9,1.44-3.4
              c1.75-0.48,3.14,0.07,3.77,1.57c-1.14-1.4-1.14-3.09-0.85-5.23c0.32-2.37-0.48-4.98-1.25-7.4c-0.24-0.28-0.48-0.56-0.74-0.82
              c-0.01-1.65-0.02-3.31-0.03-4.96c-1.13-3.61-1.71-7.13,1.54-10.07c0.43-0.39,0.65-0.96,0.97-1.44c0-1.16,0.01-2.33,0.01-3.49
              c0.71-1.29,1.44-2.55,2.9-3.16c1.57-0.09,3.07,0.34,4.59,0.65L12.45,81c0.72,0.69,2.02,0.41,2.49,1.51
              c0.01,0.32,0.02,0.64,0.03,0.96c0.53,0.66,1.62,0.56,1.97,1.46c0.07,0.15,0.23,0.27,0.25,0.41c0.92,4.86,4.77,6.27,8.78,7.52
              c0.58,0.24,0.86,0.69,1,1.3c0.35,1.48,1.1,1.12,1.92,0.38c3.56-1.48,6.84-0.17,8.28,3.35c0.27,0.67,0.41,1.21,0.98,0.21
              c0.08-1.15,1.04-1.54,1.8-2.12c0.1-1.08,1.58-1.32,1.54-2.49c1.08-0.88,2.16-0.81,3.13,0.13c1.35,1.31,2.02,2.96,1.46,4.76
              c-0.85,2.77-1.78,5.52-3.82,7.74c-1.91,2.08-2.81,4.79-4.21,7.18c-2.25,2.18-2.47,5.65-4.91,7.71c1.26-1.2,2.58-2.33,3.47-3.85
              c0.13-0.32,0.22-0.65,0.4-0.95c1.26-1.25,2.42-2.6,3.54-3.98c0.16-0.42,0.22-0.87,0.46-1.26c0.12-0.19,0.27-0.36,0.44-0.51
              c1.17-0.66,1.75-1.96,2.83-2.71c0.7-0.48,1.32-1.04,1.7-1.83c0.13-0.18,0.28-0.33,0.45-0.46c0.91-0.56,1.85-1.09,1.81-2.38
              c0.07-0.49,0.4-0.8,0.73-1.11c0.7-0.03,0.99-0.65,1.47-1.02c1.48-1.21,2.44-0.21,3.3,0.85c0.98,1.22,1.26,2.59,0.07,3.8
              c-1.35,1.37-1.89,3.01-2.24,4.84c0.96,0.13,1.37-0.64,2-0.95c1.49-1.37,3.42-2.12,4.9-3.5c0.15-0.02,0.31-0.03,0.46-0.05
              c0.8-0.54,1.65-1.01,2.44-1.57c0.32-0.1,0.65-0.09,0.97-0.17c3.05-0.71,3.05-0.71,3.71,2.24c1.12,0.05,2.49-1.12,3.33,0.43
              c0.76,1.4,0.56,2.89-0.14,4.29c-0.41,0.82-1.06,1.48-1.75,2.07c-1.42,1.2-2.05,2.15-1.03,4.32c0.73,1.57,0.94,3.93,0.38,5.97
              c-1.47,1.17-2.09,2.95-3.1,4.45c-1.53,2.27-4.09,3.39-6.12,5.57c3.25-1.18,6-2.62,8.61-4.37c2.87-1.68,6.02-2.83,8.87-4.55
              c0.29-0.19,0.61-0.31,0.94-0.38c0.71,0.03,1.25-0.36,1.81-0.73c0.22-0.11,0.44-0.18,0.68-0.22c1.64-0.21,2.73,0.52,3.32,2
              c0.57,1.43-0.11,2.54-1,3.61c-1.84,2.23-4.44,3.69-6.13,6.08c-0.08,0.35-0.2,0.68-0.44,0.95c-0.65,0.46-1.26,0.96-1.84,1.5
              c-0.59,0.08-0.93,0.41-1,1.01c-0.93,0.34-1.74,0.88-2.5,1.5l0,0c-1.45,0.59-2.89,1.19-3.78,2.59
              C64.67,143.38,63.75,143.56,62.95,143.94z`}
        />
      </g>

      <g ref={ref.ref3}>
        <PathLight
          d={`M155.95,1.5L155.95,1.5L155.95,1.5c0-0.5,0-1,0-1.5c0.33,0,0.67,0,1,0c0.17,0,0.33,0,0.5,0
              c-0.04,1.82-0.55,3.58-0.55,5.43c0,2.2,0.11,4.28,1.55,6.07c0.03,0.04,0.07,0.08,0.11,0.11c-0.04-0.04-0.08-0.07-0.11-0.11
              c3.48,0,6.96-0.01,10.44,0.01c1.23,0.01,2.58-0.11,3.06,1.49l0,0c0.82,1.31,0.23,2.04-1.03,2.48c0.01-0.83,0.01-1.65,0.02-2.47
              c-3.66-0.01-7.32-0.02-10.99-0.03c-3.54-0.01-4.01-0.47-4.01-4.03c0-1.41,0.2-2.88-0.51-4.16C154.71,3.47,156.09,2.63,155.95,1.5z
              M139.93,55.55c-1.19,0.07-1.98-0.74-2.97-1.08l0,0l0,0c-0.32-2.73,0.41-4.98,2.86-6.5c0.3-0.19,0.43-0.65,0.63-0.99
              c1,0,2-0.01,3-0.01c-1.67-1-3.34-2.85-5,0.01l0,0c-1.05-0.05-1.55,0.45-1.5,1.5c-0.94,1.9-2.34,3.72-0.99,6
              c1.08,2.02,3.36,1.6,5,2.48C140.92,56.26,140.79,55.5,139.93,55.55z M155.95,47.98c1.82-0.91,3.91-1.15,5.62-2.43
              c1.29-0.97,0.96-2.37,1.21-3.54c0.14-0.66-0.65-1.71-1.85-1.52c-0.19,1.72,1.07,4.35-2.47,4.01c-1.03-0.16-1.85,0.19-2.5,0.99l0,0
              l0,0c-1.06-0.07-1.55,0.43-1.51,1.48l0,0c-0.33,0.01-0.66,0.01-0.98,0.02c-0.51,0-1.01,0-1.52-0.01l0,0l0,0c-1.16,0-2.32,0-3.49,0
              c-0.73-0.02-1.38,0.09-1.51,0.99l0,0c-0.33,0-0.66,0-0.99,0c-0.14-0.9-0.79-1-1.52-0.98c0.01,0.33,0.02,0.65,0.02,0.98l0,0
              c2.22,1.28,4.46,2,6.77,0.17c0.17-0.14,0.48-0.11,0.72-0.16l0,0l0,0C153.28,49.15,154.61,47.85,155.95,47.98z M154.43,35.51
              L154.43,35.51c-0.03,1.04,0.49,1.53,1.51,1.49c0-2,0-4.01,0-6.01c0-0.16,0-0.33,0.01-0.49l0,0l0,0c2.04-0.77,3.32-3.35,6.03-2.52
              c-0.01-0.33-0.03-0.65-0.04-0.98c-3.6-1.28-5.62-0.61-5.99,2l0,0l0,0l0,0l0,0c-1.54,0.48-1.6,1.7-1.5,3v0.01
              C152.87,33.15,152.89,34.32,154.43,35.51z M165.95,28.98c1.66,0.5,3.33,1,4.99,1.51C169.85,28.09,167.67,29.31,165.95,28.98z
              M141.92,57c0.06,1.63,1.48,1.86,2.55,2.44C144.42,57.8,142.99,57.59,141.92,57z M146.96,61.96c-0.02,0.73,0.1,1.38,0.99,1.52
              c0.17,0,0.34-0.01,0.51-0.01C148.49,62.43,148,61.92,146.96,61.96z M203.94,65.39c-4.15-0.47-6.63,2.42-9.23,4.74
              c-1.5,1.35-2.85,2.08-4.79,1.85c-1.13,0.07-2.21,0.01-2.99-1c0.49,2.19,2.65,1.69,4,2.48l0,0l0,0l0,0c1.33,1.52,2.66,0.65,4,0.01
              c0.33-0.01,0.66-0.01,0.99-0.02c1.09-3.43,2.82-6.3,6.5-7.47c0.84,1.49,1.67,1.49,2.51,0l0,0c0.33-0.01,0.66-0.01,0.99-0.02
              c1.06,0.06,1.55-0.44,1.5-1.49c-0.33,0-0.67,0-1,0C205.42,64.31,204.82,65.49,203.94,65.39z M175.94,69.47
              c0.01,0.01,0.01,0.01,0.02,0.02l0,0l0,0C175.95,69.48,175.94,69.47,175.94,69.47c-1.67,0.91-3.34,0.82-5.02,0.02
              c0.02,1.01,0.53,1.49,1.55,1.47c1.15-0.02,2.31,0,3.47,0c0.92-0.08,1.17-0.83,1.51-1.49c1.29-0.44,1.94-2.02,3.5-1.99
              C178.46,66.1,177.08,67.47,175.94,69.47z M161.94,66.97l0.01,0.01c0,0,0,0,0,0.01C161.95,66.98,161.94,66.97,161.94,66.97
              c-0.97-0.11-1.72,0.33-2.51,0.84c-1.29,0.82-2.69,0.77-4,0c-0.64-0.38-1.18-0.93-2-0.83c1.43,2.22,3.6,2.63,6.01,2.49
              c2.12,0.43,3.02-1.1,4-2.5c0.83,0,1.67,0,2.5,0C164.2,65.04,163.1,65.04,161.94,66.97z M168.45,68.47c0.11,0.91,0.76,1.01,1.49,1
              C169.82,68.58,169.18,68.46,168.45,68.47z M219.14,14.61c1.97,1.12,2.22-0.06,2.3-1.58c-1.34,0.49-2.68,0.98-4.01,1.46
              C218,14.52,218.69,14.35,219.14,14.61z M209.94,61.97c1.35-0.31,1.42-1.4,1.5-2.49c-0.48-0.37-0.79-0.99-1.5-1.01
              C209.94,59.64,209.94,60.81,209.94,61.97z M207.43,64.47c1.56-0.11,2.39-0.94,2.5-2.5C208.39,62.09,207.56,62.93,207.43,64.47z
              M189.95,44.46c0.19-1.03-0.87-1.56-1-2.46l0,0c0.8-0.07,1.76,0.18,2-1l0,0c2.51-0.28,3.99-1.85,5-4l0,0
              c0.84-0.11,1.17-0.66,1.52-1.4c1.14-2.44,1.16-5.23,2.49-7.6c-0.49,0.03-1.11-0.01-1.3,0.52c-0.94,2.64-1.82,5.31-2.72,7.97
              c-1.92,1.01-3.56,2.38-5,4c-0.96,0.07-2.01-0.26-2.85,0.32c-0.69,0.47-1.87,0.86-1.71,1.81c0.14,0.85,1.25,0.84,2.06,0.88
              C188.58,44.37,189.21,44.5,189.95,44.46z M204.94,54.44c-0.06-1.63-1.24-2.1-2.53-2.45C202.48,53.6,203.86,53.87,204.94,54.44z
              M194.95,47.97c0.71,0.71,1.3,1.62,2.51,1.5C197.44,47.6,196.03,48.06,194.95,47.97z M199.62,48.91c-0.55-0.37-0.77,0.39-1.18,0.57
              c0.13,0.88,0.75,1.03,1.49,1.01c0,0,0,0,0-0.01c0.5,0.49,1.01,0.97,1.51,1.46c0.05-1.06-0.47-1.53-1.51-1.46
              C199.89,49.94,200.14,49.26,199.62,48.91z M226.59,11.33c-0.56-1.44-1.1-2.88-1.64-4.33l0,0c0-0.33,0-0.67,0-1
              c0.79-0.67,2.35-1.34-0.01-2l0,0l0,0l0,0c0.2-0.64,1.17-1.04,0.53-2.11c-2.54,1.46-4.66,0.53-6.54-1.39l0,0l0,0
              c-0.16-0.17-0.32-0.34-0.49-0.5c-0.33,0-0.67,0-1,0c0.32,1.43,0.68,2.82,2.51,2.99l0,0c0.49,0.01,0.99,0.02,1.48,0.03l0,0l0,0
              c0.72,1.85,2.23,3.62,1.61,5.66c-0.34,1.13-0.57,2.18-0.6,3.32c0,0.33,0,0.66,0,0.99c1.37,1,1.44-1.23,2.5-1
              c0.14,0.66,0.47,1.1,1.2,0.79C226.8,12.49,226.83,11.95,226.59,11.33z`}
        />
        <Path
          d={`M170.92,69.49c-0.32-0.01-0.65-0.01-0.97-0.02c-0.38-0.51-1.12-0.48-1.49-1c-1.18,0.07-1.42-1.41-2.5-1.5
              c-1.56-1.09-2.94-0.88-4.16,0.56c-2.93,1.95-5.71,1.71-8.36-0.55c-1.46,0-1.95-1.21-2.77-2.09c-0.58-0.62-1.46-0.96-2.21-1.43
              c-0.51-0.49-1.01-0.99-1.5-1.5c-0.63-1.03-0.88-2.44-2.49-2.52c-0.64-1.04-1.9-1.42-2.55-2.44c-0.32-0.01-0.65-0.03-0.97-0.04
              c-1.04-1.64-3.51-0.79-4.46-2.58c-1.13-3.88,1.19-5.99,4.01-7.86c1.03-0.19,1.98,0.21,2.95,0.45c0.33,0.01,0.65,0.02,0.98,0.02
              c0.25,0.23,0.49,0.46,0.75,0.68c1.49,0.86,2.94,1.27,4.57,0.21c0.58-0.37,1.42-0.47,2.18-0.32c1.31,0.45,2.52-0.09,3.76-0.35
              c1.36-0.74,2.94-0.89,4.28-1.74c2.09-1.31,2.42-2.7,0.95-4.97c-1.66-1.17-3.32-2.33-4.98-3.5c-1.41-1.77-0.5-3.82-0.7-5.73
              c0.02-0.3,0.1-0.59,0.23-0.86c0.7-1.03,1.76-1.7,2.67-2.49c1.18-1.02,2.56-0.9,3.83,0.06c1.31,0.39,3.02-0.81,3.97,1.01
              c1.64,0.59,3.7-0.24,4.99,1.51c1.46,0.05,2.85-0.11,4.16,1.09c0.98,0.89,2.58,0.57,3.84,0.2c1.24-0.36,0.99-1.57,0.96-2.55
              c-0.03-0.97-0.68-1.23-1.52-1.23c-2.91,0-5.82-0.08-8.72,0.02c-1.95,0.06-2.73-0.82-2.72-2.66c0.02-1.91,0-3.82,0-5.73
              c0.01-2.77,1.14-3.95,3.96-4.14c0.81-0.86,0.95-1.77,0.26-2.77c-2.3-0.94-4.7-0.3-7.05-0.42c-2.03-0.11-4.09,0.26-6.07-0.46
              c-0.5-0.32-1.06-0.72-1.14-1.26c-0.51-3.55-1.53-7.14,0.53-10.56c20,0,40,0,59.99,0c1.02,0.67,1.54,2,2.89,2.25
              c0.51,0.05,1,0.13,1.42,0.45c1.43,2.24,3.21,4.38,1.56,7.3c-0.32,0.56-0.1,1.3-0.17,1.96c-0.03,0.41-0.14,0.8-0.39,1.14
              c-0.27,0.31-0.61,0.5-1,0.59c-1.39,0.47-2.71,1.11-4.03,1.77c-1.8,1.13-3.97,1-5.9,1.78c-1.32,0.53-2.16,1.11-1.98,2.64
              c0.36,3.08-1.15,5-3.85,6.2c-1.94,0.86-3.39,2.72-5.72,2.76c-1.44,0.16-1.51,1.6-1.7,2.41c-0.45,1.94-1.45,3.58-2.22,5.35
              c-1.55,1.63-3.35,2.98-5.19,4.26c-0.74,0.49-1.64,0.19-2.39,0.46c-0.93,0.34-1.46,0.94-0.22,1.67c0.49,0.48,1.04,0.83,1.76,0.8
              c1.98,0.78,3.82,1.8,5.27,3.4c0.53,0.66,0.88,1.53,1.92,1.56c0.33,0.01,0.65,0.11,0.94,0.25c0.48,0.23,0.81,0.72,1.38,0.82
              c0.21,0.07,0.4,0.17,0.59,0.28c0.56,0.34,1.01,0.81,1.6,1.11c0.29,0.16,0.56,0.36,0.79,0.6c0.61,0.95,1.52,1.51,2.54,1.93
              c1.49,0.71,2.55,2,3.91,2.88c0.45,0.59,1.08,1.06,1.3,1.81c0.16,1.01,0.17,2.01,0.01,3.02c-0.07,0.23-0.18,0.45-0.32,0.65
              c-0.89,0.71-1.91,1.3-2.4,2.42c-0.16,0.19-0.35,0.34-0.56,0.46c-0.34,0.16-0.71,0.21-1.08,0.3c-0.64,0.18-1.13,0.84-1.8,0.8
              c-4.08-0.26-6.62,2.43-9.21,4.89c-1.61,1.54-3.28,2.13-5.4,1.5c-0.94-0.54-2.57,0.25-2.88-1.5c-2.23-0.78-4.48-1.52-6-3.51
              c-2.44-0.74-3.41,1.42-4.95,2.49C174.21,70.81,172.46,71.29,170.92,69.49z`}
        />
      </g>

      <g ref={ref.ref4}>
        <PathLight
          d={`M207.44,179.92c-0.5,0-1,0-1.5,0c0-0.33,0-0.67,0.01-1C206.67,178.92,207.32,179.03,207.44,179.92z
              M207.42,137.45c-0.04-1.4,0.41-2.93-0.99-4c-1.19-1.2-2.83-1.6-4.34-1.89c-1.15-0.22-2.81-0.86-3.66,0.88
              c3.44-0.01,6.55,0.51,7.71,4.46C206.29,137.43,206.92,137.41,207.42,137.45z M218.75,135.92c-1.32,0.12-2.66,0-3.99,0.05
              c-1.03,0.04-2.01,0.24-2.33,1.46c3.36,0.33,6.97-0.97,10,1.52C222.11,136.84,221.04,135.7,218.75,135.92z M222.46,139.93
              c0.48,2.71,1.17,5.37,1.05,8.2c-0.09,2.25-0.58,4.6,0.41,6.82c0-4.24,0.01-8.47,0.01-12.71
              C223.93,141.17,224.13,139.95,222.46,139.93z M224.95,172.42c0.49,1.34,0.99,2.67,1.48,4.01
              C225.99,175.08,227.62,172.96,224.95,172.42z M209.96,139.95L209.96,139.95L209.96,139.95c-0.01,2.49-0.02,4.98-0.03,7.47
              c-1.58-0.55-0.83-1.9-0.99-2.92c-0.3-1.91,0.19-4.02-1.5-5.56c-0.61,3.43,1.59,6.58,1,10l0,0c0.46,3.4,2.16,6.54,1.92,10.13
              c2.17-6.68-0.36-13.49,1.08-20.12c0-0.5,0-1,0.01-1.49C209.54,137.44,210.02,138.86,209.96,139.95z M223.95,168.92
              c0.12,0.92-0.44,2.1,0.98,2.51C224.81,170.51,225.38,169.32,223.95,168.92z M165.23,120.14c-2.22,0.73-3.22,2.73-3.27,5.09
              c-0.06,2.45,0.28,4.95-1.01,7.22c0.87-0.16,1.35-0.81,1.36-1.56c0.03-2.06,0.59-3.99,1.06-5.98c0.6-2.56,2.79-3.97,5.19-2.84
              c0.81,0.38,1.59,0.35,2.41,0.37C169.52,120.49,167.54,119.38,165.23,120.14z M173.44,124.95c0.47,2.2,2.65,1.69,3.99,2.51
              c-0.16-0.5-0.33-1.01-0.49-1.51C175.82,125.45,174.79,124.65,173.44,124.95z M160.93,132.44c-1.39,0.77-2.63,1.65-2.48,3.51l0,0
              l0,0l0,0c-1.06-0.05-1.56,0.44-1.49,1.51l0,0l0,0c-0.01,0.83-0.01,1.66-0.02,2.48l0,0c-0.32,0-0.65,0.01-0.97,0.01
              c-0.16,0.37-0.5,0.75-0.47,1.11c0.68,7.35-1.42,14.56-1.04,21.89l0,0l0,0c0,5.66,0,11.32-0.01,16.98c0.5,0,1,0,1.5,0
              c0-7.33,0-14.67-0.01-22l0,0c0-1.16,0.01-2.33,0.01-3.49l0,0l0,0c1.26-0.35,0.95-1.39,0.99-2.26c0.06-1.33-0.42-3.02,0.22-3.92
              c2.05-2.87,1.04-6.35,2.28-9.32c-0.34-1.64,1.6-2.45,1.49-4.01C160.93,134.1,160.93,133.27,160.93,132.44z M171.95,122.45
              c0.32,0.94,0.18,2.16,1.5,2.5C173.36,123.86,173.84,122.45,171.95,122.45z M178.45,128.94c1.98,1.95,4.08,1.33,4.99-1.48
              C181.9,128.34,180.35,129.21,178.45,128.94z M193.44,127.45c0.09,0.91,0.84,1.16,1.5,1.5C195,127.89,194.5,127.39,193.44,127.45z`}
        />
        <Path
          d={`M170.96,122.44c0.33,0,0.66,0.01,0.99,0.01c1.06,0.5,0.8,1.79,1.5,2.5v0.01c1.24,1.01,2.83,1.3,4.22,2.01
              c0.6,0.38,1.07,0.9,1.5,1.45c2.1,1.24,2.31,1.16,4.12-1.55c3.35-2.81,6.73-2.16,10.13-0.1c0.66,0.56,1.24,1.21,1.98,1.68
              c1.24,0.97,1.22,3.28,3.43,3.23c2.4-0.43,4.29,0.81,6.27,1.83c1.65,0.84,1.24,2.82,2.33,3.95c0.01,0.5,0.02,0.99,0.03,1.49
              c0.71,2.18,0.97,4.46,1.2,6.72c0.1,0.99,0.25,1.94,0.52,2.88c-0.09,2.36,2.01,4.22,1.56,6.27c-0.53-5.06-0.31-10.53-0.01-15.99
              c0.01-0.58,0.36-0.98,0.73-1.37c0.33-0.01,0.65-0.01,0.98-0.02c2.48-1.37,5.14-0.68,7.72-0.6c1.1,0.03,1.53,1.39,2.28,2.12
              c0.01,0.33,0.02,0.66,0.03,0.99c0.98,1.31,1.19,2.82,1.24,4.41c0.11,3.53-0.33,7.08,0.23,10.6c0.01,4.66,0.01,9.31,0.02,13.97
              c0.81,0.65,0.18,1.86,0.98,2.51c0.01,0.33,0.02,0.66,0.03,0.99c1.51,0.96,1.01,2.66,1.48,4.01l0.01-0.01
              c0.96,0.99,1.75,2.03,0.99,3.5c-6.67,0-13.33,0-20,0c-0.51-0.62-1.24-0.45-1.9-0.53c-2.19-1.38-3.08-3.65-3.7-5.93
              c-0.5-1.84-1.12-3.61-1.91-5.73c-0.73,3.27-1.86,6.09-2.99,8.91c-0.55,1.38-1.26,2.49-2.49,3.29c-12.83,0-25.66,0-38.5,0
              c-0.95-0.84-0.74-1.96-0.75-3.02c-0.01-6.22,0-12.45,0-18.67c0.05-1.22-0.11-2.44,0.11-3.66c0.27-0.67,1-1.19,0.86-2
              c-0.62-3.46,1.38-6.54,1.39-9.91c0-1.26,0.87-2.3,1.06-3.53c-0.27-1.75,1.36-2.85,1.53-4.46c0.04-0.83,0.28-1.59,0.79-2.25
              l0.03,0.01c1.02-1.08,1.43-2.35,1.25-3.85c-0.2-1.75,0.42-3.42,0.72-5.11c0.34-1.94,3.32-3.32,5.14-2.44
              C169.03,121.52,170.1,121.77,170.96,122.44z`}
        />
      </g>

      <g ref={ref.ref5}>
        <PathLight
          d={`M402.41,6.49L402.41,6.49c-0.03,0.69-0.16,1.25-0.98,1.47c-1.67,0.45-4.55-0.8-5.52-2.47l0,0l0,0
              c-0.81-2.21-3.08-3.36-4-5.49c3,0,6,0,9,0c0.17,0,0.33,0,0.5,0C403.3,1.93,402.06,4.33,402.41,6.49L402.41,6.49z M360.92,1.5
              c0-0.5,0-1,0-1.5c-0.5,0-1,0-1.5,0c-0.33,0-0.67,0-1,0c0,0.5-0.1,1.02,0.02,1.49c0.35,1.3-0.97,3.66,1.03,3.75
              C361.85,5.34,360.56,2.85,360.92,1.5z M379.92,4c-1.89,0.67-1.47,2.79-2.5,4c-0.99,1-1.5,0.86-1.5-0.6c0.01-2.46,0-4.93,0-7.39
              c-0.33,0-0.67,0-1,0c0,2.9,0,5.79,0.01,8.69c0,0.74-0.04,1.56,0.98,1.73c1.1,0.18,1.51-0.41,1.51-1.42
              C379.34,7.87,380.01,6.12,379.92,4L379.92,4c1.62-0.84,2.22-2.32,2.5-4c-0.5,0-1,0-1.5,0C381.27,1.5,379.57,2.49,379.92,4z
              M351.3,17.51c-0.76-1.35-1.42-2.47-2.87-3.02c-0.15-0.86,0.42-2.16-0.6-2.45c-1.18-0.34-0.66,1.23-1.23,1.74
              c-0.24,0.22-0.45,0.48-0.68,0.72c0.33,0,0.66,0,1,0c0,0.33,0,0.67,0,1l0,0l0,0c0,0.33,0,0.66,0,1c-0.01,0.73,0.1,1.37,1,1.5l0,0
              c0.09,0.91,0.58,1.41,1.5,1.5l0,0c0.01,0.33,0.02,0.66,0.03,0.99c0.88,0.07,1.76-0.1,2.54,0.64c2.29,2.16,8.2,0.48,8.96-3.12
              c-0.43,0.23-0.85,0.46-1.28,0.7C357.54,19.88,352.36,19.39,351.3,17.51z M376.57,20.98c-0.91,1.07-1.49,0.52-2.5,0.06
              c-3.05-1.4-5.87-3.04-8.14-5.55c-0.08,2.12,0.68,3.82,2.48,5.01c0.51,2.17,2.12,2.55,4.01,2.48l0,0l0,0c2.66,0.08,5.31,0.16,7-2.49
              C378.46,20.59,377.31,20.12,376.57,20.98z M339.43,21.99L339.43,21.99c-1.81-0.04-4.2,0.64-5.31-0.26
              c-2.53-2.05-4.51-0.83-6.63,0.04c-0.19,0-0.38,0.05-0.56,0.22c-1.59-1.43-4.31-1.15-5.28-3.57c-0.22-0.55-1.03-0.5-1.59-0.28
              c-1.06,0.42-2.09,0.9-3.13,1.35l0,0c-3.63,0.63-6.06-1.78-6.99-4.36c-0.97-2.68-2.87-1.89-3.99-1.8c-4.01,0.3-5.47-0.05-7-3.64
              c-1.09-2.54-2.56-3.76-5.32-3.19c-0.24,0.05-0.5-0.02-0.75,0.01c-2.28,0.25-3.36-1.01-3.84-3.03c-0.18-0.76,0.06-1.76-1.1-1.97
              c-0.03-0.96-1.06-0.94-1.5-1.5c-0.83,0-1.67,0-2.5,0c0.79,0.91,2.98,0.98,2.02,2.94c-1.13,2.29,0.05,3.08,1.98,3.56l0,0
              c-0.04,1.05,0.45,1.55,1.51,1.49l0,0l0,0c1,0,2.01-0.08,2.99,0.03c1.05,0.12,2.43-0.64,3.01,0.96l0,0l0,0
              c0.33,2.06,0.29,4.29,2.49,5.51l0,0l0,0c0.4,1.43,1.59,0.87,2.5,0.99l0,0l0,0l-0.01,0.02c1.31-0.02,2.91,0.44,3.88-0.15
              c1.94-1.18,2.65,0.06,3.61,1.14c0.06,3.75,0.14,3.85,3.02,3.99l0,0l0,0c0.16,0,0.33,0.01,0.49,0.01c1.54,2.1,2.79,2.1,5.5,0l0,0
              l0,0c0.83,0,1.67,0,2.5-0.01l0,0l0,0l0,0c0.19,2.2,1.66,2.61,3.5,2.49l0,0l0,0c1.66,0,3.33,0.01,4.99,0.01
              c0.83,0.01,1.57,0.1,2.32,0.66c2.1,1.56,7.23,1.13,9.18-0.66c2.54-0.13,3.87-1.48,3.99-4.02C341.71,19.49,341.22,21.6,339.43,21.99
              z M344.43,17c0.91-0.1,1.43-0.59,1.47-1.53C344.87,15.45,344.36,15.95,344.43,17z`}
        />
        <Path
          d={`M365.93,15.49c-0.1-0.7,0.2-1.47-0.42-2.17c-2.22,0.88-2.22,3.96-4.57,4.68c-2.7,3.18-8.1,2.94-10.71-0.52
              c-0.64-0.85-1.25-1.73-1.98-2.51c-0.47-1.36-0.99-1.03-1.52-0.04c-0.14,0.31-0.3,0.6-0.53,0.85c-0.51,0.42-1.06,0.81-1.07,1.57
              c-0.25,0.83-0.74,1.52-1.33,2.14c-1.65,0.9-2.35,3.14-4.58,3.22c-2.6,0.07-5.17,0.48-7.65-0.99c-1.46-0.87-2.87,0.85-4.41,0.78
              c-1.69,0.17-3.21-0.69-4.25-1.63c-2-1.81-3.93-1.5-6.08-0.74c-3.62,0.89-5.55-1.59-7.4-3.91c-1.18-1.48-2.37-1.93-4.25-1.73
              c-2.7,0.28-5.09-0.6-6.45-3.23c-0.42-0.81-1-1.53-1.4-2.34c-0.65-1.32-1.68-1.65-3.04-1.68c-4.63-0.11-4.63-0.14-6.31-4.56
              c-0.09-0.23-0.27-0.41-0.46-0.58c-0.23-0.76-1.41-1.04-1.09-2.09c24,0,47.99,0,71.99,0c1.05,0.89,0.66,2.26,1.24,3.61
              C360,2.73,360.1,2,360.2,1.27c0.02-0.55,0.38-0.9,0.72-1.27c4.67,0,9.33,0,14,0c1.29,1.3,0.6,2.94,0.73,4.43
              c0.11,1.3-0.07,2.61,0.09,3.91c0.08,0.67,0.35,1.16,1.08,0.48c1.33-1.25,2.38-2.63,2.48-4.54c0.07-0.22,0.18-0.42,0.3-0.61
              c1.1-1.1,1.63-2.63,2.81-3.67c2.83,0,5.67,0,8.5,0c-0.09,3.91-2.2,7.14-3.85,10.44c-1.62,3.25-3.71,6.3-6.04,9.15
              c-0.47,0.58-0.89,0.92-1.61,0.91c-1.02,0.84-2.54,0.7-3.48,1.76c-0.26,0.3-0.81,0.24-1.18,0.06
              C371.36,20.63,367.35,19.73,365.93,15.49z`}
        />
      </g>

      <g ref={ref.ref6}>
        <PathLight
          d={`M384.91,122.44L384.91,122.44L384.91,122.44c-0.11,0.9-0.76,1.01-1.49,1.01c0,0.72-0.11,1.37-1,1.5l0,0
              c-0.13,0.89-0.77,1.01-1.5,1l0,0l0,0l0,0c0.05,1.05-0.45,1.55-1.5,1.5c-1.43,0.47-1.6,2.68-3.49,2.5l0,0
              c0,0.72-0.11,1.37-1.01,1.49c0.61,2.8-1.4,4.29-3.01,5.99c-1.94,1.27-3.38,3.15-5.54,4.21c-0.99,0.48-1.88,0.85-2.95,0.81
              c3-3.33,6-6.67,8.99-10c1.25-3.19,3.32-5.72,5.79-8.07c0.88-0.84,1.94-0.75,2.76-1.43c0.94-0.79,2.3-1.44,1.93-2.81
              c-0.34-1.25-1.55-1.89-2.98-1.68c0.07-0.6,0.4-0.93,1-1l0,0c0.83-1.48,2.22-2.02,3.57-1.19c1.67,1.03,2.31,2.72,1.43,4.69
              C385.89,121.66,385.28,121.97,384.91,122.44z M358.11,139.44c-0.33-1.38-2.5-1.03-3.68,0.49c0.52,1.27,1.53,2.15,2.54,2.98
              c2.02,1.65,5.65,1.33,6.46-0.47C361.07,142.5,358.87,142.6,358.11,139.44z M351.27,139.5c-1.6,0.41-2.08,1.45-1.84,2.96
              c1.33,0.24,2.38-0.47,3.48-1.02c0.17-0.5,0.34-1,0.51-1.49C352.99,138.42,352.1,139.29,351.27,139.5z M328.55,92.67
              c0.99,1.74,0.8,3.46,0.88,5.24c0.04,0.91-0.07,1.97,1.25,1.99c1.13,0.02,2.65,0.6,2.76-1.43c-0.5-0.01-1-0.01-1.51-0.02
              c-0.14-0.91,0.44-2.11-1.01-2.49l0,0l0,0c-0.01-1.46,0.19-2.97-0.57-4.31c-0.66-1.17-1.58-2.08-3.05-2.16
              c-1.04-0.05-1.74,0.36-1.88,1.47C326.85,90.83,327.74,91.25,328.55,92.67z M324.44,90.98c-1.53,0.52-3.19,0.82-4.01,2.48
              C321.79,92.67,323.94,93.16,324.44,90.98z M265.44,110.95L265.44,110.95L265.44,110.95L265.44,110.95z M340.93,147.44
              c-0.12,0.3-0.17,0.82-0.36,0.88c-2.92,0.91-5.78,2.33-8.97,1.32c-0.27-0.09-0.45-0.46-0.68-0.69l0,0c-1.04,0.04-1.54-0.45-1.5-1.49
              c-0.83,0-1.66-0.01-2.49-0.01l0,0l0,0c-3.09,0.45-2.46-1.76-2.5-3.5c-0.94-0.7-1.88,0.05-2.82,0.04c-1.49-0.02-2.73-0.36-3.68-1.54
              c-1.73-0.77-2.96-1.75-2.18-3.99c0.29-0.83-0.2-1.8-1.15-1.89c-1.87-0.19-1.78-1.46-1.66-2.7c0.17-1.84-0.55-2.6-2.41-2.43
              c-0.88,0.08-1.84,0.23-2.61-0.47l0,0c-0.83,0-1.66,0-2.5-0.01c-0.71,0.73-1.64,0.43-2.49,0.51c-0.76,1.77-2.33,0.68-3.5,1l0,0
              c-0.54,1.42-1.85,1.31-3.01,1.49c-0.33,0-0.66,0-0.99,0l0,0l0,0c-1.06,0.06-1.56-0.44-1.5-1.5l0,0l0,0c-1.06-0.53-1-1.53-1.02-2.48
              l0,0c-2.62-0.87-3.15-2.1-1.69-4.27c0.52-0.77,0.45-2.02,1.7-2.24c0-0.33,0-0.67,0-1c-1.63,0.28-2.8-0.64-4-1.5l0,0
              c-0.33,0-0.66,0-1,0c-1.78-0.18-2.94,1.28-4.47,1.81c-1.37,0.47-2.65,0.67-4.03,0.69c-2.36,0.81-2.61-1.75-3.99-2.5l0,0l0,0
              c-1.19-0.87-1.73-2.02-1.51-3.5l0,0c-0.83,0-1.67,0-2.5,0c-0.63-0.34-0.2-1.04-0.5-1.5c-0.39-1.33-1.12-2.45-2-3.5
              c-1.2-0.41-1.93-1.94-3.49-1.51c0-1.83-0.02-3.66,0-5.49c0-0.82-0.12-1.73,1-2l0,0c0.43-1.45,2.16-1.98,2.51-3.49
              c0.49-0.51,0.99-1.01,1.5-1.5c0.33-0.34,0.66-0.68,0.99-1.02c0.66-0.33,1.01-0.98,1.51-1.48c1.14-0.52,1.99-1.34,2.5-2.5
              c0.33-0.01,0.66-0.01,0.99-0.02c0.17-0.15,0.34-0.3,0.52-0.45c1.61-0.86,3.28,0.37,4.9-0.14c1.2-0.25,2.41-0.52,3.58-0.9
              c0.14-1.55,2.38-0.44,2.52-1.99l0,0l0,0c1.6-1.21,3.56-1.89,4.95-3.42c0.69-0.77,1.65-0.53,2.53-0.58l0,0
              c0.15-2.26,2.57-1.33,3.51-2.49c0.54-1.25,1.24-1.98,2.51-0.74c0.42,0.41,0.9,0.69,1.5,0.74l0,0c0.33,0,0.65-0.01,0.98-0.01l0,0
              c0.1-0.9,0.59-1.41,1.5-1.49l0,0c0.13-0.89,0.78-1,1.51-0.99c0,0.33,0,0.66-0.01,0.99c0.05,1.06-0.44,1.56-1.5,1.5
              c-0.19,1.56-1.36,1.5-2.5,1.5c-0.89,0.66-1.83,1.17-3,1c-0.45,1.49-2.63,0.91-3,2.5c-0.74,1.26-2.03,1.64-3.24,2.15
              c-0.19,0.24-0.37,0.48-0.48,0.74c-2.32,1.92-5.01,3.26-7.54,4.86c-0.81,0.51-1.74,0.69-2.65,0.45c-1.84-0.51-3.43,0.12-5.03,0.89
              c-2.26,1.08-4.24,2.63-6.22,4.1c-1.84,1.37-3.34,3.22-2.95,5.94c0.12,0.85,0.08,1.9-0.84,2.42c0.85,1.23,2.75,0.98,3.45,2.43l0,0
              c2.03,1.12,2.78,3.66,4.88,4.59c1.46,0.65,1.75,1.57,1.62,2.91c0.76,0.47,1.76,0.77,2.22,1.44c0.79,1.14,1.31,0.91,2.36,0.32
              c1.66-0.93,3.4-1.83,5.42-1.76c0.33,0,0.66,0,1,0l0,0c0.84-1.35,1.76-2.05,3-0.4c0.09,0.12,0.26,0.22,0.41,0.27
              c2.71,0.95,3.49,2.17,1.99,5.19c-1.76,3.54,0.47,6.37,4.46,6.41c1.38,0.01,2.77-0.3,4.16-0.46l0,0c0.83,0,1.66,0,2.49-0.01l0,0
              c0.39-1.45,1.59-0.86,2.5-1c0.1-0.22,0.21-0.64,0.3-0.63c1.91,0.06,3.71,0.72,5.68,0.73c2.25,0.02,2.85,1.87,3.21,3.91
              c0.31,1.8,2.64,2.79,2.31,4.98c0,0.33,0,0.67,0,1c1.15,0.51,1.99,1.35,2.5,2.5c1.33,0,2.67,0,4,0c0.33,0,0.67,0,1,0
              c0,0.83,0,1.67,0,2.5c2.99,2.17,6.73,2.14,10.02,3.46C338.42,149,339.52,147.46,340.93,147.44z M294.37,88.46
              c0.01,0.02,0.02,0.04,0.03,0.07c0.01-0.02,0.02-0.04,0.03-0.06C294.41,88.46,294.39,88.46,294.37,88.46z M346.91,144.93
              c0.83-0.5,1.67-0.99,2.5-1.49C348.34,143.52,346.93,143.05,346.91,144.93z M347.42,109.95c0.48-1.79,3.1-0.59,3.5-2.5
              c0.33,0,0.66-0.01,0.99-0.01c0.17,0,0.34,0.01,0.51,0.01c1.23-0.08,2.75,0.5,3.63-0.29c1.5-1.36,3.15-1.08,4.85-1.23
              c1.96-0.17,2.8,0.57,2.53,2.53l0,0c-2.33,0.62-2.43,2.58-2.71,4.46c-0.1,0.66-1.93,0.83-0.71,1.81c0.82,0.66,1.67-0.03,2.33-0.77
              c0.24-0.27,0.72-0.34,1.09-0.5c1.64,0,3.28-0.01,4.93,0.01c0.97,0.01,2.12-0.16,2.43,1.12c0.3,1.21,0.41,2.5-0.96,3.25
              c-1.29,0.7-1.54,1.81-1.4,3.12c0,0.5,0,1-0.01,1.49l0,0l0,0l0,0c-1.99,0.17-3.21,1.9-4.99,2.5c-5.03,3.47-9.73,7.31-13.94,12.22
              c2.83,0.48,5,0.61,6.69-1.87c1-1.47,2.76-2.41,3.83-3.84c1.34-1.79,4.38-1.37,4.92-4.02c1.1-0.45,1.86-1.38,3.05-1.85
              c1.49-0.59,3.16-1.44,3.95-3.14l0,0c1.04-1.3,3.25-0.73,4-2.5c-0.83,0-1.67,0-2.5,0l0,0l0,0l0,0l0,0l0,0c0-0.5,0-0.99,0-1.49
              c1.71-0.74,2.97-1.99,4-3.51c1.88-1.07,1.13-2.88,0.77-4.17c-0.42-1.51-2.14-0.59-3.27-0.83c-0.17,0.33-0.33,0.67-0.5,1l0,0
              c-1.7,0.14-3.3,0.01-4.58-1.34c-0.54-0.57-1.18-0.72-1.41,0.34c-1.06-0.22-1.58,0.87-2.5,1.01l0,0l0,0c-1,0.01-1.58-0.33-1.08-1.43
              c0.32-0.71,0.72-1.39,1.08-2.08c0.91-0.09,1.41-0.59,1.5-1.5c1.67-1.01,1.02-2.27,0.44-3.58c-0.76-1.69-2.19-1.63-3.57-1.3
              c-1.16,0.28-2.24,0.91-3.36,1.39l0,0l0,0c-0.4,1.43-1.59,0.85-2.5,1c-0.67-0.03-1.18,0.3-1.74,0.63c-0.89,0.52-1.83,0.7-2.27-0.64
              c0.63-1.04,2.2-1.12,2.51-2.49l0,0c0.5-0.01,0.99-0.01,1.49-0.02l0,0c1.04-0.62,2.5-0.83,2.5-2.49c1.5-0.83,1.5-1.67,0-2.5l0,0
              c-0.15-0.89,0.38-2.5-1-2.32c-1.46,0.19-3.5,0.13-4.01,2.3l0,0c-2.18,0.41-4.5,0.49-6,2.51l0,0c-2.33-0.27-3.91,1.12-5.49,2.5
              c1.54-0.08,3.02,0.06,4.55-0.71c1.2-0.6,3.35-1.02,4.06,0.31c0.68,1.27-1.12,2.3-2.13,3.19c-0.55,0.48-1.25,0.79-1.87,1.2
              c-0.42,0.28-0.49,0.63-0.11,1.01c-0.17,0.33-0.33,0.67-0.5,1c-1.65-0.34-2.45,1.59-4,1.5c-2.57,1.62-5.14,3.24-8.25,5.2
              c2.54,0.25,4.01-0.77,5.63-1.21c1.33-0.37,2.56-0.81,2.61-2.49l0,0C345.42,109.96,346.42,109.95,347.42,109.95z M306.61,86.33
              c-1.53,1.15-3.63,2.18-3.69,4.64c-2.17,0.56-3.49,2.41-5.27,3.57c-2.3,1.5-2.1,4.93-4.43,6.57c-0.15,0.11-0.16,0.69-0.02,0.88
              c0.34,0.46,0.72,0.08,1.07-0.11c2.78-1.5,4.96-3.99,8.13-4.86c0.25-0.07,0.35-0.69,0.53-1.06c1.15-1.12,3.82,0.17,4-2.5l0,0l0,0
              l0,0c1.18,0.24,0.95,1.19,1,2c-0.33,0.33-0.67,0.67-1,1c-1.22,0.13-1.53,0.94-1.5,2l0,0c-2.2-0.59-3,1.19-4.12,2.43
              c-1.5,1.66-3.77,2.69-4.3,5.3c0.85-0.78,1.78-1.74,2.81-1.05c1.07,0.72,1.77,0.19,2.71-0.07c1.73-0.49,2.84-1.91,4.4-2.62
              c1.09-0.62,1.97-1.53,3.26-1.91c0.79-0.24,1.91-0.77,1.74-2.08l0,0c2.05,0.32,3.05-1.06,4-2.5c1.84-0.21,3.48-0.8,4.5-2.5
              c-0.83,0-1.66,0-2.49,0.01l0,0c-1.44-0.39-0.87-1.58-1.01-2.5c0,0-0.01,0-0.01-0.01c0,0,0,0,0.01,0v0.01
              c3.13-2.11,3.24-2.86,0.99-6.5c-0.89,0.12-1,0.77-1,1.49c-0.5,0-0.99,0.01-1.49,0.01c-0.84,0.71-2.38,0.84-2.12,2.47
              c-1.19-0.31-2.92,0.83-3.65-0.72c0.03,0,0.05-0.01,0.08-0.01c1.74-0.41,2.58-1.71,3.18-3.24l0,0c0.22-0.39,0.3-0.79,0.31-1.19
              c0.35-2-0.26-3.8-1.75-4.61c-1.02-0.55-2.22-1.1-2.55,0.8c-0.89,0.13-1,0.77-1,1.49v0.02C309.14,83.17,308.72,84.74,306.61,86.33z`}
        />
        <Path
          d={`M383.29,119.2c-0.9-1.18-1.97-1.79-3.38-0.76c-1.64-0.31-2.44,1.58-3.99,1.5c-1.4,0.91-3.37,0.55-4.48,2.07
              c-0.86,1.12-2.03,1.83-3.28,2.43c-1.39,0.66-2.86,1.22-3.72,2.63c-0.18,0.28-0.25,0.71-0.52,0.84c-3.8,1.74-6.56,4.74-9.38,7.68
              c-0.72,0.75-1.61,1.1-2.85,0.61c3.53-4.15,7.89-7.18,12.02-10.47c1.8-0.72,3.35-1.84,4.91-2.94c0.17-0.19,0.31-0.41,0.4-0.65
              c0.15-0.52,0.15-1.06,0.27-1.59c0.27-1.09,1.2-1.75,1.77-2.64c1.59-2.46,0.6-4.72-2.23-5.33c-1.67-0.36-3.27,0.1-4.91,0.13
              c-0.86-0.07-1.63,0.33-2.44,0.71c0.34-2.03,0.95-3.7,2.41-4.94c0.11-0.24,0.18-0.49,0.21-0.75c0.12-1.23-0.33-2.15-1.55-2.51
              c-1.9-0.56-4.02-0.67-5.55,0.42c-1.4,0.99-2.7,1.04-4.17,1.07c-0.28,0-0.56,0.01-0.85,0.01c-0.42,0.01-0.82,0.06-1.2,0.25
              c-0.71,0.54-1.47,0.96-2.33,1.21c-0.78,0.23-1.34,0.63-1.38,1.47c-0.68-0.51-1.47-0.54-2.33-0.29c-1.51,1.56-3.59,2.19-5.34,3.36
              c1.64-1.38,3.63-2.24,5.35-3.49c1.54-0.12,2.57-1.36,3.96-1.79c0.45-0.3,0.75-0.72,0.92-1.23c0.57-1.73,2.6-1.96,3.49-3.41
              c0.5-0.81,0.99-1.59,0.61-2.53c-0.41-1-1.34-0.99-2.29-1.1c-2.59-0.3-4.66,1.31-7.03,1.78c-0.5,0.02-1.14-0.17-1.46,0.08
              c-2.01,1.57-2.89-0.01-3.19-1.42c-0.65-3.03-2.79-2.12-4.61-2.02c-0.59,0.03-1.15,0.57-1.73,0.88c-2.42,1.31-3.19,0.93-3.26-1.61
              c-0.03-0.91,0.09-1.83-0.06-2.73c-0.49-2.93-2.42-4.21-4.69-3.18c-0.33,0.01-0.66,0.01-0.98,0.02c-1.04,1.31-2.92,1.27-4.01,2.48
              l0,0c-1.4,0.75-2.92,1.24-4.37,1.89c-0.21,0.09-0.41,0.21-0.59,0.35c-1.16,1.03-2.52,1.76-3.84,2.54c-1.15,2.01-3.82,1.76-5.15,3.5
              c-2.42,1.26-4.53,3.49-7.61,2.43c1.24-0.84,2.39-1.92,3.46-3.08c0.84-0.91,1.58-1.94,2.94-2.11c0.21-0.12,0.39-0.27,0.55-0.45
              c0.37-0.77,0.91-1.36,1.7-1.71c0.52-0.41,0.91-0.91,1.05-1.58c0.06-0.98-0.01-1.88-1.27-2.05c-1.43,1.04-3.23,1.37-4.64,2.46
              c-2.27,1.87-5.53,2.11-7.39,4.65c1.42-1.58,1.29-4.13,3.22-5.24c1.81-1.05,3.21-2.54,4.74-3.9c0.95-2.25,3.03-3.45,4.7-5.03
              c2.02-1.91,2.12-2.77,0.36-5.04c-0.12-0.04-0.23-0.08-0.35-0.13c-0.33,0-0.66,0.01-0.99,0.01c-0.23,0.23-0.46,0.46-0.64,0.74
              c-0.14,0.55-0.45,0.96-0.95,1.21c-0.17,0.14-0.33,0.29-0.47,0.46c-0.45,1.08-1.49,0.75-2.3,0.99c-0.85,0.66-1.89,0.82-2.86,1.16
              c-1.08,0.86-2.66,1.01-3.38,2.38c-0.91,1.28-2.49,1.8-3.41,3.06c-0.83-0.08-1.32,0.63-1.97,0.87c-3.22,1.16-5.35,5.58-9.74,3.3
              c-0.17-0.09-0.56,0.3-0.87,0.38c-2.92,0.78-5.16,2.7-7.61,4.33c-2.73,1.81-5.25,3.56-4.83,7.38c0.1,0.9,0.01,1.83,0.01,2.74
              c-0.04,2.17,2.29,1.8,3.18,2.97c1.4,1.33,2.3,3.14,3.92,4.23c1.22,0.82,2,1.91,2.57,3.21c0.26,0.32,0.55,0.62,0.88,0.88
              c1.48,1.18,2.69,2.27,4.93,0.75c1.13-0.76,2.76-1.89,4.55-1.54c0.38,0,0.77-0.01,1.15-0.09c2.12-1.44,3.26,0.62,4.71,1.38
              c1.37,0.73-0.1,1.54-0.18,2.31c-0.08,0.8-0.44,1.57-0.53,2.4c-0.36,3.17,1.54,5.6,4.72,5.65c1.78,0.03,3.65,0.04,5.3-0.9
              c0.76-0.13,1.58,0.22,2.3-0.27l0,0c0.76-0.79,1.75-0.38,2.64-0.52c1.72-0.41,3.34,0.49,5.08,0.31c1.75-0.18,2.75,0.94,3.01,2.56
              c0.19,1.21,0.64,2.23,1.45,3.08c0.73,0.77,1.12,1.62,1.11,2.66c0.01,0.38,0.12,0.74,0.32,1.07c0.93,1.1,1.84,2.22,3.16,2.9
              c1.23,0,2.45,0.1,3.68,0.08c0.21-0.05,0.39-0.14,0.56-0.25c-0.28,0.77-0.26,1.54,0.07,2.3c1.86,2.51,4.93,2.38,7.48,3.33
              c2.38,0.89,4.85,0.74,6.96-1.03c2.3-0.11,3.97-1.73,5.99-2.51c0.49-1.07,1.55-1.19,2.5-1.49c0.01-0.33,0.01-0.65,0.01-0.98
              c1.06-1.27,2.16-2.46,3.99-2.51c0.33,0,0.67-0.01,1-0.01c1.61-0.39,2.7,0.05,3.68,1.54c1.33,2,3.16,2.18,5.32,0.97l0,0
              c1.26-0.63,2.36-1.49,3.32-2.51c2.2-2.35,4.4-4.69,6.36-7.24c1.86-4.09,4.83-7.16,8.71-9.38c0.36-0.2,0.72-0.42,0.97-0.76
              C383.56,121.54,384.26,120.47,383.29,119.2z`}
        />
      </g>

      <g ref={ref.ref7}>
        <PathLight
          d={`M544.4,0c-2.6,3.15-5.83,4.75-10,4c-0.21,0.11-0.42,0.18-0.62,0.22c-2.09,0.66-4.26,0.05-6.38,0.27
              c-1.33,0-2.66,0-4,0c-0.02-0.02-0.04-0.04-0.06-0.06c0.02,0.02,0.05,0.04,0.06,0.06c-1.23-0.03-3.07,1.17-3-1.48
              c0.5,0,1,0,1.49-0.01c3.45,0.9,6.95,1.24,10.5,0.99c0.76-1.76,2.32-0.89,3.51-0.96c2.05-0.11,3.34-0.85,3.49-3.04
              C541.07,0,542.73,0,544.4,0z M468.91,25.49c-0.01,0.73,0.11,1.37,1,1.5c0.17,0,0.34-0.01,0.5-0.01
              C470.46,25.94,469.96,25.44,468.91,25.49z M466.42,22.98c0.06,0.93,0.57,1.42,1.49,1.51C467.97,23.44,467.46,22.94,466.42,22.98z
              M509.4,15.49L509.4,15.49c0-2.5,0-4.99,0-7.49c-0.14-1.47-1.29-2.63-1.14-4.22c0.06-0.69-0.75-0.74-1.34-0.78
              c0.11,0.92,1.21,1.45,0.98,2.5c0,0.5,0.01,0.99,0.01,1.49l0,0c0,2,0,4-0.01,5.99c0,0.74,0.12,1.63-0.68,2.01
              c-2.67,1.25-2.82,3.61-2.81,6.08c0,3.86-2.3,6.95-5.87,8.65c-1.26,0.6-2.64,0.43-3.75,1.09c-2.45,1.45-5.04,2.22-7.88,2.19l0,0
              c-0.72,0-1.37-0.1-1.5-1l0,0c-1.42-0.93-2.91-2.48-4.61-0.91c-1.45,1.34-2.53,0.65-3.77,0c-1.36-0.72-2.33-2.19-4.11-2.09
              c0,0.17-0.01,0.34-0.01,0.5c2.25,1.7,4.43,3.55,7.49,3.51c-0.01,1.45,0.97,1.5,2.04,1.49c2.16-0.02,4.31,0,6.47,0
              c0.77,0.48,1.48,1.14,2.5,1c1.28,1.73,4.83,2.05,6.51,0.77c1.75-1.33,2.75-3.53,4.99-4.27c0.86-0.1,1.84,0.3,2.45-0.73
              c1.46-2.46,2.94-4.95,1.3-7.87c-0.49-0.86-0.37-1.28,0.35-1.81C509.05,20.06,509.77,17.98,509.4,15.49L509.4,15.49z M514.4,2
              c-1.67-0.5-3.33-0.99-5-1.49C510.51,2.89,512.68,1.69,514.4,2z`}
        />
        <Path
          d={`M537.11,3.46c-1.64,0.11-3.3-0.05-4.83,0.74c-3.45,0.11-6.91,0.16-10.33-0.48c-0.64,0.03-1.12-0.3-1.55-0.72
              c-1.98-0.43-4.27,0.93-6-1.01c-1.64-0.57-3.7,0.25-5-1.49c-1.96-0.3-2.81,0.51-2.48,2.49c0.78,0.63,1.65,1.22,1.03,2.39
              c0.06,0.08,0.1,0.16,0.17,0.24c0.02,0.54,0.2,1.03,0.41,1.52c0.26,1.99,0.04,3.99,0.14,5.98c0.05,1.17-0.32,2.14-1.2,2.87
              c-1.67,1.39-2.27,3.27-2.28,5.32c-0.02,4.87-2.57,8.06-6.91,9.58c-3.63,1.27-7.12,3.32-11.22,2.84c-0.85,0.05-1.6-0.18-2.24-0.74
              c-1.11-1.26-2.13-1.9-3.98-0.88c-2.21,1.21-4-0.59-5.77-1.66c-0.67-0.41-1.05-1.31-2.02-1.24c-1-0.53-2.22-0.75-2.71-1.97
              c-0.35-0.68-0.88-1.21-1.42-1.74c-0.59-0.07-0.93-0.41-1-1c-0.46-0.54-0.97-1.03-1.49-1.51c-0.83-2.13-2.74-3.44-3.99-5.27
              c-2.67-3.91-4.28-8.43-7.07-12.24c-1.24-1.7-1.06-3.91-2.45-5.47c17.5,0,35,0,52.49,0c11.33,0,22.66,0,34,0
              C539.99,2.19,539.29,3.31,537.11,3.46z`}
        />
      </g>

      <g ref={ref.ref8}>
        <PathLight
          d={`M490.41,176.95c-0.01,0.99,0,1.98,0,2.97c-0.5,0-1,0-1.5,0c-3.67,0-7.33,0-11,0c-2.17,0-4.33,0-6.5,0
              c0.88-1.68,1.96-3.14,4-3.5c1.45-0.43,1.97-2.18,3.5-2.5c0.44,1.33,1.38,1.16,2.27,0.64c0.56-0.33,1.07-0.66,1.74-0.64l0,0
              c0.33-0.01,0.66-0.01,1-0.02c0.17,0.01,0.33,0.01,0.5,0.01l0,0l0,0l0,0c0.17-1.23,1.01-1.48,2.08-1.48c1.03,0,1.44,0.52,1.43,1.49
              l0,0l0,0C489.76,174.09,490.43,175.23,490.41,176.95z M427.41,179.92c0.5,0,1,0,1.5,0c0-1.16,0.01-2.32,0.01-3.49
              C427.73,177.3,427.2,178.45,427.41,179.92z M427.41,154.94c0.77,4.5,0.96,9.19,4,12.99c1.32-0.44,1.03-1.21,0.45-2.07
              c-1.2-1.79-2.49-3.56-1.95-5.93c-0.43-1.48,0.97-3.36-1-4.5c0-0.33,0-0.67,0-1c0-1,0-2,0-3c0-0.5,0-1,0-1.5
              c-1.56,0.2-1.5,1.37-1.5,2.5C425.91,153.27,425.91,154.1,427.41,154.94L427.41,154.94z M431.41,168.93
              c-0.47,2.51-1.36,4.93-1.51,7.5c1.39-1.01,1.82-2.94,3.51-3.69c0.18-0.08,0.31-0.78,0.25-0.81
              C432.45,171.27,432.81,169.43,431.41,168.93z M487.9,154.93L487.9,154.93c-2.07,0.83-4.16,1.63-4.99,4l0,0
              c-1.42,0.08-2.38,0.83-2.43,2.23c-0.06,1.57,1.25,1.67,2.43,1.77l0,0c1.36,1.83,0.96,3.95,1,6c-0.6,0.07-0.93,0.4-1,1
              c-1.05-0.06-1.55,0.44-1.5,1.5l0,0c-1.12,0.55-2.23,1.11-2.49,2.5c1.56-0.28,2.76-1.07,3.5-2.5c0.17-0.33,0.34-0.67,0.51-1l0,0l0,0
              c1.05,0.05,1.54-0.45,1.49-1.5l0,0c1.89-2,1.17-4,0-6c-0.09-1.44-0.03-2.85,1-4.01l0,0l0,0c0.13-1.99,2.41-2.36,3-3.99
              c0.33,0,0.67,0,1,0c0.41-0.5,0.41-1,0-1.5c-0.13-1.32,0.14-2.82-1.5-3.49C487.9,151.61,487.9,153.27,487.9,154.93z M478.91,152.43
              c0.39-1.57,2.84-0.65,3-2.5c2-1.35,4-1.35,6,0c-0.79-2.45-1.79-3.07-3.9-2.02c-1.69,0.84-2.07,0.19-2.1-1.32
              c-0.02-1.15,0.14-2.41-1.5-2.66c-0.41-0.88-1.48-1.37-1.5-2.49c-1.22-0.36-2.49-0.63-3.49-1.5c0-0.17,0-0.34-0.01-0.51
              c-0.86,0.41-1.66,0.87-1.42,2.03c0.24,1.18,1.21,0.97,1.99,0.96c2.08-0.03,2.64,1.43,2.89,3.03c0.17,1.14,0.04,2.32,0.04,3.48
              c-0.07,0.6-0.41,0.93-1,1l0,0l0,0c-0.87,0.1-1.79-0.23-2.49,0.71c-1.22,1.66-2.71,2.95-4.74,3.67c-0.92,0.33-1.85,1.17-1.76,2.48
              c0.04,0.58-0.02,1.2,0.57,1.55c0.84,0.5,0.9-0.39,1.26-0.74c0.53-0.52,1.14-0.86,1.8-1.22
              C474.74,155.19,476.8,153.77,478.91,152.43z`}
        />
        <Path
          d={`M467.92,134.96c0.33-0.01,0.66-0.02,0.99-0.02c0.6-0.56,1.16-1.19,2.06-1.25c3.52-1.02,5.22,0.23,5.18,3.81
              c-0.01,0.57,0,1.15-0.12,1.71c-0.75,1.27-1.71,2.4,0.99,2.64c1.71,0.15,2.53,1.75,2.62,3.47c0.07,1.24,0.07,2.5-0.05,3.74
              c-0.12,0.7-0.52,1.17-1.15,1.46c-1.25,0.09-2.27,0.42-3.1,1.59c-0.84,1.18-2.05,2.13-3.44,2.76c-0.74,0.34-1.47,0.74-1.91,2.09
              c2.88-2.01,5.82-3.12,8.17-5.22c0.95-1.2,2.81-1.05,3.63-2.45c4.11-1.57,4.23-1.56,6.11,0.65l-0.01,0.01
              c0.83,1.39,0.93,2.87,0.56,4.41c-1.44,1.76-3.54,2.82-4.99,4.58c-0.13,0.19-0.3,0.36-0.48,0.51c-2.21,1.71-2.19,1.78,0.55,3.51
              c1.58,1.68,1.29,3.63,0.83,5.6c-0.31,0.53-0.6,1.08-1.13,1.42c-0.76,0.34-1.22,0.95-1.53,1.7c-0.93,0.76-1.92,1.43-2.79,2.26l0,0
              c-0.79,1.36-1.61,2.7-3.25,3.19c-1.8,0.35-2.65,2.14-4.25,2.8c-14.16,0-28.33,0-42.49,0c-1.16-1.17-0.45-2.33,0.01-3.49
              c0.33,0,0.66-0.01,0.98-0.01c1.27-2.34,3.23-4.55,1.51-7.5c0-0.33,0-0.67,0-1c0.3-0.45,0.38-0.98,0.03-1.38
              c-1.68-1.92-2.26-4.19-2.23-6.67c-0.15-1.48,0.22-3.05-0.86-4.35c-0.17-0.38-0.19-0.78-0.19-1.19c0-0.97-0.03-1.94,0.03-2.91
              c0.04-0.59,0.32-1.07,0.71-1.49c0.42-1.91,2.25-2.41,3.48-3.51c0.52-0.48,1.02-0.99,1.51-1.5c0.33-0.33,0.67-0.66,1-1
              c0.69-0.31,1.03-0.96,1.51-1.48c1.15-0.48,2.01-1.28,2.86-2.21c2.03-2.19,4.79-3.47,7.12-5.3c3.51-2.46,4.67-2.22,6.45,1.74
              c0.69,1.53,1.36,1.62,2.55,0.76c1.53-0.33,2.05-2.07,3.5-2.5c0.4-0.39,0.92-0.52,1.44-0.65c1.05-0.12,2.1-0.16,3.14,0.05
              c0.23,0.09,0.44,0.22,0.63,0.37c0.63,1.68,1.51,2.28,2.89,0.64C467.27,135.14,467.58,135.01,467.92,134.96z`}
        />
      </g>

      <g ref={ref.ref9}>
        <PathLight
          d={`M588.38,31.98c0.26-2.48,2.85-1.15,3.97-2.39c0.48-0.53,1.67-0.42,2.54-0.6c0,0.5,0,1,0,1.5
              C592.65,30.65,590.84,32.71,588.38,31.98z M540.9,139.94c-0.58-1.57-2.83-2.29-2.65-4.03c0.23-2.24-0.75-4.03-1.45-5.9
              c-0.65-1.7-1.35-3.17-0.03-4.83c0.19-0.24,0.14-0.92-0.07-1.18c-1.23-1.55-0.67-3.35-0.8-5.05c-0.99-0.39-0.97,0.7-1.49,1.01
              c0.05,3.69-1.26,7.21-1.15,10.97c0.09,2.92,1.07,4.84,3.25,6.45c0.66,0.48,1.26,1.04,1.89,1.57
              C539.32,139.07,539.85,140.16,540.9,139.94z M573.4,135.94L573.4,135.94c1.44-2.12,1.79-4.7,2.96-6.95
              c0.44-0.85-0.1-1.68-0.98-2.26c-1.34-0.89-2.69-1.8-3.48-3.29c-1.63-0.16-2.63,0.6-3.21,2.28c1.48,0.1,2.03,0.81,2.44,2.23
              c0.65,2.28-0.69,4.26-0.45,6.43c0.03,0.31-0.71,0.65-0.97,1.05c-1.25,1.89-3.67,2.5-4.81,4.49c2.27-0.19,4.37-0.76,6-2.49
              C571.66,136.82,572.64,136.57,573.4,135.94z M577.72,131.62c1.4-2.51,0.84-3.82-1.46-5.35c-1.49-0.99-3.45-1.68-3.86-3.82
              c-0.17,0.33-0.34,0.66-0.51,1c-0.36,0.53-1.32,0.93-0.05,1.64c1.13,0.63,2.06,1.62,3.16,2.31c0.94,0.59,1,1.13,0.39,1.98
              c-1.41,1.95-2.17,4.12-2,6.57C575.78,135.45,576.71,133.44,577.72,131.62z M526.9,93.47c-0.16-1.64-2.46-1.94-2.41-3.56
              c0.09-2.58-0.46-5.01-1.07-7.47c-0.39-1.58-0.67-3.19-0.11-4.86c0.32-0.96-0.14-1.99-0.72-2.8c-0.52-0.72-0.74-1.45-0.69-2.3
              c-1.62,0.35-2.43,2.21-1.63,4.02c0.43,0.98,0.96,1.91,0.88,3.03c0.03,3.9,0.44,7.74,1.75,11.45c-0.06,1.17,1.41,1.41,1.51,2.49
              C525.23,94.11,526.06,94.22,526.9,93.47z M529.45,96.79c-0.11-1.78-1.02-2.29-2.54-2.33c-0.04,0-0.09,0.06-0.13,0.17
              s0,0.22,0.12,0.34c0,1.08,0.14,2.18-0.02,3.23c-0.32,2.03,0.82,3.13,2.38,3.85c1.32,0.62,2.76,0.95,4.15,1.41
              C532,101.28,529.61,99.62,529.45,96.79z M521.9,71.97c-2.47,0.97-3.74,3.98-2.32,5.98c0.93,1.31,0.77,2.64,0.82,4.02
              c1.76-0.08,0.48-1.98,1.5-2.5c-0.15-1.1,0.43-2.36-0.49-3.28c-1.5-1.5-0.9-2.64,0.49-3.71C521.9,72.3,521.9,72.13,521.9,71.97z
              M539.4,60.48c1.03-0.56,1.53-1.4,1.5-2.59c-0.04-1.47-0.01-2.94-0.01-4.41c-1.34,0.03-2.08,0.81-2.5,2
              C536.7,57.55,538.71,58.88,539.4,60.48z M533.4,103.46c2.38,2.17,7.18,2.17,10,0C540.07,103.46,536.73,103.46,533.4,103.46z
              M537.02,58.14c0.96,1.48,0.37,2.57-0.13,3.82c0.84-0.5,1.67-0.99,2.51-1.49c0.44-1.82-1.44-3.18-1-5
              C536.36,55.55,536.55,57.4,537.02,58.14z M545.9,49.49c-0.5,0-1,0-1.5,0c-0.13,0.91-1.21,1.44-1.01,2.48
              C544.44,51.35,545.88,51.14,545.9,49.49z M535.91,117.46c0.04,0,0.08-0.06,0.13-0.17c0.04-0.11,0-0.23-0.13-0.34
              c-1.53,0.49-1.6,1.71-1.49,3.01c0.71-0.03,1.01-0.64,1.49-1.01C535.9,118.45,535.9,117.96,535.91,117.46z M526.9,93.47
              c-0.83,0-1.66-0.01-2.49-0.01c0.32,1.35,1.4,1.43,2.49,1.51c0-0.17,0-0.34,0.01-0.51C526.9,94.13,526.9,93.8,526.9,93.47z
              M539.41,113.46c-1.16,0.5-1.99,1.34-2.5,2.5C538.45,115.83,539.28,115,539.41,113.46z M536.9,115.96c-0.6,0.06-0.93,0.39-1,0.99
              c0,0.17,0,0.34,0,0.51C536.79,117.33,536.91,116.68,536.9,115.96z M519.4,84.46c-1.35,1.63-0.57,3.52-0.65,5.31
              c0.11,0.46,0.35,0.84,0.66,1.19l0,0c1.16,0,2.33,0.01,3.49,0.01c0.44-0.76,0.18-1.56,0.21-2.35c0.14-3.14-1.59-6-1.21-9.15
              c-1.49,0.24-0.62,1.89-1.5,2.5C518.97,82.36,519.54,83.55,519.4,84.46z M517.91,85.99c0.02,1.16,0,2.32,0,3.48
              c0.38,0.53,0.75,1.09,1.5,0.5c0-1.83-0.01-3.67-0.01-5.5C518.37,84.45,517.89,84.96,517.91,85.99z M519.41,90.96c0-0.33,0-0.66,0-1
              c-0.5-0.17-1-0.33-1.5-0.5C517.99,90.38,518.49,90.88,519.41,90.96z M573.4,135.94c-1.09,0.07-2.17,0.16-2.5,1.5
              C572.08,137.53,573.24,137.57,573.4,135.94z`}
        />
        <Path
          d={`M594.89,30.49c0,26.16,0,52.31,0,78.47c-1.05,2.07-3.18,2.84-4.95,3.99c-5.2,3.36-10.52,6.55-15.67,10
              c-0.35,0.23-0.77,0.29-1.18,0.37c-0.44,0.18-0.79,0.51-1.22,0.7c-0.57,0.38-2.1-0.04-1,1.62c2.59,3.9,1.48,9.47-2.39,12.23
              c-1.34,0.95-2.62,1.98-3.99,2.9c-2.72,1.5-5.64,1.85-8.7,1.74c-3.33-0.12-6.66-0.02-10-0.03c-1.9-0.01-3.63-0.44-5.08-1.73
              c-4.01-3.28-4.33-8.31-5.35-12.73c-0.62-2.68-0.35-5.86-0.22-8.81c0-0.5-0.02-1,0.14-1.49c0.25-0.68,0.84-1.15,1.14-1.8
              c0.14-0.19,0.31-0.35,0.49-0.49c1.07-0.51,1.67-1.46,2.23-2.44c4.39-4.24,9.64-7.23,14.56-10.16c-1.77,0.71-8.24,1.67-10.82,1.54
              c-2.92-0.36-5.86,0.01-8.78-0.2c-0.27-0.02-0.52-0.09-0.77-0.2c-2.37-1.88-4.29-4.1-4.92-7.16c-0.23-1.12-1.09-1.57-1.87-2.16
              c-0.26-0.31-0.43-0.66-0.52-1.05c-2.06-1.86-2.71-4.17-2.42-6.88c0.18-1.65-1.08-2.99-1.1-4.73c-0.02-1.45-0.48-2.87,0.05-4.35
              c0.29-0.83-0.14-1.65-0.67-2.3c-0.63-0.77-0.73-1.63-0.74-2.55c0-0.26,0.01-0.52,0.02-0.78c0.16-1.56,1.26-2.54,2.36-3.37
              c3.98-3,8.2-5.63,12.87-7.47c1.03-0.27,1.87-0.92,2.77-1.46c0.71-0.53,1.05-1.15,0.84-2.11c-0.31-1.38-0.01-2.76,0.24-4.13
              c0.36-1.04,1.09-1.72,2.09-2.13c0.24-0.06,0.48-0.1,0.71-0.15c0.92-0.49,1.95-0.83,2.38-1.93c0.15-0.2,0.33-0.37,0.53-0.52
              c2.19-2.23,5.31-2.62,7.96-3.93c4.89-2.41,10.13-4.09,15.08-6.4c4.2-1.96,8.5-3.7,12.76-5.52c2.12-0.91,4.25-1.88,6.66-1.67
              c1.38,0.01,2.74,0.18,3.97-0.82C593.11,29.83,594.09,29.73,594.89,30.49z`}
        />
      </g>
    </SVGComponent>
  );
});

export default BrushStrokes1;
