import React from 'react';
import { ButtonNew } from '@matterapp/matter-ui';
import withRouter from 'hocs/withRouter';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';

const withNextStep = compose(
  withRouter,
  withProps(
    ({
      history,
      nextStepPath,
      onClick,
      pristine,
      submitting,
      invalid,
      disabled,
    }) => ({
      onClick: (e) => {
        if (onClick) {
          onClick(e);
        } else {
          history.push(nextStepPath);
        }
      },
      disabled: disabled || pristine || submitting || invalid,
      loading: submitting,
    })
  )
);

const NextStepButtonCore = ({
  children,
  notification,
  disabled,
  onClick,
  loading,
  icon,
  type,
  fluid,
  link,
  size,
  marginBottom,
  as,
  className,
  iconName,
  ...rest
}) => {
  const ButtonComponent = as;
  return (
    <ButtonComponent
      {...rest}
      notification={notification}
      primary={!link && !notification}
      size={size}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      type={type}
      fluid={fluid}
      link={link}
      icon={icon ? 'arrow' : iconName}
      marginBottom={marginBottom}
      className={className}
    >
      {children}
    </ButtonComponent>
  );
};

NextStepButtonCore.defaultProps = {
  onClick: () => {},
  notification: false,
  disabled: false,
  loading: false,
  icon: false,
  iconName: null,
  fluid: false,
  link: false,
  type: 'button',
  size: 'small',
  children: null,
  marginBottom: 0,
  as: ButtonNew,
  className: null,
};

NextStepButtonCore.propTypes = {
  children: PropTypes.node,
  notification: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.bool,
  iconName: PropTypes.string,
  fluid: PropTypes.bool,
  type: PropTypes.string,
  link: PropTypes.bool,
  size: PropTypes.string,
  marginBottom: PropTypes.number,
  as: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  className: PropTypes.string,
};

const WrappedNextStepButton = withNextStep(NextStepButtonCore);

WrappedNextStepButton.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
};

WrappedNextStepButton.defaultProps = {
  pristine: false,
  submitting: false,
  invalid: false,
  disabled: false,
};

export default WrappedNextStepButton;
