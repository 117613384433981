import { useState } from 'react';
import Toast from 'components/Toast';

const noop = () => null;

export const ALL_WORKSPACES_ADMIN_VALUE = 'All Channel Admins';
export const DEFAULT_UPGRADE_MESSAGE = 'Hey - Let\'s upgrade to the Pro plan to continue recognizing people with kudos! What do you think?';
export const SUCCESS_SENT_SUGGESTION = 'You successfully sent a suggestion to upgrade';
export const DEFAULT_REWARDS_MESSAGE = 'Hello -- What do you think of enabling Rewards? I believe it will help increase our Feedback Friday participation, which means improving our culture of team recognition and feedback!';
export const DEFAULT_SURVEY_MESSAGE = "Hey - Let's upgrade to Matter Pro with the Surveys add-on which includes Pulse + eNPS Surveys, Custom Surveys, and 30+ science-backed customizable survey templates.";
export const SUCCESS_SENT_REWARDS = 'You successfully sent a suggestion for Rewards';

export const filterAdminMembers = (members = []) =>
  members.filter((member) => member.isAdmin || member.isOwner);

export const formatAdminMembersForDropdown = (members) => [
  {
    label: ALL_WORKSPACES_ADMIN_VALUE,
    value: ALL_WORKSPACES_ADMIN_VALUE,
  },
  ...members.map((member) => {
    const { person } = member;
    return {
      ...members,
      ...person,
      label: person.fullName,
      subLabel: person.email,
      value: member.id,
    };
  }),
];

export const useDropdown = (params) => {
  const { adminMembers, ...otherProps } = params;

  return {
    ...otherProps,
    items: formatAdminMembersForDropdown(adminMembers),
  };
};

export const useModal = (params) => {
  const {
    isOpen,
    onClose = noop,
    onClickSend = noop,
    isRewards,
    isSurveys,
    adminMembers,
    ...otherParams
  } = params;
  let defaultMessage = DEFAULT_UPGRADE_MESSAGE;

  if (isRewards) {  
    defaultMessage = DEFAULT_REWARDS_MESSAGE;
  } else if (isSurveys) {
    defaultMessage = DEFAULT_SURVEY_MESSAGE;
  }
  
  const [message, setMessage] = useState(defaultMessage);
  const [selectedAdmin, setSelectedAdmin] = useState(
    ALL_WORKSPACES_ADMIN_VALUE
  );

  const isSingleAdmin = adminMembers.length === 1;
  const firstAdminMember = adminMembers[0] || { person: {} };
  const resetMessage = () => setMessage(defaultMessage);
  const onClickClose = () => {
    onClose();
    resetMessage();
    setSelectedAdmin(ALL_WORKSPACES_ADMIN_VALUE);
  };
  return {
    ...otherParams,
    adminItemProps: {
      peer: firstAdminMember.person,
    },
    header: isRewards ? 'Request for Rewards' : 'Request to Upgrade',
    adminMembers,
    firstAdminMember,
    isSingleAdmin,
    dropdownProps: {
      adminMembers,
      onChange: (e, props) => setSelectedAdmin(props.value),
      onSelect: (e, props) => setSelectedAdmin(props.value),
      value: selectedAdmin,
    },
    modalProps: {
      isOpen,
      onClose: onClickClose,
      canClickConfirm: message.length > 3,
      onClickCancel: onClickClose,
      onClickConfirm: async (e) => {
        onClickSend(e, {
          ...params,
          message,
          isSendToAllAdmins: selectedAdmin === ALL_WORKSPACES_ADMIN_VALUE,
          workspaceAdminId: isSingleAdmin
            ? firstAdminMember.id
            : selectedAdmin,
        });
        Toast.success(isRewards ? SUCCESS_SENT_REWARDS : SUCCESS_SENT_SUGGESTION);
        onClickClose();
      },
    },
    messageProps: {
      onChange: (e, props) => setMessage(props.value),
      value: message,
    },
    resetMessage
  };
};
