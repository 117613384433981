import omitStyled from '../../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

const Container = omitStyled('div', ['centered', 'progress'])`
  width: 100%;
  ${({ centered }) => (
    centered && `
      text-align: center;
      margin: 0 auto;
    `
  )}
  padding: 20px 20px 16px 20px;
  font-size: 17px;
  line-height: 24px;
  ${() => theme.media.medium`
    padding: 24px 24px 16px 24px;
  `}

  ${({ progress }) => progress && `
    padding-top: 16px;
    padding-bottom: 24px;
  `}
`;

export default Container;
