import React from 'react';
import { CheckboxPill, ListContainer } from '@matterapp/matter-ui';
import { Field as ReduxFormField } from 'redux-form';
import { LATEST_PERFORMANCE_RATING_OPTIONS } from '../../app-consts';

class LatestPerformanceRatingRadios extends React.Component {
  renderRadio = (reduxFormFieldProps) => {
    const { label, input, type } = reduxFormFieldProps;
    return (
      <div>
        <CheckboxPill fluid label={label} type={type} size="large" {...input} />
      </div>
    );
  };

  renderRadios = () =>
    LATEST_PERFORMANCE_RATING_OPTIONS.map((latestPerformanceRating) => (
      <ReduxFormField
        key={latestPerformanceRating.value}
        name="latestPerformanceRating"
        component={this.renderRadio}
        type="radio"
        value={latestPerformanceRating.value}
        label={latestPerformanceRating.label}
      />
    ));

  render = () => (
    <ListContainer stacked animated>
      {this.renderRadios()}
    </ListContainer>
  );
}

export default LatestPerformanceRatingRadios;
