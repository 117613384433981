import React from 'react';
import styled from 'styled-components';
import { Loader, Panel } from '@matterapp/matter-ui';

const LoaderContainer = styled(Panel.Base)`
  min-height: 160px;
  & > div {
    background: transparent !important;
  }
`;

const LoadingPanel = (props) => (
  <LoaderContainer {...props}>
    <Loader />
  </LoaderContainer>
);

export default LoadingPanel;
