import { withStateHandlers, compose, withProps } from 'recompose';
import SkillGeneratorModal from '../components/SkillGeneratorModal';
import { SKILL_GENERATOR_MODES } from '../consts';

const initialSkillGeneratorState = {
  skillGeneratorState: {
    mode: SKILL_GENERATOR_MODES.PICK_ADD_METHOD_MODE,
    isModalOpen: false,
    skillToEdit: null,
  },
};

const withSkillGeneratorState = withStateHandlers(
  () => initialSkillGeneratorState,
  {
    _handleStartAddSkill: (state) => () => ({
      skillGeneratorState: {
        ...state.skillGeneratorState,
        mode: SKILL_GENERATOR_MODES.PICK_ADD_METHOD_MODE,
        isModalOpen: true,
        skillToEdit: null,
      },
    }),
    _handlePickRecommendedSkillsMode: (state) => () => ({
      skillGeneratorState: {
        ...state.skillGeneratorState,
        mode: SKILL_GENERATOR_MODES.RECOMMENDED_SKILLS_MODE,
      },
    }),
    _handlePickCreateCustomSkillMode: (state) => () => ({
      skillGeneratorState: {
        ...state.skillGeneratorState,
        mode: SKILL_GENERATOR_MODES.CREATE_CUSTOM_SKILL_MODE,
        isModalOpen: true,
      },
    }),
    _handleStartEditSkill: (state) => (skillToEdit) => ({
      skillGeneratorState: {
        ...state.skillGeneratorState,
        mode: SKILL_GENERATOR_MODES.EDIT_CUSTOM_SKILL_MODE,
        isModalOpen: true,
        skillToEdit,
      },
    }),
    _handleCloseGeneratorModal: (state) => () => ({
      skillGeneratorState: {
        ...state.skillGeneratorState,
        isModalOpen: false,
      },
    }),
  }
);

const withData = compose(
  withSkillGeneratorState,
  withProps(
    ({
      _handleCloseGeneratorModal,
      _handleStartAddSkill,
      _handlePickCreateCustomSkillMode,
      _handlePickRecommendedSkillsMode,
      _handleStartEditSkill,
    }) => ({
      handleDismissGeneratorModal: _handleCloseGeneratorModal,
      handleClickBackFromAddSkill: _handleStartAddSkill,
      handlePickCreateCustomSkillMode: _handlePickCreateCustomSkillMode,
      handleClickRecommendedSkills: _handlePickRecommendedSkillsMode,
      handleStartAddSkill: _handleStartAddSkill,
      handleStartEditSkill: _handleStartEditSkill,
    })
  )
);

export default withData(SkillGeneratorModal);
