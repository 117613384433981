import React from 'react';
import PropTypes from 'prop-types';

class WindowPopOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWindowOpen: false,
    };
    this.openedWindow = null;
    this.windowCheckerInterval = null;
  }

  getWindowSettings = () => {
    const {
      width,
      height,
      top,
      left,
    } = this.props;
    let windowSettings = 'resizable,scrollbars,status,dependent,menubar=false,toolbar=false,location=false,personalbar=false';
    windowSettings += `,width=${width}`;
    windowSettings += `,height=${height}`;
    windowSettings += `,top=${top}`;
    windowSettings += `,left=${left}`;
    return windowSettings;
  }

  release = () => {
    if (!this.state.isWindowOpen) { return; }
    const { onUnload } = this.props;
    this.openedWindow = null;
    clearInterval(this.windowCheckerInterval);
    this.setState({
      isWindowOpen: false,
    });
    onUnload();
  }

  handleOpenWindow = () => {
    const {
      url,
      title,
    } = this.props;
    if (this.state.isWindowOpen) { return; }
    this.setState({
      isWindowOpen: true,
    });
    this.openedWindow = window.open(
      url,
      title || url,
      this.getWindowSettings()
    );
    this.windowCheckerInterval = setInterval(() => {
      if (!this.openedWindow || this.openedWindow.closed) {
        this.release();
      }
    }, 50);
  }

  render() {
    return this.props.renderTrigger({
      handleOpenWindow: this.handleOpenWindow,
      isWindowOpen: this.state.isWindowOpen,
    });
  }
}

WindowPopOut.defaultProps = {
  title: '',
  width: 540,
  height: 254,
  left: 200,
  top: 200,
  onUnload: () => null,
};

WindowPopOut.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  left: PropTypes.number,
  top: PropTypes.number,
  onUnload: PropTypes.func,
  renderTrigger: PropTypes.func.isRequired,
};

export default WindowPopOut;
