import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StepContainer from './StepContainer';
import { ListContainer } from '@matterapp/matter-ui';
import { Button } from '@matterapp/matter-ui';
import ToggleList from './ToggleList';
import theme from '@matterapp/matter-theme';

const STEP_HEADER =
  'Select 3 of my best qualities that come to mind when you think of me:';
const MAX_AMOUNT_OF_SELECTED_QUALITIES = 3;

export const BUTTON_COMPONENT = styled(Button.Simple.Toggle).attrs({
  bold: true,
})`
  font-size: ${theme.fontSize.base}
  font-weight: ${theme.fontWeight.medium};
  margin: ${theme.spacing.quarter};
  min-height: ${theme.spacing.doubleAndHalf}
  padding: ${theme.spacing.half} ${theme.spacing.single};
  ${theme.media.S`
    font-size: ${theme.fontSize.S};
    font-weight: ${theme.fontWeight.regular};
    min-height: ${theme.spacing.triple}
  `}
`;

export default class Qualities extends React.PureComponent {
  static propTypes = {
    /** The max amount of qualities that can be chosen. */
    maxAmount: PropTypes.number,
    /** Callback when selected qualities changes. */
    onChange: PropTypes.func,
    /** Function to continue to next step automatically if `maxAmount` of qualities are selected. */
    onNextStep: PropTypes.func,
    /** List of qualities to display. */
    qualities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    currentQualities: PropTypes.arrayOf(PropTypes.string),
    header: PropTypes.node,
  };

  static defaultProps = {
    currentQualities: [],
    header: STEP_HEADER,
    maxAmount: MAX_AMOUNT_OF_SELECTED_QUALITIES,
    qualities: [],
  };

  /**
   * Callback when the amount of selected qualities changes.
   * @param { Object } e: The change event.
   * @param { Object } listProps: Prop passed from the list component with the updated selected qualities.
   * @returns { void }
   */
  handleChange = (e, listProps) => {
    const { value } = listProps;
    const { maxAmount, onChange, onNextStep } = this.props;
    const hasMaxSelectedValues = value && value.length === maxAmount;
    onChange(e, listProps);

    if (hasMaxSelectedValues && onNextStep) {
      onNextStep(e, this.props);
    }
  };

  render() {
    const { currentQualities, header, maxAmount, qualities } = this.props;
    return (
      <StepContainer header={header}>
        <ListContainer alignLeft animated>
          <ToggleList
            ButtonComponent={BUTTON_COMPONENT}
            buttonProps={{ buttonType: Button.Simple.buttonTypes.BUBBLE }}
            items={qualities}
            onChange={this.handleChange}
            maxAmount={maxAmount}
            value={currentQualities}
            valueName="currentQualities"
          />
        </ListContainer>
      </StepContainer>
    );
  }
}
