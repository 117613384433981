import React from 'react';
import PropTypes from 'prop-types';
import { Confirm } from '@matterapp/matter-ui';
import { MEMBERS_PANEL_TRANSFER_OWNERSHIP_MODAL_TEXT, TextReplace } from '../sharedConsts';
import { memberDefaultProp, memberPropType } from 'libs/prop-types/workspaces';

const RemoveMemberModal = (props) => {
  const { isOpen, member, onClose, onClickCancel, onClickConfirm } = props;
  
  if (!member) {
    return null;
  }

  const { email, fullName } = member.person;
  return (
    <Confirm.Simple
      header={MEMBERS_PANEL_TRANSFER_OWNERSHIP_MODAL_TEXT.HEADER}
      isOpen={isOpen}
      onClickConfirm={(e) => onClickConfirm(e, props)}
      onClickCancel={onClickCancel}
      onClose={onClose}
      subHeader={
        <TextReplace
          text={MEMBERS_PANEL_TRANSFER_OWNERSHIP_MODAL_TEXT.SUB_HEADER}
          replaceText={fullName || email}
          replaceTextElement={'b'}
        />
      }
      confirmButtonIsDestructive
      confirmButtonLabel={MEMBERS_PANEL_TRANSFER_OWNERSHIP_MODAL_TEXT.CONFIRM_BUTTON}
      cancelButtonLabel={MEMBERS_PANEL_TRANSFER_OWNERSHIP_MODAL_TEXT.CANCEL_BUTTON}
    />
  );
};

RemoveMemberModal.defaultProps = {
  member: memberDefaultProp,
};

RemoveMemberModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  member: memberPropType,
  onClickCancel: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
};

export default RemoveMemberModal;
