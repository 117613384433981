import styled from 'styled-components';
import { 
  Box,
  Paper
} from '@mui/material';

export const LineItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  padding: 0,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    p: {
      alignSelf: 'flex-start'
    }
  }
}));

export const LineItemValues = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '550px',
  height: '40px',
  backgroundColor: theme.palette.purples[5],
  borderRadius: '4px',
  padding: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: theme.spacing(1),
    height: 'auto',
    minHeight: '40px',
    flexWrap: 'nowrap',
    p: {
      width: '95%'
    }
  }
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  svg: {
    width: '16px',
    height: '16px',
    fill: theme.palette.blacks[60],
    ':hover': {
      fill: '#212121'
    },
    ':active': {
      fill: theme.palette.purples[50]
    }
  }
}));

export const AchWrapper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    borderRadius: 0
  }
}));
