import React, { useEffect } from 'react';
import { ListWithHeader } from '@matterapp/matter-ui';
import { useMarkNotificationsAsViewedMutation } from 'hooks/useRemoteNotifications';
import PropTypes from 'prop-types';

/**
 * Notifications need to be marked as viewed, but only after
 * they are rendered. This marking function should only be called once.
 * Before, we did it on NotificationsPage's componentDidMount.
 * However back then, we did not have the requirement that the function
 * be called only after the first render.
 */
const StickyNotificationsList = (props) => {
  const { mutation } = useMarkNotificationsAsViewedMutation();
  const { items } = props;

  useEffect(() => {
    const notViewedIds = items
      .filter(({ isViewed }) => !isViewed)
      .map(({ id }) => id);

    if (notViewedIds.length > 0) {
      mutation({
        variables: {
          ids: notViewedIds
        },
      });
    }
  }, []);

  return (
    <ListWithHeader
      {...props}
      header="Activity"
      headerType="neutral"
      rounded={false}
      showAllItemBorders
    />
  );
};


StickyNotificationsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isViewed: PropTypes.bool,
    })
  ).isRequired,
};
export default StickyNotificationsList;
