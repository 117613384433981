import React from 'react';
import styled from 'styled-components';
import Chart from './Chart/Chart';
import { 
  Typography
} from '@mui/material';
import theme from '@matterapp/matter-theme';
import CustomCircularProgress from 'components/CircularProgress/CircularProgress';
import { formatAveragesToDataPoints } from './dateFilters';
import { ChartsWrapper, ChartWrapper, ProgressWrapper, CategoryPaper } from './styles';

const GroupedByTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.half};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.XS};
  line-height: ${theme.lineHeight.XS};
  color: ${theme.colors.blacks[50]};
`;

export default function EngagementScore({ 
  analyticsData,
  xAxisMaxLabels, 
  groupedBy,
  groupBy,
  rollingScore = [], 
  startDate, 
  endDate,
  datePoints,
  isLoadingData
}) {
  const { 
    engagementScore,
    engagementExplanation = ''
  } = analyticsData || {};
  const hasEngagementScore = engagementScore != null;
  const data = formatAveragesToDataPoints(rollingScore, startDate, endDate, groupBy);

  return (
    <CategoryPaper elevation={1}>
      <Typography variant='h4' component='p' sx={{mb: '5px' }}>Engagement Score</Typography>
      <Typography variant='body1' component='p' sx={{ color: 'blacks.50' }}>
        Your engagement score reveals invaluable insights into the overall health of your organization&apos;s culture and the extent to which your employees are engaged and actively involved.
      </Typography>
      <ChartsWrapper>
        <ProgressWrapper>
          <CustomCircularProgress 
            thickness={2}
            size={264}
            value={engagementScore}
          >
            <Typography 
              sx={{
                fontSize: hasEngagementScore || isLoadingData ? '48px' : '24px',
                fontWeight: 'bold',
                lineHeight: '48px',
                mb: '4px'
              }}
              component="p" 
              color="black"
            >
              {hasEngagementScore ? `${engagementScore}%` : isLoadingData ? '' : 'No score yet'}
            </Typography>
            {hasEngagementScore || isLoadingData ? (
              <Typography variant='h2' sx={{ fontSize: '24px', lineHeight: '30px' }}>
                {engagementExplanation}
              </Typography>
            ) : null}
          </CustomCircularProgress>
        </ProgressWrapper>
        <ChartWrapper>
          <Chart
            height='312px'
            data={data}
            showLineAtTheStart={false}
            showLineAtTheEnd={false}
            showScatterDataStyle={false}
            xAxisMaxLabels={xAxisMaxLabels}
            generateLabels={() => datePoints}
            formatXAxis={() => {
              if (groupBy === 'month') {
                return {
                  unit: 'month',
                  displayFormats: {
                    month: 'MMM',
                  }
                };
              }  

              return {
                unit: 'day',
                displayFormats: {
                  day: 'MMM D',
                }
              };
            }}
            maxStepSize={100}
            stepSize={20}
            yAxisRender={(value) => `${value}%`}
          />      
          <GroupedByTitle> {groupedBy} </GroupedByTitle>
        </ChartWrapper>
      </ChartsWrapper>
    </CategoryPaper>
  );
}
