import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';

const Root = styled.div`
  height: 48px;
  background: white;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
`;

const Header = styled.h2`
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  font-size: 17px;
  color: ${() => theme.colors.purples[50]};
  height: 24px;
  display: block;
`;

const SubHeader = ({
  children,
}) => (
  <Root>
    <Header>
      {children}
    </Header>
  </Root>
);

SubHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubHeader;
