import React from 'react';
import PropTypes from 'prop-types';
import One from './One';
import Two from './Two';
import Three from './Three';
import Four from './Four';
import Five from './Five';

const NumberComponents = {
  1: One,
  2: Two,
  3: Three,
  4: Four,
  5: Five,
};

const SmallNumbers = (props) => {
  const { number } = props;
  const NumberComponent = NumberComponents[number];
  return NumberComponent ? <NumberComponent /> : null;
};

SmallNumbers.propTypes = {
  /** The number svg component to get. */
  number: PropTypes.number,
};

export default SmallNumbers;