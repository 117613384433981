import React from 'react';
import Animation from '../Animation';
import styled from 'styled-components';
import gsap from 'gsap';
import SVG from '../../SVG';

const DEFAULT_SIZE = 146;

const BackgroundCircle = styled.path`
  fill: #ffe6ad;
`;
const BackgroundRing = styled.path`
  fill: #343799;
`;

const BoxBack = styled.path`
  fill: #e34a34;
`;
const BoxFront = styled.g``;
const BoxFrontLight = styled.path`
  fill: #f58565;
`;
const BoxFrontLightRibbon = styled.path`
  fill: #ffce7e;
`;
const BoxFrontDark = styled.path`
  fill: #f26849;
`;
const BoxFrontDarkRibbon = styled.path`
  fill: #f2a645;
`;
const BoxLid = styled.g``;
const BoxLidClosed = styled.g`
  opacity: 0;
`;
const BoxLidShadow = styled.path`
  fill: #e34a34;
  opacity: 0.4;
`;
const BoxTop = styled.path`
  fill: #f26849;
`;
const BoxFrontLeft = styled.path`
  fill: #f26849;
`;
const BoxFrontRight = styled.path`
  fill: #f58565;
`;
const BoxFrontRibbonRight = styled.path`
  fill: #ffce7e;
`;
const BoxFrontRibbonLeft = styled.path`
  fill: #f2a645;
`;
const BoxTopRibbon = styled.path`
  fill: #ffce7e;
`;
const BoxTopRibbonUnder = styled.path`
  fill: #f2a645;
`;

const Rainbow = styled.g``;
const RainbowRed = styled.path`
  fill: #eb2e49;
`;
const RainbowOrange = styled.path`
  fill: #f2a645;
`;
const RainbowGreen = styled.path`
  fill: #07bb87;
`;
const RainbowBlue = styled.path`
  fill: #4584ff;
`;
const RainbowPurple = styled.path`
  fill: #4f52b1;
`;
const RainbowBlocker = styled.path`
  fill: #ffe6ad;
`;

const Confetti = styled.path`
  fill-rule: evenodd;
  clip-rule: evenodd;
`;
const ConfettiLeft = styled(Confetti)`
  fill: #4f52b1;
`;
const ConfettiTop = styled.path`
  fill: #f2a645;
`;
const ConfettiTopRight = styled(Confetti)`
  fill: #589bff;
`;
const ConfettiTopRight2 = styled(Confetti)`
  fill: #ff4369;
`;
const ConfettiMiddle = styled(Confetti)`
  fill: #95e9d8;
`;
const ConfettiRight = styled(Confetti)`
  fill: #21cba3;
`;

const Cloud = styled.path`
  fill: #ffffff;
`;

const Dashes = styled.g``;
const Dash = styled.path`
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f9baa5;
`;

const Heart = styled.g``;
const HeartBackground = styled.path`
  fill: #ffffff;
`;
const HeartShape = styled.path`
  fill: #ff4369;
`;

const Face = styled.g``;

const Star = styled.path`
  fill: #ffffff;
`;

const FaceContainer = styled.path`
  fill: #e6f2ff;
`;
const FaceBackground = styled.path`
  fill: #ffce7e;
`;
const FaceOutline = styled.path`
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f2a645;
`;
const FaceLine = styled.path`
  fill: #4e4e52;
`;

export default class OpeningGift extends Animation {
  static defaultProps = {
    size: DEFAULT_SIZE,
  };

  /**
   * Moves the box and opens the lid.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  moveBox = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const duration = 0.2;
    const resetProps = { x: 0, y: 0, opacity: 1 };
    tl.to(this.boxFrontRef, { x: 0, y: -14, transformOrigin: 'center' }, '<');
    tl.to(this.boxLidRef, { x: 0, y: 0, transformOrigin: 'center' }, '<');
    tl.to(this.boxFrontRef, { x: 0, y: -14 }, '<');
    tl.to(this.boxLidRef, { x: 14.5, y: 8, scale: 1.0, rotation: 26 }, '<');
    tl.to(this.dashesRef, { opacity: 0, y: 10 }, '<');
    tl.to(this.heartRef, { opacity: 0, y: 10, scale: 1.4 }, '<');
    tl.to(this.boxLidClosedRef, { opacity: 1, y: -14 }, '<');
    tl.to(this.boxLidRef, { opacity: 0 }, '<');

    if (!initialize) {
      tl.to(this.boxFrontRef, { ...resetProps, duration }, '>');
      tl.to(
        this.boxLidClosedRef,
        { y: 0, duration, transformOrigin: 'center' },
        '<'
      );
      tl.to(this.boxLidRef, { y: 22, duration }, '<');

      tl.to(this.boxLidClosedRef, { opacity: 0 }, '>');
      tl.to(this.boxLidRef, { opacity: 1 }, '<');

      tl.to(
        this.boxLidRef,
        { ...resetProps, rotation: 0, scale: 1, duration },
        '>'
      );
      tl.to(this.dashesRef, { ...resetProps, duration }, '>');
      tl.to(this.heartRef, { ...resetProps, scale: 1, duration }, '<');
    }
    return tl;
  };

  /**
   * Rotates in the rainbow after the box lid opens.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  moveRainbow = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.to(this.cloudRef, { y: 10, opacity: 0 });
    tl.to(this.rainbowRef, {
      rotation: -100,
      transformOrigin: 'center',
      opacity: 0,
    });

    if (!initialize) {
      tl.to(this.rainbowRef, { rotation: 0, opacity: 1, duration: 0.4 }, '<');
      tl.to(this.cloudRef, { opacity: 1, y: 0, duration: 0.2 }, '<');
    }
    return tl;
  };

  /**
   * Moves the confetti after the box lid opens.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  showConfetti = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, opacity: 1 };
    tl.to(this.confettiLeftRef, { x: 15, opacity: 0 });
    tl.to(this.confettiTopRightRef, { x: -10, y: 20, opacity: 0 });
    tl.to(this.confettiTopRightRef2, { x: -8, y: 30, opacity: 0 });
    tl.to(this.confettiMiddleRef, { x: -10, opacity: 0 });
    tl.to(this.confettiRightRef, { x: -30, opacity: 0 });
    tl.to(this.confettiTopRef, { y: 40, opacity: 0 });

    if (!initialize) {
      tl.to(this.confettiLeftRef, { ...resetProps, duration: 0.2 }, '<');
      tl.to(this.confettiTopRightRef, { ...resetProps, duration: 0.2 }, '<0.2');
      tl.to(
        this.confettiTopRightRef2,
        { ...resetProps, duration: 0.3 },
        '<-0.1'
      );
      tl.to(this.confettiMiddleRef, { ...resetProps, duration: 0.3 }, '<');
      tl.to(this.confettiRightRef, { ...resetProps, duration: 0.3 }, '<');
      tl.to(this.confettiTopRef, { ...resetProps, duration: 0.3 }, '<');
    }
    return tl;
  };

  /**
   * Moves the stars after the box lid opens.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  showStars = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = {
      x: 0,
      y: 0,
      duration: 0.3,
      opacity: 1,
      scale: 1,
      rotation: 0,
      transformOrigin: 'center',
    };
    tl.to(this.faceRef, { x: -40, y: 40, opacity: 0 });
    tl.to(this.starTopRef, { x: -10, y: 60, opacity: 0, rotation: 360 });
    tl.to(this.starLeftRef, { x: 60, y: -10, opacity: 0, rotation: 360 });
    tl.to(this.starRightRef, { x: -50, y: 20, opacity: 0, rotation: 360 });
    tl.to(this.starMiddleRef, { x: -10, y: 10, opacity: 0, rotation: 360 });

    if (!initialize) {
      tl.to(this.starMiddleRef, resetProps, '<');
      tl.to(this.starLeftRef, { x: 15, y: -30, opacity: 0.5 }, '<0.1');
      tl.to(this.starRightRef, resetProps, '<-0.1');
      tl.to(this.faceRef, resetProps, '<');
      tl.to(this.starTopRef, resetProps, '<');
      tl.to(this.starLeftRef, resetProps, '<0.1');
    }
    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.add(this.moveBox({ initialize }));
    tl.add(this.moveRainbow({ initialize }), '>-0.2');
    tl.add(this.showStars({ initialize }), '<');
    tl.add(this.showConfetti({ initialize }), '<');
    return tl;
  };

  resetAnimation = () => {
    return this.playAnimation({ initialize: true });
  };

  render() {
    const { size, ...otherProps } = this.props;

    return (
      <SVG
        {...otherProps}
        size={size}
        viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}
      >
        <BackgroundCircle d="M73,4c38.108,0,69,30.892,69,69s-30.892,69-69,69S4,111.108,4,73S34.892,4,73,4z" />
        <BackgroundRing
          d="M73,146c-40.252,0-73-32.748-73-73C0,32.748,32.748,0,73,0c40.252,0,73,32.748,73,73
            C146,113.252,113.252,146,73,146z M73,8C37.159,8,8,37.159,8,73c0,35.841,29.159,65,65,65c35.841,0,65-29.159,65-65
            C138,37.159,108.841,8,73,8z"
        />
        <BoxBack d="M72.474,66.842l43.016,8.535l-43.016,8.876l-44.382-8.876L72.474,66.842z" />
        <Rainbow ref={(ref) => (this.rainbowRef = ref)}>
          <RainbowPurple
            d="M75.34,87.049c-1.882-4.519-2.374-9.492-1.415-14.29s3.327-9.205,6.804-12.663
              c3.477-3.459,7.907-5.814,12.73-6.769c4.823-0.954,9.822-0.464,14.365,1.407c4.542,1.872,8.425,5.042,11.157,9.109
              c2.649,3.944,4.088,8.561,4.174,13.297h4.362c-0.086-5.594-1.78-11.05-4.909-15.708c-3.211-4.781-7.775-8.507-13.115-10.707
              c-5.34-2.2-11.216-2.776-16.885-1.654c-5.669,1.122-10.876,3.891-14.963,7.956c-4.087,4.066-6.87,9.246-7.998,14.885
              c-1.128,5.639-0.549,11.485,1.663,16.797c2.212,5.312,5.958,9.852,10.763,13.047c4.806,3.195,10.456,4.9,16.236,4.9v-4.34
              c-4.917,0-9.724-1.45-13.812-4.168C80.408,95.431,77.221,91.569,75.34,87.049z"
          />
          <RainbowBlue
            d="M118.981,63.844c-2.732-4.067-6.615-7.237-11.157-9.109c-4.543-1.872-9.542-2.362-14.365-1.407
              c-4.823,0.954-9.253,3.31-12.73,6.769c-3.477,3.459-5.845,7.866-6.804,12.663s-0.467,9.77,1.415,14.29
              c1.882,4.519,5.068,8.382,9.157,11.099c4.089,2.718,8.895,4.168,13.812,4.168v-4.356c-3.988-0.083-7.873-1.286-11.195-3.494
              c-3.443-2.289-6.126-5.541-7.711-9.347c-1.585-3.806-1.999-7.993-1.191-12.033c0.808-4.04,2.802-7.751,5.73-10.664
              c2.928-2.913,6.658-4.896,10.72-5.7c4.061-0.804,8.271-0.391,12.096,1.185c3.826,1.576,7.096,4.246,9.396,7.671
              c2.3,3.423,3.526,7.446,3.528,11.563h3.473C123.069,72.405,121.63,67.788,118.981,63.844z"
          />
          <RainbowGreen
            d="M89.053,91.581c-2.869-1.907-5.105-4.618-6.426-7.789c-1.32-3.171-1.666-6.661-0.993-10.028
              c0.673-3.367,2.335-6.459,4.775-8.887s5.549-4.08,8.933-4.75c3.384-0.67,6.892-0.326,10.08,0.988
              c3.188,1.314,5.913,3.538,7.83,6.392c1.917,2.854,2.94,6.209,2.94,9.642h3.489c0-4.119-1.227-8.146-3.528-11.571
              c-2.3-3.425-5.57-6.094-9.396-7.671c-3.825-1.576-8.035-1.989-12.096-1.185c-4.061,0.804-7.792,2.787-10.72,5.7
              c-2.928,2.913-4.922,6.624-5.73,10.664c-0.808,4.04-0.393,8.228,1.191,12.033c1.585,3.806,4.268,7.058,7.711,9.347
              c3.321,2.208,7.207,3.411,11.195,3.494V94.49C95.012,94.407,91.801,93.407,89.053,91.581z"
          />
          <RainbowOrange
            d="M85.851,82.464c-1.056-2.537-1.333-5.329-0.794-8.022c0.538-2.693,1.868-5.167,3.82-7.109
              c1.952-1.942,4.439-3.264,7.146-3.8c2.707-0.536,5.514-0.261,8.064,0.79c2.551,1.051,4.731,2.831,6.264,5.114
              c1.534,2.283,2.352,4.967,2.352,7.713h3.49c0-3.432-1.023-6.788-2.94-9.642c-1.917-2.854-4.642-5.079-7.83-6.392
              c-3.188-1.314-6.696-1.657-10.08-0.988c-3.384,0.67-6.493,2.323-8.933,4.75s-4.102,5.52-4.775,8.887
              c-0.673,3.367-0.328,6.856,0.993,10.028c1.321,3.171,3.557,5.882,6.426,7.789c2.748,1.826,5.958,2.826,9.256,2.909v-3.471
              c-2.607-0.081-5.144-0.879-7.318-2.324C88.696,87.169,86.907,85.001,85.851,82.464z"
          />
          <RainbowRed
            d="M87.405,77.15c0-3.372,1.495-6.39,3.852-8.457c1.999-1.753,4.615-2.825,7.489-2.825
              c6.263,0,11.341,5.051,11.341,11.281h2.616c0-2.746-0.818-5.43-2.352-7.713c-1.533-2.283-3.713-4.063-6.264-5.114
              c-2.55-1.051-5.357-1.326-8.064-0.79c-2.708,0.536-5.195,1.858-7.146,3.8c-1.952,1.942-3.281,4.416-3.82,7.109
              c-0.539,2.693-0.262,5.485,0.794,8.022c1.056,2.537,2.845,4.706,5.141,6.231c2.174,1.445,4.711,2.243,7.318,2.324V88.41
              C92.25,88.18,87.405,83.234,87.405,77.15z"
          />
        </Rainbow>
        <RainbowBlocker d="M129.773,101.875h-15.374V77.149h15.374V101.875z" />
        <ConfettiLeft
          ref={(ref) => (this.confettiLeftRef = ref)}
          d="M48.843,76.185
            c-0.489-1.212-1.909-2.806-3.907-3.12c-1.415-0.223-2.186-1.047-2.365-1.539l-1.451,0.547c0.413,1.134,1.731,2.257,3.581,2.548
            c1.355,0.213,2.383,1.35,2.711,2.162L48.843,76.185z"
        />
        <ConfettiTopRight
          ref={(ref) => (this.confettiTopRightRef = ref)}
          d="M102.621,47.448
            c0.055-1.304-0.581-3.339-2.273-4.442c-1.197-0.781-1.56-1.849-1.52-2.37l-1.549-0.094c-0.092,1.202,0.646,2.765,2.212,3.787
            c1.147,0.748,1.616,2.205,1.579,3.079L102.621,47.448z"
        />
        <ConfettiTopRight2
          ref={(ref) => (this.confettiTopRightRef2 = ref)}
          d="M107.483,46.225
            c1.247-0.316,2.994-1.514,3.572-3.479c0.41-1.391,1.318-2.048,1.821-2.157l-0.335-1.539c-1.161,0.251-2.435,1.414-2.97,3.233
            c-0.392,1.332-1.639,2.203-2.475,2.415L107.483,46.225z"
        />
        <ConfettiMiddle
          ref={(ref) => (this.confettiMiddleRef = ref)}
          d="M95.478,76.081
            c1.247-0.316,2.994-1.514,3.572-3.479c0.409-1.391,1.318-2.048,1.821-2.157l-0.336-1.539c-1.16,0.251-2.434,1.414-2.969,3.233
            c-0.392,1.332-1.639,2.203-2.475,2.415L95.478,76.081z"
        />
        <BoxFront ref={(ref) => (this.boxFrontRef = ref)}>
          <BoxFrontDark d="M28.11132,75.37979l43.87835,8.6604l-0.17934,45.96361l-43.699-14.37501V75.37979z" />
          <BoxFrontDarkRibbon d="M40.041,77.736l13.656,2.696l-0.683,43.358l-12.973-4.438V77.736z" />
          <BoxFrontLight d="M115.49,75.377L71.79,84.002V130l43.699-14.375V75.377z" />
          <BoxFrontLightRibbon d="M102.516,77.939l-13.659,2.696l0.685,43.528l12.973-4.268V77.939z" />
        </BoxFront>
        <Cloud
          ref={(ref) => (this.cloudRef = ref)}
          d="M138.01,78.975c0.365-1.109,0.57-2.291,0.57-3.521c0-6.254-5.096-11.322-11.382-11.322
            c-5.402,0-9.922,3.745-11.087,8.768c-1.047-0.825-2.364-1.318-3.799-1.318c-3.386,0-6.129,2.729-6.129,6.097
            c0,0.579,0.099,1.132,0.251,1.662c-2.174,0.939-3.705,3.097-3.705,5.595h40.128C142.857,82.018,140.77,79.566,138.01,78.975z"
        />
        <ConfettiRight
          ref={(ref) => (this.confettiRightRef = ref)}
          d="M125.307,67.703
            c0.317-1.27,1.503-3.056,3.44-3.661c1.371-0.429,2.021-1.358,2.131-1.87l1.513,0.327c-0.252,1.182-1.403,2.487-3.195,3.047
            c-1.313,0.411-2.175,1.686-2.388,2.537L125.307,67.703z"
        />
        <Face ref={(ref) => (this.faceRef = ref)}>
          <FaceContainer
            d="M108.012,13.962L108.012,13.962c6.061-1.116,11.877,2.894,12.99,8.955l0.003,0.014
              c1.114,6.061-2.896,11.878-8.957,12.994s-11.876-2.894-12.99-8.955l-0.003-0.014C97.941,20.895,101.951,15.077,108.012,13.962z"
          />
          <FaceOutline
            d="M111.544,33.179c4.546-0.836,7.553-5.202,6.717-9.75
              s-5.199-7.558-9.744-6.721c-4.546,0.836-7.553,5.202-6.717,9.75C102.636,31.007,106.999,34.016,111.544,33.179z M111.242,31.532
              c3.636-0.669,6.042-4.161,5.372-7.8c-0.669-3.639-4.157-6.046-7.795-5.377c-3.636,0.669-6.042,4.162-5.373,7.801
              C104.115,29.794,107.605,32.201,111.242,31.532z"
          />
          <FaceBackground
            d="M111.412,32.463c4.149-0.764,6.896-4.75,6.133-8.903c-0.765-4.153-4.747-6.901-8.897-6.137
              c-4.149,0.764-6.896,4.75-6.132,8.902C103.278,30.479,107.262,33.227,111.412,32.463z"
          />
          <FaceLine
            d="M107.663,24.101c0.127,0.691-0.171,1.325-0.666,1.417
              c-0.495,0.091-0.999-0.396-1.126-1.087s0.171-1.326,0.666-1.417C107.03,22.923,107.536,23.41,107.663,24.101z M105.859,28.615
              c1.562,0.719,3.28,0.937,4.97,0.626c1.745-0.321,3.312-1.178,4.53-2.476c0.275-0.293,0.264-0.759-0.027-1.038
              s-0.751-0.27-1.026,0.024c-1.006,1.072-2.301,1.778-3.743,2.043c-1.396,0.257-2.812,0.077-4.103-0.517
              c-0.367-0.168-0.798-0.002-0.963,0.364C105.332,28.009,105.494,28.447,105.859,28.615z"
          />
          <FaceLine
            d="M112.299,23.814c-0.155,0.228-0.466,0.288-0.694,0.132s-0.288-0.466-0.133-0.695L112.299,23.814z
              M113.915,22.14c0.272,0.04,0.463,0.293,0.423,0.566c-0.039,0.273-0.293,0.463-0.566,0.423L113.915,22.14z M111.472,23.251
              c0.509-0.748,1.409-1.261,2.443-1.111l-0.144,0.99c-0.601-0.087-1.146,0.204-1.473,0.684L111.472,23.251z"
          />
          <FaceLine
            d="M112.253,24.451c-0.232,0.15-0.541,0.084-0.691-0.148c-0.15-0.231-0.084-0.542,0.147-0.691
              L112.253,24.451z M114.237,23.198c0.252,0.113,0.364,0.409,0.251,0.661s-0.409,0.364-0.66,0.251L114.237,23.198z M111.709,23.612
              c0.234-0.152,0.622-0.348,1.056-0.465c0.424-0.114,0.973-0.174,1.473,0.051l-0.409,0.912c-0.194-0.087-0.475-0.086-0.804,0.002
              c-0.319,0.086-0.611,0.235-0.771,0.339L111.709,23.612z"
          />
          <FaceLine
            d="M105.469,23.34c-0.11,0.253-0.404,0.37-0.657,0.261s-0.369-0.404-0.259-0.658L105.469,23.34z
              M106.242,21.275c0.257-0.102,0.547,0.025,0.647,0.282c0.1,0.257-0.027,0.547-0.284,0.648L106.242,21.275z M104.553,22.943
              c0.164-0.378,0.365-0.714,0.646-1c0.282-0.288,0.624-0.504,1.044-0.668l0.363,0.931c-0.309,0.122-0.525,0.267-0.694,0.438
              c-0.171,0.174-0.312,0.395-0.442,0.696L104.553,22.943z"
          />
        </Face>
        <Star
          ref={(ref) => (this.starTopRef = ref)}
          d="M85.429,2.986l2.307,4.648l5.155,0.746l-3.728,3.622l0.88,5.107l-4.614-2.41
            l-4.614,2.41l0.887-5.107L77.966,8.38l5.155-0.746L85.429,2.986z"
        />
        <Star
          ref={(ref) => (this.starLeftRef = ref)}
          d="M27.266,78l3.488,7.195l7.778,1.145l-5.633,5.6l1.323,7.91l-6.956-3.741L20.29,99.85
            l1.343-7.91L16,86.339l7.778-1.145L27.266,78z"
        />
        <Star
          ref={(ref) => (this.starRightRef = ref)}
          d="M121.097,44l1.268,2.473l2.829,0.393l-2.049,1.925l0.482,2.719l-2.53-1.286
            l-2.537,1.286l0.489-2.719L117,46.867l2.829-0.393L121.097,44z"
        />
        <Star
          ref={(ref) => (this.starMiddleRef = ref)}
          d="M87.865,66.677l1.494,3.01l3.339,0.483l-2.414,2.346l0.57,3.307l-2.989-1.561
            l-2.989,1.561l0.574-3.307l-2.419-2.346l3.339-0.483L87.865,66.677z"
        />
        <BoxLid ref={(ref) => (this.boxLidRef = ref)}>
          <BoxTop d="M53.993,41.217l40.398-16.776L56.619,47.159l-40.2,16.762L53.993,41.217z" />
          <BoxFrontLeft d="M16.419,63.921l40.374-16.998l7.557,14.924L23.01,76.938L16.419,63.921z" />
          <BoxFrontRight d="M94.392,24.441l-37.574,22.53l7.533,14.876l36.632-24.389L94.392,24.441z" />
          <BoxFrontRibbonRight d="M83.163,31.174l-10.8,6.479l6.57,14.571l10.774-7.259L83.163,31.174z" />
          <BoxFrontRibbonLeft d="M26.636,59.628l11.691-4.922l8.085,13.689l-12.23,4.465L26.636,59.628z" />
          <BoxTopRibbon d="M54.311,43.457L38.327,54.706l-11.691,4.922l23.691-14.503L54.311,43.457z" />
          <BoxTopRibbon d="M72.363,37.653l-18.368,6.17l3.907-2.689l25.261-9.96L72.363,37.653z" />
          <BoxTopRibbon
            d="M51.945,34.733c2.632-0.197,4.926,1.776,5.124,4.409s-1.776,4.926-4.409,5.124
              c-2.632,0.197-4.926-1.776-5.124-4.409S49.313,34.931,51.945,34.733z"
          />
          <BoxTopRibbon
            d="M50.815,36.977
              c0.011-0.055,0.017-0.111,0.017-0.166c0.001-1.746-5.705-3.164-12.745-3.167c-6.997-0.003-12.678,1.394-12.747,3.126l-0.062,0
              l1.624,12.544l24.993-3.987L50.815,36.977z"
          />
          <BoxTopRibbonUnder
            d="M38.98,44.077c6.928-0.897,12.726-0.22,12.95,1.512
              c0.224,1.732-5.21,3.863-12.138,4.76s-12.726,0.22-12.95-1.512C26.617,47.104,32.052,44.973,38.98,44.077z"
          />
          <BoxTopRibbon
            d="M51.824,34.714
              c-0.046-0.032-0.089-0.069-0.128-0.109c-1.201-1.268,1.968-6.22,7.078-11.061c5.08-4.812,10.165-7.703,11.406-6.493l0.045-0.043
              l7.445,10.226L56.779,41.52L51.824,34.714z"
          />
          <BoxTopRibbonUnder
            d="M65.3,31.73c-5.648,4.112-9.393,8.589-8.365,10.001
              c1.028,1.412,6.44-0.777,12.087-4.888s9.393-8.589,8.365-10.001S70.947,27.618,65.3,31.73z"
          />
        </BoxLid>
        <BoxLidClosed ref={(ref) => (this.boxLidClosedRef = ref)}>
          <BoxLidShadow d="M72.4735,70.11112l43.015,8.99894L71.79974,86.3721L28.0915,78.64612L72.4735,70.11112z" />
          <BoxTop
            d="M72.08196,60.65693l43.58886,3.66655l-43.73183,3.70679l-43.74853-4.48602
              L72.08196,60.65693z"
          />
          <BoxFrontLeft
            d="M28.19046,63.54425l43.74853,4.48602l-0.13964,17.70879l-43.69706-7.60398
              L28.19046,63.54425z"
          />
          <BoxFrontRight
            d="M115.67171,64.32394l-43.73272,3.70634l-0.13875,17.70924l43.70004-7.07928
            L115.67171,64.32394z"
          />
          <BoxFrontRibbonRight
            d="M102.603,65.4315l-13.73418,1.16397l0.04269,16.37206l13.61265-2.20521
              L102.603,65.4315z"
          />
          <BoxFrontRibbonLeft
            d="M39.91841,64.74685l13.78888,1.41393v16.42998l-13.65114-2.37551
              L39.91841,64.74685z"
          />
          <BoxTopRibbon
            d="M71.93899,64.10113l-18.2317,2.05965l-13.78888-1.41393l32.02058-2.4252
              V64.10113z"
          />
          <BoxTopRibbon
            d="M88.86881,66.59547l-16.92982-2.49435v-1.77948L102.603,65.4315
              L88.86881,66.59547z"
          />
          <BoxTopRibbon
            d="M74.19336,54.16255c2.61928,1.11609,3.83893,4.14361,2.7233,6.76433
              c-1.11562,2.62073-4.14361,3.83892-6.76433,2.7233c-2.61927-1.11609-3.83893-4.14361-2.7233-6.76433
              C68.54465,54.26512,71.57359,53.04741,74.19336,54.16255z"
          />
          <BoxTopRibbon
            d="M70.71407,55.31059
              c-0.65688-1.82198-0.92905-3.77477-10.04515-7.06947c-8.38412-3.03015-10.99967-3.45813-12.64446-3.0235L44.3914,55.72744
              l24.25404,7.1976C68.64543,62.92504,70.69125,55.36063,70.71407,55.31059z"
          />
          <BoxTopRibbonUnder
            d="M56.82252,58.29716c6.69709,1.98728,11.99257,4.07445,11.82764,4.66173
              c-0.16494,0.58727-5.72703-0.54751-12.42412-2.53479c-6.69709-1.98729-11.99257-4.07446-11.82763-4.66173
              C44.56262,55.17447,50.12565,56.30958,56.82252,58.29716z"
          />
          <BoxTopRibbon
            d="M74.54245,55.14221
              c0.36933-1.99041,1.60069-3.02151,11.14334-5.34337c9.56673-2.32771,12.28728-1.28154,12.38481-0.9219l1.92893,10.66761
              l-25.05199,3.65386C74.94754,63.19841,74.5587,55.19566,74.54245,55.14221z"
          />
          <BoxTopRibbonUnder
            d="M87.32227,60.50382c6.89224-1.13896,12.56743-1.5756,12.6757-0.97529
              c0.10827,0.60031-5.39056,2.01011-12.2828,3.14906c-6.89224,1.13896-12.56743,1.5756-12.6757,0.9753
              C74.93027,63.05233,80.43008,61.64242,87.32227,60.50382z"
          />
        </BoxLidClosed>
        <Dashes ref={(ref) => (this.dashesRef = ref)}>
          <Dash
            d="M58.761,60.541
              c-0.431,0.152-0.661,0.63-0.514,1.068l7.815,23.172c0.148,0.438,0.617,0.67,1.049,0.519c0.431-0.152,0.661-0.63,0.514-1.068
              L59.81,61.06C59.662,60.622,59.193,60.39,58.761,60.541z"
          />
          <Dash
            d="M63.341,57.149
              c-0.43,0.156-0.656,0.636-0.504,1.073l3.202,9.218c0.152,0.437,0.623,0.664,1.053,0.509c0.43-0.156,0.656-0.636,0.504-1.073
              l-3.202-9.217C64.243,57.221,63.771,56.993,63.341,57.149z"
          />
          <Dash
            d="M56.164,73.481
              c0.434-0.143,0.674-0.616,0.535-1.057l-3.503-11.117c-0.139-0.441-0.604-0.683-1.038-0.54s-0.674,0.616-0.535,1.057l3.503,11.117
              C55.265,73.382,55.729,73.624,56.164,73.481z"
          />
        </Dashes>
        <ConfettiTop
          ref={(ref) => (this.confettiTopRef = ref)}
          d="M90.901,31.751
            c0.88,0.954,1.716,2.929,1.167,4.908c-0.389,1.401,0.028,2.457,0.393,2.826l-1.093,1.116c-0.842-0.852-1.299-2.537-0.79-4.369
            c0.373-1.342-0.216-2.765-0.806-3.404L90.901,31.751z"
        />
        <Heart ref={(ref) => (this.heartRef = ref)}>
          <HeartBackground
            d="M48.974,52.652c2.092,3.632,6.514,5.013,9.877,3.085c3.363-1.928,4.394-6.436,2.302-10.068
              c-2.092-3.632-6.514-5.013-9.877-3.084C47.913,44.513,46.882,49.02,48.974,52.652z"
          />
          <HeartShape
            d="M51.02,50.609c1.298,1.992,6.523,2.083,6.523,2.083s2.41-4.233,1.112-6.225
              c-1.298-1.992-4.672-1.164-4.53,0.978C52.087,46.479,49.722,48.617,51.02,50.609z"
          />
        </Heart>
      </SVG>
    );
  }
}
