import withRouter from 'hocs/withRouter';
import { FEEDBACK_ALREADY_OVER } from '@matterapp/matter-errors';
import { withProps, lifecycle, compose } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import {
  withRemoteNotifications
} from 'hocs';
import { mixpanel, gaCall, gtagCall } from 'libs/tracking';
import Toast from 'components/Toast/Toast';

import { END_GIVE_FEEDBACK_SESSION_BY_INVITATION_MUTATION } from 'graphql-queries/queries';

const mixpanelFeedbackGiven = mixpanel.createClickEventSender('feedback:given');

const handleTrackFeedbackGiven = () => {
  mixpanelFeedbackGiven();
  gaCall('send', 'event', {
    'eventCategory': 'Retention',
    'eventAction': 'Give Feedback',
  });
  gtagCall('event', 'conversion', {'send_to': 'AW-801897506/g9_7CJSR_8cCEKL4r_4C'});
};

async function componentDidMount() {
  const { invitationUuid, handleEndFeedback } = this.props;
  await handleEndFeedback({
    variables: {
      invitationUuid,
    },
  });
}

async function componentWillUnmount() {
  const { remoteNotificationsData } = this.props;
  await remoteNotificationsData.refetch();
}

const withData = compose(
  withRouter,
  withRemoteNotifications,
  graphql(END_GIVE_FEEDBACK_SESSION_BY_INVITATION_MUTATION, {
    name: 'handleEndGiveFeedbackSessionMutation',
  }),
  withProps(
    ({
      handleEndGiveFeedbackSessionMutation,
      invitationUuid,
      history,
      nextStepPath,
    }) => ({
      handleEndFeedback: async () => {
        try {
          await handleEndGiveFeedbackSessionMutation({
            variables: {
              invitationUuid,
            },
          });
          handleTrackFeedbackGiven();
          history.push(nextStepPath);
        } catch (error) {
          let isAlreadyOver = false;
          const { graphQLErrors } = error;
          if (graphQLErrors && graphQLErrors.length > 0) {
            const [firstError] = graphQLErrors;
            isAlreadyOver = firstError.name === FEEDBACK_ALREADY_OVER;
          }
          if (isAlreadyOver) {
            Toast.error('You already gave your feedback!');
            history.push(nextStepPath);
          } else {
            console.error(error);
          }
        }
      },
    })
  ),
  lifecycle({
    componentDidMount,
    componentWillUnmount,
  })
);

export default withData(() => null);
