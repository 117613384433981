import React, { useReducer } from 'react';
import MobileMenuContext from './MobileMenuContext';
import reducer, { getInitialState, ACTIONS } from './reducer';

export default function MobileMenuProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const {
    isOpen,
    isFeedbackMenuOpen,
    isChannelMenuOpen,
    isAdminMenuOpen,
    topNavItem
  } = state;

  return (
    <MobileMenuContext.Provider
      value={{
        isMobileMenuOpen: isOpen,
        setIsMobileMenuOpen: (isOpen) => {
          dispatch({ type: ACTIONS.UPDATE_MOBILE_MENU, payload: isOpen });
        },
        topNavItem,
        setTopNavItem: (component) => {
          dispatch({ type: ACTIONS.SET_TOP_NAV_ITEM, payload: component });
        },
        isFeedbackMenuOpen,
        setIsFeedbackMenuOpen: (isOpen) => {
          dispatch({ type: ACTIONS.OPEN_FEEDBACK_MENU, payload: isOpen });
        },
        isChannelMenuOpen,
        setIsChannelMenuOpen: (isOpen) => {
          dispatch({ type: ACTIONS.OPEN_CHANNEL_MENU, payload: isOpen });
        },
        isAdminMenuOpen,
        setIsAdminMenuOpen: (isOpen) => {
          dispatch({ type: ACTIONS.OPEN_ADMIN_MENU, payload: isOpen });
        }
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  );
}
