import { connect } from 'react-redux';
import { markComponentAsShown, unmarkComponentAsShown } from '../reducers';

const withShowComponentOnce = connect(
  (state) => ({
    /**
     * Callback to determine if component of given id has been shown.
     * @param { String } id: Unique id of the component.
     * @returns { Boolean } If the component was shown.
     */
    wasComponentShown: (id) => {
      return state.components.componentsShown.has(id);
    },
  }),
  (dispatch) => ({
    /**
     * Callback to mark component of given id as shown.
     * @param { String } id: Unique id of the component to mark as shown.
     * @returns { void }.
     */
    markComponentAsShown: (id) => {
      dispatch(markComponentAsShown(id));
    },
    /**
     * Callback to unmark component of given id as shown.
     * @param { String } id: Unique id of the component to unmark as shown.
     * @returns { void }.
     */
    unmarkComponentAsShown: (id) => {
      dispatch(unmarkComponentAsShown(id));
    },
  })
);

export default withShowComponentOnce;
