import { useState, useEffect, useRef } from 'react';

export const useCustomization = (params) => {
  const {
    isOpen,
    skillsSelectionListProps,
    ...otherProps
  } = params;
  const { listOptions } = skillsSelectionListProps;
  const [contentHeight, setContentHeight] = useState(0);
  const bodyRef = useRef();

  const setHeight = () => {
    if (bodyRef.current && bodyRef.current.offsetHeight) {
      setContentHeight(bodyRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setHeight);
    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setHeight();
      }, 300);
    }
  }, [isOpen]);

  // force height to update after skillPills are rendered.
  useEffect(() => {
    setHeight();
  }, [listOptions]);

  return {
    ...otherProps,
    bodyRef,
    contentHeight,
    isOpen,
    skillsSelectionListProps,
  };
};
