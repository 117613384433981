import React from 'react';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import SurveysDashboardPage from 'routes/pages/surveys/SurveysDashboardPage';
import SurveyResultsPage from 'routes/pages/surveys/SurveyResults/SurveyResultsPage';
import SurveyResponsePage from 'routes/pages/surveys/SurveyResponse/SurveyResponsePage';
import { Route } from 'react-router-dom';

export const SurveysDashboard = (
  <Route
    exact
    path={Resources.surveys.pattern}
    element={(
      <PrivateRoute>
        <SurveysDashboardPage />
      </PrivateRoute>    
    )}
  />
);

export const CreateSurvey = (
  <Route
    exact
    path={Resources.createSurveys.pattern}
    element={(
      <PrivateRoute>
        <SurveysDashboardPage />
      </PrivateRoute>    
    )}
  />
);

export const SurveyResults = (
  <Route
    exact
    path={Resources.surveyResultsPage.pattern}
    element={(
      <PrivateRoute>
        <SurveyResultsPage />
      </PrivateRoute>    
    )}
  />
);

export const SurveyResponse = (
  <Route
    exact
    path={Resources.surveyResponse.pattern}
    element={(
      <PrivateRoute>
        <SurveyResponsePage />
      </PrivateRoute>
    )}
  />
);

export const SurveyPreview = (
  <Route
    exact
    path={Resources.customSurveyPreview.pattern}
    element={(
      <PrivateRoute>
        <SurveyResponsePage />
      </PrivateRoute>
    )}
  />
);
