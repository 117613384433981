import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 24px;
  color: ${() => theme.colors.black};

  ${({ highlighted }) =>
    highlighted
      ? `background: ${theme.colors.yellows[5]};`
      : `background: white;`}
  border-bottom: 1px solid ${() => theme.colors.blacks[10]};

  ${({ onClick }) =>
    onClick &&
    theme.media.medium`
      &:hover {
        background: ${theme.colors.blues[5]};
      }
  `}

  ${({ onClick }) =>
    onClick &&
    `
      cursor: pointer;
      &:active {
        background: ${theme.colors.blues[10]};
      }
  `}
`;

ItemContainer.defaultProps = {
  onClick: undefined,
  highlighted: false,
};

ItemContainer.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  highlighted: PropTypes.bool,
};

export default ItemContainer;
