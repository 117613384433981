"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.transitions = exports.timeValues = exports.times = void 0;
const times = {
  default: '0.2s',
  singleAndHalf: '0.3s',
  double: '0.4s',
  triple: '0.6s',
  quadruple: '0.8s',
  quintuple: '1.6s'
};
/** Transition times in milliseconds */

exports.times = times;
const timeValues = {
  default: 200,
  singleAndHalf: 300,
  double: 400,
  triple: 600,
  quadruple: 800,
  quintuple: 1600
};
exports.timeValues = timeValues;
const transitions = {
  times,
  timeValues
};
exports.transitions = transitions;
var _default = transitions;
exports.default = _default;