import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AvatarBase from './Base';
import avatarSvg from '../../../assets/svgs/avatar.svg';
import { getDefaultAvatar, getDefaultAvatarProps } from './DefaultAvatar';
import { Container, ContainerLink, getBadgeSize, getBadgeMarginTop } from './styles';
import getPhotoUrlFromEmail from '../../libs/getPhotoUrlFromEmail';

const buildAvatarUrl = ({
  url,
  email,
  size,
  defaultAvatarProps,
  defaultAvatarIsGenerated,
}) => {
  if (url) {
    return url;
  }
  if (email) {
    return getPhotoUrlFromEmail({
      defaultAvatarProps: getDefaultAvatarProps({
        size,
        hashString: email,
        ...defaultAvatarProps,
      }),
      defaultAvatarIsGenerated,
      email,
      size: 256,
    });
  }
  return defaultAvatarIsGenerated
    ? getDefaultAvatar({ ...defaultAvatarProps, size })
    : avatarSvg;
};

const BadgeStyledImage = styled.img`
  border-radius: 50%;
  vertical-align: middle;
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-left: -${({ size }) => size}px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  // prevent jumpies
  display: inline;
`;

const WithBadge = (props) => {
  const { badgeUrl, to } = props;
  const AvatarContainer = to ? ContainerLink : Container;

  if (badgeUrl) {
    const badgeSize = getBadgeSize(props);
    return (
      <AvatarBase {...props}>
        <AvatarContainer width={badgeSize} height={badgeSize}>
          <BadgeStyledImage
            src={buildAvatarUrl({
              ...props,
              url: badgeUrl,
              size: badgeSize,
            })}
            size={badgeSize}
            marginTop={getBadgeMarginTop(props)}
          />
        </AvatarContainer>
      </AvatarBase>
    );
  }
  return <AvatarBase {...props} />;
};

WithBadge.propTypes = {
  ...AvatarBase.propTypes,
  badgeUrl: PropTypes.string,
};

export default WithBadge;
