import React from 'react';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import AnalyticsPage from 'routes/pages/analytics/AnalyticsPage';
import { Route } from 'react-router-dom';

export const AnalyticsMain = (
  <Route
    exact
    path={Resources.analyticsMain.pattern}
    element={(
      <PrivateRoute>
        <AnalyticsPage />
      </PrivateRoute>    
    )}
  />
);
