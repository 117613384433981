import { compose, lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import { mixpanel } from 'libs/tracking';
import TrackOnMount from '../components/TrackOnMount';

function componentDidMount() {
  mixpanel.sendEvents({
    eventName: this.props.eventName,
    eventParams: this.props.eventParams,
  });
}

const withData = compose(
  lifecycle({ componentDidMount })
);

const TrackOnMountContainer = withData(TrackOnMount);

TrackOnMountContainer.defaultProps = {
  eventParams: null,
};

TrackOnMountContainer.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventParams: PropTypes.shape({}),
};

export default TrackOnMountContainer;
