import styled from 'styled-components';
import SVGBase from '../../../SVG';

export const DEFAULT_SIZE = 24;
export const DEFAULT_VIEW_BOX = '0 0 24 24';

export const SVG = styled(SVGBase).attrs(({ viewBox }) => ({
  size: DEFAULT_SIZE,
  viewBox: viewBox || DEFAULT_VIEW_BOX,
}))``;

export const Path = styled.path`
  fill: currentColor;
`;
