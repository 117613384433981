import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconCopy } from '../Icon';
import theme from '@matterapp/matter-theme';

const DEFAULT_MESSAGE = 'Link copied to clipboard';
const SIZE = 160;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 16px;
  left: calc(50% - ${SIZE / 2}px);
  z-index: 2;

  width: ${SIZE}px;
  height: ${SIZE}px;
  text-align: center;
  background: ${() => theme.colors.greens[50]};
  border-radius: 12px;
  padding: 32px 24px 24px 24px;
  margin: 0 auto;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.15);

  animation-name: zoomInOut;
  animation-duration: ${({ animationDuration }) => animationDuration}ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: both;

  @keyframes zoomInOut {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
    86% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
`;

const Message = styled.div`
  color: white;
  font-weight: bold;
  line-height: 24px;
  font-size: 17px;
`;

const IconContainer = styled.div`
  margin-bottom: 16px;

  ${Container} & > img {
    display: block;
  }

  ${Container} & > svg {
    height: 40px;
    display: block;

    path {
      fill: white;
      transition: fill 0.1s ease-in-out;
    }
  }
`;

const LinkCopyOverlay = ({ animationDuration, message, style }) => (
  <Container animationDuration={animationDuration} style={style}>
    <IconContainer>
      <IconCopy />
    </IconContainer>
    <Message>{message}</Message>
  </Container>
);

LinkCopyOverlay.propTypes = {
  /** Number of milliseconds to show overlay. */
  animationDuration: PropTypes.number.isRequired,
  /** Message to display in the overlay. */
  message: PropTypes.node,
  /** Additional styles. */
  style: PropTypes.object,
};

LinkCopyOverlay.defaultProps = {
  animationDuration: 1000,
  message: DEFAULT_MESSAGE,
};

export default LinkCopyOverlay;
