import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Avatar from '../Avatar';
import Button from '../Button';
import { Clickable } from '../Clickable/Clickable';
import { IconNew as Icon } from '../Icon';
import IconMeatballs from '../Icon/inverted-svg/IconMeatballs';
import TimeAgo from './FeedbackRequestTimeAgo';
import Menu from '../Menu';
import Tooltip from '../Tooltip';
import StatusTag from './StatusTag';
import { Person, feedbackRequestStatus } from './prop-types';
import { generateRecurrenceTooltip } from '../Advisors/helpers';

const DECLINED_LABEL = 'Declined';

const RecurringIcon = styled(Icon).attrs({ name: 'recurringIndicator', fill: theme.colors.blacks[60] })``;

const FeedbackRequestItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${theme.spacing.quintuple};
  color: ${theme.colors.blacks[90]};
  border-bottom: 1px solid ${theme.colors.blacks[10]};
  padding: ${theme.spacing.single};
  ${theme.media.S`
    padding: 0 ${theme.spacing.singleAndHalf};
  `}
`;

const AvatarContainer = styled.div`
  margin-right: ${theme.spacing.single};
  margin-left: ${theme.spacing.half};
  ${theme.media.S`
    margin-left: 0;
  `}
`;

const ContentContainer = styled.div`
  display: block;
  width: 100%;
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: ${theme.spacing.singleAndHalf};
`;

const NameContainer = styled.div`
  display: flex;
`;

const TooltipContainer = styled.div`
  &:hover {
    svg path {
    fill: ${theme.colors.greens[60]};
    stroke: ${theme.colors.greens[60]};
    }
  }
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[50]};
`;

const Divider = styled.div`
  margin: 0 ${theme.spacing.quarter};
`;

const CTAButton = styled(Clickable)`
  color: ${theme.colors.blues[40]};
`;

const CTALink = styled.a`
  visibility: visible;
  color: ${theme.colors.blues[40]};
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  margin-left: 10px;
  ${Button.Icon} {
    margin: 0;
    margin-right: 6px;
  }
`;

class ListItem extends React.Component {
  static propTypes = {
    eventTime: PropTypes.string.isRequired,
    person: Person.isRequired,
    feedbackRequestStatus: feedbackRequestStatus.isRequired,
    renderMenuList: PropTypes.func.isRequired,
    handleUpdateInfo: PropTypes.func,
    createFollowUpLink: PropTypes.func,
  }

  constructor() {
    super();
    this.state = {
      isDropdownOpen: false,
    };
  }

  renderAvatar() {
    const { person } = this.props;
    return (
      <AvatarContainer>
        <Avatar email={person.email} url={person.photoUrl} size={48} />
      </AvatarContainer>
    );
  }

  renderCTA() {
    const {
      feedbackRequestStatus,
      handleUpdateInfo,
      createFollowUpLink,
      handleFollowUp,
      isInvitationReplaced,
    } = this.props;
    const statusSubtype = feedbackRequestStatus.subType;

    if (statusSubtype === 'Ignored' || statusSubtype === 'Waiting') {
      return (
        <>
          <Divider>|</Divider>
          <CTALink 
            href={createFollowUpLink(statusSubtype)} 
            target={'_blank'}
            onMouseDown={handleFollowUp}
          >
            Follow Up
          </CTALink>
        </>
      );
    } else if (statusSubtype === 'Bounced' && !isInvitationReplaced) {
      return (
        <>
          <Divider>|</Divider>
          <CTAButton onClick={handleUpdateInfo}>Update Info</CTAButton>
        </>
      );
    }
    return null;
  }

  renderContent() {
    const { person, eventTime, recurringFeedback, handleSelectInvitation } = this.props;
    const tooltipContent = recurringFeedback ? generateRecurrenceTooltip(recurringFeedback.recurrence) : null;

    return (
      <ContentContainer onMouseEnter={handleSelectInvitation}>
        <NameContainer>
          {person.fullName || person.email}
          {tooltipContent && 
          <TooltipContainer>
            <Tooltip
              content={tooltipContent}
              style={{ minWidth: 'min-content' }}
            >
              <RecurringIcon />
            </Tooltip>
          </TooltipContainer>}
        </NameContainer>
        <TimeContainer>
          <TimeAgo date={eventTime} />
          {this.renderCTA()}
        </TimeContainer>
      </ContentContainer>
    );
  }

  renderStatusTag() {
    const { feedbackRequestStatus } = this.props;
    // We are changing the copy for tag subtype from 'ignored' to 'declined'. 
    // TODO: Update this once the backend language officially changes to 'declined'.
    const completedStatusType = feedbackRequestStatus.type === 'Completed' ? feedbackRequestStatus.type : null;
    const statusSubtype = 
      feedbackRequestStatus.subType === 'Ignored' ? DECLINED_LABEL : feedbackRequestStatus.subType;
    return feedbackRequestStatus.subType || completedStatusType ? <StatusTag tagType={statusSubtype || completedStatusType} /> : null;
  }

  renderTriggerComponent() {
    const iconColor = 
      this.state.isDropdownOpen ? theme.colors.blacks[70] : theme.colors.blacks[40];

    return (
      <IconMeatballs fill={iconColor} />
    );
  }

  renderActions() {
    const { renderMenuMap, handleSelectInvitation } = this.props;
    return (
      <MenuContainer onClick={handleSelectInvitation}>
        <Menu.Dark menuMap={renderMenuMap()} />
      </MenuContainer>
    );
  }

  render() {
    return (
      <FeedbackRequestItemContainer>
        {this.renderAvatar()}
        {this.renderContent()}
        {this.renderStatusTag()}
        {this.renderActions()}
      </FeedbackRequestItemContainer>
    );
  }
}

export default ListItem;
