import React from 'react';
import PropTypes from 'prop-types';
import Email from '../Email';
import { useMultipleEmail } from './utils';
import { MultipleEmailContainer } from './styles';
import { validateEmail } from '../../../libs/emailValidator';

const Multiple = (props) => {
  const { className, onChange, onSelect, ...dropdownProps } = props;
  const {
    emails,
    errors,
    setEmails,
    setErrors,
    handleBlur,
    handleKeyPress,
    hasOneValue,
  } = useMultipleEmail(props);

  const emailFields = emails.map((email, index) => {
    const handleChange = (e, { value }) => {
      const errorMessages = setErrors({
        index,
        value: validateEmail(value, true),
      });
      const newValues = setEmails({ index, value });
      onChange(e, {
        ...props,
        errorMessages,
        hasOneValue: hasOneValue(newValues),
        value: newValues,
      });
    };

    const handleSelect = (e, { value }) => {
      const errorMessages = setErrors({
        index,
        value: validateEmail(value, true),
      });
      const newValues = setEmails({ index, value });
      onSelect(e, {
        ...props,
        errorMessages,
        hasOneValue: hasOneValue(newValues),
        value: newValues,
      });
    };

    return (
      <Email
        filterItems
        {...dropdownProps}
        index={index}
        key={`email-dropdown-${index}`}
        errorMessage={errors[index]}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        onSelect={handleSelect}
        shouldItemRender={(item) => !emails.includes(item.value)}
        value={email}
      />
    );
  });

  return (
    <MultipleEmailContainer className={className}>
      {emailFields}
    </MultipleEmailContainer>
  );
};

Multiple.defaultProps = {
  errorMessages: [],
  initialAmountOfInputs: 3,
  items: [],
  onChange: () => null,
  onChangeValue: () => null,
  onSelect: () => null,
};

Multiple.propTypes = {
  className: PropTypes.string,
  /** Master error message to show for entire dropdowns. */
  errorMessage: PropTypes.string,
  /** Error message mapping to index of email field containing error. */
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  /** Callback when the values change. */
  onChange: PropTypes.func,
  /** Additional callback after value changes (when input is blurred or enter key is hit). */
  onChangeValue: PropTypes.func,
  /** The initial amount of inputs to display. */
  initialAmountOfInputs: PropTypes.number,
  /** Items to display in the dropdowns. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export default Multiple;
