"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.sizes = exports.contentWidth = void 0;

var _sizes = _interopRequireDefault(require("./legacyStyles/sizes"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const contentWidth = {
  min: '375px',
  max: '992px'
};
exports.contentWidth = contentWidth;
const sizes = { ..._sizes.default,
  contentWidth,
  webClient: {
    minContentWidth: contentWidth.min,
    maxContentWidth: contentWidth.max
  }
};
exports.sizes = sizes;
var _default = sizes;
exports.default = _default;