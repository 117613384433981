import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AddSkillIcon from './AddSkillIcon';
import theme from '@matterapp/matter-theme';

const AddSkillContainer = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: #ffffff;
  color: ${() => theme.colors.blues[50]};
  border-radius: 12px;
  border: 1px solid transparent;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  padding: 15px 24px; // 15px not 16px due to border

  transition: color 0.1s ease-in-out, background 0.1s ease-in-out,
    border-color 0.1s ease-in-out;

  svg {
    fill: ${() => theme.colors.blues[50]};
    transition: fill 0.1s ease-in-out;
  }

  :hover {
    color: ${() => theme.colors.blues[60]};
    border-color: ${() => theme.colors.blues[10]};
    svg {
      fill: ${() => theme.colors.blues[60]};
    }
  }

  :active {
    background-color: ${() => theme.colors.blues[5]};
    border-color: ${() => theme.colors.blues[60]};
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
  }
`;

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
`;

const LabelContainer = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 17px;
  text-align: left;
  margin-left: 24px;

  ${() => theme.media.medium`
    font-weight: bold;
  `}
`;

const AddSkillButton = ({ onClick }) => (
  <AddSkillContainer onClick={onClick} data-rc="add-a-skill">
    <IconContainer>
      <AddSkillIcon />
    </IconContainer>
    <LabelContainer>Add a skill</LabelContainer>
  </AddSkillContainer>
);

AddSkillButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddSkillButton;
