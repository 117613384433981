import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SteppedTimeline } from '@matterapp/matter-ui';
import { SkillNavButtons, TimelineNavButttons } from './NavButtons';
import IdentificationStep from './IdentificationStep';
import SkillStep from './SkillStep';
import SkillStepHeader from './SkillStepHeader';
import Progress from './Progress';
import Qualities from './Qualities';
import WorkRelationshipStep from './WorkRelationshipStep';
import {
  checkSkillForCompleteness,
  getSkillStepNumber,
  getSkillOfStep,
  getTimelinePaddingTop,
  SKILL_START_INDEX_OFFSET,
} from './utils';
import theme from '@matterapp/matter-theme';
import Background from './Background';

const QUALITIES_STEP_TITLE = 'Qualities';
const LAST_STEP_TITLE = 'Additional info';
const UP_NEXT_LABEL = 'Up next:';
const WORK_RELATIONSHIP_STEP_TITLE = 'Work relationship';

const DEFAULT_MAX_NOTE_LENGTH = 1500;
const DEFAULT_MIN_NOTE_LENGTH = 25;
const DEFAULT_MAX_AMOUNT_OF_SELECTED_QUALITIES = 3;

const Container = styled.div``;

const TimelineContainer = styled.div`
  height: 100%;
`;

const ContentContainer = styled.div`
  position: relative;
  max-width: calc(100vw - ${theme.spacing.double});
  margin: auto;
  ${theme.media.S`
    padding: ${theme.spacing.double};
    max-width: ${theme.sizes.webClient.maxContentWidth};
  `}
  ${theme.media.M`
    padding: 0;
    max-width: ${theme.sizes.webClient.maxContentWidth};
  `}
`;

const StyledTimeline = styled(SteppedTimeline).attrs({
  numberOfSpacerHeadersBefore: 2,
})`
  height: 100%;
  overflow-y: overlay;
  ${getTimelinePaddingTop}
`;


const qualitySkillPropType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.id,
});

export default class Timeline extends React.PureComponent {
  static propTypes = {
    currentQualities: PropTypes.array,
    /** The step of the current skill. If not passed, internal state will keep track. */
    currentSkillStep: PropTypes.number,
    /** The current step of the timeline. */
    currentStep: PropTypes.number.isRequired,
    /** If set, the current step maps to the current visible step. */
    currentStepIsVisibleStep: PropTypes.bool,
    /** The data of the current user. */
    currentUser: PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    /** Message to display when using the default note. */
    defaultNoteMessage: PropTypes.node,
    /** Function to generate default note. It is up to parent component to get it in skill state. `SkillToRate` of step is passed to help generate note. */
    generateDefaultNote: PropTypes.func,
    /** If the default note message should be shown. */
    isDefaultNoteMessageShown: PropTypes.bool,
    /** Max number of qualities that can be selected. */
    maxAmountOfQualities: PropTypes.number,
    /** The max length of the custon note for a skill. */
    maxNoteLength: PropTypes.number,
    /** The min length of the custon note for a skill. */
    minNoteLength: PropTypes.number,
    /** Callback when the current skill step abilities changes, */
    onChangeCurrentAbilities: PropTypes.func.isRequired,
    /** Callback when current note value changes. */
    onChangeCurrentNote: PropTypes.func.isRequired,
    /** Callback when current skill step changes. Only called if `currentSkillStep` prop is passed. */
    onChangeCurrentSkillStep: PropTypes.func,
    /** Callback when current timeline step changes. */
    onChangeCurrentStep: PropTypes.func.isRequired,
    /** Callback when current qualities changes. */
    onChangeCurrentQualities: PropTypes.func.isRequired,
    /** Callback when giver's email input changes. */
    onChangeCurrentUserEmail: PropTypes.func,
    /** Callback when giver's full name input changes. */
    onChangeCurrentUserFullName: PropTypes.func,
    /** Callback when error first occurs on user entering full name. */
    onChangeCurrentUserFullNameError: PropTypes.func,
    /** Callback when value of send receipt checkbox changes. */
    onChangeSendEmailReceipt: PropTypes.func,
    /** Callback when skill rating changes. */
    onChangeRating: PropTypes.func.isRequired,
    /** Callback when current visible step changes. */
    onChangeVisibleStep: PropTypes.func,
    /** Callback when next button is clicked. */
    onClickNext: PropTypes.func.isRequired,
    /** Callback when prev button is clicked. */
    onClickPrev: PropTypes.func.isRequired,
    /** Callback when continue edit note button is clicked. */
    onClickContinueEditNote: PropTypes.func,
    /** Callback when use default note button is clicked. */
    onClickUseDefaultNote: PropTypes.func,
    /** Callback when clicking on next button to show default note message. */
    onClickShowDefaultNoteMessage: PropTypes.func,
    /** Callback when clicking is complicated on work relationship. */
    onClickComplicatedWorkingTogether: PropTypes.func.isRequired,
    /** Callback when clicking no on work relationship. */
    onClickNoWorkingTogether: PropTypes.func.isRequired,
    /** Callback when clicking yes on work relationship. */
    onClickYesWorkingTogether: PropTypes.func.isRequired,
    /** Callback when finishining a step in the timeline. Passes callback prop to continue to next step. */
    onFinishCurrentStep: PropTypes.func,
    /** Callback when skill rater guide modal opens.  */
    onOpenRatingGuideModal: PropTypes.func,
    /** Callback when skill info popover opens. */
    onOpenSkillInfoPopover: PropTypes.func,
    /** Callback when skill info popover closes. */
    onCloseSkillInfoPopover: PropTypes.func,
    /** Callback to go to next step in the timeline. Use this callback to increment the currentStep of the timeline. */
    onNextStep: PropTypes.func,
    /** Callback when a skill step is skipped. */
    onSkipSkill: PropTypes.func,
    /** The data of the receiver of the feedback. */
    receiver: PropTypes.shape({
      firstName: PropTypes.string,
      fullName: PropTypes.string,
    }).isRequired,
    /** Shows the default note message. */
    showDefaultNoteMessage: PropTypes.bool,
    /** If submitting response will send current user a receipt. */
    shouldSendEmailReceipt: PropTypes.bool,
    /** If the enter your name step should be shown. */
    showIdentificationStep: PropTypes.bool,
    /** Array of skills to rate in the response flow. */
    skillsToRate: PropTypes.arrayOf(
      PropTypes.shape({
        skill: PropTypes.shape({
          description: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        }).isRequired,
        actionItemSelections: PropTypes.arrayOf(
          PropTypes.shape({
            ability: PropTypes.shape({
              name: PropTypes.string.isRequired,
              id: PropTypes.string.isRequired,
            }).isRequired,
            selected: PropTypes.bool,
          })
        ),
        priorRating: PropTypes.number,
        currentAbilities: PropTypes.array,
        currentRating: PropTypes.number,
        currentNote: PropTypes.string,

      })
    ),
    transferFeedbackDataFromSessionToUser: PropTypes.func,
    /** The current visible step of the timeline. If not passed in, internal state will be used. */
    visibleStep: PropTypes.number,
    qualitiesToRate: PropTypes.arrayOf(qualitySkillPropType),
  };

  static SKILL_START_INDEX_OFFSET = SKILL_START_INDEX_OFFSET;

  static Background = Background;

  static defaultProps = {
    currentStepIsVisibleStep: false,
    currentUser: {},
    maxAmountOfQualities: DEFAULT_MAX_AMOUNT_OF_SELECTED_QUALITIES,
    maxNoteLength: DEFAULT_MAX_NOTE_LENGTH,
    minNoteLength: DEFAULT_MIN_NOTE_LENGTH,
    onChangeCurrentSkillStep: () => null,
    onChangeCurrentUserEmail: () => null,
    onChangeCurrentUserFullName: () => null,
    onChangeSendEmailReceipt: () => null,
    onChangeVisibleStep: () => null,
    onFinishCurrentStep: (callback) => callback(),
    onClickNext: () => null,
    onClickPrev: () => null,
    onNextStep: () => null,
    onSkipSkill: () => null,
    skillsToRate: [],
  };

  constructor(props) {
    super(props);
    // If no skill step is passed, use internal state to keep track.
    const isInternalSkillStep = !props.currentSkillStep;
    this.state = {
      identityVerified: !props.showIdentificationStep,
      nameVerified: !props.showIdentificationStep,
      isInternalSkillStep,
      currentSkillStep: isInternalSkillStep ? 1 : undefined,
      showQualitiesNavButtons: false,
      toBeCreatedUser: null,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.showIdentificationStep !== prevProps.showIdentificationStep) {
      this.setState({ identityVerified: !this.props.showIdentificationStep, nameVerified: !this.props.showIdentificationStep });
    }
  }

  /**
   * @returns { Boolean } If user can go to the next step in the timeline.
   */
  canGoToNextStep = () => {
    const {
      currentQualities,
      maxAmountOfQualities,
      showIdentificationStep,
      skillsToRate,
      visibleStep,
    } = this.props;
    const qualitiesStepNumber = this.getQualitiesStepNumber();
    const isQualitiesStep = visibleStep === qualitiesStepNumber;
    const isSkillStep = visibleStep > 1 && visibleStep < qualitiesStepNumber;

    if (isQualitiesStep) {
      return currentQualities.length === maxAmountOfQualities;
    }
    if (isSkillStep) {
      const currentSkill = getSkillOfStep(skillsToRate, visibleStep);
      const { skillHasRating, skillHasNote } = this.checkSkillForCompleteness(
        currentSkill
      );
      return skillHasNote || !skillHasRating;
    }
    if (showIdentificationStep && visibleStep > qualitiesStepNumber) {
      return this.state.identityVerified && this.state.nameVerified;
    }
    return true;
  };

  /**
   * Checks skill if there is a rating, selected abilities and custom note.
   * @param { Object } skill: Skill to check.
   * @returns { Object } Object containing if the skill has a rating, selected abilities, valid note and if all 3 are complete.
   */
  checkSkillForCompleteness = (skill) => {
    const { maxNoteLength, minNoteLength } = this.props;
    return checkSkillForCompleteness({ skill, minNoteLength, maxNoteLength });
  };

  /**
   * @returns { Number } The total number of steps in the timeline.
   */
  getTotalSteps = () => {
    const { showIdentificationStep } = this.props;
    const additionalStep = showIdentificationStep ? 1 : 0;
    return SKILL_START_INDEX_OFFSET + this.getNumberOfSkills() + additionalStep;
  };

  /**
   * Gets the current skill step.
   * @returns { Number } The skill step
   */
  getCurrentSkillStep = () => {
    const { currentSkillStep, isInternalSkillStep } = this.state;
    return isInternalSkillStep ? currentSkillStep : this.props.currentSkillStep;
  };

  getNumberOfSkills = () => this.props.skillsToRate.length;

  /**
   * @returns { Number } The step number of the qualities step.
   */
  getQualitiesStepNumber = () => {
    return this.getNumberOfSkills() + SKILL_START_INDEX_OFFSET;
  };

  /**
   * Sets the current skill step.
   * @param { Object } e: The change event.
   * @param { Number } currentSkillStep: The updated skill step.
   * @returns { void }
   */
  setCurrentSkillStep = (e, currentSkillStep) => {
    if (this.state.isInternalSkillStep) {
      this.setState({ currentSkillStep });
    } else if (this.getCurrentSkillStep() !== currentSkillStep) {
      this.props.onChangeCurrentSkillStep(e, {
        ...this.props,
        currentSkillStep,
      });
    }
  };

  /**
   * Callback when back button of skill step is clicked.
   * @returns { void }
   */
  handleClickPrevSkillStep = (e) => {
    this.setCurrentSkillStep(e, this.getCurrentSkillStep() - 1);
    this.props.onClickPrev(e);
  };

  /**
   * Callback when continue button of skill step is clicked.
   * @returns { void }
   */
  handleClickContinueSkillStep = (e) => {
    this.setCurrentSkillStep(e, this.getCurrentSkillStep() + 1);
    this.props.onClickNext(e);
  };

  /**
   * Callback when clicking button to go to next step in timeline.
   * @returns { void }
   */
  handleClickNextStep = (e) => {
    this.setCurrentSkillStep(e, 1);
  };

  /**
   * Callback when the visible step changes.
   * @param { Object } e: The change event.
   * @param { Object } timelineProps: Props from the timeline that has the current visible step value.
   * @returns { void }
   */
  handleChangeVisibleStep = (e, timelineProps) => {
    const { visibleStep } = timelineProps;
    const {
      currentStep,
      currentStepIsVisibleStep,
      onChangeCurrentStep,
      onChangeVisibleStep,
      visibleStep: currentVisibleStep,
    } = this.props;
    const canGoToPrevStep = visibleStep < currentVisibleStep;
    const canGoToNextStep =
      this.canGoToNextStep() && visibleStep > currentVisibleStep;

    if (canGoToPrevStep || canGoToNextStep) {
      this.handleClickNextStep(e);
      if (currentStepIsVisibleStep && currentStep > 1) {
        onChangeCurrentStep(e, { ...this.props, currentStep: visibleStep });
      }
      onChangeVisibleStep(e, { ...this.props, visibleStep });
    }
  };

  /**
   * Returns callback to complete a step in the timeline to handle loading.
   * The given callback param is called when loading is finished.
   * @param { Function } callback: Additional callback to run when completing step.
   * @param { Object } navProp: NavProps supplied from the timeline.
   * @returns { Function } Wrapped function to continue to next step after finishing.
   */
  getFinishStepCallback = (callback, navProps) => {
    return (e) => {
      const { onFinishCurrentStep, skillsToRate, visibleStep } = this.props;
      const currentSkillToRate = getSkillOfStep(skillsToRate, visibleStep);
      const { toBeCreatedUser } = this.state;
      onFinishCurrentStep(e, {
        ...this.props,
        navProps,
        currentSkillToRate,
        toBeCreatedUser,
      });
      callback(e);
    };
  };

  /**
   * @returns { String } The 'up next' label. Hides the label just before the last step if passed in.
   */
  getUpNextLabel = () => {
    const { showIdentificationStep, visibleStep } = this.props;
    const totalSteps = this.getTotalSteps();
    if (
      (showIdentificationStep && visibleStep >= totalSteps - 1) ||
      visibleStep > totalSteps
    ) {
      return '';
    }
    return UP_NEXT_LABEL;
  };

  /**
   * Callback when enter key is pressed on name input and is valid.
   * Submits feedback and mimicks nav props to notate last step.
   * @param { Object } e: The key event.
   * @returns { void }
   */
  handleEnterKeyOnNameInput = (e) => {
    const { onNextStep, onFinishCurrentStep } = this.props;
    onNextStep(e);
    onFinishCurrentStep(e, { navProps: { isLastStep: true } });
  };

  /**
   * Callback to go to next skill step.
   * @param { Object } navProps: NavProps supplied from the timeline.
   * @returns { Function } Callback function to call when going to next step.
   */
  handleNextSkillStep = (navProps) => {
    const callback = this.handleClickContinueSkillStep;
    return this.getFinishStepCallback(callback, navProps);
  };

  /**
   * Callback to skip step.
   * @param { Object } navProps: NavProps supplied from the timeline.
   * @returns { Function } Callback function to call when skipping step.
   */
  handleSkipTimelineStep = (navProps) => {
    return this.handleNextTimelineStep(navProps, true);
  };

  /**
   * Callback to go to next timeline step
   * @param { Object } navProps: NavProps supplied from the timeline.
   * @param { Boolean } skipStep: If the step is being skipped.
   * @returns { Function } Callback function to call when going to next step.
   */
  handleNextTimelineStep = (navProps, skipStep) => {
    const { isCurrentStep, onClickNext } = navProps;
    const callback = (e) => {
      if (isCurrentStep) {
        this.handleClickNextStep(e);
        this.props.onNextStep(e);
        this.props.onClickNext(e);
      } else {
        onClickNext();
        this.props.onClickNext(e);
      }
      if (skipStep) {
        this.props.onSkipSkill(e, this.props);
      }
    };
    return this.getFinishStepCallback(callback, navProps);
  };

  /**
   * Callback to go to prev timeline step
   * @param { Object } navProps: NavProps supplied from the timeline.
   * @returns { Function } Callback function to call when going to prev step.
   */
  handlePrevTimelineStep = (navProps) => {
    const { onClickPrev } = navProps;
    return (e) => {
      onClickPrev(e);
      this.props.onClickPrev(e);
    };
  };

  handleIdentificationSuccess(identityVerified, toBeCreatedUser = null) {
    this.setState({
      identityVerified,
      toBeCreatedUser,
    });
  };

  handleNameVerified = (nameVerified) => {
    this.setState({ nameVerified });
  };

  getSkillNavButtons = (navProps) => {
    const {
      defaultNoteMessage,
      isDefaultNoteMessageShown,
      onClickContinueEditNote,
      onClickShowDefaultNoteMessage,
      onClickUseDefaultNote,
      showDefaultNoteMessage,
      receiver,
      skillsToRate,
    } = this.props;
    const { isOpen, visibleStep } = navProps;
    const currentSkill = getSkillOfStep(skillsToRate, visibleStep);
    const skillNavProps = { ...navProps, isSkillStep: true };
    return (
      <SkillNavButtons
        currentSkill={currentSkill}
        currentSkillStep={this.getCurrentSkillStep()}
        defaultNoteMessage={defaultNoteMessage}
        isLoading={this.state.loading}
        isOpen={isOpen}
        isDefaultNoteMessageShown={isDefaultNoteMessageShown}
        onClickContinueEditNote={onClickContinueEditNote}
        onClickPrevSkillStep={this.handleClickPrevSkillStep}
        onClickPrevTimelineStep={this.handlePrevTimelineStep(skillNavProps)}
        onClickNextSkillStep={this.handleNextSkillStep(skillNavProps)}
        onClickNextTimelineStep={this.handleNextTimelineStep(skillNavProps)}
        onClickSkipTimelineStep={this.handleSkipTimelineStep(skillNavProps)}
        onClickShowDefaultNoteMessage={onClickShowDefaultNoteMessage}
        onClickUseDefaultNote={onClickUseDefaultNote}
        showDefaultNoteMessage={showDefaultNoteMessage}
        receiversFullName={receiver.fullName}
        {...this.checkSkillForCompleteness(currentSkill)}
      />
    );
  };

  getNavigationButtons = (navProps) => {
    const {
      isFirstStep,
      isLastStep,
      totalNumberOfSteps,
      visibleStep,
    } = navProps;

    if (isFirstStep || visibleStep > totalNumberOfSteps) {
      return null;
    }
    if (visibleStep > this.getNumberOfSkills() + 1) {
      return (
        <TimelineNavButttons
          canClickNext={this.canGoToNextStep()}
          isLastStep={isLastStep}
          isQualitiesStep={visibleStep === this.getQualitiesStepNumber()}
          hideOnQualitiesStep={this.qualitiesStepIsAutoClickNext()}
          onClickPrev={this.handlePrevTimelineStep(navProps)}
          onClickNext={this.handleNextTimelineStep(navProps)}
          onClickSubmit={this.handleNextTimelineStep(navProps)}
        />
      );
    }
    return this.getSkillNavButtons(navProps);
  };

  /**
   * Renders skill step.
   * @param { Object } skillToRate: The skill of the current step to rate.
   * @returns { Function } Callback to render step to get the timeline step render props.
   */
  renderSkillStep = (skillToRate) => {
    const {
      currentStep,
      generateDefaultNote,
      maxNoteLength,
      minNoteLength,
      onChangeCurrentAbilities,
      onChangeCurrentNote,
      onChangeRating,
      onOpenRatingGuideModal,
      receiver,
    } = this.props;
    return (timelineProps) => {
      const { isOpen, stepNumber, visibleStep } = timelineProps;
      const skillNavProps = { ...timelineProps, isSkillStep: true };
      return (
        <SkillStep
          {...timelineProps}
          currentSkillStep={this.getCurrentSkillStep()}
          currentStep={currentStep}
          generateDefaultNote={generateDefaultNote}
          isOpen={isOpen}
          maxNoteLength={maxNoteLength}
          minNoteLength={minNoteLength}
          onChangeCurrentAbilities={onChangeCurrentAbilities}
          onChangeCurrentNote={onChangeCurrentNote}
          onChangeRating={onChangeRating}
          onOpenRatingGuideModal={onOpenRatingGuideModal}
          onNextSkillStep={this.handleNextSkillStep(skillNavProps)}
          receiver={receiver}
          skillToRate={skillToRate}
          stepNumber={stepNumber}
          visibleStep={visibleStep}
        />
      );
    };
  };

  renderSkillSidebar = () => {
    const { receiver, skillsToRate, visibleStep } = this.props;
    const currentSkill = getSkillOfStep(skillsToRate, visibleStep);
    return (
      <SkillStep.IconSidebar
        receiverFirstName={receiver.firstName}
        skillToRate={currentSkill}
        visibleStep={visibleStep}
      />
    );
  };

  /**
   * Renders all skill steps
   * @returns { React.Component } The rendered skill steps.
   */
  renderSkillSteps = () => {
    const {
      onOpenSkillInfoPopover,
      onCloseSkillInfoPopover,
      skillsToRate,
      visibleStep,
    } = this.props;
    return skillsToRate.map((skillToRate, index) => {
      const { name } = skillToRate.skill;
      const stepNumber = getSkillStepNumber(index);
      return (
        <SteppedTimeline.Step
          key={`${index}-skill-${name}`}
          isCompletedStep={stepNumber < visibleStep}
          title={
            <SkillStepHeader
              isCurrentStep={stepNumber === visibleStep}
              onOpenInfoPopover={onOpenSkillInfoPopover}
              onCloseInfoPopover={onCloseSkillInfoPopover}
              skillToRate={skillToRate}
            />
          }
        >
          {this.renderSkillStep(skillToRate)}
        </SteppedTimeline.Step>
      );
    });
  };

  /**
   * Renders the top progress bar
   * @returns { React.Component } The progress bar.
   */
  renderProgress = () => {
    const { receiver, showIdentificationStep, visibleStep } = this.props;
    return (
      <Progress
        currentStep={visibleStep}
        totalSteps={this.getTotalSteps()}
        receiversFullName={receiver.fullName}
        shouldCountLastStep={!showIdentificationStep}
      />
    );
  };

  /**
   * Determines if the qualities step will auto progress when 3 qualities are selected.
   * Only happens once when user reaches step for the first time.
   * @returns { Boolean } If the qualities step will auto progress.
   */
  qualitiesStepIsAutoClickNext = () => {
    const { currentStep, showIdentificationStep } = this.props;
    return (
      showIdentificationStep && currentStep === this.getQualitiesStepNumber()
    );
  };

  /**
   * Renders the qualities step
   * @returns { React.Component } The qualities step.
   */
  renderQualitiesStep = () => {
    const {
      currentQualities,
      maxAmountOfQualities,
      onChangeCurrentQualities,
      onNextStep,
      qualitiesToRate,
    } = this.props;

    return (
      <SteppedTimeline.Step title={QUALITIES_STEP_TITLE}>
        <Qualities
          currentQualities={currentQualities}
          maxAmount={maxAmountOfQualities}
          onChange={onChangeCurrentQualities}
          qualities={qualitiesToRate}
          onNextStep={this.qualitiesStepIsAutoClickNext() ? onNextStep : null}
        />
      </SteppedTimeline.Step>
    );
  };

  renderWorkRelationshipContent = (timelineProps) => {
    const {
      currentStep,
      onClickYesWorkingTogether,
      onClickNoWorkingTogether,
      onClickComplicatedWorkingTogether,
      onChangeCurrentStep,
      receiver,
    } = this.props;
    return (
      <WorkRelationshipStep
        currentStep={currentStep}
        onClickYes={onClickYesWorkingTogether}
        onClickComplicated={onClickComplicatedWorkingTogether}
        onClickNo={onClickNoWorkingTogether}
        onChangeCurrentStep={onChangeCurrentStep}
        onClickNext={timelineProps.onClickNext}
        receiversFullName={receiver.fullName}
      />
    );
  };

  renderWorkRelationshipStep = () => {
    return (
      <SteppedTimeline.Step
        title={WORK_RELATIONSHIP_STEP_TITLE}
        receiver={this.props.receiver}
      >
        {this.renderWorkRelationshipContent}
      </SteppedTimeline.Step>
    );
  };

  renderIdentificationStep = () => {
    const {
      currentUser,
      showIdentificationStep,
      visibleStep,
      transferFeedbackDataFromSessionToUser,
    } = this.props;


    const { fullName, email } = currentUser;
    const hasReachedNameStep = visibleStep >= this.getTotalSteps();
    if (showIdentificationStep) {
      return (
        <SteppedTimeline.Step title={hasReachedNameStep ? LAST_STEP_TITLE : ''}>
          <IdentificationStep
            email={email}
            fullName={fullName}
            onChangeEmail={this.props.onChangeCurrentUserEmail}
            onChangeFullName={this.props.onChangeCurrentUserFullName}
            onChangeSendEmailReceipt={this.props.onChangeSendEmailReceipt}
            onEnterKeyPress={this.handleEnterKeyOnNameInput}
            onChangeFullNameError={this.props.onChangeCurrentUserFullNameError}
            onIdentificationSuccess={this.handleIdentificationSuccess}
            onNameVerified={this.handleNameVerified}
            transferFeedbackDataFromSessionToUser={transferFeedbackDataFromSessionToUser}
            shouldSendEmailReceipt={this.props.shouldSendEmailReceipt}
          />
        </SteppedTimeline.Step>
      );
    }
    return null;
  };

  render() {
    const { currentStep, skillsToRate, visibleStep } = this.props;
    return (
      <Container>
        <Background
          currentStep={visibleStep}
          currentSkill={getSkillOfStep(skillsToRate, visibleStep)}
          numberOfSkills={this.getNumberOfSkills()}
          qualitiesStepNumber={this.getQualitiesStepNumber()}
          totalSteps={this.getTotalSteps()}
        />
        {this.renderProgress()}
        <TimelineContainer>
          {this.renderSkillSidebar()}
          <StyledTimeline
            ContainerComponent={ContentContainer}
            currentStep={currentStep}
            getNavigationButtons={this.getNavigationButtons}
            onClickStep={this.handleClickNextStep}
            onChangeVisibleStep={this.handleChangeVisibleStep}
            upNextLabel={this.getUpNextLabel()}
            visibleStep={visibleStep}
          >
            {this.renderWorkRelationshipStep()}
            {this.renderSkillSteps()}
            {this.renderQualitiesStep()}
            {this.renderIdentificationStep()}
          </StyledTimeline>
        </TimelineContainer>
      </Container>
    );
  }
}
