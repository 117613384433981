"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.mixins = void 0;

var _overflowScrollShadow = _interopRequireDefault(require("./overflowScrollShadow"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const mixins = {
  overflowScrollShadow: _overflowScrollShadow.default
};
exports.mixins = mixins;
var _default = mixins;
exports.default = _default;