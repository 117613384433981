import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../SVG';

const DEFAULT_SIZE = 80;

const Icon = ({ size, fill, ...otherProps }) => (
  <SVG
    {...otherProps}
    size={size}
    viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}
  >
    <path
      d="M0 40c0 22.091 17.909 40 40 40s40-17.909 40-40S62.091 0 40 0 0 17.909 0 40z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.524 34.451H34.47L33.336 58.87c-.042.63.504 1.176 1.135 1.176h11.095c.63 0 1.176-.546 1.092-1.176L45.524 34.45zm.375-9.953a4.502 4.502 0 00-4.496-4.497h-2.858a4.502 4.502 0 00-4.497 4.497v1.05a4.502 4.502 0 004.497 4.498h2.858a4.502 4.502 0 004.496-4.497v-1.051z"
      fill="#fff"
    />
  </SVG>
);

Icon.defaultProps = {
  size: DEFAULT_SIZE,
  fill: '#4584FF'
};

Icon.propTypes = {
  size: PropTypes.number,
};

export default Icon;
