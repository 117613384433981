import styled from 'styled-components';
import { ClickableLink, Text } from '@matterapp/matter-ui';
import { colors, spacing } from '@matterapp/matter-theme';

export const NotificationLink = styled(ClickableLink)`
  color: ${colors.blacks[80]};
  text-decoration: underline;
`;

export const TextWithReplace = Text.WithReplacement;
export const replaceText = Text.WithReplacement.replaceText;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.single};
  min-height: 167px;
`;


export const WorkspaceIcon = styled.img`
  border-radius: 100%;
  height: ${spacing.singleAndHalf};
  width: ${spacing.singleAndHalf};
`;
