import { Logo as MatterLogo } from '../../Brand';
import { Clickable } from '../../Clickable/Clickable';
import omitStyled from '../../../libs/omitStyled';
import { IconBell, IconKarotDown, IconMoreVertical } from '../../Icon';
import styled from 'styled-components';
import { colors, font, lineHeight, media, spacing, transitions, zIndex } from '@matterapp/matter-theme';
import { CurrentSpaceHeader } from './HeaderSpacesMenu/styles';

export const BORDER_WIDTH = '2px';
export const BOTTOM_NAV_BAR_SPACING = spacing.triple;
export const AVATAR_SIZE = 24;
export const MIN_HEADER_WIDTH = '180px';
export const MAX_HEADER_HEIGHT = '75px';
export const MAX_HEADER_WIDTH = '280px';
export const MENU_ITEM_ROLE = 'Menuitem';
export const MENU_ITEM_SECONDARY_ROLE = 'Menuitemchild';
export const TAB_BOTTOM_BORDER_WIDTH = '4px';
export const TRANSITION_TIME = transitions.times.default;

/*****************************************************************************/
/* SHARED STYLES                                                             */
/*****************************************************************************/
export const ACCENT_COLOR = colors.purples[40];
export const HOVER_COLOR = colors.purples[50];
export const HIGHLIGHT_COLOR = colors.blacks[5];
export const HIGHLIGHT_ACTIVE_COLOR = colors.purples[1];

export const ICON_TEXT_COLOR_MOBILE = colors.purples[20];
export const ICON_TEXT_COLOR = colors.blacks[60];

export const BACKGROUND_COLOR_MOBILE = colors.purples[50];
export const BACKGROUND_COLOR = colors.white;
export const DIVIDER_BORDER = `${BORDER_WIDTH} solid ${colors.blacks[10]}`;
export const MOBILE_HEADER_PADDING = spacing.threeQuarters;
export const NEGATIVE_MOBILE_HEADER_PADDING = `calc(${spacing.threeQuarters} * -1)`;

export const LARGE_NAV_NORMAL_SIZE_BREAKPOINT_NORMAL = 1240;

/*****************************************************************************/
/* MAIN STYLES                                                               */
/*****************************************************************************/
export const Container = styled.div`
  background: ${BACKGROUND_COLOR};
  display: flex;
  height: 100%;
  transition: box-shadow ${transitions.times.default};

  ${({ isScrolled }) =>
    isScrolled
      ? `box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)`
      : `box-shadow: 0 0 0 transparent`};

  ${media.L`
    box-shadow: none;
    flex-direction: column;
    min-width: ${MIN_HEADER_WIDTH};
    overflow: hidden;
  `}

  ${media.XL`
    max-width: ${MAX_HEADER_WIDTH};
  `}
`;

export const HeaderBar = omitStyled('div', ['showZeroWorkspaces'])`
  background: ${BACKGROUND_COLOR};
  display: flex;
  flex: 1 1 auto;
  padding-left: ${({ showZeroWorkspaces }) => showZeroWorkspaces ? '0' : spacing.singleAndHalf};
  margin: ${({ showZeroWorkspaces }) => showZeroWorkspaces ? '0' : `${MOBILE_HEADER_PADDING} 0`};

  ${media.L`
    flex: 0 0 auto;
    background: transparent;
    border-bottom: ${DIVIDER_BORDER};
    height: ${MAX_HEADER_HEIGHT};
    margin: 0;
    padding: ${({ showZeroWorkspaces }) => showZeroWorkspaces ? '0' : spacing.singleAndHalf};
  `}
`;

export const HeaderBarContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
`;

export const LogoContainer = styled(Clickable)`
  transition: transform ${transitions.times.default};
  cursor: pointer;

  &:active {
    transform: translateY(-${BORDER_WIDTH});
  }
`;

export const HeaderBarTitle = styled.div`
  color: ${colors.white};
  font-size: ${font.size.S};
  font-weight: ${font.weight.medium};
  line-height: ${AVATAR_SIZE}px;
  margin-left: ${spacing.single};
  max-width: calc(
    100vw - ${({ showGettingStarted }) => (showGettingStarted ? 330 : 220)}px
  );
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  & button {
    display: ${({ showGettingStarted }) => showGettingStarted ? 'none' : 'block'};
  }

  ${media.breakpoint(475)`
    & button {
      display: block;
    };
  `}

  ${media.L`
    display: none;
  `}
`;

export const CreateAWorkspaceButton = styled(Clickable)`
  align-items: center;
  background: ${colors.white};
  display: flex;
  color: ${colors.purples[30]};
  padding: ${spacing.singleAndHalf};
  text-decoration: none !important;
  min-height: ${spacing.tripleAndHalf};
  width: 100%;
  &:hover {
    color: ${colors.purples[50]};
  }
  ${media.L`
    padding: ${spacing.singleAndQuarter};
  `}
`;

export const CreateAWorkspaceContentContainer = styled(CurrentSpaceHeader)`
  display: flex;
  max-width: 100% !important;
`;

export const Logo = styled(MatterLogo).attrs(() => ({
  marginBottom: 0,
  size: 'small',
}))``;

export const MoreIcon = styled(IconMoreVertical)``;

/*****************************************************************************/
/* TAB STYLES                                                                */
/*****************************************************************************/
export const TabBar = styled.div`
  background: ${BACKGROUND_COLOR_MOBILE};
  display: flex;
  z-index: ${zIndex.navBar};

  ${({ showBorderAbove, showBorderBelow, isBottomTabBar }) => media.L`
    background: transparent;
    flex: ${isBottomTabBar ? '1' : '0'} 1 auto;
    display: block;
    height: auto;
    overflow: hidden;
    position: static;

    & [data-role='${MENU_ITEM_ROLE}']:first-child {
      padding-top: ${spacing.singleAndQuarter};
    }
    & [data-role='${MENU_ITEM_ROLE}']:last-child {
      padding-bottom: ${spacing.singleAndQuarter};
    }

    ${showBorderAbove ? `border-top: ${DIVIDER_BORDER};` : ''}
    ${showBorderBelow ? `border-bottom: ${DIVIDER_BORDER};` : ''}
  `}
`;

export const generateMoreTabsStyles = ({ indexOffset, numberOfInitialTabs, showMore }) => {
  const styles = [];
  for (let i = 1; i < numberOfInitialTabs + 1; i++) {
    const childTabIndex = indexOffset + i + 1;
    if (childTabIndex > numberOfInitialTabs) {
      styles.push(`
        & > div:nth-child(${childTabIndex}) {
          opacity: ${showMore ? 1 : 0};
          transform: translateY(${showMore ? 0 : 48 * -i}px);
        }`);
    }
  }
  return styles.join('');
};

export const SharedTabsContainer = styled.div`
  & > div {
    transition: opacity ${transitions.times.default}, transform ${transitions.times.default};
  }
  ${generateMoreTabsStyles}
`;

export const TabBarDockOnMobile = styled(TabBar)`
  background: ${BACKGROUND_COLOR_MOBILE};
  display: flex;
  padding: 0;
  position: fixed;
  height: ${BOTTOM_NAV_BAR_SPACING};
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.navBar};
  transition: all ${transitions.times.default}, background 0s;
`;

export const TabBarHideOnMobile = styled(TabBar)`
  display: none;
  ${media.L`
    display: block;
  `}
`;

/*****************************************************************************/
/* NOTIFICATIONS STYLES                                                      */
/*****************************************************************************/
export const NotificationsContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  height: 100%;
  max-width: ${spacing.double};

  &:after {
    content: '';
    background: ${colors.white};
    bottom: ${NEGATIVE_MOBILE_HEADER_PADDING};
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    height: ${TAB_BOTTOM_BORDER_WIDTH};
    position: absolute;
    pointer-events: ${({ isActive }) => (isActive ? 'none' : 'all')};
    width: 100%;
  }

  ${media.L`
    &:after {
      top: ${spacing.eighth};
      right: -${spacing.singleAndHalf};
      height: ${spacing.singleAndThreeQuarters};
      width: ${spacing.half};
    }
  `}
`;

export const NotificationsCircle = styled.div`
  background: ${colors.reds[50]};
  border-radius: 50%;
  height: ${spacing.singleAndThreeQuarters};
  width: ${spacing.singleAndThreeQuarters};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform ${TRANSITION_TIME};

  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: translateY(-${BORDER_WIDTH});
  }
`;

export const NotificationsCircleText = styled.span`
  font-size: ${font.size.XS};
  line-height: ${lineHeight.S};
  text-align: center;
  font-weight: ${font.weight.bold};
  color: ${colors.white};
`;

export const NotificationBell = omitStyled(IconBell, ['isActive'])`
  ${({ isActive }) => `
    transition: transform ${TRANSITION_TIME};

    path {
      fill: ${isActive ? ACCENT_COLOR : ICON_TEXT_COLOR};
      transition: fill ${TRANSITION_TIME};
    }

    &:hover {
      cursor: ${isActive ? 'default' : 'pointer'};
      path {
        fill: ${ACCENT_COLOR};
      }
    }

    &:active {
      transform: ${isActive ? `` : `translateY(-${BORDER_WIDTH});`}
      path {
        fill: ${ACCENT_COLOR};
      }
    }
  `}
`;

/*****************************************************************************/
/* PROFILE STYLES                                                            */
/*****************************************************************************/
export const ProfileContainer = styled.div`
  align-items: center;
  display: flex;
  opacity: 1;
  margin-bottom: 0;
  padding: ${MOBILE_HEADER_PADDING};
  padding-left: ${spacing.single};
  position: relative;
  transition: height 0s, opacity ${TRANSITION_TIME} ${TRANSITION_TIME};

  ${media.L`
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: ${MAX_HEADER_HEIGHT};
    padding: 0; 
    z-index: ${zIndex.navBarMenu};
  `}
`;

export const ProfileWrapper = styled.div`
  &:after {
    background: ${colors.white};
    bottom: 0;
    content: '';
    position: absolute;
    height: ${spacing.quarter};
    width: ${spacing.double};
  }

  ${({ hasActiveTab }) => `
    &:after {
      display: ${hasActiveTab ? 'block' : 'none'};
    }
  `}

  ${media.L`
    background: ${BACKGROUND_COLOR};
    border-top: ${DIVIDER_BORDER};
    overflow: hidden;

    &:after {
      display: none !important;
    }

    ${({ isOpen }) => `
      transform: translateY(${isOpen ? `calc(-100% + ${MAX_HEADER_HEIGHT})` : 0});
      transition: transform ${TRANSITION_TIME};
    `}
  `}
`;

export const AvatarContainer = styled.div`
  border: none;
  border-radius: 50%;
`;

export const ProfileAvatarContainer = omitStyled(Clickable, ['isOpen'])`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  ${media.L`
    height: ${MAX_HEADER_HEIGHT};
    padding: ${spacing.singleAndHalf};
    width: 100%;
    transition: background ${transitions.times.default};
  `}
`;

export const ProfileAvatarMobileWrapper = styled.div`
  color: ${colors.blacks[50]};
  display: block;

  & svg path {
    fill: currentColor;
  }
  &:hover {
    color: ${colors.purples[50]};
  }
  &:active {
    color: ${colors.purples[60]};
  }
  ${media.L`
    display: none;
  `}
`;

export const ProfileAvatarDesktopWrapper = styled.div`
  display: none;
  ${media.L`
    display: block;
  `}
`;

export const ProfileMenuIcon = styled(IconKarotDown)``;

export const ProfileMenuIconContainer = styled.div`
  display: none;

  ${media.L`
    display: block;
    & svg {
      color: ${colors.blacks[40]};
      transition: transform ${transitions.times.default};
      ${({ isOpen }) => isOpen ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)'};

      path {
        transition: fill ${transitions.times.default};
      }
    }
    ${ProfileAvatarContainer}:hover & svg path {
      fill: ${ICON_TEXT_COLOR};
    };
    ${ProfileAvatarContainer}:active & svg path {
      fill: ${ACCENT_COLOR};
    };
  `}
`;

export const ProfileMenuContainer = styled.div`
  color: ${colors.white};
  display: none;
  padding: 0 0 ${spacing.singleAndHalf};

  ${media.L`
    display: block;
    text-align: left;
  `}
`;
