const AUTH_START = '@auth/start-auth';
const AUTH_END = '@auth/end-auth';

const reducer = (state = false, action) => {
  switch (action.type) {
  case AUTH_START:
    return true;
  case AUTH_END:
    return false;
  default:
    return state;
  }
};

export default reducer;

export const startAuth = () => ({
  type: AUTH_START,
});

export const endAuth = () => ({
  type: AUTH_END,
});
