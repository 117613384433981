import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MAX_STEP_WIDTH } from './utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const ContentContainer = styled.div`
  flex: 1 1 100%;
  max-width: ${MAX_STEP_WIDTH}px;
`;

const SidebarContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
`;

const StepContainer = (props) => {
  const { className, children, sidebarContent } = props;
  return (
    <Container className={className}>
      <ContentWrapper>
        <ContentContainer>{children}</ContentContainer>
        <SidebarContainer>{sidebarContent}</SidebarContainer>
      </ContentWrapper>
    </Container>
  );
};

StepContainer.propTypes = {
  /** Additional style class. */
  className: PropTypes.string,
  /** The contents of the step. */
  children: PropTypes.node,
  /** The sidebar of the step. */
  sidebarContent: PropTypes.node,
};

export default StepContainer;
