import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '../../Grid';
import * as ChoiceButtons from './ChoiceButtons';

const generateAnimationDelay = (numberOfItems = 5) => {
  const styles = [];
  for (let i = 0; i < numberOfItems; i++) {
    styles.push(`
      button:nth-child(${i}) {
        animation-delay: ${0.1 + i * 0.03}s;
      }
    `);
  }
  return styles.join('');
};

const getButtonAnimation = (props) => {
  const { animated } = props;
  if (animated) {
    return `
      > button {
        animation: popin 0.6s ease 0.1s 1 normal both;
      }
      [data-card-prev="true"] & > button {
        animation: none;
      }
      ${generateAnimationDelay()}
      @keyframes popin {
        0% { transform: scale(0); }
        50% { transform: scale(1.1); }
        100% { transform: scale(1); }
      }
    `;
  }
  return '';
};

const GridContainer = styled(Grid)`
  ${getButtonAnimation}
`;

const DEFAULT_COLUMNS = 1;
const DEFAULT_SPACING = 4;

export default class TwoChoices extends React.PureComponent {
  static propTypes = {
    /** If buttons should animate in. */
    animated: PropTypes.bool,
    /** Override default button component. */
    buttonComponent: PropTypes.elementType,
    /** The button component for button one. */
    buttonOneComponent: PropTypes.elementType.isRequired,
    /** The button component for button two. */
    buttonTwoComponent: PropTypes.elementType.isRequired,
    /** Override default button one label. */
    buttonOneLabel: PropTypes.string,
    /** Override default button two label. */
    buttonTwoLabel: PropTypes.string,
    /** Additional children to show between yes and no buttons. */
    children: PropTypes.node,
    /** Children compoentns in middle, otherwise at end. */
    childrenInCenter: PropTypes.bool,
    /** Additional style class. */
    className: PropTypes.string,
    /** Override number of columns to display buttons in. */
    columns: PropTypes.number,
    /** Callback when button one is clicked. */
    onClickButtonOne: PropTypes.func.isRequired,
    /** Callback when button two is clicked. */
    onClickButtonTwo: PropTypes.func.isRequired,
    /** Spacing between buttons. */
    spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** The value of the selected button. */
    value: PropTypes.string,
  };

  static defaultProps = {
    childrenInCenter: true,
    columns: DEFAULT_COLUMNS,
    spacing: DEFAULT_SPACING,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  handleClickOne = (e, { value }) => {
    const { onClickButtonOne } = this.props;
    onClickButtonOne(e, { ...this.props, value });
  };

  handleClickTwo = (e, { value }) => {
    const { onClickButtonTwo } = this.props;
    onClickButtonTwo(e, { ...this.props, value });
  };

  renderChildren = () => {
    const { children, value } = this.props;
    return React.Children.map(children, (child) => {
      if (child && Object.values(ChoiceButtons).includes(child.type)) {
        return React.cloneElement(child, {
          selectedValue: value,
        });
      }
      return child;
    });
  };

  render() {
    const {
      animated,
      buttonComponent,
      buttonOneComponent,
      buttonTwoComponent,
      buttonOneLabel,
      buttonTwoLabel,
      childrenInCenter,
      className,
      columns,
      spacing,
      value,
    } = this.props;

    const ButtonOne = buttonOneComponent;
    const ButtonTwo = buttonTwoComponent;

    return (
      <GridContainer animated={animated} className={className} columns={columns} spacing={spacing}>
        <ButtonOne
          buttonComponent={buttonComponent}
          label={buttonOneLabel}
          onClick={this.handleClickOne}
          selectedValue={value}
        />
        {childrenInCenter && this.renderChildren()}
        <ButtonTwo
          buttonComponent={buttonComponent}
          label={buttonTwoLabel}
          onClick={this.handleClickTwo}
          selectedValue={value}
        />
        {!childrenInCenter && this.renderChildren()}
      </GridContainer>
    );
  }
}
