import React from 'react';
import PropTypes from 'prop-types';
import { Confirm } from '@matterapp/matter-ui';

const DeleteRewardModal = (props) => {
  const {
    isOpen,
    onClose,
    onDelete,
  } = props;
  
  return (
    <Confirm
      isOpen={isOpen}
      onClose={onClose}
      header="Are you sure?"
      subHeader="When you delete a Reward, it will no longer be accessible to your team."
      cancelButtonLabel="Close"
      confirmButtonLabel="Yes, Delete"
      onClickConfirm={onDelete}
      onClickCancel={onClose}
      confirmButtonIsDestructive
    />
  );
};

DeleteRewardModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DeleteRewardModal;
