import React from 'react';
import PropTypes from 'prop-types';
import PeerRecommendationsGridContainer from '../containers/PeerRecommendationsGridContainer';
import { PeerSuggestionsTrackOnMount } from '../../track-on-mount';

const PeerRecommendationsGridWrapper = ({
  peerRecommendations,
  isPeerRecommendationsLoaded,
  invitationSource,
}) => {
  if (!isPeerRecommendationsLoaded) { return null; }
  return (
    <React.Fragment>
      <PeerSuggestionsTrackOnMount
        source={invitationSource}
        numPeers={peerRecommendations.length}
      />
      <PeerRecommendationsGridContainer peerSuggestions={peerRecommendations} />
    </React.Fragment>
  );
};

PeerRecommendationsGridWrapper.propTypes = {
  invitationSource: PropTypes.string.isRequired,
  isPeerRecommendationsLoaded: PropTypes.bool.isRequired,
  peerRecommendations: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      fullName: PropTypes.string,
      photoUrl: PropTypes.string,
    })
  ).isRequired,
};

export default PeerRecommendationsGridWrapper;
