
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  ClickableLink,
  Message,
  Radio,
  TextArea,
  Weekday,
} from '@matterapp/matter-ui';
import {
  FEEDBACK_RITUAL_PANEL_TEXT,
  FEEDBACK_RITUAL_KICKOFF_MESSAGE
} from '../sharedConsts';
import theme from '@matterapp/matter-theme';
import { FormattedGroup } from 'components/Group';

const TEXTAREA_MIN_HEIGHT = 140;
const TEXTAREA_MAX_HEIGHT = TEXTAREA_MIN_HEIGHT + 100;

export const WarningMessage = styled(Message.Warning)({
  marginBottom: 0
});

export const Toggle = styled(Checkbox.Toggle).attrs(() => ({
  size: Checkbox.Toggle.sizes.S,
}))``;

/*****************************************************************************/
/* BUTTONS STYLES                                                            */
/*****************************************************************************/
export const DisableButton = styled(Button).attrs(() => ({
  content: FEEDBACK_RITUAL_PANEL_TEXT.DISABLE_BUTTON,
  color: Button.buttonColors.RED,
  size: Button.buttonSizes.S,
}))``;

export const EnableButton = styled(Button.Primary.Vivid).attrs(() => ({
  content: FEEDBACK_RITUAL_PANEL_TEXT.ENABLE_BUTTON,
  color: Button.buttonColors.BLUE,
}))``;

export const HowItWorksButton = styled(Button.Minimal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));

export const EnableButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.singleAndHalf} 0;

  & > ${EnableButton} {
    margin: 0 0 ${theme.spacing.half} 0;
  }
  & > ${HowItWorksButton} {
    margin: ${theme.spacing.half} 0 0 0;
  }

  ${theme.media.S`
    flex-direction: row;
    & > ${EnableButton} {
      margin: 0 ${theme.spacing.half} 0 0;
    }
    & > ${HowItWorksButton} {
      margin: 0 0 0 ${theme.spacing.half};
    }
  `}
`;

export const ChangeTimezoneLink = styled(ClickableLink)(() => ({
  fontSize: theme.fontSize.base,
  whiteSpace: 'nowrap'
}));

export const ExampleLink = styled(ClickableLink).attrs(() => ({
  children: FEEDBACK_RITUAL_PANEL_TEXT.SEE_EXAMPLE_LINK,
}))`
  font-size: ${theme.fontSize.base};
  white-space: nowrap;
`;

/*****************************************************************************/
/* WEEKDAY BUTTON GROUP STYLES                                               */
/*****************************************************************************/
export const WeekdayButtonGroup = styled(Weekday.ButtonGroup)`
  & button {
    font-size: ${theme.fontSize.base};
  }
  ${theme.media.S`
    & button {
      font-size: ${theme.fontSize.S};
    }
  `}
`;

/*****************************************************************************/
/* GROUP STYLES                                                              */
/*****************************************************************************/

export const Group = styled(FormattedGroup)`
  padding-bottom: ${theme.spacing.triple};
`;

export const TopGroup = styled(FormattedGroup)`
  margin-top: ${theme.spacing.double};
  padding-bottom: ${theme.spacing.tripleAndHalf};
`;

/*****************************************************************************/
/* KICKOFF MESSAGE STYLES                                                    */
/*****************************************************************************/
export const KickoffMessageContainer = styled.div`
  margin-top: ${theme.spacing.double};
  position: relative;
`;

export const KickoffMessageArea = styled(TextArea.WithCount).attrs(() => ({
  autoHeight: true,
  minLength: FEEDBACK_RITUAL_KICKOFF_MESSAGE.MIN_LENGTH,
  maxLength: FEEDBACK_RITUAL_KICKOFF_MESSAGE.MAX_LENGTH,
  showBottomBorder: false,
}))`
  font-weight: ${theme.fontWeight.regular};
  min-height: ${TEXTAREA_MIN_HEIGHT}px;
  max-height: ${TEXTAREA_MAX_HEIGHT}px;
  border: none;
`;

export const KickoffMessageHeader = styled.div`
  font-size: ${theme.fontSize.S};
  font-weight: ${theme.fontWeight.semiBold};
  margin-bottom: ${theme.spacing.half};
`;

export const KickoffMessageResetButton = styled(ClickableLink)`
  position: absolute;
  top: -${theme.spacing.half};
  right: -${theme.spacing.threeQuarters};
  font-size: ${theme.fontSize.base};
  padding: ${theme.spacing.threeQuarters};
  z-index: 1;

  &:not(:hover):not(:active) {
    color: ${theme.colors.blacks[50]};
  }
`;

/*****************************************************************************/
/* MESSAGE SENT BY STYLES                                                    */
/*****************************************************************************/
export const KickoffMessageSentByHeader = styled(KickoffMessageHeader)`
  margin: ${theme.spacing.single} 0;
`;

export const KickoffMessageSentByRadioList = styled(Radio.List.Settings)``;

export const SlackNotificationsCheckmark = styled(Checkbox)``;

export const SlackNotificationsNotifyText = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  ${({ disabled }) => `color: ${disabled ? theme.colors.blacks[40] : theme.colors.blacks[80]}`}
`;

export const SlackNotificationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  width: 100%;
  padding: 0 ${theme.spacing.single};
  background-color: ${theme.colors.blacks[5]};
`;

/*****************************************************************************/
/* POST ON MY BEHALF STYLES                                                  */
/*****************************************************************************/
export const PostOnMyBehalfContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing.single};
`;

export const PostOnMyBehalfButton = styled(Button.Primary).attrs(
  ({ isRemoveAuthorization }) => ({
    color: isRemoveAuthorization
      ? Button.buttonColors.RED
      : Button.buttonColors.BLUE,
    content: isRemoveAuthorization
      ? FEEDBACK_RITUAL_PANEL_TEXT.REMOVE_AUTHORIZATION_BUTTON
      : FEEDBACK_RITUAL_PANEL_TEXT.POST_ON_MY_BEHALF_BUTTON,
    size: Button.buttonSizes.S,
  })
)`
  ${({ hideButton }) => `
    display: ${hideButton ? 'none' : 'block'};
  `}
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.single};
  ${theme.media.S`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const FrequencyContainer = styled.div`
  margin-bottom: ${theme.spacing.singleAndHalf};
  ${theme.media.S`
    width: 100%;
    margin-right: ${theme.spacing.singleAndQuarter};
  `}
  ${theme.media.M`
    width: 372px;
  `}
`;

export const StartingContainer = styled.div`
  ${theme.media.S`
    min-width: 180px;
  `}
  ${theme.media.M`
    width: 220px;
  `}
`;

export const FrequencyDescription = styled.div`
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.colors.blacks[70]};
  margin-top: ${theme.spacing.singleAndHalf};
  margin-bottom: -${theme.spacing.half};
  ${theme.media.S`
    margin-top: 0;
  `}
`;

export const MidDayContainer = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between'
});

export const SubText = styled.p(() => ({
  fontSize: theme.fontSize.S
}));

export const SubHeader = styled(SubText)({
  fontWeight: 'bold'
});
