import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { Button } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

const ActionButton = styled(Button.Primary)(() => ({
  whiteSpace: 'nowrap',
  fontSize: theme.fontSize.base,
  lineHeight: theme.fontSize.base,
  padding: `${theme.spacing.half} ${theme.spacing.single}`,
  minHeight: 0,
  backgroundColor: theme.colors.blues[5],
  marginLeft: theme.spacing.single
}));

const BlueSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#4584ff',
  padding: theme.spacing(2),
  borderRadius: '4px',
  marginBottom: theme.spacing(1)
}));

export default function BlueBanner({ text, showButton, buttonProps }) {
  const {
    isDisabled,
    onClick,
    text: buttonText
  } = buttonProps || {};
  
  return (
    <BlueSection>
      <Typography variant='h5' component='span' sx={{ color: 'white.main' }}>
        {text}
      </Typography>
      {showButton && (
        <ActionButton 
          disabled={isDisabled} 
          onClick={onClick}
        >
          {buttonText}
        </ActionButton>
      )}
    </BlueSection>
  );
};
