import { ColorPalettes, getColorObj } from '@matterapp/matter-theme';

export const isFunction = (func) => typeof func === 'function';

export const getThemeColor = (colorPalette = ColorPalettes.Purple) => {
  const colorObj = getColorObj(colorPalette);
  return {
    backgroundColor: colorObj[30],
    lightBackgroundColor: colorObj[10],
    color: colorObj,
    lightColor: colorObj[5],
    textColor: colorObj[50],
  };
};

export { ColorPalettes as Colors };