import React, { useContext, useEffect, useState } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import ContainerNavigation from './ContainerNavigation';
import { useMutation } from '@apollo/client';
import updateMemberSettings from 'graphql-queries/mutations/workspaceMembers/updateMemberSettings';
import getCurrentMemberProperties from 'graphql-queries/queries/rewards/getCurrentMemberProperties';
import { Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { Button, Radio } from '@matterapp/matter-ui';
import Toast from 'components/Toast';

const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const EntryWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4)
}));

const SaveButton = styled(Button.Primary.Vivid)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(4)
}));

const StyledRadio = styled(Radio.List.Settings)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1)
}));

export default function UserSettingsTeamsNotifications() {
  const { memberProperties, currentTenant, currentUser } = useContext(UserContext);
  const { memberSettings } = memberProperties || {};
  const [includeInFF, setIncludeInFF] = useState();
  const canSave = includeInFF !== memberSettings?.includeInFF;
  const [updateMemberSettingsMutation, { loading }] = useMutation(updateMemberSettings);
  const PARTICIPATION_OPTIONS = ['Ask me every week', 'Never include me'];
  let includeInFFValue = null;

  if (includeInFF != null) {
    includeInFFValue = includeInFF ? PARTICIPATION_OPTIONS[0] : PARTICIPATION_OPTIONS[1];
  }

  useEffect(() => {
    if (memberSettings) {
      setIncludeInFF(memberSettings.includeInFF !== false);
    }
  }, [memberSettings]);

  async function onSave() {
    await updateMemberSettingsMutation({
      variables: {
        tenantId: currentTenant.id,
        personId: currentUser.personId,
        settings: {
          includeInFF
        }
      },
      refetchQueries: [{
        query: getCurrentMemberProperties,
        variables: {
          tenantId: currentTenant.id
        }
      }]
    });

    Toast.success(`Notification settings updated.`);
  }

  return (
    <>
      <AppLayout
        layout={AppLayout.Layouts.TwoColumnFixedLeft}
        oldLayoutVersion={1}
        containerNavigation={<ContainerNavigation />}
      >
        <Header variant="h3">
          MSFT Teams Notifications
        </Header>
        <Typography variant="body1">
          Control when and how Matter contacts you on Microsoft Teams.
        </Typography>
        <EntryWrapper>
          <Header variant="h3">
            Feedback Day
          </Header>
          <Typography variant="body1">
            Choose your level of participation.
          </Typography>
          <Box sx={{ mt: 3, mb: 3 }}>
            <StyledRadio 
              onChange={() => {
                setIncludeInFF(!includeInFF);
              }}
              value={includeInFFValue}
              values={PARTICIPATION_OPTIONS}
            />
          </Box>
        </EntryWrapper>
        <SaveButton
          submitting={loading}
          onClick={onSave}
          fluid
          size="large"
          disabled={!canSave}
        >
          Save
        </SaveButton>
      </AppLayout>
    </>
  );
}
