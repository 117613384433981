import React from 'react';
import styled from 'styled-components';
import { IconSanFrancisco } from '../Icon';

const FooterIconContainer = styled.div`
  color: ${(props) => props.theme.colors.blacks[50]};
  .sf {
    width: 50px;
    height: 32px;
    margin: 0 auto;
    position: relative;
    user-select: none;

    svg {
      position: relative;
      z-index: 1;
      path {
        fill: ${(props) => props.theme.colors.blacks[50]};
        transition: fill .4s ease-in;
      }
      &:hover path {
        fill: #c0362c;
      }
      &:active {
        transform: translateY(-1.5px);
      }
    }

    .burst {
      opacity: 0;
      position: absolute;
        bottom: -10px;
        right -8px;
      width: 17px;
      height: 17px;
      line-height: 17px;
      font-size: 17px;
      animation: burstSpin 8s linear infinite;
      transition:
        bottom .8s ease-in-out,
        opacity .8s;
    }

    &:hover {
      .burst {
        opacity: 1;
        bottom: 20px;
      }
    }

    @keyframes burstSpin { 100% { transform:rotate(360deg); } }
  }
`;

const SFIconContainer = styled.div`
  cursor: pointer;
`;

const FooterIcon = () => {
  return (
    <FooterIconContainer id={'FooterIcon'}>
      <div className="sf">
        <SFIconContainer>
          <IconSanFrancisco />
        </SFIconContainer>
        <span className="burst" role="img" aria-label="Sun">
          ☀️
        </span>
      </div>
    </FooterIconContainer>
  );
};

export default FooterIcon;
