import React from 'react';
import gsap from 'gsap';
import BaseBackground from './BaseBackground';
import { BackgroundContainer, DarkPath, LightPath } from './styles';

export default class Background2 extends BaseBackground {
  playAnimation = (animationProps = {}) => {
    BaseBackground.killAnimation(this.tl);
    const initialProps = { y: -20, scale: 1.2 };
    const resetProps = { y: 0, scale: 1, opacity: 1, duration: 0.6 };

    this.tl = gsap.timeline({ defaults: { duration: 0, opacity: 0 } });
    this.tl.to(this.treeOneRef, initialProps, '<');
    this.tl.to(this.treeTwoRef, initialProps, '<');
    this.tl.to(this.treeThreeRef, initialProps, '<');
    if (!animationProps.initialize) {
      this.tl.to(this.treeOneRef, resetProps, '>0.1');
      this.tl.to(this.treeTwoRef, resetProps, '<0.3');
      this.tl.to(this.treeThreeRef, resetProps, '<0.3');
    }
    return this.tl;
  };

  render() {
    return (
      <BackgroundContainer top={-92}>
        <BaseBackground.SVG height={144}>
          <g ref={(ref) => (this.treeThreeRef = ref)}>
            <LightPath d="M274,55a16,16,0,0,1,32,0V87a16,16,0,0,1-32,0Z" />
            <DarkPath d="M292,78.2674V126h-4V89.708l-9.854-9.854.708-.708L288,88.292V69h4v8.066l8.223-5.482.554.832Z" />
          </g>
          <g ref={(ref) => (this.treeTwoRef = ref)}>
            <LightPath d="M234,32a16,16,0,0,1,32,0V64a16,16,0,0,1-32,0Z" />
            <DarkPath d="M252,55.26733V103h-4V66.708l-9.854-9.854.708-.708L248,65.292V46h4v8.066l8.223-5.482.554.832Z" />
          </g>
          <g ref={(ref) => (this.treeOneRef = ref)}>
            <LightPath d="M194,64a16,16,0,0,1,32,0V96a16,16,0,0,1-32,0Z" />
            <DarkPath d="M212,87.26733V135h-4V98.708l-9.854-9.854.708-.708L208,97.292V78h4v8.066l8.223-5.482.554.832Z" />
          </g>
        </BaseBackground.SVG>
      </BackgroundContainer>
    );
  }
}
