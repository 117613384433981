import React, { useEffect, useState } from 'react';
import { Menu, Input } from '@matterapp/matter-ui';
import {
  KudosIcon,
  GiveFeedbackIcon,
  RequestFeedbackIcon,
  ViewDetailsIcon,
  RecurringIcon,
  EditIcon,
  iconLabels,
  TextAreaContainer,
  MembersHeaderContainer,
  MembersListContainer,
  DescriptionContainer,
  AdvisorsListItem,
  AdvisorsBody,
  AdvisorsHeader,
  ListContainer,
  AdvisorListItemContainer,
  Banner,
  BannerDescription,
  BannerTextContainer,
  Background,
  MembersEmptyMessage,
  MembersEmptyMessageBody,
  MembersEmptyMessageContainer,
} from '../../Shared';
import LoadingPanel from 'components/LoadingPanel';
import { generateRecurrenceTooltip } from '../../helpers';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import { debounce } from 'lodash';
import theme from '@matterapp/matter-theme';

const SearchInput = styled(Input)(() => ({
  height: theme.spacing.quad,
  marginTop: theme.spacing.half,
  marginBottom: theme.spacing.single,
  width: '100%',
  'input&': {
    '-webkit-appearance': 'none',
    padding: `${theme.spacing.threeQuarters} ${theme.spacing.single}`,
    borderColor: theme.colors.blacks[30],
    borderRadius: theme.borderRadius.M,
    borderWidth: '1px',
    transition: `border ${theme.transitions.times.singleAndHalf}`
  }
}));

const editRecurring = {
  icon: <EditIcon />,
  tooltipContent: iconLabels.EDIT,
};
// icon to render in menu, iconName to render in info modal
const menuIconMap = [
  {
    icon: <RequestFeedbackIcon />,
    tooltipContent: iconLabels.REQUEST,
  },
  {
    icon: <RecurringIcon />,
    tooltipContent: iconLabels.RECURRING,
  },
  {
    icon: <GiveFeedbackIcon />,
    tooltipContent: iconLabels.GIVE,
  },
  {
    icon: <KudosIcon />,
    tooltipContent: iconLabels.KUDOS,
  },
  {
    icon: <ViewDetailsIcon />,
    tooltipContent: iconLabels.INFO,
  },
];

const debouncedSearch = debounce((searchString, workspaceId, query) => {
  query({
    variables: {
      workspaceId,
      searchString
    }
  });
}, 300);

function WorkspacesMembersList(props) {
  const { 
    workspaceMembers, 
    workspace,
    onChangeSelectedAdvisor, 
    actionModalSelectorProps,
    currentUser, 
    handleSelectMember,
    isLoading: isLoadingComponent
  } = props;
  const { id: workspaceId, isAdmin, isOwner } = workspace;
  const [members, setMembers] = useState(workspaceMembers || []);
  const [searchString, setSearchString] = useState();
  const [searchMembers, { loading: isSearching, data }] = useLazyQuery(searchWorkspaceMembers);
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    if (data && !isSearching) {
      setMembers(data.searchMembers.map(member => ({
        ...member,
        fullName: member.person?.fullName,
        photoUrl: member.person?.photoUrl
      })));

      setUpdating(false);
    }
  }, [data, isSearching]);

  useEffect(() => {
    if (searchString?.length) {
      setMembers([]);
      debouncedSearch(searchString, workspaceId, searchMembers);
      setUpdating(true);
    } else {
      setMembers(workspaceMembers);
      setUpdating(false);
    }
  }, [searchString]);

  useEffect(() => {
    if (workspaceMembers?.length && !members?.length) {
      setMembers(workspaceMembers);
    }
  }, [workspaceMembers]);

  const isLoading = isUpdating || isSearching;

  function handleOpenInfoModal(member) {
    const person = {
      ...member.person,
      recurringFeedback: member.recurringFeedback
    };
    onChangeSelectedAdvisor(person);
    actionModalSelectorProps.openInfoModal();
  }


  function renderEmptyState() {
    const showInvite = isAdmin || isOwner;

    if (!showInvite) {
      return null;
    }
    
    return (
      <Banner>
        <Background />
        <BannerTextContainer>
          <BannerDescription>
            To make the most of your workspace,
            <br />
            we recommend a team of 5 or more.
          </BannerDescription>
        </BannerTextContainer>
      </Banner>
    );
  }

  function renderMembersListHeader() {
    return (
      <MembersHeaderContainer>
        <AdvisorsHeader
          className="members-list-header"
          title="Members"
          size="large"
        />
      </MembersHeaderContainer>
    );
  }

  function renderMemberDescription(member) {
    const description = member.person.headline || '-';
    
    return (
      <DescriptionContainer>
        {description}
      </DescriptionContainer>
    );
  }

  function renderMenu(member) {
    const { actionModalSelector } = actionModalSelectorProps;

    if (member && member.person.userId === currentUser.id) {
      return null;
    }
    const finalMenuIconMap = menuIconMap.slice(0);

    if (member.recurringFeedback) {
      finalMenuIconMap.splice(1, 1, editRecurring);
    }

    return (
      <Menu.Inline
        onClick={() => handleOpenInfoModal(member)}
        parentHoverComponent={AdvisorsListItem}
      >
        {finalMenuIconMap.map((menuItem, index) => {
          return (
            <Menu.Inline.Item
              key={`${member.person.userId}-${index}-menu-item`}
              onClick={async () => {
                const person = {
                  ...member.person,
                  recurringFeedback: member.recurringFeedback
                };
                await handleSelectMember(person);
                actionModalSelector(menuItem.tooltipContent, person);
              }}
              {...menuItem}
            />
          );
        })}
      </Menu.Inline>
    );
  }

  function renderSearchBar() {
    return (
      <TextAreaContainer>
        <SearchInput
          loading={false}
          onChange={({ target }) => {
            const searchString = target.value;
            setSearchString(searchString);
          }}
          value={searchString}
          placeholder="Enter a name"
          className="search-text-area"
        />
      </TextAreaContainer>
    );
  }

  function renderMembersEmptyMessage() {
    return (
      <MembersEmptyMessageContainer>
        <MembersEmptyMessage>NOBODY HAS JOINED YET</MembersEmptyMessage>
      </MembersEmptyMessageContainer>
    );
  }

  function renderEmptySearchMessage() {
    return (
      <MembersEmptyMessageContainer noMatches>
        <MembersEmptyMessage>
          NO MATCHES FOUND
          <MembersEmptyMessageBody>
            If a member is missing, ask your workspace admin to send them an invite
          </MembersEmptyMessageBody>
        </MembersEmptyMessage>
      </MembersEmptyMessageContainer>
    );
  }

  function renderList(list) {
    return (
      <MembersListContainer>
        {list.map((member) => {
          const { recurringFeedback, isSelf } = member;
          const person = {
            ...member.person,
            recurringFeedback,
            isSelf,
          };
          const isCurrentUser = member?.person.userId === currentUser.id;
          const tooltipContent = recurringFeedback ? generateRecurrenceTooltip(recurringFeedback.recurrence) : null;
          return (
            <AdvisorListItemContainer
              key={member.id}
            >
              <AdvisorsListItem
                key={member.id}
                actions={renderMenu(member)}
                content={renderMemberDescription(member)}
                advisor={person}
                tooltipContent={tooltipContent}
                onClick={() =>
                  isCurrentUser ? null : handleOpenInfoModal(member)
                }
              />
            </AdvisorListItemContainer>
          );
        })}
      </MembersListContainer>
    );
  }

  if (isLoadingComponent) { 
    return <LoadingPanel />;
  }  

  const showEmptyState = workspaceMembers?.length < 5;
  const enableSearch = workspaceMembers?.length > 15;
  const showEmptySearchMessage = searchString && !isLoading && !members?.length;

  let workspacesMembersList = null;

  if (members?.length) {
    workspacesMembersList = renderList(members);
  } else if (isLoading) {
    workspacesMembersList = <AdvisorsBody>Loading...</AdvisorsBody>;
  }

  let footer = null;
  const showFooterAddIcon = members?.length > 1;

  if (!showFooterAddIcon) {
    if (showEmptySearchMessage) {
      footer = renderEmptySearchMessage();
    } else if (!searchString) {
      footer = renderMembersEmptyMessage();
    }
  }

  return (
    <>
      {showEmptyState && !showEmptySearchMessage && renderEmptyState()}
      <ListContainer header={renderMembersListHeader()} footer={footer}>
        {enableSearch && renderSearchBar()}
        {workspacesMembersList}
      </ListContainer>
    </>
  );
}

export default WorkspacesMembersList;
