import { NavBar } from '@matterapp/matter-ui';
import { Resources } from '@matterapp/routing';
import { HELP_CENTER_URL } from '../../../app-consts';
import {
  HEADER_MENU_ITEM_SETTINGS,
  HEADER_MENU_ITEM_REFERRALS,
  HEADER_MENU_ITEM_SKILLS,
  HEADER_MENU_ITEM_PROFILE,
  HEADER_MENU_ITEM_REQUESTS,
  HEADER_MENU_ITEM_FEEDBACKS,
} from '../../../copy-consts';

export const getTabs = ({ workspacesProps = {} }) => {
  return {
    feedbacks: {
      label: HEADER_MENU_ITEM_FEEDBACKS,
      hasNotification: false,
      iconComponent: NavBar.Main.icons.feedbacks,
      to: Resources.homeMain.path(),
    },
    skills: {
      label: HEADER_MENU_ITEM_SKILLS,
      hasNotification: false,
      iconComponent: NavBar.Main.icons.skills,
      to: Resources.skillsMain.path({}, {
        workspaceId: workspacesProps.activeId
      }),
    }
  };
};

export const profileLinks = (onClickProfile) => [
  {
    label: HEADER_MENU_ITEM_PROFILE,
    onClick: onClickProfile,
  },
  {
    label: HEADER_MENU_ITEM_REQUESTS,
    to: Resources.feedbackRequestsMain.path(),
  },
  {
    label: HEADER_MENU_ITEM_SETTINGS,
    to: Resources.userSettingsMain.path(),
  },
  {
    label: HEADER_MENU_ITEM_REFERRALS,
    to: Resources.referralLink.path(),
  },
  {
    label: 'Help Center',
    href: HELP_CENTER_URL,
    linkProps: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    label: 'Sign Out',
    to: Resources.authSignout.path({}, { notification: true }),
  },
];
