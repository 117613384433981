import React from 'react';
import { personPropType } from '@matterapp/people';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

import Avatar from '../Avatar';
import { FEED_ITEM_FOOTERABLE_CLASSNAME } from '../Feed/consts';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  background: white;
  padding: 24px;
  min-width: 375px;
`;

const GiverContainer = styled.div`
  margin-right: 16px;
`;

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  color: ${theme.colors.blacks[90]};
  font-size: ${theme.fontSize.S};
  line-height: 24px;
  background: ${theme.colors.purples[5]};
  border: 1px solid ${theme.colors.purples[10]};
  border-radius: 24px;
`;

const SubmitButtonContainer = styled.div`
  width: auto;
  height: 24px;
  margin-top: 15px;
  margin-right: 16px;
  margin-left: 8px; // buffer the scrollbar

  button {
    font-size: ${theme.fontSize.base};
    line-height: 24px;
    font-weight: 500;
    text-decoration: none !important;
    &:hover:not([disabled]) {
      color: ${theme.colors.blues[40]};
    }
    &:disabled {
      color: ${theme.colors.blacks[50]};
    }
  }
`;

const Form = ({ author, submitButton, textarea }) => (
  <Container className={FEED_ITEM_FOOTERABLE_CLASSNAME}>
    <GiverContainer>
      <Avatar size={40} url={author.photoUrl} />
    </GiverContainer>
    <ContentContainer>
      {textarea}
      <SubmitButtonContainer>{submitButton}</SubmitButtonContainer>
    </ContentContainer>
  </Container>
);

Form.defaultProps = {
  submitButton: null,
};

Form.propTypes = {
  submitButton: PropTypes.node,
  author: personPropType.isRequired, // eslint-disable-line
  textarea: PropTypes.node.isRequired,
};

export default Form;
