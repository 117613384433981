import React from 'react';
import PropTypes from 'prop-types';
import { Colors, Container, Image, Overlay, Paths } from './styles';

const Thumbnail = (props) => {
  const {
    className,
    color,
    colorOnHover,
    height,
    left,
    top,
    width,
    ...otherProps
  } = props;
  return (
    <Container className={className} color={color} colorOnHover={colorOnHover}>
      <Image
        color={color}
        height={height}
        width={width}
        viewBox={`0 0 ${width} ${height}`}
        style={{
          transform: `translate(${left}px, ${top}px)`
        }}
        {...otherProps}
      />
      <Overlay />
    </Container>
  );
};

Thumbnail.defaultProps = {
  colorOnHover: true,
  top: 0,
  left: 0,
};

Thumbnail.propTypes = {
  /** If thumbnail should be colored on hover only. */
  colorOnHover: PropTypes.bool,
  /** Top image offset. */
  top: PropTypes.number,
  /** Left image offset. */
  left: PropTypes.number,
  /** Height of the image. */
  height: PropTypes.number,
  /** Width of the image. */
  width: PropTypes.number,
};

Thumbnail.Colors = Colors;
Thumbnail.Paths = Paths;

export { Colors, Thumbnail, Paths };
export default Thumbnail;
