import {
  ERROR_CANT_LEAVE_BLANK,
  ERROR_RESPONSE_FLOW_NOTE_TOO_LONG,
} from '../../copy-consts';

export default (values) => {
  const errors = {};
  const { quickNote } = values;
  const isNoteEmpty =
    typeof quickNote === 'undefined' ||
    quickNote === null ||
    quickNote.trim().length === 0;
  if (isNoteEmpty) {
    errors.quickNote = ERROR_CANT_LEAVE_BLANK;
  } else if (quickNote && quickNote.length > 1500) {
    errors.quickNote = ERROR_RESPONSE_FLOW_NOTE_TOO_LONG;
  }
  return errors;
};
