import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { ButtonNew } from '../../../Button';
import { ListItemActionBox } from '../../../List';
import AvatarBox from '../AvatarBox';
import ContentBox from '../ContentBox';
import { IconClose } from '../../../Icon';

const IconCloseContainer = styled.div`
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;

  padding: 0 6px; // Inc clickable area

  margin-left: 6px;

  svg {
    display: block;
    width: 16px;
    height: 16px;

    path {
      fill: ${() => theme.colors.blacks[40]};
    }
  }
  &:hover {
    svg path {
      fill: ${() => theme.colors.blacks[60]};
    }
  }
  &:active {
    svg path {
      fill: ${() => theme.colors.blacks[80]};
    }
  }
`;

const CTAButton = styled(ButtonNew).attrs({
  size: 'xsmall',
  primary: true,
})`
  width: 88px;
`;

const TransitionTextContainer = styled.div`
  margin-right: 14px;
`;

class BasicPeerSuggestionsListItem extends React.Component {
  renderCTAButton({ isDisabled }) {
    const { handlePickPeer, buttonCTAText } = this.props;
    return (
      <CTAButton onClick={handlePickPeer} disabled={isDisabled}>
        {buttonCTAText}
      </CTAButton>
    );
  }

  renderDismissButton({ isDisabled }) {
    const { handleDismissSuggestion } = this.props;
    return (
      <IconCloseContainer onClick={handleDismissSuggestion}>
        <IconClose isDisabled={isDisabled} />
      </IconCloseContainer>
    );
  }

  renderActionButtons() {
    const { isAccepting, isRejecting, isDisabled } = this.props;
    if (isAccepting || isRejecting) {
      return null;
    }
    return (
      <>
        {this.renderCTAButton({ isDisabled })}
        {this.renderDismissButton({ isDisabled })}
      </>
    );
  }

  renderActions() {
    const { renderTransitionText } = this.props;
    return (
      <ListItemActionBox>
        <TransitionTextContainer>
          {renderTransitionText()}
        </TransitionTextContainer>
        {this.renderActionButtons()}
      </ListItemActionBox>
    );
  }

  render() {
    return (
      <React.Fragment>
        <AvatarBox>{this.props.renderAvatar()}</AvatarBox>
        <ContentBox>{this.props.renderPeerContent()}</ContentBox>
        <ListItemActionBox>{this.renderActions()}</ListItemActionBox>
      </React.Fragment>
    );
  }
}

BasicPeerSuggestionsListItem.propTypes = {
  isAccepting: PropTypes.bool.isRequired,
  isRejecting: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handlePickPeer: PropTypes.func.isRequired,
  handleDismissSuggestion: PropTypes.func.isRequired,
  renderTransitionText: PropTypes.func.isRequired,
  renderPeerContent: PropTypes.func.isRequired,
  renderAvatar: PropTypes.func.isRequired,
  buttonCTAText: PropTypes.string.isRequired,
};

export default BasicPeerSuggestionsListItem;
