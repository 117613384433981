import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import { Button, Modal } from '@matterapp/matter-ui';
import FeedItemsList from 'modules/feed/FeedItemsList';

const StyledModal = styled(Modal)`
  .matter-ReactModal__Overlay {
    z-index: 100;
  }
`;

const ModalBody = styled(Modal.Body)`
  & .matter-feed-item-container {
    border: 0;
  }
`;
const ModalFooter = styled(Modal.Footer)`
  display: flex;
  padding: ${theme.spacing.single};
  ${theme.media.S`
    padding: ${theme.spacing.singleAndHalf};
  `}
`;

function CompletedRequestModal({ 
  selectedFeedback,
  isCompletedRequestModalOpen,
  handleCloseCompletedRequestModal
}) {
  return (
    <StyledModal
      transparent
      fullScreenMobile
      contentLabel="Complete Request Modal"
      isOpen={isCompletedRequestModalOpen}
      onClose={handleCloseCompletedRequestModal}
    >
      <ModalBody>
        <FeedItemsList 
          feedItemsList={[selectedFeedback]} 
          isLoading={!selectedFeedback} 
          feedItemsProps={{ hideFooter: true, expanded: true, showMenu: false, hideBottomMargin: true }}
        />
      </ModalBody>
      <ModalFooter>
        <Button.New
          primary
          fluid
          size="small"
          onClick={handleCloseCompletedRequestModal}
        >
          Done
        </Button.New>
      </ModalFooter>
    </StyledModal>
  );
}

CompletedRequestModal.propTypes = {
  selectedFeedback: PropTypes.object,
  isCompletedRequestModalOpen: PropTypes.bool.isRequired,
  handleCloseCompletedRequestModal: PropTypes.func.isRequired,
};

export default CompletedRequestModal;