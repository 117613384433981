"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.border = exports.borderRadius = exports.borderBase = void 0;
const borderBase = '1px solid';
exports.borderBase = borderBase;
const borderRadius = {
  S: '4px',
  M: '8px',
  L: '12px',
  pill: '100px'
};
exports.borderRadius = borderRadius;
const border = {
  base: borderBase,
  radius: borderRadius
};
exports.border = border;
var _default = border;
exports.default = _default;