import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

export const BUTTON_GROUP_ROLE = 'button-group';

const Group = (props) => {
  const { bordered, className, children, content } = props;
  return (
    <Container
      className={className}
      bordered={bordered}
      data-role={BUTTON_GROUP_ROLE}
    >
      {children}
      {content}
    </Container>
  );
};

Group.propTypes = {
  /**
   * If Button group is bordered. Removes border radius and outer borders on buttons.
   * Use only and in combination of extending with styled components to give buttons
   * a uniform border.
   */
  bordered: PropTypes.bool,
  /** Additional style class. Can further style if `bordered` prop passed in. */
  className: PropTypes.string,
  /** Buttons to display. */
  children: PropTypes.node,
  /** Buttons to display (Alt). */
  content: PropTypes.node,
};

Group.role = BUTTON_GROUP_ROLE;

export default Group;
