import React from 'react';
import Base from './Base';
import Completed from './Completed';
import ConnectYourAccount from './ConnectYourAccount';
import InvitePeers from './InvitePeers';
import PeerSuggestions from './PeerSuggestions';

const WelcomeCTA = (props) => {
  return <Base {...props} />;
};

WelcomeCTA.Base = Base;
WelcomeCTA.Completed = Completed;
WelcomeCTA.ConnectYourAccount = ConnectYourAccount;
WelcomeCTA.InvitePeers = InvitePeers;
WelcomeCTA.PeerSuggestions = PeerSuggestions;
export default WelcomeCTA;
