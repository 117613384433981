import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import Comment from './Comment';
import {
  AVATAR_SIZE,
  COMMENT_PLACEHOLDER,
  COMMENT_PROP_TYPE,
  PERSON_PROP_TYPE,
} from './consts';
import { Container, CommentsContainer, WriteCommentContainer } from './styles';
import TextArea from './TextArea';

const Comments = (props) => {
  const {
    avatarSize,
    className,
    comments,
    commentPlaceholder,
    currentUser,
    onClickAvatar,
    onClickSend,
    onClickDeleteComment,
  } = props;
  if (comments.length === 0) {
    return null;
  }
  return (
    <Container className={className}>
      <CommentsContainer>
        {comments.map((comment) => {
          return (
            <Comment
              comment={comment}
              key={comment.id}
              onClickAvatar={onClickAvatar}
              onClickDelete={onClickDeleteComment}
            />
          );
        })}
      </CommentsContainer>
      <WriteCommentContainer>
        <TextArea
          avatar={
            <Avatar
              size={avatarSize}
              email={currentUser.email}
              url={currentUser.photoUrl}
            />
          }
          onClickSend={onClickSend}
          placeholder={commentPlaceholder}
        />
      </WriteCommentContainer>
    </Container>
  );
};

Comments.defaultProps = {
  avatarSize: AVATAR_SIZE,
  commentPlaceholder: COMMENT_PLACEHOLDER,
  comments: [],
  currentUser: {},
};

Comments.propTypes = {
  /** Size of the avatar in pixels. */
  avatarSize: PropTypes.number,
  /** Additional style classes. */
  className: PropTypes.string,
  /** Placeholder text for comment. */
  commentPlaceholder: PropTypes.string,
  /** List of comments to display. */
  comments: PropTypes.arrayOf(COMMENT_PROP_TYPE),
  /** The current user. */
  currentUser: PERSON_PROP_TYPE,
  /** Callback when comment avatar is clicked. */
  onClickAvatar: PropTypes.func,
  /** Callback when comment is deleted. */
  onClickDeleteComment: PropTypes.func,
};

export default Comments;
