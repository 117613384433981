import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { ColorPalettes, getColorObj } from '@matterapp/matter-theme';

const DEFAULT_HEIGHT = 24;
const MINIMAL_PERCENTAGE_TO_DISPLAY_MOBILE = 12;
const MINIMAL_PERCENTAGE_TO_DISPLAY_DESKTOP = 7;

const Track = styled.div`
  height: ${({ height }) => height + 'px'};
  width: 100%;
  border-radius: ${({ height }) => height / 2}px;
  background: ${({ color }) => getColorObj(color)[10]}
  overflow: hidden;
`;

const Meter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${({ width }) => width > MINIMAL_PERCENTAGE_TO_DISPLAY_MOBILE ? width : MINIMAL_PERCENTAGE_TO_DISPLAY_MOBILE}%;
  height: ${({ height }) => height + 'px'};
  background: ${({ color }) => getColorObj(color)[50]}
  transition: width 0.2s;
  box-sizing: border-box;
  color: white;
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  font-weight: ${theme.fontWeight.bold};
  >span {
    margin-right: ${theme.spacing.half};
  }
  ${theme.media.S`
    width: ${({ width }) => width > MINIMAL_PERCENTAGE_TO_DISPLAY_DESKTOP ? width : MINIMAL_PERCENTAGE_TO_DISPLAY_DESKTOP}%;
  `}
`;

const Simple = ({ color, percent, percentLabel, height }) => (
  <Track color={color} height={height}>
    <Meter color={color} width={percent} height={height}>
      <span>{percentLabel}</span>
    </Meter>
  </Track>
);

Simple.defaultProps = {
  color: ColorPalettes.Red,
  percent: 0,
  height: DEFAULT_HEIGHT,
};

Simple.Track = Track;
Simple.Meter = Meter;

Simple.propTypes = {
  color: PropTypes.oneOf(Object.keys(ColorPalettes)),
  percent: PropTypes.number, // 0 - 100
  percentLabel: PropTypes.string,
};

export default Simple;
