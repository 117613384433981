import React from 'react';
import gsap from 'gsap';
import BaseBackground from './BaseBackground';
import { BackgroundContainer, DarkPath, LightPath, WhitePath } from './styles';

export default class Background8 extends BaseBackground {
  playFlag = () => {
    const tl = gsap.timeline({
      defaults: { ease: 'power1.out' },
    });
    const swingFlag = { scaleX: -0.6, duration: 0.6, };
    const showFlag = { scaleX: 1, duration: 0.8, };
    tl.to(this.flagRef, swingFlag, '<');
    tl.to(this.flagRef, showFlag, '>');
    return tl;
  };

  playAnimation = (animationProps = {}) => {
    BaseBackground.killAnimation(this.tl);
    const resetProps = {
      x: 0,
      y: 0,
      opacity: 1,
      duration: 0.5,
    };

    this.tl = gsap.timeline({ defaults: { duration: 0 } });
    this.tl.to(this.mountainOneRef, { x: -10, opacity: 0 }, '<');
    this.tl.to(this.mountainTwoRef, { x: -34, opacity: 0 }, '<');
    this.tl.to(this.mountainThreeRef, { x: -50, opacity: 0 }, '<');
    this.tl.to(this.flagRef, { transformOrigin: 'left center' }, '<');

    if (!animationProps.initialize) {
      this.tl.to(this.mountainOneRef, resetProps, '<');
      this.tl.to(this.mountainTwoRef, resetProps, '<');
      this.tl.to(this.mountainThreeRef, resetProps, '<');
      this.tl.add(this.playFlag(), '<');
      this.tl.add(this.playFlag(), '>');
    }
    return this.tl;
  };

  render() {
    return (
      <BackgroundContainer top={0}>
        <BaseBackground.SVG height={204}>
          <LightPath
            ref={(ref) => (this.mountainOneRef = ref)}
            d="M261.526,84L195,148h138L261.526,84z"
          />
          <LightPath
            ref={(ref) => (this.mountainTwoRef = ref)}
            d="M374.732,0L256,148h246.295L374.732,0z"
          />
          <g
            ref={(ref) => (this.mountainThreeRef = ref)}
          >
            <DarkPath d="M392.972,37.93L296,158h201.157L392.972,37.93z" />
            <WhitePath d="M425.029,106.77l-32.057-68.84L497.958,158h-91.362l-13.624-28.82h21.638l-8.815-22.41H425.029z" />
            <DarkPath d="M394.299,2v37.9h-2V2H394.299z" />
            <DarkPath
              ref={(ref) => (this.flagRef = ref)}
              onMouseEnter={this.playFlag}
              d="M394.15,4.03h21.685v16.25H394.15V4.03z"
            />
          </g>
        </BaseBackground.SVG>
      </BackgroundContainer>
    );
  }
}
