import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../Avatar';
import TimeAgo from '../../TimeAgo';
import { AVATAR_SIZE, COMMENT_PROP_TYPE } from '../consts';
import Menu from '../../Menu';
import {
  AvatarContainer,
  AuthorFullName,
  Container,
  ContentContainer,
  MessageContainer,
  MessageHeader,
  TimeAgoContainer,
} from './styles';

const Comment = (props) => {
  const {
    avatarSize,
    comment,
    isCanAddRemove,
    isBeingAdded,
    isBeingRemoved,
    onClickAvatar,
    onClickDelete,
  } = props;
  const { author, canDelete, createdAt, message } = comment;
  const handleClickAvatar = (e) =>
    onClickAvatar(e, { ...props, person: author });
    
  return (
    <Container
      isCanAddRemove={isCanAddRemove}
      isBeingAdded={isBeingAdded}
      isBeingRemoved={isBeingRemoved}
    >
      <AvatarContainer onClick={handleClickAvatar}>
        <Avatar size={avatarSize} email={author.email} url={author.photoUrl} />
      </AvatarContainer>
      <ContentContainer>
        <MessageHeader>
          <AuthorFullName onClick={handleClickAvatar}>
            {author.fullName}
          </AuthorFullName>
          <span>&nbsp;</span>
          <TimeAgoContainer>
            <TimeAgo date={createdAt} />
          </TimeAgoContainer>
        </MessageHeader>
        <MessageContainer>{message}</MessageContainer>
      </ContentContainer>
      {canDelete && (
        <Menu.Dark 
          comment={comment}
          menuMap={[
            {
              menuLabel: 'Delete Comment',
              handleAction: () => onClickDelete(null, { comment}),
            }
          ]}
        />
      )}
    </Container>
  );
};

Comment.defaultProps = {
  avatarSize: AVATAR_SIZE,
  comment: {},
  onClickAvatar: () => null,
  onClickDelete: () => null,
};

Comment.propTypes = {
  avatarSize: PropTypes.number,
  canDelete: PropTypes.bool,
  comment: COMMENT_PROP_TYPE,
  isCanAddRemove: PropTypes.bool,
  isBeingAdded: PropTypes.bool,
  isBeingRemoved: PropTypes.bool,
  onClickAvatar: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default Comment;
