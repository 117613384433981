import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import Left from './Left';
import Main from './Main';
import {
  Container,
  LeftPanel,
  LeftPanelTopBar,
  MainPanel,
  TopBar,
  Wrapper,
} from './styles';

const Panel = forwardRef((props, ref) => {
  const { mainRef } = ref;
  const {
    children,
    isLeftPanelActive,
    left,
    main,
    top,
    ...modalProps
  } = props;
  return (
    <Modal
      fullScreenMobile
      overlayTheme={Modal.overlayThemes.LIGHT}
      size={Modal.sizes.MAX}
      useAltLockedClass
      {...modalProps}
    >
      <Container>
        <TopBar hasTopBar={!!top}>{top}</TopBar>
        <Wrapper>
          <LeftPanel isLeftPanelActive={isLeftPanelActive}>
            <LeftPanelTopBar hasTopBar={!!top}>
              {top}
            </LeftPanelTopBar>
            {left}
          </LeftPanel>
          <MainPanel hasTopBar={!!top} ref={mainRef}>
            {main}
            {children}
          </MainPanel>
        </Wrapper>
      </Container>
    </Modal>
  );
});

Panel.propTypes = {
  /** Contents to show in main panel. */
  children: PropTypes.node,
  /** Used to display left panel in mobile view. In desktop, both panels are shown. */
  isLeftPanelActive: PropTypes.bool,
  /** Contents to show in left panel. */
  left: PropTypes.node,
  /** Alternative prop to show content in main panel. */
  main: PropTypes.node,
  /** Contents to show in top bar. */
  top: PropTypes.node,
  /** Contents to show in main top bar. */
  mainTop: PropTypes.node,
};

Panel.Left = Left;
Panel.Main = Main;

export default Panel;
