import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import getRewardsTotalBalance from 'graphql-queries/queries/admin/getRewardsTotalBalance';
import getTenantsWithFundingQuery from 'graphql-queries/queries/admin/getTenantsWithFunding';
import { 
  Box, 
  Typography, 
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell
} from '@mui/material';
import { Resources } from '@matterapp/routing';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { 
  NoResultsText
} from 'components/TableStyles';

const Caption = styled.caption({
  height: '50px',
  textAlign: 'center',
  'p:first-child': {
    marginTop: '10px'
  }
});

export default function RewardsFundingBreakdown() {
  const { data: totalBalanceData } = useQuery(getRewardsTotalBalance);
  const { loading, error, data } = useQuery(getTenantsWithFundingQuery);
  const { adminGetTenantsWithFunding: tenants = [] } = data || {};
  const { adminRewardsTotalBalance } = totalBalanceData || {};

  if (loading) {
    return <p>Loading ...</p>;
  }

  if (error) {
    return `Error! ${error}`;
  }

  return (
    <Box sx={{ m: 4, pb: 10 }}>
      <BreadCrumbs
        crumps={[
          {
            label: 'Admin',
            href: Resources.adminAccess.path()
          },
          {
            label: 'Tenant Rewards Funding Breakdown', 
            href: null
          }
        ]}
      />
      <Typography variant="h4" component='p' sx={{ mb: 2, mt: 2 }}>
        Unearned Revenue: ${((adminRewardsTotalBalance?.totalBalance || 0) / 100).toLocaleString()} USD, across {adminRewardsTotalBalance?.numberOfTenants} tenants
      </Typography>
      <Typography variant="h4" component='p' sx={{ mb: 2 }}> 
        Tenants with Prepay Enables or with Balances
      </Typography>
      <Table size='large' sx={{ mt: 2 }}>
        {!tenants.length ? (
          <Caption>
            <NoResultsText variant='h6' component='p'>
              No records
            </NoResultsText>
          </Caption>
        ) : null}
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 0 }}>Tenant Name</TableCell>
            <TableCell sx={{ pl: 0 }}>Workspace Id</TableCell>
            <TableCell sx={{ pl: 0 }}>Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tenants.map(({ id, name, rewardsFundingSettings, workspaces }) => {
            return (
              <TableRow key={id}>
                <TableCell size='large' sx={{ width: '50%', pl: 0 }}>
                  {name}
                </TableCell>
                <TableCell sx={{ pl: 0 }}>
                  {workspaces.map(({ id }) => id).join(', ')}
                </TableCell>
                <TableCell sx={{ pl: 0 }}>
                  ${(rewardsFundingSettings.balance / 100).toLocaleString()}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
