import React from 'react';
import gsap from 'gsap';
import Animation from '../../../../Animations/Animation';
import BrushStrokes1 from '../../../../Backgrounds/Patterns/BrushStrokes1';

export default class Home extends Animation {
  constructor(props) {
    super(props);
    this.refs = {};
    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
    this.ref3 = React.createRef();
    this.ref4 = React.createRef();
    this.ref5 = React.createRef();
    this.ref6 = React.createRef();
    this.ref7 = React.createRef();
    this.ref8 = React.createRef();
    this.ref9 = React.createRef();
  }

  componentWillUnmount = () => {
    this.tl.kill();
  };

  initAnimation = () => {
    const tl = gsap.timeline({
      defaults: { scale: 1.6, opacity: 0, duration: 0 },
    });
    tl.to(this.ref1.current, { scale: 4 }, '<');
    tl.to(this.ref2.current, { rotation: 90 }, '<');
    tl.to(this.ref3.current, { rotation: -45 }, '<');
    tl.to(this.ref4.current, { rotation: 60 }, '<');
    tl.to(this.ref5.current, { rotation: -10 }, '<');
    tl.to(this.ref6.current, { rotation: -70, y: 80 }, '<');
    tl.to(this.ref7.current, { rotation: 25, y: -40, scale: 2 }, '<');
    tl.to(this.ref8.current, { rotation: -80, y: 50 }, '<');
    tl.to(this.ref9.current, { rotation: -35 }, '<');
  };

  playAnimation = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = {
      x: 0,
      y: 0,
      opacity: 1,
      scale: 1,
      rotation: 0,
      duration: 1.3,
    };
    tl.add(this.initAnimation);

    tl.to(this.ref1.current, { ...resetProps }, '<0.2');
    tl.to(this.ref2.current, { ...resetProps }, '<0.3');
    tl.to(this.ref3.current, { ...resetProps }, '<0.2');
    tl.to(this.ref4.current, { ...resetProps }, '<0.3');
    tl.to(this.ref5.current, { ...resetProps }, '<0.1');
    tl.to(this.ref6.current, { ...resetProps }, '<0.2');
    tl.to(this.ref7.current, { ...resetProps }, '<0.1');
    tl.to(this.ref8.current, { ...resetProps }, '<0.2');
    tl.to(this.ref9.current, { ...resetProps }, '<0.1');

    this.tl = tl;
    return tl;
  };

  resetAnimation = () => {};

  render() {
    return (
      <BrushStrokes1
        ref={{
          ref1: this.ref1,
          ref2: this.ref2,
          ref3: this.ref3,
          ref4: this.ref4,
          ref5: this.ref5,
          ref6: this.ref6,
          ref7: this.ref7,
          ref8: this.ref8,
          ref9: this.ref9,
        }}
      />
    );
  }
}
