import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Loader } from '@matterapp/matter-ui';
import getSubscriptionPlans from 'graphql-queries/queries/billing/getSubscriptionPlans';
import createStripeSubscription from 'graphql-queries/mutations/billing/createStripeSubscription';
import sendCheckoutSuccess from 'graphql-queries/queries/billing/sendCheckoutSuccess';
import validatePromoCode from 'graphql-queries/queries/billing/validatePromoCode';
import BillingInfo from './BillingInfo';
import PromoSection from './PromoSection';
import StripeBilling from './StripeBilling';
import { LoadingContainer } from '../styles';
import { gtagCall } from 'libs/tracking';
import { useQueryParams } from 'hooks';
import UserContext from 'context/UserContext/UserContext';

const handleTrackPurchase = (value) => {
  gtagCall('event', 'conversion', {
    'send_to': 'AW-801897506/3x4UCJam_8cCEKL4r_4C',
    'value': value,
    'currency': 'USD',
    'transaction_id': ''
  });
};

export default function PaymentPanel({ workspace, tenant }) {
  const { refetchCurrentUser } = useContext(UserContext);
  const { queryParams } = useQueryParams();
  const { id: workspaceId, slackWorkspace, teamsWorkspace } = workspace || {};
  const { pulseUpgrade } = queryParams || {};
  const { primaryChannel } = slackWorkspace || {};
  const { teamName } = teamsWorkspace || {};
  const [intervalSelected, updateSelectedInterval] = useState('month');
  const [addOnsSelected, updateAddOnsSelected] = useState([]);
  const [subscription, updateSubscription] = useState(null);
  const [promocode, updatePromocode] = useState(null);

  const { data: plansData, loading: isLoadingPlans } = useQuery(getSubscriptionPlans, {
    variables: {
      workspaceId
    },
    skip: !workspaceId
  });

  const subscriptionPlans = plansData?.subscriptionPlans?.plans || [];
  const addOns = plansData?.subscriptionPlans?.addOns || [];
  const totalMembers = plansData?.subscriptionPlans?.totalMembers;
  const selectedProduct = subscriptionPlans.find(({ interval }) => interval === intervalSelected);
  const selectedAddOns = addOns
    .filter(({ productId, interval }) => addOnsSelected.includes(productId) && interval === intervalSelected);

  const { data: promoData, loading: loadingPromocode, refetch: refetchCoupon } = useQuery(validatePromoCode, {
    variables: {
      promocode,
      productIds: [selectedProduct?.productId, ...addOnsSelected],
      planIds: [selectedProduct?.id, ...selectedAddOns.map(({ id }) => id)],
      totalMembers
    },
    skip: !promocode || !selectedProduct
  });
  const { validatePromoCode: couponDetails } = promoData || {};

  const [createSubscription, { data: subscriptionData }] = useMutation(
    createStripeSubscription
  );

  const [checkoutSuccessMutation] = useMutation(
    sendCheckoutSuccess
  );

  useEffect(() => {
    if (pulseUpgrade && addOns?.length) {
      updateAddOnsSelected([addOns.find(({ interval }) => intervalSelected === interval).productId]);
    }
  }, [pulseUpgrade, addOns]);

  useEffect(() => {
    if (subscriptionData?.createStripeSubscription) {
      updateSubscription(subscriptionData?.createStripeSubscription);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (selectedProduct && promocode) {
      refetchCoupon({
        variables: {
          promocode,
          productId: selectedProduct.productId,
          planId: selectedProduct.id,
          totalMembers
        }
      });
    }
  }, [intervalSelected]);

  if (isLoadingPlans) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const applyPromoCode = (promocode) => {
    updatePromocode(promocode);
  };

  async function onSubmitPayment() {
    await createSubscription({
      variables: {
        workspaceId,
        promocodeId: couponDetails?.isValid ? couponDetails?.id : undefined,
        priceId: selectedProduct.id,
        addOns: addOns
          .filter(({ productId, interval }) => selectedAddOns.map(({ productId }) => productId).includes(productId) && intervalSelected === interval)
          .map(({ id }) => id),
        totalMembers
      }
    });
  }

  async function onPaymentComplete() {
    handleTrackPurchase(subscription.totalAfterDiscount / 100);

    await checkoutSuccessMutation(({
      variables: {
        workspaceId
      }
    }));

    refetchCurrentUser();
  }

  return (
    <>
      <BillingInfo 
        intervalSelected={intervalSelected} 
        updateSelectedInterval={updateSelectedInterval} 
        subscriptionPlans={subscriptionPlans}
        totalMembers={totalMembers}
        coupon={couponDetails}
        primaryChannel={primaryChannel}
        teamName={teamName}
        isEnterprise={tenant?.isEnterprise}
        addOns={addOns}
        selectedAddOns={selectedAddOns.map(({ productId }) => productId)}
        toggleAddOns={(priceId) => {
          if (addOnsSelected.includes(priceId)) {
            updateAddOnsSelected(addOnsSelected.filter((id) => id !== priceId));
          } else {
            updateAddOnsSelected([...addOnsSelected, priceId]);
          } 
        }}
      />
      {subscriptionPlans && <PromoSection
        applyPromoCode={applyPromoCode}
        isValid={couponDetails?.isValid}
        errorMessage={couponDetails?.errorMessage}
        isLoading={loadingPromocode}
      />}
      <StripeBilling 
        onSubmit={onSubmitPayment} 
        subscription={subscription} 
        onPaymentComplete={onPaymentComplete}
        workspaceId={parseInt(workspaceId)}
      />
    </>
  );

};
