import React from 'react';
import styled from 'styled-components';
import Base from './Base';

const DEFAULT_HEIGHT = 85.45763;
const DEFAULT_WIDTH = 86.51522;
const DEFAULT_VIEWBOX = `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`;

const Background = styled(Base.path).attrs({
  fillColor: '#FF6588',
  grayScaleColor: '#D8D8D9',
  d: `M0,35.9C4.1,18.1,23.2,1.9,41.5,0c24.6,2.9,49,21.6,44.5,48.7c-5.2,24.9-29.6,41-54.6,35.8
  c-0.4-0.1-0.9-0.2-1.3-0.3C9.9,77.9-0.4,55.2,0,35.9z M51.1,14.3c2.6-1.4,4.4-1.4,7.2-0.1C54.4,8.7,40.7,5.4,51.1,14.3z M38.5,63
  c1.7-0.6,0.5-1.8,0.1-3.1C35,52.9,35.4,59.6,38.5,63z M27.8,68c1.7,1.7,3.7,3,6,3.8C32.3,69.9,30.2,68.5,27.8,68z M61.9,54
  c0.2-1.9,1.1-6.3,0.4-6.9C62.2,49.2,60.3,52.5,61.9,54z M50,75.5c0.5,1.6,1.8,2.2,3.8,1.3C52.6,75.9,51.6,74.7,50,75.5L50,75.5z
   M27.7,34.9c-1.3-0.7-1.6,0-2,1C25.5,38,27.1,35.5,27.7,34.9L27.7,34.9z M39.6,11.7c0.6,0.9,2.6,1.5,3.6,2.3
  C42.5,12.7,41.1,11.8,39.6,11.7L39.6,11.7z M25.1,65.6c1,0.7,1.9,2.7,2.6,0.9C26.8,66.3,26,65.6,25.1,65.6z M64.9,31.8
  c0.5-0.4,1-2.5-0.5-1.9C64.6,30.5,64.8,31.1,64.9,31.8z M28.2,53.7c0,1,0.4,2,1,2.8C29,55.5,28.7,54.5,28.2,53.7z M41.8,16.4
  c-0.5-1-0.7-1.1-1.2-1C40.7,16,41.2,16.1,41.8,16.4z M32.9,11.7c-0.4,0.3-0.8,0.6-1.1,1.1C32.4,12.5,33.5,12.5,32.9,11.7z
   M30.1,58.3c-0.3-0.7-0.3-1.9-0.8-2.1C29.2,56.9,29.5,57.7,30.1,58.3z M10.2,42.6c-0.1,0.8-0.1,1,0.3,1.5
  C10.5,43.6,10.7,43.1,10.2,42.6z M63.6,33.8c0.4,0.3,0.5,0.9,0.8,0.7C64.6,33.7,64.6,33.6,63.6,33.8L63.6,33.8z M40,9.2
  c-0.2,0.4,0.6,0.3,0.9,0.5C40.8,9,40.3,9.3,40,9.2L40,9.2z M10.7,46.2c0.3-0.1,0.2-0.7-0.1-1.1C10.6,45.6,10.6,45.9,10.7,46.2
  L10.7,46.2z M10.4,44.6c0.1,0.2,0.2,0.1,0.3,0C10.6,44.5,10.5,44.4,10.4,44.6L10.4,44.6z M40,65.2c0.4,0.1,0.5-0.1,0.5-0.4
  C40.3,65,40.2,65.1,40,65.2L40,65.2z`,
})``;

const Overlay = styled(Base.path).attrs({
  fillColor: '#4F52B1',
  grayScaleColor: '#E6E6E7',
  d: `M2.6,30.7c-6.8,12.5,1.4,30.8,6.4,38.1c0.2,0.3,0.4,0.5,0.7,0.8c24.6,34.1,82.7,9,76.1-34.9
  C80,14,56.6-2.5,35.1,1.3C24.1,1.3,11.8,12.8,7,22.4c-1.3,0,0.2-0.8,0.5-1.1c-0.4-0.6-0.2-0.6,0-1.1C4.4,23.1,3.6,26.8,2.6,30.7z
   M77,63.9c0-1.1-1.2,1-0.8-0.8c0.7,0,0.6-0.6,0.9-0.9C77.3,62.6,77.7,63.7,77,63.9z M76.4,61.7c0,0.2,0,0.4,0.1,0.7
  C76.2,62.1,76.2,61.9,76.4,61.7L76.4,61.7z M76.3,60.3c0.2-0.2,0.4,0.1,0.1,0.3C76.4,60.5,76.3,60.4,76.3,60.3L76.3,60.3z
   M81.9,43.5c0.1,0.8,0.1,1.7-0.2,2.5C80.9,45.1,79.9,43.1,81.9,43.5L81.9,43.5z M67.4,38.7c0.7-1.7,1-8.5,5.2-7.5
  c2.3,1.2,4.2-2.1,7.4-0.2c-0.6,1.6,2.7,4.8,0.7,7.4c-0.7,3.5-3.3,5.5-6.9,5.9c-0.6,0-4.3-0.4-2.3-0.9c0.4,0.2,1,0.8,1.3,0.2
  c0.2-1.1-4.3-2.2-2-0.5C68.9,42.7,68.2,40.2,67.4,38.7z M73.2,53.1c0.1,0.4,0.2,0.7-0.2,1C72.8,53.8,72.9,53.3,73.2,53.1z
   M72.5,55.2c0-0.3,0.1-0.5,0.1-0.8C73.1,54.6,73.1,54.8,72.5,55.2z M65,67.6c0.3-4.3,2.9-8.9,7.5-9.8c1-0.2,4.5-1.3,4.2,0.6
  c-3.1-0.1-0.6,3.4-2.7,4.9c3.2-1.2,0.3,3.8,2.3,1.7c1.1-0.5-1.1,2.9-1.5,1.8c-0.9,3-5.6,2.1-7.8,3.7c0.4-0.8-0.7-1.6-1.2-0.8
  C65.9,68.6,64.1,68.3,65,67.6z M75.7,61.6C75.6,61.6,75.6,61.6,75.7,61.6C75.3,61.2,75.7,61.1,75.7,61.6z M62.9,54.3
  c0.3,0.1,0.4,0.4,0.6,0.7C63,55.3,62.5,54.7,62.9,54.3z M62.8,56.7c-0.4,0-0.5-0.3-0.1-0.6C62.7,56.3,62.8,56.5,62.8,56.7z
   M62.4,27.4c0.2,0.2,0.3,0.2,0,0.5C62.2,27.7,62.2,27.6,62.4,27.4z M61.5,25.6c0,0,0,0.1-0.1,0.2c0-0.1,0-0.2,0-0.3
  C61.4,25.5,61.4,25.6,61.5,25.6L61.5,25.6z M18.2,67c-0.3-0.2-0.5-0.6-0.5-1C18.3,66.3,18.5,66.6,18.2,67L18.2,67z M18.9,67
  c-0.1-0.1-0.3-0.2-0.2-0.3C18.8,66.8,19.1,66.9,18.9,67z M21.8,33.7c-1.3-0.8-3.3-1.3-3.8-2.6C18.8,31.6,22.7,33.1,21.8,33.7
  L21.8,33.7z M25.2,34.3c-6.1-0.3,3.2-0.6,5.4-1.4C29.5,34.3,26.9,34.2,25.2,34.3L25.2,34.3z M27.2,54.3c-0.1,0.2-0.5-0.2-0.3-0.3
  C27.1,54.1,27.2,54.2,27.2,54.3L27.2,54.3z M46.2,71.8c0.5-0.3,0.7-0.2,1.1,0.3C46.9,72,46.6,72,46.2,71.8L46.2,71.8z M53.9,64.2
  c0.2-0.6,0-1.2,0.8-0.8C54.5,63.7,54.4,64.1,53.9,64.2L53.9,64.2z M55.5,62c-0.3-0.1-0.8,0-1.1-0.2C54.6,61,55.7,61.7,55.5,62z
   M51.9,32.3c5.6-5.6,3.7,11-0.2,4.2C48.2,35.7,51.8,36.6,51.9,32.3L51.9,32.3z M47.1,15c1.1-1.6,0.6-3.2,1.9-4.3
  c2.2-0.2,2.7,2.9,2.8,4.7c0.1,1.2-1.2,0.1-1.5,1.1c-0.4,0.4-0.2,1.2,0.5,1c-0.1,1.2-1.8,0.3-2.3-0.5C47.3,16.8,46,16.5,47.1,15
  L47.1,15z M40.9,51.7c5.7-0.4,10.3,5.2,10,10.8c-0.3,1.9,0,1.1,0.9,0.5c0,0.7,0.1,2.3-1,1.7c-3.8,6.7-8.6,14.4-17.2,11.7
  c-5-1.5-8-4.7-7.2-9.6c0.1-1.6-0.3-4.8,1.5-4.8c-0.7-1.5-3.3-0.9,0-3.2C28.8,54.9,35.8,51.9,40.9,51.7L40.9,51.7z M30.8,32.6
  c0-0.2,0.2-0.2,0.3,0C31,32.8,30.9,32.8,30.8,32.6L30.8,32.6z M31.2,41.7c0.1-0.1,0.1,0,0.1,0.1C31.3,41.9,31.2,41.8,31.2,41.7
  L31.2,41.7z M35.5,37.3c0.1-0.6,0.5-0.7,1-0.9C36.3,36.7,35.9,37,35.5,37.3L35.5,37.3z M36.1,49.7C36,49.6,36.1,49.7,36.1,49.7
  C36.1,49.9,35.7,49.7,36.1,49.7L36.1,49.7z M37.5,35.5c0.2,0.1,0.2,0.1,0,0.4V35.5z M38.5,51.1c-0.4,0-0.8-0.2-1.1-0.5
  C38.1,50.7,38.1,50.7,38.5,51.1L38.5,51.1z M39.9,45.1c0.6,1.5-1.6-0.5-1.6-1.3C39,44.1,39.4,44.7,39.9,45.1L39.9,45.1z
   M38.6,34.1c0.3,0.5-0.8,0.9-1.1,1.3C35.9,36.4,37.9,34.5,38.6,34.1z M29.6,2.9c1.2-1.3,4-0.5,1.6,0.2C30.7,2.9,29.4,3.9,29.6,2.9
  L29.6,2.9z M30.7,13c-0.4,0.2-0.5,0.2-1,0C29.9,12.8,30.3,13,30.7,13L30.7,13z M27.2,3.3c0.5-0.2,0.6,0.7,0.1,0.7
  C26.2,3.9,26.1,3.6,27.2,3.3L27.2,3.3z M15.7,25.1c0.1-2.2,0.3-4.3,2.1-5.8c0.7,1.9,2.9,0.8,1.4-0.7c0.3,3.7-2.9-1.9,2.2-2.4
  c-1.2,0.8-1.1,2,0.1,0.6c0.4-0.2,0.4-0.7,0.4-1.1c-0.4-1.3,2.2-2,0.8-0.4c0,0.9,2.5-1.4,3.3-1.4c0.7,0,1.5,0,2.2-0.2
  c-0.4-1.4-3,0.3-4.1,0.1c-2.3,0.5,4.2-2,5.1-0.8c-1.4,0.8,2,1.5,2.6,2.1c-0.4,0.8,0.4,1.1,1.1,1c0.7,1.7,3.8,2.6,3.3,5.5
  c0.2-0.2,0.3-0.3,0.5-0.5c1.5,7-1.5,5.8-3.7,10.6c-0.9-0.7-1,1.8-2,0.6c0.6-0.4,1.7-1.1,0.3-1.2c-0.4,0.7-1.2,1.1-2,1.2
  c-0.5,1.1-2.1-1-1.7,0.8c-3.1,1-9.4-1.9-10.7-5.2c-1-1.4,0.2,2.4,0.9,2.9C15.8,30.4,16.2,26.9,15.7,25.1z M7.3,44
  c3.1-7.6,13.9-9,11.5,1.7C17.5,53.2,5.8,52.6,7.3,44L7.3,44z M5.6,27.6c-0.1,0-0.2-0.2,0-0.2C5.7,27.4,5.7,27.6,5.6,27.6L5.6,27.6
  z M5.1,24.4c0.6-2.2,2.6-1.9,1.1-0.7C7.8,23.5,4.8,27.1,5.1,24.4L5.1,24.4z M4.1,26.1c0.1-1.5,1.9-0.3,0.6,0.5
  C3.9,27.8,3.7,27.1,4.1,26.1L4.1,26.1z`,
})``;

const Mars = (props) => {
  return (
    <Base
      {...props}
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      viewBox={DEFAULT_VIEWBOX}
    >
      <Background />
      <Overlay />
    </Base>
  );
};

export default Mars;