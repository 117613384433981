import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Layout = styled.div`
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const NameFieldContainer = styled.div`
  margin-bottom: 16px;
`;

const DescriptionFieldContainer = styled.div`
  margin-bottom: 16px;
`;

const ColorPickerContainer = styled.div`

`;

const ColorPreviewContainer = styled.div`
  margin-top: 22px;
`;

const EditorLayout = ({
  skillNameField,
  skillDescriptionField,
  skillColorPicker,
  skillColorPreview,
}) => (
  <Layout>
    <NameFieldContainer>
      {skillNameField}
    </NameFieldContainer>
    <DescriptionFieldContainer>
      {skillDescriptionField}
    </DescriptionFieldContainer>
    <ColorPickerContainer>
      {skillColorPicker}
    </ColorPickerContainer>
    <ColorPreviewContainer>
      {skillColorPreview}
    </ColorPreviewContainer>
  </Layout>
);

EditorLayout.propTypes = {
  skillNameField: PropTypes.node.isRequired,
  skillDescriptionField: PropTypes.node.isRequired,
  skillColorPicker: PropTypes.node.isRequired,
  skillColorPreview: PropTypes.node.isRequired,
};

export default EditorLayout;
