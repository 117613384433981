import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../../Button';
import Modal from '../ModalOld';
import theme from '@matterapp/matter-theme';

const ModalHeader = styled(Modal.Header)`
  text-align: center !important;
`;

export default class ModalFlow extends React.PureComponent {
  static propTypes = {
    contentLabel: PropTypes.string,
    handleCloseModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        renderTopRight: PropTypes.func,
        renderBody: PropTypes.func.isRequired,
        renderFooter: PropTypes.func,
        title: PropTypes.string,
        handleGoBack: PropTypes.func.isRequired,
      })
    ).isRequired,
  };

  static Container = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
    overflow-x: hidden;

    ${() => theme.media.medium`
      padding-left: 0;
      padding-right: 0;
    `}
  `;

  static Heading = styled.h2`
    width: 368px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 24px;
    margin-bottom: 0;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;

    ${() => theme.media.medium`
      padding-left: 0;
      padding-right: 0;
    `}
  `;

  constructor(props) {
    super(props);
    // the different modal views will be a series of steps (see 'renderStep' method)
    this.state = {
      currentStepIdx: 0,
    };
  }

  handleClose = () => {
    this.props.handleCloseModal();
    this.setState({ currentStepIdx: 0 });
  };

  /* For Modal Navigation */
  incrementStep = () => {
    if (this.state.currentStepIdx + 1 >= this.props.steps.length) {
      this.handleClose();
    } else {
      this.setState((state) => ({ currentStepIdx: state.currentStepIdx + 1 }));
    }
  };

  decrementStep = () => {
    if (this.state.currentStepIdx - 1 < 0) {
      this.handleClose();
    } else {
      this.setState((state) => ({ currentStepIdx: state.currentStepIdx - 1 }));
    }
  };

  render() {
    const { contentLabel, isModalOpen, steps, size } = this.props;
    const { currentStepIdx } = this.state;
    const onFirstStep = currentStepIdx === 0;
    const onLastStep = this.state.currentStepIdx === this.props.steps.length - 1;
    const currentStep = steps[currentStepIdx];

    return (
      <Modal
        contentLabel={contentLabel}
        flushOnMobile
        transparent
        size={size}
        isOpen={isModalOpen}
        onRequestClose={this.handleClose}
      >
        {isModalOpen && (
          <>
            <ModalHeader
              title={currentStep.title}
              left={
                !onFirstStep && <Button.New
                  icon="arrowLeft"
                  link
                  onClick={() =>
                    currentStep.handleGoBack({
                      decrementStep: this.decrementStep,
                      handleClose: this.handleClose,
                    })
                  }
                />
              }
              right={currentStep.renderTopRight && currentStep.renderTopRight({
                onLastStep,
                handleClose: this.handleClose,
                incrementStep: this.incrementStep,
                decrementStep: this.decrementStep,
              })}
            />
            <Modal.Body>
              {currentStep.renderBody({
                currentStepIdx: currentStepIdx,
                totalSteps: steps.length,
                incrementStep: this.incrementStep,
                decrementStep: this.decrementStep,
              })}
            </Modal.Body>
            {currentStep.renderFooter && currentStep.renderFooter({
              handleClose: this.handleClose,
              decrementStep: this.decrementStep,
            })}
          </>
        )}
      </Modal>
    );
  }
}