import React from 'react';
import PropTypes from 'prop-types';
import theme, { font, colors, spacing } from '@matterapp/matter-theme';
import styled from 'styled-components';
import AuthBase from './Base';
import { ButtonSocial } from '../Button';

const Subtitle = styled.div`
  font-size: ${font.size.S2};
  line-height: ${theme.lineHeight.M};
  text-align: center;
  margin-bottom: ${spacing.singleAndQuarter};
  color: ${colors.blacks[60]};
  font-weight: ${font.weight.medium};
`;

const SignupEmailLink = styled.div`
  margin-top: ${spacing.singleAndQuarter};
  width: 100%;
  svg path {
    fill: ${colors.blacks[90]};
  }
  &:hover svg path {
    fill: white;
  }
  &:active svg path {
    fill: white;
  }
`;

const SlackButtonContainer = styled.div`
  width: 100%;
  margin-bottom: ${spacing.singleAndQuarter};
  margin-top: ${spacing.singleAndThreeQuarters};
`;

const TeamsButtonContainer = styled.div`
  width: 100%;
  margin-bottom: ${spacing.singleAndQuarter};
`;

const SocialButtonsContainer = styled.div`
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${spacing.singleAndQuarter};
  width: 100%;
`;

const CreateAccountLink = styled.a`
  color: ${colors.blacks[90]};
  font-family: CircularXX;
  font-size: ${font.size.base};
  line-height: ${theme.lineHeight.M};
  font-weight: ${font.weight.bold};
  text-decoration: underline;
  &:hover {
    color: ${colors.purples[40]};
  }
  &:active {
    color: ${colors.purples[60]};
  }
`;

const SIGNIN_PAGES = {
  signinMainPage: 'SIGNIN_MAIN_PAGE',
  signinWithEmail: 'SIGNIN_WITH_EMAIL',
};
class Login extends React.Component {
  static propTypes = {
    signinForm: PropTypes.node.isRequired,
    socialSigninButtons: PropTypes.array.isRequired,
    path: PropTypes.string.isRequired,
    landingPagePath: PropTypes.string,
    isMobile: PropTypes.bool,
    iFrameHeight: PropTypes.number,
    iFrameWrapper: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: SIGNIN_PAGES.signinMainPage,
    };
  }

  handleEmailSignupClick() {
    this.setState({
      currentPage: SIGNIN_PAGES.signinWithEmail,
    });
  }

  handleGoBack() {
    this.setState({
      currentPage: SIGNIN_PAGES.signinMainPage,
    });
  }

  renderSigninContent() {
    if (this.state.currentPage === SIGNIN_PAGES.signinMainPage) {
      return this.renderSigninBaseCard();
    }
    return this.renderSigninEmailCard();
  }

  renderSlackSigninButton() {
    const { socialSigninButtons } = this.props;
    return (
      <SlackButtonContainer>
        {!!socialSigninButtons && <SocialButtonsContainer>{socialSigninButtons[1]}</SocialButtonsContainer> }
      </SlackButtonContainer>
    );
  }

  renderTeamsSigninButton() {
    const { socialSigninButtons } = this.props;
    return (
      <TeamsButtonContainer data-rc="continue-with-teams-button">
        {!!socialSigninButtons && <SocialButtonsContainer>{socialSigninButtons[2]}</SocialButtonsContainer> }
      </TeamsButtonContainer>
    );
  }

  renderEmailLink = () => {
    const { socialSigninButtons } = this.props;
    return (
      <>
        {!!socialSigninButtons && <SocialButtonsContainer>{socialSigninButtons[0]}</SocialButtonsContainer>}
        <SignupEmailLink data-rc="continue-with-email-button">
          <ButtonSocial
            icon='email'
            buttonText='Sign in with email'
            onClick={this.handleEmailSignupClick.bind(this)}
          />
        </SignupEmailLink>
      </>
    );
  }

  renderSigninBaseCard() {
    return (
      <>
        {this.renderSlackSigninButton()}
        {this.renderTeamsSigninButton()}
        {this.renderEmailLink()}
      </>
    );
  }

  renderSigninEmailCard() {
    const { signinForm } = this.props;
    return (
      <>
        <Subtitle>{"Matter uses magic links to sign in. We'll send you the link. It's simple and secure."}</Subtitle>
        <FormContainer>{signinForm}</FormContainer>
      </>
    );
  }

  renderAuthContentFooterLink() {
    return (
      <CreateAccountLink style={{ marginLeft: 4 }} href={this.props.path}>
        {'Create an account'}
      </CreateAccountLink>
    );
  }

  render() {
    const { landingPagePath } = this.props;

    return (
      <AuthBase
        className='AuthLogin'
        title='Sign in'
        content={this.renderSigninContent()}
        authContentFooterText='New to Matter?'
        authContentFooterLink={this.renderAuthContentFooterLink()}
        handleGoBack={this.handleGoBack.bind(this)}
        formField={this.state.currentPage === SIGNIN_PAGES.signinWithEmail}
        landingPagePath={landingPagePath}
      />
    );
  }
}

export default Login;
