import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Skill } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';
import Tooltip from './Tooltip';

class ChartHover extends React.PureComponent {
  static propTypes = {
    skillName: PropTypes.string.isRequired,
    rating: PropTypes.shape({
      y: PropTypes.number.isRequired,
      user: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        photoUrl: PropTypes.string,
      }),
    }).isRequired,
    point: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }).isRequired
  };

  static userSize = 26;

  static Container = styled.div`
    position: absolute;
    width: ${ChartHover.userSize}px;
    height: ${ChartHover.userSize}px;
    margin-top: ${-ChartHover.userSize / 2}px;
    margin-left: ${-ChartHover.userSize / 2}px;
    pointer-events: none;
    cursor: pointer;

    img {
      width: ${ChartHover.userSize}px;
      height: ${ChartHover.userSize}px;
      border-radius: 50%;
      cursor: ${(props) => props.clickable ? 'pointer' : 'default'};

    }
  `;

  static Name = styled.span`
    font-size: ${() => theme.sizes.fontSizes[50]};
    font-weight: bold;
    padding-right: 8px;
  `;

  static Link = styled.a`

  `

  render() {
    const { point, skillName } = this.props;
    const { y, user, onClick } = this.props.rating;

    return (
      <ChartHover.Container
        style={{ left: point.x, top: point.y }}
        clickable={onClick}
      >
        <Tooltip style={{ left: 11, top: 0 }}>
          <ChartHover.Name>{user.fullName}</ChartHover.Name>
          <Skill.Pill.Basic
            size="small"
            text={`${Math.round(y)}%`}
            skill={{
              name: skillName,
            }}
          />
        </Tooltip>
      </ChartHover.Container>
    );
  }
}

export default ChartHover;
