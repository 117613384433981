export const skillRatingsMap = new Map();
skillRatingsMap.set(0, 'poor');
skillRatingsMap.set(1, 'okay');
skillRatingsMap.set(2, 'fair');
skillRatingsMap.set(3, 'decent');
skillRatingsMap.set(4, 'fairly good');
skillRatingsMap.set(5, 'good');
skillRatingsMap.set(6, 'very good');
skillRatingsMap.set(7, 'great');
skillRatingsMap.set(8, 'excellent');
skillRatingsMap.set(9, 'incredible');
skillRatingsMap.set(10, 'amazing');

export const skillRatingsTitle = {
  1: 'Back to basics!',
  2: 'Making strides!',
  3: 'Keep it up!',
  4: 'Almost there!',
  5: 'Flawless!',
};

export const RECURRING_FREQUENCY = {
  biweekly: 'biweekly',
  weekly: 'weekly',
  monthly: 'monthly',
  bimonthly: 'bimonthly',
  quarterly: 'quarterly',
};

export const FREQUENCY_NOUNS = {
  weekly: 'week',
  biweekly: 'couple of weeks',
  monthly: 'month',
  bimonthly: 'couple of months',
  quarterly: 'quarter'
};

export const skillRatingsDescriptionsReplaceText = '%n';

export const skillRatingsDescriptions = {
  1: `${skillRatingsDescriptionsReplaceText} can benefit from guidance and has a ton of room to grow in this skill.`,
  2: `${skillRatingsDescriptionsReplaceText} can improve this skill by practicing it regularly.`,
  3: `${skillRatingsDescriptionsReplaceText} can refine the craft and techniques to develop this skill further.`,
  4: `${skillRatingsDescriptionsReplaceText} is so close to honing this skill. Just a few tweaks here and there will do.`,
  5: `${skillRatingsDescriptionsReplaceText} can put this skill into practice effortlessly.`,
};

export const skillRatingsDescriptions1stPerson = {
  ...skillRatingsDescriptions,
  1: `${skillRatingsDescriptionsReplaceText} can benefit from guidance and have a ton of room to grow in this skill.`,
  3: `${skillRatingsDescriptionsReplaceText} can refine your craft and techniques to develop this skill further.`,
  4: `${skillRatingsDescriptionsReplaceText} are so close to honing this skill. Just a few tweaks here and there will do.`,
};

export const skillRatingsDescriptionsDefault = `Select how ${skillRatingsDescriptionsReplaceText} has been doing on this skill. Click Skip if you haven’t worked together in this capacity.`;
export const skillRatingsDescriptionsRecurring = `Select how ${skillRatingsDescriptionsReplaceText} has been doing on this skill.`;

/**
 * Gets the title description for the current rating of the skill.
 * @param { Number } rating: The current rating of the skill.
 * @returns { String } The title description of the rating. 
 */
export const getDescriptionTitle = (rating) => skillRatingsTitle[rating];

/**
 * Formats the description text to insert the reciever's name into the description.
 * @param { StringConstructor } description: The description text to format.
 * @param { String } firstName: The first name of the reciever of the skill rating.
 * @returns { String } The formatted description of the rating. 
 */
export const getFormattedDescription = (descr, firstName) => {
  const description = descr || '';
  return description.replace(skillRatingsDescriptionsReplaceText, firstName);
};

/**
 * Gets the description for the current rating of the skill.
 * @param { Number } rating: The current rating of the skill.
 * @param { String } firstName: The first name of the reciever of the skill rating.
 * @param { Boolean } is1stPerson: If using 1st person text.
 * @returns { String } The description of the rating. 
 */
export const getDescriptionText = (rating, firstName, is1stPerson) => {
  const descriptionsToUse = is1stPerson ? skillRatingsDescriptions1stPerson : skillRatingsDescriptions;
  const description = descriptionsToUse[rating];
  const nameToUse = is1stPerson ? (firstName || 'You') : firstName;
  return description ? getFormattedDescription(description, nameToUse) : '';
};
