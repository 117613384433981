import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

import omitStyled from '../../../libs/omitStyled';

const List = omitStyled('ul', ['align'])`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: ${({ align }) => align};
`;

class MenuList extends React.Component {
  static Button = Button;

  render = () => {
    const {
      align,
      children,
    } = this.props;
    return (
      <List align={align}>
        {children}
      </List>
    );
  }
}

MenuList.defaultProps = {
  align: 'left',
};

MenuList.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired,
};

export default MenuList;
