import React from 'react';

const InvisibleSubmitButton = () => (
  <input
    type="submit"
    style={{
      visibility: 'hidden',
      display: 'block',
      height: 0,
      border: 0,
      margin: 0,
      padding: 0,
      width: 0,
    }}
  />
);

export default InvisibleSubmitButton;
