import styled from 'styled-components';
import omitStyled from '../../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

const METER_HEIGHT = 56;

export const Container = styled.div`
  margin: 0 auto;
`;

export const BarRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: ${METER_HEIGHT}px;
  padding: 0 16px;
  background-color: ${() => theme.colors.purples[60]};
  overflow: hidden;
`;

export const HelperTextContainer = omitStyled('div', ['percentCompletion'])`
  position: relative;
  z-index: 2;
  font-weight: 600;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StepCounter = styled.div`
  z-index: 2;
  text-align: center;
  color: ${() => theme.colors.purples[10]};
`;

export const Meter = omitStyled('div', ['percentCompletion'])`
  z-index: 1;
  position: absolute;
  top: 0;
  left: -1px;
  width: ${({ percentCompletion }) => percentCompletion + 1}%;
  height: ${METER_HEIGHT}px;
  transition: all .2s ease;
`;

export const SmoothingContainer = styled.div`
  overflow: hidden;
  border-radius: 100px;
`;