"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.content = void 0;

var _colors = require("../colors");

var _sizes = require("../sizes");

const content = {
  description: {
    color: _colors.colors.blacks[50],
    fontSize: _sizes.sizes.fontSizes[40],
    lineHeight: '20px'
  },
  divider: {
    size: '1px',
    color: _colors.colors.purples[5]
  }
};
exports.content = content;
var _default = content;
exports.default = _default;