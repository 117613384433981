import { useContext, useEffect, useState } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { Resources } from '@matterapp/routing';
import Toast from 'components/Toast/Toast';
import { useNavigate } from 'react-router-dom';

function GatewayComponent() {
  const { 
    currentUser, 
    isLoadingCurrentUser, 
    isAuthenticating, 
    currentWorkspaceId, 
    refetchCurrentUser 
  } = useContext(UserContext);
  const [calledRefetch, setCalledRefetch] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    refetchCurrentUser();
    setCalledRefetch(true);
  }, []);

  useEffect(() => {
    if (!calledRefetch) {
      return;
    }

    if (!currentUser && !isLoadingCurrentUser && !isAuthenticating) {
      Toast.error('Could not log in. Please try again');

      return navigate(Resources.authRequestVerification.path());
    } else if (currentUser && currentWorkspaceId) {
      return navigate(Resources.workspacesActivityFeed.path({ workspaceId: currentWorkspaceId }));
    }
  }, [currentUser, isLoadingCurrentUser, isAuthenticating, currentWorkspaceId]);

  return null;
}


export default GatewayComponent;
