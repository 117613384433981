import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const StyledFlex = styled.div`
  display: flex;
  pointer-events: all;
  width: 100%;
  height: 96px;
  background-color: ${(props) => props.theme.colors.yellows[20]};
  margin-bottom: 16px;
  align-items: center;
  padding: 24px;

  ${() => theme.media.medium`
    padding: 12px 24px;
    margin-bottom: 32px;
    box-shadow:
      0px 1px 1px rgba(28, 28, 33, 0.15),
      0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
  `}
`;

const LeftRightBox = styled.div`
  margin-right: 8px;

  ${() => theme.media.medium`
    margin-right: 16px;
  `}
`;

const YellowCardPreview = ({ left, center, right, leftWidth }) => (
  <StyledFlex>
    <LeftRightBox
      style={{
        width: `${leftWidth}px`,
        minWidth: `${leftWidth}px`,
      }}
    >
      {left}
    </LeftRightBox>
    <div
      style={{
        flex: '1 1 auto',
        textAlign: 'left',
      }}
    >
      {center}
    </div>
    <LeftRightBox>{right}</LeftRightBox>
  </StyledFlex>
);

YellowCardPreview.propTypes = {
  left: PropTypes.node,
  leftWidth: PropTypes.number,
  center: PropTypes.node,
  right: PropTypes.node,
};

YellowCardPreview.defaultProps = {
  left: null,
  leftWidth: 48,
  center: null,
  right: null,
};

export default YellowCardPreview;
