import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '../ModalOld';
import Button from '../../../Button';
import { Header, Body, Footer } from '../components';
import { ConfirmContainer } from '../../../Confirm';

const ConfirmModalButton = styled(Button.New).attrs({
  size: 'small',
})``;

const defaultConfirmProps = {
  buttonCancelLabel: 'Cancel',
  buttonConfirmLabel: 'Yes',
  heading: 'Confirm Close',
  subheading: 'Are you sure you want to close this modal?',
  onCancel: () => null,
  onConfirm: () => null,
};

export default class ModalWithConfirmClose extends React.PureComponent {
  static Header = Header;
  static Body = Body;
  static Footer = Footer;

  static propTypes = {
    /** Props of the confirm close modal. */
    confirmProps: PropTypes.shape({
      /** The label of the cancel button in the confirm modal. Uses default if not passed in. */
      buttonCancelLabel: PropTypes.string,
      /** The label of the confirm button in the confirm modal. Uses default if not passed in. */
      buttonConfirmLabel: PropTypes.string,
      /** The heading of the confirm modal. Uses default if not passed in. */
      heading: PropTypes.string,
      /** The sub heading of the confirm modal. Uses default if not passed in. */
      subheading: PropTypes.string,
      /** Callback function when cancelling the confirm modal. */
      onCancel: PropTypes.func,
      /** Callback function when confirming the confirm modal. In addition `onRequestClose` will be called to close main modal. */
      onConfirm: PropTypes.func,
    }),
    /** Callback function to clsoe the modal. */
    onRequestClose: PropTypes.func.isRequired,
    /** If the confirm close modal should be shown. */
    showConfirmCloseModal: PropTypes.bool,
  };

  static defaultProps = {
    confirmProps: defaultConfirmProps,
    showConfirmCloseModal: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      isConfirmCloseModalOpen: false,
    };
  }

  /**
   * Helper function to get props for the confirm modal.
   * @return { Object } The confirm modal props;
   */
  getConfirmProps = () => ({
    ...defaultConfirmProps,
    ...this.props.confirmProps,
  });

  /**
   * Callback to open the confirm close modal.
   * @param { Object } e: The click event to open the confirm modal.
   * @returns { void }
   */
  handleOpenConfirmCloseModal = (e) => {
    const { onRequestClose, showConfirmCloseModal } = this.props;
    if (showConfirmCloseModal) {
      this.setState({ isConfirmCloseModalOpen: true });
    } else {
      onRequestClose(e);
    }
  };

  /**
   * Callback to close the confirm close modal.
   * @param { Object } e: The click event to close the confirm modal.
   * @returns { void }
   */
  handleCloseConfirmCloseModal = () => {
    const { onCancel } = this.getConfirmProps();
    onCancel();

    this.setState({ isConfirmCloseModalOpen: false });
  };

  /**
   * Callback to close the modal.
   * @param { Object } e: The click event to close the modal.
   * @returns { void }
   */
  handleCloseModal = (e) => {
    const { onRequestClose, showConfirmCloseModal } = this.props;

    if (showConfirmCloseModal) {
      const { onConfirm } = this.getConfirmProps();
      onConfirm();
    }

    this.setState({ isConfirmCloseModalOpen: false });
    onRequestClose(e);
  };

  renderConfirmContainer = () => {
    const {
      buttonCancelLabel,
      buttonConfirmLabel,
      heading,
      subheading,
    } = this.getConfirmProps();

    const buttonLeft = (
      <ConfirmModalButton basic onClick={this.handleCloseConfirmCloseModal}>
        {buttonCancelLabel}
      </ConfirmModalButton>
    );
    const buttonRight = (
      <ConfirmModalButton primary onClick={this.handleCloseModal}>
        {buttonConfirmLabel}
      </ConfirmModalButton>
    );

    return (
      <ConfirmContainer
        buttonLeft={buttonCancelLabel ? buttonLeft : null}
        buttonRight={buttonRight}
        heading={heading}
        onCancel={this.handleCloseConfirmCloseModal}
        subheading={subheading}
      />
    );
  };

  render() {
    const { isConfirmCloseModalOpen } = this.state;
    return (
      <>
        <Modal
          {...this.props}
          onRequestClose={this.handleOpenConfirmCloseModal}
          shouldCloseOnOverlayClick
        />
        <Modal
          contentLabel='confirmCloseModal'
          isOpen={isConfirmCloseModalOpen}
          onRequestClose={this.handleCloseConfirmCloseModal}
        >
          {this.renderConfirmContainer()}
        </Modal>
      </>
    );
  }
}
