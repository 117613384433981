import React from 'react';

const Icon = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M67.0196 151.384C67.0196 162.948 57.5733 172.394 46.0098 172.394C34.4463 172.394 25 162.948 25 151.384C25 139.821 34.4463 130.375 46.0098 130.375H67.0196V151.384Z"
      fill="#E01E5A"
    />
    <path
      d="M77.6059 151.384C77.6059 139.821 87.0521 130.375 98.6156 130.375C110.179 130.375 119.625 139.821 119.625 151.384V203.99C119.625 215.554 110.179 225 98.6156 225C87.0521 225 77.6059 215.554 77.6059 203.99V151.384Z"
      fill="#E01E5A"
    />
    <path
      d="M98.6156 67.0196C87.0521 67.0196 77.6059 57.5733 77.6059 46.0098C77.6059 34.4463 87.0521 25 98.6156 25C110.179 25 119.625 34.4463 119.625 46.0098V67.0196H98.6156Z"
      fill="#36C5F0"
    />
    <path
      d="M98.6156 77.6058C110.179 77.6058 119.625 87.0521 119.625 98.6156C119.625 110.179 110.179 119.625 98.6156 119.625H46.0098C34.4463 119.625 25 110.179 25 98.6156C25 87.0521 34.4463 77.6058 46.0098 77.6058H98.6156Z"
      fill="#36C5F0"
    />
    <path
      d="M182.98 98.6156C182.98 87.0521 192.427 77.6058 203.99 77.6058C215.554 77.6058 225 87.0521 225 98.6156C225 110.179 215.554 119.625 203.99 119.625H182.98V98.6156Z"
      fill="#2EB67D"
    />
    <path
      d="M172.394 98.6156C172.394 110.179 162.948 119.625 151.384 119.625C139.821 119.625 130.375 110.179 130.375 98.6156V46.0098C130.375 34.4463 139.821 25 151.384 25C162.948 25 172.394 34.4463 172.394 46.0098V98.6156Z"
      fill="#2EB67D"
    />
    <path
      d="M151.384 182.98C162.948 182.98 172.394 192.427 172.394 203.99C172.394 215.554 162.948 225 151.384 225C139.821 225 130.375 215.554 130.375 203.99V182.98H151.384Z"
      fill="#ECB22E"
    />
    <path
      d="M151.384 172.394C139.821 172.394 130.375 162.948 130.375 151.384C130.375 139.821 139.821 130.375 151.384 130.375H203.99C215.554 130.375 225 139.821 225 151.384C225 162.948 215.554 172.394 203.99 172.394H151.384Z"
      fill="#ECB22E"
    />
  </svg>
);

Icon.defaultProps = {
  height: 32,
  width: 32,
};

export default Icon;
