export default {
  700: {
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: '52px',
  },
  500: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
  },
  410: {
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  400: {
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  390: {
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  341: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.04em',
  },
  340: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  320: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  300: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
};
