import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../SVG';

const DEFAULT_SIZE = 80;

const Icon = ({ size, ...otherProps }) => (
  <SVG
    {...otherProps}
    size={size}
    viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}
  >
    <path
      d="M0 40c0 22.091 17.909 40 40 40s40-17.909 40-40S62.091 0 40 0 0 17.909 0 40z"
      fill="#FF4369"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.476 45.594H45.53l1.135-24.417c.042-.63-.504-1.177-1.135-1.177H34.434c-.63 0-1.176.546-1.092 1.177l1.134 24.417zm-.375 9.953a4.502 4.502 0 004.496 4.497h2.858a4.502 4.502 0 004.497-4.496v-1.051A4.502 4.502 0 0041.455 50h-2.858a4.502 4.502 0 00-4.496 4.497v1.05z"
      fill="#fff"
    />
  </SVG>
);

Icon.defaultProps = {
  size: DEFAULT_SIZE,
};

Icon.propTypes = {
  size: PropTypes.number,
};

export default Icon;
