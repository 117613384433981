import styled from 'styled-components';

export const ColoredPath = styled.path`
  fill: currentColor;
`;
export const GrayFill = styled.path`
  fill: #D8D8D9;
`;
export const PurpleFill = styled.path`
  fill: #d9d9ed;
`;
export const PurpleDarkFill = styled.path`
  fill: #ADAFDE;
`;
export const OrangeFill = styled.path`
  fill: #fde8e1;
`;
export const PinkFill = styled.path`
  fill: #ffe7ee;
`;
export const YellowFill = styled.path`
  fill: #fff5da;
`;

export const Cloud = styled.path.attrs({
  d: `M-1103.7,1364.9c-1,0.1-1.9,0.1-2.9,0.2C-1105.6,1364.9-1104.7,1364.9-1103.7,1364.9z M2560,1426.6
	l-927.2,0.1v0H1280v0H0l-1280-0.1l17-7.2c13.2-6.1,29.3,2.2,40.3-7.8c28.9-12.9,50.1,3.9,55.4-2.7c5.6-9.9,17.5-15.6,24.5-23.3
	c7.6-12.2,21.3-18.9,36.2-20.5c-0.4,0.1-0.8,0.3-1.3,0.5c1.8,0.5,4.5,0.1,5.4-0.7c-0.4,0-0.8,0-1.2,0c8.1-0.5,16.5,0.4,24.4,2.8
	c34.3-15.4,36.4,5.6,58-0.1c31.6-12.4,41.5,12.9,53.1,4.6c5.9-26.3,53.9-37.9,75-15.1c11.2,1.2,22.3-8,34.6-4.2
	c31.4,3.4,11.8-13.6,66.6-3.4c3.8-7.3,9.1-16.2,19.4-17.9c2.6-0.5,2.6-0.5,3-2.6c4.5-12.5,20.5-21.3,35-24.8
	c11.9-2.4,24.5,1.3,36,1.3c16-4.6,32.4,1,48,1.7c12.1-4.7,26.4-4.1,38.3,0.8c8.5-3.3,18.6-1.6,25.2,4c2.7,3,6.7-2,10.7-2.3
	c14.8-4.3,28.9,3.4,39.1,11.6c10.5-30.2,69.7-28.7,75.6,3.1c12.6-2.3,22.7,14.9,31.9,4.6c6.9-3.8,17.1-4,23.9,0.3
	c9.2-21.6,35.7-30,61.5-30.2c17.6,1.5,26.5,11.3,42.5-1.1c26.6-16,66.7-15.1,90.7,3.7c12.4,10.5,4.3-23.9,48.8-31
	c7-2.5,1.6-9.7,3.1-14.3c7.1-32,39.7-59.6,80.3-58.5c11.1-1.4,26.1,5.1,34.8-1.6c8.7-14.7,26.7-22.1,44.3-27.7v-0.1
	c11-10.2,27.4-10.5,41.2-14.9c4.7-1.1,9.5-0.9,14.2-0.3c1.6,0.2,2.6-0.4,3.4-1.8c8.1-14.6,23.2-23.3,36-33.1
	c19.3-8.7,39.8-11.5,60.6-13.5c4.2-0.7,8.3-1.4,12.5,0.1c1.4,0.5,2.4,0,3.5-1c8.2-9.2,10.3-22.8,19.9-31.1c3.2-1.9,0.9-6.7,4.4-8.2
	c7.8-4.1,11.6-13.9,20.9-14.9c14.6-2.5,29.6-4.5,44.5-3.2c6.8,0.7,13.1,3.4,18.6,7.7c9.2,4.8,17.9,10.4,26.5,16.2
	c7.9,3.9,10.3,14.5,17.4,18.2c3.7,0,7.4,0.4,10.9-1c15-3.6,30.1,4.3,44,9.4c3.8,2,7.4,2.9,11.3,0.5c9.4-4.5,20.4-5.1,29.3-11.2
	c4.6-3.2,10-4.4,15.5-5.4c23.4-5.5,48.6-3.5,71,5.1c7.9,2.8,16.2,5,22.8,10.6c2.9,1.3,8.7,9.1,10.8,3.9c0.6-12.7,9-22.3,20.4-27.2
	c8-3.2,15.7-9.3,24.5-9.6c10.6,0.1,21.2-0.8,31.8,0.3c3.2,0.4,5.1-1.1,6.6-3.5c8.6-16.5,28.1-24.9,46.1-25.6
	c12.5-0.8,24.6,1,35.5,7.4c1.7,0.8,3.3,0.9,5.2,0.2c6.2-2.2,12.5-2.4,18.8-0.4c1.9,0.6,3,0.1,4.2-1.4c6.8-8.9,15.1-16.3,23.1-24.1
	c18-14.6,41.2-24.5,64.3-26.4c45.2-3.2,76.5,13.6,103.2,49.8c1.5,2.4,3.3,4.9,6.1,2.3c8.1-5.7,20.9-2.1,30.2-0.8
	c2.1,0.4,4.3,0.4,5.9-1c3.9-3.4,8.7-4.3,13.4-5.2c22.6-6.6,46.1,1.9,58.2,22.3c3.2,7.5,16.1-3,24.2,0.1c5.1,0.8,10.2,2,15.4,2.4
	c12.5,0.9,22.5,8.3,33.3,13.7c12,6.3,17,19.7,25.1,29.8c28.2-19.2,59.7-28.3,93.6-20.7c18.5,4,33.7,16.4,49.6,25.8
	c0.9,0.5,1.6,1,2.2,1.7v0.4c8.2-5,17.7-5.5,26.4-3.3c11.8-14.4,25.3-24.1,40.6-27.9c23.5-15.1,55.8-2.5,72.7,25.8
	c9.4-7.4,19.7-13.5,30.4-12.2c-0.3-0.1-0.5-0.3-1-0.5c17-2.3,33.7,5.8,46.3,23c8.2,6.8,11.9,34.5,20.3,29.2
	c10.9-8.2,23.9-7.3,33.8,3.6c2.2,3.6,4.8,3,7.5,1.1c0.2-0.2,0.5-0.4,0.7-0.5c3-2.3,6-5.8,8.7-6.6c6.2-2.7,12.9-3.9,19.6-3.4
	c0,0,0,0,0,0c14.2,1,28.1,9.5,36.6,26.8c1.7,2.1,3,4.6,4.1,7.4c6.2,0.5,13.5-2.7,19.4-1.6c14.3,0.7,30,5.2,40.1,18.4
	c10.6,12.6,18.2,29.1,21,46.8c1.2,5.2-3,13.2,2.4,16c33.8,7.9,27.7,46.2,37.1,34.5c18.3-21,48.8-21.9,69-4.1
	c12.2,13.8,18.9,2.9,32.3,1.2c19.6,0.2,39.8,9.6,46.8,33.7c5.2-4.8,13-4.6,18.2-0.3c7.1,11.5,14.7-7.7,24.3-5.1
	c0.9-5,2-10.2,4.6-14.2c15.1-21.8,45.6-16.9,52.9,10.8c7.8-9.1,18.5-17.7,29.8-12.9c3,0.3,6.1,5.9,8.1,2.6c5-6.2,12.7-8,19.1-4.4
	c9.1-5.4,19.9-6.2,29.2-0.9c11.8-0.7,24.3-7,36.5-1.9c8.8,0,18.3-4.2,27.4-1.5c11.1,3.9,23.3,13.8,26.7,27.7
	c0.3,2.4,0.3,2.4,2.3,2.9c7.8,1.8,11.9,11.7,14.7,19.9c41.7-11.3,26.8,7.6,50.7,3.8c9.3-4.2,17.8,6.1,26.3,4.7
	c16-25.3,52.6-12.4,57,16.9c8.8,9.3,16.4-18.9,40.4-5.1c16.4,6.4,18-17.1,44.1,0.1c6-2.6,12.4-3.7,18.6-3.1c-0.3,0-0.6,0-0.9,0
	c0.7,0.9,2.7,1.3,4.1,0.8c-0.3-0.2-0.6-0.4-1-0.5c11.4,1.8,21.7,9.2,27.6,22.8c5.3,8.6,14.4,14.9,18.6,26c4,7.4,20.1-11.4,42.1,3
	c8.4,11.1,20.6,1.9,30.7,8.7L2560,1426.6z M-790.8,1402.8c-0.1-0.1-0.2-0.3-0.3-0.3c-0.2,0-0.4,0.1-0.6,0.2
	C-791.5,1403.1-791.2,1402.9-790.8,1402.8z M1170.4,1087.3c-0.1-0.3-0.2-0.6-0.2-0.9c-2.5,1-5,2-7.5,3c0.1,0.2,0.2,0.4,0.3,0.7
	C1165.4,1089.1,1167.9,1088.2,1170.4,1087.3z M2188.4,1399.9c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.1,0.2-0.2,0.4
	C2188.1,1400.2,2188.3,1400.4,2188.4,1399.9z M2425.9,1357.8c0.7,0.1,1.5,0.2,2.2,0.3C2427.3,1357.8,2426.6,1357.8,2425.9,1357.8z`,
})`
  fill: currentColor;
`;

export const LeftPlanetGroup = styled.g``;
export const LeftPlanetBackground = styled.path.attrs({
  d: `M62.3,943.3L62.3,943.3L62.3,943.3c-0.1-1.5-0.4-3.1-0.7-4.6
	c-0.1-0.3-0.1-0.6-0.2-0.8l-0.2-1.6l-0.6-2.2c-0.3-1.2-0.5-2.5-0.8-3.7c-0.2-1-0.4-2-0.7-3.1c-0.2-0.8-0.5-1.7-0.7-2.5
	c-0.3-1-0.4-2.1-0.8-3.1c-0.5-1.4-1.1-2.8-1.8-4.2c-0.6-1.3-1.2-2.6-2.1-3.8c-0.8-1.1-1.7-2.1-1.7-3.5c0-0.1-0.1-0.3-0.2-0.4
	c-0.6-0.7-1-1.6-1.9-2.1c-0.3-0.1-0.5-0.4-0.8-0.7c-0.9-0.9-1.8-1.8-2.6-2.7c-2.8-2.8-5.9-5.4-9.2-7.7c-2.2-1.5-4.4-2.9-6.9-3.8
	c-2.6-0.9-4.9-2.4-7.6-3c-0.1,0-0.2-0.1-0.3-0.1c-1.2-0.5-2.4-0.9-3.7-1.1c-0.2,0-0.6-0.2-0.7-0.4c-0.6-0.9-1.5-1.4-2.5-1.7
	c-2.3-0.7-4.6-1.4-6.9-2.1c-1.3-0.4-2.5-0.7-3.8-0.9c-2.7-0.5-5.4-0.4-8.2-0.6c-1.6-0.1-3.1,0-4.7,0c0,0,0,0,0,0
	c-0.6,0-1.3,0.1-1.9,0c-3.1-0.4-6,0.3-8.9,1c-2.7,0.6-5.3,1.4-7.7,2.6c-2.5,1.2-4.9,2.3-7.1,3.8c-1.6,1.1-3,2.3-4.5,3.5
	c-2,1.6-4.1,3.2-5.7,5.2c-0.3,0.4-0.7,0.7-1,1c-0.4,0.4-0.7,0.9-1,1.3c-1,1.2-2,2.4-3,3.6c-0.6,0.7-1,1.4-1.6,2.1
	c-1.3,1.7-2.7,3.3-3.9,5.1c-0.9,1.2-1.5,2.6-2.3,3.8c-0.8,1.3-1.8,2.5-2.6,3.8c-0.4,0.6-0.8,1.2-1.1,1.9c-0.3,0.7-0.4,1.4-0.6,2.1
	c-0.5,1.7-1,3.5-1,5.3c0,1-0.2,2-0.4,2.9c-0.3,1.2-0.3,2.5-0.1,3.7c0.5,2.4,0.4,4.8,0.2,7.2c-0.1,2.6,0,5.1,0.9,7.5
	c0.1,0.4,0.3,0.9,0.4,1.3c0.4,2.1,0.8,4.2,1.2,6.3c0.5,2.3,1,4.6,1.6,6.9c0.5,1.9,1.1,3.7,1.8,5.6c0.6,1.4,1.2,2.8,2,4.1
	c1.3,2,2.8,4,4.3,5.9c1.4,1.8,3,3.6,4.9,5.1c0.2,0.2,0.4,0.4,0.5,0.6c1.2,1.7,2.5,3.4,4.4,4.5c0.2,0.1,0.4,0.3,0.6,0.5
	c0.3,0.6,1,0.9,1.5,1.3c0.4,0.3,0.8,0.5,1.2,0.8c1,0.7,1.9,1.4,2.9,2c2,1.2,4.2,2.4,6.2,3.6c2.5,1.5,5.3,2.1,8.1,2.9
	c1.2,0.3,2.4,0.4,3.6,0.7c2.1,0.4,4.2,0.8,6.4,0.8c0.5,0,1.1,0.1,1.6,0.1c2.6,0.1,5.1,0.4,7.7,0.1c1-0.1,1.9-0.1,2.9-0.2
	c1.2-0.1,2.5-0.1,3.7-0.2c1.4-0.1,2.9-0.4,4.3-0.6c1.4-0.2,2.8-0.2,4.1-0.4c1.4-0.2,2.8-0.2,4.1-0.8c0.3-0.1,0.5-0.1,0.8-0.2
	c1.7-0.5,3.6-0.4,5.2-1.5c0,0,0.1,0,0.2-0.1c1.4-0.7,3.1-0.9,4.4-1.9c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.2,0.6-0.4,0.9-0.5
	c0.6-0.4,1.2-0.9,2-0.8c1,0.1,1.8-0.2,2.6-0.7c2.1-1.1,3.9-2.6,5.6-4.2c1-0.9,1.9-1.9,2.8-2.9c1.1-1.2,2.2-2.3,3.1-3.6
	c1.5-2.1,3-4.3,4.4-6.5c1.3-2,2.4-4.1,3.6-6.2c0.5-0.9,1.1-1.8,1.5-2.8c1.4-3.1,2.6-6.2,3.7-9.4c1.1-3.5,1.7-7.1,2.1-10.7
	C62.5,947.3,62.5,945.3,62.3,943.3z`,
})`
  fill: #ffaebc;
`;
export const LeftPlanetOverlay = styled.path.attrs({
  d: `M3.4,939.4c-1.2,16.1-3.7,31.6-22.9,50.5c-3.5,3.4-6.5,6.3-8.9,8.6
	c-1.8-1.1-3.6-2.1-5.4-3.1c-1-0.6-1.9-1.3-2.9-2c-0.4-0.3-0.8-0.5-1.2-0.8c-0.6-0.4-1.2-0.7-1.5-1.3c-0.1-0.2-0.3-0.3-0.6-0.5
	c-1.9-1.2-3.2-2.8-4.4-4.5c-0.2-0.2-0.3-0.4-0.5-0.6c-1.8-1.5-3.4-3.3-4.9-5.1c-1.5-1.9-3-3.9-4.3-5.9c-0.8-1.3-1.5-2.7-2-4.1
	c-0.7-1.8-1.3-3.7-1.8-5.6c-0.4-1.7-0.8-3.3-1.2-5c13.8-13,32.1-37.1,26.5-70.1c2-1.3,4.2-2.3,6.4-3.3c2.5-1.2,5.1-2,7.7-2.6
	c2.9-0.6,5.9-1.3,8.9-1c0.6,0.1,1.3,0,1.9,0c0,0,0,0,0,0c1.6,0,3.1-0.1,4.7,0c2.7,0.1,5.5,0.1,8.2,0.6c1,0.2,2.1,0.4,3.1,0.7
	l-0.3,0.8C7.9,885.1,4.6,923.3,3.4,939.4z M59.9,930.3c-0.2-1-0.4-2-0.7-3.1c-0.2-0.8-0.5-1.7-0.7-2.5c-0.3-1-0.4-2.1-0.8-3.1
	c-0.5-1.4-1.1-2.8-1.8-4.2c-0.6-1.3-1.2-2.6-2.1-3.8c-0.8-1.1-1.7-2.1-1.7-3.5c0-0.1-0.1-0.3-0.2-0.4c-0.6-0.7-1-1.6-1.9-2.1
	c-0.3-0.1-0.5-0.4-0.8-0.7c-0.9-0.9-1.8-1.8-2.6-2.7c-2.8-2.8-5.9-5.4-9.2-7.7c-2-1.4-4.1-2.6-6.4-3.6c-0.6,19.5-0.7,38.9-3.5,58.2
	c-1.9,13-5.2,24.6-12,35.8c-2.6,4.3-5.6,8.5-8.7,12.5c-0.8,1-2.7,2.5-3.5,3.9c1,0,2-0.1,3-0.2c1.4-0.1,2.9-0.4,4.3-0.6
	c1.4-0.2,2.8-0.2,4.1-0.4c1.4-0.2,2.8-0.2,4.1-0.8c0.3-0.1,0.5-0.1,0.8-0.2c1.7-0.5,3.6-0.4,5.2-1.5c0,0,0.1,0,0.2-0.1
	c1.4-0.7,3.1-0.9,4.4-1.9c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.6-0.4,1.2-0.9,2-0.8c1,0.1,1.8-0.2,2.6-0.7
	c2.1-1.1,3.9-2.6,5.6-4.2c1-0.9,1.9-1.9,2.8-2.9c1.1-1.2,2.2-2.3,3.1-3.6c1.5-2.1,3-4.3,4.3-6.5c-0.1-0.4-0.2-0.9-0.3-1.3
	c-7-28.1,6-42.7,9.1-45.8C60.1,931.1,60,930.7,59.9,930.3z`,
})`
  fill: #ff999c;
`;

export const RightPlanetGroup = styled.g``;
export const RightPlanetBackground = styled.path.attrs({
  d: `M1261,848.6c24.7-3.8,44.2-10.8,58.7-32.5c5.6-7.7,8.5-17,11.6-25.7
	c10.2-16.5,10-42.3,2.5-60c-8-18.8-39.2-45.5-59.3-47.7c-21.4-1.9-44.1,1.4-63.8,10.3c-18,11.9-35.4,29-38.9,51.3
	c-2.7,20.1-2.3,42.7,6.6,61.1c0.5,1.6,2.2,2.7,2.1,4.5c0.2,4.5,4,7.8,6.7,11.1C1206.5,839,1234,853.2,1261,848.6z`,
})`
  fill: #a0a0d2;
`;
export const RightPlanetOverlay = styled.path.attrs({
  d: `M1263.1,685.5c-1.6,0.1-3.1,0.2-4.6,0.3c0.6-3.2,5,0.4,6.2-2.5
	c2.8-2.1,5.3,2.3,0.5,1.3c0.1,0.7,0.2,1.3,0.2,2C1264.4,686.8,1263.8,686,1263.1,685.5z M1264.5,682.1c-0.6,0-1.3,0-2,0
	C1262.7,682.8,1263.5,683.1,1264.5,682.1z M1273.3,691.7c-0.6-1.1,0.2-1.7,1.1-2.2c-0.2-1.6,0.7-4.3,2.1-6.5
	c3.6,0.6,7.4,2,11.4,3.9c-0.1,0.6-0.3,1.2-0.7,1.8c-0.4,1.3-2.8,1.4-1.6,3.1c0,1.6-2.2,1.3-1,3.3c-1.2,0.4-2.3,0.9-3.8,1.4
	c1,0.3,1.5,0.4,2.2,0.9c-0.3,0.4-0.5,1.1-0.8,1.1c-2.4-0.1,1,4.3-3.7,5.5c0.7,1.8,0.7,1.8-0.8,1.9c1,1-0.3,1.9-0.7,2.6
	c0.6,2.1-2.3,3-0.4,4.8c-1.8-0.1-3.5-0.1-4.8,1.4c1.1,0,2.1-0.1,3.1,0.2c-1,0.9,0.3,3.5-1.1,3.7c-0.8-0.3-0.8-1.5-0.8-2.3
	c-1.9,0.6-5.2-1-4-3.3c0.6,0.8,0.6,0.9,0.9,1.7c1.7-0.3,0.9-2,0-2.9c-1.1,0.8-1.9,0.3-2.9-0.5c2.3-1.1-0.5-1.7-0.3-2.6
	c1.5-0.8,2.1,0.6,3.5,0.3c-0.1-1.8-4.1-0.8-2.4-3.2c0.6-1.5,0.1-3.1,1.9-3.6c-1.2-1.4-0.9-2.6,1-3.9c-0.1,0-0.2-0.1-0.3-0.1
	c0.6-1.5,0.3-3.2,2-4.1c0.1-0.1,0.1-0.4,0-0.5C1271.6,692.1,1272.5,692,1273.3,691.7z M1271.7,705.6c-0.3-0.8-1.6,0-2.3-0.1
	C1269,707,1271,706.4,1271.7,705.6z M1277.8,701c1,0.5,1.8,1,2.8-0.3C1279.5,700.5,1278.7,700.1,1277.8,701z M1300.8,732.9
	c-1,0-1.8,0-2.9,0C1298,734.3,1299.8,733.4,1300.8,732.9z M1245.4,715.3c0.2-0.2,0.4-0.4,0.7-0.7
	C1245.1,713.3,1244.2,714.4,1245.4,715.3z M1247.9,712.3c-0.7-0.5-1.2-0.1-1.8,0.4c0.5,0.9,0.9,0.7,1.5,0.2
	C1247.7,712.7,1247.8,712.5,1247.9,712.3z M1258.3,694.5c0.3,0.6,0.5,1,0.7,1.4c0.7,0.4,1.3,0.1,1.9-0.3
	C1260.3,694.4,1259.6,694.5,1258.3,694.5z M1268.9,718.9c0.7,0.7,1.8,0.6,2.7,0.9C1271.8,718.4,1269.3,717.3,1268.9,718.9z
	M1336.5,759.7c-0.2,0-0.4,0.3-0.7,0.4c0.5,1,1.6,0,1.8,1.3c0.1,0.9-0.2,1.4-0.9,1.8c-1.7,0.6-4.3,2.7-3.9-0.5
	c-2.2,0.4-4.4,2.4-6.3,0.2c1.3-0.2,2.3-0.4,3.5-0.9c0-0.2-0.1-0.4-0.1-0.6c-1.4,0.4-3.1,0-3.9,1.1c-2.3,0-10.4-4.4-5.5,1
	c0.4,0.8-0.6,1.2-1.1,0.8c-0.9-1.4-1.9,0.7-3.1,0.6c0.4,0.4,0.9,0.8,1.5,1.3c0.3-0.6,1.7-0.9,1.4,0.1c-1.3,1.8,0.5,3.2,1.8,4
	c-0.9,0.4-1.3,0.4-1.9,0.6c-0.3,1.4-1.4,1.3-2.4,1.6c-3.9,1.6-5.8,1.1-9.8,0.9c0-1.6,1.5-0.7,2.1-1.8c-3.5,0.3-6.2-0.1-8.9-2.4
	c-0.9-1.3-3.1-1.2-2.8-3c-0.8,0.5-1.4,0.8-2.1,1.2c-0.3-1.9-1.9-1.5-3.1-2c0.1-0.7,0.1-1.4-1.1-1c-0.7,0.1-2.1,0-2.3-0.8
	c0.9-2.8-9.1-0.8-2.9-3.5c-2.8-0.1-6-0.7-8.3-2.4c0.1-2-2.1-1.1-3.2-2c2.2-3.3,4.8-3.4,8.5-2.8c-0.6-2.2-2.9-2.8-4.7-1.2
	c-1.6,0.4-4.4-0.6-2.2,1.9c-9.8-0.2,2.2-2,3.1-4.5c-4.1-2-7.3-3.9-11.8-5.1c1.5-0.5,3.5-0.3,2.4-2.5c2-0.7,4-1.5,6.2-2.2
	c-0.6-2-2.7-0.1-4.2-0.4c-0.8-0.1-1.8-1.3-0.3-1.3c1.5,0.7,1.6-1.5,3-1.1c1.3,1,3.3-0.5,4.7,0.1c0.9,0.3,1.6,1.1,2.7,1
	c0,0.4,0,0.7,0.1,1.2c3.3-1.4,3.8,2.5,7.3,1.1c-0.5,0.9-1.5,1.3-0.5,2.2c1.7-2.5,5.3,1.2,7.7,1.2c2.3,0.3,5.5,0.8,6.6,3.2
	c0.6-0.3-0.1-1-0.1-1.8c1.1,0.2,1.9,0.4,3,0.6c-1.8,2.4,1.1,2.3,2,0.4c-0.8-0.5-1.8-0.5-2.1-1.6c-1.6-1.6-4.4-1.2-4.5-4.4
	c-3.4,1.4-5.1-4-8.1-4.3c0-0.9,0.5-1.2,1-2c1,0.9,2.3,0.8,3.6,0.3c0.1,1.5,0.9,1.4,1.9,1c0.1,0.4,0.1,0.7,0.2,1.2
	c1.9-3.4,4.5-0.7,7.3-1c0-0.2,0-0.4,0-0.6c-2.1-0.4-4.4-1.7-5-3.7c2.7-0.4,4.6,3,8,3.4c0.7,0.7,0,1.9,1.4,2
	c6.1,1.9,3.7,4.8,7.5,2.8c-0.2,2.6,6.5,1.8,1.5,3.2c1.9,1.6,4.4,1.5,5.4,4.1c0.4,0.9,1.7,0.3,1.4,1.6c0.5,1.1,3.6,2,2.6,3.4
	c1.9,1.2,3.9,2.3,5.9,3.6c-0.5,0.2-1,0.4-1.6,0.7C1336.4,756.3,1342.7,762.5,1336.5,759.7z M1305.1,736.7c-1-0.8-2.4-1.9-3.5-0.4
	c1.1,0.4,2.1,0.7,3.1,1C1304.9,737.1,1305,736.9,1305.1,736.7z M1278,741.1c0-0.2-0.1-0.4-0.1-0.6c-1,0.2-2.1,0.4-3.1,0.5
	c0,0.2,0.1,0.3,0.1,0.5C1275.9,741.4,1277,741.3,1278,741.1z M1279.3,754.7c0,0.1,0,0.2,0,0.3c1.2,0.1,2.4,0.1,3.5,0.2
	C1283.1,753.4,1280.3,754.5,1279.3,754.7z M1287.1,752.7c-1.2-0.5-1.8-0.3-2.5,0.8C1285.4,753.4,1286.4,753.9,1287.1,752.7z
	M1309.3,754.5c0.6,0.7,2.2,1.3,2.2,0C1310.8,754.5,1309.7,753.9,1309.3,754.5z M1310,762.4c1,1.3,1.5,1.2,2.4-0.1
	C1311.6,762.3,1310.9,762.3,1310,762.4z M1308,748.5c0.8,0.6,2.2,0.6,3-0.8C1309.6,747.6,1308.6,747,1308,748.5z M1303.7,748.4
	c-0.8,0-1.7,0-2.5,0c0,0.1,0,0.3,0,0.4c0.8,0,1.7,0,2.5,0C1303.7,748.7,1303.7,748.5,1303.7,748.4z M1306.4,769.1
	c-0.9,0.1-1.6,0.1-2.4,0.2C1305,770.2,1305.4,770.2,1306.4,769.1z M1311.4,752.1c0-1.1-3.3-1.7-4.3-1.2
	C1307.5,752.1,1310.1,751.6,1311.4,752.1z M1309,770.1c0.6,0.2,1.3,0.4,1.9,0.6C1310.7,769.5,1309.3,769,1309,770.1z M1313.3,771.4
	c-0.7-1.4-2.2-0.5-2.8,0.5C1311.8,773,1312.2,770.8,1313.3,771.4z M1315.5,753.2c-0.7-0.6-1.3-0.6-2.5,0.1
	C1313.9,754,1314.5,753.9,1315.5,753.2z M1315.2,770c0.4,0.9,0.7,1.5,0.9,1.9c0.5-0.7,0.9-1.2,1.4-1.9
	C1316.7,770,1316,770,1315.2,770z M1316.8,755.2c-0.7,0.3-2-0.2-2.3,0.7C1315.3,756.3,1317.1,756.4,1316.8,755.2z M1320.7,758.9
	c-1.6-1.9-3.2-2-3.9-0.7C1318.2,758.6,1319.3,759.3,1320.7,758.9z M1335.5,762.5c-0.8-1.6-2.6-1.5-2.4,0.1
	C1333.4,763.3,1334.3,763.3,1335.5,762.5z M1218.8,794.4c0.8,1.8,1.9,0.5,2-0.7c-0.1-0.1-0.2-0.3-0.3-0.4
	C1219.9,793.6,1219.3,794,1218.8,794.4z M1261.7,693.7c0.8-0.1,1.4-0.2,2-0.3C1263.2,692.2,1261.9,692.5,1261.7,693.7z
	M1201.3,772.7c-0.4,1.4,1.7,0.8,2.4,0.7C1202.9,773,1202.2,772.5,1201.3,772.7z M1201.5,764.8c-1.6-0.1-3.2-0.7-4.2,0.9
	c1.5,0.8,3.5,0.7,4.2,2.8c-0.2-1.9,1.4-1.8,2.2-2.6c-1-0.1-2.2,0.5-3.1-0.2C1200.8,765.3,1201,765,1201.5,764.8z M1216.1,794.3
	c0.7,1.1,1.9,0.6,2.2-1C1217.4,793.6,1216.7,793.5,1216.1,794.3z M1182.4,807c-0.4-0.5-0.8-1-1.4-1.7c-0.2,0.3-0.5,0.6-1,1.2
	c-0.5-0.9-0.9-1.5-0.6-2.5c-2,0.4-2.6-1.1-0.5-2.9c-0.9-0.8-1.9-1.6-2.8-2.3c2.6-0.2,0.2-4.3-0.7-1.6c0,0.1-0.2,0.1-0.4,0.1
	c-0.1-0.4-0.3-0.8-0.4-1.2c0.8-1,2.2-0.5,3.5-1.1c0.5-1.4-1.5-1.2-2.4-0.9c0.5-1.2,2.5-1.4,3.6-2.2c1.9-2,3-4.7,4.9-6.9
	c0.4-2.3-3.7-0.3-3.5,1.5c-1.6-0.6-5.8,0.4-6.7,1.9c1.7,0.2,3.8-1.9,5-0.4c-0.6,0.1-1.6,0.2-2,0.6c-1.1,2.3-3.4-0.4-4.4,0.5
	c-1.3-5.7-2-11.5-2.4-17.3c0.9-1,1.9-1.9,3.2-2.3c-0.5-0.5-1.1-0.5-1-1.1c1,0,2,0,3-0.1c0-0.2,0-0.3,0-0.5c-0.5-0.1-1-0.2-1.6-0.3
	c0.9-2.3,2.7-3.3,5.2-3c-0.4-0.4-0.8-0.8-1.2-1.3c0.7-0.7,1.4-1.6,2.4-0.2c0.5-0.4,0.9-0.8,1.4-1.2c-0.2-0.3-0.5-0.5-0.7-0.8
	c1-1.1,4.3-0.7,5.6,0.9c-1.5-0.5-4.9,1.9-3.9,3.4c0.6-1.8,2.6-1,3.3-2c1-1.5,2.5,0.3,3.7-0.8c-0.8-0.4-1.5-0.8-2.2-1.1
	c0.1-0.6,0.9-0.3,1.4-0.3c0.6-3.9,2.4,1.3,5.8-3c0.7,0.4-0.4,1.2,0.2,1.6c1-0.8,1.9-1.7,3.1-2.2c-0.6,2.5,0.2,2,1.5,0.9
	c0.9,1.6-1,2.2-2.1,2.9c0.5,0.6,1.1,1.2,1.6,1.9c-4.7,1.3-2.2,3.2,0.3,5.7c-1.2,1.9-4.1,0.5-6.2,1.8c0.1,1.1,1,1.3,1.8,1.1
	c0.8-0.9,4.2-1.8,2.1,0.2c-3.2,0.9,0.4,2.9,1.8,3.8c-0.2,0.4-0.5,0.9-0.9,1.7c1.7-0.2,2-0.2,1.8-2c1.2-0.2,3.1-1.6,4,0.1
	c-1.1,0.1-2.3-0.3-3.1,1c0.4,0.3,0.8,0.6,1.3,0.9c-0.3,0.5-0.6,1-1,1.4c-0.6-2.3-2,0-3,1c1.3,0.3,3.9-0.7,4.3,0.5
	c-2.2,0-5.8,2.4-2.1,3.6c-0.9,1.6-2.4,0.6-3.6,0.8c-0.2,1.4,0.9,1,1.7,1.7c-0.1,1.2-2.9,1-1.6,2.2c-0.3,0.2-0.7,0.5-1.3,0.9
	c0.4,0.3,0.6,0.5,1.2,0.9c-0.9,0.4-1.5,1.1-2.3,0.6c-2.4,0.5,1.3,2.1-1.6,3c-1.3,0.2-1.1,1.8-2.3,2c-1.9-0.2-0.7,2.6-2.8,2.4
	c-3,3.6-1.6,4.9-6.5,5.7C1183.3,805.5,1184.7,804.9,1182.4,807z M1187.7,780.9c-0.6-0.3-1.3-0.5-2.1-0.9c0.4,1.5,1.1,1.6,2,1.3
	C1187.6,781.2,1187.6,781,1187.7,780.9z M1189.1,778.1c-1.7-0.1-4.9-1.5-5.5,0.6C1185.9,777.9,1187.3,781,1189.1,778.1z
	M1188.7,776.6c0.9,1.2,1.5,0.4,2.1-0.1C1191.3,774.5,1189.1,775.5,1188.7,776.6z M1197.9,777.8c-1.3-0.8-3.1,1.1-4.6,1.8
	C1194.9,780.4,1196.6,778.8,1197.9,777.8z M1194.2,775.3c-0.2,2.3,3.4,1.9,3.6,0.1C1196.6,774.7,1195.5,776.5,1194.2,775.3z
	M1195.7,769.2c0.7,0,1.3-0.1,2.3-0.1C1197.1,767.8,1195.7,768.1,1195.7,769.2z M1195.2,763.3c1-0.5,1.7-0.9,2.6-1.3
	C1196.4,761,1195.5,761.4,1195.2,763.3z M1194,766.1c0.3,0.5,0.4,0.8,0.7,1.3c-0.9,0.2-1.7,0.4-2.5,0.6c-0.2,2.2,3.7,0.5,2.7-1
	c0.3-0.2,0.7-0.3,1-0.5C1195.3,766.3,1194.7,766.2,1194,766.1z M1191.2,762.5c0.4-0.2,0.8-0.4,1.2-0.6c-0.1-0.2-0.2-0.4-0.3-0.6
	C1191.5,761.6,1190.6,761.6,1191.2,762.5z M1188.4,773.5c1.6,0.1,3.1,0.3,4.7,0.4c0,0.1,0,0.2,0,0.3c0.9-0.2,1.7-0.5,2.6-0.7
	c0-0.2-0.1-0.4-0.1-0.5C1193.1,773.8,1190.4,771.2,1188.4,773.5z M1183.4,773.3c2.9-0.4,3.1-0.5,3.5-1.7
	C1185.7,772.2,1184.7,772.7,1183.4,773.3z M1183.2,776.6c1.9-0.6,4,0,5.3-1.8c-0.3-0.7-0.8-0.8-1.3-0.3
	C1186.1,776.1,1183.6,774.3,1183.2,776.6z M1179.3,782.7c-0.5,1-1.6,1.8-1.6,2.7C1179.4,785.4,1180.3,784.3,1179.3,782.7z
	M1179.8,767.4c1-0.3,1.6-0.6,2.3-0.8C1180.7,765.9,1180.4,766,1179.8,767.4z M1183.2,780.2c-1,0-1.9,0-3,0
	C1181.5,781.1,1182.4,781.2,1183.2,780.2z M1184.2,781.1c-0.8,0.6-0.7,1.3-1,2.3C1184.6,783.4,1185.8,782.1,1184.2,781.1z
	M1182.8,802.5c0.1,0.1,0.1,0.2,0.2,0.3c0.5-0.3,1-0.6,1.7-1C1183.4,801.1,1183.1,801.3,1182.8,802.5z M1181.1,799.2
	c-0.9,0-1.8,0-2.7,0C1179.1,800.5,1181.1,800.3,1181.1,799.2z M1181.3,795c0.8,0,1.6,0,2.3,0c0-0.1,0-0.2,0-0.3c-0.8,0-1.6,0-2.3,0
	C1181.3,794.8,1181.3,794.9,1181.3,795z M1189.8,757.5c-0.6-0.5-1.1-0.8-1.7-0.1c0.2,0.2,0.4,0.5,0.7,0.8
	C1189.3,758,1189.6,757.7,1189.8,757.5z M1195.7,726.4c-1.3-1.4-3.7,0.2-1.4,1.4C1194.9,727.3,1195.3,726.9,1195.7,726.4z
	M1217.1,787.8c-0.8-0.2-1.7-1-2.5-0.3C1214.5,788.7,1216.5,788.7,1217.1,787.8z M1212.2,791.4c0.9,0.5,2.1,0.4,2.7,1.4
	c1.1,0.1,1.7-1.6,2.9-0.3c0.1-0.2,0.1-0.4,0.2-0.7c-0.9-0.2-1.9-0.2-3.1-0.6c0.6-0.8,1-1.3,1.4-2.1
	C1214.6,788.4,1212.5,790,1212.2,791.4z M1198,773.7c0.6-0.1,1.2-0.2,1.9-0.3c0-0.2,0-0.4-0.1-0.6c-0.6,0.1-1.3,0.2-1.9,0.3
	C1197.9,773.3,1198,773.5,1198,773.7z M1269.7,740.3c-1.3,0.7-2.2,1.4-3.5,1.7C1267.7,744.3,1269.1,742.2,1269.7,740.3z
	M1187.1,757.8c-1.5,0.5-2.6,1-4,1.5C1184.4,760.3,1186.7,759.5,1187.1,757.8z M1244,715.8c-6,1.2,0.8,4.9-5.7,6.4
	c-0.3-0.6-0.6-1.2-0.9-1.7c0,0,0,0,0,0c1.1-0.2,2.6,0.4,3-1.2c-1.3-1-2.5-0.1-3,1.2l0,0c0,0,0,0,0,0c-0.4,0.3-0.8,0.7-1.2,1
	c4,2,0.3,5.8-2.4,7c1.6,2.8-1.3,2.4-2.8,1.3c0.1,0.5-0.4,1.7,0.4,1.7c-1,1.5-2.7-0.5-4.2,0.5c1.4,2.8-1.8,1.6-3.4,1.8
	c-0.7,0.3-1.2,0.5-1.6-0.4c-1.4-1-3.4-0.6-4.8-1.7c2.8-2.8-6.4-2.4-8.2-5.6c1.1-1.4-0.4-1.5-1.1-2.1c0.4-1.2,1.2-0.3,1.8-0.3
	c0.7-0.7,0.9-1.3-0.1-2c2.6-1.8-1.5-5.6,0.9-7c-1.1-1.4-1.6,0.6-2.7,0.6c-1-0.1-2,0.8-3-0.1c-2.2,0.5,2,3.4-1,3.6
	c-0.9,0.1-1.7,0.1-1,1.5c-1.2,4.6-1.5-2.9-4.4,0.1c3.1,1.9,4.7,2.6,0.4,4.2c0.4,0.3,0.8,0.6,1.5,1.2c-1.3,0.4-2.5,0.8-4,1.2
	c4.8-5.4-1.9-5.7-1.4-3.5c3.2-1.7,2,2.8-2.2,1.3c-3.4-0.8-2.2,3.8-5.3,3.6c0.7,0.8,1.2,1.3,1.8,2c-3.6-0.9-1,3.4-3.5,3.1
	c-4.5,0.1-5.1-4.6-8.7-5.9c7.3-14.4,20.2-26,33.5-34.7c13.7-6.2,28.8-9.6,44-10.5c-0.1,0.1-0.3,0.1-0.4,0.2
	c0.4,0.2,0.8,0.4,1.4,0.7c-2,1.5-3.1,4.5-5.3,5.4c2.3,1.7-7.6,6,0.4,5.5c-0.3-0.6-0.6-1.1-1-1.8c1.2-0.9,2.9,0.6,3.2-1.4
	c0.6-0.4,1.3,0.1,1.5,0.9c-0.4,0.3-0.8,0.3-1.3,0.6c0.8,0.3,1.6,0.6,2.6,0.9c-0.1-0.7-0.3-1.3-0.4-1.9c0.6,0.1,1.1-0.2,1.7-0.5
	c-0.6-0.6-1.2-0.6-1.9-0.4c-0.5,0-1.3,0.3-1.3-0.7c-0.2-1.7,2.2-1.4,3.1-2.5c0.5-0.4,0.9-0.9,1.6-0.2c2.4,0.2,4.6-1,6.8,0.7
	c1.8-1.7,4.8-1.7,5.1-4.7c-0.4-0.1-0.9-0.2-1.5-0.3c0.2-0.3,0.4-0.5,0.5-0.7c1.1,0.1,2.3,0.1,3.4,0.2c-0.6,0.3-1.3,0.5-2,0.9
	c0.5,0.5,0.9,1,1.7,0.8c-1.7,1.4-2.1,1.7-2.7,1.7c0.1,1.7-2.7,2.2-3.8,3.3c0.7,1.3,0.6,1.6-0.9,1.6c-1.8,0.3-2.7,2.2-4.4,0.7
	c-1-0.6-3.1-0.3-3.2,0.8c1.2,0.8,2.6-1.1,3.8,1c-3.1,0.7-7.2,1.1-1.9,2.7c-0.4,2.4-4-0.8-5,1.1c1.2,1.2,3.1-0.1,4,1.4
	c-1.7,0.4-3.3,1.2-5.1,0.3c-0.6-0.2-2.1-0.7-2.4,0.1c0.8,1.7,4.6-0.5,5.2,1.2c-1.1,0.9-2,1.6-3,2.4c0.5,0.4,1.5,0.3,1.4,1
	c-1.3,0.3-2.2-0.9-3,0.5c0.5,0.3,1.3,0.1,1.2,0.8c-0.7,0.2-1.7,0-2.2,0.6c0.5,0.2,1,0.4,1.4,0.6c-1.4,1.2-2.3,1.7-3.3,2.7
	c-0.1,1.2-0.9,1.5-1.7,1.4C1245.8,712.3,1241.7,713.8,1244,715.8z M1259.6,688.6c0.9,0.8,1.4,0.9,2.7,0.5c-0.4,1.1-1.4,1.7-0.7,2.5
	c0.9-1.4,2.2-1.9,3.7-2C1264.9,688.3,1260.9,688,1259.6,688.6z M1259,689.3c-1-1.3-2.1-1.2-3.3,0.2
	C1256.8,690.2,1257.8,690.2,1259,689.3z M1251.4,696.5c0.7,0.1,1.5,0.1,2.2,0.2c0-0.2,0-0.4,0-0.6c-0.7,0-1.5-0.1-2.2-0.1
	C1251.4,696.1,1251.4,696.3,1251.4,696.5z M1246,697.2c1.8,0.8,3.7-0.3,1.4-1.9C1247,695.8,1245.6,696.3,1246,697.2z M1203.6,717.7
	c-1-0.9-1.6-0.5-2.2,1.4C1202.2,718.4,1202.6,718.2,1203.6,717.7z M1234.1,726.8c-0.5-0.4-0.9-0.6-1.2-0.8
	C1231.9,727.1,1233,727.9,1234.1,726.8z M1206.4,817.9c0.1,0.4,0.1,0.7,0.2,1.3c0.6-0.5,0.9-0.8,1.3-1.1c0-0.2-0.1-0.3-0.1-0.5
	C1207.4,817.8,1206.9,817.8,1206.4,817.9z M1208.9,817.1c0.2,1.2,1.2,0.1,1.9-0.2C1210.5,816,1209.3,816.4,1208.9,817.1z
	M1301.3,730.8c0-0.2,0-0.3-0.1-0.5c-0.7,0-1.3,0.1-2,0.1C1299.1,731.7,1300.7,731.7,1301.3,730.8z M1318,816.3
	c-0.4,1.3-2,1.9-1.2,3.1c0.1,0.1,0.1,0.3,0.2,0.4c-0.3,0.3-0.5,0.7-0.8,1c-0.5,0.1-1.1,0.1-1.5-0.1c0.5,2.7-4.1,1.8-6,2.4
	c0.3,1.4,1.7,1.1,2.4,2.1c-1.8,0-3.4,0-5.1,0c-0.2,1.6,1.6,1.2,2.4,2.2c-2,0.7-2.8-0.9-4.1-1.9c4.6-0.8-1.2-2-3.2-1.6
	c1.2,0.8,1.9,1.1,2.7,1.9c-1.7,2.4-2.2-1.6-3.8-1c-1.8,1.2-2.5-2.2-4.5-1.5c-1.4,0.1-2-0.8-1.4-2.1c1,0.4,2.8,1.8,3.3,0.4
	c-2.1-1.1-3.6-1.2-6.1-0.5c1.3-1.5,0.2-1.9-0.7-2.5c-0.3,0.7-0.5,1.2-0.7,2c-1.2-2.6-11-4.5-6.6-7.4c-0.7-0.2-1.4-0.3-2.1-0.5
	c-0.5,5.2-3.5-2.5-6.7-1c0.2,0.9,0.5,1.3,1.4,1.8c-1.8,0.4-0.9,1.6-1.2,2.7c-0.3,0.6-2,1.3-1.9,2c0.3,1.1,1.2,1.2,2.3,0.6
	c-0.2,1.2-2,0.4-1.8,1.8c-0.2,1.8-0.9,4.7,2.2,3.8c-1.9,1.6-3.5,1.3-5.3-0.1c-0.5,0.5,0.2,0.8,0.6,1.2c-2.1-0.7-4.2,0.2-4.3-2.5
	c-0.9-0.7-2.9-0.7-3.2-2.2c-0.4-2.7-3.8-1.4-4.9-3.5c-1.4-1.5,0.4-2.7-0.9-4.4c0-0.9,0.6-1.6-0.8-2c-0.5-2,1.3-1.7-1.3-3.1
	c0.9,0.1,1.3-0.3,1.9-0.7c-0.5-1.3-2.6-1.2-2-2.7c1.4-0.2,1.4,1.9,3,0.9c0.1-0.1,0.5,0.4,0.8,0.7c0.3-0.3,0.6-0.6,0.8-0.9
	c-0.4-0.3-0.7-0.6-1.1-0.8c8.6-5.9-9.9-7.2-8.5-10.5c-0.6-0.4-1.2,0.2-1.6,0.1c-0.5-0.4-1.8-1.2-1.1-1.9c1.2,0,1.3,1.2,2.7,0.5
	c-0.8-0.7-1.4-1.2-2.1-1.8c2.5-1.1,4.8,0.6,7.3,0.8c-0.2-1.2,0.4-1.5,1.3-2.1c-5.2-1-2.4-0.6-6-3c0.6-1.1,1.9,0.1,2.4-1.3
	c0.4,0.4,1.1,0.7,0.8,1.4c0.8-0.2,1.6-0.5,2.4-0.7c-0.2-0.9-1.8-0.5-2.1-1.5c1.3-1.6,2.6,1.1,3.9-0.1c2.7,1.5,6.3,0.9,8.7,3.3
	c-0.1,0.1-0.1,0.2-0.1,0.3c5.9-0.1,2.4,3.1,8,0.5c0.4,4.4,4.5,2.6,4,4.9c0.9,0,1.6,0,2.4,0c-0.3,0.6-0.4,1-0.7,1.6
	c1.5,0.3,3.1,0.3,3.8,2c1.8-1,0.9,1.5,2.6,1.6c0.6,0.1,1.7-0.4,1.9,0.3c-1,3.2,4.4,0.1,2.9,3.2c1.6,0.2,2.7,0.9,3.5,2.2
	c0.4,1,2.8,0.5,2.4,2.1c2.1-1.3,2.5,0.9,4.1,1.9c1.7,1.5,4,1.9,5.4,3.7c1.9,0,2.4,0.3,2.8,1.5c2.5-0.1,2.7,0.1,2.6,1.6
	C1315.1,814.9,1315.5,816.7,1318,816.3z M1289.4,817.6c-1.6-1-3-2-4.8-2.5C1284.9,816,1288.4,817.8,1289.4,817.6z M1300,822.9
	c-2.2-1.7-3.1,1.5-0.2,0.6C1299.9,823.3,1299.9,823.1,1300,822.9z M1218.1,800.9c-0.5,4-3.1,0.6-5.5,1.5c0.9,2.3,3.2,0.6,4.7,1.6
	c-0.4,0.6-0.9,0.8-1.6,0.9c1.2,2.7-3.9,2.5-2.4,5c0.6,0.6,0.8,1.1-0.3,1.3c-1.6,0.6,0.2,2.3-2.1,2.4c0.1,0.4,0.2,0.8,0.3,1.5
	c-2.2-0.4-3.7,2.7-5.8,0.9c0.6-0.6,1.4-1,2.2-1.4c-0.8-0.3-1.7-0.6-2.6-0.7c0.2-0.5,0.4-0.9,0.6-1.5c-0.6-0.1-1.2-0.5-1.7-0.9
	c1.4-1.5,3-1.2,3.2-3.9c-1.7,0.6-2.4,3-4.4,2.1c-0.9-0.5-1.3,0-1.4,0.7c-0.4,1.4-2.1,1.7-3,2.9c0.3,0.3,0.6,0.5,1.1,0.9
	c-6.7,4.5-1.3,5.6-5.6,6.6c0.1,1.3,1.5,2.6-0.4,3.2c0.4,0.3,0.7,0.5,1.2,0.9c-2.6,2.4-1.9-1.3-3.9-0.8c-1.3-1.1-2.5-2.2-3.7-3.3
	c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-4.3-2.3-1.7-3.1-4c-0.1-0.3-0.5-0.5-0.9-0.7c-0.4-0.6-0.9-1.2-1.2-1.9c-0.1-3,3.2-2.2,1.8-4
	c2.1,0.6,4.2-1.5,1-0.7c-0.2-1.8,2.3-1.7,3.5-2.6c-0.1-1-1.6-0.2-1.9-1.1c0.4-1.4,3.1,0.3,2.9-1.5c0-0.7,0.3-0.9,0.8-1.2
	c0.7-0.5,0.9-2,1.7-2.5c1.3-0.6,0.7-2.6,1.9-3.4c2.7-2.1,3.1-7.9,7.1-7.1c-2-1.4-1.9-0.3-0.5-2.6c0.1-1.2,1.3-1.1,2-1.7
	c0.3-1.9,1.8-1.2,3.2-1.3c-2.6,3.2,1.2,7.7,1.9,3.8c1-0.2,1.9-0.4,2.8,0.3c-1.5,1.7-2.3,2.6,0.7,1.8c0.1,0.9,0.1,1.6,0.2,2.4
	c1.9-0.8,3.8,0.5,4.5,2.3c-1.2,1-1.2,1.2,0.2,2.3c1-1.6,2.4-1.4,4-1C1218.9,798.9,1220.7,798.2,1218.1,800.9z M1185,813.4
	c-0.2-0.8-1.7-0.8-2.1,0.4C1183.8,813.7,1184.6,814.2,1185,813.4z M1204.4,792.3c1.1,0.5,1.6-1.3,0.9-2.4
	C1204.9,790.8,1203.8,791.4,1204.4,792.3z M1203.1,808.9c1.2-0.7,1.4-3.3,3.6-1.6C1207.4,805.3,1201.5,806.3,1203.1,808.9z
	M1209.7,792.8c0-1.3-0.8-0.8-1.7-0.7c0,0.2,0,0.5,0,0.7C1208.6,792.8,1209.2,792.8,1209.7,792.8z M1214.7,798.8
	c-0.7-1.2-1.1-1.3-2.5-0.6C1212.8,799.2,1213.8,798.4,1214.7,798.8z M1217,797.5c-0.3,2.7-1.8,1.7-3.5,2.9c0.9,0.5,3.2,0.9,3.7-0.7
	C1217.7,798.4,1219.7,797.1,1217,797.5z`,
})`
  fill: #ff91b2;
`;

export const Robot = styled.g``;
export const RobotHead = styled(PurpleFill).attrs({
  d: `M1007,256c1.9-0.9,4-1.1,5.7-2.4c0.9-0.7,2.2-1,3.3-1.5c1.8-0.7,3.7-1.3,5.5-2.1
	c1.8-0.8,3.5-1.7,5.3-2.4c2.2-0.9,4.5-1.6,6.6-2.6c1.6-0.7,3.4-1.5,4.7-2.7c2.6-2.4,4.3-5.4,4.5-9.2c0.1-1.8,0.1-3.6-0.9-5.2
	c-0.9-1.6-2-3.1-3.1-4.6c-1.1-1.4-2.5-2.5-4.3-2.9c-0.6-0.1-1.3-0.4-1.8-0.7c-1.9-0.9-3.8-0.9-5.7-0.4c-1.7,0.4-3.3,0.7-4.7,1.9
	c-0.8,0.7-1.9,1-2.8,1.4c-1.1,0.5-2.2,1-3.3,1.5c-2.8,1-5.7,1.9-8.5,3c-3.2,1.3-6.3,2.8-9.5,4.2c-0.2,0.1-0.6,0.1-0.8,0
	c-1.6-0.8-3.2-1.7-4.8-2.6c-0.7-0.4-1.4-0.7-2.1-0.9c-0.5-0.2-1-0.3-1.5-0.6c-2.2-1.3-4.6-2.1-7.2-2.4c-4.2-0.5-8.4-0.9-12.6,0.4
	c-2.2,0.7-4.4,1.3-6.5,2.1c-1.7,0.7-3.3,1.6-5.2,1.8c-1.5,0.2-2.6,2-2.3,3.6c0.2,1.3,1.7,2.4,3.2,2.3c1.5-0.1,2.5-1.5,2.5-2.9
	c0-0.7,0.2-1.2,0.9-1.5c1.6-0.7,3.1-1.6,4.7-2.1c3.4-0.9,6.9-1.8,10.5-1.8c3.1,0,6.1,0.5,8.9,1.5c1.2,0.4,2.4,1.1,3.6,1.6
	c1,0.5,1.9,0.9,2.8,1.5c0.6,0.3,1.1,0.8,1.8,1.3c-1.5,0.7-2.6,1.2-3.8,1.8c-0.7,0.3-1.4,0.7-2.2,0.9c-2.9,0.7-5.6,1.7-8.1,3.4
	c-0.2,0.1-0.3,0.2-0.5,0.2c-2.5,0.6-3.9,2.5-5.1,4.5c-1.9,3.4-1.8,7.1-0.7,10.6c1.2,3.7,3.7,6.6,7.4,8.1c2.5,1.1,5.3,1.3,8.1,0.9
	c0.9-0.1,1.7-0.6,2.5-0.9c1.2-0.5,2.5-1.1,3.7-1.6c2-0.9,3.9-1.8,5.9-2.6C1003.1,257.3,1005.1,256.8,1007,256z M988.5,258.9
	c-1.9,0.6-3.8,0.4-5.6-0.5c-1.1-0.6-2.4-1.2-3.2-2.1c-1-1-1.8-2.3-2.3-3.6c-1.2-2.9-0.1-6.8,2.3-8.8c1.2-1,2.4-2,3.8-2.7
	c2.2-1,4.5-1.6,6.8-2.5c0.5-0.2,1-0.5,1.5-0.8c2-0.9,3.9-1.7,5.9-2.5c3.3-1.4,6.6-2.9,10-4.3c2-0.8,4-1.5,6-2.1
	c1.5-0.5,3-0.9,4.5-0.9c2.1-0.1,3.9,0.7,5.4,2.1c0.6,0.6,1.2,1.2,1.8,1.9c1.2,1.4,1.3,3.1,1,4.8c0.6,3.7-1.3,6.2-3.9,8.3
	c-0.6,0.5-1.5,0.7-2.3,1.1c-2.8,1.2-5.5,2.4-8.3,3.5c-1.9,0.8-3.9,1.4-5.8,2.2c-3.4,1.4-6.8,2.8-10.3,4.2
	C993.4,257,991,258.1,988.5,258.9z M992.6,245.6c-1.2,0-2.2,1.1-2.2,2.2c0,1.1,0.7,1.8,1.8,1.8c1.2,0,2.3-0.9,2.3-1.9
	C994.4,246.7,993.4,245.6,992.6,245.6z M1011.8,241.9c1.4,0,2.4-0.9,2.4-2.2c0-0.8-1-1.7-2-1.7c-1.1,0-2.1,1-2.1,2.1
	C1010,241.1,1010.8,241.9,1011.8,241.9z`,
})``;
export const RobotBody = styled(PurpleFill).attrs({
  d: `M1105.5,260.3c-0.7-0.6-1.3-0.6-1.9,0.1c-2,2.8-7.3,2.6-9.3-0.4c-1.9-2.8-1.1-8.2,3.3-8.9
	c0.6-0.1,1.3-0.3,1.8-0.6c0.3-0.2,0.7-1,0.5-1.3c-0.2-0.4-0.8-0.9-1.2-0.8c-2.2,0.3-4.3,1.2-5.7,3c-1.4,1.8-2.3,3.7-1.9,6.1
	c0.1,0.3-0.2,0.9-0.5,1c-1,0.4-2.1,0.8-3.1,0.9c-3.2,0.2-6.3,1.4-9.6,1.4c-2.4,0-4.8,0.9-7.2,1.3c-2.5,0.5-5.1,0.8-7.6,1.2
	c-2.5,0.4-5,0.9-7.5,1.4c-2.3,0.4-4.7,0.9-7,1.3c-1.1,0.2-1.3-0.1-1-1.2c0.1-0.7,0.3-1.4,0.2-2.1c-0.3-2.3-1.6-4.1-3.7-5.2
	c-0.5-0.3-1.2-0.4-1.8-0.4c-2.1-0.2-3.6,0.9-4.9,2.2c-0.9,0.9-1.3,0.8-2.4,0.3c-1.6-0.8-3.2-1.6-4.9-2.2c-1.2-0.4-2.5-0.6-3.8-0.6
	c-1.9,0-3.9,0.1-5.8,0.3c-1,0.1-2.1,0.4-3,0.8c-0.8,0.3-1.5,0.8-2.3,1.2c-0.8,0.5-1.7,0.8-2.4,1.4c-3.1,2.7-5.9,5.7-7.1,9.9
	c-0.5,1.7-1.2,3.3-1.2,5.1c0,0.7-0.4,1-1,1.1c-2.1,0.5-3.4,1.6-4.5,3.6c-1.1,2.2-0.3,3.9,0.5,5.6c1,2.4,4.1,3.1,6.5,2.5
	c0.2-0.1,0.5-0.2,0.8-0.1c1.6,0.6,3.2,0.1,4.8-0.2c2.9-0.6,5.8-0.9,8.7-1.4c2-0.4,4.1-0.8,6.1-1.1c1.5-0.2,3.1-0.5,4.5,0.2
	c1.2,0.6,2.3,0.9,3.6,0.8c2.3-0.1,4.7,0,7-0.4c2.7-0.5,5.3-1.3,7.9-2.1c1.3-0.4,2.6-0.8,4-1.2c0.2,0.4,0.3,0.7,0.5,1.1
	c0.8,1.8,2.5,2.4,4.1,3c2.2,0.8,4.3,0.8,6.4-0.5c0.5-0.3,1-0.6,1.5-1c0.7-0.4,0.8-1.1,0.4-1.8c-0.4-0.6-1.2-0.7-1.8-0.3
	c-0.7,0.4-1.4,0.9-2.2,1.2c-3.6,1.3-6.2-1-7.1-3.8c-1.2-4.1,1.3-7.1,5.4-7.6c0.4,0,0.9,0,1.2-0.2c0.4-0.3,0.8-0.8,0.8-1.2
	c0-0.4-0.5-0.9-0.9-1.1c-0.4-0.2-1-0.2-1.5-0.2c-2.5,0.2-4.4,1.6-6.1,3.4c-0.8,0.8-1.2,1.8-1.3,2.9c-0.1,0.8-0.2,1.6-0.3,2.5
	c0,0.7-0.4,1-1,1c-0.6,0-1.2-0.1-1.7-0.5c-1.1-0.8-2.1-1.6-3-2.5c-1.9-1.8-4.3-2.9-6.8-3.8c-1.1-0.4-2.2-0.9-3.5-1.4
	c0.8-0.5,1.4-0.8,2-1.2c2.3-1.3,4.8-2.1,7.3-2.6c2.8-0.5,5.6-1,8.4-1.5c3-0.6,6-1.2,9-1.7c2.5-0.4,5-0.6,7.6-0.9
	c2.4-0.3,4.8-0.8,7.1-1.2c2.3-0.4,4.5-0.9,6.8-1.4c1.8-0.4,3.6-0.7,5.4-1c0.5-0.1,0.9-0.2,1.4-0.2c0.3,0,0.7,0.2,0.9,0.5
	c1,1.6,2.3,2.6,4.2,3.2c1.8,0.5,3.5,0.4,5.2,0.2c1.7-0.2,2.6-1.6,3.7-2.6C1106.1,261.4,1106.1,260.9,1105.5,260.3z M1027.8,283.6
	c-6.1,0.9-12,1.8-18.1,2.7c-0.1-0.8,0.2-1.4,0.8-1.7c0.7-0.3,1.5-0.6,2.2-0.9c1.9-0.8,3.9-1.6,5.8-2.4c1.8-0.8,3.5-1.6,5.3-2.4
	c1.3-0.6,2.6-1.1,3.9-1.7c0.3-0.1,0.7-0.2,1-0.3C1027.7,279,1027.2,281.1,1027.8,283.6z`,
})``;

export const TopDot1 = styled(OrangeFill).attrs({
  d: `M608.5,125.5c2.6,4.5-5.7,7.3-8.7,4.4C597.1,125.2,607,119.7,608.5,125.5z`,
})``;
export const TopDot2 = styled(OrangeFill).attrs({
  d: `M918.4,112.3c-5.1-2.2-9.1,5.2-4.1,8.1c2.8,1.4,5.7-0.6,6.3-2c0-0.1,0-0.1,0-0.1C921.5,116.6,920.3,112.8,918.4,112.3z`,
})``;

export const TopRightPlanet = styled.path.attrs({
  d: `M1283.1,84.6c51.5-4.9,54.2,89.4,0.3,88.2C1220.9,179.8,1220.1,73.7,1283.1,84.6z`,
})`
  fill: #e5e5f1;
`;
export const TopRightRing = styled(PinkFill).attrs({
  d: `M1190,253.8c-7.3,0-13.1-5.7-13-12.7c0.1-7.8,5.4-13.2,13.2-13.1
	c7.3,0.1,12.8,5.9,12.6,13.5C1202.7,248.3,1197,253.8,1190,253.8z M1183.2,240.9c-0.1,3.7,2.7,6.6,6.6,6.6c4,0,6.9-2.7,7-6.6
	c0.1-3.7-3-6.8-6.8-6.8C1186.3,234.1,1183.2,237.2,1183.2,240.9z`,
})``;

export const LeftRing = styled(PinkFill).attrs({
  d: `M58.7,564c11.5-0.1,19.5,9.7,19.1,20c-0.3,7.4-8.3,16.9-18.1,16.2c-10.4-0.8-18.1-7.6-17.6-18.6
	C42.5,571.6,50,563.7,58.7,564z M60,594.4c6.4,0.1,12-5.5,12.1-11.2c0.1-7.1-5.8-13.1-12.9-13.2c-5.1-0.1-11.5,7.3-11.9,13.2
	C47.1,588.7,55,594.5,60,594.4z`,
})``;
export const LeftPlus1 = styled(PurpleFill).attrs({
  d: `M29.6,423c0,2.5-0.1,5,0,7.5c0.1,1.6-0.6,2.4-2.1,2.5c-1.5,0.1-2.4-0.7-2.5-2.4
	c-0.2-4.3-0.3-8.5-0.4-12.8c-0.2-5.6,0-4.7-4.7-5c-2.2-0.1-4.4-0.2-6.6-0.5c-2-0.2-3-2.6-1.8-4c0.4-0.5,1.3-0.9,1.9-0.9
	c3,0.1,6,0.3,9,0.5c1.6,0.1,2.2-0.7,2.2-2.2c0.1-3.3,0.1-6.6,0.1-9.9c0-2.1,0-4.2,0.1-6.2c0.1-1.9,0.9-2.6,2.4-2.5
	c1.5,0.1,2.4,1.1,2.4,2.8c0,3.4-0.1,6.8-0.1,10.2c0,2,0,4,0.1,6.1c0,1.3,0.5,1.9,1.9,1.9c2.6,0,5.2,0.1,7.8,0c2.1-0.1,3,0.4,3,1.9
	c0.1,1.7-1,2.9-2.9,2.9c-2.5,0-5-0.1-7.5-0.2c-2.2,0-2.9,0.7-2.8,2.9c0.1,2.5,0,5,0,7.5C29.4,423,29.5,423,29.6,423z`,
})``;
export const LeftPlus2 = styled(PurpleFill).attrs({
  d: `M50.9,439c0,1.2,0,2.5,0,3.7c0,0.8-0.3,1.2-1,1.3c-0.7,0.1-1.1-0.4-1.2-1.2
	c-0.1-2.1-0.1-4.3-0.2-6.4c-0.1-2.8,0-2.3-2.2-2.5c-1-0.1-2.1-0.1-3.1-0.2c-0.9-0.1-1.4-1.3-0.8-2c0.2-0.2,0.6-0.4,0.9-0.4
	c1.4,0,2.9,0.1,4.3,0.2c0.8,0.1,1.1-0.4,1.1-1.1c0-1.6,0-3.3,0.1-4.9c0-1,0-2.1,0.1-3.1c0.1-0.9,0.4-1.3,1.1-1.3
	c0.7,0,1.2,0.5,1.2,1.4c0,1.7,0,3.4-0.1,5.1c0,1,0,2,0,3c0,0.6,0.3,1,0.9,1c1.2,0,2.5,0,3.7,0c1,0,1.4,0.2,1.5,1
	c0.1,0.9-0.5,1.4-1.4,1.4c-1.2,0-2.4-0.1-3.6-0.1c-1.1,0-1.4,0.4-1.3,1.5c0,1.2,0,2.5,0,3.7C50.8,439,50.8,439,50.9,439z`,
})``;
export const LeftDot = styled(OrangeFill).attrs({
  d: `M86,794.3c11.3-0.6,12.3-11.9,4.8-17.9c-0.2,0.2-0.3-0.3-0.6,0c-12.7-3.6-17.8,15.2-4.3,17.6C85.9,794.2,85.8,794.4,86,794.3z`,
})``;

export const CenterRing = styled(YellowFill).attrs({
  d: `M529.4,764c10.2,0.2,14.9,5.7,15.1,13.1c0.2,7.2-5.7,15.3-13.9,15
	c-8.1-0.3-14.5-5.5-14.6-13.6C515.9,770.5,520.9,764.4,529.4,764z M531.3,784.4c2.3,0.1,5.6-3.6,5.7-6.5c0.1-2.4-3.6-6.1-6.2-6.3
	c-3.3-0.2-6.7,3.1-6.9,6.7C523.8,781.7,526.7,784.1,531.3,784.4z`,
})``;
export const CenterDot = styled(GrayFill).attrs({
  d: `M673.5,635.1c-3.9,0.7-7.4-3.4-4.2-6.3C673.5,625.5,678.9,632.6,673.5,635.1z`,
})``;

export const RightPlus = styled(PurpleFill).attrs({
  d: `M1113.6,386.7c0,1.1,0,2.2,0,3.2c0,0.7-0.3,1-0.9,1.1c-0.7,0.1-1-0.3-1.1-1.1
	c-0.1-1.9-0.1-3.7-0.2-5.6c-0.1-2.4,0-2-2-2.2c-0.9-0.1-1.9-0.1-2.9-0.2c-0.9-0.1-1.3-1.1-0.8-1.8c0.2-0.2,0.6-0.4,0.8-0.4
	c1.3,0,2.6,0.1,3.9,0.2c0.7,0,1-0.3,1-0.9c0-1.4,0-2.9,0.1-4.3c0-0.9,0-1.8,0.1-2.7c0.1-0.8,0.4-1.1,1.1-1.1s1.1,0.5,1.1,1.2
	c0,1.5,0,3-0.1,4.5c0,0.9,0,1.8,0,2.6c0,0.6,0.2,0.8,0.8,0.8c1.1,0,2.3,0,3.4,0c0.9,0,1.3,0.2,1.3,0.8c0,0.8-0.4,1.3-1.3,1.3
	c-1.1,0-2.2-0.1-3.2-0.1c-1,0-1.2,0.3-1.2,1.3c0,1.1,0,2.2,0,3.2C1113.5,386.7,1113.6,386.7,1113.6,386.7z`,
})``;
export const RightDot1 = styled(GrayFill).attrs({
  d: `M836.9,385.4c-2.9,0.4-5.7-3.8-2.3-6C838.5,376.3,841.9,384.2,836.9,385.4z M836.2,382.2
	c-0.8,0.3-0.3,0.8,0.2,0.5C836.2,382.7,836.3,382.3,836.2,382.2z M833.5,383c0.2,0.2,0.2,0.5,0.5,0.5
	C834.1,383.2,833.8,383,833.5,383z`,
})``;
export const RightDot2 = styled(GrayFill).attrs({
  d: `M909.1,390.8c-2.9,0.4-5.7-3.8-2.3-6C910.6,381.7,914,389.5,909.1,390.8z`,
})``;
export const RightDot3 = styled(GrayFill).attrs({
  d: `M1012.5,526.5c-2.9,0.4-5.7-3.8-2.3-6C1014,517.4,1017.4,525.2,1012.5,526.5z`,
})``;

export const BottomLeftRing = styled(YellowFill).attrs({
  d: `M263.9,880.5c-7.7-0.1-16-8.5-15.9-16.3c0-7.7,8.5-15.5,16.7-15.3
	c9.3,0.2,17.3,8.1,16.9,16.7C281.3,874.1,273.4,880.7,263.9,880.5z M265.3,855.4c-3.6,3.7-7.8,6.4-7.4,7.9c0.8,3.1,4,5.6,6.3,8.3
	c2.5-2.1,6.5-4,7-6.5C271.6,862.8,268.2,859.8,265.3,855.4z`,
})``;
export const BottomRightPlus1 = styled(OrangeFill).attrs({
  d: `M966.6,923c0,2.5-0.1,5,0,7.5c0.1,1.6-0.6,2.4-2.2,2.5c-1.5,0.1-2.4-0.7-2.5-2.4
	c-0.2-4.3-0.3-8.5-0.4-12.8c-0.2-5.6,0-4.7-4.7-5c-2.2-0.2-4.4-0.2-6.6-0.5c-2-0.2-3-2.6-1.8-4c0.4-0.5,1.3-0.9,1.9-0.9
	c3,0.1,6,0.3,9,0.5c1.6,0.1,2.2-0.7,2.2-2.2c0.1-3.3,0.1-6.6,0.1-9.9c0-2.1,0-4.2,0.1-6.2c0.1-1.8,0.9-2.6,2.4-2.5
	c1.5,0.1,2.4,1.1,2.4,2.8c0,3.4-0.1,6.8-0.1,10.2c0,2,0,4,0,6.1c0,1.3,0.5,1.9,1.9,1.9c2.6,0,5.2,0,7.8,0c2.1-0.1,3,0.4,3,1.9
	c0.1,1.7-1,2.9-2.9,2.9c-2.5,0-5-0.1-7.5-0.2c-2.2,0-2.9,0.7-2.8,2.9c0.1,2.5,0,5,0,7.5C966.4,923,966.5,923,966.6,923z`,
})``;
export const BottomRightPlus2 = styled(OrangeFill).attrs({
  d: `M1046.1,759.7c0,1.1,0,2.2,0,3.2c0,0.7-0.3,1-0.9,1.1c-0.7,0-1-0.3-1.1-1.1
	c-0.1-1.9-0.1-3.7-0.2-5.6c-0.1-2.4,0-2-2-2.2c-0.9-0.1-1.9-0.1-2.9-0.2c-0.9-0.1-1.3-1.1-0.8-1.8c0.2-0.2,0.6-0.4,0.8-0.4
	c1.3,0,2.6,0.1,3.9,0.2c0.7,0,1-0.3,1-0.9c0-1.4,0-2.9,0.1-4.3c0-0.9,0-1.8,0.1-2.7c0.1-0.8,0.4-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.2
	c0,1.5,0,3-0.1,4.5c0,0.9,0,1.8,0,2.6c0,0.5,0.2,0.8,0.8,0.8c1.1,0,2.3,0,3.4,0c0.9,0,1.3,0.2,1.3,0.8c0,0.8-0.4,1.3-1.3,1.3
	c-1.1,0-2.2-0.1-3.2-0.1c-1,0-1.2,0.3-1.2,1.3c0,1.1,0,2.2,0,3.2C1046,759.7,1046,759.7,1046.1,759.7z`,
})``;
export const BottomRightPlus3 = styled(OrangeFill).attrs({
  d: `M1223.1,957.7c0,1.1,0,2.2,0,3.2c0,0.7-0.3,1-0.9,1.1c-0.7,0-1-0.3-1.1-1.1
	c-0.1-1.9-0.1-3.7-0.2-5.6c-0.1-2.4,0-2-2-2.2c-0.9-0.1-1.9-0.1-2.9-0.2c-0.9-0.1-1.3-1.1-0.8-1.8c0.2-0.2,0.6-0.4,0.8-0.4
	c1.3,0,2.6,0.1,3.9,0.2c0.7,0,1-0.3,1-0.9c0-1.4,0-2.9,0.1-4.3c0-0.9,0-1.8,0.1-2.7c0.1-0.8,0.4-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.2
	c0,1.5,0,3-0.1,4.5c0,0.9,0,1.8,0,2.6c0,0.5,0.2,0.8,0.8,0.8c1.1,0,2.3,0,3.4,0c0.9,0,1.3,0.2,1.3,0.8c0,0.8-0.4,1.3-1.3,1.3
	c-1.1,0-2.2-0.1-3.2-0.1c-1,0-1.2,0.3-1.2,1.3c0,1.1,0,2.2,0,3.2C1223,957.7,1223,957.7,1223.1,957.7z`,
})``;
export const BottomRightRing = styled(PinkFill).attrs({
  d: `M1059.4,896c8.8,0,17,8.4,16.9,17.4c0,9.4-8.7,17.8-18.2,17.6
	c-7.4-0.1-15.3-6.9-15.2-19.7C1043.1,903.8,1051.4,896,1059.4,896z M1051.2,912.2c0.2,6.1,3.6,10.2,8.3,10.2
	c4.8-0.1,9.1-4.4,8.9-8.9c-0.2-6-4.6-8.4-9.4-8.9C1055.3,904.1,1051.1,908.5,1051.2,912.2z`,
})``;
export const BottomRightDot = styled(PurpleDarkFill).attrs({
  d: `M963.6,825.5c2.1,4.1-6.4,6.8-8.5,3.2C954,823.8,961.5,822.7,963.6,825.5z`,
})``;