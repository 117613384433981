import React from 'react';
import styled from 'styled-components';
import Base from '../../../svgBase';

const DEFAULT_HEIGHT = 16;
const DEFAULT_WIDTH = 9;
const PATH = `M8.11035 -4.76837e-07H4.52441L0.579102 2.45312V5.79687L4.15723 3.60937H4.25098V16H8.11035V-4.76837e-07Z`;

const NumberBase = styled(Base)`
  transform: translateX(-1px);
`;

const One = (props) => (
  <NumberBase height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
);

export default One;