import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../../ListItem';
import {
  useHandleClickPeer,
  useWithFilter,
  useRenderPeerActions,
} from './hooks';
import {
  Container,
  FilterBox,
  NoResultsContainer,
  NoResultsHeader,
  NoResultsSubHeader,
  SelectAllLink,
  SelectedText,
  Toolbar,
  ToolbarWrapper,
} from './styles';
import List from '../../List';

const NO_RESULTS_HEADER = 'No Results Found';
const NO_RESULTS_SUB_HEADER = 'Please try again.';

const WithFilter = (props) => {
  const {
    filterBoxParams,
    hasItems,
    hasNoResults,
    noResultsHeader,
    noResultsSubHeader,
    selectAllLinkProps,
    selectedItemsText,
    peers,
    hideFilterBox,
    ...otherProps
  } = useWithFilter(props);
  const handleClickPeer = useHandleClickPeer(props);
  const renderPeerActions = useRenderPeerActions(props);
  const { hideFindPeersInEmptyState } = props;

  return (
    <Container>
      <Toolbar hasItems={hasItems}>
        {!hideFilterBox && <FilterBox {...filterBoxParams} />}
        <ToolbarWrapper>
          <SelectedText>{selectedItemsText}</SelectedText>
          <SelectAllLink {...selectAllLinkProps} />
        </ToolbarWrapper>
      </Toolbar>
      {hasNoResults && (
        <NoResultsContainer>
          <NoResultsHeader>{noResultsHeader}</NoResultsHeader>
          { !hideFindPeersInEmptyState && <NoResultsSubHeader>{noResultsSubHeader}</NoResultsSubHeader>}
        </NoResultsContainer>
      )}
      <List
        onClickPeer={handleClickPeer}
        listItemProps={{
          boldHeader: false,
          defaultSubHeaderContent: ListItem.subHeaderOptions.EMAIL,
          flushedEdge: true,
        }}
        peers={peers}
        renderPeerActions={renderPeerActions}
        {...otherProps}
      />
    </Container>
  );
};

WithFilter.defaultProps = {
  noResultsHeader: NO_RESULTS_HEADER,
  noResultsSubHeader: NO_RESULTS_SUB_HEADER,
  placeholder: 'Filter using name or email',
};

WithFilter.propTypes = {
  noResultsHeader: PropTypes.node,
  noResultsSubHeader: PropTypes.node,
  placeholder: PropTypes.string,
  selectedTextTitle: PropTypes.string,
};

export default WithFilter;
