import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { skillEntityPropType } from '@matterapp/skills';
import Badge from '../Badge';
import omitStyled from '../../../libs/omitStyled';
import Button from '../../Button';
import theme from '@matterapp/matter-theme';

const LinkButton = styled(Button.Simple.Primary).attrs({
  size: 'XS',
})`
  min-width: 100px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 20px;

  & > button {
    margin-top: 0;
  }
`;

const ListItemContainer = omitStyled('div', ['appearInHighlighted'])`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  flex-direction: row;
  
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  transition:
    background-color 2s ease-in-out,
    opacity 0.2s ease-in-out,
    max-height 2s ease-out
  ;

  opacity: 1;
  background-color: white;
  max-height: 1000px;

  ${({ appearInHighlighted }) =>
    appearInHighlighted &&
    `
    opacity: 0;
    background-color: ${theme.colors.blues[5]};
    max-height: 0;
  `}

  ${({theme}) => theme.media.medium`
    border-radius: 12px;
  `}
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.media.breakpoint(780)`
    flex-direction: row;
    width: 75%;
  `}
`;

const AvatarBox = styled.div`
  width: 48px;
  margin-right: 24px;
  min-width: 48px;
  height: 48px;
`;

const SkillDetailsBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const SkillNameHeading = styled.h3`
  color: ${() => theme.colors.black};
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  font-size: 17px;
  margin: 0;
`;

const SkillDescriptionContainer = styled.p`
  color: ${() => theme.colors.black};
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  margin: 0;
  margin-top: 8px;
`;

class ListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      appearInHighlighted: props.animateIn,
    };
  }

  componentDidMount() {
    const { appearInHighlighted } = this.state;
    if (appearInHighlighted) {
      this.setState({
        appearInHighlighted: false,
      });
    }
  }

  renderAvatar() {
    const { skill } = this.props;
    return (
      <AvatarBox>
        <Badge size="fluid" skill={skill} />
      </AvatarBox>
    );
  }

  renderDetails() {
    const { skill } = this.props;
    return (
      <SkillDetailsBox>
        <SkillNameHeading>{skill.name}</SkillNameHeading>
        {skill.description && (
          <SkillDescriptionContainer>
            {skill.description}
          </SkillDescriptionContainer>
        )}
      </SkillDetailsBox>
    );
  }

  renderButton() {
    const { skill } = this.props;
    return (
      <ButtonContainer>
        <LinkButton onClick={skill.onClick}>
          View Feedback
        </LinkButton>
      </ButtonContainer>
    );
  }

  render() {
    const { rcLabel } = this.props;
    return (
      <ListItemContainer
        className="matterapp-picked-skills-list-item"
        appearInHighlighted={this.state.appearInHighlighted}
        data-rc={rcLabel}
      >
        <Details>
          {this.renderAvatar()}
          {this.renderDetails()}
        </Details>
        <div>
          {this.renderButton()}
        </div>
      </ListItemContainer>
    );}
}

ListItem.propTypes = {
  skill: skillEntityPropType.isRequired, // eslint-disable-line
  animateIn: PropTypes.bool.isRequired,
  rcLabel: PropTypes.string,
};

export default ListItem;
