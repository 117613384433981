import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { Input, TextArea  } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { ACTIONS } from './reducer';
import { RadioNoBackground } from './styles';

const StyledInput = styled(Input)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  width: '340px'
}));

const DescriptionInput = styled(TextArea.WithCount)(({ theme }) => ({
  fontWeight: theme.fontWeight.regular,
  minHeight: '64px',
  border: 'none',
  marginTop: theme.spacing(2)
}));

const ANONYMOUS_OPTIONS = [
  'Anonymous Survey: All answers are shared anonymously', 
  'Attributed Survey: Names are attached to all responses'
];

export default function CreateScreen({ state, dispatch }) {
  const { name, description, showErrors, isAnonymous } = state;
  const descriptionError = showErrors && description?.length < 25 ? 'Description must be at least 25 characters' : '';
  let nameError = name?.trim().length < 5 ? 'Title must be at least 5 characters' : '';

  if (name?.trim().length > 255) {
    nameError = 'Title must be at most 255 characters';
  }

  if (!showErrors) {
    nameError = '';
  }

  return (
    <>
      <Paper sx={{ m: 1 }}>
        <Typography variant='h5' component='p'>
          Survey Title:
        </Typography>
        <StyledInput
          type="text"
          value={name}
          rcLabel="survey-title-input"
          placeholder="E.g., Employee onboarding survey"
          showErrorMessageBelow
          errorMessage={nameError}
          onChange={(event) => {  
            dispatch({
              type: ACTIONS.UPDATE_TITLE,
              payload: event.target.value
            });
          }}
        />
        <Typography variant='h5' component='p' sx={{ mt: 4 }}>
          Survey Description:
        </Typography>
        <Box sx={{ width: '600px', maxWidth: '100%' }}>
          <DescriptionInput 
            onChange={(e) => dispatch({
              type: ACTIONS.UPDATE_DESCRIPTION,
              payload: e.target.value
            })
            }
            value={description}
            error={descriptionError}
            minLength={25}
            maxLength={400}
            allowErrorBeforeBlur
            rcLabel="survey-description-input"
            placeholder="Give people context for the survey."
          />
        </Box>
        {descriptionError && (
          <Typography variant='body2' component='p' color='error' sx={{ mt: 0.5 }} data-rc="description-error-message">
            {descriptionError}
          </Typography>
        )}
        <Typography variant='body2' component='p' color='blacks.50' sx={{ mt: 2 }}>
          The title and description will be visible to recipients.
        </Typography>
      </Paper>
      <Paper sx={{ m: 1 }}>
        <Typography variant='h5' component='p'>
          Survey Anonymity:
        </Typography>
        <RadioNoBackground 
          onChange={() => {
            dispatch({ type: ACTIONS.TOGGLE_ANONYMITY });
          }}
          value={isAnonymous ? ANONYMOUS_OPTIONS[0] : ANONYMOUS_OPTIONS[1]}
          values={ANONYMOUS_OPTIONS}
        />
        <Typography variant='body2' component='p' color='blacks.50' sx={{ mt: 2 }}>
          Anonymous surveys typically result in higher quality responses.
        </Typography>
      </Paper>
    </>
  );
}