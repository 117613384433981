import React from 'react';
import { Button, ModalNew as Modal, Skill } from '@matterapp/matter-ui';
import {
  ModalHeader,
  ModalFooter,
  CancelButton
} from './RecommendedSkillsModeStyles';

class RecommendedSkillsMode extends React.PureComponent {
  renderModalHeader = () => {
    const { onClickBack, onClickDone } = this.props;
    return (
      <ModalHeader
        left={<Modal.Header.Buttons.Back onClick={onClickBack} />}
        right={<Modal.Header.Buttons.Close onClick={onClickDone} fill="#343799"/>}
        title="Recommended"
      />
    );
  };

  renderSkillActions = ({ skill, isSelected }) => {
    const { getHandlePickRecommendedSkill } = this.props;
    return (
      <Button
        size="S"
        onClick={getHandlePickRecommendedSkill(skill)}
        basic
        disabled={isSelected}
      >
        {isSelected ? 'Added' : 'Add'}
      </Button>
    );
  };

  renderModalBody = () => {
    const { skillsData } = this.props;
    const { currentUser, recommendedSkills } = skillsData;
    const userSkills = currentUser.pickedSkillDetails.map(({ skill }) => ({
      skill,
      isSelected: true,
    }));

    const skillsToAdd = [...recommendedSkills].filter((skill) => {
      const isAlreadyAdded = userSkills.some((userSkill) => userSkill.skill.id === skill.id);
      return !isAlreadyAdded;
    }).sort((a, b) => a.name.localeCompare(b.name))
      .map((skill) => ({
        skill,
        isSelected: false,
      }));



    return (
      <Modal.Body fixedHeight>
        <Skill.AddList
          userSkills={userSkills}
          skillsToAdd={skillsToAdd}
          renderSkillActions={this.renderSkillActions}
          filterNonIconSkills
        />
      </Modal.Body>
    );
  };

  renderModalFooter = () => {
    const { onClickDone } = this.props;
    return (
      <ModalFooter>
        <CancelButton
          fluid
          color="black"
          size="small"
          onClick={onClickDone}
        >
          Cancel
        </CancelButton>
      </ModalFooter>
    );
  }

  render = () => {
    const { skillsData } = this.props;
    const { loading, currentUser } = skillsData;
    if (loading || !currentUser) { 
      return null; 
    }
    
    return (
      <React.Fragment>
        {this.renderModalHeader()}
        {this.renderModalBody()}
        {this.renderModalFooter()}
      </React.Fragment>
    );
  };
}

export default RecommendedSkillsMode;
