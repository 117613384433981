import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import RequestFeedback from '../RequestFeedback';
import GiveFeedback from '../GiveFeedback';
import GiveKudos from '../GiveKudos';
import { useComposer } from './hooks';
import { Container, ContentContainer, ContentWrapper } from './styles';

const Main = (props) => {
  const {
    banner,
    giveFeedbackProps,
    giveKudosProps,
    requestFeedbackProps,
    showTabs,
    showTabProps,
    tabProps,
  } = useComposer(props);

  return (
    <Container>
      {banner}
      <Tabs {...tabProps} />
      <ContentContainer {...showTabProps}>
        <ContentWrapper>
          {showTabs.request && <RequestFeedback {...requestFeedbackProps} />}
          {showTabs.give && <GiveFeedback {...giveFeedbackProps} />}
          {showTabs.kudos && <GiveKudos {...giveKudosProps} />}
        </ContentWrapper>
      </ContentContainer>
    </Container>
  );
};

Main.defaultProps = {
  giveFeedbackProps: {},
  giveKudosProps: {},
  requestFeedbackProps: {},
  /** Visibility of tabs */
  showTabs: {
    request: true,
    give: true,
    kudos: true,
  },
};

Main.propTypes = {
  banner: PropTypes.node,
  giveFeedbackProps: PropTypes.shape(GiveFeedback.propTypes),
  giveKudosProps: PropTypes.shape(GiveKudos.propTypes),
  requestFeedbackProps: PropTypes.shape(RequestFeedback.propTypes),
  showTabs: PropTypes.shape({
    request: PropTypes.bool,
    give: PropTypes.bool,
    kudos: PropTypes.bool,
  }),
};

export default Main;
