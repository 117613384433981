import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { ButtonNew } from '../../Button';
import Avatar from '../../Avatar';
import { peer as peerPropType } from '../prop-types';
import GamePanelBase from '../GamePanelBase';
import Panel from '../../Panel';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  button {
    width: 100%;
  }
  button:first-child {
    margin-right: 16px;
  }
`;

const AvatarContainer = styled.div`
  margin-bottom: 8px;
`;

const Header = styled.div`
  font-weight: bold;
  line-height: 32px;
  font-size: 24px;
  color: ${() => theme.colors.blacks[90]};
  margin-top: 24px;
  margin-bottom: 32px;
`;

const FriendlyName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProgressCounter = styled.div`
  position: absolute;
  top: 22px;
  right: 24px;
  font-size: ${() => theme.sizes.fontSizes[40]};
  line-height: ${() => theme.sizes.lineHeights[60]};
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${() => theme.colors.blacks[90]};
  opacity: 0.5;
`;

const WorkRelationshipMode = ({
  questionNumber,
  numTotalQuestions,
  onPeerAccept,
  onPeerReject,
  peer,
}) => {
  const friendlyName = peer.fullName ? peer.fullName : peer.email;
  return (
    <GamePanelBase
      centered
      footer={
        <Panel.Footer.Basic centered smallPadBottom>
          <ButtonContainer>
            <ButtonNew onClick={onPeerReject} basic>
              No
            </ButtonNew>
            <ButtonNew onClick={onPeerAccept} primary>
              Yes
            </ButtonNew>
          </ButtonContainer>
        </Panel.Footer.Basic>
      }
    >
      <Panel.Block.Basic>
        <ProgressCounter>
          {questionNumber + 1}/{numTotalQuestions}
        </ProgressCounter>
        <Header>
          <AvatarContainer>
            <Avatar size={64} url={peer.photoUrl} email={peer.email} />
          </AvatarContainer>
          Do you work with <br />
          <FriendlyName>{friendlyName}?</FriendlyName>
        </Header>
      </Panel.Block.Basic>
    </GamePanelBase>
  );
};

WorkRelationshipMode.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  numTotalQuestions: PropTypes.number.isRequired,
  onPeerAccept: PropTypes.func.isRequired,
  onPeerReject: PropTypes.func.isRequired,
  peer: peerPropType.isRequired,
};

export default WorkRelationshipMode;
