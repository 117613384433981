import PropTypes from 'prop-types';
import { useExperiment } from './useExperiment';

export const Experiment = ({ children, name }) => {
  const { loading, error, assignment } = useExperiment({ name });
  return children({
    loading,
    error,
    assignment,
  });
};

Experiment.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
