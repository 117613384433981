import theme from '@matterapp/matter-theme';
import { css } from 'styled-components';

const containerWidth = '359px';

export const containerStyles = css`
  position: relative;
  min-width: unset;
  color: ${theme.colors.white};
  text-align: center;
  border-radius: 0;

  ${theme.media.XS`
    min-width: ${containerWidth};
  `}

  ${theme.media.medium`
    border-radius: ${theme.borderRadius.L};
    line-height: ${theme.lineHeight.S};
    font-size: ${theme.fontSize.S};
  `}
`;

export const headingStyles = `
  font-weight: ${theme.fontWeight.bold};
  line-height: ${theme.fontSize.L};
  font-size: ${theme.fontSize.M};
  text-align: center;
  color: ${theme.colors.white};
`;

export const containerStylesWithImage = css`
  ${containerStyles}
  box-sizing: border-box;
  padding: ${theme.spacing.singleAndHalf};
  height: 320px;
  overflow: hidden;

  ${theme.media.M`
    height: 200px;
    padding: ${theme.spacing.singleAndHalf};
  `}
`;

export const headingStylesWithImage = css`
  align-items: top;
  color: ${theme.colors.black};
  display: flex;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.M};
  height: auto;
  line-height: ${theme.lineHeight.L};
  margin: auto;
  max-width: 330px;
  text-align: center;

  ${theme.media.M`
    align-items: center;
    font-weight: ${theme.fontWeight.regular};
    height: 100%;
    margin: 0;
    text-align: left;
    max-width: 30vw;
  `}
`;
