import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Clickable, IconNew as Icon } from '@matterapp/matter-ui';
import theme, { colors, font, media, spacing } from '@matterapp/matter-theme';

const BannerWrapper = styled.div`
  height: auto;
  overflow: hidden;
  transition: max-height ${theme.transitions.times.default};
  margin-top: -1px;
  ${({ isOpen, isCollapsable }) => isOpen ? !isCollapsable ? '' : `
    max-height: 250px;
    transition: max-height 0.2s ease-out-in;
  ` : `max-height: 56px;`}
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  padding: ${theme.padding.S};
  ${({ isOpen }) => isOpen ? `
    background: ${colors.purples[50]};
  ` : `
    background: ${colors.purples[60]};
    text-align: center;
  `}
  transition: background ${theme.transitions.times.default};
  
  ${media.M`
    ${({ roundAllCorners }) => roundAllCorners ? `
      border-radius: ${theme.borderRadius.M};
    ` : `
      border-radius: ${theme.borderRadius.M} ${theme.borderRadius.M} 0 0;
    `}
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ isOpen }) => isOpen ? `
    justify-content: space-between;
  ` : `
    justify-content: center;
  `}
`;

const CloseButton = styled(Clickable)`
  height: ${theme.spacing.double};
  width: ${theme.spacing.double};
  position: relative;
  bottom: ${theme.spacing.quarter};
  & svg path {
    fill: ${theme.colors.white};
  }
`;

const CloseIcon = styled(Icon).attrs(() => ({
  name: 'close',
}))``;

const ExpandedBannerHeader = styled.div`
  font-size: ${font.size.M};
  line-height: ${theme.lineHeight.L};
  font-weight: ${font.weight.bold};
  margin-bottom: ${spacing.half};
`;

const CollapsedBannerHeader = styled.div`
  font-size: ${font.size.base};
  line-height: ${theme.lineHeight.M};
  margin-top: -${spacing.singleAndQuarter};
  margin-bottom: ${spacing.half};
  color: ${colors.purples[5]};
`;

const BannerDescription = styled.div`
  font-size: ${font.size.S};
  line-height: ${theme.lineHeight.M};
  font-weight: ${font.weight.medium};
  margin-bottom: ${spacing.single};
  color: ${colors.purples[5]};
  transition: opacity ${theme.transitions.times.default};

  ${media.M`
    max-width: 550px;
  `}

  ${({ isOpen }) => isOpen ? `
    opacity: 1;
  ` : `
    opacity: 0;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${media.S`
    flex-direction: row;
  `}
`;

const BannerButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.purples[50]};
  border-color: white;
  color: white;
  font-weight: ${font.weight.medium};
  transition: opacity 0.2s ease-in-out;
  ${({ isSecondary, showSecondaryOnlyOnMobile }) => isSecondary && showSecondaryOnlyOnMobile ? 'margin-top: 16px' : ''};

  & svg {
    margin-left: 6px;
    height: 20px;
    width: 20px;
    & svg {
      path {
        stroke: ${colors.white};
      }
    }
  }

  &:hover {
    color: ${colors.purples[40]};
    border-color: ${colors.purples[60]};
    background: white;
    & svg {
      path {
        stroke: ${colors.purples[40]};
      }
    }
  }

  &:active {
    color: ${colors.purples[50]};
    border-color: ${colors.purples[60]};
    background: white;
    & svg {
      path {
        stroke: ${colors.purples[50]};
      }
    }
  }

  ${({ isOpen }) => isOpen ? `
    opacity: 1;
  ` : `
    opacity: 0;
  `}

  ${media.S`
    align-self: flex-end;
    width: 240px;
    ${({ isSecondary }) => isSecondary ? 'margin-left: 16px' : ''};
  `}

  ${media.M`
    display: ${({ showSecondaryOnlyOnMobile }) => showSecondaryOnlyOnMobile ? 'none' : ''};
  `}
`;

const CollapsedButton = styled(Button.Link)`
  margin-left: -${spacing.single};
  font-size: ${font.size.base};
  line-height: ${theme.lineHeight.M};
  font-weight: ${font.weight.bold};
  color: white;
  text-decoration: underline;
  &:hover {
    color: ${colors.blues[30]};
    text-decoration: underline;
  }
  &:active {
    color: ${colors.blues[40]};
    text-decoration: underline;
  }
`;

const PurpleBanner = (props) => {
  const {
    id,
    isCollapsable,
    roundAllCorners,
    expandedHeader,
    collapasedHeader,
    description,
    buttonLabel,
    onButtonClick,
    showSecondaryOnlyOnMobile,
    secondaryButtonLabel,
    secondaryOnButtonClick,
    secondaryButtonHref,
    style,
  } = props;
  const bannerRef = useRef();
  const [bodyHeight, setBodyHeight] = useState();
  const [isBannerExpanded, setIsBannerExpanded] = useState(true);

  const setHeight = () => {
    if (bannerRef.current) {
      setBodyHeight(bannerRef.current.clientHeight);
    }
  };

  useEffect(() => {
    setHeight();
  }, [bannerRef]);

  useEffect(() => {
    window.addEventListener('resize', setHeight);
    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  return (
    <BannerWrapper
      id={id}
      ref={bannerRef}
      isOpen={isBannerExpanded}
      height={bodyHeight}
      isCollapsable={isCollapsable}
      style={style}
    >
      <BannerContainer isOpen={isBannerExpanded} roundAllCorners={roundAllCorners}>
        <HeaderContainer isOpen={isBannerExpanded}>
          {isBannerExpanded ? 
            <ExpandedBannerHeader isOpen={isBannerExpanded}>
              {expandedHeader}
            </ExpandedBannerHeader> : 
            <CollapsedBannerHeader isOpen={isBannerExpanded}>
              {collapasedHeader}
              <CollapsedButton onClick={onButtonClick}>
                {buttonLabel} 
              </CollapsedButton>
            </CollapsedBannerHeader>
          }
          {isBannerExpanded && isCollapsable && <CloseButton
            isOpen={isBannerExpanded}
            onClick={() => setIsBannerExpanded(!isBannerExpanded)}
          >
            <CloseIcon />
          </CloseButton>}
        </HeaderContainer>
        <BannerDescription isOpen={isBannerExpanded}>
          {description}
        </BannerDescription>
        <ButtonContainer>
          <BannerButton
            isOpen={isBannerExpanded}
            onClick={onButtonClick}
          >
            {buttonLabel} 
          </BannerButton>
          {secondaryButtonLabel && 
            <BannerButton
              isOpen={isBannerExpanded}
              onClick={secondaryOnButtonClick}
              href={secondaryButtonHref}
              isSecondary={!!secondaryButtonLabel}
              showSecondaryOnlyOnMobile={showSecondaryOnlyOnMobile}
            >
              {secondaryButtonLabel}
            </BannerButton>}
        </ButtonContainer>
      </BannerContainer>
    </BannerWrapper>
  );
};

PurpleBanner.propTypes = {
  id: PropTypes.string,
  isCollapsable: PropTypes.bool,
  roundAllCorners: PropTypes.bool,
  expandedHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  collapasedHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
  showSecondaryOnlyOnMobile: PropTypes.bool,
  secondaryButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondaryOnButtonClick: PropTypes.func,
  secondaryButtonHref: PropTypes.string,
  style: PropTypes.object,
};

export default PurpleBanner;