import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import styled from 'styled-components';
import Button from '../../../Button';
import { ActionContainer, PeerSuggestionsImage } from './common/components';
import { PEERS_SUGGESTIONS_TEXT, REPLACE_TEXT } from './common/strings';
import theme from '@matterapp/matter-theme';

const ContentContainer = styled.div`
  max-width: 520px;
`;

const SuggestionsButton = styled(Button.Simple.Minimal.Text).attrs({
  buttonType: Button.Simple.buttonTypes.CTA,
})`
  box-shadow: ${theme.shadows[40]};
`;

const InvitePeers = (props) => {
  const {
    numberOfSuggestions,
    onClickPeerSuggestions,
    peerSuggestionsPageLink,
    ...otherProps
  } = props;

  const suggestionsText =
    numberOfSuggestions === 1
      ? PEERS_SUGGESTIONS_TEXT.suggestionsSingular
      : PEERS_SUGGESTIONS_TEXT.suggestions;

  const buttonText = suggestionsText.replace(REPLACE_TEXT, numberOfSuggestions);

  const actions = (
    <ActionContainer>
      <SuggestionsButton
        to={peerSuggestionsPageLink}
        onClick={onClickPeerSuggestions}
      >
        {buttonText}
      </SuggestionsButton>
    </ActionContainer>
  );

  return (
    <Base
      {...otherProps}
      actions={actions}
      bannerType={Base.bannerTypes.INFO}
      content={
        <ContentContainer>{PEERS_SUGGESTIONS_TEXT.content}</ContentContainer>
      }
      header={PEERS_SUGGESTIONS_TEXT.header}
      image={<PeerSuggestionsImage />}
    />
  );
};

InvitePeers.propTypes = {
  /** The number of peer suggestions the user has. */
  numberOfSuggestions: PropTypes.number.isRequired,
  /** Callback when peer suggestions button is clicked. */
  onClickPeerSuggestions: PropTypes.func,
  /** Link to peer suggestions page if on click callback doesn't redirect. */
  peerSuggestionsPageLink: PropTypes.string,
};

export default InvitePeers;
