import PropTypes from 'prop-types';
import React from 'react';
import { Avatar } from '@matterapp/matter-ui';
import {
  PreviewInputContainer,
  TextContainer,
  TextEdit,
  AnimationContainer,
  Label,
  LabelContainer,
  AddImageContainer,
  AddImageText
} from './styles';

function PreviewCustomKudos({
  onPreviewTextChange,
  previewTextValue,
  backgroundSettings,
  titleValue,
  url
}) {
  return (
    <PreviewInputContainer {...backgroundSettings}>
      <TextContainer {...backgroundSettings}>
        <TextEdit
          {...backgroundSettings}
          autoHeight
          autoHeightOnMount
          onChange={onPreviewTextChange}
          value={previewTextValue}
          placeholder="Your message goes here..."
        />
      </TextContainer>
      <AnimationContainer>
        <LabelContainer>
          <Label>{titleValue || 'Kudos Title'}</Label>
        </LabelContainer>
        { url &&
          <Avatar
            size={128}
            url={url}
            squaredWithoutBorder
          />
        }
        {!url &&
          <AddImageContainer>
            <AddImageText {...backgroundSettings}>IMAGE</AddImageText>
          </AddImageContainer>
        }
      </AnimationContainer>
    </PreviewInputContainer>
  );
}

PreviewCustomKudos.propTypes = {
  onPreviewTextChange: PropTypes.func,
  previewTextValue: PropTypes.string,
  titleValue: PropTypes.string,
  url: PropTypes.string,
  backgroundSettings: PropTypes.object
};

export default PreviewCustomKudos;
