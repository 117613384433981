import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DEFAULT_VIEW_BOX = '0 0 24 24';

const Container = styled.svg`
  -webkit-touch-callout: none;
`;

export const Group = styled.g``;

export const Path = styled.path`
  fill: currentColor;
`;

export const PathEvenOdd = styled(Path)`
  fill-rule: "evenodd";
  clip-rule: "evenodd";
`;

/**
 * SVG react component wrapper.
 */
const SVG = (props) => {
  const {
    children,
    className,
    height,
    size,
    viewBox,
    width,
    ...otherProps
  } = props;
  return (
    <Container
      className={className}
      height={size || height}
      width={size || width}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      {children}
    </Container>
  );
};

SVG.viewBoxFromSize = (size) => `0 0 ${size} ${size}`;

SVG.propTypes = {
  /** Additional children. */
  children: PropTypes.node,
  /** Additional class.  */
  className: PropTypes.string,
  /** Height of the svg in pixels. */
  height: PropTypes.number,
  /** Height and width of the svg in pixels. Overrides both `height` and `width` props. */
  size: PropTypes.number,
  /** Width of the svg in pixels. */
  width: PropTypes.number,
  /** The view box sizing of the svg. */
  viewBox: PropTypes.string,
};

SVG.defaultProps = {
  viewBox: DEFAULT_VIEW_BOX,
};

export default SVG;
