import React from 'react';
import PropTypes from 'prop-types';

import { presets } from 'react-motion';
import { Collapse as ReactCollapse } from 'react-collapse';

const Collapse = ({ isOpened, children, ...others }) => (
  <ReactCollapse
    isOpened={isOpened}
    springConfig={presets.stiff}
    {...others}
  >
    {children}
  </ReactCollapse>
);

Collapse.defaultProps = {
  isOpened: false,
};

Collapse.propTypes = {
  isOpened: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Collapse;
