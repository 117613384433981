import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Tooltip, Dropdown, Loader } from '@matterapp/matter-ui';
import updateMemberProperties from 'graphql-queries/mutations/workspaceMembers/updateMemberProperties';
import updateMemberPermissions from 'graphql-queries/mutations/workspaceMembers/updateMemberPermissions';
import updateMemberChannelPermissions from 'graphql-queries/mutations/workspaceMembers/updateMemberChannelPermissions';
import transferWorkspaceOwnership from 'graphql-queries/mutations/workspaceMembers/transferWorkspaceOwnership';
import { useMutation, useQuery } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import { colors } from '@matterapp/matter-theme';
import getMemberById from 'graphql-queries/queries/workspace/getMemberById';
import { 
  monthsMap, 
  getDaysInMonthList, 
  getMemberBirthdayMonthAndDay,
  birthdayHasChanged,
  MEMBERS_DROPDOWN_OWNER_ITEM,
  MEMBERS_DROPDOWN_ADMIN_ITEM,
  MEMBERS_DROPDOWN_MEMBER_ITEM,
  MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM,
  getRoleValueFromProps
} from './helpers';
import TransferOwnershipModal from './TransferOwnerhipModal';
import { 
  StyledIcon,
  SubHeader,
  InputWrapper,
  LineWrapper,
  MemberSettingWrapper,
  MemberInfo,
  BirthdayWrapper,
  RadioList,
  StyledInput,
  ClearButton
} from './styles';

export default function EditMembersModal(props) {
  const { workspace, isOpen, onClose, memberId, isAdminSettings, isEnterprise, tenant } = props;
  const { teamsWorkspace, id: workspaceId } = workspace;
  const { rewardsSettings } = tenant;
  const { isRewardsActive } = rewardsSettings || {};
  const [canSave, updateCanSave] = useState(false);
  const [coinsHasError, setCoinsHasError] = useState(false);
  const [hiredOn, setHiredOn] = useState('');
  const [totalCoins, setTotalCoins] = useState(0);
  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [role, setRole] = useState('');
  const [isTransferringModalOpen, setIsTransferringModalOpen] = useState(false);
  const [isTransferConfirmed, setIsTransferConfirmed] = useState(false);
  const [updateMemberPropertiesMutation, { loading: savingSettings, data: savedMember }] = useMutation(updateMemberProperties);
  const [updateMemberPermissionsMutation] = useMutation(updateMemberPermissions);
  const [updateMemberChannelPermissionsMutation] = useMutation(updateMemberChannelPermissions);
  const [transferOwnershipMutation, { loading: savingTransferOwnership, data: transferData, error: transferError }] = useMutation(transferWorkspaceOwnership);
  const infoText = `Populated from ${teamsWorkspace ? 'Teams' : 'Slack'}. Updates appear within 24 hours.`;
  const isChannelPermissions = isEnterprise ? !isAdminSettings : false;

  const { data, loading, refetch } = useQuery(getMemberById, {
    variables: {
      workspaceId,
      id: memberId
    },
    fetchPolicy: 'network-only',
    skip: !memberId
  });

  const { workspaceMember: member } = data || {};
  const hasBirthdayChanged = birthdayHasChanged(member, birthMonth, birthDay);
  const roleChanged = role !== getRoleValueFromProps(member, workspaceId, isChannelPermissions);
  let hasChanged = member?.hiredOn !== hiredOn || hasBirthdayChanged || roleChanged;
  const { isSelf } = member || {};

  if (isRewardsActive) {
    hasChanged = hasChanged || member?.totalCoins !== totalCoins;
  }
  
  useEffect(() => {
    if (hasChanged) {
      if (roleChanged && role === MEMBERS_DROPDOWN_OWNER_ITEM.value && !isTransferConfirmed) {
        setIsTransferringModalOpen(true);
      } else if (!coinsHasError) {
        updateCanSave(true);
      } else {
        updateCanSave(false);
      }
    } else {
      updateCanSave(false);
    }
  }, [hiredOn, birthDay, birthMonth, role, isTransferConfirmed, totalCoins, coinsHasError]);

  useEffect(() => {
    if (member) {
      updateCanSave(false);
      setHiredOn(member.hiredOn);
      const [month, day] = getMemberBirthdayMonthAndDay(member);

      setBirthDay(day);
      setBirthMonth(month);
      setRole(getRoleValueFromProps(member, workspaceId, isChannelPermissions));
      setIsTransferConfirmed(false);
      setTotalCoins(member.totalCoins);
    }
  }, [member]);

  useEffect(() => {
    if (!savingSettings && savedMember) {
      Toast.success('You successfully updated member\'s settings');

      onClose();
    }
  }, [savingSettings, savedMember]);

  useEffect(() => {
    if (!savingTransferOwnership && transferData && !transferError) {
      Toast.success('You’ve successfully transferred ownership of this channel');

      onClose();
    } else if (transferError) {
      Toast.error(transferError.message);
      onClose();
    }
  }, [savingTransferOwnership, transferData, transferError]);

  if (isOpen && (loading || !member)) {
    return (
      <Loader />
    );
  }

  const showCelebrations = isEnterprise ? !isAdminSettings : true;

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="large"
      overflow="visible"
    >
      <Modal.Header
        header="Edit Member"
        right={<Modal.Buttons.CloseIcon onClick={onClose} />}
      />
      <Modal.Body scrollable={false}>
        <MemberSettingWrapper>
          {showCelebrations && (
            <>
              <LineWrapper>
                <MemberInfo>
                  Full Name
                </MemberInfo>
                <Tooltip
                  dark
                  content={infoText}
                  style={{ marginLeft: '4px' }}
                >
                  <StyledIcon 
                    name="infoCircle" 
                    fill={colors.blacks[30]}
                  />
                </Tooltip>
                <InputWrapper>
                  <Input
                    type="text"
                    disabled
                    value={member?.person?.fullName} 
                  />
                </InputWrapper>
              </LineWrapper>
              <LineWrapper>
                <MemberInfo>
                  Email
                </MemberInfo>
                <Tooltip
                  dark
                  content={infoText}
                  style={{ marginLeft: '4px' }}
                >
                  <StyledIcon 
                    name="infoCircle" 
                    fill={colors.blacks[30]}
                  />
                </Tooltip>
                <InputWrapper>
                  <Input
                    type="text"
                    disabled
                    value={member?.email} 
                  />
                </InputWrapper>
              </LineWrapper>
              <LineWrapper>
                <MemberInfo>
                  Hired on
                </MemberInfo>
                <InputWrapper>
                  <StyledInput
                    type="date"
                    value={hiredOn} 
                    id="hired_picker"
                    onClick={() => {
                      const el = document.getElementById('hired_picker');
                      el.showPicker();
                    }}
                    onChange={(e) => {
                      setHiredOn(e.target.value);
                    }}
                  />
                </InputWrapper>
                {hiredOn ? (
                  <ClearButton onClick={() => setHiredOn('')}>
                    X
                  </ClearButton>
                ) : null}
              </LineWrapper>
              <LineWrapper>
                <MemberInfo>
                  Date of Birth
                </MemberInfo>
                <BirthdayWrapper>
                  <Dropdown
                    value={birthMonth}
                    items={monthsMap}
                    onChange={(_, { value }) => {
                      setBirthMonth(value);
                    }}
                    placeholder="Month"
                    size={Dropdown.sizes.M}
                    showSelectedItemInMenu
                  />
                  <Dropdown
                    value={birthDay}
                    items={getDaysInMonthList(birthMonth)}
                    onChange={(_, { value }) => {
                      setBirthDay(value);
                    }}
                    placeholder="Day"
                    size={Dropdown.sizes.M}
                    showSelectedItemInMenu
                  />
                </BirthdayWrapper>
                {birthMonth && birthDay ? (
                  <ClearButton onClick={() => {
                    setBirthMonth('');
                    setBirthDay('');
                  }}>
                    X
                  </ClearButton>
                ) : null}
              </LineWrapper>
              {isRewardsActive ? (
                <LineWrapper>
                  <MemberInfo>
                    Coin Balance
                  </MemberInfo>
                  <Tooltip
                    dark
                    style={{ marginLeft: '4px' }}
                    content='How many coins a user has available to redeem.'
                  >
                    <StyledIcon 
                      name="infoCircle" 
                      fill={colors.blacks[30]}
                    />
                  </Tooltip>
                  <InputWrapper>
                    <StyledInput
                      type="number"
                      value={totalCoins} 
                      id="coin_balance"
                      onChange={(e) => {
                        setTotalCoins(e.target.value);

                        if (parseInt(e.target.value) < 0) {
                          setCoinsHasError(true);
                        } else {
                          setCoinsHasError(false);
                        }
                      }}
                      showErrorMessageBelow
                      errorMessage={coinsHasError ? 'Must be greater than 0' : null}
                    />
                  </InputWrapper>
                  {totalCoins ? (
                    <ClearButton onClick={() => setTotalCoins(0)}>
                      X
                    </ClearButton>
                  ) : null}
                </LineWrapper>
              ) : null}
            </>
          )}
          <SubHeader>Member Permission</SubHeader>
          <RadioList
            showRadioAfterLabel
            disabled={isSelf || (!roleChanged && role === MEMBERS_DROPDOWN_OWNER_ITEM.value)}
            onChange={(_, { value }) => {
              setRole(value);
            }}
            values={isChannelPermissions 
              ? [
                MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM,
                MEMBERS_DROPDOWN_MEMBER_ITEM
              ] 
              : [  
                MEMBERS_DROPDOWN_OWNER_ITEM,
                MEMBERS_DROPDOWN_ADMIN_ITEM,
                MEMBERS_DROPDOWN_MEMBER_ITEM
              ]}
            value={role}
          />
        </MemberSettingWrapper>
      </Modal.Body>
      <Modal.Footer.WithCancelSave
        cancelLabel="Close"
        canClickSave={canSave}
        onClickCancel={onClose}
        onClickSave={async () => {
          if (isTransferConfirmed) {
            await transferOwnershipMutation({
              variables: {
                memberId: member.id,
                tenantId: tenant.id
              }
            });
          }

          await updateMemberPropertiesMutation({
            variables: {
              personId: member.person.id,
              tenantId: tenant.id,
              hiredOn: hiredOn || null,
              birthday: birthMonth && birthDay ? `${birthMonth} ${birthDay}` : null,
              totalCoins: isRewardsActive ? parseInt(totalCoins) : null
            }
          });

          if (roleChanged) {
            const { id: memberId } = member;

            if (isChannelPermissions) {
              const isChannelAdmin = role === MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM.value;

              await updateMemberChannelPermissionsMutation({ 
                variables: { memberId, isChannelAdmin, workspaceId: workspace.id } 
              });
            } else {
              const isAdmin = role === MEMBERS_DROPDOWN_ADMIN_ITEM.value;

              await updateMemberPermissionsMutation({ 
                variables: { memberId, isAdmin, workspaceId: workspace.id } 
              });
            }
          }

          refetch({
            workspaceId,
            id: memberId
          });
        }}
        sendIsLoading={savingSettings}
      />
      <TransferOwnershipModal 
        isOpen={isTransferringModalOpen}
        onClickCancel={() => {
          setIsTransferringModalOpen(false);
          setRole(getRoleValueFromProps(member, workspaceId, isChannelPermissions));
        }}
        onClickConfirm={() => {
          setIsTransferConfirmed(true);
          setIsTransferringModalOpen(false);
        }}
        member={member}
      />
    </Modal>
  );
}

EditMembersModal.propTypes = {
  workspace: PropTypes.object,
  memberId: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool
};
