import React from 'react';
import Animation from '../Animation';
import styled from 'styled-components';
import gsap, { Linear } from 'gsap';
import { SVG } from '../../../components';
import theme from '@matterapp/matter-theme';

const StampGroup = styled.g``;
const Stamp = styled.path`
  clip-path: url(#Stamp_Mask_1_);
  fill: ${() => theme.colors.reds[60]};
`;

const Stamper = styled.g``;
const StamperLight = styled.path`
  fill: ${() => theme.colors.blue};
`;
const StamperDark = styled.path`
  fill: ${() => theme.colors.purple};
`;

const Star = styled.path`
  fill: ${() => theme.colors.white};
`;

const Circle = styled.path`
  opacity: 0.3;
  fill: ${() => theme.colors.white};
  enable-background: new;
`;

const Plus = styled(Circle)``;

const DEFAULT_HEIGHT = 180;
const DEFAULT_WIDTH = 250;

export default class KudosAmazingJob extends Animation {
  static defaultProps = {
    height: DEFAULT_HEIGHT - 16,
    width: DEFAULT_WIDTH,
  };

  /**
   * Displays the circle and stars after the stamper is slammed.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  displaySlam = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetCircleProps = {
      x: 0,
      y: 0,
      opacity: 0.3,
      transformOrigin: 'center',
    };
    const resetStarProps = { ...resetCircleProps, opacity: 1, rotation: 0 };
    const getCircleProps = (x, y) => ({ x, y, opacity: 0 });
    const getStarProps = (x, y) => ({ x, y, opacity: 0, rotation: 360 });
    const getResetCircleProps = (duration) => ({
      ...resetCircleProps,
      duration,
    });

    tl.to(this.starTopRef, getStarProps(-2, 40));
    tl.to(this.starMidTopRef, getStarProps(-5, 20));
    tl.to(this.starMidBottomRef, getStarProps(-15, -1));
    tl.to(this.starBottomRef, getStarProps(-30, -3));
    tl.to(this.starRightRef, getStarProps(-40, 5));
    tl.to(this.starRightMiddleRef, getStarProps(-20, 3));
    tl.to(this.circleBottomRightRef, getCircleProps(-60, -3));
    tl.to(this.circleTopRef, getCircleProps(0, 40));
    tl.to(this.circleLeftRef, getCircleProps(30, 20));
    tl.to(this.circleFullTopRef, getCircleProps(30, 20));
    tl.to(this.circleFullLeftRef, getCircleProps(30, 0));
    tl.to(this.circleFullBottomLeftRef, getCircleProps(40, -10));
    tl.to(this.circleFullBottomRightRef, getCircleProps(-60, -30));
    tl.to(this.circleFullTopRightRef, getCircleProps(-60, 30));
    tl.to(this.circleFullRightRef, getCircleProps(-50, 10));

    if (!initialize) {
      tl.to(this.starTopRef, { ...resetStarProps, duration: 0.4 }, '<0.3');
      tl.to(this.starMidTopRef, { ...resetStarProps, duration: 0.3 }, '<');
      tl.to(this.starMidBottomRef, { ...resetStarProps, duration: 0.3 }, '<');
      tl.to(this.starBottomRef, { ...resetStarProps, duration: 0.5 }, '<');
      tl.to(this.starRightRef, { ...resetStarProps, duration: 0.3 }, '<');
      tl.to(this.starRightMiddleRef, { ...resetStarProps, duration: 0.2 }, '<');
      tl.to(this.circleBottomRightRef, getResetCircleProps(0.4), '<');
      tl.to(this.circleTopRef, getResetCircleProps(0.5), '<');
      tl.to(this.circleLeftRef, getResetCircleProps(0.5), '<');
      tl.to(this.circleFullTopRef, getResetCircleProps(0.2), '<');
      tl.to(this.circleFullLeftRef, getResetCircleProps(0.4), '<');
      tl.to(this.circleFullBottomLeftRef, getResetCircleProps(0.3), '<');
      tl.to(this.circleFullBottomRightRef, getResetCircleProps(0.4), '<');
      tl.to(this.circleFullTopRightRef, getResetCircleProps(0.4), '<');
      tl.to(this.circleFullRightRef, getResetCircleProps(0.3), '<');
    }
    return tl;
  };

  /**
   * Moves the stamper and displays the stamp after the stamper stamps.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  moveStamper = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const getStamperProps = (duration, x, y, rotation = 0, scale = 1) => {
      return {
        duration,
        x,
        y,
        rotation,
        scale,
        ease: Linear.easeNone,
      };
    };

    tl.to(this.stampRef, { opacity: 0, scale: 0.8 });

    if (!initialize) {
      tl.to(this.stamperRef, getStamperProps(0.1, -25, -10, 0), '<');
      tl.to(this.stamperRef, getStamperProps(0.08, -55, 0, -10, 1.1), '>');
      tl.to(this.stamperRef, getStamperProps(0.1, -126, 37, -20, 1.24), '>');
      tl.to(this.stampRef, { opacity: 1, scale: 1, duration: 0.2 }, '<0.1');
      tl.to(this.stamperRef, getStamperProps(0.1, -55, 0, 0), '>');
      tl.to(this.stamperRef, getStamperProps(0.2, 0, 0), '>');
    }
  };

  /**
   * Spins the plus in the background after stamper stamps.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  spinPlus = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });

    tl.to(this.plusRef, {
      rotation: 360,
      opacity: 0,
      x: 20,
      y: 40,
      transformOrigin: 'center',
    });

    if (!initialize) {
      tl.to(this.plusRef, {
        rotation: 0,
        opacity: 1,
        x: 0,
        y: 0,
        duration: 0.3,
      });
    }
    return tl;
  };

  playAnimation = ({ initialize, isMounting } = {}) => {
    if (this._isMounted || isMounting) {
      const tl = gsap.timeline({ defaults: { duration: 0 } });
      tl.add(this.moveStamper({ initialize }), '<');
      tl.add(this.displaySlam({ initialize }), '<');
      tl.add(this.spinPlus({ initialize }), '<0.3');
      this.tl = tl;
      return tl;
    }
  };

  resetAnimation = () => {
    return this.playAnimation({ initialize: true });
  };

  render() {
    const { height, width, ...otherProps } = this.props;

    return (
      <SVG
        {...otherProps}
        height={height}
        width={width}
        viewBox={`0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`}
      >
        <Star
          ref={(ref) => (this.starRightMiddleRef = ref)}
          d='M174,79.9l4.1,4.7l6.2,0.2l-3.2,5.4l1.7,6l-6.1-1.4l-5.2,3.5l-0.6-6.2
            l-4.9-3.9l5.7-2.5L174,79.9z'
        />
        <Star
          ref={(ref) => (this.starRightRef = ref)}
          d='M192.2,78.4l4.2-0.9l2.3-3.6l2.1,3.7l4.2,1l-2.9,3.2l0.3,4.3l-3.9-1.8l-4,1.6
            l0.5-4.2L192.2,78.4z'
        />
        <Circle
          ref={(ref) => (this.circleFullTopRightRef = ref)}
          d='M202.4,55c3.2,0,5.8-2.6,5.8-5.8
            c0-3.2-2.6-5.8-5.8-5.8c-3.2,0-5.8,2.6-5.8,5.8C196.6,52.3,199.2,55,202.4,55z'
        />
        <Circle
          ref={(ref) => (this.circleFullRightRef = ref)}
          d='M217.9,101.7c3.2,0,5.8-2.6,5.8-5.8
            c0-3.2-2.6-5.8-5.8-5.8c-3.2,0-5.8,2.6-5.8,5.8C212.1,99.1,214.7,101.7,217.9,101.7z'
        />
        <Star
          ref={(ref) => (this.starMidTopRef = ref)}
          d='M131.5,43.3l6.1,1.4l5.2-3.4l0.5,6.2l4.9,3.9l-5.8,2.4l-2.2,5.8l-4.1-4.7l-6.2-0.3
            l3.2-5.3L131.5,43.3z'
        />
        <Star
          ref={(ref) => (this.starMidBottomRef = ref)}
          d='M174,138.4l4.1,4.7l6.2,0.2l-3.2,5.4l1.7,6l-6.1-1.4l-5.2,3.5l-0.6-6.2l-4.9-3.9
            l5.7-2.5L174,138.4z'
        />
        <Star
          ref={(ref) => (this.starTopRef = ref)}
          d='M143,20.4l3.2,2.8l4.3-0.4l-1.6,4l1.7,3.9l-4.3-0.4l-3.2,2.9l-1-4.2l-3.7-2.2l3.6-2.2L143,20.4z'
        />
        <Star
          ref={(ref) => (this.starBottomRef = ref)}
          d='M194.6,156.7l4.2-0.9l2.3-3.6l2.1,3.7l4.2,1l-2.9,3.2l0.3,4.3l-3.9-1.8l-4,1.6l0.5-4.2
            L194.6,156.7z'
        />
        <Circle
          ref={(ref) => (this.circleTopRef = ref)}
          d='M106,48c-5.8,0-10.5-4.7-10.5-10.5s4.7-10.5,10.5-10.5c5.8,0,10.5,4.7,10.5,10.5
            S111.8,48,106,48z M106,31.9c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6S109.1,31.9,106,31.9z'
        />
        <Circle
          ref={(ref) => (this.circleBottomRightRef = ref)}
          d='M240,156.9c-5.8,0-10.5-4.7-10.5-10.5S234.3,136,240,136s10.5,4.7,10.5,10.5
            S245.8,156.9,240,156.9z M240,140.8c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6S243.1,140.8,240,140.8z'
        />
        <Circle
          ref={(ref) => (this.circleFullBottomRightRef = ref)}
          d='M228.7,179c3.2,0,5.8-2.6,5.8-5.8c0-3.2-2.6-5.8-5.8-5.8c-3.2,0-5.8,2.6-5.8,5.8
            C222.9,176.3,225.5,179,228.7,179z'
        />
        <Circle
          ref={(ref) => (this.circleFullTopRef = ref)}
          d='M72.7,61c3.2,0,5.8-2.6,5.8-5.8c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8
            C66.9,58.3,69.5,61,72.7,61z'
        />
        <Circle
          ref={(ref) => {
            this.circleFullBottomLeftRef = ref;
          }}
          d='M55,167.5c3.2,0,5.8-2.6,5.8-5.8c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8
            C49.2,164.9,51.8,167.5,55,167.5z'
        />
        <Circle
          ref={(ref) => {
            this.circleLeftRef = ref;
          }}
          d='M47.8,87.1c-5.8,0-10.5-4.7-10.5-10.5c0-5.8,4.7-10.5,10.5-10.5s10.5,4.7,10.5,10.5
            C58.2,82.4,53.5,87.1,47.8,87.1z M47.8,71c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6
            C53.4,73.5,50.9,71,47.8,71z'
        />
        <g ref={(ref) => (this.plusRef = ref)}>
          <Plus
            d='M57,31.9c-1.3,0-2.4-1.1-2.4-2.4V14.8c0-1.3,1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4v14.7
              C59.4,30.8,58.3,31.9,57,31.9z'
          />
          <Plus d='M64.3,24.6H49.6c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4h14.7c1.3,0,2.4,1.1,2.4,2.4S65.6,24.6,64.3,24.6z' />
        </g>
        <Circle
          ref={(ref) => (this.circleFullLeftRef = ref)}
          d='M6.8,126.4c3.2,0,5.8-2.6,5.8-5.8s-2.6-5.8-5.8-5.8c-3.2,0-5.8,2.6-5.8,5.8
            S3.6,126.4,6.8,126.4z'
        />
        <StampGroup>
          <defs>
            <path
              id='Stamp_Mask'
              d='M143.1,85.1l3.5-9.6H66.3v99.9h99.9v-45.8C147.3,118.5,137.5,100.6,143.1,85.1z'
            />
          </defs>
          <clipPath id='Stamp_Mask_1_'>
            <use xlinkHref='#Stamp_Mask' style={{ overflow: 'visible' }} />
          </clipPath>
          <Stamp
            ref={(ref) => (this.stampRef = ref)}
            d='M160.4,125.4c0.2-0.3,0.5-0.6,0.7-0.8c1.2-1.3,2.7-3.1,2.5-5.5c-0.2-2.4-2.2-3.8-3.6-4.9
              c-0.2-0.2-0.6-0.4-0.8-0.7c0.1-0.3,0.3-0.7,0.5-1c0.8-1.6,1.8-3.7,1-5.9c-0.9-2.2-3.1-3.1-4.7-3.8c-0.3-0.1-0.7-0.3-1-0.4
              c0.1-0.3,0.1-0.8,0.2-1.1c0.3-1.7,0.8-4-0.6-6c-1.4-1.9-3.8-2.2-5.5-2.4c-0.3,0-0.7-0.1-1.1-0.1c0-0.3-0.1-0.8-0.1-1.1
              c-0.1-1.7-0.3-4.1-2.1-5.6c-1.9-1.5-4.2-1.2-5.9-0.9c-0.3,0.1-0.7,0.1-1.1,0.1c-0.1-0.3-0.3-0.7-0.4-1c-0.6-1.7-1.3-3.9-3.5-4.9
              c-2.2-1-4.4,0-6,0.7c-0.3,0.1-0.7,0.3-1,0.4c-0.2-0.3-0.4-0.6-0.6-0.9c-1-1.5-2.3-3.5-4.7-3.8c-2.3-0.4-4.2,1.1-5.6,2.2
              c-0.3,0.2-0.6,0.5-0.9,0.7c-0.3-0.2-0.6-0.5-0.8-0.7c-1.3-1.2-3.1-2.7-5.5-2.5c-2.4,0.2-3.8,2.2-4.9,3.6c-0.2,0.2-0.4,0.6-0.6,0.8
              c-0.3-0.1-0.7-0.3-1-0.5c-1.6-0.8-3.7-1.8-5.9-1c-2.2,0.9-3.1,3.1-3.8,4.7c-0.1,0.3-0.3,0.7-0.4,1c-0.3-0.1-0.8-0.1-1.1-0.2
              c-1.7-0.3-4-0.8-6,0.6c-1.9,1.4-2.2,3.8-2.4,5.5c0,0.3-0.1,0.7-0.1,1.1c-0.3,0-0.8,0.1-1.1,0.1c-1.7,0.1-4.1,0.3-5.6,2.1
              s-1.2,4.2-0.9,5.9c0.1,0.3,0.1,0.7,0.1,1.1c-0.3,0.1-0.7,0.3-1,0.4c-1.7,0.6-3.9,1.3-4.9,3.5c-1,2.2,0,4.4,0.7,6
              c0.1,0.3,0.3,0.7,0.4,1c-0.3,0.2-0.6,0.4-0.9,0.6c-1.5,1-3.4,2.3-3.8,4.7c-0.4,2.3,1.1,4.2,2.2,5.6c0.2,0.3,0.5,0.6,0.7,0.9
              c-0.2,0.3-0.5,0.6-0.7,0.8c-1.2,1.3-2.7,3.1-2.5,5.5c0.2,2.4,2.2,3.8,3.6,4.9c0.2,0.2,0.6,0.4,0.8,0.7c-0.1,0.3-0.3,0.7-0.5,1
              c-0.8,1.6-1.8,3.7-1,5.9c0.9,2.2,3.1,3.1,4.7,3.8c0.3,0.1,0.7,0.3,1,0.4c0,0.3-0.1,0.8-0.2,1.1c-0.3,1.7-0.8,4,0.6,6
              c1.4,1.9,3.8,2.2,5.5,2.4c0.3,0,0.7,0.1,1.1,0.1c0,0.3,0.1,0.8,0.1,1.1c0.1,1.7,0.3,4.1,2.1,5.6s4.2,1.2,5.9,0.9
              c0.3,0,0.7-0.1,1.1-0.1c0.1,0.3,0.3,0.7,0.4,1c0.6,1.7,1.3,3.9,3.5,4.9c2.2,1,4.4,0,6-0.7c0.3-0.1,0.7-0.3,1-0.4
              c0.2,0.3,0.4,0.6,0.6,0.9c1,1.5,2.3,3.4,4.7,3.8c2.3,0.4,4.2-1.1,5.6-2.2c0.3-0.2,0.6-0.5,0.9-0.7c0.3,0.2,0.6,0.5,0.8,0.7
              c1.3,1.2,3.1,2.7,5.5,2.5c2.4-0.2,3.8-2.2,4.9-3.6c0.2-0.2,0.4-0.6,0.7-0.8c0.3,0.1,0.7,0.3,1,0.5c1.6,0.8,3.7,1.8,5.9,1
              c2.2-0.9,3.1-3.1,3.8-4.7c0.1-0.3,0.3-0.7,0.4-1c0.3,0,0.8,0.1,1.1,0.2c1.7,0.3,4,0.8,6-0.6c1.9-1.4,2.2-3.8,2.4-5.5
              c0-0.3,0.1-0.7,0.1-1.1c0.3,0,0.8-0.1,1.1-0.1c1.7-0.1,4.1-0.3,5.6-2.1c1.5-1.9,1.2-4.2,0.9-5.9c-0.1-0.3-0.1-0.7-0.1-1.1
              c0.3-0.1,0.7-0.3,1-0.4c1.7-0.6,3.9-1.3,4.9-3.5c1-2.2,0-4.4-0.6-6c-0.1-0.3-0.3-0.7-0.4-1c0.3-0.2,0.6-0.4,0.9-0.6
              c1.5-1,3.4-2.3,3.8-4.7c0.4-2.3-1.1-4.2-2.2-5.6C160.9,126.1,160.6,125.7,160.4,125.4z M119.3,166.1c-23.1,2.4-43.8-14.3-46.2-37.4
              c-2.4-23.1,14.3-43.8,37.4-46.2c23.1-2.4,43.8,14.3,46.2,37.4C159.2,142.9,142.4,163.6,119.3,166.1z M128.1,115.7
              c1.4-0.5,2-2.1,1.5-3.4s-2.1-2-3.4-1.5c-1.4,0.5-2,2-1.5,3.4C125.2,115.5,126.8,116.2,128.1,115.7z M107.9,139.3l2.3-0.9l17.6-6.8
              l2.3-0.9L127,117l-5.8,9.1l-8-6.5l-1.6,10.2l-10.4-2.8L107.9,139.3z M112.7,118.4c1.4-0.5,2-2.1,1.5-3.4c-0.5-1.4-2-2-3.4-1.5
              c-1.4,0.5-2,2-1.5,3.4C109.8,118.2,111.3,118.9,112.7,118.4z M141.4,101.2c-9.1-10.5-24-14.9-37.8-10.1l16.7,13.2L141.4,101.2z
              M107.1,158.4c6.6,1.5,13.7,1.2,20.4-1.4c6.8-2.6,12.3-7.1,16.1-12.6l-21.3-0.8L107.1,158.4z M80,127.3c0.3,3.2,1,6.3,2.2,9.5
              c4,10.5,12.5,17.9,22.4,21l-5.8-20.5L80,127.3z M101.2,92c-13.4,5.7-21.6,18.9-21.3,32.8l17.7-11.9L101.2,92z M143,103.2l-7.3,20
              l9.4,19.1c5.3-8.9,6.7-20.1,2.7-30.6C146.5,108.5,144.9,105.7,143,103.2z M97.5,121.8c-1.4,0.5-2,2.1-1.5,3.4c0.5,1.4,2,2,3.4,1.5
              c1.4-0.5,2-2.1,1.5-3.4C100.4,121.9,98.9,121.2,97.5,121.8z'
          />
        </StampGroup>
        <Stamper ref={(ref) => (this.stamperRef = ref)}>
          <StamperDark
            d='M230.2,98c-5.1-9.8-15.8-19-29.9-24.1c-14-5.1-28.1-5-38.3-0.8l-8.1-2.9l-6.1,16.6
              c-5.7,15.6,8.6,35.2,31.9,43.6s46.8,2.7,52.5-12.9l6.1-16.7L230.2,98z'
          />
          <StamperLight
            d='M238.3,100.9c5.7-15.6-8.6-35.2-31.9-43.6c-23.3-8.5-46.8-2.7-52.5,12.9c-5.7,15.6,8.6,35.2,31.9,43.6
              S232.6,116.5,238.3,100.9z'
          />
          <StamperDark
            d='M215.6,65.4c-9.1,7.8-7.2,18.4-6.8,20.1c0.3,1.3,0.3,2.6-0.1,3.8c-0.7,1.9-2.3,3.3-4.5,4.1l34.7-5.1
              c-2.2-9.4-9.9-18.9-21.2-25.6C217.4,63.4,216.8,64.3,215.6,65.4z'
          />
          <StamperDark d='M242.2,34.4l-8.7,23.9c-3.3,9-16.8,12.3-30.2,7.4c-13.3-4.8-21.4-15.9-18.4-24.8l0,0l8.8-24.1L242.2,34.4z' />
          <StamperLight
            d='M242.2,34.4c3.3-9-4.9-20.2-18.3-25.1c-13.4-4.9-26.9-1.5-30.2,7.4c-3.3,9,4.9,20.2,18.3,25.1
              C225.4,46.7,238.9,43.3,242.2,34.4z'
          />
          <StamperDark
            d='M199.6,95.9c-2.2,0-4.5-0.4-6.8-1.2c-3.6-1.3-6.6-3.5-8.6-6.2c-2.1-2.9-2.7-6-1.7-8.7c0.6-1.5,1.5-2.8,2.9-3.8
              c0.8-0.6,5.2-4.2,6.7-10.5c0.2-0.9,1.2-1.5,2.1-1.3c0.9,0.2,1.5,1.2,1.3,2.1c-1.8,7.3-7,11.6-8,12.4c-0.9,0.6-1.4,1.4-1.8,2.2
              c-0.6,1.6-0.1,3.6,1.2,5.5c1.5,2.1,4,3.9,6.9,4.9c2.2,0.8,4.4,1.1,6.5,1c1-0.1,1.8,0.7,1.9,1.6c0.1,1-0.7,1.8-1.6,1.9
              C200.4,95.8,200,95.8,199.6,95.9z'
          />
        </Stamper>
      </SVG>
    );
  }
}
