import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.47077C1 4.65848 1.6584 4 2.47059 4H29.5294C30.3416 4 31 4.65848 31 5.47077V25.3261C31 26.1384 30.3416 26.7969 29.5294 26.7969H2.47059C1.65841 26.7969 1 26.1384 1 25.3261V5.47077Z"
      fill="#F2F2F2"
    />
    <path
      d="M27.4216 26.7967L16 17.8124L27.4216 9.49072V26.7967Z"
      fill="#D8D8D9"
    />
    <path
      d="M16.0246 17.8252L4.57843 9.52817V26.7969H2.47059C1.65841 26.7969 1 26.1384 1 25.3261V6.54933H1.00206L1.00866 5.31012C1.0887 4.57341 1.7127 4 2.47059 4H3.31005L16.0246 13.273L28.804 4H29.5294C30.3416 4 31 4.65848 31 5.47077V25.3261C31 26.1384 30.3416 26.7969 29.5294 26.7969H27.4216V9.49943L16.0246 17.8252Z"
      fill="#F14336"
    />
    <path d="M1 6.79443L4.52941 9.46633L1 8.77997V6.79443Z" fill="#D32E2A" />
  </svg>
);

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Icon.defaultProps = {
  width: 32,
  height: 32,
};

export default Icon;
