export const LATEST_PERFORMANCE_RATING_OPTIONS = [
  {
    article: 'an',
    label: 'Incredible',
    value: '4',
    emoji: '🔥',
  },
  {
    article: 'an',
    label: 'Amazing',
    value: '3',
    emoji: '🙌',
  },
  {
    article: 'a',
    label: 'Great',
    value: '2',
    emoji: '😀',
  },
  {
    article: 'a',
    label: 'Good',
    value: '1',
    emoji: '🙂',
  },
  {
    article: 'a',
    label: 'Meh',
    value: '0',
    emoji: '😬',
  },
];

const LATEST_PERFORMANCE_RATINGS_LOOKUP_TABLE = {};

LATEST_PERFORMANCE_RATING_OPTIONS.forEach((performanceRating) => {
  const processedPerformanceRating = {
    ...performanceRating,
    yellowCardLabel: performanceRating.label.toLowerCase(),
  };

  LATEST_PERFORMANCE_RATINGS_LOOKUP_TABLE[
    performanceRating.value
  ] = processedPerformanceRating;
});

export const getPerformanceRatingDetails = ({ rating }) => {
  const parsedRating = `${rating}`;
  return LATEST_PERFORMANCE_RATINGS_LOOKUP_TABLE[parsedRating];
};
