export const overallRating = {
  1: 'Poor',
  2: 'Fair',
  3: 'Good',
  4: 'Great',
  5: 'Amazing',
};

/**
 * The new rater scale is from 1 -5 while the old rater is 0 - 10.
 * New ratings are on a 1 - 10 scale (2, 4, 6, 8, 10), so getting
 * the value is dividing the rating by 2 and getting the ceiling value.
 * Ratings of 0 will automatically be 1.
 * @param { Number } rating: The rating to convert.
 * @returns { Number } The converted rating to display.
 */
export const getRating = (rating) => {
  if (rating == null) {
    return rating;
  }
  return Math.ceil(rating / 2) || 1;
};

/**
 * New ratings are on a 1 - 5 scale. To ensure that they are
 * compatible with old ratings, the values will be doubled.
 * @param { Number } rating: The rating to convert.
 * @returns { Number } The converted rating to set.
 */
export const setRating = (rating = 0) => {
  return rating * 2;
};
