import React, { useContext } from 'react';
import { 
  MobileBreadCrumb
} from './styles';
import UserContext from 'context/UserContext/UserContext';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';
import { 
  Typography
} from '@mui/material';
import LeftChevron from './icons/leftChevron';

export default function SettingsNavigation({ currentLabel, settingsRedirectUrl, isChannelSetting }) {  
  const { currentWorkspaceId: workspaceId } = useContext(UserContext);
  const settingsUrl = settingsRedirectUrl || Resources.workspaceSettingsMain.path({ workspaceId });

  const settingsLabel = isChannelSetting ? 'Channel Settings' : 'Settings';
  const navigate = useNavigate();

  return (
    <>
      <MobileBreadCrumb 
        onClick={() => {
          navigate(settingsUrl);
        }}
      >
        <LeftChevron />
        <Typography variant='body1' sx={{ ml: 2 }}><b>Back</b></Typography>
      </MobileBreadCrumb>
      <BreadCrumbs
        hideOnMobile
        crumps={[
          {
            label: settingsLabel,
            href: settingsUrl
          },
          {
            label: currentLabel,
            href: null
          }
        ]}
      />
    </>
  );
}
