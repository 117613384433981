import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2c8 0 8 3.765 8 5 0 1.932-.76 4.112-1.964 5.943-1.804-1.24-3.859-1.943-6.04-1.943-2.178 0-4.231.7-6.035 1.938C7.76 11.108 7 8.93 7 7c0-1.235 0-5 8-5zM7.386 14.215C5.898 12.002 5 9.349 5 7c0-5.245 4.475-7 10-7s10 1.755 10 7c0 2.352-.9 5.007-2.39 7.22 1.549 1.467 2.84 3.37 3.77 5.57l4.116-5.29 1.5 1-3.445 10.765A2.491 2.491 0 0 1 26.176 28h-22a2.073 2.073 0 0 1-2.065-2.275c.483-4.73 2.453-8.843 5.275-11.51zM15 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 15 7z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
