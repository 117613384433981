import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import withRouter from 'hocs/withRouter';
import withInvitationOwnerFromUuid from '../hocs/withInvitationOwnerFromUuid';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Form, Button, Flow } from '@matterapp/matter-ui';
import apolloClient from 'config/apolloClient';
import { Formik, Field } from 'formik';
import { validateEmail } from '../../../../form-validations';
import { Resources } from '@matterapp/routing';
import Toast from 'components/Toast/Toast';
import {
  EMAIL_LOGIN_CODE_IF_USER_EXISTS_MUTATION,
  EMAIL_SIGNUP_LOGIN_MUTATION,
  CURRENT_USER_STATUS_QUERY,
} from 'graphql-queries/queries';
import { handleTrackSignup } from 'libs/tracking/signup';
import { SIGNUP_MEANS } from 'app-consts';

const FormContainer = styled.div`
  & p {
    color: ${theme.colors.blacks[50]};
    text-align: left;
    margin-top: 24px;
    margin-bottom: 24px;
    line-height: 22px;
  }
  & input {
    margin-bottom: 12px;
  }
  // TODO: This button styling is a hack to make the new buttons
  // look like the old style. The entire contents of this form will
  // move into RFR in the next phase of this project, and this can
  // be removed
  & button.request-email-verification-button {
    width: 100%;
    font-weight: ${theme.fontWeight.medium};
  }
  & button.request-email-verification-button:not([disabled]) {
    color: #fff;
    border-color: transparent;
    background-color: ${theme.colors.greens[50]};
  }
`;

// Header gets lost under the sticky Matter logo
const FlowCardMod = styled(Flow.Card)`
  @media screen and (max-width: ${theme.breakpoints[3]}) {
    & h1 {
      padding-top: 64px;
    }
  }
`;

class RequestVerificationByEmailForm extends React.Component {
  static propTypes = {
    invitationUuid: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super();
    this.state = {
      isInFlight: false,
    };
  }

  render() {
    return (<Formik
      initialValues={{ email: '' }}
      validate={(values) => {
        if (validateEmail(values.email)) {
          return {email: true};
        }
        return null;
      }}
      onSubmit={async (values, {resetForm}) => {
        const { email } = values;
        const { history, invitationUuid } = this.props;
        let resp;
        try {
          this.setState({
            isInFlight: true
          });
          // If the e-mail is for an existing user, send that user a code
          resp = await apolloClient.mutate({
            mutation: EMAIL_LOGIN_CODE_IF_USER_EXISTS_MUTATION,
            variables: { email }
          });
          const exists = resp.data.emailLoginCodeIfUserExists;
          if (exists) {
            history.push({
              pathname: Resources.responseFlowSubmitVerification.path({invitationUuid: invitationUuid}),
              state: { email }
            });
          }
          // Otherwise create new, un-onboarded user, and go to first step of RFR
          // (If user did not exist, no login token is sent)
          else {
            resp = await apolloClient.mutate({
              mutation: EMAIL_SIGNUP_LOGIN_MUTATION,
              variables: { email }
            });
            const { isNewRecord, user } = resp.data.login;
            handleTrackSignup({
              isNewRecord,
              user,
              signupMeans: SIGNUP_MEANS.MANUAL_EMAIL_SIGNUP,
            });
            await apolloClient.query({
              query: CURRENT_USER_STATUS_QUERY,
              fetchPolicy: 'network-only',
            });
            history.push(Resources.responseFlowBeginFeedback.path({
              invitationUuid,
            }));
          }
        }
        catch (err) {
          this.setState({
            isInFlight: false
          });
          Toast.error('Something went wrong');
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isValid,
        dirty,
      }) => {
        const { isInFlight } = this.state;
        return (
          <FormContainer>
            <Form onSubmit={(e) => {
              if (dirty && isValid) {
                handleSubmit();
              }
              e.stopPropagation();
              e.preventDefault();
            }}>
              <Field
                fluid
                name="email"
                type="email"
                shortInline
                size="medium"
                placeholder="Enter Your Email"
                onChange={handleChange}
                value={values.email}
                component={Form.Input}
                disabled={isInFlight}
              />
              <Button.Simple
                disabled={!(isValid && dirty)}
                content={isInFlight ? '' : 'Continue'}
                className="request-email-verification-button"
                type="button"
                isLoading={isInFlight}
                onClick={handleSubmit}
                rcLabel="request-email-verification-button"
              />
            </Form>
          </FormContainer>
        );
      }}
    </Formik>);
  }
}

const RoutableRequestVerificationByEmailForm = compose(
  withRouter,
)(RequestVerificationByEmailForm);

const ResponseFlowRequestVerificationPage = function (props) {
  const { invitation } = props.invitationData;

  if (!invitation) {
    return null;
  }

  const { owner } = invitation;
  return (
    <Flow.Page renderHeader={() => <Flow.Header/>}>
      <FlowCardMod
        isHeaderHiddenOnMobile
        renderFlash={() => (
          <>
            <Flow.TextBlock
              heading={
                <>
                  {owner.fullName}<br/>would like your feedback
                </>
              }
            >
              {owner.firstName} is using Matter to gather feedback, and it should only take a few minutes.
              Get started by entering your email.
            </Flow.TextBlock>
          </>
        )}
      >
        <Flow.Container maxWidth={315}>
          <FormContainer>
            <RoutableRequestVerificationByEmailForm invitationUuid={props.invitationUuid} />
            <p>By entering your email, you provide your colleague with the opportunity to continue the conversation.</p>
          </FormContainer>
        </Flow.Container>
      </FlowCardMod>
    </Flow.Page>
  );
};

ResponseFlowRequestVerificationPage.propTypes = {
  invitationUuid: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        invitation: PropTypes.shape({})
      })
    })
  }),
  invitationData: PropTypes.shape({
    invitation: PropTypes.shape({
      owner: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        headline: PropTypes.string,
        isDeleted: PropTypes.bool,
      }).isRequired,
      giver: PropTypes.shape({
        isSelf: PropTypes.bool,
      }),
    }),
  }).isRequired,
};

export default compose(
  withRouter,
  withInvitationOwnerFromUuid
)(ResponseFlowRequestVerificationPage);


