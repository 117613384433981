import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const TEXTAREA_LINE_HEIGHT = 24;
const PADDING = 16;
const MIN_ROWS = 1;
const MAX_ROWS = 3;

const StyledTextArea = styled.textarea`
  flex-grow: 1;
  resize: none;
  padding: ${PADDING}px;
  -webkit-appearance: none; // remove the iOS forced stylings
  background: transparent;
  border: none;
  color: ${theme.colors.blacks[90]};
  font-weight: normal;
  line-height: ${TEXTAREA_LINE_HEIGHT}px;
  font-size: ${theme.fontSize.S};

  &::placeholder {
    color: rgba(79, 82, 177, 0.7);
  }
`;

class TextArea extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rows: MIN_ROWS,
    };
    this.textareaRef = React.createRef();
  }

  componentDidUpdate() {
    if (!this.textareaRef.current) {
      return;
    }

    if (!this.props.value) {
      this.setState({
        rows: MIN_ROWS,
      });
      return;
    }

    const previousRows = this.textareaRef.current.rows;
    this.textareaRef.current.rows = MIN_ROWS; // reset number of rows in textarea

    const currentRows = Math.floor(
      (this.textareaRef.current.scrollHeight - PADDING * 2) /
        TEXTAREA_LINE_HEIGHT
    );

    if (currentRows === previousRows) {
      this.textareaRef.current.rows = currentRows;
    }

    if (currentRows >= MAX_ROWS) {
      this.textareaRef.current.rows = MAX_ROWS;
      this.textareaRef.current.scrollTop = this.textareaRef.current.scrollHeight;
    }

    this.setState({
      rows: currentRows < MAX_ROWS ? currentRows : MAX_ROWS,
    });
  }

  render = () => {
    const { name, placeholder, value, onChange } = this.props;

    return (
      <StyledTextArea
        ref={this.textareaRef}
        name={name}
        placeholder={placeholder}
        rows={this.state.rows}
        value={value}
        onChange={onChange}
      />
    );
  };
}

TextArea.defaultProps = {
  name: null,
  placeholder: null,
};

TextArea.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextArea;
