import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Panel from '../../../Panel';
import SkillsSelectionList from './SkillsSelectionList';
import MessageBox from './MessageBox';
import theme from '@matterapp/matter-theme';

const Wrapper = styled.div`
  transition: max-height 0.5s ease-in-out;
  height: auto;
  overflow: hidden;
  max-height: 0px;
  padding: 0 ${theme.padding.base} ${theme.padding.base};

  ${({theme}) => theme.media.S`
    padding: 0 ${theme.padding.S} ${theme.padding.S};
  `}

  ${({open, height}) => open && `
    max-height: calc(${height}px + (${theme.padding.base} * 2));
    transition: max-height 0.2s ease-out-in;
  `}

  ${({open, height, theme}) => open && theme.media.S`
    max-height: calc(${height}px + (${theme.padding.S} * 2));
  `}
`;

const Body = styled.div`
`;

const Customization = ({
  dispatch,
  invite,
  isOpen,
  userSkills=[],
  tracking,
}) => {
  const [orderedSkills, setOrderedSkills] = useState([]);
  const [listOptions, setListOptions] = useState(isOpen);
  const [contentHeight, setContentHeight] = useState(0);
  const content = useRef();

  const setHeight = () => {
    if (content.current && content.current.offsetHeight) {
      setContentHeight(content.current.offsetHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setHeight);
    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  // force height to update after skillPills are rendered.
  useEffect(() => {
    setHeight();
  }, [listOptions]);

  useEffect(() => {
    if (!userSkills.length) { return; }
    setOrderedSkills(userSkills.sort((a, b) => a.name.length > b.name.length ? 1 : -1));
  }, [userSkills]);

  // delay set list options so that the user can see the animation on the user skill options.
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setListOptions(isOpen);
        setHeight();
      }, 300);
    } else {
      setListOptions(isOpen);
    }
  }, [isOpen]);

  function updateSelectedSkills(skill) {
    tracking.skillPillClick();
    dispatch({type: 'SELECTED_SKILLS:UPDATE', skill});
  }

  function onMessageChange(_, data) {
    dispatch({type: 'CUSTOM_NOTE:UPDATE', customNote: data.message });
  }
  function clearText() {
    dispatch({type: 'CUSTOM_NOTE:UPDATE', customNote: ''});
    tracking.clearText();
  }

  return (
    <Wrapper open={isOpen} height={contentHeight}>
      <Body ref={content}>
        <Panel.Divider style={{marginTop: theme.spacing.singleAndHalf}}/>
        <SkillsSelectionList
          heading="Which of your skills?"
          skillIds={invite.skillIds}
          listOptions={listOptions}
          orderedSkills={orderedSkills}
          updateSelectedSkills={updateSelectedSkills}
        />
        <MessageBox 
          heading="Personalize your message."
          onMessageChange={onMessageChange}
          clearText={clearText}
          maxMessageLength={250}
          customNote={invite.customNote}
        />
      </Body>
    </Wrapper>
  );
};

Customization.propTypes = {
  defaultMessage: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  invite: PropTypes.shape({
    customNote: PropTypes.string,
    skillIds: PropTypes.array
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  userSkills: PropTypes.array.isRequired,
  tracking: PropTypes.shape({
    skillPillClick: PropTypes.func,
    clearText: PropTypes.func,
  })
};

Customization.defaultProps = {
  tracking: {
    skillPillClick: () => {},
    clearText: () => {},
  }
};

export default Customization;
