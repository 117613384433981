import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill={fill}>
      <path d="M9.555 9.345L4.5 12.893V8.5c0-1.65 1.35-3 3-3s3 1.35 3 3V7c0-1.65 1.35-3 3-3s3 1.35 3 3v1.5c0-1.65 1.35-3 3-3s3 1.35 3 3v1c0-1.65 1.35-3 3-3s3 1.35 3 3v5c0 1.65-1.35 3-3 3s-3-1.35-3-3V14c0 1.65-1.35 3-3 3a3.01 3.01 0 0 1-2.721-1.741 4.838 4.838 0 0 0-.449-4.759 4.857 4.857 0 0 0-5.725-1.73 4.866 4.866 0 0 0-1.05.575z" />
      <path d="M19.5 19a4.967 4.967 0 0 1-2.294-.559c.842 1.035 1.647 2.473 2.14 4.441a1 1 0 0 1-1.941.486c-.575-2.299-1.622-3.66-2.483-4.44a5.534 5.534 0 0 0-.894-.676l-1.503 1.318c-.685.6-1.645.76-2.49.42l-.701-.284 4.9-4.301a2.83 2.83 0 0 0-3.495-4.445l-5.869 4.12c-.095.065-.185.14-.275.215a3.206 3.206 0 0 0-1.089 2.207L3.5 17.5v2c0 5.5 4.5 10 10 10h5c5.5 0 10-4.5 10-10v-1.004a4.972 4.972 0 0 1-3 1.004 4.98 4.98 0 0 1-3.33-1.275c-.773.49-1.69.775-2.67.775z" />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
