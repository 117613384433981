import React from 'react';
import PropTypes from 'prop-types';
import theme, { colors, media } from '@matterapp/matter-theme';
import { skillValueObjectPropType } from '@matterapp/skills';
import omitStyled from '../../../libs/omitStyled';
import { getSkillBackgroundColor } from '../../../common/libs/get-skill-details';

const SkillNoteRoot = omitStyled('div', ['backgroundColor'])`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin: ${({ skillName }) => (skillName ? '0 4px 8px 4px' : '0 0 8px 0')};
  padding: ${theme.padding.base};
  border-radius: ${theme.sizes.borderRadius[80]};
  color: ${colors.blacks[90]};
  background-color: ${({ backgroundColor }) => backgroundColor};
  white-space: pre-line;
  word-break: break-word;
  ${media.S`
    padding: ${theme.padding.S};
  `}
`;

const SkillNote = ({ skill, children }) => {
  const backgroundColor = skill
    ? getSkillBackgroundColor(skill)
    : getSkillBackgroundColor({ name: 'generic' });
  return (
    <SkillNoteRoot backgroundColor={backgroundColor}>{children}</SkillNoteRoot>
  );
};

SkillNote.defaultProps = {
  skill: null,
};

SkillNote.propTypes = {
  children: PropTypes.node.isRequired,
  skill: skillValueObjectPropType, // eslint-disable-line
};

export default SkillNote;
