import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AddMemberButton,
  ExcludedMembersBlock,
  PeersWrapper,
  PeerListItem,
  RemoveButton,
  MembersExplainText,
  SubHeader
} from './styles';
import Toast from 'components/Toast/Toast';
import AddMembersModal from './AddMembersModal';
import getMembersByPersonIds from 'graphql-queries/queries/workspace/getMembersByPersonIds';
import { useQuery, useMutation } from '@apollo/client';
import setFeedbackRitualSettings from 'graphql-queries/mutations/slackWorkspace/setFeedbackRitualSettings';

export default function ExcludeFromMVPSection({ workspace }) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [updateRitualSettings] = useMutation(setFeedbackRitualSettings);
  const [buttonText, updateButtonText] = useState('Add Member');
  const { id: workspaceId, feedbackRitualSettings } = workspace || {};
  const { excludePeopleFromMVP = [] } = feedbackRitualSettings || {};

  const { data } = useQuery(getMembersByPersonIds, {
    variables: {
      workspaceId,
      personIds: excludePeopleFromMVP
    },
    skip: !excludePeopleFromMVP.length
  });
  const { membersByPersonIds: excludedMembers = [] } = data || {};
  const ref = createRef();

  useEffect(() => {
    if (ref?.current?.offsetWidth < 600) {
      updateButtonText('Add');
    }
  }, [ref]);

  async function removeMember(id) {
    await updateRitualSettings({
      variables: {
        settings: {
          isActive: true,
          excludePeopleFromMVP: excludePeopleFromMVP.filter(personId => personId !== id)
        },
        workspaceId
      }
    });

    Toast.success('You successfully removed an excluded member');
  }

  return (
    <ExcludedMembersBlock ref={ref}>
      <AddMembersModal 
        workspace={workspace}
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
      <SubHeader>
        Excluded Members
        <AddMemberButton 
          onClick={() => setIsAddModalOpen(true)}
        >
          {buttonText}
        </AddMemberButton>
      </SubHeader>
      <MembersExplainText>
        Exclude channel members (e.g., admins) from being selected as the Most Valuable Participant.
      </MembersExplainText>
      <PeersWrapper>
        {excludedMembers
          .map(({ id, person }) => (
            <PeerListItem
              key={id}
              peer={person}
            >
              <RemoveButton color="black" onClick={() => removeMember(person.id)}>
                REMOVE
              </RemoveButton>
            </PeerListItem>
          ))}
      </PeersWrapper>
    </ExcludedMembersBlock>
  );
}

ExcludeFromMVPSection.propTypes = {
  workspace: PropTypes.object
};
