import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;

  padding: 48px;
  background-color: ${() => theme.colors.blues[50]};

  ${() => theme.media.large`
    padding: 96px;
  `}
`;

const ContainerInner = styled.div`
  min-width: 300px;
  max-width: 600px;

  ${() => theme.media.large`
    max-width: 992px;
  `}
`;

const Header = styled.h3`
  font-family: ${() => theme.fontFamilies.heading};
  color: white;
  font-weight: bold;

  font-size: 32px;
  line-height: 44px;
  margin-bottom: 0;

  ${() => theme.media.large`
    font-size: 48px;
    line-height: 56px;
  `}
`;

const HugeNumber = styled.div`
  font-family: ${() => theme.fontFamilies.heading};
  font-size: 144px;
  line-height: 144px;
  font-weight: bold;
  margin-top: 32px;

  ${() => theme.media.large`
    margin-top: 48px;
  `}
`;

const Paragraph = styled.p`
  font-size: 17px;
  line-height: 24px;

  ${() => theme.media.large`
    font-size: 32px;
    line-height: 44px;
  `}
`;

const ConnectCalendar = () => (
  <Container>
    <ContainerInner>
      <Header>Connect your Calendar to Matter</Header>
      <Paragraph>
        Matter works with Google Calendar to understand who you work with and
        who can provide you with feedback. This results in...
      </Paragraph>
      <HugeNumber>10x</HugeNumber>
      <Paragraph>more feedback</Paragraph>
    </ContainerInner>
  </Container>
);

export default ConnectCalendar;
