import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

// mediumBreakpoint: required to work with FlowCard consts
const mediumBreakpoint = 800;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const ListItemsContainer = styled.div`
  position: relative;
  min-height: 0;
  overflow: hidden;

  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  ${() => theme.media.breakpoint(mediumBreakpoint)`
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 32px;
  `};

  // Required to see the animation overflow top
  padding: 16px;
  margin: -16px;

  .sr__listItem {
    position: relative;
    animation-name: popin;
    animation-fill-mode: both;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-delay: 0.1s;
  }

  @keyframes popin {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const SkillRecommendationsGrid = ({ children, className }) => (
  <Container className={className}>
    <ListItemsContainer className={className}>{children}</ListItemsContainer>
  </Container>
);

SkillRecommendationsGrid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SkillRecommendationsGrid.ListItemsContainer = ListItemsContainer;
SkillRecommendationsGrid.mediumBreakpoint = mediumBreakpoint;

export default SkillRecommendationsGrid;
