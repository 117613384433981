import { withProps } from 'recompose';
import PropTypes from 'prop-types';
import TrackOnMountContainer from './TrackOnMountContainer';

const withData = withProps(({ source, numPeers }) => ({
  eventName: 'peerSuggestions:viewed',
  eventParams: {
    source,
    numPeers,
  },
}));

const PeerSuggestionsTrackOnMountContainer = withData(TrackOnMountContainer);

PeerSuggestionsTrackOnMountContainer.propTypes = {
  source: PropTypes.string.isRequired,
  numPeers: PropTypes.number.isRequired,
};

export default PeerSuggestionsTrackOnMountContainer;
