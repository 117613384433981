import React from 'react';
import BasePath from './BasePath';

const DEFAULT_HEIGHT = 376;
const PATH_LENGTH = 1186;

const LeftToLeft1 = React.forwardRef((props, ref) => (
  <BasePath
    {...props}
    ref={ref}
    height={DEFAULT_HEIGHT}
    pathLength={PATH_LENGTH}
    pathCode={
      `M10,9.5V73c0,6.628,2.686,12.628,7.029,16.971S27.372,97,34,97h78.5c6.628,0,12.628,2.686,16.971,7.029
      S136.5,114.372,136.5,121v15c0,6.628,2.686,12.628,7.029,16.971S153.872,160,160.5,160h246c6.628,0,12.628,2.686,16.971,7.029
      c4.343,4.343,7.029,10.343,7.029,16.971v157c0,6.628-2.686,12.628-7.029,16.971C419.128,362.314,413.128,365,406.5,365h-198
      c-6.628,0-12.628-2.686-16.971-7.029c-4.343-4.343-7.029-10.343-7.029-16.971v-35.5c0-6.628-2.686-12.628-7.029-16.971
      c-4.343-4.343-10.343-7.029-16.971-7.029H32.719`
    }
  />
));

export default LeftToLeft1;
