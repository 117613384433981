import * as purple from './purple';
import * as simple from './simple';

export const VARIANTS = {
  DEFAULT: 'default',
  SIMPLE: 'simple',
};

export const VARIANT_THEMES = {
  [VARIANTS.DEFAULT]: purple,
  [VARIANTS.SIMPLE]: simple,
};

/**
 * Gets the style of the given variant.
 * @param { String } variant: The style variant.
 * @param {*} callback: The function to call from the theme variant to get the style.
 * @param {*} props: Additional props from styling.
 * @returns { String } The styles of the variant.
 */
export const getThemeVariant = (variant, callback, props) => {
  const theme = VARIANT_THEMES[variant] || VARIANT_THEMES.DEFAULT;
  if (theme && typeof theme[callback] === 'function') {
    return theme[callback](props);
  } else if (typeof VARIANT_THEMES[VARIANTS.DEFAULT][callback] === 'function') {
    return VARIANT_THEMES[VARIANTS.DEFAULT][callback](props);
  }
  return '';
};

export const getTextAreaContainerStyles = ({ variant, ...props }) => {
  return getThemeVariant(variant, 'getTextAreaContainerStyles', props);
};

export const getTextAreaStyles = ({ variant, ...props }) => {
  return getThemeVariant(variant, 'getTextAreaStyles', props);
};

export const getCharCountStyles = ({ variant, ...props }) => {
  return getThemeVariant(variant, 'getCharCountStyles', props);
};

export const getCharCountContainerStyles = ({ variant, ...props }) => {
  return getThemeVariant(variant, 'getCharCountContainerStyles', props);
};

export const getCharCountTextStyles = ({ variant, ...props }) => {
  return getThemeVariant(variant, 'getCharCountTextStyles', props);
};

export const getMediumScreenInputStyles = ({ variant, ...props }) => {
  return getThemeVariant(variant, 'getMediumScreenInputStyles', props);
};

export const getChildrenContainerStyles = ({ variant, ...props }) => {
  return getThemeVariant(variant, 'getChildrenContainerStyles', props);
};
