import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, spacing } from '@matterapp/matter-theme';
import { Button, TextAreaWithCount } from '@matterapp/matter-ui';
import SkillNoteAbilities from './SkillNoteAbilities';

const DEFAULT_MAX_NOTE_LENGTH = 1500;
const DEFAULT_MIN_NOTE_LENGTH = 25;

const Container = styled.div`
  & label {
    display: flex;
  }
  margin-bottom: ${spacing.double};
`;

const TextArea = styled(TextAreaWithCount.Simple)`
  min-height: ${({ hasAbilities }) => hasAbilities ? '112px' : '152px'};
  padding-top: ${spacing.half};
  ${media.S`
    min-height: ${({ hasAbilities }) => hasAbilities ? '112px' : '144px'};
    padding-top: ${spacing.single};
  `}
`;

const noteHasError = (note, min, max) => {
  if (note && note.length) {
    return note.length < min || note.length > max;
  }
  return true;
};

const getSelectedAbilities = (abilities = []) => {
  return abilities.filter((ability) => {
    return ability.selected;
  });
};

const SkillNote = (props) => {
  const {
    abilities,
    color,
    currentAbilities,
    isShown,
    maxLength,
    minLength,
    onChange,
    onError,
    value,
    defaultNote,
    autoFocus,
    skillToRate,
  } = props;
  const [note, setNote] = React.useState(value || defaultNote);
  const error = noteHasError(note, minLength, maxLength);

  //When textarea is focused, if there is text, this will move cursor to end of text.
  const moveCursorToEnd = (e) => {
    var temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
  };

  const onChangeNote = (e) => {
    const currentNote = e.target.value;
    setNote(currentNote);
    onChange(e, { currentNote, skillToRate, defaultNote, noteError: noteHasError(currentNote, minLength, maxLength) });
  };
  
  const selectedAbilities = getSelectedAbilities(abilities, currentAbilities);

  return (
    <Container isShown={isShown}>
      <TextArea
        error={error}
        label=""
        maxLength={maxLength}
        minLength={minLength}
        name="customNote"
        onChange={onChangeNote}
        onError={onError}
        tabIndex="-1"
        value={note || ''}
        autoFocus={autoFocus}
        onFocus={moveCursorToEnd}
        hasAbilities={!!selectedAbilities.length}
      >
        <SkillNoteAbilities
          abilities={abilities}
          color={color}
          currentAbilities={selectedAbilities}
        />
      </TextArea>
    </Container>
  );
};

SkillNote.SkillNoteAbilities = SkillNoteAbilities;

SkillNote.propTypes = {
  /** List of abilities of the skill. */
  abilities: PropTypes.arrayOf(
    PropTypes.shape({
      ability: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      selected: PropTypes.bool,
    })
  ),
  /** The color of the skill. */
  color: PropTypes.oneOf(Object.values(Button.Simple.buttonColors)),
  /** The currently selected abilities ids. */
  currentAbilities: PropTypes.arrayOf(PropTypes.string),
  /** The max length of the custom note. */
  maxLength: PropTypes.number,
  /** The min length of the custom note. */
  minLength: PropTypes.number,
  /** Callback when the value of the note changes. */
  onChange: PropTypes.func.isRequired,
  /** The value of the custom note. */
  value: PropTypes.string,
  isShown: PropTypes.bool,
  onError: PropTypes.func,
  autoFocus: PropTypes.bool,
  defaultNote: PropTypes.string,
  skillToRate: PropTypes.object,
};

SkillNote.defaultProps = {
  value: '',
  maxLength: DEFAULT_MAX_NOTE_LENGTH,
  minLength: DEFAULT_MIN_NOTE_LENGTH,
};

export default SkillNote;
export { noteHasError };
