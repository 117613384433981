import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import omitStyled from '../../libs/omitStyled';

import Content from '../Content';
import FlashMessage from './FlashMessage';
import FlashContainer from './FlashContainer';
import { Logo } from '../Brand';
import theme from '@matterapp/matter-theme';

import {
  smallBreakpoint,
  mediumBreakpoint,
  headerHeight,
  cardWidth as defaultCardWidth,
  cardWidthWide,
  cardWidthExtraWide,
  xsmallBreakpoint,
} from './consts';

const Container = omitStyled('div', [
  'cardWidth',
  'wide',
  'extraWide',
  'isHeaderHiddenOnMobile',
])`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: ${({ cardWidth }) => cardWidth || defaultCardWidth}px;
  flex-grow: 1;
  padding-top: ${headerHeight}px;

  ${theme.media.breakpoint(smallBreakpoint)`
    padding-top: ${headerHeight + 32}px;
    flex-grow: 0;
    border-radius: ${theme.sizes.borderRadius[80]};
    margin-bottom: 116px;
  `};

  ${({ wide }) =>
    wide &&
    theme.media.breakpoint(mediumBreakpoint)`
    max-width: ${cardWidthWide}px;
  `};

  ${({ extraWide }) =>
    extraWide &&
    theme.media.breakpoint(mediumBreakpoint)`
    max-width: ${cardWidthExtraWide}px;
  `};

  ${({ isHeaderHiddenOnMobile }) =>
    isHeaderHiddenOnMobile &&
    `
    padding-top: 0;
  `};
`;

const StyledCard = omitStyled(Content.Card, ['hideTopRadius'])`
  display: flex;
  flex-direction: column;
  box-shadow: none;
  flex-grow: 1;
  padding-left: 0;
  padding-right: 0;
  padding:  ${({ isFeedItem }) => isFeedItem ? 0 : ''};
  margin-bottom: ${({ isFeedItem }) => isFeedItem ? 0 : 'inherit'};
  &:last-child {
    margin-bottom: 0;
  }

  ${theme.media.breakpoint(smallBreakpoint)`
    border-radius: ${theme.sizes.borderRadius[80]};
    box-shadow: ${theme.shadows.flow};
    backdrop-filter: blur(20px);
  `}

  ${({ hideTopRadius }) =>
    hideTopRadius &&
    theme.media.breakpoint(smallBreakpoint)`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `};
`;

const FooterBrandMark = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
  height: 100%;

  ${theme.media.S`
    display: none;
  `}
`;

const Card = ({
  cardWidth,
  children,
  className,
  dataProps,
  flashIntro,
  flashBody,
  flashType,
  renderFlash,
  wide,
  extraWide,
  showMobileFooterBrand,
  isHeaderHiddenOnMobile,
  isFeedItem,
}) => (
  <Container
    {...dataProps}
    className={className}
    cardWidth={cardWidth}
    wide={wide}
    extraWide={extraWide}
    isHeaderHiddenOnMobile={isHeaderHiddenOnMobile}
  >
    {renderFlash ? (
      <FlashContainer type={flashType}>{renderFlash()}</FlashContainer>
    ) : (
      <FlashMessage intro={flashIntro} body={flashBody} type={flashType} />
    )}
    <StyledCard className={'styled-card'} hideTopRadius={renderFlash || flashIntro || flashBody} isFeedItem={isFeedItem}>
      {children}

      {showMobileFooterBrand && (
        <FooterBrandMark>
          <Logo size="small" marginBottom={24} />
        </FooterBrandMark>
      )}
    </StyledCard>
  </Container>
);

Card.xsmallBreakpoint = xsmallBreakpoint;
Card.smallBreakpoint = smallBreakpoint;
Card.mediumBreakpoint = mediumBreakpoint;

Card.defaultProps = {
  dataProps: {},
  flashIntro: null,
  flashBody: null,
  flashType: 'normal',
  renderFlash: null,
  wide: false,
  extraWide: false,
  showMobileFooterBrand: false,
  isHeaderHiddenOnMobile: false,
};

Card.propTypes = {
  cardWidth: PropTypes.number,
  className: PropTypes.string,
  dataProps: PropTypes.object,
  flashIntro: PropTypes.string,
  flashBody: PropTypes.node,
  flashType: PropTypes.oneOf(['normal', 'important']),
  renderFlash: PropTypes.func,
  children: PropTypes.node.isRequired,
  wide: PropTypes.bool,
  extraWide: PropTypes.bool,
  showMobileFooterBrand: PropTypes.bool,
  isHeaderHiddenOnMobile: PropTypes.bool,
};

export default Card;
