import { TextArea } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { Box } from '@mui/material';

export const QuestionWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '600px',
  maxWidth: '100%'
}));

export const QuestionInput = styled(TextArea.WithCount)(({ theme }) => ({
  fontWeight: theme.fontWeight.regular,
  minHeight: '64px',
  border: 'none'
}));
