import iconSuccess from 'assets/icon_success.svg';
import iconInfo from 'assets/icon_info.svg';
import iconWarning from 'assets/icon_warning.svg';
import iconError from 'assets/icon_error.svg';

// Lifecycle states
export const DISPLAY_STATES = {
  attached: 1,
  shown: 2,
  hidden: 3,
  detached: 4,
};

export const NOTIFICATION_TYPES = {
  success: {
    iconSrc: iconSuccess,
  },
  info: {
    iconSrc: iconInfo,
  },
  warning: {
    iconSrc: iconWarning,
  },
  error: {
    iconSrc: iconError,
  }
};

export const TIMINGS = {
  fadeIn: 200,
  fadeOutAuto: 900,
  fadeOutUser: 200,
  renderWait: 50,
  defaultShowPeriod: 3000
};


