import React from 'react';
import PropTypes from 'prop-types';
import {
  ListWithHeader,
  InvitationItem,
  Button,
  Text,
} from '@matterapp/matter-ui';

class WorkspaceInvitations extends React.Component {
  renderPendingInvitationItem = (item) => {
    const {
      handleAcceptInvitation,
      handleDeclineInvitation,
      onClickNotification,
    } = this.props;

    const token = item.token;
    return (
      <InvitationItem
        isStackedOnMobile
        key={item.id}
        name={item.name}
        iconUrl={item.iconUrl}
        content={<Text color="black">You&apos;ve been invited to join</Text>}
        actions={
          <React.Fragment>
            <Button.Primary.Vivid
              key="start"
              color="green"
              size={Button.buttonSizes.S}
              onClick={(e) => {
                onClickNotification(e);
                handleAcceptInvitation({ workspaceId: item.id, token });
              }}
            >
              Accept
            </Button.Primary.Vivid>
            <Button
              key="end"
              size={Button.buttonSizes.S}
              onClick={(e) => {
                onClickNotification(e);
                handleDeclineInvitation({ token, workspaceId: item.id });
              }}
            >
              Decline
            </Button>
          </React.Fragment>
        }
      />
    );
  };
  
  getPendingWorkspaceInvitations = (notifications) => {
    const filteredWorkspaceNotifications = notifications.filter(({isViewed}) => !isViewed);

    return filteredWorkspaceNotifications.map((notification) => {
      const { workspaceInvitationToken, payload } = notification;
      const { workspace } = payload;
      const { id, name, iconUrl } = workspace;

      return {
        id,
        name,
        iconUrl,
        token: workspaceInvitationToken,
        notificationId: notification.id
      };
    });
  };

  render() {
    const { bordered, invitations } = this.props;
    const items = this.getPendingWorkspaceInvitations(invitations);

    if (items.length == 0) {
      return null;
    }

    return (
      <ListWithHeader
        bordered={bordered}
        header="Workspace Invitation"
        headerType="invite"
        items={items}
        renderItem={this.renderPendingInvitationItem}
        rounded={false}
        showAllItemBorders
      />
    );
  }
}

WorkspaceInvitations.defaultProps = {
  bordered: false,
  onClickNotification: () => null,
};

WorkspaceInvitations.propTypes = {
  workspacesData: PropTypes.shape({
    currentUser: PropTypes.shape({
      id: PropTypes.string,
      workspaces: PropTypes.array,
    }),
  }),
  onClickNotification: PropTypes.func,
  handleAcceptInvitation: PropTypes.func.isRequired,
  handleDeclineInvitation: PropTypes.func.isRequired,
  bordered: PropTypes.bool,
  invitations: PropTypes.array
};

export default WorkspaceInvitations;
