import { graphql } from '@apollo/client/react/hoc';
import withRouter from 'hocs/withRouter';
import { compose } from 'recompose';
import WorkspaceInvitations from '../components/WorkspaceInvitations';
import {
  MARK_NOTIFICATIONS_AS_VIEWED_MUTATION
} from 'graphql-queries/queries';

const withMarkNotificationsAsVieweMutation = graphql(MARK_NOTIFICATIONS_AS_VIEWED_MUTATION, {
  name: 'markNotificationAsViewed',
});

const withData = compose(
  withRouter,
  withMarkNotificationsAsVieweMutation
);

export default withData(WorkspaceInvitations);
