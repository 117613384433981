import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const RC_LABEL = 'timeline-step';
const RESIZE_TIMEOUT_TIME = 20;
const transitionTime = '0.3s';

const getCurrentStepStyles = ({ contentHeight, isOpen }) => {
  return `
    position: ${isOpen ? 'static' : 'absolute'};
    top: 0;
    left: 0;
    right: 0;
    max-height: ${isOpen ? contentHeight : 0}px;
    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? 'all' : 'none'};
    transition: opacity ${transitionTime},
      max-height 0s ${isOpen ? '0s' : transitionTime},
      position 0s ${transitionTime};
  `;
};

const Container = styled.div`
  height: ${({ hasHeader }) => (hasHeader ? 'auto' : 0)};
  overflow: ${({ hasHeader }) => !hasHeader && 'hidden'};
`;

const ContentContainer = styled.div`
  overflow: hidden;
  ${theme.media.S`
    overflow: hidden;
  `}
  ${getCurrentStepStyles}
`;

const ContentWrapper = styled.div`
  margin: ${theme.spacing.single} 0;
  ${theme.media.S`
    margin: ${theme.spacing.double} 0;
  `}
`;

export default class Step extends React.PureComponent {
  static propTypes = {
    /**
     * The contents of the step.
     * If a function is passed, all the following private props below are accessible as render props.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    /** The title of the step. */
    title: PropTypes.node.isRequired,

    /*************************************************************************************/
    /** The following props are private props that are passed from the parent container. */
    /*************************************************************************************/
    /** If the step is clickable. */
    canClick: PropTypes.bool,
    /** If this step is completed. */
    isCompletedStep: PropTypes.bool,
    /** If this is the current step in the timeline. */
    isCurrentStep: PropTypes.bool,
    /** If this is the last step in the timeline. */
    isLastStep: PropTypes.bool,
    /** If the step is currently open. */
    isOpen: PropTypes.bool,
    /** Callback when header is clicked. */
    onClickHeader: PropTypes.func,
    /** Callback to go to next step. */
    onClickNext: PropTypes.func,
    /** Callback to go to prev step. */
    onClickPrev: PropTypes.func,
    /** The number of the step. Automatically passed in from `SteppedTimeline` */
    stepNumber: PropTypes.number,
    /** The total numebr of steps in the timeline. */
    totalNumberOfSteps: PropTypes.number,
    /** The 'Up Next' label. */
    upNextLabel: PropTypes.string,
    /** The current visible step. */
    visibleStep: PropTypes.number,
  };

  static defaultProps = {
    canClick: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      contentHeight: 1000,
    };
    this.contentRef = React.createRef();
    this.resizeTimeout = null;
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen) {
      this.handleResizeStepTimeout();
    }
  };

  componentDidMount = () => {
    this._mounted = true;
    this.handleResizeStep();
    window.addEventListener('resize', this.handleResizeStepTimeout);
  };

  componentWillUnmount = () => {
    this._mounted = false;
    clearTimeout(this.resizeTimeout);
    window.removeEventListener('resize', this.handleResizeStepTimeout);
  };

  /**
   * Since the step header and content need exact heights to for proper transition,
   * Calculate the offset height and apply to parent containers.
   * @returns { void }
   */
  handleResizeStep = () => {
    this.setContentHeight();
  };

  /**
   * Calls `handleResizeStep` with a timeout to resize step.
   * @returns { void }
   */
  handleResizeStepTimeout = () => {
    this.resizeTimeout = setTimeout(this.handleResizeStep, RESIZE_TIMEOUT_TIME);
  };

  /**
   * Calculates the height of the content.
   * Needed for elegant expanding animation.
   * @returns { void }
   */
  setContentHeight = () => {
    if (this.contentRef && this.contentRef.current) {
      this.setState({
        contentHeight: this.contentRef.current.offsetHeight + 50,
      });
    }
  };

  renderChildren = () => {
    const { children, ...otherProps } = this.props;
    if (typeof children === 'function') {
      return children(otherProps);
    }
    return children;
  };

  render() {
    const { contentHeight } = this.state;
    const { isOpen, title } = this.props;
    return (
      <>
        <Container hasHeader={!!title} data-rc={RC_LABEL} data-open={isOpen}>
          <ContentContainer contentHeight={contentHeight} isOpen={isOpen}>
            <ContentWrapper ref={this.contentRef}>
              {this.renderChildren()}
            </ContentWrapper>
          </ContentContainer>
        </Container>
      </>
    );
  }
}
