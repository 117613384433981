import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, fill }) => (
  <svg
    className={className}
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M3,0v10h10V0H3z M12,9H4V1h8V9z M1,12h9v1H1H0v-1V3h1V12z"
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#07BB87',
};

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Icon;
