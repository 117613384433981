import styled from 'styled-components';
import { IconNew as Icon } from '@matterapp/matter-ui';
import {
  border,
  colors,
  media,
  spacing,
  transitions,
  font
} from '@matterapp/matter-theme';

const MENU_CONTAINER_HEIGHT = 56;

const getMenuItemHoverColor = ({ actionItem, isActive }) => {
  if (actionItem) {
    return colors.purples[1];
  } else if (isActive) {
    return colors.white;
  }
  return colors.blacks[5];
};

const getMenuItemActiveColor = ({ actionItem, isActive }) => {
  if (actionItem) {
    return colors.purples[5];
  } else if (isActive) {
    return colors.white;
  }
  return colors.purples[1];
};

export const CurrentSpaceHeader = styled.div`
  color: ${colors.blacks[80]};
  flex: 0 0 auto;
  font-size: ${font.size.base};
  font-weight: ${font.weight.medium};
  line-height: 1;
  max-width: calc(100vw - 180px);
  overflow: hidden;
  text-align: left;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;

  div + & {
    margin-left: ${spacing.threeQuarters};
  }

  & + svg {
    min-width: ${spacing.single};
    max-width: ${spacing.single};
    margin: 0 0 0 ${spacing.quarter};
  }
`;

export const MenuItemContentContainer = styled(CurrentSpaceHeader)`
  max-width: 70% !important;
  color: ${({ actionItem }) => actionItem ? colors.purples[30] : colors.blacks[70]};
  flex: 1 1 auto;
`;

export const MenuItemContainer = styled.div`
  align-items: center;
  cursor: pointer;
  background: ${colors.white};
  color: ${({ actionItem }) => actionItem ? colors.purples[30] : colors.blacks[70]};
  display: flex;
  padding: ${spacing.single} ${spacing.singleAndQuarter};
  text-decoration: none !important;
  min-height: ${spacing.tripleAndHalf};
  width: 100%;
  
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.blacks[10]};
  }

  &:hover {
    background: ${getMenuItemHoverColor};
  }

  &:active {
    background: ${getMenuItemActiveColor};
  }

  ${media.L`
    padding: ${spacing.single};
  `}
`;

export const MenuContainer = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.blacks[10]};
  border-radius: 0;
  margin: -1px 0 0 -1px;
  width: 280px;
  position: absolute;
  top: 67px;
  left: 20px;
  height: auto;
  width: 280px;
  z-index: 10001;
  transition: all ${transitions.times.default}, max-height ${transitions.times.triple} 0.05s;

  ${({ show }) => `
    max-height: ${show ? '100vh' : `${MENU_CONTAINER_HEIGHT}px`};
    opacity: ${show ? 1 : 0};
    overflow-y: ${show ? 'auto' : 'hidden'};
    pointer-events: ${show ? 'all' : 'none'};
  `}

  ${({ show }) => media.L`
    border-radius: ${border.radius.S};
    top: 67px;
    left: 20px;
    margin: 0 0 0 -1px;
    max-height: ${show ? `calc(100vh - ${spacing.single})` : `${MENU_CONTAINER_HEIGHT}px`};
  `}
`;

export const MenuOverlay = styled.div`
  ${({ show }) => `
    background: ${show ? colors.black : 'transparent'};
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: ${show ? 'all' : 'none'};
    z-index: 10000;
    transition: all ${transitions.times.default};
  `}
`;

export const CheckIcon = styled(Icon).attrs(() => ({
  name: 'checkmark'
}))``;


