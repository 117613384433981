"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "animations", {
  enumerable: true,
  get: function () {
    return _animations.default;
  }
});
Object.defineProperty(exports, "screenWidthBreakpoints", {
  enumerable: true,
  get: function () {
    return _breakpoints.breakpoints;
  }
});
Object.defineProperty(exports, "border", {
  enumerable: true,
  get: function () {
    return _border.border;
  }
});
Object.defineProperty(exports, "ColorPalettes", {
  enumerable: true,
  get: function () {
    return _colors.ColorPalettes;
  }
});
Object.defineProperty(exports, "colors", {
  enumerable: true,
  get: function () {
    return _colors.colors;
  }
});
Object.defineProperty(exports, "getColorObj", {
  enumerable: true,
  get: function () {
    return _colors.getColorObj;
  }
});
Object.defineProperty(exports, "font", {
  enumerable: true,
  get: function () {
    return _font.font;
  }
});
Object.defineProperty(exports, "gradients", {
  enumerable: true,
  get: function () {
    return _gradients.gradients;
  }
});
Object.defineProperty(exports, "lineHeight", {
  enumerable: true,
  get: function () {
    return _lineHeight.lineHeight;
  }
});
Object.defineProperty(exports, "mixins", {
  enumerable: true,
  get: function () {
    return _mixins.default;
  }
});
Object.defineProperty(exports, "createBreakpointStyle", {
  enumerable: true,
  get: function () {
    return _media.createBreakpointStyle;
  }
});
Object.defineProperty(exports, "shadows", {
  enumerable: true,
  get: function () {
    return _shadows.shadows;
  }
});
Object.defineProperty(exports, "sizes", {
  enumerable: true,
  get: function () {
    return _sizes.sizes;
  }
});
Object.defineProperty(exports, "skillColorPalettes", {
  enumerable: true,
  get: function () {
    return _skillColors.skillColorPalettes;
  }
});
Object.defineProperty(exports, "spacing", {
  enumerable: true,
  get: function () {
    return _spacing.spacing;
  }
});
Object.defineProperty(exports, "transitionTime", {
  enumerable: true,
  get: function () {
    return _transitions.times;
  }
});
Object.defineProperty(exports, "transitions", {
  enumerable: true,
  get: function () {
    return _transitions.transitions;
  }
});
Object.defineProperty(exports, "zIndex", {
  enumerable: true,
  get: function () {
    return _zIndex.zIndex;
  }
});
exports.default = exports.media = exports.createDataRole = exports.breakpoints = void 0;

var _animations = _interopRequireDefault(require("./animations"));

var _breakpoints = require("./breakpoints");

var _border = require("./border");

var _buttons = require("./legacyStyles/buttons");

var _colors = require("./colors");

var _content = require("./legacyStyles/content");

var _font = require("./font");

var _gradients = require("./gradients");

var _inputs = require("./legacyStyles/inputs");

var _lineHeight = require("./lineHeight");

var _mixins = _interopRequireDefault(require("./mixins"));

var _ratingColors = require("./legacyStyles/ratingColors");

var _media = _interopRequireWildcard(require("./libs/media"));

var _shadows = require("./shadows");

var _sizes = require("./sizes");

var _skillColors = require("./legacyStyles/skillColors");

var _spacing = require("./spacing");

var _transitions = require("./transitions");

var _zIndex = require("./zIndex");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const createDataRole = dataRole => `[data-role="${dataRole}"]`;

exports.createDataRole = createDataRole;
const breakpointValues = Object.values(_breakpoints.breakpoints);
exports.breakpoints = breakpointValues;
const mediaBreakpoints = (0, _media.default)(_breakpoints.breakpoints);
exports.media = mediaBreakpoints;
const theme = {
  animations: _animations.default,
  border: _border.border,
  borderRadius: _border.borderRadius,
  buttons: _buttons.buttons,
  buttonModes: _buttons.buttonModes,
  colors: _colors.colors,
  content: _content.content,
  font: _font.font,
  fontFamily: _font.fontFamily,
  fontFamilies: _font.fontFamilies,
  fontSize: _font.fontSize,
  fontWeight: _font.fontWeight,
  fontFamilyWeights: _font.fontFamilyWeights,
  gradients: _gradients.gradients,
  inputs: _inputs.inputs,
  lineHeight: _lineHeight.lineHeight,
  mixins: _mixins.default,
  padding: _spacing.padding,
  ratingColors: _ratingColors.ratingColors,
  shadows: _shadows.shadows,
  sizes: _sizes.sizes,
  spacing: _spacing.spacing,
  transitions: _transitions.transitions,
  zIndex: _zIndex.zIndex,
  breakpoints: breakpointValues,
  createBreakpointStyle: _media.createBreakpointStyle,
  screenWidthBreakpoints: _breakpoints.breakpoints,
  media: mediaBreakpoints,
  skillColorPalettes: _skillColors.skillColorPalettes
};
theme.media.breakpoint = _media.breakpoint;
var _default = theme;
exports.default = _default;