import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';

export const GET_METADATA = gql`
  query {
    currentUser {
      id
      metadata
    }
  }
`;

export const UPDATE_METADATA = gql`
  mutation($metadata: JSON) {
    updateMetadata(metadata: $metadata) {
      metadata
    }
  }
`;

/**
 * Hook for getting and setting user metadata.
 * @param {Object} mutationProps
 * @returns { Object }: {
 *   metadata { Object }: The metadata object of the current user.
 *   setMetadata { Function }: Function to set metadata. Accepts an object of key value pairs.
 * }
 */
export const useUserMetadata = ({
  queryProps = {},
  mutationProps = {},
} = {}) => {
  const { data, loading } = useQuery(GET_METADATA, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-and-network',
    ...queryProps,
  });
  const [mutation] = useMutation(UPDATE_METADATA, {
    ...mutationProps,
  });
  const { currentUser } = data || {};

  return {
    data,
    metadata: loading || !currentUser ? {} : currentUser.metadata,
    loading: loading || !data,
    setMetadata: (metadata) => {
      return mutation({ variables: { metadata } });
    },
  };
};

export default useUserMetadata;
