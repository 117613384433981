import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading } from '@matterapp/matter-ui';
import { MAX_STEP_WIDTH } from './utils';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const ContentContainer = styled.div`
  flex: 1 1 100%;
  max-width: ${MAX_STEP_WIDTH}px;
`;

const SidebarContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
`;

const Header = styled(Heading).attrs({ textAlign: 'left' })`
  font-size: ${theme.fontSize.L};
  max-width: calc(${MAX_STEP_WIDTH}px - ${theme.spacing.double});
  ${theme.media.S`
    font-size: calc(${theme.fontSize.L} + ${theme.spacing.quarter});
  `}
`;

const StepContainer = (props) => {
  const { className, children, header, sidebarContent } = props;
  return (
    <Container className={className}>
      <Header>{header}</Header>
      <ContentWrapper>
        <ContentContainer>{children}</ContentContainer>
        <SidebarContainer>{sidebarContent}</SidebarContainer>
      </ContentWrapper>
    </Container>
  );
};

StepContainer.propTypes = {
  /** Additional style class. */
  className: PropTypes.string,
  /** The contents of the step. */
  children: PropTypes.node.isRequired,
  /** The header of the step. */
  header: PropTypes.node,
  sidebarContent: PropTypes.node,
};

export default StepContainer;
