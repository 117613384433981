import React from 'react';

const Icon = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#4285F4" d="M36,20.36797c0-1.0876-0.08996-2.18108-0.28189-3.25104H20.32237v6.1611h8.81642c-0.36585,1.98707-1.54137,3.74487-3.26267,4.86186v3.99766h5.25986C34.22472,29.35095,36,25.23571,36,20.36797z"/>
    <path fill="#34A853" d="M20.32237,36c4.40221,0,8.11471-1.41682,10.8196-3.86245l-5.25986-3.99766c-1.46341,0.9759-3.35264,1.52852-5.55375,1.52852c-4.25827,0-7.8688-2.816-9.16428-6.60202H5.73629v4.12112C8.50717,32.59023,14.15088,36,20.32237,36z"/>
    <path fill="#FBBC04" d="M11.15809,23.06639c-0.68372-1.98707-0.68372-4.13875,0-6.12583v-4.12112h-5.4218c-2.31506,4.52088-2.31506,9.84718,0,14.36806L11.15809,23.06639z"/>
    <path fill="#EA4335" d="M20.32237,10.33267c2.32705-0.03527,4.57614,0.82305,6.26146,2.3986l0,0l4.66011-4.56792c-2.9508-2.71606-6.86721-4.2093-10.92156-4.16227c-6.17149,0-11.8152,3.40977-14.58607,8.81837l5.4218,4.12112C12.44757,13.14867,16.0641,10.33267,20.32237,10.33267z"/>
  </svg>
);

Icon.defaultProps = {
  height: 32,
  width: 32,
};

export default Icon;
