import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from './Default';
import { BUTTON_GROUP_ROLE } from '../Group';
import theme, { createDataRole } from '@matterapp/matter-theme';

const getColorStyles = ({
  canToggle = true,
  color,
  disabled,
  bold,
  isSelected,
}) => {
  const backgroundColorLight = theme.colors.white;
  const backgroundColorDark = theme.colors.blacks[5];
  const backgroundColor = bold ? backgroundColorDark : backgroundColorLight;
  const baseColor = theme.colors[`${color}s`] || theme.colors.blues;

  const borderColorDark = theme.colors.blacks[40];
  const borderColorLight = theme.colors.blacks[10];

  const baseStyles = `
    background-color: ${backgroundColor};
    border: 1px solid ${borderColorLight};
  `;

  const selectedStyles = `
    ${baseStyles}
    background-color: ${baseColor[50]};
    border: 1px solid ${borderColorLight};
    color: ${theme.colors.white};
  `;

  if (disabled) {
    return 'border-width: 1px';
  }

  if (isSelected) {
    if (canToggle) {
      return `
        ${selectedStyles}
        &:hover {
          background-color: ${baseColor[60]};
        }
        &:active {
          background-color: ${backgroundColor};
          border-color: ${borderColorDark};
          color: inherit;
        }
      `;
    }
    return `
      ${selectedStyles}
      &:hover {
        ${selectedStyles}
      }
      &:active {
        ${selectedStyles}
      }
    `;
  }

  return `
    ${baseStyles}
    color: inherit;
    &:hover {
      background-color: ${backgroundColorDark};
      border-color: ${borderColorDark};

      ${createDataRole(BUTTON_GROUP_ROLE)} & {
        border-color: ${borderColorLight};
      }
    }
    &:active {
      ${baseStyles}
      border-color: ${borderColorDark};
      background-color: ${baseColor[5]};

      ${createDataRole(BUTTON_GROUP_ROLE)} & {
        border-color: ${borderColorLight};
      }
    }
  `;
};

const ToggleButton = styled(Button)`
  ${getColorStyles}
`;

ToggleButton.propTypes = {
  isSelected: PropTypes.bool,
};

export default ToggleButton;
