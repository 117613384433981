import styled from 'styled-components';
import { colors, font, lineHeight, spacing, media } from '@matterapp/matter-theme';
import Button from '../../../Button';
import { Clickable } from '../../../Clickable/Clickable';
import Dropdown from '../../../Dropdown';
import { Modal } from '../../../Modal';

export const sharedStyles = `
  display: flex;
  font-size: ${font.size.S};
  line-height: ${lineHeight.M};
  font-weight: ${font.weight.bold};
  padding: ${spacing.single};
`;

export const ModalHeader = styled(Modal.Header)`
  text-align: center;
`;

export const TitleSection = styled.div`
  ${sharedStyles}
  align-items: center;
  word-break: break-all;
  border-bottom: 1px solid ${colors.blacks[10]};
  ${media.S`
    padding: ${spacing.singleAndHalf};
  `}
`;

export const EmailDropdownContainer = styled.div`
  padding: ${spacing.single};
  border-bottom: 1px solid ${colors.blacks[10]};
  ${media.S`
    padding: ${spacing.singleAndHalf};
  `}
`;

export const EmailDropdown = styled(Dropdown.Email.Tags).attrs(() => ({
  fluid: true,
  filterItems: true,
  showDropdownArrow: true,
  singleValue: true,
}))`
  height: ${spacing.tripleAndHalf};
  & input {
    height: ${spacing.tripleAndHalf};
  }
`;

export const AvatarContainer = styled.div`
  border: 4px solid white;
  border-radius: 100px;
  margin-right: ${spacing.single};
`;

export const TooltipContainer = styled.div`
  margin-top: -${spacing.half};
  ${media.S`
    margin-top: 0;
  `}
  &:hover {
    svg path {
    fill: ${colors.greens[60]};
    stroke: ${colors.greens[60]};
    }
  }
`;

export const FrequencySection = styled.div`
  ${sharedStyles}
  flex-direction: column;
  padding-bottom: 0;
  ${media.S`
    padding: ${spacing.singleAndHalf};
    padding-bottom: 0;
  `}
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.single};
  ${media.S`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const FrequencyContainer = styled.div`
  margin-bottom: ${spacing.half};
  ${media.S`
    width: 552px;
  `}
`;

export const FrequencyDescription = styled.div`
  font-size: ${font.size.base};
  line-height: ${lineHeight.M};
  font-weight: ${font.weight.regular};
  color: ${colors.blacks[50]};
  margin-top: ${spacing.half};
  margin-bottom: ${spacing.half};
  font-style: italic;
  ${media.S`
    margin-top: 0;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  ${media.S`
    margin-top: 0;
    margin-bottom: ${spacing.half};
  `}
`;

export const StopRecurringButton = styled(Button.Simple.Primary)`
  background: ${colors.oranges[1]};
  margin: ${spacing.single};
  margin-top: 16px;
  margin-bottom: ${spacing.double};
  width: 100%;
  &:hover {
    background: ${colors.oranges[5]};
  }
  ${media.S`
    width: 552px;
    margin: ${spacing.double} ${spacing.singleAndHalf};
    margin-top: 0;
  `}
`;

export const CustomSettingsButton = styled(Clickable)`
  width: fit-content;
  font-size: ${font.size.S};
  line-height: ${lineHeight.M};
  color: ${colors.blacks[50]};
  margin-top: ${spacing.double};
  margin-bottom: ${spacing.singleAndHalf};
  ${({ isHidden }) => isHidden ? `
    display: none;
  ` : ''}
`;