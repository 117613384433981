import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import Comment from './Comment';
import {
  AVATAR_SIZE,
  ANIMATION_TIME,
  COMMENT_PLACEHOLDER,
  MAX_COMMENT_CHARACTER_LIMIT,
  COMMENT_PROP_TYPE,
  PERSON_PROP_TYPE,
} from './consts';
import { Container, CommentsContainer, WriteCommentContainer } from './styles';
import TextArea from './TextArea';
import ViewAllCommentsLink from './ViewAllCommentsLink';

export default class CommentsWithAddRemove extends React.PureComponent {
  static propTypes = {
    /** Size of the avatar in pixels. */
    avatarSize: PropTypes.number,
    /** Keeps the height of the comments list to the `numOfCommentsToShow` height. */
    autoHeight: PropTypes.bool,
    /** Additional style classes. */
    className: PropTypes.string,
    /** Placeholder text for comment. */
    commentPlaceholder: PropTypes.string,
    /** List of comments to display. */
    comments: PropTypes.arrayOf(COMMENT_PROP_TYPE),
    /** The current user. */
    currentUser: PERSON_PROP_TYPE,
    /** Additional header content. */
    header: PropTypes.node,
    /** Number of comments to initially display. */
    numOfCommentsToShow: PropTypes.number,
    /** Callback when send button is clicked. */
    onClickSend: PropTypes.func,
    /** Callback when comment avatar is clicked. */
    onClickAvatar: PropTypes.func,
    /** Callback when comment is deleted. */
    onClickDeleteComment: PropTypes.func,
  };

  static defaultProps = {
    avatarSize: AVATAR_SIZE,
    autoHeight: true,
    commentPlaceholder: COMMENT_PLACEHOLDER,
    comments: [],
    currentUser: {},
    maxHeight: 320,
    numOfCommentsToShow: 3,
    autoFocus: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      comments: props.comments,
      commentsToAdd: [],
      commentsToRemove: [],
      isAdding: false,
      isRemoving: false,
      isLoaded: !!props.comments.length,
      showAllComments: props.numOfCommentsToShow < 1,
    };
    this.ref = React.createRef();
    this.commentsRef = React.createRef();
    this._commentsLoaded = {};
    this.loadInitialComments(props.comments, true);

    this.addTimeout = null;
    this.removeTimeout = null;
  }

  resizeHeight = () => {
    const { numOfCommentsToShow } = this.props;
    const { comments, isAdding, showAllComments } = this.state;
    const shouldUpdateHeight =
      !showAllComments || comments.length < numOfCommentsToShow || isAdding;
    if (this.ref.current && shouldUpdateHeight) {
      this.setState({ maxHeight: this.ref.current.offsetHeight });
    }
    if (this.commentsRef.current) {
      this.commentsRef.current.scrollTop = 0;
    }
  };

  componentDidMount = () => {
    this.resizeHeight();
  };

  componentDidUpdate = () => {
    const { comments, isLoaded, isRemoving } = this.state;
    const { comments: commentsFromProps } = this.props;
    if (!isLoaded && !comments.length && commentsFromProps.length) {
      this.loadInitialComments(comments);
    } else if (comments.length < commentsFromProps.length) {
      this.handleAddComment();
    } else if (!isRemoving && comments.length > commentsFromProps.length) {
      this.handleRemoveComment();
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.addTimeout);
    clearTimeout(this.removeTimeout);
  };

  clearAddComment = (commentsToAdd) => {
    const newCommentsToAdd = commentsToAdd.filter(
      (comment) => !this.state.commentsToAdd.includes(comment)
    );
    this.setState({ commentsToAdd: newCommentsToAdd, isAdding: false });
    this.resizeHeight();
  };

  clearRemoveComment = (itemsToRemove) => {
    const newItemsToRemove = itemsToRemove.filter(
      (item) => !this.state.commentsToRemove.includes(item)
    );
    this.setState({
      commentsToRemove: newItemsToRemove,
      comments: this.props.comments,
      isRemoving: false,
    });
    this.resizeHeight();
  };

  handleAddComment = () => {
    const { comments } = this.props;
    const addedComments = comments.filter(
      ({ id }) => !this._commentsLoaded[id]
    );
    const commentsToAdd = addedComments.map((comment) => {
      this._commentsLoaded[comment.id] = true;
      return comment.id;
    });

    this.setState({ commentsToAdd, comments, isAdding: true });
    this.addTimeout = setTimeout(
      () => this.clearAddComment(commentsToAdd),
      ANIMATION_TIME * 1000
    );
  };

  handleRemoveComment = () => {
    const { comments } = this.state;
    const { comments: commentsFromProps } = this.props;
    const commentsLoaded = {};
    commentsFromProps.forEach(({ id }) => {
      commentsLoaded[id] = true;
    });

    const deletedComments = comments.filter(({ id }) => !commentsLoaded[id]);
    const commentsToRemove = deletedComments.map((comment) => {
      delete this._commentsLoaded[comment.id];
      return comment.id;
    });

    this.setState({ commentsToRemove, isRemoving: true });
    this.removeTimeout = setTimeout(
      () => this.clearRemoveComment(commentsToRemove, commentsFromProps),
      ANIMATION_TIME * 4000
    );
  };

  handleChangeComment = (e, commentProps) => {
    this.setState({ comment: commentProps.value });
  };

  handleClickSend = async (e, commentProps) => {
    const response = await this.props.onClickSend(e, {
      ...this.props,
      ...commentProps,
    });
    if (commentProps.value.length <= MAX_COMMENT_CHARACTER_LIMIT) {
      this.setState({ comment: '' });
    }

    return response;
  };

  handleShowAllComments = () => {
    this.setState({ showAllComments: true });
  };

  loadInitialComments = (comments, fromConstructor) => {
    comments.forEach(({ id }) => (this._commentsLoaded[id] = true));
    if (!fromConstructor) {
      this.setState({ comments, isLoaded: true });
    }
  };

  renderComments = (props, state) => {
    const {
      avatarSize,
      numOfCommentsToShow,
      onClickAvatar,
      onClickDeleteComment,
    } = props;
    const {
      comments,
      commentsToAdd,
      commentsToRemove,
      showAllComments,
    } = state;
    const commentsToShow = showAllComments
      ? comments
      : comments.slice(0, numOfCommentsToShow);
    return commentsToShow.map((comment) => {
      const { id } = comment;
      return (
        <Comment
          avatarSize={avatarSize}
          isCanAddRemove
          isBeingAdded={commentsToAdd.includes(id)}
          isBeingRemoved={commentsToRemove.includes(id)}
          comment={comment}
          key={id}
          onClickAvatar={onClickAvatar}
          onClickDelete={onClickDeleteComment}
        />
      );
    });
  };

  render() {
    const {
      avatarSize,
      className,
      commentPlaceholder,
      comments,
      currentUser,
      header,
      numOfCommentsToShow,
      autoFocus
    } = this.props;
    const { comment, maxHeight, showAllComments } = this.state;
    const listItems = this.renderComments(this.props, this.state);
    const isCommentsOverflow = comments.length > numOfCommentsToShow;

    return (
      <Container
        className={className}
        ref={this.ref}
        style={{
          maxHeight: comments.length && (!!comment || isCommentsOverflow) ? maxHeight : null,
        }}
      >
        <CommentsContainer ref={this.commentsRef}>
          {header}
          {listItems}
          {!showAllComments && isCommentsOverflow && (
            <ViewAllCommentsLink onClick={this.handleShowAllComments}>
              View All Comments
            </ViewAllCommentsLink>
          )}
        </CommentsContainer>

        <WriteCommentContainer>
          <TextArea
            avatar={
              <Avatar
                size={avatarSize}
                email={currentUser.email}
                url={currentUser.photoUrl}
              />
            }
            autoFocus={autoFocus}
            numberOfComments={comments.length}
            onClickSend={this.handleClickSend}
            onChange={this.handleChangeComment}
            placeholder={commentPlaceholder}
            value={comment}
          />
        </WriteCommentContainer>
      </Container>
    );
  }
}


