import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import RequestVerificationPage from '../components/pages/RequestVerificationPage';
import withRequestVerification from 'hocs/auth/withRequestVerification';
import withQueryParams from 'hocs/withQueryParams';
import Toast from 'components/Toast/Toast';

const withData = compose(
  withQueryParams,
  withProps(({
    queryParams,
  }) => {
    const { error } = queryParams;
    if (error) {
      Toast.error(error);
    } 
  }),
  withRequestVerification
);

const RequestVerificationPageContainer = withData(
  RequestVerificationPage
);

const REQUEST_VERIFICATION_CODE_DEFAULT_REDUX_FORM = '@auth/request-code';

RequestVerificationPageContainer.propTypes = {
  form: PropTypes.string,
  noSignup: PropTypes.bool.isRequired,
};

RequestVerificationPageContainer.defaultProps = {
  form: REQUEST_VERIFICATION_CODE_DEFAULT_REDUX_FORM,
  noSignup: true,
};

export default RequestVerificationPageContainer;
