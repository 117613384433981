import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        d="M12.27 20.5l4.11 4.15 3.925-2.15 8.075 6.385a.53.53 0 0 0 .33.115h.26c.43 0 .68-.49.43-.84L16 9.5 2.6 28.16a.532.532 0 0 0 .805.685L12.27 20.5z"
        fill="#fff"
      />
      <path d="M16.5 2h-1v11.5h1V2z" fill="#fff" />
      <path d="M22.5 7h-6.94V2h6.94l-2 2.5 2 2.5z" fill="#fff" />
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.56 2h-.06v8.196L2.6 28.16a.532.532 0 0 0 .805.685L12.27 20.5l4.11 4.15 3.925-2.15 8.075 6.385a.53.53 0 0 0 .33.115h.26c.43 0 .68-.49.43-.84L16.5 10.196V7h6l-2-2.5 2-2.5H15.56z"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);


Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
