import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../ListItem';
import WithAddRemove from './WithAddRemove';

const ANIMATION_TIME = 0.3;

/**
 * List.WithAdddRemove.Email
 * Defaults to showing email as the sub header for each item.
 */
class WithAddRemoveEmail extends WithAddRemove {
  static defaultProps = {
    ...WithAddRemove.defaultProps,
    defaultSubHeaderContent: WithAddRemove.subHeaderOptions.EMAIL,
  };
}

/**
 * List.WithAdddRemove.CustomSubHeader
 *
 * Adds `renderSubHeader` prop to render a custom sub header for each item.
 */
class WithAddRemoveCustomSubHeader extends WithAddRemove {
  static defaultProps = {
    ...WithAddRemove.defaultProps,
    renderSubHeader: () => null,
    squared: false,
  };

  static propTypes = {
    ...WithAddRemove.propTypes,
    /** Custom function to render sub header content of item. */
    renderSubHeader: PropTypes.func,
    squared: PropTypes.bool,
  };

  renderListItems = (props, state) => {
    const {
      defaultAvatarIsGenerated,
      defaultSubHeaderContent,
      flushedEdge,
      flushedEdgeOnMobile,
      onClickPeer,
      renderPeerActions,
      renderProfileCard,
      renderSubHeader,
      squared,
      avatarHeight,
      avatarWidth,
      customAvatar,
      largeText,
    } = props;
    const { items, itemsToAdd, itemsToRemove } = state;
    return items.map((peer) => {
      const { showCustomAvatar, disabled, email } = peer;
      
      return (
        <ListItem
          actions={renderPeerActions(peer)}
          animationTime={ANIMATION_TIME}
          defaultAvatarIsGenerated={defaultAvatarIsGenerated}
          defaultSubHeaderContent={defaultSubHeaderContent}
          flushedEdge={flushedEdge}
          flushedEdgeOnMobile={flushedEdgeOnMobile}
          isCanAddRemove
          isBeingAdded={itemsToAdd.includes(email) || itemsToAdd.includes(peer.id)}
          isBeingRemoved={itemsToRemove.includes(email) || itemsToAdd.includes(peer.id)}
          key={`${email || peer.id}-peer`}
          onClick={onClickPeer}
          peer={peer}
          disabled={disabled}
          renderProfileCard={renderProfileCard}
          subHeader={renderSubHeader({ ...props, peer })}
          squared={squared}
          largeText={largeText}
          avatarHeight={avatarHeight}
          avatarWidth={avatarWidth}
          customAvatar={showCustomAvatar ? customAvatar : null}
        />
      );
    });
  };
}

export { WithAddRemoveEmail, WithAddRemoveCustomSubHeader };
