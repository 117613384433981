import styled from 'styled-components';
import Button from '../../Button';
import { Clickable } from '../../Clickable/Clickable';
import Tooltip from '../../Tooltip';
import {
  border,
  colors,
  font,
  lineHeight,
  spacing,
  transitionTime,
} from '@matterapp/matter-theme';

const TRANSITION_TIME = transitionTime.default;
const TRANSITION_TIME_LONG = transitionTime.quadruple;
const TRANSITION_TIME_LONGER = transitionTime.quintuple;

export const AvatarContainer = styled.div` //TODO: change this back once we handle clicks
  margin-right: ${spacing.single};
`;

export const AuthorFullName = styled.div` //TODO: change this back once we handle clicks
  display: inline;
  font-weight: ${font.weight.semiBold};
`;

export const MenuTrigger = styled(Button.Icon).attrs(() => ({
  iconName: 'moreVertical',
}))`
  left: calc(${spacing.half} - ${spacing.eighth});
  margin: -${spacing.half} 0 0 ${spacing.single};
  padding: 0;
  position: relative;
  width: ${spacing.singleAndHalf};

  &:not(:hover):not(:active) svg path {
    fill: ${colors.blacks[30]};
  }
`;

export const MenuItem = styled(Clickable)`
  color: ${colors.blacks[30]};
  filter: opacity(75%);
  display: flex;
  align-items: center;
  font-size: ${font.size.base};
  min-height: ${spacing.singleAndThreeQuarters};
  padding: ${spacing.quarter} ${spacing.half};
  border-radius: 2px;
  width: 100%;
  transition: all ${TRANSITION_TIME};

  & svg {
    margin-right: ${spacing.half};
    path {
      fill: ${colors.blacks[30]};
    }
  }

  &:hover {
    filter: opacity(100%);
    background: ${colors.blacks[70]};
  }

  &:active {
    color: ${colors.white};
    & svg {
      path {
        fill: ${colors.white};
      }
    }
  }
`;

export const MenuContents = styled.div`
  padding: ${spacing.half};
`;

export const MenuPopup = styled(Tooltip).attrs(() => ({
  arrowSize: 8,
  distanceFromTrigger: 0,
  position: ['left'],
  style: {
    borderRadius: border.radius.M,
  },
}))``;

export const MessageHeader = styled.div``;

export const MessageContainer = styled.div`
  word-break: break-word;
`;

export const TimeAgoContainer = styled.div`
  color: ${colors.blacks[50]};
  display: inline;
  line-height: ${lineHeight.S};
  font-size: ${font.size.base};
`;

export const ContentContainer = styled.div`
  border-radius: ${border.radius.M};
  flex: 1;
  color: ${colors.blacks[90]};
  font-size: ${font.size.base};
  line-height: ${lineHeight.S};
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  background: transparent;
  padding: ${spacing.threeQuarters} ${spacing.singleAndHalf};
  min-width: 200px;
  white-space: pre-line;

  &:first-child {
    padding-top: ${spacing.singleAndHalf};
  }

  &:last-child {
    padding-bottom: ${spacing.singleAndHalf};
  }

  ${({ isCanAddRemove, isBeingAdded, isBeingRemoved }) => {
    if (isCanAddRemove) {
      if (isBeingAdded) {
        return `
          & > ${AvatarContainer}, & ${MenuTrigger} {
            opacity: 0;
            transition: opacity 0s;
          }
          & > ${ContentContainer} {
            background: ${colors.yellows[1]};
            opacity: 0;
            margin: -${spacing.quarter} -${spacing.quarter} -${spacing.quarter} -${spacing.half};
            padding: ${spacing.quarter} ${spacing.half} ${spacing.quarter};
            transition: background 0s, opacity 0s, margin 0s, padding 0s;
          }
      `;
      }
      if (isBeingRemoved) {
        return `
          & > ${AvatarContainer}, & ${MenuTrigger} {
            opacity: 0;
            transition: opacity ${TRANSITION_TIME} ${TRANSITION_TIME_LONG};
          }
          & > ${ContentContainer} {
            background: ${colors.yellows[1]};
            opacity: 0;
            margin: -${spacing.quarter} -${spacing.quarter} -${spacing.quarter} -${spacing.half};
            padding: ${spacing.quarter} ${spacing.half} ${spacing.quarter};
            transition: background ${TRANSITION_TIME}, opacity ${TRANSITION_TIME} ${TRANSITION_TIME_LONG};
          }
      `;
      }
      return `
        & > ${AvatarContainer}, & ${MenuTrigger} {
          opacity: 1;
          transition: opacity ${TRANSITION_TIME};
        }
        & > ${ContentContainer} {
          background: transparent;
          opacity: 1;
          transition: all ${TRANSITION_TIME} ${TRANSITION_TIME_LONGER}, opacity ${TRANSITION_TIME};
        }
      `;
    }
  }}
`;
