export function getValueMap(arr) {
  return arr.map(a => {
    const arrObject = { label: a, value: a };
    return arrObject;
  });
}

export const TABS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
};

export const filters = Object.values(TABS);

export const filtersMap = getValueMap(filters);

export const RIGHT_DIV = 'right-div';

export const TABLE_HEADERS = {
  NAME: 'Name',
  DATE: 'Request Date',
  REWARD: 'Reward',
  VALUE: 'Value',
  APPROVE: 'Approve?',
  DECLINE: 'Declined By',
  STATUS: 'Status'
};