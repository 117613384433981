import styled from 'styled-components';
import { colors, lineHeight, font, spacing } from '@matterapp/matter-theme';
import { Box, Tab, Tabs, Typography } from '@mui/material';

export const NameContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '105px'
  }
}));

export const RewardsHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(3)
  }
}));

export const NameTextContainer = styled.div`
  font-size: ${font.size.S};
  line-height: ${lineHeight.M};
  font-weight: ${font.weight.semiBold};
  color: ${colors.blacks[100]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AvatarContainer = styled.div`
  display: inline;
  margin-right: ${spacing.single};
  width: ${spacing.triple};
  height: ${spacing.triple};
`;

export const LoaderContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  minHeight: '160px'
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  maxWidth: 'none',
  color: theme.palette.blacks[60],
  fontWeight: 500,
  fontSize: '17px',
  lineHeight: '32px',
  textTransform: 'none',
  '&.Mui-selected': {
    color: theme.palette.blues[60],
    borderBottom: 'none'
  }
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.blues[60],
    height: '4px'
  }
}));
