import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import TextContainer from './TextContainer';
import Heading from './Heading';

const Container = styled(TextContainer)`
  margin-bottom: ${(props) => props.marginBottom}px;

  &:first-child {
    margin-top: ${() => theme.sizes.padding[20]};
  }

  ${() => theme.media.medium`
    &:first-child {
      margin-top: ${() => theme.sizes.padding[60]};
    }
  `};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 0px;
  font-weight: ${(props) => props.headingFontWeight};
  ${({ headingsAreInline }) => headingsAreInline && `display: inline;`};
`;

const StyledSubheading = styled(Heading)`
  font-weight: normal;
  font-weight: ${(props) => props.subHeadingFontWeight};
  ${({ headingsAreInline }) => headingsAreInline && `display: inline;`};
`;

const HeadingBlock = styled(
  ({
    className,
    heading,
    headingsAreInline,
    headingFontWeight,
    subheading,
    subHeadingFontWeight,
    marginBottom,
  }) => (
    <Container marginBottom={marginBottom} className={className}>
      <StyledHeading
        headingFontWeight={headingFontWeight}
        headingsAreInline={headingsAreInline}
      >
        {heading}
      </StyledHeading>
      {subheading && (
        <StyledSubheading
          subHeadingFontWeight={subHeadingFontWeight}
          headingsAreInline={headingsAreInline}
        >
          {headingsAreInline && ' '}
          {subheading}
        </StyledSubheading>
      )}
    </Container>
  )
)``;

HeadingBlock.defaultProps = {
  headingsAreInline: false,
  headingFontWeight: 'bold',
  subheading: null,
  marginBottom: 24,
  className: null,
};

HeadingBlock.propTypes = {
  /** The value of the heading to display. */
  heading: PropTypes.string.isRequired,
  /** Displays heading and subheading inline. */
  headingsAreInline: PropTypes.bool,
  /** Font weight value of the heading. */
  headingFontWeight: PropTypes.oneOf(['normal', 'bold']),
  /** The value of the subheading to display. */
  subheading: PropTypes.string,
  /** Amount of margin to apply below heading. */
  marginBottom: PropTypes.number,
  /** Additional class. */
  className: PropTypes.string,
};

export default HeadingBlock;
