import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 0 1em;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Field = ({
  children,
}) => (
  <Container className="field">
    {children}
  </Container>
);

Field.defaultProps = {
  children: null,
};

Field.propTypes = {
  children: PropTypes.node,
};

export default Field;
