import React from 'react';
import PropTypes from 'prop-types';
import { SmallScreen, PlusSizeScreen } from '../../Screen';

import HeaderContainer from './HeaderContainer';

const HeaderStackedText = ({ first, second, stacked }) => {
  const stackedText = (
    <>
      <div>
        <strong>{first}</strong>
      </div>
      <div>{second}</div>
    </>
  );
  return stacked ? (
    <HeaderContainer>{stackedText}</HeaderContainer>
  ) : (
    <HeaderContainer>
      <SmallScreen>{stackedText}</SmallScreen>
      <PlusSizeScreen>
        <div>
          <strong>{first}:</strong> {second}
        </div>
      </PlusSizeScreen>
    </HeaderContainer>
  );
};

HeaderStackedText.propTypes = {
  first: PropTypes.string.isRequired,
  second: PropTypes.string.isRequired,
  stacked: PropTypes.bool,
};

export default HeaderStackedText;
