import { Resources } from '@matterapp/routing';
const { REACT_APP_CUSTOM_ENV } = WEBPACK_ENV_VARS;

const ALL_ENV_VARS = {
  development: {
    INTERCOM_APP_ID: 'aksoxg83',
    CONNECT_TO_DEV_TOOLS: true,
    LOG_LEVELS: 'ENABLE_ALL',
    IS_DEBUG_AB_TEST: true,
    GOOGLE_CLIENT_ID:
      '1055107476344-b9bv47uo6qcf4k3dbu1segni55u6211l.apps.googleusercontent.com',
    MICROSITE_HOST: null,
    WEB_CLIENT_HOST: 'https://localhost',
    GRAPHQL_SERVER_HOST: '', // Relative URL
    DEBUG_MIXPANEL: true,
    MIXPANEL_TOKEN: '63963ac8406d66a6500c8a75bdf2e4ba',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_X5EUAeGzsmHDz1PMJxTmNkaJ00o0VWpcz5',
    SLACK_APP_ID: 'A025WSASVHB',
    FINCH_CLIENT_ID: 'b7156733-e141-4909-9445-f19a206ac8f7'
  },

  staging: {
    INTERCOM_APP_ID: 'aksoxg83',
    GRAPHQL_ENDPOINT_URI: 'https://api.strengths.io/graphql',
    CONNECT_TO_DEV_TOOLS: true,
    LOG_LEVELS: 'ENABLE_ALL',
    IS_DEBUG_AB_TEST: true,
    GOOGLE_CLIENT_ID:
      '1055107476344-b9bv47uo6qcf4k3dbu1segni55u6211l.apps.googleusercontent.com',
    MICROSITE_HOST: null,
    WEB_CLIENT_HOST: 'https://app.strengths.io',
    GRAPHQL_SERVER_HOST: 'https://api.strengths.io',
    DEBUG_MIXPANEL: true,
    MIXPANEL_TOKEN: 'e9e674a95af629e80c3b4014506325fb',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_X5EUAeGzsmHDz1PMJxTmNkaJ00o0VWpcz5',
    SLACK_APP_ID: 'A0298CE13A6',
    FINCH_CLIENT_ID: 'f29813db-0b4b-42f8-a1f2-92cb57bd4726'
  },

  test: {
    INTERCOM_APP_ID: 'aksoxg83',
    CONNECT_TO_DEV_TOOLS: true,
    LOG_LEVELS: 'ENABLE_ALL',
    IS_DEBUG_AB_TEST: false,
    GOOGLE_CLIENT_ID:
      '1055107476344-b9bv47uo6qcf4k3dbu1segni55u6211l.apps.googleusercontent.com',
    MICROSITE_HOST: null,
    WEB_CLIENT_HOST: 'http://localhost:3000',
    GRAPHQL_SERVER_HOST: 'http://localhost:4000',
    DEBUG_MIXPANEL: true,
    MIXPANEL_TOKEN: 'test',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_X5EUAeGzsmHDz1PMJxTmNkaJ00o0VWpcz5',
  },

  production: {
    INTERCOM_APP_ID: 'evr373n0',
    GRAPHQL_ENDPOINT_URI: 'https://api.matterapp.com/graphql',
    CONNECT_TO_DEV_TOOLS: false,
    LOG_LEVELS: 'DISABLE_ALL',
    IS_DEBUG_AB_TEST: false,
    GOOGLE_CLIENT_ID:
      '1055107476344-hpvo3u0ea9cc7nuq0ibghs6p6if78c3r.apps.googleusercontent.com',
    MICROSITE_HOST: 'https://matterapp.com',
    WEB_CLIENT_HOST: 'https://app.matterapp.com',
    GRAPHQL_SERVER_HOST: 'https://api.matterapp.com',
    DEBUG_MIXPANEL: false,
    MIXPANEL_TOKEN: 'ff6c21669ff53f59806fe06fbf4341a6',
    STRIPE_PUBLISHABLE_KEY: 'pk_live_lXLCEilM5XXzcD1wqoHjcmU500LYqGOHmx',
    SLACK_APP_ID: 'A01JBGHLMA7',
    FINCH_CLIENT_ID: 'eaae608a-6c20-4a3d-b0dd-a26ecc977aee'
  },
};

const currentEnv = REACT_APP_CUSTOM_ENV || process.env.NODE_ENV || 'development';
const currentEnvVars = ALL_ENV_VARS[currentEnv];

currentEnvVars.CURRENT_ENV = currentEnv;
// Set the API endpoint
const gqlHost = currentEnvVars.GRAPHQL_SERVER_HOST;
currentEnvVars.GRAPHQL_ENDPOINT_URI = `${gqlHost}/graphql`;
// Set a couple of test-specific endpoints
if (currentEnv == 'test') {
  Object.assign(currentEnvVars, {
    EMAIL_AGGREGATES_URI: `${gqlHost}/email-aggregates`,
    // Lambdas are only called directly in test mode
    // No need to configure lambda server host for all envs
    LAMBDAS_ALL_TEST_EMAILS_AGGREGATES_URI: 'http://localhost:3001/api/get-all-test-emails'
  });
}
if (currentEnv == 'production') {
  currentEnvVars.LOGGED_OUT_PATH = currentEnvVars.MICROSITE_HOST;
  currentEnvVars.SIGN_UP_PATH = currentEnvVars.MICROSITE_HOST;
}
else {
  currentEnvVars.LOGGED_OUT_PATH = Resources.testAuth.path();
  currentEnvVars.SIGN_UP_PATH = Resources.testAuth.path();
}


Resources.config({
  Microsite: currentEnvVars.MICROSITE_HOST,
  WebClient: currentEnvVars.WEB_CLIENT_HOST,
  GraphQLServer: currentEnvVars.GRAPHQL_SERVER_HOST,
});

export const ENV_VARS = Object.create(null);

Object.assign(ENV_VARS, currentEnvVars);
