import React from 'react';
import styled from 'styled-components';
import { YellowCard, Modal } from '@matterapp/matter-ui';
import FeedItemsList from 'modules/feed/FeedItemsList';
import PropTypes from 'prop-types';

const StyledFeedbackContainer = styled.div`
  .matter-feed-item-container {
    margin-bottom: 0 !important;
  }
`;

export class SentFeedbackYellowCard extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    feedback: PropTypes.object.isRequired
  };

  renderFooter() {
    const { handleClose } = this.props;
    return (
      <Modal.Footer.WithClose onClickClose={handleClose} />
    );
  }

  renderFeedItem() {
    const { feedback } = this.props;

    return (
      <StyledFeedbackContainer>
        <FeedItemsList 
          feedItemsList={[feedback]} 
          feedItemsProps={{ hideFooter: true, expanded: true, showMenu: false, hideBottomMargin: true }}
        />
      </StyledFeedbackContainer>
    );
  }

  render() {
    const { isOpen, handleClose } = this.props;

    return (
      <YellowCard
        isOpen={isOpen}
        handleRequestClose={handleClose}
        minimal
        footer={this.renderFooter()}
        body={this.renderFeedItem()}
      />
    );
  }
}