import React from 'react';
import { Composer } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import OutOfKudosModal from 'modules/workspaces/shared/OutOfKudosModal/OutOfKudosModal';

/**
 * Kudos modal
 * Originally combined with the Kudos Sent Modal.
 *
 * Please use the `useGiveKudos` or `useGiveKudosWorkspaces
 * hook from 'hooks/composer' and pass in the 'kudosModalProps'
 * & 'sentModalProps' props.
 */
const KudosModal = (props) => {
  const { 
    isFree,
    kudosLeft, 
    isOpen,
    onClose, 
    isAdminMember,
    onClickUpgrade
  } = props;

  if (isFree && kudosLeft <= 0) {
    return (
      <OutOfKudosModal 
        isOpen={isOpen} 
        onClose={onClose} 
        isAdminMember={isAdminMember}
        onClickUpgrade={onClickUpgrade}
      />
    );
  }

  return (
    <Composer.Main.Kudos.Modal {...props} />
  );
};

KudosModal.defaultProps = {
  kudosModalProps: {},
  sentModalProps: {},
};

KudosModal.propTypes = {
  kudosModalProps: PropTypes.shape(Composer.Main.Kudos.Modal.propTypes),
  sentModalProps: PropTypes.shape(Composer.KudosSentModal.propTypes),
  isFree: PropTypes.bool,
  kudosLeft: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isAdminMember: PropTypes.bool,
  onClickUpgrade: PropTypes.func,
};

export default KudosModal;
