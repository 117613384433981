// Lodash's doesnt always call, so use RAF here.
export default function rafDebounce(fn) {
  let timerId = null;

  function start(arg) {
    cancelAnimationFrame(timerId);
    timerId = requestAnimationFrame(() => fn(arg));
  }

  return start;
}
