import React from 'react';
import PropTypes from 'prop-types';
import { Button, Loader } from '@matterapp/matter-ui';
import { GET_SLACK_OAUTH_URL } from 'graphql-queries/queries';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  position: relative;
  min-height: 100px;
  width: 100px;
`;

export default function SlackAuthButton(props) {
  const { buttonText, iconName, className } = props;
  const variables = {
    inapp: false,
  };
  if (document.location.href.includes('login')) {
    variables.clientOrigin = 'login';
  }
  const { loading, data } = useQuery(GET_SLACK_OAUTH_URL, { variables });

  const startSlackAuthFlow = () => {
    const { slackOAuthURL } = data;

    window.location.href = slackOAuthURL;
  };

  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <Button.Social
      primary
      className={className}
      icon={iconName}
      buttonText={buttonText}
      onClick={startSlackAuthFlow}
    />
  );
}

SlackAuthButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  className: PropTypes.string,
};
