import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';

const StackContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${({ isStackedOnMobile }) =>
    isStackedOnMobile ? 'column' : 'row'};
  align-items: flex-start;

  button {
    margin-top: 8px;
    margin-left: 8px;
    vertical-align: top;

    &:first-child {
      margin-left: 0;
    }
  }

  ${() => theme.media.medium`
    button {
      margin-top: 0 !important;
      margin-left: 8px !important;
    }

    flex-direction: row;
    align-items: center;
  `}

  ${() => theme.media.large`
    flex-direction: row;
    align-items: center;
  `}
`;

StackContainer.propTypes = {
  isStackedOnMobile: PropTypes.bool.isRequired,
};

export default StackContainer;
