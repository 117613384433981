import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  margin-top: ${theme.spacing.double};
`;

const Header = styled.div`
  color: ${theme.colors.blacks[70]};
  font-size: ${theme.fontSize.M};
  font-weight: ${theme.fontWeight.bold};
  padding: 0 ${theme.spacing.singleAndHalf};
  text-align: center;
`;

const SubHeader = styled.div`
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.S};
  padding: 0 ${theme.spacing.singleAndHalf};
  text-align: center;
`;

const CTA = (props) => {
  const { header, subHeader } = props;
  return (
    <Container>
      {header && <Header>{header}</Header>}
      {subHeader && <SubHeader>{subHeader}</SubHeader>}
    </Container>
  );
};

CTA.propTypes = {
  header: PropTypes.node,
  subHeader: PropTypes.node,
};

export default CTA;
