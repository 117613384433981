import React from 'react';
import { SVG, Path } from './consts';

const Analytics = (props) => (
  <SVG {...props}>
    <Path d="M0 17.7704C0 17.4636 0.248731 17.2148 0.555556 17.2148H19.4444C19.7513 17.2148 20 17.4636 20 17.7704V18.6374C20 18.9442 19.7513 19.1929 19.4444 19.1929H0.555556C0.248731 19.1929 0 18.9442 0 18.6374V17.7704Z" fill="white" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M16.667 4.11111C17.158 4.11111 17.5559 3.71314 17.5559 3.22222C17.5559 2.7313 17.158 2.33333 16.667 2.33333C16.1761 2.33333 15.7782 2.7313 15.7782 3.22222C15.7782 3.71314 16.1761 4.11111 16.667 4.11111ZM16.667 5.44444C17.8943 5.44444 18.8893 4.44952 18.8893 3.22222C18.8893 1.99492 17.8943 1 16.667 1C15.4397 1 14.4448 1.99492 14.4448 3.22222C14.4448 4.44952 15.4397 5.44444 16.667 5.44444Z" fill="#6B6B6F" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M12.2222 11.8885C12.7131 11.8885 13.1111 11.4905 13.1111 10.9996C13.1111 10.5086 12.7131 10.1107 12.2222 10.1107C11.7313 10.1107 11.3333 10.5086 11.3333 10.9996C11.3333 11.4905 11.7313 11.8885 12.2222 11.8885ZM12.2222 13.2218C13.4495 13.2218 14.4444 12.2269 14.4444 10.9996C14.4444 9.77227 13.4495 8.77734 12.2222 8.77734C10.9949 8.77734 10 9.77227 10 10.9996C10 12.2269 10.9949 13.2218 12.2222 13.2218Z" fill="#6B6B6F" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M6.66705 7.44448C7.15797 7.44448 7.55594 7.04652 7.55594 6.5556C7.55594 6.06468 7.15797 5.66671 6.66705 5.66671C6.17613 5.66671 5.77816 6.06468 5.77816 6.5556C5.77816 7.04652 6.17613 7.44448 6.66705 7.44448ZM6.66705 8.77782C7.89435 8.77782 8.88927 7.7829 8.88927 6.5556C8.88927 5.3283 7.89435 4.33337 6.66705 4.33337C5.43975 4.33337 4.44482 5.3283 4.44482 6.5556C4.44482 7.7829 5.43975 8.77782 6.66705 8.77782Z" fill="#6B6B6F" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M7.70221 8.52201C8.24242 8.23703 8.65098 7.73601 8.81293 7.13392L11.1873 9.03345C10.647 9.31827 10.2383 9.81918 10.0762 10.4212L7.70221 8.52201ZM4.96084 7.97939C5.34536 8.4395 5.91254 8.74169 6.55091 8.77449L5.03934 11.7976C4.65479 11.3376 4.08759 11.0354 3.44922 11.0026L4.96084 7.97939ZM14.8844 4.54932C15.2448 5.03237 15.7968 5.36399 16.4279 5.43144L14.0043 9.67282C13.6439 9.18984 13.0917 8.85831 12.4606 8.79097L14.8844 4.54932Z" fill="#6B6B6F" />
    <Path fillRule="evenodd" clipRule="evenodd" d="M3.33355 14.1111C3.82447 14.1111 4.22244 13.7131 4.22244 13.2222C4.22244 12.7313 3.82447 12.3333 3.33355 12.3333C2.84263 12.3333 2.44466 12.7313 2.44466 13.2222C2.44466 13.7131 2.84263 14.1111 3.33355 14.1111ZM3.33355 15.4444C4.56085 15.4444 5.55577 14.4495 5.55577 13.2222C5.55577 11.9949 4.56085 11 3.33355 11C2.10625 11 1.11133 11.9949 1.11133 13.2222C1.11133 14.4495 2.10625 15.4444 3.33355 15.4444Z" fill="#6B6B6F" />
  </SVG>
);

export default Analytics;