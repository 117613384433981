import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { TextArea } from '@matterapp/matter-ui';

const InputWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%'
}));

const Wrapper =  styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(3)} 0`
}));

const StyledInput = styled(TextArea.WithCount)(({ theme }) => ({
  fontWeight: theme.fontWeight.regular,
  minHeight: '100px'
}));

export default function TextTypeQuestion({ 
  canSkip,
  question, 
  index, 
  onChange, 
  values,
  isAnonymous
}) {
  const { answers = [], showErrors } = values || {};
  const [answer] = answers;
  const hasError = canSkip ? false : showErrors && !answer?.trim();

  return (
    <Wrapper>
      <Typography variant="h5" component='p' data-rc={hasError ? 'question-title-with-error' : 'question-title'} color={hasError ? 'error': 'black.main'}>
        Q{index + 1} - {question}
      </Typography>
      <InputWrapper>
        <StyledInput
          onChange={(e) => {
            onChange(e.target.value);
          }}
          rcLabel='question-text-input'
          value={answer}
          maxLength={1500}
          allowErrorBeforeBlur
          showErrorMessageBelow
          placeholder={isAnonymous ? 'Write your anonymous comment here' : 'Write your comment here'}
          error={hasError && !answer}
        />
      </InputWrapper>
    </Wrapper>
  );
}
