import { withProps, compose } from 'recompose';
import { connect } from 'react-redux';
import { isPristine } from 'redux-form';
import {
  setQuickNoteCurrentPeer,
  resetQuickNote,
  tagQuickNoteSkill,
  resetTaggedQuickNoteSkill,
} from '../../../reducers';
import {
  withToggle,
} from 'hocs';
import withCurrentUser from 'context/UserContext/withCurrentUser';
import QuickNoteFeedbackProvider from '../components/QuickNoteFeedbackProvider';
import { QUICK_NOTE_FEEDBACK_REDUX_FORM } from '../consts';
import { mixpanel } from 'libs';

const handleTrack = {
  feedbackOpened: mixpanel.createEventSender('quickNoteFeedback:opened'),
};

const withConfirmDiscard = withToggle({
  stateName: 'isDiscardConfirmActive',
  handleToggleOpenName: 'handleActivateDiscardConfirm',
  handleToggleCloseName: 'handleDeactivateDiscardConfirm',
  initialIsOpen: false,
});

const withConfirmSubmissionFailed = withToggle({
  stateName: 'isSubmissionFailedActive',
  handleToggleOpenName: 'handleActivateSubmissionFailed',
  handleToggleCloseName: 'handleDeactivateSubmissionFailed',
  initialIsOpen: false,
});

const withData = compose(
  withConfirmDiscard,
  withConfirmSubmissionFailed,
  withCurrentUser,
  connect(
    (state) => ({
      currentPeer: state.quickNote.currentPeer,
      taggedSkill: state.quickNote.taggedSkill,
    }),
    (dispatch) => ({
      handleSetCurrentPeer: (currentPeer) => {
        dispatch(setQuickNoteCurrentPeer(currentPeer));
        handleTrack.feedbackOpened();
      },
      handleResetQuickNote: () => dispatch(resetQuickNote()),
      handleTagSkill: (skill) => dispatch(tagQuickNoteSkill(skill)),
      handleResetTaggedSkill: () => dispatch(resetTaggedQuickNoteSkill()),
    })
  ),
  withProps(({ currentPeer }) => ({
    isQuickNoteFormActive: !!currentPeer,
  })),
  withProps(({ handleResetQuickNote, handleDeactivateDiscardConfirm }) => ({
    handleResetAll: () => {
      handleResetQuickNote();
      handleDeactivateDiscardConfirm();
    },
  })),
  connect(
    (state, { taggedSkill, handleActivateDiscardConfirm, handleResetAll }) => ({
      handleRequestDiscard: () => {
        if (isPristine(QUICK_NOTE_FEEDBACK_REDUX_FORM)(state) && !taggedSkill) {
          handleResetAll();
        } else {
          handleActivateDiscardConfirm();
        }
      },
    })
  ),
  withProps(({ handleResetAll }) => ({
    handleConfirmDiscard: handleResetAll,
  }))
);

export default withData(QuickNoteFeedbackProvider);
