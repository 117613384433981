// @flow
import React from 'react';
import PropTypes from 'prop-types';
import DragAndDropList from '../DragAndDropList';

/**
 * Simple single list component extending DragAndDropList.
 * Allows an array of item ids to be passed to automatically create the list
 * by assigning each item an id and key and passing down common props.
 * The `renderItem` prop is called to get the contents of each item.
 * The `onReorder` prop callback returns the newly ordered list of ids. 
 */

export default class DragAndDrop extends React.Component {
  static propTypes = {
    /** The list of item ids which will call `renderItem` to render the specific item of the given id. */
    itemIds: PropTypes.array,
    /** The id for the droppable component. */
    listId: PropTypes.string.isRequired,
    /**
     * Function when reordering items.
     *
     * ```
     * onReorder(updatedProps = { itemIds, ...props }) => void
     * ```
     * @param { Object } updatedProps: {
     *   ... Props passed to the component.
     *   @param { Array } itemIds: The newly order array of item ids.
     * }
     * @returns { void }
     */
    onReorder: PropTypes.func,
    /**
     * Renders the contents of the item

     * ```
     * renderItem(renderProps = {
     *   ...props,
     *   id,
     *   index,
     * }) => Contents
     * ```
     * @param { Object } renderProps: {
     *   @param { String } id: The id of the item to render.
     *   @param { Number } index: The index of the item rendered.
     * }
     * @returns { React.Component } The contents of the item.
     */
    renderItem: PropTypes.func,
    /** Show drag handle to move items. If false, entire item is draggable. */
    showHandles: PropTypes.bool,
    /** Only allow vertical grab movements. */
    verticalDragOnly: PropTypes.bool,
  };

  static defaultProps = {
    itemIds: [],
    onReorder: () => {},
    renderItem: () => {},
    showHandles: true,
    verticalDragOnly: true,
  };

  static handleMove = DragAndDropList.handleMove;
  static handleReorder = DragAndDropList.handleReorder;
  static Droppable = DragAndDropList.Droppable;
  static Draggable = DragAndDropList.Draggable;

  handleReorder = (changeProps) => {
    const { itemIds, onReorder } = this.props;
    const newItemIds = DragAndDropList.handleReorder(changeProps, itemIds);
    onReorder({ ...this.props, itemIds: newItemIds });
  };

  renderContents = () => {
    const {
      itemIds,
      listId,
      renderItem,
      showHandles,
      verticalDragOnly,
    } = this.props;
    return (
      <DragAndDropList.Droppable id={`${listId}-${itemIds.length}`}>
        {itemIds.map((id, index) => (
          <DragAndDropList.Draggable
            id={`item-${id}`}
            key={`draggable-${id}-${index}`}
            index={index}
            showHandles={showHandles}
            verticalDragOnly={verticalDragOnly}
          >
            {renderItem({ ...this.props, id, index })}
          </DragAndDropList.Draggable>
        ))}
      </DragAndDropList.Droppable>
    );
  };

  render() {
    return (
      <DragAndDropList {...this.props} onReorder={this.handleReorder}>
        {this.renderContents()}
      </DragAndDropList>
    );
  }
}
