"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.padding = exports.spacing = void 0;
const spacing = {
  eighth: '2px',
  quarter: '4px',
  half: '8px',
  threeQuarters: '12px',
  single: '16px',
  singleAndQuarter: '20px',
  singleAndHalf: '24px',
  singleAndThreeQuarters: '28px',
  double: '32px',
  doubleAndQuarter: '36px',
  doubleAndHalf: '40px',
  doubleAndThreeQuarters: '44px',
  triple: '48px',
  tripleAndHalf: '56px',
  quad: '64px',
  // Shorthand for quadruple.
  quadruple: '64px',
  quadrupleAndHalf: '72px',
  quintuple: '80px',
  quintupleAndHalf: '88px',
  sextuple: '96px',
  sextupleAndHalf: '104px',
  septuple: '112px',
  septupleAndHalf: '120px'
};
exports.spacing = spacing;
const padding = {
  base: '20px',
  S: '24px'
};
exports.padding = padding;
var _default = spacing;
exports.default = _default;