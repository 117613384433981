import React from 'react';
import styled from 'styled-components';
import Inline from './Inline';
import theme from '@matterapp/matter-theme';

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  overflow: hidden;

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }
  .loader {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 4px solid rgba(153, 153, 153, 0.2);
    border-right: 4px solid rgba(153, 153, 153, 0.2);
    border-bottom: 4px solid rgba(153, 153, 153, 0.2);
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: ${theme.colors.blue};
    transform: translateZ(0);
    animation: loadingSpinner 1.1s infinite linear;
  }
  @-webkit-keyframes loadingSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loadingSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = () => (
  <LoaderContainer className="loader-container">
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <div className="loader">Loading...</div>
    </div>
  </LoaderContainer>
);

Loader.Inline = Inline;

export default Loader;
