import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="48" height="24" viewBox="0 0 48 24">
    <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M 1.11054 0.00621348C 0.561621 -0.0548217 0.0672061 0.340686 0.00620145 0.889758C -0.0547727 1.43859 0.340766 1.93297 0.889655 1.99401L 18.8897 3.99401L 19.0001 4.00621L 19.1105 3.99401L 37.1105 1.99401C 37.6594 1.93297 38.055 1.43859 37.994 0.889758C 37.933 0.340686 37.4386 -0.0548217 36.8897 0.00621348L 19.0001 1.99401L 1.11054 0.00621348ZM 1.11054 6.00621C 0.561621 5.94518 0.0672061 6.34069 0.00620145 6.88976C -0.0547727 7.43859 0.340766 7.93297 0.889655 7.99401L 18.8897 9.99401L 19.0001 10.0062L 19.1105 9.99401L 37.1105 7.99401C 37.6594 7.93297 38.055 7.43859 37.994 6.88976C 37.933 6.34069 37.4386 5.94518 36.8897 6.00621L 19.0001 7.99401L 1.11054 6.00621Z" transform="translate(4.99991 6.99976)" fill={fill} />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
