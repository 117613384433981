import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import GlobalStyles from '../../GlobalStyles';

const BaseThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      {children}
    </>
  </ThemeProvider>
);

BaseThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseThemeProvider;