import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Menu } from '../Menu';
import { AdvisorPropType } from './consts';
import { generateRecurrenceTooltip } from './helpers';
import {
  ListContainer,
  HeaderAddButton,
  RecurringHeader,
  MembersBody,
  MemberListItem,
  DescriptionContainer,
  RecurringListContainer,
  EditButton,
  RECURRING_LIST_ITEM_CLASSNAME,
  RECURRING_LIST_HEADER_CLASSNAME,
} from './sharedStyles';
import { 
  AddButtonIcon,
  menuIconMap,
  editRecurring
} from './icons';

export default class RecurringList extends React.Component {
  static propTypes = {
    /** List of recurring advisors. */
    recurringList: PropTypes.arrayOf(AdvisorPropType),
    /** Function to select an advisor. */
    handleSelectMember: PropTypes.func.isRequired,
    /** Function to select an action. */
    handleSelectAction: PropTypes.func.isRequired,
    /** Function to open info modal. */
    handleOpenInfoModal: PropTypes.func.isRequired,
  }
  
  handleNameOrAvatarClick = (member) => {
    const { handleSelectMember, handleOpenInfoModal } = this.props;
    handleSelectMember(member);
    handleOpenInfoModal();
  };

  handleOpenEditRecurringModal = (member) => {
    const { handleSelectMember, handleOpenEditRecurringModal } = this.props;
    handleSelectMember(member);
    handleOpenEditRecurringModal(member);
  }

  renderRecurringListHeader() {
    if (this.props.hasAcceptedMembers) {
      return (
        <RecurringHeader
          className={RECURRING_LIST_HEADER_CLASSNAME}
          title="Continuous"
          size="large"
          actionLabel={
            <HeaderAddButton>
              <AddButtonIcon />
            </HeaderAddButton>
          }
          onActionClick={() => this.props.handleOpenRecurringModal()}
        />
      );
    }
  }

  renderMemberDescription(member) {
    const { recurringFeedback } = member;
    if (recurringFeedback) {
      return (
        <DescriptionContainer>
          {recurringFeedback.recurrence.frequency} (<EditButton onClick={() => this.handleOpenEditRecurringModal(member)}>Edit</EditButton>)
        </DescriptionContainer>
      );
    }
  }

  renderMenu(member) {
    const { handleSelectAction } = this.props;
    const finalMenuIconMap = menuIconMap.slice(0);
    if (member.recurringFeedback) {
      finalMenuIconMap.splice(1, 1, editRecurring);
    }
    return (
      <Menu.Inline
        onClick={() => this.handleNameOrAvatarClick(member)}
        parentHoverComponent={MemberListItem}
      >
        {finalMenuIconMap.map((menuItem, index) => {
          return (
            <Menu.Inline.Item
              key={`${member.personId}-${index}-menu-item`}
              onClick={() => handleSelectAction(menuItem, member)}
              {...menuItem}
            />
          );
        })}
      </Menu.Inline>
    );
  }

  renderList(list) {
    return (
      <RecurringListContainer>
        <TransitionGroup>
          {list.map((member) => {
            const { recurringFeedback, person } = member;
            const personWithRecurring = {...person, recurringFeedback};
            const tooltipContent = recurringFeedback ? generateRecurrenceTooltip(recurringFeedback.recurrence) : null;
            return (
              <CSSTransition
                key={person.id}
                classNames={RECURRING_LIST_ITEM_CLASSNAME}
                timeout={2000}
                appear
              >
                <MemberListItem
                  actions={this.renderMenu(personWithRecurring)}
                  content={this.renderMemberDescription(personWithRecurring)}
                  advisor={personWithRecurring}
                  tooltipContent={tooltipContent}
                  onClick={() => this.handleNameOrAvatarClick(personWithRecurring)}
                />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </RecurringListContainer>
    );
  }

  render() {
    const { recurringList, hasAcceptedMembers } = this.props;
    const emptyListMessage = hasAcceptedMembers ? 
      <> 
        You have no members set up with
        <br/>
        continuous feedback yet
      </> : 'You have no members in your Channel';

    const recurringMembersList = recurringList.length ? 
      this.renderList(recurringList) : 
      <MembersBody hasAcceptedMembers={hasAcceptedMembers}>
        {emptyListMessage}
      </MembersBody>;

    return (
      <>
        <ListContainer header={this.renderRecurringListHeader()}>
          {recurringMembersList}
        </ListContainer>
      </>
    );
  }
}