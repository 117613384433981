import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Container';

const Basic = ({
  children,
  centered,
  extraPadBottom,
  smallPadBottom,
  smallVerticalPadding,
}) => (
  <Container
    centered={centered}
    extraPadBottom={extraPadBottom}
    smallPadBottom={smallPadBottom}
    smallVerticalPadding={smallVerticalPadding}
  >
    {children}
  </Container>
);

Basic.defaultProps = {
  centered: false,
  extraPadBottom: false,
  smallPadBottom: false,
  smallVerticalPadding: false,
};

Basic.propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
  extraPadBottom: PropTypes.bool,
  smallPadBottom: PropTypes.bool,
  smallVerticalPadding: PropTypes.bool,
};

export default Basic;
