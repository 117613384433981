import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={36} height={32} viewBox="0 0 36 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.143 12.072l2.752 3.138c.645.735 1.76.885 2.58.315.88-.62 1.09-1.83.475-2.71l-2.825-4.03a2.202 2.202 0 0 0-1.945-.94H6.975C3.14 7.845 0 10.935 0 14.71v3.43C0 21.91 3.14 25 6.975 25h.042a4.037 4.037 0 0 1-.562-2.06c0-1.002.364-1.92.968-2.63a4.043 4.043 0 0 1-.21-2.26c-.637.387-1.42.73-2.375.97a1 1 0 0 1-.486-1.94c1.517-.38 2.397-1.065 2.893-1.612.053-.058.101-.115.146-.17L6.51 14.21c.061-1.056.251-1.64.633-2.138zm2.5 5.407c.53.255 1.116.39 1.717.39.815 0 1.595-.244 2.255-.71a3.955 3.955 0 0 0 1.466-4.514h.579c1.13 0 2.06.925 2.06 2.06 0 1.13-.925 2.06-2.06 2.06h-1.03c1.13 0 2.06.925 2.06 2.06 0 1.13-.925 2.06-2.06 2.06h-.541a2.067 2.067 0 0 1 1.916 2.055c0 1.13-.925 2.06-2.06 2.06h-3.43a2.063 2.063 0 0 1-2.06-2.06c0-1.13.925-2.06 2.06-2.06h.55a2.073 2.073 0 0 1-1.925-2.055c0-.512.19-.983.504-1.346zm5.423-4.876a2.07 2.07 0 0 0 1.624-2.013c0-1.135-.93-2.06-2.06-2.06h-2.248l2.198 3.135c.208.297.37.612.486.938zm13.79-.53l-2.751 3.137a1.957 1.957 0 0 1-2.58.315 1.947 1.947 0 0 1-.475-2.71l2.825-4.03a2.202 2.202 0 0 1 1.947-.94h1.203c3.835 0 6.975 3.09 6.975 6.865v3.43c0 3.775-3.14 6.865-6.975 6.865h-.045a4.038 4.038 0 0 0 .565-2.065c0-1.002-.365-1.92-.968-2.63a4.045 4.045 0 0 0 .21-2.26c.637.387 1.42.731 2.375.97a1 1 0 0 0 .486-1.94c-1.517-.38-2.397-1.065-2.893-1.612a4.16 4.16 0 0 1-.147-.17l.882-1.088c-.061-1.056-.252-1.64-.633-2.138zm-2.5 5.406c-.53.255-1.115.39-1.716.39a3.893 3.893 0 0 1-2.255-.71 3.955 3.955 0 0 1-1.466-4.514h-.579c-1.13 0-2.06.925-2.06 2.06 0 1.13.925 2.06 2.06 2.06h1.03c-1.13 0-2.06.925-2.06 2.06 0 1.13.925 2.06 2.06 2.06h.541a2.067 2.067 0 0 0-1.916 2.055c0 1.13.925 2.06 2.06 2.06h3.43c1.135 0 2.06-.925 2.06-2.06 0-1.13-.925-2.06-2.06-2.06h-.55a2.073 2.073 0 0 0 1.925-2.055c0-.512-.19-.983-.504-1.346zm-5.422-4.876c.116-.326.278-.641.486-.938l2.198-3.135H21.37c-1.13 0-2.06.925-2.06 2.06a2.07 2.07 0 0 0 1.624 2.013z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
