import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MainContainer, Container, Wrapper, Image } from './styles';

const createThumbs = (files, size, previewIsCircle) => {
  return files.map((file) => (
    <Container key={file.name} size={size} isCircle={previewIsCircle}>
      <Wrapper>
        <Image src={file.preview} />
      </Wrapper>
    </Container>
  ));
};

const createThumbsFromUrls = (urls, size) => {
  const files = urls.map((url) => ({ name: url, preview: url }));
  return createThumbs(files, size);
};

const Preview = (props) => {
  const { previewIsCircle } = props;
  const [files, setFiles] = useState(props.files);

  useEffect(() => {
    setFiles(
      props.files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [props.files]);

  if (props.urls.length && !files.length) {
    let thumbnail;
    if (typeof props.urls[0] === 'object') {
      thumbnail = createThumbs(props.urls, props.size, previewIsCircle);
    }
    else {
      thumbnail = createThumbsFromUrls(props.urls, props.size, previewIsCircle);
    }
    return <MainContainer>{thumbnail}</MainContainer>;
  }
  return <MainContainer>{createThumbs(files, props.size, previewIsCircle)}</MainContainer>;
};

Preview.defaultProps = {
  files: [],
  urls: [],
};

Preview.propTypes = {
  /** File(s) to show preview of. */
  files: PropTypes.arrayOf(PropTypes.object),
  /** If preview image is a circle. */
  previewIsCircle: PropTypes.bool,
  /** Height and width of the image preview. */
  size: PropTypes.number,
  /** Since the image uploader cannot prepopulate the field, image urls can be passed to show that existing files were selected. */
  urls: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
};

export default Preview;
