import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 23.6855C6.65895 23.8849 9.04776 23.2239 11.1664 21.5734C10.1283 21.503 9.19055 21.1784 8.36083 20.5487C7.53111 19.919 6.9561 19.1016 6.60878 18.0651C7.34588 18.1902 8.04052 18.1707 8.7699 17.9869C6.31549 17.2359 5.01881 15.605 4.88374 13.0392C5.57067 13.3521 6.26532 13.5907 7.03714 13.6024C4.84515 11.7603 4.37434 9.54653 5.54752 6.89865C8.25277 10.1215 11.6334 11.8815 15.7742 12.1709C15.7434 11.8541 15.7009 11.5647 15.6855 11.2753C15.6122 9.7108 16.1409 8.38099 17.2793 7.32497C18.5142 6.17509 19.9884 5.79179 21.6247 6.10468C22.574 6.2846 23.3999 6.7383 24.0868 7.44231C24.1872 7.544 24.2798 7.5792 24.4264 7.544C25.3642 7.32106 26.2634 7.00035 27.1008 6.51927C27.1703 6.48016 27.2475 6.44496 27.3671 6.38629C26.9812 7.55573 26.2865 8.42011 25.3295 9.08892C26.2595 9.01461 27.1278 8.73691 28 8.41228C27.8649 8.60393 27.7376 8.79949 27.5948 8.98332C27.452 9.16714 27.3092 9.35488 27.1433 9.51524C26.6879 9.95329 26.2209 10.3796 25.7463 10.7981C25.6228 10.9076 25.5649 11.0093 25.5726 11.1775C25.6305 12.6168 25.4491 14.0287 25.0632 15.4094C24.4534 17.5957 23.4192 19.5592 21.9296 21.2644C21.0806 22.2344 20.1158 23.0636 19.0314 23.7598C17.6421 24.6515 16.137 25.2538 14.5316 25.5824C12.6522 25.9657 10.7651 25.9891 8.87796 25.6332C7.39219 25.3516 5.99518 24.8275 4.67149 24.0961C4.43994 23.9671 4.22383 23.8224 4 23.6855Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
