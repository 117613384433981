
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Button, ModalNew as Modal } from '@matterapp/matter-ui';

export const RightButton = styled(Button.Simple.Primary.Vivid)`
  flex: 1 1 100%;
  padding: ${theme.spacing.half} 0;
  line-height: ${theme.lineHeight.M};
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.fontSize.S};
`;

export const LeftButton = styled(Button.Simple)`
  flex: 1 1 100%;
  padding: ${theme.spacing.half} 0;
  margin-right: ${theme.spacing.single};
  ${({ isHidden }) => isHidden && `
    display: none;
  `}
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  padding: ${theme.spacing.single};
  ${theme.media.S`
    padding: ${theme.spacing.singleAndHalf};
  `}
`;

export const ModalBody = styled(Modal.Body)`
  div:nth-child(4) {
    border: none !important;
    padding-bottom: 0;
  }
`;

export const ModalHeader = styled(Modal.Header)`
  text-align: center;
`;