import React from 'react';
import { SVG, Path } from './consts';

const Preferences = (props) => (
  <SVG {...props}>
    <Path
      d="M19.6,11.4h-2.7c-0.3-1.1-1.3-1.9-2.4-1.9s-2.1,0.8-2.4,1.9H4.3c-0.3,0-0.6,0.3-0.6,0.6c0,0.4,0.3,0.6,0.6,0.6H12
      c0.3,1.1,1.3,1.9,2.4,1.9c1.1,0,2.1-0.8,2.4-1.9h2.7c0.4,0,0.6-0.3,0.6-0.6C20.2,11.6,19.9,11.4,19.6,11.4z M4.3,6.8h2.8
      c0.3,1.1,1.3,1.9,2.4,1.9s2.1-0.8,2.4-1.9h7.7c0.4,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6h-7.7c-0.3-1.1-1.3-1.9-2.4-1.9
      S7.3,4.5,7.1,5.6H4.3c-0.3,0-0.6,0.3-0.6,0.6C3.7,6.5,4,6.8,4.3,6.8z M19.6,17.2h-7.7c-0.3-1.1-1.3-1.9-2.4-1.9S7.3,16.1,7,17.2H4.3
      c-0.4,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6H7c0.3,1.1,1.3,1.9,2.4,1.9s2.1-0.8,2.4-1.9h7.7c0.4,0,0.6-0.3,0.6-0.6S19.9,17.2,19.6,17.2z"
    />
  </SVG>
);

export default Preferences;
