import styled from 'styled-components';
import { Input, ClickableLink, IconNew} from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';
import { Box } from '@mui/material';

export const AllowanceResetDateContainer = styled.div(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export const StyledIcon = styled(IconNew)(() => ({
  marginLeft: theme.spacing.half
}));

export const DropdownContainer = styled(Box)(({ theme }) => ({
  width: '300px',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const SubHeader = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.fontSize.S,
  fontWeight: theme.fontWeight.semiBold,
  marginBottom: theme.spacing.half
}));

export const InputWrapper = styled('div')(() => ({
  position: 'relative',
  '.allowanceInputError': {
    position: 'absolute'
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    width: '100%'
  }
}));

export const ResetButton = styled(ClickableLink)(() => ({
  position: 'absolute',
  top: `-${theme.spacing.doubleAndHalf}`,
  right: `-${theme.spacing.threeQuarters}`,
  fontSize: theme.fontSize.base,
  padding: theme.spacing.threeQuarters,
  zIndex: 1,
  '&:not(:hover):not(:active)': {
    color: theme.colors.blacks[50]
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    top: `-${theme.spacing.half}`,
  }
}));

export const StyledInput = styled(Input)(() => ({
  width: '100%',
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    marginTop: theme.spacing.double,
  }
}));

export const AllowanceBlock = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: `${theme.spacing.double} 0`,
  h5: {
    color: theme.colors.blacks[80],
    fontSize: theme.fontSize.base,
    fontWeight: theme.fontWeight.medium,
    marginBottom: '5px'
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));
