import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { ButtonNew } from '../../Button';
import { peer as peerPropType } from '../prop-types';
import GamePanelBase from '../GamePanelBase';
import Panel from '../../Panel';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  button {
    width: 100%;
  }
  button:first-child {
    margin-right: 16px;
  }
`;

const Header = styled.div`
  line-height: 32px;
  font-size: 24px;
  color: ${() => theme.colors.blacks[90]};
  margin-top: 24px;
  margin-bottom: 32px;
`;

const FlexGrowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const RequestFeedbackMode = ({ onPeerAccept, onPeerReject, peer }) => {
  const friendlyName = peer.fullName ? peer.fullName : peer.email;
  return (
    <GamePanelBase
      key={`${peer.email}-requestFeedback`}
      centered
      footer={
        <Panel.Footer.Basic centered smallPadBottom>
          <ButtonContainer>
            <ButtonNew onClick={onPeerReject} basic>
              Skip
            </ButtonNew>
            <ButtonNew onClick={onPeerAccept} primary>
              Yes!
            </ButtonNew>
          </ButtonContainer>
        </Panel.Footer.Basic>
      }
    >
      <FlexGrowContainer>
        <Panel.Block.Basic>
          <Header>
            That’s Great!
            <br />
            <strong>Ask {friendlyName} for feedback?</strong>
          </Header>
        </Panel.Block.Basic>
      </FlexGrowContainer>
    </GamePanelBase>
  );
};

RequestFeedbackMode.propTypes = {
  onPeerAccept: PropTypes.func.isRequired,
  onPeerReject: PropTypes.func.isRequired,
  peer: peerPropType.isRequired, // eslint-disable-line
};

export default RequestFeedbackMode;
