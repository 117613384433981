import React from 'react';
import styled from 'styled-components';
import { IconSpinner } from '../Icon';

const DEFAUL_SIZE = 56;

const StyledIconContainer = styled.div`
  ${({ size }) => `
    height: ${size || DEFAUL_SIZE}px;
    width: ${size || DEFAUL_SIZE}px;
    @keyframes spin { 100% { transform:rotate(360deg) } }
    animation: spin 4s linear infinite;
    margin-bottom: 16px;
    svg {
      display: block;
      height: ${size || DEFAUL_SIZE}px;
      width: ${size || DEFAUL_SIZE}px;
    }
  `}
`;

const Spinner = ({ fill, size }) => (
  <StyledIconContainer>
    <IconSpinner fill={fill} size={size} />
  </StyledIconContainer>
);

export default Spinner;
