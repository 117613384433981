import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Confirm } from '../../../Confirm';
import Button from '../../../Button';
import Skill from '../../../Skill';
import { SkillPropType } from '../../consts';
import theme from '@matterapp/matter-theme';

const ConfirmModalButton = styled(Button.New).attrs({
  size: 'small',
})``;

const Container = styled.div`
  margin-top: -14px;
  margin-bottom: ${() => theme.spacing.half};
`;

export default class SkillTag extends React.Component {
  static propTypes = {
    /** Skill selected. */
    selectedSkill: SkillPropType,
    /** Function to reset skill. */
    handleResetSkill: PropTypes.func.isRequired,
    /** Function to go to next step. */
    handleNextStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedSkill: null,
    handleResetSkill: () => {},
    handleNextStep: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleOpenConfirm = () => this.setState({ isOpen: true });

  handleCloseConfirm = () => this.setState({ isOpen: false });

  renderConfirmContainer = () => {
    const buttonRight = (
      <ConfirmModalButton basic onClick={this.handleCloseConfirm}>
        Cancel
      </ConfirmModalButton>
    );
    const buttonLeft = (
      <ConfirmModalButton primary onClick={this.props.handleResetSkill}>
        Yes, remove
      </ConfirmModalButton>
    );

    return (
      <Confirm.Old
        isOpen={this.state.isOpen}
        heading={'Are you sure?'}
        subheading={
          'Tagging a skill will help your peers better understand your feedback and its context.'
        }
        buttonLeft={buttonLeft}
        buttonRight={buttonRight}
        onCancel={this.handleCloseConfirm}
      />
    );
  };

  render() {
    const { disabled, selectedSkill, handleNextStep } = this.props;
    if (selectedSkill === null) {
      return <Container><Skill.Tagger.Empty disabled={disabled} onClick={handleNextStep} /></Container>;
    }
    return (
      <Container>
        <Skill.Tagger.Basic
          skill={selectedSkill}
          onReset={this.handleOpenConfirm}
        />
        {this.renderConfirmContainer()}
      </Container>
    );
  }
}
