import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../Button';
import Grid from '../../Grid';
import { getDescriptionTitle, getDescriptionText } from '../../../common/libs/skill-ratings-map';
import RatingGuideModal from './RatingGuideModal';
import theme from '@matterapp/matter-theme';

const DEFAULT_NUMBER_OF_STEPS = 5;
const DEFAULT_NUMBER_OF_ROWS = 1;
const RC_LABEL = 'rater';
const starDescriptions = ['Poor', 'Fair', 'Good', 'Great', 'Amazing'];

const Container = styled.div`
  position: relative;
`;

const Step = styled(Button.Simple.Toggle.Primary)`
  border-radius: ${theme.borderRadius.M};
  padding: ${theme.spacing.single} ${theme.spacing.quarter};
  font-size: ${theme.fontSize.L};
  font-weight: ${theme.fontWeight.bold};
  transition: all ${theme.transitions.times.default};
    ${({ displayNumber }) =>
    !displayNumber &&
      `
    &:not(:hover) {
      color: transparent;

      > svg path {
        fill: transparent;
      }
    }
  `}
    & > svg {
    transform: scale(0.8);
    transform-origin: center;
    path {
      fill: ${theme.colors.white};
      transition: fill ${theme.transitions.times.default};
    }
  }
  ${theme.media.S`
    &:hover {
      margin: ${({ isStarRating }) => isStarRating ? '' : '-4px 0'};
    }
    & > svg {
      transform: scale(1);
    }
  `}
`;

const DescriptionContainer = styled.div`
  color: ${theme.colors.blacks[70]};
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  margin-top: ${({ useSmallNumbers }) => useSmallNumbers ? theme.spacing.singleAndHalf : theme.spacing.double};
  min-height: ${theme.spacing.triple};
  max-width: 480px;
  ${theme.media.S`
    font-size: ${({ useSmallNumbers }) => useSmallNumbers ? theme.fontSize.base : `calc(${theme.fontSize.S} - 1px)`};
    line-height: ${({ useSmallNumbers }) => useSmallNumbers ? theme.lineHeight.S : `calc(${theme.lineHeight.M} + ${theme.spacing.quarter})`};
  `}
`;
const DescriptionHeader = styled.span`
  font-weight: ${theme.fontWeight.bold};
`;
const DescriptionText = styled.span``;

const DescriptionTextBold = styled.span`
  display: inline;
  font-weight: ${theme.fontWeight.bold};
  ${theme.media.S`
    display: block;
  `}
`;

const StarDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.03em;
  text-align: center;
  height: 40px;
  color: ${theme.colors.blacks[50]};
  ${theme.media.S`
    font-size: ${theme.fontSize.base};
    height: 56px;
  `}
`;

export default class Base extends React.PureComponent {
  static propTypes = {
    color: PropTypes.oneOf(Object.values(Button.Simple.buttonColors)),
    /** If rating self. */
    isSelfRating: PropTypes.bool,
    /** Description to show if no rating is selected. */
    noRatingDescription: PropTypes.node,
    /** Number of rows in the rater. If not passed in, all steps are in one row. */
    numberOfRows: PropTypes.number,
    /** Number of steps in the rater. */
    numberOfSteps: PropTypes.number,
    /** Callback when a step is clicked. Passes back props object in 2nd prop with `value` key. */
    onClickStep: PropTypes.func.isRequired,
    /** Callback when rating guide modal opens. */
    onOpenRatingGuideModal: PropTypes.func,
    /** The first name of the receiver. */
    receiversFirstName: PropTypes.string,
    /** Spacing between each step. */
    spacing: PropTypes.string,
    /** Display info icon on mobile to open info modal. */
    showInfoModalOnMobile: PropTypes.bool,
    /** Display description of each rating. */
    showDescription: PropTypes.bool,
  };

  static defaultProps = {
    numberOfRows: DEFAULT_NUMBER_OF_ROWS,
    numberOfSteps: DEFAULT_NUMBER_OF_STEPS,
    receiversFirstName: 'Your peer',
    spacing: theme.spacing.quarter,
    showInfoModalOnMobile: true,
    showDescription: true,
  };

  static Step = Step;

  static rcLabel = RC_LABEL;
  static colorPropType = PropTypes.oneOf(Object.values(Button.Simple.buttonColors));

  static DescriptionHeader = DescriptionHeader;
  static DescriptionText = DescriptionText;
  static DescriptionTextBold = DescriptionTextBold;

  state = {
    isHoveringOverRater: null,
    isTouching: false,
  };

  handleClickStep = (e, { value }) => {
    const { rating } = this.props;
    this.props.onClickStep(e, { ...this.props, value, prevRating: rating, rating: value });
  };

  /**
   * Callback when mouse enters the rater to mark the `isHoveringOverRating` state.
   * @returns { void }
   */
  handleMouseEnter = (e) => {
    this.setState({ isHoveringOverRater: parseInt(e.target.value, 10) });
  };

  /**
   * Callback when mouse leaves the rater to unmark the `isHoveringOverRating` state.
   * @returns { void }
   */
  handleMouseLeave = () => {
    this.setState({ isHoveringOverRater: null });
  };

  /**
   * Gets the rating description for the current rating.
   * @returns { React.Component } The render rater description.
   */
  getRatingDescription = () => {
    const {
      isSelfRating,
      noRatingDescription,
      onOpenRatingGuideModal,
      rating,
      receiversFirstName,
      showInfoModalOnMobile,
      useSmallNumbers,
    } = this.props;
    const currentRating = this.state.isHoveringOverRater || rating;
    const header = getDescriptionTitle(currentRating);
    const description =
      getDescriptionText(currentRating, receiversFirstName, isSelfRating) ||
      noRatingDescription;
    return (
      <DescriptionContainer useSmallNumbers={useSmallNumbers}>
        <DescriptionHeader>{header}</DescriptionHeader>{' '}
        <DescriptionText>{description}</DescriptionText>
        {description && showInfoModalOnMobile && (
          <RatingGuideModal
            onOpenModal={onOpenRatingGuideModal}
            receiversFirstName={receiversFirstName}
          />
        )}
      </DescriptionContainer>
    );
  };

  getStarDescription = () => {
    return starDescriptions.map((description, index) => (
      <StarDescription key={`star-rater-description-${index}`}>{description}</StarDescription>
    ));
  }

  getValueFromEvent = (e) => {
    if (e.changedTouches && e.changedTouches[0]) {
      const { pageX, pageY } = e.changedTouches[0];
      const elements = document.elementsFromPoint(pageX, pageY);
      const buttonElements = elements.filter((element) => {
        const tag = element.tagName || '';
        return tag.toLowerCase() === 'button';
      });
      if (buttonElements[0] && buttonElements[0].value) {
        return parseInt(buttonElements[0].value, 10);
      }
    }
    return 0;
  };

  handleTouchMove = (e) => {
    const value = this.getValueFromEvent(e);
    if (value) {
      this.setState({ isHoveringOverRater: value, isTouching: true });
    }
  };

  handleTouchEnd = (e) => {
    const value = this.getValueFromEvent(e);
    if (value && this.state.isTouching) {
      this.setState({ isTouching: false });
      this.handleClickStep(e, { ...this.props, value });
    }
  };

  /**
   * Gets the column def00inition for the grid.
   * The grid component supports a number of grid sizes but larger sizes (8+) should be define customly.
   * If the number of steps passed are less than or equal to the default number of steps, that value
   * gets passed to the grid.
   *
   * Other values and multi columns will generate the custom definition columns to use.
   *
   * The grid component is used for the ease of equal spacing between steps without the need of
   * specific styles.
   *
   * @returns { Number | Array } The column value to use for the grid.
   */
  getGridColumns = () => {
    const { numberOfSteps } = this.props;
    if (numberOfSteps <= DEFAULT_NUMBER_OF_STEPS) {
      return numberOfSteps;
    }

    const columns = [];
    for (let i = 0; i < numberOfSteps; i++) {
      columns.push(1);
    }
    return columns;
  };

  renderSteps = () => {
    const { color, numberOfSteps, rating, isStarRating, children } = this.props;
    const { isHoveringOverRater } = this.state;

    const steps = [];
    for (let i = 1; i <= numberOfSteps; i++) {
      steps.push(
        <Step
          displayNumber={!rating || isHoveringOverRater === i || rating === i}
          isSelected={i <= (isHoveringOverRater || rating)}
          onClick={this.handleClickStep}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          value={i}
          key={`step-${i}-of-${numberOfSteps}`}
          color={color}
          isStarRating={isStarRating}
        >
          {children ? React.cloneElement(children, {number: i, ...this.props}) : null}
        </Step>
      );
    }
    return steps;
  };

  render() {
    const { className, spacing, showDescription, isStarRating } = this.props;
    return (
      <Container
        onKeyDown={this.handleKeyDown}
        onTouchEnd={this.handleTouchEnd}
        onTouchMove={this.handleTouchMove}
        data-rc={RC_LABEL}
      >
        <Grid className={className} columns={this.getGridColumns()} spacing={spacing}>
          {this.renderSteps()}
          {isStarRating && this.getStarDescription()}
        </Grid>
        {showDescription && this.getRatingDescription()}
      </Container>
    );
  }
}

