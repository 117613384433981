import omitStyled from '../../../libs/omitStyled';

const Container = omitStyled('div', [
  'centered',
  'pushBottom',
  'padTop',
  'extraPadTop',
  'padBottom',
  'extraPadBottom',
  'solo',
])`
  width: 100%;
  
  padding: 0 ${({theme}) => theme.padding.base};
  ${({theme}) => theme.media.S`
    padding: 0 ${theme.padding.S};
  `}

  ${({ centered }) => (
    centered && `
      text-align: center;
      margin: 0 auto;
    `
  )}

  ${({ centered }) => (
    centered && `
      text-align: center;
      margin: 0 auto;
    `
  )}

  ${({ pushBottom }) => (
    pushBottom ? 'margin-bottom: 16px;' : 'margin-bottom: 0;'
  )}

  ${({
    padTop,
    extraPadTop,
  }) => {
    let padding;
    if (extraPadTop) {
      padding = 32;
    } else if (padTop) {
      padding = 16;
    } else {
      padding = 0;
    }
    return ['padding-top:', padding, 'px'].join('');
  }}

  ${({
    padBottom,
    extraPadBottom,
  }) => {
    let padding;
    if (extraPadBottom) {
      padding = 32;
    } else if (padBottom) {
      padding = 16;
    } else {
      padding = 0;
    }
    return ['padding-bottom:', padding, 'px'].join('');
  }}
`;

export default Container;
