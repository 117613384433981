import Base from './Checkbox';
import CheckboxPill from './CheckboxPill';
import Checkmark from './Checkmark';
import CheckmarkToggle from './CheckmarkToggle';
import Toggle from './Toggle';
import Radio from '../Radio';
import RadioList from './RadioList';

const Checkbox = Base;
Checkbox.Checkmark = Checkmark;
Checkbox.CheckmarkToggle = CheckmarkToggle;
Checkbox.Pill = CheckboxPill;
Checkbox.Toggle = Toggle;

// Deprecated. Just import from Radio.
Checkbox.Radio = Radio;
Checkbox.RadioList = Radio.List;
Checkbox.RadioList.Old = RadioList; //TODO: Delete this once Radio is refactored

// DEPRECATED: CheckboxPill, Checkmark, CheckmarkToggle.
// Use Checkbox.Pill, Checkbox.Checkmark, Checkbox.Toggle instead.
export { Checkbox, CheckboxPill, Checkmark, CheckmarkToggle };
export default Checkbox;