import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  margin-bottom: ${({ largerSpacing }) => largerSpacing ? theme.spacing.triple : theme.spacing.double};
  ${({ dividerAbove, dividerBelow, largerSpacing }) => {
    const divider = `1px solid ${theme.colors.blacks[30]}`;
    const spacing = largerSpacing ? theme.spacing.tripleAndHalf : theme.spacing.double;

    const above = `
      border-top: ${divider};
      padding-top: ${spacing};
    `;
    const below = `
      border-bottom: ${divider};
      padding-bottom: ${spacing};
    `;

    if (dividerAbove && dividerBelow) {
      return `
        ${above}
        ${below}
      `;
    } else if (dividerAbove) {
      return above;
    } else if (dividerBelow) {
      return below;
    }
  }}
`;

const Header = styled.div`
  color: ${theme.colors.blacks[90]};
  display: block;
  font-size: ${theme.fontSize.M};
  font-weight: ${theme.fontWeight.semiBold};
  line-height: ${theme.lineHeight.L};
  margin-bottom: ${({ hasSubHeader }) =>
    hasSubHeader ? theme.spacing.half : theme.spacing.single};
`;

const SubHeader = styled.div`
  color: ${theme.colors.blacks[90]};
  display: block;
  font-size: ${theme.fontSize.S};
  font-weight: ${theme.fontWeight.regular};
  line-height: ${theme.lineHeight.M};
  margin-bottom: ${theme.spacing.singleAndHalf};
`;

const Label = styled.label`
  color: ${theme.colors.blacks[80]};
  display: block;
  font-size: ${theme.fontSize.S2};
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: ${theme.spacing.half};
`;

const Footer = styled.div`
  color: ${theme.colors.blacks[50]};
  display: block;
  font-size: ${theme.fontSize.base};
  margin-top: ${theme.spacing.half};
`;

const FormGroup = ({
  children,
  className,
  dividerAbove,
  dividerBelow,
  footer,
  header,
  label,
  largerSpacing,
  subHeader,
}) => (
  <Container className={className} dividerAbove={dividerAbove} dividerBelow={dividerBelow} largerSpacing={largerSpacing}>
    {header && <Header data-role="header" hasSubHeader={!!subHeader}>{header}</Header>}
    {label && <Label>{label}</Label>}
    {subHeader && <SubHeader data-role="subheader">{subHeader}</SubHeader>}
    {children}
    {footer && <Footer>{footer}</Footer>}
  </Container>
);

FormGroup.Header = Header;
FormGroup.Label = Label;
FormGroup.SubHeader = SubHeader;
FormGroup.Footer = Footer;

FormGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  footer: PropTypes.node,
  header: PropTypes.node,
  label: PropTypes.string,
  subHeader: PropTypes.node,
  dividerAbove: PropTypes.bool,
  dividerBelow: PropTypes.bool,
  largerSpacing: PropTypes.bool,
};

export default FormGroup;
