import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';

export default function withCurrentUser(Component) {  
  function withUser(props) {
    const { 
      currentUser, 
      isLoadingCurrentUser, 
      isAuthenticating,
      setParams, 
      refetchCurrentUser,
      signOut,
      currentTenantWorkspaceIds
    } = useContext(UserContext);

    return (
      <Component
        {...props}
        currentUser={currentUser}
        isLoadingCurrentUser={isLoadingCurrentUser}
        isAuthenticating={isAuthenticating}
        setParams={setParams}
        refetchCurrentUser={refetchCurrentUser}
        signOut={signOut}
        currentUserStatus={{
          ...(currentUser || {}),
          isLoggedIn: !!currentUser,
        }}
        currentTenantWorkspaceIds={currentTenantWorkspaceIds}
        isLoggedIn={!!currentUser}
      />
    );
  }

  return withUser;
}