import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactToggle from 'react-toggle';
import { Text } from '../index';
import theme from '@matterapp/matter-theme';

const ToggleLabel = styled.label`
  display: flex;
  align-items: center;

  .react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    user-select: none;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: ${({ size }) => size === 'small' ?  theme.spacing.doubleAndHalf : theme.spacing.quad};
    height: ${({ size }) => size === 'small' ?  theme.spacing.singleAndHalf : theme.spacing.doubleAndQuarter};
    padding: 0;
    border-radius: ${({ size }) => size === 'small' ?  theme.spacing.threeQuarters: '30px'};
    background-color: ${() => theme.colors.blacks[10]};
    transition: all 0.2s ease;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: ${() => theme.colors.blue};
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    //background-color: ${() => theme.colors.blacks[10]};
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    left: ${({ size }) => size === 'small' ?  theme.spacing.quarter: theme.spacing.eighth};
    top: ${({ size }) => size === 'small' ?  theme.spacing.quarter: theme.spacing.eighth};
    width: ${({ size }) => size === 'small' ?  theme.spacing.single: theme.spacing.double};
    height: ${({ size }) => size === 'small' ?  theme.spacing.single: theme.spacing.double};
    border: none;
    border-radius: 50%;
    background-color: #FFF;

    box-sizing: border-box;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: ${({ size }) => size === 'small' ?  theme.spacing.singleAndQuarter: '30px'};
    top: ${({ size }) => size === 'small' ?  theme.spacing.quarter: theme.spacing.eighth};
    border-color: ${() => theme.colors.blue};
  }
`;

const Toggle = ({
  name,
  onChange,
  checked,
  label,
  size
}) => (
  <ToggleLabel size={size}>
    {(
      <ReactToggle
        icons={false}
        onChange={onChange}
        name={name}
        checked={checked}
      />
    )}
    {label &&
    <Text
      color="lightGrey"
      style={{
        marginLeft: '8px',
      }}
    >
      {label}
    </Text>
    }
  </ToggleLabel>
);

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
};

Toggle.defaultProps = {
  onChange: () => {},
  checked: false,
  label: '',
};

export default Toggle;
