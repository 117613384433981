import React from 'react';
import BasePath from './BasePath';

const DEFAULT_HEIGHT = 102;
const PATH_LENGTH = 82;

const CenterToCenter1 = React.forwardRef((props, ref) => (
  <BasePath
    {...props}
    ref={ref}
    height={DEFAULT_HEIGHT}
    pathLength={PATH_LENGTH}
    pathCode={
      'M230.5,10v81.5'
    }
  />
));

export default CenterToCenter1;
