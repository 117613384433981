import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Form, Text, IconNew as Icon } from '@matterapp/matter-ui';
import { WEEKDAY_LIST_START_ON_MONDAY } from 'libs/prop-types/weekday';
import { WeekDay } from '@matterapp/calendar-date';

export const FREQUENCY_TEXT = {
  weekly: 'Week',
  biweekly: '2 Weeks',
  triweekly: '3 Weeks',
  monthly: 'Month',
};

export const FREQUENCY = {
  weekly: 'weekly',
  biweekly: 'biweekly',
  triweekly: 'triweekly',
  monthly: 'monthly',
};

export const FREQUENCY_LIST = {
  [FREQUENCY.weekly]: {
    label: 'Weekly (recommended)',
    value: FREQUENCY.weekly,
  },
  [FREQUENCY.biweekly]: {
    label: 'Bi-Weekly (every 2 weeks)',
    value: FREQUENCY.biweekly,
  },
  [FREQUENCY.triweekly]: {
    label: 'Tri-Weekly (every 3 weeks)',
    value: FREQUENCY.triweekly,
  },
  [FREQUENCY.monthly]: {
    label: 'Monthly ',
    value: FREQUENCY.monthly,
  },
};

export const frequencyOptionsList = [
  FREQUENCY_LIST[FREQUENCY.weekly],
  FREQUENCY_LIST[FREQUENCY.biweekly],
  FREQUENCY_LIST[FREQUENCY.triweekly],
  FREQUENCY_LIST[FREQUENCY.monthly],
];

export const weekdayOptionsList = WEEKDAY_LIST_START_ON_MONDAY;

export const LinkIcon = styled(Icon).attrs({
  name: 'internalLink',
})`
  display: none;
  margin: -6px 0;
  & path {
    fill: currentColor;
  }

  ${theme.media.M`
    display: inline;
  `}
`;

export const TextReplace = Text.WithReplacement;

export const NOOP = () => null;

/*****************************************************************************/
/* SHARED CONSTS                                                             */
/*****************************************************************************/
export const CANCEL_BUTTON = 'Cancel';
export const CLOSE_BUTTON = 'Close';
export const SAVE_BUTTON = 'Save';

export const Group = (props) => <Form.Group largerSpacing {...props} />;

/*****************************************************************************/
/* TOP NAV CONSTS                                                            */
/*****************************************************************************/
export const TOP_NAV_TEXT = {
  BACK: 'Back',
  ICON: 'arrowLeft',
  SETTINGS: 'Settings',
};

/*****************************************************************************/
/* LEFT PANEL CONSTS                                                         */
/*****************************************************************************/
export const LEFT_PANEL_TEXT = {
  HEADER: 'Channel Settings',
  ADMIN_HEADER: 'Tenant Settings'
};

export const TABS = {
  GENERAL: {
    label: 'General',
    value: 'settings-general',
    order: 1
  },
  MEMBERS: {
    label: 'Members',
    value: 'settings-members',
    order: 2
  },
  FEEDBACK_RITUAL: {
    label: 'Feedback Friday',
    value: 'settings-feedback-ritual',
    order: 3
  },
  KUDOS: {
    label: 'Kudos',
    value: 'settings-kudos',
    order: 4
  },
  REWARDS: {
    label: 'Rewards',
    value: 'settings-rewards',
    order: 5
  },
  INVITATIONS: {
    label: 'Invitations',
    value: 'settings-invitations',
  },
  CELEBRATIONS: {
    label: 'Celebrations',
    value: 'settings-celebrations',
    order: 6
  },
  BILLING: {
    label: 'Billing',
    value: 'settings-billing',
    order: 8
  },
  PULSE: {
    label: 'Pulse Survey',
    value: 'settings-pulse',
    order: 7
  },
  UPGRADE_TO_PRO: {
    label: 'Upgrade to Pro',
    value: 'settings-upgrade-to-pro',
  },
  PAYMENT_INFO: {
    label: <>Payment Info <LinkIcon /></>,
    value: 'settings-payment-info',
  },
  REWARDS_MANAGER: {
    label: 'Rewards Manager',
    value: 'rewards-manager',
  },
  HRIS: {
    label: 'HRIS Integration',
    value: 'settings-hris',
    order: 9
  },
};

/*****************************************************************************/
/* UNSAVED CHANGES MODAL CONSTS                                              */
/*****************************************************************************/
export const UNSAVED_CHANGES_MODAL_TEXT = {
  HEADER: 'Leave this tab?',
  SUB_HEADER:
    'You currently have unsaved changes, are you sure you want to leave this settings tab and discard changes?',
  CANCEL_BUTTON,
  CONFIRM_BUTTON: 'Yes, Discard Changes',
};

/*****************************************************************************/
/* DELETE WORKSPACE MODAL CONSTS                                             */
/*****************************************************************************/
export const DELETE_MODAL_TEXT = {
  HEADER: 'Delete your channel?',
  SUB_HEADER: `The channel ${TextReplace.replaceText} will no longer be available and your team members won’t have access to it. Are you sure you want to delete?`,
  CANCEL_BUTTON,
  DELETE_BUTTON: 'Yes, Delete',
};

/*****************************************************************************/
/* INVITE MODAL CONSTS                                                       */
/*****************************************************************************/
export const INVITE_MODAL_TEXT = {
  BUTTON: 'Invite',
  HEADER: 'Invite People',
  CLEAR_BUTTON: 'Clear text',
  CANCEL_BUTTON,
  DROPDOWN_PLACEHOLDER: 'Enter name or email address',
  EMAIL_DESCRIPTION: 'Hover over any added name/email to remove',
  MESSAGE_HEADER: 'Your Message:',
  MESSAGE_DEFAULT_MESSAGE:
    'I’ve invited you as a member of our company’s workplace team on Matter.',
  SEND_BUTTON: 'Send',
};
export const INVITE_MESSAGE = {
  MAX_LENGTH: 2500,
  MIN_LENGTH: 25,
};

/*****************************************************************************/
/* GENERAL PANEL CONSTS                                                      */
/*****************************************************************************/
export const GENERAL_PANEL_TEXT = {
  AVATAR_HEADER: 'Tenant Avatar',
  AVATAR_SUB_HEADER: 'A visual identifier for your tenant.',
  DELETE_BUTTON: 'Delete Channel',
  DELETE_HEADER: 'The Big Red Button',
  HEADER: 'General',
  INVITATIONS_HEADER: 'Invitation Permissions',
  INVITATIONS_SUB_HEADER:
    'Control who can send invitations to join your channel',
  NAME_HEADER: 'Tenant Name',
  TIMEZONE_HEADER: 'Timezone',
};

export const formatPeers = (members, onlyShowEmails) =>
  members.map((member) => {
    const { person } = member;
    const { email, ...other } = person;
    return {
      ...(onlyShowEmails ? { ...other, email, fullName: email } : person),
      ...member,
    };
  });

/*****************************************************************************/
/* DELETE PANEL CONSTS                                                       */
/*****************************************************************************/
export const DELETE_PANEL_TEXT = {
  CANCEL_BUTTON,
  DELETE_BUTTON: 'Delete Channel',
  HEADER: 'Last step before deleting your channel...',
  NAME_HEADER: `Enter the name "${TextReplace.replaceText}" to confirm`,
  NAME_PLACEHOLDER: 'Enter channel name',
  DELETE_NOTE_LEADING_TEXT: 'Important',
  DELETE_NOTE: `${TextReplace.replaceText}: This action will permanently delete the channel. All feedback associated with the channel will no longer be accessible by anyone.`,
  DELETE_NOTE_WARNING: 'This cannot be undone.',
};

/*****************************************************************************/
/* MEMBERS PANEL CONSTS                                                      */
/*****************************************************************************/
export const MEMBERS_PANEL_TEXT = {
  HEADER: 'Members',
  CANCEL_BUTTON,
  CLOSE_BUTTON,
};
export const MEMBERS_PANEL_REMOVE_MODAL_TEXT = {
  HEADER: 'Remove Member?',
  SUB_HEADER: `You are removing the channel member ${TextReplace.replaceText}. They will still be able to access the feedback they‘ve received via their personal account.`,
  CANCEL_BUTTON,
  REMOVE_BUTTON: 'Yes, Remove',
};

export const MEMBERS_PANEL_TRANSFER_OWNERSHIP_MODAL_TEXT = {
  HEADER: 'Transfer Ownership?',
  SUB_HEADER: `You are transferring the channel ownership to ${TextReplace.replaceText}. This will downgrade your membership to admin status, and you will no longer be the channel owner.`,
  CANCEL_BUTTON,
  CONFIRM_BUTTON: 'Yes, Confirm',
};
export const MEMBERS_DROPDOWN_OWNER_ITEM = { label: 'OWNER', value: 'owner' };
export const MEMBERS_DROPDOWN_ADMIN_ITEM = { label: 'ADMIN', value: 'admin' };
export const MEMBERS_DROPDOWN_MEMBER_ITEM = {
  label: 'MEMBER',
  value: 'member',
};
export const ADMIN_DROPDOWN_ITEMS = [
  MEMBERS_DROPDOWN_ADMIN_ITEM,
  MEMBERS_DROPDOWN_MEMBER_ITEM
];
export const OWNER_DROPDOWN_ITEMS = [
  MEMBERS_DROPDOWN_OWNER_ITEM,
  ...ADMIN_DROPDOWN_ITEMS,
];

/*****************************************************************************/
/* INVITATIONS PANEL CONSTS                                                  */
/*****************************************************************************/
export const INVITATIONS_PANEL_TEXT = {
  HEADER: 'Invitations',
  SUB_HEADER: 'Listed below are active invites to potential channel members',
};
export const INVITATIONS_PANEL_REVOKE_INVITATION_MODAL_TEXT = {
  HEADER: 'Revoke Invitation?',
  SUB_HEADER: `You are revoking the invitation sent to ${TextReplace.replaceText} for joining this channel.`,
  CANCEL_BUTTON,
  REVOKE_BUTTON: 'Yes, Revoke',
};

const FEEDBACK_RITUAL_CONSTS = {
  defaultKickoffMessage: (dayOfPost) => {
    const dayString = typeof dayOfPost === 'string' ? dayOfPost : WeekDay.toString(dayOfPost);
    const titleCaseDayString = dayString.charAt(0).toUpperCase() + dayString.slice(1);
    return `Happy Feedback ${titleCaseDayString}!🎉\nYour mission: give kudos and feedback to make your teammates feel like champions! 🏆`;
  },
  defaultDayOfPost: WeekDay.Friday,
};
/*****************************************************************************/
/* FEEDBACK RITUAL PANEL CONSTS                                              */
/*****************************************************************************/
export const FEEDBACK_RITUAL_PANEL_TEXT = {
  HEADER: 'Feedback Friday',
  SUB_HEADER: 'Encourage weekly team feedback',
  CANCEL_BUTTON,
  ENABLE_BUTTON: 'Enable Feature',
  DISABLE_BUTTON: 'Disable',
  HOW_IT_WORKS_BUTTON: 'See How It Works',
  POST_ON_MY_BEHALF_BUTTON: 'Post on my behalf',
  REMOVE_AUTHORIZATION_BUTTON: 'Remove Authorization',
  SAVE_BUTTON,
  DAY_OF_POST_CONFIRM_HEADER: 'Are you sure?',
  DAY_OF_POST_CONFIRM_SUB_HEADER: `By selecting ${TextReplace.replaceText}, you are making the choice to post Feedback Friday messages on the weekend.`,
  DAY_OF_POST_CONFIRM_CANCEL_BUTTON: 'Cancel',
  DAY_OF_POST_CONFIRM_POST_BUTTON: 'Yes, Proceed',
  KICKOFF_SUB_HEADER: `Will be sent at ${TextReplace.replaceText}`,
  KICKOFF_DEFAULT_MESSAGE: FEEDBACK_RITUAL_CONSTS.defaultKickoffMessage(TextReplace.replaceText),
  REMINDER_SUB_HEADER: `Will be sent at ${TextReplace.replaceText}`,
  REMINDER_OFF_MESSAGE_HEADER: 'Reminder off',
  REMINDER_OFF_MESSAGE:
    'Reminders positively reinforce the habit of giving weekly feedback on your team.',
  RITUAL_RECAP_SUB_HEADER: `Will be sent at ${TextReplace.replaceText}`,
  RITUAL_RECAP_OFF_MESSAGE_HEADER: 'Recap off',
  RITUAL_RECAP_OFF_MESSAGE:
    'Recaps highlight team feedback wins to encourage continued feedback giving and participation on a weekly basis.',
  SEE_EXAMPLE_LINK: 'See example',
  SLACK_REQUIRED_MESSAGE_HEADER: 'Slack Required',
  SLACK_REQUIRED_MESSAGE_REPLACE_TEXT: 'Contact us',
  SLACK_REQUIRED_MESSAGE: `Your workspace is not connected to Slack. ${TextReplace.replaceText} to participate in Feedback Friday!`,
  LAST_CALL_SUB_HEADER: `Will be sent at ${TextReplace.replaceText}`,
  SLACK_NOTIFICATIONS_HEADER: 'Notifications:',
  SLACK_NOTIFICATIONS_NOTIFY_TEXT: <>Notify Channel Members Using <strong>@here</strong></>,
  STATS_MESSAGE_SUB_HEADER: `Will be sent next business day at ${TextReplace.replaceText}`
};
export const FEEDBACK_RITUAL_PANEL_DISABLE_MODAL_TEXT = {
  HEADER: FEEDBACK_RITUAL_PANEL_TEXT.DAY_OF_POST_CONFIRM_HEADER,
  SUB_HEADER:
  'You and your team will no longer receive Feedback Friday messages and notifications from Matter on Slack or the Web.',
  CANCEL_BUTTON,
  DISABLE_BUTTON: FEEDBACK_RITUAL_PANEL_TEXT.DAY_OF_POST_CONFIRM_POST_BUTTON
};
export const FEEDBACK_RITUAL_CHANGE_FREQUENCY_MODAL_TEXT = {
  HEADER: FEEDBACK_RITUAL_PANEL_TEXT.DAY_OF_POST_CONFIRM_HEADER,
  CANCEL_BUTTON,
  CONFIRM_BUTTON: FEEDBACK_RITUAL_PANEL_TEXT.DAY_OF_POST_CONFIRM_POST_BUTTON
};
export const FEEDBACK_RITUAL_KICKOFF_MESSAGE = {
  MAX_LENGTH: 400,
  MIN_LENGTH: 25,
};

/*****************************************************************************/
/* REWARDS PANEL CONSTS                                                      */
/*****************************************************************************/
export const REWARDS_PANEL_TEXT = {
  HEADER: 'Rewards',
  DISABLED_TEXT: 'Add a rewards program to your Feedback Friday.',
  LEARN_MORE_TEXT: <>Have questions about rewards? Learn more about <a href={'https://matterapp.com/rewards'} target="_blank" rel="noopener noreferrer">Rewards Details</a>.</>,
  ENABLE_BUTTON: 'Enable Feature',
  DISABLE_BUTTON: 'Disable'
};

/*****************************************************************************/
/* SLACK PANEL CONSTS                                                        */
/*****************************************************************************/
export const SLACK_PANEL_TEXT = {
  DEFAULT_SLACK_TEAM_NAME: 'Slack',
  HEADER: 'Slack',
  ICON_NAME: 'socialSlack',
  CANCEL_BUTTON,
  CONNECT_BUTTON: 'Connect',
  DISCONNECT_BUTTON: 'Disconnect',
  CONNECT_TO_SLACK_HEADER_SETUP: 'Step 1: Connect to Slack',
  SELECT_SLACK_CHANNEL_PLACEHOLDER: 'Type name of new or existing Slack channel...',
  SELECT_SLACK_CHANNEL_PLACEHOLDER_SETUP: 'You must connect to slack first...',
  SELECT_SLACK_CHANNEL_HEADER_CONNECTED: 'Post to Slack Channel',
  SELECT_SLACK_CHANNEL_HEADER_SETUP: 'Step 2: Select Slack Channel',
  SELECT_SLACK_CHANNEL_SUB_HEADER_CONNECTED:
    'Feedback announcements will be sent to this Slack channel.',
  SELECT_SLACK_CHANNEL_SUB_HEADER_SETUP:
    'Channel activity, Feedback Friday messages, and public Kudos will be sent to this Slack channel.',
  SLACK_INCOMPLETE_HEADER: 'Slack setup incomplete',
  SLACK_INCOMPLETE_MESSAGE: 'Complete step #2 to finish setting up Slack.',
  SLACK_NOT_SET_UP_HEADER: 'Slack is not set up',
  SLACK_NOT_SET_UP_MESSAGE:
    'Complete both steps below in order to set up Slack.',
};

export const SLACK_PANEL_DISCONNECT_MODAL_TEXT = {
  HEADER: 'Are you sure?',
  SUB_HEADER:
  <>
    If you find the Slack app too noisy, 
    you can update the message notification settings or 
    disable Feedback Friday under <strong>Channel Settings &gt; Feedback Friday.</strong>
  </>,
  CANCEL_BUTTON,
  DISCONNECT_BUTTON: FEEDBACK_RITUAL_PANEL_TEXT.DAY_OF_POST_CONFIRM_POST_BUTTON
};
