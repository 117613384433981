import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { handleSubmitValidation, mixpanel } from 'libs';
import { quickNoteValidation } from '../../../../form-validations';
import { GIVE_QUICK_NOTE_FEEDBACK_MUTATION } from 'graphql-queries/queries';
import Toast from 'components/Toast/Toast';
import { QUICK_NOTE_FEEDBACK_REDUX_FORM } from '../../consts';

import QuickNoteForm from '../components/QuickNoteForm';

const handleTrack = {
  feedbackSent: mixpanel.createEventSender('quickNoteFeedback:sent'),
};

const withData = compose(
  graphql(GIVE_QUICK_NOTE_FEEDBACK_MUTATION, {
    name: 'giveQuickNoteFeedbackMutation',
  }),
  reduxForm({
    form: QUICK_NOTE_FEEDBACK_REDUX_FORM,
    validate: quickNoteValidation,
    onSubmit: async (
      values,
      dispatch,
      {
        handleResetAll,
        receiver,
        taggedSkill,
        giveQuickNoteFeedbackMutation,
        handleDispatchQuickNoteFeedbackSuccessNotification,
        handleActivateSubmissionFailed,
      }
    ) => {
      await handleSubmitValidation({
        fieldName: 'quickNote',
        submit: async () => {
          try {
            const variables = {
              receiverUserId: receiver.id,
              note: values.quickNote,
            };
            if (taggedSkill) {
              variables.skillId = taggedSkill.id;
            }
            await giveQuickNoteFeedbackMutation({ variables });
            const message = receiver.firstName
              ? `Your feedback was sent to ${receiver.firstName}!`
              : 'Your feedback was sent!';
            Toast.success(message);
            handleResetAll();
            handleTrack.feedbackSent();
          } catch (e) {
            console.log('quick note failed!');
            console.error(e);
            handleActivateSubmissionFailed();
            throw e;
          }
        },
      });
    },
  })
);

const QuickNoteFormContainer = withData(QuickNoteForm);

QuickNoteFormContainer.propTypes = {
  handleResetAll: PropTypes.func.isRequired,
  handleActivateSubmissionFailed: PropTypes.func.isRequired,
};

export default QuickNoteFormContainer;
