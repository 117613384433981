import CenterToCenter1 from './CenterToCenter1';
import CenterToLeft1 from './CenterToLeft1';
import LeftToCenter1 from './LeftToCenter1';
import LeftToLeft1 from './LeftToLeft1';
import LeftToRight1 from './LeftToRight1';
import LeftToRight2 from './LeftToRight2';
import RightToLeft1 from './RightToLeft1';
import RightToLeft2 from './RightToLeft2';
import RightToRight1 from './RightToRight1';

export default {
  CenterToCenter1,
  CenterToLeft1,
  LeftToCenter1,
  LeftToLeft1,
  LeftToRight1,
  LeftToRight2,
  RightToLeft1,
  RightToLeft2,
  RightToRight1,
};