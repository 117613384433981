import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Draggable as DraggableDND } from 'react-beautiful-dnd';
import DragHandle from './DragHandle';
import theme from '@matterapp/matter-theme';

export const Container = styled.div`
  background: ${theme.colors.white};
  display: flex;
  padding: ${theme.spacing.single} ${theme.spacing.singleAndHalf};
  position: relative;
  align-items: center;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0);
  transition: box-shadow ${theme.transitions.times.double};

  &:before, &:after {
    content: '';
    height: 1px;
    position: absolute;
    background-color: ${theme.colors.blacks[10]};
    left: 0;
    right: 0;
    opacity: 1;
    transition: opacity ${theme.transitions.times.double};
  }

  &:before {
    top: 0;
    transform: translateY(-1px);
  }

  &:after {
    bottom: 0;
  }

  &:first-child:before, &:last-of-type:after {
    opacity: 0;
  }

  ${({ isDragging }) => isDragging ? `
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  ` : ''}
`;

export const ContentWrapper = styled.div`
  flex: 1 1 auto;
`;

export default class Draggable extends React.Component {
  static propTypes = {
    /** Contents of the draggable. */
    children: PropTypes.node,
    /** Additional classes. */
    className: PropTypes.string,
    /** If dragging is disabled. */
    disabled: PropTypes.bool,
    /** Class to apply when dragging it. */
    draggingClass: PropTypes.string,
    /** Unique id for this item. */
    id: PropTypes.string.isRequired,
    /** Index of this item in the list. */
    index: PropTypes.number,
    /** Show drag handle to move items. If false, entire item is draggable. */
    showHandles: PropTypes.bool,
    /** Only allow vertical grab movements. */
    verticalDragOnly: PropTypes.bool,
  };

  static defaultProps = {
    showHandles: false,
    verticalDragOnly: false,
  };

  static DragHandle = DragHandle;

  getDraggingStyles = (provided, snapshot) => {
    const { style } = provided.draggableProps;
    const { isDragging } = snapshot;
    const transform = style.transform || '';
    const yOffset = transform.split(',')[1];
    if (isDragging && transform && yOffset) {
      return { style: {
        ...style,
        transform: `translate(0px,${yOffset}`
      }};
    }
    return {};
  }

  renderContents = (provided, snapshot) => {
    const { children, className, showHandles, verticalDragOnly } = this.props;
    return (
      <Container
        className={className}
        isDragging={snapshot.isDragging}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...(verticalDragOnly ? this.getDraggingStyles(provided, snapshot) : {})}
        {...(showHandles ? {} : provided.dragHandleProps)}
      >
        <ContentWrapper>{children}</ContentWrapper>
        {showHandles && <DragHandle {...provided.dragHandleProps} />}
      </Container>
    );
  };

  render() {
    const { disabled, id, index } = this.props;
    return (
      <DraggableDND
        key={id}
        draggableId={id}
        index={index}
        isDragDisabled={disabled}
      >
        {this.renderContents}
      </DraggableDND>
    );
  }
}
