import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import theme from '@matterapp/matter-theme';
import {
  KUDOS_DROPDOWN_ITEMS,
  KUDOS_LIST_BASE,
  getKudosType,
} from '../consts';
import { getThemeColor, isFunction } from '../utils';
import {
  SelectMenu,
  MENU_ITEM_HEIGHT_MOBILE,
  MENU_HAX_HEIGHT,
} from './styles';

/*****************************************************************************/
/* Hook for handling the animation.                                          */
/*****************************************************************************/
export const useAnimation = (params) => {
  const {
    onMouseEnter,
    playAnimation: playAnimationNow,
    playAnimationOnClick,
    playAnimationOnHover,
    type,
  } = params;
  const animationRef = useRef();

  const playAnimation = () => {
    if (animationRef && animationRef.current) {
      const { playAnimation, tl } = animationRef.current;
      if (isFunction(playAnimation)) {
        if (tl && isFunction(tl.isActive) && tl.isActive()) {
          // Play animation if timeline if not active.
          return;
        }
        playAnimation();
      }
    }
  };

  const resetAnimation = () => {
    const { resetAnimation } = animationRef.current;
    if (isFunction(resetAnimation)) {
      resetAnimation();
    }
  };

  useEffect(() => {
    if (playAnimationNow) {
      playAnimation();
    }
  }, [playAnimationNow]);

  return {
    animationRef,
    playAnimation,
    resetAnimation,
    handleClick: () => {
      if (playAnimationOnClick) {
        playAnimation();
      }
    },
    handleMouseEnter: (e) => {
      if (playAnimationOnHover) {
        playAnimation();
      }
      onMouseEnter(e, { ...params, type });
    },
  };
};

/*****************************************************************************/
/* Hook handling Kudos view mode.                                            */
/*****************************************************************************/
export const useKudosView = (params) => {
  const { maxMessageLength, text } = params;
  const textToDisplay =
    (text && text.slice && text.slice(0, maxMessageLength)) || text;
  return { text: textToDisplay };
};

/*****************************************************************************/
/* Hook handling Kudos edit mode.                                            */
/*****************************************************************************/
export const useKudosEdit = (params) => {
  const {
    isEditMode,
    maxMessageLength,
    onChangeMessage,
    placeholder,
    message,
    textColor
  } = params;
  const [value, setValue] = useState(message || '');
  const textAreaRef = useRef();

  return {
    containerProps: {
      isEdit: true,
    },
    textAreaProps: {
      textColor,
      autoHeight: true,
      autoHeightOnMount: true,
      isEditMode,
      onChange: (e, props) => {
        const { value } = props;
        const trimmedValue = value.substring(0, maxMessageLength);
        onChangeMessage(e, { ...props, value: trimmedValue });
        setValue(trimmedValue);
      },
      ref: textAreaRef,
      placeholder,
      value,
    },
  };
};

/*****************************************************************************/
/* Hook handling Kudos dropdown.                                             */
/*****************************************************************************/
const getDropdownStyle = (ref, selectedIndex) => {
  const { x, y, height } = ref.current.getBoundingClientRect();
  const isMobile = height === MENU_ITEM_HEIGHT_MOBILE;
  const topOffset = isMobile ? 4 : 1;
  const menuOffset = selectedIndex * height;

  if (isMobile) {
    const { innerHeight = 0 } = window || {};
    const calculatedTop = !!selectedIndex
      ? y - (menuOffset + topOffset)
      : y - topOffset;
    const top =
      calculatedTop + MENU_HAX_HEIGHT > innerHeight
        ? innerHeight - MENU_HAX_HEIGHT
        : calculatedTop;
    return { transform: `translate3d(${x}px, ${top}px, 0px)` };
  }

  const top = y - (menuOffset + topOffset);
  return { transform: `translate3d(${x}px, ${top}px, 0px)` };
};

export const useKudosDropdown = (params) => {
  const containerRef = useRef();
  const dropdownRef = useRef();
  const menuRef = useRef();
  const { kudosToShow, onChange, value } = params;
  const formatItems = kudosToShow.map((item) => ({
    ...item,
    value: item.type,
  }));

  const renderMenu = (menu, { value }) => {
    const selectedIndex = value
      ? KUDOS_DROPDOWN_ITEMS.findIndex((item) => item.label === value)
      : 0;
    const style = containerRef
      ? getDropdownStyle(containerRef, selectedIndex)
      : {};
    return (
      <div>
        {ReactDOM.createPortal(
          <SelectMenu ref={menuRef} style={style}>
            {menu}
          </SelectMenu>,
          document.getElementById('root')
        )}
      </div>
    );
  };

  return {
    dropdownContainerProps: {
      ref: containerRef,
    },
    items: formatItems,
    onChange,
    onSelect: () => {
      if (dropdownRef.current.blur) {
        dropdownRef.current.blur();
      }
    },
    ref: dropdownRef,
    renderMenu,
    value,
  };
};

/*****************************************************************************/
/* Hook the Card component.                                                  */
/*****************************************************************************/
export const useCard = (params) => {
  const {
    animationMountDelay,
    className,
    isEditMode,
    isLocked,
    onChangeType,
    playAnimationOnMount,
    playAnimationOnClick,
    type,
    totalKudosList,
    ...otherParams
  } = params;

  const [currentType, setCurrentType] = useState(type);
  const kudosFromList = totalKudosList && totalKudosList.find(kudos => kudos.type === type);
  let animation;
  let color;
  let label;
  let backgroundImage;
  let kudosPhotoUrl;
  let backgroundColor;
  let textColor;
  let textColorForCustom;

  if (kudosFromList?.isCustom) {
    const { backgroundSettings, iconFileName } = kudosFromList;
    label = kudosFromList.label;
    color = backgroundSettings.backgroundColor || `${theme.colors.blacks[40]}`;
    backgroundColor = backgroundSettings.backgroundColor;
    backgroundImage = backgroundSettings.backgroundImage;
    textColor = backgroundSettings.isLight ? `${theme.colors.blacks[70]}` : 'white' ;
    textColorForCustom = backgroundSettings.textColor || textColor;
    kudosPhotoUrl = iconFileName;
  } else {
    const kudosData = getKudosType(currentType, params);
    animation = kudosData.animation;
    color = kudosData.color;
    label = kudosData.label;
    const themeData = getThemeColor(color);
    backgroundColor = themeData.backgroundColor;
    textColor = themeData.textColor;
  }

  const { animationRef, handleClick, handleMouseEnter } = useAnimation(params);

  useEffect(() => {
    if (currentType !== type) {
      setCurrentType(type);
    }
  }, [type, setCurrentType]);

  return {
    ...params,
    AnimationComponent: animation,
    kudosPhotoUrl,
    animationProps: {
      ref: animationRef,
      playAnimationOnMount: isEditMode ? true : playAnimationOnMount,
      animationMountDelay,
      onClick: playAnimationOnClick ? handleClick : null,
      onMouseEnter: isEditMode ? handleMouseEnter : null,
      selector: className,
    },
    animationContainerProps: {
      isEditMode,
    },
    containerProps: {
      backgroundColor,
      backgroundImage,
      className,
      'data-type': type,
      onMouseEnter: isEditMode ? null : handleMouseEnter,
    },
    dropdownProps: {
      kudosToShow: totalKudosList ? totalKudosList : KUDOS_LIST_BASE,
      isLocked,
      textColor,
      onChange: (e, props) => {
        setCurrentType(props.value);
        onChangeType(e, props);
      },
      value: currentType,
    },
    editProps: {
      ...params,
    },
    label,
    labelColor: textColor === 'white' ? `${theme.colors.blacks[70]}` : textColor,
    lockedProps: {
      ...otherParams,
      textColor,
    },
    type: currentType,
    textColor: textColorForCustom || 'white',
  };
};

/*****************************************************************************/
/* Hook handling locked kudos.                                               */
/*****************************************************************************/
export const useLockedMessage = (params) => {
  const { color, onClickUnlock, textColor } = params;
  const backgroundColor = color[5];
  const textColorHover = color[60];
  return {
    ...params,
    containerProps: {
      backgroundColor,
      textColor,
      textColorHover,
    },
    onClickUnlock,
  };
};
