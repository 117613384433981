import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  GIVE_KUDOS_MUTATION,
  WORKSPACE_FEED_QUERY,
  GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE,
} from 'graphql-queries/queries';
import { getKudosDisabledMessage } from './shared';
import Toast from 'components/Toast';
import { trackRetentionEvent } from 'libs';
import { useLazyQuery } from '@apollo/client';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import { getDropdownInviteMessage } from 'hooks/composer/shared';

const KUDOS_SENT_TOAST = 'You have successfully given Kudos!';
const KUDOS_SENT_WORKSPACE_TOAST =
  'You have successfully given Kudos to a workspace member!';

const noop = () => null;

/*****************************************************************************/
/* GIVE KUDOS MUTATION                                                       */
/*****************************************************************************/
export const useGiveKudosMutation = () => {
  const [mutation, loading] = useMutation(GIVE_KUDOS_MUTATION);
  const giveKudos = (props) => {
    const { note, receiverUserIds, type, workspaceId } = props;
    const refetchQueries = [];

    if (workspaceId) {
      refetchQueries.push({
        query: WORKSPACE_FEED_QUERY,
        variables: { workspaceId },
      });
    }

    return mutation({
      variables: {
        isPublic: true,
        note,
        receiverUserIds,
        type,
        workspaceId,
      },
      refetchQueries,
    });
  };
  return [giveKudos, loading];
};

/*****************************************************************************/
/* GIVE KUDOS MODAL HOOK                                                     */
/*****************************************************************************/
export const useModal = (params = {}) => {
  const {
    disabledMessage,
    onCloseKudosModal = noop,
    isKudosModalOpen = false,
    showKudosSelection,
  } = params;
  const [isModalOpen, setModalOpen] = useState(isKudosModalOpen);
  const [isSentModalOpen, setSentModalOpen] = useState(false);
  const [selectedKudosType, setSelectedKudosType] = useState(null);

  useEffect(() => {
    if (isKudosModalOpen !== isModalOpen) {
      setModalOpen(isKudosModalOpen);
    }
  }, [isKudosModalOpen]);

  return {
    closeModal: () => setModalOpen(false),
    isModalOpen,
    modalProps: {
      disabledMessage,
      isOpen: isModalOpen,
      onClose: () => {
        setModalOpen(false);
        onCloseKudosModal();
      },
      selectedKudosType,
      setSelectedKudosType,
      showKudosSelection,
    },
    onClickKudos: (e, props) => {
      setSelectedKudosType(props.type);
      setModalOpen(true);
    },
    openModal: () => setModalOpen(true),
    openSentModal: () => setSentModalOpen(true),
    selectedKudosType,
    setSelectedKudosType,
    sentModalProps: {
      isOpen: isSentModalOpen,
      onCloseModal: () => setSentModalOpen(false),
    },
  };
};

/*****************************************************************************/
/* GIVE KUDOS HOOK                                                           */
/*****************************************************************************/
export const useGiveKudos = (params) => {
  const {
    lockedMessage,
    onCloseKudosModal = noop,
    selectedUser,
    showEmailInDropdown,
    showKudosSelection,
    showIsPublicDropdown,
    showLockedKudos,
    workspaceId,
    useSelectedUser,
    resetFeed,
    trackSubmission,
    workspace
  } = params;
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchMembers, { data, loading: isLoadingResults }] = useLazyQuery(searchWorkspaceMembers);

  async function onSearch(value) {
    if (value) {
      searchMembers({ 
        variables: {
          workspaceId,
          searchString: value,
          excludeCurrentUser: true
        }
      });
    }
  }

  const {
    closeModal,
    modalProps,
    onClickKudos,
    openModal,
    sentModalProps,
    setSelectedKudosType,
  } = useModal(params);
  const { data: kudosData, loading: kudosLoading } = useQuery(GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE, {
    variables: {
      workspaceId,
    },
  });

  const { customKudos: totalKudosList = [] } = kudosData || {};

  let composerResetFeed = resetFeed;
  if (!composerResetFeed) {
    const { composerProps } = params;
    composerResetFeed = composerProps && composerProps.resetFeed;
  }
  const [isSendingKudos, setIsSendingKudos] = useState(false);
  const [giveKudos] = useGiveKudosMutation();
  const [onSendCallback, setOnSendKudosCallback] = useState(null);
  const [currentSelectedUsers, setSelectedUsers] = useState(null);
  const { slackWorkspace } = workspace || {};
  const primaryChannel = slackWorkspace?.primaryChannel;

  return {
    ...params,
    closeModal: (args) => {
      setSelectedMembers([]);
      closeModal(args);
    },
    giveKudosProps: {
      onClickKudos,
      showLockedKudos,
      totalKudosList,
      loading: kudosLoading,
    },
    kudosModalProps: {
      ...modalProps,
      emptyMenuMessageNoItems: getDropdownInviteMessage(primaryChannel),
      isSendingKudos,
      lockedMessage,
      onSendKudos: async (e, props) => {
        if (e && e.stopPropagation) {
          e.stopPropagation();
        }
        setIsSendingKudos(true);
        try {
          await giveKudos({ workspaceId, ...props});
          trackRetentionEvent('kudos');
          closeModal();
          setSelectedMembers([]);
          onCloseKudosModal(e);
          setIsSendingKudos(false);
          if (composerResetFeed) {
            composerResetFeed();
          }
          Toast.success(
            !!workspaceId ? KUDOS_SENT_WORKSPACE_TOAST : KUDOS_SENT_TOAST
          );
          if (trackSubmission) { trackSubmission(); }
          if (onSendCallback && typeof onSendCallback === 'function') {
            onSendCallback(e, props);
          }
        } catch (error) {
          const giveKudosError = error.graphQLErrors[0];
          if (giveKudosError?.message) {
            Toast.error(giveKudosError.message);
          }
          setIsSendingKudos(false);
        }
      },
      onSearch,
      onChange: (members) => setSelectedMembers(members.map(({ email }) => email)),
      selectedUser,
      selectedUsers: currentSelectedUsers,
      showEmailInDropdown,
      showKudosSelection,
      showIsPublicDropdown,
      showLockedKudos,
      isLoadingResults,
      users: data?.searchMembers.map(member => ({
        ...member,
        fullName: member.person?.fullName,
        photoUrl: member.person?.photoUrl,
        userId: member.person?.userId,
        value: member.email,
        label: member.person?.fullName,
        subLabel: member.person?.email
      })).filter(member => {
        return !selectedMembers.find(email => member.email === email);
      }) || [],
      useSelectedUser,
      totalKudosList
    },
    openModal,
    setSelectedKudosType,
    setSelectedUsers,
    setOnSendKudosCallback: (callback) => setOnSendKudosCallback(callback),
    sentModalProps,
  };
};

/*****************************************************************************/
/* GIVE KUDOS HOOK (WORKSPACES)                                              */
/*****************************************************************************/
const getLockedMessage = ({ hasMultipleUsers, hasSentUpgradeSuggestion }) => {
  if (hasSentUpgradeSuggestion) {
    return 'Upgrade suggestion sent!';
  }
  if (!hasMultipleUsers) {
    return 'Another member needs to join first.';
  }
  return undefined;
};

export const useGiveKudosWorkspaces = (params) => {
  const {
    hasMultipleUsers,
    hasSentUpgradeSuggestion,
    invitePath,
    onClickUpgrade = noop,
    workspace = {},
  } = params;
  const { slackWorkspace } = workspace;
  const primaryChannel = slackWorkspace?.primaryChannel;
  const { closeModal, kudosModalProps, ...kudosParams } = useGiveKudos({
    ...params,
    disabledMessage: hasMultipleUsers ? null : getKudosDisabledMessage(invitePath, primaryChannel),
    lockedMessage: getLockedMessage({
      hasMultipleUsers,
      hasSentUpgradeSuggestion,
    }),
    showIsPublicDropdown: false,
    showLockedKudos: true,
    workspaceId: workspace.id,
    workspace
  });

  return {
    ...kudosParams,
    closeModal,
    kudosModalProps: {
      ...kudosModalProps,
      closeModal,
      onClickUnlock: (e) => {
        onClickUpgrade(e);
      },
    },
  };
};
