import React from 'react';
import LeftToLeft1 from '../Paths/LeftToLeft1';
import BasePath from './BasePath';

const Path5 = React.forwardRef((props, ref) => (
  <BasePath
    marginBottom={-18}
    PathComponent={LeftToLeft1}
    pointProps={{ top: 74.6, left: 2 }}
    ref={ref}
    {...props}
  />
));

export default Path5;