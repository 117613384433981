import React from 'react';
import { CheckboxPill } from '@matterapp/matter-ui';
import { Field as ReduxFormField } from 'redux-form';
import PropTypes from 'prop-types';
import { ABILITY_CHECKBOX_NAME } from '../../app-consts';

class AbilityCheckboxes extends React.Component {
  renderCheckbox = (reduxFormFieldProps) => {
    const { label, index, input } = reduxFormFieldProps;
    return <CheckboxPill key={label} label={label} index={index} {...input} />;
  };

  renderCheckboxes = () => {
    const { abilities } = this.props;
    return abilities.map((ability, index) => (
      <ReduxFormField
        key={ability.id}
        component={this.renderCheckbox}
        name={ABILITY_CHECKBOX_NAME(ability.id)}
        type="checkbox"
        label={ability.name}
        value={ability.id}
        index={index}
      />
    ));
  };
  render = () => (
    <ReduxFormField name="abilityIds" component={this.renderCheckboxes} />
  );
}

AbilityCheckboxes.propTypes = {
  abilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AbilityCheckboxes;
