import styled from 'styled-components';
import theme, {
  colors,
  spacing,
  lineHeight,
  media,
} from '@matterapp/matter-theme';

export const DEFAULT_AVATAR_SIZE = 48;

export const StyledContainer = styled.div`
  color: ${colors.blacks[90]};
  font-size: ${theme.fontSize.S};
  line-height: ${lineHeight.M};
  padding: ${theme.padding.base} ${theme.padding.base} ${spacing.single} ${theme.padding.base};
  padding-bottom: ${({ isEqualPadding }) => isEqualPadding ? spacing.singleAndQuarter : ''};
  ${media.S`
    padding: ${theme.padding.S} ${theme.padding.S} ${spacing.single} ${theme.padding.S};
    padding-bottom: ${({ isEqualPadding }) => isEqualPadding ? spacing.singleAndHalf : ''};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  line-height: ${lineHeight.M};
  font-size: ${theme.fontSize.S};
`;

export const AvatarContainer = styled.div`
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};
`;

export const Heading = styled.span`
  margin-left: ${spacing.single};
  margin-right: ${spacing.singleAndQuarter};
  align-self: center;
`;

export const HeaderContainer = styled.span`
  font-weight: ${theme.fontWeight.semiBold};
  color: ${({ isInactive }) => isInactive ? colors.blacks[60] : 'inherit'};
`;  

export const LeftContainer = styled.div`
  display: flex;
`;

export const MenuContainer = styled.div`
  position: absolute;
  right: ${theme.spacing.threeQuarters};
  ${theme.media.S`
    right: 18px;
  `}
`;
