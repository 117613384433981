import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';
import {
  GIVE_QUICK_NOTE_FEEDBACK_MUTATION,
  QUICK_NOTE_INNER_QUERY
} from 'graphql-queries/queries';
import Toast from 'components/Toast/Toast';
import { trackRetentionEvent } from 'libs';

const getSkillsList = (userSkills, recommendedSkills) => {
  const seen = new Set();
  const result = [];
  const add = (skill) => {
    if (!seen.has(skill.id)) {
      result.push({skill});
      seen.add(skill.id);
    }
  };
  userSkills.forEach(add);
  recommendedSkills.forEach(add);
  return result;
};

const withGiveFeedback = compose(
  graphql(GIVE_QUICK_NOTE_FEEDBACK_MUTATION, {
    name: 'giveFeedbackMutation',
  }),
  graphql(QUICK_NOTE_INNER_QUERY, {
    name: 'userSkills',
    options: ({ selectedPeer }) => ({
      variables: {
        id: selectedPeer.userId,
      },
    }),
    skip: (props) =>
      !props.selectedPeer || (props.selectedPeer && !props.selectedPeer.userId),
  }),
  withProps(
    ({
      giveFeedbackMutation,
      onCloseGiveFeedbackModal,
      setIsSending,
      userSkills,
      selectedPeer,
    }) => ({
      onGiveFeedback: ({ receiverUserId, note, skillId, workspaceId }) => {
        setIsSending(true);
        giveFeedbackMutation({
          variables: { receiverUserId, note, skillId, workspaceId }
        })
          .then(() => {
            setIsSending(false);
            trackRetentionEvent('feedback');
            onCloseGiveFeedbackModal();
            const messageTitle = <strong>Nicely done!</strong>;
            const successMessage = selectedPeer.firstName ? 
              (<span>{messageTitle}{` Your feedback was sent to ${selectedPeer.firstName}`}</span>) :
              (<span>{messageTitle}{` Your feedback was sent!`}</span>);
            Toast.success(successMessage);
          })
          .catch((error) => {
            setIsSending(false);
            const errorMessage = (<span><strong>Oops! </strong>Something
              went wrong. Your feedback could not be sent</span>);
            Toast.error(errorMessage);
          });
      },
      getUserSkills: () => {
        if (!userSkills) { return null; }
        const { user, recommendedSkills, loading } = userSkills;
        if (loading || !user) { return []; }
        const { skills } = user;
        return getSkillsList(skills, recommendedSkills);
      },
      isLoadingPeerSkills: userSkills && userSkills.loading,
    })
  )
);

export default withGiveFeedback;
