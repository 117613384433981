import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { ColorPalettes, getColorObj } from '@matterapp/matter-theme';
import SVG from '../../SVG';

const DEFAULT_SIZE = 120;
const STROKE_WIDTH = 12;

const Container = styled.div`
  position: relative;
  ${({ size }) => `
    height: ${size}px;
    width: ${size}px;
  `}
`;

const CircleBckground = styled.circle`
  fill: none;
  stroke: ${(props) => getColorObj(props.color)[10]}
  stroke-width: ${STROKE_WIDTH};
`;

const Circle = styled.circle`
  ${({ color, cx, percent }) => {
    const diameter = cx * 2 - STROKE_WIDTH;
    const circumference = Math.floor(diameter * Math.PI);
    const decimal = percent / 100;
    const distance = circumference - decimal * circumference;
    return `
      fill: none;
      stroke-dasharray: ${circumference};
      stroke-dashoffset: ${distance};
      stroke: ${getColorObj(color)[50]}
      stroke-linecap: round;
      stroke-width: ${STROKE_WIDTH};
      transform: rotate(-90deg);
      transform-origin: ${cx}px ${cx}px;
      transition: stroke-dashoffset ${theme.transitions.times.double};
    `;
  }}
`;

const ChildrenContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & img {
    display: block;
  }
`;

const Radial = (props) => {
  const { children, color, percent, size } = props;
  const radius = size / 2;
  const circleProps = {
    color,
    cx: radius,
    cy: radius,
    r: radius - STROKE_WIDTH / 2,
  };
  return (
    <Container size={size}>
      <SVG height={size} width={size} viewBox={SVG.viewBoxFromSize(DEFAULT_SIZE)}>
        <CircleBckground {...circleProps} />
        <Circle {...circleProps} percent={percent} />
      </SVG>
      {children && <ChildrenContainer>{children}</ChildrenContainer>}
    </Container>
  );
};

Radial.propTypes = {
  /** Content to display inside the radial progress. */
  children: PropTypes.node,
  /** Color of the radial progress. */
  color: PropTypes.oneOf(Object.keys(ColorPalettes)),
  /** The percent value. */
  percent: PropTypes.number,
  /** The size of the radial progress. */
  size: PropTypes.number,
};

Radial.defaultProps = {
  color: ColorPalettes.Red,
  size: DEFAULT_SIZE,
};

export default Radial;
