import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const height = 24;

const Track = styled.div`
  height: ${height}px;
  width: 100%;
  border-radius: ${height / 2}px;
  background: ${() => theme.colors.blacks[5]};
  overflow: hidden;
  box-shadow: ${() => theme.shadows[60]};
`;

const Meter = styled.div`
  width: 0;
  height: ${height}px;
  background: ${() => theme.colors.blues[40]};
  border-bottom: ${height / 2}px solid ${() => theme.colors.blues[50]};
  transition: width .2s ease;
  box-sizing: border-box;
`;

const ProgressMeter = ({
  percent,
}) => (
  <div>
    <Track>
      <Meter style={{ width: `${percent}%` }} />
    </Track>
  </div>
);

ProgressMeter.defaultProps = {
  percent: 0,
};

ProgressMeter.propTypes = {
  percent: PropTypes.number, // 0 - 100
};

export default ProgressMeter;
