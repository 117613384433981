import styled from 'styled-components';
import { Button, Modal, Peer } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

export const SubHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.fontSize.S,
  fontWeight: theme.fontWeight.semiBold,
  marginBottom: theme.spacing.half,
  justifyContent: 'space-between'
}));

export const AddMemberButton = styled(Button)(() => ({
  fontSize: theme.fontSize.base,
  lineHeight: theme.fontSize.base,
  padding: theme.spacing.half,
  minHeight: 0
}));

export const ExcludedMembersBlock = styled('div')(() => ({
  position: 'relative',
  paddingTop: theme.spacing.double
}));

export const StyledModalBody = styled(Modal.Body)(() => ({
  padding: theme.spacing.double,
  overflow: 'visible',
  '& > div:first-child': {
    overflowY: 'visible'
  },
  h5: {
    color: theme.colors.blacks[80],
    fontSize: theme.fontSize.base,
    fontWeight: theme.fontWeight.medium,
    marginBottom: '5px'
  }
}));

export const PeersWrapper = styled('div')(() => ({
  marginTop: theme.spacing.single
}));

export const PeerListItem = styled(Peer.ListItem)({
  position: 'relative',
  margin: 0,
  width: '100%'
});

export const RemoveButton = styled(Button)(() => ({
  position: 'absolute',
  top: '30%',
  right: 0,
  fontSize: theme.fontSize.XS,
  lineHeight: theme.fontSize.XS,
  padding: theme.spacing.half,
  minHeight: 0,
  color: theme.colors.blacks[50]
}));

export const MembersExplainText = styled.div(() => ({
  marginTop: theme.spacing.single,
  fontSize: theme.fontSize.S
}));