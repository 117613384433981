import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import avatarSvg from '../../../assets/svgs/avatar.svg';
import { getDefaultAvatar, getDefaultAvatarProps } from './DefaultAvatar';
import { Container, ContainerLink } from './styles';
import getPhotoUrlFromEmail from '../../libs/getPhotoUrlFromEmail';
import theme from '@matterapp/matter-theme';

const BORDER_SIZE = 4;
const DATA_ROLE = 'avatar';

const BorderFrame = styled.div`
  // Frame Required as CSS Borders around rounded images are not crisp
  position: absolute;
  top: 0;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 50%;
  border: ${BORDER_SIZE}px solid ${() => theme.colors.blues[5]};
  background: transparent;
`;

const StyledImage = styled.img`
  color: white;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;

  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  ${({ bordered }) =>
    bordered &&
    `
    border: ${BORDER_SIZE}px solid transparent;
  `}

  ${({ squared }) =>
    squared &&
    `
    border-radius: ${theme.spacing.quarter};
    border: 1px solid ${theme.colors.blacks[10]};
  `}

  ${({ squaredWithoutBorder }) =>
    squaredWithoutBorder &&
    `
    border-radius: ${theme.spacing.quarter};
    border: 1px solid transparent;
  `}

  // prevent jumpies
  display: inline-block;
`;

const buildAvatarUrl = ({
  url,
  email,
  size,
  defaultAvatarProps,
  defaultAvatarIsGenerated,
}) => {
  if (url) {
    return url;
  }
  if (email) {
    return getPhotoUrlFromEmail({
      defaultAvatarProps: getDefaultAvatarProps({
        size,
        hashString: email,
        ...defaultAvatarProps,
      }),
      defaultAvatarIsGenerated,
      email,
      size: 256,
    });
  }

  return defaultAvatarIsGenerated
    ? getDefaultAvatar({ ...defaultAvatarProps, size })
    : avatarSvg;
};

const AvatarBase = ({
  children,
  to,
  url,
  email,
  size,
  height,
  width,
  marginTop,
  marginBottom,
  bordered,
  squared,
  squaredWithoutBorder,
  defaultAvatarIsGenerated,
  defaultAvatarProps,
  customAvatar
}) => {
  const AvatarContainer = to ? ContainerLink : Container;

  return (
    <>
      <AvatarContainer
        to={to}
        data-role={DATA_ROLE}
        width={width || size}
        height={height || size}
      >
        {!customAvatar && <StyledImage
          src={buildAvatarUrl({
            url,
            email,
            size,
            defaultAvatarProps,
            defaultAvatarIsGenerated,
          })}
          bordered={bordered}
          squared={squared}
          squaredWithoutBorder={squaredWithoutBorder}
          width={width || size}
          height={height || size}
          marginTop={marginTop}
          marginBottom={marginBottom}
          alt="Matter user"
          data-role={DATA_ROLE}
        />}
        {customAvatar}
        {bordered && <BorderFrame width={size} height={size} />}
      </AvatarContainer>
      {children}
    </>
  );
};

AvatarBase.defaultProps = {
  to: null,
  url: null,
  email: null,
  size: 128,
  marginTop: 0,
  marginBottom: 0,
  bordered: false,
  squared: false,
  defaultAvatarIsGenerated: false,
  defaultAvatarProps: {},
};

AvatarBase.propTypes = {
  // Optional link to go to if avatar is clicked.
  to: PropTypes.string,
  url: PropTypes.string,
  email: PropTypes.string,
  bordered: PropTypes.bool,
  squared: PropTypes.bool,
  size: PropTypes.number,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  defaultAvatarIsGenerated: PropTypes.bool,
  defaultAvatarProps: PropTypes.shape({
    size: PropTypes.oneOf(['large', 'small']),
    variant: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  }),
  children: PropTypes.node,
};

AvatarBase.DateRole = DATA_ROLE;
AvatarBase.StyledImage = StyledImage;

export default AvatarBase;
