import React from 'react';
import PropTypes from 'prop-types';

const Heading = ({
  children,
  as: Component,
  textAlign,
  className,
  marginBottom,
  strong,
  style,
}) => {
  let splitChildren = children;
  if (children.split) {
    splitChildren = children.split('\n').map((item, i) => (
      <span key={`header_line_${i}`}>{item}<br /></span> // eslint-disable-line
    ));
  }

  return (
    <Component
      className={className}
      style={{
        marginBottom,
        textAlign,
        fontWeight: strong ? 'bold' : 'normal',
        ...style,
      }}
    >
      {splitChildren}
    </Component>
  );
};

Heading.defaultProps = {
  children: <div />,
  as: 'h1',
  textAlign: 'center',
  strong: false,
  marginBottom: 24,
  className: '',
  style: {},
};

Heading.propTypes = {
  children: PropTypes.node,
  as: PropTypes.string,
  marginBottom: PropTypes.number,
  textAlign: PropTypes.string,
  strong: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

export default Heading;
