import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from './Header';
import Skill from '../Skill';
import { ButtonNew } from '../Button';
import { TextAreaWithAvatar } from '../TextAreaWithAvatar';
import theme from '@matterapp/matter-theme';

class QuickNote extends React.Component {
  static skillListDesktopMaxHeight = 359;
  static headerHeight = 65;

  static TextArea = TextAreaWithAvatar.TextArea;

  static Container = styled.div`
    background: white;
    width: 100%;
    height: 100%;

    ${() => theme.media.medium`
      max-width: 602px;
      height: auto;
      border-radius: 12px;
    `}
  `;

  static SkillTagsListWrapper = styled.div`
    position: relative;
    z-index: 100;
    height: calc(100vh - ${QuickNote.headerHeight}px);
    overflow: auto;
    border-radius: 0 0 12px 12px;

    padding-bottom: 148px; // Workaround iOS Address Bar in 100vh Layouts
    -webkit-overflow-scrolling: touch;

    ${() => theme.media.medium`
      padding-bottom: 0;
      max-height: ${QuickNote.skillListDesktopMaxHeight}px;

      > div:first-child {
        max-height: ${QuickNote.skillListDesktopMaxHeight}px;
        overflow: auto;
      }
    `}
  `;

  static BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 24px;
  `;

  static BodyInnerContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
  `;

  static AvatarContainer = styled.div`
    margin-right: 16px;
  `;

  constructor(props) {
    super(props);
    this.state = {
      isFeedbackMode: true,
    };
  }

  handleToggleFeedbackModeOn = () => {
    this.setState({ isFeedbackMode: true });
  };

  handleToggleFeedbackModeOff = () => {
    this.setState({ isFeedbackMode: false });
  };

  renderHeader() {
    const { isFeedbackMode } = this.state;
    const {
      submitFeedbackButton,
      onRequestClose,
      receiver,
      taggedSkill,
    } = this.props;

    if (isFeedbackMode)
    {return (
      <Header
        left={<ButtonNew onClick={onRequestClose} icon="close" link />}
        titleDesktop={`Give Feedback to ${receiver.firstName}`}
        titleMobile={receiver.firstName}
        right={submitFeedbackButton}
      />
    );}

    return (
      <Header
        left={
          <ButtonNew
            onClick={this.handleToggleFeedbackModeOn}
            icon="arrow-left"
            link
          />
        }
        titleDesktop="Tag a skill"
        titleMobile="Tag a skill"
        right={
          <ButtonNew
            primary
            size="xsmall"
            disabled={!taggedSkill}
            onClick={this.handleToggleFeedbackModeOn}
          >
            Done
          </ButtonNew>
        }
      />
    );
  }

  renderBody() {
    const { skillTagList, taggedSkill, onResetTaggedSkill, giver } = this.props;
    const { isFeedbackMode } = this.state;

    if (isFeedbackMode)
    {return (
      <TextAreaWithAvatar avatarUrl={giver.photoUrl}>
        {this.props.textarea}
        {taggedSkill === null ? (
          <Skill.Tagger.Empty onClick={this.handleToggleFeedbackModeOff} />
        ) : (
          <Skill.Tagger.Basic
            skill={taggedSkill}
            onReset={onResetTaggedSkill}
          />
        )}
      </TextAreaWithAvatar>
    );}

    return (
      <QuickNote.SkillTagsListWrapper>
        {skillTagList}
      </QuickNote.SkillTagsListWrapper>
    );
  }

  render() {
    return (
      <QuickNote.Container>
        {this.renderHeader()}
        {this.renderBody()}
      </QuickNote.Container>
    );
  }
}

QuickNote.defaultProps = {
  taggedSkill: null,
};

QuickNote.propTypes = {
  submitFeedbackButton: PropTypes.node.isRequired,
  onResetTaggedSkill: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  textarea: PropTypes.node.isRequired,
  skillTagList: PropTypes.node.isRequired,
  receiver: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
  }).isRequired,
  giver: PropTypes.shape({
    photoUrl: PropTypes.string,
  }).isRequired,
  taggedSkill: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default QuickNote;
