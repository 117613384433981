import React from 'react';
import PropTypes from 'prop-types';
import SkillsSelectionList from './SkillsSelectionList';
import MessageBox from './MessageBox';
import { useCustomization } from './hooks';
import { Wrapper, Body, BodyWrapper, Divider, MessageWrapper } from './sharedStyle';

const Customization = (props) => {
  const {
    bodyRef,
    children,
    contentHeight,
    isOpen,
    messageProps,
    skillsSelectionListProps,
  } = useCustomization(props);

  return (
    <Wrapper open={isOpen} height={contentHeight}>
      <Body ref={bodyRef}>
        <Divider />
        <BodyWrapper>
          <SkillsSelectionList {...skillsSelectionListProps} />
        </BodyWrapper>
        <Divider />
        <MessageWrapper>
          <MessageBox {...messageProps} />
        </MessageWrapper>
        {children}
      </Body>
    </Wrapper>
  );
};

Customization.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  messageProps: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  userSkills: PropTypes.array.isRequired,
};

Customization.defaultProps = {
  messageProps: {
    onChange: () => null,
  },
  tracking: {
    skillPillClick: () => {},
    clearText: () => {},
  },
  userSkills: [],
};

export default Customization;
