import styled from 'styled-components';
import Button from './Default';
import theme from '@matterapp/matter-theme';

const getColor = ({ color, baseColor, defaultColor, isLink, linkColor }) => {
  if (color) {
    return baseColor;
  } else if (isLink) {
    return linkColor;
  }
  return defaultColor;
};

const getColorStyles = ({ color, disabled, isLink }) => {
  const baseColor = theme.colors[`${color}s`] || theme.colors.blues;
  const shared = `
    background: transparent;
    border: none;

    & svg path {
      fill: ${isLink ? theme.colors.blues[50] : theme.colors.blacks[50]}
      transition: fill ${theme.transitions.times.default};
    }
  `;

  if (!disabled) {
    const hoverFill = getColor({
      color,
      baseColor: baseColor[50],
      defaultColor: theme.colors.purples[60],
      isLink,
      linkColor: theme.colors.purples[60],
    });
    return `
      ${shared};

      &:hover {
        & svg path {
          fill: ${hoverFill};
        }
      }
      &:active {
        & svg path {
          fill: ${theme.colors.blacks[90]};
        }
      }
    `;
  }
  return shared;
};

const IconButton = styled(Button).attrs(() => ({
  isSquare: true,
}))`
  ${getColorStyles}
`;

export default IconButton;
