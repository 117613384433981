import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckIcon,
  MenuItemContainer,
  MenuItemContentContainer,
} from './styles';

const MenuItem = (props) => {
  const { actionItem, avatar, isActive, onClick, to, content } = props;
  
  return (
    <MenuItemContainer
      actionItem={actionItem}
      isActive={isActive}
      onClick={onClick}
      to={isActive ? null : to}
    >
      {avatar}
      <MenuItemContentContainer actionItem={actionItem}>
        {content}
      </MenuItemContentContainer>
      {isActive && <CheckIcon />}
    </MenuItemContainer>
  );
};

MenuItem.propTypes = {
  /** If the menu item is an action item. */
  actionItem: PropTypes.bool,
  /** Avatar to display in the menu item. */
  avatar: PropTypes.node,
  /** Content of the menu item. */
  content: PropTypes.node,
  /** If the menu item is active. */
  isActive: PropTypes.bool,
  /** Link to go to when menu item is clicked. */
  to: PropTypes.string,
};

export default MenuItem;
