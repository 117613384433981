import React from 'react';
import BasePath from './BasePath';

const DEFAULT_HEIGHT = 280;
const PATH_LENGTH = 462;

const LeftToCenter1 = React.forwardRef((props, ref) => (
  <BasePath
    {...props}
    ref={ref}
    height={DEFAULT_HEIGHT}
    pathLength={PATH_LENGTH}
    pathCode={
      `M10,8.5v161c0,6.625,2.686,12.625,7.029,16.969S27.372,193.5,34,193.5h171.5c6.628,0,12.628,2.688,16.971,7.031
      s7.029,10.344,7.029,16.969v54`
    }
  />
));

export default LeftToCenter1;
