import React from 'react';
import PropTypes from 'prop-types';
import Item from './Item';
import List from '../../List';
import {
  Container,
  MenuButton,
  MenuContainer,
  MenuIcon,
  Wrapper,
} from './styles';

export default class Inline extends React.PureComponent {
  static propTypes = {
    /** If menu can be opened in mobile. If false, use onClick callback to trigger another action. */
    canExpandOnMobile: PropTypes.bool,
    /** Additional style class. */
    className: PropTypes.string,
    /** MUST BE OF TYPE `Menu.Inline.Item` */
    children: PropTypes.node,
    /** Callback when menu button is clicked. (Use case: Open modal in mobile since you cannot hover.) */
    onClick: PropTypes.func,
    /** Parent component to hover to trigger opening of menu. */
    parentHoverComponent: PropTypes.elementType,
  };

  static defaultProps = {
    canExpandOnMobile: false,
    parentHoverComponent: List.ItemWithAvatar,
  };

  static Item = Item;
  static MenuContainer = MenuContainer;

  constructor(props) {
    super(props);
    this.state = { menuContainerWidth: 0, menuOpen: false };
    this.menuContainerRef = React.createRef();
    this.closeTimeout = null;
  }

  componentDidMount = () => {
    if (this.menuContainerRef && this.menuContainerRef.current) {
      this.setState({
        menuContainerWidth: this.menuContainerRef.current.offsetWidth,
      });
    }
  };

  componentWillUnmount = () => {
    this._mounted = false;
    clearTimeout(this.closeTimeout);
  };

  /**
   * Opens MenuContainer when mouse enters the MenuButton.
   * @returns { void }
   */
  handleMouseEnter = () => {
    this.setState({ menuOpen: true });
  };

  /**
   * Closes MenuContainer when mouse leaves the MenuContainer.
   * @returns { void }
   */
  handleMouseLeave = () => {
    this.setState({ menuOpen: false });
  };

  /**
   * Clicking on the MenuContainer should close it.
   * @param { Object } e: The click event to close the menu container.
   * @returns { void }
   */
  handleClickMenuContainer = (e) => {
    this.handleMouseLeave(e);
  };

  isMenuItem = (item) => {
    return item && item.type === Item;
  };

  renderChildren = () => {
    return React.Children.map(this.props.children, (child) => {
      return this.isMenuItem(child)
        ? React.cloneElement(child, {
          ...child.props,
          canHover: this.state.menuOpen,
          closeMenu: this.handleMouseLeave,
        })
        : child;
    });
  };

  render() {
    const { className, canExpandOnMobile, onClick, parentHoverComponent } = this.props;
    const { menuContainerWidth, menuOpen } = this.state;
    return (
      <Container
        className={className}
        canExpandOnMobile={canExpandOnMobile}
        menuOpen={menuOpen}
        onMouseLeave={this.handleMouseLeave}
        parentHoverComponent={parentHoverComponent}
      >
        <Wrapper>
          <MenuContainer
            onClick={this.handleClickMenuContainer}
            onMouseLeave={this.handleMouseLeave}
            menuContainerWidth={menuContainerWidth}
            ref={this.menuContainerRef}
          >
            {this.renderChildren()}
          </MenuContainer>
          <MenuButton onMouseEnter={this.handleMouseEnter} onClick={onClick}>
            <MenuIcon />
          </MenuButton>
        </Wrapper>
      </Container>
    );
  }
}
