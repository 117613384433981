import React from 'react';
import Animation from '../../../Animations/Animation';
import SVG from '../../../SVG';
import { BACKGROUND_WIDTH } from './styles';

export default class BaseBackground extends Animation {
  static defaultProps = {
    playAnimationOnMount: false,
  };

  static SVG = (props) => (
    <SVG
      {...props}
      height={props.height}
      width={BACKGROUND_WIDTH}
      viewBox={`0 0 ${BACKGROUND_WIDTH} ${props.height}`}
    />
  );

  resetAnimation = () => null;
}
