import React from 'react';
import { Routes } from 'react-router-dom';
import {
  SettingsMain,
  SettingsGeneral,
  SettingsFeedbackFriday,
  SettingsKudos,
  SettingsBilling,
  SettingsHris,
  SettingsRewards,
  SettingsCelebrations,
  SettingsPulse,
  SettingsMembers,
  SettingsDelete
} from './bindings/workspaceSettings';

export default () => (
  <Routes>
    {SettingsMain}
    {SettingsGeneral}
    {SettingsFeedbackFriday}
    {SettingsKudos}
    {SettingsBilling}
    {SettingsHris}
    {SettingsRewards}
    {SettingsCelebrations}
    {SettingsPulse}
    {SettingsMembers}
    {SettingsDelete}
  </Routes>
);
