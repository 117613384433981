import React from 'react';
import PropTypes from 'prop-types';
import { YellowCard } from '../../YellowCard';
import { Modal } from '../../Modal';
import Emoji from '../../Emoji';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { ButtonNew as Button } from '../../Button';

const ModalWrapper = styled.div`
  padding: 20px;
  ${theme.media.medium`
    padding: 24px;
  `}
`;

const CloserWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fontSize.S};
  margin-top: 32px;
  margin-bottom: 8px;
`;


const Header = styled.div`
  color: black;
  text-align: left;
  border-bottom: solid 1px ${theme.colors.blacks[10]};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.fontSizes[55]};
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0 0;
  h4 {
    font-weight: 600;
    line-height: 24px;
    font-size: 17px;
    margin: unset;
  }

  p {
    font-size: 14px;
  }
  > div:first-of-type {
    margin-left: 5px;
    margin-top: 3px;
    margin-right: 15px;
  }
`;

const data = [
  {
    emoji: "🙌",
    description: "Your peers can give feedback without making an account.",
    title: "No sign up required"
  },
  {
    emoji: "🔮",
    description: "We recommend asking at least 6 people like coworkers, former colleagues, clients, and anyone you work with.",
    title: "Gather all perspectives"
  },
  {
    emoji: "🎨",
    description: "Increase your odds of hearing back by selecting up to six skills and customizing your request message.",
    title: "Personalize your request"
  },
  {
    emoji: "💬",
    description: "Try personally following up over email, Slack, or text to remind and proactively thank them for their time.",
    title: "Didn’t hear back?"
  }
];

const FactsModal = ({
  isOpen,
  onClose
}) => {
  return (
    <YellowCard
      minimal
      isOpen={isOpen}
      handleRequestClose={onClose}
      size='medium'
      transparent
      showConfirmCloseModal={false}
      body={<ModalWrapper>
        <Header>
          Here’s what to expect
        </Header>
        <Modal.Body>
          {data.map((item, i) => (
            <Item key={i}>
              <div>
                <Emoji.Image theString={item.emoji} size={17} />
              </div>
              <div>
                <h4>{item.title}</h4>
                <p>
                  {item.description}
                </p>
              </div>
            </Item>
          ))}
          <CloserWrapper>
            <Button onClick={onClose} link>
              Close
            </Button>
          </CloserWrapper>

        </Modal.Body>
      </ModalWrapper>}
    />

  );
};

FactsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FactsModal;
