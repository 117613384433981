import { withStateHandlers } from 'recompose';

const withConfirm = withStateHandlers(
  ({ initialIsConfirmOpen = false }) => ({
    isConfirmOpen: initialIsConfirmOpen,
  }),
  {
    handleOpenConfirm: () => () => ({
      isConfirmOpen: true,
    }),
    handleCloseConfirm: () => () => ({
      isConfirmOpen: false,
    }),
  }
);

export default withConfirm;
