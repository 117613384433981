import { useEffect } from 'react';
import ReactModal from 'react-modal';
import { LOCKED_BODY_CLASS, LOCKED_BODY_CLASS_ALT } from '../../consts';

export const toggleBodyLock = (props) => {
  const { isOpen, bodyOpenClassName, useAltLockedClass } = props;
  document.body.classList.toggle(useAltLockedClass ? LOCKED_BODY_CLASS_ALT : LOCKED_BODY_CLASS, isOpen);
  document.body.classList.toggle(bodyOpenClassName, isOpen);
};

export const useAdapterState = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { appElement, bodyOpenClassName, onRequestClose, useAltLockedClass, ...otherProps } = props;
  useEffect(() => {
    ReactModal.setAppElement(appElement);
    toggleBodyLock(props);
  }, []);

  useEffect(() => {
    toggleBodyLock(props);
    return () => {
      // Submiting a note needs to remove the lock
      document.body.classList.remove(useAltLockedClass ? LOCKED_BODY_CLASS_ALT : LOCKED_BODY_CLASS);
      document.body.classList.remove(bodyOpenClassName);
    };
  }, [props]);

  return { bodyOpenClassName, ...otherProps };
};