import { saveError } from './libs/createError';

export const INVALID_NUMBER = 101;
export const PHONE_IN_USE = 102;
export const PHONE_VERIFICATION_FAILED = 104;
export const INCORRECT_PHONE_VERIFICATION_CODE = 105;
export const NO_PENDING_VERIFICATIONS = 106;
export const INVALID_JOB_CATEGORY_ID = 108;
export const INVALID_ROLE_ID = 109;
export const INVALID_QUALITY_ID = 110;
export const TOO_MANY_QUALITIES = 111;
export const DUPLICATE_QUALITIES = 112;
export const INVALID_SKILL_ID = 113;
export const TOO_MANY_SKILLS = 114;
export const DUPLICATE_SKILLS = 115;
export const EMAIL_IN_USE = 116;
export const EMAIL_FOR_USER_ALREADY_EXISTS = 118;
export const INVALID_TOKEN = 119;
export const EMAIL_ALREADY_VERIFIED = 120;
export const USER_HAS_NO_EMAIL = 121;
export const UNAUTHORIZED_ACCESS = 122;
export const INVALID_INVITATION_UUID = 123;
export const CANT_RATE_SELF = 124;
export const INVALID_FEEDBACK_ID = 125;
export const TOO_FEW_QUALITIES = 127;
export const TOO_FEW_SKILLS = 128;
export const FEEDBACK_ALREADY_OVER = 129;
export const RATING_TOO_HIGH = 130;
export const RATING_TOO_LOW = 131;
export const RATING_NOT_INTEGER = 132;
export const NOT_ENOUGH_DISTINCT_RESPONSES = 133;
export const ONBOARDING_INCOMPLETE = 134;
export const INVALID_YEARS_OF_EXPERIENCE = 135;
export const INVALID_EMAIL = 136;
export const ACCOUNT_NOT_FOUND = 137;
export const DUPLICATE_SKILL_NAME = 138;
export const TOO_FEW_ABILITIES = 139;
export const TOO_MANY_ABILITIES = 140;
export const CANT_CREATE_URL_INVITATION = 142;
export const CANT_DELETE_PRIMARY = 143;
export const EMAIL_NOT_ASSOCIATED_WITH_USER = 144;
export const EXPIRED_CODE = 145;
export const INVALID_CODE = 146;
export const INVALID_REASON = 147;
export const NOT_FOUND = 148;
export const MULTIPLE_WORKSPACES = 149;
export const ONE_ROOT_WORKSPACE = 150;
export const NO_OWNER = 151;
export const SLACK_GENERIC_ERROR = 152;
export const SLACK_CANT_USE_PRIVATE_CHANNEL = 153;
export const INVALID_INVITATION_ID = 154;
export const USER_IS_REMOVED = 156;
export const CURRENT_USER_IS_REMOVED = 157;
export const SELF_FEEDBACK = 158;
export const RESTRICTIVE_ACCOUNT = 159;
export const SLACK_WORKSPACE_TAKEN = 160;
export const MEMBER_TOKEN_ALREADY_USED = 161;
export const INVALID_CHARS_IN_CHANNEL_NAME = 162;
export const INSUFFICIENT_REWARD_FUNDS = 163;

saveError({
  code: INVALID_NUMBER,
  short: 'Invalid number',
  message: 'Sorry, but that phone number is invalid. Try again!',
  explanation:
    'This error is thrown when a phone number is not a valid US number',
});
saveError({
  code: PHONE_IN_USE,
  short: 'Phone in use',
  message:
    'The phone number you’re trying to use is already associated with another account.',
  explanation:
    'This error is thrown when a user tries to sign up with a phone number that is already in use by another user',
});
saveError({
  code: INCORRECT_PHONE_VERIFICATION_CODE,
  short: 'Incorrect verification code',
  message: 'That code wasn’t valid. Give it another go!',
  explanation:
    'This error is thrown when a user gives the wrong verification code for the phone number authentication',
});
saveError({
  code: NO_PENDING_VERIFICATIONS,
  short: 'No pending verifications',
  message:
    'First you need a verification code! Click the "Resend the code" link below.',
  explanation:
    'This error is thrown when a user sends a verification code for a number that does not have a code request on',
});
saveError({
  code: PHONE_VERIFICATION_FAILED,
  short: 'Phone verification failed',
  message: 'Something went wrong! Give it another go!',
  explanation:
    'This error is thrown when there is a verification error, even thoug the code was correct. Most likely due to a network error. Trying again might work.',
});
saveError({
  code: INVALID_JOB_CATEGORY_ID,
  short: 'Invalid job category id',
  message: 'Something went wrong! That job category does not exist.',
  explanation:
    'This error is thrown when a user picks a job category id that isnt attached to a real job category in the db',
});
saveError({
  code: INVALID_ROLE_ID,
  short: 'Invalid role id',
  message: 'Something went wrong! That role does not exist.',
  explanation:
    'This error is thrown when a user picks a role id that isnt attached to a real role in the db',
});
saveError({
  code: INVALID_QUALITY_ID,
  short: 'Invalid quality id',
  message: 'Something went wrong! That quality does not exist.',
  explanation:
    'This error is thrown when a user picks a quality id that isnt attached to a real quality in the db',
});
saveError({
  code: TOO_MANY_QUALITIES,
  short: 'Too many qualities',
  message: 'You selected too many qualities. Try again!',
  explanation:
    'This error is thrown when a user picks more qualities than they are allowed',
});
saveError({
  code: DUPLICATE_QUALITIES,
  short: 'Duplicate qualities',
  message: 'You tried to select the same quality twice. Try again!',
  explanation:
    'This error is thrown when a users pick of quality ids has the same quality id more than once',
});
saveError({
  code: INVALID_SKILL_ID,
  short: 'Invalid skill id',
  message: 'Something went wrong! That skill does not exist.',
  explanation:
    'This error is thrown when a user picks a skill id that isnt attached to a real skill in the db',
});
saveError({
  code: TOO_MANY_SKILLS,
  short: 'Too many skills',
  message: 'You selected too many skills. Try again!',
  explanation:
    'This error is thrown when a user picks more skills than they are allowed',
});
saveError({
  code: DUPLICATE_SKILLS,
  short: 'Duplicate skills',
  message: 'You tried to select the same skill twice. Try again!',
  explanation:
    'This error is thrown when a users pick of skill ids has the same skill id more than once',
});
saveError({
  code: EMAIL_IN_USE,
  short: 'Email in use',
  message:
    'The email you’re trying to use is already associated with another account.',
  explanation:
    'This error is thrown when a user tries to sign up with an email that is already in use by another user',
});
saveError({
  code: EMAIL_FOR_USER_ALREADY_EXISTS,
  short: 'Email for user already exists',
  message: 'MVP notice! At this time, you can not update your account’s email.',
  explanation:
    'This error is thrown when a user who already has a verified email in the db tries to set their email',
});
saveError({
  code: INVALID_TOKEN,
  short: 'Invalid token',
  message: 'Invalid token',
  explanation:
    'This error is thrown when a user tries an invalid token for any purpose. Could be email verification, authentication etc.',
});
saveError({
  code: EMAIL_ALREADY_VERIFIED,
  short: 'Email already verified',
  message: 'Good news! Your email has already been verified.',
  explanation:
    'This error is thrown when a user who already had their email verified tries to verify their email',
});
saveError({
  code: USER_HAS_NO_EMAIL,
  short: 'User has no email',
  message: 'First, you need an email associated to your account!',
  explanation:
    'This error is thrown when a user requests to be resent the email verification email, while having no email in the db',
});

// below here is not copywritten

saveError({
  code: UNAUTHORIZED_ACCESS,
  short: 'Unauthorized access',
  message: 'You are not authorized to access this resource',
  explanation:
    'This error is thrown when a user tries to access data without permission',
});
saveError({
  code: INVALID_INVITATION_UUID,
  short: 'Invalid invitation uuid',
  message: 'You are trying to access an invitation with an invalid uuid',
  explanation:
    'This error is thrown when a user tries to access an invitation with a uuid that doesnt exist',
});
saveError({
  code: CANT_RATE_SELF,
  short: 'Cant rate self',
  message: 'You can not rate yourself',
  explanation:
    'This error is thrown when a user tries to give feedback to themselves',
});
saveError({
  code: INVALID_FEEDBACK_ID,
  short: 'Invalid feedback id',
  message: 'You are trying to access a feedback with an invalid feedback id',
  explanation:
    'This error is thrown when a user tries to access a feedback with an invalid id',
});
saveError({
  code: TOO_FEW_QUALITIES,
  short: 'Too few qualities',
  message:
    'You have selected too few qualities. Make sure you have selected the right amount',
  explanation:
    'This error is thrown when a user tries to select less than 3 qualities',
});
saveError({
  code: TOO_FEW_SKILLS,
  short: 'Too few skills',
  message:
    'You have selected too few skills. Make sure you have selected the right amount',
  explanation:
    'This error is thrown when a user tries to select less than 3 skills',
});
saveError({
  code: FEEDBACK_ALREADY_OVER,
  short: 'Feedback already over',
  message: 'You have already submitted this feedback',
  explanation:
    'This error is thrown when a user tries to finalize a feedback session which was already finalized',
});
saveError({
  code: RATING_TOO_HIGH,
  short: 'Rating too high',
  message: 'You cannot rate above 10.',
  explanation:
    'This error is thrown when a user tries to give a rating above 10.',
});
saveError({
  code: RATING_TOO_LOW,
  short: 'Rating too low',
  message: 'You cannot rate below 0.',
  explanation:
    'This error is thrown when a user tries to give a rating below 0.',
});
saveError({
  code: RATING_NOT_INTEGER,
  short: 'Rating not integer',
  message: 'The rating has to be an integer.',
  explanation:
    'This error is thrown when a user tries to give a rating that is not an integer.',
});
saveError({
  code: NOT_ENOUGH_DISTINCT_RESPONSES,
  short: 'Not enough skill ratings',
  message: ({ numDistinctRatersRequired }) => {
    const subject = numDistinctRatersRequired > 1 ? 'people' : 'person';
    return `Get rated by ${numDistinctRatersRequired} more ${subject} to see your rating`;
  },
  explanation:
    'This error is thrown when a user tries to access their feedback summary without the required number of ratings.',
});
saveError({
  code: ONBOARDING_INCOMPLETE,
  short: 'Onboarding incomplete',
  message: 'You can not proceed without setting your skills and full name.',
  explanation:
    'This error is thrown when a user tries to mark himself as onboarded, even though they arent',
});
saveError({
  code: INVALID_YEARS_OF_EXPERIENCE,
  short: 'Invalid years of experience',
  message:
    'We could not recognize those years of experience. Please pick from the provided set.',
  explanation:
    'This error is thrown when a user tries to set an unrecognized value for their years of experience.',
});
saveError({
  code: INVALID_EMAIL,
  short: 'Invalid email',
  message: 'Please try a valid email.',
  explanation: 'This error is thrown when a user tries to set an invalid email',
});
saveError({
  code: ACCOUNT_NOT_FOUND,
  short: 'Account not found',
  message: 'Account not found. Try again another email or phone number',
  explanation: 'This error is thrown when a user tries to set an invalid email',
});
saveError({
  code: DUPLICATE_SKILL_NAME,
  short: 'Duplicate skill name',
  message: 'You already have another active skill with the same name.',
  explanation:
    'This error is thrown when a user takes an action that results in duplicate active skill names',
});
saveError({
  code: TOO_FEW_ABILITIES,
  short: 'Too few abilities',
  message:
    'You have selected too few abilities. Make sure you have selected the right amount',
  explanation:
    'This error is thrown when a user tries to select less than 1 ability',
});
saveError({
  code: TOO_MANY_ABILITIES,
  short: 'Too many abilities',
  message:
    'You have selected too many abilities. Make sure you have selected the right amount',
  explanation:
    'This error is thrown when a user tries to select more than 3 abilities',
});
saveError({
  code: CANT_CREATE_URL_INVITATION,
  short: 'Try again.',
  message: 'Could not create invite link. Try again later.',
  explantion:
    'This error occurs when the max number of attempts at creating a unique URL invitation are created. If this is happening regularly, we probably need to change the way we create url invitation ids.'
});
saveError({
  code: CANT_DELETE_PRIMARY,
  short: 'You can not delete your Primary email.',
  message: 'You cannot delete your Primary email. Please add another email before you try to delete this email.',
  explantion:
    'You must have a valid email to user Matter.  You cannot delete an email if you have no other emails'
});
saveError({
  code: EMAIL_NOT_ASSOCIATED_WITH_USER,
  short: 'Email is not associated with your account.',
  message: 'This email needs to be associated with your account!',
  explanation:
    'This error is thrown when a user requests to be resend the email verification email, while not having that email in the db',
});
saveError({
  code: EXPIRED_CODE,
  short: 'This code has expired.',
  message: 'This code has expired - please request a new one.',
  explanation:
    'This code has expired - please request a new one.'
});
saveError({
  code: INVALID_CODE,
  short: 'This code is invalid.',
  message: 'This code is invalid - please verify you typed it correctly.',
  explanation:
    'This code is invalid - please verify you typed it correctly.'
});
saveError({
  code: INVALID_REASON,
  short: 'Reason is missing.',
  message: 'You must provide a reason for deleting your account.',
  explanation:
    'You must provide a reason for deleting your account.'
});
saveError({
  code: NOT_FOUND,
  short: 'Not found',
  message: 'We could not find what you where looking for.',
  explanation:
    'The item you are looking for may have already been removed or changed.'
});
saveError({
  code: MULTIPLE_WORKSPACES,
  short: 'Edit one workspace at a time.',
  message: 'You can only edit one workspace at a time.',
  explanation:
    'You can only edit one workspace at a time.'
});
saveError({
  code: ONE_ROOT_WORKSPACE,
  short: 'One root space allowed.',
  message: 'You already have one root workspace.',
  explanation:
    'You can only have one rootworkspace. All other workspaces must reference that as root.'
});
saveError({
  code: NO_OWNER,
  short: 'Workspaces must have an owner.',
  message: 'A workspace must have an owner.',
  explanation:
    'Removing this permission would result in this workspace not having an owner.'
});
saveError({
  code: SLACK_GENERIC_ERROR,
  short: 'Something went wrong.',
  message: 'Something went wrong.',
  explanation:
    'Something went wrong.'
});
saveError({
  code: SLACK_CANT_USE_PRIVATE_CHANNEL,
  short: 'Matter cannot post to private channels.',
  message: 'Matter cannot post to private channels, please try a different one',
  explanation:
    'Matter cannot post to private channels, please try a different one'
});
saveError({
  code: INVALID_INVITATION_ID,
  short: 'Invalid invitation id',
  message: 'You are trying to access an invitation with an invalid id',
  explanation:
    'This error is thrown when a user tries to access an invitation with a id that doesnt exist',
});
saveError({
  code: USER_IS_REMOVED,
  short: 'User has been removed from Matter',
  message: (params) => {
    const { users } = params || {};

    if (!users) {
      return 'User has been removed from Matter';
    }

    let userList = `<@${users[0]}>`;

    if (users.length > 1) {
      const lastUser = users.pop();

      userList = users.map(user => `<@${user}>`).join(', ');
      userList += ` and <@${lastUser}>`;
    }

    return `${userList} has been removed from Matter`;
  },
  explanation: 'Contact your admin to re-invite them',
});
saveError({
  code: CURRENT_USER_IS_REMOVED,
  short: 'User is removed',
  message: 'You have been removed from Matter',
  explanation:
    'Contact your admin to be re-invited',
});
saveError({
  code: SELF_FEEDBACK,
  short: 'You cannot give yourself feedback',
  message: 'You cannot give yourself feedback',
  explanation:
    'Nice try — send one to someone else to make their day',
});
saveError({
  code: RESTRICTIVE_ACCOUNT,
  short: 'Your Slack settings are restricted and Matter cannot create a channel',
  message: 'Your Slack settings are restricted. Please create a public channel first, then connect it here',
  explanation:
    'Contact your Slack admin to update this',
});
saveError({
  code: SLACK_WORKSPACE_TAKEN,
  short: 'Slack workspace already exists',
  message: 'Slack workspace already exists',
  explanation:
    'That Slack channel is already associated with another Matter workspace. Please try again with a different Slack channel.',
});
saveError({
  code: MEMBER_TOKEN_ALREADY_USED,
  short: 'Invitation token has already been used',
  message: 'Invitation token has already been used',
  explanation:
    'Invitation token has already been used, please login to your account or request a new invite.',
});
saveError({
  code: INVALID_CHARS_IN_CHANNEL_NAME,
  short: 'Invalid channel name',
  message: 'That\'s an invalid channel name. Try again with only use lowercase letters and numbers, no spaces or special characters.',
  explanation:
    '',
});
saveError({
  code: INSUFFICIENT_REWARD_FUNDS,
  short: 'Insufficient funds, add funds to approve.',
  message: 'Insufficient funds, add funds to approve.',
  explanation:
    '',
});