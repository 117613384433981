import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modules } from '@matterapp/matter-ui';
import Toast from 'components/Toast/Toast';
import FeedItemsList from 'modules/feed/FeedItemsList';
import { mixpanel } from 'libs/tracking';
import UserContext from 'context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';

const EVENT = 'view-feedback-flow';
const trackingEvents = {
  viewFeedback: mixpanel.createEventSender(`${EVENT}:view-feedback`),
  viewGatherPeers: mixpanel.createEventSender(`${EVENT}:gather-feedback-peer-suggestions`),
  viewSetupRecurring: mixpanel.createEventSender(`${EVENT}:view-recurring-recommendation`),
  setupRecurring: mixpanel.createEventSender(
    `${EVENT}:recurring-start-clicked`
  ),
  skipRecurring: mixpanel.createEventSender(
    `${EVENT}:recurring-skip-clicked`
  ),
  clickGatherFeedback: mixpanel.createEventSender(
    `${EVENT}:gather-feedback-request-clicked`
  ),
  skipGatherFeedback: mixpanel.createEventSender(
    `${EVENT}:gather-feedback-request-skipped`
  ),
  copyLinkClicked: mixpanel.createEventSender(
    `${EVENT}:copy-link-clicked`
  ),
};

const getHashValue = (hash = '') => {
  return parseInt(hash.replace('#', ''), 10) || 1;
};

const FeedbackFlow = (props) => {
  const {
    feedbackList,
    location,
    loading,
    onReturnHome,
    handleConfirmRecurring,
    mutations
  } = props;
  const { currentWorkspaceId: workspaceId, refetchNotifications } = useContext(UserContext);
  const navigate = useNavigate();
  const { markFeedbackAsViewed } = mutations;
  const hash = getHashValue(location.hash);
  const [currentStep, setStep] = useState(hash || 1);
  const [prevStep, setPrevStep] = useState(0);
  const [hasSetupRecurring, setSetupRecurring] = useState(false);

  const handleSetupRecurring = (recurringProps) => {
    handleConfirmRecurring(recurringProps);
    setSetupRecurring(true);
    trackingEvents.setupRecurring();
  };

  const handleReturnHome = async (e) => {
    await onReturnHome(e);
    if (hasSetupRecurring) {
      Toast.success("We've updated your recurring settings");
    }
  };

  const trackAndHandleReturnHome = async (e) => {
    trackingEvents.skipGatherFeedback();
    await handleReturnHome(e);
  };

  const setStepAndHash = (step) => {
    navigate(`#${step}`);
    setStep(step);
  };

  const trackEvents = () => {
    const feedItem = document.getElementsByClassName('matter-feed-item-container');
    const setupRecurring = document.getElementsByClassName('setup-recurring-container');
    const gatherFeedback = document.getElementsByClassName('view-feedback-gather-feedback');
    
    if (gatherFeedback.length) {
      return trackingEvents.viewGatherPeers();
    } else if (setupRecurring.length) {
      return trackingEvents.viewSetupRecurring();
    } else if (feedItem.length) {
      return trackingEvents.viewFeedback();
    } 
  };

  useEffect(() => {
    if (feedbackList && !loading) {
      if (prevStep !== currentStep) {
        setPrevStep(currentStep);
        trackEvents();
      }
      if (!hash) {
        setStepAndHash(1);
      } else if (hash !== currentStep) {
        setStep(hash);
      }
    }
  });

  useEffect(() => {
    if (feedbackList) {
      const currentFeedback = feedbackList[currentStep - 1];

      if (currentFeedback) {
        markFeedbackAsViewed(currentFeedback.id);
        refetchNotifications();
      }

    }
  }, [currentStep, feedbackList]);

  if (loading) {
    return null;
  }

  const feedbackItems = (feedbackList || []).map((feedback, index) => (
    <FeedItemsList
      key={index}
      isFeedItem
      feedItemsList={[feedback]}
      isLoading={!feedback}
      feedItemsProps={{ expanded: true, hideBottomMargin: true, showMenu: false }}
      feedItemFooterProps={{ expanded: true, autoFocus: false }}
      workspaceId={workspaceId}
    />
  ));

  return (
    <Modules.Feedback.Flow
      currentStep={currentStep}
      feedbackItems={feedbackItems}
      onClickNextFeedback={() => {
        setStepAndHash(currentStep + 1);
      }}
      onClickReturnHome={trackAndHandleReturnHome}
      handleConfirmRecurring={handleSetupRecurring}
      handleTracking={trackingEvents}
    />

  );
};

FeedbackFlow.propTypes = {
  currentUserStatus: PropTypes.shape({
    initialInvitationLink: PropTypes.string,
    pickedSkillDetails: PropTypes.array,
  }).isRequired,
  /** List of feedback to display. */
  feedbackList: PropTypes.array,
  location: PropTypes.object,
  /** If the page is currently loading. */
  loading: PropTypes.bool,
  /** Mutation functions for the feedback flow. */
  mutations: PropTypes.shape({
    markFeedbackAsViewed: PropTypes.func.isRequired,
  }).isRequired,
  /** Callback to go back to home page. */
  onReturnHome: PropTypes.func.isRequired,
  /** Callback to confirm recurring request. */
  handleConfirmRecurring: PropTypes.func
};

export default FeedbackFlow;
