import React, { useContext } from 'react';
import { 
  SettingsContainer
} from '../styles';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import { Resources } from '@matterapp/routing';
import DeleteChannelPage from './DeleteChannel';
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import SettingsNavigation from '../SettingsNavigation';

export default function DeleteChannel() {  
  const { currentWorkspaceId: workspaceId, currentTenant, currentWorkspace, isLoadingCurrentUser } = useContext(UserContext);
  const isLoading = !currentWorkspace || isLoadingCurrentUser;
  const { isEnterprise } = currentTenant || {};

  const navigate = useNavigate();
  const settingsRedirectUrl = isEnterprise 
    ? Resources.workspaceSettingsMain.path({ workspaceId }, { isChannelSetting: true }) 
    : Resources.workspaceSettingsMain.path({ workspaceId });

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={(
        <SettingsContainer>
          <SettingsNavigation 
            currentLabel='Delete Channel' 
            settingsRedirectUrl={settingsRedirectUrl}
            isChannelSetting={isEnterprise}
          />
          {isLoading 
            ? null 
            : (
              <Paper elevation={0}>
                <DeleteChannelPage 
                  tenant={currentTenant}
                  workspace={currentWorkspace}
                  isLoading={isLoadingCurrentUser}
                  onClickCancel={() => navigate(settingsRedirectUrl)}
                />
              </Paper>
            )}
        </SettingsContainer>
      )}
    />
  );
}
