import React, { useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import PulseFiltersContext from 'context/PulseFiltersContext/PulseFiltersContext';
import { useQuery } from '@apollo/client';
import { AppLayout } from 'modules';
import MainColumn from './MainColumn';
import { dateFilters, buildCustomDateRange } from './dateFilters';
import getPulseAnalytics from 'graphql-queries/queries/analytics/getPulseAnalytics';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';

export default function PulseDashboardPage() {
  const { currentWorkspaceId: workspaceId, isLoadingCurrentWorkspace, currentTenant } = useContext(UserContext);
  const { selectedFilter, setSelectedFilter, xAxisMaxLabels, setXAxisMaxLabels } = useContext(PulseFiltersContext);
  const { setTopNavItem } = useContext(MobileMenuContext);
  const { startDate, endDate, groupBy } = selectedFilter;
  const { pulseSettings } = currentTenant || {};
  const { isPulseEnabled } = pulseSettings || {};
  const navigate = useNavigate();

  const { data: analyticsData, loading: isLoadingData } = useQuery(getPulseAnalytics, {
    variables: {
      tenantId: currentTenant?.id,
      startDate: startDate.format('YYYY-MM-DD'), 
      endDate: endDate.format('YYYY-MM-DD'),
      groupBy
    },
    fetchPolicy: 'network-only',
    skip: !currentTenant
  });

  useEffect(() => {
    setTopNavItem(null);
  }, []);

  useEffect(() => {
    if (currentTenant && !isPulseEnabled) {
      navigate(
        Resources.workspacesActivityFeed.path({ workspaceId })
      );
    }
  }, [isPulseEnabled]);

  const handleChangeFilter = (value, isCustom) => {
    let selectedValue;

    if (isCustom) {
      selectedValue = buildCustomDateRange(value.from, value.to);
    } else {
      selectedValue = dateFilters.find((filter) => filter.value === value);
    }
    
    if (startDate.format('YYYY-MM-DD') === selectedValue.startDate.format('YYYY-MM-DD') && endDate.format('YYYY-MM-DD') === selectedValue.endDate.format('YYYY-MM-DD')) {
      return;
    }

    setSelectedFilter(selectedValue);
    setXAxisMaxLabels(selectedValue.xAxisResolution);
  };

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        <MainColumn
          analyticsData={analyticsData?.pulseAnalytics}
          selectedFilter={selectedFilter}
          xAxisMaxLabels={xAxisMaxLabels}
          handleChangeFilter={handleChangeFilter}
          isLoading={isLoadingCurrentWorkspace}
          isLoadingData={isLoadingData}
          workspaceId={workspaceId}
          currentTenant={currentTenant}
        />
      }
    />
  );
}
