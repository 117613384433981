import styled from 'styled-components';
import { IconNew } from '../Icon';
import theme, { ColorPalettes, getColorObj } from '@matterapp/matter-theme';

export const getBackgroundColor = ({ color }) => {
  if (color) {
    const baseColor = getColorObj(color, ColorPalettes.Blue);
    return `background-color: ${baseColor[5]};`;
  }
  return `background-color: ${theme.colors.blacks[5]};`;
};

export const Container = styled.div`
  align-items: flex-start;
  border-radius: ${theme.borderRadius.S};
  display: flex;
  padding: ${theme.spacing.single};
  ${getBackgroundColor}
`;

export const ContentContainer = styled.div`
  flex: 1 1 auto;
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.S};
`;

export const IconCustomContainer = styled.div`
  flex: 0;

  &:not(:empty) {
    margin-right: ${theme.spacing.half};
  }
`;

export const IconContainer = styled(IconCustomContainer)`
  line-height: 0;

  & svg {
    color: ${theme.colors.blacks[80]};
    margin: -1px 0;
    max-height: ${theme.spacing.singleAndHalf};

    & path {
      fill: currentColor;
    }
  }
`;

export const Icon = styled(IconNew)``;

export const HeaderText = styled.span`
  font-weight: ${theme.fontWeight.bold};
`;

export { ColorPalettes };