import React from 'react';
import LeftToRight2 from '../Paths/LeftToRight2';
import BasePath from './BasePath';

const Path6 = React.forwardRef((props, ref) => (
  <BasePath
    PathComponent={LeftToRight2}
    pointProps={{ top: 92.2, left: 98.3 }}
    ref={ref}
    {...props}
  />
));

export default Path6;