import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ClickableLink,
  IconNew as Icon,
  InputCode,
  Loader,
  Text,
} from '@matterapp/matter-ui';
import StepContainer from '../StepContainer';
import Sidebar from '../Sidebar';
import InputValidate from './InputValidate';
import theme from '@matterapp/matter-theme';
import apolloClient from 'config/apolloClient';
import { validateEmail } from '../../../../../../../../form-validations';
import Toast from 'components/Toast/Toast';
import {
  EMAIL_LOGIN_CODE_IF_USER_EXISTS_MUTATION,
  CURRENT_USER_STATUS_QUERY,
  EMAIL_LOGIN_TOKEN_LOGIN_MUTATION,
} from 'graphql-queries/queries';
import debounce from 'lodash/debounce';

export const ENTER_KEY_VALUE = 'Enter';
export const FOOTER_TEXT =
  'Help your colleague know who to thank and follow-up with, by filling in your info.';
export const NAME_FIELD_PROPS = { 'data-rc': 'full-name-input' };
export const EMAIL_FIELD_PROPS = { 'data-rc': 'email-input' };
export const STEP_HEADER = 'Who are you?';
export const EMAIL_PLACEHOLDER = 'Your email';
export const NAME_PLACEHOLDER = 'Your full name';
export const QUICK_TIP =
  'Type in both your First and Last name. (e.g., "Joyce Hopper")';
export const VERIFICATION_HEADING =
  'Looks like you already have an account tied to this email address.';
export const VERIFICATION_TEXT = `We sent a 5-digit verification code ${Text.WithReplacement.replaceText} to the email above, enter it below to continue or update your entry above.`;
export const RESEND_TEXT = 'resend';

const errorMessages = {
  EXPIRED:
    'Your login code has expired. Please start over and request a new one',
  INVALID:
    'This login code is not valid. Please make sure you entered it correctly',
  MISC: 'Something went wrong',
};

function getErrorMessage(err) {
  let msg;
  if (err.message.includes('expired')) {
    msg = errorMessages.EXPIRED;
  } else if (err.message.includes('invalid')) {
    msg = errorMessages.INVALID;
  } else {
    msg = errorMessages.MISC;
  }
  return msg;
}

const Wrapper = styled.div`
  position: relative;
  padding-bottom: ${theme.spacing.single};
`;

const FooterText = styled.div`
  color: ${theme.colors.blacks[60]};
  line-height: ${theme.lineHeight.M};
  margin-bottom: ${theme.spacing.quadruple};
`;

const ErrorIcon = styled(Icon).attrs(() => ({
  name: 'stateError',
}))``;

const SuccessIcon = styled(Icon).attrs(() => ({
  name: 'stateSuccess',
}))``;

const ResendLink = styled(ClickableLink)`
  ${({ disabled }) => disabled && `
    pointer-events: none;
    color: ${theme.colors.blacks[40]};
    text-decoration: line-through;
  `}
`;

const VerificationContainer = styled.div`
  color: ${theme.colors.blacks[80]};
  line-height: 28px;
  overflow: hidden;
  opacity: ${({ show }) => (show ? '1' : '0')};
  max-height: ${({ show }) => (show ? '280px' : '0px')};
  // Have to delay the start of the transition to wait for component to
  // render -- otherwise reflow is visible and looks super jank
  transition: max-height 0.8s ${({ show }) => (show ? '0.8s' : '0s')}, opacity 0.8s ${({ show }) => (show ? '0.8s' : '0s')};

  ${({ show }) => theme.media.S`
    max-height: ${show ? '220px' : '0px'};
  `}
`;

const VerificationHeading = styled.span`
  font-weight: ${theme.fontWeight.bold};
`;

const VerifyCode = styled(InputCode).attrs(() => ({
  numberOfFields: 5,
  spaceAfterNthElement: 0,
}))`
  display: flex;
  padding: ${theme.spacing.double} 0;

  & > ${InputCode.Input} {
    border: none !important;
    height: ${theme.spacing.quad} !important;
    max-width: ${theme.spacing.quad};
    width: 44px;
    flex: 1 1 auto;

    &:not(:last-child) {
      margin-right: ${theme.spacing.half};
    }
  }
`;

const VerifyIndicatorContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  max-width: ${theme.spacing.quad};

  & svg {
    height: ${theme.spacing.singleAndHalf};
    width: ${theme.spacing.singleAndHalf};
  }
`;

export default class IdentificationStep extends React.PureComponent {
  static propTypes = {
    /** Email value */
    email: PropTypes.string,
    /** Callback when email value changes. */
    onChangeEmail: PropTypes.func.isRequired,
    /** Callback when name value changes. */
    onChangeFullName: PropTypes.func.isRequired,

    onIdentificationSuccess: PropTypes.func.isRequired,

    onNameVerified: PropTypes.func.isRequired,
    /** Optional function callback when enter key is pressed. */
    onEnterKeyPress: PropTypes.func,
    /** Callback when error first occurs. */
    onNameError: PropTypes.func,
    /** Called when logging a user in who has already created feedback
     * with an anonymous browser-session id */
    transferFeedbackDataFromSessionToUser: PropTypes.func,
  };

  static defaultProps = {
    onNameError: () => null,
    onEnterKeyPress: () => null,
  };

  state = {
    nameOnly: null,
    isNameValid: false,
    isValidatingName: false,
    nameFieldTouched: false,
    email: '',
    isValidatingEmail: false,
    emailFieldTouched: false,
    emailCodeError: false,
    emailCodeMatches: false,
    emailVerificationCodeInput: '',
    currentlyVerifyingEmailValue: null,
    isValidatingEmailCode: false,
    isEmailCodeVerified: false,
    verificationCodeMatches: false,
  };

  typingTimeout = null;
  verifyNameTimeout = null;
  verifyEmailTimeout = null;

  constructor(props) {
    super();
    this.state.nameOnly = !!props.email;
  }

  componentWillUnmount = () => {
    clearTimeout(this.verifyNameTimeout);
  };

  getIsNameValid = () => {
    const { fullName } = this.state;
    const isNameValid = !!fullName && /\S+ \S+/.test(fullName);
    return isNameValid;
  };

  getIsEmailValid = () => {
    const { email } = this.state;
    const errMsg = validateEmail(email);
    const isEmailValid = !errMsg;
    return isEmailValid;
  };

  /**
   * Callback when name is verified.
   * @returns { void }
   */
  handleFinishValidatingName = debounce(() => {
    const isNameValid = this.getIsNameValid();

    if (isNameValid !== this.state.isNameValid) {
      this.setState({ isNameValid });
      this.props.onNameVerified(isNameValid);

      // Already have a logged-in user with a saved e-mail address
      // We're only setting the full name property
      if (this.props.email) {
        this.props.onIdentificationSuccess(isNameValid);
      }
    }

    if (isNameValid) {
      const { fullName } = this.state;
      this.props.onChangeFullName({}, { fullName, value: fullName });
    }
    else {
      this.props.onNameError();
    }

    this.setState({ isValidatingName: false });
  }, 1000);

  /**
   * Callback when email is verified.
   * @returns { void }
   */
  handleFinishVerifyEmail = () => {
    this.setState({ isValidatingEmail: false });
  };

  /**
   * Callback when name input blurs.
   * @param { Object } e: The blur event.
   * @returns { void }
   */
  handleBlurName = () => {
    this.handleFinishValidatingName();
  };

  /**
   * Callback when email input blurs.
   * @param { Object } e: The blur event.
   * @returns { void }
   */
  handleBlurEmail = () => {
    this.handleFinishValidatingEmail();
  };

  /**
   * Callback when value of name input changes.
   * @param { Object } e: The change event.
   * @returns { void }
   */
  handleChangeName = (e) => {
    const value = e.target.value;
    this.setState({
      nameFieldTouched: true,
      fullName: value,
    });
    this.handleValidateName();
  };

  /**
   * Callback when value of email input changes.
   * @param { Object } e: The change event.
   * @returns { void }
   */
  handleChangeEmail = (e) => {
    const value = e.target.value;
    this.setState({
      emailFieldTouched: true,
      email: value,
    });
    this.handleValidateEmail();
  };

  handleChangeEmailVerificationCode = async (emailVerificationCodeInput) => {
    const { email } = this.state;
    // Make sure there's a code, and that it's five numeric digits
    if (
      emailVerificationCodeInput &&
      /\d{5}/.test(emailVerificationCodeInput)
    ) {
      try {
        this.setState({ isValidatingEmailCode: true });
        await apolloClient.mutate({
          mutation: EMAIL_LOGIN_TOKEN_LOGIN_MUTATION,
          variables: {
            email,
            token: emailVerificationCodeInput,
          },
        });
        await this.props.transferFeedbackDataFromSessionToUser();
        await apolloClient.query({
          query: CURRENT_USER_STATUS_QUERY,
          fetchPolicy: 'network-only',
        });
        this.setState({
          emailCodeError: false,
          emailCodeMatches: true,
          isValidatingEmailCode: false,
        });

        // Success callback to enable Submit button
        this.props.onIdentificationSuccess(true);
      } catch (err) {
        console.error(err);
        Toast.error(getErrorMessage(err));
        this.setState({
          emailCodeError: true,
          emailCodeMatches: false,
          isValidatingEmailCode: false,
        });
        this.props.onIdentificationSuccess(false);
      }
    } else {
      this.setState({
        emailVerificationCodeInput,
        emailCodeError: false,
        emailCodeMatches: false,
        isValidatingEmailCode: false,
      });
      this.props.onIdentificationSuccess(false);
    }
  };

  handleKeyPress = (e) => {
    const { onEnterKeyPress } = this.props;
    if (e.key === ENTER_KEY_VALUE) {
      const { isNameValid, isEmailValid } = this.state;

      if (isNameValid && isEmailValid) {
        onEnterKeyPress(e);
      } else {
        // Figure out which input this is, and trigger the blur handler
        const token = 'identification-';
        let className = e.target.className.split(' ').filter((item) => {
          return item.includes(token);
        })[0];
        className = className.replace(token, '');
        this[`handleBlur${className}`](e);
      }
    }
  };

  handleValidateName = () => {
    this.setState({ isValidatingName: true });
    this.handleFinishValidatingName();
  };

  handleValidateEmail = () => {
    this.setState({
      isValidatingEmail: true,
      currentlyVerifyingEmailValue: null,
    });
    this.handleFinishValidatingEmail();
  };

  handleFinishValidatingEmail = debounce(async () => {
    const {
      email,
      fullName,
      currentlyVerifyingEmailValue,
    } = this.state;

    const isEmailValid = this.getIsEmailValid();

    if (isEmailValid !== this.state.isEmailValid) {
      this.setState({ isEmailValid });
    }

    if (isEmailValid) {

      if (email == currentlyVerifyingEmailValue) {
        return;
      }

      this.setState({
        currentlyVerifyingEmailValue: email,
      });

      // E-mail is changed. Now try to verify
      this.props.onChangeEmail({}, { email, value: email });


      try {
        // If the e-mail is for an existing user, send that user a code
        const resp = await apolloClient.mutate({
          mutation: EMAIL_LOGIN_CODE_IF_USER_EXISTS_MUTATION,
          variables: { email },
        });
        const exists = resp.data.emailLoginCodeIfUserExists;
        if (exists) {
          this.setState({
            isValidatingEmail: false,
            doesEmailExist: true,
          });
          // Empty out any previous user to be created
          this.props.onIdentificationSuccess(false, null);
        }
        else {
          this.setState({
            isValidatingEmail: false,
            doesEmailExist: false,
          });

          // Success callback to enable Submit button and pass data for
          // user to be created
          this.props.onIdentificationSuccess(true, {email, fullName});
        }
      }
      catch (err) {
        console.error(err);
        Toast.error('Something went wrong verifying this email address');
        this.props.onIdentificationSuccess(false);

      }
    }
    else {
      this.setState({
        currentlyVerifyingEmailValue: null,
      });
      this.props.onIdentificationSuccess(false);
    }


    this.setState({ isValidatingEmail: false });
  }, 1000);

  async resendLoginCode() {
    const { email } = this.state;
    await apolloClient.mutate({
      mutation: EMAIL_LOGIN_CODE_IF_USER_EXISTS_MUTATION,
      variables: { email },
    });
    Toast.success('An email is on the way!');
  }

  getNameErrorMessage = () => {
    const {
      nameFieldTouched,
      isNameValid,
      isValidatingName,
    } = this.state;
    const msg =
      nameFieldTouched &&
      !isValidatingName &&
      !isNameValid &&
      QUICK_TIP;
    return msg || '';
  };

  // Not efficient to re-calc error message, but keeps parallel with name
  getEmailErrorMessage = () => {
    const {
      email,
      emailFieldTouched,
      isEmailValid,
      isValidatingEmail,
    } = this.state;
    const msg =
      emailFieldTouched &&
      !isValidatingEmail &&
      !isEmailValid &&
      validateEmail(email); // to get the message to display
    return msg || '';
  };

  renderFooter = () => {
    const {
      doesEmailExist,
      emailCodeError,
      emailCodeMatches,
      emailVerificationCodeInput,
      isEmailValid,
      isValidatingEmail,
      isValidatingEmailCode,
    } = this.state;

    // HACK: For resizing Timeline Step
    if (doesEmailExist) {
      window.setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1000);
    }

    const resendHandler = emailCodeMatches ?
      () => null : this.resendLoginCode.bind(this);

    return (
      <>
        <VerificationContainer
          show={doesEmailExist && isEmailValid && !isValidatingEmail}
        >
          <div>
            <VerificationHeading>{VERIFICATION_HEADING}</VerificationHeading> <Text.WithReplacement
              replaceText={<span style={{whiteSpace: 'nowrap'}}>(<ResendLink disabled={emailCodeMatches} onClick={resendHandler} key={RESEND_TEXT} rcLabel="resend-link">{RESEND_TEXT}</ResendLink>)</span>}
              text={VERIFICATION_TEXT}
            />
          </div>
          <VerifyCode
            readOnly={emailCodeMatches}
            value={emailVerificationCodeInput}
            onChange={this.handleChangeEmailVerificationCode}
            type="tel"
          >
            <VerifyIndicatorContainer>
              {isValidatingEmailCode && <Loader.Inline />}
              {emailCodeMatches && <SuccessIcon />}
              {emailCodeError && <ErrorIcon />}
            </VerifyIndicatorContainer>
          </VerifyCode>
        </VerificationContainer>
        <FooterText>{FOOTER_TEXT}</FooterText>
      </>
    );
  };

  render = () => {
    const {
      nameOnly,
      fullName,
      email,
      emailCodeMatches,
      doesEmailExist,
      isEmailValid,
      isNameValid,
      isValidatingEmail,
      isValidatingName,
    } = this.state;

    const nameSidebar = (
      <Sidebar.QuickTips isLightBorder>
        <Sidebar.Paragraph>{QUICK_TIP}</Sidebar.Paragraph>
      </Sidebar.QuickTips>
    );

    const getEmailInputState = () => {
      if (!email) {
        return InputValidate.INPUT_STATES.EMPTY;
      }
      if (!isEmailValid) {
        return InputValidate.INPUT_STATES.ERROR;
      }
      if (doesEmailExist && email && !emailCodeMatches) {
        return InputValidate.INPUT_STATES.WARNING;
      }
      if (isEmailValid || emailCodeMatches) {
        return InputValidate.INPUT_STATES.SUCCESS;
      }
      return '';
    };

    const getNameInputState = () => {
      if (!fullName) {
        return InputValidate.INPUT_STATES.EMPTY;
      }
      return isNameValid ?
        InputValidate.INPUT_STATES.SUCCESS :
        InputValidate.INPUT_STATES.ERROR;
    };

    let emailInput;
    if (nameOnly) {
      emailInput = null;
    }
    else {
      emailInput = (
        <Wrapper>
          <InputValidate
            inputClassName="identification-Email"
            errorMessage={this.getEmailErrorMessage()}
            field={EMAIL_FIELD_PROPS}
            inputState={getEmailInputState()}
            loading={isValidatingEmail}
            onBlur={this.handleBlurEmail}
            onChange={this.handleChangeEmail}
            onKeyPress={this.handleKeyPress}
            placeholder={EMAIL_PLACEHOLDER}
            value={email}
          />
        </Wrapper>
      );
    }

    return (
      <StepContainer header={STEP_HEADER} sidebarContent={nameSidebar}>
        <Wrapper>
          <InputValidate
            inputClassName="identification-Name"
            errorMessage={this.getNameErrorMessage()}
            field={NAME_FIELD_PROPS}
            inputState={getNameInputState()}
            loading={isValidatingName}
            onBlur={this.handleBlurName}
            onChange={this.handleChangeName}
            onKeyPress={this.handleKeyPress}
            placeholder={NAME_PLACEHOLDER}
            value={fullName}
          />
        </Wrapper>
        {emailInput}
        {this.renderFooter()}
      </StepContainer>
    );
  };
}
