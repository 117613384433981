import React from 'react';
import PropTypes from 'prop-types';
import omitStyled from '../../../libs/omitStyled';
import { getCharCountContainerStyles, getCharCountTextStyles, VARIANTS } from '../styles';

const CharacterCountContainer = omitStyled('div', [
  'isCountZero',
  'isTooShort',
  'isTooLong',
  'errorOnZero',
])`
  font-weight: 500;
  line-height: 24px;
  ${getCharCountContainerStyles}
`;

const CountContainer = omitStyled('span', ['isTooLong'])`
  ${getCharCountTextStyles}
`;

const CharacterCount = ({
  count,
  hideCharCountDenominator,
  hideMax,
  hideMin,
  max,
  maxErrorMessage,
  min,
  minErrorMessage,
  errorOnZero,
  showRemainingCharacterCount,
  variant,
}) => {
  const isCountZero = count === 0;
  const isTooLong = count > max;
  const hasMin = Number.isFinite(min);
  const isTooShort = hasMin && count < min;
  const limit = isTooShort && !hideMin ? min : max;

  if (isTooShort && hideMin && !hideMax) {
    // Don't show if char count is too short, hideMin is true and hideMax is false.
    return null;
  } else if (!isTooShort && hideMax) {
    // Don't show if char count is not too short and hideMax is true.
    return null;
  } else if (hideMax && hideMin) {
    // Don't show if both hideMin and hideMax are true.
    return null;
  }

  let contents = '';
  if (isTooShort && minErrorMessage) {
    contents = minErrorMessage;
  } else if (isTooLong && maxErrorMessage) {
    contents = maxErrorMessage;
  } else {
    let countToShow = showRemainingCharacterCount ? limit - count : count;
    if (!isNaN(showRemainingCharacterCount) && countToShow > showRemainingCharacterCount) {
      countToShow = '';
    }
    if (!countToShow) {
      return null;
    }
    contents = (
      <>
        {' '}
        <CountContainer isTooLong={isTooLong} variant={variant}>{countToShow}</CountContainer>
        {!hideCharCountDenominator && (
          <>
            {' / '}
            <span>{limit}</span>
          </>
        )}
      </>
    );
  }

  return (
    <CharacterCountContainer
      isCountZero={isCountZero}
      isTooShort={isTooShort}
      isTooLong={isTooLong}
      errorOnZero={errorOnZero}
      variant={variant}
    >
      {contents}
    </CharacterCountContainer>
  );
};

CharacterCount.propTypes = {
  /** Current count value. */
  count: PropTypes.number,
  /** Hides counter if count is less than max value. */
  hideMax: PropTypes.bool,
  /** Hides counter if count is less than min value. */
  hideMin: PropTypes.bool,
  /** The max value of the counter. */
  max: PropTypes.number,
  /** Override error message if value is too long. */
  maxErrorMessage: PropTypes.string,
  /** The min value of the counter. If count is equal to greater than min, the max value will be used. */
  min: PropTypes.number,
  /** Override error message if value is too short. */
  minErrorMessage: PropTypes.string,
  /** Should component show error when count is 0 **/
  errorOnZero: PropTypes.bool,
  /** Hide the denominator char count. */
  hideCharCountDenominator: PropTypes.bool,
  /** Displays the reminaing number of characters. If a number is passed, it will display when that number is reached. */
  showRemainingCharacterCount: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  /** The style variant of the text area. */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

export default CharacterCount;
