import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { Advisors, TextArea } from '@matterapp/matter-ui';

const DropdownWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%'
}));

const StyledDropdown = styled(Advisors.RecurringModal.Dropdown)(({ theme }) => ({
  color: 'red'
}));

const Wrapper =  styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(3)} 0`
}));

const CommentInput = styled(TextArea.WithCount)(({ theme }) => ({
  fontWeight: theme.fontWeight.regular,
  minHeight: '100px'
}));

export default function SelectTypeQuestion({ 
  answerOptions, 
  question, 
  hasComment, 
  index, 
  onChange, 
  values, 
  onAddComment,
  canSkip,
  isAnonymous
}) {
  const { answers = [], comment, showErrors } = values || {};
  const [answer] = answers;

  const skipQuestion = canSkip ? [{ value: '0', label: 'Skip this question'}] : [];
  const showComment = hasComment && answer && answer.value !== '0';

  return (
    <Wrapper>
      <Typography variant="h5" component='p' color={showErrors && !answer ? 'error': 'black.main'}>
        Q{index + 1} - {question}
      </Typography>
      <DropdownWrapper>
        <StyledDropdown
          hasError={showErrors && !answer}
          style={{ color: 'red' }}
          options={[...answerOptions, ...skipQuestion].map(({ value, label }) => ({
            label,
            value
          }))}
          selectedOption={answers[0] || 'Select answer'}
          handleChangeSelection={onChange}
        />
      </DropdownWrapper>
      {showComment && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <b>{isAnonymous ? 'Anonymous comment' : 'Comment'}</b> (optional)
          </Typography>
          <CommentInput
            onChange={(e) => {
              onAddComment(e.target.value);
            }}
            value={comment}
            maxLength={1500}
            allowErrorBeforeBlur
            showErrorMessageBelow
            placeholder={isAnonymous ? 'Write your anonymous comment here' : 'Write your comment here'}
          />
        </Box>
      )}
    </Wrapper>
  );
}
