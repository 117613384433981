import styled from 'styled-components';
import { colors, font, spacing, lineHeight } from '@matterapp/matter-theme';
import { sizes } from '../utils';

export const DropdownCount = styled.div`
  align-items: center;
  background: ${colors.blues[40]};
  border-radius: ${spacing.singleAndHalf};
  color: ${colors.white};
  display: flex;
  font-weight: ${font.weight.bold};
  justify-content: center;
  height: ${spacing.singleAndQuarter};
  width: ${spacing.singleAndQuarter};

  ${({ size }) => {
    if (size === sizes.XS) {
      return `
        font-size: calc(${font.size.XXS} - 1px);
        height: ${spacing.single};
        width: ${spacing.single};
        margin-right: 0;
      `;
    }

    const sharedStyles = `
      font-size: ${font.size.XS};
      height: ${spacing.singleAndQuarter};
      width: ${spacing.singleAndQuarter};
    `;
    if (size === sizes.S) {
      return `
        ${sharedStyles}
        margin-right: 0;
      `;
    }
    return `
      ${sharedStyles}
      margin-right: ${spacing.quarter};
    `;
  }}
`;

export const MenuBottomContent = styled.div`
  background: ${colors.white};
  border-top: 1px solid ${colors.blacks[20]};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: ${spacing.half} ${spacing.single};
`;

export const MenuBottomContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -1px;
  position: sticky;
  bottom: 0;
`;

export const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${font.size.S};
  line-height: ${lineHeight.M};
  color: ${colors.blacks[60]};
  background: ${colors.blacks[5]};
  z-index: 1;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  padding: ${spacing.single};
`;