import React from 'react';
import gsap from 'gsap';
import BaseBackground from './BaseBackground';
import { BackgroundContainer, DarkPath, LightPath } from './styles';

export default class Background1 extends BaseBackground {
  playStart = () => {
    const tl = gsap.timeline({
      defaults: { duration: 0.4, transformOrigin: 'center bottom' },
    });
    tl.to(this.signRef, { rotation: -20 }, '<');
    tl.to(this.signRef, { rotation: 0 }, '>');
    return tl;
  };

  playAnimation = (animationProps = {}) => {
    BaseBackground.killAnimation(this.tl);
    this.tl = gsap.timeline({ defaults: { duration: 0 } });
    this.tl.to(
      this.startRef,
      { scale: 2, opacity: 0, transformOrigin: 'center' },
      '<'
    );
    this.tl.to(
      this.signRef,
      { rotation: -30, transformOrigin: 'center bottom' },
      '<'
    );
    this.tl.to(this.rockSignRef, { y: -40, opacity: 0 }, '<');
    this.tl.to(this.leftRockRef, { y: 20, opacity: 0 }, '<');
    this.tl.to(this.rightRockRef, { y: -20, opacity: 0 }, '<');

    if (!animationProps.initialize) {
      this.tl.to(this.startRef, { scale: 1, opacity: 1, duration: 0.6 }, '<');
      this.tl.to(this.signRef, { rotation: 0, duration: 0.6 }, '<');
      this.tl.to(this.rockSignRef, { y: 0, opacity: 1, duration: 0.2 }, '<');
      this.tl.to(this.leftRockRef, { y: 0, opacity: 1, duration: 0.4 }, '>');
      this.tl.to(this.rightRockRef, { y: -0, opacity: 1, duration: 0.4 }, '<');
    }
    return this.tl;
  };

  render() {
    return (
      <BackgroundContainer top={-55}>
        <BaseBackground.SVG height={96}>
          <DarkPath
            ref={(ref) => (this.leftRockRef = ref)}
            d="M498,77c0-8.837-7.163-16-16-16s-16,7.163-16,16H498z"
          />
          <LightPath
            ref={(ref) => (this.rightRockRef = ref)}
            d="M536,81c0-13.255-10.745-24-24-24s-24,10.745-24,24H536z"
          />
          <LightPath
            ref={(ref) => (this.startRef = ref)}
            d="M82.916,83.345h1.733C84.624,81.958,83.371,81,81.467,81c-1.875,0-3.25,0.942-3.241,2.356
              c-0.004,1.148,0.885,1.805,2.331,2.121l0.932,0.213c0.931,0.205,1.449,0.448,1.453,0.973c-0.004,0.57-0.597,0.958-1.516,0.958
              c-0.94,0-1.616-0.395-1.675-1.174H78C78.046,88.13,79.37,89,81.446,89c2.088,0,3.316-0.908,3.321-2.334
              c-0.004-1.296-1.078-1.984-2.565-2.288l-0.768-0.167c-0.743-0.156-1.366-0.407-1.353-0.965c0-0.502,0.489-0.87,1.374-0.87
              C82.319,82.376,82.849,82.733,82.916,83.345z M85.657,82.463h2.619v6.427h1.788v-6.427h2.619v-1.357h-7.025V82.463z M94.257,88.89
              l0.635-1.775h3.087l0.635,1.775h1.938l-2.949-7.783h-2.331l-2.953,7.783H94.257z M95.352,85.83l1.053-2.945h0.067l1.053,2.945
              H95.352z M101.581,88.89h1.809v-2.759h1.32l1.621,2.759h1.996l-1.817-3.025c0.973-0.38,1.512-1.152,1.512-2.216
              c0-1.547-1.123-2.543-3.066-2.543h-3.375V88.89z M103.39,84.808v-2.356h1.22c1.044,0,1.549,0.422,1.549,1.197
              c0,0.771-0.505,1.159-1.541,1.159H103.39z M108.975,82.463h2.618v6.427h1.788v-6.427H116v-1.357h-7.025V82.463z"
          />
          <g
            ref={(ref) => (this.rockSignRef = ref)}
            onMouseEnter={this.playStart}
          >
            <DarkPath d="M97,69c0-6.075,4.925-11,11-11s11,4.925,11,11H97z" />
            <LightPath d="M71,71c0-8.837,7.387-16,16.5-16S104,62.163,104,71H71z" />
            <LightPath
              ref={(ref) => (this.signRef = ref)}
              d="M117.411,55.396l-15.051-4.073L98.715,64L96,63.243l3.64-12.656l-14.165-3.833l6.458-5.393
                l-2.857-7.914l14.374,3.89l1.283-4.46l-5.846-1.409l-0.521-2.253l2.16-1.607l-0.348-1.502l6.08,1.465L107.285,24L110,24.757
                l-1,3.476l7.26,1.749l-1.583,0.682l1.34,0.323l-1.235,2.184l1.26,0.658l-1.074,1.514l-7.494-1.806l-1.304,4.536l14.841,4.016
                l4.187,8.274L117.411,55.396z"
            />
          </g>
        </BaseBackground.SVG>
      </BackgroundContainer>
    );
  }
}
