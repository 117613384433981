import React from 'react';
import gsap from 'gsap';
import Animation from '../../../../../Animations/Animation';
import * as S from './styles';

export default class Workspace extends Animation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  moveClouds = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, scale: 1, duration: 4 };
    tl.to(this.cloudTopRef, { x: 10, y: 30, scale: 1.2 }, '<');
    tl.to(this.cloudBottomRef, { x: -40, y: -40, scale: 1.2 }, '<');

    tl.to(this.cloudTopRef, resetProps, '<');
    tl.to(this.cloudBottomRef, resetProps, '<');
    return tl;
  };

  moveDots = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const hideProps = { scale: 4, opacity: 0, transformOrigin: 'center' };
    const resetProps = { opacity: 1, scale: 1, duration: 1 };
    tl.to(this.topDotRef, hideProps, 'hideDots');
    tl.to(this.bottomRightDotRef, { ...hideProps, scale: 2 }, '<');
    tl.to(this.leftDot1Ref, hideProps, '<');
    tl.to(this.leftDot2Ref, hideProps, '<');
    tl.to(this.leftDot3Ref, hideProps, '<');
    tl.to(this.leftDot4Ref, hideProps, '<');
    tl.to(this.leftDot5Ref, hideProps, '<');
    tl.to(this.rightDot1Ref, hideProps, '<');
    tl.to(this.rightDot2Ref, hideProps, '<');
    tl.to(this.rightDot3Ref, hideProps, '<');
    tl.to(this.centerDot1Ref, hideProps, '<');
    tl.to(this.centerDot2Ref, hideProps, '<');
    tl.to(this.centerDot3Ref, hideProps, '<');
    tl.to(this.centerDot4Ref, hideProps, '<');
    tl.to(this.centerDot5Ref, hideProps, '<');
    tl.to(this.centerDot6Ref, hideProps, '<');
    tl.to(this.centerDot7Ref, hideProps, '<');
    tl.to(this.ring1Ref, hideProps, '<');
    tl.to(this.ring2Ref, hideProps, '<');
    tl.to(this.ring3Ref, hideProps, '<');

    tl.to(this.topDotRef, { ...resetProps, duration: 0.4 }, 'hideDots+=0.5');
    tl.to(this.bottomRightDotRef, { ...resetProps, duration: 0.4 }, '<0.4');

    tl.to(this.leftDot1Ref, resetProps, 'hideDots+=0.4');
    tl.to(this.leftDot4Ref, resetProps, '<0.3');
    tl.to(this.leftDot2Ref, resetProps, '<0.3');
    tl.to(this.leftDot3Ref, resetProps, '<0.3');
    tl.to(this.leftDot5Ref, resetProps, '<0.5');

    tl.to(this.rightDot1Ref, resetProps, 'hideDots+=0.6');
    tl.to(this.rightDot2Ref, resetProps, '<0.5');
    tl.to(this.rightDot3Ref, resetProps, '<0.5');

    tl.to(this.ring1Ref, resetProps, 'hideDots+=0.7');
    tl.to(this.ring2Ref, resetProps, '<0.4');
    tl.to(this.ring3Ref, resetProps, '<0.4');

    tl.to(this.centerDot1Ref, resetProps, 'hideDots+=0.4');
    tl.to(this.centerDot4Ref, resetProps, '<0.3');
    tl.to(this.centerDot6Ref, resetProps, '<0.3');
    tl.to(this.centerDot2Ref, resetProps, '<0.3');
    tl.to(this.centerDot7Ref, resetProps, '<0.3');
    tl.to(this.centerDot3Ref, resetProps, '<0.3');
    tl.to(this.centerDot5Ref, resetProps, '<0.3');

    return tl;
  };

  movePlus = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const hideProps = { scale: 0, opacity: 0, transformOrigin: 'center' };
    const growProps = { opacity: 0.8, scale: 2, duration: 0.25 };
    const resetProps = { opacity: 1, scale: 1, duration: 0.15 };
    tl.to(this.rightPlus1Ref, hideProps, 'hidePlus');
    tl.to(this.rightPlus2Ref, hideProps, '<');
    tl.to(this.centerPlus1Ref, hideProps, '<');
    tl.to(this.centerPlus2Ref, hideProps, '<');
    tl.to(this.centerPlus3Ref, hideProps, '<');

    tl.to(this.rightPlus1Ref, growProps, 'hidePlus+=1');
    tl.to(this.rightPlus1Ref, resetProps, '>');
    tl.to(this.rightPlus2Ref, growProps, 'hidePlus+=1.6');
    tl.to(this.rightPlus2Ref, resetProps, '>');

    tl.to(this.centerPlus1Ref, growProps, 'hidePlus+=0.8');
    tl.to(this.centerPlus1Ref, resetProps, '>');
    tl.to(this.centerPlus2Ref, growProps, 'hidePlus+=1.4');
    tl.to(this.centerPlus2Ref, resetProps, '>');
    tl.to(this.centerPlus3Ref, growProps, 'hidePlus+=2');
    tl.to(this.centerPlus3Ref, resetProps, '>');

    return tl;
  };

  playAnimation = () => {
    const tl = gsap.timeline();
    tl.add(this.moveClouds(), '<');
    tl.add(this.moveDots(), '<');
    tl.add(this.movePlus(), '<');

    this.tl = tl;
    return tl;
  };

  resetAnimation = () => {};

  render() {
    return (
      <S.Image preserveAspectRatio="xMaxYMax meet">
        <S.CloudTop ref={(ref) => (this.cloudTopRef = ref)} />
        <S.CloudBottom ref={(ref) => (this.cloudBottomRef = ref)} />
        <S.BottomRightDot ref={(ref) => (this.bottomRightDotRef = ref)} />

        <S.CenterDot1 ref={(ref) => (this.centerDot1Ref = ref)} />
        <S.CenterDot2 ref={(ref) => (this.centerDot2Ref = ref)} />
        <S.CenterDot3 ref={(ref) => (this.centerDot3Ref = ref)} />
        <S.CenterDot4 ref={(ref) => (this.centerDot4Ref = ref)} />
        <S.CenterDot5 ref={(ref) => (this.centerDot5Ref = ref)} />
        <S.CenterDot6 ref={(ref) => (this.centerDot6Ref = ref)} />
        <S.CenterDot7 ref={(ref) => (this.centerDot7Ref = ref)} />

        <S.LeftDot1 ref={(ref) => (this.leftDot1Ref = ref)} />
        <S.LeftDot2 ref={(ref) => (this.leftDot2Ref = ref)} />
        <S.LeftDot3 ref={(ref) => (this.leftDot3Ref = ref)} />
        <S.LeftDot4 ref={(ref) => (this.leftDot4Ref = ref)} />
        <S.LeftDot5 ref={(ref) => (this.leftDot5Ref = ref)} />

        <S.RightDot1 ref={(ref) => (this.rightDot1Ref = ref)} />
        <S.RightDot2 ref={(ref) => (this.rightDot2Ref = ref)} />
        <S.RightDot3 ref={(ref) => (this.rightDot3Ref = ref)} />

        <S.TopDot ref={(ref) => (this.topDotRef = ref)} />

        <S.Ring1 ref={(ref) => (this.ring1Ref = ref)} />
        <S.Ring2 ref={(ref) => (this.ring2Ref = ref)} />
        <S.Ring3 ref={(ref) => (this.ring3Ref = ref)} />

        <S.RightPlus1 ref={(ref) => (this.rightPlus1Ref = ref)} />
        <S.RightPlus2 ref={(ref) => (this.rightPlus2Ref = ref)} />

        <S.CenterPlus1 ref={(ref) => (this.centerPlus1Ref = ref)} />
        <S.CenterPlus2 ref={(ref) => (this.centerPlus2Ref = ref)} />
        <S.CenterPlus3 ref={(ref) => (this.centerPlus3Ref = ref)} />
      </S.Image>
    );
  }
}
