import React from 'react';

const Icon = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="18" y="1" width="1.5" height="12" transform="rotate(90 18 1)" fill="#99999B" />
    <circle cx="1.75" cy="1.75" r="1.15" transform="rotate(90 1.75 1.75)" fill="#99999B" stroke="#99999B" strokeWidth="1.2" />
    <rect x="18" y="13" width="1.5" height="12" transform="rotate(90 18 13)" fill="#99999B" />
    <circle cx="1.75" cy="13.75" r="1.15" transform="rotate(90 1.75 13.75)" fill="#99999B" stroke="#99999B" strokeWidth="1.2" />
    <rect x="18" y="7" width="1.5" height="12" transform="rotate(90 18 7)" fill="#99999B" />
    <circle cx="1.75" cy="7.75" r="1.15" transform="rotate(90 1.75 7.75)" fill="#99999B" stroke="#99999B" strokeWidth="1.2" />
  </svg>
);

export default Icon;
