import React from 'react';
import BasePath from './BasePath';

const DEFAULT_HEIGHT = 220;
const PATH_LENGTH = 588;

const LeftToRight2 = React.forwardRef((props, ref) => (
  <BasePath
    {...props}
    ref={ref}
    height={DEFAULT_HEIGHT}
    pathLength={PATH_LENGTH}
    pathCode={
      `M8.721,9.218L8.669,118.336c0.092,6.561,2.811,12.477,7.146,16.753c4.336,4.276,10.29,6.911,16.852,6.911H35.5
      c6.628,0,12.628,2.686,16.971,7.029S59.5,159.372,59.5,166v21l0,0l0,0c0,6.075,2.463,11.575,6.444,15.556
      C69.925,206.538,75.425,209,81.5,209h345.188`
    }
  />
));

export default LeftToRight2;
