import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@matterapp/matter-ui';
import {
  ModalBody,
  RewardContainer,
  RewardImage,
  RewardDetailsContainer,
  RewardName,
  RewardDescription,
  OrderCostSection,
  DropdownErrorMessage,
  MODAL_IMAGE_WIDTH,
} from './sharedStyles';
import { getRemainingCoins } from './helpers';
import { REWARD_QUANTITY_TYPES } from '../../../rewards-manager/consts';

const CustomRewardRedeemModal = ({ 
  isOpen, 
  selectedReward, 
  currentCoinBalance, 
  onClickClose, 
  onClickComplete,
  isRequestingReward
}) => {
  const { imageUrl, name, description, value, quantity, quantityType } = selectedReward;
  const remainingCoinAmount = getRemainingCoins(value, currentCoinBalance);
  const isAmountValid = remainingCoinAmount >= 0;
  let errorMessage = null;
  if (quantityType === REWARD_QUANTITY_TYPES.LIMITED && quantity === 0) {
    errorMessage = 'This reward is currently out of stock';
  } else {
    if (!isAmountValid) {
      errorMessage = 'You do not have enough coins for this reward';
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClickClose}
      fullScreenMobile
      size={Modal.sizes.XL}
    >
      <Modal.Header
        title='Your Order'
        right={<Modal.Header.Buttons.Close onClick={onClickClose} />}
      />
      <ModalBody>
        <RewardContainer>
          <RewardImage 
            isCustom
            src={imageUrl}
            width={MODAL_IMAGE_WIDTH}
          />
          <RewardDetailsContainer>
            <RewardName>{name}</RewardName>
            <OrderCostSection>Current Balance: <strong>{currentCoinBalance.toLocaleString('en-US')} coins</strong></OrderCostSection>
            <OrderCostSection>
              Coin Cost: <strong>{value.toLocaleString('en-US')} coins</strong>
            </OrderCostSection>
            <OrderCostSection>
              Remaining Coins: <strong>{remainingCoinAmount.toLocaleString('en-US')} coins</strong>
            </OrderCostSection> 
            {errorMessage && <DropdownErrorMessage style={{ textAlign: 'left' }}>{errorMessage}</DropdownErrorMessage>}
          </RewardDetailsContainer>
        </RewardContainer>
        {description && <RewardDescription>{description}</RewardDescription>}
      </ModalBody>
      <Modal.Footer.WithCancelSave
        saveLabel='Complete Order'
        canClickSave={!isRequestingReward && !errorMessage}
        onClickSave={() => onClickComplete(selectedReward)}
        onClickCancel={onClickClose}
      /> 
    </Modal>
  );
};

CustomRewardRedeemModal.propTypes = {
  isOpen: PropTypes.bool,
  selectedReward: PropTypes.object,
  currentCoinBalance: PropTypes.number,
  onClickClose: PropTypes.func,
  onClickComplete: PropTypes.func,
  isRequestingReward: PropTypes.bool
};

export default CustomRewardRedeemModal;