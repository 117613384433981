import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconNew as Icon } from '../Icon';
import theme from '@matterapp/matter-theme';

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isLink }) =>
    isLink &&
    `:hover {
    cursor: pointer;
  }`};
`;

const StyledSpan = styled.span`
  color: ${({ isLink }) => (isLink ? theme.colors.blacks[60] : 'black')};
  font-size: ${() => theme.sizes.fontSizes[50]};
  font-style: normal;
  font-weight: normal;
  line-height: ${() => theme.sizes.lineHeights[50]};
  margin-left: 8px;
  padding-bottom: 2px;
  text-align: center;
  ${({ isLink }) =>
    isLink &&
    `:hover {
        cursor: pointer;
        color: #4584ff;
      }
      :active {
        color: #3466f2;
      }`};
`;

class IconWithText extends React.PureComponent {
  render() {
    const {
      iconName,
      children,
      iconWidth,
      iconHeight,
      isLink,
      onClick,
      textStyle,
      containerStyle,
      iconFill,
      rcLabel
    } = this.props;

    return (
      <IconTextContainer
        isLink={isLink}
        onClick={onClick}
        style={containerStyle}
        data-rc={rcLabel}
      >
        <Icon
          name={iconName}
          width={iconWidth}
          height={iconHeight}
          fill={iconFill}
        />
        <StyledSpan isLink={isLink} style={textStyle}>
          {children}
        </StyledSpan>
      </IconTextContainer>
    );
  }
}

IconWithText.defaultProps = {
  iconHeight: 16,
  iconWidth: 20,
  isLink: false,
  onClick: () => {},
  textStyle: {},
  containerStyle: {},
  iconFill: null,
};

IconWithText.propTypes = {
  children: PropTypes.string.isRequired,
  iconHeight: PropTypes.number,
  iconName: PropTypes.string.isRequired,
  iconWidth: PropTypes.number,
  isLink: PropTypes.bool,
  onClick: PropTypes.func,
  textStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  iconFill: PropTypes.string,
  rcLabel: PropTypes.string
};

export default IconWithText;
