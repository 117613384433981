import React from 'react';
import Animation from '../Animation';
import styled from 'styled-components';
import gsap from 'gsap';
import { SVG } from '../../../components';
import theme from '@matterapp/matter-theme';

const Circle = styled.path`
  opacity: 0.3;
  fill: ${() => theme.colors.white};
  enable-background: new;
`;

const Plus = styled.path`
  opacity: 0.3;
  fill: ${() => theme.colors.white};
  enable-background: new;
  fill-rule: evenodd;
  clip-rule: evenodd;
`;

const Face = styled.path`
  fill: #ffbf61;
`;

const FacePart = styled.path`
  fill: ${() => theme.colors.purple};
`;

const Dash = styled.path`
  fill: ${() => theme.colors.white};
`;

const HandLight = styled.path`
  fill: ${() => theme.colors.white};
`;

const HandDark = styled.path`
  fill: ${() => theme.colors.purple};
`;

const HandDarkClip = styled.path`
  fill: ${() => theme.colors.purple};
  fill-rule: evenodd;
  clip-rule: evenodd;
`;

const Hand = styled.g``;

const DEFAULT_HEIGHT = 164;
const DEFAULT_WIDTH = 254;

export default class KudosThankYou extends Animation {
  static defaultProps = {
    height: DEFAULT_HEIGHT,
    width: DEFAULT_WIDTH,
  };

  /**
   * Animates the face as the hat is tipped.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  animateFace = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { y: 0, duration: 0.3 };
    tl.to(this.leftEyeRef, { y: 0 });
    tl.to(this.rightEyeRef, { y: 0 });
    tl.to(this.mouthRef, { y: 0 });

    if (!initialize) {
      tl.to(this.leftEyeRef, { y: 2, duration: 0.2 }, '<');
      tl.to(this.rightEyeRef, { y: 2, duration: 0.2 }, '<');
      tl.to(this.mouthRef, { y: -2, duration: 0.2 }, '<');

      tl.to(this.leftEyeRef, resetProps, '<0.3');
      tl.to(this.rightEyeRef, resetProps, '<');
      tl.to(this.mouthRef, resetProps, '<');
    }
    return tl;
  };

  /**
   * Displays the background circles.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  displayCircles = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, opacity: 0.3, duration: 0.2 };
    tl.to(this.circleTopRef, { opacity: 0, x: 6, y: 11 });
    tl.to(this.circleBottomRightRef, { opacity: 0, x: -14, y: -5 });
    tl.to(this.circleFullTopLeftRef, { opacity: 0, x: 18, y: 8 });
    tl.to(this.circleBottomLeftRef, { opacity: 0, x: 12, y: -8 });
    tl.to(this.circleFullRightRef, { opacity: 0, x: -9 });
    tl.to(this.circleFullTopRef, { opacity: 0, y: 12 });
    tl.to(this.circleFullLeftRef, { opacity: 0, x: 9, y: 4 });
    tl.to(this.plusTopRightRef, {
      opacity: 0,
      x: -20,
      y: 20,
      rotation: -360,
      transformOrigin: 'center',
    });

    if (!initialize) {
      tl.to(this.circleTopRef, resetProps, '<0.4');
      tl.to(this.circleBottomRightRef, resetProps, '<');
      tl.to(this.circleFullTopLeftRef, resetProps, '<');
      tl.to(this.circleBottomLeftRef, resetProps, '<');
      tl.to(this.circleFullRightRef, resetProps, '<');
      tl.to(this.circleFullTopRef, resetProps, '<');
      tl.to(this.circleFullLeftRef, resetProps, '<');
      tl.to(this.plusTopRightRef, { ...resetProps, rotation: 0 }, '<');
    }
  };

  /**
   * Displays the dashes on the left side of the face after the hap is tipped.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  displayDashes = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, opacity: 1, duration: 0.3 };
    tl.to(this.topDashRef, { x: 18, y: 25, opacity: 0 });
    tl.to(this.middleDashRef, { x: 18, y: 18, opacity: 0 });
    tl.to(this.bottomDashRef, { x: 25, y: 18, opacity: 0 });
    if (!initialize) {
      tl.to(this.topDashRef, resetProps, '<0.3');
      tl.to(this.middleDashRef, resetProps, '<');
      tl.to(this.bottomDashRef, resetProps, '<');
    }
    return tl;
  };

  /**
   * Tips the hat onto the face and back.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  tipHat = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.to(this.handRef, 0, {
      x: 0,
      y: 0,
      rotation: 0,
    });
    if (!initialize) {
      tl.to(
        this.handRef,
        {
          x: -20,
          y: 30,
          rotation: -5,
          duration: 0.3,
        },
        '<'
      );
      tl.to(
        this.handRef,
        {
          x: 0,
          y: 0,
          rotation: 0,
          duration: 0.4,
        },
        '>'
      );
    }
    return tl;
  };

  playAnimation = ({ initialize, isMounting } = {}) => {
    if (this._isMounted || isMounting) {
      const tl = gsap.timeline({ defaults: { duration: 0 } });
      tl.add(this.tipHat({ initialize }));
      tl.add(this.animateFace({ initialize }), '<');
      tl.add(this.displayCircles({ initialize }), '<');
      tl.add(this.displayDashes({ initialize }), '<');
      this.tl = tl;
      return tl;
    }
  };

  resetAnimation = () => {
    return this.playAnimation({ initialize: true });
  };

  render() {
    const { height, width, ...otherProps } = this.props;

    return (
      <SVG
        {...otherProps}
        height={height}
        width={width}
        viewBox={`0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`}
      >
        <Circle
          ref={(ref) => (this.circleFullTopRef = ref)}
          d='M91.2,11.6c3.2,0,5.8-2.6,5.8-5.8S94.4,0,91.2,0c-3.2,0-5.8,2.6-5.8,5.8
            S88,11.6,91.2,11.6z'
        />
        <Circle
          ref={(ref) => (this.circleTopRef = ref)}
          d='M57.7,24.1c-5.8,0-10.5-4.7-10.5-10.5c0-5.8,4.7-10.5,10.5-10.5s10.5,4.7,10.5,10.5
            C68.2,19.4,63.5,24.1,57.7,24.1z M57.7,8c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6S60.8,8,57.7,8z'
        />
        <Circle
          ref={(ref) => (this.circleFullRightRef = ref)}
          d='M243.5,57.7c3.2,0,5.8-2.6,5.8-5.8c0-3.2-2.6-5.8-5.8-5.8c-3.2,0-5.8,2.6-5.8,5.8
            C237.7,55.1,240.3,57.7,243.5,57.7z'
        />
        <Circle
          ref={(ref) => (this.circleBottomRightRef = ref)}
          d='M243,163.1c-5.8,0-10.5-4.7-10.5-10.5c0-5.8,4.7-10.5,10.5-10.5
            c5.8,0,10.5,4.7,10.5,10.5C253.4,158.4,248.8,163.1,243,163.1z M243,147c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6
            c3.1,0,5.6-2.5,5.6-5.6C248.6,149.6,246.1,147,243,147z'
        />
        <Circle
          ref={(ref) => (this.circleFullLeftRef = ref)}
          d='M35.8,70.3c3.2,0,5.8-2.6,5.8-5.8c0-3.2-2.6-5.8-5.8-5.8c-3.2,0-5.8,2.6-5.8,5.8
            C30,67.7,32.6,70.3,35.8,70.3z'
        />
        <Circle
          ref={(ref) => {
            this.circleBottomLeftRef = ref;
          }}
          d='M49.3,149.5c-5.8,0-10.5-4.7-10.5-10.5c0-5.8,4.7-10.5,10.5-10.5s10.5,4.7,10.5,10.5
            C59.8,144.8,55.1,149.5,49.3,149.5z M49.3,133.4c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6
            C55,136,52.4,133.4,49.3,133.4z'
        />
        <Plus
          ref={(ref) => (this.plusTopRightRef = ref)}
          d='M229.4,19.2c0,1.3,1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4v-4.9h4.9c1.3,0,2.4-1.1,2.4-2.4
            s-1.1-2.4-2.4-2.4h-4.9V4.6c0-1.3-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4v4.9h-4.9c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4h4.9V19.2z'
        />
        <Circle
          ref={(ref) => (this.circleFullTopLeftRef = ref)}
          d='M5.8,34.4c3.2,0,5.8-2.6,5.8-5.8c0-3.2-2.6-5.8-5.8-5.8S0,25.4,0,28.6
            C0,31.8,2.6,34.4,5.8,34.4z'
        />
        <Face
          d='M132.9,161.2c25.6,0,46.4-20.1,46.4-44.8c0-24.7-20.8-44.8-46.4-44.8c-25.6,0-46.4,20.1-46.4,44.8
            C86.6,141.1,107.3,161.2,132.9,161.2z'
        />
        <Hand ref={(ref) => (this.handRef = ref)}>
          <HandLight
            d='M206.5,145.1c-1.6,0-3.1-0.9-3.9-2.4c-1.1-2.2-0.3-4.8,1.9-5.9l27-14.1c2.2-1.1,4.8-0.3,5.9,1.9
              c1.1,2.2,0.3,4.8-1.9,5.9l-27,14.1C207.9,144.9,207.2,145.1,206.5,145.1z'
          />
          <g>
            <g>
              <HandLight d='M213.1,77.6l-14.9,12.2l3.2,23.3l16.8,8.5l11.1-21.6l-9.5-22.1L213.1,77.6z' />
              <g>
                <HandDarkClip
                  d='M196.5,64c2.6-0.4,5.1-1.1,7.5-2.2c2.9-1.3,6.3-0.1,7.7,2.8s0.1,6.3-2.8,7.7c-5.8,2.7-12.1,3.7-18.3,3.7
                      c-13.9,0-27.9-5.1-36.6-8.3l-2.7-1l0,0c-12.7-4.6-36.3-13.2-45-31.8c-1.3-2.9-0.1-6.3,2.8-7.7c2.9-1.4,6.3-0.1,7.7,2.8
                      c1.1,2.3,2.6,4.5,4.3,6.5c0.2-0.6,0.4-1.3,0.6-1.9l4.3-11.8C133.4,2.1,153.7-2,174,5.4l1.8,0.7c20.3,7.4,33.3,23.6,25.8,44.3
                      l-4.3,11.8C197,62.8,196.8,63.4,196.5,64z'
                />
                <HandLight
                  d='M194.3,62.6l20.8,5.9c0.5,0.2,1.1,0.5,1.5,0.9l0.5,0.5L237,90.4c1.4,1.4,2,3.5,1.5,5.6l-0.4,1.4l-8.5,32.1
                      c0,0.2,0,0.4,0.1,0.5l-22.9,11.5c-1.2-1-2.2-2.3-3-3.7c-0.9-1.6-1.8-3.2-2.8-4.7c-0.9-1.6-1.9-3.1-2.9-4.6c-2-3-4-6-6.1-9
                      l-1.7-2.4c-0.8-1.1-1.1-2.5-0.8-4c0.5-2.3,1-4.5,1.4-6.8c0.4-2.3,0.7-4.6,1-6.8c0.3-2.3,0.5-4.6,0.9-6.8
                      c0.2-1.8,0.5-3.5,0.8-5.3c-2.4-3.2-4.8-6.4-7.1-9.7c-0.9-1.2-0.9-3,0.1-4.2c1.2-1.5,3.4-1.7,4.9-0.5c3.9,3.1,7.7,6.3,11.5,9.4
                      c0.7,0.6,1.2,1.5,1.3,2.5l0.1,1.3c0.2,2.3,0.4,4.6,0.5,6.9c0.2,2.3,0.3,4.6,0.4,6.9c0.1,2.3,0.3,4.6,0.5,6.9
                      c0.1,0.6,0.1,1.2,0.2,1.8c2.3,1.6,4.7,3.2,7.1,4.8c0,0,0.1,0,0.1,0.1l10.3-18.2l-13.3-18.6l-18.1-8.6c-1.3-0.6-2-2.1-1.6-3.5
                      C191.1,63,192.7,62.1,194.3,62.6z'
                />
                <HandDark
                  d='M208.8,119.1c-0.5,0-1-0.2-1.4-0.6c-0.8-0.8-0.9-2.1-0.1-2.9l14.1-15.3l-1.3-2.5l-4.8,3.1
                      c-8.8,5.2-14.8,1.7-16.7-0.9c-0.7-0.9-0.5-2.2,0.4-2.9l18-13.3c0.9-0.7,2.2-0.5,2.9,0.4c0.7,0.9,0.5,2.2-0.4,2.9l-15.8,11.8
                      c1.7,0.8,4.8,1.3,9.4-1.5l6.6-4.3c0.5-0.3,1.1-0.4,1.7-0.3c0.6,0.2,1,0.5,1.3,1.1l3,5.8c0.4,0.8,0.3,1.7-0.3,2.4l-15.1,16.4
                      C209.9,118.9,209.3,119.1,208.8,119.1z'
                />
              </g>
              <HandDark
                d='M204.4,96.6c-4.8,0-8.6-4.6-8.8-4.8c-0.7-0.9-0.6-2.2,0.3-2.9l17.6-14.4c0.9-0.7,2.2-0.6,2.9,0.3
                    s0.6,2.2-0.3,2.9l-15.8,12.9c1.3,1.1,3.3,2.3,5.2,1.6c1.1-0.4,2.3,0.1,2.7,1.2s-0.1,2.3-1.2,2.7
                    C206.1,96.5,205.2,96.6,204.4,96.6z'
              />
            </g>
            <HandDark d='M198.9,75.3c-6.9,1.2-14.1,0.7-20.9-0.5l0.6-4.8l14.3,0.3L198.9,75.3z' />
          </g>
        </Hand>
        <FacePart
          ref={(ref) => (this.rightEyeRef = ref)}
          d='M163,112.9c-0.9,0-1.7-0.6-2-1.4c-1-3-3.8-5-7-5s-6,2-7,5c-0.4,1.1-1.6,1.7-2.6,1.3
            s-1.7-1.6-1.3-2.6c1.6-4.7,6-7.9,11-7.9s9.4,3.2,11,7.9c0.4,1.1-0.2,2.3-1.3,2.6C163.4,112.9,163.2,112.9,163,112.9z'
        />
        <FacePart
          ref={(ref) => (this.leftEyeRef = ref)}
          d='M120.9,112.9c-0.9,0-1.7-0.6-2-1.4c-1-3-3.8-5-7-5c-3.2,0-6,2-7,5c-0.4,1.1-1.6,1.7-2.6,1.3
            c-1.1-0.4-1.7-1.6-1.3-2.6c1.6-4.7,6-7.9,11-7.9c5,0,9.4,3.2,11,7.9c0.4,1.1-0.2,2.3-1.3,2.6C121.3,112.9,121.1,112.9,120.9,112.9z'
        />
        <FacePart
          ref={(ref) => (this.mouthRef = ref)}
          d='M132.9,137.2c-8.4,0-15.8-5.3-18.5-13.3c-0.4-1.1,0.2-2.3,1.3-2.6s2.3,0.2,2.6,1.3
            c2.1,6.2,8,10.4,14.6,10.4c6.6,0,12.4-4.2,14.6-10.4c0.4-1.1,1.6-1.7,2.6-1.3c1.1,0.4,1.7,1.6,1.3,2.6
            C148.8,131.9,141.3,137.2,132.9,137.2z'
        />
        <Dash
          ref={(ref) => (this.topDashRef = ref)}
          d='M93.5,64.2l-3.6,2L79.5,52.6l7.9-4.4L93.5,64.2z'
        />
        <Dash
          ref={(ref) => (this.middleDashRef = ref)}
          d='M75.8,69.2l-2.3,3.5L58,65.4l4.9-7.5L75.8,69.2z'
        />
        <Dash
          ref={(ref) => (this.bottomDashRef = ref)}
          d='M76.2,87.3l-0.4,4.1l-17.1,0.7l0.9-9L76.2,87.3z'
        />
      </SVG>
    );
  }
}
