import React from 'react';
import { SVG, Path } from './consts';

const Members = (props) => (
  <SVG {...props}>
    <Path
      d="M9.2,13.7c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S7.2,13.7,9.2,13.7z M7.6,15c-2.3,0-4.1,1.8-4.1,4v1
        c0,0.5,0.5,1,1,1h10.3c0.6,0,1-0.4,1-1v-1c0-2.2-1.8-4-4.1-4H7.6z M18.5,6.7c0,1.8-1.5,3.3-3.3,3.3c-0.1,0-0.2,0-0.3,0
        c0-2-1.1-3.7-2.7-4.7c0.5-1.1,1.7-1.9,3-1.9C17,3.4,18.5,4.9,18.5,6.7z M20.3,17.2h-2.7c-0.6-1.8-2-3.2-3.8-3.9
        c0.3-0.4,0.6-0.9,0.7-1.4h2.7c2.1,0,3.8,1.6,3.8,3.7v1C21,16.9,20.7,17.2,20.3,17.2z"
    />
  </SVG>
);

export default Members;
