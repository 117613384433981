import React from 'react';
import PropTypes from 'prop-types';
import {
  FullScreenOverlay,
  Skill,
  ButtonNew as Button,
} from '@matterapp/matter-ui';
import { skillValueObjectPropType } from '@matterapp/skills';

const CustomSkillSuccessOverlay = ({
  skill,
  isOpen,
  handleSetSkill,
  handleCloseOverlay,
  children,
}) =>
  children({
    overlay: (
      <FullScreenOverlay isOpen={isOpen} onRequestClose={handleCloseOverlay}>
        {skill && (
          <FullScreenOverlay.StarburstCTA
            title={<React.Fragment>Skill Created!</React.Fragment>}
            message={
              <React.Fragment>
                Your new skill, {skill.name}, has been added to your profile.
              </React.Fragment>
            }
            badge={<Skill.Badge skill={skill} />}
            primaryButton={
              <Button extraWide onClick={handleCloseOverlay} primary>
                Done
              </Button>
            }
          />
        )}
      </FullScreenOverlay>
    ),
    isOpen,
    handleSetSkill,
    handleCloseOverlay,
  });

CustomSkillSuccessOverlay.defaultProps = {
  skill: null,
};

CustomSkillSuccessOverlay.propTypes = {
  skill: skillValueObjectPropType,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
  handleSetSkill: PropTypes.func.isRequired,
  handleCloseOverlay: PropTypes.func.isRequired,
};

export default CustomSkillSuccessOverlay;
