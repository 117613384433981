import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledInputContainer = styled.div`
  display: block;
  width: 100%;
  input {
    line-height: 20px;
    height: 40px;
    text-align: left;
    font-size: 17px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.blacks[90]};
    border:none;
    background: transparent;
    width: 100%;

    ::placeholder {
      color: ${(props) => props.theme.colors.blacks[30]};
    }
  }
`;

const InputFlush = class extends React.Component {
  render() {
    const {
      onChange,
      type,
      value,
      placeholder,
    } = this.props;
    return (
      <StyledInputContainer>
        <input
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          ref={(ref) => {
            if (ref) {
              this.ref = ref;
            }
          }}
        />
      </StyledInputContainer>
    );
  }
};

InputFlush.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

InputFlush.defaultProps = {
  value: null,
  placeholder: null,
};

export default InputFlush;
