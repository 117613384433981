
export function gtagCall(...args) {
  let count = 0;
  if (!window.gtag) {
    if (count > 10) {
      return;
    }
    console.log('gtag not loaded yet. trying again...');
    setTimeout(() => {
      count++;
      gtagCall(...args);
    }, 1000);
    return;
  }
  window.gtag(...args);
}

