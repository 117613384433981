import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@matterapp/matter-ui';
import ToggleList from './ToggleList';

const MAX_AMOUNT_OF_SELECTED_ABILITIES = 3;
const VALUE_NAME = 'currentAbilities';

const SkillAbilities = (props) => {
  const { color, maxAmount, actionItemSelections, onChange } = props;
  
  const getAbilitiesList = () => {
    const abilitiesList = [];
    const selectedAbilitiesIds = [];
    actionItemSelections.forEach((singleAbility) => {
      const { ability, selected } = singleAbility;
      if (selected) {
        selectedAbilitiesIds.push(ability.id);
      }
      abilitiesList.push(ability);
    });
    return {
      abilitiesList,
      selectedAbilitiesIds,
    };
  };

  const { abilitiesList, selectedAbilitiesIds } = getAbilitiesList();
  const [tempCurrentAbilitiesIds, setTempCurrentAbilitiesIds] = useState(selectedAbilitiesIds);

  /**
   * Callback when the current selected abilities change.
   * Calls 'onChange' prop with change event and component
   * props with the updated 'abilities' and list of 'currentAbilities'.
   * @param { Object } e: The change event.
   * @param { Object } changeProps: Props from the ToggleList with the currently selected items.
   * @returns { void }
   */
  const handleChange = (e, changeProps) => {
    const currentAbilities = changeProps[VALUE_NAME];

    const updatedActionItemSelections = actionItemSelections.map((actionItem) => {
      const { ability } = actionItem;
      return {
        ability,
        selected: currentAbilities.includes(ability.id),
      };
    });

    setTempCurrentAbilitiesIds(currentAbilities);
    onChange(e, {
      ...props,
      actionItemSelections: updatedActionItemSelections,
      currentAbilities,
    });
  };

  return (
    <ToggleList
      buttonProps={{ size: Button.Simple.buttonSizes.XS }}
      color={color}
      items={abilitiesList}
      onChange={handleChange}
      maxAmount={maxAmount}
      value={tempCurrentAbilitiesIds}
      valueName={VALUE_NAME}
    />
  );
};

SkillAbilities.defaultProps = {
  actionItemSelections: [],
  maxAmount: MAX_AMOUNT_OF_SELECTED_ABILITIES,
};

SkillAbilities.propTypes = {
  /** List of abilities tied to the current skill rated. */
  actionItemSelections: PropTypes.arrayOf(
    PropTypes.shape({
      ability: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      selected: PropTypes.bool,
    })
  ),
  /** The color of the skill. */
  color: PropTypes.oneOf(Object.values(Button.Simple.buttonColors)),
  /** The max amount of abilities to select. */
  maxAmount: PropTypes.number,
  /** Callback when the value of selected abilities changes. */
  onChange: PropTypes.func.isRequired,
};

export default SkillAbilities;