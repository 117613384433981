import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../Button';
import Emoji from '../../Emoji';
import theme from '@matterapp/matter-theme';

const NO_PEERS_TITLE = 'It takes two to tango';
const NO_PEERS_TITLE_SIZE = 22;
const NO_PEERS_MEESAGE =
  'Oops, you currently have no peers. In order to give feedback, we’ll need to know who you’re currently working with. Let’s get started by finding your peers.';

const NoPeersContainer = styled.div`
  padding: ${theme.spacing.triple} ${theme.spacing.singleAndHalf}
    ${theme.spacing.quad};

  ${theme.media.medium`
    padding: ${theme.spacing.triple} ${theme.spacing.tripleAndHalf}
    ${theme.spacing.quad};
  `}
`;

const NoPeersHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: ${NO_PEERS_TITLE_SIZE}px;
  font-weight: bold;
  line-height: ${theme.sizes.lineHeights[60]};
  margin-bottom: ${theme.spacing.quarter};
`;

const NoPeersDescription = styled.div`
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.sizes.lineHeights[50]};
`;

const EmojiContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${theme.spacing.quarter};
`;

const FindYourPeersButtonContainer = styled.div`
  margin-top: ${theme.spacing.singleAndHalf};
  display: flex;
  justify-content: flex-end;
`;

const FindPeersButton = styled(Button.New)`
  &,
  &:hover {
    color: ${theme.colors.white};
    & > svg {
      fill: ${theme.colors.white};
    }
  }
`;

const NoPeersMessage = (props) => {
  const { findYourPeersLink, onClickFindYourPeers } = props;
  return onClickFindYourPeers ? (
    <NoPeersContainer>
      <NoPeersHeader>
        {NO_PEERS_TITLE}
        <EmojiContainer>
          <Emoji.Image theString="💃" size={NO_PEERS_TITLE_SIZE} />
          <Emoji.Image theString="🕺" size={NO_PEERS_TITLE_SIZE} />
        </EmojiContainer>
      </NoPeersHeader>
      <NoPeersDescription>{NO_PEERS_MEESAGE}</NoPeersDescription>
      <FindYourPeersButtonContainer>
        <FindPeersButton
          icon="arrow"
          onClick={onClickFindYourPeers}
          primary
          size="small"
          to={findYourPeersLink}
        >
          Find Your Peers
        </FindPeersButton>
      </FindYourPeersButtonContainer>
    </NoPeersContainer>
  ) : null;
};

export default NoPeersMessage;

NoPeersMessage.propTypes = {
  /** Link to find your peers page. Displays button if passed in. */
  findYourPeersLink: PropTypes.string,
  /** Callback when find you peers button is clicked. */
  onClickFindYourPeers: PropTypes.func,
};
