import PropTypes from 'prop-types';
import styled from 'styled-components';
import starburstYellow from '../../../../../assets/svgs/starburst.yellow.svg';
import ModalAdapter from './Adapter';
import theme from '@matterapp/matter-theme';

const overlayClassName = 'matter-ReactModal__Overlay';
const contentClassName = 'matter-ReactModal__Content';

const SIZES = {
  large: 600,
  medium: 560,
  normal: 500,
  small: 480,
};

const StyledModal = styled(ModalAdapter).attrs({
  overlayClassName,
  contentClassName,
})`
  .${overlayClassName} {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) =>
    props.festive ? 'rgba(46,46,51,.95)' : 'rgba(52,55,153,.95)'};
    ${(props) =>
    props.transparent && 'background: rgba(46, 46, 51, 0.32) !important;'}
  }

  .${contentClassName} {
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;
    max-height: auto;
    width: ${({ customWidth }) => (customWidth ? customWidth : 'auto')};
    ${({ size, wide }) => {
    const maxWidth = wide ? SIZES.large : SIZES[size];
    return `max-width: ${maxWidth}px;`;
  }}
    margin: 0 auto;
    ${({ flushOnMobile }) => !flushOnMobile && `border-radius: 12px;`}
    ${(props) => {
    if (props.festive) {
      return `
        background-color: ${theme.colors.yellows[20]};
        background-image: url(${starburstYellow});
        background-repeat: no-repeat;
        background-position: 50% -40px;
      `;
    }
    return 'background: white;';
  }}
    ${() => theme.media.medium`
      border-radius: 12px;
      max-height: 85vh;
    `}

    @media (max-width: 500px) {
      top: ${({ newModalStyle }) => (newModalStyle ? '23vh' : '0 !important')};
      margin: ${(props) => {
    if (props.flushOnMobile) {return '0';}
    if (props.newModalStyle) {return '0 20px';}
    return '.5em auto';
  }};
      height: ${(props) => (props.flushOnMobile ? '100%' : 'auto')};
    }
  }

  .ReactModal__Content {
    transition: opacity ${theme.transitions.times.default};
    transition-delay: ${theme.transitions.times.singleAndHalf};
  }
  .ReactModal__Content--after-open {
    opacity: 1;
    top: 10vh;
  }
  .ReactModal__Content--before-close {
    opacity: 0;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity .15s linear;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 1;
  }
`;

StyledModal.defaultProps = {
  festive: false,
  flushOnMobile: false,
  size: 'normal',
  transparent: false,
  wide: false,
};

StyledModal.propTypes = {
  /** Label identifier of modal. */
  contentLabel: PropTypes.string.isRequired,
  /** Custom width for modal. */
  customWidth: PropTypes.string,
  /** Custom border radius for modal. */
  borderRadius: PropTypes.string,
  /** Styled modal. */
  festive: PropTypes.bool,
  /** Modal is flushed fullscreen on mobile. */
  flushOnMobile: PropTypes.bool,
  /** If modal is currently open. */
  isOpen: PropTypes.bool.isRequired,
  /** Callback when modal is closed. */
  onRequestClose: PropTypes.func.isRequired,
  /** The width of the modal. */
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
  /** If modal background is transparent. */
  transparent: PropTypes.bool,
  /** DEPRICATED: Use `size={'large'}` instead. Makes modal wider. */
  wide: PropTypes.bool,
};

export default StyledModal;
