// isOpen,
//   children,
//   loading,
//   confirmation,
//   headerText,
//   subHeaderText,
//   background,
//   confetti,

const ACTIVATE_OVERLAY = '@overlay/activate';
const RESET_OVERLAY = '@overlay/reset';

const initialState = {
  isOpen: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case ACTIVATE_OVERLAY: {
    const nextState = {
      isOpen: true,
      loading: action.loading,
      headerText: action.headerText,
      subHeaderText: action.subHeaderText,
    };
    return nextState;
  }
  case RESET_OVERLAY:
    return initialState;
  default:
    return state;
  }
};

export const activateOverlay = ({ loading, headerText, subHeaderText }) => ({
  type: ACTIVATE_OVERLAY,
  loading,
  headerText,
  subHeaderText,
});

export const resetOverlay = () => ({
  type: RESET_OVERLAY,
});

export default reducer;
