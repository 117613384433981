import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm } from 'redux-form';
import CustomSkillEditor from '../components/CustomSkillEditor';
import validate from '../validate';
import { SKILLS_QUERY, CREATE_SKILL_MUTATION, SKILL_DETAILS_QUERY } from 'graphql-queries/queries';
import withCurrentColorPalette from '../hocs/withCurrentColorPalette';
import { withQueryParams } from 'hocs';
import { getSkillFromFormValues } from '../libs/get-skill-from-form-values';
import withCustomSkillAsyncValidationError from '../hocs/withCustomSkillAsyncValidationError';
import Toast from 'components/Toast/Toast';
import withCurrentUser from 'context/UserContext/withCurrentUser';

const CREATE_CUSTOM_SKILL_FORM = '@custom-skill-editor/create-form';

const withCreateCustomSkill = compose(
  graphql(CREATE_SKILL_MUTATION, {
    name: 'createSkillMutation',
    options: ({ currentTenantWorkspaceIds }) => {
      return {
        refetchQueries: () => [
          {
            query: SKILLS_QUERY,
          },
          { 
            query: SKILL_DETAILS_QUERY, 
            variables: {
              workspaceIds: currentTenantWorkspaceIds
            },
          }
        ],
      };
    },
  }),
  withProps(({ createSkillMutation }) => ({
    createCustomSkill: (skill) =>
      createSkillMutation({
        variables: skill,
      }),
  }))
);

const withData = compose(
  withQueryParams,
  withCurrentUser,
  withProps(() => ({
    modalTitle: 'Create Skill',
    primaryButtonText: 'Create',
  })),
  withCustomSkillAsyncValidationError,
  withCurrentColorPalette({ formName: CREATE_CUSTOM_SKILL_FORM }),
  withCreateCustomSkill,
  reduxForm({
    form: CREATE_CUSTOM_SKILL_FORM,
    validate,
    destroyOnUnmount: true,
    onSubmit: async (
      values,
      dispatch,
      {
        createCustomSkill,
        handleDismissGeneratorModal,
        handleCustomSkillAsyncValidationError,
      }
    ) => {
      const skill = getSkillFromFormValues(values);
      try {
        await createCustomSkill(skill);
        handleDismissGeneratorModal();
        Toast.success('Your new skill has been created');
      } catch (error) {
        handleCustomSkillAsyncValidationError(error);
      }
    },
  })
);

const CreateCustomSkillModeContainer = withData(CustomSkillEditor);

CreateCustomSkillModeContainer.propTypes = {
  handleDismissGeneratorModal: PropTypes.func.isRequired,
  handleSetSkillForSuccessOverlay: PropTypes.func.isRequired,
};

export default CreateCustomSkillModeContainer;
