import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import AvatarHeader from '../AvatarHeader';
import Panel from '../../Panel';
import { SkillRatingsList, SkillNote } from '../SkillRatings';
import MoreDetails from '../MoreDetails';
import { Footer } from '../FeedItem';
import FeedItemLayout from '../FeedItemLayout';

const FEEDBACK_HEADER = 'gave you feedback.';

const WorkspaceLink = styled.a`
  color: ${theme.colors.blacks[90]};
  border-bottom: 1px solid ${theme.colors.blacks[90]};
  &:active {
    text-decoration: none;
    color: ${theme.colors.purples[60]};
  }
`;

const WorkspaceName = styled.span`
  color: ${theme.colors.blacks[90]};
`;

export default class QuickNoteFeedback extends React.PureComponent {

  getSubHeaderWithWorkspace = (workspace, workspacePath) => {
    if (workspacePath) {
      return (
        <span>
          from{' '}<WorkspaceLink href={workspacePath}>{workspace.name}</WorkspaceLink>{' '}{FEEDBACK_HEADER}
        </span>
      );
    }
    return (
      <span>
        from{' '}<WorkspaceName>{workspace.name}</WorkspaceName>{' '}{FEEDBACK_HEADER}
      </span>
    );
  }

  renderHeader = () => {
    const { giver, showMenu, workspace, workspacePath, onClickDelete } = this.props;
    const subheader = workspace ? this.getSubHeaderWithWorkspace(workspace, workspacePath) : FEEDBACK_HEADER;

    return (
      <AvatarHeader
        equalPadding
        header={giver.fullName}
        subheader={subheader}
        photoUrl={giver.photoUrl}
        showMenu={showMenu}
        onClickDelete={onClickDelete}
      />
    );
  };

  renderSkillRating = () => {
    const { quickNote, feedItemProps, onClickSkillBadge } = this.props;
    const { skill, note } = quickNote;

    if (!skill) {
      return <SkillNote>{note}</SkillNote>;
    }
    return (
      <SkillRatingsList
        expanded={feedItemProps.expanded}
        onClickSkillBadge={() => onClickSkillBadge(skill)}
        skillRatings={[{ skill, note }]}
      />
    );
  };

  renderDetailsButton = () => {
    const { feedItemProps, onClickDetails } = this.props;
    return (
      <MoreDetails
        onClickDetails={onClickDetails}
        expanded={feedItemProps.expanded}
      />
    );
  };

  render() {
    const { feedItemProps, footerProps } = this.props;
    const { hideFooter } = footerProps;

    return (
      <Panel.Base header={this.renderHeader()} hideBottomMargin={feedItemProps.hideBottomMargin}>
        <FeedItemLayout.Body hideFooter={hideFooter}>
          {this.renderSkillRating()}
        </FeedItemLayout.Body>
        {hideFooter ? null : <Footer {...footerProps} />}
      </Panel.Base>
    );
  }
}

QuickNoteFeedback.defaultProps = {
  feedItemProps: {
    hideBottomMargin: false,
  },
};
