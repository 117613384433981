import React from 'react';
import PropTypes from 'prop-types';
import UpgradeModal from 'modules/workspaces/shared/UpgradeModal';
import PurpleBanner from 'components/PurpleBanner';

const TrialBanner = (props) => {
  const { isAdminMember, expiresInDays, upgradeModalProps, onClickUpgrade } = props;

  const paymentButtonLabel = isAdminMember ? 'Add Payment Info' : 'Upgrade to Pro';
  const plural = expiresInDays > 1 ? 'days' : 'day';

  return (
    <>
      <UpgradeModal {...upgradeModalProps} />
      <PurpleBanner
        isCollapsable
        id={'free-trial-banner'}
        expandedHeader={`Trial ending in ${expiresInDays} ${plural}`}
        collapasedHeader={<>Trial ending in <strong>{expiresInDays} {plural}</strong></>}
        description="You're currently on a free 30-day trial. Upgrade to keep all Matter Pro features - unlimited kudos, custom kudos, gift cards and company rewards, analytics, and more!"
        buttonLabel={paymentButtonLabel}
        onButtonClick={onClickUpgrade}
      />
    </>
  );
};

TrialBanner.propTypes = {
  isAdminMember: PropTypes.bool,
  expiresInDays: PropTypes.number,
  onClickUpgrade: PropTypes.func,
  upgradeModalProps: PropTypes.shape({
    openUpgradeModal: PropTypes.func,
    onClickSendRequestToUpgrade: PropTypes.func,
  }),
};

export default TrialBanner;