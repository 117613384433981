import styled from 'styled-components';
import omitStyled from '../../../../libs/omitStyled';
import { Clickable } from '../../../Clickable/Clickable';
import TextArea from '../../../TextArea';
import theme from '@matterapp/matter-theme';

export const Wrapper = styled.div`
  transition: max-height 0.5s ease-in-out;
  height: auto;
  overflow: hidden;
  max-height: 0px;
  padding: 0;

  ${({ open, height }) =>
    open &&
    `
    max-height: calc(${height}px + (${theme.padding.base} * 2));
    transition: max-height 0.2s ease-out-in;
  `}

  ${({ open, height }) =>
    open &&
    theme.media.S`
    max-height: calc(${height}px + (${theme.padding.S} * 2));
  `}
`;

export const Body = styled.div``;

export const BodyWrapper = styled.div`
  padding: ${theme.spacing.singleAndHalf};
`;

export const MessageWrapper = styled.div`
  padding: ${theme.spacing.singleAndHalf};
`;

export const MessageContainer = styled.div`
  position: relative;
`;

export const Message = styled(TextArea.WithCount).attrs(({ messageMinLength, messageMaxLength }) => ({
  autoHeight: true,
  maxLength: messageMaxLength,
  minLength: messageMinLength,
}))`
  min-height: 150px;
  font-weight: ${theme.fontWeight.regular};
`;

export const MessageHeaderContainer = styled.div``;

export const Heading = omitStyled('div', ['label'])`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${theme.spacing.singleAndHalf};

  h3 {
    font-size: ${theme.fontSize.S};
    line-height: ${theme.lineHeight.M}
    font-weight: ${theme.fontWeight.bold};
    margin-bottom: 0;

  }

  ${({ label }) =>
    label &&
    `
    margin-bottom: -8px;
  `}

  .selection-count {
    display: flex;
    color: ${theme.colors.blacks[50]}
    > div:first-of-type {
      font-weight: ${theme.fontWeight.regular};
      margin-right: 6px;
    }
    > div:last-of-type {
      font-weight: ${theme.fontWeight.medium};
    }
  }
`;

export const Clear = styled(Clickable)`
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.base};
  &:hover {
    color: ${theme.colors.black};
  }
  &:active {
    color: ${theme.colors.purples[50]};
  }
`;

export const Divider = styled.div`
  background: ${theme.colors.blacks[10]};
  height: 1px;
  width: 100%;
`;