import React from 'react';
import PropTypes from 'prop-types';
import Animation from '../Animation';
import gsap from 'gsap';
import SVG from '../../SVG';
import * as S from './styles';
import { ColorPalettes } from '@matterapp/matter-theme';

const STEPS = [0, 1, 2, 3];
const STEP_ANGLES = [120, 120, 240, 360];
const DEFAULT_SIZE = 184;
const HIDE_ANGLE = -120;

export default class ThreeStepProgress extends Animation {
  static propTypes = {
    color: PropTypes.oneOf(Object.keys(ColorPalettes)),
    isComplete: PropTypes.bool,
    step: PropTypes.oneOf(STEPS),
    size: PropTypes.number,
  };

  static defaultProps = {
    color: ColorPalettes.Red,
    size: DEFAULT_SIZE,
    step: 0,
  };

  static steps = STEPS;

  componentDidUpdate = (prevProps) => {
    const { animate, isComplete, step } = this.props;
    if (step !== prevProps.step || isComplete !== prevProps.isComplete || animate !== prevProps.animate) {
      this.playAnimation(this.props);
    }
  }

  moveArrow = ({ hide } = {}) => {
    const { step } = this.props;
    const rotation = STEP_ANGLES[step] || STEP_ANGLES[0];
    const tl = gsap.timeline({ defaults: { duration: hide ? 0.4 : 0.1 } });
    tl.to(this.arrowRef, { rotation, opacity: hide ? 0 : 1, transformOrigin: 'center' }, '<');
    return tl;
  }

  hideStep = (ref) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.to(ref, { rotation: HIDE_ANGLE, transformOrigin: 'center' }, '<');
    return tl;
  }

  showStep = (ref) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.to(ref, { rotation: 0, transformOrigin: 'center' }, '<');
    return tl;
  }

  playStep = (ref) => {
    const { animate, shouldAnimate } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0.5 } });
    tl.add(this.moveArrow(), '<');
    if ((animate && shouldAnimate) || !shouldAnimate) {
      tl.to(ref, { rotation: 0 }, '>');
    }
    return tl;
  }

  playStepZero = () => {
    const tl = gsap.timeline({ defaults: { duration: 0.1 } });
    tl.add(this.moveArrow({ hide: true }), '<');
    tl.add(this.hideStep(this.stepOneOverlayRef), '>');
    tl.add(this.hideStep(this.stepTwoOverlayRef), '>');
    tl.add(this.hideStep(this.stepThreeOverlayRef), '>');
    return tl;
  }

  playStepOne = () => {
    const tl = gsap.timeline({ defaults: { duration: 0.5 } });
    tl.add(this.hideStep(this.stepOneOverlayRef), '<');
    tl.add(this.hideStep(this.stepTwoOverlayRef), '<');
    tl.add(this.hideStep(this.stepThreeOverlayRef), '<');
    tl.add(this.playStep(this.stepOneOverlayRef), '>');
    return tl;
  }

  playStepTwo = () => {
    const tl = gsap.timeline({ defaults: { duration: 0.5 } });
    tl.add(this.showStep(this.stepOneOverlayRef), '<');
    tl.add(this.hideStep(this.stepTwoOverlayRef), '<');
    tl.add(this.hideStep(this.stepThreeOverlayRef), '<');
    tl.add(this.playStep(this.stepTwoOverlayRef), '>');
    return tl;
  }

  playStepThree = () => {
    const tl = gsap.timeline({ defaults: { duration: 0.5 } });
    tl.add(this.showStep(this.stepOneOverlayRef), '<');
    tl.add(this.showStep(this.stepTwoOverlayRef), '<');
    tl.add(this.hideStep(this.stepThreeOverlayRef), '<');
    tl.add(this.playStep(this.stepThreeOverlayRef), '>');
    return tl;
  }

  playIsComplete = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.add(this.showStep(this.stepOneOverlayRef), '<');
    tl.add(this.showStep(this.stepTwoOverlayRef), '<');
    tl.add(this.showStep(this.stepThreeOverlayRef), '<');
    tl.add(this.moveArrow({ hide: true }));
    return tl;
  }

  /**
   * Plays the animation.
   * @param { Object } animationProps: Props to initialize or override default props passed in.
   * @returns { gsap.timeline } The animation timeline.
   */
  playAnimation = (animationProps = {}) => {
    const { shouldAnimate, step, isComplete, onComplete } = { ...animationProps, ...this.props };
    if (this.tl && this.tl.kill) {
      // If animation is currently playing, pause it and reset animation.
      this.tl.kill();
    }
    this.tl = gsap.timeline({ defaults: { duration: 0 }, onComplete });
    const stepAnimations = {
      0: this.playStepZero,
      1: this.playStepOne,
      2: this.playStepTwo,
      3: this.playStepThree,
    };
    if (isComplete) {
      this.tl.add(this.playIsComplete());
    } else {
      const playAnimation = stepAnimations[step] || stepAnimations[0];
      this.tl.add(playAnimation());
    }
    if (!shouldAnimate) {
      this.tl.seek(this.tl.duration());
    }
    return this.tl;
  };

  resetAnimation = () => {
    return this.playAnimation({ initialize: true });
  };

  render() {
    const { color, size, ...otherProps } = this.props;
    const omittedProps = Object.assign({}, otherProps);
    delete omittedProps.onComplete;

    return (
      <SVG
        {...omittedProps}
        size={size}
        viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}
      >
        <defs>
          <S.StepOneClip>
            <S.StepOneClipPath />
          </S.StepOneClip>
          <S.StepOneGradient>
            <S.StepOneGradientStop1 color={color} />
            <S.StepOneGradientStop2 color={color} />
          </S.StepOneGradient>
          <S.StepTwoClip>
            <S.StepTwoClipPath />
          </S.StepTwoClip>
          <S.StepTwoGradient>
            <S.StepTwoGradientStop1 color={color} />
            <S.StepTwoGradientStop2 color={color} />
          </S.StepTwoGradient>
          <S.StepThreeClip>
            <S.StepThreeClipPath />
          </S.StepThreeClip>
          <S.StepThreeGradient>
            <S.StepThreeGradientStop1 color={color} />
            <S.StepThreeGradientStop2 color={color} />
          </S.StepThreeGradient>
          <S.ArrowGradient>
            <S.ArrowGradientStop1 color={color} />
            <S.ArrowGradientStop2 color={color} />
          </S.ArrowGradient>
        </defs>
        <S.Group ref={(ref) => (this.stepOneRef = ref)}>
          <S.StepOneGroupClip>
            <S.StepOneBackgroundPath />
          </S.StepOneGroupClip>
          <S.StepOneGroupClip>
            <S.GroupOverlay ref={(ref) => (this.stepOneOverlayRef = ref)}>
              <S.OverlayBackground />
              <S.StepOneOverlayPath />
            </S.GroupOverlay>
          </S.StepOneGroupClip>
        </S.Group>
        <S.Group ref={(ref) => (this.stepTwoRef = ref)}>
          <S.StepTwoGroupClip>
            <S.StepTwoBackgroundPath />
          </S.StepTwoGroupClip>
          <S.StepTwoGroupClip>
            <S.GroupOverlay ref={(ref) => (this.stepTwoOverlayRef = ref)}>
              <S.OverlayBackground />
              <S.StepTwoOverlayPath />
            </S.GroupOverlay>
          </S.StepTwoGroupClip>
        </S.Group>
        <S.Group ref={(ref) => (this.stepThreeRef = ref)}>
          <S.StepThreeGroupClip>
            <S.StepThreeBackgroundPath />
          </S.StepThreeGroupClip>
          <S.StepThreeGroupClip>
            <S.GroupOverlay ref={(ref) => (this.stepThreeOverlayRef = ref)}>
              <S.OverlayBackground />
              <S.StepThreeOverlayPath />
            </S.GroupOverlay>
          </S.StepThreeGroupClip>
        </S.Group>
        <S.ArrowGroup ref={(ref) => (this.arrowRef = ref)}>
          <S.OverlayBackground />
          <S.ArrowTail />
          <S.ArrowHead />
        </S.ArrowGroup>
      </SVG>
    );
  }
}
