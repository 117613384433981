const MATTER_LOCAL_STORAGE = 'matterapp';

export const useLocalStorage = () => {
  const ls = localStorage;

  const getMatterLocalStorage = () => {
    const matterLocalStorage = ls.getItem(MATTER_LOCAL_STORAGE);
    if (!matterLocalStorage) {
      ls.setItem(MATTER_LOCAL_STORAGE, JSON.stringify({}));
      return {};
    }
    return JSON.parse(matterLocalStorage);
  };

  return {
    setItem: (key, value) => {
      const currentData = getMatterLocalStorage();
      ls.setItem(
        MATTER_LOCAL_STORAGE,
        JSON.stringify({ ...currentData, [key]: value })
      );
    },
    getItem: (key) => {
      const currentData = getMatterLocalStorage();
      return currentData[key];
    },
  };
};

export default useLocalStorage;
