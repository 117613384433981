import styled from 'styled-components';
import { Timezone } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

export const Spacer = styled.div`
  height: ${theme.spacing.double};
`;

export const TimezoneDropdown = styled(Timezone.Dropdown)`
  &[data-open='true'] {
    z-index: 20;

    & input {
      background: ${theme.colors.white} !important;
    }
  }
`;

export const WorkspaceNameContainer = styled.div`
  display: inline-block;
  word-break: break-word;
`;
