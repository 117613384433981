import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../Avatar';
import { AvatarContainer } from './styles';
import { AVATAR_SIZE, MENU_ITEM_ROLE } from '../styles';

const HeaderAvatar = ({ url, email }) => (
  <AvatarContainer role={MENU_ITEM_ROLE}>
    <Avatar size={AVATAR_SIZE} url={url} email={email} />
  </AvatarContainer>
);

HeaderAvatar.defaultProps = {
  url: null,
  email: null,
};

HeaderAvatar.propTypes = {
  /** The url of the avatar image. */
  url: PropTypes.string,
  /** The email of the user. */
  email: PropTypes.string,
};

export default HeaderAvatar;
