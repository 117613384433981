import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  color: ${() => theme.inputs.errorColor};
  line-height: 24px;
  text-align: center;
  padding: 10px;
`;

const Error = ({
  errorString,
}) => (
  <Container>
    {errorString}
  </Container>
);

Error.defaultProps = {
  errorString: '',
};

Error.propTypes = {
  errorString: PropTypes.string,
};

export default Error;
