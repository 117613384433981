import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Buttons from '../Buttons';
import theme from '@matterapp/matter-theme';

const MAX_BUTTON_HEIGHT = theme.spacing.doubleAndHalf;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing.threeQuarters} ${theme.spacing.singleAndHalf};
  border-bottom: 1px solid ${theme.colors.blacks[10]};
  ${({ showBorder }) => !showBorder && `border-bottom: none;`}
`;

const LeftContainer = styled.div`
  display: flex;
  position: absolute;
  left: ${theme.spacing.singleAndHalf};

  & button {
    max-height: ${MAX_BUTTON_HEIGHT};
  }
`;

const RightContainer = styled.div`
  display: flex;
  position: absolute;
  right: ${theme.spacing.half};

  & button {
    max-height: ${MAX_BUTTON_HEIGHT};
  }

  ${theme.media.S`
    right: ${theme.spacing.threeQuarters};
  `}
`;

const HeaderBox = styled.div`
  flex: 1;
  color: ${theme.colors.blacks[70]};
  font-weight: bold;
  margin-left: ${theme.spacing.doubleAndQuarter};
  margin-right: ${theme.spacing.doubleAndQuarter};
  font-size: ${({ shrinkOnMobile }) =>
    theme.fontSize[shrinkOnMobile ? 'S' : 'M']};

  ${LeftContainer}:empty + & {
    ${({ centerOnMobile }) => centerOnMobile ? `` : `margin-left: 0;`}
  }

  ${({ centerOnMobile }) => {
    if (centerOnMobile) {
      return `text-align: center;`;
    }
  }}

  ${() => theme.media.S`
    text-align: center;
    font-size: ${theme.fontSize.M};
    line-height: ${theme.lineHeight.L};

    &, ${LeftContainer}:empty + & {
      margin-left: ${theme.spacing.doubleAndQuarter};
    }
  `}
`;

const Header = ({
  centerOnMobile,
  className,
  left,
  header,
  title,
  right,
  shrinkOnMobile,
  showBorder,
}) => (
  <HeaderContainer className={className} showBorder={showBorder}>
    <LeftContainer>{left}</LeftContainer>
    <HeaderBox centerOnMobile={centerOnMobile} shrinkOnMobile={shrinkOnMobile}>
      {header || title}
    </HeaderBox>
    <RightContainer>{right}</RightContainer>
  </HeaderContainer>
);

Header.Buttons = Buttons;

Header.defaultProps = {
  centerOnMobile: true,
  left: null,
  right: null,
  shrinkOnMobile: false,
  showBorder: true,
};

Header.propTypes = {
  /** If header text is centered on mobile. Defaults to left-aligned. */
  centerOnMobile: PropTypes.bool,
  /** Additional class. */
  className: PropTypes.string,
  /** Left content. */
  left: PropTypes.node,
  /** Right content. */
  right: PropTypes.node,
  /** Header text. */
  header: PropTypes.node,
  /** Scale down title on mobile. */
  shrinkOnMobile: PropTypes.bool,
  /** Deprecated: Use 'header' instead. */
  title: PropTypes.string,
};

export default Header;
