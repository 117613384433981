import styled, { css } from 'styled-components';
import { Clickable } from '../../../Clickable/Clickable';
import { IconNew as Icon } from '../../../Icon';
import { border, colors, font, media, spacing, transitions } from '@matterapp/matter-theme';
import omitStyled from '../../../../libs/omitStyled';
import {
  ACCENT_COLOR,
  HOVER_COLOR,
  HIGHLIGHT_COLOR,
  HIGHLIGHT_ACTIVE_COLOR,
  ICON_TEXT_COLOR,
  ICON_TEXT_COLOR_MOBILE,
  MENU_ITEM_ROLE,
  MENU_ITEM_SECONDARY_ROLE,
  LARGE_NAV_NORMAL_SIZE_BREAKPOINT_NORMAL,
} from '../styles';

export const ICON_SIZE_VALUE = 24;
export const ICON_SIZE = `${ICON_SIZE_VALUE}px`;
export const INDICATOR_SIZE = '8px';
export const INACTIVE_COLOR = ICON_TEXT_COLOR;
export const INACTIVE_COLOR_MOBILE = ICON_TEXT_COLOR_MOBILE;
const DISABLED_COLOR = colors.blacks[30];

export { MENU_ITEM_ROLE, MENU_ITEM_SECONDARY_ROLE };

export const Container = omitStyled('div', ['isDisabled', 'isSecondary'])`
  flex: 1 1 100%;

  ${({ isSecondary }) => {
    if (isSecondary) {
      return css`
        ${media.L`
          margin-left: ${spacing.triple};

          & svg {
            display: none;
          }
        `}
      `;
    }
  }}

  ${({ isDisabled }) => isDisabled ? `
    cursor: default;
    pointer-events: none;
  ` : ``}
`;

export const CompactContainerWrapper = styled.div`
  ${media.L`
    padding: ${spacing.quarter} 0;
  `}
`;

// prettier-ignore
export const IconContainer = styled.div`
  position: relative;
  height: ${ICON_SIZE};
  display: ${({ hideIconOnMobile }) => (hideIconOnMobile ? 'none' : 'block')};

  ${media.L`
    display: inline-block;
    height: auto;
    margin-left: ${({ hasIcon }) => hasIcon ? spacing.threeQuarters : spacing.singleAndHalf};`}
`;

export const Text = omitStyled('div', ['isCompact', 'isDisabled'])`
  display: -webkit-box;
  color: inherit;
  font-size: ${font.size.XXS};
  font-weight: ${font.weight.medium};
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${media.L`
    display: flex;
    align-items: center;
    font-size: ${font.size.base};
    font-weight: ${font.weight.medium};
  `}

  ${({ isCompact }) => media.breakpoint(LARGE_NAV_NORMAL_SIZE_BREAKPOINT_NORMAL)`
    font-size: ${isCompact ? font.size.base : font.size.S};
  `}

  ${({ isDisabled }) => isDisabled ? `
    cursor: default;
    pointer-events: none;
  ` : ``}
`;

export const NumberNotificationIndicator = omitStyled('div', ['isActive'])`
  font-size: 10px;
  margin-left: 6px;
  height: ${spacing.single};
  width: ${spacing.single};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  ${Link}:hover & {
    filter: opacity(${({ isActive }) => (isActive ? 100 : 80)}%);
  }
  ${Link}:active & {
    filter: opacity(100%);
  }
  ${({ isActive }) => isActive ? `
    color: ${colors.purples[60]};
    background: white;
  ` : `
    color: white;
    background: ${colors.reds[50]};
  `};

  ${media.XL`
    font-size: 12px;
    margin-left: 8px;
    height: ${spacing.singleAndQuarter};
    width: ${spacing.singleAndQuarter};
  `}
`;

const getLinkPadding = ({ isCompact, isSecondary }) => {
  if (isCompact) {
    return `${spacing.half} 0`;
  } else if (isSecondary) {
    return `${spacing.quarter} 0`;
  }
  return `${spacing.threeQuarters} 0`;
};

// prettier-ignore
export const Link = omitStyled(Clickable, [
  'autoColorIcon',
  'isActive',
  'isCompact',
  'isSecondary',
  'isDisabled',
])`
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? colors.white : INACTIVE_COLOR)};
  position: relative;
  text-decoration: none !important;

  ${Container} > & {
    display: block;
    padding: ${spacing.quarter} 0 ${spacing.half};
    height: 100%;
    width: 100%;

    ${({ autoColorIcon }) => `
      &:active ${Text},
      &:active svg path {
        color: ${colors.white};
        ${autoColorIcon ? `fill: ${colors.white};` : ''}
      }
    `}

    ${media.L`
      text-align: ${({ isCompact }) => (isCompact ? 'left' : 'center')};
      padding: ${getLinkPadding};
    `}
  }

  ${CompactContainerWrapper} & {
    color: ${({ isActive }) => isActive ? ACCENT_COLOR : 'inherit'};
    
    ${Text} {
      font-weight: ${({ isActive }) => isActive ? font.weight.bold : font.weight.regular};
    }
    &:hover ${Text} {
      text-decoration: underline;
    }
    &:active ${Text} {
      color: ${ACCENT_COLOR};
    }
  }

  ${({ isSecondary }) => media.L`
    &:hover:after {
      background: ${({ isActive }) => isActive ? ACCENT_COLOR : HIGHLIGHT_COLOR};
    }
    &:active:after {
      background: ${({ isActive }) => isActive ? ACCENT_COLOR : HIGHLIGHT_ACTIVE_COLOR};
    }
    &:after {
      content: '';
      position: absolute;
      width: calc(100% - ${isSecondary ? spacing.singleAndQuarter : spacing.doubleAndHalf});
      height: calc(100% - ${isSecondary ? spacing.half : spacing.single});
      background: ${({ isActive }) => isActive ? ACCENT_COLOR : 'transparent'};
      border-radius: ${border.radius.S};
      top: ${isSecondary ? spacing.quarter : spacing.half};
      left: ${isSecondary ? `0` : `calc(${spacing.doubleAndHalf} / 2)`};
      transition: background ${transitions.times.default};
      z-index: -1;
    }

    ${CompactContainerWrapper} & {
      &:after {
        opacity: 0;
      }
    }
  `}

  ${media.L`
    &:after {
      width: 90%;
    }
  `}
  ${media.XL`
    &:after {
      width: calc(100% - ${spacing.doubleAndHalf});
    }
  `}
`;

// prettier-ignore
export const LinkContents = omitStyled('div', ['autoColorIcon', 'hasSecondaryTabs', 'isActive', 'isDisabled'])`
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: filter ${transitions.times.default};
  z-index: 1;

  ${({ autoColorIcon, isActive, isDisabled }) => `
    color: ${isActive ? colors.white : isDisabled ? DISABLED_COLOR : ICON_TEXT_COLOR_MOBILE};

    & svg {
      height: ${ICON_SIZE};
      margin: 0;
      ${autoColorIcon ? `
        path {
          fill: ${isActive ? colors.white : ICON_TEXT_COLOR_MOBILE};
        }`
    : ''}
    }

    & svg, & ${Text} {
      filter: opacity(${isActive ? '100' : '70'}%);
      transition: filter ${transitions.times.default};
    }
  `}

  ${Container}:hover ${Link} & svg,
  ${Container}:hover ${Link} & ${Text} {
    filter: opacity(100%);
  }

  ${({ autoColorIcon, isActive, isDisabled }) => media.L`
    color: ${isActive ? colors.white : isDisabled ? DISABLED_COLOR : ICON_TEXT_COLOR};
    display: flex;
    align-items: center;
    height: ${ICON_SIZE};
    filter: opacity(100%);
    text-align: left;
    width: 100%;

    ${Link}:active & svg path,
    ${Link}:active & ${Text} {
      color: ${isActive ? colors.white : HOVER_COLOR};
      ${autoColorIcon ? `fill: ${isActive ? colors.white : HOVER_COLOR};` : ''}
    }

    & svg, & ${Text} {
      filter: opacity(100%);
      transition: color ${transitions.times.default};
    }

    & svg {
      height: ${ICON_SIZE};
      margin: ${spacing.threeQuarters} ${spacing.half} ${spacing.threeQuarters} ${spacing.threeQuarters};
      vertical-align: middle;
      transition: fill ${transitions.times.default};
      path {
        transition: fill ${transitions.times.default};
        ${autoColorIcon ? `fill: ${isActive ? colors.white : 'currentColor'};` : ''}
      }
    }

    & img {
      height: ${ICON_SIZE};
      margin: ${spacing.threeQuarters};
    }
  `}

  ${media.breakpoint(LARGE_NAV_NORMAL_SIZE_BREAKPOINT_NORMAL)`
    & svg {
      margin: ${spacing.threeQuarters};
    }
  `}
`;

export const NotificationIndicator = styled.div`
  position: absolute;
  top: 0%;
  left: 50%;
  filter: opacity(${({ isActive }) => (isActive ? 100 : 60)}%);
  width: ${INDICATOR_SIZE};
  height: ${INDICATOR_SIZE};
  border-radius: 50%;
  background: ${colors.reds[50]};
  transform: translate(5px, 0px);
  transition: filter ${transitions.times.default};
  z-index: 1;

  ${Link}:hover & {
    filter: opacity(${({ isActive }) => (isActive ? 100 : 80)}%);
  }

  ${Link}:active & {
    filter: opacity(100%);
  }

  ${media.L`
    top: 50%;
    transform: translate(6px, -12px);
  `}
`;

export const GettingStartedIconContainer = styled.div`
  display: flex;
  margin-top: 0;
`;

export const GettingStartedIcon = styled(Icon).attrs(() => ({
  name: 'gettingStarted',
}))``;

export const GettingStartedLabel = styled.div`
  font-weight: ${font.weight.medium};
  margin-top: 0;
  margin-left: ${spacing.single};
  transition: font-size ${transitions.times.default};
  text-transform: uppercase;

  ${media.L`
    margin-left: 0;
    margin-top: calc(${spacing.single} - ${spacing.eighth});
    font-size: ${font.size.base};
    text-transform: none;
  `}

  ${media.breakpoint(1215)`
    font-size: ${font.size.S};
  `}
`;

export const GettingStartedProgressContainer = styled.div`
  background-color: ${colors.purples[40]};
  border-radius: ${border.radius.M};
  height: ${spacing.half};
  margin-top: calc(${spacing.half} - ${spacing.eighth});
  position: relative;
  width: 100%;
  opacity: ${({ isActive }) => (isActive ? 0 : 1)};
  overflow: hidden;
  transform: translateY(0px);
  transition: opacity ${transitions.times.default};

  ${media.L`
    transform: translateY(${spacing.eighth});
  `}
`;

export const GettingStartedProgressBar = styled.div`
  background-color: ${colors.purples[20]};
  height: ${spacing.half};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${({ width }) => width}%;
`;
