import React from 'react';
import Base, { Group, Path } from '../../Image';
import * as C from './classNames';

const Image = (props) => {
  return (
    <Base {...props}>
      <Group className={C.CUP}>
        <Path
          fill="#FFE8AF"
          d="M170.3,93.9c-0.7,0.1-1,0.3-1.2,0.6c-53.3,10.9-76.9-0.7-76.9-0.7c-0.1-1.6-2.7-1.5-2.6,0.2c0,0.2,1.3,20,3.2,40.1c3.9,41.2,6.4,43.7,7.4,44.7c3.1,3.1,9.5,5,14.4,6.1c14.5,3.2,34.1,2.7,46.3-6.1c1-1,3.5-3.5,7.4-44C169.5,113.2,173.2,95.1,170.3,93.9z"
        />
        <Group id="BOBA">
          <Path
            className={C.BOBA.B_11}
            fill="#42210B"
            d="M142.6,170c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1c-3.4,0-6.1-2.7-6.1-6.1C136.5,172.7,139.2,170,142.6,170z"
          />
          <Path
            className={C.BOBA.B_10}
            fill="#42210B"
            d="M115,168.5c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1c-3.4,0-6.1-2.7-6.1-6.1C108.8,171.2,111.6,168.5,115,168.5z"
          />
          <Path
            className={C.BOBA.B_9}
            fill="#42210B"
            d="M151.5,159.8c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1c-3.4,0-6.1-2.7-6.1-6.1C145.3,162.6,148.1,159.8,151.5,159.8z"
          />
          <Path
            className={C.BOBA.B_8}
            fill="#42210B"
            d="M137.7,156.8c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1C131.6,159.6,134.3,156.8,137.7,156.8z"
          />
          <Path
            className={C.BOBA.B_7}
            fill="#42210B"
            d="M123.6,158c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1C117.5,160.8,120.2,158,123.6,158z"
          />
          <Path
            className={C.BOBA.B_6}
            fill="#42210B"
            d="M109.4,155.3c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1C103.2,158,106,155.3,109.4,155.3z"
          />
          <Path
            className={C.BOBA.B_5}
            fill="#42210B"
            d="M156.5,137.2c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1C150.4,139.9,153.1,137.2,156.5,137.2z"
          />
          <Path
            className={C.BOBA.B_4}
            fill="#42210B"
            d="M148.1,147.3c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1c-3.4,0-6.1-2.7-6.1-6.1C142,150.1,144.7,147.3,148.1,147.3z"
          />
          <Path
            className={C.BOBA.B_3}
            fill="#42210B"
            d="M132.5,144.5c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1C126.3,147.3,129.1,144.5,132.5,144.5z"
          />
          <Path
            className={C.BOBA.B_2}
            fill="#42210B"
            d="M117.3,144c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1C111.2,146.7,113.9,144,117.3,144z"
          />
          <Path
            className={C.BOBA.B_1}
            fill="#42210B"
            d="M105.1,137.8c3.4,0,6.1,2.7,6.1,6.1c0,3.4-2.7,6.1-6.1,6.1S99,147.3,99,144C99,140.6,101.7,137.8,105.1,137.8z"
          />
        </Group>
        <Group className={C.STRAW}>
          <Path
            fill="#7BDBD4"
            d="M118,26.7c-0.5-2.1-2.1-3.7-4.4-4.5c-1.8-0.6-4-0.7-6.2-0.3c-5.3,1-8.8,4.8-8,8.7l8.8,31.4h19.9L118,26.7z"
          />
          <Path
            fill="#6CB7B0"
            d="M118,26.9c-0.4-2.1-2.1-3.8-4.5-4.7c-1.8-0.6-4-0.7-6.2-0.3s-4.2,1.4-5.6,2.6c-1.9,1.7-2.8,3.9-2.3,6c0.4,2.1,2.1,3.8,4.5,4.7c1.1,0.4,2.3,0.6,3.6,0.6c0.9,0,1.7-0.1,2.6-0.3C115.4,34.5,118.8,30.8,118,26.9z"
          />
        </Group>
        <Group className={C.CORGI.GROUP}>
          <Path
            id="CORGI_x5F_BACK"
            fill="#FFFFFF"
            d="M175.3,86.2c-0.7-6.8-2.1-13.9-6.3-19.4c3-8.4,12.3-31.5-2.6-32.6c-12.6,0-20.2,9.6-22.3,12.5c-6.2-4.4-27.3-5.1-33.8,1c-4.8-6.3-18.7-14.9-26.2-10.9c-10.4,5.9,4,24.5,8,31.4c-10.1,12.2-9.2,26,6.6,32.2c0.4,4.4,1.3,12.5,5.5,15.4c-0.4-2.5,0.7-5.3,0.9-5.8c0.3-0.7,1-1,1.7-0.7c0.7,0.3,1,1,0.7,1.7c-0.4,1.1-1.4,4.4-0.2,5.7c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0c0.7,0,1.4-0.1,2-0.2c0-0.2,0-0.4,0.1-0.6c1-2.1,0.4-5.3,0.4-5.3c-0.1-0.7,0.3-1.4,1-1.5c0.7-0.1,1.4,0.3,1.5,1c0,0.1,0.5,2.8-0.1,5.4c3.6-2,5.4-6.3,6.1-10c7.9,1.7,15.9,1.1,23.8,0.1c0.7,3.4,2.2,7.2,5.2,9.3c0.1-2.4,1-4.7,1.1-5.1c0.3-0.6,1-1,1.7-0.7c0.7,0.3,1,1,0.7,1.7c-0.4,0.9-1.2,3.7-0.8,5.4c0.7,0.2,1.4,0.3,2.1,0.4c1.5-3,1.1-6.1,1.1-6.1c-0.1-0.7,0.4-1.3,1.1-1.4c0.7-0.1,1.3,0.4,1.4,1.1c0,0.1,0.4,3-0.7,6.2c5.4-1.8,6.6-10.8,7-15.7C168.7,98.5,176.2,93.8,175.3,86.2z"
          />
          <Path
            id="CORGI_x5F_FRONT"
            fill="#FBC36C"
            d="M166.3,34.1c-12.6,0-20.2,9.6-22.3,12.5c-6.2-4.4-27.3-5.1-33.8,1c-4.8-6.3-18.7-14.9-26.2-10.9c-10.4,5.9,4,24.5,8,31.4c-4.2,5.1-6.5,10.4-6.6,15.4h1.7c0.3,0,0.6-0.1,0.8-0.3c0.1,0,6-4.6,11.9-4.6c1.5,0,3.5,0.5,5.6,0.9c5.2,1.2,11.1,2.5,14.5-1.4c5.8-6.5,6.3-10.6,6.1-13.6c-0.1-1.1-0.3-2-0.5-2.9c-0.3-1.2-0.5-2.2-0.2-2.9c0.2-0.3,0.4-0.6,0.7-0.9c1-1.1,1.8-2.3,1.4-5.3c-0.3-2.8-0.5-3.2-1.2-4.6l-0.2-0.3c-0.3-0.7-0.6-1.5-0.8-2.1c0.5,0,1,0,1.5,0c1.3,0,2.6,0,3.8,0.1c-0.1,0.5-0.1,1.2-0.1,2c0.1,2.2,1.1,3,1.9,3.6c0.4,0.3,0.8,0.6,1.2,1.1c0.4,0.5,0.2,1.1-0.3,2.5c-0.4,1-0.8,2-0.9,3.3c-0.3,3.3-0.4,5.5,2.2,13.1c2.4,7.3,11,11.3,16.9,11.3c0.9,0,1.7-0.1,2.5-0.3c0.5-0.1,1.2-0.3,1.8-0.5c4.6-1.2,14-3.6,16.4-2.2l2.4,0.9c-0.9-4.9-2.5-9.8-5.5-13.8C171.9,58.3,181.2,35.2,166.3,34.1z"
          />
          <Path
            id="RIGHT_x5F_EAR"
            fill="#FFE8F0"
            d="M165.2,69.2c2.9-6.4,9.5-24.4,5.5-30.5c-0.6-0.9-1.4-1.5-2.4-1.8c-1.4,0-3.2,0.4-5.5,1.5c-9.6,4.4-13.1,13.9-13.1,13.9l15.5,17.1C165.1,69.4,165.1,69.3,165.2,69.2z"
          />
          <Path
            id="LEFT_x5F_EAR"
            fill="#FFE8F0"
            d="M96.2,43.1c-4.7-3.7-7.7-4.5-8.6-4.6c-0.9,0.1-1.8,0.3-2.7,0.7c-1,0.4-1.5,1-1.8,1.9c-1.8,6,8,21.4,12.3,27.5l4.4-8.1l4.1-6.4C103.9,53.9,103,48.5,96.2,43.1z"
          />
          <Group className={C.CORGI.MOUTH}>
            <Path
              fill="#42210B"
              d="M131.8,80.7c-0.7,0.6-1.6,1-2.6,1c-1,0-1.9-0.3-2.6-1c-1.3-1.3-2.6-2.6-1.3-3.9c1.3-1.3,2.6-1.3,3.9-1.3c1.3,0,2.6,0,3.9,1.3C134.4,78.1,133.1,79.4,131.8,80.7z"
            />
            <Path
              fill="#FFCDDB"
              d="M125.3,84.6v3.9c0,0,0,3.9,3.9,3.9c3.9,0,3.9-3.9,3.9-3.9v-3.9"
            />
            <Path
              fill="#42210B"
              d="M129.2,93.6c-3.8,0-5.2-3.1-5.2-5.1v-3.9h2.6v3.9c0,0.6,0.2,2.6,2.6,2.6c2.4,0,2.6-2,2.6-2.6l0-3.9h2.6v3.9C134.4,90.5,133,93.6,129.2,93.6z"
            />
            <Path
              fill="#42210B"
              d="M126.6,85.9c-3.3,0-5-3.1-5-3.3c-0.3-0.6-0.1-1.4,0.6-1.7c0.6-0.3,1.4-0.1,1.7,0.6c0,0,1,1.9,2.8,1.9c0.2,0,0.7,0,1-1c0.3-0.8,0.3-1.6,0.3-1.6c0-0.7,0.6-1.3,1.3-1.3c0,0,0,0,0,0c0.7,0,1.3,0.6,1.3,1.3C130.5,82.5,129.7,85.9,126.6,85.9z"
            />
            <Path
              fill="#42210B"
              d="M131.8,85.9c-3.1,0-3.9-3.4-3.9-5.1c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0,0,0.8,0.3,1.6c0.4,1,0.8,1,1,1c1.8,0,2.7-1.9,2.8-1.9c0.3-0.6,1.1-0.9,1.7-0.6c0.6,0.3,0.9,1.1,0.6,1.7C136.8,82.7,135.2,85.9,131.8,85.9zM130.5,80.7L130.5,80.7L130.5,80.7z"
            />
          </Group>
          <Group className={C.CORGI.EYE.RIGHT}>
            <Path
              fill="#42210B"
              d="M145.6,64.7c-2.8,0-5.1,2.3-5.1,5.1c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1C150.7,67,148.4,64.7,145.6,64.7L145.6,64.7z"
            />
            <Path
              fill="#FFFFFF"
              d="M147.2,67.2c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3S146.5,67.2,147.2,67.2z"
            />
          </Group>
          <Group className={C.CORGI.EYE.LEFT}>
            <Path
              fill="#42210B"
              d="M113.1,64.6c-2.8,0-5.1,2.3-5.1,5.1c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1C118.2,66.9,115.9,64.6,113.1,64.6L113.1,64.6z"
            />
            <Path
              fill="#FFFFFF"
              d="M115.2,67.2c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3S114.5,67.2,115.2,67.2z"
            />
          </Group>
        </Group>
      </Group>
    </Base>
  );
};

export default Image;
