import React, { useContext, useState } from 'react';
import UserContext from 'context/UserContext/UserContext';
import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, Link } from "react-router-dom";
import { Resources } from '@matterapp/routing';
import { HELP_CENTER_URL } from 'app-consts/urls';
import {
  useAppHeaderData,
  useProfileMenuState,
} from 'modules/header/hooks/AppHeaderHooks';
import { useProfileModalState } from 'modules/profile/hooks';
import ProfileModal from 'modules/profile/components/ProfileModal';
import { useNotificationsModal } from 'modules/header/hooks/useNotifications';
import { NotificationsModal, MiniProfile } from 'modules';
import TenantSelectMenu from './TenantSelectMenu/TenantSelectMenu';
import HeaderSlideOutMenu from './HeaderSlideOutMenu/HeaderSlideOutMenu';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';

export const TOP_BAR_HEIGHT = '56px';

const NotificationsWrapper = styled(IconButton)(({ theme }) => ({
  margin: '0 10px',
  [theme.breakpoints.down('lg')]: {
    margin: 0
  }
}));

const DesktopIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}));

const MobileIconButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'flex'
  }
}));

const TenantExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: '12px',
  [theme.breakpoints.up('xl')]: {
    marginLeft: '12px'
  }
}));

const CoinsEarned = styled(Typography)(({ theme }) => ({
  display: 'inline',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

const TenantSelectGroup = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}));

const MobileHeader = styled.div(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
  }
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderRadius: 0, 
  padding: `0 ${theme.spacing(2)}`,
  boxShadow: 'none',
  zIndex: 80,
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(1)}`,
    backgroundColor: 'white',
    color: theme.palette.grey[90],
    'svg': {
      color: theme.palette.grey.main
    }
  }
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: theme.spacing(8),
  height: theme.spacing(8),
  paddingLeft: '15px',
  [theme.breakpoints.down('lg')]: {
    minHeight: TOP_BAR_HEIGHT,
    height: TOP_BAR_HEIGHT
  }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  return {
    color: theme.palette.grey.light,
    minHeight: '40px',
    fontSize: theme.fontSize.base,
    ':hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    },
    ':active': {
      textDecoration: 'underline',
      color: '#343799',
      backgroundColor: 'transparent'
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.grey[10]
    }
  };
});

const BottomMenuItem = styled(StyledMenuItem)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[10]}`
}));

const StyledMenu = styled(Menu)({
  ul: {
    padding: 0
  }
});

const TopMenuItem = styled(StyledMenuItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[10]}`,
  paddingTop: '11px',
  paddingBottom: '11px',
  ':hover': {
    textDecoration: 'none',
    cursor: 'inherit'
  }
}));

const StyledLink = styled(Link)({
  color: 'inherit'
});

export function Avatar({ url, sx }) {
  const DefaultComponent = () => (
    <AccountCircle sx={{ width: '100%', height: '100%' }} />
  );

  const StyledAvatar = styled.img({
    width: '100%',
    height: '100%',
    borderRadius: '50%'
  });

  const Component = url ? StyledAvatar : DefaultComponent;

  return (
    <Box sx={{ width: '32px', height: '32px', ...sx }}>
      <Component src={url} />
    </Box>
  );
}

export default function TopBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tenantAnchorEl, setTenantAnchorEl] = useState(null);
  const [isMobileProfileMenuOpen, setIsMobileProfileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isTenantSelectOpen = Boolean(tenantAnchorEl);
  const { 
    currentUser, 
    currentTenant, 
    tenants, 
    updateTenant, 
    currentWorkspaceId,
    notificationCount,
    memberProperties,
    currentWorkspace,
    isAdminMember
  } = useContext(UserContext);
  const { rewardsFundingSettings } = currentTenant || {};
  const { balance, enabled } = rewardsFundingSettings || {};
  const { topNavItem } = useContext(MobileMenuContext);

  const { 
    openNotificationsModal,
    isOpen: isNotificationsModalOpen,
    onClose: closeNotificationsModal,
  } = useNotificationsModal();

  const {
    activeProfileTab
  } = useAppHeaderData();

  const {
    onCloseMobileProfileMenu,
  } = useProfileMenuState({ activeProfileTab });
  const { photoUrl, fullName } = currentUser || {};

  const profileModalProps = useProfileModalState({ onCloseMobileProfileMenu, currentUser});

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <StyledMenu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ top: '15px' }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'transparent',
            opacity: 0
          }
        },
        paper: {
          elevation: 1,
          sx: {
            width: '240px',
            border: '1px solid #E6E6E7',
            borderRadius: '8px',
            boxShadow: '0px 4px 6px -2px #10182808',
            padding: 0
          }
        }
      }}
    >
      <TopMenuItem onClick={() => {
        handleMenuClose();
        profileModalProps.onClickOpenProfileModal();
      }}>
        <Avatar 
          sx={{ width: '40px', height: '40px', marginRight: '10px', cursor: 'pointer' }} 
          url={photoUrl}
        />
        <Typography 
          variant='span' 
          sx={{ fontWeight: '500', cursor: 'pointer' }}
        >
          {fullName}
        </Typography>
      </TopMenuItem>
      <StyledMenuItem onClick={() => {
        handleMenuClose();
        profileModalProps.onClickOpenProfileModal();
      }}>
        Profile
      </StyledMenuItem>
      <StyledMenuItem onClick={() => navigate(Resources.userSettingsMain.path())}>Account Settings</StyledMenuItem>
      <StyledMenuItem onClick={() => navigate(Resources.referralLink.path())}>Referral</StyledMenuItem>
      <StyledMenuItem>
        <StyledLink 
          to={HELP_CENTER_URL} 
          target='_blank' 
          noopener='true'
          noreferrer='true'
        >
          Help Center
        </StyledLink>
      </StyledMenuItem>
      <BottomMenuItem 
        onClick={() => navigate(Resources.authSignout.path({}, { notification: true }))}
      >
        Sign out
      </BottomMenuItem>
    </StyledMenu>
  );

  return (
    <>
      <StyledAppBar 
        position='sticky'
      >
        <StyledToolbar>
          <TenantSelectGroup
            onClick={(e) => {
              if (tenants.length > 1) {
                setTenantAnchorEl(e.currentTarget);
              } else {
                navigate(Resources.workspacesActivityFeed.path({ workspaceId: currentWorkspaceId }));
              }
            }}
          >
            <Avatar 
              sx={{ width: '24px', height: '24px', marginRight: '12px' }} 
              url={currentTenant?.photoUrl}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
            >
              {currentTenant?.name}
            </Typography>
            {tenants.length > 1 && (
              <TenantExpandMoreIcon 
                fontSize='large'
              />
            )}
          </TenantSelectGroup>
          <MobileHeader>
            {topNavItem ? topNavItem : (
              <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{currentWorkspace?.name}</Typography>
            )}
          </MobileHeader>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!!memberProperties?.currentCoins && (
              <CoinsEarned 
                variant='body2' 
                component='span' 
                sx={{ cursor: 'pointer' }} 
                onClick={() => navigate(Resources.rewardsStore.path({ workspaceId: currentWorkspaceId }))}
              >
                {memberProperties?.currentCoins} 
                <Typography 
                  variant='body2' 
                  component='span' 
                  sx={{ m: '0 5px', verticalAlign: 'middle' }}
                >
                  Ⓜ️
                </Typography> 
                Coins Earned
              </CoinsEarned>
            )}
            {isAdminMember && enabled && (
              <>
                {!!memberProperties?.currentCoins ? (
                  <CoinsEarned variant='body2' sx={{ ml: 1.5, mr: 1.5 }}>|</CoinsEarned>
                ) : null}
                <CoinsEarned 
                  variant='body2' 
                  component='span' 
                  sx={{ cursor: 'pointer' }} 
                  onClick={() => navigate(Resources.rewardsManager.path({ workspaceId: currentWorkspaceId }))}
                >
                  {(parseInt(balance) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} 
                  &nbsp;USD Available Balance
                </CoinsEarned>
              </>
            )}
            <NotificationsWrapper
              size='large'
              color='inherit'
              onClick={openNotificationsModal}
            >
              <Badge badgeContent={notificationCount} color='error'>
                <NotificationsIcon />
              </Badge>
            </NotificationsWrapper>
            <DesktopIconButton
              edge='end'
              onClick={handleProfileMenuOpen}
              color='inherit'
              sx={{ pl: 0 }}
            >
              <Avatar url={photoUrl} />
              <ExpandMoreIcon fontSize='large' />
            </DesktopIconButton>
            <MobileIconButton
              edge='end'
              onClick={() => setIsMobileProfileMenuOpen(true)}
              color='inherit'
            >
              <MoreVertIcon fontSize='large' />
            </MobileIconButton>
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      <TenantSelectMenu 
        isOpen={isTenantSelectOpen}
        onClose={() => setTenantAnchorEl(null)}
        tenants={tenants}
        currentTenant={currentTenant}
        onSelect={updateTenant}
      />
      {renderMenu}
      <ProfileModal {...profileModalProps}/>
      <NotificationsModal
        isOpen={isNotificationsModalOpen}
        onClose={closeNotificationsModal}
        tenantId={currentTenant?.id}
        workspaceId={currentWorkspaceId}
      />
      <HeaderSlideOutMenu
        isOpen={isMobileProfileMenuOpen}
        onClickClose={() => setIsMobileProfileMenuOpen(false)}
        onClickOpenProfileModal={() => {
          setIsMobileProfileMenuOpen(false);
          profileModalProps.onClickOpenProfileModal();
        }}
      >
        <MiniProfile isCompact />
      </HeaderSlideOutMenu>
    </>
  );
}