import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const DEFAULT_COLOR = 'gray';
const DEFAULT_SIZE = 24;
const NUMBER_OF_BLADES = 10;
const ANIMATION_TIME = 0.6;

const SpinnerFade = keyframes`
  0% { background-color: ${DEFAULT_COLOR}; }
  100% { background-color: transparent; }
`;

const SpinnerBlader = styled.div`
  ${({ size }) => `
    position: absolute;
    left: calc(50% - ${size / 24}px);
    top: 0;
    width: ${size / 12}px;
    height: ${size / 5}px;
    border-radius: 2px;
    transform-origin: center ${size / 2}px;
  `};
  background-color: transparent;

  animation: ${SpinnerFade} ${ANIMATION_TIME}s infinite linear;
`;

const getBladeStyles = () => {
  const styles = [];
  let animationDelay = 0;
  let bladeRotation = 0;

  for (let i = 1; i <= NUMBER_OF_BLADES; i++) {
    styles.push(`
      & ${SpinnerBlader}:nth-child(${i}) {
        animation-delay: ${animationDelay}s;
        transform: rotate(${bladeRotation}deg);
      }
    `);
    animationDelay += ANIMATION_TIME / NUMBER_OF_BLADES;
    bladeRotation += 360 / NUMBER_OF_BLADES;
  }
  return styles.join('');
};

const Spinner = styled.div`
  position: relative;
  display: inline-block;
  ${({ size }) => `
    height: ${size}px;
    width: ${size}px;
  `}
  ${getBladeStyles}
`;

const Inline = (props) => {
  const { className, size, style } = props;
  const blades = [];
  for (let i = 0; i < NUMBER_OF_BLADES; i++) {
    blades.push(<SpinnerBlader key={`${i}-blade`} size={size} />);
  }
  return (
    <Spinner className={className} size={size} style={style}>
      {blades}
    </Spinner>
  );
};

Inline.defaultProps = {
  size: DEFAULT_SIZE,
};

Inline.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default Inline;
