import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill={fill}>
      <path d="M19.64 10.99L16 3.62l-3.64 7.37-8.135 1.185 5.89 5.74-1.39 8.1L16 22.19l7.275 3.825-1.39-8.1 5.89-5.74-8.135-1.185z" />
      <path d="M16 25l-1.25 5h2.5L16 25zM10.71 8.72L6.76 5.41l2.02-1.47 1.93 4.78zM27.13 18.96l-2.57-.18 2.185 1.37 2.185 1.365.77-2.38-2.57-.175zM7.44 18.78l-2.185 1.37-2.185 1.365-.77-2.38 2.57-.175 2.57-.18zM21.29 8.72l3.95-3.31-2.02-1.47-1.93 4.78z" />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
