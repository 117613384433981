import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1522 9.75238V5.10476H22.7667C23.7512 5.10476 23.9973 5.66349 23.9973 5.94286L16.7674 10.8952L15.1522 9.75238Z"
      fill="#0072C5"
    />
    <path
      d="M16.7674 12.7238L15.1522 11.6571V17.9048H23.0744C23.8743 17.9048 24.023 17.2952 23.9973 16.9905V8.07619L16.7674 12.7238Z"
      fill="#0072C5"
    />
    <path
      d="M8.96235 12.0115C8.96235 13.4637 8.17623 14.641 7.20668 14.641C6.23699 14.641 5.45102 13.4637 5.45102 12.0115C5.45102 10.5593 6.23699 9.38207 7.20668 9.38207C8.17623 9.38207 8.96235 10.5593 8.96235 12.0115Z"
      fill="#0072C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.81905V21.181L14.1523 24V0L0 2.81905ZM10.768 11.9619C10.768 14.3183 9.21848 16.2286 7.30688 16.2286C5.39529 16.2286 3.84573 14.3183 3.84573 11.9619C3.84573 9.60549 5.39529 7.69524 7.30688 7.69524C9.21848 7.69524 10.768 9.60549 10.768 11.9619Z"
      fill="#0072C5"
    />
  </svg>
);

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Icon.defaultProps = {
  width: 32,
  height: 32,
};

export default Icon;
