import React from 'react';
import PropTypes from 'prop-types';
import {
  ColorPalettes,
  Container,
  ToggleButton,
  ToggleSwitch,
  SIZES,
} from './styles';

const Toggle = (props) => {
  const { checked, color, disabled, indeterminate, onChange, size, disabledColor } = props;
  const handleChange = (e) => onChange(e, { ...props, checked: !checked });

  return (
    <Container>
      <ToggleButton
        checked={checked}
        color={color}
        disabled={disabled}
        disabledColor={disabledColor}
        indeterminate={indeterminate}
        onClick={handleChange}
        size={size}
      >
        <ToggleSwitch
          size={size}
          checked={checked}
          indeterminate={indeterminate}
          disabled={disabled}
        />
      </ToggleButton>
    </Container>
  );
};

Toggle.colors = ColorPalettes;
Toggle.sizes = SIZES;

Toggle.defaultProps = {
  color: ColorPalettes.Blue,
  onChange: () => null,
  size: SIZES.M,
};

Toggle.propTypes = {
  /** If toggle is checked/active. */
  checked: PropTypes.bool,
  /** Color of the toggle. */
  color: PropTypes.oneOf(Object.values(ColorPalettes)),
  /** If toggle is disabled. */
  disabled: PropTypes.bool,
  /** Callback when checked value changes on toggle. */
  onChange: PropTypes.func,
  /** Size of the toggle. */
  size: PropTypes.oneOf(Object.values(SIZES)),
};

export default Toggle;
