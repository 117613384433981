const MARK_AS_SHOWN = '@component/mark-as-shown';
const UNMARK_AS_SHOWN = '@component/unmark-as-shown';

const initialState = {
  componentsShown: new Set(),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case MARK_AS_SHOWN: {
    const { componentId } = action;
    return {
      ...state,
      componentsShown: new Set(state.componentsShown).add(componentId),
    };
  }
  case UNMARK_AS_SHOWN: {
    const { componentId } = action;
    const componentsShown = new Set(state.componentsShown);
    componentsShown.delete(componentId);
    return { ...state, componentsShown };
  }
  default:
    return state;
  }
};

export const markComponentAsShown = (componentId) => ({
  type: MARK_AS_SHOWN,
  componentId,
});

export const unmarkComponentAsShown = (componentId) => ({
  type: UNMARK_AS_SHOWN,
  componentId,
});

export default reducer;
