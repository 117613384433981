import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Heading = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${() => theme.colors.purples[50]};
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  font-size: 17px;
  line-height: 32px;
  font-size: 24px;
  margin-bottom: 16px;
  padding-left: 20px;

  ${() => theme.media.medium`
    padding-left: 24px;
    margin-bottom: 28px;
  `}
  ${() => theme.media.large`
    padding-left: 0;
    margin-bottom: 28px;
  `}

`;

const ListHeading = ({
  children,
  indent,
  style
}) => (
  <Heading
    indent={indent}
    style={style}
  >
    {children}
  </Heading>
);
export default ListHeading;
