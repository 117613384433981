import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FEED_ITEM_FOOTERABLE_CLASSNAME } from '../consts';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 375px;
  padding: 18px 12px;
  border-top: 1px solid ${() => theme.colors.purples[5]};
  background: white;
`;

const ActionBar = React.forwardRef(({ children }, ref) => (
  <Container className={FEED_ITEM_FOOTERABLE_CLASSNAME} ref={ref}>
    {children}
  </Container>
));

ActionBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ActionBar;
