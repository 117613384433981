import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import omitStyled from '../../libs/omitStyled';
import matterLogoSvg from '../../../assets/svgs/logos/matter-logo-color.svg';
import matterWordBlueSvg from '../../../assets/svgs/logos/matter-wordmark--blue.svg';
import matterWordWhiteSvg from '../../../assets/svgs/logos/matter-wordmark--white.svg';

class LogoWordMark extends React.PureComponent {
  static wordSVGs = {
    normal: matterWordBlueSvg,
    inverted: matterWordWhiteSvg,
  };

  static LogoContainer = styled.div`
    display: inline-block;
    width: auto;
    height: 28px;

    transition: transform 0.1s ease-in-out;
    &:active {
      transform: translateY(-1.5px);
    }
  `;

  static WordMark = omitStyled('img', ['color'])`
    display: none;
    height: 18px;
    margin-left: 12px;
    vertical-align: middle;
    display: inline-block;
  `;

  render() {
    const { href, color } = this.props;
    const containerProps = href ? { as: 'a', href } : {};
    return (
      <LogoWordMark.LogoContainer {...containerProps} className="matter-logowordmark">
        <img
          src={matterLogoSvg}
          alt="Matter logo"
          style={{
            height: '28px',
            verticalAlign: 'middle',
          }}
          className="matter-logo"
        />
        <LogoWordMark.WordMark
          src={LogoWordMark.wordSVGs[color]}
          alt="Matter word mark"
          color={color}
          className="matter-wordmark"
        />
      </LogoWordMark.LogoContainer>
    );
  }
}

LogoWordMark.defaultProps = {
  href: null,
  color: 'normal',
};

LogoWordMark.propTypes = {
  href: PropTypes.string,
  color: PropTypes.oneOf(['inverted', 'normal']),
};

export default LogoWordMark;
