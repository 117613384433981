import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { IconNew as Icon } from '../../../Icon';
import useOnclickOutside from "react-cool-onclickoutside";

const ArrowIcon = styled(Icon).attrs({ name: 'karotDownSmall' })``;

const DropdownContainer = styled.div(({ hasError, isOpen }) => {
  return {
    position: 'relative',
    textAlign: 'left',
    fontSize: theme.fontSize.S,
    color: hasError && !isOpen ? theme.colors.reds['20'] : theme.colors.blacks[80],
    fontWeight: theme.fontWeight.regular,
    backgroundColor: hasError && !isOpen ? theme.colors.reds['5'] : 'inherit',
    borderRadius: '4px'
  };
});

const Header = styled.div(({ hasError, isOpen, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: disabled ? 'default' : 'pointer',
  width: '100%',
  height: '56px',
  padding: theme.spacing.single,
  border: `1px solid ${hasError && !isOpen
    ? theme.colors.reds['60'] 
    : isOpen ? theme.inputs.borderActiveColor : theme.inputs.borderColor}`,
  borderRadius: theme.inputs.borderRadius
}));

const Menu = styled.div`
  position: absolute;
  width: 100%;
  margin-top: -3px;
  z-index: 105;
  overflow: hidden;
  background: white;
  border-radius: 0 0 ${theme.inputs.borderRadius} ${theme.inputs.borderRadius};
  box-shadow: ${theme.shadows[50]};
  border: 1px solid ${theme.inputs.borderActiveColor};
  border-top-color: ${theme.inputs.borderColor};
  max-height: 228px;
  overflow-y: scroll;
  ${({ isOpen }) => !isOpen && `border: none;`}
  ${theme.media.S`
    position: ${({ position }) => position};
    width: ${({ width }) => width};
  `}
`;

const MenuItem = styled.div`
  padding: ${theme.spacing.single} !important;
  cursor: pointer;
  /*
    HACK: the borders are 1.5px so the inner contents overhang. The 1px fixes
  */
  width: calc(100% - 1px);
  border-bottom: 1px solid ${theme.colors.blacks[20]};
  &:hover {
    background: ${theme.colors.blacks[5]};
  }
`;

const Dropdown = ({ 
  width, 
  position, 
  options, 
  selectedOption, 
  handleChangeSelection, 
  disabled,
  hasError
}) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const handleClose = () => {
    setIsOpen(false);
  };

  const ref = useOnclickOutside(() => {
    handleClose();
  });

  const onChangeSelection = (selectedOption) => {
    handleChangeSelection(selectedOption);
    handleClose();
  };

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  const label = selectedOption.label ? selectedOption.label : selectedOption;
  
  return (
    <DropdownContainer ref={ref} hasError={hasError} isOpen={isOpen}>
      <Header
        isOpen={isOpen}
        onClick={disabled ? null : toggleList}
        disabled={disabled}
        hasError={hasError}
      >
        {label}
        {!disabled && (
          <ArrowIcon 
            backgroundFill={hasError && !isOpen ? theme.colors.reds['5'] : '#fff'}
            fill={hasError && !isOpen ? theme.colors.reds['60'] : '#343799'}
          />
        )}
      </Header>
      <Menu isOpen={isOpen} width={width} position={position}>
        {isOpen && options.map((option, index) => {
          const label = option.label ? option.label : option;
          const subtext = option.subtext;

          return (
            <MenuItem key={index} onClick={() => onChangeSelection(option)}>
              {label}
              {subtext ? <div style={{ fontSize: '14px' }}>{subtext}</div> : null}
            </MenuItem>
          );
        })}
      </Menu>
    </DropdownContainer>
  );
};

Dropdown.proptypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOption: PropTypes.string.isRequired,
  handleChangeSelection: PropTypes.func.isRequired,
};

export default Dropdown;