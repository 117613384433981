import { useRef, useEffect } from "react";

/** Keeps track of the value of the previous ref,
 * similar to componentDidUpdate's prevProp property */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};