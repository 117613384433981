import React from 'react';
import CenterToLeft1 from '../Paths/CenterToLeft1';
import BasePath from './BasePath';

const Path1 = React.forwardRef((props, ref) => (
  <BasePath
    PathComponent={CenterToLeft1}
    pointProps={{ top: 109, left: 2 }}
    ref={ref}
    {...props}
  />
));

export default Path1;


