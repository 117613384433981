import React from 'react';
import PropTypes from 'prop-types';
import SkillRatingItem from './SkillRatingItem';

import Gauge from '../../Skill/Gauge';
import * as FeedPropTypes from '../prop-types';
import { generateSkillRatingsLayout, getIsAlternate } from '../consts';
import { RatingsContainer } from './styles';

const SkillRatings = ({
  skillRatings,
  expanded,
  giver,
  onClickLearnMore,
  onClickActionItem,
  onClickSkillBadge,
  skillGaugeProps,
  overallRating,
  recurringFeedbackRecurrence,
}) => {
  const skillRatingsWithLayout = generateSkillRatingsLayout(skillRatings);
  const isWithActionItems = skillRatings.reduce((acc, currentSkillRating) => {
    return (
      acc ||
      !!(
        currentSkillRating.actionItems && currentSkillRating.actionItems.length
      )
    );
  }, false);
  const isReversed = skillRatings.length % 2 === 0 && isWithActionItems;

  return (
    <RatingsContainer>
      {skillRatingsWithLayout.map(({ skillRating, width, isAlternate }) => {
        return (
          <SkillRatingItem
            key={skillRating.skill.name}
            width={width}
            isAlternate={getIsAlternate({ isAlternate, isReversed })}
            truncateNote={!expanded}
            skillRating={skillRating}
            giver={giver}
            overallRating={overallRating}
            recurringFeedbackRecurrence={recurringFeedbackRecurrence}
            onClickLearnMore={onClickLearnMore}
            onClickActionItem={onClickActionItem}
            onClickSkillBadge={() => onClickSkillBadge(skillRating.skill)}
            skillGaugeProps={skillGaugeProps}
          />
        );
      })}
    </RatingsContainer>
  );
};

SkillRatings.defaultProps = {
  expanded: false,
  onClickLearnMore: () => {},
  onClickActionItem: () => {},
  onClickSkillBadge: () => {},
  skillGaugeProps: {},
};

SkillRatings.propTypes = {
  skillRatings: PropTypes.arrayOf(FeedPropTypes.skillRatingPropType).isRequired,
  expanded: PropTypes.bool,
  onClickLearnMore: PropTypes.func,
  onClickActionItem: PropTypes.func,
  onClickSkillBadge: PropTypes.func,
  skillGaugeProps: PropTypes.shape(Gauge.propTypes),
};

export default SkillRatings;
