import React from 'react';
import PropTypes from 'prop-types';
import WithClose from './WithClose';
import { BackIcon } from '../Buttons';

const WithBackClose = (props) => {
  const { hideBack, onClickBack, ...otherProps } = props;

  return (
    <WithClose
      left={hideBack ? null : <BackIcon onClick={onClickBack} />}
      {...otherProps}
    />
  );
};

WithBackClose.propTypes = {
  /** If close button is hidden. */
  hideBack: PropTypes.bool,
  /** If close button is hidden. */
  hideClose: PropTypes.bool,
  /** Callback when back button is clicked. */
  onClickBack: PropTypes.func,
  /** Callback when close button is clicked. */
  onClickClose: PropTypes.func,
};

export default WithBackClose;
