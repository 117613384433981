import { useEffect, useState } from 'react';

export const parseValue = (value, currentValues) => {
  const splitValues = value.split(',');
  const values = splitValues
    .map((value) => value.trim())
    .filter((value) => value);

  return Array.from(new Set([...currentValues, ...values]));
};

export const useTagState = (props) => {
  const {
    addButtonLabel,
    isValidValue,
    onBlur,
    onChange,
    onChangeInput,
    onSelect,
    showAddButton,
    showDropdownArrow,
    singleValue,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [inputHasError, setInputHasError] = useState(false);
  const [selectedValues, setSelectValues] = useState(props.value || []);

  useEffect(() => {
    if (props.value && (selectedValues.length !== !props.value.length)) {
      setSelectValues(props.value);
    }
  }, [props.value]);

  const checkForErrors = (values = []) => {
    const hasError =
      values.length && values.find((value) => !isValidValue(value));
    setInputHasError(hasError);
    return !!hasError;
  };

  const handleSelect = (e, value) => {
    setInputValue('');
    const values = singleValue ? [value] : parseValue(value, selectedValues, isValidValue);
    const hasError = checkForErrors(values);
    setSelectValues(values);
    onChange(e, { ...props, hasError, value: values, isSelected: true });
    onSelect(e, { ...props, hasError, value: values, isSelected: true });
    onChangeInput(e, { ...props, value: '' });
  };

  const handleChange = (e, changeProps) => {
    const { isSelected, value } = changeProps;
    if (isSelected) {
      handleSelect(e, value);
    } else {
      setInputValue(value);
      onChangeInput(e, { ...props, value });
    }
  };

  const handleBlur = (e) => {
    onBlur(e, {
      ...props,
      inputValue,
      setInputValue,
      selectedValues,
      parseValue,
    });
  };

  const handleKeyPress = (e) => {
    const value = e.target.value.trim();
    if (e.key === 'Enter' && value) {
      handleSelect(e, value);
    }
  };

  const handleRemove = (removeValue) => {
    const indexOfValue = selectedValues.indexOf(removeValue);
    const newValues = [
      ...selectedValues.slice(0, indexOfValue),
      ...selectedValues.slice(indexOfValue + 1),
    ];
    const hasError = checkForErrors(newValues);
    setSelectValues(newValues);
    onChange({}, { ...props, hasError, value: newValues });
  };

  return {
    addButtonProps: {
      content: addButtonLabel,
      disabled: !inputValue,
      onClick: (e) => {
        handleSelect(e, inputValue);
      },
    },
    handleBlur,
    handleChange,
    handleKeyPress,
    handleRemove,
    hasError: inputHasError,
    selectedValues,
    showAddButton,
    showDropdownArrow,
    singleValue,
    value: inputValue,
  };
};
