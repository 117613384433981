import React from 'react';
import styled from 'styled-components';
import { omit } from 'lodash/fp';

// This is from
// https://github.com/styled-components/styled-components/issues/439#issuecomment-373448148
//
// Basically, styled components pass all props down to the component being
// styled. React will complain if an unknown prop is passed to some components
// (e.g. html components). Styled components has a discussion going in that
// issue thread about how to fix, but it is as of yet still an issue. So this.
//
// Usage
//
// const StyledChild = omitStyled(ParentComponent, ['someProp']).attrs({
//   anotherProp: ({ someProp }) => (someProp ? 1 : 0),
// })`
//   opacity: ${({ someProp }) => (someProp ? 1 : 0)}
// `;

const omitStyled = (element, omitProps) => (
  styled((props) => React.createElement(element, { ...omit(omitProps, props) }))
);

export default omitStyled;
