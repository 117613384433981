import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckIcon, IconContainer } from '../sharedStyles';
import { sizesList } from '../utils';
import {
  Container,
  Checkbox,
  CheckboxContainer,
  Wrapper,
  LabelContainer,
  Label,
  SubLabel,
  Tooltip,
  TooltipTrigger,
} from './styles';

const DATA_ROLE = 'dropdown-item';

const Item = React.forwardRef((props, ref) => {
  const {
    actionItemContent,
    actionItemComponent,
    error,
    disabled,
    disabledMessage,
    label,
    labelProps,
    subLabel,
    highlightedValue,
    icon,
    isHighlighted,
    isSelected,
    onClick,
    onMouseDown = () => null,
    size,
    useCheckbox,
    ...otherProps
  } = props;
  const isActionItem = !!actionItemContent;
  const highlighted = label === highlightedValue;
  const [isItemSelected, setIsItemSelected] = useState(isSelected);
  const isSelectedState = useCheckbox ? isItemSelected : isSelected;
  let called = false;

  useEffect(() => {
    if (highlighted && !called) {
      otherProps.onMouseEnter({});
      called = true;
    } else {
      called = false;
    }
  }, [highlightedValue]);

  useEffect(() => {
    if (useCheckbox) {
      setIsItemSelected(isSelected);
    }
  }, [isSelected, useCheckbox]);

  const onMouseDownFunc =
    useCheckbox && !isActionItem
      ? (e) => {
        onMouseDown(e);
        setIsItemSelected(!isItemSelected);
      }
      : onMouseDown;

  const sharedContainerProps = {
    ref,
    error,
    disabled,
    onClick: disabled ? null : onClick,
    onMouseDown: disabled ? null : onMouseDownFunc,
    ...otherProps,
  };

  if (isActionItem) {
    const ActionContainer = actionItemComponent || Container;
    return (
      <ActionContainer
        isHighlighted={false}
        isSelected={false}
        {...sharedContainerProps}
      >
        {actionItemContent}
      </ActionContainer>
    );
  }

  const isItemDisabled = disabledMessage && disabled;
  const itemContents = (
    <>
      <Wrapper hasCheckbox={useCheckbox}>
        {useCheckbox && (
          <CheckboxContainer>
            {!isSelectedState && <Checkbox />}
            {isSelectedState && <Checkbox checked />}
          </CheckboxContainer>
        )}
        <LabelContainer isDisabled={isItemDisabled}>
          <Label {...labelProps}>{label}</Label>
          <SubLabel>{subLabel}</SubLabel>
        </LabelContainer>
      </Wrapper>
      {!useCheckbox && isSelectedState && (
        <IconContainer size={size}>
          <CheckIcon />
        </IconContainer>
      )}
      {icon && !isSelectedState && !useCheckbox && (
        <IconContainer size={size}>{icon}</IconContainer>
      )}
    </>
  );

  return (
    <Container
      isHighlighted={highlighted || isHighlighted}
      isSelected={isSelectedState}
      size={size}
      data-selected={isSelectedState}
      data-label={label}
      data-role={DATA_ROLE}
      {...sharedContainerProps}
    >
      {isItemDisabled && (
        <Tooltip content={disabledMessage} TriggerComponent={TooltipTrigger}>{itemContents}</Tooltip>
      )}
      {!isItemDisabled && itemContents}
    </Container>
  );
});

Item.propTypes = {
  actionItemContent: PropTypes.node,
  /** If item is disabled. */
  disabled: PropTypes.bool,
  /** Value of the highlighted item. */
  highlightedValue: PropTypes.string,
  /** If item is currently highlighted. */
  isHighlighted: PropTypes.bool,
  /** If item is currently selected. */
  isSelected: PropTypes.bool,
  /** The label of the item. */
  label: PropTypes.node,
  /** Callback when item is clicked. */
  onClick: PropTypes.func,
  /** Callback when mouse down on item. */
  onMouseDown: PropTypes.func,
  /** Callback when mouse enter on item. */
  onMouseEnter: PropTypes.func,
  /** Size of the dropdown. */
  size: PropTypes.oneOf(sizesList),
  /** The sub label of the item. */
  subLabel: PropTypes.string,
  /** Displays checkboxes for multiselection. */
  useCheckbox: PropTypes.bool,
};

Item.dataRole = DATA_ROLE;

export default Item;
