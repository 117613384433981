import React from 'react';
import Base, {
  Group,
  GroupBlendMultiply,
  Path,
  PathEvenOdd,
} from '../../Image';
import * as C from './classNames';

const Image = (props) => {
  return (
    <Base {...props}>
      <Group className={C.CUP.GROUP}>
        <Group className={C.BACKGROUND}>
          <Path
            className={C.CIRCLE.TOP}
            d="M82.001 39.5998C83.5472 39.5998 84.7992 38.3451 84.7992 36.7998C84.7992 35.2545 83.5472 33.9998 82.001 33.9998C80.4548 33.9998 79.1992 35.2545 79.1992 36.7998C79.1992 38.3451 80.4548 39.5998 82.001 39.5998Z"
            fill="#A2CCFF"
          />
          <Path
            className={C.CIRCLE.LEFT_1}
            d="M39.2 51.5999C40.9659 51.5999 42.4 50.166 42.4 48.3999C42.4 46.6338 40.97 45.1999 39.2 45.1999C37.43 45.1999 36 46.6338 36 48.3999C36.0041 50.166 37.4341 51.5999 39.2 51.5999Z"
            fill="#A2CCFF"
          />
          <Path
            className={C.CIRCLE.LEFT_2}
            d="M53.9992 69.1997C55.5444 69.1997 56.7992 67.9451 56.7992 66.3997C56.7992 64.8544 55.548 63.5997 53.9992 63.5997C52.454 63.5997 51.1992 64.8544 51.1992 66.3997C51.1992 67.9451 52.454 69.1997 53.9992 69.1997Z"
            fill="#A2CCFF"
          />
          <Path
            className={C.CIRCLE.RIGHT}
            d="M176.401 27.5999C177.948 27.5999 179.2 26.3453 179.2 24.7999C179.2 23.2546 177.948 21.9999 176.401 21.9999C174.855 21.9999 173.6 23.2546 173.6 24.7999C173.603 26.3453 174.855 27.5999 176.401 27.5999Z"
            fill="#A2CCFF"
          />
          <Path
            className={C.RING.LEFT}
            d="M58.8015 35.6C55.9362 35.6 53.5996 33.2665 53.5996 30.4C53.5996 27.5334 55.9325 25.2 58.8015 25.2C61.6667 25.2 63.9996 27.5334 63.9996 30.4C63.9996 33.2702 61.6667 35.6 58.8015 35.6ZM58.8015 27.6101C57.2635 27.6101 56.0064 28.8626 56.0064 30.4073C56.0064 31.9483 57.2598 33.2044 58.8015 33.2044C60.3395 33.2044 61.5965 31.9519 61.5965 30.4073C61.5965 28.8626 60.3395 27.6101 58.8015 27.6101Z"
            fill="#A2CCFF"
          />
          <Path
            className={C.RING.BOTTOM}
            d="M55.5985 114.8C52.7333 114.8 50.4004 112.467 50.4004 109.6C50.4004 106.737 52.7333 104.4 55.5985 104.4C58.4638 104.4 60.8004 106.733 60.8004 109.6C60.8004 112.467 58.4675 114.8 55.5985 114.8ZM55.5985 106.806C54.0605 106.806 52.8035 108.059 52.8035 109.6C52.8035 111.141 54.0568 112.397 55.5985 112.397C57.1365 112.397 58.3936 111.145 58.3936 109.6C58.3899 108.059 57.1402 106.806 55.5985 106.806Z"
            fill="#A2CCFF"
          />
          <Path
            className={C.RING.RIGHT}
            d="M182.001 48.4001C179.134 48.4001 176.801 46.0667 176.801 43.2001C176.801 40.3336 179.134 38.0001 182.001 38.0001C184.867 38.0001 187.201 40.3336 187.201 43.2001C187.201 46.0667 184.867 48.4001 182.001 48.4001ZM182.001 40.4066C180.462 40.4066 179.205 41.6591 179.205 43.2038C179.205 44.7411 180.455 46.001 182.001 46.001C183.539 46.001 184.797 44.7484 184.797 43.2038C184.797 41.6628 183.539 40.4066 182.001 40.4066Z"
            fill="#A2CCFF"
          />
          <PathEvenOdd
            className={C.PLUS}
            d="M151.91 43.2089C151.91 43.8677 152.485 44.3999 153.2 44.3999C153.914 44.3999 154.49 43.8677 154.49 43.2089V40.7908H157.11C157.824 40.7908 158.4 40.2587 158.4 39.5999C158.4 38.941 157.824 38.4089 157.11 38.4089H154.49V35.9908C154.49 35.332 153.914 34.7999 153.2 34.7999C152.485 34.7999 151.91 35.332 151.91 35.9908V38.4089H149.29C148.576 38.4089 148 38.941 148 39.5999C148 40.2587 148.576 40.7908 149.29 40.7908H151.91V43.2089Z"
            fill="#A2CCFF"
          />
          <Path
            className={C.DASH.LEFT}
            d="M117.96 40.1232L116.052 41.0549L111.202 33.8856L115.338 31.8689L117.96 40.1232Z"
            fill="#A2CCFF"
          />
          <Path
            className={C.DASH.CENTER}
            d="M125.776 35.0953L123.661 35.0459L122.721 26.4184L127.309 26.5259L125.776 35.0953Z"
            fill="#A2CCFF"
          />
          <Path
            className={C.DASH.RIGHT}
            d="M132.634 41.3816L130.789 40.3631L133.98 32.243L137.97 34.4494L132.634 41.3816Z"
            fill="#A2CCFF"
          />
        </Group>
        <Group className={C.CUP.LEFT}>
          <PathEvenOdd
            d="M128.351 65.811C129.954 61.7251 119.898 53.9569 105.889 48.4602C91.8799 42.9635 79.2239 41.8199 77.6207 45.9058C77.6192 45.9097 77.6176 45.9135 77.6161 45.9174L59.1648 92.9427C59.1608 92.9525 59.1569 92.9625 59.153 92.9724C56.9696 98.537 66.556 107.504 80.5648 113.001C94.5737 118.497 107.7 118.442 109.883 112.878C109.883 112.878 109.883 112.878 109.883 112.878L109.884 112.878L128.351 65.811Z"
            fill="#FFE8E2"
            fill-opacity="0.87"
          />
          <PathEvenOdd
            d="M114.251 95.5276L109.2 108.402L109.191 108.398C109.179 108.446 109.164 108.493 109.146 108.539C108.101 111.202 96.8759 109.288 84.0734 104.265C71.2708 99.2414 61.7393 93.0104 62.7842 90.3475C62.8022 90.3018 62.8232 90.2574 62.8471 90.2143L62.8379 90.2107L67.8894 77.3365C68.9342 74.6735 80.1597 76.587 92.9623 81.6104C105.765 86.6338 115.296 92.8647 114.251 95.5276Z"
            fill="#FFD6C7"
          />
          <PathEvenOdd
            d="M115.998 91.0827C118.197 83.1434 111.708 83.9475 102.569 85.0797C102.126 85.1347 101.675 85.1904 101.22 85.246C93.436 86.1954 68.711 80.2054 67.5931 78.0968L63.2803 89.0885L63.2894 89.0921C63.2502 89.1741 63.2139 89.2576 63.1805 89.3426C61.2403 94.2875 70.0459 102.368 82.8485 107.392C95.6511 112.415 107.602 112.479 109.543 107.534C109.576 107.449 109.606 107.363 109.633 107.276L109.642 107.28L115.998 91.0827Z"
            fill="#FFA387"
          />
          <Path
            d="M107.276 107.197C105.738 111.117 95.0118 110.575 83.3183 105.986C71.6248 101.398 63.3922 94.5013 64.9301 90.5816C66.4681 86.662 77.1943 87.204 88.8878 91.7922C100.581 96.3804 108.814 103.277 107.276 107.197Z"
            fill="#F46A46"
          />
          <GroupBlendMultiply>
            <PathEvenOdd
              d="M115.189 67.6493C122.274 68.8859 127.436 68.3503 128.421 65.8388L109.953 112.905C108.611 116.326 103.135 117.665 95.8601 116.912L115.189 67.6493Z"
              fill="#FFE8E2"
              fill-opacity="0.87"
            />
          </GroupBlendMultiply>
          <GroupBlendMultiply>
            <Path
              d="M74.8253 110.43L94.3147 60.7596C83.4658 55.6055 76.3214 49.4251 77.6914 45.9335L59.2238 93.0002C57.358 97.7553 64.0867 104.995 74.8253 110.43Z"
              fill="#FFE8E2"
              fill-opacity="0.2"
            />
          </GroupBlendMultiply>
          <GroupBlendMultiply>
            <PathEvenOdd
              d="M77.6831 45.9517C76.3451 49.4464 83.4835 55.6143 94.3136 60.7595L99.9604 46.368C88.5211 42.7753 79.0931 42.443 77.6974 45.9151L77.6831 45.9517Z"
              fill="#FFD3C8"
            />
          </GroupBlendMultiply>
          <PathEvenOdd
            d="M124.852 66.3036C126.302 65.9644 126.716 65.4665 126.817 65.2089C126.918 64.9513 126.953 64.3053 126.121 63.0706C125.323 61.8872 123.931 60.4861 121.967 58.9691C118.057 55.9489 112.188 52.7025 105.287 49.9946C98.3854 47.2868 91.8746 45.6758 86.9542 45.2311C84.4826 45.0078 82.509 45.0881 81.1193 45.4132C79.6692 45.7524 79.2561 46.2503 79.155 46.5078C79.054 46.7654 79.0183 47.4114 79.8507 48.6462C80.6486 49.8296 82.041 51.2306 84.0049 52.7476C87.9148 55.7678 93.7838 59.0143 100.685 61.7221C107.586 64.43 114.097 66.041 119.018 66.4856C121.489 66.7089 123.463 66.6286 124.852 66.3036ZM100.083 63.2566C114.092 68.7532 126.748 69.8969 128.351 65.811C129.954 61.7251 119.898 53.9568 105.889 48.4602C91.8798 42.9635 79.2238 41.8199 77.6206 45.9058C76.0174 49.9917 86.0742 57.7599 100.083 63.2566Z"
            fill="white"
          />
        </Group>
        <Group className={C.CUP.RIGHT}>
          <PathEvenOdd
            d="M118.64 70.4522C116.979 66.2205 127.395 58.1751 141.903 52.4823C156.412 46.7895 169.52 45.6051 171.18 49.8368C171.182 49.8408 171.183 49.8448 171.185 49.8488L190.295 98.5519C190.299 98.5622 190.303 98.5724 190.307 98.5827C192.568 104.346 182.64 113.633 168.131 119.326C153.622 125.018 140.028 124.961 137.766 119.198C137.766 119.198 137.766 119.198 137.766 119.198L137.766 119.198L118.64 70.4522Z"
            fill="#FFE8E2"
            fill-opacity="0.87"
          />
          <PathEvenOdd
            d="M133.242 101.229L138.474 114.563L138.483 114.559C138.495 114.608 138.511 114.657 138.529 114.704C139.611 117.462 151.237 115.481 164.497 110.278C177.756 105.075 187.628 98.6221 186.546 95.8642C186.527 95.8168 186.505 95.7708 186.48 95.7261L186.49 95.7224L181.258 82.3889C180.176 79.6309 168.55 81.6127 155.291 86.8153C142.031 92.0179 132.16 98.4712 133.242 101.229Z"
            fill="#FFD6C7"
          />
          <PathEvenOdd
            d="M131.434 96.6254C129.156 88.4029 135.877 89.2356 145.342 90.4082C145.801 90.4652 146.267 90.5229 146.739 90.5805C154.801 91.5638 180.408 85.3601 181.566 83.1762L186.033 94.5601L186.023 94.5638C186.064 94.6488 186.101 94.7353 186.136 94.8233C188.145 99.9446 179.026 108.314 165.766 113.516C152.507 118.719 140.129 118.785 138.12 113.664C138.085 113.575 138.054 113.486 138.026 113.397L138.016 113.4L131.434 96.6254Z"
            fill="#FFA387"
          />
          <Path
            d="M140.467 113.315C142.06 117.375 153.169 116.814 165.279 112.062C177.39 107.31 185.916 100.167 184.324 96.1073C182.731 92.0478 171.622 92.6091 159.511 97.361C147.4 102.113 138.874 109.256 140.467 113.315Z"
            fill="#F46A46"
          />
          <GroupBlendMultiply>
            <PathEvenOdd
              d="M132.271 72.3558C124.933 73.6366 119.587 73.0818 118.567 70.4807L137.693 119.227C139.083 122.769 144.755 124.156 152.29 123.376L132.271 72.3558Z"
              fill="#FFE8E2"
              fill-opacity="0.87"
            />
          </GroupBlendMultiply>
          <GroupBlendMultiply>
            <Path
              d="M174.076 116.663L153.891 65.2202C165.127 59.8822 172.526 53.4813 171.107 49.8652L190.234 98.6111C192.166 103.536 185.198 111.034 174.076 116.663Z"
              fill="#FFE8E2"
              fill-opacity="0.2"
            />
          </GroupBlendMultiply>
          <GroupBlendMultiply>
            <PathEvenOdd
              d="M171.115 49.8842C172.501 53.5037 165.108 59.8916 153.891 65.2204L148.043 50.3154C159.89 46.5945 169.655 46.2504 171.1 49.8464L171.115 49.8842Z"
              fill="#FFD3C8"
            />
          </GroupBlendMultiply>
          <PathEvenOdd
            d="M122.263 70.9623C120.761 70.611 120.333 70.0954 120.229 69.8286C120.124 69.5619 120.087 68.8928 120.949 67.614C121.776 66.3884 123.218 64.9373 125.252 63.3662C129.301 60.2383 135.38 56.8759 142.527 54.0715C149.675 51.267 156.418 49.5985 161.514 49.138C164.073 48.9067 166.117 48.9899 167.557 49.3266C169.059 49.6779 169.486 50.1935 169.591 50.4603C169.696 50.727 169.733 51.3961 168.871 52.6749C168.044 53.9005 166.602 55.3516 164.568 56.9227C160.519 60.0506 154.44 63.413 147.293 66.2174C140.145 69.0219 133.402 70.6904 128.306 71.1509C125.747 71.3822 123.702 71.299 122.263 70.9623ZM147.916 67.8066C133.408 73.4994 120.3 74.6839 118.64 70.4522C116.979 66.2205 127.395 58.1751 141.904 52.4823C156.412 46.7895 169.52 45.605 171.18 49.8367C172.841 54.0684 162.425 62.1138 147.916 67.8066Z"
            fill="white"
          />
        </Group>
      </Group>
    </Base>
  );
};

export default Image;
