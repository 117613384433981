import Start from './Start';
import Variant1 from './Variant1';
import Variant2 from './Variant2';
import Variant3 from './Variant3';
import Variant4 from './Variant4';
import Variant5 from './Variant5';
import Variant6 from './Variant6';
import SecondToLast from './SecondToLast';
import End from './End';

export const variantBackgroundList = [
  Variant1,
  Variant2,
  Variant3,
  Variant4,
  Variant5,
  Variant6,
];

export default {
  variantBackgroundList,
  Start,
  Variant1,
  Variant2,
  Variant3,
  Variant4,
  Variant5,
  Variant6,
  SecondToLast,
  End,
};
