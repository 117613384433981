import React from 'react';
import PropTypes from 'prop-types';
import ProfileMenu from './ProfileMenu';
import HeaderSlideOutMenu from './HeaderSlideOutMenu';
import HeaderSpacesMenu from './HeaderSpacesMenu';
import Notifications from './Notifications';
import Tabs, { renderTabs } from './Tabs';
import { PROFILE_LINKS_PROP_TYPE, TABS_PROP_TYPE } from './propTypes';
import Workspaces, {
  WORKSPACE_NAV_PROPS,
  WORKSPACE_NAV_DEFAULT_PROPS,
} from './Workspaces';
import { useIsPageScrolled } from './hooks';
import icons from './icons';
import {
  BOTTOM_NAV_BAR_SPACING,
  Container,
  HeaderBar,
  HeaderBarContainer,
  CreateAWorkspaceButton,
  CreateAWorkspaceContentContainer
} from './styles';
import {
  CreateWorkspaceLabel,
  CreateIcon,
} from './HeaderSpacesMenu/styles';

const renderLinks = ({ activeProfileTab, profileLinks, workspacesProps }) =>
  renderTabs({
    activeTab: activeProfileTab,
    isCompact: true,
    tabs: profileLinks,
    workspacesProps,
  });

const Main = (props) => {
  const {
    activeProfileTab,
    isProfileMenuOpen,
    isMobileProfileMenuOpen,
    mobileMenu,
    onCloseMobileProfileMenu,
    onCloseProfileMenu,
    onOpenMobileProfileMenu,
    onOpenProfileMenu,
    profileLinks,
    profileLinksMobile,
    workspacesProps,
    showZeroWorkspaces,
    user,
    noSpacing
  } = props;
  const { activeWorkspace } = workspacesProps || {};
  const toggleMenu = (e) => {
    isProfileMenuOpen ? onCloseProfileMenu(e) : onOpenProfileMenu(e);
    isMobileProfileMenuOpen
      ? onCloseMobileProfileMenu(e)
      : onOpenMobileProfileMenu(e);
  };
  const isZeroWorkspace = showZeroWorkspaces || workspacesProps.workspaces?.length < 1;

  return (
    <>
      <Container noSpacing={noSpacing} {...useIsPageScrolled()}>
        <HeaderBar onClick={onCloseProfileMenu} showZeroWorkspaces={isZeroWorkspace} >
          {isZeroWorkspace ? 
            <CreateAWorkspaceContentContainer>
              <CreateAWorkspaceButton to={workspacesProps.addLink}>
                <CreateWorkspaceLabel>
                  Create A Channel
                  <CreateIcon />
                </CreateWorkspaceLabel>
              </CreateAWorkspaceButton>
            </CreateAWorkspaceContentContainer> : 
            <HeaderSpacesMenu {...props} />}
          <HeaderBarContainer>
            {!isZeroWorkspace && !activeWorkspace?.isExpired && <Notifications {...props} />}
          </HeaderBarContainer>
        </HeaderBar>
        <Tabs {...props} {...workspacesProps} />
        <ProfileMenu
          activeProfileTab={activeProfileTab}
          isOpen={isProfileMenuOpen}
          onClick={toggleMenu}
          user={user}
        >
          {renderLinks({ ...props, onClick: toggleMenu })}
        </ProfileMenu>
      </Container>
      <HeaderSlideOutMenu
        isOpen={isMobileProfileMenuOpen}
        profileLinks={profileLinksMobile || profileLinks}
        onClickClose={onCloseMobileProfileMenu}
        workspaces={
          <Workspaces.ProfileMenuContents
            {...workspacesProps}
            onClick={onCloseMobileProfileMenu}
          />
        }
      >
        {mobileMenu}
      </HeaderSlideOutMenu>
    </>
  );
};

Main.icons = icons;

Main.defaultProps = {
  activeProfileTab: 0,
  numOfSharedTabsToShow: 2,
  sharedTabs: [],
  profileLinks: [],
  workspacesProps: WORKSPACE_NAV_DEFAULT_PROPS,
};

Main.propTypes = {
  /** The index of the current active tab. */
  activeTab: PropTypes.number,
  /** The index of the current active profile tab./ */
  activeProfileTab: PropTypes.number,
  /** If user has every had any notifications. */
  hasBeenNotified: PropTypes.bool,
  /** If notification page is active. */
  isNotificationsActive: PropTypes.bool,
  /** If profile menu is open. */
  isProfileMenuOpen: PropTypes.bool,
  /** Optional override to control opening of mobile profile menu. */
  isMobileProfileMenuOpen: PropTypes.bool,
  /** Number of shared tabs to display before overflowing rest to more tab. */
  numOfSharedTabsToShow: PropTypes.number,
  /** Callback when mobile profile menu closes. */
  onCloseMobileProfileMenu: PropTypes.func,
  /** Callback when mobile profile menu opens. */
  onOpenMobileProfileMenu: PropTypes.func,
  /** Callback when profile menu closes. */
  onCloseProfileMenu: PropTypes.func,
  /** Callback when profile menu opens. */
  onOpenProfileMenu: PropTypes.func,
  /** List of profile links to display in the profile menu. */
  profileLinks: PROFILE_LINKS_PROP_TYPE,
  /** List of profile links to display in the profile menu on mobile. */
  profileLinksMobile: PROFILE_LINKS_PROP_TYPE,
  /** List of tabs to display in the main navigation. */
  tabs: TABS_PROP_TYPE.isRequired,
  /** List of shared tabs to display in the main navigation across my stuff and workspaces. */
  sharedTabs: TABS_PROP_TYPE,
  /** THe number of unread notifications for the user. */
  unreadNotificationsCount: PropTypes.number,
  /** The current user information. */
  user: PropTypes.shape({
    email: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  workspacesProps: PropTypes.shape(WORKSPACE_NAV_PROPS),
};

Main.BottomNavBarSpacing = BOTTOM_NAV_BAR_SPACING;

export default Main;
