import React from 'react';
import PropTypes from 'prop-types';
import { FullScreenOverlay } from '../../FullScreenOverlay';
import CardContents from './CardContents';
import Page from '../Page';
import theme from '@matterapp/matter-theme';
import { CardWrapper, CardCurrent, CardPrev, ProgressBar, ProgressBarContainer } from './styles';

export default class Scrolling extends React.PureComponent {
  static propTypes = {
    /** For desktop and tablet views. Card width takes minimum space needed. */
    autoFitCardWidth: PropTypes.bool,
    /** Override card width in pixels. */
    cardWidth: PropTypes.number,
    /** Additional style class. */
    className: PropTypes.string,
    /** Alternative current step value. */
    currentStepValue: PropTypes.number,
    /** The current step of the flow. */
    currentStep: PropTypes.number,
    /** Hide the current flow card. */
    hideCard: PropTypes.bool,
    /** Similar to hide card, but moves current card to the side and displays loadingMessage if passed in. */
    isLoading: PropTypes.bool,
    /** Loading message to display if loading. */
    loadingMessage: PropTypes.node,
    /**
     * Render the current step contents of the flow
     *
     * @param { Object } props: All of the props passed to the flow.
     * @returns { React.Component } The rendered step contents.
     */
    renderContents: PropTypes.func,
    /**
     * Render the current header of the flow
     *
     * @param { Object } props: All of the props passed to the flow.
     * @returns { React.Component } The rendered header.
     */
    renderHeader: PropTypes.func,
    /** Display top progress bar. */
    showProgressBar: PropTypes.bool,
    /** Total number of steps in the flow. */
    totalNumSteps: PropTypes.number,
    renderFooter: PropTypes.func,
  };

  static defaultProps = {
    currentStep: 1,
    hideCard: false,
    renderContents: () => null,
    renderHeader: () => null,
    renderFooter: () => null,
    showProgressBar: true,
  };

  static CardContents = CardContents;
  static CardPrev = CardPrev;
  static CardCurrent = CardCurrent;
  static CardWrapper = CardWrapper;

  constructor(props) {
    super(props);
    this.cardRef = React.createRef();
    this.state = {
      cardWidth: props.cardWidth,
      isNextStep: true,
      isPrevHidden: true,
      prevCardWidth: props.cardWidth,
      prevContent: null,
    };
    this.cardWidthTimeout = null;
  }

  componentDidUpdate(prevProps) {
    const { cardWidth, currentStep } = this.props;
    if (prevProps.currentStep !== currentStep) {
      this.setPrevContent({
        isNextStep: prevProps.currentStep < currentStep,
        prevCardWidth: prevProps.cardWidth,
      });
    }
    if (cardWidth !== this.state.cardWidth) {
      this.setState({ cardWidth });
    }
  }

  componentDidMount = () => {
    this._mounted = true;
  };

  componentWillUnmount = () => {
    this._mounted = false;
    clearTimeout(this.cardWidthTimeout);
  };

  setPrevContent = (state) => {
    this.setState({
      ...state,
      isPrevHidden: false,
      prevContent: this.prevContent,
    });
    setTimeout(this.hidePrevContent, 50);
  };

  hidePrevContent = () => {
    if (this._mounted) {
      this.setState({ isPrevHidden: true });
    }
  };

  renderContents = () => {
    const { hideCard, renderContents } = this.props;
    if (hideCard) {
      return null;
    }
    if (this.currentContent) {
      this.prevContent = this.currentContent;
    }
    this.currentContent = renderContents(this.props);
    return this.currentContent;
  };

  renderHeader = () => {
    const { hideCard, renderHeader } = this.props;
    if (hideCard) {
      return null;
    }
    return renderHeader(this.props);
  };

  renderFooter = () => {
    const { hideCard, renderFooter } = this.props;
    if (hideCard) {
      return null;
    }
    return renderFooter(this.props);
  };

  render() {
    const { cardWidth, isNextStep, isPrevHidden, prevCardWidth, prevContent } = this.state;
    const {
      autoFitCardWidth,
      className,
      currentStepValue,
      currentStep,
      hideCard,
      isLoading,
      loadingMessage,
      showProgressBar,
      totalNumSteps,
      isFeedItem
    } = this.props;

    return (
      <Page className={className}>
        {showProgressBar && (
          <ProgressBarContainer>
            <ProgressBar
              percent={Math.min(
                1,
                (currentStepValue || currentStep) / (totalNumSteps || 1)
              )}
            />
          </ProgressBarContainer>
        )}
        {this.renderHeader()}
        {loadingMessage && (
          <FullScreenOverlay
            color={FullScreenOverlay.colors.TRANSPARENT}
            isOpen={isLoading}
          >
            <FullScreenOverlay.Spinner fill={theme.colors.blacks[90]} />
            <FullScreenOverlay.HeaderAndSubheader
              headerText={loadingMessage}
              light
            />
          </FullScreenOverlay>
        )}
        {!hideCard && (
          <CardCurrent
            autoFitCardWidth={autoFitCardWidth}
            cardWidth={cardWidth}
            dataProps={{ 'data-role': 'card' }}
            isLoading={isLoading}
            isShown={isPrevHidden}
            isNextStep={isNextStep}
            isFeedItem={isFeedItem}
          >
            <CardWrapper ref={this.cardRef}>
              {this.renderContents()}
            </CardWrapper>
          </CardCurrent>
        )}
        {prevContent && (
          <CardPrev
            autoFitCardWidth={autoFitCardWidth}
            cardWidth={prevCardWidth}
            dataProps={{ 'data-role': 'card', 'data-card-prev': true }}
            isPrevHidden={isPrevHidden}
            isNextStep={isNextStep}
            isFeedItem={isFeedItem}
          >
            <CardWrapper ref={this.cardRef}>{prevContent}</CardWrapper>
          </CardPrev>
        )}
        {this.renderFooter()}
      </Page>
    );
  }
}
