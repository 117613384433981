import isEqual from 'lodash/isEqual';
import { ENV_VARS } from 'app-consts';

export const clearIntercom = () => {
  window.Intercom('shutdown');
  window.intercomSettings = null;
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('intercom-')) {
      localStorage.removeItem(key);
    }
  });
};

export const bootIntercom = () => {
  clearIntercom();
  window.Intercom('boot', {
    app_id: ENV_VARS.INTERCOM_APP_ID,
  });
};

export const updateIntercom = (currentUserStatus, opts = {}) => {
  let intercomSettings = {};
  if (currentUserStatus) {
    const { fullName, email } = currentUserStatus;
    if (fullName) {
      intercomSettings.name = fullName;
    }
    if (email) {
      intercomSettings.email = email;
    }
  }
  // deal with custom attributes

  intercomSettings.hide_default_launcher = !opts.showIntercomMessenger;
  delete opts.showIntercomMessenger;
  
  if (Object.keys(opts).length) {
    intercomSettings = Object.assign(intercomSettings, opts);
  }

  const oldIntercomSettings = JSON.parse(localStorage.getItem('intercom-settings'));

  if (!isEqual(intercomSettings, oldIntercomSettings)) {
    localStorage.setItem('intercom-settings', JSON.stringify(intercomSettings));
    
    // Add user_id and user_hash after so that we're not persisting to localStorage.
    if (currentUserStatus) {
      const { userHash, id } = currentUserStatus;
      if (userHash) {
        intercomSettings.user_hash = userHash;
      }
      if (id) {
        intercomSettings.user_id = id;
      }
    }
    window.Intercom('update', intercomSettings);
  }
};
