import React, { useState, useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import WorkspaceSettingsContext from './WorkspaceSettingsContext';
import { useQueryParams } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';

export default function WorkspaceSettingsProvider({ children }) {
  const { queryParams } = useQueryParams();
  const [savedParams, setSavedParams] = useState({});
  const { isLoadingCurrentUser, currentUser, currentWorkspace } = useContext(UserContext);
  const [hasChanges, setHasChanges] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const settingsRedirect = queryParams?.settings === 'show' || queryParams?.section;

    if (settingsRedirect) {
      const SECTIONS_TO_REDIRECT = {
        main: Resources.workspaceSettingsMain.path({ workspaceId: currentWorkspace?.id }),
        'feedback-ritual': Resources.workspaceSettingsFeedbackFriday.path({ workspaceId: currentWorkspace?.id }),
        celebrations: Resources.workspaceSettingsCelebrations.path({ workspaceId: currentWorkspace?.id }),
        billing: Resources.workspaceSettingsBilling.path({ workspaceId: currentWorkspace?.id }),
        pulse: Resources.workspaceSettingsPulse.path({ workspaceId: currentWorkspace?.id }),
        kudos: Resources.workspaceSettingsKudos.path({ workspaceId: currentWorkspace?.id }),
        rewards: Resources.workspaceSettingsRewards.path({ workspaceId: currentWorkspace?.id }),
      };
      
      if (currentWorkspace && SECTIONS_TO_REDIRECT[queryParams?.section]) {
        navigate(SECTIONS_TO_REDIRECT[queryParams?.section || 'main']);
      } else if (currentWorkspace && queryParams?.section === 'delete') {
        navigate(Resources.workspaceSettingsDelete.path({ workspaceId: currentWorkspace?.id }, { token: queryParams?.token }));
      }
    }
  }, [isLoadingCurrentUser, currentUser, currentWorkspace]);

  return (
    <WorkspaceSettingsContext.Provider
      value={{
        hasChanges,
        setHasChanges,
        setSavedParams,
        savedParams
      }}
    >
      {children}
    </WorkspaceSettingsContext.Provider>
  );
}
