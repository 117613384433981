import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CharacterCount from './CharacterCount';
import {
  getMediumScreenInputStyles,
  getCharCountStyles,
  VARIANTS,
} from '../styles';
import TextArea from '../TextArea';

const CharacterLengthBox = styled.div`
  ${(props) => getCharCountStyles(props)};
  ${getMediumScreenInputStyles}
`;

const WithCount = React.forwardRef((props, ref) => {
  const {
    autoHeight,
    error,
    hideCharCountDenominator,
    hideMaxCharacterCount,
    hideMinCharacterCount,
    maxLength,
    maxErrorMessage,
    minLength,
    minErrorMessage,
    value,
    variant,
    showErrorOnEmpty,
    showCharacterCountOnIncorrectLength,
    showRemainingCharacterCount,
  } = props;
  const curLength = !value ? 0 : value.length;

  const maxHasError = showCharacterCountOnIncorrectLength
    ? curLength > maxLength
    : false;
  const minHasError = showCharacterCountOnIncorrectLength
    ? curLength < minLength
    : false;

  const renderInputContent = ({ hasError }) => (
    <CharacterLengthBox
      autoHeight={autoHeight}
      isError={hasError}
      variant={variant}
    >
      <CharacterCount
        count={curLength}
        hideCharCountDenominator={hideCharCountDenominator}
        hideMax={
          showCharacterCountOnIncorrectLength
            ? !maxHasError
            : hideMaxCharacterCount
        }
        hideMin={
          showCharacterCountOnIncorrectLength
            ? !minHasError
            : hideMinCharacterCount
        }
        max={maxLength}
        maxErrorMessage={maxErrorMessage}
        min={minLength}
        minErrorMessage={minErrorMessage}
        showRemainingCharacterCount={showRemainingCharacterCount}
        variant={variant}
        errorOnZero={showErrorOnEmpty}
      />
    </CharacterLengthBox>
  );
  return (
    <TextArea
      ref={ref}
      {...props}
      error={error || minHasError || maxHasError}
      renderInputContent={renderInputContent}
    />
  );
});

WithCount.defaultProps = {
  error: null,
  onBlur: () => null,
  onChange: () => null,
  placeholder: null,
  renderInputContent: () => null,
  resize: 'none',
  rows: 2,
  variant: VARIANTS.DEFAULT,
};

WithCount.propTypes = {
  autoHeight: PropTypes.bool,
  /** Force to allow error styling without explicit initial blur **/
  allowErrorBeforeBlur: PropTypes.bool,
  /** Additional class. */
  className: PropTypes.string,
  /** If the TextArea has an error. PropTypes.string is DEPRICATED. Use boolean. */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Hide the denominator char count. */
  hideCharCountDenominator: PropTypes.bool,
  /** Hides the max charater counter. */
  hideMaxCharacterCount: PropTypes.bool,
  /** Hide the min character counter. */
  hideMinCharacterCount: PropTypes.bool,
  /** Display as a single-lined input. */
  isInput: PropTypes.bool,
  /** Label of the TextArea. */
  label: PropTypes.node,
  /** DEPRECATED: Removes padding on label style. Pass in a styled label to `label` to override styling. */
  labelFlushedLeft: PropTypes.bool,
  /** Override error message if value is too long. */
  maxErrorMessage: PropTypes.string,
  /** The max length of the TextArea. */
  maxLength: PropTypes.number,
  /** Override error message if value is too short. */
  minErrorMessage: PropTypes.string,
  /** The min length of the TextArea. */
  minLength: PropTypes.number,
  /** The name of the TextArea. */
  name: PropTypes.string,
  /** Callback when TextArea is blurred. */
  onBlur: PropTypes.func,
  /** Callback when TextArea value is changed. */
  onChange: PropTypes.func.isRequired,
  /** Placehold of the TextArea. */
  placeholder: PropTypes.string,
  /** Resize controls of the TextArea. */
  resize: PropTypes.oneOf(['none', 'both', 'horizontal', 'vertical']),
  /** Number of rows in the TextArea. */
  rows: PropTypes.number,
  /** Displays the charadcter count only if message is incorrect length. */
  showCharacterCountOnIncorrectLength: PropTypes.bool,
  /** Displays the reminaing number of characters. If a number is passed, it will display when that number is reached. */
  showRemainingCharacterCount: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  /** The tab index of the textarea. */
  tabIndex: PropTypes.string,
  /** The value of the TextArea. */
  value: PropTypes.string,
  /** The style variant of the text area. */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

export default WithCount;
