export const REPLACE_RATING_TEXT = '%r';
export const REPLACE_SKILL_NAME = '%s';
export const REPLACE_ABILITIES_COUNT = '%a';

export const SKILL_LEVEL_TEXT = `I think you’re a skill Level ${REPLACE_RATING_TEXT} at ${REPLACE_SKILL_NAME}.`;
export const SELECTED_ABILITIES_TEXT = `I suggested ${REPLACE_ABILITIES_COUNT} that might improve your skill.`;

export const SINGLE_ABILITY_TEXT = 'something';
export const PLURAL_ABILITY_TEXT = 'a few things';

/**
 * Gets all the details needed from a skill to generate a default note.
 * @param { Object } skill: The skill to get details from.
 * @returns { Object } The formatted object of the details needed to generate a default note.
 */
export const getSkillDetailsForDefaultNote = (skill) => {
  const skillToRate = skill || {};
  const actionItems = skillToRate.actionItemSelections || [];
  const currentAbilities = actionItems.filter((ability) => ability.selected);
  const currentRating = skillToRate.currentRating || 1;
  const { name } = skillToRate.skill || {};
  return {
    currentAbilities,
    currentRating: currentRating / 2,
    skillName: name,
  };
};

/**
 * Generates a note based on the skill level.
 * @param { Object } noteProps: {
 *   @param { Number } currentRating: The current rating of the skill.
 *   @param { String } skillName: The name of the skill.
 * }
 * @returns { String } The generated skill level note.
 */
export const getGeneratedSkillLevelNote = (noteProps = {}) => {
  const { currentRating, skillName } = noteProps;
  return SKILL_LEVEL_TEXT.replace(REPLACE_RATING_TEXT, currentRating).replace(
    REPLACE_SKILL_NAME,
    skillName
  );
};

/**
 * Generates a note based on number of abilities selected.
 * @param { Array } currentAbilities: List of selected abilities of the skill.
 * @returns { String } The generated skill abilities note.
 */
export const getGeneratedAbilitiesNote = (currentAbilities = []) => {
  const numberOfAbilities = currentAbilities.length;
  const abilitiesCountText =
    numberOfAbilities === 1 ? SINGLE_ABILITY_TEXT : PLURAL_ABILITY_TEXT;
  if (!numberOfAbilities) {
    return '';
  }
  return SELECTED_ABILITIES_TEXT.replace(
    REPLACE_ABILITIES_COUNT,
    abilitiesCountText
  );
};

/**
 * Generates a default note for a skill based on the skill rating and number of abilities selected.
 * @param { Object } skill: The skill to generate a default note for.
 * @returns { String } The generated default note for the skill.
 */
export const getGeneratedDefaultNote = (skillToRate = {}) => {
  const {
    currentAbilities,
    currentRating,
    skillName,
  } = getSkillDetailsForDefaultNote(skillToRate);
  const skillText = getGeneratedSkillLevelNote({ currentRating, skillName });
  const abilitiesText = getGeneratedAbilitiesNote(currentAbilities);
  return `${skillText}${abilitiesText ? ' ' : ''}${abilitiesText}`;
};
