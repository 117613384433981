import React from 'react';
import PropTypes from 'prop-types';
import { Container, GiveTab, KudosTab, RequestTab } from './styles';
import Tab from './Tab';
import { useTabs } from './hooks';

const Tabs = (props) => {
  const { showTabs, tabProps } = useTabs(props);
  const { request, give, kudos } = showTabs;
  return (
    <Container>
      {request && <RequestTab {...tabProps} />}
      {give && <GiveTab {...tabProps} />}
      {kudos && <KudosTab {...tabProps} />}
    </Container>
  );
};

Tabs.Tab = Tab;

Tabs.defaultProps = {
  onChange: () => {},
  showTabs: {
    request: true,
    give: true,
    kudos: true,
  }
};

Tabs.propTypes = {
  onChange: PropTypes.func,
  showTabs: PropTypes.shape({
    request: PropTypes.bool,
    give: PropTypes.bool,
    kudos: PropTypes.bool,
  }),
};

export default Tabs;
