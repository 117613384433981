import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextAreaWithCount } from '../../TextArea';
import theme from '@matterapp/matter-theme';

const MESSAGE_TOO_SHORT = 'Too Short';
const MESSAGE_TOO_LONG = 'Too Long';

const TextAreaContainer = styled.div`
  line-height: 1;

  &:not(:last-child) {
    margin-bottom: calc(${() => theme.spacing.double} - 2px);
  }
`;

const TextArea = styled(TextAreaWithCount)`
  font-weight: normal;
  min-height: 142px;
  ${() => theme.media.medium`
    resize: none;
    max-height: 142px;
  `}
  ${() => theme.media.large`
    resize: vertical;
    max-height: calc(100vh - 500px);
  `}
`;

const Footer = styled.div`
  color: ${() => theme.colors.blacks[60]};
  font-size: ${() => theme.sizes.fontSizes[50]};
  text-align: center;
`;

export default class EditMessage extends React.PureComponent {
  static propTypes = {
    /** Additional style class. */
    className: PropTypes.string,
    /** The footer content of the edit message pane. */
    footer: PropTypes.node,
    /** Label of the edit message textarea. */
    label: PropTypes.node,
    /** The min length of the textarea. */
    minLength: PropTypes.number,
    /** The max length of the textarea. */
    maxLength: PropTypes.number,
    /** The message value of the textarea. */
    message: PropTypes.string,
    /** Callback when message value changes. */
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    /** force error to show before explicit blur **/
    allowErrorBeforeBlur: PropTypes.bool,
    /** show an error when the message is empty **/ 
    showErrorOnEmpty: PropTypes.bool,
  };

  static defaultProps = {
    minLength: 25,
    maxLength: 1000,
    message: '',
  };

  isMessageWithinLength = () => {
    const { maxLength, minLength, message } = this.props;
    const length = !message ? 0 : message.length;
    if (length < minLength) {
      return MESSAGE_TOO_SHORT;
    }
    if (length > maxLength) {
      return MESSAGE_TOO_LONG;
    }
    return null;
  };

  /**
   * Callback when value of textarea changes.
   * @param { Object } e: The change event containing the updated value.
   * @returns { void }
   */
  handleChangeMessage = (e) => {
    const { onChange } = this.props;
    onChange(e, {
      ...this.props,
      error: this.isMessageWithinLength(),
      message: e.target.value,
    });
  };

  render() {
    const {
      className,
      footer,
      hideMinCharacterCount,
      hideMaxCharacterCount,
      label,
      maxLength,
      minLength,
      message,
      placeholder,
      showErrorOnEmpty,
      allowErrorBeforeBlur,
    } = this.props;
    const isMessageWithinLength = this.isMessageWithinLength();
    const hasError = showErrorOnEmpty ? isMessageWithinLength : isMessageWithinLength && message.length !== 0;
    return (
      <>
        <TextAreaContainer className={className}>
          <TextArea
            error={hasError}
            allowErrorBeforeBlur={allowErrorBeforeBlur}
            showErrorOnEmpty
            hideMinCharacterCount={hideMinCharacterCount}
            hideMaxCharacterCount={
              hideMaxCharacterCount !== undefined
                ? hideMaxCharacterCount
                : isMessageWithinLength !== MESSAGE_TOO_LONG
            }
            label={label}
            minLength={minLength}
            maxLength={maxLength}
            name="editMessage"
            value={message}
            onChange={this.handleChangeMessage}
            placeholder={placeholder}
          />
        </TextAreaContainer>
        {footer && <Footer>{footer}</Footer>}
      </>
    );
  }
}
