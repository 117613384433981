import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10a2.5 2.5 0 1 1 3.037 2.442A8.321 8.321 0 0 0 6 15.5c-2.517-.03-4.59-2.2-4.956-5.028A2.513 2.513 0 0 1 1 10zm7 0a2.5 2.5 0 1 1 3.037 2.442A8.32 8.32 0 0 0 13 15.5c-2.517-.03-4.59-2.2-4.956-5.028A2.512 2.512 0 0 1 8 10zm15.956 10.472a2.5 2.5 0 1 0-2.992 1.97A8.321 8.321 0 0 1 19 25.5c2.517-.03 4.59-2.2 4.956-5.028zm7 0a2.5 2.5 0 1 0-2.992 1.97A8.321 8.321 0 0 1 26 25.5c2.517-.03 4.59-2.2 4.956-5.028z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
