import React from 'react';
import { skillValueObjectPropType } from '@matterapp/skills';
import PropTypes from 'prop-types';
import omitStyled from '../../../libs/omitStyled';
import Text from '../../Typography';
import { Checkmark } from '../../Checkbox';
import Badge from '../Badge';

const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints;

const editableNonTouchStyles = (props) => `
  // Prevent iPhone getting stuck on hover style
  &:hover:not(.disabled) {
    background: ${props.theme.colors.blues[10]};
  }
`;

const editableStyles = (props) => `
  cursor: pointer;

  :active:not(.disabled) {
    background: ${props.theme.colors.blues[20]};
  }
`;

const unEditableStyles = () => `
  cursor: initial;
`;

const ListItemFlex = omitStyled('div', ['hideCheckmark'])`
  display: flex;
  align-items: center;
  user-select: none;
  display: inline-flex;
  box-sizing: border-box;
  width: 160px;
  height: 136px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.blues[10]};
  transition: background 0.4s;

  ${(props) => !props.hideCheckmark && editableStyles(props)}
  ${(props) =>
    !props.hideCheckmark && !isTouchDevice() && editableNonTouchStyles(props)}
  ${(props) => props.hideCheckmark && unEditableStyles(props)}

  &.disabled {
    background: #F6F6F6;
    border-color: #F6F6F6;
    color: #BFBFC1;
    img.image {
      opacity: 0.5;
    }
    cursor: initial;
  }

  &.checked {
    border-color: ${(props) => props.theme.colors.blues[20]};
    background-color: ${(props) => props.theme.colors.blues[5]};
  }
`;

const buildListItemClass = (checked, disabled, className) => {
  let listItemClass = 'sr__listItem ';
  listItemClass += checked ? 'checked ' : '';
  listItemClass += disabled ? 'disabled ' : '';
  listItemClass += className;
  return listItemClass;
};

class SkillRecommendationsGridUnit extends React.Component {
  onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { onClick, checked, disabled } = this.props;
    if (disabled) { return null; }
    return onClick(!checked);
  };

  renderTop = () => {
    const { skill } = this.props;
    return (
      <div
        style={{
          margin: '0 auto',
          marginBottom: '8px',
          minWidth: '64px',
          height: '64px',
          width: '64px',
        }}
      >
        <Badge size="mini3" skill={skill} />
      </div>
    );
  };

  renderSkillName = () => {
    const { skill } = this.props;
    return (
      <div
        style={{
          minHeight: '24px',
          maxHeight: '32px',
          lineHeight: '16px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Text textStyle={300} color="blacks[90]" bold textAlign="center">
          {skill.name}
        </Text>
      </div>
    );
  };

  renderCheckmark = () => {
    const {
      checked,
      hideCheckmark,
      hideCheckmarkWithTransition,
      disabled,
      isWithCheckbox,
    } = this.props;
    if (!isWithCheckbox) { return null; }
    return (
      <div
        style={{
          position: 'absolute',
          top: '8px',
          left: '8px',
          width: '28px',
        }}
      >
        <Checkmark
          checked={checked}
          disabled={disabled}
          onClick={this.onClick}
          hidden={hideCheckmark}
          hiddenTransition={hideCheckmarkWithTransition}
        />
      </div>
    );
  };

  render() {
    const { className, checked, disabled, hideCheckmark, rcLabel } = this.props;

    return (
      <ListItemFlex
        className={buildListItemClass(checked, disabled, className)}
        onClick={this.onClick}
        data-rc={rcLabel}
        hideCheckmark={!!hideCheckmark}
      >
        <div
          style={{
            width: '100%',
          }}
        >
          {this.renderTop()}
          {this.renderSkillName()}
          {this.renderCheckmark()}
        </div>
      </ListItemFlex>
    );
  }
}

SkillRecommendationsGridUnit.defaultProps = {
  disabled: false,
  checked: false,
  hideCheckmark: false,
  hideCheckmarkWithTransition: false,
  isWithCheckbox: true,
  onClick: () => null,
};

SkillRecommendationsGridUnit.propTypes = {
  className: PropTypes.string,
  /** The skill to display in the grid unit. */
  skill: skillValueObjectPropType.isRequired, // eslint-disable-line
  /** If the skill is checked. */
  checked: PropTypes.bool,
  /** Hides the checkmark. */
  hideCheckmark: PropTypes.bool,
  /** Transitions when hiding the checkmark. */
  hideCheckmarkWithTransition: PropTypes.bool,
  /** If the skill is disabled. */
  disabled: PropTypes.bool,
  /** Callback when skill is clicked. */
  onClick: PropTypes.func,
  /** If checkbox element should be rendered. Slightly different than `hideCheckmark`. */
  isWithCheckbox: PropTypes.bool,
};

export default SkillRecommendationsGridUnit;
