import React from 'react';
import PropTypes from 'prop-types';
import {
  ColorPalettes,
  Container,
  ContentContainer,
  HeaderText,
  Icon,
  IconContainer,
  IconCustomContainer,
} from './styles';

const Base = (props) => {
  const {
    children,
    className,
    color,
    content,
    header,
    icon,
    iconName,
    showColonAfterHeader,
  } = props;
  return (
    <Container className={className} color={color}>
      {icon && <IconCustomContainer>{icon}</IconCustomContainer>}
      {iconName && !icon && (
        <IconContainer>
          <Icon name={iconName} />
        </IconContainer>
      )}
      <ContentContainer>
        {header && (
          <HeaderText>
            {header}
            {showColonAfterHeader ? ': ' : ''}
          </HeaderText>
        )}
        {children}
        {content}
      </ContentContainer>
    </Container>
  );
};

Base.colors = ColorPalettes;
Base.defaultProps = {};

Base.propTypes = {
  /** Additional style class. */
  className: PropTypes.string,
  /** Content of the message. */
  children: PropTypes.node,
  /** Color of the message. */
  color: PropTypes.oneOf(Object.values(ColorPalettes)),
  /** (Alt) Content of the message. */
  content: PropTypes.node,
  /** Header content of the message. Bold leading text. */
  header: PropTypes.node,
  /** Custom icon to show in the message. (Overrides `iconName` prop) */
  icon: PropTypes.node,
  /** Name of icon to display. */
  iconName: PropTypes.string,
  /** Displays a color after header. */
  showColonAfterHeader: PropTypes.bool,
};

export default Base;
