import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Filter, Loader } from '@matterapp/matter-ui';
import UserContext from 'context/UserContext/UserContext';
import getMembers from 'graphql-queries/queries/workspace/getMembers';
import { useFilter } from './hooks';
import Feed from './Feed';
import { MainColumnContainer, CustomeFooter, CustomeFooterText, EmptyStateContainer } from './styles';

function MainColumn() {
  const { currentUser, currentTenant, isLoadingCurrentWorkspace, currentTenantWorkspaceIds } = useContext(UserContext);
  const { pickedSkillDetails } = currentUser;

  const {
    feedProps,
    onChange,
    onCloseMenu,
    onClickApplyFilter,
    onClickClearFilters,
  } = useFilter({
    workspaceIds: currentTenantWorkspaceIds,
  });

  const {
    feedExists,
    feedItems,
    loading,
    onLoadMore,
    updateResultsOnComment,
    updateResultsOnDelete,
  } = feedProps;

  const showEmptyState = !loading && !feedExists;

  const personIds = new Set();
  
  feedItems?.forEach((item) => personIds.add(item.payload.giver.personId));

  const { data: membersData, loading: loadingMembers } = useQuery(getMembers, {
    variables: {
      tenantId: currentTenant?.id,
    },
    skip: !currentTenant
  });

  let members = membersData?.workspaceMembers?.members || [];
  members = members?.filter((member) => !member.isSelf);

  if (loadingMembers || isLoadingCurrentWorkspace) {
    return <Loader />;
  }

  return (
    <MainColumnContainer>
      <Filter.Feedback
        members={members}
        onChange={onChange}
        onClickApplyFilter={onClickApplyFilter}
        onClickClearFilters={onClickClearFilters}
        onCloseMenu={onCloseMenu}
        skills={pickedSkillDetails}
      />
      <Feed
        showEmptyState={showEmptyState}
        currentUser={currentUser}
        feedExists={feedExists}
        feedItems={feedItems}
        loading={loading}
        onLoadMore={onLoadMore}
        updateResultsOnComment={updateResultsOnComment}
        updateResultsOnDelete={updateResultsOnDelete}
        emptyStateContent={
          <CustomeFooter>
            <CustomeFooterText>WE&apos;LL NOTIFY YOU WHEN YOU HAVE FEEDBACK</CustomeFooterText>
          </CustomeFooter>
        }
        noFeedbackContent={
          <EmptyStateContainer>
            <CustomeFooterText>NO FEEDBACK RESULTS</CustomeFooterText>
          </EmptyStateContainer>
        }
      />
    </MainColumnContainer>
  );
};

export default MainColumn;
