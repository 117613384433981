export const DATA_ROLE = 'dropdown';

export const colors = {
  DEFAULT: 'default',
  ALT: 'defaultAlt',
  GRAY: 'gray',
  GRAY_ALT: 'grayAlt',
};

export const colorsList = Object.values(colors);

export const sizes = {
  XS: 'XS',
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
};

export const sizesList = Object.values(sizes);

export const valueContains = (value, search) => {
  if (value && typeof value === 'string') {
    const formattedValue = value.toLowerCase();
    const formattedSearch = search.toLowerCase();
    return formattedValue.includes(formattedSearch);
  }
  return false;
};

/** Hook to manually pass in 'open' prop to drodown. */
export const getOpenProps = (props) => {
  const { isOpen } = props;
  return typeof isOpen === 'boolean' ? { open: isOpen } : {};
};