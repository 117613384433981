import React from 'react';
import BackgroundAnimation, { ANIMATIONS } from '../../BackgroundAnimation';
import ColoredBackground from '../../ColoredBackground';
import gsap from 'gsap';
import * as S from './styles';

export default class Variant2Background extends BackgroundAnimation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  /**
   * Moves the clouds and planet in.
   * @returns { Object } The animation timeline.
   */
  moveCloudsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, opacity: 1, duration: 1 };
    tl.to(this.topCloudRef, { x: 150 });
    tl.to(this.bottomCloudRef, { x: -130 });
    tl.to(this.planetRef, { x: 140 });

    tl.to(this.topCloudRef, resetProps, '<');
    tl.to(this.bottomCloudRef, resetProps, '<');
    tl.to(this.planetRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the clouds and planet out.
   * @returns { Object } The animation timeline.
   */
  moveCloudsOut = () => {
    const tl = gsap.timeline({ defaults: { duration: 0.3 } });
    const resetProps = { x: 0, y: 0, duration: 0 };
    tl.to(this.topCloudRef, resetProps);
    tl.to(this.bottomCloudRef, resetProps);
    tl.to(this.planetRef, { ...resetProps, opacity: 0 });

    tl.to(this.topCloudRef, { x: -80 }, '<');
    tl.to(this.bottomCloudRef, { x: 90 }, '<');
    tl.to(this.planetRef, { x: 80 }, '<');
    return tl;
  };

  /**
   * Moves the rings in.
   * @returns { Object } The animation timeline.
   */
  moveRingsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 2, opacity: 0, transformOrigin: 'center' };
    const endProps = { scale: 1, opacity: 1, duration: 0.5 };
    tl.to(this.rightRing1Ref, initialProps);
    tl.to(this.rightRing2Ref, initialProps);
    tl.to(this.leftRingRef, initialProps);

    tl.to(this.rightRing1Ref, endProps, '<');
    tl.to(this.rightRing2Ref, endProps, '<0.3');
    tl.to(this.leftRingRef, endProps, '<-0.2');
    return tl;
  };

  /**
   * Moves the dots in.
   * @returns { Object } The animation timeline.
   */
  moveDotsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 0.7 };
    const dotRefs = {
      rightDot1Ref: { x: -70, y: 21 },
      rightDot2Ref: { x: -40, y: -46 },
      rightDot3Ref: { x: -62, y: 33 },
      rightDot4Ref: { x: 24, y: 52 },
      rightSquareRef: { x: -12, y: 74 },
      rightLineRef: { x: -27, y: 24 },
      centerDotRef: { x: 27, y: 57 },
      topDot1Ref: { x: -54, y: 13 },
      topDot2Ref: { x: -15, y: 36 },
      topLineRef: { x: 26, y: 39 },
      leftTopDotRef: { x: 54, y: 62 },
      leftDot1Ref: { x: 48, y: -18 },
      leftDot2Ref: { x: -8, y: -27 },
      leftDot3Ref: { x: -4, y: 37 },
      leftDot4Ref: { x: 28, y: 17 },
      leftDot5Ref: { x: 44, y: -22 },
    };
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], dotRefs[key]);
    });
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], resetProps, '<');
    });
    return tl;
  };

  playAnimation = () => {
    const { animation } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    if (animation !== ANIMATIONS.SHOW) {
      if (animation === ANIMATIONS.OUT) {
        tl.add(this.moveCloudsOut(), '<');
      } else {
        tl.add(this.moveCloudsIn(), '<');
        tl.add(this.moveRingsIn(), '<');
        tl.add(this.moveDotsIn(), '<0.3');
      }
    }
    this.tl = tl;
    return tl;
  };

  render() {
    return (
      <ColoredBackground {...this.props}>
        <S.TopCloud ref={(ref) => (this.topCloudRef = ref)} />
        <S.BottomCloud ref={(ref) => (this.bottomCloudRef = ref)} />
        <S.RightSquare ref={(ref) => (this.rightSquareRef = ref)} />
        <S.RightDot1 ref={(ref) => (this.rightDot1Ref = ref)} />
        <S.RightDot2 ref={(ref) => (this.rightDot2Ref = ref)} />
        <S.RightDot3 ref={(ref) => (this.rightDot3Ref = ref)} />
        <S.RightDot4 ref={(ref) => (this.rightDot4Ref = ref)} />
        <S.RightRing1 ref={(ref) => (this.rightRing1Ref = ref)} />
        <S.RightRing2 ref={(ref) => (this.rightRing2Ref = ref)} />
        <S.RightLine ref={(ref) => (this.rightLineRef = ref)} />
        <S.CenterDot ref={(ref) => (this.centerDotRef = ref)} />
        <S.TopDot1 ref={(ref) => (this.topDot1Ref = ref)} />
        <S.TopDot2 ref={(ref) => (this.topDot2Ref = ref)} />
        <S.LeftDot1 ref={(ref) => (this.leftDot1Ref = ref)} />
        <S.LeftDot2 ref={(ref) => (this.leftDot2Ref = ref)} />
        <S.LeftDot3 ref={(ref) => (this.leftDot3Ref = ref)} />
        <S.LeftDot4 ref={(ref) => (this.leftDot4Ref = ref)} />
        <S.LeftDot5 ref={(ref) => (this.leftDot5Ref = ref)} />
        <S.LeftRing ref={(ref) => (this.leftRingRef = ref)} />
        <S.TopLine ref={(ref) => (this.topLineRef = ref)} />
        <S.LeftTopDot ref={(ref) => (this.leftTopDotRef = ref)} />
        <S.Planet ref={(ref) => (this.planetRef = ref)} />
      </ColoredBackground>
    );
  }
}
