import React from 'react';

const Icon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#E4FAF6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M29.9096 22.8139C31.8651 22.8139 33.4503 21.0647 33.4503 18.907C33.4503 16.7492 31.8651 15 29.9096 15C28.5046 15 27.2907 15.9031 26.7186 17.2119C28.5225 18.1338 29.6833 20.1241 29.6833 22.2557C29.6833 22.4398 29.6746 22.6228 29.6576 22.8042C29.7409 22.8107 29.8249 22.8139 29.9096 22.8139ZM28.0085 22.2036C27.0351 21.5187 26.3854 20.3232 26.3692 18.9591C27.3427 19.644 27.9924 20.8395 28.0085 22.2036ZM27.7239 23.7938C27.4609 24.4707 27.0296 25.0492 26.488 25.465C25.9151 25.9048 25.2189 26.1627 24.4682 26.1627C24.3678 26.1627 24.2684 26.1581 24.1702 26.149C24.7709 24.8069 26.1288 23.8546 27.7239 23.7938ZM30.2723 29.6511H24.8515C24.2928 29.6511 23.8398 29.2138 23.8398 28.6744V27.6976C23.8398 27.5081 23.8538 27.3217 23.8808 27.1394H26.4914C26.651 27.1394 26.8085 27.1483 26.9633 27.1657C28.482 27.336 29.7443 28.3182 30.2723 29.6511ZM32.0335 29.6511C31.5636 27.8607 30.2104 26.4421 28.4847 25.8149C28.94 25.2217 29.2804 24.5314 29.4799 23.7907H31.9328C34.1677 23.7907 35.9794 25.5399 35.9794 27.6976V28.6744C35.9794 29.2138 35.5264 29.6511 34.9677 29.6511H32.0335Z" fill="#21CBA3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.0694 22.8139C17.1139 22.8139 15.5287 21.0647 15.5287 18.907C15.5287 16.7492 17.1139 15 19.0694 15C20.4745 15 21.6883 15.9031 22.2604 17.2119C20.4565 18.1338 19.2957 20.1241 19.2957 22.2557C19.2957 22.4398 19.3044 22.6228 19.3214 22.8042C19.2381 22.8107 19.1541 22.8139 19.0694 22.8139ZM20.9705 22.2036C21.9439 21.5187 22.5936 20.3232 22.6098 18.9591C21.6363 19.644 20.9866 20.8395 20.9705 22.2036ZM21.2551 23.7938C21.5181 24.4707 21.9494 25.0492 22.491 25.465C23.0639 25.9048 23.7601 26.1627 24.5108 26.1627C24.6112 26.1627 24.7106 26.1581 24.8088 26.149C24.2081 24.8069 22.8502 23.8546 21.2551 23.7938ZM18.7067 29.6511H24.1275C24.6862 29.6511 25.1392 29.2138 25.1392 28.6744V27.6976C25.1392 27.5081 25.1252 27.3217 25.0982 27.1394H22.4876C22.328 27.1394 22.1705 27.1483 22.0157 27.1657C20.497 27.336 19.2347 28.3182 18.7067 29.6511ZM16.9455 29.6511C17.4154 27.8607 18.7686 26.4421 20.4943 25.8149C20.039 25.2217 19.6986 24.5314 19.4991 23.7907H17.0462C14.8113 23.7907 12.9997 25.5399 12.9997 27.6976V28.6744C12.9997 29.2138 13.4526 29.6511 14.0113 29.6511H16.9455Z" fill="#21CBA3" />
    <path d="M28.2113 21.7649C28.2113 24.0213 26.5441 26.25 24.4875 26.25C22.431 26.25 20.7638 24.0213 20.7638 21.7649C20.7638 19.5086 22.431 17.6794 24.4875 17.6794C26.5441 17.6794 28.2113 19.5086 28.2113 21.7649Z" fill="#07BB87" />
    <path d="M18.104 30.9572C18.104 28.7009 20.0093 26.8717 22.3597 26.8717H26.6154C28.9658 26.8717 30.8711 28.7009 30.8711 30.9572V31.9786C30.8711 32.5427 30.3948 33 29.8072 33H19.1679C18.5803 33 18.104 32.5427 18.104 31.9786V30.9572Z" fill="#07BB87" />
  </svg>

);

export default Icon;
