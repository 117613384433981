import { compose, withProps } from 'recompose';
import { skillEntityPropType } from '@matterapp/skills';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm } from 'redux-form';
import CustomSkillEditor from '../components/CustomSkillEditor';
import validate from '../validate';
import {
  EDIT_CUSTOM_SKILL_MUTATION,
  SKILLS_QUERY,
  SKILL_DETAILS_QUERY,
} from 'graphql-queries/queries';
import withCurrentColorPalette from '../hocs/withCurrentColorPalette';
import { withQueryParams } from 'hocs';
import {
  getSkillFromFormValues,
  getValuesFromSkill,
} from '../libs/get-skill-from-form-values';
import Toast from 'components/Toast/Toast';
import withCustomSkillAsyncValidationError from '../hocs/withCustomSkillAsyncValidationError';

const EDIT_CUSTOM_SKILL_FORM = '@custom-skill-editor/edit-form';

const withEditCustomSkill = compose(
  graphql(EDIT_CUSTOM_SKILL_MUTATION, {
    name: 'editCustomSkillMutation',
    options: ({ queryParams }) => {
      return {
        refetchQueries: () => [
          {
            query: SKILLS_QUERY,
          },
          { 
            query: SKILL_DETAILS_QUERY, 
            variables: { workspaceId: queryParams?.workspaceId }
          }
        ],
      };
    },
  }),
  withProps(({ editCustomSkillMutation }) => ({
    editCustomSkill: (skill) =>
      editCustomSkillMutation({
        variables: skill,
      }),
  }))
);

const withData = compose(
  withQueryParams,
  withProps(() => ({
    modalTitle: 'Edit Skill',
    primaryButtonText: 'Save',
  })),
  withProps(() => ({
    isDisabledIfPristine: true,
  })),
  withProps(({ skillToEdit }) => ({
    initialValues: skillToEdit ? getValuesFromSkill(skillToEdit) : null,
  })),
  withCurrentColorPalette({ formName: EDIT_CUSTOM_SKILL_FORM }),
  withEditCustomSkill,
  withCustomSkillAsyncValidationError,
  reduxForm({
    form: EDIT_CUSTOM_SKILL_FORM,
    validate,
    destroyOnUnmount: true,
    onSubmit: async (
      values,
      dispatch,
      {
        handleDismissGeneratorModal,
        editCustomSkill,
        skillToEdit,
        handleCustomSkillAsyncValidationError,
      }
    ) => {
      const skill = {
        ...getSkillFromFormValues(values),
        id: skillToEdit.id,
      };
      try {
        await editCustomSkill(skill);
        handleDismissGeneratorModal();
        Toast.success(`You successfully updated your skill: "${skill.name}"`);

      } catch (error) {
        handleCustomSkillAsyncValidationError(error);
      }
    },
  })
);

const CreateCustomSkillModeContainer = withData(CustomSkillEditor);

CreateCustomSkillModeContainer.defaultProps = {
  skillToEdit: null,
};

CreateCustomSkillModeContainer.propTypes = {
  handleDismissGeneratorModal: PropTypes.func.isRequired,
  skillToEdit: skillEntityPropType,
};

export default CreateCustomSkillModeContainer;
