import PropTypes from 'prop-types';
import { CONNECTION_ACTIVITY_TYPE } from '@matterapp/peer-connections';
import { personPropType } from '@matterapp/people';

export const Connection = PropTypes.shape({
  person: personPropType.isRequired,
  id: PropTypes.string.isRequired,
});

export const Activity = PropTypes.shape({
  type: PropTypes.oneOf(Object.keys(CONNECTION_ACTIVITY_TYPE)),
  time: PropTypes.string.isRequired,
});
