import styled from 'styled-components';
import { spacing, colors, font, lineHeight, border, media } from '@matterapp/matter-theme';

export const MainColumnContainer = styled.div`
  transform: translate3d(0, 0, 0);
  min-height: 100vh;
  margin: -${spacing.single};
  padding: ${spacing.single};
  overflow: hidden;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  background: white;
  border-bottom: 1px solid ${colors.blacks[10]};
  border-radius: ${border.radius.L}
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  ${media.S`
    height: 240px;
  `}
`;

export const CustomeFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 284px;
  background: white;
  border-bottom: 1px solid ${colors.blacks[10]};
  border-radius: ${border.radius.L}
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
`;

export const CustomeFooterText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${colors.blacks[30]};
  font-size: ${font.size.S};
  line-height: ${lineHeight.M};
  font-weight: ${font.weight.semiBold};
  margin-left: ${spacing.singleAndHalf};
  margin-right: ${spacing.singleAndHalf};
`;