import map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../libs/omitStyled';

const StyledLink = omitStyled(Link, ['isActive'])`
  display: block;
  padding: 17px 0;
  color: ${() => theme.colors.blacks[50]};
  font-size: 17px;
  line-height: 22px;
  border-bottom: 1px solid ${() => theme.colors.blacks[10]};

  ${({ isActive }) => isActive && `
    color: ${theme.colors.black};
    font-weight: bold;
  `}
`;


function SideBarTabs({ tabList }) {
  const location = useLocation();

  return (
    <div className="side-bar-tabs">
      <nav>
        {map(tabList, (item, index) => (
          <StyledLink
            to={item.url}
            title={item.name}
            key={index}
            isActive={item.url === location.pathname}
          >
            {item.name}
          </StyledLink>
        ))}
      </nav>
    </div>
  );
}

SideBarTabs.defaultProps = {
  location: {
    pathname: '',
  },
};

SideBarTabs.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  tabList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
};

export default SideBarTabs;
