import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.28719 19.8307C5.34144 19.4557 5.41739 18.9188 5.49623 18.3827C5.6568 17.2976 5.8181 16.2117 5.98229 15.1274C6.00037 15.0104 5.96999 14.9332 5.88537 14.8514C4.79318 13.7941 3.70026 12.7367 2.61603 11.6704C1.80086 10.8688 1.7958 9.59394 2.59433 8.79905C2.92994 8.4646 3.34512 8.32062 3.79501 8.24563C5.2098 8.01017 6.62314 7.7642 8.03793 7.52724C8.1551 7.50774 8.21369 7.453 8.26143 7.34651C8.88347 5.96672 9.51202 4.59067 10.1319 3.21087C10.401 2.61246 10.8248 2.21576 11.4469 2.05679C12.3141 1.83557 13.1828 2.27276 13.6247 3.15763C14.2952 4.49843 14.965 5.83998 15.6283 7.18454C15.6927 7.31502 15.7722 7.35926 15.9032 7.37651C17.4026 7.57148 18.9041 7.7567 20.4007 7.97567C21.8126 8.18189 22.4773 9.90964 21.6151 11.087C21.5319 11.201 21.4393 11.3097 21.3424 11.4117C20.3428 12.4608 19.3446 13.5114 18.3378 14.553C18.2156 14.6797 18.1873 14.7907 18.2199 14.9609C18.5034 16.4607 18.787 17.9605 19.0531 19.4632C19.2738 20.7096 18.3523 21.8359 17.1566 21.8059C16.8246 21.7977 16.518 21.6919 16.2243 21.5389C14.9274 20.864 13.6291 20.1906 12.3344 19.5105C12.2179 19.449 12.1333 19.4527 12.0204 19.5172C10.7351 20.2551 9.44837 20.99 8.15944 21.7204C7.21987 22.2528 6.20435 22.0114 5.59171 21.1108C5.36748 20.7786 5.27924 20.3991 5.28719 19.8307Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#FFCE7E',
  size: '24'
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
