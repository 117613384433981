import moment from 'moment';
import { WeekDay } from '@matterapp/calendar-date';
import { defaultStartingOption, frequencyOptions } from './consts';

const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateRecurrenceTooltip = (recurrence) => {
  const day = recurrence.frequency === 'weekly' || recurrence.frequency === 'biweekly' ? WeekDay[recurrence.day] + 's' : recurrence.day;
  const date = typeof day === 'number' ? `the ${moment.localeData().ordinal(day)}` : day;
  return `${capitalizeFirstLetter(recurrence.frequency)} on ${date}`;
};

const getWeeklyDates = () => {
  const options = [defaultStartingOption];
  for (const day of week) {
    const date = {};
    date.day = WeekDay[day];
    date.label = day;
    options.push(date);
  }
  return options;
};

const getMonthlyDates = () => {
  const options = [defaultStartingOption];
  for (let i = 1; i <= 31; i++) {
    const date = {};
    date.day = i;
    date.label = 'On the ' + moment.localeData().ordinal(i);
    options.push(date);
  } 
  return options;
};

const getOptionsList = () => {
  const list = [];
  for (const option in frequencyOptions) {
    const frequency = frequencyOptions[option];
    list.push(frequency);
  }
  return list;
};

export const frequencyOptionsList = getOptionsList();
export const weeklyStartingOptions = getWeeklyDates();
export const monthlyStartingOptions = getMonthlyDates();