import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import {
  ListWithHeader,
  NotificationItem,
  Button,
  Text,
} from '@matterapp/matter-ui';
import { useNewFeedbacksQuery } from 'hooks/useRemoteNotifications';
import viewFeedbackParam from 'helpers/viewFeedbackParam';

const renderItem = (feedback, params) => {
  const { isMultipleFeedback, numberOfPeople, onClick } = params;
  const { eventTime, payload, id } = feedback;
  const { giver } = payload;
  const content = (
    <Text color="black">
      gave you{isMultipleFeedback ? ' new ' : ' '}feedback.
    </Text>
  );
  const Item = isMultipleFeedback
    ? NotificationItem.Multiple
    : NotificationItem;

  return (
    <Item
      isStackedOnMobile
      avatarUrl={giver.photoUrl}
      content={content}
      key={id}
      person={giver}
      eventTime={eventTime}
      numberOfPeople={numberOfPeople}
      actions={
        <Button.Primary.Vivid
          key="view"
          content={'View'}
          color={Button.buttonColors.PURPLE}
          size={Button.buttonSizes.S}
          onClick={onClick}
        />
      }
    />
  );
};

const getRenderItem = (params) => (feedback) => renderItem(feedback, params);

function UnreadFeedback(props) {
  const { onClickNotification, workspaceId } = props;
  const navigate = useNavigate();

  const { newFeedbacksData, loading } = useNewFeedbacksQuery();
  const { feedbackReceivedFeedItems } = newFeedbacksData;

  if (loading) {
    return null;
  }

  const nonExpiredWorkspaceFeedbacks = feedbackReceivedFeedItems.filter(feedItem => !feedItem.workspace.isExpired);

  if (!nonExpiredWorkspaceFeedbacks || !nonExpiredWorkspaceFeedbacks.length) {
    return null;
  }

  const isMultipleFeedback = nonExpiredWorkspaceFeedbacks.length > 1;
  const feedbackReceivedIdEncoded = viewFeedbackParam.encode(nonExpiredWorkspaceFeedbacks);
  const viewFeedbackLink = Resources.feedbackFlow.path({
    feedbackReceivedIdEncoded: feedbackReceivedIdEncoded,
    workspaceId,
  });
  const onClickView = (e) => {
    onClickNotification(e);
    navigate(`${viewFeedbackLink}#1`);
  };

  return (
    <ListWithHeader
      header="Unread Feedback"
      headerType="invite"
      items={[nonExpiredWorkspaceFeedbacks[0]]}
      renderItem={getRenderItem({
        onClick: onClickView,
        isMultipleFeedback,
        numberOfPeople: nonExpiredWorkspaceFeedbacks.length,
      })}
      rounded={false}
      showAllItemBorders
    />
  );
};

UnreadFeedback.defaultProps = {
  onClickNotification: () => null,
};

UnreadFeedback.propTypes = {
  onClickNotification: PropTypes.func,
  workspaceId: PropTypes.string,
};

export default UnreadFeedback;
