import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  startAuth,
  endAuth,
  setCurrentPhoneNumber,
  resetSigninData,
} from '../../reducers';
import { updateIntercom } from 'libs/tracking/intercom';
import withCurrentUser from 'context/UserContext/withCurrentUser';

const mapStateToProps = ({ auth }) => ({
  isRunningAuth: auth.isRunningAuth,
  currentPhoneNumber: auth.currentPhoneNumber,
  currentEmail: auth.currentEmail,
});

const mapDispatchToProps = (dispatch, { currentUserStatus }) => ({
  startAuth: () => dispatch(startAuth()),
  endAuth: () => {
    updateIntercom(currentUserStatus);
    dispatch(endAuth());
  },
  setCurrentPhoneNumber: (phoneNumber) =>
    dispatch(setCurrentPhoneNumber(phoneNumber)),
  resetSigninData: () => dispatch(resetSigninData()),
});

const withAuth = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withAuth,
  withCurrentUser
);
