import { createError } from 'apollo-errors';

export const errorsMap = new Map();
export const errorMessagesMap = new Map();

export const saveError = ({ code, short, message, explanation }) => {
  errorsMap.set(code, {
    code,
    short,
    message,
    explanation,
  });
};

export class MatterError extends Error {
  constructor(errorCode, params = {}) {
    super();
    const { code, short, message, explanation } = errorsMap.get(errorCode);
    const messageText =
      typeof message === 'function' ? message(params) : message;
    const InnerMatterError = createError(code, {
      message: messageText,
      data: {
        short,
        code: errorCode,
        explanation,
        ...params,
      },
    });

    const matterError = new InnerMatterError();
    matterError.isMatterError = true;

    return matterError;
  }
}
