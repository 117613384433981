import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from '../../SVG';

const path = styled.path`
  fill: ${({ fillColor, grayScale, grayScaleColor }) => (grayScale ? grayScaleColor : fillColor )};
`;

const Base = (props) => {
  const { children, grayScale, height, width, viewBox, ...otherProps } = props;
  const svgElements = React.Children.map(children, (child) => {
    return React.cloneElement(child, { grayScale });
  });
  return (
    <SVG {...otherProps} height={height} width={width} viewBox={viewBox}>
      {svgElements}
    </SVG>
  );
};

Base.propTypes = {
  grayScale: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  viewBox: PropTypes.string,
};

Base.path = path;

export default Base;
