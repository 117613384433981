import styled from 'styled-components';
import Button from '../../Button';

const getIconButton = (iconName) => styled(Button.Icon).attrs(() => ({
  iconName,
}))`
  height: auto;
  width: auto;
  padding: 0;
`;

/*****************************************************************************/
/* ICON BUTTONS                                                              */
/*****************************************************************************/
export const AdvanceIcon = getIconButton('karotRight');
export const BackIcon = getIconButton('arrowLeft');
export const CloseIcon = getIconButton('close');

// Deprecated
export const Advance = AdvanceIcon;
export const Back = BackIcon;
export const Close = CloseIcon;

/*****************************************************************************/
/* MAIN BUTTONS                                                              */
/*****************************************************************************/
export const PrimaryVivid = styled(Button.Primary.Vivid).attrs(({ isDestructive }) => ({
  color: isDestructive ? Button.buttonColors.RED : Button.buttonColors.BLUE,
}))``;

export const Primary = styled(Button.Primary).attrs(({ isDestructive }) => ({
  color: isDestructive ? Button.buttonColors.RED : Button.buttonColors.BLUE,
}))``;

export const Secondary = styled(Button).attrs(() => ({
  color: Button.buttonColors.BLACK,
}))``;

export const Cancel = styled(Secondary).attrs(() => ({
  content: 'Cancel',
}))``;

export const CloseText = styled(Button.Minimal).attrs(() => ({
  content: 'Close',
}))``;

/*****************************************************************************/
/* EXPORT                                                                    */
/*****************************************************************************/
export default {
  AdvanceIcon,
  BackIcon,
  CloseIcon,

  Primary,
  PrimaryVivid,
  Secondary,

  Cancel,
  CloseText,

  // Deprecated names. Use icon names.
  Advance,
  Back,
  Close,
};
