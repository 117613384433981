import React from 'react';
import { Colors, Thumbnail, Paths } from '../Thumbnail';

const Inclusive = (props) => {
  return (
    <Thumbnail color={Colors.Purple} width={292} height={101} top={-10} left={0} {...props}>
      <Paths.L10 d="M266.521 35.2567L273.861 20.7167C274.871 18.7167 277.341 17.8967 279.371 18.9067C281.391 19.9067 282.231 22.3467 281.201 24.3567C281.111 24.5267 281.001 24.7067 280.891 24.8567L271.501 38.1767C270.591 39.4767 268.771 39.7967 267.461 38.8967C266.261 38.0767 265.891 36.5167 266.521 35.2567Z" />
      <Paths.L30 d="M243.521 56.3767C243.271 55.6767 243.101 54.9367 243.021 54.1367C242.541 49.3167 245.771 44.7067 250.521 43.5367C255.791 42.2367 261.161 45.3467 262.571 50.5667C262.641 50.8067 262.691 51.0467 262.741 51.2867C262.941 52.3167 263.941 52.9867 264.961 52.7267L276.631 49.6267C277.951 49.2767 278.751 47.9267 278.381 46.6067L266.921 4.23671C266.101 1.19671 262.911 -0.623295 259.841 0.196705L217.051 11.5467C215.731 11.8967 214.931 13.2467 215.301 14.5667L218.171 25.1467C218.631 26.8667 217.421 28.5067 215.651 28.7367C214.901 28.8367 214.151 29.0167 213.401 29.2867C209.041 30.8967 206.321 35.4367 207.041 39.9867C207.961 45.9167 213.811 49.5867 219.491 48.0767C219.981 47.9467 220.441 47.7867 220.891 47.5867C222.491 46.8967 224.331 47.8267 224.761 49.4867L227.691 60.3167C228.011 61.4967 229.231 62.1967 230.431 61.8767L242.351 58.7167C243.351 58.4467 243.881 57.3667 243.521 56.3767Z" />
      <Paths.L20 d="M173.101 66.4667C173.371 67.2167 173.551 67.9967 173.631 68.8467C174.141 73.9667 170.711 78.8567 165.661 80.1067C160.061 81.4967 154.371 78.1767 152.861 72.6467C152.791 72.3967 152.731 72.1367 152.681 71.8767C152.471 70.7767 151.411 70.0667 150.321 70.3467L137.921 73.6367C136.511 74.0067 135.671 75.4467 136.061 76.8467L148.231 121.837C149.101 125.067 152.491 126.997 155.751 126.127L201.191 114.077C202.601 113.707 203.441 112.267 203.051 110.867L200.011 99.6267C199.521 97.8067 200.801 96.0567 202.691 95.8167C203.481 95.7067 204.291 95.5267 205.081 95.2367C209.711 93.5267 212.601 88.7067 211.841 83.8767C210.861 77.5867 204.651 73.6867 198.621 75.2867C198.101 75.4267 197.611 75.5967 197.131 75.8067C195.441 76.5367 193.481 75.5567 193.021 73.7867L189.911 62.2767C189.571 61.0267 188.271 60.2767 187.001 60.6167L174.341 63.9767C173.281 64.2667 172.721 65.4167 173.101 66.4667Z" />
      <Paths.L10
        evenOdd
        d="M267.202 6.90669C266.982 6.89669 266.751 6.91669 266.521 6.96669L246.131 11.2567C244.851 11.5167 243.971 12.6467 243.971 13.8867C243.971 14.0767 244.001 14.2667 244.031 14.4567C244.341 15.9167 245.791 16.8367 247.261 16.5367L257.891 14.2967L258.131 15.0267L241.721 19.7967C240.541 20.1367 239.771 21.2167 239.771 22.3867C239.771 22.6467 239.801 22.8967 239.871 23.1467C240.301 24.5867 241.811 25.3967 243.261 24.9767L259.831 20.1567L260.072 20.8967L242.011 27.3367C240.901 27.7367 240.211 28.7567 240.211 29.8667C240.211 30.1767 240.271 30.4867 240.381 30.7767C240.881 32.1867 242.451 32.9067 243.871 32.4067L261.771 26.0267L262.001 26.7267L247.202 33.2267C246.182 33.6767 245.572 34.6667 245.572 35.6967C245.572 36.0567 245.642 36.4267 245.812 36.7867C246.422 38.1567 248.041 38.7667 249.411 38.1667L268.131 29.9467L277.702 26.8467C280.162 26.0567 281.941 24.1367 282.621 21.8967L293.601 20.8567V1.28668L267.202 6.90669Z"
      />
      <Paths.L10 d="M242.391 116.847C242.391 116.657 242.411 116.467 242.451 116.277C242.761 114.817 244.211 113.877 245.681 114.197L266.071 118.487C267.541 118.797 268.481 120.237 268.161 121.687C267.851 123.147 266.401 124.087 264.931 123.767L244.541 119.477C243.261 119.227 242.391 118.097 242.391 116.847Z" />
      <Paths.L10 d="M238.181 108.357C238.181 108.107 238.211 107.857 238.291 107.587C238.721 106.157 240.231 105.337 241.681 105.757L264.711 112.447C266.151 112.867 266.981 114.367 266.561 115.797C266.131 117.227 264.621 118.047 263.171 117.627L240.141 110.937C238.951 110.607 238.181 109.527 238.181 108.357Z" />
      <Paths.L10 d="M238.631 100.877C238.631 100.577 238.681 100.267 238.801 99.9667C239.311 98.5567 240.871 97.8367 242.291 98.3367L268.561 107.697C269.981 108.207 270.721 109.747 270.201 111.147C269.691 112.557 268.131 113.277 266.711 112.777L240.441 103.417C239.331 103.017 238.631 101.977 238.631 100.877Z" />
      <Paths.L10 d="M243.981 95.0567C243.981 94.6967 244.061 94.3167 244.221 93.9667C244.831 92.6067 246.452 91.9867 247.822 92.5867L269.312 102.017C270.682 102.617 271.312 104.217 270.702 105.577C270.092 106.937 268.471 107.557 267.101 106.957L245.611 97.5267C244.601 97.0767 243.981 96.0967 243.981 95.0567Z" />
      <Paths.L50 d="M257.051 102.227L260.641 103.387L255.781 118.087L252.191 116.927L250.911 120.817L269.321 124.517C273.241 125.787 277.461 123.767 278.701 120.027L281.021 113.007C282.251 109.267 280.061 105.167 276.141 103.897L258.401 98.1467L257.051 102.227Z" />
      <Paths.L50 d="M293.621 110.047L271.891 107.987L255.191 121.627L293.621 129.807V110.047Z" />
      <Paths.L30 d="M224.301 85.9067C225.021 86.0367 225.721 86.2467 226.441 86.5667C230.731 88.4967 233.081 93.3767 231.771 97.8767C230.331 102.857 225.111 105.807 220.031 104.457C219.791 104.397 219.571 104.327 219.341 104.237C218.381 103.907 217.321 104.407 217.061 105.377L214.051 116.517C213.711 117.787 214.471 119.087 215.741 119.427L257.011 130.377C259.971 131.157 263.051 129.407 263.841 126.477L274.901 85.6167C275.241 84.3467 274.481 83.0467 273.211 82.7067L262.891 79.9667C261.221 79.5267 260.411 77.7267 261.081 76.1567C261.371 75.4867 261.581 74.7767 261.711 74.0267C262.461 69.6467 259.931 65.2167 255.741 63.6067C250.301 61.5067 244.381 64.5767 242.911 69.9967C242.781 70.4667 242.691 70.9267 242.641 71.3867C242.451 73.0367 240.791 74.1067 239.161 73.6767L228.601 70.8767C227.451 70.5767 226.271 71.2467 225.961 72.3867L222.881 83.7667C222.651 84.7667 223.301 85.7267 224.301 85.9067Z" />
      <Paths.L20 d="M10.3311 57.1067C11.0511 57.2367 11.7512 57.4467 12.4712 57.7667C16.7612 59.6967 19.1112 64.5767 17.8012 69.0767C16.3612 74.0567 11.1412 77.0067 6.06124 75.6567C5.82124 75.5967 5.60118 75.5267 5.37118 75.4367C4.41118 75.1067 3.35115 75.6067 3.09115 76.5767L0.0811394 87.7167C-0.258861 88.9867 0.501203 90.2867 1.7712 90.6267L43.0412 101.577C46.0012 102.357 49.0812 100.607 49.8712 97.6767L60.9312 56.8167C61.2712 55.5467 60.5112 54.2467 59.2412 53.9067L48.9212 51.1667C47.2512 50.7267 46.4412 48.9267 47.1112 47.3567C47.4012 46.6867 47.6112 45.9767 47.7412 45.2267C48.4912 40.8467 45.9612 36.4167 41.7712 34.8067C36.3312 32.7067 30.4112 35.7767 28.9412 41.1967C28.8112 41.6667 28.7212 42.1267 28.6712 42.5867C28.4812 44.2367 26.8212 45.3067 25.1912 44.8767L14.6312 42.0767C13.4812 41.7767 12.3012 42.4467 11.9912 43.5867L8.91122 54.9667C8.68122 55.9667 9.33114 56.9267 10.3311 57.1067Z" />
      <Paths.L50 d="M269.911 92.5467L279.301 105.867C280.601 107.707 280.141 110.247 278.281 111.517C276.421 112.797 273.861 112.357 272.571 110.507C272.461 110.357 272.351 110.167 272.261 109.997L264.921 95.4567C264.201 94.0367 264.791 92.3167 266.221 91.5967C267.541 90.9767 269.101 91.3967 269.911 92.5467Z" />
      <Paths.L10 d="M66.4712 34.1367C66.4712 33.8567 66.5212 33.5767 66.6012 33.2967C67.0612 31.8767 68.6013 31.0967 70.0413 31.5667L89.8712 37.9367C91.3112 38.3967 92.0912 39.9167 91.6212 41.3367C91.1612 42.7567 89.6213 43.5367 88.1813 43.0667L68.3512 36.6967C67.2012 36.3267 66.4712 35.2767 66.4712 34.1367Z" />
      <Paths.L10 d="M70.9014 29.6367C70.9014 29.4367 70.9313 29.2167 70.9813 28.9967C71.3413 27.5567 72.8114 26.6567 74.2814 27.0067L97.6013 32.6067C99.0613 32.9567 99.9613 34.4167 99.6113 35.8667C99.2513 37.3067 97.7814 38.2067 96.3114 37.8567L72.9913 32.2567C71.7413 31.9567 70.9014 30.8567 70.9014 29.6367Z" />
      <Paths.L10 d="M70.1313 23.6467C70.1313 23.4967 70.1514 23.3267 70.1814 23.1667C70.4514 21.7067 71.8713 20.7167 73.3513 20.9867L100.831 25.8867C102.321 26.1567 103.301 27.5567 103.031 29.0167C102.761 30.4767 101.341 31.4667 99.8613 31.1967L72.3813 26.2967C71.0613 26.0667 70.1313 24.9167 70.1313 23.6467Z" />
      <Paths.L10 d="M72.541 18.3767C72.541 18.2767 72.551 18.1867 72.561 18.0867C72.721 16.6067 74.071 15.5267 75.561 15.6867L98.941 18.1567C100.431 18.3067 101.521 19.6467 101.371 21.1267C101.221 22.6067 99.871 23.6867 98.371 23.5267L74.991 21.0567C73.571 20.9067 72.541 19.7367 72.541 18.3767Z" />
      <Paths.L10 d="M86.0914 21.9367L82.4015 21.1667L79.1815 36.3067L82.8815 37.0767L82.0315 41.0867L64.2214 35.1667C60.1814 34.3267 57.5515 30.4967 58.3715 26.6367L59.9115 19.4067C60.7315 15.5567 64.7015 13.0867 68.7415 13.9367L87.0115 17.7267L86.0914 21.9367Z" />
      <Paths.L10 d="M46.5112 8.81671V29.6367L77.8912 39.6467L70.3113 19.5667L46.5112 8.81671Z" />
      <Paths.L30 d="M116.481 46.8767C115.761 46.7467 115.061 46.5367 114.341 46.2167C110.051 44.2867 107.701 39.3967 109.011 34.9067C110.451 29.9267 115.671 26.9767 120.751 28.3267C120.991 28.3867 121.211 28.4567 121.441 28.5467C122.401 28.8867 123.461 28.3867 123.721 27.4067L126.731 16.2667C127.071 14.9967 126.311 13.6967 125.041 13.3567L83.771 2.40669C80.811 1.61669 77.731 3.37669 76.941 6.30669L65.8809 47.1667C65.5409 48.4367 66.301 49.7367 67.571 50.0767L77.8809 52.8167C79.5509 53.2567 80.361 55.0567 79.691 56.6267C79.401 57.2967 79.191 57.9967 79.061 58.7567C78.311 63.1367 80.841 67.5667 85.031 69.1767C90.471 71.2767 96.3909 68.2067 97.8609 62.7867C97.9909 62.3167 98.0809 61.8567 98.1309 61.3967C98.3209 59.7467 99.9809 58.6767 101.601 59.1067L112.161 61.9067C113.311 62.2067 114.491 61.5367 114.801 60.3967L117.881 49.0167C118.141 48.0267 117.481 47.0667 116.481 46.8767Z" />
      <Paths.L30 d="M116.481 46.8767C115.761 46.7467 115.061 46.5367 114.341 46.2167C110.051 44.2867 107.701 39.3967 109.011 34.9067C110.451 29.9267 115.671 26.9767 120.751 28.3267C120.991 28.3867 121.211 28.4567 121.441 28.5467C122.401 28.8867 123.461 28.3867 123.721 27.4067L126.731 16.2667C127.071 14.9967 126.311 13.6967 125.041 13.3567L83.7711 2.40669C80.8111 1.61669 77.7312 3.37669 76.9412 6.30669L65.8811 47.1667C65.5411 48.4367 66.2912 49.7367 67.5712 50.0767L77.8911 52.8167C79.5611 53.2567 80.3712 55.0567 79.7012 56.6267C79.4112 57.2967 79.2012 57.9967 79.0712 58.7567C78.3212 63.1367 80.8512 67.5667 85.0412 69.1767C90.4812 71.2767 96.4011 68.2067 97.8711 62.7867C98.0011 62.3167 98.0911 61.8567 98.1411 61.3967C98.3311 59.7467 99.9911 58.6767 101.611 59.1067L112.171 61.9067C113.321 62.2067 114.501 61.5367 114.811 60.3967L117.891 49.0167C118.141 48.0267 117.491 47.0667 116.481 46.8767Z" />
      <Paths.L20 d="M329.161 77.1167C328.441 76.9867 327.741 76.7767 327.021 76.4567C322.731 74.5267 320.381 69.6367 321.691 65.1467C323.131 60.1667 328.351 57.2167 333.431 58.5667C333.671 58.6267 333.891 58.6967 334.121 58.7867C335.081 59.1267 336.141 58.6267 336.401 57.6467L339.411 46.5067C339.751 45.2367 338.991 43.9367 337.721 43.5967L296.451 32.6467C293.491 31.8567 290.411 33.6167 289.621 36.5467L278.561 77.4067C278.221 78.6767 278.971 79.9767 280.251 80.3167L290.571 83.0567C292.241 83.4967 293.051 85.2967 292.381 86.8667C292.091 87.5367 291.881 88.2367 291.751 88.9967C291.001 93.3767 293.531 97.8067 297.721 99.4167C303.161 101.517 309.081 98.4467 310.551 93.0267C310.681 92.5567 310.771 92.0967 310.821 91.6367C311.011 89.9867 312.671 88.9167 314.291 89.3467L324.851 92.1467C326.001 92.4467 327.181 91.7767 327.491 90.6367L330.571 79.2567C330.811 78.2667 330.161 77.3067 329.161 77.1167Z" />
      <Paths.L10 d="M82.6614 12.1867L68.9714 21.1467C67.0814 22.3867 64.5414 21.8667 63.2814 20.0067C62.0314 18.1367 62.5514 15.6167 64.4414 14.3767C64.6114 14.2767 64.7913 14.1767 64.9613 14.0867L79.8214 7.19668C81.2715 6.52668 83.0014 7.14668 83.6814 8.58668C84.3014 9.87668 83.8414 11.4167 82.6614 12.1867Z" />
      <Paths.L50 d="M78.2311 129.387L63.3711 122.497C61.3211 121.537 60.4311 119.127 61.4011 117.097C62.3611 115.067 64.8011 114.187 66.8511 115.147C67.0211 115.227 67.2111 115.337 67.3711 115.447L81.0612 124.407C82.4012 125.277 82.7611 127.067 81.8811 128.387C81.0811 129.577 79.5111 129.987 78.2311 129.387Z" />
      <Paths.L30 d="M97.9213 78.2667C98.1613 78.9467 98.3313 79.6567 98.4113 80.4267C98.8713 85.0767 95.7613 89.5267 91.1713 90.6567C86.0813 91.9167 80.9113 88.9067 79.5513 83.8867C79.4913 83.6567 79.4413 83.4167 79.4013 83.1867C79.2113 82.1867 78.2413 81.5467 77.2613 81.7967L66.0013 84.7867C64.7213 85.1267 63.9613 86.4267 64.3013 87.6967L75.3613 128.557C76.1513 131.487 79.2313 133.247 82.1913 132.457L123.461 121.507C124.741 121.177 125.501 119.867 125.151 118.597L122.381 108.387C121.941 106.737 123.101 105.147 124.811 104.927C125.531 104.837 126.261 104.657 126.981 104.387C131.191 102.837 133.811 98.4567 133.121 94.0667C132.231 88.3467 126.591 84.8167 121.111 86.2667C120.641 86.3967 120.191 86.5567 119.761 86.7367C118.221 87.3967 116.451 86.5067 116.021 84.9067L113.201 74.4567C112.891 73.3167 111.711 72.6467 110.561 72.9467L99.0613 75.9967C98.0813 76.2767 97.5813 77.3167 97.9213 78.2667Z" />
      <Paths.L50
        evenOdd
        d="M98.2715 105.387L79.3815 108.757L79.2214 107.997L96.0115 103.967C97.4915 103.617 98.3815 102.147 98.0215 100.707C97.6615 99.2467 96.1914 98.3667 94.7214 98.7167L78.1014 102.707L77.9415 101.957L88.2815 98.6367C89.7115 98.1867 90.5015 96.6567 90.0315 95.2367C89.5715 93.8267 88.0314 93.0567 86.5914 93.5067L66.7615 99.8767C66.5415 99.9467 66.3315 100.047 66.1415 100.167L46.5215 106.427V127.037L60.3615 120.787C62.0815 122.397 64.5915 123.177 67.1315 122.647L77.1515 120.567L97.3514 118.427C98.8614 118.277 99.9315 116.937 99.7815 115.457C99.6215 113.967 98.2715 112.907 96.7815 113.057L80.6715 114.757L80.5115 114.027L99.2515 110.687C100.731 110.427 101.712 109.027 101.452 107.557C101.172 106.107 99.7415 105.127 98.2715 105.387Z"
      />
      <Paths.L20 d="M167.441 46.2267L161.841 13.8367H173.051L167.441 46.2267Z" />
      <Paths.L20 d="M176.801 46.2267L190.331 25.0467L196.731 30.8767L176.801 46.2267Z" />
      <Paths.L20 d="M158.111 46.2267L145.421 25.0467L139.421 30.8767L158.111 46.2267Z" />
    </Thumbnail>
  );
};

export default Inclusive;
