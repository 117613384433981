import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CONNECTION_ACTIVITY_TYPE } from '@matterapp/peer-connections';

import { ButtonNew } from '../../Button';
import { Connection } from '../prop-types';
import ListItem from './ListItem';
import EmptyList from './EmptyList';
import SubHeader from './SubHeader';
import { ListHeading } from '../../Heading';
import theme from '@matterapp/matter-theme';
const Root = styled.div``;

const HeaderActionContainer = styled.div`
  width: 32px;
  height: 32px;

  button.button {
    width: 32px;
    height: 32px;
  }
`;

const ListHeadingWrapper = styled.div`
  ${() => theme.media.medium`
    border-radius: 12px 12px 0px 0px;
  `}
  background: white;
  padding: 24px;

`;

const ListItemsContainer = styled.div`
  ${() => theme.media.medium`
    >div:last-child {
      border-radius: 0 0 12px 12px;
      box-shadow: ${theme.shadows[45]};
    }
  `}
`;

const FormContainer = styled.div`
  padding: 16px;
  background: white;

  ${({ isHidden }) =>
    isHidden &&
    `
    display: none;
  `}

  ${({ isShaking }) =>
    isShaking &&
    `
    form {
      animation-name: shake;
      animation-duration: .5s;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
    }
  `}

  ${() => theme.media.medium`
    padding-left: 24px;
    padding-right: 24px;
  `}

  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    20%, 60% { transform: translateX(-5px); }
    40%, 80% { transform: translateX(5px); }
  }
`;

class List extends React.Component {
  constructor(props) {
    super(props);
    const { connections } = this.props;
    const isAtLeastOneGaveFeedback = connections.reduce(
      (acc, cur) =>
        acc ||
        cur.activitySummary.type === CONNECTION_ACTIVITY_TYPE.RECEIVED_FEEDBACK,
      false
    );
    const isStopFormAutoDisplay =
      connections.length >= 5 && isAtLeastOneGaveFeedback;
    this.state = {
      isFormOpen: !isStopFormAutoDisplay,
      isShaking: false,
    };
    this.shakeTimeout = null;
  }

  componentWillUnmount() {
    this.clearShakeTimeout();
  }

  handleOpenInputForm = () => {
    const wasFormOpen = this.state.isFormOpen;

    this.setState(
      {
        isFormOpen: true,
      },
      () => {
        try {
          const emailInput = this.formContainerRef.querySelectorAll(
            'input[type="email"]'
          )[0];
          const textInput = this.formContainerRef.querySelectorAll(
            'input[type="text"]'
          )[0];
          const input = emailInput || textInput;
          input.focus();
          if (wasFormOpen) {
            this.clearShakeTimeout();
            this.setState({ isShaking: true });
            this.shakeTimeout = setTimeout(() => {
              this.setState({ isShaking: false });
            }, 510);
          }
        } catch (e) {
          console.error(e); //eslint-disable-line
        }
      }
    );
  };

  clearShakeTimeout = () => {
    if (this.shakeTimeout) {
      clearTimeout(this.shakeTimeout);
      this.shakeTimeout = null;
    }
  };

  renderForm = () => {
    const { form } = this.props;
    const { isFormOpen, isShaking } = this.state;
    return (
      <FormContainer
        ref={(ref) => {
          this.formContainerRef = ref;
        }}
        isHidden={!isFormOpen}
        isShaking={isShaking}
      >
        {form}
      </FormContainer>
    );
  };

  renderListItem = (connection) => (
    <ListItem
      key={connection.id}
      person={connection.person}
      activitySummary={connection.activitySummary}
      defaultAvatarIsGenerated={this.props.defaultAvatarIsGenerated}
      renderMenuList={this.props.getRenderMenuList(connection)}
      renderProfileCard={this.props.renderProfileCard}
    />
  );

  renderPendingRequestsSubList = (pendingRequestConnections) => {
    if (pendingRequestConnections.length === 0) { return null; }
    return (
      <React.Fragment>
        <SubHeader>Pending Invitations</SubHeader>
        {pendingRequestConnections.map(this.renderListItem)}
      </React.Fragment>
    );
  };

  renderListItems = () => {
    const { connections } = this.props;
    const pendingRequestConnections = connections.filter(
      ({ activitySummary }) =>
        activitySummary.type ===
        CONNECTION_ACTIVITY_TYPE.REQUESTED_LONGFORM_FEEDBACK
    );
    const nonPendingRequestConnections = connections.filter(
      ({ activitySummary }) =>
        activitySummary.type !==
        CONNECTION_ACTIVITY_TYPE.REQUESTED_LONGFORM_FEEDBACK
    );
    pendingRequestConnections.sort((b, a) => {
      const aDate = new Date(a.activitySummary.time);
      const bDate = new Date(b.activitySummary.time);
      return aDate - bDate;
    });
    return (
      <ListItemsContainer>
        {nonPendingRequestConnections.map(this.renderListItem)}
        {this.renderPendingRequestsSubList(pendingRequestConnections)}
      </ListItemsContainer>
    );
  };

  renderInputFormButton = () => (
    <ButtonNew link icon="add" onClick={this.handleOpenInputForm} />
  );

  renderHeader = () => (
    <ListHeadingWrapper>
      <ListHeading>
        <div>Your Peers</div>
        <HeaderActionContainer>
          {this.renderInputFormButton()}
        </HeaderActionContainer>
      </ListHeading>
    </ListHeadingWrapper>
  );

  renderBody = () => {
    const { connections } = this.props;
    if (connections.length === 0) { return <EmptyList />; }
    return this.renderListItems();
  };

  render() {
    return (
      <Root>
        {this.renderHeader()}
        {this.renderForm()}
        {this.renderBody()}
      </Root>
    );
  }
}

List.propTypes = {
  connections: PropTypes.arrayOf(Connection).isRequired,
  defaultAvatarIsGenerated: PropTypes.bool,
  renderProfileCard: PropTypes.func.isRequired,
  getRenderMenuList: PropTypes.func.isRequired,
  form: PropTypes.node.isRequired,
};

export default List;
