import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  align-self: center;
`;

const SingleColumnVerticalCenter = ({
  content,
}) => (
  <Container>
    {content}
  </Container>
);

SingleColumnVerticalCenter.propTypes = {
  content: PropTypes.node.isRequired,
};

export default SingleColumnVerticalCenter;
