import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Default from '../Default';
import { CloseButton, DismissButton } from './common/components';
import { bannerTypes } from '../../propTypes';
import theme from '@matterapp/matter-theme';

const baseFlexStyles = `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  position: relative;
`;

const LeftColumn = styled.div`
  display: none;
  ${() => theme.media.medium`
    display: block;
    flex: 1 1 auto;
    margin: auto 0;
    max-width: 58%;
  `}
`;

const RightColumn = styled.div`
  ${baseFlexStyles}
  ${() => theme.media.medium`
    flex: 1 1 auto;
    max-width: 42%;
  `}
`;

const ImageContainer = styled.div`
  ${baseFlexStyles}
`;

const ActionsContainer = styled.div`
  ${baseFlexStyles}
  ${({ hasActions }) => theme.media.medium`
    ${hasActions && `margin-top: ${theme.spacing.single};`}
  `}
`;

const HeaderContainer = styled.div`
  ${() => `
    font-size: ${theme.fontSize.S};
    text-align: center;
    margin-bottom: ${theme.spacing.double};
  `}
  ${ImageContainer} & {
    display: block;
    z-index: 10;
  }
  ${() => theme.media.medium`
    text-align: left;
    margin-top: ${theme.spacing.doubleAndHalf};
    &:not(:empty) {
      margin-right: ${theme.spacing.single};
    }
    ${ImageContainer} & {
      display: none;
    }
  `}
`;

const MainContainer = styled.div`
  ${() => `
    font-size: ${theme.fontSize.S};
    text-align: center;
    margin-top: ${theme.spacing.single};
  `}
  ${ActionsContainer} & {
    display: block;
    ${({ hasActions }) =>
    hasActions && `margin-bottom: ${theme.spacing.single};`}
  }
  ${() => theme.media.medium`
    font-size: ${theme.fontSize.M};
    text-align: left;
    margin-bottom: ${theme.spacing.double};
    &:not(:empty) {
      margin-right: ${theme.spacing.single};
    }
    ${ActionsContainer} & {
      display: none;
    }
  `}
`;

const WelcomeCTA = (props) => {
  const {
    actions,
    children,
    content,
    dismissButtonLabel,
    header,
    image,
    onClickDismiss,
    ...otherProps
  } = props;
  const headerContent = <HeaderContainer>{header}</HeaderContainer>;

  const mainContent = (
    <MainContainer hasActions={!!actions}>{content}</MainContainer>
  );

  const imageContent = (
    <ImageContainer>
      {headerContent}
      {image}
    </ImageContainer>
  );

  const actionsContent = (
    <ActionsContainer hasActions={!!actions}>
      {mainContent}
      {actions}
      {onClickDismiss && (
        <DismissButton label={dismissButtonLabel} onClick={onClickDismiss} />
      )}
    </ActionsContainer>
  );

  return (
    <Default {...otherProps}>
      <Container>
        <LeftColumn>
          {headerContent}
          {mainContent}
        </LeftColumn>
        <RightColumn>
          {imageContent}
          {actionsContent}
        </RightColumn>
        {onClickDismiss && <CloseButton onClick={onClickDismiss} />}
        {children}
      </Container>
    </Default>
  );
};

WelcomeCTA.propTypes = {
  /** The actions content area. */
  actions: PropTypes.node,
  /** Additional content. */
  children: PropTypes.node,
  /** The main content of the CTA. */
  content: PropTypes.node,
  /** Label of the dismiss button. */
  dismissButtonLabel: PropTypes.string,
  /** The header content. */
  header: PropTypes.node,
  /** The image content. Scales the height of the banner if no actions are provided. */
  image: PropTypes.any,
  /** Callback when dismiss button is clicked. If passed in, dismiss and close button will render. */
  onClickDismiss: PropTypes.func,
};

WelcomeCTA.defaultProps = {
  dismissButtonLabel: 'Dismiss',
};

WelcomeCTA.bannerTypes = bannerTypes;

export default WelcomeCTA;
