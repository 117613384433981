import React, { useContext } from 'react';
import { mixpanel } from 'libs/tracking';
import { ProfileCard } from '@matterapp/matter-ui';
import UserContext from 'context/UserContext/UserContext';

export const EVENT_NAME = 'mini-profile';
export const GATHER_EVENT = `${EVENT_NAME}:gather-feedback`;

const handleTrackClick = {
  pending: mixpanel.createClickEventSender(
    `${EVENT_NAME}:feedback-pending:clicked`
  ),
};

export default function MiniProfile(props) {
  const {
    canClickOnAvatar = true,
    children,
    className,
    feedbackCounts,
    isCompact,
    miniProfileData,
    pendingRequestsPath,
    profileUrl,
    hidePending,
  } = props;
  const { currentUser } = useContext(UserContext);
  const { showZeroWorkspaces } = miniProfileData;
  
  if (!currentUser) { return null; }

  const miniProfileProps = {
    avatarUrl: canClickOnAvatar ? profileUrl : null,
    className,
    feedbackCounts,
    hidePending: showZeroWorkspaces || hidePending,
    isCompact,
    onClickPendingRequests: handleTrackClick.pending,
    pendingRequestsPath,
    user: currentUser,
  };

  return children ? (
    <ProfileCard.MiniProfile {...miniProfileProps}>
      {children}
    </ProfileCard.MiniProfile>
  ) : (
    <ProfileCard.MiniProfile {...miniProfileProps} />
  );
}
