import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconNew } from '../Icon';
import { Clickable } from '../Clickable/Clickable';
import theme from '@matterapp/matter-theme';

const Tab = styled(Clickable)`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  width: 100%;
  color: ${theme.colors.blacks[50]};
  border-bottom: 1px solid ${theme.colors.blacks[10]};
  height: 57px;
  white-space: nowrap;

  ${({ isActive }) => isActive && `
    color: ${theme.colors.blacks[80]};
    font-weight: ${theme.fontWeight.bold};
  `}

  ${({ isInactive }) => isInactive && `
    color: ${theme.colors.blacks[30]};
    font-weight: ${theme.fontWeight.regular};
    user-select: none;
    pointer-events: none;
  `}

  &:hover {
    color: ${theme.colors.purples[50]};

    ${({ isInactive }) => isInactive && `
      color: ${theme.colors.blacks[30]};
      font-weight: ${theme.fontWeight.regular};
      user-select: none;
      cursor: default;
    `}
  }
`;

const MobileFilterBar = styled(Clickable)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding: 0 ${theme.spacing.singleAndHalf};
  color: ${theme.colors.blacks[80]};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  svg path {
    fill: ${theme.colors.blacks[80]};
    stroke: ${theme.colors.blacks[80]};
  }
  &:active {
    color: ${theme.colors.purples[50]};
    svg path {
      fill: ${theme.colors.purples[50]};
      stroke: ${theme.colors.purples[50]};
    }
  }

  ${theme.media.S`
    display: none;
  `};
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  z-index: 99;
  ${theme.media.S`
    display: none;
  `};
`;

const Overlay = styled.div`
  background: ${theme.colors.black};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity ${theme.transitions.times.default};
  z-index: 1;

  ${({ isOpen }) =>
    isOpen
      ? `opacity: 0.48;
        pointer-events: auto;
  `
      : `opacity: 0;
        pointer-events: none;
  `};
`;

const MobileBottomSheet = styled.div`
  position: absolute;
  padding: ${theme.spacing.threeQuarters} ${theme.spacing.singleAndHalf};
  background: ${theme.colors.white};
  min-height: 300px;
  bottom: 0;
  width: 100%;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transform: translateY(${({ isOpen }) => (isOpen ? '0%' : '100%')});
  transition: 
    transform ${theme.transitions.times.default},
    opacity 0s
    ${({ isOpen }) => (isOpen ? '' : theme.transitions.times.default)};
  z-index: 5;
`;

const CloseIconContainer = styled(Clickable)`
  position: absolute;
  bottom: 320px;
  right: ${theme.spacing.single};
  z-index: 10;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  svg path {
    fill: ${theme.colors.white};
  }
`;

const CloseIcon = styled(IconNew).attrs({ name: 'close' })``;
const KarotDownIcon = styled(IconNew).attrs({ name: 'karotDownSmall'})``;

export default class FilterList extends React.Component {
  static propTypes = {
    tabsList: PropTypes.arrayOf(
      PropTypes.shape({
        tabName: PropTypes.string.isRequired,
        requestLength: PropTypes.number,
      })
    ).isRequired,
    activeTab: PropTypes.number.isRequired,
    onActivateTab: PropTypes.func.isRequired,
    activeTabName: PropTypes.string.isRequired,
    isMobile: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleCloseBottomSheet = () => {
    this.setState({ isOpen: false });
  }

  renderTab({ tabName, requestLength, onActivate, ...props }) {
    const handleClickOnFilterTab = () => {
      onActivate();
      this.setState({ isOpen: false });
    };
    const requestLengthText = requestLength > 0 ? requestLength : null;
    
    return (
      <Tab
        data-rc="filter-list-tab"
        key={tabName}
        className="filterListItem"
        onClick={handleClickOnFilterTab}
        isInactive={requestLength === 0}
        {...props}
      >
        {tabName}&nbsp;&nbsp;{requestLengthText}
      </Tab>
    );
  }
  
  renderTabsList() {
    const { tabsList, activeTab, onActivateTab } = this.props;

    return (
      tabsList.map((item, index) => (
        this.renderTab({
          index,
          onActivate: () => onActivateTab(index, item.tabName),
          isActive: activeTab === index,
          tabName: item.tabName,
          requestLength: item.requestLength,
        })
      ))
    );
  }

  renderMobileComponent() {
    return (
      <MobileFilterBar 
        className="MobileFilterBar" 
        onClick={() => this.setState({ isOpen: true })}
      >
        {this.props.activeTabName}
        <KarotDownIcon />
      </MobileFilterBar>
    );
  }

  renderBottomSheet() {
    const { isOpen } = this.state;
    return (
      <Container 
        isOpen={isOpen}
        className="BottomSheetContainer"
      >
        <Overlay 
          isOpen={isOpen}
          onClick={this.handleCloseBottomSheet}
          data-rc="filter-list-overlay"
        />
        <CloseIconContainer 
          isOpen={isOpen}
          onClick={this.handleCloseBottomSheet}
          data-rc="filter-list-close"
        >
          <CloseIcon />
        </CloseIconContainer>
        <MobileBottomSheet isOpen={isOpen}>
          {this.renderTabsList()}
        </MobileBottomSheet>
      </Container>
    );
  }

  render() {
    if (!this.props.isMobile) {
      return this.renderTabsList();
    }
    return (
      <>
        {this.renderMobileComponent()}
        {this.renderBottomSheet()}
      </>
    );
  }
}