import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import UserSettingsNotificationsPage from '../components/UserSettingsNotificationsPage';
import {
  SET_NOTIFICATION_SETTING_MUTATION,
} from 'graphql-queries/queries';


const withData = compose(
  graphql(SET_NOTIFICATION_SETTING_MUTATION, {
    name: 'setNotificationSettingMutation',
  })
);

export default withData(UserSettingsNotificationsPage);
