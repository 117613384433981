import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 144 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M140 72C140 109.555 109.555 140 72 140C34.4446 140 4 109.555 4 72C4 34.4446 34.4446 4 72 4C109.555 4 140 34.4446 140 72Z"
      fill="#CCE3FF"
      stroke="#343799"
      strokeWidth="8"
    />
    <path
      d="M72 120C91.8823 120 108 117.314 108 114C108 110.686 91.8823 108 72 108C52.1177 108 36 110.686 36 114C36 117.314 52.1177 120 72 120Z"
      fill="#A2CCFF"
    />
    <path
      d="M50.4856 29L53.6489 35.4056L60.7168 36.4337L55.6061 41.4257L56.8121 48.4639L50.4856 45.1425L44.1591 48.4639L45.375 41.4257L40.2545 36.4337L47.3224 35.4056L50.4856 29Z"
      fill="white"
    />
    <path
      d="M104.379 68.594L103.126 67.7127L102.079 64.5947C101.627 63.267 100.835 62.0833 99.7755 61.169L98.9195 60.4258C97.6988 59.3688 96.0605 58.9306 94.4738 59.2385L92.7161 59.5808L82.1722 60.9767C80.8555 61.1503 79.6803 60.1421 79.6548 58.8112L79.6059 56.1826C79.5164 51.355 78.4129 46.6026 76.3609 42.2304L76.3107 42.1281C75.2195 39.8 73.0767 38.1357 70.5503 37.6513L70.4651 37.6366C68.3459 37.2318 66.4648 39.0593 66.8093 41.1892L66.9772 42.2408C67.5615 45.8651 67.522 49.5649 66.8598 53.1746L66.7826 53.6023C66.2867 56.302 65.4447 58.9276 64.2786 61.417L58.7101 73.3126L50.4768 78.9374L58.7009 104.248L70.1151 103.209L76.6934 104.712C79.9827 105.445 83.3906 105.316 86.5952 104.275L101.231 99.5199L103.573 98.7589C104.757 98.3741 105.73 97.5071 106.279 96.3922L106.296 96.34C107.002 94.9153 107.223 93.2969 106.931 91.7346L106.54 89.6451C106.456 89.2022 106.532 88.7452 106.752 88.3518L107.305 87.3669C108.387 85.4428 108.544 83.1331 107.732 81.0787L106.409 77.7369L106.895 76.1932C107.355 74.8443 107.319 73.4025 106.878 72.0455L106.864 72.0033C106.425 70.6247 105.577 69.4125 104.379 68.594Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M54.0547 74.6683L43.2635 78.1746C41.1625 78.8573 40.0127 81.1139 40.6953 83.2149L48.9345 108.572C49.6172 110.673 51.8738 111.823 53.9748 111.141L64.7659 107.634C66.867 106.952 68.0168 104.695 67.3341 102.594L59.0949 77.2365C58.4123 75.1355 56.1557 73.9857 54.0547 74.6683Z"
      fill="#4F52B1"
    />
    <path
      d="M59.4979 91.5004L48.7068 95.0066C46.6058 95.6893 45.456 97.9459 46.1386 100.047L48.8771 108.475C49.5598 110.576 51.8164 111.726 53.9174 111.043L64.7085 107.537C66.8095 106.854 67.9593 104.598 67.2767 102.497L64.5382 94.0685C63.8555 91.9675 61.5989 90.8177 59.4979 91.5004Z"
      fill="#343799"
    />
    <path
      d="M93.0273 31L95.1946 35.2806L100.027 35.9616L96.5273 39.2937L97.3494 44L93.0273 41.7746L88.6928 44L89.5273 39.2937L86.0273 35.9616L90.8601 35.2806L93.0273 31Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="55.1695"
        y1="56.333"
        x2="111.235"
        y2="83.6657"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE7E" />
        <stop offset="1" stopColor="#EE8D11" />
      </linearGradient>
    </defs>
  </svg>
);

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Icon.defaultProps = {
  width: 32,
  height: 32,
};

export default Icon;
