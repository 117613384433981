import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { containerStylesWithImage, headingStylesWithImage } from '../sharedStyles';
import planet from './planet.png';
import theme from '@matterapp/matter-theme';

const HEADER_TEXT = 'Gather fresh new feedback from people you know.';

const Background = styled.img.attrs({
  src: planet
})`
  position: absolute;
  bottom: 0;
  right: 50%;
  height: 194px;
  transform: translateX(50%);

  ${theme.media.M`
    right: -${theme.spacing.quad};
    transform: translateX(0%);
  `}
`;

const Container = styled.div`
  ${containerStylesWithImage}
  background-color: ${() => theme.colors.greens[10]};
`;

const Heading = styled.div`
  ${headingStylesWithImage}
  ${theme.media.M`
    max-width: 20vw;
  `}
  ${theme.media.L`
    max-width: 18vw;
  `}
`;

const ConnectYourAccountsBanner = ({ className }) => (
  <Container className={className}>
    <Background />
    <Heading>{HEADER_TEXT}</Heading>
  </Container>
);

ConnectYourAccountsBanner.propTypes = {
  className: PropTypes.string,
};

export default ConnectYourAccountsBanner;
