import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../SVG';

const DEFAULT_SIZE = 26;

const Icon = ({ fill, size }) => (
  <SVG size={size} viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      fill={fill}
      d="M8.5,13.455c1.089,0,2.292-1.072,2.292-2.816
  c0-1.744-1.203-2.816-2.292-2.816s-2.292,1.072-2.292,2.816C6.208,12.383,7.411,13.455,8.5,13.455z M6.501,16.034H8.5h1.999h0.168
  c2.393,0,4.333,1.932,4.333,4.316v1.079c0,0.596-0.485,1.079-1.083,1.079H3.083C2.485,22.509,2,22.026,2,21.43v-1.079
  c0-2.384,1.94-4.316,4.333-4.316H6.501z M6.865,14.534c-1.276-0.695-2.157-2.178-2.157-3.896c0-2.384,1.698-4.316,3.792-4.316
  c2.094,0,3.792,1.933,3.792,4.316c0,1.717-0.881,3.2-2.157,3.896c-0.495,0.27-1.05,0.421-1.635,0.421S7.36,14.804,6.865,14.534z
   M13.5,21.009v-0.658c0-1.55-1.263-2.816-2.833-2.816H6.333c-1.57,0-2.833,1.266-2.833,2.816v0.658H13.5z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      fill={fill}
      d="M18.75,5c0.345,0,0.625,0.28,0.625,0.625v2.5h2.5
  c0.345,0,0.625,0.28,0.625,0.625s-0.28,0.625-0.625,0.625h-2.5v2.5c0,0.345-0.28,0.625-0.625,0.625s-0.625-0.28-0.625-0.625v-2.5
  h-2.5C15.28,9.375,15,9.095,15,8.75s0.28-0.625,0.625-0.625h2.5v-2.5C18.125,5.28,18.405,5,18.75,5z"
    />
  </SVG>
);

Icon.defaultProps = {
  size: DEFAULT_SIZE,
};

Icon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default Icon;
