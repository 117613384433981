import React from 'react';
import { Text, Form, InputPhone } from '@matterapp/matter-ui';
import { Field as ReduxFormField } from 'redux-form';
import PropTypes from 'prop-types';
import { PLACEHOLDER_AUTH_PHONE_NUMBER } from '../../copy-consts';

const PhoneNumberField = class extends React.Component {
  renderLabel = () => {
    if (!this.props.label) { return null; }
    return (
      <Text
        block
        color="grey"
        textStyle={500}
        textAlign="center"
        marginBottom={48}
      >
        {this.props.label}
      </Text>
    );
  };

  renderError = (reduxFormFieldProps) => {
    if (!(reduxFormFieldProps.meta.submitFailed && this.props.isShowError))
    {return null;}
    return <Form.Error errorString={reduxFormFieldProps.meta.error} />;
  };

  renderField = (reduxFormFieldProps) => (
    <React.Fragment>
      {this.renderLabel()}
      <InputPhone
        placeholder={PLACEHOLDER_AUTH_PHONE_NUMBER}
        autoFocus={this.props.autoFocus}
        marginBottom={this.props.marginBottom}
        style={{ textAlign: 'center' }}
        fluid
        {...reduxFormFieldProps.input}
      />
      {this.renderError(reduxFormFieldProps)}
    </React.Fragment>
  );

  render = () => (
    <ReduxFormField
      name="phoneNumberOrEmail"
      type="tel"
      component={this.renderField}
    />
  );
};

PhoneNumberField.propTypes = {
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  isShowError: PropTypes.bool,
  marginBottom: PropTypes.number,
};

PhoneNumberField.defaultProps = {
  label: '',
  autoFocus: false,
  isShowError: true,
  marginBottom: 0,
};

export default PhoneNumberField;
