const onImagesLoaded = ({
  images,
  callback,
}) => {
  let numCompleted = 0;
  if (images.length === 0) { callback(); }
  images.forEach((src) => {
    const image = new Image();
    image.src = src;
    const onLoad = () => {
      numCompleted += 1;
      if (numCompleted === images.length) {
        callback();
      }
    };
    image.onload = onLoad;
    image.onabort = onLoad;
    image.onerror = onLoad;
  });
};

export default onImagesLoaded;
