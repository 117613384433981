import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import getRewardsCatalog from 'graphql-queries/queries/rewards/getRewardsCatalog';
import { Button, Loader, Grid, Panel } from '@matterapp/matter-ui';
import { border, colors, lineHeight, font, spacing, media } from '@matterapp/matter-theme';
import { REWARD_QUANTITY_TYPES } from '../../rewards-manager/consts';
import { VALUE_TYPE, ALL_COUNTRIES_ITEM, REWARD_TYPES } from './consts';
import { disabledCatalog } from '../data/disabledCatalog';
import { duplicateCountriesList, duplicateBrands } from '../data/duplicates';

const IMAGE_HEIGHT = 110;
const BOX_SHADOW = 'box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2)';

const StyledGrid = styled(Grid)`
  margin: 0 ${spacing.half};
  ${media.M`
    margin: 0;
  `}
`;

const LoaderContainer = styled.div`
  padding: ${spacing.singleAndHalf};
  position: relative;
  min-height: 160px;
  background: ${colors.white};
  max-width: 1000px;
  border-bottom: 1px solid ${colors.blacks[10]};
  border-radius: ${border.radius.L};
`;

const RewardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${colors.white};
  border: 1px solid ${colors.white};
  box-shadow: ${BOX_SHADOW};
  border-radius: ${border.radius.L};
  overflow: hidden;
  max-height: 255px;
`;

const StyledImg = styled.img`
  max-width: 100%;
  transform: scale(1.06);
  clip-path: inset(0 0 3% 0);
`;

const RewardDetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: ${spacing.single};
  font-size: ${font.size.XS};
  line-height: ${lineHeight.XS};
  color: ${colors.blacks[90]};
`;

const RewardName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Quantity = styled.div`
  font-size: ${font.size.XS};
  line-height: ${lineHeight.XS};
  color: ${colors.blacks[50]};
`;

const CoinPrice = styled.div`
  font-size: ${font.size.base};
  font-weight: ${font.weight.bold};
  line-height: ${lineHeight.XS};
  color: ${colors.blues[50]};
  margin-top: ${spacing.quarter};
`;

const SelectButton = styled(Button)`
  margin-top: ${({ isCustom }) => isCustom ? spacing.single : spacing.double};
  width: 100%;
`;

const EmptyRewardList = styled(Panel.Base)`
  height: 200px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  box-shadow: ${BOX_SHADOW};
  color: ${colors.blacks[40]};
  font-size: ${font.size.S};
  font-weight: ${font.weight.medium};
`;

const getCoinAmount = (cost, exchangeRate) => {
  const currencyInUSD = cost / exchangeRate;
  return (Math.round(currencyInUSD * 10) || 0).toLocaleString('en-US');
};

const RewardsGrid = ({ tenantId, isRewardsActive, selectedCountry, selectedRewardType, onClickReward }) => {
  const { loading, data } = useQuery(getRewardsCatalog, {
    variables: {
      tenantId,
      country: selectedCountry
    },
    skip: !isRewardsActive,
    fetchPolicy: 'network-only'
  });

  const [rewardsCatalog, setRewardsCatalog] = useState([]);


  function getFilteredCatalog() {
    let filteredList = data?.rewardsCatalog || [];

    if (selectedCountry !== REWARD_TYPES.ALL && selectedRewardType !== REWARD_TYPES.ALL) {
      filteredList = data?.rewardsCatalog.filter(reward => (reward.country === selectedCountry || reward.rewardType === REWARD_TYPES.CUSTOM) && reward.rewardType === selectedRewardType);
    } else if (selectedRewardType ===  REWARD_TYPES.ALL && selectedCountry !== ALL_COUNTRIES_ITEM.value) {
      filteredList = data?.rewardsCatalog.filter(reward => reward.country === selectedCountry || reward.rewardType === REWARD_TYPES.CUSTOM);
    } else if (selectedCountry === ALL_COUNTRIES_ITEM.value && selectedRewardType !==  REWARD_TYPES.ALL) {
      filteredList = data?.rewardsCatalog.filter(reward => reward.rewardType === selectedRewardType);
    }

    return filteredList || [];
  } 

  useEffect(() => {
    if (!isRewardsActive) {
      setRewardsCatalog(disabledCatalog);
    } 
  }, []);

  useEffect(() => {
    if (isRewardsActive) {
      setRewardsCatalog(getFilteredCatalog());
    }
  }, [data, isRewardsActive]);

  useEffect(() => {
    if (isRewardsActive) {  
      setRewardsCatalog(getFilteredCatalog());
    }
  }, [selectedCountry, selectedRewardType]);

  if (isRewardsActive && (loading || !data)) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <>
      {rewardsCatalog.length ? <StyledGrid
        isResponsive
        columns={3}
        responsiveColumns={[2, 2, 3, 3, 3]}
        spacing={16}
      >
        {rewardsCatalog.map((reward, index) => {
          if (!isRewardsActive) {
            const { brandId, name, image } = reward;
            return (
              <RewardItem key={`${brandId}-${name}-${index}`}>
                <StyledImg 
                  src={image}
                  alt='reward-img'
                />
              </RewardItem>
            );
          } else {
            if (reward.rewardType === REWARD_TYPES.CUSTOM) {
              const {
                id,
                name,
                imageUrl,
                value,
                quantity,
                quantityType
              } = reward;
              return (
                <RewardItem key={`${id}-${name}-${index}`}>
                  <StyledImg 
                    src={imageUrl}
                    alt='reward-img'
                    height={IMAGE_HEIGHT}
                  />
                  <RewardDetailsCard>
                    <RewardName>{name}</RewardName>
                    <Quantity>Qty Available: {quantityType === REWARD_QUANTITY_TYPES.LIMITED ? quantity.toLocaleString('en-US') : 'Unlimited'}</Quantity>
                    <CoinPrice><span role="img" aria-label="matter-coin">Ⓜ️</span> {value.toLocaleString('en-US')} coins</CoinPrice>
                    <SelectButton isCustom size={'S'} color={'black'} onClick={() => onClickReward(reward)}>Select</SelectButton>
                  </RewardDetailsCard>
                </RewardItem>
              );
            } else {
              const {
                brandId,
                name,
                items,
                image,
                valueType,
                exchangeRate,
                country,
                rewardType,
                quantity,
                quantityType
              } = reward;

              const firstItem = items[0];
              const lastItem = items[items.length-1];
  
              let brandName = name;
              if (duplicateBrands.includes(name)) {
                brandName = `${name} ${duplicateCountriesList[country]}`;
              }
  
              let costRange;

              if (valueType === VALUE_TYPE.VARIABLE) {
                const minCoins = getCoinAmount(firstItem.minValue, exchangeRate);
                const maxCoins = getCoinAmount(firstItem.maxValue, exchangeRate);
                costRange = `${minCoins} - ${maxCoins}`;
              } else if (items.length === 1) {
                costRange = firstItem.coinsValue;
              } else {
                costRange = `${firstItem.coinsValue} - ${lastItem.coinsValue.toLocaleString('en-US')}`;
              }
  
              return (
                <RewardItem key={`${brandId}-${name}-${index}`}>
                  <StyledImg 
                    src={image}
                    alt='reward-img'
                    height={IMAGE_HEIGHT}
                  />
                  <RewardDetailsCard>
                    <RewardName>{brandName}</RewardName>
                    {rewardType === REWARD_TYPES.CUSTOM && <Quantity>Qty Available: {quantityType === REWARD_QUANTITY_TYPES.LIMITED ? quantity : 'Unlimited'}</Quantity>}
                    <CoinPrice><span role="img" aria-label="matter-coin">Ⓜ️</span> {costRange} coins</CoinPrice>
                    <SelectButton size={'S'} color={'black'} onClick={() => onClickReward(reward)}>Select</SelectButton>
                  </RewardDetailsCard>
                </RewardItem>
              );
            }
          }
        })}
      </StyledGrid> : <EmptyRewardList>No Rewards Available</EmptyRewardList>}
    </>
  );
};

RewardsGrid.propTypes = {
  tenantId: PropTypes.string,
  isRewardsActive: PropTypes.bool,
  selectedCountry: PropTypes.string,
  selectedRewardType: PropTypes.string,
  onClickReward: PropTypes.func,
};

export default RewardsGrid;