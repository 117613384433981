import styled from 'styled-components';
import Button from './Default';
import Vivid from './PrimaryVivid';
import { BUTTON_GROUP_ROLE } from '../Group';
import theme, { createDataRole } from '@matterapp/matter-theme';

const getColorStyles = ({ color, disabled, useGrayscaleDisabled }) => {
  const baseColor = theme.colors[`${color}s`] || theme.colors.blues;
  const groupStyles = `
    ${createDataRole(BUTTON_GROUP_ROLE)} > & {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  `;

  if (!disabled) {
    if (color === 'default' || !color) {
      return `
        background: ${baseColor[5]};
        ${groupStyles}
        &:hover {
          background: ${baseColor[10]};
        }
      `;
    } else {
      return `
        background: ${baseColor[5]};
        ${groupStyles}
        &:hover {
          background: ${baseColor[10]};
        }
        &:active {
          background: ${baseColor[20]};
        }
      `;
    }
  }
  return `
    background: ${useGrayscaleDisabled ? theme.colors.blacks[5] : baseColor[1]};
    color: ${useGrayscaleDisabled ?  theme.colors.blacks[40] : baseColor[10]};
    ${groupStyles}
  `;
};

const PrimaryButton = styled(Button).attrs(({ color }) => ({
  color: color || Button.buttonColors.BLUE,
}))`
  &:not(:active) {
    border-color: transparent;
  }
  ${getColorStyles}
`;

PrimaryButton.Vivid = Vivid;

export default PrimaryButton;
