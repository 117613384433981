import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.343 5.648a6.96 6.96 0 0 0-2.423-1.004L12.25 2.5h-1.5l-.67 2.144a6.96 6.96 0 0 0-2.423 1.004L5.665 4.605l-1.06 1.06 1.043 1.992a6.96 6.96 0 0 0-1.004 2.423l-2.144.67v1.5l2.144.67a6.96 6.96 0 0 0 1.004 2.423l-1.043 1.992 1.06 1.06 1.992-1.043a6.959 6.959 0 0 0 2.423 1.004l.67 2.144h1.5l.67-2.144a6.96 6.96 0 0 0 2.423-1.004l1.992 1.043 1.06-1.06-1.043-1.992a6.96 6.96 0 0 0 1.004-2.423l2.144-.67v-1.5l-2.144-.67a6.96 6.96 0 0 0-1.004-2.423l1.043-1.992-1.06-1.06-1.992 1.043zM11.5 14.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm8.58 1.39l-.135 2.311a5.026 5.026 0 0 0-1.65 1.594l-2.315.055-.415 1.44 1.935 1.275c.01.802.21 1.559.555 2.227l-1.11 2.033 1.04 1.075 2.071-1.037a4.972 4.972 0 0 0 2.203.631l1.206 1.981 1.455-.365.135-2.311a5.026 5.026 0 0 0 1.65-1.594l2.315-.055.415-1.44-1.935-1.275a4.979 4.979 0 0 0-.555-2.227l1.11-2.033-1.04-1.075-2.071 1.037a4.972 4.972 0 0 0-2.203-.631l-1.206-1.981-1.455.365zm2.42 8.61a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
