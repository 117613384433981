import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7071 11.7071C20.3166 12.0976 19.6834 12.0976 19.2929 11.7071L13 5.41421L13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18L11 5.41421L4.70711 11.7071C4.31658 12.0976 3.68342 12.0976 3.29289 11.7071C2.90237 11.3166 2.90237 10.6834 3.29289 10.2929L11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L20.7071 10.2929C21.0976 10.6834 21.0976 11.3166 20.7071 11.7071Z"
      fill="black"
    />
    <rect x="3" y="21" width="18" height="2" rx="1" fill={fill} />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
