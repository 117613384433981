import React from 'react';
import { Route } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import FeedbackPage from 'routes/pages/feedback/components/FeedbackPage';

const isRenderFeedback = ({ userStatus }) =>
  userStatus.isLoggedIn && userStatus.isOnboarded;

export const FeedbackMain = (
  <Route
    exact
    path={Resources.feedbackMain.pattern}
    element={(
      <PrivateRoute
        isRender={isRenderFeedback}
      >
        <FeedbackPage />
      </PrivateRoute>
    )}
  />
);

export const FeedbackMainFallback = (
  <Route
    exact
    path={Resources.feedbackMainFallback.pattern}
    element={(
      <PrivateRoute
        isRender={isRenderFeedback}
      >
        <FeedbackPage />
      </PrivateRoute>
    )}
  />
);
