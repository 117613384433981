import React from 'react';
import BasePath from './BasePath';

const DEFAULT_HEIGHT = 220;
const PATH_LENGTH = 201;

const RightToRight1 = React.forwardRef((props, ref) => (
  <BasePath
    {...props}
    ref={ref}
    height={DEFAULT_HEIGHT}
    pathLength={PATH_LENGTH}
    pathCode={
      'M449.5,9c0,49.974,0,150.538,0,200.5'
    }
  />
));

export default RightToRight1;
