import React from 'react';
import PropTypes from 'prop-types';
import TwoChoices from './TwoChoices';
import * as ChoiceButtons from './ChoiceButtons';

const YesNo = (props) => {
  const {
    buttonComponent,
    children,
    onClickYes,
    onClickNo,
    yesLabel,
    noLabel,
    ...otherProps
  } = props;

  const twoChoicesProps = {
    buttonOneComponent: ChoiceButtons.YesButton,
    buttonTwoComponent: ChoiceButtons.NoButton,
    onClickButtonOne: onClickYes,
    onClickButtonTwo: onClickNo,
    buttonOneLabel: yesLabel,
    buttonTwoLabel: noLabel,
  };

  return (
    <TwoChoices buttonComponent={buttonComponent} {...twoChoicesProps} {...otherProps}>
      {children}
    </TwoChoices>
  );
};

YesNo.propTypes = {
  /** Override default button component. */
  buttonComponent: PropTypes.elementType,
  /** Additional children to show between yes and no buttons. */
  children: PropTypes.node,
  /** Additional style class. */
  className: PropTypes.string,
  /** Override default no button label. */
  noLabel: PropTypes.string,
  /** Callback when no button is clicked. */
  onClickNo: PropTypes.func.isRequired,
  /** Callback when yes button is clicked. */
  onClickYes: PropTypes.func.isRequired,
  /** Override default yes button label. */
  yesLabel: PropTypes.string,
};

export default YesNo;