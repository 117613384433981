import React from 'react';
import { Clickable } from '../Clickable/Clickable';
import styled from 'styled-components';
import { IconNew as Icon } from '../Icon';
import theme from '@matterapp/matter-theme';

const AddIcon = styled(Icon).attrs(() => ({ name: 'addLarge' }))``;
const Button = styled(Clickable)`
  height: 80px;
  width: 100%;

  & svg {
    color: ${theme.colors.blacks[40]};
    path {
      fill: currentColor;
      transition: color ${theme.transitions.times.default};
    }
  }
  &:hover svg {
    color: ${theme.colors.purples[40]};
  }
  &:active svg {
    color: ${theme.colors.purples[60]};
  }
`;

const ListAddButton = (props) => (
  <Button {...props}>
    <AddIcon />
  </Button>
);

export default ListAddButton;
