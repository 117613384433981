import React from 'react';
import { Skill } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import { Field as ReduxFormField } from 'redux-form';

const removeExtraInternalWhiteSpace = (s) =>
  s ? s.replace(/\s{2,}/g, ' ') : s;

const TextareaField = class extends React.PureComponent {
  getOnChange = (onChange) => (e) => {
    const { value } = e.target;
    const computedValue = removeExtraInternalWhiteSpace(value);
    onChange(computedValue);
  };

  renderField = (reduxFormFieldProps) => (
    <Skill.Generator.Form.Textarea
      {...reduxFormFieldProps.input}
      onChange={this.getOnChange(reduxFormFieldProps.input.onChange)}
      label={this.props.label}
      placeholder={this.props.placeholder}
      maxLength={this.props.maxLength}
      rows={this.props.rows}
      error={
        !reduxFormFieldProps.meta.submitFailed
          ? null
          : reduxFormFieldProps.meta.error
      }
    />
  );

  render = () => (
    <ReduxFormField
      name={this.props.name}
      type="text"
      component={this.renderField}
    />
  );
};

TextareaField.defaultProps = {
  autoFocus: false,
};

TextareaField.propTypes = {
  autoFocus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  rows: PropTypes.number.isRequired,
};

export default TextareaField;
