import styled from 'styled-components';
import { DATA_ROLE } from '../../../Modal/components/Footer/Footer';
import theme from '@matterapp/matter-theme';

const LEFT_PANEL_WIDTH = 320;
const MODAL_FOOTER_SELECTOR = `[data-role="${DATA_ROLE}"]`;
const TOP_BAR_HEIGHT = theme.spacing.quadruple;
const PADDING_MOBILE = theme.spacing.singleAndHalf;
const PADDING = theme.spacing.triple;
const PADDING_HALF = theme.spacing.singleAndHalf;

export const PanelHeaderContainer = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.double};
  align-items: center;
`;

export const PanelHeader = styled.div`
  color: ${theme.colors.blacks[90]};
  flex: 1 1 auto;
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.L}; 
  line-height: ${theme.lineHeight.XXL};
`;

export const PanelHeaderActions = styled.div`
`;

export const PanelSubHeader = styled.div`
  font-size: ${theme.fontSize.S}; 
  margin-bottom: ${theme.spacing.singleAndHalf};
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: 100%;
  margin-left: ${({ isLeftPanelActive }) =>
    isLeftPanelActive ? '0%' : '-100%'};
  overflow-y: auto;
  padding: 0 ${PADDING_MOBILE};
  border-radius: 0 0 ${theme.borderRadius.L} ${theme.borderRadius.L};
  transition: margin-left ${theme.transitions.times.default};

  & ${MODAL_FOOTER_SELECTOR} {
    margin: 0 -${PADDING_MOBILE};
  }

  ${theme.media.M`
    min-width: ${LEFT_PANEL_WIDTH}px;
    margin-left: 0 !important;
    padding: 0 ${PADDING_HALF} 0 ${PADDING};
  `}
`;

export const LeftPanelWrapper = styled.div`
  flex: 1 1 auto;

  ${({ dockFooter }) => theme.media.M`
    flex: ${dockFooter ? '1 1 auto' : 'none'};
  `}
`;

export const LeftPanelTopBar = styled.div`
  display: none;

  ${({ hasTopBar }) => theme.media.M`
    background: ${theme.colors.white};
    display: block;
    height: ${hasTopBar ? TOP_BAR_HEIGHT : 0};
    padding: ${hasTopBar ? theme.spacing.singleAndHalf : 0} 0;
    position: sticky;
    top: 0;
    z-index: 1;
  `}
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1;

  ${({ dockFooter, footerMobileOnly }) => theme.media.M`
    & ${MODAL_FOOTER_SELECTOR} {
      box-shadow: 0 2px 0px 2px ${theme.colors.white};
      position: relative;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      ${dockFooter ? '' : 'border-top: none;'}
      ${footerMobileOnly ? 'display: none;' : ''}
    }
  `}
`;

export const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: 100%;
  overflow-y: auto;
  padding: 0 ${PADDING_MOBILE};
  position: relative;
  border-radius: 0 0 ${theme.borderRadius.L} ${theme.borderRadius.L};

  & ${Footer} {
    margin: -2px -${PADDING_MOBILE} 0;
  }

  ${theme.media.M`
    & ${Footer} {
      margin: 0px -${PADDING_MOBILE};
    }
  `}

  ${({ hasTopBar }) => theme.media.M`
    min-width: auto;
    flex: 1 1 auto;
    padding: ${hasTopBar ? TOP_BAR_HEIGHT : 0} ${PADDING} 0 ${PADDING_HALF};

    & ${Footer} {
      margin: 0;
    }
  `}
`;

export const MainPanelWrapper = styled.div`
  flex: 1 1 auto;
  padding: 0;

  ${({ dockFooter }) => theme.media.M`
    flex: ${dockFooter ? '1 1 auto' : 'none'};
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;

  ${theme.media.S`
    height: calc(100vh - 20vh);
  `}

  ${theme.media.M`
    height: calc(100vh - 20vh);
  `}
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;

  ${theme.media.M`
    height: 100%;
  `}
`;


export const TopBar = styled.div`
  height: ${({ hasTopBar }) => hasTopBar ? TOP_BAR_HEIGHT : 0};
  display: block;
  padding: ${({ hasTopBar }) => hasTopBar ? theme.spacing.singleAndHalf : 0};
  position: sticky;
  top: 0;

  ${({ hasTopBar }) => {
    if (!hasTopBar) {
      return `
        & + ${Wrapper} {
          & ${LeftPanel},
          & ${MainPanel} {
            padding-top: ${theme.spacing.threeQuarters};
          }
        }
      `;
    }
  }}

  ${theme.media.M`
    display: none;
  `}
`;
