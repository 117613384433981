import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import SkillRecommendationsListItem from './SkillItem';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  width: 100%;
  
  ${theme.media.M`
    grid-template-columns: repeat(4, 25fr);
    grid-template-rows: repeat(1, 25fr);
    max-width: 730px;
    min-height: 356px;
  `}
`;

// We have a grid of each row having 4 skills.
// In order to make each grid have a nice even number of items per row, we will need to add empty skills to fill out the grid.
// This function takes in the skills list and makes a new list with the added empty items;
const getNewSkillsList = (skillsList = []) => {
  const skillsListCopy = [
    ...skillsList.slice(0, skillsList.length - 1),
    { ...skillsList[skillsList.length - 1], isLastSkill: true },
  ];
  const moduloOfFour = skillsList.length % 4;
  if (moduloOfFour !== 0) {
    const blankSkills = {};
    for (let i = 0; i < 4 - moduloOfFour; i++) {
      skillsListCopy.push(blankSkills);
    }
  }
  return skillsListCopy;
};

const SkillRecommendationsList = ({
  className,
  skillsList,
  selectedSkillIds = [],
  handleSelectSkills,
  maxSelectable,
  rcLabel,
}) => {
  const newSkillsList = getNewSkillsList(skillsList);

  return (
    <Container className={className} data-rc={rcLabel}>
      {newSkillsList.map((skill, index) => {
        const selected = selectedSkillIds.includes(skill.id);
        return (
          <SkillRecommendationsListItem
            key={index}
            index={index + 1}
            skill={skill}
            selected={selected}
            disabled={
              (!selected && selectedSkillIds.length === maxSelectable) ||
              !skill.name
            }
            handleSelectSkills={() => handleSelectSkills(skill)}
            isLastSkill={skill.isLastSkill}
          />
        );
      })}
    </Container>
  );
};

SkillRecommendationsList.defaultProps = {
  maxSelectable: 4,
  handleSelectSkills: () => {},
};

SkillRecommendationsList.propTypes = {
  /** Time for delaying animation. */
  delayAnimationTime: PropTypes.number,
  /** List of 12 recommended skills. */
  skillsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  /** List of selected skill ids. */
  selectedSkillIds: PropTypes.arrayOf(PropTypes.string),
  /** Function for handling changing skills. */
  handleSelectSkills: PropTypes.func.isRequired,
  /** Max number allowed to be selected before list is disabled */
  maxSelectable: PropTypes.number,
  /** Label selector for tests. */
  rcLabel: PropTypes.string,
};

export default SkillRecommendationsList;
