import onImagesLoaded from './onImagesLoaded';
import { getSkillIcon } from '../common/libs/get-skill-details';

const onSkillImagesLoaded = ({
  skills,
  callback,
}) => {
  const images = skills.map((skill) => getSkillIcon({ name: skill }));
  onImagesLoaded({
    images,
    callback,
  });
};

export default onSkillImagesLoaded;
