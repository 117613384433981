import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Tooltip from '../Tooltip';
import { 
  IconWaiting,
  IconBounced,
  IconIgnored,
  IconCancelled,
  IconCheckmarkCircle,
} from '../Icon';

const tagMap = {
  Completed: { 
    color: theme.colors.greens[60],
    colorFamily: 'greens',
    icon: IconCheckmarkCircle,
  },
  Waiting: { 
    color: theme.colors.purples[40],
    colorFamily: 'purples',
    icon: IconWaiting,
    tooltipContent: "Hang tight. Feedback requests typically take a week to be completed. Give them some time, then follow up.",
  },
  Bounced: { 
    color: theme.colors.reds[60],
    colorFamily: 'reds',
    icon: IconBounced,
    tooltipContent: "Your feedback request has bounced and was not delivered. Check for typos and update the email address.",
  },
  Declined: { 
    color: theme.colors.oranges[60],
    colorFamily: 'oranges',
    icon: IconIgnored,
    tooltipContent: "Oops, something went wrong. They may have declined your request by accident or think the request is not legitimate. Try following up.",
  },
  Expired: { 
    color: theme.colors.blacks[50],
    colorFamily: 'blacks',
    icon: IconCancelled,
    tooltipContent: "Looks like they didn’t respond in time. Still want feedback? Consider sending a new feedback request.",
  },
  Revoked: { 
    color: theme.colors.blacks[50],
    colorFamily: 'blacks',
    icon: IconCancelled,
    tooltipContent: "You have canceled this feedback request.",
  },
};

const TagContainer = styled.div`
  cursor: default;
  border-radius: ${theme.borderRadius.S};
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  ${theme.media.S`
    width: auto;
    padding: 0 ${theme.spacing.eighth} 0 ${theme.spacing.half};
    font-size: ${theme.fontSize.XXS};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.M};
    letter-spacing: 0.02em;
  `}
  ${({ tagType }) => `
    color: ${tagMap[tagType].color};
    background: ${theme.colors[`${tagMap[tagType].colorFamily}`][5]};    
  `}
`;

const Tag = ({ tagType }) => {
  const StatusIcon = tagMap[tagType].icon;

  return (
    <TagContainer tagType={tagType}>
      {tagType}
      <StatusIcon fill={tagMap[tagType].color}/>
    </TagContainer>
  );
};

const StatusTag = ({ tagType }) => {
  if (tagType !== 'Completed') {
    return (
      <Tooltip
        dark
        content={tagMap[tagType].tooltipContent}
      >
        <Tag tagType={tagType} />
      </Tooltip>
    );
  }
  return <Tag tagType={tagType} />;
};

export default StatusTag;