import React, { useState, useContext, useEffect } from 'react';
import { AppLayout } from 'modules';
import RewardsMainColumn from './components/RewardsMainColumn';
import RewardSideColumn from './components/SideColumn';
import UserContext from 'context/UserContext/UserContext';
import { Loader } from '@matterapp/matter-ui';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import { ChannelsSelect } from 'components/MobileSubMenu/MobileSubMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';

function RewardsStore() {
  const [isViewHistory, setIsViewHistory] = useState(false);
  const { currentTenant, currentWorkspace, refetchMemberCoins, memberProperties } = useContext(UserContext);
  const { setIsMobileMenuOpen, setTopNavItem } = useContext(MobileMenuContext);
  const { rewardsSettings = {} } = currentTenant || {};
  const location = useLocation();
  const navigate = useNavigate();
  const isHistory = location.pathname.includes('history');

  useEffect(() => {
    if (currentTenant) {
      refetchMemberCoins();
    }

    setIsMobileMenuOpen(false);
    setTopNavItem(<ChannelsSelect text='Rewards' iconVariant='rewards' />);
  }, []);

  useEffect(() => {
    setIsViewHistory(isHistory);
  }, [location]);

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={!isViewHistory}
      mainColumn={currentTenant && memberProperties ? (
        <RewardsMainColumn
          isViewHistory={isViewHistory}
          currentCoinBalance={memberProperties?.currentCoins}
          memberRewardsCountry={memberProperties?.rewardsCountry}
          onClickHistory={() => setIsViewHistory(true)}
          onClickBack={() => {
            if (isHistory) {
              const rewardsPath = Resources.rewardsStore.path({ workspaceId: currentWorkspace.id });
              navigate(rewardsPath);
            }

            setIsViewHistory(false);
          }} 
          rewardsSettings={rewardsSettings}
          tenant={currentTenant}
        />
      ) : <Loader />}
      sideColumn={
        <RewardSideColumn
          isRewardsActive={rewardsSettings.isRewardsActive}
          currentCoinBalance={memberProperties?.currentCoins}
          totalCoinBalance={memberProperties?.totalCoins}
          onClickHistory={() => setIsViewHistory(true)}
        />}
    />
  );
}

export default RewardsStore;