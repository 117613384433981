import React from 'react';
import { PeerSuggestionsGrid } from '@matterapp/matter-ui';
import { compose, withProps, lifecycle } from 'recompose';
import { withSelectedPeerRecommendations } from '../../../hocs';
import { useExperiment } from 'modules/experiments';

const INITIAL_NUM_SELECTED = 8;

function componentDidMount() {
  const { peerSuggestions, handleSelectPeers } = this.props;
  const subset = peerSuggestions.slice(0, INITIAL_NUM_SELECTED);
  handleSelectPeers(subset);
}

const peerSuggestionsToSelectablePeers = ({ allPeers, selectedPeers }) =>
  allPeers.map((peer) => ({
    peer,
    isSelected: !!selectedPeers[peer.email],
  }));

const withData = compose(
  withSelectedPeerRecommendations,
  lifecycle({ componentDidMount }),
  withProps(
    ({
      peerSuggestions,
      selectedPeerRecommendations,
      handleToggleSelectedPeerRecommendations,
    }) => ({
      selectablePeerSuggestions: peerSuggestionsToSelectablePeers({
        allPeers: peerSuggestions,
        selectedPeers: selectedPeerRecommendations,
      }),
      onPeerClick: handleToggleSelectedPeerRecommendations,
    })
  )
);

const PeerSuggestionsGridContainer = withData((props) => {
  const { assignment, loading } = useExperiment({ name: 'default-avatar' });
  return loading ? null : (
    <PeerSuggestionsGrid
      {...props}
      defaultAvatarIsGenerated={assignment === 'treatment'}
    />
  );
});

export default PeerSuggestionsGridContainer;
