import Base from './Footer';
import WithActionButtons from './WithActionButtons';
import WithCancelDelete from './WithCancelDelete';
import WithCancelSave from './WithCancelSave';
import WithCancelSend from './WithCancelSend';
import WithClose from './WithClose';

const Footer = Base;
Footer.WithActionButtons = WithActionButtons;
Footer.WithCancelDelete = WithCancelDelete;
Footer.WithCancelSave = WithCancelSave;
Footer.WithCancelSend = WithCancelSend;
Footer.WithClose = WithClose;

export default Footer;