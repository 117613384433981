import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clickable } from '../Clickable/Clickable';
import { IconNew } from '../Icon';
import theme from '@matterapp/matter-theme';

const Container = styled(Clickable)`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSize.S};
  margin-top: ${theme.spacing.half};
  margin-bottom: ${theme.spacing.half};
  padding: ${theme.spacing.quarter} 0;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  ${({ disabled }) => {
    if (disabled) {
      return `
        cursor: not-allowed;
        color: ${theme.colors.blacks[30]} !important;
        & svg path {
          fill: ${theme.colors.blacks[30]} !important;
        }
      `;
    }
    if (!disabled) {
      return `
        color: ${theme.colors.blacks[60]} !important;
        & svg path {
          fill: ${theme.colors.blacks[60]} !important;
        }
        &:hover {
          color: ${theme.colors.purples[50]} !important;
          & svg path {
            fill: ${theme.colors.purples[50]} !important;
          }
        }
        &:active {
          color: ${theme.colors.blacks[100]} !important;
          & svg path {
            fill: ${theme.colors.blacks[100]} !important;
          }
        }
      `;
    }
  }}
`;

const Icon = styled(IconNew)`
  height: ${theme.spacing.singleAndHalf};
  width: ${theme.spacing.singleAndHalf};
`;

const Label = styled.span`
  &:nth-child(2) {
    margin-left: ${theme.spacing.half};
  }
`;

const ActionItem = (props) => {
  const { children, disabled, IconComponent, iconName, onClick } = props;
  return (
    <Container disabled={disabled} onClick={onClick}>
      {iconName && <IconComponent name={iconName} disabled={disabled} />}
      <Label>{children}</Label>
    </Container>
  );
};

ActionItem.defaultProps = {
  IconComponent: Icon,
};

ActionItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  IconComponent: PropTypes.elementType,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
};

export default ActionItem;
