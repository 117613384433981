import React from 'react';
import PropTypes from 'prop-types';
import InputContainer from '../Input/InputContainer';
import InputErrorContainer from '../Input/InputErrorContainer';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../libs/omitStyled';

const StyledTextArea = omitStyled('textarea', ['borderless', 'error'])`
  color: ${(props) => props.theme.colors.black};
  font-size: 24px;
  line-height: 32px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  resize: none;

  border:
    ${(props) => props.theme.buttons.borderWidth}
    ${(props) => props.theme.buttons.borderStyle}
    ${(props) => props.theme.buttons.borderColor};
  border-radius: ${(props) => props.theme.inputs.borderRadius};

  transition:
    border .1s ease,
    background-color .1s ease,
    box-shadow .1s ease;

  &:focus {
    border-color: ${(props) => props.theme.inputs.borderActiveColor};
    box-shadow: 0px 2px 4px rgba(52, 55, 153, 0.2);
  }

  ${({ disabled }) => disabled && `
    background-color: ${theme.inputs.disabledBackgroundColor};
  `};

  ${({ error }) => error && `
    border-color: ${theme.inputs.errorColor};
  `};

  ${({ borderless }) => borderless && `
    border: none !important;
    border-radius: 0 !important;

    &:focus {
      box-shadow: none !important;
    }
  `};
`;


const TextArea = ({
  placeholder,
  onChange,
  disabled,
  errorMessage,
  rows,
  value,
  borderless,
  autoFocus,
  className,
  fluid,
}) => (
  <InputContainer
    fluid={fluid}
    error={!!errorMessage}
    disabled={disabled}
  >

    <InputErrorContainer>
      {errorMessage}
    </InputErrorContainer>

    <StyledTextArea
      autoFocus={autoFocus}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      error={!!errorMessage}
      rows={rows}
      value={value || undefined}
      borderless={borderless}
      className={className}
    />

  </InputContainer>
);

TextArea.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  autoFocus: PropTypes.bool,
  borderless: PropTypes.bool,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  value: PropTypes.string,
  className: PropTypes.string,
  fluid: PropTypes.bool,
};

TextArea.defaultProps = {
  placeholder: '',
  autoFocus: false,
  disabled: false,
  errorMessage: null,
  borderless: false,
  onChange: () => null,
  rows: 2,
  value: null,
  className: null,
  fluid: false,
};


export default TextArea;
