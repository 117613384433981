import React from 'react';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import { ENV_VARS } from 'app-consts/env-vars';
import { Button } from '@matterapp/matter-ui';

export default class GoogleAuthButton extends React.Component {
  static propTypes = {
    handleButtonClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    iconName: PropTypes.string,
  };

  render() {
    const { handleButtonClick, buttonText, iconName } = this.props;

    return (
      <GoogleLogin
        clientId={ENV_VARS.GOOGLE_CLIENT_ID}
        onSuccess={handleButtonClick}
        cookiePolicy={'single_host_origin'}
        render={(props) => (
          <Button.Social
            icon={iconName}
            buttonText={buttonText}
            onClick={props.onClick}
          />
        )}
      />
    );
  }
}
