import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

function toggleBodyLock(props) {
  const { isOpen, bodyOpenClassName } = props;
  document.body.classList.toggle('locked', isOpen);
  document.body.classList.toggle(bodyOpenClassName, isOpen);
}

class ModalAdapter extends React.Component {
  componentDidMount() {
    toggleBodyLock(this.props);
    ReactModal.setAppElement(this.props.appElement);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    toggleBodyLock(nextProps);
  }

  componentWillUnmount = () => {
    // Submiting a note needs to remove the lock
    document.body.classList.remove('locked');
    document.body.classList.remove(this.props.bodyOpenClassName);
  };

  render() {
    const {
      /* eslint-disable-next-line no-unused-vars */
      appElement,
      overlayClassName,
      contentClassName,
      className,
      htmlOpenClassName,
      bodyOpenClassName,
      onRequestClose,
      ...props
    } = this.props;

    return (
      <ReactModal
        portalClassName={className}
        overlayClassName={overlayClassName}
        className={contentClassName}
        htmlOpenClassName={htmlOpenClassName}
        bodyOpenClassName={bodyOpenClassName}
        ariaHideApp
        shouldFocusAfterRender
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        shouldReturnFocusAfterClose
        role="dialog"
        closeTimeoutMS={300}
        onRequestClose={async () => await onRequestClose()}
        {...props}
      />
    );
  }
}

ModalAdapter.defaultProps = {
  appElement: 'body',
  htmlOpenClassName: 'ReactModal__Html--open',
  bodyOpenClassName: 'ReactModal__Body--open',
  isOpen: false,
};

ModalAdapter.propTypes = {
  appElement: PropTypes.string,
  overlayClassName: PropTypes.string.isRequired,
  contentClassName: PropTypes.string.isRequired,
  htmlOpenClassName: PropTypes.string,
  bodyOpenClassName: PropTypes.string,
  className: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  contentLabel: PropTypes.string.isRequired,
};

export default ModalAdapter;
