import React from 'react';
import PropTypes from 'prop-types';
import { skillEntityPropType } from '@matterapp/skills';
import { Skill } from '@matterapp/matter-ui';
import { Field as ReduxFormField } from 'redux-form';
import { SKILL_CHECKBOX_NAME } from '../../app-consts';

export default class SkillCheckboxes extends React.Component {
  static propTypes = {
    /** List of skills to display in the checkbox grid. */
    skills: PropTypes.arrayOf(skillEntityPropType).isRequired,
    /** If the maximum of skills are selected. Disables further selection. */
    isSelectedMax: PropTypes.bool.isRequired,
  };

  renderCheckbox = (reduxFormFieldProps) => {
    const { skill, index, input, isSelectedMax } = reduxFormFieldProps;
    const isDisabled = isSelectedMax && !input.checked;
    return (
      <Skill.Recommendations.GridUnit
        key={skill.id}
        skill={skill}
        index={index}
        onClick={input.onChange}
        checked={input.checked}
        rcLabel={`skillRecommendationListItem-${index}`}
        disabled={isDisabled}
        hideCheckmark={isDisabled}
        hideCheckmarkWithTransition
      />
    );
  };

  renderCheckboxes = (reduxFormFieldProps) => {
    const { skills } = reduxFormFieldProps;
    return skills.map((skill, index) => (
      <ReduxFormField
        key={skill.id}
        component={this.renderCheckbox}
        skill={skill}
        name={SKILL_CHECKBOX_NAME(skill.id)}
        type="checkbox"
        label={skill.name}
        value={skill.id}
        isSelectedMax={reduxFormFieldProps.isSelectedMax}
        index={index}
      />
    ));
  };

  render = () => {
    const { skills, isSelectedMax } = this.props;
    return (
      <ReduxFormField
        name="skillIds"
        skills={skills}
        isSelectedMax={isSelectedMax}
        component={this.renderCheckboxes}
      />
    );
  };
}
