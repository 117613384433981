import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const HoverPath = styled.path`
  transition: all ${() => theme.transitions.times.default};
`;

export const DEFAULT_SVG_HOVER_NAME = 'svg-hover-element';

export const ICON_FILL_COLORS = [
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
  'black',
];

const Icon = ({ className, children, size }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 32 32">
      {children}
    </svg>
  );
};

Icon.defaultProps = {
  size: 32,
};

Icon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default Icon;
