import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import {
  Text,
  InputCopyToClipboard,
} from '@matterapp/matter-ui';
import { AppLayout } from 'modules';
import ContainerNavigation from './ContainerNavigation';
import { mixpanel } from 'libs';

const trackReferralLinkCopied = mixpanel.createEventSender('referralLink:copied');

const ReferralAppLayout = styled(AppLayout)`
  & p {
    font-size: ${theme.fontSize.S};
  }
  & span.socialLinkDivider {
    display: inline-block;
    margin: 0 ${theme.spacing.half};
    color: ${theme.colors.blacks[30]};
  }
`;

const CopyInputContainer = styled.div`
  margin-bottom: ${theme.spacing.singleAndQuarter};
  @media screen and (min-width: ${theme.breakpoints[3]}) {
    display: flex;
    width: 100%;
    & > div {
      margin-right: ${theme.spacing.threeQuarters};
      width: 100%;
    }
    & > button {
      margin: 0;
      padding: ${theme.spacing.half} ${theme.spacing.doubleAndQuarter};
      width: auto;
    }
  }
`;

const ReferralLinkPage = class extends React.Component {
  render() {
    const referralCode = this.props.referralCodeData.getReferralCode;
    const referralLink = `https://matterapp.com/?utm_source=referral_program&utm_medium=referral_link-${referralCode}`;
    return (
      <React.Fragment>
        <Helmet>
          <title>Referral</title>
        </Helmet>
        <ReferralAppLayout
          layout={AppLayout.Layouts.TwoColumnFixedLeft}
          oldLayoutVersion={1}
          containerNavigation={ContainerNavigation}
        >
          <Text color="black" textStyle={500} marginBottom={16} lineHeight={32} block bold>
            Referral
          </Text>
          <Text color="black" textStyle={500} marginBottom={16} lineHeight={32} block bold>
            Love Matter? Share Feedback Friday and earn $25!
          </Text>
          <p>
            Read terms <a href="https://matterapp.com/legal/referral-program" target="_blank" rel="noopener noreferrer">here</a>.
          </p>
          <Text color="black" textStyle={500} marginBottom={16} lineHeight={32} block bold>
            Copy and share your link
          </Text>
          <CopyInputContainer>
            <InputCopyToClipboard
              rcLabel={'copyReferralLinkInput'}
              onCopy={trackReferralLinkCopied}
              showBorder
              showOutsideCopyButton
              showOverlayOnCopy
              text={referralLink.substr(0, 56) + '...'}
              value={referralLink}
            />
          </CopyInputContainer>
          <p>
            Share your link on:&nbsp;&nbsp;
            <a href="https://www.facebook.com/sharer/sharer.php?u=https://matterapp.com" target="_blank" rel="noopener noreferrer">Facebook</a>
            <span className="socialLinkDivider">|</span>
            <a href="https://twitter.com/intent/tweet?text=No%20better%20feeling%20than%20recognizing%20my%20team%20members%20with%20Kudos%20on%20Feedback%20Friday%20with%20%40MatterApp.%20If%20you%20use%20Slack,%20check%20it%20out%20https%3A//matterapp.com" target="_blank" rel="noopener noreferrer">Twitter</a>
            <span className="socialLinkDivider">|</span>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://matterapp.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </p>
        </ReferralAppLayout>
      </React.Fragment>
    );
  }
};

ReferralLinkPage.propTypes = {
  referralCodeData: PropTypes.string.isRequired,
};

export default ReferralLinkPage;


