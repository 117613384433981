import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '../Heading';

const Steps = ({
  steps,
}) => (
  <Heading
    as="h5"
    marginBottom={0}
    textAlign="inherit"
  >
    Step {steps.currentStep} of {steps.totalSteps}
  </Heading>
);

Steps.defaultProps = {
  steps: {
    totalSteps: 0,
    currentStep: 0,
  },
};

Steps.propTypes = {
  steps: PropTypes.objectOf(PropTypes.number),
};

export default Steps;
