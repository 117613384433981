import { useEffect, useRef, useState } from 'react';
import { MINI_KUDOS_HEIGHT } from '../Mini';

export const useGrid = (params) => {
  const {
    collapseText,
    defaultExpanded,
    expandable,
    expandText,
    isCompact,
    itemHeight,
  } = params;
  const canExpand = expandable;
  const collapsedHeight = canExpand ? MINI_KUDOS_HEIGHT : 'auto';
  const [isExpanded, setExpanded] = useState(
    canExpand ? defaultExpanded : false
  );
  const [expandedHeight, setExpandedHeight] = useState(collapsedHeight);
  const gridRef = useRef();
  const maxHeight = isExpanded ? expandedHeight : collapsedHeight;

  useEffect(() => {
    const setHeight = () => {
      if (gridRef.current && gridRef.current.offsetHeight) {
        setExpandedHeight(gridRef.current.offsetHeight);
      }
    };
    if (expandable) {
      setHeight();
      window.addEventListener('resize', setHeight);
    }
    return () => {
      if (expandable) {
        window.removeEventListener('resize', setHeight);
      }
    };
  }, []);

  return {
    ...params,
    expandable: canExpand,
    expandButtonProps: {
      children: isExpanded ? collapseText : expandText,
      onClick: () => setExpanded(!isExpanded),
    },
    expandContainerProps: {
      expandable: canExpand,
      isCompact,
    },
    miniProps: {
      minHeight: itemHeight,
    },
    gridProps: {
      isCompact,
      ref: gridRef,
    },
    gridContainerProps: {
      isCompact,
      isExpanded,
      style: canExpand ? { maxHeight } : {},
    },
    toggleExpand: () => {
      setExpanded(!isExpanded);
    },
  };
};
