import React from 'react';
import PropTypes from 'prop-types';

const rotation = {
  up: 'rotate(-90, 10, 10)',
  down: 'rotate(90, 10, 10)',
  left: 'rotate(180, 10, 10)',
  right: '',
};

const Icon = ({ fill, direction }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 10 20C 15.5229 20 20 15.5228 20 10C 20 4.47716 15.5229 0 10 0C 4.47715 0 0 4.47716 0 10C 0 15.5228 4.47715 20 10 20ZM 9.29289 5.70711C 8.90237 5.31659 8.90237 4.68341 9.29289 4.29289C 9.68342 3.90237 10.3166 3.90237 10.7071 4.29289L 16.4142 10L 10.7071 15.7071C 10.3166 16.0976 9.68342 16.0976 9.29289 15.7071C 8.90237 15.3166 8.90237 14.6834 9.29289 14.2929L 12.5858 11L 5 11C 4.44772 11 4 10.5523 4 10C 4 9.44771 4.44772 9 5 9L 12.5858 9L 9.29289 5.70711Z"
      transform={`translate(2 2) ${rotation[direction]}`}
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
  direction: 'right',
};

Icon.propTypes = {
  fill: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

export default Icon;
