import React from 'react';
import gsap from 'gsap';
import BaseBackground from './BaseBackground';
import { BackgroundContainer, DarkPath, LightPath, WhitePath } from './styles';

export default class Background6 extends BaseBackground {
  playAnimation = (animationProps = {}) => {
    BaseBackground.killAnimation(this.tl);
    const mountainResetProps = {
      x: 0,
      opacity: 1,
      duration: 0.7,
    };

    this.tl = gsap.timeline({
      defaults: { duration: 0, transformOrigin: 'center bottom' },
    });
    this.tl.to(this.mountainOneRef, { x: 40, opacity: 0 }, '<');
    this.tl.to(this.mountainTwoRef, { x: 90, opacity: 0 }, '<');
    this.tl.to(this.mountainThreeRef, { x: 130, opacity: 0 }, '<');
    this.tl.to(this.treeRef, { x: -100, opacity: 0 }, '<');
    this.tl.to(this.tentRef, { scaleY: 0 }, '<');
    this.tl.to(this.bushesRef, { scaleY: 0 }, '<');

    if (!animationProps.initialize) {
      this.tl.to(this.mountainOneRef, mountainResetProps, '<');
      this.tl.to(this.mountainTwoRef, mountainResetProps, '<');
      this.tl.to(this.mountainThreeRef, mountainResetProps, '<');
      this.tl.to(this.tentRef, { scaleY: 1, duration: 0.3 }, '>-0.3');
      this.tl.to(this.treeRef, { x: 0, opacity: 1, duration: 0.5 }, '<');
      this.tl.to(this.bushesRef, { scaleY: 1.6, duration: 0.1 }, '<0.4');
      this.tl.to(this.bushesRef, { scaleY: 1, duration: 0.05 }, '>');
    }
    return this.tl;
  };

  render() {
    return (
      <BackgroundContainer top={44}>
        <BaseBackground.SVG height={96}>
          <LightPath
            ref={(ref) => (this.mountainOneRef = ref)}
            d="M218.438,8L163,77h115L218.438,8z"
          />
          <g ref={(ref) => (this.mountainTwoRef = ref)}>
            <DarkPath d="M227.703,23.094L184.219,77h90.203L227.703,23.094z" />
            <WhitePath d="M242.078,54l-14.375-30.906L274.781,77h-40.969l-6.109-12.938h9.703L233.453,54H242.078z" />
          </g>
          <LightPath
            ref={(ref) => (this.mountainThreeRef = ref)}
            d="M266.961,43.938L240.281,77h55.344L266.961,43.938z"
          />
          <g ref={(ref) => (this.bushesRef = ref)}>
            <LightPath
              id="BUSH_ONE_LIGHT"
              d="M370,87.5c0-3.314-2.686-6-6-6s-6,2.686-6,6H370z"
            />
            <LightPath
              id="BUSH_TWO_LIGHT"
              d="M384.25,89c0-4.971-4.029-9-9-9s-9,4.029-9,9H384.25z"
            />
          </g>
          <g ref={(ref) => (this.treeRef = ref)}>
            <LightPath d="M356,49.011c0-4.976,4.035-9.011,9.011-9.011c4.977,0,9.012,4.035,9.012,9.011v18.023c0,4.977-4.035,9.012-9.012,9.012c-4.976,0-9.011-4.035-9.011-9.012V49.011z" />
            <DarkPath d="M366.138,62.116V89h-2.253V68.56l-5.55-5.55l0.398-0.399l5.152,5.152V56.896h2.253v4.543l4.631-3.087l0.312,0.468L366.138,62.116z" />
          </g>
          <g ref={(ref) => (this.tentRef = ref)}>
            <DarkPath id="TENT_DARK" d="M344,88l-9.052-16H314l7.5,16H344z" />
            <LightPath id="TENT_LIGHT" d="M305,89l9.052-17H335l-7.5,17H305z" />
          </g>
        </BaseBackground.SVG>
      </BackgroundContainer>
    );
  }
}
