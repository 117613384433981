import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 20.5s4-7.755 4-13-2.22-6-4-6c-1.78 0-4 .755-4 6s4 13 4 13zm4 6a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
