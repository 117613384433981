import { useEffect, useState } from 'react';
import { TIMEZONES_LIST } from './consts';

export const NO_OP = () => null;

export const useDropdown = (params) => {
  const { onChange = NO_OP, value: selectedValue, ...otherParams } = params;
  const [timezoneValue, setTimezoneValue] = useState(selectedValue);
  const [selectedTimezone, setSelectedTimezone] = useState(
    TIMEZONES_LIST.find((option) => option.value === selectedValue)
  );

  const handleChange = (e, { value }) => {
    setTimezoneValue(value);
  };

  const handleSelect = (e, props) => {
    const { item = {} } = props;
    setSelectedTimezone(item);
    onChange(e, { ...props, value: item.value });
  };

  const handleMenuVisibilityChange = (e, props) => {
    const { firstItem, isOpen } = props;
    if (!isOpen) {
      const hasFirstItem = firstItem && firstItem.value;
      const selectedItem = hasFirstItem ? firstItem : selectedTimezone;
      if (hasFirstItem) {
        setSelectedTimezone(firstItem);
      }
      if (selectedItem && selectedItem.value) {
        setTimezoneValue(selectedItem.value);
        onChange(e, {
          ...props,
          value: selectedItem.value,
        });
      }
    }
  };

  useEffect(() => {
    if (!(selectedTimezone && selectedTimezone.value)) {
      setTimezoneValue(selectedValue);
      setSelectedTimezone(
        TIMEZONES_LIST.find((option) => option.value === selectedValue)
      );
    }
  }, [selectedValue]);

  return {
    ...otherParams,
    handleChange,
    handleSelect,
    handleMenuVisibilityChange,
    items: TIMEZONES_LIST,
    value: timezoneValue,
  };
};
