import React from 'react';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../libs/omitStyled';
import avatarSvg from '../../../assets/svgs/avatar.svg';
import { getDefaultAvatar } from './DefaultAvatar';

const DEFAULT_AVATAR_SIZE = 40;
const DEFAULT_OVERLAP_WIDTH = 18;

const AvatarsContainer = omitStyled('div', [
  'yellowBorder',
  'overlapWidth',
  'containerWidth',
])`
  display: flex;
  width: ${({ containerWidth }) => containerWidth}px;
  height: ${({ size }) => size}px;
  overflow: hidden;

  img {
    box-sizing: content-box;
    border-radius: 50px;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  }

  img:not(:first-of-type) {
    ${({ overlapWidth }) => overlapWidth && `margin-left: -${overlapWidth}px;`};

    ${({ yellowBorder }) => yellowBorder && `
      border: 1.5px solid ${theme.colors.yellows[20]};
      margin-right: -1.5px;
    `};
  }
`;

const AvatarMultiple = ({
  className,
  numberOfAvatarsToShow,
  urls,
  size,
  yellowBorder,
  overlapWidth,
  defaultAvatarIsGenerated,
}) => {
  const reversedUrls = [...urls];

  if (numberOfAvatarsToShow && reversedUrls.length < numberOfAvatarsToShow) {
    for (let i = reversedUrls.length; i < numberOfAvatarsToShow; i++) {
      // If numberOfAvatarsToShow is passed, this adds blank avatars to fill the number shown.
      reversedUrls.push('');
    }
  }
  reversedUrls.reverse();

  let containerWidth =
    reversedUrls.length * size - (reversedUrls.length - 1) * overlapWidth;
  containerWidth = yellowBorder ? containerWidth + 3 : containerWidth;

  return (
    <AvatarsContainer
      className={className}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
      size={size}
      yellowBorder={yellowBorder}
      overlapWidth={overlapWidth}
      containerWidth={containerWidth}
    >
      {reversedUrls.map((url, index) => {
        const defaultAvatarUrl = defaultAvatarIsGenerated
          ? getDefaultAvatar({ hashString: url, useRandom: true })
          : avatarSvg;
        return (
          <img
            key={`${url}-${String(index)}`}
            src={url || defaultAvatarUrl}
            alt="Avatar"
          />
        );
      })}
    </AvatarsContainer>
  );
};

AvatarMultiple.propTypes = {
  className: PropTypes.string,
  defaultAvatarIsGenerated: PropTypes.bool,
  numberOfAvatarsToShow: PropTypes.number,
  urls: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.number,
  yellowBorder: PropTypes.bool,
  overlapWidth: PropTypes.number,
};

AvatarMultiple.defaultProps = {
  size: DEFAULT_AVATAR_SIZE,
  yellowBorder: false,
  overlapWidth: DEFAULT_OVERLAP_WIDTH,
};

export default AvatarMultiple;
