import React, { useState } from 'react';
import {
  AnalyticsFilterIconWrapper,
  FilterCard,
  FilterTextContainer,
  FiltersContainer,
  FilterDates,
  FilterTab,
  CustomTab
} from './styles';
import { Button, IconNew as Icon } from '@matterapp/matter-ui';
import CustomDatesModal from './CustomDatesModal';
import { dateFilters } from './defaultFilters';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import moment from 'moment';

export async function downloadSpreadsheet(base64, name) {
  const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${name || 'analyticsReport'}.xlsx`;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(url);
}

export const DownloadButton = styled(Button.Primary)(() => ({
  display: 'block',
  whiteSpace: 'nowrap',
  fontSize: theme.fontSize.base,
  lineHeight: theme.fontSize.base,
  padding: `${theme.spacing.half} ${theme.spacing.single}`,
  minHeight: 0,
  background: 'none',
  border: `1px solid ${theme.colors.blues[30]}`,
  color: theme.colors.blues[50],
  marginLeft: theme.spacing.single,
  ':hover': {
    background: 'none',
  },
  ':not(:active)': {
    border: `1px solid ${theme.colors.blues[30]}`,
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    display: 'none'
  }
}));

export function buildCustomDateRange(from, to) {
  const fromDate = moment(from);
  const toDate = moment(to);
  const diff = toDate.diff(fromDate, 'days');
  let groupedBy = 'GROUPED BY DAY';
  let groupBy = 'day';
  let xAxisResolution = 7;

  if (diff > 14 && diff < 60) {
    groupedBy = 'GROUPED BY WEEK';
    groupBy = 'week';
    xAxisResolution = 8;
  } else if (diff >= 60 && diff < 90) {
    groupedBy = 'GROUPED BY WEEK';
    groupBy = 'week';
    xAxisResolution = 10;
  } else if (diff >= 90) {
    groupedBy = 'GROUPED BY MONTH';
    groupBy = 'month';
    xAxisResolution = 10;
  }

  return {
    label: 'custom',
    value: 'custom',
    startDate: fromDate,
    endDate: toDate,
    groupedBy,
    groupBy,
    xAxisResolution
  };
}

export default function DateFilterBar({
  items = dateFilters,
  value,
  onChange,
  startDate,
  endDate,
  children
}) {
  const [isCustomDatesOpen, updateIsCustomDatesOpen] = useState(false);

  return (
    <FilterCard>
      <FilterTextContainer>
        <AnalyticsFilterIconWrapper onClick={() => updateIsCustomDatesOpen(true)}>
          <Icon name="analyticsFilter" />
        </AnalyticsFilterIconWrapper>
        {startDate && endDate ? (<FilterDates>{startDate.format('MMM. D, YYYY')} → {endDate.format('MMM. D, YYYY')}</FilterDates>) : null}
        {children}
      </FilterTextContainer>
      <FiltersContainer>
        <CustomTab 
          isActive={value === 'custom'}
          onClick={() => updateIsCustomDatesOpen(true)}
        >
          <Icon name="Calendar" fill='grey' /> Custom Date
        </CustomTab>
        {items.map(item => {
          return (
            <FilterTab
              key={item.label}
              isActive={item.value === value}
              onClick={() => onChange(item.value)}
              visibleOnMobile={item.visibleOnMobile}
            > 
              {item.label}
            </FilterTab>
          );
        })}
      </FiltersContainer>
      <CustomDatesModal 
        isOpen={isCustomDatesOpen}
        onClose={() => updateIsCustomDatesOpen(false)}
        onSelect={(from, to) => {
          onChange({ from, to }, true);
          updateIsCustomDatesOpen(false);
        }}
        startDate={startDate}
        endDate={endDate}
      />
    </FilterCard>
  );
};
