import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

class EmojiList extends React.PureComponent {
  static Container = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0 0 30px 0;

    ${() => theme.media.medium`
      margin-left: 24px;
    `};
  `;

  render() {
    const { children, className } = this.props;
    return (
      <EmojiList.Container className={className}>
        {children}
      </EmojiList.Container>
    );
  }
}

EmojiList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

EmojiList.defaultProps = {
  className: null,
};

export default EmojiList;
