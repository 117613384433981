import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GIVE_QUICK_NOTE_FEEDBACK_MUTATION,
  QUICK_NOTE_INNER_QUERY
} from 'graphql-queries/queries';
import {
  getGiveFeedbackDisabledMessage,
  getDropdownInviteMessage,
  ToastTextWithHeader,
  MIN_MESSAGE_LENGTH,
  MAX_MESSAGE_LENGTH,
  REPLACE_TEXT,
  WORKSPACE_DROPDOWN_PLACEHOLDER,
} from './shared';
import Toast from 'components/Toast';
import { getAcceptedMembers } from 'helpers/workspaces';
import { trackRetentionEvent } from 'libs';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';

const ERROR_HEADER = 'Oops!';
const ERROR_MESSAGE = `Something went wrong. Your feedback could not be sent`;

const SUCCESS_HEADER = 'Nicely Done!';
const SUCCESS_MESSAGE = `Your feedback was sent${REPLACE_TEXT}`;

const getSkillsList = (userSkills = [], recommendedSkills = []) => {
  const seen = new Set();
  const result = [];
  const add = (skill) => {
    if (!seen.has(skill.id)) {
      result.push(skill);
      seen.add(skill.id);
    }
  };
  userSkills.forEach(add);
  recommendedSkills.forEach(add);
  return result;
};

/*****************************************************************************/
/* USER SKILLS QUERY                                                         */
/*****************************************************************************/
export const useUserSkills = () => {
  const [getUserSkills, { loading, data }] = useLazyQuery(
    QUICK_NOTE_INNER_QUERY
  );

  const { user, recommendedSkills } = data || {};
  const { skills } = user || { skills: [] };
  return {
    data,
    loading,
    skills: getSkillsList(skills, recommendedSkills),
    onSelectUser: (e, props) => {
      const { user } = props;
      if (user && user.userId) {
        getUserSkills({ variables: { id: user.userId } });
      }
    },
    getUserSkills: (id) => getUserSkills({ variables: id }),
  };
};

/*****************************************************************************/
/* GIVE FEEDBACK MUTATION                                                    */
/*****************************************************************************/
export const useGiveFeedbackMutation = () => {
  const [mutation, loading] = useMutation(GIVE_QUICK_NOTE_FEEDBACK_MUTATION);
  const giveFeedback = (props) => {
    const { note, receiverUserId, skillId, workspaceId } = props;
    return mutation({
      variables: {
        note,
        receiverUserId,
        skillId,
        workspaceId,
      }
    });
  };
  return [giveFeedback, loading];
};

/*****************************************************************************/
/* GIVE FEEDBACK HOOK                                                        */
/*****************************************************************************/
export const useGiveFeedback = (params) => {
  const {
    disabledMessage,
    dropdownPlaceholder,
    emptyMenuMessageNoItems,
    users = [],
    workspaceId,
    ...otherParams
  } = params;
  const [searchMembers, { loading: isLoadingResults, data }] = useLazyQuery(searchWorkspaceMembers);
  const [giveFeedback, { loading }] = useGiveFeedbackMutation();
  const { onSelectUser, skills } = useUserSkills();
  
  async function onSearch(value) {
    if (value) {
      searchMembers({ 
        variables: {
          workspaceId,
          searchString: value,
          excludeCurrentUser: true
        }
      });
    }
  }

  return {
    ...otherParams,
    hasUsers: !!users.length,
    giveFeedbackProps: {
      disabledMessage,
      dropdownPlaceholder,
      emptyMenuMessageNoItems,
      isSending: loading,
      onSelectUser,
      isLoadingResults,
      skills,
      giveFeedback,
      users: data?.searchMembers.map(member => ({
        ...member,
        fullName: member.person?.fullName,
        photoUrl: member.person?.photoUrl,
        userId: member.person?.userId,
        value: member.email,
        label: member.person?.fullName
      })),
      messageMinLength: MIN_MESSAGE_LENGTH,
      messageMaxLength: MAX_MESSAGE_LENGTH,
      onSend: async (e, props) => {
        const {
          note,
          receiverUserId,
          resetState,
          selectedUser,
          skillId,
        } = props;

        try {
          await giveFeedback({ note, receiverUserId, skillId, workspaceId });
          trackRetentionEvent('feedback');
          const hasFirstName = !!selectedUser.firstName;
          const replaceText = hasFirstName
            ? ` to ${selectedUser.firstName}`
            : '!';
          const message = SUCCESS_MESSAGE.replace(REPLACE_TEXT, replaceText);
          Toast.success(
            <ToastTextWithHeader replaceText={[SUCCESS_HEADER, message]} />
          );
          resetState();
        } catch (error) {
          Toast.error(
            <ToastTextWithHeader
              replaceText={[ERROR_HEADER, ERROR_MESSAGE]}
            />
          );
        }
      },
      onSearch
    },
    users,
  };
};

/*****************************************************************************/
/* GIVE FEEDBACK WORKSPACES HOOK                                             */
/*****************************************************************************/
export const useGiveFeedbackWorkspaces = (params) => {
  const { invitePath, workspace } = params;
  const { id, members, slackWorkspace } = workspace;
  const primaryChannel = slackWorkspace?.primaryChannel;
  const acceptedMember = getAcceptedMembers(members);
  const users = acceptedMember
    ?.map((member) => ({ ...member, ...member.person }))
    .filter((member) => !member.isSelf);
  
  const disabledMessage = users.length > 0
    ? null
    : getGiveFeedbackDisabledMessage(invitePath, primaryChannel);
  const emptyMenuMessageNoItems = getDropdownInviteMessage(primaryChannel);

  return useGiveFeedback({
    ...params,
    disabledMessage,
    dropdownPlaceholder: WORKSPACE_DROPDOWN_PLACEHOLDER,
    emptyMenuMessageNoItems,
    workspaceId: id,
    users,
  });
};
