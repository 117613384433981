import PropTypes from 'prop-types';

export {
  WORKSPACE_PROPS,
  WORKSPACE_NAV_PROPS,
  WORKSPACE_NAV_DEFAULT_PROPS,
} from './Workspaces/consts';

export const PROFILE_LINK_PROPS = {
  href: PropTypes.string,
  label: PropTypes.string.isRequired,
  linkProps: PropTypes.object,
  to: PropTypes.string,
};

export const PROFILE_LINKS_PROP_TYPE = PropTypes.arrayOf(
  PropTypes.shape(PROFILE_LINK_PROPS)
);

export const TAB_PROPS = {
  hasNotification: PropTypes.bool,
  iconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    .isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

export const TABS_PROP_TYPE = PropTypes.arrayOf(PropTypes.shape(TAB_PROPS));
