import React from 'react';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import { Route } from 'react-router-dom';
import { compose } from 'recompose';
import { withPageViewTracking } from 'hocs';
import {
  WorkspacesMembersPage
} from '../pages/workspaces';
import WorkspacesActivityFeedPage from 'routes/pages/workspaces/components/WorkspacesActivityFeed/WorkspacesActivityFeedPage';
import RecurringPageContainer from 'routes/pages/workspaces/components/RecurringPage/RecurringPageContainer';
import TenantZeroComponent from 'routes/pages/workspaces/components/TenantZero/TenantZero';

export const WorkspacesActivityFeed = (
  <Route
    exact
    path={Resources.workspacesActivityFeed.pattern}
    element={(
      <PrivateRoute>
        <WorkspacesActivityFeedPage />
      </PrivateRoute>
    )}
  />
);

export const TenantZero = (
  <Route
    exact
    path={Resources.tenantZero.pattern}
    element={(
      <PrivateRoute>
        <TenantZeroComponent />
      </PrivateRoute>
    )}
  />
);

const workspacesUpgradeHoc = compose(
  withPageViewTracking({
    pageTitle: 'workspaces/upgrade',
  })
);

const WorkspacesUpgradePage = workspacesUpgradeHoc(WorkspacesActivityFeedPage);

export const WorkspacesUpgrade = (
  <Route
    exact
    path={Resources.workspacesUpgrade.pattern}
    element={(
      <PrivateRoute>
        <WorkspacesUpgradePage />
      </PrivateRoute>
    )}
  />
);

const workspacesMembersHoc = compose(
  withPageViewTracking({
    pageTitle: 'workspaces/members',
  })
);

const WorkspacesMembersPageComponent = workspacesMembersHoc(WorkspacesMembersPage);

export const WorkspacesMembers = (
  <Route
    exact
    path={Resources.workspacesMembers.pattern}
    element={(
      <PrivateRoute>
        <WorkspacesMembersPageComponent />
      </PrivateRoute>
    )}
  />
);

export const WorkspacesRecurring = (
  <Route
    exact
    path={Resources.workspacesRecurring.pattern}
    element={(
      <PrivateRoute>
        <RecurringPageContainer />
      </PrivateRoute>
    )}
  />
);

