import React from 'react';
import PropTypes from 'prop-types';

import theme from '@matterapp/matter-theme';

const Icon = ({ fill }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7.99996C4 7.26358 4.59695 6.66663 5.33333 6.66663H26.6667C27.403 6.66663 28 7.26358 28 7.99996C28 8.73634 27.403 9.33329 26.6667 9.33329H5.33333C4.59695 9.33329 4 8.73634 4 7.99996Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 16C28 15.2636 27.403 14.6666 26.6667 14.6666H5.33333C4.59695 14.6666 4 15.2636 4 16C4 16.7363 4.59695 17.3333 5.33333 17.3333H26.6667C27.403 17.3333 28 16.7363 28 16Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 24C28 23.2636 27.403 22.6666 26.6667 22.6666H5.33333C4.59695 22.6666 4 23.2636 4 24C4 24.7363 4.59695 25.3333 5.33333 25.3333H26.6667C27.403 25.3333 28 24.7363 28 24Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: theme.colors.purples[50],
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
