import React from 'react';
import { personPropType } from '@matterapp/people';
import { propType } from 'graphql-anywhere';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import ProfilePreviewCard from '../components/ProfilePreviewCard';
import withPeerRecommendations from '../../../../feedback/withPeerRecommendations';
import withToggle from '../../../../withToggle';
import { PEER_ACTIONS_STATUS_QUERY } from 'graphql-queries/queries';
import { useExperiment } from 'modules/experiments';

const withData = compose(
  withPeerRecommendations({
    peerRecommendationFilterFn: () => true,
  }),
  withToggle({
    stateName: 'isFeedbackRequested',
    handleToggleOpenName: 'handleOpenIsFeedbackRequested',
  }),
  graphql(PEER_ACTIONS_STATUS_QUERY, {
    name: 'peerActionsStatusData',
    options: ({ person }) => ({
      fetchPolicy: 'network-only',
      variables: {
        userId: person.userId,
        email: person.email,
      },
    }),
  }),
  withProps(
    ({
      person,
      peerActionsStatusData,
      handleSetPersonForQuickNote,
      handleClosePopover,
      requestFeedbackFromMultiplePeers,
      handleOpenIsFeedbackRequested,
    }) => ({
      handleClickRequestFeedback: async () => {
        handleOpenIsFeedbackRequested();
        await requestFeedbackFromMultiplePeers({
          peers: [person],
          source: 'profileCard'
        });
        await peerActionsStatusData.refetch();
      },
      handleClickGiveQuickNote: () => {
        handleClosePopover();
        handleSetPersonForQuickNote(person);
      },
    })
  )
);

const Wrapper = ({
  peerActionsStatusData,
  handleClickRequestFeedback,
  handleClickGiveQuickNote,
  isFeedbackRequested,
  renderAnchor,
  renderPopover,
  person,
}) => {
  const { loading: experimentLoading, assignment } = useExperiment({ name: 'default-avatar' });
  const { loading } = peerActionsStatusData;
  const { getPeerActionsStatus } = peerActionsStatusData;
  if (loading || !getPeerActionsStatus || experimentLoading) { return renderAnchor(); }
  const { quickNote, requestFeedback } = getPeerActionsStatus;
  const requestFeedbackCopy = (() => {
    if (requestFeedback.pendingInvitation || isFeedbackRequested) {
      return 'Feedback Requested';
    }
    return 'Request Feedback';
  })();
  const isRequestFeedbackDisabled = (() => {
    if (isFeedbackRequested) { return true; }
    if (!requestFeedback.canBeRequested) { return true; }
    if (requestFeedback.pendingInvitation) { return true; }
    return false;
  })();
  return renderPopover({
    content: (
      <ProfilePreviewCard
        person={person}
        isRequestFeedbackDisabled={isRequestFeedbackDisabled}
        isShowQuickNote={quickNote.canBeGiven}
        requestFeedbackCopy={requestFeedbackCopy}
        handleClickRequestFeedback={handleClickRequestFeedback}
        handleClickGiveQuickNote={handleClickGiveQuickNote}
        defaultAvatarIsGenerated={assignment === 'treatment'}
      />
    ),
  });
};

Wrapper.propTypes = {
  person: personPropType.isRequired,
  isFeedbackRequested: PropTypes.bool.isRequired,
  handleClickRequestFeedback: PropTypes.func.isRequired,
  handleClickGiveQuickNote: PropTypes.func.isRequired,
  renderAnchor: PropTypes.func.isRequired,
  renderPopover: PropTypes.func.isRequired,
  peerActionsStatusData: propType(PEER_ACTIONS_STATUS_QUERY).isRequired,
};

const WrapperWithData = withData(Wrapper);

WrapperWithData.propTypes = {
  person: personPropType.isRequired,
  handleSetPersonForQuickNote: PropTypes.func.isRequired,
  handleClosePopover: PropTypes.func.isRequired,
};

export default WrapperWithData;
