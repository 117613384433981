import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, fill }) => (
  <svg className={className} width="4" height="13" viewBox="0 0 4 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.65701 4.73988H0.341101L0.000682512 11.6603C-0.0119258 11.8494 0.151979 12.0133 0.3411 12.0133H3.66962C3.85874 12.0133 4.02264 11.8494 3.99743 11.6603L3.65701 4.73988Z"
      fill={fill}
    />
    <path
      d="M3.76953 1.34906C3.76953 0.605185 3.16435 1.39983e-07 2.42047 7.49515e-08L1.56313 0C0.819254 -6.50315e-08 0.214069 0.605184 0.214069 1.34906L0.214068 1.66426C0.214068 2.40813 0.819253 3.01332 1.56313 3.01332H2.42047C3.16435 3.01332 3.76953 2.40813 3.76953 1.66426V1.34906Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'currentColor',
};

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Icon;
