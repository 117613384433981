import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, TextAreaWithCount } from '@matterapp/matter-ui';
import SkillNoteAbilities from './SkillNoteAbilities';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  & label {
    display: flex;
  }
  ${theme.media.S`
    min-height: 310px;
  `}
`;

const TextArea = styled(TextAreaWithCount.Simple)`
  min-height: 200px;
`;

const noteHasError = (note, min, max) => {
  if (note && note.length) {
    return note.length < min || note.length > max;
  }
  return true;
};

const SkillNote = (props) => {
  const {
    abilities,
    color,
    currentAbilities,
    isShown,
    maxLength,
    minLength,
    onChange,
    onError,
    value,
  } = props;
  const error = noteHasError(value, minLength, maxLength);
  return (
    <Container isShown={isShown}>
      <TextArea
        error={error}
        label=""
        maxLength={maxLength}
        minLength={minLength}
        name="customNote"
        onChange={onChange}
        onError={onError}
        tabIndex="-1"
        value={value || ''}
      >
        <SkillNoteAbilities
          abilities={abilities}
          color={color}
          currentAbilities={currentAbilities}
        />
      </TextArea>
    </Container>
  );
};

SkillNote.SkillNoteAbilities = SkillNoteAbilities;

SkillNote.propTypes = {
  /** List of abilities of the skill. */
  abilities: PropTypes.arrayOf(
    PropTypes.shape({
      ability: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      selected: PropTypes.bool,
    })
  ),
  /** The color of the skill. */
  color: PropTypes.oneOf(Object.values(Button.Simple.buttonColors)),
  /** The currently selected abilities ids. */
  currentAbilities: PropTypes.arrayOf(PropTypes.string),
  /** The max length of the custom note. */
  maxLength: PropTypes.number,
  /** The min length of the custom note. */
  minLength: PropTypes.number,
  /** Callback when the value of the note changes. */
  onChange: PropTypes.func,
  /** The value of the custom note. */
  value: PropTypes.string,
  isShown: PropTypes.bool,
  onError: PropTypes.func,
};

SkillNote.defaultProps = {
  value: '',
};

export default SkillNote;
export { noteHasError };
