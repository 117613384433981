import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../Avatar';
import {
  Container,
  AnimationContainer,
  Label,
  LabelContainer,
  TextContainer,
  SelectKudos,
  SelectKudosContainer,
  Text,
  TextEdit,
  DEFAULT_ANIMATION_NAME,
  DEFAULT_TEXT_COLOR,
  MAX_MESSAGE_LENGTH,
} from './styles';
import { PLACEHOLDER } from '../consts';
import { useCard, useKudosDropdown, useKudosEdit, useKudosView } from './hooks';

const EditMode = (props) => {
  const { containerProps, textAreaProps } = useKudosEdit(props);
  return (
    <TextContainer {...containerProps}>
      <TextEdit {...textAreaProps} />
    </TextContainer>
  );
};

const ViewMode = (props) => {
  const { text } = useKudosView(props);
  const { textColor } = props;
  return (
    <TextContainer>
      <Text textColor={textColor}>{text}</Text>
    </TextContainer>
  );
};

const KudosDropdown = (props) => {
  const { dropdownContainerProps, ...dropdownProps } = useKudosDropdown(props);
  const labelProps = {
    width: 112,
    noWrap: true,
  };

  return (
    <SelectKudosContainer {...dropdownContainerProps}>
      <SelectKudos {...dropdownProps} labelProps={labelProps}/>
    </SelectKudosContainer>
  );
};

const Card = React.forwardRef((props, ref) => {
  const {
    AnimationComponent,
    animationContainerProps,
    animationProps,
    containerProps,
    dropdownProps,
    editProps,
    isEditMode,
    isLocked,
    label,
    labelColor,
    textColor,
    kudosPhotoUrl
  } = useCard(props);
  return (
    <Container {...containerProps} ref={ref}>
      {isEditMode ? <EditMode {...editProps} textColor={textColor} /> : <ViewMode {...props} textColor={textColor}/>}
      <AnimationContainer {...animationContainerProps}>
        <LabelContainer color={labelColor} isLocked={isLocked}>
          {isEditMode ? (
            <KudosDropdown {...dropdownProps} />
          ) : (
            <Label>{label}</Label>
          )}
        </LabelContainer>
        {AnimationComponent && <AnimationComponent {...animationProps} />}
        {!AnimationComponent && kudosPhotoUrl && (
          <Avatar
            size={120}
            url={kudosPhotoUrl}
            squaredWithoutBorder
          />)
        }
      </AnimationContainer>
    </Container>
  );
});

Card.defaultProps = {
  animationFlush: false,
  animationMountDelay: 200,
  animationName: DEFAULT_ANIMATION_NAME,
  maxMessageLength: MAX_MESSAGE_LENGTH,
  onChangeMessage: () => null,
  onChangeType: () => null,
  onMouseEnter: () => {},
  placeholder: PLACEHOLDER,
  playAnimation: false,
  playAnimationOnClick: true,
  playAnimationOnMount: false,
  playAnimationOnHover: false,
  text: null,
  textColor: DEFAULT_TEXT_COLOR,
};

Card.propTypes = {
  /** The animation component in matter-ui `<Animations>`. */
  animation: PropTypes.elementType,
  /** Top and bottom edge of the animation is flushed to the top edge. */
  animationIsFlush: PropTypes.bool,
  /** Amount of milliseconds to delay animation on mount. */
  animationMountDelay: PropTypes.number,
  /** Auto focus the text area on edit mode. */
  autoFocusOnEdit: PropTypes.bool,
  /** The background color of the kudo. */
  backgroundColor: PropTypes.string,
  /** Locks all kudos reguardless if unlocked. */
  isAllLocked: PropTypes.bool,
  /** If kudos is locked. */
  isLocked: PropTypes.bool,
  /** The label text of the kudo. */
  label: PropTypes.string,
  /** Callback when message changes. */
  onChangeMessage: PropTypes.func,
  /** Callback when kudos type changes. */
  onChangeType: PropTypes.func,
  /** Boolean flag to manually trigger animation. */
  playAnimation: PropTypes.bool,
  /** Plays animation on click. */
  playAnimationOnClick: PropTypes.bool,
  /** Plays animation on hover. */
  playAnimationOnHover: PropTypes.bool,
  /** Plays the animation when component mounts. */
  playAnimationOnMount: PropTypes.bool,
  /** The maximum length of message to display. */
  maxMessageLength: PropTypes.number,
  /** Callback when unlock text is clicked on a locked */
  onClickUnlock: PropTypes.func,
  /**
   * Callback when mouse enters kudo.
   * @param { Object } e: The mouseenter event.
   * @param { Object } kudoProps: The props of the kudos including the value.
   */
  onMouseEnter: PropTypes.func,
  /** Displays the locked (pro) kudos in edit mode. */
  showLockedKudos: PropTypes.bool,
  /** The text to display inside of the kudo. */
  text: PropTypes.string,
  /** Color of the text. */
  textColor: PropTypes.string,
  /** The type of the kudo. */
  type: PropTypes.string,
};

export default Card;
