import Base from './Button';
import ButtonNew from './Old/ButtonNew';
import ResponsiveButton from './Old/ResponsiveButton';
import ButtonCircle from './Old/ButtonCircle';
import ButtonSocial from './Old/ButtonSocial';
import ButtonMarketing from './Old/Marketing';

const Button = Base;
Button.Simple = Base;

/** Deprecated way to import button, use default export `Button.<ButtonName>` */
export {
  ButtonNew,
  ResponsiveButton,
  ButtonCircle,
  Base as ButtonSimple,
  ButtonSocial,
  ButtonMarketing,
};

export default Button;
