import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import styled from 'styled-components';
import { 
  Typography,
  Box,
  Paper
} from '@mui/material';
import { Button } from '@matterapp/matter-ui'; 
import ProgressUnit from 'components/ProgressUnit/ProgressUnit';
import Badge from './badge.svg';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import { SkillColorPalette } from '@matterapp/skills';

export function getSlag(name) {
  return name.toLowerCase().replace(/\s/g, '-');
}

const EngagementWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));

const QuestionsButton = styled(Button)(({ theme }) => ({
  minWidth: '185px',
  boxSizing: 'content-box',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  minHeight: 'auto',
  marginLeft: theme.spacing(4),
  [theme.breakpoints.down('md')]:{
    alignSelf: 'flex-start',
    marginLeft: 0,
    marginTop: theme.spacing(2)
  }
}));

const EngagementCategoriesContainer = styled(Box)(({ theme }) => ({
  display: 'inline-grid',
  margin: `${theme.spacing(3)} 0`,
  gridTemplateColumns: 'auto auto auto auto auto',
  gridColumnGap: theme.spacing(2),
  gridRowGap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '50% 50%',
    gridColumnGap: '7px',
    gridRowGap: '7px',
  }
}));

const Wrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(2)}`
  }
}));

export default function EngagementCategories({ 
  analyticsData,
  isPulseUnlocked
}) {
  const { currentWorkspaceId: workspaceId } = useContext(UserContext);
  const { engagementCategories = [] } = analyticsData || {};
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Paper>
        <Typography variant='h4' component='p' sx={{mb: '5px' }}>Engagement Categories</Typography>
        <EngagementWrapper>
          <Typography variant='body1' component='p' sx={{ color: 'blacks.50' }}>
            Each pulse survey question is linked to an engagement category. 
            Categories portray how your employees think & feel and how invested they are in their roles. You can fully customize the question library to meet your needs.
          </Typography>
          <QuestionsButton onClick={() => {
            navigate(Resources.pulseQuestionLibrary.path({ workspaceId }));
          }}>
            View Question Library
          </QuestionsButton>
        </EngagementWrapper>
      </Paper>
      <EngagementCategoriesContainer>
        {[...engagementCategories]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(category => {
            let customColor;
            let tooltipText;
  
            if (category.score < 51) {
              customColor = SkillColorPalette.Red;
              tooltipText = '0-50%: Low';
            } else if (category.score < 70) {
              customColor = SkillColorPalette.Yellow;

              if (category.score < 66) {
                tooltipText = '51-65%: Moderate';
              } else {
                tooltipText = '66-70%: Good!';
              }
            } else if (category.score >= 70) {
              customColor = SkillColorPalette.Green;

              if (category.score < 86) {
                tooltipText = '71-85%: Great!';
              } else {
                tooltipText = '86-100%: Excellent!';
              }
            }

            if (category.score == null) {
              customColor = 'Black';
              tooltipText = 'No score yet';
            }

            function onButtonClick() {
              navigate(Resources.pulseCategoryDetails.path({ workspaceId, categoryId: category.id }));
            }

            const tooltipContent = (<Typography variant='body2' sx={{ p: 1 }}>{tooltipText}</Typography>);

            return (
              <ProgressUnit 
                sx={{ minWidth: '150px' }}
                maxRating={100}
                key={category.name}
                name={category.name}
                customColor={customColor}
                value={parseInt(category.score)}
                onButtonClick={isPulseUnlocked ? onButtonClick : null}
                buttonText='View Details'
                showProvidedScore
                badge={<img src={Badge} />}
                displayScoreFn={n => n || n === 0 ? `${n.toFixed(0)}%` : '-' }
                tooltipContent={tooltipContent}
              />
            );
          })}
      </EngagementCategoriesContainer>
    </Wrapper>
  );
}
