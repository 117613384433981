import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clickable, IconNew as Icon } from '@matterapp/matter-ui';
import { colors, border, font, lineHeight, spacing } from '@matterapp/matter-theme';

const Container = styled(Clickable)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px dashed ${colors.blues[10]};
  border-radius: ${border.radius.S};
  padding: ${spacing.threeQuarters} ${spacing.single};
  width: 100%;
  color: ${colors.blues[30]};
`;

const LeftContainer = styled.div`
  display: flex;
`;

const GiftIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${border.radius.S};
  height: ${spacing.triple};
  width: ${spacing.triple};;
  background: ${colors.blues[5]};

  & svg {
    height: 30px;
    width: 30px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${spacing.single};
  font-size: ${font.size.S};
  font-weight: ${font.weight.semiBold};
  line-height: ${lineHeight.M};
`;

const DescriptionText = styled.div`
  font-size: ${font.size.base};
  font-weight: ${font.weight.regular};
  line-height: ${lineHeight.M};
`;

const AddIcon = styled(Icon).attrs({ name: 'addv2', fill: colors.blues[30] })``;
const RewardsIcon = styled(Icon).attrs({ name: 'navigationRewards', fill: colors.blues[30] })``;

const EmptyCustomRewards = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <LeftContainer>
        <GiftIconContainer>
          <RewardsIcon />
        </GiftIconContainer>
        <TextContainer>
          Your First Custom Reward
          <DescriptionText>
            250 coins  |  Qty: Unlimited
          </DescriptionText>
        </TextContainer>
      </LeftContainer>
      <AddIcon />
    </Container>
  );
};

EmptyCustomRewards.propTypes = {
  onClick: PropTypes.func,
};

export default EmptyCustomRewards;