import React from 'react';
import { SVG, Path } from './consts';

const AdminSettings = (props) => (
  <SVG {...props}>
    <Path
      d="M19.1,12.9c0-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6-0.1-0.9l2-1.5c0.2-0.1,0.2-0.4,0.1-0.6l-1.9-3.2c-0.1-0.2-0.4-0.3-0.6-0.2
        l-2.4,0.9c-0.5-0.4-1-0.7-1.6-0.9l-0.4-2.5c0-0.2-0.2-0.4-0.5-0.4H10c-0.2,0-0.4,0.2-0.5,0.4L9.1,5.6C8.5,5.8,8,6.2,7.5,6.5l-2.3-1
        C5,5.4,4.8,5.5,4.7,5.7L2.8,8.9C2.7,9.1,2.7,9.4,2.9,9.5l2,1.5c0,0.3-0.1,0.6-0.1,0.9c0,0.3,0,0.6,0.1,0.9l-2,1.5
        c-0.2,0.1-0.2,0.4-0.1,0.6l1.9,3.2c0.1,0.2,0.4,0.3,0.6,0.2l2.4-0.9c0.5,0.4,1,0.7,1.6,0.9l0.4,2.5c0,0.2,0.2,0.4,0.5,0.4H14
        c0.2,0,0.4-0.2,0.5-0.4l0.4-2.5c0.6-0.2,1.1-0.6,1.6-0.9l2.4,0.9c0.2,0.1,0.5,0,0.6-0.2l1.9-3.2c0.1-0.2,0.1-0.5-0.1-0.6L19.1,12.9z
        M12,15.3c-1.9,0-3.4-1.5-3.4-3.3s1.5-3.3,3.4-3.3s3.4,1.5,3.4,3.3S13.9,15.3,12,15.3z"
    />
  </SVG>
);

export default AdminSettings;
