import React from 'react';
import Base from './Base';
import Group from './Group';
import Default from './variants/Default';
import Icon from './variants/Icon';
import Link from './variants/Link';
import Minimal from './variants/Minimal';
import Primary from './variants/Primary';
import Text from './variants/Text';
import Toggle from './variants/Toggle';
import { buttonColors, buttonSizes, buttonTypes } from './Base/props';

import ButtonNew from './Old/ButtonNew';
import ResponsiveButton from './Old/ResponsiveButton';
import ButtonCircle from './Old/ButtonCircle';
import ButtonSocial from './Old/ButtonSocial';
import ButtonMarketing from './Old/Marketing';

export default class Button extends React.PureComponent {
  static Group = Group;

  static Icon = Icon;
  static Link = Link;
  static Minimal = Minimal;
  static Primary = Primary;
  static Text = Text;
  static Toggle = Toggle;

  static buttonColors = buttonColors;
  static buttonSizes = buttonSizes;
  static buttonTypes = buttonTypes;

  static Base = Base;
  static Circle = ButtonCircle;
  static New = ButtonNew;
  static Responsive = ResponsiveButton;
  static Social = ButtonSocial;
  static Marketing = ButtonMarketing;

  render() {
    return <Default {...this.props}/>;
  }
}