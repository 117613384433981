"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _buttonShake = _interopRequireDefault(require("./buttonShake"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const animations = {
  buttonShake: _buttonShake.default
};
var _default = animations;
exports.default = _default;