import React from 'react';
import PropTypes from 'prop-types';
import FeedItemsList from 'modules/feed/FeedItemsList';

const FeedbackFeed = (props) => {
  const {
    canLoadMoreItems,
    currentUser,
    feedExists,
    feedItems,
    loading,
    noFeedbackContent,
    emptyStateContent,
    onLoadMore,
    updateResultsOnComment,
    updateResultsOnDelete,
    showEmptyState,
    workspaceId,
  } = props;

  if (!(feedItems && currentUser)) {
    return null;
  }

  if (showEmptyState) {
    return emptyStateContent;
  }

  if (!loading && !feedItems.length) {
    return noFeedbackContent;
  }

  return (
    <FeedItemsList
      canLoadMoreItems={canLoadMoreItems}
      feedItemsList={feedItems}
      isLoading={!feedItems}
      onLoadMore={onLoadMore}
      feedExists={feedExists}
      loading={loading}
      updateResultsOnComment={updateResultsOnComment}
      updateResultsOnDelete={updateResultsOnDelete}
      workspaceId={workspaceId}
    />
  );
};

FeedbackFeed.defaultProps = {
  canLoadMoreItems: false,
  noFeedbackContent: null,
};

FeedbackFeed.propTypes = {
  canLoadMoreItems: PropTypes.bool,
  currentUser: PropTypes.object,
  feedExists: PropTypes.bool,
  feedItems: PropTypes.array,
  loading: PropTypes.bool,
  // Content to show if there is no feedback.
  noFeedbackContent: PropTypes.node,
  onLoadMore: PropTypes.func,
  updateResultsOnComment: PropTypes.func,
  updateResultsOnDelete: PropTypes.func,
  showEmptyState: PropTypes.bool,
  emptyStateContent: PropTypes.node,
  workspaceId: PropTypes.string,
};

export default FeedbackFeed;
