import React from 'react';
import PropTypes from 'prop-types';
import TagSkillModal from './TagSkillModal';
import { useGiveFeedback } from './hooks';
import ConfirmResetModal from '../ConfirmResetModal';
import {
  Container,
  EmailDropdown,
  Message,
  MessageContainer,
  SkillTagger,
  Wrapper,
} from './styles';
import { CancelButton, DisabledMessage, FooterContainer, SendButton } from '../sharedStyles';

const GiveFeedback = (props) => {
  const {
    confirmResetModalProps,
    disabledMessage,
    hasSelectedUser,
    messageProps,
    onClickCancel,
    selectUserDropdownProps,
    sendButtonProps,
    skillTaggerProps,
    tagSkillModalProps,
  } = useGiveFeedback(props);
  if (disabledMessage) {
    return (
      <Wrapper>
        <DisabledMessage>{disabledMessage}</DisabledMessage>
      </Wrapper>
    );
  }
  return (
    <Container>
      <Wrapper>
        <EmailDropdown {...selectUserDropdownProps} />
        {hasSelectedUser && (
          <>
            <MessageContainer>
              <Message {...messageProps} />
            </MessageContainer>
            <SkillTagger {...skillTaggerProps} />
            <TagSkillModal {...tagSkillModalProps} />
          </>
        )}
      </Wrapper>
      {hasSelectedUser && (
        <FooterContainer>
          <CancelButton onClick={onClickCancel} />
          <SendButton {...sendButtonProps} />
        </FooterContainer>
      )}
      <ConfirmResetModal {...confirmResetModalProps} />
    </Container>
  );
};

GiveFeedback.defaultProps = {
  defaultMessage: '',
  messageMinLength: 25,
  messageMaxLength: 2000,
  onSelectUser: () => {},
  placeholder: 'What kind of feedback would you like to provide?',
  skills: [],
  users: [],
};

GiveFeedback.propTypes = {
  defaultMessage: PropTypes.string,
  disabledMessage: PropTypes.node,
  messageMinLength: PropTypes.number,
  messageMaxLength: PropTypes.number,
  placeholder: PropTypes.string,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
      userId: PropTypes.string,
    })
  ),
};

export default GiveFeedback;
