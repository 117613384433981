import {
  NavigationHome,
  NavigationMembers,
  NavigationProfile,
  NavigationPreferences,
  NavigationRequestStatus,
  NavigationSkills,
  NavigationFeedbacks,
  NavigationRewards,
} from '../../Icon/svg/Navigation';

export const icons = {
  home: NavigationHome,
  members: NavigationMembers,
  profile: NavigationProfile,
  preferences: NavigationPreferences,
  requestStatus: NavigationRequestStatus,
  skills: NavigationSkills,
  feedbacks: NavigationFeedbacks,
  rewards: NavigationRewards
};

export default icons;
