import PropTypes from 'prop-types';

export const Person = 
  PropTypes.shape({
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string.isRequired,
  });

export const SkillsStatus = 
  PropTypes.shape({
    type: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        skill: PropTypes.shape({
          name: PropTypes.string
        })
      })
    )
  });

export const InvitationProps = 
  PropTypes.shape({
    invitedPeer: Person.isRequired,
    skillsStatus: SkillsStatus.isRequired,
    customNote: PropTypes.string,
    eventTime: PropTypes.string.isRequired,
    reminders: PropTypes.shape({
      next: PropTypes.string,
    }),
    feedbackRequestStatus: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  });