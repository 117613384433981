import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import omitStyled from '../../libs/omitStyled';
import theme from '@matterapp/matter-theme';
import { Avatar } from '../../components';
import { smallBreakpoint } from './consts';
import CheckmarkSvg from './svgs/checkmark.svg';

const Container = styled.div`
  border: 1px solid ${() => theme.colors.blues[10]};
  border-radius: 16px;
  margin: 0 24px 16px 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 16px;

  border-radius: 16px 16px 0 0;
  background-color: ${() => theme.colors.blues[10]};

  img {
    display: block;
    margin-right: 16px;
  }

  ${() => theme.media.breakpoint(smallBreakpoint)`
    flex-direction: column;
    margin-top: 0;

    span {
      text-align: inherit;
    }

    img.avatar {
      margin-right: 0;
      margin-bottom: 8px;
    }
  `};
`;

const UserFullName = omitStyled('div', ['showCheckmark'])`
  display: flex;
  align-items: flex-start;
  font-size: 17px;
  line-height: 24px;
  font-weight: bold;
  color: ${() => theme.colors.blacks[90]};

  img.checkmark {
    display: none;
  }

  ${({ showCheckmark }) =>
    showCheckmark &&
    theme.media.medium`
    margin-left: 24px;
  `}

  ${({ showCheckmark }) =>
    showCheckmark &&
    `
    img.checkmark {
      display: inline-block;
      margin-top: 2px;
      margin-left: 4px;
    }
  `};
`;

const UserHeadline = styled.div`
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: left;
  color: ${() => theme.colors.blues[60]};

  ${() => theme.media.medium`
    text-align: center;
  `}
`;

const Body = styled.div`
  background: white;
  padding: 16px;
  border-radius: 0 0 16px 16px;

  font-size: 17px;
  line-height: 24px;
`;

class ProfileBlock extends React.Component {
  renderHeadline = (headline) => {
    if (headline === null) {
      return null;
    }
    return <UserHeadline>{headline}</UserHeadline>;
  };

  render() {
    const { user, avatarSize, renderBody } = this.props;
    return (
      <Container>
        <Header>
          <Avatar url={user.photoUrl} size={avatarSize} />
          <div>
            <UserFullName showCheckmark={user.photoUrl}>
              <span>{user.fullName}</span>
              <img
                src={CheckmarkSvg}
                className="checkmark"
                alt="Matter Checkmark"
                width={16}
                height={16}
              />
            </UserFullName>
            {this.renderHeadline(user.headline)}
          </div>
        </Header>
        <Body>{renderBody()}</Body>
      </Container>
    );
  }
}

ProfileBlock.defaultProps = {
  avatarSize: 64,
  renderBody: () => {},
};

ProfileBlock.propTypes = {
  avatarSize: PropTypes.number,
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    headline: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  renderBody: PropTypes.func,
};

export default ProfileBlock;
