import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { PopoverMenu } from '../../Menu';

const menuTop = 52;
const width = 288;

const HeaderMenu = styled(PopoverMenu).attrs({
  width,
  position: 'right',
  hasBorder: true,
  isFullWidthMobile: true,
  hasTriangle: true,
})`
  .popover-menu__menu {
    position: fixed;
    top: ${menuTop}px;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    > div:first-child {
      right: 26px !important;
      margin-right: 0 !important;
    }

    ${() => theme.media.medium`
      position: absolute;
      right: 0 !important;
      margin-right: -18px !important;
      bottom: auto;
      border-bottom-left-radius: ${theme.sizes.borderRadius[80]};
      border-bottom-right-radius: ${theme.sizes.borderRadius[80]};
    `}
  }
`;

export default HeaderMenu;
