import React from 'react';
import PropTypes from 'prop-types';
import Animation from '../Animation';
import styled from 'styled-components';
import gsap from 'gsap';
import SVG from '../../SVG';
import theme from '@matterapp/matter-theme';

const DEFAULT_HEIGHT = 190;
const DEFAULT_WIDTH = 302;

const DarkStar = styled.path`
  fill: ${({ color }) => {
    const colorBase = theme.colors[`${color}s`];
    return colorBase[40];
  }};
`;

const LightStar = styled.path`
  fill: ${({ color }) => {
    const colorBase = theme.colors[`${color}s`];
    return colorBase[5];
  }};
`;

export const colorList = ['red', 'orange', 'yellow', 'green', 'blue', 'purple'];

export default class FlyingStars extends Animation {
  static propTypes = {
    /** The amount of milliseconds to delay animation. */
    animationDelay: PropTypes.number,
    /** The color of the stars. */
    color: PropTypes.oneOf(colorList),
    /** The height of the animation in pixels. */
    height: PropTypes.number,
    /** The width of the animation in pixels. */
    width: PropTypes.number,
  };

  static defaultProps = {
    animationDelay: 0.8,
    color: 'yellow',
    height: DEFAULT_HEIGHT,
    width: DEFAULT_WIDTH,
  };

  playAnimation = ({ initialize } = {}) => {
    const { animationDelay } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initProps = {
      transformOrigin: 'center',
      scale: 0.2,
      opacity: 0,
      rotation: 300,
    };
    const pulseProps = {
      duration: 0.08,
      scale: 1.6,
      fill: '#ffffff',
      opacity: 0.5,
    };
    const resetProps = { x: 0, y: 0, scale: 0.8, opacity: 1, rotation: 0 };

    tl.to(this.topLeftStarRef, { x: 70, y: 50, ...initProps });
    tl.to(this.topRightStarRef, { x: -80, y: 50, ...initProps });
    tl.to(this.bottomLeftStarRef, {
      x: 80,
      y: -50,
      ...initProps,
    });
    tl.to(this.bottomRightStarRef, {
      x: -80,
      y: -50,
      ...initProps,
    });

    if (!initialize) {
      tl.to(
        this.topLeftStarRef,
        { ...resetProps, duration: 0.25 },
        `<${animationDelay}`
      );
      tl.to(this.bottomRightStarRef, { ...resetProps, duration: 0.2 }, '<0.1');
      tl.to(this.bottomLeftStarRef, { ...resetProps, duration: 0.4 }, '<-0.2');
      tl.to(this.topRightStarRef, { ...resetProps, duration: 0.3 }, '<');

      tl.to(this.topRightStarRef, { ...pulseProps }, '>');
      tl.to(this.topRightStarRef, { duration: 0.05, clearProps: 'all' }, '>');

      tl.to(this.bottomLeftStarRef, { ...pulseProps }, '>0.05');
      tl.to(this.bottomLeftStarRef, { duration: 0.05, clearProps: 'all' }, '>');

      tl.to(this.bottomRightStarRef, { ...pulseProps }, '>-0.05');
      tl.to(
        this.bottomRightStarRef,
        { duration: 0.05, clearProps: 'all' },
        '>'
      );

      tl.to(this.topLeftStarRef, { ...pulseProps }, '>0.05');
      tl.to(this.topLeftStarRef, { duration: 0.05, clearProps: 'all' }, '>');
    }
    return tl;
  };

  resetAnimation = () => {
    return this.playAnimation({ initialize: true });
  };

  render() {
    const { color, height, width, ...otherProps } = this.props;

    return (
      <SVG
        {...otherProps}
        height={height}
        width={width}
        viewBox={`0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`}
      >
        <DarkStar
          ref={(ref) => (this.topLeftStarRef = ref)}
          color={color}
          d="M0,33.777c0,0,18.724-1.499,25.723-8.7C32.344,18.265,33.954,0,33.954,0
          c3.601,24.054,11.035,31.449,33.954,33.777c0,0-17.164,1.884-24.437,8.956c-7.458,7.252-9.518,24.821-9.518,24.821
          C32.643,42.708,25.208,35.313,0,33.777z"
        />
        <DarkStar
          ref={(ref) => (this.bottomRightStarRef = ref)}
          color={color}
          d="M234.091,148.223c0,0,18.724-1.499,25.723-8.701
          c6.622-6.812,8.232-25.077,8.232-25.077c3.601,24.054,11.035,31.449,33.954,33.778c0,0-17.164,1.884-24.437,8.956
          c-7.458,7.251-9.517,24.821-9.517,24.821C266.734,157.154,259.3,149.758,234.091,148.223z"
        />
        <LightStar
          ref={(ref) => (this.topRightStarRef = ref)}
          color={color}
          d="M253.599,7.961l8.196,16.376l18.314,2.628l-13.242,12.762l3.125,17.993l-16.393-8.491
          l-16.393,8.491l3.15-17.993l-13.268-12.762l18.314-2.628L253.599,7.961z"
        />
        <DarkStar
          ref={(ref) => (this.bottomLeftStarRef = ref)}
          color={color}
          d="M51.839,173.867c0,0,8.943-0.716,12.287-4.156c3.163-3.254,3.932-11.978,3.932-11.978
          c1.72,11.489,5.271,15.022,16.218,16.134c0,0-8.198,0.9-11.672,4.278C69.041,181.608,68.057,190,68.057,190
          C67.43,178.132,63.88,174.6,51.839,173.867z"
        />
      </SVG>
    );
  }
}
