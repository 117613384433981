import React, { useEffect, useState, useContext } from 'react';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import UserContext from 'context/UserContext/UserContext';
import { 
  Box,
  Typography,
  TableHead,
  TableSortLabel,
  TableBody,
  Table
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import SurveyEditorModal from './SurveyEditor/SurveyEditorModal';
import getCustomSurveys from 'graphql-queries/queries/surveys/getCustomSurveys';
import { useQuery } from '@apollo/client';
import {
  NewSurveyButton,
  StyledBodyRow,
  StyledDarkMenu,
  NoComments,
  MainSection,
  TopSection,
  FilterTabsContainer,
  FilterTab,
  HeaderCell,
  MainTableCell,
  MenuTableCell,
  DesktopOnlyCell,
  Caption,
  StyledTableRow,
  SurveyName,
  TitleHeaderCell
} from './styles';
import ConfirmSurveyModal from './ConfirmSurveyModal';
import EditNotificationsModal from './EditNotificationsModal';
import { Resources } from '@matterapp/routing';
import useSurveyMenu, { FILTERS } from './useSurveyMenu';
import { useQueryParams } from 'hooks';
import moment from 'moment-timezone';

export default function SurveysListView() {
  const { setTopNavItem } = useContext(MobileMenuContext);
  const { currentTenant, currentWorkspace, isAdminMember, history, isLoadingCurrentWorkspace } = useContext(UserContext);
  const [activeFilter, setActiveFilter] = useState(FILTERS.ALL);
  const { queryParams, setQueryParams } = useQueryParams();
  const [order, setOrder] = useState('desc');
  const { 
    editSurvey, 
    getMenuMap, 
    isEditorOpen, 
    setIsEditorOpen,
    isConfirmModalOpen, 
    confirmModalProps,
    setEditSurvey,
    setIsConfirmModalOpen,
    setConfirmModalProps,
    editNotifications,
    setEditNotifications
  } = useSurveyMenu();
  const navigate = useNavigate();
  const location = useLocation();
  const { slackWorkspace } = currentWorkspace || {};
  const { data, refetch, loading } = useQuery(getCustomSurveys, {
    variables: {
      tenantId: currentTenant?.id
    },
    fetchPolicy: 'network-only',
    skip: !currentTenant
  }); 

  const { customSurveys = [] } = data || {};
  const currentTabSurveys = [...customSurveys].filter(survey => {
    if (activeFilter === FILTERS.ALL) {
      return true;
    }

    if (activeFilter === FILTERS.DRAFT) {
      return survey.status === 'draft';
    } else if (activeFilter === FILTERS.ACTIVE) {
      return survey.status === 'active' || survey.status === 'scheduled';
    } else {
      return survey.status === 'closed';
    }
  });

  useEffect(() => {
    if (loading || isLoadingCurrentWorkspace) {
      return;
    }

    const { surveyId } = queryParams;

    if (surveyId) {
      const survey = customSurveys.find(({ id }) => id == surveyId);

      if (survey) {
        setEditSurvey(survey);
      }
    }

    if (location.pathname?.indexOf('create') > -1) {
      setIsEditorOpen(true);
    } else {
      setIsEditorOpen(false);
    }
  }, [location, queryParams, loading]);

  useEffect(() => {
    setTopNavItem(null);
  }, []);

  useEffect(() => {
    if (queryParams?.activeTab) {
      let currentTab = queryParams.activeTab;

      if (currentTab === 'scheduled') {
        currentTab = 'active';
      }

      setActiveFilter(currentTab);

      delete queryParams.activeTab;

      setQueryParams(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    if (customSurveys?.length && queryParams?.notificationsSettings) {
      const survey = customSurveys.find(({ id }) => id == queryParams.notificationsSettings);

      setEditNotifications(survey);
      setQueryParams({});
    }
  }, [queryParams, customSurveys]);

  return (
    <Box>
      <TopSection>
        <Typography variant='h4'>Your Surveys</Typography>
        <NewSurveyButton
          rcLabel='New Survey'
          size='M'
          color='blue'
          onClick={() => {
            navigate(Resources.createSurveys.path({ workspaceId: currentWorkspace?.id, step: 1 }));
          }}
        >
          New Survey
        </NewSurveyButton> 
      </TopSection>
      <MainSection elevation={1} sx={{ display: 'flex', flexDirection: 'column' }}>
        <FilterTabsContainer>
          <FilterTab 
            isActive={activeFilter === FILTERS.ALL}
            onClick={() => setActiveFilter(FILTERS.ALL)}
          >
            All
          </FilterTab>
          <FilterTab 
            isActive={activeFilter === FILTERS.ACTIVE}
            onClick={() => setActiveFilter(FILTERS.ACTIVE)}
          >
            Active
          </FilterTab>
          <FilterTab 
            isActive={activeFilter === FILTERS.DRAFT}
            onClick={() => setActiveFilter(FILTERS.DRAFT)}
          >
            Draft
          </FilterTab>
          <FilterTab 
            isActive={activeFilter === FILTERS.CLOSED}
            onClick={() => setActiveFilter(FILTERS.CLOSED)}
          >
            Closed
          </FilterTab>
        </FilterTabsContainer>
        <Table size='large'>
          {!currentTabSurveys.length ? (
            loading || !currentTenant ? (
              <Caption>
                <NoComments variant='body1' component='p'>
                  &nbsp;
                </NoComments>
              </Caption>
            ) : (
              <Caption>
                <NoComments variant='h6' component='p'>
                  <b>No surveys yet</b>
                </NoComments>
                <NoComments variant='body1' component='p'>
                  Start from scratch or duplicate an existing survey to get started
                </NoComments>
              </Caption>
            )
          ) : null}
          <TableHead>
            <StyledTableRow>
              <TitleHeaderCell>Title</TitleHeaderCell>
              <HeaderCell>Status</HeaderCell>
              <HeaderCell>Responses</HeaderCell>
              <HeaderCell>Who can access</HeaderCell>
              <HeaderCell visibleOnMobile>
                <TableSortLabel
                  active
                  direction={order}
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                >
                  Activated on
                </TableSortLabel>
              </HeaderCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {currentTabSurveys.sort((a, b) => {
              let itemA = a.activatedOn;
              let itemB = b.activatedOn;

              if (activeFilter === FILTERS.DRAFT) {
                itemA = a.createdAt;
                itemB = b.createdAt;
              }

              if (order === 'desc') {
                return itemB - itemA;
              } else {
                return itemA - itemB;
              }
            }).map((survey) => {
              const { id, name, responsesCount = 0, activatedOn, sentOn, sendDate, status, timezone, createdAt, frequency } = survey;
              let sentText = '';

              if (sentOn) {
                sentText = `Sent ${moment(parseInt(sentOn)).format('MMM DD, YYYY')}`;
              } else if (sendDate && status !== 'draft') {
                sentText = `Sending at ${moment(sendDate).format('H:mm A')} ${moment().tz(timezone).format('z')} on ${moment(sendDate).format('MMM DD, YYYY')}`;
              } else if (status === 'draft') {
                sentText = `Created at ${moment(parseInt(createdAt)).format('MMM DD, YYYY')}`;
              }

              if (frequency !== 'once' && status === 'active') {
                sentText = `Sends ${frequency} | Recurring`;
              }

              const statusCapitalized = status.charAt(0).toUpperCase() + status.slice(1);

              return (
                <StyledBodyRow 
                  onClick={(e) => {
                    navigate(
                      Resources.surveyResultsPage.path({ workspaceId: currentWorkspace?.id, surveyId: id })
                    );
                  }}
                  key={id} 
                >
                  <MainTableCell>
                    <SurveyName variant='body2'>
                      <b>{name}</b>
                    </SurveyName>
                    <Typography variant='body2' color='blacks.50'>
                      {sentText}
                    </Typography>
                  </MainTableCell>
                  <DesktopOnlyCell>
                    <Typography variant='body2'>{statusCapitalized}</Typography>
                  </DesktopOnlyCell>
                  <DesktopOnlyCell>
                    <Typography variant='body2'>{responsesCount}</Typography>
                  </DesktopOnlyCell>
                  <DesktopOnlyCell>
                    <Typography variant='body2'>Only you</Typography>
                  </DesktopOnlyCell>
                  <DesktopOnlyCell>
                    <Typography variant='body2'>{activatedOn ? moment(parseInt(activatedOn)).format('MMM DD, YYYY') : '—'}</Typography>
                  </DesktopOnlyCell>
                  <MenuTableCell>
                    <StyledDarkMenu menuMap={getMenuMap(survey)} />
                  </MenuTableCell>
                </StyledBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </MainSection>
      {isEditorOpen ? (
        <SurveyEditorModal 
          isOpen
          isSlack={!!slackWorkspace}
          history={history}
          onClose={() => {
            setEditSurvey(null);
            refetch(({
              variables: {
                tenantId: currentTenant?.id
              }
            }));

            navigate(Resources.surveys.path({ workspaceId: currentWorkspace?.id }));
          }}
          tenantId={currentTenant?.id}
          workspaceId={currentWorkspace?.id}
          timezone={currentWorkspace?.timezone}
          survey={editSurvey}
          isRewardsActive={currentTenant?.rewardsSettings?.isRewardsActive}
          isAdminMember={isAdminMember}
        />
      ) : null}
      <ConfirmSurveyModal
        isOpen={isConfirmModalOpen}
        onClose={() => {
          setIsConfirmModalOpen(false);
          setConfirmModalProps({});
        }}
        {...confirmModalProps}
      />
      <EditNotificationsModal 
        isOpen={!!editNotifications}
        onClose={() => setEditNotifications(null)}
        survey={editNotifications}
        tenantId={currentTenant?.id}
        currentWorkspace={currentWorkspace}
      />
    </Box>
  );
}
