import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import RequestFeedback from '../RequestFeedback';
import { useRequestComposer } from './hooks';
import { Container, ContentContainer } from './styles';

const Main = (props) => {
  const {
    banner,
    requestFeedbackProps,
    tabProps,
  } = useRequestComposer(props);
  return (
    <Container>
      {banner}
      <Tabs {...tabProps} />
      <ContentContainer>
        <RequestFeedback {...requestFeedbackProps} />
      </ContentContainer>
    </Container>
  );
};

Main.defaultProps = {
  requestFeedbackProps: {},
};

Main.propTypes = {
  banner: PropTypes.node,
  requestFeedbackProps: PropTypes.shape(RequestFeedback.propTypes),
};

export default Main;
