import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Advisors } from '@matterapp/matter-ui';
import { usePrevious } from 'hooks';
import EmptyListBanner from './EmptyListBanner';
import { actionMap } from 'routes/pages/workspaces/hooks/WorkspaceActionModalHooks';

const RecurringList = (props) => {
  const {
    recurringList,
    hasAcceptedMembers,
    actionModalSelectorProps,
    handleSelectMember,
    workspace,
    setIsOutOfKudosModalOpen
  } = props;
  const {
    actionModalSelector,
    openInfoModal,
    openRecurringModalWithDropdown,
    openEditRecurringModal
  } = actionModalSelectorProps;
  const [showBanner, setShowBanner] = useState(false);
  const [dismissBanner, setDismissBanner] = useState(false);
  const prevCount = usePrevious(recurringList);
  const { isFree, kudosLeft } = workspace;

  useEffect(() => {
    if (recurringList.length === 0 && !dismissBanner && !prevCount) {
      setShowBanner(true);
    } 
  }, [recurringList]);

  return (
    <>
      {showBanner && <EmptyListBanner
        dismissBanner={() => {
          setShowBanner(false);
          setDismissBanner(true);
        }}
      />}
      <Advisors.RecurringList
        hasAcceptedMembers={hasAcceptedMembers}
        recurringList={recurringList}
        handleOpenInfoModal={openInfoModal}
        handleSelectMember={handleSelectMember}
        handleSelectAction={async (menuItem, member) => {
          if (menuItem.tooltipContent === actionMap.KUDOS && isFree && kudosLeft <= 0) {
            setIsOutOfKudosModalOpen(true);
          } else {
            await handleSelectMember(member);
            actionModalSelector(menuItem.tooltipContent, member); //TODO: delete member param after removing inline menu
          }
        }}
        handleOpenRecurringModal={openRecurringModalWithDropdown}
        handleOpenEditRecurringModal={openEditRecurringModal}
      />
    </>
  );
};

RecurringList.defaultProps = {
  isLoading: true,
};

RecurringList.propTypes = {
  recurringList: PropTypes.array,
  hasAcceptedMembers: PropTypes.bool,
  actionModalSelectorProps: PropTypes.object,
  handleSelectMember: PropTypes.func,
  workspace: PropTypes.object,
  setIsOutOfKudosModalOpen: PropTypes.func
};

export default RecurringList;
