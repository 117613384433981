import React from 'react';
import { Modal } from '@matterapp/matter-ui'; 
import { Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';

const MessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: `0 ${theme.spacing(1)}`,
  textAlign: 'left',
  marginBottom: theme.spacing(2)
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(2)
}));

const StyledFooter = styled(Modal.Footer.WithCancelSave)(({ theme }) => ({
  '[data-testid="primaryButton"]': {
    ':after': {
      content: '"Upgrade Now"',
      [theme.breakpoints.down('sm')]: {
        content: '"Upgrade"'
      }
    }
  }
}));

export default function PulseUpgradeModal(props) {
  const { isOpen, onClose, currentWorkspace } = props;
  const { teamsWorkspace } = currentWorkspace || {};
  const navigate = useNavigate();
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onClickCancel={onClose}
      confirmButtonLabel={null}
      cancelButtonLabel={null}
    >
      <Modal.Header
        centerOnMobile
        header='Surveys Add-on Required'
      />
      <Modal.Body>
        <Container>
          <MessageContainer>
            <Typography variant='body1' sx={{ mb: '32px' }}>Launch Pulse Surveys and improve company culture! </Typography>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>5 Reasons You&apos;ll Love Pulse Surveys 💜</Typography>
            <Box sx={{ mt: '8px', pl: '15px', mb: 4 }}>
              <Typography variant='body1'> 
                ✅ Fully automated surveys in Slack
              </Typography>
              <Typography variant='body1'> 
                ✅ Measure 10 key engagement categories
              </Typography>
              <Typography variant='body1'> 
                ✅ Measure employee net promoter score (eNPS)
              </Typography>
              <Typography variant='body1'> 
                ✅ Reward survey completions with Matter Coins
              </Typography>
              <Typography variant='body1'> 
                ✅ Receive weekly insights and real-time feedback
              </Typography>
            </Box>
            <Typography variant='body1'><i>Only $2 per user/month, when billed annually</i></Typography>
            <Typography variant='body1' sx={{ mt: 1 }}>
              Questions? Visit our <a href='https://help.matterapp.com' target="_blank" rel="noopener noreferrer">Help Center</a> or <a href="https://matterapp.schedulehero.io/campaign/free-trial" target="_blank" rel="noopener noreferrer">schedule a time</a>
            </Typography>
          </MessageContainer>
        </Container>
      </Modal.Body>
      <StyledFooter
        canClickSave
        onClickSave={async () => {
          onClose();
          navigate(Resources.workspaceSettingsBilling.path({ workspaceId: currentWorkspace.id }, { pulseUpgrade: true }));
        }}
        saveLabel=' '
        cancelLabel='Watch Video'
        onClickCancel={() => {
          window.open(teamsWorkspace ? 'https://matterapp.com/training/pulse-surveys-teams' : 'https://matterapp.com/training/pulse-surveys-slack', '_blank');
          onClose();
        }}
      />
    </Modal>
  );
};
