import React from 'react';
import { AppLayout } from 'modules';
import AddFundingPage from './AddFundingPage';

export default function RewardsAddFunding() {
  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        <AddFundingPage />
      }
    />
  );
};
