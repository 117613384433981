import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const getContainerStyles = ({ isFluid }) => {
  const maxWidth = isFluid ? '' : 'max-width: 335px;';

  return `
    ${maxWidth}
  `;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${theme.borderRadius.L};
  ${getContainerStyles}

  & > ${Image}:first-child {
    border-radius: ${theme.borderRadius.L} ${theme.borderRadius.L} 0 0;
    overflow: hidden;
  }
  & > ${Image}:last-child {
    border-radius: 0 0 ${theme.borderRadius.L} ${theme.borderRadius.L};
    overflow: hidden;
  }
`;

const Image = styled.img`
  width: 335px;
  height: 294px;
`;

const Content = styled.div`
  background: ${theme.colors.white};
  flex: 1 1 auto;
  font-size: ${theme.fontSize.S};
  font-family: ${theme.fontFamilies.base};
  line-height: ${theme.lineHeight.L};
  padding-bottom: ${theme.spacing.single};

  ${Container} > &:first-child {
    border-radius: ${theme.borderRadius.L} ${theme.borderRadius.L} 0 0;
  }
  ${Container} > &:last-child {
    border-radius: 0 0 ${theme.borderRadius.L} ${theme.borderRadius.L};
  }
  & + & {
    border-top: 1px solid ${theme.colors.blacks[20]};
  }
`;

const ContentPadded = styled(Content)`
  padding: 0 ${theme.spacing.singleAndHalf} ${theme.spacing.single};
`;

const Header = styled.div`
  font-size: ${theme.fontSize.XL};
  font-weight: bold;
  line-height: ${theme.lineHeight.XXXL};
  margin: 0;
  padding: ${theme.spacing.singleAndHalf} 0;
`;

export { Container, Content, ContentPadded, Header, Image };
