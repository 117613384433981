import React, { useEffect, useContext } from 'react';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import SurveysListView from './SurveysListView';
import UpgradeView from './UpgradeView';

export default function SurveysDashboardPage() {
  const { setTopNavItem } = useContext(MobileMenuContext);
  const { currentTenant } = useContext(UserContext);
  const { tier, hasPulse } = currentTenant || {};

  useEffect(() => {
    setTopNavItem(null);
  }, []);

  let Component = SurveysListView;

  if (!hasPulse || tier === 'free') {
    Component = UpgradeView;
  }

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={<Component />}
    />
  );
}
