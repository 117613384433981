import { MENU_MIN_WIDTH } from './styles';
import Text from '../../Typography/TextWithReplacement';

// TODO: FEEDBACK types match with backend or pass in items to component instead?
/*****************************************************************************/
/* FEEDBACK TYPES                                                            */
/*****************************************************************************/
export const TYPE_ALL = {
  label: 'All',
  value: 'all',
};

export const TYPE_CONSTRUCTIVE = {
  label: 'Constructive',
  value: 'constructive',
};

export const TYPE_KUDOS = {
  label: 'Kudos',
  value: 'kudos',
};

export const TYPE_KUDOS_DISABLED = {
  disabled: true,
  disabledMessage: 'Kudos are not tied to any skill.',
  label: 'Kudos',
  value: 'kudos',
};

export const FEEDBACK_TYPES = {
  ALL: TYPE_ALL.value,
  CONSTRUCTIVE: TYPE_CONSTRUCTIVE.value,
  KUDOS: TYPE_KUDOS.value,
};

/*****************************************************************************/
/* FEEDBACK FILTERS                                                          */
/*****************************************************************************/
export const FEEDBACK_FILTER = {
  HEADER: 'Feedback Filters',
  CLEAR_BUTTON: 'Clear',
};

export const FROM_FILTER = {
  DROPDOWN_LABEL: 'From',
  MENU_PROPS: {
    position: 'center',
    width: MENU_MIN_WIDTH,
  },
};

export const TYPE_FILTER = {
  DROPDOWN_LABEL: 'Type',
  ITEMS: [TYPE_ALL, TYPE_CONSTRUCTIVE, TYPE_KUDOS],
  ITEMS_KUDOS_DISABLED: [TYPE_ALL, TYPE_CONSTRUCTIVE, TYPE_KUDOS_DISABLED],
  MENU_PROPS: {
    position: 'left',
    width: MENU_MIN_WIDTH,
  },
};

export const SKILL_FILTER = {
  DROPDOWN_LABEL: 'Skill',
  MENU_PROPS: {
    position: 'right',
    width: MENU_MIN_WIDTH,
  },
};

export const FILTER_TYPES = {
  FROM: 'from',
  TYPE: 'type',
  SKILL: 'skill',
};

export const UPGRADE_MESAGE_VIEW_ALL_FEEDBACK = `To view all feedback ${Text.replaceText}`;

export const UPGRADE_LINK_TEXT = 'upgrade to Pro';
export const UPGRADE_LINK_VIEW_ALL_FEEDBACK_TEXT = 'upgrade to Matter Pro';