import Large1 from '../../../../assets/svgs/defaultAvatars/large1.svg';
import Large2 from '../../../../assets/svgs/defaultAvatars/large2.svg';
import Large3 from '../../../../assets/svgs/defaultAvatars/large3.svg';
import Large4 from '../../../../assets/svgs/defaultAvatars/large4.svg';
import Large5 from '../../../../assets/svgs/defaultAvatars/large5.svg';
import Large6 from '../../../../assets/svgs/defaultAvatars/large6.svg';

import Small1 from '../../../../assets/svgs/defaultAvatars/small1.svg';
import Small2 from '../../../../assets/svgs/defaultAvatars/small2.svg';
import Small3 from '../../../../assets/svgs/defaultAvatars/small3.svg';
import Small4 from '../../../../assets/svgs/defaultAvatars/small4.svg';
import Small5 from '../../../../assets/svgs/defaultAvatars/small5.svg';
import Small6 from '../../../../assets/svgs/defaultAvatars/small6.svg';

const defaultProps = {
  size: 128,
  variant: 1,
};

const variants = {
  large: {
    1: Large1,
    2: Large2,
    3: Large3,
    4: Large4,
    5: Large5,
    6: Large6,
  },
  small: {
    1: Small1,
    2: Small2,
    3: Small3,
    4: Small4,
    5: Small5,
    6: Small6,
  },
};

const getVariantFromHash = (hashString) => {
  const calculatedHash = hashString.split('').reduce((total, amount) => {
    return (total += amount.charCodeAt());
  }, 0);
  return calculatedHash % 6;
};

const getDefaultAvatarProps = (props = defaultProps) => {
  const { hashString, size, useRandom, variant } = props;
  const sizeVariant = size > 32 ? 'large' : 'small';
  const imageVariant = hashString ? getVariantFromHash(hashString) : variant;
  return {
    size: sizeVariant,
    variant: useRandom ? Math.floor(Math.random() * 6) + 1 : imageVariant,
  };
};

const getDefaultAvatar = (props = defaultProps) => {
  const { size, variant } = getDefaultAvatarProps(props);
  const sizeVariant = variants[size] || variants.large;
  return sizeVariant[variant] || sizeVariant[1];
};

export { getDefaultAvatar, getDefaultAvatarProps, getVariantFromHash };

export default getDefaultAvatar;
