
import styled from 'styled-components';
import { IconNew } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

export const TooltipContainer = styled.div`
  padding: ${theme.spacing.single};
`;

export const TooltipIconContainer = styled.div`
  display: flex;

  & svg path {
    fill: ${theme.colors.blacks[30]};
  }
`;

export const TooltipTrigger = styled.div`
  align-items: center;
  display: inline-flex;
  margin-left: ${theme.spacing.half};
`;

export const InfoIcon = styled(IconNew).attrs(() => ({
  name: 'infoCircle',
}))``;
