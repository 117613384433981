import React, { useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import {
  Group,
} from './sharedConsts';
import { Input, Button } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { useState } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import getWorkspaceQuery from 'graphql-queries/queries/admin/adminGetWorkspace';
import getRewardsTotalBalance from 'graphql-queries/queries/admin/getRewardsTotalBalance';
import adminGetRewardsFundCreditsQuery from 'graphql-queries/queries/admin/adminGetRewardsFundCredits';
import adminGetRewardsFundDebitsQuery from 'graphql-queries/queries/admin/adminGetRewardsFundDebits';
import adminGetRewardsFundingRecord from 'graphql-queries/queries/admin/adminGetRewardsFundingRecord';
import adminAddRewardsFunds from 'graphql-queries/queries/admin/adminAddRewardsFunds';
import adminApprovePendingFunds from 'graphql-queries/queries/admin/adminApprovePendingFunds';
import adminDeclinePendingFunds from 'graphql-queries/queries/admin/adminDeclinePendingFunds';
import { 
  TextField, 
  Box, 
  Typography, 
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  MenuItem,
  Select
} from '@mui/material';
import { Resources } from '@matterapp/routing';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Toast from 'components/Toast/Toast';
import { 
  NoResultsText
} from 'components/TableStyles';
import { useQueryParams } from 'hooks';
import FundingHistory from 'routes/pages/rewards-manager/FundingHistory/FundingHistory';
import { Confirm } from '@matterapp/matter-ui';

const Caption = styled.caption({
  height: '50px',
  textAlign: 'center',
  'p:first-child': {
    marginTop: '10px'
  }
});

function PendingFunds({ tenantId, rows, workspaceId, tenantName }) {
  const { currentUser } = useContext(UserContext);
  const [showConfirm, updateShowConfirm] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const { queryParams, setQueryParams } = useQueryParams(); 
  const { token: approvalToken } = queryParams;
  
  const [approvePendingFunds, { error: approveError, data: approvedData, loading: approvingFunds }] = useMutation(adminApprovePendingFunds);
  const [declinePendingFunds, { data: declinedData, loading: decliningFunds }] = useMutation(adminDeclinePendingFunds);
  const { data: fundingRecordData } = useQuery(adminGetRewardsFundingRecord, {
    variables: {
      token: approvalToken
    },
    skip: !approvalToken
  });

  function clearConfirm() {
    updateShowConfirm(false);
    setQueryParams({});
    setConfirmData({});
  } 

  useEffect(() => {
    if (approvedData) {
      Toast.success('Funds approved successfully');
    }
  }, [approvedData]);

  useEffect(() => {
    if (declinedData) {
      Toast.success('Request was successfully declined');
    }
  }, [declinedData]);

  useEffect(() => {
    if (approvalToken && fundingRecordData) {
      setConfirmData({
        header: 'Approve Pending Funds',
        amount: (fundingRecordData?.adminRewardsFundingRecord?.amount / 100).toLocaleString(),
        note: fundingRecordData?.adminRewardsFundingRecord?.note,
        confirmButtonLabel: 'Approve',
        onConfirm: () => {
          approvePendingFunds({
            variables: {
              tenantId,
              token: approvalToken
            },
            refetchQueries: [
              {
                query: adminGetRewardsFundCreditsQuery,
                variables: { tenantId }
              }
            ]
          });
        }
      });
      updateShowConfirm(true);
    }
  }, [approvalToken, fundingRecordData]);

  useEffect(() => {
    if (approveError) {
      Toast.error(`Had an error while approving funds: ${approveError.message}`);
    }
  }, [approveError]);

  if (!currentUser) {
    return null;
  }
  
  return (
    <Box sx={{ mt: 4, width: '100%' }}>
      <Confirm
        header={confirmData?.header}
        subHeader={(
          <Box>
            <Typography variant='h6' component='p'>
              <b>Tenant Name:</b> {tenantName}
            </Typography>
            <Typography variant='h6' component='p'>
              <b>Workspace ID:</b> {workspaceId}
            </Typography>
            <Typography variant='h6' component='p'>
              <b>Amount:</b> ${confirmData?.amount}
            </Typography>
            <Typography variant='h6' component='p'>
              <b>Note:</b> {confirmData?.note}
            </Typography>
          </Box>
        )}
        cancelButtonLabel='Cancel'
        confirmButtonLabel={confirmData?.confirmButtonLabel}
        confirmButtonIsDestructive
        isOpen={showConfirm}
        onClose={clearConfirm}
        onClickCancel={clearConfirm}
        onClickConfirm={() => {
          confirmData?.onConfirm();

          clearConfirm();
        }}
      />
      <Typography variant="h4">Pending Funds</Typography>
      <Table size='large' sx={{ mt: 2 }}>
        {!rows.length ? (
          <Caption>
            <NoResultsText variant='h6' component='p'>
              No pending items
            </NoResultsText>
          </Caption>
        ) : null}
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 0 }}>Details</TableCell>
            <TableCell sx={{ pl: 0 }}>Amount</TableCell>
            <TableCell sx={{ pl: 0 }}>Timestamp</TableCell>
            <TableCell sx={{ pl: 0 }}></TableCell>
            <TableCell sx={{ pl: 0 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ id, amount, note, paymentId, createdAt, addedBy }) => {
            return (
              <TableRow key={id}>
                <TableCell size='large' sx={{ width: '50%', pl: 0 }}>
                  {note} stripe id: {paymentId}
                </TableCell>
                <TableCell sx={{ pl: 0 }}>
                  ${(amount / 100).toLocaleString()}
                </TableCell>
                <TableCell sx={{ pl: 0 }}>
                  {String(new Date(createdAt))}
                </TableCell>
                <TableCell sx={{ pl: 0 }}>
                  <Button 
                    size={'S'} 
                    color={'black'} 
                    disabled={parseInt(currentUser?.personId) === parseInt(addedBy?.id) || approvingFunds}
                    onClick={() => {
                      setConfirmData({
                        header: 'Approve Pending Funds',
                        amount: (amount / 100).toLocaleString(),
                        note,
                        confirmButtonLabel: 'Approve',
                        onConfirm: () => {
                          approvePendingFunds({ 
                            variables: {
                              tenantId,
                              recordId: id
                            }
                          });
                        }
                      });
                      updateShowConfirm(true);
                    }}
                  >
                    Approve
                  </Button>
                </TableCell>
                <TableCell sx={{ pl: 0 }}>
                  <Button 
                    size={'S'} 
                    color={'red'} 
                    disabled={decliningFunds}
                    onClick={() => {
                      setConfirmData({
                        header: 'Decline Pending Funds',
                        amount: (amount / 100).toLocaleString(),
                        note,
                        confirmButtonLabel: 'Decline',
                        onConfirm: () => {
                          declinePendingFunds({ 
                            variables: {
                              tenantId,
                              recordId: id
                            }
                          });
                        }
                      });
                      updateShowConfirm(true);
                    }}
                  >
                    Decline
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}

function RenderTenant(props) {
  const [addFundsAmount, setAddFundsAmount] = useState(null);
  const [isCredit, setIsCredit] = useState(true);
  const [note, setNote] = useState('');
  const params = useParams();
  const { workspaceId } = params || {};
  const [addFundsMutation, { loading: addingFunds, error: addingFundsError }] = useMutation(adminAddRewardsFunds);
  const navigate = useNavigate();
  const { tenant } = props || {};

  const { 
    id: tenantId, 
    name, 
    tier,
    rewardsFundingSettings,
    workspaces
  } = tenant || {};
  const { balance, enabled } = rewardsFundingSettings || {};
  const { timezone } = workspaces?.[0] || {};

  const { data: creditData } = useQuery(adminGetRewardsFundCreditsQuery, { 
    variables: { 
      tenantId
    },
    skip: !tenantId
  });

  const { data: debitData } = useQuery(adminGetRewardsFundDebitsQuery, { 
    variables: { 
      tenantId
    },
    skip: !tenantId
  });

  useEffect(() => {
    if (addingFundsError) {
      Toast.error(`Had an error while adding funds: ${addingFundsError.message}`);
    }
  }, [addingFundsError]);

  if (!tenant) {
    return (
      <></>
    );
  }

  return (
    <Box sx={{ m: 4, pb: 10 }}>
      <BreadCrumbs
        crumps={[
          {
            label: 'Admin',
            onClick: () => navigate(Resources.adminAccess.path())
          },
          {
            label: 'Funding', 
            href: null
          }
        ]}
      />
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4">Available Balance: ${(parseInt(balance) / 100).toLocaleString()}</Typography>
        <Typography variant="body1">Tenant name: {name}</Typography>
        <Typography variant="body1">Tier: {tier}</Typography>
        {!enabled && <Typography variant="h5" component='p'>Funding is disabled</Typography>}
      </Box>
      {enabled && (
        <Box sx={{ mt: 4, width: '260px' }}>
          <Typography variant="h4" sx={{ mb: 2 }}>Add Record</Typography>
          <Select
            value={isCredit}
            label="Age"
            onChange={() => setIsCredit(!isCredit)}
            sx={{ mb: 1}}
          >
            <MenuItem value={true}>Account Funding (Credit)</MenuItem>
            <MenuItem value={false}>Account Adjustment (Debit)</MenuItem>
          </Select>
          <TextField
            variant='outlined'
            type='number'
            label='Enter Dollar Amount'
            onChange={(e) => {
              setAddFundsAmount(e.target.value);
            }}
            value={addFundsAmount} 
            sx={{ mb: 1 }}
          />
          <TextField
            variant='outlined'
            label='Note'
            onChange={(e) => {
              setNote(e.target.value);
            }}
            value={note} 
            sx={{ mb: 1 }}
          />
          <Button.Primary.Vivid
            color={'blue'}
            disabled={!addFundsAmount || addingFunds}
            onClick={async () => {
              await addFundsMutation({
                variables: {
                  tenantId,
                  workspaceId,
                  amount: parseInt(addFundsAmount * 100),
                  note,
                  isCredit
                },
                refetchQueries: [
                  {
                    query: adminGetRewardsFundCreditsQuery,
                    variables: { tenantId }
                  },
                  {
                    query: adminGetRewardsFundDebitsQuery,
                    variables: { tenantId }
                  },
                  { 
                    query: getWorkspaceQuery,
                    variables: { workspaceId }
                  }
                ]
              });

              Toast.success('Funds added successfully');

              setAddFundsAmount(null);
              setNote('');
            }}
          >
            Add Record
          </Button.Primary.Vivid>
        </Box>
      )}
      <PendingFunds 
        workspaceId={workspaceId}
        tenantName={name}
        tenantId={tenantId} 
        rows={(creditData?.adminGetRewardsFundCredits || []).filter(({ status }) => status === 'pending')}
      />
      <Box sx={{ mt: 4, width: '100%', mb: 4 }}>
        <Typography variant="h4">Funding History</Typography>
        <FundingHistory 
          creditRows={(creditData?.adminGetRewardsFundCredits || []).filter(({ status }) => status === 'approved')}
          debitRows={debitData?.adminGetRewardsFundDebits || []}
          isAdminView
          tz={timezone}
        />
      </Box>
    </Box>
  );
};

export default function RewardsFunding() {
  const [adminWorkspaceId, setAdminWorkspaceId] = useState();
  const [getAdminWorkspaceDetails, { loading, error, data: workspaceDetailsData }] = useLazyQuery(getWorkspaceQuery);
  const { data: totalBalanceData } = useQuery(getRewardsTotalBalance);
  const navigate = useNavigate();
  const params = useParams();
  const { workspaceId } = params || {};
  const { adminRewardsTotalBalance } = totalBalanceData || {};

  useEffect(() => {
    if (workspaceId) {
      getAdminWorkspaceDetails({
        variables: {
          workspaceId
        }
      });
    }
  }, [workspaceId]);

  if (loading) {
    return <p>Loading ...</p>;
  }
  if (error) {
    return `Error! ${error}`;
  }

  if (workspaceDetailsData) {
    return (
      <RenderTenant tenant={workspaceDetailsData?.adminGetWorkspace} />
    );
  }

  return (
    <Group dividerBelow header={'Rewards account balance funding'}>
      <Typography variant="h5" component='p' sx={{ mb: 2 }}>
        Unearned Revenue: ${((adminRewardsTotalBalance?.totalBalance || 0) / 100).toLocaleString()} USD, across&nbsp; 
        <Typography 
          component='span' 
          sx={{ color: 'blues.50', cursor: 'pointer' }} 
          onClick={() => {
            navigate(Resources.adminAccessFundingBreakdown.path());
          }}
        >
          <b>{adminRewardsTotalBalance?.numberOfTenants} tenants</b></Typography>
      </Typography>
      <Input
        onChange={(e) => setAdminWorkspaceId(e.target.value)} 
        value={adminWorkspaceId}
        placeholder={'Enter workspace id'}
      />
      <br></br>
      <Button.Primary.Vivid
        color={'blue'}
        style={{ marginRight: 16 }}
        disabled={!adminWorkspaceId?.trim()}
        onClick={() => navigate(Resources.adminAccessFunding.path({ workspaceId: adminWorkspaceId }))}
      >
        Account Balance Details
      </Button.Primary.Vivid>
    </Group>
  );
};
