import Animation from './Animation';
import FlyingSuccessStars from './FlyingSuccessStars';
import KudosAmazingJob from './KudosAmazingJob';
import KudosTeamPlayer from './KudosTeamPlayer';
import KudosThankYou from './KudosThankYou';
import OpeningGift from './OpeningGift';
import ProgressRocket from './ProgressRocket';
import ResponseFlow from './ResponseFlow';
import ThreeStepCircleProgress from './ThreeStepCircleProgress';

export const Kudos = {
  AmazingJob: KudosAmazingJob,
  TeamPlayer: KudosTeamPlayer,
  ThankYou: KudosThankYou,
};

export default {
  Animation,
  FlyingSuccessStars,
  KudosAmazingJob,
  KudosTeamPlayer,
  KudosThankYou,
  OpeningGift,
  ProgressRocket,
  ResponseFlow,
  ThreeStepCircleProgress,
};
