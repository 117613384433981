import React from 'react';
import PropTypes from 'prop-types';
import blueWordMarkSvg from '../../../assets/svgs/logos/matter-wordmark--blue.svg';
import whiteWordMarkSvg from '../../../assets/svgs/logos/matter-wordmark--white.svg';

const heights = {
  small: '18px',
  medium: '20px',
  mediumLarge: '27px',
  large: '40px',
};

const paddings = {
  small: '12px',
  medium: '0',
  large: '12px',
};

const WordMark = ({ marginBottom, inverted, size, className }) => (
  <div
    style={{
      marginBottom,
      boxSizing: 'content-box',
      height: heights[size],
      padding: paddings[size],
    }}
    className={className}
  >
    <img
      alt="matter logo"
      src={inverted ? whiteWordMarkSvg : blueWordMarkSvg}
      style={{
        height: '100%',
      }}
    />
  </div>
);

WordMark.propTypes = {
  marginBottom: PropTypes.number,
  inverted: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
};

WordMark.defaultProps = {
  marginBottom: 32,
  inverted: false,
  size: 'large',
  className: null,
};

export default WordMark;
