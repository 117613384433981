import styled from 'styled-components';
import SVG from '../../SVG';
import omitStyled from '../../../libs/omitStyled';
import theme, { ColorPalettes, getColorObj } from '@matterapp/matter-theme';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all ${theme.transitions.times.default};
`;

export const Path = omitStyled('path', ['evenOdd'])`
  pointer-events: none;
  user-select: none;
  transition: all ${theme.transitions.times.default};

  ${({ evenOdd }) => {
    if (evenOdd) {
      return `
        fill-rule: evenodd;
        clip-rule: evenodd;
      `;
    }
  }}
`;

export const Path1 = styled(Path)``;
export const Path10 = styled(Path)``;
export const Path20 = styled(Path)``;
export const Path30 = styled(Path)``;
export const Path40 = styled(Path)``;
export const Path50 = styled(Path)``;
export const Path60 = styled(Path)``;

export const Paths = {
  L1: Path1,
  L10: Path10,
  L20: Path20,
  L30: Path30,
  L40: Path40,
  L50: Path50,
  L60: Path60,
};

export const Image = styled(SVG)`
  min-width: ${({ width }) => width}px;
  user-select: none;
  transition: all ${theme.transitions.times.default};
`;

export const getColorStyles = (params) => {
  const { color, colorOnHover } = params;
  const colorObj = getColorObj(color) || theme.colors.blues;
  const createColor = (level, grayScaleLevel) => ({
    color: colorObj[level],
    grayScale: theme.colors.blacks[grayScaleLevel],
    path: Paths[`L${level}`],
  });

  const colorStyles = [
    createColor(1, 10),
    createColor(10, 20),
    createColor(20, 30),
    createColor(30, 40),
    createColor(40, 50),
    createColor(50, 60),
    createColor(60, 70),
  ].map((value) => {
    const { color, grayScale, path } = value;
    return `
      & ${Image} ${path} {
        fill: ${grayScale};
      }
      &:hover ${Image} ${path} {
        fill: ${color};
      }
    `;
  });

  return `
    & ${Overlay} {
      filter: opacity(${colorOnHover ? '30%' : '70%'});
      background: ${colorOnHover ? theme.colors.blacks[20] : colorObj[10]};
    }

    & ${Image} {
      filter: opacity(${colorOnHover ? '16%' : '30%'});
    }

    &:hover {
      & ${Overlay} {
        filter: opacity(${colorOnHover ? '50%' : '60%'});
        background: ${colorObj[colorOnHover ? 5 : 20]};
      }
      & ${Image} {
        filter: opacity(${colorOnHover ? '50%' : '60%'});
      }  
    }
    ${colorStyles};
  `;
};

export const Container = styled.div`
  align-items: center;
  background: ${theme.colors.white};
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  ${getColorStyles}
`;

export { ColorPalettes as Colors };
