import React from 'react';
import styled from 'styled-components';
import { 
  CircularProgress,
  Box
} from '@mui/material';

const InnerCircle = styled(Box)(({ theme }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: `4px solid ${theme.palette.blacks[20]}`,
  borderRadius: '100%',
  zIndex: 1
}));

const StyledCircularProgress = styled(CircularProgress)(({ complete }) => ({
  position: 'absolute',
  zIndex: 2,
  circle: {
    strokeLinecap: complete ? 'square' : 'round'
  }
}));

export default function CustomCircularProgress({ 
  thickness,
  size,
  value,
  children,
  sx = {}
}) {

  return (
    <Box sx={{ position: 'relative', width: `${size}px`, height: `${size}px`, ...sx }}>
      <StyledCircularProgress 
        variant='determinate' 
        value={value} 
        size={size}
        thickness={thickness}
        complete={value >= 100}
      />
      <InnerCircle sx={{ height: `${size}px`, width: `${size}px` }}>
        {children}
      </InnerCircle>
    </Box>
  );
}