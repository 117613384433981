import React from 'react';
import styled from 'styled-components';
import Button from '../../../../Button';
import { Clickable } from '../../../../Clickable/Clickable';
import { IconNew as Icon } from '../../../../Icon';
import theme from '@matterapp/matter-theme';

export const ActionContainer = styled.div`
  padding: 0;
  ${theme.media.medium`
    padding: 0 0 ${theme.spacing.doubleAndHalf} 0;
  `}
`;

export const CloseButtonIcon = styled(Icon).attrs({ name: 'close' })``;

export const CloseButtonContainer = styled(Clickable)`
  display: none;
  position: absolute;
  top: ${theme.spacing.single};
  right: ${theme.spacing.single};
  & svg {
    height: ${theme.spacing.singleAndHalf};
    width: ${theme.spacing.singleAndHalf};
    path {
      fill: ${theme.colors.blacks[80]};
    }
  }
  &:hover svg path {
    fill: ${theme.colors.blues[60]};
  }
  ${theme.media.medium`
    display: block;
  `}
`;

export const DismissButtonContainer = styled(Button.New).attrs({
  link: true,
})`
  background: transparent;
  color: ${theme.colors.blacks[80]};
  display: block;
  font-size: ${theme.fontSize.base};
  margin: ${theme.spacing.double} auto auto;
  text-decoration: underline;
  text-transform: uppercase;

  ${theme.media.medium`
    display: none;
  `}
`;

export const PeerSuggestionsIcon = styled(Icon).attrs({
  name: 'peerSuggestions',
  height: 80,
  width: 204,
})``;

export const PeerSuggestionsImageContainer = styled.div`
  margin-top: 0;
  zoom: 0.8;

  ${theme.media.medium`
      margin-top: ${theme.spacing.doubleAndHalf};
      zoom: 1;
  `}
`;

export const DismissButton = ({ label, ...otherProps }) => (
  <DismissButtonContainer {...otherProps}>{label}</DismissButtonContainer>
);

export const CloseButton = (props) => (
  <CloseButtonContainer {...props}>
    <CloseButtonIcon />
  </CloseButtonContainer>
);

export const PeerSuggestionsImage = () => (
  <PeerSuggestionsImageContainer>
    <PeerSuggestionsIcon />
  </PeerSuggestionsImageContainer>
);
