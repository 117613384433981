import React from 'react';
import Base from '../../../svgBase';

const DEFAULT_HEIGHT = 37;
const DEFAULT_WIDTH = 29;
const PATH = `M14.3591 36.4772C22.8988 36.4772 28.9159 32.1306 28.8818 25.9772C28.9159 21.6988 26.0522 18.6817 20.4272 18.0681V17.7953C24.5863 17.3522 27.6204 14.676 27.5863 10.7726C27.6204 4.89193 22.1659 0.613525 14.4272 0.613525C6.51815 0.613525 0.893153 5.13057 0.859062 11.5226H9.92725C9.96134 9.40898 11.8193 7.90898 14.4272 7.90898C16.8136 7.90898 18.4841 9.35784 18.45 11.3862C18.4841 13.4999 16.5068 14.9999 13.6772 14.9999H10.1318V21.5453H13.6772C16.7454 21.5453 18.8932 23.0453 18.8591 25.159C18.8932 27.3238 17.0522 28.8408 14.4272 28.8408C11.7 28.8408 9.75679 27.3919 9.7227 25.3635H0.177246C0.211337 31.8749 6.09202 36.4772 14.3591 36.4772Z`;

const Three = (props) => (
  <Base height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
);

export default Three;
