import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from './Default';
import Minimal from './ToggleMinimal';
import Primary from './TogglePrimary';
import theme from '@matterapp/matter-theme';

const getColorStyles = ({
  color,
  disabled,
  bold,
  isSelected,
}) => {
  const isLightColor = color === 'yellow';
  const backgroundColor = bold
    ? theme.colors.blacks[5]
    : theme.colors.white;
  const baseColor = theme.colors[`${color}s`] || theme.colors.blues;
  const baseStyles = `
    background-color: ${backgroundColor};
    border-width: 1px;
  `;

  const selectedStyles = `
    ${baseStyles}
    background-color: ${baseColor[isLightColor ? 50 : 40]};
    color: ${theme.colors.white};
  `;

  if (disabled) {
    return 'border-width: 1px';
  }

  if (isSelected) {
    return `
      ${selectedStyles}
      &:hover {
        background-color: ${baseColor[isLightColor ? 60 : 50]};
      }
      &:active {
        background-color: ${backgroundColor};
        color: inherit;
      }
    `;
  }

  return `
    ${baseStyles}
    color: inherit;
    &:hover {
      border-color: ${bold ? theme.colors.blacks[10] : baseColor[isLightColor ? 20 : 10]};
      background-color: ${backgroundColor};
    }
    &:active {
      border-color: ${baseColor[isLightColor ? 50 : 40]};
      ${selectedStyles}
    }
  `;
};

const ToggleButton = styled(Button)`
  &:not(:active) {
    border-color: transparent;
  }
  ${getColorStyles}
`;

ToggleButton.propTypes = {
  isSelected: PropTypes.bool,
};

ToggleButton.Minimal = Minimal;
ToggleButton.Primary = Primary;

export default ToggleButton;
