import React from 'react';
import PropTypes from 'prop-types';
import IconBase, { HoverPath, DEFAULT_SVG_HOVER_NAME, ICON_FILL_COLORS } from './IconFeedbackBase';
import theme from '@matterapp/matter-theme';

const DEFAULT_ICON_FILL_COLOR = 'blue';

const Icon = ({ fill, dataSvgHoverName, dataSvgHoverValue, ...otherProps }) => {
  const colorBase = theme.colors[`${fill}s`];
  const colorDark = colorBase[60];
  const colorLight = colorBase[20];
  const dataProps = { [`data-${dataSvgHoverName}`]: dataSvgHoverValue };

  return (
    <IconBase {...otherProps}>
      <HoverPath
        fill={colorDark}
        {...dataProps}
        d="M19.752,16.896h-7.644c-1.952,0-2.928,1-4.228,2.833c-1.301,1.833-1.952,5.5-1.952,7v1.667
	c0,0.167,0.163,0.5,0.651,0.5H25.28c0.487,0,0.651-0.333,0.651-0.5v-1.667c0-1.5-0.813-5.333-1.952-7
	C22.679,17.895,21.216,16.896,19.752,16.896z"
      />
      <HoverPath
        fill={colorDark}
        {...dataProps}
        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
        d="M15.992,3.144c-2.937,0-5.256,2.319-5.256,5.411
	s2.319,5.256,5.256,5.256s5.411-2.319,5.411-5.411S18.929,3.144,15.992,3.144z"
      />
      <path
        fill={colorLight}
        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
        d="M24.487,22.477c0.279,0.417,0.556,1.115,1.113,1.115h5.148
	c0.696,0,1.252-0.557,1.252-1.115c0-4.319-2.227-6.408-5.009-6.408h-1.948c-0.835,0-2.504,0.139-3.2,0.417
	c-0.835,0.279-0.556,1.253,0.139,1.672C22.956,18.716,23.931,21.084,24.487,22.477z"
      />
      <ellipse fill={colorLight} cx="25.6" cy="11.612" rx="3.2" ry="3.204" />
      <path
        fill={colorLight}
        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
        d="M7.513,22.477c-0.279,0.417-0.556,1.115-1.113,1.115H1.252
	C0.556,23.592,0,23.035,0,22.477c0-4.319,2.227-6.408,5.009-6.408h1.948c0.835,0,2.504,0.139,3.2,0.417
	c0.835,0.279,0.556,1.253-0.139,1.672C9.044,18.716,8.069,21.084,7.513,22.477z"
      />
      <ellipse fill={colorLight} cx="6.4" cy="11.612" rx="3.2" ry="3.204" />
      <path
        fill={colorDark}
        d="M24.627,19.273c-1.252-1.811-2.921-3.343-5.287-3.343h-7.096c-2.227,0-3.617,1.532-4.869,3.343
	c-1.252,1.672-2.087,5.155-2.087,6.965v1.393C5.287,29.025,6.539,30,7.931,30h16.139c1.391,0,2.644-0.975,2.644-2.368v-1.393
	C26.713,24.428,25.879,20.945,24.627,19.273z M24.864,27.784c0,0.148-0.145,0.444-0.581,0.444H7.579
	c-0.436,0-0.581-0.296-0.581-0.444v-1.481c0-1.333,0.581-4.592,1.743-6.223c1.163-1.629,2.033-2.519,3.777-2.519h6.827
	c1.307,0,2.615,0.889,3.776,2.519c1.017,1.481,1.743,4.889,1.743,6.223v1.481H24.864z"
      />
      <path
        fill={colorDark}
        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
        d="M16.001,13.144c2.571,0,4.735-2.029,4.735-4.735
	s-2.164-4.599-4.735-4.599s-4.599,2.029-4.599,4.735S13.432,13.144,16.001,13.144z M16,14.816c3.479,0,6.4-2.925,6.4-6.408
	S19.479,2,16,2S9.6,4.925,9.6,8.408S12.521,14.816,16,14.816z"
      />
    </IconBase>
  );
};

Icon.defaultProps = {
  fill: DEFAULT_ICON_FILL_COLOR,
  dataSvgHoverName: DEFAULT_SVG_HOVER_NAME,
  dataSvgHoverValue: '',
};

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.oneOf(ICON_FILL_COLORS),
  /** Name of data tag for hover svg elements. Prepends `data-` to name. Controls transition of these elements using css on hover. */
  dataSvgHoverName: PropTypes.string,
  /** Value of data tag for hover svg elements. */
  dataSvgHoverValue: PropTypes.string,
};

export default Icon;
