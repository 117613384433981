import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { spacing, colors } from '@matterapp/matter-theme';

const QualityContainer = styled.div`
  display: inline-flex;
  padding: 0 ${spacing.single};
  line-height: ${theme.lineHeight.XL};
  border-radius: 20px;
  box-shadow: none;
  background-color: white;
  color: ${colors.blues[50]};
  align-items: center;
  justify-content: center;
  width: 100%;
  ${theme.media.S`
    line-height: ${theme.lineHeight.XXL};
  `}
`;

const QualityPill = ({ name, className }) => (
  <QualityContainer className={className}>
    {name}
  </QualityContainer>
);

QualityPill.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

QualityPill.defaultProps = {
  name: 'Generic',
  className: null,
};

export default QualityPill;
