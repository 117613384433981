import React from 'react';
import SVG from '../SVG';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const DEFAULT_HEIGHT = 28;
const DEFAULT_WIDTH = 12;

const Container = styled.div`
  height: ${DEFAULT_HEIGHT}px;
  width: ${DEFAULT_WIDTH}px;
  color: #c4c4c4;
  padding: ${theme.spacing.single} ${theme.spacing.singleAndHalf};
  margin: -${theme.spacing.single} -${theme.spacing.singleAndHalf};
  box-sizing: content-box;

  &:hover {
    color: #888;
  }
`;

const Dots = styled.path`
  fill: currentColor;
  transition: color 0.2s;
`;

const DragHandle = (props) => (
  <Container {...props}>
    <SVG height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} viewBox={`0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`}>
      <Dots
        d="M2,0c1.1,0,2,0.9,2,2S3.1,4,2,4S0,3.1,0,2S0.9,0,2,0z M2,8c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S0.9,8,2,8z M2,16
          c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S0.9,16,2,16z M2,24c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S0.9,24,2,24z M10,0
          c1.1,0,2,0.9,2,2s-0.9,2-2,2S8,3.1,8,2S8.9,0,10,0z M10,8c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S8.9,8,10,8z M10,16
          c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S8.9,16,10,16z M10,24c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S8.9,24,10,24z"
      />
    </SVG>
  </Container>
);

export default DragHandle;
