import React from 'react';
import SVG, { Path } from '../../../SVG';

const CommentsIcon = (props) => {
  return (
    <SVG width={24} height={24} viewBox="0 0 24 24" {...props}>
      <Path
        d="M5.60156 4.40039C4.49699 4.40039 3.60156 5.29582 3.60156 6.40039V15.8004C3.60156 16.905 4.49699 17.8004 5.60156 17.8004H10.8016L15.6016 21.6004V17.8004H18.4016C19.5061 17.8004 20.4016 16.905 20.4016 15.8004V6.40039C20.4016 5.29582 19.5061 4.40039 18.4016 4.40039H5.60156Z"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export default CommentsIcon;
