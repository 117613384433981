export const buttonColors = {
  RED: 'red',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  GREEN: 'green',
  BLUE: 'blue',
  PURPLE: 'purple',
  BLACK: 'black',
  PINK: 'pink',
};

export const buttonSizes = {
  XS: 'XS',
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
};

export const buttonTypes = {
  NORMAL: 'normal',
  CTA: 'cta',
  BUBBLE: 'bubble',
};