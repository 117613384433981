import styled from 'styled-components';
import SVG from '../../../../../SVG';

const HEIGHT = 180;
const WIDTH = 608;

export const Path = styled.path``;

export const PathFillRule = styled.path`
  fill-rule: evenodd;
  clip-rule: evenodd;
`;

export const Image = styled(SVG).attrs(() => ({
  height: HEIGHT,
  width: WIDTH,
  viewBox: `0 0 ${WIDTH} ${HEIGHT}`,
}))`
  background: #f2a5b5;
`;

export const Background = styled(Path).attrs(() => ({
  d: `M608,180H0V0.07h608V180z`,
}))`
  fill: #f2a5b5;
`;

export const CloudTop = styled(Path).attrs(() => ({
  d: `M609.98-40.2c-9.37,7.85-27.53-13.01-45.36,15.35c-14.11-4.34-30.72-0.63-41.9,8.52
      c-3.24,6.43-6.33,12.45-9.29,18.92c-3.28,13.25,5.37,9.61-10.22,17.17c0.54,0.06,0.71,0.09,1,0.12c-3.52,3.11-7.6,5.09-10.15,9.26
      c-7.6,1.23-15.93-5.94-24.17-3.31c-15.76,0.52-19.86,11.67-37.14,7.93c-12.6-0.01-22.71,5.88-30.38,15.54
      c-4.94,3.17-15.74,1.09-17.2,9.26c-2.52,4.06,0.75,5.54-5.47,7.67c-10.81,2.35-16.45,12.18-22.69,19.53
      c-10.48-1.42-19.59,2.16-29.49,4.68c-3.77-6.29-11.08-3.89-14.81-8.42c-6.24-18.43-29.79-20.72-44.64-11.25
      c-7.59-0.64-15.48-2.28-22.91,1.37c-6.41,5.88-7.74-8.16-12.38-10.61c-6.69-6.53-20.54-8.87-29.55-7.31
      c-11.31-5.51-25.25-13.37-38.59-6.68c-7.21-1.02-15.25-2.35-21.86,1.88c-9.06,8.12-7.22,1.66-19.89,1.09
      c-10.11-25.36-23.85-35.74-53.01-34.35c-7.43,0.57-14.21,6.69-21.37,1.85c-6.7-1.62-15.58-2.04-21.2,2.46
      c-3.36,3.33-5.09,2.12-8.23,1.1C9.7,16.26,1.43,21.6-7.98,23.2c0,0,0,18.13,0,18.13c0.02-0.04,0.04-0.07,0.06-0.11
      C-8.17,46.6-7.91,218.39-8,223.2h623V-41.44C613.3-41.15,611.61-40.7,609.98-40.2z M193.86,52.7c-0.23-0.2-0.46-0.39-0.7-0.59
      c0.1-0.09,0.19-0.17,0.29-0.26c0.26,0.17,0.53,0.35,0.79,0.53C194.12,52.49,193.99,52.6,193.86,52.7z`,
}))`
  fill: #e89eb2;
`;

export const CloudBottom = styled(Path).attrs(() => ({
  d: `M-8,264.07h623c0-17.01,0-114.1,0-128.67c-0.01-0.83,0.01-81.17,0-82.39
      c-14.75-0.01-20.3,18.95-20.11,31.42c0.07,0.78-0.19,1.3-1.1,1.7c-5.1,1.66-9.33,4.76-13.54,8.09c-8.36-8.16-18.85-3.61-28.18-0.89
      c-13.32-2.55-23.42,7.31-34.13,12.84c-4.93,0.7-9.47,2.73-14.07,4.4c-16.9,7.83-11,23.75-24.12,19.43
      c-4.43-0.04-9.35,1.52-13.35,3.45c-4.35,2.17-10.83-0.22-15.2-1.2c-7.34,2.53-15.36,3.18-21.03,8.87c-4.4,2.03-9.85,1.74-14.07,4.5
      c-13.94,2.26-20.09-6.38-35.55,4.17c-8.34,7.15-19.11-1.89-28.78,2.39c-6.36,1.41-11.42,5.55-15.93,9.82
      c-1.94,1.11-5.11-1.36-7.08-2.02c-0.79-7.9-8.93-11.91-15.54-14.44c-35.52-10.38-34.79,16.26-49.72,10.14
      c-13.23-3.65-23.75,6.1-36.77-1.04c-16.61-4.43-26.34,12.35-33.8,0.31c-5.69-8.85-13.62-5.13-15.29-9.11
      c-10.74-16.79-34.89-18.97-52.26-11.69c-11.21-10.82-8.47,1.15-19.46-16.98c-10.07-9.87-25.83-9.27-37.92-3.51
      c-3.66,2.1-7.24,4.57-11.46,3.07c-9.61,1.54-3.89-11.15-12.11-12.76c-2.29-1.02-5.87,0.16-7.14-2.44
      c-3.2-6.66-11.04-10.48-18.17-11.02c-2.77,0.27-5.98,1.56-7.95-0.93C5.69,84.93-1.13,82.66-8,80.73c0.16,0.07-0.32,55.46,0.17,55.31
      C-8.32,135.87-7.83,264.01-8,264.07z M202.96,201.22c0.2,0.05,0.4,0.1,0.6,0.15C203.33,201.6,202.83,201.7,202.96,201.22z`,
}))`
  fill: #d195b1;
`;

export const BottomRightDot = styled(Path).attrs(() => ({
  d: `M631.95,143.16c9.55,31.17-22.4,70.35-54.74,58.2C489.94,170.66,589.86,59.46,631.95,143.16z`,
}))`
  fill: #b17fa3;
`;

export const CenterDot1 = styled(PathFillRule).attrs(() => ({
  d: `M319.82,14.28c-1.89,0.33-3.58-1.54-2.05-2.86C319.81,9.9,322.46,13.13,319.82,14.28z`,
}))`
  fill: #cd8b9a;
`;
export const CenterDot2 = styled(PathFillRule).attrs(() => ({
  d: `M373.94,63.32c5.34-0.26,4.99-4.86,3.32-7.33C370.66,50.62,365.3,63.1,373.94,63.32z`,
}))`
  fill: #cd8b9a;
`;
export const CenterDot3 = styled(PathFillRule).attrs(() => ({
  d: `M399.35,71.3c-1.43,0.16-2.79-1.73-1.13-2.72C400.1,67.18,401.75,70.74,399.35,71.3z`,
}))`
  fill: #c48698;
`;
export const CenterDot4 = styled(PathFillRule).attrs(() => ({
  d: `M434.47,73.73c-1.43,0.16-2.79-1.73-1.13-2.72C435.21,69.61,436.87,73.16,434.47,73.73z`,
}))`
  fill: #c48698;
`;
export const CenterDot5 = styled(PathFillRule).attrs(() => ({
  d: `M416.91,106.21c5.52-0.25,5.97-5.37,2.35-8.09c-6.14-1.76-9.19,6.72-2.37,7.97C416.87,106.16,416.83,106.23,416.91,106.21z`,
}))`
  fill: #e5909e;
`;
export const CenterDot6 = styled(PathFillRule).attrs(() => ({
  d: `M459.73,109.55c4.29-0.47,3.48-7.07-0.61-6.63C453.75,102.43,454.29,110.41,459.73,109.55z`,
}))`
  fill: #c48698;
`;
export const CenterDot7 = styled(PathFillRule).attrs(() => ({
  d: `M484.78,135.11c-1.43,0.16-2.79-1.73-1.13-2.72C485.53,130.99,487.18,134.54,484.78,135.11z`,
}))`
  fill: #b37f98;
`;

export const LeftDot1 = styled(PathFillRule).attrs(() => ({
  d: `M37.43,41.18c-4.21-0.89-2.56-7.01,1.63-5.48C42.05,37.09,41.07,41.61,37.43,41.18z`,
}))`
  fill: #c48698;
`;
export const LeftDot2 = styled(PathFillRule).attrs(() => ({
  d: `M14.45,101.59c-1.43,0.16-2.79-1.73-1.13-2.72C15.2,97.47,16.86,101.03,14.45,101.59z`,
}))`
  fill: #b17e97;
`;
export const LeftDot3 = styled(PathFillRule).attrs(() => ({
  d: `M15.38,108.46c-2.3-3.08,3.9-6.09,5.45-2.71C22.42,108.74,17.35,110.85,15.38,108.46z`,
}))`
  fill: #b17e97;
`;
export const LeftDot4 = styled(PathFillRule).attrs(() => ({
  d: `M9.97,131.32c5.22-0.59,6.47,6.21,1.41,7.32C6.86,138.85,5.18,132.16,9.97,131.32z`,
}))`
  fill: #b17e97;
`;
export const LeftDot5 = styled(PathFillRule).attrs(() => ({
  d: `M18.85,159.64c-4.21-0.89-2.56-7.01,1.63-5.48C23.47,155.55,22.49,160.07,18.85,159.64z`,
}))`
  fill: #b17e97;
`;

export const RightDot1 = styled(PathFillRule).attrs(() => ({
  d: `M575.17,41.25c2.36,0.15,3.35,3.53,1.56,4.89C572.52,47.67,570.89,42.03,575.17,41.25z`,
}))`
  fill: #c48597;
`;
export const RightDot2 = styled(PathFillRule).attrs(() => ({
  d: `M593.33,61.15c-2.49-1.01-4.45,2.33-2.01,3.68c2.23,0.37,2.72,0.25,2.98-1.71c-0.06-0.49,0.15,0.27,0.06,0.61C594.65,64.4,594.63,61.2,593.33,61.15z`,
}))`
  fill: #c48597;
`;
export const RightDot3 = styled(PathFillRule).attrs(() => ({
  d: `M571.01,78.12c1.03,1.85-3.11,3.07-4.14,1.43C566.33,77.37,569.97,76.87,571.01,78.12z`,
}))`
  fill: #9c6d98;
`;

export const TopDot = styled(Path).attrs(() => ({
  d: `M424.49-15.8c25.06-2.2,26.36,40.46,0.14,39.9C394.21,27.26,393.86-20.72,424.49-15.8z`,
}))`
  fill: #d994aa;
`;

export const Ring1 = styled(Path).attrs(() => ({
  d: `M480.88,2.45c10.96,0.27,10.38,16.05-0.62,15.85C471.5,18.67,469.96,2.41,480.88,2.45zM476.86,9.77c-0.21,6.09,8.26,5.67,8.41,0.56C485.22,5.41,477.14,4.96,476.86,9.77z`,
}))`
  fill: #f197a9;
`;
export const Ring2 = styled(Path).attrs(() => ({
  d: `M559.36,21.91c11.55-0.19,12.49,16.31,0.47,16.34C548.59,38.48,548.88,21.84,559.36,21.91z M560.01,35.68c8.1-0.14,7.21-11.09-0.38-11.05C552.58,25.66,552.01,35.24,560.01,35.68z`,
}))`
  fill: #e691a6;
`;
export const Ring3 = styled(Path).attrs(() => ({
  d: `M571.2,11.74c-8.38,0.13-8.19-12.06,0.12-11.66C579.28-0.04,579.38,11.94,571.2,11.74zM567.86,5.92c-0.19,3.93,6.69,3.89,6.59,0.01C574.52,2,567.89,1.87,567.86,5.92z`,
}))`
  fill: #e691a6;
`;

export const RightPlus1 = styled(Path).attrs(() => ({
  d: `M495.07,60.26c0.12,9.13-3.34,2.57-2.45-2.4c0.51-3.7-3.21-1.76-5.47-2.48c-3.75-2.45,2.78-2.39,4.43-1.99c2.5-1,0.5-5.91,1.22-8.28c4.42-5.19,0.6,8.28,3.22,8.38c1.46,0.33,4.82-0.87,5.28,0.85c-1.92,3.93-7.84-2.53-6.4,5.93C494.97,60.26,495.02,60.26,495.07,60.26z`,
}))`
  fill: #c586a4;
`;
export const RightPlus2 = styled(Path).attrs(() => ({
  d: `M534.01,71.86c0.08,3.88-1.48,1.09-1.07-1.05c0.22-1.61-1.39-0.76-2.38-1.08c-1.62-1.06,1.19-1.04,1.93-0.86c1.09-0.43,0.22-2.57,0.54-3.6c1.96-2.08,0.23,3.45,1.4,3.64c0.64,0.14,2.08-0.38,2.3,0.37c-0.86,1.51-3.52-0.72-2.78,2.57C533.96,71.86,533.99,71.86,534.01,71.86z`,
}))`
  fill: #c586a4;
`;

export const CenterPlus1 = styled(Path).attrs(() => ({
  d: `M351.49,124.91c0.08,3.88-1.48,1.09-1.07-1.05c0.22-1.61-1.39-0.76-2.38-1.08c-1.62-1.06,1.19-1.04,1.93-0.86c1.09-0.43,0.22-2.57,0.54-3.6c1.97-2.08,0.23,3.45,1.4,3.64c0.64,0.14,2.08-0.38,2.3,0.37c-0.86,1.51-3.52-0.72-2.78,2.57C351.44,124.91,351.47,124.91,351.49,124.91z`,
}))`
  fill: #e5909e;
`;
export const CenterPlus2 = styled(Path).attrs(() => ({
  d: `M370.96,143c0.08,3.88-1.48,1.09-1.07-1.05c0.22-1.61-1.39-0.76-2.38-1.08c-1.62-1.06,1.19-1.04,1.93-0.86c1.09-0.44,0.22-2.57,0.54-3.6c1.96-2.08,0.23,3.45,1.4,3.64c0.64,0.14,2.08-0.38,2.3,0.37c-0.85,1.51-3.52-0.72-2.78,2.58C370.91,143,370.94,143,370.96,143z`,
}))`
  fill: #e5909e;
`;
export const CenterPlus3 = styled(Path).attrs(() => ({
  d: `M401.61,134.12c0.12,9.13-3.34,2.57-2.45-2.4c0.51-3.7-3.21-1.76-5.47-2.48c-3.75-2.45,2.78-2.39,4.43-1.99c2.5-1,0.5-5.91,1.22-8.28c4.42-5.19,0.6,8.28,3.23,8.38c1.46,0.33,4.82-0.87,5.28,0.85c-1.92,3.94-7.84-2.53-6.4,5.93C401.52,134.12,401.57,134.12,401.61,134.12z`,
}))`
  fill: #e5909e;
`;
