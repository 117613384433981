import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../Avatar';
import ProfileMenuIcon from './ProfileMenuIcon';
import {
  MoreIcon,
  ProfileAvatarContainer,
  ProfileAvatarDesktopWrapper,
  ProfileAvatarMobileWrapper,
  ProfileContainer,
  ProfileMenuContainer,
  ProfileMenuIconContainer,
  ProfileWrapper,
} from './styles';

const ProfileMenu = (props) => {
  const { activeProfileTab, children, user, isOpen, onClick } = props;
  const hasActiveTab = Number.isInteger(activeProfileTab);

  return (
    <ProfileContainer>
      <ProfileWrapper isOpen={isOpen} hasActiveTab={hasActiveTab}>
        <ProfileAvatarContainer isOpen={isOpen} onClick={onClick}>
          <ProfileAvatarDesktopWrapper>
            <Avatar url={user?.photoUrl} size={32}/>
          </ProfileAvatarDesktopWrapper>
          <ProfileAvatarMobileWrapper>
            <MoreIcon />
          </ProfileAvatarMobileWrapper>
          <ProfileMenuIconContainer isOpen={isOpen}>
            <ProfileMenuIcon />
          </ProfileMenuIconContainer>
        </ProfileAvatarContainer>
        <ProfileMenuContainer>{children}</ProfileMenuContainer>
      </ProfileWrapper>
    </ProfileContainer>
  );
};

ProfileMenu.propTypes = {
  /** The index of the current active profile tab./ */
  activeProfileTab: PropTypes.number,
  /** Additional components to display. */
  children: PropTypes.node,
  /** If profile menu is currently open. */
  isOpen: PropTypes.bool,
  /** Callback when profile menu if clicked. */
  onClick: PropTypes.func,
};

export default ProfileMenu;
