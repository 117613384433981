
export const APP_ELEMENT = 'body';

export const CONTENT_LABEL = 'modal';

export const MODAL_ROLE = 'dialog';

export const LOCKED_BODY_CLASS = 'locked';
export const LOCKED_BODY_CLASS_ALT = 'modal-open';

export const OPEN_BODY_CLASS = 'ReactModal__Body--open';
export const OPEN_HTML_CLASS = 'ReactModal__Html--open';

export const CONTENT_CLASS = 'matter-ReactModal__Content';
export const OVERLAY_CLASS = 'matter-ReactModal__Overlay';

export const TRANSITIONS = {
  DROP: 'DROP',
  FADE: 'FADE',
  FADE_DOWN: 'FADE_DOWN',
  ZOOM_IN: 'ZOOM_IN',
};
export const TRANSITIONS_LIST = Object.values(TRANSITIONS);

export const OVERLAY_THEMES = {
  DEFAULT: 'DEFAULT',
  LIGHT: 'LIGHT',
  PURPLE: 'PURPLE',
};
export const OVERLAY_THEMES_LIST = Object.values(OVERLAY_THEMES);

export const SIZES = {
  MAX: 'MAX',
  XL: 'XL',
  L: 'L',
  M: 'M',
  S: 'S',
  CUSTOM: 'CUSTOM',

  // Deprecated sizes
  large: 'XL',
  medium: 'L',
  normal: 'M',
  small:'S',
};
export const SIZES_LIST = Object.keys(SIZES);