import md5 from 'md5';

const AVATAR_SIZES = {
  large: 'large',
  small: 'small',
};
const AVATAR_VARIANTS = [0, 1, 2, 3, 4, 5, 6];

const REPLACE_SIZE = '%s';
const REPLACE_VARIANT = '%v';
const BASE_AVATAR_URL = `http://static.matterapp.com/static-assets/avatars/avatar_${REPLACE_SIZE}${REPLACE_VARIANT}.png`;
const DEFAULT_AVATAR_URL =
  'https://static.matterapp.com/static-assets/matter_profile_v2_512x512.png';

const getDefaultPhotoUrl = ({ size, variant }) => {
  const avatarSize = AVATAR_SIZES[size] || AVATAR_SIZES.large;
  const avatarVariant = AVATAR_VARIANTS[variant] || AVATAR_VARIANTS[1];
  return BASE_AVATAR_URL.replace(REPLACE_SIZE, avatarSize).replace(
    REPLACE_VARIANT,
    avatarVariant
  );
};

const getPhotoUrlFromEmail = ({
  defaultAvatarProps = {},
  email,
  size,
  defaultAvatarIsGenerated,
}) => {
  const base = '//www.gravatar.com/avatar/';
  const hash = md5(email, { encoding: 'binary' });
  const defaultUrl = defaultAvatarIsGenerated
    ? getDefaultPhotoUrl(defaultAvatarProps)
    : DEFAULT_AVATAR_URL;
  const photoUrl = `${base}${hash}?s=${size}&d=${encodeURI(defaultUrl)}`;
  return photoUrl;
};

export default getPhotoUrlFromEmail;
