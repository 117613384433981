import React from 'react';
import PropTypes from 'prop-types';
import IconBase, { HoverPath, DEFAULT_SVG_HOVER_NAME, ICON_FILL_COLORS } from './IconFeedbackBase';
import theme from '@matterapp/matter-theme';

const DEFAULT_ICON_FILL_COLOR = 'yellow';

const Icon = ({ fill, dataSvgHoverName, dataSvgHoverValue, ...otherProps }) => {
  const colorBase = theme.colors[`${fill}s`];
  const colorDark = colorBase[60];
  const colorLight = colorBase[40];
  const dataProps = { [`data-${dataSvgHoverName}`]: dataSvgHoverValue };

  return (
    <IconBase {...otherProps}>
      <HoverPath
        fill={colorDark}
        {...dataProps}
        d="M18.401,15.122c-1.157-0.137-2.005-0.707-2.538-1.783c-0.573-1.16-1.152-2.317-1.729-3.476
        c-0.453-0.909-0.907-1.819-1.373-2.754c-0.057,0.12-0.103,0.211-0.145,0.304c-0.913,2.009-1.828,4.018-2.738,6.03
        c-0.276,0.611-0.656,1.121-1.221,1.468c-0.501,0.307-1.06,0.404-1.623,0.497c-2.056,0.343-4.112,0.696-6.211,1.052
        c0.096,0.103,0.157,0.173,0.224,0.236c1.557,1.513,3.111,3.031,4.675,4.536c0.831,0.799,1.176,1.768,1.009,2.924
        c-0.319,2.216-0.649,4.431-0.973,6.646c-0.013,0.089-0.016,0.18-0.027,0.307c0.107-0.053,0.173-0.084,0.237-0.121
        c1.861-1.06,3.73-2.107,5.582-3.187c1.057-0.617,2.091-0.637,3.168-0.069c1.876,0.989,3.764,1.956,5.648,2.931
        c0.091,0.047,0.183,0.091,0.299,0.147c-0.007-0.085-0.007-0.14-0.017-0.195c-0.407-2.209-0.803-4.422-1.228-6.627
        c-0.227-1.18,0.059-2.167,0.873-3.018c1.505-1.569,2.999-3.152,4.496-4.728c0.068-0.072,0.133-0.147,0.235-0.261
        c-0.687-0.087-1.315-0.164-1.941-0.247C21.523,15.531,19.964,15.306,18.401,15.122z"
      />
      <path
        fill={colorDark}
        d="M23.773,13.883c-1.933-0.283-3.874-0.523-5.811-0.775c-0.169-0.023-0.272-0.08-0.355-0.248
        c-0.857-1.737-1.723-3.471-2.588-5.203c-0.571-1.143-1.693-1.708-2.814-1.423c-0.804,0.205-1.351,0.717-1.699,1.491
        c-0.801,1.783-1.613,3.56-2.416,5.343c-0.061,0.137-0.137,0.208-0.289,0.233c-1.828,0.307-3.654,0.624-5.482,0.928
        c-0.581,0.097-1.117,0.283-1.551,0.715c-1.032,1.027-1.025,2.674,0.028,3.71c1.401,1.377,2.812,2.744,4.224,4.11
        c0.109,0.105,0.148,0.205,0.125,0.356c-0.212,1.401-0.42,2.804-0.628,4.206c-0.101,0.692-0.2,1.387-0.271,1.871
        c-0.011,0.735,0.104,1.225,0.393,1.653c0.792,1.164,2.104,1.476,3.318,0.788c1.665-0.944,3.328-1.893,4.988-2.847
        c0.145-0.083,0.255-0.088,0.405-0.009c1.673,0.879,3.35,1.748,5.026,2.62c0.38,0.197,0.776,0.335,1.205,0.345
        c1.544,0.039,2.735-1.416,2.451-3.027c-0.344-1.941-0.711-3.879-1.076-5.818c-0.043-0.22-0.005-0.363,0.152-0.527
        c1.301-1.345,2.591-2.703,3.882-4.059c0.125-0.132,0.245-0.272,0.352-0.42C26.455,16.382,25.597,14.149,23.773,13.883z
         M22.835,16.715c-1.251,1.316-2.499,2.639-3.756,3.95c-0.68,0.711-0.92,1.535-0.729,2.52c0.355,1.843,0.685,3.691,1.027,5.536
        c0.008,0.045,0.008,0.092,0.013,0.163c-0.097-0.048-0.173-0.084-0.249-0.123c-1.573-0.815-3.151-1.621-4.718-2.448
        c-0.9-0.473-1.763-0.457-2.647,0.059c-1.547,0.903-3.107,1.777-4.663,2.663c-0.053,0.031-0.109,0.056-0.199,0.101
        c0.009-0.105,0.011-0.181,0.023-0.256c0.271-1.851,0.547-3.7,0.813-5.551c0.139-0.965-0.149-1.775-0.843-2.443
        c-1.307-1.257-2.604-2.524-3.906-3.79c-0.055-0.053-0.107-0.112-0.187-0.197c1.752-0.297,3.47-0.592,5.187-0.879
        c0.471-0.079,0.937-0.16,1.356-0.416c0.473-0.289,0.789-0.717,1.02-1.227c0.76-1.68,1.524-3.358,2.287-5.036
        c0.036-0.077,0.073-0.155,0.121-0.253c0.389,0.781,0.768,1.54,1.147,2.3c0.483,0.968,0.965,1.935,1.444,2.904
        c0.445,0.899,1.152,1.375,2.12,1.489c1.305,0.155,2.607,0.343,3.911,0.513c0.523,0.069,1.048,0.133,1.621,0.205
        C22.947,16.592,22.892,16.655,22.835,16.715z"
      />
      <path
        fill={colorLight}
        d="M22.035,10.633c0.032-0.224,0.077-0.544,0.124-0.864c0.096-0.647,0.192-1.295,0.289-1.941
	c0.011-0.069-0.008-0.116-0.057-0.164c-0.651-0.631-1.303-1.261-1.949-1.897c-0.487-0.477-0.489-1.239-0.013-1.712
	c0.2-0.2,0.448-0.285,0.716-0.329c0.844-0.14,1.687-0.287,2.53-0.428c0.069-0.012,0.105-0.044,0.133-0.108
	c0.371-0.823,0.745-1.643,1.116-2.466c0.16-0.357,0.413-0.593,0.784-0.688c0.517-0.132,1.035,0.129,1.299,0.656
	c0.4,0.8,0.799,1.6,1.195,2.402c0.039,0.077,0.085,0.104,0.164,0.115c0.895,0.116,1.789,0.227,2.682,0.357
	c0.841,0.123,1.239,1.153,0.724,1.855c-0.049,0.068-0.105,0.133-0.163,0.193c-0.596,0.625-1.191,1.252-1.792,1.873
	c-0.071,0.075-0.088,0.141-0.068,0.243c0.169,0.895,0.339,1.788,0.497,2.684c0.132,0.743-0.417,1.415-1.131,1.397
	c-0.197-0.005-0.381-0.068-0.556-0.159c-0.773-0.403-1.548-0.804-2.32-1.209c-0.069-0.037-0.12-0.035-0.187,0.004
	c-0.767,0.44-1.533,0.879-2.303,1.313c-0.56,0.317-1.165,0.173-1.531-0.364C22.083,11.199,22.03,10.972,22.035,10.633z"
      />
    </IconBase>
  );
};

Icon.defaultProps = {
  fill: DEFAULT_ICON_FILL_COLOR,
  dataSvgHoverName: DEFAULT_SVG_HOVER_NAME,
  dataSvgHoverValue: '',
};

Icon.propTypes = {
  fill: PropTypes.oneOf(ICON_FILL_COLORS),
  /** Name of data tag for hover svg elements. Prepends `data-` to name. Controls transition of these elements using css on hover. */
  dataSvgHoverName: PropTypes.string,
  /** Value of data tag for hover svg elements. */
  dataSvgHoverValue: PropTypes.string,
};

export default Icon;
