import PropTypes from 'prop-types';
import {
  slackWorkspaceDefaultProp,
  slackWorkspacePropType,
} from './slack';
import { weekdayDefaultProp, weekdayPropType } from './weekday';

/*****************************************************************************/
/* FEEDBACK RITUAL SETTINGS PROP TYPE                                        */
/*****************************************************************************/
export const feedbackRitualSettingsPropTypeObj = {
  isActive: PropTypes.bool,
  isReminderActive: PropTypes.bool,
  isRecapActive: PropTypes.bool,
  dayOfPost: weekdayPropType,
  kickoffMessage: PropTypes.string,
  frequency: PropTypes.string,
  isLastCallReminderActive: PropTypes.bool,
  notifyEntireTeamForLastCall: PropTypes.bool,
  notifyEntireTeamForKickoff: PropTypes.bool,
  isStatsMessageActive: PropTypes.bool
};

export const feedbackRitualSettingsDefaultProp = {
  dayOfPost: weekdayDefaultProp,
  kickoffMessage: '',
  frequency: 'weekly',
};

export const feedbackRitualSettingsPropType = PropTypes.shape(
  feedbackRitualSettingsPropTypeObj
);

/*****************************************************************************/
/* MEMBER PROP TYPE                                                          */
/*****************************************************************************/
export const personPropTypeObj = {
  fullName: PropTypes.string,
  email: PropTypes.string,
  photoUrl: PropTypes.string,
};

export const memberPropTypeObj = {
  id: PropTypes.string,
  person: PropTypes.shape(personPropTypeObj),
};

export const memberDefaultProp = {
  person: {},
};

export const memberPropType = PropTypes.shape(memberPropTypeObj);

/*****************************************************************************/
/* MEMBERS PROP TYPE                                                         */
/*****************************************************************************/
export const membersDefaultProp = [];
export const membersPropType = PropTypes.arrayOf(memberPropType);

/*****************************************************************************/
/* WORKSPACE PROP TYPE                                                       */
/*****************************************************************************/
export const workspacePropTypeObj = {
  /** URL to connect to Slack. */
  connectWorkspaceSlackOAuthURL: PropTypes.string,
  /** Feedback Ritual settings for the workspace. */
  feedbackRitualSettings: feedbackRitualSettingsPropType,
  /** The id of the workspace. */
  id: PropTypes.string,
  /** Url for workspace icon. */
  iconUrl: PropTypes.string,
  /** Members of the workspace. */
  members: membersPropType,
  /** Name of workspace. */
  name: PropTypes.string,
  /** The slack account of the workspace. */
  slackWorkspace: slackWorkspacePropType,
  /** Timezone of the workspace. */
  timezone: PropTypes.string,
};

export const workspaceDefaultProp = {
  feedbackRitualSettings: feedbackRitualSettingsDefaultProp,
  slackWorkspace: slackWorkspaceDefaultProp,
  members: membersDefaultProp,
  timezone: 'America/Los_Angeles',
};

export const workspacePropType = PropTypes.shape(workspacePropTypeObj);
