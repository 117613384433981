import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Radio from './Radio';
import theme from '@matterapp/matter-theme';

const Container = styled.div``;

const Label = styled.div`
  &:not(:empty) {
    color: ${theme.colors.blacks[80]};
    font-size: ${theme.fontSize.S};
  }
`;

const RadioItem = styled(Radio)`
  min-height: ${theme.spacing.quad};
  ${({ expanded }) => expanded && `
    display: flex;
    align-items: flex-start;
    height: 128px;
    & ${RadioItem.Wrapper} {
      margin-top: 17px;
    }
  `}
  ${({ showRadioAfterLabel }) => {
    if (showRadioAfterLabel) {
      return `
        flex-direction: row-reverse;
        & ${Label} {
          margin: 0 ${theme.spacing.single} 0 0;
        }
      `;
    }
    return `
      flex-direction: initial;
      & ${Label} {
        margin: 0 0 0 ${theme.spacing.single};
      }
    `;
  }}
`;

const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  & ${Label} {
    margin: 22px 0; 
  }
`;

const sharedStyles = `
  display: flex;
  flex-direction: column;

  & ${RadioItem} {
    cursor: pointer;
    background: ${theme.colors.blacks[5]};
    border-bottom: 1px solid ${theme.colors.white};
    padding: 0 ${theme.spacing.single};
    justify-content: space-between;
    
    & ${Label} {
      flex: 1 1 auto;
      text-align: left;
    }

    & ${RadioItem.Wrapper} {
      background: ${theme.colors.white};
    }
  }
`;

const RadioList = (props) => {
  const {
    name,
    className,
    disabled,
    formatLabel,
    onChange,
    showRadioAfterLabel,
    value,
    values,
    size,
    expanded,
    children,
  } = props;
  const buttons = values.map((item, index) => {
    let radioLabel, radioValue, sublabel;
    const otherProps = {};
    if (name) {
      otherProps.name = name;
    }
    if (typeof item == 'string') {
      radioLabel = item;
      radioValue = item;
    }
    else {
      radioLabel = item.label;
      radioValue = item.value;
      sublabel = item.sublabel;
    }
    const checked = value === radioValue;
    let child = checked && expanded ? 
      <ChildContainer>
        <Label>{formatLabel(radioLabel)}</Label>
        {children}
      </ChildContainer> :
      <Label>{formatLabel(radioLabel)}</Label>;

    if (sublabel) {
      child = (
        <ChildContainer>
          <Label>{formatLabel(radioLabel)}</Label>
          {sublabel}
        </ChildContainer>
      );
    }

    return (
      <RadioItem
        expanded={checked && expanded}
        checked={checked}
        disabled={disabled}
        key={`radio-${index}-${radioValue}`}
        onClick={onChange}
        showRadioAfterLabel={showRadioAfterLabel}
        value={radioValue}
        size={size}
        {...otherProps}
      >
        {child}
      </RadioItem>
    );
  });
  return <Container className={className}>{buttons}</Container>;
};

RadioList.Label = Label;
RadioList.RadioItem = RadioItem;
RadioList.RadioItem.Wrapper = RadioItem.Wrapper;

RadioList.Settings = styled(RadioList).attrs(() => ({
  showRadioAfterLabel: true,
}))`
  ${sharedStyles}
`;

RadioList.Onboarding = styled(RadioList).attrs(() => ({
  showRadioAfterLabel: true,
  size: 24,
}))`
  ${sharedStyles}
  & ${RadioItem} {
    border-radius: ${theme.borderRadius.S};
    margin: 1px 0;
    & ${RadioItem.Wrapper} {
      border: 2px solid ${theme.colors.blacks[20]};
    }
  }
`;

RadioList.defaultProps = {
  formatLabel: (value) => value,
  onChange: () => {},
};

RadioList.propTypes = {
  className: PropTypes.string,
  /** If radio list is disabled. */
  disabled: PropTypes.bool,
  /** Function to format label of item based on value of item. */
  formatLabel: PropTypes.func,
  /** Callback when radio list value changes. */
  onChange: PropTypes.func,
  /** Displays radio button after label. */
  showRadioAfterLabel: PropTypes.bool,
  /** Boolean to check if radio list item can be expanded. */
  expanded: PropTypes.bool,
  /** List of values for the radio list. */
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  )
};

export default RadioList;
