import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import List from '../../List';
import ListItem from '../ListItem';
import NoPeersMessage from '../NoPeersMessage';

const Container = styled.div``;

const PeerList = (props) => {
  const {
    className,
    defaultAvatarIsGenerated,
    findYourPeersLink,
    listItemProps,
    onClickFindYourPeers,
    onClickPeer,
    peers,
    renderPeerActions,
    renderProfileCard,
    hideFindPeersInEmptyState,
  } = props;
  const listItems = peers.map((peer, index) => {
    return (
      <ListItem
        {...listItemProps}
        actions={renderPeerActions(peer)}
        defaultAvatarIsGenerated={defaultAvatarIsGenerated}
        key={`${peer.email}-peer`}
        onClick={onClickPeer}
        peer={peer}
        renderProfileCard={renderProfileCard}
      />
    );
  });

  return peers.length ? (
    <Container className={className} data-type="list">{listItems}</Container>
  ) : (
    !hideFindPeersInEmptyState && (<NoPeersMessage
      findYourPeersLink={findYourPeersLink}
      onClickFindYourPeers={onClickFindYourPeers}
    />)
  );
};

PeerList.propTypes = {
  /** Additional style class. */
  className: PropTypes.string,
  /** When the user has no avatar image, generate default avatar of use stock avatar image (leaf). */
  defaultAvatarIsGenerated: PropTypes.bool,
  /** Link to find your peers page. Displays button if passed in. */
  findYourPeersLink: PropTypes.string,
  /** Additional props for every list item. */
  listItemProps: PropTypes.object,
  /** Callback when find you peers button is clicked. */
  onClickFindYourPeers: PropTypes.func,
  /**
   * Callback when a peer list item is clicked.
   * @param { Object } e: The click event.
   * @param { Object } itemProps: The props of the item clicked.
   * @returns { void }
   */
  onClickPeer: PropTypes.func,
  /**
   * Function to render the actions continer of the item.
   * @param { Object } peer: The peer definition object.
   * @returns { React.Component } The item actions.
   */
  renderPeerActions: PropTypes.func,
  /**
   * Function to render the profile card of a list item.
   */
  renderProfileCard: PropTypes.func,
  /**
   * List of peers to show in the list.
   */
  peers: PropTypes.arrayOf(
    PropTypes.shape({
      /** The email of the peer. */
      email: PropTypes.string.isRequired,
      /** The full name of the peer. */
      fullName: PropTypes.string,
      /** The url of the photo of the peer. */
      photoUrl: PropTypes.string,
    })
  ),
  hideFindPeersInEmptyState: PropTypes.bool,
};

PeerList.defaultProps = {
  findYourPeersLink: null,
  listItemProps: {},
  onClickPeer: undefined,
  /**
   * Function to render the actions content of a peer list item.
   * @param { Object } peer: The peer definition object of the row.
   * @returns { void }
   */
  renderPeerActions: () => {},
};

PeerList.AddButton = List.AddButton;

export default PeerList;
