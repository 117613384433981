import React, { useState } from 'react';
import { Button, Confirm, Text } from '@matterapp/matter-ui';
import { WorkspaceNameContainer } from './styles';
import PropTypes from 'prop-types';

const TextReplace = Text.WithReplacement;

const DELETE_MODAL_TEXT = {
  HEADER: 'Delete your channel?',
  SUB_HEADER: `The channel ${TextReplace.replaceText} will no longer be available and your team members won’t have access to it. Are you sure you want to delete?`,
  CANCEL_BUTTON: 'Cancel',
  DELETE_BUTTON: 'Yes, Delete',
};

const DeleteModal = (props) => {
  const { onClickDelete, workspace, content } = props;
  const { id, name: workspaceName } = workspace;
  const [isOpen, setIsOpen] = useState(false);

  function handleClickDelete(e) {
    onClickDelete(e, { ...props, id });
    setIsOpen(false);
  }

  const subHeader = (
    <WorkspaceNameContainer>
      <Text.WithReplacement
        replaceText={workspaceName}
        replaceTextElement={'b'}
        text={DELETE_MODAL_TEXT.SUB_HEADER}
      />
    </WorkspaceNameContainer>
  );

  return (
    <>
      <Button.Primary
        color={Button.buttonColors.RED}
        content={content}
        onClick={() => setIsOpen(true)}
      />
      <Confirm
        header={DELETE_MODAL_TEXT.HEADER}
        subHeader={subHeader}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        cancelButtonLabel={DELETE_MODAL_TEXT.CANCEL_BUTTON}
        confirmButtonLabel={DELETE_MODAL_TEXT.DELETE_BUTTON}
        confirmButtonIsDestructive
        onClickCancel={() => setIsOpen(false)}
        onClickConfirm={handleClickDelete}
      />
    </>
  );
};

DeleteModal.propTypes = {
  workspace: PropTypes.object,
  onClickDelete: PropTypes.func
};

export default DeleteModal;
