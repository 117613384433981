import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../Button';
import Card from '../Card';
import Footer from '../Footer';
import { Heading } from '../../Heading';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & [data-type='list-item'] {
    border-color: transparent;
  }
`;

const CardFooter = styled(Footer)`
  z-index: 1;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${theme.spacing.singleAndHalf};
  background: ${theme.colors.white};

  ${theme.media.breakpoint(Card.smallBreakpoint)`
    z-index: 0;
    position: static;
    background: transparent;
    padding: ${theme.spacing.singleAndHalf};
    height: auto;
  `}
`;

const Header = styled(Heading).attrs(({ className }) => ({ as: 'h2', className }))`
  margin: 0;
  font-size: ${theme.fontSize.M};
  font-weight: ${theme.fontWeight.bold};
  text-align: center;

  ${theme.media.breakpoint(Card.smallBreakpoint)`
    padding-top: ${theme.spacing.singleAndHalf};
  `}
`;

const SubHeader = styled.div`
  color: ${theme.colors.blacks[60]};
  font-size: ${theme.fontSize.S};
  font-weight: ${theme.fontWeight.regular};
  text-align: center;
  max-width: 320px;
  margin: auto;
`;

const ImageWrapper = styled.div`
  height: auto;
  margin: 0 ${theme.spacing.single};
  min-height: 300px;
  overflow: hidden;

  ${theme.media.breakpoint(Card.smallBreakpoint)`
    max-height: 300px;
  `}
`;

const Image = styled.img`
  margin: auto;
  width: 100%;
  height: 100%;
`;

const NextButton = styled(Button.Primary.Vivid).attrs(() => ({
  color: Button.Simple.buttonColors.PURPLE,
}))`
  border-radius: ${theme.borderRadius.S};
`;

const SkipButton = styled(Button.Link).attrs(() => ({
  noHoverOnMobile: true,
  color: Button.Simple.buttonColors.BLUE,
}))`
  color: ${theme.colors.blacks[50]};
  ${({ showAnimation }) => showAnimation && `
    animation: delayedFadeIn 3.5s;
  `}

  @keyframes delayedFadeIn {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  & button {
    font-size: ${theme.fontSize.base};
    white-space: nowrap;
  }
  & a {
    align-items: center;
    color: ${theme.colors.white} !important;
    display: flex;
    font-size: ${theme.fontSize.base};
    text-decoration: none !important;
    white-space: nowrap;
  }

  ${theme.media.XXS`
    & button, & a {
      font-size: ${theme.fontSize.S};
    }
  `}
`;

const CardContents = (props) => {
  const {
    canClickNext,
    children,
    className,
    headerText,
    imgSrc,
    nextButtonHref,
    nextButtonText,
    nextButtonIsLoading,
    nextButtonProps,
    onClickNext,
    onClickSkip,
    onKeyDown,
    skipButtonText,
    skipButtonProps,
    subHeaderText,
  } = props;
  const hasFooter = skipButtonText || nextButtonText;
  return (
    <>
      <Container className={className} onKeyDown={onKeyDown}>
        {headerText && <Header>{headerText}</Header>}
        {subHeaderText && <SubHeader>{subHeaderText}</SubHeader>}
        {imgSrc && <ImageWrapper><Image src={imgSrc} /></ImageWrapper>}
        {children}
      </Container>
      {hasFooter && <CardFooter
        right={
          <ButtonContainer>
            {skipButtonText && (
              <SkipButton content={skipButtonText} onClick={onClickSkip} {...skipButtonProps} />
            )}
            {nextButtonText && <NextButton
              content={nextButtonText}
              disabled={!canClickNext || nextButtonIsLoading}
              isLoading={nextButtonIsLoading}
              onClick={onClickNext}
              href={nextButtonHref}
              {...nextButtonProps}
            />}
          </ButtonContainer>
        }
      />}
    </>
  );
};

CardContents.defaultProps = {
  canClickNext: true,
  nextButtonProps: {},
  skipButtonProps: {},
};

CardContents.propTypes = {
  /** If the next button can be clicked. */
  canClickNext: PropTypes.bool,
  /** Additional children to show. */
  children: PropTypes.node,
  /** Additional style class. */
  className: PropTypes.string,
  /** Header text of the card. */
  headerText: PropTypes.node,
  /** The image source of the image to show. */
  imgSrc: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType, PropTypes.string, PropTypes.shape({ process: PropTypes.func })]),
  /** If next button is loading. */
  nextButtonIsLoading: PropTypes.bool,
  /** The text of the next button. */
  nextButtonText: PropTypes.string,
  /** URL link for next button. */
  nextButtonHref: PropTypes.string,
  /** Additional props for next button. */
  nextButtonProps: PropTypes.object,
  /** Callback when the next button is clicked. */
  onClickNext: PropTypes.func,
  /** Callback when the skip button is clicked. */
  onClickSkip: PropTypes.func,
  /** Callback on key down. */
  onKeyDown: PropTypes.func,
  /** The text of the skip button. */
  skipButtonText: PropTypes.node,
  /** Additional props for skip button. */
  skipButtonProps: PropTypes.object,
  /** The sub header text of the card. */
  subHeaderText: PropTypes.node,
};

CardContents.Header = Header;

CardContents.SubHeader = SubHeader;

export default CardContents;
