import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  position: absolute;
  opacity: 0;
  top: calc(0% - ${({ distance = 8 }) => distance}px);
  left: 50%;
  transform: translate(-50%, -100%);
  margin: auto;
  width: 240px;
  min-height: 20px;
  transition: opacity 0.4s;
  pointer-events: none;
  z-index: 1000;

  ${({ parent }) => `
    ${parent}:hover & {
      opacity: 1;
      transition: opacity 0.4s 0.1s;
    }
  `}
`;

const Wrapper = styled.div`
  background: ${theme.colors.blacks[90]};
  border-radius: ${theme.borderRadius.M};
  color: white;
  padding: ${theme.spacing.threeQuarters} ${theme.spacing.single};
  position: relative;
  pointer-events: none;
  width: fit-content;
  margin: auto;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: ${theme.spacing.threeQuarters};
    border-style: solid;
    border-color: ${theme.colors.blacks[90]} transparent transparent transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const InlineHover = (props) => {
  const { className, children, distanceFromItem, ParentComponent } = props;
  return (
    <Container className={className} parent={ParentComponent} distance={distanceFromItem}>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

InlineHover.defaultProps = {
  distanceFromItem: 8,
};

InlineHover.propTypes = {
  /** Additional style class. */
  className: PropTypes.string,
  /** Amount of pixels to display from item. */
  distanceFromItem: PropTypes.number,
  /** The parent component to hover on to show tooltip. */
  ParentComponent: PropTypes.elementType,
};

export default InlineHover;
