import PropTypes from 'prop-types';
import { compose, withProps, withStateHandlers } from 'recompose';
import withRouter from 'hocs/withRouter';
import { graphql } from '@apollo/client/react/hoc';
import PickedSkillsList from '../components/PickedSkillsList';
import { SKILLS_QUERY, REMOVE_SKILL_MUTATION } from 'graphql-queries/queries';
import { withToggle } from '../../../../../../hocs';
import Toast from 'components/Toast/Toast';

const withRemoveSkill = compose(
  graphql(REMOVE_SKILL_MUTATION, {
    name: 'removeSkillMutation',
    options: {
      refetchQueries: () => [{ query: SKILLS_QUERY }],
    },
  }),
  withProps(({ removeSkillMutation }) => ({
    removeSkill: (skillId) => removeSkillMutation({ variables: { skillId } }),
  }))
);

const initialState = {
  skillToRemove: null,
};

const withRemoveSkillDialog = compose(
  withToggle({
    initialIsOpen: false,
    stateName: 'isRemoveSkillConfirmDialogOpen',
    handleToggleCloseName: 'handleCloseRemoveSkillConfirmDialog',
    handleToggleOpenName: 'handleOpenRemoveSkillConfirmDialog',
  }),
  withStateHandlers(() => initialState, {
    _handleResetSkillToRemove: () => () => initialState,
    _handleSetSkillToRemove: () => (skillToRemove) => ({
      skillToRemove,
    }),
  }),
  withProps(
    ({ _handleSetSkillToRemove, handleOpenRemoveSkillConfirmDialog }) => ({
      handleSetSkillToRemove: (skill) => {
        _handleSetSkillToRemove(skill);
        handleOpenRemoveSkillConfirmDialog();
      },
    })
  )
);

const withData = compose(
  withRouter,
  graphql(SKILLS_QUERY, {
    name: 'skillsData',
    options: {
      fetchPolicy: 'cache-first',
    },
  }),
  withRemoveSkillDialog,
  withRemoveSkill,
  withProps(
    ({
      removeSkill,
      skillToRemove,
      handleCloseRemoveSkillConfirmDialog,
    }) => ({
      handleConfirmRemoveSkill: async () => {
        await removeSkill(skillToRemove.id);
        Toast.success(`You removed "${skillToRemove.name}" from your skills`);
        handleCloseRemoveSkillConfirmDialog();
      },
    })
  ),
);

const PickedSkillsListContainer = withData(PickedSkillsList);

PickedSkillsListContainer.propTypes = {
  handleStartEditSkill: PropTypes.func.isRequired,
};

export default PickedSkillsListContainer;
