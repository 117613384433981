import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MenuContents,
  MenuItem,
  MenuTrigger
} from './styles';
import Tooltip from '../../Tooltip';
import { border } from '@matterapp/matter-theme';

const TriggerComponent = React.forwardRef((props, ref) => (
  <div ref={ref} onClick={props.onClick}>
    {props.children}
  </div>
));

const Menu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);

  const { menuMap, className } = props;
  const triggerProps = {
    className,
    onClick: () => setIsOpen(!isOpen),
  };

  const content = (
    <MenuContents>
      {menuMap.map(menuItem => {
        if (menuItem) {
          const { menuLabel, handleAction, rcLabel } = menuItem;
          const onClickAction = () => {
            closeMenu();
            handleAction();
          };
          return (
            <MenuItem key={menuLabel} onClick={onClickAction} rcLabel={rcLabel} >
              {menuLabel}
            </MenuItem>
          );
        }
      })}
    </MenuContents>
  );

  return (
    <Tooltip
      arrowSize={8}
      distanceFromTrigger={0}
      position={['left']}
      style={{
        borderRadius: border.radius.M,
      }}
      isOpen={isOpen}
      content={content}
      triggerProps={triggerProps}
      TriggerComponent={TriggerComponent}
      onClickOutside={closeMenu}
    >
      <MenuTrigger />
    </Tooltip>
  );
};

Menu.defaultProps = {
  menuMap: [],
};

Menu.propTypes = {
  menuMap: PropTypes.arrayOf(
    PropTypes.shape({
      menuLabel: PropTypes.string.isRequired,
      handleAction: PropTypes.func.isRequired,
      rcLabel: PropTypes.string,
    })
  ).isRequired,
};

export default Menu;
