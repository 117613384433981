import React from 'react';
import PropTypes from 'prop-types';
import { IconNew as Icon } from '../../Icon';
import Dropdown from '../../Dropdown';
import Button from '../../Button';
import Customization from './Customization/Customization';
import ConfirmResetModal from '../ConfirmResetModal';
import { useRequestFeedback } from './hooks';
import {
  Actions,
  Copied,
  Form,
  InlineForm,
  InviteLinkWrapper,
  OptionWrapper,
  Option,
  Wrapper,
} from './styles';
import {
  CancelButton,
  DisabledMessage,
  FooterContainer,
  SendButton,
} from '../sharedStyles';

const RequestFeedback = (props) => {
  const {
    canSend,
    confirmResetModalProps,
    disabledMessage,
    drawerProps,
    dropdownProps,
    formProps,
    isCopyingLink,
    isDrawerOpen,
    messageProps,
    onClickCancel,
    onClickCopyLink,
    onClickSend,
    showShareLink,
    skillsSelectionListProps,
    userSkills,
  } = useRequestFeedback(props);
  if (disabledMessage) {
    return (
      <Wrapper>
        <DisabledMessage>{disabledMessage}</DisabledMessage>
      </Wrapper>
    );
  }
  return (
    <div>
      <ConfirmResetModal {...confirmResetModalProps} />
      <Wrapper>
        <Form {...formProps}>
          <InlineForm>
            {!isDrawerOpen && (
              <Button.Primary
                disabled={!canSend}
                content="Send"
                className="submit-button"
                rcLabel="composer-submit"
                useGrayscaleDisabled
              />
            )}
            <Dropdown.Email
              {...dropdownProps}
              rcLabel="unactivated-composer-email-input"
            />
          </InlineForm>
        </Form>
        <Actions>
          <OptionWrapper>
            <Option {...drawerProps}>Custom Settings</Option>
          </OptionWrapper>
          {showShareLink && (
            <InviteLinkWrapper>
              {isCopyingLink && <Copied>Copied!</Copied>}
              {!isCopyingLink && (
                <OptionWrapper>
                  <Option rcLabel="share-link" onClick={onClickCopyLink}>
                    <Icon name="shareLink" />
                    <div>Share Via Link</div>
                  </Option>
                </OptionWrapper>
              )}
            </InviteLinkWrapper>
          )}
        </Actions>
      </Wrapper>
      <Customization
        messageProps={messageProps}
        skillsSelectionListProps={skillsSelectionListProps}
        isOpen={isDrawerOpen}
        userSkills={userSkills}
      >
        <FooterContainer>
          <CancelButton onClick={onClickCancel} />
          <SendButton disabled={!canSend} onClick={onClickSend} />
        </FooterContainer>
      </Customization>
    </div>
  );
};

RequestFeedback.propTypes = {
  /** Thhe default message for the request. */
  defaultMessage: PropTypes.string.isRequired,
  /** Disaply a disabled message. */
  disabledMessage: PropTypes.node,
  messageMinLength: PropTypes.number,
  messageMaxLength: PropTypes.number,
  /** Callback when customize link is clicked. */
  onClickCustomize: PropTypes.func,
  /**
   * Callback when copy invite link is clicked.
   * @param e (Object): The click event.
   * @param clickProps (Object): {
   *   isAllSkillsSelected: If all skills are selected.
   *   isDefaultMessage: If message is the default message.
   *   message: The message to send in the request link.
   *   skillIds: Selected skill ids for the request link.
   * }
   */
  /** Callback to return a Promise that returns a list of users based on the search query passed. */
  onSearchEmail: PropTypes.func,
  /**
   * Callback when send button is clicked.
   * @param e (Object): Send event.
   * @param inputProps (Object): {
   *   email: Email to send request to.
   *   isAllSkillsSelected: If all skills are selected.
   *   isDefaultMessage: If message is the default message.
   *   message: The message to send in the request.
   *   pickedPeers: Formatted array of the single selected email to send.
   *   skillIds: Selected skill ids for the request.
   * }
   */
  onSend: PropTypes.func,
  /** The initial set of skills to be selected. */
  selectedSkillIds: PropTypes.arrayOf(PropTypes.string),
  /** List of skills to display. */
  userSkills: PropTypes.array.isRequired,
};

RequestFeedback.defaultProps = {
  defaultMessage:
    "I'm gathering 360-degree feedback from a handful of my peers I trust and respect. Your input will help me identify opportunities to grow. I’m using Matter to gather feedback and it should only take a few minutes.",
  onClickCustomize: () => null,
  onSend: () => null,
  messageMinLength: 25,
  messageMaxLength: 2000,
  selectedSkillIds: [],
  showShareLink: true,
  userSkills: [],
};

export default RequestFeedback;
