import React from 'react';

const Icon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#E6F2FF" />
    <path d="M17.5002 14.62C15.9538 14.62 14.7002 15.8736 14.7002 17.42L14.7002 27.42C14.7002 28.9664 15.9538 30.22 17.5002 30.22L22.8345 30.22L22.8345 28.62L17.5002 28.62C16.8375 28.62 16.3002 28.0827 16.3002 27.42L16.3002 17.42C16.3002 16.7573 16.8375 16.22 17.5002 16.22L30.5002 16.22C31.1629 16.22 31.7002 16.7573 31.7002 17.42L31.7002 27.2753L33.3002 27.2753L33.3002 17.42C33.3002 15.8736 32.0466 14.62 30.5002 14.62L17.5002 14.62Z" fill="#4584FF" />
    <path d="M29.9802 20.32L18.0202 20.32L18.0202 18.72L29.9802 18.72L29.9802 20.32Z" fill="#4584FF" />
    <path d="M18.0202 23.28L29.9702 23.28L29.9702 21.68L18.0202 21.68L18.0202 23.28Z" fill="#4584FF" />
    <path d="M18.0202 26.31L24.9402 26.31L24.9402 24.71L18.0202 24.71L18.0202 26.31Z" fill="#4584FF" />
    <path d="M29.3302 30.9786L31.2345 29.0743L32.3659 30.2057L29.0959 33.4757C28.9459 33.6257 28.7424 33.71 28.5302 33.71C28.318 33.71 28.1146 33.6257 27.9645 33.4757L24.6945 30.2057L25.8259 29.0743L27.7302 30.9786L27.7302 25.03L29.3302 25.03L29.3302 30.9786Z" fill="#4584FF" />
  </svg>


);

export default Icon;
