import { useState } from 'react';

export const getEnabledFunction = (canRunFunction, func) =>
  canRunFunction ? func : () => null;

export const useDeleteState = (props) => {
  const {
    onClickCancel,
    onClickDelete,
    workspace,
    ...otherProps
  } = props;
  const [nameValue, setNameValue] = useState('');
  const [canChangeValue, setCanChangeValue] = useState(true);
  const canClickDelete = !!nameValue && workspace.name === nameValue;

  const handleClickCancel = (e) => {
    onClickCancel(e, props);
    setCanChangeValue(true);
    setNameValue('');
  };

  const handleClickDelete = (e) => {
    setCanChangeValue(false);
    onClickDelete(e, { ...props, id: workspace.id });
  };

  return {
    ...otherProps,
    canClickDelete,
    handleChangeName: getEnabledFunction(canChangeValue, (e, textProps) => {
      setNameValue(textProps.value);
    }),
    handleClickCancel,
    handleClickDelete,
    handleKeyPress: getEnabledFunction(canChangeValue, (e) => {
      if (canClickDelete && canChangeValue && e.key === 'Enter') {
        handleClickDelete(e);
      }
    }),
    name: nameValue
  };
};
