import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import List from '../../List';
import ListItem from '../ListItem';
import NoPeersMessage from '../NoPeersMessage';
import { isEqual } from 'lodash';

const ANIMATION_TIME = 0;
const Container = styled.div``;

export default class PeerList extends React.PureComponent {
  static propTypes = {
    /** Additional style class. */
    className: PropTypes.string,
    /** When the user has no avatar image, generate default avatar of use stock avatar image (leaf). */
    defaultAvatarIsGenerated: PropTypes.bool,
    /** Default sub header content to show. */
    defaultSubHeaderContent: PropTypes.oneOf(Object.values(ListItem.subHeaderOptions)),
    /** Link to find your peers page. Displays button if passed in. */
    findYourPeersLink: PropTypes.string,
    /** Extends items to left and right edge on mobile only. */
    flushedEdge: PropTypes.bool,
    /** Extends items to left and right edge with border. */
    flushedEdgeOnMobile: PropTypes.bool,
    /** Callback when find you peers button is clicked. */
    onClickFindYourPeers: PropTypes.func,
    /**
     * Callback when a peer list item is clicked.
     * @param { Object } e: The click event.
     * @param { Object } itemProps: The props of the item clicked.
     * @returns { void }
     */
    onClickPeer: PropTypes.func,
    /**
     * Function to render the actions continer of the item.
     * @param { Object } peer: The peer definition object.
     * @returns { React.Component } The item actions.
     */
    renderPeerActions: PropTypes.func,
    /**
     * Function to render the profile card of a list item.
     */
    renderProfileCard: PropTypes.func,
    /**
     * List of peers to show in the list.
     */
    peers: PropTypes.arrayOf(
      PropTypes.shape({
        /** The email of the peer. */
        email: PropTypes.string.isRequired,
        /** The full name of the peer. */
        fullName: PropTypes.string,
        /** The url of the photo of the peer. */
        photoUrl: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    defaultSubHeaderContent: ListItem.subHeaderOptions.source,
    findYourPeersLink: null,
    onClickPeer: undefined,
    renderPeerActions: () => {},
    peers: [],
  };

  static AddButton = List.AddButton;
  static subHeaderOptions = ListItem.subHeaderOptions;

  constructor(props) {
    super(props);
    this.state = {
      items: props.peers,
      itemsToAdd: [],
      itemsToRemove: [],
      isRemoving: false,
      isLoaded: !!props.peers.length,
    };
    this._itemsLoaded = {};
    this.loadInitialItems(props.peers, true);

    this.addTimeout = null;
    this.removeTimeout = null;
  }

  componentDidUpdate = () => {
    const { items, isLoaded, isRemoving } = this.state;
    const { peers } = this.props;
    if (!isLoaded && !items.length && peers.length) {
      this.loadInitialItems(peers);
    } else if (items.length < peers.length) {
      this.handleAddItem();
    } else if (!isRemoving && items.length > peers.length) {
      this.handleRemoveItem();
    } else if (!isEqual(items, peers)) {
      this.loadInitialItems(peers);
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.addTimeout);
    clearTimeout(this.removeTimeout);
  };

  clearAddItem = (itemsToAdd) => {
    const newItemsToAdd = itemsToAdd.filter(
      (item) => !this.state.itemsToAdd.includes(item)
    );
    this.setState({ itemsToAdd: newItemsToAdd });
  };

  clearRemoveItem = (itemsToRemove) => {
    const newItemsToRemove = itemsToRemove.filter(
      (item) => !this.state.itemsToRemove.includes(item)
    );
    this.setState({
      itemsToRemove: newItemsToRemove,
      items: this.props.peers,
      isRemoving: false,
    });
  };

  handleAddItem = () => {
    const { peers } = this.props;
    const addedItems = peers.filter(({ email }) => !this._itemsLoaded[email]);
    const itemsToAdd = addedItems.map((peer) => {
      this._itemsLoaded[peer.email] = true;
      return peer.email;
    });

    this.setState({ itemsToAdd, items: peers });
    this.addTimeout = setTimeout(
      () => this.clearAddItem(itemsToAdd),
      ANIMATION_TIME * 1000
    );
  };

  handleRemoveItem = () => {
    const { items } = this.state;
    const { peers } = this.props;
    const peersLoaded = {};
    peers.forEach(({ email }) => {
      peersLoaded[email] = true;
    });

    const deletedItems = items.filter(({ email }) => !peersLoaded[email]);
    const itemsToRemove = deletedItems.map((peer) => {
      delete this._itemsLoaded[peer.email];
      return peer.email;
    });

    this.setState({
      itemsToRemove,
      isRemoving: true,
    });
    this.removeTimeout = setTimeout(
      () => this.clearRemoveItem(itemsToRemove, peers),
      ANIMATION_TIME * 4000
    );
  };

  loadInitialItems = (items, fromConstructor) => {
    items.forEach(({ email }) => {
      this._itemsLoaded[email] = true;
    });
    if (!fromConstructor) {
      this.setState({ items, isLoaded: !!items.length });
    }
  };

  renderListItems = (props, state) => {
    const {
      defaultAvatarIsGenerated,
      defaultSubHeaderContent,
      flushedEdge,
      flushedEdgeOnMobile,
      onClickPeer,
      renderPeerActions,
      renderProfileCard,
    } = props;
    const { items, itemsToAdd, itemsToRemove } = state;
    return items.map((peer) => {
      return (
        <ListItem
          actions={renderPeerActions(peer)}
          animationTime={ANIMATION_TIME}
          defaultAvatarIsGenerated={defaultAvatarIsGenerated}
          defaultSubHeaderContent={defaultSubHeaderContent}
          flushedEdge={flushedEdge}
          flushedEdgeOnMobile={flushedEdgeOnMobile}
          isCanAddRemove
          isBeingAdded={itemsToAdd.includes(peer.email) || itemsToAdd.includes(peer.id)}
          isBeingRemoved={itemsToRemove.includes(peer.email) || itemsToAdd.includes(peer.id)}
          key={`${peer.email}-peer`}
          onClick={onClickPeer}
          peer={peer}
          renderProfileCard={renderProfileCard}
          disabled={peer.disabled}
        />
      );
    });
  };

  render() {
    const { className, findYourPeersLink, onClickFindYourPeers } = this.props;
    const { items } = this.state;
    const listItems = this.renderListItems(this.props, this.state);

    return items.length ? (
      <Container className={className}>{listItems}</Container>
    ) : (
      <NoPeersMessage
        findYourPeersLink={findYourPeersLink}
        onClickFindYourPeers={onClickFindYourPeers}
      />
    );
  }
}
