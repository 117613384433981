import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import omitStyled from '../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints;

const nonTouchDeviceStyles = (props) => `
  &:hover:not(:active) {
    border-color: ${props.theme.buttonModes.pill.hover.borderColor};
  }
`;

const CheckboxLabel = omitStyled('label', ['size', 'fluid'])`
  cursor: pointer;
  user-select: none;
  border-radius: 100px;
  display: inline-block;
  padding: 0 16px;

  color: ${() => theme.buttonModes.pill.color};
  border: 1.5px solid ${() => theme.buttonModes.pill.borderColor};
  background: ${() => theme.buttonModes.pill.background};

  transition:
    color 0.1s ease-in-out,
    border-color 0.1s ease-in-out,
    background 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;

  &:active:not(.disabled),
  &.checked {
    color: ${() => theme.buttonModes.pill.active.color};
    background: ${() => theme.buttonModes.pill.active.background};
    border-color: ${() => theme.buttonModes.pill.active.borderColor};
    box-shadow: 0 1px 2px ${() => theme.buttonModes.pill.active.boxShadowColor};
  }

  &.checked {
    background: ${() => theme.buttonModes.pill.checked.background} !important;
    border-color: ${() => theme.buttonModes.pill.checked.borderColor} !important;
  }

  &.disabled {
    cursor: default;
    color: ${() => theme.buttonModes.disabled.color};
    background: ${() => theme.buttonModes.disabled.background};
    border-color: ${() => theme.buttonModes.disabled.borderColor};
  }

  ${(props) => !props.disabled && !isTouchDevice() && nonTouchDeviceStyles(props)};

  ${({ fluid }) => fluid && `
    width: 100%;
  `}


  ${({ size }) => size === 'medium' && `
    font-size: 17px;
    height: 44px;
    line-height: 41px;
  `}

  ${({ size }) => size === 'large' && `
    font-size: 22px;
    height: 48px;
    line-height: 45px;
  `}
`;

const CheckboxInput = styled.input`
  display: none;
`;

const buildListItemClass = (checked, disabled) => {
  let listItemClass = 'checkboxPill ';
  listItemClass += checked ? 'checked ' : '';
  listItemClass += disabled ? 'disabled ' : '';
  return listItemClass;
};

const CheckboxPill = ({
  value,
  name,
  checked,
  label,
  disabled,
  type,
  onChange,
  fluid,
  size,
}) => (
  <CheckboxLabel
    checked={checked}
    className={buildListItemClass(checked, disabled)}
    htmlFor={`checkbox-${label}`}
    disabled={disabled}
    onChange={onChange}
    fluid={fluid}
    size={size}
  >
    {label}
    <CheckboxInput
      type={type}
      name={name}
      value={value}
      id={`checkbox-${label}`}
      disabled={disabled}
    />
  </CheckboxLabel>
);

CheckboxPill.defaultProps = {
  checked: false,
  disabled: false,
  fluid: false,
  type: 'checkbox',
  size: 'medium',
};

CheckboxPill.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  type: PropTypes.oneOf([
    'checkbox',
    'radio',
  ]),
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default CheckboxPill;
