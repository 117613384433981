import { useEffect, useRef, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { WORKSPACES } from 'app-consts';
import { useStripeSubscription } from 'hooks';
import { formatEmailSerachForDropdown } from 'helpers/dropdown';
import {
  CONNECTED_ACCOUNTS_QUERY,
  CONTACTS_LIST_MUTATION,
  LEAVE_WORKSPACE_MUTATION,
  SEND_UPGRADE_WORKSPACE_REQUEST,
  WORKSPACE_INVITATION_SUGGESTIONS,
} from 'graphql-queries/queries';
import { useWorkspace } from './WorkspacesActivityFeedHooks';
import { mixpanel } from 'libs/tracking';

const handleTracking = {
  requestUpgradeSent: mixpanel.createEventSender('request-upgrade-modal:request-send-clicked'),
};

export const WORKSPACES_EXPERIMENT_NAME = 'workspaces';
export const WORKSPACE_ID_QUERY_STRING = 'wsid';

/*****************************************************************************/
/* Defines mutation for leaving workspace.                                   */
/*****************************************************************************/
export const useLeaveWorkspace = ({ refetchQueries = [] } = {}) => {
  const [mutation, loading] = useMutation(LEAVE_WORKSPACE_MUTATION);
  const leaveWorkspace = (props) => {
    return mutation({
      variables: {
        workspaceId: props.workspaceId,
      },
      refetchQueries
    });
  };
  return { leaveWorkspace, loading };
};

/*****************************************************************************/
/* Searches contact from multi-email input.                                  */
/*****************************************************************************/
const useCurrentUserHasAccounts = () => {
  const { loading, data, error } = useQuery(CONNECTED_ACCOUNTS_QUERY);
  if (loading || error) {
    return {};
  }
  const { connectedAccountsMap } = data.currentUser;
  const { hasPeerRecommendationsSource } = connectedAccountsMap;
  return { loading, data, error, hasPeerRecommendationsSource };
};

export const filterNonPendingAndNonAcceptedEmails = (workspace, contacts) => {
  const emails = new Set();
  workspace.members.forEach((currentMember) => {
    if (currentMember.status === WORKSPACES.USER_STATUS.ACCEPTED || currentMember.status === WORKSPACES.USER_STATUS.PENDING) {
      emails.add(currentMember.person.email);
    }
  });
  return contacts.filter(contact => !emails.has(contact.email));
};

/*****************************************************************************/
/* Searches contact from multi-email input.                                  */
/*****************************************************************************/
export const useSearchEmail = () => {
  const { hasPeerRecommendationsSource } = useCurrentUserHasAccounts();
  const { workspace, loading } = useWorkspace();
  const [mutation] = useMutation(CONTACTS_LIST_MUTATION);
  const [workspaceInvitationSuggestions] = useMutation(WORKSPACE_INVITATION_SUGGESTIONS);
  const hasSlackWorkspace = workspace && workspace.slackWorkspace;
  const onSearchEmail = async (nameOrEmail) => {
    if (!hasSlackWorkspace) {
      const { data } = await mutation({
        variables: {
          nameOrEmail,
        },
      });
      const emails = filterNonPendingAndNonAcceptedEmails(workspace, data.findContactsByNameOrEmail);
      return formatEmailSerachForDropdown(emails);
    }
    const { data } = await workspaceInvitationSuggestions({
      variables: {
        workspaceId: workspace.id,
        nameOrEmail
      },
    });
    const { slack, contacts } = data && data.invitationSuggestions;
    const slackData = formatEmailSerachForDropdown(slack);
    const contactsData = formatEmailSerachForDropdown(contacts);

    const slackHeaderItem = {
      label: 'Slack Members',
      subLabel: null,
      value: '',
      __typename: 'HeaderItem'
    };

    const contactsHeaderItem = {
      label: 'Contacts',
      subLabel: null,
      value: '',
      __typename: 'HeaderItem'
    };

    const suggestions = [];
    if (slackData?.length) {
      suggestions.push(slackHeaderItem);
      suggestions.push(...slackData);
    }
    if (contactsData?.length) {
      suggestions.push(contactsHeaderItem);
      suggestions.push(...contactsData);
    }

    return suggestions;
  };

  return {
    hasContacts: hasPeerRecommendationsSource,
    hasPeerRecommendationsSource,
    isSearchEmailLoading: loading,
    onSearchEmail: hasPeerRecommendationsSource || hasSlackWorkspace ? onSearchEmail : null
  };
};

/*****************************************************************************/
/* Hook defining send upgrade suggestion modal.                              */
/*****************************************************************************/
export const useUpgradeModal = (workspace = {}, adminMembers, showUpgradeModal) => {
  const [mutation] = useMutation(SEND_UPGRADE_WORKSPACE_REQUEST);
  const sentUpgradeRef = useRef();
  const { id } = workspace;
  const [hasSentUpgrade, setHasSentUpgrade] = useState(false);
  const [isOutOfKudos, setIsOutOfKudos] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(showUpgradeModal);
  const openUpgradeModal = (isOutOfKudos) => {
    setIsUpgradeModalOpen(true);
    setIsOutOfKudos(!!isOutOfKudos);
  };
  const closeUpgradeModal = () => {
    setIsUpgradeModalOpen(false);
    setIsOutOfKudos(false);
  };

  useEffect(() => {
    sentUpgradeRef.current = null;
    return () => {
      clearTimeout(sentUpgradeRef.current);
    };
  }, []);

  return {
    hasSentUpgradeSuggestion: hasSentUpgrade,
    onClickSend: (e, props) => {
      const { isSendToAllAdmins, workspaceAdminId, message } = props;
      setHasSentUpgrade(true);
      setIsOutOfKudos(false);
      sentUpgradeRef.current = setTimeout(() => {
        setHasSentUpgrade(false);
      }, 3600);
      handleTracking.requestUpgradeSent();
      return mutation({
        variables: {
          workspaceId: id,
          workspaceAdminId: isSendToAllAdmins ? null : workspaceAdminId,
          message,
          isOutOfKudos
        },
      });
    },
    isOpen: isUpgradeModalOpen,
    onClose: closeUpgradeModal,
    openUpgradeModal,
    closeUpgradeModal,
    workspace,
    workspaceId: id,
    adminMembers
  };
};

/*****************************************************************************/
/* Shared hook for each workspaces page to control settings panel state.     */
/*****************************************************************************/
export const useSettingsPanelState = (workspace, showUpgradeModal) => {
  const { slackWorkspace } = workspace || {};
  const { loading } = useStripeSubscription({ workspace });
  const isAdminMember = false;

  const {
    hasSentUpgradeSuggestion,
    openUpgradeModal,
    ...upgradeModalProps
  } = useUpgradeModal(workspace, [], showUpgradeModal);

  return {
    openUpgradeModal,
    composerProps: {
      hasSentUpgradeSuggestion,
      invitePath: `https://slack.com/app_redirect?channel=${slackWorkspace?.primaryChannel}`,
    },
    isAdminMember,
    upgradeModalProps,
    workspaceSideColumnProps: {
      hasSentUpgradeSuggestion,
    },
    workspaceSettingsProps: {
      hasSentUpgradeSuggestion,
      workspace,
    },
    isSettingsPanelLoading: loading,
  };
};
