import React, { useState } from 'react';
import { 
  Box,
  TableHead,
  TableBody,
  Table,
  Typography
} from '@mui/material';
import CategoryGroup from './CategoryGroup';
import AddCategoryModal from './AddCategoryModal';
import { HeaderTableRow, StyledButton, CategoryHeader, StyledTableCell } from './styles';

export default function QuestionList({ 
  questions,
  categories,
  tenantId,
  workspaceId
}) {
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [addCategoryModalIsOpen, setAddCategoryModalIsOpen] = useState(false);

  return (
    <Box sx={{ mb: 10 }}>
      <AddCategoryModal 
        isOpen={addCategoryModalIsOpen}
        onClose={() => setAddCategoryModalIsOpen(false)}
        tenantId={tenantId}
        allCategories={categories}
      />
      <CategoryHeader>
        <Typography variant='h4'>Categories</Typography>
        <StyledButton onClick={() => setAddCategoryModalIsOpen(true)}>
          Add Category
        </StyledButton>
      </CategoryHeader>
      <Table size='large'>
        <TableHead>
          <HeaderTableRow>
            <StyledTableCell>&nbsp;</StyledTableCell>
            <StyledTableCell visibleOnMobile>Title</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Question Count</StyledTableCell>
            <StyledTableCell>&nbsp;</StyledTableCell>
          </HeaderTableRow>
        </TableHead>
        <TableBody>
          {[...categories]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((category) => (
              <CategoryGroup 
                key={category} 
                workspaceId={workspaceId}
                category={category}
                allCategories={categories}
                tenantId={tenantId}
                isExpanded={expandedPanel === category}
                allQuestions={questions}
                questions={[...questions].filter((question) => question.category.id === category.id)}
                onChange={() => {
                  if (expandedPanel === category) {
                    setExpandedPanel(null);
                  } else {
                    setExpandedPanel(category);
                  }
                }}
              />
            ))}
        </TableBody>
      </Table>
    </Box>
  );
}
