
import React from 'react';

const Icon = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 9C0 13.6944 3.80558 17.5 8.5 17.5C13.1944 17.5 17 13.6944 17 9C17 4.30558 13.1944 0.5 8.5 0.5C3.80558 0.5 0 4.30558 0 9Z" fill="#1C1C21"/>
    <path d="M0 9C0 13.6944 3.80558 17.5 8.5 17.5C13.1944 17.5 17 13.6944 17 9C17 4.30558 13.1944 0.5 8.5 0.5C3.80558 0.5 0 4.30558 0 9Z" fill="#4584FF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.7916 6.30951C13.1236 6.64145 13.1236 7.17964 12.7916 7.51159L8.1237 12.1795C7.96429 12.3389 7.74809 12.4285 7.52266 12.4285C7.29722 12.4285 7.08102 12.3389 6.92162 12.1795L4.69037 9.94825C4.35842 9.61631 4.35842 9.07812 4.69037 8.74617C5.02231 8.41423 5.5605 8.41423 5.89245 8.74617L7.52266 10.3764L11.5895 6.30951C11.9215 5.97756 12.4597 5.97756 12.7916 6.30951Z" fill="white"/>
  </svg>

    
);

export default Icon;