import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../SVG';

const UploadIcon = (props) => {
  const { size, ...otherProps } = props;
  return (
    <SVG size={size} viewBox={SVG.viewBoxFromSize(size)} {...otherProps}>
      <path
        d="M37.1,30.9c0.6,0.6,0.6,1.5,0,2.1c-0.3,0.3-0.7,0.4-1.1,0.4s-0.8-0.1-1.1-0.4l-9.4-9.4V46
            c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5V23.6l-9.4,9.4c-0.6,0.6-1.5,0.6-2.1,0s-0.6-1.5,0-2.1L24,17.9L37.1,30.9z M2.5,6.5V20
            c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V9.5h37V20c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V6.5H2.5z"
      />
    </SVG>
  );
};

UploadIcon.defaultProps = {
  size: 48,
};

UploadIcon.propTypes = {
  size: PropTypes.number,
};

export default UploadIcon;
