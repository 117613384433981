import React from 'react';
import Base from './Base';
import { Warning } from './variants';
import { ColorPalettes } from './styles';

const Message = (props) => <Base {...props} />;
Message.Warning = Warning;
Message.colors = ColorPalettes;

export default Message;
