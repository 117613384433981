import { getEmailValidation } from '../profile/email';
import { getPhoneNumberValidation } from './phone-number';
import { ERROR_CANT_LEAVE_BLANK } from '../../copy-consts';

export const testPhoneNumber = (input) => /^[+0-9()\s]*$/.test(input);

export const getPhoneNumberOrEmailValidation = (fieldName) => (values) => {
  if (!values[fieldName]) { return { [fieldName]: ERROR_CANT_LEAVE_BLANK }; }
  const input = values[fieldName];
  const phoneNumberError = getPhoneNumberValidation(fieldName)(values);
  const emailError = getEmailValidation(fieldName)(values);
  if (!input) { return { [fieldName]: ERROR_CANT_LEAVE_BLANK }; }
  if (input.length === 0) { return { [fieldName]: ERROR_CANT_LEAVE_BLANK }; }
  const isPhoneNumber = testPhoneNumber(input);
  if (isPhoneNumber) { return phoneNumberError; }
  return emailError;
};
