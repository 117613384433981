import log from 'loglevel';
import { ENV_VARS } from '../app-consts';

const { LOG_LEVELS } = ENV_VARS;

if (LOG_LEVELS === 'ENABLE_ALL') {
  log.enableAll();
} else if (LOG_LEVELS === 'DISABLE_ALL') {
  log.disableAll();
}

export default log;
