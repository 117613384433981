import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonNew as Button } from '../../../Button';
import Container from '../Container';
import Heading from '../Heading';
import { SIZE } from '../size';
import theme from '@matterapp/matter-theme';

const CustomizedContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  ${() => theme.media.medium`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `}
`;

const CustomizedHeading = styled(Heading)`
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 8px;
  ${() => theme.media.medium`
    margin: 0;
  `}
`;

const ActionContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 8px;
  ${() => theme.media.medium`
    padding-bottom: 0px;
  `}
`;

const DynamicAction = ({ title, actionLabel, onActionClick, actionIcon }) => (
  <CustomizedContainer>
    <CustomizedHeading size={SIZE.MEDIUM}>{title}</CustomizedHeading>
    <ActionContainer>
      <Button
        link
        size="small"
        muted
        iconPosition="right"
        icon={actionIcon}
        onClick={onActionClick}
      >
        {actionLabel}
      </Button>
    </ActionContainer>
  </CustomizedContainer>
);

DynamicAction.propTypes = {
  title: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  onActionClick: PropTypes.func.isRequired,
  actionIcon: PropTypes.string,
};

DynamicAction.defaultProps = {
  actionIcon: null,
};

export default DynamicAction;
