import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const ColoredPath = styled.path`
  fill: currentColor;
`;

export const TopCloud = styled(ColoredPath).attrs({
  d: `M2195.7,1172.5c-6.9-16.9-19.8-11.4-24-22.2c-6.2-40.1-42.6-52.6-77.4-37.4
  c-4.1,0.8-7.7-1.7-11.1-3.7c-9.8-4.2-21.2-2.7-31.2-0.6c-2.9,1.8-8.2,4.5-9.1-0.7c-5.3-18-12.8-39.2-33.8-43.1
  c-8.8-3-17.5-8.8-27.2-6.6c-2.4,0.6-3.9-0.4-5-2.4c-8.3-12.7-33.2-26.5-48.5-26.3c-6,0.7-12.4,3.8-18.3,1.9
  c-34.8-16.6-43.3,9.4-52.5,3.7c-6.2-6.8-25.1-7.9-24.2-14.4c2.7-46-37.3-81.3-81.3-83.5c-13.8-1.2-28.3,9.5-39.7-2.8
  c-3.9-0.7-6.4-4.2-10.5-4.3c-35.5-6.6-27.9,13.9-44.5,2.5c-16.5-11.1-17.3-9.4-35.4-6.2c-2.9,0.9-5.6,0-8.5-0.5
  c-6.8-2.5-16.7,1.8-22.3-1.6c-1.1-2.3-3.5-4-3.4-6.9c-4.9-8.8-12.9-17.2-21.3-22.8c-4.7-2.8-11.7-3.5-14.9-8.3c-0.9-3,0-6.3-2.2-9
  c-0.6-6.8-7.6-9.9-12.7-12.8c-4.6-1.3-10.8,0.6-13.9-3.6c-13.4-17.7-24.2-26.4-47.1-27.5c-10.9-1.5-17.1,5.3-27.6-2.4
  c-11.5-9.6-28-14.1-42.8-11.6c-7.8,1.4-15.1,5.5-22.7,6.7c-4.9-1.9-5.8-9-10.5-11.5c-2.9-1.2-5.3-3.4-7.2-5.9
  c0.5-0.1,0.7-0.1,1.6-0.2c-4.8-3.3-8.7-6.1-13.8-7.9c-4.5-2.4,2.4-15.3-2-22.4c-1.3-3.8-2.6-7.7-4.6-11.2c-1.1-1.9-3.3-3.2-2.6-5.9
  c-2.2-5.5-4.5-11.1-7.2-16.4c-14.2-13.6-36.5-21-56.1-17.4v0.3c-5.7-1.9-10.2,2.1-15.5,3.9c-2.9,1.1-6.1-0.6-8.8,1.1
  c-2.2,1.3-4.7,2.3-7.1,3.4c-19.1,5.5-30,21.8-42,36.4c-2.9,2.3-6.5-2.5-9.7-2.2c-24.2-1.4-47.9,5.2-68.8,17.2
  c-10.8,7.6-20,18.1-24,30.9c-4.7,11.9-10.6,23.8-8,37.2c-24.1,5.2-32.6,16.9-43.4,38.2c-25.3-22.2-47.9-26.7-78.6-11.9
  c-7.2,2.2-22.7,18.8-28.4,16.1c-10.7-7.4-23.8-8.1-36.2-10.7c-8.6-2.5-14.5,4.5-22.3,6.8c-5.5,1.6-10.1,6.4-15.2,9
  c-4.6,1.9-6.4,5.7-7.3,9.9c-2.1,6.8-5.3,12.8-8.7,19c-3.4-1.8-5.9-5.5-9.9-5.4c-7.5,0.7-15.7-0.2-21.7,5
  c-6.9,5.7-16.1,9.9-17.3,19.5c-1.9,3.5,0.3,11.3-5,11.5c-3.9,0.4-7.8-1.4-11.1,1.5c-7.8,4.8-16.1,8.7-21,17
  c-7.3,10-14.9,19.5-19.1,31.4c-9-6.6-20.5-6.5-30.9-8.9c-12.7-0.2-24.9,5.4-36.6,9.9c-5.1,3.4-18.7-14.8-29.3-14.2
  c-7.5-0.4-4.1-12.4-12.7-20c-8.8-10.1-16.2-21.2-26.8-29.7c-16.4-8.1-36.5-3.5-53.3,1.3c-11.7,5.6-12.1,3.5-23.1-2.3
  c-13.5-6.5-30.4-3.9-43.3,2.6c-6-23.7-28.2-39.7-50.4-47.5c-8.7-3.5-26.6-0.4-31.6-5c-10.3-13.2-25.5-23.2-42.7-23.6
  c-9.2,1.3-19.1,0.6-27.4,5.8c-1.6,1.2-3.8,2-5.8,2.3c-5.1,0-9-4.9-13.6-6.6c-7.6-3.3-15.4-5.8-23.8-4.7
  c-9.8-0.7-15.1,7.3-21.3,13.5c-8.3,2.3-17.4-1.3-24.2-6.3c-4.8-3.9-10.5-2.5-16.4-3.2c-3.2-25.1-10-51.4-31.7-66.8
  c-27.1-28.4-71-31.6-105-13.8c-4.4-5.3-8.9-11-16-12.9c-7-1.8-13.9-4.8-21.1-2.6c-4.2,0.7-7.2,4.2-11,5.5c-5.5,1.4-10,6.2-14.2,9.9
  c-3.8,5.5-9.6-4-13.5-5.7c-4.6-4.1-9.5-3.1-14.5-1.2c-4.7,2.2-1.9,9.2-3,13.7c-2.5,0.2-5,0.3-7.7,0.5V802
  c-15.7-2.4-32,0.6-45.4,8.2c-3.6,2.2-8.9,2.7-10.7,6.4c-2.7,4.5-4.9,9.2-7.2,13.8c0.6,2.3-1.5,3.4-2.6,5c-2,2.9-3.3,6.3-4.6,9.5
  c-2.3,4.3-1.6,10.3-0.5,14.8c0.9,1.8,0.3,2.9-1.5,4c-5.1,1.5-9,3.9-13.8,6.7c0.8,0.1,1.1,0.1,1.6,0.2c-1.9,2.1-4.3,3.9-7.2,4.9
  c-4.7,2.1-5.6,8.1-10.5,9.7c-7.6-1.1-14.9-4.5-22.7-5.7c-5.2-1.4-10.3-0.4-15.6,0c-10.1,0.8-19.6,4.3-27.3,9.7
  c-10.5,6.5-16.7,0.8-27.6,2c-10.3,0.9-20.6,2.4-29.4,6.9c-6.6,4.7-12,10.7-17.7,16.2c-3.1,3.5-9.3,1.9-13.9,3
  c-5.2,2.5-12.2,5-12.7,10.8c-2.2,2.2-1.3,5.1-2.2,7.6c-3.1,4-10.1,4.6-14.9,7c-8.2,4.2-14.5,11-20.3,17.3c-0.5,0.6-1.1,1.3-1,1.9
  c0.1,2.5-2.4,3.9-3.4,5.8c-5.6,2.8-15.5-0.8-22.3,1.3c-1.7,0.2-3.3,0.5-4.9,0.8c-1.2-0.1-2.4-0.3-3.6-0.4c-2.6,0.4-5-0.2-7.4-1
  c-9.9-3.7-19.9,1.7-28,6.2c-16.6,9.6-9-7.7-44.5-2.1c-4,0.1-6.6,3.1-10.5,3.7c-0.6,0.1-1.2,0.6-1.7,1c-10.9,9.4-25,0-37.9,1.4
  c-18.1,0.6-36.1,7.3-51.3,15.3c-20.2,15.4-30.8,31.7-30,55c0.9,5.5-18,6.4-24.2,12.1c-6.3,3.2-10.2-3.5-15.7-4.9
  c-11.9-4.4-25.6-3.1-36.8,1.8c-5.9,1.7-12.3-1-18.3-1.6c-15.2-0.2-40.2,11.4-48.5,22.1c-1.1,1.7-2.6,2.5-5,2
  c-9.7-1.8-18.4,3.1-27.2,5.6c-8.4,1.3-16.1,6-21.3,11.7c-7.5,6.9-8.2,16.3-12.5,24.6c-1,4.4-6.3,2.1-9.1,0.6
  c-10.1-1.7-21.5-3-31.2,0.5c-3.3,1.7-7,3.8-11.1,3.1c-34.9-12.8-71.2-2.2-77.4,31.5c-4.2,9-17.1,4.5-24,18.7
  c-10.9,0.6-20.5-4-31.3-3.4c-8.5-1.4-333,26.7-333,26.7H0h0h1280h1280C2544.3,1199.8,2209.5,1159.5,2195.7,1172.5z M-685.3,1075.5
  c-0.3-0.2-0.5-0.3-0.8-0.5c0.4-0.4,0.8-0.8,1.2-1.1c0.2,0.1,0.4,0.3,0.6,0.4C-684.6,1074.7-684.9,1075.1-685.3,1075.5z
   M-237.3,916.5c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.4,0.3-0.8,0.4-1.1c0.2,0.1,0.3,0.1,0.5,0.2C-236.9,915.9-237.1,916.2-237.3,916.5z
   M1517.3,863.3c-0.2-0.4-0.4-0.8-0.6-1.2c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0.5,0.3,0.9,0.4,1.4C1517.5,863.3,1517.4,863.3,1517.3,863.3
  z M1965.3,1052.1c-0.4-0.5-0.7-1-1.1-1.4c0.2-0.2,0.4-0.3,0.6-0.5c0.4,0.4,0.8,0.9,1.2,1.3
  C1965.8,1051.7,1965.5,1051.9,1965.3,1052.1z`,
})``;

export const BottomCloud = styled.path.attrs({
  d: `M2386.6,1126.3c-1-0.1-1.9-0.2-2.9-0.3C2384.7,1126,2385.6,1126,2386.6,1126.3z
  M2560,1200.1l-1280,0.1H0l-1280-0.1l17-10c13.2-8.4,29.3,3,40.3-10.9c28.9-18,50.1,5.5,55.4-3.8c5.6-13.9,17.5-21.8,24.5-32.5
  c7.6-17,21.3-26.3,36.2-28.5c-0.4,0.2-0.8,0.4-1.3,0.7c1.8,0.7,4.5,0.2,5.4-0.9c-0.4,0-0.8,0-1.2-0.1c8.1-0.7,16.5,0.6,24.4,3.9
  c34.3-21.5,36.4,7.8,58-0.2c31.6-17.3,41.5,18,53.1,6.4c5.9-36.6,53.9-52.8,75-21.1c11.2,1.7,22.3-11.2,34.6-5.9
  c31.4,4.8,11.8-18.9,66.6-4.7c3.8-10.2,9.1-22.6,19.4-24.9c2.6-0.6,2.6-0.7,3-3.6c4.5-17.4,20.5-29.7,35-34.6
  c11.9-3.4,24.5,1.8,36,1.9c16-6.4,32.4,1.5,48,2.4c12.1-6.6,26.4-5.7,38.3,1.1c8.5-4.5,18.6-2.2,25.2,5.5c2.7,4.2,6.7-2.8,10.7-3.2
  c14.8-5.9,28.9,4.8,39.1,16.2c9.7-34.6,49.7-40.7,69.5-13.4c3.5,5,4.9,11.6,6.1,17.8c12.6-3.2,22.7,20.8,31.9,6.4
  c6.9-5.4,17.1-5.6,23.9,0.4c9.2-30.2,35.7-41.8,61.5-42.1c17.6,2.1,26.5,15.7,42.5-1.5c26.6-22.3,66.7-21.1,90.7,5.2
  c12.4,14.6,4.3-33.3,48.8-43.2c7-3.4,1.6-13.5,3.1-20c3.6-22.1,13.7-42.8,27.6-58.5c13.3-16.5,33.9-22.1,52.7-23
  c11.1-1.9,26.1,7.2,34.8-2.3c8.7-20.4,26.7-30.8,44.3-38.5v2.3c13.5-6.2,25.8,2.2,37.5,8.6c10.4,4.3,16.6,13.4,22.3,22.7
  c5,7.5,25.8,0.7,34.8,2c30.5,0.2,54.2,14.4,69.4,40.8c5.8,12.7,13.4,25.8,10.4,40.2c-0.8,9,11.5,6.4,16.3,11
  c15.6,6.9,24,15.7,28.7,32.4c0.4,1.5,1.1,2.7,2.9,3.5c12.3-9.8,25-20.5,41.7-19.5c24.4-7,46.5,6.4,66,19.4
  c5.6,2.8,17.7-4.9,24.5-5.8c13.4-1.4,27.7,0.9,39.8,6.9c13.5,3.5,20.9,15.9,26.5,27.6c0.8,2,1.8,2.1,3.7,1.2
  c18.3-7.7,34.9,10.2,48.2,20.5c5.8,5.3-5.8,16.9,4.9,19.4c24.4,1.6,41.5,22.3,49.2,43.9c12.5-8.4,27.5-9.3,41.9-6.4
  c12.6,1.4,24.3,5.3,33.9,13.8c6.8-8,15-18.4,26.5-18.1c1.9-30.9,28.6-51.3,58-53.8c16.2-2.4,31.5,4.7,47.1,7.7c3.6,0.8,5.8-3.2,9-4
  c14.1-6.6,29.3-4.2,43.5,0.9c11.3-33.8,47.7-59,83.3-48.1c8.4-15.1,10.4-16.3,26-22.4c11.5-6,24.4-7.4,37-4.8c17.7,0.6,9,12.3,26-4
  c7.2-7,30.4-1.6,36.8,4.6c4.1,3.1,9.7,4,12.2,9.2c3.8,2.5,19.1-12.2,26-12.7c20.8-2.1,5.1-8.1,13.2-35.4
  c7.7-20.2,26.3-33.5,41.6-47.7c21.6-8,45.9-10.4,69-8.4c10.1,1.3,18.5,7,28.6,9.9c5.1-17.3,26.5-16.4,41-13.4
  c8.3,1.4,12.6,9,18.4,14.3c10.4-6.6,21.8-10.9,34.1-8.6v0.5c17.6,6.7,35.5,15.6,44.3,33.2c8.7,8.1,23.7,0.3,34.8,2
  c40.6-1.4,73.2,31.7,80.3,70.1c1.5,5.6-3.9,14.2,3.1,17.2c44.5,8.5,36.4,49.7,48.8,37.2c24-22.6,64.2-23.6,90.7-4.4
  c16,14.8,24.9,3.1,42.5,1.3c25.8,0.3,52.3,10.3,61.5,36.3c6.8-5.1,17.1-4.9,23.9-0.3c9.3,12.4,19.3-8.3,31.9-5.5
  c5.9-38.1,65.1-39.9,75.6-3.7c10.2-9.8,24.3-19,39.1-13.9c3.9,0.3,8,6.3,10.7,2.8c6.5-6.6,16.7-8.7,25.2-4.7
  c11.9-5.9,26.2-6.6,38.3-1c15.5-0.8,32-7.6,48-2c11.5,0,24.1-4.5,36-1.6c14.5,4.2,30.6,14.8,35,29.8c0.4,2.5,0.4,2.6,3,3.1
  c10.3,1.9,15.6,12.6,19.4,21.4c54.8-12.2,35.2,8.2,66.6,4.1c12.3-4.5,23.4,6.6,34.6,5.1c21-27.3,69.1-13.4,75,18.2
  c11.6,10,21.5-20.4,53.1-5.5c21.6,6.9,23.7-18.4,58,0.2c7.9-2.8,16.3-3.9,24.4-3.3c-0.4,0-0.8,0-1.2,0c0.9,1,3.6,1.4,5.4,0.8
  c-0.4-0.3-0.8-0.4-1.3-0.6c14.9,1.9,28.6,9.9,36.2,24.6c7,9.3,18.9,16.1,24.5,28c5.3,8,26.5-12.3,55.4,3.3c11,12,27.1,2.1,40.3,9.4
  L2560,1200.1z M-790.9,1166.8c-0.1-0.2-0.2-0.5-0.3-0.5c-0.2,0-0.4,0.2-0.6,0.3C-791.5,1167.2-791.2,1166.9-790.9,1166.8z
  M2071.7,1171.3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.2,0.3-0.3,0.4C2071.2,1171.6,2071.5,1171.8,2071.7,1171.3z M-1106.6,1114.3
  c1-0.1,1.9-0.2,2.9-0.3C-1104.7,1114-1105.6,1114-1106.6,1114.3z`,
})`
  fill: ${theme.colors.white};
`;

export const BottomRightDot = styled(ColoredPath).attrs({
  d: `M919.4,754c1.6-15,21.3-7.5,17.3,5.5c0.4,6.5-8.4,3.6-12.2,5.4C920.9,762.6,919.5,758.3,919.4,754z`,
})``;
export const BottomDot = styled(ColoredPath).attrs({
  d: `M650,893.3c1.6-15,21.3-7.5,17.3,5.5c0.3,6.5-8.4,3.6-12.2,5.4C651.4,902,650,897.7,650,893.3z`,
})``;
export const BottomRing = styled(ColoredPath).attrs({
  d: `M711.1,941.8c-1.1-10,9.2-17.9,18.2-16.7c8.9,1.2,15.6,6.1,15,18.7
	c-0.3,7.7-8.2,14.8-18.2,14.1C718.2,957.4,710.8,949.7,711.1,941.8z M726.6,932.7c-4.7,0.1-8.7,4-8.5,8.3c0.2,4.8,5.3,10.3,9.5,10
	c4.1-0.3,9.9-4.6,9.6-10.3C736.9,936.4,732.1,932.6,726.6,932.7z`,
})``;
export const BottomLeftRing = styled(ColoredPath).attrs({
  d: `M400.4,759.1c6.6-10,24.1-0.1,14.9,11.2C408.1,781,391.9,768.1,400.4,759.1zM411.9,770.8c9.6-10-3.3-20.2-10.2-10.2C398.4,768,404.5,775.6,411.9,770.8z`,
})``;
export const BottomLeftBlob = styled(ColoredPath).attrs({
  d: `M84.6,684.2c1.2,2,0.2-1.4,1.2,0.3c1.2-1.1,0.9,1,1.3,1.7c1.1-0.2,2.7,1.5,1.5,2.4c-2.2,0.2,0.1,4.3-2.7,2.9c-3.1-0.9-3.3,3.4-5.2-2.1C78.1,686.8,83.6,685.6,84.6,684.2z`,
})``;
export const BottomBlob = styled(ColoredPath).attrs({
  d: `M953.9,804.8c-2.1-1.1-6.2-2.7-6.7-5.3c-2-2.6-1-1.3-3.6,0c-0.9,2.5-2,5.3-5.4,5
	c-1.5,0.1,0.2,1.5-0.4,1.8c-1,0-1,1-0.2,1.3c7.4,4-1.7,10.3,5.8,6c2.3-2.1,4.2,1.5,6.6,1.1c0.9-0.5,1.3-1.9,0.6-2.8
	c1-0.5,2.2-0.9,1.3-2.2c2.8,0,1.4-4.1,3.3-2.7c0.1-0.7-0.5-1.4,0.3-2C954.9,804.9,954.4,804.8,953.9,804.8z`,
})``;
export const BottomRightBlob = styled(ColoredPath).attrs({
  d: `M989.9,702.5c-1.7,4.5,4.8-1.3,6.3,2.3c4.3,1.1,2.2-3.9,1.4-6.3c-0.9-1.4,2.4-4.6-1.1-3.8c-1.7,1.1-2.2,1.4-2.9-0.7c-1.6,0.4-0.7,1.3-0.9,2.4C989,695.5,985.9,700.6,989.9,702.5z`,
})``;
export const BottomRightBlob2 = styled(ColoredPath).attrs({
  d: `M1142.8,664.9c0.7,3.5-0.8,2.5-3,1.8c-2.6,2.1-4.6-1.3-3.4-3.6c3.1,1.2,2.6-4.6,4.9-2.9C1141.7,662.2,1146,663.8,1142.8,664.9z`,
})``;
export const RightDot1 = styled(ColoredPath).attrs({
  d: `M1225.3,353.8c-1.1,2.4-4.9,10.5-9.5,3.4C1213.7,349.7,1223.7,345.2,1225.3,353.8zM1220,357.6c1.3-0.4,2.8-2.5,1.1-3.4C1219.8,353.3,1217.6,357.8,1220,357.6z`,
})``;
export const RightDot2 = styled(ColoredPath).attrs({
  d: `M1208.8,632.8c-6.6-0.1-5.9-10.2-0.9-12C1219.2,616.6,1217.9,631.4,1208.8,632.8zM1207.6,627.1c0.9,5.9,7.6-3.4,2.8-2.6C1209.3,625.3,1207.5,625.5,1207.6,627.1z`,
})``;
export const RightRing1 = styled(ColoredPath).attrs({
  d: `M1252.4,481.8c0,7.5-4.2,12.6-10.4,12.7c-9.6,0.1-12.6-4.8-12.9-12
	c-0.2-6.4,5.7-11.8,12.5-12C1246.6,470.3,1253.6,476.5,1252.4,481.8z M1240.7,478.2c-2.4,0-4.4,1.9-4.5,4.4
	c-0.1,2.4,1.8,4.6,4.1,4.6c2.5,0.1,5-1.8,5.1-3.8C1245.6,481.2,1243,478.3,1240.7,478.2z`,
})``;
export const RightRing2 = styled(ColoredPath).attrs({
  d: `M1096,699.6c-4.3,44-76.6,35.5-67.2-11.3C1027.8,642,1101.4,657.3,1096,699.6z M1061.2,718.6c80.1-16-48.5-96.7-21.5-9.2C1042.9,717.6,1054.5,715.1,1061.2,718.6z`,
})``;
export const RightRing3 = styled(ColoredPath).attrs({
  d: `M998.7,752c9.7,0.2,14.1,5.4,14.3,12.4c0.2,6.8-5.4,14.5-13.1,14.2
	c-7.7-0.3-13.8-5.2-13.9-12.9C985.9,758.1,990.6,752.4,998.7,752z M1000.5,771.3c2.2,0.1,5.3-3.5,5.4-6.2c0.1-2.3-3.4-5.8-5.8-6
	c-3.1-0.2-6.3,2.9-6.5,6.3C993.3,768.8,996.1,771.1,1000.5,771.3z`,
})``;
export const RightBlob = styled(ColoredPath).attrs({
  d: `M1253.1,396c2,0.2,1.8,1.6,2,3c-0.1,3.6,6.9-1.2,5.1,3.2c-3,2.3-2.6,4.1,0.1,6.5
	c2.4,2.7-1.7,5.1-3.6,2.7c-2.1-3.5-4.7,1.2-7.3,0.5c-0.1-2.7-0.7-4.8-3.1-6.6C1244.3,398.9,1254,406.1,1253.1,396z M1254.1,403.9
	c-0.4-0.8-1.5,0.7-0.7,0.8C1253.7,404.5,1253.9,404.2,1254.1,403.9z`,
})``;
export const CenterDot = styled(ColoredPath).attrs({
  d: `M367.6,586.8c-0.6-3.5,5.4-6.3,6.5-2.4C376.5,590.2,367.6,593.7,367.6,586.8z M372.6,587c-0.2-3.4-3-1.6-3,0.8C370.4,589.8,372.3,588.7,372.6,587z`,
})``;
export const TopRightPlus1 = styled(ColoredPath).attrs({
  d: `M1223.7,61.9c0,1.1,0,2.2,0,3.2c0,0.7-0.3,1-0.9,1.1c-0.7,0.1-1-0.3-1.1-1.1
	c-0.1-1.9-0.1-3.7-0.2-5.6c-0.1-2.4,0-2-2-2.2c-0.9-0.1-1.9-0.1-2.9-0.2c-0.9-0.1-1.3-1.1-0.8-1.8c0.2-0.2,0.6-0.4,0.8-0.4
	c1.3,0,2.6,0.1,3.9,0.2c0.7,0,1-0.3,1-0.9c0-1.4,0-2.9,0.1-4.3c0-0.9,0-1.8,0.1-2.7c0.1-0.8,0.4-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.2
	c0,1.5,0,3-0.1,4.5c0,0.9,0,1.8,0,2.6c0,0.5,0.2,0.8,0.8,0.8c1.1,0,2.3,0,3.4,0c0.9,0,1.3,0.2,1.3,0.8c0,0.8-0.4,1.3-1.3,1.3
	c-1.1,0-2.2-0.1-3.2-0.1c-1,0-1.2,0.3-1.2,1.3c0,1.1,0,2.2,0,3.2C1223.6,61.9,1223.7,61.9,1223.7,61.9z`,
})``;
export const TopRightPlus2 = styled(ColoredPath).attrs({
  d: `M1166.2,81.5c0,1.9-0.1,3.8,0,5.6c0,1.2-0.5,1.8-1.6,1.9c-1.1,0.1-1.8-0.5-1.9-1.8
	c-0.1-3.2-0.2-6.5-0.3-9.7c-0.2-4.2,0-3.5-3.5-3.8c-1.7-0.1-3.3-0.2-5-0.4c-1.5-0.2-2.3-1.9-1.3-3c0.3-0.4,0.9-0.7,1.4-0.7
	c2.3,0.1,4.5,0.2,6.8,0.4c1.2,0.1,1.7-0.6,1.7-1.6c0.1-2.5,0.1-5,0.1-7.5c0-1.6,0-3.1,0.1-4.7c0.1-1.4,0.7-2,1.8-1.9
	s1.8,0.8,1.8,2.1c0,2.6-0.1,5.2-0.1,7.7c0,1.5,0,3,0,4.6c0,1,0.4,1.5,1.4,1.4c2,0,3.9,0,5.9,0c1.6,0,2.2,0.3,2.3,1.5
	c0.1,1.3-0.7,2.2-2.2,2.2c-1.9,0-3.8-0.1-5.6-0.1c-1.7,0-2.2,0.5-2.1,2.2c0,1.9,0,3.8,0,5.6C1166,81.5,1166.1,81.5,1166.2,81.5z`,
})``;
export const TopRightPlus3 = styled(ColoredPath).attrs({
  d: `M1210.1,128.7c0,1.1,0,2.2,0,3.2c0,0.7-0.3,1-0.9,1.1c-0.7,0-1-0.3-1.1-1.1
	c-0.1-1.9-0.1-3.7-0.2-5.6c-0.1-2.4,0-2-2-2.2c-0.9-0.1-1.9-0.1-2.9-0.2c-0.9-0.1-1.3-1.1-0.8-1.8c0.2-0.2,0.6-0.4,0.8-0.4
	c1.3,0,2.6,0.1,3.9,0.2c0.7,0.1,1-0.3,1-0.9c0-1.4,0-2.9,0.1-4.3c0-0.9,0-1.8,0.1-2.7c0-0.8,0.4-1.1,1-1.1s1.1,0.5,1.1,1.2
	c0,1.5,0,3-0.1,4.5c0,0.9,0,1.8,0,2.6c0,0.5,0.2,0.8,0.8,0.8c1.1,0,2.3,0,3.4,0c0.9,0,1.3,0.2,1.3,0.8c0,0.8-0.4,1.3-1.3,1.3
	c-1.1,0-2.2-0.1-3.2-0.1c-1,0-1.2,0.3-1.2,1.3c0,1.1,0,2.2,0,3.2C1210,128.7,1210,128.7,1210.1,128.7z`,
})``;
export const TopRightPlus4 = styled(ColoredPath).attrs({
  d: `M1168.8,160.7c0,1.6,0,3.1,0,4.7c0,1-0.4,1.5-1.4,1.6c-1,0.1-1.5-0.4-1.6-1.5
	c-0.1-2.7-0.2-5.4-0.3-8.1c-0.1-3.5,0-3-3-3.2c-1.4-0.1-2.8-0.1-4.2-0.3c-1.3-0.2-1.9-1.6-1.1-2.5c0.3-0.3,0.8-0.6,1.2-0.5
	c1.9,0,3.8,0.2,5.7,0.3c1,0.1,1.4-0.5,1.4-1.4c0-2.1,0.1-4.2,0.1-6.2c0-1.3,0-2.6,0.1-3.9c0.1-1.2,0.6-1.6,1.5-1.6
	c0.9,0,1.5,0.7,1.5,1.8c0,2.2-0.1,4.3-0.1,6.5c0,1.3,0,2.6,0,3.8c0,0.8,0.3,1.2,1.2,1.2c1.7,0,3.3,0,5,0c1.3,0,1.9,0.3,1.9,1.2
	c0.1,1.1-0.6,1.8-1.9,1.8c-1.6,0-3.2-0.1-4.7-0.1c-1.4,0-1.8,0.5-1.8,1.8c0,1.6,0,3.1,0,4.7C1168.7,160.7,1168.8,160.7,1168.8,160.7z`,
})``;
export const TopRightPlus5 = styled(ColoredPath).attrs({
  d: `M1195.8,194.7c0,1.6,0,3.1,0,4.7c0,1-0.4,1.5-1.4,1.6c-1,0.1-1.5-0.4-1.6-1.5
	c-0.1-2.7-0.2-5.4-0.3-8.1c-0.1-3.5,0-3-3-3.2c-1.4-0.1-2.8-0.1-4.2-0.3c-1.3-0.2-1.9-1.6-1.1-2.5c0.3-0.3,0.8-0.6,1.2-0.5
	c1.9,0,3.8,0.2,5.7,0.3c1,0.1,1.4-0.5,1.4-1.4c0-2.1,0.1-4.2,0.1-6.2c0-1.3,0-2.6,0.1-3.9c0.1-1.2,0.6-1.6,1.5-1.6
	c0.9,0,1.5,0.7,1.5,1.8c0,2.2-0.1,4.3-0.1,6.5c0,1.3,0,2.6,0,3.8c0,0.8,0.3,1.2,1.2,1.2c1.7,0,3.3,0,5,0c1.3,0,1.9,0.3,1.9,1.2
	c0.1,1.1-0.6,1.8-1.9,1.8c-1.6,0-3.2-0.1-4.7-0.1c-1.4,0-1.8,0.5-1.8,1.8c0,1.6,0,3.1,0,4.7C1195.7,194.7,1195.8,194.7,1195.8,194.7z`,
})``;

export const TopLeftBlob = styled(ColoredPath).attrs({
  d: `M37.6,280.4c2.9-0.2,4.2-9.1,4.8,0.3c2.8,1.4,3.1,2.4,0,4.1C40.2,288,32.3,281,37.6,280.4z`,
})``;
export const LeftCenterDot = styled(ColoredPath).attrs({
  d: `M240.6,582.6c-0.7,1.3-1.5,3.2-3.2,2.2C235.1,583.4,240.1,578.4,240.6,582.6z`,
})``;
export const LeftRing = styled(ColoredPath).attrs({
  d: `M58.4,487.9c11.5-0.2,19.5,9.7,19.1,20c-0.3,7.4-8.3,16.9-18.1,16.1
	c-10.4-0.8-18.1-7.6-17.6-18.6C42.2,495.5,49.7,487.6,58.4,487.9z M59.7,518.3c6.4,0.1,12-5.5,12.1-11.2C71.9,500,66,494,59,493.9
	c-5.1-0.1-11.5,7.3-11.9,13.1C46.8,512.6,54.7,518.4,59.7,518.3z`,
})``;
export const LeftBlob = styled(ColoredPath).attrs({
  d: `M61.8,390.3c4.8-1,0,3.4-0.2,5.4c-0.9,1.3-2.3-1.1-3.7-0.1c-1.6,0.3-1.4-2-2.2-2.7c-2.1-3.9,2.6-0.6,2.6-3C59.4,386,60.3,390.3,61.8,390.3z`,
})``;
export const LeftDot2 = styled(ColoredPath).attrs({
  d: `M141.7,522c-2.8,0.3,1,2.5-0.6,3.7C132.1,531.4,133.1,515.1,141.7,522z M139.1,525c1.7-1.1-0.6-2.7-1.6-1.5C136.6,524.6,138,525.1,139.1,525z`,
})``;
export const LeftDot1 = styled(ColoredPath).attrs({
  d: `M31.9,397.4c-5.3,0.3-6.7-6.9-2.1-9.1C34.6,386.2,37.1,396.9,31.9,397.4z`,
})``;
export const LeftPlus1 = styled(ColoredPath).attrs({
  d: `M52.7,613.3c0,1.1,0,2.2,0,3.2c0,0.7-0.3,1-0.9,1.1c-0.7,0-1-0.3-1.1-1.1
	c-0.1-1.9-0.1-3.7-0.2-5.6c-0.1-2.4,0-2-2-2.2c-0.9-0.1-1.9-0.1-2.9-0.2c-0.9-0.1-1.3-1.1-0.8-1.8c0.2-0.2,0.5-0.4,0.8-0.4
	c1.3,0,2.6,0.1,3.9,0.2c0.7,0,1-0.3,1-0.9c0-1.4,0-2.9,0.1-4.3c0-0.9,0-1.8,0.1-2.7c0.1-0.8,0.4-1.1,1-1.1c0.6,0,1,0.5,1,1.2
	c0,1.5,0,3-0.1,4.5c0,0.9,0,1.8,0,2.6c0,0.6,0.2,0.8,0.8,0.8c1.1,0,2.3,0,3.4,0c0.9,0,1.3,0.2,1.3,0.8c0,0.8-0.4,1.3-1.3,1.3
	c-1.1,0-2.2-0.1-3.2-0.1c-1,0-1.2,0.3-1.2,1.3c0,1.1,0,2.2,0,3.2C52.6,613.3,52.6,613.3,52.7,613.3z`,
})``;
export const LeftPlus2 = styled(ColoredPath).attrs({
  d: `M-4.9,632.9c0,1.9,0,3.8,0,5.6c0,1.2-0.5,1.8-1.6,1.9c-1.1,0.1-1.8-0.5-1.9-1.8
	c-0.1-3.2-0.2-6.5-0.3-9.7c-0.2-4.2,0-3.5-3.5-3.8c-1.7-0.1-3.3-0.2-5-0.4c-1.5-0.2-2.3-1.9-1.3-3c0.3-0.4,0.9-0.7,1.4-0.7
	c2.3,0.1,4.5,0.2,6.8,0.4c1.2,0.1,1.7-0.6,1.7-1.6c0.1-2.5,0.1-5,0.1-7.5c0-1.6,0-3.2,0.1-4.7c0.1-1.4,0.7-2,1.8-1.9
	s1.8,0.8,1.8,2.1c0,2.6-0.1,5.1-0.1,7.7c0,1.5,0,3,0,4.6c0,1,0.4,1.5,1.4,1.4c2,0,3.9,0,5.9,0c1.6,0,2.2,0.3,2.3,1.5
	c0.1,1.3-0.7,2.2-2.2,2.2c-1.9,0-3.8-0.1-5.6-0.1c-1.7,0-2.2,0.5-2.1,2.2c0,1.9,0,3.8,0,5.6C-5,632.9-5,632.9-4.9,632.9z`,
})``;
export const LeftPlus3 = styled(ColoredPath).attrs({
  d: `M39,680.1c0,1.1,0,2.2,0,3.2c0,0.7-0.3,1-0.9,1.1c-0.7,0-1-0.3-1.1-1.1
	c-0.1-1.9-0.1-3.7-0.2-5.6c-0.1-2.4,0-2-2-2.2c-1-0.1-1.9-0.1-2.9-0.2c-0.9-0.1-1.3-1.1-0.8-1.8c0.2-0.2,0.5-0.4,0.8-0.4
	c1.3,0,2.6,0.1,3.9,0.2c0.7,0,1-0.3,1-0.9c0-1.4,0-2.9,0.1-4.3c0-0.9,0-1.8,0.1-2.7c0.1-0.8,0.4-1.1,1.1-1.1c0.6,0,1,0.5,1,1.2
	c0,1.5,0,3-0.1,4.5c0,0.9,0,1.8,0,2.6c0,0.6,0.2,0.8,0.8,0.8c1.1,0,2.3,0,3.4,0c0.9,0,1.3,0.2,1.3,0.8c0,0.8-0.4,1.3-1.3,1.3
	c-1.1,0-2.2-0.1-3.2-0.1c-1,0-1.2,0.3-1.2,1.3c0,1.1,0,2.2,0,3.2C38.9,680.1,39,680.1,39,680.1z`,
})``;
export const TopBlob = styled(ColoredPath).attrs({
  d: `M621.5,55.2c-3-10.7-6.5,2.9-9.1-0.9c-0.7-1.6-1.3,0.5-1.1,1.2c-0.7,0.2-1.3,0.5-1.3,1.3
	c4.7,2.1,0.5,6,2.6,5.1c3.6-3.2,4.8,4.9,9.3,4.1c-0.1-1.1-0.8-2.4-0.3-3.6c0.2-2-3.4-3.2-0.7-5.2C622.3,59.3,623.1,56.1,621.5,55.2z`,
})``;
export const TopRing = styled(ColoredPath).attrs({
  d: `M713.7,72c9.7,0.2,14.1,5.4,14.3,12.4c0.2,6.8-5.4,14.5-13.1,14.2c-7.7-0.3-13.8-5.2-13.9-12.9
	C700.9,78.1,705.6,72.4,713.7,72z M715.5,91.3c2.2,0.1,5.3-3.4,5.4-6.1c0.1-2.3-3.4-5.8-5.8-6c-3.1-0.2-6.3,2.9-6.5,6.3
	C708.3,88.8,711.1,91.1,715.5,91.3z`,
})``;
export const PlanetGroup = styled.g``;
export const PlanetRing = styled(ColoredPath).attrs({
  d: `M80.1,53.9c-0.7,32.2-15,67.4-47.6,78.4c-54.1,14.6-116.7-6.1-117-70.4
	c-3.5-25.1,9.6-45.3,28.1-60.3c9.5-16.3,26.4-20.2,43.9-21.6C29.5-27.2,82.8,8.4,80.1,53.9z M25.6,130.8c41.2-8.6,57.6-63,46.7-99.9
	C65.2,4.5,37.1-6,14.5-16.4C0.3-22.8-15-15.6-29.8-14.5C-45-12-50.6,5.4-62.5,13.4c-24.9,18.5-18.4,55.3-10.6,81.1
	c13.5,25.4,45.9,41,74.1,38C6.2,134.6,31.8,121.4,25.6,130.8z`,
})``;
export const Planet = styled(ColoredPath).attrs({
  d: `M-76.9,45.1C-70,15-37.6-12.4-6.6-15.7c41.6,4.9,82.9,36.5,75.3,82.4
  c-8.5,42.6-51.7,70.4-94.5,60.1C-60,116.2-77.5,77.7-76.9,45.1z M9.7,8.5c4.4-2.4,7.5-2.3,12.1-0.1C15.2-1-7.9-6.6,9.7,8.5z
  M-11.8,91c2.9-1,0.9-3,0.1-5.3C-17.6,73.9-17,85.2-11.8,91z M-29.8,99.4c3,2.8,6.2,5.2,10.2,6.5C-22.2,102.7-25.7,100.1-29.8,99.4z
  M28,75.8c0.3-3.2,1.9-10.7,0.7-11.7C28.4,67.5,25.2,73.2,28,75.8z M7.7,112.1c0.9,2.7,3.1,3.8,6.4,2.3
  C12.2,112.8,10.4,110.8,7.7,112.1z M-29.9,43.4c-2.2-1.1-2.6,0-3.4,1.7C-33.8,48.6-31,44.5-29.9,43.4z M-9.8,4.2
  c1,1.5,4.4,2.5,6.1,4C-4.8,5.7-7.3,4.6-9.8,4.2z M-34.4,95.4c1.6,1.1,3.2,4.5,4.4,1.5C-31.5,96.5-32.9,95.4-34.4,95.4z M33,38.1
  c0.9-0.6,1.7-4.3-0.8-3.3C32.5,35.9,32.7,37,33,38.1z M-29.1,75.2c0.1,1.8,0.8,3.9,1.6,4.7C-27.9,78.1-28.3,76.6-29.1,75.2z
  M-6.1,12.1c-0.9-1.6-1.2-1.8-2.1-1.7C-8,11.4-7.2,11.7-6.1,12.1z M-21.1,4.2c-0.7,0.4-1.2,1-1.9,1.8C-22.1,5.4-20.1,5.4-21.1,4.2z
  M-26,83c-0.6-1.2-0.5-3.3-1.4-3.6C-27.2,80.6-27.2,81.8-26,83z M-59.5,56.5c-0.2,1.4-0.2,1.6,0.5,2.5
  C-59.2,58.1-58.8,57.3-59.5,56.5z M30.9,41.5c0.6,0.5,0.8,1.5,1.4,1.2C32.5,41.4,32.4,41.1,30.9,41.5z M-9.1-0.1
  c-0.2,0.6,1,0.5,1.6,0.8C-7.8-0.5-8.6,0-9.1-0.1z M-58.8,62.5c0.6-0.1,0.4-1.2-0.3-1.8C-59,61.5-58.9,62-58.8,62.5z M-59.2,59.9
  c0.2,0.3,0.4,0.2,0.5,0C-59,59.6-59.1,59.5-59.2,59.9z M-9.1,94.7c0.7,0.1,0.8-0.1,0.8-0.7C-8.7,94.3-8.8,94.5-9.1,94.7z`,
})``;
