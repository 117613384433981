import React from 'react';
import { Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { ACTIONS } from './reducer';

const DAYS = [
  { value: 'Sunday', label: 'S' },
  { value: 'Monday', label: 'M' },
  { value: 'Tuesday', label: 'T' },
  { value: 'Wednesday', label: 'W' },
  { value: 'Thursday', label: 'T' },
  { value: 'Friday', label: 'F' },
  { value: 'Saturday', label: 'S' }
];

const WeekdaySelectorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingLeft: 0
}));

const Day = styled(Box)(({ theme, isActive }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '100%',
  width: '42px',
  height: '42px',
  backgroundColor: isActive ? theme.palette.blues[50] : theme.palette.blacks[5],
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(1),
  p: {
    margin: '0 auto'
  }
}));

export default function WeekdaySelector({ state, dispatch }) {
  const { frequencyConfig, frequency } = state;
  const { sendOnDays = [], repeatValue } = frequencyConfig;

  if (!['daily', 'weekly'].includes(frequency)) {
    return null;
  }

  let occurence = 'Occurs every';

  if (repeatValue === 2) {
    occurence += ' other';
  } else if (repeatValue > 2) {
    occurence += ` ${repeatValue} weeks on`;
  }

  if (sendOnDays?.length === 1) {
    occurence += ` ${sendOnDays[0]}`;
  } else if (sendOnDays?.length > 1) {
    const ordered = DAYS.filter(({ value }) => sendOnDays.indexOf(value) > -1).map(({ value }) => value);
    const lastDay = ordered[ordered.length - 1];

    occurence += ` ${[...ordered].slice(0, ordered.length - 1).join(', ')}, and ${lastDay}`;
  }
  
  return (
    <>
      <WeekdaySelectorContainer>
        {DAYS.map(({ value, label }) => (
          <Day 
            key={value} 
            isActive={sendOnDays.includes(value)}
            onClick={() => {
              const isActive = sendOnDays.includes(value);
              let selectedDates = [...sendOnDays];

              if (selectedDates.length === 1 && isActive) {
                return;
              }
              
              if (isActive) {
                selectedDates = selectedDates.filter(day => day !== value);
              } else {
                selectedDates.push(value);
              }

              dispatch({ type: ACTIONS.UPDATE_SEND_ON_DATES, payload: selectedDates.sort() });
            }}
          >
            <Typography 
              variant="body1" 
              color={sendOnDays.includes(value) ? '#FFF' : 'blacks.80'}
            >
              {label}
            </Typography>
          </Day>
        ))}
      </WeekdaySelectorContainer>
      <Typography variant='body1'>{occurence}.</Typography>
    </>
  );
}
