import gsap from 'gsap';
import Animation from '../../Animations/Animation';

export default class KudosAnimation extends Animation {
  onAnimationMount() {
    gsap.config({
      nullTargetWarn: false,
    });
  }

  onAnimationUnmount = () => {
    if (this.tl && this.tl.kill) {
      // If animation is currently playing, pause it and reset animation.
      this.tl.kill();
    }
  };

  getElement = (elementSelector, omitBaseClass) => {
    const { selector } = this.props;
    const selectorClass = selector ? `.${selector} ` : '';
    return omitBaseClass
      ? `${selectorClass} .${elementSelector}`
      : `${selectorClass}.${this.baseClass} .${elementSelector}`;
  };

  getDefaultTimeline = (otherDefaults = {}, otherProps = {}) =>
    gsap.timeline({
      defaults: {
        duration: 0,
        transformOrigin: '50% 50%',
        ease: 'none',
        ...otherDefaults,
      },
      ...otherProps,
    });

  getBaseTimeline = (initialize) =>
    this.getDefaultTimeline({}, {
      onStart: () => {
        if (!initialize) {
          const tl = gsap.timeline();
          tl.to(this.getElement(this.baseClass, true), { opacity: 1 }, '<');
        }
      },
      onComplete: () => {
        if (!initialize) {
          const tl = gsap.timeline();
          tl.to(this.getElement(this.baseClass, true), { opacity: 0.7 }, '<');
        }
      },
    });

  resetAnimation = () => {
    return this.playAnimation({ playAnimation: true });
  };
}
