import styled from 'styled-components';
import Button from './Default';
import { BUTTON_GROUP_ROLE } from '../Group';
import theme, { createDataRole } from '@matterapp/matter-theme';

const getColorStyles = ({ color, disabled, useGrayscaleDisabled }) => {
  const baseColor = theme.colors[`${color}s`] || theme.colors.greens;
  const groupStyles = `
    ${createDataRole(BUTTON_GROUP_ROLE)} > & {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  `;

  if (!disabled) {
    return `
      color: ${theme.colors.white};
      background: ${baseColor[50]};
      ${groupStyles}
      &:hover {
        background: ${baseColor[40]};
      }
      &:active {
        color: ${theme.colors.white};
        background: ${baseColor[60]};
        border-color: transparent;
      }
    `;
  }
  return `
    background: ${useGrayscaleDisabled ? theme.colors.blacks[5] : baseColor[10]};
    color: ${useGrayscaleDisabled ?  theme.colors.blacks[40] :  theme.colors.white};
    ${groupStyles}
  `;
};

const PrimaryButton = styled(Button).attrs(({ color }) => ({
  color: color || Button.buttonColors.BLUE,
}))`
  &:not(:active) {
    border-color: transparent;
  }
  ${getColorStyles}
`;

export default PrimaryButton;
