import { css } from 'styled-components';
import theme from '@matterapp/matter-theme';
import { TEXT_AREA_CONTAINER_SELECTOR } from './consts';
import { InnerWrapper, Wrapper } from './styledComponents';

const backgroundColor = theme.colors.blacks[5];
const backgroundColorError = theme.colors.reds[5];
const borderColor = theme.colors.blacks[10];
const borderRadius = theme.borderRadius.M;
const errorColor = theme.colors.reds[50];
const transitionTime = theme.transitions.times.default;

export const getTextAreaContainerStyles = ({ isError }) => `
  background: ${isError ? backgroundColorError : backgroundColor};
  border: 2px solid ${borderColor};
  border-radius: ${borderRadius};
  border-color: ${isError ? errorColor : borderColor};
  transition: background ${transitionTime};
  &:focus-within {
    background: ${theme.colors.white};
    border-color: ${borderColor};
  }
`;

export const getTextAreaStyles = ({ resize }) => `
  background: transparent;
  border: none;
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  min-height: 100px;
  padding: ${theme.spacing.single} ${theme.spacing.single} ${theme.spacing.double};
  resize: ${resize};
`;

export const getMediumScreenInputStyles = () => theme.media.medium`
  font-size: ${theme.sizes.fontSizes[50]};
  line-height: ${theme.sizes.lineHeights[50]};
`;

export const getCharCountStyles = ({ autoHeight, isError }) => {
  const mainStyles = `
    background: ${isError ? backgroundColorError : backgroundColor};
    position: absolute;
    bottom: 0;
    left: 0;
    right: ${theme.spacing.single};
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: ${theme.spacing.half} ${theme.spacing.single};
    font-size: ${theme.spacing.single} !important;
    transition: background ${transitionTime};
  `;
  const focusStyles = `
    background: ${theme.colors.white} !important;
  `;

  if (autoHeight) {
    return css`
      ${mainStyles}
      ${InnerWrapper} ${TEXT_AREA_CONTAINER_SELECTOR}:focus-within + & {
        ${focusStyles}
      }
    }
    `;
  }
  return css`
    ${mainStyles}
    ${InnerWrapper} textarea:focus + & {
      ${focusStyles}
    }
  `;
};

export const getCharCountContainerStyles = ({
  isCountZero,
  isTooShort,
  isTooLong,
}) => `
  line-height: 1;
  text-align: left;
  font-size: ${theme.spacing.single};
  transition: all ${transitionTime}

  ${(isTooShort || isTooLong) &&
    `
      color: ${isCountZero ? theme.colors.blacks[50] : errorColor};
  `}
`;

export const getCharCountTextStyles = ({ isTooLong }) => `
  ${`color: ${isTooLong ? errorColor : theme.colors.blacks[50]};`}
`;

export const getChildrenContainerStyles = ({ isError }) => `
  background: ${isError ? backgroundColorError : backgroundColor};
  border: 0;
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
  transition: background ${transitionTime};

  &:before {
    background: ${isError ? errorColor : borderColor};
    content: '';
    display: block;
    height: 2px;
  }

  &:empty {
    &:before {
      height: 0px;
    }
  }

  ${Wrapper} [data-role="container"] > div:focus-within + & {
    background: ${theme.colors.white};
    &:before {
      background: ${borderColor};
    }
  }
`;
