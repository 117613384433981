import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Step from './Step';
import StepHeader from './StepHeader';
import SwipeContainer from '../SwipeContainer';
import theme from '@matterapp/matter-theme';

const DEFAULT_UP_NEXT_LABEL = 'Up Next:';

const Container = styled.div``;
const PrevHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  ${theme.media.S`
    position: relative;
  `}
`;
const NextHeaderContainer = styled.div`
  overflow: hidden;
`;
const ContentContainer = styled.div`
  position: relative;
`;

const UpNextContainer = styled.div`
  color: ${theme.colors.blacks[30]};
  font-size: CALC(${theme.fontSize.L} - ${theme.spacing.quarter});
  font-weight: ${theme.fontWeight.regular}
  padding: ${theme.spacing.threeQuarters} 0;
  user-select: none;
  ${theme.media.S`
    font-size: ${theme.fontSize.L}
  `}
`;

export default class SteppedTimeline extends React.PureComponent {
  static propTypes = {
    /**
     * Content of the timeline. <SteppedTimeline.Step> components will automatically
     * get assigned a step number in the order passed in and the <SteppedTimeline> manages
     * the currently visible state while providing callback renderProps from the
     * `getNavigationButtons` to go between enabled state.
     */
    children: PropTypes.node,
    /** The container to put the contents in. */
    ContainerComponent: PropTypes.elementType,
    /** The current (enabled) step of the timeline. Component keeps track of visible step. */
    currentStep: PropTypes.number,
    /**
     * Function to get the navigation buttons.
     * @param { Object } renderProps: {
     *   @param { Boolean } isCurrentStep: If current step is currently open.
     *   @param { Boolean } isFirstStep: If current step is first step.
     *   @param { Boolean } isLastStep: If current step is last step.
     *   @param { Function } onClickNext: Callback to go to next step.
     *   @param { Function } onClickPrev: Callback to go to previous step.
     *   @param { Number } totalNumberOfSteps: Total number of steps in the timeline.
     * }
     * @returns { React.Component } The nav buttons.
     */
    getNavigationButtons: PropTypes.func,
    /** Overrides internal state to mark all steps complete if true. */
    isComplete: PropTypes.bool,
    /** Number of spacer/blank headers to show before timeline. Used to create spacing to align content when changing steps. */
    numberOfSpacerHeadersBefore: PropTypes.number,
    /** Callback when a step is clicked. */
    onClickStep: PropTypes.func,
    /** Callback when visible step is changed. */
    onChangeVisibleStep: PropTypes.func,
    /** Duration of a swipe before detecting next swipe event. */
    swipeDuration: PropTypes.number,
    /** Duration of a touch swipe to trigger. `swipeDuration` is used if not passed in. */
    swipeDurationTouch: PropTypes.number,
    /** The offset amount needed to trigger swipe. */
    swipeOffset: PropTypes.number,
    /** The offset amount needed to trigger touch swipe. `swipeOffset` is used if not passed in. */
    swipeOffsetTouch: PropTypes.number,
    /** The 'Up Next' label. */
    upNextLabel: PropTypes.string,
    /** The current visible step of the timeline. If not passed in, internal state will be used. */
    visibleStep: PropTypes.number,
  };

  static defaultProps = {
    ContainerComponent: Container,
    getNavigationButtons: () => null,
    numberOfSpacerHeadersBefore: 0,
    onChangeVisibleStep: () => null,
    onClickStep: () => null,
    swipeDuration: 400,
    swipeDurationTouch: 400,
    swipeOffset: 100,
    swipeOffsetTouch: 200,
    upNextLabel: DEFAULT_UP_NEXT_LABEL,
  };

  static Step = Step;

  constructor(props) {
    super(props);
    const { currentStep, visibleStep } = props;
    const isInternalVisibleStep = !visibleStep;

    this.stepData = [];
    this.state = {
      isInternalVisibleStep,
      visibleStep: isInternalVisibleStep ? currentStep : visibleStep,
    };
  }

  componentDidUpdate = ({ currentStep }) => {
    if (currentStep !== this.props.currentStep) {
      const updatedState = { visibleStep: this.props.currentStep };
      this.props.onChangeVisibleStep({}, updatedState);
      this.setState(updatedState);
    }
  };

  /**
   * Calculates the number of chilren components that are <StepContainer>.
   * @returns The number of steps in the timeline.
   */
  getNumberTotalOfSteps = () => {
    let numberOfSteps = 0;
    React.Children.forEach(this.props.children, (child) => {
      if (this.isStepContainer(child)) {
        numberOfSteps++;
      }
    });
    return numberOfSteps;
  };

  /**
   * Gets the visible step.
   * @returns { Number } The visible step
   */
  getVisibleStep = () => {
    return this.state.isInternalVisibleStep
      ? this.state.visibleStep
      : this.props.visibleStep;
  };

  /**
   * Sets the visible step.
   * @param { Object } e: The change event.
   * @param { Number } visibleStep: The updated visible step.
   * @returns { void }
   */
  setVisibleStep = (e, visibleStep) => {
    if (this.state.isInternalVisibleStep) {
      this.setState({ visibleStep });
    } else {
      this.props.onChangeVisibleStep(e, {
        ...this.props,
        visibleStep,
      });
    }
  };

  /**
   * Callback to go to next step.
   * @returns { void }
   */
  handleNextStep = (e) => {
    const visibleStep = this.getVisibleStep();
    if (visibleStep + 1 <= this.props.currentStep) {
      this.setVisibleStep(e, visibleStep + 1);
    }
  };

  /**
   * Callback to go to previous step.
   * @returns { void }
   */
  handlePrevStep = (e) => {
    const visibleStep = this.getVisibleStep();
    if (visibleStep - 1 >= 1) {
      this.setVisibleStep(e, visibleStep - 1);
    }
  };

  /**
   * Callback when step title is clicked.
   */
  handleClickStep = (e, { stepNumber }) => {
    this.props.onClickStep(e, { ...this.props, stepNumber });
    this.setVisibleStep(e, stepNumber);
  };

  /**
   * Detemines if node is a StepContainer.
   * @param { React.Component } node: Node to check.
   * @returns { Boolean } If node is a StepContainer.
   */
  isStepContainer = (node) => {
    return node && node.type === Step;
  };

  renderNavigationButtons = ({ isOpen, stepNumber }) => {
    const { currentStep, getNavigationButtons } = this.props;
    const visibleStep = this.getVisibleStep();
    const totalNumberOfSteps = this.getNumberTotalOfSteps();

    return getNavigationButtons({
      isOpen,
      isCurrentStep: currentStep === visibleStep,
      isFirstStep: visibleStep === 1,
      isLastStep: visibleStep === totalNumberOfSteps,
      onClickPrev: this.handlePrevStep,
      onClickNext: this.handleNextStep,
      stepNumber,
      totalNumberOfSteps,
      visibleStep,
    });
  };

  renderSpacerHeaders = () => {
    const { numberOfSpacerHeadersBefore } = this.props;
    const headers = [];
    for (let i = numberOfSpacerHeadersBefore * -1; i < 0; i++) {
      headers.push(      <StepHeader
        key={`step-header-prev-${i}`}
        stepNumber={i + 1}
        visibleStep={this.getVisibleStep()}
        isPrevStep
      />);
    }
    return headers;
  }

  renderContent = () => {
    const { children, currentStep, isComplete, upNextLabel } = this.props;
    const visibleStep = this.getVisibleStep();
    const totalNumberOfSteps = this.getNumberTotalOfSteps();
    let stepIndex = 1;
    const prevStepHeaders = this.renderSpacerHeaders();
    const nextStepHeaders = [];

    const clonedChildren = React.Children.map(children, (child) => {
      if (this.isStepContainer(child)) {
        const { canClick, isCompletedStep, title } = child.props;
        const sharedProps = {
          canClick: stepIndex <= currentStep && canClick,
          isCompletedStep:
            isCompletedStep !== undefined
              ? isCompletedStep
              : stepIndex < visibleStep || isComplete,
          isLastStep: stepIndex === totalNumberOfSteps,
          isOpen: stepIndex === visibleStep,
          stepNumber: stepIndex,
          totalNumberOfSteps,
          visibleStep,
        };
        const stepProps = {
          ...sharedProps,
          isCurrentStep: stepIndex === currentStep,
          onClickHeader: this.handleClickStep,
          onClickPrev: this.handlePrevStep,
          onClickNext: this.handleNextStep,
          upNextLabel,
        };

        const headerProps = {
          ...sharedProps,
          onClick: this.handleClickStep,
          title,
        };
        prevStepHeaders.push(
          <StepHeader
            key={`step-header-prev-${stepIndex}`}
            {...headerProps}
            isPrevStep
          />
        );
        nextStepHeaders.push(
          <StepHeader
            key={`step-header-next-${stepIndex}`}
            {...headerProps}
            isNextStep
          />
        );

        const step = React.cloneElement(child, stepProps);
        stepIndex += 1;
        return step;
      }
      return;
    });
    return (
      <>
        <PrevHeaderContainer data-rc="previous-steps">
          {prevStepHeaders}
        </PrevHeaderContainer>
        <ContentContainer>{clonedChildren}</ContentContainer>
        {this.renderNavigationButtons({ stepNumber: visibleStep })}
        {visibleStep !== totalNumberOfSteps && (
          <UpNextContainer>{upNextLabel}</UpNextContainer>
        )}
        <NextHeaderContainer data-rc="next-steps">
          {nextStepHeaders}
        </NextHeaderContainer>
      </>
    );
  };

  render() {
    const {
      ContainerComponent,
      className,
      swipeDuration,
      swipeDurationTouch,
      swipeOffset,
      swipeOffsetTouch,
    } = this.props;

    return (
      <SwipeContainer
        className={className}
        onSwipePrev={this.handlePrevStep}
        onSwipeNext={this.handleNextStep}
        swipeDuration={swipeDuration}
        swipeDurationTouch={swipeDurationTouch}
        swipeOffset={swipeOffset}
        swipeOffsetTouch={swipeOffsetTouch}
      >
        <ContainerComponent>{this.renderContent()}</ContainerComponent>
      </SwipeContainer>
    );
  }
}
