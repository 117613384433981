import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import getAnalyticsSpreadsheet from 'graphql-queries/queries/analytics/getAnalyticsSpreadsheet';
import Toast from 'components/Toast/Toast';
import {
  AnalyticsFilterIconWrapper,
  FilterCard,
  FilterTextContainer,
  FiltersContainer,
  FilterDates,
  FilterTab,
  CustomTab
} from './styles';
import { IconNew as Icon } from '@matterapp/matter-ui';
import CustomDatesModal from './CustomDatesModal';
import { DownloadButton, downloadSpreadsheet } from 'components/DateFilterBar/DateFilterBar';

const FilterBar = ({
  items,
  value,
  onChange,
  fullWidth,
  startDate,
  endDate,
  groupedBy,
  workspaceId
}) => {
  const [isCustomDatesOpen, updateIsCustomDatesOpen] = useState(false);
  const [requestAnalyticsSpreadsheet, { data: spreadsheetData, loading: requestingSpreadsheet }] = useLazyQuery(getAnalyticsSpreadsheet, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (spreadsheetData && !requestingSpreadsheet) {
      downloadSpreadsheet(spreadsheetData.analyticsSpreadsheet);
      Toast.success('Check downloaded spreadsheet for instructions.');
    }
  }, [requestingSpreadsheet, spreadsheetData]);

  return (
    <FilterCard fullWidth={fullWidth}>
      <FilterTextContainer>
        <AnalyticsFilterIconWrapper onClick={() => updateIsCustomDatesOpen(true)}>
          <Icon name="analyticsFilter" />
        </AnalyticsFilterIconWrapper>
        {startDate && endDate ? (<FilterDates>{startDate.format('MMM. D, YYYY')} → {endDate.format('MMM. D, YYYY')}</FilterDates>) : null}
        <DownloadButton 
          disabled={requestingSpreadsheet}
          onClick={() => requestAnalyticsSpreadsheet({
            variables: {
              workspaceId,
              startDate: startDate.format('YYYY-MM-DD'),
              endDate: endDate.format('YYYY-MM-DD'),
              groupedBy
            }
          })}
        >
          Download Report (.XLSX)
        </DownloadButton>
      </FilterTextContainer>
      <FiltersContainer>
        <CustomTab 
          isActive={value === 'custom'}
          onClick={() => updateIsCustomDatesOpen(true)}
        >
          <Icon name="Calendar" fill='grey' /> Custom Date
        </CustomTab>
        {items.map(item => {
          return (
            <FilterTab
              key={item.label}
              isActive={item.value === value}
              onClick={() => onChange(item.value)}
              visibleOnMobile={item.visibleOnMobile}
            > 
              {item.label}
            </FilterTab>
          );
        })}
      </FiltersContainer>
      <CustomDatesModal 
        isOpen={isCustomDatesOpen}
        onClose={() => updateIsCustomDatesOpen(false)}
        onSelect={(from, to) => {
          onChange({ from, to }, true);
          updateIsCustomDatesOpen(false);
        }}
        startDate={startDate}
        endDate={endDate}
      />
    </FilterCard>
  );
};

FilterBar.defaultProps = {
  showDropdownOnlyOnMobile: false
};

FilterBar.propTypes = {
  items: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  workspaceId: PropTypes.string,
  groupedBy: PropTypes.string
};

export default FilterBar;