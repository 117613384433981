import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../SVG';

const Icon = ({ fill }) => (
  <SVG size={32} viewBox="0 0 32 32">
    <path
      d="M17.1,14.9h5.1v2.2h-5.1v5.1h-2.2v-5.1H9.8v-2.2h5.1V9.8h2.2V14.9z M28.5,16c0,6.9-5.6,12.5-12.5,12.5
        S3.5,22.9,3.5,16S9.1,3.5,16,3.5S28.5,9.1,28.5,16z M26.3,16c0-5.7-4.6-10.3-10.3-10.3C10.3,5.7,5.7,10.3,5.7,16
        c0,5.7,4.6,10.3,10.3,10.3C21.7,26.3,26.3,21.7,26.3,16z"
      fill={fill}
    />
  </SVG>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
