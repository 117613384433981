import React from 'react';
import styled from 'styled-components';
import Container from '../Container';

const EmptyContainer = styled(Container)`
  height: 30px;
`;

export default () => (
  <EmptyContainer />
);
