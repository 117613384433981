import React from 'react';
import PropTypes from 'prop-types';
import { WORKSPACE_PROPS, WORKSPACES_TITLE } from './consts';
import ProfileMenuItem from './ProfileMenuItem';
import {
  AddWorkspaceButton,
  AddWorkspaceButtonProfileContainer,
  ProfileMenuContainer,
  ProfileMenuTitle,
  ProfileMenuWorkspacesList,
} from './styles';

const ProfileMenuContents = (props) => {
  const { activeId, addLink, hideDivider, getFeedLink, onClick, title, workspaces } = props;
  const workspacesList = workspaces.map((workspace, index) => (
    <ProfileMenuItem
      activeId={activeId}
      key={`workspace-${workspace.id}-${index}`}
      onClick={onClick}
      to={getFeedLink(workspace)}
      workspace={workspace}
    />
  ));
  return (
    <ProfileMenuContainer hideDivider={hideDivider}>
      {title && <ProfileMenuTitle>{title}:</ProfileMenuTitle>}
      <ProfileMenuWorkspacesList>{workspacesList}</ProfileMenuWorkspacesList>
      <AddWorkspaceButtonProfileContainer>
        <AddWorkspaceButton to={addLink} />
      </AddWorkspaceButtonProfileContainer>
    </ProfileMenuContainer>
  );
};

ProfileMenuContents.defaultProps = {
  getFeedLink: () => null,
  title: WORKSPACES_TITLE,
  workspaces: [],
};

ProfileMenuContents.propTypes = {
  /** Current active workspace. */
  activeId: PropTypes.string,
  /** Link to add workspace. */
  addLink: PropTypes.string,
  /** Hide top divider. */
  hideDivider: PropTypes.bool,
  /** Function to generate feed link for workspace. Workspace object passed as argument. */
  getFeedLink: PropTypes.func,
  /** Callback when any link is clicked. */
  onClick: PropTypes.func,
  /** Title to display. */
  title: PropTypes.string,
  /** List of workspaces to display. */
  workspaces: PropTypes.arrayOf(PropTypes.shape(WORKSPACE_PROPS)),
};

export default ProfileMenuContents;
