import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../SVG';

const DEFAULT_SIZE = 80;

const Icon = ({ size, ...otherProps }) => (
  <SVG
    {...otherProps}
    size={size}
    viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}
  >
    <path
      d="M0 40c0 22.091 17.909 40 40 40s40-17.909 40-40S62.091 0 40 0 0 17.909 0 40z"
      fill="#05A164"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.194 27.337a4 4 0 010 5.657L38.227 54.961a4 4 0 01-5.657 0l-10.5-10.5a4 4 0 115.657-5.657l7.671 7.671 19.139-19.138a4 4 0 015.657 0z"
      fill="#fff"
    />
  </SVG>
);

Icon.defaultProps = {
  size: DEFAULT_SIZE,
};

Icon.propTypes = {
  size: PropTypes.number,
};

export default Icon;
