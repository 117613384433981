import React from 'react';
import { AppLayout } from 'modules';
import MainColumn from './MainColumn';

const FeedbackPage = () => {
  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      mainColumn={
        <MainColumn />
      }
      mobileProps={{
        showSideColumn: false,
      }}
    />
  );
};

export default FeedbackPage;
