import theme from '@matterapp/matter-theme';
import styled from 'styled-components';
import { Modal } from '../../../Modal';

export const ModalHeader = styled(Modal.Header)`
  text-align: center;
`;

export const Body = styled.div`
  div:nth-child(3) {
    border: none !important;
    padding-bottom: 0;
  }
`;

export const Section = styled.div`
  padding: 0 ${theme.spacing.singleAndHalf};
  padding-bottom: ${theme.spacing.singleAndHalf};;
  border-bottom: 1px solid ${theme.colors.blacks[10]};
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.singleAndHalf};;
  h3 {
    font-size: ${theme.fontSize.S};
    line-height: ${theme.lineHeight.M};
    font-weight: ${theme.fontWeight.bold};
    margin-left: ${theme.spacing.single};;
  }
`;