import Base from './TextArea';
import Basic from './Basic';
import PurpleTextArea from './PurpleTextArea';
import TextAreaWithCount from './WithCount';

const TextArea = Base;
TextArea.Basic = Basic;
TextArea.Purple = PurpleTextArea;
TextArea.WithCount = TextAreaWithCount;

export { TextArea, PurpleTextArea, TextAreaWithCount };
export default TextArea;
