import styled from 'styled-components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm } from '@matterapp/matter-ui';
import Toast from 'components/Toast/Toast';
import theme from '@matterapp/matter-theme';

const Wrapper = styled.div(() => ({
  marginTop: theme.spacing.half,
  marginBottom: theme.spacing.double
}));

const RemoveButton = styled(Button)(() => ({
  fontSize: theme.fontSize.base,
  lineHeight: theme.fontSize.base,
  padding: theme.spacing.half,
  minHeight: 0
}));

const Row = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `${theme.colors.blacks[10]} solid 1px`,
  padding: `${theme.spacing.half} 0`
}));

const Coins = styled.div(() => ({
  color: theme.colors.blacks[50],
  margin: `0 ${theme.spacing.single}`
}));

export default function AnniversaryCustomAmountTable({ anniversaryCustomAwards, saveCelebrationSettings, workspaceId }) {
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [recordToRemove, setRecordToRemove] = useState();

  if (!anniversaryCustomAwards.length) {
    return null;
  }

  return (
    <Wrapper>
      <Confirm
        isOpen={isRemoveModalOpen}
        onClickCancel={() => {
          setRemoveModalOpen(false);
          setRecordToRemove();
        }}
        onClickConfirm={async () => {
          await saveCelebrationSettings({
            variables: {
              workspaceId,
              settings: {
                anniversaryCustomAwards: anniversaryCustomAwards
                  .filter((record) => record.years !== recordToRemove.years)
                  .map((record) => ({ years: record.years, coins: record.coins }))
              }
            }
          });
          
          setRemoveModalOpen(false);
          setRecordToRemove();

          Toast.success('You successfully removed a custom award!');
        }}
        header='Remove Custom Award'
        subHeader={`You are removing the custom award for ${recordToRemove?.years} year${recordToRemove?.years > 1 ? 's' : ''} of service. After removal, the year of service will revert to the default amount.`}
        confirmButtonLabel='Yes, Remove'
        confirmButtonIsDestructive
      />
      {[...anniversaryCustomAwards]
        .sort((a, b) => a.years - b.years)
        .map(({ years, coins }) => (
          <Row key={`${years}-${coins}`}>
            <div style={{ display: 'flex' }}>
              <b>{years} year{years > 1 ? 's' : ''} of service</b>
              <Coins>{coins.toLocaleString('en-US')} coins</Coins>
            </div>
            <RemoveButton 
              color='black'
              onClick={() => {
                setRemoveModalOpen(true);
                setRecordToRemove({ years, coins });
              }}
            >
              Remove
            </RemoveButton>
          </Row>
        ))}
    </Wrapper>
  );
}

AnniversaryCustomAmountTable.propTypes = {
  anniversaryCustomAwards: PropTypes.array,
  saveCelebrationSettings: PropTypes.func,
  workspaceId: PropTypes.string
};