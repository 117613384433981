import React from 'react';
import Animation from '../../Animation';
import Image from './Image';
import * as C from './classNames';

export default class Cheers extends Animation {
  baseClass = C.BASE;

  moveBackground = (initialize) => {
    const tl = this.getDefaultTimeline({ duration: 0.5 });
    const DASH_LEFT = this.getElement(C.DASH.LEFT);
    const DASH_CENTER = this.getElement(C.DASH.CENTER);
    const DASH_RIGHT = this.getElement(C.DASH.RIGHT);

    const RING_LEFT = this.getElement(C.RING.LEFT);
    const RING_BOTTOM = this.getElement(C.RING.BOTTOM);
    const RING_RIGHT = this.getElement(C.RING.RIGHT);

    const CIRCLE_LEFT_1 = this.getElement(C.CIRCLE.LEFT_1);
    const CIRCLE_LEFT_2 = this.getElement(C.CIRCLE.LEFT_2);
    const CIRCLE_RIGHT = this.getElement(C.CIRCLE.RIGHT);
    const CIRCLE_TOP = this.getElement(C.CIRCLE.TOP);
    const PLUS = this.getElement(C.PLUS);

    const BACKGROUND_ELEMENTS = [
      [DASH_LEFT, { x: 5, y: 20 }],
      [DASH_CENTER, { y: 20 }],
      [DASH_RIGHT, { x: -5, y: 20 }],
      [RING_LEFT, { x: 20, y: 20 }],
      [RING_BOTTOM, { x: 20, y: -20 }],
      [RING_RIGHT, { x: -20, y: 20 }],
      [CIRCLE_LEFT_1, { x: 30, y: 10 }],
      [CIRCLE_LEFT_2, { x: 30, y: 5 }],
      [CIRCLE_RIGHT, { x: -30, y: 30 }],
      [CIRCLE_TOP, { x: 20, y: 30 }],
      [PLUS, { x: -10, y: 20, rotate: 360 }],
    ];

    BACKGROUND_ELEMENTS.forEach(([element, props], index) => {
      tl.to(element, { ...props, duration: 0 }, index === 0 ? '>0.5' : '<');
    });

    if (!initialize) {
      BACKGROUND_ELEMENTS.forEach(([element], index) => {
        tl.to(element, { x: 0, y: 0, rotate: 0 }, index === 0 ? '>0.5' : '<');
      });
      tl.to(PLUS, { rotate: 720, duration: 1, ease: 'power.out' }, '>');
      [
        CIRCLE_LEFT_1,
        RING_RIGHT,
        RING_LEFT,
        CIRCLE_TOP,
        RING_BOTTOM,
        CIRCLE_LEFT_2,
        CIRCLE_RIGHT,
      ].forEach((element) => {
        tl.to(element, { scale: 2, duration: 0.1 }, '<0.02');
        tl.to(element, { scale: 1, duration: 0.1 }, '>');
      });
    }

    return tl;
  };

  moveCups = (initialize) => {
    const tl = this.getDefaultTimeline({ duration: 0.5 });
    const BACKGROUND = this.getElement(C.BACKGROUND);
    const CUPS = this.getElement(C.CUP.GROUP);
    const CUP_LEFT = this.getElement(C.CUP.LEFT);
    const CUP_RIGHT = this.getElement(C.CUP.RIGHT);
    const duration = initialize ? 0 : 0.4;

    tl.to(BACKGROUND, { opacity: 0, duration }, '<');
    tl.to(CUP_LEFT, { x: -24, scale: 0.8, rotate: -45, duration }, '<');
    tl.to(CUP_RIGHT, { x: 24, scale: 0.8, rotate: 45, duration }, '<');

    if (!initialize) {
      tl.to(CUP_LEFT, { x: 0, scale: 1, ease: 'power.out', rotate: 0 }, '>0.2');
      tl.to(CUP_RIGHT, { x: 0, scale: 1, ease: 'power.out', rotate: 0 }, '<');
      tl.to(CUPS, { y: 10, duration: 0.25 }, '<');
      tl.to(BACKGROUND, { opacity: 1 }, '>');
      tl.to(CUPS, { y: -16, duration: 0.25 }, '<');
      tl.to(CUPS, { y: 0, duration: 0.8 }, '>0.4');
    }

    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = this.getBaseTimeline(initialize);
    tl.add(this.moveCups(initialize), '<');
    tl.add(this.moveBackground(initialize), '<');
    this.tl = tl;
    return this.tl;
  };

  render() {
    return <Image className={C.BASE} {...this.props} />;
  }
}
