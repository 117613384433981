import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Toggle from './Toggle';
import { LeftRight } from '../Structural';
import Text from '../Typography';

const LeftRightStyled = styled(LeftRight)`
  text-align: left;
`;

const ResponsiveBox = styled.div`
  margin-bottom: ${({ mbSmall }) => mbSmall}px;

  ${() => theme.media.large`
    margin-bottom: ${({ mbLarge }) => mbLarge}px;
  `}
`;

const ToggleUnit = ({ text, onChange, name, checked, isLast }) => {
  const largeScreenMargin = isLast ? 0 : 16;
  const smallScreenMargin = isLast ? 0 : 32;
  return (
    <ResponsiveBox mbSmall={smallScreenMargin} mbLarge={largeScreenMargin}>
      <LeftRightStyled
        left={
          <Text color="black" textStyle={400} textAlign="left" block>
            {text}
          </Text>
        }
        right={<Toggle checked={checked} name={name} onChange={onChange} />}
      />
    </ResponsiveBox>
  );
};

ToggleUnit.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default ToggleUnit;
