import React from 'react';
import PropTypes from 'prop-types';
import { TabContainer, TabIcon, TabLabel, TabLabelMobile } from './styles';

const Tab = (props) => {
  const {
    activeTab,
    className,
    icon,
    label,
    labelMobile,
    onClick,
    value,
  } = props;
  return (
    <TabContainer
      className={className}
      isActive={activeTab === value}
      value={value}
      onClick={onClick}
    >
      <TabIcon>{icon}</TabIcon>
      <TabLabelMobile>{labelMobile || label}</TabLabelMobile>
      <TabLabel>{label || labelMobile}</TabLabel>
    </TabContainer>
  );
};

Tab.defaultProps = {
  activeTab: 0,
};

Tab.PropTyoes = {
  activeTab: PropTypes.number,
  className: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.node,
  labelMobile: PropTypes.node,
  onClick: PropTypes.function,
  value: PropTypes.number,
};

export default Tab;
