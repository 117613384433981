import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import styled from 'styled-components';
import Button from '../../../Button';
import { ActionContainer, PeerSuggestionsImage } from './common/components';
import { CONNECT_YOUR_ACCOUNT_TEXT, REPLACE_TEXT } from './common/strings';
import theme from '@matterapp/matter-theme';

const ConnectButton = styled(Button.New).attrs({
  extraWide: true,
  primary: true,
  size: 'small',
})`
  display: block;
  margin-bottom: ${theme.spacing.single};

  &:hover,
  &:active {
    color: ${theme.colors.white};
  }
`;

const ContentContainer = styled.div`
  max-width: 540px;
`;

const LaterButton = styled(Button.New).attrs({
  link: true,
})`
  display: block;
  font-size: ${theme.fontSize.S};
  margin: auto;
`;

const ConnectYourAccount = (props) => {
  const {
    connectYourAccountsLink,
    firstName,
    hasPeers,
    onClickConnect,
    onClickLater,
    ...otherProps
  } = props;
  const nameText = firstName ? `, ${firstName}` : '';
  const content = (
    <ContentContainer>
      {hasPeers
        ? CONNECT_YOUR_ACCOUNT_TEXT.content
        : CONNECT_YOUR_ACCOUNT_TEXT.contentNoPeers}
    </ContentContainer>
  );

  const actions = (
    <ActionContainer>
      <ConnectButton to={connectYourAccountsLink} onClick={onClickConnect}>
        {CONNECT_YOUR_ACCOUNT_TEXT.connect}
      </ConnectButton>
      <LaterButton onClick={onClickLater}>
        {CONNECT_YOUR_ACCOUNT_TEXT.later}
      </LaterButton>
    </ActionContainer>
  );

  return (
    <Base
      {...otherProps}
      actions={actions}
      bannerType={Base.bannerTypes.INFO}
      content={content}
      header={CONNECT_YOUR_ACCOUNT_TEXT.header.replace(REPLACE_TEXT, nameText)}
      image={<PeerSuggestionsImage />}
    />
  );
};

ConnectYourAccount.propTypes = {
  /** Url to connect you accounts page. */
  connectYourAccountsLink: PropTypes.string,
  /** The first name of the user. */
  firstName: PropTypes.string,
  /** If user has peers, but not connected. */
  hasPeers: PropTypes.bool,
  /** Callback when connect button is clicked. */
  onClickConnect: PropTypes.func,
  /** Callback when later button is clicked. */
  onClickLater: PropTypes.func,
};

export default ConnectYourAccount;
