import React from 'react';
// import PropTypes from 'prop-types';

const Icon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.65251 9.80309C5.7825 8.93308 5.7825 7.52252 6.65251 6.65251C7.52252 5.7825 8.93308 5.7825 9.80309 6.65251L10 6.84942C10.2175 7.06692 10.2175 7.41956 10 7.63706C9.7825 7.85457 9.42986 7.85457 9.21235 7.63706L9.01544 7.44015C8.58044 7.00515 7.87516 7.00515 7.44015 7.44015C7.00515 7.87516 7.00515 8.58044 7.44015 9.01544L8.81853 10.3938C9.25354 10.8288 9.95882 10.8288 10.3938 10.3938C10.6113 10.1763 10.964 10.1763 11.1815 10.3938C11.399 10.6113 11.399 10.964 11.1815 11.1815C10.3115 12.0515 8.9009 12.0515 8.03089 11.1815L6.65251 9.80309Z" fill="#828285"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3475 10.1969C14.2175 11.0669 14.2175 12.4775 13.3475 13.3475C12.4775 14.2175 11.0669 14.2175 10.1969 13.3475L10 13.1506C9.7825 12.9331 9.7825 12.5804 10 12.3629C10.2175 12.1454 10.5701 12.1454 10.7876 12.3629L10.9846 12.5598C11.4196 12.9949 12.1248 12.9949 12.5598 12.5598C12.9949 12.1248 12.9949 11.4196 12.5598 10.9846L11.1815 9.60618C10.7465 9.17117 10.0412 9.17117 9.60618 9.60618C9.38867 9.82368 9.03603 9.82368 8.81853 9.60618C8.60103 9.38867 8.60103 9.03603 8.81853 8.81853C9.68854 7.94852 11.0991 7.94852 11.9691 8.81853L13.3475 10.1969Z" fill="#828285"/>
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#D8D8D9"/>
  </svg>
);

export default Icon;

