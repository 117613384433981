import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      d="M20.3,9.8c0-1.9,0-2.8-0.4-3.5c-0.3-0.6-0.8-1.1-1.5-1.5c-0.7-0.4-1.6-0.4-3.5-0.4H9c-1.9,0-2.8,0-3.5,0.4
        C4.9,5.2,4.4,5.7,4,6.3C3.7,7,3.7,8,3.7,9.8v9.9c0,0.3,0.3,0.6,0.6,0.6c0.2,0,0.3-0.1,0.4-0.2L7.8,17H15c1.9,0,2.8,0,3.5-0.4
        c0.6-0.3,1.1-0.8,1.5-1.5c0.4-0.7,0.4-1.6,0.4-3.5V9.8z M7,8.7c0-0.5,0.4-0.8,0.8-0.8h8.3c0.5,0,0.8,0.4,0.8,0.8
        c0,0.5-0.4,0.8-0.8,0.8H7.8C7.4,9.5,7,9.1,7,8.7z M7,12.8C7,12.4,7.4,12,7.8,12h6.7c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8
        H7.8C7.4,13.7,7,13.3,7,12.8z"
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
