import * as kudos from './types';
import { formatType } from './special';

export const COLLAPSE_TEXT = 'Show Less';
export const EXPAND_TEXT = 'Show More';
export const PLACEHOLDER = 'Enter message here...';

export const KUDOS_LIST = Object.values(kudos);
export const KUDOS_DEF = KUDOS_LIST.reduce(
  (acc, kudos) => ({ ...acc, [kudos.type]: kudos }),
  {}
);
export const KUDOS_DROPDOWN_ITEMS = KUDOS_LIST.map(
  ({ color, isBase, label, type }) => ({
    'data-color': color,
    isBase,
    label,
    value: type,
  })
);

export const KUDOS_LIST_BASE = KUDOS_LIST.filter((kudos) => kudos.isBase);
export const KUDOS_DROPDOWN_ITEMS_BASE = KUDOS_DROPDOWN_ITEMS.filter(
  (kudos) => kudos.isBase
);

export const KUDOS_OPTIONS = KUDOS_LIST.map(({ type }) => type);
export const KUDOS_DEFAULT = kudos.THANK_YOU;
export const KUDOS_DEFAULT_TYPE = KUDOS_DEFAULT.type;

export const getKudosType = (type, params) => {
  return formatType(KUDOS_DEF[type] || kudos.THANK_YOU, params);
};


export const isBaseKudos = (type) => {
  const kudosDef = KUDOS_DEF[type] || {};
  return kudosDef.isBase || false;
};

export const COMPOSER_KUDOS_LIMIT = 30;

export const getComposerKudos = (kudosList) => {
  if (!kudosList) { return null; }

  const kudosListCopy = [...kudosList];

  kudosListCopy.sort((b, a) => {
    return a.createdAt - b.createdAt;
  });

  return kudosList.slice(0, COMPOSER_KUDOS_LIMIT);
};