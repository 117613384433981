import React from 'react';
import PropTypes from 'prop-types';
import List from '../List';

export default class SingleSelectList extends React.PureComponent {
  static propTypes = {
    /** Link to find your peers page. Displays button if passed in. */
    findYourPeersLink: PropTypes.string,
    /** Callback when value changes. */
    onChange: PropTypes.func,
    /** Callback when find you peers button is clicked. */
    onClickFindYourPeers: PropTypes.func.isRequired,
    /** Callback when peer is selected */
    onClick: PropTypes.func,
    /**
     * List of peers to show in the list.
     */
    peers: PropTypes.arrayOf(
      PropTypes.shape({
        /** The email of the peer. */
        email: PropTypes.string.isRequired,
        /** The full name of the peer. */
        fullName: PropTypes.string,
        /** The url of the photo of the peer. */
        photoUrl: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    onChange: () => {},
    value: {},
  };

  /**
   * Callback when a peer is clicked.
   * @param { Object } e: The click event.
   * @param { Object } peerProps: The props of the clicked row containing the peer.
   * @returns {void }
   */
  handleClickPeer = (e, { peer }) => {
    const { onChange, onClick } = this.props;
    onClick();
    onChange(e, { value: peer });
  };

  render() {
    const { findYourPeersLink, peers, onClickFindYourPeers } = this.props;
    return (
      <List
        findYourPeersLink={findYourPeersLink}
        onClickFindYourPeers={onClickFindYourPeers}
        onClickPeer={this.handleClickPeer}
        peers={peers}
      />
    );
  }
}
