import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Styles from './styles';

const Container = styled(Styles.Container)`
  ${({ marginBottom }) => `
    margin-bottom: ${marginBottom}%;
  `}
`;

const Point = styled(Styles.Point)`
  ${({ top, left }) => `
    top: ${top}%;
    left: ${left}%;
  `}
`;

const BasePath = React.forwardRef((props, ref) => {
  const {
    animateBackground,
    background,
    className,
    marginBottom,
    onComplete,
    PathComponent,
    pointProps,
    renderPointContents,
    step,
    ...otherProps
  } = props;
  const [pathIsShown, setPathIsShown] = useState(!animateBackground);
  const [pointIsShown, setPointIsShown] = useState(false);
  const [currentStep, setStep] = useState(step);

  const handleSetPointIsShown = (isShown) => {
    if (ref && ref.current) {
      setPointIsShown(isShown);
    }
  };

  useEffect(() => {
    if (currentStep !== step) {
      handleSetPointIsShown(false);
      setStep(currentStep);
    }
  }, [step]);

  if (animateBackground && pointIsShown) {
    handleSetPointIsShown(false);
    setPathIsShown(false);
  }

  const handleComplete = (e) => {
    setPathIsShown(true);
    if (!animateBackground) {
      handleSetPointIsShown(true);
    }
    onComplete(e);
  };

  return (
    <Container className={className} marginBottom={marginBottom}>
      <PathComponent
        animateBackground={animateBackground}
        ref={ref}
        {...otherProps}
        onComplete={handleComplete}
      />
      {background}
      <Point top={pointProps.top} left={pointProps.left} enabled={pointIsShown} pathIsShown={pathIsShown}>
        {renderPointContents({ ...props, enabled: pointIsShown })}
      </Point>
    </Container>
  );
});

BasePath.propTypes = {
  animateBackground: PropTypes.bool,
  background: PropTypes.node,
  className: PropTypes.string,
  marginBottom: PropTypes.number,
  onComplete: PropTypes.func.isRequired,
  PathComponent: PropTypes.elementType,
  pointProps: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  renderPointContents: PropTypes.func,
};

BasePath.defaultProps = {
  marginBottom: 0,
  pointProps: {},
  renderPointContents: () => null,
};

export default BasePath;
