import React from 'react';
import { AppLayout } from 'modules';
import ReportsPage from './ReportsPage';

export default function RewardsReports() {
  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        <ReportsPage />
      }
    />
  );
};
