import React, { useEffect, useState } from 'react';

export const DEFAULT_EMPTY_MESSAGE = 'Type the name of a contact';
export const DEFAULT_PLACEHOLDER = 'name@example.com';

export const renderEmptyMenu = () => <div />;

export const useEmail = (value) => {
  const [email, setEmail] = useState(value);

  useEffect(() => {
    setEmail(value);
  }, [value]);

  return email;
};

export const generateInitialMultipleValues = (initialAmount = 3) => {
  const value = [];
  for (let i = 0; i < initialAmount; i++) {
    value.push('');
  }
  return value;
};

export const updateMultipleEmails = (emails = [], indexToChange, value, errors) => {
  let allValuesFull = true;
  const checkErrors = errors && Array.isArray(errors);
  const updatedEmails = emails.map((email, i) => {
    if (!email) {
      allValuesFull = false;
    }
    if (i === indexToChange) {
      return value;
    }
    return checkErrors ? errors[i] : email;
  });

  return allValuesFull ? [...updatedEmails, ''] : updatedEmails;
};

export const useMultipleEmail = (props) => {
  const { onChangeValue, initialAmountOfInputs, value = [] } = props;
  const [emails, setEmails] = useState(
    generateInitialMultipleValues(initialAmountOfInputs)
  );
  const [errors, setErrors] = useState(
    generateInitialMultipleValues(initialAmountOfInputs)
  );

  useEffect(() => {
    if (value && value.length) {
      setEmails(value);
    }
  }, [value]);

  const handleBlur = (e, { isSubmit, index, value }) => {
    onChangeValue(e, { ...props, emails, isSubmit, value: emails });
    setEmails(updateMultipleEmails(emails, index, value));
  };

  const handleKeyPress = (e, keyProps) => {
    if (e.key === 'Enter') {
      handleBlur(e, { ...keyProps, isSubmit: true });
    }
  };

  return {
    emails,
    errors,
    setEmails: ({ value, index }) => {
      const updatedEmails = updateMultipleEmails(emails, index, value);
      setEmails(updatedEmails);
      return updatedEmails;
    },
    setErrors: ({ value, index }) => {
      const updatedErrors = updateMultipleEmails(emails, index, value, errors);
      setErrors(updatedErrors);
      return updatedErrors;
    },
    handleBlur,
    handleKeyPress,
    hasOneValue: (emailValues = []) => emailValues.filter(email => !!email).length === 1,
    updateMultipleEmails,
  };
};