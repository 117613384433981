import { WORKSPACES } from 'app-consts';

/** Maps TAB_VALUES from web-client to matter-ui */
/** eg. general: settings-general */
export const TAB_VALUES = WORKSPACES.TAB_VALUES;


/*****************************************************************************/
/* FUNCTIONS TO GET MEMBERS BY STATUS                                        */
/*****************************************************************************/
export const getMembersFromStatus = (members = [], status) =>
  members.filter((member) => member.status === status);

export const getAcceptedMembers = (members) =>
  getMembersFromStatus(members, WORKSPACES.USER_STATUS.ACCEPTED);

export const getPendingMembers = (members = []) =>
  getMembersFromStatus(members, WORKSPACES.USER_STATUS.PENDING);

export const getQueryParamForOpenSettingsPanel = (tab) => {
  const base = `${WORKSPACES.URL_PARAMS.SETTINGS}=${WORKSPACES.URL_PARAM_VALUES.SETTINGS.OPEN}`;
  if (tab) {
    return `${base}&${WORKSPACES.URL_PARAMS.PANEL}=${tab}`;
  }
  return base;
};

/*****************************************************************************/
/* FUNCTIONS TO DETERMINE WHAT PANELS ARE OPEN                               */
/*****************************************************************************/
export const isDeletePanelOpen = (params = {}) =>
  params[WORKSPACES.URL_PARAMS.PANEL] === WORKSPACES.TAB_VALUES.DELETE;

export const isSettingsPanelOpenFromParams = (params = {}) => {
  return (
    params[WORKSPACES.URL_PARAMS.SETTINGS] ===
      WORKSPACES.URL_PARAM_VALUES.SETTINGS.OPEN ||
    !!params[WORKSPACES.URL_PARAMS.PANEL]
  );
};

export const isLeftPanelActiveFromParams = (params = {}) => {
  const panelValues = Object.values(WORKSPACES.URL_PARAM_VALUES.PANEL);
  return !panelValues.includes(params[WORKSPACES.URL_PARAMS.SETTINGS]);
};
