const TAG = '@composer';

export const INITIALIZE = `${TAG}/INITIALIZE`;
export const CHANGE_MESSAGE = `${TAG}/CHANGE_MESSAGE`;
export const CHANGE_MODAL_STEP = `${TAG}/CHANGE_MODAL_STEP`;

export const ADD_SKILL = `${TAG}/ADD_SKILL`;
export const REMOVE_SKILL = `${TAG}/REMOVE_SKILL`;

export const CLOSE_REQUEST_FEEDBACK_MODAL = `${TAG}/CLOSE_REQUEST_FEEDBACK_MODAL`;
export const OPEN_REQUEST_FEEDBACK_MODAL = `${TAG}/OPEN_REQUEST_FEEDBACK_MODAL`;

export const SET_CUSTOM_SURVEY = `${TAG}/SET_CUSTOM_SURVEY`;
export const SET_FEEDBACK_TEMPLATE = `${TAG}/SET_FEEDBACK_TEMPLATE`;
export const SET_IS_SENDING = `${TAG}/SET_IS_SENDING`;

export const CLOSE_SEND_KUDOS_MODAL = `${TAG}/CLOSE_SEND_KUDOS_MODAL`;
export const OPEN_SEND_KUDOS_MODAL = `${TAG}/OPEN_SEND_KUDOS_MODAL`;

export const CLOSE_KUDOS_SENT_MODAL = `${TAG}/CLOSE_KUDOS_SENT_MODAL`;
export const OPEN_KUDOS_SENT_MODAL = `${TAG}/OPEN_KUDOS_SENT_MODAL`;

export const CHANGE_SELECTED_KUDOS = `${TAG}/CHANGE_SELECTED_KUDOS`;
export const RESET_SELECTED_KUDOS = `${TAG}/RESET_SELECTED_KUDOS`;
export const CHANGE_SELECTED_PEERS = `${TAG}/CHANGE_SELECTED_PEERS`;

export const OPEN_GIVE_FEEDBACK_MODAL = `${TAG}/OPEN_GIVE_FEEDBACK_MODAL`;
export const CLOSE_GIVE_FEEDBACK_MODAL = `${TAG}/CLOSE_GIVE_FEEDBACK_MODAL`;

export const CHANGE_SELECTED_PEER = `${TAG}/CHANGE_SELECTED_PEER`;
export const CHANGE_SELECTED_SKILL = `${TAG}/CHANGE_SELECTED_SKILL`;
export const RESET_SELECTED_SKILL = `${TAG}/RESET_SELECTED_SKILL`;
export const UPDATE_SELECTED_SKILLS = `${TAG}/UPDATE_SELECTED_SKILLS`;
export const INIT_USER_SKILLS_LIST = `${TAG}/INIT_USER_SKILLS_LIST`;
