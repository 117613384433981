import withRouter from 'hocs/withRouter';
import { compose, withProps } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import {
  FIND_OR_CREATE_FEEDBACK_TEMPLATE_MUTATION,
  SKILL_DETAILS_QUERY,
} from 'graphql-queries/queries';
import {
  withGiveFeedback,
} from 'modules/composer/hocs';
import {
  withComposer,
  withPeerRecommendations,
} from '../../../../hocs';
import { DEFAULT_REQUEST_MESSAGE } from 'copy-consts';
import Toast from 'components/Toast/Toast';

export const getSortedMembersList = (memberList = []) => {
  return [...memberList].sort((a, b) => {
    let first = a.person.fullName || '';
    let second = b.person.fullName || '';
    if (first === second) {
      first = a.person.email || '';
      second = b.person.email || '';
    }
    return first.toLowerCase() > second.toLowerCase() ? 1 : -1;
  });
};

export const withData = compose(
  withRouter,
  withComposer,
  withPeerRecommendations({
    peerRecommendationFilterFn: () => true,
  }),
  graphql(SKILL_DETAILS_QUERY, {
    name: 'pickedSkillsList',
    options: ({ match }) => {
      return {
        variables: { workspaceId: match.params?.workspaceId }
      };
    }
  }),
  graphql(FIND_OR_CREATE_FEEDBACK_TEMPLATE_MUTATION, {
    name: 'findOrCreateFeedbackTemplate',
  }),
  withProps(({
    findOrCreateFeedbackTemplate,
    requestFeedbackFromMultiplePeers,
    match
  }) => ({
    handleSendFeedbackRequest: async ({ selectedMember, selectedSkillsIds, message }) => {
      try {
        const { data: templateData } = await findOrCreateFeedbackTemplate({
          variables: { 
            skillIds: selectedSkillsIds 
          },
        });
        const { id: feedbackTemplateId } = templateData.findOrCreateFeedbackTemplate;

        const inviteProps = {
          peers: [selectedMember],
          source: 'workspaces',
        };
        inviteProps.workspaceId = match.params?.workspaceId;
        inviteProps.skillIds = selectedSkillsIds;
        inviteProps.feedbackTemplateId = feedbackTemplateId;

        if (message !== DEFAULT_REQUEST_MESSAGE) {
          inviteProps.customNote = message;
        }

        await requestFeedbackFromMultiplePeers(inviteProps);
        Toast.success(`You've successfully requested feedback`);
      } catch (e) {
        console.error('Send feedback request error: ', e);
        Toast.error('Something went wrong. Please try again later');
      }
    },
  })),
  withGiveFeedback,
);
