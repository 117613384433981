import { compose, lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import OnMount from '../components/OnMount';

function componentDidMount() {
  const { handleMount } = this.props;
  return handleMount();
}

const withData = compose(lifecycle({ componentDidMount }));

const OnMountContainer = withData(OnMount);

OnMountContainer.propTypes = {
  handleMount: PropTypes.func.isRequired,
};

export default OnMountContainer;
