import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextStyles from './styles/TextStyles';
import TextWithReplacement from './TextWithReplacement';

const getColor = (theme, passedColorProp) => {
  const keys = passedColorProp.match(/\w+/g);
  return keys.reduce((r, k) => r[k], theme.colors);
};

const StyledSpan = styled.span`
  color: ${(props) => getColor(props.theme, props.color)};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  font-weight: ${(props) => props.fontWeight};
  margin-bottom: ${(props) => props.marginBottom}px;
  text-align: ${(props) => props.textAlign};
  ${(props) => props.uppercase && 'text-transform: uppercase;'};
  ${(props) => props.block && 'display: block;'};
  ${(props) => TextStyles[props.textStyle]};
  ${(props) => props.bold && 'font-weight: bold;'};
  ${(props) => props.underline && 'text-decoration: underline;'};
`;

const StyledParagraph = StyledSpan.withComponent('p');

const Text = ({
  as,
  color,
  fontSize,
  lineHeight,
  fontWeight,
  textStyle,
  textAlign,
  marginBottom,
  uppercase,
  bold,
  block,
  children,
  style,
  isNoBreak,
  underline,
  className,
  rcLabel,
}) => {
  if (children === null) { return null; }
  const stringChildren = String(children);
  if (stringChildren.length < 1) { return null; }
  const splitChildrenArr = (!isNoBreak && children.split && children.split('\n')) || [];
  const text = (
    (splitChildrenArr.length === 0) ||
    (splitChildrenArr.length === 1)
  ) ? children :
    splitChildrenArr.map(
      (item, index) => {
        const br = index === splitChildrenArr.length - 1 ? null : (<br />);
        return (
          <span key={`text_line_${String(index)}`}>{item}{br}</span>
        );
      });

  return (
    <React.Fragment>
      {as === 'span' &&
        <StyledSpan
          color={color}
          lineHeight={lineHeight ? `${lineHeight}px` : 'inherit'}
          fontSize={fontSize ? `${fontSize}px` : 'inherit'}
          fontWeight={fontWeight}
          textStyle={textStyle}
          textAlign={textAlign}
          uppercase={uppercase}
          underline={underline}
          bold={bold}
          block={block}
          marginBottom={marginBottom}
          style={style}
          className={className}
          data-rc={rcLabel}
        >
          {text}
        </StyledSpan>
      }
      {as === 'p' &&
        <StyledParagraph
          color={color}
          lineHeight={lineHeight ? `${lineHeight}px` : 'inherit'}
          fontSize={fontSize ? `${fontSize}px` : 'inherit'}
          fontWeight={fontWeight}
          textStyle={textStyle}
          textAlign={textAlign}
          uppercase={uppercase}
          underline={underline}
          bold={bold}
          block={block}
          marginBottom={marginBottom}
          style={style}
          className={className}
          data-rc={rcLabel}
        >
          {children}
        </StyledParagraph>
      }
    </React.Fragment>
  );
};

Text.defaultProps = {
  as: 'span',
  bold: false,
  uppercase: false,
  block: false,
  children: null,
  color: 'inherit',
  lineHeight: null,
  fontSize: null,
  fontWeight: '400',
  textStyle: null,
  textAlign: 'left',
  marginBottom: 0,
  style: {},
  isNoBreak: false,
  underline: false,
  className: null,
};

Text.propTypes = {
  as: PropTypes.oneOf([
    'span',
    'p',
  ]),
  bold: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  lineHeight: PropTypes.number,
  fontSize: PropTypes.oneOf([
    14,
    17,
    22,
    24,
    48,
  ]),
  fontWeight: PropTypes.oneOf([
    'bold',
    '900',
    '700',
    '500',
    '400',
  ]),
  textStyle: PropTypes.number,
  textAlign: PropTypes.oneOf([
    'initial',
    'inherit',
    'left',
    'center',
    'right',
  ]),
  marginBottom: PropTypes.number,
  uppercase: PropTypes.bool,
  block: PropTypes.bool,
  style: PropTypes.shape({}),
  isNoBreak: PropTypes.bool,
  underline: PropTypes.bool,
  className: PropTypes.string,
  rcLabel: PropTypes.string,
};

Text.WithReplacement = TextWithReplacement;

export default Text;
