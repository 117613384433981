import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ClickableLink, TextArea } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

const Container = styled.div(() => ({
  marginTop: theme.spacing.double,
  position: 'relative'
}));

const ResetButton = styled(ClickableLink)(() => ({
  position: 'absolute',
  top: `-${theme.spacing.half}`,
  right: `-${theme.spacing.threeQuarters}`,
  fontSize: theme.fontSize.base,
  padding: theme.spacing.threeQuarters,
  zIndex: 1,
  '&:not(:hover):not(:active)': {
    color: theme.colors.blacks[50]
  }
}));

const TEXTAREA_MIN_HEIGHT = 140;
const TEXTAREA_MAX_HEIGHT = TEXTAREA_MIN_HEIGHT + 100;

const MessageArea = styled(TextArea.WithCount)(({ theme }) => ({
  fontWeight: theme.fontWeight.regular,
  minHeight: `${TEXTAREA_MIN_HEIGHT}px`,
  maxHeight: `${TEXTAREA_MAX_HEIGHT}px`,
  border: 'none'
}));

const MessageHeader = styled.div(() => ({
  fontSize: theme.fontSize.S,
  fontWeight: theme.fontWeight.semiBold,
  marginBottom: theme.spacing.half
}));

export default function TextfieldSection({ 
  onChange, 
  defaultMessage,
  message,
  minLength = 0,
  maxLength = 400,
  messageHeader = 'Your Message:'
}) {
  return (
    <Container>
      {defaultMessage ? (
        <ResetButton 
          onClick={() => onChange(defaultMessage)}
        >
          Reset to default
        </ResetButton>
      ) : null}
      <MessageArea 
        autoHeight
        minLength={minLength}
        maxLength={maxLength}
        label={
          <MessageHeader>{messageHeader}</MessageHeader>
        }
        allowErrorBeforeBlur
        showCharacterCountOnIncorrectLength
        onChange={(_, { value }) => onChange(value)}
        value={message}
      />
    </Container>
  );
}

TextfieldSection.propTypes = {
  onChange: PropTypes.func, 
  defaultMessage: PropTypes.string,
  message: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  messageHeader: PropTypes.string
};
