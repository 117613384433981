import React from 'react';
import PropTypes from 'prop-types';
import Kudos from '../../Kudos';
import { useModal } from './hooks';
import {
  KudosContainer,
  KudosModal,
  UserContainer,
  UserDropdown,
  UserDropdownContainer,
  UserItem,
  IsPublicDropdown,
  IsPublicDropdownContainer,
  MIN_MESSAGE_LENGTH,
  MAX_MESSAGE_LENGTH,
} from './styles';
import { DisabledMessageInModal } from '../sharedStyles';

const Modal = (props) => {
  const { emptyMenuMessageNoItems } = props;
  const {
    disabledMessage,
    footerProps,
    gridProps,
    hasSelectedUser,
    headerProps,
    isPublicDropdownProps,
    kudosCardParams,
    modalProps,
    selectedUser,
    showIsPublicDropdown,
    showGrid,
    userDropdownProps,
  } = useModal(props);

  return (
    <KudosModal {...modalProps}>
      <KudosModal.Header.WithBackClose {...headerProps} />
      <KudosModal.Body>
        {showGrid && (
          <KudosContainer>
            <Kudos.Grid {...gridProps}/>
          </KudosContainer>
        )}
        {!showGrid && (
          <>
            <UserContainer>
              <UserDropdownContainer>
                {disabledMessage && (
                  <DisabledMessageInModal>{disabledMessage}</DisabledMessageInModal>
                )}
                {hasSelectedUser && !disabledMessage && (
                  <UserItem peer={selectedUser} />
                )}
                {!hasSelectedUser && !disabledMessage && (
                  <UserDropdown 
                    {...userDropdownProps}
                    emptyMenuMessageNoItems={emptyMenuMessageNoItems}
                  />
                )}
                {showIsPublicDropdown && (
                  <IsPublicDropdownContainer>
                    <IsPublicDropdown {...isPublicDropdownProps} />
                  </IsPublicDropdownContainer>
                )}
              </UserDropdownContainer>
            </UserContainer>
            <KudosContainer>
              <Kudos.Card {...kudosCardParams} />
            </KudosContainer>
          </>
        )}
      </KudosModal.Body>
      {!showGrid && <KudosModal.Footer.WithCancelSend {...footerProps} />}
    </KudosModal>
  );
};

Modal.defaultProps = {
  minMessageLength: MIN_MESSAGE_LENGTH,
  maxMessageLength: MAX_MESSAGE_LENGTH,
  onClickUnlock: () => null,
  selectionTitle: 'Select From Below',
  title: 'Give Kudos',
  users: [],
  userPlaceholder: 'Select member(s)...',
};

Modal.propTypes = {
  /** Pass in message to display and disable editing of kudos. */
  disabledMessage: PropTypes.node,
  /** Is modal currently open. */
  isOpen: PropTypes.bool,
  /** If currently sending kudos. */
  isSendingKudos: PropTypes.bool,
  /** Override default locked message. */
  lockedMessage: PropTypes.string,
  /** Max length of kudos message. */
  maxMessageLength: PropTypes.number,
  /** Min length of kudos message. */
  minMessageLength: PropTypes.number,
  /** Callback when clicking on 'unlock this kudos' link. */
  onClickUnlock: PropTypes.func,
  /** Callback when kudos is sending. */
  onSendKudos: PropTypes.func,
  /** The selected kudos type. */
  selectedKudosType: PropTypes.string,
  /** Automatically assigns kudos to selected member / hides dropdown. */
  selectedUser: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    userId: PropTypes.string,
  }),
  showEmailInDropdown: PropTypes.bool,
  /** Display kudos selection grid. */
  showKudosSelection: PropTypes.bool,
  /** Display the Is Public Dropdown. */
  showIsPublicDropdown: PropTypes.bool,
  /** Displays the locked (pro) kudos. */
  showLockedKudos: PropTypes.bool,
  /** title of Kudos selection grid. */
  selectionTitle: PropTypes.string,
  /** Title of the modal. */
  title: PropTypes.string,
  /** Placeholder text for user dropdown. */
  userPlaceholder: PropTypes.string,
  /** List of users to display in dropdown. */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      fullName: PropTypes.string,
      userId: PropTypes.string,
    })
  ),
};

export default Modal;
