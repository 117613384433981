import React from 'react';
import { SVG, Path } from './consts';

const Feedbacks = (props) => (
  <SVG {...props}>
    <Path
      d="M20.7,9.7c0-2,0-2.9-0.4-3.7c-0.3-0.7-0.9-1.2-1.5-1.5c-0.7-0.4-1.7-0.4-3.7-0.4H8.8c-2,0-2.9,0-3.7,0.4
      C4.5,4.8,4,5.4,3.6,6C3.2,6.8,3.2,7.8,3.2,9.7v10.4c0,0.3,0.3,0.6,0.6,0.6c0.2,0,0.3-0.1,0.4-0.2l3.3-3.3H15c2,0,2.9,0,3.7-0.4
      c0.7-0.3,1.2-0.9,1.5-1.5c0.4-0.7,0.4-1.7,0.4-3.7V9.7H20.7z M6.7,8.5c0-0.5,0.4-0.9,0.9-0.9h8.8c0.5,0,0.9,0.4,0.9,0.9
      s-0.4,0.9-0.9,0.9H7.6C7.1,9.4,6.7,9,6.7,8.5z M6.7,12.9c0-0.5,0.4-0.9,0.9-0.9h7c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9h-7
      C7.1,13.8,6.7,13.4,6.7,12.9z"
    />
  </SVG>
);

export default Feedbacks;
