import React from 'react';
import RightToRight1 from '../Paths/RightToRight1';
import BasePath from './BasePath';

const Path3 = React.forwardRef((props, ref) => (
  <BasePath
    marginBottom={-11.8}
    PathComponent={RightToRight1}
    pointProps={{ top: 104, left: 97.7 }}
    ref={ref}
    {...props}
  />
));

export default Path3;