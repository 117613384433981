import React from 'react';
import PropTypes from 'prop-types';
import {
  AdminHeader,
  SettingsTab,
  SettingsTabMobile,
  AnalyticsTab,
  RewardsManagerTab
} from './styles';
import { WORKSPACE_PROPS, WORKSPACE_NAV_DEFAULT_PROPS } from './consts';

const AdminTabs = (props) => {
  const {
    activeWorkspaceTab,
    activeWorkspace,
    onClickSettings,
    getAnalyticsLink,
    getRewardsManagerLink,
    numberOfNotifications
  } = props;
  return (
    <>
      <AdminHeader>Admin</AdminHeader>
      <SettingsTabMobile
        onClick={(e) =>
          onClickSettings(e, {
            ...props,
            workspace: activeWorkspace,
            shouldRedirect: true,
          })
        }
        isDisabled={activeWorkspace.isExpired}
      />
      <SettingsTab
        onClick={(e) =>
          onClickSettings(e, { ...props, workspace: activeWorkspace })
        }
        isDisabled={activeWorkspace.isExpired}
      />
      <AnalyticsTab
        to={getAnalyticsLink(activeWorkspace)}
        isActive={activeWorkspaceTab === 6}
        isDisabled={activeWorkspace.isExpired}
      />
      <RewardsManagerTab 
        numberOfNotifications={numberOfNotifications}
        to={getRewardsManagerLink(activeWorkspace)}
        isActive={activeWorkspaceTab === 7}
        isDisabled={activeWorkspace.isExpired}
      />
    </>
  );
};

AdminTabs.defaultProps = {
  activeWorkspace: {},
  ...WORKSPACE_NAV_DEFAULT_PROPS,
};

AdminTabs.propTypes = {
  activeWorkspace: PropTypes.shape(WORKSPACE_PROPS),
  ...WORKSPACE_PROPS,
};

export default AdminTabs;
