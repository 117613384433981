import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spacing } from '@matterapp/matter-theme';
import AuthBase from './Base';
import ConfirmationImage from './confirmation.png';

const ConfirmationImageContainer = styled.div`
  margin-bottom: -${spacing.single};
  img {
    width: 264px;
  }
`;

const MagicLinkConfirmation = (props) => {
  const { landingPagePath } = props;
  return (
    <AuthBase
      isConfirmPage
      id={'AuthConfirmation'}
      title="Check your email!"
      subtitle={
        <>
          You will receive a magic link in your inbox if this email is tied to a Matter account.
          <br/>
          Check your email to sign in instantly.
        </>
      }
      content={
        <ConfirmationImageContainer>
          <img src={ConfirmationImage} id={'magic-link-confirmation'}/>
        </ConfirmationImageContainer>
      }
      landingPagePath={landingPagePath}
      authContentFooterText={<>Didn&apos;t show up? Contact: <a href='mailto:hello@matterapp.com?Subject=Matter Login Help'>hello@matterapp.com</a></>}
    />
  );
};

MagicLinkConfirmation.propTypes = {
  handleSubmit: PropTypes.func,
  currentEmail: PropTypes.string.isRequired,
  landingPagePath: PropTypes.string.isRequired,
  handleResendMagicLink: PropTypes.func.isRequired,
};

export default MagicLinkConfirmation;
