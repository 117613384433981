import React from 'react';
import { Colors, Thumbnail, Paths } from '../Thumbnail';

const Thoughtful = (props) => {
  return (
    <Thumbnail color={Colors.Green} width={292} height={101} top={0} left={0} {...props}>
      <Paths.L40 d="M45.7398 78.49L43.3698 35.34C42.7398 35.79 42.0798 36.21 41.3998 36.58L39.0898 78.49H45.7398Z" />
      <Paths.L50 d="M43.22 33.9C43.01 32.41 42.66 30.95 42.16 29.54C40.2 23.91 36.07 19.05 30.25 16.31C22.5 12.67 13.75 13.73 7.20996 18.32C7.82996 26.29 12.6 33.71 20.34 37.35C27.33 40.64 35.14 40.1 41.4 36.58C42.07 36.2 42.73 35.79 43.37 35.34C43.34 34.86 43.28 34.37 43.22 33.9Z" />
      <Paths.L50 d="M43.9498 48.15C44.1198 46.91 44.4198 45.7 44.8298 44.52C46.4598 39.84 49.8998 35.79 54.7498 33.51C61.1998 30.48 68.4898 31.36 73.9298 35.19C73.4198 41.83 69.4498 48 62.9998 51.03C57.1798 53.77 50.6798 53.32 45.4598 50.39C44.8998 50.08 44.3498 49.73 43.8198 49.35C43.8498 48.94 43.8998 48.54 43.9498 48.15Z" />
      <Paths.L50
        evenOdd
        d="M84.4 93.7C75.3 97.79 59.8 100.48 42.2 100.48C24.6 100.48 9.1 97.79 0 93.7C6.92 81 23.21 72.08 42.2 72.08C61.19 72.08 77.47 81 84.4 93.7Z"
      />
      <Paths.L40 d="M104.77 85.66L103.17 56.57C102.74 56.87 102.3 57.15 101.84 57.41L100.28 85.66H104.77Z" />
      <Paths.L50 d="M103.07 55.6C102.93 54.6 102.69 53.61 102.36 52.66C101.04 48.87 98.2498 45.59 94.3298 43.74C89.1098 41.28 83.2098 42 78.7998 45.1C79.2198 50.47 82.4298 55.47 87.6498 57.93C92.3598 60.15 97.6298 59.78 101.85 57.41C102.3 57.16 102.75 56.87 103.18 56.57C103.15 56.24 103.11 55.92 103.07 55.6Z" />
      <Paths.L50 d="M103.56 65.2C103.68 64.37 103.88 63.55 104.15 62.75C105.25 59.59 107.57 56.86 110.83 55.33C115.18 53.28 120.09 53.88 123.76 56.46C123.41 60.93 120.74 65.1 116.39 67.14C112.47 68.99 108.08 68.68 104.57 66.71C104.19 66.5 103.82 66.26 103.46 66.01C103.49 65.74 103.52 65.47 103.56 65.2Z" />
      <Paths.L50
        evenOdd
        d="M130.82 95.9C124.68 98.66 114.23 100.47 102.37 100.47C90.5099 100.47 80.0599 98.66 73.9199 95.9C78.5899 87.34 89.5699 81.33 102.37 81.33C115.18 81.33 126.16 87.35 130.82 95.9Z"
      />
      <Paths.L50
        evenOdd
        d="M156.97 42.18C156.85 42.3 156.85 42.49 156.97 42.61L160.02 45.66L159.95 45.73L182 46.9C182.08 46.9 182.16 46.88 182.22 46.82L191.31 38.4C191.38 38.34 191.41 38.25 191.41 38.16L190.2 15.59L190.21 15.58L190.2 15.57L187.11 12.48C186.99 12.36 186.8 12.36 186.68 12.48L156.97 42.18Z"
      />
      <Paths.L50
        evenOdd
        d="M271.53 36.55H267.82V27.95H271.53C282.54 27.95 291.46 36.87 291.46 47.88V63.12C291.46 74.13 282.53 83.05 271.53 83.05H269.58V74.45H271.53C277.79 74.45 282.86 69.38 282.86 63.12V47.88C282.86 41.62 277.79 36.55 271.53 36.55Z"
      />
      <Paths.L60
        evenOdd
        d="M269.56 36.46H268.2C267.28 36.46 266.54 35.72 266.54 34.8C266.54 33.88 267.28 33.14 268.2 33.14H269.56C278.83 33.14 286.34 40.65 286.34 49.92V60.13C286.34 61.05 285.6 61.79 284.68 61.79C283.76 61.79 283.02 61.05 283.02 60.13V49.92C283.01 42.48 276.99 36.46 269.56 36.46Z"
      />
      <Paths.L50 d="M207.73 25.3C207.89 21.97 210.64 19.35 213.98 19.35H262.16C265.45 19.35 268.18 21.91 268.4 25.19L272.22 83.02C272.7 90.24 266.97 96.35 259.74 96.35H217.43C210.29 96.35 204.59 90.38 204.94 83.24L207.73 25.3Z" />
      <Paths.L50 d="M182.71 46.27L190.67 38.7L238.7 79.59L221.84 96.45L182.71 46.27Z" />
      <Paths.L60
        evenOdd
        d="M204.94 83.16L204.93 83.34C204.59 90.48 210.28 96.45 217.42 96.45H259.73C266.95 96.45 272.66 90.36 272.21 83.16H204.94Z"
      />
      <Paths.L60 d="M205.28 75.29L206.59 52.32L203.97 50.1L197.02 64.31L205.28 75.29Z" />
      <Paths.L50 d="M268.3 23.22C268.3 27.44 254.81 30.85 238.17 30.85C221.53 30.85 208.04 27.43 208.04 23.22C208.04 19.01 221.53 15.59 238.17 15.59C254.81 15.59 268.3 19 268.3 23.22Z" />
      <Paths.L50 d="M260.59 21.89C260.59 16.09 258.28 10.52 254.18 6.41C250.08 2.31 244.51 0 238.7 0C232.89 0 227.33 2.31 223.22 6.41C219.12 10.51 216.81 16.08 216.81 21.89H224.59C224.59 18.15 226.08 14.56 228.72 11.92C231.37 9.27001 234.95 7.79001 238.69 7.79001C242.43 7.79001 246.02 9.28001 248.66 11.92C251.31 14.57 252.79 18.15 252.79 21.89H260.59Z" />
      <Paths.L60 d="M253.42 14.97C254.19 14.61 254.53 13.69 254.11 12.96C252.67 10.45 250.63 8.32 248.17 6.77C245.27 4.95 241.91 4 238.48 4.05C235.06 4.1 231.72 5.13999 228.87 7.03999C226.45 8.65999 224.47 10.84 223.1 13.39C222.7 14.14 223.06 15.05 223.84 15.38C224.62 15.72 225.52 15.35 225.93 14.61C227.06 12.6 228.64 10.88 230.57 9.59999C232.92 8.02999 235.68 7.16999 238.51 7.12999C241.34 7.08999 244.12 7.86999 246.52 9.37999C248.48 10.61 250.11 12.29 251.3 14.26C251.74 14.99 252.65 15.33 253.42 14.97Z" />
      <Paths.L30 d="M177.89 61.76C174.66 61.76 172.05 64.36 172.05 67.57C172.05 70.78 174.66 73.38 177.89 73.38C181.11 73.38 183.73 70.78 183.73 67.57C183.72 64.36 181.11 61.76 177.89 61.76Z" />
      <Paths.L30 d="M151.02 96.78C145.2 96.78 140.46 92.07 140.46 86.27C140.46 80.48 145.2 75.76 151.02 75.76C156.84 75.76 161.58 80.47 161.58 86.27C161.58 92.07 156.85 96.78 151.02 96.78ZM151.02 80.63C147.89 80.63 145.34 83.16 145.34 86.28C145.34 89.39 147.88 91.93 151.02 91.93C154.15 91.93 156.69 89.4 156.69 86.28C156.71 83.16 154.15 80.63 151.02 80.63Z" />
      <Paths.L30 d="M145.73 61.03C148.8 61.03 151.29 58.56 151.29 55.5C151.29 52.44 148.8 49.97 145.73 49.97C142.66 49.97 140.17 52.44 140.17 55.5C140.17 58.55 142.67 61.03 145.73 61.03Z" />
      <Paths.L30 d="M151.07 26.35C150.8 27.94 151.86 29.45 153.44 29.72C155.02 29.99 156.52 28.92 156.79 27.33C157.06 25.74 156 24.23 154.42 23.96C152.84 23.69 151.34 24.76 151.07 26.35Z" />
      <Paths.L30 d="M198.94 23.43C198.67 25.02 199.73 26.53 201.31 26.8C202.89 27.07 204.39 26 204.66 24.41C204.93 22.82 203.87 21.31 202.29 21.04C200.71 20.78 199.21 21.84 198.94 23.43Z" />
      <Paths.L30 d="M49.9302 18.86C49.6602 20.45 50.7202 21.96 52.3002 22.23C53.8802 22.5 55.3802 21.43 55.6502 19.84C55.9202 18.25 54.8602 16.74 53.2802 16.47C51.7002 16.2 50.2002 17.27 49.9302 18.86Z" />
      <Paths.L30 d="M274.83 18.86C274.56 20.45 275.62 21.96 277.2 22.23C278.78 22.5 280.28 21.43 280.55 19.84C280.82 18.25 279.76 16.74 278.18 16.47C276.6 16.2 275.1 17.27 274.83 18.86Z" />
      <Paths.L30 d="M102.37 35.12L97.29 6.88H107.46L102.37 35.12Z" />
      <Paths.L30 d="M109.72 33.99L121.23 15.91L126.67 20.88L109.72 33.99Z" />
      <Paths.L30 d="M93.9001 33.99L83.1601 15.91L78.0801 20.88L93.9001 33.99Z" />
    </Thumbnail>
  );
};

export default Thoughtful;
