import React from 'react';
import PropTypes from 'prop-types';
import { Logo, WordMark } from '@matterapp/matter-ui';

const LogoAndWordMark = ({ inverted }) => (
  <div>
    <Logo size="medium" marginBottom={16} />
    <WordMark size="large" marginBottom={48} inverted={inverted} />
  </div>
);

LogoAndWordMark.propTypes = {
  inverted: PropTypes.bool,
};

LogoAndWordMark.defaultProps = {
  inverted: false,
};

export default LogoAndWordMark;
