import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import getWorkspace from 'graphql-queries/queries/workspace/getWorkspace';
import getCurrentUser from 'graphql-queries/queries/user/getCurrentUser';
import qs from 'qs';

const WORKSPACE_MEMBERS_LIMIT = 50;

export const useWorkspace = () => {
  const params = useParams();
  let workspaceId = params.workspaceId;

  if (!workspaceId) {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    workspaceId = params.workspaceId;
  }

  const { loading: loadingWorkspace, error: workspaceError, data: workspaceData, refetch } = useQuery(getWorkspace, {
    variables: {
      workspaceId,
      limit: WORKSPACE_MEMBERS_LIMIT,
    },
    skip: !workspaceId
  });

  const { loading: loadingUser, error: userError, data: userData } = useQuery(getCurrentUser);

  const currentUser = userData ? userData.currentUser : { pickedSkillDetails: [] };
  const workspace = workspaceData ? workspaceData.workspace : {};

  return {
    loading: loadingUser || loadingWorkspace,
    error: workspaceError || userError,
    currentUser,
    data: workspace,
    workspace,
    refetch
  };
};
