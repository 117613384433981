import React from 'react';

const Icon = () => (
  <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M133.261 68.0967L131.051 66.4132L129.347 60.6816C128.609 58.2405 127.25 56.0383 125.39 54.3078L123.888 52.9014C121.746 50.901 118.812 49.9967 115.926 50.4506L112.729 50.9556L93.5928 52.7916C91.2031 53.0195 89.1404 51.1107 89.1743 48.6922L89.2443 43.9155C89.3732 35.1428 87.6656 26.4393 84.2209 18.3636L84.1363 18.1744C82.3047 13.8744 78.5331 10.7099 73.9971 9.66279L73.8439 9.6305C70.039 8.75483 66.5298 11.9488 67.0241 15.8396L67.2642 17.7603C68.1017 24.3806 67.8074 31.0969 66.3935 37.6082L66.2283 38.3797C65.1696 43.2493 63.4899 47.9616 61.233 52.405L50.4544 73.6379L35.2383 83.3066L48.5747 129.816L69.2625 128.686L81.0588 131.851C86.9583 133.4 93.1242 133.392 98.9775 131.714L125.71 124.049L129.988 122.822C132.151 122.202 133.961 120.692 135.02 118.703L135.054 118.61C136.417 116.069 136.912 113.145 136.479 110.289L135.899 106.468C135.773 105.658 135.938 104.833 136.358 104.133L137.417 102.382C139.489 98.9593 139.911 94.7754 138.568 90.9908L136.379 84.8344L137.35 82.0633C138.262 79.6443 138.284 77.0236 137.569 74.5301L137.547 74.4526C136.835 71.9199 135.377 69.6624 133.261 68.0967Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M44.7887 73.9402L18.8711 81.372C16.7475 81.9809 15.5197 84.196 16.1286 86.3196L31.2932 139.205C31.9021 141.328 34.1173 142.556 36.2408 141.947L62.1584 134.516C64.282 133.907 65.5098 131.692 64.9009 129.568L49.7363 76.6827C49.1274 74.5592 46.9122 73.3313 44.7887 73.9402Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="45.079"
        y1="42.569"
        x2="145.058"
        y2="95.323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE7E" />
        <stop offset="1" stopColor="#EE8D11" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="60.092"
        y1="112.796"
        x2="46.156"
        y2="64.195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#343799" />
        <stop offset="1" stopColor="#4F52B1" />
      </linearGradient>
    </defs>
  </svg>
);

export default Icon;
