import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import {
  toggleSelectedPeerRecommendation,
  purgeSelectedPeerRecommendations,
  selectPeers,
} from '../../reducers';
import withPeerRecommendations from './withPeerRecommendations';
import { withToggle } from '../../hocs';

const mapStateToProps = ({ peerRecommendations }) => ({
  selectedPeerRecommendations: peerRecommendations.selectedPeerRecommendations,
  numSelectedPeerRecommendations: Object.keys(
    peerRecommendations.selectedPeerRecommendations
  ).length,
});

const mapDispatchToProps = (dispatch) => ({
  handleToggleSelectedPeerRecommendations: (selectedPeer) => {
    dispatch(toggleSelectedPeerRecommendation(selectedPeer));
  },
  handlePurgeSelectedPeerRecommendations: () => {
    dispatch(purgeSelectedPeerRecommendations());
  },
  handleSelectPeers: (peers) => {
    dispatch(selectPeers(peers));
  },
});

const withData = compose(
  withPeerRecommendations({
    peerRecommendationFilterFn: (peer) => peer.isFeedbackRecommendation,
  }),
  withToggle({
    initialIsOpen: false,
    stateName: 'isSubmittingPeerRecommendationsForm',
    handleToggleOpenName: 'handleOpenIsSubmittingPeerRecommendationsForm',
    handleToggleCloseName: 'handleCloseIsSubmittingPeerRecommendationsForm',
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(
    ({
      selectedPeerRecommendations,
      peerRecommendations,
      handlePurgeSelectedPeerRecommendations,
      handleRequestFeedbackFromMultiplePeersViaPeerRecommendations,
      handleOpenIsSubmittingPeerRecommendationsForm,
      handleCloseIsSubmittingPeerRecommendationsForm,
    }) => ({
      handleRequestFeedbackFromSelectedPeers: async ({
        onSuccess,
        onFailure,
        invitationSource,
      }) => {
        try {
          handleOpenIsSubmittingPeerRecommendationsForm();
          const selectedPeers = peerRecommendations.filter(
            ({ email }) => selectedPeerRecommendations[email]
          );
          await handleRequestFeedbackFromMultiplePeersViaPeerRecommendations({
            peers: selectedPeers,
            source: invitationSource,
          });
          await handlePurgeSelectedPeerRecommendations();
          handleCloseIsSubmittingPeerRecommendationsForm();
          await onSuccess();
        } catch (error) {
          handleCloseIsSubmittingPeerRecommendationsForm();
          console.log({ error });
          await onFailure();
        }
      },
    })
  )
);

export default withData;
