import React from 'react';
import SVG, { Path } from '../../SVG';

const ProfileMenuIcon = (props) => {
  return (
    <SVG height={32} width={32} viewBox={'0 0 32 32'} {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9,11.3l7.5,7.5c0.5,0.5,0.5,1.4,0,1.9l0,0c-0.5,0.5-1.4,0.5-1.9,0l0,0L16,14.1l-6.6,6.6c-0.5,0.5-1.4,0.5-1.9,0l0,0c-0.5-0.5-0.5-1.4,0-1.9l0,0l7.5-7.5c0.3-0.3,0.6-0.4,0.9-0.4S16.7,11,16.9,11.3L16.9,11.3z"
      />
    </SVG>
  );
};

export default ProfileMenuIcon;
