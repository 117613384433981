import styled from 'styled-components';
import { Button, Modal, Peer } from '@matterapp/matter-ui';
import { AllowanceBlock } from '../DefaultAllowanceSection/styles';
import { Group } from '../../sharedConsts';
import theme from '@matterapp/matter-theme';

export const StyledGroup = styled(Group)(() => ({
  marginBottom: theme.spacing.double,
  paddingBottom: theme.spacing.double
}));

export const CustomAllowanceWrapper = styled(AllowanceBlock)({
  margin: 0
});

export const AddAllowanceButton = styled(Button)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  fontSize: theme.fontSize.base,
  lineHeight: theme.fontSize.base,
  padding: theme.spacing.half,
  minHeight: 0
}));

export const CustomAllowanceBlock = styled('div')(() => ({
  position: 'relative'
}));

export const StyledModalBody = styled(Modal.Body)(() => ({
  padding: theme.spacing.double,
  overflow: 'visible',
  '& > div:first-child': {
    overflowY: 'visible'
  },
  h5: {
    color: theme.colors.blacks[80],
    fontSize: theme.fontSize.base,
    fontWeight: theme.fontWeight.medium,
    marginBottom: '5px'
  }
}));

export const PeersWrapper = styled('div')(() => ({
  marginTop: theme.spacing.single
}));

export const PeerListItem = styled(Peer.ListItem)({
  position: 'relative',
  margin: 0,
  width: '100%'
});

export const RemoveButton = styled(Button)(() => ({
  position: 'absolute',
  top: '30%',
  right: 0,
  fontSize: theme.fontSize.XS,
  lineHeight: theme.fontSize.XS,
  padding: theme.spacing.half,
  minHeight: 0,
  color: theme.colors.blacks[50]
}));
