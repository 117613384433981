import React from 'react';
import PropTypes from 'prop-types';
import CheckmarkToggle from './CheckmarkToggle';

const DEFAULT_UNCHECKED_ICON = 'addSmall';
const DEFAULT_CHECKMARK_SIZE = 24;

const Checkmark = ({
  className,
  name,
  disabled,
  checked,
  hidden,
  hiddenTransition,
  onChange,
  size,
}) => (
  <CheckmarkToggle
    className={className}
    checked={checked}
    disabled={disabled}
    iconUncheckedName={DEFAULT_UNCHECKED_ICON}
    hideBorder
    isHidden={hidden}
    isTransitionHidden={hiddenTransition}
    name={name}
    onChange={onChange}
    size={size}
  />
);

Checkmark.defaultProps = {
  checked: false,
  hidden: false,
  hiddenTransition: false,
  name: '',
  disabled: false,
  onChange: () => null,
  size: DEFAULT_CHECKMARK_SIZE,
};

Checkmark.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  hiddenTransition: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.number,
};

export default Checkmark;
