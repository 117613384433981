import React, { forwardRef } from 'react';
import SVG, { Path } from '../../SVG';

const HEIGHT = 240;
const WIDTH = 600;

const Blobs1 = forwardRef((props, forwardedRef) => {
  const ref = forwardedRef || {};
  return (
    <SVG
      {...props}
      preserveAspectRatio="xMidYMid meet"
      width={WIDTH}
      height={HEIGHT}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
    >
      <Path
        ref={ref.ref1}
        d="M40.45,10.37c3.45,8.96,9.11,14.57,18.26,18.13l0,0c20.45,14.61,15.31,26.91-0.47,42.14
          c-1.06,2.8-3.22,5.54-2.71,9.06c0.01,3.86-5.21,5.71-4.95,9.57l0,0c-3.41,2.6-9.21,3.56-8.37,10.28c0.3,4.07-4.67,6.36-3.47,10.55
          l0,0c8.13,10.66-3.43,26.89-11.09,35.04c-5.49,4.4-11.78,7.47-17.67,11.51c-3.28,0.22-6.45-1.07-9.96-0.98c0-46.96,0-93.93,0-140.89
          c4.73-2.75,11.57,4.29,15.31,1.78c0.65-5.12,1.38-11.15,3.19-16.24C27.58-0.1,36.71,0.51,40.45,10.37z"
      />
      <Path
        ref={ref.ref2}
        d="M74.76,10.79C74.76,10.79,74.76,10.79,74.76,10.79C74.76,10.79,74.75,10.79,74.76,10.79L74.76,10.79z M143.25,0
          H63.31c2.65,4.15,7.43,7.66,11.44,10.79c7.79,6.22,19.41,3.82,26.35,10.14c4.6-2.61,9.35,2.25,13.12,1.38
          c4.48-1.33,7.84,4.66,12.34,1.96c6.6-4.76,17.48-0.7,22.68-6.88c0.99-2.36-0.89-5,2.72-5.15c2.16-3.25-0.16-8.88-2.48-11.99
          C147.41,0.16,145.34,0.08,143.25,0z"
      />
      <Path
        ref={ref.ref3}
        d="M126.57,81.96C126.56,81.96,126.56,81.97,126.57,81.96C126.56,81.97,126.56,81.97,126.57,81.96L126.57,81.96z
          M58.06,240h126.24c0.08,0,0.15,0,0.23,0c6.26-16.6,5.23-25.21,0-44.1c-4.52-15.01-4.75-30.53-8.38-45.82
          c-7.74-26.77-7.54-77.43-49.59-68.11c-4.06,3.39-4.41,11.87-10.86,12.25c-10.23,3.19-21.15,5.49-32.31,6.37l0,0
          c-13.07,5.69-16.98,20.34-24.89,31.04c-0.78,1.83,2.43,0.02,2.68,1.3c1.42,3.79-1.61,7.06-0.23,10.75
          c9.26,13.1,14.59,6.86,20.89,12.88c-1.15,1.45-4.71,1.27-4.12,4.54c-1.21,3.01-8.13-0.96-14.06,1.96
          c-8.94-0.51-13.48,6.48-14.08,14.2c0.27,5.71,10.69,13.66,1.25,14.96l0,0c-3.08,0.17-6.66,1.14-8.16-2.2
          c-4.07,0.63-8.31,6-5.64,10.52c4.43,13.98,12.08,27.41,20.49,39.45C57.69,239.99,57.88,240,58.06,240z"
      />
      <Path
        ref={ref.ref4}
        d="M386.03,0c3,6.28-1.78,9.84-4.71,14.46c-3.25,13.39-3.7,27.57-9.97,40.15c-4.98,15.6-28.85,40.46-43.48,23.39
          c-5.28-1.04-8.16,5.39-13.58,4.42c-5.55,0.58-10.23,2.5-15.51,3.19c-9.15,9.05-5.49,0.69-17.29,5.86c-3.43,0.37-6.81,1.61-9.87,2.93
          c-1.25,1.84-2.32,3.96-5.17,3.45c-3.16,1.2-5.84-0.82-8.64-0.49c-3.32,0.59-5.73,1.75-8.16,3.43
          c-17.69,1.88-38.98-26.78-49.07-39.69l0,0c-10.76-12.91-5.12-28.73,12.57-27.7c4.65-2.91,15.27,3.85,17.25-1.21
          c-2.33-6.28-8.49-15.15-4.9-21.31C223.9,5.7,217.52,4.02,214.22,0h33.71c1.47,0.53,1.18,1.41-1.96,2.84
          c3.45,1.69,9.02,2.7,12.64,6.3c3.95,1.53,6.26-5.6,5.68-9.14H386.03z"
      />
      <Path
        ref={ref.ref5}
        d="M318.24,154.69c1.35,2.76,6.27,12.82,2.48,12.97c-3.09-4.08-1.91,0.83-2.47,2.94c0.41,7.38-1.6,8.08-8.15,8.1
          c-6.66,3.93-10.7,3.17-17.42-1.9c-4.14-3.39-4.83,3.15-7.73,1.88c2.08-2.7-0.65-2.21-1.41-4.19c1.35-1.71,7.01,2.86,4.87-1.92
          c-21.26-5.5,0.53,1.88-23.94,7.35c-16.96-5.22-39.62-5.51-52.78-18.37c-5.95-6.68,2.11-14.82-0.76-22.05
          c-1.29-4.93,2.61-7.8,3.72-12c-3.38-1.04,1.05-5.99,3.63-7.16c0.2-2.43,3.62-0.04,4.98-2.15c13.4,0.72,26.24-9,40.47-8.84
          C290.05,103.24,307.8,132.83,318.24,154.69z"
      />
      <Path
        ref={ref.ref6}
        d="M303.68,230.68c-1.05,3.43,5.32,3.75,1.98,9.32c-23.44,0-46.87,0-70.31,0c-7.6-9.99-11.61-20.13,3.22-26.19
          C249.69,209.41,310.12,213.11,303.68,230.68z"
      />
      <Path
        ref={ref.ref7}
        d="M579.25,28.17c-0.51,3.69,4.35,10.4-2.17,11.01c-16.12,7.89-31.62-11.02-44.1-20.56
          c6.35,14.33,0.42,7.22-1.13,13.83c0.2,1.23-0.23,1.9-1.6,2.34c-6,1.32-7.99,0.28-5.85,7.32c-8.66-5.09-0.13,9.8-3.95,10.33
          c-1.44,0.32-2.7-0.73-3.92-0.72c-2.95,2.84-7.2,2.45-11.05,2.44c-11.61,4.45-15.55-15.8-26.89-10.79
          C461.43,27.97,441.79,15,424.33,0c47.86,0,95.72,0,143.58,0C569.82,5.54,585.6,26.92,579.25,28.17z"
      />
      <Path
        ref={ref.ref8}
        d="M425.07,98.09c1.48-2.27,5.39-2.38,5.44,0.95c-3.09,0.01-4.62-0.92-2,2.23c2.05,2.13-1.26,3.47,1.24,5.13
          c-0.74,1.81-2.82,3.58-4.19,5.14c-2.79,3.35-5.06,7.44-9.98,7.29c2.94,2.11-2.22,6.66-3.85,8.88c-5.53,1.31-10.37-5.59-5.9-9.3
          c-3.87-5.51,1.53-19.4,9.87-18.11C418.94,98.46,421.36,94.84,425.07,98.09z M404.59,127.72c0.15,0.7,1.9,1.28,0.49,1.71
          c-1.97,0.16-4.23-0.12-3.2-2.69c0.16-1.24,1.06-0.16,1.72-0.24C404.3,126.61,404.35,127.24,404.59,127.72z"
      />
      <Path
        ref={ref.ref9}
        d="M495.14,129.92c12.66,27.43-1.38,82.22-39.24,64.2c-2.67,0.62-14.55-3.45-7.9-2.93c1.91,0.73,1.89,0.69,1-1.25
          c-25.7-4.02-13.05-8.85-24.92-11.01c-3.4,5.62-8.25,0.49-12.59-0.24c-2.71-2.89-5.48-2.52-9.12-4.91c-6.55,2.4-1.05-23.14,1-26.7
          c5.97-8.76,13.95-19.67,24.66-21.81c0.37,1.35-2.83,4.2,0.24,4.16c3.83-0.36,3.02,7.67,6.65,1.66c3.44,3.32-3.1,8.98-1.49,13.29
          c3.25,8.74,9.06-10.2,10.38-13.97c4.48-4.87,9.44-11.56,10.38-19.66c1.18-6.53,2.47-13.55,7.89-16.37c-3.36-1.69,1.4-3.72,1.57-6.09
          c1.75-5.35,2.46,2.37,4.79,1.07c11.52-1.04,10.26,10.37,10.9,18.77C488,112.73,492.71,120.04,495.14,129.92z"
      />
      <Path
        ref={ref.ref10}
        d="M579.5,216.66L579.5,216.66c-1.31-1.3-0.49-3.24,0.03-4.73c-0.7-1.7-4.82,3.98-6.95,4.72
          c-8.77,5.4-3.35-4.41-18.74,2.48c-1.69-4.7-0.29-1.57-4.69-3.44c-1.74-2.36-3.01-0.26-4.95-0.41c-0.63-1.16,1-1.72,1.49-2.54
          c-2.65-2.77-2.57-6.36-2.22-10.04c-0.16-16.08,11.1-34.15,3.58-48.34c0.6-10.58,2.6-20.69,6.79-30.08c0.12-3.18,2.02-4.17,4.32-5.5
          c-2.64-2.2-0.51-5.75,2.85-6.01c6.18-0.94,5.8-11.44,11.09-12.99c3.81,0.94,2.91-5.64,6.65-5.47c4.4-5.92,5.79,2.68,11.26,1.09
          c3.02-1.34,1.38,2.31,3.55,2.91c5.65,0.64,5.61,9.2,5.69,13.72c2.31,9.2-6.06,67.24,0.73,35.77c0,30.18,0,62.01,0.02,92.19h-14.81
          c-2.21-0.72-3.46-1.84-2.48-3.49C584.97,224.62,589.12,224.28,579.5,216.66z"
      />
    </SVG>
  );
});

export default Blobs1;
