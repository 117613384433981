import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.51 6.75C7.53798 6.75 6.75 7.53798 6.75 8.51V15.49C6.75 16.462 7.53798 17.25 8.51 17.25H15.49C16.462 17.25 17.25 16.462 17.25 15.49V8.51C17.25 7.53798 16.462 6.75 15.49 6.75H8.51ZM7.75 8.51C7.75 8.09026 8.09026 7.75 8.51 7.75H15.49C15.9097 7.75 16.25 8.09026 16.25 8.51V15.49C16.25 15.9097 15.9097 16.25 15.49 16.25H8.51C8.09026 16.25 7.75 15.9097 7.75 15.49V8.51ZM14.1636 10.5436C14.3588 10.3483 14.3588 10.0317 14.1636 9.83645C13.9683 9.64119 13.6517 9.64119 13.4565 9.83645L9.83645 13.4564C9.64119 13.6517 9.64119 13.9683 9.83645 14.1635C10.0317 14.3588 10.3483 14.3588 10.5436 14.1636L14.1636 10.5436Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;