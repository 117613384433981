import styled from 'styled-components';
import Button from './Default';
import TextAnimated from './TextAnimated';
import theme from '@matterapp/matter-theme';

const getColorStyles = ({ color, defaultTextColor, disabled }) => {
  if (!disabled) {
    if (color === 'default' || !color) {
      return `
        &:not(:hover) {
          color: ${defaultTextColor || 'inherit'};
        }
        &:active {
          background: ${theme.colors.blues[5]};
        }
        @media (hover: hover) {
          &:hover {
            background: ${theme.colors.blues[5]};
          }
        }
      `;
    }
    return `
      &:not(:hover) {
        color: ${defaultTextColor || 'inherit'};
      }
    `;
  }
};

const TextButton = styled(Button)`
  &,
  &:hover,
  &:active {
    border-color: transparent;
  }
  ${getColorStyles}
`;

export default TextButton;
TextButton.Animated = TextAnimated;