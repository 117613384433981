import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { WordMark } from '@matterapp/matter-ui';
import SlackAuthButton from '../../../modules/auth/components/SlackAuthButton';
import TeamsAuthButton from '../../../modules/auth/components/TeamsAuthButton';
import { withPageViewTracking } from '../../../hocs';

const BUTTON_CLASSNAME = 'slack-sign-up';

const Container = styled.div`
  margin-top: ${theme.spacing.single};
  text-align: center;
  height: 100%;
`;

const Content = styled.div`
  position: absolute;
  text-align: center;
  top: 25%;
  width: 100%;
  // We have an href, but style it like standard Button
  & a {
    display: inline-block;
    line-height: 30px;
    vertical-align: middle;
  }
  & a:hover {
    color: ${theme.colors.blacks[60]};
    text-decoration: none;
  }
  @media screen and (max-width: ${theme.breakpoints[3]}) {
    top: 25%;
  }
`;

const Headline = styled.div`
  font-size: 48px; // Do we need an XL in theme?
  font-weight: ${theme.fontWeight.bold};
  line-height: 64px;
  margin-bottom: ${theme.spacing.tripleAndHalf};
  @media screen and (max-width: ${theme.breakpoints[3]}) {
    font-size: ${theme.fontSize.XL};
    line-height: 48px;
    padding: 0 24px;
  }
`;

const NavLink = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  font-size: 36px;
  font-weight: ${theme.fontWeight.bold};
  & a {
    margin-top: 24px;
  }
  .${BUTTON_CLASSNAME} {
    margin-bottom: 10px;
    width: 350px;
  }
`;

function TestAuth() {
  return (
    <Container>
      <a href="/sign-up">
        <WordMark />
      </a>
      <Content>
        <Headline>
          This is an auth page for testing only.
        </Headline>
        (In Production, this would be the Matter corporate site pages.)
        <NavLink>
          <SlackAuthButton
            buttonText={'Sign up with Slack'}
            iconName={'slack'}
            className={BUTTON_CLASSNAME}
          />
          <TeamsAuthButton
            buttonText={'Sign up with Teams'}
            className={BUTTON_CLASSNAME}
          />
          <a href="/login" target='_blank'>Visit Sign In Page</a>
        </NavLink>
      </Content>
    </Container>
  );
}

export default withPageViewTracking({
  pageTitle: 'Test Auth',
})(TestAuth);


