import styled from 'styled-components';
import { border, colors, font, lineHeight, spacing } from '@matterapp/matter-theme';
import { IconNew as Icon, Button, Checkbox } from '@matterapp/matter-ui';

export const BillingModalContainer = styled.div`
  width: 100%;
  padding-bottom: 24px;
`;

export const CheckmarkIcon = styled(Icon).attrs({ name: 'checkmarkSuccess' })``;

export const BillingFooterContainer = styled.div`
  margin-bottom: ${spacing.triple};
`;

export const BillingFooterHeader = styled.div`
  color: ${colors.blacks[90]};
  line-height: ${lineHeight.L};
  font-size: ${font.size.S};
  font-weight: ${font.weight.medium};
`;

export const BillingFooterContent = styled.div`
  color: ${colors.blacks[60]};
  line-height: ${lineHeight.M};
  font-size: ${font.size.base};
`;

export const LoadingContainer = styled.div`
  height: 50px;
  position: relative;
  margin-bottom: ${spacing.double}
`;

const headerStyle = `
  font-size: ${font.size.S};
  line-height: ${lineHeight.L};
  font-weight: ${font.weight.bold};
  text-align: left;
  color: ${colors.black[90]};
  margin-bottom: ${spacing.singleAndQuarter};
`;

export const SectionHeader = styled.div`
  ${headerStyle}
`;

export const PaymentHeader = styled.div`
  ${headerStyle}
  margin-top: ${spacing.singleAndHalf};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PricingList = styled(Checkbox.RadioList)`
  button {
    height: auto;
    font-size: ${font.size.S2} !important;
    font-weight: ${font.weight.semiBold};
    line-height: ${lineHeight.M};
    color: ${colors.blacks[70]};
    margin-top: 8px;
  }
  button > div {
    color: ${colors.blacks[70]} !important;
    font-size: ${font.size.S2} !important;
  }
`;

export const PriceDetail = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${colors.blues[1]};
  padding: ${spacing.single};
  font-size: ${font.size.S};
  line-height: ${lineHeight.double};
  margin-bottom: 1px;
`;

export const PlanBreakDown = styled.div`
  display: flex;
  align-items: center;
`;

export const PricingDetailAmount = styled.div`
  font-weight: ${font.weight.bold};
`;

export const PromoCodeHeader = styled.div`
  display: flex;
  color: ${colors.blacks[70]};
  font-size: ${font.size.S2};
  font-weight: ${font.weight.semiBold};
  line-height: ${lineHeight.M};  
  cursor: pointer;
  width: fit-content;
  ${({ isOpen }) =>
  isOpen && `
    margin-bottom: ${spacing.single};
  `};
`;

export const KarotContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${spacing.single};
  svg path {
    fill: #000;
  }

  transition: all 0.4s ease;

  ${({ isOpen }) =>
  isOpen && `
    transform: rotate(180deg);
  `};
`;

export const PromoInputContainer = styled.div`
  display: flex;
  ${({ isOpen }) => isOpen ? `
    opacity: 1;
  ` : `
    opacity: 0;
  `}
`;

export const ApplyButton = styled(Button.Primary.Vivid)`
  font-weight: ${font.weight.medium};
  margin-left: ${spacing.half};
  padding-left: ${spacing.single};
  padding-right: ${spacing.single};
`;

export const PoweredByStripeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 0;
  margin-right:  ${spacing.threeQuarters};
  margin-top:  ${spacing.quarter};
`;

export const ErrorIcon = styled(Icon).attrs(() => ({
  name: 'stateError',
}))``;

export const SuccessIcon = styled(Icon).attrs(() => ({
  name: 'stateSuccess',
}))``;

export const VerifyIndicatorContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  max-width: ${spacing.quad};

  & svg {
    height: ${spacing.singleAndHalf};
    width: ${spacing.singleAndHalf};
  }
`;

export const FormInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const CardElementContainer = styled.div`
  padding: 11px;
  border: 1px solid ${colors.blacks[40]};
  border-radius: ${border.radius.S};
  ${({ isInvalid }) => isInvalid ? `
    border-color: ${colors.reds[40]};
  ` : ''}
`;

export const ErrorMessage = styled.div`
  margin-top: ${spacing.half};
  color: ${colors.reds[40]};
`;

export const SubmitButton = styled(Button.Primary.Vivid)`
  width: 100%;
  margin: ${spacing.single} 0;
  font-weight: ${font.weight.medium};
`;

export const ContactSalesButton = styled(Button).attrs(() => ({
  content: 'Contact Sales',
  size: Button.buttonSizes.S,
}))``;