import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Auth, Page } from '@matterapp/matter-ui';
import PhoneNumberOrEmailForm from '../forms/PhoneNumberOrEmailForm';
import { Resources } from '@matterapp/routing';
import GoogleAuthButton from '../GoogleAuthButton';
import SlackAuthButton from '../SlackAuthButton';
import TeamsAuthButton from '../TeamsAuthButton';
import { SIGNUP_MEANS, ENV_VARS } from 'app-consts';
class RequestVerificationPage extends React.Component {

  async handleGoogleSignin(response) {
    const { handleGoogleYoloLogin, history } = this.props;
    
    await handleGoogleYoloLogin({
      googleYoloToken: response.tokenId,
    }, SIGNUP_MEANS.GOOGLE_SIGN_IN);
    // Quick fix to remove unmounting warning in console
    // TODO: find better way of handling the warning
    setImmediate(() => history.push(Resources.authLoginGateway.path()));
  }

  renderSocialSigninButtons() {
    return [
      <GoogleAuthButton
        key='google'
        handleButtonClick={this.handleGoogleSignin.bind(this)}
        buttonText={'Sign in with Google'}
        iconName={'google'}
      />,
      <SlackAuthButton
        key='slack'
        buttonText={'Sign in with Slack'}
        iconName={'slack'}
      />,
      <TeamsAuthButton
        key='teams'
        buttonText={'Sign in with Teams'}
      />
    ];
  }

  renderSigninForm() {
    const {
      handleSubmit,
      invalid,
      submitting,
      pristine,
      component,
    } = this.props;
    if (component) { return component(this.props); }

    return (
      <PhoneNumberOrEmailForm
        handleSubmit={handleSubmit}
        invalid={invalid}
        submitting={submitting}
        pristine={pristine}
        shortInline
        marketing
      />
    );
  }

  renderLoginMainPage() {
    return (
      <Page
        layout={'AuthPage'}
        content={
          <Auth.Login
            socialSigninButtons={this.renderSocialSigninButtons()}
            signinForm={this.renderSigninForm()}
            path={ENV_VARS.SIGN_UP_PATH}
            landingPagePath={Resources.landingPage.url()}
          />
        }
      />
    );
  }

  render() {
    /**
     * Check if current path is the feedback auth link. If so, render signin form
     * to go directly to phone verification step if user is not logged in.
     */
    const authPathPattern = Resources.responseFlowRequestVerification.pattern;
    const authPathToMatch = authPathPattern.split(':')[0];
    const isAuthPath = this.props.location.pathname.startsWith(authPathToMatch);
    return (
      <>
        <Helmet>
          <title>Sign In</title>
          <meta
            name="description"
            content="Welcome back to Matter. Sign in now to read your feedback or provide your peers with feedback."
          />
        </Helmet>
        {isAuthPath ? this.renderSigninForm() : this.renderLoginMainPage()}
      </>
    );
  }
}

RequestVerificationPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  component: PropTypes.func,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  handleGoogleYoloLogin: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  currentUserStatus: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
  }),
};

RequestVerificationPage.defaultProps = {
  component: null,
};

export default RequestVerificationPage;
