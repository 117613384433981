import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PageContent from '../../../PageContent';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  max-width: 936px;
  min-width: 375px;
  min-height: 500px;
  padding-right: 24px;
  padding-left: 24px;
  margin-top: 32px;
  margin-bottom: 96px;
  margin-left: auto;
  margin-right: auto;

  ${() => theme.media.medium`
    padding-right: 36px;
    margin-top: 64px;
    margin-bottom: 164px;
  `}
`;

const NavBox = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 32px;

  ${() => theme.media.medium`
    width: 224px;
    margin-bottom: 0;
  `}
`;

const ChildrenBox = styled.div`
  flex: 1;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  ${() => theme.media.medium`
    padding-left: 32px;
    padding-right: 32px;
  `}
`;

const TwoColumnFixedLeft = ({
  children,
  containerNavigation,
  header,
  footer,
}) => (
  <React.Fragment>
    {header}
    <PageContent>
      <Container
        style={{
          textAlign: 'left',
          minHeight: 'calc(100vh - 389px)', // Keep the footer at the bottom
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <NavBox>{containerNavigation}</NavBox>
          <ChildrenBox>{children}</ChildrenBox>
        </div>
      </Container>
    </PageContent>

    {footer}
  </React.Fragment>
);

TwoColumnFixedLeft.propTypes = {
  containerNavigation: PropTypes.node,
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
};

TwoColumnFixedLeft.defaultProps = {
  containerNavigation: null,
  children: null,
  header: null,
  footer: null,
};

export default TwoColumnFixedLeft;
