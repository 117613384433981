import styled from 'styled-components';
import { DEFAULT_IMAGE_SIZE } from '../styles';

export const MainContainer = styled.div`
  line-height: 0;
  overflow: hidden;
`;

export const Container = styled.div`
  display: inline-flex;
  width: ${(props) => props.size || DEFAULT_IMAGE_SIZE}px;
  height: ${(props) => props.size || DEFAULT_IMAGE_SIZE}px;
  border-radius: ${({ isCircle, size }) => isCircle ? `${size || DEFAULT_IMAGE_SIZE}px` : '0'};
  overflow: hidden;
  position: relative;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`;

export const Wrapper = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  overflow: hidden;
`;
