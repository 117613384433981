import React from 'react';
import { Default, WelcomeCTA } from './variants';
import { bannerTypes } from './propTypes';

const HeroBanner = (props) => {
  return <Default {...props} />;
};

HeroBanner.bannerTypes = bannerTypes;
HeroBanner.Default = Default;
HeroBanner.WelcomeCTA = WelcomeCTA;
export default HeroBanner;
