import React from 'react';
import Base from '../../../svgBase';

const DEFAULT_HEIGHT = 17;
const DEFAULT_WIDTH = 13;
const PATH = `M6.0752 16.2187C9.80176 16.2187 12.2861 13.875 12.2861 10.5781C12.2861 7.53125 10.1611 5.35937 7.28613 5.35937C5.77051 5.35937 4.5127 6.00781 3.98145 6.89062H3.8877L4.25488 3.11719H11.3408V-4.76837e-07H1.0752L0.379883 8.625L3.79395 9.26562C4.22363 8.54688 5.11426 8.10156 6.05176 8.10937C7.52051 8.10937 8.56738 9.14844 8.55957 10.6875C8.56738 12.2188 7.53613 13.25 6.0752 13.25C4.83301 13.25 3.80957 12.4766 3.75488 11.2969H0.00488286C0.0517578 14.1719 2.5752 16.2187 6.0752 16.2187Z`;

const Five = (props) => (
  <Base height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
);

export default Five;