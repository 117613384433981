import React from 'react';
import PropTypes from 'prop-types';
import { ButtonNew as Button } from '../../../Button';
import Container from '../Container';
import Heading from '../Heading';
import { LeftRight } from '../../../Structural';
import {
  sizePropType,
  SIZE,
} from '../size';

const Action = ({
  title,
  actionLabel,
  onActionClick,
  size,
  className,
}) => (
  <Container className={className}>
    <LeftRight
      left={(
        <Heading size={size}>
          {title}
        </Heading>
      )}
      right={(
        <Button
          link
          onClick={onActionClick}
        >
          {actionLabel}
        </Button>
      )}
    />
  </Container>
);

Action.defaultProps = {
  size: SIZE.MEDIUM,
};

Action.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  actionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onActionClick: PropTypes.func.isRequired,
  size: sizePropType,
  className: PropTypes.string,
};

export default Action;
