import React from 'react';
import { SVG, Path } from './consts';

const RewardsStore = (props) => (
  <SVG {...props}>
    <Path
      d="M8.75 4H8.81875C9.81562 4 10.7406 4.52844 11.2469 5.38906L12 6.67062L12.7531 5.38906C13.2594 4.52844 14.1844 4 15.1812 
      4H15.25C16.7688 4 18 5.23125 18 6.75C18 7.2 17.8906 7.59687 17.7 8H19C19.5531 8 20 8.44688 20 9V11C20 11.5531 19.5531 12 
      19 12H5C4.44781 12 4 11.5531 4 11V9C4 8.44688 4.44781 8 5 8H6.3C6.10812 7.59687 6 7.2 6 6.75C6 5.23125 7.23125 4 8.75 
      4ZM9.95312 6.14937C9.71562 5.74719 9.28437 5.5 8.81875 5.5H8.75C8.05937 5.5 7.5 6.05969 7.5 6.75C7.5 7.44062 8.05937 8 
      8.75 8H11.0406L9.95312 6.14937ZM15.25 5.5H15.1812C14.7156 5.5 14.2844 5.74719 14.0469 6.14937L12.9594 8H15.25C15.9406 8 
      16.5 7.44062 16.5 6.75C16.5 6.05969 15.9406 5.5 15.25 5.5ZM5 13H11V20H6.5C5.67156 20 5 19.3281 5 18.5V13ZM13 20V13H19V18.5C19 
      19.3281 18.3281 20 17.5 20H13Z"
    />
  </SVG>
);

export default RewardsStore;