import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../Tooltip';
import { COMMENTS_HEADER, NO_COMMENT_HEADER } from './consts';
import CommentsIcon from './CommentsIcon';
import {
  CommentButtonNumber,
  CommentButtonContainer
} from './styles';

const TriggerComponent = React.forwardRef((props, ref) => (
  <div ref={ref} onClick={props.onClick}>
    {props.children}
  </div>
));

const CommentsButton = (props) => {
  const { isActive, numberOfComments, onClick } = props;
  const [isOpen, setIsOpen] = useState(false);

  const tooltipContent = numberOfComments > 0 ? COMMENTS_HEADER : NO_COMMENT_HEADER;
  const commentNumber = numberOfComments == 0 ? '' : numberOfComments;
  return (
    <CommentButtonContainer
      isActive={isActive}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onClick={onClick}
    >
      <Tooltip
        content={tooltipContent}
        isOpen={isOpen}
        TriggerComponent={TriggerComponent}
      >
        <CommentsIcon />
      </Tooltip>
      <CommentButtonNumber>{commentNumber}</CommentButtonNumber>
    </CommentButtonContainer>
  );
};

CommentsButton.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CommentsButton;
