import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../components/Typography/Text';

const HeaderAndSubheader = ({
  light,
  headerText,
  subHeaderText,
}) => {
  const color = light ? "#2E2E33" : 'white';
  return (
    <React.Fragment>
      <Text
        color={color}
        textStyle={500}
        fontSize={22}
        textAlign="center"
        block
      >
        {headerText}
      </Text>
      {
        subHeaderText &&
        <Text
          color={color}
          textStyle={500}
          textAlign="center"
          block
        >
          {subHeaderText}
        </Text>
      }
    </React.Fragment>
  );  
};
  
HeaderAndSubheader.defaultProps = {
  subHeaderText: null,
};

HeaderAndSubheader.propTypes = {
  headerText: PropTypes.node.isRequired,
  subHeaderText: PropTypes.string,
};

export default HeaderAndSubheader;
