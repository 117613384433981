import React from 'react';
import Animation from '../../Animation';
import Image from './Image';
import Cube, { SQUARE_SIZE } from './Cube';
import * as C from './classNames';

export default class Impressive extends Animation {
  baseClass = C.BASE;

  moveBackground = (initialize) => {
    const tl = this.getDefaultTimeline();
    const BACKGROUND = this.getElement(C.BACKGROUND);
    const CIRCLE_LEFT_1 = this.getElement(C.CIRCLE.LEFT_1);
    const CIRCLE_LEFT_2 = this.getElement(C.CIRCLE.LEFT_2);
    const CIRCLE_TOP = this.getElement(C.CIRCLE.TOP);
    const RING_LEFT = this.getElement(C.RING.LEFT);
    const RING_RIGHT = this.getElement(C.RING.RIGHT);
    const PLUS = this.getElement(C.PLUS);

    const resetProps = { x: 0, y: 0, opacity: 1, duration: 0.6 };

    tl.to(
      BACKGROUND,
      { opacity: 0, y: 40, duration: initialize ? 0 : 0.3 },
      '<'
    );
    tl.to(CIRCLE_LEFT_1, { x: 70, y: -20 }, '>');
    tl.to(CIRCLE_LEFT_2, { x: 38, y: -16 }, '<');
    tl.to(CIRCLE_TOP, { x: -28, y: 30 }, '<');
    tl.to(RING_LEFT, { x: 68, y: -16 }, '<');
    tl.to(RING_RIGHT, { x: -40, y: 30 }, '<');
    tl.to(PLUS, { x: 50, y: 20, rotate: 360 }, '<');
    tl.to(BACKGROUND, { y: 0 }, '<');

    if (!initialize) {
      tl.to(BACKGROUND, resetProps, '>1.8');
      tl.to(CIRCLE_LEFT_1, resetProps, '<');
      tl.to(CIRCLE_LEFT_2, resetProps, '<');
      tl.to(CIRCLE_TOP, resetProps, '<');
      tl.to(RING_LEFT, resetProps, '<');
      tl.to(RING_RIGHT, resetProps, '<');
      tl.to(PLUS, { ...resetProps, rotate: 0 }, '<');
    }

    return tl;
  };

  moveCube = (initialize) => {
    const tl = this.getDefaultTimeline();
    const SECTION_1 = this.getElement(C.CUBE_3D.SECTION_1);
    const SECTION_2 = this.getElement(C.CUBE_3D.SECTION_2);
    const CUBE_3D = this.getElement(C.CUBE_3D.SHAPE, true);
    const CUBE_2D = this.getElement(C.CUBE_2D);
    const CUBE_2D_COVER = this.getElement(C.CUBE_2D_COVER);

    const getCubeProps = (add = 0) => ({
      rotateX: 56 + add,
      rotateY: 320 - add,
      rotate: 8 + add,
    });
    const finishedCubeProps = getCubeProps(0);
    const midCubeProps = getCubeProps(100);
    const startCubeProps = getCubeProps(200);

    const translateY = SQUARE_SIZE;
    const duration = 0.9;

    tl.to(CUBE_3D, { opacity: 0, ...startCubeProps }, '<');
    tl.to(CUBE_2D, { y: 80, opacity: 0, duration: initialize ? 0 : 0.3 }, '<');
    tl.to(CUBE_2D, { y: 0 }, '>');
    tl.to(CUBE_2D_COVER, { opacity: 0.8, scale: 1 }, '>');
    tl.to(CUBE_3D, { opacity: 1, x: 100, y: -40 }, '>');

    tl.to(SECTION_1, { rotateY: 180 }, '<');
    tl.to(SECTION_2, { translateY, rotateY: -180 }, '<');

    if (!initialize) {
      tl.to(CUBE_3D, { x: 0, y: 0, duration: duration + 0.5 }, 'cube');
      tl.to(CUBE_3D, { ...midCubeProps, duration }, '<');
      tl.to(CUBE_3D, { ...finishedCubeProps, duration }, '>');

      tl.to(SECTION_1, { rotateY: 90, duration }, 'cube');
      tl.to(SECTION_2, { translateY, rotateY: -90, duration }, '<');

      tl.to(SECTION_2, { translateY, rotateY: 0, duration }, '>');
      tl.to(SECTION_1, { rotateY: 0, duration }, '<');

      tl.to(CUBE_3D, { opacity: 0 }, '>0.05');
      tl.to(CUBE_2D, { opacity: 1, duration: 0.2 }, '<');
      tl.to(CUBE_2D_COVER, { opacity: 0, scale: 1.2, duration: 0.2 }, '>');
      tl.to(CUBE_2D, { opacity: 1, duration: 0.2 }, '<');
    }
    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = this.getBaseTimeline(initialize);
    tl.add(this.moveCube(initialize), '<');
    tl.add(this.moveBackground(initialize), '<');
    this.tl = tl;
    return this.tl;
  };

  render() {
    return (
      <>
        <Image className={C.BASE} {...this.props} />
        <Cube />
      </>
    );
  }
}
