import React from 'react';
import { Typography, Box } from '@mui/material';
import { Checkbox, Input, Button as MatterButton } from '@matterapp/matter-ui';
import { QuestionWrapper, QuestionInput } from './styles';
import { ACTIONS } from '../reducer';
import RemoveButton from './RemoveButton';

export default function MultipleChoiceQuestion(props) {
  const { question, id, dispatch, required, comments, maxLength, answerOptions, errors } = props;
  const questionError = errors.find((error) => error.field === 'question');
  const optionsError = errors.find((error) => error.field === 'options');

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant='h5' component='p'>
        Question:
      </Typography>
      <QuestionWrapper>
        <QuestionInput 
          onChange={(e) => {
            dispatch({
              type: ACTIONS.UPDATE_QUESTION_TEXT,
              payload: {
                id,
                question: e.target.value
              }
            });
          }}
          value={question}
          maxLength={maxLength}
          allowErrorBeforeBlur
          error={!!questionError}
          placeholder="Type your question here..."
        />
        {questionError && (
          <Typography variant='body2' component='p' color='error' sx={{ mt: 0.5 }}>
            {questionError.message}
          </Typography>
        )}
      </QuestionWrapper>
      <Typography variant='h5' component='p' sx={{ mt: 3 }}>
        Options:
      </Typography>
      {answerOptions.map(({ label }, index) => {
        const optionError = errors.find((error) => error.field === `option[${index}]`);

        return (
          <Box 
            key={index} 
            sx={{ mt: 2, display: 'flex', flexDirectionL: 'row', alignItems: 'center' }} 
            data-id={`option[${index}]`}
          >
            <Input 
              value={label}
              focusOnMount={index === answerOptions.length - 1}
              errorMessage={!!optionError}
              onChange={(e) => {
                dispatch({
                  type: ACTIONS.UPDATE_QUESTION_OPTION,
                  payload: {
                    id,
                    index,
                    option: e.target.value
                  }
                });
              }}
              placeholder={`Option ${index + 1}`}
            />
            {answerOptions.length > 1 && (
              <RemoveButton 
                sx={{ ml: 2 }} 
                onClick={() => {
                  dispatch({
                    type: ACTIONS.DELETE_QUESTION_OPTION,
                    payload: {
                      id,
                      index
                    }
                  });
                }}
              />
            )}
          </Box>
        );
      })}
      {optionsError && (
        <Typography variant='body2' component='p' color='error' sx={{ mt: 0.5 }}>
          {optionsError.message}
        </Typography>
      )}
      <Box sx={{ mt: 3 }}>
        <MatterButton 
          onClick={async () => {
            dispatch({
              type: ACTIONS.ADD_QUESTION_OPTION,
              payload: {
                id
              }
            });
          }}
          size='S'
        >
          Add Option
        </MatterButton>
      </Box>
      <Box 
        sx={{ 
          mt: 4, 
          display: 'flex', 
          alignItems: 'center', 
          cursor: 'pointer'
        }}
        onClick={() => {
          dispatch({ type: ACTIONS.TOGGLE_QUESTION_REQUIRED, payload: { id } });
        }}
      >
        <Checkbox.Toggle
          size='S' 
          checked={required}
        />
        <Typography variant='body1' component='p' sx={{ ml: 1 }}>
          <b>Make question required</b>
        </Typography>
      </Box>
      <Box 
        sx={{ 
          mt: 4, 
          display: 'flex', 
          alignItems: 'center', 
          cursor: 'pointer'
        }}
        onClick={() => {
          dispatch({ type: ACTIONS.TOGGLE_ENABLE_COMMENTS, payload: { id } });
        }}
      >
        <Checkbox.Toggle
          size='S' 
          checked={comments}
        />
        <Typography variant='body1' component='p' sx={{ ml: 1 }}>
          <b>Enable comments</b>
        </Typography>
      </Box>
    </Box>
  );
}
