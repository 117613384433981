import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  IconCheckmarkSmall,
} from '../Icon';
import theme from '@matterapp/matter-theme';

const LabelText = styled.span``;

const IconContainer = styled.div`
  width: ${theme.spacing.singleAndHalf};
  min-width: ${theme.spacing.singleAndHalf};
  height: ${theme.spacing.singleAndHalf};
`;

const getCheckmarkStyles = (props) => {
  const { checked, clickable, disabled, displayLabelFirst, muted, light, showAnimation, iconSize, isCheckboxV2 } = props;
  const checkboxPosition = displayLabelFirst ? 'after' : 'before';
  return `
    [type="checkbox"]:not(:checked) + label:${checkboxPosition},
    [type="checkbox"]:checked + label:${checkboxPosition} {
      content: '';
      position: ${displayLabelFirst ? 'static' : 'absolute'};
        left: 0px;
        top: 0px;
      width: ${iconSize}px;
      height: ${iconSize}px;
      background: white;
      border: 1px solid ${theme.colors.blacks[40]};
      border-radius: 2px;
      ${isCheckboxV2 ? `margin: -2px -3px` : ''}
    }

    [type="checkbox"]:not(:checked):hover + label:hover:${checkboxPosition} {
      border-color: ${isCheckboxV2 ? theme.colors.blues[50] : theme.colors.purples[50]};
    }

    [type="checkbox"]:checked + label:${checkboxPosition} {
      background: ${light ? 'white' : theme.colors.blacks[70]};
      border-color: ${theme.colors.blacks[70]};
      ${showAnimation ? `animation: checkmarkPulse 0.6s 1 ease-out;` : ''}
    }

    [type="checkbox"]:checked:hover + label:hover:${checkboxPosition} {
      background: ${light ? 'white' : theme.colors.purples[50]};
      border-color: ${isCheckboxV2 ? theme.colors.blues[50] : theme.colors.purples[50]};
    }
  
    @keyframes checkmarkPulse {
      from {
        box-shadow:  0 0 0 0 rgba(255,255,255,1),
                    0 0 0 0 rgba(69,132,255,1),
                    inset 0px -1px 0px rgba(0, 0, 0, 0.1);
      }
      to {
        box-shadow:  0 0 0 0 rgba(255,255,255,1),
                    0 0 0 10px rgba(69,132,255,0),
                    inset 0px -1px 0px rgba(0, 0, 0, 0.1);
      }
    }

    [type="checkbox"]:not(:checked) + label img {
      opacity: 0;
      transform: scale(0);
    }

    [type="checkbox"]:checked + label img {
      opacity: 1;
      transform: scale(1);
    }

    [type="checkbox"]:disabled:not(:checked) + label:${checkboxPosition},
    [type="checkbox"]:disabled:checked + label:${checkboxPosition} {
      box-shadow: none;
      border-color: #dddddd;
      background-color: #dddddd;
    }

    [type="checkbox"]:disabled:checked + label:${checkboxPosition} {
      color: #999999;
    }

    [type="checkbox"]:disabled + label {
      color: #aaaaaa;
    }

    /* accessibility */
    [type="checkbox"]:checked:focus + label:${checkboxPosition} {
      border-color: ${theme.colors.blacks[70]};
    }
    [type="checkbox"]:not(:checked):focus + label:${checkboxPosition} {
      border:
        ${theme.inputs.borderWidth}
        ${theme.inputs.borderStyle}
        ${theme.inputs.borderColor};
    }
    cursor: ${(disabled || !clickable) ? 'auto' : 'pointer'};

    svg {
      position: relative;
      margin: ${displayLabelFirst ? '4px 1px' : '1px'};

      path {
        fill: ${(!light && (checked || disabled)) ? 'white' : isCheckboxV2 ? theme.colors.blues[50] : theme.colors.blacks[70]};
      }
    }
    &:hover {
      svg path {
        fill: ${!light ? 'white' : (isCheckboxV2 ? theme.colors.blues[50] : theme.colors.purples[50])};
      }
    }

    & ${LabelText} {
      margin-left: ${displayLabelFirst ? '0px' : muted ? '12px' : '4px'};
    }

    & ${IconContainer} {
      ${displayLabelFirst ? 'margin-right: -24px; margin-left: 10px;' : ''}
    }

    ${muted && `
      [type="checkbox"]:not(:checked) + label:${checkboxPosition},
      [type="checkbox"]:checked + label:${checkboxPosition} {
        width: 22px;
        height: 22px;
        background: ${theme.colors.blacks[5]};
        border: 1px solid ${theme.colors.blacks[10]};
      }
      svg path {
        fill: ${theme.colors.blue};
      }
    `}
  `;
};

const ContainerCheckbox = styled.div`
  ${getCheckmarkStyles}
`;

const Input = styled.input`
  :not(:checked),
  :checked {
    position: absolute;
    left: -9999px;
  }
  cursor: ${({ disabled, clickable }) => ((disabled || !clickable) ? 'auto' : 'pointer')};
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  text-align: left;
  line-height: 24px;
  height: 24px;
  user-select: none;
  cursor: ${({ disabled, clickable }) => ((disabled || !clickable) ? 'auto' : 'pointer')};
  color: ${({ checked }) => checked ? `${theme.colors.blacks[70]}` : `${theme.colors.blacks[50]}`};
  &:hover {
    color: ${theme.colors.purples[50]};
  }
`;

const getOnChangeCallback = (onChange = () => null, props = {}) => {
  return (e) => {
    onChange(e, { ...props, checked: e.target.checked, value: e.target.checked });
  };
};

const Checkbox = (props) => {
  const {
    className,
    checked,
    displayLabelFirst,
    label,
    name,
    disabled,
    clickable,
    onChange,
    muted,
    light,
    showAnimation,
    iconSize,
    checkmarkSize,
    isCheckboxV2
  } = props;
  return (
    <ContainerCheckbox
      light={light}
      className={className}
      disabled={disabled}
      checked={checked}
      muted={muted}
      clickable={clickable}
      displayLabelFirst={displayLabelFirst}
      showAnimation={showAnimation}
      iconSize={iconSize}
      isCheckboxV2={isCheckboxV2}
    >
      <Input
        type="checkbox"
        id={`checkbox-${name}`}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={getOnChangeCallback(onChange, props)}
        clickable={clickable}
      />
      <Label
        htmlFor={`checkbox-${name}`}
        disabled={disabled}
        clickable={clickable}
        checked={checked}
      >
        {displayLabelFirst && <LabelText>{label}</LabelText>}
        <IconContainer>
          {checked && <IconCheckmarkSmall size={checkmarkSize}/>}
        </IconContainer>
        {!displayLabelFirst && <LabelText>{label}</LabelText>}
      </Label>
    </ContainerCheckbox>
  );
};

Checkbox.defaultProps = {
  label: null,
  clickable: true,
  checked: false,
  disabled: false,
  name: null,
  onChange: () => null,
  muted: false,
  displayLabelFirst: false,
  light: false,
  showAnimation: true,
  iconSize: 16,
  checkmarkSize: 16,
  isCheckboxV2: false
};

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  muted: PropTypes.bool,
  displayLabelFirst: PropTypes.bool,
  light: PropTypes.bool,
  showAnimation: PropTypes.bool,
};

export default Checkbox;
