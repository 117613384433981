import { compose } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import {
  REMOTE_NOTIFICATIONS_QUERY,
  MARK_NOTIFICATIONS_AS_VIEWED_MUTATION,
  MARK_ALL_NOTIFICATIONS_AS_VIEWED_MUTATION,
} from 'graphql-queries/queries';

const withRemoteNotifications = compose(
  graphql(REMOTE_NOTIFICATIONS_QUERY, {
    name: 'remoteNotificationsData',
  }),
  graphql(MARK_NOTIFICATIONS_AS_VIEWED_MUTATION, {
    name: 'markNotificationsAsViewedMutation',
  }),
  graphql(MARK_ALL_NOTIFICATIONS_AS_VIEWED_MUTATION, {
    name: 'markAllNotificationsAsViewedMutation',
  })
);

export default withRemoteNotifications;
