import React, { useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Modal, Form, TextArea, Radio } from '@matterapp/matter-ui';
import { font, lineHeight, spacing, colors, media } from '@matterapp/matter-theme';
import updateCustomReward from 'graphql-queries/queries/rewards/updateCustomReward';
import getS3PresignedUrl from 'graphql-queries/mutations/getS3PresignedUrl';
import isExistingCustomReward from 'graphql-queries/queries/rewards/isExistingReward';
import { IMAGE_FILE_UPLOAD_ERROR_DETAILS } from 'routes/pages/settings/Kudos/AddKudosModal/consts';
import ImageUpload from 'modules/workspaces/shared/ImageUpload/ImageUpload';
import { REWARD_QUANTITY_TYPES } from '../consts';
import Toast from 'components/Toast/Toast';

const MainModal = styled(Modal).attrs(() => ({
  size: Modal.sizes.XL,
  fullScreenMobile: true,
  overflow: 'visible',
}))``;

const Section = styled.div`
  margin-bottom: ${spacing.single};
`;

const RequiredSign = styled.div`
  color: ${colors.reds[50]};
`;

const Title = styled.div`
  display: flex;
  margin-bottom: ${spacing.single};
  font-size: ${font.size.S2};
  font-weight: ${font.weight.bold};
  line-height: ${lineHeight.M};
`;

const TitleInput = styled(Form.Input).attrs(() => ({
  fluid: true,
}))`
  font-size: ${font.size.S};
`;

const DescriptionText = styled.div`
  font-size: ${font.size.base};
  font-weight: ${font.weight.regular};
  line-height: ${lineHeight.M};
  margin-top: ${spacing.half};
  color: ${colors.blacks[60]};
`;

const DescriptionInput = styled(TextArea.Basic)`
  height: ${spacing.septuple};
  font-size:  ${font.size.S};
  line-height:  ${lineHeight.M};
  overflow-y: scroll;
`;

const DetailsContainer = styled.div`
  padding: ${spacing.singleAndHalf} ${spacing.singleAndHalf} 0 ${spacing.singleAndHalf};
`;

const QUANTITY_INPUT_WIDTH = 248;

const RewardQuantityInput = styled(Form.Input)`
  max-width: ${QUANTITY_INPUT_WIDTH}px;
  ${media.S`
    width: ${QUANTITY_INPUT_WIDTH}px;
  `}
`;

const rewardQuantityTypes = [
  {
    label: 'Unlimited quantity',
    value: REWARD_QUANTITY_TYPES.UNLIMITED
  }, 
  {
    label: 'Amount in stock',
    value: REWARD_QUANTITY_TYPES.LIMITED,
    expanded: true
  }
];

const MAX_COINS_TO_REDEEM = 1000000;

const errorCodes = {
  TITLE_TOO_LONG: 'Your title is too long. The limit is 40 characters.',
  TITLE_EXISTS: 'This title already exists. Try another.',
  VALUE_ERROR: `Value must be between 0 and ${MAX_COINS_TO_REDEEM}.`,
  IMAGE_ERROR: 'The image does not meet the requirements',
  DESCRIPTION_TOO_LONG: 'Your description is too long. The limit is 255 characters.',
};

// DM_TODO: Remove ref?
const AddCustomRewardModal =  React.forwardRef((props, ref) => {
  const {
    isOpen,
    onClose,
    tenantId,
    workspaceId,
    customReward,
    createCustomReward
  } = props;
  const [titleInput, setTitleInput] = useState('');
  const [descriptipnInput, setDescriptionInput] = useState('');
  const [rewardValueInput, setRewardValueInput] = useState('');
  const [quantityInput, setQuantityInput] = useState('');

  const [isInputFocused, setIsInputFocused] = useState(false);

  const [titleErrorMessage, setTitleErrorMessage] = useState(null);
  const [descErrorMessage, setDescErrorMessage] = useState(null);
  const [rewardValueErrorMessage, setRewardValueErrorMessage] = useState(null);
  const [quantityErrorMessage, setQuantityErrorMessage] = useState(null);

  const [selectedQuantityType, changeSelectedQuantityType] = useState(REWARD_QUANTITY_TYPES.UNLIMITED);
  const [url, setUrl] = useState();

  const [checkIfNameExists, { data: isExistingCustomRewardData }] = useLazyQuery(isExistingCustomReward);
  const [s3PresignedUrlMutation] = useMutation(getS3PresignedUrl);

  const [updateCustomRewardMutation] = useMutation(updateCustomReward);

  useEffect(() => {
    if (customReward) {
      const { name, value, description, quantityType, quantity, imageUrl } = customReward;
      setTitleInput(name);
      setDescriptionInput(description);
      setRewardValueInput(value);
      changeSelectedQuantityType(quantityType);
      setQuantityInput(quantity);
      setUrl(imageUrl);
    }

  }, [customReward]);

  useEffect(() => {
    if (isExistingCustomRewardData?.isExistingCustomReward) {
      setTitleErrorMessage(errorCodes.TITLE_EXISTS);
    }
  }, [isExistingCustomRewardData]);

  useImperativeHandle(ref, () => ({
    resetModal() {
      resetState();
    }
  }));

  const resetState = () => {
    setTitleInput('');
    setRewardValueInput('');
    setDescriptionInput('');
    setQuantityInput('');
    setUrl('');
    changeSelectedQuantityType(REWARD_QUANTITY_TYPES.UNLIMITED);
    setTitleErrorMessage(null);
    setDescErrorMessage(null);
    setRewardValueErrorMessage(null);
    setQuantityErrorMessage(null);
    setIsInputFocused(false);
  };

  const onClickCancel = () => {
    resetState();
    onClose();
  };

  const onClickSave = async () => {
    const variables = {
      name: titleInput,
      description: descriptipnInput,
      value: parseInt(rewardValueInput),
      imageUrl: url,
      quantityType: selectedQuantityType,
      quantity: parseInt(quantityInput),
      workspaceId,
    };

    if (customReward) {
      await updateCustomRewardMutation({
        variables: {
          id: customReward.id,
          ...variables
        }
      });
      Toast.success('Success! Reward has been updated');
    } else {
      await createCustomReward({
        variables
      });
      Toast.success('Success! You added a new Reward');
    }
    onClickCancel();
  };

  const isQuantityValid = selectedQuantityType === REWARD_QUANTITY_TYPES.UNLIMITED ? true : quantityInput && !quantityErrorMessage;
  const isInputsValid = !titleErrorMessage && !descErrorMessage && !rewardValueErrorMessage && titleInput && rewardValueInput;
  const canClickSend = !!isInputsValid && !!isQuantityValid && !!url;

  return (
    <>
      <MainModal isOpen={isOpen} onClose={onClickCancel}>
        <Modal.Header
          centerOnMobile
          header={customReward ? 'Edit Reward' : 'Add Reward'}
          right={<Modal.Buttons.CloseIcon onClick={onClickCancel} />}
        />
        <Modal.Body>
          <DetailsContainer>
            <Section>
              <Title>Title<RequiredSign>*</RequiredSign></Title>
              <TitleInput
                showErrorMessageBelow
                placeholder="Enter a title"
                errorMessage={titleErrorMessage}
                onChange={(e, { value }) => {
                  setTitleInput(value);
                  if (value.length > 40) {
                    setTitleErrorMessage(errorCodes.TITLE_TOO_LONG);
                  } else {
                    setTitleErrorMessage(null);
                  }
                }}
                onBlur={() => {
                  if (titleInput) {
                    checkIfNameExists({
                      variables: {
                        tenantId,
                        name: titleInput
                      },
                      fetchPolicy: 'network-only'
                    });
                  }
                }}
                value={titleInput}
              />
            </Section>
            <Section>
              <Title>Description</Title>
              <DescriptionInput
                fluid
                placeholder="Enter a description" 
                value={descriptipnInput}
                errorMessage={descErrorMessage}
                onChange={(e) => {
                  const description = e.target.value;
                  setDescriptionInput(description);

                  if (description?.length > 255) {
                    setDescErrorMessage(errorCodes.DESCRIPTION_TOO_LONG);
                  } else {
                    setDescErrorMessage(null);
                  }
                }}
              />
            </Section>
            <Section>
              <Title>Value<RequiredSign>*</RequiredSign></Title>
              <TitleInput
                fluid
                type="number"
                showErrorMessageBelow
                placeholder="Enter number of coins" 
                errorMessage={rewardValueErrorMessage}
                value={rewardValueInput}
                onChange={(e, { value }) => {
                  setRewardValueInput(value);
                  if (value < 1 || value > MAX_COINS_TO_REDEEM) {
                    setRewardValueErrorMessage(errorCodes.VALUE_ERROR);
                  } else {
                    setRewardValueErrorMessage(null);
                  }
                }}
              />
              <DescriptionText>10 coins = $1</DescriptionText>
            </Section>
            <Section>
              <Title style={{ marginBottom: 0 }}>Quantity Available<RequiredSign>*</RequiredSign></Title>
              <Radio.List
                expanded
                onChange={(e, { value }) => changeSelectedQuantityType(value)}
                values={rewardQuantityTypes}
                value={selectedQuantityType}
              >
                <RewardQuantityInput
                  showErrorMessageBelow
                  errorMessageStyle={{ position: 'absolute' }}
                  type="number"
                  placeholder="Enter number of rewards" 
                  errorMessage={quantityErrorMessage}
                  focusOnMount={isInputFocused}
                  value={quantityInput}
                  onChange={(e, { value }) => {
                    setQuantityInput(value);
                    if (value < 0 || value > 1000) {
                      setQuantityErrorMessage(errorCodes.VALUE_ERROR);
                    } else {
                      setQuantityErrorMessage(null);
                    }
                  }}
                />
              </Radio.List>
            </Section>
            <Section>
              <Title>Upload Image<RequiredSign>*</RequiredSign></Title>
              <ImageUpload
                url={url}
                onSaveIcon={async (props, file) => {
                  const presignedUrlData = await s3PresignedUrlMutation({
                    variables: {
                      prefix: 'customRewards'
                    },
                  });
                  
                  const { presignedUrl, imgixPath } = presignedUrlData.data.getS3PresignedUrl;
     
                  await fetch(presignedUrl, {
                    method: 'PUT',
                    headers: { 
                      'Content-Type': 'image/png'
                    },
                    body: file
                  });

                  setUrl(imgixPath);

                  return imgixPath;
                }}
                onRemoveIcon={() => {
                  setUrl(null);
                  return null;
                }}
                changePhotoText="EDIT IMAGE"
                changePhotoHeader="Edit Image"
                uploadImageSubHeader="Image size 300px x 190px work best."
                aspectRatio={1.58}
                iconWidth={134}
                iconHeight={85}
                {...IMAGE_FILE_UPLOAD_ERROR_DETAILS}
              />
            </Section>
          </DetailsContainer>
        </Modal.Body>
        <Modal.Footer.WithCancelSave
          canClickSave={canClickSend}
          onClickCancel={onClickCancel}
          onClickSave={onClickSave}
        />
      </MainModal>
    </>
  );
});

AddCustomRewardModal.defaultProps = {
  showCustomMessage: false,
  hasContacts: true,
  onClose: () => null,
};

AddCustomRewardModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  workspaceId: PropTypes.string,
  customReward: PropTypes.object,
  currentMemberCoins: PropTypes.string,
  createCustomReward: PropTypes.func
};

export default AddCustomRewardModal;
