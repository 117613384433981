"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.gradients = void 0;

var _colors = require("./colors");

const gradients = {
  purpleBackground: `radial-gradient(${`circle at bottom, ${_colors.colors.purples[40]} 0%, ${_colors.colors.purples[60]} 75%`})`
};
exports.gradients = gradients;
var _default = gradients;
exports.default = _default;