import React from 'react';
import SVG from '../../SVG';

const SIZE = 24;

const FilterIcon = () => {
  return (
    <SVG height={SIZE} width={SIZE} viewBox={'0 0 24 24'}>
      <path
        d="M8.1,9.7c0-1.3-0.8-2.3-2-2.6V2.5H4.6v4.6c-1.2,0.3-2,1.4-2,2.6s0.8,2.3,2,2.6v9.2h1.5v-9.2
	C7.3,12,8.1,10.9,8.1,9.7z M5.4,11.2c-0.9,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S6.2,11.2,5.4,11.2z M21.1,9.7
	c0-1.3-0.8-2.3-2-2.6V2.5h-1.5v4.6c-1.2,0.3-2,1.4-2,2.6s0.8,2.3,2,2.6v9.2h1.5v-9.2C20.3,12,21.1,10.9,21.1,9.7z M18.4,11.2
	c-0.9,0-1.6-0.7-1.6-1.5s0.7-1.5,1.6-1.5s1.6,0.7,1.6,1.5S19.2,11.2,18.4,11.2z M14.6,15.4c0-1.3-0.8-2.3-2-2.6V2.5h-1.5v10.3
	c-1.2,0.3-2,1.4-2,2.6c0,1.3,0.8,2.3,2,2.6v3.4h1.5v-3.4C13.8,17.7,14.6,16.7,14.6,15.4z M11.9,17c-0.9,0-1.5-0.7-1.5-1.6
	s0.7-1.5,1.5-1.5c0.9,0,1.6,0.7,1.6,1.5S12.7,17,11.9,17z"
      />
    </SVG>
  );
};

export default FilterIcon;
