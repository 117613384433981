import React from 'react';
import PropTypes from 'prop-types';
import { VIEW_ALL_COMMENTS_TEXT } from './consts';
import { ViewAllCommentsLink, ViewAllCommentsLinkContainer } from './styles';

const ViewAllComments = ({ onClick, viewAllText }) => {
  return (
    <ViewAllCommentsLinkContainer>
      <ViewAllCommentsLink onClick={onClick}>{viewAllText}</ViewAllCommentsLink>
    </ViewAllCommentsLinkContainer>
  );
};

ViewAllComments.defaultProps = {
  onClick: () => null,
  viewAllText: VIEW_ALL_COMMENTS_TEXT,
};

ViewAllComments.propTypes = {
  viewAllText: PropTypes.string,
};

export default ViewAllComments;
