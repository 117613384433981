import { mixpanel } from 'libs/tracking/mixpanel';

const WORKSPACES = 'workspaces:';
export const handleTrack = {
  requestFeedbackClick: mixpanel.createEventSender(`${WORKSPACES}request-feedback-viewed`),
  requestFeedbackSubmitted: mixpanel.createEventSender(`${WORKSPACES}request-feedback-submitted`),
  giveFeedbackClick: mixpanel.createEventSender(`${WORKSPACES}give-feedback-viewed`),
  giveFeedbackSubmitted: mixpanel.createEventSender(`${WORKSPACES}give-feedback-submitted`),
  giveKudosClick: mixpanel.createEventSender(`${WORKSPACES}give-kudos-viewed`),
  giveKudosSubmitted: mixpanel.createEventSender(`${WORKSPACES}give-kudos-submitted`),
  recurringFeedbackClick: mixpanel.createEventSender(`${WORKSPACES}recurring-modal-viewed`),
  recurringFeedbackEditClick: mixpanel.createEventSender(`${WORKSPACES}recurring-edit-modal-viewed`),
  recurringFeedbackSubmitted: mixpanel.createEventSender(`${WORKSPACES}recurring-edit-modal-submitted`),
  onStopRecurring: mixpanel.createEventSender(`${WORKSPACES}stopped-recurring-feedback`),
};