import React from 'react';
import Base from '../../../svgBase';

const DEFAULT_HEIGHT = 16;
const DEFAULT_WIDTH = 14;
const PATH = `M0.400391 13.3437H8.12695V16H11.8223V13.3437H13.7363V10.2969H11.8223V-4.76837e-07H6.95508L0.400391 10.3281V13.3437ZM8.20508 10.2969H4.24414V10.1719L8.08008 4.09375H8.20508V10.2969Z`;

const Four = (props) => (
  <Base height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
);

export default Four;