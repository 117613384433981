import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconNew as Icon } from '../../Icon';
import ContactAutocomplete from '../../ContactAutocomplete';
import Button from '../../Button';
import Tooltip from '../../Tooltip';
import Panel from '../../Panel';
import { Clickable } from '../../Clickable/Clickable';
import theme from '@matterapp/matter-theme';
import styled from 'styled-components';
import Customization from './Customization/Customization';
import FactsModal from './FactsModal';
import ConfirmResetModal from './ConfirmResetModal';

const Copied = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.greens[5]};
  border-radius: ${theme.borderRadius.S};
  width: 136px;
  height: 28px;
  color: ${theme.colors.greens[60]};
  font-size: ${theme.fontSize.base};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  div:first-of-type {
    margin-right: ${theme.spacing.half};
  }
  .info {
    &:hover {
      path {
        fill: ${theme.colors.blacks[80]};
      }
    }
    &:active {
      path {
        fill: ${theme.colors.purples[50]};
      }
    }
  }
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.singleAndHalf} 0 0;
  justify-content: space-between;
  box-sizing: border-box;
  height: 52px;
`;
const InviteLinkWrapper = styled.div`
  min-height: 28px;
  display: flex;
  align-items: center;
`;
const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSize.base};

  ${theme.media.S`
    font-size: ${theme.fontSize.S};
  `}
`;
const Option = styled(Clickable)`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  svg {
    margin-left: ${theme.spacing.half};
  }
  color: ${({open}) => open ? theme.colors.purples[50] : theme.colors.blacks[50]};
  &:hover {
    color: ${theme.colors.blacks[80]};
  }
  &:active {
    color: ${theme.colors.purples[50]};
  }
  ${({active}) => active && `
    color: ${theme.colors.purples[50]};
  `}
`;


const Cancel = styled(Option)`
  color: ${theme.colors.blues[50]};
  &:hover {
    color: ${theme.colors.blues[60]};
  }
  &:active {
    color: ${theme.colors.purples[50]}
  }
`;

const InlineForm = styled.div`
  input {
    height: 56px;
    padding-right: 90px;
  }
  position: relative;
  .submit-button {
    padding-left: ${theme.spacing.single};
    padding-right: ${theme.spacing.single};
    ${theme.media.S`
      padding-left: ${theme.spacing.singleAndHalf};
      padding-right: ${theme.spacing.singleAndHalf};
    `}
    position: absolute;
    bottom: ${theme.spacing.quarter};
    right: ${theme.spacing.quarter};
  }
`;
// Deprecated
const UnactivatedUserComposer = ({
  handleSearch,
  requestSendPeerInvite,
  requestInviteLink,
  userSkills,
  defaultMessage,
  canSend,
  invite,
  inviteDispatcher,
  dirty,
  tracking,
  inviteLink,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  function sendInvite(e) {
    e.preventDefault();
    requestSendPeerInvite();
  }

  function openInfoModal() {
    setModalOpen(true);
    tracking.infoModalClick();
  }

  function togglePersonalizationDrawer() {
    if (!isDrawerOpen) {
      tracking.customizeClicked();
    }
    setDrawerOpen(!isDrawerOpen);
  }

  function resetInvite() {
    inviteDispatcher({ type: 'INVITE:RESET' });
    tracking.resetClicked();
    setDrawerOpen(false);
    setConfirmOpen(false);
  }

  return (
    <>
      <FactsModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
      <ConfirmResetModal
        isOpen={isConfirmOpen}
        onConfirm={resetInvite}
        onCancel={() => setConfirmOpen(false)}
      />
      <Panel.Base>
        <Panel.Header.Basic
          title={
            <Header>
              <div>Send your feedback requests</div>
              <div onClick={openInfoModal}>
                <Tooltip
                  dark
                  content="Learn More"
                >
                  <Icon
                    name="infoCircle"
                    fill={theme.colors.blacks[30]}
                    className='info'
                  />
                </Tooltip>
              </div>
            </Header>
          }
        />
        <Panel.Block.Basic className="hi">
          <form onSubmit={sendInvite}>
            <InlineForm>
              <Button.Simple.Primary
                size="M"
                withBorder
                disabled={!canSend}
                content="Send"
                className="submit-button"
                rcLabel="composer-submit"
              />
              <ContactAutocomplete
                fluid
                size="medium"
                errorMessage={''}
                value={invite.email}
                placeholder='Enter an email address...'
                onChange={(value) => inviteDispatcher({ type: 'EMAIL:UPDATE', email: value })}
                search={(query) => handleSearch(query)}
                rcLabel="unactivated-composer-email-input"
              />
            </InlineForm>
          </form>
          <Actions>
            <OptionWrapper>
              {dirty ? (
                <>
                  <span>Personalize</span>
                  <span>&nbsp;|&nbsp;</span>
                  <Cancel onClick={() => { canSend ? setConfirmOpen(true) : resetInvite(); }}>
                    Cancel
                  </Cancel>
                </>
              ) : (
                <Option active={isDrawerOpen && !dirty} onClick={togglePersonalizationDrawer}>Personalize</Option>
              )}
            </OptionWrapper>
            <InviteLinkWrapper>
              {(inviteLink.copied || inviteLink.copying) &&
                <Copied>{inviteLink.copied && "Copied!"}</Copied>
              }
              {!(inviteLink.copied || inviteLink.copying) &&
                <OptionWrapper>
                  <Option
                    rcLabel="share-link"
                    onClick={() => {
                      requestInviteLink();
                      tracking.shareViaLink();
                    }}
                  >
                    <div>Share via link</div>
                    <Icon name="shareLink" />
                  </Option>
                </OptionWrapper>
              }
            </InviteLinkWrapper>
          </Actions>
        </Panel.Block.Basic>
        <Customization
          defaultMessage={defaultMessage}
          dispatch={inviteDispatcher}
          invite={invite}
          isOpen={isDrawerOpen}
          userSkills={userSkills}
          tracking={tracking}
        />
      </Panel.Base>
    </>
  );
};
UnactivatedUserComposer.propTypes = {
  userSkills: PropTypes.array.isRequired,
  handleSearch: PropTypes.func.isRequired,
  requestSendPeerInvite: PropTypes.func.isRequired,
  requestInviteLink: PropTypes.func.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  canSend: PropTypes.bool.isRequired,
  invite: PropTypes.object.isRequired,
  inviteDispatcher: PropTypes.func.isRequired,
  inviteLink: PropTypes.shape({
    copied: PropTypes.bool,
    copying: PropTypes.bool,
  }),
  tracking: PropTypes.shape({
    infoModalClick: PropTypes.func,
    customizeClicked: PropTypes.func,
    shareViaLink: PropTypes.func,
    skillPillClick: PropTypes.func,
    clearText: PropTypes.func,
    resetClicked: PropTypes.func
  })
};

UnactivatedUserComposer.defaultProps = {
  userSkills: [],
  tracking: {
    infoModalClick: () => {},
    customizeClicked: () => {},
    shareViaLink: () => {},
    skillPillClick: () => {},
    clearText: () => {},
    resetClicked: () => {}
  }
};

export default UnactivatedUserComposer;
