import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.58958 12C5.51686 16.1878 8.66136 18.1 12 18.1C15.3386 18.1 18.4831 16.1878 21.4104 12C18.4831 7.81211 15.3386 5.89998 12 5.89998C8.66136 5.89998 5.51686 7.81211 2.58958 12ZM0.751155 11.5007C3.94979 6.7028 7.69077 4.09998 12 4.09998C16.3092 4.09998 20.0502 6.7028 23.2488 11.5007C23.4504 11.8031 23.4504 12.1969 23.2488 12.4992C20.0502 17.2972 16.3092 19.9 12 19.9C7.69077 19.9 3.94979 17.2972 0.751155 12.4992C0.549615 12.1969 0.549615 11.8031 0.751155 11.5007ZM12 9.39998C10.5641 9.39998 9.4 10.564 9.4 12C9.4 13.4359 10.5641 14.6 12 14.6C13.4359 14.6 14.6 13.4359 14.6 12C14.6 10.564 13.4359 9.39998 12 9.39998ZM7.6 12C7.6 9.56992 9.56995 7.59998 12 7.59998C14.4301 7.59998 16.4 9.56992 16.4 12C16.4 14.43 14.4301 16.4 12 16.4C9.56995 16.4 7.6 14.43 7.6 12Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#6B6B6F',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;

