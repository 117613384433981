import React from 'react';
import PropTypes from 'prop-types';
import { skillEntityPropType } from '@matterapp/skills';

import Badge from '../../Badge';
import Skill from '../..';

import {
  getSkillPrimaryColor,
  getSkillBackgroundColor,
  getSkillBorderColor,
} from '../../../../common/libs/get-skill-details';

import ClassicStyle from './variants/Classic/styles';
import SmallStyle from './variants/Small/styles';

const styleVariantMap = {
  'classic': ClassicStyle,
  'small': SmallStyle,
};

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorShake: false,
    };

    this.shakerTimeout = null;
  }
  static defaultProps = {
    className: null,
    onClick: () => null,
    selected: false,
  };

  static propTypes = {
    skill: skillEntityPropType.isRequired,
    rating: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    rating: null,
  };


  getPillText() {
    const { rating } = this.props;
    let pillText = '-';

    if (Number.isFinite(rating)) {
      pillText = rating;
    }

    // String conversion required to display a rating of 0
    return pillText.toString();
  }

  setShakeable = () => {
    this.setState({errorShake: true});
    setTimeout(() => {
      this.setState({errorShake: false});
    }, 1000);
  }

  getStyleVariant() {
    const variant = this.props.small ? 'small' : 'classic';
    const styles = styleVariantMap[variant];
    if (!styles) {
      throw Error(`${this.props.variant} is not a valid variant for SkillPill component`);
    }
    return styles;
  }

  render() {
    const {
      skill,
      rating,
      className,
      onClick,
      selected,
      backgroundColor,
      disabled,
      vivid
    } = this.props;
    const styleVariant = this.getStyleVariant();
    const Wrapper = styleVariant.Wrapper;
    const Container = styleVariant.Container;
    const SkillBadgeContainer = styleVariant.SkillBadgeContainer;
    const SkillName = styleVariant.SkillName;
    const SkillPillContainer = styleVariant.SkillPillContainer;
    return (
      <Wrapper>
        <Container
          primaryColor={getSkillPrimaryColor(skill)}
          backgroundColor={backgroundColor || getSkillBackgroundColor(skill)}
          borderColor={backgroundColor || getSkillBorderColor(skill)}
          className={className}
          onClick={() => disabled ? this.setShakeable() : onClick()}
          selected={selected}
          disabled={disabled}
          shaking={this.state.errorShake}
          vivid={vivid}
        >
          <SkillBadgeContainer>
            <Badge skill={skill} size="fluid" />
          </SkillBadgeContainer>

          <SkillName>
            {skill.name}
          </SkillName>
          <SkillPillContainer>
            <Skill.Pill.Basic
              text={this.getPillText()}
              skill={skill}
              colorless={rating === null || rating === undefined}
            />
          </SkillPillContainer>
        </Container>
      </Wrapper>

    );
  }
}

export default Button;
