import React from 'react';
import PropTypes from 'prop-types';
import { getSkillColorPalette } from '@matterapp/matter-ui';
import SkillNote from './SkillNote';
import SkillNamePopover from '../SkillNamePopover';
import { FlowHeader, SkillNameContainer } from '../sharedStyles';
import { getGeneratedDefaultNote } from '../utils';
import {
  skillsToRatePropType,
} from '../../../propTypes';

const SkillNotesStep = (props) => {
  const { 
    customDescription,
    newSkillsToRate,
    descriptionHeader,
    recurrenceFrequency,
    onChangeCurrentNote,
  } = props;

  /**
   * Callback when the value of the current note changes.
   *
   * Calls `onChangeCurrentNote` prop with change event object and
   * updated component props containing the updated `currentNote` value.
   *
   * @param { Object } e: The change event.
   * @returns { void }
   */
  const handleChangeCurrentNote = (e, noteProps) => {
    const { skillToRate, currentNote, noteError } = noteProps;
    onChangeCurrentNote(e, {
      ...props,
      noteError,
      currentNote,
      skillToRate: {...skillToRate, ...noteProps, currentNote},
    });
  };

  return (
    <>
      <FlowHeader>
        {descriptionHeader}
      </FlowHeader>
      {newSkillsToRate.map((skillToRate, index) => {
        const { skill, actionItemSelections, currentNote } = skillToRate;
        const color = getSkillColorPalette(skill.name).toLowerCase();
        const defaultNote = getGeneratedDefaultNote({...skillToRate, frequency: recurrenceFrequency});

        return  (
          <div key={`skill-note-container-${skill.id}`}>
            <SkillNameContainer
              key={`skill-note-header-${skill.id}-${index}`}
              isSkillName
            >
              <SkillNamePopover
                skillToRate={skillToRate}
                customDescription={customDescription}
              />
            </SkillNameContainer>
            <SkillNote
              key={`note-${skill.id}-${index}`}
              abilities={actionItemSelections}
              color={color}
              skillToRate={skillToRate}
              onChange={handleChangeCurrentNote}
              value={currentNote}
              defaultNote={defaultNote}
            />
          </div>
        );
      })}
    </>
  );
};

SkillNotesStep.propTypes = {
  /** Step header text. */
  descriptionHeader: PropTypes.string.isRequired,
  /** Description for custom skills. */
  customDescription: PropTypes.string,
  /** List of total skills to rate in the timeline. */
  newSkillsToRate: skillsToRatePropType,
  /** Recurrence frequency */
  recurrenceFrequency: PropTypes.string.isRequired,
  /** onChange handler for notes */
  onChangeCurrentNote: PropTypes.func.isRequired,
};

export default SkillNotesStep;