import React from 'react';
import PropTypes from 'prop-types';
import {
  TagContainer,
  TagLabelContainer,
  TagRemoveButton,
  TagRemoveButtonIcon,
  TagRemoveButtonIconContainer,
} from './styles';
import { sizesList } from '../utils';

const Tag = (props) => {
  const { isValid, label, onClickRemove, size, value } = props;
  return (
    <TagContainer size={size} isValid={isValid}>
      <TagLabelContainer>{label || value}</TagLabelContainer>
      <TagRemoveButton onClick={onClickRemove}>
        <TagRemoveButtonIconContainer>
          <TagRemoveButtonIcon />
        </TagRemoveButtonIconContainer>
      </TagRemoveButton>
    </TagContainer>
  );
};

Tag.propTypes = {
  /** If tag is valid. */
  isValid: PropTypes.bool,
  /** Label of the tag. */
  label: PropTypes.string,
  /** Callback when remove button is clicked. */
  onClickRemove: PropTypes.func,
  /** Size of the dropdown. [XS, S, M, L ,XL] */
  size: PropTypes.oneOf(sizesList),
  /** Value of the tag. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Tag;
