import PropTypes from 'prop-types';

export const WORKSPACES_TITLE = 'Workspaces';
export const OTHER_WORKSPACES_TITLE = 'Other Workspaces';

export const FEED_TITLE = 'Home';
export const FEEDBACKS_TITLE = 'Feedback';
export const RECURRING_TITLE = 'Continuous';
export const SKILLS_TITLE = 'Skills';
export const INSIGHTS_TITLE = 'Insights';
export const MEMBERS_TITLE = 'Members';
export const DESKTOP_SETTINGS_TITLE = 'Channel Settings';
export const SETTINGS_TITLE = 'Settings';
export const REWARDS_STORE_TITLE = 'Rewards';
export const REWARDS_MANAGER_TITLE = 'Rewards Manager';
export const ANALYTICS_TITLE = 'Analytics';
export const ADD_WORKSPACE_TITLE = 'Add a Channel';
export const CREATE_WORKSPACE_TITLE = 'Create a Channel';

export const WORKSPACE_PROPS = {
  id: PropTypes.string,
  name: PropTypes.string,
  iconUrl: PropTypes.string,
  isAdmin: PropTypes.bool,
  isOwner: PropTypes.bool,
};

export const WORKSPACE_NAV_DEFAULT_PROPS = {
  activeTab: 1,
  getFeedLink: () => null,
  getRecurringLink: () => null,
  getInsightsLink: () => null,
  getSkillsLink: () => null,
  getSettingsLink: () => null,
  getAnalyticsLink: () => null,
  onChangeWorkspace: () => null,
  onClickCreate: () => null,
  onClickLeave: () => null,
  onClickSettings: () => null,
  onClickSettingsMobile: () => null,
  workspaces: [],
};

export const WORKSPACE_NAV_PROPS = {
  /** The id of the currently active workspace. */
  activeId: PropTypes.string,
  /** The current active workspace tab. */
  activeTab: PropTypes.number,
  /** The current active workspace. */
  activeWorkspace: PropTypes.shape(WORKSPACE_PROPS),
  /** Link to add workspace. */
  addLink: PropTypes.string,
  /** Function to generate feed link for workspace. Workspace object passed as argument. */
  getFeedLink: PropTypes.func,
  /** Function to get skills link. */
  getSkillsLink: PropTypes.func,
  /** Function to generate insights link for workspace. Workspace object passed as argument. */
  getInsightsLink: PropTypes.func,
  /** Function to generate settings link for workspace. Workspace object passed as argument. */
  getSettingsLink: PropTypes.func,
  /** Content to show in header. Defaults to workspace name. */
  headerContent: PropTypes.node,
  /** Callback when workspace changes. */
  onChangeWorkspace: PropTypes.func,
  /** Callback when leave workspace button is clicked. */
  onClickLeave: PropTypes.func,
  /** Override 'onClickSettings' function for mobile. Used only in slide out menu. */
  onClickSettingsMobile: PropTypes.func,
  /** Callback when settings icon is clicked. */
  onClickSettings: PropTypes.func,
  /** List of workspaces to display. */
  workspaces: PropTypes.arrayOf(PropTypes.shape(WORKSPACE_PROPS)),
};
