import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button,
  SimpleConfirmModal,
} from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

const ConfirmButton = styled(Button.Simple.Primary)`
  flex: 1 1 100%;
  padding: ${() => theme.spacing.half} 0;
  margin-left: ${() => theme.spacing.half};
`;

const CancelButton = styled(Button.Simple)`
  flex: 1 1 100%;
  padding: ${() => theme.spacing.half} 0;
  margin-right: ${() => theme.spacing.half};
`;

const CancelRequestModal = ({
  isCancelRequestModalOpen,
  handleCancelButton,
  handleConfirmButton
}) => (
  <SimpleConfirmModal
    isOpen={isCancelRequestModalOpen}
    heading="Cancel feedback request?"
    subheading="Accidentally asked the wrong person? Or prefer to start a new request? Matter doesn't notify your peer when you cancel a request."
    buttonLeft={
      <CancelButton 
        size={'M'} 
        color={'black'}
        onClick={handleCancelButton}
      >
        Go Back
      </CancelButton>
    }
    buttonRight={
      <ConfirmButton 
        size={'M'} 
        onClick={handleConfirmButton}
      >
        Yes, Cancel
      </ConfirmButton>
    }
    onCancel={handleCancelButton}
  />
);

CancelRequestModal.propTypes = {
  isCancelRequestModalOpen: PropTypes.bool.isRequired,
  handleCancelButton: PropTypes.func.isRequired,
  handleConfirmButton: PropTypes.func.isRequired,
};

export default CancelRequestModal;