import { useEffect, useState } from 'react';

export const useFilterState = (propValue = [], valueKey = '') => {
  const [filters, setFilters] = useState(propValue);

  useEffect(() => {
    if (propValue !== filters) {
      setFilters(propValue);
    }
  }, [propValue]);

  return [
    filters,
    (e, { value }) => {
      setFilters(value);
      return { [valueKey]: value };
    },
    () => setFilters([]),
  ];
};
