import React, { useContext } from 'react';
import { 
  SettingsContainer
} from '../styles';
import AdminsSettingsHoc from '../AdminsSettingsHoc';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import { Resources } from '@matterapp/routing';
import FeedbackFridaySettingsPage from './FeedbackFridaySettings';
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import SettingsNavigation from '../SettingsNavigation';
import { useQueryParams } from 'hooks';

export default function FeedbackFridaySettings() {  
  const { currentWorkspaceId: workspaceId, currentTenant, currentWorkspace, isLoadingCurrentUser } = useContext(UserContext);
  const isLoading = !currentWorkspace || isLoadingCurrentUser;
  const { isEnterprise } = currentTenant || {};
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const settingsRedirectUrl = queryParams.isChannelSetting 
    ? Resources.workspaceSettingsMain.path({ workspaceId }, { isChannelSetting: true }) 
    : Resources.workspaceSettingsMain.path({ workspaceId });

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={(
        <AdminsSettingsHoc redirectUrl={settingsRedirectUrl}>
          <SettingsContainer>
            <SettingsNavigation 
              currentLabel='Feedback Friday'
              settingsRedirectUrl={settingsRedirectUrl} 
              isChannelSetting={isEnterprise}
            />
            {isLoading 
              ? null 
              : (
                <Paper elevation={0}>
                  <FeedbackFridaySettingsPage 
                    tenant={currentTenant}
                    workspace={currentWorkspace}
                    isLoading={isLoadingCurrentUser}
                    onClickCancel={() => navigate(settingsRedirectUrl)}
                  />
                </Paper>
              )}
          </SettingsContainer>
        </AdminsSettingsHoc>
      )}
    />
  );
}
