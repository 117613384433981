import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { ModalAdapter } from '../Modal';

const overlayClassName = 'matter-yellow-card--ReactModal__Overlay';
const contentClassName = 'matter-yellow-card--ReactModal__Content';
const TRANSITION_TIME = theme.transitions.times.singleAndHalf;

const CardModal = styled(ModalAdapter).attrs({
  overlayClassName,
  contentClassName,
})`
  .${overlayClassName} {
    z-index: 99;
    position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    background: rgba(46,46,51,.95);
  }

  .${contentClassName} {
    z-index: 100;
    position: relative;
    width: auto;
    margin: 0 auto;
    border-radius: 4px;
    background-color: #FFF;
  }

  .ReactModal__Content {
    .wowWrapper {
      opacity: 0;
      transition: opacity ${TRANSITION_TIME};
      transition-delay: 200ms;
    }
  }
  .ReactModal__Content--after-open {
    .wowWrapper {
      opacity: 1;
    }
  }
  .ReactModal__Content--before-close {
    .wowWrapper {
      opacity: 1;
    }
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity ${TRANSITION_TIME};
    -webkit-overflow-scrolling: touch;
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
    overflow: auto;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

CardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  contentLabel: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default CardModal;
