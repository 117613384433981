import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import omitStyled from '../../libs/omitStyled';

const Container = omitStyled('div', ['hasBorder'])`
  border-bottom: 1px solid #E4E4E4;
`;

const InnerContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin: 0 auto;
  max-width: 1190px;
`;

const Left = styled.div`
  flex: 1
  padding-left: 20px;
  text-align: left;
  > * {
    vertical-align: middle;
  }
`;

const Center = styled.div`
  flex: 1;
  text-align: center;
  > * {
    vertical-align: middle;
  }
`;

const Right = styled.div`
  flex: 1;
  padding-right: 20px;
  text-align: right;
  > * {
    vertical-align: middle;
  }
`;

const ActionHeader = ({ left, center, right }) => (
  <Container>
    <InnerContainer>
      <Left>{left}</Left>
      <Center>{center}</Center>
      <Right>{right}</Right>
    </InnerContainer>
  </Container>
);

ActionHeader.defaultProps = {
  left: null,
  center: null,
  right: null,
};

ActionHeader.propTypes = {
  left: PropTypes.node,
  center: PropTypes.node,
  right: PropTypes.node,
};

export default ActionHeader;
