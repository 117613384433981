import skillsPageIcon from '../../../assets/svgs/page-icons/skills-page-icon.svg';
import loopsPageIcon from '../../../assets/svgs/page-icons/loops-page-icon.svg';
import feedbackReceivedPageIcon from '../../../assets/svgs/trophy.svg';
import advisorsPageIcon from '../../../assets/svgs/page-icons/advisors-page-icon.svg';
import workspacesPageIcon from '../../../assets/svgs/page-icons/workspaces-page-icon.svg';
import continuousPageIcon from '../../../assets/svgs/page-icons/continuous-page-icon.svg';
import rewardsPageIcon from '../../../assets/svgs/page-icons/rewards-page-icon.svg';
import { skills } from '../../common/libs/skills-map';
import { genericSVGs } from '../../common/libs/generic-svgs-map';

const FALLBACK_ICON = 'Blue';

const pageIcons = [
  {
    name: 'skillsPageIcon',
    url: skillsPageIcon,
  },
  {
    name: 'feedbackReceivedPageIcon',
    url: feedbackReceivedPageIcon,
  },
  {
    name: 'advisorsPageIcon',
    url: advisorsPageIcon,
  },
  {
    name: 'loopsPageIcon',
    url: loopsPageIcon,
  },
  {
    name: 'workspacesPageIcon',
    url: workspacesPageIcon,
  },
  {
    name: 'continuousPageIcon',
    url: continuousPageIcon,
  },
  {
    name: 'rewardsPageIcon',
    url: rewardsPageIcon
  }
];

// normalize skills data to same format as pageIcons
const skillIcons = skills.map(({ name, svg }) => ({ name, url: svg }));

const genericIcons = Object.keys(genericSVGs)
  .map((key) => ({ name: key, url: genericSVGs[key]}));

const icons = [
  ...pageIcons,
  ...skillIcons,
  ...genericIcons
];

export const iconNames = icons.map(({ name }) => name);

/* genericSVGs are already imported in correct format */
const iconsMap = {};

icons.forEach((icon) => {
  iconsMap[icon.name] = icon;
});

export const getIconUrl = (iconName) => {
  const iconDetails = iconsMap[iconName] || iconsMap[FALLBACK_ICON];
  return iconDetails.url;

};
