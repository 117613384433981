import { useState } from 'react';

export const NO_OP = () => null;
export const isFunction = (func) => func && typeof func === 'function';

export const useCurrentStep = () => {
  const [currentStep, setCurrentStep] = useState(0);
  return {
    currentStep,
    incrementStep: () => setCurrentStep(currentStep + 1),
    decrementStep: () => setCurrentStep(currentStep - 1),
    resetStep: () => setCurrentStep(0),
  };
};

export const useProps = (props) => {
  const { handleCloseModal, isOpen, isModalOpen, onClose } = props;
  return {
    ...props,
    isOpen: isOpen || isModalOpen,
    onClose: (e) => {
      onClose(e, props);
      if (isFunction(handleCloseModal)) {
        handleCloseModal(e, props);
      }
    },
  };
};

export const useCurrentDisplayedStep = (currentDisplayedStep, params) => {
  const { handleGoBack, onGoBack = NO_OP } = currentDisplayedStep;
  return {
    ...currentDisplayedStep,
    onGoBack: (e, props) => {
      const propsToReturn = { ...props, ...params };
      onGoBack({ ...e, ...propsToReturn }, propsToReturn);
      if (isFunction(handleGoBack)) {
        handleGoBack({ ...e, ...propsToReturn }, propsToReturn);
      }
    },
  };
};

export const useFlowState = (props) => {
  const { isOpen, onClose, steps, ...otherProps } = useProps(props);
  const {
    currentStep,
    decrementStep,
    incrementStep,
    resetStep,
  } = useCurrentStep(0);
  const totalSteps = steps.length;
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === totalSteps - 1;
  const currentDisplayedStep = useCurrentDisplayedStep(
    steps[currentStep],
    props
  );
  const { onGoBack, renderBody, renderFooter, renderTopRight } =
    currentDisplayedStep || {};

  const handleClose = (e) => {
    resetStep();
    onClose(e, { ...props, currentStep });
  };

  const renderProps = {
    currentStep,
    currentStepIdx: currentStep,
    isFirstStep,
    isLastStep,
    onLastStep: isLastStep,
    handleClose,
    incrementStep: (e) => {
      if (currentStep + 1 >= totalSteps) {
        handleClose(e);
      } else {
        incrementStep();
      }
    },
    decrementStep: (e) => {
      if (currentStep - 1 < 0) {
        handleClose(e);
      } else {
        decrementStep();
      }
    },
    totalSteps,
  };

  return {
    ...otherProps,
    ...renderProps,
    currentDisplayedStep,
    isOpen,
    onClickBackStep: (e) => onGoBack(e, renderProps),
    renderProps,
    renderBody: () => (renderBody ? renderBody(renderProps) : null),
    renderFooter: () => (renderFooter ? renderFooter(renderProps) : null),
    renderTopRight: () => (renderTopRight ? renderTopRight(renderProps) : null),
    steps,
  };
};
