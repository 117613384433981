import styled from 'styled-components';
import { Box } from '@mui/material';
import { Peer, Button, ClickableLink, Radio, Dropdown } from '@matterapp/matter-ui';

export const ToggleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.blacks[5],
  width: '100%',
  padding: theme.spacing(2),
  marginBottom: '1px'
}));

export const SubHeader = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: `${theme.spacing(3)} 0`,
  justifyContent: 'space-between'
}));

export const PeerListItem = styled(Peer.ListItem)({
  position: 'relative',
  margin: 0,
  width: '100%'
});

export const RemoveButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '30%',
  right: 0,
  minHeight: 0,
  color: theme.palette.blacks[50]
}));

export const AllowanceBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));

export const InputWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(6),
  '.allowanceInputError': {
    position: 'absolute'
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    marginTop: theme.spacing(2)
  }
}));

export const ResetButton = styled(ClickableLink)(({ theme }) => ({
  position: 'absolute',
  top: `-${theme.spacing(3)}`,
  right: 0,
  zIndex: 1,
  '&:not(:hover):not(:active)': {
    color: theme.colors.blacks[50]
  },
  [theme.breakpoints.down('lg')]: {
    top: `-${theme.spacing(1)}`,
  }
}));

export const FrequencyWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  width: '50%',
  [theme.breakpoints.down('lg')]: {
    width: '100%'
  }
}));

export const EnpsFrequencyWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  width: '70%',
  [theme.breakpoints.down('lg')]: {
    width: '100%'
  }
}));

export const StyledRadio = styled(Radio.List.Settings)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1)
}));

export const SearchInput = styled(Dropdown.Email.Tags)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  'input&': {
    borderColor: theme.palette.blacks[30],
    borderRadius: theme.spacing(1),
    borderWidth: '1px'
  }
}));
