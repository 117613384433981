import React from 'react';
import PropTypes from 'prop-types';
import {
  ActivityFeedTab,
  SettingsTab,
  SettingsTabMobile,
  FeedbacksTab,
  RecurringTab,
  SkillsTab,
  RewardsStoreTab,
} from './styles';
import { WORKSPACE_PROPS, WORKSPACE_NAV_DEFAULT_PROPS } from './consts';

const NonAdminTabs = (props) => {
  const {
    activeWorkspaceTab,
    activeWorkspace,
    getFeedLink,
    getFeedbacksLink,
    getRecurringLink,
    getSkillsLink,
    getRewardsStoreLink,
    onClickSettings,
    isAdminOrOwner
  } = props;

  return (
    <>
      <ActivityFeedTab
        to={getFeedLink(activeWorkspace)}
        isActive={activeWorkspaceTab === 1}
      />
      <FeedbacksTab
        to={getFeedbacksLink(activeWorkspace)}
        isActive={activeWorkspaceTab === 2}
        isDisabled={activeWorkspace.isExpired}
      />
      <RecurringTab
        to={getRecurringLink(activeWorkspace)}
        isActive={activeWorkspaceTab === 3}
        isDisabled={activeWorkspace.isExpired}
      />
      <SkillsTab
        to={getSkillsLink(activeWorkspace)}
        isActive={activeWorkspaceTab === 4}
        isDisabled={activeWorkspace.isExpired}
      />
      <RewardsStoreTab
        to={getRewardsStoreLink(activeWorkspace)}
        isActive={activeWorkspaceTab === 5}
        isDisabled={activeWorkspace.isExpired}
      />
      {!isAdminOrOwner && 
        <>
          <SettingsTabMobile
            onClick={(e) =>
              onClickSettings(e, {
                ...props,
                workspace: activeWorkspace,
                shouldRedirect: true,
              })
            }
            isDisabled={activeWorkspace.isExpired}
          />
          <SettingsTab
            onClick={(e) =>
              onClickSettings(e, { ...props, workspace: activeWorkspace })
            }
            isDisabled={activeWorkspace.isExpired}
          />
        </>
      }
    </>
  );
};

NonAdminTabs.defaultProps = {
  activeWorkspace: {},
  ...WORKSPACE_NAV_DEFAULT_PROPS,
};

NonAdminTabs.propTypes = {
  activeWorkspace: PropTypes.shape(WORKSPACE_PROPS),
  ...WORKSPACE_PROPS,
};

export default NonAdminTabs;
