import { compose, withProps } from 'recompose';
import withRouter from 'hocs/withRouter';

const withRouteParams = (paramComputations) =>
  compose(
    withRouter,
    withProps(({ match, computedMatch }) => {
      const matchToUse = computedMatch || match;
      const paramValues = {};
      const paramNames = Object.keys(paramComputations);
      paramNames.forEach((paramName) => {
        const paramComputation = paramComputations[paramName];
        if (typeof paramComputation === 'boolean') {
          paramValues[paramName] = matchToUse.params[paramName];
        } else if (typeof paramComputation === 'function') {
          paramValues[paramName] = paramComputation(matchToUse.params);
        }
      });
      return paramValues;
    })
  );

export default withRouteParams;
