import React from 'react';
import styled from 'styled-components';
import { skillColorPalettePropType } from '@matterapp/skills';
import { getPalette } from '../../../../common/libs/skill-color-palettes';
import omitStyled from '../../../../libs/omitStyled';
import genericSkillIcon from './assets/generic-skill.svg';
import largeSprinkles from './assets/sprinkles-large.svg';
import smallSprinkles from './assets/sprinkles-small.svg';
import theme from '@matterapp/matter-theme';

const PreviewContainer = omitStyled('div', ['primaryColor'])`
  background: ${({ primaryColor }) => primaryColor || 'white'};
  height: 160px;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
`;

const Sprinkles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("${smallSprinkles}");
  ${() => theme.media.medium`
    background-image: url("${largeSprinkles}");
  `}
  width: 100%;
  height: 100%;
`;

const GenericSkill = styled.img.attrs({
  src: genericSkillIcon,
})`
  width: 96px;
  height: 96px;
  margin-top: 32px;
`;

const Preview = ({ selectedColorPalette }) => {
  if (!selectedColorPalette) { return null; }
  const palette = getPalette(selectedColorPalette);
  const primaryColor = palette && palette.primary;
  return (
    <PreviewContainer primaryColor={primaryColor}>
      <Sprinkles>
        <GenericSkill />
      </Sprinkles>
    </PreviewContainer>
  );
};

Preview.defaultProps = {
  selectedColorPalette: null,
};

Preview.propTypes = {
  selectedColorPalette: skillColorPalettePropType,
};

export default Preview;
