import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconNew as Icon, PopoverMenu, getSkillIcon } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & .popover-menu__menu {
    margin-top: ${theme.spacing.quarter};
    margin-left: ${theme.spacing.singleAndHalf};
    width: calc(100vw - ${theme.spacing.quintuple});
    & > div:first-child {
      transform: translateX(-${theme.spacing.singleAndHalf});
    }
  }

  ${theme.media.S`
    & .popover-menu__menu {
      margin-left: calc(-${theme.spacing.double} - ${theme.spacing.quarter});
      width: auto;
      & > div:first-child {
        transform: translateX(0);
      }
    }
  `}
`;

const SkillBadge = styled.img`
  height: 72px;
  width: 72px;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: ${theme.spacing.single};
  right: ${theme.spacing.single};
  cursor: pointer;
  & svg path {
    fill: ${theme.colors.blacks[30]};
  }
`;

const InfoIconContainer = styled.div`
  display: flex;
  padding: ${theme.spacing.half};
  opacity: ${({ showIcon }) => (showIcon ? 1 : 0)};
  transition: opacity 1s ${theme.transitions.times.singleAndHalf};
  & svg path {
    fill: ${({ isActive }) => theme.colors.blacks[isActive ? 50 : 30]};
  }
  ${theme.media.M`
    display: none;
    opacity: 0;
  `}
`;

const Contents = styled.div`
  min-width: 100%;
  padding: ${theme.spacing.double};
  text-align: center;
  ${theme.media.S`
    min-width: 300px;
  `}
`;

const SkillName = styled.div`
  color: ${theme.colors.blacks[80]};
  font-weight: ${theme.fontWeight.medium};
  font-size: 20px;
  margin-top: ${theme.spacing.threeQuarters};
`;

const DescriptionText = styled.div`
  color: ${theme.colors.blacks[80]};
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.regular};
  line-height: ${theme.lineHeight.M};
  margin-top: ${theme.spacing.single};
`;

export default class StepHeader extends React.PureComponent {
  static propTypes = {
    /** If step header is for current step. */
    isCurrentStep: PropTypes.bool,
    /** Callback when popover opens. */
    onOpenInfoPopover: PropTypes.func,
    /**  Callback when popover closes. */
    onCloseInfoPopover: PropTypes.func,
    /** The skill of the current step.  */
    skillToRate: PropTypes.shape({
      description: PropTypes.string,
      skill: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    onOpenInfoPopover: () => null,
    onCloseInfoPopover: () => null,
    skillToRate: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClosePopover = (e) => {
    this.props.onCloseInfoPopover(e);
    this.setState({ isOpen: false });
  };

  handleOpenPopover = (e) => {
    this.props.onOpenInfoPopover(e);
    this.setState({ isOpen: true });
  };

  renderPopoverContents = () => {
    const { skill } = this.props.skillToRate;
    const { description, name } = skill;
    const iconSrc = getSkillIcon(skill);
    return (
      <Contents>
        <CloseIconContainer>
          <Icon name="close" />
        </CloseIconContainer>
        <SkillBadge src={iconSrc} />
        <SkillName>{name}</SkillName>
        <DescriptionText>{description}</DescriptionText>
      </Contents>
    );
  };

  renderPopover = () => {
    return (
      <PopoverMenu
        hasTriangle
        isFullWidthMobile
        isOpen={this.state.isOpen}
        onOpen={this.handleOpenPopover}
        onClose={this.handleClosePopover}
        position="left"
        triggerComponent={
          <InfoIconContainer
            isActive={this.state.isOpen}
            showIcon={this.props.isCurrentStep}
          >
            <Icon name="infoCircle" />
          </InfoIconContainer>
        }
      >
        {this.renderPopoverContents()}
      </PopoverMenu>
    );
  };

  render() {
    const { isCurrentStep, skillToRate } = this.props;
    const { skill } = skillToRate;
    const { name } = skill;
    return (
      <Container>
        {name}
        {isCurrentStep && this.renderPopover()}
      </Container>
    );
  }
}
