"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.lineHeight = void 0;
const lineHeight = {
  XXXL: '48px',
  XXL: '40px',
  XL: '36px',
  L: '32px',
  M: '24px',
  S: '20px',
  base: '17px',
  XS: '16px'
};
exports.lineHeight = lineHeight;
var _default = lineHeight;
exports.default = _default;