import React from 'react';
import PropTypes from 'prop-types';
import ConfirmContainer from './Container';
import Modal from '../Modal';

class Confirm extends React.Component {  
  renderContent = () => {
    const {
      buttonRight,
      buttonLeft,
      heading,
      subheading,
      onCancel,
    } = this.props;

    return (
      <ConfirmContainer
        buttonRight={buttonRight}
        buttonLeft={buttonLeft}
        heading={heading}
        subheading={subheading}
        onCancel={onCancel}
      />
    );
  };

  render() {
    const {
      isOpen,
      onCancel,
      className,
      contentLabel,
      transparent
    } = this.props;
    return (
      <Modal
        className={className}
        isOpen={isOpen}
        onClose={onCancel}
        onRequestClose={onCancel}
        contentLabel={contentLabel}
        transparent={transparent}
        transition={Modal.transitions.FADE}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

Confirm.defaultProps = {
  contentLabel: 'Confirm Modal',
  buttonLeft: null,
};

Confirm.propTypes = {
  buttonLeft: PropTypes.node,
  buttonRight: PropTypes.node.isRequired,
  heading: PropTypes.node.isRequired,
  subheading: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  contentLabel: PropTypes.string,
  transparent: PropTypes.bool,
};

export default Confirm;
