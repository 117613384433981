import React from 'react';
import PropTypes from 'prop-types';
import { ListContainer, ButtonNew as Button } from '@matterapp/matter-ui';
import {
  BUTTON_LABEL_YES,
  BUTTON_LABEL_NO,
  BUTTON_LABEL_COMPLICATED,
} from '../../copy-consts';

class YesNoButtons extends React.Component {
  renderYesButton = () => {
    const { yesLabel, handleYes } = this.props;
    return (
      <div>
        <Button pill size="large" onClick={handleYes} rcLabel="yesButton">
          {yesLabel}
        </Button>
      </div>
    );
  };

  renderNoButton = (label, isComplicated) => {
    const { noLabel, handleNo } = this.props;
    const rcLabel = isComplicated ? 'complicatedButton' : 'noButton';
    return (
      <div>
        <Button pill size="large" onClick={handleNo} rcLabel={rcLabel}>
          {label || noLabel}
        </Button>
      </div>
    );
  };

  render() {
    const { isWithComplicated, complicatedLabel } = this.props;
    return (
      <ListContainer stacked animated>
        {this.renderYesButton()}
        {isWithComplicated && this.renderNoButton(complicatedLabel, true)}
        {this.renderNoButton()}
      </ListContainer>
    );
  }
}

YesNoButtons.propTypes = {
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  complicatedLabel: PropTypes.string,
  handleYes: PropTypes.func.isRequired,
  handleNo: PropTypes.func.isRequired,
  isWithComplicated: PropTypes.bool.isRequired,
};

YesNoButtons.defaultProps = {
  yesLabel: BUTTON_LABEL_YES,
  noLabel: BUTTON_LABEL_NO,
  complicatedLabel: BUTTON_LABEL_COMPLICATED,
};

export default YesNoButtons;
