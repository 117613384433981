import React, { useContext } from 'react';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import styled from 'styled-components';
import SlideOutMenu from 'components/SlideOutMenu/SlideOutMenu';
import { TOP_BAR_HEIGHT } from 'components/TopBar/TopBar';
import { Box, Typography, Icon } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconNew as MatterIcon } from '@matterapp/matter-ui';
import ChannelMobileMenu from 'components/MobileSubMenu/ChannelMobileMenu';
import FeedbackMobileMenu from 'components/MobileSubMenu/FeedbackMobileMenu';
import AdminMobileMenu from 'components/MobileSubMenu/AdminMobileMenu';

const createIconComponent = (name) => styled(MatterIcon).attrs(() => ({ name }))``;

const SliderWrapper = styled(SlideOutMenu)(({ theme, isOpen }) => ({
  display: 'none',
  height: `calc(100vh - 105px)`,
  [theme.breakpoints.down('lg')]: {
    top: TOP_BAR_HEIGHT,
    display: isOpen ? 'inherit' : 'none',
    borderTop: `solid 1px ${theme.palette.grey[10]}`
  }
}));

export const Tab = styled(Box)(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.colors.blacks[10]}`,
  color: `${theme.colors.blacks[isActive ? 80 : 50]}`,
  fontSize: theme.fontSize.S,
  fontWeight: `${theme.fontWeight[isActive ? 'bold' : 'regular']}`,
  padding: `${theme.spacing(2)} 0`,
  width: '100%',
  transition: 'color 0.2s'
}));

export const Tabs = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(3)}`,
  marginTop: theme.spacing(3)
}));

const TitleWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '200px'
});

const icons = {
  hash: TagIcon,
  back: ArrowBackIcon,
  rewards: createIconComponent('navigationRewards'),
  feedback: createIconComponent('navigationFeedbacks')
};

const StyledIcon = styled(Icon)({
  width: '24px',
  height: '24px',
  marginRight: '11px'
});

export function ChannelsSelect({ iconVariant, text, iconAction = () => {} }) {
  const Icon = icons[iconVariant];

  return (
    <TitleWrapper>
      <StyledIcon 
        onClick={iconAction} 
        component={Icon}
      />
      <Typography
        variant='h6' 
        noWrap
        sx={{ fontWeight: 'bold' }}
      >
        {text}
      </Typography>
    </TitleWrapper>
  );
}

export default function MobileSubMenu() {
  const { isMobileMenuOpen, isChannelMenuOpen, isFeedbackMenuOpen, isAdminMenuOpen } = useContext(MobileMenuContext);

  let innerComponent = null;

  if (isChannelMenuOpen) {
    innerComponent = <ChannelMobileMenu />;
  } else if (isFeedbackMenuOpen) {
    innerComponent = <FeedbackMobileMenu />;
  } else if (isAdminMenuOpen) {
    innerComponent = <AdminMobileMenu />;
  }

  return (
    <SliderWrapper isOpen={isMobileMenuOpen}>
      {innerComponent}
    </SliderWrapper>
  );
}