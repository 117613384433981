import React from 'react';
import { personPropType } from '@matterapp/people';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import Avatar from '../../Avatar';
import { CARD_WIDTH } from './consts';
import theme from '@matterapp/matter-theme';

const AVATAR_SIZE = 84;

const CardContainer = styled.div`
  border-radius: 12px;
  width: ${CARD_WIDTH}px;
`;

const Header = styled.div`
  background: ${() => theme.colors.purples[50]};
  border-radius: 12px 12px 0px 0px;
  padding-top: 24px;
  word-break: break-word;
  padding-bottom: 8px;
`;

const FullNameHeading = styled.h3`
  color: white;
  font-style: normal;
  font-weight: bold;
  line-height: 32px;
  font-size: 24px;
  text-align: center;
  width: 100%;

  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
`;

const HeadlineHeading = styled.h4`
  color: ${() => theme.colors.blues[40]};
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  width: 100%;

  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
`;

const BodyContainer = styled.div`
  text-align: center;
  position: relative;
`;
const AvatarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -${AVATAR_SIZE / 2}px;
`;

const AvatarContainerTop = styled.div`
  height: 42px;
  background: ${() => theme.colors.purples[50]};
`;

const AvatarContainerBottom = styled.div`
  height: 42px;
  background: white;
`;

const CardContentContainer = styled.div`
  background: white;
  border-radius: 0px 0px 12px 12px;
  padding-top: 8px;
  padding-bottom: 24px;
  padding-left: 48px;
  padding-right: 48px;
  text-align: center;
`;

const Card = ({ person, actions, defaultAvatarIsGenerated }) => (
  <CardContainer>
    <Header>
      <FullNameHeading>{person.fullName || person.email}</FullNameHeading>
      <HeadlineHeading>{person.headline}</HeadlineHeading>
    </Header>

    <BodyContainer>
      <AvatarContainerTop />
      <AvatarContainerBottom />
      <AvatarContainer>
        <Avatar
          defaultAvatarIsGenerated={defaultAvatarIsGenerated}
          url={person.photoUrl}
          email={person.email}
          size={AVATAR_SIZE}
          bordered
        />
      </AvatarContainer>
    </BodyContainer>

    <CardContentContainer>
      <div>{actions}</div>
    </CardContentContainer>
  </CardContainer>
);

Card.propTypes = {
  person: personPropType.isRequired, // eslint-disable-line
  actions: PropTypes.node.isRequired,
  defaultAvatarIsGenerated: PropTypes.bool,
};

export default withTheme(Card);
