import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 8.89543 0.895431 8 2 8H16.5858C17.1162 8 17.6249 8.21071 18 8.58579L25.4142 16C25.7893 16.3751 26 16.8838 26 17.4142V38C26 39.1046 25.1046 40 24 40H2C0.895431 40 0 39.1046 0 38V10ZM16.5858 10H2V38H24V17.4142L16.5858 10Z" transform="translate(2)" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M10 2C10 0.895431 10.8954 0 12 0H26.5858C27.1162 0 27.6249 0.210714 28 0.585786L35.4142 8C35.7893 8.37507 36 8.88378 36 9.41421V30C36 31.1046 35.1046 32 34 32H25V30H34V9.41421L26.5858 2H12V9H10V2Z" transform="translate(2)" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.98959 24.1465C5.4275 22.5844 5.4275 20.0517 6.98959 18.4896C8.55169 16.9275 11.0844 16.9275 12.6464 18.4896L13 18.8431C13.3905 19.2337 13.3905 19.8668 13 20.2574C12.6095 20.6479 11.9763 20.6479 11.5858 20.2574L11.2322 19.9038C10.4512 19.1228 9.18486 19.1228 8.40381 19.9038C7.62276 20.6849 7.62276 21.9512 8.40381 22.7322L10.8787 25.2071C11.6597 25.9882 12.9261 25.9882 13.7071 25.2071C14.0976 24.8166 14.7308 24.8166 15.1213 25.2071C15.5118 25.5976 15.5118 26.2308 15.1213 26.6213C13.5592 28.1834 11.0266 28.1834 9.46447 26.6213L6.98959 24.1465Z" transform="translate(2)" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.0104 24.8536C20.5725 26.4157 20.5725 28.9483 19.0104 30.5104C17.4483 32.0725 14.9157 32.0725 13.3536 30.5104L13 30.1569C12.6095 29.7663 12.6095 29.1332 13 28.7426C13.3905 28.3521 14.0237 28.3521 14.4142 28.7426L14.7678 29.0962C15.5488 29.8772 16.8151 29.8772 17.5962 29.0962C18.3772 28.3151 18.3772 27.0488 17.5962 26.2678L15.1213 23.7929C14.3403 23.0118 13.0739 23.0118 12.2929 23.7929C11.9024 24.1834 11.2692 24.1834 10.8787 23.7929C10.4882 23.4024 10.4882 22.7692 10.8787 22.3787C12.4408 20.8166 14.9734 20.8166 16.5355 22.3787L19.0104 24.8536Z" transform="translate(2)" fill={fill} />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
