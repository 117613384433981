import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7.615C12.447 7.615 16 3 16 3s3.553 4.615 12 4.615v6.44C28 20.635 23.1 26.32 16 29 8.9 26.32 4 20.635 4 14.055v-6.44zm18.239 6.059a1 1 0 0 0-1.478-1.348l-6.967 7.642-2.518-3.099a1 1 0 0 0-1.552 1.262l3.25 4a1 1 0 0 0 1.515.043l7.75-8.5z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
