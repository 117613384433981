import React from 'react';
import { 
  Box,
  Typography,
  Paper
} from '@mui/material';
import TextQuestionResponses from './TextQuestionResponses';
import SelectQuestionResponses from './SelectQuestionResponses';
import EnpsQuestionResponses from './EnpsQuestionResponses';
import {
  ResultsWrapper,
  StyledDivider
} from './styles';

export default function ResponsesBlock({ questions, responses, timezone, enps, totalSent }) {
  return (
    [...questions].sort((a, b) => a.sort - b.sort).map((question, idx) => {
      const { id: questionId, type, category } = question;
      const questionResponses = responses?.find((response) => response.questionId === questionId);
      const { responseCount = 0 } = questionResponses || {};

      let ResultsComponent = SelectQuestionResponses;

      if (type === 'text') {
        ResultsComponent = TextQuestionResponses;
      }

      if (category?.isEnps) {
        ResultsComponent = EnpsQuestionResponses;
      }

      return (
        <Paper sx={{ padding: 0, mt: 3, pb: 1 }} key={questionId}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ 
              minWidth: '88px', 
              minHeight: '88px', 
              textAlign: 'center', 
              display: 'flex', 
              alignItems: 'center', 
              flexDirection: 'row' 
            }}>
              <Typography variant='h2' sx={{ margin: '0 auto' }}>Q{idx + 1}</Typography>
            </Box>
            <Box sx={{ alignSelf: 'center', pt: 2, pb: 2 }}>
              <Typography variant='body1'><b>{question.question}</b></Typography>
              <Typography variant='body2' color='blacks.50'>{responseCount} out of {totalSent} people answered this question</Typography>
            </Box>
          </Box>
          <StyledDivider />
          <ResultsWrapper>
            <ResultsComponent 
              response={questionResponses} 
              question={question} 
              timezone={timezone}
              enpsData={enps}
            />
          </ResultsWrapper>
        </Paper>
      );
    })
  );
}
