
import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12C6 8.68971 8.68971 6 12 6C15.3103 6 18 8.68971 18 12C18 15.3103 15.3103 18 12 18C8.68971 18 6 15.3103 6 12ZM12 6.92308C9.19952 6.92308 6.92308 9.19952 6.92308 12C6.92308 14.8005 9.19952 17.0769 12 17.0769C14.8005 17.0769 17.0769 14.8005 17.0769 12C17.0769 9.19952 14.8005 6.92308 12 6.92308Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8415 9.80037C15.0368 9.99563 15.0368 10.3122 14.8415 10.5075L11.3083 14.0407C11.2145 14.1345 11.0874 14.1871 10.9548 14.1871C10.8221 14.1871 10.695 14.1345 10.6012 14.0407L9.18491 12.6244C8.98965 12.4291 8.98965 12.1126 9.18491 11.9173C9.38017 11.722 9.69675 11.722 9.89201 11.9173L10.9548 12.98L14.1344 9.80037C14.3297 9.60511 14.6463 9.60511 14.8415 9.80037Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
