import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import PeerCard from './PeerCard';
import GameOver from './GameOver';
import Loading from './Loading';
import { peer as peerPropType } from './prop-types';

import Panel from '../Panel';

const GAME_MODES = {
  WORK_RELATIONSHIP: 'WORK_RELATIONSHIP',
  REQUEST_FEEDBACK: 'REQUEST_FEEDBACK',
};
const ANIMATION_DURATION_MS = 240;

const GameContainer = styled(Panel.Base)`
  border: none;
  position: relative;
  height: 288px;
  min-height: 288px;
  max-height: 288px;
  overflow: hidden;
  .wrg-slide-exit {
    transform: translateY(0);
  }
  .wrg-slide-exit.wrg-slide-exit-active {
    opacity: 1;
    transform: translateY(-100%);
    transition: transform ${ANIMATION_DURATION_MS}ms,
      opacity ${ANIMATION_DURATION_MS}ms;
    transition-timing-function: linear;
    transition-delay: 0;
  }
`;

class Game extends React.Component {
  static PeerCard = PeerCard;
  static GameOver = GameOver;
  static Loading = Loading;

  constructor(props) {
    super(props);
    this.state = {
      peers: props.peers,
      curPeerIndex: 0,
      isGameOver: false,
      gameMode: GAME_MODES.WORK_RELATIONSHIP,
    };
  }

  componentWillUnmount = () => {
    clearTimeout(this.delayTimeout);
  };

  getHandleWorkRelationshipPeerAccept = ({ peer }) => async () => {
    const { handleWorkRelationshipPeerAccept } = this.props;
    await this.handleNextStep({ peer, isAccepted: true });
    await this.delay();
    await handleWorkRelationshipPeerAccept({ peer });
  };

  getHandleWorkRelationshipPeerReject = ({ peer }) => async () => {
    const { handleWorkRelationshipPeerReject } = this.props;
    await this.handleNextStep({ peer, isAccepted: false });
    await this.delay();
    await handleWorkRelationshipPeerReject({ peer });
  };

  getHandleRequestFeedbackPeerAccept = ({ peer }) => async () => {
    const { handleRequestFeedbackPeerAccept } = this.props;
    await this.handleNextStep({ peer, isAccepted: true });
    await this.delay();
    await handleRequestFeedbackPeerAccept({ peer });
  };

  getHandleRequestFeedbackPeerReject = ({ peer }) => async () => {
    const { handleRequestFeedbackPeerReject } = this.props;
    await this.handleNextStep({ peer, isAccepted: false });
    await this.delay();
    await handleRequestFeedbackPeerReject({ peer });
  };

  delay = () =>
    new Promise((resolve) => {
      this.delayTimeout = setTimeout(resolve, ANIMATION_DURATION_MS);
    });

  handleNextStep = async ({ peer, isAccepted }) => {
    const { handleViewRequestFeedbackMode } = this.props;
    const { curPeerIndex, peers, gameMode } = this.state;
    const nextPeerIndex = curPeerIndex + 1;

    if (
      gameMode === GAME_MODES.WORK_RELATIONSHIP &&
      !peer.isUser &&
      peer.isFeedbackRecommendation &&
      isAccepted
    ) {
      // switching to request feedback mode
      return new Promise((resolve) => {
        this.setState(
          {
            gameMode: GAME_MODES.REQUEST_FEEDBACK,
          },
          () => {
            resolve();
            handleViewRequestFeedbackMode({ peer });
          }
        );
      });
    } else if (nextPeerIndex >= peers.length) {
      // that was the last card and it's time to end the game
      return new Promise((resolve) => {
        this.setState(
          {
            isGameOver: true,
          },
          resolve
        );
      });
    } else if (gameMode === GAME_MODES.WORK_RELATIONSHIP) {
      // peer was rejected and there are more cards
      return new Promise((resolve) => {
        this.setState(
          {
            curPeerIndex: nextPeerIndex,
          },
          resolve
        );
      });
    } else if (gameMode === GAME_MODES.REQUEST_FEEDBACK) {
      // we're coming back from a request feedback mode
      return new Promise((resolve) => {
        this.setState(
          {
            curPeerIndex: nextPeerIndex,
            gameMode: GAME_MODES.WORK_RELATIONSHIP,
          },
          resolve
        );
      });
    }
    throw new Error('Unknown game state');
  };

  renderWorkRelationshipGameMode = () => {
    const curPeer = this.state.peers[this.state.curPeerIndex];

    return (
      <CSSTransition
        key={`${curPeer.email}-workRelationshipMode`}
        timeout={ANIMATION_DURATION_MS}
        classNames="wrg-slide"
      >
        <PeerCard.WorkRelationshipMode
          questionNumber={this.state.curPeerIndex}
          numTotalQuestions={this.state.peers.length}
          peer={curPeer}
          onPeerAccept={this.getHandleWorkRelationshipPeerAccept({
            peer: curPeer,
          })}
          onPeerReject={this.getHandleWorkRelationshipPeerReject({
            peer: curPeer,
          })}
        />
      </CSSTransition>
    );
  };

  renderRequestFeedbackGameMode = () => {
    const curPeer = this.state.peers[this.state.curPeerIndex];
    return (
      <CSSTransition
        key={`${curPeer.email}-requestFeedbackMode`}
        timeout={ANIMATION_DURATION_MS}
        classNames="wrg-slide"
      >
        <PeerCard.RequestFeedbackMode
          peer={curPeer}
          onPeerAccept={this.getHandleRequestFeedbackPeerAccept({
            peer: curPeer,
          })}
          onPeerReject={this.getHandleRequestFeedbackPeerReject({
            peer: curPeer,
          })}
        />
      </CSSTransition>
    );
  };

  renderCorrectState = () => {
    if (this.state.isGameOver) { return <GameOver />; }
    if (this.state.peers.length === 0) { return null; }
    if (this.state.gameMode === GAME_MODES.REQUEST_FEEDBACK) {
      return this.renderRequestFeedbackGameMode();
    }
    return this.renderWorkRelationshipGameMode();
  };

  render = () => (
    <GameContainer>
      <TransitionGroup>{this.renderCorrectState()}</TransitionGroup>
    </GameContainer>
  );
}

Game.propTypes = {
  peers: PropTypes.arrayOf(peerPropType).isRequired,
  handleWorkRelationshipPeerAccept: PropTypes.func.isRequired,
  handleWorkRelationshipPeerReject: PropTypes.func.isRequired,
  handleRequestFeedbackPeerAccept: PropTypes.func.isRequired,
  handleRequestFeedbackPeerReject: PropTypes.func.isRequired,
  handleViewRequestFeedbackMode: PropTypes.func.isRequired,
};

export default Game;
