import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 327px;
  max-width: 327px;

  margin: 0 auto;

  form {
    width: 100%;
  }

  input[type='email'] {
    width: 100%;
    height: 48px;
  }

  ${() => theme.media.medium`
    align-items: center;
    flex-direction: column;
    width: 540px;
    max-width: 540px;

    form {
      flex-grow: 1;
      width: auto;
    }

    .button,
    input[type=email] {
      width: 540px;
    }
  `}

  ${() => theme.media.large`
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    max-width: 100%;

    .button {
      width: auto;
    }

    input[type=email] {
      width: 100%;
    }

  `}
`;

const InputContainer = styled.div`
  margin-right: 0;
  margin-bottom: 8px;

  ${() => theme.media.medium`
    margin-right: 0;
    margin-bottom: 8px;
  `}

  ${() => theme.media.large`
    margin-right: 16px;
    margin-bottom: 0;
  `}
`;

const InlineForm = ({ handleSubmit, renderInput, renderButton }) => (
  <FormContainer>
    <form onSubmit={handleSubmit}>
      <InputContainer>{renderInput()}</InputContainer>
    </form>
    {renderButton()}
  </FormContainer>
);

InlineForm.propTypes = {
  renderInput: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  renderButton: PropTypes.func.isRequired,
};

export default InlineForm;
