import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const iconSize = 24;
const indicatorSize = 8;

const transitionParams = '150ms ease';

const TabContainer = styled.div`
  height: 100%;
  box-sizing: border-box;

  > * {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition:
      transform ${transitionParams},
      border-bottom ${transitionParams},
      border-top ${transitionParams};

    color: ${() => theme.colors.blues[40]};
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
  }

  cursor: pointer;

  transition: color ${transitionParams};
  flex-grow: 1;

  svg path {
    transition: fill ${transitionParams};
    fill: ${() => theme.colors.blues[40]};
  }

  ${({ isActive }) =>
    isActive &&
    `
    color: white !important;

    svg path {
      fill: white;
    }

    > * {
      color: white;
      border-top: 4px solid transparent;
      border-bottom: 4px solid ${theme.colors.yellow} !important;
    }
  `}

  ${() => theme.media.medium`
    flex-grow: 0;

    &:hover {
      > * {
        color: inherit;
        border-top: 0px solid transparent;
        border-bottom: 4px solid ${theme.colors.blues[40]};
      }
    }

    > *:active {
      text-decoration: none;
    }
  `}

  ${() => theme.media.large`
    svg {
      margin-right: 10px;
    }
  `}
`;

const NotificationContainer = styled.div`
  height: ${iconSize}px;
  width: 0;
  position: relative;
`;

const NotificationIndicator = styled.div`
  position: absolute;
  left: ${iconSize - indicatorSize / 2}px;
  top: ${-indicatorSize / 2}px;
  width: ${indicatorSize}px;
  height: ${indicatorSize}px;
  border-radius: 50%;

  background: ${() => theme.colors.oranges[50]};

  ${() => theme.media.large`
    right: auto;
    left: ${-indicatorSize / 2}px;
  `}
`;

const Text = styled.span`
  display: none;

  ${() => theme.media.large`
    display: block;
  `}
`;

const Tab = ({
  isActive,
  hasNotification,
  children,
  iconComponent: IconComponent,
  renderLink,
}) => (
  <TabContainer role="Menuitem" isActive={isActive}>
    {renderLink(
      <React.Fragment>
        <NotificationContainer>
          {hasNotification && <NotificationIndicator />}
        </NotificationContainer>
        <IconComponent />
        <Text>{children}</Text>
      </React.Fragment>
    )}
  </TabContainer>
);

Tab.defaultProps = {
  isActive: false,
  hasNotification: false,
  renderLink: (children) => <div>{children}</div>,
};

Tab.propTypes = {
  isActive: PropTypes.bool,
  hasNotification: PropTypes.bool,
  children: PropTypes.node.isRequired,
  renderLink: PropTypes.func,
  iconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    .isRequired,
};

export default Tab;
