import PropTypes from 'prop-types';
import styled from 'styled-components';
import Adapter from '../components/Adapter';

const overlayClassName = 'matter-ReactModal__Overlay';
const contentClassName = 'matter-ReactModal__Content';

const ModalVideo = styled(Adapter).attrs({
  overlayClassName,
  contentClassName,
})`
  .${overlayClassName} {
    z-index: 10000; // Needs to be on top of Yolo at 9999
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
  }

  .${contentClassName} {
    background: transparent;
    outline: 0;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.15s linear;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

ModalVideo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  contentLabel: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalVideo;
