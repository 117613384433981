import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  CompactContainerWrapper,
  Link,
  LinkContents,
  IconContainer,
  NotificationIndicator,
  NumberNotificationIndicator,
  Text,
  MENU_ITEM_ROLE,
  MENU_ITEM_SECONDARY_ROLE,
} from './styles';

const Tab = (props) => {
  const {
    autoColorIcon,
    className,
    getPath,
    numberOfNotifications,
    hasNotification,
    hasSecondaryTabs,
    hideIconOnMobile,
    href,
    isActive,
    isCompact,
    isDisabled,
    isHidden,
    isSecondary,
    icon,
    iconComponent: IconComponent,
    label,
    linkProps = {},
    onClick,
    title,
    to,
  } = props;
  const hasIcon = icon || !!IconComponent;
  if (isHidden) {
    return null;
  }

  const iconToShow = (
    <IconContainer hasIcon={hasIcon} hideIconOnMobile={hideIconOnMobile}>
      {hasNotification && <NotificationIndicator isActive={isActive}/>}
      {hasIcon && !!IconComponent && <IconComponent />}
      {hasIcon && !IconComponent && icon}
    </IconContainer>
  );
  const sharedLinkProps = {
    autoColorIcon,
    href,
    onClick,
    to: getPath(props) || to,
    isActive,
    isDisabled,
    isCompact,
    isSecondary,
    ...linkProps,
  };
  const sharedLinkContentsProps = {
    autoColorIcon,
    isActive,
    isDisabled,
    hasSecondaryTabs,
  };

  if (isCompact) {
    return (
      <Container className={className} data-role={isSecondary ? MENU_ITEM_SECONDARY_ROLE : MENU_ITEM_ROLE} isSecondary={isSecondary} title={title} isDisabled={isDisabled} >
        <CompactContainerWrapper>
          <LinkContents {...sharedLinkContentsProps}>
            {iconToShow}
            <Link {...sharedLinkProps}>
              <Text isCompact isDisabled={isDisabled}>{label}</Text>
            </Link>
          </LinkContents>
        </CompactContainerWrapper>
      </Container>
    );
  }

  return (
    <Container className={className} data-role={isSecondary ? MENU_ITEM_SECONDARY_ROLE : MENU_ITEM_ROLE} isSecondary={isSecondary} title={title} isDisabled={isDisabled}>
      <Link {...sharedLinkProps}>
        <LinkContents {...sharedLinkContentsProps}>
          {iconToShow}
          <Text isCompact={isSecondary} isDisabled={isDisabled}>
            {label}
            {numberOfNotifications ? <NumberNotificationIndicator isActive={sharedLinkContentsProps.isActive}>{numberOfNotifications}</NumberNotificationIndicator> : null}
          </Text>
        </LinkContents>
      </Link>
    </Container>
  );
};

Tab.defaultProps = {
  autoColorIcon: true,
  isActive: false,
  getPath: () => null,
  hasNotification: false,
};

Tab.propTypes = {
  /** If icon in tab should be auto-colored. */
  autoColorIcon: PropTypes.bool,
  /** Additional style class. */
  className: PropTypes.string,
  /** Generate dynamic 'To' path from props. */
  getPath: PropTypes.func,
  /** If tab has a notification. */
  hasNotification: PropTypes.bool,
  /** If tab has secondary tabs under it. */
  hasSecondaryTabs: PropTypes.bool,
  /** If href is passed, a basic `a` link will render. */
  href: PropTypes.string,
  /** If tab is active (current tab). */
  isActive: PropTypes.bool,
  /** If tab is compact style. */
  isCompact: PropTypes.bool,
  /** If tab is hidden. */
  isHidden: PropTypes.bool,
  /** If secondary level tab. */
  isSecondary: PropTypes.bool,
  /** Custon icon to use if iconComponent is not passed in. */
  icon: PropTypes.node,
  /** Icon to display on the tab. */
  iconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.elementType]),
  /** The label of the tab. */
  label: PropTypes.node.isRequired,
  /** Additional props passed to the rendered link. */
  linkProps: PropTypes.object,
  /** Callback if tab is clicked. */
  onClick: PropTypes.func,
  /** If `to` prop is passed, tab will render a link to the given path. */
  to: PropTypes.string,
};

export default Tab;
