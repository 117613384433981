import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import SkillDetailsBanner from '../../skillDetailsBanner.svg';

export const Heading = styled.div`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.M};
  line-height: ${theme.spacing.double};
`;

export const Body = styled.div`
  color: ${({theme}) => theme.colors.blacks[50]};
  font-size: 17px;
`;

export const ImageContainer = styled.div`
  width: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const Background = styled.img.attrs({
  src: SkillDetailsBanner
})`
  bottom: 0;
  right: 50%;
  display: none;
  height: 100px;
  ${theme.media.S`
    position: absolute;
    right: -30px;
    transform: translateX(0%);
    display: block;
    height: 180px;
    top: 20px;
  `}
  ${theme.media.M`
    height: 200px;
    top: 10px;
  `}
`;

export const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${theme.colors.yellows[20]};
  border: 1px solid ${theme.colors.yellows[40]};
  padding: ${theme.spacing.singleAndHalf};
  margin-bottom: ${theme.spacing.singleAndHalf};
  margin-top: -${theme.spacing.singleAndHalf};
  position: relative;
  text-align: center;
  color: ${theme.colors.blacks[80]};
  ${theme.media.S`
    justify-content: flex-start;
    padding: 28px 0 28px ${theme.spacing.singleAndHalf};
    margin-top: 0;
    text-align: left;
    border-radius: ${theme.borderRadius.L};
    height: 200px;
    overflow: hidden;
  `}
`;

export const BannerContent = styled.div`
  width: 85%;
  ${theme.media.XS`
    width: 66%;
    margin: 0;
  `}
  ${theme.media.S`
    width: 450px;
    margin: 0;
  `}
  ${theme.media.M`
    width: 300px;
    position: absolute;
    left: 24px;
    margin: 0;
  `}
  ${theme.media.L`
    width: 350px;
    margin: 0;
  `}
`;