import React from 'react';
import BackgroundAnimation, { ANIMATIONS } from '../../BackgroundAnimation';
import ColoredBackground from '../../ColoredBackground';
import gsap from 'gsap';
import * as S from './styles';

export default class SecondToLastBackground extends BackgroundAnimation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  /**
   * Moves the clouds in.
   * @returns { Object } The animation timeline.
   */
  moveCloudsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 1 };
    tl.to(this.cloudRef, { x: -160 });

    tl.to(this.cloudRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the clouds out.
   * @returns { Object } The animation timeline.
   */
  moveCloudsOut = () => {
    const tl = gsap.timeline({ defaults: { opacity: 0, duration: 0.3 } });
    const resetProps = { x: 0, y: 0, duration: 0 };
    tl.to(this.cloudRef, resetProps);

    tl.to(this.cloudRef, { x: -90, y: -200 }, '<');
    return tl;
  };

  /**
   * Moves the planets in.
   * @returns { Object } The animation timeline.
   */
  movePlanetsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { opacity: 1, x: 0, duration: 1 };
    tl.to(this.leftPlanetRef, { x: -130, opacity: 0 });
    tl.to(this.rightPlanetRef, { x: 130, opacity: 0 });
    tl.to(this.topRightPlanet, { x: 160, opacity: 0 });

    tl.to(this.leftPlanetRef, resetProps, '<');
    tl.to(this.rightPlanetRef, resetProps, '<');
    tl.to(this.topRightPlanet, resetProps, '<');
    return tl;
  };

  /**
   * Moves the planets out.
   * @returns { Object } The animation timeline.
   */
  movePlanetsOut = () => {
    const tl = gsap.timeline({ defaults: { opacity: 0, duration: 0.6 } });
    const initialProps = { opacity: 1, x: 0, duration: 0 };
    tl.to(this.leftPlanetRef, initialProps);
    tl.to(this.rightPlanetRef, initialProps);
    tl.to(this.topRightPlanet, initialProps);

    tl.to(this.leftPlanetRef, { x: -120 }, '<');
    tl.to(this.rightPlanetRef, { x: 120 }, '<');
    tl.to(this.topRightPlanet, { x: 160 }, '<');
    return tl;
  };

  /**
   * Moves the robot in.
   * @returns { Object } The animation timeline.
   */
  moveRobotIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { opacity: 1, x: 0, y: 0, duration: 0.8 };
    tl.to(this.robotRef, { x: 130, y: 200, opacity: 0 });
    tl.to(this.robotRef, resetProps, '<0.5');
    return tl;
  };

  /**
   * Moves the stars in.
   * @returns { Object } The animation timeline.
   */
  moveStarsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 0, opacity: 0, transformOrigin: 'center' };
    const zoomProps = { scale: 1.4, opacity: 1, duration: 0.3 };
    const normalizedProps = { scale: 1, duration: 0.1 };
    tl.to(this.leftPlus1Ref, initialProps);
    tl.to(this.leftPlus2Ref, initialProps);
    tl.to(this.rightPlusRef, initialProps);
    tl.to(this.bottomRightPlus1Ref, initialProps);
    tl.to(this.bottomRightPlus2Ref, initialProps);
    tl.to(this.bottomRightPlus3Ref, initialProps);

    tl.to(this.leftPlus1Ref, zoomProps, '<0.6');
    tl.to(this.leftPlus1Ref, normalizedProps, '>');

    tl.to(this.leftPlus2Ref, zoomProps, '<-0.4');
    tl.to(this.leftPlus2Ref, normalizedProps, '>');

    tl.to(this.rightPlusRef, zoomProps, '<-0.3');
    tl.to(this.rightPlusRef, normalizedProps, '>');

    tl.to(this.bottomRightPlus1Ref, zoomProps, '<-0.2');
    tl.to(this.bottomRightPlus1Ref, normalizedProps, '>');

    tl.to(this.bottomRightPlus2Ref, zoomProps, '<-0.1');
    tl.to(this.bottomRightPlus2Ref, normalizedProps, '>');

    tl.to(this.bottomRightPlus3Ref, zoomProps, '<');
    tl.to(this.bottomRightPlus3Ref, normalizedProps, '>');
    return tl;
  };

  /**
   * Moves the rings in.
   * @returns { Object } The animation timeline.
   */
  moveRingsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 2, opacity: 0, transformOrigin: 'center' };
    const normalizedProps = { scale: 1, opacity: 1, duration: 0.4 };
    const ringRefs = {
      leftRingRef: '<0.1',
      topRightRingRef: '<0.2',
      centerRingRef: '<0.1',
      bottomLeftRingRef: '<0.2',
      bottomRightRingRef: '<-0.1',
    };
    Object.keys(ringRefs).forEach((key) => {
      tl.to(this[key], initialProps);
    });
    Object.keys(ringRefs).forEach((key) => {
      tl.to(this[key], normalizedProps, ringRefs[key]);
    });
    return tl;
  };

  /**
   * Moves the dots in.
   * @returns { Object } The animation timeline.
   */
  moveDotsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 0.7 };
    const dotRefs = {
      topDot1Ref: { x: -30, y: 65 },
      topDot2Ref: { x: 57, y: 25 },
      leftDotRef: { x: 78, y: -43 },
      centerDotRef: { x: -62, y: -38 },
      rightDot1Ref: { x: 34, y: 42 },
      rightDot2Ref: { x: -12, y: -53 },
      rightDot3Ref: { x: 42, y: -62 },
      bottomRightDotRef: { x: -19, y: -92 },
    };
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], dotRefs[key]);
    });
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], resetProps, '<');
    });
    return tl;
  };

  playAnimation = () => {
    const { animation } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    if (animation !== ANIMATIONS.SHOW) {
      if (animation === ANIMATIONS.OUT) {
        tl.add(this.moveCloudsOut(), '<');
        tl.add(this.movePlanetsOut(), '<');
      } else {
        tl.add(this.moveCloudsIn(), '<');
        tl.add(this.movePlanetsIn(), '<');
        tl.add(this.moveRobotIn(), '<');
        tl.add(this.moveStarsIn(), '<');
        tl.add(this.moveRingsIn(), '<');
        tl.add(this.moveDotsIn(), '<0.3');
      }
    }
    this.tl = tl;
    return tl;
  };

  render() {
    return (
      <ColoredBackground {...this.props}>
        <S.Cloud ref={(ref) => (this.cloudRef = ref)} />
        <S.Robot ref={(ref) => (this.robotRef = ref)}>
          <S.RobotHead />
          <S.RobotBody />
        </S.Robot>
        <S.LeftPlanetGroup ref={(ref) => (this.leftPlanetRef = ref)}>
          <S.LeftPlanetBackground />
          <S.LeftPlanetOverlay />
        </S.LeftPlanetGroup>
        <S.RightPlanetGroup ref={(ref) => (this.rightPlanetRef = ref)}>
          <S.RightPlanetBackground />
          <S.RightPlanetOverlay />
        </S.RightPlanetGroup>
        <S.TopDot1 ref={(ref) => (this.topDot1Ref = ref)} />
        <S.TopDot2 ref={(ref) => (this.topDot2Ref = ref)} />
        <S.TopRightPlanet ref={(ref) => (this.topRightPlanet = ref)} />
        <S.TopRightRing ref={(ref) => (this.topRightRingRef = ref)} />
        <S.LeftRing ref={(ref) => (this.leftRingRef = ref)} />
        <S.LeftPlus1 ref={(ref) => (this.leftPlus1Ref = ref)} />
        <S.LeftPlus2 ref={(ref) => (this.leftPlus2Ref = ref)} />
        <S.LeftDot ref={(ref) => (this.leftDotRef = ref)} />
        <S.CenterRing ref={(ref) => (this.centerRingRef = ref)} />
        <S.CenterDot ref={(ref) => (this.centerDotRef = ref)} />
        <S.RightPlus ref={(ref) => (this.rightPlusRef = ref)} />
        <S.RightDot1 ref={(ref) => (this.rightDot1Ref = ref)} />
        <S.RightDot2 ref={(ref) => (this.rightDot2Ref = ref)} />
        <S.RightDot3 ref={(ref) => (this.rightDot3Ref = ref)} />
        <S.BottomLeftRing ref={(ref) => (this.bottomLeftRingRef = ref)} />
        <S.BottomRightPlus1 ref={(ref) => (this.bottomRightPlus1Ref = ref)} />
        <S.BottomRightPlus2 ref={(ref) => (this.bottomRightPlus2Ref = ref)} />
        <S.BottomRightPlus3 ref={(ref) => (this.bottomRightPlus3Ref = ref)} />
        <S.BottomRightRing ref={(ref) => (this.bottomRightRingRef = ref)} />
        <S.BottomRightDot ref={(ref) => (this.bottomRightDotRef = ref)} />
      </ColoredBackground>
    );
  }
}
