import { compose, withProps } from 'recompose';
import { withGuides } from '../../../hocs';
import Guide from '../components/Guide';

const withData = compose(
  withGuides,
  withProps(({ guides }) => ({
    curGuide: guides.length > 0 ? guides[0] : null,
  })),
  withProps(({ curGuide, dismissGuide }) => ({
    handleDismiss: !curGuide
      ? () => null
      : () => {
        dismissGuide({ name: curGuide.name });
      },
  }))
);

export default withData(Guide);
