import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.78414 8.19784C6.63357 8.83352 4.12673 10.8898 2.79013 12.6129C2.45162 13.0493 1.82345 13.1287 1.38707 12.7901C0.950681 12.4516 0.871334 11.8235 1.20984 11.3871C2.78141 9.3611 5.73521 6.9231 9.54693 6.20649C13.428 5.47686 18.0142 6.5664 22.694 11.2796C22.7338 11.318 22.7701 11.3594 22.8027 11.4034C22.8909 11.5217 22.9495 11.6551 22.9785 11.7934C23.0194 11.9881 23.0018 12.1925 22.9258 12.3786C22.5544 13.3453 21.8038 14.7834 20.7146 16.0757C19.624 17.3697 18.1034 18.623 16.1843 18.9829C15.6414 19.0847 15.1189 18.7271 15.0171 18.1843C14.9153 17.6415 15.2729 17.1189 15.8157 17.0171C17.0966 16.777 18.2426 15.9053 19.1853 14.7868C19.9108 13.926 20.4622 12.9815 20.8125 12.2355C18.5918 10.1167 16.4697 8.93488 14.5109 8.3863C15.6527 9.18116 16.4 10.5033 16.4 12C16.4 14.4301 14.43 16.4 12 16.4C9.56992 16.4 7.59998 14.4301 7.59998 12C7.59998 10.3779 8.47771 8.96085 9.78414 8.19784ZM14.7449 10.078C15.246 10.8391 15.241 11.7269 14.7337 12.0611C14.2263 12.3952 13.4087 12.0491 12.9075 11.288C12.4063 10.5269 12.4113 9.63912 12.9187 9.30499C13.4261 8.97086 14.2437 9.31697 14.7449 10.078Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#828285',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
