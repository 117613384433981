import React from 'react';
import { 
  Box,
  Typography,
  Divider
} from '@mui/material';
import { ToggleContainer, ToggleWrapper, ToggleUnit } from 'components/ToggleGroup';

export default function HrisFieldsPanel({ tenant, hasHris, syncFields, setSyncFields }) {
  const { hrisSettings } = tenant || {};
  const { status } = hrisSettings || {};

  if (!hasHris || !status || status === 'disconnected') {
    return null;
  }

  function toggleField(field) {
    if (syncFields.includes(field)) {
      setSyncFields(syncFields.filter(f => f !== field));
    } else {
      setSyncFields([...syncFields, field]);
    }
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Divider sx={{ mb: 6, mt: 6 }} />
      <Typography variant='h4' sx={{ mb: 2 }}>
        Standard Fields
      </Typography>
      <Typography variant='body1' sx={{ mb: 2 }}>
        Import standard fields provided by your HRIS. Matter will consider the HRIS the source of truth for all of these fields.
      </Typography>
      <ToggleWrapper>
        <ToggleContainer>
          Date of Birth
          <ToggleUnit 
            checked={syncFields.includes('birthday')}
            onChange={() => toggleField('birthday')}
          />
        </ToggleContainer>
        <ToggleContainer>
          Hire Date
          <ToggleUnit 
            checked={syncFields.includes('hired_on')}
            onChange={() => toggleField('hired_on')}
          />
        </ToggleContainer>
      </ToggleWrapper>
    </Box>
  );

};
