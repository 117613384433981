import { SkillColorPalette } from '@matterapp/skills';

export const genericSVGs = {
  [SkillColorPalette.Blue]: "https://static.matterapp.com/static-assets/generic_blue-1589766294183.svg",
  [SkillColorPalette.Green]: "https://static.matterapp.com/static-assets/generic_green-1589766362018.svg",
  [SkillColorPalette.Orange]: "https://static.matterapp.com/static-assets/generic_orange-1589766402137.svg",
  [SkillColorPalette.Pink]: "https://static.matterapp.com/static-assets/generic_pink-1589766426826.svg",
  [SkillColorPalette.Purple]: "https://static.matterapp.com/static-assets/generic_purple-1589766447516.svg",
  [SkillColorPalette.Red]: "https://static.matterapp.com/static-assets/generic_red-1589766465148.svg",
  [SkillColorPalette.Yellow]: "https://static.matterapp.com/static-assets/generic_yellow-1589766491676.svg",
};