import React from 'react';
import LeftToRight1 from '../Paths/LeftToRight1';
import BasePath from './BasePath';

const Path2 = React.forwardRef((props, ref) => (
  <BasePath
    marginBottom={-12.9}
    PathComponent={LeftToRight1}
    pointProps={{ top: 14, left: 97.8 }}
    ref={ref}
    {...props}
  />
));

export default Path2;