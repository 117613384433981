import styled from 'styled-components';

export const LayoutContainer = styled.div`
  position: relative;
`;

export const Wrapper = styled.label`
  display: flex;
  flex-direction: column;
`;

export const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
