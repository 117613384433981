import React from 'react';
import PropTypes from 'prop-types';
import { skillValueObjectPropType } from '@matterapp/skills';
import MatterPill from '../../Label';
import {
  getSkillPrimaryColor,
  SKILL_FONT_COLOR,
} from '../../../common/libs/get-skill-details';

const Basic = ({
  skill,
  text,
  size,
  marginBottom,
  block,
  className,
  colorless,
}) => (
  <MatterPill
    marginBottom={marginBottom}
    block={block}
    text={text || ''}
    backgroundColor={getSkillPrimaryColor(skill)}
    fontColor={SKILL_FONT_COLOR}
    size={size}
    className={className}
    colorless={colorless}
  />
);

Basic.defaultProps = {
  size: 'small',
  text: null,
  marginBottom: 0,
  block: false,
  className: null,
  colorless: false,
};

Basic.propTypes = {
  skill: skillValueObjectPropType.isRequired, // eslint-disable-line
  text: PropTypes.string,
  size: PropTypes.string,
  marginBottom: PropTypes.number,
  block: PropTypes.bool,
  className: PropTypes.string,
  colorless: PropTypes.bool,
};

export default Basic;
