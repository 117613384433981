import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border-top: 1px solid ${() => theme.colors.blacks[10]};
`;

const YellowCardFooter = ({ left, right, center }) => (
  <Container>
    {center ? (
      center
    ) : (
      <>
        <div>{left}</div>
        <div style={{ marginLeft: 'auto', flex: 'none' }}>{right}</div>
      </>
    )}
  </Container>
);

YellowCardFooter.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  center: PropTypes.node,
};

YellowCardFooter.defaultProps = {
  left: null,
  right: null,
  center: null,
};

export default YellowCardFooter;
