import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import starburstPurple from '../../../assets/svgs/starburst.purple.svg';
import Shockwave from './Shockwave';

const StarbustContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 72px;
  left: 50%;
  margin-left: -240px;
  width: 480px;
  height: 480px;

  background-image: url(${starburstPurple});
  background-repeat: no-repeat;
  background-position: 50% 50%;

  ${() => theme.media.medium`
    top: 16vh;
  `}
`;

const Starburst = ({ shockwave }) => (
  <StarbustContainer>{shockwave && <Shockwave />}</StarbustContainer>
);

Starburst.defaultProps = {
  shockwave: false,
};

Starburst.propTypes = {
  shockwave: PropTypes.bool,
};

export default Starburst;
