import { connect } from 'react-redux';
import { createGuide, dismissGuide } from '../reducers';

const withData = connect(
  (state) => ({
    guides: state.guides,
  }),
  (dispatch) => ({
    createGuide: ({ name }) => {
      dispatch(createGuide({ name }));
    },
    dismissGuide: ({ name }) => {
      dispatch(dismissGuide({ name }));
    },
  })
);

export default withData;
