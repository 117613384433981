import React, { useState } from 'react';
import { Modal, Confirm } from '@matterapp/matter-ui';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import deletePulseSubcategory from 'graphql-queries/mutations/pulse/deletePulseSubcategory';
import getTenantPulseCategories from 'graphql-queries/queries/pulse/getTenantPulseCategories';
import getTenantPulseQuestions from 'graphql-queries/queries/pulse/getTenantPulseQuestions';
import { useMutation } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import { StyledButton } from './styles';

const LineWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'center', 
  justifyContent: 'space-between',
  padding: `${theme.spacing(1)} 0`,
  borderBottom: `1px solid ${theme.palette.blacks[20]}`,
  '&:first-of-type': {
    borderBottom: 'none'
  }
}));

const NoSubcategoriesWrapper = styled(Box)({
  height: '140px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

const NoSub = styled(Typography)(({ theme }) => ({
  color: theme.palette.blacks[60], 
  textAlign: 'center'
}));

const StyledModal = styled(Modal)({
  zIndex: 100
});

const Body = styled(Modal.Body)(({ theme }) => ({
  padding: theme.spacing(3)
}));

const StyledConfirm = styled(Confirm)(({
  zIndex: 101
}));

export default function SubcategoriesModal(props) {
  const {
    isOpen,
    onClose,
    tenantId,
    category,
    questions,
    onAddSubcategory
  } = props;
  const [deleteSubcategory] = useMutation(deletePulseSubcategory);
  const [deletingId, setDeletingId] = useState(null);
  const { subcategories, name: categoryName } = category;
  const categoryToDelete = deletingId ? subcategories.find(({ id }) => id === deletingId) : null;
  let questionsWithDeletedSubcategory = 0;

  if (categoryToDelete) {
    questionsWithDeletedSubcategory = questions.filter(({ subcategory }) => subcategory?.id === deletingId).length;
  }

  return (
    <StyledModal 
      isOpen={isOpen} 
      onClose={onClose}
      size="large"
    >
      <Modal.Header
        header={categoryName}
        right={<Modal.Buttons.CloseIcon onClick={onClose} />}
      />
      <Body>
        <StyledConfirm
          header='Remove Subcategory'
          subHeader={`You are removing the ${categoryToDelete?.name} and all associated data. This subcategory is currently assigned to ${questionsWithDeletedSubcategory} question${questionsWithDeletedSubcategory === 1 ? '' : 's'}. The questions will not be deleted. This cannot be undone.`}
          isOpen={deletingId}
          confirmButtonLabel='Yes, Delete'
          cancelButtonLabel='Cancel'
          onClickCancel={() => setDeletingId(null)}
          onClickConfirm={() => {
            deleteSubcategory({
              variables: {
                subcategoryId: deletingId,
                categoryId: category.id,
                tenantId
              },
              refetchQueries: [
                {
                  query: getTenantPulseCategories,
                  variables: { tenantId }
                },
                {
                  query: getTenantPulseQuestions,
                  variables: { tenantId }
                }
              ]
            }).then(() => {
              Toast.success('Subcategory deleted');
              setDeletingId(null);
            });
          }}
        />
        <LineWrapper>
          <Typography variant='h5' component='p'>
            Subcategories
          </Typography>
          <StyledButton onClick={onAddSubcategory}>
            Add Subcategory
          </StyledButton>
        </LineWrapper>
        {subcategories?.length ? (
          <>
            {subcategories.map(({ id, name }) => (
              <LineWrapper key={id}>
                <Typography variant='body1' component='p'>
                  {name}
                </Typography>
                <StyledButton
                  size={'S'} 
                  color={'black'}
                  onClick={() => {
                    setDeletingId(id);
                  }}
                >
                  Remove
                </StyledButton>
              </LineWrapper>
            ))}
            <Typography variant='body2' sx={{ mt: 2 }}>
              💡 Assign subcategories to questions to gather more detailed insights into specific areas.
            </Typography>
          </>
        ) : (
          <NoSubcategoriesWrapper>
            <NoSub variant='h6' component='p'>
              <b>No Subcategories</b>
            </NoSub>
            <NoSub variant='body1' component='p'>
              Gather more insightful data with subcategories
            </NoSub>
          </NoSubcategoriesWrapper>
        )}
      </Body>
      <Modal.Footer.WithClose
        onClickClose={onClose}
      />
    </StyledModal>
  );
};
