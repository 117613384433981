"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getColorObj = exports.ColorPalettes = exports.colors = exports.purples = exports.blues = exports.greens = exports.yellows = exports.oranges = exports.reds = exports.blacks = void 0;
const blacks = {
  100: '#1C1C21',
  90: '#2E2E33',
  80: '#3E3E42',
  70: '#4E4E52',
  60: '#6B6B6F',
  50: '#828285',
  40: '#99999B',
  30: '#C6C6C7',
  20: '#D8D8D9',
  10: '#E6E6E7',
  5: '#F6F6F6',
  1: '#F8F8F8'
};
exports.blacks = blacks;
const reds = {
  60: '#EB2E49',
  50: '#FF4369',
  40: '#FF6588',
  30: '#FF88A5',
  20: '#FFABC2',
  10: '#FFCDDB',
  5: '#FFE7EE',
  1: '#FFF3F7'
};
exports.reds = reds;
const oranges = {
  60: '#E34A34',
  50: '#F26849',
  40: '#F58565',
  30: '#F7A085',
  20: '#F9BAA5',
  10: '#FCD7CA',
  5: '#FDE8E1',
  1: '#FEF3F0'
};
exports.oranges = oranges;
const yellows = {
  60: '#EE8D11',
  50: '#F2A645',
  40: '#FFCE7E',
  30: '#FFDA96',
  20: '#FFE6AD',
  10: '#FFEDC5',
  5: '#FFF5DA',
  1: '#FFF9EC'
};
exports.yellows = yellows;
const greens = {
  60: '#05A164',
  50: '#07BB87',
  40: '#21CBA3',
  30: '#5BDABE',
  20: '#95E9D8',
  10: '#C6F3EA',
  5: '#E4FAF6',
  1: '#EBFBF8'
};
exports.greens = greens;
const blues = {
  60: '#3466F2',
  50: '#4584FF',
  40: '#589BFF',
  30: '#7DB3FF',
  20: '#A2CCFF',
  10: '#CCE3FF',
  5: '#E6F2FF',
  1: '#F3F8FF'
};
exports.blues = blues;
const purples = {
  60: '#25277A',
  50: '#343799',
  40: '#4F52B1',
  30: '#7E81C8',
  20: '#ADAFDE',
  10: '#CFD1EC',
  5: '#E9E9F6',
  1: '#F4F4FB'
};
exports.purples = purples;
const colors = {
  reds,
  oranges,
  yellows,
  greens,
  blues,
  purples,
  blacks,
  // Do not use the pink color scheme directly. This is used to colorize skill colors that are pink.
  pinks: reds,
  red: reds['50'],
  orange: oranges['50'],
  yellow: yellows['50'],
  green: greens['50'],
  blue: blues['50'],
  purple: purples['50'],
  black: blacks['80'],
  white: '#FFFFFF'
};
exports.colors = colors;
const ColorPalettes = {
  Red: 'Red',
  Orange: 'Orange',
  Yellow: 'Yellow',
  Green: 'Green',
  Blue: 'Blue',
  Purple: 'Purple',
  Pink: 'Pink',
  Black: 'Black'
};
exports.ColorPalettes = ColorPalettes;

const getColorObj = (colorPaletteName = '', defaultColorName) => {
  const colorName = `${colorPaletteName.toLowerCase()}s`;
  const colorObj = colors[colorName];

  if (colorObj) {
    return colorObj;
  }

  return defaultColorName ? colors[defaultColorName] || {} : {};
};

exports.getColorObj = getColorObj;
var _default = colors;
exports.default = _default;