import { withStateHandlers } from 'recompose';

const withToggle = ({
  stateName,
  handleToggleName,
  handleToggleOpenName,
  handleToggleCloseName,
  initialIsOpen = false,
}) => {
  const props = {};
  if (handleToggleOpenName) {
    props[handleToggleOpenName] = () => () => ({
      [stateName]: true,
    });
  }
  if (handleToggleCloseName) {
    props[handleToggleCloseName] = () => () => ({
      [stateName]: false,
    });
  }
  if (handleToggleName) {
    props[handleToggleName] = (state) => () => ({
      [stateName]: !state[stateName],
    });
  }
  return withStateHandlers(
    ({ initialIsToggleOpen = initialIsOpen }) => ({
      [stateName]: initialIsToggleOpen,
    }),
    props
  );
};

export default withToggle;
