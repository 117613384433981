import PropTypes from 'prop-types';

export const SKILL_SELECTION_TITLE = 'Which of your skills?';
export const MESSAGE_SECTION_TITLE = 'Personalize your message';

export const iconLabels = {
  REQUEST: 'Request Feedback',
  RECURRING: 'Start Continuous',
  EDIT: 'Edit Continuous',
  GIVE: 'Give Feedback',
  KUDOS: 'Give Kudos',
  INFO: 'View Info',
  DISABLED: 'Actions are limited for this Advisor, ask them to join Matter to Give Feedback and Kudos.',
};

export const frequencyOptions = {
  weekly: 'Weekly',
  biweekly: 'Biweekly (every two weeks)',
  monthly: 'Monthly (recommended)',
  bimonthly: 'Bimonthly (every two months)',
  quarterly: 'Quarterly (every three months)',
};

export const MESSAGE_LENGTH = {
  MIN: 25,
  ADD_ADVISORS_MAX: 250,
  GIVE_FEEDBACK_MAX: 1000,
  GIVE_KUDOS_MAX: 150,
};

export const defaultStartingOption = {
  label: 'Starting today',
};

export const AdvisorPropType = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
  fullName: PropTypes.string,
  photoUrl: PropTypes.string,
});

export const SkillPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  colorPalette: PropTypes.string,
  svg: PropTypes.string,
});
