import styled from 'styled-components';
import { rgba } from 'polished';
import ButtonBase, { buttonColors } from '../../Base';
import { buttonStyles } from './props';
import theme from '@matterapp/matter-theme';

const ButtonSecondary = styled(ButtonBase)`
  ${buttonStyles}
  color: ${({ isLoading }) => isLoading ? rgba(theme.colors.purples[50], 0.25) : theme.colors.purples[50]};
  background: transparent;
  border-color: ${theme.colors.purples[50]};

  &:hover {
    color: white;
    border-color: ${theme.colors.purples[50]};
    background-color: ${theme.colors.purples[50]};
  }
  &:active {
    color: white;
    border-color: ${theme.colors.purples[40]};
    background-color: ${theme.colors.purples[40]};
    box-shadow: ${theme.shadows[60]};
  }
`;

ButtonSecondary.defaultProps = {
  color: buttonColors.PURPLE,
};

export default ButtonSecondary;
