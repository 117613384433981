import React from 'react';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import HrisSyncHistoryPage from 'routes/pages/hris/SyncHistory';
import { Route } from 'react-router-dom';

export const HrisSyncHistory = (
  <Route
    exact
    path={Resources.hrisSyncHistory.pattern}
    element={(
      <PrivateRoute>
        <HrisSyncHistoryPage />
      </PrivateRoute>    
    )}
  />
);
