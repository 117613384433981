import { isEqual } from 'lodash';

export const ACTIONS = {
  DEFAULT_SETTINGS_HAS_ERROR: 'DEFAULT_SETTINGS_HAS_ERROR',
  DEFAULT_SETTINGS_NEW_PROPS: 'DEFAULT_SETTINGS_NEW_PROPS',
  REWARD_TYPES_NEW_PROPS: 'REWARD_TYPES_NEW_PROPS',
  RESET_INITIAL_SETTINGS: 'RESET_INITIAL_SETTINGS',
  UPDATE_APPROVAL_PROCESS: 'UPDATE_APPROVAL_PROCESS',
  TOGGLE_REWARDS_FUNDING: 'TOGGLE_REWARDS_FUNDING',
  REWARDS_FUNDING_THRESHOLD_RESET: 'REWARDS_FUNDING_THRESHOLD_RESET',
  UPDATE_REWARDS_FUNDING_THRESHOLD: 'UPDATE_REWARDS_FUNDING_THRESHOLD',
  REMOVE_MEMBER_FROM_NOTIFY_LIST: 'REMOVE_MEMBER_FROM_NOTIFY_LIST',
  ADD_MEMBERS_FROM_NOTIFY_LIST: 'ADD_MEMBERS_FROM_NOTIFY_LIST'
};

export function getInitialState({ 
  maxAllotedCoinPerRitualPerMember, 
  disabledRewardTypes, 
  autoApprove, 
  notifyAdmins,
  allowanceResetFrequency,
  rewardsFundingSettings,
  initialized = false
}) {
  const initialSettings = {
    maxAllotedCoinPerRitualPerMember,
    allowanceResetFrequency,
    disabledRewardTypes: disabledRewardTypes || [],
    autoApprove,
    notifyAdmins,
    rewardsFundingSettings: rewardsFundingSettings || { notifyMembers: [], enabled: false, threshold: 5000 }
  };

  return {
    canSave: false,
    defaultSettingsHasError: false,
    settings: { ...initialSettings },
    initialSettings,
    initialized
  };
}

export function getStateTransferObject(state) {
  const { 
    maxAllotedCoinPerRitualPerMember,
    allowanceResetFrequency,
    disabledRewardTypes,
    autoApprove,
    notifyAdmins,
    rewardsFundingSettings
  } = state;

  return { 
    maxAllotedCoinPerRitualPerMember,
    allowanceResetFrequency,
    disabledRewardTypes,
    autoApprove,
    notifyAdmins,
    enableRewardsFunding: !!rewardsFundingSettings.enabled,
    rewardsFundingThreshold: rewardsFundingSettings.threshold,
    rewardsFundingNotifyMembers: rewardsFundingSettings.notifyMembers
  };
}

export function rewardsReducer(state, action) {
  switch (action.type) {
  case ACTIONS.UPDATE_APPROVAL_PROCESS: {
    const newSettings = {
      ...state.settings,
      autoApprove: action.payload.autoApprove,
      notifyAdmins: action.payload.notifyAdmins
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.DEFAULT_SETTINGS_HAS_ERROR: {
    return { 
      ...state,
      canSave: false, 
      defaultSettingsHasError: true
    };
  }
  case ACTIONS.DEFAULT_SETTINGS_NEW_PROPS: {
    const newSettings = {
      ...state.settings,
      maxAllotedCoinPerRitualPerMember: action.payload.maxAllowance,
      allowanceResetFrequency: action.payload.allowanceResetFrequency
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      defaultSettingsHasError: false,
      settings: newSettings
    };
  }
  case ACTIONS.REWARD_TYPES_NEW_PROPS: {
    const { disabledRewardTypes } = state.settings;
    let newDisabledTypes = [];

    if (disabledRewardTypes.includes(action.payload)) {
      newDisabledTypes = disabledRewardTypes.filter(type => type !== action.payload);
    } else {
      newDisabledTypes = [...disabledRewardTypes, action.payload];
    }

    const newSettings = {
      ...state.settings,
      disabledRewardTypes: newDisabledTypes
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.RESET_INITIAL_SETTINGS: {
    return getInitialState({ ...action.payload, initialized: true });
  }
  case ACTIONS.TOGGLE_REWARDS_FUNDING: {
    const newSettings = {
      ...state.settings,
      rewardsFundingSettings: {
        ...state.settings.rewardsFundingSettings,
        enabled: !state.settings.rewardsFundingSettings.enabled
      }
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.REWARDS_FUNDING_THRESHOLD_RESET: {
    const newSettings = {
      ...state.settings,
      rewardsFundingSettings: {
        ...state.settings.rewardsFundingSettings,
        threshold: 5000
      }
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.UPDATE_REWARDS_FUNDING_THRESHOLD: {
    const newSettings = {
      ...state.settings,
      rewardsFundingSettings: {
        ...state.settings.rewardsFundingSettings,
        threshold: action.payload || 0
      }
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.REMOVE_MEMBER_FROM_NOTIFY_LIST: {
    const newSettings = {
      ...state.settings,
      rewardsFundingSettings: {
        ...state.settings.rewardsFundingSettings,
        notifyMembers: state.settings.rewardsFundingSettings.notifyMembers.filter(member => member != action.payload)
      }
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings
    };
  }

  case ACTIONS.ADD_MEMBERS_FROM_NOTIFY_LIST: {
    const newSettings = {
      ...state.settings,
      rewardsFundingSettings: {
        ...state.settings.rewardsFundingSettings,
        notifyMembers: [...(state.settings.rewardsFundingSettings.notifyMembers || []), ...action.payload]
      }
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  default: {
    return {};
  }
  }
}