import React, { useState, useEffect, useRef } from 'react';
import { 
  Box,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Icon,
  Accordion
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import styled from 'styled-components';

export const MenuItem = styled(Box)(({ theme, isActive }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: theme.spacing(2),
  marginRight: 0,
  cursor: 'pointer',
  backgroundColor: isActive ? theme.palette.purples[50] : 'inherit',
  color: isActive ? theme.palette.white.main : theme.palette.blacks[60],
  borderRadius: '4px',
  padding: '4px',
  transitionDuration: '0.2s',
  transitionProperty: 'background-color',
  '&:hover': {
    backgroundColor: isActive ? theme.palette.purples[50] : theme.palette.blacks[5]
  },
  '&:active': {
    backgroundColor: isActive ? theme.palette.purples[50] : theme.palette.purples[5]
  },
  '&:first-of-type': {
    marginTop: theme.spacing(1)
  },
  '&:last-of-type': {
    marginBottom: 0
  }
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
  paddingLeft: theme.spacing(1),
  margin: '16px 0',
  '::before': {
    height: 0
  }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: '44px', 
  height: '44px',
  marginTop: theme.spacing(2),
  color: theme.palette.blacks[60],
  '.MuiAccordionSummary-content': {
    alignItems: 'center'
  },
  paddingRight: theme.spacing(1)
}));

export default function SlideMenuItem({
  expanded = false,
  menuItems = [],
  name,
  icon,
  children
}) {
  const [isOpen, setIsOpen] = useState(expanded);
  const ref = useRef();

  useEffect(() => {
    setIsOpen(expanded);
  }, [expanded]);

  return (
    <StyledAccordion
      defaultExpanded={expanded}
      disableGutters
      square
      ref={ref}
      elevation={0}
      onClick={(e) => {
        setIsOpen(!isOpen);
      }}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon fontSize='large'/>}
      >
        {icon}
        <Typography variant='h6' sx={{ userSelect: 'none' }}>
          {name}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails sx={{ padding: 0, paddingLeft: '10px' }}>
        {menuItems.map(({
          key,
          isActive,
          onClick,
          text,
          rightIcon
        }) => {
          return (
            <MenuItem 
              key={key} 
              isActive={isActive}
              onClick={onClick}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon 
                  component={PlayArrowIcon}
                  sx={{ marginRight: '5px', width: '10px' }}
                />
                <Typography 
                  sx={{ userSelect: 'none' }}
                  variant='h6'
                >
                  {text}
                </Typography>
              </Box>
              {rightIcon}
            </MenuItem>
          );
        })}
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
}