import React from 'react';
import styled from 'styled-components';
import { colors, font, media } from '@matterapp/matter-theme';
import PageContent from '../../../PageContent';

const PageContentWithBackground = styled(PageContent)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
  padding-top: 0 !important;
  ${media.S`
    background-color: ${colors.purples[5]};
    width: 100%;
  `}
`;

const FooterContainer = styled.div`
  display: none;
  ${media.S`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${font.size.XS};
    line-height: 14px;
    font-weight: ${font.weight.medium};
    text-align: center;
    letter-spacing: 0.04em;
    bottom: 0;
    width: 100%;
    position: relative;
    color: ${colors.blacks[60]};
    background-color: ${colors.purples[5]};
  `}
`;

const ContentContainer = styled.div`
  // Avoid Position absolute in the FooterContainer
  flex-grow: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

class AuthPage extends React.Component {
  renderFooter() {
    return (
      <FooterContainer id={'AuthFooter'}>
        <div style={{ marginBottom: '1.5em' }}>
          <span>Matter is made in San Francisco, CA</span>
        </div>
      </FooterContainer>
    );
  }

  render() {
    const { content } = this.props;
    return (
      <PageContentWithBackground className="AuthPage">
        <ContentContainer>
          {content}
        </ContentContainer>
        {this.renderFooter()}
      </PageContentWithBackground>
    );
  }
}

export default AuthPage;
