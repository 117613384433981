import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => ( 
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd" 
      d="M12.0005 3.04431C7.05446 3.04431 3.04492 7.05385 3.04492 11.9999C3.04492 16.9459 7.05446 20.9554 12.0005 20.9554C16.9465 20.9554 20.956 16.9459 20.956 11.9999C20.956 7.05385 16.9465 3.04431 12.0005 3.04431ZM4.91437 11.9999C4.91437 8.08632 8.08693 4.91376 12.0005 4.91376C13.6207 4.91376 15.1131 5.45707 16.3067 6.37185L6.37246 16.3061C5.45768 15.1125 4.91437 13.6201 4.91437 11.9999ZM12.0005 19.086C10.3803 19.086 8.88784 18.5427 7.69421 17.6279L17.6285 7.6936C18.5433 8.88723 19.0866 10.3797 19.0866 11.9999C19.0866 15.9134 15.914 19.086 12.0005 19.086Z"
      fill={fill}
    />
  </svg>

);

Icon.defaultProps = {
  fill: '#6B6B6F',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;