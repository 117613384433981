import React from 'react';
import {
  Button,
  Container,
} from '@matterapp/matter-ui';
const OnboardedUserContent = ({
  handleReturnToMatter
}) => {
  return (
    <Container marginTop={8} marginBottom={12}>
      <Button.Primary.Vivid color="purple" onClick={handleReturnToMatter}>
        Return to Matter
      </Button.Primary.Vivid>
    </Container>
  );
};

export default OnboardedUserContent;