import styled, { keyframes, css } from 'styled-components';
import theme, { colors, media, spacing } from '@matterapp/matter-theme';
import * as Base from '../Base/styles';

const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;
const Wrapper = styled(Base.Wrapper)`
  padding: 0;
`;

const Container = styled(Base.Container)`
  color: ${({ selected, vivid }) => selected || vivid ? colors.black : colors.blacks[40]};
  border: 1px solid ${({ selected }) => selected ? colors.blues[5] : colors.blacks[5]};
  background: ${({ selected }) => selected ? colors.blues[5] : colors.blacks[5]};
  padding: ${spacing.quarter};
  padding-right: ${spacing.single};
  &:hover {
    ${media.S`
      border: 1px solid ${({ selected }) => selected ? colors.blues[20] : colors.blacks[10]};
      background: ${({ selected }) => selected ? colors.blues[5] : colors.blacks[5]};
  `}
  }
  
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
  `}
  ${({ shaking }) => shaking && css`
    animation: ${shake} .1s;
  `}
`;

const SkillBadgeContainer = styled(Base.SkillBadgeContainer)`
  width: ${spacing.singleAndHalf};
  height: ${spacing.singleAndHalf};
`;

const SkillName = styled(Base.SkillName)`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
`;

const SkillPillContainer = styled(Base.SkillPillContainer)`
  display: none;
`;

export default {
  Wrapper,
  Container,
  SkillBadgeContainer,
  SkillName,
  SkillPillContainer
};
