import React from 'react';
import PropTypes from 'prop-types';
import theme, { spacing } from '@matterapp/matter-theme';
import omitStyled from '../../../libs/omitStyled';

const Container = omitStyled('div', ['flatTop', 'flatBottom', 'firstOnMobile', 'emphasize', 'hideBottomMargin'])`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: white;
  margin-bottom: ${spacing.singleAndQuarter};
  ${({ firstOnMobile }) => {
    if (firstOnMobile) {
      return `border-top: 1px solid ${theme.colors.blacks[10]};`;
    }
    return ``;
  }}
  ${({ flatTop, flatBottom, hideBottomMargin }) => theme.media.M`
    ${!flatTop &&
      !flatBottom &&
      `
      border-radius: ${theme.borderRadius.L};
    `}
    ${flatTop &&
      !flatBottom &&
      `
      border-radius: 0px 0px ${theme.borderRadius.L} ${theme.borderRadius.L};
    `}
    ${!flatTop &&
      flatBottom &&
      `
      border-radius: ${theme.borderRadius.L} ${theme.borderRadius.L} 0px 0px;
    `}
    border-top: none;
    margin-bottom: ${spacing.singleAndHalf};
    ${hideBottomMargin && 
      `
      margin-bottom: 0;
      border-bottom: none !important;
    `}
  `}
  ${({ emphasize }) => {
    if (emphasize) {
      return `box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.08)`;
    }
    return `box-shadow: ${theme.shadows[40]};`;
  }}
`;

const Base = ({
  header,
  children,
  emphasize,
  footer,
  className,
  flatTop,
  flatBottom,
  firstOnMobile,
  hideBottomMargin,
}) => (
  <Container
    className={className}
    flatTop={flatTop}
    flatBottom={flatBottom}
    firstOnMobile={firstOnMobile}
    emphasize={emphasize}
    hideBottomMargin={hideBottomMargin}
  >
    {header}
    {children}
    {footer}
  </Container>
);

Base.defaultProps = {
  header: null,
  footer: null,
  children: null,
  flatTop: false,
  flatBottom: false,
  firstOnMobile: false,
  className: '',
  emphasize: false,
  hideBottomMargin: false,
};

Base.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  className: PropTypes.string,
  flatBottom: PropTypes.bool,
  flatTop: PropTypes.bool,
  firstOnMobile: PropTypes.bool,
  emphasize: PropTypes.bool,
  hideBottomMargin: PropTypes.bool,
};

export default Base;
