import React from 'react';
import { Typography, Paper, Divider, Box } from '@mui/material';
import { Advisors } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { ACTIONS } from '../reducer';
import { QUESTION_TYPES, QUESTION_DETAILS } from '../consts';
import TextQuestion from './TextQuestion';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import AgreeDisagreeQuestion from './AgreeDisagreeQuestion';

const DropdownWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '220px'
}));

const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3)
}));
  
const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3)
}));

function getQuestionComponent(type) {
  switch (type) {
  case QUESTION_TYPES.TEXT:
    return TextQuestion;
  case QUESTION_TYPES.MULTIPLE_CHOICE:
    return MultipleChoiceQuestion;
  case QUESTION_TYPES.AGREE_DISAGREE:
  case QUESTION_TYPES.ENPS:
    return AgreeDisagreeQuestion;
  default:
    return TextQuestion;
  }
}

export default function Question(props) {
  const { 
    question,
    index,
    dispatch,
    canDelete,
    showErrors,
    hasEnps
  } = props;

  const Component = getQuestionComponent(question.type);

  const questionTypes = Object.keys(QUESTION_DETAILS).filter((type) => {
    if (hasEnps) {
      return type !== QUESTION_TYPES.ENPS;
    }

    return true;
  }).map((type) => ({
    type,
    label: QUESTION_DETAILS[type].label
  }));

  return (
    <Paper sx={{ padding: 0, mt: 3, ml: 1, mr: 1 }}>
      <TopSection>
        <Typography variant='h5' component='p'>
          {`Question ${index + 1}`}
        </Typography>
        <Box>
          {canDelete && (
            <Box sx={{ display: 'flex' }}>
              <Typography 
                variant='body2' 
                color='blues.50' 
                sx={{ cursor: 'pointer', mr: 1 }}
                onClick={() => {
                  dispatch({
                    type: ACTIONS.TOGGLE_REORDER_QUESTIONS
                  });
                }}
              >
                Reorder
              </Typography>
              <Typography 
                variant='body2' 
                color='reds.60' 
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch({
                    type: ACTIONS.DELETE_QUESTION,
                    payload: { id: question.id }
                  });
                }}
              >
                Delete
              </Typography>
            </Box>
          )}
        </Box>
      </TopSection>
      <Divider sx={{ margin: 0, mb: 1 }} />
      <Section>
        <Typography variant='h5' component='p'>
          Question Type:
        </Typography>
        <DropdownWrapper>
          <Advisors.RecurringModal.Dropdown
            options={questionTypes.map(({ type, label }) => ({
              label,
              value: type
            }))}
            selectedOption={question.label}
            handleChangeSelection={(choice) => {
              dispatch({
                type: ACTIONS.UPDATE_QUESTION_TYPE,
                payload: {
                  id: question.id,
                  type: choice.value
                }
              });
            }}
          />
        </DropdownWrapper>
        <Component 
          {...question}
          showErrors={showErrors}
          dispatch={dispatch}
        />
      </Section>
    </Paper>
  );
}