import React, { useState } from 'react';
import {
  Group,
} from './sharedConsts';
import { Input, Button } from '@matterapp/matter-ui';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import ADMIN_GET_WORKSPACE from 'graphql-queries/queries/admin/adminGetWorkspace';
import adminMoveToEnterprise from 'graphql-queries/queries/admin/adminMoveToEnterprise';
import adminTierSync from 'graphql-queries/queries/admin/adminTierSync';
import adminSlackSync from 'graphql-queries/queries/admin/adminSlackSync';
import adminTeamsSync from 'graphql-queries/queries/admin/adminTeamsSync';
import ADMIN_EXTEND_WORKSPACE_TRIAL from 'graphql-queries/queries/admin/adminExtendWorkspaceTrial';
import { Resources } from '@matterapp/routing';
import { Link, Box, Button as MuiButton } from '@mui/material';
import ModifySubscriptionsModal from './ModifySubscriptionsModal';

const MemberTable = styled.table(({ theme }) => ({
  border: '1px solid black',
  borderCollapse: 'collapse',
  marginTop: theme.spacing(2)
}));

const TableHeader = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
`;

const TableRow = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
`;

const RenderTenant = (props) => {
  const [dateToExtend, setDateToExtend] = useState(new Date().toISOString().split('T')[0]);
  const [canExtend, setCanExtend] = useState(false);
  const [extendWorkspaceTrialMutation, { loading: extendWorkspaceTrialLoading, error: extendWorkspaceTrialError }] = useMutation(ADMIN_EXTEND_WORKSPACE_TRIAL);

  const { tenant } = props || {};

  const { 
    id: tenantId, 
    name, 
    tier, 
    createdAt, 
    expiresInDays, 
    workspaces = [],
    tierExpires, 
    isDeleted, 
    activeMembersCount, 
    members,
    isEnterprise,
    subscriptions = []
  } = tenant || {};
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  if (extendWorkspaceTrialLoading) {
    return <p>Loading ...</p>;
  }
  if (extendWorkspaceTrialError) {
    return `Error! ${extendWorkspaceTrialError}`;
  }

  const { slackWorkspace } = workspaces[0] || {};


  const dateChange = (newDate) => {
    setDateToExtend(newDate);
    if (!isDeleted) {
      setCanExtend(true); 
    }
  };

  const extendFreeTrial = async () => {
    await extendWorkspaceTrialMutation({
      variables: { 
        tenantId,
        tierExpires: dateToExtend
      },
    });
  };

  if (!tenant) {
    return (
      <></>
    );
  }

  const RenderAdminMembersList = ({ members, workspaceId }) => {
    const tableRows = (members || [])
      .map((member) => {

        const { id: memberId, email, permissions, status, person, token } = member;
        const { isAdmin, isOwner } = permissions;
        const { fullName } = person;
        const memberPermission = isAdmin ? 'admin' : (isOwner ? 'owner' : 'member');

        const rows = (
          <tr key={memberId}>
            <TableHeader>{memberId}</TableHeader>
            <TableRow>{fullName}</TableRow>
            <TableRow>{email}</TableRow>
            <TableRow>{status}</TableRow>
            <TableRow>{memberPermission}</TableRow>
            <TableRow>
              <Link
                href={Resources.authRedirectOnLoginViaToken.url(
                  {},
                  {
                    redirectPath: Resources.workspaceDashboard.path({ workspaceId }),
                    token,
                  }
                )}
                target="_blank"
                rel="noreferrer"
              >
                Login to workspace
              </Link>
            </TableRow>
          </tr>
        );
        return rows;
      });

    return (
      <MemberTable className="table table-hover">
        <caption><b>Admin/Owner Member&apos;s List</b></caption>
        <thead>
          <tr>
            <TableHeader>Member Id</TableHeader>
            <TableHeader>Member name</TableHeader>
            <TableHeader>Member email</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Member permission</TableHeader>
            <TableHeader>Magic link</TableHeader>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </MemberTable>
    );
  };

  const RenderWorkspaces = ({ workspaces }) => {
    const tableRows = workspaces
      .map((workspace) => {

        const { id: workspaceId, createdAt, slackWorkspace, teamsWorkspace, isDeleted, timezone, activeMembersCount } = workspace;
        const { teamName, primaryChannel, totalMembers } = slackWorkspace || {};
        const { teamName: teamsName = '', channelName = '' } = teamsWorkspace || {};

        const rows = (
          <tr key={workspaceId}>
            <TableRow>{workspaceId}</TableRow>
            <TableRow>{new Date(Number(createdAt)).toUTCString()}</TableRow>
            <TableRow>{isDeleted ? 'Yes' : 'No'}</TableRow>
            <TableRow>{timezone}</TableRow>
            {slackWorkspace ? (
              <>
                <TableRow>{totalMembers}</TableRow>
                <TableRow>{teamName}</TableRow>
                <TableRow>{primaryChannel}</TableRow>
              </>
            ) : (
              <>
                <TableRow>{activeMembersCount}</TableRow>
                <TableRow>{teamsName}</TableRow>
                <TableRow>{channelName}</TableRow>
              </>
            )}
          </tr>
        );
        return rows;
      });

    return (
      <MemberTable className="table table-hover">
        <caption><b>Admin/Owner Member&apos;s List</b></caption>
        <thead>
          <tr>
            <TableHeader>Workspace Id</TableHeader>
            <TableHeader>Workspace created</TableHeader>
            <TableHeader>Workspace deleted</TableHeader>
            <TableHeader>Workspace timezone</TableHeader>
            {slackWorkspace ? (
              <>
                <TableHeader>Slack Workspace total members</TableHeader>
                <TableHeader>Slack team name</TableHeader>
                <TableHeader>Slack channel name</TableHeader>
              </>
            ) : (
              <>
                <TableHeader>Total members</TableHeader>
                <TableHeader>Microsoft team name</TableHeader>
                <TableHeader>Microsoft channel name</TableHeader>
              </>
            )}
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </MemberTable>
    );
  };

  RenderAdminMembersList.propTypes = {
    members: PropTypes.array,
  };

  const todaysDate = new Date();
  const todaysDateFormatted =  new Date().toISOString().split('T')[0];
  let minDate, isWorkspaceNotPro;
  const tierExpireDate = tierExpires && new Date(tierExpires);
  if (tier == 'trial' || tier == 'expired' || tier == 'free') {
    minDate = tierExpireDate && tierExpireDate < todaysDate ? tierExpireDate.toISOString().split('T')[0] : todaysDateFormatted;
    isWorkspaceNotPro = true;
  }

  return (
    <>
      <ModifySubscriptionsModal 
        tenant={tenant}
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        subscriptions={subscriptions}
      />
      <br></br>
      <p></p>
      <p>Tenant Name: {name}</p>
      <p>Tenant created: {new Date(Number(createdAt)).toUTCString()}</p>
      <p>Tenant deleted? : <b>{isDeleted ? 'Yes' : 'No'}</b></p>
      <p>Is Enterprise ? : <b>{isEnterprise ? 'Yes' : 'No'}</b></p>
      <p>Active members count: {activeMembersCount}</p>
      <p>Tier: {tier}</p>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <p>Subscriptions: {subscriptions.join(', ')}</p> 
        <MuiButton
          variant="contained"
          sx={{ ml: 3, backgroundColor: 'blues.50' }}
          onClick={() => setIsAddModalOpen(true)}
        > 
          Add/Remove Subscriptions
        </MuiButton>
      </Box>
      <p>Tier expiring in days: {expiresInDays || '--'}</p>
      <p>Tier expire date: {tierExpires ? `${tierExpireDate.toUTCString()}` : '--'}</p>
      <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 3 }}>
        <input 
          type="date" 
          id="start" 
          name="trip-start"
          disabled={!isWorkspaceNotPro}
          value={dateToExtend}
          onChange={(e) => dateChange(e.target.value)} 
          min={minDate} 
          max={new Date(todaysDate.setMonth(todaysDate.getMonth()+6)).toISOString().split('T')[0]} 
        />
        <MuiButton
          variant="contained"
          sx={{ ml: 3, backgroundColor: 'blues.50' }}
          disabled={!canExtend}
          onClick={() => extendFreeTrial()}
        > 
          Extend Trial 
        </MuiButton>
      </Box>
      <RenderWorkspaces workspaces={workspaces} />
      <RenderAdminMembersList members={members} workspaceId={workspaces[0].id}/>
    </>
  );
};


const WorkspaceTierForm = () => {
  const [adminWorkspaceId, setAdminWorkspaceId] = useState();
  const [message, setMessage] = useState();
  const [getAdminWorkspaceDetails, { loading, error, data: workspaceDetailsData }] = useLazyQuery(ADMIN_GET_WORKSPACE);
  const [runAdminTierSync] = useMutation(adminTierSync);
  const [runAdminSlackSync] = useMutation(adminSlackSync);
  const [runAdminTeamsSync] = useMutation(adminTeamsSync);
  const [runAdminMoveToEnterprise] = useMutation(adminMoveToEnterprise);
  const { isEnterprise, id: tenantId, workspaces } = workspaceDetailsData?.adminGetWorkspace || {};
  const workspace = workspaces ? workspaces[0] : {};
  const { slackWorkspace } = workspace;


  if (loading) {
    return <p>Loading ...</p>;
  }
  if (error) {
    return `Error! ${error}`;
  }

  const getAdminSettings = async () => {

    if (!adminWorkspaceId) {
      return null;
    }
    await getAdminWorkspaceDetails({
      variables: {
        workspaceId: adminWorkspaceId.trim()
      }
    });
  };

  async function runTierUpdate() {
    await runAdminTierSync({
      variables: {
        tenantId
      }
    });

    setMessage('Workspace\'s tier update started');
  }

  async function moveToEnterprise() {
    await runAdminMoveToEnterprise({
      variables: {
        workspaceId: adminWorkspaceId.trim()
      }
    });

    setMessage('Workspace moved to enterprise');
  }

  async function runSlackSync() {
    if (slackWorkspace) {
      await runAdminSlackSync({
        variables: {
          tenantId
        }
      });
    } else {
      await runAdminTeamsSync({
        variables: {
          tenantId
        }
      });
    }
    setMessage('Sync started');
  }

  const emptyText = workspaceDetailsData && !workspaceDetailsData.adminGetWorkspace ? 'No workspace found' : '';

  return (
    <Group dividerBelow header={'Change workspace tier'}>
      <Input
        onChange={(e) => setAdminWorkspaceId(e.target.value)} 
        value={adminWorkspaceId}
        placeholder={'Enter workspace id'}
      />
      {emptyText}
      <br></br>
      <Button.Primary.Vivid
        color={'blue'}
        style={{ marginRight: 16 }}
        onClick={() => getAdminSettings()}
      >
        Workspace Details
      </Button.Primary.Vivid>
      <RenderTenant tenant={workspaceDetailsData?.adminGetWorkspace} />

      {tenantId ? (
        <>
          <Button.Primary.Vivid
            color={'blue'}
            style={{ margin: 16, marginLeft: 0, marginBottom: 0 }}
            onClick={runSlackSync}
          >
            {slackWorkspace ? 'Run Slack Sync for Workspace' : 'Run Teams Sync for Workspace'}
          </Button.Primary.Vivid>

          <Button.Primary.Vivid
            color={'blue'}
            style={{ margin: 16, marginLeft: 0, marginBottom: 0 }}
            onClick={runTierUpdate}
          >
            Run Tier Check for Workspace
          </Button.Primary.Vivid>

          {isEnterprise ? null : (
            <Button.Primary.Vivid
              color={'blue'}
              style={{ margin: 16, marginLeft: 0, marginBottom: 0 }}
              onClick={moveToEnterprise}
            >
              Move to Enterprise
            </Button.Primary.Vivid>
          )}

          <Group>
            <p>        
              {message}
            </p>
          </Group>
        </>
      ) : null}
    </Group>
  );
};

export default WorkspaceTierForm;