import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.445335 17.7295C0.0424223 17.3518 0.0220082 16.719 0.399738 16.3161L7.25854 9L0.399738 1.68394C0.0220075 1.28102 0.0424216 0.64819 0.445334 0.270458C0.848246 -0.107271 1.48108 -0.0868569 1.85881 0.316055L10 9L1.85881 17.6839C1.48108 18.0869 0.848247 18.1073 0.445335 17.7295Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#3466F2',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
