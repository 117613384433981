import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ConfirmationImage from '../../../../assets/imgs/components/confirmation_send.png';
import { ClickableLink } from '../../Clickable/Clickable';
import Modal from '../Modal';
import TextReplacement from '../../Typography/TextWithReplacement';
import theme from '@matterapp/matter-theme';

const HEADER = 'Check Your Email';
const SUB_HEADER =
  'We need to verify that this is your account before continuing to add another email. We’re playing it safe since you haven’t logged in a while.';
const RESEND_LINK_TEXT = 'Resend magic link.';
const RESEND_TEXT = `Didn’t show up? ${TextReplacement.replaceText}`;

const Image = styled.img.attrs(() => ({ alt: HEADER, src: ConfirmationImage }))`
  max-width: 300px;
  transform: translateX(${theme.spacing.single});
`;

const ModalBody = styled(Modal.Body)`
  margin: ${theme.spacing.single} 0;
  text-align: center;
`;

const FooterText = styled(TextReplacement)`
  color: ${theme.colors.blacks[70]};
  font-size: ${theme.fontSize.S};
  padding: ${theme.spacing.half} 0;
`;

const CheckYourEmail = ({
  header,
  isOpen,
  onClickResend,
  onClose,
  resendLinkText,
  subHeader,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size={Modal.sizes.S}>
    <Modal.Header.CTA
      header={header}
      subHeader={subHeader}
      onClickClose={onClose}
    />
    <ModalBody>
      <Image />
    </ModalBody>
    <Modal.Footer centered>
      <FooterText
        text={RESEND_TEXT}
        replaceText={
          <ClickableLink key="resend-link" onClick={onClickResend}>
            {resendLinkText}
          </ClickableLink>
        }
      />
    </Modal.Footer>
  </Modal>
);

CheckYourEmail.defaultProps = {
  header: HEADER,
  subHeader: SUB_HEADER,
  resendLinkText: RESEND_LINK_TEXT,
};

CheckYourEmail.propTypes = {
  /** Override default header text. */
  header: PropTypes.string,
  /** Override default sub header text. */
  subHeader: PropTypes.string,
  /** If modal is open. */
  isOpen: PropTypes.bool,
  /** Callback when resend button is clicked. */
  onClickResend: PropTypes.func,
  /** Callback when modal closes. */
  onClose: PropTypes.func,
  /** Override resned link text. */
  resendLinkText: PropTypes.string,
};

export default CheckYourEmail;
