import React from 'react';
import { SVG, Path } from './consts';

const FeedbacksNew = (props) => (
  <SVG {...props}>
    <Path
      d="M8.75 12C9.99264 12 11 13.0074 11 14.25V15.752L10.9921 15.8604C10.6814 17.9866 8.77152 19.009 5.56679 19.009C2.37361 19.009 0.433301 17.9983 0.0144627 15.8966L0 15.75V14.25C0 13.0074 1.00736 12 2.25 12H8.75ZM5.5 4C7.433 4 9 5.567 9 7.5C9 9.433 7.433 11 5.5 11C3.567 11 2 9.433 2 7.5C2 5.567 3.567 4 5.5 4ZM17.75 0C18.9926 0 20 1.00736 20 2.25V5.75C20 6.99264 18.9926 8 17.75 8H16.1972L13.6555 10.2071C13.2385 10.5691 12.6069 10.5245 12.2448 10.1075C12.0869 9.92557 12 9.69279 12 9.45226L11.9993 7.98619C10.8747 7.86153 10 6.90791 10 5.75V2.25C10 1.00736 11.0074 0 12.25 0H17.75Z"
    />
  </SVG>
);

export default FeedbacksNew;
