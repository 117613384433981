import { SKILL_GENERATOR_FORM_FIELD_NAMES } from '../consts';

export const getSkillFromFormValues = (values) => ({
  name: values[SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_NAME],
  description: values[SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_DESCRIPTION],
  colorPalette: values[SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_COLOR_PALETTE],
});

export const getValuesFromSkill = (skill) => ({
  [SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_NAME]: skill.name,
  [SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_DESCRIPTION]: skill.description,
  [SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_COLOR_PALETTE]: skill.colorPalette,
});
