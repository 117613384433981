import React from 'react';
import Main from './Main';

const Profile = (props) => (
  <Main
    mobileProps={{ showSideColumn: true, sideColumnFirst: true }}
    {...props}
  />
);

export default Profile;
