import React from 'react';
import Base, { Group, Path, PathEvenOdd } from '../../Image';
import * as C from './classNames';

const Image = (props) => {
  return (
    <Base {...props}>
      <Group>
        <defs>
          <Path id="SVGID_1_" d="M56,13h144v107H56V13z" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" />
        </clipPath>
        <Group className={C.BACKGROUND}>
          <Path
            className={C.HIGHLIGHT}
            fill="#FFFFFF"
            opacity="0"
            d="M175.8,100.7h-57.9c-2.4,0-4.4-1.9-4.4-4.4V38.4c0-2.4,1.9-4.4,4.4-4.4h57.9c2.4,0,4.4,1.9,4.4,4.4v57.9C180.2,98.7,178.2,100.7,175.8,100.7z"
          />
          <Path
            fill="#CFD1EC"
            className={C.TRIANGLE.TOP}
            d="M96.2,66.2l-14.1-9L86,53L96.2,66.2z"
          />
          <Path
            fill="#CFD1EC"
            className={C.TRIANGLE.CENTER}
            d="M96.2,72.5l-21.5,3.7v-7.4L96.2,72.5z"
          />
          <Path
            fill="#CFD1EC"
            className={C.TRIANGLE.BOTTOM}
            d="M96.2,78.7l-14.1,8.4l3.9,4L96.2,78.7z"
          />
          <Path
            fill="#CFD1EC"
            className={C.CIRCLE.TOP}
            opacity="0.4"
            d="M60.1,47.5c2.1,0,3.7-1.7,3.7-3.7c0-2.1-1.7-3.7-3.7-3.7c-2.1,0-3.7,1.7-3.7,3.7C56.4,45.9,58,47.5,60.1,47.5z"
          />
          <Path
            fill="#CFD1EC"
            className={C.CIRCLE.CENTER}
            opacity="0.4"
            d="M59.7,108.4c2.1,0,3.7-1.7,3.7-3.7c0-2.1-1.7-3.7-3.7-3.7c-2.1,0-3.7,1.7-3.7,3.7C56,106.8,57.7,108.4,59.7,108.4z"
          />
          <Path
            fill="#CFD1EC"
            className={C.CIRCLE.BOTTOM}
            opacity="0.3"
            d="M85.5,120.8c2.3,0,4.1-1.9,4.1-4.1c0-2.3-1.8-4.1-4.1-4.1c-2.3,0-4.1,1.9-4.1,4.1C81.3,119,83.2,120.8,85.5,120.8z"
          />
          <PathEvenOdd
            fill="#CFD1EC"
            className={C.PLUS}
            opacity="0.4"
            d="M198.5,53.9c0,0.9,0.7,1.5,1.5,1.5c0.9,0,1.5-0.7,1.5-1.5v-3.1h3.1c0.9,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5h-3.1v-3.1c0-0.9-0.7-1.5-1.5-1.5c-0.9,0-1.5,0.7-1.5,1.5v3.1h-3.1c-0.9,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h3.1V53.9z"
          />
        </Group>
        <Group clipPath="url(#SVGID_2_)">
          <Group className={C.HAND.TOP_LEFT}>
            <Path
              fill="#CFD1EC"
              d="M126.2,31.3l-9.7-4.9c-1.3-0.7-1.9-2.3-1.2-3.7c0.7-1.3,2.3-1.9,3.6-1.2c0.1,0.1,0.2,0.1,0.3,0.2l8.8,6.2c0.9,0.6,1.1,1.8,0.5,2.7C128.1,31.5,127.1,31.7,126.2,31.3z"
            />
            <Path
              className={C.PUZZLE.TOP_LEFT}
              fill="#ED3247"
              d="M140.3,46.6c-0.5,0.2-1,0.3-1.5,0.3c-3.2,0.3-6.3-1.8-7-5c-0.9-3.5,1.2-7.1,4.7-8c0.2,0,0.3-0.1,0.5-0.1c0.7-0.1,1.1-0.8,1-1.5l-2.1-7.8c-0.2-0.9-1.1-1.4-2-1.2L105.6,31c-2,0.5-3.2,2.7-2.7,4.7l7.5,28.4c0.2,0.9,1.1,1.4,2,1.2l7-1.9c1.1-0.3,2.2,0.5,2.4,1.7c0.1,0.5,0.2,1,0.4,1.5c1.1,2.9,4.1,4.7,7.1,4.2c3.9-0.6,6.4-4.5,5.4-8.3c-0.1-0.3-0.2-0.6-0.3-0.9c-0.5-1.1,0.2-2.3,1.3-2.6l7.2-1.9c0.8-0.2,1.2-1,1-1.8l-2.1-7.9C141.6,46.7,140.9,46.3,140.3,46.6z"
            />
            <Path
              fill="#FF4369"
              d="M139,47c-0.4,0.2-0.9,0.3-1.4,0.3c-3.1,0.3-6-1.8-6.8-4.8c-0.8-3.4,1.2-6.8,4.5-7.7c0.2,0,0.3-0.1,0.5-0.1c0.7-0.1,1.1-0.8,0.9-1.4l-2-7.5c-0.2-0.8-1.1-1.4-1.9-1.1L105.7,32c-1.9,0.5-3.1,2.6-2.6,4.5l7.3,27.4c0.2,0.8,1.1,1.4,1.9,1.1l6.8-1.8c1.1-0.3,2.2,0.5,2.3,1.6c0.1,0.5,0.2,1,0.4,1.4c1,2.8,3.9,4.5,6.9,4.1c3.8-0.6,6.1-4.3,5.2-8c-0.1-0.3-0.2-0.6-0.3-0.9c-0.4-1,0.2-2.2,1.2-2.5l6.9-1.9c0.8-0.2,1.2-1,1-1.8l-2-7.6C140.4,47.1,139.7,46.8,139,47z"
            />
            <Path
              fill="#CFD1EC"
              d="M128.2,42.4L122.7,30l-1.1-3.4l3.3-0.9l-5.1-3.6c-0.6-0.9-1.6-1.5-2.6-1.9L115.4,0h-14.6l6.6,30.8c0,0.1,0,0.3,0,0.4l2.9,13.5c0.2,0.9,0.9,1.4,1.7,1.4c0.1,0,0.3,0,0.4,0c1-0.2,1.6-1.2,1.4-2.1l-1.5-7.1l0.5-0.2l3.2,10.9c0.2,0.8,0.9,1.3,1.7,1.3c0.2,0,0.3,0,0.5-0.1c1-0.3,1.5-1.3,1.2-2.2l-3.2-11l0.5-0.2l4.3,12c0.3,0.7,0.9,1.2,1.7,1.2c0.2,0,0.4,0,0.6-0.1c0.9-0.3,1.4-1.4,1.1-2.3l-4.2-11.9l0.5-0.2l4.3,9.8c0.3,0.7,1,1.1,1.6,1.1c0.2,0,0.5,0,0.7-0.2C128.2,44.4,128.6,43.3,128.2,42.4z"
            />
          </Group>
          <Group className={C.HAND.TOP_RIGHT}>
            <Path
              className={C.PUZZLE.TOP_RIGHT}
              fill="#00A162"
              d="M158.7,59.3c0.1-0.5,0.2-0.9,0.4-1.4c1.3-2.8,4.5-4.4,7.5-3.5c3.3,1,5.3,4.4,4.4,7.8c0,0.2-0.1,0.3-0.1,0.5c-0.2,0.6,0.1,1.3,0.8,1.5l7.4,2c0.8,0.2,1.7-0.3,1.9-1.1l7.3-27.4c0.5-2-0.6-4-2.6-4.5l-27.1-7.3c-0.8-0.2-1.7,0.3-1.9,1.1l-1.8,6.8c-0.3,1.1-1.5,1.6-2.5,1.2c-0.4-0.2-0.9-0.3-1.4-0.4c-2.9-0.5-5.9,1.2-6.9,4c-1.4,3.6,0.6,7.5,4.2,8.5c0.3,0.1,0.6,0.1,0.9,0.2c1.1,0.1,1.8,1.2,1.5,2.3l-1.9,7c-0.2,0.8,0.2,1.5,1,1.8l7.6,2C157.9,60.4,158.5,60,158.7,59.3z"
            />
            <Path
              fill="#07BB87"
              d="M159.9,59.3c0.1-0.5,0.2-0.9,0.4-1.4c1.3-2.9,4.5-4.4,7.5-3.5c3.3,1,5.3,4.4,4.4,7.8c0,0.2-0.1,0.3-0.1,0.5c-0.2,0.6,0.1,1.3,0.8,1.5l7.4,2c0.8,0.2,1.7-0.3,1.9-1.1l7.3-27.4c0.5-2-0.6-4-2.6-4.5l-27.1-7.3c-0.8-0.2-1.7,0.3-1.9,1.1l-1.8,6.8c-0.3,1.1-1.5,1.6-2.5,1.2c-0.4-0.2-0.9-0.3-1.4-0.4c-2.9-0.5-5.9,1.2-6.9,4c-1.4,3.6,0.6,7.5,4.2,8.5c0.3,0.1,0.6,0.1,0.9,0.2c1.1,0.1,1.8,1.2,1.5,2.3l-1.9,7c-0.2,0.8,0.2,1.5,1,1.8l7.6,2C159.1,60.4,159.8,60,159.9,59.3z"
            />
            <Path
              fill="#343799"
              d="M177.2,0L175,21.8c-0.5,0.1-0.9,0.4-1.3,0.7c-0.2,0.1-0.4,0.2-0.6,0.3l-8.8,6.2c-0.8,0.6-1.1,1.6-0.7,2.5c0.5,1,1.7,1.4,2.6,0.9l6.2-3.1l12.6,3.4l0.7-3.2c0.1-0.4,0.2-0.7,0.2-1.1l6-28.4H177.2z"
            />
          </Group>
          <Group className={C.HAND.BOTTOM_LEFT}>
            <Path
              className={C.PUZZLE.BOTTOM_LEFT}
              fill="#4F4FB3"
              d="M134.8,76.1c-0.1,0.5-0.2,0.9-0.4,1.4c-1.3,2.9-4.5,4.4-7.5,3.5c-3.3-1-5.3-4.4-4.4-7.8c0-0.2,0.1-0.3,0.1-0.5c0.2-0.6-0.1-1.3-0.8-1.5l-7.4-2c-0.8-0.2-1.7,0.3-1.9,1.1l-7.3,27.4c-0.5,2,0.6,4,2.6,4.5l27.1,7.3c0.8,0.2,1.7-0.3,1.9-1.1l1.8-6.8c0.3-1.1,1.5-1.6,2.5-1.2c0.4,0.2,0.9,0.3,1.4,0.4c2.9,0.5,5.9-1.2,6.9-4c1.4-3.6-0.6-7.5-4.2-8.5c-0.3-0.1-0.6-0.1-0.9-0.2c-1.1-0.1-1.8-1.2-1.5-2.3l1.9-7c0.2-0.8-0.2-1.5-1-1.8l-7.6-2C135.6,75,135,75.4,134.8,76.1z"
            />
            <Path
              fill="#ADAFDE"
              d="M134,77.7c-0.1,0.5-0.2,0.9-0.4,1.4c-1.3,2.9-4.5,4.4-7.5,3.5c-3.3-1-5.3-4.4-4.4-7.8c0-0.2,0.1-0.3,0.1-0.5c0.2-0.6-0.1-1.3-0.8-1.5l-7.4-2c-0.8-0.2-1.7,0.3-1.9,1.1l-7.3,27.4c-0.5,2,0.6,4,2.6,4.5l27.1,7.3c0.8,0.2,1.7-0.3,1.9-1.1l1.8-6.8c0.3-1.1,1.5-1.6,2.5-1.2c0.4,0.2,0.9,0.3,1.4,0.4c2.9,0.5,5.9-1.2,6.9-4c1.4-3.6-0.6-7.5-4.2-8.5c-0.3-0.1-0.6-0.1-0.9-0.2c-1.1-0.1-1.8-1.2-1.5-2.3l1.9-7c0.2-0.8-0.2-1.5-1-1.8l-7.6-2C134.7,76.6,134.1,77,134,77.7z"
            />
            <Path
              fill="#CFD1EC"
              d="M114.6,105.9l-3.7-5.8c-0.5-0.7-1.5-1-2.5-0.7c-0.9,0.5-1.3,1.6-0.9,2.6l4.6,9.9c0.1,0.1,0.1,0.2,0.2,0.3l0.1,0.1c0.2,0.6,0.5,1.1,0.9,1.6L103,137h15.5l7.5-23.4c0.2-0.4,0.3-0.8,0.4-1.2l1-3.1L114.6,105.9z"
            />
          </Group>
          <Group className={C.HAND.BOTTOM_RIGHT}>
            <Path
              className={C.PUZZLE.BOTTOM_RIGHT}
              fill="#4280FF"
              d="M156,89c0.5-0.2,0.9-0.3,1.4-0.3c3.1-0.3,6,1.8,6.8,4.8c0.8,3.4-1.2,6.8-4.5,7.7c-0.2,0-0.3,0.1-0.5,0.1c-0.7,0.1-1.1,0.8-0.9,1.4l2,7.5c0.2,0.8,1.1,1.4,1.9,1.1l27.1-7.3c1.9-0.5,3.1-2.6,2.6-4.5L184.7,72c-0.2-0.8-1.1-1.4-1.9-1.1l-6.8,1.8c-1.1,0.3-2.2-0.5-2.3-1.6c-0.1-0.5-0.2-1-0.4-1.4c-1-2.8-3.9-4.5-6.9-4.1c-3.8,0.6-6.1,4.3-5.2,8c0.1,0.3,0.2,0.6,0.3,0.9c0.4,1-0.2,2.2-1.2,2.5l-6.9,1.9c-0.8,0.2-1.2,1-1,1.8l2,7.6C154.7,88.8,155.4,89.2,156,89z"
            />
            <Path
              fill="#589BFF"
              d="M154.8,90c0.4-0.2,0.9-0.3,1.4-0.3c3.1-0.3,6,1.8,6.8,4.8c0.8,3.4-1.2,6.8-4.5,7.7c-0.2,0-0.3,0.1-0.5,0.1c-0.7,0.1-1.1,0.8-0.9,1.4l2,7.5c0.2,0.8,1.1,1.4,1.9,1.1l27.1-7.3c1.9-0.5,3.1-2.6,2.6-4.5l-7.3-27.4c-0.2-0.8-1.1-1.4-1.9-1.1l-6.8,1.8c-1.1,0.3-2.2-0.5-2.3-1.6c-0.1-0.5-0.2-1-0.4-1.4c-1-2.8-3.9-4.5-6.9-4.1c-3.8,0.6-6.1,4.3-5.2,8c0.1,0.3,0.2,0.6,0.3,0.9c0.4,1-0.2,2.2-1.2,2.5l-6.9,1.9c-0.8,0.2-1.2,1-1,1.8l2,7.6C153.5,89.9,154.2,90.2,154.8,90z"
            />
            <Path
              fill="#343799"
              d="M184.1,113.2c0-0.1,0.1-0.1,0.1-0.2l3.7-7.9l-4.6,1.2l-0.5-2.5l-1.4-13.4c-0.1-1-1-1.7-2-1.6s-1.7,1-1.6,2l1.1,10.7l-0.5,0.1l-2.2-12.4c-0.2-1-1.1-1.6-2.1-1.5c-0.8,0.2-1.5,1.1-1.3,2.1l2.2,12.5l-0.5,0.1l-2.7-11.1c-0.2-1-1.2-1.6-2.2-1.3c-1,0.2-1.6,1.2-1.3,2.2l2.7,11l-0.5,0.1l-2.2-6.9c-0.3-1-1.3-1.5-2.3-1.2c-0.9,0.3-1.4,1.3-1.1,2.3l4.2,13.2c0,0.1,0.1,0.3,0.2,0.4l8.2,25.7l15.7,0.1L183,115C183.5,114.5,183.9,113.9,184.1,113.2z"
            />
          </Group>
        </Group>
      </Group>
    </Base>
  );
};

export default Image;
