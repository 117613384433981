import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from '../Avatar';
import { Menu } from '../Menu';
import { getIconUrl, iconNames } from './avatar-icons';
import { DATA_ROLE, DATA_ROLE_MENU } from './consts';
import theme from '@matterapp/matter-theme';

const avatarSize = 80;
const avatarReveal = 24;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: ${theme.spacing.singleAndHalf};
  color: ${theme.colors.black};
  background: ${theme.colors.white};
  box-shadow: ${theme.shadows[45]};

  &::before {
    content: '';
    position: absolute;
    top: -${avatarReveal}px;
    width: 100%;
    height: 100px;
    background: ${theme.colors.white};
    z-index: 0;
  }

  ${({ mobileCard }) => {
    if (mobileCard) {
      return `
        margin-bottom: ${theme.spacing.singleAndHalf};
        border-radius: ${theme.sizes.borderRadius[80]};
      `;
    }
  }}

  ${theme.media.S`
    margin: 0 8px ${theme.spacing.singleAndHalf};
    padding-bottom: ${theme.spacing.double};
    background-color: ${theme.colors.white};
    border-radius: ${theme.sizes.borderRadius[80]};

    &::before {
      display: none;
    }
  `}

  ${theme.media.M`
    margin: 0 0 ${theme.spacing.singleAndHalf};
  `}
`;

const Header = styled.h1`
  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'initial')};
  text-align: center;
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.fontSizes[60]};
  line-height: ${theme.lineHeight.L};
  margin-top: ${theme.spacing.single};
  margin-bottom: ${({ hasSubHeader }) =>
    hasSubHeader ? theme.spacing.single : '20px'};

  ${theme.media.S`
    display: initial;
  `}
`;

const SubHeader = styled.span`
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.fontSize.S};
  text-align: center;
  width: 275px;
  margin-bottom: ${theme.spacing.double};
`;

const Body = styled.div`
  text-align: center;
  font-size: ${theme.sizes.fontSizes[50]};
  line-height: ${theme.sizes.lineHeights[50]};
  margin-top: ${({ mobileCard }) => (mobileCard ? '0' : '12px')};
  width: 100%;

  ${theme.media.S`
    margin-top: 0;
  `}
`;

const AvatarContainer = styled.div`
  display: block;
  margin-top: -${avatarReveal - 24}px;
  width: ${avatarSize}px;
  height: ${avatarSize}px;
  border-radius: 50%;

  z-index: 1;

  > * {
    width: ${avatarSize}px;
    height: ${avatarSize}px;
  }

  > img {
    border-radius: 50%;
  }

  ${theme.media.S`
    margin-top: -${avatarReveal + 24}px;
  `}
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 60px;
  right: 10px;
  ${theme.media.M`
    top: ${theme.spacing.single};
    right: ${theme.spacing.threeQuarters};
  `}
`;

const AvatarCard = ({
  avatarProps = {},
  className,
  rcLabel,
  title,
  subHeader,
  topRightMenuContent,
  iconName,
  mobileCard = false,
  onMouseEnter,
  onMouseLeave,
  children,
  useCustomTopRightMenu,
}) => {

  return (
    <Container
      className={className}
      mobileCard={mobileCard}
      data-rc={rcLabel}
      data-role={DATA_ROLE}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <AvatarContainer>
        <Avatar
          size={avatarSize}
          url={avatarProps.url ? null : getIconUrl(iconName)}
          {...avatarProps}
        />
      </AvatarContainer>
      {topRightMenuContent && !useCustomTopRightMenu && (
        <MenuContainer data-role={DATA_ROLE_MENU}>
          <Menu.Dark menuMap={topRightMenuContent} />
        </MenuContainer>
      )}
      {topRightMenuContent && useCustomTopRightMenu && topRightMenuContent}
      <Header hideOnMobile={!mobileCard} hasSubHeader={!!subHeader}>
        {title}
      </Header>
      {subHeader && <SubHeader>{subHeader}</SubHeader>}
      <Body>{children}</Body>
    </Container>
  );
};

AvatarCard.iconNames = iconNames;
AvatarCard.getIconUrl = getIconUrl;

AvatarCard.AvatarContainer = AvatarContainer;
AvatarCard.Header = Header;
AvatarCard.Body = Body;

AvatarCard.DataRole = DATA_ROLE;
AvatarCard.DataRoleMenu = DATA_ROLE_MENU;

AvatarCard.Avatar = Avatar;

AvatarCard.propTypes = {
  avatarProps: PropTypes.shape({
    email: PropTypes.string,
    url: PropTypes.string,
  }),
  className: PropTypes.string,
  iconName: function (props, propName) {
    // iconName must be one of 'iconNames' value
    if (!props.avatarProps && !iconNames.includes(props[propName])) {
      return new Error(
        `Invalid prop '${propName}' supplied to AvatarCard. Validation failed.`
      );
      // Cannot use both iconName and avatarProps
    } else if (!!props[propName] && !!props.avatarProps) {
      return new Error(
        `Cannot have both '${propName}' and 'avatarProps' props. Please use one.`
      );
    }
  },
  title: PropTypes.string.isRequired,
  topRightMenuContent: PropTypes.node,
  mobileCard: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.node.isRequired,
  rcLabel: PropTypes.string,
  subHeader: PropTypes.string,
  useCustomTopRightMenu: PropTypes.bool,
};

AvatarCard.defaultProps = {
  mobileCard: false,
  rcLabel: DATA_ROLE,
};
export default AvatarCard;
