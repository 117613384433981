import React, { useState } from 'react';
import { Resources } from '@matterapp/routing';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Flow } from '@matterapp/matter-ui';
import { THANK_YOU_QUERY } from 'graphql-queries/queries';
import OnboardedUserContent from './OnboardedUserContent';
import { SentFeedbackYellowCard } from './SentFeedbackYellowCard';
import { mixpanel } from 'libs/tracking';
import DocumentHead from 'components/atoms/DocumentHead';
import { useNavigate } from 'react-router-dom';

const handleTrack = {
  viewSentFeedback: mixpanel.createEventSender('readyToGrow:viewSentFeedback')
};

const CheckoutSentFeedbackButton = styled.button`
  color: #ffffff;
  text-decoration: underline;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  &:active {
    text-decoration: none;
  }
`;

function ResponseFlowThankYouPage({ invitationUuid }) {
  const [isYellowCardOpen, setIsYellowCardOpen] = useState(false);
  const navigate = useNavigate();
  const { data, loading } = useQuery(THANK_YOU_QUERY, {
    variables: {
      invitationUuid
    }
  });

  if (loading) {
    return null;
  }

  const {
    invitation,
  } = data;
  const { workspace } = invitation?.lastCompletedFeedbackFeedItem; 
  const { id: workspaceId } = workspace || {};
  
  function handleOpenYellowCard() {
    handleTrack.viewSentFeedback();
    setIsYellowCardOpen(true);
  };

  function handleCloseYellowCard() {
    handleTrack.viewSentFeedback();
    
    setIsYellowCardOpen(false);
  };

  return (
    <>
      <DocumentHead title="Thank you" />
      <Flow.Page
        isTimelineFlow
        renderHeader={() => <Flow.Header isHideOnMobile />}
      >
        <Flow.Card
          isHeaderHiddenOnMobile
          flashBody={
            <>
              <div>
                <strong>
                  Your feedback has been sent to {invitation.owner.firstName}.
                </strong>
              </div>
              <div>
                <CheckoutSentFeedbackButton
                  data-rc="preview-feedback-link"
                  onClick={handleOpenYellowCard}
                >
                  Check out what they’ll see!
                </CheckoutSentFeedbackButton>
              </div>
            </>
          }
        >
          <>
            <SentFeedbackYellowCard
              isOpen={isYellowCardOpen}
              handleClose={handleCloseYellowCard}
              feedback={invitation.lastCompletedFeedbackFeedItem}
              recurringFeedback={invitation.recurringFeedback}
            />
            <OnboardedUserContent
              handleReturnToMatter={() => navigate(Resources.workspacesActivityFeed.path({ workspaceId }))}
            />
          </>
        </Flow.Card>
      </Flow.Page>
    </>
  );
};

export default ResponseFlowThankYouPage;
