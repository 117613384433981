import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown';
import { useDropdown } from './hooks';

const TimezoneDropdown = (props) => {
  const {
    handleChange,
    handleSelect,
    handleMenuVisibilityChange,
    items,
    value,
    ...otherProps
  } = useDropdown(props);

  return (
    <Dropdown
      filterItems
      filterLabelAndValue
      items={items}
      resetFilterOnOpen
      onSelect={handleSelect}
      onMenuVisibilityChange={handleMenuVisibilityChange}
      onChange={handleChange}
      value={value}
      {...otherProps}
    />
  );
};

TimezoneDropdown.propTypes = {
  /** Dropdown takes width of parent container. */
  fluid: PropTypes.bool,
  /** Callback when value of timezone dropdown changes. */
  onChange: PropTypes.func,
  /** Value of selected timezone. */
  value: PropTypes.string,
};

export default TimezoneDropdown;
