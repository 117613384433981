import React from 'react';
import { SVG, Path } from './consts';

const Recurring = (props) => (
  <SVG {...props}>
    <Path
      d="M17.3674 9.17972L17.5507 9.50964L19.4816 8.50398L19.2913 8.15672C17.8876 5.59462 15.2026 4 12.2727 4C9.71218 4 7.36976 5.17694 5.85333 7.22185L4 6.60945L5.22268 12.4381L9.65753 8.47182L8.10082 7.95292C9.18272 6.82122 10.6847 6.176 12.2727 6.176C14.4006 6.176 16.3332 7.31806 17.3674 9.17972ZM4.51778 7.18888L4.5177 7.18885L5.46437 11.7018L5.46444 11.7017L4.51778 7.18888ZM6.00905 7.68161L6.00912 7.68163L6.07396 7.57789C7.51343 5.55485 9.78286 4.38772 12.2728 4.38772C12.2831 4.38772 12.2935 4.38774 12.3038 4.38778C12.2934 4.38774 12.2831 4.38772 12.2727 4.38772C9.78278 4.38772 7.51335 5.55485 6.07388 7.57789L6.00905 7.68161ZM17.7064 8.99139C16.673 7.13126 14.7961 5.94362 12.6956 5.80245C14.7961 5.94365 16.6729 7.1313 17.7063 8.99143L17.7064 8.99139ZM12.3006 19.6122C12.2909 19.6123 12.2812 19.6123 12.2715 19.6123C12.2715 19.6123 12.2714 19.6123 12.2714 19.6123C12.2811 19.6123 12.2909 19.6123 12.3006 19.6122ZM11.7119 19.5922C9.13491 19.4064 6.83819 17.9445 5.57991 15.67L6.8248 15.0216C6.82479 15.0216 6.82479 15.0216 6.82478 15.0215L5.57983 15.67C5.64272 15.7836 5.7082 15.8953 5.77618 16.0048C7.06839 18.0875 9.26368 19.4157 11.7119 19.5922ZM19.0797 12.3113L19.0797 12.3112L15.6432 15.3847L17.1215 15.8774L16.9659 16.059C16.9266 16.1048 16.8867 16.1499 16.8463 16.1944C15.7451 17.4047 14.2189 18.1301 12.5863 18.2161C14.2789 18.127 15.8572 17.3506 16.966 16.059L17.1216 15.8774L15.6432 15.3847L19.0797 12.3113ZM12.2585 17.837C13.8458 17.837 15.3483 17.1922 16.4414 16.0594L14.8866 15.5411L19.3214 11.5749L20.5441 17.4035L18.683 16.7886C17.1664 18.8271 14.8276 20 12.2714 20C9.32911 20 6.65748 18.419 5.24057 15.8576L5.04812 15.5097L6.9821 14.5025L7.16465 14.835C8.18527 16.6939 10.1293 17.837 12.2585 17.837Z"
    />
  </SVG>
);

export default Recurring;