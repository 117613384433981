import React from 'react';
import BasePath from './BasePath';

const DEFAULT_HEIGHT = 124;
const PATH_LENGTH = 280;

const CenterToLeft1 = React.forwardRef((props, ref) => (
  <BasePath
    {...props}
    ref={ref}
    height={DEFAULT_HEIGHT}
    pathLength={PATH_LENGTH}
    pathCode={
      `M206.5,10.5h-74.75c-3.521,0-6.708,1.427-9.016,3.734S119,19.729,119,23.25l0,0l0,0
      c0,3.521-1.427,6.708-3.734,9.016C112.958,34.573,109.771,36,106.25,36H34c-6.628,0-12.628,2.686-16.971,7.029S10,53.373,10,60v55`
    }
  />
));

export default CenterToLeft1;
