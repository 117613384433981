import React from 'react';
import Pill from './Pill';
import Badge from './Badge';
import Unit from './Unit';
import PickedSkillsList from './PickedSkillsList';
import Recommendations from './Recommendations';
import SkillGenerator from './Generator';
import { AddList, AddListUnit } from './AddList';
import Tagger from './Tagger';
import Gauge from './Gauge';
import {
  getSkillBackgroundColor,
  getSkillBorderColor,
  getSkillPrimaryColor,
} from '../../common/libs';

class Skill extends React.Component {
  static Badge = Badge;
  static Pill = Pill;
  static Unit = Unit;
  static Tagger = Tagger;
  static AddList = AddList;
  static AddListUnit = AddListUnit;
  static Recommendations = Recommendations;
  static PickedSkillsList = PickedSkillsList;
  static Generator = SkillGenerator;
  static Gauge = Gauge;
  static helpers = {
    getSkillBackgroundColor,
    getSkillBorderColor,
    getSkillPrimaryColor,
  };
}

export default Skill;
