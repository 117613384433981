import React from 'react';
import Animation from '../../Animation';
import Image from './Image';
import * as C from './classNames';

export default class Thoughtful extends Animation {
  baseClass = C.BASE;

  moveWater = (initialize) => {
    const tl = this.getDefaultTimeline({
      duration: 0.5,
      transformOrigin: '150% 0%',
    });
    const hideProps = { rotate: 88, x: 22, opacity: 0, duration: 0 };
    const showProps = { rotate: 0, y: 0, opacity: 1 };

    const hideWater = (yPositions = []) => {
      yPositions.map((y, index) => {
        tl.to(this.waterDroplets[index], { ...hideProps, y: y - 2 }, '<');
      });
    };

    const showWater = (xPositions = []) => {
      xPositions.map((x, index) => {
        tl.to(
          this.waterDroplets[index],
          { opacity: 1, duration: 0 },
          index === 0 ? '>1.2' : '<'
        );
      });
      xPositions.map((x, index) => {
        tl.to(this.waterDroplets[index], { x, rotate: -30, ease: 'power.in' }, '<0.03');
        tl.to(this.waterDroplets[index], { ...showProps }, '<0.04');
      });
    };

    const waterPositions = [
      [-35, 0],
      [-50, -10],
      [-30, 5],
      [-45, -7],
      [-25, 7],
      [-35, 0],
      [-40, -7],
      [-20, 10],
      [-40, -5],
      [-35, 0],
      [-45, -7],
      [-25, 7],
      [-50, -10],
      [-35, 0],
      [-45, -7],
      [-25, 7],
      [-50, -10],
      [-35, 0],
      [-45, -7],
      [-25, 7],
    ];

    hideWater(waterPositions.map(([y]) => y));

    if (!initialize) {
      showWater(waterPositions.map(([y, x]) => x));
    }
    return tl;
  };

  movePlant = (initialize) => {
    const tl = this.getDefaultTimeline({ duration: 0.4 });
    const TRIANGLE_LEFT = this.getElement(C.TRIANGLE.LEFT);
    const TRIANGLE_CENTER = this.getElement(C.TRIANGLE.CENTER);
    const TRIANGLE_RIGHT = this.getElement(C.TRIANGLE.RIGHT);

    const hideProps = { opacity: 0, duration: initialize ? 0 : 0.4 };
    const initialProps = { y: 10, opacity: 0, duration: 0 };
    const resetProps = { x: 0, y: 0, opacity: 1 };

    tl.add(this.moveWater(initialize), '<');
    tl.to(TRIANGLE_LEFT, hideProps, '<');
    tl.to(TRIANGLE_CENTER, hideProps, '<');
    tl.to(TRIANGLE_RIGHT, hideProps, '<');
    tl.to(TRIANGLE_LEFT, { x: 3, ...initialProps }, '>');
    tl.to(TRIANGLE_CENTER, { ...initialProps }, '<');
    tl.to(TRIANGLE_RIGHT, { x: -3, ...initialProps }, '<');

    if (!initialize) {
      tl.to(TRIANGLE_LEFT, resetProps, '>3.4');
      tl.to(TRIANGLE_CENTER, resetProps, '<');
      tl.to(TRIANGLE_RIGHT, resetProps, '<');
    }
    return tl;
  };

  moveLeaves = (initialize) => {
    const tl = this.getDefaultTimeline({ duration: 1 });
    const LEAF_LEFT = this.getElement(C.LEAF.LEFT);
    const LEAF_RIGHT = this.getElement(C.LEAF.RIGHT);
    const leftProps = { transformOrigin: '100% 86%', rotate: -70 };
    const rightProps = { transformOrigin: '0% 82%', rotate: 70 };

    tl.to(LEAF_LEFT, { ...leftProps, duration: initialize ? 0 : 0.4 }, '<');
    tl.to(LEAF_RIGHT, { ...rightProps, duration: initialize ? 0 : 0.4 }, '<');

    if (!initialize) {
      tl.to(LEAF_LEFT, { ...leftProps, rotate: 0 }, '>2.4');
      tl.to(LEAF_RIGHT, { ...rightProps, rotate: 0 }, '<');
    }
    return tl;
  };

  movePail = () => {
    const tl = this.getDefaultTimeline({ duration: 1.5 });
    const WATER_PAIL = this.getElement(C.WATER_PAIL);

    tl.to(WATER_PAIL, { x: -30, y: -50, rotate: -60 }, '<');
    tl.to(WATER_PAIL, { x: 0, y: 0, rotate: 0 }, '>0.6');

    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = this.getBaseTimeline(initialize);

    tl.add(this.moveLeaves(initialize), '<');
    tl.add(this.movePlant(initialize), '<');
    if (!initialize) {
      tl.add(this.movePail(initialize), '<');
    }

    this.tl = tl;
    return this.tl;
  };

  renderWaterDroplets = () => {
    const droplets = [];
    this.waterDroplets = {};

    for (let i = 0; i < 20; i++) {
      droplets.push(
        <path
          key={`water-droplet-${i}`}
          fill="#4F52B1"
          ref={(ref) => (this.waterDroplets[i] = ref)}
          d="M93,90c0,0-1,2-1,3s1,1,1,1s1,0,1-1S93,90,93,90z"
        />
      );
    }
    return droplets;
  };

  render() {
    return (
      <Image
        className={C.BASE}
        {...this.props}
        waterDroplets={this.renderWaterDroplets()}
      />
    );
  }
}
