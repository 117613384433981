import React from 'react';
import Base, { Group, Path, PathEvenOdd } from '../../Image';
import * as C from './classNames';

const Image = (props) => {
  return (
    <Base {...props}>
      <Group className={C.BIRD}>
        <Path d="M125.6,84.2c0.2-0.4,0.9-0.3,1,0.2c0.6,2.2,2.7,6.1,9.8,6.6c0.5,0,0.6,0.6,0.1,0.8c-0.9,0.3-2.1,0.5-3.4,0.4c-3.5-0.2-0.5,0.8-0.3,0.9c0.2,0.1,0.5,0.3,0.8,0.4c0.5,0.2,0.4,1.1-0.2,1c-2.3-0.4-5-1.2-6.6-3.1c-0.3-0.3-0.9-1.3-1.1-1.7C125.2,88.6,124.5,86.5,125.6,84.2z" />
        <Group className={C.WING.RIGHT}>
          <Path
            fill="#25237B"
            d="M135.6,13.2l-9.7,19.8c-0.4,0.9-1.6,1-2.2,0.2l-2.3-2.8c-1.3-1.5-1.5-3.7-0.6-5.5L130,6.2c0.7-1.3,2.5-1.6,3.4-0.4l1.5,1.9C136.2,9.2,136.5,11.4,135.6,13.2z"
          />
          <Path
            fill="#25237B"
            d="M123.5,81.2c3.5-0.7,10.2-4.8,11.9-8c2.9-5.9,1.9-8.6,0.3-12.2c-0.2-0.5-0.5-1-0.8-1.5l-2.2-2.7c-0.5-0.7-1.6-0.5-1.9,0.2l-0.1,0.1c0.8-1.7,0.6-3.7-0.6-5.2l-2.2-2.7c-0.5-0.7-1.6-0.5-1.9,0.2l-0.1,0.1c0.8-1.7,0.6-3.7-0.6-5.2l-2.2-2.7c-0.5-0.7-1.6-0.5-1.9,0.2l-0.1,0.1c0.8-1.7,0.6-3.7-0.6-5.2l-2.2-2.8c-0.5-0.7-1.6-0.5-1.9,0.2L105.3,56c-0.3,0.5-0.5,1-0.7,1.4l-0.1,0.1l0.1-0.1c-0.7,1.6-1.2,3.2-1.5,5c-0.2,1.6-0.2,3.1,0,4.7c0.8,5.1,4.2,10.1,8.8,12.6C111.9,79.7,120,81.8,123.5,81.2z"
          />
          <Path
            fill="#25237B"
            d="M128.4,27.8l-9.7,19.7c-0.4,0.9-1.6,1-2.2,0.2l-2.3-2.8c-1.3-1.6-1.5-3.7-0.6-5.5l9.2-18.7c0.7-1.3,2.5-1.6,3.4-0.4l1.5,1.9C129,23.8,129.2,26,128.4,27.8z"
          />
          <Path
            fill="#25237B"
            d="M139.9,22.8l-9.8,19.8c-0.4,0.9-1.6,1-2.2,0.2l-2.3-2.8c-1.3-1.5-1.5-3.7-0.6-5.5l9.2-18.7c0.7-1.3,2.5-1.6,3.4-0.4l1.5,1.9C140.5,18.8,140.8,21,139.9,22.8z"
          />
          <Path
            fill="#25237B"
            d="M132.7,37.4l-9.8,19.8c-0.4,0.9-1.6,1-2.2,0.2l-2.3-2.8c-1.3-1.5-1.5-3.7-0.6-5.5l9.2-18.7c0.7-1.3,2.5-1.6,3.4-0.4l1.5,1.9C133.3,33.5,133.6,35.6,132.7,37.4z"
          />
          <Path
            fill="#25237B"
            d="M143.4,34l-9.8,19.8c-0.4,0.9-1.6,1-2.2,0.2l-2.3-2.8c-1.3-1.5-1.5-3.7-0.6-5.5l9.2-18.7c0.7-1.3,2.5-1.6,3.4-0.4l1.5,1.9C144.1,30.1,144.3,32.2,143.4,34z"
          />
          <Path
            fill="#25237B"
            d="M146.9,45.3L137.2,65c-0.4,0.9-1.6,1-2.2,0.2l-2.3-2.8c-1.3-1.6-1.5-3.7-0.6-5.5l9.2-18.7c0.7-1.3,2.5-1.5,3.4-0.4l1.5,1.9C147.6,41.3,147.8,43.5,146.9,45.3z"
          />
          <Path
            fill="#25237B"
            d="M137,47l-9.7,19.8c-0.4,0.9-1.6,1-2.2,0.2l-2.3-2.8c-1.3-1.5-1.5-3.7-0.6-5.5l9.2-18.7c0.7-1.3,2.5-1.5,3.4-0.4l1.5,1.9C137.6,43.1,137.9,45.2,137,47z"
          />
          <Path
            fill="#25237B"
            d="M141.3,56.7l-9.7,19.8c-0.4,0.9-1.6,1-2.2,0.2l-2.3-2.8c-1.3-1.6-1.5-3.7-0.6-5.5l9.2-18.7c0.7-1.3,2.5-1.5,3.4-0.4l1.5,1.9C141.9,52.7,142.2,54.9,141.3,56.7z"
          />
        </Group>
        <Path
          fill="#4F4FB3"
          d="M155.5,71.3l29.9,12.5c0.9,0.4,1.1,1.5,0.4,2.2l-2.7,2.4c-1.5,1.4-3.6,1.7-5.4,1l-28.8-12.1c-1.4-0.6-1.7-2.4-0.6-3.4l1.8-1.7C151.5,70.9,153.6,70.5,155.5,71.3z"
        />
        <Path
          fill="#7E81C8"
          d="M156.6,72c-3.9,3.6-8,6.9-12.4,9.8c-6.1,4-12.7,7.5-19.9,8.9c-7.3,1.4-14.8,0.1-20.6-4.8c-3.9-3.4-6.8-8.3-8-13.4c-0.1-0.6-0.6-1-1.2-1.1c-1.9-0.3-5.8-1.7-8.1-7.9c-0.2-0.4-0.5-0.7-0.9-0.9l-2.3-0.7c-2-0.6-2.2-3.4-0.3-4.3c0.3-0.2,0.6-0.5,0.7-0.8c0.7-2,3.7-9,11-12.1c8.5-3.7,19.5,0.8,22.1,3.3c0.1,0,0.1,0.1,0.2,0.1c0.6,0.4,4.2,3.2,5.6,5.9c0.2,0.4,0.6,0.7,1.1,0.8c2.4,0.4,10,1.7,16.3,5.2l16.5,9.8C157.1,70.2,157.3,71.3,156.6,72z"
        />
        <Path
          fill="#F3A83E"
          d="M83.4,56.9l-6,2.8c-0.6,0.3-0.5,1.2,0.1,1.4l6.6,2L83.4,56.9z"
        />
        <Path
          fill="#343799"
          d="M100.9,43.2L100.9,43.2c-8.3,0-15.3,5.1-17.2,12.7c-0.3,1.1-0.5,2.2-0.5,3.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.4l0,0c0.3,5.9,4.9,10.6,10.6,11.2c0.5,0.1,1,0.2,1.5,0.2c0.1,0,0.2,0,0.3-0.1c0.9-0.5,6.3-3.2,10.2-6.5c0.5-0.5,0.2-1.3-0.5-1.3c-4,0.2-8-0.1-11.9-1.4c-0.5-0.2-0.9-0.3-1.4-0.5c-0.7-0.2-0.7-1.1-0.1-1.4c2.4-1,4.7-2.1,6.8-3.6c3-2.1,5.7-4.6,8.8-6.5c1.6-1,7.2-4.2,9.2-2.2C114.9,46.1,107.9,42.9,100.9,43.2z"
        />
        <Path
          fill="#343799"
          d="M184.7,79.9l-30.2-13.5c-7.8-3.7-14.5-6.8-16.3-7.4c0,0-0.1,0-0.2-0.1c-0.4-0.2-0.9-0.4-1.3-0.6c-5.4-2.4-11-3.4-13.1-3.7c-0.5-0.1-1,0.2-1.3,0.7c-0.6,1.4-1.6,4.1-0.9,6.5c0.9,3.2,1.8,8.8-7.1,12c-0.6,0.2-0.9,0.9-0.7,1.5c0.6,1.5,2.9,3.6,12.3,1.6c0,0,0.1,0,0.1,0c0.8-0.2,8.6,5.2,21,2.9c7-1.3,29.9,5.5,29.9,5.5c1.8,0.8,4,0.5,5.5-0.8l2.7-2.4C185.7,81.5,185.5,80.3,184.7,79.9z"
        />
        <Path
          fill="#FFFFFF"
          d="M97,51.5c0.2,1.3-0.8,2.5-2.1,2.6c-1.3,0.2-2.5-0.8-2.6-2.1c-0.2-1.3,0.8-2.5,2.1-2.6C95.7,49.3,96.9,50.2,97,51.5z"
        />
        <Path
          fill="#25237B"
          d="M95.7,51.8c0.1,0.8-0.5,1.6-1.3,1.7s-1.6-0.5-1.7-1.3s0.5-1.6,1.3-1.7C94.9,50.4,95.6,51,95.7,51.8z"
        />
        <Path
          fill="#FFFFFF"
          d="M94.4,51.3c0.1,0.5-0.3,0.9-0.7,1c-0.5,0.1-0.9-0.3-1-0.7c-0.1-0.5,0.3-0.9,0.7-0.9C93.9,50.5,94.4,50.8,94.4,51.3z"
        />
        <Group className={C.WING.LEFT}>
          <Path
            fill="#343799"
            d="M152.1,18.1l-13.9,17.1c-0.6,0.7-1.8,0.6-2.2-0.2l-1.6-3.2c-0.9-1.8-0.7-3.9,0.6-5.5L148.1,10c1-1.2,2.8-1,3.4,0.4l1.1,2.2C153.6,14.4,153.3,16.5,152.1,18.1z"
          />
          <Path
            fill="#343799"
            d="M125.1,81.7c3.5,0.1,11-2.4,13.4-5.2c4.2-5.1,3.7-7.9,3-11.8c-0.1-0.6-0.2-1.1-0.5-1.7l-1.6-3.2c-0.4-0.8-1.4-0.9-1.9-0.2l-0.1,0.1c1.2-1.5,1.4-3.5,0.6-5.2l-1.6-3.2c-0.4-0.8-1.4-0.9-1.9-0.2l-0.1,0.1c1.2-1.5,1.4-3.5,0.6-5.2l-1.6-3.2c-0.4-0.8-1.4-0.9-1.9-0.2l-0.1,0.1c1.2-1.5,1.4-3.5,0.6-5.2l-1.6-3.2c-0.4-0.8-1.4-0.9-1.9-0.2l-15.4,19c-0.4,0.4-0.7,0.8-1,1.2c-1,1.4-1.9,2.9-2.6,4.5c-0.6,1.5-0.9,3-1,4.6c-0.4,5.2,1.9,10.7,5.8,14.2C114.2,77.7,117.4,81.7,125.1,81.7z"
          />
          <Path
            fill="#4F4FB3"
            d="M141.8,30.7l-13.9,17.1c-0.6,0.7-1.8,0.6-2.2-0.2l-1.6-3.2c-0.9-1.8-0.7-3.9,0.6-5.5l13.2-16.2c0.9-1.2,2.8-1,3.4,0.4l1.1,2.2C143.3,27,143,29.2,141.8,30.7z"
          />
          <Path
            fill="#25237B"
            d="M154.1,28.4l-13.9,17.1c-0.6,0.7-1.8,0.6-2.2-0.2l-1.6-3.2c-0.9-1.8-0.7-3.9,0.6-5.5l13.2-16.2c0.9-1.2,2.8-1,3.4,0.4l1.1,2.2C155.6,24.7,155.4,26.9,154.1,28.4z"
          />
          <Path
            fill="#343799"
            d="M143.8,41.1l-13.9,17.1c-0.6,0.7-1.8,0.6-2.2-0.2l-1.6-3.2c-0.9-1.8-0.7-3.9,0.6-5.5L139.9,33c0.9-1.2,2.8-1,3.4,0.4l1.1,2.2C145.3,37.4,145.1,39.5,143.8,41.1z"
          />
          <Path
            fill="#343799"
            d="M155,40.2l-13.9,17.1c-0.6,0.7-1.8,0.6-2.2-0.2l-1.6-3.2c-0.9-1.8-0.7-3.9,0.6-5.5l13.2-16.2c0.9-1.2,2.8-1,3.4,0.4l1.1,2.2C156.5,36.5,156.3,38.6,155,40.2z"
          />
          <Path
            fill="#25237B"
            d="M156,51.9L142.1,69c-0.6,0.7-1.8,0.6-2.2-0.2l-1.6-3.2c-0.9-1.8-0.6-3.9,0.6-5.5l13.2-16.2c0.9-1.2,2.8-1,3.4,0.4l1.1,2.2C157.5,48.2,157.2,50.3,156,51.9z"
          />
          <Path
            fill="#4F4FB3"
            d="M145.9,51.4L132,68.5c-0.6,0.7-1.8,0.6-2.2-0.2l-1.6-3.2c-0.9-1.8-0.7-3.9,0.6-5.5L142,43.4c0.9-1.2,2.8-1,3.4,0.4l1.1,2.2C147.4,47.7,147.1,49.9,145.9,51.4z"
          />
          <Path
            fill="#343799"
            d="M147.9,61.8L134,78.9c-0.6,0.7-1.8,0.6-2.2-0.2l-1.6-3.2c-0.9-1.8-0.6-3.9,0.6-5.5L144,53.7c1-1.2,2.8-1,3.4,0.4l1.1,2.2C149.4,58.1,149.2,60.2,147.9,61.8z"
          />
        </Group>
        <Path d="M120.4,87.3c0.2-0.4,0.9-0.3,1,0.2c0.6,2.2,2.7,6.1,9.8,6.6c0.5,0,0.6,0.6,0.1,0.8c-0.9,0.3-2.1,0.5-3.4,0.4c-0.5,0-0.8,0.6-0.3,0.9c0.2,0.1,0.5,0.3,0.8,0.4c0.5,0.2,0.4,1.1-0.2,1c-2.3-0.4-5.1-1.2-6.6-3.1c-0.3-0.3-0.9-1.3-1.1-1.7C120,91.6,119.4,89.6,120.4,87.3z" />
        <Path
          fill="#FFFFFF"
          d="M171.6,171.2c-1.5-8.2-6-16.5-12.2-21.9c-0.5-0.5-1.1-0.9-1.6-1.3c-2.5-2-5.3-3.9-8.2-5.2c0,0-19.2-9.4-48.2,3.5c-25.4,11.3-27.4,26.6-27.5,30.3c0.1,3.6,2.1,19,27.5,30.3c29,12.9,48.2,3.5,48.2,3.5c3-1.3,5.7-3.1,8.2-5.2c0.6-0.4,1.1-0.9,1.6-1.3c6.2-5.4,10.7-13.7,12.2-21.9c0.3-1.8,0.4-3.6,0.4-5.4C172,174.8,171.9,173,171.6,171.2z"
        />
        <Path
          fill="#FFEDC5"
          d="M171.6,171.3c-0.5-2.8-1.4-5.6-2.5-8.3c0.1,0.7,1.8,18.3-11.5,30.8c-13.6,12.7-36,12.6-48.6,9.5c-12.5-3.1-26.2-8.8-26.2-8.8c4.1,4.2,10,8.5,18.7,12.4c29,12.9,48.2,3.5,48.2,3.5c2.9-1.3,5.7-3.1,8.2-5.1c0.6-0.4,1.1-0.9,1.6-1.3c6.2-5.4,10.7-13.6,12.1-21.9c0.3-1.8,0.4-3.6,0.4-5.4C172,174.9,171.9,173,171.6,171.3z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,97.3l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,97.2,125.1,97.4,124.8,97.3z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M139.9,97.3l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C140.4,97.2,140.1,97.4,139.9,97.3z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,99.2l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,99.1,125.1,99.3,124.8,99.2z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,101.1l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,101,125.1,101.1,124.8,101.1z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,103l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,102.8,125.1,103,124.8,103z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,104.8l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6s0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,104.7,125.1,104.9,124.8,104.8z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,106.7l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6s0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,106.6,125.1,106.8,124.8,106.7z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,108.6l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6s0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,108.5,125.1,108.6,124.8,108.6z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,110.5l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6s0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,110.3,125.1,110.5,124.8,110.5z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,112.3l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,112.2,125.1,112.4,124.8,112.3z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M132.2,92.6l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C132.8,92.5,132.5,92.7,132.2,92.6z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M129.6,93l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C130.2,92.8,129.9,93,129.6,93z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M127.2,93.9l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C127.8,93.8,127.5,94,127.2,93.9z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M134.7,93l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C135.3,92.8,135,93,134.7,93z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M137,93.9l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C137.6,93.8,137.3,94,137,93.9z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M125.6,95.4l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C126.2,95.3,125.9,95.5,125.6,95.4z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M138.6,95.4l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C139.2,95.3,138.9,95.5,138.6,95.4z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,114.2l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6s0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,114.1,125.1,114.3,124.8,114.2z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,116.1l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,116,125.1,116.2,124.8,116.1z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,118l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,117.9,125.1,118,124.8,118z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,119.9l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,119.7,125.1,119.9,124.8,119.9z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,121.7l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,121.6,125.1,121.8,124.8,121.7z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,123.6l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,123.5,125.1,123.7,124.8,123.6z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,125.5l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,125.4,125.1,125.6,124.8,125.5z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,127.4l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,127.2,125.1,127.4,124.8,127.4z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,129.2l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,129.1,125.1,129.3,124.8,129.2z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,131.1l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,131,125.1,131.2,124.8,131.1z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,133l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,132.9,125.1,133.1,124.8,133z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,134.9l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,134.8,125.1,134.9,124.8,134.9z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M124.8,136.8l-0.5-0.1c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4l0.5,0.1c0.3,0.1,0.5,0.3,0.4,0.6C125.4,136.6,125.1,136.8,124.8,136.8z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M126.4,140c0,0.8-0.7,1.4-1.5,1.4c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.7-1.4,1.5-1.4C125.7,138.6,126.4,139.3,126.4,140z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M126.3,142.7c-0.3,0.2-0.7,0.1-0.9-0.1c-0.2-0.3-0.2-0.7,0.1-0.9l0.2-0.2c0.3-0.2,0.7-0.2,0.9,0.1c0.2,0.3,0.2,0.7-0.1,0.9L126.3,142.7z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M127.8,144.8c-0.3,0.2-0.7,0.1-0.9-0.2c-0.2-0.3-0.1-0.7,0.2-0.9l0.2-0.2c0.3-0.2,0.7-0.1,0.9,0.2c0.2,0.3,0.1,0.7-0.2,0.9L127.8,144.8z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M129.2,147c-0.3,0.2-0.7,0.1-0.9-0.2c-0.2-0.3-0.1-0.7,0.2-0.9l0.3-0.2c0.3-0.2,0.7-0.1,0.9,0.2c0.2,0.3,0.1,0.7-0.2,0.9L129.2,147z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M130.5,149.2c-0.3,0.2-0.7,0.1-0.9-0.2c-0.2-0.3-0.1-0.7,0.2-0.9l0.2-0.1c0.3-0.2,0.7-0.1,0.9,0.2c0.2,0.3,0.1,0.7-0.2,0.9L130.5,149.2z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M131.7,151.5c-0.3,0.2-0.7,0-0.9-0.3c-0.2-0.3,0-0.7,0.3-0.9l0.3-0.1c0.3-0.2,0.7,0,0.9,0.3c0.2,0.3,0,0.7-0.3,0.9L131.7,151.5z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M132.8,153.8c-0.3,0.1-0.7,0-0.8-0.3c-0.2-0.3,0-0.7,0.3-0.8l0.3-0.1c0.3-0.2,0.7,0,0.9,0.3c0.2,0.3,0,0.7-0.3,0.9L132.8,153.8z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M133.9,156.2c-0.3,0.1-0.7,0-0.8-0.3c-0.1-0.3,0-0.7,0.3-0.8l0.3-0.1c0.3-0.1,0.7,0,0.9,0.3c0.1,0.3,0,0.7-0.4,0.9L133.9,156.2z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M134.8,158.6c-0.3,0.1-0.7,0-0.8-0.4c-0.1-0.3,0-0.7,0.4-0.8l0.3-0.1c0.3-0.1,0.7,0,0.8,0.4c0.1,0.3,0,0.7-0.4,0.8L134.8,158.6z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M135.7,161c-0.3,0.1-0.7-0.1-0.8-0.4c-0.1-0.3,0.1-0.7,0.4-0.8l0.3-0.1c0.3-0.1,0.7,0,0.8,0.4c0.1,0.3-0.1,0.7-0.4,0.8L135.7,161z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M136.5,163.4c-0.3,0.1-0.7-0.1-0.8-0.4c-0.1-0.3,0.1-0.7,0.4-0.8l0.3-0.1c0.3-0.1,0.7,0.1,0.8,0.4c0.1,0.3-0.1,0.7-0.4,0.8L136.5,163.4z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M137.2,165.9c-0.3,0.1-0.7-0.1-0.8-0.4c-0.1-0.3,0.1-0.7,0.4-0.8l0.3-0.1c0.3-0.1,0.7,0.1,0.8,0.4c0.1,0.3-0.1,0.7-0.5,0.8L137.2,165.9z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M137.8,168.4c-0.3,0.1-0.7-0.1-0.8-0.5c-0.1-0.3,0.1-0.7,0.5-0.8l0.3-0.1c0.4-0.1,0.7,0.1,0.8,0.5c0.1,0.4-0.1,0.7-0.5,0.8L137.8,168.4z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M138.3,170.9c-0.3,0.1-0.7-0.2-0.8-0.5c-0.1-0.3,0.2-0.7,0.5-0.8l0.3-0.1c0.4-0.1,0.7,0.1,0.8,0.5s-0.2,0.7-0.5,0.8L138.3,170.9z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M138.8,173.5c-0.3,0.1-0.7-0.2-0.7-0.5c-0.1-0.3,0.2-0.7,0.5-0.7l0.3-0.1c0.4-0.1,0.7,0.2,0.8,0.5c0.1,0.4-0.2,0.7-0.5,0.8L138.8,173.5z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M139.2,176c-0.4,0-0.7-0.2-0.7-0.6c0-0.4,0.2-0.7,0.5-0.7l0.3,0c0.4,0,0.7,0.2,0.7,0.5c0,0.4-0.2,0.7-0.6,0.7L139.2,176z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M139.5,178.6c-0.4,0-0.7-0.2-0.7-0.6c0-0.4,0.2-0.7,0.6-0.7l0.3,0c0.4,0,0.7,0.2,0.7,0.6c0,0.4-0.2,0.7-0.6,0.7L139.5,178.6z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M139.7,181.2c-0.4,0-0.7-0.2-0.7-0.6c0-0.4,0.2-0.7,0.6-0.7l0.3,0c0.4,0,0.7,0.2,0.7,0.6c0,0.4-0.2,0.7-0.6,0.7L139.7,181.2z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M139.8,183.7c-0.4,0-0.6-0.3-0.7-0.6c0-0.4,0.3-0.6,0.6-0.7l0.3,0c0.4,0,0.7,0.3,0.7,0.6c0,0.4-0.3,0.7-0.6,0.7L139.8,183.7z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M139.8,186.3c-0.4,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h0.3c0.4,0,0.7,0.3,0.7,0.6c0,0.4-0.3,0.6-0.6,0.6H139.8z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M139.8,188.9c-0.4,0-0.6-0.3-0.6-0.7c0-0.4,0.3-0.6,0.7-0.6h0.3c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.6-0.7,0.6L139.8,188.9z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M139.7,191.5c-0.4,0-0.6-0.3-0.6-0.7c0-0.4,0.3-0.6,0.7-0.6l0.3,0c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.6-0.7,0.6L139.7,191.5z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M139.5,194c-0.4,0-0.6-0.4-0.6-0.7c0-0.4,0.3-0.6,0.7-0.6l0.3,0c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.4,0.6-0.7,0.6L139.5,194z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M139.2,196.6c-0.4,0-0.6-0.4-0.6-0.7c0-0.4,0.4-0.6,0.7-0.6l0.3,0c0.4,0,0.6,0.4,0.6,0.7c0,0.4-0.4,0.6-0.7,0.6L139.2,196.6z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M138.8,199.1c-0.4-0.1-0.6-0.4-0.5-0.7c0-0.4,0.4-0.6,0.7-0.5l0.3,0c0.4,0,0.6,0.4,0.6,0.7c-0.1,0.4-0.4,0.6-0.7,0.5L138.8,199.1z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M138.4,201.7c-0.3-0.1-0.6-0.4-0.5-0.7c0.1-0.3,0.4-0.6,0.7-0.5l0.3,0c0.4,0.1,0.6,0.4,0.5,0.8c-0.1,0.4-0.4,0.6-0.8,0.5L138.4,201.7z"
        />
        <PathEvenOdd
          fill="#FF4668"
          d="M137.8,204.2c-0.3-0.1-0.6-0.4-0.5-0.8c0.1-0.3,0.4-0.6,0.8-0.5l0.3,0.1c0.4,0.1,0.6,0.4,0.5,0.8c-0.1,0.4-0.4,0.6-0.8,0.5L137.8,204.2z"
        />
        <Path d="M120.4,87.3c0.2-0.4,0.9-0.3,1,0.2c0.6,2.2,2.7,6.1,9.8,6.6c0.5,0,0.6,0.6,0.1,0.8c-0.9,0.3-2.1,0.5-3.4,0.4c-1.4-0.1-2.6,0.2-3.6-0.2C120,92.9,120.4,87.3,120.4,87.3z" />
        <Path d="M136.4,91c-2.9-0.2-4.9-1-6.4-2c-0.6,0.2-1.2,0.4-1.9,0.6c0.3,0.2,0.7,0.5,1.1,0.7c0.5,0.2,0.1,1.4,0.5,2.3c0.2,0.4,1.1-0.2,3.1,0.4c-0.2-0.1-3.1-1.1,0.3-0.9c1.4,0.1,2.5-0.2,3.4-0.4C137,91.6,136.9,91.1,136.4,91z" />
      </Group>
    </Base>
  );
};

export default Image;
