import React from 'react';
import BasePath from './BasePath';

const DEFAULT_HEIGHT = 84;
const PATH_LENGTH = 475;

const LeftToRight1 = React.forwardRef((props, ref) => (
  <BasePath
    {...props}
    ref={ref}
    height={DEFAULT_HEIGHT}
    pathLength={PATH_LENGTH}
    pathCode={
      `M34,11h61c6.628,0,12.628,2.686,16.971,7.029S119,28.372,119,35v14c0,6.628,2.686,12.628,7.029,16.971
      S136.372,73,143,73h109c6.628,0,12.628-2.686,16.971-7.029C273.314,61.628,276,55.628,276,49V35c0-6.628,2.686-12.628,7.029-16.971
      C287.372,13.686,293.372,11,300,11h125.5`
    }
  />
));

export default LeftToRight1;
