import React from 'react';
import { 
  Typography,
  Divider
} from '@mui/material';
import moment from 'moment-timezone';
import {
  QuestionWrapper,
  Result,
  NoResponsesWrapper,
  NoResponses
} from './styles';

export default function TextQuestionResponses({ 
  response, 
  timezone,
  noResultsText = 'No responses Yet'
}) {
  const { results = [] } = response || {};

  return (
    <QuestionWrapper>
      {results.map(({ answer, createdAt }) => (
        <Result key={createdAt}>
          <Typography variant='body1'>{answer}</Typography>
          <Typography sx={{ fontSize: '12px' }} color='blacks.50'>
            {timezone ? moment(createdAt).tz(timezone).format('MMM DD, YYYY') : null}
          </Typography>
          <Divider sx={{ margin: 0, mt: 3 }} />
        </Result>
      ))}
      {!results.length && (
        <NoResponsesWrapper>
          <NoResponses variant='h6' color='blacks.30'>
            {noResultsText}
          </NoResponses>
        </NoResponsesWrapper>
      )}
    </QuestionWrapper>
  );
}
