import React from 'react';
import PropTypes from 'prop-types';
import IconBase, { HoverPath, DEFAULT_SVG_HOVER_NAME, ICON_FILL_COLORS } from './IconFeedbackBase';
import theme from '@matterapp/matter-theme';

const DEFAULT_ICON_FILL_COLOR = 'red';

const Icon = ({ dataSvgHoverName, dataSvgHoverValue, fill, ...otherProps }) => {
  const colorBase = theme.colors[`${fill}s`];
  const colorDark = colorBase[60];
  const colorLight = colorBase[20];
  const dataProps = { [`data-${dataSvgHoverName}`]: dataSvgHoverValue };

  return (
    <IconBase {...otherProps}>
      <path
        fill={colorLight}
        d="M25.597,10.441h-0.888v3.277c0,2.831-2.377,5.268-5.485,5.268H9.18c-0.324,0-0.643,0.049-0.956,0.124
	v1.664c0,3.37,2.872,6.112,6.403,6.112h9.169c0.491,0,0.964,0.145,1.364,0.421L32,32V16.553C32,13.182,29.128,10.441,25.597,10.441z
	"
      />
      <HoverPath
        fill={colorDark}
        {...dataProps}
        d="M19.516,19.249c3.257,0,5.751-2.554,5.751-5.523v-3.435V8.813c0-2.969-2.493-5.523-5.751-5.523H6.917
	c-3.257,0-5.751,2.554-5.751,5.523v14.932l5.336-3.717c0.451-0.314,0.955-0.524,1.48-0.649c0.328-0.078,0.663-0.13,1.003-0.13
	H19.516z"
      />
      <path
        fill={colorDark}
        d="M19.224,2H7.209C3.228,2,0,5.147,0,9.03v15.299c0,0.953,1.104,1.507,1.9,0.953l5.884-4.099
	c0.408-0.284,0.896-0.436,1.397-0.436h10.044c3.981,0,7.209-3.147,7.209-7.03V9.03C26.433,5.147,23.205,2,19.224,2z M24.709,13.716
	c0,2.831-2.377,5.268-5.485,5.268H9.18c-0.841,0-1.669,0.256-2.367,0.742l-5.089,3.545V9.03c0-2.831,2.377-5.268,5.485-5.268h12.015
	c3.107,0,5.485,2.435,5.485,5.268V13.716z"
      />
    </IconBase>
  );
};

Icon.defaultProps = {
  fill: DEFAULT_ICON_FILL_COLOR,
  dataSvgHoverName: DEFAULT_SVG_HOVER_NAME,
  dataSvgHoverValue: '',
};

Icon.propTypes = {
  fill: PropTypes.oneOf(ICON_FILL_COLORS),
  /** Name of data tag for hover svg elements. Prepends `data-` to name. Controls transition of these elements using css on hover. */
  dataSvgHoverName: PropTypes.string,
  /** Value of data tag for hover svg elements. */
  dataSvgHoverValue: PropTypes.string,
};

export default Icon;
