import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Avatar,
} from '../../components';
import theme from '@matterapp/matter-theme';
import { smallBreakpoint } from './consts';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    display: block;
  }

  ${() => theme.media.breakpoint(smallBreakpoint)`
    flex-direction: column;
    text-align: center;
    margin-top: 0;

    span {
      text-align: inherit;
    }

    img {
      margin-right: 0;
      margin-bottom: 8px;
    }
  `};
`;

const UserFullName = styled.div`
  color: white;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
`;

const UserHeadline = styled.div`
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
`;


const ProfileBlock = class extends React.Component {
  renderHeadline = (headline) => {
    if (headline === null) {
      return null;
    }
    return (
      <UserHeadline>
        {headline}
      </UserHeadline>
    );
  }

  render() {
    const {
      user,
      avatarSize,
    } = this.props;
    return (
      <Container>
        {user.photoUrl && (
          <Avatar
            url={user.photoUrl}
            size={avatarSize}
          />
        )}
        <div>
          <UserFullName>
            {user.fullName}
          </UserFullName>
          {this.renderHeadline(user.headline)}
        </div>
      </Container>
    );
  }
};

ProfileBlock.defaultProps = {
  avatarSize: 64,
};

ProfileBlock.propTypes = {
  avatarSize: PropTypes.number,
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    headline: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
};

export default ProfileBlock;
