import React from 'react';
import PropTypes from 'prop-types';
import TwoColumnFixedLeft from './v2/TwoColumnFixedLeft';
import TwoColumnFixedLeftV2 from './v2/TwoColumnFixedLeftV2';
import SingleColumnV2 from './v2/SingleColumnV2';
import SingleColumnVerticalCenter from './v2/SingleColumnVerticalCenter';
import AppAuthenticatedV2 from './v2/AppAuthenticated';
import V3 from './v3';
import Empty from './v2/Empty';
import AuthPage from './v2/AuthPage';

const CURRENT_VERSION = 3;

// These layout names are deprecated.
// Use new names in `Layouts` keys and version number.
const deprecatedLayoutNameMap = {
  AppAuthenticatedV2: 'Main',
  'AppAuthenticatedV2.Profile': 'Profile',
  TwoColumnFixedLeftV2: 'TwoColumnFixedLeft',
  SingleColumnV2: 'SingleColumn',
};

const Layouts = {
  AuthPage: {
    default: AuthPage,
  },
  Empty: {
    default: Empty,
  },
  Main: {
    default: AppAuthenticatedV2.Main,
    version2: AppAuthenticatedV2.Main,
    version3: V3.Main,
  },
  Profile: {
    default: AppAuthenticatedV2.Profile,
    version3: V3.Profile,
  },
  SingleColumn: {
    default: SingleColumnV2,
  },
  SingleColumnVerticalCenter: {
    default: SingleColumnVerticalCenter,
  },
  TwoColumnFixedLeft: {
    default: TwoColumnFixedLeftV2,
    version1: TwoColumnFixedLeft,
    version3: V3.TwoColumnFixedLeft,
  },
};

const Layout = (props) => {
  const { type, version } = props;
  const layoutObject = Layouts[type] || Layouts[deprecatedLayoutNameMap[type]];
  const LayoutInstance =
    layoutObject[`version${version}`] || layoutObject.default;

  if (!LayoutInstance) {
    console.log('Cannot find layout', type);
  }

  return <LayoutInstance {...props} />;
};

Layout.defaultProps = {
  content: null,
  header: null,
  footer: null,
  version: CURRENT_VERSION,
};

Layout.propTypes = {
  content: PropTypes.node,
  type: PropTypes.string.isRequired,
  version: PropTypes.number,
  header: PropTypes.node,
  footer: PropTypes.node,
};

Layout.Layouts = Object.keys(Layouts).reduce((acc, cur) => {
  return { ...acc, [cur]: cur };
}, {});

export default Layout;
