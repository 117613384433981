import React from 'react';
import PropTypes from 'prop-types';

const MultiLineText = ({
  text,
}) => (
  <div>
    {
      text.split('\n').map((i) => <div key={i}>{i}</div>)
    }
  </div>
);

MultiLineText.defaultProps = {
  text: '',
};

MultiLineText.propTypes = {
  text: PropTypes.string,
};

export default MultiLineText;
