import theme from '@matterapp/matter-theme';

const fontSizeH1 = `${28 / 14}rem`;
const fontSizeH2 = `${24 / 14}rem`;
const fontSizeH3 = `${17 / 14}rem`;
const fontSizeH4 = `${21 / 14}rem`;
const fontSizeH5 = `${14 / 14}rem`;

const defaultLineHeight = 1.4285;

export default `
  html {
    font-size: ${theme.sizes.fontSizes[40]};
  }

  body {
    font-family: ${theme.fontFamilies.body};
    font-size: ${theme.sizes.fontSizes[40]};
    line-height: ${defaultLineHeight};
    color: ${theme.colors.black};
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 0;
    margin: 0 0 1rem;
    line-height: ${defaultLineHeight};
    font-weight: normal;
  }

  h1 {
    min-height: 1rem;
    font-size: ${fontSizeH1};
  }
  h2 {
    font-size: ${fontSizeH2};
  }
  h3 {
    font-size: ${fontSizeH3};
  }
  h4 {
    font-size: ${fontSizeH4};
  }
  h5 {
    font-size: ${fontSizeH5};
  }

  h5 {
    color: ${theme.colors.blue};
    text-transform: uppercase;
  }

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child {
    margin-top: 0em;
  }

  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child {
    margin-bottom: 0em;
  }

  p {
    margin: 0 0 1em;
    line-height: ${defaultLineHeight};
  }
  p:first-child {
    margin-top: 0em;
  }
  p:last-child {
    margin-bottom: 0em;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.blues[50]};
    &:hover {
      color: ${theme.colors.purples[60]};
    }
    &:active {
      text-decoration: underline;
    }
  }
`;
