import PropTypes from 'prop-types';
import { skillValueObjectPropType } from '@matterapp/skills';

export const abilityPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const ID = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const userPropType = PropTypes.shape({
  id: ID.isRequired,
  fullName: PropTypes.string.isRequired,
  headline: PropTypes.string,
  photoUrl: PropTypes.string,
  email: PropTypes.string,
});

export const skillRatingPropType = PropTypes.shape({
  skill: skillValueObjectPropType.isRequired,
  rating: PropTypes.number,
  note: PropTypes.string,
  actionItems: PropTypes.arrayOf(abilityPropType),
});

export const checkInFeedbackReceivedPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  giver: userPropType.isRequired,
  skillRatings: PropTypes.arrayOf(skillRatingPropType).isRequired,
  latestPerformance: PropTypes.shape({
    rating: PropTypes.number.isRequired,
    note: PropTypes.string,
  }).isRequired,
});

export const longFormFeedbackReceivedPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  giver: userPropType,
  skillRatings: PropTypes.arrayOf(skillRatingPropType).isRequired,
  qualityEndorsements: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
});

const quickNotePropType = PropTypes.shape({
  note: PropTypes.string.isRequired,
  skill: skillValueObjectPropType,
});

const kudosPropTypes = PropTypes.shape({
  note: PropTypes.string.isRequired,
  type: PropTypes.string,
});

export const quickNoteFeedbackReceivedPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  giver: userPropType.isRequired,
  quickNote: quickNotePropType.isRequired,
});

export const kudosFeedbackReceivedPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  giver: userPropType.isRequired,
  kudos: kudosPropTypes.isRequired,
});

export const feedbackReceived = PropTypes.oneOfType([
  longFormFeedbackReceivedPropType,
  checkInFeedbackReceivedPropType,
  quickNoteFeedbackReceivedPropType,
]);

export const feedItem = PropTypes.shape({
  id: PropTypes.string.isRequired,
  feedbackReceived: feedbackReceived.isRequired,
});
