import styled from 'styled-components';
import Button from '../../Button';
import Base from '../Dropdown';
import { Clickable } from '../../Clickable/Clickable';
import { IconNew as Icon } from '../../Icon';
import {
  getBorderColor,
  getTextColor,
  getItemSizeStyles,
  getItemFontStyles,
  MenuContainer,
  VARIANTS,
} from '../sharedStyles';
import theme from '@matterapp/matter-theme';

export const getDropdownStyles = ({ color, hasTags, isOpen, singleValue }) => {
  if (hasTags) {
    if (singleValue) {
      return `
        display: none;
        color: red;
      `;
    }
    return `
      border-bottom-width: 2px;
      border-bottom-style: ${isOpen ? 'solid' : 'dotted'};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      
      & input {
        color: ${getTextColor({ color, variant: VARIANTS.BASE })};
      }

      &+ ${MenuContainer} {
        margin-top: -2px;
      }

      &:not([data-open="true"]):not(:hover) {
        border-bottom-color: ${theme.colors.blacks[30]};
      }

      &[data-error="true"] {
        background: ${theme.colors.white};
      }
    `;
  }
  return '';
};

export const Dropdown = styled(Base)`
  ${getDropdownStyles}
`;

/**
 * TAG CONTAINER STYLES
 */
export const TagsContainer = styled.div`
  border: 1px solid
    ${(props) => getBorderColor({ ...props, variant: VARIANTS.BASE })};
  display: ${({ hasTags }) => (hasTags ? 'block' : 'none')};
  overflow-y: auto;

  ${({ singleValue }) => {
    if (singleValue) {
      return `
        border-radius: ${theme.inputs.borderRadius};
      `;
    }
    return `
      border-top: none;
      border-radius: 0 0 ${theme.inputs.borderRadius} ${theme.inputs.borderRadius};
    `;
  }}

  [data-role='dropdown'][data-error='true'] & {
    border-color: ${(props) => getBorderColor({ ...props, variant: VARIANTS.ERROR })};
  }

  ${getItemSizeStyles}
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
`;

/**
 * SINGLE TAG STYLES
 */
export const TagRemoveButtonIconContainer = styled.div`
  border-radius: ${theme.borderRadius.M};
  display: flex;
  padding: ${theme.spacing.half};
`;

export const getTagColorStyles = ({ isValid, size }) => `
  background-color: ${isValid ? theme.colors.blues[10] : theme.colors.reds[10]};
  color: ${isValid ? theme.colors.purples[50] : theme.colors.reds[50]};

  & button {
    & > ${TagRemoveButtonIconContainer} {
      background-color: ${isValid ? theme.colors.blues[10] : theme.colors.reds[10]};
    }
    & svg {
      height: ${getItemFontStyles({ size })};
      width: ${getItemFontStyles({ size })};

      & path {
        fill: ${isValid ? theme.colors.purples[50] : theme.colors.reds[50]};
        transition: fill ${theme.transitions.times.default};
      }
    }
    &:hover svg path {
      fill: ${isValid ? theme.colors.blues[40] : theme.colors.reds[40]};
    }
    &:active svg path {
      fill: ${isValid ? theme.colors.purples[60] : theme.colors.reds[60]};
    }
  }
`;

export const TagContainer = styled.div`
  border-radius: ${theme.borderRadius.M};
  display: flex;
  font-size: ${getItemFontStyles};
  padding: ${theme.spacing.half} ${theme.spacing.single};
  position: relative;
  margin: 4px;

  ${getTagColorStyles}
`;

export const TagLabelContainer = styled.div`
  font-size: inherit;
`;

export const TagRemoveButtonIcon = styled(Icon).attrs(() => ({
  name: 'close',
}))``;

export const TagRemoveButton = styled(Clickable)`
  align-items: center;
  border-radius: ${theme.borderRadius.M};
  justify-content: flex-end;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  transition: opacity ${theme.transitions.times.default};

  ${TagContainer}:hover & {
    opacity: 1;
  }

  ${theme.media.M`
    width: auto;
  `}
`;

export const AddButton = styled(Button.Primary).attrs(() => ({
  addButtonLabel: 'Add',
  size: Button.buttonSizes.S,
  useGrayscaleDisabled: true,
}))`
  position: absolute;
  height: 42px;
  top: ${theme.spacing.quarter};
  right: ${theme.spacing.quarter};
  opacity: ${({ disabled }) => disabled ? 0 : 1};
  transition: all ${theme.transitions.times.default};
`;

export const DescriptionText = styled.div`
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.base};
  margin: ${theme.spacing.single} 0;
`;
