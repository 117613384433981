import React from 'react';
import PropTypes from 'prop-types';
import { Body, Header, Footer, StyledModal } from './components';

const Modal = ({ children, ...rest }) => (
  <StyledModal {...rest}>{children}</StyledModal>
);

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Modal;
