import { css } from 'styled-components';

export const buttonSizes = {
  S: 'S',
  M: 'M',
  L: 'L',
};

export const buttonStyles = css`
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.05em;
  border-radius: 12px;
  border: 1px solid transparent;
  transition: all .1s ease;
  padding: 6px 23px; // Subtract 1 Horizontally to account for border-width

  ${({ size }) => size === 'S' && `
    font-size: 14px;
    line-height: 32px;
    height: 48px;
    border-radius: 8px;
    & > [data-role='loader'] {
      border-radius: 8px;
    }
  `}

  ${({ size }) => size === 'M' && `
    font-size: 17px;
    line-height: 40px;
    height: 56px;
  `}

  ${({ size }) => size === 'L' && `
    font-size: 22px;
    line-height: 54px;
    height: 72px;
    padding-left: 64px;
    padding-right: 64px;
  `}

  & > [data-role='loader'] {
    border-radius: 12px;
  }
`;