import PropTypes from 'prop-types';
import { personPropType } from '@matterapp/people';

export const COMMENTS_HEADER = 'Comments';
export const NO_COMMENT_HEADER = 'Comment';
export const THANK_TEXT = 'Thank';
export const THANKED_LABEL = 'Thanked!';

export const THANK_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string,
  author: personPropType, // eslint-disable-line
  canDelete: PropTypes.bool,
  createdAt: PropTypes.string,
});

export const THANKS_PROP_TYPE_OBJ = {
  canThank: PropTypes.bool,
  canSeeThanks: PropTypes.bool,
  numberThanks: PropTypes.number,
  thanks: PropTypes.oneOfType([PropTypes.arrayOf(THANK_PROP_TYPE), THANK_PROP_TYPE]),
};

export const THANKS_PROP_TYPE = PropTypes.shape(THANKS_PROP_TYPE_OBJ);

export const THANKS_DEFAULT_PROPS = {
  thanks: [],
};
