import React, { useState, useEffect } from 'react';
import { Modal, List } from '@matterapp/matter-ui';
import styled from 'styled-components';

const StyledModal = styled(Modal)({
  zIndex: 100
});

export default function ReorderModal(props) {
  const {
    isOpen,
    onClose,
    onConfirm,
    questions
  } = props;
  const [newOrder, setNewOrder] = useState(questions.map(({ id }) => id));

  useEffect(() => {
    setNewOrder(questions.map(({ id }) => id));
  }, [questions]);
  
  return (
    <StyledModal 
      isOpen={isOpen} 
      onClose={onClose}
      size="large"
    >
      <Modal.Header
        header="Reorder Questions"
        right={<Modal.Buttons.CloseIcon onClick={onClose} />}
      />
      <Modal.Body>
        <List.DragAndDrop 
          verticalDragOnly
          onReorder={({ itemIds }) => setNewOrder(itemIds)}
          itemIds={newOrder}
          renderItem={({ id }) => {
            const question = questions.find((question) => question.id === id);

            return (
              <div>
                {question.question}
              </div>
            );
          }}
        />
      </Modal.Body>
      <Modal.Footer.WithCancelSave
        canClickSave
        onClickCancel={onClose}
        saveLabel='Apply Changes'
        onClickSave={() => {
          onConfirm(newOrder);
        }}
      />
    </StyledModal>
  );
};
