import React, { Component } from 'react';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../../libs/omitStyled';
import {
  IconAddSmall,
  IconCloseSmall,
  IconCheckmark,
} from '../../Icon';


const StyledButtonCircle = omitStyled('button', ['size', 'type'])`
  :not([disabled]) {
    cursor: pointer;
  }
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid transparent;

  transition:
    transform 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;

  &::after {
    content: "";
    position: absolute;
      top: 0;
      left: 0;
    width: 100%;
    height: calc(100% + 3.5px);
  }

  &:hover:not([disabled]) {
    overflow: visible;
    transform: translateY(-2px);
  }

  ${({ size }) => (size === 'small') && `
    line-height: 32px;
    width: 32px;
    height: 32px;
    svg {
      width: 16px;
      min-width: 16px; // Required in Mobile Safari
      height: 16px;
    }
  `}
  ${({ size }) => (size === 'medium') && `
    line-height: 64px;
    width: 64px;
    height: 64px;
    svg {
      width: 32px;
      height: 32px;
    }
  `}

  ${({ type }) => (type === 'add') && `
    background-color: ${theme.colors.blues[5]};
    border-color: ${theme.colors.blues[10]};
    svg path {
      fill: ${theme.colors.blue};
    }
    &:hover:not([disabled]) {
      border-color: ${theme.colors.blues[50]};
      box-shadow: ${theme.colors.blues[5]} 0px 2px 0px;
    }
    &:active {
      border-color: ${theme.colors.blues[50]};
      background-color: ${theme.colors.blues[50]};
      svg path {
        fill: white;
      }
    }
  `}
  ${({ type }) => (type === 'remove') && `
    background-color: ${theme.colors.blacks[5]};
    border-color: ${theme.colors.blacks[5]};
    svg {
      width: 12px;
      min-width: 12px; // Required in Mobile Safari
      height: 12px;
      path {
        fill: ${theme.colors.blacks[30]};
      }
    }
    &:hover:not([disabled]) {
      border-color: ${theme.colors.blacks[30]};
      box-shadow: ${theme.colors.blacks[5]} 0px 2px 0px;
      transform: unset;
    }
    &:active {
      border-color: ${theme.colors.blacks[30]};
      background-color: ${theme.colors.blacks[30]};
      svg path {
        fill: white;
      }
    }
  `}
  ${({ type, size }) => (type === 'yes') && `
    background-color: ${theme.colors.greens[5]};
    border-color: ${theme.colors.greens[20]};
    svg path {
      fill: ${theme.colors.green};
    }
    &:hover:not([disabled]) {
      border-color: ${theme.colors.greens[50]};
      box-shadow: ${theme.colors.greens[5]} 0px 2px 0px;
    }
    &:active {
      border-color: ${theme.colors.greens[50]};
      background-color: ${theme.colors.greens[50]};
      svg path {
        fill: white;
      }
    }
    ${(size === 'medium') && `
      svg {
        width: 44px;
        height: auto;
      }
    `}
  `}
  ${({ type, size }) => (type === 'no') && `
    background-color: ${theme.colors.reds[5]};
    border-color: ${theme.colors.reds[10]};
    svg {
      width: 12px;
      height: 12px;
      path {
        fill: ${theme.colors.reds[60]};
      }
    }
    &:hover:not([disabled]) {
      border-color: ${theme.colors.reds[50]};
      box-shadow: ${theme.colors.reds[5]} 0px 2px 0px;
    }
    &:active {
      border-color: ${theme.colors.reds[50]};
      background-color: ${theme.colors.reds[50]};
      svg path {
        fill: white;
      }
    }
    ${(size === 'medium') && `
      svg {
        width: 20px;
        height: 20px;
      }
    `}
  `}

  &:disabled {
    cursor: default !important;
    box-shadow: none !important;
  }
`;


class ButtonCircle extends Component {
  static types = {
    add: 'add',
    remove: 'remove',
    yes: 'yes',
    no: 'no',
  }

  static sizes = {
    small: 'small',
    medium: 'medium',
  }

  static icons = {
    [ButtonCircle.types.add]: IconAddSmall,
    [ButtonCircle.types.remove]: IconCloseSmall,
    [ButtonCircle.types.no]: IconCloseSmall,
    [ButtonCircle.types.yes]: IconCheckmark,
  }

  render = () => {
    const {
      className,
      onClick,
      type,
      size,
      disabled,
    } = this.props;
    const Icon = ButtonCircle.icons[type];
    return (
      <StyledButtonCircle
        className={className}
        size={size}
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        <Icon />
      </StyledButtonCircle>
    );
  }
}

ButtonCircle.defaultProps = {
  disabled: false,
};

ButtonCircle.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(
    Object.keys(ButtonCircle.types)
  ).isRequired,
  size: PropTypes.oneOf(
    Object.keys(ButtonCircle.sizes)
  ).isRequired,
  disabled: PropTypes.bool,
};

export default ButtonCircle;
