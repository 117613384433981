import { graphql } from '@apollo/client/react/hoc';
import ReferralLinkPage from './ReferralLinkPage';
import { REFERRAL_CODE_QUERY } from 'graphql-queries/queries';
import { compose } from 'recompose';
const withData = compose(
  graphql(REFERRAL_CODE_QUERY, {
    name: 'referralCodeData',
  })
);
const ReferralLinkPageContainer = withData(ReferralLinkPage);

export default ReferralLinkPageContainer;

