import React from 'react';
import gsap from 'gsap';
import BaseBackground from './BaseBackground';
import { BackgroundContainer, DarkPath, LightPath } from './styles';

export default class Background4 extends BaseBackground {
  playAnimation = (animationProps = {}) => {
    BaseBackground.killAnimation(this.tl);
    const resetProps = { x: 0, scale: 1, opacity: 1, duration: 0.6 };

    this.tl = gsap.timeline({ defaults: { duration: 0, opacity: 0 } });
    this.tl.to(this.leftRockRef, { x: 30 }, '<');
    this.tl.to(this.rightRockRef, { x: -30 }, '<');

    if (!animationProps.initialize) {
      this.tl.to(this.leftRockRef, resetProps, '<');
      this.tl.to(this.rightRockRef, resetProps, '<');
    }
    return this.tl;
  };

  render() {
    return (
      <BackgroundContainer top={-60}>
        <BaseBackground.SVG height={32}>
          <LightPath
            ref={(ref) => (this.leftRockRef = ref)}
            d="M45.333,20.333c0-5.891-4.776-10.666-10.667-10.666C28.776,9.667,24,14.442,24,20.333H45.333z"
          />
          <DarkPath
            ref={(ref) => (this.rightRockRef = ref)}
            d="M70.667,23c0-8.837-7.163-16-16-16c-8.837,0-16,7.163-16,16H70.667z"
          />
        </BaseBackground.SVG>
      </BackgroundContainer>
    );
  }
}
