import React, { useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import PropTypes from 'prop-types';
import { useWorkspaceFeedQuery } from './hooks';
import { AppLayout } from 'modules';
import FeedItemsList from 'modules/feed/FeedItemsList';
import ComposerWorkspaces from 'modules/composer/Workspaces';
import WorkspacesSideColumn from '../WorkspacesSideColumn';
import { MainColumnContainer } from '../../Shared';
import Toast from 'components/Toast/Toast';
import * as qs from 'qs';
import { useSettingsPanelState } from 'routes/pages/workspaces/hooks/shared';
import getMembers from 'graphql-queries/queries/workspace/getMembers';
import { useQuery } from '@apollo/client';

function WorkspacesActivityFeedPage() {
  const { 
    currentUser, 
    currentTenant, 
    currentWorkspace: workspace = {}, 
    error,
    tenantAdmins
  } = useContext(UserContext);

  const { setIsChannelMenuOpen } = useContext(MobileMenuContext);
  const { workspaceFeed, onLoadMore, feedExists, updateResultsOnComment, updateResultsOnDelete, resetFeed } = useWorkspaceFeedQuery();
  const { id, name } = workspace;
  const { data: membersData } = useQuery(getMembers, {
    variables: { 
      workspaceId: id, limit: 50 
    },
    skip: !id
  });

  const { workspaceMembers: { members: acceptedMembers } } = membersData || { workspaceMembers: { members: [] } };

  const {
    headerProps,
    composerProps,
    footer,
    onClickUpgrade,
    workspaceSideColumnProps
  } = useSettingsPanelState(workspace);

  useEffect(() => {
    if (!error) {
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (params.app_update == 'ok') {
        Toast.success('You’ve successfully updated Matter on Slack.');
      }
      else if (params.app_update == 'error') {
        Toast.success('Something went wrong, and we couldn’t update Matter on Slack.');
      }
    }
  }, []);

  useEffect(() => {
    if (currentTenant?.workspaces.length > 1) {
      setIsChannelMenuOpen(true);
    }
  }, [currentTenant]);

  const pageTitle = `${name} Workspace`;

  return (
    <AppLayout
      headerProps={headerProps}
      layout={AppLayout.Layouts.Main}
      pageTitle={pageTitle}
      footer={footer}
      mainColumn={
        <>
          <MainColumnContainer>
            <ComposerWorkspaces
              firstName={currentUser?.firstName}
              {...composerProps}
              resetFeed={resetFeed}
              tenantAdmins={tenantAdmins}
              workspace={{
                ...workspace,
                id,
                members: acceptedMembers,
                name,
                invites: [],
                pendingMembers: []
              }}
            />
            <FeedItemsList
              workspaceFeed={workspaceFeed}
              workspace={workspace}
              isLoading={!workspaceFeed}
              feedExists={feedExists}
              onLoadMore={onLoadMore}
              updateResultsOnComment={updateResultsOnComment}
              updateResultsOnDelete={updateResultsOnDelete}
              onClickUpgrade={onClickUpgrade}
            />
          </MainColumnContainer>
        </>
      }
      mobileProps={{
        showSideColumn: false,
        sideColumnFirst: true,
      }}
      sideColumn={
        <WorkspacesSideColumn
          {...workspaceSideColumnProps}
          resetFeed={resetFeed}
          workspace={workspace}
          tenant={currentTenant}
          currentUser={currentUser}
          workspaceMembers={acceptedMembers}
        />
      }
    />
  );
}

WorkspacesActivityFeedPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  })
};

export default WorkspacesActivityFeedPage;
