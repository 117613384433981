import Base from './Header';
import CTA from './CTA';
import WithAvatar from './WithAvatar';
import WithBackClose from './WithBackClose';
import WithClose from './WithClose';

const Header = Base;
Header.CTA = CTA;
Header.WithAvatar = WithAvatar;
Header.WithBackClose = WithBackClose;
Header.WithClose = WithClose;

export default Header;