import React from 'react';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../../../libs/omitStyled';

const BodyContainer = omitStyled('div', ['fixedHeight'])`
  overflow: hidden;
  display: flex;
  flex: 1 1 auto;

  ${({ fixedHeight }) => theme.media.medium`
    ${fixedHeight && `border-radius: 0 0 12px 12px;`};
  `}
 `;

const ScrollContainer = omitStyled('div', ['fixedHeight'])`
  overflow-y: auto;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;

  ${({ fixedHeight }) => theme.media.medium`
    ${fixedHeight && `max-height: 500px;`};
  `}
`;

const Body = ({ children, className, fixedHeight }) => (
  <BodyContainer className={className} fixedHeight={fixedHeight}>
    <ScrollContainer fixedHeight={fixedHeight}>{children}</ScrollContainer>
  </BodyContainer>
);

Body.defaultProps = {
  fixedHeight: false,
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fixedHeight: PropTypes.bool,
};

Body.ScrollContainer = ScrollContainer;

export default Body;
