import React from 'react';
import styled from 'styled-components';
import Base from './Base';

const DEFAULT_HEIGHT = 97.66199;
const DEFAULT_WIDTH = 1280;
const DEFAULT_VIEWBOX = `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`;

const Background = styled(Base.path).attrs({
  fillColor: '#FFFFFF',
  grayScaleColor: '#E6E6E7',
  d: `M812.59705,97.662H244.73175c.37061-.71729.74475-1.43408,1.11346-2.15137,4.53272-8.81055,8.814-17.13281,15.32032-24.2832,9.31347-10.23535,21.25732-19.32325,35.5-27.01075,13.90429-7.5039,49.32275-18.29687,64.64551-20.5791,7.90722-1.24414,14.93066-2.36328,21.21582-3.36425,17.84924-2.84186,29.61633-4.71491,38.801-5.84693q.75092-.09009,1.5014-.18066c.97583-.11505,1.93244-.22425,2.85529-.322.06165-.0066.11847-.02546.1781-.03736C496.78369,5.51154,570.02936,0,640,0c94.54376,0,195.066,10.06067,287.7525,23.65515a338.06944,338.06944,0,0,1,46.85382,14.66864c-.703.03082-1.38592.06024-2.034.08722-23.47364.9707-77.14942,10.24512-97.83985,18.04492-21.34082,8.044-38.93652,18.208-52.29785,30.21094A71.66861,71.66861,0,0,0,812.59705,97.662Zm206.734-28.0791c-9.31445-10.23926-21.25683-19.32618-35.49707-27.01172-.37347-.2016-.76989-.40662-1.17364-.61273-3.72748.17523-7.09692.33227-9.92206.44866-23.20605.96-76.20312,10.1045-96.5957,17.791-20.874,7.86816-38.04395,17.77441-51.03418,29.44434a65.27385,65.27385,0,0,0-7.50232,8.01953h219.011q-.984-1.88765-1.955-3.77832C1030.12793,85.06628,1025.8457,76.73718,1019.33105,69.58289ZM983.584,37.91c7.87189-.37,16.939-.79583,25.872-1.10778q-24.55664-4.28925-50.3089-8.36689a209.86971,209.86971,0,0,1,24.36011,9.47828ZM294.76611,40.69714c8.01868-4.32757,23.01807-9.72223,37.3692-14.01953C148.18335,54.98206,0,95.662,0,95.662v2H240.2276q1.03839-1.98862,2.061-3.98047C246.74023,85.02625,251.34424,76.077,258.207,68.536,267.82471,57.9657,280.125,48.59851,294.76611,40.69714Zm734.72583-.32977c-12.0365.04974-26.90234.66809-39.21234,1.23492a133.65418,133.65418,0,0,1,32.00946,25.28821c6.8711,7.5459,11.47656,16.50293,15.93067,25.16407q1.13818,2.2163,2.292,4.43164a1.98716,1.98716,0,0,1,.21069,1.17578H1280v-2S1173.74341,66.492,1029.49194,40.36737Z`,
})``;

const Europa = (props) => {
  return (
    <Base
      {...props}
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      viewBox={DEFAULT_VIEWBOX}
    >
      <Background />
    </Base>
  );
};

export default Europa;
