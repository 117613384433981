import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { media, spacing } from '@matterapp/matter-theme';
import { Button } from '@matterapp/matter-ui';

const BASE_COLOR_LEVEL = 40;
const BASE_COLOR_LEVEL_DARK = 50;

/**
 * Gets the color of the ability buttons.
 * @param { Object } styleProps: Style props of the component.
 * @returns { String } The style containing the color of the button.
 */
/* istanbul ignore next */
export const getAbilityColor = ({ color }) => {
  const isLightColor = color === Button.Simple.buttonColors.YELLOW;
  const baseColorLevel = isLightColor
    ? BASE_COLOR_LEVEL_DARK
    : BASE_COLOR_LEVEL;
  let baseColor = theme.colors[`${color}s`] || theme.colors.blues;

  if (color === Button.Simple.buttonColors.PINK) {
    baseColor = theme.colors.reds;
  }
  return `
    background: ${baseColor[baseColorLevel]};
  `;
};

const Container = styled.div`
  padding: ${spacing.half};
  padding-bottom: ${spacing.quarter};
  ${media.S`
    padding: ${spacing.threeQuarters} ${spacing.single};
    padding-bottom: ${spacing.quarter};
  `}
`;

const Ability = styled.div`
  border-radius: ${theme.borderRadius.S};
  color: ${theme.colors.white};
  display: inline-block;
  margin-right: ${spacing.quarter};
  margin-bottom: ${spacing.quarter};
  padding: ${spacing.quarter} ${spacing.threeQuarters};
  font-size: ${theme.fontSize.S2};
  line-height: ${theme.lineHeight.M};
  ${media.S`
    margin-right: ${spacing.half};
    margin-bottom: ${spacing.half};
  `}
  ${getAbilityColor}
`;

const SkillAbilities = (props) => {
  const { color, currentAbilities } = props;

  return currentAbilities.length ? (
    <Container>
      {currentAbilities.map((singleAbility, index) => {
        const { id, name } = singleAbility.ability;
        return (
          <Ability color={color} key={`ability-${index}-${name}-${id}`} data-rc={'skill-note-ability'}>
            {name}
          </Ability>
        );
      })}
    </Container>
  ) : null;
};

SkillAbilities.propTypes = {
  /** The color of the skill. */
  color: PropTypes.oneOf(Object.values(Button.Simple.buttonColors)),
  /** The currently selected abilities ids. */
  currentAbilities: PropTypes.arrayOf(
    PropTypes.shape({
      ability: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      selected: PropTypes.bool,
    })
  ),
};

export default SkillAbilities;
