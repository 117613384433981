import styled from 'styled-components';
import { Clickable } from '../../Clickable/Clickable';
import theme from '@matterapp/matter-theme';

export const Wrapper = styled.div`
  padding: ${theme.spacing.singleAndHalf};
`;

export const Form = styled.form``;

export const Copied = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.greens[5]};
  border-radius: ${theme.borderRadius.S};
  width: 110px;
  height: ${theme.spacing.singleAndHalf};
  color: ${theme.colors.greens[60]};
  font-size: ${theme.fontSize.base};
  margin: -2px 0;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.singleAndQuarter} 0 0;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const InviteLinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSize.base};

  ${theme.media.S`
    font-size: ${theme.fontSize.S};
  `}
`;

export const Option = styled(Clickable)`
  display: flex;
  align-items: center;
  font-size: ${theme.font.size.base};
  cursor: pointer;
  user-select: none;
  svg {
    margin-right: ${theme.spacing.quarter};
  }
  color: ${({ open }) =>
    open ? theme.colors.purples[50] : theme.colors.blacks[50]};
  &:hover {
    color: ${theme.colors.blacks[80]};
  }
  &:active {
    color: ${theme.colors.purples[50]};
  }
  ${({ active }) => active && `color: ${theme.colors.purples[50]};`}
`;

export const InlineForm = styled.div`
  position: relative;
  input {
    height: ${theme.spacing.quadruple};
    padding-right: 90px;
  }
  .submit-button {
    height: calc(${theme.spacing.quadruple} - ${theme.spacing.half});
    padding-left: ${theme.spacing.single};
    padding-right: ${theme.spacing.single};
    position: absolute;
    bottom: calc(${theme.spacing.quarter} + 1px);
    right: calc(${theme.spacing.quarter} + 1px);

    ${theme.media.S`
      padding-left: ${theme.spacing.singleAndHalf};
      padding-right: ${theme.spacing.singleAndHalf};
    `}
  }
`;