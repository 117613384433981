import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ListItem from './ListItem';
import ListItemActionBox from './ListItemActionBox';
import ListItemStackContainer from './ListItemStackContainer';
import theme from '@matterapp/matter-theme';

const DEFAULT_ANIMATION_TIME = 0.2;
const LIST_ITEM_HEIGHT = 80;

const AvatarBox = styled.div`
  ${({ onClick, avatarWidth }) => {
    return `
      width: ${avatarWidth ? avatarWidth : theme.spacing.triple};
      margin-right: ${theme.spacing.single};
      align-self: flex-start;
      cursor: ${!!onClick ? 'pointer' : 'default'}
    `;
  }}

  ${({ disabled }) => 
    disabled && 
  `
    filter: grayscale(.5);
    opacity: 25%;
  `}
`;

const ContentContainer = styled.div`
  flex: 1;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  user-select: none;

  > * {
    display: inline;
    line-height: ${theme.lineHeight.S};
  }
`;

const ListItemContainer = styled(ListItemStackContainer)`
  align-items: center;
  flex: 1;
  min-width: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ disabled }) => disabled && `
    color: ${theme.colors.blacks[30]};
  `}

  ${theme.media.M`
    max-width: calc(84vw - 440px);
  `}

  ${theme.media.L`
    max-width: calc(75vw - 500px);
  `}

  ${theme.media.XL`
    max-width: 100%;
  `}
`;

const HeaderContainer = styled.div`
  color: inherit;
  font-weight: ${({ boldHeader }) => boldHeader ? theme.fontWeight.bold : theme.fontWeight.regular};
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ largeText }) => largeText && `
    font-size: ${theme.font.size.S};
    line-height: ${theme.lineHeight.M};
  `}
`;

const SubHeaderContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ disabled }) => {
    return `
      color: ${disabled ? theme.colors.blacks[30] : theme.colors.blacks[50]};
    `;
  }}

  ${({ largeText }) => largeText && `
    line-height: ${theme.lineHeight.M};
  `}
`;

const getListItemStyles = ({
  animationTime = DEFAULT_ANIMATION_TIME,
  canClickItem,
  flushedEdge,
  flushedEdgeOnMobile,
  highlighted,
  itemHeight = LIST_ITEM_HEIGHT,
  isBeingAdded,
  isBeingRemoved,
  isCanAddRemove,
}) => {
  const getAddRemoveStyles = () => {
    if (isCanAddRemove) {
      return `
        background: ${theme.colors.white};
        max-height: ${itemHeight}px;
      `;
    }
    return '';
  };

  const baseStyle = `
    margin: 0 ${theme.spacing.singleAndHalf};
    padding: ${theme.spacing.threeQuarters} 0;
    width: calc(100% - ${theme.spacing.singleAndHalf} * 2);
    ${getAddRemoveStyles()}
  `;

  const flushedToEdgeStyle = `
    margin: 0;
    padding: ${theme.spacing.threeQuarters} ${theme.spacing.singleAndHalf};
    width: 100%;
    ${getAddRemoveStyles()}
  `;

  if (highlighted) {
    return `
      ${flushedToEdgeStyle}
    `;
  }
  if (canClickItem) {
    return `
      ${flushedEdge ? flushedToEdgeStyle : baseStyle}
      &:hover {
        ${flushedToEdgeStyle}
        background: ${theme.colors.blues[5]};
      }
    `;
  }
  if (!flushedEdge && !flushedEdgeOnMobile) {
    return baseStyle;
  }
  return css`
    ${flushedToEdgeStyle}
    ${theme.media.M`
      ${flushedEdgeOnMobile ? baseStyle : flushedToEdgeStyle}
    `}
  `;
};

const StyledListItem = styled(ListItem)`
  flex-wrap: nowrap;
  white-space: normal;
  ${getListItemStyles}
`;

export default class PeerListItem extends React.PureComponent {
  static propTypes = {
    /** The content of the actions container of the list item. */
    actions: PropTypes.node,
    /** Override default add/remove animation time. */
    animationTime: PropTypes.number,
    /** The avatar of the list item. */
    avatar: PropTypes.node,
    /** If header text is bold. */
    boldHeader: PropTypes.bool,
    /** Additional style class. */
    className: PropTypes.string,
    /** Additional children components. */
    children: PropTypes.node,
    /** Additional children components (shortcut). If children and content are passed, content is displayed before children. */
    content: PropTypes.node,
    /** Extends to left and right edge on mobile only. */
    flushedEdge: PropTypes.bool,
    /** Extends to left and right edge with border. */
    flushedEdgeOnMobile: PropTypes.bool,
    /**
     * Callback when the list item is clicked.
     * @param { Object } e: The click event.
     * @param { Object } itemProps: The props of the item.
     */
    onClick: PropTypes.func,
    /** If item if being added. */
    isBeingAdded: PropTypes.bool,
    /** If item is being removed. */
    isBeingRemoved: PropTypes.bool,
    /** If item can be added or removed. */
    isCanAddRemove: PropTypes.bool,
    /** If the item is highlighted. */
    isHighlighted: PropTypes.bool,
    /** If the contents of the item are stacked on mobile screens. */
    isStackedOnMobile: PropTypes.bool,
    /** Used for `isCanAddRemove` animation. Height of the item. */
    itemHeight: PropTypes.number,
    /** The header content of the item. */
    header: PropTypes.node,
    /** Callback if avatar is clicked. */
    onClickAvatar: PropTypes.func,
    /** The subHeader content of the item. */
    subHeader: PropTypes.node,
  };

  static defaultProps = {
    boldHeader: true,
    onClick: null,
    isStackedOnMobile: false,
    highlighted: false,
  };

  static HeaderContainer = HeaderContainer;
  static SubHeaderContainer = SubHeaderContainer;
  static TextContainer = TextContainer;

  /**
   * Determines if the item can be clicked when the `onClick` prop is passed in as a function.
   * @return { Boolean } The item is clickable.
   */
  canClickItem = () => typeof this.props.onClick === 'function';

  /**
   * Callback when a list item is clicked. Passes back the event click and the props of the item.
   * @param { Object } e: The click event.
   * @returns { void }
   */
  handleClick = (e) => {
    if (this.canClickItem) {
      this.props.onClick(e, this.props);
    }
  };

  render() {
    const {
      actions,
      avatar,
      boldHeader,
      largeText,
      children,
      className,
      content,
      flushedEdge,
      flushedEdgeOnMobile,
      disabled,
      isBeingAdded,
      isBeingRemoved,
      isCanAddRemove,
      isHighlighted,
      isStackedOnMobile,
      itemHeight,
      header,
      onClickAvatar,
      subHeader,
      avatarWidth,
      avatarHeight,
    } = this.props;
    const canClickItem = this.canClickItem();

    return (
      <StyledListItem
        canClickItem={canClickItem}
        className={className}
        onClick={canClickItem ? this.handleClick : null}
        highlighted={isHighlighted}
        isBeingAdded={isBeingAdded}
        isBeingRemoved={isBeingRemoved}
        isCanAddRemove={isCanAddRemove}
        itemHeight={itemHeight}
        flushedEdge={flushedEdge}
        flushedEdgeOnMobile={flushedEdgeOnMobile}
        data-type="list-item"
      >
        {avatar && <AvatarBox avatarWidth={avatarWidth} avatarHeight={avatarHeight} disabled={disabled} onClick={onClickAvatar}>{avatar}</AvatarBox>}
        <ListItemContainer isStackedOnMobile={isStackedOnMobile}>
          <ContentContainer>
            <TextContainer disabled={disabled}>
              <HeaderContainer largeText={largeText} boldHeader={boldHeader}>{header}</HeaderContainer>
              {subHeader && (
                <SubHeaderContainer disabled={disabled} largeText={largeText}>{subHeader}</SubHeaderContainer>
              )}
            </TextContainer>
            {content}
            {children}
          </ContentContainer>
          {actions && <ListItemActionBox>{actions}</ListItemActionBox>}
        </ListItemContainer>
      </StyledListItem>
    );
  }
}
