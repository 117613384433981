import React from 'react';
import BoldNumbers from './Numbers/Bold';
import SmallNumbers from './Numbers/Small';
import RaterBase from '../Base';

export const NumbersRating = (props) => {
  return (
    <RaterBase {...props}>
      {props.useSmallNumbers ? <SmallNumbers /> : <BoldNumbers />}
    </RaterBase>
  );
};

NumbersRating.defaultProps = {
  useSmallNumbers: false,
};

export default NumbersRating;