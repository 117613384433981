import React from 'react';
import { Auth, Page } from '@matterapp/matter-ui';
import { Resources } from '@matterapp/routing';

function MagicLinkVerificationPage({ currentEmail }) {  
  return (
    <Page
      layout="AuthPage"
      content={
        <Auth.MagicLinkConfirmation
          currentEmail={currentEmail}
          landingPagePath={Resources.landingPage.url()}
          handleResendMagicLink={() => {}}
        />
      }
    />
  );
}

export default MagicLinkVerificationPage;
