import React from 'react';

const IconCloseCircle = ({fillOuter, fillInner}) => {
  return (
    <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x=".965" width="24" height="24" rx="12" fill={fillOuter}/>
      <g clipPath="url(#clip0)" fillRule="evenodd" clipRule="evenodd" fill={fillInner}>
        <path d="M7.826 6.862c.26-.26.683-.26.943 0l9.333 9.333a.667.667 0 01-.942.943L7.826 7.805a.667.667 0 010-.943z"/>
        <path d="M18.102 6.862a.667.667 0 00-.942 0l-9.334 9.333a.667.667 0 10.943.943l9.333-9.333a.667.667 0 000-.943z"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M4.965 4h16v16h-16V4z" fill="#fff"/>
        </clipPath>
      </defs>
    </svg>
  );
};

IconCloseCircle.defaultProps = {
  fillOuter: "#ADAFDE",
  fillInner: "#4F52B1"
};

export default IconCloseCircle;
