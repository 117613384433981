import { css } from 'styled-components';
import theme from '@matterapp/matter-theme';
import { TEXT_AREA_CONTAINER_SELECTOR } from './consts';

export const getBackgroundColor = ({ isError, isFocus }) => {
  if (isFocus) {
    return isError ? theme.colors.reds[5] : theme.colors.purples[5];
  }
  return isError ? theme.colors.reds[5] : theme.colors.blacks[5];
};

export const getTextAreaContainerStyles = () => ``;

export const getTextAreaStyles = ({ isError, resize, showBottomBorder }) => `
  padding: ${theme.spacing.threeQuarters} ${theme.spacing.single};
  font-style: normal;
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.lineHeight.S};
  font-size: ${theme.sizes.fontSizes[40]};
  border: none;
  border-bottom: ${showBottomBorder ? '2px solid' : 'none'};
  border-color: ${theme.colors.blacks[40]};
  resize: ${resize};
  width: 100%;
  color: ${theme.colors.blacks[80]};
  background: ${getBackgroundColor({ isError })};
  ::placeholder {
    color: ${theme.colors.blacks[40]};
  }
  :focus {
    ::placeholder {
      color: ${theme.colors.blacks[50]};
    }
    background: ${getBackgroundColor({ isError, isFocus: true })};
    border-color: ${isError ? theme.colors.reds[60] : theme.colors.purples[40]};
  }
`;

export const getMediumScreenInputStyles = () => theme.media.medium`
  font-size: ${theme.sizes.fontSizes[50]};
  line-height: ${theme.sizes.lineHeights[50]};
`;

export const getCharCountStyles = ({ autoHeight, isError }) => {
  const mainStyles = `
    background: ${getBackgroundColor({ isError })};
    border: none;
    padding: 0 0 ${theme.spacing.quarter} ${theme.spacing.quarter};
    position: absolute;
    bottom: ${theme.spacing.half};
    right: ${theme.spacing.single};
    z-index: 1;
  `;
  const focusStyles = `
    background: ${getBackgroundColor({ isError, isFocus: true })};
    color: ${theme.colors.purples[40]};
  `;

  if (autoHeight) {
    return css`
      ${mainStyles}
      ${TEXT_AREA_CONTAINER_SELECTOR}:focus-within + & {
        ${focusStyles}
      }
    `;
  }
  return css`
    ${mainStyles}
    input:focus + &,
    textarea:focus + & {
      ${focusStyles}
    }
  `;
};

export const getCharCountContainerStyles = ({
  isCountZero,
  isTooShort,
  isTooLong,
}) => `
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  ${(isTooShort || isTooLong) && `color: ${isCountZero ? theme.colors.black : theme.colors.reds[50]};`}
`;

export const getCharCountTextStyles = ({ isTooLong }) => `
  ${isTooLong && `color: ${theme.colors.reds[50]};`}
`;

export const getChildrenContainerStyles = () => ``;
