import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Sidebar from '../Sidebar';
import StepContainerComponent from '../StepContainer';
import { getSkillIcon } from '@matterapp/matter-ui';
import { MAX_STEP_WIDTH } from '../utils';
import theme from '@matterapp/matter-theme';

const REPLACE_NAME = '%n';
const CUSTOM_DESCRIPTION = `This is a custom skill ${REPLACE_NAME} is currently working on.`;

/**
 * On desktop, the step containerheight is 0 when not shown and on mobile
 * it always takes up the entire content space.
 */
/* istanbul ignore next */
const StepContainer = styled(StepContainerComponent)`
  ${({ isShown }) => (isShown ? `max-height: unset;` : `max-height: 0;`)}
  ${theme.media.S`
    max-height: unset;
  `}
`;

const SkillBadge = styled.img.attrs({
  height: theme.spacing.triple,
  width: theme.spacing.triple,
})`
  pointer-events: none;
  -webkit-touch-callout: none;
`;

const SkillSidebarContainerSpacer = styled.div`
  width: ${MAX_STEP_WIDTH};
`;

const SkillSidebarContainer = styled.div`
  pointer-events: none;
  position: absolute;
  top: ${theme.spacing.double};
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
`;

const SkillSidebarWrapper = styled.div`
  display: flex;
  margin: auto;
  max-width: ${theme.sizes.webClient.maxContentWidth};
  width: 100%;
`;

const SkillIconSidebar = (props) => {
  const { receiverFirstName, skillToRate, visibleStep } = props;
  const { skill } = skillToRate;
  const { description, name } = skill || {};
  const icon = skill ? <SkillBadge src={getSkillIcon(skill)} /> : null;

  const headerText = name ? `${name}:` : null;
  const descriptionText =
    description || CUSTOM_DESCRIPTION.replace(REPLACE_NAME, receiverFirstName);
  return (
    <SkillSidebarContainer>
      <SkillSidebarWrapper>
        <StepContainer
          sidebarContent={
            <Sidebar
              header={headerText}
              icon={icon}
              isTopSidebar
              content={skill ? descriptionText : null}
              visibleStep={visibleStep}
            />
          }
        >
          <SkillSidebarContainerSpacer />
        </StepContainer>
      </SkillSidebarWrapper>
    </SkillSidebarContainer>
  );
};

SkillIconSidebar.propTypes = {
  /** The feedback receiver's first name. */
  receiverFirstName: PropTypes.string,
  /** The skill to rate for this step. */
  skillToRate: PropTypes.shape({
    skill: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
  /** The currrent visible step of the timeline. */
  visibleStep: PropTypes.number,
};

SkillIconSidebar.defaultProps = {
  skillToRate: {
    skill: {},
  },
};

export default SkillIconSidebar;
