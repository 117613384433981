import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './Styles';

const Card = (props) => {
  const { className, children, isFluid } = props;
  return (
    <Styles.Container className={className} isFluid={isFluid}>{children}</Styles.Container>
  );
};

Card.propTypes = {
  /**
   * Contents of a card. It is recommended to have at most one <Card.Image> and one
   * <Card.Content> that contains <Card.Header>. The order will determine the content
   * border radius by default. If multiple <Card.Content>, a line will separate the content.
   */
  children: PropTypes.node.isRequired,
  /** Additional style class. */
  className: PropTypes.string,
  /** If card should take width of container. */
  isFluid: PropTypes.bool,
};

Card.defaultProps = {
  className: null,
  isFluid: false,
};

Card.Content = Styles.Content;
Card.Content.Padded = Styles.ContentPadded;
Card.Header = Styles.Header;
Card.Image = Styles.Image;

export default Card;