export const useTabs = (params) => {
  const { activeTab, onChange, ...otherProps } = params;

  return {
    ...otherProps,
    tabProps: {
      activeTab,
      onClick: (e, props) => {
        onChange(e, { ...params, ...props });
      },
    },
  };
};
