import React from 'react';
import PropTypes from 'prop-types';
import TwoChoices from './TwoChoices';
import * as ChoiceButtons from './ChoiceButtons';

const MonthlyWeeklyNever = (props) => {
  const {
    buttonComponent,
    children,
    onClickMonthly,
    onClickWeekly,
    onClickNever,
    value,
    ...otherProps
  } = props;

  const twoChoicesProps = {
    buttonOneComponent: ChoiceButtons.MonthlyButton,
    buttonTwoComponent: ChoiceButtons.NeverButton,
    onClickButtonOne: onClickMonthly,
    onClickButtonTwo: onClickNever,
    value,
  };

  return (
    <TwoChoices buttonComponent={buttonComponent} {...twoChoicesProps} {...otherProps}>
      <ChoiceButtons.WeeklyButton
        buttonComponent={buttonComponent}
        onClick={onClickWeekly}
        selectedValue={value}
      />
      {children}
    </TwoChoices>
  );
};

MonthlyWeeklyNever.propTypes = {
  /** Override default button component. */
  buttonComponent: PropTypes.elementType,
  /** Additional children to show. */
  children: PropTypes.node,
  /** Additional style class. */
  className: PropTypes.string,
  /** Callback when the monthly button is clicked. */
  onClickMonthly: PropTypes.func.isRequired,
  /** Callback when the weekly button is clicked. */
  onClickWeekly: PropTypes.func.isRequired,
  /** Callback when the never button is clicked. */
  onClickNever: PropTypes.func.isRequired,
};

export default MonthlyWeeklyNever;