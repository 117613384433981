"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.font = exports.fontFamilies = exports.fontSize = exports.fontFamilyWeights = exports.fontWeight = exports.fontFamily = void 0;
const fontFamily = {
  default: "'Inter UI', sans-serif",
  marketing: "'CircularXX', 'sans-serif'"
};
exports.fontFamily = fontFamily;
const fontWeight = {
  thin: 200,
  light: 300,
  regular: 400,
  book: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
  extraBlack: 950,
  ultra: 950
};
exports.fontWeight = fontWeight;
const fontFamilyWeights = {
  default: ['regular', 'medium', 'bold'],
  marketing: ['thin', 'light', 'regular', 'book', 'medium', 'bold', 'black', 'extraBlack']
};
exports.fontFamilyWeights = fontFamilyWeights;
const fontSize = {
  XXXL: '44px',
  XXL: '36px',
  XL: '32px',
  L: '24px',
  M: '22px',
  S: '17px',
  S2: '16px',
  base: '14px',
  XS: '12px',
  XXS: '10px'
}; // Deprecated. Use `FontFamily` instead.

exports.fontSize = fontSize;
const fontFamilies = {
  body: "'Inter UI', sans-serif",
  heading: 'museo-slab, sans-serif',
  marketing: "'CircularXX', sans-serif"
};
exports.fontFamilies = fontFamilies;
const font = {
  family: fontFamily,
  familyWeights: fontFamilyWeights,
  families: fontFamilies,
  size: fontSize,
  weight: fontWeight
};
exports.font = font;
var _default = font;
exports.default = _default;