import { useEffect, useContext } from 'react';
import { Resources } from '@matterapp/routing';
import { useQueryParams } from 'hooks';
import UserContext from 'context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import * as jose from 'jose';

function isSameUserCurrentlyLoggedIn({
  currentUser,
  token,
}) {  
  /**
     * We check whether the email address embedded inside the auth token matches that of the user. We do so by
     * decoding the jwt auth token. (We are decoding, not verifying. All jwt tokens can be decoded without the signature secret)
     */
  const decodedToken = jose.decodeJwt(token);

  return parseInt(decodedToken.userId) === parseInt(currentUser.id);
}

function AuthRedirectOnLoginPage() {
  const { queryParams } = useQueryParams();
  const { 
    handleAuthTokenLogin,
    signOut,
    currentUser,
    isLoadingCurrentUser
  } = useContext(UserContext);
  const navigate = useNavigate();

  const { redirectPath } = queryParams;

  async function signInAndRedirect() {
    try {
      if (currentUser && isSameUserCurrentlyLoggedIn({ currentUser, token: queryParams.token })) {
        navigate(redirectPath);

        return;
      } else if (currentUser) {
        await signOut();
      }

      await handleAuthTokenLogin(queryParams.token);  

    } catch (e) {
      navigate(Resources.authRequestVerification.path());
    }
  }

  useEffect(() => {
    if (!isLoadingCurrentUser) {
      signInAndRedirect();
    }
  }, [isLoadingCurrentUser]);

  return null;
}

export default AuthRedirectOnLoginPage;
