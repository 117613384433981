import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  LineWrapper, 
  SubHeader, 
  ActionButton,
  SubText,
  Section, 
  AllowanceBlock,
  InputWrapper,
  ResetButton,
  CustomStyledInput,
  TemplateButtonWrapper,
  SubSection
} from './styles';
import { DEFAULT_ALLOWED_COINS, getCoinsHelperText } from './helpers';
import { spacing } from '@matterapp/matter-theme';
import InlineCheckbox from 'components/Forms/InlineCheckbox';
import TextfieldSection from 'components/Forms/TextfieldSection';

export default function BirthdaySection({ 
  isActive, 
  isRewardsActive,
  celebrationSummary, 
  requestBirthdayEntry, 
  requestCelebrationSpreadsheet,
  onUploadClick,
  birthdayCoinsAwarded, 
  updateBirthdayCoinsAwarded,
  hasError,
  autoCollectBirthdays,
  updateAutoCollectBirthdays,
  defaultBirthdayMessage,
  birthdayMessage,
  updateBirthdayMessage,
  isSlack,
  isBirthdaysNotifyAll,
  updateBirthdaysNotifyAll,
  autoCollectBirthdaysStartDate
}) {
  const [askedMembers, updateAskedMembers] = useState(false);

  if (!isActive) {
    return null;
  }
  
  const { activeMembersCount, birthdayEnteredCount = 0 } = celebrationSummary || {};

  return (
    <Section>
      {isRewardsActive ? (
        <AllowanceBlock style={{ marginBottom: '24px' }}>
          <div>
            <p>Coin Amount Awarded</p> 
            {getCoinsHelperText(birthdayCoinsAwarded)}
          </div>
          <InputWrapper>
            <ResetButton 
              onClick={() => updateBirthdayCoinsAwarded(DEFAULT_ALLOWED_COINS)}
            >
              Reset
            </ResetButton>
            <CustomStyledInput
              onChange={(e) => {
                if (!e.target.value || Number(e.target.value) > -1) {
                  updateBirthdayCoinsAwarded(e.target.value ? Number(e.target.value) : '');
                }
              }}
              errorMessage={hasError ? 'Must be between 0-100,000' : null}
              showErrorMessageBelow
              errorMessageClassName="allowanceInputError"
              value={birthdayCoinsAwarded.toString()} 
            />
          </InputWrapper>
        </AllowanceBlock>
      ) : null}        
      <SubHeader>
        Add Birthdays
      </SubHeader>
      <SubText>{birthdayEnteredCount} of {activeMembersCount} members have their birthdays entered.</SubText>
      <LineWrapper>
        <p>
          Send a message to collect birthdays from members whose birthday has not been added.
        </p>
        <ActionButton 
          disabled={askedMembers}
          onClick={() => {
            updateAskedMembers(true);
            requestBirthdayEntry();
          }}
        >
          Ask Members
        </ActionButton>
      </LineWrapper>
      <LineWrapper style={{ marginTop: spacing.single }}>
        <p>
          Import birthdays in bulk via Excel spreadsheet using Matter&apos;s template.
        </p>
        <TemplateButtonWrapper>
          <ActionButton 
            onClick={requestCelebrationSpreadsheet}
          >
            Download Template
          </ActionButton>
          <ActionButton 
            onClick={onUploadClick}
          >
            Upload Completed Template
          </ActionButton>
        </TemplateButtonWrapper>
      </LineWrapper>
      <SubSection>
        <SubHeader>
          Automatically Collect
        </SubHeader>
        <p>
          {autoCollectBirthdays ? (
            `When new members are added to your workspace after ${autoCollectBirthdaysStartDate}, they will be automatically asked for their birthday.`
          ) : 'When new members are added to your workspace, they will be automatically asked for their birthday.' }
        </p>
        <InlineCheckbox 
          text='Automatically Collect From New Members'
          onChange={() => updateAutoCollectBirthdays(!autoCollectBirthdays)}
          isChecked={autoCollectBirthdays}
          checkboxName='collectBirthday'
        />
      </SubSection>
      <TextfieldSection 
        onChange={(text) => updateBirthdayMessage(text)}
        defaultMessage={defaultBirthdayMessage}
        message={birthdayMessage}
        minLength={25}
        messageHeader='Birthday Message:'
      />
      <SubText>
        {`Write {user} where you'd like to tag the member. {date} for the month and day.`}
      </SubText>
      <SubSection>
        <SubHeader>
          Notifications:
        </SubHeader>
        <InlineCheckbox 
          text={isSlack ? <>Notify Channel Members Using <strong>@here</strong></> : 'Notify team with an activity notification'}
          onChange={() => updateBirthdaysNotifyAll(!isBirthdaysNotifyAll)}
          isChecked={isBirthdaysNotifyAll}
          checkboxName='notifyBirthday'
        />
      </SubSection>
    </Section>
  );
}

BirthdaySection.propTypes = {
  isActive: PropTypes.bool,
  isRewardsActive: PropTypes.bool,
  celebrationSummary: PropTypes.object,
  requestBirthdayEntry: PropTypes.func,
  requestCelebrationSpreadsheet: PropTypes.func,
  onUploadClick: PropTypes.func,
  birthdayCoinsAwarded: PropTypes.number,
  updateBirthdayCoinsAwarded: PropTypes.func,
  hasError: PropTypes.bool,
  autoCollectBirthdays: PropTypes.bool,
  updateAutoCollectBirthdays: PropTypes.func,
  defaultBirthdayMessage: PropTypes.string,
  birthdayMessage: PropTypes.string,
  updateBirthdayMessage: PropTypes.func,
  isSlack: PropTypes.bool,
  isBirthdaysNotifyAll: PropTypes.bool,
  updateBirthdaysNotifyAll: PropTypes.func,
  autoCollectBirthdaysStartDate: PropTypes.string
};
