import { useCallback, useEffect, useState } from 'react';
import rafDebounce from '../../../common/libs/rafDebounce';
import getScrollTop from '../../../common/libs/getScrollTop';

export const height = 64;
export const mobileShortThreshold = height;
export const shadowScrollThreshold = 5;

export const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};

export const useIsPageScrolled = (params = {}) => {
  const { watchAllScrolling } = params;
  const [mobileTop, setMobileTop] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledPastHeader, setIsScrolledPastHeader] = useState(false);

  const callback = useCallback(rafDebounce((e) => {
    const scrollTop = getScrollTop(watchAllScrolling && e ? e.target : null);
    const currentMobileTop = scrollTop >= mobileShortThreshold ? -height : 0;
    const currentIsScrolled = scrollTop >= shadowScrollThreshold;
    const currentIsScrolledPastHeader = scrollTop >= height;

    if (mobileTop !== currentMobileTop) {
      setMobileTop(currentMobileTop);
    }
    if (isScrolled !== currentIsScrolled) {
      setIsScrolled(currentIsScrolled);
    }
    if (isScrolledPastHeader !== currentIsScrolledPastHeader) {
      setIsScrolledPastHeader(currentIsScrolledPastHeader);
    }
  }), [mobileTop, isScrolled]);

  useWindowEvent('scroll', callback);
  return { mobileTop, isScrolled, isScrolledPastHeader };
};