import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export default styled.div`
  ${() => `
    color: ${theme.inputs.errorTextColor};
    font-size: 14px;
    text-align: left;
  `}
`;
