import React, { useContext } from 'react';
import { SideBarTabs } from '@matterapp/matter-ui';
import { Resources } from '@matterapp/routing';
import UserContext from 'context/UserContext/UserContext';

function ContainerNavigation() {
  const { currentWorkspace } = useContext(UserContext);
  const tablist = [
    { name: 'General', url: Resources.userSettingsMain.path() },
    { name: 'Celebrations', url: Resources.userCelebrationsSettings.path() },
    {
      name: 'Email Notifications',
      url: Resources.userSettingsNotifications.path(),
    },
  ];

  if (currentWorkspace?.teamsWorkspace) {
    tablist.push({ 
      name: 'MSFT Teams Notifications', 
      url: Resources.userTeamsNotificationsSettings.path() 
    });
  } 

  return (
    <SideBarTabs
      title="Account Settings"
      tabList={tablist}
    />
  );
}

export default ContainerNavigation;
