import theme from '@matterapp/matter-theme';

export const xsmallBreakpoint = 320;
export const smallBreakpoint = 480;
export const mediumBreakpoint = 800;

export const breakpoints = {
  XS: xsmallBreakpoint,
  S: smallBreakpoint,
  M: mediumBreakpoint,
};

export const headerHeight = 64;

export const cardWidth = smallBreakpoint;
export const cardWidthWide = 736;
export const cardWidthExtraWide = 800;

export const flowTheme = {
  default: {
    background: theme.colors.purples[1],
  },
  blue: {
    background: theme.colors.blues[5],
  },
  gray: {
    background: theme.colors.blacks[5],
  },
};
export const themeColors = Object.keys(flowTheme);

export const getFlowTheme = (themeColor) => {
  return flowTheme[themeColor] || flowTheme.default;
};

export const getFlowThemeBackground = ({ themeColor, ...other }) => {
  return getFlowTheme(themeColor).background;
};
