import React, { useContext, useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import UserContext from 'context/UserContext/UserContext';
import { StyledPaper, Header, ThresholdBlock, InputWrapper, ResetButton, StyledInput } from './styles';
import { Button, Radio } from '@matterapp/matter-ui';
import { ACTIONS } from './reducer';
import AddMembersModal from './AddMembersModal';
import {
  PeersWrapper,
  PeerListItem,
  RemoveButton
} from './CustomAllowanceSection/styles';
import getMembersByPersonIds from 'graphql-queries/queries/workspace/getMembersByPersonIds';
import { useQuery } from '@apollo/client';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';

export default function FundingSection(props) {
  const { rewardsFundingSettings, dispatch } = props;
  const { currentWorkspace: workspace, currentTenant: tenant, refetchCurrentUser } = useContext(UserContext);
  const [isMembersModalOpen, setIsMembersModalOpen] = useState(false);
  const { balance = 0, enabled: isPrepayEnabled, threshold = 0, notifyMembers = [] } = rewardsFundingSettings || {};
  const navigate = useNavigate();

  const fundingOptions = [
    'Pay-As-You-Go: Credit Card on File', 
    'Prepay: Rewards Account Balance'
  ];

  const { data: membersData } = useQuery(getMembersByPersonIds, {
    variables: {
      workspaceId: workspace.id,
      personIds: notifyMembers
    },
    skip: !notifyMembers?.length
  });

  const { membersByPersonIds: members = [] } = membersData || {};

  return (
    <StyledPaper>
      <AddMembersModal 
        addMembers={(personIds) => dispatch({
          type: ACTIONS.ADD_MEMBERS_FROM_NOTIFY_LIST,
          payload: personIds
        })}
        workspace={workspace}
        tenant={tenant}
        isOpen={isMembersModalOpen}
        onClose={() => setIsMembersModalOpen(false)}
        refetchCurrentUser={refetchCurrentUser}
        existingMembers={members}
      />
      <Typography variant='h3' sx={{ mb: 3 }}>Funding Method</Typography>
      <Typography variant='body1'>
        By default, approved reward requests are charged to your credit card, which includes a 3% processing fee. To avoid this fee and reduce frequent credit card transactions, you can prepay for rewards. Invoicing options (e.g., ACH/wire) are available with prepay.
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Radio.List.Settings 
          onChange={() => {
            dispatch({ type: ACTIONS.TOGGLE_REWARDS_FUNDING });
          }}
          value={isPrepayEnabled ? fundingOptions[1] : fundingOptions[0]}
          values={fundingOptions}
        />
      </Box>
      <Divider />
      <Header>
        <Typography variant='h4'>Available Account Balance: ${(parseInt(balance) / 100).toLocaleString()} USD</Typography>
        <Button 
          size={'S'} 
          onClick={() => navigate(Resources.rewardsAddFunding.path({ workspaceId: workspace?.id }))}
        >
          Add Funds
        </Button>
      </Header>
      {isPrepayEnabled ? (
        <Box>
          <Box>
            <Typography variant='h5' component='p'>Balance Alert Threshold</Typography>
          </Box>
          <ThresholdBlock>
            <Typography variant='body1'>
              Set a balance threshold to trigger admin alerts. Alerts are sent when the threshold is reached and every 24 hours until resolved.
            </Typography>
            <InputWrapper>
              <ResetButton 
                onClick={() => dispatch({ type: ACTIONS.REWARDS_FUNDING_THRESHOLD_RESET })}
              >
                Reset
              </ResetButton>
              <StyledInput
                type="string"
                onChange={(_, target) => {
                  let value = target.value.replace(/[^0-9]/g, '');               
                  value = parseInt(value, 10);
        
                  dispatch({ 
                    type: ACTIONS.UPDATE_REWARDS_FUNDING_THRESHOLD,
                    payload: value || 0
                  });
                }}
                showErrorMessageBelow
                errorMessageClassName="allowanceInputError"
                value={`$${threshold?.toLocaleString('en-US')}`} 
              />
            </InputWrapper>
          </ThresholdBlock>
          <Header sx={{ mt: 3 }}>
            <Typography variant='h5' component='p'>Notified Members</Typography>
            <Button 
              size={'S'} 
              onClick={() => setIsMembersModalOpen(true)}
            >
              Add Member
            </Button>
          </Header>
          <Typography variant='body1'>
            By default, all admins are notified. Optionally, specify which admins receive balance alerts and account funding notifications.
          </Typography>
          <PeersWrapper>
            {members
              .map(({ id, person }) => (
                <PeerListItem
                  key={person.id}
                  peer={person}
                >
                  <RemoveButton color="black" onClick={() => dispatch({ 
                    type: ACTIONS.REMOVE_MEMBER_FROM_NOTIFY_LIST,
                    payload: person.id
                  })}>
                    REMOVE
                  </RemoveButton>
                </PeerListItem>
              ))}
          </PeersWrapper>
        </Box>
      ) : null}
    </StyledPaper>
  );
}
