import React from 'react';
import PropTypes from 'prop-types';
import YesNo from './YesNo';
import { ComplicatedButton } from './ChoiceButtons';

const YesComplicatedNo = (props) => {
  const {
    buttonComponent,
    children,
    complicatedLabel,
    onClickComplicated,
    ...otherProps
  } = props;

  return (
    <YesNo buttonComponent={buttonComponent} {...otherProps}>
      <ComplicatedButton
        buttonComponent={buttonComponent}
        label={complicatedLabel}
        onClick={onClickComplicated}
      />
      {children}
    </YesNo>
  );
};

/** Additional proptypes to <YesNo> component proptypes. */
YesComplicatedNo.propTypes = {
  /** Override default button component. */
  buttonComponent: PropTypes.elementType,
  /** Additional children to show after complicated button. */
  children: PropTypes.node,
  /** Override default complicated button label. */
  complicatedLabel: PropTypes.string,
  /** Callback function when complicated button is clicked. */
  onClickComplicated: PropTypes.func,
};

export default YesComplicatedNo;