import { graphql } from '@apollo/client/react/hoc';
import { normalizeEmail } from '@matterapp/people';
import * as jose from 'jose';
import { compose, withProps } from 'recompose';
import { SIGNUP_MEANS } from 'app-consts';
import { handleTrackSignup } from 'libs/tracking/signup';
import {
  FEEDBACK_INVITATION_TOKEN_LOGIN_MUTATION,
} from 'graphql-queries/queries';

const withFeedbackInvitationTokenLoginMutation = graphql(
  FEEDBACK_INVITATION_TOKEN_LOGIN_MUTATION,
  { name: 'feedbackInvitationTokenLoginMutation' }
);

async function handleSignIn({
  feedbackInvitationTokenLoginMutation,
  feedbackInvitationToken,
  onSuccess,
  onFailure,
  refetchCurrentUser
}) {
  try {
    const { data } = await feedbackInvitationTokenLoginMutation({
      variables: { token: feedbackInvitationToken },
    });
    const { isNewRecord, user } = data.login;

    refetchCurrentUser();
    handleTrackSignup({
      isNewRecord,
      user,
      signupMeans: SIGNUP_MEANS.EMAIL_GIVE_FEEDBACK_LINK,
    });
  } catch (error) {
    return onFailure();
  }
}

function isSameUserCurrentlyLoggedIn({
  currentUser,
  feedbackInvitationToken,
}) {  
  const { email } = currentUser;
  /**
     * We check whether the email address embedded inside the auth token matches that of the user. We do so by
     * decoding the jwt auth token. (We are decoding, not verifying. All jwt tokens can be decoded without the signature secret)
     */
  const decodedFeedbackInvitationToken = jose.decodeJwt(feedbackInvitationToken);
  const feedbackInvitationEmail = normalizeEmail(
    decodedFeedbackInvitationToken.email
  );

  return feedbackInvitationEmail === email;
}

const withHandleFeedbackInvitationTokenLogin = withProps(
  ({
    feedbackInvitationTokenLoginMutation,
  }) => {
    return {
      handleFeedbackInvitationTokenLogin: async ({
        feedbackInvitationToken,
        onSuccess,
        onFailure,
        currentUser,
        signOut,
        refetchCurrentUser,
      }) => {
        if (currentUser && !isSameUserCurrentlyLoggedIn({
          currentUser,
          feedbackInvitationToken
        })) {
          /**
           * 1. If the user is not logged in, we sign them out.
           */
          await signOut();
        } else if (currentUser) {
          return onSuccess();
        }
        /**
         * 3. Now that there's no authenticated user, we can proceed to sign the user in via the feedback invitation token.
         */
        return handleSignIn({
          feedbackInvitationToken,
          feedbackInvitationTokenLoginMutation,
          refetchCurrentUser,
          onFailure,
          onSuccess
        });
      },
    };
  }
);

const withData = compose(
  withFeedbackInvitationTokenLoginMutation,
  withHandleFeedbackInvitationTokenLogin
);

export default withData;
