import React from 'react';
import { SVG, Path } from './consts';

const TeamInsights = (props) => (
  <SVG {...props}>
    <Path
      d={`M53.3,89.9v41.5c0,2.7-2.2,4.9-4.9,4.9H20.8c-2.7,0-4.9-2.2-4.9-4.9V89.9c0-2.7,2.2-4.9,4.9-4.9h27.7
          C51.1,85,53.3,87.2,53.3,89.9z M96.8,29.7H69.2c-2.7,0-4.9,2.2-4.9,4.9v96.8c0,2.7,2.2,4.9,4.9,4.9h27.7c2.7,0,4.9-2.2,4.9-4.9V34.6
          C101.8,31.9,99.5,29.7,96.8,29.7z M150.2,62.2c0-2.7-2.2-4.9-4.9-4.9h-27.7c-2.7,0-4.9,2.2-4.9,4.9v69.2c0,2.7,2.2,4.9,4.9,4.9h27.7
          c2.7,0,4.9-2.2,4.9-4.9V62.2z`}
    />
  </SVG>
);

export default TeamInsights;
