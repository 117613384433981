import { useState } from 'react';
import { WEEKDAY_LIST } from 'libs/prop-types/weekday';
import { FREQUENCY_LIST } from '../../sharedConsts';

/*****************************************************************************/
/* Hook state for day of post picker                                         */
/*****************************************************************************/
export const useDayOfPostPicker = (params) => {
  const { dayOfPost, onChangeDayOfPost, frequency, onChangeFrequency } = params;
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(WEEKDAY_LIST[dayOfPost]);
  const [selectedFrequency, setSelectedFrequency] = useState(FREQUENCY_LIST[frequency]);

  return {
    dayOfPost,
    frequency,
    isConfirmOpen,
    handleChangeDayOfPost: (props) => {
      const { isWeekend } = props;
      setSelectedDay(props);
      if (isWeekend) {
        setIsConfirmOpen(true);
      } else {
        onChangeDayOfPost(props);
      }
    },
    handleChangeFrequency: (props) => {
      setSelectedFrequency(props);
      onChangeFrequency(props);
    },
    handleCloseModal: () => setIsConfirmOpen(false),
    handleClickCancel: () => setIsConfirmOpen(false),
    handleClickConfirm: (e) => {
      setIsConfirmOpen(false);
      onChangeDayOfPost({
        ...params,
        item: selectedDay,
        value: selectedDay.value,
      });
    },
    selectedDay,
    selectedFrequency
  };
};
