import styled from 'styled-components';
import { Clickable } from '../../../Clickable/Clickable';
import SlideOutMenu from '../../SlideOutMenu';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../../../libs/omitStyled';

export const Container = styled(SlideOutMenu).attrs(() => ({
  clickBackgroundToClose: true,
}))`
  display: inherit;
  
  ${theme.media.L`
    display: none;
  `};
`;

export const Link = omitStyled(Clickable, ['isDisabled'])`
  color: ${theme.colors.blues[50]};
  cursor: pointer;
  font-size: ${theme.fontSize.base};
  height: ${theme.spacing.singleAndHalf};
  line-height: ${theme.spacing.singleAndHalf};
  text-align: left;

  & + & {
    margin-top: ${theme.spacing.half};
  }

  &:hover {
    color: ${theme.colors.blues[60]};
  }

  &:active {
    color: ${theme.colors.purples[60]};
  }

  ${({ isDisabled }) => isDisabled ? `
    cursor: default;
    pointer-events: none;
    color: ${theme.colors.blacks[30]};
  ` : ``}
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.singleAndHalf};
`;
