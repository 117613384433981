import * as t from './actionTypes';

const initialState = {
  amountDefaultNoteMessageShown: 0,
  feedbackRating: null,
  currentSkillsIds: [],
  currentQualities: [],
  currentStep: 1,
  currentRecurringStep: 1,
  currentSkillStep: 1,
  currentSkillToRate: {},
  currentUserData: { fullName: '' },
  isDefaultNoteMessageShown: false,
  qualitiesToRate: [],
  receiver: {},
  saveHashes: [],
  showDefaultNoteMessage: false,
  skillsToRate: [],
  currentSkillsToRate: [],
  timelineIsInitialized: false,
  visibleStep: 1,
  totalSteps: 2,
};

/**
 * Keeps track of how many times the Default Note message was shown.
 * @param { Object } actionProps: {
 *   @param { Object } action: The action payload containing flag if message should be shown.
 *   @param { Object } state: The current state.
 * @returns { Number } The number of time the default note message was shown.
 */
export const getAmountDefaultNoteMessageShown = ({ action, state }) => {
  const { amountDefaultNoteMessageShown } = state;
  if (action.showDefaultNoteMessage) {
    return amountDefaultNoteMessageShown + 1;
  }
  return amountDefaultNoteMessageShown;
};

/**
 * Gets the updated list of skills to rate.
 * @param { Object } actionProps: {
 *   @param { Object } action: The action payload containing the new skill.
 *   @param { Object } state: The current state.
 * }
 * @returns { Array } The updated list of skills.
 */
export const getUpdatedSkillsToRate = ({ action, state }) => {
  const { isRecurring, index, skillToRate } = action;
  const { skill } = skillToRate;
  const { skillsToRate } = state;

  if (isRecurring) {
    return skillsToRate.map(oldSkill => {
      if (skill && oldSkill.skill.id === skill.id) {
        return {
          ...oldSkill,
          currentRating: skillToRate.currentRating,
          currentAbilities: skillToRate.currentAbilities,
          actionItemSelections: skillToRate.actionItemSelections,
          currentNote: skillToRate.currentNote,
        };
      }
      return oldSkill;
    });
  }

  return skillsToRate
    .slice(0, index)
    .concat(skillToRate)
    .concat(skillsToRate.slice(index + 1));
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case t.CHANGE_FEEDBACK_RATING:
    return {
      ...state,
      feedbackRating: action.feedbackRating,
    };
  case t.CHANGE_SELECTED_SKILLS_IDS:
    const { skill } = action;
    let skillIdIndex, newIds;
    if (skill) {
      skillIdIndex = state.currentSkillsIds.indexOf(skill.id);
    }
    if (skillIdIndex === -1) {
      newIds = [...state.currentSkillsIds, skill.id];
    } else {
      newIds = [
        ...state.currentSkillsIds.slice(0, skillIdIndex),
        ...state.currentSkillsIds.slice(skillIdIndex + 1)
      ];    
    }
    return { ...state, currentSkillsIds: newIds };
  case t.CHANGE_CURRENT_QUALITIES:
    return {
      ...state,
      currentQualities: action.currentQualities,
    };
  case t.CHANGE_CURRENT_SKILL_RATINGS:
    return {
      ...state,
      currentSkillToRate: action.skillToRate,
      skillsToRate: getUpdatedSkillsToRate({ action, state }),
    };
  case t.CHANGE_CURRENT_STEP:
    return {
      ...state,
      currentStep: action.currentStep,
    };
  case t.CHANGE_CURRENT_RECURRING_STEP:
    return {
      ...state,
      currentRecurringStep: action.currentRecurringStep,
    };
  case t.CHANGE_CURRENT_SKILL_STEP:
    return {
      ...state,
      currentSkillStep: action.currentSkillStep,
    };
  case t.CHANGE_CURRENT_USER_FULL_NAME:
    return {
      ...state,
      currentUserData: {
        fullName: action.currentUserFullName,
      },
    };

  case t.CHANGE_VISIBLE_STEP:
    return {
      ...state,
      visibleStep: action.visibleStep,
      currentSkillStep: 1,
    };
  case t.INITIALIZE_TIMELINE:
    const { currentSkillsToRate } = action;
    const totalSteps = currentSkillsToRate?.length + 2 || 2; //This is total initial steps for recurring, it will change in local state

    return {
      ...state,
      feedbackRating: action.feedbackRating || null,
      currentSkillsIds: action.currentSkillsIds || [],
      selectedSkillIds: action.selectedSkillIds || [],
      currentStep: action.currentStep,
      currentRecurringStep: action.currentRecurringStep || 1,
      feedbackId: action.feedbackId,
      qualitiesToRate: action.qualitiesToRate,
      receiver: action.receiver,
      skillsToRate: action.skillsToRate,
      currentSkillsToRate: action.currentSkillsToRate || [],
      totalSteps,
      timelineIsInitialized: true,
      visibleStep: action.currentStep,
    };
  case t.RESET_TIMELINE: 
    return {
      ...state,
      amountDefaultNoteMessageShown: 0,
      feedbackRating: null,
      currentSkillsIds: [],
      currentQualities: [],
      currentStep: 1,
      currentRecurringStep: 1,
      currentSkillStep: 1,
      currentSkillToRate: {},
      totalSteps: 2,
      currentUserData: { fullName: '' },
      isDefaultNoteMessageShown: false,
      saveHashes: [],
      showDefaultNoteMessage: false,
      timelineIsInitialized: false,
      visibleStep: 1,
    };
  case t.SAVE_TIMELINE:
    return {
      ...state,
      saveHashes: [...state.saveHashes, action.saveHash],
    };
  case t.SAVE_TIMELINE_COMPLETE:
    return {
      ...state,
      saveHashes: state.saveHashes.filter((hash) => hash !== action.saveHash),
    };
  case t.SET_GENERATED_DEFAULT_NOTE:
    return {
      ...state,
      generatedDefaultNote: action.generatedDefaultNote,
    };
  case t.SET_WORK_RELATIONSHIP:
    return {
      ...state,
      isWorkingTogether: action.isWorkingTogether,
      visibleStep: 2,
    };
  case t.TOGGLE_IS_DEFAULT_NOTE_MESSAGE_SHOWN:
    return {
      ...state,
      isDefaultNoteMessageShown: action.isDefaultNoteMessageShown,
    };
  case t.TOGGLE_SHOW_DEFAULT_NOTE_MESSAGE:
    return {
      ...state,
      amountDefaultNoteMessageShown: getAmountDefaultNoteMessageShown({
        action,
        state,
      }),
      showDefaultNoteMessage: action.showDefaultNoteMessage,
    };
  default:
    return state;
  }
};

export default reducer;
