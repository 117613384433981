import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const shortScreenHeight = '1000px';
const shortScreenWidth = theme.screenWidthBreakpoints.S;

export const getSVGStyles = () => `
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  max-height: 100vh;
  bottom: 100vh;
  @media screen and (max-height: ${shortScreenHeight}) and (min-width: ${shortScreenWidth}) {
    top: -20vh;
    bottom: unset;
  }

  & svg {
    position: absolute;
    overflow: overlay;
    left: 50%;
    bottom: 0;
    width: 100%;
    transform: translateX(-50%);
    height: 1200px;

    @media screen and (max-height: ${shortScreenHeight}) and (min-width: ${shortScreenWidth}) {
      top: -100vh;
      bottom: unset;
    }
  }
`;

export const getTransitionStyles = () => {
  return `
    opacity: 0;
    transform: translateY(200vh);
    transition: transform 0.65s, opacity
      ${theme.transitions.times.double} ${theme.transitions.times.double};
    @media screen and (max-height: ${shortScreenHeight}) and (min-width: ${shortScreenWidth}) {
      transform: translateY(200vh);
    }
  `;
};

export const getTransitionStylesCurrent = () => {
  return `
    opacity: 1;
    transform: translateY(100vh);
    transition: transform 0.65s, opacity ${theme.transitions.times.double};
  `;
};

export const getBackgroundColor = (props) => {
  const { currentStep, qualitiesStepNumber } = props;
  if (currentStep > qualitiesStepNumber) {
    return theme.colors.blues[5];
  }
  return 'transparent';
};

export const Container = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${getBackgroundColor};
  transition: background ${theme.transitions.times.singleAndHalf};
`;

export const BackgroundContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  background: ${({ showBackgroundOnMobile }) => showBackgroundOnMobile ? '#F3F8FF' : ''};
`;

export const CurrentBackground = styled.div`
  display: none;

  ${theme.media.S`
    display: block;
    ${getSVGStyles}
    ${getTransitionStylesCurrent}
  `}
`;