import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Emoji from '../Emoji';

const SIZE = 96;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${SIZE}px;
  min-width: ${SIZE}px;
  height: ${SIZE}px;
  min-height: ${SIZE}px;

  background-color: ${(props) => props.theme.colors.blues[40]};
  border: 8px solid ${(props) => props.theme.colors.purples[50]};

  border-radius: 50%;
`;

const Badge = ({ size, theString, className }) => (
  <Container className={className}>
    <Emoji.Image size={size} theString={theString} />
  </Container>
);

Badge.defaultProps = {
  size: 52,
  theString: '',
  className: '',
};

Badge.propTypes = {
  size: PropTypes.number,
  theString: PropTypes.string,
  className: PropTypes.string,
};

export default Badge;
