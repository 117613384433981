import styled from 'styled-components';
import theme, { spacing } from '@matterapp/matter-theme';
import {
  IconNew as Icon,
  Panel,
  Menu,
  Button,
  PeerAdvisorListItem,
} from '@matterapp/matter-ui';
import MembersListBanner from './membersListBanner.png';

export const KudosIcon = styled(Icon).attrs({ name: 'clap' })``;
export const GiveFeedbackIcon = styled(Icon).attrs({ name: 'giveFeedback' })``;
export const RequestFeedbackIcon = styled(Icon).attrs({ name: 'requestFeedback' })``;
export const ViewDetailsIcon = styled(Icon).attrs({ name: 'eyev2' })``;
export const RecurringIcon = styled(Icon).attrs({ name: 'recurring' })``;
export const EditIcon = styled(Icon).attrs({ name: 'pencil'})``;

export const iconLabels = {
  REQUEST: 'Request Feedback',
  RECURRING: 'Start Continuous',
  EDIT: 'Edit Continuous',
  GIVE: 'Give Feedback',
  KUDOS: 'Give Kudos',
  INFO: 'View Info',
  DISABLED: 'Actions are limited for this member, ask them to join Matter to Give Feedback and Kudos.',
  SELF: 'You cannot rate yourself',
};

export const transitionTime = 550;

export const addAdvisorTransitionTime = 3000;

export const MainColumnContainer = styled.div`
  transform: translate3d(0, 0, 0);
  margin: -${spacing.single};
  padding: ${spacing.single};
  overflow: hidden;
`;

export const MenuContainer = styled.div`
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  z-index: 5;
  transition: opacity ${transitionTime.single}, margin 0s ${transitionTime.single};
  pointer-events: none;
  transform: translateX(${theme.spacing.half});
  position: absolute;
`;

export const ListContainer = styled(Panel.Base)`
  overflow: hidden;
  ${theme.media.L`
    overflow: visible;
  `}
`;

const sharedHeaderStyles = `
  padding: ${theme.spacing.singleAndHalf};
  h2 {
    color: ${theme.colors.blacks[60]} !important;
  }    
`;

export const AdvisorsHeader = styled(Panel.Header.Basic)`
  ${sharedHeaderStyles}  
`;

export const AdvisorsBody = styled.div`
  padding: ${({ isRecurring }) => isRecurring ? `${theme.spacing.doubleAndHalf} ${theme.spacing.singleAndHalf};` : '109px 0'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.blacks[30]};
  font-weight: ${theme.fontWeight.semiBold};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  border-top: 1px solid ${theme.colors.blacks[10]};
`;

export const AdvisorsListItem = styled(PeerAdvisorListItem)`
  margin: 0;
  padding: ${theme.spacing.single} ${theme.spacing.singleAndHalf};
  color: ${theme.colors.blacks[90]};
  // Because we allowed visible overflow above, we have to make sure the last list item still have a border radius
  &:last-child {
    border: none;
    border-radius: 0 0 ${theme.borderRadius.L} ${theme.borderRadius.L};
  }
  & ${Menu.Inline.Item.ItemWrapper} {
    color: ${theme.colors.blacks[60]};
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  line-height: ${theme.lineHeight.M};
  font-size: ${theme.fontSize.base};
`;

export const MembersListContainer = styled.div`
  .advisor-list-item-enter {
    opacity: 0;
    height: 0;
    margin-top: -1px; // this negative margin negates the border so there isn't a jump when item appears.
    padding-top: 0;
    padding-bottom: 0;
    background: ${theme.colors.yellows[1]};
    & ${MenuContainer} {
      background: transparent;
      box-shadow: none;
    }
  }
  .advisor-list-item-enter-active {
    opacity: 1;
    height: 81.08px; // this is an exact height of each list item. Prevents jumps.
    margin: 0;
    padding-top: ${theme.spacing.single};
    padding-bottom: ${theme.spacing.single};
    background: ${theme.colors.yellows[1]};
    transition: all ${transitionTime}ms;
    & ${MenuContainer} {
      background: transparent;
      box-shadow: none;
    }
  }
  .advisor-list-item-enter-done {
    background: white;
    transition: background 1.7s;
    & ${MenuContainer} {
      background: white;
      transition: background 1.7s, box-shadow 1.7s;
    }
  }
  .advisor-list-item-exit {
    opacity: 1;
    height: 81.08px;
    overflow: hidden;
  }
  .advisor-list-item-exit-active {
    opacity: 0;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    overflow: hidden;
    transition: all ${transitionTime}ms;
  }
  div:last-child {
    border-bottom: 0;
  }
`;

export const MembersHeaderContainer = styled.div`
 flex: 1 1 auto;
 display: flex;
 div:nth-child(2) {
  cursor: pointer;
}
`;

export const ConfirmButton = styled(Button.Simple.Primary)`
  flex: 1 1 100%;
  padding: ${() => theme.spacing.half} 0;
  width: 100%;
`;

export const Subheading = styled.div`
  margin-bottom: ${theme.spacing.half};
`;

export const TextAreaContainer = styled.div`
  margin: ${theme.spacing.threeQuarters} ${theme.spacing.singleAndHalf};
  .search-text-area {
    height: ${theme.spacing.tripleAndHalf};
    font-size: ${theme.fontSize.S};
    line-height: ${theme.lineHeight.M}
  }
`;

export const AdvisorListItemContainer = styled.div`
  & > div:first-child{
    background-color: ${({ showAdvisorAdded }) => {
  return showAdvisorAdded ? `#FFFBF0`: `#FFFFFF`;
}} !important;
  }
  ${Menu.Inline.MenuContainer} {
    background: transparent !important;
    box-shadow: none !important;
  }
  border-bottom: 1px solid ${theme.colors.blacks[10]};
`;

export const Banner = styled.div`
  display: flex;
  justify-content: center;
  background: ${theme.colors.reds[5]};
  border: 1px solid ${theme.colors.reds[10]};
  padding: ${theme.spacing.single};
  position: relative;
  text-align: center;
  color: ${theme.colors.blacks[80]};
  margin-bottom: ${theme.spacing.singleAndHalf}
  ${theme.media.S`
    justify-content: flex-start;
    padding: ${theme.spacing.singleAndHalf};
    height: 200px;
    text-align: left;
    border-radius: ${theme.borderRadius.L};
  `}
`;

export const BannerTextContainer = styled.div`
  width: 100%;
  ${theme.media.XS`
    width: 85%;
  `}
  ${theme.media.S`
    width: 435px;
  `}
  ${theme.media.M`
    width: 465px;
  `}
  ${theme.media.L`
    width: 355px;
  `}
`;

export const BannerTitle = styled.div`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.M};
  line-height: ${theme.spacing.double};
  margin-bottom: ${theme.spacing.single};
`;

export const BannerDescription = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.spacing.singleAndHalf};
`;

export const Background = styled.img.attrs({
  src: MembersListBanner
})`
  display: none;
  ${theme.media.S`
    display: block;
    position: absolute;
    right: 50%;
    height: 176px;
    right: ${theme.spacing.singleAndThreeQuarters};
    top: ${theme.spacing.threeQuarters};
  `}
`;

export const MembersEmptyMessageContainer = styled.div`
  height: ${({ noMatches, showInvite }) => noMatches ? showInvite ? '136px': '160px' : '243px'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: ${({ noMatches }) => noMatches ? '0': `1px solid ${theme.colors.blacks[10]}`};
`;

export const MembersEmptyMessage = styled.div`
  width: 355px;
  font-size: ${theme.fontSize.S};
  font-weight: ${theme.fontWeight.semiBold};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[30]};
  text-align: center;
`;

export const MembersEmptyMessageBody = styled.div`
  margin-top: ${theme.spacing.single};
  font-size: ${theme.fontSize.S};
  font-weight: ${theme.fontWeight.regular};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[30]};
  text-align: center;
`;
