import omitSyled from '../../libs/omitStyled';
import { ButtonNew } from '../Button';
import theme from '@matterapp/matter-theme';

const BasicButton = omitSyled(ButtonNew).attrs({
  basic: true,
  size: 'medium',
})`
  display: block;
  ${(props) => !props.fluid && 'width: 280px;'}
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  font-size: 22px;
  ${({ disabled }) => !disabled && `
    border-color: ${() => theme.colors.blues[20]};
  `}

  &:last-child {
    margin-bottom: 0;
  }

  ${({ noMarginBottom }) => noMarginBottom && `
    margin-bottom: 0;
  `}
`;

export default BasicButton;
