import React, { useState, useContext } from 'react';
import { 
  Box,
  Typography,
  TableCell,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableRow as MuiTableRow,
  TableBody,
  Table
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserContext from 'context/UserContext/UserContext';
import { TableRow, StyledButton, StyledTableCell } from './styles';
import styled from 'styled-components';
import QuestionDetails from './QuestionDetails';
import DarkMenu from 'components/Menu/Dark/Menu';
import CreateQuestionModal from './CreateQuestionModal';
import SubcategoriesModal from './SubcategoriesModal';
import AddSubcategoryModal from './AddSubcategoryModal';
import AddCategoryModal from './AddCategoryModal';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';
import Toast from 'components/Toast/Toast';
import { Confirm } from '@matterapp/matter-ui';
import deletePulseCategory from 'graphql-queries/mutations/pulse/deletePulseCategory';
import getTenantPulseCategories from 'graphql-queries/queries/pulse/getTenantPulseCategories';
import { useMutation } from '@apollo/client';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: 0,
  '.MuiAccordionDetails-root': {
    backgroundColor: theme.palette.blacks[5],
    borderTop: `1px solid ${theme.palette.blacks[20]}`,
    paddingLeft: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  },
  ':hover': {
    backgroundColor: theme.palette.blacks[5],
  },
  ':active': {
    backgroundColor: theme.palette.purple[5]
  },
}));

const NoQuestionsWrapper = styled(Box)({
  height: '140px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

const NoQuestions = styled(Typography)(({ theme }) => ({
  color: theme.palette.blacks[60], 
  textAlign: 'center'
}));

const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({ isExpanded }) => ({
  transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0
}));

const QuestionsHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme.spacing(2)} 0`,
  paddingBottom: theme.spacing(3),
  marginLeft: theme.spacing(1.5),
  marginBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.blacks[20]}`
}));

const CategoryWrapper = styled(Box)(({ theme }) => ({
  '> div': {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  }
}));

export default function CategoryGroup({ 
  questions,
  allQuestions,
  category,
  isExpanded,
  onChange,
  allCategories
}) {
  const { currentTenant, currentWorkspace } = useContext(UserContext);
  const { id: tenantId, pulseSettings } = currentTenant || {};
  const { isPulseUnlocked = false } = pulseSettings || {};
  const { id: workspaceId } = currentWorkspace || {};
  const { name: categoryName, isEnabled, id } = category;
  const [deleteCategory] = useMutation(deletePulseCategory);
  const [deletingCategory, setDeletingCategory] = useState(null);
  const [addingQuestion, setAddingQuestion] = useState(false);
  const [subcategoryModalOpen, setSubcategoryModalOpen] = useState(false);
  const [addSubCategoryModalIsOpen, setAddSubCategoryModalIsOpen] = useState(false);
  const [editCategoryModalIsOpen, setEditSubCategoryModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const menuMap = [
    {
      menuLabel: 'Edit',
      rcLabel: 'edit-category-menu',
      handleAction: () => {
        setEditSubCategoryModalIsOpen(true);
      },
    },
    {
      menuLabel: 'View Results',
      rcLabel: 'view-results-category-menu',
      handleAction: () => {
        if (isPulseUnlocked) {
          navigate(
            Resources.pulseCategoryDetails.path({ workspaceId, categoryId: category.id })
          );
        } else {
          Toast.error('You can access your Pulse Survey results once 3 members have completed the survey.');
        }
      },
    },
    {
      menuLabel: 'Subcategories',
      rcLabel: 'subcategories-category-menu',
      handleAction: () => {
        setSubcategoryModalOpen(true);
      },
    },
    {
      menuLabel: 'Delete',
      rcLabel: 'delete-category-menu',
      handleAction: () => {
        setDeletingCategory(id);
      },
    }
  ];

  return (
    <MuiTableRow>
      <Confirm
        header='Delete Category'
        subHeader={(
          <>
            💡 You are removing the category: <b>{category.name}</b>, and all associated data. This cannot be undone.
          </>
        )}
        isOpen={deletingCategory}
        confirmButtonLabel='Yes, Delete'
        confirmButtonIsDestructive
        cancelButtonLabel='Cancel'
        onClickCancel={() => setDeletingCategory(false)}
        onClickConfirm={async () => {
          if (allCategories.length === 1) {
            setDeletingCategory(false);
            Toast.error('You must have at least one category in your Pulse Survey');
          } else {
            await deleteCategory({ 
              variables: { 
                tenantId,
                id: category.id
              },
              refetchQueries: [{
                query: getTenantPulseCategories,
                variables: { tenantId }
              }]
            });
            setDeletingCategory(false);
  
            Toast.success('Category deleted');
          }
        }
        }
      />
      <CreateQuestionModal 
        isOpen={addingQuestion}
        onClose={() => setAddingQuestion(false)}
        onAddSubcategory={() => setSubcategoryModalOpen(true)}
        tenantId={tenantId}
        category={category}
      />
      <SubcategoriesModal
        isOpen={subcategoryModalOpen}
        onClose={() => setSubcategoryModalOpen(false)}
        onAddSubcategory={() => {
          setSubcategoryModalOpen(false);
          setAddSubCategoryModalIsOpen(true);
        }}
        tenantId={tenantId}
        category={category}
        questions={questions}
      />
      <AddSubcategoryModal 
        isOpen={addSubCategoryModalIsOpen}
        onClose={() => setAddSubCategoryModalIsOpen(false)}
        tenantId={tenantId}
        category={category}
      />
      <AddCategoryModal
        isEdit
        allCategories={allCategories}
        isOpen={editCategoryModalIsOpen}
        onClose={() => setEditSubCategoryModalIsOpen(false)}
        tenantId={tenantId}
        category={category}
      />
      <TableCell colSpan={5} sx={{ padding: 0 }}>
        <StyledAccordion onChange={onChange} expanded={isExpanded} disableGutters>
          <StyledAccordionSummary>
            <Table>
              <TableBody>
                <TableRow sx={{ width: '100%' }}>
                  <StyledTableCell visibleOnMobile>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <StyledExpandMoreIcon fontSize='large' sx={{ fill: '#757575' }} />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell visibleOnMobile>
                    <CategoryWrapper>
                      <b>{categoryName}</b>
                      <Box>
                        <Typography variant='body2' color='blacks.50'>
                          {isEnabled ? 'Enabled' : 'Disabled'} | {questions.length} question{questions.length > 1 ? 's' : ''}
                        </Typography>
                      </Box>
                    </CategoryWrapper>
                  </StyledTableCell>
                  <StyledTableCell>
                    {isEnabled ? 'Enabled' : 'Disabled'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {questions.length}
                  </StyledTableCell>
                  <StyledTableCell visibleOnMobile>
                    <DarkMenu menuMap={menuMap} />
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledAccordionSummary>
          <AccordionDetails>
            <QuestionsHeader>
              <Typography variant='h4'>Questions</Typography>
              <StyledButton onClick={() => setAddingQuestion(true)}>
                Add Question
              </StyledButton>
            </QuestionsHeader>
            {questions?.length ? (
              <Table sx={{ ml: 1.5, mr: 1.5 }}>
                <TableBody>
                  {[...questions]
                    .sort((a, b) => a.question.localeCompare(b.question))
                    .map((question) => (
                      <QuestionDetails 
                        key={question.id} 
                        allQuestions={allQuestions}
                        question={question}
                        category={category}
                        tenantId={tenantId}
                        workspaceId={workspaceId}
                        onAddSubcategory={() => setSubcategoryModalOpen(true)}
                      />
                    ))}
                </TableBody>
              </Table>
            ) : (
              <NoQuestionsWrapper>
                <NoQuestions variant='h6' component='p'>
                  <b>No questions</b>
                </NoQuestions>
                <NoQuestions variant='body1' component='p'>
                  Add one or more questions to this category
                </NoQuestions>
              </NoQuestionsWrapper>
            )}
          </AccordionDetails>
        </StyledAccordion>
      </TableCell>
    </MuiTableRow>
  );
}
