export const BASE = 'kudosBobaCorgi';

export const CUP = `${BASE}-cup`;
export const STRAW = `${BASE}-straw`;

export const CORGI = {
  GROUP: `${BASE}-corgi`,
  EYE: {
    LEFT: `${BASE}-eye-left`,
    RIGHT: `${BASE}-eye-right`,
  },
  MOUTH: `${BASE}-mouth`,
};

export const BOBA = {
  B_1: `${BASE}-boba-1`,
  B_2: `${BASE}-boba-2`,
  B_3: `${BASE}-boba-3`,
  B_4: `${BASE}-boba-4`,
  B_5: `${BASE}-boba-5`,
  B_6: `${BASE}-boba-6`,
  B_7: `${BASE}-boba-7`,
  B_8: `${BASE}-boba-8`,
  B_9: `${BASE}-boba-9`,
  B_10: `${BASE}-boba-10`,
  B_11: `${BASE}-boba-11`,
};
