import React, { useState, useContext } from 'react';
import { Button, Confirm } from '@matterapp/matter-ui';
import Toast from 'components/Toast/Toast';
import {
  getDayOfMonth,
  getMonth,
  getYear,
} from '@matterapp/calendar-date';
import {
  PLAN,
  RECURRING_INTERVAL,
  UPDATE_BILLING_PLAN,
  monthsOptions,
} from '../consts';
import {
  useCreateStripePortalSession
} from '../hooks';
import styled from 'styled-components';
import BlueBanner from 'components/BlueBanner/BlueBanner';
import { Box, Divider, Typography, Link } from '@mui/material';
import { useMutation } from '@apollo/client';
import updateSubscriptionForPlanChange from 'graphql-queries/mutations/billing/updateSubscriptionForPlanChange';
import addPulseToSubscription from 'graphql-queries/mutations/billing/addPulseToSubscription';
import UserContext from 'context/UserContext/UserContext';

const StyledLink = styled(Link)(() => ({ theme }) => ({
  color: theme.colors.blues[50],
  fontSize: '14px',
  lineHeight: '14px',
  textDecoration: 'none',
  cursor: 'pointer',
}));

const PlanBreakdown = styled(Box)(() => ({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}));

const PulseDescription = styled(Box)(() => ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: '35px'
}));

const PlanSection = styled(Box)(() => ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '106px',
  width: 'calc(50% - 8px)',
  borderRadius: '4px',
  border: '1px solid #E4E4E4',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  ':nth-child(2n)': {
    marginLeft: theme.spacing(2)
  },
  h5: {
    textTransform: 'none'
  }
}));

const AddPulse = styled(PlanSection)(() => ({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: 'auto',
  margin: 0,
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    height: '250px'
  }
}));

const getUserCount = (quantity, currentTier) => {
  if (currentTier) {
    const { minMembers, maxMembers } = currentTier;
    const membersText = maxMembers ? `${minMembers}-${maxMembers}` : quantity.toLocaleString();

    return `${membersText}`;
  }

  return quantity.toLocaleString();
};

function BillingBannerContent(props) {
  const { 
    workspace,
    subscription, 
    canceledSubscription,
    isActiveSubscription,
    isYearlySubscription,
    tenant
  } = props;
  const { refetchCurrentUser } = useContext(UserContext);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isPulseConfirmModalOpen, setIsPulseConfirmModalOpen] = useState(false);
  const onCreatePortalSession = useCreateStripePortalSession();
  const [changeSubscription, { loading: changingSubscription }] = useMutation(updateSubscriptionForPlanChange);
  const [addPulse, { loading: addingPulse }] = useMutation(addPulseToSubscription);

  const { id: workspaceId, tenantId } = workspace;
  const { stripeCustomerId, isEnterprise } = tenant;

  let userCountText, currentPayment, renewalDate;
  const { quantity, renewDate, interval, planMoney, currentTier, totalAfterDiscount, addOns = [], paymentMethod } = subscription || {};

  if (subscription) {
    userCountText = getUserCount(quantity, currentTier);
    const paymentAmount = totalAfterDiscount || totalAfterDiscount === 0 ? totalAfterDiscount : planMoney;
    currentPayment = parseFloat(paymentAmount / 100).toFixed(2);

    const [dollars, cents] = currentPayment.split('.');
    currentPayment = `${parseInt(dollars).toLocaleString('en-US')}.${cents}`;

    renewalDate = canceledSubscription ? null : `${monthsOptions[getMonth(renewDate)]} ${getDayOfMonth(renewDate)}, ${getYear(renewDate)}`;
  }

  async function onChangePlan() {
    if (isActiveSubscription && subscription) {
      setIsConfirmModalOpen(false);

      const modifiedPlan = isYearlySubscription ? PLAN.MONTH : PLAN.YEAR;

      await changeSubscription({
        variables: {
          tenantId,
          plan: modifiedPlan,
        },
      });
      Toast.success("You've successfully updated your plan");
    }
  };

  async function onAddPulse() {
    if (isActiveSubscription && subscription) {
      setIsPulseConfirmModalOpen(false);

      await addPulse({
        variables: {
          tenantId
        },
      });

      refetchCurrentUser();

      Toast.success('Success! You’ve added Surveys');
    }
  };

  const onOpenCustomerPortal = async () => {
    const newWindow = window.open('', '_blank');
    if (stripeCustomerId) {
      const { data } = await onCreatePortalSession(workspaceId);

      if (data?.createPortalSession) {
        newWindow.location = data.createPortalSession;
      }
    }
  };

  const isYearlyPlan = interval === RECURRING_INTERVAL.YEAR;

  return (
    <>
      <Confirm
        header={UPDATE_BILLING_PLAN.HEADER}
        subHeader={UPDATE_BILLING_PLAN.SUBHEADER}
        isOpen={isConfirmModalOpen}
        confirmButtonLabel='Yes, I am sure'
        cancelButtonLabel='Cancel'
        onClickCancel={() => setIsConfirmModalOpen(false)}
        onClickConfirm={onChangePlan}
      />
      <Confirm
        header={UPDATE_BILLING_PLAN.HEADER}
        subHeader='This billing update will take effect immediately.'
        isOpen={isPulseConfirmModalOpen}
        confirmButtonLabel='Yes, I am sure'
        cancelButtonLabel='Cancel'
        onClickCancel={() => setIsPulseConfirmModalOpen(false)}
        onClickConfirm={onAddPulse}
      />
      <Box>
        <BlueBanner 
          showButton={!isYearlyPlan}
          text={isYearlyPlan ? '🎉 Woohoo! You’re saving 25% by paying annually 👏' : '👉 Save 25% by paying annually'}
          buttonProps={{
            isDisabled: changingSubscription,
            onClick: () => setIsConfirmModalOpen(true),
            text: changingSubscription ? 'Switching' : 'Switch to Annual Billing'
          }}
        />
        <PlanBreakdown>
          <PlanSection>
            <Typography variant='body2' color='blacks.50' sx={{ lineHeight: '17px' }}>
              Plan
            </Typography>
            <Typography variant='h5' color='blacks.90'>
              {isEnterprise ? 'Enterprise Plan' : 'Pro'}{addOns?.length ? ` + ${addOns[0].name}` : ''}
            </Typography>
            <StyledLink 
              href='https://matterapp.com/pricing'
              target='_blank'
              noopener
              noreferrer
            >
              See what&apos;s included
            </StyledLink>
          </PlanSection>
          <PlanSection>
            <Typography variant='body2' color='blacks.50' sx={{ lineHeight: '17px' }}>
              {isYearlySubscription ? 'Annual Price' : 'Monthly Price'}
            </Typography>
            <Typography variant='h5' color='blacks.90' sx={{ fontWeight: 'normal' }}>
              <b>${currentPayment}</b> ({`${userCountText} user${userCountText > 1 ? 's' : ''}`})
            </Typography>
            <StyledLink onClick={onOpenCustomerPortal}>View billing details</StyledLink>
          </PlanSection>
          <PlanSection>
            <Typography variant='body2' color='blacks.50' sx={{ lineHeight: '17px' }}>
              Renews on
            </Typography>
            <Typography variant='h5' color='blacks.90'>
              {renewalDate}
            </Typography>
            <StyledLink onClick={onOpenCustomerPortal}>View receipts</StyledLink>
          </PlanSection>
          <PlanSection>
            <Typography variant='body2' color='blacks.50' sx={{ lineHeight: '17px' }}>
              Card on File
            </Typography>
            <Typography variant='h5' color='blacks.90'>
              {paymentMethod?.card?.last4 ? `**** **** **** ${paymentMethod?.card?.last4}` : '**** **** **** ****'}
            </Typography>
            <StyledLink onClick={onOpenCustomerPortal}>Update credit card</StyledLink>
          </PlanSection>
        </PlanBreakdown>
        <Divider />
        {addOns?.length ? null : (
          <>
            <Typography variant='h5' color='blacks.90' sx={{ textTransform: 'none' }}>
              Add-ons
            </Typography>
            <AddPulse>
              <PulseDescription>
                <Typography variant='h5' color='blacks.90' sx={{ fontWeight: 'normal', mb: 1 }}>
                  <b>Surveys</b> (${isYearlyPlan ? 2 : 3} user/month)
                </Typography>
                {isYearlyPlan ? null : (
                  <Typography variant='body2' color='blacks.50' sx={{ lineHeight: '20px', mb: 1 }}>
                    Only $2 user/month when you switch to Annual Billing.
                  </Typography>
                )}
                <Typography variant='body2' sx={{ lineHeight: '20px' }}>
                  Matter&apos;s Survey add-on includes Pulse + eNPS Surveys, and Custom Surveys.
                </Typography>
              </PulseDescription>
              <Box>
                <Button.Primary.Vivid
                  color={'blue'}
                  size='S'
                  disabled={addingPulse}
                  onClick={() => setIsPulseConfirmModalOpen(true)}
                >
                  {addingPulse ? 'Upgrading...': 'Upgrade'}
                </Button.Primary.Vivid>
              </Box>
            </AddPulse>
            <Divider />
          </>
        )}
      </Box>
    </>
  );
}

export default BillingBannerContent;