import React, { useEffect, useState } from 'react';
import { useSpring } from 'react-spring';
import PropTypes from 'prop-types';
import { skillValueObjectPropType } from '@matterapp/skills';
import Badge from '../Badge';
import Unit from './Unit';
import { getSkillColorPaletteFromSkill } from '../../../common/libs/get-skill-details';
import {
  getDescriptionText,
  getDescriptionTitle,
} from '../../../common/libs/skill-ratings-map';
import Tooltip from '../../Tooltip';
import {
  Bar,
  BarContainer,
  Container,
  SkillBadge,
  SkillBadgeContainer,
  SkillInfo,
  SkillInfoContainer,
  SkillName,
  SkillScore,
  TooltipContainer,
  UnitContainer,
} from './styles';
import {  Menu } from '../../Menu';

const Gauge = (props) => {
  const {
    allowZeroRating,
    animateIn,
    badge,
    className,
    maxRating,
    name,
    numberOfUnits,
    onClickSkill,
    skill,
    rating,
    showScore,
    shouldAnimateIn,
    skillNameNumberOfLines,
    alwaysShowBar,
    rcLabel,
    topRightMenuContent,
  } = props;
  const color = getSkillColorPaletteFromSkill(skill);
  const scoreMultiplier = maxRating / numberOfUnits;
  const baseRating = allowZeroRating
    ? rating
    : Math.max(rating, scoreMultiplier);
  const calculatedScore = baseRating / scoreMultiplier;
  const calculatedScoreValue = Math.floor(calculatedScore);
  const showBar = alwaysShowBar || (!isNaN(rating) && rating !== null);
  const units = [];
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isPopupInitiallyClicked, setPopupInitiallyClicked] = useState(false);
  const [isBarPopupOpen, setBarPopupOpen] = useState(false);
  const [isBarPopupInitiallyClicked, setBarPopupInitiallyClicked] = useState(
    false
  );
  const [canAnimate, setCanAnimate] = useState(false);

  let isMounted = true;
  useEffect(() => {
    if (!canAnimate && isMounted) {
      setTimeout(() => {
        setCanAnimate(true);
      }, 200);
    }
    return () => {
      isMounted = false;
    };
  });
  const tooltipProps = {
    content: (
      <TooltipContainer>
        {rating ? (
          <>
            <span>
              {showScore ? calculatedScoreValue : calculatedScore}.{' '}
              {getDescriptionTitle(calculatedScoreValue)}{' '}
            </span>
            <span>{getDescriptionText(calculatedScoreValue, '', true)}</span>
          </>
        ) : (
          <>
            <span>
              <span>No feedback: </span>
            </span>
            <span>You have yet to receive feedback on this skill. Request feedback from a peer.</span>
          </>
        )}
      </TooltipContainer>
    ),
    dark: true,
  };

  for (let i = 0; i < numberOfUnits; i++) {
    const unit = <Unit key={`${i}-${rating}-cell`} />;
    const emptyState = i==0 && (isNaN(rating) || rating == null);
    if (emptyState || i + 1 === Math.ceil(calculatedScore)) {
      units.push(
        <Tooltip
          key={`${i}-${rating}-tooltip`}
          {...tooltipProps}
          isOpen={isBarPopupOpen}
        >
          {unit}
        </Tooltip>
      );
    } else {
      units.push(unit);
    }
  }
  
  let barWidth = shouldAnimateIn ? 0 : baseRating / maxRating;
  let displayedScoreValue = shouldAnimateIn ? 0 : calculatedScore;
  if (shouldAnimateIn && animateIn && canAnimate) {
    barWidth = baseRating / maxRating;
    displayedScoreValue = calculatedScore;
  }
  const barProps = useSpring({
    transform: `scaleX(${barWidth})`,
    from: { transform: 'scaleX(0)' },
    config: { friction: 25 },
  });
  const skillScoreProps = showScore
    ? useSpring({ number: displayedScoreValue, from: { number: 0 } })
    : { number: { interpolate: () => {} } };
  const displayedScore = showScore
    ? skillScoreProps.number.interpolate((number) => number ? number.toFixed(1) : '')
    : '-';

  const handleClickPopup = () => {
    if (!isPopupInitiallyClicked) {
      // Edge case where popup toggles on first open.
      setPopupOpen(true);
      setPopupInitiallyClicked(true);
    } else {
      setPopupOpen(!isPopupOpen);
    }
  };

  const handleClickBarPopup = () => {
    if (!isBarPopupInitiallyClicked) {
      // Edge case where popup toggles on first open.
      setBarPopupOpen(true);
      setBarPopupInitiallyClicked(true);
    } else {
      setBarPopupOpen(!isBarPopupOpen);
    }
  };

  const bar = (
    <BarContainer
      color={color}
      onMouseEnter={() => setBarPopupOpen(true)}
      onMouseLeave={() => setBarPopupOpen(false)}
      onClick={handleClickBarPopup}
      showScore={showScore}
    >
      <Bar color={color} style={barProps} />
      <UnitContainer>{units}</UnitContainer>
    </BarContainer>
  );

  const skillBadge = badge || <Badge skill={skill} />;
  const skillName = name || skill.name;
  return (
    <Container className={className} showScore={showScore} data-rc={rcLabel}>
      <SkillInfoContainer showScore={showScore}>
        <SkillInfo>
          <SkillBadgeContainer>
            <SkillBadge onClick={onClickSkill} data-rc="skill-badge">
              {skillBadge}
            </SkillBadge>
            {topRightMenuContent && (
              <Menu.Dark menuMap={topRightMenuContent}/>
            )}
          </SkillBadgeContainer>
          <SkillName onClick={onClickSkill} numberOfLines={skillNameNumberOfLines}>{skillName}</SkillName>
        </SkillInfo>
        {showScore && (
          <Tooltip {...tooltipProps} isOpen={isPopupOpen}>
            <SkillScore
              color={color}
              showScore={canAnimate && rating}
              onMouseEnter={() => setPopupOpen(true)}
              onMouseLeave={() => setPopupOpen(false)}
              onClick={handleClickPopup}
            >
              {displayedScore}
            </SkillScore>
          </Tooltip>
        )}
      </SkillInfoContainer>
      {showBar && bar}
    </Container>
  );
};

Gauge.propTypes = {
  /** Allow rating of 0. If false, rating minimum will display as 1. */
  allowZeroRating: PropTypes.bool,
  /** If `shouldAnimateIn` is passed, this controls when the animation is played. If initially passed in as true, will animate on mount.  */
  animateIn: PropTypes.bool,
  /** Override default badge to show. */
  badge: PropTypes.node,
  /** Additional style class */
  className: PropTypes.string,
  /** The max rating value of the gauge. */
  maxRating: PropTypes.number,
  /** Override name to display. Defualts to skill name. */
  name: PropTypes.node,
  /** Number of units to show. Automatically calculates the rating mulipleis units dont match the maxRating. */
  numberOfUnits: PropTypes.number,
  /** Callback when skill badge is clicked. */
  onClickSkill: PropTypes.func,
  /** The rating of the gauge. */
  rating: PropTypes.number,
  /** Sets initial rating to 0 and animates in to given rating when `animateIn` props changes to true.  */
  shouldAnimateIn: PropTypes.bool,
  /** Show the score value inline above the gauge. */
  showScore: PropTypes.bool,
  /** The skill of the rating. */
  skill: skillValueObjectPropType,
  /** Number of lines to show on skill name. */
  skillNameNumberOfLines: PropTypes.number,
  /** show the rating bar even when there's no rating*/
  alwaysShowBar: PropTypes.bool,
  /** menu to support add, remove, edit skill and request feedback */
  topRightMenuContent: PropTypes.arrayOf(PropTypes.shape({
    menuLabel: PropTypes.string,
    rcLabel: PropTypes.string,
    handleAction: PropTypes.func,
  })),
  rcLabel: PropTypes.string,
};

Gauge.defaultProps = {
  ability: {},
  allowZeroRating: true,
  maxRating: 10,
  numberOfUnits: 5,
  alwaysShowBar: false,
};

export default Gauge;
