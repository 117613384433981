import React from 'react';
import PropTypes from 'prop-types';
import { Confirm } from '@matterapp/matter-ui';

const DeleteConfirmModal = (props) => {
  const { isOpen, isWorkspace, onClose, onClickDelete } = props;
  let subHeader;
  if (isWorkspace) {
    subHeader = 'This will permanently remove this item for all members of your workspace.';
  } else {
    subHeader = 'This action will permanently remove this feedback from your feed.';
  }

  return (
    <Confirm.Simple
      header="Delete feedback?"
      isOpen={isOpen}
      onClickConfirm={onClickDelete}
      onClickCancel={onClose}
      onClose={onClose}
      subHeader={subHeader}
      confirmButtonIsDestructive
      confirmButtonLabel="Yes, Delete"
      cancelButtonLabel="Cancel"
    />
  );
};

DeleteConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClickDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isWorkspace: PropTypes.bool.isRequired,
  deleteProps: PropTypes.shape({
    isMultipleReceiver: PropTypes.bool,
  })
};

export default DeleteConfirmModal;
