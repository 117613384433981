import React from 'react';
import { Resources } from '@matterapp/routing';
import { Route } from 'react-router-dom';
import { compose } from 'recompose';
import { withQueryParams, withRouteParams, withStepPaths } from 'hocs';
import DeclineRecurringPage from '../pages/recurring-flow/decline-recurring';

const declineRecurringHoc = compose(
  withQueryParams,
  withRouteParams({
    invitationUuid: true,
    rfrequency: true,
  }),
  withStepPaths({
    homePath: () => {
      return Resources.homeMain.path();
    }
  })
);

const Component = declineRecurringHoc(DeclineRecurringPage);

export const DeclineRecurringFeedbackGateway = (
  <Route
    exact
    path={Resources.recurringDecline.pattern}
    element={(
      <Component />
    )}
  />
);
