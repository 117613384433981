import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { PICK_RECOMMENDED_SKILL_MUTATION, SKILLS_QUERY, SKILL_DETAILS_QUERY } from 'graphql-queries/queries';
import Toast from 'components/Toast/Toast';
import RecommendedSkillsMode from '../components/RecommendedSkillsMode';
import withCurrentUser from 'context/UserContext/withCurrentUser';

const withData = compose(
  withCurrentUser,
  graphql(SKILLS_QUERY, {
    name: 'skillsData',
  }),
  graphql(PICK_RECOMMENDED_SKILL_MUTATION, {
    name: 'pickRecommendedSkillMutation',
    options: (props) => {
      return {
        refetchQueries: () => [
          {
            query: SKILLS_QUERY,
          },
          { 
            query: SKILL_DETAILS_QUERY, 
            variables: { 
              workspaceIds: props.currentTenantWorkspaceIds
            }
          }
        ],
      };
    },
  }),

  withProps(
    ({
      pickRecommendedSkillMutation,
      handleDismissGeneratorModal,
    }) => ({
      getHandlePickRecommendedSkill: (skill) => async () => {
        handleDismissGeneratorModal();
        try {
          await pickRecommendedSkillMutation({
            variables: {
              skillId: skill.id,
            },
          });

          Toast.success(`You added "${skill.name}" to your skills`);
        } catch (e) {
          console.error({ e });
        }
      },
    })
  )
);

const RecommendedSkillsModeContainer = withData(RecommendedSkillsMode);

RecommendedSkillsModeContainer.propTypes = {
  handleDismissGeneratorModal: PropTypes.func.isRequired,
};

export default RecommendedSkillsModeContainer;
