import { useState, useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FEEDBACKS_FEED_QUERY } from 'graphql-queries/queries';
import { Filter } from '@matterapp/matter-ui';

/*****************************************************************************/
/* Helper functions                                                          */
/*****************************************************************************/
export const NOOP = () => null;

export const isEmptyQuery = (variables = {}) => {
  const { userIds = [], type, skillIds = [], offset } = variables;
  return !userIds?.length && !type && !skillIds?.length && !offset;
};

export const formatQueryVariables = (variables = {}) => {
  const { userIds = [], type, skillIds = [], offset, workspaceIds } = variables;
  const formattedVariables = { offset, workspaceIds };
  if (userIds?.length) {
    formattedVariables.userIds = userIds;
  }
  if (typeof type !== 'object') {
    formattedVariables.type = type;
  }
  if (skillIds?.length) {
    formattedVariables.skillIds = skillIds;
  }
  return formattedVariables;
};

export const generateFilterValueObj = (from = [], type = [], skill = []) => ({
  from,
  type,
  skill,
});

export const hasFilterValueChanged = (currentValue = {}, prevValue = {}) => {
  const fromValueChanged = currentValue.from !== prevValue.from;
  const typeValueChanged = currentValue.type[0] !== prevValue.type[0];
  const skillValueChanged = currentValue.skill !== prevValue.skill;
  return fromValueChanged || typeValueChanged || skillValueChanged;
};

/*****************************************************************************/
/* Hook for FEEDBACKS_FEED_QUERY                                             */
/*****************************************************************************/
export const useFeedbackQuery = (workspaceIds) => {
  const [query, { data, loading }] = useLazyQuery(FEEDBACKS_FEED_QUERY);
  const { currentUser, feedbacksFeed } = data || {};
  const { count, feedItems } = feedbacksFeed || { feedItems: [] };
  const [results, setResults] = useState([]);

  const feedExists = !!count;

  /**
   * When loading more items, it checks if the feed exists before updating offset.
   */
  const onLoadMore = () => {
    return;
  };

  /**
   * Update the item in the list when a comment is added.
   * @param { Object } item: The item to update.
   * @param { Number } index: The index of the comment.
   */
  const updateResultsOnComment = (item, index) => {
    const listItem = results[index];
    listItem.comments = item.comments;
    results[index] = listItem;
    setResults([...results]);
  };

  const updateResultsOnDelete = (index) => {
    const list = [...(results.slice(0, index)), ...(results.slice(index+1))];
    setResults(list);
  };

  useEffect(() => {
    if (!loading && !!feedItems) {
      const updatedResults = [...feedItems];
      setResults(updatedResults);
    }
  }, [data]);

  useEffect(() => {
    if (workspaceIds) {
      query({
        variables: {
          workspaceIds
        }
      });
    }
  }, [workspaceIds]);

  return {
    getFeedbacks: (variables) => {
      const isEmptyVariables = isEmptyQuery(variables);

      return query({
        variables: isEmptyVariables
          ? {workspaceIds, offset: 0}
          : formatQueryVariables({ ...variables, offset: 0 }),
      });
    },
    data,
    currentUser,
    homeFeed: results,
    feedItems: results,
    feedExists,
    loading,
    onLoadMore,
    updateResultsOnComment,
    updateResultsOnDelete,
  };
};

/*****************************************************************************/
/* Hook for Filter and MainColumn                                            */
/*****************************************************************************/
export const useFilter = (props = {}) => {
  const { workspaceIds } = props;
  const {
    getFeedbacks,
    feedItems,
    feedExists,
    loading,
    onLoadMore,
    updateResultsOnComment,
    updateResultsOnDelete,
  } = useFeedbackQuery(workspaceIds);
  const hasQueryRan = useRef(false);
  const [lastFilterValue, setLastFilterValue] = useState(
    generateFilterValueObj()
  );

  const [skillIds, setSkillIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const isAllType = selectedType === Filter.Feedback.feedbackTypes.ALL;
  const currentValue = generateFilterValueObj(
    userIds,
    selectedType ? [selectedType] : [], // Since the type dropdown is still a multi-select, pass in a s array.
    skillIds
  );

  /**
   * Runs the query to get more feed items.
   * @param { Object } overrideVariables: Additioanl query variables to override.
   * @param { Boolean } forceRun: Forces the query to run.
   */
  const runQuery = async (overrideVariables = {}, forceRun) => {
    if (!hasQueryRan.current) {
      // Prevent multiple query calls until timeout.
      hasQueryRan.current = true;

      if (forceRun || hasFilterValueChanged(currentValue, lastFilterValue)) {
        setLastFilterValue(currentValue);
        await getFeedbacks({
          workspaceIds,
          skillIds,
          userIds,
          type: isAllType ? '' : selectedType,
          ...overrideVariables,
        });
        hasQueryRan.current = false;
      } else {
        setTimeout(() => {
          hasQueryRan.current = false;
        }, 100);
      }
    }
  };

  return {
    feedProps: {
      feedExists,
      feedItems,
      loading,
      onLoadMore,
      updateResultsOnComment,
      updateResultsOnDelete
    },
    onChange: (e, props) => {
      const { changeType, value } = props;
      const { from, type, skill } = value;
      const isKudosType = type === Filter.Feedback.feedbackTypes.KUDOS;
      const currentSelectedType = type[0];

      setSkillIds(isKudosType ? [] : skill);
      setUserIds(from);
      setSelectedType(currentSelectedType);

      // Always run query when type filter is changed
      if (
        changeType === Filter.Feedback.filterTypes.TYPE &&
        selectedType !== currentSelectedType
      ) {
        runQuery({ type: currentSelectedType }, true);
      }
    },
    onClickApplyFilter: () => {
      // Run query for from and skill filters when apply is clicked.
      runQuery();
    },
    onClickClearFilters: () => {
      // Run query when filters are cleared.
      runQuery(
        { userIds: [], type: Filter.Feedback.feedbackTypes.ALL, skillIds: [] },
        true
      );
    },
    onCloseMenu: () => {
      // Run query for from and skill filters when menu is closed.
      runQuery();
    },
    value: currentValue
  };
};
