import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs, ChannelsSelect } from './MobileSubMenu';
import UserContext from 'context/UserContext/UserContext';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import { IconButton, Badge } from '@mui/material';
import { useQuery } from '@apollo/client';
import getPendingRewardRequests from 'graphql-queries/queries/rewards/getPendingRewardRequests';

export default function AdminMobileMenu() {
  const { currentTenant, currentWorkspace } = useContext(UserContext);
  const { slackWorkspace } = currentWorkspace;
  const { pulseSettings } = currentTenant || {};
  const { isPulseEnabled } = pulseSettings || {};
  const { isMobileMenuOpen, setIsMobileMenuOpen, setTopNavItem, setIsAdminMenuOpen } = useContext(MobileMenuContext);
  const [isAnalyticsTab, setIsAnalyticsTab] = useState(false);
  const rewardsManagerPath = Resources.rewardsManager.path({ workspaceId: currentWorkspace?.id });
  const pulseDashboardPath = Resources.pulseDashboard.path({ workspaceId: currentWorkspace?.id });

  const { data: pendingRewardRequestsData } = useQuery(getPendingRewardRequests, {
    variables: {
      tenantId: currentTenant?.id
    },
    skip: !currentTenant?.id,
  });

  const navigate = useNavigate();
  const numberOfRewardsNotifications = pendingRewardRequestsData?.getPendingRewardRequests?.length;

  useEffect(() => {
    if (isMobileMenuOpen) {
      setTopNavItem(<ChannelsSelect text='Admin' iconVariant='feedback' />);
    } else {
      setIsAdminMenuOpen(false);
    }
  }, [isMobileMenuOpen]);

  if (!currentTenant) {
    return null;
  }
  
  return !isAnalyticsTab ? 
    (
      <Tabs>
        <Tab 
          onClick={() => {
            setIsAnalyticsTab(true);
            setTopNavItem(<ChannelsSelect text='Analytics' iconVariant='feedback' />);
          }}>
          Analytics
        </Tab>
        {isPulseEnabled && slackWorkspace && (
          <Tab 
            onClick={() => {
              setIsMobileMenuOpen(false);
              navigate(pulseDashboardPath);
            }}>
              Pulse Survey
          </Tab>)}
        <Tab 
          onClick={() => {
            setIsMobileMenuOpen(false);
            navigate(rewardsManagerPath);
          }}>
            Rewards Manager
          <IconButton
            size='large'
            color='inherit'
            sx={{ ml: '5px' }}
          >
            <Badge badgeContent={numberOfRewardsNotifications} color='error' />
          </IconButton>
        </Tab>
        <Tab 
          onClick={() => {
            setIsMobileMenuOpen(false);
            navigate(Resources.workspaceSettingsMain.path({ workspaceId: currentWorkspace?.id }));
          }}>
          Settings
        </Tab>
      </Tabs>
    ) : (
      <Tabs>
        {currentTenant?.workspaces.map(({ name, id }) => (
          <Tab key={id} onClick={() => {
            setIsMobileMenuOpen(false);
            setIsAnalyticsTab(false);
            navigate(Resources.analyticsMain.path({ workspaceId: id }));
            setTopNavItem(
              <ChannelsSelect text={name} iconVariant='back' iconAction={() => {
                setIsAdminMenuOpen(true);
              }} />
            );
          }}>{name}</Tab>
        ))}
      </Tabs>
    );
}
