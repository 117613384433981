import { compose, withProps } from 'recompose';
import withRouter from 'hocs/withRouter';
import withCurrentUser from 'context/UserContext/withCurrentUser';
import withAuthReducer from './withAuthReducer';

const LOGIN_CALLBACK_HANDLER_ROOT_KEY = '@LOGIN_CALLBACK';

const getInitialHandlerState = () => ({});

export const withLoginCallback = compose(
  withRouter,
  withAuthReducer,
  withCurrentUser,
  withProps(({ location }) => ({
    setupLoginCallbackHandler: ({
      successRedirect = location.pathname,
      isReloadCurrentUser,
    }) => {
      const newHandler = getInitialHandlerState();
      newHandler.successRedirect = successRedirect;
      newHandler.isReloadCurrentUser = isReloadCurrentUser;
      localStorage.setItem(
        LOGIN_CALLBACK_HANDLER_ROOT_KEY,
        JSON.stringify(newHandler)
      );
    },
  })),
  withProps(() => {
    const currentLoginHandlerUnparsed = localStorage.getItem(
      LOGIN_CALLBACK_HANDLER_ROOT_KEY
    );
    const currentLoginHandler = JSON.parse(currentLoginHandlerUnparsed);
    return { currentLoginHandler };
  }),
  withProps(
    ({ history, currentLoginHandler, currentUserStatusData, endAuth }) => ({
      handleLoginCallback: async () => {
        const { successRedirect, isReloadCurrentUser } = currentLoginHandler;
        if (isReloadCurrentUser) {
          await currentUserStatusData.refetch();
        }
        endAuth();
        history.push(successRedirect);
      },
    })
  )
);
