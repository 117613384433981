import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Emoji from '../Emoji';

class ListItem extends React.Component {
  static propTypes = {
    emoji: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  static Container = styled.li`
    display: flex;

    font-size: 17px;
    line-height: 24px;

    margin-bottom: 24px;
  `;

  static EmojiContainer = styled.div`
    width: 24px;
    min-width: 24px;
    margin-right: 8px;
    padding-top: 3px;
  `;

  static ContentContainer = styled.div`
    flex-grow: 1;
  `;

  render() {
    const { emoji, children, className } = this.props;

    return (
      <ListItem.Container className={className}>
        <ListItem.EmojiContainer>
          <Emoji.Image theString={emoji} size={17} />
        </ListItem.EmojiContainer>
        <ListItem.ContentContainer>{children}</ListItem.ContentContainer>
      </ListItem.Container>
    );
  }
}

export default ListItem;
