import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Skill from '../../../Skill';
import { ButtonNew as Button } from '../../../Button';
import Loader from '../../../Loader';
import { SkillPropType } from '../../consts';

const LoaderContainer = styled.div`
  position: relative;
  height: 100px;
`;

export default class SkillsList extends React.PureComponent {
  static propTypes = {
    /** List of available skills to choose from. */
    userSkills: PropTypes.arrayOf(SkillPropType).isRequired,
    /** Skill selected. */
    selectedSkill: SkillPropType,
    /** If the skills list is still loading */
    isLoading: PropTypes.bool,
    /** Function to handle selecting skills. */
    handleChangeSkill: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userSkills: [],
    selectedSkill: null,
    isLoading: false,
    handleChangeSkill: () => {},
  };

  handleClickSkill = (e, { skill }) => {
    this.props.handleChangeSkill(e, { value: skill });
  };

  renderSkillsList = () => {
    const { userSkills, selectedSkill, isLoading } = this.props;
    const skills = userSkills.map((userSkill) => ({
      skill: userSkill.skill,
      isSelected: false,
      isTagged: selectedSkill && userSkill.skill.id === selectedSkill.id,
    }));

    if (isLoading) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    }
    return (
      <Skill.AddListUnit
        skills={skills}
        onClickItem={this.handleClickSkill}
        renderSkillActions={this.renderSkillActions}
      />
    );
  };

  renderSkillActions = () => (
    <Button size="xsmall" basic>
      Select
    </Button>
  );

  render() {
    return <>{this.renderSkillsList()}</>;
  }
}
