import { useState } from 'react';
import Dropdown from '../../Dropdown';
import { isValidEmail } from '../../../libs/emailValidator';

/*****************************************************************************/
/* HOOK FOR MESSAGE                                                          */
/*****************************************************************************/
export const useMessage = (params) => {
  const { defaultMessage, messageMinLength, messageMaxLength, placeholder } = params;
  const [message, setMessage] = useState(defaultMessage);
  const messageTooShort = message.length < messageMinLength;
  const messageTooLong = message.length > messageMaxLength;
  const isMessageValid = !(messageTooShort || messageTooLong);

  return {
    error: isMessageValid ? null : ' ',
    hideMaxCharacterCount: !messageTooLong,
    isMessageValid,
    message,
    messageMinLength,
    messageMaxLength,
    minLength: messageMinLength,
    maxLength: messageMaxLength,
    onChange: (e, props) => setMessage(props.value),
    placeholder,
    resetMessage: () => setMessage(defaultMessage),
    value: message,
  };
};

/*****************************************************************************/
/* HOOK FOR SELECTING A SKILL                                                */
/*****************************************************************************/
export const useSelectSkill = (params) => {
  const { skills } = params;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState({});
  const [savedSkill, setSavedSkill] = useState({});

  const formattedSkills = skills.map((skill) => ({
    skill,
    isSelected: skill.id === selectedSkill.id,
    isTagged: false,
  }));

  return {
    hasSelectedSkill: !!selectedSkill.id,
    isOpen,
    onClose: () => setIsOpen(false),
    openTagSkillModal: () => {
      if (selectedSkill.id && !savedSkill.id) {
        setSelectedSkill({});
      }
      setIsOpen(true);
    },
    onClickDone: () => {
      setIsOpen(false);
      setSavedSkill(selectedSkill);
    },
    onSelectSkill: (e, props) => setSelectedSkill(props.skill),
    selectedSkill: savedSkill,
    skills: formattedSkills,
    removeSkill: () => {
      setSelectedSkill({});
      setSavedSkill({});
    },
  };
};

/*****************************************************************************/
/* HOOK FOR SELECTING USER                                                   */
/*****************************************************************************/
export const useSelectUserDropdown = (params) => {
  const { 
    onSelectUser, 
    dropdownPlaceholder, 
    emptyMenuMessageNoItems, 
    users, 
    onSearch,
    isLoadingResults
  } = params;
  const [value, setValue] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  return {
    emptyMenuMessageNoItems,
    formatTagLabel: (value) => {
      const userObject = users.find((user) => user.email === value) || {};
      return userObject.fullName;
    },
    isValidValue: (email) => {
      return (
        isValidEmail(email) && !!users.find((user) => user.email === email)
      );
    },
    onChange: (e, props) => {
      setValue(props.value);
      if (!props.value.length) {
        setSelectedUser({});
      }
    },
    onSelect: (e, props) => {
      const user = props.item || {};
      onSelectUser(e, { ...props, user });
      setSelectedUser(user);
    },
    hasSelectedUser: !!selectedUser.userId,
    items: Dropdown.Email.Tags.formatItems(users),
    removeSelectedUser: () => {
      setValue([]);
      setSelectedUser({});
    },
    onSearch,
    placeholder: dropdownPlaceholder || 'Select a Matter user...',
    selectedUser,
    value,
    isLoadingResults
  };
};

/*****************************************************************************/
/* HOOK FOR GIVE FEEDBACK STATE                                              */
/*****************************************************************************/
export const useGiveFeedback = (params) => {
  const { disabledMessage, isSending, onSend } = params;
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const {
    hasSelectedUser,
    removeSelectedUser,
    selectedUser,
    ...selectUserDropdownProps
  } = useSelectUserDropdown(params);
  const { isMessageValid, message, resetMessage, ...messageProps } = useMessage(
    params
  );
  const {
    hasSelectedSkill,
    openTagSkillModal,
    selectedSkill,
    removeSkill,
    ...tagSkillModalProps
  } = useSelectSkill(params);
  const resetState = () => {
    resetMessage();
    removeSelectedUser();
    removeSkill();
  };
  const canSend = hasSelectedUser && message && isMessageValid;
  const onClickSend = (e) => {
    onSend(e, {
      ...params,
      canSend,
      message,
      note: message,
      receiverUserId: selectedUser.userId,
      resetState,
      selectedUser,
      selectedSkill,
      skillId: selectedSkill.id,
    });
  };

  return {
    canSend,
    confirmResetModalProps: {
      isOpen: isConfirmOpen,
      onConfirm: () => {
        setConfirmOpen(false);
        resetState();
      },
      onCancel: () => {
        setConfirmOpen(false);
      },
    },
    disabledMessage,
    hasChanges: hasSelectedUser || hasSelectedSkill,
    hasSelectedUser,
    message,
    messageProps,
    onClickCancel: () => {
      setConfirmOpen(true);
    },
    onClickSend,
    selectedSkill,
    selectedUser,
    selectUserDropdownProps,
    sendButtonProps: {
      disabled: !canSend,
      isLoading: isSending,
      onClick: onClickSend,
    },
    skillTaggerProps: {
      isEmpty: !selectedSkill.id,
      onClick: openTagSkillModal,
      onReset: removeSkill,
      skill: selectedSkill,
    },
    tagSkillModalProps,
  };
};
