import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import useFindContacts from '../useFindContacts';
import { DEFAULT_REQUEST_MESSAGE } from 'copy-consts';
import {
  getRequestFeedbackDisabledMessage,
  getDropdownInviteMessage,
  useUserSkills,
  MAX_MESSAGE_LENGTH,
  MIN_MESSAGE_LENGTH,
  WORKSPACE_DROPDOWN_PLACEHOLDER,
} from './shared';
import {
  FIND_OR_CREATE_URL_INVITATION_MUTATION,
  REQUEST_FEEDBACK_MUTATION,
  FIND_OR_CREATE_FEEDBACK_TEMPLATE_MUTATION,
} from 'graphql-queries/queries';
import { mixpanel } from 'libs/tracking';
import Toast from 'components/Toast';
import { useLazyQuery } from '@apollo/client';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';

const fetchPolicy = 'no-cache';
const MIXPANEL_EVENT = 'composer-unactivated';

const handleTrack = {
  infoModalClick: mixpanel.createEventSender(
    `${MIXPANEL_EVENT}:info-modal:viewed`
  ),
  customizeClicked: mixpanel.createEventSender(
    `${MIXPANEL_EVENT}:custom-settings:clicked`
  ),
  shareViaLink: mixpanel.createEventSender(
    `${MIXPANEL_EVENT}:share-via-link:clicked`
  ),
  skillPillClick: mixpanel.createEventSender(
    `${MIXPANEL_EVENT}:skill-pill:clicked`
  ),
  clearText: mixpanel.createEventSender(`${MIXPANEL_EVENT}:clear-text:clicked`),
  resetClicked: mixpanel.createEventSender(
    `${MIXPANEL_EVENT}:reset-custom-settings:clicked`
  ),
};

/*****************************************************************************/
/* FEEDBACK TEMPLATE MUTATION                                                */
/*****************************************************************************/
export const useFeedbackTemplateMutation = () => {
  const [
    mutation,
    loading,
  ] = useMutation(FIND_OR_CREATE_FEEDBACK_TEMPLATE_MUTATION, { fetchPolicy });
  const findOrCreateFeedbackTemplate = (skillIds) => {
    return mutation({ variables: { skillIds } });
  };
  return [findOrCreateFeedbackTemplate, loading];
};

/*****************************************************************************/
/* URL INVITATION MUTATION                                                   */
/*****************************************************************************/
export const useUrlInvitationMutation = () => {
  const [
    mutation,
    loading,
  ] = useMutation(FIND_OR_CREATE_URL_INVITATION_MUTATION, { fetchPolicy });
  const findOrCreateUrlInvitation = (props) => {
    const { customNote, feedbackTemplateId } = props;
    return mutation({
      variables: {
        customNote,
        feedbackTemplateId,
        source: 'unactivatedUserComposer',
      },
    });
  };
  return [findOrCreateUrlInvitation, loading];
};

/*****************************************************************************/
/* REQUEST FEEDBACK MUTATION                                                 */
/*****************************************************************************/
export const useRequestFeedbackMutation = () => {
  const [mutation, loading] = useMutation(REQUEST_FEEDBACK_MUTATION, {
    fetchPolicy,
    onCompleted: () => {
      const message = (
        <span>
          <strong>That&apos;s the ticket!</strong> Your request is on its way
        </span>
      );
      Toast.success(message);
    },
  });
  const requestFeedback = (inviteData) => {
    return mutation({
      variables: {
        ...inviteData,
        source: 'unactivatedUserComposer',
      },
    });
  };
  return [requestFeedback, loading];
};

/*****************************************************************************/
/* REQUEST FEEDBACK HOOK                                                     */
/*****************************************************************************/
export const useRequestFeedbackBase = (params) => {
  const { workspace } = params;
  const [isInviteSent, setIsInviteSent] = useState(false);
  const [searchMembers, { loading: isLoadingResults, data }] = useLazyQuery(searchWorkspaceMembers);
  const { userSkills } = useUserSkills();
  const [findOrCreateFeedbackTemplate] = useFeedbackTemplateMutation();
  const [requestFeedback] = useRequestFeedbackMutation();

  const prepareInviteData = async (props) => {
    const { isAllSkillsSelected, isDefaultMessage, message, skillIds } = props;
    let feedbackTemplateId = null;

    if (!isAllSkillsSelected && skillIds.length) {
      const { data } = await findOrCreateFeedbackTemplate(skillIds);
      feedbackTemplateId = data.findOrCreateFeedbackTemplate.id;
    }
    return {
      customNote: isDefaultMessage ? null : message,
      feedbackTemplateId,
    };
  };

  const onSend = async (e, props) => {
    const { canSend, email, pickedPeers, workspace } = props;
    if (canSend && !isInviteSent && pickedPeers && pickedPeers.length && email) {
      setIsInviteSent(true);
      const inviteData = await prepareInviteData(props);
      requestFeedback({ ...inviteData, email, pickedPeers, workspaceId: workspace? workspace.id : null });
      setIsInviteSent(false);
    }
  };

  async function onSearch(value) {
    if (value) {
      searchMembers({ 
        variables: {
          workspaceId: workspace.id,
          searchString: value,
          excludeCurrentUser: true
        }
      });
    }
  }

  return {
    ...params,
    requestFeedbackProps: {
      ...params,
      defaultMessage: DEFAULT_REQUEST_MESSAGE,
      messageMinLength: MIN_MESSAGE_LENGTH,
      messageMaxLength: MAX_MESSAGE_LENGTH,
      onClickCustomize: handleTrack.customizeClicked,
      onSend,
      selectedSkillIds: userSkills.map((skill) => skill.id),
      userSkills,
      onSearchEmail: onSearch,
      isLoadingResults,
      items: data?.searchMembers.map(member => ({
        ...member,
        fullName: member.person?.fullName,
        photoUrl: member.person?.photoUrl,
        userId: member.person?.userId,
        value: member.email
      }))
    },
  };
};

/*****************************************************************************/
/* REQUEST FEEDBACK HOOK                                                     */
/*****************************************************************************/
export const useRequestFeedback = (params) => {
  const [onSearchEmail] = useFindContacts();
  return useRequestFeedbackBase({ ...params, onSearchEmail });
};

/*****************************************************************************/
/* REQUEST FEEDBACK WORKSPACES HOOK                                          */
/*****************************************************************************/
export const useRequestFeedbackWorkspaces = (params) => {
  const { hasMultipleUsers, invitePath, workspace } = params;
  const { members, slackWorkspace } = workspace || {};
  const primaryChannel = slackWorkspace?.primaryChannel;
  const items = members
    .map((member) => ({ ...member, ...member.person }))
    .filter((member) => !member.isSelf);
  const disabledMessage = hasMultipleUsers
    ? null
    : getRequestFeedbackDisabledMessage(invitePath, primaryChannel);
  const emptyMenuMessageNoItems = getDropdownInviteMessage(primaryChannel);

  return useRequestFeedbackBase({
    ...params,
    disabledMessage,
    dropdownPlaceholder: WORKSPACE_DROPDOWN_PLACEHOLDER,
    emptyMenuMessageNoItems,
    filterItems: true,
    items,
    showShareLink: false,
  });
};

export default useRequestFeedback;
