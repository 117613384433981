import PropTypes from 'prop-types';
import Animation from '../Animation';

export const ANIMATIONS = Animation.ANIMATIONS;

export default class BackgroundAnimation extends Animation {
  static propTypes = {
    animation: PropTypes.oneOf(Object.values(ANIMATIONS)),
    /** Plays animation when component mounts. */
    playAnimationOnMount: PropTypes.bool,
  };

  static defaultProps = {
    animation: ANIMATIONS.SHOW,
    playAnimationOnMount: false,
  }

  static ANIMATIONS = ANIMATIONS;
}
