import React from 'react';
import { Colors, Thumbnail, Paths } from '../Thumbnail';

const TeamPlayer = (props) => {
  return (
    <Thumbnail color={Colors.Blue} width={288} height={108} top={6} left={0} {...props}>
      <Paths.L20 d="M182.31 18.74C185.54 18.74 188.15 16.14 188.15 12.93C188.15 9.72 185.54 7.12 182.31 7.12C179.09 7.12 176.47 9.72 176.47 12.93C176.48 16.14 179.09 18.74 182.31 18.74Z" />
      <Paths.L20 d="M83.2699 42.34C85.8099 42.34 87.8699 40.29 87.8699 37.76C87.8699 35.23 85.8099 33.18 83.2699 33.18C80.7299 33.18 78.6699 35.23 78.6699 37.76C78.6699 40.29 80.7299 42.34 83.2699 42.34Z" />
      <Paths.L20 d="M5.57022 67.34C8.11022 67.34 10.1702 65.29 10.1702 62.76C10.1702 60.23 8.11022 58.18 5.57022 58.18C3.03022 58.18 0.970215 60.23 0.970215 62.76C0.980215 65.29 3.03022 67.34 5.57022 67.34Z" />
      <Paths.L20 d="M112.61 25.5C111.34 25.5 110.31 26.52 110.31 27.79C110.31 29.06 111.34 30.08 112.61 30.08C113.88 30.08 114.91 29.06 114.91 27.79C114.91 26.53 113.88 25.5 112.61 25.5Z" />
      <Paths.L20 d="M176.48 36.8C175.21 36.8 174.18 37.82 174.18 39.09C174.18 40.35 175.21 41.38 176.48 41.38C177.75 41.38 178.78 40.36 178.78 39.09C178.77 37.82 177.75 36.8 176.48 36.8Z" />
      <Paths.L20 d="M1.42892e-05 11.62C1.42892e-05 5.21 5.24001 0 11.67 0C18.1 0 23.34 5.21 23.34 11.62C23.34 18.03 18.1 23.23 11.67 23.23C5.23001 23.23 -0.00998571 18.02 1.42892e-05 11.62ZM5.40001 11.62C5.40001 15.08 8.22001 17.87 11.67 17.87C15.14 17.87 17.94 15.06 17.94 11.62C17.94 8.16 15.12 5.37 11.67 5.37C8.21001 5.38 5.39001 8.18 5.40001 11.62Z" />
      <Paths.L20 d="M244.88 31.9H270.09C271.39 31.9 272.44 32.95 272.44 34.25C272.44 35.55 271.39 36.6 270.09 36.6H244.88C243.58 36.6 242.53 35.55 242.53 34.25C242.53 32.95 243.58 31.9 244.88 31.9Z" />
      <Paths.L20 d="M244.88 41.51H261.55C262.85 41.51 263.9 42.56 263.9 43.86C263.9 45.16 262.85 46.21 261.55 46.21H244.88C243.58 46.21 242.53 45.16 242.53 43.86C242.53 42.57 243.58 41.51 244.88 41.51Z" />
      <Paths.L20 d="M244.88 51.13H253C254.3 51.13 255.35 52.18 255.35 53.48C255.35 54.78 254.3 55.83 253 55.83H244.88C243.58 55.83 242.53 54.78 242.53 53.48C242.53 52.18 243.58 51.13 244.88 51.13Z" />
      <Paths.L20 d="M36.8102 31.9H11.5902C10.2902 31.9 9.24023 32.95 9.24023 34.25C9.24023 35.55 10.2902 36.6 11.5902 36.6H36.8002C38.1002 36.6 39.1502 35.55 39.1502 34.25C39.1602 32.95 38.1102 31.9 36.8102 31.9Z" />
      <Paths.L20 d="M36.81 41.51H20.14C18.84 41.51 17.79 42.56 17.79 43.86C17.79 45.16 18.84 46.21 20.14 46.21H36.81C38.11 46.21 39.16 45.16 39.16 43.86C39.16 42.57 38.11 41.51 36.81 41.51Z" />
      <Paths.L20 d="M36.8098 51.13H28.6898C27.3898 51.13 26.3398 52.18 26.3398 53.48C26.3398 54.78 27.3898 55.83 28.6898 55.83H36.8098C38.1098 55.83 39.1598 54.78 39.1598 53.48C39.1598 52.18 38.1098 51.13 36.8098 51.13Z" />
      <Paths.L20 d="M262.08 13.71C264.89 13.71 267.16 11.45 267.16 8.65999C267.16 5.86999 264.89 3.60999 262.08 3.60999C259.28 3.60999 257 5.86999 257 8.65999C257 11.45 259.28 13.71 262.08 13.71Z" />
      <Paths.L20 d="M203.4 67.97C198.8 67.97 195.05 64.24 195.05 59.66C195.05 55.08 198.8 51.35 203.4 51.35C208 51.35 211.75 55.08 211.75 59.66C211.75 64.24 208.02 67.97 203.4 67.97ZM203.4 55.19C200.93 55.19 198.91 57.19 198.91 59.66C198.91 62.12 200.92 64.13 203.4 64.13C205.87 64.13 207.89 62.13 207.89 59.66C207.9 57.19 205.88 55.19 203.4 55.19Z" />
      <Paths.L20 d="M278.88 71.41C274.28 71.41 270.53 67.68 270.53 63.1C270.53 58.52 274.28 54.79 278.88 54.79C283.48 54.79 287.23 58.52 287.23 63.1C287.23 67.68 283.5 71.41 278.88 71.41ZM278.88 58.63C276.41 58.63 274.39 60.63 274.39 63.1C274.39 65.56 276.4 67.57 278.88 67.57C281.35 67.57 283.37 65.57 283.37 63.1C283.38 60.63 281.36 58.63 278.88 58.63Z" />
      <Paths.L20 d="M107.55 63.81C109.85 63.81 111.71 61.96 111.71 59.67C111.71 57.38 109.85 55.53 107.55 55.53C105.25 55.53 103.39 57.38 103.39 59.67C103.39 61.95 105.25 63.81 107.55 63.81Z" />
      <Paths.L20 d="M165.08 51.47C162.66 51.47 160.7 53.42 160.7 55.83C160.7 58.24 162.66 60.19 165.08 60.19C167.5 60.19 169.46 58.24 169.46 55.83C169.46 53.43 167.5 51.47 165.08 51.47Z" />
      <Paths.L20 d="M78.6299 61.89C78.8399 60.64 78.0099 59.45 76.7599 59.24C75.5099 59.03 74.3299 59.87 74.1199 61.12C73.9099 62.37 74.7399 63.56 75.9899 63.77C77.2299 63.98 78.4199 63.14 78.6299 61.89Z" />
      <Paths.L20 d="M104.8 12.03C105.5 7.91999 102.76 4.01999 98.67 3.31999C94.58 2.61999 90.7 5.37999 89.99 9.48999C89.29 13.59 92.03 17.5 96.12 18.2C100.22 18.89 104.1 16.13 104.8 12.03Z" />
      <Paths.L30 d="M238.23 66.5004C238.66 67.6604 237.67 69.0904 236.02 69.6804C234.37 70.2704 232.7 69.8104 232.27 68.6504L218.64 31.3404L224.59 29.1904L238.23 66.5004Z" />
      <Paths.L20 d="M232.99 66.0604C233.36 67.0704 232.95 66.9604 232.56 67.1004C232.16 67.2404 231.92 67.5804 231.56 66.5804L219.5 33.5704L220.94 33.0504L232.99 66.0604Z" />
      <Paths.L40 d="M217.45 33.1704C225.09 33.1704 231.29 27.0104 231.29 19.4104C231.29 11.8104 225.09 5.65039 217.45 5.65039C209.81 5.65039 203.61 11.8104 203.61 19.4104C203.61 27.0104 209.8 33.1704 217.45 33.1704Z" />
      <Paths.L60 d="M208.56 20.7904C208.81 20.7304 209.01 20.5204 209.04 20.2504C209.14 19.3004 209.83 18.5304 210.76 18.3204C211.69 18.1104 212.64 18.5304 213.13 19.3504C213.31 19.6504 213.69 19.7404 213.99 19.5604C214.28 19.3804 214.38 19.0004 214.2 18.7004C213.43 17.4204 211.94 16.7704 210.49 17.0904C209.04 17.4104 207.96 18.6204 207.8 20.1004C207.76 20.4404 208.01 20.7504 208.35 20.7904C208.43 20.8004 208.5 20.8004 208.56 20.7904Z" />
      <Paths.L60 d="M220.83 18.0904C221.08 18.0304 221.29 17.8204 221.31 17.5504C221.41 16.6004 222.1 15.8304 223.03 15.6204C223.96 15.4104 224.91 15.8304 225.4 16.6504C225.58 16.9504 225.96 17.0404 226.26 16.8604C226.55 16.6804 226.65 16.3004 226.47 16.0004C225.7 14.7204 224.21 14.0704 222.76 14.3904C221.31 14.7104 220.23 15.9204 220.07 17.4004C220.03 17.7404 220.28 18.0504 220.62 18.0904C220.7 18.1104 220.77 18.1104 220.83 18.0904Z" />
      <Paths.L60 d="M218.89 25.9904C221.33 25.4504 223.16 23.4104 223.43 20.9104C223.47 20.5704 223.22 20.2604 222.88 20.2204C222.54 20.1804 222.23 20.4304 222.2 20.7704C221.99 22.7404 220.56 24.3404 218.63 24.7704C216.71 25.1904 214.73 24.3304 213.71 22.6404C213.53 22.3404 213.15 22.2504 212.85 22.4304C212.56 22.6104 212.46 22.9904 212.64 23.2904C213.93 25.4304 216.45 26.5204 218.89 25.9904Z" />
      <Paths.L50 d="M230.46 14.7104C229.56 12.2404 228.02 10.1904 226.09 8.6604C227.3 11.4304 229.94 19.8104 223.55 26.5504C218.5 31.8804 210.41 29.4304 206.4 27.7204C209.97 32.4104 216.32 34.4604 222.17 32.3404C229.36 29.7404 233.07 21.8504 230.46 14.7104Z" />
      <Paths.L30 d="M212.802 13.4186C214.687 12.1538 215.551 10.1396 214.733 8.91963C213.914 7.69971 211.723 7.73608 209.838 9.00086C207.953 10.2656 207.088 12.2799 207.907 13.4998C208.726 14.7197 210.917 14.6834 212.802 13.4186Z" />
      <Paths.L50 d="M195.57 79.2104H275.01C277.11 79.2104 278.81 80.9104 278.81 83.0104V99.1604C278.81 101.26 277.11 102.96 275.01 102.96H195.57C193.47 102.96 191.77 101.26 191.77 99.1604V83.0104C191.77 80.9104 193.47 79.2104 195.57 79.2104Z" />
      <Paths.L40 d="M265.11 73.0604H262.65H258.43C256.89 73.0604 255.65 74.3104 255.65 75.8404V76.8404C255.65 76.8504 255.65 76.8504 255.65 76.8604V79.2004H258.43H262.65H268.91V76.8604C268.92 74.7604 267.21 73.0604 265.11 73.0604Z" />
      <Paths.L60 d="M235.459 79.2104H275.009C277.109 79.2104 278.809 80.9104 278.809 83.0104V99.1604C278.809 101.26 277.109 102.96 275.009 102.96H235.479C235.469 102.96 235.459 102.95 235.459 102.94V79.2104Z" />
      <Paths.L40 d="M191.77 81.6404C191.77 80.3004 192.86 79.2104 194.2 79.2104H276.38C277.72 79.2104 278.81 80.3004 278.81 81.6404V83.0104H191.77V81.6404Z" />
      <Paths.L50 d="M243.98 75.0104H244.04V74.6104C244.04 72.5104 242.34 70.8104 240.24 70.8104H240.09V69.1704C240.09 67.7604 238.94 66.6104 237.53 66.6104H232.84C231.43 66.6104 230.28 67.7604 230.28 69.1704V70.8104C228.2 70.8304 226.51 72.5204 226.51 74.6104V75.0104H226.58C224.49 75.0204 222.79 76.7104 222.79 78.8104V79.2104H247.76V78.8104C247.77 76.7104 246.08 75.0104 243.98 75.0104Z" />
      <Paths.L30 d="M43.8597 66.5004C43.4297 67.6604 44.4197 69.0904 46.0697 69.6804C47.7097 70.2804 49.3897 69.8104 49.8197 68.6504L63.4497 31.3404L57.4997 29.1904L43.8597 66.5004Z" />
      <Paths.L20 d="M44.2699 65.3904C43.8999 66.4004 44.3099 66.2904 44.6999 66.4304C45.0999 66.5704 45.3399 66.9104 45.6999 65.9104L57.7599 32.9004L56.3199 32.3804L44.2699 65.3904Z" />
      <Paths.L40 d="M64.6398 33.1704C72.2798 33.1704 78.4798 27.0104 78.4798 19.4104C78.4798 11.8104 72.2798 5.65039 64.6398 5.65039C56.9998 5.65039 50.7998 11.8104 50.7998 19.4104C50.7998 27.0104 56.9898 33.1704 64.6398 33.1704Z" />
      <Paths.L60 d="M73.5298 20.7904C73.2798 20.7304 73.0798 20.5204 73.0498 20.2504C72.9498 19.3004 72.2598 18.5304 71.3298 18.3204C70.3998 18.1104 69.4498 18.5304 68.9598 19.3504C68.7798 19.6504 68.3998 19.7404 68.0998 19.5604C67.8098 19.3804 67.7098 19.0004 67.8898 18.7004C68.6598 17.4204 70.1498 16.7704 71.5998 17.0904C73.0498 17.4104 74.1298 18.6204 74.2898 20.1004C74.3298 20.4404 74.0798 20.7504 73.7398 20.7904C73.6598 20.8004 73.5998 20.8004 73.5298 20.7904Z" />
      <Paths.L60 d="M61.2598 18.0904C61.0098 18.0304 60.7998 17.8204 60.7798 17.5504C60.6798 16.6004 59.9898 15.8304 59.0598 15.6204C58.1298 15.4104 57.1798 15.8304 56.6898 16.6504C56.5098 16.9504 56.1298 17.0404 55.8298 16.8604C55.5398 16.6804 55.4398 16.3004 55.6198 16.0004C56.3898 14.7204 57.8798 14.0704 59.3298 14.3904C60.7798 14.7104 61.8598 15.9204 62.0198 17.4004C62.0598 17.7404 61.8098 18.0504 61.4698 18.0904C61.3998 18.1104 61.3198 18.1104 61.2598 18.0904Z" />
      <Paths.L60 d="M63.1995 25.9904C60.7595 25.4504 58.9295 23.4104 58.6595 20.9104C58.6195 20.5704 58.8695 20.2604 59.2095 20.2204C59.5495 20.1804 59.8595 20.4304 59.8895 20.7704C60.0995 22.7404 61.5295 24.3404 63.4595 24.7704C65.3795 25.1904 67.3595 24.3304 68.3795 22.6404C68.5595 22.3404 68.9395 22.2504 69.2395 22.4304C69.5295 22.6104 69.6295 22.9904 69.4495 23.2904C68.1595 25.4304 65.6495 26.5204 63.1995 25.9904Z" />
      <Paths.L50 d="M77.6498 14.7104C76.7498 12.2404 75.2098 10.1904 73.2798 8.6604C74.4898 11.4304 77.1298 19.8104 70.7398 26.5504C65.6898 31.8804 57.5998 29.4304 53.5898 27.7204C57.1598 32.4104 63.5098 34.4604 69.3598 32.3404C76.5498 29.7404 80.2598 21.8504 77.6498 14.7104Z" />
      <Paths.L30 d="M59.9939 13.4086C61.8789 12.1439 62.7433 10.1296 61.9247 8.90969C61.1062 7.68977 58.9146 7.72613 57.0296 8.99092C55.1447 10.2557 54.2803 12.2699 55.0989 13.4899C55.9174 14.7098 58.109 14.6734 59.9939 13.4086Z" />
      <Paths.L30 d="M144.04 70.7104C144.04 71.9404 142.63 72.9404 140.89 72.9404C139.15 72.9404 137.74 71.9404 137.74 70.7104V31.2104H144.04V70.7104Z" />
      <Paths.L20 d="M139.26 67.1704C139.26 68.2404 138.92 68.0004 138.5 68.0004C138.08 68.0004 137.74 68.2404 137.74 67.1704V32.2304H139.27L139.26 67.1704Z" />
      <Paths.L40 d="M140.93 33.1804C148.54 33.1804 154.7 27.0504 154.7 19.4904C154.7 11.9304 148.54 5.80042 140.93 5.80042C133.32 5.80042 127.16 11.9304 127.16 19.4904C127.16 27.0504 133.33 33.1804 140.93 33.1804Z" />
      <Paths.L50 d="M149.35 8.67041C150.51 11.2604 153.3 19.6804 146.91 26.4204C141.81 31.8104 133.55 29.1204 129.72 27.4404C132.22 30.9204 136.31 33.1804 140.93 33.1804C148.54 33.1804 154.7 27.0504 154.7 19.4904C154.7 15.0904 152.61 11.1704 149.35 8.67041Z" />
      <Paths.L30 d="M136.759 13.1282C138.635 11.8696 139.497 9.86776 138.685 8.65701C137.872 7.44626 135.693 7.48507 133.817 8.7437C131.941 10.0023 131.079 12.0042 131.892 13.2149C132.704 14.4256 134.884 14.3868 136.759 13.1282Z" />
      <Paths.L60 d="M143.83 17.7704C144.31 16.3504 145.63 15.3904 147.12 15.3904C148.61 15.3904 149.94 16.3504 150.41 17.7704C150.52 18.1004 150.34 18.4604 150.02 18.5704C149.95 18.5904 149.88 18.6004 149.82 18.6004C149.56 18.6004 149.32 18.4304 149.23 18.1704C148.92 17.2604 148.08 16.6504 147.12 16.6504C146.16 16.6504 145.32 17.2604 145.01 18.1704C144.9 18.5004 144.54 18.6804 144.22 18.5704C143.89 18.4604 143.72 18.1004 143.83 17.7704Z" />
      <Paths.L60 d="M146.5 22.8204C146.83 22.7104 147.18 22.8904 147.29 23.2204C147.92 25.1104 149.68 26.3704 151.66 26.3704C152.09 26.3704 152.51 26.3104 152.91 26.1904C152.64 26.6804 152.34 27.1604 152.01 27.6104C151.89 27.6204 151.78 27.6204 151.66 27.6204C149.14 27.6204 146.91 26.0104 146.11 23.6104C145.99 23.2804 146.17 22.9304 146.5 22.8204Z" />
      <Paths.L50 d="M101.33 79.2104H180.77C182.87 79.2104 184.57 80.9104 184.57 83.0104V99.1604C184.57 101.26 182.87 102.96 180.77 102.96H101.33C99.2298 102.96 97.5298 101.26 97.5298 99.1604V83.0104C97.5298 80.9104 99.2298 79.2104 101.33 79.2104Z" />
      <Paths.L40 d="M170.87 73.0604H168.41H164.19C162.65 73.0604 161.41 74.3104 161.41 75.8404V76.8404C161.41 76.8504 161.41 76.8504 161.41 76.8604V79.2004H164.19H168.41H174.67V76.8604C174.68 74.7604 172.97 73.0604 170.87 73.0604Z" />
      <Paths.L60 d="M141.22 79.2104H180.77C182.87 79.2104 184.57 80.9104 184.57 83.0104V99.1604C184.57 101.26 182.87 102.96 180.77 102.96H141.24C141.23 102.96 141.22 102.95 141.22 102.94V79.2104Z" />
      <Paths.L40 d="M97.5298 81.6404C97.5298 80.3004 98.6198 79.2104 99.9598 79.2104H182.14C183.48 79.2104 184.57 80.3004 184.57 81.6404V83.0104H97.5298V81.6404Z" />
      <Paths.L50 d="M149.74 75.0104H149.8V74.6104C149.8 72.5104 148.1 70.8104 146 70.8104H145.85V69.1704C145.85 67.7604 144.7 66.6104 143.29 66.6104H138.6C137.19 66.6104 136.04 67.7604 136.04 69.1704V70.8104C133.96 70.8304 132.27 72.5204 132.27 74.6104V75.0104H132.34C130.25 75.0204 128.55 76.7104 128.55 78.8104V79.2104H153.52V78.8104C153.53 76.7104 151.84 75.0104 149.74 75.0104Z" />
      <Paths.L50 d="M7.72969 79.2104H87.1697C89.2697 79.2104 90.9697 80.9104 90.9697 83.0104V99.1604C90.9697 101.26 89.2697 102.96 87.1697 102.96H7.72969C5.62969 102.96 3.92969 101.26 3.92969 99.1604V83.0104C3.92969 80.9104 5.62969 79.2104 7.72969 79.2104Z" />
      <Paths.L40 d="M77.2798 73.0604H74.8198H70.5998C69.0598 73.0604 67.8198 74.3104 67.8198 75.8404V76.8404C67.8198 76.8504 67.8198 76.8504 67.8198 76.8604V79.2004H70.5998H74.8198H81.0798V76.8604C81.0798 74.7604 79.3798 73.0604 77.2798 73.0604Z" />
      <Paths.L60 d="M47.6196 79.2104H87.1696C89.2696 79.2104 90.9696 80.9104 90.9696 83.0104V99.1604C90.9696 101.26 89.2696 102.96 87.1696 102.96H47.6396C47.6296 102.96 47.6196 102.95 47.6196 102.94V79.2104Z" />
      <Paths.L40 d="M3.92969 81.6404C3.92969 80.3004 5.01969 79.2104 6.35969 79.2104H88.5397C89.8797 79.2104 90.9697 80.3004 90.9697 81.6404V83.0104H3.92969V81.6404Z" />
      <Paths.L50 d="M56.1495 75.0104H56.2095V74.6104C56.2095 72.5104 54.5095 70.8104 52.4095 70.8104H52.2595V69.1704C52.2595 67.7604 51.1095 66.6104 49.6995 66.6104H45.0095C43.5995 66.6104 42.4495 67.7604 42.4495 69.1704V70.8104C40.3695 70.8304 38.6795 72.5204 38.6795 74.6104V75.0104H38.7495C36.6595 75.0204 34.9595 76.7104 34.9595 78.8104V79.2104H59.9295V78.8104C59.9295 76.7104 58.2395 75.0104 56.1495 75.0104Z" />
    </Thumbnail>
  );
};

export default TeamPlayer;
