import React from 'react';
import gsap from 'gsap';
import Animation from '../../../../Animations/Animation';
import Blobs1 from '../../../../Backgrounds/Patterns/Blobs1';

export default class Base extends Animation {
  constructor(props) {
    super(props);
    this.refs = {};
    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
    this.ref3 = React.createRef();
    this.ref4 = React.createRef();
    this.ref5 = React.createRef();
    this.ref6 = React.createRef();
    this.ref7 = React.createRef();
    this.ref8 = React.createRef();
    this.ref9 = React.createRef();
    this.ref10 = React.createRef();
  }

  componentWillUnmount = () => {
    this.tl.kill();
  };

  initAnimation = () => {
    const tl = gsap.timeline({
      defaults: { opacity: 0.3, duration: 0 },
    });
    tl.to(this.ref1.current, { scale: 1.2, x: -40 }, '<');
    tl.to(this.ref2.current, { scale: 1.4, y: -30 }, '<');
    tl.to(this.ref3.current, { scale: 0.8, y: 50 }, '<');
    tl.to(this.ref4.current, { scale: 0.7, y: -20, x: 10 }, '<');
    tl.to(this.ref5.current, { scale: 1.5, y: 30, x: 30 }, '<');
    tl.to(this.ref6.current, { scale: 1.2, y: 20 }, '<');
    tl.to(this.ref7.current, { scale: 0.7, y: -30, x: 100 }, '<');
    tl.to(this.ref8.current, { scale: 1.3, y: -80, x: -20 }, '<');
    tl.to(this.ref9.current, { scale: 0.8, y: 50, x: 40 }, '<');
    tl.to(this.ref10.current, { scale: 1.2, y: -20, x: 40 }, '<');
  };

  playAnimation = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, opacity: 0.2, scale: 1, duration: 3 };
    tl.add(this.initAnimation);

    tl.to(this.ref1.current, { ...resetProps }, '<');
    tl.to(this.ref2.current, { ...resetProps }, '<');
    tl.to(this.ref3.current, { ...resetProps }, '<');
    tl.to(this.ref4.current, { ...resetProps }, '<');
    tl.to(this.ref5.current, { ...resetProps }, '<');
    tl.to(this.ref6.current, { ...resetProps }, '<');
    tl.to(this.ref7.current, { ...resetProps }, '<');
    tl.to(this.ref8.current, { ...resetProps }, '<');
    tl.to(this.ref9.current, { ...resetProps }, '<');
    tl.to(this.ref10.current, { ...resetProps }, '<');

    this.tl = tl;
    return tl;
  };

  resetAnimation = () => {};

  render() {
    return (
      <Blobs1
        ref={{
          ref1: this.ref1,
          ref2: this.ref2,
          ref3: this.ref3,
          ref4: this.ref4,
          ref5: this.ref5,
          ref6: this.ref6,
          ref7: this.ref7,
          ref8: this.ref8,
          ref9: this.ref9,
          ref10: this.ref10,
        }}
      />
    );
  }
}
