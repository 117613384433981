import React from 'react';
import PropTypes from 'prop-types';
import { ListContainer, ButtonNew as Button } from '@matterapp/matter-ui';

class JobCategoryButtons extends React.Component {
  renderButtons = () =>
    this.props.jobCategories.map((item, index) => (
      <div key={item.id}>
        <Button
          pill
          size="large"
          rcLabel={`jobCategoryButton-${index}`}
          onClick={() => {
            this.props.setJobCategory(item.id);
          }}
        >
          {item.title === 'Other' ? <em>Other</em> : item.title}
        </Button>
      </div>
    ));

  render() {
    return (
      <ListContainer stacked animated>
        {this.renderButtons()}
      </ListContainer>
    );
  }
}

JobCategoryButtons.propTypes = {
  jobCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  setJobCategory: PropTypes.func.isRequired,
};

export default JobCategoryButtons;
