export const BUTTON_LABEL_OKAY = 'Okay';
export const BUTTON_LABEL_YES = 'Yes';
export const BUTTON_LABEL_NO = 'No';
export const BUTTON_LABEL_COMPLICATED = "It's complicated";
export const BUTTON_LABEL_SUBMIT = 'Submit';
export const BUTTON_LABEL_ONWARD = 'Onward';
export const BUTTON_LABEL_ADD = 'Add';
export const BUTTON_LABEL_CANCEL = 'Cancel';
export const BUTTON_LABEL_NEXT = 'Next';
export const BUTTON_LABEL_CONFIRM = 'Confirm';
export const BUTTON_LABEL_SKIP_STEP = 'Skip this step';
export const BUTTON_LABEL_LETS_GO = 'Let’s go!';
export const BUTTON_LABEL_LETS_GET_STARTED = "Let's get started!";
export const BUTTON_LABEL_GET_STARTED = 'Get started';
export const BUTTON_LABEL_SKIP_FOR_NOW = 'Skip for now';
