import React from 'react';
import styled from 'styled-components';
import { media, spacing, colors, font, lineHeight } from '@matterapp/matter-theme';
import { Box } from '@mui/material';

const BOTTOM_BORDER = `1px solid ${colors.blacks[10]}`;
const MOBILE_PADDING = `0 ${spacing.single}`;
const DESKTOP_PADDING = `0 ${spacing.singleAndHalf}`;

const sharedHeaderStyles = `
  display: flex;
  align-items: center;
  width: 100%;
  height: ${spacing.tripleAndHalf};
  font-size: ${font.size.S};
  line-height: ${lineHeight.L};
  font-weight: ${font.weight.medium};
  color: ${colors.blacks[60]};
  border-bottom: ${BOTTOM_BORDER};
`;

const sharedRowStyles = `
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: ${BOTTOM_BORDER};
`;

const HeaderContainer = styled.div`
  display: none;
  ${media.M`
  padding: ${DESKTOP_PADDING};
    ${sharedHeaderStyles}
  `}
  border-top: ${BOTTOM_BORDER};
`;

const MobileHeaderContainer = styled.div`
  ${sharedHeaderStyles}
  padding: ${MOBILE_PADDING};
  ${media.M`
    display: none;
  `}
`;

const RowContainer = styled.div`
  display: none;
  ${media.M`
    height: ${spacing.quintuple};
    padding: ${DESKTOP_PADDING};
    ${sharedRowStyles}
    &:last-of-type {
      border-bottom: none;
    }
  `}
`;

const MobileRowContainer = styled.div`
  height: ${spacing.quadrupleAndHalf};
  padding: ${MOBILE_PADDING};
  ${sharedRowStyles}
  ${media.M`
    display: none;
  `}
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: ${spacing.quintuple};
  font-size: ${font.size.base};
  font-weight: ${font.weight.medium};
  line-height: ${lineHeight.M};
  color: ${colors.blacks[40]};
`;

export default function RewardsTable(props) {
  const {
    headerRow,
    mobileHeaderRow,
    desktopRows,
    mobileRows,
    emptyStateMessage
  } = props;
  const isEmpty = !mobileRows.length || !desktopRows.length;

  return (
    <Box>
      <HeaderContainer>{headerRow}</HeaderContainer>
      <MobileHeaderContainer>{mobileHeaderRow}</MobileHeaderContainer>
      {isEmpty ? 
        <EmptyState>{emptyStateMessage}</EmptyState> :
        <>
          {mobileRows.map((row, index) => <MobileRowContainer key={`${index}-mobile`}>{row}</MobileRowContainer>)}
          {desktopRows.map((row, index) => <RowContainer key={`${index}-desktop`}>{row}</RowContainer>)}
        </>
      }
    </Box>
  );
};

RewardsTable.defaultProps = {
  hideOnMobile: true,
  mobileRows: [],
  desktopRows: [],
};
