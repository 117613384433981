import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, fill }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24">
    <path id="Ellipse" d="M5,10c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S3.9,10,5,10z" fill={fill} />
    <path id="Ellipse_2" d="M12,10c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S10.9,10,12,10z" fill={fill} />
    <path id="Ellipse_3" d="M19,10c1.1,0,2,0.9,2,2s-0.9,2-2,2c-1.1,0-2-0.9-2-2S17.9,10,19,10z" fill={fill} />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Icon;
