import React from 'react';
import Confirm from '../Confirm';

const ConfirmResetModal = (props) => {
  const {
    cancelButtonLabel,
    confirmButtonLabel,
    header,
    isOpen,
    onCancel,
    onConfirm,
    subHeader,
  } = props;
  return (
    <Confirm
      isOpen={isOpen}
      onClose={onCancel}
      header={header}
      subHeader={subHeader}
      cancelButtonLabel={cancelButtonLabel}
      confirmButtonLabel={confirmButtonLabel}
      onClickCancel={onCancel}
      onClickConfirm={onConfirm}
    />
  );
};

ConfirmResetModal.defaultProps = {
  cancelButtonLabel: 'Cancel',
  confirmButtonLabel: 'Yes, Discard',
  header: 'Discard Changes?',
  subHeader: 'You can’t undo this and you will lose your draft.',
};

export default ConfirmResetModal;
