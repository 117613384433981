import styled from 'styled-components';
import { Clickable } from '../Clickable/Clickable';
import { colors, font, mixins, spacing, transitionTime } from '@matterapp/matter-theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CommentsContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  color: ${colors.blacks[90]};
  ${({ showOverflowShadow }) => showOverflowShadow && mixins.overflowScrollShadow()}
`;

export const WriteCommentContainer = styled.div`
  flex: 1 1 auto;
`;

export const ViewAllCommentsLinkContainer = styled.div`
  padding: ${spacing.single} ${spacing.singleAndHalf} ${spacing.singleAndHalf};
`;

export const ViewAllCommentsLink = styled(Clickable)`
  color: ${colors.blacks[60]};
  display: inline-block;
  font-size: ${font.size.XS};
  text-align: left;
  text-transform: uppercase;
  transition: color ${transitionTime.default};

  &:hover {
    color: ${colors.purples[40]};
  }

  &:active {
    color: ${colors.purples[60]};
  }
`;
