import styled from 'styled-components';
import theme, { spacing, media } from '@matterapp/matter-theme';

export const Section = styled.div`
padding: 0 ${spacing.single};
${({ isHidden }) => isHidden && `
  border-bottom: none;
`}
${media.S`
  padding: 0 ${spacing.singleAndHalf};
`}
`;

export const OpenToCloseWrapper = styled.div`
  height: auto;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;

  ${({isOpen, height}) => isOpen ? `
    max-height: ${height}px;
    transition: max-height 0.2s ease-out-in;
  ` : `max-height: 0;`}

  div:nth-child(2) {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const CloseToOpenWrapper = styled.div`
  transition: max-height 0.5s ease-in-out;
  height: auto;
  overflow: hidden;
  max-height: 0px;
  padding: 0;

  ${({ open, height }) =>
    open &&
    `
    max-height: calc(${height}px + (${theme.padding.base} * 2));
    transition: max-height 0.2s ease-out-in;
  `}

  ${({ open, height }) =>
    open &&
    media.S`
    max-height: calc(${height}px + (${theme.padding.S} * 2));
  `}
`;

export const CloseToOpenBody = styled.div``;