import { compose, withProps } from 'recompose';
import { Resources } from '@matterapp/routing';
import withRouter from 'hocs/withRouter';
import withAuthReducer from './withAuthReducer';
import withRequestMagicLinkTokenMutation from './withRequestMagicLinkTokenMutation';

const withData = compose(
  withRequestMagicLinkTokenMutation,
  withRouter,
  withAuthReducer,
  withProps(
    ({ requestMagicLinkTokenMutation, history }) => ({
      handleRequestMagicLinkToken: async ({ email }) => {
        try {
          await requestMagicLinkTokenMutation({
            variables: { email },
          });
          window.localStorage.setItem('magicLinkEmail', email);
        } catch (e) {
          // Irrespctive of the error here, we want users who are using email signin to be redirected to `check you email` page
          // This is the best way to avoid outsiders to know if an email exists in our db for security purposes (instead of
          // showing error messages/toast messages stating the same)
        }
        history.push(Resources.authMagicLinkVerify.path());
      },
    })
  )
);

export default withData;
