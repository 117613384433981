import React from 'react';
import styled from 'styled-components';
import Base from './Base';

const DEFAULT_HEIGHT = 58.39141;
const DEFAULT_WIDTH = 59.00003;
const DEFAULT_VIEWBOX = `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`;

const Background = styled(Base.path).attrs({
  fillColor: '#83E2E3',
  grayScaleColor: '#E6E6E7',
  d: `M0,24.5C2.8,12.4,15.8,1.3,28.3,0c16.8,2,33.4,14.8,30.3,33.3c-3.5,17-20.2,28-37.2,24.4
  c-0.3-0.1-0.6-0.1-0.9-0.2C6.8,53.2-0.2,37.7,0,24.5z M34.9,9.8c1.5-1,3.4-1,4.9-0.1C37.1,5.9,27.8,3.7,34.9,9.8z M26.2,43.1
  c1.2-0.4,0.4-1.2,0-2.1C23.9,36.1,24.1,40.7,26.2,43.1z M19,46.4c1.2,1.2,2.6,2.1,4.1,2.6C22,47.7,20.6,46.8,19,46.4L19,46.4z
   M42.2,36.9c0.1-1.3,0.8-4.3,0.3-4.7C42.4,33.6,41.1,35.9,42.2,36.9z M34.1,51.6c0.4,1.1,1.2,1.5,2.6,0.9
  C35.9,51.9,35.2,51.1,34.1,51.6z M18.9,23.9c-0.9-0.5-1.1,0-1.4,0.7C17.4,25.9,18.5,24.3,18.9,23.9z M27,8c0.4,0.6,1.8,1,2.5,1.6
  C29,8.7,28.1,8.1,27,8z M17.1,44.8c0.7,0.5,1.3,1.8,1.8,0.6C18.3,45.3,17.7,44.8,17.1,44.8z M44.3,21.7c0.4-0.3,0.7-1.7-0.3-1.3
  C44.1,20.8,44.2,21.3,44.3,21.7z M19.3,36.7c0,0.7,0.3,1.4,0.6,1.9C19.8,37.9,19.6,37.3,19.3,36.7z M28.5,11.2
  c-0.4-0.6-0.5-0.7-0.8-0.7C27.8,10.9,28.1,11,28.5,11.2z M22.5,8c-0.3,0.2-0.6,0.4-0.8,0.7C22.1,8.5,22.9,8.5,22.5,8z M20.5,39.8
  c-0.2-0.5-0.2-1.3-0.5-1.4C19.9,38.9,20.1,39.4,20.5,39.8z M7,29.1c-0.1,0.6-0.1,0.7,0.2,1C7.1,29.8,7.3,29.4,7,29.1z M43.4,23.1
  c0.2,0.2,0.3,0.6,0.6,0.5C44.1,23,44,22.9,43.4,23.1z M27.3,6.3c-0.1,0.3,0.4,0.2,0.6,0.3C27.8,6.1,27.5,6.3,27.3,6.3z M7.3,31.6
  c0.2,0,0.1-0.5-0.1-0.7C7.2,31.1,7.2,31.3,7.3,31.6z M7.1,30.5c0.1,0.1,0.2,0.1,0.2,0C7.2,30.4,7.2,30.3,7.1,30.5z M27.3,44.6
  c0.3,0,0.3,0,0.3-0.3C27.5,44.4,27.4,44.5,27.3,44.6z`,
})``;

const Overlay = styled(Base.path).attrs({
  fillColor: '#98CBFF',
  grayScaleColor: '#F6F6F6',
  d: `M0,29.6c-0.5-15.8,12.2-28,29.5-29c6,1.2,11.9,2.7,16.7,6.6c5.7,4.6,9.7,10.2,10.5,17.8
  c0.9,8.4-0.7,16.3-6,23c-5,6.2-11.8,9.9-19.6,10.2C14.4,58.6,0.7,47.2,0,29.6z M21.4,54.4c3.6,1.7,7,1.5,10.3,1.5
  c4.7,0,8.9-2.4,12.7-4.8c2.6-1.7,4.7-3.9,6.1-6.6c1.8-3.6,3.2-7.3,4.2-11.1c1-3.5,0.4-7.1-0.4-10.5c-1.2-5.1-4.4-9-7.9-12.8
  c-0.7-0.8-1.5-1-2.4-0.2c-1.2,1.1-2.4,2.3-3.7,3.4c-0.6,0.6-1.3,1.1-1.9,0.3c-0.2-0.3,0.1-1.4,0.5-1.9c1.1-1.5,2.4-2.8,3.6-4.3
  c-2.5-2.1-5.6-3.3-8.9-3.7c-0.7-0.1-1.4,0.1-1.9,0.5c-1.6,1.4-3.1,2.9-4.7,4.3c-2.9,2.5-5.7,5.1-8.7,7.5c-0.4,0.3-1.1,0.2-1.7,0.2
  c0.2-0.6,0.3-1.2,0.6-1.7c0.1-0.2,0.4-0.3,0.6-0.5c3.7-3.3,7.3-6.7,11-10c0.3-0.3,0.5-0.6,0.7-0.9c0-0.2-0.1-0.3-0.1-0.5
  C27.4,3,25.2,3,23.3,3.6c-3.1,1.1-6.1,2.3-9.1,3.8c-0.7,0.3-1.3,0.7-1.9,1.3c-1.6,1.6-3.1,3.3-4.7,4.9c-0.8,0.7-1.4,1.4-2,2.3
  c-2.3,4.9-4,9.9-3.4,15.5C2.4,32.8,2.7,34.2,3,36c0.6-0.3,1.2-0.7,1.7-1.1c1.8-1.7,3.7-3.5,5.5-5.2c1.6-1.5,3.2-3,4.9-4.5
  c0.5-0.4,1.1-0.7,1.8-0.8c0.4,0,0.8,0.2,1.1,0.6c0.1,0.4,0,0.9-0.3,1.2c-1.6,1.4-3.2,2.8-4.8,4.2c-2.7,2.3-5.4,4.5-8.1,6.8
  c-0.9,0.6-1.2,1.9-0.6,2.9c0,0.1,0.1,0.1,0.2,0.2c1,1.6,2,3.3,3.1,4.9c2.5,3.8,6.2,6.7,10.4,8.4c1,0.5,2,0.8,2.4-0.8
  c0.1-0.3,0.4-0.6,0.7-0.8c2.1-1.8,4.1-3.6,6.2-5.3c1.1-0.9,2.2-1.9,3.4-2.8c1.1-0.9,2.3-1.8,3.4-2.8c0.7-0.8,1.5-1.5,2.3-2.1
  c3.2-1.7,5.5-4.4,8.1-6.8c0.5-0.5,1.1-1,1.8-1.4c0.5-0.1,1-0.2,1.5-0.1c0,0.5,0.1,1.1-0.2,1.5c-0.6,0.8-1.2,1.5-2,2.1
  c-2.7,2.3-5.3,4.7-8,6.9c-3.1,2.6-6.3,5.2-9.5,7.8C25.9,50.7,23.8,52.5,21.4,54.4z M24.7,38.8c-0.4-0.3-0.9-0.5-0.9-0.7
  c0-0.4,0.2-0.8,0.5-1.2c3.8-3.3,7.6-6.6,11.4-9.9c0.8-0.7,1.4-1.4,2.2-2c0.7-0.5,1.6-1.4,2.3-0.3c0.2,0.3-0.5,1.6-1.1,2.1
  c-1.4,1.3-3,2.5-4.4,3.7c-2.7,2.3-5.4,4.7-8.2,7C25.9,38,25.3,38.4,24.7,38.8z`,
})``;

const Neptune = (props) => {
  return (
    <Base
      {...props}
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      viewBox={DEFAULT_VIEWBOX}
    >
      <Background />
      <Overlay />
    </Base>
  );
};

export default Neptune;