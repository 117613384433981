import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '../Modal';
import WithActionButtons from '../Modal/components/Footer/WithActionButtons';
import Old from './Old';
import omitStyled from '../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.singleAndHalf};
`;

const SubheaderContainer = styled.div`
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  margin-bottom: ${theme.spacing.single};
`;

const HeaderContainer = omitStyled('div', ['centerAlignHeader'])`
  text-align: ${({ centerAlignHeader }) => centerAlignHeader ? 'center' : 'left'};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.M};
  line-height: ${theme.lineHeight.M};
  margin-bottom: ${theme.spacing.half};
`;

const Footer = styled(WithActionButtons)`
  border: none;
  padding: 0;
`;

const Confirm = (props) => {
  const {
    canClickConfirm,
    canClickCancel,
    className,
    header,
    subHeader,
    centerAlignHeader,
    isOpen,
    onClickConfirm,
    onClickCancel,
    onClose,
    confirmButtonIsDestructive,
    confirmButtonLabel,
    confirmButtonProps,
    cancelButtonLabel,
    cancelButtonProps,
    openLowerFromTop,
    useVividConfirmButton,
    maxHeight,
  } = props;
  const footerProps = {
    canClickPrimary: canClickConfirm,
    canClickSecondary: canClickCancel,
    onClickPrimary: onClickConfirm,
    onClickSecondary: onClickCancel,
    primaryIsDestructive: confirmButtonIsDestructive,
    primaryLabel: confirmButtonLabel,
    primaryProps: confirmButtonProps,
    secondaryLabel: cancelButtonLabel,
    secondaryProps: cancelButtonProps,
    useVividPrimaryButton: useVividConfirmButton,
  };
  return (
    <Modal
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      openLowerFromTop={openLowerFromTop}
      transition={Modal.transitions.FADE}
      maxHeight={maxHeight}
    >
      <Container>
        {header && <HeaderContainer centerAlignHeader={centerAlignHeader}>{header}</HeaderContainer>}
        <SubheaderContainer>{subHeader}</SubheaderContainer>
        {(cancelButtonLabel || confirmButtonLabel) && <Footer {...footerProps} />}
      </Container>
    </Modal>
  );
};

Confirm.Simple = Confirm;
Confirm.Old = Old;

Confirm.defaultProps = {
  openLowerFromTop: true,
  useVividConfirmButton: false,
  cancelButtonLabel: 'Cancel'
};

Confirm.propTypes = {
  /** If confirm button can be clicked. */
  canClickConfirm: PropTypes.bool,
  /** If cancel can be clicked. */
  canClickCancel: PropTypes.bool,
  /** Cancel button label. */
  cancelButtonLabel: PropTypes.node,
  /** Additional props for cancel button. */
  cancelButtonProps: PropTypes.object,
  /** Additional class. */
  className: PropTypes.string,
  /** If confirm button is destructive action. (Button color red) */
  confirmButtonIsDestructive: PropTypes.bool,
  /** Confirm button label. */
  confirmButtonLabel: PropTypes.node,
  /** Additional props for confirm button. */
  confirmButtonProps: PropTypes.object,
  /** Header of confirm. */
  header: PropTypes.node,
  /** Is confirm modal open. */
  isOpen: PropTypes.bool,
  /** Callback when confirm button is clicked. */
  onClickConfirm: PropTypes.func,
  /** Callback when cancel button is clicked. */
  onClickCancel: PropTypes.func,
  /** Callback when modal is closed. */
  onClose: PropTypes.func,
  /** Opens confirm lower from top of screen. */
  openLowerFromTop: PropTypes.bool,
  /** Sub Header of confirm. */
  subHeader: PropTypes.node,
  /** Use vivide confirm button. */
  useVividConfirmButton: PropTypes.bool,
  /** Modal Content css props */
  maxHeight: PropTypes.string
};

export default Confirm;
