import React from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import Animation from '../../../Animations/Animation';
import SVG from '../../../SVG';
import {
  getEase,
  Background,
  Path,
  BASE_VELOCITY,
  SHARED_WIDTH,
} from './styles';

export default class BasePath extends Animation {
  static propTypes = {
    /** If animation should play or just show instantly. */
    animate: PropTypes.bool,
    /** Animates the background in.  */
    animateIn: PropTypes.bool,
    /** Height of path svg. */
    height: PropTypes.number,
    /** Callback when animation completes playing. */
    onComplete: PropTypes.func,
    /** SVG code for the path. */
    pathCode: PropTypes.string,
    /** Length of the path in pixels. */
    pathLength: PropTypes.number,
    /** The speed of the animation per pixel. */
    velocity: PropTypes.number,
  };

  static defaultProps = {
    animate: true,
    animateBackground: false,
    onComplete: () => {},
    pathLength: 1,
    velocity: BASE_VELOCITY,
  };

  onAnimationUnmount = () => {
    if (this.tl && this.tl.kill) {
      // If animation is currently playing, pause it and reset animation.
      this.tl.kill();
    }
  };

  playAnimation = (animationProps = {}) => {
    const {
      animate,
      animateBackground,
      pathLength,
      onComplete,
      velocity,
    } = this.props;
    const duration = animate ? velocity * pathLength : 0;
    const refToAnimate = animateBackground ? this.backgroundRef : this.pathRef;

    this.onAnimationUnmount();

    this.tl = gsap.timeline({
      defaults: { duration: 0, ease: getEase() },
      onComplete,
    });

    if (animateBackground) {
      this.tl.to(refToAnimate, { strokeDashoffset: pathLength }, '<');
    }

    if (!animate) {
      return this.tl;
    }

    this.tl.to(refToAnimate, { strokeDasharray: pathLength, strokeDashoffset: pathLength }, '<');
    if (!animationProps.initialize) {
      if (!duration) {
        onComplete(this.tl);
        this.tl.to(refToAnimate, { strokeDasharray: 0 }, '>');
      } else {
        this.tl.to(refToAnimate, { strokeDashoffset: 0, duration }, '>');
      }
    }
    return this.tl;
  };

  resetAnimation = () => {
    return this.playAnimation({ initialize: true });
  };

  render() {
    const {
      animate,
      animateBackground,
      height,
      pathCode,
      pathLength,
    } = this.props;

    const backgroundProps = {
      d: pathCode,
      strokeDasharray: pathLength,
      strokeDashoffset: animate ? null : 0,
    };
    const pathProps = { ...backgroundProps };
    if (animateBackground) {
      pathProps.strokeDashoffset = pathLength;
    }

    return (
      <SVG
        height={height}
        width={SHARED_WIDTH}
        viewBox={`0 0 ${SHARED_WIDTH} ${height}`}
      >
        <Background
          {...backgroundProps}
          ref={(ref) => (this.backgroundRef = ref)}
        />
        <Path {...pathProps} ref={(ref) => (this.pathRef = ref)} />
      </SVG>
    );
  }
}
