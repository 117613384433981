import React from 'react';
import PropTypes from 'prop-types';
import KudosModal from 'modules/composer/shared/KudosModal';
import { useGiveKudos } from 'hooks/composer';

const GiveKudosModal = (props) => {
  const { isOpen, onClose, ...otherProps } = props;
  const { kudosModalProps } = useGiveKudos({
    showKudosSelection: true,
    isKudosModalOpen: isOpen,
    onCloseKudosModal: onClose,
    ...otherProps,
  });

  return <KudosModal {...kudosModalProps} />;
};

GiveKudosModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  workspace: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default GiveKudosModal;