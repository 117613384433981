import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../Typography/Text';
import theme from '@matterapp/matter-theme';
import { ListItem, ListItemActionBox, ListItemStackContainer } from '../List';
import Avatar from '../Avatar';

const avatarSize = 48;

const AvatarBox = styled.div`
  width: 48px;
  margin-right: ${theme.spacing.half};
  align-self: flex-start;
`;

const ContentContainer = styled.div`
  flex: 1;
  text-align: left;
  padding-right: ${theme.spacing.threeQuarters};
  user-select: none;
  > * {
    display: inline;
    line-height: ${theme.spacing.single};
    margin-right: ${theme.spacing.quarter};
  }
`;
const InvitationItem = ({
  name,
  iconUrl,
  content,
  actions,
  onClick,
  highlighted,
  isStackedOnMobile,
}) => {
  return (
    <ListItem onClick={onClick} highlighted={highlighted}>
      <AvatarBox>
        <Avatar size={avatarSize} url={iconUrl}/>
      </AvatarBox>
      <ListItemStackContainer isStackedOnMobile={isStackedOnMobile}>
        <ContentContainer>
          {content}
          <Text color="black" bold>
            {name}
          </Text>
        </ContentContainer>
        {actions ? <ListItemActionBox>{actions}</ListItemActionBox> : null}
      </ListItemStackContainer>
    </ListItem>
  );
};

InvitationItem.defaultProps = {
  onClick: undefined,
  isStackedOnMobile: false,
  highlighted: false,
};

InvitationItem.propTypes = {
  name: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  isStackedOnMobile: PropTypes.bool,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  highlighted: PropTypes.bool,
};

export default InvitationItem;