import React from 'react';
import PropTypes from 'prop-types';
import { Container, Wrapper, LoadingContainer } from './styles';
import KudosGrid from '../../Kudos/Grid';
import Loader from '../../Loader';

const GiveKudos = (props) => {
  const { loading } = props;
  const { kudosProps, onClickKudos, totalKudosList } = props;
    
  const kudosGridProps = {
    ...kudosProps,
    onClickKudos: (e, params) => {
      onClickKudos(e, { ...params, ...props });
    },
    unlockAllKudos: true,
    totalKudosList
  };

  if (loading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }
  return (
    <Container>
      <Wrapper>
        <KudosGrid {...kudosGridProps} />
      </Wrapper>
    </Container>
  );
};

GiveKudos.defaultProps = {
  kudosProps: {},
};

GiveKudos.propTypes = {
  kudosProps: PropTypes.shape(KudosGrid.propTypes),
  onClickKudos: PropTypes.func,
  showLockedKudos: PropTypes.bool,
};

export default GiveKudos;
