import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import PageContent from '../../../../PageContent';

const PageContainer = styled.div`
  display: flex;
  max-width: ${() => theme.sizes.webClient.maxContentWidth};
  width: 100%;
  margin: 0 auto;

  ${() => theme.media.medium`
    margin-top: 24px;
  `}

  ${() => theme.media.large`
    margin-top: 48px;
  `}
`;

const PageContentWithBackground = styled(PageContent)`
  z-index: 20;
  background: ${() => theme.colors.blacks[5]};
  box-sizing: content-box;
  min-height: calc(100% - 112px);

  ${() => theme.media.medium`
    min-height: calc(100% - 64px);
  `}

  padding-bottom: 100px;
`;

const SideColumnOnlyLargeBreakpoint = styled.div`
  display: none;

  ${() => theme.media.large`
    display: initial;
  `}
`;

const MainBox = styled.div`
  width: 100%;
  margin-right: 0;

  ${() => theme.media.medium`
    width: 100%;
    margin-right: 0;
  `}

  ${() => theme.media.large`
    width: 608px;
    margin-right: 32px;
  `}
`;

const SideBox = styled.div`
  width: 100%;

  ${() => theme.media.medium`
    width: 100%;
  `}

  ${() => theme.media.large`
    width: 352px;
  `}
`;

const AppAuthenticatedV2 = ({
  banner,
  header,
  footer,
  mainColumn,
  sideColumn,
  className,
}) => (
  <React.Fragment>
    {header}
    <PageContentWithBackground className={className}>
      {banner}
      <PageContainer style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
        <MainBox>{mainColumn}</MainBox>
        <SideBox>
          <SideColumnOnlyLargeBreakpoint>
            {sideColumn}
          </SideColumnOnlyLargeBreakpoint>
          {footer}
        </SideBox>
      </PageContainer>
    </PageContentWithBackground>
  </React.Fragment>
);

AppAuthenticatedV2.defaultProps = {
  banner: null,
  header: null,
  footer: null,
  mainColumn: null,
  sideColumn: null,
};

AppAuthenticatedV2.propTypes = {
  banner: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  mainColumn: PropTypes.node,
  sideColumn: PropTypes.node,
  className: PropTypes.string,
};

export default AppAuthenticatedV2;
