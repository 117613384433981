import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  LineWrapper,
  SubHeader, 
  ActionButton, 
  SubText, 
  Section, 
  AllowanceBlock,
  InputWrapper,
  ResetButton,
  CustomStyledInput,
  TemplateButtonWrapper,
  SubSection,
  CustomAmountButton,
  CustomAllowanceBlock
} from './styles';
import { DEFAULT_ALLOWED_COINS, getCoinsHelperText } from './helpers';
import { spacing } from '@matterapp/matter-theme';
import InlineCheckbox from 'components/Forms/InlineCheckbox';
import TextfieldSection from 'components/Forms/TextfieldSection';
import AnniversaryAddCustomAmountModal from './AnniversaryAddCustomAmountModal';
import AnniversaryCustomAmountTable from './AnniversaryCustomAmountTable';

export default function AnniversariesSection({ 
  isActive, 
  isRewardsActive,
  celebrationSummary, 
  anniversaryCustomAwards,
  requestAnniversaryEntry,
  requestCelebrationSpreadsheet,
  onUploadClick,
  anniversaryCoinsAwarded,
  updateAnniversaryCoinsAwarded,
  hasError,
  updateAutoCollectAnniversaries,
  autoCollectAnniversaries,
  anniversaryMessage,
  updateAnniversaryMessage,
  defaultAnniversaryMessage,
  isSlack,
  updateAnniversariesNotifyAll,
  isAnniversariesNotifyAll,
  autoCollectAnniversariesStartDate,
  saveCelebrationSettings,
  workspaceId
}) {
  const [askedMembers, updateAskedMembers] = useState(false);
  const [isCustomAmountModalOpen, setIsCustomAmountModalOpen] = useState(false);

  if (!isActive) {
    return null;
  }
  
  const { activeMembersCount, hiredOnEnteredCount = 0 } = celebrationSummary || {};

  return (
    <Section>
      <AnniversaryAddCustomAmountModal 
        isOpen={isCustomAmountModalOpen}
        onClose={() => setIsCustomAmountModalOpen(false)}
        saveCelebrationSettings={saveCelebrationSettings}
        workspaceId={workspaceId}
        anniversaryCustomAwards={anniversaryCustomAwards}
      />
      {isRewardsActive ? (
        <>
          <AllowanceBlock style={{ marginBottom: '24px' }}>
            <div>
              <p>Coin Amount Awarded</p> 
              {getCoinsHelperText(anniversaryCoinsAwarded)}
            </div>
            <InputWrapper>
              <ResetButton 
                onClick={() => updateAnniversaryCoinsAwarded(DEFAULT_ALLOWED_COINS)}
              >
                Reset
              </ResetButton>
              <span>Default Amount</span>
              <CustomStyledInput
                onChange={(e) => {
                  if (!e.target.value || Number(e.target.value) > -1) {
                    updateAnniversaryCoinsAwarded(e.target.value ? Number(e.target.value) : '');
                  }
                }}
                errorMessage={hasError ? 'Must be between 0-100,000' : null}
                showErrorMessageBelow
                errorMessageClassName="allowanceInputError"
                value={anniversaryCoinsAwarded.toString()} 
              />
            </InputWrapper>
          </AllowanceBlock>
          <CustomAllowanceBlock>
            <CustomAmountButton 
              onClick={() => setIsCustomAmountModalOpen(true)}
            >
              Add Custom Amount
            </CustomAmountButton>
            <AnniversaryCustomAmountTable 
              anniversaryCustomAwards={anniversaryCustomAwards} 
              saveCelebrationSettings={saveCelebrationSettings}
              workspaceId={workspaceId}
            />
          </CustomAllowanceBlock>
        </>
      ) : null}      
      <SubHeader>
        Add Work Anniversaries
      </SubHeader>
      <SubText>{hiredOnEnteredCount} of {activeMembersCount} members have their work anniversaries entered.</SubText>
      <LineWrapper>
        <p>
          Send a message to collect work anniversaries from members whose work anniversary has not been added.
        </p>
        <ActionButton
          disabled={askedMembers}
          onClick={() => {
            updateAskedMembers(true);
            requestAnniversaryEntry();
          }}
        >
          Ask Members
        </ActionButton>
      </LineWrapper>
      <LineWrapper style={{ marginTop: spacing.single }}>
        <p>
          Import anniversaries in bulk via Excel spreadsheet using Matter&apos;s template.
        </p>
        <TemplateButtonWrapper>
          <ActionButton 
            onClick={requestCelebrationSpreadsheet}
          >
            Download Template
          </ActionButton>
          <ActionButton 
            onClick={onUploadClick}
          >
            Upload Completed Template
          </ActionButton>
        </TemplateButtonWrapper>
      </LineWrapper>
      <SubSection>
        <SubHeader>
          Automatically Collect
        </SubHeader>
        <p>
          {autoCollectAnniversaries ? (
            `When new members are added to your workspace after ${autoCollectAnniversariesStartDate}, they will be automatically asked for their work anniversary.`
          ) : 'When new members are added to your workspace, they will be automatically asked for their work anniversary.'}
        </p>
        <InlineCheckbox 
          text='Automatically Collect From New Members'
          onChange={() => updateAutoCollectAnniversaries(!autoCollectAnniversaries)}
          isChecked={autoCollectAnniversaries}
          checkboxName='collectAnniversaries'
        />
      </SubSection>
      <TextfieldSection 
        onChange={(text) => updateAnniversaryMessage(text)}
        defaultMessage={defaultAnniversaryMessage}
        message={anniversaryMessage}
        minLength={25}
        messageHeader='Work Anniversary Message:'
      />
      <SubText>
        {`Write {user} where you'd like to tag the member. {number} for the anniversary year.`}
      </SubText>
      <SubSection>
        <SubHeader>
          Notifications:
        </SubHeader>
        <InlineCheckbox 
          text={isSlack ? <>Notify Channel Members Using <strong>@here</strong></> : 'Notify team with an activity notification'}
          onChange={() => updateAnniversariesNotifyAll(!isAnniversariesNotifyAll)}
          isChecked={isAnniversariesNotifyAll}
          checkboxName='notifyAnniversaries'
        />
      </SubSection>
    </Section>
  );
}

AnniversariesSection.propTypes = {
  isActive: PropTypes.bool,
  isRewardsActive: PropTypes.bool,
  celebrationSummary: PropTypes.object,
  requestAnniversaryEntry: PropTypes.func,
  requestCelebrationSpreadsheet: PropTypes.func,
  onUploadClick: PropTypes.func,
  anniversaryCoinsAwarded: PropTypes.number,
  updateAnniversaryCoinsAwarded: PropTypes.func,
  hasError: PropTypes.bool,
  updateAutoCollectAnniversaries: PropTypes.func,
  autoCollectAnniversaries: PropTypes.bool,
  anniversaryMessage: PropTypes.string,
  updateAnniversaryMessage: PropTypes.func,
  defaultAnniversaryMessage: PropTypes.string,
  isSlack: PropTypes.bool,
  isAnniversariesNotifyAll: PropTypes.bool,
  updateAnniversariesNotifyAll: PropTypes.func,
  autoCollectAnniversariesStartDate: PropTypes.string,
  saveCelebrationSettings: PropTypes.func,
  workspaceId: PropTypes.string,
  anniversaryCustomAwards: PropTypes.array
};
