import styled, { css } from 'styled-components';
import { ClickableLink } from '../../Clickable/Clickable';
import Dropdown from '../../Dropdown';
import { IconNew as Icon } from '../../Icon';
import TextArea from '../../TextArea';
import { Colors, getThemeColor } from '../utils';
import theme, { createDataRole } from '@matterapp/matter-theme';

export const DEFAULT_ANIMATION_NAME = 'KudosThankYou';
export const DEFAULT_TEXT_COLOR = theme.colors.purple;
export const MAX_MESSAGE_LENGTH = 300;

export const KUDOS_ANIMATION_Z_INDEX = 10;
export const KUDOS_TEXT_Z_INDEX = 5;
export const KUDOS_HEIGHT = 120;
export const KUDOS_MAX_WIDTH = 700;
export const IMAGE_MAX_WIDTH = 200;

export const TEXT_AREA_MIN_HEIGHT = 140;
export const LABEL_HEIGHT = 24;

export const MENU_HAX_HEIGHT = 290;
export const MENU_DROPDOWN_HEIGHT = 24;
export const MENU_ITEM_HEIGHT_MOBILE = 32;
export const MENU_ITEM_HEIGHT = 26;

/*****************************************************************************/
/* TEXT STYLES                                                               */
/*****************************************************************************/
export const textContainerPadding = `${theme.spacing.singleAndHalf} ${theme.spacing.singleAndHalf} ${theme.spacing.single}`;

export const TextStyles = css`
  color: ${theme.colors.white};
  font-size: ${theme.fontSize.S};
  font-weight: ${theme.fontWeight.bold};
  line-height: ${theme.lineHeight.M};
  z-index: ${KUDOS_TEXT_Z_INDEX};

  ${theme.media.S`
    font-size: ${theme.fontSize.L};
    line-height: ${theme.lineHeight.L};
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  padding: ${({ isEdit }) => isEdit ? 0 : textContainerPadding};
  word-break: break-word;
  z-index: ${KUDOS_TEXT_Z_INDEX};

  & > * {
    flex: 1 1 auto;
    z-index: ${KUDOS_TEXT_Z_INDEX};
  }
`;

export const Text = styled.div`
  ${TextStyles}
  margin: -${theme.spacing.quarter} 0;
  white-space: break-spaces;
  ${({ textColor }) =>
    textColor && `
    color: ${textColor} !important;
    ::placeholder {
      color: ${textColor} !important;
    }
  `}
`;

export const TextSpacer = styled.div`
  display: inline-block;
  width: ${IMAGE_MAX_WIDTH - 40}px;
  height: ${theme.spacing.single};
`;

export const TextEdit = styled(TextArea).attrs(() => ({
  autoFocus: true,
  autoHeight: true,
  showBottomBorder: false,
}))`
  ${TextStyles}
  background: none !important;
  overflow: hidden;
  margin: -${theme.spacing.quarter} 0;
  min-height: ${TEXT_AREA_MIN_HEIGHT}px;
  padding: ${textContainerPadding};
  width: 100%;
  ::placeholder {
    color: ${theme.colors.white} !important;
  }
  ${({ textColor }) =>
    textColor && `
    color: ${textColor} !important;
    ::placeholder {
      color: ${textColor} !important;
    }
  `}
  

  &[disabled] {
    filter: opacity(50%);
  }
`;

/*****************************************************************************/
/* CARD STYLES                                                               */
/*****************************************************************************/
export const Container = styled.div`
  ${({ backgroundColor, backgroundImage }) => `
    background-color: ${backgroundColor};
    background-image: url(${backgroundImage});
    border-radius: ${theme.borderRadius.L};
    overflow: hidden;
    position: relative;
    transition: background-color ${theme.transitions.times.default};

    & ${Text}, & ${TextEdit} {
      text-shadow: 0 0 ${theme.spacing.quarter} ${backgroundColor}, 0 0 ${theme.spacing.half} ${backgroundColor};
      transition: text-shadow ${theme.transitions.times.default};
      transform: translate3d(0px, 0px, 0px);
    }
  `}
`;

export const AnimationContainer = styled.div`
  align-items: flex-end;
  display: flex;
  min-height: 120px;
  padding: 0 ${theme.spacing.singleAndHalf} ${theme.spacing.singleAndHalf};
  z-index: ${KUDOS_ANIMATION_Z_INDEX};

  & > svg {
    display: block;
    min-width: ${IMAGE_MAX_WIDTH}px;
    max-width: ${IMAGE_MAX_WIDTH}px;
    margin-right: -${theme.spacing.singleAndHalf};
    margin-bottom: -${theme.spacing.singleAndHalf};
    margin-left: -100px;
    height: auto;
    overflow: visible;
    opacity: 0.7;
  }
`;

/*****************************************************************************/
/* DROPDOWN STYLES                                                           */
/*****************************************************************************/
const itemSelector = createDataRole(Dropdown.Item.dataRole);

export const createKudosMenuItemsStyle = () => {
  return Object.values(Colors)
    .map((color) => {
      const isSelected = `[data-selected="true"]`;
      const colorSelector = `[data-color="${color}"]`;
      const { textColor } = getThemeColor(color);
      return `
      & ${itemSelector}${colorSelector}:hover,
      & ${itemSelector}${isSelected}${colorSelector} {
        color: ${textColor} || ${theme.colors.blacks[40]};
      }
    `;
    })
    .join('');
};

export const DropdownLocked = styled.div`
  align-items: center;
  color: ${theme.colors.blacks[40]};
  display: flex;
  font-size: ${theme.fontSize.XXS};
  text-transform: uppercase;
`;

export const SelectMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: auto !important;
  width: auto !important;
  max-width: calc(100vw - 96px);
  max-height: ${MENU_HAX_HEIGHT}px;
  z-index: ${theme.zIndex.dropdown};

  & ${createDataRole(Dropdown.Menu.dataRole)} {
    margin-top: 0;
    max-height: ${MENU_HAX_HEIGHT}px;
    width: calc(100vw - 96px);
    text-transform: uppercase;
    ${createKudosMenuItemsStyle()}

    & ${itemSelector} {
      height: ${MENU_ITEM_HEIGHT_MOBILE}px;
      display: flex;
      align-items: center;
    }

    ${theme.media.S`
      width: 160px;
      & ${itemSelector} {
        height: ${MENU_ITEM_HEIGHT}px;
      }
    `}
  }
`;

export const SelectKudosContainer = styled.div`
  height: ${MENU_ITEM_HEIGHT_MOBILE}px;
  margin-bottom: ${MENU_DROPDOWN_HEIGHT - MENU_ITEM_HEIGHT_MOBILE}px;

  ${theme.media.S`
    height: ${MENU_ITEM_HEIGHT}px;
    margin-bottom: ${MENU_DROPDOWN_HEIGHT - MENU_ITEM_HEIGHT}px;
  `}
`;

export const SelectKudos = styled(Dropdown).attrs(() => ({
  color: Dropdown.colors.GRAY_ALT,
  fitToValue: true,
  fitToValuePadding: 4,
  showMenuOverDropdown: true,
  size: Dropdown.sizes.XS,
}))`
  align-items: center;
  background: ${theme.colors.white} !important;
  border-color: transparent;
  height: ${theme.spacing.singleAndHalf};
  display: flex;

  & input {
    font-weight: ${theme.fontWeight.medium};
    min-width: ${theme.spacing.triple};
    text-transform: uppercase;
  }

  ${theme.media.S`
    & + ${createDataRole(Dropdown.Menu.dataRole)} {
      width: 100%;

      & ${itemSelector} {
        min-height: auto;
      }
    }
  `}
`;

export const Label = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.S};
  display: inline-block;
  font-size: ${theme.fontSize.XS};
  font-weight: ${theme.fontWeight.medium};
  height: ${theme.spacing.singleAndHalf};
  padding: ${theme.spacing.quarter} ${theme.spacing.half};
  position: relative;
  text-transform: uppercase;
`;

export const LabelContainer = styled.div`
  flex: 1 1 100%;

  ${({ color }) => `
    & ${Label} {
      color: ${color};
    }
    & ${SelectKudos} {
      border-color: ${color};
      input {
        color: ${color};
        -webkit-text-fill-color: ${color} !important;
      }
      svg path {
        fill: ${color} !important;
        stroke: ${color} !important;
      }
    }
  `}
`;

/*****************************************************************************/
/* LOCKED MESSAGE STYLES                                                     */
/*****************************************************************************/
export const LockIcon = styled(Icon).attrs(() => ({
  name: 'lock',
}))``;

export const UnlockLink = styled(ClickableLink)`
  position: relative;
  text-decoration: none !important;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    transition: bottom ${theme.transitions.times.default},
      color ${theme.transitions.times.default};
  }
`;

export const LockedContainer = styled.div`
  ${({ backgroundColor, textColor, textColorHover }) => `
    align-items: center;
    background-color: ${backgroundColor};
    border-top-left-radius: ${theme.borderRadius.L};
    border-top-right-radius: ${theme.borderRadius.L};
    color: ${textColor};
    display: flex;
    font-size: ${theme.fontSize.S};
    justify-content: flex-start;
    min-height: ${theme.spacing.tripleAndHalf};
    padding: ${theme.spacing.single} ${theme.spacing.singleAndHalf};
    position: relative;
    text-align: center;
    transition: color ${theme.transitions.times.default};

    &:after {
      background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: ${theme.spacing.half};
      width: 100%;
    }

    & svg {
      margin-right: ${theme.spacing.half};
    }

    & ${UnlockLink} {
      color: ${textColor};

      &:after {
        background: ${textColor};
      }
      &:hover {
        color: ${textColorHover};
        &:after {
          background: ${textColorHover};
          bottom: -2px;
        }
      }
      &:active {
        font-weight: ${theme.fontWeight.bold};
        &:after {
          height: 2px;
          bottom: -2px;
        }
      }
    }
  `}
`;
