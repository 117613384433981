import { useState } from 'react';

export const useNotificationsModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return {
    isOpen,
    onClose: () => setIsOpen(false),
    openNotificationsModal: () => setIsOpen(true),
  };
};
