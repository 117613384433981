import React, { useContext, useState } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import ContainerNavigation from './ContainerNavigation';
import { useMutation } from '@apollo/client';
import updateMemberProperties from 'graphql-queries/mutations/workspaceMembers/updateMemberProperties';
import getCurrentMemberProperties from 'graphql-queries/queries/rewards/getCurrentMemberProperties';
import { Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { Dropdown, Button } from '@matterapp/matter-ui';
import { 
  monthsMap, 
  getDaysInMonthList,
  getMemberBirthdayMonthAndDay,
  getMemberAnniversaryMonthDayAndYear,
  getYearsList,
  monthsOptions
} from 'routes/pages/settings/Members/helpers';
import { useEffect } from 'react';
import Toast from 'components/Toast';

const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const EntryWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4)
}));

const MonthDropdown = styled(Dropdown)(({ theme }) => ({
  width: '190px'
}));

const DropdownWrapper = styled.div(({ theme }) => ({
  marginRight: theme.spacing(2)
}));

const DayDropdown = styled(MonthDropdown)(({ theme }) => ({
  width: '110px'
}));

const YearDropdown = styled(MonthDropdown)(({ theme }) => ({
  width: '140px'
}));

const SaveButton = styled(Button.Primary.Vivid)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(4)
}));

const ClearButton = styled.div(() => ({
  color: 'red',
  cursor: 'pointer',
  fontSize: '17px',
  fontWeight: 'bold'
}));

const LineWrapper = styled.div(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'flex-start',
  width: '100%',
  button: {
    alignSelf: 'center'
  }
}));

function getBirthdayString(day, month) {
  if (!day || !month) {
    return null;
  }
  return `${month} ${day}`;
};

function getHiredOnString(year, month, day) {
  if (!year || !month || !day) {
    return null;
  }

  let monthNumber = monthsOptions.indexOf(month) + 1;
  let dayNumber = parseInt(day);

  if (monthNumber < 10) {
    monthNumber = `0${monthNumber}`;
  }

  if (dayNumber < 10) {
    dayNumber = `0${dayNumber}`;
  }

  return `${year}-${monthNumber}-${dayNumber}`;
}

export default function UserSettingsCelebrations() {
  const { memberProperties, currentTenant, currentUser } = useContext(UserContext);
  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [hiredDay, setHiredDay] = useState('');
  const [hiredMonth, setHiredMonth] = useState('');
  const [hiredYear, setHiredYear] = useState('');
  const [hiredOn, setHiredOn] = useState('');
  const [updateMemberPropertiesMutation, { loading }] = useMutation(updateMemberProperties);
  const updatedBirthday = getBirthdayString(birthDay, birthMonth);
  const birthdayChanged = updatedBirthday !== memberProperties?.birthday;
  const hiredOnChanged = hiredOn !== memberProperties?.hiredOn;
  const canSave = birthdayChanged || hiredOnChanged;

  useEffect(() => {
    if (memberProperties) {
      const [birthdayMonth, birthDay] = getMemberBirthdayMonthAndDay(memberProperties);
      const [hiredYear, hiredMonth, hiredDay] = getMemberAnniversaryMonthDayAndYear(memberProperties);
 
      setBirthDay(birthDay);
      setBirthMonth(birthdayMonth);
      setHiredDay(hiredDay);
      setHiredMonth(hiredMonth);
      setHiredYear(hiredYear);

      setHiredOn(memberProperties.hiredOn);
    }
  }, [memberProperties]);

  useEffect(() => {
    setHiredOn(getHiredOnString(hiredYear, hiredMonth, hiredDay));
  }, [hiredDay, hiredMonth, hiredYear]);

  async function onSave() {
    const variables = {
      personId: currentUser.personId,
      tenantId: currentTenant.id,
      birthday: getBirthdayString(birthDay, birthMonth),
      hiredOn
    };

    await updateMemberPropertiesMutation({
      variables,
      refetchQueries: [{
        query: getCurrentMemberProperties,
        variables: {
          tenantId: currentTenant.id
        }
      }]
    });

    Toast.success(`Successfully updated your settings`);
  }

  return (
    <>
      <AppLayout
        layout={AppLayout.Layouts.TwoColumnFixedLeft}
        oldLayoutVersion={1}
        containerNavigation={<ContainerNavigation />}
      >
        <Header variant="h3">
          Celebrations
        </Header>
        <Typography variant="body1">
          Enter one or more of your celebration dates so Matter can celebrate your special day with your team.
        </Typography>
        <EntryWrapper>
          <Header variant="h3">
            Birthday
          </Header>
          <LineWrapper>
            <DropdownWrapper>
              <MonthDropdown
                value={birthMonth}
                items={monthsMap}
                onChange={(_, { value }) => {
                  setBirthMonth(value);
                }}
                placeholder="Month"
                size={Dropdown.sizes.M}
                showSelectedItemInMenu
              />
            </DropdownWrapper>
            <DropdownWrapper>
              <DayDropdown
                value={birthDay}
                items={getDaysInMonthList(birthMonth)}
                onChange={(_, { value }) => {
                  setBirthDay(value);
                }}
                placeholder="Day"
                size={Dropdown.sizes.M}
                showSelectedItemInMenu
              />
            </DropdownWrapper>
            {birthDay && birthMonth ? (
              <ClearButton onClick={() => {
                setBirthDay('');
                setBirthMonth('');
              }}>
                X
              </ClearButton>
            ) : null}
          </LineWrapper>
        </EntryWrapper>
        <EntryWrapper>
          <Header variant="h3">
            Work Anniversary
          </Header>
          <LineWrapper>
            <DropdownWrapper>
              <MonthDropdown
                value={hiredMonth}
                items={monthsMap}
                onChange={(_, { value }) => {
                  setHiredMonth(value);
                }}
                placeholder="Month"
                size={Dropdown.sizes.M}
                showSelectedItemInMenu
              />
            </DropdownWrapper>
            <DropdownWrapper>
              <DayDropdown
                value={hiredDay}
                items={getDaysInMonthList(hiredMonth)}
                onChange={(_, { value }) => {
                  setHiredDay(value);
                }}
                placeholder="Day"
                size={Dropdown.sizes.M}
                showSelected
                ItemInMenu
              />
            </DropdownWrapper>
            <DropdownWrapper>
              <YearDropdown
                value={hiredYear}
                items={getYearsList()}
                onChange={(_, { value }) => {
                  setHiredYear(value);
                }}
                placeholder="Year"
                showSelectedItemInMenu
              />
            </DropdownWrapper>
            {hiredOn ? (
              <ClearButton onClick={() => {
                setHiredDay('');
                setHiredMonth('');
                setHiredYear('');
              }}>
                X
              </ClearButton>
            ) : null}
          </LineWrapper>
        </EntryWrapper>
        <SaveButton
          submitting={loading}
          onClick={onSave}
          fluid
          size="large"
          disabled={!canSave}
        >
          Save
        </SaveButton>
      </AppLayout>
    </>
  );
}
