import React from 'react';
import PropTypes from 'prop-types';
import Skill from '../../../Skill';
import { ListContainer } from '../../../List';
import { Heading } from './sharedStyle';

const SkillsSelectionList = ({
  skillIds,
  listOptions,
  orderedSkills,
  updateSelectedSkills,
  heading,
  animated
}) => (
  <>
    <Heading>
      <h3>{heading}</h3>
      <div className="selection-count">
        <div>Selected</div>
        <div className="count">
          {skillIds.length}
        </div>
      </div>
    </Heading>
    {listOptions &&
      <ListContainer
        alignLeft
        itemPadding={"0 8px 8px 0"}
        style={{marginBottom: '0px'}}
        animated={animated}
      >
        {orderedSkills.map((skill) => (
          <Skill.Pill.Button.Small
            key={skill.id}
            skill={skill}
            selected={skillIds.includes(skill.id)}
            disabled={skillIds.length == 1 && skillIds.includes(skill.id)}
            onClick={() => updateSelectedSkills(skill)}
          />
        ))}
      </ListContainer>
    }
  </>
);

SkillsSelectionList.defaultProps = {
  animated: true,
};

SkillsSelectionList.propTypes = {
  skillIds: PropTypes.array.isRequired,
};

export default SkillsSelectionList;
