import { notificationsRedux } from '@matterapp/matter-ui';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import onboardingReducer from './onboarding';
import authReducer from './auth';
import guidesReducer from './guides';
import peerRecommendationsReducer from './peer-recommendations';
import overlayReducer from './overlay-reducer';
import quickNoteReducer from './quick-note-reducer';
import componentsReducer from './components-reducer';
import composerReducer from './composer-reducer';
import responseFlowReducer from './response-flow-reducer';

const appReducer = combineReducers({
  form: formReducer,
  notifications: notificationsRedux.reducer,
  onboarding: onboardingReducer,
  auth: authReducer,
  guides: guidesReducer,
  peerRecommendations: peerRecommendationsReducer,
  overlay: overlayReducer,
  quickNote: quickNoteReducer,
  components: componentsReducer,
  composer: composerReducer,
  responseFlow: responseFlowReducer,
});

export * from './onboarding';
export * from './auth';
export * from './guides';
export * from './peer-recommendations';
export * from './overlay-reducer';
export * from './quick-note-reducer';
export * from './components-reducer';
export * from './composer-reducer';
export * from './response-flow-reducer';

export default appReducer;
