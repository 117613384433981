import React from 'react';
import PropTypes from 'prop-types';
import { ProfileCard, ButtonNew as Button } from '@matterapp/matter-ui';
import { personPropType } from '@matterapp/people';

const ProfilePreviewCard = ({
  person,
  handleClickGiveQuickNote,
  handleClickRequestFeedback,
  isRequestFeedbackDisabled,
  requestFeedbackCopy,
  isShowQuickNote,
  defaultAvatarIsGenerated,
}) => (
  <ProfileCard.Preview
    defaultAvatarIsGenerated={defaultAvatarIsGenerated}
    person={person}
    actions={
      <div>
        {!isRequestFeedbackDisabled && (
          <Button
            primary
            fluid
            size="small"
            onClick={handleClickRequestFeedback}
          >
            {requestFeedbackCopy}
          </Button>
        )}
        {isShowQuickNote && (
          <div style={{ marginTop: 8 }}>
            <Button basic fluid size="small" onClick={handleClickGiveQuickNote}>
              Give Feedback
            </Button>
          </div>
        )}
      </div>
    }
  />
);

ProfilePreviewCard.propTypes = {
  person: personPropType.isRequired,
  handleClickRequestFeedback: PropTypes.func.isRequired,
  handleClickGiveQuickNote: PropTypes.func.isRequired,
  isRequestFeedbackDisabled: PropTypes.bool.isRequired,
  isShowQuickNote: PropTypes.bool.isRequired,
  requestFeedbackCopy: PropTypes.string.isRequired,
  defaultAvatarIsGenerated: PropTypes.bool,
};

export default ProfilePreviewCard;
