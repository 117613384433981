import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

// mediumBreakpoint: required to work with FlowCard consts
const mediumBreakpoint = 800;

const RootContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const ListItemsContainer = styled.div`
  position: relative;
  min-height: 0;
  overflow: hidden;

  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  ${() => theme.media.breakpoint(mediumBreakpoint)`
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 32px;
  `};
`;

const GridContainer = ({ children }) => (
  <RootContainer>
    <ListItemsContainer>{children}</ListItemsContainer>
  </RootContainer>
);

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GridContainer;
