import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const ColoredPath = styled.path`
  fill: currentColor;
`;

export const TopCloud = styled(ColoredPath).attrs({
  d: `M2484.6,1185.4c-11.7-6-27,11.7-32.9-2.9c-5.6-6.3-15.6-9.2-23.7-5.9
	c-2.3-28-25.8-44.8-51.1-51.9c-17.8-2.8-29.7,6.4-41.5-12.4c-21.2-25.9-61-35.4-90.6-19.4c-15.5,9.1,3.2-29.4-38.4-49.4
	c-6.1-4.8,1.5-11.9,1.4-17.7c3.1-39.7-20.4-80.9-60.8-90.2c-10.5-4.5-27.3-0.8-33.8-11.1c-3.4-16.8-15.1-29.1-28.2-39.3v-0.5
	c-0.7-0.4-1.3-0.7-2-1.1c-1.6-1.2-3.1-2.3-4.7-3.5l-0.3,1c-14.1-6.4-30-8.6-45-4.1c-11.2,3.4-25.7,6.4-32.1,17.1
	c-3.8,4.6-7.8,9.3-10.2,15c-16.6-5-34.3,0.9-49.6,7.5c-3-20.8-28.2-32.2-47-24.7c-3.8-10.1-7.8-20.2-14.1-29v-1
	c-17.5-28-54.7-26.8-82-15.3c-17.4-37-69.1-40.9-100.9-20.8c-0.4-11,0.4-26.9-10.5-33.3c-1.8-0.8-3.2-1.8-4.3-3.5
	c-4.7-7.6-13.9-9.8-21.4-13.5c-3.5-1.5-7.1-2.4-11-2.5c-3-0.1-3.4-0.7-3.2-3.7c0.4-11.5-2.9-22.9-6.1-33.8
	c-2.2-9.8-3.9-19.4-10.7-27.2c-18.2-21.5-47.6-33.4-75.6-31.8c-14.8,1.4-28.1,7.4-41.7,13c-7.9-15.7-14.6-34.5-31.6-42.9
	c-18.4-6.2-42.2-5-57.6,7.6c-9.5-14.3-23.2-20-39.5-20c-5.1-10.4-7.6-21.8-15.8-30.1c-9.9-8.5-29.6-20.4-45.6-24.5v0.6
	c-2.7-0.5-5.3-0.9-8-1.3c-16.7-1.4-45,14.9-57.7,25.8c-8.2,8.3-10.6,19.6-15.8,30c-16.2,0-29.9,5.7-39.4,20
	c-15.4-12.6-39.1-13.8-57.5-7.6c-17,8.3-23.7,27.1-31.5,42.8c-13.6-5.6-26.8-11.6-41.6-13c-27.9-1.7-57.3,10.2-75.5,31.7
	c-6.7,7.8-8.4,17.4-10.6,27.2c-3.2,10.9-6.5,22.3-6.1,33.7c0.2,3.1-0.2,3.6-3.2,3.7c-3.8,0.1-7.5,1-10.9,2.5
	c-7.6,3.6-16.7,5.9-21.4,13.4c-1,1.7-2.5,2.7-4.2,3.5c-10.9,6.3-10,22.2-10.5,33.2c-31.7-20.1-83.3-16.2-100.7,20.7
	c-27.2-11.5-64.3-12.7-81.8,15.3v1c-6.3,8.8-10.3,18.9-14.1,28.9c-18.8-7.4-43.9,3.9-46.9,24.7c-15.3-6.6-32.9-12.5-49.5-7.5
	c-2.4-5.7-6.4-10.3-10.1-14.9c-6.3-10.7-20.8-13.7-32-17.1c-22.5-6.8-47.1,1.7-64.5,16.5c-1,1.4-2.4,2.3-3.9,3
	c-4.2,5.7-8,12.1-10.8,18.7c-3.7,7.7-2.7,16.9-5.6,24.5c-5-2.5-10.4-4.5-15.5-6.3c-7.8-5.7-18.8-6.1-28.2-6.1c0,0,0,0,0,0
	c-16-1.1-30.8,8.4-40.6,20.3c-17.8-13-47.2-7.5-55.4,13.8c-2.8-21.1-13.1-38.7-32.9-48c-4.2-2.1-8.8-3.6-13.1-5.2
	c-7.8-5.7-18.8-6.1-28.2-6c0,0,0,0,0,0c-6.4-0.5-12.8,0.9-18.7,3.4c-7.7-8.3-16.9-15.6-28.2-18.2c-6.5-4.9-15.6-5.4-23.6-5.3
	c0,0,0,0,0,0c-11.2-0.8-21.7,4.9-29.6,12.5c-19.3-20.7-56.7-13.7-65.2,14.1c-8.6-9.3-21.9-12.7-34.3-13c-0.1-5,2-11-1.6-15.2
	c-0.5-4.2-0.4-8.9-1.5-13.2c-0.6-0.7-0.2-1.3,0.3-1.9c-14.8-31.5-34.8-48.8-71.1-50.6v0.6c-0.8-0.2-1.5-0.4-2.3-0.5
	c-13.5,0.3-27.1-3-40.2,1.1c-7.2,2.3-14.6,4-21.8,5.8c-3.8,1.2-7.5,5.2-11.6,4.6c-3.8,1.3-6.1,6-9.3,8.5c-5.8,5.5-15.7,7.1-18.8,15
	c-4.2,4.8-7.9,17.8-16.3,10.8c-16.1-12.3-38-13.5-57.9-9.7c-13.7,2.1-23.9,10.9-35,17.7c-5,3.2-9.8,6.4-13.1,11.3
	c-6,7.5-4.6,17.4-10,24.8c-21.1-5.7-53.2,0.8-69.6,13.8c-10,10.6-17.3,24.4-18.9,38.4c-1.7,5.2,1.6,13.1-4.5,16
	c-20.8-16.9-51.6-11.1-70.8,4.8c-13.8-5.7-29.1-10.3-43.2-2.6c-9.4,3.6-21.1,7-24,17c-12.9-1-27.1-3.6-38.7,3.1
	c-22.5,9.8-32.6,28.2-33.1,50.2c-14.5,8.7-25.4,23-25.3,39.2c-28.4-6.9-56.5,3.4-69,28c-22.2-8.2-36.8-2.6-51.8,13.6
	c-11.3-7.2-23.2-12.5-35.4-13.8l0.1-0.5c-1.4,0.1-2.9,0.1-4.3,0.2c-3-0.1-6.1,0.1-9.1,0.5c-8.7,0.7-17.3,1.9-25.6,4.4
	c-12.4,6.9-26.2,11.3-38.5,18.2c-5,2.2-9.8-6.8-15.9-6.2c-6.2,0.1-12.4-2.6-18.5-0.9c-1.2,0.4-2.1,0.1-2.7-0.8
	c-14.6-28.8-56.6-19.1-76.5-0.3c-27.2-10.2-40.6-13-68.4-0.8c-7.4,4-17.1,7.4-21.3,14.5c-3.9,7.4-10.1,13.5-13.8,21.1
	c-1.4,2.6-3.3,3.2-6.1,2.8c-5.5-0.9-10.2,2.5-15.3,3.7c-6.8,1.6-13.3,4.3-18.4,8.7c-1.4-0.7-1.8-1.5-2.5-2.6
	c-3-5.8-8.4-9.1-14.4-11.9c-10.3-4.2-20.9-8.4-32.3-7.8c-9.8,0.8-19.9,1.3-28.6,5.8c-1.1,0.5-1.8,0.5-2.9-0.1
	c-23.1-15.1-59-14.8-77.2,6.4c-11.3-11.6-33.3-4.5-41,7.5c-1.9,2.9-2.3,3.4-6,2.9c-15.8-2.2-27.8,7.8-38.1,17
	c-1.5,0.9-2.3,2.7-3.4,4.1v1.2H22.5v0h1300.1v0.7h730.7h31.6H2560C2558,1162.9,2500.2,1149.7,2484.6,1185.4z`,
})``;

export const BottomCloud = styled.path.attrs({
  d: `M2543,1191.3c-13.2-7.6-29.3,2.7-40.3-9.8c-28.9-16.3-50.1,4.9-55.4-3.4
	c-5.6-12.5-17.5-19.7-24.5-29.4c-7.6-15.4-21.3-23.8-36.2-25.8c0.4,0.1,0.8,0.3,1.3,0.6c-1.8,0.6-4.5,0.2-5.4-0.9c0.4,0,0.8,0,1.2,0
	c-8.1-0.6-16.5,0.5-24.4,3.5c-34.3-19.5-36.4,7.1-58-0.2c-31.6-15.6-41.5,16.3-53.1,5.8c-5.9-33.1-53.9-47.8-75-19.1
	c-11.2,1.6-22.3-10.1-34.6-5.3c-31.4,4.3-11.8-17.1-66.6-4.3c-3.8-9.2-9.1-20.5-19.4-22.5c-2.6-0.6-2.6-0.6-3-3.3
	c-4.5-15.7-20.5-26.9-35-31.3c-11.9-3.1-24.5,1.6-36,1.7c-16-5.8-32.4,1.3-48,2.2c-12.1-5.9-26.4-5.1-38.3,1c-8.5-4.1-18.6-2-25.2,5
	c-2.7,3.8-6.7-2.5-10.7-2.9c-14.8-5.4-28.9,4.3-39.1,14.6c-9.7-31.3-49.7-36.8-69.5-12.2c-3.5,4.5-4.9,10.5-6.1,16.1
	c-12.6-2.9-22.7,18.8-31.9,5.8c-6.9-4.8-17.1-5-23.9,0.3c-9.2-27.3-35.7-37.8-61.5-38.1c-17.6,1.9-26.5,14.2-42.5-1.4
	c-26.6-20.1-66.7-19.1-90.7,4.7c-12.4,13.2-4.3-30.1-48.8-39.1c-7-3.1-1.6-12.2-3.1-18c-3.6-20-13.7-38.7-27.6-52.9
	c-13.3-14.9-33.9-20-52.7-20.8c-11.1-1.7-26.1,6.5-34.8-2.1c-8.7-18.5-26.7-27.8-44.3-34.9c-6.9-3-23,9-27.7,14.3
	c-2.9,4.5-8.1,7-9.6,12.4c-0.3,0.9-1.2,1.6-1.8,2.4c-5.1,4.7-7.8,10.5-11.2,16.6c-17.3-1.9-36.1-6.3-53.2-1
	c-15.7,4.8-30.5,13.8-39.4,28c-5.1,9.2-8.3,19.2-12.1,29c-4.4,9.1-3.9,27.8-13.7,32c-47.4,16.1-24.2,43.2-48.4,23
	c-13.2-7.1-13.8-7.9-29-7.9c-18.1,2.9-36.3,5.7-52.6,14.4c-4,1.4-9.8,7.8-13.6,6.6c-8.9-5.3-20-3.5-29.9-5.3
	c-12.2-2.4-24.9-1.3-35.3,5.9c-4.9,6.2-18.3,13.1-19.1,20.3c0,1.9-1.1,3-1.9,4.5c-7.4,12.2-19.1-4.6-40.2,11.1
	c-11.5,8.3-17.2,15.2-20.3,29.2c-5,5.6-12.6,7.8-16.8,14.3c-13.4,9.4-23.1,22.1-27.6,38c-22.6-18.9-59.2-21.5-81.5-0.8
	c-4.2-4.4-9.7-8.7-15.9-9.6c-2,0-4.7-0.8-5.8-2.2c-5.3-7.2-6.4-16.5-11.4-23.9c-3.8-5.3-5.9-12.5-11.9-15.5
	c-15-9.1-32.5-19.9-50.7-16.5c-10.8,1.5-22.6,0.3-31.8,7.1c-6,2.4-12.9-3.9-19.4-4.3c-11.6-3.1-22.7,4-33,7.8
	c-5.6-19.7-15.5-33.4-32.9-44.4c-17.2-12.2-30.2-14.4-50.9-11.9c-6.6,0.9-10.7-13.6-27.2-18c-16.5-4.8-35.6-12.1-50.9-0.4
	c-1.9,1.3-3.5,1.5-5.3,0.4c-12.5-8.6-31.8-16.2-45-5.1c-4.6,5-5.8,11.6-10.4,16.8c-7.3-14.3-24.5-17.4-38.8-18.9
	c-0.9-6.5,1.2-13.2,0.2-19.7c-2.1-9.6-4-20.8-11-28.2c-11.2-13-23.7-24.4-38.3-33.5c-7.1-4.3-15.3-5.7-23.5-6.9
	c-12-1.4-24.3-3.1-36.2-0.7c-9.7-0.7-17.5,6.7-27,7.4c-6.3-13.3-37.2-15.9-48.3-7.3c-4.2,2.8-8.2,6-12.2,9.2c-3.4,2.7-3.3,2.8-6.6,0
	c-11.9-13.8-33.5-21.6-51-15.2c-17.6,5.9-35.5,13.7-44.3,29.1c-8.7,7.1-23.7,0.3-34.8,1.7c-40.6-1.2-73.2,27.9-80.3,61.6
	c-1.5,4.9,3.9,12.5-3.1,15.1c-44.5,7.5-36.4,43.7-48.8,32.6c-24-19.8-64.2-20.7-90.7-3.9c-16,13-24.9,2.7-42.5,1.1
	c-25.8,0.2-52.3,9.1-61.5,31.9c-6.8-4.5-17.1-4.3-23.9-0.3c-9.3,10.9-19.3-7.3-31.9-4.8c-5.9-33.5-65.1-35.1-75.6-3.3
	c-10.2-8.6-24.3-16.7-39.1-12.2c-3.9,0.3-8,5.6-10.7,2.4c-6.5-5.8-16.7-7.6-25.2-4.2c-11.9-5.1-26.2-5.8-38.3-0.9
	c-15.5-0.7-32-6.6-48-1.8c-11.5,0-24.1-4-36-1.4c-14.5,3.7-30.6,13-35,26.2c-0.4,2.2-0.4,2.3-3,2.8c-10.3,1.7-15.6,11.1-19.4,18.8
	c-54.8-10.7-35.2,7.2-66.6,3.6c-12.3-4-23.4,5.8-34.6,4.4c-21-24-69.1-11.7-75,15.9c-11.6,8.8-21.5-17.9-53.1-4.9
	c-21.6,6.1-23.7-16.1-58,0.1c-7.9-2.5-16.3-3.5-24.4-2.9c0.4,0,0.8,0,1.2,0c-0.9,0.9-3.6,1.2-5.4,0.7c0.4-0.2,0.8-0.4,1.3-0.5
	c-14.9,1.7-28.6,8.7-36.2,21.6c-7,8.1-18.9,14.1-24.5,24.6c-5.3,7-26.5-10.8-55.4,2.9c-11,10.5-27.1,1.8-40.3,8.2l-17,7.6l1280,0.1
	v0c7.6,0,1273.3,0,1280,0l1280-0.1L2543,1191.3z`,
})`
  fill: ${theme.colors.white};
`;

export const RightSquare = styled(ColoredPath).attrs({
  d: `M1122.4,329.5c-1.6-3.7-1.6-7.5-1.1-11.4c0.3-2.4,0.8-4.8,1.4-7.2c0.1-0.5,0.8-1.1,1.3-1.1
	c4.2-0.4,8.3-0.7,12.5-0.8c0.6,0,1.2,1.1,1.8,1.7c0,0,0,0.2,0,0.2c-0.2,6.2-0.3,12.4-0.5,18.5
	C1132.7,329.5,1127.6,329.5,1122.4,329.5z`,
})``;
export const RightDot1 = styled(ColoredPath).attrs({
  d: `M1233.8,44c4.8,0,8.5,3.4,8.6,7.9c0.1,4.5-3.8,8.5-8.3,8.5c-4.4,0-8-3.5-8-7.8
	C1226,47.4,1229.2,44,1233.8,44z M1234.6,54.2c0.7-1,1.3-1.7,1.2-2.2c-0.1-0.6-0.9-1.1-1.4-1.6c-0.5,0.5-1.3,1-1.3,1.5
	C1233.2,52.5,1233.9,53.1,1234.6,54.2z`,
})``;
export const RightDot2 = styled(ColoredPath).attrs({
  d: `M1132.1,165.6c-2.6,0-5.4-2.9-5.4-5.5c0-2.6,2.9-5.3,5.7-5.2c3.2,0.1,5.9,2.8,5.8,5.7
	C1138,163.4,1135.3,165.7,1132.1,165.6z M1132.6,157.1c-1.2,1.3-2.6,2.2-2.5,2.7c0.3,1.1,1.4,1.9,2.1,2.8c0.8-0.7,2.2-1.4,2.4-2.2
	C1134.7,159.6,1133.5,158.6,1132.6,157.1z`,
})``;
export const RightDot3 = styled(ColoredPath).attrs({
  d: `M1214,277.7c8.8-1,7.2-15.6-1.3-14.7C1201.7,262,1202.8,279.6,1214,277.7z`,
})``;
export const RightDot4 = styled(ColoredPath).attrs({
  d: `M1264.3,460.6c0,3.1-2.2,5.4-5.2,5.4c-2.8,0-5-2-5-4.8c-0.1-3.2,2.5-6.2,5.2-6.2C1262.2,455,1264.3,457.3,1264.3,460.6z`,
})``;
export const RightRing1 = styled(ColoredPath).attrs({
  d: `M1232.7,329c11.5-0.1,19.5,9.7,19.1,20c-0.3,7.4-8.3,16.9-18.1,16.1
	c-10.4-0.8-18.1-7.6-17.6-18.6C1216.5,336.6,1223.9,328.7,1232.7,329z M1234,359.4c6.4,0.1,12-5.5,12.1-11.2
	c0.1-7.1-5.8-13.1-12.9-13.2c-5.1-0.1-11.5,7.3-11.9,13.1C1221.1,353.7,1229,359.5,1234,359.4z`,
})``;
export const RightRing2 = styled(ColoredPath).attrs({
  d: `M1267,410.8c-7.3,0-13.1-5.7-13-12.7c0.1-7.9,5.4-13.2,13.2-13.1c7.3,0.1,12.8,5.9,12.6,13.5
	C1279.7,405.3,1274,410.8,1267,410.8z M1260.2,397.9c-0.1,3.7,2.7,6.6,6.6,6.6c4,0,6.9-2.7,7-6.6c0.1-3.7-3-6.8-6.8-6.8
	C1263.3,391.1,1260.2,394.2,1260.2,397.9z`,
})``;
export const RightLine = styled(ColoredPath).attrs({
  d: `M1195.1,444.5c-0.6-0.3-1.3-0.5-1.3-0.8c-0.1-0.6,0.1-1.3,0.4-1.7c2.1-2.2,4.3-4.4,6.6-6.5
	c3.9-3.6,7.9-7.2,11.9-10.8c0.2-0.2,0.5-0.5,0.8-0.6c0.7-0.2,1.5-0.2,2.3-0.3c-0.1,0.7,0.1,1.5-0.2,2c-0.4,0.8-1,1.5-1.7,2.1
	c-5.5,5.1-11.1,10.1-16.7,15.2C1196.5,443.6,1195.8,444,1195.1,444.5z`,
})``;
export const CenterDot = styled(ColoredPath).attrs({
  d: `M722.2,218.8c0-5.3,3.7-7.7,6.7-7.7c3.2,0,7.6,4,7.5,6.8c-0.2,3.8-3.7,7.7-7,7.5
	C726.2,225.2,721.3,222.5,722.2,218.8z M728.7,221.1c0.4,0,0.8,0,1.2,0c0.5-0.9,1.6-1.9,1.5-2.8c-0.1-0.8-1.3-1.6-2-2.3
	c-0.8,0.8-2.1,1.5-2.3,2.4C726.9,219.1,728.1,220.1,728.7,221.1z`,
})``;
export const LeftDot1 = styled(ColoredPath).attrs({
  d: `M13.8,373.2c0.1-5.5,2.7-8.1,8-8c4.1,0.1,7.1,3.4,7,7.8c-0.1,4.6-2.1,7.1-7.5,7.3C16.9,380.5,13.7,377.2,13.8,373.2z`,
})``;
export const LeftDot2 = styled(ColoredPath).attrs({
  d: `M32,642c1.5,0.1,2.7,0.7,2.8,2.6c0.1,1.8-1.2,3.3-3,3.3c-1.8,0-2.7-1-2.9-2.8C28.9,643.3,30.1,642.1,32,642z`,
})``;
export const LeftDot3 = styled(ColoredPath).attrs({
  d: `M14.5,674c4-7.4,12.9,0.8,9.4,6.8C20,688.3,11.8,679.9,14.5,674z`,
})``;
export const LeftDot4 = styled(ColoredPath).attrs({
  d: `M56.3,689.6c0,3.1-2.2,5.4-5.2,5.4c-2.8,0-5-2-5-4.8c-0.1-3.2,2.5-6.2,5.2-6.2C54.2,684,56.3,686.3,56.3,689.6z`,
})``;
export const LeftDot5 = styled(ColoredPath).attrs({
  d: `M66.8,749.3c2.7,0.1,4.8,2.4,4.8,5.1c-0.1,2.2-3.1,5.2-5.3,5.1c-2.5-0.1-4.5-2.4-4.4-5.1C62,751.4,64.1,749.2,66.8,749.3z`,
})``;
export const LeftRing = styled(ColoredPath).attrs({
  d: `M50.4,565c8.8,0,17,8.4,16.9,17.4c0,9.4-8.7,17.8-18.2,17.6c-7.4-0.1-15.3-6.9-15.2-19.7
	C34.1,572.8,42.4,565,50.4,565z M42.2,581.2c0.2,6.1,3.6,10.2,8.3,10.2c4.8-0.1,9.1-4.4,8.9-8.9c-0.2-6-4.6-8.4-9.4-8.9
	C46.3,573.1,42.1,577.5,42.2,581.2z`,
})``;
export const TopLine = styled(ColoredPath).attrs({
  d: `M771.3,60.1c-1.5-0.8-2.6-1.4-3.7-2c-0.9-0.6-1.7-1.3-2.6-1.9c-2-1.3-4-2.7-6.1-3.8
	c-4.2-2.3-4.1-6.2-3.5-10c0.3-2,0-3.4-0.7-5.2c-0.7-1.8-0.8-3.8-0.8-5.8c0-0.4,1.1-0.9,1.7-1.3c0.3,0.5,1,1.3,0.8,1.5
	c-1.6,2.4,0.6,4.2,0.9,6.3c0.2,1.5,0.7,2.9,0.8,4.3c0.1,3.5,1.4,5.9,4.7,7.7c3.5,1.8,6.6,4.4,9.8,6.8c1,0.8,1.2,1.8-0.1,2.6
	C772,59.5,771.5,59.9,771.3,60.1z`,
})``;
export const TopDot1 = styled(ColoredPath).attrs({
  d: `M617,52.9c0.8-5.9,3.3-8.5,7.4-7.8c1.8,0.3,4.1,3.1,4.5,5.1c0.6,3.3-3.9,7.6-6.7,6.6
	C620,56,618.3,53.9,617,52.9z`,
})``;
export const TopDot2 = styled(ColoredPath).attrs({
  d: `M715,42.4c-3.5,0-6-2.3-6-5.4c0-3.7,3.3-7.1,6.8-7c2.9,0.1,6,2.9,6,5.5
	C721.8,40.3,719.7,42.5,715,42.4z M717.9,37c0-0.3,0-0.6,0-0.9c-0.6-0.4-1.4-1.4-1.9-1.2c-1,0.3-1.8,1.2-2.6,1.8
	c0.6,0.6,1.2,1.7,1.7,1.6C716.1,38.2,717,37.5,717.9,37z`,
})``;
export const LeftTopDot = styled(ColoredPath).attrs({
  d: `M50.5,69.3c0.9,11.9-13.3,9.7-18.2,3.3C29.6,58.3,48.7,54.8,50.5,69.3z`,
})``;
export const Planet = styled(ColoredPath).attrs({
  d: `M1284.4,128.4c12.3-1.2,22.3,3.7,31.2,11.6c8.7,7.7,17.1,15.9,20.6,27.3c1.7,5.6,1.7,11.8,1.7,17.7
	c0,4.9-1,9.8-1.7,14.6c-1.1,8.2-6.9,13.7-11,20.3c-5.2,8.2-13.1,13.2-20.3,19c-1.3,1.1-3.2,1.6-4.9,2.2
	c-22.9,7.8-42.5,1.1-60.2-13.8c-9.8-8.2-15.1-18.8-17.2-31c-2.6-14.6,0.8-27.9,8.6-40.8c4.9-8.1,11.5-13.9,18.5-19.7
	c5.7-4.7,12.5-6.6,19.6-7.3C1274.3,128,1279.4,128.4,1284.4,128.4z M1227.6,198.1c2.6-1.8,4.8-3.2,6.8-4.8c5-3.9,9.9-7.9,14.9-11.8
	c1.8-1.4,3.5-2.9,5.4-4.2c0.9-0.6,2.2-0.7,3.3-1.1c-0.3,1.2-0.3,2.9-1.1,3.6c-2.6,2.5-5.4,4.7-8.2,7.1c-5.8,5.1-11.8,10.2-17.4,15.5
	c-0.9,0.9-1.6,3.3-1,4.3c2.8,4.8,5.5,9.9,9.4,13.9c4.5,4.6,10.1,8.4,15.4,12.2c2.1,1.5,4.7,2.6,7.2,3c1.1,0.2,2.6-2,3.8-3.2
	c7.3-6.8,14.5-13.8,22-20.5c8.1-7.3,16.3-14.4,24.6-21.5c1.8-1.5,4.1-2.5,6.4-3.9c0.6,3.7-4,8.1-11.5,13.6c-4.8,3.5-8.8,8-13.1,12.1
	c-4.4,4.2-8.9,8.3-13.3,12.6c-4.3,4.2-8.5,8.4-13.1,13c8.3,2.1,15.8,2.6,23.9,1c10.4-2.1,18.4-7.7,25.2-14.8
	c5.3-5.6,9.2-12.8,12.9-19.7c4.5-8.4,5.2-17.9,3.8-27.2c-1.6-10.5-6.5-19.6-13.9-27.4c-5.4-5.7-6-5.9-12-0.5c-0.7,0.6-1.3,1.4-2.1,2
	c-1.1,0.8-2.2,1.7-3.4,2c-0.8,0.2-1.9-0.5-2.8-0.7c0.4-0.9,0.6-2,1.3-2.6c2.1-1.8,4.4-3.3,6.5-5c3.4-2.8,3.2-4.8-0.8-6.8
	c-3.6-1.8-7.5-3.2-11.3-4.6c-3.4-1.2-6.9-1.4-9.8,1.1c-6.4,5.5-12.7,11.2-19,16.8c-2.3,2.1-4.6,4.2-7,6.2c-0.5,0.4-1.4,0.3-2.2,0.5
	c-0.1-0.8-0.5-1.8-0.2-2.3c1-1.6,2.2-3.1,3.5-4.5c6.2-6.6,12.4-13.2,18.4-19.6c-5.7-2.8-21.9,2-33.4,12.5
	C1230.2,158.4,1223.8,176.3,1227.6,198.1z`,
})``;
