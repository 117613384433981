import styled from 'styled-components';
import { Paper } from '@mui/material';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(2)} 0`,
    borderRadius: 0,
    marginTop: theme.spacing(2)
  }
}));
