import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import omitStyled from '../../libs/omitStyled';
import { InputContainer } from '../Input';
import theme from '@matterapp/matter-theme';

const BACKSPACE_KEYCODE = 8;

const INPUT_TYPES = {
  NUMBER: 'number',
  PASSWORD: 'password',
  PHONE: 'tel',
  TEXT: 'text',
};

const InputCodeInput = styled.input`
  width: 47px;
  height: 64px;
  padding: 0;
  font-size: ${theme.fontSize.XL};
  min-width: auto !important;
  ${({ readOnly }) => readOnly ? `background-color: ${theme.colors.white};` : ''}
`;

const InputCodeContainer = omitStyled(InputContainer, ['spaceAfterNthElement'])`
  display: inline-block;
  text-align: center;

  ${InputCodeInput} {
    text-align: center;
    border-width: 1.5px;
    margin-right: ${theme.spacing.quarter};
    ${({ error }) => error && `
      color: ${theme.inputs.errorTextColor};
      border-color: ${theme.inputs.errorBorderColor} !important;
      background-color: ${theme.inputs.errorBackgroundColor};
      &::placeholder {
        color: ${theme.inputs.errorPlaceholderColor};
      }
    `};
  }

  ${InputCodeInput}:nth-child(${({ spaceAfterNthElement }) =>
  spaceAfterNthElement}n + 0) {
    margin-right: 12px !important;
  }

  ${InputCodeInput}:last-child {
    margin-right: 0 !important;
  }

  ${({ applyVerifyEmailViaCode }) =>
    applyVerifyEmailViaCode &&
    `
    input {
      width:  ${theme.spacing.quad} !important;
      border: 1px solid ${theme.colors.blacks[10]} !important;
      font-size: ${theme.fontSize.S} !important;
      line-height: ${theme.lineHeight.M} !important;
      font-weight: ${theme.fontWeight.bold};
      &:focus {
        box-shadow: none !important;
      }
    }
  `}
`;

const updateTargetValue = (list = [], targetIndex = 0, targetValue = '') => {
  const targetLength = targetValue.length;
  let currentSubIndex = 0;
  return list.map((value, index) => {
    if (index === targetIndex && targetLength < 2) {
      return targetValue;
    } else if (
      targetLength > 1 &&
      currentSubIndex < targetLength &&
      index === targetIndex + currentSubIndex
    ) {
      const valueToReturn = targetValue.charAt(currentSubIndex);
      currentSubIndex += 1;
      return valueToReturn;
    }
    return value;
  });
};

export default class ReactCodeInput extends Component {
  static propTypes = {
    autofocus: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    disabled: PropTypes.bool,
    inputStyle: PropTypes.shape({}),
    inputStyleInvalid: PropTypes.shape({}),
    isValid: PropTypes.bool,
    marginBottom: PropTypes.number,
    name: PropTypes.string,
    numberOfFields: PropTypes.number,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    spaceAfterNthElement: PropTypes.number,
    style: PropTypes.shape({}),
    touch: PropTypes.func,
    type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
    untouch: PropTypes.func,
    value: PropTypes.string,
    onBlurComplete: PropTypes.bool,
  };

  static defaultProps = {
    autofocus: false,
    className: null,
    disabled: false,
    inputStyle: {},
    inputStyleInvalid: { borderColor: '#FF4379' },
    isValid: true,
    marginBottom: 0,
    name: 'code-input-default-prop-name',
    numberOfFields: 5,
    value: '',
    style: {},
    spaceAfterNthElement: 0,
    type: INPUT_TYPES.TEXT,
    touch: () => {},
    onChange: () => {},
    untouch: () => {},
    onBlurComplete: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      input: this.getInput(),
    };
  }

  static Input = InputCodeInput;
  static InputTypes = INPUT_TYPES;

  getInput = (renderProps) => {
    const { numberOfFields } = this.props;
    const value = this.state ? this.state.value : '';
    const updatedInput = [];
    for (let i = 0; i < Math.min(Number(numberOfFields), 32); i += 1) {
      updatedInput.push([...value][i] || '');
    }
    return updatedInput;
  };

  componentDidMount() {
    if (this.inputRefs[0] && this.props.autofocus) {
      this.focusTimeout = setTimeout(() => {
        this.inputRefs[0].focus();
      }, 100);
    }
  }

  componentDidUpdate(prevProps) {
    const { numberOfFields, value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ value });
    }
    if (prevProps.numberOfFields !== numberOfFields) {
      this.setState({ input: this.getInput() });
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.focusTimeout);
  };

  handleBackspace = (e) => {
    e.preventDefault();

    const targetNo = Number(e.target.dataset.inputno);
    const prevTarget = this.inputRefs[targetNo - 1];
    this.inputRefs[targetNo].value = '';

    const input = updateTargetValue(this.state.input, targetNo, '');
    const value = input.join('');

    this.setState({ value, input });
    if (this.inputRefs[targetNo].value === '') {
      if (prevTarget) {
        prevTarget.focus();
        prevTarget.select();
      }
    }
    this.props.onChange(value);
    this.handleTouch(value);
  };

  handleKeyDown = (e) => {
    if (e.keyCode === BACKSPACE_KEYCODE) {
      this.handleBackspace(e);
    }
  };

  handleChange = (e) => {
    const targetNo = Number(e.target.dataset.inputno);
    let value = String(e.target.value);

    if (value !== '') {
      if (this.props.type === INPUT_TYPES.NUMBER) {
        if (!value.match(/(\d)/g)) {
          return;
        }
        if (value.length > 1) {
          this.inputRefs[targetNo].value = value.slice(-1);
        }
      }

      const targetValue = this.inputRefs[targetNo].value;
      const input = updateTargetValue(this.state.input, targetNo, targetValue);
      value = input.join('');

      const vLength = value.length;
      if (vLength > 1) {
        value.split('').forEach((s, i) => {
          if (this.inputRefs[i]) {
            this.inputRefs[i].value = s;
          }
        });
      }

      const newTargetIndex = input.length === vLength ? vLength - 1 : vLength;
      const newTarget = this.inputRefs[newTargetIndex];

      if (newTarget) {
        if (input.length === vLength && this.props.onBlurComplete) {
          this.inputRefs[targetNo].blur();
          newTarget.blur();
        } else {
          newTarget.focus();
          newTarget.select();
        }
      }

      this.setState({ value, input });
    }
    if (value && 'onChange' in this.props) {
      this.props.onChange(value);
    }
    this.handleTouch(value);
  };

  handleTouch = (value) => {
    const { touch, untouch, name } = this.props;
    if (typeof touch === 'function' && typeof untouch === 'function') {
      if (value === '') {
        touch(name);
      } else {
        untouch(name);
      }
    }
  };

  handleBlur = (e) => {
    this.handleTouch(e.target.value);
  };

  render() {
    const {
      children,
      className,
      disabled,
      style,
      inputStyle,
      inputStyleInvalid,
      isValid,
      marginBottom,
      numberOfFields,
      readOnly,
      spaceAfterNthElement,
      type,
      applyVerifyEmailViaCode,
      errorMessage,
    } = this.props;

    this.inputRefs = [];

    return (
      <InputCodeContainer
        className={className}
        style={{ ...style, marginBottom }}
        spaceAfterNthElement={spaceAfterNthElement}
        applyVerifyEmailViaCode={applyVerifyEmailViaCode}
        error={!!errorMessage}
      >
        {this.state.input.map((value, i) => (
          <InputCodeInput
            ref={(curInput) => {
              if (curInput) {
                this.inputRefs.push(curInput);
              }
            }}
            value={value}
            key={`input_${i}`}
            type={type}
            min={0}
            max={9}
            maxLength={numberOfFields}
            style={isValid ? inputStyle : inputStyleInvalid}
            autoComplete="new-password"
            onFocus={(e) => e.target.select(e)}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            disabled={disabled || readOnly}
            readOnly={readOnly}
            data-valid={isValid}
            data-inputno={i}
            data-label={applyVerifyEmailViaCode ? 'verify-email-via-code' : null}
          />
        ))}
        {children}
      </InputCodeContainer>
    );
  }
}
