import { useEffect, useState } from 'react';

export const useProfileMenuState = ({ activeProfileTab = null }) => {
  const [isProfileMenuOpen, setProfileMenuOpenState] = useState(
    activeProfileTab !== null
  );
  const [isMobileProfileMenuOpen, setMobileProfileMenuOpenState] = useState(
    false
  );

  useEffect(() => {
    // On desktop, keep profile menu open if navigating to a profile / settings page.
    if (activeProfileTab !== null) {
      setProfileMenuOpenState(true);
      setMobileProfileMenuOpenState(false);
    }
  }, [activeProfileTab]);

  return {
    isProfileMenuOpen,
    isMobileProfileMenuOpen,
    setProfileMenuOpen: setProfileMenuOpenState,
    onCloseMobileProfileMenu: () => setMobileProfileMenuOpenState(false),
    onCloseProfileMenu: () => setProfileMenuOpenState(false),
    onOpenMobileProfileMenu: () => setMobileProfileMenuOpenState(true),
    onOpenProfileMenu: () => setProfileMenuOpenState(true),
  };
};
