import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AvatarCard from '../AvatarCard';
import Button from '../Button';
import { Clickable } from '../Clickable/Clickable';
import { IconNew } from '../Icon';
import { Modal } from '../Modal';
import { QualityPill } from '../Quality';
import theme from '@matterapp/matter-theme';

const REPLACE_AMOUNT = '%a';
const FEEDBACK_HEADER = 'Your Feedback:';
const FEEDBACK_LABEL = 'Feedback';
const DEFAULT_QUALITIES_OVERFLOW_AMOUNT = 6;
const QUALITIES_HEADER = 'Your Qualities';
const QUALITIES_OVERFLOW_LABEL = `See All (${REPLACE_AMOUNT})`;
const RC_LABEL = 'avatar-card-profile';

const LinkIcon = styled(IconNew).attrs({
  name: 'internalLink',
})`
  display: none;
  margin: -6px 0;
  & path {
    fill: currentColor;
  }

  ${theme.media.M`
    display: inline;
  `}
`;

const HeadlineLabel = styled.span`
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.fontSize.S};
`;

const FeedbackCountContainer = styled.div`
  width: 100%;
  margin-top: ${theme.spacing.single};
`;
const FeedbackCountLabel = styled.span`
  color: ${theme.colors.blacks[50]};
`;
const FeedbackCountKey = styled.div`
  color: ${theme.colors.blacks[50]};
  text-transform: capitalize;
  font-weight: ${theme.fontWeight.regular};
`;
const FeedbackCountValue = styled.div`
  color: ${theme.colors.blacks[60]};
  font-weight: ${theme.fontWeight.bold};
`;
const FeedbackCountRow = styled(Clickable)`
  display: flex;
  line-height: 1;
  ${({ onClick }) =>
    onClick
      ? `
    cursor: pointer;
    &:hover ${FeedbackCountKey},
    &:hover ${FeedbackCountLabel}, ${FeedbackCountValue} {
      color: ${theme.colors.blues[50]};
    }
    &:active {
      text-decoration: none;
    }
  `
      : `
    cursor: default;
  `}
`;
const FeedbackCountHeader = styled.div`
  display: none;
  color: ${theme.colors.blacks[50]};
  font-weight: ${theme.fontWeight.bold};
`;

const avatarStylesCompact = `
  align-items: flex-start;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  & ${AvatarCard.AvatarContainer} {
    height: 100%;
    margin: 0;
    padding: ${theme.spacing.singleAndHalf};
  }
  & ${AvatarCard.Header} {
    margin: 0 0 -${theme.spacing.half};
    padding: 0 ${theme.spacing.singleAndHalf};
    font-size: ${theme.fontSize.S};
  }
  & ${AvatarCard.Body} {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;

    ${FeedbackCountHeader} {
      display: block;
      font-size: ${theme.fontSize.base};
      padding: 0 ${theme.spacing.singleAndHalf};
      margin: ${theme.spacing.singleAndHalf} 0 calc(-${theme.spacing.singleAndHalf} + ${theme.spacing.quarter});
    }
    ${FeedbackCountContainer} {
      padding: ${theme.spacing.singleAndHalf};
      font-size: ${theme.fontSize.base};
      border-bottom: 1px solid ${theme.colors.blacks[10]};
      margin-top: 0;
    }
    ${HeadlineLabel} {
      padding: 0 ${theme.spacing.singleAndHalf};
      font-size: ${theme.fontSize.base};
    }
  }
`;

const getContainerStylesMobile = ({ isCompact }) => {
  if (isCompact) {
    return `
      display: flex;
      ${avatarStylesCompact}
      &:before {
        display: none;
      }
      & ${AvatarCard.Body} {
        text-align: left;
      }
      ${FeedbackCountContainer} {
        display: flex;
        ${FeedbackCountRow} {
          margin: 0 ${theme.spacing.single} 0 0;
          ${FeedbackCountLabel} {
            display: none;
          }
          ${FeedbackCountKey}:after {
            content: ':';
          }
          ${FeedbackCountKey} {
            margin: 0 ${theme.spacing.half} 0 0;
          }
        }
      }
    `;
  }
  return `
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    & ${AvatarCard.Body} {
      margin: -${theme.spacing.single} 0 0;
      width: 100%;
    }
    & ${AvatarCard.Header} {
      margin-left: ${theme.spacing.single};
      margin-right: ${theme.spacing.single};
      word-break: break-all;
    }
    ${FeedbackCountContainer} {
      display: block;
      ${FeedbackCountRow} {
        width: 100%;
        border-top: 1px solid ${theme.colors.blacks[10]};
        border-bottom: 1px solid ${theme.colors.blacks[10]};
        margin: 0 0 -1px 0;
        padding: ${theme.spacing.threeQuarters} ${theme.spacing.singleAndHalf};
        ${FeedbackCountKey} {
          flex: 1 1 auto;
          margin: 0;
          text-align: left;
        }
      }
      &:last-child ${FeedbackCountRow}:last-child {
        border-bottom: none;
      }
    }
  `;
};

const ModalHeader = styled(Modal.Header)`
  & div {
    text-align: center;
  }
`;

export const StyledQualityPill = styled(QualityPill)`
  background: ${theme.colors.blacks[5]};
  border: none;
`;
export const QualitiesOverflowButton = styled(Button.Simple.Primary)`
  align-items: center;
  display: inline-flex;
  padding-left: ${theme.spacing.half};
  padding-right: ${theme.spacing.half};
  &:not(:hover):not(:active) {
    background: ${theme.colors.blacks[5]};
    color: ${theme.colors.blues[50]};
  }
`;
const QualitiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: ${({ hasChildren }) =>
    hasChildren ? `1px solid ${theme.colors.blacks[10]}` : 'none'};
  margin: 0;
  padding: ${theme.spacing.single};
  text-align: left;

  & > ${StyledQualityPill}, & > ${QualitiesOverflowButton} {
    margin: ${theme.spacing.half};
    border-radius: ${theme.borderRadius.M};
    font-size: ${theme.fontSize.base};
  }
`;

const getContainerStyles = ({ isCompact }) => {
  if (isCompact) {
    return theme.media.S`
      ${avatarStylesCompact}
    `;
  }
  return theme.media.S`
  `;
};

const Container = styled(AvatarCard)`
  ${getContainerStylesMobile}
  ${getContainerStyles}
`;

export default class MiniProfile extends React.PureComponent {
  static propTypes = {
    /** URL to go to if avatar is clicked. */
    avatarUrl: PropTypes.string,
    /** Additional style class. */
    className: PropTypes.string,
    /** Amount of feedback counts. */
    feedbackCounts: PropTypes.shape({
      given: PropTypes.number,
      pending: PropTypes.number,
      received: PropTypes.number,
    }),
    /** If mini profile should use compact layout. */
    isCompact: PropTypes.bool,
    /** Callback when pending row is clicked. */
    onClickPendingRequests: PropTypes.func,
    /** Path to go to when pending requests is clicked. */
    pendingRequestsPath: PropTypes.string,
    /** List of qualities to display. */
    qualityEndorsements: PropTypes.arrayOf(
      PropTypes.shape({
        quality: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
        numEndorsements: PropTypes.number.isRequired,
      })
    ),
    qualitiesOverflowAmount: PropTypes.number,
    /** Metadata of current user. */
    user: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string,
      headline: PropTypes.string,
      photoUrl: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    feedbackCounts: {},
    isCompact: false,
    qualityEndorsements: [],
    qualitiesOverflowAmount: DEFAULT_QUALITIES_OVERFLOW_AMOUNT,
  };

  state = {
    isQualitiesModalOpen: false,
  };

  toggleOpenQualitiesModal = () => {
    this.setState({ isQualitiesModalOpen: !this.state.isQualitiesModalOpen });
  };

  renderFeedbackCountRow = (rowProps) => {
    const { isLabelReversed, key, label, onClick, to, value } = rowProps;
    const keyLabelText = label || FEEDBACK_LABEL;
    const keyLabelValue = `${isLabelReversed ? ' ' : ''}
      ${keyLabelText}
      ${isLabelReversed ? '' : ' '}`;
    const keyLabel = <FeedbackCountLabel>{keyLabelValue}</FeedbackCountLabel>;

    return (
      <FeedbackCountRow onClick={onClick} to={to}>
        <FeedbackCountKey>
          {!isLabelReversed && keyLabel}
          {key}
          {isLabelReversed && keyLabel}
          {onClick && <LinkIcon />}
        </FeedbackCountKey>
        <FeedbackCountValue>{value || '-'}</FeedbackCountValue>
      </FeedbackCountRow>
    );
  };

  renderFeedbackCounts = () => {
    const { hidePending } = this.props;
    const { given, pending, received } = this.props.feedbackCounts;
    return (
      <>
        <FeedbackCountHeader>{FEEDBACK_HEADER}</FeedbackCountHeader>
        <FeedbackCountContainer>
          {this.renderFeedbackCountRow({ key: 'received', value: received })}
          {this.renderFeedbackCountRow({ key: 'given', value: given })}
          {hidePending ? null : this.renderFeedbackCountRow({
            key: 'pending',
            label: 'Requests',
            value: pending,
            isLabelReversed: true,
            to: this.props.pendingRequestsPath,
            onClick: this.props.onClickPendingRequests,
          })}
        </FeedbackCountContainer>
      </>
    );
  };

  renderQualitiesContainer(renderProps) {
    const { hasChildren, qualtiesOverflow, qualitiesToShow } = renderProps;
    return (
      <QualitiesWrapper hasChildren={hasChildren}>
        {qualitiesToShow.map((qualityEndorsement) => (
          <StyledQualityPill
            key={qualityEndorsement.quality.id}
            name={qualityEndorsement.quality.name}
            number={qualityEndorsement.numEndorsements}
          />
        ))}
        {qualtiesOverflow}
      </QualitiesWrapper>
    );
  }

  /**
   * Renders the qualities overflow modal
   * @param { Array } qualityEndorsements: List of qualities to show in modal.
   * @returns { React.Component } The qualities modal and toggle button.
   */
  renderQualitiesModal(qualityEndorsements) {
    const { isQualitiesModalOpen } = this.state;
    const numberOfQualities = qualityEndorsements.length;
    const closeButton = (
      <Modal.Header.Buttons.Close onClick={this.toggleOpenQualitiesModal} />
    );

    return (
      <>
        <QualitiesOverflowButton onClick={this.toggleOpenQualitiesModal}>
          {QUALITIES_OVERFLOW_LABEL.replace(REPLACE_AMOUNT, numberOfQualities)}
        </QualitiesOverflowButton>
        <Modal
          flushOnMobile
          isOpen={isQualitiesModalOpen}
          onRequestClose={this.toggleOpenQualitiesModal}
          transparent
          size="large"
        >
          <ModalHeader
            title={QUALITIES_HEADER}
            right={closeButton}
          />
          <Modal.Body>
            {this.renderQualitiesContainer({
              qualitiesToShow: qualityEndorsements,
            })}
          </Modal.Body>
        </Modal>
      </>
    );
  }

  renderQualities(renderProps) {
    const {
      children,
      qualityEndorsements,
      qualitiesOverflowAmount,
    } = renderProps;
    const numberOfQualities = qualityEndorsements.length;
    const showQualitiesOverflow = numberOfQualities > qualitiesOverflowAmount;
    const qualitiesToShow = showQualitiesOverflow
      ? qualityEndorsements.slice(0, qualitiesOverflowAmount - 1)
      : qualityEndorsements;

    if (numberOfQualities === 0) {
      return null;
    }
    const qualtiesOverflow = showQualitiesOverflow
      ? this.renderQualitiesModal(qualityEndorsements)
      : null;

    return this.renderQualitiesContainer({
      hasChildren: !!children,
      qualitiesToShow,
      qualtiesOverflow,
    });
  }

  render() {
    const {
      avatarUrl,
      children,
      className,
      isCompact,
      qualityEndorsements,
      qualitiesOverflowAmount,
      user,
    } = this.props;
    const { email, fullName, headline, photoUrl } = user;
    const avatarProps = { email, url: photoUrl, to: avatarUrl };
    return (
      <Container
        avatarProps={avatarProps}
        className={className}
        isCompact={isCompact}
        mobileCard
        title={fullName}
        rcLabel={RC_LABEL}
      >
        {headline && <HeadlineLabel>{headline}</HeadlineLabel>}
        {this.renderFeedbackCounts()}
        {this.renderQualities({
          children,
          qualityEndorsements,
          qualitiesOverflowAmount,
        })}
        {children}
      </Container>
    );
  }
}
