import React, { useState } from 'react';
import PulseFiltersContext from './PulseFiltersContext';
import { dateFilters } from 'routes/pages/pulse/dateFilters';

export default function PulseFiltersProvider({ children }) {
  const [selectedFilter, setSelectedFilter] = useState(dateFilters[0]);
  const [xAxisMaxLabels, setXAxisMaxLabels] = useState(dateFilters[0].xAxisResolution);

  return (
    <PulseFiltersContext.Provider
      value={{
        selectedFilter,
        setSelectedFilter,
        xAxisMaxLabels,
        setXAxisMaxLabels
      }}
    >
      {children}
    </PulseFiltersContext.Provider>
  );
}
