import React from 'react';
import styled from 'styled-components';
import { Form } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

export function FormGroup(props) {
  return (
    <Form.Group largerSpacing {...props} />
  );
};

const GroupHeader = styled.div`
align-items: center;
flex: 1 1 auto;
display: flex;
`;
const GroupHeaderActions = styled.div`
align-items: center;
flex: 0 1 auto;
display: flex;
`;
const GroupHeaderContainer = styled.div`
display: ${({ flexOnMobile }) => (flexOnMobile ? 'flex' : 'block')};

${({ flexOnMobile }) => {
  if (!flexOnMobile) {
    return `
      & > ${GroupHeaderActions} {
        margin-top: ${theme.spacing.single};
      }
    `;
  }
}};

${theme.media.S`
  display: flex;

  & > ${GroupHeaderActions} {
    margin-top: 0
  }
`}
`;

export function FormattedGroup(props) {
  const {
    header,
    headerActions,
    subHeader,
    subHeaderActions,
    ...otherProps
  } = props;
  const headerContent = headerActions ? (
    <GroupHeaderContainer flexOnMobile>
      <GroupHeader>{header}</GroupHeader>
      <GroupHeaderActions>{headerActions}</GroupHeaderActions>
    </GroupHeaderContainer>
  ) : (
    header
  );
  const subHeaderContent = subHeaderActions ? (
    <GroupHeaderContainer>
      <GroupHeader>{subHeader}</GroupHeader>
      <GroupHeaderActions>{subHeaderActions}</GroupHeaderActions>
    </GroupHeaderContainer>
  ) : (
    subHeader
  );
  return (
    <FormGroup
      header={headerContent}
      subHeader={subHeaderContent}
      {...otherProps}
    />
  );
};