import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { DISPLAY_STATES, NOTIFICATION_TYPES, TIMINGS } from './consts';
import iconCloseX from 'assets/icon_close_x.svg';

const CLOSE_BUTTON_SIZE = 10;
const ICON_SIZE = 24;

const OuterContainer = styled.div`
  display: ${props => props.display};
  opacity: ${props => props.opacity};
  height: auto !important;
  margin: auto;
  text-align: center;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: ${props => props.bottom};
  overflow: auto;
  transition: ${props => `opacity ${props.fadeTime}s ease-in-out, bottom ${props.fadeTime}s ease-in-out`};
  max-width: 600px;
  @media screen and (max-width: ${props => props.breakpointSmall}) {
    max-width: 100%;
    bottom: -5px; // WTF why?
  }
  overflow: visible;
`;

const InnerContainer = styled.div`
  background-color: ${props => props.theme.colors.blacks[90]};
  color: #fff;
  text-align: left;
  border-radius: 36px;
  display: inline-block;
  position: relative;
  padding: 24px 24px 16px 24px;
  font-size: 14px;
  line-height: 24px;
  overflow: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  @media screen and (max-width: ${props => props.breakpointSmall}) {
    min-width: 100%;
    max-width: 100%;
    border-radius: 0px;
  }
`;

const IconContainer = styled.div`
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

const ContentContainer = styled.div`
  float: left;
  max-width: 472px; // 600 - ((24 + 24 + 16) * 2)
  margin-bottom: 8px;
  @media screen and (max-width: ${props => props.breakpointSmall}) {
    float: unset;
    margin: 0 0 8px 40px;
  }
`;

const CloseButtonContainer = styled.div`
  float: right;
  margin-left: 16px;
  @media screen and (max-width: ${props => props.breakpointSmall}) {
    margin-bottom: 8px;
  }
`;

const CloseButton = styled.div`
  border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  line-height: 24px;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.blacks[50]};
  }
  transition: background-color 0.1s;
`;

// ======================
// Rendering functions
// ======================
function renderIcon(notificationType) {
  const alertProps = NOTIFICATION_TYPES[notificationType];

  if (!alertProps) {
    throw new Error(`${notificationType} is not a valid alert type.`);
  }

  const src = alertProps.iconSrc;
  const notificationTypeUpper = notificationType.charAt(0).toUpperCase() + notificationType.slice(1);
  const alt = `${notificationTypeUpper} icon`;
  const dataRc = `toast-icon-${notificationType}`;
  return (
    <IconContainer>
      <img src={src} width={ICON_SIZE} height={ICON_SIZE} alt={alt} data-rc={dataRc}/>
    </IconContainer>
  );
}

function  renderCloseButton(onClick, breakpointSmall) {
  return (
    <CloseButtonContainer breakpointSmall={breakpointSmall}>
      <CloseButton onClick={onClick} theme={theme} data-rc="toast-close-button">
        <img src={iconCloseX} width={CLOSE_BUTTON_SIZE}
          height={CLOSE_BUTTON_SIZE} alt="Close button" />
      </CloseButton>
    </CloseButtonContainer>
  );
}

function renderContent(content, breakpointSmall) {
  let rendered;
  if (typeof content == 'string') {
    rendered = (
      <span>
        {content}
      </span>
    );
  }
  else {
    rendered = content;
  }
  return (
    <ContentContainer breakpointSmall={breakpointSmall}>
      {rendered}
    </ContentContainer>
  );
}

export default class ToastNotification extends React.Component {

  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleMouseEnter: PropTypes.func.isRequired,
    handleMouseLeave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super();
    this.state = {
      // Display lifecycle state -- one of the four DISPLAY_STATES
      displayState: DISPLAY_STATES.detached,
      // Notif type -- one of the four NOTIFICATION_TYPES
      notificationType: null,
      // String or Component, the content to display in the Toast
      content: null,
      // Serves two purposes:
      // 1. Is set to `false` when a user clicks on the Close (X) button,
      // and used to set the fadeout to a much shorter time, so the user
      // feels an immediate close of the notification
      // 2. Can also be set beforehand as an option by a user displaying a Toast
      // This prevents the timeout from getting set to auto-dismiss, forcing
      // the user to click the Close (X) button to dismiss
      autoDismiss: true,
      // User-specified callback, will be executed after Toast is dismissed
      callback: null
    };
  }

  handleChange(...args) {
    this.props.handleChange(...args);
  }

  handleClose() {
    this.props.handleClose();
  }

  handleMouseEnter() {
    this.props.handleMouseEnter();
  }

  handleMouseLeave() {
    this.props.handleMouseLeave();
  }

  /**
   * User-invoked dismissal -- sets the internal state to no-auto-dismiss,
   * which sets the fadeout time to a shorter interval
   */
  hide() {
    // Idempotence
    if (this.state.displayState > DISPLAY_STATES.shown) {
      return;
    }
    this.setState({
      autoDismiss: false
    });
    this.handleClose();
  }

  render() {
    const {
      displayState,
      notificationType,
      content,
      autoDismiss
    } = this.state;

    // Initial empty-state render, just return nothing
    if (!notificationType) {
      return null;
    }

    if (!content) {
      throw new Error('No content provided to alert message');
    }

    let display, opacity, bottom;
    // 'display' property
    // ---------------
    // detached
    if (displayState == DISPLAY_STATES.detached) {
      display = 'none';
    }
    // attached, shown, hidden
    else {
      display = 'block';
    }
    // 'opacity' property
    // ---------------
    // shown
    if (displayState == DISPLAY_STATES.shown) {
      opacity = 1;
    }
    // attached, hidden, detached
    else {
      opacity = 0;
    }
    // 'bottom' property, for position
    // Transitioning from attached to shown should slide in
    // Transitioning between shown and hidden should not move
    // ---------------
    // shown, hidden
    if (displayState == DISPLAY_STATES.shown || displayState == DISPLAY_STATES.hidden) {
      bottom = '30px';
    }
    // attached, detached
    else {
      bottom = '-100px';
    }

    let fadeTime;
    if (displayState == DISPLAY_STATES.shown) {
      fadeTime = TIMINGS.fadeIn;
    }
    else if (displayState == DISPLAY_STATES.hidden) {
      fadeTime = autoDismiss ? TIMINGS.fadeOutAuto : TIMINGS.fadeOutUser;
    }
    else {
      fadeTime = 0;
    }

    this.handleChange({
      exited: displayState
    });

    const breakpointSmall = theme.breakpoints[3];
    return (
      <>
        <OuterContainer fadeTime={fadeTime / 1000} display={display}
          opacity={opacity} bottom={bottom} breakpointSmall={breakpointSmall}>
          <InnerContainer breakpointSmall={breakpointSmall} theme={theme}
            onMouseEnter={this.handleMouseEnter.bind(this)}
            onMouseLeave={this.handleMouseLeave.bind(this)}>
            { renderIcon(notificationType) }
            { renderCloseButton(this.hide.bind(this), breakpointSmall) }
            { renderContent(content, breakpointSmall)  }
          </InnerContainer>
        </OuterContainer>
      </>
    );
  }
}

