export const BASE = 'kudosAmazingJob';

export const INK = `${BASE}-stamp-ink`;
export const STAMP = `${BASE}-stamper`;

export const STAR = {
  TOP_1: `${BASE}-star-top-1`,
  TOP_2: `${BASE}-star-top-2`,
  BOTTOM_1: `${BASE}-star-bottom-1`,
  BOTTOM_2: `${BASE}-star-bottom-2`,
};

export const RING = {
  TOP: `${BASE}-ring-top`,
  LEFT: `${BASE}-ring-left`,
  RIGHT: `${BASE}-ring-right`,
};

export const CIRCLE = {
  TOP: `${BASE}-circle-top`,
  LEFT_1: `${BASE}-circle-left-1`,
  LEFT_2: `${BASE}-circle-left-2`,
  LEFT_3: `${BASE}-circle-left-3`,
};
