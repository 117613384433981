const TAG = '@response-flow';

export const INITIALIZE_TIMELINE = `${TAG}/INITIALIZE_TIMELINE`;
export const RESET_TIMELINE = `${TAG}/RESET_TIMELINE`;
export const SAVE_TIMELINE = `${TAG}/SAVE_TIMELINE`;
export const SAVE_TIMELINE_COMPLETE = `${TAG}/SAVE_TIMELINE_COMPLETE`;

export const CHANGE_FEEDBACK_RATING = `${TAG}/CHANGE_FEEDBACK_RATING`;
export const CHANGE_SELECTED_SKILLS_IDS = `${TAG}/CHANGE_SELECTED_SKILLS_IDS`;
export const CHANGE_CURRENT_STEP = `${TAG}/CHANGE_CURRENT_STEP`;
export const CHANGE_CURRENT_RECURRING_STEP = `${TAG}/CHANGE_CURRENT_RECURRING_STEP`;
export const CHANGE_CURRENT_SKILL_STEP = `${TAG}/CHANGE_CURRENT_SKILL_STEP`;
export const CHANGE_VISIBLE_STEP = `${TAG}/CHANGE_VISIBLE_STEP`;

export const SET_WORK_RELATIONSHIP = `${TAG}/SET_WORK_RELATIONSHIP`;
export const SET_GENERATED_DEFAULT_NOTE = `${TAG}/SET_GENERATED_DEFAULT_NOTE`;

export const CHANGE_CURRENT_QUALITIES = `${TAG}/CHANGE_CURRENT_QUALITIES`;
export const CHANGE_CURRENT_SKILL_RATINGS = `${TAG}/CHANGE_CURRENT_SKILL_RATINGS`;
export const CHANGE_CURRENT_USER_FULL_NAME = `${TAG}/CHANGE_CURRENT_USER_FULL_NAME`;

export const TOGGLE_IS_DEFAULT_NOTE_MESSAGE_SHOWN = `${TAG}/TOGGLE_IS_DEFAULT_NOTE_MESSAGE_SHOWN`;
export const TOGGLE_SHOW_DEFAULT_NOTE_MESSAGE = `${TAG}/TOGGLE_SHOW_DEFAULT_NOTE_MESSAGE`;
