import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      d="M18.3,7.2c0,1.6-1.2,2.9-2.6,2.9s-2.6-1.3-2.6-2.9s1.2-2.9,2.6-2.9S18.3,5.6,18.3,7.2z M14.4,15.7
        c0.2,0.4,0.5,0.6,0.9,0.6h4c0.5,0,1-0.4,1-0.9c0-3.5-1.7-4.3-3.8-4.3H15c-0.6,0-1.9,0.1-2.4,0.3c-0.6,0.3-0.4,1,0.1,1.4
        C13.5,13.4,13.9,14.7,14.4,15.7z M11.5,10.2c0,1.8-1.3,3.3-2.9,3.3c-1.6,0-2.9-1.5-2.9-3.3c0-1.8,1.3-3.3,2.9-3.3
        C10.1,6.9,11.5,8.3,11.5,10.2z M3.5,17.7c0-1.8,1.5-3.3,3.3-3.3h3.3c1.8,0,3.3,1.5,3.3,3.3v0.8c0,0.5-0.4,0.8-0.8,0.8H4.4
        c-0.5,0-0.8-0.4-0.8-0.8V17.7z"
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
