import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const WhitePath = styled.path`
  fill: ${theme.colors.white};
`;

export const BottomCloud = styled(WhitePath).attrs({
  d: `M2546.3,1375.2c-0.4,0.1-0.7-0.5-0.8-0.8c-0.6-3.1-3.4-3.4-5.7-4.3
      c-14.9-41.1-63.4-35.8-100.4-2.6c-3.5,2.8-6.7,4.3-11.6,2.1c-11.2-5-23-6.7-35.3-5.5c-27.4,5.2-24.7-10.7-47.7-15.7
      c-14.4-3.4-29.3-7.7-44.3-5.5c-10.9,1.9-21.2,5.9-31.8,8.7c-4.2,1.1-4.2,1.4-6.7-2.1c-8.2-13.3-17.4-27.4-30.4-36.4
      c-3-1.7-1.8-5.8-5.7-6.2c-4-1.2-7.4-3.4-7.8-8.2c-2-3.5-3.4-8-7-10.4c-6.8-10.8-19-17-28.8-24.9c-17.6-10.2-40.4-11.5-59.6-5.6
      c-16,8.9-26.7-3.7-42.1-6.3c-7.7-0.7-15.4-5.4-23-2c-9.4,3.8-18.9,7.4-28.3,11.2c-10.3,5.5-12.5,6-23.1,0.3
      c-11.4-4.1-23.3-8-35.5-6.3c-12.6,1.3-25.7,5.3-35.3,14.2c-3,2.8-7.2,3.5-9.8,6.9c-1.3,1.2-2.5,1.2-4.1,0.1
      c-3.7-2.6-8-5.2-11.5-7.6c1.3-23.6-20.5-39.9-40.1-47.8c-28.3-13.7-67.7-7.2-87,18.6c-6,3.6-15.8-1.8-21.8-4.4
      c-8-4.1-16.6-5.4-25.4-5.7c-7.9,0-15.9,1.8-23.7,3c-3.7,0.7-7.3,2.2-11.1,0.4c-37.6-24.6-53.4-16.5-93.7-7.4
      c-2.3-8.1-7.6-14.1-11.3-21.5c-4.7-8.7-13.5-15.9-23.4-17.7c-4.5-1-4.5-1.1-5.2-5.5c-0.3-9.8-8.4-15.6-14.1-22.5
      c-6.9-9.9-17.4-16.1-27.6-21.9c-23.2-13.8-50.6-8.6-75.7-4.9c-2.1,1.9-4.3,0.7-6.5,0.2c-6.2-1.4-12.3-3.1-18.7-3.1
      c-19.2-1.5-38.3,1.3-56.6,7.1c-10,2.1-18.7-6.8-29.1-6c-20.5-4.2-43.2,2.8-59.1,16.1v-0.3c-5.4-4.9-13.1-6.7-19-10.7
      c-1-2.7-3.4-2.5-5.5-3c-19.1-6.8-40.8-10.1-58.8,1.8c-7.1,4.8-14.4,9.4-20.4,15.6c-5.6,4.5-9.1,13.8-17.7,11.6
      c-15.2-4.9-30.6-0.3-45.7,2.6c-6.3-0.3-11.9-5.7-17.9-7.8c-16.3-4.5-34.6-5.7-51.2-1.7c-9.3,3-17.8,7.9-26.7,11.7
      c-3.6,1.5-3.5,1.8-6.1-1c-8.8-10.6-18.7-21.7-31.2-27.9c-2.8-1.1-2.3-4.8-5.8-4.7c-6-0.6-6.4-3.7-9.2-8.1
      c-8.3-11.8-22-17.7-34.5-24.4c-16.7-6.6-36.8-4.8-52.9,2.8c-12.6,9.8-24.1,0.2-37.7-0.1c-20.3-3.6-26.8,5.8-43.7,14.6
      c-4.4,2.3-8.3,7.3-13.8,5.2c-12.1-4-25.7-6.2-38.3-2.9c-15,3-26.8,13.4-36.7,24.2c-1,1.2-2.1,1.4-3.6,0.6
      c-3.5-1.8-7.7-3.5-11.1-5.2c-1.9-20.8-23-32.3-41.2-36.7c-26.5-8.3-60.1,2.4-73.7,27.4c-11,6.6-29.3-10.3-53.9,0
      c-23.4,10-21.9,0.1-43.4-3.8c-20.9-4.2-39.1,7.6-58.1,14c-8.2-12.9-18.4-29.1-35.3-29.8c-4.1-0.3-4.1-0.3-5.3-4.1
      c-1.5-8.6-9.4-12.5-15.2-17.9c-11.9-11.6-28.8-17.5-44.9-19.7c-16.8-1-33,5-48.9,9.5c-1.6,1.9-3.7,1.1-5.6,1
      c-22.5-2.5-45.1,3.5-65.3,13.1c-8.5,3.1-17.3-3.6-26.2-1.5c-20.6-1.2-40.7,10-53.4,25.9c-10.5-15.4-21.8-29.7-37.9-39.6
      c-2.6-2.1-6.3-1.8-9.1-3c-3.8-2.2-7.8-3.7-12.1-4.6c-28.7-7.6-39.6-0.2-63.2,13.8v0.6c-14.7-15.8-37.9-25.9-59.1-20.4
      c-11.7-0.6-20,10.6-29.1,7.5c-23.8-9.4-50.6-13.2-75.3-5c-2.1,0.6-4.4,2.1-6.5-0.2c-18.3-3-37.8-8.2-56.3-3.3
      c-18.8,6.7-36.5,19.5-47.1,37.1c-6,9.2-13.8,16.4-14.1,28.4c-0.7,5.6-0.7,5.7-5.2,6.9c-10.2,2.2-19.3,12.3-23.4,22.3
      c-3.6,9.3-8.9,16.9-11.3,27.1c-43.5-12.1-54.7-21.6-93.7,9.3c-7.2,3.3-25.1-5.4-34.8-4.2c-8.8,0.4-17.4,2.1-25.4,7.2
      c-5.3,3.1-16.8,10.1-21.8,5.6c-5.1-10.9-14.7-16.9-23.8-23.6c-14.7-10-31.8-9.7-48.2-6.3c-28.7,8.5-56.8,34.5-55.1,66.7
      c-3.2,1.5-12.8,13.7-15.6,9.4c-2.7-4.2-6.8-5.2-9.8-8.6c-13.8-15.7-36.7-22.3-56.3-15.8c-7.3,2.7-14.9,5.3-21.3,9.9
      c-2.8,2.1-5.6,1.6-8.3,0.2c-9.5-4.5-38.2-23.4-46.6-19.6c-10.8,3.4-21.6,5.1-31.2,11.8c-8,6.2-15.3,3.8-23.5-0.3
      c-19.3-7.6-42.7-5.4-59.6,7c-10,10-22.4,18.2-28.8,31.3c-3.6,3.1-5,8.8-7,13.1c-0.4,6.2-3.7,8.8-7.8,10.4c-3.9,0.6-2.7,5.8-5.7,7.8
      c-13.5,11.9-22.5,29.7-30.4,45.9c-2.5,4.5-2.4,4.1-6.7,2.7c-10.6-3.6-20.9-8.6-31.8-11c-15.1-2.8-29.9,2.6-44.3,6.9
      c-10.6,2.5-18.5,11-26.3,18.3c-1.6,1.6-3.3,2.6-5.1,2.6c-5.4-0.2-10.9-0.4-16.3-1.1c-26.8-4.6-37.6,15.8-46.9,4.3
      c-39.8-44.2-85.8-46.5-100.4,3.3c-2.3,1.1-5.1,1.4-5.7,5.4c-0.1,0.4-0.4,1.1-0.8,1c-7.3-1.3-9,8.3-13.7,12.1H-4l4-0.1v0.2h1280
      v-0.2c2,0.2,1277.2,0,1280,0.1C2555.3,1381.8,2553.5,1374.1,2546.3,1375.2z M-867.7,1222.5c-3,0.7-5.9,2.8-9.4,1.1
      c3.2-2.4,6.3-1.9,9.4-1.8C-867.8,1222.1-867.7,1222.5-867.7,1222.5z M-325.7,1321.3c0.8-0.6,1.1-0.8,1.4,0.3
      C-325,1321.9-325.3,1322.4-325.7,1321.3z M434.8,1235.5c-0.3,0-0.6-0.1-0.9-0.2c0.2-0.9,0.6-0.7,1.2-0.4
      C435,1235.2,434.9,1235.5,434.8,1235.5z M899.1,1096.8c0,0,0-0.3,0-0.5c2.7-0.5,5.4-1.3,8.4,0
      C904.5,1097.9,901.8,1096.8,899.1,1096.8z M1604.2,1334.7c0.2-0.2,0.3-0.7,0.5-0.7c0.3,0,0.6,0.3,1,0.4
      C1605.3,1335.3,1604.9,1334.9,1604.2,1334.7z M2147.8,1255.5c3.1-0.1,6.2-0.5,9.4,1.4C2154.9,1258,2148.3,1257,2147.8,1255.5z`,
})``;

export const MiddleCloud = styled.path.attrs({
  d: `M2559.7,1375.4c-2.7-29.4-29.6-48.6-57.5-50.9c-16.2-2.4-31.4,4.7-47,7.7
      c-3.6,0.8-5.8-3.2-9-4c-14.1-6.6-29.3-4.2-43.4,0.9c-11.2-33.8-47.6-58.9-83.2-48c-8.4-15-10.3-16.2-26-22.3
      c-14.9-7.3-34.2-8.7-49-1.1c-9.1,6.7-8.2-21-43.4-7.1c-2.7,0.9-5.6,1.2-7.2,4c-0.2,0.4-0.9,0.6-1.4,0.9c-3.9,2.4-8.6,3.7-10.9,8.3
      c-3.7,2.6-19.1-12.2-25.9-12.6c-3.9-1.7-11.7,0.4-11.1-5.8c4.7-32.3-12.8-50.8-35.9-70.2v-0.4c-18.4-17.3-54.6-16.5-78.7-14.7
      c-7-18.2-23.9-34-43.8-36.1c-4.3,0.1-7.8-3.4-6.7-7.8c0.7-3.8,3.4-8.6-0.2-11.8c-4.4-3.6-8.6-7.6-13.3-10.9
      c-9.9-6.8-22.6-15.7-34.8-9.6c-1.9,0.9-2.9,0.8-3.7-1.2c-9.5-20.4-15.8-23.8-35.9-31.7c-9.7-2.3-20.5-3.9-30.4-2.7
      c-6.8,1-18.8,8.6-24.5,5.8c-19.5-13-41.6-26.4-65.9-19.4c-16.7-1-29.4,9.7-41.6,19.5c-1.9-0.8-2.5-2-2.9-3.5
      c-1.9-6.6-5.1-12.7-8.8-18.5c-6.8-8.9-21.9-15.6-32.5-18.5c-7-3-1.6-11.6-3.1-17.1c-3.2-16.1-10.8-31.9-21.9-44.2
      c-19.7-26.2-55.1-27.8-85-24.3c-11.5,1-12.5-20-30.2-26.2c-11.4-6.6-24.9-13.7-38.6-11c-2.1,0.5-23.1-5.7-38.4-0.9
      c-13.2-18-36.3-24.7-55.9-33.6c-23-0.7-46.8,4.9-67.9,14.2c-9.2,4.5-15.2,12.6-23.9,18.5c-12-15.6-27-6.4-40.3,2V890
      c-3.8,2.5-8.1-1.3-11.7,1.7c-4.1,3.2-9.3,5.2-12.2,9.8c-11.4,18.3-18.7,40.4-17.5,62.2c0.1,6-8.8,5-12.1,8.8
      c-6.5,4.2-12.2,8.7-18,14.1c-14.8-15.3-34.9-13.4-52.2-3.1c-3,2.5-6.3,0.4-9.6,0.3c-2.5,0-5.1,0.6-7.6,0.3
      c-16.2,0.5-36.8,13.2-48.2,24.3c-6.7,8.3-18.7,6.1-27.2,11.9c-3.8,2-8.5,2.1-12.1,4.7c-11.1,8.3-21.9,17.4-27.5,30.4
      c-3.2,6.1-3.9,14.1-9.4,18.6c-12.9-7-28.1-1.7-40.1,4.7c-8.7,5.1-22.4-0.5-31.2-2.8c-11.4,4.6-22,5.7-32.3,12.2
      c-6.2,1.8-9.2,9.4-15.2,10.8c-14-0.1-26,12.9-35.4,10.3c-13.3-3.2-29.1-8.3-42.3-3.3c-10,5.9-20.5,10.9-30,17.7
      c-6.4,3.7-22.1-5.1-32-3.6c-20-0.7-37.5,11.2-49.9,25.7c-2.2,3-11.6-3.5-14.5-4.7c-1.2-17.4-17.4-27.8-31.9-33.9
      c-28.6-11.1-67-4.5-82.7,24.1c-5.9,8.3-31.8-9.4-52.8,0.4c-23,9.3-21.1,2.6-42.3-3.1c-21.7-5.2-40.7,6.4-60.3,13.5
      c-2.9-6.7-8.3-11.2-12.4-17c-4.1-5.9-11.4-10.9-18.5-12.2c-2.8-0.9-8,0.9-8.8-2.8c-1.8-6.4-5-11.9-10.8-15.4
      c-6.7-5.9-13.5-11.7-21.6-15.6c-23.6-13.5-51.7-8.4-75.8,1.4c-3.7-4.6-7.5-9.3-13.4-11.2c-4.4-2.2-10.8-1.7-13.7-6
      c-20.4-50.6-65.2-48.9-105.6-22.3c-3,1.7-5.5-2.2-8.8-1.3c-9.6-0.7-12-0.3-13-10.7c-3.6-29.1-22.8-15.1-26.6-24.9
      c-2.5-6.9-8-12.7-13.8-17.1c-9-5.7-20-11.1-30.8-7.5c-5.4,1.7-8.2-2.9-11.7-5.9c-2.8-2.8-7-3.7-9.2-7.1C19.2,961.1,9.2,958.5,0,955
      c0-0.1,0-0.2,0-0.2c-3.7-0.3-7.4-0.9-10.9,1.1c-4.8,3.2-11,1.7-16.2,3.1c-2.5,1.1-5.2,1.9-7.9,2.9c-19.7,4.1-33,17.8-46.8,31
      c-1.2,1.1-2.4,0.6-3.6,0.1c-7-3-14.8-2.4-22.2-2c-21.4,1.1-42,5.9-61.6,14.6c-11.1,5.9-22.2,15-26.8,26.3
      c-5.1,9.9-11.8,20-8.9,31.6c-23.6,3.8-35.6,12.4-48.3,32.5c-27.6-19.3-56.2-22.3-87.5-10.2c-9,2.2-23.7,15.4-31.6,13.7
      c-12.5-6.4-26.7-6.9-40.3-9.1c-9.5-2.1-16,3.7-24.8,5.8c-6.1,1.3-11.2,5.4-17,7.7c-5.1,1.6-7.1,4.9-8.2,8.4c-2.4,5.9-6,11-9.7,16.2
      c-3.9-1.6-6.7-4.7-11-4.6c-8.1,0.6-17.3-0.3-24.2,4.3c-6.9,4.6-17.6,7.9-19.3,16.6c-2.1,3.4,0,9.9-5.6,9.8
      c-4.2,0.4-8.7-1.2-12.3,1.3c-8.3,4-18,7.4-23.4,14.4c-7.8,8.3-16.5,16.3-21.3,26.7c-10.3-5.7-22.9-5.5-34.4-7.5
      c-15-0.5-29.5,5.9-43.8,9v0.1c-10.3-2.6-20,1.6-29.6,5c-11.4,3.5-18.9,11-23.6,21.6c-10.4,0-19.1,2.8-25.3,9.8
      c-10.6-6.4-25.1-6.6-36.9-3.7c-10,3.5-15,12.7-20.2,21c-24.4-9.9-54.8-8.7-75.1,9.2c-4.1,3.6-5.4,8.3-6.8,13.3
      c-2,5.3-4.2,10.8-3.9,16.5c0.6,3.2-6.9,1.4-9.1,3c-6.4,1.4-11.5,4.6-16.4,8.3c-6.5,2.7-6.6,10.4-6.7,16.3
      c-20.3-9-50.6-9.6-64.6,10.2c-17.2-5.1-39.4-7.2-52.5,7.5v0.5c-3.9,4.1-6.6,9.1-9,14.2c-10.7-3-26.8,0-30.1,12.1
      c-10-3.3-21.2-6.1-31.8-3.7c-14.6-22.1-50.6-20.3-70.6-6.3c-3.7,3.6-7.4,8.2-8.8,13.4c-1,2.6-0.6,5.7-2,8c-9.1-3-18.1-6.8-28-6
      c0,0,0,0,0,0c-9.6-0.5-19.1,3.5-26.1,9.9c-11.2-5.8-28.5-4.9-35.5,6.7h612.4v0H0v0h1280h1280
      C2560,1383.9,2560.1,1376.6,2559.7,1375.4z M0,1085c0-0.1,0-0.2,0-0.4c0.1,0.1,0.2,0.1,0.3,0.2C0.2,1084.9,0.1,1085,0,1085z
      M-1280,1385.3L-1280,1385.3C-1280,1385.3-1280,1385.3-1280,1385.3L-1280,1385.3z`,
})`
  fill: #ecdee8;
`;

export const TopCloud = styled.path.attrs({
  d: `M2557.7,1346c-9.6,3.7-23.8-5.6-34.9-3.5c-14.2-0.9-26.8,6.4-41.1,5.4
      c-9.1-22.1-26-15-31.5-29.1c-8.2-52.6-55.9-69-101.7-49.1c-5.3,1.1-10.1-2.2-14.5-4.9c-12.8-5.5-27.8-3.5-41-0.8
      c-3.7,2.4-10.7,5.9-12-0.9c-5.7-13-6.6-27.6-16.4-38.4c-6.8-8.8-16.9-16.2-28-18.3c-11.6-3.9-23-11.5-35.7-8.7
      c-3.1,0.8-5.1-0.5-6.5-3.1c-10.9-16.7-43.6-34.7-63.6-34.5c-7.9,0.9-16.3,5-24.1,2.4c-14.7-7.7-32.8-9.7-48.4-2.8
      c-7.3,2.2-12.3,12.6-20.6,7.6c-8.1-9-32.9-10.4-31.7-18.9c0.9-36.3-13-61.8-39.4-85.7c-19.9-12.5-43.5-22.9-67.3-23.9
      c-17-2.2-35.5,12.5-49.8-2.2c-0.7-0.6-1.5-1.4-2.3-1.5c-5.1-0.9-8.4-5.5-13.7-5.7c-46.6-8.7-36.6,18.2-58.5,3.3
      c-10.6-7.1-23.8-15.6-36.8-9.7c-3.1,1.3-6.2,2.2-9.7,1.6c-1.6,0.2-3.1,0.5-4.7,0.7c-2.1-0.6-4.2-1.1-6.4-1.3
      c-8.9-3.3-21.9,2.4-29.3-2.1c-1.4-3.1-4.6-5.2-4.5-9.1c0-1-0.7-2.1-1.4-3c-7.6-9.8-15.9-20.4-26.6-27c-6.2-3.7-15.4-4.6-19.5-10.9
      c-1.2-3.9,0.1-8.3-2.9-11.8c-0.8-9-9.9-13-16.7-16.8c-6.1-1.8-14.2,0.8-18.3-4.7c-7.5-8.5-14.6-18-23.3-25.3
      c-11.5-7.2-25.1-9.5-38.6-10.8c-14.3-1.9-22.5,7-36.2-3.1c-10.1-8.5-22.5-13.9-35.8-15.1c-6.9-0.7-13.6-2.3-20.4-0.1
      c-10.3,1.8-19.8,7.2-29.9,8.9c-6.4-2.5-7.6-11.8-13.8-15.1c-3.8-1.6-6.9-4.5-9.4-7.7c0.6-0.1,1-0.1,2-0.3
      c-6.3-4.3-11.5-8.1-18.1-10.4c-2.4-1.8-3.1-3.5-2-6.3c1.4-7.1,2.4-16.4-0.7-23.1c-1.8-4.9-3.4-10.2-6-14.7
      c-1.4-2.5-4.3-4.2-3.5-7.7c-2.9-7.2-5.9-14.5-9.4-21.5c-2.4-5.8-9.4-6.6-14-10c-17.6-11.9-39-16.6-59.6-12.9
      c-3.5,0.7-7,1.7-10.3,2.9c-15.1,14.3-19.6-3.4-44.3-0.6c-11.7,1.7-26.2,5.4-33.3,15.6c-0.5,1.2-1.8,2.4-3,2.9
      c-6.8,3.8-8.6,11.9-12.6,18.1c-29.3-10.5-64.2-0.8-86.1,20c-3.5,7-6.6,14.5-9.5,21.7c0.9,3.5-2.1,5.3-3.5,7.8
      c-2.7,4.6-4.3,9.9-6.1,14.9c-5.9,10.9,3.4,26.8-2.7,29.7c-6.7,2.4-11.9,6.1-18.3,10.5c1.1,0.2,1.5,0.2,2.1,0.3
      c-2.5,3.2-5.7,6.2-9.5,7.8c-6.2,3.3-7.5,12.8-13.9,15.3c-10.1-1.7-19.8-7.1-30.1-8.9c-19.6-3.4-41.5,2.6-56.7,15.3
      c-13.9,10.2-22.1,1.2-36.6,3.2c-31.6,1.9-43.8,12.4-62.4,36.4c-4.1,5.6-12.3,3-18.5,4.8c-6.9,3.9-16.1,7.9-16.9,17
      c-2.9,3.5-1.7,7.9-2.9,11.9c-4.2,6.3-13.4,7.3-19.7,11c-11.2,7.4-21.8,18.6-28.3,30.2c0.1,3.9-3.1,6.1-4.6,9.2
      c-7.4,4.5-20.6-1.3-29.6,2.1c-11.9,1.3-21.4,9.1-33.5,10.4c-10.7-17.1-23.9-8.8-30.4-19.7c-14.3-42.7-56.5-49.5-91.7-26.4
      c-4.3,1.7-8.8-0.4-12.8-2c-11.5-2.7-23.6,1.1-34.3,5.2c-2.8,2.5-8.1,6.5-10.1,1c-9.4-18.8-21.1-40.4-45.1-40.8
      c-10.2-1.6-20.8-6.3-31-2.2c-2.5,1.1-4.3,0.3-5.9-1.6c-11.5-12.4-41.3-22.7-57.9-19.6c-6.4,1.9-12.9,6.5-19.7,5.5
      c-41.3-11.6-45.7,18.5-56.8,13.9c-8.1-6.3-28.9-3.9-29.1-11.2c-5.6-50.8-56.1-82-104.6-76.1c-15.3,1.3-29.2,15.7-43.9,4.3
      c-4.4-0.1-7.8-3.4-12.3-2.8c-40.1-0.6-27.9,20.4-48.2,11.1c-9.8-4.4-22.1-9.6-32-2.9c-2.4,1.5-4.9,2.7-7.8,2.7
      c-4.2,1.2-8.1,3.2-12.7,2.3c-0.8-0.1-1.9,1-2.8,1.6c-25.5-4.4-54.5,5.3-72.9,23c-3.5,7-6.5,14.3-9.4,21.5c0.8,3.5-2,5.2-3.5,7.7
      c-2.6,4.6-4.3,9.8-6,14.7c-5.9,10.7,3.4,26.6-2.7,29.4c-6.7,2.4-11.8,6.1-18.1,10.4c1.1,0.1,1.4,0.2,2,0.3
      c-2.5,3.2-5.6,6.1-9.4,7.7c-6.2,3.3-7.4,12.6-13.8,15.1c-10-1.7-19.6-7-29.9-8.9c-19.4-3.3-41.1,2.6-56.2,15.2
      c-13.7,10.1-21.9,1.2-36.2,3.1c-31.3,1.9-43.4,12.3-61.8,36.1c-4.1,5.5-12.2,2.9-18.3,4.7c-6.8,3.8-16,7.8-16.7,16.8
      c-2.9,3.4-1.7,7.9-2.9,11.8c-4.1,6.3-13.3,7.2-19.5,10.9c-11,7.4-21.6,18.4-28,30c0.1,3.9-3.1,6-4.5,9.1
      c-7.4,4.4-20.4-1.3-29.3,2.1c-3.9,0.6-7.2,1.7-11.1,0.6c-3.4,0.6-6.5-0.3-9.7-1.6c-12.9-5.8-26.2,2.7-36.7,9.7
      c-21.8,14.9-11.8-12-58.4-3.3c-5.3,0.2-8.6,4.8-13.7,5.7c-14.9,16.2-33.9,2.2-52.1,3.7c-57.8,2.8-110.3,49.3-106.7,109.6
      c1.2,8.6-23.6,9.9-31.7,18.9c-11.9,7.8-22.7-26.6-69-4.8c-7.7,2.6-16.2-1.6-24.1-2.4c-20-0.3-52.7,17.8-63.6,34.5
      c-1.4,2.6-3.4,3.9-6.5,3.1c-12.7-2.8-24.1,4.8-35.7,8.7c-11,2.1-21.2,9.4-28,18.3c-2.2,2.4-294,5-295.5,7.8H-0.7v0h1280.3H2560
      v-5.8v-34.9C2559.2,1344.2,2558.4,1345.1,2557.7,1346z M-899.2,1341.4c-0.4-0.2-0.7-0.5-1.1-0.7c0.5-0.6,1.1-1.2,1.6-1.8
      c0.3,0.2,0.5,0.4,0.8,0.6C-898.2,1340.1-898.7,1340.8-899.2,1341.4z M-311.1,1093.6c-0.1,0-0.2-0.1-0.4-0.1
      c0.2-0.6,0.4-1.2,0.6-1.8c0.2,0.1,0.4,0.2,0.6,0.3C-310.6,1092.6-310.9,1093.1-311.1,1093.6z M414.2,984.3c-0.5-0.5-1-0.9-1.4-1.4
      c0.2-0.2,0.4-0.4,0.6-0.6c0.5,0.4,1.1,0.8,1.6,1.2C414.7,983.8,414.4,984,414.2,984.3z M849.9,979.5c-0.1,0-0.2-0.1-0.4-0.1
      c0.2-0.6,0.4-1.2,0.6-1.8c0.2,0.1,0.4,0.2,0.6,0.3C850.5,978.5,850.2,979,849.9,979.5z M1591.2,942c-0.3-0.5-0.5-1.1-0.8-1.6
      c0.2-0.1,0.4-0.2,0.6-0.3c0.2,0.6,0.4,1.2,0.6,1.8C1591.4,941.9,1591.3,942,1591.2,942z M2179.2,1189.8c-0.5-0.6-0.9-1.3-1.4-1.9
      c0.3-0.2,0.5-0.4,0.8-0.6c0.5,0.6,1.1,1.2,1.6,1.8C2179.9,1189.3,2179.6,1189.6,2179.2,1189.8z`,
})`
  fill: #f7f6f9;
`;

export const DarkDotPath = styled.path.attrs({
  fillRule: 'evenodd',
  clipRule: 'evenodd',
})`
  fill: #d8d8d9;
`;

export const LightPath = styled.path.attrs({
  fillRule: 'evenodd',
  clipRule: 'evenodd',
})`
  fill: #fde8e1;
`;

export const LeftDotsGroup = styled.g``;
export const LeftDot1 = styled(DarkDotPath).attrs({
  d: `M93.3,318.9c-0.4-0.5-1.5-0.2-1.8-0.8c-7-3.7-2-14.4,5.1-11.3C102.8,309.8,100.8,319.8,93.3,318.9z`,
})``;
export const LeftDot2 = styled(DarkDotPath).attrs({
  d: `M50.2,459c-2.9,0.4-5.7-3.8-2.3-6C51.8,449.9,55.2,457.8,50.2,459z`,
})``;
export const LeftDot3 = styled(DarkDotPath).attrs({
  d: `M36.9,518.1c10.7-1.3,13.3,13.7,2.9,16.2C30.5,534.7,27.1,519.9,36.9,518.1z`,
})``;
export const LeftDot4 = styled(DarkDotPath).attrs({
  d: `M55.2,580.7c-0.4-0.5-1.5-0.2-1.8-0.8c-7-3.7-2-14.4,5.1-11.3C64.7,571.6,62.6,581.6,55.2,580.7z`,
})``;
export const LeftDot5 = styled(DarkDotPath).attrs({
  d: `M48,843.3c-4.7-6.8,8-13.5,11.2-6C62.5,843.9,52.1,848.6,48,843.3z`,
})``;

export const TopDotsGroup = styled.g``;
export const TopDot1 = styled(DarkDotPath).attrs({
  d: `M532.3,56.3c-0.3-0.5-0.4-0.5-0.8-0.6c1.3-1.6-2.2-3.5-3.7-4.1c-0.5-0.2-1.1-0.4-1.6-0.5c-0.4-0.2-0.8-0.1-1.3-0.1c-1.1,0.1-2.4,0.7-2.5,0.8c-0.1,0-0.8,0.4-0.9,0.5c-0.2,0.2-0.6,0.4-1.2,0.8c-4.6,2.3-3.2,13.1,4.3,13.2C530.5,66.1,534.1,59.5,532.3,56.3z`,
})``;
export const TopDot2 = styled(DarkDotPath).attrs({
  d: `M883.1,73.8c4-7.4,12.9,0.8,9.4,6.8C888.5,88.1,880.4,79.7,883.1,73.8z`,
})``;
export const TopDot3 = styled(DarkDotPath).attrs({
  d: `M1124.1,86.9c-0.4-0.5-1.5-0.2-1.8-0.8c-7-3.7-2-14.4,5.1-11.3C1133.6,77.8,1131.5,87.8,1124.1,86.9z`,
})``;

export const TopLightDotsGroup = styled.g``;
export const TopLightDot1 = styled(LightPath).attrs({
  d: `M608.5,125.5c2.6,4.5-5.7,7.3-8.7,4.4C597.1,125.2,607,119.7,608.5,125.5z`,
})``;
export const TopLightDot2 = styled(LightPath).attrs({
  d: `M920.7,118.3C920.7,118.3,920.7,118.3,920.7,118.3C920.7,118.3,920.7,118.3,920.7,118.3C920.6,118.3,920.6,118.3,920.7,118.3c-0.3,0.7-0.6,1-1,1.4c-0.8,1.4-2.2,1.3-5.4,0.8
      c-5-3-1-10.4,4.1-8.1C920.3,112.8,921.5,116.4,920.7,118.3C920.7,118.2,920.7,118.2,920.7,118.3z`,
})``;

export const RightDotsGroup = styled.g``;
export const RightDot1 = styled(DarkDotPath).attrs({
  d: `M836.9,385.4c-2.9,0.4-5.7-3.8-2.3-6C838.5,376.3,841.9,384.2,836.9,385.4z M836.2,382.2
  c-0.8,0.3-0.3,0.8,0.2,0.5C836.2,382.7,836.3,382.3,836.2,382.2z M833.5,383c0.2,0.2,0.2,0.5,0.5,0.5
  C834.1,383.2,833.8,383,833.5,383z`,
})``;
export const RightDot2 = styled(DarkDotPath).attrs({
  d: `M909.1,390.8c-2.9,0.4-5.7-3.8-2.3-6C910.6,381.7,914,389.5,909.1,390.8z M908.3,387.6
  c-0.8,0.3-0.3,0.8,0.2,0.5C908.3,388,908.5,387.6,908.3,387.6z M905.7,388.4c0.2,0.2,0.2,0.5,0.5,0.5
  C906.2,388.6,906,388.4,905.7,388.4z`,
})``;
export const RightDot3 = styled(DarkDotPath).attrs({
  d: `M1012.5,526.5c-2.9,0.3-5.7-3.8-2.3-6C1014,517.4,1017.4,525.2,1012.5,526.5z M1011.7,523.3
  c-0.8,0.3-0.3,0.8,0.2,0.5C1011.7,523.7,1011.9,523.3,1011.7,523.3z M1009.1,524c0.2,0.2,0.2,0.5,0.5,0.5
  C1009.6,524.2,1009.3,524,1009.1,524z`,
})``;
export const RightDot4 = styled(DarkDotPath).attrs({
  d: `M1198.2,319c4.8,0.3,6.9,7.8,3.2,10.8C1192.7,333.2,1189.4,320.7,1198.2,319z`,
})``;
export const RightDot5 = styled(DarkDotPath).attrs({
  d: `M1237.7,369c-0.4,1.1-1,1.8-1.7,2.1l0,0c-0.9,0.6-2.3,0.4-4.7,0c-5-3-1-10.4,4.1-8.1
  C1237.4,363.5,1238.6,367.2,1237.7,369z`,
})``;

export const BottomDotsGroup = styled.g``;
export const BottomDot1 = styled(DarkDotPath).attrs({
  d: `M673.5,635.1c-3.9,0.7-7.4-3.4-4.2-6.3C673.5,625.5,678.9,632.6,673.5,635.1z`,
})``;
export const BottomDot3 = styled(DarkDotPath).attrs({
  d: `M935.5,783.3c-0.3-0.5-0.4-0.5-0.8-0.6c1.3-1.6-2.2-3.5-3.7-4.1c-0.5-0.2-1.1-0.4-1.6-0.5
  c-0.4-0.2-0.8-0.1-1.3-0.1c-1.1,0.1-2.4,0.7-2.5,0.8c-0.1,0-0.8,0.4-0.9,0.5c-0.2,0.2-0.6,0.4-1.2,0.8c-4.6,2.3-3.2,13.1,4.3,13.2
  C933.8,793.1,937.4,786.6,935.5,783.3z`,
})``;
export const BottomDot4 = styled(DarkDotPath).attrs({
  d: `M961,845.7c-11.2,1.9-12.3-15.7-1.3-14.7C968.1,830.1,969.8,844.7,961,845.7z`,
})``;
export const BottomDot2 = styled(DarkDotPath).attrs({
  d: `M784.7,743.5c11-0.6,10.2-10.8,6.8-16.2c-6.5-5.9-17.5-1.2-16.3,8.3C776.1,739.8,780.1,744.4,784.7,743.5z`,
})``;

export const TopRightStar = styled.path`
  fill: #d9d9ed;
`;
export const TopRightStarsGroup = styled.g``;
export const TopRightStarLeft = styled(TopRightStar).attrs({
  d: `M1113.6,386.7c0,1.1,0,2.2,0,3.2c0,0.7-0.3,1-0.9,1.1c-0.7,0-1-0.3-1.1-1.1
	c-0.1-1.9-0.1-3.7-0.2-5.6c-0.1-2.4,0-2-2-2.2c-0.9-0.1-1.9-0.1-2.9-0.2c-0.9-0.1-1.3-1.1-0.8-1.8c0.2-0.2,0.6-0.4,0.8-0.4
	c1.3,0,2.6,0.1,3.9,0.2c0.7,0,1-0.3,1-0.9c0-1.4,0-2.9,0.1-4.3c0-0.9,0-1.8,0.1-2.7c0.1-0.8,0.4-1.1,1.1-1.1s1.1,0.5,1.1,1.2
	c0,1.5,0,3-0.1,4.5c0,0.9,0,1.8,0,2.6c0,0.6,0.2,0.8,0.8,0.8c1.1,0,2.3,0,3.4,0c0.9,0,1.3,0.2,1.3,0.8c0,0.8-0.4,1.3-1.3,1.3
	c-1.1,0-2.2-0.1-3.2-0.1s-1.2,0.3-1.2,1.3c0,1.1,0,2.2,0,3.2C1113.5,386.7,1113.6,386.7,1113.6,386.7z`,
})``;
export const TopRightStarRight = styled(TopRightStar).attrs({
  d: `M1033.3,361.9c0,1.1,0.2,4.1,0.3,6.6c0.1,1.6-0.6,2.4-2.2,2.5
	c-1.5,0.1-2.4-0.7-2.5-2.4c-0.2-4.3-0.3-8.5-0.4-12.8c-0.2-5.6,0-4.7-4.7-5c-2.2-0.1-4.4-0.2-6.6-0.5c-2-0.2-3-2.6-1.8-4
	c0.4-0.5,1.3-0.9,1.9-0.9c3,0.1,6,0.3,9,0.5c1.6,0.1,2.2-0.7,2.2-2.2c0.2-5.3,0-10.8,0.2-16.1c0.1-1.9,0.9-2.6,2.4-2.5
	s2.4,1.1,2.4,2.8c0,5.5-0.2,10.8,0,16.3c0,1.3,0.5,1.9,1.9,1.9c2.6,0,5.2,0.1,7.8,0c2.1-0.1,3,0.4,3.1,1.9c0.1,1.7-1,2.9-2.9,2.9
	c-2.5,0-5-0.1-7.4-0.2c-2.2,0-2.9,0.7-2.8,2.9C1033.3,356.1,1033.3,360.8,1033.3,361.9z`,
})``;
export const TopRightStarDot = styled.path.attrs({
  d: `M1189.6,400.5c2.1,4.1-6.4,6.8-8.5,3.2C1180,398.8,1187.5,397.7,1189.6,400.5z`,
})`
  fill: #adafde;
`;

export const BottomStar = styled.path`
  fill: #fde8e1;
`;
export const BottomStarsGroup = styled.g``;
export const BottomStarLeft = styled(BottomStar).attrs({
  d: `M738.6,879.7c0,1.1,0,2.2,0,3.2c0,0.7-0.3,1-0.9,1.1c-0.7,0-1-0.3-1.1-1.1
	c-0.1-1.9-0.1-3.7-0.2-5.6c-0.1-2.4,0-2-2-2.2c-1-0.1-1.9-0.1-2.9-0.2c-0.9-0.1-1.3-1.1-0.8-1.8c0.2-0.2,0.5-0.4,0.8-0.4
	c1.3,0,2.6,0.1,3.9,0.2c0.7,0,1-0.3,1-0.9c0-1.4,0-2.9,0.1-4.3c0-0.9,0-1.8,0.1-2.7c0.1-0.8,0.4-1.1,1-1.1s1.1,0.5,1.1,1.2
	c0,1.5,0,3-0.1,4.5c0,0.9,0,1.8,0,2.6c0,0.5,0.2,0.8,0.8,0.8c1.1,0,2.3,0,3.4,0c0.9,0,1.3,0.2,1.3,0.8c0,0.8-0.4,1.3-1.3,1.3
	c-1.1,0-2.2-0.1-3.2-0.1s-1.2,0.3-1.2,1.3c0,1.1,0,2.2,0,3.2C738.5,879.7,738.6,879.7,738.6,879.7z`,
})``;
export const BottomStarCenter = styled(BottomStar).attrs({
  d: `M778.6,919.7c0,1.1,0,2.2,0,3.2c0,0.7-0.3,1-0.9,1.1c-0.7,0-1-0.3-1.1-1.1
	c-0.1-1.9-0.1-3.7-0.2-5.6c-0.1-2.4,0-2-2-2.2c-1-0.1-1.9-0.1-2.9-0.2c-0.9-0.1-1.3-1.1-0.8-1.8c0.2-0.2,0.5-0.4,0.8-0.4
	c1.3,0,2.6,0.1,3.9,0.2c0.7,0,1-0.3,1-0.9c0-1.4,0-2.9,0.1-4.3c0-0.9,0-1.8,0.1-2.7c0.1-0.8,0.4-1.1,1-1.1s1.1,0.5,1.1,1.2
	c0,1.5,0,3-0.1,4.5c0,0.9,0,1.8,0,2.6c0,0.5,0.2,0.8,0.8,0.8c1.1,0,2.3,0,3.4,0c0.9,0,1.3,0.2,1.3,0.8c0,0.8-0.4,1.3-1.3,1.3
	c-1.1,0-2.2-0.1-3.2-0.1s-1.2,0.3-1.2,1.3c0,1.1,0,2.2,0,3.2C778.5,919.7,778.6,919.7,778.6,919.7z`,
})``;
export const BottomStarRight = styled(BottomStar).attrs({
  d: `M841.6,900c0,2.5-0.1,5,0,7.5c0.1,1.6-0.6,2.4-2.2,2.5c-1.5,0.1-2.4-0.7-2.5-2.4
	c-0.2-4.3-0.3-8.5-0.4-12.8c-0.2-5.6,0-4.7-4.7-5c-2.2-0.2-4.4-0.2-6.6-0.5c-2-0.2-3-2.6-1.8-4c0.4-0.5,1.3-0.9,1.9-0.9
	c3,0.1,6,0.3,9,0.5c1.6,0.1,2.2-0.7,2.2-2.2c0.1-3.3,0.1-6.6,0.1-9.9c0-2.1,0-4.2,0.1-6.2c0.1-1.8,0.9-2.6,2.4-2.5s2.4,1.1,2.4,2.8
	c0,3.4-0.1,6.8-0.1,10.2c0,2,0,4,0,6.1c0,1.3,0.5,1.9,1.9,1.9c2.6,0,5.2,0,7.8,0c2.1-0.1,3,0.4,3,1.9c0.1,1.7-1,2.9-2.9,2.9
	c-2.5,0-5-0.1-7.5-0.2c-2.2,0-2.9,0.7-2.8,2.9c0.1,2.5,0,5,0,7.5C841.4,900,841.5,900,841.6,900z`,
})``;
export const BottomStarDot = styled(BottomStar).attrs({
  d: `M873,838.3c11.4-0.6,12.3-11.9,4.8-17.9c-0.2,0.2-0.3-0.3-0.5,0c-12.7-3.6-17.8,15.2-4.3,17.6C872.9,838.2,872.8,838.4,873,838.3z`,
})``;

export const Ring = styled.path`
  fill: #ffe7ee;
`;
export const RingBottomRight = styled(Ring).attrs({
  d: `M1165.7,652c11.5-0.1,19.5,9.7,19.1,20c-0.3,7.4-8.3,16.9-18.1,16.2
	c-10.4-0.8-18.1-7.6-17.6-18.6C1149.5,659.6,1156.9,651.7,1165.7,652z M1167,682.4c6.4,0.1,12-5.5,12.1-11.2
	c0.1-7.1-5.8-13.1-12.9-13.2c-5.1-0.1-11.5,7.3-11.9,13.2C1154.1,676.7,1162,682.5,1167,682.4z`,
})``;
export const RingTopRight = styled(Ring).attrs({
  d: `M1190,253.8c-7.3,0-13.1-5.7-13-12.7c0.1-7.8,5.4-13.2,13.2-13.1
	c7.3,0.1,12.8,5.9,12.6,13.5C1202.7,248.3,1197,253.8,1190,253.8z M1183.2,240.9c-0.1,3.7,2.7,6.6,6.6,6.6c4,0,6.9-2.7,7-6.6
	c0.1-3.7-3-6.8-6.8-6.8C1186.3,234.1,1183.2,237.2,1183.2,240.9z`,
})``;
export const RingCenterRight = styled(Ring).attrs({
  d: `M1004.4,609c8.8,0,17,8.4,16.9,17.4c0,9.4-8.7,17.8-18.2,17.6
	c-7.4-0.1-15.3-6.9-15.2-19.7C988.1,616.8,996.4,609,1004.4,609z M996.2,625.2c0.2,6.1,3.6,10.2,8.3,10.2c4.8-0.1,9.1-4.4,8.9-8.9
	c-0.2-6-4.6-8.4-9.4-8.9C1000.3,617.1,996.1,621.5,996.2,625.2z`,
})``;

export const PlanetRight = styled.path.attrs({
  d: `M1359.9,516.6c0.7,18,7.6,37-0.7,55.6c-12.2,28.9-38.1,60.6-69.9,67.6
	c-9,1-18.3-0.1-27.2-1.2c-5.1,1.7-10,2.8-14.8-2.2c-24.3-8.9-50.4-18.1-59.2-46C1129.8,471.9,1309.6,396.2,1359.9,516.6z`,
})`
  fill: #d9d9ed;
`;
export const PlanetTopRight = styled.path.attrs({
  d: `M1283.1,84.6c51.5-4.9,54.2,89.4,0.3,88.2C1220.9,179.8,1220.1,73.7,1283.1,84.6z`,
})`
  fill: #e5e5f1;
`;
export const PlanetLeft = styled.path.attrs({
  d: `M-75,687.7c8-76.3,100.8-80.8,124-11.9C58.7,763-70.5,777.4-75,687.7z`,
})`
  fill: #f1bdd0;
`;
