import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../Avatar';
import SkillsSelectionList from '../../../Composer/UnactivatedUserComposer/Customization/SkillsSelectionList';
import MessageBox from '../../../Composer/UnactivatedUserComposer/Customization/MessageBox';
import { Modal } from '../../../Modal';
import Button from '../../../Button';
import { isValidEmail } from '../../../../libs/emailValidator';
import { DEFAULT_REQUEST_MESSAGE } from '../../../../copy-consts';
import {
  SkillPropType,
  AdvisorPropType,
  MESSAGE_LENGTH,
  SKILL_SELECTION_TITLE,
  MESSAGE_SECTION_TITLE,
} from '../../consts';
import {
  ModalHeader,
  Body,
  Section,
  HeaderContainer,
} from './styles';

const INITIAL_STATE = {
  message: DEFAULT_REQUEST_MESSAGE,
};

export default class RequestFeedbackModal extends React.Component {
  static propTypes = {
    /** If the modal is open. */
    isOpen: PropTypes.bool.isRequired,
    /** Advisor selected. */
    selectedAdvisor: AdvisorPropType,
    /** List of available skills to choose from. */
    skillsList: PropTypes.arrayOf(SkillPropType),
    /** List of user skill ids. Used for selecting/deselecting skill pills */
    selectedSkillIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Function to select/deselect skills. */
    updateSelectedSkills: PropTypes.func.isRequired,
    /** Function to send request. */
    handleSendRequest: PropTypes.func.isRequired,
    /** Function to close modal. */
    handleCloseModal: PropTypes.func.isRequired,
    contentLabel: PropTypes.string,
  };

  constructor() {
    super();
    this.state = INITIAL_STATE;    
  }

  handleChangeMessage = (e, { message }) => {
    this.setState({ message });
  }

  handleClearMessage = () => {
    this.setState({ message: '' });
  }

  handleCloseModal = () => {
    this.props.handleCloseModal();
    this.setState(INITIAL_STATE);
  }

  handleSendFeedbackRequest = () => {
    const { message } = this.state;
    this.props.handleSendRequest(message);
    this.handleCloseModal();
  }

  render() {
    const { message } = this.state;
    const {
      isOpen,
      selectedAdvisor,
      skillsList,
      selectedSkillIds,
      updateSelectedSkills,
      contentLabel,
    } = this.props;
    const { email, photoUrl, fullName } = selectedAdvisor;
    const label = !contentLabel ? "Request Feedback Modal" : contentLabel;
    const messageLengthInLimits = message.length >= MESSAGE_LENGTH.MIN && message.length <= MESSAGE_LENGTH.ADD_ADVISORS_MAX;
    const canSend = isValidEmail(email) && messageLengthInLimits;

    return (
      <Modal
        contentLabel={label}
        size="large"
        isOpen={isOpen}
        transparent
        fullScreenMobile
        onClose={this.handleCloseModal}
      >
        <ModalHeader
          right={
            <Modal.Header.Buttons.Close
              onClick={this.handleCloseModal}
            />
          }
          title="Request Feedback"
        />
        <Modal.Body>
          <Body>
            <Section>
              <HeaderContainer>
                <Avatar url={photoUrl} size={48} />
                <h3>{fullName || email}</h3>
              </HeaderContainer>
            </Section>
            <Section>
              <SkillsSelectionList 
                animated={false}
                heading={SKILL_SELECTION_TITLE}
                listOptions
                orderedSkills={skillsList}
                updateSelectedSkills={updateSelectedSkills}
                skillIds={selectedSkillIds}
              />
            </Section>
            <Section>
              <MessageBox 
                heading={MESSAGE_SECTION_TITLE}
                customNote={message}
                clearText={this.handleClearMessage}
                onMessageChange={this.handleChangeMessage}
              />
            </Section>
          </Body>
        </Modal.Body>
        <Modal.Footer.ButtonContainer
          left={
            <Button.Simple
              color="black"
              onClick={this.handleCloseModal}
            >
              Cancel
            </Button.Simple>
          }
          right={
            <Button.Simple.Primary.Vivid
              color="green"
              disabled={!canSend}
              onClick={this.handleSendFeedbackRequest}
            >
              Send
            </Button.Simple.Primary.Vivid>
          }
        />
      </Modal>
    );
  }
}