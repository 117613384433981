import React, { useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { propType } from 'graphql-anywhere';
import {
  AvatarCard,
  ButtonNew as Button,
  Container,
} from '@matterapp/matter-ui';
import { AppLayout } from 'modules';
import FeedItemsList from 'modules/feed/FeedItemsList';
import { FEEDBACK_RECEIVED_QUERY } from 'graphql-queries/queries';
import { useSettingsPanelState } from 'routes/pages/workspaces/hooks/shared';

function FeedbackReceivedViewFeedbackPage(props) {
  const { feedbackReceivedData, handleError, handleStartReadyToGrow } = props;
  const { 
    feedbackFeedItemByFeedbackId, 
    loading, 
    error,
    currentUser
  } = feedbackReceivedData;
  const { 
    currentWorkspace: workspace
  } = useContext(UserContext);

  const feedItemsList = feedbackFeedItemByFeedbackId ? [feedbackFeedItemByFeedbackId] : [];
  const {
    headerProps
  } = useSettingsPanelState(workspace || {});

  useEffect(() => {
    if (error) {
      handleError();
    }
  }, [error]);

  function renderMainColumn() {  
    return (
      <FeedItemsList
        feedItemsList={feedItemsList}
        isLoading={!feedbackFeedItemByFeedbackId}
        feedItemsProps={{ expanded: true, showMenu: false }}
        feedItemFooterProps={{ expanded: true, autoFocus: false }}
      />
    );
  }

  function renderSideColumn() {
    if (loading) { 
      return null; 
    }

    const { payload } = feedbackFeedItemByFeedbackId;
    const { receiver } = payload;
    const { firstName, isOnboarded } = currentUser;

    if (isOnboarded) { 
      return null; 
    }

    return (
      <AvatarCard
        iconName="feedbackReceivedPageIcon"
        title={`Way to go ${firstName}!`}
      >
        You are helping your peer <strong>{receiver.fullName}</strong> receive
        actionable feedback. {`You're what makes Matter great!`}
        <Container marginTop={24} textAlign="center">
          <Button
            onClick={handleStartReadyToGrow}
            primary
            size="small"
          >{`Sign Up - It's Free`}</Button>
        </Container>
      </AvatarCard>
    );
  }

  if (error) { return null; }

  return (
    <>
      <Helmet title="View Feedback" />
      <AppLayout
        headerProps={headerProps}
        layout={AppLayout.Layouts.Main}
        mainColumn={renderMainColumn()}
        sideColumn={renderSideColumn()}
      />
    </>
  );
}

FeedbackReceivedViewFeedbackPage.propTypes = {
  feedbackReceivedData: propType(FEEDBACK_RECEIVED_QUERY).isRequired,
  handleStartReadyToGrow: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default FeedbackReceivedViewFeedbackPage;
