import React from 'react';
import { 
  Box,
  Typography,
  LinearProgress
} from '@mui/material';
import TextQuestionResponses from './TextQuestionResponses';
import styled from 'styled-components';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '32px',
  backgroundColor: theme.palette.blacks[5],
  '.MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.palette.blues[40]
  }
}));

export default function SelectQuestionResponses({ response, question, timezone }) {
  const { results = [] } = response || {};
  const comments = response?.comments.map(({ createdAt, message }) => ({
    answer: message, 
    createdAt
  }));
  const totalCount = results?.reduce((acc, result) => acc + result.count, 0) || 0;

  const { answerOptions } = question;

  return (
    <Box>
      <Box>
        {answerOptions.map((option) => {
          const result = results.find(({ answer }) => answer == option.value) || {};
          const { count = 0 } = result;
          const value = totalCount ? (count / totalCount) * 100 : 0;
          const responsesText = count === 1 ? 'response' : 'responses';

          return (
            <Box key={option.value} sx={{ mb: 4, mt: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1.5 }}>
                <Typography variant='body1'>{option.label}</Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography variant='body2' color='blacks.50' sx={{ mr: 1.5 }}>{count} {responsesText}</Typography>
                  <Typography variant='body1'><b>{Math.round(value)}%</b></Typography>
                </Box>
              </Box>
              <StyledLinearProgress 
                variant='determinate' 
                value={value}
              />
            </Box>
          );
        })}
      </Box>
      <Box sx={{ overflowY: 'scroll', maxHeight: '362px'}}>
        <TextQuestionResponses 
          response={{ results: comments }} 
          timezone={timezone} 
          noResultsText='No Comments Yet'
        />
      </Box>
    </Box>
  );
}
