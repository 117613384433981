import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  HeaderContainer,
  isMainHeader,
  Page,
  PageContainer,
} from './styles';
import { useSetStickyTop } from './utils';

const Base = ({ children, className, header, dockMobileHeader, noSpacing }) => {
  const headerRef = useRef();
  const top = useSetStickyTop(headerRef);

  return (
    <Page className={className} isMainHeader={isMainHeader(header)}>
      <PageContainer>
        <HeaderContainer
          ref={headerRef}
          dockHeader={dockMobileHeader}
          top={top}
          noSpacing={noSpacing}
        >
          {header}
        </HeaderContainer>
        {children}
      </PageContainer>
    </Page>
  );
};

Base.propTypes = {
  /** Additional style classes. */
  className: PropTypes.string,
  /** Page Content. */
  children: PropTypes.node,
  /** Header Content. */
  header: PropTypes.node,
  /** Docks the header to the top of the page on mobile. */
  dockMobileHeader: PropTypes.bool,
};

export default Base;
