import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PageContent from '../../../PageContent';

const Container = styled.div`
  max-width: 992px;
  overflow: hidden;
  min-height: calc(100vh - 97px - 64px);
  margin-left: auto;
  margin-right: auto;
`;

const NewSingleColumnTopAligned = ({ children, header, footer }) => (
  <React.Fragment>
    {header}
    <Container>
      <PageContent>{children}</PageContent>
    </Container>
    {footer}
  </React.Fragment>
);

NewSingleColumnTopAligned.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
};

NewSingleColumnTopAligned.defaultProps = {
  header: null,
  footer: null,
  children: null,
};

export default NewSingleColumnTopAligned;
