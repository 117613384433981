import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';
import { IconSpinner, IconNew } from '../../Icon';
import theme from '@matterapp/matter-theme';

/**
 * Wrapper for link component to omit props passed from styled component.
 * @param { Object } props: Link component props.
 * @returns { React.Component } Link component omitting unsupported props.
 */
const Link = (props) => {
  const ommitedProps = [
    'basic',
    'extraWide',
    'fluid',
    'iconPosition',
    'infoLink',
    'inherit',
    'inverted',
    'link',
    'loading',
    'minimal',
    'notification',
    'pill',
    'primary',
    'secondary',
    'transparent',
    'muted',
    'marketing',
  ];
  const dataObject = Object.create(null);
  const filteredProps = Object.assign(dataObject, props);
  ommitedProps.forEach((key) => {
    delete filteredProps[key];
  });

  return <ReactRouterLink {...filteredProps} />;
};

const ExternalLink = styled.a`
  color: white;
  display: flex !important;
  justify-content: center;
  align-items: center;
  :hover {
    color: white;
  }
  :active {
    color: white;
    text-decoration: none;
  }
`;

const inheritStyles = () => `
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  height: inherit;
  color: inherit;
  text-decoration: inherit;
`;


const normalStyles = ({
  background,
  backgroundActive,
  backgroundHover,
}) => `
  position: relative;
  overflow: hidden;
  color: white;
  border-width: ${() => theme.buttons.borderWidth};
  border-style: ${() => theme.buttons.borderStyle};
  border-color: transparent;

  background: ${background};
  transition:
    color 0.1s ease-in-out,
    border-color 0.1s ease-in-out,
    background 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;

  &::after:not([disabled]) {
    content: "";
    position: absolute;
      top: 0;
      left: 0;
    width: 100%;
    height: calc(100% + 3.5px);
  }

  &:hover {
    overflow: visible;
    background: ${backgroundHover || theme.colors.greens[40]};
  }

  &:disabled {
    cursor: default;
  }

  &:active {
    text-shadow: none;
    background-color: ${backgroundActive};
  }

  svg.icon-spinner {
    path {
      fill: white;
    }
  }

`;

const basicStyles = ({
  color,
  background,
  borderColor,
  boxShadowColor,
  borderRadius,
  active,
  disabled,
}) => `
  box-sizing: border-box;
  color: ${color};
  border-color: ${borderColor};
  border-style: solid;
  background: ${background};
  transition: box-shadow .1s ease;

  ${borderRadius &&
    `
    border-radius: ${borderRadius};
  `};

  &:hover {
    box-shadow: 0 2px 4px ${boxShadowColor};
  }

  &:active {
    color: ${active.color};
    border-color: ${active.borderColor};
  }

  &:disabled,
  &[disabled] {
    cursor: default !important;
    box-shadow: none !important;
    color: ${disabled.color} !important;
    background: ${disabled.background} !important;
    border-color: ${disabled.borderColor} !important;
  }
`;

const pillStyles = ({
  color,
  background,
  borderColor,
  boxShadowColor,
  borderRadius,
  active,
  disabled,
  checked,
  hover,
  size,
}) => `
  ${basicStyles({
    color,
    background,
    boxShadowColor,
    borderRadius,
    active,
    disabled,
    size,
  })}

  color: ${color};
  border-color: ${borderColor};
  background: ${background};

  font-weight: 400;

  transition: none;

  // Pill Specific Sizes
  ${size === 'small' &&
    `
    font-size: 17px;
    line-height: 41px;
    height: 44px;
  `};

  ${size === 'large' &&
    `
    font-size: 22px;
    line-height: 45px;
    height: 48px;
  `};

  &:hover {
    color: ${hover.color};
    border-color: ${hover.borderColor};
    box-shadow: none;
  }

  &:active {
    color: ${active.color};
    background: ${active.background};
    border-color: ${active.borderColor};
    box-shadow: 0 1px 2px ${active.boxShadowColor};
  }

  ${checked &&
    `
    &.checked {
      color: ${checked.color};
      background: ${checked.background};
      border-color: ${checked.borderColor};
      box-shadow: 0 1px 2px ${checked.boxShadowColor};
    }
  `};
`;

const minimalStyles = (props) => `
  color: ${props.theme.colors.blues[60]};
  font-size: 17px;
  font-weight: 400;
  height: 40px;
  line-height: 37px;
  background: ${props.theme.colors.white};
  border: 1.5px solid ${props.theme.colors.white};
  box-shadow:
    0px 1px 1px rgba(28, 28, 33, 0.15),
    0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  transition:
    transform 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;

  &::after {
    content: "";
    position: absolute;
      top: 0;
      left: 0;
    width: 100%;
    height: calc(100% + 3.5px);
  }

  &:hover:not([disabled]) {
    overflow: visible;
    transform: translateY(-2px);
  }

  &:active:not([disabled]) {
    color: ${props.theme.colors.purple};
  }

  &:disabled {
    cursor: default;
    color: ${props.theme.colors.blues[20]};
    text-decoration: line-through;
    background: ${props.theme.colors.blues[5]};
    border: 1.5px solid ${props.theme.colors.blues[20]};
    box-shadow: none;
  }
`;

/**
 * This is the new styling for ButtonNew to reflect the new landing page styling.
 * As we revamp the in app styling, hopefully we will start migrating to this new type of styling.
 */ 
const marketingStyles = () => `
  text-shadow: none !important;
  background: ${theme.colors.purples[50]};
  box-shadow: none;
  font-size: 17px;
  font-weight: bold;
  border-radius: 8px; 
  
  &:hover {
    background: ${theme.colors.purples[40]};
    box-shadow: 0px 4px 8px rgba(52, 55, 153, 0.32) !important;
    transform: none !important;
  }

  &:active {
    background: ${theme.colors.purples[50]};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.1) !important;
  }

  &[disabled] {
    cursor: default !important;
    box-shadow: none !important;
    border: none !important;
    color: white;
    font-size: 17px;
    background: ${theme.colors.purples[10]};
  }
`;

const getMutedStyles = ({ muted }) => {
  if (muted) {
    return `
      color: ${theme.colors.blacks[40]};
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: ${theme.colors.purple};
      }
    `;
  }

  return `
    color: ${theme.colors.blues[60]};
    &:hover {
      color: ${theme.colors.purple};
    }
    &:active {
      text-decoration: underline;
    }
  `;
};

const linkStyles = ({ muted }) => `
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  height: inherit;

  border: none;
  padding: 0;

  transition: color 0.1s ease-in-out;
  background: transparent;

  ${getMutedStyles({ muted })}
`;

const infoLinkStyles = () => `
  background: none;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  height: inherit;

  border: none;
  padding: 0;

  color: inherit;
  text-decoration: underline;
`;

const invertedStyles = () => `
  border-color: white;
  color: white;
  background: transparent;

  &:active,
  &:hover {
    background-color: white;
    color: ${theme.colors.black};
  }
`;

const iconStyles = ({ iconPosition }) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${iconPosition === 'right' &&
    `
    svg {
      margin-left: 6px;

      &:first-child {
        margin-left: 0;
      }
    }
  `};

  ${iconPosition === 'left' &&
    `
    svg {
      margin-right: 6px;

      &:last-child {
        margin-left: 0;
      }
    }
  `};
`;

const IconSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    vertical-align: middle;
  }
`;

const disabledStyles = () => `
  cursor: default;
  color: ${theme.buttonModes.disabled.color};
  background: ${theme.buttonModes.disabled.background};
  border:
    ${theme.buttons.borderWidth}
    ${theme.buttons.borderStyle}
    ${theme.buttonModes.disabled.borderColor};

  svg path {
    fill: ${theme.colors.blacks[40]};
  }
`;

const loadingStyles = () => `
  align-items: center;
  justify-content: center;
  display: inline-flex;

  @keyframes spin { 100% { transform:rotate(360deg) } }

  svg {
    padding: 0;
    animation: spin 4s linear infinite;
  }
`;

export const StyledButton = styled.button`
  // General Btn
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;

  // ExtraWide?
  padding: ${(props) => (props.extraWide && '0 32px') || '0 16px'};

  position: relative;
  text-align: center;
  border-style: solid;
  border-width: ${() => theme.buttons.borderWidth};

  // Btn Size Specific
  font-size: ${(props) =>
    (props.size === 'xsmall' && '14px') ||
    (props.size === 'small' && '17px') ||
    (props.size === 'medium' && '22px') ||
    (props.size === 'big' && '17px')};
  font-weight: 500;
  height: ${(props) =>
    (props.size === 'xsmall' && '32px') ||
    (props.size === 'small' && '48px') ||
    (props.size === 'medium' && '56px') ||
    (props.size === 'big' && '64px')};

  // line-height = height - (roundUp(borderWidth) * 2)
  line-height: ${(props) =>
    (props.size === 'xsmall' && '28px') ||
    (props.size === 'small' && '44px') ||
    (props.size === 'medium' && '52px')};

  // Fluid?
  width: ${(props) => (props.fluid && '100%') || 'auto'};

  > svg {
    display: block;
    vertical-align: middle;
  }

  transition:
    color .1s ease,
    border-color .1s ease,
    background-color .1s ease,
    box-shadow .1s ease;

  ${(props) => props.disabled && disabledStyles(props)}

  ${(props) =>
    props.notification &&
    !props.disabled &&
    normalStyles({
      ...props.theme.buttonModes.notification,
      hasFuzzyShadow: true,
    })}

  ${(props) =>
    props.primary &&
    !props.disabled &&
    normalStyles({
      ...props.theme.buttonModes.primary,
      hasFuzzyShadow: false,
    })}

  ${(props) =>
    props.basic &&
    !props.disabled &&
    basicStyles({
      ...props.theme.buttonModes.basic,
    })}
    
  ${(props) =>
    props.pill &&
    pillStyles({
      ...props.theme.buttonModes.pill,
      size: props.size,
    })}

  ${(props) => props.inverted && invertedStyles(props)}
  ${(props) => props.minimal && minimalStyles(props)}
  ${(props) => props.link && linkStyles(props)}
  ${(props) => props.infoLink && infoLinkStyles(props)}
  ${(props) => props.inherit && inheritStyles(props)}
  ${(props) => props.icon && iconStyles(props)}
  ${(props) => props.loading && loadingStyles(props)}
  ${(props) => props.marketing && marketingStyles(props)}

  ${(props) =>
    props.transparent &&
    `
    background-color: transparent;
  `}
`;

export const StyledButtonLink = StyledButton.withComponent(Link);
export const StyledButtonExternalLink = StyledButton.withComponent(ExternalLink);

const IconContainer = ({ icon, fill }) => {
  if (!icon) { return null; }
  const fillColor = !fill ? "currentColor" : fill;
  return <IconNew name={icon} fill={fillColor} />;
};

IconContainer.propTypes = {
  icon: PropTypes.string,
};

IconContainer.defaultProps = {
  icon: null,
};

class ButtonNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = { debouncedDisabled: props.disabled };
    this.debounceTimeout = null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.debounceDisabled) { return; }
    if (this.props.disabled) {
      this.clearDebounceTimeout();
      if (!this.state.debouncedDisabled) {
        this.setState({ debouncedDisabled: true }); //eslint-disable-line
      }
      return;
    }

    if (
      prevProps.disabled &&
      !this.props.disabled &&
      this.state.debouncedDisabled &&
      !this.debounceTimeout
    ) {
      this.debounceTimeout = setTimeout(() => {
        this.setState({ debouncedDisabled: false });
      }, 900);
    }
  }

  componentWillUnmount() {
    this.clearDebounceTimeout();
  }

  getDisabled = () => {
    const { disabled, debounceDisabled } = this.props;
    if (disabled) { return true; }
    if (!debounceDisabled) { return disabled; }
    return this.state.debouncedDisabled;
  };

  clearDebounceTimeout = () => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    }
  };


  render() {
    const {
      children,
      size,
      fluid,
      link,
      infoLink,
      basic,
      pill,
      primary,
      secondary,
      inverted,
      notification,
      minimal,
      inherit,
      onClick,
      onMouseDown,
      onMouseUp,
      to,
      href,
      className,
      loading,
      icon,
      iconPosition,
      style,
      extraWide,
      transparent,
      textStyle,
      rcLabel,
      muted,
      marketing,
      target,
      fill,
    } = this.props;

    let Component;
    if (to) {
      Component = StyledButtonLink;
    } else if (href) {
      Component = StyledButtonExternalLink;
    } else {
      Component = StyledButton;
    }

    return (
      <Component
        to={to}
        href={href}
        size={size}
        fluid={fluid}
        link={link}
        infoLink={infoLink}
        basic={basic}
        transparent={transparent}
        pill={pill}
        primary={primary}
        secondary={secondary}
        inverted={inverted}
        notification={notification}
        muted={muted}
        minimal={minimal}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        disabled={this.getDisabled()}
        className={`button ${className || ''}`}
        inherit={inherit}
        loading={loading}
        icon={icon}
        iconPosition={iconPosition}
        style={style}
        extraWide={extraWide}
        data-button-type={primary ? 'primary' : undefined}
        data-rc={rcLabel}
        marketing={marketing}
        target={target}
      >
        {!loading && icon && iconPosition === 'left' && (
          <IconContainer icon={icon} />
        )}
        {!loading && children && <span style={textStyle}>{children}</span>}
        {!loading && icon && iconPosition === 'right' && (
          <IconContainer icon={icon} fill={fill}/>
        )}
        {loading && (
          <IconSpinnerContainer>
            <IconSpinner />
          </IconSpinnerContainer>
        )}
      </Component>
    );
  }
}

ButtonNew.defaultProps = {
  children: null,
  size: 'medium',
  fluid: false,
  basic: false,
  pill: false,
  primary: false,
  secondary: false,
  inverted: false,
  notification: false,
  minimal: false,
  link: false,
  inherit: false,
  transparent: false,
  to: null,
  onClick: () => null,
  onMouseDown: () => null,
  onMouseUp: () => null,
  disabled: false,
  className: null,
  loading: false,
  icon: null,
  iconPosition: 'right',
  style: null,
  extraWide: false,
  debounceDisabled: false,
  muted: false,
  textStyle: {},
  marketing: false,
};

ButtonNew.propTypes = {
  rcLabel: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'big']),
  fluid: PropTypes.bool,
  basic: PropTypes.bool,
  transparent: PropTypes.bool,
  pill: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  inverted: PropTypes.bool,
  notification: PropTypes.bool,
  minimal: PropTypes.bool,
  link: PropTypes.bool,
  infoLink: PropTypes.bool, // Used on link buttons that opens up an info modal.
  to: PropTypes.string, // Will use a Link component when you set this...
  href: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  inherit: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  style: PropTypes.object, // eslint-disable-line
  extraWide: PropTypes.bool,
  debounceDisabled: PropTypes.bool,
  muted: PropTypes.bool,
  textStyle: PropTypes.object,
  marketing: PropTypes.bool,
  fill: PropTypes.string,
};

export default ButtonNew;
