import React from 'react';
import PropTypes from 'prop-types';

const LeftRight = ({ left, right }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <div>{left}</div>
    <div
      style={{
        marginLeft: 'auto',
        flex: 'none',
      }}
    >
      {right}
    </div>
  </div>
);

LeftRight.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
};

LeftRight.defaultProps = {
  left: null,
  right: null,
};

export default LeftRight;
