import times from 'lodash/times';
import React from 'react';
import PropTypes from 'prop-types';

import omitStyled from '../../libs/omitStyled';

const generateAnimationDelay = (n) =>
  times(
    n,
    (i) => `
  .list-container-item:nth-child(${i}),
  form > label:nth-child(${i}) > .button,
  form > label.checkboxPill:nth-child(${i}) {
    animation-delay: ${0.1 + i * 0.03}s;
  },
  label.radioPill:nth-child(${i}) {
    animation-delay: ${0.1 + i * 0.03}s;
  }
`
  );

const Container = omitStyled('div', ['stacked', 'animated', 'itemPadding'])`
  margin-bottom: 48px;
  text-align: center;

  .button,
  .checkboxPill,
  .radioPill {
    margin: 0 4px 8px 0;
    vertical-align: top; // required when bubble label text overflows
  }

  > div {
    padding: ${({itemPadding}) => itemPadding || 'inherit'}
  }

  .list-container-item + .list-container-item {
    margin-top: 14px;
  }

  label.checkboxPill, label.radioPill {
    display: inline-block;
  }

  ${({ stacked }) =>
    stacked ?
      `
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      margin-bottom: 8px;
      margin-right: 0;
      width: 279px;
    }
  ` : ''};

  ${({ animated }) =>
    animated ?
      `
    > *:not(form), > form > * {
      animation-name: popin;
      animation-fill-mode: both;
      animation-duration: .6s;
      animation-timing-function: ease;
      animation-delay: 0.1s;
    }
    ${generateAnimationDelay(34)}
  ` : ''};

  @keyframes popin {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const ListContainer = ({
  children,
  className,
  stacked,
  animated,
  alignCenter,
  alignLeft,
  style,
  itemPadding
}) => (
  <Container
    className={className}
    stacked={stacked}
    animated={animated}
    itemPadding={itemPadding}
    style={{
      textAlign: (alignCenter && 'center') || (alignLeft && 'left') || null,
      ...style,
    }}
  >
    {children}
  </Container>
);

ListContainer.defaultProps = {
  children: false,
  stacked: false,
  animated: false,
  alignCenter: false,
  alignLeft: false,
  style: {},
};

ListContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  stacked: PropTypes.bool,
  animated: PropTypes.bool,
  alignCenter: PropTypes.bool,
  alignLeft: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default ListContainer;
