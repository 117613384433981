import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import shuffleArray from '../../../libs/shuffleArray';
import AvatarMultiple from '../../Avatar/Multiple';

const MAX_AVATARS = 3;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const AvatarsContainer = styled(AvatarMultiple)`
  margin: 0 ${() => theme.spacing.single};

  ${() => theme.media.medium`
    margin: 0 ${() => theme.spacing.single} 0 0;
  `}
`;

const ClickableArea = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-wrap: wrap;

  ${() => theme.media.medium`
    flex-wrap: nowrap;
  `}
  & a {
    font-size: ${theme.fontSize.S};
  }
`;

class PeerSuggestionsPreview extends React.Component {
  shouldComponentUpdate = ({ peerSuggestions }) => {
    // Rerender only if list length changes.
    return peerSuggestions.length !== this.props.peerSuggestions.length;
  };

  render = () => {
    const {
      alwaysShowAvatars,
      defaultAvatarIsGenerated,
      linkTo,
      onActionClick,
      peerSuggestions,
    } = this.props;
    const peersWithPhotos = peerSuggestions.filter((peer) => peer.photoUrl);
    const photoUrls = peersWithPhotos.map((peer) => peer.photoUrl);
    const maxPossible = peerSuggestions.length < MAX_AVATARS ? peerSuggestions.length : MAX_AVATARS;

    if (alwaysShowAvatars && photoUrls.length <= maxPossible) {
      const currentAvatars = photoUrls.length;
      for (let i = currentAvatars; i < maxPossible; i++) {
        photoUrls.push('');
      }
    }

    const shuffledPhotoUrls = shuffleArray(photoUrls).slice(0, maxPossible);

    if (peerSuggestions.length === 0) { return null; }
    return (
      <Container>
        <ClickableArea onClick={onActionClick}>
          <AvatarsContainer
            defaultAvatarIsGenerated={defaultAvatarIsGenerated}
            urls={shuffledPhotoUrls}
            size={32}
            overlapWidth={4}
          />
          <Link to={linkTo}>Suggestions for you</Link>
        </ClickableArea>
      </Container>
    );
  };
}

PeerSuggestionsPreview.defaultProps = {
  alwaysShowAvatars: false,
  linkTo: '/peer-suggestions',
};

PeerSuggestionsPreview.propTypes = {
  alwaysShowAvatars: PropTypes.bool,
  defaultAvatarIsGenerated: PropTypes.bool,
  peerSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      photoUrl: PropTypes.string,
      fullName: PropTypes.string,
    })
  ).isRequired,
  onActionClick: PropTypes.func.isRequired,
  linkTo: PropTypes.string,
};

export default PeerSuggestionsPreview;
