/* istanbul ignore file */
import styled from 'styled-components';
import { Clickable } from '../../Clickable/Clickable';
import { IconNew as Icon } from '../../Icon';
import theme from '@matterapp/matter-theme';

const { times: transitionTime } = theme.transitions;

/***********************************************
 * Menu.Inline Styles                          *
 ***********************************************/
export const MenuIcon = styled(Icon).attrs(() => ({ name: 'more' }))`
  min-width: ${theme.spacing.singleAndHalf};
  overflow: visible;
  pointer-events: none;
  & path {
    fill: currentColor;
  }
`;
export const MenuButton = styled(Clickable)`
  color: currentColor;
  z-index: 10;
`;

export const MenuContainer = styled.div`
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  z-index: 5;
  transition: opacity ${transitionTime.single}, margin 0s ${transitionTime.single};
  pointer-events: none;
  transform: translateX(${theme.spacing.half});
  position: absolute;
`;

export const getMenuOpenStyles = () => {
  return `
    & ${MenuButton} {
      z-index: 5;
      ${MenuIcon} {
        opacity: 0;
      }
    }
    & ${MenuContainer} {
      opacity: 1;
      pointer-events: all;
      transition: opacity ${transitionTime.single};
      z-index: 10;
    }
  `;
};

export const getParentMenuOpenStyles = (parentHoverComponent) => {
  if (!!parentHoverComponent) {
    return `
      ${parentHoverComponent}:hover & {
        ${getMenuOpenStyles()}
      }
    `;
  } else {
    return `
      &:hover {
        ${getMenuOpenStyles()}
      }
    `;
  }
};

export const Container = styled.div`
  color: ${theme.colors.blacks[40]};
  min-width: ${theme.spacing.double};

  & ${MenuButton} {
    pointer-events: all;
    ${MenuIcon} {
      opacity: 1;
      transition: opacity ${transitionTime.double};
    }
  }
  & ${MenuContainer} {
    opacity: 0;
    background: ${theme.colors.white};
    box-shadow: -${theme.spacing.single} 0 ${theme.spacing.single} ${theme.spacing.quarter} ${theme.colors.white};
    transition: opacity ${transitionTime.singleAndHalf};
  }

  ${({ canExpandOnMobile, menuOpen, parentHoverComponent }) => {
    if (canExpandOnMobile && menuOpen) {
      return getMenuOpenStyles();
    } else {
      return theme.media.S`
        ${getParentMenuOpenStyles(parentHoverComponent)}
      `;
    }
  }}
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

/***********************************************
 * Menu.Inline.Item Styles                     *
 ***********************************************/
export const ItemContainer = styled.div`
  min-width: ${theme.spacing.singleAndHalf};
  & > button {
    margin: 0 !important;
  }
  & svg path {
    fill: currentColor;
  }

  ${({ disabled }) => {
    if (disabled) {
      const disabledColors = `
        color: ${theme.colors.blacks[20]};
        cursor: pointer;
        & svg path {
          fill: ${theme.colors.blacks[20]};
        }
        & button {
          cursor: not-allowed;
        }
      `;
      return `
        ${disabledColors}
        &:hover {
          ${disabledColors}
        }
      `;
    }
    return `
      &:hover {
        color: ${theme.colors.purples[50]};
        & svg path {
          fill: ${theme.colors.purples[50]};
        }
      }
    `;
  }}
`;

export const ItemContentWrapper = styled(Clickable)`
  color: inherit;
`;

export const ItemWrapper = styled.div`
  padding: ${theme.spacing.half};
  position: relative;
  
  & > button,
  & > ${ItemContentWrapper} {
    margin: 0 !important;
  }
  & svg {
    height: ${theme.spacing.singleAndHalf};
    width: auto;
  }
  ${theme.media.S`
    & > ${ItemContentWrapper} {
      margin: 0 !important;
      margin-left: 0 !important;
    }
  `}
`;

export const ItemIconWrapper = styled.div``;

export const TOOLTIP_STYLES = {
  minWidth: 50,
  pointerEvents: 'none',
};
