import React from 'react';
import { skillValueObjectPropType } from '@matterapp/skills';
import PropTypes from 'prop-types';
import omitStyled from '../../libs/omitStyled';

import { getSkillIcon } from '../../common/libs/get-skill-details';

const sizes = {
  mini: 35,
  mini2: 40, // this all needs to be renamed.
  mini3: 64,
  tiny: 80,
  small: 150,
  medium: 300,
  large: 450,
  big: 600,
  huge: 800,
  massive: 960,
};

const Image = omitStyled('img', ['size'])`
  display: inline-block;

  height: auto;
  width: 100%;
  max-width: ${({ size }) => sizes[size]}px;

  -webkit-touch-callout: none;

  ${({ size }) =>
    size === 'fluid' &&
    `
    max-width: none;
  `};

  ${({ 'data-clickable': clickable }) =>
    clickable &&
    `
    &:hover {
      cursor: pointer;
    }
  `};
`;

const Badge = ({ className, marginBottom, onClick, skill, size }) => {
  const src = getSkillIcon(skill);
  const isClickable = typeof onClick === 'function' || null;

  return (
    <Image
      className={className}
      src={src}
      alt={`Skill badge for: ${skill.name}`}
      size={size}
      style={{
        marginBottom,
      }}
      data-clickable={isClickable}
      onClick={onClick}
    />
  );
};

Badge.defaultProps = {
  size: 'small',
  className: undefined,
  marginBottom: 0,
  onClick: null,
};

Badge.propTypes = {
  className: PropTypes.string,
  marginBottom: PropTypes.number,
  onClick: PropTypes.func,
  skill: skillValueObjectPropType.isRequired,
  size: PropTypes.oneOf(Object.keys(sizes).concat(['fluid'])),
};

export default Badge;
