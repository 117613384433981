import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Form, InputCode, ButtonNew as Button } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import { Field as ReduxFormField } from 'redux-form';

const InputErrorContainer = styled.div`
  color: ${theme.inputs.errorTextColor};
  font-size: 14px;
  line-height: ${theme.lineHeight.S};
  text-align: left;
`;
class VerificationCodeField extends React.Component {
  getHandleChange = (reduxFormFieldProps) => (newValue) => {
    reduxFormFieldProps.input.onChange(newValue);
    this.props.onChange(newValue);
  };

  isFormValid = (formMeta) => {
    if (!formMeta.submitFailed) {
      return true;
    }
    return !formMeta.invalid;
  };

  renderDisabled = (reduxFormFieldProps) => {
    if (!reduxFormFieldProps.disabled) { return null; }
    return (
      <Button
        size="big"
        basic
        disabled
        loading
        fluid
        style={{ marginBottom: 32 }}
      />
    );
  };

  renderError = () => {
    const { errorMessage } = this.props;
    if (!errorMessage) { return null; }
    return <InputErrorContainer>{errorMessage}</InputErrorContainer>;
  };

  renderInput = (reduxFormFieldProps) => {
    if (reduxFormFieldProps.disabled) { return null; }
    const { fields, errorMessage, applyVerifyEmailViaCode } = this.props;
    return (
      <InputCode
        autofocus={reduxFormFieldProps.autofocus}
        type="tel"
        fields={fields}
        isValid={this.isFormValid(reduxFormFieldProps.meta)}
        {...reduxFormFieldProps.input}
        onChange={this.getHandleChange(reduxFormFieldProps)}
        marginBottom={32}
        applyVerifyEmailViaCode={applyVerifyEmailViaCode}
        errorMessage={ errorMessage || null }
      >
        {this.renderError()}
      </InputCode>
    );
  };

  renderField = (reduxFormFieldProps) => (
    <React.Fragment>
      {this.renderInput(reduxFormFieldProps)}
      {this.renderDisabled(reduxFormFieldProps)}
      {!reduxFormFieldProps.meta.submitFailed ? null : (
        <Form.Error errorString={reduxFormFieldProps.meta.error} />
      )}
    </React.Fragment>
  );

  render = () => (
    <ReduxFormField
      name="verificationCode"
      type="text"
      component={this.renderField}
      disabled={this.props.disabled}
      autofocus={this.props.autoFocus}
    />
  );
}

VerificationCodeField.propTypes = {
  autoFocus: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  fields: PropTypes.number,
  applyVerifyEmailViaCode: PropTypes.bool,
  errorMessage: PropTypes.string,
};

VerificationCodeField.defaultProps = {
  disabled: false,
  fields: 6,
  applyVerifyEmailViaCode: false,
};

export default VerificationCodeField;
