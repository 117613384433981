import React from 'react';
import BaseThemeProvider from '../BaseThemeProvider';
import PropTypes from 'prop-types';
import '@matterapp/matter-fonts/build/fonts.css';

const MatterThemeProvider = ({ children }) => (
  <BaseThemeProvider>
    {children}
  </BaseThemeProvider>
);

MatterThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MatterThemeProvider;
