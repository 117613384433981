import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { multipleEmailsValidation } from '../../../form-validations';
import { handleSubmitValidation } from '../../../libs';
import Toast from 'components/Toast/Toast';
import { DEFAULT_REQUEST_MESSAGE } from 'copy-consts';

const REQUEST_FEEDBACK_REDUX_FORM =
  '@social/composer-request-feedback-redux-form';

const REPLACE_TEXT = '%s';
const REQUEST_SENT_TEXT = `Your ${REPLACE_TEXT} requests are on their way`;
const REQUEST_SENT_TEXT_SINGULAR = 'Your request is on its way';
const REQUEST_SENT_TITLES = {
  1: 'That’s the ticket!',
  2: 'Nice work!',
};

const withRequestFeedback = ({ fieldName, sourceName }) =>
  compose(
    connect(
      null,
      (dispatch, { initialValues }) => ({
        resetEmailValues: () => {
          dispatch(
            change(REQUEST_FEEDBACK_REDUX_FORM, 'emails', initialValues.emails)
          );
        },
        updateEmailForm: () => {
          // Fix to trigger redux form to force a re-validate on the email form
          // by changing `updatedOn` value. This fixes the form from resetting
          // The Pristine and invalid state when toggling between modal steps.
          dispatch(
            change(REQUEST_FEEDBACK_REDUX_FORM, 'updatedOn', new Date())
          );
        },
      })
    ),
    reduxForm({
      form: REQUEST_FEEDBACK_REDUX_FORM,
      validate: multipleEmailsValidation,
      onSubmit: async (
        values,
        dispatch,
        {
          feedbackTemplate,
          handleActivateSuccessModeWithTimeout,
          handleRequestFeedbackFromMultiplePeersViaManualSendInvite, // From withPeerRecommendations, in this.props
          isCustomSurvey,
          message,
          onCloseRequestFeedbackModal,
          resetEmailValues,
          selectedSkillsIds,
        }
      ) => {
        await handleSubmitValidation({
          fieldName: fieldName,
          submit: async () => {
            const { emails } = values;
            const pickedPeers = emails
              .filter((email) => email)
              .map((email) => ({ email }));
            const inviteProps = {
              peers: pickedPeers,
              source: sourceName,
            };
            const numberOfRequests = pickedPeers.length;

            if (numberOfRequests < 1) {
              const errorMessage = (<span><strong>Oops! </strong>Something
                went wrong. Your request could not be sent</span>);
              return Toast.error(errorMessage);
            }

            if (isCustomSurvey) {
              inviteProps.skillIds = selectedSkillsIds;
              inviteProps.feedbackTemplateId = feedbackTemplate.id;
            }
            if (message !== DEFAULT_REQUEST_MESSAGE) {
              // If custom note is different from the default note, send as prop.
              inviteProps.customNote = message;
            }
            await handleRequestFeedbackFromMultiplePeersViaManualSendInvite(
              inviteProps
            );
            onCloseRequestFeedbackModal();
            resetEmailValues();
            handleActivateSuccessModeWithTimeout();

            const notificationTitleChoice = Math.floor(Math.random() * 2) + 1;
            const notificationFirstPart = REQUEST_SENT_TITLES[notificationTitleChoice];
            const notificationSecondPart = (numberOfRequests === 1) ?
              REQUEST_SENT_TEXT_SINGULAR :
              REQUEST_SENT_TEXT.replace(REPLACE_TEXT, numberOfRequests);
            Toast.success(`${notificationFirstPart} ${notificationSecondPart}`);
          },
        });
        return null;
      },
    })
  );

export default withRequestFeedback;
