import typography from './typography';

export const shared = `
  ${typography}

  .matter-profile-card-preview {
    z-index: 101;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
  }

  .react-tiny-popover-container {
    z-index: 105;
  }
`;
