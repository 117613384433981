"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.breakpoint = exports.default = exports.createBreakpointStyle = exports.getBreakpointEmSize = void 0;

// https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md#media-templates
// Usage
//
// We write styles mobile first -- base styles for smallest screen, use
// breakpoints for larger sizes. `media` hangs off the theme.
//
// import styled from 'styled-components';
// const SomeContainer = styled.div`
//   font-size: .9rem;
//
//   ${(props) => props.theme.media.medium`
//     font-size: 1rem;
//   `}
//
//   ${(props) => props.theme.media.large`
//     font-size: 1.4rem;
//   `}
// `;
// Use em in breakpoints to work properly cross-browser and support users
// changing their browsers font-size: https://zellwk.com/blog/media-query-units/
const getBreakpointEmSize = pxWidth => parseInt(pxWidth, 10) / 16;
/**
 * Generates a breakpoint width-specific style.
 * @param { String } breakpoint: The breakpoint width in pixels.
 * @param { String } styles: The styles for the breakpoint.
 * @param { Boolean } toString: If the returning styles should be returned as a string,
 *                              else it returns a css object for styled-components.
 */


exports.getBreakpointEmSize = getBreakpointEmSize;

const createBreakpointStyle = (breakpoint, styles, toString = true) => {
  const {
    css
  } = require('styled-components');

  const emSize = getBreakpointEmSize(breakpoint);
  const generatedCss = css`
    @media screen and (min-width: ${emSize}em) {
      ${styles}
    }
  `;
  return toString ? generatedCss.join('') : generatedCss;
};

exports.createBreakpointStyle = createBreakpointStyle;

var _default = breakpoints => {
  // Iterate through the breakpoints and create a media template
  const {
    css
  } = require('styled-components');

  return Object.keys(breakpoints).reduce((accumulator, label) => {
    // eslint-disable-next-line no-param-reassign
    accumulator[label] = (...args) => createBreakpointStyle(breakpoints[label], css(...args), false);

    return accumulator;
  }, {});
}; // Usage:
//
// const StyledCard = styled.div`
//   ${({ theme }) => theme.media.breakpoint(480)`
//     border-radius: 12px;
//   `}
// `;
//
// NOTE: If you pass in a string, the breakpoint will be used as is.
//       If you pass in a number, it will automatically be converted to use em measurments.


exports.default = _default;

const breakpoint = (size, convertToEm = true) => {
  const {
    css
  } = require('styled-components');

  const convertedSize = convertToEm ? `${getBreakpointEmSize(size)}em` : `${size}px`;
  return (...args) => css`
    @media screen and (min-width: ${typeof size === 'string' ? size : convertedSize}) {
      ${css(...args)}
    }
  `;
};

exports.breakpoint = breakpoint;