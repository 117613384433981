import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Logo, Button } from '@matterapp/matter-ui';
import { ENV_VARS } from 'app-consts/env-vars';
import Toast from 'components/Toast/Toast';

const Container = styled.div`
  margin-top: ${theme.spacing.single};
  text-align: center;
  height: 100%;
`;

const Content = styled.div`
  position: absolute;
  text-align: center;
  top: 35%;
  width: 100%;
  // We have an href, but style it like standard Button
  & a {
    display: inline-block;
    line-height: 30px;
    vertical-align: middle;
  }
  & a:hover {
    color: ${theme.colors.blacks[60]};
    text-decoration: none;
  }
  @media screen and (max-width: ${theme.breakpoints[3]}) {
    top: 25%;
  }
`;

const Headline = styled.div`
  font-size: 48px; // Do we need an XL in theme?
  font-weight: ${theme.fontWeight.bold};
  line-height: 64px;
  margin-bottom: ${theme.spacing.tripleAndHalf};
  @media screen and (max-width: ${theme.breakpoints[3]}) {
    font-size: ${theme.fontSize.XL};
    line-height: 48px;
    padding: 0 24px;
  }
`;

class AccountClosed extends React.Component {

  async componentDidMount() {
    Toast.error('Your Matter account has been closed');
  }

  render() {
    return (
      <Container>
        <Logo size="small"/>
        <Content>
          <Headline>
            We’re always here if you need us.<br/>
            Please visit again soon!
          </Headline>
          <Button.Simple
            size="M"
            color="black"
            disabled={false}
            content="Back to Home"
            className="home-button"
            href={ENV_VARS.LOGGED_OUT_PATH}
            data-rc="close-account-home-button"
          />
        </Content>
      </Container>
    );
  }
}

export default AccountClosed;

