import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import omitStyled from '../../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

import {
  IconSocialGoogle,
  IconSocialSlack,
  IconEmail,
} from '../../Icon';
import IconSocialTeams from '../../Icon/svg/IconSocialTeams';

const transitionTime = theme.transitions.times.default;
const transitionStyles = `
  transition: background ${transitionTime}, background-size ${transitionTime} ease-in-out, border ${transitionTime}, color ${transitionTime};
`;

const getButtonStyles = ({ primary, secondary }) => {
  if (primary) {
    return `
      background-color: ${theme.colors.purples[50]};
      color: white;
      border: 2px solid ${theme.colors.purples[50]};
      &:hover {
        background-color: ${theme.colors.purples[40]};
        border: 2px solid ${theme.colors.purples[40]};
      }
      &:active {
        background-color: ${theme.colors.purples[60]};
        border: 2px solid ${theme.colors.purples[60]};
      }
    `;
  } else if (secondary) {
    return `
      background-color: ${theme.colors.blacks[90]};
      color: white;
      border: 2px solid ${theme.colors.blacks[90]};
    `;
  }
};

const ButtonBase = omitStyled('button', ['success', 'type'])`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0 20px;
  line-height: 24px;
  font-family: CircularXX;
  font-size: ${theme.fontSize.S2};
  font-weight: ${theme.fontWeight.black};
  text-align: center;
  cursor: pointer;
  color: ${theme.colors.blacks[90]};
  background-color: white;
  border: 2px solid ${theme.colors.blacks[90]};
  border-radius: ${theme.borderRadius.M};
  width: 100%;
  height: 56px;
  &:hover {
    color: white;
    background-color: ${theme.colors.blacks[90]};
    border: 2px solid ${theme.colors.blacks[90]};
  }
  &:active {
    color: white;
    background-color: ${theme.colors.blacks[80]};
    border: 2px solid ${theme.colors.blacks[80]};
  }

  ${getButtonStyles}
  ${transitionStyles};
`;

const IconContainer = styled.div`
  width: ${theme.spacing.singleAndHalf};
  height: ${theme.spacing.singleAndHalf};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LabelContainer = styled.div`
  display: flex;
  margin-left: ${theme.spacing.half};
`;

const socialIconMap = {
  'google': IconSocialGoogle,
  'slack': IconSocialSlack,
  'email': IconEmail,
  'teams': IconSocialTeams
};

class ButtonSocial extends React.Component {
  renderIcon() {
    const { icon } = this.props;
    const IconComponent = socialIconMap[icon];

    return <IconComponent/>;
  }
  
  render() {
    const { onClick, buttonText, primary, secondary, className } = this.props;

    return (
      <ButtonBase
        onClick={onClick}
        primary={primary}
        secondary={secondary}
        className={className}
      >
        <IconContainer>
          {this.renderIcon()}
        </IconContainer>
        <LabelContainer>
          {buttonText}
        </LabelContainer>
      </ButtonBase>
    );
  }
}

ButtonSocial.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.string
};

export default ButtonSocial;
