import React, { useState } from 'react';
import { Modal, Input } from '@matterapp/matter-ui';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import createPulseSubcategory from 'graphql-queries/mutations/pulse/createPulseSubcategory';
import getTenantPulseCategories from 'graphql-queries/queries/pulse/getTenantPulseCategories';
import { useMutation } from '@apollo/client';
import Toast from 'components/Toast/Toast';

const StyledModal = styled(Modal)({
  zIndex: 100
});

const StyledInput = styled(Input)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: '100%'
}));

const Wrapper = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(5)} ${theme.spacing(3)}`
}));

export default function AddSubcategoryModal(props) {
  const {
    isOpen,
    onClose,
    tenantId,
    category
  } = props;
  const [subcategoryName, setSubcategoryName] = useState('');
  const [createSubcategory] = useMutation(createPulseSubcategory);
  const { subcategories } = category;
  const nameError = subcategoryName?.length > 255 ? 'Name must be at most 255 characters' : null;
  const isDuplicate = subcategories?.find(({ name }) => name.toLowerCase() === subcategoryName?.trim().toLowerCase());

  function resetAndClose() {
    setSubcategoryName('');
    onClose();
  }

  return (
    <StyledModal 
      isOpen={isOpen} 
      onClose={resetAndClose}
      size="large"
    >
      <Modal.Header
        header='Add Subcategory'
        right={<Modal.Buttons.CloseIcon onClick={resetAndClose} />}
      />
      <Modal.Body>
        <Wrapper>
          <Typography variant='h5' component='p'>
            Subcategory will be added to {category.name}:
          </Typography>
          <StyledInput
            type="text"
            value={subcategoryName}
            rcLabel="category-title-input"
            placeholder="Enter title"
            showErrorMessageBelow
            errorMessage={nameError || isDuplicate ? 'Subcategory already exists' : null}
            onChange={(event) => {  
              setSubcategoryName(event.target.value);
            }}
          />
        </Wrapper>
      </Modal.Body>
      <Modal.Footer.WithCancelSave
        canClickSave={subcategoryName?.trim().length > 1 && subcategoryName?.trim().length < 256 && !isDuplicate}
        onClickCancel={resetAndClose}
        saveLabel='Save'
        cancelLabel='Close'
        onClickSave={async () => {
          await createSubcategory({ 
            variables: { 
              tenantId,
              name: subcategoryName.trim(),
              categoryId: category.id
            },
            refetchQueries: [{
              query: getTenantPulseCategories,
              variables: { tenantId }
            }]
          });
          resetAndClose();
          Toast.success('Subcategory added');
        }}
      />
    </StyledModal>
  );
};
