import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from '@matterapp/matter-ui';
import { MiniProfile, NotificationsModal } from 'modules';
import {
  useAppHeaderData,
  useAppHeaderLinks,
  useNotificationsModal,
  useProfileMenuState,
} from '../hooks/AppHeaderHooks';
import { getTabs, profileLinks } from './consts';
import { useProfileModalState } from 'modules/profile/hooks';

const AppHeader = (props) => {
  const { onClickBrand } = useAppHeaderLinks();
  const {
    activeProfileTab,
    currentUser,
    tabProps,
    sharedNavProps,
  } = useAppHeaderData(props);
  const { workspacesProps = {}, showZeroWorkspaces } = sharedNavProps || {};
  const {
    openNotificationsModal,
    ...notificationsModalProps
  } = useNotificationsModal();
  const {
    isProfileMenuOpen,
    isMobileProfileMenuOpen,
    onCloseMobileProfileMenu,
    onCloseProfileMenu,
    onOpenMobileProfileMenu,
    onOpenProfileMenu,
  } = useProfileMenuState({ activeProfileTab });

  const profileModalProps = useProfileModalState({onCloseMobileProfileMenu, currentUser});
  const profileMenuLinks = profileLinks(profileModalProps.onClickOpenProfileModal);

  if (!currentUser) {
    return null;
  }
  const tabs = getTabs({ ...tabProps, workspacesProps });

  const miniProfile = (
    <MiniProfile isCompact workspaceId={workspacesProps.activeId} hidePending={workspacesProps.activeWorkspace?.isExpired}/>
  );

  if (showZeroWorkspaces) {
    profileMenuLinks.splice(0, 2);
  }

  const sharedNavBarProps = {
    ...sharedNavProps,
    activeProfileTab,
    hasBeenNotified: true,
    isProfileMenuOpen,
    isMobileProfileMenuOpen,
    myStuffUrl: tabs.feedbacks.to,
    onClickBrand,
    onClickNotifications: openNotificationsModal,
    onCloseMobileProfileMenu,
    onCloseProfileMenu,
    onOpenMobileProfileMenu,
    onOpenProfileMenu,
    profileLinks: profileMenuLinks,
    profileLinksMobile: profileMenuLinks,
    tabs: [tabs.feedbacks, tabs.skills],
    user: currentUser,
    noSpacing: props.noSpacing,
  };
  return (
    <>
      <NavBar.Main {...sharedNavBarProps} mobileMenu={miniProfile} />
      <NotificationsModal {...notificationsModalProps} workspaceId={workspacesProps.activeId}/>
    </>
  );
};

AppHeader.defaultProps = {
  version: 3,
};

AppHeader.propTypes = {
  /** Layout Version to determine which header to use. */
  version: PropTypes.number,
  noSpacing: PropTypes.bool,
};

export default AppHeader;
