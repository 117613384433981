import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7,7v10h10v1H6V7H7z M16.5,7C16.8,7,17,7.2,17,7.5v5c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5V8.7
      l-5.5,5.5c-0.2,0.2-0.5,0.2-0.7,0c-0.2-0.2-0.2-0.5,0-0.7L15.3,8h-3.8C11.2,8,11,7.8,11,7.5S11.2,7,11.5,7H16.5z"
    />
  </svg>
);

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
