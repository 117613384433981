import React, { useContext } from 'react';
import { AppLayout } from 'modules';
import UserContext from 'context/UserContext/UserContext';
import getMembers from 'graphql-queries/queries/workspace/getMembers';
import { useQuery } from '@apollo/client';
import WorkpacesSideColumn from '../WorkspacesSideColumn';
import WorkspacesMembersList from './WorkspacesMembersList';
import ActionModals from '../ActionModals';
import { useSettingsPanelState } from 'routes/pages/workspaces/hooks/shared';
import {
  useActionModals,
  useActionModalSelector,
  useRecurringModal,
} from '../../hooks/WorkspaceActionModalHooks';
import { useWorkspaceFeedQuery } from '../WorkspacesActivityFeed/hooks';

const WorkspacesMembersPage = (props) => {
  const { pickedSkillsList, selectedPeer, initUserSkillsList, setDefaultMessage, onChangeSelectedAdvisor } = props;
  const { resetFeed } = useWorkspaceFeedQuery();
  const { 
    currentWorkspace: workspace, 
    currentWorkspaceId: workspaceId,
    currentUser,
    currentTenant,
    isLoadingCurrentWorkspace
  } = useContext(UserContext);
  const modalProps = useActionModals();
  const actionModalSelectorProps = useActionModalSelector({
    ...modalProps,
    pickedSkillsList,
    selectedPeer,
    initUserSkillsList,
    setDefaultMessage,
    currentUser
  });

  const { data: membersData, loading: isLoadingMembers } = useQuery(getMembers, {
    variables: { workspaceId, limit: 50 },
    skip: !workspaceId
  });

  const { workspaceMembers: { members: acceptedMembers } } = membersData || { workspaceMembers: { members: [] } };

  const { id, name } = workspace || {};
  const {
    footer,
    headerProps,
    workspaceSideColumnProps,
    workspaceSettingsProps,
  } = useSettingsPanelState(workspace);

  const recurringModalProps = useRecurringModal({ workspaceId: id, acceptedMembers, onChangeSelectedAdvisor, currentUser});
  const isListLoading = isLoadingCurrentWorkspace || isLoadingMembers;

  if (isLoadingCurrentWorkspace || !workspace) {
    return null;
  }

  return (
    <>
      <AppLayout
        headerProps={headerProps}
        footer={footer}
        layout={AppLayout.Layouts.Main}
        pageTitle={name}
        mainColumn={
          <>
            <WorkspacesMembersList
              {...workspaceSettingsProps}
              {...props}
              isLoading={isListLoading}
              workspaceMembers={acceptedMembers}
              acceptedMembers={acceptedMembers}
              currentUser={currentUser}
              handleSelectMember={recurringModalProps.handleSelectMember}
              actionModalSelectorProps={actionModalSelectorProps}
            />
          </>
        }
        mobileProps={{
          showSideColumn: false,
        }}
        sideColumn={
          <WorkpacesSideColumn
            {...workspaceSideColumnProps}
            workspace={workspace}
            currentUser={currentUser}
            workspaceMembers={acceptedMembers}
            showList={false}
          />
        }
      />
      <ActionModals 
        {...props}
        {...modalProps}
        {...recurringModalProps}
        resetFeed={resetFeed}
        workspace={workspace}
        tenant={currentTenant}
        currentUser={currentUser}
        actionModalSelector={actionModalSelectorProps.actionModalSelector}
      />
    </>
  );
};

export default WorkspacesMembersPage;
