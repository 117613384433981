import React from 'react';
import AccountSettings from './AccountSettings';
import FeatureSettings from './FeatureSettings';
import TeamSettings from './TeamSettings';
import { SettingsWrapper, MobileSettingsHeader } from './styles';
import { useQueryParams } from 'hooks';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { 
  Typography
} from '@mui/material';

export default function WorkspaceSettingsList() {
  const { queryParams } = useQueryParams();
  const isChannelSetting = queryParams.isChannelSetting;
  const label = isChannelSetting ? 'Channel Settings' : 'Settings';

  return (
    <SettingsWrapper>
      <MobileSettingsHeader>
        <Typography variant='body1'>{label}</Typography>
      </MobileSettingsHeader>
      <BreadCrumbs
        hideOnMobile
        crumps={[
          {
            label,
            href: null
          }
        ]}
      />
      <AccountSettings isChannelSetting={isChannelSetting} />
      <FeatureSettings isChannelSetting={isChannelSetting} />
      <TeamSettings isChannelSetting={isChannelSetting} />
    </SettingsWrapper>
  );
}
