import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.27047 7.44533C3.6482 7.04242 4.28103 7.02201 4.68394 7.39974L12 14.2585L19.3161 7.39974C19.719 7.02201 20.3518 7.04242 20.7295 7.44533C21.1073 7.84825 21.0869 8.48108 20.6839 8.85881L12 17L3.31606 8.85881C2.91315 8.48108 2.89274 7.84825 3.27047 7.44533Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#3466F2',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
