import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { CloseIcon } from '../Buttons';

const WithClose = (props) => {
  const { hideClose, onClickClose, ...otherProps } = props;

  return (
    <Header
      right={hideClose ? null : <CloseIcon onClick={onClickClose} />}
      {...otherProps}
    />
  );
};

WithClose.propTypes = {
  /** If close button is hidden. */
  hideClose: PropTypes.bool,
  /** Callback when close button is clicked. */
  onClickClose: PropTypes.func,
};

export default WithClose;
