import React from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { ENV_VARS } from 'app-consts';

const stripePromise = loadStripe(ENV_VARS.STRIPE_PUBLISHABLE_KEY);
function StripeBilling({ onSubmit, onPaymentComplete, subscription, workspaceId }) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm 
        onSubmit={onSubmit} 
        onPaymentComplete={onPaymentComplete} 
        subscription={subscription}
        workspaceId={workspaceId}
      />
    </Elements>
  );
}

StripeBilling.propTypes = {
  onSubmit: PropTypes.func,
  onPaymentComplete: PropTypes.func,
  workspaceId: PropTypes.number,
  subscription: PropTypes.object
};

export default StripeBilling;
