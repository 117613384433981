import React from 'react';
import styled from 'styled-components';
import DocumentHead from 'components/atoms/DocumentHead';
import ResponseFlow from './ResponseFlow';
import { updateIntercom } from 'libs/tracking/intercom';
import { mixpanel } from 'libs/tracking';

export const PAGE_TITLE = 'Feedback';
export const EVENT_NAME = 'recurring-flow';

const handleTrackEvent = {
  viewSkillSelectionStep: mixpanel.createEventSender(`${EVENT_NAME}:opening-page:viewed`),
  viewSkillStep: mixpanel.createEventSender(`${EVENT_NAME}:skill:viewed`),
  viewSkillNotesStep: mixpanel.createEventSender(`${EVENT_NAME}:note-step:viewed`),
  submitFeedback: mixpanel.createEventSender(
    `${EVENT_NAME}:submitted`
  ),
};

const TimelineContainer = styled.div`
  max-height: 100vh;
`;

export default class RecurringResponseFlowPage extends React.PureComponent {
  static defaultProps = {
    actions: {},
    mutations: {},
    receiver: {
      fullName: '',
    },
    skillsToRate: [],
  };

  state = {
    isSaving: false,
  }

  componentDidUpdate = (prevProps) => {
    const { loading, mutations } = this.props;
    if (prevProps.loading && !loading && this.hasSkillsToRate()) {
      mutations.beginFeedback()
        .then(() => {
          this.initializeTimeline();
          this.trackViewSteps();
        })
        .catch((err) => {
          throw err;
        });
    } 
    this.setShowSavingMessage();
  };

  componentWillUnmount() {
    this.props.actions.resetTimeline();
  }

  /**
   * Sets the onbeforeunload function if there are currently saving actions.
   * @returns { void }
   */
  setShowSavingMessage = () => {
    const { saveHashes } = this.props;
    if (saveHashes.length) {
      // Show message if timeline is saving and window is closing.
      // NOTE: Only IE will show this custom message otherwise its up to the browser.
      window.onbeforeunload = () => 'Are you sure you want to leave?';
    } else {
      window.onbeforeunload = null;
    }
  };

  /**
   * Track current step on initial load.
   * @returns { void }
   */
  trackViewSteps = () => {
    const { currentRecurringStep, totalSteps } = this.props;
    if (currentRecurringStep === 1) {
      handleTrackEvent.viewSkillSelectionStep();
    } else if (currentRecurringStep === totalSteps) {
      handleTrackEvent.viewSkillNotesStep();
    } else {
      handleTrackEvent.viewSkillStep();
    }
  };

  /**
   * @returns { Boolean } If the timeline has any skills to rate.
   */
  hasSkillsToRate = () => {
    const { initializeData } = this.props;
    return (
      initializeData &&
      initializeData.skillsToRate &&
      initializeData.skillsToRate.length
    );
  };

  /**
   * Callback when next button is click to go to next step.
   * @returns { void }
   */
  handleNextStep = async (stepProps) => {
    const { currentRecurringStep, actions } = this.props;
    const { skillId } = stepProps;

    if (skillId && currentRecurringStep !== 1) {
      await this.handleFinishCurrentSkillStep(skillId);
    } else if (currentRecurringStep === 1) {
      await this.handleSaveFirstStep();
    }
    actions.changeCurrentRecurringStep(currentRecurringStep + 1);
  };

  /**
   * Callback when next button is click to go to prev step.
   * @returns { void }
   */
  handlePrevStep = async (stepProps) => {
    const { currentRecurringStep, actions } = this.props;
    const { skillId, isLastStep } = stepProps;

    if (skillId && !isLastStep) {
      await this.handleFinishCurrentSkillStep(skillId);
    } else if (isLastStep) {
      await this.handleSaveSkillNotes();
    }
    actions.changeCurrentRecurringStep(currentRecurringStep - 1);
  }

  /**
   * Changes the current step of the timeline.
   * @param { Object } e: The change event.
   * @param { Object } timelineProps: {
   *   @param { Number } currentRecurringStep: The new current step of the timeline.
   * }
   * @returns { void }
   */
  handleChangeCurrentStep = (e, timelineProps) => {
    this.props.actions.changeCurrentRecurringStep(timelineProps.currentStep);
  };

  handleSaveFirstStep = () => {
    const { feedbackRating, currentSkillsIds, feedbackId, mutations } = this.props;
    const rating = JSON.parse(feedbackRating);

    return Promise.all([
      mutations.rateFeedback({ rating, feedbackId }), 
      mutations.selectSkillsForFeedback({ skillIds: currentSkillsIds, feedbackId })
    ]);
  }

  handleRateFeedback = (e, currentRating) => {
    this.props.actions.changeFeedbackRating(currentRating);
  }

  /**
   * Changes the skill ids to rate on.
   * @param { Object } e: The change event.
   * @param { Object } skill: Skill selected
   * @returns { void }
   */
  handleChangeSkillsIds = (e, skill) => {
    this.props.actions.changeCurrentSkillsIds(skill);
  };

  /**
   * Callback update skill in redux state.
   * @param { Object } e: The change event.
   * @param { Object } skillProps: {
   *   @param { Array } currentAbilities: List of current selected abilities of the skill.
   *   @param { Number } stepNumber: The number of the step.
   *   @param { Object } skillToRate: the updated skill to put into redux.
   * }
   * @returns { void }
   */
  handleUpdateSkill = (e, skillProps) => {
    const {
      currentAbilities,
      skillToRate: updatedSkill,
    } = skillProps;
    const skillToRate = { ...updatedSkill, currentAbilities };
    this.props.actions.changeCurrentSkillRatings({ isRecurring: true, skillToRate, ...skillProps });
  };

  /**
   * Finishes the current skill step by saving data to backend.
   * @param { Object } currentSkillToRate: The current skill to save data for.
   * @returns { void }
   */
  handleFinishCurrentSkillStep = async (skillToSaveId) => {
    const { feedbackId, mutations, skillsToRate } = this.props;
    const currentSkillToRate = skillsToRate.filter(skillToRate => skillToRate.skill.id === skillToSaveId)[0];

    if (currentSkillToRate) {
      const {
        actionItemSelections,
        currentRating: rating,
        skill: { id },
      } = currentSkillToRate;
      const selectedActionItems = actionItemSelections.filter(actionItem => actionItem.selected);
      const abilityIds = selectedActionItems.map(actionItem => actionItem.ability.id);
      const baseSkillProps = { feedbackId, skillId: id };
      if (rating) {
        await mutations.rateSkill({ ...baseSkillProps, rating });
      }
      if (abilityIds) {
        await mutations.pickActionItemsForSkill({ ...baseSkillProps, abilityIds });
      } 
    }
  };

  handleSaveSkillNotes = async () => {
    const { feedbackId, mutations, skillsToRate, currentSkillsIds } = this.props;
    const finalSkills = [];
    
    for (const skillToRate of skillsToRate) {
      for (const skillId of currentSkillsIds) {
        if (skillToRate.skill.id === skillId) {
          finalSkills.push(skillToRate);
        }
      }
    }

    const skills = finalSkills.map(skillToSave => {
      const {
        currentNote: note,
        defaultNote,
        skill: { id },
      } = skillToSave;

      const noteValue = note ? note.trim() : null;
      const noteToSave = defaultNote === noteValue ? null : noteValue; // Don't save if note is the same as generated note.
      return {skillId: id, note: noteToSave};
    });

    await mutations.rateSkillBatch(feedbackId, skills);
  }

  /**
   * Finishes the response flow by saving data to backend.
   * @param { Object } e: The finish event.
   * @param { Object } props: {
   *   @param { Object } navProps: Props from the timeline to build out the nav buttons
   *   @param { Object } currentSkillToRate: If current step is a skill step, save data of the skill.
   * }
   * @returns { void }
   */
  handleSubmitFeedbackRequest = async (isLastStep) => {
    if (isLastStep) {
      this.setState({ isSaving: true });
      handleTrackEvent.submitFeedback();
      await this.handleSaveSkillNotes();
      await this.props.mutations.endFeedback();
    }
  };

  /**
   * Initializes the timeline with data from the server.
   * @returns { void }
   */
  initializeTimeline = () => {
    this.props.actions.initializeTimeline(this.props.initializeData);
  };

  getSortedSkills = () => {
    const { skillsToRate } = this.props;
    
    return [...skillsToRate].sort((a, b) => {
      const first = a.skill.name;
      const second = b.skill.name;
      return first.toLowerCase() > second.toLowerCase() ? 1 : -1;
    });
  };

  renderTimeline = () => {
    const {
      receiver,
      feedbackRating,
      currentSkillsIds,
      currentRecurringStep,
      currentSkillsToRate,
      skillsToRate,
      totalSteps,
      recurringFeedback,
    } = this.props;

    if (!this.hasSkillsToRate()) {
      return null;
    }

    return (
      <>
        <DocumentHead title={PAGE_TITLE} />
        <TimelineContainer>
          <ResponseFlow.Timeline
            isSaving={this.state.isSaving}
            recurringFeedbackRecurrence={recurringFeedback.recurrence}
            receiver={receiver}
            totalSteps={totalSteps}
            feedbackRating={feedbackRating}
            skillsToRate={skillsToRate}
            currentSkillsToRate={currentSkillsToRate}
            currentSkillsIds={currentSkillsIds}
            currentStep={currentRecurringStep}
            onChangeFeedbackRating={this.handleRateFeedback}
            onChangeCurrentSkillsIds={this.handleChangeSkillsIds}
            onChangeCurrentAbilities={this.handleUpdateSkill}
            onChangeCurrentNote={this.handleUpdateSkill}
            onChangeCurrentStep={this.handleChangeCurrentStep}
            onChangeCurrentSkillRating={this.handleUpdateSkill}
            onSubmitFeedbackRequest={this.handleSubmitFeedbackRequest}
            onNextStep={this.handleNextStep}
            onPrevStep={this.handlePrevStep}
            handleTrackEvent={handleTrackEvent}
          />
        </TimelineContainer>
      </>
    );
  };

  render() {
    // FIXME: Hide Intercom messenger. This is a little hacky, but this is
    // currently the only public route where we don't want to show it
    updateIntercom(null, { showIntercomMessenger: false });

    return <>{this.renderTimeline()}</>;
  }
}
