import { compose, withProps } from 'recompose';
import { reduxForm } from 'redux-form';
import withRouter from 'hocs/withRouter';
import { graphql } from '@apollo/client/react/hoc';
import withCurrentUser from 'context/UserContext/withCurrentUser';
import {
  ERROR_AUTH_VERIFICATION_CODE_NOT_A_NUMBER,
} from '../../../../copy-consts';
import {
  VERIFY_EMAIL_LOGIN_CODE_MUTATION,
  RESEND_VERIFY_EMAIL_NEW_ACCOUNT,
  LOGOUT_MUTATION
} from 'graphql-queries/queries';
import VerifyEmailGatewayPage from '../components/VerifyEmailGatewayPage';
import Toast from 'components/Toast/Toast';

const VERIFY_EMAIL_VIA_CODE = '@auth/verify-email-via-code';

const withData = compose(
  withRouter,
  withCurrentUser,
  reduxForm({
    form: VERIFY_EMAIL_VIA_CODE,
    validate: (values) => {
      const errors = {};
      const value = parseInt(values);
      if (Number.isNaN(value)) {
        errors.verificationCode = ERROR_AUTH_VERIFICATION_CODE_NOT_A_NUMBER;
      }
      return errors;
    },
    destroyOnUnmount: false,
    onSubmit: async () => {},
  }),
  graphql(VERIFY_EMAIL_LOGIN_CODE_MUTATION, {
    name: 'verifyEmailLoginCodeMutation',
  }),
  graphql(RESEND_VERIFY_EMAIL_NEW_ACCOUNT, {
    name: 'resendVerifyEmailNewAccountMutation',
  }),
  graphql(LOGOUT_MUTATION, {
    name: 'logout',
  }),
  withProps(
    ({
      resendVerifyEmailNewAccountMutation
    }) => ({
      resendVerifyEmailNewAccount: async () => {
        try {
          await resendVerifyEmailNewAccountMutation();
          Toast.success('An email is on the way!');
        }
        catch (error) {
          Toast.error('Something went wrong, please try again later');
        }
      }
    })
  ) 
);

const VerifyEmailGatewayPageContainer = withData(VerifyEmailGatewayPage);
export default VerifyEmailGatewayPageContainer;

