import React, { useContext, useEffect } from 'react';
import { Tab, Tabs, ChannelsSelect } from './MobileSubMenu';
import UserContext from 'context/UserContext/UserContext';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import { IconButton, Icon } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';

export default function ChannelMobileMenu() {
  const { currentTenant, updateWorkspace } = useContext(UserContext);
  const { isMobileMenuOpen, setIsMobileMenuOpen, setTopNavItem, setIsChannelMenuOpen } = useContext(MobileMenuContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobileMenuOpen) {
      setTopNavItem(<ChannelsSelect text='Matter Channels' iconVariant='hash' />);
    } else {
      setIsChannelMenuOpen(false);
    }
  }, [isMobileMenuOpen]);

  if (!currentTenant) {
    return null;
  }
  
  return (
    <Tabs>
      {currentTenant?.workspaces.map(({ name, id }) => (
        <Tab key={id} onClick={() => {
          setIsMobileMenuOpen(false);
          setTopNavItem(
            <ChannelsSelect text={name} iconVariant='back' iconAction={() => {
              setIsChannelMenuOpen(true);
            }} />
          );
          updateWorkspace(id);
        }}>
          {name}
          <IconButton
            size='large'
            color='inherit'
            sx={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              setIsMobileMenuOpen(false);
              navigate(Resources.workspaceSettingsMain.path({ workspaceId: id }, { isChannelSetting: true }));
            }}
          >
            <Icon component={SettingsIcon} />
          </IconButton>
        </Tab>
      ))}
    </Tabs>
  );
}
