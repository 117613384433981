import React from 'react';
import PropTypes from 'prop-types';
import { Container, Link, LinkContainer } from './styles';
import { menuItemLabels } from '../../consts';

// This is the mobile version of the user settings drawer
const renderLinks = ({ onClickClose, profileLinks, workspaces }) =>
  profileLinks.map((link, index) => {
    const { href, label, linkProps = {}, to, onClick } = link;
    const { activeWorkspace } = workspaces.props || {};
    const isDisabled = activeWorkspace?.isExpired && (label === menuItemLabels.PROFILE || label === menuItemLabels.REQUEST);

    return (
      <Link
        id={`profile-link-${label}`}
        key={`profile-link-${index}`}
        href={href}
        to={to}
        isDisabled={isDisabled}
        onClick={onClick || onClickClose}
        {...linkProps}
      >
        {label}
      </Link>
    );
  });

// This is the mobile slide out menu
const HeaderSlideOutMenu = (props) => {
  const { children, isOpen, onClickClose, workspaces } = props;

  return (
    <Container isOpen={isOpen} onClickClose={onClickClose}>
      {children}
      <LinkContainer>{renderLinks(props)}</LinkContainer>
      {workspaces}
    </Container>
  );
};

HeaderSlideOutMenu.defaultProps = {
  profileLinks: [],
};

HeaderSlideOutMenu.propTypes = {
  /** Additional children components to display. */
  children: PropTypes.node,
  /** If menu is open. */
  isOpen: PropTypes.bool,
  /** Callback when menu closes. */
  onClickClose: PropTypes.func.isRequired,
  /** Links to display in the profile menu. */
  profileLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string.isRequired,
      linkProps: PropTypes.object,
      to: PropTypes.string,
    })
  ),
  /** Workspaces content */
  workspaces: PropTypes.node,
};

export default HeaderSlideOutMenu;
