import React from 'react';
import PropTypes from 'prop-types';
import { formatItems } from '../Email';
import Tags from './Tags';
import { useSelectOnBlurState, NO_OP } from './hooks';

const SelectOnBlur = (props) => {
  const {
    handleBlur,
    handleChange,
    handleSelect,
    value,
    ...otherProps
  } = useSelectOnBlurState(props);
  return (
    <Tags
      {...otherProps}
      onBlur={handleBlur}
      onChange={handleChange}
      onSelect={handleSelect}
      value={value}
    />
  );
};

SelectOnBlur.formatItems = formatItems;
SelectOnBlur.defaultProps = {
  onBlur: NO_OP,
  onChange: NO_OP,
  onSelect: NO_OP,
};

SelectOnBlur.propTypes = {
  /** Optional callback when dropdown is blurred. */
  onBlur: PropTypes.func,
  /** Callback when value changes. */
  onChange: PropTypes.func,
  /** Optional callback when value is selected. */
  onSelect: PropTypes.func,
  /** List of email values displayed. */
  value: PropTypes.arrayOf(PropTypes.string),
};

export default SelectOnBlur;
