import React from 'react';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import { Popover } from '@matterapp/matter-ui';
import { personPropType } from '@matterapp/people';
import { ProfilePreviewCard } from './modules';
import { QuickNoteFeedback } from '../../modules';
import { useExperiment } from 'modules/experiments';

const ProfileCard = ({ anchor, person, hide }) => {
  if (hide) { return anchor; }
  return (
    <QuickNoteFeedback>
      {({ handleSetCurrentPeer }) => (
        <Popover anchor={anchor}>
          {({
            handleClosePopover,
            openRequestedAtLeastOnce,
            renderPopover,
            renderAnchor,
          }) =>
            openRequestedAtLeastOnce ? (
              <ProfilePreviewCard
                handleSetPersonForQuickNote={handleSetCurrentPeer}
                handleClosePopover={handleClosePopover}
                person={person}
                renderPopover={renderPopover}
                renderAnchor={renderAnchor}
              />
            ) : (
              renderAnchor()
            )
          }
        </Popover>
      )}
    </QuickNoteFeedback>
  );
};

ProfileCard.propTypes = {
  anchor: PropTypes.node,
  person: personPropType.isRequired,
  hide: PropTypes.bool,
};

ProfileCard.defaultProps = {
  hide: false,
};

export default withProps(({
  hideProfileCard,
}) => {
  const { assignment } = useExperiment({ name: 'default-avatar' });
  return ({
    defaultAvatarIsGenerated: assignment === 'treatment',
    renderProfileCard: (props) => {
      return (<ProfileCard hide={hideProfileCard} {...props} />);
    },
  });
});
