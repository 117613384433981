import React, { useContext, useEffect } from 'react';
import { 
  SettingsContainer
} from '../styles';
import AdminsSettingsHoc from '../AdminsSettingsHoc';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import { Resources } from '@matterapp/routing';
import HrisSettingsPage from './HrisSettings';
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import SettingsNavigation from '../SettingsNavigation';

export default function HrisSettings() {  
  const { currentWorkspaceId: workspaceId, currentTenant, currentWorkspace, isLoadingCurrentUser, refetchCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    refetchCurrentUser();
  }, []);

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={(
        <AdminsSettingsHoc redirectUrl={Resources.workspaceSettingsMain.path({ workspaceId })}>
          <SettingsContainer>
            <SettingsNavigation currentLabel='HRIS' />
            {!currentWorkspace 
              ? null 
              : (
                <Paper elevation={0}>
                  <HrisSettingsPage 
                    tenant={currentTenant}
                    workspace={currentWorkspace}
                    isLoading={isLoadingCurrentUser}
                    onClickCancel={() => navigate(Resources.workspaceSettingsMain.path({ workspaceId }))}
                  />
                </Paper>
              )}
          </SettingsContainer>
        </AdminsSettingsHoc>
      )}
    />
  );
}
