import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="56" height="56" viewBox="0 0 56 56">
    <g fill="none">
      <path
        d="M 0 0L 44 0C 50.6274 0 56 5.37258 56 12L 56 56L 0 0Z"
        transform="matrix(-1 0 0 1 56 0)"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
