import React, { useContext, useState, useEffect } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { Button } from '@matterapp/matter-ui';
import { 
  Box,
  Typography,
  Icon,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Table,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import getHrisSyncHistory from 'graphql-queries/queries/hris/getHrisSyncHistory';
import getHrisSyncReportQuery from 'graphql-queries/queries/hris/getHrisSyncReport';
import { useQuery, useLazyQuery } from '@apollo/client';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { Resources } from '@matterapp/routing';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from 'styled-components';
import Toast from 'components/Toast/Toast';

async function downloadSpreadsheet(base64) {
  const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = 'hrisSyncReport.xlsx';

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(url);
}

const EditButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '24px',
  padding: `0 ${theme.spacing(0.5)}`,
  minHeight: 0,
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: '950px',
  [theme.breakpoints.down('sm')]: {
    '.MuiPaper-root': {
      borderRadius: 0
    }
  }
}));

const MobileBreadCrumb = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0
  }
}));

const TopWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(2)} 0`,
    margin: `0 ${theme.spacing(1)}`
  }
}));

const MainSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3), 
  display: 'flex', 
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    marginBottom: theme.spacing(2)
  }
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '400',
  padding: theme.spacing(1.5, 3)
}));

const ColumnCell = styled(TableCell)(({ theme, status }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  padding: theme.spacing(1.5, 3)
}));

export default function SyncHistory() {
  const { currentTenant, currentWorkspace, isAdminMember } = useContext(UserContext);
  const navigate = useNavigate();
  const [order, setOrder] = useState('desc');

  const { data: hrisHistoryData } = useQuery(getHrisSyncHistory, {
    variables: {
      tenantId: currentTenant?.id
    },
    fetchPolicy: 'network-only',
    skip: !currentTenant
  }); 
  const [getHrisSyncReport, { data: syncData, loading: requestingSyncReport }] = useLazyQuery(getHrisSyncReportQuery, {
    fetchPolicy: 'network-only'
  });

  const rows = hrisHistoryData?.hrisSyncHistory || [];

  const orderedRows = [...rows].sort((a, b) => {
    if (order === 'asc') {
      return a.timestamp > b.timestamp ? 1 : -1;
    } 

    return b.timestamp > a.timestamp ? 1 : -1;
  });

  if (!isAdminMember && currentWorkspace) {
    navigate(Resources.surveys.path({ workspaceId: currentWorkspace?.id }));
  }

  useEffect(() => {
    if (syncData && !requestingSyncReport) {
      downloadSpreadsheet(syncData.hrisSyncReport);
      Toast.success('Check downloaded spreadsheet for instructions.');
    }
  }, [requestingSyncReport, syncData]);
  
  return (
    <Wrapper>
      <TopWrapper>
        <MobileBreadCrumb 
          onClick={() => {
            navigate(Resources.workspaceSettingsHris.path({ workspaceId: currentWorkspace?.id }));
          }}
        >
          <Icon 
            component={ArrowBackIcon}
            sx={{ mr: 1, color: '#828285' }}
          />
          <Typography variant='body1'><b>Settings</b></Typography>
        </MobileBreadCrumb>
        <BreadCrumbs
          hideOnMobile
          crumps={[
            {
              label: 'Settings',
              onClick: () => navigate(Resources.workspaceSettingsMain.path({ workspaceId: currentWorkspace?.id }))
            },
            {
              label: 'HRIS Integration', 
              onClick: () => navigate(Resources.workspaceSettingsHris.path({ workspaceId: currentWorkspace?.id }))
            },
            {
              label: 'Sync History', 
              href: null
            }
          ]}
        />
      </TopWrapper>
      <MainSection>
        <Typography variant='h4' sx={{ mb: 3 }}>Sync History</Typography>
        <Typography variant='body1'>Matter will sync with your HRIS every 24 hours. A 60-day history is maintained at Matter.</Typography>

        <Table size='large' sx={{ mt: 2 }}>
          <TableHead>
            <TableRow>
              <HeaderCell>Status</HeaderCell>
              <HeaderCell>
                <TableSortLabel
                  active
                  direction={order}
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                >
                  Timestamp
                </TableSortLabel>
              </HeaderCell>
              <HeaderCell>Changes</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedRows.map(({ id, date, changes, status }) => (
              <TableRow key={id}>
                <ColumnCell sx={{ 
                  textTransform: 'capitalize',
                  color: status === 'failed' ? '#EB2E49' : (status === 'warning' ? '#EE8D11' : '#05A164')
                }}>
                  <b>{status}</b>
                </ColumnCell>
                <ColumnCell>{date}</ColumnCell>
                <ColumnCell>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {changes}                 
                    <EditButton
                      size='small' 
                      color='black'
                      onClick={() => getHrisSyncReport({
                        variables: {
                          syncId: id,
                          tenantId: currentTenant.id
                        }
                      })}
                    >
                      EXPORT
                    </EditButton>
                  </Box>
                </ColumnCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </MainSection>
    </Wrapper>
  );
}
