const TOGGLE_PEER_RECOMMENDATION = '@selected-peer-recommendations/toggle';
const FLUSH_SELECTED_PEER_RECOMMENDATIONS =
  '@selected-peer-recommendations/purge';
const SELECT_PEERS = '@selected-peer-recommendations/select-peers';

const initialState = {};
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case TOGGLE_PEER_RECOMMENDATION: {
    const wasSelected = !!state[action.selectedPeer.email];
    const nextState = { ...state };
    if (wasSelected) {
      delete nextState[action.selectedPeer.email];
    } else {
      nextState[action.selectedPeer.email] = true;
    }
    return nextState;
  }
  case FLUSH_SELECTED_PEER_RECOMMENDATIONS:
    return initialState;
  case SELECT_PEERS: {
    const nextState = { ...state };
    action.peers.forEach((peer) => {
      nextState[peer.email] = true;
    });
    return nextState;
  }
  default:
    return state;
  }
};

export const toggleSelectedPeerRecommendation = (selectedPeer) => ({
  type: TOGGLE_PEER_RECOMMENDATION,
  selectedPeer,
});

export const selectPeers = (peers) => ({
  type: SELECT_PEERS,
  peers,
});

export const purgeSelectedPeerRecommendations = () => ({
  type: FLUSH_SELECTED_PEER_RECOMMENDATIONS,
});

export default reducer;
