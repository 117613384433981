import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.95005 8.89218C9.95005 8.48579 9.58258 8.2 9.18755 8.2C8.79251 8.2 8.42505 8.48579 8.42505 8.89218V16.7828C8.42505 17.1892 8.79251 17.475 9.18755 17.475C9.58258 17.475 9.95005 17.1892 9.95005 16.7828V8.89218Z"
      fill={fill}
    />
    <path
      d="M12.7625 8.89218C12.7625 8.48579 12.3951 8.2 12 8.2C11.605 8.2 11.2375 8.48579 11.2375 8.89218V16.7828C11.2375 17.1892 11.605 17.475 12 17.475C12.3951 17.475 12.7625 17.1892 12.7625 16.7828V8.89218Z"
      fill={fill}
    />
    <path
      d="M14.8125 8.2C15.2076 8.2 15.575 8.48579 15.575 8.89218V16.7828C15.575 17.1892 15.2076 17.475 14.8125 17.475C14.4175 17.475 14.05 17.1892 14.05 16.7828V8.89218C14.05 8.48579 14.4175 8.2 14.8125 8.2Z"
      fill={fill}
    />
    <path fillRule="evenodd" clipRule="evenodd"
      d="M14.25 2.5C14.9826 2.5 15.575 3.09242 15.575 3.825V4.75H19.3125C19.7334 4.75 20.075 5.09154 20.075 5.5125C20.075 5.93346 19.7334 6.275 19.3125 6.275H19.1V18.9452C19.1 19.9391 18.2915 20.7476 17.2976 20.7476H6.70241C5.70851 20.7476 4.90002 19.9391 4.90002 18.9452V6.275H4.68749C4.26653 6.275 3.92499 5.93346 3.92499 5.5125C3.92499 5.09154 4.26653 4.75 4.68749 4.75H8.42499V3.825C8.42499 3.09242 9.01741 2.5 9.74999 2.5H14.25ZM6.36193 6.275V18.9452C6.36193 19.132 6.51559 19.2857 6.70241 19.2857H17.2976C17.4845 19.2857 17.6381 19.132 17.6381 18.9452V6.275H6.36193ZM9.75 4.95V3.825H14.25V4.95H9.75Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: "#6B6B6F",
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
