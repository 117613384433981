import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { canSwipe, isContainerScrolledAtEnd } from './utils';

const SCROLL_TIMEOUT = 40;

const Container = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
`;

export default class SwipeContainer extends React.PureComponent {
  static propTypes = {
    /** Contents of the swipe container. */
    children: PropTypes.node.isRequired,
    /** Additional style class. */
    className: PropTypes.string,
    /** Callback when swipe next is triggered. */
    onSwipeNext: PropTypes.func.isRequired,
    /** Callback when swipe prev is triggered. */
    onSwipePrev: PropTypes.func.isRequired,
    /** Duration of a swipe before detecting next swipe event. */
    swipeDuration: PropTypes.number.isRequired,
    /** Duration of a touch swipe to trigger. `swipeDuration` is used if not passed in. */
    swipeDurationTouch: PropTypes.number,
    /** The offset amount needed to trigger swipe. */
    swipeOffset: PropTypes.number.isRequired,
    /** The offset amount needed to trigger touch swipe. `swipeOffset` is used if not passed in. */
    swipeOffsetTouch: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      isSwipeTriggered: false,
    };
    this.ref = React.createRef();
    this.scrollTimeout = null;
    this.swipeTimeout = null;
  }

  componentDidMount = () => {
    this._mounted = true;
  };

  componentWillUnmount = () => {
    this._mounted = false;
    clearTimeout(this.swipeTimeout);
  };

  /**
   * Safely sets the last scroll offset.
   * @param { Number } offset: The scroll offset.
   * @returns { void }
   */
  setOffset = (offset) => {
    if (this._mounted) {
      this.setState({ offset });
    }
  };

  /**
   * Safely sets the the flag to indicate that a swipe was triggered.
   * @param { Object } swipeProps: Object containing flag if swipe was a touch event.
   * @returns { void }
   */
  setSwipeTrigger = (swipeProps = {}) => {
    if (this._mounted) {
      const { swipeDuration, swipeDurationTouch } = this.props;
      const duration = swipeProps.isTouch ? swipeDurationTouch : swipeDuration;
      clearTimeout(this.swipeTimeout);
      this.setState({ isSwipeTriggered: true });
      this.swipeTimeout = setTimeout(
        this.clearSwipeTriggered,
        duration || swipeDuration
      );
    }
  };

  /**
   * Clears the isSwipeTriggered state flag.
   * @returns { void }
   */
  clearSwipeTriggered = () => {
    if (this._mounted) {
      this.setState({ isSwipeTriggered: false });
    }
  };

  setScrollTriggered = () => {
    if (this._mounted) {
      this.setState({ isScrolling: true });
    }
  };

  clearScrollTriggered = (e) => {
    if (this._mounted) {
      this.setState({ isScrolling: false });
    }
  };

  /**
   * Calculates swipe event from wheel event to determine next/prev step callback.
   * @param { Object } e: The wheel event.
   * @returns { void }
   */
  handleWheel = (e) => {
    const { onSwipeNext, onSwipePrev, swipeOffset } = this.props;
    const { offset, isScrolling, isSwipeTriggered } = this.state;
    const currentOffset = Math.abs(e.deltaY);
    const isOffsetLess = offset < currentOffset;
    const isPositive = e.deltaY > 0;
    const isSwipeAllowed = canSwipe({
      containerRef: this.ref,
      currentOffset,
      isSwipeTriggered,
      offset,
      swipeOffset,
    });
    this.setOffset(currentOffset);

    if ((!isContainerScrolledAtEnd(this.ref) || isScrolling) && isOffsetLess) {
      this.setScrollTriggered();
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(
        this.clearScrollTriggered,
        SCROLL_TIMEOUT
      );
    } else if (isSwipeAllowed && !isSwipeTriggered) {
      this.setSwipeTrigger({ isTouch: false });
      e.stopPropagation();
      if (isPositive) {
        onSwipeNext(e);
      } else {
        onSwipePrev(e);
      }
    }
  };

  /**
   * Stores the initial touch offset to calulate swipe.
   * @param { Object } e: The touch event.
   * @returns { void }
   */
  handleTouchStart = (e) => {
    if (!e.touches[1]) {
      this.setState({ touch: e.touches[0].clientY });
      this.setSwipeTrigger({ isTouch: true });
    }
  };

  /**
   * Compares the initial touch offset with the ending offset
   * to determine swipe and call next/prev step callback.
   * @param { Object } e: The touch event.
   * @returns { void }
   */
  handleTouchEnd = (e) => {
    const {
      onSwipeNext,
      onSwipePrev,
      swipeOffset,
      swipeOffsetTouch,
    } = this.props;
    const difference = e.changedTouches[0].clientY - this.state.touch;
    const currentOffset = Math.abs(difference);
    const currentSwipeOffset = swipeOffsetTouch || swipeOffset;

    if (currentOffset > currentSwipeOffset && this.state.isSwipeTriggered) {
      if (difference > 0) {
        onSwipePrev(e);
      } else {
        onSwipeNext(e);
      }
    }
  };

  render() {
    const { className, children } = this.props;
    return (
      <Container
        className={className}
        ref={this.ref}
        onWheel={this.handleWheel}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
      >
        {children}
      </Container>
    );
  }
}
