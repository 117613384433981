import React from 'react';

const Icon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z" fill="#C6C6C7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.1036 10.8868L10.8955 10.8868L10.6688 15.7649C10.6604 15.8909 10.7695 16 10.8955 16L13.112 16C13.238 16 13.3471 15.8909 13.3303 15.7649L13.1036 10.8868ZM13.1785 8.89836C13.1785 8.403 12.7755 7.99999 12.2801 7.99999L11.7092 7.99999C11.2138 7.99999 10.8108 8.403 10.8108 8.89836L10.8108 9.10826C10.8108 9.60363 11.2138 10.0066 11.7092 10.0066L12.2801 10.0066C12.7755 10.0066 13.1785 9.60363 13.1785 9.10826L13.1785 8.89836Z" fill="white" />
  </svg>
);

export default Icon;
