import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import theme from '@matterapp/matter-theme';
import Button from '../../../components/Button';

const HeaderText = styled.div`
  margin-bottom: ${theme.spacing.double};
  margin-top: ${theme.spacing.half};
  line-height: ${theme.lineHeight.L};
  font-size: ${theme.fontSize.L};
  font-weight: ${theme.fontWeight.bold};
  align-items: center;
  ${theme.media.M`
    margin-bottom: ${theme.spacing.doubleAndHalf};
    margin-top: ${theme.spacing.singleAndHalf};
  `}
`;

const RecurringButton = styled(Button).attrs(() => ({
  buttonType: Button.Simple.buttonTypes.BUBBLE,
  size: Button.Simple.buttonSizes.M,
}))`
  width: 300px;
  font-size: 22px;
  min-height: ${theme.spacing.doubleAndThreeQuarters};
  height: ${theme.spacing.doubleAndThreeQuarters};
  line-height: ${theme.lineHeight.L};
  margin-bottom: ${theme.spacing.half};
  line-height: ${theme.lineHeight.L};
  ${theme.media.M`
    height: ${theme.spacing.triple};
  `}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: ${theme.borderRadius.L};
  margin: 0 ${theme.spacing.singleAndHalf} ${theme.spacing.double};
`;

const organizeSkills = (list) => {
  return list.map(skills => skills.skill);
};

const day = JSON.parse(moment().format('D'));

const frequencyOptions = [
  {
    label: 'Monthly',
    value: 'monthly'
  },
  {
    label: 'Bi-Monthly',
    value: 'bimonthly'
  },
  {
    label: 'Quarterly',
    value: 'quarterly'
  },
  {
    label: 'Never',
    value: 'never'
  }
];

const SetupRecurring = ({
  currentStep,
  totalNumSteps,
  feedbackReceived,
  onClickNext,
  onClickReturnHome,
  handleConfirmRecurring,
  trackSkipRecurring,
}) => {
  const isLastStep = currentStep === totalNumSteps;

  const { giver, feedback } = feedbackReceived;

  const lowestRatings = [...feedback.skillRatings].sort((a, b) => a.rating - b.rating).slice(0, 3);
  const lowestRatingsList = organizeSkills(lowestRatings);
  const selectedSkillIds = lowestRatingsList.map(skill => skill.id);

  const handleStartRecurring = (frequency) => {
    const payload = {
      skillIds: selectedSkillIds,
      personId: giver.personId,
      day,
      frequency,
    };

    if (frequency !== 'never') {
      handleConfirmRecurring(payload);
    } else {
      trackSkipRecurring();
    }

    if (isLastStep) {
      onClickReturnHome();
    } else {
      onClickNext();
    }
  };

  return (
    <Container
      className="setup-recurring-container"
    >
      <HeaderText>How frequently would you like to receive feedback from {giver.fullName || giver.email}?</HeaderText>
      {frequencyOptions.map((option) => {
        return (
          <RecurringButton
            onClick={() => handleStartRecurring(option.value)}
          >
            {option.label}
          </RecurringButton>
        );
      })}
    </Container>
  );
};

SetupRecurring.propTypes = {
  feedbackReceived: PropTypes.shape({
    giver: PropTypes.shape({
      personId: PropTypes.number,
      fullName: PropTypes.string,
      photoUrl: PropTypes.string,
      email: PropTypes.string,
    }),
    skillRatings: PropTypes.array,
  }).isRequired,
  /** Override the next button text. */
  nextButtonText: PropTypes.string,
  /** Callback when the next button is clicked. */
  onClickNext: PropTypes.func,
  /** Callback when the start recurring button is clicked. */
  handleConfirmRecurring: PropTypes.func,
};

export default SetupRecurring;