import React from 'react';
import PropTypes from 'prop-types';
import WithActionButtons from './WithActionButtons';

const WithClose = (props) => {
  const {
    canClickClose,
    className,
    onClickClose,
    closeLabel,
    closeProps,
    ...otherProps
  } = props;

  return (
    <WithActionButtons
      {...otherProps}
      className={className}
      canClickSecondary={canClickClose}
      onClickSecondary={onClickClose}
      secondaryLabel={closeLabel}
      secondaryProps={closeProps}
    />
  );
};

WithClose.defaultProps = {
  canClickClose: true,
  closeLabel: 'Close',
};

WithClose.propTypes = {
  /** If close button can be clicked. */
  canClickClose: PropTypes.bool,
  /** Additional class. */
  className: PropTypes.string,
  /** Callback when close button is clicked. */
  onClickClose: PropTypes.func,
  /** Close button label. */
  closeLabel: PropTypes.string,
  /** Additional props for close button. */
  closeProps: PropTypes.object,
};

export default WithClose;
