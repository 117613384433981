import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import CardContents from '../../../components/Flow/Scrolling/CardContents';

const numberOfSteps = 1;

const Header = styled.div`
  margin: ${theme.spacing.singleAndHalf} ${theme.spacing.singleAndHalf} 0;
  line-height: ${theme.lineHeight.L};
  ${theme.media.M`
    margin-top: 0;
  `}
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  text-transform: uppercase;
  color: ${theme.colors.blacks[30]};
`;

const GatherFeedback = (props) => {
  const {
    canClickNext,
    children,
    onClickNext,
    onClickSkip,
    header,
    skipButtonText,
    nextButtonText,
    nextButtonIsLoading
  } = props;

  const content = !children ? <EmptyState> That&apos;s all for now </EmptyState> : children;
  const headerText = !header ? 'Request more feedback?' : header;

  return (
    <CardContents
      className="view-feedback-gather-feedback"
      canClickNext={canClickNext}
      headerText={<Header>{headerText}</Header>}
      onClickNext={onClickNext}
      onClickSkip={onClickSkip}
      skipButtonText={skipButtonText}
      nextButtonText={nextButtonText}
      nextButtonIsLoading={nextButtonIsLoading}
    >
      {content}
    </CardContents>
  );
};

GatherFeedback.defaultProps = {
  canClickNext: true,
  onClickNext: () => null,
  onClickSkip: () => null,
};

GatherFeedback.propTypes = {
  /** If next button can be clicked. */
  canClickNext: PropTypes.bool,
  /** Children to display. */
  children: PropTypes.node,
  /** Callback when the next button is clicked. */
  onClickNext: PropTypes.func,
  /** Callback when the skip button is clicked. */
  onClickSkip: PropTypes.func,
};

GatherFeedback.numberOfSteps = numberOfSteps;

export default GatherFeedback;
