import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { WORKSPACE_FEED_QUERY } from 'graphql-queries/queries';
import Toast from 'components/Toast/Toast';

export const useWorkspaceFeedQuery = () => {
  const { workspaceId } = useParams();
  const [offset, setOffset] = useState(0);
  const [results, setResults] = useState([]);
  const { data, error, loading, refetch } = useQuery(WORKSPACE_FEED_QUERY, {
    variables: {
      workspaceId: workspaceId || '',
      offset: offset,
    },
  });
  const feedExists = data && !!data.workspaceFeed && !!data.workspaceFeed.count;
  const onLoadMore = () => {
    if (feedExists) {
      setOffset(results.length);
    }
  };

  const resetFeed = () => {
    setOffset(0);
    refetch();
  };

  const updateResultsOnComment = (item, index) => {
    const listItem = results[index];
    listItem.comments = item.comments;
    results[index] = listItem;
    setResults([...results]);
  };

  const updateResultsOnDelete = (index) => {
    const list = [...(results.slice(0, index)), ...(results.slice(index+1))];
    setResults(list);
  };

  useEffect(() => {
    if (data && !!data.workspaceFeed) {
      const newResults = !!offset ? [...results, ...data.workspaceFeed.feedItems] : [...data.workspaceFeed.feedItems];
      setResults(newResults);
    }
  }, [data]);

  if (!loading && error) { Toast.error('You do not have permission to view this channel'); }

  return {
    workspaceFeed: results,
    feedExists,
    onLoadMore,
    updateResultsOnComment,
    updateResultsOnDelete,
    resetFeed
  };
};

export const useGiveKudosModal = ({ selectedAdvisor, onSendKudos }) => {
  const [isGiveKudosModalOpen, setIsGiveKudosOpen] = useState(false);
  const [selectedKudos, setSelectedKudos] = useState();

  const handleSelectKudos = (e, { selectedKudos }) => {
    setSelectedKudos(selectedKudos);
  };

  const handleSendKudos = async (note) => {
    setIsGiveKudosOpen(false);
    const receiverUserIds = [selectedAdvisor.id];
    await onSendKudos({
      receiverUserIds,
      type: selectedKudos,
      note,
    });
    setSelectedKudos();
  };
  const handleOpenKudosModal = () => setIsGiveKudosOpen(true);
  const handleCloseKudosModal = () => setIsGiveKudosOpen(false);

  return {
    selectedKudos,
    isGiveKudosModalOpen,
    handleSelectKudos,
    handleSendKudos,
    handleOpenKudosModal,
    handleCloseKudosModal,
  };
};