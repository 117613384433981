import Hero from './Hero';
import InlineForm from './InlineForm';
import StackedForm from './StackedForm';
import ConnectCalendar from './ConnectCalendar';

const Marketing = {
  Hero,
  InlineForm,
  StackedForm,
  ConnectCalendar,
};

export default Marketing;
