import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';
import ToggleList from '../ToggleList';

const NUMBER_OF_COLUMNS = 2;
const MAX_AMOUNT_OF_SELECTED_ABILITIES = 3;
const VALUE_NAME = 'currentAbilities';

export default class SkillAbilities extends React.PureComponent {
  static propTypes = {
    /** List of abilities tied to the current skill rated. */
    abilities: PropTypes.arrayOf(
      PropTypes.shape({
        ability: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
        selected: PropTypes.bool,
      })
    ),
    /** The color of the skill. */
    color: PropTypes.oneOf(Object.values(Button.Simple.buttonColors)),
    /** The max amount of abilities to select. */
    maxAmount: PropTypes.number,
    /** Callback when the value of selected abilities changes. */
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    abilities: [],
    currentAbilities: [],
    maxAmount: MAX_AMOUNT_OF_SELECTED_ABILITIES,
  };

  getAbilities = () => {
    const abilities = [];
    const currentAbilities = [];
    this.props.abilities.forEach((singleAbility) => {
      const { ability, selected } = singleAbility;
      if (selected) {
        currentAbilities.push(ability.id);
      }
      abilities.push(ability);
    });
    return {
      abilities,
      currentAbilities,
    };
  };

  /**
   * Callback when the current selected abilities change.
   * Calls 'onChange' prop with change event and component
   * props with the updated 'abilities' and list of 'currentAbilities'.
   * @param { Object } e: The change event.
   * @param { Object } changeProps: Props from the ToggleList with the currently selected items.
   * @returns { void }
   */
  handleChange = (e, changeProps) => {
    const currentAbilities = changeProps[VALUE_NAME];
    const { abilities, onChange } = this.props;
    const updatedAbilities = abilities.map((singleAbility) => {
      const { ability } = singleAbility;
      return {
        ability,
        selected: currentAbilities.includes(ability.id),
      };
    });
    onChange(e, {
      ...this.props,
      abilities: updatedAbilities,
      currentAbilities,
    });
  };

  render() {
    const { color, maxAmount } = this.props;
    const { abilities, currentAbilities } = this.getAbilities();
    return (
      <Grid columns={NUMBER_OF_COLUMNS} spacing={theme.spacing.half}>
        <ToggleList
          buttonProps={{
            buttonType: Button.Simple.buttonTypes.CTA,
            size: Button.Simple.buttonSizes.S,
          }}
          color={color}
          items={abilities}
          onChange={this.handleChange}
          maxAmount={maxAmount}
          value={currentAbilities}
          valueName={VALUE_NAME}
        />
      </Grid>
    );
  }
}
