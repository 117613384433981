import styled from 'styled-components';
import Button from './Default';
import theme from '@matterapp/matter-theme';

const getColorStyles = ({ color, disabled }) => {
  const baseColor = theme.colors[`${color}s`] || theme.colors.blues;
  if (!disabled) {
    return `
      background: radial-gradient(${baseColor[5]} 0%, transparent 50%);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0% 0%;

      &:not(:hover) {
        color: inherit;
      }
      &:hover {
        background: radial-gradient(${baseColor[5]} 80%, transparent);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 120vw 120vw;
      }
      &:active {
        background: radial-gradient(${baseColor[10]} 90%, transparent);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 120vw 120vw;
      }
    `;
  }
};

const TextButton = styled(Button)`
  &,
  &:hover,
  &:active {
    border-color: transparent;
  }
  ${getColorStyles}
`;

export default TextButton;
