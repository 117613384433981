import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import map from 'lodash/map';

// At some point we could move to something with infinite scrolling and other
// goodies we need. e.g. https://coderiety.github.io/react-list/

const ListContainer = styled.div`
  background: white;
  ${({ rounded }) => theme.media.medium`
    border-radius: ${rounded ? '0 0 12px 12px' : '0px'};
  `}
  div:last-child {
    ${({ rounded, showAllItemBorders }) => `
      ${showAllItemBorders ? '' : 'border: none;'}
      border-radius: ${rounded ? '0 0 12px 12px' : '0px'};
    `}
  }
`;

const List = ({
  items,
  className,
  renderItem,
  empty,
  children,
  rounded,
  showAllItemBorders,
}) => {
  const renderedItems = map(items, (item, i) => renderItem(item, i));
  return (
    <ListContainer className={className} rounded={rounded} showAllItemBorders={showAllItemBorders}>
      {children}
      {items.length > 0 ? renderedItems : empty}
    </ListContainer>
  );
};

List.defaultProps = {
  className: null,
  empty: null,
  items: [],
  renderItem: () => null,
  children: null,
  rounded: true,
};

List.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func,
  empty: PropTypes.node,
  children: PropTypes.node,
  rounded: PropTypes.bool,
  showAllItemBorders: PropTypes.bool,
};

export default List;
