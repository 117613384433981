import React from 'react';
import PropTypes from 'prop-types';
import { Checkmark, PeerRecommendationListItem } from '@matterapp/matter-ui';
import { Field as ReduxFormField } from 'redux-form';

const PeerRecommendationCheckboxes = class extends React.Component {
  renderPeerRecommendationListItem = (reduxFormFieldProps) => {
    const { recommendedPeer } = reduxFormFieldProps;
    const { input } = reduxFormFieldProps;
    const { onChange, checked } = input;
    const toggleCheckbox = (e) => {
      e.preventDefault();
      e.stopPropagation();
      onChange(!checked);
    };
    return (
      <PeerRecommendationListItem
        photoUrl={recommendedPeer.photoUrl}
        fullName={recommendedPeer.fullName}
        email={recommendedPeer.email}
        onClick={toggleCheckbox}
        checkbox={
          <Checkmark
            type="checkbox"
            {...reduxFormFieldProps.input}
            value={reduxFormFieldProps.value}
            onChange={toggleCheckbox}
          />
        }
      />
    );
  };

  renderCheckboxes = (reduxFormFieldProps) => {
    const { peerRecommendations } = reduxFormFieldProps;
    return peerRecommendations.map((recommendedPeer, index) => (
      <ReduxFormField
        key={recommendedPeer.email}
        component={this.renderPeerRecommendationListItem}
        name={`pickedPeers.${index}`}
        type="checkbox"
        value={recommendedPeer.email}
        recommendedPeer={recommendedPeer}
      />
    ));
  };

  render = () => {
    const { peerRecommendations } = this.props;
    return (
      <ReduxFormField
        name="pickedPeers"
        peerRecommendations={peerRecommendations}
        component={this.renderCheckboxes}
      />
    );
  };
};

PeerRecommendationCheckboxes.propTypes = {
  peerRecommendations: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      photoUrl: PropTypes.string,
      fullName: PropTypes.string,
    })
  ).isRequired,
};

export default PeerRecommendationCheckboxes;
