import React from 'react';
import Card from './Card';
import Title from './Title';
import FullWidth from './FullWidth';
import Collapse from './Collapse';

class Content extends React.Component {
  static Card = Card;
  static Title = Title;
  static FullWidth = FullWidth;
  static Collapse = Collapse;
}

export default Content;
