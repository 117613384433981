import styled from 'styled-components';
import Button from '../../Button';
import {
  colors,
  font,
  lineHeight,
  spacing,
  transitions,
} from '@matterapp/matter-theme';

export const Container = styled.div`
  border-top: 1px solid ${colors.blacks[10]};
  display: flex;
  position: relative;
`;

export const AvatarContainer = styled.div`
  padding: ${spacing.singleAndHalf};
  padding-right: ${spacing.single};
  margin-top: -2px;
`;

const getTextAreaHeight = ({ numberOfComments }) => {
  if (numberOfComments === 3) {
    return 'max-height: 240px;';
  }
  if (numberOfComments === 2) {
    return 'max-height: 180px;';
  }
  if (numberOfComments === 1) {
    return 'max-height: 120px;';
  }
  if (numberOfComments === 0) {
    return 'max-height: 200px;';
  }
  return 'max-height: 290px;';
};

export const TextAreaContainer = styled.div`
  flex: 1;

  & textarea {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  & textarea::-webkit-scrollbar {
    display: none;
  }

  & textarea,
  & textarea + div {
    background: transparent !important;
    font-size: ${font.size.S};
    line-height: ${lineHeight.M};
    font-weight: normal;
    min-height: ${spacing.quadrupleAndHalf};
    padding: calc(${spacing.singleAndHalf} - 2px) ${spacing.quintuple} ${spacing.singleAndHalf} 0;
    overflow-y: ${({ hasValue }) => hasValue ? 'scroll' : 'hidden'};
    ${getTextAreaHeight}
  }
  & [data-role='container'] {
    line-height: 0;
  }
  & [data-role='label'] {
    display: none;
  }
`;

export const SendButtonContainer = styled.div`
  position: absolute;
  top: calc(${spacing.threeQuarters} - 2px);
  right: 0;
`;

export const SendButton = styled(Button.Link).attrs(() => ({
  content: 'Send',
  useDefaultNormalStateColor: true,
}))`
  font-size: ${font.size.S};
  transition: opacity ${transitions.times.default};

  &[disabled] {
    background: transparent;
  }

  ${({ visible }) =>
    visible ? 'opacity: 1;' : 'opacity: 0; pointer-events: none;'};
`;
