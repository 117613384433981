import React from 'react';
import { Confirm, ButtonNew as Button } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';

class ConnectButton extends React.Component {
  state = {
    isDisconnectModalOn: false,
  };

  handleDisplayDisconnectModal = () => {
    this.setState({
      isDisconnectModalOn: true,
    });
  };

  handleDismissDisconnect = () => {
    this.setState({
      isDisconnectModalOn: false,
    });
  };

  handleDisconnect = async () => {
    const { handleDisconnect } = this.props;
    await handleDisconnect();
    this.handleDismissDisconnect();
  };

  renderDisconnectCancelButton = () => (
    <Button basic onClick={this.handleDismissDisconnect} size="small">
      Cancel
    </Button>
  );

  renderDisconnectConfirmButton = () => (
    <Button onClick={this.handleDisconnect} primary size="small">
      Disconnect
    </Button>
  );

  renderDisconnectModal = () => {
    const { isDisconnectModalOn } = this.state;
    const { disconnectSubheading } = this.props;
    return (
      <Confirm.Old
        isOpen={isDisconnectModalOn}
        buttonRight={this.renderDisconnectCancelButton()}
        buttonLeft={this.renderDisconnectConfirmButton()}
        heading="Are you sure?"
        subheading={disconnectSubheading}
        onCancel={this.handleDismissDisconnect}
      />
    );
  };

  render = () => {
    const { children, isLoading, handleConnect, isConnected } = this.props;
    return (
      <React.Fragment>
        {this.renderDisconnectModal()}
        {children({
          handleConnect,
          handleDisconnect: this.handleDisplayDisconnectModal,
          isLoading,
          isConnected,
        })}
      </React.Fragment>
    );
  };
}

ConnectButton.defaultProps = {
  isConnected: null,
};

ConnectButton.propTypes = {
  handleConnect: PropTypes.func.isRequired,
  handleDisconnect: PropTypes.func.isRequired,
  disconnectSubheading: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool,
};

export default ConnectButton;
