import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';
import { colors, spacing } from '@matterapp/matter-theme';
import ActionItems from './ActionItems';
import { skillRatingPropType } from '../prop-types';
import Skill from '../../Skill';
import Gauge from '../../Skill/Gauge';
import { skillRatingsMap, skillRatingsTitle, FREQUENCY_NOUNS } from '../../../common/libs/skill-ratings-map';
import SkillRatingItemBase from './SkillRatingItemBase';
import { getSkillBorderColor } from '../../../common/libs/get-skill-details';

const ActionItemsDivider = styled.div`
  background-color: ${({ color }) => color};
  height: 1px;
  width: 100%;
  margin-top: ${spacing.single};
`;

const TruncatedContainer = styled.div`
  a {
    color: ${colors.purples[40]};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:active {
      color: ${colors.purples[60]};
    }
  }
`;

export default class SkillRatingItem extends React.PureComponent {
  state = {
    isNoteTruncated: this.props.truncateNote,
  };

  getAutoNote() {
    const { maxRating, maxRatingValue, skillRating, overallRating, recurringFeedbackRecurrence } = this.props;
    const { rating, skill } = skillRating;
    const multiplier = maxRatingValue / maxRating;
    const displayedRating = rating / multiplier;

    if (overallRating && !!recurringFeedbackRecurrence) {
      const ratingTitle = skillRatingsTitle[displayedRating];
      const finalTitle = ratingTitle ? `${ratingTitle.slice(0, ratingTitle.length-1)}, ` : '';
      return `${finalTitle}I left you a new score on ${skill.name} for last ${FREQUENCY_NOUNS[recurringFeedbackRecurrence.frequency]}.`;
    }
    return `I think you're ${skillRatingsMap.get(rating)} at ${skill.name}. I rated you ${rating ? displayedRating : 1}/${maxRating}.`;
  }

  handleExpandNote = (e) => {
    e.preventDefault();
    if (this.state.isNoteTruncated) {
      this.setState({ isNoteTruncated: false });
    }
  }

  renderNote() {
    const { truncateNote, skillRating } = this.props;
    const note = skillRating.note || this.getAutoNote();
    const isActionItem = !!skillRating.actionItems?.length;

    if (!this.state.isNoteTruncated) {
      return (
        <>
          {note}
          {isActionItem && this.renderActionItemNote()}
        </>
      );
    }
    
    if (isActionItem && truncateNote) {
      return (
        <>
          <TruncatedContainer>
            <TextTruncate
              line={3}
              element="span"
              truncateText="…"
              text={note}
              textTruncateChild={<a onClick={this.handleExpandNote}>[Expand]</a>}
            />
          </TruncatedContainer>
          {this.renderActionItemNote()}
        </>
      );
    } else if (isActionItem) {
      return (
        <>
          {note}
          {this.renderActionItemNote()}
        </>
      );
    } else if (truncateNote) {
      return (
        <TruncatedContainer>
          <TextTruncate
            line={6}
            element="span"
            truncateText="…"
            text={note}
            textTruncateChild={<a onClick={this.handleExpandNote}>[Expand]</a>}
          />
        </TruncatedContainer>
      );
    }
    return {note};
  }

  renderActionItemNote() {
    const { skillRating, giver } = this.props;
    const { actionItems } = skillRating;
    const numThings = actionItems.length;
    const pluralizedThings = {
      subject: numThings === 1 ? 'thing' : 'things',
      verb: numThings === 1 ? 'is' : 'are',
    };
    const borderColor = getSkillBorderColor(skillRating.skill);

    return (
      <>
        <ActionItemsDivider color={borderColor} />
        <p>
          <br />
          {`Here ${pluralizedThings.verb} ${numThings} ${pluralizedThings.subject} ${giver.firstName || giver.email} recommends you can start doing to improve this skill: `}
        </p>
        {this.renderActionItem()}
      </>
    );
  }

  renderActionItem() {
    const { skillRating, onClickLearnMore, onClickActionItem } = this.props;

    return (
      <ActionItems
        skill={skillRating.skill}
        pickedAbilities={skillRating.actionItems}
        onClickLearnMore={onClickLearnMore}
        onClickActionItem={onClickActionItem}
        bkgColor={Skill.helpers.getSkillBackgroundColor(skillRating.skill)}
      />
    );
  }

  render() {
    const {
      width,
      skillRating,
      isAlternate,
      onClickSkillBadge,
      skillGaugeProps,
    } = this.props;
    const { rating, skill } = skillRating;
    return (
      <SkillRatingItemBase
        isAlternate={isAlternate}
        note={this.renderNote()}
        onClickSkillBadge={onClickSkillBadge}
        rating={rating}
        skill={skill}
        skillGaugeProps={skillGaugeProps}
        width={width}
      />
    );
  }
}

SkillRatingItem.defaultProps = {
  maxRating: 5,
  maxRatingValue: 10,
  width: null,
  isAlternate: false,
  truncateNote: false,
  onClickLearnMore: () => {},
  onClickActionItem: () => {},
  onClickSkillBadge: () => {},
  skillGaugeProps: {},
};

SkillRatingItem.propTypes = {
  maxRating: PropTypes.number,
  maRatingValue: PropTypes.number,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  skillRating: skillRatingPropType.isRequired,
  truncateNote: PropTypes.bool,
  isAlternate: PropTypes.bool,
  onClickLearnMore: PropTypes.func,
  onClickActionItem: PropTypes.func,
  onClickSkillBadge: PropTypes.func,
  skillGaugeProps: PropTypes.shape(Gauge.propTypes),
};