import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00069 6.01536L11.5926 2.18199C11.8519 2.06629 12.1481 2.06629 12.4074 2.18199L20.9993 6.01536H21.5C21.7761 6.01536 22 6.23922 22 6.51536V8.02309H2V6.51536C2 6.23922 2.22386 6.01536 2.5 6.01536H3.00069ZM13.1111 4.00762C13.1111 4.56204 12.6137 5.01149 12 5.01149C11.3864 5.01149 10.8889 4.56204 10.8889 4.00762C10.8889 3.4532 11.3864 3.00375 12 3.00375C12.6137 3.00375 13.1111 3.4532 13.1111 4.00762ZM4.5 9.00018C4.22386 9.00018 4 9.22404 4 9.50018V17.5002C4 17.7763 4.22386 18.0002 4.5 18.0002H5.5C5.77614 18.0002 6 17.7763 6 17.5002V9.50018C6 9.22404 5.77614 9.00018 5.5 9.00018H4.5ZM3 19.5656C3 19.2895 3.22386 19.0656 3.5 19.0656H20.5C20.7761 19.0656 21 19.2895 21 19.5656V20.0695L21.5 20.0695C21.7761 20.0695 22 20.2933 22 20.5695V22.0772H2V20.5695C2 20.2933 2.22386 20.0695 2.5 20.0695H3V19.5656ZM8 9.50018C8 9.22404 8.22386 9.00018 8.5 9.00018H9.5C9.77614 9.00018 10 9.22404 10 9.50018V17.5002C10 17.7763 9.77614 18.0002 9.5 18.0002H8.5C8.22386 18.0002 8 17.7763 8 17.5002V9.50018ZM14.5 9.00018C14.2239 9.00018 14 9.22404 14 9.50018V17.5002C14 17.7763 14.2239 18.0002 14.5 18.0002H15.5C15.7761 18.0002 16 17.7763 16 17.5002V9.50018C16 9.22404 15.7761 9.00018 15.5 9.00018H14.5ZM18 9.50018C18 9.22404 18.2239 9.00018 18.5 9.00018H19.5C19.7761 9.00018 20 9.22404 20 9.50018V17.5002C20 17.7763 19.7761 18.0002 19.5 18.0002H18.5C18.2239 18.0002 18 17.7763 18 17.5002V9.50018Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#07BB87',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
