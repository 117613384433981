import { graphql } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';
import { UPDATE_PHOTO_MUTATION } from 'graphql-queries/queries';
import Toast from 'components/Toast/Toast';

const withUpdatePhotoMutation = graphql(UPDATE_PHOTO_MUTATION, {
  name: 'updatePhotoMutation',
});

const withPhotoSync = compose(
  withUpdatePhotoMutation,

  withProps(
    ({
      connectedAccountsData,
      updatePhotoMutation,
    }) => ({
      connectedAccountsData,
      handlePhotoSync: async ({ provider }) => {
        try {
          const userWithUpdatedPhoto = await updatePhotoMutation({
            variables: { provider },
          });
          const data = !!userWithUpdatedPhoto && userWithUpdatedPhoto.data ? userWithUpdatedPhoto.data : null;
          const user = data.updatePhoto;
          Toast.success('Successfully updated your photo');
          return user.photoUrl;
        } catch (err) {
          const providerNames = {
            twitter: 'Twitter',
            linkedIn: 'LinkedIn',
          };
          console.log(err);
          Toast.error(`Please connect your ${providerNames[provider]} account`);
        }
      },
    })
  )
);

export default withPhotoSync;
