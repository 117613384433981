"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.buttonShake = void 0;
const buttonShake = `
{
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(4px);
  }
  75% {
    transform: translateX(-4px);
  }
}
`;
exports.buttonShake = buttonShake;
var _default = buttonShake;
exports.default = _default;