import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconWithText from '../IconWithText';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  background-color: ${() => theme.colors.blacks[5]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  border-radius: 4px;
  padding-left: 24px;
  padding-right: 24px;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  min-width: 101px;
  padding-bottom: 12px;
  padding-top: 12px;
`;

const DividerColumn = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  padding-bottom: 8px;
  padding-top: 8px;
`;

const Divider = styled.div`
  background-color: #c6c6c7;
  border-radius: 4px;
  height: 100%;
  width: 2px;
`;

class ContactsSyncButtons extends React.PureComponent {
  render() {
    const { onGmailClick, onOutlookClick } = this.props;
    return (
      <Container>
        <Column>
          <IconWithText
            iconName="SocialGmail"
            iconWidth={32}
            iconHeight={32}
            isLink
            onClick={onGmailClick}
            rcLabel="contactsSyncButtonGoogle"
          >
            Gmail
          </IconWithText>
        </Column>
        <DividerColumn>
          <Divider />
        </DividerColumn>
        <Column>
          <IconWithText
            iconName="SocialOutlook"
            iconWidth={32}
            iconHeight={32}
            isLink
            onClick={onOutlookClick}
            rcLabel="contactsSyncButtonOutlook"
          >
            Outlook
          </IconWithText>
        </Column>
      </Container>
    );
  }
}

ContactsSyncButtons.propTypes = {
  onGmailClick: PropTypes.func.isRequired,
  onOutlookClick: PropTypes.func.isRequired,
};

export default ContactsSyncButtons;
