import React from 'react';
import PropTypes from 'prop-types';

const Empty = ({
  content,
  header,
  footer,
}) => (
  <React.Fragment>
    {header}
    {content}
    {footer}
  </React.Fragment>
);

Empty.defaultProps = {
  header: null,
  footer: null,
};

Empty.propTypes = {
  content: PropTypes.node.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
};

export default Empty;
