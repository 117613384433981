import React from 'react';
import styled from 'styled-components';
import SVG from '../../../../SVG';
import theme from '@matterapp/matter-theme';

const DarkFill = styled.path`
  clip-rule: evenodd;
  fill-rule: evenodd;
  fill: ${theme.colors.purples[50]};
`;

const StarBurst = styled.path.attrs({
  d: `M255,53.6c0,0.1,0.1,0.2,0.2,0.3c1.6,2.9,4,3.1,5,3.1c1.9,0,3.3-0.8,4.5-2.2c0.3,0.8,0.7,1.3,1.1,1.9
      c1,1.2,2.6,1.9,4.1,1.9c1.5,0,2.8-0.2,4.3-1.9c0.2,1.3,0.7,2.1,1.2,2.8c1,1.4,2.6,2.2,4.4,2.2c1,0,2-0.1,3.3-0.9
      c0.1,1.2,0.5,2,1,2.8c1,1.6,2.7,2.6,4.7,2.6c0.9,0,1.8-0.1,3-0.7c-0.3,1.8,0,2.9,0.6,3.9c1,1.9,2.8,2.9,4.9,2.9c0.5,0,1,0,1.6-0.2
      c-0.5,1.6-0.1,2.7,0.3,3.8c0.9,2.1,2.9,3.4,5.3,3.4c0.3,0,0.7,0,1.1-0.1c-1.1,2-0.9,3.3-0.4,4.8c0.6,1.7,2.2,3.6,5.1,3.8
      c-1.2,1.8-1.1,3.1-0.8,4.6c0.3,1.1,1.3,3.7,5,4.3c-1.2,1.2-2.3,3-1.9,5.2c0.1,1,0.9,3.2,3.8,4.4c-1.5,1.2-2.4,2.9-2.3,4.9
      c0.2,2.5,1.8,3.9,3.5,4.8c-1.7,0.9-3.3,2.3-3.5,4.8c-0.1,2.1,0.7,3.7,2.3,4.9c-2.9,1.1-3.6,3.4-3.8,4.4c-0.3,2.2,0.7,3.9,1.9,5.2
      c-3.8,0.6-4.8,3.1-5,4.3c-0.3,1.5-0.4,2.8,0.8,4.6c-2.9,0.2-4.6,2.1-5.2,3.8c-0.5,1.4-0.7,2.7,0.4,4.8c-0.4,0-0.8-0.1-1.1-0.1
      c-2.4,0-4.4,1.3-5.3,3.4c-0.5,1.1-0.8,2.2-0.3,3.8c-0.6-0.1-1.1-0.2-1.6-0.2c-2.1,0-4,1.1-4.9,2.9c-0.5,1-0.9,2.1-0.6,3.9
      c-1.2-0.6-2.1-0.7-3-0.7c-1.9,0-3.7,1-4.7,2.6c-0.5,0.8-0.9,1.6-1,2.8c-1.2-0.8-2.2-0.9-3.2-0.9c-1.8,0-3.3,0.8-4.4,2.2
      c-0.5,0.7-0.9,1.5-1.2,2.8c-1.6-1.7-2.9-1.9-4.3-1.9c-1.6,0-3.1,0.7-4.1,1.9c-0.4,0.5-0.8,1-1.1,1.9c-1.2-1.4-2.6-2.2-4.5-2.2
      c-1,0-3.4,0.3-5,3.1c0,0.1-0.1,0.2-0.2,0.3c0-0.1-0.1-0.2-0.2-0.3c-1.6-2.9-4-3.1-5-3.1c-1.9,0-3.3,0.8-4.5,2.2
      c-0.3-0.8-0.7-1.3-1.1-1.9c-1-1.2-2.6-1.9-4.1-1.9c-1.5,0-2.8,0.2-4.3,1.9c-0.2-1.3-0.7-2.1-1.2-2.8c-1-1.4-2.6-2.2-4.4-2.2
      c-1,0-2,0.1-3.3,0.9c-0.1-1.2-0.5-2-1-2.8c-1-1.6-2.7-2.6-4.7-2.6c-0.9,0-1.8,0.1-3,0.7c0.3-1.8,0-2.9-0.6-3.9
      c-0.9-1.9-2.8-2.9-4.9-2.9c-0.5,0-1,0-1.6,0.2c0.5-1.6,0.1-2.7-0.3-3.8c-0.9-2.1-2.9-3.4-5.3-3.4c-0.3,0-0.7,0-1.1,0.1
      c1.1-2,0.9-3.3,0.4-4.8c-0.6-1.8-2.2-3.6-5.2-3.8c1.2-1.8,1.1-3.1,0.8-4.6c-0.3-1.1-1.3-3.7-5-4.3c1.2-1.2,2.3-3,1.9-5.2
      c-0.1-1-0.9-3.2-3.8-4.4c1.5-1.2,2.4-2.9,2.3-4.9c-0.2-2.5-1.8-3.9-3.5-4.8c1.7-0.9,3.3-2.3,3.5-4.8c0.1-2.1-0.7-3.7-2.3-4.9
      c2.9-1.1,3.6-3.4,3.8-4.4c0.3-2.2-0.7-3.9-1.9-5.2c3.8-0.6,4.8-3.1,5-4.3c0.4-1.5,0.5-2.8-0.8-4.6c2.9-0.2,4.6-2.1,5.1-3.8
      c0.5-1.4,0.7-2.7-0.4-4.8c0.4,0,0.8,0.1,1.1,0.1c2.4,0,4.4-1.3,5.3-3.4c0.5-1.1,0.8-2.2,0.3-3.8c0.6,0.1,1.1,0.2,1.6,0.2
      c2.1,0,4-1.1,4.9-2.9c0.5-1,0.9-2.1,0.6-3.9c1.2,0.6,2.1,0.7,3,0.7c1.9,0,3.7-1,4.7-2.6c0.5-0.8,0.9-1.6,1-2.8
      c1.2,0.8,2.2,0.9,3.2,0.9c1.8,0,3.3-0.8,4.4-2.2c0.5-0.7,1-1.5,1.2-2.8c1.6,1.7,2.9,1.9,4.3,1.9c1.6,0,3.1-0.7,4.1-1.9
      c0.4-0.5,0.8-1,1.1-1.9c1.2,1.4,2.6,2.2,4.5,2.2c1,0,3.4-0.3,5-3.1C254.9,53.7,255,53.7,255,53.6z M255-139
      c0,128.7-0.2,191.3-5.2,191.3c-4.7,0-13.6-55.3-30.7-163C236.4-1.6,245,53.8,240.1,53.8c-5.1,0-24.6-59.4-63.8-180.2
      C216.5-2.4,235.6,57,230.1,57c-5,0-30.6-49.8-79.4-145.5c50.9,99.9,76.2,150,70.5,150c-5.7,0-42.6-50.6-116.2-151.8
      C183.1,17,219.5,67.6,212.8,67.6c-6,0-45.6-39.4-120.1-113.9c81.2,81.2,120.7,121,113.1,121c-7.3,0-58-36.6-156.9-108.4
      C159.4,46.5,209.4,83.1,199.3,83.1c-8.4,0-58-25.1-148.7-71.3C158,66.5,207.6,92,193.8,92C182,92,122.7,73,12.7,37.2
      c142.6,46.4,199.7,65.2,174.3,65.2c-16.9,0-70-8.3-158.6-22.3C250.4,115.2,249.8,116,0,116c249.8,0,250.4,0.7,28.3,35.9
      c88.6-14,141.8-22.3,158.6-22.3c25.4,0-31.7,18.8-174.5,65.2c110.2-35.8,169.4-54.9,181.3-54.9c13.7,0-35.8,25.5-143.3,80.3
      c90.7-46.2,140.3-71.3,148.7-71.3c10.1,0-39.9,36.6-150.4,116.9c98.8-71.8,149.6-108.4,156.9-108.4c7.7,0-31.8,39.8-113,120.9
      c74.5-74.5,114.2-113.9,120.1-113.9c6.7,0-29.7,50.6-107.7,157.9C178.7,221,215.7,170.5,221.4,170.5c5.7,0-19.6,50.1-70.5,150
      c48.8-95.7,74.4-145.5,79.4-145.5c5.5,0-13.6,59.4-53.9,183.4c39.3-120.8,58.7-180.2,63.8-180.2c4.9,0-3.7,55.4-21,164.4
      c17-107.5,26-162.8,30.7-162.8c5,0,5.2,62.4,5.2,191c0-128.5,0.2-191,5.2-191c4.7,0,13.6,55.3,30.7,163
      c-17.3-109.1-25.9-164.5-21-164.5c5.1,0,24.6,59.4,63.8,180.2C293.5,234.4,274.4,175,279.9,175c5,0,30.6,49.8,79.4,145.5
      c-50.9-99.9-76.2-150-70.6-150c5.7,0,42.6,50.6,116.2,151.8c-78-107.4-114.5-158-107.7-158c6,0,45.6,39.4,120.1,113.9
      c-81.1-81.1-120.6-120.9-113-120.9c7.3,0,58,36.6,156.9,108.4c-110.5-80.3-160.5-116.9-150.4-116.9c8.4,0,58,25.1,148.7,71.3
      c-107.5-54.7-157.1-80.3-143.3-80.3c11.9,0,71.1,19.1,181.2,54.8c-142.7-46.3-199.7-65.2-174.3-65.2c16.9,0,70,8.3,158.6,22.3
      C259.6,116.8,260.2,116,510,116c-249.8,0-250.4-0.7-28.3-35.9c-88.6,14-141.8,22.3-158.6,22.3c-25.4,0,31.7-18.8,174.5-65.2
      C387.4,73.1,328.1,92.2,316.3,92.2c-13.7,0,35.8-25.5,143.3-80.3C368.8,58,319.2,83.1,310.8,83.1c-10.1,0,39.9-36.6,150.4-116.9
      C362.3,38,311.6,74.6,304.3,74.6c-7.7,0,31.8-39.8,112.9-120.8c-74.5,74.5-114.1,113.8-120,113.8c-6.7,0,29.7-50.6,107.7-157.9
      C331.3,11,294.3,61.5,288.6,61.5c-5.7,0,19.6-50.1,70.5-150C310.4,7.2,284.8,57,279.8,57c-5.5,0,13.6-59.4,53.9-183.4
      C294.5-5.6,275,53.8,269.9,53.8c-4.9,0,3.7-55.4,21-164.5c-17.1,107.6-26,163-30.7,163C255.2,52.3,255-10.3,255-139z M333.8-126.5
      c0,0,0,0.1,0,0.1C333.8-126.4,333.8-126.5,333.8-126.5z M176.2-126.5c0,0,0,0.1,0,0.2C176.3-126.4,176.2-126.5,176.2-126.5z
      M417.3-46.3C417.2-46.2,417.2-46.2,417.3-46.3C417.2-46.2,417.2-46.2,417.3-46.3z M461.3-33.9c0,0-0.1,0.1-0.2,0.1
      C461.2-33.8,461.2-33.8,461.3-33.9z M48.7-33.9c0,0,0.1,0,0.1,0.1C48.8-33.8,48.8-33.8,48.7-33.9z M12.5,37.2c0,0,0.1,0,0.1,0
      C12.6,37.3,12.5,37.2,12.5,37.2z M497.4,194.7c0,0,0.1,0,0.1,0C497.5,194.8,497.4,194.8,497.4,194.7z M461.1,265.8
      c0,0,0.1,0.1,0.1,0.1C461.2,265.8,461.2,265.8,461.1,265.8z M48.9,265.8c0,0-0.1,0-0.1,0.1C48.8,265.8,48.8,265.8,48.9,265.8z
      M219.1,342.5c0,0,0,0.1,0,0.1C219.1,342.6,219.1,342.6,219.1,342.5z M333.7,358.4c0,0,0,0.1,0,0.2
      C333.8,358.5,333.7,358.4,333.7,358.4z M176.3,358.4c0,0,0,0.1,0,0.1C176.2,358.5,176.3,358.4,176.3,358.4z M255,370.7
      c0,0.1,0,0.2,0,0.3C255,370.9,255,370.8,255,370.7z`,
})`
  fill: url(#SVGID_1_);
`;

const BadgeBackground = styled(DarkFill).attrs({
  d: `M324.4,116c0,38.4-31.1,69.4-69.4,69.4c-38.4,0-69.4-31.1-69.4-69.4c0-38.4,31.1-69.4,69.4-69.4
	    C293.4,46.6,324.4,77.6,324.4,116z`,
})``;

const BadgeInnerBackground = styled.path.attrs({
  d: `M255,175.5c34.5,0,62.5-34.5,62.5-59.5c0-34.5-28-62.5-62.5-62.5c-34.5,0-62.5,28-62.5,62.5
      C192.5,145.3,220.5,175.5,255,175.5z`,
})`
  fill: ${theme.colors.oranges[20]};
`;

const BadgeOutline = styled(DarkFill).attrs({
  d: `M324.4,116c0,38.4-31.1,69.4-69.4,69.4c-38.4,0-69.4-31.1-69.4-69.4c0-38.4,31.1-69.4,69.4-69.4
      C293.4,46.6,324.4,77.6,324.4,116z M317.1,123.4c-3.6,31-30,55.1-62.1,55.1c-32,0-58.4-24.1-62.1-55.1l1.3,3.9l5.2,7.4
      c-3.7-6.2-9.5-18.5-4.6-35.6l0.7-2.2c-0.2,0.7-0.5,1.5-0.7,2.2l-0.3,1.1c7-26.9,31.4-46.7,60.5-46.7c28.8,0,53.1,19.5,60.3,46.1
      c4.4,17.2-0.6,27.3-4.4,33.8l4.8-6.1L317.1,123.4z`,
})``;

const FlameOutline = styled(DarkFill).attrs({
  d: `M317.5,118.3c-5.9,18.4-16.1,30.6-27.7,30.6c-18.2,0-33-29.9-33-66.8c0-10.2,1.1-19.8,3.1-28.5
      c-1.6-0.1-3.2-0.2-4.9-0.2c-1.6,0-3.1,0.1-4.6,0.2c1.3,7.7,2,15.9,2,24.6c0,39.1-14.8,70.7-33,70.7c-11.1,0-20.8-11.7-26.8-29.5
      c1.8,32.9,29,59.1,62.4,59.1C288.7,178.5,316.2,151.8,317.5,118.3z`,
})``;

const FlameMiddle = styled.path.attrs({
  d: `M297.8,161.4c-1.5,0.4-3.1,0.6-4.6,0.6c-21.1,0-38.2-34.6-38.2-77.3c0-11.1,1.2-21.6,3.2-31.2
      c-1.1-0.1-2.2-0.1-3.3-0.1c-1.3,0-2.7,0-4,0.1c2.1,9.5,3.2,20.1,3.2,31.1c0,42.7-17.1,77.3-38.2,77.3c-1.3,0-2.5-0.1-3.7-0.4
      c11.2,10.5,26.2,16.9,42.7,16.9C271.5,178.5,286.6,172,297.8,161.4z`,
})`
  clip-rule: evenodd;
  fill-rule: evenodd;
  fill: ${theme.colors.oranges[40]};
`;

const FlameInner = styled.path.attrs({
  d: `M279.7,173.4c-13.1-10.5-22.9-36.2-25.1-67.6c-2.1,31.1-11.8,56.8-24.8,67.4c7.7,3.4,16.2,5.3,25.2,5.3
      C263.8,178.5,272.1,176.7,279.7,173.4z M254.6,80.1c0.6-9.4,2-18.4,3.9-26.5c-1.2-0.1-2.4-0.1-3.6-0.1c-1.4,0-2.8,0-4.2,0.1
      C252.7,61.8,254,70.7,254.6,80.1z`,
})`
  clip-rule: evenodd;
  fill-rule: evenodd;
  fill: #ffce7e;
`;

const StarLeft = styled.path.attrs({
  d: `M208.7,125c0,0,4.1-0.3,5.6-1.9c1.4-1.5,1.8-5.5,1.8-5.5c0.8,5.2,2.4,6.9,7.4,7.4c0,0-3.7,0.4-5.3,2
	    c-1.6,1.6-2.1,5.4-2.1,5.4C215.8,127,214.2,125.4,208.7,125z`,
})`
  fill: ${theme.colors.white};
`;

const StarRight = styled.path.attrs({
  d: `M295.5,109.3c0,0,2.7-0.2,3.7-1.3c1-1,1.2-3.6,1.2-3.6c0.5,3.5,1.6,4.6,4.9,4.9c0,0-2.5,0.3-3.5,1.3
	    c-1.1,1.1-1.4,3.6-1.4,3.6C300.2,110.6,299.2,109.6,295.5,109.3z`,
})`
  fill: ${theme.colors.white};
`;

const Rocket = styled.path.attrs({
  d: `M281,91.8l-9.9-11.6c0,0,0-8.9-0.1-9c-0.5-11-13.1-23.8-16-23.8c-1.1,0.1-2.9,1.4-5.4,3.9
      c-5,5-10.4,12.7-10.8,19.7c-0.1,0.1-0.1,9-0.1,9l-9.8,11.5c-0.2,0.2-0.4,0.6-0.3,0.9l0.1,19.7c0,0.6,0.3,1.1,0.9,1.3
      c0.4,0.2,1.1,0.1,1.5-0.3l7.9-7.5v6.4c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4l2.8,0.1l2.8,7.5c0.2,0.6,0.7,0.9,1.3,0.9h15.7
      c0.4,0,0.7-0.1,1-0.4c0.1-0.1,0.3-0.3,0.3-0.5l2.7-7.4h2.9c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1v-6.4l7.9,7.5
      c0.4,0.4,1.1,0.5,1.5,0.3c0.1-0.1,0.3-0.1,0.5-0.3c0.3-0.3,0.4-0.6,0.4-1V92.7C281.3,92.3,281.2,92,281,91.8z M260,76.2
      c-1.4,1.4-3.1,2.1-5,2.1c-1.9,0-3.6-0.7-5-2.1c-1.4-1.4-2.1-3.1-2.1-5s0.8-3.7,2.1-5c1.4-1.4,3.1-2.1,5.1-2c3.9,0,7,3.1,7,7
      C262.1,73.2,261.2,75,260,76.2z`,
})`
  fill: ${theme.colors.white};
`;

const RocketWindow = styled.path.attrs({
  d: `M254.9,63.3c4.5,0,8.2,3.7,8.2,8.2s-3.7,8.2-8.2,8.2c-4.5,0-8.2-3.7-8.2-8.2S250.4,63.3,254.9,63.3z`,
})`
  fill: ${theme.colors.purples[50]};
`;

const RocketStarburst = () => {
  return (
    <SVG width={510} height={244} viewBox="0 0 510 244">
      <radialGradient
        id="SVGID_1_"
        cx="-342.1294"
        cy="724.349"
        r="1"
        gradientTransform="matrix(1.561425e-14 255 255 -1.561425e-14 -184454 87359)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style={{ stopColor: '#e4e9e2' }} />
        <stop offset="1" style={{ stopColor: '#e4e9e2', stopOpacity: 0.19 }} />
      </radialGradient>
      <StarBurst />
      <BadgeBackground />
      <BadgeInnerBackground />
      <FlameOutline />
      <StarRight />
      <BadgeOutline />
      <FlameMiddle />
      <FlameInner />
      <StarLeft />
      <Rocket />
      <RocketWindow />
    </SVG>
  );
};

export default RocketStarburst;
