import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../SVG';

const DEFAULT_SIZE = 80;

const Icon = ({ size, ...otherProps }) => (
  <SVG
    {...otherProps}
    size={size}
    viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}
  >
    <path
      d="M0 40c0 22.091 17.909 40 40 40s40-17.909 40-40S62.091 0 40 0 0 17.909 0 40z"
      fill="#F2A645"
    />
    <path
      d="M39.998 38.434a4.642 4.642 0 00-4.634 4.634 4.642 4.642 0 004.634 4.633 4.642 4.642 0 004.633-4.633 4.642 4.642 0 00-4.633-4.634z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.996 12.2c1.22 0 2.343.667 2.928 1.738l21.333 39.1a3.333 3.333 0 01-2.926 4.93H18.698a3.333 3.333 0 01-2.928-4.929l21.3-39.1a3.333 3.333 0 012.926-1.738zM40 22.499L24.31 51.3h31.405L40 22.498z"
      fill="#fff"
    />
  </SVG>
);

Icon.defaultProps = {
  size: DEFAULT_SIZE,
};

Icon.propTypes = {
  size: PropTypes.number,
};

export default Icon;
