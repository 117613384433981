export const FREQUENCY_OPTIONS = [
  {
    label: 'Weekly (recommended)',
    value: 'weekly',
    capitalized: 'Weekly'
  },
  {
    label: 'Bi-Weekly (every 2 weeks)',
    value: 'biweekly',
    capitalized: 'Bi-Weekly'
  },
  {
    label: 'Monthly',
    value: 'monthly',
    capitalized: 'Monthly'
  },
  {
    label: 'Bi-Monthly (every 2 months)',
    value: 'bimonthly'
  },
  {
    label: 'Quarterly (every 3 months)',
    value: 'quarterly'
  }
];

export const ENPS_FREQUENCY_OPTIONS = [
  {
    label: 'Monthly (recommended)',
    value: 'monthly'
  },
  {
    label: 'Every 2 Months',
    value: 'every-two-months'
  },
  {
    label: 'Every 3 Months',
    value: 'every-three-months'
  }
];

export function getRecipients(tenant, currentWorkspace) {
  if (!tenant || !currentWorkspace) {
    return [];
  }

  const { isEnterprise, workspaces } = tenant || {};
  const { activeMembersCount, slackWorkspace, teamsWorkspace } = currentWorkspace;
  const channelName = slackWorkspace ? `#${slackWorkspace.primaryChannel}` : teamsWorkspace?.channelName;
  const channelId = slackWorkspace ? slackWorkspace.primaryChannelId : teamsWorkspace?.channelId;
  const recipients = [];

  if (isEnterprise) {
    const channels = workspaces.map((workspace) => {
      const { slackWorkspace, teamsWorkspace } = workspace;

      if (slackWorkspace) {
        return {
          label: `#${slackWorkspace.primaryChannel}`,
          value: slackWorkspace.primaryChannel.id,
          subLabel: ''
        };
      } else if (teamsWorkspace) {
        return {
          label: teamsWorkspace.channelName,
          value: teamsWorkspace.channelId,
          subLabel: ''
        };
      }
    });
    recipients.push(...channels);
  } else {
    recipients.push({
      label: `Everyone in ${channelName} (${activeMembersCount} members)`,
      value: channelId,
      subLabel: ''
    });
  }

  return recipients;
}