import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Container from './Container';
import Badge from '../Badge';
import { IconCloseSmall } from '../../Icon';

const BasicContainer = styled(Container)`
  color: ${() => theme.colors.purples[50]};
  font-weight: bold;
`;

const SkillBadgeContainer = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: ${() => theme.colors.blacks[20]};

  margin-right: 6px;

  svg {
    width: 10px;
    height: 10px;
    path {
      fill: white;
    }
  }

  &:hover {
    color: ${() => theme.colors.blues[50]};
    background: ${() => theme.colors.blues[5]};

    svg path {
      fill: ${() => theme.colors.blues[50]};
    }
  }

  &:active {
    color: white;
    background: ${() => theme.colors.blues[60]};

    svg path {
      fill: white;
    }
  }
`;

const Basic = ({ skill, onReset }) => (
  <BasicContainer>
    <SkillBadgeContainer>
      <Badge size="small" skill={skill} />
    </SkillBadgeContainer>
    <span>{skill.name}</span>

    <CloseContainer onClick={onReset}>
      <IconCloseSmall />
    </CloseContainer>
  </BasicContainer>
);

Basic.propTypes = {
  skill: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onReset: PropTypes.func.isRequired,
};

export default Basic;
