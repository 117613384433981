import React from 'react';
import styled from 'styled-components';
import { colors } from '@matterapp/matter-theme';
import * as C from './classNames';

export const SQUARE_SIZE = 25;
export const BORDER_SIZE = 1;

const FaceFill = styled.div`
  height: ${SQUARE_SIZE - BORDER_SIZE}px;
  width: ${SQUARE_SIZE - BORDER_SIZE}px;
  border-radius: 2px;
`;

const Face = styled.div`
  background: ${colors.purples[60]};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: ${SQUARE_SIZE + BORDER_SIZE}px;
  width: ${SQUARE_SIZE + BORDER_SIZE}px;
  backface-visibility: hidden;
`;

const CubeContainer = styled.div`
  opacity: 1;
  position: absolute;
  height: ${SQUARE_SIZE * 2}px;
  width: ${SQUARE_SIZE * 2}px;
  transform-style: preserve-3d;

  & ${Face}:nth-child(1) {
    transform: translateZ(${SQUARE_SIZE / 2}px);
    & ${FaceFill} {
      background-color: ${colors.yellows[40]};
    }
  }
  & ${Face}:nth-child(2) {
    transform: rotateX(90deg) translateZ(${SQUARE_SIZE / 2}px);
    & ${FaceFill} {
      background-color: ${colors.purples[40]};
    }
  }
  & ${Face}:nth-child(3) {
    transform: rotateX(-90deg) translateZ(${SQUARE_SIZE / 2}px);
    & ${FaceFill} {
      background-color: ${colors.reds[40]};
    }
  }
  & ${Face}:nth-child(4) {
    transform: rotateY(-90deg) translateZ(${SQUARE_SIZE / 2}px);
    & ${FaceFill} {
      background-color: ${colors.blues[40]};
    }
  }
  & ${Face}:nth-child(5) {
    transform: rotateY(90deg) translateZ(${SQUARE_SIZE / 2}px);
    & ${FaceFill} {
      background-color: ${colors.greens[40]};
    }
  }
  & ${Face}:nth-child(6) {
    transform: translateZ(-${SQUARE_SIZE / 2}px) rotateY(180deg);
    & ${FaceFill} {
      background-color: ${colors.oranges[40]};
    }
  }
`;

const RowContainer = styled.div`
  transform-style: preserve-3d;
  & ${CubeContainer}:nth-child(2) {
    transform: translateX(${SQUARE_SIZE}px);
  }
`;

const SectionContainer = styled.div`
  transform-style: preserve-3d;

  & ${RowContainer}:nth-child(1) {
    transform: translateZ(${SQUARE_SIZE / 2}px);
  }
  & ${RowContainer}:nth-child(2) {
    transform: translateZ(-${SQUARE_SIZE / 2}px);
  }
`;
const Main = styled.div`
  width: ${SQUARE_SIZE * 2}px;
  height: ${SQUARE_SIZE * 2}px;
  margin: 0 auto;
  margin-top: 150px;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  position: absolute;
  pointer-events: none;
  right: 30px;
  bottom: 20px;

  & ${SectionContainer}:nth-child(2) {
    transform: translateY(${SQUARE_SIZE}px) rotateY(0deg);
  }
`;

const CubeFace = () => (
  <Face>
    <FaceFill />
  </Face>
);

const Cube = (props) => (
  <CubeContainer {...props}>
    <CubeFace />
    <CubeFace />
    <CubeFace />
    <CubeFace />
    <CubeFace />
    <CubeFace />
  </CubeContainer>
);

const Row = (props) => (
  <RowContainer {...props}>
    <Cube />
    <Cube />
  </RowContainer>
);

const Section = (props) => (
  <SectionContainer {...props}>
    <Row />
    <Row />
  </SectionContainer>
);

const CubeShape = (props) => {
  const { className, otherProps } = props;
  return (
    <Main {...otherProps} className={[className, C.CUBE_3D.SHAPE].join(' ')}>
      <Section className={C.CUBE_3D.SECTION_1} />
      <Section className={C.CUBE_3D.SECTION_2} />
    </Main>
  );
};

export default CubeShape;
