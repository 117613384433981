import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Resources } from '@matterapp/routing';
import { Modal, Avatar, IconNew as Icon, Clickable } from '@matterapp/matter-ui';

const StyledProfileModal = styled(Modal).attrs(() => ({
  fullScreenMobile: true,
  size: Modal.sizes.XL,
}))``;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.spacing.double};
  margin-bottom: 34px;
  ${theme.media.S`
    margin-bottom: ${theme.spacing.triple};
    margin-top: ${theme.spacing.triple};
  `}
`;

const Headline = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[80]};
`;

const Bio = styled.div`
  width: 327px;
  text-align: center;
  margin-top: ${theme.spacing.half};
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
`;

const NameContainer = styled.div`
  height: ${theme.spacing.triple};
  font-weight: ${theme.fontWeight.black};
  font-size: ${theme.fontSize.XL};
  margin-top: ${theme.spacing.singleAndHalf};
  color: ${theme.colors.blacks[80]};
`;

const LinkIcon = styled(Icon).attrs({
  name: 'internalLink',
})`
  margin: -6px 0;
  & path {
    fill: currentColor;
  }
`;

const FeedbackCountContainer = styled.div`
  width: 100%;
  margin: 0 0 ${theme.spacing.triple};
  border-bottom: 1px solid ${theme.colors.blacks[5]};
`;

const FeedbackCountKey = styled.div`
  color: ${theme.colors.blacks[50]};
  text-transform: capitalize;
  font-weight: ${theme.fontWeight.regular};
`;

const FeedbackCountValue = styled.div`
  color: ${theme.colors.blacks[60]};
  font-weight: ${theme.fontWeight.bold};
`;

const FeedbackCountRow = styled(Clickable)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.half} ${theme.spacing.singleAndHalf};
  border-top: 1px solid ${theme.colors.blacks[5]};
  line-height: ${theme.lineHeight.M};
  font-size: ${theme.fontSize.S2};
  ${({ to }) =>
  to
    ? `
    cursor: pointer;
    ${FeedbackCountValue} {
      color: ${theme.colors.blues[50]};
    }
    &:hover ${FeedbackCountKey},
    &:hover ${FeedbackCountValue} {
      color: ${theme.colors.blues[50]};
    }
    &:active {
      text-decoration: none;
    }
  `
    : `
    cursor: default;
  `}
`;

const ProfileModal = (props) => {
  const { isOpen, onCloseProfileModal, headline, bio, photoUrl, name, feedbackCounts } = props;

  const renderFeedbackCountRow = (rowProps) => {
    const { key, to, value } = rowProps;
    return (
      <FeedbackCountRow to={to}>
        <FeedbackCountKey>
          {key}
          {to && <LinkIcon />}
        </FeedbackCountKey>
        <FeedbackCountValue>{value || '-'}</FeedbackCountValue>
      </FeedbackCountRow>
    );
  };

  const renderFeedbackCounts = () => {
    if (!feedbackCounts) { return null; }
    const { given, pending, received } = feedbackCounts;
    return (
      <FeedbackCountContainer>
        {renderFeedbackCountRow({ key: 'Feedback Received', value: received })}
        {renderFeedbackCountRow({ key: 'Feedback Given', value: given })}
        {renderFeedbackCountRow({
          key: 'Pending Requests',
          value: pending,
          to: Resources.feedbackRequestsMain.path(),
        })}
      </FeedbackCountContainer>
    );
  };
  
  return (
    <StyledProfileModal isOpen={isOpen} onClose={onCloseProfileModal} >
      <StyledProfileModal.Header.WithClose
        header='Profile'
        onClickClose={onCloseProfileModal}
      />
      <StyledProfileModal.Body>
        <DetailsContainer>
          <Avatar
            size={80}
            url={photoUrl}
          />
          {name &&
            <NameContainer>{name}</NameContainer>
          }
          {headline && <Headline>{headline}</Headline>}
          {bio && <Bio>{bio}</Bio>}
        </DetailsContainer>
        {renderFeedbackCounts()}
      </StyledProfileModal.Body>
      <StyledProfileModal.Footer.WithClose
        onClickClose={onCloseProfileModal}
      />
    </StyledProfileModal>
  );
};

ProfileModal.propTypes = {
  isOpen: PropTypes.bool,
  onCloseProfileModal: PropTypes.func,
  headline: PropTypes.string,
  bio: PropTypes.string,
  photoUrl: PropTypes.string,
  name: PropTypes.string,
  feedbackCounts: PropTypes.object
};

export default ProfileModal;
