import { connect } from 'react-redux';
import { setNumInvited } from '../reducers';

const mapStateToProps = (state) => ({
  numInvited: state.onboarding.numInvited,
});
const mapDispatchToProps = (dispatch) => ({
  setNumInvited: (numInvited) => dispatch(setNumInvited(numInvited)),
});

const withNumInvited = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withNumInvited;
