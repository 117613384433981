import styled from 'styled-components';
import { ClickableLink, Confirm, Peer, TextArea } from '@matterapp/matter-ui';
import { colors, font, spacing, zIndex } from '@matterapp/matter-theme';

export const Modal = styled(Confirm).attrs(() => ({
  cancelButtonLabel: 'Cancel',
  confirmButtonLabel: 'Send',
  openLowerFromTop: false,
  centerAlignHeader: true,
}))`
  z-index: ${zIndex.confirm};
`;

export const Wrapper = styled.div`
  padding: ${spacing.singleAndHalf} 0 ${spacing.threeQuarters};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ spaceAbove }) => spaceAbove ? spacing.singleAndHalf : 0};
  margin-bottom: ${spacing.single};
`;

export const Header = styled.div`
  font-size: ${font.size.S};
  font-weight: ${font.weight.bold};
`;

export const ResetButton = styled(ClickableLink).attrs(() => ({
  children: 'Reset to default',
}))`
  color: ${colors.blacks[50]};
  font-size: ${font.size.base};
`;

export const AdminItem = styled(Peer.ListItem).attrs(() => ({
  subHeader: 'Workspace Admin',
}))`
  border: none;
  padding: 0;
  margin: 0;
`;

export const Message = styled(TextArea).attrs(() => ({}))`
  font-weight: ${font.weight.regular};
  min-height: 160px;
`;