import { compose, withProps } from 'recompose';
import { DUPLICATE_SKILL_NAME } from '@matterapp/matter-errors';
import Toast from 'components/Toast/Toast';

const withCustomSkillAsyncValidationError = compose(
  withProps(() => ({
    handleCustomSkillAsyncValidationError: (error) => {
      let isDuplicateSkillName = false;
      const { graphQLErrors } = error;
      if (graphQLErrors && graphQLErrors.length > 0) {
        const [firstError] = graphQLErrors;
        isDuplicateSkillName = firstError.name === DUPLICATE_SKILL_NAME;
      }
      if (isDuplicateSkillName) {
        Toast.error('Sorry! You already have a skill with this name');
      } else {
        Toast.error('Something went wrong. Please try again');
      }
    },
  }))
);

export default withCustomSkillAsyncValidationError;
