import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown';
import { validateEmail } from '../../../libs/emailValidator';
import { DEFAULT_EMPTY_MESSAGE, DEFAULT_PLACEHOLDER } from './utils';

export const formatItems = (items, showSubLabel = true) =>
  items.map((item) => ({
    ...item,
    label: item.fullName || item.email,
    subLabel: showSubLabel && item.fullName ? item.email : null,
    value: item.email,
  }));

const Email = (props) => {
  const {
    autoValidateEmail,
    errorMessage,
    items,
    value,
    ...dropdownProps
  } = props;
  const error =
    autoValidateEmail && value
      ? validateEmail(value, true) || errorMessage
      : errorMessage;

  return (
    <Dropdown
      errorMessage={error}
      formatSearchResults={formatItems}
      showLabelAsValue={false}
      showSelectedItemInMenu
      showValuesAsSubLabels
      items={formatItems(items)}
      placeholder={DEFAULT_PLACEHOLDER}
      value={value}
      {...dropdownProps}
    />
  );
};

Email.defaultProps = {
  autoValidateEmail: false,
  emptyMenuMessage: DEFAULT_EMPTY_MESSAGE,
  items: [],
};

Email.propTypes = {
  /** If component should auto-validate email. */
  autoValidateEmail: PropTypes.bool,
  /** Error message to display. */
  errorMessage: PropTypes.string,
  /** Message to show in empty menu. */
  emptyMenuMessage: PropTypes.node,
  /** Items to display in the dropdown. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  /** The email value. */
  value: PropTypes.string,
};

Email.formatItems = formatItems;

export default Email;
