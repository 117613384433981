import styled from 'styled-components';
import Button from '../../../Button';
import Success from '../../../Success';
import theme, { colors, spacing } from '@matterapp/matter-theme';

const abilityButtonColors = {
  Red: {
    hover: colors.reds[20],
    active: colors.reds[40],
  },
  Pink: {
    hover: colors.reds[20],
    active: colors.reds[40],
  },
  Green: {
    hover: colors.greens[40],
    active: colors.greens[50],
  },
  Blue: {
    hover: colors.blues[20],
    active: colors.blues[40],
  },
  Orange: {
    hover: colors.oranges[20],
    active: colors.oranges[40],
  },
  Yellow: {
    hover: colors.yellows[40],
    active: colors.yellows[50],
  },
  Purple: {
    hover: colors.purples[20],
    active: colors.purples[30],
  },
};

export const AbilityButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  flex-wrap: wrap;
  width: 100%;
`;

export const AbilityButton = styled(Button.Minimal)`
  margin: 5px;
  margin-left: 0;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.blacks[60]} !important;
  border: 1px solid ${({ borderColor }) => borderColor};
  background-color: white !important;
  ${theme.media.S`
    display: inline-block;
    width: auto;
  `}
  &:hover {
    color: ${colors.blacks[70]} !important;
    border-color: ${({ colorState }) => colorState && abilityButtonColors[colorState].hover} !important;
  }
  &:active {
    border-color: ${({ colorState }) => colorState && abilityButtonColors[colorState].active} !important;
  }
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SkillName = styled.div`
  font-weight: bold;
  font-size: ${theme.sizes.fontSizes[55]};
  line-height: ${theme.sizes.lineHeights[60]};
`;

export const AbilityModal = styled(Success.Modal)`
  padding: ${spacing.double};
`;

export const AbilityName = styled.div`
  color: ${colors.blacks[80]};
  font-weight: bold;
  line-height: ${theme.sizes.lineHeights[50]};
  font-size: ${theme.sizes.fontSizes[50]};
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const AbilitySubtitle = styled.div`
  color: ${colors.blacks[80]};
  font-weight: normal;
  line-height: ${theme.sizes.lineHeights[50]};
  font-size: ${theme.sizes.fontSizes[50]};
  width: 300px;
`;

export const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const AbilityDescription = styled.div`
  margin: ${spacing.double};
  font-size: ${theme.sizes.fontSizes[50]};
  line-height: ${theme.sizes.lineHeights[50]};
`;

export const LearnMoreButton = styled(Button.New)`
  padding-top: ${spacing.threeQuarters};
  padding-bottom: ${spacing.threeQuarters};
  font-size: ${theme.sizes.fontSizes[50]};
  line-height: ${theme.sizes.lineHeights[50]};
`;

export const CloseButton = styled(Button.Simple)`
  border: none;
  color: ${colors.blues[80]};
  margin: ${spacing.single} 0px;
`;
