import React from 'react';
import { Resources } from '@matterapp/routing';
import { Route } from 'react-router-dom';
import { compose } from 'recompose';
import {
  ResponseFlowWelcomePage,
  ResponseFlowThankYouPage,
  ResponseFlowBeginFeedbackPage,
  ResponseFlowEndFeedbackPage,
  ResponseFlowCantRateSelfPage,
  ResponseFlowRequestVerificationPage,
  ResponseFlowSubmitVerificationPage,
  ResponseFlowTimelinePage,
  RecurringResponseFlowPage,
} from '../pages/response-flow';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import {
  withRouteParams,
  withStepPaths,
  withPageViewTracking,
  withQueryParams,
} from '../../hocs';
import withCurrentUser from 'context/UserContext/withCurrentUser';

const RECURRING_RATING_PARAM = '?rating=';

const withFeedbackErrorPaths = withStepPaths({
  cantRateSelfPath: ({ invitationUuid }) =>
    Resources.responseFlowCantRateSelf.path({
      invitationUuid,
    }),
});

const responseFlowTimelinePageHoc = compose(
  withQueryParams,
  withRouteParams({
    invitationUuid: true,
  }),
  withCurrentUser,
  withStepPaths({
    nextStepPath: ({ invitationUuid }) =>
      Resources.responseFlowThankYou.path({
        invitationUuid,
      }),
    cantRateSelfPath: ({ invitationUuid }) =>
      Resources.responseFlowCantRateSelf.path({
        invitationUuid,
      }),
  }),
  withPageViewTracking({
    pageTitle: 'response-flow/rfr',
  })
);

const ResponseFlowTimelinePageComponent = responseFlowTimelinePageHoc(ResponseFlowTimelinePage);

export const ResponseFlowTimeline = (
  <Route
    exact
    path={Resources.responseFlowTimeline.pattern}
    element={<ResponseFlowTimelinePageComponent />}
  />
);

const responseFlowWelcomeHoc = compose(
  withQueryParams,
  withRouteParams({
    invitationUuid: true,
  }),
  withStepPaths({
    authorizedPath: ({ invitationUuid, queryParams }) => {
      const { rating } = queryParams;
      if (rating) {
        return `${Resources.responseFlowBeginFeedback.path({ invitationUuid })}${RECURRING_RATING_PARAM}${queryParams.rating}`;
      }
      return Resources.responseFlowBeginFeedback.path({ invitationUuid });
    },
  }),
  withPageViewTracking({
    pageTitle: 'response-flow/welcome',
    getEventParamsFromProps: ({ invitationUuid }) => ({
      invitationType: (() => {
        if (invitationUuid.length <= 8) { return 'url'; }
        return 'email';
      })(),
    }),
  })
);
const ResponseFlowWelcomeComponent = responseFlowWelcomeHoc(ResponseFlowWelcomePage);

export const ResponseFlowWelcome = (
  <Route
    exact
    path={Resources.responseFlowWelcome.pattern}
    element={<ResponseFlowWelcomeComponent />}
  />
);

const responseFlowBeginFeedbackSharedHoc = compose(
  withRouteParams({
    invitationUuid: true,
  }),
  withQueryParams,
  withFeedbackErrorPaths,
  withStepPaths({
    nextStepPath: ({ queryParams, invitationUuid }) => {
      const { rating } = queryParams;
      if (rating) {
        return `${Resources.recurringResponseFlow.path({ invitationUuid })}${RECURRING_RATING_PARAM}${rating}`;
      }
      return Resources.responseFlowTimeline.path({
        invitationUuid,
      });
    },
  })
);
const responseFlowBeginFeedbackPageHoc = compose(
  responseFlowBeginFeedbackSharedHoc,
  withPageViewTracking({
    pageTitle: 'response-flow/begin-feedback',
  })
);

const ResponseFlowBeginFeedbackComponent = responseFlowBeginFeedbackPageHoc(ResponseFlowBeginFeedbackPage);

export const ResponseFlowBeginFeedback = (
  <Route
    exact
    path={Resources.responseFlowBeginFeedback.pattern}
    element={<ResponseFlowBeginFeedbackComponent />}
  />
);

/** Recurring Response Flow */
const recurringResponseFlowPageHoc = compose(
  withQueryParams,
  withRouteParams({
    invitationUuid: true,
  }),
  withStepPaths({
    nextStepPath: ({ invitationUuid }) =>
      Resources.responseFlowThankYou.path({
        invitationUuid,
      }),
    cantRateSelfPath: ({ invitationUuid }) =>
      Resources.responseFlowCantRateSelf.path({
        invitationUuid,
      }),
  }),
  withPageViewTracking({
    pageTitle: 'response-flow/recurring',
  })
);

const RecurringResponseFlowTimelineComponent = recurringResponseFlowPageHoc(RecurringResponseFlowPage);

export const RecurringResponseFlowTimeline = (
  <Route
    exact
    path={Resources.recurringResponseFlow.pattern}
    element={<RecurringResponseFlowTimelineComponent />}
  />
);

const responseFlowRequestVerificationHoc = compose(
  withRouteParams({
    invitationUuid: true,
  }),
  withStepPaths({
    phoneCodeNextStepPath: ({ invitationUuid }) =>
      Resources.responseFlowSubmitVerification.path({
        invitationUuid,
      }),
    fallbackPath: ({ invitationUuid }) =>
      Resources.responseFlowSetName.path({
        invitationUuid,
      }),
  }),
  withPageViewTracking({
    pageTitle: 'response-flow/request-verification',
  })
);
export const ResponseFlowRequestVerification = (
  <PrivateRoute
    exact
    path={Resources.responseFlowRequestVerification.pattern}
    component={responseFlowRequestVerificationHoc(
      ResponseFlowRequestVerificationPage
    )}
  />
);

const responseFlowSubmitVerificationCodeHoc = compose(
  withRouteParams({
    invitationUuid: true,
  }),
  withStepPaths({
    nextStepPath: ({ invitationUuid }) =>
      Resources.responseFlowBeginFeedback.path({
        invitationUuid,
      }),
    fallbackPath: ({ invitationUuid }) =>
      Resources.responseFlowRequestVerification.path({
        invitationUuid,
      }),
  }),
  withPageViewTracking({
    pageTitle: 'response-flow/submit-verification',
  })
);
export const ResponseFlowSubmitVerificationCode = (
  <PrivateRoute
    exact
    path={Resources.responseFlowSubmitVerification.pattern}
    component={responseFlowSubmitVerificationCodeHoc(
      ResponseFlowSubmitVerificationPage
    )}
  />
);

const responseEndFeedbackSharedHoc = compose(
  withRouteParams({
    invitationUuid: true,
  }),
  withStepPaths({
    nextStepPath: ({ invitationUuid }) =>
      Resources.responseFlowThankYou.path({
        invitationUuid,
      }),
    fallbackPath: ({ invitationUuid }) =>
      Resources.responseFlowRequestVerification.path({
        invitationUuid,
      }),
  })
);
const responseFlowEndFeedbackPageHoc = compose(
  responseEndFeedbackSharedHoc,
  withPageViewTracking({
    pageTitle: 'response-flow/end-feedback',
  })
);
const ResponseFlowEndFeedbackRoute = responseEndFeedbackSharedHoc(PrivateRoute);
export const ResponseFlowEndFeedback = (
  <ResponseFlowEndFeedbackRoute
    exact
    path={Resources.responseFlowEndFeedback.pattern}
    component={responseFlowEndFeedbackPageHoc(ResponseFlowEndFeedbackPage)}
    isRender={({ userStatus }) => userStatus.isLoggedIn}
    customRedirect={({ fallbackPath }) => fallbackPath}
  />
);

const responseFlowThankYouSharedHoc = compose(
  withRouteParams({
    invitationUuid: true,
  }),
  withStepPaths({
    fallbackPath: ({ invitationUuid }) =>
      Resources.authRequestVerification.path({
        invitationUuid,
      }),
  })
);
const responseFlowThankYouPageHoc = compose(
  responseFlowThankYouSharedHoc,
  withPageViewTracking({
    pageTitle: 'response-flow/thank-you',
  })
);

const ResponseFlowThankYouComponent = responseFlowThankYouPageHoc(ResponseFlowThankYouPage);

export const ResponseFlowThankYou = (
  <Route
    exact
    path={Resources.responseFlowThankYou.pattern}
    element={(
      <PrivateRoute
        isRender={({ userStatus }) => userStatus.isLoggedIn}
        customRedirect={({ fallbackPath }) => fallbackPath}
      >
        <ResponseFlowThankYouComponent />
      </PrivateRoute>    
    )}
  />
);

const responseFlowCantRateSelfSharedHoc = compose(
  withRouteParams({
    invitationUuid: true,
  }),
  withStepPaths({
    fallbackPath: ({ invitationUuid }) =>
      Resources.responseFlowRequestVerification.path({
        invitationUuid,
      }),
  })
);
const responseFlowCantRateSelfPageHoc = compose(
  responseFlowCantRateSelfSharedHoc,
  withPageViewTracking({
    pageTitle: 'response-flow/cant-rate-self',
  })
);

const ResponseFlowCantRateSelfComponent = responseFlowCantRateSelfPageHoc(ResponseFlowCantRateSelfPage);

export const ResponseFlowCantRateSelf = (
  <Route
    exact
    path={Resources.responseFlowCantRateSelf.pattern}
    element={(
      <PrivateRoute
        isRender={({ userStatus }) => userStatus.isLoggedIn}
        customRedirect={({ fallbackPath }) => fallbackPath}
      >
        <ResponseFlowCantRateSelfComponent />
      </PrivateRoute>    )
    }
  />
);

