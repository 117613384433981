import React, { useContext, useEffect } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';

function HomePage() {
  const { 
    currentWorkspaceId,
    currentUser,
    isLoadingCurrentUser
  } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoadingCurrentUser && !currentUser) {
      navigate(Resources.authSignout.path());
    }

    if (!isLoadingCurrentUser && currentUser && currentWorkspaceId) {
      navigate(Resources.workspacesActivityFeed.path({ workspaceId: currentWorkspaceId }));
    }
  }, [isLoadingCurrentUser, currentUser, currentWorkspaceId]);

  return null;
}

export const HomeMain = (
  <Route 
    exact 
    path={Resources.homeMain.pattern} 
    element={<HomePage />}
  />
);

export const Root = (
  <Route 
    path={Resources.appRoot.pattern} 
    exact 
    element={<Navigate to={Resources.homeMain.path()} />} 
  />
);
