import React from 'react';
import PropTypes from 'prop-types';
import StyledButton, { Loader } from './StyledButton';
import { buttonColors, buttonSizes, buttonTypes } from './props';

const ButtonBase = (props) => {
  const {
    buttonType,
    children,
    className,
    color,
    content,
    disabled,
    isLoading,
    onClick,
    size,
    ...otherProps
  } = props;
  const sharedProps = { buttonType, color, disabled, size };

  return (
    <StyledButton
      {...otherProps}
      {...sharedProps}
      className={className}
      disabled={disabled}
      isLoading={isLoading}
      onClick={onClick}
    >
      {content || children}
      {isLoading && <Loader {...sharedProps} />}
    </StyledButton>
  );
};



ButtonBase.propTypes = {
  /** Button type */
  buttonType: PropTypes.oneOf(Object.values(buttonTypes)),
  /** Button content. `content` will override this. */
  children: PropTypes.any,
  /** Additional class. */
  className: PropTypes.string,
  /** Hover color of button. */
  color: PropTypes.oneOf(Object.values(buttonColors)),
  /** Shortcut for children. Overrides children content. */
  content: PropTypes.any,
  /** If button is in loading state. */
  isLoading: PropTypes.bool,
  /** If button is disabled. */
  disabled: PropTypes.bool,
  /** Callback when button is clicked. */
  onClick: PropTypes.func,
  /** e2e Test data tag selector. */
  rcLabel: PropTypes.string,
  /** Size of the button. */
  size: PropTypes.oneOf(Object.values(buttonSizes)),
};

ButtonBase.defaultProps = {
  size: buttonSizes.M,
  buttonType: buttonTypes.NORMAL,
};

export default ButtonBase;
