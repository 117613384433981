import styled from 'styled-components';
import Button from '../../Button';
import { Clickable } from '../../Clickable/Clickable';
import Icon from '../../Icon';
import {
  colors,
  font,
  spacing,
  transitionTime,
} from '@matterapp/matter-theme';

const TRANSITION_TIME = transitionTime.default;

export const MenuTrigger = styled(Button.Icon).attrs(() => ({
  iconName: 'moreVertical',
  rcLabel: 'three-dots-menu',
}))`
  padding: 0;
  position: relative;
  width: ${spacing.singleAndHalf};
  height: ${spacing.singleAndHalf};
  &:not(:hover):not(:active) svg path {
    fill: ${colors.blacks[30]};
  }
`;

export const MenuItem = styled(Clickable)`
  color: ${colors.blacks[30]};
  display: flex;
  align-items: center;
  font-size: ${font.size.base};
  min-height: ${spacing.singleAndThreeQuarters};
  padding: ${spacing.quarter} ${spacing.half};
  border-radius: 2px;
  width: 100%;
  transition: all ${TRANSITION_TIME};

  & svg {
    margin-right: ${spacing.half};
    path {
      fill: ${colors.blacks[30]};
    }
  }

  &:hover {
    filter: opacity(100%);
    background: ${colors.blacks[70]};
  }

  &:active {
    color: ${colors.white};
    & svg {
      path {
        fill: ${colors.white};
      }
    }
  }
`;

export const MenuContents = styled.div`
  padding: ${spacing.half};
`;

export const DeleteIcon = styled(Icon).attrs(() => ({
  name: 'trashCan',
}))``;
