import React from 'react';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../../libs/omitStyled';
import { PANEL_BORDER_RADIUS, PANEL_MARGIN_BOTTOM } from '../consts';

const Container = omitStyled('div', ['bordered'])`
  margin-bottom: ${PANEL_MARGIN_BOTTOM};
  box-shadow: 0px 1px rgba(0, 0, 0, 0.08);

  margin: 0 0 24px 0;

  ${() => theme.media.medium`
    border-radius: ${PANEL_BORDER_RADIUS};

    > :first-child {
      border-top-left-radius: ${PANEL_BORDER_RADIUS};
      border-top-right-radius: ${PANEL_BORDER_RADIUS};
    }

    > :last-child {
      border: none;
      border-bottom-left-radius: ${PANEL_BORDER_RADIUS};
      border-bottom-right-radius: ${PANEL_BORDER_RADIUS};
    }
  `}

  > div {
    margin: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;

    ${({ bordered }) =>
    bordered &&
      `
      border-bottom: solid 1px ${theme.colors.purples[5]};
    `}

  }
`;

const Group = ({ children, bordered }) => (
  <Container bordered={bordered}>{children}</Container>
);

Group.defaultProps = {
  bordered: false,
};

Group.propTypes = {
  children: PropTypes.node.isRequired,
  bordered: PropTypes.bool,
};

export default Group;
