import React from 'react';
import Base from './Base';
import {
  TwoColumnContainer,
  TwoColumnContentContainer,
  TwoColumnContentContainerWrapper,
  TwoColumnFilterContainer,
  TwoColumnFilterWrapper,
  TwoColumnChildrenContainer,
} from './styles';

const LeftPanelOnly = ({
  children,
  className,
  containerNavigation,
  dockLeftColumn,
  header,
  leftColumn,
  hideLeftColumnOnMobile,
}) => (
  <Base className={className} header={header}>
    <TwoColumnContainer>
      <TwoColumnContentContainer>
        <TwoColumnContentContainerWrapper>
          <TwoColumnFilterContainer hideFilterColumn={hideLeftColumnOnMobile}>
            <TwoColumnFilterWrapper dock={dockLeftColumn}>
              {containerNavigation}
              {leftColumn}
            </TwoColumnFilterWrapper>
          </TwoColumnFilterContainer>
          <TwoColumnChildrenContainer>{children}</TwoColumnChildrenContainer>
        </TwoColumnContentContainerWrapper>
      </TwoColumnContentContainer>
    </TwoColumnContainer>
  </Base>
);

LeftPanelOnly.defaultProps = {
  dockLeftColumn: true,
};

export default LeftPanelOnly;
