"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ratingColors = void 0;

var _colors = require("../colors");

const ratingColors = {
  10: {
    primaryColor: _colors.colors.greens[50],
    backgroundColor: 'rgba(7, 187, 135, 0.2)',
    borderColor: 'rgba(7, 187, 135, 0.2)'
  },
  9: {
    primaryColor: '#39BC80',
    backgroundColor: 'rgba(57, 188, 128, 0.2)',
    borderColor: 'rgba(57, 188, 128, 0.2)'
  },
  8: {
    primaryColor: '#68BD78',
    backgroundColor: 'rgba(104, 189, 120, 0.2)',
    borderColor: 'rgba(104, 189, 120, 0.2)'
  },
  7: {
    primaryColor: '#99BE71',
    backgroundColor: 'rgba(153, 190, 113, 0.2)',
    borderColor: 'rgba(153, 190, 113, 0.2)'
  },
  6: {
    primaryColor: '#CCBF69',
    backgroundColor: 'rgba(204, 191, 105, 0.2)',
    borderColor: 'rgba(204, 191, 105, 0.2)'
  },
  5: {
    primaryColor: '#FFBF61',
    backgroundColor: 'rgba(255, 191, 97, 0.2);',
    borderColor: 'rgba(255, 191, 97, 0.2)'
  },
  4: {
    primaryColor: '#FFA766',
    backgroundColor: 'rgba(255, 167, 102, 0.2)',
    borderColor: 'rgba(255, 167, 102, 0.2)'
  },
  3: {
    primaryColor: '#FF8E6A',
    backgroundColor: 'rgba(255, 142, 106, 0.2)',
    borderColor: 'rgba(255, 142, 106, 0.2)'
  },
  2: {
    primaryColor: '#FF756F',
    backgroundColor: 'rgba(255, 117, 111, 0.2)',
    borderColor: 'rgba(255, 117, 111, 0.2)'
  },
  1: {
    primaryColor: '#FF5B74',
    backgroundColor: 'rgba(255, 91, 116, 0.2)',
    borderColor: 'rgba(255, 91, 116, 0.2)'
  },
  0: {
    primaryColor: '#FF4379',
    backgroundColor: 'rgba(255, 67, 121, 0.2)',
    borderColor: 'rgba(255, 67, 121, 0.2)'
  },
  default: {
    primaryColor: '#C6C6C7',
    backgroundColor: 'transparent',
    borderColor: '#E6E6E7'
  }
};
exports.ratingColors = ratingColors;
var _default = ratingColors;
exports.default = _default;