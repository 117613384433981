import React from 'react';
import styled from 'styled-components';
import Button from '../../../Button';

export const Primary = styled(Button.New).attrs({
  primary: true,
})`
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  font-size: 14px;
  text-align: center;
`;

export const Back = (props) => <Button.New icon='arrowLeft' link {...props} />;

export const Close = (props) => <Button.New icon='close' link {...props} />;

export const CloseText = styled(Button.Simple.Minimal).attrs(() => ({
  content: 'Close',
}))``;

export const Advance = (props) => (
  <Button.New icon='karotRight' link {...props} />
);

export default {
  Primary,
  Back,
  Close,
  CloseText,
  Advance,
};