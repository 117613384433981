import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Modal, Text, Loader } from '@matterapp/matter-ui';
import { DELETE_PANEL_TEXT } from '../sharedConsts';
import { useDeleteState } from './hooks';
import {
  DeleteNoteContainer,
  DeleteNoteWarningContainer,
  RedText,
  WorkspaceNameHeader,
} from './styles';
import { Toast, TOAST_TEXT } from 'routes/pages/workspaces/hooks/WorkspaceSettingsHooks/toasts';
import { useMutation } from '@apollo/client';
import { DELETE_WORKSPACE_MUTATION } from 'graphql-queries/queries';
import UserContext from 'context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import { useQueryParams } from 'hooks';

export default function DeleteChannel(props) {
  const { queryParams } = useQueryParams();
  const { token } = queryParams || {};
  const { currentTenant, currentUser, refetchCurrentUser, isLoadingCurrentUser } = useContext(UserContext);
  const { tenants: allUserTenants } = currentUser || {};
  const { workspaces, isEnterprise } = currentTenant || {};

  const navigate = useNavigate(); 
  const { workspace, inputTestId } = props;
  const [isDeletingWorkspace, setIsDeleting] = useState(false);
  const [mutation] = useMutation(DELETE_WORKSPACE_MUTATION);

  async function onClickDelete() {
    setIsDeleting(true);
    await mutation({
      variables: { 
        workspaceId: workspace.id, token }
    });
    setIsDeleting(false);

    if (isEnterprise) {
      if (workspaces.length === 1) {
        navigate(Resources.tenantZero.path({ tenantId: currentTenant.id }));
      } else {
        const otherWorkspace = workspaces.find(({ id }) => id !== workspace.id);

        navigate(Resources.workspacesActivityFeed.path({ workspaceId: otherWorkspace.id }));
      }
    } else if (allUserTenants.length > 1) {
      const otherTenant = allUserTenants.find(({ id, workspaces }) => id !== currentTenant.id && workspaces.length);

      if (otherTenant) {
        navigate(Resources.workspacesActivityFeed.path({ workspaceId: otherTenant.workspaces[0].id }));
      } else {
        navigate(Resources.authSignout.path({}, { notification: true }));
      }
    } else {
      navigate(Resources.authSignout.path({}, { notification: true }));
    }

    refetchCurrentUser();

    Toast.success(TOAST_TEXT.DELETE);
  };

  const {
    canClickDelete,
    handleChangeName,
    handleClickDelete,
    handleClickCancel,
    handleKeyPress,
    name
  } = useDeleteState({ ...props, onClickDelete });

  useEffect(() => {
    Toast.success(TOAST_TEXT.EMAIL_CONFIRMED);
  }, []);

  if (!currentTenant || isLoadingCurrentUser) {
    return <Loader />;
  }

  return (
    <Modal.Panel.Main
      dockFooter={false}
      header={DELETE_PANEL_TEXT.HEADER}
      footer={
        <Modal.Footer.WithCancelDelete
          canClickDelete={canClickDelete}
          deleteIsLoading={isDeletingWorkspace}
          deleteLabel={DELETE_PANEL_TEXT.DELETE_BUTTON}
          onClickCancel={handleClickCancel}
          onClickDelete={handleClickDelete}
        />
      }
    >
      <Form.Group
        header={
          <WorkspaceNameHeader>
            <Text.WithReplacement
              replaceText={workspace.name}
              text={DELETE_PANEL_TEXT.NAME_HEADER}
            />
          </WorkspaceNameHeader>
        }
        footer={
          <DeleteNoteContainer>
            <Text.WithReplacement
              replaceText={
                <RedText key={DELETE_PANEL_TEXT.DELETE_NOTE_LEADING_TEXT}>
                  {DELETE_PANEL_TEXT.DELETE_NOTE_LEADING_TEXT}
                </RedText>
              }
              text={DELETE_PANEL_TEXT.DELETE_NOTE}
            />
          </DeleteNoteContainer>
        }
      >
        <Input
          fluid
          rcLabel={inputTestId}
          onChange={isDeletingWorkspace ? null : handleChangeName}
          onKeyPress={isDeletingWorkspace ? null : handleKeyPress}
          placeholder={DELETE_PANEL_TEXT.NAME_PLACEHOLDER}
          value={name}
        />
      </Form.Group>
      <DeleteNoteWarningContainer>
        {DELETE_PANEL_TEXT.DELETE_NOTE_WARNING}
      </DeleteNoteWarningContainer>
    </Modal.Panel.Main>
  );
};
