import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        d="M23.033 5.988c.077-.687.243-1.098.537-1.098 2.86 0 3.948 6.888 4.293 9.986-2.162 1.399-3.546 3.308-4.234 4.522a1 1 0 1 0 1.742.984c.707-1.255 1.96-3.038 4.164-4.183 1.006-.349 1.959-.126 2.63 1.216 0 0-2.786 4.477-4.303 7.507-1.108 2.754-3.11 3.453-5.195 3.561a5.27 5.27 0 0 1-.51.016L22 28.5l.002-.006A5.263 5.263 0 0 1 17 23.25v-.349c1.383-.866 3.833-2.655 4.935-5.026.14-.265.295-.75.295-.75.17-.535.27-1.095.27-1.68 0-2.165-1.68-3.925-3.75-3.925A3.66 3.66 0 0 0 16 12.775a3.677 3.677 0 0 0-2.75-1.255c-2.07 0-3.75 1.76-3.75 3.925 0 .585.105 1.145.27 1.68.09.295.295.75.295.75 1.102 2.37 3.552 4.16 4.935 5.026v.349a5.263 5.263 0 0 1-5.002 5.244L10 28.5a11.634 11.634 0 0 1-.25 0c-.14 0-.28-.006-.417-.017-2.086-.108-4.088-.807-5.195-3.561-1.517-3.03-4.303-7.507-4.303-7.507.671-1.342 1.624-1.565 2.63-1.216 2.203 1.145 3.457 2.929 4.164 4.183a1 1 0 0 0 1.742-.984c-.688-1.214-2.072-3.123-4.234-4.522C4.482 11.778 5.57 4.89 8.43 4.89c.294 0 .46.411.537 1.098C9.358 4.805 9.857 4 10.49 4c.549 0 .971.54 1.297 1.407.246-.328.521-.517.828-.517 1.101 0 1.694 2.175 2.013 4.804.48.123.94.308 1.372.551a5.655 5.655 0 0 1 1.372-.551c.319-2.629.911-4.804 2.013-4.804.307 0 .582.189.828.517C20.538 4.54 20.961 4 21.51 4c.633 0 1.132.805 1.523 1.988z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
