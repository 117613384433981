import React from 'react';
import { useQueryParams } from 'hooks';
import { useMutation } from '@apollo/client';
import saveAttributionParams from 'graphql-queries/mutations/user/saveAttributionParams';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

// Whole purpose of this view is to capture params (ads, seo etc)
// save them to localStorage
// and redirect to Teams app store
export default function TeamsInstallRedirect() {
  const { queryParams } = useQueryParams();
  const [saveAttributionParamsMutation] = useMutation(saveAttributionParams);

  async function saveAndRedirect() {
    if (Object.keys(queryParams).length > 0) {
      try {
        localStorage.setItem(
          'matters-teams-attribution-params',
          JSON.stringify(queryParams)
        );
  
        await saveAttributionParamsMutation({
          variables: {
            workspaceId: null,
            source: 'teams',
            params: JSON.stringify(queryParams)
          }
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    window.location.replace('https://teams.microsoft.com/l/app/d718fbf7-be13-4b88-99ef-bbba4e726f7e');
  }

  useEffect(() => {
    saveAndRedirect();
  }, []);

  return (
    <>
    </>
  );
}
