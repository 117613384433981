import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Buttons from './Buttons';
import theme from '@matterapp/matter-theme';

const FooterContainer = styled.div`
  background: #ffffff;
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing.single};
  border-top: 1px solid ${theme.colors.blacks[10]};
  border-radius: 0 0 ${theme.sizes.borderRadius[80]} ${theme.sizes.borderRadius[80]};

  ${theme.media.S`
    padding: ${theme.spacing.threeQuarters} ${theme.spacing.singleAndHalf};
  `}
`;

const ChildrenContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

const LeftContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  display: flex;
`;

const Footer = ({ children, className, left, right }) => (
  <FooterContainer className={className}>
    <LeftContainer>{left}</LeftContainer>
    <ChildrenContainer>{children}</ChildrenContainer>
    <RightContainer>{right}</RightContainer>
  </FooterContainer>
);

/**
 * Footer variant to display buttons. Pass in a 'left' and 'right' button
 * and the container will automatically add a spacer between the buttons.
 * When passing only one button (either in 'left' or 'right'), button will
 * take full width of the footer.
 */
Footer.ButtonContainer = styled(Footer)`
  padding: ${theme.spacing.single};
  ${({ left, right }) => {
    if (left && right) {
      return `& ${ChildrenContainer} {
        min-width: ${theme.spacing.single};
        max-width: ${theme.spacing.single};
      }`;
    }
  }}
  ${({ left }) => {
    if (left) {
      return `& ${LeftContainer} {
        flex: 1 1 100%;

        & button {
          width: 100%;
        }
      }`;
    }
  }}
  ${({ right }) => {
    if (right) {
      return `& ${RightContainer} {
        flex: 1 1 100%;

        & button {
          width: 100%;
        }
      }`;
    }
  }}
  ${theme.media.S`
    padding: ${theme.spacing.singleAndHalf};
  `}
`;

Footer.Buttons = Buttons;

Footer.defaultProps = {
  left: null,
  right: null,
};

Footer.propTypes = {
  className: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
};

export default Footer;
