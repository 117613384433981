import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div``;

const PillContainer = styled.div`
  display: flex;
`;

const Pill = styled.div`
  ${() => `
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.S};
    flex: 1 1 auto;
    height: ${theme.spacing.singleAndHalf};
    margin: 0 2px;

    &:after {
      content: '';
      display: block;
      background: ${theme.colors.blues[60]};
      border-radius: ${theme.borderRadius.S};
      height: 100%;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: transform ${theme.transitions.times.default};
    }
  `}
  ${({ isSelected }) => isSelected && `
    &:after {
      transform: scaleY(1);
    }
  `}
`;

const Pills = (props) => {
  const { currentStep, totalSteps } = props;
  const pills = [];
  for (let i = 0; i < totalSteps; i++) {
    pills.push(<Pill key={`pill-${i}`} isSelected={i < currentStep } />);
  }
  return (
    <Container>
      <PillContainer>
        {pills}
      </PillContainer>
    </Container>
  );
};

Pills.propTypes = {
  /** The current step of the pill progress. */
  currentStep: PropTypes.number.isRequired,
  /** The total number of steps in the pill progress. */
  totalSteps: PropTypes.number.isRequired,
};

export default Pills;
