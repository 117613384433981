import React from 'react';
import BackgroundAnimation, { ANIMATIONS } from '../../BackgroundAnimation';
import ColoredBackground from '../../ColoredBackground';
import gsap from 'gsap';
import * as S from './styles';

export default class StartBackground extends BackgroundAnimation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  /**
   * Moves the clouds in.
   * @returns { Object } The animation timeline.
   */
  moveCloudsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, duration: 1 };
    tl.to(this.topCloudRef, { x: 130, y: 0 });
    tl.to(this.middleCloudRef, { x: -70, y: 0 });
    tl.to(this.bottomCloudRef, { x: 90, y: 0 });

    tl.to(this.topCloudRef, resetProps, '<');
    tl.to(this.middleCloudRef, resetProps, '<');
    tl.to(this.bottomCloudRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the clouds out.
   * @returns { Object } The animation timeline.
   */
  moveCloudsOut = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.to(this.topCloudRef, { x: 0, y: 0 });
    tl.to(this.middleCloudRef, { x: 0, y: 0 });
    tl.to(this.bottomCloudRef, { x: 0, y: 0 });

    tl.to(this.topCloudRef, { x: -80, y: -100, duration: 0.6 }, '<');
    tl.to(this.middleCloudRef, { x: 60, y: -50, duration: 0.6 }, '<');
    tl.to(this.bottomCloudRef, { x: -120, duration: 0.6 }, '<');
    return tl;
  };

  /**
   * Moves the dots in.
   * @returns { Object } The animation timeline.
   */
  moveDotsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 0.7 };
    const dotRefs = {
      leftDot1Ref: { x: -24, y: 10 },
      leftDot2Ref: { x: 32, y: -15 },
      leftDot3Ref: { x: 4, y: 15 },
      leftDot4Ref: { x: -14, y: 7 },
      leftDot5Ref: { x: 29, y: -38 },
      topDot1Ref: { x: 11, y: 68 },
      topDot2Ref: { x: -8, y: 57 },
      topDot3Ref: { x: -39, y: 29 },
      rightDot1Ref: { x: -65, y: -27 },
      rightDot2Ref: { x: 17, y: 33 },
      rightDot3Ref: { x: -9, y: -26 },
      rightDot4Ref: { x: -32, y: -26 },
      rightDot5Ref: { x: 15, y: -63 },
      topRightStarDotRef: { x: -37, y: 42 },
      bottomStarDotRef: { x: -7, y: -55 },
      bottomDot1Ref: { x: -83, y: -55 },
      bottomDot2Ref: { x: 16, y: -44 },
      bottomDot3Ref: { x: -24, y: -52 },
      bottomDot4Ref: { x: 47, y: 23 },
    };
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], dotRefs[key]);
    });
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], resetProps, '<');
    });
    return tl;
  };

  /**
   * Moves the planets in.
   * @returns { Object } The animation timeline.
   */
  movePlanetsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.to(this.planetTopRightRef, { x: 150, opacity: 0 });
    tl.to(this.planetRightRef, { x: 70, opacity: 0 });
    tl.to(this.planetLeftRef, { x: -90, opacity: 0 });

    tl.to(this.planetTopRightRef, { opacity: 1, x: 0, duration: 1 }, '<');
    tl.to(this.planetRightRef, { opacity: 1, x: 0, duration: 1 }, '<');
    tl.to(this.planetLeftRef, { opacity: 1, x: 0, duration: 1 }, '<');
    return tl;
  };

  /**
   * Moves the planets out.
   * @returns { Object } The animation timeline.
   */
  movePlanetsOut = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.to(this.planetTopRightRef, { opacity: 1, x: 0 });
    tl.to(this.planetRightRef, { opacity: 1, x: 0 });
    tl.to(this.planetLeftRef, { opacity: 1, x: 0 });

    tl.to(this.planetTopRightRef, { x: 150, opacity: 0, duration: 0.3 }, '<');
    tl.to(this.planetRightRef, { x: 70, opacity: 0, duration: 0.3 }, '<');
    tl.to(this.planetLeftRef, { x: -70, opacity: 0, duration: 0.3 }, '<');
    return tl;
  };

  /**
   * Moves the stars in.
   * @returns { Object } The animation timeline.
   */
  moveStarsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 0, opacity: 0, transformOrigin: 'center' };
    const zoomProps = { scale: 1.4, opacity: 1, duration: 0.3 };
    const normalizedProps = { scale: 1, duration: 0.1 };
    const starRefs = {
      topRightStarLeftRef: '<0.6',
      topRightStarRightRef: '<-0.4',
      bottomStarLeftRef: '<-0.3',
      bottomStarCenterRef: '<-0.2',
      bottomStarRightRef: '<-0.1',
    };
    Object.keys(starRefs).forEach((key) => {
      tl.to(this[key], initialProps);
    });
    Object.keys(starRefs).forEach((key) => {
      tl.to(this[key], zoomProps, starRefs[key]);
      tl.to(this[key], normalizedProps, '>');
    });
    return tl;
  };

  /**
   * Moves the rings in.
   * @returns { Object } The animation timeline.
   */
  moveRingsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 2, opacity: 0, transformOrigin: 'center' };
    const normalizedProps = { scale: 1, opacity: 1, duration: 0.4 };
    tl.to(this.ringBottomRightRef, initialProps);
    tl.to(this.ringTopRightRef, initialProps);
    tl.to(this.ringCenterRightRef, initialProps);

    tl.to(this.ringBottomRightRef, normalizedProps, '<');
    tl.to(this.ringTopRightRef, normalizedProps, '<0.2');
    tl.to(this.ringCenterRightRef, normalizedProps, '<0.1');
    return tl;
  };

  playAnimation = () => {
    const { animation } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    if (animation !== ANIMATIONS.SHOW) {
      if (animation === ANIMATIONS.OUT) {
        tl.add(this.moveCloudsOut(), '<');
        tl.add(this.movePlanetsOut(), '<');
      } else {
        tl.add(this.moveCloudsIn(), '<');
        tl.add(this.movePlanetsIn(), '<');
        tl.add(this.moveDotsIn(), '<0.3');
        tl.add(this.moveStarsIn(), '<-0.3');
        tl.add(this.moveRingsIn(), '<0.5');
      }
    }
    this.tl = tl;
    return tl;
  };

  render() {
    return (
      <ColoredBackground {...this.props}>
        <S.TopCloud ref={(ref) => (this.topCloudRef = ref)} />
        <S.MiddleCloud ref={(ref) => (this.middleCloudRef = ref)} />
        <S.BottomCloud ref={(ref) => (this.bottomCloudRef = ref)} />
        <S.LeftDotsGroup>
          <S.LeftDot1 ref={(ref) => (this.leftDot1Ref = ref)} />
          <S.LeftDot2 ref={(ref) => (this.leftDot2Ref = ref)} />
          <S.LeftDot3 ref={(ref) => (this.leftDot3Ref = ref)} />
          <S.LeftDot4 ref={(ref) => (this.leftDot4Ref = ref)} />
          <S.LeftDot5 ref={(ref) => (this.leftDot5Ref = ref)} />
        </S.LeftDotsGroup>
        <S.TopDotsGroup>
          <S.TopDot1 ref={(ref) => (this.topDot1Ref = ref)} />
          <S.TopDot2 ref={(ref) => (this.topDot2Ref = ref)} />
          <S.TopDot3 ref={(ref) => (this.topDot3Ref = ref)} />
        </S.TopDotsGroup>
        <S.RightDotsGroup>
          <S.RightDot1 ref={(ref) => (this.rightDot1Ref = ref)} />
          <S.RightDot2 ref={(ref) => (this.rightDot2Ref = ref)} />
          <S.RightDot3 ref={(ref) => (this.rightDot3Ref = ref)} />
          <S.RightDot4 ref={(ref) => (this.rightDot4Ref = ref)} />
          <S.RightDot5 ref={(ref) => (this.rightDot5Ref = ref)} />
        </S.RightDotsGroup>
        <S.BottomDotsGroup>
          <S.BottomDot1 ref={(ref) => (this.bottomDot1Ref = ref)} />
          <S.BottomDot2 ref={(ref) => (this.bottomDot2Ref = ref)} />
          <S.BottomDot3 ref={(ref) => (this.bottomDot3Ref = ref)} />
          <S.BottomDot4 ref={(ref) => (this.bottomDot4Ref = ref)} />
        </S.BottomDotsGroup>
        <S.TopRightStarsGroup>
          <S.TopRightStarLeft ref={(ref) => (this.topRightStarLeftRef = ref)} />
          <S.TopRightStarRight
            ref={(ref) => (this.topRightStarRightRef = ref)}
          />
          <S.TopRightStarDot ref={(ref) => (this.topRightStarDotRef = ref)} />
        </S.TopRightStarsGroup>
        <S.BottomStarsGroup>
          <S.BottomStarLeft ref={(ref) => (this.bottomStarLeftRef = ref)} />
          <S.BottomStarCenter ref={(ref) => (this.bottomStarCenterRef = ref)} />
          <S.BottomStarRight ref={(ref) => (this.bottomStarRightRef = ref)} />
          <S.BottomStarDot ref={(ref) => (this.bottomStarDotRef = ref)} />
        </S.BottomStarsGroup>
        <S.RingBottomRight ref={(ref) => (this.ringBottomRightRef = ref)} />
        <S.RingTopRight ref={(ref) => (this.ringTopRightRef = ref)} />
        <S.RingCenterRight ref={(ref) => (this.ringCenterRightRef = ref)} />
        <S.PlanetRight ref={(ref) => (this.planetRightRef = ref)} />
        <S.PlanetTopRight ref={(ref) => (this.planetTopRightRef = ref)} />
        <S.PlanetLeft ref={(ref) => (this.planetLeftRef = ref)} />
      </ColoredBackground>
    );
  }
}
