export const BASE = 'kudosThoughtful';

export const PLANT = `${BASE}-plant`;

export const LEAF = {
  LEFT: `${BASE}-leaf-left`,
  RIGHT: `${BASE}-leaf-right`,
};

export const TRIANGLE = {
  LEFT: `${BASE}-triangle-left`,
  CENTER: `${BASE}-triangle-center`,
  RIGHT: `${BASE}-triangle-right`,
};

export const WATER_PAIL = `${BASE}-water-pail`;
