import styled from 'styled-components';
import { ClickableLink } from '../../Clickable/Clickable';
import Icon from '../../Icon';
import Text from '../../Typography/TextWithReplacement';
import { border, colors, font, media, spacing, transitions } from '@matterapp/matter-theme';

export const MENU_MIN_WIDTH = 240;

export const HeaderContainer = styled.div`
  display: flex;
  padding: ${spacing.singleAndHalf};
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  font-size: ${font.size.S};
  font-weight: ${font.weight.medium};

  & > svg {
    margin-right: ${spacing.half};
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  padding: 0 ${spacing.singleAndHalf} ${spacing.singleAndHalf};

  & > * {
    flex: 1 1 auto;
  }
  & > *:not(:last-child) {
    margin-right: ${spacing.half};
  }
`;

export const ClearContainer = styled.div`
  ${({ show }) => `
    flex: 0 1 auto;
    opacity: ${show ? 1 : 0.5};
    pointer-events: ${show ? 'all' : 'none'};
    transition: all ${transitions.times.default};
  `}
`;

export const ClearButton = styled(ClickableLink)`
  color: ${colors.blacks[40]};
`;

export const LockIcon = styled(Icon).attrs(() => ({
  name: 'lock',
}))``;

export const UpgradeContainer = styled.div`
  align-items: center;
  background: ${colors.blues[40]};
  color: ${colors.white};
  display: flex;
  font-size: ${font.size.base};
  font-weight: ${font.weight.medium};
  justify-content: center;
  line-height: ${spacing.single};
  padding: ${({ showInPanel }) => showInPanel ? spacing.threeQuarters : spacing.single};
  text-align: center;

  & > svg {
    margin-right: ${spacing.half};
  }
  
  ${({ showInPanel }) => media.S`
    border-top-left-radius: ${border.radius.M};
    border-top-right-radius: ${border.radius.M};
    border-bottom-left-radius: ${showInPanel ? 0 : border.radius.M};
    border-bottom-right-radius: ${showInPanel ? 0 : border.radius.M};
  `}
`;

export const UpgradeLink = styled(ClickableLink)`
  color: ${colors.white};
  font-weight: ${font.weight.medium};
  text-decoration: underline;
`;

export { Text };
