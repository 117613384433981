export const BASE = 'kudosInclusive';

export const BACKGROUND = `${BASE}-background`;

export const HIGHLIGHT = `${BASE}-highlight`;

export const HAND = {
  TOP_LEFT: `${BASE}-hand-top-left`,
  TOP_RIGHT: `${BASE}-hand-top-right`,
  BOTTOM_LEFT: `${BASE}-hand-bottom-left`,
  BOTTOM_RIGHT: `${BASE}-hand-bottom-right`,
};

export const PUZZLE = {
  TOP_LEFT: `${BASE}-puzzle-top-left`,
  TOP_RIGHT: `${BASE}-puzzle-top-right`,
  BOTTOM_LEFT: `${BASE}-puzzle-bottom-left`,
  BOTTOM_RIGHT: `${BASE}-puzzle-bottom-right`,
};

export const CIRCLE = {
  TOP: `${BASE}-circle-top`,
  CENTER: `${BASE}-circle-center`,
  BOTTOM: `${BASE}-circle-bottom`,
};

export const TRIANGLE = {
  TOP: `${BASE}-triangle-top`,
  CENTER: `${BASE}-triangle-center`,
  BOTTOM: `${BASE}-triangle-bottom`,
};

export const PLUS = `${BASE}-plus`;
