import getPhotoUrlFromEmail from '../../libs/getPhotoUrlFromEmail';

export const peerStatus = {
  isAccepted: 'isAccepted', // swap out with fresh peer or dont render
  isAccepting: 'isAccepting', // briefly display a transition animation
  isRejecting: 'isRejecting', // briefly display a transition animation
  isRejected: 'isRejected', // swap out with fresh peer or dont render
  isSwappedOut: 'isSwappedOut', // dont consider for rendering
  isFresh: 'isFresh', // ready to replace any stale peers
};

const initializePeerMetaData = (peer) => {
  const nextPeer = { ...peer };
  return {
    peer: nextPeer,
    status: peerStatus.isFresh,
  };
};

const initialPool = {
  peerMetaTable: {},
  peersList: [],
};

export const registerToPool = (pool = initialPool, peerSuggestions) => {
  const {
    peerMetaTable,
    peersList,
  } = pool;
  const newPeersTable = { ...peerMetaTable };
  const newPeerSuggestionEmails = new Set();
  peerSuggestions.forEach((peer) => {
    newPeerSuggestionEmails.add(peer.email);
  });
  const newPeersList = peersList.filter(({ email }) => newPeerSuggestionEmails.has(email));

  peerSuggestions.forEach((peer) => {
    const existingPeerMetaData = newPeersTable[peer.email];
    if (existingPeerMetaData) { return; }
    const nextPeerMetaData = initializePeerMetaData(peer);
    newPeersTable[peer.email] = nextPeerMetaData;
    const photoUrl = peer.photoUrl || getPhotoUrlFromEmail({
      email: peer.email,
      size: 256,
    });
    newPeersList.push(peer);
    const img = new Image();
    img.src = photoUrl;
    img.onload = () => {
      console.log(`image pre-loaded: ${img.src}`); // eslint-disable-line
    };
  });
  const nextPool = {
    peerMetaTable: newPeersTable,
    peersList: newPeersList,
  };
  return nextPool;
};

export const setPeerStatus = ({
  pool,
  peer,
  status,
}) => {
  const {
    peerMetaTable,
    peersList,
  } = pool;

  const peerMetaData = peerMetaTable[peer.email];

  const newPeerMetaData = {
    ...peerMetaData,
    status,
  };

  const newPeerMetaTable = {
    ...peerMetaTable,
    [peer.email]: newPeerMetaData,
  };

  const newPool = {
    peerMetaTable: newPeerMetaTable,
    peersList,
  };

  return newPool;
};

export const swapOutExpiredPeers = ({
  pool,
  numberSuggestionsToShow,
}) => {
  const {
    peerMetaTable,
    peersList,
  } = pool;

  const newPeerMetaTable = {
    ...peerMetaTable,
  };

  const unReservedPeersList = numberSuggestionsToShow !== null ?
    peersList.slice(0, numberSuggestionsToShow) :
    peersList.slice(0);
  const reservedPeersList = numberSuggestionsToShow !== null ?
    peersList.slice(numberSuggestionsToShow) :
    [];

  const newPeersList = [];

  const pushPeer = (peer) => {
    const peerMetaData = newPeerMetaTable[peer.email];
    if (peerMetaData.status.isSwappedOut) { return; }
    newPeersList.push(peer);
  };

  unReservedPeersList.forEach((peer) => {
    const peerMetaData = newPeerMetaTable[peer.email];
    const newPeerMetaData = { ...peerMetaData };
    if (
      (peerMetaData.status === peerStatus.isAccepted) ||
      (peerMetaData.status === peerStatus.isRejected)
    ) {
      newPeerMetaData.status = peerStatus.isSwappedOut;
      const reservedPeer = reservedPeersList.shift();
      if (reservedPeer) {
        pushPeer(reservedPeer);
      }
    } else {
      pushPeer(peer);
    }
    newPeerMetaTable[peer.email] = newPeerMetaData;
  });

  reservedPeersList.forEach((peer) => {
    pushPeer(peer);
  });

  const newPool = {
    peerMetaTable: newPeerMetaTable,
    peersList: newPeersList,
  };

  return newPool;
};
