import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PageContent from '../../../PageContent';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  max-width: 936px;
  min-width: 375px;
  min-height: 500px;
  margin-bottom: ${theme.spacing.singleAndHalf};
  margin-left: auto;
  margin-right: auto;
  display: flex;

  ${theme.media.S`
    padding-right: 36px;
    padding-left: ${theme.spacing.singleAndHalf};
    margin-top: ${theme.spacing.quad};
    margin-bottom: 164px;
  `}
`;

const FilterColumn = styled.div`
  display: none;
  ${theme.media.S`
    display: block;
    min-width: 224px;
    margin-bottom: 0;
  `}
`;

const ChildrenBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  ${theme.media.S`
    padding-left: ${theme.spacing.double};
  `}
`;

const TwoColumnFixedLeftV2 = ({
  children,
  leftColumn,
  header,
  footer,
}) => (
  <React.Fragment>
    {header}
    <PageContent>
      <Container>
        <FilterColumn>{leftColumn}</FilterColumn>
        <ChildrenBox>{children}</ChildrenBox>
      </Container>
    </PageContent>

    {footer}
  </React.Fragment>
);

TwoColumnFixedLeftV2.propTypes = {
  leftColumn: PropTypes.node,
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
};

TwoColumnFixedLeftV2.defaultProps = {
  leftColumn: null,
  children: null,
  header: null,
  footer: null,
};

export default TwoColumnFixedLeftV2;
