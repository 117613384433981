import React from 'react';
import { SVG, Path } from './consts';

const ActivityFeed = (props) => (
  <SVG {...props}>
    <Path
      d={`M81,34.6V83c0,2.7-2.2,4.9-4.9,4.9H34.6c-2.7,0-4.9-2.2-4.9-4.9V34.6c0-2.7,2.2-4.9,4.9-4.9h41.5
          C78.8,29.7,81,31.9,81,34.6z M76.1,98.8H34.6c-2.7,0-4.9,2.2-4.9,4.9v27.7c0,2.7,2.2,4.9,4.9,4.9h41.5c2.7,0,4.9-2.2,4.9-4.9v-27.7
          C81,101,78.8,98.8,76.1,98.8z M143.2,34.6c0-2.7-2.2-4.9-4.9-4.9H96.8c-2.7,0-4.9,2.2-4.9,4.9v20.8c0,2.7,2.2,4.9,4.9,4.9h41.5
          c2.7,0,4.9-2.2,4.9-4.9V34.6z M143.2,76.1c0-2.7-2.2-4.9-4.9-4.9H96.8c-2.7,0-4.9,2.2-4.9,4.9v55.3c0,2.7,2.2,4.9,4.9,4.9h41.5
          c2.7,0,4.9-2.2,4.9-4.9V76.1z`}
    />
  </SVG>
);

export default ActivityFeed;
