import { graphql } from '@apollo/client/react/hoc';
import { SET_WORK_RELATIONSHIP_WITH_PEER_MUTATION } from 'graphql-queries/queries';

const withSetWorkRelationshipWithPeer = graphql(
  SET_WORK_RELATIONSHIP_WITH_PEER_MUTATION,
  {
    name: 'setWorkRelationshipWithPeerMutation',
  }
);

export default withSetWorkRelationshipWithPeer;
