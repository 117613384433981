import React from 'react';
import { compose, withProps } from 'recompose';
import { Resources } from '@matterapp/routing';
import withRouter from 'hocs/withRouter';
import { graphql } from '@apollo/client/react/hoc';
import FeedbackFlowPage from '../components/FeedbackFlowPage';
import {
  FEEDBACK_FLOW_QUERY,
  MARK_FEEDBACKS_AS_VIEWED_MUTATION,
  CREATE_OR_OVERWRITE_RECURRING_FEEDBACK_MUTATION,
  FIND_OR_CREATE_FEEDBACK_TEMPLATE_MUTATION
} from 'graphql-queries/queries';
import { withNumInvited } from 'hocs';
import Toast from 'components/Toast/Toast';
import viewFeedbackParam from 'helpers/viewFeedbackParam';
import { DEFAULT_REQUEST_MESSAGE } from 'copy-consts';

/**
 * Gets data for the response flow timeline and generates the
 * `initializeData` object to pass into redux for the timeline.
 */
const withTotalFeedbackCounts = graphql(FEEDBACK_FLOW_QUERY, {
  options: (props) => {
    const ids = viewFeedbackParam.decode(props.feedbackReceivedIdEncoded);
    return ({
      variables: {
        feedItemIds: ids,
      },
    });
  },
  props: ({ data }) => {
    const {
      loading,
      error,
      newFeedbacksReceived,
      totalFeedbacksReceived,
    } = data;
    if (loading || error || !newFeedbacksReceived) {
      return {};
    }

    return {
      feedbackList: newFeedbacksReceived,
      totalFeedbacksReceived,
      data,
    };
  },
});

/**
 * Defines the `markFeedbackCommentsAsViewed` mutation function.
 * @param { Object } markFeedbackVariables: {
 *   @param { String } feedbackReceivedId: The id of the feedback.
 * }
 * @returns { void }
 */
const withMarkFeedbacksAsViewed = graphql(MARK_FEEDBACKS_AS_VIEWED_MUTATION, {
  props: ({ mutate, ownProps: { mutations = {} } }) => ({
    mutations: {
      ...mutations,
      markFeedbackAsViewed: async (viewedFeedItemId) => {
        await mutate({
          variables: {
            ids: [viewedFeedItemId],
          },
        });
      },
    },
  }),
});

const withData = compose(
  withRouter,
  withMarkFeedbacksAsViewed,
  withTotalFeedbackCounts,
  withNumInvited,
  graphql(CREATE_OR_OVERWRITE_RECURRING_FEEDBACK_MUTATION, {
    name: 'createOrOverwriteRecurringFeedbackMutation',
  }),
  graphql(FIND_OR_CREATE_FEEDBACK_TEMPLATE_MUTATION, {
    name: 'findOrCreateFeedbackTemplate',
  }),
  withProps(({ createOrOverwriteRecurringFeedbackMutation, findOrCreateFeedbackTemplate, requestFeedbackFromMultiplePeers }) => ({
    handleConfirmRecurring: async ({
      personId,
      message,
      day,
      frequency,
      skillIds,
    }) => {
      try {
        const recurringProps = {
          personId,
          recurrence: { day, frequency }, 
          skillIds,
          sendImmediately: false,
        };
        if (message && message !== DEFAULT_REQUEST_MESSAGE) {
          recurringProps.customNote = message;
        }
        await createOrOverwriteRecurringFeedbackMutation({ 
          variables: recurringProps
        });
      } catch (e) {
        Toast.error('Something went wrong. Please try again later on your Continuous page');
        console.error('Make recurring error: ', e);
      }
    },
    handleSendFeedbackRequest: async ({peerRecommendationsEmailList, selectedSkillsIds}) => {
      try {
        const { data: templateData } = await findOrCreateFeedbackTemplate({
          variables: {
            skillIds: selectedSkillsIds
          },
        });
        const { id: feedbackTemplateId } = templateData.findOrCreateFeedbackTemplate;
        const peers = [];
        peerRecommendationsEmailList.forEach(email => {
          peers.push({email});
        });
        const inviteProps = {};
        inviteProps.peers = peers;
        inviteProps.skillIds = selectedSkillsIds;
        inviteProps.feedbackTemplateId = feedbackTemplateId;
        inviteProps.customNote = DEFAULT_REQUEST_MESSAGE;
        inviteProps.source = 'feedbackFlowPage';
        await requestFeedbackFromMultiplePeers(inviteProps);
        const message = peerRecommendationsEmailList.length > 1 ? "Your feedback requests have been sent" : "Your feedback request has been sent";
        Toast.success(message);
      } catch (e) {
        const errorMessage = (<span><strong>Oops! </strong>Something went wrong. Your request could not be sent</span>);
        Toast.error(errorMessage);
        console.error('error', e);
      }
    },
  })),
  withProps((ownProps) => ({
    handleError: () => {
      setTimeout(() => {
        Toast.error('Oops! We could not find that feedback, it may have been deleted');
      }, 200);
      ownProps.navigate(Resources.homeMain.path());
    },
    onReturnHome: async () => {
      ownProps.navigate(Resources.homeMain.path());
    },
    initialValues: {
      emails: Array(3).fill(''),
    },
  })),
);

const FeedbackReceivedPageContainer = withData((props) => {
  const pageProps = {...props };
  return <FeedbackFlowPage {...pageProps} />;
});

export default FeedbackReceivedPageContainer;
