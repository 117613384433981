import { isEqual } from 'lodash';

export function getInitialState({ 
  isPulseActive,
  isPulseEnabled,
  frequency,
  questionCount = 3,
  eNpsFrequency,
  eNpsEnabled,
  sendReminders = true,
  adminNotifications: {
    newFeedback,
    digest
  },
  notifyMembers,
  coinsPerSurvey,
  rewardsActive,
  excludeMembers,
  launchSurveysInstantly,
  sendToAll = true,
  recipients
}) {
  const initialSettings = {
    isPulseActive,
    isPulseEnabled,
    frequency,
    eNpsFrequency,
    eNpsEnabled,
    rewardsActive,
    adminNotifications: {
      newFeedback,
      digest
    },
    coinsError: false,
    questionCount,
    notifyMembers,
    coinsPerSurvey: parseInt(coinsPerSurvey, 10),
    excludeMembers: excludeMembers || [],
    launchSurveysInstantly,
    sendToAll,
    recipients: (recipients || []).map((recipient) => ({ label: null, value: recipient })),
    sendReminders
  };

  return {
    canSave: false,
    settings: { ...initialSettings },
    initialSettings
  };
}

export const ACTIONS = {
  FREQUENCY_CHANGE: 'FREQUENCY_CHANGE',
  QUESTION_COUNT_CHANGE: 'QUESTION_COUNT_CHANGE',
  TOGGLE_ENPS: 'TOGGLE_ENPS',
  ENPS_FREQUENCY_CHANGE: 'ENPS_FREQUENCY_CHANGE',
  RESET_INITIAL_SETTINGS: 'RESET_INITIAL_SETTINGS',
  TOGGLE_NEW_FEEDBACK: 'TOGGLE_NEW_FEEDBACK',
  TOGGLE_DIGEST: 'TOGGLE_DIGEST',
  RESET_COINS: 'RESET_COINS',
  COINS_PER_SURVEY_CHANGE: 'COINS_PER_SURVEY_CHANGE',
  TOGGLE_LAUNCH_INSTANTLY: 'TOGGLE_LAUNCH_INSTANTLY',
  LAUNCH_PULSE: 'LAUNCH_PULSE',
  TOGGLE_PULSE: 'TOGGLE_PULSE',
  TOGGLE_AUDIENCE: 'TOGGLE_AUDIENCE',
  ADD_RECIPIENT: 'ADD_RECIPIENT',
  REMOVE_RECIPIENT: 'REMOVE_RECIPIENT',
  TOGGLE_REWARDS: 'TOGGLE_REWARDS',
  TOGGLE_REMINDERS: 'TOGGLE_REMINDERS'
};

function canSave(settings, initialSettings) {
  return !isEqual(settings, initialSettings) && !settings.coinsError;
}

export function getSettings(state) {
  const {
    isPulseActive,
    isPulseEnabled,
    frequency,
    questionCount,
    eNpsFrequency,
    eNpsEnabled,
    adminNotifications,
    notifyMembers,
    coinsPerSurvey,
    excludeMembers,
    launchSurveysInstantly,
    sendToAll,
    recipients,
    rewardsActive,
    sendReminders
  } = state.settings;

  return {
    isPulseActive,
    isPulseEnabled,
    frequency,
    questionCount,
    eNpsFrequency,
    eNpsEnabled,
    adminNotifications,
    notifyMembers,
    coinsPerSurvey,
    launchSurveysInstantly,
    sendToAll,
    recipients: sendToAll ? [] : recipients.map(({ value }) => value),
    excludeMembers: sendToAll ? excludeMembers : [],
    rewardsActive,
    sendReminders
  };
}

export function pulseReducer(state, action) {
  switch (action.type) {
  case ACTIONS.FREQUENCY_CHANGE: {
    const newSettings = {
      ...state.settings,
      frequency: action.payload
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.LAUNCH_PULSE: {
    const newSettings = {
      ...state.settings,
      isPulseActive: true
    };

    return {
      ...state,
      settings: newSettings
    };
  }
  case ACTIONS.TOGGLE_ENPS: {
    const newSettings = { 
      ...state.settings,
      eNpsEnabled: !state.settings.eNpsEnabled
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.ENPS_FREQUENCY_CHANGE: {
    const newSettings = {
      ...state.settings,
      eNpsFrequency: action.payload
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.TOGGLE_NEW_FEEDBACK: {
    const newSettings = { 
      ...state.settings,
      adminNotifications: {
        newFeedback: !state.settings.adminNotifications.newFeedback,
        digest: state.settings.adminNotifications.digest
      }
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.TOGGLE_DIGEST: {
    const newSettings = { 
      ...state.settings,
      adminNotifications: {
        newFeedback: state.settings.adminNotifications.newFeedback,
        digest: !state.settings.adminNotifications.digest
      }
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.TOGGLE_LAUNCH_INSTANTLY: {
    const newSettings = { 
      ...state.settings,
      launchSurveysInstantly: !state.settings.launchSurveysInstantly
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.RESET_COINS: {
    const newSettings = { 
      ...state.settings,
      coinsPerSurvey: 20,
      coinsError: false
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.COINS_PER_SURVEY_CHANGE: {
    const coinsError = action.payload > 100000 || action.payload < 0;

    const newSettings = { 
      ...state.settings,
      coinsPerSurvey: parseInt(action.payload),
      coinsError
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }
  case ACTIONS.RESET_INITIAL_SETTINGS: {
    return getInitialState(action.payload);
  }
  case ACTIONS.TOGGLE_PULSE: {
    const newSettings = { 
      ...state.settings,
      isPulseEnabled: !state.settings.isPulseEnabled
    };

    return {
      ...state,
      settings: newSettings
    };
  }
  case ACTIONS.QUESTION_COUNT_CHANGE: {
    const newSettings = { 
      ...state.settings,
      questionCount: action.payload
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }

  case ACTIONS.TOGGLE_AUDIENCE: {
    const newSettings = { 
      ...state.settings,
      sendToAll: !state.settings.sendToAll
    };

    const audienceIsComplete = newSettings.sendToAll || newSettings.recipients.length > 0;

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings) && audienceIsComplete,
      settings: newSettings
    };
  }

  case ACTIONS.ADD_RECIPIENT: {
    const newRecipients = [...state.settings.recipients, action.payload];
    const newSettings = { 
      ...state.settings,
      recipients: newRecipients
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }

  case ACTIONS.REMOVE_RECIPIENT: {
    const newRecipients = state.settings.recipients.filter(({ value }) => value !== action.payload.value);
    const newSettings = { 
      ...state.settings,
      recipients: newRecipients
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings) && newRecipients.length > 0,
      settings: newSettings
    };
  }

  case ACTIONS.TOGGLE_REWARDS: {
    const newSettings = { 
      ...state.settings,
      rewardsActive: !state.settings.rewardsActive
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }

  case ACTIONS.TOGGLE_REMINDERS: {
    const newSettings = { 
      ...state.settings,
      sendReminders: !state.settings.sendReminders
    };

    return {
      ...state,
      canSave: canSave(newSettings, state.initialSettings),
      settings: newSettings
    };
  }

  default: {
    return {};
  }
  }
}