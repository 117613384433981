import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { skillEntityPropType } from '@matterapp/skills';
import { ButtonNew } from '../../Button';
import ListItem from './ListItem';
import { ListHeading } from '../../Heading';
import Empty from './Empty';
import AddSkillButton from './AddSkillButton';
import theme from '@matterapp/matter-theme';

const SkillsListContainer = styled.div`
  width: 100%;
  padding: 0;

  ${() => theme.media.large`
    padding: 0;
  `}
`;

const ContentContainer = styled.div`
  margin-bottom: 8px;
`;

const ListItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .matterapp-picked-skills-list-item + .matterapp-picked-skills-list-item {
    margin-top: 8px;
  }
`;

const AddButton = styled(ButtonNew).attrs({
  link: true,
  icon: 'addv2',
})`
  color: ${() => theme.colors.purples[50]};
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

class PickedSkillsList extends React.Component {
  state = {
    skills: [],
    isFirstSkillsRendered: false,
  };

  static Empty = Empty;

  componentDidMount() {
    this.handleUpdate({ nextSkills: this.props.skills });
  }

  componentDidUpdate(prevProps) {
    if (this.props.skills.length !== prevProps.skills.length) {
      this.handleUpdate({ nextSkills: this.props.skills });
    }
  }

  handleUpdate = ({ nextSkills }) => {
    this.setState(
      {
        skills: nextSkills,
      },
      () => {
        this.setState((curState) => ({
          isFirstSkillsRendered:
            curState.isFirstSkillsRendered || nextSkills.length > 0,
        }));
      }
    );
  };

  renderListItems = () => {
    return (
      <ListItemsContainer>
        {this.state.skills.map((skill, i) => (
          <ListItem
            key={skill.id}
            skill={skill}
            animateIn={this.state.isFirstSkillsRendered}
            rcLabel={`list-item-${i}`}
          />
        ))}
      </ListItemsContainer>
    );
  };

  renderEmptyState = () => <Empty />;

  renderHeaderAddSkillButton = () => {
    const { handleClickAddSkill } = this.props;
    return <AddButton onClick={handleClickAddSkill} />;
  };

  renderHeader = () => (
    <ListHeading>
      <span>Your Skills</span>
      <ButtonBox>{this.renderHeaderAddSkillButton()}</ButtonBox>
    </ListHeading>
  );

  renderContent = () => {
    const { skills } = this.state;
    return (
      <ContentContainer>
        {skills.length > 0 ? this.renderListItems() : this.renderEmptyState()}
      </ContentContainer>
    );
  };

  renderAddSkillButton = () => {
    const { handleClickAddSkill } = this.props;
    return <AddSkillButton onClick={handleClickAddSkill} />;
  };

  render = () => (
    <SkillsListContainer data-rc="matterapp-picked-skills-list">
      {this.renderHeader()}
      {this.renderContent()}
      {this.renderAddSkillButton()}
    </SkillsListContainer>
  );
}

PickedSkillsList.ListItem = ListItem;

PickedSkillsList.propTypes = {
  skills: PropTypes.arrayOf(skillEntityPropType).isRequired,
  handleClickAddSkill: PropTypes.func.isRequired,
};

export default PickedSkillsList;
