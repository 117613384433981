import { compose, withProps } from 'recompose';
import * as qs from 'qs';
import withRouter from 'hocs/withRouter';

const withData = compose(
  withRouter,
  withProps(({ history, location }) => ({
    queryParams: (() => {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      return params;
    })(),
    setQueryParams: (queryParams, hash, replaceState = false) => {
      const params = qs.stringify(queryParams);
      const action = replaceState ? 'replace' : 'push';
      if (hash) {
        history[action](`?${params}#${hash}`);
      } else {
        history[action](`?${params}`);
      }
    },
  }))
);

export default withData;
