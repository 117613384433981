import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SimpleConfirmContainer from './SimpleConfirmContainer';
import Modal from '../Modal/Modal';
import Button from '../Button';
import theme from '@matterapp/matter-theme';

const StyledModal = styled(Modal)`
  .matter-ReactModal__Content {
    border-radius: ${() => theme.borderRadius.M};
  }
  .matter-ReactModal__Overlay {
    z-index: 100;
  }
`;

const SecondaryButton = styled(Button.Simple.Minimal)`
  flex: 1 1 100%;
`;

const PrimaryButton = styled(Button.Simple.Primary)`
  flex: 1 1 100%;

  ${SecondaryButton} + & {
    margin-left: ${theme.spacing.single};
  }
`;

class SimpleConfirmModal extends React.Component {
  static PrimaryButton = PrimaryButton;
  static SecondaryButton = SecondaryButton;

  renderContent = () => {
    const {
      heading,
      subheading,
      form,
      buttonRight,
      buttonLeft,
    } = this.props;

    return (
      <SimpleConfirmContainer
        heading={heading}
        subheading={subheading}
        form={form}
        buttonRight={buttonRight}
        buttonLeft={buttonLeft}
      />
    );
  };

  render() {
    const { isOpen, onCancel } = this.props;
    return (
      <StyledModal
        openLowerFromTop
        isOpen={isOpen}
        onClose={onCancel}
        transition={Modal.transitions.FADE}
      >
        {this.renderContent()}
      </StyledModal>
    );
  }
}

SimpleConfirmModal.propTypes = {
  buttonLeft: PropTypes.node,
  buttonRight: PropTypes.node,
  form: PropTypes.node,
  heading: PropTypes.string,
  subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default SimpleConfirmModal;
