import styled from 'styled-components';
import { Button as MatterButton } from '@matterapp/matter-ui';
import { 
  Box,
  Paper
} from '@mui/material';

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexDirection: 'row', 
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '60%'
  }
}));

export const SettingsButton = styled(MatterButton.Simple.Primary.Vivid)(({ theme }) => ({
  marginRight: theme.spacing(2),
  textWrap: 'nowrap',
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(2)
  }
}));

export const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    borderRadius: 0
  }
}));

export const BalanceSectionWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex', 
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 0,
    '> button': {
      marginTop: theme.spacing(3)
    }
  }
}));
