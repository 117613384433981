import React from 'react';
import styled from 'styled-components';
import Button from '../../Button';
import SimpleConfirmModal from '../../Confirm/SimpleConfirmModal';
import theme from '@matterapp/matter-theme';

const ConfirmButton = styled(Button.Simple.Primary).attrs(() => ({
  size: 'M',
  color: 'blue'
}))`
  flex: 1 1 100%;
  padding: ${() => theme.spacing.half} 0;
  margin-left: ${() => theme.spacing.half};
`;

const CancelButton = styled(Button.Simple).attrs(() => ({
  size: 'M',
  color: 'black'
}))`
  flex: 1 1 100%;
  padding: ${() => theme.spacing.half} 0;
  margin-right: ${() => theme.spacing.half};
`;

const ConfirmResetModal = ({
  isOpen,
  onCancel,
  onConfirm
}) => (
  <SimpleConfirmModal
    isOpen={isOpen}
    onCancel={onCancel}
    onRequestClose={onCancel}
    heading="Discard Changes?"
    subheading="You can't undo this and you will lose your draft."
    buttonLeft={
      <CancelButton onClick={onCancel}>
        Cancel
      </CancelButton>
    }
    buttonRight={
      <ConfirmButton onClick={onConfirm}>
        Yes, discard
      </ConfirmButton>
    }
  />
);

export default ConfirmResetModal;
