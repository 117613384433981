import { compose, withProps } from 'recompose';
import { Resources } from '@matterapp/routing';
import PropTypes from 'prop-types';
import withRouter from 'hocs/withRouter';
import withInvitationOwnerFromUuid from '../hocs/withInvitationOwnerFromUuid';
import ResponseFlowCantRateSelfPage from '../components/ResponseFlowCantRateSelfPage';

const withData = compose(
  withRouter,
  withInvitationOwnerFromUuid,
  withProps(({ history }) => ({
    handleReturnToMatter: () => {
      history.push(Resources.homeMain.path());
    },
  }))
);

const ResponseFlowCantRateSelfPageContainer = withData(
  ResponseFlowCantRateSelfPage
);

ResponseFlowCantRateSelfPageContainer.propTypes = {
  invitationUuid: PropTypes.string.isRequired,
};

export default ResponseFlowCantRateSelfPageContainer;
