import { graphql } from '@apollo/client/react/hoc';
import { SYNC_PEER_RECOMMENDATIONS_MUTATION } from 'graphql-queries/queries';

const withSyncPeerRecommendations = graphql(
  SYNC_PEER_RECOMMENDATIONS_MUTATION,
  {
    name: 'syncPeerRecommendationsMutation',
  }
);

export default withSyncPeerRecommendations;
