import React from 'react';
import PropTypes from 'prop-types';
import { peerRecommendationSourceCopy } from '@matterapp/peer-recommendations';
import List from '../../List';
import Avatar from '../../Avatar';

const avatarSize = 48;
const subHeaderOptions = {
  NONE: 'none',
  EMAIL: 'email',
  SOURCE: 'source',
};

export default class PeerListItem extends React.PureComponent {
  static propTypes = {
    /** The content of the actions container of the list item. */
    actions: PropTypes.node,
    /** Additional style class. */
    className: PropTypes.string,
    /** Additional children components. */
    children: PropTypes.node,
    /** Additional children components (shortcut). If children and content are passed, content is displayed before children. */
    content: PropTypes.node,
    /** When the user has no avatar image, generate default avatar of use stock avatar image (leaf). */
    defaultAvatarIsGenerated: PropTypes.bool,
    /** Default sub header content to show. */
    defaultSubHeaderContent: PropTypes.oneOf(Object.values(subHeaderOptions)),
    /**
     * Callback when the list item is clicked.
     * @param { Object } e: The click event.
     * @param { Object } itemProps: The props of the item.
     */
    onClick: PropTypes.func,
    /** If the item is highlighted. */
    isHighlighted: PropTypes.bool,
    /** If the contents of the item are stacked on mobile screens. */
    isStackedOnMobile: PropTypes.bool,
    /** Callback function to render the header. */
    renderHeader: PropTypes.func,
    /** Callback function to render the profile card of the list item when hovered. */
    renderProfileCard: PropTypes.func,
    /** SubHeader content. Overrides peer source. */
    subHeader: PropTypes.node,
    /** Callback if avatar is clicked. */
    onClickAvatar: PropTypes.func,
    /** The peer definition object. */
    peer: PropTypes.shape({
      /** The email of the peer. */
      email: PropTypes.string.isRequired,
      /** The full name of the peer. */
      fullName: PropTypes.string,
      /** The url of the photo to display. */
      photoUrl: PropTypes.string,
      /** The source of the peer suggestion. */
      source: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    defaultSubHeaderContent: subHeaderOptions.SOURCE,
    onClick: null,
    isStackedOnMobile: false,
    highlighted: false,
  };

  static subHeaderOptions = subHeaderOptions;

  /**
   * Determines if the item can be clicked when the `onClick` prop is passed in as a function.
   * @return { Boolean } The item is clickable.
   */
  canClickItem = () => typeof this.props.onClick === 'function';

  /**
   * Callback when a list item is clicked. Passes back the event click and the props of the item.
   * @param { Object } e: The click event.
   * @returns { void }
   */
  handleClick = (e) => {
    if (this.canClickItem) {
      this.props.onClick(e, this.props);
    }
  };

  /**
   * Renders the avatar of the list item.
   * @returns { React.Component } The rendered avatar.
   */
  renderAvatar = () => {
    const { defaultAvatarIsGenerated, peer, squared, avatarHeight, avatarWidth, customAvatar } = this.props;
    const { email, photoUrl } = peer;
    const anchor = (
      <Avatar
        defaultAvatarIsGenerated={defaultAvatarIsGenerated}
        email={email}
        size={avatarSize}
        height={avatarHeight}
        width={avatarWidth}
        url={photoUrl}
        squared={squared}
        customAvatar={customAvatar}
      />
    );
    return this.renderProfileCard({ anchor, person: peer });
  };

  renderHeader = ({ anchor, person }) => {
    const { renderHeader } = this.props;
    const header = this.renderProfileCard({ anchor, person });
    if (typeof renderHeader === 'function') {
      return renderHeader(header);
    }
    return header;
  };

  /**
   * Callback to render the profile card when list item is hovered.
   * If `renderProfileCard` is not passed in, the card will not get rendered.
   * @param { Object } renderProps: Object containing the anchor component and peer of the list item.
   * @returns { React.Component } The profile card, or anchor component.
   */
  renderProfileCard = ({ anchor, person }) => {
    const { renderProfileCard } = this.props;
    if (typeof renderProfileCard === 'function') {
      return renderProfileCard({ anchor, person });
    }
    return anchor;
  };

  renderSubHeaderContent = () => {
    const {
      defaultSubHeaderContent,
      peer,
      subHeader,
    } = this.props;
    const { email, fullName, source } = peer;

    if (defaultSubHeaderContent === subHeaderOptions.NONE) {
      return null;
    }
    if (defaultSubHeaderContent === subHeaderOptions.EMAIL) {
      return fullName ? email : subHeader;
    }
    if (subHeader) {
      return subHeader;
    }
    if (source) {
      return peerRecommendationSourceCopy[source] || subHeader;
    }
    return subHeader;
  }

  render() {
    const {
      actions,
      children,
      className,
      content,
      isHighlighted,
      isStackedOnMobile,
      onClick,
      onClickAvatar,
      peer,
      avatarHeight,
      avatarWidth,
      ...otherProps
    } = this.props;
    const { email, fullName } = peer;
    const canClickItem = this.canClickItem();

    const header = this.renderHeader({
      anchor: fullName || email,
      person: peer,
    });

    return (
      <List.ItemWithAvatar
        {...otherProps}
        peer={peer}
        actions={actions}
        avatar={this.renderAvatar()}
        avatarWidth={avatarWidth}
        avatarHeight={avatarHeight}
        canClickItem={canClickItem}
        className={className}
        content={content}
        isHighlighted={isHighlighted}
        header={header}
        isStackedOnMobile={isStackedOnMobile}
        onClick={onClick}
        onClickAvatar={onClickAvatar}
        subHeader={this.renderSubHeaderContent()}
      >
        {children}
      </List.ItemWithAvatar>
    );
  }
}
