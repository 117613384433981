import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, fill }) => (
  <svg className={className} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 0C3.35786 -3.62117e-07 3.62117e-07 3.35786 0 7.5C-3.62117e-07 11.6421 3.35786 15 7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 3.62117e-07 7.5 0ZM6.26661 6.29058H8.73327L8.98778 11.7383C8.99443 11.8808 8.8807 12 8.73805 12H6.26195C6.1193 12 6.00556 11.8808 6.01222 11.7383L6.26661 6.29058ZM7.81661 3C8.36889 3 8.81661 3.44772 8.81661 4V4.23342C8.81661 4.78571 8.36889 5.23342 7.81661 5.23342H7.18328C6.63099 5.23342 6.18328 4.78571 6.18328 4.23342V4C6.18328 3.44771 6.63099 3 7.18328 3H7.81661Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'currentColor',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
