import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7C11.4477 7 11 7.44772 11 8V11.7803L8.85295 13.0199C8.37466 13.296 8.21078 13.9076 8.48692 14.3859C8.76307 14.8642 9.37466 15.0281 9.85295 14.7519L12.5005 13.2233C12.8468 13.0234 13.0283 12.6477 12.9973 12.274C12.9991 12.2496 13 12.2249 13 12.2V8C13 7.44772 12.5523 7 12 7Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#05A164',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
