import React from 'react';
import Base from './Base';
import styled from 'styled-components';
import RocketStarburst from './common/RocketStarburst';
import { COMPLETED_TEXT } from './common/strings';
import theme from '@matterapp/matter-theme';

const ContentContainer = styled.div`
  max-width: 540px;
`;

const ImageContainer = styled.div`
  margin: -${theme.spacing.single} 0;
  line-height: 0;
  z-index: 0;
  & svg {
    height: 100%;
    width: 100%;
  }
  ${theme.media.medium`
    margin: 0;
    & svg {
      height: auto;
      width: auto;
    }
  `}
`;

const Completed = (props) => {
  const { onClickDismiss, ...otherProps } = props;
  return (
    <Base
      {...otherProps}
      bannerType={Base.bannerTypes.SUCCESS}
      content={<ContentContainer>{COMPLETED_TEXT.content}</ContentContainer>}
      header={COMPLETED_TEXT.header}
      image={
        <ImageContainer>
          <RocketStarburst />
        </ImageContainer>
      }
      onClickDismiss={onClickDismiss}
    />
  );
};

Completed.defaultProps = {
  onClickDismiss: () => null,
};

export default Completed;
