import { withStateHandlers } from 'recompose';

const withToggle = ({ lockPropName, handleAttemptLockName }) =>
  withStateHandlers(
    () => ({
      [lockPropName]: false,
    }),
    {
      [handleAttemptLockName]: () => () => ({
        [lockPropName]: true,
      }),
    }
  );

export default withToggle;
