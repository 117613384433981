import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Animations from '../../Animations';
import Success from '../../Success';
import Button from '../../Button';
import theme from '@matterapp/matter-theme';

const DEFAULT_TITLE = 'Woohoo!';
const DEFAULT_SUB_TITLE = 'You just made someone’s day!';
const DEFAULT_CONTENT_TEXT = 'Your kudos is on its way.';

const AnimationContainer = styled.div`
  margin-top: ${() => theme.spacing.single};
`;

const CloseButton = styled(Button.New)`
  margin-top: ${() => theme.spacing.single};
  min-width: 160px;
`;

export default class KudosSentModal extends React.PureComponent {
  static propTypes = {
    content: PropTypes.node,
    title: PropTypes.string,
    subTitle: PropTypes.string,
  };

  static defaultProps = {
    content: DEFAULT_CONTENT_TEXT,
    title: DEFAULT_TITLE,
    subTitle: DEFAULT_SUB_TITLE,
  };

  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.playAnimation();
    }
  };

  playAnimation = () => {
    if (this.imageRef.current && this.imageRef.current.resetAnimation) {
      this.imageRef.current.resetAnimation();
    }
    setTimeout(() => {
      if (this.imageRef.current && this.imageRef.current.playAnimation) {
        this.imageRef.current.playAnimation();
      }
    }, 500);
  };

  render() {
    const { content, isOpen, onCloseModal, title, subTitle } = this.props;

    const titleText = (
      <>
        <div>{title}</div>
        <div>{subTitle}</div>
      </>
    );

    const contentContainer = (
      <div>
        <div>{content}</div>
        <CloseButton notification onClick={onCloseModal} size="small">
          Close
        </CloseButton>
      </div>
    );

    return (
      <Success.Modal
        content={contentContainer}
        isOpen={isOpen}
        image={
          <AnimationContainer>
            <Animations.OpeningGift ref={this.imageRef} />
          </AnimationContainer>
        }
        onCloseModal={onCloseModal}
        showShockwave
        showStars
        title={titleText}
      />
    );
  }
}
