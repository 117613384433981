import React from 'react';
import PropTypes from 'prop-types';
import omitStyled from '../../../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

const BodyContainer = omitStyled('div', ['fixedHeight', 'scrollable'])`
  overflow: ${({ scrollable }) => scrollable ? 'hidden' : 'visible'};
  display: flex;
  flex: 1 1 auto;

  ${({ fixedHeight }) => theme.media.medium`
    ${fixedHeight && `border-radius: 0 0 ${theme.borderRadius.L} ${theme.borderRadius.L};`};
  `}
 `;

const ScrollContainer = omitStyled('div', ['fixedHeight', 'scrollable'])`
  overflow-y: ${({ scrollable }) => scrollable ? 'auto' : 'visible'};
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;

  ${({ fixedHeight }) => theme.media.medium`
    ${fixedHeight && `max-height: 500px;`};
  `}
`;

const Body = ({ children, className, fixedHeight, scrollable }) => (
  <BodyContainer className={className} fixedHeight={fixedHeight} scrollable={scrollable}>
    <ScrollContainer fixedHeight={fixedHeight} scrollable={scrollable}>{children}</ScrollContainer>
  </BodyContainer>
);

Body.defaultProps = {
  fixedHeight: false,
  scrollable: true,
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fixedHeight: PropTypes.bool,
  scrollable: PropTypes.bool,
};

Body.ScrollContainer = ScrollContainer;

export default Body;
