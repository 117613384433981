import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AvatarBase from './Base';
import { Container, getBadgeSize, getBadgeMarginTop } from './styles';
import { colors, font } from '@matterapp/matter-theme';

const CountContainer = styled.div`
  ${({ marginTop, size }) => `
    background: ${colors.purples[10]};
    border-radius: 50% !important;
    color: ${colors.purples[50]};
    font-size: ${font.size.XXS};
    font-weight: ${font.weight.semiBold};
    line-height: ${size - 2}px;
    margin-top: ${marginTop}px;
    margin-left: -${size}px;
    min-width: ${size}px;
    max-width: ${size}px;
    height: ${size}px;
    text-align: center;
    vertical-align: middle;
    width: ${size}px;

    // prevent jumpies
    display: inline-block;
  `}  
`;

const WithCount = (props) => {
  const { count, showAsAddition } = props;
  const badgeSize = getBadgeSize(props);
  return (
    <AvatarBase {...props}>
      <Container width={badgeSize} height={badgeSize}>
        <CountContainer marginTop={getBadgeMarginTop(props)} size={badgeSize}>
          {showAsAddition ? `+${count - 1}` : count}
        </CountContainer>
      </Container>
    </AvatarBase>
  );
};

WithCount.propTypes = {
  ...AvatarBase.propTypes,
  /** Number to display. */
  count: PropTypes.number,
  /** Displays a + sign in front of number and decrements the count by one. */
  showAsAddition: PropTypes.bool,
};

export default WithCount;
