import React from 'react';
import { NOTIFICATION_TYPE } from '@matterapp/notifications';
import { replaceText } from './styles';

export function getNotificationDetails(item, hasWorkspace) {
  switch (item.type) {
  case NOTIFICATION_TYPE.feedbackThanked: {
    return {
      copy: 'thanked you for giving them feedback.',
      icon: 'happyFace',
    };
  }
  case NOTIFICATION_TYPE.newCommentOnFeedbackGiven: {
    return {
      copy: 'commented on a feedback you gave.',
      icon: hasWorkspace ? 'workspacesSpaces' : 'comment',
    };
  }
  case NOTIFICATION_TYPE.newCommentOnFeedbackReceived: {
    return {
      copy: 'commented on a feedback you received.',
      icon: 'comment',
    };
  }
  case NOTIFICATION_TYPE.advisorInvitationReceived: {
    return {
      copy: (
        <>
          asked you to join their Advisory Council for{' '}
          <strong>{item.payload.skill.name}</strong>
        </>
      ),
      icon: 'council',
    };
  }
  case NOTIFICATION_TYPE.feedbackRequestBounced: {
    return {
      copy: (
        <div style={{ marginLeft: -5 }}>
          Your request to{' '}
          <strong>{item.payload.invitation.invitedPeer.email}</strong> has
          bounced.
        </div>
      ),
      icon: 'bouncedNotification',
    };
  }
  case NOTIFICATION_TYPE.feedbackRequestDeclined: {
    return {
      copy: 'has declined your request.',
      icon: 'declined',
    };
  }
  case NOTIFICATION_TYPE.newMemberJoinedWorkspace: {
    return {
      copy: `has joined ${replaceText}`,
      icon: 'workspacesSpaces',
    };
  }
  case NOTIFICATION_TYPE.workspaceInvitationDeclined: {
    return {
      copy: `has declined your invitation to join ${replaceText}`,
      icon: 'declined',
    };
  }
  
  default: {
    throw new Error(`Unrecognized notification type: ${item.type}`);
  }
  }
}
