import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../Button';
import theme from '@matterapp/matter-theme';

export const choices = {
  YES: 'Yes',
  NO: 'No',
  MAYBE: 'Maybe',
  COMPLICATED: 'It’s complicated',
  MONTHLY: 'Monthly',
  WEEKLY: 'Weekly',
  NEVER: 'Never',
};

const selectedButtonStyle = `
  background: ${theme.colors.blues[50]};
  border-color: ${theme.colors.blues[50]};
  box-shadow: 0px 1px 2px rgba(69, 132, 255, 0.15);
  color: ${theme.colors.white};
`;

const pillButton = styled(Button.Simple).attrs(() => ({
  buttonType: Button.Simple.buttonTypes.BUBBLE,
}))`
  border-color: ${theme.colors.blues[20]};
  font-size: ${theme.fontSize.M};
  ${(props) => {
    if (props['data-selected']) {
      return selectedButtonStyle;
    }
  }}
`;

const pillToggleButton = styled(pillButton)`
  &:active {
    ${selectedButtonStyle};
  }
`;

export const Buttons = {
  Pill: pillButton,
  PillToggle: pillToggleButton,
};

const ChoiceButton = (props) => {
  const { buttonComponent, children, label, ...otherProps } = props;
  const Component = buttonComponent;
  return (
    <Component {...otherProps}>
      {label}
      {children}
    </Component>
  );
};

ChoiceButton.propTypes = {
  /** The button component to use. */
  buttonComponent: PropTypes.elementType,
  /** Additional contents of the button. */
  children: PropTypes.node,
  /** Label of the button. */
  label: PropTypes.node,
};

ChoiceButton.defaultProps = {
  buttonComponent: Button.Simple,
};

const createButton = (defaultLabel) => {
  return ({ label, selectedValue, ...otherProps }) => (
    <ChoiceButton
      label={label || defaultLabel}
      data-selected={selectedValue ? selectedValue === defaultLabel : null}
      value={defaultLabel}
      {...otherProps}
    />
  );
};

const YesButton = createButton(choices.YES);
const NoButton = createButton(choices.NO);
const MaybeButton = createButton(choices.MAYBE);
const ComplicatedButton = createButton(choices.COMPLICATED);
const MonthlyButton = createButton(choices.MONTHLY);
const WeeklyButton = createButton(choices.WEEKLY);
const NeverButton = createButton(choices.NEVER);

export {
  ChoiceButton,
  ComplicatedButton,
  MaybeButton,
  MonthlyButton,
  NeverButton,
  NoButton,
  WeeklyButton,
  YesButton,
};
