import styled from 'styled-components';
import Card from '../Card';
import theme, { transitions } from '@matterapp/matter-theme';
import { smallBreakpoint } from '../consts';

export const transitionTime = transitions.times.singleAndHalf;

export const CardWrapper = styled.div``;
export const CardCurrent = styled(Card)`
  & .matter-feed-item-container {
    border: 0;
    margin: -${theme.spacing.singleAndHalf} 0;
  }
  ${(props) => theme.media.breakpoint(smallBreakpoint)`
    height: auto;
    margin-bottom: 112px;
    overflow-x: visible;
    width: ${props.autoFitCardWidth ? 'fit-content' : '100%'};
  `}
`;

export const CardPrev = styled(Card)`
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  left: 50%;
  z-index: 10;
  box-shadow: none;
  pointer-events: none;
  height: 100vh;

  & .matter-feed-item-container {
    border: 0;
    margin: -${theme.spacing.singleAndHalf} 0;
  }

  ${(props) => theme.media.breakpoint(smallBreakpoint)`
    height: auto;
    margin-bottom: ${theme.spacing.double};
    width: ${props.autoFitCardWidth ? 'fit-content' : '100%'};
  `}

  & * {
    cursor: default !important;
    pointer-events: none !important;
  }
`;

export const ProgressBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${theme.spacing.quarter};
  z-index: 100;
`;

export const ProgressBar = styled.div`
  background: ${theme.colors.purples[50]};
  width: 100%;
  position: absolute;
  height: ${theme.spacing.quarter};
  transform: scaleX(${({ percent }) => percent});
  transform-origin: left;
  transition: transform ${theme.transitions.times.default};
`;
