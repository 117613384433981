import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Clickable } from '../Clickable/Clickable';

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${theme.spacing.quintuple};
  margin-right: ${theme.spacing.quarter};
  ${theme.media.S`
    margin: 0;
  `}
`;

const Tab = styled(Clickable)`
  align-items: center;
  color: ${theme.colors.blacks[30]};
  display: flex;
  font-size: ${theme.fontSize.S};
  justify-content: center;
  line-height: ${theme.lineHeight.M};
  height: 100%;
  min-width: ${theme.spacing.single};
  padding: 0 ${theme.spacing.threeQuarters};
  user-select: none;
  transition: color ${theme.transitions.times.default};

  ${({ isActive, reverseColors }) => isActive ? `
    color: ${reverseColors ? theme.colors.purples[50] : theme.colors.blacks[70]};
    font-weight: ${theme.fontWeight.bold};
  ` : `
    &:hover {
      color: ${reverseColors ? theme.colors.blacks[60] : theme.colors.purples[40]};
    }
    &:active {
      color: ${reverseColors ? theme.colors.blacks[80] : theme.colors.purples[60]};
    }
  `}
`;

const Divider = styled.div`
  height: 22px;
  border: .5px solid ${theme.colors.blacks[30]};
`;

const Filter = ({ className, tabsList, activeTab, onChangeTab, reverseColors, TabComponent }) => {
  return (  
    <TabsContainer className={className} >
      {tabsList.map((tab, index) => {
        return (
          <React.Fragment key={index}>
            <TabComponent
              data-rc="sorting-bar-tab"
              key={tab} 
              onClick={() => onChangeTab(index, tab)}
              isActive={activeTab === index}
              reverseColors={reverseColors}
            >
              {tab}
            </TabComponent>
            {index < tabsList.length - 1 && <Divider/>}
          </React.Fragment >
        );
      })}
    </TabsContainer>
  );
};

Filter.defaultProps = {
  activeTab: null,
  isActive: false,
  reverseColors: false,
  tabsList: [],
  TabComponent: Tab,
};

Filter.propTypes = {
  activeTab: PropTypes.number,
  className: PropTypes.string,
  onChangeTab: PropTypes.func.isRequired,
  reverseColors: PropTypes.bool,
  tabsList: PropTypes.arrayOf(PropTypes.node),
  TabComponent: PropTypes.elementType,
};

Filter.Tab = Tab;
Filter.TabsContainer = TabsContainer;

export default Filter;
