export const duplicateCountriesList = {
  PL: 'Poland',
  LU: 'Luxembourg',
  FR: 'France',
  AT: 'Austria',
  DE: 'Germany',
  BE: 'Belgium',
  MC: 'Monaco',
  US: 'USA',
  CA: 'Canada',
  PR: 'Puerto Rico'
};

export const duplicateBrands = ['Nike', 'Charity On Top', 'lululemon', 'Amazon.de', 'Amazon.fr', 'Walmart'];