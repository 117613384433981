import { graphql } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';
import { handleSubmitValidation } from 'libs';
import { SET_FULL_NAME_MUTATION } from 'graphql-queries/queries';
import { trackSetFullName } from 'libs/tracking/setFullName';

const withSetFullNameMutation = graphql(SET_FULL_NAME_MUTATION, {
  props: ({ mutate }) => ({
    setFullNameMutation: async (fullName) => {
      const { data } = await mutate({
        variables: { fullName },
      });
      return data.setFullName;
    },
  }),
});

export default compose(
  withSetFullNameMutation,
  withProps(({ setFullNameMutation }) => ({
    setFullName: async (fullName) => {
      await handleSubmitValidation({
        fieldName: 'fullName',
        submit: async () => {
          const result = await setFullNameMutation(fullName);
          trackSetFullName(result);
        },
      });
    },
  }))
);
