import React, { useState } from 'react';
import theme from '@matterapp/matter-theme';
import styled from 'styled-components';
import InlineHover from './InlineHover';
import { Popover, Box } from '@mui/material';

const StyledPopover = styled(Popover)({
  '.MuiPaper-root': {
    overflow: 'visible',
    backgroundColor: theme.colors.blacks[90],
    color: theme.colors.white,
    borderRadius: '8px',
    padding: 0
  }
});

const ContentWrapper = styled.div`
  padding: ${theme.spacing.threeQuarters} ${theme.spacing.single};
`;

const TooltipHeader = styled('div')`
  font-weight: ${theme.fontWeight.bold};
  padding: 0 ${theme.spacing.single};
  padding-top: ${theme.spacing.threeQuarters};
`;

const Trigger = styled.button`
  background: none;
  border: none;
  outline: none;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  flex-grow: 0;
`;

const ArrowRight = styled(Box)({
  position: 'absolute',
  width: 0,
  height: 0, 
  borderTop: '8px solid transparent',
  borderBottom: '8px solid transparent',
  borderLeft: `8px solid ${theme.colors.blacks[90]}`
});

const ArrowUp = styled(Box)({
  position: 'absolute',
  width: 0,
  height: 0, 
  borderLeft: '8px solid transparent',
  borderRight: '8px solid transparent',
  borderBottom: `8px solid ${theme.colors.blacks[90]}`
});

const Tooltip = (props) => {
  const {
    content,
    header,
    position,
    clickOnly,
    isOpen,
    handlePopupOpen,
    TriggerComponent,
    triggerProps,
    children,
    onClick = () => {}
  } = props;
  const useInternalState = isOpen === undefined;
  const [isOpenState, setOpenState] = useState(false);
  const isPopupOpen = useInternalState ? isOpenState : isOpen;
  const setPopupOpen = useInternalState ? setOpenState : handlePopupOpen;

  const [anchorEl, setAnchorEl] = useState(null);

  const Arrow = position === 'left' ? ArrowRight : ArrowUp;

  let anchorOrigin = {
    vertical: 'center',
    horizontal: 'left'
  };

  let transformOrigin = {
    vertical: 'center',
    horizontal: 'right'
  };

  let arrowPosition = {
    top: 'calc(50% - 8px)', 
    right: '-8px'
  };

  if (position === 'bottom') {
    anchorOrigin={
      vertical: 'bottom',
      horizontal: 'center',
    };
    
    transformOrigin={
      vertical: 'top',
      horizontal: 'center',
    };

    arrowPosition = {
      top: '-8px', 
      right: 'calc(50% - 8px)'
    };
  }
  return (
    <>
      <TriggerComponent
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if (clickOnly) {
            setPopupOpen(true);
            setAnchorEl(e.currentTarget);
            onClick();
          }
        }}
        onMouseEnter={(e) => {
          if (!clickOnly && useInternalState) {
            setAnchorEl(e.currentTarget);
            setPopupOpen(true);
          }
        }}
        onMouseLeave={() => {
          if (!clickOnly && useInternalState) {
            setAnchorEl(null);
            setPopupOpen(false);
          }
        }}

        {...triggerProps} 
      >
        {children}
      </TriggerComponent>
      <StyledPopover 
        open={isPopupOpen}
        anchorEl={anchorEl}
        disableScrollLock
        onClose={(e) => {
          e.stopPropagation();
          
          setAnchorEl(null);
          setPopupOpen(false);
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <>
          <Arrow sx={{ ...arrowPosition, visible: !!anchorEl }} />
          {header ? (
            <TooltipHeader>
              {header}
            </TooltipHeader>
          ) : null}
          {typeof content == 'string' ? (
            <ContentWrapper>{content}</ContentWrapper>
          ) : (
            content
          )}
        </>
      </StyledPopover>
    </>
  );
};

Tooltip.defaultProps = {
  arrowSize: 12,
  dark: true,
  disableReposition: false,
  distanceFromTrigger: 4,
  canClickInside: false,
  content: '',
  position: ['top', 'bottom', 'left', 'right'],
  align: 'center',
  style: {},
  handlePopupOpen: () => {},
  onClick: () => {},
  onClickOutside: () => {},
  triggerProps: {},
  TriggerComponent: Trigger
};

Tooltip.InlineHover = InlineHover;

export default Tooltip;
