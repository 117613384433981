import React from 'react';
import PropTypes from 'prop-types';
import { WORKSPACE_PROPS } from './consts';
import Icon from './Icon';
import {
  ProfileMenuWorkspaceName,
  ProfileMenuWorkspaceContainer,
  ProfileMenuWorkspaceLink,
} from './styles';

const ProfileMenuItem = (props) => {
  const {
    onClick,
    to,
    workspace,
  } = props;
  return (
    <ProfileMenuWorkspaceContainer>
      <ProfileMenuWorkspaceLink onClick={onClick} to={to}>
        <Icon src={workspace.iconUrl} />
        <ProfileMenuWorkspaceName>{workspace.name}</ProfileMenuWorkspaceName>
      </ProfileMenuWorkspaceLink>
    </ProfileMenuWorkspaceContainer>
  );
};

ProfileMenuItem.defaultProps = {
  workspace: {},
};

ProfileMenuItem.propTypes = {
  /** Current active workspace id. */
  activeId: PropTypes.string,
  /** Callback when item is clicked. */
  onClick: PropTypes.func,
  /** URL to go to when item is clicked. */
  to: PropTypes.string,
  /** Workspace to display. */
  workspace: PropTypes.shape(WORKSPACE_PROPS),
};

export default ProfileMenuItem;
