import filter from 'lodash/filter';

export function getValueMap(arr) {
  return arr.map(a => {
    const arrObject = { label: a, value: a };
    return arrObject;
  });
}
export const monthsOptions = {
  0 : 'January',
  1 : 'February',
  2 : 'March',
  3 : 'April',
  4 : 'May',
  5 : 'June',
  6 : 'July',
  7 : 'August',
  8 : 'September',
  9 : 'October',
  10 : 'November',
  11 : 'December'
};

export const months = Object.values(monthsOptions);
export function sleep(time) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  });
}

function has(str, query) {
  return str.toLowerCase().indexOf(query) > -1;
}

export function search(query, searchList) {
  return sleep(200).then(() =>
    filter(searchList, (c) => has(c.label, query) || has(c.value, query))
  );
}