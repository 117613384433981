export const itemHalfMargin = '4px';
export const qualitiesHeight = '48px';
export const FEED_ITEM_CONTAINER_CLASS_NAME = 'matter-feed-item-container';

/**
 * any component that uses this class name will have its border-bottom and
 * border radius overriden, if they are the last item in a FeedItem. If they
 * are anywhere but last, their styles will remain untouched.
 */
export const FEED_ITEM_FOOTERABLE_CLASSNAME = 'matter-feed-item-footerable';

// This will work out widths of each skill rating
//
// * Ratings with notes go at the top with `1` width
// * Those without notes will be prioritized to show rows of 2, side by side
// * When an odd number of non-note ratings, it'll put a row of 3 at the bottom
export const generateSkillRatingsLayout = (skillRatings) => {
  return skillRatings.map((skillRating, i) => ({
    skillRating,
    width: 1,
    isAlternate: i % 2 === 1,
  }));
};

export function getIsAlternate({ isAlternate, isReversed }) {
  /**
   * We are computing whether to have the badge left-aligned or right-aligned.
   * When this function returns false, the badge will be left-aligned.
   * When this function returns true, the badge will be right-aligned.
   * Sometimes, the user will want to reverse this rule. This means that
   * even though `isAlternate` is true, because `isReversed` is also true,
   * we want to return false. The table below summarizes all the potential
   * combinations. Upon closer inspection, you'll realize that this is
   * equivalent to xOring the inputs, which is what we end up doing.
   *
   *
   * +-------------+------------+--------+
   * | isAlternate | isReversed | result |
   * +-------------+------------+--------+
   * | true        | true       | false  |
   * | true        | false      | true   |
   * | false       | true       | true   |
   * | false       | false      | false   |
   * +-------------+------------+--------+
   *
   */
  const xOred = isAlternate ^ isReversed;
  // Xoring bools returns a number. We need to further convert that to a boolean
  const result = !!xOred;
  return result;
}
