import PropTypes from 'prop-types';
import { personPropType } from '@matterapp/people';

export const AVATAR_SIZE = 24;

export const ANIMATION_TIME = 0.3;

export const COMMENT_PLACEHOLDER = 'Write a comment';
export const COMMENTS_HEADER = 'Comments';
export const MAX_COMMENT_CHARACTER_LIMIT = 3000;

export const COMMENT_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  author: personPropType.isRequired, // eslint-disable-line
  message: PropTypes.string.isRequired,
});

export const VIEW_ALL_COMMENTS_TEXT = 'View All Comments';

export { personPropType as PERSON_PROP_TYPE };

