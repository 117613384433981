import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const HeaderContainer = styled.div`
  color: ${() => theme.colors.purple};
  font-size: 14px;
  line-height: 20px;
  padding: 0 20px;

  ${({ stacked }) => stacked ? `
    display: flex;
    flex-direction: column;
  ` : ``}
`;

export default HeaderContainer;
