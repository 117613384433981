import React from 'react';
import styled from 'styled-components';
import { Button, Box, Typography } from '@mui/material';

const BannerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: 'white',
  margin: theme.spacing(2),
  padding: theme.spacing(3),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.purple[50],
  borderRadius: '8px'
}));

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  borderColor: 'white',
  marginTop: theme.spacing(2),
  textTransform: 'none',
  fontSize: '17px',
  minHeight: '48px',
  '&:hover': {
    color: theme.palette.purple[40],
    backgroundColor: 'white',
    borderColor: theme.palette.purple[60]
  },
  '&:active': {
    color: theme.palette.purple[50],
    borderColor: theme.palette.purple[60]
  }
}));

export default function UpgradeBanner({ tier, expiresInDays, onClickUpgrade }) {
  if (!['trial', 'free'].includes(tier)) {
    return null;
  }

  const plural = expiresInDays > 1 ? 'days' : 'day';

  return (
    <BannerContainer>
      <Typography component='p' variant='body1'>
        {tier === 'trial' 
          ? <>You have <strong>{expiresInDays} {plural}</strong> left in your free trial</>
          : 'You\'re on the Free Plan. Upgrade to unlock all the features.'}
      </Typography>
      <StyledButton
        variant="outlined"
        onClick={onClickUpgrade}
      >
        Upgrade to Pro
      </StyledButton>
    </BannerContainer>
  );
}