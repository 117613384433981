import React from 'react';
import PropTypes from 'prop-types';
import Footer from './Footer';
import Buttons from '../Buttons';

const WithActionButtons = (props) => {
  const {
    canClickPrimary,
    canClickSecondary,
    className,
    onClickPrimary,
    onClickSecondary,
    primaryLabel,
    primaryProps = {},
    primaryIsDestructive,
    primaryIsLoading,
    secondaryLabel,
    secondaryProps = {},
    useVividPrimaryButton,
    ...otherProps
  } = props;

  const primaryButtonProps = {
    isDestructive: primaryIsDestructive,
    isLoading: primaryIsLoading,
    ...primaryProps,
  };

  const PrimaryComponent = useVividPrimaryButton
    ? Buttons.PrimaryVivid
    : Buttons.Primary;

  const secondaryButton = secondaryLabel ? (
    <Buttons.Secondary
      content={secondaryLabel}
      disabled={!canClickSecondary}
      onClick={onClickSecondary}
      data-testid="secondaryButton"
      {...secondaryProps}
    />
  ) : null;

  const primaryButton = primaryLabel ? (
    <PrimaryComponent
      content={primaryLabel}
      disabled={!canClickPrimary}
      onClick={onClickPrimary}
      data-testid="primaryButton"
      {...primaryButtonProps}
    />
  ) : null;

  return (
    <Footer
      {...otherProps}
      className={className}
      left={secondaryButton}
      right={primaryButton}
    />
  );
};

WithActionButtons.defaultProps = {
  canClickPrimary: true,
  canClickSecondary: true,
  useVividPrimaryButton: true,
};

WithActionButtons.propTypes = {
  /** If primary button can be clicked. */
  canClickPrimary: PropTypes.bool,
  /** If secondary can be clicked. */
  canClickSecondary: PropTypes.bool,
  /** Additional class. */
  className: PropTypes.string,
  /** Callback when primary button is clicked. */
  onClickPrimary: PropTypes.func,
  /** Callback when secondary button is clicked. */
  onClickSecondary: PropTypes.func,
  /** If primary button is destructive action. (Button color red) */
  primaryIsDestructive: PropTypes.bool,
  /** If primary button is loading. */
  primaryIsLoading: PropTypes.bool,
  /** Primary button label. */
  primaryLabel: PropTypes.node,
  /** Additional props for primary button. */
  primaryProps: PropTypes.object,
  /** Secondary button label. */
  secondaryLabel: PropTypes.node,
  /** Additional props for secondary button. */
  secondaryProps: PropTypes.object,
  /** Use vivid primary button. */
  useVividPrimaryButton: PropTypes.bool,
};

export default WithActionButtons;
