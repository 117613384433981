import React from 'react';
import PropTypes from 'prop-types';

export const REPLACE_TEXT = '%REPLACE%';

const TextWithReplacement = (props) => {
  const {
    elementType,
    className,
    formatTextChunk,
    replaceText,
    replaceTextElement,
    text,
  } = props;
  let numOfTextReplaced = 0;

  const Container = elementType;
  const replaceMultiple = Array.isArray(replaceText);
  const replaceElementMultiple = Array.isArray(replaceTextElement);

  const textRegex = new RegExp('(' + REPLACE_TEXT + ')', 'g');
  const textArray = text.split(textRegex);
  const contents = textArray.map((chunk, index) => {
    if (chunk === REPLACE_TEXT) {
      const replaceTextValue = replaceMultiple
        ? replaceText[numOfTextReplaced]
        : replaceText;

      if (replaceTextElement) {
        const Wrapper = replaceElementMultiple
          ? replaceTextElement[numOfTextReplaced]
          : replaceTextElement;
        numOfTextReplaced += 1;
        return <Wrapper key={`${text}-${index}`}>{replaceTextValue}</Wrapper>;
      }
      numOfTextReplaced += 1;
      return formatTextChunk(replaceTextValue, index);
    }
    return formatTextChunk(chunk, index);
  });

  return <Container className={className}>{contents}</Container>;
};

TextWithReplacement.replaceText = REPLACE_TEXT;

TextWithReplacement.defaultProps = {
  elementType: 'span',
  formatTextChunk: (text) => text,
};

TextWithReplacement.propTypes = {
  /** HTML element to use. */
  elementType: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  /** Function to format each chunk of the text. */
  formatTextChunk: PropTypes.func,
  /**
   * Text to replace all `replaceText` with.
   * Pass in array to replace text in the order of the replacement texts.
   * If passing in an element or [element(s)], you MUST set a unique key for each element.
   */
  replaceText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /** If passed in, wraps the replaced text with given HTML element. */
  replaceTextElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
    ),
  ]),
  /**
   * Formatted text containing at least 1 `replaceText` delimeter.
   * e.g. `I want the next word after this `${TextWithReplacement.replaceText}` to be replaced`;
   */
  text: PropTypes.string.isRequired,
};

export default TextWithReplacement;
