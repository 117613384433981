export const FeedItemTypes = {
  CREATION: 'WorkspaceCreation',
  NEW_MEMBERS: 'WorkspaceMemberAdded',
  KUDOS: 'KudosPayload',
  RITUAL_START: 'RitualStart',
  RITUAL_PROGRESS: 'RitualProgress',
  RITUAL_RECAP: 'RitualRecap',
  LONG_FORM: 'LongformPayload',
  QUICK_NOTE: 'QuickNotePayload',
};

export const MAX_COMMENT_CHARACTER_LIMIT = 3000;