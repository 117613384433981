import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Path0 from './Path0';
import Path1 from './Path1';
import Path2 from './Path2';
import Path3 from './Path3';
import Path4 from './Path4';
import Path5 from './Path5';
import Path6 from './Path6';
import Path7 from './Path7';
import Path8 from './Path8';
import Background1 from './Background1';
import Background2 from './Background2';
import Background4 from './Background4';
import Background5 from './Background5';
import Background6 from './Background6';
import Background7 from './Background7';
import Background8 from './Background8';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${theme.spacing.doubleAndHalf};

  ${theme.media.XS`
    padding: 0 ${theme.spacing.triple};
  `}

  ${theme.media.S`
    padding: 0 ${theme.spacing.tripleAndHalf};
  `}

  ${theme.media.M`
    padding: 0 ${theme.spacing.quad};
  `}
`;

const PATHS = [Path0, Path1, Path2, Path3, Path4, Path5, Path6, Path7, Path8];

const BACKGROUNDS = [
  null,
  Background1,
  Background2,
  null,
  Background4,
  Background5,
  Background6,
  Background7,
  Background8,
];

const NUMBER_OF_PATHS = PATHS.length;

const VELOCITIES = {
  fast: 0.0003,
  slow: 0.002,
  instant: 0,
};

export default class Route1 extends React.Component {
  static propTypes = {
    /** If the background path should animate in first. */
    animateBackground: PropTypes.bool,
    /** If the animation should start at the beginning or between steps. */
    animateFromBeginning: PropTypes.bool,
    /** Callback when the full animation is finished. */
    onFinishAnimation: PropTypes.func,
    /** Callback to render the contents of each point on the Route. */
    renderPointContents: PropTypes.func,
    /** Animation fades to current step instead of animating in. */
    showInstantly: PropTypes.bool,
    /** The current step on the route. */
    step: PropTypes.number,
  };

  static defaultProps = {
    animateFromBeginning: false,
    onFinishAnimation: () => null,
    renderPointContents: () => null,
    step: 1,
  };

  static NUMBER_OF_PATHS = NUMBER_OF_PATHS;

  constructor(props) {
    super(props);
    this.pathRefs = PATHS.reduce((acc, cur, index) => {
      return { ...acc, [index]: React.createRef() };
    }, {});
    this.backgroundRefs = BACKGROUNDS.reduce((acc, cur, index) => {
      if (cur) {
        return { ...acc, [index]: React.createRef() };
      }
      return acc;
    }, {});
    this.state = {
      backgroundIn: !props.animateBackground,
      pathIn: false,
    };
  }

  componentDidMount = () => {
    this._mounted = true;
  };

  componentWillUnnount = () => {
    this._mounted = false;
  };

  playAnimation = () => {
    this.resetAnimation({ dontReset: true });
    this.pathRefs[0].current.playAnimation();
  };

  resetAnimation = ({ dontReset } = {}) => {
    PATHS.forEach((key, index) => {
      const ref = this.pathRefs[index];
      if (ref && ref.current) {
        ref.current.resetAnimation();
      }
      if (index === PATHS.length - 1 && this.state.backgroundIn && !dontReset && this._mounted) {
        this.setState({ backgroundIn: false }, this.resetAnimation);
      }
    });
  };

  /**
   * Gets callback function to play path animation.
   * @param { Number } pathNumber: The path number to play animation of.
   * @returns { void }
   */
  getCompletePathCallback = (pathNumber) => {
    return () => {
      const { backgroundIn } = this.state;
      const { animateBackground, onFinishAnimation, step } = this.props;
      const ref = this.pathRefs[pathNumber];
      const backgroundRef = this.backgroundRefs[pathNumber];
      const pathisBefore = pathNumber < step;
      if ((!backgroundIn || pathisBefore) && ref && ref.current) {
        if (ref && ref.current) {
          ref.current.playAnimation();
        }
        if (animateBackground && !backgroundIn && backgroundRef && backgroundRef.current) {
          backgroundRef.current.playAnimation();
        }
      }
      if (pathNumber === PATHS.length && !this.state.backgroundIn && this._mounted) {
        this.setState({ backgroundIn: true }, this.playAnimation);
      }
      if (this.state.backgroundIn && pathNumber === step) {
        onFinishAnimation(this.props);
      }
    };
  };

  /**
   * Determines the velocity of the path animation.
   * Varies by the number of animations to play and determines a
   * gradual slow down of the full animation when reaching the end.
   * @param { Number } pathIndex: The index of the path animation.
   * @returns { Number } The velocity of the path animation.
   */
  getPathVelocity = (pathIndex) => {
    const { animateFromBeginning, step, showInstantly } = this.props;
    const baseVelocityDecrement = (step - 1) * VELOCITIES.fast;
    const baseVelovity = 0.003 - baseVelocityDecrement;
    const increment = baseVelovity / step;
    if (showInstantly) {
      return VELOCITIES.instant;
    }
    if (!animateFromBeginning && pathIndex >= step) {
      return VELOCITIES.slow;
    }
    if (animateFromBeginning || pathIndex >= step) {
      return increment * pathIndex;
    }
    return VELOCITIES.instant;
  };

  render() {
    const { backgroundIn } = this.state;
    const { animateBackground, renderPointContents, step } = this.props;
    return (
      <Container>
        {PATHS.map((Component, index) => {
          let background = null;
          if (BACKGROUNDS[index]) {
            const BackgroundComponent = BACKGROUNDS[index];
            background = (
              <BackgroundComponent
                playAnimationOnMount={!animateBackground}
                ref={this.backgroundRefs[index]}
              />
            );
          }
          return (
            <Component
              animateBackground={!backgroundIn}
              background={background}
              velocity={backgroundIn ? this.getPathVelocity(index + 1) : 0.0002}
              ref={this.pathRefs[index]}
              key={`path-${index}`}
              onComplete={this.getCompletePathCallback(
                Number.parseInt(index, 10) + 1
              )}
              renderPointContents={(renderProps) =>
                renderPointContents({ ...renderProps, ...this.props, index })
              }
              step={step}
            />
          );
        })}
      </Container>
    );
  }
}
