import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Clickable, IconNew } from '@matterapp/matter-ui';
import SlideOutMenu from 'components/SlideOutMenu/SlideOutMenu';

export const Container = styled(SlideOutMenu).attrs(() => ({
  clickBackgroundToClose: true,
}))`
  display: inherit;
  z-index: 1001;
  
  ${theme.media.L`
    display: none;
  `};
`;

export const CloseIconContainer = styled(Clickable)`
  cursor: pointer;
  position: absolute;
  top: ${theme.spacing.single};
  right: ${theme.spacing.single};
  z-index: 10;
  svg path {
    fill: ${theme.colors.blacks[60]};
  }
`;

export const CloseIcon = styled(IconNew).attrs({ name: 'close' })``;

export const Link = styled(Clickable)`
  color: ${theme.colors.blues[50]};
  cursor: pointer;
  font-size: ${theme.fontSize.base};
  height: ${theme.spacing.singleAndHalf};
  line-height: ${theme.spacing.singleAndHalf};
  text-align: left;

  & + & {
    margin-top: ${theme.spacing.half};
  }

  &:hover {
    color: ${theme.colors.blues[60]};
  }

  &:active {
    color: ${theme.colors.purples[60]};
  }

  ${({ isDisabled }) => isDisabled ? `
    cursor: default;
    pointer-events: none;
    color: ${theme.colors.blacks[30]};
  ` : ``}
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.singleAndHalf};
`;
