import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Rater, Skill } from '@matterapp/matter-ui';
import { media, spacing } from '@matterapp/matter-theme';
import SkillAbilities from './SkillAbilities';
import SkillIconSidebar from './SkillIconSidebar';
import SkillNamePopover from '../SkillNamePopover';
import {
  setRating,
  getRating,
} from '../utils';
import {
  getSkillColorPalette,
  getFormattedDescription,
  skillRatingsDescriptionsRecurring,
} from '@matterapp/matter-ui';
import {
  FlowHeader,
  StepContainer,
  SkillStepsNavButton,
  NavButtonContainer,
  RaterContainer,
  StepHeaderContainer,
  StepHeader,
  MOBILE_RATER_STEP_SIZE,
  DESKTOP_RATER_STEP_SIZE,
  NUMBER_OF_RATINGS,
} from '../sharedStyles';
import {
  REPLACE_NAME,
  REPLACE_SKILL_NAME,
  REPLACE_FREQUENCY_TEXT,
} from '../utils';
import { FREQUENCY_NOUNS } from 'app-consts';

const REPLACE_TEXT = '%s';
const SKILL_RATING_HEADER = `How has ${REPLACE_NAME} ${REPLACE_SKILL_NAME} been in the last ${REPLACE_FREQUENCY_TEXT}?`;
const ABILITY_HEADER = `What can ${REPLACE_NAME} start doing to grow in this skill?`;

const Wrapper = styled.div``;

const SkillBadgeContainer = styled.div`
  width: ${spacing.singleAndHalf};
  height: ${spacing.singleAndHalf};
  margin-right: ${spacing.half};
`;

const SkillRater = styled(Rater.Skill).attrs({
  useSmallNumbers: true,
})`
  max-width: ${(MOBILE_RATER_STEP_SIZE + 4) * NUMBER_OF_RATINGS}px;
  height: ${MOBILE_RATER_STEP_SIZE}px;
  & ${Button.Simple.Toggle.Primary} {
    padding: ${spacing.threeQuarters} ${spacing.quarter};
  }
  ${media.S`
    max-width: ${(DESKTOP_RATER_STEP_SIZE + 4) * NUMBER_OF_RATINGS}px;
    height: ${DESKTOP_RATER_STEP_SIZE}px;
  `}
`;

const SkillAbilitiesContainer = styled.div`
  ${media.S`
    height: 220px;
  `}
`;

const CustomSkillSpacer = styled.div`
  ${media.S`
    height: 292px;
  `}
`;

const SkillStep = (props) => {
  const {
    skillToRate,
    receiver,
    currentStep,
    isFirstSkillStep,
    isLastSkillStep,
    customSkillDescription,
    recurringFeedbackRecurrenceFrequency,
    onClickNextStep,
    onClickBackStep,
    onChangeCurrentSkillRating,
    onChangeCurrentAbilities,
    onOpenRatingGuideModal,
    handleTrackEvent,
  } = props;
  const {
    skill,
    actionItemSelections,
    currentRating,
  } = skillToRate;
  const [tempCurrentRating, setTempCurrentRating] = useState(currentRating);
  const [tempSelectedAbilities, setTempSelectedAbilities] = useState(actionItemSelections);
  const color = getSkillColorPalette(skill.name).toLowerCase();
  const firstName = receiver.firstName || '';
  const receiverFirstNamePlural = `${firstName}'${
    firstName.endsWith('s') ? '' : 's'
  }`;
  const skillHeader = SKILL_RATING_HEADER
    .replace(REPLACE_NAME, receiverFirstNamePlural)
    .replace(REPLACE_TEXT, skill.name)
    .replace(REPLACE_FREQUENCY_TEXT, FREQUENCY_NOUNS[recurringFeedbackRecurrenceFrequency]);
  const abilityHeader = ABILITY_HEADER.replace(REPLACE_NAME, firstName);
  const noRatingDescription = getFormattedDescription(
    skillRatingsDescriptionsRecurring,
    receiver.firstName
  );

  /**
   * Function to get updated skillToRate merged with updated props.
   * @param { Object } updatedProps: Updated prop
   */
  const getUpdatedSkillToRate = (updatedProps) => {
    return { ...skillToRate, ...updatedProps };
  };

  /**
   * Callback when the value of selected abilities changes.
   *
   * Calls `onChangeCurrentAbilities` prop with change event object and
   * updated component props containing the updated `currentAbilities` value.
   *
   * @param { Object } e: The change event.
   * @param { Object } abilityProps: Props of the SkillAbilities component with updated value.
   * @returns { void }
   */
  const handleChangeCurrentAbilities = (e, abilityProps) => {
    const { actionItemSelections, currentAbilities } = abilityProps;

    onChangeCurrentAbilities(e, {
      ...props,
      actionItemSelections,
      currentAbilities,
      skillToRate: getUpdatedSkillToRate({ currentRating: tempCurrentRating, actionItemSelections }),
    });
    setTempSelectedAbilities(actionItemSelections);
  };

  const handleChangeRating = (e, { prevRating, value }) => {
    const currentRating = prevRating === value ? null : setRating(value);

    onChangeCurrentSkillRating(e, {
      ...props,
      currentRating,
      skillToRate: getUpdatedSkillToRate({ currentRating, actionItemSelections: tempSelectedAbilities }),
    });
    setTempCurrentRating(currentRating);
  };

  const handleNextStep = (e) => {
    onClickNextStep(e, skillToRate?.skill.id);
    if (isLastSkillStep) {
      handleTrackEvent.viewSkillNotesStep();
    } else {
      handleTrackEvent.viewSkillStep();
    }
  };

  const handlePrevStep = (e) => {
    onClickBackStep(e, skillToRate?.skill.id);
    if (!isFirstSkillStep) {
      handleTrackEvent.viewSkillStep();
    } else {
      handleTrackEvent.viewSkillSelectionStep();
    }
  };

  return (
    <Wrapper>
      <StepContainer
        sidebarContent={
          <SkillIconSidebar 
            receiverFirstName={firstName}
            skillToRate={skillToRate}
            customDescription={customSkillDescription}
          />
        }
      >
        <StepHeaderContainer>
          <StepHeader>
            <SkillBadgeContainer>
              <Skill.Badge size="fluid" skill={skill} />
            </SkillBadgeContainer>
            <SkillNamePopover
              skillToRate={skillToRate}
              customDescription={customSkillDescription}
            />
          </StepHeader>
        </StepHeaderContainer>
        <FlowHeader>
          {skillHeader}
        </FlowHeader>
        <RaterContainer>
          <SkillRater
            color={color}
            noRatingDescription={noRatingDescription}
            onClickStep={handleChangeRating}
            onOpenRatingGuideModal={onOpenRatingGuideModal}
            rating={getRating(tempCurrentRating)}
            receiversFirstName={receiver.firstName}
          />
        </RaterContainer>
        {!!actionItemSelections.length ? 
          <>
            <FlowHeader>
              {abilityHeader}
            </FlowHeader>
            <SkillAbilitiesContainer>
              <SkillAbilities
                actionItemSelections={actionItemSelections}
                color={color}
                onChange={handleChangeCurrentAbilities}
                currentStep={currentStep}
              />
            </SkillAbilitiesContainer>
          </> :
          <CustomSkillSpacer />
        }
        <NavButtonContainer>
          <SkillStepsNavButton
            onClick={handlePrevStep}
            data-rc={'back-button'}
          >
            Back
          </SkillStepsNavButton>
          <SkillStepsNavButton
            onClick={handleNextStep}
            disabled={!tempCurrentRating}
            data-rc={'continue-button'}
          >
            Continue
          </SkillStepsNavButton>
        </NavButtonContainer>
      </StepContainer>
    </Wrapper>
  );
};

SkillStep.propTypes = {
  /** The current step number of the timeline. Not specifically the currently open step. */
  currentStep: PropTypes.number,
  /** Callback when the list of current selected abilities changes. */
  onChangeCurrentAbilities: PropTypes.func.isRequired,
  /** Callback when the current rating is changed. */
  onChangeCurrentSkillRating: PropTypes.func.isRequired,
  /** Callback when skill rater guide modal opens.  */
  onOpenRatingGuideModal: PropTypes.func,
  /** The data of the receiver of the feedback. */
  receiver: PropTypes.shape({
    firstName: PropTypes.string,
    fullName: PropTypes.string,
  }).isRequired,
  /** The skill to rate for this step. */
  skillToRate: PropTypes.shape({
    skill: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    actionItemSelections: PropTypes.arrayOf(
      PropTypes.shape({
        ability: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
        selected: PropTypes.bool,
      })
    ),
    /** The current note of this skill. */
    currentNote: PropTypes.string,
    /** The current rating of this skill. */
    currentRating: PropTypes.number,
    /** The prior rating of this skill. */
    priorRating: PropTypes.number,
  }).isRequired,
  isFirstSkillStep: PropTypes.bool,
  isLastSkillStep: PropTypes.bool,
  customSkillDescription: PropTypes.string,
  onClickNextStep: PropTypes.func.isRequired,
  onClickBackStep: PropTypes.func.isRequired,
  recurringFeedbackRecurrenceFrequency: PropTypes.string.isRequired,
  handleTrackEvent: PropTypes.object,
};

export default SkillStep;