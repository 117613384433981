import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const SmallScreen = styled.span`
  ${() => theme.media.S`
    display: none;
  `}
`;

export default SmallScreen;
