import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import getRewardsHistory from 'graphql-queries/queries/rewards/getRewardHistory';
import { useQuery } from '@apollo/client';
import { Button } from '@matterapp/matter-ui';
import { colors, font, lineHeight, media, spacing } from '@matterapp/matter-theme';
import RewardsTable from './RewardsTable';
import RewardsConfirmModal from './modals/ConfirmModal';
import { 
  Box,
  Paper
} from '@mui/material';
import ResendRewardModal from './ResendRewardModal';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 0,
}));

const ResendButton = styled(Button.Link)(({ theme }) => ({
  color: theme.colors.blues[50],
  padding: 0,
  minHeight: 'auto'
}));

const REWARD_HEADERS = {
  REWARD: 'Reward',
  DATE: 'Date Requested',
  COST: '# Coins',
  STATUS: 'Status',
};

const CLASSNAMES = {
  STATUS: 'redemption-status',
  NAME: 'reward-name',
  REWARDS_TABLE: 'rewards-table'
};

const MobileBackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${spacing.single};
  height: ${spacing.tripleAndHalf};
  background: white;
  border-top: 2px solid ${colors.blacks[10]};
  box-shadow: 0px 1px 1px rgb(0 0 0 / 15%), 0px 2px 14px rgb(0 0 0 / 10%);
  margin-bottom: ${spacing.singleAndHalf};
  ${media.M`
    display: none;
  `}
`;

const BackButton = styled(Button.Link).attrs(() => ({
  iconName: 'arrowLeft',
  size: Button.buttonSizes.M,
}))`
  align-items: center;
  color: ${colors.blacks[50]};
  display: flex;
  padding: 24px;
  font-weight: ${font.weight.medium};

  & svg {
    width: ${spacing.singleAndQuarter};
    margin-right: ${spacing.half};

    path {
      fill: currentColor;
    }
  }
`;

const RowCell = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const RowGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  font-size: ${font.size.S};
  width: 100%;
  grid-gap: 12px;

  >div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .${CLASSNAMES.STATUS} {
    justify-self: flex-end;
  }

  .${CLASSNAMES.NAME}{
    font-weight: ${font.weight.semiBold};
    line-height: ${lineHeight.M};
    color: ${colors.black};
  }

  ${media.M`
    grid-template-columns: 3fr 1fr 1fr 1fr;
  `}

  ${media.L`
    grid-template-columns: 3fr 1fr 1fr 1fr;
  `}
`;

const RedemptionStatus = styled(Box)(({ theme, status }) => ({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize',
  color: status === 'pending' ? theme.palette.blues[50] : status === 'declined' ? theme.palette.reds[40] : 'inherit',
  fontWight: status === 'pending' || status === 'declined' ? font.weight.medium : '',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

const RedemptionHistory = ({ 
  tenantId,
  onClickBack, 
  isAdminMember, 
  isConfirmModalOpen, 
  rewardType, 
  onCloseModal,
  autoApprove
}) => {
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const [rewardRequestId, setRewardRequestId] = useState(null);
  const { data, loading } = useQuery(getRewardsHistory, {
    variables: {
      tenantId
    }
  });

  if (loading) {
    return null;
  }
  
  const redemptionHistory = data?.getRewardsHistory;

  return (
    <>
      <MobileBackButtonContainer>
        <ResendRewardModal 
          rewardRequestId={rewardRequestId}
          tenantId={tenantId}
          isOpen={resendModalOpen}
          onClose={() => {
            setResendModalOpen(false);
            setRewardRequestId(null);
          }}
        />
        <BackButton onClick={onClickBack}>
          Back To Rewards
        </BackButton>
      </MobileBackButtonContainer>
      <StyledPaper elevation={0}>
        <BackButton onClick={onClickBack}>
          Back To Rewards
        </BackButton>
        <RewardsTable 
          className={CLASSNAMES.REWARDS_TABLE}
          headerRow={
            <RowGrid>
              <div>{REWARD_HEADERS.REWARD}</div>
              <div>{REWARD_HEADERS.COST}</div>
              <div>{REWARD_HEADERS.DATE}</div>
              <div className={CLASSNAMES.STATUS}>{REWARD_HEADERS.STATUS}</div>
            </RowGrid>
          }
          mobileHeaderRow={
            <RowGrid>
              <div>{REWARD_HEADERS.REWARD}</div>
              <div>{REWARD_HEADERS.COST}</div>
              <div className={CLASSNAMES.STATUS}>{REWARD_HEADERS.STATUS}</div>
            </RowGrid>
          }
          emptyStateMessage={'You have no redemption history'}
          desktopRows={redemptionHistory.map(redemption => {
            const { name, date, coinsValue, status } = redemption;          
            const transformedDate = moment(Number(date)).format('L');  
            return (
              <RowGrid key={`${name}-${date}`}>
                <RowCell className={CLASSNAMES.NAME}>{name}</RowCell>
                <RowCell>{coinsValue}</RowCell>
                <RowCell>{transformedDate}</RowCell>
                <RedemptionStatus 
                  className={CLASSNAMES.STATUS} 
                  status={status}
                >
                  {status === 'cancelled' ? 'Refunded' : status}
                  {status === 'fulfilled' && (
                    <ResendButton onClick={() => {
                      setRewardRequestId(redemption.id);
                      setResendModalOpen(true);
                    }}>
                      &nbsp;(resend)
                    </ResendButton>
                  )}
                </RedemptionStatus>
              </RowGrid>
            );
          })}
          mobileRows={redemptionHistory.map(redemption => {
            const { name, coinsValue, status } = redemption;
            return (
              <RowGrid key={`${name}-${coinsValue}-mobile`}>
                <RowCell className={CLASSNAMES.NAME}>{name}</RowCell>
                <RowCell>{coinsValue}</RowCell>
                <RedemptionStatus 
                  className={CLASSNAMES.STATUS} 
                  status={status}
                >
                  {status === 'cancelled' ? 'Refunded' : status}
                  {status === 'fulfilled' && (
                    <ResendButton onClick={() => {
                      setRewardRequestId(redemption.id);
                      setResendModalOpen(true);
                    }}>
                      &nbsp;(resend)
                    </ResendButton>
                  )}
                </RedemptionStatus>
              </RowGrid>
            );
          })}
        >
        </RewardsTable>
      </StyledPaper>
      <RewardsConfirmModal 
        isOpen={isConfirmModalOpen} 
        isAdmin={isAdminMember} 
        rewardType={rewardType} 
        onClose={onCloseModal} 
        autoApprove={autoApprove}
      />
    </>
  );
};

RedemptionHistory.propTypes = {
  isConfirmModalOpen: PropTypes.bool,
  isAdminMember: PropTypes.bool,
  tenantId: PropTypes.string,
  onClickBack: PropTypes.func,
  onCloseModal: PropTypes.func,
  rewardType: PropTypes.string,
  autoApprove: PropTypes.bool
};

export default RedemptionHistory;