import Inline from './Inline';
import PopoverMenu from './PopoverMenu';
import MenuList from './MenuList';
import Dark from './Dark';

export { MenuList, PopoverMenu };

export const Menu = {
  Inline,
  List: MenuList,
  Popover: PopoverMenu,
  Dark,
};

export default Menu;