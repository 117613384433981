import React from 'react';
import PropTypes from 'prop-types';
import { Confirm } from '@matterapp/matter-ui';
import { REWARD_TYPES } from '../consts';

const ConfirmRewardModal = (props) => {
  const {
    isOpen,
    isAdmin,
    rewardType,
    onClose,
    autoApprove
  } = props;

  let confirmText = 'Your reward has been requested';

  if (!isAdmin) {
    confirmText += ' and your admins have been notified';
  }

  confirmText += '.';

  if (rewardType !== REWARD_TYPES.CUSTOM) {
    confirmText += ' Once it\'s approved, you will receive an email with the reward.';
  }

  if (autoApprove) {
    confirmText = 'Your reward request has been submitted successfully.';

    if (rewardType !== REWARD_TYPES.CUSTOM) {
      confirmText += ' You will receive an email with the reward momentarily.';
    }
  }

  return (
    <Confirm
      isOpen={isOpen}
      onClose={onClose}
      header="Order Confirmation"
      subHeader={confirmText}
      cancelButtonLabel="Close"
      onClickCancel={onClose}
    />
  );
};

ConfirmRewardModal.propTypes = {
  isOpen: PropTypes.bool,
  isAdmin: PropTypes.bool,
  rewardType: PropTypes.string,
  onClose: PropTypes.func,
  autoApprove: PropTypes.bool
};

export default ConfirmRewardModal;
