import React from 'react';
import PropTypes from 'prop-types';
import { Notifications } from '@matterapp/matter-ui';

const NotificationsProvider = ({ notifications, children }) => (
  <Notifications.Provider value={notifications}>
    {children}
  </Notifications.Provider>
);

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
      uid: PropTypes.number.isRequired,
      message: PropTypes.string,
      dismissible: PropTypes.bool,
    })
  ).isRequired,
};

export default NotificationsProvider;
