import { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'hooks';

export default function AdminsSettingsHoc({ children, redirectUrl }) {
  const { isAdminMember, currentWorkspace, isChannelAdmin } = useContext(UserContext);
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const { isChannelSetting } = queryParams;
  
  if (!currentWorkspace) {
    return null;
  }

  if (!isAdminMember && !(isChannelSetting && isChannelAdmin)) {
    if (redirectUrl) {
      navigate(redirectUrl);
    }
    
    return null;
  }

  return children;
}