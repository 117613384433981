import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import FeedbackRequestSample from '../../../assets/imgs/components/feedback_request_sample.png';

const GatherFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.spacing.singleAndHalf};
  margin-bottom: ${theme.spacing.singleAndHalf};
  ${theme.media.S`
    border: ${theme.inputs.borderDefault} ${theme.colors.blacks[10]};
    border-radius: ${theme.borderRadius.L};
  `}
`;

const EmptyListHeader = styled.h2`
  font-weight: ${theme.fontWeight.bold};
  line-height: ${theme.lineHeight.L};
  font-size: ${theme.fontSize.M};
  margin-bottom: ${theme.spacing.half};
`;

const GatherFeedbackBody = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M}
  color: ${theme.colors.blacks[50]};
`;

const SampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.blacks[5]};
  padding: ${theme.spacing.singleAndHalf};
  ${theme.media.S`
    border-radius: ${theme.borderRadius.L};
  `}
`;

const SampleImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.spacing.half};
  width: 100%;
  
  img {
    width: 327px;
    height: 142px;
  }
  ${theme.media.S`
    img {
      width: 560px;
      height: 243px;
    }
  `}
  ${theme.media.L`
    img {
      width: calc(100vw - 580px);
      height: auto;
    }
  `}
  ${theme.media.XL`
    img {
      width: 530px;
      height: 230px;
    }
  `}
`;

const EmptyList = () => (
  <>
    <GatherFeedbackContainer>
      <EmptyListHeader>
        No requests yet.
        <br />
        Gather feedback and manage them here.
      </EmptyListHeader>
      <GatherFeedbackBody>
        After gathering feedback, you’ll be able to check their status, follow up, and manage them here.
      </GatherFeedbackBody>
    </GatherFeedbackContainer>
    <SampleContainer>
      <EmptyListHeader>
        Here’s a sneak peak of what’s to come
      </EmptyListHeader>
      <SampleImageContainer>
        <img src={FeedbackRequestSample}/>
      </SampleImageContainer>
    </SampleContainer>
  </>
);

export default EmptyList;
