import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M 1 0C 0.447708 0 0 0.447723 0 1C 0 1.55228 0.447708 2 1 2L 17 2C 17.5523 2 18 1.55228 18 1C 18 0.447723 17.5523 0 17 0L 1 0ZM 15 7C 15 6.44772 14.5523 6 14 6L 4 6C 3.44771 6 3 6.44772 3 7C 3 7.55228 3.44771 8 4 8L 14 8C 14.5523 8 15 7.55228 15 7ZM 12 13C 12 12.4477 11.5523 12 11 12L 7 12C 6.44771 12 6 12.4477 6 13C 6 13.5523 6.44771 14 7 14L 11 14C 11.5523 14 12 13.5523 12 13Z" transform="translate(3 5)" fill={fill} />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
