import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Button from '../../../Button';

const ButtonContainer = styled.div`
  display;
  margin: ${theme.spacing.single} ${theme.spacing.singleAndQuarter};
  margin-bottom: 0;
  ${theme.media.S`
    margin: ${theme.spacing.single} ${theme.spacing.singleAndHalf};
    margin-bottom: 0;
  `}
`;

const GiveFeedbackButtons = ({ onClickKudos, onClickFeedback }) => {
  return (
    <ButtonContainer>
      <Button onClick={onClickKudos} size="XS" style={{marginRight: 8}}>
        Give Kudos
      </Button>
      <Button onClick={onClickFeedback} color="black" size="XS">
        Give Feedback
      </Button>
    </ButtonContainer>
  );
};

export default GiveFeedbackButtons;