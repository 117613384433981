import React from 'react';
import PropTypes from 'prop-types';
import { CheckIcon, DropdownIcon } from '../sharedStyles';
import SmallArrowDown from './SmallArrowDown';
import { Container, IconContainer, Input, Wrapper } from './styles';
import { colorsList, sizes, sizesList } from '../utils';

const Trigger = React.forwardRef((props, ref) => {
  const {
    autoFocus,
    className,
    dropdownArrowComponent,
    color,
    content,
    disabled,
    fitToValue,
    fitToValuePadding,
    hasError,
    isSearchEnabled,
    showCheckmark,
    showDropdownArrow,
    size,
    onBlur,
    onAfterBlur,
    onFocus,
    onAfterFocus,
    onKeyPress,
    placeholder,
    rcLabel,
    value,
    ...otherProps
  } = props;
  const isOpen = props['aria-expanded'];
  const DropdownComponent = [sizes.XS, sizes.S].includes(size)
    ? SmallArrowDown
    : DropdownIcon;
  const dropdownArrow = showDropdownArrow ? dropdownArrowComponent || <DropdownComponent /> : null;

  return (
    <Container
      className={className}
      color={color}
      disabled={disabled}
      hasError={hasError}
      isOpen={isOpen}
      data-error={hasError || null}
      data-open={isOpen || null}
      size={size}
    >
      <Wrapper>
        <Input
          autoFocus={autoFocus}
          data-rc={rcLabel}
          disabled={disabled}
          placeholder={placeholder}
          ref={ref}
          readOnly={!isSearchEnabled}
          setSelectionRange={() => {}}
          showDropdownArrow={showDropdownArrow}
          inputSize={size}
          value={value}
          onKeyPress={(e) => {
            onKeyPress(e, props);
          }}
          onBlur={(e) => {
            onBlur(e, props);
            onAfterBlur(e, props);
          }}
          onFocus={(e) => {
            onFocus(e, props);
            onAfterFocus(e, props);
          }}
          {...otherProps}
          autoComplete="chrome-off"
          size={fitToValue && value ? value.length + fitToValuePadding : null}
        />
      </Wrapper>
      <IconContainer size={size}>
        {showCheckmark && isOpen && value ? <CheckIcon /> : dropdownArrow}
      </IconContainer>
      {content}
    </Container>
  );
});

Trigger.defaultProps = {
  fitToValuePadding: 0,
  onAfterBlur: () => {},
  onAfterFocus: () => {},
  onKeyPress: () => {},
};

Trigger.propTypes = {
  /** Auto focus input field. */
  autoFocus: PropTypes.bool,
  /** Additional class. */
  className: PropTypes.string,
  /** Color theme of the dropdown. */
  color: PropTypes.oneOf(colorsList),
  /** Additional content to display. */
  content: PropTypes.node,
  /** Custom dropdown arrow component. */
  dropdownArrowComponent: PropTypes.node,
  /** Dropdown trigger fits the selected value. */
  fitToValue: PropTypes.bool,
  /** Additional invisible characters to add for sizing. */
  fitToValuePadding: PropTypes.number,
  /** If there is an error. */
  hasError: PropTypes.bool,
  /** If search is enabled in dropdown. */
  isSearchEnabled: PropTypes.bool,
  /** Additional blur callback to not overridw Autocomplete onBlur. */
  onAfterBlur: PropTypes.func,
  /** Additional focus callback to not overridw Autocomplete onFocus. */
  onAfterFocus: PropTypes.func,
  /** Callback when key is pressed. */
  onKeyPress: PropTypes.func,
  /** Placeholder text to show when there is no value. */
  placeholder: PropTypes.string,
  /** Label locator string for tests. */
  rcLabel: PropTypes.string,
  /** Displays the checkmark icon when open. */
  showCheckmark: PropTypes.bool,
  /** Displays the dropdown arrow when open. */
  showDropdownArrow: PropTypes.bool,
  /** Size of the dropdown. */
  size: PropTypes.oneOf(sizesList),
  /** Value of the dropdown. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Trigger;
