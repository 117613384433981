import React from 'react';
import PropTypes from 'prop-types';
import { Footer, LeftPanelWrapper } from './styles';
import Header from './Header';

const LeftPanel = (props) => {
  const {
    children,
    dockFooter,
    footer,
    footerMobileOnly,
    header,
    headerActions,
    subHeader,
  } = props;
  return (
    <>
      <LeftPanelWrapper dockFooter={dockFooter}>
        <Header
          header={header}
          headerActions={headerActions}
          subHeader={subHeader}
        />
        {children}
      </LeftPanelWrapper>
      <Footer dockFooter={dockFooter} footerMobileOnly={footerMobileOnly}>
        {footer}
      </Footer>
    </>
  );
};

LeftPanel.defaultProps = {
  dockFooter: true,
  footerMobileOnly: true,
};

LeftPanel.propTypes = {
  /** Content to display. */
  children: PropTypes.node,
  /** Always docks footer to bottom. Otherwise footer will dock when it reaches the bottom of the modal. */
  dockFooter: PropTypes.bool,
  /** Footer content to display. */
  footer: PropTypes.node,
  /** Only shows the footer in mobile. */
  footerMobileOnly: PropTypes.bool,
  /** Header text of the panel. */
  header: PropTypes.string,
  /** Header actions content. */
  headerActions: PropTypes.node,
  /** Sub Header text of the panel. */
  subHeader: PropTypes.string,
};

export default LeftPanel;
