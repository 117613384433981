export function getInitialState(questions) {
  const answers = questions.map((question) => ({
    id: question.id,
    answers: [],
    comment: '',
    showErrors: false
  }));

  return {
    canSave: false,
    answers,
    questions,
    hasAnswered: false
  };
}

export function getStateTransferObject(state, surveyId, isPreview) {  
  return { 
    surveyId,
    answers: state.answers.map(({ id, answers, comment }) => ({
      questionId: id,
      answers: answers.map((answer) => answer?.value || answer),
      comment
    })),
    isPreview
  };
}

export const ACTIONS = {
  INIT_STATE: 'INIT_STATE',
  ADD_ANSWER: 'ADD_ANSWER',
  ADD_COMMENT: 'ADD_COMMENT',
  VALIDATE: 'VALIDATE'
};

export default function reducer(state, action) {
  switch (action.type) {

  case ACTIONS.INIT_STATE: {
    const { questions } = action.payload;

    return getInitialState(questions);
  }

  case ACTIONS.ADD_ANSWER: {
    const { id, answer } = action.payload;

    const answers = state.answers.map((details) => {
      if (details.id == id) {
        return {
          ...details,
          answers: [answer]
        };
      }

      return details;
    });

    return {
      ...state,
      answers,
      hasAnswered: true
    };
  }

  case ACTIONS.ADD_COMMENT: {
    const { id, comment } = action.payload;

    const answers = state.answers.map((details) => {
      if (details.id == id) {
        return {
          ...details,
          comment: comment
        };
      }

      return details;
    });

    return {
      ...state,
      answers
    };
  }

  case ACTIONS.VALIDATE: {
    const canSave = state.answers.every(({ answers, id }) => {
      const question = state.questions.find((question) => question.id == id);

      if (question.type === 'text' && question.canSkip) {
        return true;
      }

      return answers.length > 0;
    });

    return {
      ...state,
      answers: state.answers.map((details) => ({
        ...details,
        showErrors: !canSave
      })),
      canSave
    };
  }

  default: {
    return {};
  }
  }
}