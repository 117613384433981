import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 6L9 13H2.5l4-7zM21 13l-5-7-5 7h10zm8.5 0l-4-7-2.5 7h6.5zM8.865 15l5.225 14.595L2.5 15h6.365zm9.045 14.595L29.5 15h-6.378L17.91 29.595zM21 15l-5 15-5-15h10zM8.5 6l2 4.5L14 6H8.5zm13 4.5l2-4.5H18l3.5 4.5z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
