import React from 'react';
import { compose } from 'recompose';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import {
  FeedbackFlowPage,
  FeedbackReceivedViewFeedbackPage,
} from '../pages/feedback-received';
import { withPageViewTracking, withRouteParams } from '../../hocs';
import { Route } from 'react-router-dom';

const feedbackFlowHoc = compose(
  withPageViewTracking({
    pageTitle: 'feedback',
  }),
  withRouteParams({
    feedbackReceivedIdEncoded: true,
    workspaceId: true,
  })
);

const FeedbackFlowPageComponent = feedbackFlowHoc(FeedbackFlowPage);

export const FeedbackFlow = (
  <Route
    exact
    path={Resources.feedbackFlow.pattern}
    element={(
      <PrivateRoute>
        <FeedbackFlowPageComponent />
      </PrivateRoute>
    )}
  />
);

const feedbackReceivedViewFeedbackHoc = compose(
  withPageViewTracking({
    pageTitle: 'feedback-received/view',
  }),
  withRouteParams({
    feedbackReceivedId: true,
  })
);

const Component = feedbackReceivedViewFeedbackHoc(
  FeedbackReceivedViewFeedbackPage
);

export const FeedbackReceivedViewFeedback = (
  <Route
    exact
    path={Resources.feedbackReceivedViewFeedback.pattern}
    element={(
      <PrivateRoute>
        <Component />
      </PrivateRoute>
    )}
  />
);
