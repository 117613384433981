import styled from 'styled-components';
import ComposerTabs from '../Tabs';
import theme, { colors, media } from '@matterapp/matter-theme';
import { Container as TabsContainer } from '../Tabs/styles';

export const Container = styled.div`
  background: ${theme.colors.white};
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.08);
  z-index: 10;
  border-top: 1px solid ${colors.blacks[10]};

  ${media.M`
    border-top: none;
    border-bottom-left-radius: ${theme.borderRadius.L};
    border-bottom-right-radius: ${theme.borderRadius.L};
  `}

  ${media.M`
    border-radius: ${theme.borderRadius.L};
  `}
`;

export const ContentWrapper = styled.div`
  & > * {
    flex: 1 1 100%;
    min-width: 100%;
    max-height: 100px;
    opacity: 0;
    pointer-events: none;
    transition: visibility ${theme.transitions.times.default} 0s, opacity ${theme.transitions.times.default};

    & input {
      pointer-events: none;
    }
  }
`;

export const ContentContainer = styled.div`
  overflow: ${({ isChangingTab }) => (isChangingTab ? 'hidden' : 'visible')};
  width: 100%;

  ${TabsContainer}:focus-within + & {
    overflow: hidden;
  }
  
  & ${ContentWrapper} {
    display: flex;
    transform: translateX(${({ left }) => left});
    transition: transform ${theme.transitions.times.default};

    & > * {
      visibility: ${({ isChangingTab }) => (isChangingTab ? 'visible' : 'hidden')};
    }

    & > *:nth-child(${({ active }) => active}) {
      visibility: visible;
      max-height: 100%;
      opacity: 1;
      pointer-events: all;

      & input {
        pointer-events: all;
      }
    }
  }
`;

export const Tabs = styled(ComposerTabs)``;
