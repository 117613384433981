import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Label,
  ThumbnailContainer,
  LabelOnlyContainer,
  MINI_KUDOS_HEIGHT,
  getLabelColors,
} from './styles';
import Tooltip from '../../Tooltip';
import { useMini } from './hooks';

function Mini(props) {
  const {
    backgroundColor,
    backgroundImage,
    containerProps,
    label,
    labelProps,
    Thumbnail,
    tooltipContent
  } = useMini(props);

  return (
    <Container {...containerProps} {...getLabelColors(backgroundColor)}>
      {Thumbnail &&
        <>
          <ThumbnailContainer backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
            <Thumbnail isLocked={false} colorOnHover={false}/>
          </ThumbnailContainer>
          <Tooltip content={tooltipContent} style={{ minWidth: 'min-content' }}>
            <Label {...labelProps}>{label}</Label>
          </Tooltip>
        </>
      }
      {!Thumbnail && (
        <LabelOnlyContainer>
          <Tooltip content={tooltipContent} style={{ minWidth: 'min-content' }}>
            <Label>{label}</Label>
          </Tooltip>
        </LabelOnlyContainer>
      )}
    </Container>
  );
}

Mini.defaultProps = {
  isLocked: true,
  lockedLabel: 'Pro',
  minHeight: MINI_KUDOS_HEIGHT,
  onClick: () => null,
};

Mini.propTypes = {
  isLocked: PropTypes.bool,
  lockedLabel: PropTypes.string,
  minHeight: PropTypes.number,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default Mini;
