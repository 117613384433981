import React from 'react';
import Base from '../../../svgBase';

const DEFAULT_HEIGHT = 17;
const DEFAULT_WIDTH = 14;
const PATH = `M1.16504 17H13.0088V13.8828H6.52441V13.7812L8.43848 12.0156C11.9072 8.99219 12.8135 7.45312 12.8135 5.61719C12.8135 2.73438 10.4619 0.781249 6.8291 0.781249C3.29004 0.781249 0.922852 2.82031 0.930664 6.07812H4.59473C4.59473 4.63281 5.48535 3.79687 6.81348 3.79687C8.11816 3.79687 9.06348 4.60156 9.06348 5.92188C9.06348 7.11719 8.31348 7.92969 7.00098 9.10937L1.16504 14.2188V17Z`;

const Two = (props) => (
  <Base height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
);

export default Two;