import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Clickable } from '../Clickable/Clickable';

export const DEFAULT_IMAGE_SIZE = 88;
export const CONTAINER_HEIGHT = 72;

const getStatusStyles = (props) => {
  const { disabled, enableClick, isDragAccept, isDragReject } = props;
  if (disabled) {
    return `
      background-color: ${theme.colors.blacks[5]};
      color: ${theme.colors.blacks[20]};
      cursor: not-allowed;
    `;
  }
  if (isDragAccept) {
    return `
      background-color: ${theme.colors.blues[5]};
      color: ${theme.colors.blues[40]};
    `;
  }
  if (isDragReject) {
    return `
      background-color: ${theme.colors.reds[5]};
      color: ${theme.colors.reds[40]};
      cursor: not-allowed !important;
    `;
  }
  return `
    background-color: ${theme.colors.blacks[5]};
    cursor: ${enableClick ? 'pointer' : 'default'};
    color: ${theme.colors.blacks[40]};
  `;
};

export const Container = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${({ imagePreviewSize, multiple }) =>
    multiple ? 'auto' : `${(imagePreviewSize || DEFAULT_IMAGE_SIZE) + CONTAINER_HEIGHT}px`};
  outline: none;
  padding: ${({ multiple }) => multiple ? '0' : `${theme.spacing.single}`};
  transition: background ${theme.transitions.times.default},
    color ${theme.transitions.times.default};
  user-select: none;

  & svg path {
    fill: currentColor;
    transition: fill ${theme.transitions.times.default};
  }

  &:active, &:hover {
    background: ${theme.colors.blues[5]};
  }

  ${getStatusStyles}
`;

export const Button = styled(Clickable)`
  color: ${theme.colors.blues[50]};
  cursor: pointer;
  font-size: ${theme.fontSize.XS};
  height: 100%;
  padding: ${theme.spacing.half};
  line-height: ${theme.lineHeight.XS};
  min-height: ${theme.lineHeight.XS};
  text-transform: uppercase;

  &:hover {
    color: ${theme.colors.blues[60]};
  }

  &:active {
    color: ${theme.colors.purples[60]};
    text-decoration: underline;
  }
`;

export const ButtonSpacer = styled.div`
  background-color: ${theme.colors.blacks[40]};
  margin: 0 ${theme.spacing.half};
  min-height: ${theme.lineHeight.XS};
  height: 100%;
  width: 1px;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${theme.spacing.half};
`;

export const Input = styled.input``;

export const IconContainer = styled.div``;

const DEFAUL_ICON_SIZE = 64;

export const SpinnerIconContainer = styled.div`
  ${({ size }) => `
    height: ${size || DEFAUL_ICON_SIZE}px;
    width: ${size || DEFAUL_ICON_SIZE}px;
    @keyframes spin { 100% { transform:rotate(360deg) } }
    animation: spin 4s linear infinite;
    margin-bottom: 16px;
    svg {
      display: block;
      height: ${size || DEFAUL_ICON_SIZE}px;
      width: ${size || DEFAUL_ICON_SIZE}px;
    }
  `}
`;

export const Header = styled.div`
  font-size: ${theme.fontSize.S2};
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.lineHeight.M};
  text-transform: uppercase;
  &:active, &:hover {
    color: ${theme.colors.blues[50]};
  }
`;

export const ChangeButtonWithText = styled(Clickable)`
  font-size: ${theme.fontSize.XS};
  line-height: ${theme.lineHeight.XS};
  color: ${theme.colors.blues[60]};
  cursor: pointer;
`;

export const SubHeader = styled.div`
  font-size: ${theme.fontSize.XS};
  line-height: ${theme.lineHeight.M};

  ${Header} + & {
    margin-top: ${theme.spacing.quarter};
  }
  &:active, &:hover {
    color: ${theme.colors.blues[40]};
  }
`;

export const CropContainer = styled.div`
  max-height: 500px;
  max-width: 500px;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
width: 100%;
&:active, &:hover {
  color: ${theme.colors.blues[40]};
  background: ${theme.colors.blues[5]};
}
`;