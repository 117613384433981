import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationSystem from 'react-notification-system-redux';
import theme from '@matterapp/matter-theme';
// polyfill til we are on react 16.3
import ScrollWatcher from '../Header/ScrollWatcher';

const { Provider, Consumer } = React.createContext([]);

const headerHeight = 64;
const mobileHeaderHeight = 112;

const NotificationContainer = styled.div`
  position: ${({ hasFixedHeader }) => (hasFixedHeader ? 'absolute' : 'fixed')};
  width: 100% !important;
  top: ${({ hasFixedHeader, hasAppHeader }) =>
    hasFixedHeader || hasAppHeader
      ? `${hasAppHeader ? mobileHeaderHeight : headerHeight}px`
      : '0px'};
  left: 0 !important;
  right: 0 !important;
  will-change: top;

  z-index: 999;

  ${({ hasAppHeader }) =>
    hasAppHeader &&
    `
    transition: top .2s ease;
  `};

  ${() => theme.media.medium`
    top: ${(props) =>
    props.hasFixedHeader || props.hasAppHeader
      ? `${headerHeight}px`
      : '0px'} !important;
  `} ${({ hasFixedHeader, isScrolledPastHeader }) =>
  hasFixedHeader &&
    isScrolledPastHeader &&
    `
    position: fixed;
    top: 0 !important;
  `};

  ${({ hasFixedHeader, isScrolledPastHeader }) =>
    hasFixedHeader &&
    isScrolledPastHeader &&
    theme.media.medium`
    position: fixed;
    top: 0 !important;
  `};

  .notifications-wrapper {
    text-transform: none;

    .notifications-bc,
    .notifications-tc {
      position: absolute !important;
      width: 100% !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      margin: 0 !important;
      padding: 0 !important;

      .notification {
        position: absolute !important;
        top: 0 !important;
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        box-shadow: none !important;
        border: none !important;
        border-radius: 0 !important;
        height: auto !important;

        h4.notification-title {
          font-size: 17px !important;
          font-weight: normal !important;
          line-height: 22px !important;
          text-align: center !important;
          color: white !important;
          padding: 11px 24px !important;
          margin: 0 !important;
        }
        .notification-dismiss {
          display: none;
        }
      }
      .notification-success {
        background-color: ${() => theme.colors.blue} !important;
      }
      .notification-error {
        background-color: ${() => theme.colors.red} !important;
      }
      .notification-warning {
        background-color: ${() => theme.colors.yellow} !important;
      }
      .notification-info {
        background-color: ${() => theme.colors.green} !important;
      }
    }
  }
`;

/**  Custom wrapper when 'children' is passed to notification instead of title. */
const ItemContainer = styled.div`
  ${() => {
    return `
      font-size: ${theme.sizes.fontSizes[50]};
      font-weight: normal;
      color: ${theme.colors.white};
      padding: ${theme.sizes.padding[30]} ${theme.sizes.padding[60]};
      text-align: center;
    
      & [data-emoji] {
        margin-left: ${theme.sizes.padding[20]};
        transform: translateY(3px);
      }
    `;
  }}
`;

const ItemTitleText = styled.span`
  font-weight: bold;
  margin-right: 0;
`;

const Item = ({ title, message }) => (
  <ItemContainer>
    <ItemTitleText>{title}</ItemTitleText> {message}
  </ItemContainer>
);

Item.propTypes = {
  /** The title of the the notification. */
  title: PropTypes.node,
  /** The message of the notification. */
  message: PropTypes.node,
};

const Notifications = ({
  notifications,
  hasAppHeader,
  hasFixedHeader,
  watchAllScrolling,
}) => (
  <ScrollWatcher watchAllScrolling={watchAllScrolling}>
    {({ mobileTop, isScrolledPastHeader }) => (
      <NotificationContainer
        hasAppHeader={hasAppHeader}
        hasFixedHeader={hasFixedHeader}
        isScrolledPastHeader={isScrolledPastHeader}
        style={hasAppHeader ? { top: mobileTop + mobileHeaderHeight } : {}}
      >
        <NotificationSystem notifications={notifications} />
      </NotificationContainer>
    )}
  </ScrollWatcher>
);

Notifications.defaultProps = {
  hasFixedHeader: false,
  hasAppHeader: false,
  watchAllScrolling: false,
};

Notifications.propTypes = {
  hasFixedHeader: PropTypes.bool,
  hasAppHeader: PropTypes.bool,
  watchAllScrolling: PropTypes.bool,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
      uid: PropTypes.number.isRequired,
      message: PropTypes.string,
      dismissible: PropTypes.bool,
    })
  ).isRequired,
};

const NotificationsFromContext = (props) => (
  <Consumer>
    {(notifications) => (
      <Notifications notifications={notifications} {...props} />
    )}
  </Consumer>
);

NotificationsFromContext.Provider = Provider;
NotificationsFromContext.Consumer = Consumer;
NotificationsFromContext.Item = Item;

export default NotificationsFromContext;
