import { compose, withProps } from 'recompose';
import withRouter from 'hocs/withRouter';

const getHashValue = (hash = '') => {
  return parseInt(hash.replace('#', ''), 10) || 0;
};

/**
 * HOC for flow pages that use hashes. e.g. View Feedback Flow, Create A Loop Flow.
 * Provides the hash and hash value (minus the # symbol) usually a number that
 * maps to the current page. Using a hash for page navigation allows for easy page
 * transition animations without reloading the content on the page.
 *
 * This HOC also provides helper functions to set the hash or navigate to another page.
 */
const withData = compose(
  withRouter,
  withProps(({ history }) => {
    const { location } = history;
    return {
      hash: location.hash,
      hashValue: getHashValue(location.hash),
      clearHash: (useWindowHistory) => {
        if (useWindowHistory) {
          /** Workaround to clear hash without page scrolling to top. */
          window.history.replaceState('', document.title, window.location.pathname);
        } else {
          history.replace(location.pathName);
        }
      },
      setHash: (hashValue) => history.push(`#${hashValue}`),
      setPage: (page) => history.push(page),
    };
  })
);

export default withData;
