import React from 'react';
import { WorkRelationshipGame as Game } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import { TrackOnMount } from '../../track-on-mount';

class WorkRelationshipGame extends React.Component {
  renderTrackOnMount = () => {
    const { peerRecommendations, source } = this.props;
    if (peerRecommendations.length < 1) { return null; }
    return (
      <TrackOnMount
        eventName="workRelationshipRecommendations:viewed"
        eventParams={{
          source,
          numPeers: peerRecommendations.length,
        }}
      />
    );
  };

  renderGame = () => {
    const {
      handleWorkRelationshipPeerAccept,
      handleWorkRelationshipPeerReject,
      handleRequestFeedbackPeerAccept,
      handleRequestFeedbackPeerReject,
      handleViewRequestFeedbackMode,
      peerRecommendations,
      isWorkRelationshipPeerRecommendationsFirstPopulated,
    } = this.props;
    if (!isWorkRelationshipPeerRecommendationsFirstPopulated) { return null; }
    return (
      <Game
        peers={peerRecommendations}
        handleWorkRelationshipPeerAccept={handleWorkRelationshipPeerAccept}
        handleWorkRelationshipPeerReject={handleWorkRelationshipPeerReject}
        handleRequestFeedbackPeerAccept={handleRequestFeedbackPeerAccept}
        handleRequestFeedbackPeerReject={handleRequestFeedbackPeerReject}
        handleViewRequestFeedbackMode={handleViewRequestFeedbackMode}
      />
    );
  };

  render = () => {
    const { isPeerRecommendationsLoaded } = this.props;
    if (!isPeerRecommendationsLoaded) { return null; }
    return (
      <React.Fragment>
        {this.renderTrackOnMount()}
        {this.renderGame()}
      </React.Fragment>
    );
  };
}

WorkRelationshipGame.propTypes = {
  handleWorkRelationshipPeerAccept: PropTypes.func.isRequired,
  handleWorkRelationshipPeerReject: PropTypes.func.isRequired,
  handleRequestFeedbackPeerAccept: PropTypes.func.isRequired,
  handleRequestFeedbackPeerReject: PropTypes.func.isRequired,
  handleViewRequestFeedbackMode: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  peerRecommendations: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      isUser: PropTypes.bool.isRequired,
      fullName: PropTypes.string,
      photoUrl: PropTypes.string,
    })
  ).isRequired,
  isPeerRecommendationsLoaded: PropTypes.bool.isRequired,
  isWorkRelationshipPeerRecommendationsFirstPopulated:
    PropTypes.bool.isRequired,
};

export default WorkRelationshipGame;
