import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from './GridContainer';
import GridItem from './GridItem';

class PeerSuggestionsGrid extends React.Component {
  getPeerOnToggle = (peer) => () => {
    this.props.onPeerClick(peer);
  }

  renderListItems = () => {
    const { defaultAvatarIsGenerated, selectablePeerSuggestions } = this.props;
    return selectablePeerSuggestions.map((peerSuggestion) => {
      const {
        peer,
        isSelected,
      } = peerSuggestion;
      return (
        <GridItem
          key={peer.email}
          defaultAvatarIsGenerated={defaultAvatarIsGenerated}
          peer={peer}
          isSelected={isSelected}
          onToggle={this.getPeerOnToggle(peer)}
        />
      );
    });
  }

  render = () => (
    <GridContainer>
      {this.renderListItems()}
    </GridContainer>
  )
}

PeerSuggestionsGrid.propTypes = {
  defaultAvatarIsGenerated: PropTypes.bool,
  selectablePeerSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      peer: PropTypes.shape({
        email: PropTypes.string.isRequired,
        fullName: PropTypes.string,
        photoUrl: PropTypes.string,
      }),
      isSelected: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onPeerClick: PropTypes.func.isRequired,
};
export default PeerSuggestionsGrid;
