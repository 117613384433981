import React from 'react';
import { AppFooter as AppFooterUIC } from '@matterapp/matter-ui';
import { Resources } from '@matterapp/routing';
import { TWITTER_URL, BLOG_URL, HELP_CENTER_URL } from '../../../app-consts';

const AppFooter = () => (
  <AppFooterUIC
    twitterUrl={TWITTER_URL}
    blogUrl={BLOG_URL}
    helpCenterUrl={HELP_CENTER_URL}
    privacyUrl={Resources.legalPrivacyPolicy.url()}
    termsUrl={Resources.legalTermsOfService.url()}
  />
);

export default AppFooter;
