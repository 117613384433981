import React, { useState, useEffect } from 'react';
import { ToggleContainer, ToggleWrapper, ToggleUnit } from 'components/ToggleGroup';
import { Modal } from '@matterapp/matter-ui';
import updateTenantSubscriptions from 'graphql-queries/queries/admin/adminUpdateTenantSubscriptions';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import Toast from 'components/Toast/Toast';

const StyledModalBody = styled(Modal.Body)(({ theme }) => ({
  padding: theme.spacing(2),
  overflow: 'visible',
  '& > div:first-child': {
    overflowY: 'visible'
  },
  h5: {
    color: theme.colors.blacks[80],
    fontSize: theme.fontSize.base,
    fontWeight: theme.fontWeight.medium,
    marginBottom: '5px'
  }
}));

export default function ModifySubscriptionsModal(props) {
  const { subscriptions, isOpen, onClose, tenant } = props;
  const [enabledSubscriptions, setEnabledSubscriptions] = useState(subscriptions);
  const { id: tenantId } = tenant;

  const [canSave, updateCanSave] = useState(false);
  const [updateSubscriptions, { loading: savingSubscriptions, data }] = useMutation(updateTenantSubscriptions);

  function toggleSubscription(field) {
    if (enabledSubscriptions.includes(field)) {
      setEnabledSubscriptions(enabledSubscriptions.filter(f => f !== field));
    } else {
      setEnabledSubscriptions([...enabledSubscriptions, field]);
    }
  }

  function resetAndClose() {
    setEnabledSubscriptions(subscriptions);
    onClose();
  }

  useEffect(() => {
    if (!savingSubscriptions && data) {
      Toast.success('You successfully updated subscriptions');

      resetAndClose();
    }
  }, [savingSubscriptions, data]);

  useEffect(() => {
    setEnabledSubscriptions(subscriptions);
  }, [subscriptions]);

  useEffect(() => {
    const hasChanges = enabledSubscriptions.length !== subscriptions.length;

    updateCanSave(hasChanges);
  }, [enabledSubscriptions]);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={resetAndClose}
      size="medium"
    >
      <Modal.Header
        header="Modify Subscriptions"
        right={<Modal.Buttons.CloseIcon onClick={resetAndClose} />}
      />
      <StyledModalBody>
        <ToggleWrapper>
          <ToggleContainer>
            Surveys
            <ToggleUnit 
              checked={enabledSubscriptions.includes('survey')}
              onChange={() => toggleSubscription('survey')}
            />
          </ToggleContainer>
          <ToggleContainer>
            Hris
            <ToggleUnit 
              checked={enabledSubscriptions.includes('hris')}
              onChange={() => toggleSubscription('hris')}
            />
          </ToggleContainer>
        </ToggleWrapper>
      </StyledModalBody>
      <Modal.Footer.WithCancelSave
        canClickSave={canSave}
        onClickCancel={resetAndClose}
        onClickSave={() => {
          updateSubscriptions({
            variables: {
              subscriptions: enabledSubscriptions,
              tenantId
            }
          });
        }}
        sendIsLoading={savingSubscriptions}
      />
    </Modal>
  );
}
