import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ContinueEditingButton,
  DefaultNoteButton,
  DefaultNoteOverlayBackdrop,
  DefaultNoteOverlayContainer,
  MobileButtonSeparator,
  NavButtonContainer,
  NavButtonWrapper,
  NextButton,
  PrevButton,
  SkipButton,
} from './Base';
import {
  getNumberOfSkillSteps,
  getSkillAbilitiesStepNumber,
  getSkillNoteStepNumber,
} from '../utils';
import theme from '@matterapp/matter-theme';

export const REPLACE_TEXT = '%s';
export const DEFAULT_NOTE_MESSAGE_TITLE = 'Leaving without a custom note?';
export const DEFAULT_NOTE_MESSAGE_PROFESSIONALLY_TEXT = ` so ${REPLACE_TEXT} can grow professionally`;
export const DEFAULT_NOTE_MESSAGE = `Notes help clarify and provide actionable feedback${REPLACE_TEXT}. A default note might not help explain your feedback as well as an edited one.`;

const Heading = styled.div`
  color: ${theme.colors.blacks[70]};
  font-size: ${theme.fontSize.M};
  font-weight: ${theme.fontWeight.bold};
  line-height: ${theme.fontSize.M};
  margin: ${theme.spacing.singleAndHalf} ${theme.spacing.single}
    ${theme.spacing.half} ${theme.spacing.single};

  ${theme.media.medium`
    margin: 0 0 ${theme.spacing.half} 0;
  `}
`;

const CustomNoteMessage = styled.div`
  color: ${theme.colors.blacks[70]};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.L};
  margin: 0 ${theme.spacing.single} ${theme.spacing.single}
    ${theme.spacing.single};

  ${theme.media.medium`
    margin: 0 0 ${theme.spacing.half} 0;
  `}
`;

export default class SkillNavButtons extends React.PureComponent {
  static propTypes = {
    currentSkill: PropTypes.shape({
      actionItemSelections: PropTypes.array,
    }),
    /** The current skill index number. */
    currentSkillStep: PropTypes.number,
    /** The message to show warning users if they want to use the default message. */
    defaultNoteMessage: PropTypes.node,
    /** The default note message title. */
    defaultNoteMessageTitle: PropTypes.string,
    /** Controls if clicking next will show default message of go to next step. */
    isDefaultNoteMessageShown: PropTypes.bool,
    /** TODO: If timeline if currently loading. */
    isLoading: PropTypes.bool,
    /** If step is currently open. */
    isOpen: PropTypes.bool,
    /** Callback when continue editing button is clicked. */
    onClickContinueEditNote: PropTypes.func,
    /** Call back to go to next step in the timeline. */
    onClickNextSkillStep: PropTypes.func.isRequired,
    /** Callback to go to next skill step. */
    onClickNextTimelineStep: PropTypes.func.isRequired,
    /** Callback to go back to previous skill step. */
    onClickPrevSkillStep: PropTypes.func.isRequired,
    /** Callback to go to previous step in the timeline. */
    onClickPrevTimelineStep: PropTypes.func.isRequired,
    /** Callback to skip current step in timeline. */
    onClickSkipTimelineStep: PropTypes.func.isRequired,
    /** Callback to show default note message. */
    onClickShowDefaultNoteMessage: PropTypes.func,
    /** Callback when Use default note button is clicked. */
    onClickUseDefaultNote: PropTypes.func,
    /** Controls the displaying of the default note message. */
    showDefaultNoteMessage: PropTypes.bool,
    /** If the skill has a rating. */
    skillHasRating: PropTypes.bool,
    /** If skill has a valid note. */
    skillHasNote: PropTypes.bool,
    /** The feedback receiver's full name. */
    receiversFullName: PropTypes.string,
  };

  static defaultProps = {
    defaultNoteMessageTitle: DEFAULT_NOTE_MESSAGE_TITLE,
    onClickContinueEditNote: () => null,
    onClickShowDefaultNoteMessage: () => null,
    onClickUseDefaultNote: () => null,
    showDefaultNoteMessage: false,
  };

  /**
   * Gets generated defaultl note message if not passed in.
   * @returns { String } The generated message.
   */
  getDefaultNoteMessageText = () => {
    const { defaultNoteMessage, receiversFullName } = this.props;
    if (defaultNoteMessage) {
      return defaultNoteMessage;
    }
    if (!receiversFullName) {
      return DEFAULT_NOTE_MESSAGE.replace(REPLACE_TEXT, '');
    }
    const prefessionallyText = DEFAULT_NOTE_MESSAGE_PROFESSIONALLY_TEXT.replace(
      REPLACE_TEXT,
      receiversFullName
    );
    return DEFAULT_NOTE_MESSAGE.replace(REPLACE_TEXT, prefessionallyText);
  };

  /**
   * Determines if the next button can be clicked based on the current skill step.
   * @returns { Boolean } If the next button is clickable.
   */
  canClickNext = () => {
    const { currentSkill, currentSkillStep, skillHasNote } = this.props;
    switch (currentSkillStep) {
    case getSkillNoteStepNumber(currentSkill):
      return skillHasNote;
    case getSkillAbilitiesStepNumber():
      // Fallthrough. Users can select 0 abilities.
    default:
      return true;
    }
  };

  /**
   * @returns { Boolean } If current skill step if the first step.
   */
  isFirstStep = () => {
    return this.props.currentSkillStep === 1;
  };

  /**
   * @returns { Boolean } If current skill step if the last step.
   */
  isLastStep = () => {
    const { currentSkill, currentSkillStep } = this.props;
    return currentSkillStep === getNumberOfSkillSteps(currentSkill);
  };

  /**
   * Callback if next button is clicked to show the default note message.
   * @param { Object } e: The click event.
   * @param { Object } clickProps: Additional props if click came from the default note button.
   * @returns { void }
   */
  handleClickShowDefaultNoteMessage = (e, clickProps) => {
    const { clickFromDefaultNoteButton } = clickProps || {};
    const {
      onClickContinueEditNote,
      onClickShowDefaultNoteMessage,
      showDefaultNoteMessage,
    } = this.props;

    if (showDefaultNoteMessage && !clickFromDefaultNoteButton) {
      onClickContinueEditNote(e);
    }

    onClickShowDefaultNoteMessage(e, {
      ...this.props,
      showDefaultNoteMessage: !showDefaultNoteMessage,
    });
  };

  /**
   * Callback if use default note button is clicked.
   * @param { Object } e: The click event.
   * @returns { void }
   */
  handleClickUseDefaultNote = (e) => {
    const { onClickNextTimelineStep, onClickUseDefaultNote } = this.props;
    onClickNextTimelineStep(e, this.props);
    onClickUseDefaultNote(e, this.props);
    this.handleClickShowDefaultNoteMessage(e, {
      clickFromDefaultNoteButton: true,
    });
  };

  renderSkipButton = () => {
    return (
      <SkipButton
        disabled={!this.canClickNext()}
        onClick={this.props.onClickSkipTimelineStep}
      />
    );
  };

  renderNextButton = () => {
    const {
      isLoading,
      isDefaultNoteMessageShown,
      onClickNextSkillStep,
      onClickNextTimelineStep,
      skillHasRating,
    } = this.props;

    if (!skillHasRating && this.isFirstStep()) {
      return this.renderSkipButton();
    }

    const onClickNextStep =
      this.isLastStep() || !skillHasRating
        ? onClickNextTimelineStep
        : onClickNextSkillStep;

    const onClickNext =
      isDefaultNoteMessageShown && this.isLastStep()
        ? this.handleClickShowDefaultNoteMessage
        : onClickNextStep;

    return (
      <NextButton
        disabled={!this.canClickNext()}
        onClick={onClickNext}
        isLoading={isLoading}
      />
    );
  };

  renderPrevButton = () => {
    const onClickPrev = this.isFirstStep()
      ? this.props.onClickPrevTimelineStep
      : this.props.onClickPrevSkillStep;

    return <PrevButton onClick={onClickPrev} />;
  };

  renderUseDefaultNoteOverlay = () => {
    const {
      defaultNoteMessageTitle,
      isDefaultNoteMessageShown,
      showDefaultNoteMessage,
    } = this.props;
    return this.isLastStep() && isDefaultNoteMessageShown ? (
      <>
        <DefaultNoteOverlayContainer show={showDefaultNoteMessage}>
          <Heading>{defaultNoteMessageTitle}</Heading>
          <CustomNoteMessage>
            {this.getDefaultNoteMessageText()}
          </CustomNoteMessage>
          <NavButtonWrapper>
            <DefaultNoteButton onClick={this.handleClickUseDefaultNote} />
            <MobileButtonSeparator />
            <ContinueEditingButton
              onClick={this.handleClickShowDefaultNoteMessage}
            />
          </NavButtonWrapper>
        </DefaultNoteOverlayContainer>
        <DefaultNoteOverlayBackdrop show={showDefaultNoteMessage} />
      </>
    ) : null;
  };

  render() {
    return (
      <NavButtonContainer data-active={this.props.isOpen}>
        <NavButtonWrapper>
          {this.renderUseDefaultNoteOverlay()}
          {this.renderPrevButton()}
          <MobileButtonSeparator />
          {this.renderNextButton()}
        </NavButtonWrapper>
      </NavButtonContainer>
    );
  }
}
