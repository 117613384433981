import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import theme from '@matterapp/matter-theme';
import { Resources } from '@matterapp/routing';
import { Page, Button, Auth, Container, Text, InputCode } from '@matterapp/matter-ui';
import iconError from 'assets/icon_error.svg';
import Toast from 'components/Toast/Toast';
import { clearLocalStorage } from 'app-consts';

const InputErrorContainer = styled.div`
  color: ${theme.inputs.errorTextColor};
  font-size: 14px;
  line-height: ${theme.lineHeight.S};
  text-align: left;
  margin-top: ${theme.spacing.half};
`;

const VerifyCode = styled(InputCode).attrs(() => ({
  numberOfFields: 5,
  spaceAfterNthElement: 0,
}))``;

const ConfirmationMessage = styled.div`
  color: ${theme.colors.blacks[60]};
`;

const StyledTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.singleAndThreeQuarters};
  margin-bottom: ${theme.spacing.single};
  height: ${theme.spacing.singleAndHalf};
  justify-content: space-between;
`;

const StyledText = styled(Text)`
  font-size: ${theme.fontSize.S2};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[80]};
  font-weight: ${theme.fontWeight.bold};
`;

const FormContainer = styled.div`
  margin-bottom: ${theme.spacing.doubleAndThreeQuarters};
  text-align: center;
`;

const AuthContentFooterContent = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[60]};
  text-align: center;
  margin-top: ${theme.spacing.single};
`;

const ErrorMessage = 'The code you entered is incorrect.';
class VerifyEmailGatewayPage extends React.Component {

  constructor() {
    super();
    this.state = {
      showErrorMessage: false,
      isValid: true,
      errorMessage: null,
    };
  }

  renderSubtitle() {
    const { currentUserStatus } = this.props;
    return (
      <ConfirmationMessage>
        {'An email verification code was sent to '}
        <StyledText>{currentUserStatus.email}</StyledText>
      </ConfirmationMessage>
    );
  }

  authContentFooterLink() {
    return (
      <Button.New
        link
        style={{ marginLeft: 4 }}
        onClick={this.props.resendVerifyEmailNewAccount}
      >
        {'Resend Code'}
      </Button.New>
    );
  }

  logoutOnWrongEmail() {
    return (
      <Button.New
        link
        style={{ marginLeft: 4 }}
        onClick={this.onClickLogout}
      >
        {'Log out'}
      </Button.New>
    );
  }

  authContentFooterContent() {
    return (
      <AuthContentFooterContent>
        Wrong address?
        {this.logoutOnWrongEmail()}
        {' '} to sign in with a different email.
      </AuthContentFooterContent>
    );
  }

  renderError = () => {
    return <InputErrorContainer>{ErrorMessage}</InputErrorContainer>;
  };

  onCodeInputChange = async (code) => {
    if (code &&
      /\d{5}/.test(code)
    ) {
      try {
        const { currentUserStatus, history, verifyEmailLoginCodeMutation } = this.props;
        await verifyEmailLoginCodeMutation({
          variables: {
            email: currentUserStatus.email,
            token: code,
          }
        });
        history.push(Resources.authLoginGateway.path());
      } catch (e) {
        const { graphQLErrors } = e;
        if (graphQLErrors && graphQLErrors.length > 0) {
          Toast.error('The passcode that you entered is incorrect or has expired');
          this.setState({ showErrorMessage: true });
        }
      }
    } else {
      this.setState({ showErrorMessage: false });
    }
  }

  renderContents = () => {
    const {
      submitting,
    } = this.props;
    return (
      <FormContainer>
        <StyledTextContainer>
          <StyledText>
            Enter Your Code
          </StyledText>
          {this.state.showErrorMessage && <img src={iconError} width={16} height={16} alt='' />}
        </StyledTextContainer>
        <VerifyCode
          autofocus
          applyVerifyEmailViaCode
          disabled={submitting}
          onChange={this.onCodeInputChange}
          errorMessage={this.state.showErrorMessage ? ErrorMessage : null}
          onBlurComplete
          type="tel"
        >
          {this.state.showErrorMessage && this.renderError()}
        </VerifyCode>
      </FormContainer>
    );
  }

  onClickLogout = async () => {
    const { logout } = this.props;
    clearLocalStorage();
    await logout();
    window.location.href = Resources.landingPage.url();
  }

  renderPageContent = () => {
    const title = <span>Verify your Email</span>;
    return (
      <Auth.Base
        id={'EmailVerificationViaCode'}
        title={title}
        subtitle={this.renderSubtitle()}
        content={this.renderContents()}
        onClickMatterLogo={this.onClickLogout}
        authContentFooterText={`Check your spam folder if you can't find your code.`}
        authContentFooterLink={this.authContentFooterLink()}
        authContentFooterContent={this.authContentFooterContent()}
      />
    );
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Verify your email</title>
        </Helmet>
        <Container textAlign="center">
          <Page
            layout="AuthPage"
            content={this.renderPageContent()}
          />
        </Container>
      </React.Fragment>
    );
  }
}

VerifyEmailGatewayPage.propTypes = {
  currentUserStatus: PropTypes.object,
  history: PropTypes.object,
  submitting: PropTypes.bool,
  verifyEmailLoginCodeMutation: PropTypes.func,
  resendVerifyEmailNewAccount: PropTypes.func,
  logout: PropTypes.func,
};

export default VerifyEmailGatewayPage;
