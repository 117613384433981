import React from 'react';
import PropTypes from 'prop-types';
import omitStyled from '../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

const PillContainer = omitStyled('div', [
  'fontColor',
  'backgroundColor',
  'colorless',
])`
  display: inline-block;
  padding: 0 12px;
  border-radius: 50px;
  min-height: 20px;
  width: fit-content;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;

  &.small {
    font-size: 14px;
    height: 20px;
    line-height: 20px;
  }

  &.medium {
    font-size: 14px;
    height: 24px;
    line-height: 24px;
  }

  color: ${({ fontColor }) => fontColor};

  background-color: ${({ backgroundColor, colorless }) =>
    colorless ? theme.colors.blacks[30] : backgroundColor};
`;

const Pill = ({
  size,
  text,
  backgroundColor,
  fontColor,
  marginBottom,
  block,
  colorless,
}) => (
  <PillContainer
    className={`pill-wrapper ${size}`}
    fontColor={fontColor}
    backgroundColor={backgroundColor}
    colorless={colorless}
    style={{
      marginBottom,
      display: block ? 'block' : 'inline-block',
    }}
  >
    {text}
  </PillContainer>
);

Pill.defaultProps = {
  size: 'small',
  text: '',
  backgroundColor: '#CCC',
  fontColor: '#FFF',
  marginBottom: 0,
  block: false,
  colorless: false,
};

Pill.propTypes = {
  size: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  marginBottom: PropTypes.number,
  block: PropTypes.bool,
  colorless: PropTypes.bool,
};

export default Pill;
