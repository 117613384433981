import React  from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Avatar,
  Loader
} from '@matterapp/matter-ui';
import FilterBar from './FilterBar/FilterBar';
import KudosSentChart from './KudosSentChart';
import { dateFilters } from './consts';
import {
  MainColumnContainer,
  NonFilterContainer,
  KudosCard,
  KudosAndMembersContainer,
  RightSideColumn,
  ParticipationCard,
  RecognitionCard,
  KudosContentContainer,
  KudosCountRow,
  KudosSentIcon,
  KudosReceivedIcon,
  MembersIcon,
  ParticipationIcon,
  RecognitionIcon,
  KudosTextRow,
  TeamRecognitionTextRow,
  TeamRecognition,
  KudosCountText,
  KudosTitle,
  KudosInfoIcon,
  TopFeebackText,
  StyledAvatar,
  FeebackerContainer,
  FeedbackerName,
  FeedbackerScore,
  Divider,
  DetailsContainer,
  Spacer40,
  Spacer16,
  LeftSideColumn,
  LeftSideColumnContainer,
  KudosSentChartContainer,
  NonFilterCardForMobile,
  LoaderContainer,
  ToolTipContainer,
  MobileDividerForKudosCards,
  NoDataContainer,
  HideIcon,
  Container,
  TeamRecTitle,
  MobileDividerForParticipationCard
} from './styles';
import MembersList from './MembersList';

const renderTopFeedbackersList = (list, isFeedbackGiven=false) => {
  if (!(list?.length)) {
    return <NoDataContainer> NO DATA</NoDataContainer>;
  }
  return (
    <>
      {list.slice(0, 3).map((item, index) => {
        const { photoUrl, fullName, feedbacksGiven, feedbacksReceived } = item;
        const score = isFeedbackGiven ? feedbacksGiven : feedbacksReceived;
        return (
          <div key={index}>
            <FeebackerContainer>
              <DetailsContainer>
                <StyledAvatar>
                  <Avatar size={24} url={photoUrl} marginBottom={1} />
                </StyledAvatar>
                <FeedbackerName>{fullName}</FeedbackerName>
              </DetailsContainer>
              <FeedbackerScore>{score}</FeedbackerScore>
            </FeebackerContainer>
            {index != list.length -1 && <Divider />}
          </div>
        );
      })}
      {
        isFeedbackGiven && <Spacer40 />
      }
    </>
  );
};

function MainColumn(props) {
  const {
    workspaceAnalyticsData,
    workspaceMemberAnalyticsData,
    workspaceMemberAnalyticsLoading,
    onLoadMore,
    selectedFilter,
    xAxisMaxLabels,
    handleChangeFilter,
    isLoading,
    showSpinner,
    workspaceId
  } = props;
  const {
    totalKudosGiven,
    totalKudosReceived,
    activeMembersCount,
    participation,
    topFeedbackGivers,
    topFeedbackReceivers,
    kudosSentList,
    datePoints
  } = workspaceAnalyticsData;

  const renderKudosChart = () => {
    return (
      <KudosSentChartContainer>
        <KudosSentChart
          averages={kudosSentList}
          datePoints={datePoints}
          groupedBy={selectedFilter.groupedBy}
          xAxisMaxLabels={xAxisMaxLabels}
          startDate={selectedFilter.startDate.format('YYYY-MM-DD')}
          endDate={selectedFilter.endDate.format('YYYY-MM-DD')}
        />
      </KudosSentChartContainer>
    );
  };

  const getDigitCount = (value) => {
    if (value) {
      return value.toString().length;
    }
    return 0;
  };

  const renderKudosAndMembers = () => {
    const kudosSentLength = getDigitCount(totalKudosGiven);
    const kudosReceivedLength = getDigitCount(totalKudosReceived);
    const membersLength = getDigitCount(activeMembersCount);

    return (
      <KudosAndMembersContainer>
        <KudosCard>
          <KudosContentContainer>
            <KudosCountRow>
              <KudosCountText> {totalKudosGiven} </KudosCountText>
              <HideIcon length={kudosSentLength}> <KudosSentIcon /> </HideIcon>
            </KudosCountRow>
            <KudosTextRow>
              <KudosTitle>Kudos Sent</KudosTitle>
              <ToolTipContainer>
                <Tooltip
                  content="Total public and private Kudos given."
                  style={{ minWidth: 'min-content' }}
                >
                  <KudosInfoIcon />
                </Tooltip>
              </ToolTipContainer>
            </KudosTextRow>
          </KudosContentContainer>
        </KudosCard>

        <MobileDividerForKudosCards/>

        <KudosCard>
          <KudosContentContainer>
            <KudosCountRow>
              <KudosCountText> {totalKudosReceived} </KudosCountText>
              <HideIcon length={kudosReceivedLength}> <KudosReceivedIcon /> </HideIcon>
            </KudosCountRow>
            <KudosTextRow>
              <KudosTitle>Kudos Received</KudosTitle>
              <ToolTipContainer>
                <Tooltip
                  content="Total Kudos received by all individuals. For example, if a Kudos is given to 2 people, it counts as 2 Kudos received."
                  style={{ minWidth: 'min-content' }}
                >
                  <KudosInfoIcon />
                </Tooltip>
              </ToolTipContainer>
            </KudosTextRow>
          </KudosContentContainer>
        </KudosCard>

        <MobileDividerForKudosCards/>

        <KudosCard>
          <KudosContentContainer>
            <KudosCountRow>
              <KudosCountText> {activeMembersCount} </KudosCountText>
              <HideIcon length={membersLength}> <MembersIcon /> </HideIcon>
            </KudosCountRow>
            <KudosTextRow>
              <KudosTitle> Members </KudosTitle>
              <ToolTipContainer>
                <Tooltip
                  content="Total members with active Matter accounts."
                  style={{ minWidth: 'min-content' }}
                >
                  <KudosInfoIcon />
                </Tooltip>
              </ToolTipContainer>
            </KudosTextRow>
          </KudosContentContainer>
        </KudosCard>
      </KudosAndMembersContainer>
    );
  };

  const renderKudosCountsAndChart = () => {
    return (
      <LeftSideColumn>
        
        {renderKudosAndMembers()}
        {renderKudosChart()}
      </LeftSideColumn>
    );
  };

  const renderLeftSideColumn = () => {
    return (
      <LeftSideColumnContainer>
        {renderKudosCountsAndChart()}
        <MembersList
          workspaceMemberAnalyticsData={workspaceMemberAnalyticsData}
          workspaceMemberAnalyticsLoading={workspaceMemberAnalyticsLoading}
          onLoadMore={onLoadMore}
          showSpinner={showSpinner}
        />
      </LeftSideColumnContainer>
    );
  };

  const renderRightSideColumn = () => {
    return (
      <RightSideColumn>
        <ParticipationCard>
          <KudosContentContainer>
            <KudosCountRow>
              <KudosCountText> {participation}% </KudosCountText>
              <ParticipationIcon />
            </KudosCountRow>
            <KudosTextRow>
              <KudosTitle fontSize={17}>Participation</KudosTitle>
              <ToolTipContainer>
                <Tooltip
                  content="% of members who have given Kudos or Feedback to another member."
                  style={{ minWidth: 'min-content' }}
                >
                  <KudosInfoIcon />
                </Tooltip>
              </ToolTipContainer>
            </KudosTextRow>
          </KudosContentContainer>
        </ParticipationCard>
        <MobileDividerForParticipationCard/>
        <RecognitionCard>
          <TeamRecognition>
            <TeamRecognitionTextRow>
              <TeamRecTitle> Team Recognition </TeamRecTitle>
              <RecognitionIcon />
            </TeamRecognitionTextRow>
            <TopFeebackText> Top Feedback Givers </TopFeebackText>
            {renderTopFeedbackersList(topFeedbackGivers, true)}
            <TopFeebackText> Top Feedback Receivers </TopFeebackText>
            {renderTopFeedbackersList(topFeedbackReceivers)}
            <Spacer16 />
          </TeamRecognition>
        </RecognitionCard>
      </RightSideColumn>
    );
  };

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }
 
  return (
    <Container>
      <MainColumnContainer>
        <FilterBar
          startDate={selectedFilter.startDate}
          endDate={selectedFilter.endDate}
          value={selectedFilter.value}
          items={dateFilters}
          onChange={handleChangeFilter}
          workspaceId={workspaceId}
          groupedBy={selectedFilter.groupBy}
        />
        <NonFilterContainer>
          {renderLeftSideColumn()}
          {renderRightSideColumn()}
        </NonFilterContainer>
        {
          <NonFilterCardForMobile>
            {renderKudosAndMembers()}
            {renderKudosChart()}
            {renderRightSideColumn()}
            <MembersList
              workspaceMemberAnalyticsData={workspaceMemberAnalyticsData}
              workspaceMemberAnalyticsLoading={workspaceMemberAnalyticsLoading}
              onLoadMore={onLoadMore}
              showSpinner={showSpinner}
            />
          </NonFilterCardForMobile>
        }
      </MainColumnContainer>
    </Container>
  );
}

MainColumn.propTypes = {
  workspaceAnalyticsData: PropTypes.object,
  workspaceMemberAnalyticsData: PropTypes.array,
  workspaceMemberAnalyticsLoading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  selectedFilter: PropTypes.object,
  handleChangeFilter: PropTypes.func,
  xAxisMaxLabels: PropTypes.number,
  isLoading: PropTypes.bool,
  showSpinner: PropTypes.bool,
  workspaceId: PropTypes.string
};

export default MainColumn;
