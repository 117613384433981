import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Starburst from './Starburst';
import FlyingSuccessStars from '../Animations/FlyingSuccessStars';

const Container = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: ${theme.spacing.single};
  position: relative;

  ${theme.media.medium`
    padding: ${theme.spacing.doubleAndHalf};
  `}
`;

const Background = styled(Starburst)`
  fill: ${theme.colors.white};
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
  opacity: 0.4;
`;

const OverlayContainer = styled.div`
  z-index: 1;
`;

const TitleContainer = styled.div`
  color: ${({ textColor }) => textColor};
  font-size: ${theme.sizes.fontSizes[55]};
  font-weight: bold;
  margin: auto;
  text-align: center;
`;

const ImageContainer = styled.div`
  margin: auto;
  padding: ${theme.spacing.singleAndHalf};
  text-align: center;
`;

const ContentContainer = styled.div`
  color: ${({ textColor }) => textColor};
  font-size: ${theme.sizes.fontSizes[55]};
  margin: auto;
  text-align: center;
`;

const Stars = styled(FlyingSuccessStars)`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
`;

const Shockwave = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background: radial-gradient(
    transparent 60%,
    rgba(255, 255, 255, 0.7) 65%,
    rgba(255, 255, 255, 0.7) 66%,
    transparent 75%
  );

  background-size: 0 0;
  background-position: center;
  background-repeat: no-repeat;

  animation: shockwave 2s 0s ease;
  animation-delay: 200ms;

  @keyframes shockwave {
    0% {
      background-size: 0 0;
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      background-size: 480px 480px;
      opacity: 0;
    }
  }
`;

const SuccessContainer = (props) => {
  const {
    backgroundColor,
    children,
    className,
    content,
    image,
    showShockwave,
    showStars,
    textColor,
    title,
    hideBackground,
  } = props;
  return (
    <Container backgroundColor={backgroundColor} className={className}>
      {!hideBackground && <Background />}
      {showShockwave && <Shockwave />}
      {showStars && <Stars playAnimationOnMount />}
      <OverlayContainer>
        {title && (
          <TitleContainer textColor={textColor}>{title}</TitleContainer>
        )}
        {image && <ImageContainer>{image}</ImageContainer>}
        <ContentContainer textColor={textColor}>
          {content || children}
        </ContentContainer>
      </OverlayContainer>
    </Container>
  );
};

export default SuccessContainer;

SuccessContainer.propTypes = {
  /** The background color of the success container. */
  backgroundColor: PropTypes.string,
  /** Additional content. */
  children: PropTypes.node,
  /** Additional style class. */
  className: PropTypes.string,
  /** Additional content shortcut. Overrides children */
  content: PropTypes.node,
  /** Hides the background starburst. */
  hideBackground: PropTypes.bool,
  /** Image to display in success container. */
  image: PropTypes.node,
  /** Show the shockwave animation in the background. */
  showShockwave: PropTypes.bool,
  /** Show the star animation in the background. */
  showStars: PropTypes.bool,
  /** The text color of the success container. */
  textColor: PropTypes.string,
  /** The title of the success container. */
  title: PropTypes.node,
};

SuccessContainer.defaultProps = {
  backgroundColor: theme.colors.yellows[20],
  hideBackground: false,
  textColor: theme.colors.purple,
};
