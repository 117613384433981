import { SubmissionError } from 'redux-form';
import { logger } from '../libs';

const handleSubmitValidation = ({ fieldName, submit }) => {
  return submit().catch((error) => {
    if (error) {
      logger.error({
        submissionError: error,
      });
      if (fieldName && error.graphQLErrors && error.graphQLErrors[0]) {
        throw new SubmissionError({
          [fieldName]: error.graphQLErrors[0].message,
        });
      }
    }
    throw error;
  });
};

export default handleSubmitValidation;
