import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const DeleteNoteContainer = styled.div`
  color: ${theme.colors.blacks[80]};
  line-height: ${theme.lineHeight.L};
  font-size: ${theme.fontSize.S};
`;

export const RedText = styled.span`
  color: ${theme.colors.reds[60]};
`;

export const DeleteNoteWarningContainer = styled(DeleteNoteContainer)`
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: ${theme.spacing.double};
`;

export const WorkspaceNameHeader = styled.div`
  display: inline-block;
  word-break: break-word;
`;
