import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PurpleTextArea } from '../TextArea';
import Avatar from '../Avatar';

class TextAreaWithAvatar extends React.Component {
  static BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 24px;
  `;

  static BodyInnerContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
  `;

  static AvatarContainer = styled.div`
    margin-right: 16px;
  `;

  static TextArea = (props) => <PurpleTextArea {...props} />;

  render() {
    return (
      <TextAreaWithAvatar.BodyContainer style={this.props.style}>
        <TextAreaWithAvatar.AvatarContainer>
          <Avatar size={44} url={this.props.avatarUrl} />
        </TextAreaWithAvatar.AvatarContainer>
        <TextAreaWithAvatar.BodyInnerContainer>
          {this.props.children}
        </TextAreaWithAvatar.BodyInnerContainer>
      </TextAreaWithAvatar.BodyContainer>
    );
  }
}

TextAreaWithAvatar.defaultProps = {
  style: {},
};

TextAreaWithAvatar.propTypes = {
  children: PropTypes.node.isRequired,
  avatarUrl: PropTypes.string,
  style: PropTypes.object,
};

export default TextAreaWithAvatar;
