import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { useQuery } from '@apollo/client';
import { AppLayout } from 'modules';
import getTenantPulseQuestions from 'graphql-queries/queries/pulse/getTenantPulseQuestions';
import getTenantPulseCategories from 'graphql-queries/queries/pulse/getTenantPulseCategories';
import { 
  Box,
  Typography,
  Divider
} from '@mui/material';
import { Loader } from '@matterapp/matter-ui';
import QuestionList from './QuestionList';
import BreadCrumps from 'components/BreadCrumbs/BreadCrumbs';
import { Resources } from '@matterapp/routing';
import styled from 'styled-components';

const Layout = styled(AppLayout)({
  '.layout-content-container': {
    backgroundColor: 'white'
  }
});

const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: '950px'
}));

const TopWrapper = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(6)} 0`,
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    marginTop: 0,
    marginBottom: theme.spacing(2)
  }
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: `0 ${theme.spacing(2)}`
  }
}));

export default function QuestionLibraryPage() {
  const { isLoadingCurrentWorkspace, currentTenant, currentWorkspaceId: workspaceId } = useContext(UserContext);

  const { data, loading: loadingQuestions } = useQuery(getTenantPulseQuestions, {
    variables: {
      tenantId: currentTenant?.id
    },
    skip: !currentTenant
  });

  const { data: categoryData, loading: loadingCategories } = useQuery(getTenantPulseCategories, {
    variables: {
      tenantId: currentTenant?.id
    },
    skip: !currentTenant
  });

  const { tenantPulseQuestions: questions = [] } = data || {};
  const { tenantPulseCategories: categories = [] } = categoryData || {};

  const isLoading = isLoadingCurrentWorkspace || loadingQuestions || loadingCategories;

  return (
    <Layout
      layout={AppLayout.Layouts.TwoColumnFixedLeft}
      hideFilterColumn
      hideFooter
    >
      <Wrapper>
        <BreadCrumps 
          hideOnMobile
          crumps={[
            {
              label: 'Pulse Survey',
              href: Resources.pulseDashboard.path({ workspaceId })
            },
            {
              label: 'Question Library',
              href: Resources.pulseQuestionLibrary.path({ workspaceId })
            }
          ]}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <TopWrapper>
              <Typography variant='h4' sx={{ mb: '6px' }}>Question Library</Typography>
              <Typography variant='body1'>
                Customize your pulse survey question library to meet your organization’s needs.
              </Typography>
            </TopWrapper>
            <StyledDivider sx={{ borderColor: 'blacks.30' }}/>
            <QuestionList 
              questions={questions} 
              categories={categories} 
              tenantId={currentTenant?.id} 
              workspaceId={workspaceId}
            />
          </>
        )}
      </Wrapper>
    </Layout>
  );
}
