"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.buttonModes = exports.buttons = void 0;

var _colors = require("../colors");

const buttons = {
  borderRadius: '8px',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: _colors.colors.blacks[100],
  fontSize: '16px',
  fontWeight: '700',
  padding: '0 24px',
  textTransform: 'capitalize',
  height: {
    L: '56px',
    M: '48px',
    S: '32px'
  }
};
exports.buttons = buttons;
const buttonModes = {
  xsmall: {
    height: '32px'
  },
  small: {
    height: '48px'
  },
  medium: {
    height: '56px'
  },
  basic: {
    color: _colors.colors.blacks[100],
    background: _colors.colors.white,
    borderColor: _colors.colors.blacks[100],
    padding: '0 16px',
    hover: {
      color: _colors.colors.blacks[100],
      borderColor: _colors.colors.blacks[100]
    },
    active: {
      color: _colors.colors.purple,
      borderColor: _colors.colors.purple
    },
    disabled: {
      color: _colors.colors.blacks[30],
      background: _colors.colors.blacks[5],
      borderColor: _colors.colors.blacks[30]
    }
  },
  pill: {
    color: _colors.colors.blues[60],
    background: _colors.colors.white,
    borderColor: _colors.colors.blues[20],
    boxShadowColor: 'rgba(52, 55, 153, .2)',
    // FIXME: use polished module to generate these colors?
    borderRadius: '100px',
    hover: {
      color: _colors.colors.blues[60],
      borderColor: _colors.colors.blue
    },
    active: {
      color: 'white',
      background: _colors.colors.blues[60],
      borderColor: _colors.colors.blues[60],
      boxShadowColor: _colors.colors.blue
    },
    checked: {
      color: 'white',
      borderColor: _colors.colors.blue,
      background: _colors.colors.blue,
      boxShadowColor: 'rgba(69, 132, 255, .25)'
    },
    disabled: {
      color: _colors.colors.blacks[30],
      background: _colors.colors.blacks[5],
      borderColor: _colors.colors.blacks[30]
    }
  },
  notification: {
    background: _colors.colors.orange,
    backgroundHover: _colors.colors.oranges[40],
    backgroundActive: _colors.colors.oranges[60],
    boxShadowColor: 'rgba(237, 86, 50, 0.25)',
    textShadowColor: '#ED5632',
    hasFuzzyShadow: true
  },
  primary: {
    color: _colors.colors.white,
    background: _colors.colors.purple,
    backgroundActive: _colors.colors.purples[60],
    borderColor: 'transparent'
  },
  disabled: {
    color: _colors.colors.blacks[30],
    background: _colors.colors.blacks[5],
    borderColor: _colors.colors.blacks[30]
  },
  default: {
    color: _colors.colors.black,
    background: '#E0E1E2',
    borderColor: 'transparent',
    boxShadowColor: 'rgba(0, 0, 0, .05)'
  }
};
exports.buttonModes = buttonModes;
var _default = buttons;
exports.default = _default;