import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@matterapp/matter-theme';
import Container from './Container';
import {
  IconCircleDashed,
} from '../../Icon';


const EmptyContainer = styled(Container)`
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  color: ${colors.blacks[50]};
  font-weight: 500;
  svg {
    circle {
      stroke: ${colors.blacks[50]};
    }
    path {
      fill: ${colors.blacks[50]};
    }
  }

  &:hover {
    ${({ disabled }) => disabled ? '' : `
      color: ${colors.blues[60]};
      background: ${colors.blacks[5]};

      svg {
        circle {
          stroke: ${colors.blues[60]};
        }
        path {
          fill: ${colors.blues[60]};
        }
    }`};
  }

  &:active {
    ${({ disabled }) => disabled ? '' : `
      color: ${colors.purples[60]};
      background: ${colors.blacks[5]};

      svg {
        circle {
          stroke:${colors.purples[60]};
        }
        path {
          fill: ${colors.purples[60]};
        }
      }
    }`};
  }
`;

const IconContainer = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

const Empty = ({
  disabled,
  onClick,
}) => (
  <EmptyContainer disabled={disabled} onClick={disabled ? () => {} : onClick}>
    <IconContainer>
      <IconCircleDashed />
    </IconContainer>
    <span>
      Tag a Skill
    </span>
  </EmptyContainer>
);

Empty.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Empty;
