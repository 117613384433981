import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const EmptyListRoot = styled.div`
  background: white;
  ${() => theme.media.medium`
    border-radius: 0 0 12px 12px;
  `}
  padding: 24px 24px 32px 24px;
  color: ${() => theme.colors.blacks[50]};
`;

const EmptyListHeader = styled.h2`
  font-style: normal;
  font-weight: bold;
  line-height: 32px;
  font-size: 22px;
  margin-bottom: 8px;
`;

export default () => (
  <EmptyListRoot>
    <EmptyListHeader>
      0 peers <br />
      (It takes 2 to tango 💃🕺)
    </EmptyListHeader>
  </EmptyListRoot>
);
