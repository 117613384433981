import styled from 'styled-components';
import { Clickable } from '../../../Clickable/Clickable';
import Icon from '../../../Icon';
import omitStyled from '../../../../libs/omitStyled';
import { AVATAR_SIZE, LARGE_NAV_NORMAL_SIZE_BREAKPOINT_NORMAL } from '../styles';
import {
  border,
  colors,
  font,
  media,
  spacing,
  transitions,
  zIndex,
} from '@matterapp/matter-theme';

const MENU_ITEM_TEXT_MAX_WIDTH = 150;

export const CurrentSpaceHeader = styled.div`
  color: ${colors.blacks[80]};
  flex: 0 0 auto;
  font-size: ${font.size.base};
  font-weight: ${font.weight.medium};
  line-height: 1;
  max-width: calc(100vw - 180px);
  overflow: hidden;
  text-align: left;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;

  div + & {
    margin-left: ${spacing.threeQuarters};
  }

  & + svg {
    min-width: ${spacing.single};
    max-width: ${spacing.single};
    margin: 0 0 0 ${spacing.quarter};
  }

  ${media.XS`
    max-width: ${MENU_ITEM_TEXT_MAX_WIDTH}px;
  `}

  ${media.L`
    font-size: ${font.size.XS};
    max-width: calc(6vw - ${spacing.quarter});
  `}

  ${media.breakpoint(LARGE_NAV_NORMAL_SIZE_BREAKPOINT_NORMAL)`
    font-size: ${font.size.base};
    max-width: calc(100vw - 1150px);
  `}

  ${media.XL`
    font-size: ${font.size.base};
    max-width: ${MENU_ITEM_TEXT_MAX_WIDTH - 10}px;
  `}
`;

export const AvatarContainer = styled.div`
  align-items: center;
  cursor: ${({ canClick }) => canClick ? 'pointer' : 'default'};
  display: flex;
  min-width: ${AVATAR_SIZE}px;
  width: 'auto';

  & > svg:last-child path {
    fill: ${colors.blacks[80]};
    stroke: ${colors.blacks[80]};
  }

  &:hover {
    > ${CurrentSpaceHeader} {
      color: ${colors.purples[50]};
    }
    > svg:last-child path {
      fill: ${colors.purples[50]};
      stroke: ${colors.purples[50]};
    }
  }

  ${media.L`
    width: ${({ fluid }) => fluid ? '100%' : 'auto'};
  `}
`;

export const CheckIcon = styled(Icon).attrs(() => ({
  name: 'checkmark'
}))``;

export const DropdownIcon = styled(Icon).attrs(() => ({
  name: 'karotDownSmall'
}))``;

export const MENU_CONTAINER_HEIGHT = 56;

export const MenuContainer = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.blacks[10]};
  border-radius: 0;
  margin: -1px 0 0 -1px;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 300px;
  z-index: ${zIndex.modal};
  transition: all ${transitions.times.default}, max-height ${transitions.times.triple} 0.05s;

  ${({ show }) => `
    max-height: ${show ? '100vh' : `${MENU_CONTAINER_HEIGHT}px`};
    opacity: ${show ? 1 : 0};
    overflow-y: ${show ? 'auto' : 'hidden'};
    pointer-events: ${show ? 'all' : 'none'};
  `}

  ${({ show }) => media.L`
    border-radius: ${border.radius.S};
    top: ${spacing.half};
    left: ${spacing.half};
    margin: 0 0 0 -1px;
    max-height: ${show ? `calc(100vh - ${spacing.single})` : `${MENU_CONTAINER_HEIGHT}px`};
    width: calc(100% - ${spacing.single});
  `}
`;

export const MenuOverlay = styled.div`
  ${({ show }) => `
    background: ${show ? colors.black : 'transparent'};
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: ${show ? 'all' : 'none'};
    z-index: ${zIndex.modalOverlay};
    transition: all ${transitions.times.default};
  `}
`;

export const MenuItemContentContainer = styled(CurrentSpaceHeader)`
  max-width: 100% !important;
  color: ${({ actionItem }) => actionItem ? colors.purples[30] : colors.blacks[70]};
  flex: 1 1 auto;
`;

const getMenuItemHoverColor = ({ actionItem, isActive }) => {
  if (actionItem) {
    return colors.purples[1];
  } else if (isActive) {
    return colors.white;
  }
  return colors.blacks[5];
};

const getMenuItemActiveColor = ({ actionItem, isActive }) => {
  if (actionItem) {
    return colors.purples[5];
  } else if (isActive) {
    return colors.white;
  }
  return colors.purples[1];
};

export const MenuItemContainer = omitStyled(Clickable, ['actionItem', 'isActive', 'showZeroWorkspaces'])`
  align-items: center;
  background: ${colors.white};
  color: ${({ actionItem }) => actionItem ? colors.purples[30] : colors.blacks[70]};
  display: flex;
  padding: ${spacing.single} ${spacing.singleAndQuarter};
  text-decoration: none !important;
  min-height: ${spacing.tripleAndHalf};
  width: 100%;
  
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.blacks[10]};
  }

  &:hover {
    background: ${getMenuItemHoverColor};
  }

  &:active {
    background: ${getMenuItemActiveColor};
  }

  ${media.L`
    padding: ${spacing.single};
  `}
`;

export const CreateWorkspaceLabel = styled.div`
  align-items: center;
  display: flex;

  & svg {
    height: ${spacing.threeQuarters};
    width: ${spacing.threeQuarters};
    margin-left: ${spacing.half};

    path {
      fill: ${colors.purples[30]};
    }
  }
`;

export const CreateIcon = styled(Icon).attrs(() => ({
  name: 'addv2'
}))``;