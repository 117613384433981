import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { qualitiesHeight } from './consts';
import { ButtonNew } from '../Button';
import theme from '@matterapp/matter-theme';

const DetailsButton = styled(ButtonNew).attrs({
  size: 'small',
})`
  width: 100%;
  flex-grow: 1;
  height: ${qualitiesHeight};
  line-height: ${qualitiesHeight};

  font-weight: normal;
  border-radius: ${() => theme.sizes.borderRadius[80]};
  color: ${() => theme.colors.blues[50]};
  background: ${() => theme.colors.blacks[5]};
  transition: color 150ms ease;
  border: none;
  margin: 0;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    color: ${() => theme.colors.purples[50]};
  }
`;

const MoreDetails = ({ expanded, onClickDetails }) => {
  if (!expanded) {
    return (
      <DetailsButton onClick={onClickDetails}>See the details</DetailsButton>
    );
  }
  return null;
};

MoreDetails.defaultProps = {
  expanded: false,
};

MoreDetails.propTypes = {
  onClickDetails: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
};

export default MoreDetails;
