import React from 'react';
import PropTypes from 'prop-types';
import {
  MobileButtonSeparator,
  NavButtonContainer,
  NavButtonWrapper,
  NextButton,
  PrevButton,
  SubmitButton,
} from './Base';

export default class TimelineNavButttons extends React.PureComponent {
  static propTypes = {
    /** If step is the last step. */
    isLastStep: PropTypes.bool,
    /** If step is qualities step. */
    isQualitiesStep: PropTypes.bool,
    /** Callback when next button is clicked. */
    onClickNext: PropTypes.func.isRequired,
    /** Callback when next submit button is clicked. */
    onClickSubmit: PropTypes.func.isRequired,
    canClickNext: PropTypes.bool,
    onClickPrev: PropTypes.func,
    hideOnQualitiesStep: PropTypes.bool,
  };

  render() {
    const {
      canClickNext,
      isLastStep,
      isQualitiesStep,
      onClickNext,
      onClickPrev,
      onClickSubmit,
      hideOnQualitiesStep,
    } = this.props;
    if (isQualitiesStep && hideOnQualitiesStep) {
      return null;
    }
    return (
      <NavButtonContainer isLastStep={isLastStep} isQualitiesStep={isQualitiesStep}>
        <NavButtonWrapper>
          <PrevButton onClick={onClickPrev} />
          <MobileButtonSeparator />
          {isLastStep ? (
            <SubmitButton disabled={!canClickNext} onClick={onClickSubmit} />
          ) : (
            <NextButton disabled={!canClickNext} onClick={onClickNext} />
          )}
        </NavButtonWrapper>
      </NavButtonContainer>
    );
  }
}
