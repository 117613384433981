import React, { useState, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import { SKILL_DETAILS_QUERY } from 'graphql-queries/queries';
import { AppLayout } from 'modules';
import LoadingPanel from 'components/LoadingPanel';
import ActionModals from '../../ActionModals';
import RecurringList from './RecurringList';
import RecurringSideColumn from './RecurringSideColumn';
import { useSettingsPanelState } from 'routes/pages/workspaces/hooks/shared';
import getMembers from 'graphql-queries/queries/workspace/getMembers';
import {
  useActionModals,
  useActionModalSelector,
  useRecurringModal,
} from '../../../hooks/WorkspaceActionModalHooks';
import { useWorkspaceFeedQuery } from '../../WorkspacesActivityFeed/hooks';
import getMembersWithRecurring from 'graphql-queries/queries/workspace/getMembersWithRecurring';
import OutOfKudosModal from 'modules/workspaces/shared/OutOfKudosModal/OutOfKudosModal';

const WorkspacesRecurringPage = (props) => {
  const { 
    currentWorkspace: workspace, 
    currentWorkspaceId: workspaceId,
    currentUser,
    isLoadingCurrentWorkspace,
    currentTenant
  } = useContext(UserContext);
  const [isOutOfKudosModalOpen, setIsOutOfKudosModalOpen] = useState(false);

  const { data: membersData, loading: isLoadingMembers } = useQuery(getMembers, {
    variables: { workspaceId, limit: 50 },
    skip: !workspaceId
  });

  const { workspaceMembers: { members: acceptedMembers } } = membersData || { workspaceMembers: { members: [] } };
  const { pickedSkillsList, selectedPeer, initUserSkillsList, setDefaultMessage, onChangeSelectedAdvisor } = props;
  const { resetFeed } = useWorkspaceFeedQuery();
  const modalProps = useActionModals();
  const actionModalSelectorProps = useActionModalSelector({
    ...modalProps,
    pickedSkillsList,
    selectedPeer,
    initUserSkillsList,
    setDefaultMessage,
    onChangeSelectedAdvisor,
    currentUser
  });

  const { id, name, isAdmin, isOwner } = workspace || {};

  const { loading: loadingReccuring, data: reccuringData } = useQuery(getMembersWithRecurring, {
    variables: { workspaceId },
    skip: !workspaceId,
  });

  const { membersWithRecurring = [] } = reccuringData || {};

  const {
    footer,
    workspaceSideColumnProps
  } = useSettingsPanelState(workspace);
  const recurringModalProps = useRecurringModal({ 
    workspaceId: id, 
    members: acceptedMembers, 
    membersWithRecurring, 
    onChangeSelectedAdvisor, 
    currentUser,
    workspace
  });
  const { sortedRecurringList, handleSelectMember } = recurringModalProps;
  const isListLoading = isLoadingCurrentWorkspace || loadingReccuring || isLoadingMembers || !workspace;
  const pageTitle = `${name} Workspace`;

  return (
    <>
      <AppLayout
        footer={footer}
        layout={AppLayout.Layouts.Main}
        pageTitle={pageTitle}
        mainColumn={
          <>
            {isListLoading 
              ? <LoadingPanel /> 
              : <RecurringList
                recurringList={sortedRecurringList}
                hasAcceptedMembers={acceptedMembers.length > 1}
                actionModalSelectorProps={actionModalSelectorProps}
                handleSelectMember={handleSelectMember}
                workspace={workspace}
                setIsOutOfKudosModalOpen={setIsOutOfKudosModalOpen}
              />
            }
          </>
        }
        mobileProps={{
          showSideColumn: false,
        }}
        sideColumn={
          <RecurringSideColumn
            {...props}
            {...modalProps}
            {...workspaceSideColumnProps}
            recurringLength={sortedRecurringList.length}
            workspace={workspace}
            currentUser={currentUser}
            workspaceMembers={acceptedMembers}
          />
        }
      />
      <ActionModals 
        {...props}
        {...modalProps}
        {...recurringModalProps}
        resetFeed={resetFeed}
        workspace={workspace}
        tenant={currentTenant}
        currentUser={currentUser}
        actionModalSelector={actionModalSelectorProps.actionModalSelector}
        setIsOutOfKudosModalOpen={setIsOutOfKudosModalOpen}
      />
      <OutOfKudosModal 
        isOpen={isOutOfKudosModalOpen} 
        onClose={() => setIsOutOfKudosModalOpen(false)} 
        isAdminMember={isAdmin || isOwner}
      />
    </>
  );
};

WorkspacesRecurringPage.propTypes = {
  selectedPeer: PropTypes.shape({
    userId: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    photoUrl: PropTypes.string,
  }),
  initUserSkillsList: PropTypes.func,
  setDefaultMessage: PropTypes.func,
  onChangeSelectedAdvisor: PropTypes.func,
  pickedSkillsList: propType(SKILL_DETAILS_QUERY).isRequired
};

export default WorkspacesRecurringPage;
