import PropTypes from 'prop-types';
import SubmitVerificationCodePage from '../components/pages/SubmitVerificationCodePage';

const SubmitVerificationCodePageContainer =  SubmitVerificationCodePage;

SubmitVerificationCodePageContainer.propTypes = {
  nextStepPath: PropTypes.string.isRequired,
  fallbackPath: PropTypes.string.isRequired,
};

export default SubmitVerificationCodePageContainer;
