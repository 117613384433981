import React from 'react';
import Base, { Group, Path } from '../../Image';
import * as C from './classNames';

const Image = (props) => {
  return (
    <Base {...props}>
      <Path
        className={C.CIRCLE.LEFT_1}
        fill="#A2CCFF"
        d="M60.5,80.5c1,0,1.7-0.8,1.7-1.7c0-1-0.8-1.7-1.7-1.7c-1,0-1.7,0.8-1.7,1.7C58.8,79.7,59.6,80.5,60.5,80.5z"
      />
      <Path
        className={C.CIRCLE.LEFT_2}
        fill="#A2CCFF"
        d="M55.3,97.7c1.9,0,3.5-1.5,3.5-3.5c0-1.9-1.6-3.5-3.5-3.5c-1.9,0-3.5,1.5-3.5,3.5C51.9,96.2,53.4,97.7,55.3,97.7z"
      />
      <Path
        className={C.CIRCLE.LEFT_3}
        fill="#A2CCFF"
        d="M71.6,97c1,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7c-1,0-1.7,0.8-1.7,1.7C69.9,96.2,70.7,97,71.6,97z"
      />
      <Path
        className={C.CIRCLE.BOTTOM}
        fill="#A2CCFF"
        d="M131.3,97c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8c-2.1,0-3.8,1.7-3.8,3.8S129.2,97,131.3,97z"
      />
      <Path
        className={C.CIRCLE.RIGHT}
        fill="#A2CCFF"
        d="M191.3,41.1c1.9,0,3.5-1.5,3.5-3.5c0-1.9-1.6-3.5-3.5-3.5c-1.9,0-3.5,1.5-3.5,3.5C187.8,39.6,189.4,41.1,191.3,41.1z"
      />
      <Path
        className={C.CIRCLE.TOP_1}
        fill="#A2CCFF"
        d="M111.1,43.4c-0.3,1.9,0.9,3.7,2.8,4c1.9,0.3,3.7-0.9,4-2.8c0.3-1.9-0.9-3.7-2.8-4C113.3,40.3,111.5,41.5,111.1,43.4z"
      />
      <Path
        className={C.CIRCLE.TOP_2}
        fill="#A2CCFF"
        d="M123.3,55.5c-0.2,0.9,0.5,1.8,1.4,2c0.9,0.2,1.8-0.5,2-1.4c0.2-0.9-0.5-1.8-1.4-2C124.3,53.9,123.4,54.5,123.3,55.5z"
      />
      <Group className={C.JOYSTICK_1.GROUP}>
        <Group className={C.JOYSTICK_1.STICK}>
          <Path
            fill="#D1D2D4"
            d="M87.2,97.1c0,1,1.1,1.8,2.5,1.8c1.4,0,2.5-0.8,2.5-1.8V65.8h-5V97.1z"
          />
          <Path
            fill="#C4C4C4"
            d="M91,94.3c0,0.8,0.3,0.7,0.6,0.7c0.3,0,0.6,0.2,0.6-0.7V66.6H91L91,94.3L91,94.3z"
          />
          <Path
            fill="#FF4369"
            d="M89.7,67.4c6,0,10.9-4.9,10.9-10.9s-4.9-10.9-10.9-10.9c-6,0-10.9,4.9-10.9,10.9S83.7,67.4,89.7,67.4z"
          />
          <Path
            fill="#EB2E49"
            d="M96.4,47.9c0.9,2.1,3.1,8.7-1.9,14.1c-4,4.3-10.6,2.1-13.6,0.8c2,2.8,5.2,4.6,8.9,4.6c6,0,10.9-4.9,10.9-10.9C100.6,53,99,49.9,96.4,47.9z"
          />
          <Path
            fill="#FF6588"
            d="M84,48c1.5-1,3.2-1,3.9-0.1c0.7,1,0,2.5-1.5,3.5s-3.2,1-3.9,0.1C81.9,50.6,82.6,49,84,48z"
          />
          <Path
            fill="#2E2E33"
            d="M92,55.1c0.4-1.1,1.4-1.9,2.6-1.9c1.2,0,2.2,0.8,2.6,1.9c0.1,0.3-0.1,0.5-0.3,0.6c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.7-0.9-1.2-1.7-1.2c-0.8,0-1.4,0.5-1.7,1.2c-0.1,0.3-0.4,0.4-0.6,0.3C92.1,55.7,91.9,55.4,92,55.1z"
          />
          <Path
            fill="#2E2E33"
            d="M94.1,59.1c0.3-0.1,0.5,0.1,0.6,0.3c0.5,1.5,1.9,2.5,3.5,2.5c0.3,0,0.7-0.1,1-0.1c-0.2,0.4-0.5,0.8-0.7,1.1c-0.1,0-0.2,0-0.3,0c-2,0-3.8-1.3-4.4-3.2C93.7,59.5,93.9,59.2,94.1,59.1z"
          />
        </Group>
        <Group className={C.JOYSTICK_1.BUTTON}>
          <Path
            fill="#FF4369"
            d="M108.7,99.1h4.5c1.7,0,3,1.3,3,3v1.8h-10.5v-1.8C105.7,100.5,107.1,99.1,108.7,99.1z"
          />
          <Path
            fill="#EB2E49"
            d="M111,99.1h2.2c1.7,0,3,1.3,3,3v1.8H111V99.1z"
          />
          <Path
            fill="#FF6588"
            d="M107.6,104v-4.8c-1,0-1.8,0.8-1.8,1.8v3H107.6z"
          />
        </Group>
        <Path
          fill="#4F52B1"
          d="M58.3,104H121c1.7,0,3,1.3,3,3v12.7c0,1.7-1.3,3-3,3H58.3c-1.7,0-3-1.3-3-3V107C55.3,105.3,56.7,104,58.3,104z"
        />
        <Path
          fill="#4F52B1"
          d="M58.3,104h0.6v18.7h-0.6c-1.7,0-3-1.3-3-3V107C55.3,105.3,56.7,104,58.3,104z"
        />
        <Path
          fill="#343799"
          d="M92.8,104H121c1.7,0,3,1.3,3,3v12.7c0,1.7-1.3,3-3,3H92.8c-1.7,0-3-1.3-3-3V107C89.8,105.3,91.2,104,92.8,104z"
        />
        <Path
          fill="#7E81C8"
          d="M124,105.9H55.3c0-1.1,0.9-1.9,1.9-1.9h64.9C123.2,104,124,104.8,124,105.9z"
        />
        <Path
          fill="#4F52B1"
          d="M83.6,100.7h12.1c1.7,0,3,1.3,3,3v0.3H80.6v-0.3C80.6,102,82,100.7,83.6,100.7z"
        />
        <Path
          fill="#343799"
          d="M94.2,100.7h1.6c1.7,0,3,1.3,3,3v0.3h-4.6V100.7z"
        />
        <Path
          fill="#7E81C8"
          d="M86.7,97.4L86.7,97.4c-1.7,0-3.1,1.3-3.1,3v0.3h3.1V97.4z"
        />
        <Path
          fill="#4F52B1"
          d="M86.5,97.4h6.3c1.7,0,3,1.3,3,3v0.3H83.5v-0.3C83.5,98.7,84.9,97.4,86.5,97.4z"
        />
        <Path
          fill="#343799"
          d="M92.7,97.4L92.7,97.4c1.7,0,3.1,1.3,3.1,3v0.3h-3.1V97.4z"
        />
        <Path
          fill="#4F52B1"
          d="M88.7,94h2c1.7,0,3,1.3,3,3v0.3h-8V97C85.7,95.4,87,94,88.7,94z"
        />
        <Path fill="#343799" d="M91.7,97.3V94c1.1,0,2,0.9,2,2v1.3H91.7z" />
      </Group>
      <Group className={C.JOYSTICK_2.GROUP}>
        <Group className={C.JOYSTICK_2.STICK}>
          <Path
            fill="#D1D2D4"
            d="M175.9,94c0.3,0.9-0.4,2-1.7,2.5c-1.3,0.5-2.6,0.1-3-0.8l-10.8-29.4l4.7-1.7L175.9,94z"
          />
          <Path
            fill="#C4C4C4"
            d="M175.6,93.1c0.3,0.8,0,0.7-0.3,0.8c-0.3,0.1-0.5,0.4-0.8-0.4L165,67.4l1.1-0.4L175.6,93.1z"
          />
          <Path
            fill="#FF4369"
            d="M159.5,67.7c6,0,10.9-4.9,10.9-10.9s-4.9-10.9-10.9-10.9c-6,0-10.9,4.9-10.9,10.9S153.5,67.7,159.5,67.7z"
          />
          <Path
            fill="#2E2E33"
            d="M152.5,57.9c0.2,0,0.4-0.2,0.4-0.4c0.1-0.8,0.6-1.4,1.4-1.5c0.7-0.2,1.5,0.2,1.9,0.8c0.1,0.2,0.4,0.3,0.7,0.2c0.2-0.1,0.3-0.4,0.2-0.7c-0.6-1-1.8-1.5-2.9-1.3c-1.1,0.3-2,1.2-2.1,2.4c0,0.3,0.2,0.5,0.4,0.5C152.4,57.9,152.5,57.9,152.5,57.9z"
          />
          <Path
            fill="#2E2E33"
            d="M162.2,55.8c0.2,0,0.4-0.2,0.4-0.4c0.1-0.8,0.6-1.4,1.4-1.5c0.7-0.2,1.5,0.2,1.9,0.8c0.1,0.2,0.4,0.3,0.7,0.2c0.2-0.1,0.3-0.4,0.2-0.7c-0.6-1-1.8-1.5-2.9-1.3c-1.1,0.3-2,1.2-2.1,2.4c0,0.3,0.2,0.5,0.4,0.5C162.1,55.8,162.2,55.8,162.2,55.8z"
          />
          <Path
            fill="#2E2E33"
            d="M160.7,62c1.9-0.4,3.4-2,3.6-4c0-0.3-0.2-0.5-0.4-0.5c-0.3,0-0.5,0.2-0.5,0.4c-0.2,1.6-1.3,2.8-2.8,3.2c-1.5,0.3-3.1-0.3-3.9-1.7c-0.1-0.2-0.4-0.3-0.7-0.2c-0.2,0.1-0.3,0.4-0.2,0.7C156.8,61.5,158.7,62.4,160.7,62z"
          />
          <Path
            fill="#EB2E49"
            d="M169.8,53.1c-0.7-1.9-1.9-3.6-3.4-4.8c1,2.2,3,8.8-2,14.1c-4,4.2-10.4,2.3-13.5,0.9c2.8,3.7,7.8,5.3,12.4,3.6C168.9,65,171.9,58.7,169.8,53.1z"
          />
          <Path
            fill="#FF6588"
            d="M153.5,48.6c1.5-1,3.2-1,3.9-0.1s0,2.5-1.5,3.5c-1.5,1-3.2,1-3.9,0.1S152,49.6,153.5,48.6z"
          />
        </Group>
        <Group className={C.JOYSTICK_2.BUTTON}>
          <Path
            fill="#FF4369"
            d="M192.7,99.1h4.5c1.7,0,3,1.3,3,3v1.8h-10.5v-1.8C189.7,100.5,191,99.1,192.7,99.1z"
          />
          <Path
            fill="#EB2E49"
            d="M195.2,99.1h1.9c1.7,0,3,1.3,3,3v1.8h-4.9V99.1z"
          />
          <Path
            fill="#FF6588"
            d="M191.9,104v-4.8c-1.2,0-2.2,1-2.2,2.2v2.7H191.9z"
          />
        </Group>
        <Path
          fill="#4F52B1"
          d="M142.3,104H205c1.7,0,3,1.3,3,3v12.7c0,1.7-1.3,3-3,3h-62.7c-1.7,0-3-1.3-3-3V107C139.3,105.3,140.6,104,142.3,104z"
        />
        <Path
          fill="#343799"
          d="M176.7,104H205c1.7,0,3,1.3,3,3v12.7c0,1.7-1.3,3-3,3h-28.2c-1.7,0-3-1.3-3-3V107C173.7,105.3,175.1,104,176.7,104z"
        />
        <Path
          fill="#4F52B1"
          d="M170.5,97.4h6.3c1.7,0,3,1.3,3,3v0.3h-12.3v-0.3C167.5,98.7,168.8,97.4,170.5,97.4z"
        />
        <Path
          fill="#343799"
          d="M176.6,97.4L176.6,97.4c1.7,0,3.1,1.3,3.1,3v0.3h-3.1V97.4z"
        />
        <Path
          fill="#4F52B1"
          d="M172.6,94h2c1.7,0,3,1.3,3,3v0.3h-8V97C169.6,95.4,170.9,94,172.6,94z"
        />
        <Path fill="#343799" d="M175.6,97.4V94c1.1,0,2,0.9,2,2v1.3H175.6z" />
        <Path
          fill="#7E81C8"
          d="M208,105.9h-68.7c0-1.1,0.9-1.9,1.9-1.9H206C207.1,104,208,104.8,208,105.9z"
        />
        <Path
          fill="#4F52B1"
          d="M167.5,100.7h12.1c1.7,0,3,1.3,3,3v0.3h-18.1v-0.3C164.5,102,165.9,100.7,167.5,100.7z"
        />
        <Path
          fill="#343799"
          d="M178.1,100.7h1.6c1.7,0,3,1.3,3,3v0.3h-4.6V100.7z"
        />
        <Path
          fill="#7E81C8"
          d="M170.6,97.4L170.6,97.4c-1.7,0-3.1,1.3-3.1,3v0.3h3.1V97.4z"
        />
        <Path fill="#7E81C8" d="M171.6,97.4V94c-1.1,0-2,0.9-2,2v1.3H171.6z" />
      </Group>
    </Base>
  );
};

export default Image;
