import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '@matterapp/matter-ui';
import connectHrisMutation from 'graphql-queries/mutations/workspaces/connectHris';
import { 
  Icon,
  Box,
  Typography
} from '@mui/material';
import styled from 'styled-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useFinchConnect } from '@tryfinch/react-connect';
import HrisSyncStatus from './HrisSyncStatus';
import UserContext from 'context/UserContext/UserContext';
import { ENV_VARS } from 'app-consts/env-vars';
const { IS_PROD } = WEBPACK_ENV_VARS;

const StyledIcon = styled(Icon)(({ theme }) => ({
  width: '15px',
  height: '15px',
  color: 'white',
  marginRight: theme.spacing(1.5)
}));

const ProviderLogo = styled(Box)(({ theme }) => ({
  width: '64px',
  height: '64px',
  margin: `${theme.spacing(2)} 0`,
  img: {
    width : '100%'
  }
}));

const ConnectionStatus = styled(Typography)(({ theme }) => ({
  color: theme.palette.greens[60],
  textTransform: 'capitalize'
}));

export default function HrisConnectPanel({ tenant }) {
  const { id: tenantId, hrisSettings } = tenant || {};
  const { status, providerDisplayName, providerIcon } = hrisSettings || {};
  const [updateHrisMutation, { loading: connectingHris }] = useMutation(connectHrisMutation);
  const { refetchCurrentUser, currentWorkspace } = useContext(UserContext);
  const [initialConnect, setInitialConnect] = useState(false);
  const { timezone } = currentWorkspace || { timezone: 'America/Los_Angeles' };

  const onSuccess = ({ code }) => {
    setInitialConnect(true);
    updateHrisMutation({
      variables: {
        tenantId,
        code
      }
    });

    setTimeout(async () => {
      await refetchCurrentUser();

      setInitialConnect(false);
    }, 10000);
  };

  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log('User exited Finch Connect');

  const { open: connectHris } = useFinchConnect({
    clientId: ENV_VARS.FINCH_CLIENT_ID,
    products: ['company', 'directory', 'individual', 'employment'],
    sandbox: IS_PROD ? null : 'finch',       // optional
    onSuccess,
    onError,
    onClose,
  });

  if (!status || status === 'disconnected') {
    return (
      <Button.Primary.Vivid
        onClick={connectHris}
        disabled={connectingHris}
        style={{ marginTop: '24px' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledIcon 
            component={OpenInNewIcon}
          />
          Sign In to HRIS
        </Box>
      </Button.Primary.Vivid>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <ProviderLogo>
          <img src={providerIcon} />
        </ProviderLogo>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
          <Typography variant='body1'><b>{providerDisplayName}</b></Typography>
          <ConnectionStatus variant='body2'>{status}</ConnectionStatus>
        </Box>
      </Box>
      <HrisSyncStatus 
        tenant={tenant}
        connectHris={connectHris}
        timezone={timezone}
        initialConnect={initialConnect}
      />
    </Box>
  );

};
