import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-grow: 1;
  padding: 0 24px;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  max-width: 992px;
  min-width: 300px;

  ${() => theme.media.medium`
    align-items: stretch;
  `}
`;

const Hero = ({ className, style, children, ...other }) => (
  <Container className={className} style={style}>
    <InnerContainer {...other}>{children}</InnerContainer>
  </Container>
);

Hero.propTypes = {
  style: PropTypes.object, // eslint-disable-line
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Hero.defaultProps = {
  style: null,
  className: null,
};

export default Hero;
