import React from 'react';
import { PurpleTextArea } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import { Field as ReduxFormField } from 'redux-form';

const QuickNoteField = class extends React.Component {
  renderField = (reduxFormFieldProps) => (
    <PurpleTextArea
      placeholder={this.props.placeholder}
      autoFocus={this.props.autoFocus}
      errorMessage={
        !reduxFormFieldProps.meta.submitFailed
          ? null
          : reduxFormFieldProps.meta.error
      }
      {...reduxFormFieldProps.input}
    />
  );

  render = () => (
    <ReduxFormField name="quickNote" component={this.renderField} />
  );
};

QuickNoteField.defaultProps = {
  placeholder: null,
  autoFocus: false,
};

QuickNoteField.propTypes = {
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default QuickNoteField;
