import React from 'react';
import PropTypes from 'prop-types';
import {
  NotificationBell,
  NotificationsCircle,
  NotificationsCircleText,
  NotificationsContainer,
} from './styles';

const Notifications = (props) => {
  const {
    hasBeenNotified,
    isNotificationsActive,
    onClickNotifications,
    unreadNotificationsCount,
  } = props;
  if (!hasBeenNotified) {
    return null;
  } else if (unreadNotificationsCount > 0) {
    return (
      <NotificationsContainer isActive={isNotificationsActive}>
        <NotificationsCircle onClick={onClickNotifications}>
          <NotificationsCircleText>
            {unreadNotificationsCount}
          </NotificationsCircleText>
        </NotificationsCircle>
      </NotificationsContainer>
    );
  }
  return (
    <NotificationsContainer isActive={isNotificationsActive}>
      <NotificationBell
        isActive={isNotificationsActive}
        onClick={onClickNotifications}
      />
    </NotificationsContainer>
  );
};

Notifications.propTypes = {
  /** If user has every had any notifications. */
  hasBeenNotified: PropTypes.bool,
  /** If notification page is active. */
  isNotificationsActive: PropTypes.bool,
  /** Callback when notifications is clicked. */
  onClickNotifications: PropTypes.func,
  /** THe number of unread notifications for the user. */
  unreadNotificationsCount: PropTypes.number,
};


export default Notifications;
