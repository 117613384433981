import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { Helmet } from 'react-helmet';
import { useQuery, useMutation } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { ListHeading, Loader } from '@matterapp/matter-ui';
import { EmptyState } from '../modules/EmptyState';
import SkillAvatarCard from '../modules/SkillAvatarCard/SkillAvatarCard';
import { SkillChart } from '../modules/SkillChart';
import { AppLayout } from 'modules';
import FeedItemsList from 'modules/feed/FeedItemsList';
import { Resources } from '@matterapp/routing';
import { SKILL_DETAILS_2_PAGE_QUERY, REMOVE_SKILL_MUTATION, SKILL_DETAILS_QUERY } from 'graphql-queries/queries';
import { useParams, useNavigate } from 'react-router-dom';

const elementPadding = '28px';

function SkillDetailsPage() {
  const { skillSlug } = useParams();
  const navigate = useNavigate();
  const { currentWorkspaceId: workspaceId, currentTenantWorkspaceIds } = useContext(UserContext); 
  const [removeSkillMutation] = useMutation(REMOVE_SKILL_MUTATION);
  const { loading, data } = useQuery(SKILL_DETAILS_2_PAGE_QUERY, {
    variables: {
      skillSlug,
      workspaceIds: currentTenantWorkspaceIds,
      limit: 6
    },
    skip: !currentTenantWorkspaceIds?.length
  });

  if (loading || !currentTenantWorkspaceIds) { 
    return <Loader />; 
  }

  const { pickedSkills, skillFeedbackFeedItems } = data || {};
  const { skill, feedbackFeedItems, startDate } = skillFeedbackFeedItems;

  if (!skill) {
    return (
      <Navigate to={Resources.skillsMain.path()} />
    );
  }

  function handleConfirmRemoveSkill() {
    removeSkillMutation({
      variables: {
        skillId: skill.id
      },
      refetchQueries: [{
        query: SKILL_DETAILS_QUERY,
        variables: {
          workspaceIds: currentTenantWorkspaceIds,
        }
      }]
    });

    navigate(Resources.skillsMain.path({ workspaceId }));
  }

  function renderFeedbacks(skill, feedbackFeedItems) {
    const feedItems = [...feedbackFeedItems]
      .map(feedItem => { 
        const { payload } = feedItem;
        const { feedback } = payload;
        const newFeedback = {
          ...feedback,
          skillRatings: [...(feedback?.skillRatings || [])].filter((skillRating) => skillRating.skill.id === skill.id)
        };

        return {
          ...feedItem,
          payload: {
            ...payload,
            feedback: newFeedback
          }
        };
      });

    return (
      <>
        <ListHeading>
          Activity
        </ListHeading>
        <FeedItemsList 
          feedItemsList={feedItems} 
          isLoading={!feedbackFeedItems} 
          workspaceId={workspaceId} 
          feedItemsProps={{ hideEndorsements: true, skillSlug }}
        />
      </>
    );
  }

  function renderMainColumn() {
    const ratedFeeditems = [...feedbackFeedItems].filter((feedItem) => {
      return !!feedItem.payload.feedback?.skillRatings?.length || !!feedItem.payload.quickNote?.skill?.length;
    });
    const pickedSkill = pickedSkills.filter(pickedSkill => pickedSkill.skill.id == skill.id)[0];
    return (
      <>
        <SkillChart style={{marginBottom: elementPadding}}
          feedItems={ratedFeeditems}
          skill={skill}
          startDate={startDate}
          pickedSkill={pickedSkill}
        />

        {feedbackFeedItems.length ?
          renderFeedbacks(skill, feedbackFeedItems) :
          <EmptyState />
        }
      </>
    );
  }

  return (
    <>
      <Helmet title={skill.name} />
      <AppLayout
        layout={AppLayout.Layouts.Profile}
        mainColumn={renderMainColumn()}
        sideColumn={(
          <SkillAvatarCard
            skill={skill}
            canEdit={skill.isCustom && !feedbackFeedItems.length}
            handleConfirmRemoveSkill={handleConfirmRemoveSkill}
          />
        )}
      />
    </>
  );
};

export default SkillDetailsPage;
