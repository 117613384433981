import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

class PurpleTextArea extends React.PureComponent {
  static StyledTextArea = styled.textarea`
    resize: none;

    width: 100%;
    height: 268px;

    background: ${() => theme.colors.purples[5]};
    border: none;
    border-bottom: 2px solid ${() => theme.colors.purples[40]};
    border-radius: 2px;

    padding: 16px;
    margin-bottom: 14px;

    color: ${() => theme.colors.blacks[90]};
    font-weight: 500;
    line-height: 24px;
    font-size: 17px;

    &::placeholder {
      color: rgba(79, 82, 177, 0.7);
    }

    ${() => theme.media.medium`
      height: 132px;
    `}
  `;

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus && this.textAreaRef) {
      this.textAreaRef.focus();
    }
  }

  render() {
    const { value, name, onChange, placeholder, style, className } = this.props;

    return (
      <PurpleTextArea.StyledTextArea
        ref={(ref) => {
          this.textAreaRef = ref;
        }}
        className={className}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        style={style}
      />
    );
  }
}

PurpleTextArea.defaultProps = {
  name: null,
  placeholder: null,
  autoFocus: false,
  style: {},
  className: null,
};

PurpleTextArea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default PurpleTextArea;
