import { compose, withProps, withStateHandlers } from 'recompose';
import PropTypes from 'prop-types';
import CustomSkillSuccessOverlay from '../components/CustomSkillSuccessOverlay';
import { withToggle } from '../../../../../../../../hocs';

const initialState = {
  skill: null,
};

const withData = compose(
  withStateHandlers(() => initialState, {
    _handleSetSkill: () => (skill) => ({ skill }),
    _handleResetSkill: () => () => initialState,
  }),
  withToggle({
    stateName: 'isOpen',
    initialIsOpen: false,
    handleToggleOpenName: '_handleOpen',
    handleToggleCloseName: '_handleClose',
  }),
  withProps(
    ({
      _handleClose,
      _handleOpen,
      _handleSetSkill,
      handlePickCreateCustomSkillMode,
    }) => ({
      handleSetSkill: (skill) => {
        _handleSetSkill(skill);
        _handleOpen();
      },
      handleCloseOverlay: _handleClose,
      handleClickCreateNewCustomSkill: () => {
        _handleClose();
        handlePickCreateCustomSkillMode();
      },
    })
  )
);

const CustomSkillSuccessOverlayContainer = withData(CustomSkillSuccessOverlay);

CustomSkillSuccessOverlayContainer.propTypes = {
  handlePickCreateCustomSkillMode: PropTypes.func.isRequired,
};

export default CustomSkillSuccessOverlayContainer;
