import {
  getDayOfMonth,
  getMonth,
  getYear,
} from '@matterapp/calendar-date';
import { months } from 'routes/pages/user-settings/components/Consts';

export const formatToPeersList = ({ list, tenant, currentUser, skipCaseFormat = false }) => {
  return list.map(item => {
    const { creatorUserId, label, iconFileName, createdAt, isCustom, creatorName } = item;
    const { isAdmin, isOwner } = tenant;
    const creator = creatorUserId === currentUser.id;
    const month = months[getMonth(createdAt)];
    const day = getDayOfMonth(createdAt);
    const year = getYear(createdAt);
    const canEdit = isCustom && (isAdmin || isOwner || creator); // add check - default kudos can't be edited
    const canDelete = isAdmin || isOwner || creator;

    return {
      ...item,
      fullName: skipCaseFormat ? label : label.toLowerCase().replace(/(^\w|\s\w)/g, s => s.toUpperCase()),
      photoUrl: iconFileName,
      creatorName: creatorName || creator?.person?.fullName || creator?.person?.email,
      createdTime: `${month} ${day}, ${year}`,
      canEdit,
      canDelete,
      showCustomAvatar: isCustom && !iconFileName,
    };
  });
};