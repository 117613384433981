import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';
import {
  AddButton,
  DescriptionText,
  Dropdown,
  TagsContainer,
  TagsWrapper,
} from './styles';
import { useTagState } from './utils';
import { colorsList } from '../utils';

const Tags = (props) => {
  const {
    children,
    color,
    descriptionText,
    formatTagLabel,
    isValidValue,
    ...dropdownProps
  } = props;
  const {
    addButtonProps,
    handleBlur,
    handleChange,
    handleKeyPress,
    handleRemove,
    hasError,
    selectedValues,
    showAddButton,
    showDropdownArrow,
    singleValue,
    value,
  } = useTagState(props);
  const hasTags = !!selectedValues.length;

  const tagContent = selectedValues.map((selectedValue, index) => (
    <Tag
      key={`tag-${selectedValue}-${index}`}
      label={formatTagLabel ? formatTagLabel(selectedValue) : selectedValue}
      isValid={isValidValue(selectedValue)}
      onClickRemove={() => handleRemove(selectedValue)}
      value={selectedValue}
    />
  ));

  return (
    <Dropdown
      errorMessage={hasError ? ' ' : null}
      {...dropdownProps}
      hasTags={hasTags}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      shouldItemRender={(item) => !selectedValues.includes(item.value)}
      showDropdownArrow={showDropdownArrow}
      singleValue={singleValue}
      value={value}
    >
      {showAddButton && <AddButton {...addButtonProps} />}
      {children}
      <TagsContainer
        color={color}
        hasTags={hasTags}
        singleValue={singleValue}
        data-role="container"
      >
        <TagsWrapper>{tagContent}</TagsWrapper>
      </TagsContainer>
      {hasTags && descriptionText && (
        <DescriptionText>{descriptionText}</DescriptionText>
      )}
    </Dropdown>
  );
};

Tags.defaultProps = {
  addButtonLabel: 'Add',
  onBlur: () => null,
  onChangeInput: () => null,
  onSelect: () => null,
  isValidValue: () => true,
  showDropdownArrow: true,
};

Tags.propTypes = {
  /** Label of add button. */
  addButtonLabel: PropTypes.string,
  /** Color theme of the dropdown. */
  color: PropTypes.oneOf(colorsList),
  /** Description text to show when there are tags selected. */
  descriptionText: PropTypes.node,
  /** Optional function to format selected tag value. */
  formatTagLabel: PropTypes.func,
  /** Callback when tag is removed. */
  handleRemove: PropTypes.func,
  /** Callback to determin if value of a single tag is valid. */
  isValidValue: PropTypes.func,
  /** Callback when input is blurred. */
  onBlur: PropTypes.func,
  /** Optional callback when value of input changes. */
  onChangeInput: PropTypes.func,
  /** Callback when value is selected. */
  onSelect: PropTypes.func,
  /** Displays add button inline. */
  showAddButton: PropTypes.bool,
  /** If only one value is allowed. */
  singleValue: PropTypes.bool,
  /** List of tag values displayed. */
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export default Tags;
