import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C8.68385 6 6 8.68386 6 12C6 15.3161 8.68385 18 12 18C15.3161 18 18 15.3161 18 12C18 8.68386 15.3161 6 12 6ZM7 12C7 9.23614 9.23614 7 12 7C14.7639 7 17 9.23614 17 12C17 14.7639 14.7639 17 12 17C9.23614 17 7 14.7639 7 12ZM12.5 8.71997C12.5 8.44383 12.2761 8.21997 12 8.21997C11.7238 8.21997 11.5 8.44383 11.5 8.71997V12.33C11.5 12.462 11.5522 12.5886 11.6452 12.6822L13.0252 14.0722C13.2197 14.2682 13.5363 14.2694 13.7323 14.0748C13.9282 13.8802 13.9294 13.5637 13.7348 13.3677L12.5 12.1239V8.71997Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
