import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconNew as Icon } from '../../components';
import theme from '@matterapp/matter-theme';

const DEFAULT_ICON_NAME = 'checkmarkSmall';
const DEFAULT_ICON_SIZE = 32;

const getIconFill = ({ checked, disabled, hover, theme }) => {
  if (checked || disabled) {
    return 'white';
  }
  if (hover) {
    return theme.colors.blue;
  }
  return theme.colors.blacks[40];
};

const ContainerDiv = styled.div`
  transition: ${(props) =>
    props.hiddenTransition && 'transform 0.2s, opacity 0.2s'};

  ${({ isHidden, isTransitionHidden }) => {
    if (isHidden) {
      if (!isTransitionHidden) {
        return `display: none;`;
      }
      return `
        display: block;
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        transition: transform 0.4s, opacity 0.2s;
      `;
    }
  }};

  ${({ checked, disabled, size, showBorder, isCustomIcon }) => {
    return `
      [type='checkbox']:not(:checked) + label:before,
      [type='checkbox']:checked + label:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: ${size}px;
        height: ${size}px;
        background-color: ${showBorder ? theme.colors.white : theme.colors.blacks[5]};
        border-radius: 100%;
        ${showBorder ? `border: 1px solid ${theme.colors.blacks[20]};` : ''}
      }

      [type='checkbox'] {
        &:not(:checked) + label {
          &:hover:before {
            background: ${isCustomIcon ? 'none' : theme.colors.blues[5]};
          }
          img {
            opacity: 0;
            transform: scale(0);
          }
        }
        &:checked + label {
          &:before {
            animation: checkmarkPulse 0.6s 1 ease-out;
            background: ${theme.colors.blue};
            border: none;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08),
              inset 0px -1px 0px rgba(0, 0, 0, 0.1);
          }
          img {
            opacity: 1;
            transform: scale(1);
          }
        }
        &:disabled {
          &:not(:checked) + label {
            &:before {
              box-shadow: none;
              background-color: ${theme.colors.blacks[20]};
            }
          }
          &:checked + label {
            &:after {
              color: ${theme.colors.blacks[40]};
            }
            &:before {
              box-shadow: none;
              background-color: ${theme.colors.blacks[20]};
            }
          }
          & + label {
            color: #aaaaaa;
          }
        }
      }
    
      @keyframes checkmarkPulse {
        from {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 1), 0 0 0 0 rgba(69, 132, 255, 1);
        }
        to {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 1),
            0 0 0 10px rgba(69, 132, 255, 0);
        }
      }

      /* accessibility */
      [type='checkbox']:checked:focus + label:before {
        border-color: ${theme.colors.blue};
      }
      [type='checkbox']:not(:checked):focus + label:before {
        border: ${theme.inputs.borderWidth}
          ${theme.inputs.borderStyle}
          ${theme.inputs.borderColor};
      }
      cursor: ${disabled ? 'auto' : 'pointer'};
    
      svg {
        position: relative;
        margin: ${theme.spacing.quarter};

        path {
          fill: ${getIconFill({ checked, disabled, showBorder, theme })}
        }
      }
    
    `;
  }}
`;

const Input = styled.input`
  :not(:checked),
  :checked {
    position: absolute;
    left: -9999px;
  }
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
`;

const Label = styled.label`
  ${({ disabled, size }) => {
    return `
      position: relative;
      display: block;
      width: ${size}px;
      height: ${size}px;
      text-align: center;
      user-select: none;
      cursor: ${disabled ? 'auto' : 'pointer'};
    `;
  }}
`;

const Checkmark = ({
  className,
  name,
  disabled,
  checked,
  isHidden,
  isTransitionHidden,
  iconCheckedName,
  iconUncheckedName,
  isCustomIcon,
  onChange,
  showBorder,
  size,
}) => {
  const iconSize = size - 8;
  return (
    <ContainerDiv
      className={className}
      isHidden={isHidden}
      isTransitionHidden={isTransitionHidden}
      disabled={disabled}
      checked={checked}
      size={size}
      showBorder={showBorder}
      isCustomIcon={isCustomIcon}
    >
      <Input
        type="checkbox"
        id={`checkbox-${name}`}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <Label
        htmlFor={`checkbox-${name}`}
        disabled={disabled}
        size={size}
        data-type="label"
      >
        <React.Fragment>
          {!checked && iconUncheckedName && <Icon name={iconUncheckedName} size={iconSize} />}
          {checked && iconCheckedName && <Icon name={iconCheckedName} size={iconSize} />}
        </React.Fragment>
      </Label>
    </ContainerDiv>
  );
};

Checkmark.defaultProps = {
  checked: false,
  disabled: false,
  showBorder: false,
  isHidden: false,
  isTransitionHidden: false,
  iconCheckedName: DEFAULT_ICON_NAME,
  iconUncheckedName: DEFAULT_ICON_NAME,
  name: '',
  onChange: () => null,
  size: DEFAULT_ICON_SIZE,
};

Checkmark.propTypes = {
  /** Additional class. */
  className: PropTypes.string,
  /** If the checkmark is checked state. */
  checked: PropTypes.bool,
  /** If checkmark is hidden. */
  isHidden: PropTypes.bool,
  /** If animation is hidden. */
  isTransitionHidden: PropTypes.bool,
  /** Name of checked icon. */
  iconCheckedName: PropTypes.string,
  /** Name of unchecked icon. */
  iconUncheckedName: PropTypes.string,
  /** Name of checkmark. Needed for label click. */
  name: PropTypes.string,
  /** If checkmark is disabled. */
  disabled: PropTypes.bool,
  /** Callback when `checked` value is changed. */
  onChange: PropTypes.func,
  /** Size of the checkmark. */
  size: PropTypes.number,
};

export default Checkmark;
