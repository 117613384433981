import React from 'react';
import PropTypes from 'prop-types';
import SkillNote from './SkillNote';
import { skillValueObjectPropType } from '@matterapp/skills';
import Gauge from '../../Skill/Gauge';
import {
  RatingContainer,
  RatingFlexContainer,
  RatingItemContainer,
} from './styles';

export const maxNoteHeight = '122px';

const SkillRatingItemBase = (props) => {
  const {
    isAlternate,
    note,
    onClickSkillBadge,
    rating,
    skill,
    skillGaugeProps,
    width,
  } = props;
  return (
    <RatingContainer width={width}>
      <RatingFlexContainer
        alignItems="stretch"
        order={isAlternate ? 1 : 0}
        width={100 / 3}
      >
        <RatingItemContainer key={skill.name}>
          <Gauge
            {...skillGaugeProps}
            size="small"
            skill={skill}
            rating={rating}
            onClickSkill={onClickSkillBadge}
          />
        </RatingItemContainer>
      </RatingFlexContainer>
      <RatingFlexContainer order={isAlternate ? 0 : 1} width={66}>
        <SkillNote skill={skill} >{note}</SkillNote>
      </RatingFlexContainer>
    </RatingContainer>
  );
};

SkillRatingItemBase.defaultProps = {
  isAlternate: false,
  skillGaugeProps: {},
  truncateNote: false,
  width: null,
};

SkillRatingItemBase.propTypes = {
  isAlternate: PropTypes.bool,
  maxRating: PropTypes.number,
  maxRatingValue: PropTypes.number,
  note: PropTypes.node,
  onClickSkillBadge: PropTypes.func,
  skill: skillValueObjectPropType.isRequired,
  skillGaugeProps: PropTypes.shape(Gauge.propTypes),
  truncateNote: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
};

export default SkillRatingItemBase;