import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, media, spacing } from '@matterapp/matter-theme';
import {
  FEED_ITEM_CONTAINER_CLASS_NAME,
  FEED_ITEM_FOOTERABLE_CLASSNAME,
} from './consts';

class FeedItemLayout extends React.PureComponent {
  static Wrapper = styled.div`
    box-sizing: border-box;
    text-align: left;
    background: white;
    margin: 0;
    margin-bottom: 24px;

    ${media.S`
      border-radius: 12px;
      border-bottom: 1px solid ${colors.blacks[10]};
    `}

    .${FEED_ITEM_FOOTERABLE_CLASSNAME}:last-child {
      border-top: 1px solid ${colors.purples[5]};
      border-radius: 0 0 12px 12px;
    }
  `;

  static Body = styled.div`
    padding: 0 ${spacing.singleAndQuarter};
    padding-bottom: ${({ hideFooter }) => hideFooter ? spacing.singleAndQuarter : ''};
    ${media.S`
      padding: 0 ${spacing.singleAndHalf};
      padding-bottom: ${({ hideFooter }) => hideFooter ? spacing.singleAndHalf : ''};
    `}
  `;

  render() {
    const { children } = this.props;
    return (
      <FeedItemLayout.Wrapper className={FEED_ITEM_CONTAINER_CLASS_NAME}>
        {children}
      </FeedItemLayout.Wrapper>
    );
  }
}

FeedItemLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeedItemLayout;
