import { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CREATE_OR_OVERWRITE_RECURRING_FEEDBACK_MUTATION,
  INACTIVATE_RECURRING_FEEDBACK_MUTATION,
} from 'graphql-queries/queries';
import Toast from 'components/Toast/Toast';
import {
  REPLACE_FREQUENCY_TEXT,
  DEFAULT_RECURRING_REQUEST_MESSAGE,
  DEFAULT_REQUEST_MESSAGE
} from 'copy-consts';
import { FREQUENCY_NOUNS } from 'app-consts';
import { useSelectUserDropdown } from 'modules/feed/RitualModals/hooks';
import { getSortedMembersList } from '../components/WorkspacesSharedHandlers';
import { handleTrack } from '../components/ActionModals/tracking';
import getMembersWithRecurring from 'graphql-queries/queries/workspace/getMembersWithRecurring';

export const actionMap = {
  REQUEST: 'Request Feedback',
  RECURRING: 'Start Continuous',
  EDIT: 'Edit Continuous',
  GIVE: 'Give Feedback',
  KUDOS: 'Give Kudos',
  INFO: 'View Info',
};

const errorNotification = () => Toast.error('Something went wrong. Please try again later');

export const useMakeOrEditRecurring = (workspaceId) => {
  const [mutation] = useMutation(CREATE_OR_OVERWRITE_RECURRING_FEEDBACK_MUTATION);
  const handleMakeOrOverwriteRecurring = async ({
    personId,
    message,
    recurrence,
    sendImmediately,
    skillIds,
    isEditRecurringMode,
  }) => {
    try {
      const recurringProps = {
        personId,
        recurrence, 
        skillIds,
        sendImmediately,
        workspaceId,
      };
  
      const frequencyNoun = FREQUENCY_NOUNS[recurrence.frequency];
      const defaultRecurringMessage = DEFAULT_RECURRING_REQUEST_MESSAGE.replace(REPLACE_FREQUENCY_TEXT, frequencyNoun);
      if (message !== defaultRecurringMessage) {
        recurringProps.customNote = message;
      }

      await mutation({ 
        variables: recurringProps,
        refetchQueries: [{ 
          query: getMembersWithRecurring, 
          variables: { workspaceId } 
        }]
      });
      
      if (isEditRecurringMode) {
        Toast.success('Success! You updated your continuous feedback request');
      } else {
        Toast.success('Success! You have set up continuous feedback');
      }
    } catch (e) {
      errorNotification();
      console.error('Make or edit recurring error: ', e);
    }
  };
  return handleMakeOrOverwriteRecurring;
};

export const useStopRecurring = (workspaceId) => {
  const [mutation] = useMutation(INACTIVATE_RECURRING_FEEDBACK_MUTATION);

  const handleStopRecurring = async (recurringFeedbackId) => {
    try {
      await mutation({
        variables: { id: recurringFeedbackId, workspaceId },
        refetchQueries: [{ 
          query: getMembersWithRecurring, 
          variables: { workspaceId } 
        }],
      });
      Toast.success('You have stopped continuous feedback for this member');
    } catch (e) {
      errorNotification();
      console.error('Stop recurring error: ', e);
    }
  };
  
  return handleStopRecurring;
};

export const useRecurringModal = (params) => {
  const { 
    workspaceId, 
    membersWithRecurring, 
    onChangeSelectedAdvisor, 
    currentUser,
    workspace
  } = params;
  const handleMakeOrOverwriteRecurring = useMakeOrEditRecurring(workspaceId);
  const handleStopRecurring = useStopRecurring(workspaceId);

  const userDropdownProps = useSelectUserDropdown({
    membersWithRecurring,
    currentUser,
    onSelectUser: (e, { user }) => onChangeSelectedAdvisor(user),
    workspaceId,
    workspace
  });

  const handleSelectMember = async (selectedMember) => {
    await onChangeSelectedAdvisor(selectedMember);
  };

  return {
    handleMakeOrOverwriteRecurring,
    handleStopRecurring,
    handleSelectMember,
    userDropdownProps,
    sortedRecurringList: getSortedMembersList(membersWithRecurring)
  };
};

export const useActionModals = () => {
  const [isInfoModalOpen, setInfoModal] = useState(false);
  const [isRecurringModalOpen, setRecurringModal] = useState(false);
  const [isRequestFeedbackModalOpen, setRequestFeedbackModal] = useState(false);
  const [isGiveFeedbackModalOpen, setGiveFeedbackModal] = useState(false);
  const [isSendKudosModalOpen, setSendKudosModal] = useState(false);
  const [showEmailDropdown, setEmailDropdown] = useState(false);
  const [isEditRecurringMode, setEditMode] = useState(false);

  const modalStates = {
    isInfoModalOpen,
    isRecurringModalOpen,
    isRequestFeedbackModalOpen,
    isGiveFeedbackModalOpen,
    isSendKudosModalOpen,
  };

  const modalHandlers = {
    setInfoModal,
    setRecurringModal,
    setRequestFeedbackModal,
    setGiveFeedbackModal,
    setSendKudosModal,
    setEmailDropdown,
    setEditMode,
  };

  const modalModes = {
    showEmailDropdown,
    isEditRecurringMode,
  };

  return {
    modalStates,
    modalHandlers,
    modalModes
  };
};

export const useActionModalSelector = (params) => {
  const { modalHandlers, initUserSkillsList, setDefaultMessage, pickedSkillsList } = params;
  const { setInfoModal, setEditMode, setRecurringModal, setEmailDropdown, setRequestFeedbackModal, setGiveFeedbackModal, setSendKudosModal } = modalHandlers;
  const closeInfoModal = () => setInfoModal(false);
  const openInfoModal = () => setInfoModal(true);

  const openRecurringModalWithDropdown = () => {
    const isEditMode = false;
    setEmailDropdown(true);
    viewRecurringModal(isEditMode);
    handleTrack.recurringFeedbackClick();
  };

  const openEditRecurringModal = (member) => {
    const isEditMode = true;
    setEmailDropdown(false);
    viewRecurringModal(isEditMode);
    setEditRecurringFeedbackModal(member);
    handleTrack.recurringFeedbackEditClick();
  };

  const getUserPickedSkillsList = () => {
    if (!pickedSkillsList?.pickedSkills) { return []; }

    return pickedSkillsList.pickedSkills.map(userSkill => userSkill.skill);
  };

  const initializeRequestFeedbackModals = () => {
    const skillsList = getUserPickedSkillsList();

    initUserSkillsList(skillsList);
    setDefaultMessage(DEFAULT_REQUEST_MESSAGE);
  };

  const setEditRecurringFeedbackModal = (member) => {
    const { feedbackTemplate, customNote } = member?.recurringFeedback;
    initUserSkillsList(feedbackTemplate.skills);
    setDefaultMessage(customNote);
  };

  const viewRequestModal = () => {
    initializeRequestFeedbackModals();
    setRequestFeedbackModal(true);
    closeInfoModal();
  };

  const viewRecurringModal = (isEditRecurringMode) => {
    if (!isEditRecurringMode) {
      initializeRequestFeedbackModals();
    }
    setEditMode(isEditRecurringMode);
    setRecurringModal(true);
    closeInfoModal();
  };

  const actionModalSelector = (action, member) => {
    switch (action) {
    case actionMap.REQUEST:
      viewRequestModal();
      handleTrack.requestFeedbackClick();
      break;
    case actionMap.RECURRING:
      const isEditRecurringMode = false;
      setEmailDropdown(false);
      viewRecurringModal(isEditRecurringMode);
      handleTrack.recurringFeedbackClick();
      break;
    case actionMap.EDIT:
      openEditRecurringModal(member);
      break;
    case actionMap.GIVE:
      setGiveFeedbackModal(true);
      handleTrack.giveFeedbackClick();
      closeInfoModal();
      break;
    case actionMap.KUDOS:
      setSendKudosModal(true);
      handleTrack.giveKudosClick();
      closeInfoModal();
      break;
    case actionMap.INFO:
      openInfoModal();
      break;
    default: 
      throw new Error("Unrecognized action: ", action);
    }
  };

  return {
    actionModalSelector,
    viewRequestModal,
    viewRecurringModal,
    closeInfoModal,
    openInfoModal,
    openRecurringModalWithDropdown,
    openEditRecurringModal
  };
};