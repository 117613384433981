import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkmark } from '../../Checkbox';
import Avatar from '../../Avatar';
import theme from '@matterapp/matter-theme';

const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints;

const checkedStyles = (props) => `
  border-color: ${props.theme.colors.blues[20]};
  background-color: ${props.theme.colors.blues[5]};
`;

const ListItemRootNonTouchStyles = (props) => `
  // Prevent iPhone getting stuck on hover style
  :hover {
    background: ${props.theme.colors.blues[10]};
  }
`;

const ListItemRoot = styled.div`
  position: relative;
  align-items: center;
  user-select: none;
  display: inline-flex;
  box-sizing: border-box;
  width: 164px;
  height: 136px;
  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.blues[10]};
  cursor: pointer;

  :active {
    background: ${(props) => props.theme.colors.blues[20]};
  }

  transition: border-color 0.1s ease-in-out, background 0.1s ease-in-out;

  ${(props) => props.checked && checkedStyles(props)}
  ${(props) =>
    !props.checked && !isTouchDevice() && ListItemRootNonTouchStyles(props)}
`;

const GridItemContainer = styled.div`
  min-width: 64px;
  width: 64px;
  height: 64px;
  margin: 8px auto 8px;
`;

const CheckmarkContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 24px;
`;

const PeerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  text-align: center;
`;

const PeerNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 24px;
  max-height: 32px;
  line-height: 16px;
  text-align: center;
  overflow: hidden;
  width: 100%;

  color: ${() => theme.colors.blacks[90]};
  font-size: 14px;
  font-weight: bold;

  .peerEmail {
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

class GridItem extends React.Component {
  renderTop = () => {
    const { defaultAvatarIsGenerated, peer } = this.props;
    const { email, photoUrl } = peer;
    return (
      <GridItemContainer>
        <Avatar
          defaultAvatarIsGenerated={defaultAvatarIsGenerated}
          size={64}
          email={email}
          url={photoUrl}
        />
      </GridItemContainer>
    );
  };

  renderPeerName = () => {
    const { peer } = this.props;
    return (
      <PeerNameContainer data-rc="peer-suggestion-name">
        {peer.fullName || <span className="peerEmail">{peer.email}</span>}
      </PeerNameContainer>
    );
  };

  renderCheckmark = () => {
    const { isSelected, peer } = this.props;
    return (
      <CheckmarkContainer>
        <Checkmark
          checked={isSelected}
          name={`peer-suggestion-checkbox-${peer.email}`}
        />
      </CheckmarkContainer>
    );
  };

  render() {
    const { onToggle, isSelected } = this.props;

    return (
      <ListItemRoot
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onToggle();
        }}
        checked={isSelected}
      >
        {this.renderCheckmark()}
        <PeerContainer>
          {this.renderTop()}
          {this.renderPeerName()}
        </PeerContainer>
      </ListItemRoot>
    );
  }
}

GridItem.propTypes = {
  defaultAvatarIsGenerated: PropTypes.bool,
  peer: PropTypes.shape({
    email: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default GridItem;
