import React from 'react';
import PropTypes from 'prop-types';

const Container = ({
  children,
  position,
  textAlign,
  maxWidth,
  maxHeight,
  minHeight,
  marginLeft,
  marginRight,
  marginBottom,
  marginTop,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  padding,
  height,
  width,
  style,
}) => {
  const containerPadding = (
    paddingLeft === 0 &&
    paddingRight === 0 &&
    paddingTop === 0 &&
    paddingBottom === 0
  ) ? padding : undefined;
  const containerStyles = {
    position,
    maxHeight,
    minHeight,
    maxWidth,
    textAlign,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    height,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    width,
    ...style,
  };
  if (containerPadding) { containerStyles.padding = containerPadding; }
  return (
    <div style={containerStyles} >
      {children}
    </div>
  );
};

Container.defaultProps = {
  children: null,
  position: null,
  maxHeight: 'none',
  minHeight: 'none',
  maxWidth: 'none',
  textAlign: 'left',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  marginBottom: 0,
  marginTop: 0,
  padding: 0,
  height: 'default',
  width: 'default',
  style: {},
};

Container.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paddingLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paddingRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paddingTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paddingBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textAlign: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
};

export default Container;
