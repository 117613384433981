import React from 'react';
import SVG, { Path } from '../../SVG';

const SIZE = 16;

const Icon = ({ props }) => (
  <SVG
    {...props}
    width={SIZE}
    height={SIZE}
    viewBox={SVG.viewBoxFromSize(SIZE)}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4V6H13C14.1046 6 15 6.89543 15 8V10V14C15 15.1046 14.1046 16 13 16H3C2.99569 16 2.99137 16 2.98707 16C1.88843 15.993 1 15.1003 1 14V10V8C1 6.89543 1.89543 6 3 6H4V4ZM6 6H10V4C10 2.89543 9.10458 2 8 2C6.89542 2 6 2.89543 6 4V6Z"
    />
  </SVG>
);

export default Icon;
