export const RECURRING_FREQUENCY = {
  biweekly: 'biweekly',
  weekly: 'weekly',
  monthly: 'monthly',
  bimonthly: 'bimonthly',
  quarterly: 'quarterly',
};

export const FREQUENCY_NOUNS = {
  weekly: 'week',
  biweekly: 'couple of weeks',
  monthly: 'month',
  bimonthly: 'couple of months',
  quarterly: 'quarter'
};