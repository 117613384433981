import React from 'react';
import Base, { Group, Path, PathEvenOdd } from '../../Image';
import * as C from './classNames';

const Image = (props) => {
  return (
    <Base {...props}>
      <Group className={C.BACKGROUND}>
        <Path
          className={C.CIRCLE.LEFT_1}
          d="M49.4004 112C51.8883 112 53.9004 109.987 53.9004 107.5C53.9004 105.013 51.8883 103 49.4004 103C46.9182 103 44.9004 105.013 44.9004 107.5C44.9004 109.981 46.9182 112 49.4004 112Z"
          fill="#FFABC2"
        />
        <Path
          className={C.CIRCLE.LEFT_2}
          d="M90.0629 109.663C88.9454 111.886 86.2429 112.779 84.0211 111.662C81.7994 110.545 80.9043 107.843 82.0218 105.621C83.1367 103.403 85.8418 102.504 88.0635 103.621C90.2827 104.743 91.1777 107.445 90.0629 109.663Z"
          fill="#FFABC2"
        />
        <Path
          className={C.CIRCLE.TOP}
          d="M173.567 18.9694C172.338 21.4145 169.365 22.3973 166.921 21.1687C164.477 19.9401 163.493 16.9678 164.722 14.5227C165.948 12.0833 168.924 11.0949 171.368 12.3235C173.812 13.5521 174.794 16.5299 173.567 18.9694Z"
          fill="#FFABC2"
        />
        <Path
          className={C.RING.RIGHT}
          d="M202.4 32C197.442 32 193.4 27.9621 193.4 23C193.4 18.038 197.442 14 202.4 14C207.359 14 211.4 18.038 211.4 23C211.407 27.9621 207.365 32 202.4 32ZM202.4 18.1646C199.733 18.1646 197.563 20.3292 197.563 23.0064C197.563 25.6709 199.727 27.8481 202.4 27.8481C205.067 27.8481 207.238 25.6836 207.238 23.0064C207.244 20.3355 205.067 18.1646 202.4 18.1646Z"
          fill="#FFABC2"
        />
        <Path
          className={C.RING.LEFT}
          d="M65.9504 87.2001C61.24 87.2001 57.4004 83.3641 57.4004 78.6501C57.4004 73.9362 61.24 70.1001 65.9504 70.1001C70.6607 70.1001 74.5004 73.9362 74.5004 78.6501C74.5004 83.3641 70.6728 87.2001 65.9504 87.2001ZM65.9504 74.0565C63.4168 74.0565 61.3549 76.1128 61.3549 78.6561C61.3549 81.1875 63.4108 83.2558 65.9504 83.2558C68.4839 83.2558 70.5459 81.1995 70.5459 78.6561C70.5519 76.1128 68.4839 74.0565 65.9504 74.0565Z"
          fill="#FFABC2"
        />
        <PathEvenOdd
          className={C.PLUS}
          d="M64.9746 51.0343C64.9746 52.1509 65.8703 53.0528 66.9808 53.0528C68.0914 53.0528 68.9871 52.1448 68.9871 51.0404V46.9479H73.0548C74.1653 46.9479 75.0611 46.046 75.0611 44.9294C75.0611 43.8127 74.1653 42.9108 73.0548 42.9108H68.9871V38.8187C68.9871 37.702 68.0914 36.8001 66.9808 36.8001C65.8703 36.8001 64.9746 37.702 64.9746 38.8187V42.9108H60.9067C59.7962 42.9108 58.9004 43.8127 58.9004 44.9294C58.9004 46.046 59.7962 46.9479 60.9067 46.9479H64.9746V51.0343Z"
          fill="#FFABC2"
        />
      </Group>
      <Group className={C.CUBE_2D}>
        <Path
          d="M143.195 66.2413C142.811 66.5477 142.318 66.6825 141.831 66.6138L126.932 64.5123C125.393 64.2952 124.845 62.3528 126.043 61.3633L137.655 51.777C138.044 51.4564 138.549 51.3145 139.048 51.3861L154.254 53.57C155.804 53.7928 156.339 55.7608 155.114 56.7375L143.195 66.2413Z"
          fill="#FFCF7A"
        />
        <PathEvenOdd
          d="M138.136 50.8099L159.421 53.867L142.721 67.1836L121.865 64.242L138.136 50.8099ZM138.396 51.737L123.967 63.6491L142.468 66.2586L157.278 54.449L138.396 51.737Z"
          fill="#25237B"
        />
        <Path
          d="M158.959 53.6662C158.572 53.9759 158.075 54.1115 157.585 54.0407L142.422 51.8488C140.872 51.6246 140.339 49.6557 141.565 48.6802L153.555 39.142C153.934 38.8404 154.419 38.7055 154.899 38.7683L170.21 40.7688C171.772 40.9729 172.325 42.9512 171.096 43.9365L158.959 53.6662Z"
          fill="#FFCF7A"
        />
        <PathEvenOdd
          d="M154.02 38.2091L175.452 41.0095L158.479 54.6147L137.255 51.5464L154.02 38.2091ZM154.276 39.1307L139.399 50.9665L158.227 53.6885L173.288 41.6149L154.276 39.1307Z"
          fill="#25237B"
        />
        <Path
          d="M123.47 63.4444C123.085 63.7622 122.584 63.9043 122.089 63.8364L106.5 61.6952C104.937 61.4805 104.398 59.4944 105.638 58.5189L117.694 49.0326C118.075 48.7327 118.562 48.6005 119.042 48.6663L134.161 50.7369C135.705 50.9483 136.258 52.8959 135.056 53.8873L123.47 63.4444Z"
          fill="#FFCF7A"
        />
        <PathEvenOdd
          d="M118.165 48.1017L139.26 50.9907L122.997 64.4055L101.242 61.4174L118.165 48.1017ZM103.417 60.8271L122.734 63.4805L137.149 51.5904L118.416 49.0249L103.417 60.8271Z"
          fill="#25237B"
        />
        <Path
          d="M138.864 50.7977C138.482 51.1018 137.992 51.2363 137.509 51.17L122.406 49.1026C120.855 48.8901 120.307 46.9263 121.525 45.9412L133.353 36.3745C133.737 36.0639 134.232 35.926 134.722 35.9935L149.972 38.0985C151.529 38.3134 152.071 40.2895 150.841 41.2685L138.864 50.7977Z"
          fill="#FFCF7A"
        />
        <PathEvenOdd
          d="M133.826 35.4253L155.186 38.3735L138.393 51.7353L117.241 48.8396L133.826 35.4253ZM134.083 36.3498L119.378 48.2434L138.139 50.8118L153.028 38.9647L134.083 36.3498Z"
          fill="#25237B"
        />
        <Path
          d="M155.36 112.329L148.993 89.3261L164.658 76.7288L171.042 99.868L155.36 112.329Z"
          fill="#FF6688"
        />
        <Path
          d="M157.475 110.649C156.472 111.446 154.981 110.96 154.639 109.726L149.306 90.4593C149.116 89.7716 149.353 89.0364 149.909 88.5892L162.538 78.4338C163.54 77.6279 165.039 78.1119 165.381 79.3515L170.727 98.7283C170.918 99.4194 170.677 100.158 170.116 100.604L157.475 110.649Z"
          fill="#0ACBA2"
        />
        <PathEvenOdd
          d="M164.903 75.9658L171.543 100.032L155.112 113.088L148.491 89.1645L164.903 75.9658ZM149.494 89.4878L155.606 111.571L170.539 99.7045L164.411 77.4919L149.494 89.4878Z"
          fill="#25237B"
        />
        <Path
          d="M173.198 98.1777C172.194 98.9691 170.708 98.4815 170.368 97.2491L165.01 77.8315C164.819 77.1373 165.062 76.3959 165.628 75.9507L178.523 65.8104C179.527 65.0207 181.012 65.5087 181.352 66.7402L186.704 86.1391C186.895 86.8327 186.652 87.5734 186.087 88.0187L173.198 98.1777Z"
          fill="#0ACBA2"
        />
        <PathEvenOdd
          d="M180.886 63.3928L187.523 87.4484L170.836 100.6L164.193 76.5203L180.886 63.3928ZM165.197 76.8505L171.333 99.0877L186.518 87.1188L180.389 64.9037L165.197 76.8505Z"
          fill="#25237B"
        />
        <Path
          d="M151.085 87.5917C150.086 88.3973 148.589 87.9195 148.242 86.6842L142.958 67.9005C142.764 67.2086 143.002 66.4674 143.564 66.019L156.27 55.8785C157.271 55.0795 158.763 55.5616 159.107 56.7953L164.332 75.519C164.524 76.2067 164.288 76.9428 163.732 77.3911L151.085 87.5917Z"
          fill="#0ACBA2"
        />
        <PathEvenOdd
          d="M158.626 53.4346L165.15 76.8134L148.732 90.0553L142.134 66.5971L158.626 53.4346ZM143.14 66.921L149.218 88.5317L164.146 76.4918L158.136 54.9529L143.14 66.921Z"
          fill="#25237B"
        />
        <Path
          d="M164.498 76.0271L158.421 54.15L174.397 41.4046L180.459 63.3754L164.498 76.0271Z"
          fill="#FF6688"
        />
        <Path
          d="M166.78 74.9691C165.778 75.7638 164.289 75.2791 163.947 74.0465L158.737 55.2889C158.545 54.5983 158.784 53.8598 159.345 53.4129L172.279 43.0941C173.282 42.2941 174.776 42.7795 175.118 44.0162L180.314 62.8503C180.505 63.5421 180.264 64.2811 179.701 64.7269L166.78 74.9691Z"
          fill="#0ACBA2"
        />
        <PathEvenOdd
          d="M174.643 40.6445L181.13 64.1556L164.42 77.4008L157.918 53.9875L174.643 40.6445ZM158.922 54.3128L164.914 75.886L180.126 63.8277L174.149 42.1651L158.922 54.3128Z"
          fill="#25237B"
        />
        <Path
          d="M152.252 112.09C153.521 112.259 154.552 111.078 154.213 109.844L148.912 90.568C148.723 89.8799 148.142 89.3703 147.436 89.2715L131.386 87.0282C130.113 86.8502 129.073 88.0347 129.415 89.2743L134.762 108.651C134.952 109.342 135.537 109.853 136.248 109.948L152.252 112.09Z"
          fill="#FF6688"
        />
        <PathEvenOdd
          d="M128.089 86.1231L134.729 110.189L155.529 112.973L148.948 89.0385L128.089 86.1231ZM148.252 89.8305L154.328 111.923L135.423 109.393L129.294 87.1807L148.252 89.8305Z"
          fill="#25237B"
        />
        <Path
          d="M132.357 109.446C133.625 109.61 134.651 108.43 134.311 107.197L128.954 87.7797C128.762 87.0855 128.173 86.5739 127.458 86.482L111.188 84.3883C109.921 84.2252 108.897 85.4054 109.237 86.637L114.589 106.036C114.78 106.729 115.369 107.241 116.082 107.333L132.357 109.446Z"
          fill="#FF6688"
        />
        <PathEvenOdd
          d="M107.921 83.5238L114.558 107.579L135.627 110.314L128.984 86.2343L107.921 83.5238ZM128.291 87.033L134.426 109.27L115.251 106.781L109.122 84.5663L128.291 87.033Z"
          fill="#25237B"
        />
        <Path
          d="M145.912 89.0189C147.183 89.1984 148.223 88.0205 147.888 86.7818L142.793 67.9463C142.605 67.2524 142.02 66.7385 141.308 66.6416L125.199 64.4509C123.93 64.2783 122.897 65.4572 123.234 66.6929L128.349 85.4467C128.537 86.1356 129.117 86.6466 129.824 86.7465L145.912 89.0189Z"
          fill="#FF6688"
        />
        <PathEvenOdd
          d="M121.924 63.5611L128.312 86.9775L149.197 89.9275L142.833 66.4047L121.924 63.5611ZM142.136 67.1985L147.998 88.8688L129.009 86.1866L123.124 64.613L142.136 67.1985Z"
          fill="#25237B"
        />
        <Path
          d="M125.968 86.2291C127.236 86.3973 128.266 85.218 127.928 83.9842L122.782 65.2089C122.593 64.5176 122.009 64.0063 121.298 63.9099L104.903 61.6834C103.631 61.5108 102.597 62.6937 102.939 63.9305L108.135 82.7646C108.326 83.4564 108.912 83.967 109.623 84.0614L125.968 86.2291Z"
          fill="#FF6688"
        />
        <PathEvenOdd
          d="M101.617 60.7926L108.103 84.3037L129.241 87.107L122.818 63.6716L101.617 60.7926ZM122.122 64.4659L128.04 86.0595L108.797 83.5074L102.821 61.8448L122.122 64.4659Z"
          fill="#25237B"
        />
        <PathEvenOdd
          d="M133.366 35.085C133.627 34.8758 133.963 34.7835 134.295 34.8295L174.334 40.3878C174.816 40.4548 175.212 40.8021 175.342 41.2714L187.906 86.8099C188.036 87.281 187.872 87.7843 187.489 88.0885L155.915 113.209C155.656 113.415 155.324 113.507 154.995 113.463L114.823 108.137C114.338 108.073 113.938 107.725 113.808 107.253L101.244 61.7157C101.114 61.2456 101.277 60.7434 101.658 60.4389L133.366 35.085ZM103.234 60.9501C102.854 61.2546 102.691 61.7568 102.82 62.2269L114.898 106.001C115.028 106.473 115.428 106.822 115.913 106.886L154.594 112.014C154.923 112.058 155.254 111.966 155.514 111.76L185.912 87.575C186.295 87.2707 186.459 86.7675 186.329 86.2964L174.25 42.5169C174.12 42.0476 173.724 41.7003 173.242 41.6333L134.694 36.2821C134.363 36.236 134.027 36.3284 133.765 36.5375L103.234 60.9501Z"
          fill="#25237B"
        />
        <PathEvenOdd
          d="M133.08 34.7282C133.44 34.4406 133.902 34.3136 134.358 34.3769L174.397 39.9352C175.06 40.0273 175.604 40.5048 175.783 41.1501L188.347 86.6887C188.525 87.3365 188.3 88.0284 187.774 88.4468L156.2 113.567C155.843 113.851 155.387 113.977 154.935 113.917L114.763 108.591C114.095 108.503 113.546 108.024 113.367 107.375L100.803 61.8377C100.625 61.1913 100.849 60.5009 101.372 60.0821L133.08 34.7282ZM134.232 35.2828C134.025 35.2541 133.815 35.3118 133.651 35.4425L101.943 60.7964C101.705 60.9868 101.604 61.3006 101.685 61.5944L114.248 107.132C114.33 107.427 114.58 107.644 114.883 107.684L155.055 113.01C155.261 113.038 155.468 112.98 155.63 112.851L187.205 87.7311C187.444 87.5409 187.546 87.2264 187.465 86.9319L174.901 41.3934C174.82 41.1001 174.572 40.883 174.271 40.8411L134.232 35.2828ZM133.479 36.1807C133.839 35.8932 134.301 35.7662 134.757 35.8295L173.305 41.1808C173.968 41.2728 174.513 41.7504 174.691 42.3957L186.769 86.1751C186.948 86.8229 186.723 87.5149 186.197 87.9333L155.798 112.118C155.442 112.401 154.985 112.528 154.534 112.468L115.853 107.34C115.186 107.251 114.636 106.772 114.457 106.123L102.38 62.3489C102.201 61.7025 102.425 61.0121 102.949 60.5933L133.479 36.1807ZM134.631 36.7354C134.424 36.7066 134.214 36.7644 134.051 36.8951L103.52 61.3076C103.282 61.498 103.18 61.8118 103.261 62.1056L115.339 105.88C115.42 106.175 115.67 106.393 115.973 106.433L154.654 111.561C154.859 111.589 155.067 111.531 155.229 111.402L185.627 87.2175C185.867 87.0274 185.969 86.7129 185.888 86.4184L173.809 42.6389C173.728 42.3456 173.48 42.1285 173.179 42.0867L134.631 36.7354Z"
          fill="#343799"
        />
        <Path
          className={C.CUBE_2D_COVER}
          opacity="0"
          fill="#ffffff"
          d="M133.1,34.7c0.4-0.3,0.8-0.4,1.3-0.4l40,5.6c0.7,0.1,1.2,0.6,1.4,1.2l12.6,45.5c0.2,0.6,0,1.3-0.6,1.8l-31.6,25.1c-0.4,0.3-0.8,0.4-1.3,0.3l-40.2-5.3c-0.7-0.1-1.2-0.6-1.4-1.2l-12.6-45.5c-0.2-0.6,0-1.3,0.6-1.8L133.1,34.7z"
        />
      </Group>
    </Base>
  );
};

export default Image;
