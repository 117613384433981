import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7.11c-1.425-1.605-3.455-2.61-5.71-2.61-4.3 0-7.79 3.65-7.79 8.155 0 1.21.22 2.37.565 3.485.185.615.61 1.555.61 1.555C6.955 24.76 16 29.335 16 29.335s9.045-4.575 12.33-11.64c.285-.55.61-1.555.61-1.555.355-1.11.56-2.275.56-3.485 0-4.505-3.49-8.155-7.79-8.155-2.255 0-4.29 1.005-5.71 2.61zm-2.22 7.045c1.28-1.25.4-3.01.4-3.01s4.17 2.2 2.633 7.715h.035c.8-.55 2.36-2.032 1.567-4.845 0 0 2.62 2.62 2.908 4.752A5.452 5.452 0 0 1 16 25.42a5.452 5.452 0 0 1-5.455-5.455c0-.669.12-1.309.34-1.9.388-1.187 1.649-2.693 2.895-3.91z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
