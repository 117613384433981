import { graphql } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';
import apolloClient from 'config/apolloClient';
import withAuthReducer from './withAuthReducer';
import withRouter from 'hocs/withRouter';
import {
  CURRENT_USER_STATUS_QUERY,
  VERIFY_EMAIL_TOKEN_LOGIN_MUTATION,
} from 'graphql-queries/queries';

const withVerifyEmailTokenLoginMutation = graphql(VERIFY_EMAIL_TOKEN_LOGIN_MUTATION, {
  name: 'verifyEmailTokenLoginMutation',
});

const withData = compose(
  withVerifyEmailTokenLoginMutation,
  withAuthReducer,
  withRouter,
  withProps(
    ({
      startAuth,
      endAuth,
      verifyEmailTokenLoginMutation,
    }) => ({
      handleVerifyEmailLogin: async ({
        verifyEmailToken,
        handleSuccess,
        handleFailure,
      }) => {
        startAuth();
        try {
          await verifyEmailTokenLoginMutation({
            variables: { token: verifyEmailToken },
          });
          await apolloClient.query({
            query: CURRENT_USER_STATUS_QUERY,
            fetchPolicy: 'network-only',
          });
          await handleSuccess();
          endAuth();
        } catch (error) {
          endAuth();
          handleFailure(error);
        }
      },
    })
  )
);

export default withData;
