import React from 'react';
import { Advisors } from '@matterapp/matter-ui';
import GiveKudosModal from './GiveKudosModal';
import { handleTrack } from './tracking';
import { actionMap } from 'routes/pages/workspaces/hooks/WorkspaceActionModalHooks';

const ActionModals = class extends React.Component {
  /**
   * Formats current user skills to use with the skill list picker.
   * @returns { Array } Formatted user skills.
   */
  getUserPickedSkillsList() {
    const { pickedSkillsList } = this.props;

    if (!pickedSkillsList?.pickedSkills) {
      return [];
    }

    return pickedSkillsList.pickedSkills.map(userSkill => userSkill.skill);
  }

  handleCloseFeedbackRequestModal = () => {
    this.props.initializeComposer();
    this.props.modalHandlers.setRequestFeedbackModal(false);
  }

  handleCloseGiveFeedbackModal = () => {
    this.props.initializeComposer();
    this.props.modalHandlers.setGiveFeedbackModal(false);
  }

  handleCloseGiveKudosModal = () => {
    this.props.initializeComposer();
    this.props.modalHandlers.setSendKudosModal(false);
  }

  handleMakeOrEditRecurring = async ({ message, frequency, day, sendImmediately }) => {
    const {
      selectedPeer,
      userDropdownProps,
      handleMakeOrOverwriteRecurring,
      selectedSkillsIds,
      modalModes,
      modalHandlers
    } = this.props;
    const { isEditRecurringMode, showEmailDropdown } = modalModes;
    const { selectedUser } = userDropdownProps;

    const recurrence = {
      frequency,
      day,
    };
    const payload = {
      personId: showEmailDropdown ? selectedUser.id : selectedPeer.id,
      sendImmediately,
      message,
      recurrence,
      skillIds: selectedSkillsIds,
      isEditRecurringMode,
    };

    modalHandlers.setRecurringModal(false);
    userDropdownProps.removeSelectedUser();
    await handleMakeOrOverwriteRecurring(payload);
    handleTrack.recurringFeedbackSubmitted();
  }

  handleStopRecurringRequest = async () => {
    const { selectedPeer, handleStopRecurring, modalHandlers } = this.props;
    const { recurringFeedback } = selectedPeer;
    modalHandlers.setRecurringModal(false);
    await handleStopRecurring(recurringFeedback.id);
    handleTrack.onStopRecurring();
  }

  renderInfoModal() {
    const { selectedPeer, modalStates, modalHandlers, actionModalSelector, workspace, setIsOutOfKudosModalOpen, tenant } = this.props;
    const { kudosLeft } = workspace || {};
    const { isFree } = tenant; 

    if (!workspace) {
      return null;
    }
    
    if (modalStates.isInfoModalOpen) {
      return (
        <Advisors.InfoModal
          isOpen={modalStates.isInfoModalOpen}
          isRecurring={!!selectedPeer.recurringFeedback?.id}
          selectedAdvisor={selectedPeer}
          handleCloseModal={() => modalHandlers.setInfoModal(false)} 
          handleSelectAction={({ tooltipContent }) => {
            if (tooltipContent === actionMap.KUDOS && isFree && kudosLeft <= 0) {
              setIsOutOfKudosModalOpen(true);
              modalHandlers.setInfoModal(false);
            } else {
              actionModalSelector(tooltipContent, selectedPeer);
            }
          }}
        />
      );
    }
  }

  renderRecurringModal() {
    const {
      selectedPeer,
      selectedSkillsIds,
      userDropdownProps,
      onChangeSelectedSkillsIds,
      handleSelectMember,
      modalStates,
      modalHandlers,
      modalModes,
    } = this.props;
    const skillsList = this.getUserPickedSkillsList();

    if (modalStates.isRecurringModalOpen) {
      return (
        <Advisors.RecurringModal
          isOpen={modalStates.isRecurringModalOpen}
          isEditMode={modalModes.isEditRecurringMode}
          showEmailDropdown={modalModes.showEmailDropdown}
          selectedAdvisor={selectedPeer}
          recurringFeedback={selectedPeer.recurringFeedback}
          skillsList={skillsList}
          selectedSkillIds={selectedSkillsIds}
          userDropdownProps={userDropdownProps}
          updateSelectedSkills={onChangeSelectedSkillsIds}
          handleSelectMember={handleSelectMember}
          handleMakeOrEditRecurring={this.handleMakeOrEditRecurring}
          handleStopRecurring={this.handleStopRecurringRequest}
          handleCloseModal={() => modalHandlers.setRecurringModal(false)}
        />
      );
    }
  }

  renderRequestFeedbackModal() {
    const {
      selectedPeer,
      selectedSkillsIds,
      onChangeSelectedSkillsIds,
      handleSendFeedbackRequest,
      modalStates,
    } = this.props;
    const skillsList = this.getUserPickedSkillsList();

    if (modalStates.isRequestFeedbackModalOpen) {
      console.log('TUTA?', selectedSkillsIds);
      return (
        <Advisors.RequestFeedbackModal
          isOpen={modalStates.isRequestFeedbackModalOpen}
          selectedAdvisor={selectedPeer}
          skillsList={skillsList}
          selectedSkillIds={selectedSkillsIds}
          updateSelectedSkills={onChangeSelectedSkillsIds}
          handleSendRequest={async (message) => {
            await handleSendFeedbackRequest({
              selectedMember: selectedPeer,
              selectedSkillsIds,
              message,
            });
            this.handleCloseFeedbackRequestModal();
            handleTrack.requestFeedbackSubmitted();
          }}
          handleCloseModal={this.handleCloseFeedbackRequestModal}
        />
      );
    }
  }

  renderGiveFeedbackModal() {
    const { 
      selectedPeer,
      selectedSkill,
      isLoadingPeerSkills,
      getUserSkills,
      onChangeSelectedSkill,
      onResetSelectedSkill,
      onGiveFeedback,
      modalStates,
      workspace
    } = this.props;
    const skills = getUserSkills();
    
    if (modalStates.isGiveFeedbackModalOpen) {
      return (
        <Advisors.GiveFeedbackModal
          isOpen={modalStates.isGiveFeedbackModalOpen}
          selectedAdvisor={selectedPeer}
          skills={skills}
          selectedSkill={selectedSkill}
          isLoadingPeerSkills={isLoadingPeerSkills}
          handleSelectSkill={onChangeSelectedSkill}
          handleResetSelectedSkill={onResetSelectedSkill}
          handleSendFeedback={(note) => {
            this.handleCloseGiveFeedbackModal();
            onGiveFeedback({
              receiverUserId: selectedPeer.userId,
              note,
              skillId: selectedSkill && selectedSkill.id,
              workspaceId: workspace?.id
            });
            handleTrack.giveFeedbackSubmitted();
          }}
          handleCloseModal={this.handleCloseGiveFeedbackModal}
        />
      );
    }
  }

  renderGiveKudosModal() {
    const { 
      selectedPeer,
      modalStates,
      workspace,
      resetFeed,
    } = this.props;
    return (
      <GiveKudosModal
        showIsPublicDropdown
        isOpen={modalStates.isSendKudosModalOpen}
        onClose={this.handleCloseGiveKudosModal}
        selectedUser={selectedPeer}
        trackSubmission={handleTrack.giveKudosSubmitted}
        workspaceId={workspace?.id}
        resetFeed={resetFeed}
      />
    );
  }

  render() {
    if (this.props.selectedPeer && this.props.tenant) {
      return (
        <>
          {this.renderInfoModal()}
          {this.renderRecurringModal()}
          {this.renderRequestFeedbackModal()}
          {this.renderGiveFeedbackModal()}
          {this.renderGiveKudosModal()}
        </>
      );
    }
  }
};

export default ActionModals;
