import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Skill } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';
import SkillChartTooltip from './SkillChartTooltip';

class SkillChartHoverUser extends React.PureComponent {
  static propTypes = {
    skillName: PropTypes.string.isRequired,
    rating: PropTypes.shape({
      y: PropTypes.number.isRequired,
      user: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        photoUrl: PropTypes.string,
      }),
    }).isRequired,
    point: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }).isRequired
  };

  static userSize = 26;

  static Container = styled.div`
    position: absolute;
    width: ${SkillChartHoverUser.userSize}px;
    height: ${SkillChartHoverUser.userSize}px;
    margin-top: ${-SkillChartHoverUser.userSize / 2}px;
    margin-left: ${-SkillChartHoverUser.userSize / 2}px;
    pointer-events: none;
    cursor: pointer;

    img {
      width: ${SkillChartHoverUser.userSize}px;
      height: ${SkillChartHoverUser.userSize}px;
      border-radius: 50%;
      cursor: ${(props) => props.clickable ? 'pointer' : 'default'};

    }
  `;

  static Name = styled.span`
    font-size: ${() => theme.sizes.fontSizes[50]};
    font-weight: bold;
    padding-right: 8px;
  `;

  static Link = styled.a`

  `

  render() {
    const { point, skillName } = this.props;
    const { y, user, onClick } = this.props.rating;
    return (
      <SkillChartHoverUser.Container
        style={{ left: point.x, top: point.y }}
        clickable={onClick}
      >
        <SkillChartTooltip style={{ left: 11, top: 0 }}>
          <SkillChartHoverUser.Name>{user.fullName}</SkillChartHoverUser.Name>
          <Skill.Pill.Basic
            size="small"
            text={`${Math.round(y)}`}
            skill={{
              name: skillName,
            }}
          />
        </SkillChartTooltip>
      </SkillChartHoverUser.Container>
    );
  }
}

export default SkillChartHoverUser;
