import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, fill }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.70711 10.2929C4.31658 9.90237 3.68342 9.90237 3.29289 10.2929C2.90237 10.6834 2.90237 11.3166 3.29289 11.7071L4.70711 10.2929ZM10 17L9.29289 17.7071C9.68342 18.0976 10.3166 18.0976 10.7071 17.7071L10 17ZM20.2071 8.20711C20.5976 7.81658 20.5976 7.18342 20.2071 6.79289C19.8166 6.40237 19.1834 6.40237 18.7929 6.79289L20.2071 8.20711ZM3.29289 11.7071L9.29289 17.7071L10.7071 16.2929L4.70711 10.2929L3.29289 11.7071ZM10.7071 17.7071L20.2071 8.20711L18.7929 6.79289L9.29289 16.2929L10.7071 17.7071Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Icon;
