import React from 'react';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import SettingsMainPage from 'routes/pages/settings/MainSettings';
import GeneralSettingsPage from 'routes/pages/settings/General';
import FeedbackFridaySettingsPage from 'routes/pages/settings/FeedbackFriday';
import KudosSettingsPage from 'routes/pages/settings/Kudos';
import BillingSettingsPage from 'routes/pages/settings/Billing';
import HrisSettingsPage from 'routes/pages/settings/Hris';
import RewardsSettingsPage from 'routes/pages/settings/Rewards';
import CelebrationsSettingsPage from 'routes/pages/settings/Celebrations';
import PulseSettingsPage from 'routes/pages/settings/Pulse';
import MemberSettingsPage from 'routes/pages/settings/Members';
import DeleteSettingsPage from 'routes/pages/settings/Delete';
import { Route } from 'react-router-dom';

export const SettingsMain = (
  <Route
    path={Resources.workspaceSettingsMain.pattern}
    element={(
      <PrivateRoute>
        <SettingsMainPage />
      </PrivateRoute>    
    )}
  />
);

export const SettingsGeneral = (
  <Route
    exact
    path={Resources.workspaceSettingsGeneral.pattern}
    element={(
      <PrivateRoute>
        <GeneralSettingsPage />
      </PrivateRoute>    
    )}
  />
);

export const SettingsFeedbackFriday = (
  <Route
    exact
    path={Resources.workspaceSettingsFeedbackFriday.pattern}
    element={(
      <PrivateRoute>
        <FeedbackFridaySettingsPage />
      </PrivateRoute>    
    )}
  />
);

export const SettingsKudos = (
  <Route
    exact
    path={Resources.workspaceSettingsKudos.pattern}
    element={(
      <PrivateRoute>
        <KudosSettingsPage />
      </PrivateRoute>    
    )}
  />
);

export const SettingsBilling = (
  <Route
    exact
    path={Resources.workspaceSettingsBilling.pattern}
    element={(
      <PrivateRoute>
        <BillingSettingsPage />
      </PrivateRoute>    
    )}
  />
);

export const SettingsHris = (
  <Route
    exact
    path={Resources.workspaceSettingsHris.pattern}
    element={(
      <PrivateRoute>
        <HrisSettingsPage />
      </PrivateRoute>    
    )}
  />
);

export const SettingsRewards = (
  <Route
    exact
    path={Resources.workspaceSettingsRewards.pattern}
    element={(
      <PrivateRoute>
        <RewardsSettingsPage />
      </PrivateRoute>    
    )}
  />
);

export const SettingsCelebrations = (
  <Route
    exact
    path={Resources.workspaceSettingsCelebrations.pattern}
    element={(
      <PrivateRoute>
        <CelebrationsSettingsPage />
      </PrivateRoute>    
    )}
  />
);

export const SettingsPulse = (
  <Route
    exact
    path={Resources.workspaceSettingsPulse.pattern}
    element={(
      <PrivateRoute>
        <PulseSettingsPage />
      </PrivateRoute>    
    )}
  />
);

export const SettingsMembers = (
  <Route
    exact
    path={Resources.workspaceSettingsMembers.pattern}
    element={(
      <PrivateRoute>
        <MemberSettingsPage />
      </PrivateRoute>    
    )}
  />
);

export const SettingsDelete = (
  <Route
    exact
    path={Resources.workspaceSettingsDelete.pattern}
    element={(
      <PrivateRoute>
        <DeleteSettingsPage />
      </PrivateRoute>    
    )}
  />
);
