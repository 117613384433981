import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

const DEFAULT_MAX_AMOUNT = 3;
const DEFAULT_VALUE_NAME = 'value';

export const BUTTON_COMPONENT = styled(Button.Simple.Toggle).attrs({
  buttonType: Button.Simple.buttonTypes.CTA,
  bold: true,
})`
  padding: ${theme.spacing.half};
  min-height: 64px;
  ${theme.media.S`
    padding: ${theme.spacing.single};
    min-height: 0;
  `}
`;

/**
 * Returns a new array with the given index removed without mutating original array.
 * @param { Array } list: The array to filter.
 * @param { Number } index: The index of the element to remove.
 * @returns { Array } The new filtered array.
 */
export const removeFromArray = (list, index) => {
  return list.slice(0, index).concat(list.slice(index + 1));
};

export default class ToggleList extends React.PureComponent {
  static propTypes = {
    /** The button component to use. */
    ButtonComponent: PropTypes.elementType,
    /** Additional props for each button component */
    buttonProps: PropTypes.object,
    /** The total max amount of buttons that can be toggled. */
    maxAmount: PropTypes.number,
    /** Callback when currently selected value changes. */
    onChange: PropTypes.func.isRequired,
    /** The items to display. */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ).isRequired,
    /** The currently selected items by id. */
    value: PropTypes.arrayOf(PropTypes.string),
    /** The named value passed back in callback props; */
    valueName: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    ButtonComponent: BUTTON_COMPONENT,
    buttonProps: {},
    color: Button.Simple.buttonColors.BLUE,
    maxAmount: DEFAULT_MAX_AMOUNT,
    value: [],
    valueName: DEFAULT_VALUE_NAME,
  };

  /**
   * Callback when a button is toggled.
   * Calls onChange with click event and updated props with updated value array.
   * @param { Object } ev: The click event.
   * @param { Object } buttonProps: Props of the clicked button to get the value.
   * @returns { void }
   */
  handleClickToggleButton = (ev, buttonProps) => {
    const { value: buttonValue } = buttonProps;
    const { onChange, value, valueName } = this.props;

    const index = value.indexOf(buttonValue);
    const changedValue = value.includes(buttonValue)
      ? removeFromArray(value, index)
      : value.concat(buttonValue);

    onChange(ev, {
      ...this.props,
      value: changedValue,
      [valueName]: changedValue,
    });
  };

  render() {
    const {
      ButtonComponent,
      buttonProps,
      color,
      items,
      maxAmount,
      value,
      valueName,
    } = this.props;
    return items.map((item, index) => {
      const { name, id } = item;
      const isSelected = value.includes(id);
      return (
        <ButtonComponent
          color={color}
          content={name}
          disabled={value.length === maxAmount && !isSelected}
          isSelected={isSelected}
          key={`${index}-${valueName}-${name}`}
          onClick={this.handleClickToggleButton}
          value={id}
          rcLabel={`item-${index + 1}`}
          tabIndex="-1"
          {...buttonProps}
        />
      );
    });
  }
}
