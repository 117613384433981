import React from 'react';
import Base from '../../../svgBase';

const DEFAULT_HEIGHT = 18;
const DEFAULT_WIDTH = 14;
const PATH = `M6.92871 17.2187C10.6787 17.2187 13.3428 15.2344 13.335 12.4531C13.3428 10.4844 12.0537 9.07812 9.58496 8.78906V8.66406C11.4365 8.39844 12.749 7.15625 12.7412 5.35938C12.749 2.75 10.3896 0.781249 6.95996 0.781249C3.50684 0.781249 0.991211 2.78125 0.959961 5.625H4.65527C4.68652 4.53125 5.6709 3.79687 6.95996 3.79687C8.18652 3.79687 9.02246 4.53906 9.01465 5.60937C9.02246 6.71875 8.03809 7.48437 6.61621 7.48437H5.06152V10.2344H6.61621C8.2334 10.2344 9.28809 11.0313 9.27246 12.1562C9.28809 13.2891 8.31934 14.0781 6.94434 14.0781C5.57715 14.0781 4.56934 13.3516 4.53027 12.2969H0.639648C0.670898 15.1875 3.27246 17.2187 6.92871 17.2187Z`;

const Three = (props) => (
  <Base height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
);

export default Three;