import React from 'react';
import { Skill } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import { Field as ReduxFormField } from 'redux-form';

const ColorPaletteField = class extends React.PureComponent {
  renderField = (reduxFormFieldProps) => {
    const { value, onChange } = reduxFormFieldProps.input;
    const computedValue = value && value.length > 0 ? value : null;
    return (
      <Skill.Generator.ColorButtons
        selectedColorPalette={computedValue}
        onSelectColor={onChange}
        label="Select a skill badge color"
      />
    );
  };

  render = () => (
    <ReduxFormField
      name={this.props.name}
      type="select"
      component={this.renderField}
    />
  );
};

ColorPaletteField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ColorPaletteField;
