import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Filter from './Filter';
import Stacked from './Stacked';

import map from 'lodash/map';

import Tab from './Tab';

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
`;

const defaultRenderTab = ({
  index, // eslint-disable-line
  children, // eslint-disable-line
  ...others
}) => (
  <Tab key={index} {...others}>
    <span>{children}</span>
  </Tab>
);

const Tabs = ({ tabs, renderTab, activeTab, onActivateTab }) => (
  <TabsContainer>
    {map(tabs, (tab, index) =>
      renderTab({
        index,
        onActivate: () => onActivateTab(index, tab),
        isActive: activeTab === index,
        children: tab,
      })
    )}
  </TabsContainer>
);

Tabs.defaultProps = {
  activeTab: null,
  renderTab: defaultRenderTab,
};

Tabs.propTypes = {
  activeTab: PropTypes.number, // index into `tabs` prop
  onActivateTab: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired, // eslint-disable-line
  renderTab: PropTypes.func,
};

Tabs.Filter = Filter;
Tabs.Stacked = Stacked;

export default Tabs;
