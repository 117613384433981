import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '@matterapp/matter-theme';
import moment from 'moment';
import { WeekDay } from '@matterapp/calendar-date';
import { FREQUENCY_NOUNS } from '../../../../common/libs/skill-ratings-map';
import { REPLACE_FREQUENCY_TEXT, DEFAULT_RECURRING_REQUEST_MESSAGE } from '../../../../copy-consts';
import Avatar from '../../../Avatar';
import Button from '../../../Button';
import { IconNew as Icon } from '../../../Icon';
import { Modal } from '../../../Modal';
import Tooltip from '../../../Tooltip';
import {
  MESSAGE_LENGTH,
  AdvisorPropType,
  SkillPropType,
  frequencyOptions,
  defaultStartingOption,
} from '../../consts';
import {
  frequencyOptionsList,
  weeklyStartingOptions,
  monthlyStartingOptions,
  capitalizeFirstLetter,
  generateRecurrenceTooltip
} from '../../helpers';
import {
  ModalHeader,
  TitleSection,
  EmailDropdownContainer,
  EmailDropdown,
  AvatarContainer,
  TooltipContainer,
  FrequencySection,
  FrequencyContainer,
  FrequencyDescription,
  DropdownContainer,
  ButtonContainer,
  StopRecurringButton,
  CustomSettingsButton,
} from './styles';
import FrequencyDropdown from './Dropdown';
import Body from './Body';

const RecurringIcon = styled(Icon).attrs({ name: 'recurringIndicator', fill: colors.blacks[60] })``;

const INITIAL_STATE = {
  isBodyOpen: true,
  message: DEFAULT_RECURRING_REQUEST_MESSAGE,
  sendImmediately: false,
  selectedFrequency: frequencyOptionsList[2],
  selectedStart: defaultStartingOption,
  hasMadeChanges: false,
};

export default class RecurringModal extends React.Component {
  static propTypes = {
    /** If the modal is open. */
    isOpen: PropTypes.bool.isRequired,
    /** Advisor selected. */
    selectedAdvisor: AdvisorPropType.isRequired,
    /** List of available skills to choose from. */
    skillsList: PropTypes.arrayOf(SkillPropType),
    /** List of user skill ids. Used for selecting/deselecting skill pills */
    selectedSkillIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Function to select/deselect skills. */
    updateSelectedSkills: PropTypes.func.isRequired,
    /** Function to add advisor. */
    handleMakeOrEditRecurring: PropTypes.func.isRequired,
    /** Function to close modal. */
    handleCloseModal: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    const { recurringFeedback, showEmailDropdown, isEditMode } = this.props;
    if (!isEditMode) {
      this.setState({ isBodyOpen: false });
    }
    if (showEmailDropdown || !recurringFeedback) { 
      const defaultRecurringNote = DEFAULT_RECURRING_REQUEST_MESSAGE.replace(REPLACE_FREQUENCY_TEXT, FREQUENCY_NOUNS['monthly']);
      this.setState({ message: defaultRecurringNote });
      return null; 
    }
    
    const { recurrence, customNote } = recurringFeedback;
    const selectedFrequency = frequencyOptions[recurrence.frequency];
    const startingOptions = recurrence.frequency === 'weekly' || recurrence.frequency === 'biweekly'  ? weeklyStartingOptions : monthlyStartingOptions;
    const selectedStart = startingOptions.filter(option => option.day === recurrence.day)[0];
    const frequencyNoun = FREQUENCY_NOUNS[recurrence.frequency];
    if (customNote) {
      this.setState({ message: customNote });
    } else {
      const defaultRecurringNote = DEFAULT_RECURRING_REQUEST_MESSAGE.replace(REPLACE_FREQUENCY_TEXT, frequencyNoun);
      this.setState({ message: defaultRecurringNote });
    }
    if (selectedStart !== this.state.selectedStart) {
      this.setState({ selectedStart });
    }
    if (selectedFrequency !== this.state.selectedFrequency) {
      this.setState({ selectedFrequency });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedFrequency } = this.state;
    if (prevState.selectedFrequency !== selectedFrequency && !this.props.recurringFeedback?.customNote) {
      const frequency = Object.keys(frequencyOptions)[Object.values(frequencyOptions).indexOf(selectedFrequency)];
      const frequencyNoun = FREQUENCY_NOUNS[frequency];
      const defaultRecurringNote = DEFAULT_RECURRING_REQUEST_MESSAGE.replace(REPLACE_FREQUENCY_TEXT, frequencyNoun);
      this.setState({ message: defaultRecurringNote });
    }
  }

  handleChangeMessage = (e, { message }) => {
    this.setState({ message, hasMadeChanges: true });
  }

  handleClearMessage = () => {
    this.setState({ message: '' });
  }

  handleChangeFrequency = (selectedOption) => {
    if (this.state.selectedFrequency !== selectedOption) {
      this.setState({
        selectedFrequency: selectedOption,
        hasMadeChanges: true,
      });
    }
  }

  handleChangeSkills = (skill) => {
    this.props.updateSelectedSkills(skill);
    this.setState({ hasMadeChanges: true });
  }

  handleMakeOrEditRecurring = async () => {
    const { message, selectedStart, selectedFrequency } = this.state;

    let sendImmediately = false;
    const frequency = this.getFrequencyLabel().toLowerCase();
    if (selectedStart === defaultStartingOption) {
      sendImmediately = true;
      if (selectedFrequency === frequencyOptions.monthly || selectedFrequency === frequencyOptions.bimonthly || selectedFrequency === frequencyOptions.quarterly) {
        defaultStartingOption.day = JSON.parse(moment().format('D'));
      } else {
        defaultStartingOption.day = WeekDay[moment().format('dddd')];
      }
    }
    await this.props.handleMakeOrEditRecurring({
      message,
      frequency,
      day: selectedStart.day,
      sendImmediately,
    });
  }

  handleCloseModal = async () => {
    await this.props.handleCloseModal();
    this.setState(INITIAL_STATE);
  }

  handleOpenCustomization = (e) => {
    e.preventDefault();
    this.setState({ isBodyOpen: true });
  }

  getStartingDateLabel() {
    const { selectedStart } = this.state;

    if (selectedStart && selectedStart.label === defaultStartingOption.label) {
      return `Today (the ${moment().format('Do')})`;
    }
    return selectedStart.label;
  }

  getFrequencyLabel() {
    const { selectedFrequency } = this.state;
    const frequency = Object.keys(frequencyOptions).find(key => frequencyOptions[key] === selectedFrequency);
    return capitalizeFirstLetter(frequency);
  }

  renderUser() {
    const {
      selectedAdvisor,
      showEmailDropdown,
      userDropdownProps,
    } = this.props;

    if (showEmailDropdown) {
      return (
        <EmailDropdownContainer>
          <EmailDropdown {...userDropdownProps} placeholder={'Select a member'}/>
        </EmailDropdownContainer>
      );
    } 
    const tooltipContent = selectedAdvisor?.recurringFeedback ? generateRecurrenceTooltip(selectedAdvisor.recurringFeedback.recurrence) : null;
    return (
      <TitleSection>
        <AvatarContainer>
          <Avatar url={selectedAdvisor.photoUrl} size={48}/>
        </AvatarContainer>
        {selectedAdvisor.fullName || selectedAdvisor.email}
        {tooltipContent && 
          <TooltipContainer>
            <Tooltip
              content={tooltipContent}
              style={{ minWidth: 'min-content' }}
            >
              <RecurringIcon />
            </Tooltip>
          </TooltipContainer>}
      </TitleSection>
    );
  }

  renderDropdown() {
    const { selectedFrequency, selectedStart } = this.state;
    const { isEditMode, recurringFeedback } = this.props;
    const frequency = this.getFrequencyLabel();
    
    let startingVerb = 'Starts';
    let startingDate = this.getStartingDateLabel();

    if (isEditMode && selectedStart.label !== defaultStartingOption.label) {
      startingVerb = 'Next request will be sent on';
      startingDate = moment(recurringFeedback.nextDeliveryOn).format('ll');
    }

    return (
      <>
        <DropdownContainer>
          <FrequencyContainer>
            <FrequencyDropdown 
              width={'552px'}
              disabled={isEditMode}
              position={isEditMode ? 'absolute' : 'fixed'}
              options={frequencyOptionsList}
              selectedOption={selectedFrequency}
              handleChangeSelection={this.handleChangeFrequency}
            />
          </FrequencyContainer>
        </DropdownContainer>
        <FrequencyDescription>
          {startingVerb} <strong>{startingDate}</strong> and recurs <strong>{frequency}</strong>
        </FrequencyDescription>
      </>
    );
  }

  renderCustomization() {
    const { message, isBodyOpen } = this.state;
    const {
      isEditMode,
      skillsList,
      selectedSkillIds,
    } = this.props;

    const customizationProps = {
      isOpen: isBodyOpen,
      message,
      skillsList,
      selectedSkillIds,
      handleClearMessage: this.handleClearMessage,
      handleChangeMessage: this.handleChangeMessage,
      updateSelectedSkills: this.handleChangeSkills,
    };
    // depending on if it's in editing mode or not, the modal body needs different transition and height refs
    // to display animation. So we are using two different skills selection/message components to render correctly.
    return (
      <Body 
        isEditMode={isEditMode}
        customizationProps={customizationProps}
      />
    );
  }

  render() {
    const { message, hasMadeChanges, isBodyOpen } = this.state;
    const {
      isOpen,
      isEditMode,
      selectedAdvisor,
      showEmailDropdown,
      userDropdownProps,
      handleStopRecurring,
    } = this.props;
    let modalTitle;
    if (isEditMode) {
      modalTitle = 'Edit Continuous';
    } else {
      modalTitle = 'Set Up Continuous';
    }
    const hasSelectedUser = showEmailDropdown ? !!userDropdownProps.selectedUser : Object.keys(selectedAdvisor).length !== 0;
    const cantSend = !hasSelectedUser || (isEditMode && !hasMadeChanges) || message.length < MESSAGE_LENGTH.MIN;

    return (
      <Modal
        contentLabel="Recurring Modal"
        size="large"
        isOpen={isOpen}
        transparent
        fullScreenMobile
        onClose={this.handleCloseModal}
      >
        <ModalHeader
          title={modalTitle}
          right={<Modal.Header.Buttons.Close onClick={this.handleCloseModal} />}
        />
        <Modal.Body>
          {this.renderUser()}
          <FrequencySection>
            Frequency:
            {this.renderDropdown()}
            <CustomSettingsButton isHidden={isBodyOpen} onClick={this.handleOpenCustomization}>Custom Settings</CustomSettingsButton>
          </FrequencySection>
          {this.renderCustomization()}
          {isEditMode && 
          <ButtonContainer>
            <StopRecurringButton color={'red'} onClick={handleStopRecurring}>
              Delete Continuous
            </StopRecurringButton>
          </ButtonContainer>}
        </Modal.Body>
        <Modal.Footer.ButtonContainer
          left={
            <Button.Simple
              color="black"
              onClick={this.handleCloseModal}
            >
              Cancel
            </Button.Simple>
          }
          right={
            <Button.Simple.Primary.Vivid
              color="green"
              disabled={cantSend}
              onClick={this.handleMakeOrEditRecurring}
            >
              Save
            </Button.Simple.Primary.Vivid>
          }
        />
      </Modal>
    );
  }
}