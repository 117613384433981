import React from 'react';
import { compose } from 'recompose';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import { FeedbackRequestsPage } from 'routes/pages/feedback-requests';
import { withPageViewTracking, withRouteParams } from 'hocs';
import { Route } from 'react-router-dom';

const feedbackRequestsMainHoc = compose(
  withPageViewTracking({
    pageTitle: 'feedbackRequests/main',
  }),
  withRouteParams({
    viewOrUpdate: true,
    invitationUuid: true,
  })
);

const FeedbackRequestsPageComponent = feedbackRequestsMainHoc(FeedbackRequestsPage);

export const FeedbackRequestsMain = (
  <Route
    exact
    path={Resources.feedbackRequestsMain.pattern}
    element={(
      <PrivateRoute>
        <FeedbackRequestsPageComponent />
      </PrivateRoute>
    )}
  />
);

const feedbackRequestsViewHoc = compose(
  withPageViewTracking({
    pageTitle: 'feedbackRequests/view',
  }),
  withRouteParams({
    invitationUuid: true,
  })
);

const FeedbackRequestsViewComponent = feedbackRequestsViewHoc(FeedbackRequestsPage);
export const FeedbackRequestsView = (
  <Route
    exact
    path={Resources.viewFeedbackRequest.pattern}
    element={(
      <PrivateRoute>
        <FeedbackRequestsViewComponent />
      </PrivateRoute>
    )}
  />
);
