import React from 'react';
import { Confirm } from '@matterapp/matter-ui';

export default function ConfirmSurveyModal(props) {
  const {
    isOpen,
    onClose,
    text,
    confirmButtom,
    onConfirm,
    isDestructive,
    header = 'Are you sure?',
    confirmButtonDisabled
  } = props;
  
  return (
    <Confirm
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      subHeader={text}
      cancelButtonLabel="Close"
      confirmButtonLabel={confirmButtom}
      onClickConfirm={onConfirm}
      onClickCancel={onClose}
      confirmButtonIsDestructive={isDestructive}
      confirmButtonProps={{
        disabled: confirmButtonDisabled
      }}
    />
  );
};
