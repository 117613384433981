import React from 'react';
import { Resources } from '@matterapp/routing';
import {
  ReferralLinkPage,
} from '../pages/referral-link';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import { Route } from 'react-router-dom';

const isRenderReferralLink = ({ userStatus }) =>
  userStatus.isLoggedIn && userStatus.isOnboarded;

export const ReferralLink = (
  <Route
    exact
    path={Resources.referralLink.pattern}
    element={(
      <PrivateRoute
        isRender={isRenderReferralLink}
      >
        <ReferralLinkPage />
      </PrivateRoute>
    )}
  />
);

