import React from 'react';
import PropTypes from 'prop-types';
import { getPngIcon, getSvgIcon, getInvertedSvgIcon } from './get-icon';

const Icon = (props) => {
  const { name, width, height, inverted } = props;
  const pngIconSrc = getPngIcon(name);
  if (pngIconSrc) {
    return (
      <img
        alt={`${name}-icon`}
        src={pngIconSrc}
        width={width}
        height={height}
      />
    );
  }

  const IconToRender = inverted ? getInvertedSvgIcon(name) : getSvgIcon(name);

  if (!IconToRender) {
    console.log('Icon', name, 'not found'); // eslint-disable-line
    return null;
  }

  return <IconToRender {...props} />;
};

Icon.defaultProps = {
  width: 32,
  height: 32,
  inverted: false,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Icon;
