import styled from 'styled-components';

const Shockwave = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  background:
    radial-gradient(
      transparent 60%,rgba(233,233,246, .8) 65%,
      rgba(233,233,246, .8) 66%,transparent 70%);

  background-size:0 0;
  background-position:center;
  background-repeat:no-repeat;

  animation: shockwave 2s 0s ease;
  animation-delay: 200ms;

  @keyframes shockwave {
    0% {
      background-size: 0 0;
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      background-size: 480px 480px;
      opacity: 0;
    }
  }
`;

export default Shockwave;
