import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="16"
      cy="16"
      r="15.5"
      stroke={fill}
      strokeMiterlimit="3.62796"
      strokeDasharray="4 4"
    />
    <path
      d="M9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17V15ZM23 17C23.5523 17 24 16.5523 24 16C24 15.4477 23.5523 15 23 15V17ZM15 23C15 23.5523 15.4477 24 16 24C16.5523 24 17 23.5523 17 23H15ZM17 9C17 8.44772 16.5523 8 16 8C15.4477 8 15 8.44772 15 9H17ZM9 17H23V15H9V17ZM17 23V9H15V23H17Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16.5523 8 17 8.44772 17 9V15H23C23.5523 15 24 15.4477 24 16C24 16.5523 23.5523 17 23 17H17V23C17 23.5523 16.5523 24 16 24C15.4477 24 15 23.5523 15 23V17H9C8.44772 17 8 16.5523 8 16C8 15.4477 8.44772 15 9 15H15V9C15 8.44772 15.4477 8 16 8Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#343799',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
