import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from '../../Loader';
import { FEED_ITEM_CONTAINER_CLASS_NAME } from '../consts';
import theme from '@matterapp/matter-theme';

const FeedItemsContainer = styled.div`
  .${FEED_ITEM_CONTAINER_CLASS_NAME} + .${FEED_ITEM_CONTAINER_CLASS_NAME} {
    margin-top: 24px;
  }
`;

const LoaderContainer = styled.div`
  padding: 24px;
  position: relative;
  min-height: 160px;
  background: white;
  border-bottom: 1px solid ${() => theme.colors.blacks[10]};
`;

class FeedItemsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ scrollTop: window.scrollY, windowHeight: window.innerHeight });
  }

  renderLoading = () => (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );

  renderFeedItems = () => {
    const { feedItems, renderFeedItem } = this.props;
    return (
      <FeedItemsContainer>
        {feedItems.map((feedItem, index) => (
          <React.Fragment key={feedItem.id}>
            {renderFeedItem(feedItem, { ...this.state, index })}
          </React.Fragment>
        ))}
      </FeedItemsContainer>
    );
  };

  render = () => {
    const { feedItems, isLoading, renderEmptyList } = this.props;
    if (isLoading) {
      return this.renderLoading();
    }
    if (feedItems.length === 0) {
      return renderEmptyList();
    }
    return this.renderFeedItems();
  };
}

FeedItemsList.defaultProps = {
  renderEmptyList: () => null,
};

FeedItemsList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  renderFeedItem: PropTypes.func.isRequired,
  feedItems: PropTypes.array.isRequired,
  renderEmptyList: PropTypes.func,
};

export default FeedItemsList;
