import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clickable } from '../../Clickable/Clickable';
import { IconNew } from '../../Icon';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  z-index: ${theme.zIndex.sidePanelOverlay};
`;

const MenuBackground = styled.div`
  background: ${theme.colors.black};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity ${theme.transitions.times.default};
  z-index: ${theme.zIndex.sidePanelOverlay};

  ${({ isOpen }) =>
    isOpen
      ? `opacity: 0.48;
         pointer-events: auto;
    `
      : `opacity: 0;
         pointer-events: none
    `};
`;

const MenuContainer = styled.div`
  position: absolute;
  background: ${theme.colors.white};
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 200px;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  overflow-y: auto;
  transform: translateX(${({ isOpen }) => (isOpen ? '0%' : '100%')});
  transition: transform ${theme.transitions.times.default},
    opacity 0s
      ${({ isOpen }) => (isOpen ? '' : theme.transitions.times.default)};
  z-index: ${theme.zIndex.sidePanel};
`;

const CloseIconContainer = styled(Clickable)`
  cursor: pointer;
  position: absolute;
  top: ${theme.spacing.single};
  right: ${theme.spacing.single};
  z-index: 10;
  svg path {
    fill: ${theme.colors.blacks[60]};
  }
`;

const CloseIcon = styled(IconNew).attrs({ name: 'close' })``;

const SlideOutMenu = (props) => {
  const {
    children,
    className,
    clickBackgroundToClose,
    isOpen,
    onClickClose,
  } = props;
  return (
    <Container className={className} isOpen={isOpen}>
      <MenuBackground
        isOpen={isOpen}
        onClick={clickBackgroundToClose ? onClickClose : null}
      />
      <MenuContainer isOpen={isOpen}>
        <CloseIconContainer onClick={onClickClose}>
          <CloseIcon />
        </CloseIconContainer>
        {children}
      </MenuContainer>
    </Container>
  );
};

SlideOutMenu.propTypes = {
  // Additional style classes.
  className: PropTypes.string,
  // If background is clicked, `onClickClose` will be called.
  clickBackgroundToClose: PropTypes.bool,
  // If menu is open.
  isOpen: PropTypes.bool,
  // Callback when close button is clicked.
  onClickClose: PropTypes.func,
};

SlideOutMenu.MenuContainer = MenuContainer;

export default SlideOutMenu;
