import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const LabelContainer = styled.div`
  color: ${() => theme.colors.black};

  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;

  ${() => theme.media.medium`
    margin-left: 8px;
    font-size: 17px;
    line-height: 24px;
  `}
`;

export default LabelContainer;
