import React from 'react';
import { Section } from './styles';
import SkillsSelectionList from '../../../../Composer/UnactivatedUserComposer/Customization/SkillsSelectionList';
import MessageBox from '../../../../Composer/UnactivatedUserComposer/Customization/MessageBox';
import { SKILL_SELECTION_TITLE, MESSAGE_SECTION_TITLE } from '../../../consts';

const Body = ({
  skillsList,
  updateSelectedSkills,
  selectedSkillIds,
  message,
  handleClearMessage,
  handleChangeMessage,
}) => {
  return (
    <>
      <Section>
        <SkillsSelectionList 
          listOptions
          animated={false}
          heading={SKILL_SELECTION_TITLE}
          orderedSkills={skillsList}
          updateSelectedSkills={updateSelectedSkills}
          skillIds={selectedSkillIds}
        />
      </Section>
      <Section>
        <MessageBox
          heading={MESSAGE_SECTION_TITLE}
          customNote={message}
          clearText={handleClearMessage}
          onMessageChange={handleChangeMessage}
        />
      </Section>
    </>
  );
};

export default Body;