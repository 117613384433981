import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Input, Radio, Modal } from '@matterapp/matter-ui';
import DeleteModal from './DeleteModal';
import { Group } from '../sharedConsts';
import { Spacer, TimezoneDropdown } from './styles';
import { IMAGE_FILE_UPLOAD_ERROR_DETAILS } from 'routes/pages/settings/Kudos/AddKudosModal/consts';
import ImageUpload from 'modules/workspaces/shared/ImageUpload/ImageUpload';
import ChangeRitualFrequencyConfirmModal from 'routes/pages/settings/FeedbackFriday/ChangeRitualFrequencyConfirmModal';
import { useMutation } from '@apollo/client';
import updateTenant from 'graphql-queries/mutations/workspaces/updateTenant';
import getS3PresignedUrl from 'graphql-queries/mutations/getS3PresignedUrl';
import { useGeneralPanel } from 'routes/pages/workspaces/hooks/WorkspaceSettingsHooks';
import { useQueryParams } from 'hooks';
import UserContext from 'context/UserContext/UserContext';
import { SettingsFooter } from '../styles';

const CustomKudosSettings = styled(Radio.List.Settings)``;

const GENERAL_PANEL_TEXT = {
  AVATAR_HEADER: 'Tenant Avatar',
  AVATAR_SUB_HEADER: 'A visual identifier for your tenant.',
  DELETE_BUTTON: 'Delete Channel',
  DELETE_HEADER: 'The Big Red Button',
  HEADER: 'General',
  INVITATIONS_HEADER: 'Invitation Permissions',
  INVITATIONS_SUB_HEADER:
    'Control who can send invitations to join your channel',
  NAME_HEADER: 'Tenant Name',
  TIMEZONE_HEADER: 'Timezone',
};

const privateKudosSettings = {
  all: 'All Members',
  admins: 'Admins Only',
  none: 'No Members'
};

export default function GeneralSettings(props) {
  const { 
    workspace, 
    tenant,
    onClickCancel,
    isLoading
  } = props;
  const { queryParams } = useQueryParams();
  const { isChannelAdmin } = useContext(UserContext);
  const { isEnterprise } = tenant;

  const { 
    checkYourEmailModalProps,  
    ritualDateString, 
    isConfirmationModalOpen, 
    onClickConfirmSave, 
    onClickSave,
    onClickDelete
  } = useGeneralPanel({ tenant, workspace });
  const NAME_HEADER = isEnterprise ? 'Tenant Name': 'Channel Name';
  const AVATAR_HEADER = isEnterprise ? 'Tenant Avatar': 'Channel Avatar';
  const { name, isOwner, isAdmin, photoUrl, id: tenantId } = tenant;
  const {
    id: workspaceId,
    timezone,
    slackWorkspace,
    generalSettings
  } = workspace;
  const canDeleteWorkspace = isOwner || isAdmin || isChannelAdmin;
  const { settings } = slackWorkspace || {};
  const { customKudosAdminOnly, privateKudosPermission = 'all' } = generalSettings || settings || {};
  const [channelIconUrl, setChannelIconUrl] = useState(photoUrl);
  const [mutation, { loading: savingWorkspace }] = useMutation(updateTenant);
  const [s3PresignedUrlMutation] = useMutation(getS3PresignedUrl);
  const [tenantName, setTenantName] = useState(name);
  const [workspaceTimezone, setWorkspaceTimezone] = useState(timezone);
  const [isAdminOnly, setIsAdminOnly] = useState(customKudosAdminOnly);
  const [privateKudosAccess, setPrivateKudosAccess] = useState(privateKudosPermission);
  const [canClickSave, updateCanClickSave] = useState(false);
  const timezoneChanged = workspaceTimezone !== timezone;
  const workspaceNameChanged = tenantName !== name;
  const kudosSettingsUpdated = customKudosAdminOnly !== isAdminOnly;
  const privateKudosPermissionUpdated = privateKudosPermission !== privateKudosAccess;
  const workspaceSettingsUpdated = timezoneChanged || kudosSettingsUpdated || privateKudosPermissionUpdated;
  const timezoneSettings = { timezone: workspaceTimezone };

  useEffect(() => {
    setTenantName(name);
    setWorkspaceTimezone(timezone);
  }, [name, timezone]);

  useEffect(() => {
    if (photoUrl !== channelIconUrl) {
      mutation({
        variables: {
          tenantId,
          name,
          icon: channelIconUrl
        }
      });
    }
  }, [channelIconUrl]);

  useEffect(() => {
    if (tenantName !== name || workspaceTimezone !== timezone || kudosSettingsUpdated || privateKudosPermissionUpdated) {
      updateCanClickSave(true);
    } else {
      updateCanClickSave(false);
    }
  }, [
    tenantName,
    workspaceTimezone,
    isAdminOnly,
    privateKudosAccess
  ]);

  const handleChangeName = (e, changeProps) =>
    setTenantName(changeProps.value);

  const handleClickSave = async (e) => {
    await onClickSave(e, {
      ...props,
      workspace: {
        ...workspace,
        timezone: workspaceTimezone,
        name: tenantName,
      },
      workspaceId,
      name: tenantName,
      icon: channelIconUrl,
      timezoneSettings,
      workspaceUpdated: workspaceNameChanged,
      customKudosAdminOnly: isAdminOnly,
      privateKudosPermission: privateKudosAccess,
      workspaceSettingsUpdated,
      timezoneChanged
    });

    updateCanClickSave(false);
  };

  function handleChangeTimezone(e, changeProps) {
    setWorkspaceTimezone(changeProps.value);
  }

  async function onSaveIcon(props, file) {
    const presignedUrlData = await s3PresignedUrlMutation({
      variables: {
        prefix: 'teamAvatar'
      },
    });
    
    const { presignedUrl, imgixPath } = presignedUrlData.data.getS3PresignedUrl;

    await fetch(presignedUrl, {
      method: 'PUT',
      headers: { 
        'Content-Type': 'image/png'
      },
      body: file
    });

    setChannelIconUrl(imgixPath);
  }

  const adminSettings = isEnterprise ? !queryParams.isChannelSetting  : true;
  const channelSettings = isEnterprise ? queryParams.isChannelSetting  : true;

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Modal.Panel.Main
        header={GENERAL_PANEL_TEXT.HEADER}
        footer={<SettingsFooter
          primaryLabel='Save'
          canClickPrimary={canClickSave}
          onClickPrimary={handleClickSave}
          saveProps={{
            isLoading: savingWorkspace
          }}
        />}
      >
        {adminSettings && (
          <>
            <Group
              dividerBelow
              header={AVATAR_HEADER}
              subHeader={GENERAL_PANEL_TEXT.AVATAR_SUB_HEADER}
            >
              <ImageUpload
                url={photoUrl}
                onSaveIcon={onSaveIcon}
                onRemoveIcon={() => setChannelIconUrl(null)}
                workspace={workspace}
                changePhotoText="CHANGE AVATAR"
                changePhotoHeader="Change Avatar"
                defaultAvatarName="workspacesPageIcon"
                {...IMAGE_FILE_UPLOAD_ERROR_DETAILS}
              />
            </Group>
            <Group dividerBelow header={NAME_HEADER}>
              <Input 
                fluid 
                onChange={handleChangeName} value={tenantName} 
                rcLabel='channelNameInput'
              />
            </Group>
          </>
        )}
        {channelSettings && (
          <Group
            dividerBelow={!isAdmin || !isOwner}
            header={GENERAL_PANEL_TEXT.TIMEZONE_HEADER}
            subHeader='Send a feedback ritual kickoff, a reminder, and recap on this day.'
          >
            <TimezoneDropdown
              fluid
              onChange={handleChangeTimezone}
              value={workspaceTimezone}
            />
          </Group>
        )}
        {channelSettings && (
          <>
            <Group
              dividerBelow
              header={'Custom Kudos Permissions'}
              subHeader={'Control who can create custom kudos.'}
            >
              <CustomKudosSettings
                onChange={(e, { value }) => {
                  if (value == 'Admins Only') {
                    setIsAdminOnly(true);
                  } else {
                    setIsAdminOnly(false);
                  }
                }}
                value={isAdminOnly ? 'Admins Only' : 'All Members'}
                values={['All Members', 'Admins Only']}
              />
            </Group>
            <Group
              dividerBelow={canDeleteWorkspace}
              header={'Private Kudos Permissions'}
              subHeader={'Control who can send private kudos.'}
            >
              <CustomKudosSettings
                onChange={(e, { value }) => {
                  const valueKey = Object.keys(privateKudosSettings).find(key => privateKudosSettings[key] === value);

                  setPrivateKudosAccess(valueKey);
                }}
                value={privateKudosSettings[privateKudosAccess]}
                values={Object.values(privateKudosSettings)}
              />
            </Group>
            {canDeleteWorkspace && workspace && (
              <Group header={GENERAL_PANEL_TEXT.DELETE_HEADER}>
                <DeleteModal 
                  onClickDelete={onClickDelete} 
                  workspace={workspace} 
                  content='Delete Channel'
                />
              </Group>
            )}
            <Spacer />
          </>
        )}
        <ChangeRitualFrequencyConfirmModal 
          ritualDateString={ritualDateString}
          isOpen={isConfirmationModalOpen}
          onClickConfirm={onClickConfirmSave}
          onClickCancel={onClickCancel}
        />
      </Modal.Panel.Main>
      <Modal.Presets.CheckYourEmail {...checkYourEmailModalProps} />
    </>
  );
}
