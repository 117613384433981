import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  APP_ELEMENT,
  CONTENT_LABEL,
  MODAL_ROLE,
  OPEN_BODY_CLASS,
  OPEN_HTML_CLASS,
} from '../../consts';
import { useAdapterState } from './utils';

const ModalAdapter = (props) => {
  const {
    bodyOpenClassName,
    className,
    contentClassName,
    htmlOpenClassName,
    onClose,
    overlayClassName,
    ...otherProps
  } = useAdapterState(props);

  return (
    <ReactModal
      ariaHideApp
      bodyOpenClassName={bodyOpenClassName}
      className={contentClassName}
      closeTimeoutMS={300}
      htmlOpenClassName={htmlOpenClassName}
      onRequestClose={async () =>  await onClose()}
      overlayClassName={overlayClassName}
      portalClassName={className}
      role={MODAL_ROLE}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      {...otherProps}
    />
  );
};

ModalAdapter.defaultProps = {
  appElement: APP_ELEMENT,
  contentLabel: CONTENT_LABEL,
  htmlOpenClassName: OPEN_HTML_CLASS,
  bodyOpenClassName: OPEN_BODY_CLASS,
  isOpen: false,
};

ModalAdapter.propTypes = {
  appElement: PropTypes.string,
  bodyOpenClassName: PropTypes.string,
  className: PropTypes.string.isRequired,
  contentClassName: PropTypes.string.isRequired,
  contentLabel: PropTypes.string,
  htmlOpenClassName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  overlayClassName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

export default ModalAdapter;
