import React, { useEffect, useContext, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import UserContext from 'context/UserContext/UserContext';
import getRewardsSpreadsheet from 'graphql-queries/queries/analytics/getRewardsSpreadsheet';
import DateFilterBar, { DownloadButton, downloadSpreadsheet, buildCustomDateRange } from 'components/DateFilterBar/DateFilterBar';
import moment from 'moment';
import { 
  Box, 
  Typography, 
  Paper
} from '@mui/material';
import { List, ListItem } from './styles';

const dateFilters = [
  {
    label: 'This Month',
    value: 'this month',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    groupedBy: 'GROUPED BY WEEK',
    groupBy: 'week',
    xAxisResolution: 5,
    visibleOnMobile: true
  },
  {
    label: 'Last Month',
    value: 'last month',
    groupBy: 'week',
    startDate: moment().add(-1, 'month').startOf('month'),
    endDate: moment().add(-1, 'month').endOf('month'),
    groupedBy: 'GROUPED BY WEEK',
    xAxisResolution: 5,
    visibleOnMobile: true
  }
];

export default function ReportsPage() {
  const { 
    currentTenant
  } = useContext(UserContext);
  const { id: tenantId } = currentTenant || {};
  const [selectedFilter, setSelectedFilter] = useState(dateFilters[0]);

  const [requestAnalyticsSpreadsheet, { data: spreadsheetData, loading: requestingSpreadsheet }] = useLazyQuery(getRewardsSpreadsheet, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (spreadsheetData && !requestingSpreadsheet) {
      downloadSpreadsheet(spreadsheetData.rewardsSpreadsheet, 'rewardsReport');
      Toast.success('Download started.');
    }
  }, [requestingSpreadsheet, spreadsheetData]);

  const handleChangeFilter = (value, isCustom) => {
    let selectedValue;
    console.log({value, isCustom});
    if (isCustom) {
      selectedValue = buildCustomDateRange(value.from, value.to);
    } else {
      selectedValue = dateFilters.find((filter) => filter.value === value);
    }
    

    setSelectedFilter(selectedValue);
  };

  return (
    <Box>
      <DateFilterBar 
        startDate={selectedFilter.startDate}
        endDate={selectedFilter.endDate}
        value={selectedFilter.value}
        onChange={handleChangeFilter}
        groupedBy={selectedFilter.groupBy}
        items={dateFilters}
      >
        <DownloadButton 
          disabled={requestingSpreadsheet}
          onClick={() => requestAnalyticsSpreadsheet({
            variables: {
              tenantId,
              startDate: selectedFilter.startDate.format('YYYY-MM-DD'),
              endDate: selectedFilter.endDate.format('YYYY-MM-DD')
            }
          })}>
          Download Report (.XLSX)
        </DownloadButton>
      </DateFilterBar>
      <Paper elevation={1}>
        <Typography variant='body1'>
          Select a date range and click Download Report (.XLSX) to receive an advanced Rewards Report. Depending on rewards features being used, the rewards report may include but is not limited to:
        </Typography>
        <List>
          <ListItem>        
            <Typography variant='body1'>
              <b>Rewards Report:</b> Breakdown of each team member and their coin allowance per period, remaining coins per period, coins earned, coins redeemed, and unredeemed coins.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body1'>
              <b>Redeemed Rewards:</b> Breakdown of reward request including the requester name and email, order status of the request, the requested reward and reward amount, the amount charged to the payment method and the currency charged (USD), and the coin amounts redeemed.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body1'>
              <b>Rewards Balance Transactions:</b> List of all rewards balance transactions, including timestamp, credits/debits, amount, type, ending balance, etc.  
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body1'>
              <b>Rewards Balance Invoices:</b> List of prepay invoice transactions, including amount, status, payment date, etc.
            </Typography>
          </ListItem>
        </List>
        <Typography variant='body1'>
          <a 
            href='https://docs.google.com/spreadsheets/d/1zvyF1ftJD_Z2EogNgVn9tfKoHVGJT24ICpEaoZolKHQ/view?gid=0#gid=0'
            target='_blank'
            rel='noopener noreferrer'
          >Sample Rewards Report</a>
        </Typography>
      </Paper>
    </Box>
  );
};
