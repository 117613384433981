import { skillColorPalettes as colorPalettes } from '@matterapp/matter-theme';

const getPalette = (name) => {
  const palette = colorPalettes[name];
  if (palette) { return palette; }
  return null;
};

const defaultPalette = colorPalettes['Blue'];

export {
  colorPalettes,
  getPalette,
  defaultPalette
};