import React from 'react';
import PropTypes from 'prop-types';
import { Animations, getSkillColorPaletteFromSkill } from '@matterapp/matter-ui';
import {
  Container,
  BackgroundContainer,
  CurrentBackground,
} from './styles';

const ANIMATIONS = Animations.Animation.ANIMATIONS;

export default class Background extends React.PureComponent {
  static propTypes = {
    currentStep: PropTypes.number,
    skills: PropTypes.array,
    numberOfSkills: PropTypes.number,
    playAnimationOnMount: PropTypes.bool,
    totalSteps: PropTypes.number,
  };

  static defaultProps = {
    playAnimationOnMount: true,
    currentSkill: { skill: {} },
  };

  /**
   * Gets the background of the given skill.
   * @param { Object } currentSkill: THe skill to get the color of.
   * @returns { String } The color of the skill.
   */
  getBackgroundColor = (currentSkill) => {
    const { skill } = currentSkill || {};
    return skill && skill.name
      ? getSkillColorPaletteFromSkill(skill).toLowerCase()
      : 'blue';
  };

  /**
   * Gets the corresponding background fro the current step.
   * @param { Object } backgroundProps: {
   *   @param { Number } currentStep: The number of the current step.
   * }
   * @returns { React.Component } The rendered background.
   */
  getBackground = () => {
    const { currentStep, skills } = this.props;
    const animationProps = { animation: ANIMATIONS.IN };

    const backgrounds = [];
    backgrounds.push(<Animations.ResponseFlow.Recurring.Start {...animationProps}/>);
    for (const skill of skills) {
      const color = this.getBackgroundColor(skill);
      const Background = Animations.ResponseFlow.Recurring.Skill;
      backgrounds.push(
        <Background color={color} {...animationProps} />
      );
    }
    backgrounds.push(<Animations.ResponseFlow.Recurring.End {...animationProps}/>);
    return backgrounds[currentStep - 1];
  };

  render() {
    const { currentStep, totalSteps } = this.props;
    const isLastStep =  currentStep >= totalSteps;

    return (
      <Container {...this.props}>
        <BackgroundContainer showBackgroundOnMobile={isLastStep}>
          <CurrentBackground>
            {this.getBackground()}
          </CurrentBackground>
        </BackgroundContainer>
      </Container>
    );
  }
}
