import React from 'react';
import { propType } from 'graphql-anywhere';
import PropTypes from 'prop-types';
import { AvatarCard, ProfileCard } from '@matterapp/matter-ui';
import { PROFILE_CARD_QUERY } from 'graphql-queries/queries';

const Card = ({ minimal, profileCardData, useAvatarCard }) => {
  const { currentUser } = profileCardData;
  if (!currentUser) {
    return null;
  }
  if (useAvatarCard) {
    const { email, fullName, headline, photoUrl } = currentUser;
    return (
      <AvatarCard
        avatarProps={{ email, url: photoUrl }}
        title={fullName}
        rcLabel="profile-card"
        subHeader={headline}
      />
    );
  }
  return (
    <ProfileCard.Stats
      minimal={minimal}
      user={currentUser}
      qualityEndorsements={currentUser.endorsedQualities}
    />
  );
};

Card.propTypes = {
  minimal: PropTypes.bool,
  profileCardData: propType(PROFILE_CARD_QUERY).isRequired,
  useAvatarCard: PropTypes.bool,
};

Card.defaultProps = {
  minimal: false,
};

export default Card;
