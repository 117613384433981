import styled from 'styled-components';
import theme, { media, spacing } from '@matterapp/matter-theme';
import Button from '../../../Button';
import { Clickable } from '../../../Clickable/Clickable';
import { IconNew } from '../../../Icon';
import { ICON_SIZE_VALUE } from '../Tab/styles';
import NavBar from '../../../NavBar';
import Tab from '../Tab';
import {
  CREATE_WORKSPACE_TITLE,
  FEEDBACKS_TITLE,
  RECURRING_TITLE,
  SKILLS_TITLE,
  FEED_TITLE,
  INSIGHTS_TITLE,
  MEMBERS_TITLE,
  SETTINGS_TITLE,
  DESKTOP_SETTINGS_TITLE,
  REWARDS_STORE_TITLE,
  REWARDS_MANAGER_TITLE,
  ANALYTICS_TITLE,
} from './consts';

export const restrictTextHeightStyles = `
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const IconContainer = styled.div`
  overflow: hidden;
  border-radius: 100%;

  ${({ size }) => `
    height: ${size || ICON_SIZE_VALUE}px;
    width: ${size || ICON_SIZE_VALUE}px;
    min-width: ${size || ICON_SIZE_VALUE}px;

    & svg {
      height: ${size || ICON_SIZE_VALUE}px;
      width: ${size || ICON_SIZE_VALUE}px;
      min-width: ${size || ICON_SIZE_VALUE}px;
      margin: 0px;
    }
  `};
`;

export const Icon = styled.img`
  margin: 0 !important;
  ${({ size }) => `
    height: ${size || ICON_SIZE_VALUE}px;
    width: ${size || ICON_SIZE_VALUE}px;
    min-width: ${size || ICON_SIZE_VALUE}px;
  `};
`;

export const FallbackIcon = styled(IconNew).attrs(() => ({
  name: 'workspacesSpaces',
}))`
  filter: opacity(100%) !important;
`;

export const WorkspaceTab = styled(Tab).attrs(() => ({
  isSecondary: true,
}))``;

const createIconComponent = (name) => styled(IconNew).attrs(() => ({ name }))``;
const ActivityFeedIcon = createIconComponent('navigationHome');

export const ActivityFeedTab = styled(Tab).attrs(() => ({
  iconComponent: ActivityFeedIcon,
  label: FEED_TITLE,
}))``;

const FeedbackIcon = createIconComponent('navigationFeedbacks');
export const FeedbacksTab = styled(Tab).attrs(() => ({
  iconComponent: FeedbackIcon,
  label: FEEDBACKS_TITLE,
}))``;

const RecurringIcon = createIconComponent('navigationRecurring');
export const RecurringTab = styled(Tab).attrs(() => ({
  iconComponent: RecurringIcon,
  label: RECURRING_TITLE,
}))``;

export const SkillsTab = styled(Tab).attrs(() => ({
  iconComponent: NavBar.Main.icons.skills,
  label: SKILLS_TITLE,
}))``;

const InsightsIcon = createIconComponent('workspacesTeamInsights');
export const InsightsTab = styled(Tab).attrs(() => ({
  iconComponent: InsightsIcon,
  label: INSIGHTS_TITLE,
}))``;

const MembersIcon = createIconComponent('navigationMembers');
export const MembersTab = styled(Tab).attrs(() => ({
  iconComponent: MembersIcon,
  label: MEMBERS_TITLE,
}))``;

const SettingsIcon = createIconComponent('navigationAdminSettings');
export const SettingsTab = styled(Tab).attrs(() => ({
  iconComponent: SettingsIcon,
  label: DESKTOP_SETTINGS_TITLE,
}))`
  display: none;
  ${media.L`
    display: block;
  `}
`;

const RewardsStoreIcon = createIconComponent('navigationRewards');
export const RewardsStoreTab = styled(Tab).attrs(() => ({
  iconComponent: RewardsStoreIcon,
  label: REWARDS_STORE_TITLE
}))`
  display: none;
  ${media.L`
    display: block;
  `}
`;

const RewardsManagerIcon = createIconComponent('navigationRewards');
export const RewardsManagerTab = styled(Tab).attrs(() => ({
  iconComponent: RewardsManagerIcon,
  label: REWARDS_MANAGER_TITLE
}))`
  display: none;
  ${media.L`
    display: block;
  `}
`;

const AnalyticsIcon = createIconComponent('navigationAnalytics');
export const AnalyticsTab = styled(Tab).attrs(() => ({
  iconComponent: AnalyticsIcon,
  label: ANALYTICS_TITLE,
}))`
  svg {
    width: ${spacing.singleAndHalf} !important;
  }
`;

const SettingsIconMobile = createIconComponent('navigationAdminSettings');
export const SettingsTabMobile = styled(Tab).attrs(() => ({
  iconComponent: SettingsIconMobile,
  label: SETTINGS_TITLE,
}))`
  display: block;
  svg {
    height: 20px !important;
  }
  ${media.L`
    display: none;
  `}
`;

export const AdminHeader = styled.div`
  display: none;
  ${media.L`
    display: flex;
    text-transform: uppercase;
    padding: ${spacing.single} 0 ${spacing.single} ${spacing.singleAndHalf};
    margin-top: ${spacing.half};
    font-size: ${theme.fontSize.base};
    line-height: ${theme.lineHeight.M};
    letter-spacing: 0.05em;
    color: ${theme.colors.blacks[60]};
    border-top: 1px solid ${theme.colors.blacks[10]};
  `}
`;

export const ProfileMenuContainer = styled.div`
  border-top: ${({ hideDivider }) => hideDivider ? 'none' : `1px solid ${theme.colors.blacks[10]}`};
  padding: ${spacing.single};
`;

export const ProfileMenuTitle = styled.div`
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.base};
  margin: ${spacing.half} 0;
`;

export const ProfileMenuWorkspacesList = styled.div``;

export const ProfileMenuWorkspaceName = styled.div`
  text-align: left;
  max-width: 270px;
  margin-right: 0 !important;
  word-break: break-word;
  ${restrictTextHeightStyles}
`;

export const ProfileMenuWorkspaceContainer = styled.div`
  display: flex;
  margin: 0 0 ${spacing.quarter};

  &:last-child {
    margin: 0 0 ${spacing.single};
  }
`;

export const ProfileMenuWorkspaceLink = styled(Clickable)`
  align-items: center;
  color: ${theme.colors.blacks[80]};
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  font-size: ${theme.fontSize.base};
  padding: ${spacing.half} 0;

  & > div {
    margin: 0 ${spacing.threeQuarters} 0 0;
  }
`;

export const ProfileMenuWorkspaceLeaveIcon = styled(IconNew).attrs(() => ({
  name: 'close',
}))``;

export const AddWorkspaceButtonProfileContainer = styled.div`
  margin-bottom: ${spacing.single};
`;

export const AddWorkspaceButton = styled(Button.Simple.Minimal).attrs(() => ({
  content: CREATE_WORKSPACE_TITLE,
  size: Button.Simple.buttonSizes.S,
}))``;
