import React from 'react';
import MenuItem from './MenuItem';
import {
  MenuContainer,
  MenuOverlay,
} from './styles';
import { Avatar } from '../TopBar';

function TenantSelectMenu(props) {
  const {
    isOpen,
    onClose,
    tenants,
    currentTenant,
    onSelect
  } = props;

  if (!currentTenant) {
    return null;
  }
  
  const sortedTenants = [...tenants].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <MenuContainer show={isOpen}>
        {sortedTenants.map((tenant) => (
          <MenuItem
            key={`tenant-menu-${tenant.id}`}
            avatar={<Avatar url={tenant.photoUrl} />}
            content={tenant.name}
            isActive={tenant.id === currentTenant.id}
            onClick={() => {
              onClose();
              onSelect(tenant.id);
            }}
          />
        ))}
      </MenuContainer>
      <MenuOverlay onClick={onClose} show={isOpen} />
    </>
  );
};

export default TenantSelectMenu;
