import styled from 'styled-components';
import Primary from './MinimalPrimary';
import Button from './Default';
import Text from './MinimalText';
import theme from '@matterapp/matter-theme';

const getColorStyles = ({ disabled }) => {
  if (!disabled) {
    return `
      background: ${theme.colors.white};
      border-color: ${theme.colors.blacks[20]};
      color: ${theme.colors.blacks[70]};

      &:hover {
        border-color: ${theme.colors.blacks[40]};
        color: ${theme.colors.blacks[70]};
      }
      &:active {
        border-color: ${theme.colors.blacks[40]};
        color: ${theme.colors.blacks[70]};
        background: ${theme.colors.blacks[10]};
      }
    `;
  }
};

const MinimalButton = styled(Button)`
  ${getColorStyles}
`;

MinimalButton.Primary = Primary;
MinimalButton.Text = Text;
export default MinimalButton;
