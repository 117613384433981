import React from 'react';
import PropTypes from 'prop-types';
import SVG, { Path } from '../SVG';

const SVGBase = (props) => {
  const { height, path, width } = props;
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <SVG height={height} width={width} {...props} viewBox={viewBox}>
      <Path d={path} />
    </SVG>
  );
};

SVGBase.propTypes = {
  /** The height of the number. */
  height: PropTypes.number,
  /** The path stroke of the number. */
  path: PropTypes.string,
  /** The width of the number. */
  width: PropTypes.number,
};

export default SVGBase;
