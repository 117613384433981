import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 7.75a3.25 3.25 0 0 0-6.197-1.372l-.013-.003-5.723 9.051A6.75 6.75 0 1 0 13.5 19.75V17c0 1.375 1.125 2.5 2.5 2.5s2.5-1.125 2.5-2.5v2.75a6.75 6.75 0 1 0 11.933-4.324L24.71 6.375l-.013.003A3.25 3.25 0 0 0 18.5 7.75V11c0-1.375-1.125-2.5-2.5-2.5a2.507 2.507 0 0 0-2.5 2.5V7.75zM16 14.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5zm-4.5 5.25a4.75 4.75 0 1 1-9.5 0 4.75 4.75 0 0 1 9.5 0zm18.5 0a4.75 4.75 0 1 1-9.5 0 4.75 4.75 0 0 1 9.5 0z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
