import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { containerStyles, headingStyles } from './sharedStyles';
import theme from '@matterapp/matter-theme';

import {
  ButtonNew,
  Emoji,
} from '../../../components';

const animationDelay = 400;
const animationDuration = 500;

const Container = styled.div`
  ${containerStyles}
  padding: ${() => `${theme.spacing.double} ${theme.spacing.single}`};
  background-color: ${() => theme.colors.blues[60]};

  ${() => theme.media.medium`
    padding: ${theme.spacing.singleAndHalf};
  `}
`;

const Heading = styled.div`
  ${headingStyles}
  margin-bottom: 16px;
`;

const EmojiContainer = styled.div`
  &.animate {
    .fistLeft {
      animation-name: bounceInLeft;
      animation-delay: ${animationDelay}ms;
      animation-duration: ${animationDuration}ms;
      animation-fill-mode: both;
    }

    .fistRight {
      animation-name: bounceInRight;
      animation-delay: ${animationDelay}ms;
      animation-duration: ${animationDuration}ms;
      animation-fill-mode: both;
    }
  }

  @keyframes bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: ease-in;
    }

    0% {
      opacity: 0;
      transform: translate3d(-50px, 0, 0);
    }

    80% {
      opacity: 1;
      transform: rotate(0);
    }

    82% {
      transform: rotate(-20deg);
    }

    to {
      transform: translate3d(-20px, 0, 0) rotate(-20deg);
    }
  }

  @keyframes bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: ease-in;
    }

    from {
      opacity: 0;
      transform: translate3d(50px, 0, 0);
    }

    80% {
      opacity: 1;
      transform: rotate(0);
    }

    82% {
      transform: rotate(20deg);
    }

    to {
      transform: translate3d(20px, 0, 0) rotate(20deg);
    }
  }
`;

const ConfettiBubbles = styled.div`
  display: none;

  &.animate {
    display: inherit;
    position: absolute;
    top: 30px;
    left: calc(50% - 20px);
    width: 40px;
    height: 42px;
    z-index: 2;

    &::before,
    &::after {
      opacity: 0;
      position: absolute;
      content: '';
      display: block;
      width: 140%;
      height: 100%;
      left: 0;
      z-index: -1000;
      transition: all ease-in-out 0.5s;
      background-repeat: no-repeat;
    }
    &::before {
      display: none;
      top: -75%;
      background-image: radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%);
      background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%,
        20% 20%;
    }
    &::after {
      display: none;
      bottom: -35%;
      background-image: radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%),
        radial-gradient(circle, #ffce7e 20%, transparent 20%);
      background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%,
        20% 20%;
    }
    &::before {
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
      animation-delay: ${animationDuration + animationDelay - 150}ms;
    }
    &::after {
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
      animation-delay: ${animationDuration + animationDelay - 150}ms;
    }
    @keyframes topBubbles {
      0% {
        opacity: 0;
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
          40% 90%, 55% 90%, 70% 90%;
      }
      50% {
        opacity: 1;
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
          50% 50%, 65% 20%, 90% 30%;
      }
      100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
          50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
      }
    }
    @keyframes bottomBubbles {
      0% {
        opacity: 0;
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
          70% -10%, 70% 0%;
      }
      50% {
        opacity: 1;
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%,
          95% 60%, 105% 0%;
      }
      100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%,
          95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
      }
    }
  }
`;

class WelcomeBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      animate: false,
      videoPlaying: false,
    };
  }

  componentDidMount() {
    this.attemptRevealAnimation();
  }

  attemptRevealAnimation = () => {
    this.setState({ animate: true });
  };

  handleVideoStart = () => {
    this.setState({ videoPlaying: true });
  };

  handleVideoPause = () => {
    this.setState({ videoPlaying: false });
  };

  handleVideoEnded = () => {
    this.setState({ videoPlaying: false });
  };

  render() {
    const { firstName, onVideoStart } = this.props;
    return (
      <Container>
        <EmojiContainer className={this.state.animate ? 'animate' : ''}>
          <Emoji.Image className="fistLeft" theString="🤜" size={40} />
          <Emoji.Image className="fistRight" theString="🤛" size={40} />
        </EmojiContainer>

        <ConfettiBubbles className={this.state.animate ? 'animate' : ''} />
        <Heading>Welcome to Matter, {firstName}!</Heading>

        <ButtonNew primary size="small" onClick={this.handleVideoStart}>
          Watch our demo video
        </ButtonNew>

        <ReactPlayer
          ref={(ref) => {
            if (ref) {
              this.rp = ref;
            }
          }}
          url="https://matterapp.wistia.com/medias/fyh71czrv3"
          playing={this.state.videoPlaying}
          className="matter-react-player"
          width={0}
          height={0}
          config={{
            wistia: {
              options: {
                popover: true,
              },
            },
          }}
          onStart={() => onVideoStart()}
          onPause={() => this.handleVideoPause()}
          onEnded={() => this.handleVideoEnded()}
        />
      </Container>
    );
  }
}

WelcomeBanner.propTypes = {
  firstName: PropTypes.string.isRequired,
  onVideoStart: PropTypes.func.isRequired,
};

export default WelcomeBanner;
