import React from 'react';

const Icon = () => (
  <svg width={55} height={67} fill="none">
    <rect
      x={46.042}
      y={33.823}
      width={8.711}
      height={7.02}
      rx={3.51}
      fill="#343799"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.488 1.915a4 4 0 0 0-4 4v3.418h-.573a1.915 1.915 0 0 0 0 3.83h.573v16.003h-.573a1.914 1.914 0 1 0 0 3.829h.573v16.003h-.573a1.915 1.915 0 0 0 0 3.83h.573v9.542a4 4 0 0 0 4 4h32.798a8 8 0 0 0 8-8V9.915a8 8 0 0 0-8-8H6.488z"
      fill="#ADAFDE"
    />
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6 0a4 4 0 0 0-4 4v3.928H2.539a1.915 1.915 0 1 0 0 3.83H5.6v15.806H2.538a1.915 1.915 0 0 0 0 3.83H5.6V47.2H2.538a1.915 1.915 0 0 0 0 3.83H5.6v8.788a4 4 0 0 0 4 4h32.798a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8H9.6z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6 0a4 4 0 0 0-4 4v3.928H2.539a1.915 1.915 0 1 0 0 3.83H5.6v15.806H2.538a1.915 1.915 0 0 0 0 3.83H5.6V47.2H2.538a1.915 1.915 0 0 0 0 3.83H5.6v8.788a4 4 0 0 0 4 4h32.798a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8H9.6z"
      fill="#fff"
    />
    <path
      d="M5.6 7.928v1h1v-1h-1zm0 3.83h1v-1h-1v1zm0 15.806v1h1v-1h-1zm0 3.83h1v-1h-1v1zm0 15.806v1h1v-1h-1zm0 3.83h1v-1h-1v1zM6.6 4a3 3 0 0 1 3-3v-2a5 5 0 0 0-5 5h2zm0 3.928V4h-2v3.928h2zm-4.062 1H5.6v-2H2.538v2zm-.915.915c0-.505.41-.915.915-.915v-2A2.915 2.915 0 0 0-.377 9.843h2zm.915.914a.914.914 0 0 1-.915-.914h-2a2.915 2.915 0 0 0 2.915 2.914v-2zm3.063 0H2.538v2H5.6v-2zm1 16.807V11.757h-2v15.807h2zm-4.063 1H5.6v-2H2.538v2zm-.915.915c0-.505.41-.915.915-.915v-2A2.915 2.915 0 0 0-.377 29.48h2zm.915.915a.915.915 0 0 1-.915-.915h-2a2.915 2.915 0 0 0 2.915 2.915v-2zm3.063 0H2.538v2H5.6v-2zm1 16.806V31.393h-2V47.2h2zm-4.063 1H5.6v-2H2.538v2zm-.915.915c0-.505.41-.915.915-.915v-2a2.915 2.915 0 0 0-2.915 2.915h2zm.915.915a.915.915 0 0 1-.915-.915h-2a2.915 2.915 0 0 0 2.915 2.915v-2zm3.063 0H2.538v2H5.6v-2zm1 9.788V51.03h-2v8.788h2zm3 3a3 3 0 0 1-3-3h-2a5 5 0 0 0 5 5v-2zm32.798 0H9.6v2H42.4v-2zm7-7a7 7 0 0 1-7 7v2a9 9 0 0 0 9-9h-2zm0-47.818v47.818h2V8h-2zm-7-7a7 7 0 0 1 7 7h2a9 9 0 0 0-9-9v2zM9.6 1H42.4v-2H9.6v2z"
      fill="#ADAFDE"
      mask="url(#a)"
    />
    <ellipse cx={27.999} cy={30.632} rx={12.444} ry={12.764} fill="#343799" />
    <path
      d="M21.154 36.376a5.105 5.105 0 0 1 5.106-5.105h3.477a5.105 5.105 0 0 1 5.106 5.105 2.553 2.553 0 0 1-2.553 2.553h-8.583a2.553 2.553 0 0 1-2.553-2.553z"
      fill="#FF7C6E"
    />
    <rect
      x={24.888}
      y={22.974}
      width={6.222}
      height={7.658}
      rx={3.111}
      fill="#FFBF61"
    />
    <path
      d="M44.798 26.803h9.955v10.211h-9.955l4.044-5.105-4.044-5.105z"
      fill="#4F52B1"
    />
  </svg>
);

export default Icon;
