import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DocumentHead from 'components/atoms/DocumentHead';
import ResponseFlow from './ResponseFlow';
import { getGeneratedDefaultNote } from './utils';
import { mixpanel } from 'libs/tracking';
import { updateIntercom } from 'libs/tracking/intercom';
import {
  EMAIL_SIGNUP_LOGIN_MUTATION,
  CURRENT_USER_STATUS_QUERY,
} from 'graphql-queries/queries';
import { handleTrackSignup } from 'libs/tracking/signup';
import { SIGNUP_MEANS } from 'app-consts';
import apolloClient from 'config/apolloClient';
export const ACTION_ITEMS_SKILL_STEP = 2;
export const MAX_QUALITIES_SELECTED = 3;
export const PAGE_TITLE = 'Feedback';

export const EVENT_NAME = 'rf';
export const NAV_EVENT = `${EVENT_NAME}:navigation`;
export const NAME_FORM_EVENT = `${EVENT_NAME}:name-form`;
export const SKILL_EVENT = `${EVENT_NAME}:skill`;
export const SKILL_RATE_EVENT = `${SKILL_EVENT}-rate`;
export const SKILL_NOTE_EVENT = `${SKILL_EVENT}-note`;
export const SKILL_CUSTOM_NOTE_EVENT = `${SKILL_NOTE_EVENT}:leaving-wo-a-custom-note`;

const handleTrackClick = {
  customizeNote: mixpanel.createClickEventSender(
    `${SKILL_CUSTOM_NOTE_EVENT}:customize:clicked`
  ),
  useDefaultNote: mixpanel.createClickEventSender(
    `${SKILL_CUSTOM_NOTE_EVENT}:use-default:clicked`
  ),
  prevStep: mixpanel.createClickEventSender(`${NAV_EVENT}:back:clicked`),
  nextStep: mixpanel.createClickEventSender(`${NAV_EVENT}:continue:clicked`),
  skipStep: mixpanel.createClickEventSender(`${NAV_EVENT}:skip:clicked`),
};

const handleTrackEvent = {
  changeFullNameError: mixpanel.createEventSender(
    `${NAME_FORM_EVENT}:change-full-name-error:viewed`
  ),
  submitFeedback: mixpanel.createEventSender(
    `${EVENT_NAME}:feedback-submitted`
  ),
  viewAbilities: mixpanel.createEventSender(`${SKILL_EVENT}-abilities:viewed`),
  viewCustomNote: mixpanel.createEventSender(
    `${SKILL_CUSTOM_NOTE_EVENT}:viewed`
  ),
  viewNameForm: mixpanel.createEventSender(`${NAME_FORM_EVENT}:viewed`),
  viewSkillInfoModal: mixpanel.createEventSender(
    `${SKILL_RATE_EVENT}:skill-info-modal:viewed`
  ),
  viewRatingGuideModal: mixpanel.createEventSender(
    `${SKILL_RATE_EVENT}:rating-guide-modal:viewed`
  ),
  viewSkillNote: mixpanel.createEventSender(`${SKILL_NOTE_EVENT}:viewed`),
  viewSkillRater: mixpanel.createEventSender(`${SKILL_RATE_EVENT}:viewed`),
  viewQualities: mixpanel.createEventSender(`${EVENT_NAME}:qualities:viewed`),
  viewWorkRelationship: mixpanel.createEventSender(
    `${EVENT_NAME}:work-relationship:viewed`
  ),
};

const TimelineContainer = styled.div`
  max-height: 100vh;
`;

const qualitySkillPropType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.id,
});

const simpleUserPropType = PropTypes.shape({ fullName: PropTypes.string });

const skillToRatePropType = PropTypes.shape({
  skill: qualitySkillPropType,
  currentNote: PropTypes.string,
  currentRating: PropTypes.number,
  previousRating: PropTypes.number,
});

const skillsToRatePropType = PropTypes.arrayOf(skillToRatePropType);

const statePropTypes = PropTypes.shape({
  qualitiesToRate: PropTypes.arrayOf(qualitySkillPropType),
  receiver: PropTypes.shape({
    fullName: PropTypes.string,
  }),
  skillsToRate: skillsToRatePropType,
});

export default class ResponseFlowFullPage extends React.PureComponent {
  static propTypes = {
    /** Redux actions needed for timeline props and to update redux state. */
    actions: PropTypes.shape({
      changeCurrentQualities: PropTypes.func.isRequired,
      changeCurrentSkillRatings: PropTypes.func.isRequired,
      changeCurrentStep: PropTypes.func.isRequired,
      changeCurrentSkillStep: PropTypes.func.isRequired,
      changeCurrentUserFullName: PropTypes.func.isRequired,
      changeVisibleStep: PropTypes.func.isRequired,
      initializeTimeline: PropTypes.func.isRequired,
      resetTimeline: PropTypes.func.isRequired,
      setGeneratedDefaultNote: PropTypes.func.isRequired,
      setWorkRelationship: PropTypes.func.isRequired,
      toggleIsDefaultNoteMessageShown: PropTypes.func.isRequired,
      toggleShowDefaultNoteMessage: PropTypes.func.isRequired,
    }).isRequired,
    /** The number of times the default message note was shown. */
    amountDefaultNoteMessageShown: PropTypes.number,
    /** The current step of the current skill of the timeline. */
    currentSkillStep: PropTypes.number,
    /** The current step of the timeline. */
    currentStep: PropTypes.number,
    /** The currently selected qualities. */
    currentQualities: PropTypes.arrayOf(PropTypes.string),
    /** The id of the feedback. */
    feedbackId: PropTypes.string,
    /** The current gerenrated ddefault note for the current skill. */
    generatedDefaultNote: PropTypes.string,
    /** Fetched state datat to initialize timeline. */
    initializeData: statePropTypes,
    /** If the default note message should be shown. */
    isDefaultNoteMessageShown: PropTypes.bool,
    /** If call to graphql is loading timeline. */
    loading: PropTypes.bool,
    /** Mutations of the feedback. */
    mutations: PropTypes.shape({
      beginFeedback: PropTypes.func,
      transferFeedback: PropTypes.func,
      endFeedback: PropTypes.func,
      pickActionItemsForSkill: PropTypes.func.isRequired,
      rateSkill: PropTypes.func.isRequired,
      setFullName: PropTypes.func.isRequired,
      setQualities: PropTypes.func.isRequired,
      setWorkRelationship: PropTypes.func.isRequired,
    }),
    /** List of qualities to rate/select in the qualities step of the timeline. */
    qualitiesToRate: PropTypes.arrayOf(qualitySkillPropType),
    /** Information of the receiver of the feedback. */
    receiver: simpleUserPropType,
    /** List of currently saving mutation hashes. */
    saveHashes: PropTypes.arrayOf(PropTypes.string),
    /** Toggles the display of the default note message. */
    showDefaultNoteMessage: PropTypes.bool,
    /** List of skills to rate in the timeline. */
    skillsToRate: skillsToRatePropType,
    /** The current visible step of the timeline. */
    visibleStep: PropTypes.number,
  };

  static defaultProps = {
    actions: {},
    mutations: {},
    receiver: {
      fullName: '',
    },
    skillsToRate: [],
  };

  state = {
    showIdentificationStep: false,
  };

  componentDidUpdate = (prevProps) => {
    const { loading, mutations, currentUser } = this.props;

    if (prevProps.loading && !loading && this.hasSkillsToRate()) {
      mutations.beginFeedback().then(() => {
        this.initializeTimeline();
        // check is current user exists, and has a fullname set, on mount.
        // If we don't have a logged-in user, or they have no fullName set,
        // then we show the identification step.


        if (currentUser) {
          this.setState({ showIdentificationStep: !currentUser.fullName });
        }
      }).catch((err) => {
        throw err;
      });
    } else if (!loading) {
      this.trackViewSteps(prevProps);
    }
    this.setShowSavingMessage();
  };

  componentWillUnmount() {
    this.props.actions.resetTimeline();
  }

  /**
   * Sets the onbeforeunload function if there are currently saving actions.
   * @returns { void }
   */
  setShowSavingMessage = () => {
    const { saveHashes } = this.props;
    if (saveHashes.length) {
      // Show message if timeline is saving and window is closing.
      // NOTE: Only IE will show this custom message otherwise its up to the browser.
      window.onbeforeunload = () => 'Are you sure you want to leave?';
    } else {
      window.onbeforeunload = null;
    }
  };

  /**
   * Check to see if any tracking events need to occur when props change.
   * @param { Object } prevProps: The previous props before the update.
   * @returns { void }
   */
  trackViewSteps = (prevProps) => {
    const {
      currentSkillStep: prevSkillStep,
      visibleStep: prevStep,
    } = prevProps;
    const skillOffsetCount = ResponseFlow.Timeline.SKILL_START_INDEX_OFFSET;
    const { currentSkillStep, skillsToRate, visibleStep } = this.props;
    const hasChangedSkillStep =
      Math.abs(prevSkillStep - currentSkillStep) === 1;
    const hasChangedStep = prevStep !== visibleStep;
    const qualitiesStep = skillOffsetCount + skillsToRate.length;
    const isSkillStep = visibleStep > 1 && visibleStep < qualitiesStep;

    if (visibleStep === 1) {
      handleTrackEvent.viewWorkRelationship();
    }
    if (isSkillStep) {
      if ((hasChangedStep || hasChangedSkillStep) && currentSkillStep === 1) {
        handleTrackEvent.viewSkillRater();
      }
      if (hasChangedSkillStep && currentSkillStep === 2) {
        const currentSkill = skillsToRate[visibleStep - skillOffsetCount];
        // Custom skills do not have abilities, so step 2 is not always abilities.
        if (
          currentSkill &&
          currentSkill.actionItemSelections &&
          currentSkill.actionItemSelections.length
        ) {
          handleTrackEvent.viewAbilities();
        }
      }
    }
    if (visibleStep === qualitiesStep) {
      handleTrackEvent.viewQualities();
    }
    if (visibleStep === qualitiesStep + 1) {
      handleTrackEvent.viewNameForm();
    }
  };

  /**
   * Determines if the default note message should be shown when continuing skill step.
   * @param { Object } skillToRate: The current skill to rate of the step.
   * @param { String } overrideDefaultNote: Override the default note to use from props.
   * @returns { void }
   */
  checkIfDefaultNoteMessageIsShown = (skillToRate, overrideDefaultNote) => {
    const {
      actions,
      amountDefaultNoteMessageShown,
      generatedDefaultNote,
      isDefaultNoteMessageShown,
    } = this.props;
    const { currentNote } = skillToRate || {};
    const defaultNote = overrideDefaultNote || generatedDefaultNote;
    const isDefaultNoteUsed = currentNote === defaultNote;
    const noteShownLessThanOne = amountDefaultNoteMessageShown < 1;
    const shouldShowMessage = noteShownLessThanOne && isDefaultNoteUsed;

    if (shouldShowMessage && !isDefaultNoteMessageShown) {
      actions.toggleIsDefaultNoteMessageShown(true);
    } else if (isDefaultNoteMessageShown) {
      actions.toggleIsDefaultNoteMessageShown(false);
    }
  };

  /**
   * Generates a default note for a skill step.
   * @param { Object } noteProps: {
   *   @param { Number } stepNumber: The number of the step to a generate note for.
   *   @param { Object } skillToRate: The skill to generate a note for.
   * }
   * @returns { void }
   */
  generateDefaultNote(noteProps = {}) {
    const { stepNumber } = noteProps;
    const oldSkillToRate = noteProps.skillToRate || {};
    const defaultNote = getGeneratedDefaultNote(oldSkillToRate);
    const index = stepNumber - ResponseFlow.Timeline.SKILL_START_INDEX_OFFSET;
    const hasNote = !!oldSkillToRate.currentNote;
    const skillToRate = hasNote
      ? { ...oldSkillToRate, defaultNote }
      : { ...oldSkillToRate, currentNote: defaultNote, defaultNote };

    this.props.actions.setGeneratedDefaultNote(defaultNote);
    this.props.actions.changeCurrentSkillRatings({ index, skillToRate });

    if (!hasNote) {
      this.checkIfDefaultNoteMessageIsShown(skillToRate, defaultNote);
    } else {
      this.checkIfDefaultNoteMessageIsShown(oldSkillToRate);
    }
    handleTrackEvent.viewSkillNote();
  };

  /**
   * @returns { Boolean } If the timeline has any skills to rate.
   */
  hasSkillsToRate = () => {
    const { initializeData } = this.props;
    return (
      initializeData &&
      initializeData.skillsToRate &&
      initializeData.skillsToRate.length
    );
  };

  /**
   * Callback to set the work relationship.
   * @param { Object } workRelationProps: {
   *   @param { Boolean } isWorkingTogether: If users are working together.
   * }
   * @returns { Function } Callback to set the work relationship.
   */
  handleSetWorkRelation = (e, workRelationProps) => {
    const { isWorkingTogether } = workRelationProps;
    this.props.actions.setWorkRelationship(isWorkingTogether);
    this.props.mutations.setWorkRelationship({ isWorkingTogether });
  };

  /**
   * Callback when next button is click to go to next step.
   * @returns { void }
   */
  handleNextStep = () => {
    this.props.actions.changeCurrentStep(this.props.currentStep + 1);
  };

  /**
   * Toggles the display of the default note message.
   * @param { Object } e: The click event.
   * @param { Object } messageProps: {
   *   @param { Boolean } showDefaultNoteMessage: If the default note should be shown.
   * }
   * @returns { void }
   */
  handleClickShowDefaultNoteMessage = (e, messageProps) => {
    const { actions, amountDefaultNoteMessageShown } = this.props;
    const { showDefaultNoteMessage } = messageProps;
    if (amountDefaultNoteMessageShown < 1) {
      actions.toggleShowDefaultNoteMessage(showDefaultNoteMessage);
      if (showDefaultNoteMessage) {
        handleTrackEvent.viewCustomNote();
      }
    } else {
      actions.toggleIsDefaultNoteMessageShown(false);
    }
  };

  /**
   * Changes the current step of the timeline.
   * @param { Object } e: The change event.
   * @param { Object } timelineProps: {
   *   @param { Number } currentStep: The new current step of the timeline.
   * }
   * @returns { void }
   */
  handleChangeCurrentStep = (e, timelineProps) => {
    this.props.actions.changeCurrentStep(timelineProps.currentStep);
  };

  /**
   * Changes the current skill step of the timeline.
   * @param { Object } e: The change event.
   * @param { Object } skillProps: {
   *   @param { Number } currentSkillStep: The new current skill step of the timeline.
   * }
   * @returns { void }
   */
  handleChangeCurrentSkillStep = (e, skillProps) => {
    this.props.actions.changeCurrentSkillStep(skillProps.currentSkillStep);
  };

  /**
   * Callback to change the current full name if full name step is enabled.
   * @param { Object } e: The change event.
   * @param { Object } userProps: Updated props of the user containing the new full name value.
   * @returns { void }
   */
  handleChangeCurrentUserFullName = (e, userProps) => {
    this.props.actions.changeCurrentUserFullName(userProps.fullName);
  };

  /**
   * Changes the visible step of the timeline.
   * @param { Object } e: The change event.
   * @param { Object } timelineProps: {
   *   @param { Number } visibleStep: The new visible step of the timeline.
   * }
   * @returns { void }
   */
  handleChangeVisibleStep = (e, timelineProps) => {
    this.props.actions.changeVisibleStep(timelineProps.visibleStep);
  };

  /**
   * Callback update rating of skill in redux state.
   * @param { Object } e: The change event.
   * @param { Object } skillProps: {
   *   @param { Object } skillToRate: the updated skill to put into redux.
   * }
   * @returns { void }
   */
  handleChangeRating = (e, skillProps) => {
    this.handleUpdateSkill(e, skillProps);
    this.handleFinishCurrentSkillStep(skillProps.skillToRate);
  };

  /**
   * Callback update skill in redux state.
   * @param { Object } e: The change event.
   * @param { Object } skillProps: {
   *   @param { Array } currentAbilities: List of current selected abilities of the skill.
   *   @param { Number } stepNumber: The number of the step.
   *   @param { Object } skillToRate: the updated skill to put into redux.
   * }
   * @returns { void }
   */
  handleUpdateSkill = (e, skillProps) => {
    const {
      currentAbilities,
      stepNumber,
      skillToRate: updatedSkill,
    } = skillProps;
    const index = stepNumber - ResponseFlow.Timeline.SKILL_START_INDEX_OFFSET;
    const skillToRate = { ...updatedSkill, currentAbilities };
    this.props.actions.changeCurrentSkillRatings({ index, skillToRate });
    this.checkIfDefaultNoteMessageIsShown(skillToRate);
  };

  /**
   * Updates the currently selected qualities.
   * @param { Object } e: The change event.
   * @param { Object } qualityProps: Updated props
   * @returns { void }
   */
  handleUpdateQualities = async (e, qualityProps) => {
    const { currentQualities } = qualityProps;
    this.props.actions.changeCurrentQualities(qualityProps.currentQualities);

    if (currentQualities.length === MAX_QUALITIES_SELECTED) {
      const { feedbackId, mutations } = this.props;
      await mutations.setQualities({
        feedbackId,
        qualityIds: currentQualities,
      });
    }
  };

  /**
   * Finishes the current skill step by saving data to backend.
   * @param { Object } currentSkillToRate: The current skill to save data for.
   * @returns { void }
   */
  handleFinishCurrentSkillStep = (currentSkillToRate) => {
    const { currentSkillStep, feedbackId, mutations } = this.props;
    const {
      currentAbilities: abilityIds,
      currentNote: note,
      currentRating: rating,
      defaultNote,
      skill: { id },
    } = currentSkillToRate;
    const baseSkillProps = { feedbackId, skillId: id };
    const noteValue = note ? note.trim() : null;
    const noteToSave = defaultNote === noteValue ? null : noteValue; // Don't save if note is the same as generated note.

    if (currentSkillStep === ACTION_ITEMS_SKILL_STEP && abilityIds) {
      mutations.pickActionItemsForSkill({ ...baseSkillProps, abilityIds });
    } else if (rating) {
      mutations.rateSkill({ ...baseSkillProps, note: noteToSave, rating });
    }
  };

  /**
   * Finishes the current step by saving data to backend.
   * @param { Object } e: The finish event.
   * @param { Object } props: {
   *   @param { Object } navProps: Props from the timeline to build out the nav buttons
   *   @param { Object } currentSkillToRate: If current step is a skill step, save data of the skill.
   * }
   * @returns { void }
   */
  handleFinishCurrentStep = async (e, props) => {
    if (!props.navProps) {
      return;
    }
    const { isSkillStep, isLastStep } = props.navProps;
    const { mutations } = this.props;

    if (isSkillStep && props.currentSkillToRate) {
      this.handleFinishCurrentSkillStep(props.currentSkillToRate);
    }
    if (isLastStep) {
      if (this.shouldShowIdentificationStep()) {
        // We have a logged in user -- this may be from an e-mail invitation
        // or they may have just logged via the identification step
        if (props.toBeCreatedUser) {
          const {email, fullName } = props.toBeCreatedUser;
          const resp = await apolloClient.mutate({
            mutation: EMAIL_SIGNUP_LOGIN_MUTATION,
            variables: { email, fullName },
          });
          const { isNewRecord, user } = resp.data.login;
          handleTrackSignup({
            isNewRecord,
            user,
            signupMeans: SIGNUP_MEANS.MANUAL_EMAIL_SIGNUP,
          });
          await apolloClient.query({
            query: CURRENT_USER_STATUS_QUERY,
            fetchPolicy: 'network-only',
          });
        }
      }
      handleTrackEvent.submitFeedback();
      await mutations.endFeedback();
    }
  };

  transferFeedbackDataFromSessionToUser = async () => {
    const { mutations } = this.props;
    await mutations.transferFeedback(); 
  };

  /**
   * Initializes the timeline with data from the server.
   * @returns { void }
   */
  initializeTimeline = () => {
    this.props.actions.initializeTimeline(this.props.initializeData);
  };

  /**
   * @returns { Boolean } If the enter full name step should be shown.
   */
  shouldShowIdentificationStep = () => {
    return this.state.showIdentificationStep;
  };

  getSortedSkills = () => {
    const { skillsToRate } = this.props;
    
    return [...skillsToRate].sort((a, b) => {
      const first = a.skill.name;
      const second = b.skill.name;
      return first.toLowerCase() > second.toLowerCase() ? 1 : -1;
    });
  };

  renderTimeline = () => {
    const {
      currentQualities,
      currentStep,
      currentSkillStep,
      isDefaultNoteMessageShown,
      qualitiesToRate,
      receiver,
      showDefaultNoteMessage,
      visibleStep,
      currentUser
    } = this.props;
    if (!this.hasSkillsToRate()) {
      return null;
    }

    return (
      <>
        <DocumentHead title={PAGE_TITLE} />
        <TimelineContainer>
          <ResponseFlow.Timeline
            currentQualities={currentQualities}
            currentStep={currentStep}
            currentSkillStep={currentSkillStep}
            currentUser={currentUser}
            generateDefaultNote={this.generateDefaultNote.bind(this)}
            isDefaultNoteMessageShown={isDefaultNoteMessageShown}
            onChangeCurrentAbilities={this.handleUpdateSkill}
            onChangeCurrentNote={this.handleUpdateSkill}
            onChangeCurrentQualities={this.handleUpdateQualities}
            onChangeCurrentStep={this.handleChangeCurrentStep}
            onChangeCurrentSkillStep={this.handleChangeCurrentSkillStep}
            onChangeCurrentUserFullName={this.handleChangeCurrentUserFullName}
            onChangeCurrentUserFullNameError={handleTrackEvent.changeFullNameError}
            onChangeVisibleStep={this.handleChangeVisibleStep}
            onChangeRating={this.handleChangeRating}
            onFinishCurrentStep={this.handleFinishCurrentStep}
            onClickNext={handleTrackClick.nextStep}
            onClickPrev={handleTrackClick.prevStep}
            onNextStep={this.handleNextStep}
            onOpenSkillInfoPopover={handleTrackEvent.viewSkillInfoModal}
            onClickYesWorkingTogether={this.handleSetWorkRelation}
            onClickComplicatedWorkingTogether={this.handleSetWorkRelation}
            onClickNoWorkingTogether={this.handleSetWorkRelation}
            onClickShowDefaultNoteMessage={
              this.handleClickShowDefaultNoteMessage
            }
            onClickContinueEditNote={handleTrackClick.customizeNote}
            onClickUseDefaultNote={handleTrackClick.useDefaultNote}
            onSkipSkill={handleTrackClick.skipStep}
            onOpenRatingGuideModal={handleTrackEvent.viewRatingGuideModal}
            qualitiesToRate={qualitiesToRate}
            receiver={receiver}
            showIdentificationStep={this.shouldShowIdentificationStep()}
            showDefaultNoteMessage={showDefaultNoteMessage}
            skillsToRate={this.getSortedSkills()}
            transferFeedbackDataFromSessionToUser={this.transferFeedbackDataFromSessionToUser}
            visibleStep={visibleStep}
          />
        </TimelineContainer>
      </>
    );
  };

  render() {
    // FIXME: Hide Intercom messenger. This is a little hacky, but this is
    // currently the only public route where we don't want to show it
    updateIntercom(null, { showIntercomMessenger: false });

    return <>{this.renderTimeline()}</>;
  }
}
