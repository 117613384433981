import styled from 'styled-components';
import { Button } from '@matterapp/matter-ui';
import { font, spacing } from '@matterapp/matter-theme';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ spaceAbove }) => spaceAbove ? spacing.singleAndHalf : 0};
  margin-bottom: ${spacing.single};
`;

export const MessageContainer = styled.div`
  display: block;
  text-align: center;
  padding: 0 ${spacing.singleAndHalf};
  margin-top: ${spacing.singleAndHalf};
  margin-bottom: ${spacing.single};
  font-size: ${font.size.S};
`;

export const ContextMessageContainer = styled.div`
  display: block;
  text-align: center;
  padding: 0 ${spacing.singleAndHalf};
  margin-top: ${spacing.singleAndHalf};
  margin-bottom: ${spacing.single};
  font-size: ${font.size.base};
`;

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2)
}));

export const UpgradeButton = styled(Button.Primary.Vivid).attrs(() => ({
  color: Button.buttonColors.PURPLE,
}))`
  margin-top: ${spacing.singleAndHalf};
`;
