import React from 'react';
import PropTypes  from 'prop-types';
import Dropdown from '../../../Dropdown/Multiple';
import { FEEDBACK_TYPES, TYPE_FILTER } from '../consts';

const Type = (props) => {
  const { isKudosDisabled, value } = props;
  const isAll = !value[0] || value[0] === FEEDBACK_TYPES.ALL;
  return (
    <Dropdown
      {...props}
      displayValue={TYPE_FILTER.DROPDOWN_LABEL}
      items={
        isKudosDisabled ? TYPE_FILTER.ITEMS_KUDOS_DISABLED : TYPE_FILTER.ITEMS
      }
      menuProps={TYPE_FILTER.MENU_PROPS}
      singleSelect
      showApplyClearButtons={false}
      showSelectionCount={!isAll}
      toggleSingleSelect={false}
      value={isAll ? [FEEDBACK_TYPES.ALL] : value}
    />
  );
};

Type.propTypes = {
  isKudosDisabled: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default Type;
