import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AvatarCard, Button, IconNew as Icon } from '@matterapp/matter-ui';
import { colors, font, lineHeight, spacing } from '@matterapp/matter-theme';
import { COIN_BALANCE_TEXT } from '../consts';

const SideColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AvatarCardContainer = styled(AvatarCard)`
  margin-bottom: 0 !important;
  padding-left: ${spacing.triple};
  padding-right: ${spacing.triple};
  box-shadow: none;
`;

const CoinBalance = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height:  ${spacing.doubleAndHalf};
  border-bottom: 1px solid ${colors.blacks[10]};
  border-top: 1px solid ${colors.blacks[10]};
  margin: 0 0 -1px 0;
  padding: ${spacing.half} ${spacing.singleAndHalf};
  font-size: ${font.size.S2};
  line-height: ${lineHeight.M};
  color: ${colors.blacks[50]};
`;

const ViewHistoryButton = styled(Button)`
  color: ${colors.blues[50]};
  font-weight: ${font.weight.bold};
  font-size: ${font.size.base};
  margin-top:  ${spacing.double};
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    margin-left: 6px;
    height: ${spacing.singleAndQuarter};
    width: ${spacing.singleAndQuarter};
  }

  &:hover {
    & svg {
      path {
        stroke: ${colors.purples[50]};
      }
    }
  }

  &:active {
    & svg {
      path {
        stroke: ${colors.purples[60]};
      }
    }
  }
`;

export const ExternalLinkIcon = styled(Icon).attrs(() => ({name: 'externalLink'}))``;

const RewardSideColumn = ({ isRewardsActive, currentCoinBalance = 0, totalCoinBalance = 0, onClickHistory }) => {
  let title = 'Rewards';
  let description = 'Team members can now add Matter Coins to Feedback they give to show extra appreciation. Earned Matter Coins can be exchanged for exciting rewards!';
  let coinSection = <StyledButton style={{ width: 210 }} href="https://matterapp.com/rewards">Learn More <ExternalLinkIcon fill={colors.blues[60]}/></StyledButton>;

  if (isRewardsActive) {
    title = 'Your Rewards';
    description = 'Exchange your Matter coins for rewards!';
    coinSection = 
      <>
        <CoinBalance>
          <>{COIN_BALANCE_TEXT}</>
          <strong>{currentCoinBalance.toLocaleString('en-US')}</strong>
        </CoinBalance>
        <CoinBalance>
          <>Total Coins Redeemed</>
          <strong>{totalCoinBalance.toLocaleString('en-US')}</strong>
        </CoinBalance>
        <ViewHistoryButton onClick={onClickHistory}>View Redemption History</ViewHistoryButton>
      </>;
  }
  
  return (
    <SideColumnContainer>
      <AvatarCardContainer iconName="rewardsPageIcon" title={title}>
        {description}
      </AvatarCardContainer>
      {coinSection}
    </SideColumnContainer>
  );
};

RewardSideColumn.propTypes = {
  isRewardsActive: PropTypes.bool,
  currentCoinBalance: PropTypes.number,
  totalCoinBalance: PropTypes.number,
  onClickHistory: PropTypes.func,
};

export default RewardSideColumn;