import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Planet from './svgs/Planet';
import Atmosphere from './svgs/Atmosphere';
import theme from '@matterapp/matter-theme';
import {
  Emoji,
  IconNew as Icon,
  Clickable
} from '../../../../components';

const BackgroundWrapper = styled.div`
  position: absolute;
  right:0;
`;

const Wrapper = styled.div`
  position: relative;
  min-width: 359px;
  border-radius: 0;
  overflow: hidden;
  background-color: ${() => theme.colors.purples[40]};

  ${() => theme.media.S`
    border-radius: 12px;
    line-height: 24px;
    font-size: 17px;
    height: 244px;
  `}

  ${() => theme.media.M`
    height: auto;
  `}
`;
const Container = styled.div`
  position: relative;
  height: 100%;
  color: white;
  padding: ${theme.spacing.singleAndHalf};
  border-radius: 0;
  ${() => theme.media.S`
    border-radius: ${theme.borderRadius.L};
    line-height: ${theme.lineHeight.M};
    font-size: ${theme.fontSize.S};
  `}
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({bold}) => bold ? "bold" : "normal"};
  line-height: ${theme.lineHeight.L};
  font-size: ${theme.fontSize.M};
  color: ${theme.colors.white};
  margin-bottom: ${theme.spacing.half};
  ${({theme}) => theme.media.S`
    justify-content: flex-start;
  `}
`;

const Greeting = styled(Heading)`
  display: none;
  ${({theme}) => theme.media.S`
    display: flex;
  `}
`;

const Paragraph = styled.p`
  color: ${theme.colors.purples[5]};
  font-style: normal;
  font-weight: normal;

  line-height: ${theme.lineHeight.S};
  font-size: ${theme.fontSize.base};
  text-align: center;

  ${() => theme.media.S`
    line-height: ${theme.lineHeight.M};
    font-size: ${theme.fontSize.S};
    text-align: left;
  `}
`;

const ChildrenContainer = styled.div`
  width: 100%;
  margin-top: ${theme.spacing.singleAndHalf};
  ${theme.media.S`
    margin-top: ${theme.spacing.double};
  `}
`;

const DismissContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.spacing.singleAndHalf};

  ${({theme}) => theme.media.S`
    display: flex;
    margin: 0;
  `}
`;
const Dismiss = styled(Clickable)`
  color: ${theme.colors.purples[20]};
  display: flex;
  line-height: ${theme.lineHeight.M};
  align-items: center;
  text-transform: uppercase;
  letter-spacing: .04em;
  font-size: ${theme.fontSize.base};
  svg {
    display: none;
  }

  &:hover {
    color: ${theme.colors.purples[5]};
    svg rect {
      fill: ${theme.colors.purples[5]};
    }
  }

  &:active {
    color: ${theme.colors.white};
    svg rect {
      fill: ${theme.colors.white};
    }
  }
  ${({ canClose }) => !canClose && `
    display: none;
  `}

  ${theme.media.medium`

    position: absolute;
    top: calc(${theme.spacing.singleAndHalf} + 4px);
    right: ${theme.spacing.singleAndHalf};

    svg {
      display: flex;
      margin-left: ${theme.spacing.half};
    }
  `}
`;

const PeersHomeBanner = ({
  children,
  firstName,
  onClose,
  canClose,
}) => (
  <Wrapper data-rc="home-banner-boost">
    <BackgroundWrapper style={{bottom: "-10px"}}>
      <Planet fill="#4C4DAF" />
    </BackgroundWrapper>
    <BackgroundWrapper>
      <Atmosphere fill="#4C4DAF" />
    </BackgroundWrapper>

    <Container>
      <Greeting bold>
        <Emoji.Image
          theString="👋"
          size={22}
        />
        &nbsp;Hello {firstName},
      </Greeting>
      <Heading>
        Let’s finish setting up Matter!
      </Heading>
      <Paragraph>
        Discover your biggest growth opportunities from people you trust and respect.
        Get started by asking 3 people for feedback.
      </Paragraph>
      <ChildrenContainer>
        {children}
      </ChildrenContainer>
      <DismissContainer>
        <Dismiss
          canClose={canClose}
          onClick={onClose}
          rcLabel="dismiss-boost"
        >
            dismiss
          <Icon name="closeCircle" />
        </Dismiss>
      </DismissContainer>
    </Container>
  </Wrapper>
);

PeersHomeBanner.propTypes = {
  children: PropTypes.node.isRequired,
  firstName: PropTypes.string.isRequired,
  canClose: PropTypes.bool,
  onClose: PropTypes.func
};

PeersHomeBanner.defaultProps = {
  canClose: true,
  onClose: () => {}
};

export default PeersHomeBanner;
