import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input, Loader } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';
import iconSuccess from 'assets/icon_success.svg';
import iconWarning from 'assets/icon_warning.svg';
import iconError from 'assets/icon_error.svg';

const ICON_SIZE = 24;
const IconImageContainer = styled.div`
  width: ${theme.spacing.singleAndHalf};
  height: ${theme.spacing.singleAndHalf};
`;

function getIconImageContainer(src) {
  const alt = null;
  const dataRc = null;
  return (
    <IconImageContainer>
      <img src={src} width={ICON_SIZE} height={ICON_SIZE} alt={alt} data-rc={dataRc}/>
    </IconImageContainer>
  );
}

const INPUT_STATE_ICONS = {
  ERROR: iconError,
  SUCCESS: iconSuccess,
  WARNING: iconWarning,
  EMPTY: null,
};

const INPUT_STATE_VALUES = Object.keys(INPUT_STATE_ICONS).reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});

const BaseInput = styled(Input).attrs(() => ({
  autoComplete: 'new-password',
}))`
  height: ${theme.spacing.quad};
  width: 100%;
  input& {
    -webkit-appearance: none;
    padding: ${theme.spacing.threeQuarters} ${theme.spacing.single};
    border-color: transparent;
    border-radius: ${theme.borderRadius.M};
    border-width: 2px;
    transition: border ${theme.transitions.times.singleAndHalf};
    &:focus {
      box-shadow: none;
      border-color: ${theme.colors.blacks[30]};
    }
  }
`;

const InputIconContainer = styled.div`
  position: absolute;
  top: ${theme.spacing.single};
  right: ${theme.spacing.single};

  div:focus-within + & {
    
  }
`;

const Wrapper = styled.div`
  position: relative;

  ${({ inputState, loading }) => {
  if (inputState === INPUT_STATE_VALUES.WARNING && !loading) {
    return `
        & input {
          border-color: ${theme.colors.yellows[20]};
        }
      `;
  }
  return '';
}}
`;

const InputValidate = (props) => {
  const {
    className,
    inputClassName,
    errorMessage,
    fieldProps,
    inputState,
    loading,
    onBlur,
    onChange,
    onKeyPress,
    placeholder,
    showErrorMessageBelow,
    value,
  } = props;
  let renderedIcon = null;
  if (loading) {
    renderedIcon = <Loader.Inline />;
  }
  else {
    const inputStateIcon = errorMessage ? INPUT_STATE_ICONS.ERROR : INPUT_STATE_ICONS[inputState];
    renderedIcon = inputStateIcon && getIconImageContainer(inputStateIcon);
  }
  return (
    <Wrapper className={className} inputState={inputState} loading={loading}>
      <BaseInput
        className={inputClassName}
        errorMessage={errorMessage}
        field={fieldProps}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        showErrorMessageBelow={showErrorMessageBelow}
        value={value}
      />
      <InputIconContainer>
        {renderedIcon}
      </InputIconContainer>
    </Wrapper>
  );
};

InputValidate.defaultProps = {
  showErrorMessageBelow: true,
};

InputValidate.propTypes = {
  /** Callback when input is blurred. */
  onBlur: PropTypes.func,
  /** Callback when input changes. */
  onChange: PropTypes.func,
  /** Callback when key is pressed. */
  onKeyPress: PropTypes.func,
  /** Error message to display. */
  errorMessage: PropTypes.string,
  /** The state of the input to display icon. */
  inputState: PropTypes.oneOf(Object.keys(INPUT_STATE_ICONS)),
  /* Additioanl props for the input. */
  fieldProps: PropTypes.object,
  /** Placeholder text. */
  placeholder: PropTypes.string,
  /** If error message should be displayed below the input. */
  showErrorMessageBelow: PropTypes.bool,
  /** The value of the input. */
  value: PropTypes.string,
  /** Custom class name for the wrapper, if any. */
  className: PropTypes.string,
  /** Custom class name for the input, if any. */
  inputClassName: PropTypes.string,
  loading: PropTypes.bool,
};

InputValidate.INPUT_STATES = INPUT_STATE_VALUES;

export default InputValidate;
