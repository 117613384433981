import { lifecycle, compose } from 'recompose';
import { trackPageView } from 'libs/tracking/pageView';

const withPageViewTracking = ({
  pageTitle,
  getEventParamsFromProps = () => null,
}) => {
  if (!pageTitle) {
    throw new Error('page title is required');
  }
  return compose(
    lifecycle({
      componentDidMount() {
        const computedTrackingParams = getEventParamsFromProps(this.props);
        trackPageView({
          pageTitle,
          params: computedTrackingParams,
        });
      },
    })
  );
};

export default withPageViewTracking;
