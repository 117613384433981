import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { ENV_VARS } from 'app-consts';
import possibleTypes from './fragmentTypes';
import fetch from 'isomorphic-fetch';

const { GRAPHQL_ENDPOINT_URI, CURRENT_ENV } = ENV_VARS;

const cache = new InMemoryCache({
  possibleTypes
});

const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT_URI,
  credentials: 'include',
  fetch
});

const client = new ApolloClient({
  link: httpLink,
  cache,
  connectToDevTools: CURRENT_ENV !== 'production',
  credentials: 'include',
});

export default client;
