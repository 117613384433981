import { graphql } from '@apollo/client/react/hoc';
import ProfileCard from '../components/ProfileCard';
import { PROFILE_CARD_QUERY } from 'graphql-queries/queries';

const withData = graphql(PROFILE_CARD_QUERY, {
  name: 'profileCardData',
});

const ProfileCardContainer = withData(ProfileCard);

export default ProfileCardContainer;
