import React from 'react';
import Input from './Input';
import InputPhone from './InputPhone';
import InputCopyToClipboard from './InputCopyToClipboard';
import InputCopyToClipboardOverlay from './InputCopyToClipboardOverlay';
import InputFlush from './InputFlush';
import InputContainer from './InputContainer';
import InputErrorContainer from './InputErrorContainer';
import Choices from './Choices';
import { SIZES } from './consts';

// Deprecated way of import input components. Use updated method: InputPhone -> Input.Phone
export {
  Input,
  InputPhone,
  InputCopyToClipboard,
  InputCopyToClipboardOverlay,
  InputFlush,
  InputContainer,
  InputErrorContainer,
};

export default class InputBase extends React.PureComponent {
  static Phone = InputPhone;
  static CopyToClipboard = InputCopyToClipboard;
  static CopyToClipboardOverlay = InputCopyToClipboardOverlay;
  static Flush = InputFlush;
  static Container = InputContainer;
  static ErrorContainer = InputErrorContainer;
  static Choices = Choices;
  static sizes = SIZES;

  render() {
    return <Input {...this.props} />;
  }
}