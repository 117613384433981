import React from 'react';
import PropType from 'prop-types';

class YellowCardInner extends React.Component {
  componentDidMount() {
    if (this.ref) {
      this.ref.scrollIntoView(true);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div ref={(ref) => {
        if (ref) {
          this.ref = ref;
        }
      }}
      >
        {children}
      </div>
    );
  }
}

YellowCardInner.propTypes = {
  children: PropType.node.isRequired,
};

export default YellowCardInner;
