import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import RewardRequestTable from './RequestTable';
import { useQuery } from '@apollo/client';
import getAllRewardRequests from 'graphql-queries/queries/rewards/getAllRewardRequests';
import { LoaderContainer, StyledTab, StyledTabs, RewardsHeader } from './styles';
import { StyledPaper } from '../styles';
import { Button, Loader } from '@matterapp/matter-ui';
import PendingTab from './PendingTabTable';
import approveAll from 'graphql-queries/queries/rewards/approveAll';
import { useMutation } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import getPendingRewardRequests from 'graphql-queries/queries/rewards/getPendingRewardRequests';
import getCurrentMemberProperties from 'graphql-queries/queries/rewards/getCurrentMemberProperties';
import styled from 'styled-components';

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(2)
  }
}));

function CurrentTable(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

export default function RewardsRequestList({ tenant, workspaceId, refetchCurrentUser, refetchDebitRows }) {
  const [value, setValue] = useState(0);
  const { id: tenantId, rewardsSettings, tier } = tenant || {};
  const isMatterPro = tier === 'pro' || tier === 'enterprise';
  const isRewardsActive = rewardsSettings?.isRewardsActive && isMatterPro;

  const { data: allRewardRequestsData } = useQuery(getAllRewardRequests, {
    variables: {
      tenantId: tenant?.id
    },
    skip: !tenant?.id
  });
  const isPendingTab = value === 0;

  const refetchQueries = [
    {
      query: getPendingRewardRequests,
      variables: {
        tenantId,
      }
    },
    { 
      query: getAllRewardRequests,
      variables: {
        tenantId,
      }
    },
    {
      query: getCurrentMemberProperties,
      variables: {
        tenantId
      }
    }
  ];

  const [approveAllMutation, { loading: isApproveAllLoading, error: approveAllError }] = useMutation(approveAll, {
    refetchQueries,
    update(cache, { data: { approveAll } }) {
      const { balanceAfterRedemption } = approveAll[0];
      if (balanceAfterRedemption != null) {
        cache.modify({
          id: cache.identify(tenant),
          fields: {
            rewardsFundingSettings(cached) {
              return {
                ...cached,
                balance: balanceAfterRedemption
              };
            }
          }
        });
      }
    }
  });

  useEffect(() => {
    if (approveAllError) {
      Toast.error(approveAllError.message);
    }
  }, [approveAllError]);

  async function approveAllRequestsFunction() {
    await approveAllMutation({
      variables: {
        workspaceId
      },
    });

    Toast.success('All requests have been approved');

    setTimeout(async () => {
      refetchCurrentUser();
      refetchDebitRows();
    }, 10000);
  };

  function handleChange(_, newValue) {
    setValue(newValue);
  };

  if (!tenant || !allRewardRequestsData) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  const { pendingRequests, declinedRequests, approvedRequests } = allRewardRequestsData?.getAllRewardRequests;

  return (
    <StyledPaper elevation={0} sx={{ position: 'relative' }}>
      <RewardsHeader variant='h4'>Reward Requests</RewardsHeader>
      {isPendingTab && isRewardsActive ? (
        <StyledButton
          size={Button.buttonSizes.S}
          onClick={approveAllRequestsFunction}
          disabled={isApproveAllLoading || !pendingRequests.length}
        >
          Approve All
        </StyledButton>
      ) : null}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label='Pending' disableRipple />
          <StyledTab label='Approved' disableRipple />
          <StyledTab label='Declined' disableRipple />
        </StyledTabs>
      </Box>
      <CurrentTable value={value} index={0}>
        <PendingTab 
          pendingRequests={pendingRequests} 
          tenant={tenant} 
          workspaceId={workspaceId} 
          isLoading={isApproveAllLoading}
          refetchCurrentUser={refetchCurrentUser}
          refetchDebitRows={refetchDebitRows}
        />
      </CurrentTable>
      <CurrentTable value={value} index={1}>
        <RewardRequestTable
          rewardRequests={approvedRequests}
          lastColumnHeader='Status'
          emptyStateMessage='No Approved Requests'
        />
      </CurrentTable>
      <CurrentTable value={value} index={2}>
        <RewardRequestTable
          rewardRequests={declinedRequests}
          lastColumnHeader='Status'
          emptyStateMessage='No Declined Requests'
        />
      </CurrentTable>
    </StyledPaper>
  );
};
