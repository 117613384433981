import styled from 'styled-components';
import theme, { ColorPalettes as Colors } from '@matterapp/matter-theme';

export const ClipPath = styled.path`
  fill: none;
`;

export const Group = styled.g``;

export const GroupOverlay = styled.g``;

export const Gradient = styled.linearGradient.attrs(() => ({
  gradientTransform: 'matrix(1, 0, 0, -1, 0, -396)',
  gradientUnits: 'userSpaceOnUse',
}))``;

export const OverlayBackground = styled.path.attrs(() => ({
  d: `M0,0h184v184H0V0z`,
}))`
  fill: none;
`;

export const GRADIENT_COLORS = {
  1: '#F26849', // theme.colors.oranges[50]
  2: '#FF6588', // theme.colors.reds[40]
  3: '#FF4369', // theme.colors.reds[50]
  4: '#E34A34', // theme.colors.oranges[60]
};

export const GRADIENT_COLOR_HUE_VALUES = {
  1: 50,
  2: 40,
  3: 50,
  4: 60,
};

const SECONDARY_COLORS = {
  [Colors.Pink]: Colors.Orange,
  [Colors.Red]: Colors.Orange,
  [Colors.Orange]: Colors.Yellow,
  [Colors.Yellow]: Colors.Orange,
  [Colors.Green]: Colors.Blue,
  [Colors.Blue]: Colors.Purple,
  [Colors.Purple]: Colors.Blue,
};

const getColorObject = (color) => {
  const colorToParse = color === Colors.Pink ? Colors.Red : color;
  const colorName = `${colorToParse.toLowerCase()}s`;
  return theme.colors[colorName] || theme.colors.blues;
};

const getGradientColors = (color, stop) => {
  const colorName = stop === 1 || stop === 4 ? SECONDARY_COLORS[color] : color;
  const colorObj = getColorObject(colorName);
  return colorObj[GRADIENT_COLOR_HUE_VALUES[stop]];
};

/***********************************************/
/* STEP ONE                                    */
/***********************************************/
export const StepOneClipId = 'clip-path';
export const StepOneGradientId = 'linear-gradient';

export const StepOneClip = styled.clipPath.attrs(() => ({
  id: StepOneClipId,
}))``;

export const StepOneClipPath = styled(ClipPath).attrs(() => ({
  d: `M103,11.5,93.5,23h0a69.0747,69.0747,0,0,1,58.3,103.5h0l5.2,14,14.7-2.5-.6,1.1A92.10449,92.10449,0,0,0,93.5,0Z`,
}))``;

export const StepOneGroupClip = styled(Group)`
  clip-path: url(#${StepOneClipId});
`;

export const StepOneBackgroundPath = styled(StepOneClipPath)`
  fill: ${theme.colors.purples[5]};
`;

export const StepOneOverlayPath = styled(StepOneBackgroundPath)`
  fill: url(#${StepOneGradientId});
`;

export const StepOneGradient = styled(Gradient).attrs(() => ({
  id: StepOneGradientId,
  x1: '93.5',
  y1: '-466.25',
  x2: '184',
  y2: '-466.25',
}))``;

export const StepOneGradientStop1 = styled.stop.attrs((props) => ({
  offset: 0,
  stopColor: getGradientColors(props.color, 1),
}))``;

export const StepOneGradientStop2 = styled.stop.attrs((props) => ({
  offset: 1,
  stopColor: getGradientColors(props.color, 2),
}))``;

/***********************************************/
/* STEP TWO                                    */
/***********************************************/
export const StepTwoClipId = 'clip-path-3';
export const StepTwoGradientId = 'linear-gradient-2';

export const StepTwoClip = styled.clipPath.attrs(() => ({
  id: StepTwoClipId,
}))``;

export const StepTwoClipPath = styled(ClipPath).attrs(() => ({
  d: `M156.2,141.8l-5.2-14h0a68.96329,68.96329,0,0,1-118.7-1.3h0L17.5,124l-5.2,14-.7-1.2a92.03057,92.03057,0,0,0,159.3,2.5Z`,
}))``;

export const StepTwoGroupClip = styled(Group)`
  clip-path: url(#${StepTwoClipId});
`;

export const StepTwoBackgroundPath = styled(StepTwoClipPath)`
  fill: ${theme.colors.purples[10]};
`;

export const StepTwoOverlayPath = styled(StepTwoBackgroundPath)`
  fill: url(#${StepTwoGradientId});
`;

export const StepTwoGradient = styled(Gradient).attrs(() => ({
  id: StepTwoGradientId,
  x1: '12.3',
  y1: '-550',
  x2: '170.89999',
  y2: '-550',
}))``;

export const StepTwoGradientStop1 = styled.stop.attrs((props) => ({
  offset: 0,
  stopColor: getGradientColors(props.color, 3),
}))``;

export const StepTwoGradientStop2 = styled.stop.attrs((props) => ({
  offset: 1,
  stopColor: getGradientColors(props.color, 2),
}))``;

/***********************************************/
/* STEP THREE                                  */
/***********************************************/
export const StepThreeClipId = 'clip-path-5';
export const StepThreeGradientId = 'linear-gradient-3';

export const StepThreeClip = styled.clipPath.attrs(() => ({
  id: StepThreeClipId,
}))``;

export const StepThreeClipPath = styled(ClipPath).attrs(() => ({
  d: `M16.8,122.7l14.7,2.5h0A69.01787,69.01787,0,0,1,92,23l9.5-11.5L92,0A91.98446,91.98446,0,0,0,11.6,136.7Z`,
}))``;

export const StepThreeGroupClip = styled(Group)`
  clip-path: url(#${StepThreeClipId});
`;

export const StepThreeBackgroundPath = styled(StepThreeClipPath)`
  fill: ${theme.colors.purples[20]};
`;

export const StepThreeOverlayPath = styled(StepThreeBackgroundPath)`
  fill: url(#${StepThreeGradientId});
`;

export const StepThreeGradient = styled(Gradient).attrs(() => ({
  id: StepThreeGradientId,
  x1: '101.5',
  y1: '-464.35',
  x2: '0',
  y2: '-464.35',
}))``;

export const StepThreeGradientStop1 = styled.stop.attrs((props) => ({
  offset: 0,
  stopColor: getGradientColors(props.color, 4),
}))``;

export const StepThreeGradientStop2 = styled.stop.attrs((props) => ({
  offset: 1,
  stopColor: getGradientColors(props.color, 3),
}))``;

/***********************************************/
/* ARROW                                       */
/***********************************************/
export const ArrowGradientId = 'linear-gradient-4';

export const ArrowGroup = GroupOverlay;

export const ArrowGradient = styled.linearGradient.attrs(() => ({
  id: ArrowGradientId,
  x1: '52.5367',
  y1: '1562.63208',
  x2: '-7.82049',
  y2: '1648.53834',
  gradientTransform: 'translate(0 -1534)',
  gradientUnits: 'userSpaceOnUse',
}))``;

export const ArrowGradientStop1 = styled.stop.attrs(() => ({
  offset: 0,
  stopColor: theme.colors.white,
  stopOpacity: 0.5,
}))``;

export const ArrowGradientStop2 = styled.stop.attrs(() => ({
  offset: 1,
  stopColor: theme.colors.white,
  stopOpacity: 0,
}))``;

export const ArrowHead = styled.path.attrs(() => ({
  d: `M74.4,11.9a1.48661,1.48661,0,0,0,1,2.8c3.7-1.3,9.7-2,15.6-2.1l-3.7,4.8a1.5,1.5,0,0,0,2.4,1.8L96,11,88.2,3a1.45,1.45,0,0,0-2.1,0,1.45,1.45,0,0,0,0,2.1l4.5,4.5C84.5,9.8,78.4,10.5,74.4,11.9Z`,
}))`
  fill: ${theme.colors.white};
  fill-opacity: 0.5;
  fill-rule: evenodd;
`;

export const ArrowTail = styled.path.attrs(() => ({
  d: `M12.7,106.5a1.03721,1.03721,0,0,1-1.2-.8c0-.2-.1-.3-.1-.5a.94491.94491,0,0,1,.9-1.1,1.03721,1.03721,0,0,1,1.2.8c0,.2.1.3.1.5A.993.993,0,0,1,12.7,106.5Zm-1.1-8.6a1.01794,1.01794,0,0,1-1.1-.9,3.40193,3.40193,0,0,0-.1-1,.94477.94477,0,0,1,1-1,1.02782,1.02782,0,0,1,1.1,1,3.40193,3.40193,0,0,0,.1,1C12.7,97.4,12.2,97.8,11.6,97.9Zm-.1-9.2a1.02782,1.02782,0,0,1-1-1.1,3.40193,3.40193,0,0,1,.1-1,.90329.90329,0,0,1,1.1-.9,1.02782,1.02782,0,0,1,1,1.1,3.402,3.402,0,0,1-.1,1A1.01794,1.01794,0,0,1,11.5,88.7Zm1-9.2a.9675.9675,0,0,1-.8-1.2c.1-.3.1-.7.2-1a1.03721,1.03721,0,0,1,1.2-.8.9675.9675,0,0,1,.8,1.2c-.1.3-.1.7-.2,1A1.09669,1.09669,0,0,1,12.5,79.5Zm2-9a.99135.99135,0,0,1-.7-1.2c.1-.3.2-.7.3-1a1.14586,1.14586,0,0,1,1.3-.7.99134.99134,0,0,1,.7,1.2c-.1.3-.2.6-.3,1A1.015,1.015,0,0,1,14.5,70.5Zm3-8.7a.91708.91708,0,0,1-.5-1.3,10.37482,10.37482,0,0,0,.4-1,1.0735,1.0735,0,0,1,1.4-.5.91708.91708,0,0,1,.5,1.3c-.1.3-.3.6-.4.9A1.0997,1.0997,0,0,1,17.5,61.8Zm4-8.3a1.01185,1.01185,0,0,1-.4-1.4c.2-.3.3-.6.5-.9a1.10718,1.10718,0,0,1,1.4-.4,1.01184,1.01184,0,0,1,.4,1.4c-.2.3-.3.6-.5.9A1.07535,1.07535,0,0,1,21.5,53.5Zm5-7.9a.897.897,0,0,1-.2-1.4,5.54689,5.54689,0,0,1,.6-.8,1.07539,1.07539,0,0,1,1.4-.2.90617.90617,0,0,1,.2,1.4c-.2.3-.4.5-.6.8A.897.897,0,0,1,26.5,45.6Zm5.8-7.2a.972.972,0,0,1-.1-1.4c.2-.3.5-.5.7-.8a1.2,1.2,0,0,1,1.5-.1,1.0709,1.0709,0,0,1,.1,1.4l-.7.7A1.098,1.098,0,0,1,32.3,38.4ZM39,31.8a.972.972,0,0,1,.1-1.4c.3-.2.5-.5.8-.7a1.09294,1.09294,0,0,1,1.5.1.972.972,0,0,1-.1,1.4,5.0084,5.0084,0,0,0-.8.7A1.2,1.2,0,0,1,39,31.8ZM46.3,26a1.05766,1.05766,0,0,1,.2-1.4l.9-.6a1.01242,1.01242,0,0,1,1.1,1.7l-.9.6A1.14619,1.14619,0,0,1,46.3,26Zm8-5a1.01185,1.01185,0,0,1,.4-1.4c.3-.2.6-.3.9-.5a1.02956,1.02956,0,1,1,1,1.8c-.3.2-.6.3-.9.5A1.10718,1.10718,0,0,1,54.3,21Zm8.5-4.1a.96114.96114,0,0,1,.6-1.3c.3-.1.7-.3,1-.4a1.01242,1.01242,0,1,1,.7,1.9,10.37482,10.37482,0,0,1-1,.4A1.26793,1.26793,0,0,1,62.8,16.9Z`,
}))`
  fill: url(#${ArrowGradientId});
  fill-rule: evenodd;
`;
