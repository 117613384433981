import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.918 6.71a11.937 11.937 0 0 1 4.086-.714c1.44 0 2.817.253 4.087.715l5.703-2.535a.991.991 0 0 1 1.38 1.055l-1.014 6.6a10.577 10.577 0 0 1 1.315 4.376l2.758-.27a1.5 1.5 0 0 1 .292 2.986l-3.261.32c-.075.345-.167.685-.275 1.018l2.258.671a1.5 1.5 0 0 1-.856 2.876l-2.744-.817c-2.052 3.013-5.604 5.004-9.643 5.004-4.038 0-7.59-1.991-9.642-5.004l-2.744.817a1.5 1.5 0 0 1-.856-2.876l2.258-.671c-.108-.333-.2-.673-.275-1.019l-3.261-.319a1.5 1.5 0 1 1 .292-2.985l2.758.27a10.579 10.579 0 0 1 1.314-4.376L4.835 5.23a.991.991 0 0 1 1.38-1.055l5.703 2.535zm-.413 7.786c-.614 0-1.142.367-1.373.898a1 1 0 1 1-1.834-.797 3.494 3.494 0 0 1 3.207-2.101c1.435 0 2.668.863 3.206 2.1a1 1 0 0 1-1.834.798 1.494 1.494 0 0 0-1.372-.898zm5.5 4.994a1 1 0 1 0-2 0c0 .965-.969 2-2.5 2-.98 0-1.772-.445-2.184-1.027a1 1 0 1 0-1.633 1.154c.819 1.158 2.246 1.873 3.816 1.873 1.355 0 2.65-.555 3.5-1.478.85.923 2.146 1.478 3.5 1.478 1.57 0 2.998-.715 3.817-1.873a1 1 0 1 0-1.634-1.154c-.41.582-1.204 1.027-2.183 1.027-1.532 0-2.5-1.035-2.5-2zm.292-4.893a3.494 3.494 0 0 1 3.207-2.101c1.436 0 2.668.863 3.207 2.1a1 1 0 0 1-1.834.798 1.494 1.494 0 0 0-1.373-.898c-.614 0-1.141.367-1.373.898a1 1 0 1 1-1.834-.797z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
