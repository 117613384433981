import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import { colors, font, lineHeight, media, spacing } from '@matterapp/matter-theme';
import { Button, IconNew as Icon, Loader, Peer, Menu, Tooltip } from '@matterapp/matter-ui';
import getAllCustomRewards from 'graphql-queries/queries/rewards/getAllCustomRewards';
import createCustomReward from 'graphql-queries/queries/rewards/createCustomReward';
import deleteCustomReward from 'graphql-queries/queries/rewards/deleteCustomReward';
import { REWARD_QUANTITY_TYPES } from '../consts';
import { formatToPeersList } from './helpers';
import AddCustomRewardModal from './AddCustomRewardModal';
import DeleteRewardModal from './DeleteRewardModal';
import EmptyCustomRewards from './EmptyCustomRewards';
import Toast from 'components/Toast/Toast';
import { StyledPaper } from '../styles';
import { Box } from '@mui/material';

const WarningIcon = styled(Icon).attrs({ name: 'caution', fill: colors.yellows[60] })``;
const InfoIcon = styled(Icon).attrs({ name: 'infoCircle', fill: colors.blacks[30], size: 16 })``;
const MenuIcon = styled(Icon).attrs(() => ({inverted: true, name: 'meatballs' }))``;

const MenuContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.blacks[50],
  display: 'flex',
  justifyContent: 'flex-end',
  height: 'auto,',
  width: '100%',
  '&:hover': {
    color: theme.palette.blues[50]
  },
  [`& ${MenuIcon}`]:{
    margin: 0,
    path: {
      fill: 'currentColor'
    }
  }
}));

const SubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${font.size.M};
  font-weight: ${font.weight.bold};
  line-height: ${lineHeight.L};
  color: ${colors.blacks[90]};
  margin-bottom: ${spacing.single};
`;

const TooltipContainer = styled.div`
  margin-left: ${spacing.quarter};
  ${media.S`
    margin-top: 0;
  `}
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.singleAndHalf};
`;

const CustomRewardsList = styled(Peer.List.WithAddRemove.CustomSubHeader).attrs(() => ({
  flushedEdgeOnMobile: true,
}))`
  margin: 0 -${spacing.singleAndHalf};
`;

const WarningIconContainer = styled.div`
  svg {
    path {
      fill: ${colors.yellows[50]};
    }
  }
`;

const LoaderContainer = styled.div`
  padding: ${spacing.singleAndHalf};
  position: relative;
  min-height: 160px;
  width: 100%;
`;

const AVATAR_HEIGHT = 48;
const AVATAR_WIDTH = 76;

export default function CustomRewardsPage({ tenantId, workspaceId }) {
  const modalRef = useRef();
  const [isAddRewardModalOpen, setIsAddRewardModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);

  const { loading: isCustomRewardLoading, data: customRewardsData } = useQuery(getAllCustomRewards, {
    variables: {
      tenantId,
    }
  });

  const refetchQueries = [
    {
      query: getAllCustomRewards,
      variables: {
        tenantId,
      }
    }
  ];

  const [createCustomRewardMutation] = useMutation(createCustomReward, { refetchQueries });
  const [deleteCustomRewardMutation] = useMutation(deleteCustomReward, { refetchQueries });

  const openRewardModal = () => setIsAddRewardModalOpen(true);

  const renderSubHeader = (renderProps) => {
    const { peer } = renderProps;
    const { quantity, quantityType, value} = peer;
    return `${value.toLocaleString('en-US')} coins | Qty: ${quantityType === REWARD_QUANTITY_TYPES.UNLIMITED ? 'Unlimited' : quantity}`;
  };

  const renderPeerActions = (selectedReward) => {
    const { quantityType, quantity } = selectedReward;
    const menuItemProps = [
      {
        menuLabel: 'Edit Reward',
        rcLabel: 'menu-item-edit-reward',
        handleAction: () => {
          setSelectedReward(selectedReward);
          openRewardModal();
        },
      },
      {
        menuLabel: 'Delete Reward',
        rcLabel: 'menu-item-delete-reward',
        handleAction: () => {
          setSelectedReward(selectedReward);
          setIsDeleteModalOpen(true);
        }
      }
    ];

    return (
      <MenuContainer>
        {quantityType === REWARD_QUANTITY_TYPES.LIMITED && quantity < 1 && 
          <Tooltip
            content={
              <div style={{ textAlign: 'center', padding: spacing.threeQuarters}}>
                This reward has been fully redeemed. Restock or delete this reward.
              </div>
            }
            style={{ minWidth: 'min-content' }}
          >
            <WarningIconContainer>
              <WarningIcon />
            </WarningIconContainer>
          </Tooltip>
        }
        <Menu.Dark menuMap={menuItemProps} />
      </MenuContainer>
    );
  };

  return (
    <>
      <StyledPaper>
        <SubTitle>
          Custom Rewards
          <Button size="S" onClick={openRewardModal}>Add Reward</Button>
        </SubTitle>
        <Description>
          Offer unique company rewards such as branded merchandise, free lunch, vacation time, etc. Admins are responsible for reward delivery.
          <TooltipContainer>
            <Tooltip
              content={<div style={{ textAlign: 'center', padding: spacing.threeQuarters }}>We recommend having at least one custom reward.</div>}
              style={{ minWidth: 'min-content' }}
            >
              <InfoIcon />
            </Tooltip>
          </TooltipContainer>

        </Description>
        {isCustomRewardLoading || !customRewardsData ? 
          <LoaderContainer>
            <Loader />
          </LoaderContainer> :
          customRewardsData.getAllCustomRewards.length ? 
            <CustomRewardsList 
              squared
              largeText
              avatarHeight={AVATAR_HEIGHT}
              avatarWidth={AVATAR_WIDTH}
              peers={formatToPeersList(customRewardsData.getAllCustomRewards)}
              renderSubHeader={renderSubHeader}
              renderPeerActions={renderPeerActions}
            /> :
            <EmptyCustomRewards onClick={openRewardModal} />
        }
      </StyledPaper>
      <AddCustomRewardModal
        isOpen={isAddRewardModalOpen}
        onClose={() => {
          setIsAddRewardModalOpen(false);
          setSelectedReward(null);
        }}
        tenantId={tenantId}
        workspaceId={workspaceId}
        customReward={selectedReward}
        ref={modalRef}
        createCustomReward={createCustomRewardMutation}
      />
      {selectedReward &&
        <DeleteRewardModal
          isOpen={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
            setSelectedReward(null);
          }}
          onDelete={async () => {
            await deleteCustomRewardMutation({
              variables: {
                id: selectedReward.id,
                workspaceId
              }
            });
            setIsDeleteModalOpen(false);
            setSelectedReward(null);
            Toast.success('Reward has been deleted');
            modalRef.current.resetModal();
          }}
        />
      }
    </>
  );
};
