import React from 'react';

const Icon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#FFF5DA" />
    <path d="M24.3529 14C19.1765 14 14.8941 17.9529 14.4 23.0118H12.9882C12.4471 23.0118 12 23.4588 12 24C12 24.5412 12.4471 24.9882 12.9882 24.9882H20.6118C20.9882 24.9882 21.3176 24.7765 21.4824 24.4471L22.3059 22.8471L24.8235 27.7176C24.9882 28.0471 25.3176 28.2588 25.6941 28.2588C26.0706 28.2588 26.4 28.0471 26.5647 27.7176L27.9765 24.9647H29.3882C29.9294 24.9647 30.3765 24.5176 30.3765 23.9765C30.3765 23.4353 29.9294 22.9882 29.3882 22.9882H27.3882C27.0118 22.9882 26.6824 23.2 26.5176 23.5294L25.6941 25.1294L23.1765 20.2824C22.8471 19.6235 21.7647 19.6235 21.4353 20.2824L20.0235 23.0353H16.3765C16.8706 19.0588 20.2588 15.9765 24.3529 15.9765C28.7765 15.9765 32.4 19.5765 32.4 24.0235C32.4 28.4706 28.7765 32.0471 24.3529 32.0471C21.0118 32.0471 18.1176 29.9765 16.9176 27.0588H14.8235C16.1176 31.0824 19.9059 34 24.3529 34C29.8588 34 34.3529 29.5059 34.3529 24C34.3529 18.4941 29.8588 14 24.3529 14Z" fill="#F2A645" />
  </svg>

);

export default Icon;
