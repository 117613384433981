import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@matterapp/matter-ui';
import { 
  CustomDatesWrapper,
  DateInput,
  DateLabel
} from './styles';

export default function CustomDatesModal(props) {
  const { isOpen, onClose, onSelect, startDate, endDate } = props;
  const [from, updateFrom] = useState(startDate.format('YYYY-MM-DD'));
  const [to, updateTo] = useState(endDate.format('YYYY-MM-DD'));

  const datesAreValid = !!(from && to && from <= to);

  useEffect(() => {
    if (isOpen) {
      updateFrom(startDate.format('YYYY-MM-DD'));
      updateTo(endDate.format('YYYY-MM-DD'));
    }
  }, [isOpen]);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="large"
    >
      <Modal.Header
        header="Custom Dates"
        right={<Modal.Buttons.CloseIcon onClick={onClose} />}
      />
      <Modal.Body>
        <CustomDatesWrapper>
          <DateLabel>
            From
          </DateLabel>
          <DateInput
            type="date"
            value={from}
            id="from_picker"
            onClick={() => {
              const el = document.getElementById('from_picker');
              el.showPicker();
            }}
            onChange={(e) => {
              updateFrom(e.target.value);
            }}
          />
          <DateLabel>
            To
          </DateLabel>
          <DateInput
            type="date"
            value={to} 
            min={from}
            id="to_picker"
            onClick={() => {
              const el = document.getElementById('to_picker');
              el.showPicker();
            }}
            onChange={(e) => {
              updateTo(e.target.value);
            }}
          />
        </CustomDatesWrapper>
      </Modal.Body>
      <Modal.Footer.WithCancelSave
        canClickSave={datesAreValid}
        saveLabel="Apply"
        onClickCancel={onClose}
        onClickSave={() => onSelect(from, to)}
      />
    </Modal>
  );
}

CustomDatesModal.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  isOpen: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object
};
