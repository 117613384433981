import React from 'react';
import Base from '../../../svgBase';

const DEFAULT_HEIGHT = 36;
const DEFAULT_WIDTH = 28;
const PATH = `M13.8409 35.4772C22.3466 35.4772 27.9034 30.3124 27.8864 22.9999C27.9034 16.3692 23.267 11.6817 17.0455 11.6817C13.5 11.6817 10.6364 13.2499 9.54546 15.3635H9.34091L10.0227 7.659H25.7046V0.0908203H2.25L0.886364 19.3181L9.20455 20.9545C10.0057 19.2499 11.8295 18.2272 13.8409 18.2272C16.7386 18.2272 18.767 20.2215 18.75 23.2726C18.767 26.3238 16.7386 28.3181 13.8409 28.3181C11.3352 28.3181 9.27273 26.784 9.20455 24.3635H0C0.0511363 30.8749 5.79546 35.4772 13.8409 35.4772Z`;

const Five = (props) => (
  <Base height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
);

export default Five;
