import { Skill } from '@matterapp/skills';
import { SKILL_GENERATOR_FORM_FIELD_NAMES } from './consts';

const fieldsMap = {
  name: SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_NAME,
  description: SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_DESCRIPTION,
  colorPalette: SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_COLOR_PALETTE,
};

const toReduxForm = (errorsList) => {
  const errors = {};
  errorsList.forEach(({ property, constraints }) => {
    const fieldName = fieldsMap[property];
    const curFieldErrorKeys = Object.keys(constraints);
    const firstErrorKey = curFieldErrorKeys[0];
    const firstError = constraints[firstErrorKey];
    errors[fieldName] = firstError;
  });
  return errors;
};

export default (values) => {
  const errorsList = Skill.validate({
    name: values[fieldsMap.name],
    description: values[fieldsMap.description],
    colorPalette: values[fieldsMap.colorPalette],
  });
  const errors = toReduxForm(errorsList);
  return errors;
};
