
import React, { useContext } from 'react';
import { 
  Typography
} from '@mui/material';
import { 
  SeetingsWrapper,
  IconWrapper,
  HeaderWrapper, 
  SubSection,
  SubSectionWrapper,
  SubSectionHeader
} from './styles';
import RightChevron from './icons/rightChevron';
import FeatureIcon from './icons/feature';
import UserContext from 'context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import AdminsSettingsHoc from './AdminsSettingsHoc';

export default function FeatureSettings({ isChannelSetting }) {
  const { currentWorkspaceId: workspaceId, currentTenant } = useContext(UserContext);
  const { isEnterprise } = currentTenant || {};
  const showChannelFeatures = isEnterprise && isChannelSetting || !isEnterprise;
  const params = isChannelSetting ? { isChannelSetting: true } : {};

  const navigate = useNavigate();

  return (
    <SeetingsWrapper>
      <HeaderWrapper>
        <IconWrapper>
          <FeatureIcon />
        </IconWrapper>
        <Typography variant='h4'>Features</Typography>
      </HeaderWrapper>
      <SubSectionWrapper>
        {showChannelFeatures && (
          <AdminsSettingsHoc>
            <SubSection onClick={() => navigate(Resources.workspaceSettingsFeedbackFriday.path({ workspaceId }, params))}>
              <SubSectionHeader>
                <Typography variant='h5' component='p'>Feedback Friday</Typography>
                <RightChevron />
              </SubSectionHeader>
              <Typography variant='body2'>
                Manage and customize your automatic recognition reminders.
              </Typography>
            </SubSection>
          </AdminsSettingsHoc>
        )}
        {showChannelFeatures && (
          <SubSection onClick={() => navigate(Resources.workspaceSettingsKudos.path({ workspaceId }, params))}>
            <SubSectionHeader>
              <Typography variant='h5' component='p'>Kudos</Typography>
              <RightChevron />
            </SubSectionHeader>
            <Typography variant='body2'>
              Customize kudos for company values, company humor, etc.
            </Typography>
          </SubSection>
        )}
        {!isChannelSetting && (
          <AdminsSettingsHoc>
            <SubSection onClick={() => navigate(Resources.workspaceSettingsRewards.path({ workspaceId }))}>
              <SubSectionHeader>
                <Typography variant='h5' component='p'>Rewards</Typography>
                <RightChevron />
              </SubSectionHeader>
              <Typography variant='body2'>
                Manage funding method, coin allowances, categories, and more.
              </Typography>
            </SubSection>
          </AdminsSettingsHoc>
        )}
        {showChannelFeatures && (
          <AdminsSettingsHoc>
            <SubSection onClick={() => navigate(Resources.workspaceSettingsCelebrations.path({ workspaceId }, params))}>
              <SubSectionHeader>
                <Typography variant='h5' component='p'>Celebrations</Typography>
                <RightChevron />
              </SubSectionHeader>
              <Typography variant='body2'>
                Manage how birthdays and work anniversaries are celebrated.
              </Typography>
            </SubSection>
          </AdminsSettingsHoc>
        )}
        {!isChannelSetting && (
          <AdminsSettingsHoc>
            <SubSection onClick={() => navigate(Resources.workspaceSettingsPulse.path({ workspaceId }))}>
              <SubSectionHeader>
                <Typography variant='h5' component='p'>Pulse Survey</Typography>
                <RightChevron />
              </SubSectionHeader>
              <Typography variant='body2'>
                Fully customize and launch your pulse and eNPS survey.
              </Typography>
            </SubSection>
          </AdminsSettingsHoc>
        )}
      </SubSectionWrapper>
    </SeetingsWrapper>
  );
}
