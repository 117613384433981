import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import { shared } from './shared';
import { browserPlugins } from './browser-plugins';

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  html,
  body {
    height: 100%;
  }

  body {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    background: white;

    overflow-x: hidden;
    overflow-y: scroll; // prevent jumping on route change

    // Used by Modals like RateSkill Modal
    &.locked, &.modal-open {
      overflow: hidden;
    }
  }

  #root, #root > div {
    height: 100%;
    width: 100%;
    box-sizing: content-box;
  }

  *:focus {
    outline: none;
  }

  * {
    box-sizing: border-box;
  }

  .ReactModal__Html--open body {
    overflow: hidden;
  }

  ${browserPlugins}
  ${shared}
`;

export default GlobalStyles;
