import React from 'react';
import {
  Group,
} from './sharedConsts';
import { Input, Button } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import ADMIN_GET_MEMBER_DETAILS from 'graphql-queries/queries/admin/adminGetMemberDetails';
import ADMIN_UPDATE_MEMBER_PERMISSION from 'graphql-queries/queries/admin/adminUpdateMemberPermission';
import ADMIN_REMOVE_MEMBER_PERMISSIONS from 'graphql-queries/queries/admin/adminRemoveMemberPermissions';

const WORKSPACE_MEMBER_PERMISSION = {
  OWNER: 'owner',
  ADMIN: 'admin'
};

const MemberTable = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
`;

const MemberTableHeader = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
`;

const MemberTableData = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
`;

const RenderMember = (props) => {
  const [updateMemberPermissionMutation, { loading: updateMemberPermissionLoading, error: updateMemberPermissionError, data: updateMemberPermissionData }] = useMutation(ADMIN_UPDATE_MEMBER_PERMISSION);
  const [removeMemberPermissionsMutation, { loading: removeMemberPermissionsLoading, error: removeMemberPermissionsError, data: removeMemberPermissionsData }] = useMutation(ADMIN_REMOVE_MEMBER_PERMISSIONS);
  const [recentlyUpdatedMember, setRecentlyUpdatedMember] = useState({});


  if (updateMemberPermissionLoading || removeMemberPermissionsLoading ) {
    return <p>Loading ...</p>;
  }

  if (updateMemberPermissionError || removeMemberPermissionsError) {
    return `Error! ${updateMemberPermissionError || removeMemberPermissionsError}`;
  }

  const { memberData, memberEmail } = props;

  if (!memberData) {
    return (
      <>
      </>
    );
  }

  const updateMemberPermission = async (member, permission) => {
    await updateMemberPermissionMutation({
      variables: {
        personId: member.person.id,
        tenantId: member.tenant.id,
        permission
      },
      refetchQueries: [{
        query: ADMIN_GET_MEMBER_DETAILS,
        variables: {
          memberEmail: member.email
        }
      }]
    });

    if (updateMemberPermissionData) {
      setRecentlyUpdatedMember(updateMemberPermissionData);
    }
  };

  const demoteMember = async (member) => {
    await removeMemberPermissionsMutation({
      variables: {
        personId: member.person.id,
        tenantId: member.tenant.id
      },
      refetchQueries: [{
        query: ADMIN_GET_MEMBER_DETAILS,
        variables: {
          memberEmail: member.email
        }
      }]
    });

    if (removeMemberPermissionsData) {
      setRecentlyUpdatedMember(removeMemberPermissionsData);
    }
  };

  const membersListItems = memberData.map((member) => {

    if (recentlyUpdatedMember?.id == member?.id) {
      member = recentlyUpdatedMember;
    }

    const { id: memberId, status, tenant, person } = member;
    const { workspaces, name: workspaceName, tier, tierExpires, isEnterprise, isAdmin, isOwner } = tenant || {};
    const { id: workspaceId } = workspaces?.[0] || {};
    const { fullName } = person;

    let canBePromotedToAdmin = true, canBePromotedToOwner = true, canBeDemotedToMember = false;
    if (isAdmin) {
      canBePromotedToAdmin = false;
      canBeDemotedToMember = true;
    }

    if (isOwner) {
      canBePromotedToOwner = false;
      canBePromotedToAdmin = false;
      canBeDemotedToMember = true;
    }

    if (status == 'removed') {
      canBePromotedToOwner = false;
      canBePromotedToAdmin = false;
    }

    const memberPermission = isAdmin ? 'admin' : (isOwner ? 'owner' : 'member');
    const rows = (
      <tr key={memberId}>
        <MemberTableHeader>{memberId}</MemberTableHeader>
        <MemberTableData>{status}</MemberTableData>
        <MemberTableData>{workspaceId}</MemberTableData>
        <MemberTableData>{workspaceName}</MemberTableData>
        <MemberTableData>{isEnterprise ? 'enterprise' : 'non-enterprise'}</MemberTableData>
        <MemberTableData>{tier}</MemberTableData>
        <MemberTableData>{tierExpires ? `${new Date(tierExpires).toUTCString()}` : '--'}</MemberTableData>
        <MemberTableData>{fullName}</MemberTableData>
        <MemberTableData>{memberPermission}</MemberTableData>
        <MemberTableData><Button.Primary.Vivid
          color={'blue'}
          style={{ marginRight: 16 }}
          disabled={!canBePromotedToAdmin}
          onClick={() => updateMemberPermission(member, WORKSPACE_MEMBER_PERMISSION.ADMIN)}
        >
          Make Admin
        </Button.Primary.Vivid></MemberTableData>
        <MemberTableData><Button.Primary.Vivid
          color={'blue'}
          style={{ marginRight: 16 }}
          onClick={() => updateMemberPermission(member, WORKSPACE_MEMBER_PERMISSION.OWNER)}
          disabled={!canBePromotedToOwner}
        >
          Make Owner
        </Button.Primary.Vivid></MemberTableData>
        <MemberTableData><Button.Primary.Vivid
          color={'blue'}
          style={{ marginRight: 16 }}
          onClick={() => demoteMember(member)}
          disabled={!canBeDemotedToMember}
        >
          Make Member
        </Button.Primary.Vivid></MemberTableData>
      </tr>
    );

    return rows;
  });

  return (
    <>
      <p><b>Email: {memberEmail}</b></p>
      <MemberTable className="table table-hover">
        <thead>
          <tr>
            <MemberTableHeader>Member Id</MemberTableHeader>
            <MemberTableHeader>Status</MemberTableHeader>
            <MemberTableHeader>Workspace Id</MemberTableHeader>
            <MemberTableHeader>Tenant name</MemberTableHeader>
            <MemberTableHeader>Tenant type</MemberTableHeader>
            <MemberTableHeader>Tenant tier</MemberTableHeader>
            <MemberTableHeader>Tenant expiry date</MemberTableHeader>
            <MemberTableHeader>Member name</MemberTableHeader>
            <MemberTableHeader>Member permission</MemberTableHeader>
            <MemberTableHeader>Promote to admin?</MemberTableHeader>
            <MemberTableHeader>Promote to owner?</MemberTableHeader>
            <MemberTableHeader>Demote to member?</MemberTableHeader>
          </tr>
        </thead>
        <tbody>{membersListItems}</tbody>
      </MemberTable>
    </>
    
  );
};

const MemberPromoteAdmin = (props) => {
  const [adminEmail, setAdminEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [getAdminWorkspaceDetails, { loading, error, data: memberDetailsData }] = useLazyQuery(ADMIN_GET_MEMBER_DETAILS);


  if (loading) {
    return <p>Loading ...</p>;
  }
  if (error) {
    return `Error! ${error}`;
  }

  const getAdminMemberDetails = async () => {

    if (!adminEmail) {
      setEmailError('Enter valid email');
      return null;
    }

    setEmailError();

    await getAdminWorkspaceDetails({
      variables: {
        memberEmail: adminEmail.trim()
      }
    });
  };

  return (
    <Group dividerBelow header={'Update member permission'}>
      <Input
        onChange={(e) => setAdminEmail(e.target.value)} 
        value={adminEmail}
        placeholder={'Enter member email'}
        error={emailError}
      />
      <br></br>
      <Button.Primary.Vivid
        color={'blue'}
        style={{ marginRight: 16 }}
        onClick={() => getAdminMemberDetails()}
      >
        Member Details
      </Button.Primary.Vivid>
      <RenderMember memberData={memberDetailsData?.adminGetMemberDetails} memberEmail={adminEmail}/>
    </Group>
  );
};

RenderMember.propTypes = {
  memberData: PropTypes.array,
  memberEmail: PropTypes.string
};

export default MemberPromoteAdmin;
