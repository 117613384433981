import { combineReducers } from 'redux';
import isRunningAuthReducer from './is-running-auth';
import currentPhoneNumberReducer from './current-phone-number';
import { AUTH_RESET_SIGNIN_DATA } from './auth-actions';

export * from './is-running-auth';
export * from './current-phone-number';

export const resetSigninData = () => ({
  type: AUTH_RESET_SIGNIN_DATA,
});

export default combineReducers({
  isRunningAuth: isRunningAuthReducer,
  currentPhoneNumber: currentPhoneNumberReducer,
});
