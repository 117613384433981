import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import omitStyled from '../../libs/omitStyled';
import TextContainer from './TextContainer';
import Text from './Text';
import Heading from './Heading';

const Container = omitStyled(TextContainer, ['muted'])`

  margin-bottom: 24px;

  &:first-child {
    margin-top: 8px;
  }
`;

const TextBlock = styled(({ className, heading, children, muted, style }) => (
  <Container className={className} style={style}>
    {heading ? <Heading>{heading}</Heading> : null}
    <Text muted={muted}>{children}</Text>
  </Container>
))``;

TextBlock.defaultProps = {
  heading: null,
  className: null,
  muted: false,
};

TextBlock.propTypes = {
  heading: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  muted: PropTypes.bool,
};

export default TextBlock;
