import React from 'react';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import { Avatar } from '@matterapp/matter-ui';
import {
  Divider,
  MembersListContainer,
  MembersListTitle,
  MembersListHeaderContainer,
  MemberHeaderIconContainer,
  MemberHeaderNameContainer,
  MemberHeaderKudosSentContainer,
  MemberHeaderKudosReceivedContainer,
  MemberNameContainer,
  MemberKudosSentContainer,
  MemberKudosReceivedContainer,
  MemberListContainer,
  StyledMemberListAvatar,
  MembersListIcon,
  SpinnerIconContainer,
  IconSpinner,
  MemberHeaderKudosSentMobileContainer,
  MemberHeaderKudosReceivedMobileContainer,
  MemberHeaderNameMobileContainer
} from './styles';
import { useFeedItemRenderProps } from 'modules/feed/hooks';

const renderMembersList = (list) => {
  return list.map((item, index) => {
    const { fullName, photoUrl, kudosGiven, kudosReceived } = item;
    return (
      <div key={index}>
        <Divider />
        <MemberListContainer>
          <StyledMemberListAvatar>
            <Avatar size={48} url={photoUrl} />
          </StyledMemberListAvatar>
          <MemberNameContainer>{fullName}</MemberNameContainer>
          <MemberKudosSentContainer>{kudosGiven}</MemberKudosSentContainer>
          <MemberKudosReceivedContainer>{kudosReceived}</MemberKudosReceivedContainer>
        </MemberListContainer>
      </div>
    );
  }
  );
};

export default function MembersList(props) {
  const {
    workspaceMemberAnalyticsData,
    workspaceMemberAnalyticsLoading,
    onLoadMore,
    showSpinner
  } = props;

  const { scrollTop, windowHeight, scrollHeight } = useFeedItemRenderProps();
  
  if (!showSpinner && (scrollTop + windowHeight + 100 >= scrollHeight)) {
    onLoadMore();
  }

  const renderSpinnerComponent = () => {
    if (!showSpinner) { 
      return null;
    }

    return (
      <SpinnerIconContainer> 
        <IconSpinner fill={theme.colors.blacks[40]}/> 
      </SpinnerIconContainer>
    );
  };
 
  return (
    <MembersListContainer>
      <MembersListTitle>Member Activity</MembersListTitle>
      <Divider />
      <MembersListHeaderContainer>
        <MemberHeaderIconContainer> <MembersListIcon /> </MemberHeaderIconContainer>
        <MemberHeaderNameContainer> Name </MemberHeaderNameContainer>
        <MemberHeaderKudosSentContainer> Kudos Sent</MemberHeaderKudosSentContainer>
        <MemberHeaderKudosReceivedContainer> Kudos Received </MemberHeaderKudosReceivedContainer>
        <MemberHeaderNameMobileContainer> Name </MemberHeaderNameMobileContainer>
        <MemberHeaderKudosSentMobileContainer> Sent </MemberHeaderKudosSentMobileContainer>
        <MemberHeaderKudosReceivedMobileContainer> Received </MemberHeaderKudosReceivedMobileContainer>
      </MembersListHeaderContainer>
      {!workspaceMemberAnalyticsLoading && renderMembersList(workspaceMemberAnalyticsData)}
      {renderSpinnerComponent()}
    </MembersListContainer>
  );
}

MembersList.propTypes = {
  workspaceMemberAnalyticsData: PropTypes.array,
  workspaceMemberAnalyticsLoading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  showSpinner: PropTypes.bool,
};
