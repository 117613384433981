import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { skillDetailPropType } from './prop-types';
import Gauge from '../Gauge';
import Button from '../../Button';
import { SimpleConfirmModal } from '../../Confirm';
import theme from '@matterapp/matter-theme';

const RightButton = styled(Button.Simple.Primary)`
  flex: 1 1 100%;
  padding: ${() => theme.spacing.half} 0;
  margin-left: ${() => theme.spacing.half};
`;

const LeftButton = styled(Button.Simple)`
  flex: 1 1 100%;
  padding: ${() => theme.spacing.half} 0;
  margin-right: ${() => theme.spacing.half};
`;

const LinkButton = styled(Button.Simple.Minimal).attrs({
  size: 'XS',
})`
  min-width: 100px;
  width: 100%;
  z-index: 0;
`;

const SkillGauge = styled(Gauge)`
  flex: 1;
`;

const SkillUnitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid transparent;
  text-align: center;
  width: 176px;
  height: 232px;
  background: white;
  box-shadow: ${() => theme.shadows[45]};

  transition: box-shadow 0.1s ease;

  align-items: center;
  flex-direction: column;

  ${() => theme.media.medium`
    width: 192px;
  `}
`;

class UnitItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false
    };
  }

  openDialog = () => {
    this.setState({ isDialogOpen: true });
  }

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  }

  renderRemoveSkillConfirmDialog() {
    const {
      handleConfirmRemoveSkill,
      skillDetail
    } = this.props;
    
    if (!this.state.isDialogOpen) { return null; }
    const { skill } = skillDetail;

    return (
      <SimpleConfirmModal
        transparent
        isOpen={this.state.isDialogOpen}
        buttonRight={
          <RightButton rcLabel="remove-skill-confirm" size={'M'} color={'red'} onClick={() => handleConfirmRemoveSkill(skill)} >
            Yes, Remove
          </RightButton>
        }
        buttonLeft={
          <LeftButton
            size={'M'}
            color={'black'}
            onClick={() => this.setState({ isDialogOpen: false })}
          >
            Cancel
          </LeftButton>
        }
        heading="Remove skill?"
        subheading={
          <>
            Are you sure you want to remove this skill? You will no longer
            receive feedback on:
            <strong> {skill.name}</strong>.
          </>
        }
        onCancel={this.closeDialog}
      />
    );
  }

  render() {
    const { skillDetail, skillGaugeProps, handleStartEditSkill } = this.props;
    const { average, skill, onClick, numFeedbacks } = skillDetail;
    const ratingBase = average || 0;
    const roundedRating = Math.round(ratingBase * 100) / 100;
    const showEditSkill = skill.isCustom && !numFeedbacks;
    const editSkillMenuItem = showEditSkill ? {
      menuLabel: 'Edit Skill',
      rcLabel: "menu-item-edit",
      handleAction: () => handleStartEditSkill(skill),
    } : null;
    const removeSkillMenuItem = {
      menuLabel: 'Remove Skill',
      rcLabel: "menu-item-remove",
      handleAction: this.openDialog,
    };
    const topRightMenuContent = [
      editSkillMenuItem,
      removeSkillMenuItem,
    ];

    return (
      <>
        {this.renderRemoveSkillConfirmDialog()}
        <SkillUnitContainer data-rc={this.props.rcLabel}>
          <SkillGauge
            onClickSkill={onClick}
            rcLabel="matterapp-skills-unit-list-item"
            {...skillGaugeProps}
            showScore
            alwaysShowBar
            size="medium"
            skill={skill}
            rating={average}
            text={`Rating: ${typeof average === 'number' ? roundedRating : '?'}`}
            topRightMenuContent={topRightMenuContent}
          />
          <LinkButton onClick={onClick}>
            View Feedback
          </LinkButton>
        </SkillUnitContainer>
      </>
    );
  }
}

UnitItem.propTypes = {
  skillDetail: skillDetailPropType.isRequired, // eslint-disable-line
  /** Props for the skill gauge. */
  skillGaugeProps: PropTypes.object,
  rcLabel: PropTypes.string,
  handleConfirmRemoveSkill: PropTypes.func,
  handleStartEditSkill: PropTypes.func,
  renderModal: PropTypes.func,
};

UnitItem.defaultProps = {
  rcLabel: "skills-unit-list"
};

export default UnitItem;
