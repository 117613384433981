import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Card from '../Card';
import { KUDOS_LIST } from '../consts';
import theme from '@matterapp/matter-theme';

const KudoContainer = styled.div`
  ${() => {
    return `
      margin-bottom: ${theme.spacing.single};

      & [data-type] {
        transition: transform ${theme.transitions.times.default};
      }

      &:hover [data-type] {
        box-shadow: ${theme.shadows[50]};
        transform: translateY(-2px);
      }

      &:active [data-type] {
        filter: brightness(90%);
      }
    `;
  }};
`;

export default class KudosList extends React.PureComponent {
  static propTypes = {
    /** Additional class. */
    className: PropTypes.string,
    /** Callback when a kudo is clicked. */
    onClick: PropTypes.func,
    /** List of kudos to display in list. */
    options: PropTypes.array,
    /** Plays animation on click. */
    playAnimationOnClick: PropTypes.bool,
    /** Plays animation on hover. */
    playAnimationOnHover: PropTypes.bool,
    /** Plays animation on mount. */
    playAnimationOnMount: PropTypes.bool,
    /** Text to display in all the kudo options. */
    text: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
    options: KUDOS_LIST,
    playAnimationOnClick: true,
    playAnimationOnHover: true,
    playAnimationOnMount: true,
    text: null,
  };

  render() {
    const {
      className,
      onClick,
      options,
      playAnimationOnClick,
      playAnimationOnHover,
      playAnimationOnMount,
      text,
    } = this.props;
    const kudos = options.map((option, index) => {
      return (
        <KudoContainer key={`${index}-${option}`}>
          <Card
            playAnimationOnClick={playAnimationOnClick}
            playAnimationOnHover={playAnimationOnHover}
            playAnimationOnMount={playAnimationOnMount}
            onClick={onClick}
            text={text}
            {...option}
          />
        </KudoContainer>
      );
    });
    return <div className={className}>{kudos}</div>;
  }
}
