import RecurringPage from './RecurringPage';
import { withData } from '../WorkspacesSharedHandlers';
import { compose } from 'recompose';
import { withPageViewTracking } from 'hocs';

const RecurringPageContainer = withData(RecurringPage);

export default compose(
  withPageViewTracking({
    pageTitle: 'workspaces/recurring',
  })
)(RecurringPageContainer);
