import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../libs/omitStyled';

const FooterContainer = omitStyled('footer', ['borderTop'])`
  height: 73px;
  width: 100%;
  background: white;
  justify-content: flex-start;
  border-radius: 0 0 12px 12px;

  ${({ borderTop }) =>
    borderTop &&
    `
    border-top: 1px solid ${theme.colors.blacks[10]};
  `};

  ${() => theme.media.large`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

const FooterContent = styled.div`
  height: 100%;
  padding: 0 20px;
  display: flex;
  box-sizing: border-box;
  align-items: center;

  ${() => theme.media.large`
    max-width: ${theme.sizes.webClient.maxContentWidth};
    width: 100%;
  `}
`;

const Left = styled.div`
  flex: 1;
  text-align: left;
`;

const Right = styled.div`
  text-align: right;
`;

const Footer = ({ footerRightComponent, footerLeftComponent, borderTop }) => (
  <FooterContainer borderTop={borderTop} className="footer-wrapper">
    <FooterContent>
      <Left>{footerLeftComponent}</Left>
      <Right>{footerRightComponent}</Right>
    </FooterContent>
  </FooterContainer>
);

Footer.defaultProps = {
  footerLeftComponent: false,
  footerRightComponent: false,
  borderTop: true,
};

Footer.propTypes = {
  footerLeftComponent: PropTypes.node,
  footerRightComponent: PropTypes.node,
  borderTop: PropTypes.bool,
};

export default Footer;
