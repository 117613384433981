import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../../Button';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  position: relative;
  padding: ${theme.spacing.double} 0 ${theme.spacing.single} 0;
  text-align: ${({ leftAlign }) => (leftAlign ? 'left' : 'center')};
`;

const Header = styled.div`
  color: ${theme.colors.blacks[70]};
  font-size: ${theme.fontSize.M};
  font-weight: ${theme.fontWeight.bold};
  padding: 0 ${theme.spacing.singleAndHalf};
`;

const SubHeader = styled.div`
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.S};
  padding: 0 ${theme.spacing.singleAndHalf};
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const CloseButton = styled(Button.Icon).attrs(() => ({
  iconName: 'close',
}))``;

const CTA = (props) => {
  const { header, leftAlign, onClickClose, subHeader } = props;
  const showClose = onClickClose && typeof onClickClose === 'function';
  return (
    <Container leftAlign={leftAlign}>
      {showClose && (
        <CloseButtonContainer>
          <CloseButton onClick={onClickClose} />
        </CloseButtonContainer>
      )}
      {header && <Header>{header}</Header>}
      {subHeader && <SubHeader>{subHeader}</SubHeader>}
    </Container>
  );
};

CTA.defaultProps = {
  textAlign: true,
};

CTA.propTypes = {
  /** Header Text. */
  header: PropTypes.node,
  /** If text if left aligned. */
  leftAlign: PropTypes.bool,
  /** If function passed, it will display close button at top right. */
  onClickClose: PropTypes.func,
  /** Sub Header Text. */
  subHeader: PropTypes.node,
};

export default CTA;
