import styled from 'styled-components';
import { ClickableLink } from '../../Clickable/Clickable';
import Grid from '../../Grid';
import theme from '@matterapp/matter-theme';

export const Container = styled.div``;

export const GridContainer = styled.div`
  overflow: hidden;
  transition: max-height ${theme.transitions.times.default};

  ${({ isCompact, isExpanded }) => {
    if (!isCompact) {
      return `
        max-height: ${isExpanded ? 144 : 64}px;
      `;
    }
  }}
`;

export const KudosGrid = styled(Grid).attrs(({ isCompact }) => ({
  responsiveColumns: isCompact ? [1, 1, 2, 2, 2] : [2, 2, 3, 3, 3],
  spacing: theme.spacing.single,
}))``;

export const ExpandContainer = styled.div`
  display: ${({ isCompact }) => (isCompact ? 'none' : 'flex')};
  justify-content: center;
  padding: ${theme.spacing.single};

  ${({ expandable }) => theme.media.S`
    display: ${expandable ? 'flex' : 'none'};
  `}
`;

export const ExpandButton = styled(ClickableLink)`
  color: ${theme.colors.blacks[40]};
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
  padding: ${theme.spacing.half};
  text-transform: uppercase;
`;
