import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Panel from '../../../Panel';
import AvatarHeader from '../../AvatarHeader';
import { Footer } from '../../FeedItem';
import GiveFeedbackButtons from './GiveFeedbackButtons';

const ImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: ${theme.borderRadius.L};
  height: 80px;
  margin-left: ${theme.spacing.singleAndQuarter};
  margin-right: ${theme.spacing.singleAndQuarter};
  background-image: url(${({ imageInstance }) => imageInstance.mobile});
  background-size: cover;
  background-repeat: no-repeat;
  ${theme.media.S`
    margin-left: ${theme.spacing.singleAndHalf};
    margin-right: ${theme.spacing.singleAndHalf};
  `};
`;

const RitualStart = ({
  footerProps = {},
  mainMessage,
  photoUrl,
  onClickKudos,
  onClickFeedback,
  onClickDelete,
  TooltipComponent
}) => {
  const panelHeader = 
    <AvatarHeader
      equalPadding={false}
      canClickHeader={false}
      subheader={mainMessage}
      photoUrl={photoUrl}
      showMenu={false}
      onClickDelete={onClickDelete}
    />;

  return (
    <Panel.Base header={panelHeader}>
      <ImageDiv imageInstance={Panel.Block.ActionImage.imageInstances.ritualKickOffPreview} />
      <GiveFeedbackButtons onClickKudos={onClickKudos} onClickFeedback={onClickFeedback} />
      <Footer  {...footerProps} TooltipComponent={TooltipComponent} />
    </Panel.Base>
  );
};

RitualStart.defaultProps = {
  commentProps: {},
};

export default RitualStart;