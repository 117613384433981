import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FormContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 992px;
  min-width: 320px;

  input {
    text-align: left;
  }

  form,
  input[type='email'],
  button {
    width: 100%;
  }
`;

const StackedForm = ({ handleSubmit, renderInput, renderButton }) => (
  <FormContainer>
    <form onSubmit={handleSubmit}>
      <div style={{ marginBottom: 14 }}>{renderInput()}</div>
    </form>
    {renderButton()}
  </FormContainer>
);

StackedForm.propTypes = {
  renderInput: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  renderButton: PropTypes.func.isRequired,
};

export default StackedForm;
