import PropTypes from 'prop-types';

/*****************************************************************************/
/* SLACK USER PROP TYPE                                                      */
/*****************************************************************************/
export const slackUserPropTypeObj = {
  id: PropTypes.string,
  isBot: PropTypes.bool,
  displayName: PropTypes.string,
  email: PropTypes.string,
  matterUser: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    isSelf: PropTypes.bool,
  }),
};

export const slackUserDefaultProp = {
  matterUser: {},
};

export const slackUserPropType = PropTypes.shape(slackUserPropTypeObj);

/*****************************************************************************/
/* SLACK WORKSPACE SETTINGS PROP TYPE                                        */
/*****************************************************************************/
export const slackWorkspacePropTypeObj = {
  id: PropTypes.string,
  teamName: PropTypes.string,
  postAsUserOAuthURL: PropTypes.string,
  primaryChannel: PropTypes.string,
};

export const slackWorkspaceDefaultProp = {
};

export const slackWorkspacePropType = PropTypes.shape(slackWorkspacePropTypeObj);