import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.347811 0.347811C0.811558 -0.115937 1.56344 -0.115937 2.02719 0.347811L6 4.32062L9.97281 0.347811C10.4366 -0.115937 11.1884 -0.115937 11.6522 0.347811C12.1159 0.811558 12.1159 1.56344 11.6522 2.02719L7.67938 6L11.6522 9.97281C12.1159 10.4366 12.1159 11.1884 11.6522 11.6522C11.1884 12.1159 10.4366 12.1159 9.97281 11.6522L6 7.67938L2.02719 11.6522C1.56344 12.1159 0.811558 12.1159 0.347811 11.6522C-0.115937 11.1884 -0.115937 10.4366 0.347811 9.97281L4.32062 6L0.347811 2.02719C-0.115937 1.56344 -0.115937 0.811558 0.347811 0.347811Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#EB2E49',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
