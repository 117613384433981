import styled from 'styled-components';
import {
  Button,
  IconNew as Icon,
  Peer,
} from '@matterapp/matter-ui';
import { font, spacing, colors, lineHeight, transitions } from '@matterapp/matter-theme';

export const MenuIcon = styled(Icon).attrs(() => ({
  inverted: true,
  name: 'meatballs',
}))``;

export const AddKudosButton = styled(Button).attrs(() => ({
  content: 'Add Kudos',
  size: Button.buttonSizes.S,
}))``;

export const CustomKudosList = styled(Peer.List.WithAddRemove.CustomSubHeader).attrs(() => ({
  flushedEdgeOnMobile: true,
}))({
  margin: `0 -${spacing.singleAndHalf}`,
  paddingBottom: '24px'
});

export const MenuContainer = styled.div`
  ${({ displayInSlideMenu }) => `
    color: ${colors.blacks[50]};
    display: flex;
    justify-content: flex-end;
    height: auto;
    width: 100%;

    &:hover {
      color: ${colors.blues[50]};
    }
    & ${MenuIcon} {
      margin: ${displayInSlideMenu ? 0 : `-96px ${spacing.threeQuarters} 0 0`};

      path {
        fill: currentColor;
        transition: fill ${transitions.times.default};
      }
    }
  `};
`;

export const CustomAvatar = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  height: ${spacing.triple};
  font-size: 11px;
  font-weight: ${font.weight.bold};
  line-height: ${lineHeight.base};
  color: ${colors.blacks[30]};
  border: 1px solid ${colors.blacks[10]};
  border-radius: ${spacing.quarter};
`;

export const LockIcon = styled(Icon).attrs(() => ({
  name: 'lock'
}))``;

export const IconContainer = styled.div`
& svg {
  path {
    fill: ${colors.blacks[30]};
  }
}
`;
