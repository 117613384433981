import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 2.725C25 9.056 22.21 14.387 18.412 16 22.21 17.613 25 22.944 25 29.275c0 .184-.009.366-.017.547v.003l-.008.175H7.025a33.986 33.986 0 0 0-.008-.175v-.003C7.008 29.64 7 29.459 7 29.275 7 22.944 9.79 17.613 13.588 16 9.79 14.387 7 9.056 7 2.725c0-.184.008-.366.017-.547v-.003L7.025 2h17.95l.008.175v.003c.008.181.017.363.017.547zM16 8.79c2.68 0 5.048-.655 6.47-1.656-.664 2.675-1.905 4.887-3.446 6.168a3.88 3.88 0 0 1-.119.103C16.28 15.595 16 16.5 16 16.5s-.28-.905-2.905-3.095a3.914 3.914 0 0 1-.119-.103c-1.54-1.28-2.782-3.493-3.446-6.168C10.952 8.135 13.32 8.79 16 8.79zM10.91 28c.435-2.84 2.55-5 5.09-5s4.655 2.16 5.09 5H10.91z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
