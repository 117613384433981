import React from 'react';
import Base, { Group, Path, PathEvenOdd } from '../../Image';
import * as C from './classNames';

const Image = (props) => {
  return (
    <Base {...props}>
      <Group className={C.CAT}>
        <Path
          fill="#FF4668"
          d="M80.5,75.5c0.4,3.6,2.4,9,2.4,9s4-5.4,7.3-8.1c3.3-2.7,10.8-5.6,10.8-5.6s-6.4-3.4-10.1-4.6c-1.9-0.6-4.6-1.1-6.6-1.3c-1.9-0.2-3.6,1.2-3.8,3.1C80.3,70.3,80.2,73.3,80.5,75.5z"
        />
        <PathEvenOdd
          fill="#FFE7EE"
          d="M93.9,64.4c4.1,1.4,9.6,4.4,11.3,5.4c4.1-1.1,8.5-1.7,13-1.7c5,0,9.7,0.7,14.2,2l0,0c0,0,7-4,11.9-5.7c3.5-1.2,8.5-2.1,11.8-2.6c2-0.3,3.8,1.1,4,3.1c0.3,3.4,0.6,8.5,0.2,12.2c-0.4,3.7-2,8.7-2.9,11.3c2.9,4.8,4.6,10,4.6,15.4c0,7.4-3.1,14.2-8.4,19.7c7.4,8.3,11.3,18.7,11.3,28.1c0,19.6-17.2,45.9-46.5,45.9c-29.3,0-46.7-26.3-46.7-45.9c0-9.4,4-19.9,11.4-28.2c-5.2-5.5-8.3-12.3-8.3-19.7c0-6,2.1-11.9,5.6-17c-0.9-2.7-2-6.6-2.3-9.6c-0.4-3.7-0.1-8.8,0.2-12.2c0.2-2,2-3.4,4-3.1C85.5,62.2,90.4,63.1,93.9,64.4z"
        />
        <PathEvenOdd
          fill="#FF6588"
          d="M133.1,69.7c1.9-1,7.2-3.9,11.2-5.3c3.5-1.2,8.5-2.1,11.8-2.6c2-0.3,3.8,1.1,4,3.1c0.3,3.4,0.6,8.5,0.2,12.2c-0.4,3.7-2,8.7-2.9,11.3c2.9,4.8,4.6,10,4.6,15.4c0,7.4-3.1,14.2-8.4,19.7c7.4,8.3,11.3,18.7,11.3,28.1c0,19.6-17.2,45.9-46.5,45.9c-29.3,0-46.7-26.3-46.7-45.9c0-9.4,4-19.9,11.4-28.2c-5.2-5.5-8.3-12.3-8.3-19.7c0-6,2.1-11.9,5.6-17c-0.9-2.7-2-6.6-2.3-9.6c-0.4-3.7-0.1-8.8,0.2-12.2c0.2-2,2-3.4,4-3.1c3.3,0.5,8.2,1.4,11.8,2.6c4.1,1.4,9.6,4.4,11.3,5.4c4.1-1.1,8.5-1.7,13-1.7c4,0,7.8,0.5,11.4,1.3c0.9,0.2,1.8,0.4,2.7,0.7c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0.1,0C132.6,69.9,132.8,69.8,133.1,69.7z M132.2,68c0.1-0.1,0.3-0.2,0.5-0.3c0.7-0.4,1.8-1,3-1.6c2.4-1.2,5.4-2.7,8-3.6c3.7-1.3,8.8-2.2,12.1-2.7c3.1-0.5,5.9,1.7,6.2,4.8c0.3,3.4,0.6,8.7,0.2,12.6c-0.4,3.5-1.8,8.1-2.7,10.8c2.8,4.8,4.4,10.1,4.4,15.6c0,7.4-2.9,14.2-7.7,19.8c6.9,8.4,10.7,18.7,10.7,28.1c0,10.3-4.5,22.2-12.7,31.6c-8.3,9.4-20.4,16.3-35.7,16.3c-15.3,0-27.5-6.9-35.8-16.3c-8.3-9.4-12.8-21.3-12.8-31.6c0-9.4,3.8-19.7,10.8-28.1c-4.8-5.6-7.7-12.4-7.7-19.7c0-6.1,2-12.1,5.5-17.3c-0.8-2.7-1.8-6.2-2.1-9.1c-0.5-4-0.1-9.2,0.2-12.6c0.3-3.1,3.1-5.3,6.2-4.8c3.3,0.5,8.4,1.4,12.1,2.7c3.8,1.3,8.7,3.9,11,5.2c4.1-1,8.3-1.6,12.8-1.6C123.1,66.1,127.8,66.8,132.2,68z"
        />
        <Path
          fill="#FFABC2"
          d="M156.8,75.5c-0.4,3.6-2.4,9-2.4,9s-4-5.4-7.3-8.1c-3.3-2.7-10.8-5.6-10.8-5.6s6.4-3.4,10.1-4.6c1.9-0.6,4.6-1.1,6.6-1.3c1.9-0.2,3.6,1.2,3.8,3.1C156.9,70.3,157,73.3,156.8,75.5z"
        />
        <Path
          fill="#FFABC2"
          d="M81.2,76c0.4,3.6,2.4,9,2.4,9s4-5.4,7.3-8.1c3.3-2.7,10.8-5.6,10.8-5.6s-6.4-3.4-10.1-4.6c-1.9-0.6-4.6-1.1-6.6-1.3c-1.9-0.2-3.6,1.2-3.8,3.1C81.1,70.8,81,73.8,81.2,76z"
        />
        <Path
          className={C.EYE.LEFT}
          fill="#343799"
          d="M109.8,95.6c-0.6,0-1.2-0.4-1.4-1c-0.7-2.2-2.7-3.6-5-3.6c-2.3,0-4.3,1.5-5,3.6c-0.3,0.8-1.1,1.2-1.9,0.9c-0.8-0.3-1.2-1.1-0.9-1.9c1.1-3.4,4.2-5.6,7.8-5.6c3.5,0,6.6,2.3,7.8,5.6c0.3,0.8-0.2,1.6-0.9,1.9C110.1,95.5,109.9,95.6,109.8,95.6z"
        />
        <Path
          className={C.EYE.RIGHT}
          fill="#343799"
          d="M139.6,95.6c-0.6,0-1.2-0.4-1.4-1c-0.7-2.2-2.7-3.6-5-3.6c-2.3,0-4.3,1.5-5,3.6c-0.3,0.8-1.1,1.2-1.9,0.9c-0.8-0.3-1.2-1.1-0.9-1.9c1.1-3.4,4.2-5.6,7.8-5.6c3.5,0,6.6,2.3,7.8,5.6c0.3,0.8-0.2,1.6-0.9,1.9C139.9,95.5,139.7,95.6,139.6,95.6z"
        />
        <PathEvenOdd
          className={C.MOUTH}
          fill="#343799"
          d="M120,103.4c0.6-0.5,1.1-1.3,1.1-2.2c0-1.5-1.3-2.1-2.8-2.1c-1.5,0-2.8,0.6-2.8,2.1c0,0.9,0.4,1.7,1.1,2.3v1.1h0c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.7,0.5-1.1,0.6c-0.4,0.1-0.9,0.2-1.3,0.2s-0.9-0.1-1.3-0.2c-0.4-0.2-0.8-0.4-1.1-0.6c-0.3-0.3-0.6-0.6-0.7-1c-0.3-0.6-0.8-1.1-1.4-1.1h-0.3c-0.8,0-1.5,0.7-1.2,1.4c0.1,0.2,0.2,0.5,0.3,0.7c0.3,0.7,0.8,1.3,1.4,1.8c0.6,0.5,1.3,0.9,2,1.2c0.8,0.3,1.6,0.4,2.4,0.4c0.6,0,1.2-0.1,1.8-0.2c0.2,0.3,0.4,0.6,0.6,0.8c0.4,0.4,0.8,0.6,1.3,0.8c0.5,0.2,1,0.3,1.5,0.3c0.5,0,1-0.1,1.5-0.3c0.5-0.2,0.9-0.5,1.3-0.8c0.2-0.2,0.5-0.5,0.6-0.8c0.6,0.2,1.2,0.2,1.9,0.2c0.8,0,1.7-0.1,2.5-0.4c0.8-0.3,1.5-0.7,2.1-1.2c0.6-0.5,1.1-1.1,1.4-1.8c0.1-0.2,0.2-0.4,0.3-0.7c0.2-0.8-0.4-1.5-1.3-1.5h-0.3c-0.7,0-1.2,0.6-1.4,1.1c-0.2,0.4-0.4,0.7-0.8,1c-0.3,0.3-0.7,0.5-1.1,0.6c-0.4,0.1-0.9,0.2-1.3,0.2s-0.9-0.1-1.3-0.2c-0.4-0.2-0.8-0.4-1.1-0.6c-0.3-0.3-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.8-0.3-1.1h0V103.4z M118.1,108h0.2h0.1c0,0-0.1-0.1-0.1-0.2C118.2,107.9,118.2,108,118.1,108z"
        />
        <Group className={C.WHISKERS.LEFT}>
          <PathEvenOdd
            fill="#343799"
            d="M83.1,100.4c4-0.5,6.6,0.5,8.4,2.4c0.4,0.4,1.1,0.5,1.6,0.1c0.4-0.4,0.5-1.1,0.1-1.6c-2.4-2.5-5.7-3.7-10.3-3.1c-0.6,0.1-1,0.6-1,1.2C81.9,100,82.5,100.4,83.1,100.4z"
          />
          <PathEvenOdd
            fill="#343799"
            d="M87.1,106.1c1.4,0,2.6,0.2,3.1,0.4c0.6,0.2,1.2,0,1.4-0.6c0.2-0.6,0-1.2-0.6-1.4c-0.9-0.4-2.4-0.6-3.9-0.6c-1.6,0-3.4,0.2-5,0.9c-0.6,0.2-0.8,0.9-0.6,1.4c0.2,0.6,0.9,0.8,1.4,0.6C84.1,106.3,85.7,106.1,87.1,106.1z"
          />
        </Group>
        <Group className={C.WHISKERS.RIGHT}>
          <PathEvenOdd
            fill="#343799"
            d="M154.4,100.4c-4-0.5-6.6,0.5-8.4,2.4c-0.4,0.4-1.1,0.5-1.6,0.1s-0.5-1.1-0.1-1.6c2.4-2.5,5.7-3.7,10.3-3.1c0.6,0.1,1,0.6,1,1.2C155.5,100,155,100.4,154.4,100.4z"
          />
          <PathEvenOdd
            fill="#343799"
            d="M150.4,106.1c-1.4,0-2.6,0.2-3.1,0.4c-0.6,0.2-1.2,0-1.4-0.6c-0.2-0.6,0-1.2,0.6-1.4c0.9-0.4,2.4-0.6,3.9-0.6c1.6,0,3.4,0.2,5,0.9c0.6,0.2,0.8,0.9,0.6,1.4c-0.2,0.6-0.9,0.8-1.4,0.6C153.3,106.3,151.8,106.1,150.4,106.1z"
          />
        </Group>
        <Group className={C.NECKLACE.GROUP}>
          <PathEvenOdd
            fill="#FF4668"
            d="M151.1,124.7c-4.5,3.4-17.5,5.9-32.8,5.9c-15.3,0-28.3-2.5-32.9-5.9c-1.1,0.8-1.7,1.7-1.7,2.7c0,4.7,15.5,8.5,34.6,8.5c19.1,0,34.6-3.8,34.6-8.5C152.9,126.4,152.3,125.5,151.1,124.7z"
          />
          <Group className={C.NECKLACE.BELL}>
            <Path
              fill="#F3A83E"
              d="M110,142.9c-0.7,0-1.3-0.6-1.2-1.2c0.1-0.9,0.3-1.8,0.7-2.7c0.5-1.2,1.3-2.4,2.2-3.3c1-1,2.1-1.7,3.3-2.2s2.6-0.8,3.9-0.8c1.4,0,2.7,0.3,3.9,0.8c1.2,0.5,2.4,1.3,3.3,2.2c1,1,1.7,2.1,2.2,3.3c0.4,0.9,0.6,1.8,0.7,2.7c0.1,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.3-1.2c-0.1-0.6-0.3-1.2-0.5-1.7c-0.4-0.9-1-1.8-1.7-2.5c-0.7-0.7-1.6-1.3-2.5-1.7c-0.9-0.4-2-0.6-3-0.6c-1,0-2,0.2-3,0.6c-0.9,0.4-1.8,1-2.5,1.7c-0.7,0.7-1.3,1.6-1.7,2.5c-0.2,0.6-0.4,1.1-0.5,1.7C111.3,142.3,110.7,142.9,110,142.9z"
            />
            <Path
              fill="#FFCF7A"
              d="M127.9,140.2c0,4.8-3.9,8.7-8.7,8.7c-4.8,0-8.7-3.9-8.7-8.7c0-4.8,3.9-8.7,8.7-8.7C124,131.5,127.9,135.4,127.9,140.2z"
            />
            <PathEvenOdd
              fill="#F3A83E"
              d="M117.1,141.3c0-0.6,0.5-1.1,1.1-1.1h2.7c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1h-0.3v6.4h-2.2v-6.4h-0.2C117.6,142.4,117.1,141.9,117.1,141.3z"
            />
          </Group>
        </Group>
        <Group className={C.BACKGROUND}>
          <Path
            className={C.STAR.LEFT}
            fill="#FF6588"
            d="M28.6,53l2.9,3.3l4.4,0.2l-2.2,3.8l1.2,4.2l-4.3-1l-3.7,2.4l-0.4-4.3L23,58.8l4-1.7L28.6,53z"
          />
          <Path
            className={C.STAR.TOP}
            fill="#FF6588"
            d="M129,40.6l2.9-0.7l1.6-2.9l1.5,3l2.9,0.8l-2,2.5l0.2,3.4l-2.7-1.4l-2.8,1.3l0.3-3.4L129,40.6z"
          />
          <Path
            className={C.STAR.RIGHT_1}
            fill="#FF6588"
            d="M180.3,23.8l4.5,1.1l3.9-2.7l0.4,4.9l3.6,3.1l-4.3,1.9l-1.7,4.6l-3.1-3.7l-4.7-0.2l2.4-4.2L180.3,23.8z"
          />
          <Path
            className={C.STAR.RIGHT_2}
            fill="#FF6588"
            d="M196.1,43.2l2.4,2.1l3.1-0.3l-1.2,3l1.3,3l-3.1-0.3l-2.3,2.2l-0.7-3.2l-2.7-1.6l2.7-1.7L196.1,43.2z"
          />
          <Path
            className={C.CIRCLE.LEFT_2}
            fill="#FF6588"
            d="M59.6,57.6c2.4,0,4.4-2,4.4-4.4c0-2.4-2-4.4-4.4-4.4c-2.4,0-4.4,2-4.4,4.4C55.2,55.7,57.2,57.6,59.6,57.6z"
          />
          <Path
            className={C.CIRCLE.LEFT_1}
            fill="#FF6588"
            d="M46,89.5c2.2,0,4-1.8,4-4s-1.8-4-4-4c-2.2,0-4,1.8-4,4S43.8,89.5,46,89.5z"
          />
          <Path
            className={C.RING}
            fill="#FF6588"
            d="M46,126.1c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C54.1,122.5,50.5,126.1,46,126.1z M46,113.7c-2.4,0-4.3,1.9-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3c2.4,0,4.3-1.9,4.3-4.3C50.4,115.7,48.4,113.7,46,113.7z"
          />
          <PathEvenOdd
            className={C.PLUS}
            fill="#FF6588"
            d="M121.4,24.7c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8v-3.7h3.6c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8H125v-3.7c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8v3.7h-3.6c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h3.6V24.7z"
          />
          <Path
            className={C.CIRCLE.TOP}
            fill="#FF6588"
            d="M115.5,52.8c2.2,0,4-1.8,4-4s-1.8-4-4-4c-2.2,0-4,1.8-4,4C111.5,51,113.3,52.8,115.5,52.8z"
          />
          <Path
            className={C.CIRCLE.BACK}
            fill="#FFFFFF"
            d="M170.3,71.5c6.1,0,11-4.9,11-11s-4.9-11-11-11c-6.1,0-11,4.9-11,11C159.3,66.5,164.3,71.5,170.3,71.5z"
          />
        </Group>
        <Group className={C.HAND}>
          <PathEvenOdd
            fill="#FFE7EE"
            d="M184.3,59.5c0,2.5-0.8,4.6-2,5.9c-0.3,1.1-0.9,2.1-1.8,3.1h0l1.4,53.9c0.3,8.8-6.8,16.1-15.6,16.1c-4.4,0-8-3.7-7.8-8.1l2.1-61.6c-1-0.9-1.6-1.9-2-3.1c-1.4-1.2-2.3-3.6-2.3-6.2c0-4,2.1-7.2,4.6-7.2c0,0,0.1,0,0.1,0c0.5-3.2,2.7-5.6,5.3-5.6c1.6,0,3.1,0.9,4,2.4c1-1.5,2.4-2.4,4-2.4c2.6,0,4.7,2.4,5.3,5.6C182.3,52.5,184.3,55.6,184.3,59.5z"
          />
          <PathEvenOdd
            fill="#FF6588"
            d="M181.9,122.4c0.3,8.8-6.8,16.1-15.6,16.1c-4.4,0-8-3.7-7.8-8.1l2.1-61.6c-1-0.9-1.6-1.9-2-3.1c-1.4-1.2-2.3-3.6-2.3-6.2c0-4,2.1-7.2,4.6-7.2c0,0,0.1,0,0.1,0c0.5-3.2,2.7-5.6,5.3-5.6c1,0,1.9,0.3,2.7,1c0.5,0.4,1,0.9,1.3,1.5c0.4-0.6,0.8-1.1,1.3-1.5c0.8-0.6,1.7-1,2.7-1c2.6,0,4.7,2.4,5.3,5.6c2.5,0.2,4.5,3.3,4.5,7.2c0,2.5-0.8,4.6-2,5.9c-0.1,0.4-0.2,0.8-0.4,1.1c-0.3,0.7-0.8,1.3-1.3,1.9c0,0,0,0,0,0h0L181.9,122.4zM182.5,69.2l1.3,53.2c0.3,9.9-7.6,18.1-17.5,18.1c-5.5,0-10-4.6-9.7-10.2l2.1-60.7c-0.7-0.8-1.3-1.7-1.7-2.7c-1.7-1.7-2.6-4.5-2.6-7.3c0-2.3,0.6-4.5,1.7-6.2c0.8-1.3,2-2.4,3.5-2.8c1-3.1,3.5-5.8,6.9-5.8c1.6,0,2.9,0.6,4,1.5c1.1-0.9,2.5-1.5,4-1.5c3.4,0,5.9,2.7,6.9,5.9c1.4,0.5,2.4,1.6,3.2,2.8c1,1.6,1.6,3.8,1.6,6c0,2.6-0.8,5.1-2.2,6.9C183.7,67.4,183.2,68.3,182.5,69.2z"
          />
          <Path
            fill="#FFABC2"
            d="M182,58.7c0,1.8-1.1,3.2-2.5,3.2c-1.4,0-2.5-1.4-2.5-3.2s1.1-3.2,2.5-3.2C180.8,55.5,182,56.9,182,58.7z"
          />
          <Path
            fill="#FFABC2"
            d="M177,53c0,1.8-1.1,3.2-2.5,3.2S172,54.8,172,53c0-1.8,1.1-3.2,2.5-3.2S177,51.3,177,53z"
          />
          <Path
            fill="#FFABC2"
            d="M168.6,53c0,1.8-1.1,3.2-2.5,3.2c-1.4,0-2.5-1.4-2.5-3.2c0-1.8,1.1-3.2,2.5-3.2C167.5,49.9,168.6,51.3,168.6,53z"
          />
          <Path
            fill="#FFABC2"
            d="M163.1,58.7c0,1.8-1.1,3.2-2.5,3.2s-2.5-1.4-2.5-3.2s1.1-3.2,2.5-3.2S163.1,56.9,163.1,58.7z"
          />
          <Path
            fill="#FFABC2"
            d="M164.6,63.1c0.6-1.9,2.4-3.2,4.3-3.2h2.7c2,0,3.7,1.3,4.3,3.2c0.8,2.4-0.6,5-3,5.7l-1.2,0.4c-1.1,0.3-2.2,0.3-3.2,0l-0.9-0.3C165.1,68.1,163.8,65.5,164.6,63.1z"
          />
        </Group>
      </Group>
    </Base>
  );
};

export default Image;
