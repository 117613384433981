import PropTypes from 'prop-types';
import omitStyled from '../../libs/omitStyled';
import { smallBreakpoint } from './consts';
import theme from '@matterapp/matter-theme';
import TextBlock from './TextBlock';

const FlashContainer = omitStyled('div', ['type'])`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 17px;
  line-height: 24px;

  min-height: 80px;

  padding: 12px 0;
  background: ${() => theme.colors.blues[40]};
  color: white;
  text-align: center;

  ${({ type }) =>
    type === 'important' &&
    `
    background: ${theme.colors.yellows[40]};
    color: ${theme.colors.black};
  `}

  ${TextBlock}:first-child {
    margin-top: 0;
  }
  ${TextBlock}:last-child {
    margin-bottom: 0;
  }

  ${() => theme.media.breakpoint(smallBreakpoint)`
    border-radius: ${theme.sizes.borderRadius[80]};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

FlashContainer.defaultProps = {
  type: 'normal',
};

FlashContainer.propTypes = {
  type: PropTypes.oneOf(['normal', 'important']),
};

export default FlashContainer;
