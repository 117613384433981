import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const itemHeight = 40;

const Item = styled.li`
  cursor: pointer;
  height: ${itemHeight}px;
  font-weight: 500;

  > * {
    height: ${itemHeight}px;
    line-height: ${itemHeight}px;
    font-size: ${() => theme.sizes.fontSizes[40]} !important;
  }

  ${({ danger }) =>
    danger &&
    `
    color: ${theme.colors.reds[60]};
    &:hover {
      background: ${theme.colors.reds[5]};
    }
  `}

  ${({ danger }) =>
    !danger &&
    `
    color: ${theme.colors.blues[60]};
    &:hover {
      background: ${theme.colors.blues[5]};
      color: ${theme.colors.blue};
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    cursor: initial;
    color: ${theme.colors.blacks[30]};
    background: initial;

    &:hover {
      background: initial;
      color: ${theme.colors.blacks[30]};
    }
  `}


  ${({ disabled }) =>
    !disabled &&
    `
    &:active {
      text-decoration: underline;
    }
  `}

  transition: color 0.1s ease-in-out;
`;

const StyledButton = styled.a`
  color: inherit;
  &:hover {
    color: inherit;
  }
  &:active {
    color: inherit;
    text-decoration: inherit;
  }
  border: none;
`;

const Button = ({
  children,
  content,
  onClick,
  danger,
  disabled,
  unstyled,
  rcLabel
}) => {
  const contentToShow = <>{children}{content}</>;
  return (
    <Item
      onClick={(e) => {
        if (!disabled) {
          onClick(e);
        }
      }}
      danger={danger}
      disabled={disabled}
      data-rc={rcLabel}
    >
      {unstyled ? contentToShow : <StyledButton>{contentToShow}</StyledButton>}
    </Item>
  );
};

Button.defaultProps = {
  danger: false,
  disabled: false,
  onClick: () => {},
  unstyled: false,
};

Button.propTypes = {
  /** Children to display in the MenuList.Button */
  children: PropTypes.node,
  /** Additional children to display in the MenuList.Button */
  content: PropTypes.node,
  /** Callback when button is clicked. */
  onClick: PropTypes.func,
  /** Applies danger/warning styles. */
  danger: PropTypes.bool,
  /** If button is disabled. */
  disabled: PropTypes.bool,
  /** Pass children directly to container and does not apply styles. */
  unstyled: PropTypes.bool,
  rcLabel: PropTypes.string,
};

export default Button;
