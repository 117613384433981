export const VALUE_TYPE = {
  VARIABLE: 'VARIABLE_VALUE',
  FIXED: 'FIXED_VALUE',
};

export const REWARD_TYPES = {
  ALL: 'all',
  CUSTOM: 'custom',
  DONATION: 'donation',
  GIFT_CARD: 'gift card',
  CASH_EQUIVALENT: 'cash equivalent'
};

export const ALL_COUNTRIES_ITEM = { label: 'All Countries', value: REWARD_TYPES.ALL };

export const REWARD_TYPE_ITEMS = [
  { label: 'All Categories', value: REWARD_TYPES.ALL}, 
  { label: 'Custom', value: REWARD_TYPES.CUSTOM},
  { label: 'Donations', value: REWARD_TYPES.DONATION}, 
  { label: 'Gift Cards', value: REWARD_TYPES.GIFT_CARD},
  { label: 'Prepaid Cards', value: REWARD_TYPES.CASH_EQUIVALENT}
];
