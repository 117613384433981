import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0l2.328 3.208c.706.127 1.392.312 2.053.549L24 2.145l.413 3.944c.54.458 1.04.959 1.498 1.498L29.855 8l-1.314 2.95c-1.072-.235-3.279-.604-6.041-.45-1.585.087-2.753.502-3.798.874-.93.33-1.76.626-2.702.626-.941 0-1.773-.296-2.702-.626-1.044-.372-2.213-.787-3.798-.874-2.762-.154-4.969.215-6.041.45L2.145 8l3.944-.413c.458-.539.959-1.04 1.497-1.498L8 2.145l3.619 1.612a12.916 12.916 0 0 1 2.053-.55L16 0zM3.46 12.562c-.1.364-.184.734-.252 1.11L0 16l3.208 2.328c.127.706.312 1.392.549 2.053L2.145 24l3.944.413c.458.539.959 1.04 1.497 1.498L8 29.855l3.619-1.612c.661.237 1.347.422 2.053.55L16 32l2.328-3.208a12.906 12.906 0 0 0 2.053-.549L24 29.855l.413-3.945a13.08 13.08 0 0 0 1.498-1.497L29.855 24l-1.612-3.619a12.9 12.9 0 0 0 .55-2.053L32 16l-3.208-2.328a12.942 12.942 0 0 0-.251-1.11c-.254.213-.472.598-.511 1.288-.007.12-.017.234-.03.342v.058c0 3.45-2.46 6.25-5.5 6.25-2.584 0-4.749-2.023-5.34-4.748l-.005-.002-.003-.033a7.013 7.013 0 0 1-.118-.772C16.892 14.3 16.596 13.5 16 13.5c-.596 0-.892.8-1.034 1.445-.025.263-.065.52-.118.772l-.003.033-.005.002C14.25 18.477 12.084 20.5 9.5 20.5c-3.04 0-5.5-2.8-5.5-6.25v-.058a5.078 5.078 0 0 1-.03-.342c-.039-.69-.257-1.075-.51-1.288zM16 25.5c2.21 0 4-1.79 4-4h-8c0 2.21 1.79 4 4 4z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
