import { graphql } from '@apollo/client/react/hoc';
import withRouter from 'hocs/withRouter';
import { compose, withProps } from 'recompose';
import withCurrentUser from 'context/UserContext/withCurrentUser';
import withAuthReducer from './withAuthReducer';
import { GOOGLE_YOLO_LOGIN_MUTATION } from 'graphql-queries/queries';
import { handleTrackSignup } from 'libs/tracking/signup';

const handleTrackGoogleSignupMethod = ({isNewRecord, user, googleSignupMethod}) => {
  return handleTrackSignup({
    isNewRecord,
    user,
    signupMeans: googleSignupMethod,
  });
};

const withGoogleYoloLoginMutation = graphql(GOOGLE_YOLO_LOGIN_MUTATION, {
  name: 'googleYoloLoginMutation',
});

const withData = compose(
  withRouter,
  withGoogleYoloLoginMutation,
  withCurrentUser,
  withAuthReducer,
  withProps(
    ({
      startAuth,
      endAuth,
      googleYoloLoginMutation
    }) => ({
      handleGoogleYoloLogin: async ({ googleYoloToken }, googleSignupMethod) => {
        startAuth();
        try {
          const { data } = await googleYoloLoginMutation({
            variables: { token: googleYoloToken },
          });
          const { isNewRecord, user, redirect } = data.login;

          handleTrackGoogleSignupMethod({isNewRecord, user, googleSignupMethod});
          
          window.localStorage.setItem('loginType', 'google');

          if (redirect) {
            window.location.href = redirect;

            return;
          }
          
          endAuth();
        } catch (error) {
          endAuth();
        }
      },
    })
  )
);

export default withData;
