import React from 'react';
import PropTypes from 'prop-types';
import { Flow, Container } from '@matterapp/matter-ui';
import { propType } from 'graphql-anywhere';
import { INVITATION_FROM_UUID_QUERY } from 'graphql-queries/queries';

const CantRateSelfPage = ({ invitationData, handleReturnToMatter }) => {
  if (invitationData.loading || invitationData.error) { return null; }
  const { owner } = invitationData.invitation;
  return (
    <Flow.Page renderHeader={() => <Flow.Header />}>
      <Flow.Card renderFlash={() => <Flow.ProfileBlock user={owner} />}>
        <Container textAlign="center" marginBottom={48}>
          <Flow.TextBlock heading="Whoa there!">
            You can’t rate yourself on Matter. You can return to Matter and ask
            a few of your trusted peers.
          </Flow.TextBlock>
          <Flow.PrimaryButton onClick={handleReturnToMatter}>
            Return to Matter
          </Flow.PrimaryButton>
        </Container>
      </Flow.Card>
    </Flow.Page>
  );
};

CantRateSelfPage.propTypes = {
  invitationData: propType(INVITATION_FROM_UUID_QUERY).isRequired,
  handleReturnToMatter: PropTypes.func.isRequired,
};

export default CantRateSelfPage;
