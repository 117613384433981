import React from 'react';
import styled from 'styled-components';
import { IconNew as Icon } from '../Icon';
import { iconLabels } from './consts';

export const AddButtonIcon = styled(Icon).attrs({ name: 'addv2' })``;
export const KudosIcon = styled(Icon).attrs({ name: 'clap' })``;
export const GiveFeedbackIcon = styled(Icon).attrs({ name: 'giveFeedback' })``;
export const RequestFeedbackIcon = styled(Icon).attrs({ name: 'requestFeedback' })``;
export const ViewDetailsIcon = styled(Icon).attrs({ name: 'eyev2' })``;
export const RecurringIcon = styled(Icon).attrs({ name: 'recurring' })``;
export const EditIcon = styled(Icon).attrs({ name: 'pencil'})``;

export const editRecurring = {
  icon: <EditIcon />,
  tooltipContent: iconLabels.EDIT,
};

export const menuIconMap = [
  {
    icon: <RequestFeedbackIcon />,
    tooltipContent: iconLabels.REQUEST,
  },
  {
    icon: <RecurringIcon />,
    tooltipContent: iconLabels.RECURRING,
  },
  {
    icon: <GiveFeedbackIcon />,
    tooltipContent: iconLabels.GIVE,
  },
  {
    icon: <KudosIcon />,
    tooltipContent: iconLabels.KUDOS,
  },
  {
    icon: <ViewDetailsIcon />,
    tooltipContent: iconLabels.INFO,
  },
];

export const infoModalIconsMap = menuIconMap.slice(0, 4);