import { graphql } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';
import apolloClient from 'config/apolloClient';
import withAuthReducer from './withAuthReducer';
import { handleTrackSignup } from 'libs/tracking/signup';
import {
  CURRENT_USER_STATUS_QUERY,
  MAGIC_LINK_TOKEN_LOGIN_MUTATION,
} from 'graphql-queries/queries';
import { SIGNUP_MEANS } from 'app-consts';

const withMagicLinkLoginMutation = graphql(MAGIC_LINK_TOKEN_LOGIN_MUTATION, {
  name: 'magicLinkTokenLoginMutation',
});

const withData = compose(
  withMagicLinkLoginMutation,
  withAuthReducer,
  withProps(
    ({
      startAuth,
      endAuth,
      magicLinkTokenLoginMutation,
    }) => ({
      handleMagicLinkLogin: async ({
        magicLinkToken,
        handleSuccess,
        handleFailure,
      }) => {
        startAuth();
        try {
          const { data } = await magicLinkTokenLoginMutation({
            variables: { token: magicLinkToken },
          });
          const { isNewRecord, user, redirectToWorkspaceId } = data.login;

          handleTrackSignup({
            isNewRecord,
            user,
            signupMeans: SIGNUP_MEANS.MANUAL_EMAIL_SIGNUP,
          });
          await apolloClient.query({
            query: CURRENT_USER_STATUS_QUERY,
            fetchPolicy: 'network-only',
          });
          await handleSuccess(redirectToWorkspaceId);
          endAuth();
        } catch (error) {
          endAuth();
          handleFailure();
        }
      },
    })
  )
);

export default withData;
