import camelCase from 'lodash/camelCase';
import * as svgIcons from './svg';
import * as invertedSvgIcons from './inverted-svg';

import googleCalendar from '../../../assets/imgs/services/google_calendar.png';
import googleContacts from '../../../assets/imgs/services/google_contacts.png';
import googleGmail from '../../../assets/imgs/services/google_gmail.png';
import linkedIn from '../../../assets/imgs/services/linkedIn.png';
import twitter from '../../../assets/imgs/services/twitter.png';
import calendarPeers from './pngs/calendar_peers.png';
import peerSuggestions from './pngs/peer_suggestions.png';
import rater from './pngs/rater.png';

const pngIcons = {
  googleCalendar,
  googleContacts,
  googleGmail,
  linkedIn,
  twitter,
  calendarPeers,
  peerSuggestions,
  rater,
};

const adjustedSvgIcons = {};
const adjustedInvertedSvgIcons = {};

const filterIconName = (iconName) => {
  return iconName.replace('Icon', '');
};

Object.keys(svgIcons).forEach((iconName) => {
  const filteredIconName = filterIconName(iconName);
  const finalIconName = camelCase(filteredIconName);
  adjustedSvgIcons[finalIconName] = svgIcons[iconName];
});

Object.keys(invertedSvgIcons).forEach((iconName) => {
  const filteredIconName = filterIconName(iconName);
  const finalIconName = camelCase(filteredIconName);
  adjustedInvertedSvgIcons[finalIconName] = invertedSvgIcons[iconName];
});

export const svgIconNames = Object.keys(adjustedSvgIcons);

export const getSvgIcon = (name) => {
  const adjustedName = camelCase(name);
  return adjustedSvgIcons[adjustedName] || null;
};

export const getInvertedSvgIcon = (name) => {
  const adjustedName = camelCase(name);
  return adjustedInvertedSvgIcons[adjustedName] || null;
};

export const getPngIcon = (name) => pngIcons[name] || null;

export const iconList = {
  ...pngIcons,
  ...adjustedSvgIcons,
  ...adjustedInvertedSvgIcons,
};
