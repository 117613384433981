import { colors } from '@matterapp/matter-theme';
import Toast from 'components/Toast';

export const IMAGE_FILE_UPLOAD_ERROR_DETAILS = {
  maxSize: 2000000,
  minSize: 2000,
  onMaxFileSizeNotMet: () => Toast.error('File size exceeds max of 2MB, please try again'),
  onMinFileSizeNotMet: () => Toast.error('File size needs to be at least 2KB, please try again'),
  onFileTypeNotMet: () => Toast.error('File type not supported, please try again'),
};

export const colorsToShow = [
  {
    backgroundName: 'purple30',
    backgroundColor: `${colors.purples[30]}`,
  },
  {
    backgroundName: 'blue30',
    backgroundColor: `${colors.blues[30]}`,
  },
  {
    backgroundName: 'red30',
    backgroundColor: `${colors.reds[30]}`,
  },
  {
    backgroundName: 'orange30',
    backgroundColor: `${colors.oranges[30]}`,
  },
  {
    backgroundName: 'green30',
    backgroundColor: `${colors.greens[30]}`,
  },
  {
    backgroundName: 'black30',
    backgroundColor: `${colors.blacks[30]}`,
  },
  {
    backgroundName: 'purple10',
    backgroundColor: `${colors.purples[10]}`,
    isLight: true,
  },
  {
    backgroundName: 'blue10',
    backgroundColor: `${colors.blues[10]}`,
    isLight: true,
  },
  {
    backgroundName: 'red10',
    backgroundColor: `${colors.reds[10]}`,
    isLight: true,
  },
  {
    backgroundName: 'orange10',
    backgroundColor: `${colors.oranges[10]}`,
    isLight: true,
  },
  {
    backgroundName: 'yellow10',
    backgroundColor: `${colors.yellows[10]}`,
    isLight: true,
  },
  {
    backgroundName: 'green10',
    backgroundColor: `${colors.greens[10]}`,
    isLight: true,
  },
  {
    backgroundName: 'black10',
    backgroundColor: `${colors.blacks[10]}`,
    isLight: true,
  },
  // add these back when we can support patterns alongside image kudos image in slack
  // {
  //   backgroundName: 'purplePattern',
  //   backgroundImage: PurplePatternKudos,
  // },
  // {
  //   backgroundName: 'bluePattern',
  //   backgroundImage: BluePatternKudos,
  // },
  // {
  //   backgroundName: 'redPattern',
  //   backgroundImage: RedPatternKudos,
  // },
  // {
  //   backgroundName: 'orangePattern',
  //   backgroundImage: OrangePatternKudos,
  // },
  // {
  //   backgroundName: 'greenPattern',
  //   backgroundImage: GreenPatternKudos,
  // },
  // {
  //   backgroundName: 'tealPattern',
  //   backgroundImage: TealPatternKudos,
  // },
  // {
  //   backgroundName: 'squigglePattern',
  //   backgroundImage: SquigglePatternKudos,
  // },
];