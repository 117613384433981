import React from 'react';

const Icon = () => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M16.9001 9.70454H20.0015C19.9072 7.26013 18.9518 5.03486 17.4321 3.33395L15.2371 5.55139C16.1977 6.68099 16.811 8.12233 16.9001 9.70454Z"
        fill="#95E9D8"
      />
      <path
        d="M9.36064 3.1513V0.0129576C6.97588 0.16557 4.81707 1.16473 3.17213 2.71694L5.36323 4.93048C6.44134 3.94175 7.82933 3.29348 9.36064 3.1513Z"
        fill="#F58565"
      />
      <path
        d="M14.5049 4.81447L16.6999 2.59702C15.0149 1.06046 12.8135 0.0952198 10.3939 0V3.13312C11.9601 3.22182 13.3868 3.8414 14.5049 4.81447Z"
        fill="#FFCE7E"
      />
      <path
        d="M3.12048 10.7482H0.0139618C0.161154 13.0948 1.10241 15.2262 2.57174 16.871L4.76671 14.6535C3.85386 13.5839 3.25605 12.2326 3.12048 10.7482Z"
        fill="#EB2E49"
      />
      <path
        d="M4.65205 5.68572L2.45579 3.46696C1.00195 5.15092 0.0916873 7.32272 1.52588e-05 9.70452H3.10137C3.1853 8.18491 3.7534 6.79444 4.65205 5.68572Z"
        fill="#FF4369"
      />
      <path
        d="M16.8803 10.7482C16.7396 12.2939 16.0979 13.6961 15.1205 14.7866L17.3116 17.0001C18.848 15.3396 19.8371 13.1587 19.9881 10.7495H16.8803V10.7482Z"
        fill="#21CBA3"
      />
    </g>
    <path
      d="M14.5 10C14.5 12.4853 12.4853 14.5 9.99999 14.5C7.5147 14.5 5.49998 12.4853 5.49998 10C5.49998 7.51472 7.5147 5.5 9.99999 5.5C12.4853 5.5 14.5 7.51472 14.5 10Z"
      fill="#4584FF"
      stroke="#25277A"
    />
    <path
      d="M10.9643 7.5L12.0778 9.42857H9.85084L10.9643 7.5Z"
      fill="#fff"
    />
    <circle cx="8.714" cy="11.036" r="0.964" fill="#fff" />
    <rect x="10.321" y="10.071" width="1.929" height="1.929" fill="#25277A" />
    <path
      d="M9.67374 8.23009C9.66224 8.19458 9.63417 8.17348 9.59583 8.17112C9.42164 8.16021 9.24745 8.14895 9.0732 8.13833C9.02924 8.13567 9.00056 8.11501 8.98426 8.07081C8.92305 7.90479 8.86047 7.73927 8.79782 7.57376C8.79166 7.5574 8.78419 7.53982 8.77269 7.52769C8.7553 7.50933 8.73462 7.50043 8.71429 7.5C8.69395 7.50043 8.67327 7.50933 8.65588 7.52769C8.64438 7.53982 8.63698 7.5574 8.63075 7.57376C8.5681 7.7392 8.50559 7.90472 8.44431 8.07081C8.42802 8.11501 8.39933 8.13567 8.35537 8.13833C8.18112 8.14895 8.00693 8.16021 7.83275 8.17112C7.79447 8.17348 7.76633 8.19458 7.75483 8.23009C7.74305 8.26647 7.75298 8.30198 7.78283 8.32802C7.81926 8.35974 7.85589 8.39116 7.89252 8.42259C7.99221 8.50811 8.09218 8.59341 8.19173 8.67915C8.22405 8.70699 8.23336 8.74214 8.22247 8.78512C8.17838 8.95932 8.13497 9.13374 8.09231 9.30829C8.08793 9.32616 8.08588 9.34711 8.09046 9.3644C8.10697 9.42567 8.16797 9.44677 8.22062 9.41147C8.3666 9.31353 8.51278 9.21589 8.65835 9.11723C8.67752 9.10425 8.69587 9.09786 8.71429 9.09829C8.7327 9.09786 8.75105 9.10425 8.77023 9.11723C8.91579 9.21589 9.0619 9.31353 9.20795 9.41147C9.2606 9.44677 9.32161 9.42567 9.33811 9.3644C9.34276 9.34718 9.34064 9.32616 9.33626 9.30829C9.2936 9.13374 9.25013 8.95932 9.2061 8.78512C9.19521 8.74214 9.20452 8.70699 9.23684 8.67915C9.3364 8.59341 9.43629 8.50811 9.53605 8.42259C9.57268 8.39116 9.60931 8.35974 9.64574 8.32802C9.67559 8.30205 9.68552 8.26647 9.67374 8.23009Z"
      fill="#25277A"
    />
  </svg>
);


export default Icon;
