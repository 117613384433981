import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import PropTypes from 'prop-types';
import {
  FileUpload,
  Clickable,
  Avatar,
  AvatarCard,
  IconNew as Icon,
} from '@matterapp/matter-ui';

const StyledPane = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.colors.blacks[5]};
  border: ${({ showBackgroundColor }) => !showBackgroundColor ?  `2px dashed ${theme.colors.blacks[50]}` : 'none' };
  &:active, &:hover {
    color: ${theme.colors.blues[60]};
    border: ${({ showBackgroundColor }) => !showBackgroundColor ?  `2px dashed ${theme.colors.blues[50]}` : 'none' };
    background: ${({ showBackgroundColor }) => showBackgroundColor ?  `${theme.colors.blacks[5]}` : `${theme.colors.blues[5]}`};
    & svg {
      path {
        fill: ${theme.colors.blues[50]};
      }
    }
    & div {
      color: ${theme.colors.blues[50]};
    }
  }
`;

const StyledImageUpload = styled(FileUpload.ImageWithCrop)`
`;

const ChangeButtonWithText = styled(Clickable)`
  font-size: ${theme.fontSize.XS};
  line-height: ${theme.lineHeight.XS};
  color: ${theme.colors.blues[60]};
  cursor: pointer;
`;

const ButtonSpacer = styled.div`
  background-color: ${theme.colors.blacks[40]};
  margin: 0 ${theme.spacing.half};
  min-height: ${theme.lineHeight.XS};
  height: 100%;
  width: 1px;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 14px;
`;

const StyledContainer = styled.div`
  min-height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  font-size: ${theme.fontSize.S2};
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[50]};
  text-transform: uppercase;
`;

const SubHeader = styled.div`
  font-size: ${theme.fontSize.XS};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[40]};
  ${Header} + & {
    margin-top: ${theme.spacing.quarter};
  }
`;

const SpinnerIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes spin { 100% { transform:rotate(360deg) } }
  margin-bottom: ${theme.spacing.half};
  svg {
    display: block;
    height: ${theme.spacing.doubleAndHalf};
    width: ${theme.spacing.doubleAndHalf};
    animation: spin 4s linear infinite;
    path {
      fill: ${theme.colors.blacks[40]};
    }
  }
`;

const IconSpinner = styled(Icon).attrs(() => ({
  name: 'spinner',
}))``;

const DEFAULT_UPLOAD_IMAGE_SUBHEADER = "Square 512x512px images with a transparent background work best.";

const ImageUpload = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: props.url,
      isFileAccepting: false,
    };
  }

  onFileAccepted = async (file) => {
    this.setState({ isFileAccepting: true });
    const url = await this.props.onSaveIcon(this.props, file);
    this.setState({ url, isFileAccepting: false });
  }

  removeIcon = async () => {
    await this.props.onRemoveIcon(this.props);
    this.setState({ url: null });
  }

  renderUpload = () => {
    if (this.state.isFileAccepting) {
      return (
        <StyledContainer>
          <SpinnerIconContainer> <IconSpinner size={48} /> </SpinnerIconContainer>
          <Header> UPLOADING IMAGE </Header>
          {!this.props.aspectRatio && <SubHeader>Max of 512x512px.</SubHeader>}
        </StyledContainer>
      );
    }

    const { defaultAvatarName, aspectRatio, iconWidth, iconHeight, uploadImageSubHeader } = this.props;
    let url = this.props.url || this.state.url;
    const urlExists = !!url;
    url = url || (defaultAvatarName ? AvatarCard.getIconUrl(defaultAvatarName) : null);

    return (
      <>
        {urlExists &&
          <StyledContainer>
            <Avatar
              size={88}
              width={iconWidth}
              height={iconHeight}
              url={url}
              squaredWithoutBorder
            />
            <ButtonContainer>
              <StyledImageUpload
                showIcon={false}
                onFileAccepted={this.onFileAccepted}
                multiple={true}
                {...this.props}
              />
              <ButtonSpacer />
              <ChangeButtonWithText onClick={this.removeIcon}>REMOVE</ChangeButtonWithText>
            </ButtonContainer>
          </StyledContainer>
        }
        {!urlExists &&
          <StyledImageUpload
            onFileAccepted={this.onFileAccepted}
            aspectRatio={aspectRatio}
            uploadImageHeader="UPLOAD IMAGE"
            uploadImageSubHeader={uploadImageSubHeader || DEFAULT_UPLOAD_IMAGE_SUBHEADER}
            {...this.props}
          />
        }
      </>
    );


  }
  render() {
    const url = this.props.url || this.state.url;
    const urlExists = !!url;
    return (
      <StyledPane showBackgroundColor={urlExists}>
        {this.renderUpload()}
      </StyledPane>
    );
  }
};

ImageUpload.defaultProps = {
  changePhotoText: "CHANGE PHOTO",
  changePhotoHeader: "Change Photo",
};

ImageUpload.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  onChange: PropTypes.func,
  onSaveIcon: PropTypes.func,
  onRemoveIcon: PropTypes.func,
  url: PropTypes.string,
  urls: PropTypes.arrayOf(PropTypes.string),
  changePhotoText: PropTypes.string,
  changePhotoHeader: PropTypes.string,
  defaultAvatarName: PropTypes.string,
  aspectRatio: PropTypes.number,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  uploadImageSubHeader: PropTypes.string,
};

export default ImageUpload;
