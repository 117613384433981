import styled from 'styled-components';

import Container from './Container';

const TextContainer = styled(Container).attrs({
  maxWidth: 336,
  sidePadding: 24,
  sidePaddingSmall: 16,
})`
  text-align: center;
  font-size: 17px;
  line-height: 1.6;
`;

export default TextContainer;
