import React from 'react';
import PropTypes from 'prop-types';
import { Animations, getSkillColorPaletteFromSkill } from '@matterapp/matter-ui';
import {
  Container,
  BackgroundContainer,
  CurrentBackground,
  LastBackground,
} from './styles';

const ANIMATIONS = Animations.Animation.ANIMATIONS;

export default class Background extends React.PureComponent {
  static propTypes = {
    currentStep: PropTypes.number,
    currentSkill: PropTypes.shape({
      skill: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    numberOfSkills: PropTypes.number,
    playAnimationOnMount: PropTypes.bool,
    qualitiesStepNumber: PropTypes.number,
    totalSteps: PropTypes.number,
  };

  static defaultProps = {
    playAnimationOnMount: true,
    currentSkill: { skill: {} },
  };

  constructor(props) {
    super(props);
    this.state = {
      lastColor: '',
      lastStep: 0,
      isGoToNextStep: true,
      isTransitionRunning: false,
    };
    this.transitionTimeout = null;
  }

  componentDidMount = () => {
    this._mounted = true;
  };

  componentWillUnmount = () => {
    this._mounted = false;
    clearTimeout(this.transitionTimeout);
  };

  /**
   * Clears the flag that the transition is running.
   * @returns { void }
   */
  clearTransition = () => {
    if (this._mounted) {
      this.setState({ isTransitionRunning: false });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { currentSkill: lastSkill, currentStep: lastStep } = prevProps;
    const { currentStep } = this.props;
    if (currentStep !== lastStep && !this.isLastStep()) {
      this.setState({
        lastColor: this.isSkillStep(lastStep)
          ? this.getBackgroundColor(lastSkill)
          : '',
        isGoToNextStep: currentStep > lastStep,
        isTransitionRunning: true,
        lastStep,
      });
      this.transitionTimeout = setTimeout(this.clearTransition, 20);
    }
  };

  /**
   * Determines if given step is a skill step.
   * @param { Number } stepNumber: The number of the step to check.
   * @returns { Boolean } If step is a skill step.
   */
  isSkillStep = (stepNumber) => {
    const { qualitiesStepNumber } = this.props;
    return stepNumber > 1 && stepNumber < qualitiesStepNumber;
  };

  /**
   * Determines if current step is the last step;
   * @returns { Boolean } If current step is last step.
   */
  isLastStep = () => {
    const { currentStep, qualitiesStepNumber, totalSteps } = this.props;
    if (qualitiesStepNumber === totalSteps) {
      return currentStep > totalSteps + 1;
    }
    return currentStep > totalSteps;
  };

  /**
   * Gets the background of the given skill.
   * @param { Object } currentSkill: THe skill to get the color of.
   * @returns { String } The color of the skill.
   */
  getBackgroundColor = (currentSkill) => {
    const { skill } = currentSkill || {};
    return skill && skill.name
      ? getSkillColorPaletteFromSkill(skill).toLowerCase()
      : 'blue';
  };

  /**
   * Get the background animation for the skill.
   * @param { Object } backgroundProps: Props from the instance or overriden props.
   * @param { String } animation: The name of the animation transition to run.
   * @returns { React.Component } The skill background.
   */
  getSkillBackground = (backgroundProps, animation) => {
    const { currentStep, colorOverride } = backgroundProps;
    const { currentSkill } = this.props;
    const color = colorOverride || this.getBackgroundColor(currentSkill);
    const backgroundIndex =
      (currentStep - 2) %
      Animations.ResponseFlow.NonRecurring.variantBackgroundList.length;
    const Background =
      Animations.ResponseFlow.NonRecurring.variantBackgroundList[backgroundIndex];
    return <Background animation={animation} color={color} />;
  };

  /**
   * Gets the corresponding background fro the current step.
   * @param { Object } backgroundProps: {
   *   @param { Number } currentStep: The number of the current step.
   *   @param { Boolean } isLastStep: If this background was the last step to be shown.
   *   @param { Number } totalSteps: The total number of steps in the timeline.
   * }
   * @returns { React.Component } The rendered background.
   */
  getBackground = (backgroundProps) => {
    const { currentStep, isLastStep, totalSteps } =
      backgroundProps || this.props;
    const animation = isLastStep ? ANIMATIONS.OUT : ANIMATIONS.IN;
    const animationProps = { animation };
    const BackgroundBase = Animations.ResponseFlow.NonRecurring;

    if (currentStep === 1) {
      return <BackgroundBase.Start {...animationProps} />;
    }
    if (currentStep === this.props.qualitiesStepNumber) {
      return <BackgroundBase.SecondToLast {...animationProps} />;
    }
    if (currentStep >= totalSteps) {
      return (
        <BackgroundBase.End {...animationProps} colorizeBackground={false} />
      );
    }
    return this.getSkillBackground(backgroundProps || this.props, animation);
  };

  getCurrentBackground = () => {
    return this.getBackground(this.props);
  };

  getLastBackground = () => {
    const { lastColor, lastStep } = this.state;
    return lastStep
      ? this.getBackground({
        currentStep: lastStep,
        colorOverride: lastColor,
        isLastStep: true,
        totalSteps: this.props.totalSteps,
      })
      : null;
  };

  render() {
    const { isGoToNextStep, isTransitionRunning } = this.state;
    return (
      <Container {...this.props}>
        <BackgroundContainer>
          <LastBackground
            isGoToNextStep={isGoToNextStep}
            isTransitionRunning={isTransitionRunning}
          >
            {this.getLastBackground()}
          </LastBackground>
          <CurrentBackground
            isGoToNextStep={isGoToNextStep}
            isTransitionRunning={isTransitionRunning}
          >
            {this.getCurrentBackground()}
          </CurrentBackground>
        </BackgroundContainer>
      </Container>
    );
  }
}
