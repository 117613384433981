import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { colors, spacing, media, font, } from '@matterapp/matter-theme';
import { WordMark } from '../Brand';
import Panel from '../Panel';
import Background from './Background';

const WORDMARK_CLASSNAME = 'auth-wordmark';

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: 100%;
`;

const AuthContainer = styled(Panel.Base)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.borderRadius.L};
  box-shadow: none;
  margin-bottom: ${spacing.doubleAndHalf};
  width: 100%;
  padding: ${spacing.double};
  background: rgba(255, 255, 255, 0.5);
  ${media.XS`
    width: 80%;
  `}
  ${media.S`
    width: 480px;
    padding: ${spacing.triple};
    box-shadow: 0px 32px 56px -24px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
  `}
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-family: CircularXX;
  font-weight: ${font.weight.bold};
  letter-spacing: -0.02em;
  font-size: 40px;
  line-height: 44px;
  margin-bottom: ${spacing.threeQuarters};
  color: ${colors.blacks[90]};
  br.mobile-break {
  }
  // Allows specifying mobile-only wrapping with toggling <br/>
  @media screen and (min-width: ${theme.breakpoints[3]}) {
    br.mobile-break {
      display: none;
    }
  }
  ${media.S`
    font-size: 48px;
    line-height: 52px;
  `}
`;

const Subtitle = styled.div`
  font-size: ${font.size.S2};
  line-height: ${theme.lineHeight.M};
  font-weight: ${font.weight.medium};
  margin-bottom: ${spacing.singleAndThreeQuarters};
  text-align: center;
  color: ${colors.blacks[60]};
  &:empty {
    display: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${font.size.S2};
  line-height: ${theme.lineHeight.M};
  color: ${colors.blacks[60]};
  margin-bottom: ${spacing.doubleAndHalf};
  width: 100%;
`;

const AuthFooterDivider = styled.div`
  border-bottom: 2px solid ${colors.blacks[10]};
  width: 100%;
  margin-bottom: ${spacing.singleAndHalf};
`;

const AuthContentFooterContainer = styled.div`
  font-size: ${font.size.base};
  line-height: 21px;
  font-weight: ${font.weight.semiBold};
  color: ${colors.blacks[60]};
  text-align: center;
`;

const WordMarkLogo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .${WORDMARK_CLASSNAME} {
    padding: 0 !important;
    margin-top: ${spacing.double};
    margin-bottom: 0 !important;
  }
  ${media.S`
    .${WORDMARK_CLASSNAME} {
      height: 50px !important;
      margin-bottom: 60px !important;
    }
  `}
  ${media.L`
    .${WORDMARK_CLASSNAME} {
      margin-bottom: 76px !important;
    }
  `}
`;

const WordMarkLink = styled.a`
  display: flex;
  align-items: center;
  .${WORDMARK_CLASSNAME} {
    padding: 0 !important;
    margin-top: ${spacing.double};
    margin-bottom: 0 !important;
  }
  ${media.S`
    .${WORDMARK_CLASSNAME} {
      height: 50px !important;
      margin-bottom: 60px !important;
    }
  `}
  ${media.L`
    .${WORDMARK_CLASSNAME} {
      margin-bottom: 76px !important;
    }
  `}
`;


const AuthBase = (props) => {
  const {
    size,
    title,
    subtitle,
    content,
    isConfirmPage,
    landingPagePath,
    onClickMatterLogo,
    authContentFooterText,
    authContentFooterLink,
    authContentFooterContent
  } = props;

  return (
    <PageContent className={'AuthBase'}>
      <Background isConfirmPage={isConfirmPage} />
      {!onClickMatterLogo &&
        <WordMarkLink href={landingPagePath} title="Matter">
          <WordMark className={WORDMARK_CLASSNAME} size="large" />
        </WordMarkLink>
      }
      {onClickMatterLogo &&
        <WordMarkLogo onClick={onClickMatterLogo}>
          <WordMark className={WORDMARK_CLASSNAME} size="large" />
        </WordMarkLogo>
      }
      <AuthContainer size={size} className={'AuthContainer'}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <ContentContainer>{content}</ContentContainer>
        <AuthFooterDivider />
        <AuthContentFooterContainer>
          {authContentFooterText}
          {authContentFooterLink}
          {authContentFooterContent}
        </AuthContentFooterContainer>
      </AuthContainer>
    </PageContent>
  );
};

AuthBase.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.node.isRequired,
  authContentFooterText: PropTypes.string,
  authContentFooterLink: PropTypes.node,
  authContentFooterContent: PropTypes.node,
  formField: PropTypes.bool,
  handleGoBack: PropTypes.func,
  landingPagePath: PropTypes.string,
  onClickMatterLogo: PropTypes.func,
};

export default AuthBase;
