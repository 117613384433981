import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="icon-spinner">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.55978 0C10.1121 0 10.5598 0.447715 10.5598 1V3C10.5598 3.55228 10.1121 4 9.55978 4C9.0075 4 8.55978 3.55228 8.55978 3V1C8.55978 0.447715 9.0075 0 9.55978 0Z"
      transform="translate(2.44 2)"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.55978 16C10.1121 16 10.5598 16.4477 10.5598 17V19C10.5598 19.5523 10.1121 20 9.55978 20C9.0075 20 8.55978 19.5523 8.55978 19V17C8.55978 16.4477 9.0075 16 9.55978 16Z"
      transform="translate(2.44 2)"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.68193 1.90985C4.12873 1.58522 4.7541 1.68427 5.07873 2.13108L6.2543 3.74912C6.57892 4.19592 6.47988 4.82129 6.03307 5.14592C5.58626 5.47054 4.96089 5.37149 4.63627 4.92469L3.46069 3.30665C3.13607 2.85984 3.23512 2.23447 3.68193 1.90985Z"
      transform="translate(2.44 2)"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0865 14.8541C13.5333 14.5295 14.1587 14.6285 14.4833 15.0754L15.6589 16.6934C15.9835 17.1402 15.8844 17.7656 15.4376 18.0902C14.9908 18.4148 14.3655 18.3158 14.0408 17.869L12.8653 16.2509C12.5406 15.8041 12.6397 15.1787 13.0865 14.8541Z"
      transform="translate(2.44 2)"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0492207 6.90984C0.219886 6.38459 0.78404 6.09714 1.30929 6.26781L3.21141 6.88584C3.73666 7.0565 4.02411 7.62066 3.85345 8.14591C3.68278 8.67117 3.11863 8.95862 2.59337 8.78795L0.69126 8.16992C0.166006 7.99925 -0.121445 7.4351 0.0492207 6.90984Z"
      transform="translate(2.44 2)"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2661 11.8541C15.4368 11.3289 16.0009 11.0414 16.5262 11.2121L18.4283 11.8301C18.9536 12.0008 19.241 12.5649 19.0704 13.0902C18.8997 13.6154 18.3355 13.9029 17.8103 13.7322L15.9082 13.1142C15.3829 12.9435 15.0955 12.3794 15.2661 11.8541Z"
      transform="translate(2.44 2)"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0492135 13.0902C-0.121452 12.5649 0.165999 12.0008 0.691253 11.8301L2.59337 11.2121C3.11862 11.0414 3.68277 11.3289 3.85344 11.8541C4.0241 12.3794 3.73665 12.9435 3.2114 13.1142L1.30929 13.7322C0.784033 13.9029 0.219879 13.6154 0.0492135 13.0902Z"
      transform="translate(2.44 2)"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2661 8.1459C15.0955 7.62064 15.3829 7.05649 15.9082 6.88582L17.8103 6.26779C18.3355 6.09713 18.8997 6.38458 19.0703 6.90983C19.241 7.43508 18.9536 7.99924 18.4283 8.1699L16.5262 8.78794C16.0009 8.9586 15.4368 8.67115 15.2661 8.1459Z"
      transform="translate(2.44 2)"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.68192 18.0902C3.23511 17.7656 3.13606 17.1402 3.46069 16.6934L4.63626 15.0753C4.96088 14.6285 5.58625 14.5295 6.03306 14.8541C6.47987 15.1787 6.57892 15.8041 6.25429 16.2509L5.07872 17.8689C4.7541 18.3158 4.12873 18.4148 3.68192 18.0902Z"
      transform="translate(2.44 2)"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0865 5.1459C12.6397 4.82128 12.5406 4.19591 12.8652 3.7491L14.0408 2.13107C14.3654 1.68426 14.9908 1.58521 15.4376 1.90984C15.8844 2.23446 15.9835 2.85983 15.6588 3.30664L14.4833 4.92467C14.1587 5.37148 13.5333 5.47053 13.0865 5.1459Z"
      transform="translate(2.44 2)"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'white',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
