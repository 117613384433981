import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -24px;
  margin-top: auto;
  border-top: 1px solid ${() => theme.colors.blacks[10]};
  padding: 0 20px;
  height: 73px;
`;

const Footer = ({
  className,
  left,
  center,
  right,
}) => (
  <Container className={className} data-role="footer">
    <div>{left}</div>
    <div>{center}</div>
    <div>{right}</div>
  </Container>
);

Footer.defaultProps = {
  left: null,
  center: null,
  right: null,
};

Footer.propTypes = {
  className: PropTypes.string,
  left: PropTypes.node,
  center: PropTypes.node,
  right: PropTypes.node,
};

export default Footer;
