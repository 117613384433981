import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z"
      fill="none"
    />
    <path 
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12C6 8.68386 8.68385 6 12 6C15.3161 6 18 8.68386 18 12C18 15.3161 15.3161 18 12 18C8.68385 18 6 15.3161 6 12ZM12 7C9.23614 7 7 9.23614 7 12C7 14.7639 9.23614 17 12 17C14.7639 17 17 14.7639 17 12C17 9.23614 14.7639 7 12 7ZM11.25 9.02002L11.41 12.69H12.6L12.77 9.02002H11.25ZM11.77 13.79H12.24C12.47 13.79 12.65 13.98 12.65 14.2V14.37C12.65 14.6 12.47 14.78 12.24 14.78H11.77C11.55 14.78 11.36 14.6 11.36 14.37V14.2C11.36 13.98 11.54 13.79 11.77 13.79Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;