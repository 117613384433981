import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import map from 'lodash/map';
import theme from '@matterapp/matter-theme';

const ProfileMenuContainer = styled.div`
  font-weight: normal;
  padding: 0 24px 16px 24px;
`;

const SectionHeading = styled.h3`
  font-size: ${() => theme.sizes.fontSizes[50]};
  font-weight: bold;
  margin: 0 0 4px;
  line-height: 48px;
  color: ${() => theme.colors.purples[50]};
`;

const Section = styled.section`
  &:last-child {
    margin-bottom: 0;
  }
`;

const Link = styled.li``;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ${Link} {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    > * {
      font-size: ${() => theme.sizes.fontSizes[50]} !important;
    }
  }
`;

const ProfileMenu = ({ className, links, showHeader }) => (
  <ProfileMenuContainer className={className}>
    <Section>
      {showHeader && <SectionHeading>Personal Settings</SectionHeading>}
      <List>
        {map(links, (link, i) => (
          <Link key={i}>{link}</Link>
        ))}
      </List>
    </Section>
  </ProfileMenuContainer>
);

ProfileMenu.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.node).isRequired,
  showHeader: PropTypes.bool,
};

ProfileMenu.defaultProps = {
  showHeader: true,
};

ProfileMenu.List = List;
ProfileMenu.Link = Link;

export default ProfileMenu;
