import styled from 'styled-components';
import omitStyled from '../../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

export const maxNoteHeight = '122px';

export const RatingsContainer = styled.div`
  display: flex;
  margin-bottom: 0;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const RatingContainer = omitStyled('div', ['width'])`
  display: flex;
  width: ${({ width = 1 }) => width * 100}%;
`;

export const RatingFlexContainer = styled.div`
  display: flex;
  order: ${({ order }) => order};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  flex: 1 1 ${({ width = 1 }) => width}%;
  max-width: ${({ width = 1 }) => width}%;
  margin: 0px 2px;
  word-break: break-word;
  p:first-child {
    margin-bottom: 0em;
  }
  ${({ order }) => order === 0 ? `
    &, & > ${RatingItemContainer} {
      margin-left: 0;
    }
  ` : `
    &, & > ${RatingItemContainer} {
      margin-right: 0;
    }
  `}
`;

export const RatingItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 4px 8px 4px;
  background: #ffffff;
  border: 1px solid ${theme.colors.blacks[10]};
  box-sizing: border-box;
  border-radius: 12px;
  min-height: 152px;
  text-align: center;
  padding: ${theme.spacing.single} ${theme.spacing.half};

  ${theme.media.S`
  padding: ${theme.spacing.single} ${theme.spacing.threeQuarters};
`}
`;
