import React from 'react';
import Animation from '../../Animation';
import Image from './Image';
import * as C from './classNames';

export default class BobaCorgi extends Animation {
  baseClass = C.BASE;

  moveCorgi = () => {
    const tl = this.getDefaultTimeline({ duration: 0.5 });
    const CORGI = this.getElement(C.CORGI.GROUP);

    for (let i = 0; i < 6; i++) {
      tl.to(CORGI, { y: 6 }, '>');
      tl.to(CORGI, { y: 0 }, '>');
    }
    return tl;
  };

  moveCup = () => {
    const tl = this.getDefaultTimeline({ duration: 0.6 });
    const CUP = this.getElement(C.CUP);

    tl.to(CUP, { y: -58, scale: 0.75 }, '<');
    tl.to(CUP, { y: -0, scale: 1 }, '>5.4');
    return tl;
  };

  moveStraw = () => {
    const tl = this.getDefaultTimeline({ duration: 0.8 });
    const STRAW = this.getElement(C.STRAW);

    for (let i = 0; i < 4; i++) {
      tl.to(STRAW, { x: 20, rotate: 20 }, '>');
      tl.to(STRAW, { x: 0, rotate: 0 }, '>');
    }

    return tl;
  };

  moveBoba = () => {
    const tl = this.getDefaultTimeline({ duration: 0.5 });
    const BOBA_1 = this.getElement(C.BOBA.B_1);
    const BOBA_2 = this.getElement(C.BOBA.B_2);
    const BOBA_3 = this.getElement(C.BOBA.B_3);
    const BOBA_4 = this.getElement(C.BOBA.B_4);
    const BOBA_5 = this.getElement(C.BOBA.B_5);
    const BOBA_6 = this.getElement(C.BOBA.B_6);
    const BOBA_7 = this.getElement(C.BOBA.B_7);
    const BOBA_8 = this.getElement(C.BOBA.B_8);
    const BOBA_9 = this.getElement(C.BOBA.B_9);
    const BOBA_10 = this.getElement(C.BOBA.B_10);
    const BOBA_11 = this.getElement(C.BOBA.B_11);

    const moveLabel = 'bobaMove';
    const resetProps = { x: 0, y: 0 };

    [
      [
        BOBA_1,
        [
          [4, -13],
          [6, -16],
          [4, -17],
          [0, -14],
          [1, -12],
          [4, -8],
          [0, -10],
          [1, -7],
          [1, -5],
          [1, -2],
        ],
      ],
      [
        BOBA_2,
        [
          [-2, -10],
          [0, -14],
          [4, -13],
          [1, -11],
          [2, -9],
          [-2, -4],
          [0, -7],
          [3, -6],
          [2, -4],
          [1, -2],
        ],
      ],
      [
        BOBA_3,
        [
          [2, -16],
          [4, -26],
          [1, -18],
          [-3, -12],
          [-5, -8],
          [-2, -10],
          [0, -12],
          [1, -7],
          [3, -4],
          [1, -2],
        ],
      ],
      [
        BOBA_4,
        [
          [3, -16],
          [7, -10],
          [-2, -6],
          [-3, -5],
          [-4, -9],
          [-2, -12],
          [-1, -11],
          [1, -8],
          [-2, -5],
          [2, -4],
        ],
      ],
      [
        BOBA_5,
        [
          [4, -18],
          [6, -10],
          [5, -6],
          [2, -5],
          [-2, -9],
          [-3, -12],
          [-2, -11],
          [0, -8],
          [2, -5],
          [1, -4],
        ],
      ],
      [
        BOBA_6,
        [
          [-3, -16],
          [-2, -14],
          [3, -12],
          [-2, -6],
          [0, -8],
          [3, -3],
          [-2, -7],
          [0, -5],
          [2, -4],
          [1, -3],
        ],
      ],
      [
        BOBA_7,
        [
          [0, -14],
          [1, -12],
          [-2, -11],
          [0, -8],
          [3, -9],
          [2, -7],
          [1, -5],
          [0, -4],
          [-2, -2],
          [-1, -1],
        ],
      ],
      [
        BOBA_8,
        [
          [4, -15],
          [2, -10],
          [-4, -8],
          [-3, -11],
          [0, -3],
          [2, -5],
          [-2, -6],
          [-1, -4],
          [0, -3],
          [1, -2],
        ],
      ],
      [
        BOBA_9,
        [
          [5, -14],
          [2, -12],
          [4, -10],
          [0, -7],
          [-3, -8],
          [-1, -6],
          [2, -7],
          [-1, -3],
          [0, -2],
          [1, 0],
        ],
      ],
      [
        BOBA_10,
        [
          [-5, -12],
          [-3, -10],
          [2, -8],
          [0, -7],
          [-2, -5],
          [0, -6],
          [-1, -5],
          [0, -3],
          [2, -2],
          [1, 0],
        ],
      ],
      [
        BOBA_11,
        [
          [5, -13],
          [3, -11],
          [2, -9],
          [-2, -8],
          [-1, -7],
          [0, -6],
          [-1, -5],
          [0, -4],
          [-2, -2],
          [-1, 1],
        ],
      ],
    ].forEach(([element, coords]) => {
      coords.forEach(([x, y], index) => {
        tl.to(element, { x, y }, index === 0 ? moveLabel : '>');
      });
      tl.to(element, resetProps, '>');
    });

    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = this.getBaseTimeline(initialize);

    if (!initialize) {
      tl.add(this.moveCup(initialize), '<');
      tl.add(this.moveCorgi(initialize), '<');
      tl.add(this.moveStraw(initialize), '<');
      tl.add(this.moveBoba(initialize), '<0.6');
    }
    this.tl = tl;
    return this.tl;
  };

  render() {
    return <Image className={C.BASE} {...this.props} />;
  }
}
