import React, { useContext } from 'react';
import { 
  Typography
} from '@mui/material';
import { 
  SeetingsWrapper,
  IconWrapper,
  HeaderWrapper, 
  SubSection,
  SubSectionWrapper,
  SubSectionHeader
} from './styles';
import RightChevron from './icons/rightChevron';
import AccountIcon from './icons/account';
import UserContext from 'context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';

export default function AccountSettings({ isChannelSetting }) {
  const { isAdminMember, currentWorkspaceId: workspaceId } = useContext(UserContext);
  const navigate = useNavigate();
  const params = isChannelSetting ? { isChannelSetting: true } : {};

  if (!isAdminMember) {
    return null;
  }
  
  return (
    <SeetingsWrapper>
      <HeaderWrapper>
        <IconWrapper>
          <AccountIcon />
        </IconWrapper>
        <Typography variant='h4'>Account</Typography>
      </HeaderWrapper>
      <SubSectionWrapper>
        <SubSection onClick={() => navigate(Resources.workspaceSettingsGeneral.path({ workspaceId }, params))}>
          <SubSectionHeader>
            <Typography variant='h5' component='p'>General</Typography>
            <RightChevron />
          </SubSectionHeader>
          <Typography variant='body2'>
            Update your organization’s information and settings.
          </Typography>
        </SubSection>
        {!isChannelSetting && (
          <>
            <SubSection onClick={() => navigate(Resources.workspaceSettingsBilling.path({ workspaceId }))}>
              <SubSectionHeader>
                <Typography variant='h5' component='p'>Billing</Typography>
                <RightChevron />
              </SubSectionHeader>
              <Typography variant='body2'>
                View invoices, update payment method, and change your plan.
              </Typography>
            </SubSection>
            <SubSection onClick={() => navigate(Resources.workspaceSettingsHris.path({ workspaceId }))}>
              <SubSectionHeader>
                <Typography variant='h5' component='p'>HRIS Integration</Typography>
                <RightChevron />
              </SubSectionHeader>
              <Typography variant='body2'>
                Manage data automatically from 100+ HRIS platforms.
              </Typography>
            </SubSection>
          </>
        )}
      </SubSectionWrapper>
    </SeetingsWrapper>
  );
}
