import React from 'react';
import styled from 'styled-components';
import Elements from '../Elements';

const BackgroundContainer = styled.div`
  background: ${(props) => props.theme.colors.blacks[5]};
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 0;
`;

const Stars = styled(Elements.Stars)`
  position: absolute;
  left: 50%;
  top: 0%;
  bottom: 0;
  width: auto;
  height: 100%;
  transform: translateX(-50%);
  z-index: -9999;
`;

const Footer = styled(Elements.Europa)`
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
`;

const Jupiter = styled(Elements.Jupiter)`
  position: absolute;
  top: 14%;
  right: -40px;
`;

const Mars = styled(Elements.Mars)`
  position: absolute;
  bottom: 100px;
  left: 75%;
`;

const Neptune = styled(Elements.Neptune)`
  position: absolute;
  top: 10%;
  left: 5%;
`;

const SpaceTwo = (props) => (
  <BackgroundContainer {...props}>
    <Footer grayScale />
    <Jupiter grayScale />
    <Mars grayScale />
    <Neptune grayScale />
    <Stars grayScale />
  </BackgroundContainer>
);

export default SpaceTwo;
