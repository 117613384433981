import React from 'react';
import styled from 'styled-components';
import theme, { colors, media, spacing } from '@matterapp/matter-theme';
import Panel from '../../Panel';
import AvatarHeader from '../AvatarHeader';
import FeedItemLayout from '../FeedItemLayout';
import { SkillRatingsList } from '../SkillRatings';
import QualityEndorsements from '../QualityEndorsements';
import { Footer } from '../FeedItem';
import { overallRating, getRating } from '../../../common/libs/overall-rating';

const FEEDBACK_HEADER = 'gave you feedback.';

const SkillRatingsContainer = styled.div``;

const OverallRatingBanner = styled.div`
  padding: ${spacing.singleAndQuarter};
  background: ${colors.purples[1]};
  border-radius: ${theme.borderRadius.L};
  margin-bottom: ${spacing.single};
  ${media.S`
    padding: ${spacing.singleAndHalf};
  `}
`;

const FeedItemBody = styled(FeedItemLayout.Body)`
  border-bottom: 1px solid ${colors.blacks[10]};
  padding-bottom: ${theme.padding.base};
  ${media.S`
    padding-bottom: ${theme.padding.S};
  `}
`;

const WorkspaceLink = styled.a`
  color: ${theme.colors.blacks[90]};
  border-bottom: 1px solid ${theme.colors.blacks[90]};
  &:active {
    text-decoration: none;
    color: ${theme.colors.purples[60]};
  }
`;

const WorkspaceName = styled.span`
  color: ${theme.colors.blacks[90]};
`;

export default class LongFormFeedback extends React.PureComponent {
  constructor() {
    super();
    this.skillRatingsRef = React.createRef();
    this.state = {
      animatedIn: false,
    };
  }

  componentDidMount() {
    if (this.props.feedItemProps.renderProps) {
      this.checkScrollTop();
    }
  }

  getSubHeaderWithWorkspace = (workspace, workspacePath) => {
    if (workspacePath) {
      return (
        <span>
          from{' '}<WorkspaceLink href={workspacePath}>{workspace.name}</WorkspaceLink>{' '}{FEEDBACK_HEADER}
        </span>
      );
    }
    return (
      <span>
        from{' '}<WorkspaceName>{workspace.name}</WorkspaceName>{' '}{FEEDBACK_HEADER}
      </span>
    );
  }

  renderHeader = () => {
    const { giver, showMenu, workspace, workspacePath, onClickDelete } = this.props;
    const subheader = workspace ? this.getSubHeaderWithWorkspace(workspace, workspacePath) : FEEDBACK_HEADER;
    return (
      <AvatarHeader
        equalPadding
        header={giver.fullName}
        subheader={subheader}
        photoUrl={giver.photoUrl}
        showMenu={showMenu}
        onClickDelete={onClickDelete}
      />
    );
  };

  checkScrollTop = () => {
    const { animatedIn } = this.state;
    const { scrollTop, windowHeight } = this.props.renderProps;
    if (this.skillRatingsRef.current && !animatedIn) {
      const itemTop = this.skillRatingsRef.current.offsetTop;
      const canAnimate = (scrollTop - 50 > itemTop - windowHeight);
      if (canAnimate) {
        this.setState({ animatedIn: true });
      }
    }
  }
  
  renderOverallRating() {
    const { feedback, giver, feedItemProps } = this.props;
    if (feedback.overallRating && !feedItemProps.hideEndorsements) {
      const rating = getRating(feedback.overallRating);
      const ratingDescription = overallRating[rating].toLowerCase();
      return (
        <OverallRatingBanner>
          {`${giver.fullName} said it's been ${ratingDescription} working with you.`}
        </OverallRatingBanner>
      );
    }
  }

  renderSkillRatings = () => {
    const { giver, feedback, feedItemProps, renderProps, onClickLearnMore, onClickSkillBadge, trackViewAbilityModal } = this.props;
    const { expanded } = feedItemProps;
    const { shouldAnimateIn } = renderProps;
    const { skillRatings } = feedback;

    if (shouldAnimateIn) {
      this.checkScrollTop();
    }
    return (
      <SkillRatingsContainer ref={this.skillRatingsRef}>
        <SkillRatingsList
          expanded={expanded}
          skillRatings={skillRatings}
          overallRating={feedback.overallRating}
          recurringFeedbackRecurrence={giver.recurringFeedback?.recurrence}
          giver={giver}
          onClickLearnMore={onClickLearnMore}
          onClickActionItem={trackViewAbilityModal}
          onClickSkillBadge={onClickSkillBadge}
          skillGaugeProps={{ animateIn: shouldAnimateIn ? this.state.animatedIn : true, shouldAnimateIn: true }}
        />
      </SkillRatingsContainer>
    );
  };

  renderQualityEndorsements = () => {
    const { feedback, giver, feedItemProps, onClickDetails } = this.props;
    const { hideEndorsements } = feedItemProps;

    return (
      <QualityEndorsements
        feedbackReceived={feedback}
        onClickDetails={onClickDetails}
        hideEndorsements={hideEndorsements}
      >
        <>
          {giver.firstName || giver.email} endorsed you for being:
        </>
      </QualityEndorsements>
    );
  };

  render() {
    const { feedItemProps, footerProps } = this.props;
    const { hideBottomMargin } = feedItemProps;
    const { hideFooter } = footerProps;
    return (
      <Panel.Base header={this.renderHeader()} hideBottomMargin={hideBottomMargin}>
        <FeedItemBody>
          {this.renderOverallRating()}
          {this.renderSkillRatings()}
          {this.renderQualityEndorsements()}
        </FeedItemBody>
        {hideFooter ? null : <Footer {...footerProps} />}
      </Panel.Base>
    );
  }
}

LongFormFeedback.defaultProps = {
  feedItemProps: {
    hideFooter: false,
    hideBottomMargin: false,
  }
};