import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../Container';
import Heading from '../Heading';
import { sizePropType, SIZE } from '../size';
import theme from '@matterapp/matter-theme';

const PieProgressContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 24px;
`;

const BelowProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 32px;

  ${() => theme.media.medium`
    padding-top: 24px;
  `}
`;

const IconContainer = styled.div`
  display: none;
  ${() => theme.media.medium`
    display: initial;
    padding-right: 24px;
  `}
`;

const ContentContainer = styled.div`
  padding-right: 24px;
  ${() => theme.media.medium`
    padding-right: 32px;
  `}
`;

const ProgressHeader = ({ title, children, progress, size, icon }) => (
  <Container progress>
    <PieProgressContainer>{progress}</PieProgressContainer>
    <BelowProgressContainer>
      {icon && <IconContainer>{icon}</IconContainer>}
      <ContentContainer>
        <Heading size={size}>{title}</Heading>
        {children}
      </ContentContainer>
    </BelowProgressContainer>
  </Container>
);

ProgressHeader.defaultProps = {
  children: null,
  progress: null,
  icon: null,
  size: SIZE.MEDIUM,
};

ProgressHeader.propTypes = {
  title: PropTypes.string.isRequired,
  progress: PropTypes.node,
  children: PropTypes.node,
  icon: PropTypes.node,
  size: sizePropType,
};

export default ProgressHeader;
