import React from 'react';
import OpenToClose from './OpenToClose';
import CloseToOpen from './CloseToOpen';

const Body = (props) => {
  const { isEditMode, customizationProps } = props;
  if (isEditMode) {
    return (
      <OpenToClose
        {...customizationProps}
      />
    );
  } 
  return (
    <CloseToOpen
      {...customizationProps}
    />
  );
};

export default Body;