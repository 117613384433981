import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import RaterBase from '../Base';
import Base from '../svgBase';

const DEFAULT_HEIGHT = 24;
const DEFAULT_WIDTH = 26;
const PATH = 'M4.5553 23.1495C4.71416 22.2257 4.85986 21.3752 5.00556 20.5248C5.28098 18.915 5.56674 17.3061 5.81584 15.6917C5.84404 15.5114 5.71056 15.2493 5.56768 15.1089C3.8597 13.435 2.1395 11.7732 0.413653 10.1169C0.126953 9.84182 -0.0930076 9.56485 0.0395326 9.14846C0.173013 8.72742 0.509533 8.54154 0.915614 8.47926C2.02482 8.30918 3.1359 8.15489 4.24698 7.9941C5.57332 7.8017 6.90248 7.62139 8.22318 7.39926C8.4046 7.36858 8.60764 7.15203 8.69976 6.97079C9.76102 4.89349 10.8054 2.8069 11.8459 0.719385C12.0424 0.326233 12.2567 0 12.77 0C13.2907 0 13.4928 0.351328 13.6855 0.734256C14.7233 2.80504 15.7676 4.87211 16.7951 6.94755C16.9473 7.25426 17.1278 7.40019 17.4869 7.44945C19.8341 7.77103 22.1766 8.12143 24.52 8.46811C24.9148 8.52666 25.2795 8.63913 25.4393 9.07131C25.6076 9.52395 25.4045 9.8381 25.0943 10.1374C23.3835 11.7871 21.6821 13.4462 19.9647 15.0885C19.7335 15.3088 19.6611 15.5235 19.7138 15.8246C20.1302 18.1612 20.5353 20.5006 20.9536 22.8372C21.0213 23.2146 20.9978 23.552 20.6697 23.7955C20.3266 24.0501 19.9741 24.0632 19.5793 23.8559C17.4606 22.7415 15.3287 21.6503 13.2127 20.5304C12.8818 20.3547 12.6337 20.3686 12.3122 20.5387C10.1944 21.6559 8.0662 22.7554 5.93898 23.8559C5.48778 24.0892 5.01684 24.0288 4.7621 23.6839C4.64366 23.5194 4.60982 23.2954 4.5553 23.1495Z';

const StarBase = styled(Base)`
  transform: translateX(-1px);
`;

const StarRating = (props) => (
  <RaterBase
    color="purple"
    spacing={theme.spacing.half}
    showDescription={false}
    isStarRating
    {...props}
  >
    <StarBase height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
  </RaterBase>
);

export default StarRating;
