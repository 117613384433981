import * as Base from '../../styles';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const METER_HEIGHT = 40;
const Container = styled(Base.Container)``;

const BarRoot = styled(Base.BarRoot)`
  background-color: ${theme.colors.purples[50]};
  height: ${METER_HEIGHT}px;
`;

const HelperTextContainer = styled(Base.HelperTextContainer)`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.regular};
  margin: 0 auto;
  text-transform: uppercase;
`;

const StepCounter = styled(Base.StepCounter)`
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.base};
`;

const Meter = styled(Base.Meter)`
  background: ${theme.colors.reds[40]};
  height: ${METER_HEIGHT}px;
  overflow: hidden;
`;

const SmoothingContainer = styled(Base.SmoothingContainer)`
  overflow: hidden;
  border-radius: 100px;
`;

export default {
  Container,
  BarRoot,
  HelperTextContainer,
  StepCounter,
  Meter,
  SmoothingContainer
}; 