import React from 'react';
import PropTypes from 'prop-types';
import TangoRewardRedeemModal from './TangoRewardRedeemModal';
import CustomRewardRedeemModal from './CustomRewardRedeemModal';
import { REWARD_TYPES } from '../consts';

const RedeemModal = (props) => {
  return (
    props.selectedReward.rewardType === REWARD_TYPES.CUSTOM ?
      <CustomRewardRedeemModal {...props} /> :
      <TangoRewardRedeemModal {...props} />
  );
};

RedeemModal.propTypes = {
  selectedReward: PropTypes.object,
};

export default RedeemModal;