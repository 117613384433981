import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useExperiment } from 'modules/experiments';
import { Panel, PeerSuggestionsPreview } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

const Container = styled(Panel.Base)`
  margin: ${() => theme.spacing.singleAndHalf} 0;
`;

const PeerSuggestionsPreviewWrapper = ({
  alwaysShowAvatars,
  peerRecommendations,
  isPeerRecommendationsLoaded,
  handleClickViewAll,
  viewAllLinkTo,
}) => {
  const { assignment, loading } = useExperiment({ name: 'default-avatar' });
  if (
    loading ||
    !(
      isPeerRecommendationsLoaded &&
      peerRecommendations &&
      peerRecommendations.length
    )
  ) {
    return null;
  }
  return (
    <Container>
      <Panel.Footer.Basic smallVerticalPadding>
        <PeerSuggestionsPreview
          alwaysShowAvatars={alwaysShowAvatars}
          defaultAvatarIsGenerated={assignment === 'treatment'}
          peerSuggestions={peerRecommendations}
          onActionClick={handleClickViewAll}
          linkTo={viewAllLinkTo}
        />
      </Panel.Footer.Basic>
    </Container>
  );
};

PeerSuggestionsPreviewWrapper.propTypes = {
  alwaysShowAvatars: PropTypes.bool,
  isPeerRecommendationsLoaded: PropTypes.bool.isRequired,
  peerRecommendations: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      fullName: PropTypes.string,
      photoUrl: PropTypes.string,
    })
  ).isRequired,
  handleClickViewAll: PropTypes.func.isRequired,
  viewAllLinkTo: PropTypes.string.isRequired,
};

export default PeerSuggestionsPreviewWrapper;
