import React from 'react';
import Animation from '../../Animation';
import Image from './Image';
import * as C from './classNames';

export default class Meowow extends Animation {
  baseClass = C.BASE;

  moveBackground = (initialize) => {
    const tl = this.getDefaultTimeline({ duration: 0.4 });
    const BACKGROUND = this.getElement(C.BACKGROUND);
    const STAR_TOP = this.getElement(C.STAR.TOP);
    const STAR_LEFT = this.getElement(C.STAR.LEFT);
    const STAR_RIGHT_1 = this.getElement(C.STAR.RIGHT_1);
    const STAR_RIGHT_2 = this.getElement(C.STAR.RIGHT_2);

    const CIRCLE_BACK = this.getElement(C.CIRCLE.BACK);
    const CIRCLE_TOP = this.getElement(C.CIRCLE.TOP);
    const CIRCLE_LEFT_1 = this.getElement(C.CIRCLE.LEFT_1);
    const CIRCLE_LEFT_2 = this.getElement(C.CIRCLE.LEFT_2);
    const RING = this.getElement(C.RING);
    const PLUS = this.getElement(C.PLUS);

    // const BACKGROUND_ELEMENTS = [
    //   [STAR_TOP, { y: 40 }],
    //   [STAR_LEFT, { x: 30, y: 20 }],
    //   [STAR_RIGHT_1, { x: -30, y: 20 }],
    //   [STAR_RIGHT_2, { x: -25, y: 15 }],
    //   [CIRCLE_TOP, { y: 30 }],
    //   [CIRCLE_LEFT_1, { x: 40, y: 10 }],
    //   [CIRCLE_LEFT_2, { y: 50, y: 5 }],
    //   [RING, { x: 55, y: -3 }],
    //   [PLUS, { y: 30 }],
    // ];

    const BACKGROUND_ELEMENTS = [
      [STAR_TOP, { x: 36, y: 18 }],
      [CIRCLE_LEFT_1, { x: 124, y: -21 }],
      [RING, { x: 124, y: -54 }],
      [STAR_RIGHT_1, { x: -15, y: 35 }],
      [CIRCLE_TOP, { x: 55, y: 16 }],
      [STAR_LEFT, { x: 140, y: 4 }],
      [STAR_RIGHT_2, { x: -27, y: 16 }],
      [CIRCLE_LEFT_2, { x: 111, y: 11 }],
      [PLUS, { x: 47, y: 45 }],
    ];

    const START_PULSE_LABEL = 'startPulse';

    const pulseElements = (elements = [], start = START_PULSE_LABEL) => {
      elements.forEach((element, index) => {
        tl.to(element, { scale: 2 }, index === 0 ? start : '<-0.05');
        tl.to(element, { scale: 1, ease: 'power.in' }, '>');
      });
    };

    tl.to(BACKGROUND, { opacity: 0, duration: initialize ? 0 : 0.3 }, '<');

    if (!initialize) {
      tl.to(CIRCLE_BACK, { opacity: 0, scale: 1, duration: 0 }, '>');
      BACKGROUND_ELEMENTS.forEach(([element, hideProps]) => {
        tl.to(element, { ...hideProps, duration: 0 }, '>');
      });

      tl.to(BACKGROUND, { opacity: 1, duration: 0.2 }, 'handUp+=0.4');
      tl.to(CIRCLE_BACK, { opacity: 1, duration: 0 }, '<-0.1');
      tl.to(CIRCLE_BACK, { opacity: 0, scale: 3.2 }, '>');
      BACKGROUND_ELEMENTS.forEach(([element]) => {
        tl.to(element, { x: 0, y: 0, rotate: 0, duration: 0.2 }, '<0.01');
      });

      tl.addLabel(START_PULSE_LABEL);
      tl.to(PLUS, { rotate: -1080, duration: 4.5 }, '>');
      pulseElements([
        STAR_TOP,
        STAR_LEFT,
        STAR_RIGHT_1,
        STAR_LEFT,
        STAR_RIGHT_2,
        STAR_TOP,
        STAR_RIGHT_1,
        STAR_LEFT,
        STAR_TOP,
        STAR_RIGHT_2,
        STAR_LEFT,
      ]);
      pulseElements(
        [
          CIRCLE_LEFT_1,
          CIRCLE_TOP,
          RING,
          CIRCLE_LEFT_2,
          CIRCLE_TOP,
          RING,
          CIRCLE_LEFT_1,
          CIRCLE_TOP,
          CIRCLE_LEFT_2,
          CIRCLE_LEFT_1,
          RING,
        ],
        `${START_PULSE_LABEL}+=0.004`
      );
    }

    return tl;
  };

  moveCat = () => {
    const tl = this.getDefaultTimeline({ ease: 'power.out', duration: 1 });
    const CAT = this.getElement(C.CAT);

    tl.to(CAT, { y: -40 }, 'handUp+=1');
    tl.to(CAT, { y: 0 }, '>2.4');

    return tl;
  };

  moveHand = (initialize) => {
    const tl = this.getDefaultTimeline({
      transformOrigin: '50% 100%',
      duration: 0.5,
    });
    const HAND = this.getElement(C.HAND);

    if (initialize) {
      tl.to(HAND, { y: 40, duration: 0 }, '<');
    } else {
      tl.to(HAND, { y: 40, duration: 0.3 }, 'handup');
      tl.to(HAND, { y: 0, duration: 0.2 }, '>0.2');

      for (let i = 0; i < 3; i++) {
        tl.to(HAND, { scaleY: 0.78, rotate: 4 }, i === 0 ? '>0.5' : '>');
        tl.to(HAND, { scaleY: 1, rotate: 0 }, '>');
      }
    }

    return tl;
  };

  ringBell = () => {
    const tl = this.getDefaultTimeline({
      transformOrigin: '50% 0%',
      duration: 0.4,
    });
    const BELL = this.getElement(C.NECKLACE.BELL);

    for (let i = 0; i < 7; i++) {
      tl.to(BELL, { rotate: -14 }, '>');
      tl.to(BELL, { rotate: 14 }, '>');
    }

    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = this.getBaseTimeline(initialize);

    tl.add(this.moveHand(initialize), '<');
    tl.add(this.moveBackground(initialize), '<');
    if (!initialize) {
      tl.add(this.moveCat(), '<');
      tl.add(this.ringBell(), '<');
    }

    this.tl = tl;
    return this.tl;
  };

  render() {
    return <Image className={C.BASE} {...this.props} />;
  }
}
