import React from 'react';
import styled from 'styled-components';
import { media, spacing } from '@matterapp/matter-theme';
import {
  Avatar,
  Button,
  List,
  Rater,
  Skill,
} from '@matterapp/matter-ui';
import {
  FlowHeader,
  StepContainer,
  SkillStepsNavButton,
  NavButtonContainer,
  SidebarContainer,
  SidebarWrapper,
  StepHeaderContainer,
  StepHeader,
  MOBILE_RATER_STEP_SIZE,
  DESKTOP_RATER_STEP_SIZE,
  NUMBER_OF_RATINGS,
} from './sharedStyles';
import {
  setRating,
  getRating,
  REPLACE_NAME,
  REPLACE_FREQUENCY_TEXT,
} from './utils';
import Background from './Background';
import Sidebar from './Sidebar';
import SkillStep from './SkillStep';
import SkillNotesStep from './SkillNotesStep';
import { FREQUENCY_NOUNS } from 'app-consts';

const REPLACE_DEMONSTRATIVE_PRONOUN = '%d';
const OVERALL_RATING_HEADER = `How’s it been working with ${REPLACE_NAME} ${REPLACE_DEMONSTRATIVE_PRONOUN} last ${REPLACE_FREQUENCY_TEXT}?`;
const SKILL_SELECTION_HEADER = `Select the skills you’d like to provide feedback on for ${REPLACE_NAME}.`;
const DESCRIPTION_HEADER = `Take a moment to elaborate before sending your feedback to ${REPLACE_NAME}.`;
const CUSTOM_DESCRIPTION = `This is a custom skill ${REPLACE_NAME} is currently working on.`;

const QUICK_TIPS = {
  NOTE_1: 'Elaborate on the your suggested improvement area.',
  NOTE_2: 'Describe what they can start, stop, or continue doing.',
  NOTE_3: 'Give examples of what a "home-run" would look like.',
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: ${spacing.single};
  margin-top: ${({ isLastStep }) => isLastStep ? '' :  spacing.quintuple};
  ${media.S`
    padding: 0 ${spacing.singleAndHalf};
    margin: auto;
    max-width: 992px;
    margin-top: 6vh !important;
  `}
`;

const StepsContainer = styled.div`
  min-height: 484px;
  margin-bottom: 100px;
  ${media.S`
    margin-bottom: 0;
  `}
`;

const LeftContainer = styled.div`
  width: 608px;
`;

const StarRaterContainer = styled.div`
  margin-bottom: ${spacing.doubleAndHalf};
  height: ${spacing.sextuple};
  ${media.S`
    margin-bottom: ${spacing.double};
    height: 128px;
  `}
`;

const StarRater = styled(Rater.Star)`
  max-width: ${(MOBILE_RATER_STEP_SIZE + 4) * NUMBER_OF_RATINGS}px;
  height: ${MOBILE_RATER_STEP_SIZE}px;
  & ${Button.Simple.Toggle.Primary} {
    padding: 0 !important;
    min-height: ${MOBILE_RATER_STEP_SIZE}px;
    min-width: ${MOBILE_RATER_STEP_SIZE}px;
  }
  ${media.S`
    max-width: ${(DESKTOP_RATER_STEP_SIZE + 4) * NUMBER_OF_RATINGS}px;
    height: ${DESKTOP_RATER_STEP_SIZE}px;
    & ${Button.Simple.Toggle.Primary} {
      min-height: ${DESKTOP_RATER_STEP_SIZE}px;
      min-width: ${DESKTOP_RATER_STEP_SIZE}px;
    }
  `}
`;

const SkillStepContainer = styled.div``;

const NavButton = styled(Button.Primary.Vivid)`
  flex: 1 1 100%;
`;

export default class Timeline extends React.Component {
  static defaultProps = {
    currentSkillsIds: [],
    skillsToRate: [],
    currentSkillsToRate: [],
    onChangeFeedbackRating: () => null,
    onChangeCurrentSkillsIds: () => null,
    onChangeCurrentSkillRating: () => null,
    onChangeCurrentNote: () => null,
    onChangeCurrentAbilities: () => null,
    onSubmitFeedbackRequest: () => null,
    onNextStep: () => null,
    onPrevStep: () => null,
  };

  state = {
    newSkillsToRate: [],
    totalSteps: 2,
    skillNotesErrors: {}
  }

  componentDidUpdate(prevProps) {
    const { currentSkillsToRate, totalSteps } = this.props;
    if (prevProps.currentSkillsToRate.length !== currentSkillsToRate.length) {
      this.setState({ newSkillsToRate: currentSkillsToRate, totalSteps });
    }
  }

  getCustomSkillDescription = () => {
    const { receiver } = this.props;
    return CUSTOM_DESCRIPTION.replace(REPLACE_NAME, receiver.firstName);
  }

  getSkillsToRate = () => {
    const { skillsToRate, currentSkillsIds } = this.props;
    const newSkillsToRate = [];

    for (const skillToRate of skillsToRate) {
      for (const skillId of currentSkillsIds) {
        if (skillId === skillToRate.skill.id) {
          newSkillsToRate.push(skillToRate);
        }
      }
    }
    const totalSteps = newSkillsToRate.length + 2;
    this.setState({ newSkillsToRate, totalSteps });
  }

  onClickNextStep = (e, skillId) => {
    const { currentStep, onNextStep, handleTrackEvent } = this.props;
    const isLastStep = currentStep >= this.state.totalSteps;
    if (!isLastStep) {
      this.getSkillsToRate();
    } 
    if (currentStep === 1) {
      handleTrackEvent.viewSkillStep();
    }
    onNextStep({ skillId, isLastStep });
    window.scrollTo({ top: 0 });
  }

  onClickBackStep = (e, skillId) => {
    const { currentStep, onPrevStep, handleTrackEvent } = this.props;
    const isLastStep = currentStep >= this.state.totalSteps;
    if (currentStep !== 1) {
      onPrevStep({ skillId, isLastStep });
    } 
    if (isLastStep) {
      handleTrackEvent.viewSkillStep();
    }
    window.scrollTo({ top: 0 });
  }

  handleChangeFeedbackRating = (e, { prevRating, value }) => {
    const currentRating = prevRating === value ? null : setRating(value);
    const { onChangeFeedbackRating } = this.props;
    onChangeFeedbackRating(e, currentRating);
  }

  handleChangeCurrentAbilities = (e, abilityProps) => {
    this.props.onChangeCurrentAbilities(e, abilityProps);
    this.getSkillsToRate();
  }

  handleChangeCurrentNote = (e, noteProps) => {
    const { skillNotesErrors } = this.state;
    const { noteError, skillToRate } = noteProps;
    const skillName = skillToRate.skill.name;
    const errorState = {
      [skillName]: noteError,
    };
    // This checks if any skill notes has errors so we can disable the submit button if needed;
    this.setState({ skillNotesErrors: {...skillNotesErrors, ...errorState}});
    this.props.onChangeCurrentNote(e, noteProps);
  }

  renderHeader() {
    const { currentStep, receiver } = this.props;
    if (currentStep === 1) {
      return (
        <StepHeaderContainer>
          <StepHeader>
            <Avatar url={receiver.photoUrl} size={24} />
            <span>{`Feedback for ${receiver.fullName}`}</span>
          </StepHeader>
        </StepHeaderContainer>
      );
    } else {
      return null;
    }
  }


  renderNavigationButtons = () => {
    const { isSaving, currentStep, feedbackRating, currentSkillsIds, onSubmitFeedbackRequest } = this.props;
    const { totalSteps, skillNotesErrors } = this.state;
    const disableNext = !feedbackRating || !currentSkillsIds.length;
    const disableSubmit = Object.values(skillNotesErrors).includes(true);
    const isLastStep = currentStep >= totalSteps;

    if (currentStep === 1) {
      return (
        <NavButtonContainer>
          <NavButton
            onClick={this.onClickNextStep}
            disabled={disableNext}
            isLoading={isSaving}
            data-rc={'first-step-continue-button'}
          >
            Continue
          </NavButton>
        </NavButtonContainer>
      );
    } else if (isLastStep) {
      return (
        <NavButtonContainer>
          <SkillStepsNavButton
            onClick={this.onClickBackStep}
            data-rc={'back-button'}
          >
            Back
          </SkillStepsNavButton>
          <NavButton
            onClick={() => onSubmitFeedbackRequest(isLastStep)}
            disabled={disableSubmit}
            isLoading={isSaving}
            data-rc={'submit-button'}
          >
            Submit
          </NavButton>
        </NavButtonContainer>
      );
    } else {
      return null;
    }
  };

  renderSideBar() {
    const { currentStep, receiver } = this.props;
    const { totalSteps } = this.state;
    const jobTitle =  receiver.currentJobCategory?.title;
    const receiverJobTitle = jobTitle === 'Other' ?  receiver.headline : jobTitle;
    const isFirstStep = currentStep === 1;
    const isLastStep = currentStep >= totalSteps;
    let sidebarContent;

    if (isFirstStep) {
      sidebarContent = 
        <Sidebar
          useAvatar
          header={receiver.fullName}
          avatar={receiver.photoUrl}
          content={receiverJobTitle}
        />;
    } else if (isLastStep) {
      sidebarContent = 
        <Sidebar.QuickTips>
          <Sidebar.Paragraph>{QUICK_TIPS.NOTE_1}</Sidebar.Paragraph>
          <Sidebar.Paragraph>{QUICK_TIPS.NOTE_2}</Sidebar.Paragraph>
          <Sidebar.Paragraph>{QUICK_TIPS.NOTE_3}</Sidebar.Paragraph>
        </Sidebar.QuickTips>;
    }

    if (isFirstStep || isLastStep) {
      return (
        <SidebarContainer>
          <SidebarWrapper>
            <StepContainer
              sidebarContent={sidebarContent}
            />
          </SidebarWrapper>
        </SidebarContainer>
      );
    } else {
      return null;
    }
  }

  renderSkillSelectionStep() {
    const {
      receiver,
      feedbackRating,
      skillsToRate,
      currentSkillsIds,
      recurringFeedbackRecurrence,
      onChangeCurrentSkillsIds
    } = this.props;
    const currentRating = getRating(feedbackRating);
    const firstName = receiver.firstName || '';
    const fullName = receiver.fullName || '';
    const demonstrativePronoun = (recurringFeedbackRecurrence.frequency === 'biweekly' || recurringFeedbackRecurrence.frequency === 'bimonthly') ? 'these' : 'this';
    const skillSelectionHeader = SKILL_SELECTION_HEADER.replace(REPLACE_NAME, firstName);
    const overallRaterHeader = OVERALL_RATING_HEADER
      .replace(REPLACE_NAME, fullName)
      .replace(REPLACE_DEMONSTRATIVE_PRONOUN, demonstrativePronoun)
      .replace(REPLACE_FREQUENCY_TEXT, FREQUENCY_NOUNS[recurringFeedbackRecurrence.frequency]);
    console.log('currentRating', feedbackRating);
    return (
      <div data-rc={'Skill Selection Step'}>
        <FlowHeader>
          {overallRaterHeader}
        </FlowHeader>
        <StarRaterContainer data-rc={'feedback-rater'}>
          <StarRater 
            rating={currentRating} 
            onClickStep={this.handleChangeFeedbackRating}
          /> 
        </StarRaterContainer>
        <FlowHeader>
          {skillSelectionHeader}
        </FlowHeader>
        <List.Container
          data-rc={'skill-selection-list'}
          alignLeft
          itemPadding={"0 8px 8px 0"}
          style={{marginBottom: '0px', height: '220px'}}
          animated={false}
        >
          {skillsToRate.map((singleSkill) => {
            const { skill } = singleSkill;
            return (
              <Skill.Pill.Button.Small
                key={skill.id}
                vivid
                skill={skill}
                selected={currentSkillsIds.includes(skill.id)}
                disabled={false}
                onClick={(e) => onChangeCurrentSkillsIds(e, skill)}
              >
                {skill.name}
              </Skill.Pill.Button.Small>
            );
          })}
        </List.Container>
      </div>
    );
  }

  /**
   * Renders all skill steps
   * @returns { React.Component } The rendered skill steps.
   */
  renderSkillSteps = () => {
    const {
      receiver,
      currentStep,
      recurringFeedbackRecurrence,
      onChangeCurrentAbilities,
      onChangeCurrentSkillRating,
      onOpenRatingGuideModal,
      handleTrackEvent,
    } = this.props;
    const { newSkillsToRate } = this.state;

    const skillSteps = newSkillsToRate.map((skillToRate, index) => {
      return (
        <SkillStepContainer key={`skill-step-${index}-${skillToRate.skill.id}`} data-rc={'Skill Rating Step'}>
          <SkillStep
            currentStep={currentStep}
            receiver={receiver}
            skillToRate={skillToRate}
            isLastSkillStep={this.state.totalSteps - 1 === currentStep}
            isFirstSkillStep={currentStep === 2}
            recurringFeedbackRecurrenceFrequency={recurringFeedbackRecurrence.frequency}
            onClickNextStep={this.onClickNextStep}
            onClickBackStep={this.onClickBackStep}
            onChangeCurrentAbilities={onChangeCurrentAbilities}
            onChangeCurrentSkillRating={onChangeCurrentSkillRating}
            onOpenRatingGuideModal={onOpenRatingGuideModal}
            customSkillDescription={this.getCustomSkillDescription()}
            handleTrackEvent={handleTrackEvent}
          />
        </SkillStepContainer>
      );
    });

    return skillSteps[currentStep - 2]; // Because array starts at 0, we are subtracting 2 to get to the first Skill Step in the array
  };

  renderSkillNotesStep = () => {
    const { 
      receiver,
      recurringFeedbackRecurrence
    } = this.props;
    const { newSkillsToRate } = this.state;
    const firstName = receiver.firstName || '';
    const descriptionHeader = DESCRIPTION_HEADER.replace(REPLACE_NAME, firstName);
    const customDescription = this.getCustomSkillDescription();
    return (
      <SkillNotesStep
        newSkillsToRate={newSkillsToRate}
        descriptionHeader={descriptionHeader}
        customDescription={customDescription}
        recurrenceFrequency={recurringFeedbackRecurrence.frequency}
        onChangeCurrentNote={this.handleChangeCurrentNote}
      />
    );
  }

  renderSteps() {
    const { currentStep } = this.props;
    const { totalSteps } = this.state;
    const isLastStep = currentStep >= totalSteps;
    let steps = [];
    if (currentStep === 1) {
      steps = this.renderSkillSelectionStep();
    } else if (isLastStep) {
      steps = this.renderSkillNotesStep();
    } else {
      steps = this.renderSkillSteps();
    }
    return steps;
  }

  render() {
    const { currentStep } = this.props;
    const { totalSteps, newSkillsToRate } = this.state;
    const isLastStep = currentStep >= totalSteps;

    return (
      <>
        <Background
          currentStep={currentStep}
          skills={newSkillsToRate}
          numberOfSkills={newSkillsToRate.length}
          totalSteps={totalSteps}
        />
        <Container isLastStep={isLastStep}>
          {this.renderHeader()}
          <LeftContainer>
            <StepsContainer>
              {this.renderSteps()}
            </StepsContainer>
            {this.renderNavigationButtons()}
          </LeftContainer>
          {this.renderSideBar()}
        </Container>
      </>
    );
  }
}