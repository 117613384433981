import React from 'react';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../../../libs/omitStyled';

const Container = omitStyled('div', ['backgroundColor'])`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  color: ${() => theme.colors.blacks[90]};
  font-size: 17px;
  line-height: 24px;

  width: 100%;
  height: 48px;
  margin: 0 12px;
  border-radius: 8px;

  background-color: white;
  transition: background-color 0.1s ease-in-out;

  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }

  &:hover:not([disabled]) {
    background-color: ${(props) => props.backgroundColor};
    cursor: pointer;
  }


  ${() => theme.media.medium`
    margin: 0 16px;
  `}
`;

const ActionBarItem = ({ children, backgroundColor, onClick, disabled }) => (
  <Container
    backgroundColor={backgroundColor}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </Container>
);

ActionBarItem.defaultProps = {
  onClick: undefined,
  disabled: false,
};

ActionBarItem.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ActionBarItem;
