import Base from './Modal';
import OldModal from './Old/ModalOld';

import { Flow, Panel, WithConfirmClose } from './variants';
import { ModalFlow, ModalVideo, ModalWithConfirmClose } from './Old/variants';

import Presets from './presets';

const Modal = Base;
Modal.Panel = Panel;
Modal.Presets = Presets;

Modal.Old = OldModal;
Modal.Old.Flow = OldModal;
Modal.Flow = Flow;
Modal.Video = ModalVideo;
Modal.WithConfirmClose = WithConfirmClose;

// ModalNew is Deprecated. Use Modal.Old instead.
export {
  Modal,
  OldModal as ModalNew,
  ModalFlow,
  ModalVideo,
  ModalWithConfirmClose,
};
export { Adapter as ModalAdapter } from './Old/components';

export default Modal;
