import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { sizes } from '../utils';
import {
  fontSizes,
  getBackgroundColor,
  getBorderColor,
  getArrowColor,
  getSizeStyle,
  getTextColor,
  heights,
  iconRight,
  paddings,
  textColors,
  transitionTime,
  VARIANTS,
} from '../sharedStyles';

const getInputSizeStyles = ({ inputSize = sizes.M }) => {
  return `
    font-size: ${getSizeStyle(fontSizes, inputSize)};
    height: ${getSizeStyle(heights, inputSize)};
    padding: ${getSizeStyle(paddings, inputSize)};
    padding-right: calc(${getSizeStyle(paddings, inputSize)} * 2);
  `;
};

const getStateStyles = ({ color, disabled, hasError, isOpen }) => {
  const styles = {
    arrowColor: getArrowColor({ color, variant: VARIANTS.BASE }),
    backgroundColor: getBackgroundColor({ color, variant: VARIANTS.BASE }),
    backgroundColorHover: getBackgroundColor({ color, variant: VARIANTS.OPEN }),
    borderColor: getBorderColor({ color, variant: VARIANTS.BASE }),
    borderColorHover: getBorderColor({ color, variant: VARIANTS.OPEN }),
    textColor: getTextColor({ color, variant: VARIANTS.BASE }),
    textHoverColor: getTextColor({ color, variant: VARIANTS.OPEN || VARIANTS.BASE }),
  };

  if (disabled) {
    styles.arrowColor = getArrowColor({ color, variant: VARIANTS.DISABLED });
    styles.backgroundColor = getBackgroundColor({ color, variant: VARIANTS.DISABLED });
    styles.backgroundColorHover = getBackgroundColor({ color, variant: VARIANTS.DISABLED });
    styles.borderColor = getBorderColor({ color, variant: VARIANTS.DISABLED });
    styles.borderColorHover = getBorderColor({ color, variant: VARIANTS.DISABLED });
    styles.textColor = getTextColor({ color, variant: VARIANTS.DISABLED });
    styles.textHoverColor = getTextColor({ color, variant: VARIANTS.DISABLED });
  }
  if (hasError) {
    styles.arrowColor = getTextColor({ color, variant: VARIANTS.ERROR });
    styles.backgroundColor = isOpen
      ? getBackgroundColor({ color, variant: VARIANTS.BASE })
      : getBackgroundColor({ color, variant: VARIANTS.ERROR });
    styles.borderColor = getBorderColor({ color, variant: VARIANTS.ERROR });
    styles.borderColorHover = getBorderColor({
      color,
      variant: VARIANTS.ERROR,
    });
    styles.textColor = getTextColor({ color, variant: VARIANTS.ERROR });
  } else if (isOpen) {
    styles.borderColor = getBorderColor({ color, variant: VARIANTS.OPEN });
  }

  return `
    border-color: ${styles.borderColor};
    background-color: ${styles.backgroundColor};
    transition: background-color ${transitionTime}, border-color ${transitionTime}, color ${transitionTime};

    & ${IconContainer} svg path {
      fill: ${styles.arrowColor};
      stroke: ${styles.arrowColor};
    }

    & input {
      color: ${styles.textColor};
      -webkit-text-fill-color: ${styles.textColor};
    }

    &:hover {
      border-color: ${styles.borderColorHover};
      background-color: ${styles.backgroundColorHover};
      
      & input {
        color: ${styles.textHoverColor}
        -webkit-text-fill-color: ${styles.textHoverColor};
      }
    }

    ${isOpen ? `
      border-color: ${styles.borderColorHover};
      background-color: ${styles.backgroundColorHover};
      
      & input {
        color: ${styles.textHoverColor}
        -webkit-text-fill-color: ${styles.textHoverColor};
      }
    ` : ``}
  `;
};

const getCursorStyles = ({ disabled, readOnly, showDropdownArrow }) => {
  if (disabled) {
    return 'cursor: not-allowed;';
  } else if (readOnly) {
    return 'cursor: pointer;';
  } else if (showDropdownArrow) {
    return `
      cursor: pointer;
      &[aria-expanded="true"] {
        cursor: text;
      }
    `;
  }
  return 'cursor: text;';
};

export const Container = styled.div`
  border: 1px solid;
  border-radius: ${theme.borderRadius.S};
  position: relative;
  transform: border-color ${theme.transitions.times.default};
  ${getStateStyles}
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Input = styled.input`
  appearance: none;
  background: none !important;
  border-radius: ${theme.borderRadius.S};
  border: none;
  padding: ${theme.spacing.single};
  padding-right: ${theme.spacing.double};
  min-width: 100px;
  text-align: left;
  width: 100%;

  ::placeholder {
    color: ${theme.colors.blacks[40]};
    -webkit-text-fill-color: ${theme.colors.blacks[40]};
  }
  
  ${getCursorStyles}
  ${getInputSizeStyles}
`;

export const IconContainer = styled.div`
  align-items: center;
  background: transparent;
  display: flex;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: ${({ inputSize }) => getSizeStyle(iconRight, inputSize)};
  transform: translateY(-50%);
`;

export const ErrorContainer = styled.div`
  color: ${textColors.error};
  font-size: ${theme.fontSize.S};
  text-align: left;
`;
