import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Card = styled.div`
  background: white;
  box-shadow: ${() => theme.shadows[40]};
  color: ${() => theme.colors.black};

  padding: ${() => theme.sizes.padding[60]};
  margin-bottom: ${() => theme.sizes.padding[60]};

  ${() => theme.media.medium`
    border-radius: ${theme.sizes.borderRadius[80]};
  `}
`;

export default Card;
