import { compose } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import {
  BEGIN_FEEDBACK_MUTATION,
  END_GIVE_FEEDBACK_SESSION_BY_INVITATION_MUTATION,
  FEEDBACK_TIMELINE_QUERY,
  PICK_ACTIONS_ITEMS_FOR_SKILL_MUTATION,
  RATE_FEEDBACK_MUTATION,
  RECURRING_SELECT_SKILLS_IDS_MUTATION,
  RATE_SKILL_MUTATION,
  RATE_SKILL_BATCH_MUTATION,
  SET_FULL_NAME_MUTATION,
  SET_WORK_RELATIONSHIP_FROM_INVITATION_MUTATION,
  SET_QUALITIES_MUTATION,
  TRANSFER_FEEDBACK_DATA_FROM_SESSION_TO_USER,
} from 'graphql-queries/queries';
import {
  FEEDBACK_ALREADY_OVER,
  CANT_RATE_SELF,
} from '@matterapp/matter-errors';
import ResponseFlow from '../components/ResponseFlowTimelinePage/ResponseFlow';
import { withResponseFlowTimeline } from 'hocs';
import { Resources } from '@matterapp/routing';
import Toast from 'components/Toast/Toast';

const getSortedSkills = (skillsToRate) => {
  return [...skillsToRate].sort((a, b) => {
    const first = a.skill.name;
    const second = b.skill.name;
    return first.toLowerCase() > second.toLowerCase() ? 1 : -1;
  });
};

/**
 * Calculates the last step the reciever did if they left the feedback and returned.
 * If a skill have both a note and rating, then the last step is the next step with no rating.
 * @param { Array } skillsToRate: The list of skills in the response flow.
 * @returns { Number } The calulated current step of the timeline.
 */
const getCalculatedCurrentStep = (skillsToRate = []) => {
  let currentStep = 1;
  skillsToRate.forEach((skillToRate, index) => {
    const stepNumber = index + ResponseFlow.Timeline.SKILL_START_INDEX_OFFSET;
    if (skillToRate.currentNote && skillToRate.currentRating) {
      currentStep = stepNumber + 1;
    } else if (skillToRate.currentRating) {
      currentStep = stepNumber;
    }
  });
  return currentStep;
};

/**
 * Checks if the given error is a CANT_RATE_SELF error.
 * @param { Object } error: The error object to check.
 * @returns { Boolean } If error is CANT_RATE_SELF.
 */
const isSelfRateError = (error) => {
  if (error) {
    if (error.graphQLErrors && error.graphQLErrors[0]) {
      const errorName = error.graphQLErrors[0].name;
      if (errorName === CANT_RATE_SELF) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Helper to keep track of currently saving request.
 * @param { Object } actions: Actions props passed down from HOC component containing the redux actions to save.
 * @param { Object } promiseCallback: The promise returned from the mutation.
 */
const saveTimelineData = (actions, promiseCallback) => {
  const { saveTimeline, saveTimelineComplete } = actions;
  const saveHash = Math.random()
    .toString(36)
    .substring(7);
  saveTimeline(saveHash);
  promiseCallback.then(
    () => {
      saveTimelineComplete(saveHash);
    },
    (error) => {
      console.error(error);
      saveTimelineComplete(saveHash);
    }
  );
};

/**
 * Gets data for the response flow timeline and generates the
 * `initializeData` object to pass into redux for the timeline.
 */
const withTimelineQuery = graphql(FEEDBACK_TIMELINE_QUERY, {
  props: ({ data, ownProps }) => {
    const { cantRateSelfPath, history, nextStepPath, queryParams } = ownProps;
    const { loading, error, giveFeedbackSession } = data;
    const baseProps = { loading, error, mutations: {} };
    const { id, receiver, skillsToRateWithMetaData } =
      giveFeedbackSession || {};

    if (data.invitation && data.invitation.owner && data.invitation.owner.isDeleted) {
      history.replace(Resources.homeMain.path());
      Toast.error('Sorry, that user is no longer on Matter');
    }

    if (isSelfRateError(error)) {
      history.push(cantRateSelfPath);
      return;
    }

    if (!loading && !giveFeedbackSession) {
      history.push(nextStepPath);
    }

    if (loading || error || !(giveFeedbackSession && receiver)) {
      return {
        ...baseProps,
        initializeData: {
          qualitiesToRate: [],
          receiver: {},
          skillsToRate: [],
          currentSkillsIds: [],
        },
      };
    }
    const { overallRating, selectedSkillIds } = giveFeedbackSession;
    const sortedSkills = getSortedSkills(skillsToRateWithMetaData);
    const skillsToRate = [];
    let currentStep = 1;

    if (queryParams.rating && selectedSkillIds) {
      if (skillsToRate.length === 0) { currentStep = 1; }

      for (const skillToRate of sortedSkills) {
        for (const skillId of selectedSkillIds) {
          if (skillId === skillToRate.skill.id) {
            skillsToRate.push(skillToRate);
          }
        }
      }
  
      skillsToRate.forEach(skillToRate => {
        if (skillToRate.currentRating) {
          currentStep = currentStep + 1;
        }
      });
    }

    return {
      ...baseProps,
      recurringFeedback: data.invitation.recurringFeedback,
      initializeData: {
        currentStep: getCalculatedCurrentStep(skillsToRateWithMetaData),
        currentRecurringStep: currentStep,
        feedbackId: id,
        qualitiesToRate: (receiver && receiver.qualitiesToRate) || [],
        receiver,
        skillsToRate: skillsToRateWithMetaData,
        currentSkillsIds: selectedSkillIds || [],
        feedbackRating: overallRating || queryParams.rating,
        currentSkillsToRate: skillsToRate,
      },
    };
  },
});

/**
 * Defines the `setWorkRelationship` mutation function.
 * @param { Object } setWorkRelationshipVariables: {
 *   @param { Boolean } isWorkingTogether: If giver and receiver are working together.
 * }
 * @returns { void }
 */
const withWorkRelationsShipMutation = graphql(
  SET_WORK_RELATIONSHIP_FROM_INVITATION_MUTATION,
  {
    props: ({ mutate, ownProps: { actions, mutations } }) => ({
      mutations: {
        ...mutations,
        setWorkRelationship: ({ variables, isWorkingTogether }) => {
          saveTimelineData(
            actions,
            mutate({
              variables: {
                ...(variables || {}),
                isWorkingTogether: isWorkingTogether || false,
              },
            })
          );
        },
      },
    }),
  }
);

/**
 * Defines the `rateFeedback` mutation function.
 * @param { Object } rateFeedbackVariables: {
 *   @param { String } feedbackId: The id of the feedback.
 *   @param { Number } rating: The rating of the feedback.
 * }
 * @returns { void }
 */
const withRateFeedbackMutation = graphql(RATE_FEEDBACK_MUTATION, {
  props: ({ mutate, ownProps: { actions, mutations } }) => ({
    mutations: {
      ...mutations,
      rateFeedback: ({ feedbackId, rating }) => {
        const promise = mutate({
          variables: {
            feedbackId,
            rating,
          },
        });
        saveTimelineData(
          actions,
          promise,
        );
        return promise;
      },
    },
  }),
});

/**
 * Defines the `selectSkillsForFeedback` mutation function.
 * @param { Object } selectSkillsForFeedbackVariables: {
 *   @param { String } feedbackId: The id of the feedback.
 *   @param { Array } skillIds: The list of skills selected.
 * }
 * @returns { void }
 */
const withRecurringSelectSkillsIdsMutation = graphql(RECURRING_SELECT_SKILLS_IDS_MUTATION, {
  props: ({ mutate, ownProps: { actions, mutations } }) => ({
    mutations: {
      ...mutations,
      selectSkillsForFeedback: ({ feedbackId, skillIds }) => {
        const promise = mutate({
          variables: {
            feedbackId,
            skillIds,
          },
        });
        saveTimelineData(
          actions,
          promise,
        );
        return promise;
      },
    },
  }),
});

/**
 * Defines the `rateSkill` mutation function.
 * @param { Object } rateSkillVariables: {
 *   @param { String } feedbackId: The id of the feedback.
 *   @param { Number } rating: The rating of the skill.
 *   @param { String } skillId: The id of the skill.
 *   @param { String } note: The note of the skill.
 * }
 * @returns { void }
 */
const withRateSkillMutation = graphql(RATE_SKILL_MUTATION, {
  props: ({ mutate, ownProps: { actions, mutations } }) => ({
    mutations: {
      ...mutations,
      rateSkill: ({ feedbackId, rating, skillId, note, variables }) => {
        const promise = mutate({
          variables: {
            ...(variables || {}),
            feedbackId,
            skillId,
            rating: rating || 0, // TODO: change 0 to null when backend supports null scores.
            note: note || null,
          },
        });
        saveTimelineData(
          actions,
          promise,
        );
        return promise;
      },
    },
  }),
});

const withRateSkillBatchMutation = graphql(RATE_SKILL_BATCH_MUTATION, {
  props: ({ mutate, ownProps: { actions, mutations } }) => ({
    mutations: {
      ...mutations,
      rateSkillBatch: (feedbackId, skills) => {
        const promise = mutate({
          variables: {
            feedbackId,
            skills,
          },
        });
        saveTimelineData(
          actions,
          promise,
        );
        return promise;
      },
    },
  }),
});

/**
 * Defines the `pickActionItemsForSkill` mutation function.
 * @param { Object } rateSkillVariables: {
 *   @param { String } feedbackId: The id of the feedback.
 *   @param { Number } rating: The rating of the skill.
 *   @param { String } skillId: The id of the skill.
 *   @param { String } note: The note of the skill.
 * }
 * @returns { void }
 */
const withPickActionItemsForSkilllMutation = graphql(
  PICK_ACTIONS_ITEMS_FOR_SKILL_MUTATION,
  {
    props: ({ mutate, ownProps: { actions, mutations } }) => ({
      mutations: {
        ...mutations,
        pickActionItemsForSkill: ({
          feedbackId,
          abilityIds,
          skillId,
          variables,
        }) => {
          const promise = mutate({
            variables: {
              ...(variables || {}),
              feedbackId,
              skillId,
              abilityIds: abilityIds || [],
            },
          });
          saveTimelineData(
            actions,
            promise,
          );
          return promise;
        },
      },
    }),
  }
);

/**
 * Defines the `setQualities` mutation function.
 * @param { Object } setQualitiesVariables: {
 *   @param { String } feedbackId: The id of the feedback.
 *   @param { Array } qualityIds: List of selected quality ids.
 * }
 * @returns { void }
 */
const withSetQualitiesMutation = graphql(SET_QUALITIES_MUTATION, {
  props: ({ mutate, ownProps: { actions, mutations } }) => ({
    mutations: {
      ...mutations,
      setQualities: ({ feedbackId, qualityIds, variables }) => {
        saveTimelineData(
          actions,
          mutate({
            variables: {
              ...(variables || {}),
              feedbackId,
              qualityIds,
            },
          })
        );
      },
    },
  }),
});

/**
 * Defines the `setFullName` mutation function.
 * @param { String } fullName: The full name to set.
 * @returns { String } The full name that was set.
 */
const withSetFullNameMutation = graphql(SET_FULL_NAME_MUTATION, {
  props: ({ mutate, ownProps: { mutations } }) => ({
    mutations: {
      ...mutations,
      setFullName: async (fullName) => {
        const { data } = await mutate({
          variables: { fullName },
        });
        return data.setFullName;
      },
    },
  }),
});

/**
 * Defines the `beginFeedback` mutation function.
 */
export const withBeginFeedbackMutation = graphql(BEGIN_FEEDBACK_MUTATION, {
  props: ({
    mutate,
    ownProps: { cantRateSelfPath, history, match, mutations },
  }) => ({
    mutations: {
      ...mutations,
      beginFeedback: async () => {
        const { invitationUuid } = match.params;
        try {
          await mutate({
            variables: { invitationUuid },
          });
        } catch (error) {
          if (isSelfRateError(error)) {
            history.push(cantRateSelfPath);
            return;
          }
          throw error;
        }
      },
    },
  }),
});

/**
 * Defines the `transferFeedback` mutation function.
 */
export const withTransferFeedbackMutation = graphql(TRANSFER_FEEDBACK_DATA_FROM_SESSION_TO_USER, {
  props: ({
    mutate,
    ownProps: { match, mutations },
  }) => ({
    mutations: {
      ...mutations,
      transferFeedback: async () => {
        const { invitationUuid } = match.params;
        await mutate({
          variables: { invitationUuid },
        });
      },
    },
  }),
});

/**
 * Defines the `endFeedback` mutation function.
 */
const withEndFeedbackMutation = graphql(
  END_GIVE_FEEDBACK_SESSION_BY_INVITATION_MUTATION,
  {
    props: ({ mutate, ownProps }) => {
      const {
        history,
        match,
        mutations,
        nextStepPath,
      } = ownProps;
      return {
        mutations: {
          ...mutations,
          endFeedback: async () => {
            const { invitationUuid } = match.params;
            try {
              await mutate({
                variables: { invitationUuid },
              });
              history.push(nextStepPath);
            } catch (error) {
              let isAlreadyOver = false;
              const { graphQLErrors } = error;
              if (graphQLErrors && graphQLErrors.length > 0) {
                const [firstError] = graphQLErrors;
                isAlreadyOver = firstError.name === FEEDBACK_ALREADY_OVER;
              }
              if (isAlreadyOver) {
                Toast.error('You already gave your feedback!');
                history.push(nextStepPath);
              } else {
                Toast.error('Something went wrong');
                console.error(error);
              }
            }
          },
        },
      };
    },
  }
);

export default compose(
  withResponseFlowTimeline,
  withTimelineQuery,
  withWorkRelationsShipMutation,
  withRecurringSelectSkillsIdsMutation,
  withRateFeedbackMutation,
  withRateSkillMutation,
  withRateSkillBatchMutation,
  withPickActionItemsForSkilllMutation,
  withSetFullNameMutation,
  withSetQualitiesMutation,
  withBeginFeedbackMutation,
  withTransferFeedbackMutation,
  withEndFeedbackMutation,
);
