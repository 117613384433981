import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const ColoredPath = styled.path`
  fill: currentColor;
`;

export const TopCloud = styled(ColoredPath).attrs({
  d: `M2557.7,1170.6c-9.6,2.9-23.7-4.4-34.9-2.7c-14.2-0.7-26.8,5-41.1,4.2
	c-9.1-17.3-26-11.7-31.5-22.7c-8.2-41.1-55.9-53.9-101.7-38.4c-5.3,0.9-10.1-1.7-14.5-3.8c-12.8-4.3-27.8-2.7-41-0.6
	c-3.7,1.8-10.7,4.6-12-0.7c-5.7-10.1-6.6-21.5-16.4-30c-6.8-6.9-16.9-12.7-28-14.3c-11.6-3-23-9-35.7-6.8c-3.1,0.6-5.1-0.4-6.5-2.4
	c-10.9-13.1-43.6-27.1-63.6-26.9c-7.9,0.7-16.3,3.9-24.1,1.9c-14.7-6-32.8-7.6-48.4-2.2c-7.3,1.7-12.3,9.9-20.6,6
	c-8.1-7-32.9-8.1-31.7-14.8c0.9-28.4-13-48.3-39.4-67c-19.9-9.8-43.5-17.9-67.3-18.7c-17-1.7-35.5,9.8-49.8-1.7
	c-0.7-0.5-1.5-1.1-2.3-1.2c-5.1-0.7-8.4-4.3-13.7-4.5c-46.6-6.8-36.6,14.3-58.4,2.6c-10.6-5.5-23.8-12.2-36.7-7.6
	c-3.1,1-6.2,1.7-9.7,1.3c-1.6,0.1-3.1,0.4-4.7,0.5c-2.1-0.5-4.2-0.8-6.4-1c-8.9-2.6-21.9,1.8-29.3-1.6c-1.4-2.4-4.6-4.1-4.5-7.1
	c0-0.8-0.7-1.7-1.4-2.4c-7.6-7.7-15.9-16-26.6-21.1c-6.2-2.9-15.4-3.6-19.5-8.5c-1.2-3,0.1-6.5-2.9-9.2c-0.8-7-9.9-10.1-16.7-13.1
	c-6.1-1.4-14.2,0.6-18.3-3.7c-7.5-6.7-14.6-14-23.3-19.7c-11.5-5.6-25.1-7.4-38.6-8.5c-14.3-1.5-22.5,5.5-36.2-2.4
	c-10.1-6.6-22.5-10.8-35.8-11.8c-6.9-0.5-13.6-1.8-20.4-0.1c-10.3,1.4-19.8,5.6-29.9,6.9c-6.4-1.9-7.6-9.2-13.8-11.8
	c-3.8-1.2-6.9-3.5-9.4-6c0.6-0.1,1-0.1,2-0.2c-6.3-3.4-11.5-6.3-18.1-8.2c-2.4-1.4-3.1-2.7-2-4.9c1.4-5.5,2.4-12.8-0.7-18.1
	c-1.8-3.9-3.4-7.9-6-11.5c-1.4-2-4.3-3.3-3.5-6c-2.9-5.6-5.9-11.4-9.4-16.8c-2.4-4.5-9.4-5.2-14-7.8c-17.4-9.2-38.5-12.8-58.9-10.1
	v-0.8c-0.2,0-0.5-0.1-0.7-0.1c-4.3-0.6-9.2,0.1-13.4-0.1c-6.1-0.7-15.5,10.3-20.4,14.2c-5.7,4.5-7,14.9-12.6,18.2
	c-7.3-4.9-12.1-5.1-18.6-5.6c-6.8-0.2-13.3-3.4-20.1-1.7c-17.3,4.3-35.2,8.6-47.4,22.8c-2.8,3.1-7.2,2.7-9.7,6.3
	c-2.4,4.2-4.6,7.9-5.6,12.6c-13.9,27-18.1,18.8-11.2,51.8c0.2,2.6,0.2,2.7-2.4,3.3c-20.3,3-32.9,19.8-45.5,34.2
	c-12.8-14.9-33.7-18.1-52.2-20.4c-19.3-1-35,14.1-51,22.9c-7.8,3.6-13.6-9.9-22.8-8.2c-40.1,1.3-51.6,12-73.8,43.3
	c-5.8-2.5-11.3-7.9-17.9-4c-15.8,3.5-24.7,18.2-31.4,31.6c-1.7,4-6.5,6-10.3,7.5c-15.8,10.2-28.9,28.3-36,45.6
	c-20.3-13.5-48.3-15.9-69.6-3.3c-2.1,1.4-5.1,2-7.4,1.2c-6-2.7-12.7-2.8-18.9-4.6c-0.7-7-1.6-13.5-6.7-18.9
	c-5.3-6.7-9.3-14.5-16.3-19.7C642,927.7,621.7,915,598,917c-12.3-0.8-23.4,4.4-33.5,10.8c-5.2,3.6-8.6-2.2-13.6-2.8
	c-10.8-1.9-21.5,2.2-31.9,4.7c-3.7,0.9-3.4-4.8-4.6-7.1c-4.5-16.1-20.5-24.8-32.4-35.2c-8.5-6.8-19.3-8.6-29.5-11.4
	c-18.3-5.2-21.9,10.2-35-9.1c-5.7-8.7-15.7-14.1-26-13c-9.8,0.3-19.6-1.4-29.5,0.4c-5.9-0.1-12.3,5.1-17.5,3.3
	c-18.6-10.9-44.2-5.7-53.1,15.1c-7.1-6.9-15.3-11.8-23.9-16.7c-5.3-5-16.1-1.3-16.9-10.6c-1.4-9.8-3.1-19.4-7.2-28.6
	c-24.3-52.6-75.8-69.9-127.1-43c-4.2-9.3-15-11.8-23.9-14.9c-5.4-2.3-10.6,0.7-16.1,1.1c-5.6,0.2-9.7,3.4-13.6,6.7
	c-3.5,1.6-10.4,14.8-13.3,11.2c-5.3-3.4-9.2-8.8-14.5-12c-2.9-1.1-5.8-2-8.4-3.8c-6.4-1.6-20.9-1.4-26.5,2.3l0,0
	c-11.9,0.9-24,0.6-35,4.5c-7.1,2.6-14.5,4.7-21.7,6.9c-3.8,1.4-7.1,4.8-11.3,4.8c-3.7,1.4-5.5,5.4-8.5,7.6
	c-5.2,4.9-15.3,7.2-17.5,13.7c-3.7,4.2-5.9,14.8-15.4,10.2c-18-8-40.7-6.6-60.7-1.5c-13.8,3-23.2,11-33.7,17.5
	c-4.7,3-9.3,6-12.1,10.2c-5.3,6.5-2.5,14-7.2,20.5c-22.4-2.3-54.5,6.2-69.8,18.1c-9,9.3-14.7,20.9-14.6,32c-1.1,4.2,3.2,10.1-2.6,13
	c-23.5-11-54.4-3.3-72.1,11.2c-14.9-3-31.2-5-44.7,2.5c-9.2,3.8-20.8,7.7-22.6,15.8c-13.4,0.6-28.3,0-39.4,6.5
	c-21.8,10-30,25.5-27.7,42.7c-13.8,8.3-23.2,20.6-21.1,33.3c-30-2.4-57.6,8.6-67.3,29.1c-23.8-4.1-38.1,1.8-51.5,16
	c-12.5-4.5-25.4-7.3-38-7.1v-0.4c-1.5,0.2-2.9,0.4-4.4,0.6c-3.1,0.2-6.2,0.7-9.3,1.4c-8.8,1.4-17.5,3.3-25.7,6.1
	c-11.9,6.7-25.4,11.6-37.3,18.2c-4.8,2.2-10.9-4.3-17.1-3.2c-6.3,0.7-13.1-0.7-19.1,1.2c-1.2,0.5-2.1,0.3-2.9-0.3
	c-18.6-21-60.6-9-78.6,7.8c-29.2-5.1-43.3-5.9-70.4,6.5c-7.1,3.9-16.6,7.6-20.1,13.6c-3,6.2-8.7,11.6-11.5,17.9
	c-1.1,2.1-3,2.8-6,2.8c-5.8-0.1-10.2,3-15.3,4.5c-6.8,2-13.1,4.7-17.9,8.7c-1.5-0.4-2-1-2.9-1.8c-3.8-4.2-9.8-6.3-16.3-7.8
	c-11.1-2.2-22.5-4.4-34.2-2.7c-10,1.7-20.3,3.1-28.7,7.5c-1,0.5-1.8,0.6-3,0.2c-25.7-9.4-62.4-5.4-78.4,13.1
	c-13.1-7.9-34.8,0-41.2,10.2c-1.6,2.5-2,2.9-5.8,2.9c-16.5-0.1-27.6,9-37,17.3c-1.4,0.9-2,2.4-3,3.6l0.1,0.9l1280.1-0.6v-0.1h1279.5
	v0.1H2560v-4.6v-27.3C2559.2,1169.2,2558.4,1169.9,2557.7,1170.6z M1591.2,854.9c-0.3-0.4-0.5-0.8-0.8-1.2c0.2-0.1,0.4-0.2,0.6-0.3
	c0.2,0.5,0.4,0.9,0.6,1.4C1591.5,854.8,1591.3,854.8,1591.2,854.9z M2179.2,1048.5c-0.5-0.5-0.9-1-1.4-1.5c0.3-0.2,0.5-0.3,0.8-0.5
	c0.5,0.5,1.1,0.9,1.6,1.4C2179.9,1048.1,2179.6,1048.3,2179.2,1048.5z`,
})``;

export const BottomCloud = styled.path.attrs({
  d: `M-1097.4,1148.8c-1,0.1-2,0.1-3,0.2C-1099.4,1148.8-1098.4,1148.8-1097.4,1148.8z
	M2560,1200.4c-287.2,0-1002,0-1280.1,0c0,0-1279.8,0-1279.8,0v-0.1H-1280l17.6-6c13.7-5,30.4,1.8,41.8-6.5
 c30-10.8,51.9,3.3,57.4-2.3c5.8-8.3,18.1-13,25.3-19.4c7.9-10.2,22.1-15.7,37.5-17c-0.4,0.1-0.9,0.2-1.3,0.4
 c1.9,0.4,4.7,0.1,5.6-0.6c-0.4,0-0.8,0-1.3,0c8.4-0.4,17.1,0.4,25.3,2.3c35.6-12.9,37.7,4.7,60.1-0.1c32.7-10.3,43,10.8,55,3.8
 c6.1-21.9,55.9-31.5,77.6-12.6c11.6,1,23.1-6.7,35.8-3.5c32.5,2.9,12.2-11.3,69-2.8c3.9-6.1,9.4-13.5,20.1-14.9
 c2.7-0.4,2.7-0.4,3.1-2.2c4.6-10.4,21.2-17.7,36.3-20.7c12.3-2,25.3,1.1,37.3,1.1c16.6-3.8,33.6,0.9,49.7,1.4
 c12.6-3.9,27.3-3.4,39.7,0.7c8.8-2.7,19.3-1.3,26.1,3.3c2.8,2.5,7-1.7,11-1.9c15.3-3.6,29.9,2.9,40.5,9.7
 c10.9-25.1,72.2-23.9,78.3,2.6c13.1-1.9,23.5,12.4,33.1,3.8c7.1-3.2,17.7-3.3,24.8,0.2c9.6-18,37-25,63.7-25.2
 c18.2,1.3,27.5,9.4,44-0.9c27.5-13.3,69.1-12.6,94,3.1c12.8,8.7,4.5-19.9,50.5-25.8c7.3-2.1,1.6-8.1,3.2-11.9
 c7.3-26.7,41.1-49.7,83.2-48.7c44.4,1.3,24.8,6.2,54.5-21.7c51.1-42.3,117.2-39.9,174.9-12.8c1.3,0.6,1.4,0.5,2.5-0.5
 c2.7-2.8,5.9-5.1,9.5-6.6c25.1-13.5,50.3,1.6,62.9,24.3c26.3-31.3,75.6-48.3,109.1-18.3c9.5,7.5,18.2,15.7,24.3,26.3
 c2,2.1,3.6,7.8,7.1,5.4c28.8-17.8,57.1,7.8,72,31.6c0.9,1.3,0.9,1.3,2.4,0.8c8.6-3.2,17.7-4,26.8-3.4c9.2,1,17.5,5.6,24.9,10.9
 c13.3-0.3,27.1-7.4,40.8-5.1c31,3.8,66.9,34.5,58.1,68.1c7.2-3.1,14.4-6.3,21.9-8.2c8.6-2.5,17.5-0.7,26.1-2.5
 c10.8-1.9,21.1,4.1,31.4,6.6c24.3,7.5,43.4,23.6,58.1,44.1c21.3-23.1,58.1-15.5,75.4,8.7c30.9-43.3,76.5-37.2,110.3-1.1
 c13.1-11.1,26.1-26.4,44.1-28.3c15.1-1.3,31.5-0.3,44.1,9c2.2,1.2,7.1,6.1,9.2,3.5c0.5-0.8,0.9-1.7,1.6-2.3
 c10.3-11.3,26.3-18.4,40.6-9.8c5.3,1.4,11.9,11,15.6,10.1c9.4-10.3,23-17.3,37.1-13.4c11.4,5.9,23.8,13.7,26.9,27
 c6.7-3.7,12.1-7.5,19.9-3c1.2,0.9,1.3,0.9,2.4-0.1c7.1-6.3,18.1-5,27-5.5c16.7-0.9,24.9,16.2,26.6,30.3c9.4-2.9,21.1-4.6,28.2,3.5
 c-0.2-5.6,5-10.4,9.5-12.9c8.2-2.4,19.5-2.5,26.8,2.1c0.8,0.7,0.8,0.7,1.7,0.1c5-3,9.7-0.4,14.1,1.9c7.5-19.1,31.1-24.7,45.3-8.9
 c15.7-9.6,23.8-14.7,40-0.2c0.9,0.6,1.2,2.7,2.6,1.9c25.3-15.8,47.2-10.1,67.7,10c22.5-23.6,58.1-27.5,78.4,0.9
 c12.3-16,38.6-20.8,53.3-5.7c21.8-29.4,63.9-42,97.5-26.2v-0.4c13.2-6.2,32.1-5.8,45.1,0.8c8.7,5.8,13.6,1.2,23.2,0.5
 c14.1,0.1,28.6,4,33.6,14.1c3.7-2,9.3-1.9,13.1-0.1c5.1,4.8,10.5-3.2,17.5-2.1c3.2-14.8,35.6-15.5,41.4-1.4
 c5.6-3.8,13.3-7.4,21.4-5.4c2.1,0.1,4.4,2.5,5.8,1.1c3.6-2.6,9.1-3.4,13.8-1.8c6.5-2.3,14.3-2.6,21-0.4c15.1-1.6,30.6-1.6,46-1.4
 c7.9,1.6,16.7,5.8,19.2,11.6c0.2,1,0.2,1,1.6,1.2c5.6,0.8,8.5,4.9,10.6,8.3c30-4.7,19.3,3.2,36.4,1.6c6.7-1.8,12.8,2.6,18.9,2
 c11.5-10.6,37.8-5.2,41,7.1c6.3,3.9,11.8-7.9,29-2.1c11.8,2.7,12.9-7.1,31.7,0.1c4.3-1.1,8.9-1.5,13.4-1.3c-0.2,0-0.4,0-0.7,0
 c0.5,0.4,2,0.5,2.9,0.3c-0.2-0.1-0.5-0.2-0.7-0.2c8.2,0.7,15.6,3.9,19.8,9.5c3.8,3.6,10.3,6.3,13.4,10.9c2.9,3.1,14.5-4.8,30.3,1.3
 C2268,1202.1,2549.2,1198.1,2560,1200.4z M-773.4,1180.3c-0.2-0.4-0.5-0.3-0.9-0.1C-774,1180.6-773.8,1180.4-773.4,1180.3z
	M391.1,990c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1,0-0.1,0.1-0.2,0.2c0.2,0.2,0.4,0.5,0.5,0.7C390.9,990.2,391,990.1,391.1,990z
	M2030.1,1189.2c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.1,0.2C2029.9,1189.3,2030,1189.4,2030.1,1189.2z`,
})`
  fill: ${theme.colors.white};
`;

export const TopLeftRing = styled(ColoredPath).attrs({
  d: `M17.4,37.3c0,7.5-4.2,12.6-10.4,12.7c-9.6,0.1-12.6-4.8-12.9-12c-0.3-6.3,5.6-11.8,12.4-12
	C11.5,25.8,18.5,32.1,17.4,37.3z M5.7,33.8c-2.4,0-4.4,1.9-4.5,4.4c-0.1,2.5,1.8,4.6,4.1,4.6c2.5,0.1,5-1.8,5.1-3.8
	C10.5,36.8,7.9,33.8,5.7,33.8z`,
})``;
export const TopDot1 = styled(ColoredPath).attrs({
  d: `M623,57.7c0-5.3,3.7-7.7,6.7-7.7c3.2,0,7.6,4,7.5,6.8c-0.2,3.8-3.7,7.7-7,7.5
	C627,64.1,622.2,61.4,623,57.7z M629.5,60c0.4,0,0.8,0,1.2,0c0.5-0.9,1.6-1.9,1.5-2.8c-0.1-0.8-1.3-1.5-2-2.3
	c-0.8,0.8-2.1,1.5-2.3,2.4C627.7,58.1,628.9,59.1,629.5,60z`,
})``;
export const TopDot2 = styled(ColoredPath).attrs({
  d: `M749.9,99.2c4.9,0,8.5,3.4,8.6,7.9c0,4.5-3.8,8.5-8.3,8.5c-4.4,0-8-3.5-8-7.8
	C742.1,102.7,745.3,99.2,749.9,99.2z M750.8,109.4c0.7-1.1,1.4-1.7,1.2-2.2c-0.1-0.6-0.9-1.1-1.4-1.6c-0.5,0.5-1.3,1-1.3,1.5
	C749.3,107.7,750,108.3,750.8,109.4z`,
})``;
export const TopRightRing = styled(ColoredPath).attrs({
  d: `M1211.4,54.5c-5.1,0-8.8-3.7-8.8-8.8c0-3.9,3.8-7.5,7.9-7.4c4.7,0.1,8.6,3.8,8.6,8.1
	C1219.1,50.4,1215.2,54.5,1211.4,54.5z M1214.6,46.7c0.1-2.4-1.2-4-3.8-4c-1.9,0-3.8,0.5-4.1,2.8c-0.3,2.1,2,4.1,4.7,4.3
	C1213.7,50.1,1214.4,48.6,1214.6,46.7z`,
})``;
export const TopRightSquare1 = styled(ColoredPath).attrs({
  d: `M1150.9,113.1c-0.5,0.7-1.1,1.3-1.6,2c-0.3,0.4-0.2,1.2-0.6,1.3
	c-3.4,1.5-6.8,3-10.3,4.3c-2.2,0.8-3.5-0.7-4.3-2.4c-0.7-1.5-1.3-3.1-1.5-4.7c-0.7-6.4-1.3-12.9-1.8-19.3c-0.2-2.9,2.6-5,5.1-3.5
	c1.4,0.9,2.7,0.7,4.1,0.9c3,0.3,5.9,0.9,8.8,1.5c0.6,0.1,1.4,0.7,1.7,1.2c0.7,1.7,1.3,3.5,1.7,5.4c0.2,1.2-0.3,2.5-0.3,3.8
	c0,1.2,0,2.5,0.2,3.7c0.4,1.7,1.3,3.2,1.6,4.9c0.1,0.5-1.2,1.2-1.9,1.8C1151.5,113.7,1151.2,113.3,1150.9,113.1z`,
})``;
export const TopRightSquare2 = styled(ColoredPath).attrs({
  d: `M1240.2,122.4c-1.6-3.7-1.6-7.5-1.1-11.4c0.3-2.4,0.8-4.8,1.4-7.2
	c0.1-0.5,0.8-1.1,1.3-1.1c4.2-0.4,8.3-0.7,12.5-0.8c0.6,0,1.2,1.1,1.8,1.7v0.2c-0.2,6.2-0.3,12.3-0.5,18.5
	C1250.6,122.4,1245.4,122.4,1240.2,122.4z`,
})``;
export const LeftRing = styled(ColoredPath).attrs({
  d: `M48.6,607.8c-7.3-0.1-15.1-8.1-15.1-15.4c0-7.3,8.1-14.7,15.8-14.5c8.8,0.2,16.4,7.7,16,15.8
	C65.1,601.7,57.6,608,48.6,607.8z M49.9,584c-3.5,3.5-7.4,6.1-7,7.5c0.8,2.9,3.8,5.3,5.9,7.9c2.3-2,6.1-3.8,6.6-6.2
	C55.9,591,52.6,588.2,49.9,584z`,
})``;
export const LeftDot1 = styled(ColoredPath).attrs({
  d: `M135.3,525.7c0,3.1-2.2,5.4-5.2,5.4c-2.8,0-5-2-5-4.8c-0.1-3.2,2.5-6.2,5.2-6.2C133.2,520.2,135.3,522.5,135.3,525.7z`,
})``;
export const LeftDot2 = styled(ColoredPath).attrs({
  d: `M92.9,642.7c-0.5,2.5-1.4,4-3.3,3.8c-0.9-0.1-2.1-1.3-2.3-2.2c-0.3-1.9,1.5-3.9,3.2-3.5C91.5,641,92.2,642.2,92.9,642.7z`,
})``;
export const LeftDot3 = styled(ColoredPath).attrs({
  d: `M397.7,676.5c1.5,0.1,2.7,0.7,2.8,2.6c0.1,1.8-1.2,3.3-3,3.3s-2.7-1-2.9-2.8S395.7,676.6,397.7,676.5z`,
})``;
export const LeftDot4 = styled(ColoredPath).attrs({
  d: `M262,736.3c-3.5,0-6-2.3-6-5.4c0-3.7,3.3-7.1,6.8-7c2.9,0.1,6,2.9,6,5.5
			C268.9,734.2,266.8,736.3,262,736.3z M265,730.9c0-0.3,0-0.6,0-0.9c-0.6-0.4-1.4-1.4-1.9-1.2c-1,0.3-1.8,1.2-2.6,1.8
			c0.6,0.6,1.2,1.7,1.7,1.6C263.2,732.1,264.1,731.4,265,730.9z`,
})``;
export const CenterDot = styled(ColoredPath).attrs({
  d: `M728,684.5c0-5.3,3.7-7.7,6.7-7.7c3.2,0,7.6,4,7.5,6.8c-0.2,3.8-3.7,7.7-7,7.5
	C731.9,690.9,727.1,688.2,728,684.5z M734.4,686.7c0.4,0,0.8,0,1.2,0c0.5-0.9,1.6-1.9,1.5-2.8c-0.1-0.8-1.3-1.5-2-2.3
	c-0.8,0.8-2.1,1.5-2.3,2.4C732.6,684.8,733.8,685.8,734.4,686.7z`,
})``;
export const RightLine = styled(ColoredPath).attrs({
  d: `M904.9,612c-0.6-0.3-1.3-0.5-1.3-0.8c-0.1-0.6,0.1-1.3,0.4-1.7c2.1-2.2,4.3-4.4,6.5-6.5
	c3.9-3.7,7.9-7.2,11.9-10.8c0.2-0.2,0.5-0.5,0.8-0.6c0.7-0.2,1.5-0.2,2.3-0.3c-0.1,0.7,0,1.5-0.2,2c-0.4,0.8-1,1.5-1.7,2.1
	c-5.5,5.1-11.1,10.1-16.7,15.2C906.3,611.1,905.6,611.5,904.9,612z`,
})``;
export const RightRing1 = styled(ColoredPath).attrs({
  d: `M1221.3,566.6c8.8,0,16.9,8.4,16.9,17.4c0,9.4-8.7,17.8-18.2,17.6
	c-7.4-0.1-15.3-6.9-15.2-19.7C1204.9,574.4,1213.2,566.6,1221.3,566.6z M1213,582.8c0.2,6.1,3.6,10.2,8.3,10.1
	c4.8-0.1,9.1-4.4,8.9-8.9c-0.2-6-4.6-8.4-9.4-8.9C1217.1,574.7,1212.9,579.2,1213,582.8z`,
})``;
export const RightRing2 = styled(ColoredPath).attrs({
  d: `M959.8,718.2c11.5-0.1,19.5,9.7,19.1,20c-0.3,7.4-8.3,16.9-18.1,16.2
	c-10.4-0.8-18.1-7.6-17.6-18.6C943.6,725.8,951,717.9,959.8,718.2z M961.1,748.7c6.4,0.1,12-5.5,12.1-11.2
	c0.1-7.1-5.8-13.1-12.9-13.2c-5.1-0.1-11.5,7.3-11.9,13.2C948.2,742.9,956.1,748.7,961.1,748.7z`,
})``;
export const RightDot1 = styled(ColoredPath).attrs({
  d: `M1259.2,447.5c2.7,0.1,4.9,2.4,4.8,5.1c-0.1,2.2-3.1,5.2-5.3,5.1c-2.5-0.1-4.5-2.4-4.4-5.1C1254.4,449.5,1256.5,447.3,1259.2,447.5z`,
})``;
export const RightDot2 = styled(ColoredPath).attrs({
  d: `M1104.9,533.6c0.1-5.5,2.7-8.1,8-8c4.1,0.1,7.1,3.4,7,7.8c-0.1,4.6-2.2,7.1-7.5,7.3C1108,541,1104.8,537.6,1104.9,533.6z`,
})``;
export const RightDot3 = styled(ColoredPath).attrs({
  d: `M1131.1,684c0,3.1-2.2,5.4-5.2,5.4c-2.8,0-5-2-5-4.8c-0.1-3.2,2.5-6.2,5.2-6.2C1128.9,678.4,1131.1,680.7,1131.1,684z`,
})``;
export const BottomRing1 = styled(ColoredPath).attrs({
  d: `M535.8,854.9c9.7,0.2,14.1,5.4,14.3,12.4c0.2,6.8-5.4,14.5-13.1,14.2
	c-7.7-0.3-13.8-5.2-13.9-12.9C523,861,527.7,855.2,535.8,854.9z M537.5,874.2c2.2,0.1,5.3-3.5,5.4-6.2c0.1-2.3-3.4-5.8-5.8-6
	c-3.1-0.2-6.3,2.9-6.5,6.3C530.4,871.6,533.2,874,537.5,874.2z`,
})``;
export const BottomRing2 = styled(ColoredPath).attrs({
  d: `M711.2,898.4c-1.1-10,9.2-17.9,18.2-16.7c8.9,1.2,15.6,6.1,15,18.7
	c-0.3,7.7-8.2,14.8-18.2,14.1C718.3,914,710.9,906.3,711.2,898.4z M726.8,889.3c-4.6,0.1-8.7,4-8.5,8.3c0.1,4.8,5.3,10.3,9.5,10
	c4.1-0.3,9.9-4.6,9.6-10.3C737,893,732.3,889.2,726.8,889.3z`,
})``;
