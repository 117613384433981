import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from '../../Avatar';

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BorderedWrapper = styled.div`
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border: 2px solid white;
  border-radius: 50%;
`;

const HeaderAvatar = ({
  url,
  email,
}) => (
  <AvatarContainer role="Menuitem">
    <BorderedWrapper>
      <Avatar
        size={32}
        url={url}
        email={email}
      />
    </BorderedWrapper>
  </AvatarContainer>
);

HeaderAvatar.defaultProps = {
  url: null,
  email: null,
};

HeaderAvatar.propTypes = {
  url: PropTypes.string,
  email: PropTypes.string,
};

export default HeaderAvatar;
