import React from 'react';
import PropTypes from 'prop-types';
import SVG, { Path } from '../../SVG';

const DEFAULT_SIZE = 24;

const Icon = ({ className, size, fill }) => (
  <SVG className={className} size={size} viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}>
    <Path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M13.7592 4.0524C13.0042 2.6492 10.9916 2.6492 10.2366 4.05239L3.24152 17.0539C2.52464 18.3863 3.48974 20.0015 5.00279 20.0015H18.993C20.5061 20.0015 21.4712 18.3863 20.7543 17.0539L13.7592 4.0524ZM12.9171 14.7413H11.0803L10.8894 8.29561C10.8852 8.1547 10.9983 8.03821 11.1393 8.03821H12.8582C12.9992 8.03821 13.1123 8.1547 13.1081 8.29561L12.9171 14.7413ZM11.9987 17.74C11.4573 17.74 11.0183 17.301 11.0183 16.7595V16.6851C11.0183 16.1436 11.4573 15.7046 11.9987 15.7046C12.5402 15.7046 12.9792 16.1436 12.9792 16.6851V16.7595C12.9792 17.301 12.5402 17.74 11.9987 17.74Z"
      fill={fill}
    />
  </SVG>
);

Icon.defaultProps = {
  size: DEFAULT_SIZE,
  fill: 'black'
};

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default Icon;