import { compose, withProps } from 'recompose';
import withRouter from 'hocs/withRouter';
import { OAUTH_RESOURCE_DETAILS } from '../app-consts';
import withPhotoSync from './withPhotoSync';
import Toast from 'components/Toast/Toast';
import withSyncPeerRecommendations from './withSyncPeerRecommendations';
import { mixpanel } from 'libs/tracking';
import { Resources } from '@matterapp/routing';

const OAUTH_CALLBACK_HANDLER_ROOT_KEY = '@OAUTH_CALLBACK';

const handleTrack = {
  success: mixpanel.createEventSender('socialConnectSuccess'),
  failure: mixpanel.createEventSender('socialConnectFailure'),
};

const getInitialHandlerState = () => ({});

export const withOAuthCallback = compose(
  withRouter,
  withProps(() => ({
    handleDisplayConnectSuccessBanner: ({ resourceName }) => {
      const { userFriendlyName } = OAUTH_RESOURCE_DETAILS[resourceName];
      Toast.success(`Successfully connected your ${userFriendlyName}`);
    },
    handleDisplayDisconnectSuccessBanner: ({ resourceName }) => {
      const { userFriendlyName } = OAUTH_RESOURCE_DETAILS[resourceName];
      Toast.success(`Successfully disconnected your ${userFriendlyName}`);
    },
    handleDisplayConnectErrorBanner: ({ resourceName }) => {
      const { userFriendlyName } = OAUTH_RESOURCE_DETAILS[resourceName];
      Toast.success(`Something went wrong. We could not connect to your ${userFriendlyName}. Try again`);
    },
  })),
  withSyncPeerRecommendations,
  withPhotoSync,
  withProps(({ location }) => ({
    setupOAuthCallbackHandler: ({
      successRedirect = location.pathname,
      failureRedirect = location.pathname,
      resourceName,
      displaySuccessBanner,
      displayFailureBanner,
    }) => {
      const newHandler = getInitialHandlerState();
      Object.assign(newHandler, {
        successRedirect,
        failureRedirect,
        resourceName,
        displaySuccessBanner,
        displayFailureBanner,
      });
      localStorage.setItem(
        OAUTH_CALLBACK_HANDLER_ROOT_KEY,
        JSON.stringify(newHandler)
      );
    },
  })),
  withProps(() => {
    const currentHandlerUnparsed = localStorage.getItem(
      OAUTH_CALLBACK_HANDLER_ROOT_KEY
    );
    const currentHandler = JSON.parse(currentHandlerUnparsed);
    return { currentHandler };
  }),
  withProps(
    ({
      history,
      syncPeerRecommendationsMutation,
      handlePhotoSync,
      handleDisplayConnectSuccessBanner,
      handleDisplayConnectErrorBanner,
      currentHandler,
    }) => ({
      handleOAuthCallback: async ({ isSuccessful }) => {
        const {
          successRedirect,
          failureRedirect,
          resourceName,
          displaySuccessBanner,
          displayFailureBanner,
        } = currentHandler;
        if (isSuccessful) {
          handleTrack.success({
            connection: resourceName,
          });
          const resourceDetails = OAUTH_RESOURCE_DETAILS[resourceName];
          const {
            isSyncPeerRecommendations,
            isSyncLinkedInPhoto,
            isSyncTwitterPhoto,
          } = resourceDetails;
          if (isSyncPeerRecommendations) {
            await syncPeerRecommendationsMutation();
          } else if (isSyncLinkedInPhoto) {
            await handlePhotoSync({
              provider: OAUTH_RESOURCE_DETAILS.linkedIn.provider,
            });
          } else if (isSyncTwitterPhoto) {
            await handlePhotoSync({
              provider: OAUTH_RESOURCE_DETAILS.twitter.provider,
            });
          }
          if (displaySuccessBanner) {
            // --------------
            // Don't display the success toast in the onboarding flow -- it's distracting
            // --------------
            // This is hacky, but we arrive on this page via multiple full-page redirects,
            // and the only way this component knows the context it's being used in is by
            // looking at where it will be going next
            if (successRedirect != Resources.onboardingPeerSuggestions.pattern) {
              handleDisplayConnectSuccessBanner({
                resourceName,
              });
            }
          }
          history.push(successRedirect);
        } else {
          handleTrack.failure({
            resourceName,
          });
          if (displayFailureBanner) {
            handleDisplayConnectErrorBanner({
              resourceName,
            });
          }
          history.push(failureRedirect);
        }
      },
    })
  ),
  withProps(({ currentHandler }) => ({
    getOverlayHeaderText: () => {
      const { resourceName } = currentHandler;
      return OAUTH_RESOURCE_DETAILS[resourceName].overlayHeaderText;
    },
    getOverlaySubheaderText: () => {
      const { resourceName } = currentHandler;
      return OAUTH_RESOURCE_DETAILS[resourceName].overlaySubheaderText;
    },
  })),
);
