import React from 'react';
import { createRoot } from 'react-dom/client';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import 'config/polyfill';
import App from 'components/App';
import { bootIntercom } from 'libs/tracking/intercom';
import * as Sentry from '@sentry/react';
const { REACT_APP_CUSTOM_ENV } = WEBPACK_ENV_VARS;

const config = {
  typekit: { id: 'ahg6csz' },
  custom: {
    families: ['Inter UI:n4,n5,n7'],
  },
};

bootIntercom();

const container = document.getElementById('root');
const root = createRoot(container);

Sentry.init({
  dsn: 'https://dd8a4606a62b423b8120b8dd54286f46@o130359.ingest.sentry.io/287743',
  environment: REACT_APP_CUSTOM_ENV,
  attachStacktrace: true,
  tracesSampleRate: 1.0
});

root.render(
  <Sentry.ErrorBoundary 
    showDialog
  >
    <WebfontLoader config={config}>
      <App />
    </WebfontLoader>
  </Sentry.ErrorBoundary>
);
