import React from 'react';

import Item from './Item';
import List from './List';
import Form from './Form';
import TextArea from './TextArea';

class Comment extends React.Component {
  static Item = Item;
  static List = List;
  static Form = Form;
  static TextArea = TextArea;
}

export default Comment;
