import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from './Default';
import theme from '@matterapp/matter-theme';

const getColorStyles = ({ color, disabled, isSelected }) => {
  const isLightColor = color === 'yellow';
  const baseColor = theme.colors[`${color}s`] || theme.colors.blues;
  const baseStyles = `
    border-width: 0;
    color: ${theme.colors.white};
    font-weight: ${theme.fontWeight.bold};
  `;

  if (disabled) {
    return '';
  }

  if (isSelected) {
    return `
      ${baseStyles}
      background-color: ${baseColor[isLightColor ? 50 : 40]};
      &:hover {
        background-color: ${baseColor[isLightColor ? 60 : 50]};
      }
      &:active {
        background-color: ${baseColor[isLightColor ? 50 : 40]};
      }
    `;
  }

  return `
    ${baseStyles}
    background-color: ${baseColor[20]};
    &:hover {
      background-color: ${baseColor[isLightColor ? 50 : 40]};
    }
    &:active {
      background-color: ${baseColor[isLightColor ? 60 : 50]};
    }
  `;
};

const ToggleButtonPrimary = styled(Button)`
  &:not(:active) {
    border-color: transparent;
  }
  ${getColorStyles}
`;

ToggleButtonPrimary.propTypes = {
  isSelected: PropTypes.bool,
};
export default ToggleButtonPrimary;
