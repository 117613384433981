import React from 'react';
import { compose, withProps } from 'recompose';
import withRouter from 'hocs/withRouter';
import Toast from 'components/Toast/Toast';
import { graphql } from '@apollo/client/react/hoc';
import { OUTBOUND_INVITATIONS_QUERY, REVOKE_LONG_FORM_FEEDBACK_INVITATION_MUTATION } from 'graphql-queries/queries';
import {  mixpanel } from 'libs/tracking';
import FeedbackRequestsPage from '../components/FeedbackRequestsPage';

const handleTrack = {
  feedbackRequestRevoked: mixpanel.createEventSender('frs:request-cancelled'),
  feedbackRequestEmailUpdated: mixpanel.createEventSender('frs:request-updated'),
};

const withData = compose(
  withRouter,
  graphql(OUTBOUND_INVITATIONS_QUERY, {
    name: 'outboundInvitationsData',
    options: {
      fetchPolicy: "cache-and-network"
    }
  }),
  graphql(REVOKE_LONG_FORM_FEEDBACK_INVITATION_MUTATION, {
    name: 'revokeLongFormFeedbackInvitationMutation',
  }),
  withProps(({ revokeLongFormFeedbackInvitationMutation }) => ({
    handleRevokeLongFormFeedbackInvitation: async (invitationId) => {
      await revokeLongFormFeedbackInvitationMutation({
        variables: { invitationId },
      });
    },
  })),
  withProps(
    ({ handleRevokeLongFormFeedbackInvitation }) => ({
      handleRevokeFeedbackInvitation: async (invitation) => {
        const invitationId = invitation.id;
        const { invitedPeer } = invitation;

        try {
          // We are letting our function know that we'd like `OUTBOUND_INVITATIONS_QUERY` to be refetched once the invite is cancelled.
          await handleRevokeLongFormFeedbackInvitation(invitationId);
          Toast.success(`You cancelled a request to ${invitedPeer.fullName || invitedPeer.email}`);
          handleTrack.feedbackRequestRevoked({ invitationId });
        } catch (e) {
          Toast.error((<span><strong>Oops!</strong> Something went wrong, please try again later</span>));
        }
      },
    })
  ),
);

const FeedbackRequestsPageContainer = withData(FeedbackRequestsPage);

export default FeedbackRequestsPageContainer;
