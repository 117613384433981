import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TimeAgo from '../TimeAgo';
import Text from '../Typography/Text';
import { ListItem, ListItemActionBox, ListItemStackContainer } from '../List';
import Avatar from '../Avatar';
import { spacing } from '@matterapp/matter-theme';

const avatarSize = 48;

const AvatarBox = styled.div`
  min-width: ${spacing.triple};
  width: ${({ fluid }) => fluid ? 'auto' : spacing.triple};
  margin-right: ${spacing.half};
  align-self: flex-start;
`;

const ContentContainer = styled.div`
  flex: 1;
  text-align: left;
  padding-right: ${spacing.threeQuarters};
  user-select: none;

  > * {
    display: inline;
    line-height: ${spacing.single};
    margin-right: calc(${spacing.quarter} + 1px);
  }
`;

const NotificationItem = ({
  person,
  content,
  eventTime,
  actions,
  onClick,
  highlighted,
  isStackedOnMobile,
}) => {
  return (
    <ListItem onClick={onClick} highlighted={highlighted}>
      <AvatarBox>
        <Avatar size={avatarSize} url={person.photoUrl} />
      </AvatarBox>
      <ListItemStackContainer isStackedOnMobile={isStackedOnMobile}>
        <ContentContainer>
          <Text color="black" bold>
            {person.fullName || person.email}
          </Text>
          {content}{' '}
          <Text color="blacks[50]" textStyle={340}>
            <TimeAgo date={eventTime} />
          </Text>
        </ContentContainer>
        {actions ? <ListItemActionBox>{actions}</ListItemActionBox> : null}
      </ListItemStackContainer>
    </ListItem>
  );
};

const Multiple = ({
  actions,
  avatarUrl,
  content,
  highlighted,
  isStackedOnMobile,
  numberOfPeople,
  onClick,
}) => {
  return (
    <ListItem onClick={onClick} highlighted={highlighted}>
      <AvatarBox fluid>
        <Avatar.WithCount showAsAddition count={numberOfPeople} size={avatarSize} url={avatarUrl} />
      </AvatarBox>
      <ListItemStackContainer isStackedOnMobile={isStackedOnMobile}>
        <ContentContainer>
          <Text color="black" bold>
            {numberOfPeople} people
          </Text>
          {content}
        </ContentContainer>
        {actions ? <ListItemActionBox>{actions}</ListItemActionBox> : null}
      </ListItemStackContainer>
    </ListItem>
  );
};

Multiple.defaultProps = {
  onClick: undefined,
  isStackedOnMobile: false,
  highlighted: false,
};

Multiple.propTypes = {
  avatarUrls: PropTypes.arrayOf(PropTypes.string),
  isStackedOnMobile: PropTypes.bool,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  highlighted: PropTypes.bool,
  numberOfPeople: PropTypes.number,
};

NotificationItem.Multiple = Multiple;

NotificationItem.defaultProps = {
  onClick: undefined,
  isStackedOnMobile: false,
  highlighted: false,
};

NotificationItem.propTypes = {
  person: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  isStackedOnMobile: PropTypes.bool,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired,
  eventTime: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  highlighted: PropTypes.bool,
};

export default NotificationItem;
