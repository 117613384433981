import omitSyled from '../../libs/omitStyled';
import Button from '../Button';

const PrimaryButton = omitSyled(Button.New).attrs({
  primary: true,
  size: 'medium',
})`
  ${(props) => !props.fluid && 'min-width: 280px; max-width: 330px;'}
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;

  &:last-child {
    margin-bottom: 0;
  }
  ${({ noMarginBottom }) => noMarginBottom && `
    margin-bottom: 0;
  `}
`;

export default PrimaryButton;
