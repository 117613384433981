import { compose, withProps } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import {
  PEER_RECOMMENDATIONS_QUERY,
  REQUEST_FEEDBACK_MUTATION
} from 'graphql-queries/queries';

const withRequestFeedbackMutation = graphql(REQUEST_FEEDBACK_MUTATION, {
  name: 'requestFeedbackMutation'
});

const withPeerRecommendationsData = graphql(PEER_RECOMMENDATIONS_QUERY, {
  name: 'peerRecommendationsData',
  options: {
    fetchPolicy: 'network-only',
  },
});

const unique = (arr, identity) => {
  const seen = new Set();
  const toReturn = [];
  arr.forEach((item) => {
    const key = identity(item);
    if (seen.has(key)) { return; }
    toReturn.push(item);
    seen.add(key);
  });
  return toReturn;
};

const withRequestFeedbackFromMultiplePeers = withProps(
  ({
    requestFeedbackMutation
  }) => ({
    requestFeedbackFromMultiplePeers: async ({
      customNote,
      feedbackTemplateId,
      peers,
      source,
      isRecommendation,
      workspaceId,
    }) => {
      const uniquePeers = unique(peers, (peer) => peer.email).map((peer) => ({
        email: peer.email,
        fullName: peer.fullName,
        photoUrl: peer.photoUrl,
      }));
      
      await requestFeedbackMutation({
        variables: {
          customNote,
          feedbackTemplateId,
          pickedPeers: uniquePeers,
          source,
          isRecommendation,
          workspaceId,
        }
      });
    },
  })
);

const withData = () =>
  compose(
    withPeerRecommendationsData,
    withRequestFeedbackMutation,
    withRequestFeedbackFromMultiplePeers
  );

export default withData;
