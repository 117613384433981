import Base from './Dropdown';
import Email from './Email';
import Multiple from './Multiple';
import Tags from './Tags';

const Dropdown = Base;
Dropdown.Email = Email;
Dropdown.Multiple = Multiple;
Dropdown.Tags = Tags;

export default Dropdown;