import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { 
  Typography, 
  Box,
  Divider
} from '@mui/material';
import { Resources } from '@matterapp/routing';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import CopyIcon from './CopyIcon';
import getRewardsFundingPaymentIntent from 'graphql-queries/queries/billing/getRewardsFundingPaymentIntent';
import { 
  LineItem,
  LineItemValues,
  IconWrapper,
  AchWrapper
} from './styles';
import { useQuery } from '@apollo/client';
import { Loader } from '@matterapp/matter-ui';
import Toast from 'components/Toast/Toast';

function BankAccountDetail({ text, value }) {
  return (
    <LineItem>
      <Typography variant='h7' component='p' sx={{ alignSelf: 'center' }}>
        <b>{text}</b>
      </Typography>
      <LineItemValues>
        <Typography variant='body2' sx={{ alignSelf: 'center' }}>
          {value}
        </Typography>
        <IconWrapper 
          onClick={() => {
            navigator.clipboard.writeText(value);

            Toast.success('Copied to clipboard');
          }}
        >
          <CopyIcon />
        </IconWrapper>
      </LineItemValues>
    </LineItem>
  );
}
export default function AddFundingPage() {
  const { 
    currentTenant,
    currentWorkspaceId
  } = useContext(UserContext);
  const { data: paymentIntentData, loading } = useQuery(getRewardsFundingPaymentIntent, {
    variables: {
      tenantId: currentTenant?.id
    },
    skip: !currentTenant
  });

  const { 
    accountNumber,
    routingNumber,
    bankName,
    swiftCode
  } = paymentIntentData?.rewardsFundingPaymentIntent || {};

  return (
    <Box>
      <BreadCrumbs
        crumps={[
          {
            label: 'Funding',
            href: Resources.rewardsFunding.path({ workspaceId: currentWorkspaceId })
          },
          {
            label: 'Add Funds', 
            href: Resources.rewardsAddFunding.path({ workspaceId: currentWorkspaceId })
          },
          {
            label: 'Send ACH/Wire', 
            href: null
          }
        ]}
      />
      <AchWrapper sx={{ maxWidth: '760px' }}>
        <Box>
          <Typography variant='h4' sx={{ mb: 3 }}>
            Send an ACH or Wire Transfer to the Following Bank Account
          </Typography>
          <Typography variant='body1'>
            Use the information below to send an ACH or wire transfer from your bank to Matter. 
            We&apos;ll only process orders of $500.00 USD or more.
          </Typography>
        </Box>
        {loading && (
          <Loader />
        )}
        <Box sx={{ mt: 3 }}>
          <BankAccountDetail text='Bank Name' value={bankName} />
          <BankAccountDetail text='Routing #' value={routingNumber} />
          <BankAccountDetail text='Account #' value={accountNumber} />
          <BankAccountDetail text='Account Holder' value='MatterApp, Inc.' />
          <BankAccountDetail text='SWIFT Code' value={swiftCode} />
          <BankAccountDetail text='Address' value='510 Meadowmont Village Cir #196, Chapel Hill, North Carolina, 27517' />
        </Box>
        <Divider sx={{ mt: 3, mb: 3 }} />
        <Box>
          <Typography variant='body2' sx={{ mb: 1 }}>
            <b>We’ll email you when funds are available</b>
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: '20px' }}>
            Bank transfers can take up to five business days, sometimes longer. Your bank might charge you a fee to send an ACH or wire transfer, but Matter doesn’t charge anything to receive them.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant='body2' sx={{ mb: 1 }}>
            <b>Any questions?</b>
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: '20px' }}>
            Contact us at <a href="mailto:funding@matterapp.com" target="_blank" rel="noopener noreferrer">funding@matterapp.com</a>
          </Typography>
        </Box>
      </AchWrapper>
    </Box>
  );
};
