import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 26.7541C4 19.5922 4 12.4302 4 5.26826C4.00746 5.23593 4.01741 5.20609 4.02238 5.17376C4.0945 4.65154 4.3755 4.29095 4.8654 4.09947C4.99223 4.04974 5.13398 4.03233 5.26826 4C12.4302 4 19.5922 4 26.7541 4C26.8188 4.01243 26.8834 4.02238 26.9456 4.03482C27.5822 4.1691 28.0025 4.73609 28 5.35281C27.995 12.4576 27.9975 19.5598 27.995 26.6646C27.995 26.7641 27.9876 26.8635 27.9677 26.9605C27.8607 27.5375 27.3186 27.9975 26.7292 27.9975C24.71 28 22.6932 28 20.6739 28C20.6391 28 20.6043 27.995 20.5695 27.9901C20.5695 24.8965 20.5695 21.8129 20.5695 18.7193C21.6114 18.7193 22.6459 18.7193 23.6854 18.7193C23.8421 17.5033 23.9963 16.3021 24.1529 15.0836C22.9444 15.0836 21.7582 15.0836 20.562 15.0836C20.562 15.0339 20.562 14.9966 20.562 14.9593C20.562 14.2456 20.5595 13.5343 20.5645 12.8206C20.567 12.6142 20.5819 12.4053 20.6117 12.2014C20.6938 11.6344 20.9947 11.2465 21.5667 11.1172C21.8153 11.06 22.0715 11.0252 22.3276 11.0202C22.9319 11.0053 23.5387 11.0152 24.143 11.0152C24.1877 11.0152 24.23 11.0152 24.2847 11.0152C24.2847 10.9531 24.2847 10.9083 24.2847 10.8635C24.2847 9.89866 24.2847 8.9313 24.2847 7.96643C24.2847 7.76997 24.2847 7.76997 24.0932 7.75008C24.0236 7.74262 23.954 7.73267 23.8844 7.7277C23.4144 7.69786 22.9419 7.66055 22.4719 7.64315C21.686 7.61579 20.9002 7.58595 20.1243 7.77495C19.2266 7.99378 18.4532 8.41902 17.8589 9.13522C17.1501 9.99067 16.8617 11.0003 16.8318 12.087C16.8045 13.0345 16.8219 13.9845 16.8194 14.9344C16.8194 14.9817 16.8194 15.0314 16.8194 15.0911C15.765 15.0911 14.733 15.0911 13.701 15.0911C13.701 16.3046 13.701 17.5058 13.701 18.7193C14.7429 18.7193 15.7749 18.7193 16.8119 18.7193C16.8119 21.8178 16.8119 24.9039 16.8119 27.9901C16.7746 27.9925 16.7448 27.995 16.7174 27.995C12.9077 27.995 9.1004 27.995 5.29064 27.995C4.71122 27.9975 4.18402 27.5723 4.04974 27.0078C4.02984 26.9232 4.01741 26.8387 4 26.7541Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;

