import logger from '../logger';

export function gaCall(...args) {
  let count = 0;
  if (!window.ga) {
    if (count > 10) {
      return;
    }
    console.log('ga not loaded yet. trying again...');
    setTimeout(() => {
      count++;
      gaCall(...args);
    }, 1000);
    return;
  }
  window.ga(...args);
}

export const trackGoogleAnalytics = ({ eventAction, eventCategory }) => {
  logger.info(`googleAnalytics:${eventCategory}:${eventAction}`);
  gaCall('send', {
    hitType: 'event',
    eventCategory,
    eventAction,
  });
};

export function createNamedGoogleAnalyticsEventSender({ eventAction, eventCategory }) {
  return function () {
    return trackGoogleAnalytics({ eventAction, eventCategory });
  };
}
