import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clickable } from '../../Clickable/Clickable';
import { IconNew as Icon } from '../../Icon';
import InfoModal from '../../InfoModal';
import theme from '@matterapp/matter-theme';
import { getDescriptionTitle, getDescriptionText } from '../../../common/libs/skill-ratings-map';

const HEADING_TEXT = 'Step Guide';
const SUB_HEADING_TEXT = ' ';
const RATINGS = [1, 2, 3, 4, 5];

const Container = styled.div`
  font-size: ${theme.fontSize.base};
  margin-bottom: ${theme.spacing.singleAndHalf};
`;
const Paragraph = styled.p`
  line-height: ${theme.lineHeight.M};
  margin-bottom: ${theme.spacing.singleAndHalf};
`;

const Header = styled.span`
  font-weight: ${theme.fontWeight.bold};
`;

const Description = styled.span``;

const IconContainer = styled(Clickable)`
  color: ${theme.colors.blacks[30]}
  margin: -${theme.spacing.quarter};
  padding: ${theme.spacing.half};
  vertical-align: middle;
  display: inline;
  ${theme.media.S`
    display: none;
  `}
`;

export default class RatingGuideModal extends React.PureComponent {
  static propTypes = {
    /** Callback when modal opens. Since the modal keeps state internally, this function os optional and mainly used for any tracking. */
    onOpenModal: PropTypes.func,
  };

  static defaultProps = {
    onOpenModal: () => null,
  };

  /**
   * Creates callback when modal opens.
   * @param { Object } modalProps: {
   *   @param { Function } toggleModal: Callback function from InfoModal component to toggle modal.
   * }
   * @returns { Function } The callback function to open modal.
   */
  handleOpenModal = (modalProps) => {
    return (e) => {
      const { toggleModal } = modalProps;
      toggleModal(e);
      this.props.onOpenModal();
    };
  };

  render() {
    const { receiversFirstName } = this.props;
    const bodyContent = RATINGS.map((rating) => {
      return (
        <Paragraph key={`step-guide-${rating}`}>
          <Header>{getDescriptionTitle(rating)}</Header>{' '}
          <Description>
            {getDescriptionText(rating, receiversFirstName)}
          </Description>
        </Paragraph>
      );
    });

    return (
      <InfoModal
        headingText={HEADING_TEXT}
        subHeadingText={SUB_HEADING_TEXT}
        bodyContent={<Container>{bodyContent}</Container>}
      >
        {(modalProps) => (
          <IconContainer onClick={this.handleOpenModal(modalProps)}>
            <Icon name="infoCircle" />
          </IconContainer>
        )}
      </InfoModal>
    );
  }
}
