import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { AvatarCard, Button } from '@matterapp/matter-ui';

export const Container = styled(AvatarCard)`
  padding-left: 0;
  padding-right: 0;

  & > h1 {
    margin-top: ${theme.spacing.singleAndQuarter} !important;
    margin-bottom: ${({ subHeader }) => subHeader ? theme.spacing.single : theme.spacing.double}!important;
    padding: 0 ${theme.spacing.singleAndHalf};
    word-break: break-word;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: ${theme.lineHeight.XL};
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  ${theme.media.S`
    padding-bottom: ${theme.spacing.singleAndThreeQuarters};
  `}
`;

export const SideColumnCountHeader = styled.div`
  color: ${theme.colors.blacks[60]};
  display: flex;
  font-size: ${theme.fontSize.base};
  margin-top: ${theme.spacing.singleAndHalf};
  line-height: ${theme.spacing.singleAndHalf};
  ${theme.media.M`
    margin-bottom: 0;
    padding: ${theme.spacing.half} ${theme.spacing.singleAndHalf};
    justify-content: space-between;
  `}
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  height: ${theme.spacing.double};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.fontSize.base};
  line-height: ${theme.spacing.singleAndHalf};
  text-align: left;
`;

export const SideColumnCountContainer = styled.div`
  width: 100%;
  font-size: ${theme.fontSize.S2};
`;

export const SideColumnCountFooter = styled.div`
  display: flex;
  line-height: 1;
  height: ${theme.spacing.singleAndHalf};
  border-bottom: none !important;
`;

export const SideColumnCountRow = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: ${theme.spacing.singleAndHalf};
  border-top: ${({ showTopLine }) =>
  showTopLine ? `1px solid ${theme.colors.blacks[10]};` : `0`}
  cursor: ${({ isCurrentUser, onClick }) => (isCurrentUser || !onClick ? 'default' : 'pointer')};
  ${({ isCurrentUser, onClick }) =>
    !isCurrentUser && !!onClick
      ? `
    ${SideColumnCountValue} {
      color: ${theme.colors.blues[50]};
    }
    &:hover ${SideColumnCountKey} {
      color: ${theme.colors.blues[50]};
    }
    &:active {
      text-decoration: underline;
      color: ${theme.colors.blues[50]};
    }
  `
      : ``}
  `;

export const SideColumnCountKey = styled.div`
  color: ${theme.colors.blacks[50]};
  text-transform: capitalize;
  line-height: ${theme.spacing.singleAndHalf};
  font-weight: ${theme.fontWeight.regular};
  height: ${theme.spacing.singleAndHalf};
  display: flex;
`;

export const SideColumnCountValue = styled.div`
  color: ${theme.colors.blacks[60]};
  font-weight: ${theme.fontWeight.bold};
`;

const avatarStylesCompact = `
  align-items: flex-start;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  & ${SideColumnCountHeader} {
    display: block;
    font-size: ${theme.fontSize.base};
    padding: 0 ${theme.spacing.singleAndHalf};
  }
  ${SideColumnCountContainer} {
    padding: ${theme.spacing.singleAndHalf};
    font-size: ${theme.fontSize.S2};
    border-bottom: 1px solid ${theme.colors.blacks[10]};
    margin-top: 0;
  }
`;

const getContainerStylesMobile = ({ isCompact }) => {
  if (isCompact) {
    return `
      display: flex;
        ${avatarStylesCompact}
        &:before {
          display: none;
        }
        ${SideColumnCountContainer} {
          display: flex;
          ${SideColumnCountRow} {
            margin: 0 ${theme.spacing.single} 0 0;
            
            ${SideColumnCountKey}:after {
              content: ':';
            }
            ${SideColumnCountKey} {
              margin: 0 ${theme.spacing.half} 0 0;
            }
          }
        }
      `;
  }
  return ` 
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      
      ${SideColumnCountContainer} {
        ${SideColumnCountRow} {
          width: 100%;
          height: 40px;
          line-height: ${theme.spacing.singleAndHalf};
          border-bottom: 1px solid ${theme.colors.blacks[10]};
          margin: 0 0 -1px 0;
          padding: ${theme.spacing.half} ${theme.spacing.singleAndHalf};
          ${SideColumnCountKey} {
            flex: 1 1 auto;
            margin: 0;
            text-align: left;
          }
        }
      }
    `;
};

const getContainerStyles = ({ isCompact }) => {
  if (isCompact) {
    return theme.media.M`
      ${avatarStylesCompact}
    `;
  }
};

export const AvatarContainer = styled.div`
  ${getContainerStylesMobile}
  ${getContainerStyles}
`;

export const StyledAvatar = styled.div`
  display: inline;
  margin-right: ${theme.spacing.half};
  width: ${theme.spacing.singleAndHalf};
  height: ${theme.spacing.singleAndHalf};
`;

export const StyledName = styled.div`
  max-width: 272px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ViewAllButton = styled(Button.Simple.Minimal)`
  flex: 1 1 100%;
  height: ${theme.spacing.double};
  width: 304px;
  color: ${theme.colors.blacks[60]};
`;

export const ViewAllButtonContainer = styled.div`
  margin-top: ${theme.spacing.singleAndQuarter};
`;

export const TooltipContainer = styled.div`
  margin-top: -${theme.spacing.half};
  ${theme.media.S`
    margin-top: 0;
  `}
  &:hover {
    svg path {
    fill: ${theme.colors.greens[60]};
    stroke: ${theme.colors.greens[60]};
    }
  }
`;