import React from 'react';
import PropTypes from 'prop-types';
import { ListContainer, ButtonNew as Button } from '@matterapp/matter-ui';

class YearsOfExperienceButtons extends React.Component {
  renderButtons = () =>
    this.props.years.map((item, index) => (
      <div key={item.data}>
        <Button
          pill
          size="large"
          rcLabel={`yearsOfExperienceButton-${index}`}
          onClick={() => {
            this.props.setYearsOfExperience(item.data);
          }}
        >
          {item.label}
        </Button>
      </div>
    ));

  render() {
    return (
      <ListContainer stacked animated>
        {this.renderButtons()}
      </ListContainer>
    );
  }
}

YearsOfExperienceButtons.propTypes = {
  years: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  setYearsOfExperience: PropTypes.func.isRequired,
};

export default YearsOfExperienceButtons;
