import React from 'react';
import PropTypes from 'prop-types';
import { formatLabelDefaultProp } from './utils';
import Button from '../Button/variants/ToggleMinimal';
import ButtonGroup from '../Button/Group';

const RadioButtonGroup = (props) => {
  const {
    name,
    className,
    color,
    disabled,
    formatLabel,
    onChange,
    value,
    values,
  } = props;
  const buttons = values.map((item, index) => {
    const otherProps = { ...(item.buttonProps || {}) };
    const handleChange = (e, params) => {
      onChange(e, { ...params, item });
    };
    
    if (name) {
      otherProps.name = name;
    }
    
    let radioLabel, radioValue;
    if (typeof item == 'string') {
      radioLabel = item;
      radioValue = item;
    } else {
      radioLabel = item.label;
      radioValue = item.value;
    }

    return (
      <Button
        canToggle={false}
        color={color}
        isSelected={value === radioValue}
        disabled={disabled || item.disabled}
        key={`radio-button-${index}-${radioValue}`}
        onClick={handleChange}
        value={radioValue}
        {...otherProps}
      >
        {formatLabel(radioLabel)}
      </Button>
    );
  });
  return (
    <ButtonGroup bordered className={className}>
      {buttons}
    </ButtonGroup>
  );
};

RadioButtonGroup.buttonColors = Button.buttonColors;

RadioButtonGroup.defaultProps = {
  color: Button.buttonColors.BLUE,
  formatLabel: formatLabelDefaultProp,
  onChange: () => {},
};

RadioButtonGroup.propTypes = {
  /** Color of the buttons. Alternatively, you can color each button in the values props. */
  color: PropTypes.oneOf(Object.values(Button.buttonColors)),
  /** Additional style class. */
  className: PropTypes.string,
  /** If radio button group is disabled. */
  disabled: PropTypes.bool,
  /** Function to format label of item based on value of item. */
  formatLabel: PropTypes.func,
  /** Callback when radio button group value changes. */
  onChange: PropTypes.func,
  /** Value of selected radio button. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** List of values for the radio button group. */
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        buttonProps: PropTypes.object,
        disabled: PropTypes.bool,
        label: PropTypes.node,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ])
  ),
};

export default RadioButtonGroup;
