import React from 'react';
import { 
  Box,
  Typography,
  Paper
} from '@mui/material';
import {
  ResultsWrapper,
  StyledDivider
} from './styles';
import moment from 'moment-timezone';

export default function IndividualResponsesBlock({ questions, responses }) {
  return (
    [...responses]
      .sort((a, b) => a.createdAt - b.createdAt)
      .map(({ id, createdAt, responder, response }, idx) => {
        return (
          <Paper sx={{ padding: 0, mt: 3, pb: 1 }} key={id}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ 
                minWidth: '88px', 
                minHeight: '88px', 
                textAlign: 'center', 
                display: 'flex', 
                alignItems: 'center', 
                flexDirection: 'row' 
              }}>
                <Typography variant='h2' sx={{ margin: '0 auto' }}>{idx + 1}</Typography>
              </Box>
              <Box sx={{ alignSelf: 'center', pt: 2, pb: 2 }}>
                <Typography variant='body1'><b>By {responder.fullName}</b></Typography>
                <Typography variant='body2' color='blacks.50'>Submitted {moment(parseInt(createdAt)).format('MMM D, YYYY')} at {moment(parseInt(createdAt)).format('hh:mm a')}</Typography>
              </Box>
            </Box>
            <StyledDivider />
            <ResultsWrapper>
              {response.map(({ questionId, comment, answers }, idx) => {
                const question = questions.find(({ id }) => id === questionId);
                const { answerOptions } = question;
                let [answer] = answers;

                if (answerOptions?.length) {
                  answer = answerOptions.find(({ value }) => value == answer)?.label;
                }

                return (
                  <Box key={questionId} sx={{ mt: 2, mb: 2 }}>
                    <Typography variant='body2'>
                      <b>
                      Q{idx + 1}: {question?.question}
                      </b>
                    </Typography>
                    <Typography variant='body2'>
                    Answer: {answer || 'Skipped'}
                    </Typography>
                    {comment && (
                      <Typography variant='body2'>
                      Comment: {comment}
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </ResultsWrapper>
          </Paper>
        );
      })
  );
}
