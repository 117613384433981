import { mixpanel } from './mixpanel';
import { gtagCall } from './googleTagManager';

const mixpanelSignupCompleted = mixpanel.createEventSender('signup:completed');

export const handleTrackSignup = ({ isNewRecord, user, signupMeans }) => {
  if (isNewRecord) {
    mixpanel.trackPeopleData({
      '$email': user.email,
      '$signupMeans': signupMeans,
    });
    mixpanel.alias(user.id);
    mixpanelSignupCompleted({
      signupMeans,
      signupSource: window.location.pathname,
      signupSourceDetails: window.location,
    });
    gtagCall('event', 'conversion', {'send_to': 'AW-801897506/RVMUCImq_scCEKL4r_4C'});
  } else {
    mixpanel.identify(user.id);
  }
};
