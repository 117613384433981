import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import omitStyled from '../../../libs/omitStyled';
import { bannerTypes } from '../propTypes';
import theme from '@matterapp/matter-theme';

const BANNER_MIN_HEIGHT = 200;

const getBackgroundColor = ({ bannerType }) => {
  const styles = {
    [bannerTypes.ERROR]: theme.colors.reds[5],
    [bannerTypes.INFO]: theme.colors.blues[5],
    [bannerTypes.SUCCESS]: theme.colors.greens[5],
    [bannerTypes.WARNING]: theme.colors.yellows[5],
  };
  return styles[bannerType] || styles.info;
};

const getDismissedStyles = ({ dismissed }) => {
  const baseStyles = `
    transition: ${theme.transitions.times.default};
  `;

  if (dismissed) {
    return `
      max-height: 0px;
      min-height: 0px;
      overflow: hidden;
      ${baseStyles}
    `;
  }
  return baseStyles;
};

const Container = omitStyled('div', ['bannerType', 'dismissed'])`
  background: ${getBackgroundColor};
  max-height: 100%;
  min-height: ${BANNER_MIN_HEIGHT}px;
  ${getDismissedStyles}
`;

const InnerContainer = styled.div`
  ${() => `
    margin: auto;
    max-width: ${theme.sizes.webClient.maxContentWidth};
    padding: ${theme.spacing.double} ${theme.spacing.singleAndHalf};
    transition: padding ${theme.transitions.times.default};
  `}
  ${() => theme.media.medium`
    box-sizing: content-box;
    padding: 0 ${theme.spacing.singleAndHalf};
  `}
`;

const HeroBanner = (props) => {
  const { bannerType, children, dismissed, ...otherProps } = props;
  return (
    <Container dismissed={dismissed} bannerType={bannerType} {...otherProps}>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  );
};

HeroBanner.propTypes = {
  /** Banner type. Determines the background color. */
  bannerType: PropTypes.oneOf(Object.values(bannerTypes)),
  /** If banner is dismissed. */
  dismissed: PropTypes.bool,
};

HeroBanner.defaultProps = {
  bannerType: bannerTypes.INFO,
};

HeroBanner.bannerTypes = bannerTypes;

export default HeroBanner;
