import Animation from './Animation';
import { Colors } from './utils';

export const BOBA_CORGI = {
  animation: Animation.BobaCorgi,
  color: Colors.Purple,
};

export const formatType = (type, params = {}) => {
  const { message = '', text = '' } = params;
  const textToCheck = message || text;
  if (textToCheck && textToCheck.toLowerCase().includes('boba corgi')) {
    return { ...type, ...BOBA_CORGI };
  }
  return type;
};
