import React from 'react';
import gsap from 'gsap';
import BaseBackground from './BaseBackground';
import { BackgroundContainer, DarkPath, LightPath } from './styles';

export default class Background5 extends BaseBackground {
  animateWave = (waveRef) => {
    const waveShow = { scaleY: 1 };
    const waveHide = { scaleY: 0.25 };
    const tl = gsap.timeline({
      defaults: { duration: 0.4, transformOrigin: 'center' },
    });
    tl.to(waveRef, { x: 0, duration: 2 }, '<');
    tl.to(waveRef, waveShow, '<');
    tl.to(waveRef, waveHide, '>');
    tl.to(waveRef, waveShow, '>');
    tl.to(waveRef, waveHide, '>');
    tl.to(waveRef, waveShow, '>');
    tl.to(waveRef, waveHide, '>');
    tl.to(waveRef, waveShow, '>');
    return tl;
  };

  playWaves = () => {
    const tl = gsap.timeline();
    tl.add(this.animateWave(this.topWaveRef), '<');
    tl.add(this.animateWave(this.middleWaveRef), '<');
    tl.add(this.animateWave(this.bottomWaveRef), '<');
    return tl;
  };

  playAnimation = (animationProps = {}) => {
    BaseBackground.killAnimation(this.tl);
    const { initialize } = animationProps;
    const bridgeResetProps = { y: 0, scale: 1, opacity: 1, duration: 0.4 };
    this.tl = gsap.timeline({
      defaults: { duration: 0, transformOrigin: 'center' },
    });
    this.tl.to(this.lakeRef, { scale: 1.2, opacity: 0 }, '<');
    this.tl.to(this.boardwalkRef, { opacity: 0, scale: 1.3, y: 40 }, '<');
    this.tl.to(this.topWaveRef, { x: -40, scaleY: 0 }, '<');
    this.tl.to(this.middleWaveRef, { x: -20, scaleY: 0 }, '<');
    this.tl.to(this.bottomWaveRef, { x: 40, scaleY: 0 }, '<');
    this.tl.to(this.leftRockOneRef, { scale: 0 }, '<');
    this.tl.to(this.leftRockTwoRef, { scale: 0 }, '<');
    this.tl.to(this.middleRockOneRef, { scale: 0 }, '<');
    this.tl.to(this.middleRockTwoRef, { scale: 0 }, '<');
    this.tl.to(this.bottomRockOneRef, { scale: 0 }, '<');
    this.tl.to(this.bottomRockTwoRef, { scale: 0 }, '<');
    this.tl.to(this.bridgePathRef, { y: -50, scale: 0.8, opacity: 0 }, '<');
    this.tl.to(this.bridgeTopPostsRef, { y: -30, scale: 1.4, opacity: 0 }, '<');
    this.tl.to(
      this.bridgeBottomPostsRef,
      { y: 30, scale: 1.4, opacity: 0 },
      '<'
    );

    if (!initialize) {
      this.tl.to(this.lakeRef, { scale: 1, opacity: 1, duration: 1 }, 'lake');
      this.tl.to(
        this.boardwalkRef,
        { y: 0, scale: 1, opacity: 1, duration: 0.2 },
        '<0.8'
      );
      this.tl.to(this.leftRockOneRef, { scale: 1, duration: 0.4 }, 'lake');
      this.tl.to(this.leftRockTwoRef, { scale: 1, duration: 0.4 }, '<0.2');
      this.tl.to(this.middleRockOneRef, { scale: 1, duration: 0.4 }, '<');
      this.tl.to(this.middleRockTwoRef, { scale: 1, duration: 0.4 }, '<0.2');
      this.tl.to(this.bottomRockOneRef, { scale: 1, duration: 0.4 }, '<');
      this.tl.to(this.bottomRockTwoRef, { scale: 1, duration: 0.4 }, '<0.2');
      this.tl.to(this.bridgeTopPostsRef, bridgeResetProps, 'lake');
      this.tl.to(this.bridgeBottomPostsRef, bridgeResetProps, '<');
      this.tl.to(this.bridgePathRef, bridgeResetProps, '>0.1');
      this.tl.add(this.playWaves(), 'lake');
    }
    return this.tl;
  };

  render() {
    return (
      <BackgroundContainer top={3}>
        <BaseBackground.SVG height={336}>
          <LightPath
            ref={(ref) => (this.bottomRockOneRef = ref)}
            d="M383.333,325.334c0-5.891-4.775-10.667-10.666-10.667c-5.891,0-10.667,4.776-10.667,10.667
              H383.333z"
          />
          <DarkPath
            ref={(ref) => (this.bottomRockTwoRef = ref)}
            d="M408.667,328c0-8.837-7.164-16-16-16c-8.837,0-16,7.163-16,16H408.667z"
          />
          <LightPath
            ref={(ref) => (this.leftRockTwoRef = ref)}
            d="M49.333,183.333c0-5.891,4.776-10.667,10.667-10.667c5.891,0,10.667,4.776,10.667,10.667H49.333z"
          />
          <DarkPath
            ref={(ref) => (this.leftRockOneRef = ref)}
            d="M24,186c0-8.837,7.163-16,16-16s16,7.163,16,16H24z"
          />
          <LightPath
            ref={(ref) => (this.middleRockOneRef = ref)}
            d="M264,242.5c0-3.314-2.686-6-6-6s-6,2.686-6,6H264z"
          />
          <LightPath
            ref={(ref) => (this.middleRockTwoRef = ref)}
            d="M278.25,244c0-4.971-4.029-9-9-9s-9,4.029-9,9H278.25z"
          />
          <DarkPath
            ref={(ref) => (this.bridgeTopPostsRef = ref)}
            d="M454.442,247.721v11.163H447v-11.163c0-2.055,1.666-3.721,3.721-3.721
              S454.442,245.666,454.442,247.721z M510.721,244c-2.055,0-3.721,1.666-3.721,3.721v11.163h7.442v-11.163
              C514.442,245.666,512.776,244,510.721,244z"
          />
          <g ref={(ref) => (this.bridgePathRef = ref)}>
            <LightPath d="M447,257.954c0,0,20.152-8.372,33.721-8.372s33.721,8.372,33.721,8.372v26.047c0,0-20.326-6.279-33.721-6.279c-13.395,0-33.721,6.279-33.721,6.279V257.954z" />
            <DarkPath
              d="M447.5,257.488v24.884c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-24.884
                c0-0.276,0.224-0.5,0.5-0.5S447.5,257.212,447.5,257.488z M454.442,254.662c-0.276,0-0.5,0.224-0.5,0.5v26.977
                c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-26.977C454.942,254.886,454.718,254.662,454.442,254.662z M461.884,252.338
                c-0.276,0-0.5,0.224-0.5,0.5v27.442c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-27.442
                C462.384,252.562,462.16,252.338,461.884,252.338z M469.326,250.477c-0.276,0-0.5,0.223-0.5,0.5v27.907c0,0.276,0.224,0.5,0.5,0.5
                s0.5-0.224,0.5-0.5v-27.907C469.826,250.7,469.602,250.477,469.326,250.477z M476.768,249.547c-0.277,0-0.5,0.224-0.5,0.5v27.907
                c0,0.276,0.223,0.5,0.5,0.5c0.276,0,0.5-0.224,0.5-0.5v-27.907C477.268,249.771,477.044,249.547,476.768,249.547z M484.209,249.547
                c-0.276,0-0.5,0.224-0.5,0.5v27.907c0,0.276,0.224,0.5,0.5,0.5c0.277,0,0.5-0.224,0.5-0.5v-27.907
                C484.709,249.771,484.486,249.547,484.209,249.547z M491.651,250.477c-0.276,0-0.5,0.223-0.5,0.5v27.907c0,0.276,0.224,0.5,0.5,0.5
                s0.5-0.224,0.5-0.5v-27.907C492.151,250.7,491.927,250.477,491.651,250.477z M499.093,252.338c-0.276,0-0.5,0.224-0.5,0.5v27.442
                c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-27.442C499.593,252.562,499.369,252.338,499.093,252.338z M506.535,254.197
                c-0.276,0-0.5,0.224-0.5,0.5v26.977c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-26.977
                C507.035,254.421,506.811,254.197,506.535,254.197z M513.977,254.197c-0.276,0-0.5,0.224-0.5,0.5v26.977c0,0.276,0.224,0.5,0.5,0.5
                s0.5-0.224,0.5-0.5v-26.977C514.477,254.421,514.253,254.197,513.977,254.197z"
            />
          </g>
          <DarkPath
            ref={(ref) => (this.bridgeBottomPostsRef = ref)}
            d="M454.442,272.837V284H447v-11.163c0-2.055,1.666-3.721,3.721-3.721
              S454.442,270.782,454.442,272.837z M510.721,269.116c-2.055,0-3.721,1.666-3.721,3.721V284h7.442v-11.163
              C514.442,270.782,512.776,269.116,510.721,269.116z"
          />
          <g ref={(ref) => (this.lakeRef = ref)} onMouseEnter={this.playWaves}>
            <LightPath d="M288,9c22.644,0,41,18.356,41,41c0,22.644-18.356,41-41,41h-44c-22.644,0-41-18.356-41-41c0-22.644,18.356-41,41-41H288z" />
            <DarkPath
              ref={(ref) => (this.topWaveRef = ref)}
              d="M316,33c0,0.552-0.448,1-1,1c-2.241,0-4.315-0.604-5.858-1.632
                c-0.641-0.427-1.2-0.947-1.642-1.534c-0.442,0.587-1.001,1.106-1.642,1.534C304.315,33.396,302.241,34,300,34
                c-2.241,0-4.315-0.604-5.858-1.632c-0.641-0.427-1.2-0.947-1.642-1.534c-0.442,0.587-1.001,1.106-1.642,1.534
                C289.315,33.396,287.241,34,285,34c-2.241,0-4.315-0.604-5.858-1.632c-0.641-0.427-1.2-0.947-1.642-1.534
                c-0.442,0.587-1.001,1.106-1.642,1.534C274.315,33.396,272.241,34,270,34c-0.552,0-1-0.448-1-1s0.448-1,1-1
                c1.901,0,3.577-0.516,4.749-1.297c1.178-0.785,1.751-1.761,1.751-2.703c0-0.552,0.448-1,1-1s1,0.448,1,1
                c0,0.942,0.573,1.918,1.751,2.703C281.423,31.484,283.099,32,285,32s3.577-0.516,4.749-1.297c1.178-0.785,1.751-1.761,1.751-2.703
                c0-0.552,0.448-1,1-1s1,0.448,1,1c0,0.942,0.573,1.918,1.751,2.703C296.423,31.484,298.099,32,300,32s3.577-0.516,4.749-1.297
                c1.178-0.785,1.751-1.761,1.751-2.703c0-0.552,0.448-1,1-1s1,0.448,1,1c0,0.942,0.573,1.918,1.751,2.703
                C311.423,31.484,313.099,32,315,32C315.552,32,316,32.448,316,33z"
            />
            <DarkPath
              ref={(ref) => (this.middleWaveRef = ref)}
              d="M315.5,50c0,0.552-0.448,1-1,1c-2.241,0-4.315-0.604-5.858-1.632
                c-0.766-0.51-1.424-1.145-1.892-1.879c-0.468,0.734-1.126,1.369-1.892,1.879C303.315,50.396,301.241,51,299,51
                c-2.241,0-4.315-0.604-5.858-1.632c-0.641-0.427-1.2-0.947-1.642-1.534c-0.442,0.587-1.001,1.106-1.642,1.534
                C288.315,50.396,286.241,51,284,51c-2.241,0-4.315-0.604-5.858-1.632c-0.641-0.427-1.2-0.947-1.642-1.534
                c-0.442,0.587-1.001,1.106-1.642,1.534C273.315,50.396,271.241,51,269,51c-2.241,0-4.315-0.604-5.858-1.632
                c-0.641-0.427-1.2-0.947-1.642-1.534c-0.442,0.587-1.001,1.106-1.642,1.534C258.315,50.396,256.241,51,254,51
                c-2.241,0-4.315-0.604-5.858-1.632c-0.641-0.427-1.2-0.947-1.642-1.534c-0.442,0.587-1.001,1.106-1.642,1.534
                C243.315,50.396,241.241,51,239,51c-0.552,0-1-0.448-1-1s0.448-1,1-1c1.901,0,3.577-0.516,4.749-1.297
                c1.178-0.785,1.751-1.761,1.751-2.703c0-0.552,0.448-1,1-1s1,0.448,1,1c0,0.942,0.573,1.918,1.751,2.703
                C250.423,48.484,252.099,49,254,49s3.577-0.516,4.749-1.297c1.178-0.785,1.751-1.761,1.751-2.703c0-0.552,0.448-1,1-1s1,0.448,1,1
                c0,0.942,0.573,1.918,1.751,2.703C265.423,48.484,267.099,49,269,49s3.577-0.516,4.749-1.297c1.178-0.785,1.751-1.761,1.751-2.703
                c0-0.552,0.448-1,1-1s1,0.448,1,1c0,0.942,0.573,1.918,1.751,2.703C280.423,48.484,282.099,49,284,49s3.577-0.516,4.749-1.297
                c1.178-0.785,1.751-1.761,1.751-2.703c0-0.552,0.448-1,1-1s1,0.448,1,1c0,0.942,0.573,1.918,1.751,2.703
                C295.423,48.484,297.099,49,299,49s3.577-0.516,4.749-1.297c1.178-0.785,1.751-1.761,1.751-2.703c0-0.552,0.448-1,1-1
                c0.089,0,0.168,0.029,0.25,0.051C306.832,44.029,306.911,44,307,44c0.552,0,1,0.448,1,1c0,0.942,0.573,1.918,1.751,2.703
                C310.923,48.484,312.599,49,314.5,49C315.052,49,315.5,49.448,315.5,50z"
            />
            <DarkPath
              ref={(ref) => (this.bottomWaveRef = ref)}
              d="M275,67c0,0.552-0.448,1-1,1c-2.241,0-4.315-0.604-5.858-1.632
                c-0.641-0.427-1.2-0.947-1.642-1.534c-0.442,0.587-1.001,1.106-1.642,1.534C263.315,67.396,261.241,68,259,68
                c-2.241,0-4.315-0.604-5.858-1.632c-0.641-0.427-1.2-0.947-1.642-1.534c-0.442,0.587-1.001,1.106-1.642,1.534
                C248.315,67.396,246.241,68,244,68c-2.241,0-4.315-0.604-5.858-1.632c-0.641-0.427-1.2-0.947-1.642-1.534
                c-0.442,0.587-1.001,1.106-1.642,1.534C233.315,67.396,231.241,68,229,68c-0.552,0-1-0.448-1-1s0.448-1,1-1
                c1.901,0,3.577-0.516,4.749-1.297c1.178-0.785,1.751-1.761,1.751-2.703c0-0.552,0.448-1,1-1s1,0.448,1,1
                c0,0.942,0.573,1.918,1.751,2.703C240.423,65.484,242.099,66,244,66s3.577-0.516,4.749-1.297c1.178-0.785,1.751-1.761,1.751-2.703
                c0-0.552,0.448-1,1-1s1,0.448,1,1c0,0.942,0.573,1.918,1.751,2.703C255.423,65.484,257.099,66,259,66s3.577-0.516,4.749-1.297
                c1.178-0.785,1.751-1.761,1.751-2.703c0-0.552,0.448-1,1-1s1,0.448,1,1c0,0.942,0.573,1.918,1.751,2.703
                C270.423,65.484,272.099,66,274,66C274.552,66,275,66.448,275,67z"
            />
            <g ref={(ref) => (this.boardwalkRef = ref)}>
              <DarkPath d="M252,81c0-2.209,1.791-4,4-4c2.209,0,4,1.791,4,4v12h-8V81z M259.279,114V82.55h25V114H259.279z M284,81c0-2.209,1.791-4,4-4s4,1.791,4,4v12h-8V81z" />
              <LightPath
                d="M258.779,109.375c0-0.276,0.224-0.5,0.5-0.5h25c0.276,0,0.5,0.224,0.5,0.5
                  s-0.224,0.5-0.5,0.5h-25C259.003,109.875,258.779,109.651,258.779,109.375z M258.779,104.75c0-0.276,0.224-0.5,0.5-0.5h25
                  c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-25C259.003,105.25,258.779,105.026,258.779,104.75z M258.779,100.125
                  c0-0.276,0.224-0.5,0.5-0.5h25c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-25C259.003,100.625,258.779,100.401,258.779,100.125z
                  M258.779,95.5c0-0.276,0.224-0.5,0.5-0.5h25c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-25
                  C259.003,96,258.779,95.776,258.779,95.5z M258.779,90.875c0-0.276,0.224-0.5,0.5-0.5h25c0.276,0,0.5,0.224,0.5,0.5
                  s-0.224,0.5-0.5,0.5h-25C259.003,91.375,258.779,91.151,258.779,90.875z M258.779,86.25c0-0.276,0.224-0.5,0.5-0.5h25
                  c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-25C259.003,86.75,258.779,86.526,258.779,86.25z"
              />
            </g>
          </g>
        </BaseBackground.SVG>
      </BackgroundContainer>
    );
  }
}
