import React, { useState, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { useMutation } from '@apollo/client';
import { SEND_ENABLE_REWARDS_REQUEST } from 'graphql-queries/queries';
import UpgradeModal from 'modules/workspaces/shared/UpgradeModal';
import PurpleBanner from 'components/PurpleBanner';
import { ExternalLinkIcon } from './SideColumn';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';

const RewardsBanner = () => {
  const { isAdminMember, currentWorkspaceId, tenantAdmins, isLoadinCurrentUser } = useContext(UserContext);
  const [mutation] = useMutation(SEND_ENABLE_REWARDS_REQUEST);
  const [isRewardModalOpen, setIsRewardModalOpen] = useState(false);
  const navigate = useNavigate();

  if (isLoadinCurrentUser) {
    return null;
  }
  
  const onClickEnableRewards = () => {
    if (isAdminMember) {
      navigate(Resources.workspaceSettingsRewards.path({ workspaceId: currentWorkspaceId }));
    } else {
      setIsRewardModalOpen(true);
    }
  };

  const onClickSend = (e, { isSendToAllAdmins, workspaceAdminId, message }) => {
    return mutation({
      variables: {
        workspaceId: currentWorkspaceId,
        workspaceAdminId: isSendToAllAdmins ? null : workspaceAdminId,
        message,
      },
    });
  };

  return (
    <>
      <UpgradeModal
        isRewards
        isOpen={isRewardModalOpen}
        adminMembers={tenantAdmins}
        onClose={() => setIsRewardModalOpen(false)}
        onClickSend={onClickSend}
      />
      <PurpleBanner
        id='rewards-banner'
        roundAllCorners
        isCollapsable={false}
        style={{ marginBottom: 24 }}
        expandedHeader='Encourage Team Recognition with Rewards'
        description={
          <>
            <strong>How Rewards Works:</strong> Team members give & receive coins from Kudos and feedback. Coins can be redeemed for digital gift cards from this page. Try Rewards today!
          </>
        }
        buttonLabel={isAdminMember ? 'Enable Rewards' : 'Request Rewards'}
        onButtonClick={onClickEnableRewards}
        showSecondaryOnlyOnMobile
        secondaryButtonLabel={<>Learn More <ExternalLinkIcon fill={'white'}/></>}
        secondaryButtonHref={'https://matterapp.com/rewards'}
      />
    </>
  );
};

export default RewardsBanner;