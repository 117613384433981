import styled from 'styled-components';
import { Clickable } from '../../Clickable/Clickable';
import theme, { ColorPalettes, getColorObj } from '@matterapp/matter-theme';

const transitionTime = theme.transitions.times.default;

export { ColorPalettes };

export const SIZES = {
  XS: 'XS',
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
};

export const SWITCH_SIZE = {
  [SIZES.XS]: theme.spacing.half,
  [SIZES.S]: theme.spacing.single,
  [SIZES.M]: theme.spacing.singleAndHalf,
  [SIZES.L]: theme.spacing.double,
  [SIZES.XL]: theme.spacing.doubleAndHalf,
};

export const HEIGHTS = {
  [SIZES.XS]: theme.spacing.single,
  [SIZES.S]: theme.spacing.singleAndHalf,
  [SIZES.M]: theme.spacing.double,
  [SIZES.L]: theme.spacing.doubleAndHalf,
  [SIZES.XL]: theme.spacing.triple,
};

export const WIDTHS = {
  [SIZES.XS]: theme.spacing.singleAndHalf,
  [SIZES.S]: theme.spacing.doubleAndHalf,
  [SIZES.M]: theme.spacing.tripleAndHalf,
  [SIZES.L]: theme.spacing.quadrupleAndHalf,
  [SIZES.XL]: theme.spacing.quintupleAndHalf,
};

export const getToggleColor = ({ color, checked, disabled, disabledColor, indeterminate }) => {
  const baseColor = getColorObj(color, ColorPalettes.Blue);
  const sharedTransition = `background ${transitionTime}, height ${transitionTime}, width ${transitionTime}, padding ${transitionTime}`;
  if (disabled) {
    return `
      background-color: ${disabledColor ? disabledColor : theme.colors.blacks[5]};
      cursor: not-allowed;
    `;
  }
  if (indeterminate) {
    return `
      background-color: ${baseColor[20]};
      transition: ${sharedTransition};

      &:hover {
        background-color: ${baseColor[40]};
      }
      &:active {
        background-color: ${baseColor[50]};
      }
    `;
  }
  if (checked) {
    return `
      background-color: ${baseColor[50]};
      transition: ${sharedTransition};

      &:hover {
        background-color: ${baseColor[60]};
      }
      &:active {
        background-color: ${baseColor[40]};
      }
    `;
  }
  return `
    background-color: ${theme.colors.blacks[10]};
    transition: ${sharedTransition};

    &:hover {
      background-color: ${baseColor[20]};
    }
    &:active {
      background-color: ${baseColor[40]};
    }
  `;
};

export const getToggleSize = ({ size }) => {
  const height = HEIGHTS[size] || HEIGHTS[SIZES.M];
  return `
    border-radius: ${height};
    padding: ${theme.spacing.quarter};
    height: ${height};
    width: ${WIDTHS[size] || WIDTHS[SIZES.M]};
  `;
};

export const getToggleSwitchSize = ({ size }) => {
  const switchSize = SWITCH_SIZE[size] || SWITCH_SIZE[SIZES.M];
  return `
    border-radius: 100%;
    height: ${switchSize};
    width: ${switchSize};
  `;
};

export const getToggleSwitchState = ({ checked, indeterminate }) => {
  const sharedTransition = `transform ${transitionTime}, height ${transitionTime}, width ${transitionTime}`;
  if (indeterminate) {
    return `
      transform: translateX(50%);
      transition: ${sharedTransition};
    `;
  }
  return `
    transform: translateX(${checked ? '100%' : '0%'});
    transition: ${sharedTransition};
  `;
};

export const Container = styled.div`
  display: inherit;
`;

export const ToggleSwitch = styled.div`
  background: ${theme.colors.white};
  border-radius: 100%;
  box-shadow: ${({ disabled }) => theme.shadows[disabled ? 45 : 47]};
  ${getToggleSwitchSize}
  ${getToggleSwitchState}
`;

export const ToggleButton = styled(Clickable)`
  ${getToggleSize}
  ${getToggleColor}
`;
