import { useState, useEffect } from 'react';

export const getPixelValue = (pxValue) =>
  parseInt(pxValue.replace('px', ''), 10);

export const getStickyTopPosition = (elementRef) => {
  if (elementRef.current) {
    const elementHeight = elementRef.current.offsetHeight;
    const windowHeight = window.innerHeight;
    if (elementHeight < windowHeight) {
      return 0;
    }
    return windowHeight - elementHeight;
  }
};

/**
 * Hook to get the top position of a position sticky element in the page layout.
 * @param {Object} containerRef: The ref of the element.
 */
export const useSetStickyTop = (containerRef) => {
  const [top, setTop] = useState(0);

  const setContainerTop = () => {
    setTop(getStickyTopPosition(containerRef));
  };

  useEffect(() => {
    window.addEventListener('resize', setContainerTop);
    window.addEventListener('wheel', setContainerTop);
    setContainerTop();
    return () => {
      window.removeEventListener('resize', setContainerTop);
      window.removeEventListener('wheel', setContainerTop);
    };
  }, []);

  return top;
};
