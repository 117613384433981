import React from 'react';
import apolloClient from 'config/apolloClient';
import { compose, withProps } from 'recompose';
import { GIVE_KUDOS_MUTATION, WORKSPACE_FEED_QUERY } from 'graphql-queries/queries';
import Toast from 'components/Toast/Toast';
import { trackRetentionEvent } from 'libs';

const withSendKudos = compose(
  withProps(
    ({
      onOpenKudosSentModal,
      onCloseSendKudosModal,
      setIsSending,
    }) => ({
      onSendKudos: async ({ receiverUserIds, type, note, workspaceId, isPublic }) => {
        setIsSending(true);
        try {
          await apolloClient.mutate({
            mutation: GIVE_KUDOS_MUTATION,
            variables: { receiverUserIds, type, note, workspaceId, isPublic },
            refetchQueries: () => {
              if (workspaceId) {
                return [{ 
                  query: WORKSPACE_FEED_QUERY,
                  variables: { workspaceId }
                }];
              }
            }
          });
          trackRetentionEvent('kudos');
          setIsSending(false);
          onCloseSendKudosModal();
          onOpenKudosSentModal();
        } catch (error) {
          const errorMessage = (<span><strong>Oops! </strong>Something
            went wrong. Your kudos could not be sent</span>);
          Toast.error(errorMessage);
          
          console.error('Failed to send kudos.', error);
          setIsSending(false);
        }
      },
    })
  )
);

export default withSendKudos;
