import React from 'react';

const Icon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#E9E9F6" />
    <g clipPath="url(#clip0_265_3480)">
      <path d="M30.4802 16.7H25.1774V18.3H30.4802C31.1429 18.3 31.6802 18.8373 31.6802 19.5L31.6802 29.5C31.6802 30.1628 31.1429 30.7 30.4802 30.7L17.4802 30.7C16.8174 30.7 16.2802 30.1628 16.2802 29.5L16.2802 20.0637H14.6802L14.6802 29.5C14.6802 31.0464 15.9338 32.3 17.4802 32.3H30.4802C32.0266 32.3 33.2802 31.0464 33.2802 29.5V19.5C33.2802 17.9536 32.0266 16.7 30.4802 16.7Z" fill="#3E3E42" />
      <path d="M30.4802 16.7H25.1774V18.3H30.4802C31.1429 18.3 31.6802 18.8373 31.6802 19.5L31.6802 29.5C31.6802 30.1628 31.1429 30.7 30.4802 30.7L17.4802 30.7C16.8174 30.7 16.2802 30.1628 16.2802 29.5L16.2802 20.0637H14.6802L14.6802 29.5C14.6802 31.0464 15.9338 32.3 17.4802 32.3H30.4802C32.0266 32.3 33.2802 31.0464 33.2802 29.5V19.5C33.2802 17.9536 32.0266 16.7 30.4802 16.7Z" fill="#4F52B1" />
      <path d="M29.9202 20.73H23.0802V22.33H29.9202V20.73Z" fill="#3E3E42" />
      <path d="M29.9202 20.73H23.0802V22.33H29.9202V20.73Z" fill="#4F52B1" />
      <path d="M29.9402 23.73H18.0802V25.33H29.9402V23.73Z" fill="#3E3E42" />
      <path d="M29.9402 23.73H18.0802V25.33H29.9402V23.73Z" fill="#4F52B1" />
      <path d="M18.0802 26.71H29.8502V28.31H18.0802V26.71Z" fill="#3E3E42" />
      <path d="M18.0802 26.71H29.8502V28.31H18.0802V26.71Z" fill="#4F52B1" />
      <path d="M20.0658 13.4943C19.7534 13.1819 19.2468 13.1819 18.9344 13.4943L15.6644 16.7643L16.7958 17.8957L18.7001 15.9914V21.92H20.3001V15.9914L22.2044 17.8957L23.3358 16.7643L20.0658 13.4943Z" fill="#3E3E42" />
      <path d="M20.0658 13.4943C19.7534 13.1819 19.2468 13.1819 18.9344 13.4943L15.6644 16.7643L16.7958 17.8957L18.7001 15.9914V21.92H20.3001V15.9914L22.2044 17.8957L23.3358 16.7643L20.0658 13.4943Z" fill="#4F52B1" />
    </g>
    <defs>
      <clipPath id="clip0_265_3480">
        <rect width="24" height="24" fill="white" transform="translate(12 12)" />
      </clipPath>
    </defs>
  </svg>

);

export default Icon;
