import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import Header from '../../components/Header';
import { useFlowState } from './hooks';
import { BackButton } from './styles';

const Flow = (props) => {
  const {
    currentDisplayedStep,
    handleClose,
    isFirstStep,
    isOpen,
    onClickBackStep,
    renderBody,
    renderFooter,
    renderTopRight,
    ...modalProps
  } = useFlowState(props);

  return (
    <Modal
      fullScreenMobile
      {...modalProps}
      isOpen={isOpen}
      onClose={handleClose}
    >
      {isOpen && (
        <>
          <Header
            centerOnMobile
            title={currentDisplayedStep.title}
            left={!isFirstStep && <BackButton onClick={onClickBackStep} />}
            right={renderTopRight()}
          />
          <Modal.Body>{renderBody()}</Modal.Body>
          {renderFooter()}
        </>
      )}
    </Modal>
  );
};

Flow.defaultProps = {
  onClose: () => null,
};

Flow.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func, // Replaces `handleCloseModal`
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      renderTopLeft: PropTypes.func,
      renderTopRight: PropTypes.func,
      renderBody: PropTypes.func.isRequired,
      renderFooter: PropTypes.func,
      title: PropTypes.string,
      onGoBack: PropTypes.func, // Replaces `handleGoBack`
    })
  ).isRequired,
};

export default Flow;
