import React from 'react';
import { Resources } from '@matterapp/routing';
import styled from 'styled-components';
import { Page, ButtonNew as Button, Emoji } from '@matterapp/matter-ui';
import { useNavigate } from 'react-router-dom';

const StyledH1 = styled.h1`
color: ${({ theme }) => theme.colors.blacks[90]};
font-size: 30px;
font-weight: 800;
line-height: 38px;
margin-bottom: 48px;
`;

const StyledH2 = styled.h2`
color: ${({ theme }) => theme.colors.blacks[70]};
font-size: 18px;
font-weight: 500;
line-height: 26px;
margin-bottom: 48px;
`;

const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-flow: column nowrap;
margin-bottom: 88px;
padding-bottom: 48px;
min-height: 50vh;
`;

const EmojiContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 48px;
`;

export function NotFound() {
  const navigate = useNavigate();
  
  return (
    <Page layout="SingleColumnV2">
      <Container>
        <StyledH1>Page Not Found</StyledH1>
        <EmojiContainer>
          <Emoji.Badge theString="🙃" />
        </EmojiContainer>
        <StyledH2>
          {"Uh oh, looks like you tried to visit a page that doesn't exist!"}
        </StyledH2>
        <Button primary onClick={() => navigate(Resources.homeMain.path())}>
          Back to Homepage
        </Button>
      </Container>
    </Page>
  );
}

export default NotFound;
