import React from 'react';
import PropTypes from 'prop-types';
import { useGrid } from './hooks';
import {
  Container,
  ExpandButton,
  ExpandContainer,
  GridContainer,
  KudosGrid,
} from './styles';
import Mini from '../Mini';
import { COLLAPSE_TEXT, EXPAND_TEXT, getComposerKudos } from '../consts';

const Grid = (props) => {
  const {
    className,
    expandable,
    expandButtonProps,
    gridContainerProps,
    gridProps,
    miniProps,
    onClickKudos,
    unlockAllKudos,
  } = useGrid(props);
  const { totalKudosList } = props;
  const kudosList = getComposerKudos(totalKudosList);
  const kudos = kudosList && kudosList.map((option, index) => {
    return (
      <Mini
        key={`${index}-${option.type}`}
        isLocked={!unlockAllKudos}
        onClick={onClickKudos}
        totalKudosList={totalKudosList}
        {...miniProps}
        {...option}
      />
    );
  });
  return (
    <Container className={className}>
      <GridContainer {...gridContainerProps}>
        <KudosGrid {...gridProps}>{kudos}</KudosGrid>
      </GridContainer>
      <ExpandContainer expandable={expandable}>
        <ExpandButton {...expandButtonProps} />
      </ExpandContainer>
    </Container>
  );
};

Grid.defaultProps = {
  collapseText: COLLAPSE_TEXT,
  defaultExpanded: false,
  expandable: true,
  expandText: EXPAND_TEXT,
  isCompact: false,
  onClickKudos: () => null,
  unlockAllKudos: true,
};

Grid.propTypes = {
  /** Text to display to collapse grid. */
  collapseText: PropTypes.string,
  /** If the grid is expanded by default. */
  defaultExpanded: PropTypes.bool,
  /** If the grid can be expanded. */
  expandable: PropTypes.bool,
  /** Text to display to expand grid. */
  expandText: PropTypes.string,
  /** Use compact grid (i.e. In Modal). Always hides 'Show More' button. */
  isCompact: PropTypes.bool,
  /** Callback when a kudos item is clicked. */
  onClickKudos: PropTypes.func,
  /** Displays the locked (pro) kudos. */
  showLockedKudos: PropTypes.bool,
  /** If all kudos are unlocked. */
  unlockAllKudos: PropTypes.bool,
};

export default Grid;
