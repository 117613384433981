import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SELECTED_ITEM_SELECTOR } from './Item';
import { MenuContainer } from './sharedStyles';

export const DATA_ROLE = 'menu';

export const getSelectedItem = (items = []) => {
  return items.find((item) => {
    const { props } = item || {};
    return props.isSelected;
  });
};

const Menu = React.forwardRef((props, ref) => {
  const { ContainerComponent, children, items, ...otherProps } = props;
  useEffect(() => {
    if (items) {
      const selectedItem = getSelectedItem(items);
      if (selectedItem) {
        const selectedElement = document.querySelector(SELECTED_ITEM_SELECTOR);
        const parentElement = selectedElement.parentElement;
        parentElement.scrollTop = selectedElement.offsetTop;
      }
    }
  }, []);

  if (items) {
    return (
      <ContainerComponent ref={ref} data-role={DATA_ROLE} {...otherProps}>
        {items}
      </ContainerComponent>
    );
  }
  return <ContainerComponent data-role={DATA_ROLE} {...otherProps}>{children}</ContainerComponent>;
});

Menu.defaultProps = {
  ContainerComponent: MenuContainer,
};

Menu.propTypes = {
  ContainerComponent: PropTypes.elementType,
  /** If there are no items to show, render children elements. */
  children: PropTypes.node,
  /** Dropdown items to show. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isSelected: PropTypes.bool,
    })
  ),
};

Menu.dataRole = DATA_ROLE;

export default Menu;
