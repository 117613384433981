import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Droppable as DroppableDND } from 'react-beautiful-dnd';
import theme from '@matterapp/matter-theme';

export const Container = styled.div`  
  ${({ isDragOver }) => isDragOver ? `
    background: ${theme.colors.white};
  ` : ``}
`;

export default class Droppable extends React.Component {
  static propTypes = {
    /**
     * Function to check if item can be dragged over list.
     *
     * ```
     * canItemDragOver(itemId)
     * ```
     * @param { String } itemId: The id of the item being dragged over.
     * @returns { Boolean } If item can be dragged over.
     */
    canItemDragOver: PropTypes.func,
    /** List items. Must be ```<DragAndDropList.Draggable>``` */
    children: PropTypes.node,
    /** Additional classes. */
    className: PropTypes.string,
    /** If dropping is disabled. */
    disabled: PropTypes.bool,
    /** Unique id for this list. */
    id: PropTypes.string,
  };

  static defaultProps = {
    canItemDragOver: () => true,
    id: 'droppable',
  };

  /**
   * handleItemDragOver
   * Callback function get additional container states when item is dragged over.
   * @param { Object } snapshot: Object containing dnd state.
   * @returns { Object } Additional container props.
   */
  checkDragOver = ({ isDraggingOver, draggingOverWith }) => {
    const { canItemDragOver } = this.props;
    if (isDraggingOver) {
      return canItemDragOver(draggingOverWith)
        ? { isDragOver: true }
        : { isError: true };
    }
    return {};
  };

  renderContents = (provided, snapshot) => {
    const { children, className } = this.props;
    return (
      <Container
        className={className}
        {...this.checkDragOver(snapshot)}
        ref={provided.innerRef}
      >
        {children}
        <span>{provided.placeholder}</span>
      </Container>
    );
  };

  render() {
    const { disabled, id } = this.props;
    return (
      <DroppableDND droppableId={id} isDropDisabled={disabled}>
        {this.renderContents}
      </DroppableDND>
    );
  }
}
