import PropTypes from 'prop-types';

/*****************************************************************************/
/* WEEKDAY PROP TYPE                                                         */
/*****************************************************************************/
export const WEEKDAY = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export const WEEKDAY_LIST = {
  [WEEKDAY.MONDAY]: {
    abbr: 'Mon',
    name: 'Monday',
    label: 'Monday',
    value: WEEKDAY.MONDAY,
  },
  [WEEKDAY.TUESDAY]: {
    abbr: 'Tue',
    name: 'Tuesday',
    label: 'Tuesday',
    value: WEEKDAY.TUESDAY,
  },
  [WEEKDAY.WEDNESDAY]: {
    abbr: 'Wed',
    name: 'Wednesday',
    label: 'Wednesday',
    value: WEEKDAY.WEDNESDAY,
  },
  [WEEKDAY.THURSDAY]: {
    abbr: 'Thu',
    name: 'Thursday',
    label: 'Thursday',
    value: WEEKDAY.THURSDAY,
  },
  [WEEKDAY.FRIDAY]: {
    abbr: 'Fri',
    name: 'Friday',
    label: 'Friday',
    value: WEEKDAY.FRIDAY,
  },
  [WEEKDAY.SATURDAY]: {
    abbr: 'Sat',
    isWeekend: true,
    name: 'Saturday',
    label: 'Saturday',
    value: WEEKDAY.SATURDAY,
  },
  [WEEKDAY.SUNDAY]: {
    abbr: 'Sun',
    isWeekend: true,
    name: 'Sunday',
    label: 'Sunday',
    value: WEEKDAY.SUNDAY,
  },
};

export const WEEKDAY_LIST_START_ON_MONDAY = [
  WEEKDAY_LIST[WEEKDAY.MONDAY],
  WEEKDAY_LIST[WEEKDAY.TUESDAY],
  WEEKDAY_LIST[WEEKDAY.WEDNESDAY],
  WEEKDAY_LIST[WEEKDAY.THURSDAY],
  WEEKDAY_LIST[WEEKDAY.FRIDAY],
  WEEKDAY_LIST[WEEKDAY.SATURDAY],
  WEEKDAY_LIST[WEEKDAY.SUNDAY],
];

export const WEEKDAY_LIST_START_ON_SUNDAY = [
  WEEKDAY_LIST[WEEKDAY.SUNDAY],
  WEEKDAY_LIST[WEEKDAY.MONDAY],
  WEEKDAY_LIST[WEEKDAY.TUESDAY],
  WEEKDAY_LIST[WEEKDAY.WEDNESDAY],
  WEEKDAY_LIST[WEEKDAY.THURSDAY],
  WEEKDAY_LIST[WEEKDAY.FRIDAY],
  WEEKDAY_LIST[WEEKDAY.SATURDAY],
];

export const weekdayDefaultProp = WEEKDAY.FRIDAY;

export const weekdayPropType = PropTypes.oneOf(Object.values(WEEKDAY));