import React from 'react';
import PropTypes from 'prop-types';
import { Advisors } from '@matterapp/matter-ui';
import { useGiveFeedbackWorkspaces } from 'hooks/composer/useGiveFeedback';
import { useGiveFeedbackModal } from './hooks';

const RitualGiveFeedbackModal = ({
  isOpen,
  workspace = {},
  closeModal,
}) => {
  const { giveFeedbackProps } = useGiveFeedbackWorkspaces({ workspace });
  const {
    selectedUser,
    selectedSkill,
    resetState,
    onClickSend,
    selectUserDropdownProps,
    skillTaggerProps,
    tagSkillModalProps,
  } = useGiveFeedbackModal({ ...giveFeedbackProps, workspace, workspaceId: workspace.id });
  const { onSelect } = selectUserDropdownProps;
  const { onReset } = skillTaggerProps;
  const { skills, onSelectSkill} = tagSkillModalProps;

  const handleCloseModal = () => {
    resetState();
    closeModal();
  };

  const handleSendFeedback = (message) => {
    onClickSend(message);
    closeModal();
  };

  return (
    <Advisors.GiveFeedbackModal
      isOpen={isOpen}
      skills={skills}
      selectedDropdownUser={selectedUser}
      selectedSkill={selectedSkill}
      handleSelectUser={onSelect}
      handleSelectSkill={onSelectSkill}
      handleResetSelectedSkill={onReset}
      handleSendFeedback={handleSendFeedback}
      giveFeedbackProps={giveFeedbackProps}
      userDropdownProps={selectUserDropdownProps}
      handleCloseModal={handleCloseModal}
    />
  );
};

RitualGiveFeedbackModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  workspace: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func,
};

export default RitualGiveFeedbackModal;