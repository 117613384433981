import React, { useContext, useEffect } from 'react';
import UserContext from 'context/UserContext/UserContext';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import { 
  Container,
  Icon,
  Box,
  Typography,
  Badge
} from '@mui/material';
import styled from 'styled-components';
import SettingsIcon from '@mui/icons-material/Settings';
import { Resources } from '@matterapp/routing';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import TagIcon from '@mui/icons-material/Tag';
import { IconNew as MatterIcon } from '@matterapp/matter-ui';
import { useUpgradeModal } from 'routes/pages/workspaces/hooks/shared';
import UpgradeModal from 'modules/workspaces/shared/UpgradeModal';
import { useQuery } from '@apollo/client';
import getPendingRewardRequests from 'graphql-queries/queries/rewards/getPendingRewardRequests';

const createIconComponent = (name) => styled(MatterIcon).attrs(() => ({ name }))``;

const StyledIcon = styled(Icon)({
  width: '24px',
  height: '24px'
});

const StyledMobileContainer = styled(Container)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    height: theme.spacing(6),
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    justifyContent: 'space-between'
  }
}));

const StaticMenuItem = styled(Box)(({ theme, isActive }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '48px',
  margin: `${theme.spacing(2)} ${theme.spacing(3)}`,
  cursor: 'pointer',
  backgroundColor: isActive ? theme.palette.purples[50] : 'inherit',
  color: isActive ? theme.palette.white.main : theme.palette.blacks[60],
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: isActive ? theme.palette.purples[50] : theme.palette.blacks[5]
  },
  '&:active': {
    backgroundColor: isActive ? theme.palette.purples[50] : theme.palette.purples[5]
  },
  transitionDuration: '0.2s',
  transitionProperty: 'background-color',
  [theme.breakpoints.down('lg')]: { 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center',
    padding: 0,
    margin: 'auto',
    color: isActive ? theme.palette.white.main : theme.palette.primary.light
  }
}));

const RewardsManagerIcon = createIconComponent('navigationRewards');
const FeedbackIcon = createIconComponent('navigationFeedbacksNew');
const HomeIcon = createIconComponent('navigationHome');
const SurveyIcon = createIconComponent('navigationSurvey');

export default function MobileNav() {
  const { 
    currentTenant, 
    currentWorkspace,
    isAdminMember = true,
    currentWorkspaceId: workspaceId,
    isEnterprise,
    setParams,
    tenantAdmins
  } = useContext(UserContext);
  const { 
    setIsMobileMenuOpen, 
    isChannelMenuOpen,
    setIsChannelMenuOpen,
    isFeedbackMenuOpen, 
    setIsFeedbackMenuOpen,
    isAdminMenuOpen,
    setIsAdminMenuOpen
  } = useContext(MobileMenuContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const upgradeModalProps = useUpgradeModal(currentWorkspace, tenantAdmins, false);

  const { data: pendingRewardRequestsData } = useQuery(getPendingRewardRequests, {
    variables: {
      tenantId: currentTenant?.id
    },
    skip: !currentTenant?.id,
  });

  const numberOfRewardsNotifications = pendingRewardRequestsData?.getPendingRewardRequests?.length;

  const homePath = Resources.workspacesActivityFeed.path({ workspaceId });
  const rewardsPath = Resources.rewardsStore.path({ workspaceId });
  const surveysPath = Resources.surveys.path({ workspaceId });

  useEffect(() => {
    setParams(params);
  }, [params]);

  return (
    <StyledMobileContainer>
      {isEnterprise && currentTenant?.workspaces.length > 1 ? (
        <StaticMenuItem 
          isActive={location.pathname === homePath && isChannelMenuOpen}
          onClick={() => {       
            if (location.pathname === homePath) {
              setIsChannelMenuOpen(currentTenant?.workspaces.length > 1);
            } else {
              navigate(homePath);
            }
          }}
        >
          <StyledIcon 
            component={TagIcon}
          />
          <Typography 
            variant='body3'
          >
            Channels
          </Typography>
        </StaticMenuItem>
      ) : (
        <StaticMenuItem 
          isActive={location.pathname === homePath && !isFeedbackMenuOpen}
          onClick={() => {
            navigate(homePath);
            setIsMobileMenuOpen(false);
          }}
        >
          <StyledIcon 
            component={HomeIcon}
          />
          <Typography 
            variant='body3'
          >
            Home
          </Typography>
        </StaticMenuItem>
      )}
      <StaticMenuItem 
        isActive={location.pathname === rewardsPath && !isFeedbackMenuOpen && !isChannelMenuOpen}
        onClick={() => {
          navigate(rewardsPath);
          setIsMobileMenuOpen(false);
        }}
      >
        <StyledIcon 
          component={RewardsManagerIcon}
        />
        <Typography 
          variant='body3'
          sx={{ userSelect: 'none' }}
        >
          Rewards
        </Typography>
      </StaticMenuItem>

      <StaticMenuItem 
        isActive={location.pathname === surveysPath && !isFeedbackMenuOpen && !isChannelMenuOpen}
        onClick={() => {
          navigate(surveysPath);
          setIsMobileMenuOpen(false);
        }}
      >
        <Icon 
          component={SurveyIcon}
          width={16}
        />
        <Typography 
          variant='body3'
          component='p'
          sx={{ userSelect: 'none', marginTop: '4px', marginBottom: '1px' }}
        >
          Surveys
        </Typography>
      </StaticMenuItem>

      <StaticMenuItem 
        isActive={isFeedbackMenuOpen}
        onClick={() => {
          setIsFeedbackMenuOpen(true);
        }}
      >
        <StyledIcon 
          component={FeedbackIcon}
        />
        <Typography 
          variant='body3'
          sx={{ userSelect: 'none' }}
        >
            Feedback
        </Typography>
      </StaticMenuItem>
      {isAdminMember && (
        <StaticMenuItem 
          isActive={isAdminMenuOpen}
          onClick={() => {
            setIsAdminMenuOpen(true);
          }}
        >
          <Badge badgeContent={numberOfRewardsNotifications} color='error'>
            <StyledIcon 
              component={SettingsIcon}
            />
          </Badge>
          <Typography 
            variant='body3'
            sx={{ userSelect: 'none' }}
          >
            Admin
          </Typography>
        </StaticMenuItem>
      )}
      <UpgradeModal {...upgradeModalProps} />
    </StyledMobileContainer>
  );
}
