import React from 'react';
import BackgroundAnimation, { ANIMATIONS } from '../../BackgroundAnimation';
import ColoredBackground from '../../ColoredBackground';
import gsap from 'gsap';
import * as S from '../../../../Backgrounds/Elements/SpaceThree';

export default class EndBackground extends BackgroundAnimation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  /**
   * Moves the planets in.
   * @returns { Object } The animation timeline.
   */
  movePlanetsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { opacity: 1, x: 0, duration: 1 };
    tl.to(this.bottomLeftPlanetRef, { x: -130, opacity: 0 });
    tl.to(this.bottomRightPlanetRef, { x: 130, opacity: 0 });

    tl.to(this.bottomLeftPlanetRef, resetProps, '<');
    tl.to(this.bottomRightPlanetRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the planets out.
   * @returns { Object } The animation timeline.
   */
  movePlanetsOut = () => {
    const tl = gsap.timeline({ defaults: { opacity: 0, duration: 0.6 } });
    const initialProps = { opacity: 1, x: 0, duration: 0 };
    tl.to(this.bottomLeftPlanetRef, initialProps);
    tl.to(this.rightPlanetRef, initialProps);

    tl.to(this.bottomLeftPlanetRef, { x: -130 }, '<');
    tl.to(this.bottomRightPlanetRef, { x: 130 }, '<');
    return tl;
  };

  /**
   * Moves the rings in.
   * @returns { Object } The animation timeline.
   */
  moveRingsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 2, opacity: 0, transformOrigin: 'center' };
    const normalizedProps = { scale: 1, opacity: 1, duration: 0.4 };
    const ringRefs = {
      bottomRing1Ref: '<0.1',
      bottomRing2Ref: '<0.2',
      bottomRing3Ref: '<0.1',
      bottomRing4Ref: '<0.2',
      rightRing1Ref: '<-0.1',
      rightRing2Ref: '<',
      rightRing3Ref: '<0.2',
      leftRing1Ref: '<-0.3',
      leftRing2Ref: '<0.1',
      leftRing3Ref: '<',
      leftRing4Ref: '<0.1',
      leftRing5Ref: '<-0.2',
      topLeftRingRef: '<-0.3',
      topRightRingRef: '<0.2',
    };
    Object.keys(ringRefs).forEach((key) => {
      tl.to(this[key], initialProps);
    });
    Object.keys(ringRefs).forEach((key) => {
      tl.to(this[key], normalizedProps, ringRefs[key]);
    });
    return tl;
  };

  /**
   * Moves the dots in.
   * @returns { Object } The animation timeline.
   */
  moveDotsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, opacity: 1, duration: 0.7 };
    const dotRefs = {
      topRightDotRef: { x: -42, y: 70 },
      bottomDot1Ref: { x: -64, y: -19 },
      bottomDot2Ref: { x: 35, y: -48 },
      bottomDot3Ref: { x: -27, y: -63 },
      bottomDot4Ref: { x: 34, y: 77 },
      bottomLineRef: { x: 36, y: -36 },
      rightDot1Ref: { x: -83, y: -52 },
      rightDot2Ref: { x: 17, y: -61 },
      rightDot3Ref: { x: -25, y: -48 },
      rightDot4Ref: { x: -20, y: -44 },
      rightDot5Ref: { x: -85, y: 26 },
      rightDot6Ref: { x: -47, y: -18 },
      rightDot7Ref: { x: 52, y: -23 },
      rightDot8Ref: { x: -43, y: -37 },
      rightDot9Ref: { x: -9, y: -42 },
      rightDot10Ref: { x: -63, y: -8 },
      rightDot11Ref: { x: 28, y: 6 },
      rightDot12Ref: { x: -9, y: 57 },
      rightDot13Ref: { x: 6, y: -44 },
      rightDot14Ref: { x: 11, y: -65 },
      rightDot15Ref: { x: 63, y: -13 },
      leftDot1Ref: { x: 74, y: -18 },
      leftDot2Ref: { x: -4, y: -51 },
      leftDot3Ref: { x: 42, y: -47 },
      leftDot4Ref: { x: 5, y: -68 },
      leftDot5Ref: { x: 38, y: 26 },
      leftDot6Ref: { x: -24, y: -33 },
      leftDot7Ref: { x: 48, y: -52 },
      leftDot8Ref: { x: -61, y: 35 },
      leftDot9Ref: { x: 28, y: 49 },
      leftDot10Ref: { x: -14, y: 37 },
      leftDot11Ref: { x: -11, y: -84 },
      leftDot12Ref: { x: -54, y: 7 },
      leftDot13Ref: { x: 42, y: 51 },
      leftTear1Ref: { x: -62, y: -42, opacity: 0 },
      leftTear2Ref: { x: -32, y: -22, opacity: 0 },
      leftLineRef: { x: 41, y: -41 },
      centerDotRef: { x: -4, y: 90 },
      topLineRef: { x: -27, y: -27 },
    };
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], dotRefs[key]);
    });
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], resetProps, '<');
    });
    return tl;
  };

  playAnimation = () => {
    const { animation } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    if (animation !== ANIMATIONS.SHOW) {
      if (animation === ANIMATIONS.OUT) {
        tl.add(this.movePlanetsOut(), '<');
      } else {
        tl.add(this.movePlanetsIn(), '<0.3');
        tl.add(this.moveRingsIn(), '<0.1');
        tl.add(this.moveDotsIn(), '<-0.1');
      }
    }
    this.tl = tl;
    return tl;
  };

  render() {
    return (
      <ColoredBackground colorizeBackground {...this.props}>
        <S.BottomLine ref={(ref) => (this.bottomLineRef = ref)} />
        <S.BottomDot1 ref={(ref) => (this.bottomDot1Ref = ref)} />
        <S.BottomDot2 ref={(ref) => (this.bottomDot2Ref = ref)} />
        <S.BottomDot3 ref={(ref) => (this.bottomDot3Ref = ref)} />
        <S.BottomDot4 ref={(ref) => (this.bottomDot4Ref = ref)} />
        <S.BottomRing1 ref={(ref) => (this.bottomRing1Ref = ref)} />
        <S.BottomRing2 ref={(ref) => (this.bottomRing2Ref = ref)} />
        <S.BottomRing3 ref={(ref) => (this.bottomRing3Ref = ref)} />
        <S.BottomRing4 ref={(ref) => (this.bottomRing4Ref = ref)} />
        <S.TopRightDot ref={(ref) => (this.topRightDotRef = ref)} />
        <S.RightDot1 ref={(ref) => (this.rightDot1Ref = ref)} />
        <S.RightDot2 ref={(ref) => (this.rightDot2Ref = ref)} />
        <S.RightDot3 ref={(ref) => (this.rightDot3Ref = ref)} />
        <S.RightDot4 ref={(ref) => (this.rightDot4Ref = ref)} />
        <S.RightDot5 ref={(ref) => (this.rightDot5Ref = ref)} />
        <S.RightDot6 ref={(ref) => (this.rightDot6Ref = ref)} />
        <S.RightDot7 ref={(ref) => (this.rightDot7Ref = ref)} />
        <S.RightDot8 ref={(ref) => (this.rightDot8Ref = ref)} />
        <S.RightDot9 ref={(ref) => (this.rightDot9Ref = ref)} />
        <S.RightDot10 ref={(ref) => (this.rightDot10Ref = ref)} />
        <S.RightDot11 ref={(ref) => (this.rightDot11Ref = ref)} />
        <S.RightDot12 ref={(ref) => (this.rightDot12Ref = ref)} />
        <S.RightDot13 ref={(ref) => (this.rightDot13Ref = ref)} />
        <S.RightDot14 ref={(ref) => (this.rightDot14Ref = ref)} />
        <S.RightDot15 ref={(ref) => (this.rightDot15Ref = ref)} />
        <S.RightRing1 ref={(ref) => (this.rightRing1Ref = ref)} />
        <S.RightRing2 ref={(ref) => (this.rightRing2Ref = ref)} />
        <S.RightRing3 ref={(ref) => (this.rightRing3Ref = ref)} />
        <S.LeftDot1 ref={(ref) => (this.leftDot1Ref = ref)} />
        <S.LeftDot2 ref={(ref) => (this.leftDot2Ref = ref)} />
        <S.LeftDot3 ref={(ref) => (this.leftDot3Ref = ref)} />
        <S.LeftDot4 ref={(ref) => (this.leftDot4Ref = ref)} />
        <S.LeftDot5 ref={(ref) => (this.leftDot5Ref = ref)} />
        <S.LeftDot6 ref={(ref) => (this.leftDot6Ref = ref)} />
        <S.LeftDot7 ref={(ref) => (this.leftDot7Ref = ref)} />
        <S.LeftDot8 ref={(ref) => (this.leftDot8Ref = ref)} />
        <S.LeftDot9 ref={(ref) => (this.leftDot9Ref = ref)} />
        <S.LeftDot10 ref={(ref) => (this.leftDot10Ref = ref)} />
        <S.LeftDot11 ref={(ref) => (this.leftDot11Ref = ref)} />
        <S.LeftDot12 ref={(ref) => (this.leftDot12Ref = ref)} />
        <S.LeftDot13 ref={(ref) => (this.leftDot13Ref = ref)} />
        <S.LeftTear1 ref={(ref) => (this.leftTear1Ref = ref)} />
        <S.LeftTear2 ref={(ref) => (this.leftTear2Ref = ref)} />
        <S.LeftLine ref={(ref) => (this.leftLineRef = ref)} />
        <S.LeftRing1 ref={(ref) => (this.leftRing1Ref = ref)} />
        <S.LeftRing2 ref={(ref) => (this.leftRing2Ref = ref)} />
        <S.LeftRing3 ref={(ref) => (this.leftRing3Ref = ref)} />
        <S.LeftRing4 ref={(ref) => (this.leftRing4Ref = ref)} />
        <S.LeftRing5 ref={(ref) => (this.leftRing5Ref = ref)} />
        <S.CenterDot ref={(ref) => (this.centerDotRef = ref)} />
        <S.TopLine ref={(ref) => (this.topLineRef = ref)} />
        <S.TopRightRing ref={(ref) => (this.topRightRingRef = ref)} />
        <S.TopLeftRing ref={(ref) => (this.topLeftRingRef = ref)} />
        <S.BottomRightPlanet ref={(ref) => (this.bottomRightPlanetRef = ref)} />
        <S.BottomLeftPlanet ref={(ref) => (this.bottomLeftPlanetRef = ref)} />
      </ColoredBackground>
    );
  }
}
