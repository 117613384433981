import Base from './List';
import WithAddRemoveBase from './WithAddRemove';
import { WithAddRemoveEmail, WithAddRemoveCustomSubHeader } from './WithAddRemoveVariants';

const WithAddRemove = WithAddRemoveBase;
WithAddRemove.CustomSubHeader = WithAddRemoveCustomSubHeader; // List.WithAddRemove.CustomSubHeader
WithAddRemove.Email = WithAddRemoveEmail; // List.WithAddRemove.Email

const List = Base;
List.WithAddRemove = WithAddRemove;

export { List, WithAddRemove };
export default List;