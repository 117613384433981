import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxPill } from '@matterapp/matter-ui';
import { Field as ReduxFormField } from 'redux-form';
import { QUALITY_CHECKBOX_NAME } from '../../app-consts';

class QualityCheckboxes extends React.Component {
  renderCheckbox = (reduxFormFieldProps) => {
    const { label, index, input } = reduxFormFieldProps;
    return (
      <CheckboxPill
        key={label}
        label={label}
        index={index}
        {...input}
        disabled={!input.checked && reduxFormFieldProps.isSelectedMax}
      />
    );
  };
  renderCheckboxes = () => {
    const { qualities, isSelectedMax } = this.props;
    return qualities.map((quality, index) => (
      <ReduxFormField
        key={quality.id}
        component={this.renderCheckbox}
        name={QUALITY_CHECKBOX_NAME(quality.id)}
        type="checkbox"
        label={quality.name}
        value={quality.id}
        isSelectedMax={isSelectedMax}
        index={index}
      />
    ));
  };
  render = () => (
    <ReduxFormField name="qualityIds" component={this.renderCheckboxes} />
  );
}

QualityCheckboxes.propTypes = {
  qualities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isSelectedMax: PropTypes.bool.isRequired,
};

export default QualityCheckboxes;
