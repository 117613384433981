import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Resources } from '@matterapp/routing';
import ConnectButton from './ConnectButton';
import { mixpanel } from 'libs/tracking';
import {
  Panel,
  ConnectedAccountItem,
  List,
  ListItem,
} from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

const RECOMMENDED_LABEL = '(recommended)';

/* Styled Components */
const ConnectYourAccountsListItem = styled(ConnectedAccountItem).attrs({
  iconSize: 40,
  isDisplayedInList: true,
})`
  flex: 1;
`;

/* Styled Components */
const DescriptionText = styled.span`
  font-size: ${({ theme }) => theme.sizes.fontSizes[50]};
  line-height: ${({ theme }) => theme.sizes.lineHeights[50]};
  vertical-align: middle;
`;

/* Page Text & Constants */
const PANEL_TITLE = 'Syncing your contacts is the fastest way to grow';
const PANEL_DESCRIPTION = 'We never contact anyone without your permission.';

const getHandleTrack = {
  clickConnectGoogle: (trackingSource) =>
    mixpanel.createClickEventSender(`${trackingSource}/connect-google-click`),
  clickConnectSlack: (trackingSource) =>
    mixpanel.createClickEventSender(`${trackingSource}/connect-slack-click`),
  clickConnectOutlook: (trackingSource) =>
    mixpanel.createClickEventSender(`${trackingSource}/connect-outlook-click`),
};

export default class ConnectYourAccountsPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.successRedirectConnectAccount = Resources.findYourPeersPeerSuggestions.path();
  }

  static propTypes = {
    trackingSource: PropTypes.string,
  };

  /**
   * Get the account items to show in the list based on the props passed to display the.
   * @returns { Array } List of account items to display.
   */
  getItems = () => {
    const { trackingSource } = this.props;
    return [
      {
        account: {
          name: 'Google',
          iconName: 'socialGoogle',
        },
        button: (
          <ConnectButton
            onClick={getHandleTrack.clickConnectGoogle(trackingSource)}
            successRedirect={this.successRedirectConnectAccount}
            rcLabel="connectGoogleButton"
            oAuthId="GoogleCombinedReadOnly"
          />
        ),
      },
      {
        account: {
          name: 'Outlook',
          iconName: 'socialOutlook',
        },
        button: (
          <ConnectButton
            onClick={getHandleTrack.clickConnectOutlook(trackingSource)}
            successRedirect={this.successRedirectConnectAccount}
            rcLabel="connectOutlookButton"
            oAuthId="OutlookCombinedReadOnly"
          />
        ),
      },
      {
        account: {
          name: 'Slack',
          iconName: 'socialSlack',
        },
        button: (
          <ConnectButton
            onClick={getHandleTrack.clickConnectSlack(trackingSource)}
            successRedirect={this.successRedirectConnectAccount}
            rcLabel="connectSlackButton"
            oAuthId="SlackContactsReadOnly"
          />
        ),
      },
    ];
  };

  renderItem = (listItem) => {
    const { account, button } = listItem;
    const recommendedLabel = account.isRecommended ? RECOMMENDED_LABEL : '';
    return (
      <ListItem key={account.name}>
        <ConnectYourAccountsListItem
          accountName={account.name}
          button={button}
          iconName={account.iconName}
          label={recommendedLabel}
          textColor={theme.colors.purple}
        />
      </ListItem>
    );
  };

  renderList() {
    return <List items={this.getItems()} renderItem={this.renderItem} />;
  }

  render() {
    return (
      <Panel.Base>
        <Panel.Header.Basic title={PANEL_TITLE} size={Panel.Header.SIZE.LARGE}>
          <DescriptionText>{PANEL_DESCRIPTION}</DescriptionText>
        </Panel.Header.Basic>
        {this.renderList()}
      </Panel.Base>
    );
  }
}
