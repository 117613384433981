import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Rater } from '@matterapp/matter-ui';
import Sidebar from '../Sidebar';
import SkillAbilities from './SkillAbilities';
import SkillIconSidebar from './SkillIconSidebar';
import SkillNote from './SkillNote';
import StepContainerComponent from '../StepContainer';
import {
  setRating,
  getRating,
  skillHasAbilities,
  getSkillAbilitiesStepNumber,
  getSkillNoteStepNumber,
} from '../utils';
import {
  getSkillColorPalette,
  getFormattedDescription,
  skillRatingsDescriptionsDefault,
} from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

const REPLACE_NAME = '%n';
const REPLACE_TEXT = '%s';
const SKILL_STEP_TIMEOUT = 500;
const SKILL_RATER_HEADER = `How has ${REPLACE_NAME} ${REPLACE_TEXT} been?`;
const SKILL_ABILITIES_HEADER = `What can ${REPLACE_NAME} start doing to grow in this skill?`;
const SKILL_NOTE_HEADER =
  'Elaborate on your rating by adding to the note below.';
const NEXT_STEP_AUTO_PROGRESS_TIMEOUT = 300;

const RATER_STEP_SIZE = 120;
const NUMBER_OF_RATINGS = 5;
const MAX_AMOUNT_OF_SELECTED_ABILITIES = 3;

const QUICK_TIPS = {
  ABILITIES: 'Select up to three abilities from the grid on the left',
  NOTE_1: 'Add context to your rating by providing concrete examples.',
  NOTE_2: 'Describe what they can start, stop, or continue doing.',
  NOTE_3: 'Give examples of what a "home-run" would look like.',
};

const getCurrentSkillStepStyle = ({ currentSkillStep }) =>
  `transform: translateX(${-(currentSkillStep - 1) * 100}%)`;

const Wrapper = styled.div``;

const Container = styled.div`
  display: flex;
  transition: transform ${theme.transitions.times.default};
  ${getCurrentSkillStepStyle}
`;

/**
 * On desktop, the step containerheight is 0 when not shown and on mobile
 * it always takes up the entire content space.
 */
/* istanbul ignore next */
const StepContainer = styled(StepContainerComponent)`
  ${({ isShown }) => (isShown ? `max-height: unset;` : `max-height: 0;`)}
  ${theme.media.S`
    max-height: unset;
  `}
`;

const SkillRater = styled(Rater.Skill)`
  max-width: ${(RATER_STEP_SIZE + 4) * NUMBER_OF_RATINGS}px;
  height: ${RATER_STEP_SIZE}px;

  & ${Rater.Skill.Step} {
    font-size: ${theme.fontSize.XXXL};
    font-weight: ${theme.fontWeight.bold};
  }
`;

export default class SkillStep extends React.PureComponent {
  static propTypes = {
    /** Timeout duration of the step animation. */
    animationTimeout: PropTypes.number,
    /** The current step number of the timeline. Not specifically the currently open step. */
    currentStep: PropTypes.number,
    /** The current skill step. */
    currentSkillStep: PropTypes.number,
    /** Function to generate default note. It is up to parent component to get it in skill state. `SkillToRate` of step is passed to help generate note. */
    generateDefaultNote: PropTypes.func,
    /** If this step is currently open. */
    isOpen: PropTypes.bool,
    /** The max length of the custom note. */
    maxNoteLength: PropTypes.number.isRequired,
    /** The min length of the custom note. */
    minNoteLength: PropTypes.number.isRequired,
    /** Callback when the list of current selected abilities changes. */
    onChangeCurrentAbilities: PropTypes.func.isRequired,
    /** Callback when the current note is changed. */
    onChangeCurrentNote: PropTypes.func.isRequired,
    /** Callback when the current rating is changed. */
    onChangeRating: PropTypes.func.isRequired,
    /** Callback when skill rater guide modal opens.  */
    onOpenRatingGuideModal: PropTypes.func,
    /** The data of the receiver of the feedback. */
    receiver: PropTypes.shape({
      firstName: PropTypes.string,
      fullName: PropTypes.string,
    }).isRequired,
    /** The skill to rate for this step. */
    skillToRate: PropTypes.shape({
      skill: PropTypes.shape({
        description: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }).isRequired,
      actionItemSelections: PropTypes.arrayOf(
        PropTypes.shape({
          ability: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
          selected: PropTypes.bool,
        })
      ),
      /** The current note of this skill. */
      currentNote: PropTypes.string,
      /** The current rating of this skill. */
      currentRating: PropTypes.number,
      /** The prior rating of this skill. */
      priorRating: PropTypes.number,
    }).isRequired,
    onNextSkillStep: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    animationTimeout: SKILL_STEP_TIMEOUT,
    generateDefaultNote: () => null,
  };

  static IconSidebar = SkillIconSidebar;

  constructor(props) {
    super(props);
    this.state = {
      currentSkillStep: 1,
      ratingSetOnce: false,
      abilitiesSetOnce: false,
    };
    this.autoNextStepTimeout = null;
    this.closeTimeout = null;
  }

  componentDidMount = () => {
    this._mounted = true;
  };

  componentWillUnmount = () => {
    this._mounted = false;
    window.removeEventListener('keydown', this.handleKeyDown);
    clearTimeout(this.autoNextStepTimeout);
    clearTimeout(this.closeTimeout);
  };

  componentDidUpdate(prevProps) {
    const {
      animationTimeout,
      currentStep,
      currentSkillStep,
      generateDefaultNote,
      isOpen,
      skillToRate,
    } = this.props;

    const stepJustClosed = prevProps.isOpen && !isOpen;
    const currentStepOffset = Math.abs(
      this.state.currentSkillStep - currentSkillStep
    );
    const currentlyOpen =
      prevProps.isOpen && isOpen && prevProps.currentStep === currentStep;

    if (stepJustClosed) {
      this.closeTimeout = setTimeout(this.resetStep, animationTimeout);
    } else if (currentlyOpen && currentStepOffset === 1) {
      this.setState({ currentSkillStep });
    }

    if (
      currentlyOpen &&
      prevProps.currentSkillStep === getSkillAbilitiesStepNumber(skillToRate) &&
      currentSkillStep === getSkillNoteStepNumber(skillToRate)
    ) {
      generateDefaultNote(this.props);
    }
    if (isOpen && currentSkillStep === 1 && !this.keyboardListener) {
      this.keyboardListener = true;
      window.addEventListener('keydown', this.handleKeyDown);
    } else if (
      !(isOpen && currentSkillStep === 1) &&
      this.keyboardListener
    ) {
      this.keyboardListener = false;
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleKeyDown = (e) => {
    const { key } = e;
    const value = parseInt(key, 10);
    if (value) {
      const rating = getRating(this.props.skillToRate.currentRating);
      this.handleChangeRating(e, { rating, value });
    }
  };

  /**
   * Function to get updated skillToRate merged with updated props.
   * @param { Object } updatedProps: Updated prop
   */
  getUpdatedSkillToRate = (updatedProps) => {
    const { skillToRate } = this.props;
    return { ...skillToRate, ...updatedProps };
  };

  /**
   * Resets the state of the skill step.
   * @returns { void }
   */
  resetStep = () => {
    if (this._mounted) {
      this.setState({ currentSkillStep: 1 });
    }
  };

  handleAutoNextStep = () => {
    this.autoNextStepTimeout = setTimeout(
      this.props.onNextSkillStep,
      NEXT_STEP_AUTO_PROGRESS_TIMEOUT
    );
  };

  /**
   * Callback when the value of selected abilities changes.
   *
   * Calls `onChangeCurrentAbilities` prop with change event object and
   * updated component props containing the updated `currentAbilities` value.
   *
   * @param { Object } e: The change event.
   * @param { Object } abilityProps: Props of the SkillAbilities component with updated value.
   * @returns { void }
   */
  handleChangeCurrentAbilities = (e, abilityProps) => {
    const { abilitiesSetOnce } = this.state;
    const { onChangeCurrentAbilities, onNextSkillStep } = this.props;
    const { abilities, currentAbilities } = abilityProps;
    onChangeCurrentAbilities(e, {
      ...this.props,
      actionItemSelections: abilities,
      currentAbilities,
      skillToRate: this.getUpdatedSkillToRate({
        actionItemSelections: abilities,
      }),
    });
    if (
      !abilitiesSetOnce &&
      currentAbilities &&
      currentAbilities.length === MAX_AMOUNT_OF_SELECTED_ABILITIES &&
      onNextSkillStep
    ) {
      this.handleAutoNextStep();
      this.setState({ abilitiesSetOnce: true });
    }
  };

  /**
   * Callback when the value of the current note changes.
   *
   * Calls `onChangeCurrentNote` prop with change event object and
   * updated component props containing the updated `currentNote` value.
   *
   * @param { Object } e: The change event.
   * @returns { void }
   */
  handleChangeCurrentNote = (e) => {
    const currentNote = e.target.value;
    this.props.onChangeCurrentNote(e, {
      ...this.props,
      currentNote,
      skillToRate: this.getUpdatedSkillToRate({ currentNote }),
    });
  };

  /**
   * Callback when the value of the current rating changes.
   *
   * Calls `onChangeRating` prop with change event object and
   * updated component props containing the updated `currentRating` value.
   *
   * @param { Object } e: The change event.
   * @param { Object } raterProps: Props of the Rater component with updated value.
   * @returns { void }
   */
  handleChangeRating = (e, { prevRating, value }) => {
    const { ratingSetOnce } = this.state;
    const { onChangeRating, onNextSkillStep } = this.props;
    const currentRating = prevRating === value ? null : setRating(value);
    onChangeRating(e, {
      ...this.props,
      currentRating,
      skillToRate: this.getUpdatedSkillToRate({ currentRating }),
    });
    if (!ratingSetOnce && onNextSkillStep) {
      this.handleAutoNextStep();
      this.setState({ ratingSetOnce: true });
    }
  };

  renderContents = () => {
    const { currentSkillStep } = this.state;
    const {
      skillToRate,
      onOpenRatingGuideModal,
      maxNoteLength,
      minNoteLength,
      receiver,
    } = this.props;
    const {
      skill,
      actionItemSelections,
      currentNote,
      currentRating,
    } = skillToRate;
    const color = getSkillColorPalette(skill.name).toLowerCase();
    const firstName = receiver.firstName || '';
    const receiverFirstNamePlural = `${firstName}'${
      firstName.endsWith('s') ? '' : 's'
    }`;
    const raterHeader = SKILL_RATER_HEADER.replace(
      REPLACE_TEXT,
      skill.name
    ).replace(REPLACE_NAME, receiverFirstNamePlural);
    const noRatingDescription = getFormattedDescription(
      skillRatingsDescriptionsDefault,
      receiver.firstName
    );

    const abilitiesSidebar = (
      <Sidebar.QuickTips>
        <Sidebar.Paragraph>{QUICK_TIPS.ABILITIES}</Sidebar.Paragraph>
      </Sidebar.QuickTips>
    );

    const noteSidebar = (
      <Sidebar.QuickTips>
        <Sidebar.Paragraph>{QUICK_TIPS.NOTE_1}</Sidebar.Paragraph>
        <Sidebar.Paragraph>{QUICK_TIPS.NOTE_2}</Sidebar.Paragraph>
        <Sidebar.Paragraph>{QUICK_TIPS.NOTE_3}</Sidebar.Paragraph>
      </Sidebar.QuickTips>
    );

    return (
      <Container currentSkillStep={this.state.currentSkillStep}>
        <StepContainer header={raterHeader} isShown={currentSkillStep === 1}>
          <SkillRater
            color={color}
            noRatingDescription={noRatingDescription}
            onClickStep={this.handleChangeRating}
            onOpenRatingGuideModal={onOpenRatingGuideModal}
            rating={getRating(currentRating)}
            receiversFirstName={receiver.firstName}
          />
        </StepContainer>
        {!!skillHasAbilities(skillToRate) && (
          <StepContainer
            header={SKILL_ABILITIES_HEADER.replace(REPLACE_NAME, firstName)}
            sidebarContent={abilitiesSidebar}
            isShown={
              currentSkillStep === getSkillAbilitiesStepNumber(skillToRate)
            }
          >
            <SkillAbilities
              abilities={actionItemSelections}
              color={color}
              onChange={this.handleChangeCurrentAbilities}
            />
          </StepContainer>
        )}
        <StepContainer
          header={SKILL_NOTE_HEADER}
          sidebarContent={noteSidebar}
          isShown={currentSkillStep === getSkillNoteStepNumber(skillToRate)}
        >
          <SkillNote
            abilities={actionItemSelections}
            color={color}
            maxLength={maxNoteLength}
            minLength={minNoteLength}
            onChange={this.handleChangeCurrentNote}
            value={currentNote}
          />
        </StepContainer>
      </Container>
    );
  };

  render() {
    return (
      <Wrapper>
        {this.props.children}
        {this.renderContents()}
      </Wrapper>
    );
  }
}

export { getCurrentSkillStepStyle };
