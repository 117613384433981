import React, { useEffect, useContext } from 'react';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import { AppLayout } from 'modules';
import SurveyResponseView from './SurveyResponseView';

export default function SurveyResponsePage() {
  const { setTopNavItem } = useContext(MobileMenuContext);

  useEffect(() => {
    setTopNavItem(null);
  }, []);


  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        <SurveyResponseView />
      }
    />
  );
}
