/**
 * Return the difference in days of (baseDate - targetDate).
 */

export const daysAgo = (startDate, days) => {
  const date = new Date(startDate);
  date.setDate(date.getDate() - days);
  return date;
};

export const hoursAgo = (startDate, hours) => {
  const date = new Date(startDate);
  date.setHours(date.getHours() - hours);
  return date;
};

export const daysDifference = ({
  firstDate,
  secondDate,
}) => {
  // Get 1 day in milliseconds
  const one_day = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  const date1_ms = firstDate.getTime();
  const date2_ms = secondDate.getTime();

  // Calculate the difference in milliseconds
  const difference_ms = date2_ms - date1_ms;

  // Convert back to days and return
  const diff = Math.round(difference_ms / one_day);
  return Math.abs(diff);
};

export const isNumeric = (val) => {
  return Number.isFinite(val);
};

export const daysLeft = ({
  firstDate,
  secondDate,
}) => {
  // Get 1 day in milliseconds
  const one_day = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  const date1_ms = firstDate.getTime();
  const date2_ms = secondDate.getTime();

  // Calculate the difference in milliseconds
  const difference_ms = date2_ms - date1_ms;

  return Math.ceil(difference_ms / one_day);
};
