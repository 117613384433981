import { useEffect, useContext } from 'react';
import { Resources } from '@matterapp/routing';
import { ENV_VARS } from 'app-consts/env-vars';
import Toast from 'components/Toast/Toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserContext from 'context/UserContext/UserContext';

function AuthSignout() {
  const { signOut } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const showNotification = searchParams.get('notification') === 'true';
  const destinationRoute = searchParams.get('destinationRoute');
  
  async function signOutAndNavigate() {
    const redirectDelay = showNotification ? 1000 : 0;

    await signOut();
    if (showNotification) {
      Toast.success('Successfully signed out');
    }

    setTimeout(() => {
      if (destinationRoute) {
        navigate(Resources[destinationRoute].path());
      } else {
        window.location.href = (ENV_VARS.LOGGED_OUT_PATH);
      }
    }, redirectDelay);
  }

  useEffect(() => {
    signOutAndNavigate();
  }, []);

  return null;
}

export default AuthSignout;
