import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'hocs/withRouter';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Form, Button, Flow } from '@matterapp/matter-ui';
import apolloClient from 'config/apolloClient';
import { Formik, Field } from 'formik';
import { Resources } from '@matterapp/routing';
import Toast from 'components/Toast/Toast';
import {
  EMAIL_LOGIN_CODE_IF_USER_EXISTS_MUTATION,
  EMAIL_LOGIN_TOKEN_LOGIN_MUTATION,
  CURRENT_USER_STATUS_QUERY,
} from 'graphql-queries/queries';

const errorMessages = {
  EXPIRED: 'Your login code has expired. Please start over and request a new one',
  INVALID: 'This login code is not valid. Please make sure you entered it correctly',
  MISC: 'Something went wrong',
};

const FormContainer = styled.div`
  // Can't make a link with no href attr. Use a styled button instead
  & button.faux-link {
    border: 0;
    background: none;
    cursor: pointer;
    color: ${theme.colors.blues[50]};
    padding: 0;
  }
  & p {
    text-align: left;
  }
  & p.sub-prompt {
    font-size: ${theme.fontSize.base};
  }
  & input {
    margin-bottom: 12px;
  }
  // TODO: This button styling is a hack to make the new buttons
  // look like the old style. The entire contents of this form will
  // move into RFR in the next phase of this project, and this can
  // be removed
  & button.submit-email-login-code-button {
    width: 100%;
    font-weight: ${theme.fontWeight.medium};
    margin-bottom: 12px;
  }
  & button.submit-email-login-code-button:not([disabled]) {
    color: #fff;
    border-color: transparent;
    background-color: ${theme.colors.greens[50]};
  }
`;

function getErrorMessage(err) {
  let msg;
  if (err.message.includes('expired')) {
    msg = errorMessages.EXPIRED;
  }
  else if (err.message.includes('invalid')) {
    msg = errorMessages.INVALID;
  }
  else {
    msg = errorMessages.MISC;
  }
  return msg;
}

class SubmitVerificationByEmailForm extends React.Component {
  static propTypes = {
    invitationUuid: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.shape({
        state: PropTypes.shape({
          email: PropTypes.string
        })
      })
    }),
  };

  constructor(props) {
    super();
    this.state = {
      isInFlight: false,
      validFields: {
        fullName: false,
        email: false
      },
      checkEmailTimestamp: null,
    };
  }

  async resendCode() {
    const { email } = this.props.history.location.state;
    try {
      await apolloClient.mutate({
        mutation: EMAIL_LOGIN_CODE_IF_USER_EXISTS_MUTATION,
        variables: { email }
      });
      Toast.success('Resent login code. Please check your email');
    }
    catch (err) {
      Toast.error('Could not re-send login code');
    }
  }

  render() {
    const { email } = this.props.history.location.state;
    const { invitationUuid } = this.props;
    return (<Formik
      initialValues={{ confirmationCode: '' }}
      validate={(values) => {
        const { confirmationCode } = values;
        // Make sure there's a code, and that it's five numeric digits
        if (confirmationCode && /\d{5}/.test(confirmationCode)) {
          return null;
        }
        return {confirmationCode: 1};
      }}
      onSubmit={async (values) => {
        const { history } = this.props;
        const { confirmationCode } = values;
        try {
          await apolloClient.mutate({
            mutation: EMAIL_LOGIN_TOKEN_LOGIN_MUTATION,
            variables: {
              email,
              token: confirmationCode,
            }
          });
          await apolloClient.query({
            query: CURRENT_USER_STATUS_QUERY,
            fetchPolicy: 'network-only',
          });
          // Redirect to RFR
          history.push(Resources.responseFlowBeginFeedback.path({
            invitationUuid,
          }));
        }
        catch (err) {
          Toast.error(getErrorMessage(err));
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isValid,
        dirty,
      }) => {
        const { isInFlight } = this.state;
        return (
          <FormContainer>
            <Form onSubmit={(e) => {
              if (dirty && isValid) {
                handleSubmit();
              }
              e.stopPropagation();
              e.preventDefault();
            }}>
              <Field
                fluid
                name="confirmationCode"
                type="text"
                shortInline
                size="medium"
                placeholder="Enter five-digit confirmation code"
                onChange={handleChange}
                value={values.confirmationCode}
                component={Form.Input}
                disabled={isInFlight}
              />
              <p className="sub-prompt">
                Haven’t received an email? Check your spam folder, or <button
                  onClick={this.resendCode.bind(this)}
                  className="faux-link"
                >resend code</button>.
              </p>
              <Button.Simple
                size="M"
                disabled={!(isValid && dirty)}
                content={'Continue'}
                className="submit-email-login-code-button"
                type="button"
                isLoading={isInFlight}
                onClick={handleSubmit}
                rcLabel="submit-email-login-code-button"
              />
            </Form>
          </FormContainer>
        );
      }}
    </Formik>);
  }
}

const RoutableSubmitVerificationByEmailForm = withRouter(SubmitVerificationByEmailForm);

const ResponseFlowSubmitVerificationPage = (props) => {
  return (
    <Flow.Page renderHeader={() => <Flow.Header isHideOnMobile />}>
      <Flow.Card>
        <Flow.Container maxWidth={315}>
          <FormContainer>
            <h2>Check your email for a code</h2>
            <RoutableSubmitVerificationByEmailForm invitationUuid={props.invitationUuid} />
          </FormContainer>
        </Flow.Container>
      </Flow.Card>
    </Flow.Page>
  );
};

ResponseFlowSubmitVerificationPage.propTypes = {
  invitationUuid: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        email: PropTypes.string
      })
    })
  }),
};

export default ResponseFlowSubmitVerificationPage;
