import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const ColoredPath = styled.path`
  fill: currentColor;
`;

export const TopCloud = styled(ColoredPath).attrs({
  d: `M2558.1,1197.2c-5.9-5.2-12.7-10.9-21.6-9.7c-2.1,0.3-2.3,0-3.4-1.6
	c-4.4-6.8-16.9-10.8-23.3-4.3c-10.3-12-30.7-12.2-43.8-3.6c-0.6,0.4-1,0.4-1.6,0.1c-5-2.6-10.7-2.8-16.3-3.3
	c-6.5-0.4-12.5,2.1-18.4,4.4c-3.4,1.6-6.5,3.5-8.2,6.8c-0.4,0.6-0.6,1.1-1.4,1.5c-2.9-2.5-6.6-4-10.5-4.9c-2.9-0.7-5.6-2.6-8.7-2.1
	c-1.6,0.2-2.7-0.1-3.5-1.6c-2.1-4.3-5.6-7.8-7.8-12c-2.4-4-7.9-5.9-12.1-8.2c-15.8-6.9-23.4-5.3-38.8,0.5
	c-11.1-10.6-34.5-16-43.1-0.4c-0.6-0.1-1.2-0.1-1.7-0.2c-8.4-1.3-16.5,0.6-24.6,2.8c-5.6-2.6-11.5-4.8-16.9-7.8
	c-4.7-1.4-9.6-2.1-14.5-2.5c-1.7-0.3-3.5-0.4-5.2-0.3c-0.8,0-1.6-0.1-2.4-0.1l0,0.3c-6.9,0.7-13.7,3.7-20.1,7.8
	c-0.1-0.1-0.2-0.2-0.3-0.3c-10.7-26-39.4-44.5-67.5-35.9c-7-12.5-8.5-13.4-21.6-18.5c-12.3-6.1-28.3-7.2-40.6-0.9
	c-7.5,5.6-6.8-17.4-36-5.9c-2.3,0.8-4.6,1-6,3.4c-0.2,0.3-0.7,0.5-1.1,0.7c-3.2,2-7.1,3.1-9,6.9c-3.1,2.1-15.8-10.1-21.5-10.5
	c-3.2-1.4-9.7,0.4-9.2-4.8c3.9-26.8-10.6-42.1-29.7-58.2v-0.4c-15.2-14.3-45.3-13.7-65.3-12.2c-5.8-15.1-19.8-28.2-36.3-30
	c-3.5,0.1-6.5-2.8-5.5-6.5c0.6-3.2,2.9-7.2-0.2-9.8c-3.7-3-7.2-6.3-11-9c-8.2-5.7-18.7-13.1-28.9-8c-1.6,0.7-2.4,0.6-3.1-1
	c-7.9-16.9-13.1-19.7-29.7-26.3c-8-1.9-17-3.2-25.2-2.2c-5.6,0.8-15.6,7.1-20.3,4.8c-16.2-10.8-34.5-21.9-54.7-16.1
	c-13.8-0.8-24.3,8.1-34.6,16.2c-1.5-0.7-2.1-1.7-2.4-2.9c-1.6-5.5-4.2-10.5-7.3-15.3c-5.6-7.3-18.1-12.9-26.9-15.3
	c-5.8-2.5-1.3-9.6-2.6-14.2c-2.7-13.4-9-26.5-18.2-36.7c-16.4-21.8-45.7-23.1-70.6-20.1c-9.5,0.8-10.4-16.6-25.1-21.7
	c-9.5-5.5-20.7-11.4-32-9.1c-1.7,0.4-19.2-4.7-31.8-0.8c-11-15-30.1-20.5-46.4-27.9c-19.1-0.5-38.8,4-56.3,11.7
	c-7.6,3.7-12.6,10.4-19.8,15.3c-10-13-22.4-5.3-33.5,1.7l0,0c-16.1-2.7-42.5,8.9-57.5,15.5c-8.9,4.8-15.4,13.9-21.4,21.9
	c-1.1,1.7-2.3,2.6-4.5,2.4c-7.3,0-13.7-4.6-20.9-4.4c-13.8-1.7-27.6-2.3-41.4,1.2c-20.2,1.4-32,9.6-47.3,21.7
	c-28.6,28.6-26.2,33.6-35.3,70.3c-39.3,10.5-30.3,5.9-53.4,38.4c-3.3-2.3-5.5-5.1-7.5-8c-6.7-8.8-17.9-15.6-29-16.2
	c-23.7,0.5-47.9-1.7-70.6,6.6c-8.8,2.1-15.8,15.9-24.6,7.9c-10.1-13.2-43.4-9.8-58.3-5.7c-27.7,2.4-32.2,16-47.8,35.3
	c-7.3-4.7-15.4-0.5-23.4-2c-17.5,1.3-33.3,16.6-37.5,33.5c-0.5,2.1-1.7,3-3.9,2.8c-6.1-0.5-11.2,2.6-16.5,4.8
	c-8.7,5.6-17.3,12.5-24.6,20c-6.8,7-13.7,13.7-17.2,23.1c-0.8,1.5-2,1.9-3.2,0.9c-17.2-12.1-40-15.6-60.5-11.9
	c-4,0.9-7.9,2.5-11.6,4.2c-7.1,3.2-5.5,3-11.1-0.8c-25.2-20.5-22.2-4.6-33.2-33.5c-4.7-12.9-12.4-22.4-23.9-29.7
	c-12.6-10.3-26.3-20.2-42.5-23.9c-12.3-2.6-25.2-6.8-37.6-2.9c-7.4,1-14.4,6.3-21.7,5.9c-12.6-4.9-25.5-8.8-39.1-5.7
	c-5.8,0.6-7.2-7.6-10.8-11.1c-7.3-11.2-13.9-24.4-25.4-31.9c-17.9-11.7-35.1-27.1-57.7-26.7c-6.2-1.3-14.3,2.5-19.4-2
	c-10.3-11.4-21.4-22.6-36-28.2c-4.3-1.9-8-5.5-13-4c-7.6,0.9-15.2,1.7-22.5,3.7c-1.7,0.4-3.4,0.9-4.8-0.8c-0.5,0.5-1,0.9-1.6,1.4
	c-6.3-7.3-16.9-15.2-26.8-12.5c-3,1.2-6,1.2-9,1c-14-0.4-24.3,9.3-33,19c-8.5-5.5-38.7-16.1-40.2-24.7c0.3-28.5-24.9-86.4-53.4-95.6
	v-0.2c0,0-26.7-19.9-84.2-7.2c-6.6,1.4-12.6,3.2-18.2,5.1c-10.5,2.8-20.7,6.6-30.7,11.5c-11.5,6.7-22.9,17.2-27.6,30
	c-5.2,11.3-12.2,22.8-9.2,36.1c-24.4,4.3-36.8,14.2-49.9,37c-28.5-22-58.1-25.4-90.4-11.6c-9.3,2.5-24.5,17.6-32.7,15.7
	c-12.9-7.3-27.6-7.9-41.7-10.4c-9.8-2.4-16.5,4.2-25.6,6.6c-6.3,1.5-11.6,6.2-17.5,8.7c-5.2,1.8-7.4,5.5-8.4,9.6
	c-2.4,6.7-6.2,12.6-10,18.5c-4-1.8-6.9-5.4-11.4-5.2c-8.3,0.7-17.8-0.3-25,4.9c-7.1,5.2-18.2,9-19.9,18.9c-2.1,3.9,0,11.3-5.8,11.2
	c-4.3,0.4-9-1.4-12.7,1.5c-8.5,4.5-18.5,8.4-24.2,16.5c-8.1,9.4-17,18.6-22,30.5c-10.7-6.6-23.7-6.3-35.5-8.6
	c-15.5-0.5-30.5,6.7-45.2,10.3v0.1c-10.7-3-20.7,1.8-30.6,5.7c-11.8,4-19.5,12.5-24.3,24.7c-10.7,0-19.8,3.2-26.1,11.2
	c-10.9-7.3-25.9-7.6-38.1-4.2c-10.3,4-15.5,14.5-20.9,23.9c-25.1-11.3-56.6-9.9-77.5,10.5c-4.3,4.1-5.6,9.5-7,15.2
	c-2.1,6-4.3,12.4-4.1,18.9c0.6,3.6-7.2,1.6-9.4,3.5c-6.6,1.6-11.9,5.2-17,9.5c-6.7,3-6.8,11.9-6.9,18.5
	c-20.9-10.2-52.2-11-66.7,11.6c-17.7-5.8-40.7-8.2-54.2,8.5v0.6c-4,4.7-6.8,10.4-9.3,16.2c-11.1-3.4-27.7,0-31.1,13.8
	c-10.4-3.8-21.9-6.9-32.8-4.2c-15.1-25.2-52.3-23.2-72.9-7.2c-3.8,4.2-7.7,9.4-9,15.2c-1,3-0.6,6.5-2.1,9.1
	c-9.4-3.5-18.7-7.8-29-6.9c0,0,0,0,0,0c-9.9-0.6-19.7,4-26.9,11.3c-11.5-6.6-29.5-5.6-36.6,7.6h632.4v0H0v0h1280v0h928.3h133.2H2560
	v-0.7C2559.4,1198.7,2558.9,1197.7,2558.1,1197.2z`,
})``;

export const BottomCloud = styled.path.attrs({
  d: `M-1103.7,1133.4c-1,0.1-1.9,0.1-2.9,0.3C-1105.6,1133.4-1104.7,1133.4-1103.7,1133.4z
	M2383.6,1121.4c1,0.1,1.9,0.2,2.9,0.3C2385.5,1121.4,2384.6,1121.4,2383.6,1121.4z M1280,1200.2L1280,1200.2l-1280,0l-1280-0.1
	l17-7.7c13.2-6.5,29.3,2.3,40.3-8.4c28.9-14,50.1,4.2,55.4-2.9c5.6-10.7,17.5-16.9,24.5-25.2c7.6-13.2,21.3-20.4,36.2-22.1
	c-0.4,0.1-0.8,0.3-1.3,0.5c1.8,0.5,4.5,0.1,5.4-0.7c-0.4,0-0.8,0-1.2,0c8.1-0.6,16.5,0.5,24.4,3c34.3-16.7,36.4,6.1,58-0.1
	c31.6-13.4,41.5,14,53.1,5c5.9-28.4,53.9-40.9,75-16.3c11.2,1.3,22.3-8.6,34.6-4.6c31.4,3.7,11.8-14.6,66.6-3.7
	c3.8-7.9,9.1-17.5,19.4-19.3c2.6-0.5,2.6-0.5,3-2.8c4.5-13.4,20.5-23,35-26.8c11.9-2.6,24.5,1.4,36,1.4c16-5,32.4,1.1,48,1.8
	c12.1-5.1,26.4-4.4,38.3,0.9c8.5-3.5,18.6-1.7,25.2,4.3c2.7,3.2,6.7-2.2,10.7-2.5c14.8-4.6,28.9,3.7,39.1,12.5
	c9.7-26.8,49.7-31.5,69.5-10.4c3.5,3.9,4.9,8.9,6.1,13.8c12.6-2.5,22.7,16.1,31.9,5c6.9-4.1,17.1-4.3,23.9,0.3
	c9.2-23.4,35.7-32.4,61.5-32.6c17.6,1.6,26.5,12.2,42.5-1.2c26.6-17.2,66.7-16.3,90.7,4c12.4,11.3,4.3-25.8,48.8-33.4
	c7-2.7,1.6-10.4,3.1-15.5c3.6-17.1,13.7-33.2,27.6-45.3c13.3-12.8,33.9-17.1,52.7-17.8c11.1-1.5,26.1,5.5,34.8-1.8
	c8.7-15.8,26.7-23.8,44.3-29.9c5.5-3.7,20.1-3.9,26.5-2.3c2.6,1.7,5.5,2.7,8.4,3.8c5.4,3.2,9.2,8.6,14.5,12
	c2.9,3.6,9.8-9.6,13.3-11.2c3.8-3.3,8-6.5,13.5-6.7c5.4-0.4,10.7-3.4,16.1-1.1c8.9,3.1,19.7,5.6,23.9,14.9
	c51.3-26.8,102.8-9.6,127.1,43c4,9.2,5.8,18.8,7.2,28.5c0.8,9.3,11.7,5.6,16.9,10.6c8.5,4.9,16.8,9.7,23.9,16.7
	c9-20.8,34.5-26,53.1-15.1c5.2,1.8,11.6-3.4,17.5-3.3c9.9-1.8,19.7-0.1,29.5-0.3c10.4-1.1,20.4,4.3,26,13c13.1,19.3,16.7,4,35,9.1
	c10.2,2.7,21,4.6,29.5,11.4c11.9,10.4,27.9,19.1,32.4,35.2c1.2,2.3,1,8,4.6,7.1c10.4-2.4,21.1-6.5,31.9-4.6c5,0.6,8.4,6.4,13.6,2.8
	c10.2-6.4,21.2-11.6,33.5-10.8c23.7-2,44,10.7,60.4,26.5c7,5.3,11,13.1,16.3,19.7c5.1,5.3,6,11.8,6.7,18.9
	c6.2,1.8,12.9,1.9,18.9,4.6c2.4,0.8,5.4,0.2,7.4-1.2c21.2-12.6,49.3-10.2,69.6,3.3c7.1-17.4,20.2-35.4,36-45.6
	c3.7-1.6,8.5-3.5,10.3-7.5c6.7-13.5,15.6-28.2,31.4-31.6c6.5-3.9,12.1,1.4,17.9,4c22.2-31.3,33.7-42,73.8-43.3
	c9.3-1.7,15.1,11.8,22.8,8.1c16-8.9,31.7-23.9,51-22.9c18.5,2.3,39.4,5.5,52.2,20.4c12.6-14.4,25.2-31.3,45.5-34.2
	c2.5-0.6,2.6-0.7,2.4-3.3c-6.9-33-2.7-24.8,11.2-51.8c1-4.6,3.2-8.4,5.6-12.6c2.5-3.6,6.8-3.2,9.7-6.3
	c12.2-14.1,30.1-18.5,47.4-22.8c6.8-1.7,13.3,1.4,20.1,1.7c6.6,0.5,11.3,0.7,18.6,5.6c5.7-3.3,6.9-13.7,12.6-18.2
	c4.8-3.9,14.3-14.9,20.4-14.2c4.2,0.2,9.1-0.5,13.4,0.1c0.2,0,0.5,0.1,0.7,0.1v0.2c17.4,7.1,35,16.5,43.6,34.9
	c8.7,8.6,23.7,0.3,34.8,2.1c40.6-1.5,73.3,33.7,80.3,74.5c1.5,5.9-3.9,15.1,3.1,18.2c44.5,9,36.5,52.8,48.8,39.5
	c24-24,64.2-25.1,90.8-4.7c16,15.7,24.9,3.3,42.5,1.4c25.8,0.3,52.3,11,61.5,38.5c6.8-5.4,17.1-5.2,24-0.4
	c9.3,13.2,19.3-8.8,31.9-5.8c5.9-40.5,65.1-42.4,75.6-3.9c10.2-10.4,24.3-20.2,39.1-14.8c3.9,0.4,8,6.7,10.7,2.9
	c6.5-7,16.7-9.2,25.2-5c11.9-6.2,26.2-7.1,38.3-1c15.5-0.8,32-8,48-2.2c11.5,0,24.1-4.8,36-1.7c14.5,4.5,30.6,15.8,35.1,31.6
	c0.4,2.7,0.4,2.7,3,3.3c10.3,2.1,15.6,13.4,19.4,22.7c54.8-12.9,35.2,8.7,66.6,4.3c12.3-4.8,23.4,7,34.6,5.4
	c21-29,69.1-14.2,75,19.3c11.6,10.6,21.5-21.6,53.1-5.9c21.6,7.3,23.7-19.5,58,0.2c7.9-3,16.3-4.2,24.4-3.5c-0.4,0-0.8,0-1.2,0
	c0.9,1,3.6,1.5,5.4,0.9c-0.4-0.3-0.8-0.5-1.3-0.6c14.9,2,28.6,10.5,36.2,26.1c7,9.9,18.9,17.1,24.5,29.7c5.3,8.4,26.5-13,55.4,3.5
	c11,12.7,27.1,2.2,40.3,9.9l17,9.1L1280,1200.2z M2070.6,1169.7c0.4,0.1,0.6,0.4,0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.3
	C2070.8,1169.3,2070.7,1169.5,2070.6,1169.7z M-790.9,1174.3c-0.1-0.1-0.2-0.4-0.3-0.4c-0.2,0-0.4,0.1-0.6,0.2
	C-791.5,1174.7-791.2,1174.4-790.9,1174.3z`,
})`
  fill: ${theme.colors.white};
`;

export const BottomLeftDot = styled(ColoredPath).attrs({
  d: `M77.7,679.2c-0.5,2.7-1.4,4.3-3.5,4c-0.9-0.1-2.2-1.4-2.4-2.3c-0.4-2,1.5-4.1,3.4-3.7C76.3,677.4,77.1,678.6,77.7,679.2z`,
})``;
export const BottomRightDot1 = styled(ColoredPath).attrs({
  d: `M885,871.4c0.1-5.8,2.9-8.5,8.4-8.4c4.3,0.1,7.5,3.6,7.4,8.2c-0.1,4.9-2.3,7.5-8,7.8C888.3,879.2,884.9,875.7,885,871.4z`,
})``;
export const BottomRightDot2 = styled(ColoredPath).attrs({
  d: `M1002.9,895.3c-5.1,0-8.9-3.8-8.9-8.8c0-3.9,3.8-7.5,8-7.4c4.8,0.1,8.7,3.8,8.7,8.2
	C1010.7,891.2,1006.7,895.3,1002.9,895.3z M1006.1,887.4c0.1-2.5-1.2-4-3.8-4c-1.9,0-3.8,0.5-4.1,2.9c-0.3,2.1,2,4.2,4.7,4.4
	C1005.2,890.8,1005.9,889.4,1006.1,887.4z`,
})``;
export const BottomDot1 = styled(ColoredPath).attrs({
  d: `M591.3,992.1c-3.7,0-6.3-2.4-6.3-5.7c0-3.9,3.5-7.5,7.1-7.4c3.1,0.1,6.3,3.1,6.4,5.9
	C598.5,989.9,596.3,992.2,591.3,992.1z M594.4,986.4c0-0.3,0-0.7,0-1c-0.7-0.5-1.5-1.5-2-1.3c-1,0.3-1.8,1.2-2.8,1.9
	c0.6,0.6,1.2,1.7,1.8,1.7C592.5,987.7,593.5,986.9,594.4,986.4z`,
})``;
export const BottomDot2 = styled(ColoredPath).attrs({
  d: `M653,963.4c0.1-5.8,2.9-8.5,8.4-8.4c4.3,0.1,7.5,3.6,7.4,8.2c-0.1,4.9-2.3,7.5-8,7.8C656.3,971.2,652.9,967.7,653,963.4z`,
})``;
export const Galaxy = styled(ColoredPath).attrs({
  d: `M1060.7,745.4c2.1,0.4,5.1,0.8,8,1.4c0.9,0.2,1.8,0.8,2.4,1.5c0.6,0.7,0.8,1.8,1.2,2.7
	c-0.9-0.1-1.9-0.1-2.8-0.4c-2.7-0.7-5.3-1.6-8.1-2.2c-2-0.4-3.9-0.5-4.6,2.4c-0.4,1.6-2.1,1.8-3,0.3c-0.9-1.4-1.8-1.4-3.1-0.9
	c-3.2,1.2-6.5,2.2-9.5,3.7c-7,3.7-14.1,7.2-19.6,13.1c-0.8,0.8-1.6,1.7-2.3,2.6c-3.4,4.4-3.7,6.5-0.8,12.6c0.4,0.8,0.3,1.9,0.5,2.9
	c-1-0.6-2.3-0.9-2.8-1.8c-3.7-6.2-3-11.2,1.8-16.8c4.7-5.6,10.8-9.2,17.3-12.2c1.9-0.9,3.8-1.9,5.5-3.1
	C1046.5,747.3,1053.2,746.5,1060.7,745.4z M1081.1,772.1c-0.9,5.5-2.4,12.2-7.7,17.4c-1.2,1.2-1.9,3-3.2,4.2
	c-3.5,3.2-7.1,6.3-10.7,9.2c-0.5,0.4-1.6,0.1-2.4,0.2c0.3-0.7,0.4-1.7,0.9-2.2c3.3-3.2,6.8-6.3,10.1-9.6c5.5-5.7,8.8-12.6,10.3-20.3
	c0.6-3.1-1.7-7-4.7-8c-2.4-0.8-5-1-7.4-1.7c-0.8-0.2-1.6-1-2.3-1.6c0.8-0.3,1.7-1,2.4-0.9c2.9,0.3,5.8,0.7,8.5,1.5
	C1078.8,761.4,1081.1,765.7,1081.1,772.1z M1033.4,789.1c-1.1-0.5-3.5-0.8-4.1-2c-1-2.1,0.2-4.5,2.1-5.7c3.7-2.2,7.6-4.4,11.6-5.8
	c1.4-0.5,4.1,0.5,5.3,1.7c1.6,1.6,0.4,3.9-0.8,5.5C1044.2,787.1,1039.3,788.3,1033.4,789.1z M1012.8,755.4c-3.1,3.9-5.9,8-9.3,11.6
	c-3.2,3.4-4.6,7.5-5.8,11.8c-0.2,0.8-0.2,1.6-0.5,2.3c-2.3,6.9,6.2,14,12.4,11c1.6-0.8,3.4-1.5,5.1-2c0.7-0.2,1.6,0.3,2.4,0.5
	c-0.5,0.6-0.8,1.7-1.4,1.9c-3.1,1.1-6.3,2.5-9.5,2.9c-4.7,0.5-7.8-2.5-10.2-6.2c-3.5-5.4-1.6-10.7,0.4-15.9c1.2-3.3,2.8-6.4,5.6-9
	c2.8-2.5,4.5-5.9,7-8.7c0.7-0.8,2.2-0.9,3.4-1.3C1012.5,754.7,1012.7,755.1,1012.8,755.4z M1027.2,803.4c2.2-1,4.4-2.2,6.8-3.1
	c9.1-3.4,17.2-8.4,24.1-15.2c3.8-3.9,5.1-8.7,3.2-14.1c-0.2-0.7,0.2-1.7,0.3-2.5c0.7,0.5,1.7,0.8,2,1.5c3,6,0.4,11-3,15.9
	c-3.2,4.7-8,7.7-12.7,10.5c-5.1,3-10.6,5.2-16.1,7.6c-1.3,0.6-2.9,0.4-4.3,0.6C1027.4,804.2,1027.3,803.8,1027.2,803.4z
	 M1018.2,752.6c-0.5-0.3-1.1-0.4-1.1-0.6c0-0.5,0.1-1.4,0.4-1.6c3.8-2.2,7.6-4.3,11.4-6.3c0.4-0.2,1.2,0.3,1.8,0.4
	c-0.4,0.7-0.6,1.7-1.2,2c-2.6,1.4-5.3,2.6-8,4C1020.4,751.1,1019.3,751.9,1018.2,752.6z M1042.9,810.3c1.6-1.1,2.7-2.1,4-2.8
	c1.8-1,3.8-1.9,5.7-2.7c0.4-0.2,1.2,0,1.7,0.3c0.2,0.1,0.1,1.2-0.1,1.3c-3.2,1.6-6.5,3.1-9.8,4.7
	C1044.3,811.1,1044,810.9,1042.9,810.3z`,
})``;
export const RightRing1 = styled(ColoredPath).attrs({
  d: `M1237.6,299c12.1-0.2,20.6,10.2,20.1,21.1c-0.3,7.8-8.8,17.9-19.1,17.1
	c-11-0.9-19.2-8-18.6-19.6C1220.5,307.1,1228.4,298.7,1237.6,299z M1239,331.1c6.7,0.1,12.7-5.8,12.8-11.8
	c0.1-7.5-6.1-13.8-13.6-13.9c-5.3-0.1-12.2,7.7-12.5,13.9C1225.4,325.1,1233.7,331.2,1239,331.1z`,
})``;
export const RightRing2 = styled(ColoredPath).attrs({
  d: `M1195.7,374c0,7.9-4.5,13.3-11,13.4c-10.1,0.1-13.3-5.1-13.6-12.6c-0.3-6.7,6-12.5,13.2-12.7
	C1189.5,361.8,1196.9,368.4,1195.7,374z M1183.3,370.2c-2.5-0.1-4.7,2-4.7,4.6c-0.1,2.6,1.9,4.8,4.4,4.9c2.6,0.1,5.3-1.9,5.4-4
	C1188.5,373.3,1185.7,370.3,1183.3,370.2z`,
})``;
export const RightRing3 = styled(ColoredPath).attrs({
  d: `M1269.2,598.8c9.3,0,17.9,8.8,17.9,18.4c0,9.9-9.2,18.7-19.2,18.6c-7.8-0.1-16.2-7.3-16-20.7
	C1252,607,1260.8,598.8,1269.2,598.8z M1260.5,615.9c0.2,6.4,3.8,10.8,8.8,10.7c5-0.1,9.6-4.6,9.4-9.4c-0.3-6.3-4.9-8.9-10-9.4
	C1264.9,607.4,1260.4,612.1,1260.5,615.9z`,
})``;
export const RightRing4 = styled(ColoredPath).attrs({
  d: `M1147.1,752.8c-1.1-10.6,9.7-18.9,19.2-17.6c9.4,1.2,16.4,6.4,15.9,19.8
	c-0.3,8.1-8.7,15.6-19.3,14.9C1154.6,769.2,1146.8,761.1,1147.1,752.8z M1163.5,743.2c-4.9,0.1-9.2,4.3-9,8.8
	c0.2,5,5.6,10.9,10,10.6c4.3-0.3,10.4-4.9,10.1-10.9C1174.3,747,1169.3,743,1163.5,743.2z`,
})``;
export const RightDot = styled(ColoredPath).attrs({
  d: `M1308,484.9c0.1,17.9-12.6,31-30.1,31.1c-16.2,0.1-28.6-11.6-28.9-27.3
	c-0.4-18.2,14.1-35.6,29.9-35.7C1295.8,452.9,1307.9,466.2,1308,484.9z`,
})``;
export const RightLine = styled(ColoredPath).attrs({
  d: `M1171.4,682.9c-0.6-0.3-1.4-0.5-1.4-0.8c-0.1-0.6,0.1-1.4,0.5-1.8c2.2-2.3,4.5-4.6,6.9-6.8
	c4.2-3.9,8.4-7.6,12.6-11.4c0.3-0.2,0.5-0.6,0.8-0.6c0.8-0.2,1.6-0.3,2.4-0.4c-0.1,0.7,0.1,1.5-0.2,2.1c-0.4,0.8-1.1,1.6-1.8,2.2
	c-5.9,5.4-11.8,10.7-17.7,16C1172.9,681.9,1172.1,682.4,1171.4,682.9z`,
})``;
export const LeftDot1 = styled(ColoredPath).attrs({
  d: `M24.9,513.9c0,3.3-2.3,5.7-5.5,5.7c-3,0-5.3-2.1-5.3-5c-0.1-3.4,2.6-6.6,5.5-6.6
	C22.6,508,24.9,510.4,24.9,513.9z`,
})``;
export const LeftDot2 = styled(ColoredPath).attrs({
  d: `M33.1,608.1c0-5.6,3.9-8.2,7.1-8.1c3.4,0,8,4.3,7.9,7.1c-0.2,4-3.9,8.1-7.4,7.9
	C37.3,614.9,32.2,612,33.1,608.1z M39.9,610.5c0.4,0,0.8,0,1.3,0c0.6-1,1.6-2,1.6-2.9c-0.1-0.9-1.4-1.6-2.1-2.5
	c-0.8,0.8-2.2,1.6-2.4,2.6C38.1,608.5,39.3,609.5,39.9,610.5z`,
})``;
export const LeftSquare1 = styled(ColoredPath).attrs({
  d: `M45,89.9c-3.4,2.3-5.5,4.7-7.5,4.7c-1.7-0.1-3.3-3-4.9-4.7c2.1-1.7,4-3.9,6.5-4.8
	C40.1,84.7,42.1,87.4,45,89.9z`,
})``;
export const LeftSquare2 = styled(ColoredPath).attrs({
  d: `M20.3,216.1c-1.7-3.9-1.7-7.9-1.2-12c0.3-2.5,0.9-5.1,1.4-7.6c0.1-0.5,0.9-1.1,1.4-1.2
	c4.4-0.4,8.8-0.7,13.2-0.9c0.6,0,1.3,1.2,1.9,1.8c0,0,0,0.2,0,0.3c-0.2,6.5-0.3,13-0.5,19.6C31.2,216.1,25.7,216.1,20.3,216.1z`,
})``;
export const LeftSquare3 = styled(ColoredPath).attrs({
  d: `M16,296.4c-0.6,0.7-1.2,1.4-1.7,2.1c-0.3,0.4-0.3,1.2-0.6,1.4c-3.6,1.6-7.2,3.2-10.9,4.6
	c-2.3,0.9-3.7-0.7-4.6-2.5c-0.7-1.6-1.4-3.3-1.6-5c-0.8-6.8-1.4-13.6-1.9-20.4c-0.2-3.1,2.8-5.2,5.4-3.7c1.5,0.9,2.8,0.8,4.3,0.9
	c3.1,0.3,6.2,0.9,9.3,1.6c0.7,0.1,1.5,0.7,1.8,1.3c0.7,1.8,1.4,3.7,1.7,5.7c0.2,1.3-0.3,2.6-0.3,4c0,1.3-0.1,2.7,0.3,3.9
	c0.4,1.8,1.4,3.4,1.7,5.2c0.1,0.5-1.3,1.3-2,1.9C16.7,297,16.3,296.7,16,296.4z`,
})``;
export const TopDot1 = styled(ColoredPath).attrs({
  d: `M637.6,61.5c0-5.6,3.9-8.2,7.1-8.1c3.4,0,8,4.3,7.9,7.1c-0.2,4-3.9,8.1-7.4,7.9
	C641.8,68.2,636.6,65.4,637.6,61.5z M644.4,63.9c0.4,0,0.8,0,1.3,0c0.6-1,1.6-2,1.6-2.9c-0.1-0.9-1.4-1.6-2.1-2.5
	c-0.8,0.8-2.2,1.6-2.4,2.6C642.5,61.8,643.7,62.9,644.4,63.9z`,
})``;
export const TopDot2 = styled(ColoredPath).attrs({
  d: `M771.6,105.3c5.1,0,9,3.6,9,8.3c0.1,4.8-4,8.9-8.8,8.9c-4.7,0-8.5-3.7-8.5-8.3
	C763.3,108.9,766.7,105.3,771.6,105.3z M772.5,116c0.7-1.1,1.4-1.8,1.3-2.3c-0.1-0.6-1-1.2-1.5-1.7c-0.5,0.5-1.4,1-1.4,1.5
	C770.9,114.2,771.7,114.9,772.5,116z`,
})``;
export const CenterDot = styled(ColoredPath).attrs({
  d: `M399.6,714.9c1.6,0.1,2.9,0.7,3,2.7c0.1,1.9-1.3,3.5-3.1,3.5c-1.9,0-2.9-1.1-3.1-2.9C396.3,716.3,397.6,715,399.6,714.9z`,
})``;
export const TopRightDiamond1 = styled(ColoredPath).attrs({
  d: `M1207.6,4.6c0.3-1.4,0.4-2.4,0.7-3.1c1.7-4.1,3.3-8.2,3.5-12.8
	c0.1-2.1,1.9-4.3,3.2-6.2c0.3-0.5,1.9-0.6,2.6-0.2c5.3,3.3,10.6,6.6,15.7,10.2c0.9,0.6,1.2,2.8,0.9,4c-1.3,6.6-2.9,13.2-4.4,19.8
	c-0.5,2.2-1.5,2.6-3.4,1.4c-5.7-3.4-11.4-6.9-17-10.4C1208.4,6.6,1208,5.3,1207.6,4.6z M1226.9,13.8c0.3-0.9,0.7-1.6,0.8-2.3
	c1.1-5.2,2.2-10.4,3.1-15.7c0.1-0.8-0.6-2.2-1.4-2.7c-3.1-2.1-6.3-4.1-9.6-6c-2.9-1.7-3.3-1.6-4.4,1.5c-1.7,4.7-3.1,9.4-4.4,14.2
	c-0.2,0.7,0.3,2,0.9,2.4C1216.8,8.1,1221.7,10.9,1226.9,13.8z`,
})``;
export const TopRightDiamond2 = styled(ColoredPath).attrs({
  d: `M1263.2,19.4c3,0,6,0,8.9,0c3.8,0.1,4.5,0.7,4.6,4.4c0.1,3.3,0.1,6.7,0.2,10
	c0,1.9-0.6,3.1-2.7,3.1c-4.2,0.1-8.4,0.4-12.6,0.2c-1.1-0.1-2.5-1.3-3.1-2.3c-2.5-4-4.7-8.2-6.9-12.4c-1-1.9-0.5-2.9,1.6-3
	C1256.5,19.3,1259.9,19.4,1263.2,19.4C1263.2,19.4,1263.2,19.4,1263.2,19.4z M1255.6,22.8c1.8,3.3,3.5,6.3,5.2,9.3
	c0.4,0.7,1.2,1.6,1.9,1.6c2.9,0.3,5.9,0.6,8.9,0.5c0.7,0,2-1,2.1-1.6c0.3-2.6,0.1-5.2,0.2-7.9c0.1-1.7-0.7-2.3-2.4-2.3
	c-4.7,0-9.5,0-14.2,0C1256.7,22.5,1256.1,22.7,1255.6,22.8z`,
})``;
export const TopRightDiamond3 = styled(ColoredPath).attrs({
  d: `M1187.9,42.2c3.4,0,6.9,0.1,10.3,0c2.1,0,2.8,0.8,2.7,2.8c-0.1,3.8-0.1,7.6,0,11.3
	c0.1,2.1-0.7,3.1-2.9,3.1c-3.9,0-7.8,0.2-11.6,0c-1.2-0.1-2.7-1-3.3-2c-2.6-3.7-4.9-7.5-7.2-11.3c-1.8-2.8-1.1-4,2.2-4
	C1181.4,42.1,1184.7,42.2,1187.9,42.2C1187.9,42.2,1187.9,42.2,1187.9,42.2z M1179,45.1c1.5,2.5,2.7,4.1,3.5,5.9
	c2.2,4.7,5.5,6.3,10.7,5.5c4.8-0.7,4.8-0.3,4.8-5.2c0-6.3,0-6.3-6.2-6.3C1187.8,45.1,1183.8,45.1,1179,45.1z`,
})``;
export const TopRightDiamond4 = styled(ColoredPath).attrs({
  d: `M1258.4,54.9c-1.7,5.5-3.3,10.8-5,16.1c-0.6,1.8-1.2,3.7-2,5.4
	c-0.6,1.4-1.8,2-3.3,1.1c-5.6-3.5-11.2-6.9-16.6-10.5c-0.6-0.4-0.7-1.8-0.7-2.8c0.1-5.6,2.8-10.4,4.5-15.5c0.6-1.9,1.2-3.8,1.8-5.7
	c0.7-2.1,2-2.6,3.9-1.5c5.1,3.1,10.1,6.3,15.1,9.7C1257.1,51.8,1257.5,53.5,1258.4,54.9z M1240.2,45.2c-1.2,2.8-2.1,5.6-3.7,8
	c-2.1,3.2-2.4,6.7-2.3,10.3c0,0.7,0.5,1.7,1.1,2.1c4.3,2.9,8.8,5.6,13.9,8.9c1.9-6.9,3.7-13.1,5.3-19.4c0.2-0.7-0.4-2-1-2.4
	C1249.2,50.1,1244.8,47.8,1240.2,45.2z`,
})``;
export const TopRightDiamond5 = styled(ColoredPath).attrs({
  d: `M1287.1,77.2c-0.1,1.9-0.3,3.9-0.3,5.8c0,2.2-1.1,3.2-3.2,3.3
	c-7.1,0.3-14.2,0.5-19.1-6.1c-0.7-1-1.9-1.6-2.8-2.4c-1.5-1.5-1.2-3,0.8-3.5c6.9-1.6,13.8-3.1,20.8-4.5c3.3-0.6,3.8,0.1,3.7,3.5
	C1286.9,74.5,1287,75.9,1287.1,77.2C1287,77.2,1287,77.2,1287.1,77.2z M1265.9,76.6c4.2,5.7,10.4,8.1,16.2,6.5
	c0.6-0.2,1.5-1.1,1.5-1.8c0.2-2.3,0.2-4.7,0.1-7.1c0-0.5-1.2-1.5-1.6-1.4C1276.8,74,1271.6,75.3,1265.9,76.6z`,
})``;
export const TopRightDiamond6 = styled(ColoredPath).attrs({
  d: `M1202.6,75.6c1,0.5,1.8,0.8,2.3,1.3c1.9,2.1,3.8,4.4,5.6,6.6c0.2,0.3,0.5,0.8,0.4,0.9
	c-1.4,1.7-2.8,3.4-4.4,4.8c-0.6,0.5-2.4,0.5-3,0c-2.6-2.2-4.9-4.7-7.3-7.2c-0.4-0.4-0.5-1.8-0.2-2
	C1198.1,78.5,1200.4,77.1,1202.6,75.6z M1205.1,87c0.8-1.3,1.9-2.4,1.7-2.7c-1.1-1.6-2.3-3.3-3.8-4.4c-0.5-0.4-2,0.6-3.9,1.3
	C1201.3,83.4,1202.9,85,1205.1,87z`,
})``;
export const TopRightDiamond7 = styled(ColoredPath).attrs({
  d: `M1187.5,106.8c4.8,0.4,7.2,3.5,6.1,7.9c-0.7,2.5-1.3,5.1-2.3,7.5
	c-0.7,2-2,2.1-3.2,0.3c-2.1-3.2-4.2-6.5-6-9.9c-0.4-0.8-0.1-2.8,0.7-3.4C1184.1,108.1,1185.9,107.6,1187.5,106.8z M1189.2,118.4
	c0.6-1.6,1.1-2.6,1.2-3.6c0.1-1.2,0.3-3.1-0.4-3.7c-1.4-1.3-3.3-1.1-5.2,0.5C1186.3,113.8,1187.6,115.9,1189.2,118.4z`,
})``;
export const TopRightDiamond8 = styled(ColoredPath).attrs({
  d: `M1226.6,101.6c0.2,0,0.5,0,0.7,0.1c3.2,0.7,6.5,8.5,4.5,11.1
	c-1.3,1.7-2.8,3.3-4.4,4.8c-1.2,1.2-2.7,1.2-4-0.1c-2.6-2.6-5.2-5.2-7.7-7.9c-0.5-0.5-0.7-2.2-0.3-2.4
	C1218.8,104.6,1222.2,102.2,1226.6,101.6z M1226.2,114.3c1-1,1.8-1.9,2.6-2.8c1.2-1.5-0.4-6.1-2.1-5.9c-2.2,0.2-4.4,1.3-6.5,2.2
	c-0.2,0.1-0.2,1.9,0.2,2.2C1222.1,111.5,1224.1,112.8,1226.2,114.3z`,
})``;
export const TopRightDiamond9 = styled(ColoredPath).attrs({
  d: `M1276.1,121.5c-0.9,1.2-1.4,2-2,2.5c-0.8,0.8-1.8,1.4-2.7,2.1c-1.5,1.2-2.9,1-4.2-0.3
	c-2.1-2.2-4.2-4.3-6.2-6.4c-1.7-1.9-1.4-3.1,1-4.2c0.5-0.2,0.9-0.5,1.4-0.7c1.8-0.5,4.3-1.8,5.2-1.1
	C1271.5,115.7,1273.6,118.7,1276.1,121.5z M1264.3,117.2c-0.1,0.3-0.1,0.7-0.2,1c1.7,1.5,3.4,3,5.3,4.3c0.2,0.2,1.3-0.8,2-1.2
	c-1.2-1.5-2.3-3.3-3.7-4.5C1267.1,116.4,1265.5,117.1,1264.3,117.2z`,
})``;
export const TopRightDiamond10 = styled(ColoredPath).attrs({
  d: `M1226.3,144.3c-0.3-0.2-1.1-0.5-1.5-1c-1.6-2.1-3-4.3-4.5-6.5
	c-1.1-1.6-0.4-2.6,1.2-3.3c6.5-2.8,13.1-5.5,19.6-8.2c0.9-0.4,2.3-0.5,2.9-0.1c0.5,0.4,0.7,2,0.3,2.8c-1.8,4.3-3.8,8.4-5.7,12.6
	c-0.1,0.3-0.5,0.6-0.8,0.7C1234.2,142.3,1230.5,143.2,1226.3,144.3z M1240.4,129.9c-0.2-0.2-0.3-0.4-0.5-0.6
	c-5.3,2.2-11,3.5-15.5,7.3c0.6,1.1,1,2.1,1.7,2.8c0.4,0.5,1.3,0.9,1.9,0.9c2.3-0.3,4.6-0.7,6.9-1.2c0.6-0.1,1.6-0.4,1.8-0.8
	C1238,135.5,1239.2,132.7,1240.4,129.9z`,
})``;
export const TopLeftDiamond = styled(ColoredPath).attrs({
  d: `M7.1,44c-4.8,0.1-5.6-0.2-6.7-3.8c-1.7-5.7-3-11.5-4.4-17.3c-0.4-1.7,0.7-2.6,2.3-2.1
	c2.7,0.9,5.4,2,8,3.1c3.6,1.5,7.2,3.2,10.8,4.7c2.1,0.9,3,2.1,2.5,4.5c-0.4,2.1-0.2,4.4-0.2,6.6c0,2.1-0.8,3.4-3.3,3.6
	C12.8,43.4,9.4,43.8,7.1,44z M0.5,25.2C0.5,26,0.3,26.5,0.4,27c0.9,3.9,1.7,7.9,2.8,11.7c0.3,0.9,1.6,2.1,2.5,2.1
	c3.1,0.1,6.3-0.3,9.4-0.6c0.5,0,1-0.4,1.3-0.7c1.3-1.8-0.3-7.5-2.3-8.5c-3.8-1.8-7.6-3.5-11.4-5.3C2,25.5,1.3,25.4,0.5,25.2z`,
})``;
