import React, { useState, useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import SkillUnit from 'components/SkillUnit/UnitsList';
import IconKarotDown from '@matterapp/matter-ui/build/components/Icon/svg/IconKarotDown';
import Content from '@matterapp/matter-ui/build/components/Content';
import { Resources } from '@matterapp/routing';
import { AppLayout } from '../../../../modules';
import { SKILL_DETAILS_QUERY, REMOVE_SKILL_MUTATION } from 'graphql-queries/queries';
import { useQuery, useMutation } from '@apollo/client';
import { SkillGeneratorModal} from '../modules';
import { Loader } from '@matterapp/matter-ui';
import {
  Background,
  BannerContainer,
  Heading,
  AvatarDescription,
  AddASkillButton,
  AddASkillButtonContainer,
  FeedbackCountContainer,
  FeedbackCountKey,
  FeedbackCountValue,
  FeedbackCountRow,
  FeedbackCountHeader,
  FeedbackCountFooter,
  AvatarContainer,
  BannerContent,
  KarotContainer,
  ShowFeedbackCount,
  ShowFeedbackCountCollapse,
} from './SkillsStyles';
import { useNavigate } from 'react-router-dom';
import { useSettingsPanelState } from 'routes/pages/workspaces/hooks/shared';
import Toast from 'components/Toast/Toast';

function SkillsPage() {
  const { currentWorkspace: workspace, currentUser, currentTenantWorkspaceIds } = useContext(UserContext);
  const navigate = useNavigate();
  const [animateIn, setAnimateIn] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const { id: workspaceId } = workspace || {};
  const {
    headerProps
  } = useSettingsPanelState(workspace);
  const { data, loading } = useQuery(SKILL_DETAILS_QUERY, {
    variables: {
      workspaceIds: currentTenantWorkspaceIds
    },
    skip: !currentTenantWorkspaceIds
  });
  const [removeSkill] = useMutation(REMOVE_SKILL_MUTATION);

  const { pickedSkills = [] } = data || {};

  useEffect(() => {
    if (workspace) {
      setAnimateIn(true);
    }
  }, [workspace]);

  async function handleConfirmRemoveSkill({ id: skillId, name }) {
    await removeSkill({
      variables: {
        skillId
      },
      refetchQueries: [{
        query: SKILL_DETAILS_QUERY,
        variables: {
          workspaceIds: currentTenantWorkspaceIds
        }
      }]
    });

    Toast.success(`You removed "${name}" from your skills`);
  }

  function renderEmptyState() {
    return (
      <BannerContainer>
        <Background />
        <BannerContent>
          <Heading>Get started by gathering feedback on your skills from people you work with.
          </Heading>
        </BannerContent>
      </BannerContainer>
    );
  }

  function renderMainColumn() {
    if (loading || !workspace) {
      return <Loader />;
    }

    const showEmptyState = !(currentUser.isReceivedFeedback) || (pickedSkills == null || pickedSkills.length == 0);
    
    const skillDetails = pickedSkills.map((skillDetail) => {
      return {
        ...skillDetail,
        workspaceId,
        onClick: () => {
          const { skill } = skillDetail;
          const skillPath = Resources.skillDetails.path({
            skillSlug: skill.slug,
            workspaceId
          });
          return navigate(skillPath);
        },
      };
    });

    const skillGaugeProps = { animateIn, shouldAnimateIn: true };

    return (
      <>
        {showEmptyState && renderEmptyState()}
        <SkillGeneratorModal>
          {({ handleStartEditSkill, renderModal }) => (
            <>
              {renderModal()}
              <SkillUnit
                skillDetails={skillDetails}
                skillGaugeProps={skillGaugeProps}
                handleConfirmRemoveSkill={handleConfirmRemoveSkill}
                handleStartEditSkill={handleStartEditSkill}
                renderModal={renderModal}
              />
            </>
          )}
        </SkillGeneratorModal>
      </>
    );
  }

  function onSkillCountClick(skillSlug) {
    const skillPath = Resources.skillDetails.path({ skillSlug, workspaceId });

    return navigate(skillPath);
  }

  function toggleOpen() {
    setIsOpened(!isOpened);
  }

  function renderFeedbackCounts() {
    if (!pickedSkills) { return null; }

    return pickedSkills.map((pickedSkill) => {
      return (
        <FeedbackCountRow
          key={pickedSkill.skill.id}
          onClick={() => onSkillCountClick(pickedSkill.skill.slug)}
        >
          <FeedbackCountKey>
            {pickedSkill.skill.name}
          </FeedbackCountKey>
          <FeedbackCountValue>
            {pickedSkill.numFeedbacks || '-'}
          </FeedbackCountValue>
        </FeedbackCountRow>
      );
    });
  }

  function renderSkillsFeedbackCounts() {
    const feedbackCounts = renderFeedbackCounts();

    return (
      <>
        <FeedbackCountHeader onClick={toggleOpen}>
          Skill Feedback Count
          <KarotContainer isOpened={isOpened}>
            <IconKarotDown />
          </KarotContainer>
        </FeedbackCountHeader>
        <ShowFeedbackCountCollapse>
          <Content.Collapse isOpened={isOpened}>
            <FeedbackCountContainer>{feedbackCounts}</FeedbackCountContainer>
          </Content.Collapse>
        </ShowFeedbackCountCollapse>
        <ShowFeedbackCount>
          <FeedbackCountContainer>{feedbackCounts}</FeedbackCountContainer>
        </ShowFeedbackCount>
        <FeedbackCountFooter />
      </>
    );
  }

  function renderAddSkill() {
    return (
      <SkillGeneratorModal>
        {({ handleStartAddSkill, renderModal }) => (
          <>
            {renderModal()}
            <AddASkillButtonContainer>
              <AddASkillButton
                onClick={handleStartAddSkill}
                rcLabel="add-a-skill"
              >
                Add a Skill
              </AddASkillButton>
            </AddASkillButtonContainer>
          </>
        )}
      </SkillGeneratorModal>
    );
  }

  return (
    <AppLayout
      headerProps={headerProps}
      layout={AppLayout.Layouts.Profile}
      mainColumn={renderMainColumn()}
      sideColumn={(
        <AvatarContainer iconName="skillsPageIcon" title="Your Skills">
          <AvatarDescription>
            Matter gathers feedback on the skills you add. Select skills recommended by Matter or add your own custom skills.
          </AvatarDescription>
          {renderAddSkill()}
          {renderSkillsFeedbackCounts()}
        </AvatarContainer>
      )}
    />
  );
}

export default SkillsPage;
