export function getInitialState() {
  const initialSettings = {
    isOpen: false,
    isFeedbackMenuOpen: false,
    isChannelMenuOpen: false,
    isAdminMenuOpen: false,
    topNavItem: null
  };

  return {
    ...initialSettings,
    initialSettings,
  };
}

export const ACTIONS = {
  UPDATE_MOBILE_MENU: 'UPDATE_MOBILE_MENU',
  OPEN_CHANNEL_MENU: 'OPEN_CHANNEL_MENU',
  OPEN_FEEDBACK_MENU: 'OPEN_FEEDBACK_MENU',
  OPEN_ADMIN_MENU: 'OPEN_ADMIN_MENU',
  SET_TOP_NAV_ITEM: 'SET_TOP_NAV_ITEM'
};

export default function reducer(state, action) {
  switch (action.type) {
  case ACTIONS.UPDATE_MOBILE_MENU: {
    return {
      ...state.initialSettings,
      isOpen: action.payload,
    };
  }
  case ACTIONS.OPEN_CHANNEL_MENU: {
    return {
      ...state.initialSettings,
      isChannelMenuOpen: action.payload,
      isOpen: action.payload
    };
  }
  case ACTIONS.OPEN_FEEDBACK_MENU: {
    return {
      ...state.initialSettings,
      isFeedbackMenuOpen: action.payload,
      isOpen: action.payload
    };
  }
  case ACTIONS.OPEN_ADMIN_MENU: {
    return {
      ...state.initialSettings,
      isAdminMenuOpen: action.payload,
      isOpen: action.payload
    };
  }
  case ACTIONS.SET_TOP_NAV_ITEM: {
    return {
      ...state,
      topNavItem: action.payload
    };
  }

  default: {
    return {};
  }
  }
}