import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ListItem,
  ListItemActionBox,
  ListItemStackContainer,
} from '../List';
import Avatar from '../Avatar';
import { IconNew as Icon } from '../Icon';
import Tooltip from '../Tooltip';
import theme from '@matterapp/matter-theme';

const avatarSize = 48;
const RecurringIcon = styled(Icon).attrs({ name: 'recurringIndicator', fill: theme.colors.blacks[60] })``;

const AvatarBox = styled.div`
  width: ${theme.spacing.triple};
  margin-right: ${theme.spacing.single};
  align-self: flex-start;
  &:hover {
    cursor: ${({ isSelf }) => isSelf ? 'default' : 'pointer' };
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  user-select: none;

  > * {
    display: inline;
    line-height: ${theme.lineHeight.S};
  }
`;

const ListItemContainer = styled(ListItemStackContainer)`
  align-items: center;
  flex: 1;
  min-width: 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const NameWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const NameContainer = styled.div`
  display: flex;
  font-weight: ${theme.fontWeight.semiBold};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.colors.blacks[90]};
  &:hover {
    cursor: ${({ isSelf }) => isSelf ? 'default' : 'pointer' };
  }
`;

const TooltipContainer = styled.div`
  margin-top: -${theme.spacing.half};
  ${theme.media.S`
    margin-top: 0;
    margin-left: -${theme.spacing.half};
  `}
  &:hover {
    svg path {
    fill: ${theme.colors.greens[60]};
    stroke: ${theme.colors.greens[60]};
    }
  }
`;

const DescriptionContainer = styled.div`
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[50]};
`;

const StyledListItem = styled(ListItem)`
  flex-wrap: nowrap;
  white-space: normal;
`;

export default class AdvisorListItem extends React.PureComponent {
  static propTypes = {
    /** The content of the actions container of the list item. */
    actions: PropTypes.node,
    /** Additional style class. */
    className: PropTypes.string,
    /** Additional children components. */
    children: PropTypes.node,
    /** Additional children components (shortcut). If children and content are passed, content is displayed before children. */
    content: PropTypes.node,
    /** When the user has no avatar image, generate default avatar of use stock avatar image (leaf). */
    defaultAvatarIsGenerated: PropTypes.bool,
    /** On click advisor title or avatar */
    onClick: PropTypes.func,
    /** The peer definition object. */
    advisor: PropTypes.shape({
      /** The email of the peer. */
      email: PropTypes.string.isRequired,
      /** The full name of the peer. */
      fullName: PropTypes.string,
      /** The url of the photo to display. */
      photoUrl: PropTypes.string,
    }).isRequired,
    /** added for workspaces members list */
    showAdvisorIcon: PropTypes.bool,
  };

  /**
   * Renders the avatar of the list item.
   * @returns { React.Component } The rendered avatar.
   */
  renderAvatar = () => {
    const { defaultAvatarIsGenerated, advisor, onClick } = this.props;
    const { email, photoUrl, isSelf } = advisor;
    return (
      <AvatarBox onClick={onClick} isSelf={isSelf}>
        <Avatar
          defaultAvatarIsGenerated={defaultAvatarIsGenerated}
          email={email}
          size={avatarSize}
          url={photoUrl}
        />
      </AvatarBox>
    );
  };

  render() {
    const {
      actions,
      children,
      className,
      content,
      advisor,
      onClick,
      tooltipContent,
    } = this.props;
    const { email, fullName, isSelf } = advisor;
    const name = isSelf ? fullName + ' (You)' : fullName;

    return (
      <StyledListItem className={className}>
        <ListItemContainer isStackedOnMobile={false}>
          <ContentContainer>
            {this.renderAvatar()}
            <TitleContainer>
              <NameWrapper>
                <NameContainer onClick={onClick} isSelf={isSelf}>
                  {name || email}
                </NameContainer>
                {tooltipContent && 
                <TooltipContainer>
                  <Tooltip
                    content={tooltipContent}
                    style={{ minWidth: 'min-content' }}
                  >
                    <RecurringIcon />
                  </Tooltip>
                </TooltipContainer>}
              </NameWrapper>
              <DescriptionContainer>
                {content}
              </DescriptionContainer>
            </TitleContainer>
            {children}
          </ContentContainer>
          {actions && <ListItemActionBox>{actions}</ListItemActionBox>}
        </ListItemContainer>
      </StyledListItem>
    );
  }
}
