import React from 'react';
import { Confirm, Advisors } from '@matterapp/matter-ui';
import { DropdownContainer, FrequencyContainer, StartingContainer, FrequencyDescription } from './styles';
import { useDayOfPostPicker } from './hooks';
import { FEEDBACK_RITUAL_PANEL_TEXT, TextReplace, frequencyOptionsList, weekdayOptionsList, FREQUENCY_TEXT } from '../sharedConsts';

const DayOfPostPicker = (props) => {
  const {
    handleClickCancel,
    handleChangeDayOfPost,
    handleChangeFrequency,
    handleClickConfirm,
    handleCloseModal,
    isConfirmOpen,
    selectedDay,
    selectedFrequency
  } = useDayOfPostPicker(props);
  const selectedDayName = selectedDay.name;
  return (
    <>
      <DropdownContainer>
        <FrequencyContainer>
          <Advisors.RecurringModal.Dropdown
            options={frequencyOptionsList}
            selectedOption={selectedFrequency}
            handleChangeSelection={handleChangeFrequency}
            width={'100%'}
            position={'absolute'}
          />
        </FrequencyContainer>

        <StartingContainer>
          <Advisors.RecurringModal.Dropdown
            options={weekdayOptionsList}
            selectedOption={selectedDay}
            handleChangeSelection={handleChangeDayOfPost}
            width={'100%'}
            position={'absolute'}
          />
        </StartingContainer>
      </DropdownContainer>
      <FrequencyDescription>
        Occurs once every <strong>{FREQUENCY_TEXT[selectedFrequency.value]}</strong> on <strong>{selectedDayName}s</strong>
      </FrequencyDescription>
      <Confirm
        isOpen={isConfirmOpen}
        header={FEEDBACK_RITUAL_PANEL_TEXT.DAY_OF_POST_CONFIRM_HEADER}
        subHeader={
          <TextReplace
            replaceText={selectedDayName}
            replaceTextElement={'b'}
            text={FEEDBACK_RITUAL_PANEL_TEXT.DAY_OF_POST_CONFIRM_SUB_HEADER}
          />
        }
        cancelButtonLabel={FEEDBACK_RITUAL_PANEL_TEXT.CANCEL_BUTTON}
        confirmButtonLabel={
          <TextReplace
            replaceText={selectedDayName}
            text={FEEDBACK_RITUAL_PANEL_TEXT.DAY_OF_POST_CONFIRM_POST_BUTTON}
          />
        }
        onClickCancel={handleClickCancel}
        onClickConfirm={handleClickConfirm}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default DayOfPostPicker;
