import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ThreeStepCircleProgress from '../../Animations/ThreeStepCircleProgress';
import Label from './Label';
import theme from '@matterapp/matter-theme';

const stepLabelPropTypes = PropTypes.shape({
  disabled: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
});

const defaultLabelProps = {
  step1: {
    title: 'Step One',
  },
  step2: {
    title: 'Step Two',
  },
  step3: {
    title: 'Step Three',
  },
};

const Container = styled.div``;

const Wrapper = styled.div`
  display: inline-block;
  text-align: center;
`;

const ProgressOverlay = styled.div`
  align-items: center;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #ffffff;
  border-radius: 100%;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.15);
  color: ${theme.colors.purples[50]};
  display: flex;
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.bold};
  height: 120px;
  justify-content: center;
  left: 50%;
  line-height: ${theme.lineHeight.S};
  padding: ${theme.spacing.single};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
`;

const PropgressContainer = styled.div`
  position: relative;
  line-height: 0;
`;

const TopLabelContainer = styled.div`
  width: 100%;
`;

const BottomLabelContainer = styled.div`
  display: flex;
  margin-top: -${theme.spacing.double};
  width: 100%;
`;

const BottomLabelSpacer = styled.div`
  height: 100%;
  width: 100px;
`;

const ThreeStepCircle = (props) => {
  const { animate, children, className, currentStep, hideLabels, isComplete, onCompleteAnimation, shouldAnimate, stepLabelProps } = props;
  const { step1, step2, step3 } = stepLabelProps;
  const internalStep = isComplete ? 4 : currentStep;

  const createStepLabel = ({ disabled, step, title, text }) => (
    <Label
      hide={hideLabels}
      step={step}
      title={title}
      text={text}
      isComplete={internalStep > step}
      disabled={typeof disabled == undefined ? step > currentStep : disabled}
    />
  );

  return (
    <Container className={className}>
      <Wrapper>
        <TopLabelContainer>
          {createStepLabel({ step: 1, ...(step1 || {}) })}
        </TopLabelContainer>
        <PropgressContainer>
          <ProgressOverlay>{children}</ProgressOverlay>
          <ThreeStepCircleProgress
            animate={animate}
            isComplete={isComplete}
            onComplete={onCompleteAnimation}
            shouldAnimate={shouldAnimate}
            step={Math.min(currentStep, 3)}
          />
        </PropgressContainer>
        <BottomLabelContainer>
          {createStepLabel({ step: 3, ...(step3 || {}) })}
          <BottomLabelSpacer />
          {createStepLabel({ step: 2, ...(step2 || {}) })}
        </BottomLabelContainer>
      </Wrapper>
    </Container>
  );
};

ThreeStepCircle.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  currentStep: PropTypes.oneOf(ThreeStepCircleProgress.steps),
  hideLabels: PropTypes.bool,
  isComplete: PropTypes.bool,
  onCompleteAnimation: PropTypes.func,
  shouldAnimate: PropTypes.bool,
  stepLabelProps: PropTypes.shape({
    step1: stepLabelPropTypes,
    step2: stepLabelPropTypes,
    step3: stepLabelPropTypes,
  }),
};

ThreeStepCircle.defaultProps = {
  stepLabelProps: defaultLabelProps,
  onCompleteAnimation: () => {},
};

ThreeStepCircle.defaultLabelProps = defaultLabelProps;

export default ThreeStepCircle;
