import React, { useState, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Confirm } from '../Confirm';
import UploadIcon from './UploadIcon';
import { ACCEPT_TYPES, useFiles } from './consts';
import {
  Container,
  IconContainer,
  CropContainer,
  Header,
  SubHeader,
  ChangeButtonWithText,
  ImageContainer
} from './styles';

const ImageWithCrop = (props) => {
  const {
    className,
    disabled,
    enableClick,
    multiple,
    enableDragAndDrop,
    onMaxFileSizeNotMet,
    icon,
    onChange,
    onRemove,
    urls,
    onFileAccepted,
    showIcon,
    uploadImageHeader,
    uploadImageSubHeader,
    maxSize,
    aspectRatio,
    ...dropzoneProps
  } = props;
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    ...dropzoneProps,
    disabled,
    noClick: !enableClick,
    noDrag: !enableDragAndDrop,
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles && acceptedFiles.length) {
        const file = acceptedFiles[0];
        if (file.size > maxSize) {
          onMaxFileSizeNotMet();
        } else {
          setUploadedImg(URL.createObjectURL(file));
        }
      }
    }
  });

  const { hasFiles } = useFiles({
    acceptedFiles,
    onChange,
    onRemove,
    urls,
  });

  const [uploadedImg, setUploadedImg] = useState();
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: aspectRatio || 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [showImageCropModal, setShowImageCropModal] = useState(false);
  const [croppedFile, setCroppedFile] = useState(null);
  

  const onCloseModal = () => {
    setShowImageCropModal(false);
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > maxSize) {
        onMaxFileSizeNotMet();
      } else {
        setUploadedImg(URL.createObjectURL(file));
      }
    }
  };

  const dataUrlToFile = (dataUrl, fileName) => {
    let byteString;
    if (dataUrl.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataUrl.split(',')[1]);
    } else {
      byteString = unescape(dataUrl.split(',')[1]);
    }

    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

    const intArr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      intArr[i] = byteString.charCodeAt(i);
    }

    const file = new Blob([intArr], { type: mimeString });
    file.name = fileName;
    file.lastModifiedDate = new Date();

    return file;
  };

  const setCroppedImg = () => {
    const canvas = document.createElement("canvas");
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL("image/png");
    const file = dataUrlToFile(base64Image, uploadedImg?.fileName || 'uploadedImage');
    setCroppedFile(file);
  };

  useEffect(() => {
    if (completedCrop) {
      setCroppedImg();
    }
  }, [completedCrop]);

  useEffect(() => {
    if (uploadedImg) {
      setShowImageCropModal(true);
    }
  }, [uploadedImg]);

  const renderImageCropModal = () => {
    const { changePhotoHeader } = props;
    return (
      <Confirm
        isOpen={showImageCropModal}
        onClickCancel={onCloseModal}
        onClickConfirm={async () => {
          onCloseModal();
          await onFileAccepted(croppedFile);
        }}
        onClose={onCloseModal}
        header={changePhotoHeader}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Save"
        useVividConfirmButton
        maxHeight="initial"
        subHeader={
          <CropContainer>
            <ReactCrop
              src={uploadedImg}
              onImageLoaded={setImage}
              crop={crop}
              onChange={(c) => {
                setCrop(c);
              }}
              onComplete={(c) => {
                setCompletedCrop(c);
              }}
            />
          </CropContainer>
        }
      />
      
    );
  };

  return (
    <ImageContainer>
      {renderImageCropModal()}
      <Container
        {...{ className, disabled, enableClick, hasFiles, enableDragAndDrop, multiple }}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} onChange={onSelectFile}/>
        {showIcon &&
          <>
            <IconContainer>{icon}</IconContainer>
            {uploadImageHeader && <Header> {uploadImageHeader} </Header>}
            {uploadImageSubHeader && <SubHeader> {uploadImageSubHeader} </SubHeader> }
          </>
        }
        {!showIcon &&
          <ChangeButtonWithText>CHANGE</ChangeButtonWithText>
        }
      </Container>
    </ImageContainer>
  );
};

ImageWithCrop.propTypes = {
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onMaxFileSizeNotMet: PropTypes.func,
  onMinFileSizeNotMet: PropTypes.func,
  onFileTypeNotMet: PropTypes.func,
  onFileAccepted: PropTypes.func,
};

ImageWithCrop.defaultProps = {
  accept: ACCEPT_TYPES.IMAGE.ANY,
  showIcon: true,
  disabled: false,
  enableClick: true,
  enableDragAndDrop: true,
  multiple: false,
  onChange: () => {},
  onRemove: () => {},
  icon: <UploadIcon />,
  maxSize: 2000000,
  onMaxFileSizeNotMet: () => null,
  onMinFileSizeNotMet: () => null,
  onFileTypeNotMet: () => null,
  onFileAccepted: () => null,
};

export default ImageWithCrop;
