import React, { useEffect } from 'react';
import { Resources } from '@matterapp/routing';
import PropTypes from 'prop-types';
import withRouter from 'hocs/withRouter';
import { compose } from 'recompose';
import withAuthReducer from 'hocs/auth/withAuthReducer';
import Toast from 'components/Toast/Toast';
import { Loader } from '@matterapp/matter-ui';
import { useMutation } from '@apollo/client';
import { SLACK_LOGIN_MUTATION } from 'graphql-queries/queries';
import { SIGNUP_MEANS } from 'app-consts';
import { handleTrackSignup } from 'libs/tracking/signup';

function SlackLoginGatewayPage(props) {
  const {
    currentUserStatus,
    history,
    queryParams,
    startAuth,
    endAuth
  } = props;
  const { code, inapp } = queryParams;
  const { isLoggedIn } = currentUserStatus;

  const [handleSlackLogin, { data: slackLoginData }] = useMutation(SLACK_LOGIN_MUTATION);

  useEffect(() => {
    if (inapp) {
      history.replace(
        `${Resources.workspacesCreateWorkspaceFlow.path()}?code=${code}`
      );
    }
  }, [inapp]);

  useEffect(() => {
    if (!inapp && !isLoggedIn) {
      startAuth();
      handleSlackLogin({
        variables: {
          token: code
        }
      }).catch(() => {
        endAuth();
        //history.replace(Resources.authRequestVerification.path());
        Toast.error((<span><strong>Oh no!</strong> Something went wrong, please try again.</span>));
      });
    }
  }, [isLoggedIn, inapp]);

  useEffect(() => {
    if (slackLoginData) {
      const { isNewRecord, user, redirect } = slackLoginData.login;

      handleTrackSignup({
        isNewRecord,
        user,
        signupMeans: SIGNUP_MEANS.SLACK,
      });

      window.localStorage.setItem('loginType', 'slack');

      endAuth();
      
      if (redirect) {
        window.location.href = redirect;

        return;
      }

      history.replace(Resources.authLoginGateway.path());
    }
  }, [slackLoginData]);

  return (
    <Loader />
  );
}

SlackLoginGatewayPage.propTypes = {
  currentUserStatus: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
  }),
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
  queryParams: PropTypes.shape({
    code: PropTypes.string,
    inapp: PropTypes.string
  }),
  startAuth: PropTypes.func.isRequired,
  endAuth: PropTypes.func.isRequired,
};

const withData = compose(
  withRouter,
  withAuthReducer
);

export default withData(SlackLoginGatewayPage);
