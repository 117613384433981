import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import map from 'lodash/map';
import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import debounce from 'lodash/debounce';
import Transition from 'react-transition-group/Transition';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../libs/omitStyled';

const triangleSize = { width: 24, height: 12 };
const triangleHalfWidth = triangleSize.width / 2;
const menuOffset = { side: 24 + 12, top: triangleSize.height };

const menuTopTranslate = 5;
const menuAnimationDuration = 150;

const menuBorderColor = 'rgba(0, 0, 0, 0.15)';

function getCSSValue(val) {
  return typeof val === 'number' ? `${val}px` : val;
}

function genCSS(obj, { prefix } = { prefix: '' }) {
  const props = map(
    Object.keys(obj),
    (key) => `${prefix}${key}: ${getCSSValue(obj[key])};`
  );
  return props.join('\n');
}

const Container = styled.div`
  user-select: none;
`;

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: ${theme.sizes.borderRadius[80]};
`;

const TriggerContainer = styled.div.attrs({
  className: 'popover-menu__trigger',
})`
  height: 100%;
  cursor: pointer;

  svg {
    display: block;
  }
`;

const HOVER_TIMEOUT = 10;
const TRIANGLE_HEIGHT = 12;

const Triangle = styled.div`
  position: absolute;
  top: -${menuOffset.top}px;
  height: 0px;
  width: 0px;
  border-left: ${TRIANGLE_HEIGHT}px solid transparent;
  border-right: ${TRIANGLE_HEIGHT}px solid transparent;
  border-bottom: ${TRIANGLE_HEIGHT}px solid ${({ hasBorder }) =>
  hasBorder ? menuBorderColor : 'transparent'};

  &:after {
    position: absolute;
    content: '';
    height: 0px;
    width: 0px;
    top: ${({ hasBorder }) => (hasBorder ? '1px' : '0px')};
    left: -${TRIANGLE_HEIGHT + 1}px;
    border-left: ${TRIANGLE_HEIGHT + 1}px solid transparent;
    border-right: ${TRIANGLE_HEIGHT + 1}px solid transparent;
    border-bottom: ${TRIANGLE_HEIGHT + 1}px solid ${theme.colors.white};
  }
  ${({ positionStyle }) =>
    positionStyle.left
      ? 'margin-left'
      : 'margin-right'}: -${triangleHalfWidth}px;

  ${({ positionStyle }) => genCSS(pick(positionStyle, ['left', 'right']))}

  ${({ positionAdjust, isFullWidthMobile }) =>
    !isFullWidthMobile &&
    `
    ${genCSS(mapValues(pick(positionAdjust, ['left', 'right']), (v) => -v))}
  `}

  ${({ positionAdjust }) => theme.media.medium`
    ${genCSS(mapValues(pick(positionAdjust, ['left', 'right']), (v) => -v))}
  `}
`;

const Menu = omitStyled('div', [
  'positionAdjust',
  'positionStyle',
  'width',
  'hasBorder',
  'isFullWidthMobile',
  'mobileFullWidthMargin',
]).attrs({
  className: 'popover-menu__menu',
})`
  position: absolute;
  box-sizing: border-box;

  z-index: 10;

  ${({ isFullWidthMobile, mobileFullWidthMargin }) =>
    isFullWidthMobile &&
    `
    left: ${mobileFullWidthMargin};
    width: calc(100% - (${mobileFullWidthMargin} * 2));

    & ${Triangle} {
      transform: translateX(${mobileFullWidthMargin});
    }

    ${theme.media.S`
      & ${Triangle} {
        transform: translateX(0);
      }
    `}
  `}

  ${({ isFullWidthMobile, mobileFullWidthMargin }) =>
    isFullWidthMobile &&
    theme.media.S`
      & ${Triangle} {
        transform: translateX(0);
      }
  `}

  ${({ width, isFullWidthMobile, positionStyle, positionAdjust }) =>
    !isFullWidthMobile &&
    `
    width: ${width}${typeof width === 'string' ? '' : 'px'};
    left: auto;
    bottom: auto;
    top: ${positionStyle.top}px;

    ${genCSS(pick(positionStyle, ['left', 'right']))}
    ${genCSS(mapKeys(pick(positionAdjust, ['left', 'right']), (v, k) => `margin-${k}`))}
  `}

  margin-top: ${menuOffset.top}px;

  background: white;
  border-radius: ${() => theme.sizes.borderRadius[80]};

  transform: translateY(${menuTopTranslate});
  transition: opacity ${menuAnimationDuration}ms ease, transform ${menuAnimationDuration}ms ease;
  opacity: 0;

  text-align: left;
  box-shadow: ${() => theme.shadows[47]};

  ${({ hasBorder }) =>
    hasBorder &&
    `
    border: 0.5px solid ${menuBorderColor};
  `};

  ${({ width, positionAdjust, positionStyle }) => theme.media.medium`
    position: absolute;
    width: ${width}${typeof width === 'string' ? '' : 'px'};
    left: auto;
    bottom: auto;
    top: ${positionStyle.top}px;

    ${genCSS(pick(positionStyle, ['left', 'right']))}
    ${genCSS(mapKeys(pick(positionAdjust, ['left', 'right']), (v, k) => `margin-${k}`))}

    border-radius: ${theme.sizes.borderRadius[80]};
  `}
`;

const menuTransitionStyles = {
  entering: { opacity: 1, transform: 'translateY(0)' },
  entered: { opacity: 1, transform: 'translateY(0)' },
  exiting: { opacity: 0, transform: `translateY(${menuTopTranslate}px)` },
  exited: { opacity: 0, transform: `translateY(${menuTopTranslate}px)` },
};

export default class PopoverMenu extends React.Component {
  static propTypes = {
    /** Additional children components. */
    children: PropTypes.node.isRequired,
    /** Additional classes. */
    className: PropTypes.string,
    /** Margin to add on sides of menu if full screen on mobile. */
    mobileFullWidthMargin: PropTypes.string,
    /** If popover menu should have a border. */
    hasBorder: PropTypes.bool,
    /** If popover menu has triangle pointer. */
    hasTriangle: PropTypes.bool,
    /** If the menu should display full width on mobile. */
    isFullWidthMobile: PropTypes.bool,
    /** Prop state if popover is open. */
    isOpen: PropTypes.bool.isRequired,
    /**
     * Callback to open the popover.
     * @params { Boolean } isOpen: If the menu is open. Always true.
     * @returns { void }
     */
    onOpen: PropTypes.func.isRequired,
    /**
     * Callback to close the popover.
     * @params { Boolean } isOpen: If the menu is open. Always false.
     * @returns { void }
     */
    onClose: PropTypes.func.isRequired,
    /** Position of the popover when open. */
    position: PropTypes.oneOf(['right', 'left']),
    /** The component to trigger the popover on. */
    triggerComponent: PropTypes.node.isRequired,
    /** Triggers the popover on hover in addition to click. */
    triggerOnHover: PropTypes.bool,
    /** Width style of the popover. */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    className: null,
    hasBorder: true,
    hasTriangle: false,
    mobileFullWidthMargin: '0%',
    isFullWidthMobile: false,
    position: 'left',
    triggerOnHover: false,
    width: 'auto',
  };

  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      enterTrigger: false,
      enterMenu: false,
    };
  }

  componentDidMount = () => {
    this.updateTriggerPosition();
    window.addEventListener('resize', this.handleWindowResize);
  };

  componentDidUpdate = () => {
    if (this.props.isOpen && !this.windowClickHandler) {
      window.addEventListener('mouseup', this.handleWindowClick);
    } else if (!this.props.isOpen && this.handleWindowClick) {
      window.removeEventListener('mouseup', this.windowClickHandler);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleWindowResize);
    clearTimeout(this.hoverTimeout);
  };

  updateTriggerPosition = () => {
    if (!this.triggerEl) {
      return;
    }

    const offsetEl =
      this.triggerEl.offsetTop != null
        ? this.triggerEl
        : this.triggerContainerEl;

    const { width, height } = this.triggerEl.getBoundingClientRect();
    const { offsetTop, offsetLeft, offsetParent } = offsetEl;
    const { offsetWidth } = offsetParent || { offsetWidth: 0 };
    const offsetRight = offsetWidth - offsetLeft - width;

    // These locate to the center bottom of the trigger item
    this.setState({
      top: offsetTop + height,
      left: offsetLeft + width / 2,
      right: offsetRight + width / 2,
    });
  };

  handleWindowResize = debounce(() => {
    this.updateTriggerPosition();
  }, 100);

  /**
   * Callback when window is clicked to close popover if open.
   * @returns { Object } Error object.
   */
  handleWindowClick = (event) => {
    const { onClose } = this.props;
    const { target } = event;
    try {
      if (!this.triggerContainerEl.contains(target)) {
        onClose();
      }
      return null;
    } catch (e) {
      return e;
    }
  };

  /**
   * Callback when an item in the popover menu is click to close the popover.
   * @returns { void }
   */
  handleMenuClick = () => {
    if (this.triggerContainerEl) {
      this.props.onClose();
      this.setState({ enterTrigger: false, enterMenu: false });
    }
  };

  /**
   * Callback to toggle the popover.
   * @returns { void }
   */
  handleToggle = () => {
    const { isOpen, onClose, onOpen, triggerOnHover } = this.props;
    if (isOpen && !triggerOnHover) {
      onClose();
    } else {
      onOpen();
    }
  };

  /**
   * Callback when the mouse enters menu to set state flag. Prevents menu from
   * closing if `triggerOnHover = true` and mouse has left the trigger but
   * entered the menu.
   * @returns { void }
   */
  handleMouseEnterMenu = () => {
    this.setState({ enterMenu: true });
    this.props.onOpen();
  };

  /**
   * Callback when the mouse leaves the menu to disabled the state flag and
   * toggles the popover.
   * @returns { void }
   */
  handleMouseLeaveMenu = () => {
    this.setState({ enterMenu: false }, () => {
      this.hoverTimeout = setTimeout(this.handleCloseMenu, HOVER_TIMEOUT);
    });
  };

  /**
   * Callback when the mouse enters the trigger.
   * @returns { void }
   */
  handleMouseEnterTrigger = () => {
    this.setState({ enterTrigger: true });
    this.props.onOpen();
  };

  /**
   * Callback when the mouse leaves the trigger. It force closes the popover if
   * the mouse has not entered the menu.
   * @returns { void }
   */
  handleMouseLeaveTrigger = () => {
    this.setState({ enterTrigger: false }, () => {
      this.hoverTimeout = setTimeout(this.handleCloseMenu, HOVER_TIMEOUT);
    });
  };

  /**
   * Callback to close the menu. Checks if cursor is on menu or trigger to
   * detemine if menu can close.
   * @returns { void }
   */
  handleCloseMenu = () => {
    if (
      this.triggerContainerEl &&
      !this.state.enterMenu &&
      !this.state.enterTrigger
    ) {
      this.setState({ enterTrigger: false, enterMenu: false });
      this.props.onClose();
    }
  };

  /**
   * Get additional event props on the menu component when
   * `triggerOnHover = true`.
   * @returns { Object } Additional event props for the menu.
   */
  getMenuToggleProps = () => {
    const { triggerOnHover } = this.props;
    const toggleProps = {};

    if (triggerOnHover) {
      toggleProps.onMouseEnter = this.handleMouseEnterMenu;
      toggleProps.onMouseLeave = this.handleMouseLeaveMenu;
    }
    return toggleProps;
  };

  /**
   * Get event props on the trigger component. Appends additional event props
   * when `triggerOnHover = true`.
   * @returns { Object } Event props for the trigger component.
   */
  getToggleTriggerProps = () => {
    const { triggerOnHover } = this.props;
    const toggleProps = {
      onClick: this.handleToggle,
    };

    if (triggerOnHover) {
      toggleProps.onMouseEnter = this.handleMouseEnterTrigger;
      toggleProps.onMouseLeave = this.handleMouseLeaveTrigger;
    }

    return toggleProps;
  };

  render = () => {
    const {
      triggerComponent,
      isOpen,
      children,
      width,
      position,
      hasBorder,
      hasTriangle,
      className,
      isFullWidthMobile,
      mobileFullWidthMargin,
    } = this.props;

    let positionAdjust = { left: -menuOffset.side };
    let positionStyle = { top: this.state.top, left: this.state.left };
    if (position === 'right') {
      positionAdjust = { right: -menuOffset.side };
      positionStyle = { top: this.state.top, right: this.state.right };
    }

    return (
      <Container className={className}>
        <TriggerContainer
          ref={(el) => {
            this.triggerContainerEl = el;
            this.triggerEl = el ? el.childNodes[0] : null;
          }}
          {...this.getToggleTriggerProps()}
        >
          {triggerComponent}
        </TriggerContainer>
        <Transition
          in={isOpen}
          timeout={menuAnimationDuration}
          unmountOnExit
          mountOnEnter
          onEnter={
            (node) =>
              node.scrollTop /* force a reflow so initial animation triggers */
          }
        >
          {(transitionState) => (
            <Menu
              width={width}
              hasBorder={hasBorder}
              isFullWidthMobile={isFullWidthMobile}
              mobileFullWidthMargin={mobileFullWidthMargin}
              positionAdjust={positionAdjust}
              positionStyle={positionStyle}
              style={menuTransitionStyles[transitionState]}
              onClick={this.handleMenuClick}
              {...this.getMenuToggleProps()}
            >
              {hasTriangle && (
                <Triangle
                  hasBorder={hasBorder}
                  positionAdjust={positionAdjust}
                  positionStyle={positionStyle}
                  isFullWidthMobile={isFullWidthMobile}
                />
              )}
              <Wrapper>{children}</Wrapper>
            </Menu>
          )}
        </Transition>
      </Container>
    );
  };
}
