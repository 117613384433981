import styled from 'styled-components';
import { 
  Box,
  Paper
} from '@mui/material';

export const FundingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  height: '105px',
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: '4px',
  border: `1px solid ${theme.palette.blacks[10]}`,
  transitionDuration: '0.2s',
  transitionProperty: 'background-color',
  cursor: 'pointer',
  ':hover':{
    backgroundColor: theme.palette.blacks[5]
  },
  ':active': {
    backgroundColor: theme.palette.purples[5]
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'auto',
    marginRight: 0,
    flexDirection: 'column'
  }
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.purples[5],
  padding: 0,
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  svg: {
    width: '16px',
    height: '16px'
  }
}));

export const NoFeeWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  alignItems: 'center', 
  alignSelf: 'baseline',
  '> div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60px',
    height: '24px',
    backgroundColor: theme.palette.greens[5],
    color: theme.palette.greens[50],
    fontWeight: 'bold',
    marginRight: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1)
  }
}));

export const PaperWrapper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  maxWidth: '760px',
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    borderRadius: 0
  }
}));

export const FundingCopyWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));