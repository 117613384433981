import React from 'react';
import { Text, Form, Container } from '@matterapp/matter-ui';
import { Field as ReduxFormField } from 'redux-form';
import PropTypes from 'prop-types';

const PhoneNumberOrEmailField = class extends React.Component {
  componentDidMount() {
    if (this.input && this.props.autoFocus) {
      this.input.focus();
    }
  }

  renderLabel = () => {
    if (!this.props.label) { return null; }
    return (
      <Text
        block
        color="grey"
        textStyle={500}
        textAlign="center"
        marginBottom={48}
      >
        {this.props.label}
      </Text>
    );
  };

  renderError = (reduxFormFieldProps) => {
    if (!(reduxFormFieldProps.meta.submitFailed && this.props.isShowError))
    {return null;}
    return <Form.Error errorString={reduxFormFieldProps.meta.error} />;
  };

  renderField = (reduxFormFieldProps) => (
    <Container marginBottom={this.props.marginBottom}>
      <Form.Input
        fluid
        autoFocus={this.props.autoFocus}
        marketingStyle={this.props.marketingStyle}
        size="massive"
        shortInline={this.props.shortInline}
        placeholder={this.props.placeholder}
        isHideErrorMessage={!this.props.isShowError}
        errorMessage={
          !reduxFormFieldProps.meta.submitFailed
            ? null
            : reduxFormFieldProps.meta.error
        }
        {...reduxFormFieldProps.input}
      />
    </Container>
  );

  render = () => (
    <ReduxFormField
      name="phoneNumberOrEmail"
      type="text"
      component={this.renderField}
    />
  );
};

PhoneNumberOrEmailField.propTypes = {
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  shortInline: PropTypes.bool,
  isShowError: PropTypes.bool,
  marginBottom: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  marketingStyle: PropTypes.bool,
};

PhoneNumberOrEmailField.defaultProps = {
  label: '',
  autoFocus: false,
  isShowError: false,
  marginBottom: 0,
};

export default PhoneNumberOrEmailField;
