import styled from 'styled-components';
import BrushStrokes1 from './backgrounds/Home';
import Clouds from './backgrounds/Workspace';
import { Clickable } from '../../../Clickable/Clickable';
import { IconNew as Icon } from '../../../Icon';
import theme from '@matterapp/matter-theme';

const BACKGROUND_HEIGHT = '180px';

const baseHeaderStyles = `
  color: ${theme.colors.white};
  line-height: ${theme.lineHeight.L};
  font-size: ${theme.fontSize.M};
  margin-bottom: ${theme.spacing.half};
`;

// prettier-ignore
export const Container = styled.div`
  border-radius: 0px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  ${({ append }) => theme.media.M`
     border-radius: ${theme.borderRadius.L};
     ${append ? `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
     ` : ''}
  `}
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  transform: translateZ(-1px);

  & svg {
    height: 100%;
    width: auto;
    min-width: 100vw;
    transform: translateZ(${theme.spacing.half});
  }

  ${theme.media.XS`
    & svg {
      height: auto;
    }
  `}

  ${theme.media.S`
    & svg {
      min-height: 100%;
      min-width: 100%;
    }
  `}
`;

export const BaseBackgroundContainer = styled(BackgroundContainer)`
  background: ${theme.colors.purples[40]};

  & svg {
    color: ${theme.colors.purples[50]};
    min-height: ${BACKGROUND_HEIGHT};
    & path {
      opacity: 0.2;
    }
  }
`;

export const HomeBackground = BrushStrokes1;

export const HomeBackgroundContainer = styled(BackgroundContainer)``;

export const WorkspaceBackground = Clouds;

export const WorkspaceBackgroundContainer = styled(BackgroundContainer)``;

export const Wrapper = styled.div`
  min-height: 0;
  padding: ${theme.spacing.singleAndHalf};
  text-align: center;
  z-index: 1;

  ${theme.media.S`
    min-height: ${BACKGROUND_HEIGHT};
    text-align: left;
  `}
`;

export const CloseButton = styled(Clickable)`
  height: ${theme.spacing.double};
  width: ${theme.spacing.double};
  position: relative;
  left: 0;
  bottom: -2px;

  & svg path {
    fill: ${theme.colors.white};
  }

  ${theme.media.S`
    left: ${theme.spacing.quarter};
    bottom: ${theme.spacing.half};
  `}
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  name: 'close',
}))``;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 0px;

  ${theme.media.S`
    height: auto;
  `}
`;

export const Header = styled.div`
  ${baseHeaderStyles}
  font-weight: ${theme.fontWeight.bold};
  opacity: 0;
  margin: 0;
  word-break: break-word;

  ${theme.media.S`
    opacity: 1;
    ${baseHeaderStyles}
  `}
`;

export const SubHeader = styled.div`
  ${baseHeaderStyles}
  font-weight: ${theme.fontWeight.medium};
  max-width: calc(100vw - 140px);
  margin-left: auto;
  margin-right: auto;
  word-break: break-word;

  ${theme.media.S`
    max-width: 100%;
  `}
`;

export const Contents = styled.div`
  color: ${theme.colors.white};
  font-size: ${theme.fontSize.S};
  font-weight: ${theme.fontWeight.regular};
  line-height: ${theme.lineHeight.M};
`;
