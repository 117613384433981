import React from 'react';
import { rgba } from 'polished';
import { Clickable } from '../../Clickable/Clickable';
import omitStyled from '../../../libs/omitStyled';
import { buttonSizes, buttonTypes } from './props';
import { IconSpinner } from '../../Icon';
import theme, { createDataRole } from '@matterapp/matter-theme';

export const LOADER_DATA_ROLE = 'loader';
export const LOADER_DATA_ROLE_SELECTOR = `div${createDataRole(LOADER_DATA_ROLE)}`;

const getColorStyles = ({ color, disabled, bold, isLoading }) => {
  const baseColor = theme.colors[`${color}s`] || theme.colors.blues;
  const disabledColor = theme.colors.blacks;

  const borderStyles = `
    border-style: solid;
    border-width: 1px;
  `;

  const disabledTextColor = disabledColor[bold ? 30 : 40];

  const colorStyles = `
    border-color: ${disabled ? disabledColor[10] : baseColor[10]};
    color: ${disabled ? disabledTextColor : baseColor[60]};
  `;

  const disabledStyles = disabled
    ? `
    background: ${disabledColor[5]};
    cursor: not-allowed;
  `
    : ``;

  const pointerEvents = isLoading
    ? `
    pointer-events: none;
  `
    : ``;

  return `
    ${borderStyles}
    ${colorStyles}
    ${disabledStyles}
    ${pointerEvents}
  `;
};

const getLoaderBackground = ({ color, disabled }) => {
  const baseColor = theme.colors[`${color}s`] || theme.colors.blues;
  if (disabled) {
    return `
      background: ${rgba(theme.colors.blacks[40], 0.3)};
    `;
  }
  return `
    background: ${rgba(baseColor[40], 0.3)};
  `;
};

const styles = {
  base: {
    [buttonSizes.XL]: `
      font-size: ${theme.fontSize.M};
      line-height: ${theme.lineHeight.M};
      padding: ${theme.spacing.singleAndHalf};
    `,
    [buttonSizes.L]: `
      font-size: ${theme.fontSize.M};
      line-height: ${theme.lineHeight.M};
      min-height: ${theme.buttons.height.L};
      padding: ${theme.spacing.threeQuarters} ${theme.spacing.singleAndHalf};
    `,
    [buttonSizes.M]: `
      font-size: ${theme.fontSize.S};
      line-height: ${theme.lineHeight.M};
      min-height: ${theme.buttons.height.M};
      padding: ${theme.spacing.half} ${theme.spacing.singleAndHalf};
    `,
    [buttonSizes.S]: `
      font-size: ${theme.fontSize.base};
      line-height: ${theme.lineHeight.S};
      min-height: ${theme.buttons.height.S};
      padding: ${theme.spacing.quarter} ${theme.spacing.single};
    `,
    [buttonSizes.XS]: `
      font-size: ${theme.fontSize.base};
      line-height: ${theme.lineHeight.S};
      min-height: ${theme.buttons.height.S};
      padding: ${theme.spacing.quarter} ${theme.spacing.half};
    `,
  },
  bold: {
    [buttonSizes.XL]: `
      font-weight: ${theme.fontWeight.bold};
    `,
    [buttonSizes.L]: `
      font-size: calc(${theme.fontSize.M} - 2px);
      font-weight: ${theme.fontWeight.bold};
    `,
    [buttonSizes.M]: `
      font-weight: ${theme.fontWeight.medium};
    `,
    [buttonSizes.S]: `
      font-weight: ${theme.fontWeight.medium};
    `,
    [buttonSizes.XS]: `
      font-size: ${theme.fontSize.XS};
      font-weight: ${theme.fontWeight.medium};
    `,
  },
  [buttonTypes.CTA]: {
    [buttonSizes.XL]: theme.borderRadius.L,
    [buttonSizes.L]: theme.borderRadius.L,
    [buttonSizes.M]: theme.borderRadius.L,
    [buttonSizes.S]: theme.borderRadius.M,
    [buttonSizes.XS]: theme.borderRadius.M,
  },
  [buttonTypes.BUBBLE]: {
    [buttonSizes.XL]: `calc(${theme.buttons.height.L} * 2)`,
    [buttonSizes.L]: `calc(${theme.buttons.height.L} * 2)`,
    [buttonSizes.M]: `calc(${theme.buttons.height.M} * 2)`,
    [buttonSizes.S]: `calc(${theme.buttons.height.S} * 2)`,
    [buttonSizes.XS]: `calc(${theme.buttons.height.S} * 2)`,
  },
  square: {
    [buttonSizes.XL]: `
      font-size: ${theme.fontSize.M};
      line-height: ${theme.lineHeight.M};
      padding: ${theme.spacing.singleAndHalf};
    `,
    [buttonSizes.L]: `
      font-size: ${theme.fontSize.M};
      line-height: ${theme.lineHeight.M};
      height: ${theme.buttons.height.L};
      width: ${theme.buttons.height.L};
      padding: ${theme.spacing.threeQuarters};
    `,
    [buttonSizes.M]: `
      font-size: ${theme.fontSize.S};
      line-height: ${theme.lineHeight.M};
      height: ${theme.buttons.height.M};
      width: ${theme.buttons.height.M};
      padding: ${theme.spacing.half};
    `,
    [buttonSizes.S]: `
      font-size: ${theme.fontSize.base};
      line-height: ${theme.lineHeight.S};
      height: ${theme.buttons.height.S};
      width: ${theme.buttons.height.S};
      padding: ${theme.spacing.quarter};
    `,
    [buttonSizes.XS]: `
      font-size: ${theme.fontSize.base};
      line-height: ${theme.lineHeight.S};
      height: ${theme.buttons.height.S};
      width: ${theme.buttons.height.S};
      padding: ${theme.spacing.quarter};
    `,
  },
};

const getBaseStyles = ({ isSquare, size }) => {
  if (isSquare) {
    return styles.square[size] || styles.square.M;
  }
  return styles.base[size] || styles.base.M;
};

const getRadiusStyles = ({ size, buttonType }) => {
  const radiusStyles = styles[buttonType];
  if (radiusStyles) {
    return `border-radius: ${radiusStyles[size] || styles.M};`;
  }
  return `border-radius: ${theme.borderRadius.S};`;
};

const getFontStyles = ({ bold, isLoading, size }) => {
  if (bold) {
    const fontStyles = styles.bold[size];
    return `
      background-color: ${theme.colors.blacks[5]};
      font-weight: ${theme.fontWeight.bold};
      ${fontStyles}
      ${isLoading ? `color: ${theme.colors.blacks[20]} !important!` : ''}
    `;
  }
  return '';
};

const StyledButton = omitStyled(Clickable, [
  'bold',
  'color',
  'buttonType',
  'isLoading',
  'isLink',
  'isSquare',
  'theme',
])`
  box-sizing: border-box;
  user-select: none !important;
  -webkit-touch-callout: none !important;

  ${getColorStyles}
  ${getBaseStyles}
  ${getRadiusStyles}
  ${getFontStyles}
`;

const LoaderContainer = omitStyled('div', ['color', 'size', 'buttonType'])`
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.05);

  @keyframes spin { 100% { transform:rotate(360deg) } }

  svg {
    padding: 0;
    animation: spin 4s linear infinite;
  }
  ${getLoaderBackground}
  ${getRadiusStyles}
`;

export const Loader = (props) => (
  <LoaderContainer data-role={LOADER_DATA_ROLE} {...props}>
    <IconSpinner />
  </LoaderContainer>
);

export default StyledButton;
