import React, { useContext, useEffect } from 'react';
import { Tab, Tabs, ChannelsSelect } from './MobileSubMenu';
import UserContext from 'context/UserContext/UserContext';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';

export default function FeedbackMobileMenu() {
  const { currentTenant, currentWorkspace } = useContext(UserContext);
  const { isMobileMenuOpen, setIsMobileMenuOpen, setTopNavItem, setIsFeedbackMenuOpen } = useContext(MobileMenuContext);

  const feedbackMainPath = Resources.feedbackMain.path({ workspaceId: currentWorkspace?.id });
  const continuousMainPath = Resources.workspacesRecurring.path({ workspaceId: currentWorkspace?.id });
  const skillsMainPath = Resources.skillsMain.path({ workspaceId: currentWorkspace?.id });
  const requestsMainPath = Resources.feedbackRequestsMain.path();

  const navigate = useNavigate();

  useEffect(() => {
    if (isMobileMenuOpen) {
      setTopNavItem(<ChannelsSelect text='Feedback' iconVariant='feedback' />);
    } else {
      setIsFeedbackMenuOpen(false);
      setTopNavItem(
        <ChannelsSelect text={currentTenant?.name} iconVariant='back' iconAction={() => {
          setIsFeedbackMenuOpen(true);
        }} />
      );
    }
  }, [isMobileMenuOpen]);

  if (!currentTenant) {
    return null;
  }
  
  return (
    <Tabs>
      <Tab 
        onClick={() => {
          setIsMobileMenuOpen(false);
          navigate(feedbackMainPath);
        }}>
        Activity
      </Tab>
      <Tab 
        onClick={() => {
          setIsMobileMenuOpen(false);
          navigate(continuousMainPath);
        }}>
        Continuous
      </Tab>
      <Tab 
        onClick={() => {
          setIsMobileMenuOpen(false);
          navigate(skillsMainPath);
        }}>
        Skills
      </Tab>
      <Tab 
        onClick={() => {
          setIsMobileMenuOpen(false);
          navigate(requestsMainPath);
        }}>
        Request Status
      </Tab>
    </Tabs>
  );
}
