import React from 'react';
import PropTypes from 'prop-types';
import { Confirm } from '@matterapp/matter-ui';

const RemoveConfirmModal = (props) => {
  const { isOpen, onClose, onClickRemove } = props;
  return (
    <Confirm.Simple
      header="Remove yourself?"
      isOpen={isOpen}
      onClickConfirm={onClickRemove}
      onClickCancel={onClose}
      onClose={onClose}
      subHeader="This action will only remove you from this feedback. It will not delete this feedback for others."
      confirmButtonIsDestructive
      confirmButtonLabel="Yes, Remove"
      cancelButtonLabel="Cancel"
    />
  );
};

RemoveConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClickRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteProps: PropTypes.shape({
    isMultipleReceiver: PropTypes.bool,
  })
};

export default RemoveConfirmModal;
