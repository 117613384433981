import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from '@matterapp/matter-ui';
import { font, spacing } from '@matterapp/matter-theme';
import { PhoneNumberOrEmailField } from '../../../../form-fields';
import NextStepButton from 'components/molecules/NextStepButton';

const FormInputContainer = styled.div`
  margin-bottom: ${spacing.single};
  input {
    font-size: ${font.size.S2};
  }
`;

const FormContainer = styled.div`
  width: 100%;
`;

const StyledNextStepButton = styled(NextStepButton)`
  font-size: ${font.size.S2} !important;
  font-weight: ${font.weight.black};
  font-family: CircularXX;
`;

const PhoneNumberOrEmailForm = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  shortInline,
  marketing,
}) => (
  <FormContainer>
    <Form onSubmit={handleSubmit} centered>
      <FormInputContainer>
        <PhoneNumberOrEmailField
          shortInline={shortInline}
          autoFocus
          label="Sign In to Matter"
          placeholder="Enter your email"
          marginBottom={8}
          fluid
          marketingStyle={marketing}
        />
      </FormInputContainer>
      <StyledNextStepButton
        onClick={handleSubmit}
        invalid={invalid}
        submitting={submitting}
        pristine={pristine}
        marketing={marketing}
        size="medium"
        fluid
      >
        Continue
      </StyledNextStepButton>
    </Form>
  </FormContainer>
);

PhoneNumberOrEmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  shortInline: PropTypes.bool,
  marketing: PropTypes.bool,
};

export default PhoneNumberOrEmailForm;
