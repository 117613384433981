import React from 'react';
import Base from './Base';
import {
  HomeBackground,
  HomeBackgroundContainer,
} from './styles';

const Home = (props) => {
  return (
    <Base
      {...props}
      background={
        <HomeBackgroundContainer>
          <HomeBackground />
        </HomeBackgroundContainer>
      }
    />
  );
};

export default Home;
