import React from 'react';
import styled from 'styled-components';
import { 
  Typography,
  Box
} from '@mui/material';
import theme from '@matterapp/matter-theme';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const DEFAULTS = [
  {
    score: 1,
    flex: '20%',
    emptyColor: theme.colors.blacks[5],
    color: theme.colors.reds[60]
  },
  {
    score: 2,
    flex: '20%',
    emptyColor: theme.colors.blacks[10],
    color: theme.colors.reds[40]
  },
  {
    score: 3,
    flex: '20%',
    emptyColor: theme.colors.blacks[20],
    color: theme.colors.yellows[40]
  },
  {
    score: 4,
    flex: '20%',
    emptyColor: theme.colors.blacks[30],
    color: theme.colors.greens[40]
  },
  {
    score: 5,
    flex: '20%',
    emptyColor: theme.colors.blacks[40],
    color: theme.colors.greens[60]
  }
];

const Wrapper = styled(Box)({
  display: 'flex', 
  flexDirection: 'row',
  width: '360px'
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  [`.${tooltipClasses.popperArrow} .${tooltipClasses.tooltipArrow}`]: {
    padding: `0 ${theme.spacing(2)}`
  }
}));

const DistributionBox = styled(Box)(({ theme, color, flex }) => ({
  flexBasis: flex, 
  backgroundColor: color, 
  color: theme.palette.blacks[70],
  textAlign: 'center', 
  borderRadius: 0,
  height: '32px',
  ':first-child': {
    borderRadius: '4px 0 0 4px'
  },
  ':last-child': {
    borderRadius: '0 4px 4px 0'
  },
  ':only-child': {
    borderRadius: '4px'
  }
}));

function defaultDistribution() {
  return (
    <Wrapper>
      {DEFAULTS.map(({ flex, emptyColor, score }) => {  
        return (
          <StyledTooltip 
            title='No data' 
            arrow 
            key={score} 
            placement='top'
          >
            <DistributionBox flex={flex} color={emptyColor}>—</DistributionBox>
          </StyledTooltip>
        );
      })}
    </Wrapper>
  );
}

export default function ScoreDistribution({ distribution }) {
  if (!distribution || !distribution.length) {
    return defaultDistribution();
  }
  
  const total = distribution.reduce((aggr, curr) => {
    return aggr + curr.count;
  }, 0);

  const scoreToTitle = {
    5: 'Strongly Agree',
    4: 'Agree',
    3: 'Neither Agree nor Disagree',
    2: 'Disagree',
    1: 'Strongly Disagree'
  };

  const mapped = distribution.map(({ count, score }) => {
    const defaultItem = DEFAULTS.find(item => item.score === score);

    return {
      ...defaultItem,
      flex: `${Math.round((count / total) * 100)}%`,
      title: scoreToTitle[score]
    };
  });

  return (
    <Wrapper>
      {mapped.map(({ flex, color, score, title }) => (
        <StyledTooltip 
          title={title}
          arrow 
          key={score} 
          placement='top'
        >
          <DistributionBox key={score} color={color} flex={flex}>
            <Typography variant='body2' sx={{ fontWeight: 'bold', lineHeight: '32px' }}>{flex}</Typography>
          </DistributionBox>
        </StyledTooltip>
      ))}
    </Wrapper>
  );
}