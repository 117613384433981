import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Button from '../Button';

const headerButtonStyles = () => `
  line-height: 24px;
  margin: 0 20px;
  font-size: 14px;
`;

const BasicButton = styled(Button).attrs({
  size: 'S',
})`
  ${(props) => headerButtonStyles(props)}

  border-style: solid;
  border-width: 1px;
  border-color: ${() => theme.colors.blues[10]};

  &:hover {
    box-shadow: none;
  }

  &:active {
    color: white;
    border-color: ${() => theme.colors.blue};
    background-color: ${() => theme.colors.blue};
    box-shadow: none;
  }

  ${() => theme.media.medium`
    &:hover {
      border-color: ${theme.colors.blue};
      box-shadow: none;
    }
  `}
`;

const PrimaryButton = styled(Button.Primary.Vivid).attrs({
  color: Button.Simple.buttonColors.PURPLE,
})`
  ${headerButtonStyles}
`;

export default {
  Basic: BasicButton,
  Primary: PrimaryButton,
};
