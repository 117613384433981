import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

/**
 * Component for setting the document head of the page. This uses react-helmet.
 * See https://github.com/nfl/react-helmet for additional props.
 * Mainly used for setting page title.
 */

const DocumentHead = ({ children, title, ...helmetProps }) => (
  <Helmet title={title} {...helmetProps}>
    {children}
  </Helmet>
);

DocumentHead.propTypes = {
  /** Additional children props to set in document head. */
  children: PropTypes.node,
  /** Sets the title of the page. */
  title: PropTypes.string,
};

export default DocumentHead;
