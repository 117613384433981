import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M11.3123 3.60957C11.1623 3.4895 10.9566 3.46609 10.7834 3.54935C10.6102 3.63261 10.5 3.8078 10.5 4V8.5H5C4.72386 8.5 4.5 8.72386 4.5 9V15C4.5 15.2761 4.72386 15.5 5 15.5H10.5V20C10.5 20.1922 10.6102 20.3674 10.7834 20.4507C10.9566 20.5339 11.1623 20.5105 11.3123 20.3904L21.3123 12.3904C21.431 12.2956 21.5 12.1519 21.5 12C21.5 11.8481 21.431 11.7045 21.3123 11.6096L11.3123 3.60957Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
