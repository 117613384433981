import omitStyled from '../../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

const Container = omitStyled('div', [
  'centered',
  'highlight',
  'extraPadBottom',
  'smallPadBottom',
  'smallVerticalPadding',
])`
  width: 100%;
  padding: 16px 24px;
  ${({ centered }) => (
    centered && `
      text-align: center;
      margin: 0 auto;
    `
  )}

  ${({ highlight }) => (
    highlight && `background-color: ${theme.colors.blues[5]};`
  )}

  ${({ smallPadBottom }) => (
    smallPadBottom && 'padding-bottom: 24px;'
  )}

  ${({ extraPadBottom }) => (
    extraPadBottom && 'padding-bottom: 32px;'
  )}

  ${({ smallVerticalPadding }) => (
    smallVerticalPadding && `
      padding-top: 12px;
      padding-bottom: 12px;
    `
  )}
`;

export default Container;
