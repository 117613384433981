import React from 'react';

const Icon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#FFF5DA" />
    <g clipPath="url(#clip0_265_6909)">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9473 14C17.395 14 16.9473 14.4477 16.9473 15V17H15.4473C13.5143 17 11.9473 18.567 11.9473 20.5C11.9473 22.433 13.5143 24 15.4473 24H17.4069C18.2989 26.3321 20.4023 28.0651 22.9473 28.4291V30C21.2904 30 19.9473 31.3431 19.9473 33C19.9473 33.5523 20.395 34 20.9473 34H26.9473C27.4995 34 27.9473 33.5523 27.9473 33C27.9473 31.3431 26.6041 30 24.9473 30V28.4291C27.4923 28.0651 29.5957 26.3321 30.4876 24H32.4473C34.3802 24 35.9473 22.433 35.9473 20.5C35.9473 18.567 34.3802 17 32.4473 17H30.9473V15C30.9473 14.4477 30.4995 14 29.9473 14H17.9473ZM16.9473 19H15.4473C14.6188 19 13.9473 19.6716 13.9473 20.5C13.9473 21.3284 14.6188 22 15.4473 22H16.9648C16.9532 21.8349 16.9473 21.6681 16.9473 21.5V19ZM30.9297 22C30.9413 21.8349 30.9473 21.6681 30.9473 21.5V19H32.4473C33.2757 19 33.9473 19.6716 33.9473 20.5C33.9473 21.3284 33.2757 22 32.4473 22H30.9297Z" fill="#F2A645" />
    </g>
    <defs>
      <clipPath id="clip0_265_6909">
        <rect width="23.6842" height="24" fill="white" transform="translate(11.9473 12)" />
      </clipPath>
    </defs>
  </svg>


);

export default Icon;
