import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'mobile-device-detect';
import Rocket from '../../Animations/ProgressRocket';
import { IconNew as Icon } from '../../Icon';
import Pills from '../Pills';
import * as R from './rocketComponents';

const NUMBER_OF_STEPS = 6;
const DEFAULT_SUBHEADERS = {
  0: 'Fuel your future by sending six feedback requests',
  1: 'The start of many. Keep it up!',
  2: 'Two down, four to go.',
  3: 'Half way there. Keep at it!',
  4: 'So close! Two more to go.',
  5: (
    <R.FifthStepHeader>
      You’re almost there. Just one request away!
    </R.FifthStepHeader>
  ),
  6: 'Blast off! You did it!',
};

export default class ProgressRocket extends React.PureComponent {
  static propTypes = {
    /** The current step of the progress (out of 6). */
    currentStep: PropTypes.oneOf(Rocket.steps).isRequired,
    /** Header text to display. */
    header: PropTypes.string,
    /** HIdes the progress count and only displays the header. */
    hideProgressCount: PropTypes.bool,
    /** If the progress module is closed. */
    isClosed: PropTypes.bool,
    /** If container is initially expanded. */
    isInitiallyExpanded: PropTypes.bool,
    /** Callback when close button(s) are clicked. */
    onClickClose: PropTypes.func,
    /** Callback when animation completes playing. */
    onCompleteAnimation: PropTypes.func,
    /** Plays animation on mount. */
    playAnimationOnMount: PropTypes.bool,
    /** The subheader text, */
    subheader: PropTypes.node,
    /** Display the close button. */
    showCloseButton: PropTypes.bool,
  };

  static steps = Rocket.steps;

  static defaultProps = {
    canToggleOnDesktop: false,
    header: 'Your Progress',
    hideProgressCount: false,
    isInitiallyExpanded: false,
    showCloseButton: false,
    playAnimationOnMount: true,
  };

  constructor(props) {
    super(props);
    this.rocketRef = React.createRef();
    this.state = {
      isExpanded: props.isInitiallyExpanded,
      isAnimationPlaying: props.playAnimationOnMount,
    };
  }

  /**
   * Setting _mounted flag due to the async nature of animation to prevent setting
   * state when animation is complete and component is unmounted.
   */
  componentDidMount = () => {
    this._mounted = true;
  };

  /**
   * Clearing the _mounted flag.
   */
  componentWillUnmount = () => {
    this._mounted = false;
  };

  componentDidUpdate({ currentStep }) {
    const { isExpanded } = this.state;
    const canPlayAnimation = (isExpanded && isMobile) || !isMobile;
    if (currentStep !== this.props.currentStep && canPlayAnimation) {
      this.handleStartAnimationPlaying();
      this.rocketRef.current.playAnimation();
    }
  }

  /**
   * Callback when animation is finished playing.
   * @returns { void }
   */
  handleFinishAnimationPlaying = () => {
    if (this._mounted) {
      this.setState(
        { isAnimationPlaying: false },
        this.props.onCompleteAnimation
      );
    }
  };

  /**
   * Callback when animation starts playing.
   * @returns { void }
   */
  handleStartAnimationPlaying = () => {
    if (this._mounted) {
      this.setState({ isAnimationPlaying: true });
    }
  };

  /**
   * Callback when animation is clicked to play.
   * @returns { void }
   */
  handleClick = () => {
    if (!this.state.isAnimationPlaying) {
      this.handleStartAnimationPlaying();
      this.rocketRef.current.playAnimation({ playFromBeginning: true });
    }
  };

  /**
   * Toggles the expanded state.
   * @returns { void }
   */
  handleToggleExpand = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  getProgressCount = () => {
    const { currentStep, hideProgressCount } = this.props;
    if (hideProgressCount) {
      return null;
    }
    return `${currentStep} / ${NUMBER_OF_STEPS}`;
  };

  renderHeader = () => {
    const {
      canToggleOnDesktop,
      currentStep,
      header,
      onClickClose,
      showCloseButton,
      subheader,
    } = this.props;
    const { isExpanded } = this.state;
    const containerProps = { canToggleOnDesktop, isExpanded, showCloseButton };
    const progressCount = this.getProgressCount();
    const proogressSeparator = progressCount ? ': ' : '';
    return (
      <>
        <R.ExpandToggle {...containerProps} onClick={this.handleToggleExpand}>
          <Icon name="karotUp" />
        </R.ExpandToggle>
        <R.CloseButtonTop {...containerProps} onClick={onClickClose}>
          <Icon name="close" />
        </R.CloseButtonTop>
        <R.HeaderTextContainer>
          {header}
          {proogressSeparator}
          {progressCount}
        </R.HeaderTextContainer>
        <R.SubHeaderTextContainer {...containerProps}>
          {subheader || DEFAULT_SUBHEADERS[currentStep]}
        </R.SubHeaderTextContainer>
      </>
    );
  };

  render() {
    const {
      canToggleOnDesktop,
      currentStep,
      isClosed,
      onClickClose,
      playAnimationOnMount,
      showCloseButton,
    } = this.props;
    const { isAnimationPlaying, isExpanded } = this.state;
    const containerProps = {
      canToggleOnDesktop,
      currentStep,
      isExpanded,
      showCloseButton,
    };
    return (
      <R.Container isClosed={isClosed}>
        <R.ProgressContainer>
          <R.HeaderContainer {...containerProps}>
            {this.renderHeader()}
            <R.PillsContainer {...containerProps}>
              <Pills currentStep={currentStep} totalSteps={NUMBER_OF_STEPS} />
            </R.PillsContainer>
          </R.HeaderContainer>
          <R.RocketContainer {...containerProps}>
            <Rocket
              step={currentStep}
              ref={this.rocketRef}
              onClick={this.handleClick}
              onComplete={this.handleFinishAnimationPlaying}
              playAnimationOnMount={playAnimationOnMount}
            />
            <R.CloseButtonContainer
              {...containerProps}
              isAnimationPlaying={isAnimationPlaying}
            >
              <R.CloseButton {...containerProps} onClick={onClickClose}>
                Close
              </R.CloseButton>
            </R.CloseButtonContainer>
          </R.RocketContainer>
        </R.ProgressContainer>
      </R.Container>
    );
  }
}
