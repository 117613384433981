// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import Droppable from './Droppable';
import Draggable from './Draggable';
import { handleMove, handleReorder } from './utils';

/**
 * Simple wrapper for [react-beautiful-dnd](https://github.com/atlassian/react-beautiful-dnd). Provides
 * shortcuts to apply provider and snapshot to droppable and draggable items. Simplifies the styles to
 * use classes instead.
 */
export default class DragAndDropList extends React.Component {
  static Droppable = Droppable;
  static Draggable = Draggable;

  static propTypes = {
    /** Must be ```<DragAndDropList.Droppable>``` */
    children: PropTypes.node,
    /**
     * Function when moving an item from one list to another list.
     *
     * ```
     * onMove({ 
     *   source: {
     *     index,
     *     droppableId
     *   },
     *   destination: {
     *     index,
     *     droppableId
     *   }
     * })
     * ```
     * @param { Object } changeProps: {
     *   source: {
     *     @param { String } index: The index of the item being moved.
     *     @param { String } droppableId: The id of the list to move the item from.
     *   },
     *   destination: {
     *     @param { String } index: The new index of the item to move to.
     *     @param { String } droppableId: The id of the list to move the item to.
     *   }
     * }
     * @returns { void }
     */
    onMove: PropTypes.func,
    /**
     * Function when reordering items of one list.
     *
     * ```
     * onReorder({ 
     *   source: {
     *     index,
     *     droppableId
     *   },
     *   destination: {
     *     index
     *   }
     * })
     * ```
     * @param { Object } changeProps: {
     *   source: {
     *     @param { String } index: The index of the item being moved.
     *     @param { String } droppableId: The id of the list to move the item from.
     *   },
     *   destination: {
     *     @param { String } index: The new index of the item to move to.
     *   }
     * }
     * @returns { void }
     */
    onReorder: PropTypes.func,
  };

  static defaultProps = {
    onMove: () => {},
    onReorder: () => {},
  };

  static handleMove = handleMove;

  static handleReorder = handleReorder;

  /**
   * Callback function on drag end. Determines if move or reorder has occurred.
   * @param { Object } result: The new state and ordering of items.
   * @returns { void }
   */
  handleDragEnd = (result = {}) => {
    const { onMove, onReorder } = this.props;
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      onReorder({ source, destination });
    } else {
      onMove({ source, destination });
    }
  };

  render() {
    const { children } = this.props;
    return <DragDropContext onDragEnd={this.handleDragEnd}>{children}</DragDropContext>;
  }
}
