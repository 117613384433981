import React from 'react';
import PropTypes from 'prop-types';
import WithActionButtons from './WithActionButtons';

const WithCancelDelete = (props) => {
  const {
    canClickCancel,
    canClickDelete,
    className,
    onClickCancel,
    onClickDelete,
    deleteIsDestructive,
    deleteLabel,
    deleteProps,
    cancelLabel,
    cancelProps,
    useVividDeleteButton,
    ...otherProps
  } = props;

  return (
    <WithActionButtons
      {...otherProps}
      className={className}
      canClickPrimary={canClickDelete}
      canClickSecondary={canClickCancel}
      onClickPrimary={onClickDelete}
      onClickSecondary={onClickCancel}
      primaryIsDestructive={deleteIsDestructive}
      primaryLabel={deleteLabel}
      primaryProps={deleteProps}
      secondaryLabel={cancelLabel}
      secondaryProps={cancelProps}
      useVividPrimaryButton={useVividDeleteButton}
    />
  );
};

WithCancelDelete.defaultProps = {
  canClickCancel: true,
  canClickDelete: true,
  deleteIsDestructive: true,
  deleteLabel: 'Delete',
  cancelLabel: 'Cancel',
  useVividDeleteButton: true,
};

WithCancelDelete.propTypes = {
  /** If cancel button can be clicked. */
  canClickCancel: PropTypes.bool,
  /** If delete button can be clicked. */
  canClickDelete: PropTypes.bool,
  /** Additional class. */
  className: PropTypes.string,
  /** Callback when delete button is clicked. */
  onClickDelete: PropTypes.func,
  /** Callback when cancel button is clicked. */
  onClickCancel: PropTypes.func,
  /** If save is destructive action. */
  deleteIsDestructive: PropTypes.bool,
  /** Delete button label. */
  deleteLabel: PropTypes.node,
  /** Additional props for delete button. */
  deleteProps: PropTypes.object,
  /** Cancel button label. */
  cancelLabel: PropTypes.node,
  /** Additional props for cancel button. */
  cancelProps: PropTypes.object,
  /** Use vivid delete button. */
  useVividDeleteButton: PropTypes.bool,
};

export default WithCancelDelete;
