import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from './ButtonNew';
import theme from '@matterapp/matter-theme';

const MobileButton = styled(Button)`
  ${() => theme.media.medium`
    display: none;
  `};
`;

const DesktopButton = styled(Button)`
  display: none;
  ${() => theme.media.medium`
    display: inline;
  `};
`;

const ResponsiveButton = ({
  onMobileClick,
  onDesktopClick,
  children,
  ...rest
}) => (
  <React.Fragment>
    <MobileButton
      onClick={onMobileClick}
      {...rest}
    >
      {children}
    </MobileButton>
    <DesktopButton
      onClick={onDesktopClick}
      {...rest}
    >
      {children}
    </DesktopButton>
  </React.Fragment>
);

ResponsiveButton.propTypes = {
  onMobileClick: PropTypes.func.isRequired,
  onDesktopClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ResponsiveButton;
