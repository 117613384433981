import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';

const Grid = forwardRef((props, ref) => {
  const { children, className, columns, isResponsive, responsiveColumns, spacing } = props;
  return (
    <Styles.Grid
      className={className}
      columns={columns}
      isResponsive={isResponsive}
      responsiveColumns={responsiveColumns}
      ref={ref}
      spacing={spacing}
    >
      {children}
    </Styles.Grid>
  );
});

Grid.propTypes = {
  /** Children components with each child taking up 1 column of the grid from top to bottom and left to right. */
  children: PropTypes.node,
  /** Additional style class */
  className: PropTypes.string,
  /**
   *  The number of columns for the grid.
   * 
   *  For custom column width, you can pass in array of column grid widths. Does not support `isResponsive` prop.
   *  eg. [1, 1, 1, 1] for 4 equal columns.
   *  eg. [1, 2, 1] for 3 columns with the 2 column twice as wide.
   */
  columns: PropTypes.oneOfType([PropTypes.oneOf(Styles.columnSizes), PropTypes.arrayOf(PropTypes.number)]),
  /** If grid is responsive or keeps the same amount of columns on all screen sizes. */
  isResponsive: PropTypes.bool,
  /**
   * Custom resposive columns of the same width.
   * Pass in array of the column count for each screen breakpoint.
   * [base, XS, S, M, L]. 
   */
  responsiveColumns: PropTypes.arrayOf(PropTypes.oneOf(Styles.columnSizes)),
  /** Spacing between items in the grid. Number or string value in pixels. */
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Grid;
