import React, { useState, useRef, useEffect } from 'react';
import { CloseToOpenWrapper, CloseToOpenBody } from './styles';
import Customization from './Customization';

const CloseToOpen = (props) => {
  const { isOpen } = props;
  const [contentHeight, setContentHeight] = useState(0);
  const bodyRef = useRef();

  const setHeight = () => {
    if (bodyRef.current && bodyRef.current.offsetHeight) {
      setContentHeight(bodyRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setHeight);
    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setHeight();
      }, 300);
    }
  }, [isOpen]);

  return (
    <CloseToOpenWrapper open={isOpen} height={contentHeight}>
      <CloseToOpenBody ref={bodyRef}>
        <Customization {...props}/>
      </CloseToOpenBody>
    </CloseToOpenWrapper>
  );
};

export default CloseToOpen;
