import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import {
  FooterContainer,
  TwoColumnContainer,
  TwoColumnContentContainer,
  TwoColumnContentContainerWrapper,
  TwoColumnFilterContainer,
  TwoColumnFilterWrapper,
  TwoColumnChildrenContainer,
} from './styles';

const TwoColumnFixedLeft = ({
  children,
  className,
  containerNavigation,
  dockLeftColumn,
  header,
  footer,
  leftColumn,
  hideLeftColumnOnMobile,
}) => (
  <Base className={className} header={header}>
    <TwoColumnContainer>
      <TwoColumnContentContainer>
        <TwoColumnContentContainerWrapper>
          <TwoColumnFilterContainer hideFilterColumn={hideLeftColumnOnMobile}>
            <TwoColumnFilterWrapper dock={dockLeftColumn}>
              {containerNavigation}
              {leftColumn}
            </TwoColumnFilterWrapper>
          </TwoColumnFilterContainer>
          <TwoColumnChildrenContainer>{children}</TwoColumnChildrenContainer>
        </TwoColumnContentContainerWrapper>
        <FooterContainer>{footer}</FooterContainer>
      </TwoColumnContentContainer>
    </TwoColumnContainer>
  </Base>
);

TwoColumnFixedLeft.defaultProps = {
  dockLeftColumn: true,
};

TwoColumnFixedLeft.propTypes = {
  /** Additional style classes. */
  className: PropTypes.string,
  /** Main Column Contents. */
  children: PropTypes.node,
  /** (Alt) Left Column Content. */
  containerNavigation: PropTypes.node,
  /** Docks the left column when scrolling. */
  dockLeftColumn: PropTypes.bool,
  /** Footer Content. */
  footer: PropTypes.node,
  /** Header Content. */
  header: PropTypes.node,
  /** Left Column Content. */
  leftColumn: PropTypes.node,
  /** Hides the left column on mobile. */
  hideLeftColumnOnMobile: PropTypes.bool,
};

export default TwoColumnFixedLeft;
