import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListItem from '../ListItem';

const Container = styled.div``;

export default class PeersList extends React.Component {
  static propTypes = {
    /** Label of the default accept button if `renderActions` prop func is not passed. */
    acceptButtonLabel: PropTypes.string,
    /** Content to show when peer is accepted. */
    acceptedText: PropTypes.node,
    /** When the user has no avatar image, generate default avatar of use stock avatar image (leaf). */
    defaultAvatarIsGenerated: PropTypes.bool,
    /** Content to show when peer is dismissed. */
    dismissedText: PropTypes.node,
    /** Additional style class. */
    className: PropTypes.string,
    /** If list is narrow in size. */
    isNarrow: PropTypes.bool,
    /** Max number of peers to show in list. Setting to 0 shows all available peers. */
    numberOfPeersShown: PropTypes.number,
    /**
     * Callback when an accept button in a peer list item is clicked. Not required if no accept buttons are rendered.
     * @param { Object } e: The click event.
     * @param { Object } peerItemProps: The props of the item clicked including the peer.
     * @returns { Promise } Resolved promise when accepting a peer.
     */
    onClickAccept: PropTypes.func,
    /**
     * Callback when a dismiss button in a peer list item is clicked. Not required if no dismiss buttons are rendered.
     * @param { Object } e: The click event.
     * @param { Object } peerItemProps: The props of the item clicked including the peer.
     * @returns { Promise } Resolved promise when dismissing a peer.
     */
    onClickDismiss: PropTypes.func,
    /**
     * Callback when a peer is removed from the list (Accepted or dismissed).
     * @param { Object } peerItemProps: The props of the item removed.
     * @returns { void }
     */
    onRemovePeer: PropTypes.func,
    /**
     * List of peers to show in the list.
     */
    peers: PropTypes.arrayOf(
      PropTypes.shape({
        /** The email of the peer. */
        email: PropTypes.string.isRequired,
        /** The full name of the peer. */
        fullName: PropTypes.string,
        /** The url of the photo of the peer. */
        photoUrl: PropTypes.string,
        /** The source of the peer suggestion. */
        source: PropTypes.string,
      })
    ),
    /**
     * Callback to render custom actions of the item.
     * If not passed in, a default Accept and Dismiss button are rendered.
     * @param { Object } callbackProps: {
     *   @param { String } acceptButtonLabel: The accept button label passed from props.
     *   @param { Function } handleClickAccept: When an accept type button is clicked.
     *   @param { Function } handleClickDismiss: When a dismiss type button is clicked.
     * }
     * @returns { React.Component } Rendered actions to show in the item.
     */
    renderActions: PropTypes.func,
    /**
     * Function to render the profile card of a list item.
     */
    renderProfileCard: PropTypes.func,
    /** Show the dismiss button on every peer suggestion item. */
    showDismissButton: PropTypes.bool,
  };

  static defaultProps = {
    numberOfPeersShown: 10,
    showDismissButton: true,
  };

  constructor(props) {
    super(props);
    const { peers, numberOfPeersShown } = props;
    this.state = {
      currentPeerIndex: numberOfPeersShown || peers.length - 1,
      peers,
    };
  }

  getReplacementPeer = () => {
    const { currentPeerIndex, peers } = this.state;
    const replacementPeer = peers[currentPeerIndex];
    this.setState({ currentPeerIndex: currentPeerIndex + 1 });
    return replacementPeer;
  };

  render = () => {
    const {
      acceptButtonLabel,
      acceptedText,
      className,
      defaultAvatarIsGenerated,
      dismissedText,
      isNarrow,
      numberOfPeersShown,
      onClickAccept,
      onClickDismiss,
      onRemovePeer,
      renderActions,
      renderProfileCard,
      showDismissButton,
      waitToReplace,
    } = this.props;
    const { peers } = this.state;
    const peersToShow = numberOfPeersShown
      ? peers.slice(0, numberOfPeersShown)
      : peers;
    const listItems = peersToShow.map((peer, index) => {
      return (
        <ListItem
          waitToReplace={waitToReplace}
          acceptButtonLabel={acceptButtonLabel}
          acceptedText={acceptedText}
          defaultAvatarIsGenerated={defaultAvatarIsGenerated}
          dismissedText={dismissedText}
          key={`${index}-peer`}
          getReplacementPeer={this.getReplacementPeer}
          isNarrow={isNarrow}
          onClickAccept={onClickAccept}
          onClickDismiss={onClickDismiss}
          onRemovePeer={onRemovePeer}
          peer={peer}
          renderActions={renderActions}
          renderProfileCard={renderProfileCard}
          showDismissButton={showDismissButton}
        />
      );
    });
    return <Container className={className}>{listItems}</Container>;
  };
}
