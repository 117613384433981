import styled from 'styled-components';
import { rgba } from 'polished';
import ButtonBase from '../../Base';
import { buttonStyles } from './props';
import theme from '@matterapp/matter-theme';

const PrimaryButton = styled(ButtonBase)`
  ${buttonStyles}
  color: ${({ isLoading }) => isLoading ? rgba(theme.colors.white, 0.25) : theme.colors.white};
  background: ${theme.colors.purples[50]};

  &:hover {
    background: ${theme.colors.purples[40]};
    box-shadow: 0px 4px 8px rgba(52, 55, 153, 0.32) !important;
  }

  &:active {
    background: ${theme.colors.purples[50]};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
`;

export default PrimaryButton;
