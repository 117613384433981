import React from 'react';
import { SVG, Path } from './consts';

const Survey = (props) => (
  <SVG {...props} viewBox="0 0 15 18">
    <Path
      d="M8.775 1.35H5.625C5.25221 1.35 4.95 1.65221 4.95 2.025C4.95 2.39779 5.25221 2.7 5.625 2.7H8.775C9.14779 2.7 9.45 2.39779 9.45 2.025C9.45 1.65221 9.14779 1.35 8.775 1.35ZM8.775 0C9.80018 0 10.6474 0.761815 10.7815 1.75022L10.7937 1.872L10.7876 1.7995L12.375 1.8C13.4934 1.8 14.4 2.70662 14.4 3.825V15.975C14.4 17.0934 13.4934 18 12.375 18H2.025C0.906623 18 0 17.0934 0 15.975V3.825C0 2.70662 0.906623 1.8 2.025 1.8L3.61241 1.7995L3.6054 1.872L3.61849 1.75022C3.75257 0.761815 4.59982 0 5.625 0H8.775ZM9 13.5H3.6C3.22721 13.5 2.925 13.8022 2.925 14.175C2.925 14.5478 3.22721 14.85 3.6 14.85H9C9.37279 14.85 9.675 14.5478 9.675 14.175C9.675 13.8022 9.37279 13.5 9 13.5ZM7.2 9.9H3.6C3.22721 9.9 2.925 10.2022 2.925 10.575C2.925 10.9478 3.22721 11.25 3.6 11.25H7.2C7.57279 11.25 7.875 10.9478 7.875 10.575C7.875 10.2022 7.57279 9.9 7.2 9.9ZM10.8 6.3H3.6C3.22721 6.3 2.925 6.60221 2.925 6.975C2.925 7.34779 3.22721 7.65 3.6 7.65H10.8C11.1728 7.65 11.475 7.34779 11.475 6.975C11.475 6.60221 11.1728 6.3 10.8 6.3Z"
    />
  </SVG>
);

export default Survey;
