import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import withRouter from 'hocs/withRouter';
import { CANT_RATE_SELF } from '@matterapp/matter-errors';
import { withBeginFeedbackMutation } from '../hocs/withResponseFlowTimeline';
import { Resources } from '@matterapp/routing';
import Toast from 'components/Toast/Toast';

const withData = compose(withRouter, withBeginFeedbackMutation);

const ResponseFlowBeginFeedbackContainer = withData((props) => {
  const {
    history,
    nextStepPath,
    cantRateSelfPath,
    mutations: {
      beginFeedback
    }
  } = props;
  // Recursive retry for this API call, needed because Basic auth box
  // on Staging env causes this API call initially to fail
  let retries = 0;
  const handleErr = function (err) {
    console.error(err);
    Toast.error('Something went wrong with beginning feedback session');
    history.push(Resources.homeMain.path());
  };
  const tryBeginFeedback = function () {
    beginFeedback().then(() => {
      history.push(nextStepPath);
    }).catch((err) => {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        const errorName = err.graphQLErrors[0].name;
        if (errorName === CANT_RATE_SELF) {
          return history.push(cantRateSelfPath);
        }
      }
      if (retries > 5) {
        return handleErr(err);
      }
      else {
        retries++;
        window.setTimeout(tryBeginFeedback, 2000);
      }
    });
  };
  tryBeginFeedback();
  return <div />;
});

ResponseFlowBeginFeedbackContainer.propTypes = {
  nextStepPath: PropTypes.string.isRequired,
  cantRateSelfPath: PropTypes.string.isRequired,
};

export default ResponseFlowBeginFeedbackContainer;
