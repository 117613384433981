import styled from 'styled-components';
import ButtonBase, {
  buttonColors,
  buttonSizes,
  buttonTypes,
  LOADER_DATA_ROLE,
  LOADER_DATA_ROLE_SELECTOR,
} from '../Base';
import omitStyled from '../../../libs/omitStyled';
import { BUTTON_GROUP_ROLE } from '../Group';
import theme, { createDataRole } from '@matterapp/matter-theme';

/** Button Styles */
const getColorStyles = ({ color, disabled, isPrimary }) => {
  if (disabled) {
    return '';
  }
  const baseColor = theme.colors[`${color}s`] || theme.colors.blues;
  const defaultActiveColor = theme.colors.purples;
  const isDefaultColor = !color;

  const transitionTime = theme.transitions.times.default;
  const transitionStyles = `
    transition: background ${transitionTime}, background-size ${transitionTime} ease-in-out, border ${transitionTime}, color ${transitionTime};
  `;

  const defaultStyles = isDefaultColor
    ? `
    background: ${theme.colors.white};
  `
    : ``;

  const hoverStyles = isDefaultColor
    ? `
    border-color: ${baseColor[isPrimary ? 20 : 40]};
  `
    : `
    background: ${baseColor[isPrimary ? 10 : 5]};
    border-color: ${baseColor[isPrimary ? 10 : 5]};
  `;

  const activeStyles = isDefaultColor
    ? `
    color: ${defaultActiveColor[60]};
    border-color: ${defaultActiveColor[60]};
  `
    : `
    background: ${baseColor[isPrimary ? 20 : 10]};
    border-color: ${baseColor[isPrimary ? 20 : 40]};
  `;

  return `
    ${defaultStyles}
    &:hover {
      ${hoverStyles}
      cursor: pointer;
    }
    &:active {
      ${activeStyles}
    }
    ${transitionStyles};
  `;
};

const getGroupStyles = () => `
  ${createDataRole(BUTTON_GROUP_ROLE)} > & {
    &:not(:last-child) {
      margin-right: -1px;
    }
  }
`;

const StyledButton = omitStyled(ButtonBase, [
  'description',
  'iconName',
  'isPrimary',
  'isPill',
  'title',
])`
  z-index: 1;
  text-align: center;
  justify-content: center;
  ${getColorStyles}
  ${getGroupStyles}
`;

export const DescriptiveContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div`
  ${IconContainer} + & {
    margin: 0 0 0 ${theme.spacing.single}
  }
`;

export const TitleContainer = styled.div`
  color: white;
  font-weight: bold;
  font-size: ${theme.fontSize.S};
`;

export const DescriptionContainer = styled.div`
  padding: 0 ${theme.spacing.quarter};
  ${({ theme: { content } }) => {
    return `
      color: ${content.description.color};
      font-size: ${content.description.fontSize};
      line-height: ${content.description.lineHeight};
    `;
  }}
  ${() => theme.media.large`
    padding: 0;
  `};
`;

export {
  buttonColors,
  buttonSizes,
  buttonTypes,
  LOADER_DATA_ROLE,
  LOADER_DATA_ROLE_SELECTOR,
};
export default StyledButton;
