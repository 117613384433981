import React from 'react';
import PropTypes from 'prop-types';
import {
  Panel
} from '@matterapp/matter-ui';
import SkillChartUI from 'components/SkillChart/SkillChart';
import { transform, mean } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import withRouter from 'hocs/withRouter';
import { Resources } from '@matterapp/routing';

function formatDate(dateStr) {
  return moment(dateStr).format("YYYY-MM-DD");
}

const PanelBase = styled(Panel.Base)`
  z-index: 10;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  h2 {
    color: ${({theme}) => theme.colors.purples[50]};
    font-size: 17px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 0;
  }

  span {
    color: ${({theme}) => theme.colors.blacks[50]};
  }

  div:first-of-type {
    ${({theme}) => theme.media.medium`
      width: unset;
      flex-grow: 1;
    `}
  }

  div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .no-mobile {
    display: none;
    ${({theme}) => theme.media.medium`
      display: inline;
    `}
  }
`;

const ChartWrapper = styled.div`
  padding: 0;
  ${({theme}) => theme.media.medium`
    padding-left: 24px;
    padding-right: 24px;
  `}
`;

class SkillChart extends React.PureComponent {

  static propTypes = {
    pickedSkill: PropTypes.object,
    skill: PropTypes.object,
    feedItems: PropTypes.array,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    history: PropTypes.object.isRequired,
  }

  goToFeedbackView(feedItem) {
    const { payload } = feedItem;
    const viewFeedbackLink = Resources.feedbackReceivedViewFeedback.path({
      feedbackReceivedId: payload.feedbackId
    });
    return this.props.history.push(viewFeedbackLink);
  }

  formatFeedbackToDataPoints = () => {
    const { feedItems, skill } = this.props;
    console.log('formatFeedbackToDataPoints', feedItems);
    const sortedFeedItems = feedItems.sort((a, b) => {
      return moment(a.eventTime).diff(moment(b.eventTime));
    });
    
    const startDate = formatDate(this.props.startDate);
    const endDate = formatDate(this.props.endDate);

    const ratings = sortedFeedItems.map(feedItem => ({
      user: feedItem.payload.giver,
      onClick: () => this.goToFeedbackView(feedItem),
      x: moment(feedItem.eventTime).format("YYYY-MM-DD"), // date
      y: feedItem.payload.feedback.skillRatings.filter((rating) => rating.skill.id === skill.id)[0].rating // skillRating
    }));



    // Creates an object in format:
    // date: [ratings]
    const ratingAccumulator = [];
    const groupedRatingsByDate = transform(ratings, function (result, rating, index) {
      ratingAccumulator.push(rating.y);
      result[rating.x] = [...ratingAccumulator];
    }, {});

    const cumulativeRating = Math.round(mean(ratingAccumulator)*100)/100;

    const averages = transform(groupedRatingsByDate, function (result, ratings, date) {
      result.push({
        x: date,
        y: mean(ratings)
      });
    }, []);

    return {
      startDate,
      endDate,
      averages,
      ratings,
      cumulativeRating,
    };
  }

  render() {
    const { skill, feedItems, pickedSkill } = this.props;
    if (!feedItems.length) { return null; }
    const data = this.formatFeedbackToDataPoints();
    
    if (!data) { return null; }
    const { cumulativeRating, startDate } = data;

    const pluralizedRatings = pickedSkill.numFeedbacks > 1 ? "Ratings" : "Rating";


    return (
      <PanelBase>
        <Panel.Header.Basic>
          <HeaderContent>
            <div>
              <h2>Your current score</h2>
              <span>Since <span className="no-mobile">you joined Matter on </span>{moment(startDate).format('MMM D, YYYY')}</span>
            </div>
            <div>
              <h2>{Math.max(cumulativeRating / 2, 1)}</h2>
              <span>{pickedSkill.numFeedbacks} {pluralizedRatings}</span>
            </div>
          </HeaderContent>
        </Panel.Header.Basic>
        <ChartWrapper>
          <SkillChartUI
            skillName={skill.name}
            data={data}
            stepSize={1}
          />
        </ChartWrapper>
        <Panel.Footer.Empty />
      </PanelBase>
    );
  }
}

export default withRouter(SkillChart);
