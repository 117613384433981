import React from 'react';
import PropTypes from 'prop-types';
import {
  registerToPool,
  setPeerStatus,
  peerStatus,
  swapOutExpiredPeers,
} from './peer-suggestions-reducer';
import omitStyled from '../../libs/omitStyled';
import PeerSuggestionsListItemContainer from './PeerSuggestionsListItemContainer';

const PeerSuggestionsListRoot = omitStyled('div')`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  border-radius: 0 0 12px 12px;
  > :last-child {
    border-radius: 0 0 12px 12px;
    border: none;
  }
`;

const UPDATE_DELAY = 500;

class PeerSuggestionsList extends React.Component {
  constructor(props) {
    super(props);
    const { peerSuggestions } = props;
    this.state = {
      peersPool: registerToPool(undefined, peerSuggestions),
      numInvited: 0,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const registeredPool = registerToPool(this.state.peersPool, nextProps.peerSuggestions);
    const finalPool = registeredPool;
    this.setState({
      peersPool: finalPool,
    });
  }

  componentWillUnmount = () => {
    clearTimeout(this.peerAnimationTimeout);
  }

  getPeersToRender = () => {
    const {
      peersList,
    } = this.state.peersPool;
    const { numberSuggestionsToShow } = this.props;
    const pickedPeers = numberSuggestionsToShow !== null ?
      peersList.slice(0, numberSuggestionsToShow) :
      peersList.slice(0);
    return pickedPeers;
  }

  getHandlePickPeer = (peer) => async () => {
    const { handlePickPeer } = this.props;
    this.incrementNumInvited();
    await this.handlePeerUpdate({
      peer,
      firstStatus: peerStatus.isAccepting,
      secondStatus: peerStatus.isAccepted,
    });
    setTimeout(async () => {
      await handlePickPeer(peer);
    }, UPDATE_DELAY);
  }

  getHandleRejectPeer = (peer) => async () => {
    const { handleDismissSuggestion } = this.props;
    await this.handlePeerUpdate({
      peer,
      firstStatus: peerStatus.isRejecting,
      secondStatus: peerStatus.isRejected,
    });
    setTimeout(async () => {
      await handleDismissSuggestion(peer);
    }, UPDATE_DELAY);
  }

  incrementNumInvited = () => {
    this.setState({
      numInvited: this.state.numInvited + 1,
    });
  }

  handlePeerUpdate = ({
    peer,
    firstStatus,
    secondStatus,
  }) => {
    const rootPromise = new Promise((resolve) => {
      const setStateResult = this.setState({
        peersPool: setPeerStatus({
          pool: this.state.peersPool,
          peer,
          status: firstStatus,
        }),
      }, resolve);
      return setStateResult;
    }).then(() => {
      const timeoutPromise = new Promise((resolve) => {
        this.peerAnimationTimeout = setTimeout(resolve, 500);
      });
      return timeoutPromise;
    }).then(() => {
      const pendingPool = setPeerStatus({
        pool: this.state.peersPool,
        peer,
        status: secondStatus,
      });
      const swappedPool = swapOutExpiredPeers({
        pool: pendingPool,
        numberSuggestionsToShow: this.props.numberSuggestionsToShow,
      });
      return new Promise((resolve) => {
        this.setState({
          peersPool: swappedPool,
        }, resolve);
      });
    });
    return rootPromise;
  }

  render = () => {
    const peersToRender = this.getPeersToRender();
    const {
      renderPeerSuggestionsListItem,
      renderProfileCard,
    } = this.props;
    return (
      <PeerSuggestionsListRoot className="peer-suggestion-list">
        {peersToRender.map((peer) => {
          const peerMeta = this.state.peersPool.peerMetaTable[peer.email];
          return (
            <PeerSuggestionsListItemContainer
              renderProfileCard={renderProfileCard}
              key={peer.email}
              peerStatus={peerMeta.status}
              handlePickPeer={this.getHandlePickPeer(peer)}
              handleDismissSuggestion={this.getHandleRejectPeer(peer)}
              peer={peer}
              numPeersInvited={this.state.numInvited}
            >
              {renderPeerSuggestionsListItem}
            </PeerSuggestionsListItemContainer>
          );
        })}
      </PeerSuggestionsListRoot>
    );
  }
}

PeerSuggestionsList.defaultProps = {
  numberSuggestionsToShow: null,
};

PeerSuggestionsList.propTypes = {
  numberSuggestionsToShow: PropTypes.number,
  peerSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      photoUrl: PropTypes.string,
      fullName: PropTypes.string,
    })
  ).isRequired,
  handlePickPeer: PropTypes.func.isRequired,
  handleDismissSuggestion: PropTypes.func.isRequired,
  renderPeerSuggestionsListItem: PropTypes.func.isRequired,
  renderProfileCard: PropTypes.func.isRequired,
};

export default PeerSuggestionsList;
