import React from 'react';
import PropTypes from 'prop-types';
import { Skill, ModalNew as Modal } from '@matterapp/matter-ui';
import { ModalFooter, CancelButton, ModalHeader, ModalBody } from './PickAddMethodModeStyles';

class PickAddMethodMode extends React.PureComponent {

  renderModalFooter = () => {
    const { onClickClose } = this.props;
    return (
      <ModalFooter>
        <CancelButton
          fluid
          color="black"
          size="small"
          onClick={onClickClose}
        >
          Cancel
        </CancelButton>
      </ModalFooter>
    );
  }

  renderModalHeader = () => {
    const { onClickClose } = this.props;
    return (
      <ModalHeader
        right={<Modal.Header.Buttons.Close onClick={onClickClose} fill="#343799"/>}
        title="Add a Skill"
      />
    );
  };

  renderModalBody = () => {
    const { onClickCreateCustomSkill, onClickRecommendedSkills } = this.props;
    return (
      <ModalBody>
        <Skill.Generator.IconCTA.Container>
          <Skill.Generator.IconCTA.Button
            iconName="recommended"
            onClick={onClickRecommendedSkills}
            rcLabel="add-recommended-skill"
            title="Recommended Skills"
            description="Select from over 30 skills on Matter."
          />
          <Skill.Generator.IconCTA.Button
            iconName="custom"
            onClick={onClickCreateCustomSkill}
            rcLabel="add-custom-skill"
            title="Create a Custom Skill"
            description="Add your own personal skills and description to Matter."
          />
        </Skill.Generator.IconCTA.Container>
      </ModalBody>
    );
  };

  render = () => (
    <React.Fragment>
      {this.renderModalHeader()}
      {this.renderModalBody()}
      {this.renderModalFooter()}
    </React.Fragment>
  );
}

PickAddMethodMode.propTypes = {
  onClickCreateCustomSkill: PropTypes.func.isRequired,
  onClickRecommendedSkills: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default PickAddMethodMode;
