import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const colors = ['red', 'orange', 'yellow', 'green', 'blue', 'purple'];

const getBaseColor = (color) => {
  return theme.colors[`${color}s`] || theme.colors.oranges;
};

const evenOddStyle = {
  clipRule: 'evenodd',
  fillRule: 'evenodd',
};

export const Background = styled.path.attrs({
  d: `M352,0v468H0V0H352z`,
})`
  fill: ${({ color }) => getBaseColor(color)[5]};
`;

export const RocketShip = styled.g``;
export const RocketBody = styled.path.attrs({
  d: `M215.39973,404.45969l-15.00662-17.7157c0,0,0-13.51196-0.10004-13.71213
      c-0.70032-16.61472-19.90877-36.03189-24.31071-36.03189c-1.60072,0.1001-4.40193,2.10187-8.20361,5.90521
      c-7.50331,7.50665-15.80698,19.31708-16.30719,29.92648c-0.10005,0.1001-0.10005,13.71213-0.10005,13.71213l-14.90654,17.51553
      c-0.30014,0.30026-0.60028,0.90079-0.40018,1.30115l0.10005,29.92645c0,0.90082,0.40018,1.60144,1.30058,1.9017
      c0.60027,0.30023,1.60071,0.10007,2.20096-0.40036l11.90524-11.31v9.70862c0,0.60052,0.10005,1.00089,0.60027,1.50131
      c0.40018,0.40036,0.90039,0.60052,1.50067,0.60052l4.3019,0.1001l4.3019,11.31c0.30014,0.90079,1.00044,1.30115,1.90083,1.30115
      h23.91052c0.60027,0,1.00044-0.1001,1.50066-0.60052c0.10005-0.1001,0.40018-0.40036,0.40018-0.70065l4.10181-11.20993h4.40195
      c0.60027,0,1.00043-0.1001,1.50066-0.60052c0.40016-0.40036,0.60027-0.90079,0.60027-1.50134v-9.70859l11.90524,11.31
      c0.60027,0.60052,1.60071,0.70062,2.20096,0.40033c0.10005-0.1001,0.40018-0.1001,0.70032-0.40033
      C215.7999,436.58804,216,436.08762,216,435.48706v-29.72632C215.79991,405.26041,215.69986,404.75995,215.39973,404.45969z
      M183.48567,380.83878c-2.10092,2.10187-4.60204,3.20285-7.60335,3.20285c-2.90128,0-5.50243-1.10098-7.60336-3.20285
      c-2.10092-2.10187-3.2014-4.6041-3.2014-7.60675c0-2.90256,1.20053-5.60495,3.2014-7.60672
      c2.10094-2.10187,4.60204-3.20285,7.7034-3.10275c5.90259,0,10.60468,4.80423,10.60468,10.60941
      C186.68709,376.23471,185.38651,378.9371,183.48567,380.83878z`,
})`
  fill: ${theme.colors.white};
`;

export const RocketWindow = styled.g``;
export const RocketWindowBackground = styled.path.attrs({
  d: `M176,385c6.60001,0,12-5.39999,12-12s-5.39999-12-12-12s-12,5.39999-12,12
      S169.39999,385,176,385z`,
})`
  fill: ${theme.colors.blues[60]};
`;

export const RocketPlatform = styled.g``;
export const PlatformBase = styled.path.attrs({
  d: `M233,446H93c-1.10449,0-2,0.89551-2,2v20c0,1.10449,0.89551,2,2,2h140
      c1.10449,0,2-0.89551,2-2v-20C235,446.89551,234.10449,446,233,446z`,
})`
  fill: ${theme.colors.blacks[30]};
`;
export const PlatformTower = styled.path.attrs({
  d: `M117,326H93c-0.13135,0-0.26251,0.01379-0.39185,0.03949
      c-0.05829,0.0116-0.11212,0.03345-0.16846,0.04999c-0.06866,0.02008-0.13818,0.03595-0.20483,0.06354
      c-0.0658,0.02728-0.12531,0.06476-0.18738,0.09863c-0.05237,0.0285-0.10675,0.05164-0.15692,0.08521
      c-0.21924,0.14655-0.40759,0.33514-0.55408,0.55444c-0.03174,0.04749-0.05334,0.099-0.08057,0.14844
      c-0.03571,0.06464-0.07465,0.12701-0.10309,0.19574c-0.02686,0.065-0.04224,0.13269-0.06195,0.19965
      c-0.01715,0.05786-0.03949,0.11334-0.05139,0.17334C91.01379,327.73767,91,327.86871,91,328v24v24v24v24v24
      c0,1.10449,0.89551,2,2,2h24c0.13129,0,0.26239-0.01379,0.39166-0.03949c0.05902-0.01172,0.11365-0.03381,0.17065-0.0506
      c0.06793-0.0199,0.13672-0.03558,0.20264-0.06287c0.06647-0.02759,0.12683-0.06549,0.18951-0.09979
      c0.05164-0.02826,0.10529-0.05096,0.15479-0.08405c0.21936-0.14661,0.40778-0.33514,0.55426-0.5545
      c0.03174-0.04749,0.05334-0.099,0.08057-0.14844c0.03571-0.06464,0.07465-0.12701,0.10309-0.19574
      c0.02686-0.065,0.04224-0.13269,0.06195-0.19965c0.01715-0.05786,0.03949-0.11334,0.05139-0.17334
      C118.98627,448.26227,119,448.13129,119,448v-24v-24v-24v-24v-24C119,326.89551,118.10449,326,117,326z M97.82812,426H115v17.17188
      L97.82812,426z M112.17188,350H95v-17.17188L112.17188,350z M95,354h17.17188L95,371.17188V354z M95,380.82812L112.17188,398H95
      V380.82812z M115,395.17188L97.82812,378H115V395.17188z M95,402h17.17188L95,419.17188V402z M115,422H97.82812L115,404.82812V422z
      M115,374H97.82812L115,356.82812V374z M115,347.17188L97.82812,330H115V347.17188z M95,428.82812L112.17188,446H95V428.82812z`,
})`
  fill: ${theme.colors.blacks[30]};
`;

const StepNumber = styled.path`
  fill: ${theme.colors.white};
  opacity: 0;
`;
export const StepNumberOne = styled(StepNumber).attrs({
  d: `M178.10001,366.79694h-2.5L172.5,368.69693v2.29999l2.8-1.79999h0.10001v9.89999
      h2.60001v-12.29999H178.10001z`,
})``;
export const StepNumberTwo = styled(StepNumber).attrs({
  d: `M171.69998,378.89999h8.8v-2.10001h-5.2v-0.10001l1.8-1.79999
      c2.5-2.29999,3.2-3.5,3.2-4.89999c0-2.10001-1.8-3.70001-4.39999-3.70001c-2.60001,0-4.39999,1.60001-4.39999,4h2.5
      c0-1.20001,0.7-1.89999,1.89999-1.89999c1.10001,0,1.89999,0.70001,1.89999,1.79999c0,1-0.60001,1.70001-1.7,2.70001L171.7,377
      v1.89999H171.69998z`,
})``;
export const StepNumberThree = styled(StepNumber).attrs({
  d: `M175.90179,379.35001c2.8,0,4.8-1.5,4.8-3.60001c0-1.5-1-2.60001-2.8-2.89999V372.75
      c1.39999-0.20001,2.3-1.20001,2.3-2.60001c0-1.89999-1.7-3.5-4.3-3.5c-2.5,0-4.39999,1.5-4.5,3.60001h2.5
      c0-0.89999,0.89999-1.60001,2-1.60001s1.8,0.60001,1.8,1.60001s-0.8,1.60001-2,1.60001h-1.2V373.75h1.2
      c1.39999,0,2.3,0.70001,2.3,1.70001s-0.8,1.69998-2,1.69998s-2-0.60001-2.10001-1.5h-2.60001
      C171.2018,377.85001,173.2018,379.35001,175.90179,379.35001z`,
})``;
export const StepNumberFour = styled(StepNumber).attrs({
  d: `M170.90001,377H176.8v2.20001h2.5V377h1.5v-2.10001h-1.5v-8.10001H176l-5.2,8.10001
      V377H170.90001z M176.8,374.89999h-3.39999v-0.10001l3.3-5.10001h0.10001v5.20001H176.8z`,
})``;
export const StepNumberFive = styled(StepNumber).attrs({
  d: `M176.04999,379.5c2.80002,0,4.60001-1.79999,4.60001-4.29999
      c0-2.39999-1.60001-4.10001-3.89999-4.10001c-1.10001,0-2,0.5-2.5,1.10001h-0.10001l0.30002-3.10001h5.39999V367H172.25
      l-0.60001,6.5l2.29999,0.39999c0.39999-0.5,1.10001-0.80002,1.80002-0.80002c1.19998,0,2.10001,0.90002,2.10001,2.20001
      S176.95001,377.5,175.75,377.5c-1,0-1.89999-0.70001-1.89999-1.60004h-2.5C171.64999,377.90002,173.54999,379.5,176.04999,379.5z`,
})``;
export const StepNumberSix = styled(StepNumber).attrs({
  d: `M176.14999,379.60001c2.8,0,4.60001-1.80002,4.60001-4.30002
      c0-2.29999-1.60001-4-3.8-4c-1.39999,0-2.60001,0.70001-3.10001,1.70001h-0.10001c0-2.39999,0.89999-3.89999,2.5-3.89999
      c0.89999,0,1.60001,0.5,1.8,1.39999h2.60001c-0.2-2.10001-1.89999-3.70001-4.39999-3.70001c-3.10001,0-5,2.5-5,6.70001
      C171.24998,378,173.64999,379.60001,176.14999,379.60001z M176.14999,377.5c-1.2,0-2.10001-1-2.10001-2.20001
      c0-1.19998,0.89999-2.19998,2.10001-2.19998c1.2,0,2.10001,0.89999,2.10001,2.19998
      C178.24998,376.60001,177.34999,377.5,176.14999,377.5z`,
})``;

export const Flames = styled.g``;
export const SmallFlame = styled.path.attrs({
  ...evenOddStyle,
  d: `M184,461.5c0-8.29999-1.39999-18.5-8-18.5
      s-8,10.20001-8,18.5s6.39999,21.5,8,21.5S184,469.79999,184,461.5z`,
})`
  fill: ${theme.colors.yellows[40]};
`;
export const DarkFlame = styled.path.attrs({
  ...evenOddStyle,
  d: `M296,678c-93.60001-86.20001-120-149.29999-120-309c0,159.70001-26.39999,222.79999-120,309H296z`,
})`
  fill: ${theme.colors.oranges[40]};
`;
export const LightFlame = styled.path.attrs({
  ...evenOddStyle,
  d: `M262,678c-50.3-70.20001-86.7-173-86-301c-0.7,128-35.7,230.79999-86,301H262z`,
})`
  fill: ${theme.colors.yellows[40]};
`;

const WingLine = styled.path`
  fill: ${({ color }) => getBaseColor(color)[10]};
`;
export const WingLines = styled.g``;
export const LeftWingLine = styled(WingLine).attrs({
  d: `M138,442c-0.55225,0-1,0.44727-1,1v23c0,0.55273,0.44775,1,1,1s1-0.44727,1-1v-23
      C139,442.44727,138.55225,442,138,442z`,
})``;
export const CenterLeftWingLine = styled(WingLine).attrs({
  d: `M153,447c-0.55225,0-1,0.44727-1,1v31c0,0.55273,0.44775,1,1,1s1-0.44727,1-1
      v-31C154,447.44727,153.55225,447,153,447z`,
})``;
export const CenterRightWingLine = styled(WingLine).attrs({
  d: `M200,447c-0.55273,0-1,0.44727-1,1v31c0,0.55273,0.44727,1,1,1s1-0.44727,1-1
      v-31C201,447.44727,200.55273,447,200,447z`,
})``;
export const RightWingLine = styled(WingLine).attrs({
  d: `M214,442c-0.55273,0-1,0.44727-1,1v23c0,0.55273,0.44727,1,1,1s1-0.44727,1-1v-23
      C215,442.44727,214.55273,442,214,442z`,
})``;

const ProgressLine = styled.path`
  fill: ${theme.colors.white};
`;
export const ProgressLines = styled.g``;
export const ProgressLine1 = styled(ProgressLine).attrs({
  d: 'M216,324h-80v-2h80V324z',
})``;
export const ProgressLine2 = styled(ProgressLine).attrs({
  d: 'M216,284h-80v-2h80V284z',
})``;
export const ProgressLine3 = styled(ProgressLine).attrs({
  d: 'M216,244h-80v-2h80V244z',
})``;
export const ProgressLine4 = styled(ProgressLine).attrs({
  d: 'M216,204h-80v-2h80V204z',
})``;
export const ProgressLine5 = styled(ProgressLine).attrs({
  d: 'M216,164h-80v-2h80V164z',
})``;
export const ProgressLine6 = styled(ProgressLine).attrs({
  d: 'M216,124h-80v-2h80V124z',
})``;

export const Smoke = styled.path.attrs({
  ...evenOddStyle,
  d: `M85.1,468h181.10001
      c0.70001-0.5,1.29999-1.10001,1.60001-1.89999c3.60001-9.79999,2.20001-20.60001-3.70001-29.5
      C254.5,422,235,418,217.40001,422.89999c-2.5-9.60001-11.60001-16.60001-22.39999-16.60001c-5,0-9.7,1.5-13.5,4.20001V382
      c0-1.89999-1.5-3.39999-3.5-3.70001v-0.10001h-3.89999c-2.3,0-4.2,1.70001-4.2,3.70001v28.39999
      c-3.8-2.60001-8.5-4.20001-13.5-4.20001c-10.7,0-19.60001,6.89999-22.3,16.20001c-7-2.60001-14.4-4.39999-21.6-2.79999
      c-10.7,2.5-19.7,8.5-25.2,16.89999c-5.9,8.89999-7.2,19.60001-3.7,29.5C83.8,466.89999,84.4,467.5,85.1,468z`,
})`
  fill: ${theme.colors.blacks[30]};
`;

const TreeLeaves = styled.path`
  fill: ${theme.colors.yellows[10]};
`;
const TreeTrunk = styled.path`
  fill: ${({ color }) => getBaseColor(color)[20]};
`;
export const Landscape = styled.g``;
export const RightSmallTree = styled.g``;
export const RightSmallTreeLeaves = styled(TreeLeaves).attrs({
  d: `M338.60001,424.39999C354.80002,424.39999,368,411.19998,368,395
      s-13.20001-29.39999-29.39999-29.39999S309.20001,378.80002,309.20001,395S322.39999,424.39999,338.60001,424.39999z`,
})``;
export const RightSmallTreeTrunk = styled(TreeTrunk).attrs({
  d: `M354.00684,391.69336c-0.39062-0.39062-1.02344-0.39062-1.41406,0
      l-12.99316,12.99225V384.5c0-0.55273-0.44727-1-1-1s-1,0.44727-1,1v20.18555l-12.99219-12.99219
      c-0.39062-0.39062-1.02344-0.39062-1.41406,0s-0.39062,1.02344,0,1.41406l14.40625,14.40625V489.5c0,0.55273,0.44727,1,1,1
      s1-0.44727,1-1v-81.98633l14.40723-14.40625C354.39746,392.7168,354.39746,392.08398,354.00684,391.69336z`,
})``;
export const RightLargeTree = styled.g``;
export const RightLargeTreeLeaves = styled(TreeLeaves).attrs({
  d: `M293.5,406.29999c20.70001,0,37.5-16.79999,37.5-37.5s-16.79999-37.5-37.5-37.5
      s-37.5,16.80002-37.5,37.5S272.79999,406.29999,293.5,406.29999z`,
})``;
export const RightLargeTreeTrunk = styled(TreeTrunk).attrs({
  d: `M313.00879,364.89453c-0.38965-0.3916-1.02246-0.39453-1.41406-0.00391L294.5,381.8949
      V355.5c0-0.55273-0.44727-1-1-1s-1,0.44727-1,1v26.38574l-16.99316-16.99316c-0.39062-0.39062-1.02344-0.39062-1.41406,0
      s-0.39062,1.02344,0,1.41406L292.5,384.71387V489.5c0,0.55273,0.44727,1,1,1s1-0.44727,1-1V384.71545l18.50488-18.40686
      C313.39648,365.91895,313.39844,365.28613,313.00879,364.89453z`,
})``;
export const LeftTree = styled.g``;
export const LeftTreeLeaves = styled(TreeLeaves).attrs({
  d: `M-13,403.89999c0,20.39999,16.5,36.89999,36.9,36.89999s36.9-16.5,36.9-36.89999
      v-33.5C60.8,350,44.3,333.5,23.9,333.5S-13,350-13,370.39999V403.89999z`,
})``;
export const LeftTreeTrunk = styled(TreeTrunk).attrs({
  d: `M37.40723,388.39258c-0.39062-0.39062-1.02344-0.39062-1.41406,0L24.7998,399.58594
      V382.2002c0-0.55273-0.44775-1-1-1s-1,0.44727-1,1v17.37201l-11.08984-11.1767c-0.38867-0.3916-1.02148-0.39453-1.41406-0.00586
      c-0.39209,0.38867-0.39453,1.02246-0.00586,1.41406L22.7998,402.4118v71.98859c0,0.55273,0.44775,1,1,1s1-0.44727,1-1v-71.98633
      l12.60742-12.60742C37.79785,389.41602,37.79785,388.7832,37.40723,388.39258z`,
})``;

export const Sky = styled.g``;
export const Sun = styled.path.attrs({
  d: `M9.5,141.875c0-3.5,0-7,0-10.5c0-4.5,7-4.5,7,0c0,3.5,0,7,0,10.5
      C16.5,146.375,9.5,146.375,9.5,141.875z M60.625,175.5c-3.5,0-7,0-10.5,0c-4.5,0-4.5,7,0,7c3.5,0,7,0,10.5,0
      C65.125,182.5,65.125,175.5,60.625,175.5z M-24.125,175.5c-3.5,0-7,0-10.5,0c-4.5,0-4.5,7,0,7c3.5,0,7,0,10.5,0
      C-19.625,182.5-19.625,175.5-24.125,175.5z M9.5,216.125c0,3.5,0,7,0,10.5c0,4.5,7,4.5,7,0c0-3.5,0-7,0-10.5
      C16.5,211.625,9.5,211.625,9.5,216.125z M41.7262,155.40002c2.47491-2.47485,4.94977-4.94977,7.42462-7.42462
      c3.18201-3.18195-1.76776-8.13171-4.94971-4.94977c-2.47491,2.47491-4.94977,4.94977-7.42462,7.42462
      C33.59448,153.63226,38.54425,158.58203,41.7262,155.40002z M41.7262,202.95294c-3.18195-3.18195-8.13171,1.76776-4.94971,4.94977
      c2.47485,2.47485,4.94971,4.94977,7.42462,7.42462c3.18195,3.18201,8.13171-1.76776,4.94971-4.94977
      C46.67596,207.90271,44.20111,205.42786,41.7262,202.95294z M-15.7262,155.40002c3.18201,3.18201,8.13171-1.76776,4.94977-4.94977
      c-2.47491-2.47485-4.94977-4.94971-7.42462-7.42462c-3.18201-3.18195-8.13177,1.76782-4.94977,4.94977
      C-20.67596,150.45026-18.20105,152.92517-15.7262,155.40002z M13,152c-14.91168,0-27,12.08832-27,27s12.08832,27,27,27
      s27-12.08832,27-27S27.91168,152,13,152z M-15.7262,202.95294c-2.47485,2.47491-4.94977,4.94977-7.42462,7.42462
      c-3.18201,3.18201,1.76776,8.13177,4.94977,4.94977c2.47485-2.47485,4.94971-4.94977,7.42462-7.42462
      C-7.59448,204.7207-12.54419,199.771-15.7262,202.95294z`,
})`
  fill: ${theme.colors.yellows[40]};
`;

const Cloud = styled.path`
  fill: ${({ color }) => getBaseColor(color)[10]};
`;
export const LeftCloud = styled(Cloud).attrs({
  d: `M57.2,208.8c-1.9,0-3.8,0.2-5.6,0.60001c0.4-1.39999,0.6-2.89999,0.6-4.5
      c0-9.39999-7.6-16.89999-16.9-16.89999c-5.7,0-10.7,2.8-13.8,7.10001c-3.5-5.3-10.4-11.39999-21-11.39999
      c-17.2,0-29.7,11.5-29.7,27.3c0,1.60001,0.1,4.7,0.8,7.3c-0.2,0.10001-0.4,0.10001-0.5,0.2c-0.1,0-0.1,0-0.1,0
      c-11.7-0.10001-21,9.2-21,20.7s9.3,20.8,20.8,20.8c0.2,0,0.4,0,0.6,0c0.7,0,1.3,0,1.8,0c1,0,1.9,0,2.8-0.10001c0.7,0,1.4,0,2,0
      c9.8,0,23.4-0.10001,37.7-0.20001C30.5,259.60001,45.8,259.5,57.1,259.5c14,0,25.4-11.39999,25.4-25.39999S71.2,208.8,57.2,208.8z`,
})``;
export const RightCloud = styled(Cloud).attrs({
  d: `M391.79999,134.39999C390.60001,111.9,372,94,349.20001,94
      C333.40002,94,319,102.7,311.5,116.6c-12.79999-5.3-27.39999,0.8-32.70001,13.6c-1.29999,3-1.89999,6.3-1.89999,9.60001
      c0,0.89999,0.10001,1.89999,0.20001,2.8c-12.20001,0.39999-21.89999,10.3-21.89999,22.60001
      c0,12.5,10.10001,22.60001,22.60001,22.60001H288h32.20001h22h35.20001h5.89999c15.10001,0,27.39999-12.3,27.39999-27.39999
      C410.60001,148.2,402.70001,138,391.79999,134.39999z`,
})``;

const Bird = styled.path`
  fill: ${theme.colors.purples[20]};
`;
export const Birds = styled.g``;
export const LeftBird = styled(Bird).attrs({
  d: `M230.43262,184.30225c-6.78125,0.45801-12.6181,3.60583-15.13281,7.9306
      c-2.51471-4.32477-8.35156-7.4726-15.13281-7.9306c-0.54297-0.04004-1.02734,0.37939-1.06543,0.93018
      c-0.03711,0.55078,0.37988,1.02783,0.93066,1.06494c7.06055,0.47705,12.94434,4.04834,14.30859,8.68506
      c0.01233,0.04193,0.03796,0.07501,0.05511,0.11401c0.01855,0.04236,0.03497,0.08313,0.05914,0.1225
      c0.04474,0.07294,0.0993,0.13489,0.15967,0.19287c0.0249,0.02411,0.04413,0.05157,0.07172,0.07312
      c0.09552,0.07465,0.20215,0.13184,0.31812,0.16791c0.00482,0.00153,0.00812,0.00537,0.013,0.00684
      c0.09473,0.02783,0.18945,0.04102,0.28223,0.04102s0.18848-0.01318,0.28223-0.04102
      c0.00488-0.00146,0.00812-0.00531,0.01294-0.00684c0.11591-0.03607,0.22266-0.09326,0.31818-0.16791
      c0.02747-0.02148,0.04669-0.04889,0.07159-0.07294c0.06049-0.05804,0.11511-0.12018,0.15991-0.19324
      c0.02411-0.03931,0.04047-0.07996,0.05902-0.12219c0.01715-0.03906,0.04279-0.07214,0.05511-0.11414
      c1.36426-4.63672,7.24805-8.20801,14.30859-8.68506c0.55078-0.03711,0.96777-0.51416,0.93066-1.06494
      S230.98633,184.26514,230.43262,184.30225z`,
})``;
export const CenterBird = styled(Bird).attrs({
  d: `M271.53223,234.60205c-6.7804,0.45844-12.61719,3.60602-15.13184,7.93066
      c-2.51471-4.32465-8.35156-7.47223-15.13281-7.93066c-0.54004-0.04834-1.02734,0.37891-1.06543,0.93018
      c-0.03711,0.55078,0.37988,1.02832,0.93066,1.06543c7.06055,0.47656,12.94434,4.04785,14.30859,8.68457
      c0.01208,0.04114,0.03729,0.07349,0.05402,0.11182c0.01898,0.04364,0.03595,0.08557,0.06091,0.12604
      c0.04376,0.07111,0.09717,0.13135,0.15582,0.18817c0.02612,0.02545,0.04645,0.05426,0.0755,0.0769
      c0.09534,0.07428,0.20197,0.1311,0.31763,0.16705c0.00482,0.00146,0.008,0.00537,0.01288,0.00677
      C256.21289,245.9873,256.30762,246,256.40039,246s0.18848-0.0127,0.28223-0.04102c0.00482-0.0014,0.00806-0.00531,0.01288-0.00677
      c0.11572-0.03589,0.22223-0.09277,0.31763-0.16705c0.02899-0.02258,0.04926-0.05133,0.07532-0.07672
      c0.05872-0.05688,0.11224-0.11731,0.15607-0.18848c0.0249-0.04041,0.04181-0.08228,0.06079-0.12579
      c0.01672-0.03833,0.04199-0.07074,0.05408-0.11194c1.36426-4.63672,7.24707-8.20801,14.30762-8.68457
      c0.55078-0.03711,0.96777-0.51465,0.93066-1.06543S272.07812,234.55664,271.53223,234.60205z`,
})``;
export const RightBird = styled(Bird).attrs({
  d: `M301.33301,221.10254c-6.78125,0.45801-12.6181,3.60583-15.13281,7.9303
      c-2.51471-4.32446-8.35156-7.47229-15.13281-7.9303c-0.54102-0.04102-1.02734,0.37939-1.06543,0.93018
      c-0.03711,0.55078,0.37988,1.02783,0.93066,1.06494c7.06055,0.47705,12.94434,4.04834,14.30859,8.68457
      c0.01233,0.04193,0.03796,0.07501,0.05511,0.11401c0.01855,0.04236,0.03497,0.08313,0.05914,0.1225
      c0.04474,0.07294,0.0993,0.13489,0.15967,0.19287c0.0249,0.02411,0.04413,0.05157,0.07172,0.07312
      c0.09552,0.07465,0.20215,0.13184,0.31812,0.16791c0.00482,0.00153,0.00812,0.00537,0.013,0.00684
      c0.09473,0.02783,0.18945,0.04102,0.28223,0.04102s0.18848-0.01318,0.28223-0.04102
      c0.00488-0.00146,0.00812-0.00531,0.01294-0.00684c0.11591-0.03607,0.22266-0.09326,0.31818-0.16791
      c0.02747-0.02148,0.04669-0.04889,0.07159-0.07294c0.06049-0.05804,0.11511-0.12018,0.15991-0.19324
      c0.02411-0.03931,0.04047-0.07996,0.05902-0.12219c0.01715-0.03906,0.04279-0.07214,0.05511-0.11414
      c1.36426-4.63623,7.24805-8.20752,14.30859-8.68457c0.55078-0.03711,0.96777-0.51416,0.93066-1.06494
      S301.88867,221.06445,301.33301,221.10254z`,
})``;

const Star = styled.path`
  fill: ${({ color }) => getBaseColor(color)[20]};
`;
export const Stars = styled.g``;
export const RightBottomStar = styled(Star).attrs({
  d: `M309,291l6.89999-1.10001L317,283l1.10001,6.89999L325,291l-6.89999,1.10001
      L317,299l-1.10001-6.89999L309,291z`,
})``;
export const RightMiddleStar = styled(Star).attrs({
  d: `M292.20001,214.2L306.40002,211.8L308.70001,197.2L311,211.8L325.20001,214.2
      L311,216.59999L308.70001,231.2l-2.29999-14.60001L292.20001,214.2z`,
})``;
export const RightTopStar = styled(Star).attrs({
  d: `M307.20001,146.10001l7.70001-1.3l1.29999-7.7l1.29999,7.7l7.70001,1.3l-7.70001,1.3
      l-1.29999,7.7l-1.29999-7.7L307.20001,146.10001z`,
})``;
export const RightCenterBottomStar = styled(Star).attrs({
  d: `M257,241.5l8.20001-1.3L266.5,232l1.29999,8.2l8.10001,1.3l-8.10001,1.3
      L266.5,251l-1.29999-8.2L257,241.5z`,
})``;
export const RightCenterMiddleStar = styled(Star).attrs({
  d: `M254.89999,177l6-1l1-6l1,6l6,1l-6,1l-1,6l-1-6L254.89999,177z`,
})``;
export const RightCenterTopStar = styled(Star).attrs({
  d: `M246,114l5.2-0.8l0.89999-5.2l0.8,5.2L258,114l-5.10001,0.8L252,120
      l-0.89999-5.2L246,114z`,
})``;
export const LeftCenterBottomStar = styled(Star).attrs({
  d: `M78.4,243.39999L77,252l-1.4-8.60001L67,242l8.6-1.39999L77,232l1.4,8.60001
      L87,242L78.4,243.39999z`,
})``;
export const LeftCenterMiddleStar = styled(Star).attrs({
  d: `M76.7,189l-2.4-14.60001L59.7,172l14.6-2.39999L76.7,155l2.4,14.60001
      L93.7,172l-14.6,2.39999L76.7,189z`,
})``;
export const LeftCenterTopStar = styled(Star).attrs({
  d: `M115,115l-1,6l-1-6l-6-1l6-1l1-6l1,6l6,1L115,115z`,
})``;
export const LeftBottomStar = styled(Star).attrs({
  d: `M35,290l-1,6l-1-6l-6-1l6-1l1-6l1,6l6,1L35,290z`,
})``;
export const LeftMiddleStar = styled(Star).attrs({
  d: `M34.7,206.8l-1.2,7.3l-1.2-7.3l-7.3-1.2l7.3-1.2l1.2-7.3l1.2,7.3l7.3,1.2
      L34.7,206.8z`,
})``;
export const LeftTopStar = styled(Star).attrs({
  d: `M32,138.39999L30.6,147l-1.4-8.60001L20.6,137l8.6-1.39999l1.4-8.6l1.4,8.6L40.6,137
      L32,138.39999z`,
})``;

export const Globe = styled.g``;
export const Land = styled.path.attrs({
  d: `M356.2,411.5c-6.9,0.1-13.8,2.3-19.5,4.2c-1.8,0.6-4.2,1.4-5.7,1.7c-6.3-6.3-14.8-10-23.7-10.3
      c-0.4,0-0.8,0-1.1,0c-5.6,0-10.9,2.5-14.5,6.8c-3.9,4.6-5.4,10.8-4.1,16.7c0.7,2.6,1.6,5.1,2.8,7.5c0.8,1.6,1.5,3.3,1.9,5
      c-0.5,0.3-1,0.5-1.6,0.7c-0.7,0.1-1.4,0.1-2.1,0.1c-3.4,0-6.7-0.9-9.5-2.8c-2.9-2.3-6.5-3.6-10.1-3.6c-1.5,0-2.9,0.2-4.4,0.6
      c-6.1,2.4-10.6,7.8-11.7,14.3c-2.2,10.3,3.5,16.4,8.6,21.7c0.8,0.8,1.6,1.7,2.4,2.6c5.4,5.4,12.1,9.2,19.4,11.3
      c1,0.3,1.9,0.7,2.9,1c-0.3,0.6-0.7,1.1-0.9,1.5c-0.8,1.3-1.5,2.6-2.3,3.9c-1.4,2.3-2.8,4.7-4.2,6.8c-1.8,2.7-3.8,5.3-6,8
      c-5.4,6.2-9.8,13.3-12.9,20.9c-2.1,6.7-3.4,13.7-3.6,20.8c-0.2,4.8-0.9,9.5-1.9,14.2c-1.5,4.4-3.9,8.5-7.1,11.9
      c-5.8,6.7-12.5,12.7-19.9,17.7c-3.1,2.2-6.9,4.3-10.3,4.3c-1.9,0-3.7-0.6-5.2-2.2c-5.5-6.3-9.2-14.1-10.6-22.4
      c-0.7-4.1-1.1-8.2-1.1-12.4c0.1-6.2-0.7-12.4-2.4-18.4c-0.8-2.2-1.7-4.3-2.8-6.4c-1.5-2.1-2.3-4.7-2.4-7.3
      c1.1-10.9-1.6-19.3-8-25.1c-6.9-6.2-16.5-7.6-23.6-7.6c-4.5,0-7.9,0.5-9.1,0.7c-0.6,0-1.1,0.1-1.7,0.1c-6.6,0-12.3-2.7-19.4-6
      l-1.9-0.9c-12.3-5.7-17-16-15.3-33.5c2.3-18,15.1-32.9,32.5-37.9c4.5-1.2,8.9-1.7,13.4-1.7c11.3,0,22.8,3.2,35.8,7.1
      c4.1,1.2,13.3,2.2,29.9,3l1,0.1c0.9,0,1.8,0.1,2.7,0.1c8,0,14.1-2,18.5-5.9c3.1-2.7,5.1-6.6,5.5-10.7c1-8.3-1.6-16.7-7.3-22.9
      c-9.8-10.4-26.6-11.9-39.3-11.9c-0.7,0-1.3,0-2,0c-3.3,0-6.9,0.3-10.3,0.5c-3.7,0.3-7.5,0.6-11.1,0.6c-3.8,0-7.5-0.3-10.7-1.3
      c-0.6-0.2-1.1-0.4-1.6-0.6c-0.2-2.1,0.9-3.3,6-6.6c2.7-1.6,5.2-3.5,7.3-5.8c5.8-6.7,6.4-17.1,6.6-20.5V343v-0.1
      c0.7-5.3,13.2-7.6,24-7.6c2,0,3.9,0.1,5.6,0.2c0.2,0,0.5,0,0.8,0c0.3,0,0.6,0,0.9,0c4.7,0,9.1-2.2,11.9-5.9
      c5.7-7.8,2.3-19.8,1.3-22.8c-1.4-4.9-1.1-10.1,0.8-14.6c27.8,9.2,53.3,24.8,74.7,46.1C331.4,359.3,346.9,384.3,356.2,411.5z
      M39.5,514.7c-10.9-9-23.1-19.2-25.1-35.8c-1.3-11.5-1.5-23.1-0.5-34.7c-0.6-10-12.8-14.2-23.3-16.1c-3.5,14.5-5.3,29.5-5.3,44.8
      c0,50.9,19.8,98.7,55.8,134.7c1.9,1.9,3.8,3.8,5.8,5.6c2.6-3.8,3.4-11,1.9-19.1c-1.1-7.6-0.5-15.3,1.6-22.7l0.2-0.9
      c1.1-4.3,2.4-8.6,4.1-12.7c2.5-5.5,3.9-11.4,4-17.4C57.9,529.9,49.4,522.9,39.5,514.7z M72,313.3c-11,7.2-21.4,15.5-30.9,24.9
      c-16.4,16.4-29.4,35.2-38.8,55.7c3.3-2.8,6.4-5.9,9.1-9c10.6-12.4,23.5-18.3,36-23.9c1.9-0.8,3.7-1.6,5.6-2.5
      c11.3-5.3,17.2-10.7,19.8-18.1c1.7-7.3,1.7-14.8-0.1-22.1C72.5,317,72.2,315.3,72,313.3z M92.2,644.2
      c25.7,12.6,54.1,19.2,83.6,19.2c10.4,0,20.6-0.8,30.6-2.4c-23.4-19.6-47.5-25.9-68.8-25.9C119.8,635.1,104,639.5,92.2,644.2z`,
})`
  fill: ${theme.colors.greens[40]};
`;
export const Water = styled.path.attrs({
  d: `M366,473c0-19.10004-2.79999-37.90002-8.40002-56c-0.39996,0-0.79999,0-1.29999,0
      c-6.29999,0-12.90002,2.20001-18.20001,4c-1.89996,0.69995-4.39996,1.5-6.09998,1.89996
      c-0.40002,0.10004-0.90002,0.20001-1.29999,0.20001c-1.5,0-2.90002-0.59998-3.90002-1.59998
      c-5.39996-5.30005-12.39996-8.40002-20-8.70001h-0.09998c-0.29999,0-0.5,0-0.79999,0c-3.90002,0-7.70001,1.79999-10.20001,4.79999
      c-2.70001,3.29999-3.79999,7.69995-2.90002,11.79999c0.60004,2.19995,1.40002,4.29999,2.40002,6.39996
      c0.89996,1.79999,1.69995,3.79999,2.29999,5.90002c0.70001,2.40002-0.40002,4.90002-2.5,6.20001
      c-0.80005,0.5-1.80005,0.90002-2.70001,1.20001c-0.29999,0.09998-0.70001,0.20001-1,0.20001
      c-0.90002,0.09998-1.80005,0.20001-2.70001,0.20001c-4.5,0-8.79999-1.20001-12.5-3.60004
      c-0.20001-0.09998-0.29999-0.29999-0.5-0.39996c-1.90002-1.5-4.20001-2.29999-6.60004-2.29999
      c-0.89996,0-1.70001,0.09998-2.59998,0.29999c-4.20001,1.70001-7.20001,5.5-8,10c0,0,0,0.09998,0,0.20001
      c-1.5,6.89996,1.70001,11,7.20001,16.79999c0.79999,0.89996,1.59998,1.70001,2.39996,2.59998
      c4.70001,4.60004,10.5,8,16.90002,9.70001c0.09998,0,0.29999,0,0.39996,0.10004L285.59991,483
      c0.80005,0.40002,1.70001,0.70001,2.60004,1c1.5,0.59998,2.59998,1.79999,3.20001,3.29999c0.5,1.5,0.39996,3.20001-0.40002,4.60004
      c-0.29999,0.5-0.69995,1.09998-0.89996,1.5V493.5c-0.79999,1.29999-1.59998,2.5-2.29999,3.79999
      c-1.40002,2.40002-2.90002,4.90002-4.40002,7.10004c-1.79999,2.79999-3.89996,5.29999-6,8.09998l-0.09998,0.20001
      c-0.10004,0-0.10004,0.10004-0.20001,0.20001c-5,5.70001-9,12.20001-11.90002,19.20001
      c-1.89996,6.19995-3.09998,12.59998-3.29999,19.09998v0.09998c-0.29999,5-1,10.09998-2.09998,15.09998
      c-0.10004,0.09998-0.10004,0.29999-0.20001,0.5c-1.70001,5.10004-4.5,9.90002-8.20001,13.90002
      c-6.10004,7-13.10004,13.20001-20.79999,18.5c-4.90002,3.5-9.40002,5.29999-13.5,5.29999
      c-3.60004,0-6.70001-1.30005-9.20001-3.90002c-0.09998-0.10004-0.09998-0.10004-0.20001-0.20001
      c-6.20001-7.09998-10.29999-15.79999-11.79999-25.09998c-0.79999-4.29999-1.20001-8.79999-1.20001-13.29999V562
      c0.09998-5.60004-0.59998-11.29999-2.09998-16.70001c-0.60004-1.79999-1.40002-3.5-2.30005-5.20001
      c-2-3-3.09998-6.40002-3.19995-9.90002c0-0.29999,0-0.59998,0-0.79999c0.89996-9.20001-1.10004-15.90002-6.20001-20.5
      c-5.59998-5-13.90002-6.09998-19.90002-6.09998c-4.09998,0-7.29999,0.5-8.19995,0.70001
      c-0.20001,0.09998-0.40002,0.09998-0.60004,0.09998c-0.59998,0.10004-1.29999,0.10004-2,0.10004
      c-7.79999,0-14.29999-3-21.70001-6.5l-1.89996-0.90002c-14.5-6.79999-20.30005-19.20001-18.40002-39c0-0.09998,0-0.09998,0-0.20001
      c2.59998-20.09998,16.90002-36.79999,36.5-42.39996c0.09998,0,0.09998,0,0.20001,0
      c4.79999-1.20001,9.59998-1.79999,14.79999-1.79999c12.20001,0,24.70001,3.5,37.40002,7.39996
      c5.20001,1.59998,22.70001,2.5,28.5,2.79999l1,0.10004c0.79999,0.09998,1.59998,0.09998,2.39996,0.09998
      c6.60004,0,11.5-1.5,14.80005-4.5l0.09998-0.09998c2-1.80005,3.29999-4.30005,3.60004-7.10004V411.5
      c0.89996-6.70001-1.30005-13.5-5.80005-18.5c-6.39996-6.80005-17.89996-10.10004-35.19995-10.10004c-0.60004,0-1.29999,0-1.90002,0
      c-3.20001,0-6.59998,0.29999-9.89996,0.5C190.89996,383.70001,187,384,183.20001,384c-4.90002,0-8.80005-0.5-12.20001-1.5
      c-0.90002-0.30005-1.79999-0.60004-2.70001-1.10004c-1.59998-0.79999-2.70001-2.5-2.89996-4.29999
      c-0.60004-5.89996,3.79999-8.79999,8.5-11.79999c0.09998,0,0.09998-0.09998,0.19995-0.09998
      c2.20001-1.29999,4.20001-2.90002,6-4.79999c4.5-5.40002,5-14.30005,5.20001-17.20001v-0.10004v-0.20001
      c0-0.09998,0-0.19995,0-0.29999V342.5c1.40002-11.20001,21.10004-12.40002,29.5-12.40002c2.10004,0,4.20001,0,6.10004,0.20001
      c0.09998,0,0.09998,0,0.19995,0h0.10004c0.09998,0,0.20001,0,0.29999,0c0.10004,0,0.29999,0,0.5,0c3,0,5.70001-1.40002,7.5-3.70001
      c3.20001-4.5,2.29999-12.5,0.5-17.89996c-0.09998-0.10004-0.09998-0.10004-0.09998-0.20001
      c-1.80005-5.90002-1.40002-12.29999,0.89996-17.90002C213,285.20001,194.59998,282.5,175.90002,282.5
      c-35,0-69,9.59998-98.90002,28.00006c0.29999,2.90002,0.59998,5.40002,0.89996,7.10004c2,8,2,16.5,0,24.5
      c-0.09998,0.20001-0.09998,0.29999-0.20001,0.5C74.59998,351.50012,67.79999,357.90009,55,363.80011
      c-1.70001,0.79999-3.30005,1.59998-5,2.29999l-0.60004,0.29999c-11.89996,5.39996-24.20001,10.89996-34,22.39996
      c-5,5.79999-11.29999,11.5-17.19995,15.40002c-2.40002,6.19995-4.5,12.5-6.20001,18.89996
      c21.39996,3.79999,26.69995,13.40002,27.19995,21c0,0.29999,0,0.5,0,0.79999c-1,11.10004-0.89996,22.5,0.40002,33.60004
      c1.70001,14.29999,12.59998,23.39996,23.09998,32.09998c10.30005,8.59998,20.10004,16.70001,21.10004,29.5
      c0,0.20001,0,0.29999,0,0.5c-0.10004,6.79999-1.60004,13.29999-4.5,19.5c-1.5,3.89996-2.70001,7.79999-3.70001,11.79999
      l-0.20001,0.79999c0,0.09998-0.09998,0.20001-0.09998,0.29999c-1.90002,6.59998-2.40002,13.39996-1.5,20.29999
      c1.89996,10,0.70001,18.5-3.10004,23.5c10.70001,9.29999,22.40002,17.39996,34.80005,24.09998
      c12.39996-5.40002,30.69995-11.20001,51.69995-11.20001c27.90002,0,53.5,10.09998,75.80005,30.09998
      c42.09998-8.5,80.59998-31.20001,108.39996-64.29999C350.09998,561.29999,366,517.79999,366,473z`,
})`
  fill: ${theme.colors.blues[10]};
`;
