import styled from 'styled-components';
import { 
  Box,
  Paper,
  TableRow
} from '@mui/material';

export const StyledTableRow = styled(TableRow)({
  '&:last-child > td': {
    borderBottom: 'none'
  }
});

export const CategoryPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    borderRadius: 0
  }
}));

export const LoaderContainer = styled.div(({ theme }) => ({
  position: 'relative',
  minHeight: '160px',
  padding: theme.spacing(2)
}));

export const ChartsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  alignItems: 'center',
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column'
  }
}));

export const ChartWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(3),
  width: '560px',
  [theme.breakpoints.down('lg')]: {
    width: '100%'
  }
}));

export const ProgressWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: `0 ${theme.spacing(3)}`,
  paddingTop: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: theme.spacing(2)
  }
}));
 