import NonRecurring from './NonRecurring';
import Recurring from './Recurring';
import BackgroundAnimation from './BackgroundAnimation';
import { backgroundColors } from './ColoredBackground';

export default {
  NonRecurring,
  Recurring,
  BackgroundAnimation,
  backgroundColors
};
