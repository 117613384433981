import React, { useContext, useEffect } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';

export default function TenantZero() {
  const { 
    currentTenant,
    setTenantZero
  } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    setTenantZero(true);
  }, []);

  useEffect(() => {
    if (currentTenant?.workspaces.length > 0) {
      setTenantZero(false);
      navigate(Resources.workspacesActivityFeed.path({ workspaceId: currentTenant.workspaces[0].id }));
    }
  }, [currentTenant]);

  const pageTitle = `${currentTenant?.name} Tenant`;

  return (
    <AppLayout
      layout={AppLayout.Layouts.LeftPanelOnly}
      pageTitle={pageTitle}
      mainColumn={null}
      sideColumn={null}
    />
  );
}
