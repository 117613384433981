import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clickable } from '../Clickable/Clickable';
import omitStyled from '../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

const Container = styled.div``;

const Tab = omitStyled(Clickable, ['isActive'])`
  border-bottom: 1px solid ${theme.colors.blacks[10]};
  display: block;
  color: ${({ isActive }) => theme.colors.blacks[isActive ? 80 : 50]};
  font-size: ${theme.fontSize.S};
  font-weight: ${({ isActive }) =>
    theme.fontWeight[isActive ? 'bold' : 'regular']};
  line-height: ${theme.lineHeight.M};
  padding: ${theme.spacing.single} 0;
  pointer-events: ${({ isActive }) => isActive ? 'none' : 'all'};
  text-align: left;
  width: 100%;
  transition: color ${theme.transitions.times.default};

  &:hover {
    color: ${theme.colors.purples[60]};
  }

  &:active {
    color: ${theme.colors.blacks[80]};
  }
`;

const isFunction = (func) => typeof func === 'function';

const Stacked = (props) => {
  const { activeTab, className, onChange, tabs } = props;

  return (
    <Container className={className}>
      {tabs.map((tab, index) => {
        const { label, onClick, to, value } = tab;
        const handleClick = (e, tabProps) => {
          if (isFunction(onClick)) {
            onClick(e, tabProps);
          }
          if (isFunction(onChange)) {
            onChange(e, { ...props, activeTab: tabProps.value });
          }
        };

        return (
          <Tab
            key={`tab-${value}-${index}`}
            isActive={value === activeTab}
            onClick={handleClick}
            to={to}
            value={value}
          >
            {label || value}
          </Tab>
        );
      })}
    </Container>
  );
};

Stacked.propTypes = {
  /** Value of the active tab. */
  activeTab: PropTypes.string,
  /** Additional class. */
  className: PropTypes.string,
  /** Callback when active tab changes. */
  onChange: PropTypes.func,
  /** Tabs to display. */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label of the tab. If not passed, value will show instead. */
      label: PropTypes.node,
      /** Callback when tab is clicked. */
      onClick: PropTypes.func,
      /** If tab is a link, pass in to prop url for tab. */
      to: PropTypes.string,
      /** Value of the tab. Not all tabs will have a url, so will need a unique value to determine the active tab. */
      value: PropTypes.string.isRequired,
    })
  ),
};

export default Stacked;
