import Jupiter from './Jupiter';
import Mars from './Mars';
import Europa from './Europa';
import Neptune from './Neptune';
import Stars from './Stars';

export const Backgrounds = {
  Jupiter,
  Mars,
  Europa,
  Neptune,
  Stars,
};

export default Backgrounds;