/* istanbul ignore file */
import React from 'react';
import styled from 'styled-components';
import { Button, IconNew as Icon } from '@matterapp/matter-ui';
import { MAX_STEP_WIDTH } from '../utils';
import theme from '@matterapp/matter-theme';

const BUTTON_LABELS = {
  CONTINUE_EDIT: 'Continue Editing',
  DEFAULT_NOTE: 'Use Default Note',
  NEXT: 'Continue',
  PREV: 'Back',
  SKIP: 'Skip',
  SUBMIT: 'Submit Feedback',
};

export const SkipIcon = styled(Icon).attrs({
  name: 'karotDown',
})``;

export const PrevIcon = styled(Icon).attrs({
  name: 'karotUp',
})``;

export const ButtonTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    &:first-child + span,
    &:last-child {
      margin-left: ${theme.spacing.half};
    }
    transform: scale(0.8);
    path {
      fill: currentColor;
    }
  }
  ${theme.media.S`
    & svg {
      display: none;
    }
  `}
`;

export const NavButtonContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: none;
  max-width: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
  z-index: 10;

  ${theme.media.S`
    border-bottom: ${({ isLastStep, isQualitiesStep }) =>
  isLastStep || isQualitiesStep
    ? 'none'
    : `2px solid ${theme.colors.blacks[10]}`};
    box-shadow: none;
    position: static;
    max-width: ${MAX_STEP_WIDTH}px;
    margin-bottom: ${theme.spacing.double};
    padding-bottom: ${theme.spacing.double};
  `}
`;

export const NavButtonWrapper = styled.div`
  box-shadow: 0 -2px 8px 0px ${theme.colors.blacks[20]};
  display: flex;
  position: relative;
  ${theme.media.S`
    box-shadow: none;
  `}
`;

export const NavButton = styled(Button.Simple.Minimal)`
  border-radius: 0;
  border-width: 0px;
  font-weight: ${theme.fontWeight.medium};
  height: ${theme.spacing.quad};
  flex: 1 1 100%;
  &:last-child {
    margin-left: -1px;
  }
  &:hover {
    z-index: 10;
  }
  &:active {
    background-color: #f9f9f9;
  }
  ${theme.media.S`
    border-radius: ${theme.borderRadius.S};
    border-width: 1px;
    font-weight: ${theme.fontWeight.regular};
    height: auto;
    &:last-child {
      margin-left: ${theme.spacing.singleAndHalf};
    }
    &:active {
      background-color: ${theme.colors.white};
    }
  `}
`;

export const getOverlayStyles = ({ show }) => `
  opacity: ${show ? 1 : 0};
  z-index: ${show ? 100 : 0};
  pointer-events: ${show ? 'all' : 'none'};
`;

export const DefaultNoteOverlayContainer = styled.div`
  background: ${theme.colors.white};
  border-radius: 0;
  box-shadow: none;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  padding: 0;
  transition: opacity ${theme.transitions.times.default};
  ${getOverlayStyles}

  ${theme.media.medium`
    border-radius: ${theme.borderRadius.M};
    box-shadow: 0 0 ${theme.spacing.half} ${theme.colors.blacks[40]};
    bottom: -${theme.spacing.singleAndHalf};
    left: -${theme.spacing.singleAndHalf};
    right: -${theme.spacing.singleAndHalf};
    padding: ${theme.spacing.singleAndHalf};
  `}
`;

export const DefaultNoteOverlayBackdrop = styled.div`
  position: fixed;
  background: ${theme.colors.black};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: ${({ show }) => (show ? 0.2 : 0)};
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
  transition: opacity ${theme.transitions.times.default};
`;

export const ContinueEditingButton = styled(Button.Simple.Primary).attrs({
  content: BUTTON_LABELS.CONTINUE_EDIT,
  rcLabel: BUTTON_LABELS.CONTINUE_EDIT,
})`
  flex: 1 1 100%;
  &:last-child {
    margin-left: 0;
  }
  ${theme.media.medium`
    &:last-child {
      margin-left: ${theme.spacing.singleAndHalf};
    }
  `}
`;

export const MobileButtonSeparator = styled.div`
  display: inline;
  min-width: 3px;
  background: ${theme.colors.blacks[10]};
  ${theme.media.S`
    display: none;
    min-width: 0px;
  `}
`;

export const DefaultNoteButton = styled(NavButton).attrs({
  content: BUTTON_LABELS.DEFAULT_NOTE,
  rcLabel: BUTTON_LABELS.DEFAULT_NOTE,
})``;

export const NextButton = styled(NavButton).attrs({
  content: BUTTON_LABELS.NEXT,
  rcLabel: BUTTON_LABELS.NEXT,
})``;

export const PrevButton = styled(NavButton).attrs({
  content: (
    <ButtonTextWrapper>
      <PrevIcon />
      <span>{BUTTON_LABELS.PREV}</span>
    </ButtonTextWrapper>
  ),
  rcLabel: BUTTON_LABELS.PREV,
})``;

export const SkipButton = styled(NavButton).attrs({
  content: (
    <ButtonTextWrapper>
      <span>{BUTTON_LABELS.SKIP}</span>
      <SkipIcon />
    </ButtonTextWrapper>
  ),
  rcLabel: BUTTON_LABELS.SKIP,
})``;

export const SubmitButton = styled(Button.Primary.Vivid).attrs({
  content: BUTTON_LABELS.SUBMIT,
  rcLabel: BUTTON_LABELS.SUBMIT,
  useGrayscaleDisabled: true,
})`
  border-radius: 0;
  border-width: 0px;
  font-weight: ${theme.fontWeight.medium};
  height: ${theme.spacing.quad};
  flex: 1 1 100%;
  &:last-child {
    margin-left: -1px;
  }
  &:hover {
    z-index: 10;
  }
  ${theme.media.S`
    border-radius: ${theme.borderRadius.S};
    border-width: 1px;
    font-weight: ${theme.fontWeight.regular};
    height: auto;
    &:last-child {
      margin-left: ${theme.spacing.singleAndHalf};
    }
  `}
`;
