import omitStyled from '../../../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

export const Heading = omitStyled('div', ['label'])`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${theme.spacing.single};
  padding-bottom: ${theme.spacing.singleAndHalf};

  h3 {
    font-size: ${theme.fontSize.S};
    line-height: ${theme.lineHeight.M}
    font-weight: ${theme.fontWeight.black};
    margin-bottom: 0;

  }

  ${({label}) => label && `
    margin-bottom: -8px;
  `}

  .selection-count {
    display: flex;
    color: ${theme.colors.blacks[50]}
    > div:first-of-type {
      font-weight: ${theme.fontWeight.regular};
      margin-right: 6px;
    }
    > div:last-of-type {
      font-weight: ${theme.fontWeight.medium};
    }
  }
`;