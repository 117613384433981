import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Buttons, Body, Header, Footer } from './components';
import { StyledModal } from './styles';
import {
  OVERLAY_THEMES,
  OVERLAY_THEMES_LIST,
  SIZES,
  SIZES_LIST,
  TRANSITIONS,
  TRANSITIONS_LIST,
} from './consts';

const Modal = (props) => {
  const { isOpen, onAfterOpen: onOpen, onRequestClose: onCloseOld, onClose } = props;
  const [isAfterOpen, setIsAfterOpen] = useState(false);

  const onAfterOpen = () => {
    onOpen();
    setTimeout(() => {
      // Timeout to fix transitions on Safari due to React Portal.
      setIsAfterOpen(true);
    }, 5);
  };

  const onRequestClose = (e) => {
    if (typeof onCloseOld === 'function') {
      onCloseOld(e, props);
    }
    onClose(e, props);
    if (!isOpen) {
      setIsAfterOpen(false);
    }
  };

  const onAfterClose = (e) => {
    if (!isOpen) {
      setIsAfterOpen(false);
    }
  };

  return (
    <StyledModal
      {...props}
      isAfterOpen={isAfterOpen}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      onClose={onRequestClose}
    />
  );
};

Modal.defaultProps = {
  onAfterOpen: () => {},
  onClose: () => null,
  overlayTheme: OVERLAY_THEMES.DEFAULT,
  size: SIZES.M,
  transition: TRANSITIONS.FADE,
};

Modal.propTypes = {
  /** If overlay blurs background on open. */
  blurOverlay: PropTypes.bool,
  /** Custom width of modal. Most pass in size={CUSTOM} to use. */
  customWidth: PropTypes.string,
  /** Open modal in full screen on mobile. */
  fullScreenMobile: PropTypes.bool,
  /** If modal is open. */
  isOpen: PropTypes.bool,
  /** Callback when modal closes. */
  onClose: PropTypes.func,
  /** Opens the modal lower from top. (Used for confirm modals.) */
  openLowerFromTop: PropTypes.bool,
  /** Theme of the modal overlay. */
  overlayTheme: PropTypes.oneOf(OVERLAY_THEMES_LIST),
  /** Size (width) of modal. */
  size: PropTypes.oneOf(SIZES_LIST),
  /** Modal open/close transition. */
  transition: PropTypes.oneOf(TRANSITIONS_LIST),
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.Buttons = Buttons;

Modal.overlayThemes = OVERLAY_THEMES;
Modal.sizes = SIZES;
Modal.transitions = TRANSITIONS;

export default Modal;
