import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.465 5.33L16 .5l-3.465 4.83L6.89 3.46l.035 5.945L1.26 11.21 4.78 16l-3.52 4.79 5.665 1.805-.035 5.945 5.645-1.87L16 31.5l3.465-4.83 5.645 1.87-.035-5.945 5.665-1.805L27.22 16l3.52-4.79-5.665-1.805.035-5.945-5.645 1.87zM16.75 24h-1.5c-.69 0-1.25-.565-1.25-1.25V18H9.25C8.56 18 8 17.435 8 16.75v-1.5c0-.69.565-1.25 1.25-1.25H14V9.25c0-.69.565-1.25 1.25-1.25h1.5c.69 0 1.25.565 1.25 1.25V14h4.75c.685 0 1.25.565 1.25 1.25v1.5c0 .69-.565 1.25-1.25 1.25H18v4.75c0 .685-.565 1.25-1.25 1.25z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
