import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from '../Avatar';
import omitStyled from '../../libs/omitStyled';
import { ListItem } from '../List';
import { peerStatus } from './peer-suggestions-reducer';
import theme from '@matterapp/matter-theme';

const avatarSize = 40;

const NoWrapText = styled.div`
  color: ${() => theme.colors.purples[50]};
  font-weight: bold;
  line-height: 20px;
  font-size: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledListItem = omitStyled(ListItem, ['peerStatus'])`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  will-change: opacity;
  padding: 20px 6px 20px 24px;

  ${(props) => props.peerStatus === peerStatus.isAccepting && `
    background: ${props.theme.colors.yellows[5]};
  `};

  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.purples[5]};
`;

const ActionText = styled.span`
  display: inline-block;
  height: 32px;
  line-height: 32px;
`;

const PeerSuggestionsListItem = class extends React.Component {
  getAcceptCopy = () => {
    const { numPeersInvited } = this.props;
    switch (numPeersInvited) {
    case 1: return 'Success!';
    case 2: return 'Nice!';
    case 3: return 'Good Job!';
    case 4: return 'Great Job!';
    case 5: return 'Awesome!';
    default: return 'Amazing!';
    }
  }

  getRenderTransitionText = ({
    isAccepting,
    isRejecting,
  }) => () => (
    <React.Fragment>
      {isAccepting && (<ActionText>{this.getAcceptCopy()}</ActionText>)}
      {isRejecting && (<ActionText>Dismissed!</ActionText>)}
    </React.Fragment>
  )

  renderAvatar = () => {
    const {
      peer,
      renderProfileCard,
    } = this.props;
    return renderProfileCard({
      person: peer,
      anchor: (
        <Avatar
          size={avatarSize}
          url={peer.photoUrl}
          email={peer.email}
        />
      ),
    });
  }

  renderPeerContent = () => {
    const {
      peer,
      renderProfileCard,
    } = this.props;
    return (
      <React.Fragment>
        <NoWrapText
          color="black"
          fontSize={14}
          bold
        >
          {renderProfileCard({
            person: peer,
            anchor: peer.fullName || peer.email,
          })}
        </NoWrapText>
      </React.Fragment>
    );
  }

  render = () => {
    const {
      children,
      handlePickPeer,
      handleDismissSuggestion,
    } = this.props;
    const isAccepting = this.props.peerStatus === peerStatus.isAccepting;
    const isAccepted = this.props.peerStatus === peerStatus.isAccepted;
    const isRejecting = this.props.peerStatus === peerStatus.isRejecting;
    const isRejected = this.props.peerStatus === peerStatus.isRejected;
    const isDisabled = isAccepted || isRejected || isRejecting || isAccepting;
    return (
      <StyledListItem
        flexWrap="nowrap"
        peerStatus={this.props.peerStatus}
      >
        {children({
          isAccepting,
          isAccepted,
          isRejecting,
          isRejected,
          isDisabled,
          handlePickPeer,
          handleDismissSuggestion,
          renderTransitionText: this.getRenderTransitionText({
            isAccepting,
            isRejecting,
          }),
          renderPeerContent: this.renderPeerContent,
          renderAvatar: this.renderAvatar,
        })}
      </StyledListItem>
    );
  }
};

PeerSuggestionsListItem.propTypes = {
  peer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    fullName: PropTypes.string,
  }).isRequired,
  handlePickPeer: PropTypes.func.isRequired,
  handleDismissSuggestion: PropTypes.func.isRequired,
  peerStatus: PropTypes.string.isRequired,
  numPeersInvited: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
  renderProfileCard: PropTypes.func.isRequired,
};

export default PeerSuggestionsListItem;
