"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.shadows = void 0;
const shadows = {
  60: '0px 1px 1px rgba(28, 28, 33, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.08)',
  50: '0px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.15)',
  47: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  45: '0px 1px 1px rgba(0, 0, 0, 0.08)',
  40: '0px 1px 0px rgba(0, 0, 0, 0.08)',
  flow: '0px 24px 40px -16px rgba(0, 0, 0, 0.16)'
};
exports.shadows = shadows;
var _default = shadows;
exports.default = _default;