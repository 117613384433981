import React from 'react';
import PropTypes from 'prop-types';
import DocumentHead from 'components/atoms/DocumentHead';
import SideNav from 'components/SideNav/SideNav';
import MobileNav from 'components/SideNav/MobileNav';
import Page from 'components/Page/Page';
import { AppFooter } from 'modules';
import TopBar from 'components/TopBar/TopBar';

const AppLayout = (props) => {
  const {
    footer,
    pageTitle,
    showFooter,
    sideColumn,
    sideColumnSubcontent,
    layout,
    ...otherProps
  } = props;

  const documentHead = pageTitle ? <DocumentHead title={pageTitle} /> : null;

  const pageProps = {
    header: (
      <>
        <SideNav />
        <MobileNav />
      </>
    ),
    footer: showFooter ? footer || <AppFooter /> : null,
    sideColumn,
    sideColumnSubcontent,
    layout,
    ...otherProps,
  };

  return (
    <>
      <TopBar />
      {documentHead}
      <Page {...pageProps} />
    </>
  );
};

AppLayout.Layouts = Page.Layouts;

AppLayout.defaultProps = {
  headerProps: {},
  showFooter: true,
  showUpgradeModal: false
};

AppLayout.propTypes = {
  /** Combines the side column and footer contents for the old layout. */
  combineSideColumns: PropTypes.bool,
  /** Additional props for the header component. */
  headerProps: PropTypes.object,
  /** Footer Content */
  footer: PropTypes.node,
  /** Fallback layout version. */
  oldLayoutVersion: PropTypes.number,
  /** FOrce layout version. */
  overrideLayoutVersion: PropTypes.number,
  /** Page title to display in document header. */
  pageTitle: PropTypes.string,
  /** If no footer content is passed, AppFooter is shown. */
  showFooter: PropTypes.bool,
  /** Side Column Content */
  sideColumn: PropTypes.node,
  /** Side Column Extra Content */
  sideColumnSubcontent: PropTypes.node,
  /** No margin around main column */
  noSpacing: PropTypes.bool
};

export default AppLayout;
