import PropTypes from 'prop-types';
import omitStyled from '../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

const FullWidth = omitStyled('div', ['onlyInMobile'])`
  margin-left: -${() => theme.sizes.padding[60]};
  margin-right: -${() => theme.sizes.padding[60]};

  ${({ onlyInMobile }) => onlyInMobile && theme.media.medium`
    margin-left: 0;
    margin-right: 0;
  `}
`;

FullWidth.defaultProps = {
  onlyInMobile: false,
};

FullWidth.propTypes = {
  onlyInMobile: PropTypes.bool,
};

export default FullWidth;
