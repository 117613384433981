import React from 'react';
import PropTypes from 'prop-types';
import {
  PanelHeader,
  PanelHeaderActions,
  PanelHeaderContainer,
  PanelSubHeader,
} from './styles';

const Header = (props) => {
  const { header, headerActions, subHeader } = props;

  if (!header && !headerActions) {
    return null;
  }

  return (
    <>
      <PanelHeaderContainer>
        <PanelHeader>{header}</PanelHeader>
        <PanelHeaderActions>{headerActions}</PanelHeaderActions>
      </PanelHeaderContainer>
      {subHeader && <PanelSubHeader>{subHeader}</PanelSubHeader>}
    </>
  );
};

Header.propTypes = {
  /** Header text of the panel. */
  header: PropTypes.string,
  /** Header actions content. */
  headerActions: PropTypes.node,
  /** Sub Header text of the panel. */
  subHeader: PropTypes.string,
};

export default Header;
