import React from 'react';
import PropTypes from 'prop-types';
import { AddSkillType } from './prop-types';
import { normalizedSkillsMap } from '../../../common/libs/skills-map';
import AddListUnit from './AddListUnit';

class AddList extends React.PureComponent {
  filterSkillsToAdd = ({ skill }) => {
    const name = skill.name.trim().toLowerCase();
    const skillInSkillsMap = normalizedSkillsMap[name];
    const isWithIcon = skillInSkillsMap && skillInSkillsMap.svg;
    return isWithIcon;
  };

  render = () => {
    const {
      onClickItem,
      userSkills,
      skillsToAdd,
      renderSkillActions,
      filterNonIconSkills,
    } = this.props;
    const filteredSkillsToAdd = filterNonIconSkills ? skillsToAdd.filter(this.filterSkillsToAdd) : skillsToAdd;
    return (
      <div data-rc="recommended-skills-list">
        <AddListUnit
          onClickItem={onClickItem}
          skills={filteredSkillsToAdd}
          renderSkillActions={renderSkillActions}
        />
        <AddListUnit
          onClickItem={onClickItem}
          skills={userSkills}
          renderSkillActions={renderSkillActions}
        />
      </div>
    );
  };
}

AddList.propTypes = {
  userSkills: PropTypes.arrayOf(AddSkillType).isRequired,
  skillsToAdd: PropTypes.arrayOf(AddSkillType).isRequired,
  renderSkillActions: PropTypes.func.isRequired,
  filterNonIconSkills: PropTypes.bool,
  onClickItem: PropTypes.func,
};

AddList.defaultProps = {
  filterNonIconSkills: false,
};

export default AddList;
