import React, { useState } from 'react';
import DialogContext from './DialogContext';
import { Confirm } from '@matterapp/matter-ui';

export default function DialogProvider({ children }) {
  const [dialogProps, setDialogProps] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { 
    onClose = () => {}, 
    onConfirm, 
    header,
    subHeader,
    confirmButtonLabel = 'Yes, Proceed',
    cancelButtonLabel = 'Cancel'
  } = dialogProps || {};

  function closeModal() {
    setIsOpen(false);
    onClose();
  }

  return (
    <DialogContext.Provider
      value={{
        openDialog: (props) => {
          setDialogProps(props);
          setIsOpen(true);
        }
      }}
    >
      {children}
      <Confirm
        header={header}
        subHeader={subHeader}
        cancelButtonLabel={cancelButtonLabel}
        confirmButtonLabel={confirmButtonLabel}
        confirmButtonIsDestructive
        isOpen={isOpen}
        onClose={closeModal}
        onClickCancel={closeModal}
        onClickConfirm={() => {
          onConfirm();
          closeModal();
        }}
      />
    </DialogContext.Provider>
  );
}
