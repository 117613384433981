import React from 'react';
import { Confirm } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import deleteCustomKudos from 'graphql-queries/mutations/workspaces/deleteCustomKudos';
import { GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE } from 'graphql-queries';

function ConfirmDeleteKudos({
  isOpen,
  onClose,
  customKudosId,
  workspaceId
}) {
  const [deleteCustomKudosMutation] = useMutation(deleteCustomKudos);

  async function handleDeleteKudos() {
    await deleteCustomKudosMutation({
      variables: {
        id: customKudosId
      },
      refetchQueries: [{ 
        query: GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE,
        variables: {
          workspaceId
        }
      }]
    });
    onClose();
  }

  return (
    <Confirm
      header='Are you sure?'
      subHeader='When you delete a Kudos. It will no longer be accessible to your team.'
      isOpen={isOpen}
      confirmButtonLabel='Yes, Delete'
      cancelButtonLabel='Cancel'
      onClickCancel={onClose}
      onClickConfirm={handleDeleteKudos}
    />
  );
}

ConfirmDeleteKudos.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  customKudosId: PropTypes.string,
  workspaceId: PropTypes.string
};

export default ConfirmDeleteKudos;
