import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import PageContent from '../../../../PageContent';

const PageContainer = styled.div`
  display: flex;
  max-width: ${() => theme.sizes.webClient.maxContentWidth};
  width: 100%;
  margin: 0 auto;

  ${() => theme.media.medium`
    margin-top: 48px;
  `}
`;

const PageContentWithBackground = styled(PageContent)`
  background: ${() => theme.colors.blacks[5]};
  box-sizing: content-box;
  min-height: calc(100% - 112px);
  padding-bottom: 100px;

  ${() => theme.media.medium`
    min-height: calc(100% - 64px);
    box-sizing: content-box;
  `}
`;

const SideColumnOnlyLargeBreakpoint = styled.div`
  display: none;

  ${() => theme.media.large`
    display: initial;
  `}
`;

const SideColumnOnlyNonLargeBreakPoint = styled.div`
  display: initial;

  ${() => theme.media.large`
    display: none;
  `}
`;

const MainBox = styled.div`
  width: 100%;
  margin-right: 0;
  order: 1;
  margin-top: 24px;

  ${() => theme.media.medium`
    width: 100%;
    margin-right: 0;
    order: 1;
    margin-top: 0;
  `}

  ${() => theme.media.large`
    width: 608px;
    margin-right: 32px;
    order: 0;
    margin-top: 0;
  `}
`;

const SideBox = styled.div`
  width: 100%;
  order: 0;

  ${() => theme.media.medium`
    width: 100%;
    order: 0;
  `}

  ${() => theme.media.large`
    width: 352px;
    order: 1;
  `}
`;

const AppAuthenticatedV2Profile = ({
  banner,
  header,
  footer,
  mainColumn,
  sideColumn,
  sideColumnSubcontent,
  className,
}) => (
  <React.Fragment>
    {header}
    <PageContentWithBackground className={className}>
      {banner}
      <PageContainer style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
        <MainBox>{mainColumn}</MainBox>
        <SideBox>
          {sideColumn}
          <SideColumnOnlyLargeBreakpoint>
            {sideColumnSubcontent}
            {footer}
          </SideColumnOnlyLargeBreakpoint>
        </SideBox>
      </PageContainer>
      <SideColumnOnlyNonLargeBreakPoint>
        {sideColumnSubcontent}
        {footer}
      </SideColumnOnlyNonLargeBreakPoint>
    </PageContentWithBackground>
  </React.Fragment>
);

AppAuthenticatedV2Profile.defaultProps = {
  banner: null,
  header: null,
  footer: null,
  mainColumn: null,
  sideColumn: null,
  className: PropTypes.string,
};

AppAuthenticatedV2Profile.propTypes = {
  banner: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  mainColumn: PropTypes.node,
  sideColumn: PropTypes.node,
};

export default AppAuthenticatedV2Profile;
