import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../../Dropdown/Multiple';
import { SKILL_FILTER } from '../consts';

export const formatSkillsForDropdown = (skills = []) =>
  skills.map((currentSkill) => {
    if (currentSkill.skill) {
      return {
        ...currentSkill,
        label: currentSkill.skill.name,
        value: `${currentSkill.skill.id}`,
      };
    }
    return {
      ...currentSkill,
      label: currentSkill.name,
      value: `${currentSkill.id}`,
    };
  });

const Skill = (props) => {
  const { skills, ...otherProps } = props;
  return (
    <Dropdown
      {...otherProps}
      displayValue={SKILL_FILTER.DROPDOWN_LABEL}
      items={formatSkillsForDropdown(skills)}
      menuProps={SKILL_FILTER.MENU_PROPS}
    />
  );
};

Skill.defaultProps = {
  skills: [],
};

Skill.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

export default Skill;
