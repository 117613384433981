import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../SVG';

const Icon = ({ className, fill }) => (
  <SVG className={className} width={24} height={24} viewBox="0 0 24 24">
    <path
      fill={fill}
      d="M24,12.8C23.5,19.1,18.4,24,12,24c0,0,0,0,0,0s0,0,0,0c-0.1,0-0.1,0-0.2,0c-5.6-0.1-10.2-4-11.4-9.2
        C0.1,13.9,0,13,0,12c0-1.2,0.2-2.4,0.5-3.6c1.2-3.8,4.1-6.7,7.9-7.9C9.6,0.2,10.8,0,12,0c1.2,0,2.3,0.2,3.3,0.5
        c4.6,1.3,8.1,5.4,8.6,10.3c0,0.4,0.1,0.8,0.1,1.2C24,12.3,24,12.5,24,12.8z"
    />
    <path
      fill={'#FFFFFF'}
      d="M18.2,9.8c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5C17.6,8.2,18.2,8.9,18.2,9.8z M8.8,9.8
        c0-0.8-0.7-1.5-1.5-1.5S5.7,8.9,5.7,9.8s0.7,1.5,1.5,1.5S8.8,10.6,8.8,9.8z M16.3,14.4c-2.4,2.1-6.2,2.1-8.6,0
        c-0.3-0.2-0.7-0.2-0.9,0.1c-0.2,0.3-0.2,0.7,0.1,0.9c1.5,1.3,3.3,1.9,5.2,1.9c1.9,0,3.7-0.6,5.2-1.9c0.3-0.2,0.3-0.7,0.1-0.9
        C17,14.2,16.6,14.2,16.3,14.4z"
    />
  </SVG>
);

Icon.defaultProps = {
  fill: '#05A164',
};

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Icon;
