import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 0C0.671573 0 0 0.671573 0 1.5V10.5C0 11.3284 0.671573 12 1.5 12H16.5C17.3284 12 18 11.3284 18 10.5V1.5C18 0.671573 17.3284 0 16.5 0H1.5ZM3.97495 2.91955C3.65437 2.65726 3.18185 2.70451 2.91955 3.02509C2.65726 3.34568 2.70451 3.81819 3.02509 4.08049L9.00002 8.96907L14.975 4.08049C15.2955 3.81819 15.3428 3.34568 15.0805 3.02509C14.8182 2.70451 14.3457 2.65726 14.0251 2.91955L9.00002 7.03098L3.97495 2.91955Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#2E2E33',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
