import styled from 'styled-components';
import Button from './Default';
import theme from '@matterapp/matter-theme';

const getColorStyles = ({ disabled }) => {
  if (!disabled) {
    return `
      background: ${theme.colors.white};
      border-color: transparent;
      color: ${theme.colors.blacks[70]};

      &:hover, &:active {
        border-color: ${theme.colors.blacks[40]};
        color: ${theme.colors.blacks[70]};
      }
    `;
  }
  return 'border: none;';
};

const MinimalTextButton = styled(Button)`
  ${getColorStyles}
`;

export default MinimalTextButton;
