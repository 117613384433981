import React, { useState, useEffect } from 'react';
import { Modal, Dropdown } from '@matterapp/matter-ui';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import { useLazyQuery } from '@apollo/client';
import { 
  StyledModalBody, 
  StyledGroup
} from './CustomAllowanceSection/styles';
import { getDropdownInviteMessage } from 'hooks/composer/shared';

export default function AddMembersModal(props) {
  const { 
    workspace, 
    isOpen, 
    onClose, 
    tenant, 
    existingMembers, 
    addMembers
  } = props;
  const { id: tenantId } = tenant;
  const { slackWorkspace } = workspace || {};
  const [searchMembers, { loading: isLoadingResults, data }] = useLazyQuery(searchWorkspaceMembers);
  const [selectedMembers, updateSelectedMembers] = useState([]);
  const [canSave, updateCanSave] = useState(false);

  const primaryChannel = slackWorkspace?.primaryChannel;
  const existingPersonIds = existingMembers.map(({ person }) => String(person.id));

  function resetAndClose() {
    updateSelectedMembers([]);
    onClose();
  }

  async function onSearch(value) {
    if (value) {
      searchMembers({
        variables: {
          tenantId,
          searchString: value
        }
      });
    }
  }

  function onChange(_, { value: updatedList }) {
    if (updatedList.length < selectedMembers.length) {
      const newMembers = selectedMembers.filter(({ value }) => updatedList.find(email => email === value));
      updateSelectedMembers(newMembers);
    }
  }

  function onSelect(_, item ) {
    if (item) {
      updateSelectedMembers([...selectedMembers, item.item]);
    }
  }

  function formatMembers(members) {
    return members.map((member) => {
      return {
        ...member,
        label: member.person.fullName,
        subLabel: member.person.email,
        value: member.person.email
      };
    });
  }
  
  useEffect(() => {
    if (!selectedMembers.length) {
      updateCanSave(false);
    } else {
      updateCanSave(true);
    }
  }, [selectedMembers]);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={resetAndClose}
      size="large"
    >
      <Modal.Header
        header="Add members"
        right={<Modal.Buttons.CloseIcon onClick={resetAndClose} />}
      />
      <StyledModalBody>
        <StyledGroup dividerBelow>
          <Dropdown.Email.Tags
            filterItems
            formatTagLabel={(email) => selectedMembers.find((member) => member.value === email)?.label}
            items={formatMembers(data?.searchMembers.filter(({ person }) => !existingPersonIds.includes(String(person.id))) || [])}
            showValuesAsSubLabels
            isValidValue={() => true}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={onSelect}
            isLoadingResults={isLoadingResults}
            emptyMenuMessageNoItems={getDropdownInviteMessage(primaryChannel)}
            placeholder="Enter name(s)"
            fluid
            isInitiallyPristine
          />
        </StyledGroup>
      </StyledModalBody>
      <Modal.Footer.WithCancelSave
        canClickSave={canSave}
        onClickCancel={resetAndClose}
        onClickSave={() => {
          addMembers(selectedMembers.map(({ person }) => person.id));

          resetAndClose();
        }}
      />
    </Modal>
  );
}
