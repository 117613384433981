import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import UserContext from 'context/UserContext/UserContext';
import syncHrisMutation from 'graphql-queries/mutations/hris/syncHris';
import { 
  Box,
  Typography,
  Link
} from '@mui/material';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Button } from '@matterapp/matter-ui';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.blues[50],
  cursor: 'pointer',
  textDecoration: 'none'
}));

const GreenWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.greens[5],
  padding: theme.spacing(2),
  borderRadius: '4px'
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  '& button': {
    marginLeft: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    '& button': {
      marginBottom: theme.spacing(2),
      marginLeft: 0
    }
  }
}));

export default function HrisSyncStatus({ timezone, connectHris, initialConnect }) {
  const { currentWorkspace, currentTenant, refetchCurrentUser } = useContext(UserContext);
  const { hrisSettings } = currentTenant || {};
  const { lastSync } = hrisSettings || {};
  const { date: lastSyncedAt, status: lastSyncStatus } = lastSync || {};
  const [syncHris, { loading: syncing }] = useMutation(syncHrisMutation);
  const navigate = useNavigate();
  let statusHeader;
  let syncBody;
  let syncDateParsed;
  
  if (lastSyncedAt) {
    syncDateParsed = moment(parseInt(lastSyncedAt)).tz(timezone).format('h:mm A on MMM DD, YYYY');
  }

  if (initialConnect) {
    statusHeader = 'We are preparing your data...';
    syncBody = (
      <Typography variant='body1'>
        Come back later to start a manual sync or wait for the daily automatic sync.
      </Typography>
    );
  } else if (lastSyncStatus === 'in_progress') {
    statusHeader = 'Your sync is in progress...';
    syncBody = (
      <Typography variant='body1'>
        Your automatic sync is scheduled. Matter works to sync with your HRIS platform every 24 hours.
      </Typography>
    );
  } else if (lastSyncStatus === 'failed') {
    statusHeader = 'Your sync failed.';
    syncBody = (
      <>
        <Typography variant='body1' sx={{ color: 'reds.50', mt: 3, mb: 3 }}>
          <b>Your last sync failed at {syncDateParsed}.</b>
        </Typography>
        <Typography variant='body1'>
          It’s us, not you! First, let us reassure you that your data is safe and nothing has been changed. We’d like to get things back on track for you, so please contact support.
        </Typography>
      </>
    );
  } else if (lastSyncStatus === 'success' || lastSyncStatus === 'warning') {
    statusHeader = 'Your sync is complete!';
    syncBody = (
      <>
        <Typography variant='body1'>
          Want to see what was updated? Check the&nbsp; 
          <StyledLink 
            onClick={() => {
              navigate(Resources.hrisSyncHistory.path({ workspaceId: currentWorkspace?.id }));
            }}>
              sync history.
          </StyledLink>
        </Typography>
        <Typography variant='body1' sx={{ mt: 3, mb: 3 }}>
          Your last sync completed at {syncDateParsed}.
        </Typography>
        <Typography variant='body1'>
          Your next sync is scheduled and will run automatically.
        </Typography>
      </>
    );
  }

  return (
    <Box>
      <GreenWrapper>
        <Typography variant='body1'>
          <b>{statusHeader}</b>
        </Typography>
        {syncBody}
      </GreenWrapper>
      <ButtonsWrapper>
        <Button 
          size={'S'} 
          color={'black'} 
          disabled={syncing || !lastSyncStatus || lastSyncStatus === 'in_progress'} 
          onClick={async () => {
            await syncHris({ variables: { tenantId: currentTenant.id }});

            setTimeout(async () => {
              await refetchCurrentUser();
            }, 20000);
          }}
        >
          Start Sync Now
        </Button>
        <Button size={'S'} color={'black'} onClick={() => {
          navigate(Resources.hrisSyncHistory.path({ workspaceId: currentWorkspace?.id }));
        }}>
          View Sync History
        </Button>
        <Button size={'S'} color={'black'} onClick={connectHris}>
          Reconnect HRIS
        </Button>
      </ButtonsWrapper>
    </Box>
  );

};
