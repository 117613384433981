import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import CustomRewardsPage from './CustomRewards';

export default function CustomRewards() {
  const { 
    currentTenant, 
    currentWorkspaceId,
  } = useContext(UserContext);
  const { id: tenantId } = currentTenant || {};

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        <CustomRewardsPage  
          tenantId={tenantId}
          workspaceId={currentWorkspaceId}
        />
      }
    />
  );
};
