import { compose, withProps, lifecycle } from 'recompose';
import withRouter from 'hocs/withRouter';
import withMagicTokenLinkLogin from 'hocs/auth/withMagicTokenLinkLogin';
import Toast from 'components/Toast/Toast';
import { Resources } from '@matterapp/routing';

async function componentDidMount() {
  await this.props.handleLoginAttempt();
}

const withData = compose(
  withRouter,
  withMagicTokenLinkLogin,
  withProps(
    ({
      handleMagicLinkLogin,
      history,
      nextStepPath,
      token,
      fallbackPath
    }) => ({
      handleLoginAttempt: async () => {
        await handleMagicLinkLogin({
          magicLinkToken: token,
          handleSuccess: (redirectToWorkspaceId) => {
            if (redirectToWorkspaceId) {
              const workspaceRedirect = Resources.workspacesActivityFeed.path({
                workspaceId: redirectToWorkspaceId
              });
              history.push(workspaceRedirect);
            } else {
              history.push(nextStepPath);
            }
          },
          handleFailure: () => {
            history.push(fallbackPath);
            Toast.error('Something went wrong');
          },
        });
      },
    })
  ),
  lifecycle({
    componentDidMount,
  })
);

export default withData(() => null);
