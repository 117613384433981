"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.overflowScrollShadow = void 0;

var _colors = require("../colors");

var _spacing = require("../spacing");

/**
 * Mixin for creating vertical overflow shadows in a scrolling container.
 * @param {*} props : {
 *   backgroundColor (String): The color of the container background.
 *   size (Number): The size of the shadow in pixels.
 * }
 */
const overflowScrollShadow = props => {
  const {
    backgroundColor = _colors.colors.white,
    size
  } = props || {};
  const shadowSize = size || _spacing.spacing.single;
  const shadowHalfSize = size ? `calc(${size} / 2)` : _spacing.spacing.half;
  return `
    background: 
      linear-gradient(to top, ${backgroundColor}, ${backgroundColor}),
      linear-gradient(to top, ${backgroundColor}, ${backgroundColor}),
      linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
    background-position: bottom center, top center, bottom center, top center;
    background-color: ${backgroundColor};
    background-repeat: no-repeat;
    background-size: 100% ${shadowSize}, 100% ${shadowSize}, 100% ${shadowHalfSize}, 100% ${shadowHalfSize};
    background-attachment: local, local, scroll, scroll;
  `;
};

exports.overflowScrollShadow = overflowScrollShadow;
var _default = overflowScrollShadow;
exports.default = _default;