import React from 'react';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import omitStyled from '../../libs/omitStyled';

import Field from './Field';
import Group from './Group';
import Error from './Error';
import { Input } from '../Input';
import InlineGroup from './InlineGroup';

const StyledForm = omitStyled('form', ['fullScreen', 'centered'])`
  ${({ centered }) => centered && `
    text-align: center;
  `}

  ${({ fullScreen }) => fullScreen && `
    width: 100%;
    height: 100%;

    .field {
      padding: 24px;
    }

    .field textarea {
      border: none;
      font-size: 21px;
      line-height: 28px;
      padding: 0;
    }

    ${theme.media.breakpoint(1200)`
      display: flex;
      align-items: center;

      .field {
        width: 100%;
        align-items: center;
        height: max-content;
      }
      .field textarea {
        width: 600px;
      }
    `}
    }
  `}
`;

const Form = ({
  children,
  onSubmit,
  action,
  fullScreen,
  display,
  centered,
}) => (
  <StyledForm
    fullScreen={fullScreen}
    centered={centered}
    onSubmit={onSubmit}
    action={action}
    style={{
      display,
    }}
  >
    {children}
  </StyledForm>
);

Form.Field = Field;
Form.Group = Group;
Form.Input = Input;
Form.Error = Error;
Form.InlineGroup = InlineGroup;

Form.defaultProps = {
  children: <div />,
  onSubmit: () => false,
  action: '',
  fullScreen: false,
  display: 'block',
  centered: false,
};

Form.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  action: PropTypes.string,
  display: PropTypes.string,
  fullScreen: PropTypes.bool,
  centered: PropTypes.bool,
};

export default Form;
