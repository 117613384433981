import React from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import Workspaces, { WORKSPACE_NAV_PROPS } from './Workspaces';
import { TabBarDockOnMobile } from './styles';
import { menuItemLabels } from '../consts';

const renderTabs = ({
  activeTab,
  isCompact,
  indexOffset = 0,
  tabs,
  returnObjectDef,
  workspacesProps = {},
}) => {
  let hasActiveTab = false;
  const renderedTabs = tabs.map((tab, i) => {
    const isActive = i + indexOffset === activeTab;
    if (isActive && !hasActiveTab) {
      hasActiveTab = true;
    }

    const isDisabled = workspacesProps.activeWorkspace?.isExpired && (tab.label === menuItemLabels.PROFILE || tab.label === menuItemLabels.REQUEST); 
    return (
      <Tab
        {...tab}
        index={i + indexOffset}
        key={`${tab.label}-${i}-tab`}
        isActive={isActive}
        isCompact={isCompact}
        isDisabled={isDisabled}
        workspacesProps={workspacesProps}
      />
    );
  });
  return returnObjectDef ? { activeTab, hasActiveTab, indexOffset, tabs: renderedTabs } : renderedTabs;
};

const Tabs = (props) => {
  const { onCloseProfileMenu, workspacesProps } = props;
  const workspacesIsActive = !!workspacesProps.activeId;

  return (
    <TabBarDockOnMobile onClick={onCloseProfileMenu}>
      {workspacesIsActive ? (
          <>
            <Workspaces.NonAdminTabs {...workspacesProps} />
            {workspacesProps.isAdminOrOwner && <Workspaces.AdminTabs {...workspacesProps} />}
          </>
      ) : (
        renderTabs(props)
      )}
    </TabBarDockOnMobile>
  );
};

Tabs.renderTabs = renderTabs;

Tabs.propTypes = {
  workspacesProps: PropTypes.shape(WORKSPACE_NAV_PROPS),
};

export { renderTabs };

export default Tabs;
