import React from 'react';
import styled from 'styled-components';
import SVG from '../../SVG';
import * as S from '../Elements/SpaceThree';

const DEFAULT_HEIGHT = 1190;
const DEFAULT_WIDTH = 1280;
const DEFAULT_VIEWBOX = `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`;

const BackgroundContainer = styled.div`
  background: ${(props) => props.theme.colors.blacks[5]};
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 0;
  bottom: 0;

  & svg {
    height: 100%;
    width: 100%;
  }
`;

const SpaceThree = (props) => (
  <BackgroundContainer {...props}>
    <SVG
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      preserveAspectRatio="xMidYMax slice"
      viewBox={DEFAULT_VIEWBOX}
    >
      <S.BottomLine />
      <S.BottomDot1 />
      <S.BottomDot2 />
      <S.BottomDot3 />
      <S.BottomDot4 />
      <S.BottomRing1 />
      <S.BottomRing2 />
      <S.BottomRing3 />
      <S.BottomRing4 />
      <S.TopRightDot />
      <S.RightDot1 />
      <S.RightDot2 />
      <S.RightDot3 />
      <S.RightDot4 />
      <S.RightDot5 />
      <S.RightDot6 />
      <S.RightDot7 />
      <S.RightDot8 />
      <S.RightDot9 />
      <S.RightDot10 />
      <S.RightDot11 />
      <S.RightDot12 />
      <S.RightDot13 />
      <S.RightDot14 />
      <S.RightDot15 />
      <S.RightRing1 />
      <S.RightRing2 />
      <S.RightRing3 />
      <S.LeftDot1 />
      <S.LeftDot2 />
      <S.LeftDot3 />
      <S.LeftDot4 />
      <S.LeftDot5 />
      <S.LeftDot6 />
      <S.LeftDot7 />
      <S.LeftDot8 />
      <S.LeftDot9 />
      <S.LeftDot10 />
      <S.LeftDot11 />
      <S.LeftDot12 />
      <S.LeftDot13 />
      <S.LeftTear1 />
      <S.LeftTear2 />
      <S.LeftLine />
      <S.LeftRing1 />
      <S.LeftRing2 />
      <S.LeftRing3 />
      <S.LeftRing4 />
      <S.LeftRing5 />
      <S.CenterDot />
      <S.TopLine />
      <S.TopRightRing />
      <S.TopLeftRing />
      <S.BottomRightPlanet />
      <S.BottomLeftPlanet />
    </SVG>
  </BackgroundContainer>
);

export default SpaceThree;
