import React from 'react';
import PropTypes from 'prop-types';
import From from './FilterDropdowns/From';
import Type from './FilterDropdowns/Type';
import Skill from './FilterDropdowns/Skill';
import Panel from '../../Panel';
import FilterIcon from './FilterIcon';
import UpgradeMessage from './UpgradeMessage';
import {
  ClearButton,
  ClearContainer,
  FilterContainer,
  HeaderContainer,
  Header,
} from './styles';
import { FEEDBACK_FILTER, FEEDBACK_TYPES, FILTER_TYPES, TYPE_KUDOS } from './consts';
import { useFilterState } from './hooks';

const NOOP = () => null;
const EMPTY_VALUE = {
  from: [],
  type: [],
  skill: [],
};

const Feedback = (props) => {
  const {
    disableKudosOnSkillSelect,
    members,
    onChange,
    onClickApplyFilter,
    onClickClearFilters,
    onCloseMenu,
    panelProps,
    skills,
    value,
  } = props;

  const [fromFilters, setFromFilters, clearFromFilters] = useFilterState(
    value.from,
    FILTER_TYPES.FROM
  );
  const [typeFilters, setTypeFilters, clearTypeFilters] = useFilterState(
    value.type,
    FILTER_TYPES.TYPE
  );
  const [skillFilters, setSkillFilters, clearSkillFilters] = useFilterState(
    value.skill,
    FILTER_TYPES.SKILL
  );

  const hasKudosFilter = typeFilters.includes(TYPE_KUDOS.value);
  const hasSkillFilter = !!skillFilters.length;
  const hasFilters =
    !!fromFilters.length || !!typeFilters.length || hasSkillFilter;

  const getOnChangeFilter = (callback = NOOP, changeType = '') => (e, params) => {
    const updatedValue = callback(e, params);
    onChange(e, {
      ...props,
      changeType,
      value: {
        from: fromFilters,
        type: typeFilters,
        skill: skillFilters,
        ...updatedValue,
      },
    });
  };

  return (
    <Panel.Base {...panelProps}>
      <HeaderContainer>
        <Header>
          <FilterIcon />
          {FEEDBACK_FILTER.HEADER}
        </Header>
        <ClearContainer show={hasFilters}>
          <ClearButton
            onClick={(e) => {
              clearFromFilters();
              clearTypeFilters();
              clearSkillFilters();
              onChange(e, { ...props, value: EMPTY_VALUE });
              onClickClearFilters(e, { ...props, value: EMPTY_VALUE });
            }}
          >
            {FEEDBACK_FILTER.CLEAR_BUTTON}
          </ClearButton>
        </ClearContainer>
      </HeaderContainer>
      <FilterContainer>
        <Type
          onChange={getOnChangeFilter(setTypeFilters, FILTER_TYPES.TYPE)}
          value={typeFilters}
          isKudosDisabled={disableKudosOnSkillSelect ? hasSkillFilter : false}
        />
        <From
          members={members}
          onChange={getOnChangeFilter(setFromFilters, FILTER_TYPES.FROM)}
          onClickApply={onClickApplyFilter}
          onMenuClose={onCloseMenu}
          value={fromFilters}
        />
        <Skill
          skills={skills}
          onChange={getOnChangeFilter(setSkillFilters, FILTER_TYPES.SKILL)}
          onClickApply={onClickApplyFilter}
          onMenuClose={onCloseMenu}
          value={skillFilters}
          disabled={hasKudosFilter}
        />
      </FilterContainer>
    </Panel.Base>
  );
};

Feedback.feedbackTypes = FEEDBACK_TYPES;
Feedback.filterTypes = FILTER_TYPES;

Feedback.UpgradeMessage = UpgradeMessage;

Feedback.defaultProps = {
  disableKudosOnSkillSelect: false,
  members: [],
  onChange: NOOP,
  onClickApplyFilter: NOOP,
  onClickClearFilters: NOOP,
  onCloseMenu: NOOP,
  panelProps: {
    emphasize: true,
    firstOnMobile: true,
  },
  skills: [],
  value: EMPTY_VALUE,
};

Feedback.propTypes = {
  disableKudosOnSkillSelect: PropTypes.bool,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
      userId: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  onClickApplyFilter: PropTypes.func,
  onClickClearFilters: PropTypes.func,
  onCloseMenu: PropTypes.func,
  onClickUpgrade: PropTypes.func,
  panelProps: PropTypes.shape({
    emphasize: PropTypes.bool,
    firstOnMobile: PropTypes.bool,
  }),
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  upgradeMessage: PropTypes.string,
  value: PropTypes.shape({
    from: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.arrayOf(PropTypes.string),
    skill: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default Feedback;
