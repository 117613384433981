import moment from 'moment';

export const dateFilters = [
  {
    label: 'This Week',
    value: 'this week',
    startDate: moment().startOf('week').add(1, 'day'),
    endDate: moment().endOf('week').add(1, 'day'),
    groupedBy: 'GROUPED BY DAY',
    groupBy: 'day',
    xAxisResolution: 7,
    visibleOnMobile: true
  },
  {
    label: 'Last Week',
    value: 'last week',
    startDate: moment().add(-1, 'week').startOf('week').add(1, 'day'),
    endDate: moment().add(-1, 'week').endOf('week').add(1, 'day'),
    groupedBy: 'GROUPED BY DAY',
    groupBy: 'day',
    xAxisResolution: 7,
    visibleOnMobile: false
  },
  {
    label: 'This Month',
    value: 'this month',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    groupedBy: 'GROUPED BY WEEK',
    groupBy: 'week',
    xAxisResolution: 5,
    visibleOnMobile: true
  },
  {
    label: 'Last Month',
    value: 'last month',
    groupBy: 'week',
    startDate: moment().add(-1, 'month').startOf('month'),
    endDate: moment().add(-1, 'month').endOf('month'),
    groupedBy: 'GROUPED BY WEEK',
    xAxisResolution: 5,
    visibleOnMobile: true
  }
];