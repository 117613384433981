import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Avatar,
  Text,
} from '../../components';

import { smallBreakpoint } from './consts';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: -8px;
  margin-bottom: -8px;
  padding-left: 24px;
  padding-right: 24px;

  img {
    display: block;
    margin-right: 8px;
  }

  ${() => theme.media.breakpoint(smallBreakpoint)`
    flex-direction: column;
    text-align: center;
    margin-top: 0;

    span {
      text-align: inherit;
    }

    img {
      margin-right: 0;
      margin-bottom: 8px;
    }
  `};
`;

const AvatarBlock = class extends React.Component {
  renderHeadline = (headline) => {
    if (headline === null) {
      return null;
    }
    return (
      <Text color="purple" textStyle={341} uppercase block>
        {headline}
      </Text>
    );
  }

  render() {
    const {
      photoUrl,
      size,
    } = this.props;
    return (
      <Container>
        {photoUrl && (
          <Avatar
            url={photoUrl}
            size={size}
          />
        )}
      </Container>
    );
  }
};

AvatarBlock.defaultProps = {
  size: 64,
};

AvatarBlock.propTypes = {
  size: PropTypes.number,
  photoUrl: PropTypes.string.isRequired,
};

export default AvatarBlock;
