import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import Starburst from './Starburst';

const HEADER_FOOTER_FIXED_HEIGHT = 180;
const MAX_CONTENT_WIDTH = 420;
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 72px;
  min-height: 480px;
  width: 100%;
  height: 100%;
  max-height: 520px;
  text-align: center;

  ${() => theme.media.medium`
    top: 16vh;
  `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: ${HEADER_FOOTER_FIXED_HEIGHT}px;
  max-height: ${HEADER_FOOTER_FIXED_HEIGHT}px;
`;

const Heading = styled.h1`
  font-weight: bold;
  line-height: 32px;
  font-size: 22px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 144px;
  width: 144px;
  min-height: 144px; // prevent jumpies
  min-width: 144px; // prevent jumpies

  margin-top: 32px;
  margin-bottom: 32px;

  img,
  svg {
    border-radius: 50%;
    animation: pulse-bomb-new 2s 0s ease-out;
    animation-delay: 200ms;
  }

  @keyframes pulse-bomb-new {
    0% {
      box-shadow: 0 0 8px 6px rgba(233, 233, 246, 0),
        0 0 0px 0px rgba(233, 233, 246, 0.2), 0 0 0px 0px rgba(233, 233, 246, 0);
      transform: scale(1);
    }
    10% {
      box-shadow: 0 0 8px 6px rgba(233, 233, 246, 0.2),
        0 0 12px 10px rgba(233, 233, 246, 0.2),
        0 0 12px 14px rgba(233, 233, 246, 0);
      transform: scale(1.08);
    }
    60% {
      box-shadow: 0 0 8px 6px rgba(233, 233, 246, 0),
        0 0 0px 0px rgba(233, 233, 246, 0.2), 0 0 0px 0px rgba(233, 233, 246, 0);
      transform: scale(0.96);
    }
    80% {
      transform: scale(1);
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: ${HEADER_FOOTER_FIXED_HEIGHT}px;
  max-height: ${HEADER_FOOTER_FIXED_HEIGHT}px;
`;

const Message = styled.div`
  max-width: ${MAX_CONTENT_WIDTH}px;
  color: ${() => theme.colors.purples[5]};
  line-height: 24px;
  font-size: 17px;
  padding: 0 20px;
  margin-bottom: 32px;
`;

const StarburstCTA = ({ badge, title, message, primaryButton }) => (
  <React.Fragment>
    <Starburst shockwave />
    <Container>
      <Header>
        <Heading>{title}</Heading>
      </Header>

      <Body>{badge}</Body>

      <Footer>
        <Message>{message}</Message>

        {primaryButton}
      </Footer>
    </Container>
  </React.Fragment>
);

StarburstCTA.defaultProps = {
  primaryButton: null,
};

StarburstCTA.propTypes = {
  primaryButton: PropTypes.node,
  title: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  badge: PropTypes.node.isRequired, // eslint-disable-line
};

export default StarburstCTA;
