import React, { useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { useQuery } from '@apollo/client';
import IS_USER_AN_ADMIN from 'graphql-queries/queries/admin/isUserAnAdmin';
import { Loader } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const LoaderContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export default function AdminRoute({ children }) {
  const { currentUser, isLoadingCurrentUser} = useContext(UserContext);
  const navigate = useNavigate();
  
  const { loading, error, data } = useQuery(IS_USER_AN_ADMIN);

  useEffect(() => {
    if (!isLoadingCurrentUser && !currentUser) {
      navigate('/404');
    }
  }, [isLoadingCurrentUser, currentUser]);

  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (error) {
    navigate('/404');
  }

  if (!data?.isUserAnAdmin) {
    navigate('/404');
  }
  
  return children;
}
