import React from 'react';
import PropTypes from 'prop-types';
import FileUpload from './FileUpload';
import { ACCEPT_TYPES, IMAGE_HEADER, IMAGE_LOADING_HEADER, IMAGE_SUB_HEADER } from './consts';

const Image = (props) => {
  const { url, showImagePreview, showFileNames, showLoading, ...otherProps } = props;
  return (
    <FileUpload
      accept={ACCEPT_TYPES.IMAGE.ANY}
      header={IMAGE_HEADER}
      loadingHeader={IMAGE_LOADING_HEADER}
      subHeader={IMAGE_SUB_HEADER}
      imagePreviewIsCircle
      showImagePreview={showImagePreview}
      showFileNames={showFileNames}
      showLoading={showLoading}
      urls={!!url ? [url] : []}
      {...otherProps}
    />
  );
};

Image.propTypes = {
  /** Since the image uploader cannot prepopulate the field, image url can be passed to show that existing file was selected. */
  url: PropTypes.string,
  showImagePreview: PropTypes.bool,
  showLoading: PropTypes.bool,
  showFileNames: PropTypes.bool,
};

Image.defaultProps = {
  showImagePreview: true,
};

export default Image;
