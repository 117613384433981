import styled from 'styled-components';
import theme, { colors, spacing, media } from '@matterapp/matter-theme';
import { Button, Clickable } from '@matterapp/matter-ui';

export const AnalyticsFilterIconWrapper = styled.div`
  display: block;

  ${media.M`
    display: none;
  `}
`;

export const FilterButton = styled(Button.Primary)(() => {
  return {
    whiteSpace: 'nowrap',
    fontSize: theme.fontSize.base,
    lineHeight: theme.fontSize.base,
    minHeight: 0,
  };
});

export const FilterCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  background: ${colors.white};
  padding: 24px;
  border-top: 1px solid ${colors.blacks[10]};
  border-bottom: 1px solid ${colors.blacks[10]};
  margin-bottom: ${spacing.singleAndHalf};

  ${media.S`
    align-items: baseline;
    justify-content: space-between;
  `}

  ${media.M`
    border-top: none;
    border-radius: ${theme.borderRadius.L};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15), 0px 2px 14px rgba(0, 0, 0, 0.1);
  `}

  ${media.XXL`
    margin: 0;
    margin-bottom: ${spacing.singleAndHalf};
    border-radius: ${theme.borderRadius.L};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15), 0px 2px 14px rgba(0, 0, 0, 0.1);
  `}
`;

export const FilterTextContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: ${spacing.singleAndHalf};
  justify-content: space-between;

  ${media.S`
    margin-right: ${spacing.single};
    margin-bottom: 0;
  `}
`;

export const FilterTab = styled(Clickable)(({ theme, isActive, visibleOnMobile }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  fontSize: theme.font.size.S,
  lineHeight: theme.lineHeight.M,
  textAlign: 'center',
  background: isActive ? colors.blues[1] : 'none',
  color: isActive ? colors.blues[60] : colors.blacks[60],
  padding: theme.spacing(3),
  '&:hover': {
    cursor: 'pointer',
    background: colors.blues[1],
    color: colors.blues[60]
  },
  [theme.breakpoints.down('lg')]: {
    display: visibleOnMobile ? 'block' : 'none',
    width: '127px',
    padding: 0
  }
}));

export const CustomTab = styled(FilterTab)(({ theme, isActive }) => `
  svg {
    margin-right: ${theme.spacing(0.5)};
    path {
      fill: ${isActive ? theme.colors.blues[50] : theme.colors.blacks[60]};
    }

    rect {
      fill: ${isActive ? theme.colors.blues[1] : theme.colors.white};
    }
  }

  &:hover, &:active {
    svg {
      rect {
        fill: ${theme.colors.blues[1]};
      }

      path {
        fill: ${theme.colors.blues[50]};
      }
    }
  }

  ${[theme.breakpoints.down('lg')]} {
    display: none;
  }
`);

export const FiltersContainer = styled.div(() => ({
  display: 'flex',
  alignSelf: 'end',
  marginTop: theme.spacing.single,
  borderTop: `1px solid ${theme.colors.blacks[10]}`,
  borderBottom: `1px solid ${theme.colors.blacks[10]}`,
  borderLeft: `1px solid ${theme.colors.blacks[10]}`,
  height: theme.spacing.tripleAndHalf,
  color: theme.colors.blacks[60],
  lineHeight: theme.lineHeight.L,
  fontSize: theme.font.size.S,
  borderRadius: theme.spacing.quarter,
  button: {
    borderRight: `1px solid ${colors.blacks[10]}`
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    alignSelf: 'center'
  }
}));

export const FilterDates = styled.b(() => ({
  right: theme.spacing.singleAndHalf,
  marginRight: 0,
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    fontSize: theme.fontSize.S
  }
}));

export const DateInput = styled.input(() => ({
  cursor: 'pointer',
  width: '100%',
  display: 'inline-block',
  color: theme.colors.black,
  boxSizing: 'border-box',
  textAlign: 'left',
  border: `${theme.inputs.borderWidth} ${theme.inputs.borderStyle} ${theme.inputs.borderColor}`,
  borderRadius: theme.inputs.borderRadius,
  '&:focus': {
    color: theme.colors.black,
    background: 'white',
    borderColor: theme.inputs.borderActiveColor,
    boxShadow: '0px 2px 4px rgba(52, 55, 153, 0.2)',
  },
  '&::placeholder': {
    color: theme.inputs.placeholderColor
  },
  fontSize: theme.fontSize.S,
  lineHeight: theme.lineHeight.M,
  padding: theme.spacing.threeQuarters,
  height: theme.spacing.tripleAndHalf
}));

export const CustomDatesWrapper = styled.div(() => ({
  display: 'flex',
  padding: theme.spacing.double,
  margin: `${theme.spacing.single} 0`,
  alignItems: 'baseline',
  width: '100%',
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    padding: theme.spacing.single,
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));

export const DateLabel = styled.p(() => ({
  marginRight: theme.spacing.half,
  '&:nth-of-type(2)': {
    marginLeft: theme.spacing.half
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    '&:nth-of-type(2)': {
      marginLeft: 0
    },
    margin: `${theme.spacing.single} 0`
  }
}));

