import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar';
import MenuItem from './MenuItem';
import {
  AvatarContainer,
  CurrentSpaceHeader,
  DropdownIcon,
  MenuContainer,
  MenuOverlay,
} from './styles';
import Workspaces, {
  WORKSPACE_NAV_PROPS,
  WORKSPACE_NAV_DEFAULT_PROPS,
} from '../Workspaces';

const useMenuState = () => {
  const [isOpen, setIsOpen] = useState(false);
  return {
    isOpen,
    closeMenu: () => setIsOpen(false),
    openMenu: () => setIsOpen(true),
  };
};

const HeaderSpacesMenu = (props) => {
  const { user, workspacesProps } = props;
  const {
    activeId,
    activeWorkspace,
    getFeedLink,
    onChangeWorkspace,
    workspaces,
  } = workspacesProps;
  const { isOpen, openMenu, closeMenu } = useMenuState();
  const hasActiveWorkspace = activeId && activeWorkspace;
  const avatar = <Avatar url={user.photoUrl} email={user.email} />;

  return (
    <>
      <AvatarContainer fluid canClick onClick={openMenu}>
        {hasActiveWorkspace ? (
          <Workspaces.Icon src={activeWorkspace.iconUrl} />
        ) : (
          avatar
        )}
        <CurrentSpaceHeader>
          {hasActiveWorkspace ? activeWorkspace.name : ''}
        </CurrentSpaceHeader>
        <DropdownIcon />
      </AvatarContainer>
      <MenuContainer show={isOpen}>
        {workspaces.map((workspace) => (
          <MenuItem
            key={`workspace-menu-${workspace.id}`}
            avatar={<Workspaces.Icon src={workspace.iconUrl} />}
            content={workspace.name}
            isActive={workspace.id === activeId}
            onClick={(e) => {
              closeMenu();
              onChangeWorkspace(e, { workspaceId: workspace.id });
            }}
            to={getFeedLink(workspace)}
          />
        ))}
      </MenuContainer>
      <MenuOverlay onClick={closeMenu} show={isOpen} />
    </>
  );
};

HeaderSpacesMenu.defaultProps = {
  user: {},
  workspacesProps: WORKSPACE_NAV_DEFAULT_PROPS,
  showZeroWorkspaces: false,
};

HeaderSpacesMenu.propTypes = {
  /** URL to home (My Stuff) page. */
  myStuffUrl: PropTypes.string,
  /** Workspaces props/ */
  workspacesProps: PropTypes.shape(WORKSPACE_NAV_PROPS),
};

export default HeaderSpacesMenu;
