import React from 'react';
import PropTypes from 'prop-types';
// import * as GuideItems from './guide-items';

const guideItems = {
  // [GUIDE_FABULOUS_WORK]: GuideItems.FabulousWorkGuide,
};

const Guide = ({ curGuide, handleDismiss }) => {
  if (!curGuide) { return null; }
  const { name } = curGuide;
  const CurGuideComponent = guideItems[name];
  return <CurGuideComponent handleDismiss={handleDismiss} />;
};

Guide.defaultProps = {
  curGuide: null,
};

Guide.propTypes = {
  curGuide: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  handleDismiss: PropTypes.func.isRequired,
};

export default Guide;
