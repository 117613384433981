import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5.5a1 1 0 0 1 1 1v4.906l1.269-4.74a1 1 0 0 1 1.932.518L18.432 6.92l2.452-4.246a1 1 0 0 1 1.732 1l-2.452 4.247 3.469-3.47a1 1 0 1 1 1.414 1.415l-3.469 3.47 4.247-2.453a1 1 0 1 1 1 1.732l-4.246 2.452 4.737-1.269a1 1 0 0 1 .518 1.932L23.094 13H28a1 1 0 0 1 .371 1.928l-4.376 1.751 4.201.84a1 1 0 0 1 .12 1.93l-3.928 1.309 3.808.761a1 1 0 0 1 .12 1.93l-3.928 1.309 3.808.761a1 1 0 1 1-.392 1.962l-7.5-1.5a1 1 0 0 1-.12-1.93l3.927-1.309-3.807-.761a1 1 0 0 1-.12-1.93l3.927-1.309-3.807-.761a1 1 0 0 1-.175-1.91L22.807 15H20.5a1 1 0 0 1-.836-1.549.995.995 0 0 1-.284-1.058.994.994 0 0 1-.549-.949.995.995 0 0 1-.775-.775.996.996 0 0 1-.95-.549.996.996 0 0 1-1.057-.284.996.996 0 0 1-1.098 0 .995.995 0 0 1-1.058.284.995.995 0 0 1-.949.549.996.996 0 0 1-.776.775.995.995 0 0 1-.548.95.995.995 0 0 1-.284 1.057A1 1 0 0 1 10.5 15H3a1 1 0 0 1 0-2h4.905l-4.739-1.269a1 1 0 0 1 .518-1.932l4.737 1.269-4.246-2.452a1 1 0 0 1 1-1.732l4.247 2.452-3.47-3.469a1 1 0 0 1 1.415-1.414l3.469 3.469-2.452-4.247a1 1 0 1 1 1.732-1l2.452 4.246-1.269-4.737a1 1 0 0 1 1.932-.518l1.269 4.74V1.5a1 1 0 0 1 1-1z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
