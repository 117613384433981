import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';
import { 
  Box
} from '@mui/material';

const Container = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '62px',
  width: '100%',
  padding: `0 ${theme.spacing.single}`,
  backgroundColor: theme.colors.blacks[5]
}));

const TextBox = styled(Box)(({ disabled }) => ({
  fontSize: theme.fontSize.S,
  lineHeight: theme.lineHeight.M,
  color: disabled ? theme.colors.blacks[40] : theme.colors.blacks[80]
}));

export default function InlineCheckbox({ onChange, isChecked, text, checkboxName }) {
  return (
    <Container>
      <TextBox>
        {text}
      </TextBox>
      <Checkbox
        onChange={onChange}
        checked={isChecked}
        iconSize={30}
        checkmarkSize={24}
        name={checkboxName || 'checkbox'}
        light
        isCheckboxV2
        showAnimation={false}
      />
    </Container>
  );
}
