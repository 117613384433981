import styled from 'styled-components';
import Button from './Default';
import theme from '@matterapp/matter-theme';

const getColorStyles = ({ disabled }) => {
  if (!disabled) {
    return `
      background: #F2F2F2;
      border-color: transparent;
      color: ${theme.colors.blacks[70]};

      &:hover, &:active {
        border-color: ${theme.colors.blacks[40]};
        color: ${theme.colors.blacks[70]};
      }
    `;
  }
  return 'border: none;';
};

const MinimalPrimaryButton = styled(Button)`
  ${getColorStyles}
`;

export default MinimalPrimaryButton;
