import React from 'react';
import PropTypes from 'prop-types';

// Safari 10 on iOS disabled the `user-scale=no` meta tag. There are some JS
// workarounds. This component only disables double tap to zoom.
//
// https://wouterdeschuyter.be/blog/how-to-disable-viewport-scaling-in-ios-10-you-dont
// https://stackoverflow.com/questions/37808180/disable-viewport-zooming-ios-10-safari/38573198#38573198

const doubleTapDelay = 200;
const maxTapLength = 150;

function getNow() {
  return new Date().getTime();
}

const DocumentZoomPreventer = class extends React.Component {
  componentDidMount() {
    let touchStart = 0;
    let lastTouchEnd = 0;

    document.addEventListener('touchstart', () => {
      touchStart = getNow();
    });

    document.addEventListener(
      'touchend',
      (event) => {
        const now = getNow();

        // only deal with short taps
        const tapLength = now - touchStart;
        if (tapLength > maxTapLength) { return; }

        // Allow double click in buttons and links
        if (event.target.closest('a, button')) { return; }

        if (now - lastTouchEnd <= doubleTapDelay) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
};

DocumentZoomPreventer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DocumentZoomPreventer;
