import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Divider = styled.hr`
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  height: 1px;
  background-color: ${() => theme.content.divider.color};
`;

export default Divider;
