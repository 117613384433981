import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import starburstYellow from '../../../assets/svgs/starburst.yellow.svg';
import { Text } from '../../components';
import CardModal from './CardModal';
import YellowCardInner from './YellowCardInner';
import { FEED_ITEM_CONTAINER_CLASS_NAME } from '../Feed/consts';

import { IconClose } from '../Icon';

const CloseButton = styled.button`
  display: inline;
  position: absolute;
  top: ${theme.spacing.singleAndQuarter};
  right: ${theme.spacing.singleAndHalf};
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  svg {
    user-select: none;
    path {
      transition: fill 0.2s ease;
      fill: ${theme.colors.white};
    }
  }

  ${theme.media.S`
    position: fixed;
  `}
`;

const Header = styled.div`
  position: absolute;
  color: white;
  top: ${theme.spacing.singleAndQuarter};
  left: 50%;
  transform: translate(-50%);
  text-transform: uppercase;
  white-space: nowrap;
  font-size: ${theme.fontSize.XS};
  line-height: ${theme.lineHeight.M};
  font-weight: ${theme.fontWeight.semiBold};
  ${theme.media.S`
    top: ${theme.spacing.singleAndHalf};
  `}
`;

const Wrapper = styled.div`
  pointer-events: all;
  position: absolute;
  top: ${theme.spacing.quad};
  left: 0;
  right: 0;
  background: white;
  margin-bottom: ${theme.spacing.quintupleAndHalf};
  .${FEED_ITEM_CONTAINER_CLASS_NAME} {
    border: none;
    border-radius: 0;
  }
  ${({ minimal }) =>
    minimal &&
    `
    .${FEED_ITEM_CONTAINER_CLASS_NAME} {
      border-radius: ${theme.borderRadius.M} ${theme.borderRadius.M} 0 0;
    }
  `}

  ${theme.media.S`
    top: ${theme.spacing.quadrupleAndHalf};
    max-width: ${({ customWidth }) => customWidth ? customWidth + 'px' : '480px'};
    margin: 0 auto;
    margin-bottom: 112px;
    box-shadow: 0px -1px 4px rgba(28, 28, 33, 0.25);
    border-radius: ${theme.borderRadius.M};
  `}
`;

const WowContainer = styled.div`
  display: flex;
  border-radius: 12px 12px 0 0;
  background-color: ${theme.colors.yellows[20]};
  background-image: url(${starburstYellow});
  background-repeat: no-repeat;
  background-position: 50% -${theme.spacing.quintupleAndHalf};
`;

const WowBody = styled.div`
  display: flex;
  max-width: 280px;
  margin: 0 auto;
`;

const BadgeBox = styled.div`
  width: ${theme.spacing.sextuple};
  min-width: ${theme.spacing.sextuple};
  margin-bottom: ${theme.spacing.singleAndHalf};
  margin-top: ${theme.spacing.singleAndHalf};
`;

class YellowCard extends React.Component {
  renderMainCard = () => {
    const {
      header,
      title,
      titleColor,
      badge,
      cardContent,
      body,
      footer,
      handleRequestClose,
      minimal,
      customWidth,
    } = this.props;

    return (
      <React.Fragment>
        {header && <Header>{header}</Header>}
        <CloseButton onClick={handleRequestClose} className="close-btn">
          <IconClose />
        </CloseButton>
        <YellowCardInner>
          <Wrapper minimal={minimal} className="wowWrapper" customWidth={customWidth}>
            <span
              ref={(ref) => {
                if (ref) {
                  this.topRef = ref;
                }
              }}
            />
            {!minimal && (
              <WowContainer
                style={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '48px',
                }}
              >
                <WowBody
                  style={{ alignItems: 'center', flexDirection: 'column' }}
                >
                  <Text color={titleColor} textStyle={500} bold>
                    {title}
                  </Text>
                  <BadgeBox>{badge}</BadgeBox>
                  {cardContent}
                </WowBody>
              </WowContainer>
            )}
            {body}
            {footer}
          </Wrapper>
        </YellowCardInner>
      </React.Fragment>
    );
  };

  render() {
    const { isOpen, handleRequestClose } = this.props;

    return (
      <CardModal
        isOpen={isOpen}
        contentLabel="main card content"
        onRequestClose={handleRequestClose}
      >
        {this.renderMainCard()}
      </CardModal>
    );
  }
}

YellowCard.defaultProps = {
  minimal: false,
  badge: null,
  cardContent: null,
  body: null,
  footer: null,
  title: null,
  titleColor: 'black',
  header: null,
  customWidth: null,
};

YellowCard.propTypes = {
  handleRequestClose: PropTypes.func.isRequired,
  minimal: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  badge: PropTypes.node,
  cardContent: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
};

export default YellowCard;
