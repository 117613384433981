import { withProps, compose } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import ConnectButton from '../components/Connect';
import { withOAuthCallback } from '../../../hocs';
import {
  CONNECTED_ACCOUNTS_QUERY,
  DISCONNECT_CONNECTED_ACCOUNT_MUTATION,
} from 'graphql-queries/queries';
import { OAUTH_RESOURCE_DETAILS } from '../../../app-consts';
import { mixpanel } from 'libs/tracking';

const handleTrack = {
  attempt: mixpanel.createEventSender('socialConnectAttempt'),
};

const withData = compose(
  withOAuthCallback,
  graphql(CONNECTED_ACCOUNTS_QUERY, {
    name: 'connectedAccountsData',
  }),
  graphql(DISCONNECT_CONNECTED_ACCOUNT_MUTATION, {
    name: 'disconnectConnectedAccountMutation',
  }),
  withProps(({ resourceName }) => ({
    oauthURI: OAUTH_RESOURCE_DETAILS[resourceName].connectUrl,
  })),
  withProps(({ connectedAccountsData }) => ({
    isLoading: connectedAccountsData.loading,
  })),
  withProps(({ connectedAccountsData, resourceName }) => {
    const { loading, currentUser } = connectedAccountsData;
    const findIsConnected = () => {
      const { getIsConnected } = OAUTH_RESOURCE_DETAILS[resourceName];
      if (loading) { return null; }
      if (!currentUser) { return null; }
      const { connectedAccountsMap } = currentUser;
      if (!connectedAccountsMap) { return null; }
      return getIsConnected({ connectedAccountsMap });
    };
    return { isConnected: findIsConnected() };
  }),
  withProps(({ connectedAccountsData, resourceName, isConnected }) => {
    const findConnectedAccount = () => {
      const { getResource } = OAUTH_RESOURCE_DETAILS[resourceName];
      const { currentUser } = connectedAccountsData;
      if (!isConnected) { return null; }
      const { connectedAccountsMap } = currentUser;
      return getResource({ connectedAccountsMap });
    };
    return { connectedAccount: findConnectedAccount() };
  }),
  withProps(
    ({
      connectedAccount,
      disconnectConnectedAccountMutation,
      handleDisplayDisconnectSuccessBanner,
      resourceName,
    }) => ({
      handleDisconnect: async () => {
        if (!connectedAccount) { return; }
        await disconnectConnectedAccountMutation({
          variables: {
            connectedAccountId: connectedAccount.id,
          },
        });
        handleDisplayDisconnectSuccessBanner({
          resourceName,
        });
      },
    })
  ),
  withProps(
    ({
      failureRedirect,
      successRedirect,
      oauthURI,
      setupOAuthCallbackHandler,
      resourceName,
      displaySuccessBanner,
      displayFailureBanner,
    }) => {
      return {
        handleConnect: () => {
          setupOAuthCallbackHandler({
            failureRedirect,
            successRedirect,
            resourceName,
            displaySuccessBanner,
            displayFailureBanner,
          });
          handleTrack.attempt(
            {
              resourceName,
            },
            () => {
              window.location = oauthURI;
            }
          );
        },
      };
    }
  )
);

const ConnectButtonInner = withData(ConnectButton);

ConnectButtonInner.propTypes = {
  /** The name of the resource connecting to. */
  resourceName: PropTypes.string.isRequired,
  /** The redirect path when connection fails. */
  failureRedirect: PropTypes.string,
  /** The redirect path when connection is sucessful. */
  successRedirect: PropTypes.string,
  /** Displays banner when successfully connected. */
  displaySuccessBanner: PropTypes.bool,
  /** Displays banner when an error occurs while connecting. */
  displayFailureBanner: PropTypes.bool,
};

ConnectButtonInner.defaultProps = {
  displaySuccessBanner: true,
  displayFailureBanner: true,
};

class ConnectButtonContainer {
  static GoogleCombinedReadOnly = withProps({
    resourceName: OAUTH_RESOURCE_DETAILS.googleCombinedReadOnly.name,
    disconnectSubheading:
      OAUTH_RESOURCE_DETAILS.googleCombinedReadOnly.disconnectSubheading,
  })(ConnectButtonInner);

  static GoogleCalendarReadOnly = withProps({
    resourceName: OAUTH_RESOURCE_DETAILS.googleCalendarReadOnly.name,
    disconnectSubheading:
      OAUTH_RESOURCE_DETAILS.googleCalendarReadOnly.disconnectSubheading,
  })(ConnectButtonInner);

  static GoogleContactsReadOnly = withProps({
    resourceName: OAUTH_RESOURCE_DETAILS.googleContactsReadOnly.name,
    disconnectSubheading:
      OAUTH_RESOURCE_DETAILS.googleContactsReadOnly.disconnectSubheading,
  })(ConnectButtonInner);

  static LinkedIn = withProps({
    resourceName: OAUTH_RESOURCE_DETAILS.linkedIn.name,
    disconnectSubheading: OAUTH_RESOURCE_DETAILS.linkedIn.disconnectSubheading,
  })(ConnectButtonInner);

  static Twitter = withProps({
    resourceName: OAUTH_RESOURCE_DETAILS.twitter.name,
    disconnectSubheading: OAUTH_RESOURCE_DETAILS.twitter.disconnectSubheading,
  })(ConnectButtonInner);

  static OutlookCombinedReadOnly = withProps({
    resourceName: OAUTH_RESOURCE_DETAILS.outlookCombinedReadOnly.name,
    disconnectSubheading: OAUTH_RESOURCE_DETAILS.outlookCombinedReadOnly.disconnectSubheading,
  })(ConnectButtonInner);

  static SlackContactsReadOnly = withProps({
    resourceName: OAUTH_RESOURCE_DETAILS.slackContactsReadOnly.name,
    disconnectSubheading: OAUTH_RESOURCE_DETAILS.slackContactsReadOnly.disconnectSubheading,
  })(ConnectButtonInner)
}

export default ConnectButtonContainer;
