import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Badge from '../Badge';
import { Clickable } from '../../Clickable/Clickable';
import Icon from '../../Icon';
import Tooltip from '../../Tooltip';
import omitStyled from '../../../libs/omitStyled';
import {
  border,
  colors,
  font,
  lineHeight,
  media,
  spacing,
  transitions,
} from '@matterapp/matter-theme';

const LIGHT_GREY_COLOR = colors.blacks[5];
const GREY_COLOR = colors.blacks[10];
const WHITE_COLOR = colors.white;
const DEFAULT_SVG_SIZE = '8px';
const MOBILE_ICON_CONTAINER_SIZE = '14px';
const DESKTOP_ICON_CONTAINER_SIZE = spacing.singleAndHalf;
const SKILL_ITEM_BORDER_RADIUS = border.radius.M;
const SKILL_ITEM_BORDER = `1px solid ${GREY_COLOR}`;
const SKILL_ITEM_BORDER_WIDE = `2px solid ${GREY_COLOR}`;
const ICON_BORDER_RADIUS = '100%';

const InfoIcon = styled(Icon).attrs({ name: 'infoLetter', fill: GREY_COLOR })``;
const CheckmarkIcon = styled(Icon).attrs({ name: 'checkmarkSmall' })``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  right: ${spacing.single};

  ${media.M`
    width: 90%;
    position: absolute;
    top: 0;
    right: -${spacing.eighth};
    left: 0;
    margin: ${spacing.half};
    visibility: hidden;
    transition: pointer-events ${transitions.times.default} ${transitions.times.double};
  `}
`;

const TooltipTrigger = styled.div``;

const InfoIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: ${MOBILE_ICON_CONTAINER_SIZE};
  width: ${MOBILE_ICON_CONTAINER_SIZE};
  background: ${GREY_COLOR};
  border-radius: ${ICON_BORDER_RADIUS}
  border: 1.5px solid ${GREY_COLOR};
  position: relative;

  svg {
    height: ${DEFAULT_SVG_SIZE};
    width: ${DEFAULT_SVG_SIZE};
    path {
      fill: ${WHITE_COLOR};
    }
  }

  ${media.M`
    left: 0;
    height: ${DESKTOP_ICON_CONTAINER_SIZE};
    width: ${DESKTOP_ICON_CONTAINER_SIZE};
    max-height: ${DESKTOP_ICON_CONTAINER_SIZE};
    max-width: ${DESKTOP_ICON_CONTAINER_SIZE};
    border: 2px solid ${GREY_COLOR};
    background: ${WHITE_COLOR};
    svg {
      height: 12px;
      width: 13px;
      path {
        fill: ${GREY_COLOR};
      }
    }
    &:hover {
      background: ${GREY_COLOR};
      svg path {
        fill: ${WHITE_COLOR};
      }
    }
  `}
`;

const CheckmarkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${spacing.single};
  border: 2px solid ${GREY_COLOR};
  border-radius: ${ICON_BORDER_RADIUS};
  height: ${DESKTOP_ICON_CONTAINER_SIZE};
  width: ${DESKTOP_ICON_CONTAINER_SIZE};
  max-height: ${DESKTOP_ICON_CONTAINER_SIZE};
  max-width: ${DESKTOP_ICON_CONTAINER_SIZE};
  background: ${WHITE_COLOR};

  svg path {
    fill: ${WHITE_COLOR};
  }

  ${media.M`
    top: 0;
    right: -${spacing.eighth};
    border: ${SKILL_ITEM_BORDER_WIDE};
  `}

  ${({ selected }) =>
    selected &&
    `
    background: ${colors.blue};
    border-color: ${colors.blue} !important;
  `}
`;

const Container = omitStyled(Clickable, ['display', 'index', 'isLastSkill'])`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  align-items: center;
  padding: ${spacing.single};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  box-sizing: content-box;
  border: ${SKILL_ITEM_BORDER_WIDE};
  border-bottom: ${SKILL_ITEM_BORDER};
  border-top: ${SKILL_ITEM_BORDER};
  background: ${WHITE_COLOR};
  white-space: nowrap;

  animation-name: popin;
  animation-fill-mode: both;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-delay: ${({ index }) => index * 0.04 + 0.5}s;

  ${({ isLastSkill }) =>
    isLastSkill
      ? `
    border-bottom-left-radius: ${SKILL_ITEM_BORDER_RADIUS};
    border-bottom-right-radius: ${SKILL_ITEM_BORDER_RADIUS};
  `
      : ''}

  &:first-child {
    border-top: ${SKILL_ITEM_BORDER_WIDE};
    border-top-left-radius: ${SKILL_ITEM_BORDER_RADIUS};
    border-top-right-radius: ${SKILL_ITEM_BORDER_RADIUS};
  }
  
  img {
    transition: filter ${transitions.times.default};
  }

  @keyframes popin {
    0% {
      border-color: transparent;
      transform: scale(0);
    }
    50% {
      border-color: transparent;
      transform: scale(1.1);
    }
    100% {
      z-index: 1;
      transform: scale(1);
    }
  }

  ${({ selected }) =>
    selected &&
    `
    background: ${LIGHT_GREY_COLOR};

    .checkmark {
      visibility: visible;
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background: ${WHITE_COLOR};

    img {
      filter: grayscale(100%) opacity(25%);
    }

    &:hover {
      cursor: default;
      background: ${WHITE_COLOR};
      ${ButtonContainer} {
        visibility: hidden;
      }
    }
  `}

  ${({ isLastSkill, index }) => media.M`
    animation-delay: ${index * 0.026 + 0.5}s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${spacing.singleAndHalf} 0 ${spacing.single} 0;
    text-align: center;
    box-sizing: content-box;
    border: ${SKILL_ITEM_BORDER};
    min-width: 180px;

    ${ButtonContainer} {
      pointer-events: none;
    }

    &:hover {
      background: ${LIGHT_GREY_COLOR};
      ${ButtonContainer} {
        pointer-events: all;
        visibility: visible;
      }
    }

    ${isLastSkill ? `
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `
    : ''}

    &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      border-top: ${SKILL_ITEM_BORDER_WIDE};
    }
    &:nth-last-child(4), &:nth-last-child(3), &:nth-last-child(2), &:last-child {
      border-bottom: ${SKILL_ITEM_BORDER_WIDE};
    }
    &:nth-child(4n + 1) {
      border-left: ${SKILL_ITEM_BORDER_WIDE};
    }
    &:nth-child(4n + 4) {
      border-right: ${SKILL_ITEM_BORDER_WIDE};
    }

    &:first-child {
      border-top-left-radius: ${SKILL_ITEM_BORDER_RADIUS};
      border-top-right-radius: 0;
    }
    &:nth-child(4) {
      border-top-right-radius: ${SKILL_ITEM_BORDER_RADIUS};
    }
    &:nth-last-child(4) {
      border-bottom-left-radius: ${SKILL_ITEM_BORDER_RADIUS};
    }
    &:last-child {
      border-bottom-right-radius: ${SKILL_ITEM_BORDER_RADIUS};
    }
  `}
`;

const SkillName = styled.div`
  margin-right: 5px;
  margin-left: ${spacing.single}
  font-weight: ${font.weight.medium};
  font-size: ${font.size.base};
  line-height: ${lineHeight.S};
  transition: opacity ${transitions.times.default};

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 50%;
  `}
  
  ${media.M`
    margin: 0;
    margin-top: ${spacing.single}
    color: ${colors.blacks[70]};
  `}
`;

const SkillContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.M`
    flex-direction: column;
  `}
`;

const TooltipContent = styled.div`
  line-height: ${lineHeight.S};
  text-align: left;
  white-space: break-spaces;
`;

const SkillRecommendationsListItem = ({
  className,
  skill,
  selected,
  disabled,
  index,
  handleSelectSkills,
  isLastSkill,
}) => {
  if (!skill) {
    return null;
  }

  const skillName =
    !skill.name || skill.name.length < 23
      ? skill.name
      : `${skill.name.substr(0, 19)}...`;
  const tooltipContent = (
    <TooltipContent>
      <strong>{skill.name}</strong>
      <br />
      {skill.description || 'This is a custom skill'}
    </TooltipContent>
  );

  return (
    <Container
      className={className}
      data-index={index}
      data-skill={`skill-${skill.id}`}
      index={index}
      isLastSkill={isLastSkill}
      selected={selected}
      disabled={disabled}
      onClick={handleSelectSkills}
      display={!!skill.name}
    >
      {skill.name && ( // Because we are creating fake skill items to fit the grid, this ensure no badges are rendered if it's blank skill
        <>
          <SkillContainer>
            <Badge size="mini2" skill={skill} />
            <SkillName disabled={disabled}>{skillName}</SkillName>
          </SkillContainer>
          <ButtonContainer>
            <TooltipTrigger>
              <InfoIconContainer>
                <InfoIcon />
                <Tooltip.InlineHover distanceFromItem={16} ParentComponent={InfoIconContainer}>
                  {tooltipContent}
                </Tooltip.InlineHover>
              </InfoIconContainer>
            </TooltipTrigger>
            {/* <Tooltip content={tooltipContent} TriggerComponent={TooltipTrigger}>
            </Tooltip> */}
            <CheckmarkContainer className="checkmark" selected={selected}>
              <CheckmarkIcon />
            </CheckmarkContainer>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

SkillRecommendationsListItem.propTypes = {
  /** Time for delaying animation. */
  delayAnimationTime: PropTypes.number,
  /** Skill to render. */
  skill: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  /** Selected skill. */
  selected: PropTypes.bool,
  /** Disabled skill. */
  disabled: PropTypes.bool,
  /** Handle selecting skills. */
  handleSelectSkills: PropTypes.func.isRequired,
};

export default SkillRecommendationsListItem;
