import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { skillValueObjectPropType } from '@matterapp/skills';

import Avatar from './Base';
import Skill from '../Skill';

class AvatarWithSkill extends React.PureComponent {
  static defaultProps = {
    userUrl: null,
    size: 44,
  };

  static propTypes = {
    defaultAvatarIsGenerated: PropTypes.bool,
    skill: skillValueObjectPropType.isRequired, // eslint-disable-line
    userUrl: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static Container = styled.div`
    display: flex;
    margin-bottom: 16px;
    margin-top: 16px;
    justify-content: center;
  `;

  static SkillBadgeContainer = styled.div`

    ${({ size, shouldOffset }) =>
    shouldOffset &&
      `   z-index: 1;
          margin-left:  ${-(Number(size) / 5.25)}px;`}
  
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  `;

  render() {
    const { defaultAvatarIsGenerated, userUrl, skill, size } = this.props;

    return (
      <AvatarWithSkill.Container>
        {userUrl && (
          <div>
            <Avatar
              defaultAvatarIsGenerated={defaultAvatarIsGenerated}
              size={size}
              url={userUrl}
            />
          </div>
        )}
        <AvatarWithSkill.SkillBadgeContainer
          size={size}
          shouldOffset={!!userUrl}
        >
          <Skill.Badge size="small" skill={skill} />
        </AvatarWithSkill.SkillBadgeContainer>
      </AvatarWithSkill.Container>
    );
  }
}

export default AvatarWithSkill;
