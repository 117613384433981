import React from 'react';
import BackgroundAnimation, { ANIMATIONS } from '../../BackgroundAnimation';
import ColoredBackground from '../../ColoredBackground';
import gsap from 'gsap';
import * as S from './styles';

export default class Variant4Background extends BackgroundAnimation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  /**
   * Moves the clouds in.
   * @returns { Object } The animation timeline.
   */
  moveCloudsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 1 };
    tl.to(this.topCloudRef, { x: 140 });
    tl.to(this.bottomCloudRef, { x: -110 });

    tl.to(this.topCloudRef, resetProps, '<');
    tl.to(this.bottomCloudRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the clouds out.
   * @returns { Object } The animation timeline.
   */
  moveCloudsOut = () => {
    const tl = gsap.timeline({ defaults: { duration: 0.3 } });
    const resetProps = { x: 0, y: 0, duration: 0 };
    tl.to(this.topCloudRef, resetProps);
    tl.to(this.bottomCloudRef, resetProps);

    tl.to(this.topCloudRef, { x: -90 }, '<');
    tl.to(this.bottomCloudRef, { x: 120 }, '<');
    return tl;
  };

  /**
   * Moves the rings in.
   * @returns { Object } The animation timeline.
   */
  moveRingsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 2, opacity: 0, transformOrigin: 'center' };
    const endProps = { x: 0, y: 0, scale: 1, opacity: 1, duration: 0.5 };
    tl.to(this.leftRing1Ref, initialProps);
    tl.to(this.leftRing2Ref, initialProps);
    tl.to(this.rightRingRef, initialProps);
    tl.to(this.bottomRingRef, initialProps);
    tl.to(this.topRingRef, initialProps);
    tl.to(this.bottomTear1Ref, { ...initialProps, x: -20, y: -20 });
    tl.to(this.bottomTear2Ref, { ...initialProps, x: -40, y: -40 });

    tl.to(this.leftRing1Ref, endProps, '<');
    tl.to(this.leftRing2Ref, endProps, '<0.2');
    tl.to(this.rightRingRef, endProps, '<0.1');
    tl.to(this.bottomRingRef, endProps, '<0.2');
    tl.to(this.topRingRef, endProps, '<-0.2');
    tl.to(this.bottomTear1Ref, endProps, '<');
    tl.to(this.bottomTear2Ref, endProps, '<');
    return tl;
  };

  /**
   * Moves the dots in.
   * @returns { Object } The animation timeline.
   */
  moveDotsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 0.7 };
    const dotRefs = {
      topDot1Ref: { x: -81, y: 33 },
      topDot2Ref: { x: 47, y: 42 },
      topLeftDot1Ref: { x: -140, y: 10 },
      topLeftDot2Ref: { x: 30, y: 42 },
      topRightDot: { x: -43, y: 52 },
      leftDotRef: { x: 54, y: -10 },
      centerDotRef: { x: -33, y: 105 },
      rightDot1Ref: { x: -12, y: -41 },
      rightDot2Ref: { x: 67, y: 2 },
      rightDot3Ref: { x: -55, y: -20 },
      rightDot4Ref: { x: 19, y: -33 },
      bottomDot1Ref: { x: -71, y: -51 },
      bottomDot2Ref: { x: 63, y: -67 },
      bottomDot3Ref: { x: -76, y: 20 },
      bottomDot4Ref: { x: -56, y: -37 },
    };
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], dotRefs[key]);
    });
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], resetProps, '<');
    });
  };

  playAnimation = () => {
    const { animation } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    if (animation !== ANIMATIONS.SHOW) {
      if (animation === ANIMATIONS.OUT) {
        tl.add(this.moveCloudsOut(), '<');
      } else {
        tl.add(this.moveCloudsIn(), '<');
        tl.add(this.moveRingsIn(), '<');
        tl.add(this.moveDotsIn(), '<0.3');
      }
    }
    this.tl = tl;
    return tl;
  };

  render() {
    return (
      <ColoredBackground {...this.props}>
        <S.TopCloud ref={(ref) => (this.topCloudRef = ref)} />
        <S.BottomCloud ref={(ref) => (this.bottomCloudRef = ref)} />
        <S.TopDot1 ref={(ref) => (this.topDot1Ref = ref)} />
        <S.TopDot2 ref={(ref) => (this.topDot2Ref = ref)} />
        <S.TopRing ref={(ref) => (this.topRingRef = ref)} />
        <S.TopLeftDot1 ref={(ref) => (this.topLeftDot1Ref = ref)} />
        <S.TopLeftDot2 ref={(ref) => (this.topLeftDot2Ref = ref)} />
        <S.TopRightDot ref={(ref) => (this.topRightDot = ref)} />
        <S.LeftRing1 ref={(ref) => (this.leftRing1Ref = ref)} />
        <S.LeftRing2 ref={(ref) => (this.leftRing2Ref = ref)} />
        <S.LeftDot ref={(ref) => (this.leftDotRef = ref)} />
        <S.CenterDot ref={(ref) => (this.centerDotRef = ref)} />
        <S.RightRing ref={(ref) => (this.rightRingRef = ref)} />
        <S.RightDot1 ref={(ref) => (this.rightDot1Ref = ref)} />
        <S.RightDot2 ref={(ref) => (this.rightDot2Ref = ref)} />
        <S.RightDot3 ref={(ref) => (this.rightDot3Ref = ref)} />
        <S.RightDot4 ref={(ref) => (this.rightDot4Ref = ref)} />
        <S.BottomDot1 ref={(ref) => (this.bottomDot1Ref = ref)} />
        <S.BottomDot2 ref={(ref) => (this.bottomDot2Ref = ref)} />
        <S.BottomDot3 ref={(ref) => (this.bottomDot3Ref = ref)} />
        <S.BottomDot4 ref={(ref) => (this.bottomDot4Ref = ref)} />
        <S.BottomLeftDot ref={(ref) => (this.bottomLeftDotRef = ref)} />
        <S.BottomRing ref={(ref) => (this.bottomRingRef = ref)} />
        <S.BottomTear1 ref={(ref) => (this.bottomTear1Ref = ref)} />
        <S.BottomTear2 ref={(ref) => (this.bottomTear2Ref = ref)} />
      </ColoredBackground>
    );
  }
}
