import KudosSentModal from './KudosSentModal';
import Main from './Main';
import Tabs, { TabValues } from './Tabs';
import UnactivatedUser from './UnactivatedUserComposer';
import EditMessage from './EditMessage';

export default {
  Main,
  UnactivatedUser,
  KudosSentModal,
  Tabs,
  TabValues,
  EditMessage,
  invitationSuccessModeOverlayTime: 1000,
};
