import React, { useState } from 'react';
import { Tooltip } from '@matterapp/matter-ui';
import {
  InfoIcon,
  TooltipContainer,
  TooltipIconContainer,
  TooltipTrigger,
} from './styles';

export default function TimezoneTooltip() {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <Tooltip
      content={
        <TooltipContainer>
          <b>Not the right timezone?</b> Update your timezone in Settings: General
        </TooltipContainer>
      }
      isOpen={isOpen}
      onClickOutside={() => setIsOpen(false)}
      TriggerComponent={TooltipTrigger}
      triggerProps={{
        onBlur: () => setIsOpen(false),
        onClick: () => setIsOpen(!isOpen),
        onMouseEnter: () => setIsOpen(true),
        onMouseLeave: () => setIsOpen(false),
      }}
    >
      <TooltipIconContainer>
        <InfoIcon />
      </TooltipIconContainer>
    </Tooltip>
  );
};
