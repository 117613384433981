import { useEffect, useState } from 'react';
import { VALUES } from '../Tabs';

export const useShowTab = (params) => {
  const { activeTab, isGiveTab, isKudosTab, isRequestTab, showTabs } = params;
  const { request, give, kudos } = showTabs;
  const [isChangingTab, setIsChangingTab] = useState(false);
  let changeTimeout = null;

  const firstTab = {
    active: 1,
    isChangingTab,
    left: '0%',
  };
  const secondTab = {
    active: 2,
    isChangingTab,
    left: '-100%',
  };
  const lastTab = {
    active: 3,
    isChangingTab,
    left: '-200%',
  };

  useEffect(() => {
    setIsChangingTab(true);
    changeTimeout = setTimeout(() => {
      setIsChangingTab(false);
    }, 400);
    return () => {
      clearTimeout(changeTimeout);
    };
  }, [activeTab]);

  if (request && give && kudos) {
    if (isRequestTab) {
      return firstTab;
    }
    if (isGiveTab) {
      return secondTab;
    }
    if (isKudosTab) {
      return lastTab;
    }
  }
  if ((request && give) || (request && kudos)) {
    return isRequestTab ? firstTab : secondTab;
  }
  if (give && kudos) {
    return isGiveTab ? firstTab : secondTab;
  }
  return firstTab;
};

export const useActiveTab = (params) => {
  const { defaultTab = VALUES.REQUEST, showTabs } = params;
  const [activeTab, setActiveTab] = useState(defaultTab);
  const isGiveTab = activeTab === VALUES.GIVE;
  const isKudosTab = activeTab === VALUES.KUDOS;
  const isRequestTab = activeTab === VALUES.REQUEST;

  return {
    isGiveTab,
    isKudosTab,
    isRequestTab,
    tabProps: {
      activeTab,
      onChange: (e, props) => {
        setActiveTab(props.value);
      },
      showTabs,
    },
  };
};

export const useRequestComposer = (params) => {
  return {
    ...params,
    tabProps: {
      activeTab: VALUES.REQUEST,
      showTabs: {
        request: true,
      },
    },
  };
};

export const useKudosComposer = (params) => {
  return {
    ...params,
    tabProps: {
      activeTab: VALUES.KUDOS,
      showTabs: {
        kudos: true,
      },
    },
  };
};

export const useComposer = (params) => {
  const { isGiveTab, isKudosTab, isRequestTab, tabProps } = useActiveTab(
    params
  );
  const showTabProps = useShowTab({
    ...params,
    ...tabProps,
    isGiveTab,
    isKudosTab,
    isRequestTab,
  });
  return {
    ...params,
    isGiveTab,
    isKudosTab,
    isRequestTab,
    showTabs: tabProps.showTabs,
    showTabProps,
    tabProps,
  };
};
