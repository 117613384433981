export const NO_OP = () => null;

export const useSelectOnBlurState = (params) => {
  const { onBlur, onChange, onSelect, value } = params;
  let isSelected = false;

  return {
    ...params,
    handleChange: (e, props) => {
      onChange(e, { ...params, ...props });
    },
    handleBlur: (e, props) => {
      setTimeout(() => {
        if (isSelected) {
          isSelected = false;
        } else if (props.inputValue) {
          const { inputValue, setInputValue, parseValue } = props;
          onChange(e, {
            ...params,
            ...props,
            value: parseValue(inputValue, value),
          });
          onBlur(e, props);
          setInputValue('');
        }
      }, 200);
    },
    handleSelect: (selectedValue) => {
      isSelected = true;
      onSelect({}, { ...params, value: selectedValue });
    },
  };
};
