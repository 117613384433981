"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.breakpoints = void 0;
// `medium` and `large` are deprecated, use `S` and `L` instead.
const deprecatedBreakpoints = {
  medium: '640px',
  large: '1080px'
};
const breakpoints = {
  XXS: '360px',
  XS: '420px',
  S: '640px',
  M: '860px',
  L: '1080px',
  XL: '1280px',
  XXL: '1600px',
  ...deprecatedBreakpoints
};
exports.breakpoints = breakpoints;
var _default = breakpoints;
exports.default = _default;