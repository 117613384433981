import React from 'react';
import PropTypes from 'prop-types';
import matterLogoColorSvg from '../../../assets/svgs/logos/matter-logo-color.svg';
import matterLogoMonoSvg from '../../../assets/svgs/logos/matter-logo-mono.svg';

const logoUrls = {
  color: matterLogoColorSvg,
  inverted: matterLogoMonoSvg,
};

const sizeMap = {
  mini: '20px',
  small: '28px',
  smedium: '32px',
  medium: '64px',
  large: '80px',
  huge: '300px',
  massive: '400px',
};

const Logo = ({
  marginBottom,
  inline,
  type,
  size,
  className
}) => (
  <div style={{
    marginBottom,
    height: sizeMap[size],
    display: inline ? 'inline-block' : 'block',
  }}
  className={className}
  >
    <img
      alt="matter logo"
      src={logoUrls[type]}
      style={{
        width: sizeMap[size],
        height: sizeMap[size],
      }}
    />
  </div>
);

Logo.propTypes = {
  marginBottom: PropTypes.number,
  inline: PropTypes.bool,
  type: PropTypes.oneOf([
    'color',
    'inverted',
  ]),
  size: PropTypes.oneOf([
    'mini',
    'small',
    'smedium',
    'medium',
    'large',
    'huge',
    'massive',
  ]),
  className: PropTypes.string,
};

Logo.defaultProps = {
  marginBottom: 24,
  inline: false,
  type: 'color',
  size: 'huge',
  className: null,
};

export default Logo;
