import { 
  Box
} from '@mui/material';
import styled from 'styled-components';
import { Checkbox } from '@matterapp/matter-ui';

export const AddOn = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  height: '30px',
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  marginRight: '18px',
  marginLeft: '3px'
}));
