import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import {
  ContentContainer,
  FooterContainer,
  MainContainer,
  MainWrapper,
  SideColumn,
  SideContainer,
  SideSubcontentContainer,
  SideWrapper,
} from './styles';
import { useSetStickyTop } from './utils';

const Main = ({
  className,
  dataRoles,
  header,
  footer,
  mainColumn,
  mobileProps: { showSideColumn, sideColumnFirst },
  sideColumn,
  showRightSideColumn,
  noSpacing,
  sideColumnSubcontent,
  sideColumnFitContents,
}) => {
  const sidebarRef = useRef();
  const top = useSetStickyTop(sidebarRef);

  return (
    <Base className={className} header={header} noSpacing={noSpacing}>
      <ContentContainer>
        <MainContainer noSpacing={noSpacing} data-role={dataRoles.mainColumn} showRightSideColumn={showRightSideColumn}>
          <MainWrapper showRightSideColumn={showRightSideColumn}>{mainColumn}</MainWrapper>
        </MainContainer>
        {showRightSideColumn && 
          <> 
            <SideContainer
              data-role={dataRoles.sideColumn}
              ref={sidebarRef}
              firstOnMobile={sideColumnFirst}
              showOnMobile={showSideColumn}
              top={top}
            >
              <SideColumn>
                <SideWrapper fitContents={sideColumnFitContents}>
                  {sideColumn}
                </SideWrapper>
                <SideSubcontentContainer>
                  {sideColumnSubcontent}
                </SideSubcontentContainer>
              </SideColumn>
              <FooterContainer data-role={dataRoles.footer}>
                {footer}
              </FooterContainer>
            </SideContainer>
          </>
        }
        <SideSubcontentContainer>
          {sideColumnSubcontent}
        </SideSubcontentContainer>
        <FooterContainer data-role={dataRoles.footer}>{footer}</FooterContainer>
      </ContentContainer>
    </Base>
  );
};

Main.defaultProps = {
  dataRoles: {},
  mobileProps: {},
  showRightSideColumn: true,
};

Main.SideWrapper = SideWrapper;

Main.propTypes = {
  /** Additional style classes. */
  className: PropTypes.string,
  /** Add data-role selectors to parts of the layout/ */
  dataRoles: PropTypes.shape({
    mainColumn: PropTypes.string,
    sideColumn: PropTypes.string,
    footer: PropTypes.string,
  }),
  /** Footer Content. */
  footer: PropTypes.node,
  /** Header Content. */
  header: PropTypes.node,
  /** Main Column Content. */
  mainColumn: PropTypes.node,
  /** Mobile layout props. */
  mobileProps: PropTypes.shape({
    /** Shows the side column contents on mobile. Hides by default. */
    showSideColumn: PropTypes.bool,
    /** Displays the side column contents first on mobile. */
    sideColumnFirst: PropTypes.bool,
  }),
  /** Side Column Contents. */
  sideColumn: PropTypes.node,
  /** Side Column Subcontents. */
  sideColumnSubcontent: PropTypes.node,
  /** Fits sidebar contents to true height instead of stretching to fill sapce and pushing footer to bottom. */
  sideColumnFitContents: PropTypes.bool,
  showRightSideColumn: PropTypes.bool,
};

export default Main;
