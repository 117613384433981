import { graphql } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';
import { Resources } from '@matterapp/routing';
import MiniProfile from '../components/MiniProfile';
import { MINI_PROFILE_QUERY } from 'graphql-queries/queries';

const withMiniProfileData = graphql(MINI_PROFILE_QUERY, {
  name: 'miniProfileData'
});

const withData = compose(
  withMiniProfileData,
  withProps(({ miniProfileData }) => {
    const {
      feedbacksGiven,
      feedbackReceivedFeedItems,
      loading,
      outboundInvitations,
    } = miniProfileData;

    if (loading) {
      return {};
    }

    const pendingInvitations = outboundInvitations.filter((invitation) => invitation.feedbackRequestStatus.type === 'Pending');

    return {
      feedbackCounts: {
        given: feedbacksGiven.length,
        received: feedbackReceivedFeedItems.length,
        pending: pendingInvitations.length,
      },
      pendingRequestsPath: Resources.feedbackRequestsMain.path(),
    };
  })
);

const MiniProfileContainer = withData(MiniProfile);

export default MiniProfileContainer;
