import PropTypes from 'prop-types';
import omitStyled from '../../libs/omitStyled';
import theme from '@matterapp/matter-theme';
import { smallBreakpoint } from './consts';

const Container = omitStyled('div', [
  'maxWidth',
  'sidePadding',
  'sidePaddingSmall',
  'marginBottom',
])`
  text-align: center;
  width: 100%;
  max-width: ${({ maxWidth, sidePaddingSmall }) => maxWidth + (sidePaddingSmall * 2)}px;
  padding: 0 ${({ sidePaddingSmall }) => sidePaddingSmall}px;
  margin: 0 auto;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  box-sizing: border-box;

  ${({ maxWidth, sidePadding }) => theme.media.breakpoint(smallBreakpoint)`
    max-width: ${maxWidth + (sidePadding * 2)}px;
    padding: 0 ${sidePadding}px;
  `}
`;

Container.defaultProps = {
  marginBottom: 0,
  maxWidth: 336,
  sidePadding: 24,
  sidePaddingSmall: 16,
};

Container.propTypes = {
  marginBottom: PropTypes.number,
  maxWidth: PropTypes.number,
  sidePadding: PropTypes.number,
  sidePaddingSmall: PropTypes.number,
};

export default Container;
