import React from 'react';
import PropTypes from 'prop-types';
import InputContainer from './InputContainer';
import InputErrorContainer from './InputErrorContainer';

class Input extends React.Component {
  state = {};
  focus = () => this.input.focus();

  componentDidMount = () => {
    if (this.props.focusOnMount && this.input && this.input.focus) {
      this.focus();
    }
  };

  handleRef = (input) => {
    this.input = input;
    this.ref = {
      inputRef: input,
      focus: () => this.focus(),
    }; // for back compat
  };

  handleChange = (e) => {
    this.props.onChange(e, { ...this.props, value: e.target.value });
  };

  renderError = () => {
    const { errorMessage, errorMessageClassName, errorMessageStyle, isHideErrorMessage } = this.props;
    if (!errorMessage) {
      return null;
    }
    if (isHideErrorMessage) {
      return null;
    }
    return <InputErrorContainer className={errorMessageClassName} style={errorMessageStyle}>{errorMessage}</InputErrorContainer>;
  };

  render() {
    return (
      <InputContainer
        size={this.props.size}
        error={!!this.props.errorMessage}
        fluid={this.props.fluid}
        type={this.props.type}
        shortInline={this.props.shortInline}
        disabled={this.props.disabled}
        marketingStyle={this.props.marketingStyle}
      >
        {!this.props.showErrorMessageBelow && this.renderError()}
        <input
          {...this.props.field}
          maxLength={this.props.maxLength}
          onBlur={this.props.onBlur}
          onChange={this.handleChange}
          onFocus={this.props.onFocus}
          onKeyPress={this.props.onKeyPress}
          placeholder={this.props.placeholder}
          type={this.props.type}
          className={this.props.className}
          value={this.props.value}
          disabled={this.props.disabled}
          ref={this.handleRef}
          style={this.props.style}
          data-rc={this.props.rcLabel}
          data-testid={this.props.rcLabel}
          autoFocus={this.props.autoFocus}
        />
        {this.props.showErrorMessageBelow && this.renderError()}
      </InputContainer>
    );
  }
}

Input.defaultProps = {
  onChange: () => false,
  onFocus: () => false,
  fluid: false,
  disabled: false,
  size: 'medium',
  errorMessage: null,
  className: '',
  placeholder: '',
  value: '',
  type: 'text',
  shortInline: false,
  style: null,
  showErrorMessageBelow: false,
  isHideErrorMessage: false,
};

Input.propTypes = {
  field: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  shortInline: PropTypes.bool,
  showErrorMessageBelow: PropTypes.bool,
  isHideErrorMessage: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
  errorMessageStyle: PropTypes.object,
  marketingStyle: PropTypes.bool,
  focusOnMount: PropTypes.bool,
  rcLabel: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default Input;
