import { mixpanel } from 'libs/tracking';

export function trackExperiment({ experimentName, experimentVariantName }, cb) {
  const {
    computedTrackingParams,
    newMixpanelFormattedEvent,
    paramsLog,
  } = mixpanel.getFormattedEvent({
    eventName: 'experiment:start',
    eventParams: {
      experimentName,
      experimentVariantName,
    },
  });
  const experimentNameToRegister = `experiment:${experimentName}`;
  mixpanel.trackPeopleData({
    [experimentNameToRegister]: experimentVariantName,
  });
  // mixpanel.identify();
  return mixpanel.sendNewEvent(
    {
      computedTrackingParams,
      newMixpanelFormattedEvent,
      paramsLog,
    },
    cb
  );
}
