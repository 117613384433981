import React from 'react';
import PropTypes from 'prop-types';
import { IconNew as Icon } from '../../Icon';
import StyledButton, {
  buttonColors,
  buttonSizes,
  buttonTypes,
  DescriptiveContent,
  IconContainer,
  TextContainer,
  TitleContainer,
  DescriptionContainer,
  LOADER_DATA_ROLE_SELECTOR,
} from './StyledButton';

export default class Button extends React.PureComponent {
  static propTypes = {
    /** Button type */
    buttonType: PropTypes.oneOf(Object.values(buttonTypes)),
    /** Button content. `content` will override this. */
    children: PropTypes.any,
    /** Additional class. */
    className: PropTypes.string,
    /** Hover color of button. */
    color: PropTypes.oneOf(Object.values(buttonColors)),
    /** Shortcut for children. Overrides children content. */
    content: PropTypes.any,
    /** Cypress data tag selector. */
    rcLabel: PropTypes.string,
    /** For more descriptive buttons, you can provide a description along with a title to display. */
    description: PropTypes.string,
    /** If button is disabled. */
    disabled: PropTypes.bool,
    /** If text should be bolded. */
    bold: PropTypes.bool,
    /** Name of icon to display. */
    iconName: PropTypes.string,
    /** Callback when button is clicked. */
    onClick: PropTypes.func,
    /** Size of the button. */
    size: PropTypes.oneOf(Object.values(buttonSizes)),
    /** For more descriptive buttons, you can provide a title along with a description to display. */
    title: PropTypes.string,
  };

  static buttonColors = buttonColors;
  static loaderDataRoleSelector = LOADER_DATA_ROLE_SELECTOR;

  renderDescriptiveContent = ({ iconName, title, description }) => {
    const hasText = title || description;
    if (iconName || hasText) {
      return (
        <DescriptiveContent>
          {iconName && (
            <IconContainer hasText={hasText}>
              <Icon name={iconName} />
            </IconContainer>
          )}
          {hasText && (
            <TextContainer hasIcon={!!iconName} className={'button-text-container'}>
              {title && <TitleContainer>{title}</TitleContainer>}
              {description && (
                <DescriptionContainer>{description}</DescriptionContainer>
              )}
            </TextContainer>
          )}
        </DescriptiveContent>
      );
    }
  };

  /**
   * Renders the content of the button. If `description`, `iconName`, or `title` props are passed,
   * additional descriptive content will be rendered.
   * @returns { React.Component} The button content.
   */
  renderContent = () => {
    const { content, children, iconName, title, description } = this.props;
    return (
      <>
        {this.renderDescriptiveContent({ iconName, title, description })}
        {content || children}
      </>
    );
  };

  render() {
    const {
      buttonType,
      className,
      rcLabel,
      bold,
      color,
      disabled,
      isPill,
      onClick,
      size,
      ...otherProps
    } = this.props;
    return (
      <StyledButton
        {...otherProps}
        bold={bold}
        buttonType={buttonType}
        className={className}
        rcLabel={rcLabel}
        color={color}
        disabled={disabled}
        isPill={isPill}
        onClick={onClick}
        size={size}
      >
        {this.renderContent()}
      </StyledButton>
    );
  }
}
