import React from 'react';
import PropTypes from 'prop-types';
import emoji from 'react-easy-emoji';

import omitStyled from '../../libs/omitStyled';

const EMOJI_CDN_BASE_URL = 'https://twemoji.maxcdn.com/2/';
const EMOJI_CDN_PNG_PATH = '72x72/';
const EMOJI_CDN_SVG_PATH = 'svg/';

const Container = omitStyled('div', ['size', 'emojiUrl'])`
  align-items: center;
  justify-content: center;
  display: inline-flex;
  font-size: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-image: url(${(props) => props.emojiUrl});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: ${(props) => props.size}px;
`;

const getEmojiUrl = (theString, assetType) => {
  const unicodeValue = emoji(theString, (code) => code);

  if (assetType === 'svg') {
    return `${EMOJI_CDN_BASE_URL}${EMOJI_CDN_SVG_PATH}${unicodeValue}.svg`;
  }

  return `${EMOJI_CDN_BASE_URL}${EMOJI_CDN_PNG_PATH}${unicodeValue}.png`;
};

const Image = ({ size, theString, assetType, className, value }) => (
  <Container
    size={size}
    emojiUrl={getEmojiUrl(value || theString, assetType)}
    className={className}
    data-emoji
  />
);

Image.defaultProps = {
  assetType: 'png',
  size: 32,
  theString: '',
  className: '',
  value: '',
};

Image.propTypes = {
  assetType: PropTypes.oneOf(['png', 'svg']),
  size: PropTypes.number,
  theString: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
};

export default Image;
