import React from 'react';
import styled from 'styled-components';

import ConfettiSvg from './svgs/confetti.svg';
import { ConfettiCannon } from '../Confetti';
import Emoji from '../Emoji';
import theme from '@matterapp/matter-theme';
import Panel from '../Panel';
import GamePanelBase from './GamePanelBase';

const StyledGamePanelBase = styled(GamePanelBase)`
  justify-content: flex-start;
  background-color: ${() => theme.colors.purples[40]};
  background-image: url(${ConfettiSvg});
`;

const Header = styled.div`
  color: white;
  line-height: 32px;
  font-size: 24px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: #fff;
  font-size: 36px;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const GameOver = () => (
  <StyledGamePanelBase>
    <Panel.Block.Basic>
      <Header>
        <IconContainer>
          <Emoji.Image theString="🎉" />
          <ConfettiCannon active />
        </IconContainer>
        <strong>Great work!</strong>
        <br />
        Come back later for new suggestions!
      </Header>
    </Panel.Block.Basic>
  </StyledGamePanelBase>
);

export default GameOver;
