import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        d="M4.41 13.047c-.328-1.226-.513-2.507-.433-3.808.065-1.296.419-2.627 1.127-3.788A6.237 6.237 0 0 1 6.42 3.895c.13-.11.249-.239.388-.339l.429-.284c.145-.09.284-.2.434-.274l.463-.22c1.241-.577 2.602-.797 3.928-.777 1.331.015 2.632.27 3.858.688 1.232.409 2.423.947 3.52 1.625.568.31 1.076.708 1.615 1.067.498.409 1.032.777 1.49 1.226l.713.653.673.698c.463.454.847.972 1.27 1.465 1.581 2.05 2.852 4.397 3.47 7.029.29 1.316.424 2.707.255 4.122-.165 1.411-.653 2.871-1.57 4.098-.893 1.236-2.209 2.178-3.62 2.706-1.415.539-2.9.653-4.306.559-2.832-.215-5.404-1.281-7.677-2.712a1.89 1.89 0 0 1-.593-2.612 1.891 1.891 0 0 1 2.682-.548l.015.01c1.79 1.25 3.833 2.178 5.877 2.422 1.012.115 2.029.075 2.916-.219.882-.29 1.665-.788 2.238-1.505 1.181-1.416 1.45-3.634 1.062-5.768-.4-2.158-1.391-4.257-2.702-6.126-.359-.449-.673-.927-1.067-1.35l-.568-.649L21 8.272c-.394-.424-.858-.768-1.286-1.156-.464-.34-.902-.718-1.396-1.012a14.731 14.731 0 0 0-3.046-1.58c-1.061-.414-2.178-.683-3.29-.748-1.106-.07-2.223.065-3.215.473l-.374.155c-.12.055-.229.134-.343.2l-.344.204c-.115.07-.21.17-.314.249-.434.319-.788.728-1.097 1.176-.608.898-.967 1.984-1.072 3.116-.12 1.131-.01 2.303.24 3.45v.004a.542.542 0 0 1-.414.648.563.563 0 0 1-.638-.404z"
        fill={fill}
      />
      <path
        d="M12.471 29.103l-3.808-4.247L3 25.584l2.861-4.935-2.442-5.16 5.583 1.197 4.152-3.918.583 5.678 5.01 2.736-5.22 2.313-1.056 5.608z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
