import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import { IconNew as Icon } from '../Icon';
import Shockwave from './Shockwave';
import Starburst from './Starburst';

const Header = styled.h1`
  font-weight: bold;
  line-height: 32px;
  font-size: 22px;
  text-align: center;
  color: #fff;

  margin-bottom: 36px;
`;

const EmojiWrapper = styled.div`
  margin-bottom: 0;
  height: 160px;
  max-height: 160px;

  svg {
    border-radius: 50%;
    animation: pulse-bomb-new 2s 0s ease-out;
    animation-delay: 200ms;
  }

  @keyframes pulse-bomb-new {
    0% {
      box-shadow: 0 0 8px 6px rgba(233, 233, 246, 0),
        0 0 0px 0px rgba(233, 233, 246, 0.2), 0 0 0px 0px rgba(233, 233, 246, 0);
      transform: scale(1);
    }
    10% {
      box-shadow: 0 0 8px 6px rgba(233, 233, 246, 0.2),
        0 0 12px 10px rgba(233, 233, 246, 0.2),
        0 0 12px 14px rgba(233, 233, 246, 0);
      transform: scale(1.08);
    }
    60% {
      box-shadow: 0 0 8px 6px rgba(233, 233, 246, 0),
        0 0 0px 0px rgba(233, 233, 246, 0.2), 0 0 0px 0px rgba(233, 233, 246, 0);
      transform: scale(0.96);
    }
    80% {
      transform: scale(1);
    }
  }
`;

const Footer = styled.h2`
  margin-top: 26px;
  max-width: 359px;

  line-height: 24px;
  font-size: 17px;
  text-align: center;
  color: ${() => theme.colors.purples[5]};
`;

const ThankYou = ({ headerText, subHeaderText }) => (
  <Starburst>
    <Shockwave />
    <Header>{headerText}</Header>
    <EmojiWrapper>
      <Icon name="thumbsUpCelebrate" height={144} width={144} />
    </EmojiWrapper>
    <Footer>{subHeaderText}</Footer>
  </Starburst>
);

ThankYou.propTypes = {
  headerText: PropTypes.node.isRequired,
  subHeaderText: PropTypes.node.isRequired,
};

export default ThankYou;
