import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  display: inline-flex;
  height: 40px;
  background: ${() => theme.colors.blacks[5]};
  border-radius: 32px;
  padding: 4px;
  font-weight: 500;
  line-height: 32px;
  font-size: 14px;
  color: ${() => theme.colors.blacks[90]};

  transition-duration: 0.1s;
  transition-property: background, color;

  span {
    margin-right: 12px;
  }

  svg circle,
  svg path {
    transition-duration: 0.1s;
    transition-property: fill, stroke;
  }
`;

Container.defaultProps = {};

export default Container;
