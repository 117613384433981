import { useState } from 'react';
import { useRequestFeedbackWorkspaces } from './useRequestFeedback';
import { useGiveFeedbackWorkspaces } from './useGiveFeedback';
import { useGiveKudosWorkspaces } from './useGiveKudos';
import { Composer } from '@matterapp/matter-ui';
import { useUpgradeModal } from 'routes/pages/workspaces/hooks/shared';
/*****************************************************************************/
/* WORKSPACES COMPOSER                                                       */
/*                                                                           */
/* PARAMS:                                                                   */
/*   firstName (string): First name of currentUser.                          */
/*   workspaceId (string): The id of the workspace.                          */
/*****************************************************************************/
export const useWorkspacesComposer = (params) => {
  const { workspace = {}, tenantAdmins } = params;
  const { members } = workspace;

  const [showKudosSelection, setShowKudosSelection] = useState(false);

  const workspaceMembers = members
    .filter((member) => !member.isSelf)
    .map((member) => member.person);
  const hasMultipleUsers = workspaceMembers && workspaceMembers.length >= 1;

  const { requestFeedbackProps } = useRequestFeedbackWorkspaces({
    ...params,
    hasMultipleUsers,
  });

  const { giveFeedbackProps } = useGiveFeedbackWorkspaces(params);

  const {
    kudosModalProps,
    giveKudosProps,
  } = useGiveKudosWorkspaces({
    ...params,
    hasMultipleUsers,
    onCloseKudosModal: () => {
      setShowKudosSelection(false);
    },
    showKudosSelection,
    showEmailInDropdown: false,
    showIsPublicDropdown: true,
    showLockedKudos: true,
    workspace,
    workspaceMembers,
    users: workspaceMembers,
  });

  const composerProps = {
    defaultTab: Composer.TabValues.KUDOS,
    requestFeedbackProps,
    giveFeedbackProps,
    giveKudosProps,
  };

  const upgradeModalProps = useUpgradeModal(workspace, tenantAdmins, false);

  return {
    composerProps,
    kudosModalProps,
    upgradeModalProps
  };
};
