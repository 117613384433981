import React from 'react';
import { Panel } from '@matterapp/matter-ui';
import ExampleSkillChart from 'assets/example-skill-chart.png';
import {
  Heading,
  ImageContainer,
  BannerContainer,
  Background,
  BannerContent
} from './EmptyStateStyles';

const EmptyState = () => (
  <>
    <div>
      <BannerContainer>
        <Background />
        <BannerContent>
          <Heading>
            Oh, no data.
            <br />
            Once you receive feedback you can track your progress and view it here.
          </Heading>
        </BannerContent>
      </BannerContainer>
    </div>
    <Panel.Base>
      <Panel.Header.Basic title={
        <Heading>
          Here’s a preview of what’s to come
        </Heading>
      }/>
      <Panel.Block.Basic>
        <ImageContainer>
          <img
            src={ExampleSkillChart}
            alt="Example Skill Chart"
          />
        </ImageContainer>
      </Panel.Block.Basic>
      <Panel.Footer.Empty />
    </Panel.Base>

  </>
);

export default EmptyState;
