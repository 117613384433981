import React from 'react';
import RequestRow from './RequestRow';
import { 
  TableHead,
  TableBody,
  Table,
  TableRow
} from '@mui/material';
import { 
  NoResultsText,
  HeaderCell
} from 'components/TableStyles';
import styled from 'styled-components';

const Caption = styled.caption({
  height: '50px',
  textAlign: 'center',
  'p:first-child': {
    marginTop: '10px'
  }
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `solid 1px ${theme.palette.blacks[10]}`
}));

export default function RewardRequestTable({
  isPendingTab,
  tenant,
  rewardRequests,
  lastColumnHeader,
  lastColumnText,
  emptyStateMessage,
  onClickApprove,
  onClickDecline,
  isApprovalLoading,
  isLoading,
  onClickMore
}) {
  return (
    <Table size='large'>
      {!rewardRequests.length ? (
        <Caption>
          <NoResultsText variant='h6' component='p'>
            {emptyStateMessage}
          </NoResultsText>
        </Caption>
      ) : null}
      <TableHead>
        <StyledTableRow>
          <HeaderCell visibleOnMobile>Name</HeaderCell>
          <HeaderCell>Request Date</HeaderCell>
          <HeaderCell visibleOnMobile>Reward</HeaderCell>
          <HeaderCell visibleOnMobile>Value</HeaderCell>
          <HeaderCell>{lastColumnHeader}</HeaderCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {rewardRequests.map((request) => {
          return (
            <RequestRow 
              key={request.id}
              tenant={tenant}
              isPendingTab={isPendingTab}
              rewardRequest={request}
              lastColumnText={lastColumnText}
              onClickApprove={onClickApprove}
              onClickDecline={onClickDecline}
              isApprovalLoading={isApprovalLoading}
              isLoading={isLoading}
              onClickMore={onClickMore}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};
