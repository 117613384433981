import useLocalStorage from '../useLocalStorage';
import useUserMetadata from '../useUserMetadata';

export const CURRENT_WORKSPACE = 'current-workspace-id';

export const useWorkspaceLocalStoage = () => {
  const { setItem, getItem } = useLocalStorage();
  return {
    getWorkspaceId: () => getItem(CURRENT_WORKSPACE),
    setWorkspaceId: (id) => setItem(CURRENT_WORKSPACE, id),
  };
};

export const useWorkspaceLocalStorageAndMetadata = () => {
  const { getWorkspaceId, setWorkspaceId } = useWorkspaceLocalStoage();
  const { setMetadata } = useUserMetadata();
  return {
    getWorkspaceId,
    setWorkspaceId: (id) => {
      setWorkspaceId(id);
      setMetadata({ [CURRENT_WORKSPACE]: id });
    },
  };
};

export default useWorkspaceLocalStoage;