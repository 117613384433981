

import Base from './List';
import Container from './ListContainer';
import WithHeader from './ListWithHeader';
import AddButton from './ListAddButton';
import Item from './ListItem';
import ItemStackContainer from './ListItemStackContainer';
import ItemActionBox from './ListItemActionBox';
import ItemActionItem from './ListItemActionItem';
import ItemWithAvatar from './ListItemWithAvatar';
import DragAndDrop from './DragAndDrop';

Base.Item = Item;
Base.AddButton = AddButton;
Base.Container = Container;
Base.ItemActionBox = ItemActionBox;
Base.ItemActionItem = ItemActionItem;
Base.ItemStackContainer = ItemStackContainer;
Base.ItemWithAvatar = ItemWithAvatar;
Base.WithHeader = WithHeader;
Base.DragAndDrop = DragAndDrop;

// DEPRECATED
export const List = Base;
export const ListContainer = Container; // Use List.Container
export const ListWithHeader = WithHeader; // Use List.WithHeader
export const ListItem = Item; // Use List.Item
export const ListItemStackContainer = ItemStackContainer; // Use List.ItemStackContainer
export const ListItemActionBox = ItemActionBox; // Use List.ItemActionBox

export default Base;

