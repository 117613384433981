import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { media, colors, spacing } from '@matterapp/matter-theme';
import { QualityPill } from '../Quality';
import { ButtonNew } from '../Button';
import { itemHalfMargin, qualitiesHeight } from './consts';

const QualityEndorsementsCopyContainer = styled.div`
  margin-bottom: ${spacing.single};
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.regular}
  line-height: ${theme.lineHeight.S};
  color: ${colors.blacks[60]};
`;

const QualitiesContainerHidden = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -${itemHalfMargin};
`;

const QualitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.borderRadius.L};
  background-color: ${colors.blacks[5]};
  padding: ${theme.padding.base};
  ${media.XS`
    padding: ${theme.padding.S};
  `}
`;

const StyledQualityPill = styled(QualityPill)`
  margin-bottom: ${spacing.half};
  ${media.XS`
    margin-bottom: 0;
    margin-right: ${spacing.half};
  `}
`;

const QualityPillContainer = styled.div`
  display: flex;
  flex-direction: column;
  div:last-child {
    margin-bottom: 0;
  }
  ${media.XS`
    flex-direction: row;
      div:last-child {
        margin-right: 0;
    }
  `}
`;

const DetailsButton = styled(ButtonNew).attrs({
  size: 'small',
})`
  flex-grow: 1;
  height: ${qualitiesHeight};
  line-height: ${qualitiesHeight};

  font-weight: normal;
  border-radius: ${theme.borderRadius.L};
  color: ${colors.blues[50]};
  background: ${colors.blacks[5]};
  transition: color 150ms ease;
  border: none;
  margin: 0 ${itemHalfMargin};
  padding: 0 ${spacing.half};
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    color: ${colors.purples[50]};
  }
`;

const QualityEndorsements = ({
  feedbackReceived,
  hideEndorsements,
  onClickDetails,
  children,
}) => {
  const { qualityEndorsements, skillRatings, feedback } = feedbackReceived;
  const endorsement = qualityEndorsements || feedback.qualityEndorsements;
  const ratings = skillRatings || feedback.skillRatings;

  if (hideEndorsements) {
    return (
      <QualitiesContainerHidden>
        <DetailsButton onClick={onClickDetails}>See the details</DetailsButton>
      </QualitiesContainerHidden>
    );
  } else if (!endorsement.length) {
    return <QualitiesContainerHidden />;
  }
  return (
    <QualitiesContainer>
      {ratings.length > 0 && (
        <QualityEndorsementsCopyContainer>
          {children}
        </QualityEndorsementsCopyContainer>
      )}
      <QualityPillContainer>
        {endorsement.map((quality) => (
          <StyledQualityPill key={quality.name} {...quality} />
        ))}
      </QualityPillContainer>

    </QualitiesContainer>
  );
};

QualityEndorsements.defaultProps = {
  hideEndorsements: false
};

QualityEndorsements.propTypes = {
  onClickDetails: PropTypes.func,
  hideEndorsements: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default QualityEndorsements;
