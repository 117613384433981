import React, { useState, useEffect } from 'react';
import { Modal, Dropdown } from '@matterapp/matter-ui';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import { useLazyQuery } from '@apollo/client';
import { Box } from '@mui/material';

export default function NotifyMembersModal(props) {
  const { isOpen, onClose, onSave, tenantId, header } = props;
  const [searchMembers, { loading: isLoadingResults, data }] = useLazyQuery(searchWorkspaceMembers);
  const [selectedMembers, updateSelectedMembers] = useState([]);
  const [canSave, updateCanSave] = useState(false);

  function resetAndClose() {
    updateSelectedMembers([]);
    onClose();
  }

  async function onSearch(value) {
    if (value) {
      searchMembers({
        variables: {
          tenantId,
          searchString: value
        }
      });
    }
  }

  function onChange(_, { value: updatedList }) {
    if (updatedList.length < selectedMembers.length) {
      const newMembers = selectedMembers.filter(({ value }) => updatedList.find(email => email === value));
      updateSelectedMembers(newMembers);
    }
  }

  function onSelect(_, item ) {
    if (item) {
      updateSelectedMembers([...selectedMembers, item.item]);
    }
  }

  function formatMembers(members) {
    return members.map((member) => {
      return {
        ...member,
        label: member.person.fullName,
        subLabel: member.person.email,
        value: member.person.email
      };
    });
  }
  
  useEffect(() => {
    if (!selectedMembers.length) {
      updateCanSave(false);
    } else {
      updateCanSave(true);
    }
  }, [selectedMembers]);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={resetAndClose}
      size="large"
    >
      <Modal.Header
        header={header}
        right={<Modal.Buttons.CloseIcon onClick={resetAndClose} />}
      />
      <Box sx={{ padding: 3, height: '200px' }}>
        <Dropdown.Email.Tags
          filterItems
          formatTagLabel={(email) => selectedMembers.find((member) => member.value === email)?.label}
          items={formatMembers(data?.searchMembers || [])}
          showValuesAsSubLabels
          isValidValue={() => true}
          onChange={onChange}
          onSearch={onSearch}
          onSelect={onSelect}
          isLoadingResults={isLoadingResults}
          emptyMenuMessageNoItems='No matches'
          placeholder="Enter name(s)"
          fluid
          isInitiallyPristine
        />
      </Box>
      <Modal.Footer.WithCancelSave
        canClickSave={canSave}
        onClickCancel={resetAndClose}
        onClickSave={() => {
          onSave(selectedMembers);
          resetAndClose();
        }}
      />
    </Modal>
  );
}
