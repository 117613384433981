import Card from './Card';
import Container from './Container';
import Header from './Header';
import HeaderStepText from './Headers/HeaderStepText';
import HeaderStackedText from './Headers/HeaderStackedText';
import HeaderContainer from './Headers/HeaderContainer';
import Footer from './Footer';
import Heading from './Heading';
import Page from './Page';
import BasicButton from './BasicButton';
import PrimaryButton from './PrimaryButton';
import HeaderButton from './HeaderButton';
import Text from './Text';
import TextBlock from './TextBlock';
import TextContainer from './TextContainer';
import ProfileBlock from './ProfileBlock';
import ProfileBlockNew from './ProfileBlockNew';
import CollapseBlock from './CollapseBlock';
import HeadingBlock from './HeadingBlock';
import AvatarBlock from './AvatarBlock';
import Scrolling from './Scrolling';
import { breakpoints } from './consts';

export default {
  breakpoints,
  Card,
  Container,
  Header,
  HeaderStepText,
  HeaderStackedText,
  Footer,
  Heading,
  Page,
  BasicButton,
  PrimaryButton,
  HeaderButton,
  Text,
  TextBlock,
  ProfileBlock,
  ProfileBlockNew,
  CollapseBlock,
  HeadingBlock,
  AvatarBlock,
  HeaderContainer,
  TextContainer,
  Scrolling,
};
