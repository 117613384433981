import PropTypes from 'prop-types';

export const Person = PropTypes.shape({
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string,
  userId: PropTypes.string,
  photoUrl: PropTypes.string,
});

export const feedbackRequestStatus = PropTypes.shape({
  type: PropTypes.string.isRequired,
  subType: PropTypes.string,
});

export const FeedbackRequest = PropTypes.shape({
  id: PropTypes.string.isRequired,
  eventTime: PropTypes.string.isRequired,
  invitedPeer: Person.isRequired,
  feedbackRequestStatus: feedbackRequestStatus.isRequired,
});
