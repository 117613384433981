import { graphql } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';
import apolloClient from 'config/apolloClient';
import { handleTrackSignup } from 'libs/tracking/signup';
import {
  CURRENT_USER_STATUS_QUERY,
  EMAIL_SIGNUP_LOGIN_MUTATION,
} from 'graphql-queries/queries';
import { SIGNUP_MEANS } from 'app-consts';

const withEmailSignupLoginMutation = graphql(EMAIL_SIGNUP_LOGIN_MUTATION, {
  name: 'emailSignupLoginMutation',
});

const withData = compose(
  withEmailSignupLoginMutation,
  withProps(({ emailSignupLoginMutation }) => ({
    handleEmailSignupLogin: async ({ email, handleSuccess, handleFailure }) => {
      try {
        const { data } = await emailSignupLoginMutation({
          variables: { email },
        });
        const { isNewRecord, user } = data.login;
        handleTrackSignup({
          isNewRecord,
          user,
          signupMeans: SIGNUP_MEANS.MANUAL_EMAIL_SIGNUP,
        });
        await apolloClient.query({
          query: CURRENT_USER_STATUS_QUERY,
          fetchPolicy: 'network-only',
        });
        await handleSuccess();
      } catch (error) {
        await handleFailure(error);
      }
    },
  }))
);

export default withData;
