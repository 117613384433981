import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledModal,
  ModalContainer,
  ModalCloseButton,
} from './ModalComponents';
import { IconClose } from '../Icon';
import Container from './Container';

export default class Modal extends React.PureComponent {
  static propTypes = {
    /** The background color of the success container. */
    backgroundColor: PropTypes.string,
    /** Additional content. Displays after success container */
    children: PropTypes.node,
    /** Additional style class. */
    className: PropTypes.string,
    /** Additional content shortcut. Overrides children */
    content: PropTypes.node,
    /** Hides the top right close button. */
    hideCloseButton: PropTypes.bool,
    /** Hides the background starburst. */
    hideBackground: PropTypes.bool,
    /** Callback when closing the modal. */
    onCloseModal: PropTypes.func.isRequired,
    /** Image to display in success container. */
    image: PropTypes.node,
    /** Show the shockwave animation in the success container. */
    showShockwave: PropTypes.bool,
    /** Show the star animation in the success container. */
    showStars: PropTypes.bool,
    /** The text color of the success container. */
    textColor: PropTypes.string,
    /** The title of the success container. */
    title: PropTypes.node,
    /** Controls the width of the modal */
    size: PropTypes.oneOf(['normal', 'narrow']),
  };

  static defaultProps = {
    hideCloseButton: false,
    size: 'normal',
  };

  renderContents = () => {
    const { children, size } = this.props;
    return (
      <ModalContainer size={size}>
        {this.renderContainer()}
        {children}
      </ModalContainer>
    );
  };

  renderContainer = () => {
    const {
      className,
      backgroundColor,
      content,
      image,
      showShockwave,
      showStars,
      textColor,
      title,
      hideBackground,
    } = this.props;

    return (
      <Container
        backgroundColor={backgroundColor}
        content={content}
        image={image}
        showShockwave={showShockwave}
        showStars={showStars}
        textColor={textColor}
        hideBackground={hideBackground}
        title={title}
        className={className}
      />
    );
  };

  renderCloseButton = () => {
    const { hideCloseButton, onCloseModal } = this.props;
    if (hideCloseButton) {
      return null;
    }
    return (
      <ModalCloseButton onClick={onCloseModal}>
        <IconClose />
      </ModalCloseButton>
    );
  };

  render() {
    const { isOpen, onCloseModal } = this.props;
    return (
      <StyledModal isOpen={isOpen} onRequestClose={onCloseModal}>
        {this.renderCloseButton()}
        {this.renderContents()}
      </StyledModal>
    );
  }
}
