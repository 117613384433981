import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clickable } from '../../../Clickable/Clickable';
import EditMessage from '../../EditMessage';
import theme from '@matterapp/matter-theme';
import { Heading } from './sharedStyle';

const MessageWrapper = styled.div`
  position: relative;
`;

const Clear = styled(Clickable)`
  position: absolute;
  top: 21px;
  ${({theme}) => theme.media.S`
    top: ${theme.spacing.singleAndHalf};
  `}

  right: 0;
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.base};
  &:hover {
    color: ${theme.colors.black};
  }
  &:active {
    color: ${theme.colors.purples[50]};
  }
`;

const MessageBox = ({
  customNote,
  heading,
  onMessageChange,
  clearText,
}) => {

  return (
    <MessageWrapper>
      <EditMessage
        message={customNote}
        maxLength={250}
        onChange={onMessageChange}
        showErrorOnEmpty
        allowErrorBeforeBlur
        label={
          <Heading label>
            <h3>{heading}</h3>
          </Heading>
        }
      />
      <Clear onClick={clearText}>
        Clear text
      </Clear>
    </MessageWrapper>
  );
};

MessageBox.propTypes = {
  heading: PropTypes.string,
  customNote: PropTypes.string,
  onMessageChange: PropTypes.func.isRequired,
  clearText: PropTypes.func.isRequired,
};

export default MessageBox;
