import { useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import apolloClient from 'config/apolloClient';
import { compose } from 'recompose';
import {
  withFeedbackInvitationTokenLogin,
} from 'hocs';
import Toast from 'components/Toast/Toast';
import {
  INVITATION_FROM_UUID_QUERY,
  IGNORE_INVITATION_MUTATION,
} from 'graphql-queries/queries';
import { useNavigate } from 'react-router-dom';

const PERIODS = {
  biweekly: 'couple of weeks',
  weekly: 'week',
  monthly: 'month',
  bimonthly: 'couple of months',
  quarterly: 'quarter'
};

function isPlural(frequency) {
  return (frequency === "biweekly" || frequency === "bimonthly");
}

/**
 * This component is used as a gateway to ignore the current
 * recurring feedback invitation and redirect to home page
 * based on the feedback token
 */
function DeclineRecurring({ 
  queryParams,
  invitationUuid,
  rfrequency,
  handleFeedbackInvitationTokenLogin,
  homePath
}) {
  const navigate = useNavigate();
  const { 
    currentUser, 
    isLoadingCurrentUser,
    refetchCurrentUser,
    signOut
  } = useContext(UserContext);
  const feedbackInvitationToken = queryParams.token;

  async function signInAndDecline() {
    if (!!feedbackInvitationToken) {
      await handleFeedbackInvitationTokenLogin({
        feedbackInvitationToken: queryParams.token,
        currentUser,
        signOut,
        refetchCurrentUser,
        onSuccess: () => {
          navigate(homePath);
        },
        onSameUserCurrentlyLoggedIn: () => {
          navigate(homePath);
        },
        onFailure: () => {
          navigate(homePath);
        },
      });
    } else {
      navigate(homePath);
    }

    try {
      const invitationData = await apolloClient.query({
        query: INVITATION_FROM_UUID_QUERY,
        variables: {
          invitationUuid
        },
      });

      const invitationId = invitationData.data.invitation && invitationData.data.invitation.id;
      if (!invitationId) {
        throw new Error("Invalid invitation uuid");
      }

      // ignore invitation
      await apolloClient.mutate({
        mutation: IGNORE_INVITATION_MUTATION,
        variables: {
          invitationId
        }
      });

      Toast.success(`You're all set! You'll receive an email ${isPlural(rfrequency) ? 
        'in ' : ''}next ${PERIODS[rfrequency]}`);
    } catch (error) {
      Toast.success('You’ve already completed this feedback request');
      console.error({ error });
    }
  }
  useEffect(() => {
    if (!isLoadingCurrentUser) {
      signInAndDecline();
    }
  }, [isLoadingCurrentUser]);

  return null;
}

export default compose(
  withFeedbackInvitationTokenLogin
)(DeclineRecurring);
