import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';

const QUICK_TIPS_HEADER = 'Quick Tips:';
const DEFAULT_SIDEBAR_WIDTH = 260;

/**
 * Gets the border color of the sidebar.
 * @param { Object } styleProps: Style props passed to the component.
 * @returns { String } The style string with the border style.
 */
/* istanbul ignore next */
const getBorderColor = ({ isLightBorder }) =>
  isLightBorder ? theme.colors.white : theme.colors.blacks[5];

const getMinHeight = ({ hasIcon, isTopSidebar }) => {
  if (isTopSidebar) {
    return hasIcon ? `max-height: 180px; overflow: visible;` : `max-height: 0px; overflow: hidden;`;
  }
  return 'max-height: auto';
};

const Container = styled.div`
  display: none;
  border-left: ${theme.spacing.quarter} solid ${getBorderColor};
  color: ${theme.colors.blacks[60]};
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  width: ${DEFAULT_SIDEBAR_WIDTH}px;
  padding-left: ${theme.spacing.single};
  transition: max-height ${theme.transitions.times.double}, overflow 0.2s ${theme.transitions.times.double};
  ${getMinHeight}
  ${theme.media.M`
    display: inline;
  `}
`;

const Header = styled.div`
  font-weight: ${theme.fontWeight.bold};
`;

const IconContainer = styled.div`
  max-height: ${theme.spacing.triple};
  margin-bottom: ${theme.spacing.single};
  &:empty {
    margin-bottom: 0px;
  }
`;

const Paragraph = styled.p`
  margin-top: ${theme.spacing.half};
  line-height: ${theme.lineHeight.M};
`;

const Sidebar = (props) => {
  const {
    children,
    className,
    content,
    header,
    icon,
    isLightBorder,
    isTopSidebar,
  } = props;
  return (
    <Container
      className={className}
      isLightBorder={isLightBorder}
      isTopSidebar={isTopSidebar}
      hasIcon={!!icon}
    >
      <IconContainer hasIcon={!!icon}>{icon}</IconContainer>
      {header && <Header>{header}</Header>}
      {content}
      {children}
    </Container>
  );
};

Sidebar.propTypes = {
  /** Contents of the sidebar. */
  children: PropTypes.node,
  /** Additioanl style class. */
  className: PropTypes.string,
  /** Additional / shorthand content of the sidebar. */
  content: PropTypes.node,
  /** Header text of the sidebar. */
  header: PropTypes.string,
  /** Icon to show in the sidebar. */
  icon: PropTypes.node,
  /** If light border color is used. */
  isLightBorder: PropTypes.bool,
  /** If the sidebar is the top most sidebar. */
  isTopSidebar: PropTypes.bool,
};

Sidebar.QuickTips = (props) => (
  <Sidebar header={QUICK_TIPS_HEADER} {...props} />
);

Sidebar.Paragraph = Paragraph;

export default Sidebar;
