import React from 'react';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import RewardsStorePage from 'routes/pages/rewards-store/RewardsStore';
import { Route } from 'react-router-dom';

export const RewardsStore = (
  <Route
    exact
    path={Resources.rewardsStore.pattern}
    element={(
      <PrivateRoute>
        <RewardsStorePage />
      </PrivateRoute>
    )}
  />
);

export const RewardsHistory = (
  <Route
    exact
    path={Resources.rewardsHistory.pattern}
    element={(
      <PrivateRoute>
        <RewardsStorePage />
      </PrivateRoute>
    )}
  />
);
