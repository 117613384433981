import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fill={fill}
      d="M10.4,5.9c0,1.1-0.6,2-1.4,2.5l0.6,0c0.9,0,1.7,0.7,1.7,1.7c0,0.5-0.4,0.8-0.8,0.8l-5.8,0
        c-0.5,0-0.8-0.4-0.8-0.8c0-0.9,0.7-1.7,1.7-1.7l0.6,0C5.2,7.9,4.6,7,4.6,5.9C4.6,4.3,5.9,3,7.5,3S10.4,4.3,10.4,5.9z M13.4,6.5
        c0-0.9,0-1.4,0.2-1.8C13.7,4.4,14,4.2,14.3,4c0.4-0.2,0.8-0.2,1.8-0.2l1.3,0c0.9,0,1.4,0,1.8,0.2c0.3,0.2,0.6,0.4,0.7,0.7
        C20,5.1,20,5.6,20,6.5l0,1.3c0,0.9,0,1.4-0.2,1.8c-0.2,0.3-0.4,0.6-0.7,0.7c-0.4,0.2-0.8,0.2-1.8,0.2l-1.3,0c-0.9,0-1.4,0-1.8-0.2
        c-0.3-0.2-0.6-0.4-0.7-0.7c-0.2-0.4-0.2-0.8-0.2-1.8L13.4,6.5z M3.6,16.9c0.1,0.4,0.5,0.7,1.1,1.4l0.9,0.9c0.7,0.7,1,1,1.4,1.1
        c0.3,0.1,0.7,0.1,1,0c0.4-0.1,0.7-0.5,1.4-1.1l0.9-0.9c0.7-0.7,1-1,1.1-1.4c0.1-0.3,0.1-0.7,0-1c-0.1-0.4-0.5-0.7-1.1-1.4l-0.9-0.9
        c-0.7-0.7-1-1-1.4-1.1c-0.3-0.1-0.7-0.1-1,0c-0.4,0.1-0.7,0.5-1.4,1.1l-0.9,0.9c-0.7,0.7-1,1-1.1,1.4C3.5,16.2,3.5,16.5,3.6,16.9z
        M16.7,20.1c2.1,0,3.7-1.7,3.7-3.8c0-2.1-1.7-3.7-3.8-3.7c-2.1,0-3.7,1.7-3.7,3.8C12.9,18.4,14.6,20.1,16.7,20.1z"
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
