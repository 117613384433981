import React from 'react';
import Base from '../../../svgBase';

const DEFAULT_HEIGHT = 35;
const DEFAULT_WIDTH = 31;
const PATH = `M0.725586 29.5454H17.7029V34.9999H26.771V29.5454H30.7938V22.1817H26.771V0.0908203H14.771L0.725586 22.1817V29.5454ZM17.9074 22.1817H10.0665V21.909L17.6347 9.909H17.9074V22.1817Z`;

const Four = (props) => (
  <Base height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
);

export default Four;
