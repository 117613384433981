export const BASE = 'kudosMeowow';

export const CAT = `${BASE}-cat`;

export const EYE = {
  LEFT: `${BASE}-eye-left`,
  RIGHT: `${BASE}-eye-right`,
};

export const MOUTH = `${BASE}-mouth`;

export const WHISKERS = {
  LEFT: `${BASE}-whiskers-left`,
  RIGHT: `${BASE}-whiskers-right`,
};

export const NECKLACE = {
  GROUP: `${BASE}-necklace`,
  BELL: `${BASE}-bell`,
};

export const HAND = `${BASE}-hand`;

export const BACKGROUND = `${BASE}-background`;

export const STAR = {
  TOP: `${BASE}-star-top`,
  LEFT: `${BASE}-star-left`,
  RIGHT_1: `${BASE}-star-right-1`,
  RIGHT_2: `${BASE}-star-right-2`,
};

export const CIRCLE = {
  TOP: `${BASE}-circle-top`,
  LEFT_1: `${BASE}-circle-left-1`,
  LEFT_2: `${BASE}-circle-left-2`,
  BACK: `${BASE}-circle-back`,
};

export const RING = `${BASE}-ring`;

export const PLUS = `${BASE}-plus`;
