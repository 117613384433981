import React from 'react';
import styled from 'styled-components';
import Base from './Base';

const DEFAULT_HEIGHT = 960;
const DEFAULT_WIDTH = 1280;
const DEFAULT_VIEWBOX = `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`;

const Gray = styled(Base.path).attrs({
  fillColor: '#E6E6E7',
  grayScaleColor: '#E6E6E7',
  d: `M203.9,462.1c6.4-0.3,9.9,3.6,10.9,9.4c1.2,6.7-4.7,11.3-9.6,11.5
  c-4.6,0.2-10.7-4.6-11.2-8.6C193.5,468.7,197.9,461.3,203.9,462.1z M204.8,478c3.3,0,5.7-2.1,5.7-5.2c0-3.6-2.6-6.1-6.5-6.1
  c-2.9,0.1-5.2,2.5-5.1,5.4c0,0.1,0,0.3,0,0.4C199.2,475.7,200.7,478.1,204.8,478z M894.1,645c7.6-0.1,13.8,6,13.9,13.6
  c0,0.3,0,0.5,0,0.8c-0.2,5.3-6.1,12.2-13.2,11.6c-7.5-0.6-13.2-5.5-12.8-13.4C882.4,650.5,887.8,644.8,894.1,645z M895.1,666.9
  c4.6,0.1,8.7-4,8.8-8.1c0-5.2-4.2-9.4-9.3-9.5c-3.7-0.1-8.4,5.3-8.6,9.5C885.7,662.8,891.4,666.9,895.1,666.9L895.1,666.9z
   M45.6,679c-5.4,0-9.6-4-9.6-8.8c0.1-5.5,4-9.2,9.7-9.2c5-0.1,9.2,3.9,9.3,8.9c0,0.2,0,0.3,0,0.5C54.9,675.2,50.7,679,45.6,679z
   M40.5,670c0,2.5,2,4.6,4.6,4.6c0.1,0,0.2,0,0.3,0c2.6,0.2,4.9-1.8,5.1-4.4c0-0.1,0-0.2,0-0.2c-0.1-2.7-2.4-4.9-5.2-4.8
  C42.7,665.4,40.6,667.4,40.5,670L40.5,670z M715,800.1c0,3.2-2.6,5.9-5.9,5.8c0,0,0,0,0,0c-4,0.2-6.9-2.2-7.1-5.9
  c0-3.4,2.7-6.1,6.1-6.1c0,0,0,0,0.1,0c3.6-0.2,6.6,2.5,6.9,6C715,800.1,715,800.1,715,800.1z M708.5,803.8c2.5,0,4.4-1.6,4.3-3.7
  c0-1.5-2.9-3.9-4.6-4c-2-0.1-3.8,1.4-4,3.4c0,2.3,1.8,4.2,4.1,4.2C708.4,803.8,708.4,803.8,708.5,803.8z M951.1,811.9
  c0-4.1,2.6-6,4.7-5.9c2.2,0,5.3,3.1,5.2,5.2c-0.1,2.9-2.6,5.9-4.9,5.8C953.9,816.8,950.5,814.8,951.1,811.9z M955.6,813.7
  c0.3,0,0.6,0,0.8,0c0.4-0.7,1.1-1.5,1-2.1c0-0.6-0.9-1.2-1.4-1.8c-0.6,0.6-1.5,1.2-1.6,1.9C954.4,812.2,955.2,813,955.6,813.7z
   M894.7,714c-2.7,0-4.7-1.7-4.7-3.9c0.1-2.8,2.5-5.1,5.3-5.1c2.3,0,4.7,2.1,4.7,4C900,712.5,898.4,714,894.7,714z M897,710.1
  c0-0.2,0-0.4,0-0.7c-0.5-0.3-1.1-1-1.5-0.9c-0.7,0.3-1.4,0.8-2,1.3c0.4,0.4,0.9,1.2,1.3,1.2C895.6,710.8,896.3,710.5,897,710.1z
   M493.3,774c-1.6,0-3.3-2.2-3.3-4.1c0-1.9,1.8-3.9,3.5-3.9c2.1,0.3,3.6,2.1,3.5,4.2C497,772.3,495.4,774,493.3,774z M493.6,767.6
  c-0.8,0.9-1.6,1.6-1.5,2c0.3,0.8,0.7,1.5,1.3,2.1c0.5-0.5,1.3-1,1.5-1.7C494.9,769.5,494.2,768.7,493.6,767.6L493.6,767.6z
   M1102.9,264.5c0,5.6-3.2,9.4-8,9.5c-7.3,0.1-9.7-3.6-9.9-9c-0.2-4.8,4.3-8.9,9.6-9C1098.4,255.9,1103.8,260.5,1102.9,264.5z
   M1093.9,261.8c-1.8,0-3.4,1.4-3.4,3.3c-0.1,1.8,1.3,3.4,3.2,3.5c1.9,0,3.8-1.4,3.9-2.8C1097.4,263.7,1095.9,262.1,1093.9,261.8z
   M508.3,553c-3.1,0-5.3-2.8-5.3-6.5c0-2.9,2.3-5.6,4.8-5.5c2.9,0.1,5.2,2.8,5.2,6C513,550,510.6,553,508.3,553z M510.2,547.2
  c0.1-1.8-0.7-3-2.3-3c-1.2-0.2-2.3,0.7-2.4,1.9c0,0.1,0,0.2,0,0.2c-0.1,1.7,1.2,3.1,2.8,3.2C509.7,549.7,510.1,548.7,510.2,547.2z
   M910,527.6c-0.1,3.1-2.4,5.5-5.1,5.3c-2.7-0.1-4.8-2.3-4.9-5c0.2-2.9,2.6-5.1,5.5-5C907.9,523.1,910.1,525.5,910,527.6z
   M907.3,527.4c0-1-0.8-1.9-1.9-1.9c0,0-0.1,0-0.1,0c-1.2-0.1-2.4,0.7-2.5,2c0,0.1,0,0.2,0,0.3c-0.1,1.3,0.8,2.5,2.1,2.7
  C906,530.5,907.3,529,907.3,527.4z M222.4,848c-2.8,0.3-7.5-3.8-7.3-6.8c0.3-5,2.8-7,6.9-7.2c3.9-0.1,5.8,3.5,6.1,7.2
  C228.3,844.6,225.2,848,222.4,848z M221.3,836.4c-2.5,0-4,1.7-4,4.9c0,2.2,2.3,4,5,4c1.6,0,3.4-2.2,3.5-4.1
  C225.8,838.7,223.8,836.6,221.3,836.4z M1231.6,324.9c-17.2,1.6-22.5-20.6-8.8-28.3C1243.8,286.9,1255.8,323.6,1231.6,324.9z
   M1230,300.2c-13.4-0.7-13.4,20.3-0.2,19.9C1244.5,320.7,1242.6,300.6,1230,300.2z M53.6,507.9c0.9-3.2,1.4-7.6,5.3-10.4
  c4.1-2.9,8-6.2,12.2-8.8c3.8-2.2,7.8-4.2,11.8-5.9c1.5-0.8,3.5-1.1,3.2-3.5c0-0.4,0.8-1.2,1.3-1.3c0.8-0.1,1.6,0.2,2.1,0.7
  c0.8,1.2,1.5,1,2.7,0.7c5.4-1,10.7-0.5,15.5,2.5c0.3,0.2,0.8,0.4,0.9,0.8c0.2,0.7,0.4,1.5,0.6,2.3c-0.7,0.1-1.5,0-2.2-0.1
  c-1.8-0.8-3.4-1.9-5.3-2.5c-3.2-0.9-6.6-0.9-9.8,0.1c-5.2,1.4-9.7,4-14.5,6.4c-5.3,2.7-10.3,6-14.8,9.9c-2.7,2.3-5.5,4.8-6.2,8.8
  c-0.4,2.3-0.1,4.1,1.4,5.8c0.3,0.3,0.6,0.7,0.8,1.1c0.1,0.5,0.2,1.1,0.2,1.7c-0.6-0.1-1.4,0.1-1.8-0.3
  C54.5,514.1,53.2,511,53.6,507.9z M72.8,520.4c-1.7,0.1-3.4,0.2-4.2-1.6c-0.8-1.7-0.1-3.2,1-4.5c3.9-4.9,9.2-6.7,15.2-6.7
  c2.3,0,3.7,2.2,2.5,4.1c-1.1,2.1-2.7,3.8-4.7,5.2c-3,1.8-6.4,2.8-9.6,4.1C72.9,520.8,72.8,520.6,72.8,520.4z M47.6,525.4
  c-5.3,0-8.1-1.5-10.6-4.9c-3-4.1-2-8-0.9-12.1c2.3-8.5,8.6-14.1,14.5-20c0.4-0.4,0.8-0.7,1.4-0.9c0.4-0.1,0.9,0,1.3,0.3
  c0.2,0.4,0.3,0.8,0.1,1.3c-0.3,0.6-0.7,1.1-1.2,1.6c-2.9,3.1-5.9,6-8.6,9.2c-3.4,3.9-5.4,8.8-5.9,14c-0.4,4.4,3.7,8.7,8.1,8.9
  c2.8,0,5.7-0.7,8.1-2.1c0.9-0.5,2.1-1.3,2.8,0.1c0.7,1.4-0.3,2.1-1.6,2.5C52.3,524.1,49.4,524.9,47.6,525.4z M103.7,494.9
  c-0.6-0.5-1.3-1-1.9-1.5c0.7-0.5,1.4-1.4,2.1-1.5c4.8-0.1,9.4,0.2,12.2,5.2c2,3.6,1.3,7.1,0.3,10.8c-1.5,5.8-5.1,10.4-8.6,14.8
  c-3.2,4-7.5,7.2-11.4,10.6c-0.4,0.3-1.2,0.1-1.8,0.1c0.1-0.7-0.1-1.6,0.2-2c1.6-1.7,3.4-3.1,5-4.7c3-3.1,6-6.3,9-9.6
  c3.5-3.9,4.6-9,6-13.8c1-3.6-2.5-7.8-6.2-8.1c-1.5-0.2-2.9-0.5-4.4-0.8C103.9,494.5,103.8,494.7,103.7,494.9z M100.3,507.7
  c-0.5-1.7-0.8-3.4-1.1-5.1c0.1-0.9,0.2-1.7,0.6-2.5c0.6,0.5,1.5,1,1.7,1.6c1.2,4.1,1.4,7.9-1.1,11.8c-3.2,5.1-7.7,8.6-12.5,12
  c-3.9,2.8-8.1,5.1-12.6,6.9c-2,0.8-3.9,1.9-5.9,2.6c-0.7,0.2-1.7-0.4-2.5-0.6c0.5-0.7,0.8-1.7,1.5-2c3-1.3,6.1-2.3,9-3.6
  c2.3-1.1,4.5-2.4,6.7-3.9c6-3.9,11.6-8.2,15.2-14.6C99.6,509.6,100,508.7,100.3,507.7z M58.1,486.9c-0.7-0.5-1.5-0.8-1.7-1.4
  c-0.1-0.6,0.2-1.2,0.7-1.6c3.6-2,7.2-3.9,10.9-5.8c0.4-0.2,1.1,0.2,1.7,0.3c-0.2,0.6-0.2,1.4-0.6,1.7c-0.7,0.5-1.4,1-2.2,1.4
  c-2.1,1.3-4.1,2.6-6.2,3.8C59.9,485.9,59,486.3,58.1,486.9z M91.9,536.3c-0.5,0.3-1,0.6-1.6,0.9c-2.6,1.3-5.1,2.6-7.7,3.7
  c-0.5,0.2-1.3-0.3-2-0.4c0.2-0.6,0.4-1.4,0.8-1.6c2.8-1.5,5.6-2.8,8.4-4.1c0.4-0.2,1.2,0.3,1.8,0.5C91.8,535.7,91.8,536,91.9,536.3
  z`,
})``;

const Orange = styled(Base.path).attrs({
  fillColor: '#F2A645',
  grayScaleColor: 'transparent',
  d: `M1087.4,870.8c3.9-0.3,4.1,7.5,0.3,8C1083.8,879.1,1083.1,870.3,1087.4,870.8z M787.5,756
  c-1.9,0.3-3.6-2.8-1.5-4.5C788.4,749.2,790.6,755.1,787.5,756z M787,753.6c-0.5,0.2-0.2,0.6,0.1,0.3
  C787,753.9,787.1,753.7,787,753.6z M785.3,754.2c0.1,0.1,0.1,0.3,0.3,0.3C785.6,754.4,785.5,754.2,785.3,754.2
  C785.3,754.2,785.3,754.2,785.3,754.2z M850.4,644.5c0-0.1-0.1-0.2-0.2-0.2C850.2,644.5,850.2,644.5,850.4,644.5z M850,644.6
  c0.1,0.1,0,0.4,0.2,0.3C850.1,644.9,850.2,644.3,850,644.6z M849.4,645.2C849.4,645.1,849.4,645.2,849.4,645.2
  C849.4,645.2,849.3,645.2,849.4,645.2z M849.2,643.9C849.2,644,849.3,644,849.2,643.9L849.2,643.9z M848.7,644.3
  c-0.1,0-0.1,0.2,0,0.1V644.3z M848.6,644.8C848.6,644.9,848.7,644.9,848.6,644.8C848.7,644.8,848.7,644.8,848.6,644.8z
   M848.6,645.1C848.6,645,848.5,645,848.6,645.1L848.6,645.1z M848.2,646.2C848.2,646.1,848.1,646.1,848.2,646.2
  C848.1,646.2,848.1,646.2,848.2,646.2z M847.9,646.4C847.9,646.3,847.9,646.3,847.9,646.4C847.8,646.4,847.8,646.4,847.9,646.4z
   M847.7,645.7C847.7,645.6,847.7,645.6,847.7,645.7C847.7,645.7,847.7,645.7,847.7,645.7L847.7,645.7z M847.5,646
  c0,0.1,0,0.2,0.1,0.2C847.7,646.1,847.6,646,847.5,646L847.5,646z M847,644C847,644,847.1,644,847,644C847.1,644,847.1,644,847,644
  L847,644z M847,644.6C847,644.7,847,644.6,847,644.6C847,644.6,847,644.6,847,644.6L847,644.6z M847,645.1c0,0.1-0.1,0.2,0.1,0.2
  C847,645.2,847.1,645,847,645.1L847,645.1z M846.8,644.8C846.8,644.7,846.8,644.7,846.8,644.8C846.8,644.8,846.8,644.8,846.8,644.8
  L846.8,644.8z M846.7,644.2C846.7,644.3,846.7,644.3,846.7,644.2C846.7,644.2,846.8,644.2,846.7,644.2L846.7,644.2z M846.7,645.6
  C846.7,645.7,846.7,645.7,846.7,645.6C846.7,645.6,846.8,645.6,846.7,645.6z M846.4,643.9C846.4,643.8,846.4,643.8,846.4,643.9
  C846.4,643.9,846.4,643.9,846.4,643.9z M846.2,643.8c0.1,0,0.2-0.3,0.1-0.3C846.3,643.6,846.2,643.6,846.2,643.8L846.2,643.8z
   M846,645.9c-0.1,0-0.1,0.2,0,0.1V645.9z M845.9,645.7v-0.1C845.8,645.5,845.8,645.8,845.9,645.7z M845.8,646.4
  C845.9,646.3,845.8,646.3,845.8,646.4L845.8,646.4z M845.6,645.3c-0.1,0-0.1,0.1-0.3,0.1C845.4,645.5,845.9,645.5,845.6,645.3
  L845.6,645.3z M845.7,646.4v-0.1C845.6,646.2,845.6,646.5,845.7,646.4L845.7,646.4z M845.6,644.5
  C845.6,644.6,845.6,644.6,845.6,644.5C845.7,644.5,845.6,644.5,845.6,644.5L845.6,644.5z M845.5,641.1
  C845.5,641,845.4,641.1,845.5,641.1L845.5,641.1z M845.5,645.6c-0.2,0.1-0.1-0.1-0.2-0.1C845.2,645.6,845.5,645.8,845.5,645.6
  L845.5,645.6z M845.2,643.5c-0.1,0-0.1,0.2,0,0.1V643.5z M845.2,646.6c0-0.3-0.1-0.2-0.1,0.1C845.2,646.7,845.1,646.5,845.2,646.6
  L845.2,646.6z M844.4,645.3C844.4,645.4,844.4,645.3,844.4,645.3C844.5,645.3,844.5,645.3,844.4,645.3L844.4,645.3z M844.3,645.5
  C844.3,645.4,844.2,645.4,844.3,645.5C844.2,645.5,844.2,645.5,844.3,645.5L844.3,645.5z M843,643.8C843,643.9,843,643.9,843,643.8
  C843.1,643.8,843.1,643.8,843,643.8L843,643.8z M842.9,641.9C842.9,641.8,842.8,641.9,842.9,641.9
  C842.8,641.9,842.8,641.9,842.9,641.9L842.9,641.9z M842.4,642.1C842.3,642.1,842.3,642.2,842.4,642.1L842.4,642.1z M841.3,642.8
  C841.3,642.9,841.3,642.9,841.3,642.8C841.3,642.8,841.3,642.8,841.3,642.8L841.3,642.8z M850.2,646.1c-0.1,0.1,0,0.2,0.1,0.2
  C850.3,646.3,850.3,646.1,850.2,646.1L850.2,646.1z M845.6,651.9c5.6,0,6.1-8.4,4.9-6.7c0.2-0.1,0.6,0.5,0,0.5
  c0.1-0.6-0.4-0.3-0.8-0.4c0.2-0.2,0.2-0.3,0.2,0.1c0.2-0.1,0.3-0.5,0-0.4c-0.1-0.3,0.2-0.1,0.2-0.8c-0.3,0.5-0.1-0.2-0.4-0.3
  c0.1,0.6-0.3-0.3-0.5-0.5c0.3,0,0.3,0.5,0.1-0.3c-0.2,0.2-0.2,0.3-0.3,0c0.2,0.2,0.1-0.1,0.3-0.3c0,0.3,0.3,0.3,0.2,0.6
  c0.2-0.2,0.1,0.3,0.1,0.5c0.3-0.2,0.4,0,0.3,0.3c1.4-1.2-3.2-3.4-4.1-3.1c-0.1-0.3-0.5,0.3-0.2,0.5c-0.6-1.2-1.7,0-2.6,0.1
  c0.1,0.2,0.2,0.7-0.1,0.4c0,0.8-0.2-0.6-0.6,0.2c-0.4-0.5,0,0.4-0.4,0.2C838.3,644,839.9,652.9,845.6,651.9L845.6,651.9z
   M850,643.8C850,643.9,849.9,643.8,850,643.8L850,643.8z M849.9,643.8c-0.3,0.1-0.3-0.2-0.1-0.3
  C849.8,643.6,849.9,643.6,849.9,643.8L849.9,643.8z M849.9,643.4c0,0.2-0.2,0-0.1-0.1C849.9,643.2,849.8,643.4,849.9,643.4
  L849.9,643.4z M846.3,641.1c0.1,0,0,0.1,0.1,0.1C846.3,641.3,846.3,641.2,846.3,641.1L846.3,641.1z M845.6,641.3
  c0.1-0.1,0.6-0.1,0.5,0.1C846,641.2,845.7,641.5,845.6,641.3L845.6,641.3z M846.5,641.2c0.2,0,0.1,0.1,0.1,0.2
  C846.5,641.4,846.5,641.3,846.5,641.2L846.5,641.2z M845.8,641.5C845.8,641.4,845.8,641.5,845.8,641.5
  C845.8,641.5,845.9,641.5,845.8,641.5L845.8,641.5z M844.6,641.3C844.6,641.2,844.6,641.2,844.6,641.3
  C844.7,641.3,844.7,641.3,844.6,641.3L844.6,641.3z M849.8,648C849.8,648.1,849.7,648.1,849.8,648C849.7,648,849.7,648,849.8,648
  L849.8,648z M849.9,647.8C849.9,647.9,849.9,647.9,849.9,647.8C849.8,647.8,849.8,647.8,849.9,647.8L849.9,647.8z M850,647.3v0.1
  C849.9,647.5,850,647.2,850,647.3L850,647.3z M850.2,647c0.1,0,0,0.1,0.1,0.1C850.1,647.2,850.2,647,850.2,647L850.2,647z
   M850.6,646.8C850.6,646.9,850.6,646.8,850.6,646.8C850.5,646.8,850.5,646.8,850.6,646.8L850.6,646.8z M850.9,646.6v0.1
  C850.7,646.8,850.8,646.6,850.9,646.6L850.9,646.6z M850.8,646.2c-0.1,0.2-0.1,0.6-0.1,0.1C850.8,646.3,850.7,646.1,850.8,646.2
  L850.8,646.2z M850.7,646c-0.1,0.2-0.3,0-0.2-0.1C850.7,645.9,850.5,646.1,850.7,646L850.7,646z M850.2,645.5
  c-0.1,0.1,0.6,1.6,0.1,1.4c0.2-0.2,0.1-0.7-0.1-0.4c0.1,0.1,0.1,0.3,0,0.4c0,0-0.1,0.1-0.1,0.1c0.1-0.1,0-0.3,0.1-0.3
  c-0.2-0.1-0.4-0.2-0.1-0.3c-0.4,0-0.1,0-0.1-0.4C850.4,646.7,849.3,645.3,850.2,645.5L850.2,645.5z M849.1,646.5
  c-0.1,0-0.1-0.2,0-0.1V646.5z M849.5,646.4C849.5,646.3,849.5,646.4,849.5,646.4C849.5,646.4,849.5,646.4,849.5,646.4L849.5,646.4z
   M849.6,646.1c-0.1,0.2-0.3,0.2-0.2-0.1C849.4,646,849.4,646.1,849.6,646.1L849.6,646.1z M849.7,645.5
  C849.7,645.6,849.6,645.5,849.7,645.5L849.7,645.5z M849.6,645.3C849.6,645.4,849.5,645.4,849.6,645.3
  C849.5,645.3,849.5,645.3,849.6,645.3L849.6,645.3z M849.7,644.3C849.7,644.4,849.6,644.4,849.7,644.3L849.7,644.3z M849.3,643.6
  C849.3,643.7,849.2,643.7,849.3,643.6C849.2,643.6,849.2,643.6,849.3,643.6L849.3,643.6z M848.9,643.2c-0.1,0.2-0.4-0.1-0.1-0.1
  C848.8,643.1,848.7,643.2,848.9,643.2L848.9,643.2z M848,643.6c-0.1,0-0.1-0.2,0-0.1V643.6z M848.1,643.6
  C848.2,643.6,848.1,643.6,848.1,643.6C848.1,643.6,848.1,643.6,848.1,643.6L848.1,643.6z M847.5,643.6c-0.2-0.1-0.2-0.3-0.2-0.6
  C847.4,643.2,847.5,643.4,847.5,643.6L847.5,643.6z M846.8,642.9v0.1C846.7,643.1,846.7,642.9,846.8,642.9L846.8,642.9z
   M847.9,642.9C848,642.9,847.9,642.9,847.9,642.9C847.9,642.9,847.9,642.9,847.9,642.9z M847,642.9c0.1,0.1,0.3,0.6,0.1,0.5
  C847.1,643.2,847,643.2,847,642.9L847,642.9z M847.5,643.3C847.6,643.3,847.6,643.3,847.5,643.3
  C847.5,643.3,847.5,643.3,847.5,643.3L847.5,643.3z M847.6,643.5C847.6,643.4,847.6,643.4,847.6,643.5
  C847.6,643.5,847.7,643.5,847.6,643.5L847.6,643.5z M848.2,643.4c0-0.1-0.1-0.3,0.1-0.3C848.2,643.2,848.3,643.4,848.2,643.4
  L848.2,643.4z M848.3,643.1c0.4-0.2,0.2,0,0,0.2V643.1z M848.6,643C848.6,643.1,848.5,643.1,848.6,643L848.6,643z M848.9,642.7v0.1
  C848.7,642.9,848.8,642.7,848.9,642.7L848.9,642.7z M849.1,642.5v0.1C849,642.7,849,642.4,849.1,642.5L849.1,642.5z M848.8,642.6
  c0.1-0.2-0.1-0.2-0.1-0.4C848.8,642.2,848.9,642.5,848.8,642.6L848.8,642.6z M845.8,641.8C845.8,641.9,845.8,641.9,845.8,641.8
  C845.7,641.8,845.7,641.8,845.8,641.8L845.8,641.8z M845.1,641.5C845.1,641.5,845.1,641.5,845.1,641.5
  C845,641.4,845,641.5,845.1,641.5z M844.6,641.6C844.5,641.6,844.6,641.5,844.6,641.6L844.6,641.6z M844.9,641.4
  C844.9,641.4,844.9,641.4,844.9,641.4C844.8,641.3,844.8,641.4,844.9,641.4L844.9,641.4z M843.9,641.5
  C843.9,641.4,843.9,641.4,843.9,641.5C844,641.5,844,641.5,843.9,641.5L843.9,641.5z M844.1,641.5c0.1,0,0,0.1,0.1,0.2
  C844.1,641.7,844.1,641.5,844.1,641.5L844.1,641.5z M844,641.6c0,0.1-0.1,0.2-0.1,0.2C843.9,641.7,843.9,641.6,844,641.6L844,641.6
  z M843.4,641.7C843.5,641.7,843.5,641.7,843.4,641.7C843.4,641.8,843.4,641.8,843.4,641.7L843.4,641.7z M843.2,641.8
  c0,0.1-0.1,0.1-0.1,0.1C843.1,641.9,843.1,641.8,843.2,641.8z M845.8,641.9c0.1,0,0,0.1,0.1,0.1C845.8,642,845.9,641.9,845.8,641.9
  L845.8,641.9z M843.3,642C843.3,641.9,843.4,642,843.3,642L843.3,642z M845.7,642.1C845.7,642,845.8,642.1,845.7,642.1L845.7,642.1
  z M848.1,642.2v-0.3C848.2,641.9,848.3,642.3,848.1,642.2z M848.4,642.1c0.1,0,0,0.1,0.1,0.1C848.4,642.3,848.4,642.2,848.4,642.1z
   M846.6,642.3C846.6,642.4,846.6,642.3,846.6,642.3C846.7,642.3,846.6,642.3,846.6,642.3z M843.2,642.3c0.1,0,0,0.1,0.1,0.1
  C843.1,642.4,843.2,642.3,843.2,642.3L843.2,642.3z M848.5,642.4c0.2,0.1,0.2,0.3,0.2,0.5C848.5,642.8,848.6,642.6,848.5,642.4
  L848.5,642.4z M846.2,642.6C846.1,642.6,846.1,642.5,846.2,642.6L846.2,642.6z M842.3,642.4c0.1,0.1,0.1,0.1,0.2,0
  C842.4,642.5,842.2,642.6,842.3,642.4L842.3,642.4z M843,642.5c0.2-0.1,0.1,0.3,0.3,0.1c0,0.1-0.1,0.1-0.1,0.3
  c-0.1-0.1-0.1-0.2-0.2-0.1C843,642.7,843,642.6,843,642.5L843,642.5z M843.9,643c0.1,0.5,0,0.1-0.3,0
  C843.7,642.7,843.7,643.1,843.9,643L843.9,643z M843.1,643c0.1,0,0,0.1,0.1,0.2C843,643.2,843.1,643.1,843.1,643L843.1,643z
   M842.9,643.2c0.1-0.2-0.1-0.2-0.1-0.3C842.9,642.9,843.1,643.1,842.9,643.2L842.9,643.2z M843.2,643
  C843.3,643,843.3,643.1,843.2,643C843.2,643.1,843.2,643.1,843.2,643L843.2,643z M843.5,643.2C843.6,643.1,843.7,643.2,843.5,643.2
  C843.8,643.5,843.4,643.5,843.5,643.2L843.5,643.2z M844.4,643.3C844.4,643.2,844.4,643.2,844.4,643.3
  C844.5,643.3,844.5,643.3,844.4,643.3L844.4,643.3z M845.9,642.8c0.1,0,0.1,0.1,0.1,0.1C846,642.9,845.9,642.9,845.9,642.8
  L845.9,642.8z M843.5,642.9c-0.1,0-0.1-0.1-0.1-0.2C843.6,642.7,843.5,642.9,843.5,642.9L843.5,642.9z M846,642.7
  C846,642.7,846,642.7,846,642.7C846.1,642.8,846.1,642.7,846,642.7L846,642.7z M842,642.6c0.1,0,0,0.1,0.1,0.1
  C841.9,642.8,842,642.7,842,642.6L842,642.6z M842.1,643C842.1,642.9,842.1,643,842.1,643C842.2,643,842.2,643,842.1,643L842.1,643
  z M842.3,643.1c0.1-0.3,0.1-0.3,0.3-0.1C842.5,643.4,842.5,642.9,842.3,643.1C842.5,643.3,842.3,643.3,842.3,643.1L842.3,643.1z
   M842.1,643.2c0.1,0.1,0.2,0.5-0.1,0.4C842,643.4,842.1,643.4,842.1,643.2L842.1,643.2z M843.9,643.6
  C843.8,643.5,843.9,643.5,843.9,643.6C843.9,643.6,843.9,643.6,843.9,643.6L843.9,643.6z M842.8,643.6
  C842.7,643.6,842.8,643.5,842.8,643.6L842.8,643.6z M841.7,643.6C841.6,643.6,841.6,643.5,841.7,643.6L841.7,643.6z M841.9,643.5
  C841.9,643.4,842,643.5,841.9,643.5L841.9,643.5z M841.4,643.6c0.1,0,0,0.1,0.1,0.2C841.4,643.8,841.4,643.6,841.4,643.6
  L841.4,643.6z M844.5,644c-0.1-0.1-0.2-0.4-0.3,0c-0.4-0.2,0-0.3,0.2-0.4C844.4,643.8,844.5,643.8,844.5,644L844.5,644z M844.6,644
  c0.1,0,0.2,0,0.1,0.1C844.6,644,844.5,644.1,844.6,644L844.6,644z M843.9,644.3c-0.6-0.3-0.1-0.3,0.1-0.6c0,0.2-0.1,0.3,0,0.4
  C843.7,644,843.8,644.2,843.9,644.3L843.9,644.3z M844.5,644.3c-0.1,0.4-0.2,0.1-0.1-0.1C844.5,644.1,844.3,644.4,844.5,644.3
  L844.5,644.3z M843.9,644.9c0.1,0,0.1,0.1,0.1,0.1C843.9,645,843.9,644.9,843.9,644.9L843.9,644.9z M843,645.1
  C842.9,645.1,842.9,645,843,645.1C843,645,843,645,843,645.1z M842.7,645c-0.1,0-0.2,0-0.1-0.1C842.6,644.9,842.7,644.9,842.7,645
  L842.7,645z M841.6,645.1c0-0.1,0.1-0.1,0.1-0.1C841.8,645.1,841.8,645.1,841.6,645.1L841.6,645.1z M841.6,645.1
  C841.5,645.1,841.5,645.1,841.6,645.1C841.6,645.1,841.6,645.1,841.6,645.1L841.6,645.1z M843.7,644.8c-0.2,0.1-0.1,0-0.1-0.1
  C843.8,644.6,843.8,645,843.7,644.8L843.7,644.8z M842,644.5c0.3,0.1-0.1,0.2-0.1,0.3C841.9,644.6,842.1,644.7,842,644.5L842,644.5
  z M841.7,644.8C841.6,644.8,841.6,644.8,841.7,644.8C841.7,644.7,841.7,644.7,841.7,644.8L841.7,644.8z M842.5,644.7
  C842.5,644.7,842.5,644.7,842.5,644.7C842.6,644.8,842.6,644.7,842.5,644.7L842.5,644.7z M842.4,644.8
  C842.3,644.8,842.3,644.8,842.4,644.8C842.4,644.7,842.4,644.7,842.4,644.8L842.4,644.8z M842.6,644.8
  C842.7,644.8,842.7,644.8,842.6,644.8C842.6,644.8,842.6,644.9,842.6,644.8L842.6,644.8z M843.4,644.9
  C843.3,644.9,843.4,644.8,843.4,644.9C843.4,644.8,843.4,644.8,843.4,644.9L843.4,644.9z M842.8,644.8c-0.2,0-0.2-0.2,0-0.2
  C842.7,644.8,842.8,644.6,842.8,644.8L842.8,644.8z M843,644.6C842.9,644.5,843,644.6,843,644.6L843,644.6z M843.6,644.6
  C843.5,644.5,843.6,644.5,843.6,644.6C843.6,644.6,843.6,644.6,843.6,644.6L843.6,644.6z M842.7,643.9c0.5-0.5,0.7,0.4,0.2,0.1
  C842.7,644.6,842.5,644.4,842.7,643.9L842.7,643.9z M842.5,644.2C842.4,644.2,842.4,644.2,842.5,644.2
  C842.5,644.1,842.5,644.1,842.5,644.2L842.5,644.2z M842.3,643.9c0-0.3,0.3-0.3,0.2,0C842.3,643.7,842.5,643.8,842.3,643.9
  L842.3,643.9z M843.4,643.9C843.4,643.9,843.4,643.9,843.4,643.9C843.3,644,843.4,644,843.4,643.9L843.4,643.9z M842.9,644.4
  C842.9,644.4,842.9,644.4,842.9,644.4C842.8,644.5,842.9,644.4,842.9,644.4L842.9,644.4z M843.2,644.2c0.1,0,0,0.1,0.1,0.2
  C843.2,644.5,843.2,644.3,843.2,644.2L843.2,644.2z M843.9,644.2c0.1,0,0,0.1,0.1,0.2C843.9,644.5,843.9,644.3,843.9,644.2
  L843.9,644.2z M844.3,644.3c-0.1,0-0.2-0.1-0.1-0.2C844.2,644.1,844.2,644.2,844.3,644.3L844.3,644.3z M843.7,643.8
  C843.6,643.8,843.7,643.7,843.7,643.8C843.7,643.7,843.7,643.7,843.7,643.8L843.7,643.8z M843.3,643.8c-0.1,0-0.1-0.2,0-0.1V643.8z
   M841.3,643.8C841.3,643.8,841.3,643.8,841.3,643.8C841.3,643.9,841.3,643.8,841.3,643.8L841.3,643.8z M841.7,643.7
  c0.1,0,0,0.1,0.1,0.1C841.7,643.9,841.7,643.8,841.7,643.7L841.7,643.7z M840.8,644.1C840.8,644,840.8,644,840.8,644.1
  C840.8,644.1,840.9,644.1,840.8,644.1L840.8,644.1z M841.1,644.1C841.1,644,841.2,644,841.1,644.1
  C841.2,644.1,841.2,644.1,841.1,644.1L841.1,644.1z M841.3,644.2C841.3,644.1,841.3,644.1,841.3,644.2
  C841.3,644.2,841.3,644.2,841.3,644.2L841.3,644.2z M842.1,644.1c0-0.4,0-0.3,0.1-0.1C842,644.6,841.5,644.6,842.1,644.1
  L842.1,644.1z M840.9,644.3c0.1,0,0,0.1,0.1,0.1C840.9,644.5,840.9,644.4,840.9,644.3L840.9,644.3z M840.2,644.6
  c0.1,0,0,0.1,0.1,0.1C840.2,644.8,840.2,644.6,840.2,644.6L840.2,644.6z M841.1,644.5c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0
  C841,644.6,841,644.5,841.1,644.5L841.1,644.5z M841.3,644.8C841.3,644.9,841.2,644.9,841.3,644.8
  C841.2,644.8,841.2,644.8,841.3,644.8L841.3,644.8z M840.6,645.6c0.1,0,0,0.1,0.1,0.1C840.6,645.8,840.7,645.7,840.6,645.6
  L840.6,645.6z M840.7,646.1c0.1,0,0,0.1,0.1,0.1C840.7,646.3,840.7,646.2,840.7,646.1L840.7,646.1z M840.9,644.9c0.6,0,0,0.8,0.2,1
  C840.8,647,840.7,645.3,840.9,644.9L840.9,644.9z M841.3,645.2C841.4,645.2,841.3,645.3,841.3,645.2L841.3,645.2z M841.4,645.3
  C841.5,645.3,841.5,645.4,841.4,645.3C841.4,645.4,841.4,645.4,841.4,645.3L841.4,645.3z M841.3,645.8c0.3-0.4,0.4,0,0.1,0.4
  C841.5,646,841.4,645.9,841.3,645.8L841.3,645.8z M841.3,645.8C841.2,645.8,841.2,645.8,841.3,645.8
  C841.3,645.8,841.3,645.8,841.3,645.8L841.3,645.8z M841.9,646.6c-0.4,0.3,0.2-0.7-0.4-0.2c0.4-0.4,0.2-1.9,0.4-1.3
  c0.5,0,0.3,0.5-0.1,0.7C842,646.2,842.2,647.4,841.9,646.6L841.9,646.6z M843.2,645.8c0,0.2,0.1,0.2,0.2,0.1
  C843.4,646.8,842.3,645.2,843.2,645.8L843.2,645.8z M843.2,645.8C843.3,645.7,843.3,645.8,843.2,645.8
  C843.2,645.8,843.2,645.8,843.2,645.8L843.2,645.8z M844.6,646c0.1,0,0,0.1,0.1,0.1C844.5,646.1,844.6,646,844.6,646L844.6,646z
   M843.9,646.2c0.1-0.1,0.2-0.1,0.3-0.1C844.1,646.2,844.1,646.2,843.9,646.2L843.9,646.2z M844.7,645.8c0.2,0,0,0.1,0.1,0.2
  C844.7,645.9,844.7,645.9,844.7,645.8L844.7,645.8z M844.6,645.9c-0.1,0-0.1-0.1-0.1-0.1C844.7,645.7,844.6,645.8,844.6,645.9
  L844.6,645.9z M844.1,646c-0.1,0-0.1-0.1-0.1-0.1C844.1,645.8,844,645.9,844.1,646L844.1,646z M843.9,646
  C843.8,646,843.9,645.9,843.9,646L843.9,646z M843.7,646C843.6,646,843.6,645.9,843.7,646C843.7,645.9,843.7,645.9,843.7,646
  L843.7,646z M842.3,646.3C842.2,646.3,842.2,646.3,842.3,646.3C842.3,646.2,842.3,646.2,842.3,646.3L842.3,646.3z M842.1,646.2
  c-0.4-0.1,0.4-0.4,0.2-0.6C842.7,645.8,842.3,646,842.1,646.2L842.1,646.2z M842.1,645.9C842,645.9,842.1,645.9,842.1,645.9
  C842.1,645.8,842.1,645.8,842.1,645.9L842.1,645.9z M842.7,645.8c0,0.1-0.1,0.2-0.2,0.1C842.5,645.9,842.6,645.8,842.7,645.8
  L842.7,645.8z M842.9,646C842.9,646,842.9,646,842.9,646C842.8,646.1,842.9,646.1,842.9,646L842.9,646z M843.2,645.6
  C843.1,645.5,843.2,645.6,843.2,645.6C843.2,645.6,843.2,645.6,843.2,645.6L843.2,645.6z M843.4,645.4c0.1,0,0,0.1,0.1,0.1
  C843.3,645.6,843.4,645.5,843.4,645.4L843.4,645.4z M843,645.5C842.9,645.5,843,645.4,843,645.5C843,645.4,843,645.4,843,645.5
  L843,645.5z M842.4,645.3c0.1,0,0,0.1,0.1,0.1C842.3,645.5,842.4,645.4,842.4,645.3L842.4,645.3z M842.6,645.5c0-0.1,0-0.2,0.1-0.1
  C842.7,645.4,842.7,645.5,842.6,645.5L842.6,645.5z M843,645.2c0.1,0,0,0.1,0.1,0.1C842.9,645.4,843,645.3,843,645.2L843,645.2z
   M844.1,646.3C844.1,646.3,844.1,646.3,844.1,646.3C844,646.4,844.1,646.4,844.1,646.3L844.1,646.3z M844.7,646.8
  c-0.5-0.8,1.8-0.4,0.5-0.9c-0.1,0.6-0.3,0-0.2-0.2c0.2-0.3,0.8-0.9,0.1-0.5c0.2,0.2-0.2,0.4-0.1,0c-0.1,0.1-0.1,0.7-0.2,0.2
  c-0.1,0.3-0.1,0.4-0.1,0c-0.1,0.2-0.3,0.4-0.5,0.6c-0.1-1.3-0.5,0.4-0.7-0.3c0.2,0.2,0.1-0.2,0.1-0.4c0.2,0.3,1-0.7,1,0.1
  c0.3,0.2-0.4-1.4,0.1-0.9c-0.2,0.6,0.3,1.2,0.2,0.2c0.7,0.4,0.5-0.2,0.4-0.5c-0.2,0.2-0.1,0.2-0.1-0.1c-0.2,0.5-0.4-0.2-0.4,0.4
  c0.8-0.2-0.1,0.4-0.1-0.1c0.1-0.3-0.1-0.4-0.2-0.6c0.2,0,0.2,0.1,0.3,0.2c0-0.4,0.2-0.2,0,0c0.1,0.3,0.3-0.2,0.1-0.3
  c0.8-0.8,0.3,0.8,0.5,0.1c0.3-0.2,0,0,0.1,0.3c0.2-0.4,0.4-0.5,0.8-0.8c0,0.2-0.2,0.3,0.1,0.2c-0.2-0.8-0.2-0.5,0.2-0.1
  c0,0.3-0.4,1.4-0.5,1c-0.2,0.6-0.6-0.2-0.6,0.4c0.2-0.1,0.6,0,0.8-0.1c-0.1-1,0.4,0.1,0.8,0.1c0.3-0.4-0.5-0.6-0.1-0.1
  c-0.6-0.2,0.3-1.5,0.1-0.5c0.8-0.7-0.1,0.4,0.6-0.1c0,0.5,0.3,0.7,0.5,1.2c0.3-0.2-0.3-0.5,0.1-0.3c0.1-0.4,0.1,0,0.1,0.2
  c0.3-0.2-0.2-0.7,0.1-1c0.1,0.7,0-0.5-0.2,0c-0.3,0,0-0.3,0.2-0.2c-0.2-0.2-0.1-0.4,0.1-0.3c-0.1,0.1,0,0.2,0,0.4
  c0.5-0.9-0.2,0.4,0.5,0.5c-0.5,0.2-0.5,1.2,0.1,0c-0.3,0.7,0.7,0.5-0.3-0.5c0.3,0.2-0.1-0.1,0.1-0.2c-0.1,0.3,0.2,0.1,0.1,0.3
  c0.8,0.4,0.1,1,0.4,1.3c-0.5-0.1-0.1,1.8-0.9,0.7c-1,1.5-0.9,2.2-1.6,0.1c-0.1,0.3-0.4,0.3-0.8,0.6c-0.2-0.4,0.1-0.4-0.3-0.3
  c0.4,0.5-0.7,0.9-0.8,0.5c-0.3,0.4-0.5-0.4-0.5,0.1C845.4,646.6,844.8,647.3,844.7,646.8L844.7,646.8z M844,645.6
  c-0.1,0-0.2,0-0.1-0.1C843.9,645.6,844,645.5,844,645.6L844,645.6z M845.2,644.4c0.1,0,0,0.1,0.1,0.1
  C845.2,644.6,845.2,644.5,845.2,644.4L845.2,644.4z M845.8,643.2c0,0.1-0.1,0.2-0.1,0.3C845.7,643.3,845.7,643.2,845.8,643.2
  L845.8,643.2z M846.9,643.8C847,643.7,847,643.8,846.9,643.8C846.9,643.8,846.9,643.8,846.9,643.8L846.9,643.8z M846.8,644
  C846.9,644,846.9,644.1,846.8,644C846.8,644.1,846.8,644.1,846.8,644L846.8,644z M848,644c0.1,0,0,0.1,0.1,0.1
  C848,644.1,848,644,848,644L848,644z M848.2,644.5C848.3,644.4,848.3,644.5,848.2,644.5C848.2,644.5,848.2,644.5,848.2,644.5
  L848.2,644.5z M848.9,646.2c-0.1,0-0.1-0.1-0.1-0.1C848.9,646.1,848.9,646.1,848.9,646.2L848.9,646.2z M846.8,646.6
  c0.1-0.6,0.3-0.2,0.3,0.2C847,646.6,846.9,646.8,846.8,646.6L846.8,646.6z M846.9,646.8C846.8,646.9,846.8,646.8,846.9,646.8
  C846.9,646.8,846.9,646.8,846.9,646.8L846.9,646.8z M846.8,646.8C846.7,646.8,846.7,646.8,846.8,646.8
  C846.8,646.7,846.8,646.7,846.8,646.8L846.8,646.8z M847.2,646.2c0.1,0,0,0.1,0.1,0.2C847.2,646.5,847.3,646.3,847.2,646.2
  L847.2,646.2z M846.4,647c0.1-0.1,0.1-0.4,0.3-0.4C846.6,646.7,846.6,647,846.4,647L846.4,647z M847,647
  C847,646.9,847.1,646.9,847,647C847.1,647,847.1,647,847,647L847,647z M847.2,646.8c0.1,0,0,0.1,0.1,0.2
  C847.1,647,847.2,646.8,847.2,646.8L847.2,646.8z M847.2,647.1c0-0.1,0.1,0,0.1-0.1C847.4,647.2,847.3,647.1,847.2,647.1
  L847.2,647.1z M847.5,647.2c0,0.1-0.1,0-0.1,0.1C847.3,647.1,847.4,647.2,847.5,647.2L847.5,647.2z M846.6,647.7
  c0.1-0.4,0.7-0.7,0.6-0.1C847.1,647.8,846.8,647.9,846.6,647.7L846.6,647.7z M849.6,646.7c0.6-0.1,0.2,1.2-0.1,1.3
  C848.8,648.9,849.8,647.2,849.6,646.7L849.6,646.7z M849.2,648.6c0.1,0,0,0.1,0.1,0.2C849.2,648.8,849.2,648.6,849.2,648.6
  L849.2,648.6z M848.9,648.9C848.9,648.8,848.9,648.8,848.9,648.9C849,648.9,849,648.9,848.9,648.9L848.9,648.9z M847.2,650.7
  c1.1-0.8,2-1.7,2.8-2.7C849.8,649.5,848.6,650.6,847.2,650.7L847.2,650.7z M850.3,645.1c-0.1,0-0.1,0.1-0.1,0.1
  C850.2,645.3,850.2,645.2,850.3,645.1L850.3,645.1z M850.2,646c-0.1-0.1,0-0.3-0.2-0.3C850,645.8,850,646,850.2,646L850.2,646z
   M849.1,645.1c0-0.1-0.1-0.1-0.1-0.1C848.9,645.1,849,645.1,849.1,645.1C849.1,645.1,849.1,645.1,849.1,645.1L849.1,645.1z
   M849,645.7c0-0.1,0-0.4-0.2-0.4C848.9,645.4,848.8,645.6,849,645.7L849,645.7z M848.7,644.1c0,0.1,0.1,0.1,0.1,0.1
  C848.8,644.2,848.8,644.1,848.7,644.1L848.7,644.1z M848.8,644.5c-0.1,0.1-0.1,0.1-0.2,0.2C848.7,644.7,848.8,644.7,848.8,644.5
  L848.8,644.5z M848.6,645.5c0,0.1,0.1,0.1,0.1,0.1C848.8,645.5,848.7,645.5,848.6,645.5L848.6,645.5z M848.6,645.8
  c0-0.1,0-0.3-0.1-0.3C848.5,645.5,848.5,645.7,848.6,645.8L848.6,645.8z M848.2,645.1H848c0.1,0.1-0.1,0.8,0.2,0.3
  C848,645.4,848.3,645.3,848.2,645.1L848.2,645.1z M848.4,645.2c-0.3-0.2-0.1,0.6,0.2-0.1C848.3,645.2,848.4,644.9,848.4,645.2
  L848.4,645.2z M848,644.7c-0.1,0,0-0.1-0.1-0.1c0.1,0.4-0.1-0.2-0.3-0.1C847.5,644.8,847.9,645.1,848,644.7L848,644.7z
   M847.6,645.9c0.1,0.1,0.1,0.1,0.2,0.2C847.8,646,847.8,645.9,847.6,645.9L847.6,645.9z M846.2,645.5c0.4-0.2,0.4,0,0.4-0.3
  c0.2,0.1,0.2,0,0.2,0.3C847.1,644.8,846.2,645.1,846.2,645.5L846.2,645.5z M846.5,646.3c0.1,0,0.1-0.1,0.2-0.1
  C846.7,646.1,846.4,646.2,846.5,646.3L846.5,646.3z M846.6,645.8c-0.1,0,0,0.3-0.2,0.1C846.4,646.3,846.7,646,846.6,645.8
  L846.6,645.8z M846.1,645.6c0,0.1-0.1,0-0.1,0.1C846.2,645.7,846.4,645.5,846.1,645.6L846.1,645.6z M846.1,646c0.1,0,0.2,0,0.2-0.1
  s0-0.2-0.1-0.2c0,0,0,0,0,0C846.3,645.9,846.1,645.9,846.1,646L846.1,646z M845.8,644c0.2,0,0.4-0.2,0.3-0.1
  c0.4,0.5-0.3-1.1-0.1-0.1c-0.3-0.1-0.3,0.2-0.4,0.1C845.4,644.2,846.1,644.3,845.8,644L845.8,644z M845.7,645.6
  c-0.1,0-0.1,0.1-0.1,0.2C845.7,645.8,845.7,645.7,845.7,645.6L845.7,645.6z M845.4,644.7c0,0.1,0.1,0.1,0.1,0.1
  C845.6,644.8,845.5,644.7,845.4,644.7L845.4,644.7z M845.5,646.5c-0.1-0.3,0.2-0.4-0.1-0.3C845.5,646.3,845.4,646.5,845.5,646.5
  L845.5,646.5z M1033.8,627c-2.1,0-2.8-3.2,0-3C1036.7,623.9,1036.8,627,1033.8,627z M999,601.1c0.2-2,3.8-1.2,2.8,0.8
  C1001.1,603.9,999,602.9,999,601.1z M1080.8,432.3c-1.3,0.3,1.9,0.6-1,0.9c0.2,0.7-0.2,1.2-0.9,0.6c0.1-1.3-1.7,0.4-1.8-0.2
  c0.5-0.2,1-0.2,1-0.9C1075.4,432.2,1080.9,429.4,1080.8,432.3z M1027,497.6c-0.3-1.1,1.4-2.3,1.8-1
  C1029.7,498.7,1027.1,500.3,1027,497.6z M1075,543c-1.2,0.4-1.5-2-0.2-1.9C1076.4,540.8,1076.3,542.9,1075,543z M982.7,492
  c-1,0.1-1.9-0.8-1.7-1.5C981.9,488.8,986,491.9,982.7,492z M988.2,444.1c-1.9-9.1,12-7.2,6.5,0.7
  C993.3,445.8,987.5,447.2,988.2,444.1z M993.3,442.3c0.6-0.5,0.4-1-0.4-1.3C993,441.5,993.1,441.9,993.3,442.3L993.3,442.3z
   M972.3,419.9c-5.4,1.2-3.5-7.6,0.2-6.9C974.8,414.5,976.8,420,972.3,419.9z M972.1,417.3c0-0.3,0-0.7-0.4-0.7
  C971.2,416.8,971.5,417.5,972.1,417.3L972.1,417.3z M931.1,398.9c-0.9-5.1,7.5-2.6,5.7,1.3C935.1,403,930.6,402.6,931.1,398.9z
   M1019.5,457c-1.4-0.1-2.1-2.4-0.6-3C1020.4,454,1020.2,455.8,1019.5,457z M883,417c1.2,0,1.5,2.9,0.2,3
  C882.2,419.7,881.2,416.7,883,417z M1036.5,393c-2.6,0-1.5-2.6,0.7-1.9C1038.7,391.6,1037.8,392.8,1036.5,393z M1042.8,413
  c-1.5,0.2-0.8-3.2,0.3-3C1044.4,410.5,1044.2,412.9,1042.8,413z M1061.7,758.6c-1.7-1,2.7-2-1.8-2.6
  C1054.6,756.2,1062.2,762.9,1061.7,758.6z M961.7,736.1c0,2.3,1.3-0.5,2-0.9l0,0C965.9,731.7,953.8,732.7,961.7,736.1z
   M962.2,735.3c0.3,0.1,0.6,0.4,0.2,0.7C962.1,735.9,962.1,735.6,962.2,735.3z M938.7,761.6c1,0.6,3.1,0.6,3.3-0.9
  C942.2,758.1,936,758.5,938.7,761.6z M1092.9,717c-0.2,0.2-0.3,0.3-0.6,0.5c1.7,1,3.8,0.4,4.7-1.3
  C1095.6,714.7,1089.8,714.2,1092.9,717z M1062,757.7c-0.2,0.7,3.1,0,1.6-0.7C1063,757.1,1062.5,757.4,1062,757.7z M266.5,333
  c3.2,0,6.4,3.3,6.5,6.8c0,2.8-2.9,6.2-5.3,6.2c-4.2-0.1-6.6-2.5-6.7-6.6C260.9,335.4,263,333,266.5,333z M266.7,337.2
  c-0.6,1-1.3,1.6-1.3,2.1c0.1,0.6,0.8,1.1,1.2,1.7c0.5-0.5,1.3-1,1.5-1.7C268.2,338.9,267.4,338.2,266.7,337.2z M399.6,400
  c2.9,0.1,5.5,2.5,5.4,5.1c-0.2,3.2-2,4.8-5.2,4.9c-2.7-0.1-4.8-2.4-4.8-5.1C395.1,401.9,397,399.9,399.6,400z M286.9,490
  c2.5,0,4,1.6,4.1,4.4c0,2-1.6,3.6-3.6,3.6c-2.3-0.1-4.2-1.9-4.4-4.2C283.1,491.7,284.8,490,286.9,490z M352,547.1
  c0.1-1.9,1.4-3.1,3.6-3.1c2.9,0,4.5,1.3,4.4,3.4c-0.1,1.6-1.8,2.6-4.3,2.6C353.3,550,351.9,548.8,352,547.1z M168,569
  c-1.9,0-3-1-3-3c-0.1-2,0.8-2.9,2.8-3c1.8,0,3.3,1.5,3.2,3.3c0,0,0,0,0,0C171,568.1,170,569,168,569z M179,355.1
  c-0.2,1.5-1.5,2.7-3,2.9c-1.7,0-3-1.4-3-3.1c0-1.7,1.4-3,3.1-3c0.1,0,0.2,0,0.3,0C177.9,352.2,179,353.6,179,355.1z M383,604.6
  c-0.2,1.3-1.3,2.3-2.7,2.4c-1.5,0-3.3-1.2-3.3-2.4c0.1-1.5,1.4-2.6,2.8-2.6C381.3,602,383,603.4,383,604.6z M285.9,653
  c-2.1-0.1-2.8-0.8-2.9-2.2c-0.1-1.1,0.5-2.1,1.6-1.7c1,0.5,1.9,1.2,2.4,2.2C287.2,651.6,286.2,652.6,285.9,653z M205.8,638
  c0.4,0.8,1.2,1.5,1.2,2.3c0,0.5-0.9,1.1-1.5,1.7c-0.5-0.6-1.3-1.2-1.5-2c-0.1-0.5,0.6-1.3,1-2C205.2,638,205.5,638,205.8,638z
   M1211,331.5c0,1.9-1.5,3.4-3.3,3.5c-0.1,0-0.2,0-0.2,0c-1.8,0.1-3.3-1.2-3.4-3c0,0,0,0,0-0.1c0-2,1.5-3.8,3.5-4
  c1.9-0.1,3.4,1.4,3.5,3.3C1211,331.4,1211,331.5,1211,331.5z M136.5,765c1.9,0,3.4,1.6,3.5,3.5c-0.1,1.5-2.2,3.6-3.8,3.5
  c-1.8-0.1-3.3-1.6-3.2-3.5c-0.1-1.9,1.4-3.5,3.3-3.5C136.4,765,136.5,765,136.5,765z M32.6,701c1.2-0.1,2.3,0.8,2.4,2
  c0,0.1,0,0.1,0,0.2c0.1,1.4-0.9,2.6-2.3,2.8c-0.1,0-0.2,0-0.3,0c-1.2,0.1-2.3-0.8-2.4-2.1c0-0.1,0-0.2,0-0.3
  c-0.1-1.3,0.8-2.5,2.2-2.6C32.3,701,32.4,701,32.6,701z M503,858.5c-1.1,0.7-1.8,1.5-2.4,1.5c-0.5,0-1-1-1.6-1.5
  c0.6-0.6,1.3-1.1,2.1-1.5C501.4,856.9,502.1,857.8,503,858.5z M553,683.9c0.1-2.9,1.5-6.1,6.1-5.9c2.7,0.1,4.8,2.2,4.9,4.8
  c0.1,4-1.7,6.1-5.5,6.1c-2.9,0.2-5.3-2.1-5.5-5C553,684,553,684,553,683.9z M364,785.4c-0.1,2.1-2.2,3.7-4.7,3.6
  c-2.6-0.1-4.5-1.7-4.3-3.7c0.2-2.4,1.9-3.4,5.3-3.3C362.8,782.1,364.1,783.3,364,785.4z M414.9,664c-2.9-0.1-3.9-1.2-3.9-4
  c0.1-3.2,1.5-5.1,3.9-5c2.3,0.1,4.2,2.1,4.1,4.4C418.9,662.1,417.2,664.1,414.9,664z M628,614.7c-0.3,2.2-1,3.5-2.4,3.3
  c-0.8-0.3-1.4-1-1.6-1.9c-0.2-1.7,1-3.4,2.3-3C627,613.2,627.6,614.2,628,614.7z M1207.7,620c-7.5,0.3-7.7-12.3,0.4-12
  C1216.3,607.8,1215.8,620.7,1207.7,620z M1268.5,775c-6.8,0-7.2-8.8-1.3-10C1274.1,764.2,1275.2,774.8,1268.5,775z M1180.9,817
  c3.9-0.3,4.1,7.5,0.3,8C1177.3,825.3,1176.6,816.5,1180.9,817z M14,218.1c0.5,4.2-7.3,3.6-7,0.2C7.1,214.4,14.1,213.6,14,218.1z
   M38,280.1c0.2,2.6-4.1,2.4-4,0c-0.1-1.1,0.8-2.1,1.9-2.1c1.1-0.1,2.1,0.8,2.1,1.9C38,279.9,38,280,38,280.1z M225,140.7
  c0.1-3.3,1.5-4.8,4.3-4.7c2.2,0,3.8,2,3.7,4.6c-0.1,2.8-1.1,4.2-4,4.4c-2.2,0-4-1.7-4-3.9C225,141,225,140.9,225,140.7z M275,210.5
  c0,2.6-1.4,3.5-4.2,3.5c-1.9,0-2.9-1.2-2.8-3.2c0.1-1.8,1.7-2.9,4.2-2.8C273.6,207.9,274.9,209,275,210.5
  C275,210.5,275,210.5,275,210.5z M204.2,177c1.4-0.1,2.7,1,2.8,2.4c0,0.9-0.7,1.6-1.6,1.6c0,0,0,0-0.1,0c-1.1,0.1-2.5-1.3-2.3-2.3
  C203.3,178,203.7,177.5,204.2,177z M194.9,105c-0.5,0.4-1.1,1.1-1.5,1c-0.6-0.3-1-1.1-1.5-1.7c0.4-0.4,0.9-1.3,1.3-1.2
  c0.6,0.1,1.1,0.8,1.7,1.3C195,104.5,195,104.8,194.9,105z M92,30.5c0,3.8-2.1,5.6-6.5,5.5c-5.7,0.1-3.8-11.1,1-11
  C90.1,25,92,26.9,92,30.5z M88.3,30.9c0-1.6-1.8-1.7-2.6-0.4C86.5,31.9,87.3,31.6,88.3,30.9z`,
})``;

const Stars = (props) => {
  return (
    <Base
      {...props}
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      viewBox={DEFAULT_VIEWBOX}
    >
      <Gray />
      <Orange />
    </Base>
  );
};

export default Stars;