import React from 'react';
import styled from 'styled-components';
import theme, { getColorObj } from '@matterapp/matter-theme';
import Avatar from '../Avatar';

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  display: inline-flex;
  width: fit-content;
  height: ${theme.spacing.double};
  background: ${({ color }) => color ? getColorObj(color)[5] : theme.colors.blacks[5]};
  border-radius: ${theme.borderRadius.pill};
  padding: ${theme.spacing.quarter};
  font-weight: ${theme.fontWeight.regular};
  line-height: ${theme.lineHeight.S};
  font-size: ${theme.fontSize.base};
  color: ${({ color }) => color ? getColorObj(color)[50] : theme.colors.blacks[90]};
`;

const ChildrenContainer = styled.div`
  margin-right: ${theme.spacing.threeQuarters};
  margin-left: ${({ showAvatar }) => !showAvatar ? theme.spacing.threeQuarters : '0'};
`;

const AvatarContainer = styled.div`
  display: inline-block;
  width: ${theme.spacing.singleAndHalf};
  height: ${theme.spacing.singleAndHalf};
  margin-right: ${theme.spacing.half};
`;

const AvatarPill = ({ photoUrl, color, children, showAvatar }) => {
  return (
    <Container color={color}>
      {showAvatar && <AvatarContainer>
        <Avatar url={photoUrl} size={24} />
      </AvatarContainer>}
      <ChildrenContainer showAvatar={showAvatar}>{children}</ChildrenContainer>
    </Container>
  );
};

AvatarPill.defaultProps = {
  showAvatar: true,
};

export default AvatarPill;