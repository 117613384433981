import React from 'react';
import PropTypes from 'prop-types';
import { Form, ListContainer } from '@matterapp/matter-ui';
import { QualityCheckboxes } from '../../../form-fields';

const SetQualitiesForm = ({ handleSubmit, qualities, isSelectedMax }) => (
  <React.Fragment>
    {qualities.length < 1 ? null : (
      <ListContainer alignCenter animated>
        <Form onSubmit={handleSubmit}>
          <QualityCheckboxes
            qualities={qualities}
            isSelectedMax={isSelectedMax}
          />
        </Form>
      </ListContainer>
    )}
  </React.Fragment>
);

SetQualitiesForm.propTypes = {
  qualities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isSelectedMax: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SetQualitiesForm;
