import React, { useEffect } from 'react';
import { Paper, Box } from '@mui/material';
import { ACTIONS } from '../reducer';
import Question from './Question';
import { ContinueButton } from '../styles';
import ReorderModal from './ReorderModal';

export default function BuilderScreen({ state, dispatch }) {
  const { questions, showErrors, reorderingQuestions } = state;

  useEffect(() => {
    if (!questions.length) {
      dispatch({
        type: ACTIONS.ADD_QUESTION
      });
    }
  }, [questions]);

  return (
    <Box>
      {questions.map((question, index) => (
        <Question 
          canDelete={questions.length > 1}
          question={question}
          index={index} 
          key={question.id} 
          dispatch={dispatch}
          showErrors={showErrors}
          hasEnps={questions.some(q => q.type === 'enps')}
        />
      ))}
      <Paper sx={{ mt: 3, ml: 1, mr: 1, p: 3, mb: '187px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ContinueButton
          size='S'
          color='blue'
          disabled={questions.length >= 20}
          onClick={() => {
            dispatch({
              type: ACTIONS.ADD_QUESTION
            });
          }}
        >
          + Add Question
        </ContinueButton>
      </Paper>
      <ReorderModal
        isOpen={reorderingQuestions}
        onClose={() => {
          dispatch({
            type: ACTIONS.TOGGLE_REORDER_QUESTIONS
          });
        }}
        questions={questions}
        onConfirm={(newOrder) => {
          dispatch({
            type: ACTIONS.REORDER_QUESTIONS,
            payload: newOrder
          });
          dispatch({
            type: ACTIONS.TOGGLE_REORDER_QUESTIONS
          });
        }}
      />
    </Box>
  );
}