import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const MultipleEmailContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: ${theme.spacing.single};
  }
`;