import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const heartEyesImgSrc = 'https://assets-global.website-files.com/60b9220d74f790a1191230c9/6166980e9159c0c1097e7c3e_gs-6.png';
const smileyFaceImgSrc = 'https://assets-global.website-files.com/60b9220d74f790a1191230c9/616695c54f27546db4f549b0_gs-4.png';
const starImgSrc = 'https://assets-global.website-files.com/60b9220d74f790a1191230c9/616695c6455e1213483178af_gs-1.png';
const blueDotImgSrc = 'https://assets-global.website-files.com/60b9220d74f790a1191230c9/6166980d24baad6be8045408_gs-7.png';
const purpleDotImgSrc = 'https://assets-global.website-files.com/60b9220d74f790a1191230c9/6166980df9a3b10554b170a6_gs-2.png';
const redDotImgSrc = 'https://assets-global.website-files.com/60b9220d74f790a1191230c9/616695c65673814ec3b66d1a_gs-3.png';

const MOVE_UP = 'move-up';
const MOVE_DOWN = 'move-down';

const BackgroundContainer = styled.div`
  position: absolute;
  .${MOVE_UP} {
    animation: UpNDown 8s infinite alternate;
    @keyframes UpNDown {
      0% { transform: translateY(0); }
      50% { transform: translateY(-10px); }
      100% { transform: translateY(0); }
    }
  }
  .${MOVE_DOWN} {
    animation: DownNUp 8s infinite alternate;
    @keyframes DownNUp {
      0% { transform: translateY(-10px); }
      50% { transform: translateY(0); }
      100% { transform: translateY(-10px); }
    }
  }
`;

const HeartEyes = styled.div`
  z-index: 999;
  position: absolute;
  top: -330px;
  left: 100px;
  ${theme.media.S`
    top: ${({ isConfirmPage }) => isConfirmPage ? '-300' : '-240'}px;
    left: 120px;
  `}
  ${theme.media.L`
    top: ${({ isConfirmPage }) => isConfirmPage ? '-290' : '-230'}px;
  `}
`;

const SmileyFace = styled.div`
  z-index: 999;
  position: absolute;
  top: 120px;
  left: -220px;
  ${theme.media.S`
    top: 100px;
    left: -280px;
  `}
  ${theme.media.L`
    top: 115px;
    left: -295px;
  `}
`;

const Star = styled.div`
  z-index: 999;
  position: absolute;
  top: -40px;
  left: 180px;
  ${theme.media.XS`
    top: -40px;
    left: 210px;
  `}
  ${theme.media.S`
    z-index: 0;
    top: 10px;
    left: 225px;
  `}
  ${theme.media.L`
    top: 25px;
  `}
`;

const BlueDot = styled.div`
  z-index: 999;
  position: absolute;
  top: -180px;
  left: -210px;
  ${theme.media.S`
    top: 50px;
    left: -330px;
  `}
  ${theme.media.L`
    top: 60px;
  `}
`;

const PurpleDot = styled.div`
  z-index: 999;
  position: absolute;
  top: 300px;
  left: 120px;
  ${theme.media.S`
    top: 170px;
    left: 300px;
  `}
  ${theme.media.L`
    top: 180px;
  `}
`;

const RedDot = styled.div`
  z-index: 999;
  position: absolute;
  top: 250px;
  left: -160px;
  ${theme.media.S`
    z-index: 0;
    top: 200px;
    left: -260px;
  `}
  ${theme.media.L`
    top: 210px;
  `}
`;

const Background = ({ isConfirmPage }) => (
  <BackgroundContainer>
    <HeartEyes className={MOVE_UP} isConfirmPage={isConfirmPage}>
      <img
        src={heartEyesImgSrc}
        alt='heart-eyes'
        width='85'
      />
    </HeartEyes>
    <SmileyFace className={MOVE_UP}>
      <img 
        src={smileyFaceImgSrc}
        alt='smiley-face'
        width='90'
      />
    </SmileyFace>
    <Star className={MOVE_DOWN}>
      <img 
        src={starImgSrc}
        alt='star'
        width='40'
      />
    </Star>
    <BlueDot className={MOVE_DOWN}>
      <img 
        src={blueDotImgSrc}
        alt='blueDot'
        width='20'
      />
    </BlueDot>
    <PurpleDot className={MOVE_UP}>
      <img 
        src={purpleDotImgSrc}
        alt='purpleDot'
        width='15'
      />
    </PurpleDot>
    <RedDot>
      <img 
        src={redDotImgSrc}
        alt='redDot'
        width='35'
      />
    </RedDot>
  </BackgroundContainer>
);

export default Background;