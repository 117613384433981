import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const columnSizes = [1, 2, 3, 4, 5, 6, 7, 8];

const sizes = {
  XS: 'XS',
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
};

const getColumnStyles = ({ columns, isResponsive, responsiveColumns, size }) => {
  if (Array.isArray(responsiveColumns)) {
    const sizeToIndex = {
      [sizes.L]: 4,
      [sizes.M]: 3,
      [sizes.S]: 2,
      [sizes.XS]: 1,
    };
    const index = sizeToIndex[size];
    const count = responsiveColumns[index];
    return `grid-template-columns: repeat(${count}, minmax(0, 1fr));`;
  }
  if (!columns) {
    return '';
  }
  if (Array.isArray(columns)) {
    const customTemplateColumns = columns.map((column) => `${column}fr`);
    return `grid-template-columns: ${customTemplateColumns.join(' ')}`;
  }
  const columnCount = {
    8: {
      [sizes.L]: 8,
      [sizes.M]: 4,
      [sizes.S]: 4,
      [sizes.XS]: 2,
    },
    7: {
      [sizes.L]: 7,
      [sizes.M]: 4,
      [sizes.S]: 3,
      [sizes.XS]: 2,
    },
    6: {
      [sizes.L]: 6,
      [sizes.M]: 3,
      [sizes.S]: 3,
      [sizes.XS]: 2,
    },
    5: {
      [sizes.L]: 5,
      [sizes.M]: 3,
      [sizes.S]: 2,
      [sizes.XS]: 1,
    },
    4: {
      [sizes.L]: 4,
      [sizes.M]: 4,
      [sizes.S]: 2,
      [sizes.XS]: 1,
    },
    3: {
      [sizes.L]: 3,
      [sizes.M]: 3,
      [sizes.S]: 2,
      [sizes.XS]: 1,
    },
    2: {
      [sizes.L]: 2,
      [sizes.M]: 2,
      [sizes.S]: 2,
      [sizes.XS]: 1,
    },
    1: {
      [sizes.L]: 1,
      [sizes.M]: 1,
      [sizes.S]: 1,
      [sizes.XS]: 1,
    },
  };

  const column = columnCount[columns] || columnCount[1];
  const count = isResponsive ? column[size] : column[sizes.L];
  return `grid-template-columns: repeat(${count}, minmax(0, 1fr));`;
};

const getBaseStyles = (params) => {
  if (!!params.responsiveColumns) {
    return getColumnStyles({ ...params, size: sizes.XS });
  }
  return '';
};

const getStylesExtraSmall = (params) => theme.media.XS`
  ${getColumnStyles({ ...params, size: sizes.XS })}
`;

const getStylesSmall = (params) => theme.media.S`
  ${getColumnStyles({ ...params, size: sizes.S })}
`;

const getStylesMedium = (params) => theme.media.M`
  ${getColumnStyles({ ...params, size: sizes.M })}
`;

const getStylesLarge = (params) => theme.media.L`
  ${getColumnStyles({ ...params, size: sizes.L })}
`;

const getSpacingStyles = ({ spacing }) => {
  const spacingValue = typeof spacing === 'number' ? `${spacing}px` : spacing;
  return spacing ? `grid-gap: ${spacingValue};` : '';
};

const Grid = styled.div`
  display: grid;
  ${getSpacingStyles}
  ${getBaseStyles}
  ${getStylesExtraSmall}
  ${getStylesSmall}
  ${getStylesMedium}
  ${getStylesLarge}
  ${getColumnStyles}
`;

export { Grid, columnSizes };
