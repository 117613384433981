import React from 'react';
import { Typography, Box } from '@mui/material';
import { Checkbox } from '@matterapp/matter-ui';
import { QuestionWrapper, QuestionInput } from './styles';
import { ACTIONS } from '../reducer';

export default function AgreeDisagreeQuestion(props) {
  const { question, id, dispatch, required, comments, maxLength, errors } = props;
  const questionError = errors.find((error) => error.field === 'question');

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant='h5' component='p'>
        Question:
      </Typography>
      <QuestionWrapper>
        <QuestionInput 
          onChange={(e) => {
            dispatch({
              type: ACTIONS.UPDATE_QUESTION_TEXT,
              payload: {
                id,
                question: e.target.value
              }
            });
          }}
          value={question}
          maxLength={maxLength}
          allowErrorBeforeBlur
          error={!!questionError}
          placeholder="Type your question here..."
        />
        {questionError && (
          <Typography variant='body2' component='p' color='error' sx={{ mt: 0.5 }}>
            {questionError.message}
          </Typography>
        )}
      </QuestionWrapper>
      <Box 
        sx={{ 
          mt: 4, 
          display: 'flex', 
          alignItems: 'center', 
          cursor: 'pointer'
        }}
        onClick={() => {
          dispatch({ type: ACTIONS.TOGGLE_QUESTION_REQUIRED, payload: { id } });
        }}
      >
        <Checkbox.Toggle
          size='S' 
          checked={required}
        />
        <Typography variant='body1' component='p' sx={{ ml: 1 }}>
          <b>Make question required</b>
        </Typography>
      </Box>
      <Box 
        sx={{ 
          mt: 4, 
          display: 'flex', 
          alignItems: 'center', 
          cursor: 'pointer'
        }}
        onClick={() => {
          dispatch({ type: ACTIONS.TOGGLE_ENABLE_COMMENTS, payload: { id } });
        }}
      >
        <Checkbox.Toggle
          size='S' 
          checked={comments}
        />
        <Typography variant='body1' component='p' sx={{ ml: 1 }}>
          <b>Enable comments</b>
        </Typography>
      </Box>
    </Box>
  );
}
