import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Transition from 'react-transition-group/Transition';

import theme from '@matterapp/matter-theme';
import { IconHeart } from '../../../../Icon';

const animationDuration = 425;
const shrinkAnimationDuration = animationDuration * 0.35;

const HeartContainer = styled.div`
  svg {
    display: block;
  }

  transform-origin: center;
  animation-duration: ${animationDuration}ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: none;
  animation-delay: 0;

  @keyframes pop {
    0% {
      transform: scale(1);
    }
    35% {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    85% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const transitionStyles = {
  entering: {
    animationName: 'pop',
  },
};

const iconPropsActive = {
  outline: false,
  fill: theme.colors.red,
};

const iconPropsNotActive = {
  outline: true,
  fill: theme.colors.black,
};

const transitionProps = {
  entering: iconPropsNotActive,
  entered: iconPropsActive,
};

const Heart = class extends React.Component {
  constructor() {
    super();
    this.state = {
      animate: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isActive !== this.props.isActive) {
      this.setState({ animate: nextProps.isActive });
    }
  }

  // This is a little complicated with 2 transitions. I wanted the heart to be
  // black outline until it is 0 width, then change to solid red. The first
  // transition and handles the icon props for the duration of the shrink. The
  // second is for the css keyframe scale animation.
  render() {
    const { isActive } = this.props;
    return (
      <Transition in={this.state.animate} timeout={shrinkAnimationDuration}>
        {(transitionStateProps) => (
          <Transition in={this.state.animate} timeout={animationDuration}>
            {(transitionStateOverall) => (
              <HeartContainer style={transitionStyles[transitionStateOverall]}>
                <IconHeart
                  {...(isActive ? iconPropsActive : iconPropsNotActive)}
                  {...transitionProps[transitionStateProps]}
                />
              </HeartContainer>
            )}
          </Transition>
        )}
      </Transition>
    );
  }
};

Heart.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default Heart;
