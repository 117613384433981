import { gaCall } from './googleAnalytics';
import { gtagCall } from './googleTagManager';

export const trackRetentionEvent = (key) => {
  const eventValues = {
    kudos: {
      text: 'Give Kudos',
      hash: 'AW-801897506/NGWsCLyJ_8cCEKL4r_4C',
    },
    feedback: {
      text: 'Give Feedback',
      hash: 'AW-801897506/g9_7CJSR_8cCEKL4r_4C',
    },
  };
  const eventValue = eventValues[key];

  gaCall('send', 'event', {
    'eventCategory': 'Retention',
    'eventAction': eventValue.text,
  });
  gtagCall('event', 'conversion', {'send_to': eventValue.hash});
};
