import { useRef, useState } from 'react';

export const useTextArea = (params) => {
  const { autoFocus, canClickSend, onClickSend, onChange, placeholder, sendOnEnter, value } = params;
  const [hasSentMessage, setHasSentMessage] = useState(false);
  const textareaRef = useRef();
  const hasValue = value.trim().length;

  const handleSend = async (e) => {
    const response = await onClickSend(e, {
      ...params,
      comment: { message: value },
      value,
    });

    if (textareaRef.current && textareaRef.current.getShadowHeight) {
      textareaRef.current.getShadowHeight();
    }
    return response;
  };

  return {
    ...params,
    canClickSend: canClickSend && hasValue,
    hasValue: !!value,
    textAreaProps: {
      autoFocus,
      autoHeight: true,
      showBottomBorder: false,
      ref: textareaRef,
      onChange: (e, props) => {
        if (!hasSentMessage) {
          onChange(e, { ...props, value: props.value });
        }
      },
      onKeyPress: async (e) => {
        const isEnterKey = e.key && e.key === "Enter";
        const isShiftKey = e.shiftKey;
        if (!hasValue && isEnterKey) {
          e.preventDefault();
        }
        if (sendOnEnter && canClickSend && hasValue && isEnterKey && !isShiftKey) {
          e.preventDefault();
          try {
            setHasSentMessage(true);
            await handleSend(e);
            setHasSentMessage(false);
            onChange(e, { ...params, value: '' });
          } catch (error) {
            console.error(error);
            setHasSentMessage(false);
          }
        }
      },
      placeholder,
      value,
    },
    sendButtonProps: {
      disabled: !canClickSend || !hasValue,
      visible: hasValue,
      onClick: handleSend,
    }
  };
};
