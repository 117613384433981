import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Loader } from '@matterapp/matter-ui';
import getStripeSubscriptionByTenantId from 'graphql-queries/queries/billing/getStripeSubscriptionByTenantId';
import {
  LoadingContainer,
} from '../styles';
import {
  RECURRING_INTERVAL,
} from '../consts';
import BillingBannerContent from './BillingBannerContent';

function UpgradedPanel(props) {
  const {
    isLoading,
    workspace,
    canceledSubscription,
    tenant,
    refetchCurrentUser
  } = props;
  const { tenantId } = workspace || {};
  const { data: activeSubscriptionData, loading: isSubscriptionLoading } = useQuery(getStripeSubscriptionByTenantId, {
    variables: {
      tenantId,
    },
    skip: !tenantId
  });

  if (isLoading || isSubscriptionLoading || !workspace) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const { stripeSubscription: subscription } = activeSubscriptionData || {};
  const isActiveSubscription = subscription?.status === 'active' && !canceledSubscription;
  const isYearlySubscription = subscription?.interval === RECURRING_INTERVAL.YEAR;

  return (
    <BillingBannerContent 
      workspace={workspace}
      tenant={tenant}
      subscription={subscription} 
      isActiveSubscription={isActiveSubscription}
      isYearlySubscription={isYearlySubscription}
      canceledSubscription={canceledSubscription}
      refetchCurrentUser={refetchCurrentUser}
    />
  );

}

UpgradedPanel.propTypes = {
  workspace: PropTypes.object,
  isLoading: PropTypes.bool,
  canceledSubscription: PropTypes.object,
};

export default UpgradedPanel;
