import React from 'react';
import PropTypes from 'prop-types';
import WithActionButtons from './WithActionButtons';

const WithCancelSend = (props) => {
  const {
    canClickCancel,
    canClickSend,
    className,
    onClickSend,
    onClickCancel,
    sendIsDestructive,
    sendIsLoading,
    sendLabel,
    sendProps,
    cancelLabel,
    cancelProps,
    useVividSendButton,
    ...otherProps
  } = props;

  return (
    <WithActionButtons
      {...otherProps}
      className={className}
      canClickPrimary={canClickSend}
      canClickSecondary={canClickCancel}
      onClickPrimary={onClickSend}
      onClickSecondary={onClickCancel}
      primaryIsDestructive={sendIsDestructive}
      primaryIsLoading={sendIsLoading}
      primaryLabel={sendLabel}
      primaryProps={sendProps}
      secondaryLabel={cancelLabel}
      secondaryProps={cancelProps}
      useVividPrimaryButton={useVividSendButton}
    />
  );
};

WithCancelSend.defaultProps = {
  canClickCancel: true,
  canClickSave: true,
  sendLabel: 'Send',
  cancelLabel: 'Cancel',
  useVividSaveButton: true,
};

WithCancelSend.propTypes = {
  /** If cancel button can be clicked. */
  canClickCancel: PropTypes.bool,
  /** If send button can be clicked. */
  canClickSend: PropTypes.bool,
  /** Additional class. */
  className: PropTypes.string,
  /** Callback when save button is clicked. */
  onClickSend: PropTypes.func,
  /** Callback when cancel button is clicked. */
  onClickCancel: PropTypes.func,
  /** If send is destructive action. */
  saveIsDestructive: PropTypes.bool,
  /** If send button is loading. */
  saveIsLoading: PropTypes.bool,
  /** Send button label. */
  sendLabel: PropTypes.node,
  /** Additional props for send button. */
  sendProps: PropTypes.object,
  /** Cancel button label. */
  cancelLabel: PropTypes.node,
  /** Additional props for cancel button. */
  cancelProps: PropTypes.object,
  /** Use vivid send button. */
  useVividSendButton: PropTypes.bool,
};

export default WithCancelSend;
