import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import Avatar from '../../Avatar';
import List from '../../List';
import { Modal } from '../../Modal';
import { AdvisorPropType } from '../consts';
import { editRecurring, infoModalIconsMap } from '../icons';

const Body = styled.div`
  margin: ${theme.spacing.double};
`;

const AdvisorInfoModal = ({
  isOpen,
  selectedAdvisor,
  handleCloseModal,
  handleSelectAction,
  isRecurring,
}) => {
  const { fullName, email, photoUrl } = selectedAdvisor;
  const subHeader = fullName ? email : null;
  const actionItemsMap = infoModalIconsMap.slice(0);
  if (isRecurring) {
    actionItemsMap.splice(1, 1, editRecurring);
  }

  return (
    <Modal
      contentLabel="Info Modal"
      size="small"
      isOpen={isOpen}
      transparent
      fullScreenMobile
      onClose={handleCloseModal}
    >
      <Modal.Header.WithAvatar
        header={fullName || email}
        subHeader={subHeader}
        avatar={<Avatar url={photoUrl} size={85}/>}
        onClickClose={handleCloseModal}
      />
      <Modal.Body>
        <Body>
          {actionItemsMap.map((item, index) => {
            return (
              <List.ItemActionItem
                key={index}
                onClick={() => handleSelectAction(item)}
                {...item}
              >
                {item.tooltipContent}
              </List.ItemActionItem>
            );
          })}
        </Body>
      </Modal.Body>
      <Modal.Footer.WithClose onClickClose={handleCloseModal} />
    </Modal>
  );
};

AdvisorInfoModal.protoTypes = {
  /** Advisor selected. */
  selectedAdvisor: AdvisorPropType,
  /** If the modal is open. */
  isOpen: PropTypes.bool.isRequired,
  /** If the advisor is recurring. */
  isRecurring: PropTypes.bool,
  /** Function to select menu action. */
  handleSelectAction: PropTypes.func.isRequired,
  /** Function to close modal. */
  handleCloseModal: PropTypes.func.isRequired,
};

export default AdvisorInfoModal;