import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0971 36.3202C29.596 36.3202 35.9711 29.2866 35.9711 20.2557C35.9711 19.1113 35.8723 17.9908 35.68 16.9065L35.6843 16.9022H20.2566V23.0662H29.4324C28.3023 27.2969 24.8084 29.9008 20.4478 29.9008C15.1137 29.9008 10.5026 25.5563 10.5026 20.0002C10.5026 14.444 14.8268 9.93991 20.1609 9.93991C22.5774 9.93991 24.7865 10.8643 26.4803 12.3921L31.0527 7.93463C28.338 5.29905 24.6006 3.68018 20.0971 3.68018C10.3751 3.68018 4 10.9373 4 19.9682C4 28.9992 11.2069 36.3202 20.0971 36.3202Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5875 21.0542H4.06384C4.61402 29.5789 11.595 36.3203 20.126 36.3203C29.6249 36.3203 36 29.2867 36 20.2558C36 19.1114 35.9012 17.9909 35.7089 16.9066L35.7132 16.9023H20.2855V23.0663H29.4613C28.3312 27.297 24.8373 29.9009 20.4767 29.9009C15.4849 29.9009 11.1264 26.0962 10.5875 21.0542Z"
      fill={fill}
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="4"
      y="16"
      width="32"
      height="21"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5875 21.0542H4.06384C4.61402 29.5789 11.595 36.3203 20.126 36.3203C29.6249 36.3203 36 29.2867 36 20.2558C36 19.1114 35.9012 17.9909 35.7089 16.9065L35.7132 16.9023H20.2854V23.0663H29.4613C28.3312 27.297 24.8373 29.9009 20.4767 29.9009C15.4849 29.9009 11.1264 26.0962 10.5875 21.0542Z"
        fill={fill}
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M31.413 32.5516L25.6754 28.1442L20.0653 23.2259V16.8384H36.003V32.5516H31.413Z"
        fill={fill}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.69876 27.3386L11.0817 23.3762C10.7079 22.3414 10.5027 21.2233 10.5027 20.0514C10.5027 18.8758 10.6963 17.7473 11.0521 16.6987L5.59066 12.6226C5.33988 13.16 5.11721 13.7148 4.92375 14.2851L4.33684 16.4947C4.1148 17.6289 4.00012 18.8082 4.00012 20.0194C4.00012 22.6506 4.61187 25.1366 5.69876 27.3386Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'white',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
