import { useState, useContext, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import deleteCustomSurvey from 'graphql-queries/mutations/surveys/deleteCustomSurvey';
import closeCustomSurvey from 'graphql-queries/mutations/surveys/closeCustomSurvey';
import getSurveyAnalyticsSpreadsheet from 'graphql-queries/queries/surveys/getSurveyAnalyticsSpreadsheet';
import getCustomSurveys from 'graphql-queries/queries/surveys/getCustomSurveys';
import duplicateCustomSurvey from 'graphql-queries/mutations/surveys/duplicateSurvey';
import sendSurveyReminder from 'graphql-queries/mutations/surveys/sendSurveyReminder';
import Toast from 'components/Toast/Toast';
import UserContext from 'context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import { downloadSpreadsheet } from 'components/DateFilterBar/DateFilterBar';

export const FILTERS = {
  ALL: 'all',
  ACTIVE: 'active',
  DRAFT: 'draft',
  CLOSED: 'closed'
};

export default function useSurveyMenu(survey) {
  const [deleteSurvey] = useMutation(deleteCustomSurvey);
  const [closeSurvey] = useMutation(closeCustomSurvey);
  const [duplicateSurvey] = useMutation(duplicateCustomSurvey);
  const [sendReminder, { loading: sendingReminder }] = useMutation(sendSurveyReminder);
  const [editSurvey, setEditSurvey] = useState(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState({});
  const [editNotifications, setEditNotifications] = useState(null);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  
  const { currentTenant, currentWorkspace } = useContext(UserContext);
  const reminderCopy = currentWorkspace?.slackWorkspace 
    ? 'Matter will send a reminder message in Slack to each recipient.'
    : 'Matter will send a reminder message in Teams to each recipient.';
     
  const navigate = useNavigate();
  const [requestSurveyAnalyticsSpreadsheet, { data: spreadsheetData, loading: downloadingReport}] = useLazyQuery(getSurveyAnalyticsSpreadsheet, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (sendingReminder) {
      setConfirmButtonDisabled(true);
    } else {
      setConfirmButtonDisabled(false);
    }
  }, [sendingReminder]);

  useEffect(() => {
    if (spreadsheetData) {
      downloadSpreadsheet(spreadsheetData.surveyAnalyticsSpreadsheet, 'customSurveyReport');
      
      Toast.success('Check downloaded spreadsheet for instructions.');
    }
  }, [spreadsheetData]);
  
  function downloadReport(surveyId) {
    requestSurveyAnalyticsSpreadsheet({
      variables: {
        tenantId: currentTenant?.id,
        surveyId
      }
    });
  }

  function getMenuMap(menuSurvey) {
    const { status, id: surveyId } = survey || menuSurvey || {};

    const editSurveyMenu = {
      menuLabel: 'Edit',
      rcLabel: 'edit-survey-menu',
      handleAction: () => {
        if (['draft', 'scheduled'].includes(status)) {
          navigate(Resources.createSurveys.path({ workspaceId: currentWorkspace.id, step: 1 }, { surveyId }));
        } else {
          setIsConfirmModalOpen(true);
          setConfirmModalProps({
            confirmButtom: 'Duplicate',
            header: 'It\'s Too Late to Edit',
            text: 'Custom Surveys can not be edited once they have been sent to recipients. Perhaps you’d like to duplicate the survey?',
            onConfirm: async () => {
              await duplicateSurvey({
                variables: {
                  surveyId: surveyId
                },
                refetchQueries: [{
                  query: getCustomSurveys,
                  variables: {
                    tenantId: currentTenant?.id
                  }
                }]
              });

              navigate(
                Resources.surveys.path({ workspaceId: currentWorkspace?.id }, { activeTab: FILTERS.DRAFT })
              );
      
              Toast.success('Success! Survey duplicated');

              setIsConfirmModalOpen(false);
              setConfirmModalProps({});
            }
          });
        }
      },
    };
  
    const deleteSurveyMenu = {
      menuLabel: 'Delete',
      rcLabel: 'delete-survey-menu',
      handleAction: () => {
        setIsConfirmModalOpen(true);
        setConfirmModalProps({
          confirmButtom: 'Yes, Delete',
          isDestructive: true,
          text: 'There is no undelete option. By deleting the survey, you will lose access to your survey and all the survey’s recipient responses.',
          onConfirm: async () => {
            await deleteSurvey({
              variables: {
                id: surveyId
              },
              refetchQueries: [{
                query: getCustomSurveys,
                variables: {
                  tenantId: currentTenant?.id
                }
              }]
            });
  
            Toast.success('Success! Survey deleted ');
            setIsConfirmModalOpen(false);
            setConfirmModalProps({});
            navigate(Resources.surveys.path({ workspaceId: currentWorkspace?.id }), { activeTab: survey.status });
          }
        });
      },
    };
  
    const closeSurveyMenu = {
      menuLabel: 'Close',
      rcLabel: 'close-survey-menu',
      handleAction: () => {
        setIsConfirmModalOpen(true);
        setConfirmModalProps({
          confirmButtom: 'Close survey',
          text: 'By closing the survey, Matter will no longer send recipients requests to complete the survey, and no future responses will be allowed.',
          onConfirm: async () => {
            await closeSurvey({
              variables: {
                id: surveyId
              },
              refetchQueries: [{
                query: getCustomSurveys,
                variables: {
                  tenantId: currentTenant?.id
                }
              }]
            });
  
            Toast.success('Success! Survey closed');
            setIsConfirmModalOpen(false);
            setConfirmModalProps({});
          }
        });
      }
    };

    const reportMenu = {
      menuLabel: 'Download Report',
      rcLabel: 'download-report-menu',
      handleAction: () => {
        downloadReport(surveyId);
      }
    };

    const sendReminderMenu = {
      menuLabel: 'Send Reminder',
      rcLabel: 'send-reminder-menu',
      handleAction: () => {
        setIsConfirmModalOpen(true);
        setConfirmModalProps({
          confirmButtom: 'Send Reminder',
          text: reminderCopy,
          onConfirm: async () => {
            await sendReminder({
              variables: {
                surveyId,
                tenantId: currentTenant?.id
              }
            });
  
            Toast.success('Success! Reminder message sent');
            setIsConfirmModalOpen(false);
            setConfirmModalProps({});
          }
        });
      }
    };

    const duplicateSurveyMenu = {
      menuLabel: 'Duplicate',
      rcLabel: 'duplicate-survey-menu',
      handleAction: async () => {
        await duplicateSurvey({
          variables: {
            surveyId
          },
          refetchQueries: [{
            query: getCustomSurveys,
            variables: {
              tenantId: currentTenant?.id
            }
          }]
        });

        navigate(
          Resources.surveys.path({ workspaceId: currentWorkspace?.id }, { activeTab: FILTERS.DRAFT })
        );

        Toast.success('Success! Survey duplicated');
      }
    };

    const editNotificationsMenu = {
      menuLabel: 'Notifications',
      rcLabel: 'notifications-menu',
      handleAction: () => {
        setEditNotifications(survey || menuSurvey);
      },
    };

    if (['scheduled'].includes(status)) {
      return [
        editNotificationsMenu,
        editSurveyMenu,
        duplicateSurveyMenu,
        closeSurveyMenu,
        deleteSurveyMenu,
      ];
    }

    if (['active'].includes(status)) {
      return [
        sendReminderMenu,
        editNotificationsMenu,
        reportMenu,
        editSurveyMenu,
        duplicateSurveyMenu,
        closeSurveyMenu,
        deleteSurveyMenu,
      ];
    }
  
    if (status === 'closed') {
      return [
        duplicateSurveyMenu,
        deleteSurveyMenu
      ];
    }
  
    // Draft
    return [
      editSurveyMenu,
      duplicateSurveyMenu,
      deleteSurveyMenu,
    ];
  }

  return {
    getMenuMap,
    editSurvey,
    isEditorOpen,
    setIsEditorOpen,
    isConfirmModalOpen,
    confirmModalProps: {
      ...confirmModalProps,
      confirmButtonDisabled
    },
    setEditSurvey,
    setIsConfirmModalOpen,
    setConfirmModalProps,
    editNotifications,
    setEditNotifications,
    FILTERS,
    downloadReport,
    downloadingReport
  };
}
