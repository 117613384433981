import React, { useState, useEffect } from 'react';
import { Modal, Input, Checkbox } from '@matterapp/matter-ui';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import createPulseCategory from 'graphql-queries/mutations/pulse/createPulseCategory';
import updatePulseCategory from 'graphql-queries/mutations/pulse/updatePulseCategory';
import getTenantPulseCategories from 'graphql-queries/queries/pulse/getTenantPulseCategories';
import { useMutation } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import { ToggleContainer } from './styles';

const StyledModal = styled(Modal)({
  zIndex: 100
});

const StyledInput = styled(Input)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '340px',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));

const Section = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(5)} ${theme.spacing(3)}`,
  '&:first-of-type': {
    borderBottom: `1px solid ${theme.palette.blacks[30]}`,
    paddingBottom: theme.spacing(4)
  }
}));

export default function AddCategoryModal(props) {
  const {
    isOpen,
    isEdit,
    category,
    onClose,
    tenantId,
    allCategories = []
  } = props;
  const [categoryName, setCategoryName] = useState('');
  const [isEnabled, setIsEnabled] = useState(true);
  const [createCategory] = useMutation(createPulseCategory);
  const [updateCategory] = useMutation(updatePulseCategory);

  const isDuplicate = allCategories?.find(({ name, id }) => name.toLowerCase() === categoryName?.trim().toLowerCase() && category?.id !== id);
  const nameError = categoryName?.length > 255 ? 'Title must be at most 255 characters' : null;

  useEffect(() => {
    if (isEdit && category) {
      setCategoryName(category.name);
      setIsEnabled(category.isEnabled);
    }
  }, [isOpen, isEdit, category]);

  function resetAndClose() {
    setCategoryName('');
    setIsEnabled(true);
    onClose();
  }

  return (
    <StyledModal 
      isOpen={isOpen} 
      onClose={resetAndClose}
      size="large"
    >
      <Modal.Header
        header='Add Category'
        right={<Modal.Buttons.CloseIcon onClick={resetAndClose} />}
      />
      <Modal.Body>
        <Section>
          <Typography variant='h4'>
            Title
          </Typography>
          <StyledInput
            type="text"
            value={categoryName}
            rcLabel="category-title-input"
            placeholder="Enter category title"
            showErrorMessageBelow
            errorMessage={nameError || isDuplicate ? 'Category already exists' : null}
            onChange={(event) => {  
              setCategoryName(event.target.value);
            }}
          />
          <Typography variant='body2' sx={{ mt: 2 }}>
            💡 Subcategories can be added later to gather more detailed insights.
          </Typography>
        </Section>
        <Section>
          <Typography variant='h4'>
            Status
          </Typography>
          <ToggleContainer>
            <Typography variant='body1' component='p' >
              Category {isEnabled ? 'Enabled' : 'Disabled'}
            </Typography>
            <Checkbox.Toggle
              size='S' 
              checked={isEnabled}
              onChange={() => setIsEnabled(!isEnabled)}
            />
          </ToggleContainer>
          <Typography variant='body2'>
            Questions in a disabled category will not be included in pulse surveys.
          </Typography>
        </Section>
      </Modal.Body>
      <Modal.Footer.WithCancelSave
        canClickSave={categoryName.length > 1 && categoryName.length < 256 && !isDuplicate}
        onClickCancel={resetAndClose}
        saveLabel='Save'
        cancelLabel='Close'
        onClickSave={async () => {
          if (isEdit) {
            await updateCategory({ 
              variables: { 
                id: category.id,
                tenantId,
                name: categoryName,
                isEnabled
              }
            });
            resetAndClose();
            Toast.success('Category updated');
          } else {
            await createCategory({ 
              variables: { 
                tenantId,
                name: categoryName,
                isEnabled
              },
              refetchQueries: [{
                query: getTenantPulseCategories,
                variables: { tenantId }
              }]
            });
            resetAndClose();
            Toast.success('Category added');
          }
        }}
      />
    </StyledModal>
  );
};
