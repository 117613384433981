import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../SVG';

const Icon = ({ fill, size }) => (
  <SVG size={size} viewBox="0 0 24 24">
    <path
      fill={fill}
      d="M19.7,18.3c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0L12,13.4l-6.3,6.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l6.3-6.3L4.3,5.7
        c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6.3,6.3l6.3-6.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L13.4,12L19.7,18.3z"
    />
  </SVG>
);

Icon.defaultProps = {
  fill: 'black',
  size: 24,
};

Icon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default Icon;
