import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Dropdown } from '@matterapp/matter-ui';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import setFeedbackRitualSettings from 'graphql-queries/mutations/slackWorkspace/setFeedbackRitualSettings';
import { useLazyQuery, useMutation } from '@apollo/client';
import { 
  StyledModalBody
} from './styles';
import Toast from 'components/Toast/Toast';
import { getDropdownInviteMessage } from 'hooks/composer/shared';

export default function AddMembersModal(props) {
  const { workspace, isOpen, onClose } = props;
  const { id: workspaceId, feedbackRitualSettings, slackWorkspace } = workspace;
  const { excludePeopleFromMVP = [] } = feedbackRitualSettings || {};

  const [searchMembers, { loading: isLoadingResults, data }] = useLazyQuery(searchWorkspaceMembers);
  const [selectedMembers, updateSelectedMembers] = useState([]);
  const [updateRitualSettings, { loading: savingSettings, data: savedMembers }] = useMutation(setFeedbackRitualSettings);
  const primaryChannel = slackWorkspace?.primaryChannel;

  const canSave = !!selectedMembers.length;

  function resetAndClose() {
    updateSelectedMembers([]);
    onClose();
  }

  async function onSearch(value) {
    if (value) {
      searchMembers({
        variables: {
          workspaceId,
          searchString: value
        }
      });
    }
  }

  function onChange(_, { value: updatedList }) {
    if (updatedList.length < selectedMembers.length) {
      const newMembers = selectedMembers.filter(({ value }) => updatedList.find(email => email === value));
      updateSelectedMembers(newMembers);
    }
  }

  function onSelect(_, item ) {
    if (item) {
      updateSelectedMembers([...selectedMembers, item.item]);
    }
  }

  function formatMembers(members) {
    return members.map((member) => {
      return {
        ...member,
        label: member.person.fullName,
        subLabel: member.person.email,
        value: member.person.email
      };
    });
  }

  useEffect(() => {
    if (!savingSettings && savedMembers) {
      Toast.success('You successfully excluded member(s)');

      resetAndClose();
    }
  }, [savingSettings, savedMembers]);
 
  return (
    <Modal 
      isOpen={isOpen} 
      onClose={resetAndClose}
      size="large"
    >
      <Modal.Header
        header="Exclude Members"
        right={<Modal.Buttons.CloseIcon onClick={resetAndClose} />}
      />
      <StyledModalBody>
        <Dropdown.Email.Tags
          filterItems
          formatTagLabel={(email) => selectedMembers.find((member) => member.value === email)?.label}
          items={formatMembers(data?.searchMembers || [])}
          showValuesAsSubLabels
          isValidValue={() => true}
          onChange={onChange}
          onSearch={onSearch}
          onSelect={onSelect}
          isLoadingResults={isLoadingResults}
          emptyMenuMessageNoItems={getDropdownInviteMessage(primaryChannel)}
          placeholder="Enter name(s)"
          fluid
          isInitiallyPristine
        />
      </StyledModalBody>
      <Modal.Footer.WithCancelSave
        canClickSave={canSave}
        onClickCancel={resetAndClose}
        onClickSave={() => {
          updateRitualSettings({
            variables: {
              settings: {
                isActive: true,
                excludePeopleFromMVP: Array.from(new Set([...selectedMembers.map(({ person }) => parseInt(person.id)), ...excludePeopleFromMVP])),
              },
              workspaceId
            }
          });
        }}
        sendIsLoading={savingSettings}
      />
    </Modal>
  );
}

AddMembersModal.propTypes = {
  workspace: PropTypes.object,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool
};

