import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { 
  Typography
} from '@mui/material';
import { BalanceSectionWrapper } from './styles';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';
import { Button as MatterButton } from '@matterapp/matter-ui';

export default function BalanceSection() {
  const { 
    currentTenant,
    currentWorkspaceId
  } = useContext(UserContext);
  const { rewardsFundingSettings } = currentTenant || {};
  const { balance = 0 } = rewardsFundingSettings || {};
  const navigate = useNavigate();

  return (
    <BalanceSectionWrapper>
      <Typography variant='h4'>
        Available Balance: ${(parseInt(balance) / 100).toLocaleString()} USD
      </Typography>
      <MatterButton.Simple.Primary.Vivid
        size='M'
        color='blue'
        onClick={() => {
          navigate(Resources.rewardsAddFunding.path({ workspaceId: currentWorkspaceId }));
        }}
      >
        Add Funds
      </MatterButton.Simple.Primary.Vivid> 
    </BalanceSectionWrapper>
  );
};
