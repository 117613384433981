import React from 'react';
import { SVG } from './consts';

const Spaces = (props) => (
  <SVG {...props}>
    <path
      fill="#ADAEE2"
      d="M0,83C0,37.2,37.2,0,83,0s83,37.2,83,83s-37.2,83-83,83S0,128.8,0,83z"
    />
    <path
      fill="#FFFFFF"
      d="M28.2,105.3c0,12.2,10,22.2,22.2,22.2c12.2,0,22.2-10,22.2-22.2s-10-22.2-22.2-22.2
	C38.2,83.1,28.2,93.1,28.2,105.3z M51.8,49.8c-6.8,0-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5c6.9,0,12.5-5.6,12.5-12.5
	C64.3,55.4,58.7,49.8,51.8,49.8z M51.8,67.8c-3,0-5.5-2.5-5.5-5.5c0-3,2.5-5.5,5.5-5.5c3,0,5.5,2.5,5.5,5.5
	C57.3,65.3,54.9,67.8,51.8,67.8z M109,78.4c-1.8,0-3-1.4-3-3.1c0-1.8,1.4-3.1,3-3.1h30.3v-0.2c0-1.8-0.2-3.4-0.4-5.1h-13.2
	c-1.8,0-3-1.4-3-3.1c0-1.6,1.4-3.1,3-3.1h11.8c-4.6-13-16.9-22.2-31.4-22.2c-9.3,0-17.8,4-23.9,10.1h22.1c1.8,0,3,1.4,3,3.1
	c0,1.6-1.4,3.1-3,3.1H77.6c-1.1,1.6-1.8,3.4-2.5,5.2h12.8c1.8,0,3,1.4,3,3.1c0,1.6-1.4,3.1-3,3.1H73.2c-0.4,1.8-0.5,3.6-0.5,5.6
	c0,5.6,1.4,10.7,3.7,15.4h27.8c1.8,0,3,1.4,3,3.1c0,1.8-1.4,3.1-3,3.1H80.5c6.1,7.4,15.3,11.9,25.5,11.9c16.2,0,29.6-11.7,32.6-27.1
	H109V78.4z"
    />
  </SVG>
);

export default Spaces;
