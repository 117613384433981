import React from 'react';
import styled from 'styled-components';
import { 
  Typography,
  Box
} from '@mui/material';

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  backgroundColor: theme.palette.purples[50],
  borderRadius: '20px',
  height: '40px',
  marginTop: theme.spacing(4)
}));

const TextWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  height: '40px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `0 ${theme.spacing(2)}`
}));

const Progress = styled(Box)(({ theme, progress }) => ({
  position: 'absolute',
  left: 0,
  width: !progress ? '10%': `${progress}%`,
  backgroundColor: theme.palette.reds[40],
  borderRadius: progress >= 100 ? '20px': '20px 0 0 20px',
  height: '40px',
  transition: 'width 1s ease-in-out'
}));


export default function ProgressLine({ progress, total, text }) {
  return (
    <Wrapper>
      <Progress progress={Math.round((progress / total) * 100)} />
      <TextWrapper>
        <Typography color='purples.50' variant='body2'> </Typography>
        <Typography color='white.main' variant='body2' sx={{ textTransform: 'uppercase' }}>{progress}/{total} {text}</Typography>
        <Typography color='white.main' variant='body2'>{progress}/{total}</Typography>
      </TextWrapper>
    </Wrapper>
  );
}
