import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Content from '../Content';
import { ButtonNew } from '../Button';

const CollapseButtonContainer = styled.div`
`;

const CollapseButton = styled(ButtonNew).attrs({
  link: true,
})`
  font-size: 17px;
  color: white;
  &:hover {
    text-decoration: underline;
  }
`;

class CollapseBlock extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpened: false,
    };
  }

  render() {
    const { children, linkTextOpen, linkTextClosed } = this.props;
    const { isOpened } = this.state;
    return (
      <div>
        <Content.Collapse
          isOpened={isOpened}
        >
          {children}
        </Content.Collapse>
        <CollapseButtonContainer
          style={{ marginTop: isOpened ? 24 : 0 }}
        >
          <CollapseButton
            onClick={() => this.setState({
              isOpened: !isOpened,
            })}
          >
            {isOpened ? linkTextOpen : linkTextClosed}
          </CollapseButton>
        </CollapseButtonContainer>
      </div>
    );
  }
}

CollapseBlock.defaultProps = {
};

CollapseBlock.propTypes = {
  linkTextOpen: PropTypes.string.isRequired,
  linkTextClosed: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CollapseBlock;
