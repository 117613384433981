import React from 'react';
import PropTypes from 'prop-types';
import { OAuthConnect } from 'modules';
import styled from 'styled-components';
import { Button } from '@matterapp/matter-ui';

const ConnectButtonInner = styled(Button.Simple.Primary).attrs({
  content: 'Continue',
  bold: true,
  size: 'S',
})``;

export default class ConnectButton extends React.PureComponent {
  static propTypes = {
    /** Data label for cypress. */
    rcLabel: PropTypes.string.isRequired,
    /** Callback when connect button is clicked. */
    onClick: PropTypes.func.isRequired,
    /** Url redirect after successfully connecting */
    successRedirect: PropTypes.string.isRequired,
    /** The id of the OAuthConnect flow to use */
    oAuthId: PropTypes.string.isRequired,
  };

  getClickHandler = ({ handleConnect }) => () => {
    this.props.onClick();
    handleConnect();
  };

  render() {
    const {
      rcLabel,
      successRedirect,
      oAuthId,
    } = this.props;
    const Connect = OAuthConnect[oAuthId];
    return (
      <Connect successRedirect={successRedirect}>
        {({ handleConnect, isLoading }) => {
          if (isLoading) {
            return null;
          }
          return (
            <ConnectButtonInner
              rcLabel={rcLabel}
              onClick={this.getClickHandler({
                handleConnect,
                isLoading,
              })}
            />
          );
        }}
      </Connect>
    );
  }
}
