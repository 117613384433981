import React from 'react';
import { CustomPicker } from 'react-color';
import { Saturation, Hue } from 'react-color/lib/components/common';
import PropTypes from 'prop-types';

export const ColorPicker = ({ hex, hsl, hsv, onChange }) => {
  const styles = {
    hue: {
      height: 10,
      position: 'relative',
      marginBottom: 10,
    },
    saturation: {
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      marginBottom: 10,
      paddingBottom: '75%'
    }
  };

  return (
    <div 
      className='custom-color-picker'
      style={{
        width: 250,
        height: 215,
        backgroundColor: 'white',
        borderRadius: 5,
        padding: 10,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px'
      }}>
      <div style={styles.saturation}>
        <Saturation hsl={hsl} hsv={hsv} hex={hex} onChange={onChange} />
      </div>
      <div style={styles.hue}>
        <Hue hsl={ hsl } onChange={ onChange } />
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  onChange: PropTypes.func,
  hex: PropTypes.string,
  hsl: PropTypes.string,
  hsv: PropTypes.string
};

export default CustomPicker(ColorPicker);