import styled from 'styled-components';
import { IconNew } from '../Icon';
import theme from '@matterapp/matter-theme';
import { colors, sizes } from './utils';

export const VARIANTS = {
  BASE: 'base',
  OPEN: 'open',
  ERROR: 'error',
  DISABLED: 'disabled',
  SELECTED: 'selected',
};

export const backgroundColors = {
  [colors.DEFAULT]: {
    [VARIANTS.BASE]: 'transparent',
    [VARIANTS.OPEN]: 'transparent',
    [VARIANTS.ERROR]: theme.colors.reds[5],
    [VARIANTS.DISABLED]: theme.colors.white,
  },
  [colors.ALT]: {
    [VARIANTS.BASE]: 'transparent',
    [VARIANTS.OPEN]: theme.colors.blues[1],
    [VARIANTS.ERROR]: theme.colors.reds[5],
    [VARIANTS.DISABLED]: theme.colors.white,
  },
  [colors.GRAY]: {
    [VARIANTS.BASE]: 'transparent',
    [VARIANTS.OPEN]: 'transparent',
    [VARIANTS.ERROR]: theme.colors.reds[5],
    [VARIANTS.DISABLED]: theme.colors.blacks[5],
  },
  [colors.GRAY_ALT]: {
    [VARIANTS.BASE]: 'transparent',
    [VARIANTS.OPEN]: 'transparent',
    [VARIANTS.ERROR]: theme.colors.reds[5],
    [VARIANTS.DISABLED]: theme.colors.blacks[5],
  },
};

export const borderColors = {
  [colors.DEFAULT]: {
    [VARIANTS.BASE]: theme.colors.blacks[40],
    [VARIANTS.OPEN]: theme.colors.blues[50],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[20],
  },
  [colors.ALT]: {
    [VARIANTS.BASE]: theme.colors.blacks[20],
    [VARIANTS.OPEN]: theme.colors.blues[50],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[20],
  },
  [colors.GRAY]: {
    [VARIANTS.BASE]: theme.colors.blacks[20],
    [VARIANTS.OPEN]: theme.colors.blacks[20],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[10],
  },
  [colors.GRAY_ALT]: {
    [VARIANTS.BASE]: theme.colors.whites,
    [VARIANTS.OPEN]: theme.colors.blacks[20],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[10],
  },
};

export const arrowColors = {
  [colors.DEFAULT]: {
    [VARIANTS.BASE]: theme.colors.blacks[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[20],
  },
  [colors.ALT]: {
    [VARIANTS.BASE]: theme.colors.blacks[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[20],
  },
  [colors.GRAY]: {
    [VARIANTS.BASE]: theme.colors.blacks[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[30],
  },
  [colors.GRAY_ALT]: {
    [VARIANTS.BASE]: theme.colors.blacks[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[30],
  },
};

export const checkColors = {
  [colors.DEFAULT]: {
    [VARIANTS.BASE]: theme.colors.blues[50],
  },
  [colors.ALT]: {
    [VARIANTS.BASE]: theme.colors.blues[50],
  },
  [colors.GRAY]: {
    [VARIANTS.BASE]: theme.colors.blacks[40],
  },
  [colors.GRAY_ALT]: {
    [VARIANTS.BASE]: theme.colors.blacks[40],
  },
};

export const itemBackground = {
  [colors.DEFAULT]: {
    [VARIANTS.BASE]: theme.colors.white,
    [VARIANTS.SELECTED]: theme.colors.white,
    [VARIANTS.ERROR]: theme.colors.white,
    [VARIANTS.DISABLED]: theme.colors.white,
  },
  [colors.ALT]: {
    [VARIANTS.BASE]: theme.colors.white,
    [VARIANTS.SELECTED]: theme.colors.white,
    [VARIANTS.DISABLED]: theme.colors.white,
    [VARIANTS.ERROR]: theme.colors.white,
  },
  [colors.GRAY]: {
    [VARIANTS.BASE]: theme.colors.white,
    [VARIANTS.SELECTED]: theme.colors.white,
    [VARIANTS.ERROR]: theme.colors.white,
    [VARIANTS.DISABLED]: theme.colors.white,
  },
  [colors.GRAY_ALT]: {
    [VARIANTS.BASE]: theme.colors.blacks[5],
    [VARIANTS.SELECTED]: theme.colors.white,
    [VARIANTS.ERROR]: theme.colors.blacks[5],
    [VARIANTS.DISABLED]: theme.colors.blacks[5],
  },
};

export const itemHighlight = {
  [colors.DEFAULT]: {
    [VARIANTS.BASE]: theme.colors.blacks[5],
    [VARIANTS.ERROR]: theme.colors.reds[5],
  },
  [colors.ALT]: {
    [VARIANTS.BASE]: theme.colors.blacks[5],
    [VARIANTS.ERROR]: theme.colors.reds[5],
  },
  [colors.GRAY]: {
    [VARIANTS.BASE]: theme.colors.blacks[5],
    [VARIANTS.ERROR]: theme.colors.reds[5],
  },
  [colors.GRAY_ALT]: {
    [VARIANTS.BASE]: theme.colors.white,
    [VARIANTS.ERROR]: theme.colors.reds[5],
  },
};

export const itemTextColors = {
  [colors.DEFAULT]: {
    [VARIANTS.BASE]: theme.colors.blacks[80],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[30],
  },
  [colors.ALT]: {
    [VARIANTS.BASE]: theme.colors.blacks[80],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[50],
  },
  [colors.GRAY]: {
    [VARIANTS.BASE]: theme.colors.blacks[60],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[60],
  },
  [colors.GRAY_ALT]: {
    [VARIANTS.BASE]: theme.colors.blacks[60],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[60],
  },
};

export const textColors = {
  [colors.DEFAULT]: {
    [VARIANTS.BASE]: theme.colors.blacks[80],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[30],
  },
  [colors.ALT]: {
    [VARIANTS.BASE]: theme.colors.blacks[80],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[50],
    [VARIANTS.OPEN]: theme.colors.blues[50],
  },
  [colors.GRAY]: {
    [VARIANTS.BASE]: theme.colors.blacks[40],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[30],
  },
  [colors.GRAY_ALT]: {
    [VARIANTS.BASE]: theme.colors.blacks[40],
    [VARIANTS.ERROR]: theme.colors.reds[40],
    [VARIANTS.DISABLED]: theme.colors.blacks[30],
  },
};

export const fontSizes = {
  [sizes.XS]: theme.fontSize.XS,
  [sizes.S]: theme.fontSize.base,
  [sizes.M]: theme.fontSize.S2,
  [sizes.L]: theme.fontSize.S,
  [sizes.XL]: theme.fontSize.S,
};

export const heights = {
  [sizes.XS]: theme.spacing.singleAndHalf,
  [sizes.S]: theme.spacing.double,
  [sizes.M]: theme.spacing.triple,
  [sizes.L]: theme.spacing.tripleAndHalf,
  [sizes.XL]: theme.spacing.quadruple,
};

export const iconRight = {
  [sizes.XS]: theme.spacing.half,
  [sizes.S]: theme.spacing.half,
  [sizes.M]: theme.spacing.half,
  [sizes.L]: theme.spacing.threeQuarters,
  [sizes.XL]: theme.spacing.single,
};

export const checkIconSize = {
  [sizes.XS]: theme.spacing.single,
  [sizes.S]: theme.spacing.singleAndQuarter,
  [sizes.M]: theme.spacing.singleAndHalf,
  [sizes.L]: theme.spacing.singleAndHalf,
  [sizes.XL]: theme.spacing.singleAndHalf,
};

export const paddings = {
  [sizes.XS]: theme.spacing.half,
  [sizes.S]: theme.spacing.threeQuarters,
  [sizes.M]: theme.spacing.single,
  [sizes.L]: theme.spacing.single,
  [sizes.XL]: theme.spacing.single,
};

export const paddingsVertical = {
  [sizes.XS]: theme.spacing.quarter,
  [sizes.S]: theme.spacing.half,
  [sizes.M]: theme.spacing.threeQuarters,
  [sizes.L]: theme.spacing.threeQuarters,
  [sizes.XL]: theme.spacing.threeQuarters,
};

export const transitionTime = theme.transitions.times.default;

/*****************************************************************************/
/* COLOR UTILS                                                               */
/*****************************************************************************/
export const getColorStyles = (styleObj = {}, color, variant) => {
  const style = styleObj[color] || styleObj[colors.DEFAULT];
  if (style[variant]) {
    return style[variant];
  }
};

export const getBackgroundColor = ({ color, variant }) =>
  getColorStyles(backgroundColors, color, variant);

export const getBorderColor = ({ color, variant }) =>
  getColorStyles(borderColors, color, variant);

export const getArrowColor = ({ color, variant }) =>
  getColorStyles(arrowColors, color, variant);

export const getCheckColor = ({ color, variant }) =>
  getColorStyles(checkColors, color, variant);

export const getItemBackgroundColor = ({ color, variant }) =>
  getColorStyles(itemBackground, color, variant);

export const getItemHighlightColor = ({ color, variant }) =>
  getColorStyles(itemHighlight, color, variant);

export const getItemTextColor = ({ color, variant }) =>
  getColorStyles(itemTextColors, color, variant);

export const getTextColor = ({ color, variant }) =>
  getColorStyles(textColors, color, variant);

/*****************************************************************************/
/* SIZE UTILS                                                                */
/*****************************************************************************/
export const getSizeStyle = (styleObj = {}, size) =>
  styleObj[size] || styleObj.M;

export const getItemFontStyles = ({ size }) => getSizeStyle(fontSizes, size);

export const getItemPaddingStyles = ({ singleValue, size }) => {
  if (singleValue) {
    return theme.spacing.half;
  }
  return `
    ${getSizeStyle(paddingsVertical, size)} ${getSizeStyle(paddings, size)};
  `;
};

export const getIconRightStyles = ({ size }) => getSizeStyle(iconRight, size);

export const getMenuStyles = ({ menuProps = { position: 'left' }, showMenuOverDropdown, size }) => {
  const menuWidth  = menuProps?.width ? `min-width: ${menuProps.width}px;` : ``;
  let menuPosition = '';

  if (menuProps?.position) {
    if (menuProps.position === 'right') {
      menuPosition = 'right: 0;';
    }
    if (menuProps.position === 'center') {
      menuPosition = `
        left: 50%;
        transform: translateX(-50%);
      `;
    }
  }

  if (showMenuOverDropdown) {
    return `
      margin-top: calc(${heights[size] || heights[sizes.M]} * -1 - 2px);
      border-radius: ${theme.inputs.borderRadius};
      ${menuPosition}
      ${menuWidth}
    `;
  }
  if (menuProps?.showBelowDropdown) {
    return `
      margin-top: ${theme.spacing.half};
      border-radius: ${theme.inputs.borderRadius};
      ${menuPosition}
      ${menuWidth}
    `;
  }
  return `
    border-top-color: rgba(0, 0, 0, 0.1);
    border-radius: 0 0 ${theme.inputs.borderRadius} ${theme.inputs.borderRadius};
    margin-top: -3px;
    ${menuPosition}
    ${menuWidth}
  `;
};

export const getCheckIconSize = ({ size }) => {
  const checkSize = getSizeStyle(checkIconSize, size);
  return `
    width: ${checkSize};
  `;
};

export const getItemSizeStyles = (props) => `
  font-size: ${getItemFontStyles(props)};
  padding: ${getItemPaddingStyles(props)};
`;

export const CheckIcon = styled(IconNew).attrs(() => ({
  name: 'checkmark',
}))``;

export const DropdownIcon = styled(IconNew).attrs(() => ({
  name: 'karotDownSmall',
  backgroundFill: 'transparent',
}))``;

export const AutocompleteContainer = styled.div`
  position: relative;
  display: ${({ fluid }) => (fluid ? 'block' : 'inline-block')};

  & > div:first-child {
    display: block;
    flex: 1 1 auto;
  }
`;

export const IconContainer = styled.div`
  align-items: center;
  background: transparent;
  display: flex;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: ${getIconRightStyles};
  transform: translateY(-50%);

  & ${CheckIcon} {
    ${getCheckIconSize}
    & path {
      fill: ${theme.colors.blues[40]};
    }
  }
`;

export const MenuContainer = styled.div`
  background: ${theme.colors.white};
  border: 1px solid
    ${(props) => getBorderColor({ ...props, variant: VARIANTS.OPEN })};
  box-shadow: ${theme.shadows[50]};
  max-height: 272px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  text-align: left;
  width: 100%;
  z-index: ${theme.zIndex.dropdown};

  ${getMenuStyles}

  [data-error='true'] + & {
    border-color: ${borderColors.error};
  }
`;

export const ErrorContainer = styled.div`
  color: ${(props) => getTextColor({ ...props, variant: VARIANTS.ERROR })};
  font-size: ${theme.fontSize.S};
  text-align: left;
`;

export const EmptyMenuMessage = styled.div`
  position: relative;
  box-sizing: border-box;
  background: white;
  user-select: none;

  ${getItemSizeStyles}
`;
