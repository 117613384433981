import logger from '../logger';
import { MIXPANEL_EVENT_SOURCE } from 'app-consts';
import { mixpanel } from './mixpanel';

export const trackPageView = ({ pageTitle, params = {} }) => {
  const computedParams = Object.assign({}, params, {
    eventSource: MIXPANEL_EVENT_SOURCE,
    pathName: window.location.pathname, // TODO: deprecated. remove after February 2020
    pathname: window.location.pathname,
    href: window.location.href,
    origin: window.location.href,
    hostname: window.location.hostname,
    search: window.location.search,
  });
  if (!pageTitle) {
    throw new Error('page title is required');
  }
  if (window && window.ga) {
    window.ga('set', 'page', window.location.pathname);
    window.ga('set', 'location', window.location.href);
    window.ga('send', 'pageview', window.location.pathname);
  }
  const mixpanelNewestFormattedEvent = 'pageView';
  const mixpanelOldFormattedEvent = `w::pageView:${pageTitle}`;
  logger.info(`GoogleAnalytics: ${window.location.pathname}`);
  mixpanel.track(mixpanelNewestFormattedEvent, computedParams);
  mixpanel.track(mixpanelOldFormattedEvent, computedParams);
};