import React from 'react';
import BackgroundAnimation, { ANIMATIONS } from '../../BackgroundAnimation';
import ColoredBackground from '../../ColoredBackground';
import gsap from 'gsap';
import * as S from './styles';

export default class Variant1Background extends BackgroundAnimation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  /**
   * Moves the clouds in.
   * @returns { Object } The animation timeline.
   */
  moveCloudsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 1 };
    tl.to(this.topCloudRef, { x: -120 });
    tl.to(this.bottomCloudRef, { x: 120 });

    tl.to(this.topCloudRef, resetProps, '<');
    tl.to(this.bottomCloudRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the clouds out.
   * @returns { Object } The animation timeline.
   */
  moveCloudsOut = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0 };
    tl.to(this.topCloudRef, resetProps);
    tl.to(this.bottomCloudRef, resetProps);

    tl.to(this.topCloudRef, { x: 80, duration: 0.3 }, '>');
    tl.to(this.bottomCloudRef, { x: -80, duration: 0.3 }, '<');
    return tl;
  };

  /**
   * Moves the planet in.
   * @returns { Object } The animation timeline.
   */
  movePlanetIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.to(this.planetRef, { x: -120, opacity: 0 });
    tl.to(this.planetRingRef, {
      scale: 2,
      opacity: 0,
      transformOrigin: 'center',
    });

    tl.to(this.planetRef, { opacity: 1, x: 0, duration: 1 }, '<');
    tl.to(this.planetRingRef, { scale: 1, opacity: 1, duration: 0.5 }, '<0.5');
    return tl;
  };

  /**
   * Moves the rings in.
   * @returns { Object } The animation timeline.
   */
  moveRingsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 2, opacity: 0, transformOrigin: 'center' };
    const endProps = { scale: 1, opacity: 1, duration: 0.5 };
    tl.to(this.bottomLeftRingRef, initialProps);
    tl.to(this.rightRing1Ref, initialProps);
    tl.to(this.rightRing2Ref, initialProps);
    tl.to(this.rightRing3Ref, initialProps);
    tl.to(this.leftRingRef, initialProps);
    tl.to(this.topRingRef, initialProps);
    tl.to(this.bottomRingRef, initialProps);

    tl.to(this.bottomLeftRingRef, endProps, '<0.3');
    tl.to(this.rightRing1Ref, endProps, '<');
    tl.to(this.rightRing2Ref, { ...endProps, scale: 1.2 }, '<0.1');
    tl.to(this.rightRing3Ref, endProps, '<0.2');
    tl.to(this.leftRingRef, endProps, '<-0.3');
    tl.to(this.topRingRef, endProps, '<-0.2');
    tl.to(this.bottomRingRef, endProps, '<0.3');
    return tl;
  };

  /**
   * Moves the dots in.
   * @returns { Object } The animation timeline.
   */
  moveDotsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 0.7 };
    const dotRefs = {
      rightDot1Ref: { x: -60, y: 33 },
      rightDot2Ref: { x: 37, y: 30 },
      rightBlobRef: { x: 13, y: -42 },
      centerDotRef: { x: 58, y: -26 },
      topBlobRef: { x: 12, y: 72 },
      leftBlobRef: { x: 41, y: 28 },
      leftDot1Ref: { x: 8, y: 29 },
      leftDot2Ref: { x: 6, y: 51 },
      leftCenterDotRef: { x: -14, y: -47 },
      topLeftBlobRef: { x: 25, y: -35 },
      bottomLeftBlobRef: { x: 19, y: 43 },
      bottomDotRef: { x: 3, y: -63 },
      bottomBlobRef: { x: -54, y: -5 },
      bottomRightDotRef: { x: -32, y: -55 },
      bottomRightBlobRef: { x: -44, y: -68 },
      bottomRightBlob2Ref: { x: 47, y: -32 },
    };
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], dotRefs[key]);
    });
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], resetProps, '<');
    });
    return tl;
  };

  /**
   * Moves the stars in.
   * @returns { Object } The animation timeline.
   */
  moveStarsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 0, opacity: 0, transformOrigin: 'center' };
    const zoomProps = { scale: 1.4, opacity: 1, duration: 0.3 };
    const normalizedProps = { scale: 1, duration: 0.1 };
    const starRefs = {
      topRightPlus1Ref: '<0.6',
      topRightPlus2Ref: '<-0.4',
      topRightPlus3Ref: '<-0.3',
      topRightPlus4Ref: '<-0.2',
      topRightPlus5Ref: '<-0.1',
      leftPlus1Ref: '<-0.6',
      leftPlus2Ref: '<-0.1',
      leftPlus3Ref: '<',
    };
    Object.keys(starRefs).forEach((key) => {
      tl.to(this[key], initialProps);
    });
    Object.keys(starRefs).forEach((key) => {
      tl.to(this[key], zoomProps, starRefs[key]);
      tl.to(this[key], normalizedProps, '>');
    });
    return tl;
  };

  playAnimation = () => {
    const { animation } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    if (animation !== ANIMATIONS.SHOW) {
      if (animation === ANIMATIONS.OUT) {
        tl.add(this.moveCloudsOut(), '<');
      } else {
        tl.add(this.moveCloudsIn(), '<');
        tl.add(this.movePlanetIn(), '<');
        tl.add(this.moveRingsIn(), '<');
        tl.add(this.moveStarsIn(), '<');
        tl.add(this.moveDotsIn(), '<0.3');
      }
    }
    this.tl = tl;
    return tl;
  };

  render() {
    return (
      <ColoredBackground {...this.props}>
        <S.TopCloud ref={(ref) => (this.topCloudRef = ref)} />
        <S.BottomCloud ref={(ref) => (this.bottomCloudRef = ref)} />
        <S.BottomRightDot ref={(ref) => (this.bottomRightDotRef = ref)} />
        <S.BottomDot ref={(ref) => (this.bottomDotRef = ref)} />
        <S.BottomRing ref={(ref) => (this.bottomRingRef = ref)} />
        <S.BottomLeftRing ref={(ref) => (this.bottomLeftRingRef = ref)} />
        <S.BottomLeftBlob ref={(ref) => (this.bottomLeftBlobRef = ref)} />
        <S.BottomBlob ref={(ref) => (this.bottomBlobRef = ref)} />
        <S.BottomRightBlob ref={(ref) => (this.bottomRightBlobRef = ref)} />
        <S.BottomRightBlob2 ref={(ref) => (this.bottomRightBlob2Ref = ref)} />
        <S.RightDot1 ref={(ref) => (this.rightDot1Ref = ref)} />
        <S.RightDot2 ref={(ref) => (this.rightDot2Ref = ref)} />
        <S.RightRing1 ref={(ref) => (this.rightRing1Ref = ref)} />
        <S.RightRing2 ref={(ref) => (this.rightRing2Ref = ref)} />
        <S.RightRing3 ref={(ref) => (this.rightRing3Ref = ref)} />
        <S.RightBlob ref={(ref) => (this.rightBlobRef = ref)} />
        <S.CenterDot ref={(ref) => (this.centerDotRef = ref)} />
        <S.TopRightPlus1 ref={(ref) => (this.topRightPlus1Ref = ref)} />
        <S.TopRightPlus2 ref={(ref) => (this.topRightPlus2Ref = ref)} />
        <S.TopRightPlus3 ref={(ref) => (this.topRightPlus3Ref = ref)} />
        <S.TopRightPlus4 ref={(ref) => (this.topRightPlus4Ref = ref)} />
        <S.TopRightPlus5 ref={(ref) => (this.topRightPlus5Ref = ref)} />
        <S.TopLeftBlob ref={(ref) => (this.topLeftBlobRef = ref)} />
        <S.LeftCenterDot ref={(ref) => (this.leftCenterDotRef = ref)} />
        <S.LeftRing ref={(ref) => (this.leftRingRef = ref)} />
        <S.LeftBlob ref={(ref) => (this.leftBlobRef = ref)} />
        <S.LeftDot1 ref={(ref) => (this.leftDot1Ref = ref)} />
        <S.LeftDot2 ref={(ref) => (this.leftDot2Ref = ref)} />
        <S.LeftPlus1 ref={(ref) => (this.leftPlus1Ref = ref)} />
        <S.LeftPlus2 ref={(ref) => (this.leftPlus2Ref = ref)} />
        <S.LeftPlus3 ref={(ref) => (this.leftPlus3Ref = ref)} />
        <S.TopBlob ref={(ref) => (this.topBlobRef = ref)} />
        <S.TopRing ref={(ref) => (this.topRingRef = ref)} />
        <S.PlanetGroup ref={(ref) => (this.planetRef = ref)}>
          <S.PlanetRing ref={(ref) => (this.planetRingRef = ref)} />
          <S.Planet />
        </S.PlanetGroup>
      </ColoredBackground>
    );
  }
}
