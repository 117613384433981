import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TimeAgo from '../TimeAgo';
import Text from '../Typography/Text';
import { ListItem, ListItemStackContainer, ListItemActionBox } from '../List';
const ContentContainer = styled.div`
  flex: 1;
  text-align: left;
  padding-right: 12px;
  user-select: none;

  > * {
    display: inline;
    line-height: 16px;
    margin-right: 5px;
  }
`;
const FreeformNotificationItem = ({
  left,
  center,
  right,
  eventTime,
  onClick,
  highlighted,
}) => {
  return (
    <ListItem onClick={onClick} highlighted={highlighted}>
      {left}
      <ListItemStackContainer>
        <ContentContainer>
          {center}
          <Text color="blacks[50]" textStyle={340}>
            <TimeAgo date={eventTime} />
          </Text>
        </ContentContainer>
        <ListItemActionBox>{right}</ListItemActionBox>
      </ListItemStackContainer>
    </ListItem>
  );
};

FreeformNotificationItem.defaultProps = {
  onClick: undefined,
  highlighted: false,
};

FreeformNotificationItem.propTypes = {
  left: PropTypes.node.isRequired,
  center: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
  eventTime: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  highlighted: PropTypes.bool,
};

export default FreeformNotificationItem;
