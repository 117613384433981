import styled from 'styled-components';
import { CTA_BUTTON_CLASS_NAME } from './consts';

const Container = styled.div`
  padding: 24px;
  padding-bottom: 32px;

  .${CTA_BUTTON_CLASS_NAME} + .${CTA_BUTTON_CLASS_NAME} {
    margin-top: 18px;
  }
`;

export default Container;
