import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dotdotdot from 'react-dotdotdot';
import { personPropType } from '@matterapp/people';
import { CONNECTION_ACTIVITY_TYPE } from '@matterapp/peer-connections';
import { IconNew } from '../../Icon';
import { Activity } from '../prop-types';
import Avatar from '../../Avatar';
import { PopoverMenu } from '../../Menu';
import TimeAgo from '../../TimeAgo';
import theme from '@matterapp/matter-theme';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  background: white;
  color: ${() => theme.colors.blacks[90]};
  ${({ isActive }) =>
    isActive && `background: ${theme.colors.blacks[5]};`}

  padding: 16px;

  ${() => theme.media.medium`
    padding-left: 24px;
    padding-right: 24px;
  `}
`;

const AvatarContainer = styled.div`
  margin-right: 16px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  ${() => theme.media.medium`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}

  ${() => theme.media.large`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

const ContentTextContainer = styled(Dotdotdot).attrs({})`
  flex: 1;
  margin-right: 16px;
  word-break: break-word;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  font-size: 17px;

  -webkit-line-clamp: 1 !important;

  ${() => theme.media.medium`
    -webkit-line-clamp: 2 !important;
  `}
`;

const ContentExplainerContainer = styled.div`
  font-style: italic;
  ${() => theme.media.medium`
    color: ${theme.colors.purples[50]}
  `}
`;

const ActionsContainer = styled.div`
  padding: 0;
  position: relative;
  margin-left: 16px;

  .popover-menu__trigger {
    svg {
      path {
        fill: ${() => theme.colors.purples[50]};
      }
    }
  }

  .popover-menu__menu {
    min-width: 158px;
    margin-right: -24px;
    top: 16px;
    overflow: hidden;
  }
`;

class ListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
    };
  }

  getExplainer = () => {
    const { activitySummary } = this.props;
    const { type, time } = activitySummary;
    switch (type) {
    case CONNECTION_ACTIVITY_TYPE.ADDED_WORK_RELATIONSHIP:
      return 'you work together';
    case CONNECTION_ACTIVITY_TYPE.GAVE_FEEDBACK: {
      return (
        <React.Fragment>
            you gave feedback <TimeAgo date={time} />
        </React.Fragment>
      );
    }
    case CONNECTION_ACTIVITY_TYPE.RECEIVED_FEEDBACK: {
      return (
        <React.Fragment>
            gave you feedback <TimeAgo date={time} />
        </React.Fragment>
      );
    }
    case CONNECTION_ACTIVITY_TYPE.REQUESTED_LONGFORM_FEEDBACK:
      return 'pending';
    default:
      throw new Error(`Unrecognized activity type: ${type}`);
    }
  };

  renderAvatar = () => {
    const { defaultAvatarIsGenerated, person, renderProfileCard } = this.props;
    const { photoUrl, email } = person;
    return (
      <AvatarContainer>
        {renderProfileCard({
          person,
          anchor: (
            <Avatar
              defaultAvatarIsGenerated={defaultAvatarIsGenerated}
              email={email}
              url={photoUrl}
              size={48}
            />
          ),
        })}
      </AvatarContainer>
    );
  };

  renderContent = () => {
    const { person, renderProfileCard } = this.props;
    const { email, fullName } = person;
    const text = fullName || email;
    const explainer = this.getExplainer();
    return (
      <ContentContainer>
        <ContentTextContainer clamp={2}>
          {renderProfileCard({
            person,
            anchor: text,
          })}
        </ContentTextContainer>
        <ContentExplainerContainer>{explainer}</ContentExplainerContainer>
      </ContentContainer>
    );
  };

  renderActions = () => (
    <ActionsContainer>
      <PopoverMenu
        isOpen={this.state.isDropdownOpen}
        onOpen={() => this.setState({ isDropdownOpen: true })}
        onClose={() => this.setState({ isDropdownOpen: false })}
        triggerComponent={
          <IconNew inverted={this.state.isDropdownOpen} name="meatballs" />
        }
        position="right"
      >
        {this.props.renderMenuList()}
      </PopoverMenu>
    </ActionsContainer>
  );

  render = () => (
    <Root>
      {this.renderAvatar()}
      {this.renderContent()}
      {this.renderActions()}
    </Root>
  );
}

ListItem.propTypes = {
  person: personPropType.isRequired, // eslint-disable-line
  activitySummary: Activity.isRequired, // eslint-disable-line
  defaultAvatarIsGenerated: PropTypes.bool,
  renderMenuList: PropTypes.func.isRequired,
  renderProfileCard: PropTypes.func.isRequired,
};

export default ListItem;
