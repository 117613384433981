import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@matterapp/matter-theme';
import Container from '../Container';
import LabelContainer from '../LabelContainer';
import Heart from './Heart';

const ActionItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Thanks = ({ isActive, onClick, disabled }) => (
  <Container
    backgroundColor={theme.colors.reds[5]}
    onClick={onClick}
    disabled={disabled}
  >
    <ActionItemContainer role="checkbox" aria-checked={isActive} tabIndex={-1}>
      <Heart isActive={isActive} />
      <LabelContainer>{isActive ? 'Thanked!' : 'Thank'}</LabelContainer>
    </ActionItemContainer>
  </Container>
);

Thanks.defaultProps = {
  disabled: false,
  onClick: undefined,
};

Thanks.propTypes = {
  isActive: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Thanks;
