import React from 'react';
import Base from './Base';
import {
  WorkspaceBackground,
  WorkspaceBackgroundContainer,
} from './styles';

const Workspace = (props) => {
  return (
    <Base
      {...props}
      background={
        <WorkspaceBackgroundContainer>
          <WorkspaceBackground />
        </WorkspaceBackgroundContainer>
      }
    />
  );
};

export default Workspace;
