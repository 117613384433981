import React from 'react';
import Animation from '../../Animation';
import Image from './Image';
import * as C from './classNames';

export default class ThankYou extends Animation {
  baseClass = C.BASE;

  moveBackground = (initialize) => {
    const tl = this.getDefaultTimeline({ duration: 0.3 });
    const DASHES = this.getElement(C.DASHES);
    const CIRCLE_TOP = this.getElement(C.CIRCLE.TOP);
    const CIRCLE_LEFT_1 = this.getElement(C.CIRCLE.LEFT_1);
    const CIRCLE_LEFT_2 = this.getElement(C.CIRCLE.LEFT_2);
    const CIRCLE_RIGHT = this.getElement(C.CIRCLE.RIGHT);
    const RING_TOP = this.getElement(C.RING.TOP);
    const RING_BOTTOM = this.getElement(C.RING.BOTTOM);
    const RING_RIGHT = this.getElement(C.RING.RIGHT);
    const PLUS = this.getElement(C.PLUS);

    const initialProps = {
      rotate: 0,
      opacity: 0,
      duration: initialize ? 0 : 0.2,
      transformOrigin: 'center',
    };
    const hideProps = { opacity: 0, scale: 0.8, duration: 0 };
    const resetProps = { x: 0, y: 0, scale: 1, rotate: 0, opacity: 1 };

    tl.to(DASHES, initialProps, '<');
    tl.to(CIRCLE_TOP, initialProps, '<');
    tl.to(CIRCLE_LEFT_1, initialProps, '<');
    tl.to(CIRCLE_LEFT_2, initialProps, '<');
    tl.to(CIRCLE_RIGHT, initialProps, '<');
    tl.to(RING_TOP, initialProps, '<');
    tl.to(RING_BOTTOM, initialProps, '<');
    tl.to(RING_RIGHT, initialProps, '<');
    tl.to(PLUS, initialProps, '<');

    tl.to(DASHES, { x: 8, y: 16, ...hideProps }, '>');
    tl.to(CIRCLE_TOP, { x: 10, y: 38, ...hideProps }, '<');
    tl.to(CIRCLE_LEFT_1, { x: 60, y: 30, ...hideProps }, '<');
    tl.to(CIRCLE_LEFT_2, { x: 60, y: 20, ...hideProps }, '<');
    tl.to(CIRCLE_RIGHT, { x: -60, y: 30, ...hideProps }, '<');
    tl.to(RING_TOP, { x: 30, y: 50, ...hideProps }, '<');
    tl.to(RING_BOTTOM, { x: 40, y: -20, ...hideProps }, '<');
    tl.to(RING_RIGHT, { x: -60, y: -30, ...hideProps }, '<');
    tl.to(PLUS, { x: -20, y: 20, rotate: 360, ...hideProps }, '<');

    if (!initialize) {
      tl.to(DASHES, resetProps, '>0.4');
      tl.to(CIRCLE_TOP, resetProps, '<');
      tl.to(CIRCLE_LEFT_1, resetProps, '<');
      tl.to(CIRCLE_LEFT_2, resetProps, '<');
      tl.to(CIRCLE_RIGHT, resetProps, '<');
      tl.to(RING_TOP, resetProps, '<');
      tl.to(RING_BOTTOM, resetProps, '<');
      tl.to(RING_RIGHT, resetProps, '<');
      tl.to(PLUS, resetProps, '<');
    }

    return tl;
  };

  tipHat = () => {
    const tl = this.getDefaultTimeline({ duration: 0.3 });
    const HAT = this.getElement(C.HAND.GROUP);
    const FACE = this.getElement(C.FACE.GROUP);
    const EYES = this.getElement(C.FACE.EYES);
    const MOUTH = this.getElement(C.FACE.MOUTH);

    const getFaceProps = (scaleY = 1) => ({ scaleY, duration: 0.08 });

    tl.to(HAT, { x: 0, y: 0, rotate: 0, duration: 0 }, 'start-hat');
    tl.to(HAT, { x: -14, y: 12, rotate: -20 }, '<');
    tl.to(FACE, { y: 4 }, '<');
    tl.to(EYES, getFaceProps(0.6), '>');
    tl.to(MOUTH, getFaceProps(0.8), '<');
    tl.to(EYES, getFaceProps(), '>');
    tl.to(MOUTH, getFaceProps(), '<');
    tl.to(HAT, { x: 0, y: 0, rotate: 0 }, '>0.1');
    tl.to(FACE, { y: 0 }, '<');

    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = this.getBaseTimeline(initialize);
    if (!initialize) {
      tl.add(this.tipHat(), '<');
    }
    tl.add(this.moveBackground(initialize), '<');

    this.tl = tl;
    return this.tl;
  };

  render() {
    return <Image className={C.BASE} {...this.props} />;
  }
}
