import React from 'react';
import PropTypes from 'prop-types';

import theme from '@matterapp/matter-theme';

const SolidPath = ({ fill }) => (
  <path d="M 10.1585 1.74292C 10.4848 2.06927 10.7653 2.42409 11 2.7994C 11.2347 2.42409 11.5152 2.06927 11.8416 1.74293C 14.1655 -0.580968 17.9332 -0.580958 20.2571 1.74293C 22.581 4.06681 22.581 7.83456 20.2571 10.1585L 12.3602 18.0554C 11.609 18.8066 10.391 18.8066 9.63984 18.0554L 1.74292 10.1585C -0.580968 7.83456 -0.580979 4.06682 1.74292 1.74292C 4.06682 -0.580979 7.83456 -0.580968 10.1585 1.74292Z" transform="translate(1 3)" fill={fill} />
);

SolidPath.propTypes = {
  fill: PropTypes.string.isRequired,
};

const OutlinePath = ({ fill }) => (
  <path fillRule="evenodd" clipRule="evenodd" d="M 11.4239 4.9506C 11.228 5.2638 10.7719 5.26379 10.576 4.95058L 9.72817 3.59466C 9.55321 3.31486 9.3434 3.04919 9.09779 2.80358C 7.35969 1.06548 4.54169 1.06547 2.80358 2.80358C 1.06547 4.54169 1.06548 7.35969 2.80358 9.09779L 10.7005 16.9947C 10.8659 17.1601 11.1341 17.1601 11.2995 16.9947L 19.1964 9.0978C 20.9345 7.35969 20.9345 4.54169 19.1964 2.80359C 17.4583 1.06549 14.6403 1.06548 12.9022 2.80359C 12.6566 3.04919 12.4468 3.31487 12.2718 3.59471L 11.4239 4.9506ZM 10.0041 1.59394C 10.0562 1.64249 10.1077 1.69215 10.1585 1.74292C 10.4848 2.06927 10.7653 2.42409 11 2.7994C 11.2347 2.42409 11.5152 2.06927 11.8416 1.74293C 11.8923 1.69216 11.9438 1.6425 11.9959 1.59394C 14.3294 -0.579858 17.984 -0.530186 20.2571 1.74293C 22.581 4.06681 22.581 7.83456 20.2571 10.1585L 12.3602 18.0554C 11.609 18.8066 10.391 18.8066 9.63984 18.0554L 1.74292 10.1585C -0.580968 7.83456 -0.580979 4.06682 1.74292 1.74292C 4.01605 -0.530208 7.67062 -0.579859 10.0041 1.59394Z" transform="translate(1 3)" fill={fill} />
);

OutlinePath.propTypes = {
  fill: PropTypes.string.isRequired,
};

const Icon = ({ fill, outline }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    { outline
      ? <OutlinePath fill={fill} />
      : <SolidPath fill={fill} />
    }
  </svg>
);


Icon.defaultProps = {
  fill: theme.colors.reds[50],
  outline: false,
};

Icon.propTypes = {
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default Icon;
