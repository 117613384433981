import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5 26h-9.688L8.5 31v-5C5.475 26 3 23.525 3 20.5v-11C3 6.475 5.475 4 8.5 4h15C26.525 4 29 6.475 29 9.5v11c0 3.025-2.475 5.5-5.5 5.5zm-12-14a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM16 22a7 7 0 0 1-6.705-5h13.41A7 7 0 0 1 16 22zm6.5-8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
