import RecurringList from './RecurringList';
import { 
  InfoModal,
  RequestFeedbackModal,
  GiveFeedbackModal,
  GiveKudosModal,
  RecurringModal,
} from './modals';

export const Advisors = {
  RecurringList,
  InfoModal,
  RequestFeedbackModal,
  GiveFeedbackModal,
  GiveKudosModal,
  RecurringModal,
};
