import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from '../../../TimeAgo';
import Thanks from './Thanks';
import WithAddRemove from '../../../Comments/WithAddRemove';
import { COMMENT_PROP_TYPE, PERSON_PROP_TYPE } from '../../../Comments/consts';
import { useFeedItemComment } from './hooks';
import CommentsButton from './CommentsButton';
import {
  COMMENTS_HEADER,
  THANKS_PROP_TYPE,
  THANKS_DEFAULT_PROPS,
} from './consts';
import {
  ActionsContainer,
  Container,
  CloseButton,
  Header,
  HeaderContainer,
  MainContainer,
  MiddleActionsContainer,
  ToolbarContainer,
  TimeContainer,
} from './styles';

const Footer = (props) => {
  const { TooltipComponent } = props;
  const {
    isOpen,
    eventTime,
    hasComments,
    autoFocus,
    numberOfComments,
    toggleShowComments,
    onClickClose,
    mainContainerProps,
    toolbarProps,
    thanksProps,
    commentsProps,
  } = useFeedItemComment(props);

  return (
    <MainContainer {...mainContainerProps}>
      <ToolbarContainer {...toolbarProps}>
        <TimeContainer>
          <TimeAgo date={eventTime} />
        </TimeContainer>
        <MiddleActionsContainer>
          <Thanks {...thanksProps} />
        </MiddleActionsContainer>
        <ActionsContainer>
          <CommentsButton
            TooltipComponent={TooltipComponent}
            isActive={isOpen}
            onClick={toggleShowComments}
            numberOfComments={numberOfComments}
          />
        </ActionsContainer>
      </ToolbarContainer>
      {isOpen && (
        <Container hasComments={hasComments}>
          <WithAddRemove
            {...commentsProps}
            header={
              !!hasComments ? (
                <HeaderContainer>
                  <Header>{COMMENTS_HEADER}</Header>
                  <CloseButton onClick={onClickClose} />
                </HeaderContainer>
              ) : null
            }
            autoFocus={autoFocus}
          />
        </Container>
      )}
    </MainContainer>
  );
};

Footer.defaultProps = {
  currentUser: {},
  comments: [],
  onClickDeleteComment: () => null,
  onSendComment: () => null,
  thanks: THANKS_DEFAULT_PROPS,
  autoUpdateComments: true,
};

Footer.propTypes = {
  /** If appending footer. Removes top padding. */
  append: PropTypes.bool,
  /** The current user. */
  currentUser: PERSON_PROP_TYPE,
  /** List of comments to show, */
  comments: PropTypes.arrayOf(COMMENT_PROP_TYPE),
  /** ID of the FeedItem. */
  id: PropTypes.string,
  /** Callback when comment is deleted. */
  onClickDeleteComment: PropTypes.func,
  /** Callback to send comment. */
  onSendComment: PropTypes.func,
  /** Thanks props. */
  thanks: THANKS_PROP_TYPE,
};

export default Footer;
