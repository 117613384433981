import React from 'react';
import styled from 'styled-components';
import { 
  Button
} from '@matterapp/matter-ui'; 
import { Paper } from '@mui/material';
import Gauge from './Gauge';

const LinkButton = styled(Button.Simple.Minimal)
  .attrs({
    size: 'XS',
  })`
  min-width: 100px;
  width: 100%;
  z-index: 0;
  margin-top: 15px;
`;

const SkillGauge = styled(Gauge)({
  flex: 1
});

const UnitContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  userSelect: 'none',
  padding: theme.spacing(2),
  borderRadius: '12px',
  textAlign: 'center',
  width: '176px',
  minHeight: '184px'
}));

export default function ProgressUnit({
  name,
  value,
  onButtonClick,
  buttonText,
  showScore,
  customColor,
  onClick = () => {},
  displayScoreFn,
  badge,
  menuContent,
  sx,
  showProvidedScore,
  maxRating = 10,
  tooltipContent
}) {

  return (
    <UnitContainer elevation={1} className='progress-unit-container' sx={{ ...sx }}>
      <SkillGauge
        onClickSkill={onClick}
        showScore={showScore}
        showProvidedScore={showProvidedScore}
        alwaysShowBar
        size="medium"
        skill={{
          name,
          colorPalette: customColor
        }}
        tooltipContent={tooltipContent}
        rating={value}
        maxRating={maxRating}
        topRightMenuContent={menuContent}
        displayScoreFn={displayScoreFn}
        badge={badge}
        allowZeroRating
      />
      {onButtonClick ? (
        <LinkButton onClick={onButtonClick}>
          {buttonText}
        </LinkButton>
      ) : null}
    </UnitContainer>
  );
}
