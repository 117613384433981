import { useEffect, useState } from 'react';

export const ACCEPT_TYPES = {
  AUDIO: {
    ANY: 'audio/*',
  },
  FILES: {
    ANY: 'application/*, audio/*, image/*, video/*',
  },
  IMAGE: {
    ANY: 'image/*',
    JPEG: 'image/jpeg',
    PNG: 'image/png',
  },
  VIDEO: {
    ANY: 'video/*',
  },
};

export const IMAGE_HEADER = 'Upload Image';
export const IMAGE_LOADING_HEADER = 'Uploading Image';
export const IMAGE_SUB_HEADER = 'Min dimensions: 176×176px';

export const useFiles = (props) => {
  const { acceptedFiles, onChange, onRemove, urls = [] } = props;
  const [files, setFiles] = useState([]);
  const [imageUrls, setUrls] = useState(urls || []);
  const [imageUrlsSet, setImageUrlsSet] = useState(!!imageUrls.length);

  useEffect(() => {
    setFiles(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (!imageUrlsSet) {
      setImageUrlsSet(true);
      setUrls(urls);
    }
  }, [urls]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(files);
    }
  }, [files]);

  return {
    files,
    hasFiles: !!files.length || !!imageUrls.length,
    imageUrls,
    setFiles,
    clearFiles: (e) => {
      e.stopPropagation();
      onRemove(e, props);
      setFiles([]);
      setUrls([]);
    },
  };
};
