import React from 'react';
import { SVG, Path } from './consts';

const Profile = (props) => (
  <SVG {...props}>
    <Path
      d="M14.9,8.2c-1.1,0.6-1.8,1.6-2.2,2.7l-0.2-2.2l-0.1-1c-0.1-0.2-0.1-0.4-0.2-0.7c-0.5-1.4-1.6-2.6-3-3.3
        C7.2,2.8,5,3.1,3.3,4.3c0.2,2.1,1.4,4,3.4,4.9C8.4,10,10.4,9.9,12,9l-1.4,12.8H14l-1-9.7c1.2,0.7,2.8,0.7,4.1,0
        c1.4-0.8,2.3-2.2,2.3-3.8C18.1,7.5,16.4,7.4,14.9,8.2z"
    />
  </SVG>
);

export default Profile;
