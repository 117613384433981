import React from 'react';
import PropTypes from 'prop-types';

import {
  IconQualityAmbitious,
  IconQualityArticulate,
  IconQualityAssertive,
  IconQualityCharismatic,
  IconQualityCollaborative,
  IconQualityConfident,
  IconQualityCurious,
  IconQualityDependable,
  IconQualityEnthusiastic,
  IconQualityEntrepreneurial,
  IconQualityFlexible,
  IconQualityFriendly,
  IconQualityGeneric,
  IconQualityHumble,
  IconQualityImpatient,
  IconQualityInnovative,
  IconQualityKind,
  IconQualityLogical,
  IconQualityObservant,
  IconQualityOptimistic,
  IconQualityOrganized,
  IconQualityPassionate,
  IconQualityPatient,
  IconQualityPositive,
  IconQualityRespectful,
  IconQualitySelfless,
} from '../Icon';

const icons = {
  Ambitious: IconQualityAmbitious,
  Articulate: IconQualityArticulate,
  Assertive: IconQualityAssertive,
  Charismatic: IconQualityCharismatic,
  Collaborative: IconQualityCollaborative,
  Confident: IconQualityConfident,
  Curious: IconQualityCurious,
  Dependable: IconQualityDependable,
  Enthusiastic: IconQualityEnthusiastic,
  Entrepreneurial: IconQualityEntrepreneurial,
  Flexible: IconQualityFlexible,
  Friendly: IconQualityFriendly,
  Generic: IconQualityGeneric,
  Humble: IconQualityHumble,
  Impatient: IconQualityImpatient,
  Innovative: IconQualityInnovative,
  Kind: IconQualityKind,
  Logical: IconQualityLogical,
  Observant: IconQualityObservant,
  Optimistic: IconQualityOptimistic,
  Organized: IconQualityOrganized,
  Passionate: IconQualityPassionate,
  Patient: IconQualityPatient,
  Positive: IconQualityPositive,
  Respectful: IconQualityRespectful,
  Selfless: IconQualitySelfless,
};

const QualityIcon = ({ name, fillColor }) => {
  const SelectedIcon = icons[name] || IconQualityGeneric;
  return (
    <SelectedIcon
      fill={fillColor}
    />
  );
};

QualityIcon.icons = icons;

QualityIcon.propTypes = {
  name: PropTypes.string.isRequired,
  fillColor: PropTypes.string,
};

QualityIcon.defaultProps = {
  fillColor: 'black',
};

export default QualityIcon;
