import React from 'react';
import PropTypes from 'prop-types';
import { propType } from 'graphql-anywhere';
import { SKILL_DETAILS_QUERY } from 'graphql-queries/queries';
import { Resources } from '@matterapp/routing';
import ActionModals from '../ActionModals';
import {
  useActionModals,
  useActionModalSelector,
  useRecurringModal,
} from '../../hooks/WorkspaceActionModalHooks';
import {
  Container,
  SideColumnCountContainer,
  SideColumnCountRow,
  SideColumnCountKey,
  SideColumnCountValue,
  AvatarContainer,
} from './Styles';

const WorkspacesSideColumn = (props) => {
  const {
    history,
    workspace,
    workspaceMembers,
    currentUser,
    showList,
    pickedSkillsList,
    selectedPeer,
    initUserSkillsList,
    setDefaultMessage,
    onChangeSelectedAdvisor,
    resetFeed,
    tenant
  } = props;
  const { photoUrl, name } = tenant || {};
  const { activeMembersCount } = workspace;
  const modalProps = useActionModals();
  const actionModalSelectorProps = useActionModalSelector({
    ...modalProps,
    pickedSkillsList,
    selectedPeer,
    initUserSkillsList,
    setDefaultMessage,
    currentUser
  });
  const recurringModalProps = useRecurringModal({
    workspaceId: workspace.id,
    acceptedMembers: workspaceMembers,
    onChangeSelectedAdvisor,
    currentUser
  });

  const openWorkspaceMembersTab = () => {
    history.push(
      Resources.workspacesMembers.path({ workspaceId: workspace.id })
    );
  };

  if (!workspaceMembers || !currentUser || !workspace?.id) {
    return null;
  }

  return (
    <Container
      title={name || ''}
      avatarProps={{ url: photoUrl || Container.getIconUrl('workspacesPageIcon') }}
      useCustomTopRightMenu
    >
      <AvatarContainer>
        <SideColumnCountContainer>
          <SideColumnCountRow
            showTopLine
            onClick={workspace.isExpired ? null : openWorkspaceMembersTab}
          >
            <SideColumnCountKey>Channel Members</SideColumnCountKey>
            <SideColumnCountValue>{activeMembersCount}</SideColumnCountValue>
          </SideColumnCountRow>
        </SideColumnCountContainer>
      </AvatarContainer>
      {showList && 
      <>
        <ActionModals 
          {...props}
          {...modalProps}
          {...recurringModalProps}
          resetFeed={resetFeed}
          workspace={workspace}
          currentUser={currentUser}
          tenant={tenant}
          actionModalSelector={actionModalSelectorProps?.actionModalSelector}
          handleSelectMember={recurringModalProps.handleSelectMember}
        />
      </>}
    </Container>
  );
};

WorkspacesSideColumn.propTypes = {
  workspace: PropTypes.shape({
    iconUrl: PropTypes.string,
    id: PropTypes.string,
    isAdmin: PropTypes.bool,
    isOwner: PropTypes.bool,
    isExpired: PropTypes.bool,
    name: PropTypes.string,
    slackWorkspace: PropTypes.shape({
      id: PropTypes.string
    }),
    activeMembersCount: PropTypes.number,
  }),
  selectedPeer: PropTypes.shape({
    userId: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    photoUrl: PropTypes.string,
    isMatterUser: PropTypes.bool,
    recurringFeedback: PropTypes.shape({
      id: PropTypes.string,
      recurrence: PropTypes.shape({
        frequency: PropTypes.string,
      })
    })
  }),
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    pickedSkillDetails: PropTypes.arrayOf(PropTypes.object),
  }),
  workspaceMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  showList: PropTypes.bool,
  onChangeSelectedAdvisor: PropTypes.func,
  pickedSkillsList: propType(SKILL_DETAILS_QUERY).isRequired,
  initUserSkillsList: PropTypes.func,
  setDefaultMessage: PropTypes.func,
  resetFeed: PropTypes.func,
};

WorkspacesSideColumn.defaultProps = {
  showList: true,
};

export default WorkspacesSideColumn;