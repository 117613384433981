import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Button, ModalNew as Modal } from '@matterapp/matter-ui';

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  padding: ${theme.spacing.single};
  ${theme.media.S`
    padding: ${theme.spacing.singleAndHalf};
  `}
`;

export const CancelButton = styled(Button.Simple)`
  flex: 1 1 100%;
  padding: ${theme.spacing.half} 0;
  ${({ isHidden }) => isHidden && `
    display: none;
  `}
`;

export const ModalHeader = styled(Modal.Header)`
  text-align: center;
`;

export const ModalBody = styled(Modal.Body)`
  div:nth-child(4) {
    border: none !important;
    padding-bottom: 0;
  }
`;