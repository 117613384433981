import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.655 2.5H8.345c-.51 0-.965.315-1.14.79L1.5 17.5v8.235c0 .7.565 1.265 1.265 1.265h26.47c.7 0 1.265-.565 1.265-1.265V17.5L24.795 3.29a1.212 1.212 0 0 0-1.14-.79zm-14.69 2h14.07l4.56 13H23.04c-.815 0-1.51.56-1.71 1.35A5.499 5.499 0 0 1 16 23a5.509 5.509 0 0 1-5.335-4.15c-.2-.79-.895-1.35-1.71-1.35H4.4l4.565-13z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
