import React from 'react';
import PropTypes from 'prop-types';
import Skill from '../../../Skill';
import { getSkillBorderColor, getSkillColorPaletteFromSkill } from '../../../../common/libs/get-skill-details';
import {
  AbilityButton,
  AbilityModal,
  ModalHeaderContainer,
  ModalBodyContainer,
  SkillName,
  AbilityName,
  AbilitySubtitle,
  AbilityDescription,
  AbilityButtonContainer,
  LearnMoreButton,
  CloseButton,
} from './ActionItemsStyles';

class ActionItems extends React.PureComponent {
  static propTypes = {
    skill: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      abilities: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        })
      ),
    }).isRequired,
    pickedAbilities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ).isRequired,
    bkgColor: PropTypes.string.isRequired,
    onClickLearnMore: PropTypes.func.isRequired,
    onClickActionItem: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      currentAbility: null,
    };
  }

  handleOpenModal(ability) {
    this.setState({
      isOpen: true,
      currentAbility: ability,
    });
  }

  handleCloseModal() {
    this.setState({
      isOpen: false,
    });
  }

  handleClickLearnMore() {
    const { skill, onClickLearnMore } = this.props;
    onClickLearnMore(skill);
    this.handleCloseModal();
  }

  renderActionItemButtons() {
    const { skill, pickedAbilities } = this.props;
    const sortedAbilitiesStorage = {};

    // This is to sort the abilities name by shortest to longest length for UI purposes
    pickedAbilities.map((ability) => {
      sortedAbilitiesStorage[ability.name] = ability;
    });

    const sortedAbilities = Object.keys(sortedAbilitiesStorage)
      .sort((a, b) => a.length - b.length)
      .map((ability) => sortedAbilitiesStorage[ability]);

    const borderColor = getSkillBorderColor(skill);
    const color = getSkillColorPaletteFromSkill(skill);

    return sortedAbilities.map((ability) => (
      <AbilityButton
        key={ability.id}
        size={'S'}
        type={'cta'}
        onClick={() => this.handleOpenModal(ability)}
        data-rc={'ability-button'}
        colorState={color}
        borderColor={borderColor}
      >
        {ability.name}
      </AbilityButton>
    ));
  }

  renderAbilityModal() {
    const { bkgColor, onClickActionItem } = this.props;
    const { isOpen } = this.state;

    if (isOpen) {
      // this function handles mixpanel tracking
      onClickActionItem();
      return (
        <AbilityModal
          isOpen={this.state.isOpen}
          onCloseModal={this.handleCloseModal.bind(this)}
          backgroundColor={bkgColor}
          hideBackground={true}
          title={this.renderAbilityModalHeader()}
          size={'narrow'}
          data-rc={'ability-modal'}
        >
          {this.renderAbilityModalBody()}
        </AbilityModal>
      );
    }
    return null;
  }

  renderAbilityModalHeader() {
    const { skill } = this.props;
    const skillName = skill.name.toLowerCase();
    const ability = this.state.currentAbility;

    const getAbilityLength = () => {
      if (skill.abilities.length > 1) {
        return ` ${skill.abilities.length} abilities`;
      }
      return '1 ability';
    };

    return (
      <ModalHeaderContainer>
        <Skill.Badge skill={skill} size={'tiny'} marginBottom={5} />
        <SkillName>{skill.name}</SkillName>
        <AbilityName>
          {ability.name}
        </AbilityName>
        <AbilitySubtitle>
          {`1 out of ${getAbilityLength()} that lead to the mastery of ${skillName}`}
        </AbilitySubtitle>
      </ModalHeaderContainer>
    );
  }

  renderAbilityModalBody() {
    const ability = this.state.currentAbility;
    return (
      <ModalBodyContainer>
        <AbilityDescription>
          {ability.description}
        </AbilityDescription>
        <LearnMoreButton
          primary
          extraWide
          size={'S'}
          icon={'externalLink'}
          fill='white'
          onClick={this.handleClickLearnMore.bind(this)}
          data-rc={'ability-modal-learn-more-button'}
        >
          {'Learn more '}
        </LearnMoreButton>
        <CloseButton
          size={'M'}
          onClick={this.handleCloseModal.bind(this)}
          data-rc={'ability-modal-close-button'}
        >
          {'Close'}
        </CloseButton>
      </ModalBodyContainer>
    );
  }

  render() {
    return (
      <>
        <AbilityButtonContainer>
          {this.renderActionItemButtons()}
        </AbilityButtonContainer>
        {this.renderAbilityModal()}
      </>
    );
  }
}

export default ActionItems;
