"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.inputs = void 0;

var _colors = require("../colors");

const inputs = {
  borderDefault: '1px solid',
  borderRadius: '4px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: _colors.colors.blacks[40],
  borderActiveColor: _colors.colors.blue,
  placeholderColor: _colors.colors.blacks[40],
  errorColor: _colors.colors.red,
  errorTextColor: _colors.colors.reds[40],
  errorBackgroundColor: _colors.colors.reds[5],
  errorBorderColor: _colors.colors.reds[40],
  errorPlaceholderColor: _colors.colors.reds[20],
  disabledTextColor: _colors.colors.black[50],
  disabledBackgroundColor: _colors.colors.blacks[5],
  disabledPlaceholderColor: _colors.colors.blacks[30]
};
exports.inputs = inputs;
var _default = inputs;
exports.default = _default;