import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { colors, font, lineHeight, spacing, media } from '@matterapp/matter-theme';
import { Dropdown, IconNew as Icon } from '@matterapp/matter-ui';

const AnalyticsFilterIcon = styled(Icon).attrs({ name: 'analyticsFilter' })``;

const FilterCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: ${spacing.singleAndHalf};
  border-top: 1px solid ${colors.blacks[10]};
  border-bottom: 1px solid ${colors.blacks[10]};
  margin-bottom: ${spacing.singleAndHalf};

  ${media.S`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}

  ${media.M`
    height: 104px;
    border-top: none;
    border-radius: ${theme.borderRadius.L};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15), 0px 2px 14px rgba(0, 0, 0, 0.1);
  `}

  ${media.L`
    ${({ fullWidth }) => fullWidth ? `
      margin: 24px;
    ` : 
  'max-width: 1000px;'};
  `}

  ${media.XXL`
    margin: 0;
    margin-bottom: ${spacing.singleAndHalf};
    border-radius: ${theme.borderRadius.L};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15), 0px 2px 14px rgba(0, 0, 0, 0.1);
  `}
`;

const FilterTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.singleAndHalf};

  ${media.S`
    margin-right: ${spacing.single};
    margin-bottom: 0;
  `}
`;

const FilterText = styled.div`
  display: none;
  font-size: ${font.size.S};
  line-height: ${lineHeight.M};
  margin-left: ${spacing.half};

  ${media.S`
  display: block;
  margin-right: ${spacing.single};
  margin-bottom: 0;
`}
`;

const FilterTextWithNoDates = styled(FilterText)({
  display: 'block'
});

const FilterDropdown = styled(Dropdown).attrs(() => ({
  color: Dropdown.colors.ALT,
  size: Dropdown.sizes.M,
}))`
  width: 100%;
  
  ${media.M`
    display: ${({ showDropdownOnlyOnMobile }) => showDropdownOnlyOnMobile ? 'none;' : 'flex;'}
    max-width: 190px;
    margin-top: 0;
  `}
`;

const DropdownContainer = styled.div`
  display: flex;

  & [data-role="dropdown"]:nth-child(2) {
    margin-left: ${spacing.half};
  }

  & [data-role="dropdown"]:only-child {
    width: 100%;
  }

  ${media.S`
    justify-content: space-between;
  `}
`;

const FilterDates = styled.b`
  position: absolute;
  right: ${spacing.singleAndHalf};
  margin-right: 0;
  margin-left: auto;

  ${media.S`
    position: static;
    margin-right: ${spacing.double};
  `}
`;

const FilterBar = ({
  items,
  value,
  onChange,
  style,
  customComponent,
  showDropdownOnlyOnMobile,
  fullWidth,
  listsOfItems,
  startDate,
  endDate,
  filterName = 'Filters'
}) => {
  const FilterTextComponent = startDate && endDate ? FilterText : FilterTextWithNoDates;

  return (
    <FilterCard style={style} fullWidth={fullWidth}>
      <FilterTextContainer>
        <AnalyticsFilterIcon/>
        <FilterTextComponent>{filterName}</FilterTextComponent>
      </FilterTextContainer>
      {startDate && endDate ? (<FilterDates>{startDate.format('MMM. D, YYYY')} → {endDate.format('MMM. D, YYYY')}</FilterDates>) : null}
      <DropdownContainer>
        {listsOfItems?.length ?
          listsOfItems.map((list, index) => {
            const { items, value, onChange } = list;
            return (
              <FilterDropdown
                key={`filter-bar-dropdown-${index}`}
                value={value}
                items={items}
                onChange={onChange}
                showSelectedItemInMenu
                showDropdownOnlyOnMobile={showDropdownOnlyOnMobile}
              />
            );
          }) : 
          <FilterDropdown
            value={value}
            items={items}
            onChange={onChange}
            showSelectedItemInMenu
            showDropdownOnlyOnMobile={showDropdownOnlyOnMobile}
          />
        }
      </DropdownContainer>
      {customComponent}
    </FilterCard>
  );
};

FilterBar.defaultProps = {
  showDropdownOnlyOnMobile: false
};

FilterBar.propTypes = {
  listsOfItems: PropTypes.array,
  items: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  showDropdownOnlyOnMobile: PropTypes.bool,
  customComponent: PropTypes.node,
  fullWidth: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

export default FilterBar;