import React from 'react';
import { Typography, Box } from '@mui/material';
import { Advisors } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { ACTIONS } from './reducer';
import { getRepeatOptions } from './consts';

const WeekdaySelectorContainer = styled(Box)({
  paddingLeft: 0,
  '> div': {
    width: '100%'
  }
});

function lowercaseFirstLetter(string) {
  if (!string) {
    return string;
  }
  
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export default function MonthDaySelector({ state, dispatch }) {
  const { frequencyConfig, frequency, sendNow, sendDate } = state;
  const { sendOnDay, repeatValue } = frequencyConfig;

  if (!['monthly', 'yearly'].includes(frequency)) {
    return null;
  }

  let occurence = '';

  if (frequency === 'monthly') {
    occurence = 'Occurs';

    if (repeatValue === 1) {
      occurence += ' every month';
    } else if (repeatValue === 2) {
      occurence += ' every other month';
    } else {
      occurence += ` every ${repeatValue} months`;
    }
    
  } else {
    occurence = 'Occurs every year';
  }

  occurence += ` ${lowercaseFirstLetter(sendOnDay?.label)}`;

  return (
    <>
      <WeekdaySelectorContainer>
        <Advisors.RecurringModal.Dropdown
          style={{ width: '100%' }}
          selectedOption={sendOnDay}
          handleChangeSelection={(choice) => {
            dispatch({ type: ACTIONS.UPDATE_SEND_ON_DAY, payload: choice });
          }}
          options={getRepeatOptions(frequency, sendNow ? null: sendDate)}
        />
      </WeekdaySelectorContainer>
      <Box 
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: 3
        }}
      >
        <Typography variant='body1'>{occurence}.</Typography>
      </Box>
    </>
  );
}
