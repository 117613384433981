import React from 'react';
import { Modal } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const StyledModalBody = styled(Modal.Body)(({ theme }) => ({
  padding: theme.spacing(3)
}));

const StyledModal = styled(Modal)({
  zIndex: 120
});

export default function LeaveModal(props) {
  const { onClose, isOpen, onResume } = props;

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      style={{ zIndex: 1000}}
    >
      <Modal.Header
        header='Are you sure you want to leave?'
      />
      <StyledModalBody>
        <Typography variant="body1">
          It looks like you’re still working on this. You will lose the info you’ve entered so far if you leave now.
        </Typography>
      </StyledModalBody>
      <Modal.Footer.WithCancelDelete
        deleteLabel='Leave'
        cancelLabel='Keep Editing'
        onClickCancel={onResume}
        onClickDelete={onClose}
      />
    </StyledModal>
  );
};

