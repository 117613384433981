import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { ButtonBase } from '../Clickable/Clickable';
import theme from '@matterapp/matter-theme';
import styled from 'styled-components';
import InlineHover from './InlineHover';

const DivWithKeyframes = styled.div`
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
`;

const divStyle = ({
  position,
  dark,
  maxWidth,
  minWidth,
  textAlign,
  pointerEvents = 'all',
  styleObj = {},
}) => {
  const styles = {
    backgroundColor: dark ? theme.colors.blacks[90] : theme.colors.white,
    color: dark ? theme.colors.white : theme.colors.black,
    borderRadius: theme.borderRadius.L,
    minWidth: minWidth || '100px',
    maxWidth: maxWidth || '250px',
    overflow: 'hidden',
    pointerEvents,
    fontSize: theme.fontSize.base,
    textAlign: textAlign,
    zIndex: 1000,
    ...styleObj
  };
  if (position == 'top') {
    styles.boxShadow =
      '0px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.15)';
  }
  if (position == 'bottom') {
    styles.boxShadow =
      '0px 1px 1px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.08)';
  }
  return styles;
};

const ContentWrapper = styled.div`
  padding: ${theme.spacing.threeQuarters} ${theme.spacing.single};
`;

const Trigger = styled(ButtonBase)`
  display: flex;
  flex-grow: 0;
`;

const TooltipHeader = styled('div')`
  font-weight: ${theme.fontWeight.bold};
  padding: 0 ${theme.spacing.single};
  padding-top: ${theme.spacing.threeQuarters};
`;

const Tooltip = (props) => {
  const {
    arrowSize,
    content,
    header,
    distanceFromTrigger,
    position,
    dark,
    clickOnly,
    children,
    align,
    isOpen,
    handlePopupOpen,
    style = {},
    onClick,
    onClickOutside,
    TriggerComponent = Trigger,
    triggerProps,
  } = props;
  const useInternalState = isOpen === undefined;
  const [isOpenState, setOpenState] = useState(false);
  const isPopupOpen = useInternalState ? isOpenState : isOpen;
  const setPopupOpen = useInternalState ? setOpenState : handlePopupOpen;
  const positionInstructions = position;

  return (
    <Popover
      align={align}
      isOpen={isPopupOpen}
      positions={positionInstructions}
      padding={distanceFromTrigger}
      reposition={false}
      onClickOutside={(e) => {
        setPopupOpen(false);
        onClickOutside(e, props);
      }}
      containerStyle={{ zIndex: 1000, ...style }}
      content={({ position, childRect, popoverRect }) => {
        return (
          <DivWithKeyframes>
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowSize={arrowSize}
              arrowColor={dark ? theme.colors.blacks[90] : theme.colors.white}
            >
              <div style={divStyle({ position, dark, ...style, styleObj: style })}>
                {header ? (
                  <TooltipHeader>
                    {header}
                  </TooltipHeader>
                ) : null}
                {typeof content == 'string' ? (
                  <ContentWrapper>{content}</ContentWrapper>
                ) : (
                  content
                )}
              </div>
            </ArrowContainer>
          </DivWithKeyframes>
        );
      }}
    >
      {useInternalState ? (
        <TriggerComponent
          onMouseEnter={() => !clickOnly && setPopupOpen(true)}
          onMouseLeave={() => !clickOnly && setPopupOpen(false)}
          onClick={(e) => {
            if (clickOnly) {
              setPopupOpen(!isPopupOpen);
            }
            onClick(e, props);
          }}
          {...triggerProps}
        >
          {children}
        </TriggerComponent>
      ) : (
        <TriggerComponent {...triggerProps}>
          {children}
        </TriggerComponent>
      )}
    </Popover>
  );
};

Tooltip.defaultProps = {
  arrowSize: 12,
  dark: true,
  distanceFromTrigger: 4,
  canClickInside: false,
  content: '',
  position: ['top', 'bottom', 'left', 'right'],
  align: 'center',
  style: {},
  handlePopupOpen: () => {},
  onClick: () => {},
  onClickOutside: () => {},
  triggerProps: {}
};

Tooltip.Trigger = Trigger;

Tooltip.propTypes = {
  /** Size of the arrow in pixels. */
  arrowSize: PropTypes.number,
  /** If the tooltip is dark themed. */
  dark: PropTypes.bool,
  /** Distance in pixels from trigger to show tooltip. */
  distanceFromTrigger: PropTypes.number,
  /** Content to display in the tooltip. */
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  /** Position of tooltip. */
  position: PropTypes.arrayOf(PropTypes.string),
  /** Alignment of tooltip. */
  align: PropTypes.string,
  /** If popup is open. Note: If left undefined, component will use internal state to trigger. */
  isOpen: PropTypes.bool,
  /** Callback to open popup. */
  handlePopupOpen: PropTypes.func,
  /** Additional styles. */
  style: PropTypes.object,
  /** Callback if tooltip trigger is clicked. */
  onClick: PropTypes.func,
  /** Callback when click outdie of tooltip. */
  onClickOutside: PropTypes.func,
  /** Component to use as trigger. */
  TriggerComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.string,
  ]),
  /** Additional props passed to the trigger. */
  triggerProps: PropTypes.object,
  /** Optional header for tooltip that will be displayd in bold */
  header: PropTypes.string
};

Tooltip.InlineHover = InlineHover;

export default Tooltip;
