import React from 'react';
import { AppLayout } from 'modules';
import SendWirePage from './SendWirePage';

export default function RewardsFundSendWire() {
  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        <SendWirePage />
      }
    />
  );
};
