"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.sizes = void 0;
const sizes = {
  padding: {
    60: '24px',
    50: '20px',
    40: '16px',
    30: '12px',
    20: '8px'
  },
  borderRadius: {
    80: '12px',
    50: '4px'
  },
  fontSizes: {
    90: '44px',
    80: '36px',
    70: '32px',
    60: '24px',
    55: '22px',
    50: '17px',
    40: '14px'
  },
  lineHeights: {
    90: '48px',
    80: '40px',
    70: '36px',
    60: '32px',
    50: '24px',
    45: '17px',
    40: '16px'
  }
};
exports.sizes = sizes;
var _default = sizes;
exports.default = _default;