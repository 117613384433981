import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BUTTON_LABEL_AUTH_RESEND_VERIFICATION_CODE } from '../../../copy-consts';
import NextStepButton from 'components/molecules/NextStepButton';

const Button = styled(NextStepButton)`
  height: 36px;
`;

const ResendCodeButton = ({
  resendVerificationCode,
  isResendingVerificationCode,
}) => (
  <Button
    onClick={resendVerificationCode}
    submitting={isResendingVerificationCode}
    pristine={false}
    link
  >
    {BUTTON_LABEL_AUTH_RESEND_VERIFICATION_CODE}
  </Button>
);

ResendCodeButton.propTypes = {
  resendVerificationCode: PropTypes.func.isRequired,
  isResendingVerificationCode: PropTypes.bool.isRequired,
};

export default ResendCodeButton;
