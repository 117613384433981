import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors, font, lineHeight } from '@matterapp/matter-theme';
import { useLazyQuery } from '@apollo/client';
import {
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import Toast from 'components/Toast/Toast';
import { mixpanel } from 'libs/tracking';
import { 
  PaymentHeader, 
  PoweredByStripeContainer,
  CardElementContainer,
  ErrorMessage,
  SubmitButton,
} from '../styles';
import poweredByStripe from './powered-by-stripe.svg';
import trackAddPaymentInfo from 'graphql-queries/queries/billing/trackAddPaymentInfo';

const handleTracking = {
  submitClickedSuccess: mixpanel.createEventSender('billing:submit-payment-clicked-success'),
  submitClickedError: mixpanel.createEventSender('billing:submit-payment-clicked-failed'),
};
function CheckoutForm({ onSubmit, subscription, onPaymentComplete, workspaceId }) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isEventSent, setEventSent] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [trackAddingPayment] = useLazyQuery(
    trackAddPaymentInfo
  );

  async function processPayment() {
    let result;
    if (subscription.status === 'active') {
      // setupIntent - if no payment intent for 100% promo
      result = await stripe.confirmCardSetup(subscription.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        }
      });
      result.intent = result.setupIntent;
    } else {
      // paymentIntent
      result = await stripe.confirmCardPayment(subscription.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        }
      });
      result.intent = result.paymentIntent;
    }
    setIsLoading(false);
    if (result.error) {
      setError(result.error.message);
      handleTracking.submitClickedError();
      Toast.error('Payment failed, please check your billing details and try again');
    } else if (result.intent.status === 'succeeded') {
      onPaymentComplete();
      handleTracking.submitClickedSuccess();
      Toast.success('Success! You upgraded to Matter Pro');
    }
  }

  useEffect(() => {
    if (subscription) {
      processPayment();
    }
  }, [subscription]);

  const handleChange = async (event) => {
    setSubmitDisabled(event.empty);
    setError(event.error ? event.error.message : "");
    
    if (!isEventSent) {
      setEventSent(true);

      trackAddingPayment({
        variables: {
          workspaceId
        }
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);

    if (!subscription) {
      onSubmit();
    } else {
      processPayment();
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: colors.blacks[90],
        fontSize: font.size.S,
        lineHeight: lineHeight.M,
        fontWeight: font.weight.regular,
        '::placeholder': {
          color: colors.blacks[40],
        },
      },
      invalid: {
        color: colors.reds[40],
        iconColor: colors.reds[40],
      },
    }
  };
  
  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <PaymentHeader>
        Add Payment Info
        <PoweredByStripeContainer>
          <img
            alt='powered by stripe'
            src={poweredByStripe}
            style={{
              height: 24,
            }}
          />
        </PoweredByStripeContainer>
      </PaymentHeader>
      <CardElementContainer isInvalid={!!error}>
        <CardElement options={cardStyle} onChange={handleChange}/>
      </CardElementContainer>
      {error && (
        <ErrorMessage className='card-error' role='alert'>
          {error}
        </ErrorMessage>
      )}
      <SubmitButton id='submit' disabled={isLoading || !stripe || !elements || !!error || submitDisabled} color='blue' isLoading={isLoading}>Submit Payment</SubmitButton>
    </form>
  );
}

CheckoutForm.propTypes = {
  onSubmit: PropTypes.func,
  onPaymentComplete: PropTypes.func,
  workspaceId: PropTypes.number,
  subscription: PropTypes.object
};

export default CheckoutForm;
