import { Resources } from '@matterapp/routing';
import { matchRoutes } from 'react-router-dom';

export const LATEST_LAYOUT_VERSION = 3;
export const WORKSPACE_QUERY_PARAM = 'workspace';

export const pathsToTabs = {
  [Resources.homeMain.pattern]: 0,
  [Resources.skillsMain.pattern]: 1,
  [Resources.skillDetails.pattern]: 1,
};

export const profilePathsToTabs = {
  [Resources.feedbackRequestsMain.pattern]: 1,
  [Resources.userSettingsMain.pattern]: 2,
  [Resources.userSettingsAccount.pattern]: 2,
  [Resources.userSettingsNotifications.pattern]: 2
};

export const notificationsPathsToTabs = {
  [Resources.notificationsMain.pattern]: 1,
};

export const workspacesPathsToTabs = {
  [Resources.workspacesActivityFeed.pattern]: 1,
  [Resources.feedbackMain.pattern]: 2,
  [Resources.workspacesRecurring.pattern]: 3,
  [Resources.skillsMain.pattern]: 4,
  [Resources.skillDetails.pattern]: 4,
  [Resources.rewardsStore.pattern]: 5,
  [Resources.analyticsMain.pattern]: 6,
  [Resources.rewardsManager.pattern]: 7,
};

export const isHomePage = (path) => {
  return getActiveTab(path) === 0;
};

export const isWorkspacesPage = (path) => {
  return getActiveTab(path, workspacesPathsToTabs) === 1;
};

export const isSettingsPage = (path) => {
  return getActiveTab(path, profilePathsToTabs) === 2;
};

export const getActiveTab = (path, tabs = pathsToTabs) => {
  const match = matchRoutes(Object.keys(tabs).map(tab => ({ path: tab })), path);

  if (match) {
    const route = match[0].route.path;
    const tab = tabs[route];

    return Number.isInteger(tab) ? tab : null;
  }
  
  return null;
};

export const getActiveWorkspaces = (workspaces = []) => {
  return workspaces.filter((workspace = {}) => {
    const { isDeleted, isPending } = workspace;
    return !isDeleted && !isPending;
  });
};

export const compareWorkspace = (a = {}, b = {}) => {
  const workspaceA = a.name;
  const workspaceB = b.name;

  if (workspaceA > workspaceB) {
    return 1;
  }
  if (workspaceA < workspaceB) {
    return -1;
  }
  return 0;
};

export const sortWorkspaces = (workspacesToSort = []) => {
  return [...workspacesToSort].sort(compareWorkspace);
};
