import React from 'react';

const Icon = () => (
  <svg
    width="182"
    height="147"
    viewBox="0 0 182 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M99.7893 0L90.5496 29.6471L81.3098 0H99.7893Z"
      fill="#CCE3FF"
    />
    <path
      d="M67.7946 8.66394L76.8642 29.5624L55.4043 22.0427L67.7946 8.66394Z"
      fill="#CCE3FF"
    />
    <path
      d="M113.194 8.66394L104.125 29.5624L125.585 22.0427L113.194 8.66394Z"
      fill="#CCE3FF"
    />
    <path
      d="M176.956 50.2458L176.853 50.22C174.056 49.5489 171.241 51.2611 170.529 54.0573L164.817 76.7281C164.723 77.1066 164.165 77.0034 164.208 76.6162L167.837 44.2491C168.189 41.1173 165.967 38.2867 162.853 37.8823L162.741 37.8651C159.592 37.4607 156.709 39.6891 156.296 42.8553L152.136 75.3085C152.075 75.7989 151.355 75.7558 151.355 75.2568L151.364 36.3853C151.365 32.8147 148.474 29.9153 144.913 29.9153H144.784C141.223 29.9153 138.331 32.8147 138.33 36.3853L138.321 74.362C138.321 74.6374 137.918 74.6546 137.892 74.3792L135.54 41.7626C135.3 38.4502 132.435 35.9465 129.131 36.1616L129.011 36.1702C125.734 36.3853 123.227 39.1987 123.381 42.4939L125.319 83.9552L124.821 88.2915C124.468 91.3716 121.293 93.273 118.419 92.1201L118.307 92.0771C114.601 90.5886 110.645 89.8229 106.655 89.8229H103.781C100.52 89.8229 97.5423 91.6727 96.0829 94.5894L96.0485 94.6668C95.379 96.0004 96.0736 97.6179 97.4979 98.0567L104.079 100.079C105.597 100.543 106.961 101.404 108.034 102.565L114.116 109.138L118.611 115.514C120.369 118.009 122.677 120.056 125.354 121.519L128.991 123.498L131.418 131.577H164.196L167.002 127.258C168.17 125.468 168.934 123.446 169.243 121.33L173.592 91.8792C173.661 91.4404 173.695 91.0016 173.704 90.5542L173.764 88.1022C173.782 87.3623 173.876 86.6223 174.048 85.8996L180.868 56.604C181.512 53.7476 179.771 50.9169 176.956 50.2458Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M169.379 124.754L130.476 124.754C129.372 124.754 128.476 125.649 128.476 126.754L128.472 144.263C128.471 145.368 129.367 146.263 130.471 146.263L169.374 146.263C170.479 146.263 171.374 145.368 171.375 144.263L171.379 126.754C171.379 125.649 170.484 124.754 169.379 124.754Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M4.04092 50.2458L4.14389 50.22C6.94115 49.5489 9.75557 51.261 10.4678 54.0572L16.1738 76.728C16.2682 77.1066 16.8259 77.0034 16.783 76.6162L13.162 44.249C12.8102 41.1172 15.0326 38.2866 18.1473 37.8822L18.2589 37.865C21.408 37.4606 24.291 39.689 24.7029 42.8552L28.8559 75.3084C28.9159 75.7988 29.6367 75.7558 29.6367 75.2568V36.3852C29.6367 32.8146 32.5284 29.9152 36.0893 29.9152H36.218C39.7789 29.9152 42.6706 32.8146 42.6706 36.3852V74.362C42.6706 74.6373 43.0738 74.6545 43.0996 74.3792L45.4592 41.7625C45.6995 38.4501 48.5654 35.9464 51.8689 36.1615L51.989 36.1701C55.2668 36.3852 57.7723 39.1986 57.6179 42.4938L55.6701 83.9552L56.1678 88.2915C56.5196 91.3716 59.6944 93.273 62.5689 92.1201L62.6804 92.0771C66.3872 90.5886 70.3428 89.8229 74.3328 89.8229H77.2073C80.4679 89.8229 83.4453 91.6727 84.904 94.5894L84.9384 94.6668C85.6076 96.0004 84.9126 97.6179 83.4883 98.0567L76.907 100.079C75.3882 100.543 74.0239 101.404 72.9513 102.565L66.8677 109.138L62.3715 115.514C60.6125 118.009 58.3043 120.056 55.6272 121.519L51.989 123.498L49.5607 131.577H16.783L13.9772 127.258C12.8102 125.468 12.0466 123.446 11.7377 121.33L7.39592 91.8792C7.32727 91.4404 7.29295 91.0016 7.28437 90.5542L7.22431 88.1022C7.20715 87.3623 7.11276 86.6223 6.94115 85.8996L0.136772 56.5781C-0.51535 53.7475 1.2265 50.9169 4.04092 50.2458Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M50.9757 124.754H12.073C10.9684 124.754 10.073 125.65 10.073 126.754V144.263C10.073 145.368 10.9684 146.263 12.073 146.263H50.9757C52.0803 146.263 52.9757 145.368 52.9757 144.263V126.754C52.9757 125.65 52.0803 124.754 50.9757 124.754Z"
      fill="url(#paint3_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="113.292"
        y1="49.792"
        x2="179.676"
        y2="116.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE7E" />
        <stop offset="1" stopColor="#EE8D11" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="171.375"
        y1="135.509"
        x2="128.472"
        y2="135.499"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#25277A" />
        <stop offset="1" stopColor="#4F52B1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="67.705"
        y1="49.792"
        x2="1.305"
        y2="116.013"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE7E" />
        <stop offset="1" stopColor="#EE8D11" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="52.974"
        y1="135.509"
        x2="10.071"
        y2="135.509"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F52B1" />
        <stop offset="1" stopColor="#25277A" />
      </linearGradient>
    </defs>
  </svg>
);

export default Icon;
