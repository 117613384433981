import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import omitStyled from '../../libs/omitStyled';
import theme from '@matterapp/matter-theme';
import List from './List';

const ListContainer = omitStyled('div', ['bordered'])`
  flex-direction: column;
  margin-bottom: 0;
  ${({ bordered }) =>
    bordered &&
    `
    border: solid 1px ${theme.colors.blacks[10]}
  `}

  ${({ rounded }) => theme.media.medium`
    border-radius: ${rounded ? '12px' : '0px'};
    margin-bottom: 24px;
  `}

  ${theme.media.large`
    margin-bottom: 24px;
  `}
`;

const Header = styled.div`
  padding: 16px 24px;
  background: white;
  color: ${theme.colors.purple};
  font-weight: bold;
  font-size: ${theme.font.size.base};
  line-height: 1;
  text-align: left;

  ${({ rounded }) => theme.media.medium`
    ${rounded ? `
      border-top-left-radius: ${theme.sizes.borderRadius[80]};
      border-top-right-radius: ${theme.sizes.borderRadius[80]};
    ` : ''}
  `}

  ${({ headerType }) =>
    headerType === 'normal' &&
    `
    background: ${theme.colors.blacks[5]};
  `}

  ${({ headerType }) =>
    headerType === 'normal' &&
    theme.media.medium`
    background: white;
  `}

  ${({ headerType }) =>
    headerType === 'neutral' &&
    `
    color: ${theme.colors.blacks[60]};
    background: ${theme.colors.blacks[5]};
  `}

  ${({ headerType }) =>
    headerType === 'important' &&
    `
    color: ${theme.colors.oranges[60]};
    background: ${theme.colors.yellows[10]};
  `}

  ${({ headerType }) =>
    headerType === 'info' &&
    `
    color: ${theme.colors.purples[50]};
    background: ${theme.colors.blues[5]};
  `}

  ${({ headerType }) =>
    headerType === 'invite' &&
    `
    color: ${theme.colors.purples[50]};
    background: ${theme.colors.purples[5]};
  `}

  ${({ headerType }) =>
    headerType === 'success' &&
    `
    color: ${theme.colors.greens[50]};
    background: ${theme.colors.greens[5]};
  `}
`;

const ListWithHeader = ({
  header,
  headerType,
  items,
  className,
  renderItem,
  empty,
  bordered,
  rounded,
  showAllItemBorders,
}) => (
  <ListContainer className={className} bordered={bordered}>
    <Header headerType={headerType} rounded={rounded}>{header}</Header>
    <List items={items} renderItem={renderItem} empty={empty} rounded={rounded} showAllItemBorders={showAllItemBorders} />
  </ListContainer>
);

ListWithHeader.defaultProps = {
  className: null,
  headerType: 'normal',
  rounded: true,
  empty: null,
  bordered: false,
};

ListWithHeader.propTypes = {
  header: PropTypes.node.isRequired,
  headerType: PropTypes.oneOf(['normal', 'neutral', 'important', 'info', 'invite', 'success']),
  rounded: PropTypes.bool,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderItem: PropTypes.func.isRequired,
  bordered: PropTypes.bool,
  empty: PropTypes.node,
  showAllItemBorders: PropTypes.bool,
};

export default ListWithHeader;
