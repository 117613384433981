import React from 'react';
import PropTypes from 'prop-types';
import Animation from '../Animation';
import gsap from 'gsap';
import SVG from '../../SVG';
import * as R from './rocketComponents';

const DEFAULT_HEIGHT = 468;
const DEFAULT_WIDTH = 352;

export const STEPS = [0, 1, 2, 3, 4, 5, 6];

export default class ProgressRocket extends Animation {
  static propTypes = {
    /** The amount of milliseconds to delay animation. */
    animationDelay: PropTypes.number,
    /** Base color of the animation. */
    color: PropTypes.oneOf(R.colors),
    /** The height of the animation in pixels. */
    height: PropTypes.number,
    /** The width of the animation in pixels. */
    width: PropTypes.number,
    /** Callback when animation completes playing. */
    onComplete: PropTypes.func,
    /** Plays animation from beggining to current step, otherwise animation plays from previous step. */
    playFromBeginning: PropTypes.bool,
    /** The current step of the progress. */
    step: PropTypes.oneOf(STEPS),
  };

  static steps = STEPS;

  static defaultProps = {
    animationDelay: 1,
    color: R.colors[1],
    height: DEFAULT_HEIGHT,
    width: DEFAULT_WIDTH,
    step: 0,
  };

  constructor(props) {
    super(props);
    this.birdRefs = [];
    this.progressLineRefs = [];
    this.stepsRefs = [];
    this.tl = gsap.timeline({ defaults: { duration: 0 } });
  }

  /**
   * Sets the initial position of the animation.
   * @returns { gsap.timeline } The timeline of the initialization.
   */
  initializeAnimation = () => {
    const clearProps = { clearProps: 'all' };
    const tl = gsap.timeline({
      defaults: { duration: 0, transformOrigin: 'center' },
    });
    tl.to(this.birdsRef, { opacity: 1 });
    tl.to(this.darkFlameRef, clearProps);
    tl.to(this.darkFlameRef, { scaleY: 0 });
    tl.to(this.globeRef, { y: 190, rotation: 0, scale: 1 });
    tl.to(this.landscapeRef, clearProps);
    tl.to(this.lightFlameRef, clearProps);
    tl.to(this.lightFlameRef, { scaleY: 0 });
    tl.to(this.landscapeRef, { ease: 'none' });
    tl.to(this.lightFlameRef, { ease: 'none' });
    tl.to(this.platformRef, clearProps);
    tl.to(this.platformTowerRef, clearProps);
    tl.to(this.progressLinesRef, { opacity: 0 });
    tl.to(this.rocketShipRef, clearProps);
    tl.to(this.skyRef, clearProps);
    tl.to(this.smallFlameRef, { opacity: 0 });
    tl.to(this.smokeRef, { opacity: 0 });
    tl.to(this.starsRef, { opacity: 0 });
    tl.to(this.stepsRefs[1], clearProps);
    tl.to(this.wingLinesRef, { y: 0, opacity: 0, scaleY: 0 });

    this.birdRefs.forEach((birdRef) => {
      tl.to(birdRef, clearProps, '<');
    });
    this.progressLineRefs.forEach((progressLineRef) => {
      tl.to(progressLineRef, clearProps, '<');
    });
    this.stepsRefs.forEach((stepRef) => {
      tl.to(stepRef, clearProps, '<');
    });
    return tl;
  };

  /**
   * Animates the progress line and step count.
   * @param { gsap.timeline } timeline: The current timeline animation.
   * @param { Number } step: The current step number.
   * @param { Boolean } animatePrevStep: If previous step should be animated.
   * @returns { void }
   */
  animateProgressLines = (timeline, step, animatePrevStep = true) => {
    timeline.to(
      this.progressLineRefs[step - 1],
      { opacity: 0, scaleX: 2, duration: 0.6, ease: 'none' },
      animatePrevStep ? '<' : '>'
    );
    if (animatePrevStep && this.stepsRefs[step]) {
      timeline.to(
        this.stepsRefs[step - 1],
        { opacity: 0, duration: 0.2, ease: 'none' },
        '<0.1'
      );
      timeline.to(
        this.stepsRefs[step],
        { opacity: 1, duration: 0.2, ease: 'none' },
        '>'
      );
    }
  };

  /**
   * Animates the previous step timeline.
   * @param { gsap.timeline } timeline: The current timeline animation.
   * @param { gsap.timeline } timeline: The previous timeline animation.
   * @param { Boolean } initialize: If step should be initialized instead of played.
   * @returns { void }
   */
  animatePrevStep = (timeline, prevTimeline, initialize) => {
    if (initialize) {
      prevTimeline.seek(prevTimeline.duration());
      timeline.to(
        this.rocketShipRef,
        { duration: this.props.animationDelay },
        '>'
      );
    } else {
      timeline.add(prevTimeline);
    }
  };

  /**
   * Gets the timeline default props. If animation step matches current step, animation should ease out.
   * @param { Number } step: The current animation step.
   * @param { Number } baseDuration: The base default animation duration.
   * @returns { Object } The default animation props.
   */
  getTimelineDefaults = (step, baseDuration = 0.6) => {
    const isCurrentStep = this.props.step === step;
    return {
      defaults: {
        duration: isCurrentStep ? baseDuration + 0.6 : baseDuration,
        ease: isCurrentStep ? 'power1.out' : 'none',
        transformOrigin: 'center',
      },
    };
  };

  /**
   * Plays the animation of step 1.
   * @returns { gsap.timeline } The timeline of the step.
   */
  playStepOne = () => {
    const tl = gsap.timeline(this.getTimelineDefaults(1, 0.8));
    tl.add(this.initializeAnimation(), '>');
    tl.to(
      this.darkFlameRef,
      { y: -65, scaleY: 0.42, scaleX: 0, duration: 0 },
      '<'
    );
    tl.to(
      this.lightFlameRef,
      { y: -52, scaleY: 0.4, scaleX: 0, duration: 0 },
      '<'
    );
    tl.to(this.smokeRef, { scale: 0, y: 20, duration: 0 }, '>0.5');
    tl.to(
      this.smokeRef,
      { opacity: 1, scaleX: 0.7, scaleY: 0.8, duration: 1.2, ease: 'none' },
      '>0.05'
    );
    tl.to(
      this.darkFlameRef,
      { y: -65, scaleY: 0.42, scaleX: 0.58, duration: 0.3 },
      '<0.2'
    );
    tl.to(
      this.lightFlameRef,
      { y: -52, scaleY: 0.4, scaleX: 0.5, duration: 0.3 },
      '<'
    );
    tl.add('birds');
    tl.to(this.birdRefs[1], { x: -20, y: 20, opacity: 0, scale: 0 }, '<0.1');
    tl.to(this.birdRefs[2], { x: 20, y: -30, opacity: 0, scale: 0 }, '<0.1');
    tl.to(this.birdRefs[3], { x: 40, y: 10, opacity: 0, scale: 0 }, '<-0.2');
    tl.to(this.rocketShipRef, { y: -50 }, 'birds-=0.1');
    tl.to(this.smokeRef, { y: 0, scale: 1 }, '<');
    tl.to(this.progressLinesRef, { opacity: 1, duration: 0.4 }, '<');
    tl.to(
      this.platformTowerRef,
      { y: 140, x: -20, rotation: -40, duration: 1, ease: 'power1.in' },
      '<-0.3'
    );
    tl.to(this.stepsRefs[1], { opacity: 1, duration: 0.2 }, '<0.6');
    tl.to(this.platformRef, { opacity: 0, duration: 0 }, '>');
    return tl;
  };

  /**
   * Plays the animation of step 2.
   * @param { Object } stepProps: Animation props to indicate initialization.
   * @returns { gsap.timeline } The timeline of the step.
   */
  playStepTwo = ({ initialize } = {}) => {
    const tl = gsap.timeline(this.getTimelineDefaults(2));
    const tlPrev = this.playStepOne({ initialize });
    this.animatePrevStep(tl, tlPrev, initialize);
    tl.to(this.rocketShipRef, { y: -90 }, '>');
    tl.to(this.smokeRef, { scale: 1.4, opacity: 0.8 }, '<');
    tl.to(this.darkFlameRef, { y: -50, scaleY: 0.6, scaleX: 0.9 }, '<');
    tl.to(this.lightFlameRef, { y: -36, scaleY: 0.52, scaleX: 0.7 }, '<');
    this.animateProgressLines(tl, 2);
    return tl;
  };

  /**
   * Plays the animation of step 3.
   * @param { Object } stepProps: Animation props to indicate initialization.
   * @returns { gsap.timeline } The timeline of the step.
   */
  playStepThree = ({ initialize } = {}) => {
    const tl = gsap.timeline(this.getTimelineDefaults(3));
    const tlPrev = this.playStepTwo({ initialize });
    this.animatePrevStep(tl, tlPrev, initialize);
    tl.to(this.rocketShipRef, { y: -130 }, '>');
    tl.to(this.wingLinesRef, { y: -20, duration: 0 }, '<');
    tl.to(this.smokeRef, { scale: 1.8, opacity: 0.5 }, '<');
    tl.to(this.darkFlameRef, { y: -36, scaleY: 0.72, scaleX: 1.1 }, '<');
    tl.to(this.lightFlameRef, { y: -8, scaleY: 0.72, scaleX: 1.1 }, '<');
    this.animateProgressLines(tl, 3);
    return tl;
  };

  /**
   * Plays the animation of step 4.
   * @param { Object } stepProps: Animation props to indicate initialization.
   * @returns { gsap.timeline } The timeline of the step.
   */
  playStepFour = ({ initialize } = {}) => {
    const tl = gsap.timeline(this.getTimelineDefaults(4));
    const tlPrev = this.playStepThree({ initialize });
    this.animatePrevStep(tl, tlPrev, initialize);
    tl.to(this.rocketShipRef, { y: -170 }, '>');
    tl.to(this.wingLinesRef, { y: -10, opacity: 1, scaleY: 0.4 }, '<');
    tl.to(this.smokeRef, { scale: 2, opacity: 0 }, '<');
    tl.to(this.darkFlameRef, { y: -20, scaleY: 0.9, scaleX: 1.3 }, '<');
    tl.to(this.lightFlameRef, { y: 10, scaleY: 0.9, scaleX: 1.25 }, '<');
    this.animateProgressLines(tl, 4);
    return tl;
  };

  /**
   * Plays the animation of step 5.
   * @param { Object } stepProps: Animation props to indicate initialization.
   * @returns { gsap.timeline } The timeline of the step.
   */
  playStepFive = ({ initialize } = {}) => {
    const tl = gsap.timeline(this.getTimelineDefaults(5));
    const tlPrev = this.playStepFour({ initialize });
    this.animatePrevStep(tl, tlPrev, initialize);
    tl.to(this.rocketShipRef, { y: -210 }, '>');
    tl.to(this.wingLinesRef, { y: 0, opacity: 1, scaleY: 1 }, '<');
    tl.to(this.darkFlameRef, { y: 10, scaleY: 1, scaleX: 1 }, '<');
    tl.to(this.lightFlameRef, { y: 10, scaleY: 1.2, scaleX: 1 }, '<');
    tl.to(this.landscapeRef, { y: 40 }, '<');
    tl.to(this.skyRef, { y: 40 }, '<');
    this.animateProgressLines(tl, 5);
    return tl;
  };

  /**
   * Plays the animation of step 6.
   * @param { Object } stepProps: Animation props to indicate initialization.
   * @returns { gsap.timeline } The timeline of the step.
   */
  playStepSix = ({ initialize } = {}) => {
    const tl = gsap.timeline(this.getTimelineDefaults(6));
    const tlPrev = this.playStepFive({ initialize });
    this.animatePrevStep(tl, tlPrev, initialize);
    this.animateProgressLines(tl, 6, false);
    tl.to(
      this.globeRef,
      { y: 340, scale: 1.8, rotation: -50, duration: 0 },
      '<'
    );
    tl.to(this.rocketShipRef, { y: -250, duration: 2 }, '<');
    tl.to(this.wingLinesRef, { y: 10, scaleY: 1.4 }, '<');
    tl.to(this.smallFlameRef, { opacity: 1 }, '<');
    tl.to(this.darkFlameRef, { opacity: 0, scaleY: 1.3 }, '<');
    tl.to(this.lightFlameRef, { opacity: 0, scaleY: 1.3 }, '<');
    tl.to(this.landscapeRef, { y: 600, opacity: 0, duration: 1.5 }, '<');
    tl.to(this.skyRef, { y: 600, opacity: 0, duration: 1.5 }, '<');
    tl.to(
      this.globeRef,
      { y: 0, scale: 1, rotation: -20, duration: 1.5, ease: 'none' },
      '>-0.8'
    );
    tl.to(this.wingLinesRef, { opacity: 0 }, '<');
    tl.to(this.starsRef, { duration: 0 }, '<0.8');
    this.animateProgressLines(tl, 6);
    this.animateProgressLines(tl, 7);
    tl.to(this.starsRef, { opacity: 1 }, '<');
    tl.to(this.globeRef, { rotation: 0, duration: 1.5 }, '>-0.8');
    return tl;
  };

  /**
   * Plays the animation.
   * @param { Object } animationProps: Props to initialize or override default props passed in.
   * @returns { gsap.timeline } The animation timeline.
   */
  playAnimation = (animationProps = {}) => {
    const { initialize, step, onComplete, playFromBeginning } = { ...animationProps, ...this.props };
    const initializeFromBeginning = playFromBeginning && initialize;
    const shouldInitialize = !playFromBeginning || initialize;
    const initializeStep = initializeFromBeginning ? false : shouldInitialize;
    const playProps = { initialize: initializeStep };

    const stepAnimations = {
      1: this.playStepOne,
      2: this.playStepTwo,
      3: this.playStepThree,
      4: this.playStepFour,
      5: this.playStepFive,
      6: this.playStepSix,
    };

    if (this.tl && this.tl.kill) {
      // If animation is currently playing, pause it and reset animation.
      this.tl.kill();
    }

    const animationFunction = stepAnimations[step] || this.initializeAnimation;
    this.tl = gsap.timeline({ defaults: { duration: 0 }, onComplete });
    this.tl.add(animationFunction(playProps));

    if (initialize) {
      this.tl.seek(0);
      this.tl.pause();
    }
    return this.tl;
  };

  resetAnimation = () => {
    return this.playAnimation({ initialize: true });
  };

  render() {
    const { color, height, width, ...otherProps } = this.props;
    const filteredProps = {...otherProps};
    delete filteredProps.onComplete;
    return (
      <SVG
        {...filteredProps}
        height={height}
        width={width}
        viewBox={`0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`}
      >
        <R.Background color={color} />
        <R.ProgressLines ref={(ref) => (this.progressLinesRef = ref)}>
          <R.ProgressLine1 ref={(ref) => (this.progressLineRefs[1] = ref)} />
          <R.ProgressLine2 ref={(ref) => (this.progressLineRefs[2] = ref)} />
          <R.ProgressLine3 ref={(ref) => (this.progressLineRefs[3] = ref)} />
          <R.ProgressLine4 ref={(ref) => (this.progressLineRefs[4] = ref)} />
          <R.ProgressLine5 ref={(ref) => (this.progressLineRefs[5] = ref)} />
          <R.ProgressLine6 ref={(ref) => (this.progressLineRefs[6] = ref)} />
        </R.ProgressLines>
        <R.Landscape ref={(ref) => (this.landscapeRef = ref)}>
          <R.RightSmallTree>
            <R.RightSmallTreeLeaves />
            <R.RightSmallTreeTrunk color={color} />
          </R.RightSmallTree>
          <R.RightLargeTree>
            <R.RightLargeTreeLeaves />
            <R.RightLargeTreeTrunk color={color} />
          </R.RightLargeTree>
          <R.LeftTree>
            <R.LeftTreeLeaves />
            <R.LeftTreeTrunk color={color} />
          </R.LeftTree>
        </R.Landscape>
        <R.PlatformBase ref={(ref) => (this.platformRef = ref)} />
        <R.Smoke ref={(ref) => (this.smokeRef = ref)} />
        <R.Globe ref={(ref) => (this.globeRef = ref)}>
          <R.Land />
          <R.Water />
        </R.Globe>
        <R.Stars ref={(ref) => (this.starsRef = ref)}>
          <R.RightBottomStar color={color} />
          <R.RightMiddleStar color={color} />
          <R.RightTopStar color={color} />
          <R.RightCenterBottomStar color={color} />
          <R.RightCenterMiddleStar color={color} />
          <R.RightCenterTopStar color={color} />
          <R.LeftCenterBottomStar color={color} />
          <R.LeftCenterMiddleStar color={color} />
          <R.LeftCenterTopStar color={color} />
          <R.LeftBottomStar color={color} />
          <R.LeftMiddleStar color={color} />
          <R.LeftTopStar color={color} />
        </R.Stars>
        <R.PlatformTower ref={(ref) => (this.platformTowerRef = ref)} />
        <R.RocketShip ref={(ref) => (this.rocketShipRef = ref)}>
          <R.Flames>
            <R.SmallFlame ref={(ref) => (this.smallFlameRef = ref)} />
            <R.DarkFlame ref={(ref) => (this.darkFlameRef = ref)} />
            <R.LightFlame ref={(ref) => (this.lightFlameRef = ref)} />
            <R.WingLines ref={(ref) => (this.wingLinesRef = ref)}>
              <R.LeftWingLine color={color} />
              <R.CenterLeftWingLine color={color} />
              <R.CenterRightWingLine color={color} />
              <R.RightWingLine color={color} />
            </R.WingLines>
          </R.Flames>
          <R.RocketBody />
          <R.RocketWindow>
            <R.RocketWindowBackground />
            <R.StepNumberOne ref={(ref) => (this.stepsRefs[1] = ref)} />
            <R.StepNumberTwo ref={(ref) => (this.stepsRefs[2] = ref)} />
            <R.StepNumberThree ref={(ref) => (this.stepsRefs[3] = ref)} />
            <R.StepNumberFour ref={(ref) => (this.stepsRefs[4] = ref)} />
            <R.StepNumberFive ref={(ref) => (this.stepsRefs[5] = ref)} />
            <R.StepNumberSix ref={(ref) => (this.stepsRefs[6] = ref)} />
          </R.RocketWindow>
        </R.RocketShip>
        <R.Sky ref={(ref) => (this.skyRef = ref)}>
          <R.Sun />
          <R.LeftCloud color={color} />
          <R.RightCloud color={color} />
          <R.Birds ref={(ref) => (this.birdsRef = ref)}>
            <R.LeftBird ref={(ref) => (this.birdRefs[1] = ref)} />
            <R.CenterBird ref={(ref) => (this.birdRefs[2] = ref)} />
            <R.RightBird ref={(ref) => (this.birdRefs[3] = ref)} />
          </R.Birds>
        </R.Sky>
      </SVG>
    );
  }
}
