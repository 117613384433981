import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from '../../SVG';

const DEFAULT_HEIGHT = 120;
const DEFAULT_WIDTH = 200;

const Group = styled.g``;
const GroupBlendMultiply = styled.g`
  mix-blend-mode: multiply;
`;

const Path = styled.path``;
const PathEvenOdd = styled.path.attrs(() => ({
  fillRule: 'evenodd',
  clipRule: 'evenodd',
}))``;

const Image = forwardRef((props, ref) => {
  const { children, className, onClick, onMouseEnter } = props;
  const otherProps = { className, onClick, onMouseEnter };
  return (
    <SVG
      width={DEFAULT_WIDTH}
      height={DEFAULT_HEIGHT}
      viewBox={`0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`}
      preserveAspectRatio="xMidYMin slice"
      ref={ref}
      {...otherProps}
    >
      {children}
    </SVG>
  );
});

Image.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
};

Image.Group = Group;
Image.GroupBlendMultiply = GroupBlendMultiply;
Image.Path = Path;
Image.PathEvenOdd = PathEvenOdd;

export { Group, GroupBlendMultiply, Path, PathEvenOdd };
export default Image;
