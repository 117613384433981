import moment from 'moment';
import { WeekDay } from '@matterapp/calendar-date';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateRecurrenceTooltip = (recurrence) => {
  const day = recurrence.frequency === 'weekly' || recurrence.frequency === 'biweekly' ? WeekDay[recurrence.day] + 's' : recurrence.day;
  const date = typeof day === 'number' ? `the ${moment.localeData().ordinal(day)}` : day;
  return `${capitalizeFirstLetter(recurrence.frequency)} on ${date}`;
};