import omitStyled from '../../../libs/omitStyled';
import { SIZE } from './size';
import theme from '@matterapp/matter-theme';

const Heading = omitStyled('h2', ['centered', 'size'])`
  color: ${() => theme.colors.blacks[90]};
  font-weight: bold;

  ${({ size }) =>
    size === SIZE.LARGE
      ? `
  line-height: 32px;
  font-size: 22px;
  `
      : `
  font-size: 17px;
  line-height: 24px;
  `}

  margin-bottom: 8px;
  ${({ centered }) =>
    centered &&
    `
      text-align: center;
      margin: 0 auto;
    `}
`;

export default Heading;
