import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { containerStyles, headingStyles } from './sharedStyles';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
${containerStyles}
  padding-top: ${theme.spacing.singleAndHalf};
  padding-bottom: ${theme.spacing.double};
  background-color: ${() => theme.colors.purples[50]};
`;

const Heading = styled.div`
  ${headingStyles}
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  color: ${() => theme.colors.purples[5]};
  font-style: normal;
  font-weight: normal;

  line-height: 20px;
  font-size: 14px;

  ${() => theme.media.medium`
    line-height: 24px;
    font-size: 17px;
  `}
`;

const ChildrenContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeBanner = ({ children }) => (
  <Container>
    <Heading>Welcome to Your Peers List!</Heading>
    <Paragraph>
      Peers help find your superpowers, discover <br />
      blindspots and become the best version of yourself. <br />
      <strong>Let’s get started and add 5 peers to your list.</strong>
    </Paragraph>
    <ChildrenContainer>{children}</ChildrenContainer>
  </Container>
);

WelcomeBanner.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WelcomeBanner;
