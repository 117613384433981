import styled from 'styled-components';
import { Clickable } from '../Clickable/Clickable';

export const getBadgeSize = ({ size } = {}, modifier = 2.4) => size / modifier;
export const getBadgeMarginTop = ({ size } = {}, modifier = 1.7) => size / modifier;

export const Container = styled.div`
  display: inline;
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const ContainerLink = styled(Clickable)`
  display: inline-block;
  position: relative;
`;
