import { connect } from 'react-redux';
import { composerActions } from '../reducers';

const {
  addSkill,
  changeMessage,
  changeSelectedKudos,
  changeSelectedPeers,
  changeSelectedSkill,
  changeSelectedPeer,
  resetSelectedSkill,
  resetSelectedKudos,
  closeGiveFeedbackModal,
  closeKudosSentModal,
  closeRequestFeedbackModal,
  closeSendKudosModal,
  initialize,
  initUserSkillsList,
  openGiveFeedbackModal,
  openKudosSentModal,
  openRequestFeedbackModal,
  openSendKudosModal,
  removeSkill,
  setCustomSurvey,
  setFeedbackTemplate,
  setIsSending,
  onChangeSelectedSkillsIds,
} = composerActions;

const mapStateToProps = (state) => state.composer;

const mapDispatchToProps = (dispatch) => ({
  initializeComposer: (props) => dispatch(initialize(props)),
  onAddSkill: (e, { skill }) => dispatch(addSkill(skill.id)),
  onChangeMessage: (e, { message }) => dispatch(changeMessage(message)),
  onChangeSelectedKudos: (e, { selectedKudos }) =>
    dispatch(changeSelectedKudos(selectedKudos)),
  onChangeSelectedPeers: (e, { selectedPeers }) =>
    dispatch(changeSelectedPeers(selectedPeers)),
  onChangeSelectedPeer: (e, { selectedPeer }) =>
    dispatch(changeSelectedPeer(selectedPeer)),
  onChangeSelectedSkill: (e, { selectedSkill }) =>
    dispatch(changeSelectedSkill(selectedSkill)),
  onResetSelectedSkill: () => dispatch(resetSelectedSkill()),
  onCloseKudosSentModal: () => dispatch(closeKudosSentModal()),
  onCloseRequestFeedbackModal: () => dispatch(closeRequestFeedbackModal()),
  onCloseSendKudosModal: () => dispatch(closeSendKudosModal()),
  onCloseGiveFeedbackModal: () => dispatch(closeGiveFeedbackModal()),
  onOpenKudosSentModal: () => dispatch(openKudosSentModal()),
  onOpenRequestFeedbackModal: () => dispatch(openRequestFeedbackModal()),
  onOpenSendKudosModal: () => dispatch(openSendKudosModal()),
  onOpenGiveFeedbackModal: () => dispatch(openGiveFeedbackModal()),
  onRemoveSkill: (e, { skill }) => dispatch(removeSkill(skill.id)),
  setCustomSurvey: (isCustom) => dispatch(setCustomSurvey(isCustom)),
  setFeedbackTemplate: (props) => dispatch(setFeedbackTemplate(props)),
  setIsSending: (isSending) => dispatch(setIsSending(isSending)),

  //advisors
  onResetSelectedKudos: () => dispatch(resetSelectedKudos()),
  onChangeSelectedAdvisor: (selectedPeer) => dispatch(changeSelectedPeer(selectedPeer)),
  onClearMessage: () => dispatch(changeMessage('')),
  onChangeSelectedSkillsIds: (skill) => dispatch(onChangeSelectedSkillsIds(skill)),
  initUserSkillsList: (userSkills) => dispatch(initUserSkillsList(userSkills)),
  setDefaultMessage: (message) => dispatch(changeMessage(message)),
});

const withData = connect(mapStateToProps, mapDispatchToProps);

export default withData;
