"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.skillColorPalettes = void 0;

var _colors = require("../colors");

const skillColorPalettes = {
  [_colors.ColorPalettes.Purple]: {
    primary: _colors.colors.purples[40],
    background: _colors.colors.purples[1],
    borderColor: _colors.colors.purples[10],
    emphasis: _colors.colors.purples[50],
    tint: _colors.colors.purples[5]
  },
  [_colors.ColorPalettes.Blue]: {
    primary: _colors.colors.blues[40],
    background: _colors.colors.blues[1],
    borderColor: _colors.colors.blues[10],
    emphasis: _colors.colors.blues[60],
    tint: _colors.colors.blues[5]
  },
  [_colors.ColorPalettes.Red]: {
    primary: _colors.colors.reds[40],
    background: _colors.colors.reds[1],
    borderColor: _colors.colors.reds[10],
    emphasis: _colors.colors.reds[60],
    tint: _colors.colors.reds[5]
  },
  [_colors.ColorPalettes.Pink]: {
    primary: _colors.colors.reds[30],
    background: _colors.colors.reds[1],
    borderColor: _colors.colors.reds[10],
    emphasis: _colors.colors.reds[50],
    tint: _colors.colors.reds[5]
  },
  [_colors.ColorPalettes.Orange]: {
    primary: _colors.colors.oranges[50],
    background: _colors.colors.oranges[1],
    borderColor: _colors.colors.oranges[10],
    emphasis: _colors.colors.oranges[50],
    tint: _colors.colors.oranges[5]
  },
  [_colors.ColorPalettes.Yellow]: {
    primary: _colors.colors.yellows[50],
    background: _colors.colors.yellows[1],
    borderColor: _colors.colors.yellows[10],
    emphasis: _colors.colors.yellows[60],
    tint: _colors.colors.yellows[5]
  },
  [_colors.ColorPalettes.Green]: {
    primary: _colors.colors.greens[50],
    background: _colors.colors.greens[1],
    borderColor: _colors.colors.greens[10],
    emphasis: _colors.colors.greens[60],
    tint: _colors.colors.greens[5]
  },
  [_colors.ColorPalettes.Black]: {
    primary: _colors.colors.blacks[50],
    background: _colors.colors.blacks[1],
    borderColor: _colors.colors.blacks[10],
    emphasis: _colors.colors.blacks[60],
    tint: _colors.colors.blacks[5]
  }
};
exports.skillColorPalettes = skillColorPalettes;
var _default = skillColorPalettes;
exports.default = _default;