import { compose } from 'recompose';
import { withPeerRecommendations } from '../../../hocs';
import PeerRecommendationsGridWrapper from '../components/PeerRecommendationsGridWrapper';

const withData = compose(
  withPeerRecommendations({
    peerRecommendationFilterFn: (peer) => peer.isFeedbackRecommendation,
  })
);

export default withData(PeerRecommendationsGridWrapper);
