import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@matterapp/matter-ui';
import { useDropdown, useModal } from './hooks';
import {
  Header,
  HeaderContainer,
  Message,
  Modal,
  ResetButton,
  Wrapper,
} from './styles';

export const AdminDropdown = (props) => {
  const dropdownProps = useDropdown(props);
  return <Dropdown fluid {...dropdownProps} />;
};

export const UpgradeModal = (props) => {
  const { header, dropdownProps, messageProps, modalProps, resetMessage } = useModal(props);

  return (
    <Modal
      {...modalProps}
      header={header}
      subHeader={
        <Wrapper>
          <HeaderContainer>
            <Header>Select an Admin:</Header>
          </HeaderContainer>
          <AdminDropdown {...dropdownProps} />
          <HeaderContainer spaceAbove>
            <Header>Your Message:</Header>
            <ResetButton onClick={resetMessage} />
          </HeaderContainer>
          <Message {...messageProps} />
        </Wrapper>
      }
    />
  );
};

UpgradeModal.propTypes = {
  isOpen: PropTypes.bool,
  onClickSend: PropTypes.func,
};

export default UpgradeModal;
