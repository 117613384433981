import { useEffect, useState } from 'react';
import { KUDOS_DEFAULT_TYPE } from '../../Kudos';
import Dropdown from '../../Dropdown';

const GIVE_KUDOS_CLASS = 'give-kudos-composer';

/*****************************************************************************/
/* HOOK FOR ISPUBLIC DROPDOWN                                                */
/*****************************************************************************/
export const useIsPublic = () => {
  const isPublicItem = {
    label: 'Public',
    value: 'isPublic',
  };
  const isPrivateItem = {
    label: 'Private',
    value: 'isPrivate',
  };
  const [isPublic, setIsPublic] = useState(true);
  const [isPublicDropdownValue, setIsPublicDropdownValue] = useState(
    isPublicItem.value
  );
  return {
    isPublic,
    isPublicDropdownProps: {
      items: [isPublicItem, isPrivateItem],
      onChange: (e, props) => {
        setIsPublic(props.value === isPublicItem.value);
        setIsPublicDropdownValue(props.value);
      },
      value: isPublicDropdownValue,
    },
  };
};

/*****************************************************************************/
/* HOOK FOR KUDOS CARD                                                       */
/*****************************************************************************/
export function useKudosCard(params) {
  const {
    disabledMessage,
    lockedMessage,
    maxMessageLength,
    onClickUnlock,
    selectedKudosType,
    showLockedKudos,
    totalKudosList,
  } = params;
  const isDisabled = !!disabledMessage;

  const [kudosType, setKudosType] = useState(
    selectedKudosType || KUDOS_DEFAULT_TYPE
  );

  const [message, setMessage] = useState('');
  
  useEffect(() => {
    if (selectedKudosType) {
      setKudosType(selectedKudosType);
    }
  }, [selectedKudosType]);

  return {
    clearMessage: () => setMessage(''),
    isDisabled,
    kudosCardParams: {
      className: GIVE_KUDOS_CLASS,
      message,
      onChangeMessage: (e, props) => {
        setMessage(props.value);
      },
      onChangeType: (e, props) => {
        const { value } = props;
        setKudosType(value);
      },
      maxMessageLength,
      onClickUnlock,
      showLockedKudos,
      isEditMode: true,
      isAllLocked: isDisabled,
      lockedMessage,
      type: kudosType,
      totalKudosList,
      selectedKudosType
    },
    kudosType,
    message,
    setKudosType,
    showLockedKudos,
  };
}

/*****************************************************************************/
/* HOOK FOR USER DROPDOWN                                                    */
/*****************************************************************************/
export const useUserDropdown = (params) => {
  const { 
    isOpen, 
    selectedUser, 
    selectedUsers: selectedUsersFromProps, 
    showEmailInDropdown, 
    userPlaceholder, 
    users,
    onSearch,
    isLoadingResults
  } = params;
  const [isSelectedUsersSetFromProps, setSelectedUsersSetFromProps] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [hasUsers, setHasUsers] = useState(!!users?.length);
  const hasSelectedUser = selectedUser && selectedUser.userId;
  const items = hasUsers ? Dropdown.Email.Tags.formatItems(users, showEmailInDropdown) : [];

  useEffect(() => {
    setHasUsers(!!users?.length);
  }, [users]);

  useEffect(() => {
    if (!selectedUsers.length && !isSelectedUsersSetFromProps && selectedUsersFromProps?.length) {
      setSelectedUsersSetFromProps(true);
      setSelectedUsers(selectedUsersFromProps);
    }
    if (!isOpen && isSelectedUsersSetFromProps) {
      setSelectedUsersSetFromProps(false);
    }
  }, [isOpen, selectedUsersFromProps]);

  function onSelect(_, { item }) {
    if (item) {
      setSelectedUsers([...selectedUsers, item]);
      params.onChange([...selectedUsers, item]);
    }
  }

  function onChange(_, { value: updatedList }) {
    if (updatedList.length < selectedUsers.length) {
      const newUsers = selectedUsers.filter(({ fullName }) => updatedList.find(name => name === fullName));
      setSelectedUsers(newUsers);
      if (params.onChange) {
        params.onChange(newUsers);
      }
    }
  }

  return {
    clearUsers: () => setSelectedUsers([]),
    hasUsers,
    hasSelectedUser,
    isValidUsers: selectedUsers?.length || selectedUser,
    userDropdownProps: {
      filterItems: hasUsers,
      items,
      showValuesAsSubLabels: true,
      isValidValue: () => true,
      formatTagLabel: (user) => {
        if (user) {
          return user.fullName;
        }

        return null;
      },
      onChange,
      onSelect,
      onSearch,
      placeholder: userPlaceholder,
      value: selectedUsers.map(({ fullName }) => fullName),
      isLoadingResults
    },
    selectedUsers,
  };
};

const formatUsersForSend = (selectedUsers) =>
  selectedUsers.map(({ userId }) => userId);

/*****************************************************************************/
/* HOOK FOR MODAL                                                            */
/*****************************************************************************/
export const useModal = (params) => {
  const {
    disabledMessage,
    isOpen,
    isSendingKudos,
    minMessageLength,
    onClose,
    onSendKudos,
    selectedUser,
    selectionTitle,
    showKudosSelection,
    showIsPublicDropdown,
    showLockedKudos,
    title,
    totalKudosList
  } = params;

  const [showGrid, setShowGrid] = useState(showKudosSelection);
  const { isPublic, isPublicDropdownProps } = useIsPublic();
  const {
    clearMessage,
    kudosCardParams,
    kudosType,
    message,
    setKudosType,
  } = useKudosCard(params);
  const {
    clearUsers,
    hasSelectedUser,
    isValidUsers,
    userDropdownProps,
    selectedUsers,
  } = useUserDropdown(params);

  useEffect(() => {
    clearMessage();
    clearUsers();
    if ((isOpen && showKudosSelection) || (showGrid && !showKudosSelection)) {
      setShowGrid(showKudosSelection);
    }
  }, [isOpen, showKudosSelection]);

  return {
    ...params,
    disabled: !!disabledMessage,
    footerProps: {
      canClickSend: !!(
        (selectedUsers.length || hasSelectedUser) &&
        isValidUsers &&
        message &&
        message.length >= minMessageLength &&
        !disabledMessage
      ),
      onClickSend: (e) => {
        onSendKudos(e, {
          ...params,
          isPublic: showIsPublicDropdown ? isPublic : false,
          note: message,
          members: selectedUsers,
          type: kudosType,
          receiverUserIds: hasSelectedUser
            ? [selectedUser.userId]
            : formatUsersForSend(selectedUsers),
        });
      },
      onClickCancel: onClose,
      sendIsLoading: isSendingKudos,
    },
    gridProps: {
      expandable: false,
      isCompact: true,
      itemHeight: 80,
      onClickKudos: (e, props) => {
        setKudosType(props.type);
        setShowGrid(false);
      },
      showLockedKudos,
      unlockAllKudos: true,
      totalKudosList
    },
    hasSelectedUser,
    headerProps: {
      hideBack: showGrid || !showKudosSelection,
      onClickBack: () => setShowGrid(true),
      onClickClose: onClose,
      title: showGrid ? selectionTitle : title,
    },
    isPublicDropdownProps,
    kudosCardParams,
    modalProps: { isOpen, onClose },
    showGrid,
    showIsPublicDropdown: showIsPublicDropdown && !disabledMessage,
    userDropdownProps,
  };
};
