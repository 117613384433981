import React from 'react';
import BasePath from './BasePath';

const DEFAULT_HEIGHT = 292;
const PATH_LENGTH = 639;

const RightToLeft2 = React.forwardRef((props, ref) => (
  <BasePath
    {...props}
    ref={ref}
    height={DEFAULT_HEIGHT}
    pathLength={PATH_LENGTH}
    pathCode={
      `M450.687,8.81l0.197,141.08c-0.107,6.55-2.83,12.452-7.165,16.717s-10.281,6.893-16.832,6.893H329
      c-6.628,0-12.628,2.688-16.971,7.031C307.686,184.875,305,190.875,305,197.5v11c0,6.625-2.686,12.625-7.029,16.969
      c-4.343,4.344-10.343,7.031-16.971,7.031H161c-6.628,0-12.628,2.688-16.971,7.031S137,249.875,137,256.5v2
      c0,6.625-2.686,12.625-7.029,16.969S119.628,282.5,113,282.5H34`
    }
  />
));

export default RightToLeft2;
