import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '@matterapp/matter-theme';

const sharedStyles = `
  background: none;
  border: none;
  outline: none;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;

  ${({ disabled }) => {
    if (disabled) {
      return `cursor: not-allowed;`;
    }
    return `cursor: pointer;`;
  }}
`;

export const ButtonBase = styled.button`
  ${sharedStyles}
`;

const LinkBase = styled.a`
  ${sharedStyles}
`;

export const ClickableLink = styled(Clickable)`
  color: ${theme.colors.blues[50]};
  transition: color ${theme.transitions.times.default};

  &:hover {
    color: ${theme.colors.purples[60]};
  }
  &:active {
    text-decoration: underline;
  }
`;

/**
 * Utility component that serves as the basis of clickable elements.
 * Determines the base component as a button or link if `to` prop is passed.
 */
export function Clickable(props) {
  const { 
    disabled, 
    onClick = () => {},
    href, 
    to, 
    rcLabel, 
    ...otherProps
  } = props;

  function handleClick(e) {
    if (!disabled && typeof onClick == 'function') {
      onClick(e, props);
    }
  }

  if (to) {
    return <Link to={to} {...otherProps} />;
  }
  if (href) {
    return <LinkBase href={href} {...otherProps} />;
  }

  return (
    <ButtonBase
      {...otherProps}
      disabled={disabled}
      onClick={handleClick}
      data-rc={rcLabel}
    />
  );
}
