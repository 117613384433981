import React from 'react';
import { SVG, Path } from './consts';

const TeamMembers = (props) => (
  <SVG {...props}>
    <Path
      d={`M138.3,27.7H27.7c-7.6,0-13.8,6.2-13.8,13.8v83c0,7.6,6.2,13.8,13.8,13.8h110.7c7.6,0,13.8-6.2,13.8-13.8v-83
          C152.2,33.9,146,27.7,138.3,27.7z M31.1,50.5h31.1c2.7,0,4.8,2.2,4.8,4.8s-2.2,4.8-4.8,4.8H31.1c-2.7,0-4.8-2.2-4.8-4.8
          S28.5,50.5,31.1,50.5z M55.3,115.5H31.1c-2.7,0-4.8-2.2-4.8-4.8s2.2-4.8,4.8-4.8h24.2c2.7,0,4.8,2.2,4.8,4.8S58,115.5,55.3,115.5z
          M69.2,87.8h-38c-2.7,0-4.8-2.2-4.8-4.8s2.2-4.8,4.8-4.8h38c2.7,0,4.8,2.2,4.8,4.8S71.8,87.8,69.2,87.8z M143.2,124.5h-9.7v-6.9
          c0-12.6-10.2-22.8-22.8-22.8c-12.6,0-22.8,10.2-22.8,22.8v6.9h-9.7v-6.9c0-12.2,6.7-22.8,16.6-28.4c-5.9-4.7-9.7-11.9-9.7-20.1
          c0-14.1,11.5-25.6,25.6-25.6s25.6,11.5,25.6,25.6c0,8.1-3.8,15.4-9.7,20.1c9.9,5.6,16.6,16.2,16.6,28.4V124.5z M126.6,69.2
          c0,8.8-7.1,15.9-15.9,15.9s-15.9-7.1-15.9-15.9c0-8.8,7.1-15.9,15.9-15.9S126.6,60.4,126.6,69.2z`}
    />
  </SVG>
);

export default TeamMembers;
