import styled from 'styled-components';
import Base from './Default';

const getColorStyles = ({ color, disabled, isLoading, theme, useDefaultNormalStateColor }) => {
  const baseColor = theme.colors[`${color}s`];

  if (isLoading) {
    return `
      background: transparent;
      border: none;
      color: ${baseColor ? baseColor[20] : theme.colors.blues[20]};
      & ${Base.loaderDataRoleSelector} {
        background: none;

        & svg path {
          fill: ${baseColor ? baseColor[50] : theme.colors.blues[50]};
        }
      }
    `;
  }

  if (!disabled) {
    return `
      background: transparent;
      border: none;
      color: ${useDefaultNormalStateColor ? theme.colors.blues[50] : 'inherit'};

      & ${Base.loaderDataRoleSelector} {
        background: none;
      }

      &:hover {
        background: transparent;
        color: ${baseColor ? baseColor[40] : theme.colors.purples[50]};
      }
      &:active {
        background: transparent;
        color: ${baseColor ? baseColor[60] : theme.colors.blacks[80]};
      }
    `;
  }
  return `
    border: none;
  `;
};

const LinkButton = styled(Base)`
  ${getColorStyles}
`;

export default LinkButton;
