import React from 'react';
import BackgroundAnimation, { ANIMATIONS } from '../../BackgroundAnimation';
import ColoredBackground from '../../ColoredBackground';
import gsap from 'gsap';
import * as S from './styles';

export default class Variant5Background extends BackgroundAnimation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  /**
   * Moves the clouds in.
   * @returns { Object } The animation timeline.
   */
  moveCloudsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 1 };
    tl.to(this.topCloudRef, { x: 140 });
    tl.to(this.bottomCloudRef, { x: -120 });

    tl.to(this.topCloudRef, resetProps, '<');
    tl.to(this.bottomCloudRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the clouds out.
   * @returns { Object } The animation timeline.
   */
  moveCloudsOut = () => {
    const tl = gsap.timeline({ defaults: { duration: 0.3 } });
    const resetProps = { x: 0, y: 0, duration: 0 };
    tl.to(this.topCloudRef, resetProps);
    tl.to(this.bottomCloudRef, resetProps);

    tl.to(this.topCloudRef, { x: -90 }, '<');
    tl.to(this.bottomCloudRef, { x: 110 }, '<');
    return tl;
  };

  /**
   * Moves the planets in.
   * @returns { Object } The animation timeline.
   */
  movePlanetsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { opacity: 1, x: 0, duration: 1 };
    tl.to(this.leftPlanetRef, { x: -120, opacity: 0 });
    tl.to(this.rightPlanetRef, { x: 120, opacity: 0 });

    tl.to(this.leftPlanetRef, resetProps, '<');
    tl.to(this.rightPlanetRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the planets out.
   * @returns { Object } The animation timeline.
   */
  movePlanetsOut = () => {
    const tl = gsap.timeline({ defaults: { opacity: 0, duration: 0.6 } });
    const initialProps = { opacity: 1, x: 0, duration: 0 };
    tl.to(this.leftPlanetRef, initialProps);
    tl.to(this.rightPlanetRef, initialProps);

    tl.to(this.leftPlanetRef, { x: -120 }, '<');
    tl.to(this.rightPlanetRef, { x: 120 }, '<');
    return tl;
  };

  /**
   * Moves the rings in.
   * @returns { Object } The animation timeline.
   */
  moveRingsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 2, opacity: 0, transformOrigin: 'center' };
    const endProps = { scale: 1, opacity: 1, duration: 0.5 };
    tl.to(this.leftRingRef, initialProps);
    tl.to(this.rightRingRef, initialProps);
    tl.to(this.bottomRingRef, initialProps);

    tl.to(this.leftRingRef, endProps, '<');
    tl.to(this.rightRingRef, endProps, '<0.3');
    tl.to(this.bottomRingRef, endProps, '<-0.1');
    return tl;
  };

  /**
   * Moves the dots in.
   * @returns { Object } The animation timeline.
   */
  moveDotsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 0.7 };
    const dotRefs = {
      topDot1Ref: { x: -44, y: 41 },
      topDot2Ref: { x: -3, y: 63 },
      topDot3Ref: { x: 85, y: 58 },
      topDot4Ref: { x: 53, y: 12 },
      topDot5Ref: { x: -47, y: 18 },
      topDot6Ref: { x: -37, y: -35 },
      topDot7Ref: { x: -48, y: 28 },
      topDot8Ref: { x: 19, y: 32 },
      topDot9Ref: { x: -27, y: 44 },
      topDot10Ref: { x: 11, y: 49 },
      leftDot1Ref: { x: 67, y: -62 },
      leftDot2Ref: { x: 47, y: 32 },
      bottomDot1Ref: { x: 17, y: -36 },
      bottomDot2Ref: { x: -77, y: -19 },
      rightDot1Ref: { x: -52, y: 9 },
      rightDot2Ref: { x: 45, y: -39 },
      rightDot3Ref: { x: -53, y: -41 },
      rightDot4Ref: { x: 35, y: -28 },
      rightDot5Ref: { x: -22, y: -35 },
      rightDot6Ref: { x: -36, y: 33 },
      rightDot7Ref: { x: 25, y: 37 },
    };
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], dotRefs[key]);
    });
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], resetProps, '<');
    });
    return tl;
  };

  playAnimation = () => {
    const { animation } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    if (animation !== ANIMATIONS.SHOW) {
      if (animation === ANIMATIONS.OUT) {
        tl.add(this.moveCloudsOut(), '<');
        tl.add(this.movePlanetsOut(), '<');
      } else {
        tl.add(this.moveCloudsIn(), '<');
        tl.add(this.movePlanetsIn(), '<');
        tl.add(this.moveRingsIn(), '<');
        tl.add(this.moveDotsIn(), '<0.3');
      }
    }
    this.tl = tl;
    return tl;
  };

  render() {
    return (
      <ColoredBackground {...this.props}>
        <S.TopCloud ref={(ref) => (this.topCloudRef = ref)} />
        <S.BottomCloud ref={(ref) => (this.bottomCloudRef = ref)} />
        <S.TopDot1 ref={(ref) => (this.topDot1Ref = ref)} />
        <S.TopDot2 ref={(ref) => (this.topDot2Ref = ref)} />
        <S.TopDot3 ref={(ref) => (this.topDot3Ref = ref)} />
        <S.TopDot4 ref={(ref) => (this.topDot4Ref = ref)} />
        <S.TopDot5 ref={(ref) => (this.topDot5Ref = ref)} />
        <S.TopDot6 ref={(ref) => (this.topDot6Ref = ref)} />
        <S.TopDot7 ref={(ref) => (this.topDot7Ref = ref)} />
        <S.TopDot8 ref={(ref) => (this.topDot8Ref = ref)} />
        <S.TopDot9 ref={(ref) => (this.topDot9Ref = ref)} />
        <S.TopDot10 ref={(ref) => (this.topDot10Ref = ref)} />
        <S.LeftPlanet ref={(ref) => (this.leftPlanetRef = ref)} />
        <S.LeftDot1 ref={(ref) => (this.leftDot1Ref = ref)} />
        <S.LeftDot2 ref={(ref) => (this.leftDot2Ref = ref)} />
        <S.LeftRing ref={(ref) => (this.leftRingRef = ref)} />
        <S.RightRing ref={(ref) => (this.rightRingRef = ref)} />
        <S.RightPlanet ref={(ref) => (this.rightPlanetRef = ref)} />
        <S.BottomDot1 ref={(ref) => (this.bottomDot1Ref = ref)} />
        <S.BottomDot2 ref={(ref) => (this.bottomDot2Ref = ref)} />
        <S.BottomRing ref={(ref) => (this.bottomRingRef = ref)} />
        <S.RightDot1 ref={(ref) => (this.rightDot1Ref = ref)} />
        <S.RightDot2 ref={(ref) => (this.rightDot2Ref = ref)} />
        <S.RightDot3 ref={(ref) => (this.rightDot3Ref = ref)} />
        <S.RightDot4 ref={(ref) => (this.rightDot4Ref = ref)} />
        <S.RightDot5 ref={(ref) => (this.rightDot5Ref = ref)} />
        <S.RightDot6 ref={(ref) => (this.rightDot6Ref = ref)} />
        <S.RightDot7 ref={(ref) => (this.rightDot7Ref = ref)} />
      </ColoredBackground>
    );
  }
}
