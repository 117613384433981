import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C8.73638 0 9.33333 0.596954 9.33333 1.33333V6.66667H14.6667C15.403 6.66667 16 7.26362 16 8C16 8.73638 15.403 9.33333 14.6667 9.33333H9.33333V14.6667C9.33333 15.403 8.73638 16 8 16C7.26362 16 6.66667 15.403 6.66667 14.6667V9.33333H1.33333C0.596954 9.33333 0 8.73638 0 8C0 7.26362 0.596954 6.66667 1.33333 6.66667H6.66667V1.33333C6.66667 0.596954 7.26362 0 8 0Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#4584FF',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
