import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const ColoredPath = styled.path`
  fill: currentColor;
`;

export const TopCloud = styled(ColoredPath).attrs({
  d: `M2227,1241.9c-10.8-0.6-20.4,4.1-31.3,3.5c-6.9-14.3-19.8-9.7-24-18.8
	c-6.2-33.9-42.6-44.5-77.4-31.7c-4.1,0.7-7.7-1.4-11.1-3.1c-9.8-3.5-21.2-2.2-31.2-0.5c-2.9,1.5-8.2,3.8-9.1-0.6
	c-4.3-8.4-5-17.8-12.5-24.7c-5.2-5.7-12.9-10.5-21.3-11.8c-8.8-2.5-17.5-7.4-27.2-5.6c-2.4,0.5-3.9-0.3-5-2
	c-8.3-10.8-33.2-22.4-48.5-22.2c-6,0.6-12.4,3.3-18.3,1.6c-11.2-5-25-6.3-36.8-1.8c-5.5,1.4-9.4,8.1-15.7,4.9
	c-6.2-5.8-25.1-6.7-24.2-12.2c0.7-23.4-9.9-39.8-30-55.3c-15.1-8.1-33.1-14.8-51.3-15.4c-12.9-1.4-27.1,8.1-37.9-1.4
	c-0.5-0.4-1.1-0.9-1.7-1c-3.9-0.6-6.4-3.5-10.5-3.7c-35.5-5.6-27.9,11.8-44.5,2.2c-8.1-4.6-18.1-10-28-6.3c-2.4,0.8-4.8,1.4-7.4,1.1
	c-1.2,0.1-2.4,0.3-3.6,0.5c-1.6-0.4-3.2-0.7-4.9-0.9c-6.8-2.2-16.7,1.5-22.3-1.3c-1.1-2-3.5-3.4-3.4-5.9c0-0.6-0.5-1.4-1-1.9
	c-5.8-6.4-12.1-13.2-20.3-17.4c-4.7-2.4-11.7-3-14.9-7c-0.9-2.5,0-5.4-2.2-7.6c-0.6-5.8-7.6-8.4-12.7-10.8c-4.6-1.1-10.8,0.5-13.9-3
	c-5.7-5.5-11.1-11.6-17.7-16.3c-8.8-4.6-19.1-6.1-29.4-7c-10.9-1.2-17.1,4.5-27.6-2c-7.7-5.5-17.2-8.9-27.3-9.8
	c-5.3-0.4-10.4-1.5-15.6,0c-7.8,1.2-15.1,4.6-22.7,5.7c-4.9-1.6-5.8-7.6-10.5-9.7c-2.9-1-5.3-2.9-7.2-5c0.5-0.1,0.7-0.1,1.6-0.2
	c-4.8-2.8-8.7-5.2-13.8-6.7c-1.9-1.2-2.4-2.3-1.5-4.1c1.1-4.6,1.8-10.6-0.5-14.9c-1.3-3.2-2.6-6.6-4.6-9.5c-1.1-1.6-3.3-2.7-2.6-5
	c-2.2-4.6-4.5-9.4-7.2-13.9c-1.8-3.8-7.1-4.3-10.7-6.4c-13.4-7.7-29.7-10.7-45.4-8.3v0c-13.6,2.8-27.3,6.1-39.6,12.7
	c-11.6,10.2-25,17.6-36.6,27.6c-4.7,3.4-11.5-6.1-17.8-4.4c-6.4,1.1-13.4-0.8-19.4,2.1c-1.1,0.7-2.1,0.5-3-0.4
	c-20.8-30.6-62.7-12.1-79.7,13c-30.3-7-44.7-7.9-71.4,11c-6.9,5.9-16.4,11.5-19.4,20.4c-2.6,9.2-7.9,17.3-10.3,26.7
	c-1,3.2-2.9,4.3-5.9,4.3c-5.9,0-10.2,4.7-15.3,6.9c-6.8,3-13,7.2-17.5,13.2c-1.6-0.6-2.2-1.4-3.1-2.6c-4.2-6.1-10.6-9.1-17.3-11.2
	c-11.5-3-23.3-6-35.2-3.3c-10.1,2.6-20.5,4.9-28.7,11.7c-1,0.8-1.8,0.9-3,0.4c-27-13.4-64.2-6.8-79.1,20.8
	c-14-11.4-35.6,0.7-41.2,15.8c-1.5,3.7-1.8,4.3-5.7,4.4c-16.8,0.2-27.4,13.8-36.4,26.2c-2.3,2.3-2.2,7.2-5.7,8.1
	c-20.5-18.2-30.6-15.4-55.5-9.4c-1,2.5-21.5-13.7-20.2-16c-0.2-8.2-3.3-15.8-5.6-23.5c-2.7-7.9-10.6-12.8-16.5-18.2
	c-16.4-8.3-36-11.5-54-7.7c-4.9,1.5-9.7,5.2-14.9,5.2c-11-4.1-22.5-7.6-34.4-6.4c-5.7-0.8-6.8-12-10.8-16.1
	c-12.3-17.2-32.5-31.8-54.4-30.4c-6.1,1.8-3.9-5.4-7.8-7.9c-6.2-7.9-14.8-11.6-24.3-13.7c-7.1-0.2-14.4-2.3-21.5-0.6
	c-4.2,1.3-7.9,0.2-10.8-3.2c-4.4-3.7-9.7-6.3-15-8.6c-22.4-10.5-25,12.3-40.7,2.5c-5.8-2.5-9.8-7.5-16.1-8.4
	c-3.3-52.7-47-88.3-99.2-73.7c-6,0.5-7.7-7.6-11.8-10.4c-6.5-2.9-18.7-8.7-25.5-6.5c-7.8,2.8-16.1,5.1-21.8,11.5
	c-12.1-9.7-28.1-17.7-43.6-11.4c-2.4,0.4-2.9-0.1-3.3-2.5c-1.8-8.3-2.9-16.8-5.3-25c-4.9-9.9-11.2-19.1-16.6-28.8
	c-7.5-19.6-19.8-36.9-39.3-45.8c-3.8-2.9-8.6-0.2-12.5-2.6c-2.6-1.4-5.1-1.6-7.5-0.6V712c-13.4-9.5-28.3-19.9-40.3-2.3
	c-8.6-6.6-14.7-15.8-23.9-20.8c-21.1-10.5-44.9-16.7-67.9-15.9c-19.6,10-42.7,17.5-55.9,37.8c-15.2-5.3-36.3,1.6-38.4,1.1
	c-13.7-3.1-27.2,5-38.6,12.4c-17.7,7-18.7,30.6-30.2,29.5c-30-4-65.3-2.2-85,27.3c-11.1,13.8-18.7,31.6-21.9,49.8
	c-1.5,6.3,3.9,16-3.1,19.3c-10.6,3.3-25.7,10.8-32.5,20.8c-3.7,6.6-6.8,13.4-8.8,20.8c-0.4,1.6-1.1,3-2.9,3.9
	c-12.3-11-25-23-41.6-21.9c-24.3-7.9-46.4,7.2-65.9,21.9c-5.6,3.1-17.7-5.5-24.5-6.5c-9.9-1.3-20.7,0.5-30.4,3.1
	c-20.1,9-26.3,12.7-35.9,35.7c-0.8,2.2-1.7,2.4-3.7,1.3c-12.2-6.9-25,3.2-34.8,10.8c-4.6,3.7-8.9,8.1-13.3,12.2
	c-3.6,3.5-0.9,8.9-0.2,13.3c1.1,5-2.4,8.9-6.7,8.8c-19.9,2.4-36.8,20.2-43.8,40.7c-24.1-2-60.3-2.9-78.7,16.5v0.5
	c-23,21.9-40.6,42.7-35.9,79.1c0.5,7-7.3,4.6-11.1,6.5c-6.9,0.5-22.3,17.1-25.9,14.2c-2.2-5.2-7-6.6-10.9-9.4
	c-0.5-0.3-1.1-0.5-1.4-1c-1.6-3.1-4.5-3.5-7.2-4.6c-35.2-15.6-34.3,15.6-43.4,8c-14.8-8.5-34.1-7-49,1.3c-15.7,6.9-17.6,8.2-26,25.1
	c-35.6-12.3-71.9,16.1-83.2,54.1c-14.2-5.7-29.3-8.4-43.4-1c-3.2,0.9-5.4,5.5-9,4.6c-15.6-3.4-30.8-11.4-47-8.7
	c-27.9,2.6-54.9,24.2-57.5,57.3c-0.4,1.4-0.3,62-0.3,63.6L0,1268.8v0h1280l1280,52.4v-1.9c0,0-9.8-2.6-26.8-8.6
	c-13.5-4.8-28.9-12-52.8-13.3c-11.2-3.3-21.1-17.2-45.1-13.9c-8-8-13.2-14.9-37.9-19.2c-16.6-2.8-30.3,3.3-51.3-1.3
	c-14.8-22.2-38.9-24.4-53.9-20.8C2238.1,1224.3,2229.8,1241.5,2227,1241.9z M1517.3,983.6c-0.2-0.3-0.4-0.7-0.6-1
	c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0.4,0.3,0.8,0.4,1.1C1517.5,983.5,1517.4,983.5,1517.3,983.6z M1965.3,1143.4
	c-0.4-0.4-0.7-0.8-1.1-1.2c0.2-0.1,0.4-0.3,0.6-0.4c0.4,0.4,0.8,0.8,1.2,1.1C1965.8,1143.1,1965.5,1143.3,1965.3,1143.4z`,
})``;

export const BottomCloud = styled.path.attrs({
  d: `M-1138.8,1273c-0.8,0-1.5,0.1-2.3,0.2C-1140.4,1273-1139.6,1273-1138.8,1273z M2560,1321.2
	l-975.7,0v0H1213v0c0,0-849.8,0-1146.1,0v0h-371.3v0l-975.7,0l13.6-5.6c10.6-4.7,23.5,1.7,32.3-6.1c23.2-10.1,40.1,3.1,44.4-2.1
	c4.5-7.7,14-12.2,19.6-18.2c6.1-9.5,17.1-14.7,29-16c-0.3,0.1-0.7,0.2-1,0.4c1.4,0.4,3.6,0.1,4.3-0.5c-0.3,0-0.7,0-1,0
	c6.5-0.4,13.2,0.3,19.6,2.2c27.5-12,29.2,4.4,46.4-0.1c25.3-9.6,33.3,10.1,42.5,3.6c4.7-20.5,43.2-29.5,60-11.8
	c8.9,1,17.9-6.2,27.7-3.3c25.1,2.7,9.4-10.6,53.3-2.7c3-5.7,7.2-12.7,15.5-13.9c2.1-0.4,2.1-0.4,2.4-2c3.6-9.7,16.4-16.6,28.1-19.4
	c9.5-1.9,19.6,1,28.8,1c12.8-3.6,26,0.8,38.4,1.3c9.7-3.7,21.1-3.2,30.7,0.6c6.8-2.5,14.9-1.2,20.2,3.1c2.1,2.3,5.4-1.6,8.5-1.8
	c11.9-3.3,23.1,2.7,31.3,9c7.7-19.4,39.8-22.8,55.7-7.5c2.8,2.8,3.9,6.5,4.9,9.9c10.1-1.8,18.1,11.6,25.6,3.6
	c5.5-3,13.7-3.1,19.2,0.2c7.4-16.9,28.6-23.4,49.2-23.6c14.1,1.2,21.2,8.8,34-0.8c21.3-12.5,53.4-11.8,72.7,2.9
	c9.9,8.2,3.5-18.6,39.1-24.1c5.6-1.9,1.3-7.5,2.5-11.2c2.9-12.3,11-23.9,22.1-32.7c10.6-9.2,27.1-12.4,42.2-12.9
	c6.2-0.8,14,1.5,20.4,1.1c1.1-2,2.5-3.8,4.3-5.2c9-12.1,23.6-18.1,38.5-18.7c0,0,0,0,0,0c7-0.3,14,0.5,20.6,2.4
	c2.9,0.6,6.1,3.1,9.2,4.6c0.2,0.1,0.5,0.2,0.7,0.3c2.9,1.3,5.6,1.8,7.9-0.8c10.5-7.6,24.1-8.2,35.6-2.5c8.8,3.7,12.8-15.6,21.4-20.4
	c13.3-12,30.8-17.7,48.8-16.1c-0.5,0.2-0.8,0.2-1.1,0.3c11.2-0.9,22.1,3.3,32,8.5c17.8-19.8,51.8-28.6,76.5-18
	c16.1,2.7,30.3,9.4,42.8,19.5c9.1-1.5,19.1-1.2,27.8,2.3v2.3c6.5-6.4,10.7-15.4,42.3-4.7c11.3-7.7,25.1-14.4,38.5-5.1
	c0.3-3.5,2.2-5.1,3.5-7.8l0,0.1c-0.3-3.1,9.3-12.3,5.3-7.3c5.3-5.5,14.6-6.9,22.6-2.6c9.5,2.2,19.2-15.7,30.7-9.3
	c1.5,1.1,1-0.6,1.8-1.4c-0.3-0.2-0.7-0.4-1-0.6c11.5,0.2,22.9,0.8,31.9,8.7c0.1-0.3,0.1-0.6,0.2-0.9c11.5,13.6,13.3-20.9,34.7-28.9
	c0.1,0.5,0.2,0.9,0.3,1.5c1.7-3.6,4.6-4.3,7.6-6.4c-0.1,0.5-0.2,0.9-0.3,1.2c3.1-0.3,3-0.8,2.9-2.8c3-1.6,6.1-3.2,9.3-5
	c1.3,4.7,7.2-4.8,10.7-3.5c2.7-3.6,7.9,0.4,9.2-3.7c9,0.5,24.2-6.6,35.7-2.1c0-0.6,0-0.9,0-1.4c4.6,2.8,10.7,4.2,16.1,4
	c4.3,4.2,6.2-8.3,8.7-10.9c15.7-25.1,47.6-28.4,70.7-12.3c5.4,3,11.2,8,16.9,3.1c1.7-2.5,6.4-1.6,6.9-4.7c0.8-1.9,1.3-0.5,0.8,1.2
	c27.1-20.6,68.7,5,87.2,16.5c0.8,1,1.5,1.9,2.4,3c-0.7-0.1-1-0.1-1.4-0.1c0.4,1.2,1.1,1.5,3,1.3c-0.4,2.1,1.2,2.5,2.7,1.6
	c0.9,3.8,4.3,3.3,5.7,7.1c4.2,3,6.4,13,11.3,10.9c6.8-8.2,21.1-18.3,38.5-20c24.5-2.5,46.3,9.2,46.3,9.2s12.5,7.8,18,18.7
	c11.3,5.8,28.6-0.9,38.4,14.3c8.1-12.5,23.3-19.4,35.7-11.4c3.1,3.1,4.4,11.1,9.2,4.9c4.7-5.8,12.2-13.8,18.1-16.7
	c18.9,0.5,38,3.8,51.7,17.9c10.2-13.2,26.6-24.1,43.7-17.1c7.9,2.4,10.7,15.5,20.4,11.6c14-5,27.9-10,43-9.3
	c52.5-5.7,80.1,46.1,88.2,87.8c9.2,2.7,18.6,5,26.6,10.7c3.3,1.2,8.4,11,11.3,6.2c13.7-19.2,33.9-24.2,52.3-7.9
	c1.5,1.4,2.8,1.8,4.6,1.1c21.6-10.4,46-4.5,65,8.4c8.6,3.5,8.8,12.2,18.2,10.4v0.3c8.7-2.5,18.7-2.8,27.8-1.7
	c12.5-7.3,26.6-12.3,42.8-14.2c24.7-7.7,58.7-1.3,76.5,13.1c9.9-3.8,20.7-6.9,32-6.2c-0.3-0.1-0.6-0.1-1.1-0.2
	c17.9-1.2,35.5,2.9,48.8,11.7c8.6,3.5,12.6,17.5,21.4,14.8c11.5-4.2,25.1-3.7,35.6,1.8c2.3,1.8,5.1,1.5,7.9,0.6
	c0.2-0.1,0.5-0.2,0.7-0.3c3.1-1.2,6.3-2.9,9.2-3.4c6.6-1.4,13.6-2,20.6-1.8v0c14.9,0.5,29.5,4.8,38.5,13.6c1.8,1.1,3.2,2.3,4.3,3.8
	c6.5,0.3,14.2-1.4,20.4-0.8c15.1,0.3,31.6,2.6,42.2,9.3c11.1,6.4,19.2,14.8,22.1,23.8c1.2,2.6-3.1,6.7,2.5,8.1
	c35.6,4,29.2,23.5,39.1,17.6c19.2-10.7,51.4-11.2,72.7-2.1c12.8,7,19.9,1.5,34,0.6c20.6,0.1,41.8,4.9,49.2,17.1
	c5.5-2.4,13.7-2.3,19.2-0.2c7.4,5.9,15.4-3.9,25.6-2.6c0.9-2.5,2.1-5.2,4.9-7.2c15.8-11.1,47.9-8.6,55.7,5.5
	c8.2-4.6,19.5-9,31.3-6.6c3.1,0.2,6.4,3,8.5,1.3c5.2-3.1,13.4-4.1,20.2-2.2c9.5-2.8,21-3.1,30.7-0.5c12.4-0.4,25.6-3.6,38.4-1
	c9.2,0,19.3-2.1,28.8-0.8c11.6,2,24.5,7,28.1,14.1c0.3,1.2,0.3,1.2,2.4,1.5c8.3,0.9,12.5,6,15.5,10.1c43.9-5.8,28.2,3.9,53.3,1.9
	c9.8-2.1,18.7,3.1,27.7,2.4c16.8-12.9,55.3-6.3,60,8.6c9.3,4.7,17.2-9.6,42.5-2.6c17.3,3.3,18.9-8.7,46.4,0.1
	c6.4-1.3,13.1-1.9,19.6-1.6c-0.3,0-0.7,0-1,0c0.7,0.5,2.9,0.6,4.3,0.4c-0.3-0.1-0.7-0.2-1-0.3c11.9,0.9,22.9,4.7,29,11.6
	c5.6,4.4,15.1,7.6,19.6,13.2c4.3,3.8,21.2-5.8,44.4,1.5c8.8,5.7,21.7,1,32.3,4.4L2560,1321.2z M-888.3,1302.5
	c-0.1-0.1-0.2-0.3-0.2-0.3c-0.1,0-0.3,0.1-0.4,0.2C-888.8,1302.8-888.6,1302.6-888.3,1302.5z M2169,1307.6c-0.2,0-0.3-0.1-0.4-0.1
	c-0.1,0-0.1,0.1-0.2,0.2C2168.6,1307.7,2168.8,1307.8,2169,1307.6z M2418.8,1286.2c0.8,0,1.5,0.1,2.3,0.1
	C2420.4,1286.2,2419.6,1286.2,2418.8,1286.2z`,
})`
  fill: ${theme.colors.white};
`;

export const TopDot1 = styled(ColoredPath).attrs({
  d: `M529.6,130.5c0,3.1-2.2,5.4-5.2,5.4c-2.8,0-5-2-5-4.8c-0.1-3.2,2.5-6.2,5.2-6.2
	C527.5,124.9,529.6,127.2,529.6,130.5z`
})``;
export const TopDot2 = styled(ColoredPath).attrs({
  d: `M661.4,172.2c-3.7-1.2-7.3-0.6-10.5-2.9c-4.7-3.5-7.9-11.2-6.4-16.1c1.3-4.4,3.2-8.3,7.1-11.1
	c4.2-3,8.7-2.3,12.5-0.5c8.5,4.1,13.3,14.5,7.8,23.6C669.3,169.3,665.4,170.9,661.4,172.2z`
})``;
export const TopRing = styled(ColoredPath).attrs({
  d: `M809.1,58c7.6,0,14.5,7.2,14.5,14.9c0,8-7.4,15.2-15.6,15.1c-6.3-0.1-13.1-5.9-13-16.8
	C795.1,64.7,802.2,58,809.1,58z M802,71.8c0.2,5.2,3.1,8.8,7.1,8.7c4.1-0.1,7.8-3.8,7.7-7.6c-0.2-5.1-4-7.2-8.1-7.6
	C805.5,64.9,801.9,68.8,802,71.8z`
})``;
export const TopLeftDot1 = styled(ColoredPath).attrs({
  d: `M51.5,108.7c-0.5,8.5-0.6,14.8-4,20.1c-4,6.3-8.8,11.6-15.8,15.2c-8,4.2-16,3.9-24.4,3.2
	c-8.3-0.7-14.1-5.7-20.5-9.9c-0.9-0.6-2.5-1-3.5-0.7c-2.3,0.7-4.6,0.8-5.5-1.5c-0.6-1.5-0.3-4.1,0.7-5.4c1.5-2,1.4-3.5,0.5-5.5
	c-5.4-12.1-3.1-23.5,3.5-34.4C-13,82.2-6.1,77.2,2.1,74.6c4.4-1.4,9.5-1.3,14.3-1.2c15.6,0.5,25.8,8.9,31.5,22.9
	C49.8,100.9,50.7,106,51.5,108.7z`
})``;
export const TopLeftDot2 = styled(ColoredPath).attrs({
  d: `M133.8,125.3c0,2.1-1.9,3.8-4.2,3.8c-1.9,0-3.5-1.9-3.5-4.1c0-2.2,1.7-4.1,3.5-4.1
	C131.8,120.8,133.8,123,133.8,125.3z`
})``;
export const TopRightDot = styled(ColoredPath).attrs({
  d: `M1209,155.7c0.1-5.5,2.7-8.1,8-8c4.1,0.1,7.1,3.4,7,7.8c-0.1,4.6-2.2,7.1-7.5,7.4
	C1212.1,163,1208.9,159.7,1209,155.7z`
})``;
export const LeftRing1 = styled(ColoredPath).attrs({
  d: `M49.7,346c11.5-0.1,19.5,9.7,19.1,20c-0.3,7.4-8.3,16.9-18.1,16.1
	c-10.4-0.8-18.1-7.6-17.6-18.6C33.5,353.6,41,345.7,49.7,346z M51,376.4c6.4,0.1,12-5.5,12.1-11.2c0.1-7.1-5.8-13.1-12.9-13.2
	c-5.1-0.1-11.5,7.3-11.9,13.1C38.1,370.7,46,376.5,51,376.4z`
})``;
export const LeftRing2 = styled(ColoredPath).attrs({
  d: `M63.7,469c9.7,0.2,14.1,5.4,14.3,12.4c0.2,6.9-5.4,14.5-13.1,14.2
	c-7.7-0.3-13.8-5.2-13.9-12.9C50.9,475.1,55.6,469.4,63.7,469z M65.5,488.3c2.2,0.1,5.3-3.5,5.4-6.1c0.1-2.3-3.4-5.8-5.8-6
	c-3.1-0.2-6.3,2.9-6.5,6.3C58.3,485.8,61.1,488.1,65.5,488.3z`
})``;
export const LeftDot = styled(ColoredPath).attrs({
  d: `M28.6,433c2.3,0.1,4.3,2,4.3,4.3c0,2.5-1.8,4.6-4,4.6c-2.8,0-4.9-2-4.8-4.6C24.1,434.8,26.1,432.9,28.6,433z`
})``;
export const CenterDot = styled(ColoredPath).attrs({
  d: `M751.2,475.1c-2.4-0.5-4.1-1.3-3.7-3.1c0.2-1.1,1.8-2.5,3-2.7c1.6-0.3,3.2,1,2.9,2.9C753.2,473.3,751.8,474.2,751.2,475.1z`
})``;
export const RightRing = styled(ColoredPath).attrs({
  d: `M1249.7,496c11.5-0.1,19.5,9.7,19.1,20c-0.3,7.4-8.3,16.9-18.1,16.2
	c-10.4-0.8-18.1-7.6-17.6-18.6C1233.5,503.6,1240.9,495.7,1249.7,496z M1251,526.4c6.4,0.1,12-5.5,12.1-11.2
	c0.1-7.1-5.8-13.1-12.9-13.2c-5.1-0.1-11.5,7.3-11.9,13.2C1238.1,520.7,1246,526.5,1251,526.4z`
})``;
export const RightDot1 = styled(ColoredPath).attrs({
  d: `M1199.7,556.8c0.2-2.6,1.1-3.8,3-3.9c1.9-0.1,2.7,1.2,2.7,2.9c0,1.9-1.5,2.9-3.2,2.8C1201.2,558.4,1200.3,557.2,1199.7,556.8z`
})``;
export const RightDot2 = styled(ColoredPath).attrs({
  d: `M1176,607.6c-3.5,0-6-2.3-6-5.4c0-3.7,3.3-7.1,6.8-7c2.9,0.1,6,2.9,6,5.5
	C1182.8,605.5,1180.7,607.7,1176,607.6z M1178.9,602.2c0-0.3,0-0.6,0-0.9c-0.6-0.4-1.4-1.4-1.9-1.2c-1,0.3-1.8,1.2-2.6,1.8
	c0.6,0.6,1.2,1.7,1.7,1.6C1177.1,603.4,1178,602.7,1178.9,602.2z`
})``;
export const RightDot3 = styled(ColoredPath).attrs({
  d: `M1212.2,695c-1.1-2.1-3.7-4.9-3.1-6.2c1-2.1,4.1-3.3,6.2-4.8c1.3,1.9,3.8,3.9,3.7,5.8c-0.1,1.9-2.7,3.7-4.3,5.5C1213.9,695.2,1213.1,695.1,1212.2,695z`
})``;
export const RightDot4 = styled(ColoredPath).attrs({
  d: `M1242.7,797c-3.5-0.1-6.9-3.7-6.8-7.2c0.2-4.2,3.2-7,7.4-6.8c4.2,0.2,7.8,3.5,7.8,7.1C1251.2,794,1247.3,797.1,1242.7,797z`
})``;
export const BottomDot1 = styled(ColoredPath).attrs({
  d: `M676.1,893.7c0-5.3,3.7-7.7,6.7-7.7c3.2,0,7.6,4,7.5,6.8c-0.2,3.8-3.7,7.7-7,7.5
	C680.1,900.1,675.2,897.4,676.1,893.7z M682.5,895.9c0.4,0,0.8,0,1.2,0c0.5-0.9,1.6-1.9,1.5-2.8c-0.1-0.8-1.3-1.6-2-2.3
	c-0.8,0.8-2.1,1.5-2.3,2.4C680.8,894,682,895,682.5,895.9z`
})``;
export const BottomDot2 = styled(ColoredPath).attrs({
  d: `M852.1,855.7c0-5.3,3.7-7.7,6.7-7.7c3.2,0,7.6,4,7.5,6.8c-0.2,3.8-3.7,7.7-7,7.5
	C856.1,862.1,851.2,859.4,852.1,855.7z M858.5,857.9c0.4,0,0.8,0,1.2,0c0.5-0.9,1.6-1.9,1.5-2.8c-0.1-0.8-1.3-1.6-2-2.3
	c-0.8,0.8-2.1,1.5-2.3,2.4C856.8,856,858,857,858.5,857.9z`
})``;
export const BottomDot3 = styled(ColoredPath).attrs({
  d: `M877.5,949.1c-2.9,0.2-5.9-1.2-8.9-2.9c-1.7-1-3.2-2.2-4.5-3.5c-1-1-1.8-2.3-2.6-3.5
	c-1.3-2.2-1.8-4.6-2.4-7.1c0-0.2-0.1-0.4-0.1-0.6c0.2-2.6,0.1-5.2,1.2-7.6c0.8-1.6,1.3-3.4,2.4-4.8c1.7-2.1,3.6-4,6-5.4
	c1.6-0.9,3.2-1.7,5-2.2c1.3-0.4,2.8-0.5,4.2-0.6c2.9-0.2,5.6,0.9,8.2,1.9c1.4,0.6,2.6,1.7,4,2.5c2.4,1.6,3.8,3.9,5,6.4
	c0.5,1,0.7,2.1,1,3.1c0.1,0.4,0.3,0.8,0.4,1.3c0.3,1.6,0.8,3.3,0.8,4.9c-0.1,1.9-0.6,3.8-1.1,5.7c-0.1,0.4-0.5,0.7-0.7,1.1
	c-0.2,0.3-0.3,0.6-0.5,0.9c-0.5,0.8-0.8,1.7-1.4,2.4c-1.3,1.5-2.7,2.9-4.2,4.2C886.1,948.3,882.2,949.1,877.5,949.1z`
})``;
export const BottomDot4 = styled(ColoredPath).attrs({
  d: `M926.3,706.7c0.5-2.3,1.5-3.1,3.2-3c1.8,0,3.2,0.7,3.2,2.6c0,1.9-1.2,3.5-3,3.1
	C928.5,709.2,927.5,707.6,926.3,706.7z`
})``;
export const BottomLeftDot = styled(ColoredPath).attrs({
  d: `M129.6,695c2.3,0.1,4.3,2,4.3,4.3c0,2.5-1.8,4.5-4,4.5c-2.8,0-4.9-2-4.8-4.6
	C125.1,696.8,127.1,694.9,129.6,695z`
})``;
export const BottomRing = styled(ColoredPath).attrs({
  d: `M964.8,843c20.4,0.5,29.7,11.4,30.2,26.1c0.4,14.4-11.4,30.5-27.7,29.9
	c-16.2-0.6-29.1-11-29.3-27.1C937.8,855.9,947.8,843.8,964.8,843z M968.5,883.5c4.6,0.3,11.2-7.2,11.4-12.9
	c0.2-4.8-7.2-12.2-12.4-12.5c-6.6-0.4-13.3,6.1-13.7,13.2C953.5,878.2,959.4,883,968.5,883.5z`
})``;
export const BottomTear1 = styled(ColoredPath).attrs({
  d: `M1071.3,780.3c-0.8-0.1-1.3,0-1.7-0.2c-2.2-0.9-4.3-1.8-6.4-2.8c-1.9-0.9-3.8-1.7-5.7-2.7
	c-2.3-1.2-4.6-2.4-6.9-3.6c-0.7-0.4-1.5-0.8-2.2-1.1c-2.7-1.1-4.3-3.3-5.7-5.8c-1-1.8,0.3-4.8,2.1-6c3.6-2.4,6.3-1.3,10.3,1.5
	c3.8,2.6,6.7,6.2,9.3,10c2,2.9,4,5.8,6,8.7C1070.7,778.9,1070.9,779.4,1071.3,780.3z`
})``;
export const BottomTear2 = styled(ColoredPath).attrs({
  d: `M1037,730.9c0.3-4.2,3.4-7.8,7.9-7.9c4.3-0.1,7.8,1.3,10.9,4.1c2.3,2.1,4.9,3.9,7.2,6.1
	c1.8,1.7,3.1,3.8,4.7,5.7c0.7,0.8,1.6,1.5,2.3,2.4c1.3,1.7,2.4,3.5,3.7,5.3c1.9,2.8,3.8,5.6,5.7,8.4c0.5,0.8,0.9,1.6,1.2,2.5
	c0.3,0.7-0.2,1.1-0.8,0.9c-2.1-0.8-4.1-1.6-6.2-2.5c-1-0.4-1.9-0.9-2.9-1.3c-2.9-1.3-5.8-2.4-8.6-3.9c-3.8-1.9-7.5-3.8-11.1-6
	c-3.3-2.1-6.8-3.9-9.7-6.7c-1.9-1.8-3.3-3.8-4-6.3C1037.1,731.5,1037.1,731.2,1037,730.9z`
})``;