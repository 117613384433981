import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import GiveKudos, { Modal } from '../GiveKudos';
import { useKudosComposer } from './hooks';
import { Container, ContentContainer } from './styles';

function Kudos(props) {
  const {
    banner,
    giveKudosProps,
    tabProps,
  } = useKudosComposer(props);
  return (
    <Container>
      {banner}
      <Tabs {...tabProps} />
      <ContentContainer>
        <GiveKudos {...giveKudosProps} />
      </ContentContainer>
    </Container>
  );
}

Kudos.Modal = Modal;

Kudos.defaultProps = {
  giveKudosProps: {},
};

Kudos.propTypes = {
  banner: PropTypes.node,
  giveKudosProps: PropTypes.shape(GiveKudos.propTypes),
};

export default Kudos;
