import styled from 'styled-components';
import Button from '../Button';
import { border, colors, font, lineHeight, spacing } from '@matterapp/matter-theme';

export const FooterContainer = styled.div`
  border-top: 1px solid ${colors.blacks[10]};
  display: flex;
  padding: ${spacing.singleAndHalf};

  & button {
    flex: 1 1 100%;

    &:first-child {
      margin-right: ${spacing.half};
    }
    &:last-child {
      margin-left: ${spacing.half};
    }
  }
`;

export const CancelButton = styled(Button.Minimal).attrs(() => ({
  content: 'Cancel',
}))``;

export const SendButton = styled(Button.Primary.Vivid).attrs(() => ({
  content: 'Send',
}))``;

export const DisabledMessage = styled.div`
  align-items: center;
  background: ${colors.blacks[5]};
  border-radius: ${border.radius.S};
  color: ${colors.blacks[50]};
  display: flex;
  font-size: ${font.size.S2};
  min-height: ${spacing.quadruple};
  padding: ${spacing.single};
  line-height: ${lineHeight.M};
`;

export const DisabledMessageInModal = styled(DisabledMessage)`
  min-height: auto;
  font-style: italic;
`;