import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@matterapp/matter-ui';
import { Resources } from '@matterapp/routing';

export default function TeamsAuthButton(props) {
  const { buttonText, className } = props;

  const startTeamsAuthFlow = () => {
    window.location.href = Resources.oAuthTeamsLogin.url();
  };

  return (
    <Button.Social
      primary
      className={className}
      icon='teams'
      buttonText={buttonText}
      onClick={startTeamsAuthFlow}
    />
  );
}

TeamsAuthButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  className: PropTypes.string,
};
