import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export default styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-100%);
  margin-top: -18px;
  min-width: 100px;
  max-width: 250px;
  color: ${() => theme.colors.black};
  padding: 12px 16px;
  border-radius: 12px;
  box-shadow: ${() => theme.shadows[50]};
  white-space: nowrap;

  background: white;
  text-align: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12px 0 12px;
    border-color: white transparent transparent transparent;
    margin-left: -10px;
  }

  &::before {
    left: 50%;
    bottom: -13px;
    border-width: 13px 13px 0 13px;
    margin-left: -11px;
    border-color: rgba(0, 0, 0, 0.15) transparent transparent transparent;
  }
`;
