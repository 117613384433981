import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import Item from './Item';
import Menu from './Menu';
import Trigger from './Trigger';
import {
  AutocompleteContainer,
  EmptyMenuMessage,
  ErrorContainer,
} from './sharedStyles';
import debounce from 'lodash/debounce';
import {
  colors,
  colorsList,
  DATA_ROLE,
  getOpenProps,
  sizes,
  sizesList,
  valueContains,
} from './utils';

export default class Dropdown extends React.PureComponent {
  constructor(props) {
    super();
    this.queryID = 0;
    this.state = {
      filteredItems: props.filterItems ? props.items : [],
      keyPressValue: '',
      keyPressTimes: 0,
      isPristine: props.isInitiallyPristine,
      isLoadingResults: false
    };
    this.dropdownRef = React.createRef();
  }

  componentDidUpdate = () => {
    const { items, filterItems, isLoadingResults } = this.props;
    if (isLoadingResults || isLoadingResults === false) {
      this.setState({ isLoadingResults });
    }

    if (filterItems) {
      this.setState({ filteredItems: items, isLoadingResults });
    }
  };

  blur = () => {
    const dropdownRef = this.getDropdownRef();
    if (dropdownRef.blur) {
      dropdownRef.blur();
    }
  };

  focus = () => {
    const dropdownRef = this.getDropdownRef();
    if (dropdownRef.focus) {
      dropdownRef.focus();
    }
  };

  getDropdownRef = () => {
    if (this.dropdownRef.current && this.dropdownRef.current) {
      return this.dropdownRef.current;
    }
  };

  isSearchEnabled = () => typeof this.props.onSearch === 'function';

  getNextQueryID = () => {
    this.queryID += 1;
    return this.queryID;
  };

  getDisplayValue = () => {
    const { items, showLabelAsValue, value } = this.props;

    const currentItem = items.find((item) => item.value === value) || { value };

    return showLabelAsValue
      ? currentItem.label || currentItem.value
      : currentItem.value;
  };

  getItemFromKeyPress = () => {
    const { keyPressTimes, keyPressValue } = this.state;
    const { items } = this.props;
    const filteredItems = items.filter((item) => {
      const { label, value } = item;
      const valueToCheck = label || value;
      return (
        !!keyPressValue &&
        valueToCheck &&
        valueToCheck.toLowerCase().startsWith(keyPressValue)
      );
    });
    return filteredItems[keyPressTimes % filteredItems.length] || {};
  };

  getItems = () => {
    const { filterItems, items, showSelectedItemInMenu, value } = this.props;
    if (this.isSearchEnabled() || filterItems) {
      return showSelectedItemInMenu
        ? this.state.filteredItems
        : this.state.filteredItems.filter((item) => item.value !== value);
    } else if (showSelectedItemInMenu) {
      return items;
    }
    return items.filter((item) => item.value !== value);
  };

  getItemValue = (item) => item.value;

  handleChange = (e) => {
    const { firstItem } = this.state;
    const enteredValue = e.target.value;
    if (this.isSearchEnabled()) {
      this.handleSearch(enteredValue);
    } else if (this.props.filterItems) {
      this.handleFilter(enteredValue);
    }
    this.props.onChange(e, { ...this.props, firstItem, value: enteredValue });
  };

  formatFilterItems = (items, query) => {
    const { filterLabelAndValue, showValuesAsSubLabels } = this.props;
    return items.filter((item) => {
      const { filterValue, label, subLabel, value } = item;
      const subLabelValue = showValuesAsSubLabels
        ? subLabel || filterValue || value
        : subLabel;
      if (filterLabelAndValue) {
        return (
          valueContains(label, query) ||
          valueContains(label, query) ||
          valueContains(filterValue, query)
        );
      }
      return (
        valueContains(filterValue || label || value, query) ||
        valueContains(subLabelValue, query)
      );
    });
  };

  handleFilter = (query) => {
    const { items } = this.props;
    const { isFiltering } = this.state;
    const filteredItems =
      query && isFiltering ? this.formatFilterItems(items, query) : items;
    this.setState({ filteredItems, firstItem: filteredItems[0] || {} });
    if (!isFiltering) {
      this.setState({ isFiltering: true });
    }
  };

  handleSearch = debounce((query) => {
    return this.props.onSearch(query);
  }, 300);

  handleSelect = (value) => {
    const { items, onChange, onSelect } = this.props;
    const item = items.find((item) => item.value === value);
    const selectProps = { ...this.props, item, isSelected: true, value };
    onChange({}, selectProps);
    onSelect({}, selectProps);
  };

  onKeyPress = (e) => {
    const { keyPressValue, keyPressTimes } = this.state;
    const newKeyPress = e.key;
    const newKeyPressTimes =
      keyPressValue === newKeyPress ? keyPressTimes + 1 : 0;
    this.setState({
      keyPressValue: newKeyPress,
      keyPressTimes: newKeyPressTimes,
    });
  };

  renderItem = (item, isHighlighted) => {
    const {
      color,
      showValuesAsSubLabels,
      size,
      getCustomRenderItemProps,
      labelProps,
    } = this.props;
    const { label, subLabel, value } = item;
    const key = `${label}-${value}`;
    const customRenderItemProps = !!getCustomRenderItemProps
      ? getCustomRenderItemProps(item)
      : {};
    return (
      <Item
        color={color}
        key={key}
        isSelected={this.props.value === value}
        label={label || value}
        labelProps={labelProps}
        size={size}
        subLabel={showValuesAsSubLabels ? value || subLabel : subLabel}
        isHighlighted={isHighlighted}
        {...customRenderItemProps}
      />
    );
  };

  renderItemWithKeyValue = (item, isHighlighted) => {
    const { color, getCustomRenderItemProps, showValuesAsSubLabels, size, labelProps } = this.props;
    const { error, label, subLabel, value, ...otherProps } = item;
    const selectedItem = this.getItemFromKeyPress();
    const isSelected = this.props.value === value;
    const customRenderItemProps = !!getCustomRenderItemProps
      ? getCustomRenderItemProps(item)
      : {};

    return (
      <Item
        {...otherProps}
        color={color}
        error={error}
        key={`${value}-${isSelected}`}
        highlightedValue={selectedItem.label || selectedItem.value}
        isSelected={isSelected}
        label={label || value}
        labelProps={labelProps}
        size={size}
        subLabel={showValuesAsSubLabels ? value || subLabel : subLabel}
        isHighlighted={isHighlighted}
        {...customRenderItemProps}
      />
    );
  };

  handleMenuVisibility = (isOpen) => {
    if (this.props.shouldToggleInputPristine) {
      if (this.state.isPristine && this.props.value && !isOpen) {
        this.setState({ isPristine: false });
      } else if (!this.state.isPristine && !this.props.value && isOpen) {
        this.setState({ isPristine: true });
      }
    }
    if (!isOpen) {
      this.setState({ keyPressValue: '' });
      if (this.state.isFiltering && this.props.resetFilterOnOpen) {
        this.setState({ filteredItems: this.props.items, isFiltering: false });
      }
    }
    this.props.onMenuVisibilityChange(isOpen, {
      ...this.props,
      isOpen,
      firstItem: this.state.firstItem,
    });
  };

  renderError = () => {
    const { errorMessage } = this.props;
    if (!errorMessage || this.state.isPristine) {
      return null;
    }
    return <ErrorContainer>{errorMessage}</ErrorContainer>;
  };

  renderMenu = (items, value) => {
    const {
      color,
      emptyMenuMessage,
      emptyMenuMessageNoItems,
      filterItems,
      menuProps,
      renderMenu,
      showMenuOverDropdown,
      showAllItemsOnFilter,
      size,
    } = this.props;
    const isSearchEnabled = this.isSearchEnabled();
    const noItems = items.length === 0;

    if (this.state.isLoadingResults) {
      return (
        <Menu color={color} size={size} value={value}>
          <EmptyMenuMessage size={size}>Loading…</EmptyMenuMessage>
        </Menu>
      );
    }

    if ((isSearchEnabled || !showAllItemsOnFilter) && !value) {
      if (emptyMenuMessage) {
        return (
          <Menu color={color} size={size} value={value}>
            <EmptyMenuMessage size={size}>{emptyMenuMessage}</EmptyMenuMessage>
          </Menu>
        );
      }
      return <div />;
    }

    if (
      (isSearchEnabled ||
        !showAllItemsOnFilter ||
        (filterItems && showAllItemsOnFilter)) &&
      !!value &&
      noItems
    ) {
      if (emptyMenuMessageNoItems) {
        return (
          <Menu color={color} size={size} value={value}>
            <EmptyMenuMessage size={size}>
              {emptyMenuMessageNoItems}
            </EmptyMenuMessage>
          </Menu>
        );
      }
    }

    if (noItems) {
      return <div />;
    }
    const menu = (
      <Menu
        color={color}
        showMenuOverDropdown={
          isSearchEnabled || filterItems ? false : showMenuOverDropdown
        }
        menuProps={menuProps}
        items={items}
        size={size}
        value={value}
      />
    );
    return !!renderMenu
      ? renderMenu(menu, { color, items, size, value })
      : menu;
  };

  renderInput = (props) => {
    const {
      autoFocus,
      className,
      color,
      disabled,
      dropdownArrowComponent,
      filterItems,
      fitToValue,
      fitToValuePadding,
      errorMessage,
      index,
      onBlur,
      onFocus,
      onKeyPress,
      placeholder,
      rcLabel,
      showDropdownArrow,
      showSelectedItemInMenu,
      size,
    } = this.props;
    const isSearchEnabled = this.isSearchEnabled();
    const isInputEnabled = filterItems || isSearchEnabled;
    return (
      <Trigger
        {...props}
        autoFocus={autoFocus}
        className={className}
        color={color}
        disabled={disabled}
        dropdownArrowComponent={dropdownArrowComponent}
        fitToValue={fitToValue}
        fitToValuePadding={fitToValuePadding}
        hasError={!!errorMessage && !this.state.isPristine}
        index={index}
        onAfterBlur={onBlur}
        onAfterFocus={onFocus}
        onKeyPress={isInputEnabled ? onKeyPress : this.onKeyPress}
        placeholder={placeholder}
        rcLabel={rcLabel}
        size={size}
        isSearchEnabled={isInputEnabled}
        showCheckmark={!showSelectedItemInMenu}
        showDropdownArrow={isSearchEnabled ? false : showDropdownArrow}
      />
    );
  };

  render() {
    const {
      color,
      filterItems,
      fluid,
      children,
      shouldItemRender,
    } = this.props;
    const isInputEnabled = filterItems || this.isSearchEnabled();
    const error = this.renderError();
    const openProps = getOpenProps(this.props);

    return (
      <AutocompleteContainer
        fluid={fluid}
        data-role={DATA_ROLE}
        data-error={!!error || null}
      >
        <Autocomplete
          autoHighlight
          color={color}
          wrapperStyle={{}}
          getItemValue={this.getItemValue}
          items={this.getItems()}
          value={this.getDisplayValue()}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          onMenuVisibilityChange={this.handleMenuVisibility}
          renderMenu={this.renderMenu}
          renderItem={
            isInputEnabled ? this.renderItem : this.renderItemWithKeyValue
          }
          ref={this.dropdownRef}
          renderInput={this.renderInput}
          shouldItemRender={shouldItemRender}
          {...openProps}
        />
        {children}
        {error}
      </AutocompleteContainer>
    );
  }
}

Dropdown.defaultProps = {
  color: colors.DEFAULT,
  items: [],
  isInitiallyPristine: true,
  filterItems: false,
  formatSearchResults: (results) => results,
  onMenuVisibilityChange: () => null,
  onSelect: () => null,
  resetFilterOnOpen: false,
  showAllItemsOnFilter: true,
  showDropdownArrow: true,
  showLabelAsValue: true,
  showMenuOverDropdown: false,
  showSelectedItemInMenu: true,
  showValuesAsSubLabels: false,
  shouldToggleInputPristine: true,
  size: sizes.M,
};

Dropdown.colors = colors;
Dropdown.sizes = sizes;
Dropdown.Menu = Menu;
Dropdown.Item = Item;

Dropdown.propTypes = {
  /** Auto focus in the input field. */
  autoFocus: PropTypes.bool,
  /** Additional children. */
  children: PropTypes.node,
  /** Additional style class. */
  className: PropTypes.string,
  /** Color theme of the dropdown. */
  color: PropTypes.oneOf(colorsList),
  /** If dropdown is disabled. */
  disabled: PropTypes.bool,
  /** Custom dropdown arrow component. */
  dropdownArrowComponent: PropTypes.node,
  /** Content to display when there is no results in menu. */
  emptyMenuMessage: PropTypes.node,
  /** Error message to display. */
  errorMessage: PropTypes.node,
  /** Info message to display. */
  infoMessage: PropTypes.node,
  /** Allows for simple searching of the list. For more advanced searching, use the `onSearch` prop. */
  filterItems: PropTypes.bool,
  /** When filtering, search check label and value and options filterValue of object. */
  filterLabelAndValue: PropTypes.bool,
  /** Dropdown trigger fits the selected value. */
  fitToValue: PropTypes.bool,
  /** Additional function to format search results. */
  formatSearchResults: PropTypes.func,
  /** If validation is initially pristine. */
  isInitiallyPristine: PropTypes.bool,
  /** Items to display in the dropdown. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      error: PropTypes.bool,
      filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      subLabel: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ),
  /** Callback when value changes. */
  onChange: PropTypes.func.isRequired,
  /** Callback when visibility of menu changes. */
  onMenuVisibilityChange: PropTypes.func,
  /** If passed in, callback function to return items based on serach value. */
  onSearch: PropTypes.func,
  /** Optional callback when item is selected. */
  onSelect: PropTypes.func,
  /** Placeholder text of there is no selected value. */
  placeholder: PropTypes.string,
  /** Optional function to rendering/displaying the menu when no matches are found upon searching. */
  renderNoMatchesMessage: PropTypes.func,
  /** Optional function to rendering/displaying the menu. */
  renderMenu: PropTypes.func,
  /** When filtering items, the filter displays all items again on next open. */
  resetFilterOnOpen: PropTypes.bool,
  /** Label locator string for tests. */
  rcLabel: PropTypes.string,
  /** Displays all items in dropdown when no value is in the input when filtering. */
  showAllItemsOnFilter: PropTypes.bool,
  /** Display the dropdown arrow. */
  showDropdownArrow: PropTypes.bool,
  /** Only use with dropdown with a few items. */
  showMenuOverDropdown: PropTypes.bool,
  /** Shows the label of the currently selected value. */
  showLabelAsValue: PropTypes.bool,
  /** If item should be rendered. */
  shouldItemRender: PropTypes.func,
  /** Show selected item in list. Otherwise, once an item is selected, it disapears from the list. */
  showSelectedItemInMenu: PropTypes.bool,
  /** Displays each item's value as a subLabel. */
  showValuesAsSubLabels: PropTypes.bool,
  /** If pristine state should be automatically toggled. */
  shouldToggleInputPristine: PropTypes.bool,
  /** Size of the dropdown. [XS, S, M, L ,XL] */
  size: PropTypes.oneOf(sizesList),
  /** The currently selected value. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
