import React from 'react';
import PropTypes from 'prop-types';
import rafDebounce from '../../common/libs/rafDebounce';
import getScrollTop from '../../common/libs/getScrollTop';

export const height = 64;
export const mobileTabHeight = 48;
export const mobileShortThreshold = height;
export const shadowScrollThreshold = 5;

class ScrollWatcher extends React.Component {
  constructor() {
    super();
    this.state = {
      mobileTop: 0,
      isScrolled: false,
      isScrolledPastHeader: false,
    };
  }

  componentDidMount() {
    this.handleScroll = rafDebounce(this.calculateScrolledState);
    window.addEventListener('scroll', this.handleScroll, this.props.watchAllScrolling);
    this.calculateScrolledState();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, this.props.watchAllScrolling);
  }

  calculateScrolledState = (e) => {
    const { onMobileScroll, watchAllScrolling } = this.props;
    const scrollTop = getScrollTop(watchAllScrolling && e ? e.target : null);
    const mobileTop = scrollTop >= mobileShortThreshold ? -height : 0;
    const isScrolled = scrollTop >= shadowScrollThreshold;
    const isScrolledPastHeader = scrollTop >= height;

    if (this.state.mobileTop !== mobileTop) {
      this.setState({ mobileTop });

      // Close the menu when the body is scrolled enough to shift the header
      if (mobileTop < 0 && this.props.onMobileScroll) {
        onMobileScroll();
      }
    }

    if (this.state.isScrolled !== isScrolled) {
      this.setState({ isScrolled });
    }

    if (this.state.isScrolledPastHeader !== isScrolledPastHeader) {
      this.setState({ isScrolledPastHeader });
    }
  }

  render() {
    return this.props.children(this.state);
  }
}

ScrollWatcher.defaultProps = {
  watchAllScrolling: false,
  onMobileScroll: () => {},
};

ScrollWatcher.propTypes = {
  watchAllScrolling: PropTypes.bool,
  onMobileScroll: PropTypes.func,
  children: PropTypes.func.isRequired,
};

export default ScrollWatcher;
