import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PlusSizeScreen, SmallScreen } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

const CURRENT_STEP_STRING = '%c';
const RECEIVER_NAME_STRING = '%n';
const TOTAL_STEP_STRING = '%t';
const LAST_STEP_LABEL = 'Last Step';
const LOADING_TIMEOUT = 500;
const STEP_COUNT_LABEL = `Feedback for ${RECEIVER_NAME_STRING}`;
const STEP_COUNT_TEMPLATE = `${CURRENT_STEP_STRING} of ${TOTAL_STEP_STRING}`;

const getProgressWidth = ({ width }) => width || 0;
const getLastStepBackground = ({ isLastStep }) => {
  if (isLastStep) {
    return `
      background: transparent;
      transition: all ${theme.transitions.times.double},
        background ${theme.transitions.times.default};
    `;
  }
  return `
      background: ${theme.colors.white};
      transition: all ${theme.transitions.times.double},
        background ${theme.transitions.times.singleAndHalf} ${theme.transitions.times.singleAndHalf};
    `;
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: ${theme.spacing.single};
  pointer-events: none;
  z-index: 10;
  ${getLastStepBackground}
  ${theme.media.S`
    background: transparent;
    padding-bottom: 0;
  `}
`;

const ProgressBarContainer = styled.div`
  background: ${({ isLastStep }) =>
  isLastStep ? 'transparent' : theme.colors.white};
  position: relative;
  height: ${theme.spacing.half};
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  transition: opacity ${theme.transitions.times.default};
  ${theme.media.S`
    background: transparent;
  `}
`;

const ProgressBar = styled.div`
  background: ${theme.colors.blacks[70]};
  height: ${theme.spacing.half};
  width: ${getProgressWidth};
  transition: width ${theme.transitions.times.double};
`;

const LabelContainer = styled.div`
  width: 100%;
  max-width: ${theme.sizes.webClient.maxContentWidth};
  margin: ${theme.spacing.half} auto;
  text-align: right;
`;

const getTextShadow = ({ isLastStep }) => {
  if (isLastStep) {
    return '';
  }
  const textShadow = [];
  for (let i = 1; i < 12; i++) {
    const style = ` 0 0 ${i * 2}px ${theme.colors.white}`;
    textShadow.push(style);
    textShadow.push(style);
  }
  return `text-shadow:${textShadow.join(',')}`;
};

const Label = styled.span`
  margin-right: ${theme.spacing.single};
  ${getTextShadow};
  ${theme.media.S`
    margin-right: 0;
  `}
`;

const Progress = (props) => {
  let loadTimeout = null;
  const [isLoaded, setLoaded] = useState(false);

  const {
    className,
    currentStep,
    lastStepLabel,
    stepCountLabel,
    receiversFullName,
    shouldCountLastStep,
    totalSteps,
  } = props;
  const totalStepsOffset = shouldCountLastStep ? 0 : 1;
  const visibleTotalSteps = totalSteps - totalStepsOffset;
  const width = `${Math.min(currentStep / visibleTotalSteps, 1) * 100}%`;

  const stepCount = STEP_COUNT_TEMPLATE.replace(
    CURRENT_STEP_STRING,
    Math.min(currentStep, visibleTotalSteps)
  ).replace(TOTAL_STEP_STRING, visibleTotalSteps);

  const lastStepCount = shouldCountLastStep ? stepCount : lastStepLabel;
  const stepCountToDisplay =
    currentStep >= totalSteps ? lastStepCount : stepCount;
  const label = stepCountLabel ? (
    <>
      <PlusSizeScreen>
        {stepCountLabel.replace(RECEIVER_NAME_STRING, receiversFullName)}:{' '}
      </PlusSizeScreen>
      <SmallScreen>{receiversFullName}: </SmallScreen>
      {stepCountToDisplay}
    </>
  ) : (
    stepCountToDisplay
  );

  useEffect(() => {
    loadTimeout = setTimeout(() => setLoaded(true), LOADING_TIMEOUT);

    // returned function will be called on component unmount
    return () => {
      clearTimeout(loadTimeout);
    };
  }, []);

  const isLastStep =
    (!shouldCountLastStep && currentStep >= totalSteps) ||
    currentStep > totalSteps;

  return (
    <Container isLastStep={isLastStep}>
      <ProgressBarContainer
        className={className}
        isLoaded={isLoaded}
        isLastStep={isLastStep}
      >
        <ProgressBar currentStep={currentStep} width={width} />
      </ProgressBarContainer>
      <LabelContainer>
        <Label
          isLastStep={
            isLastStep ||
            (!shouldCountLastStep && currentStep === totalSteps - 1)
          }
        >
          {label}
        </Label>
      </LabelContainer>
    </Container>
  );
};

Progress.propTypes = {
  /** Additional style classes. */
  className: PropTypes.string,
  /** The current step of the timeline. */
  currentStep: PropTypes.number.isRequired,
  /** If `shouldCountLastStep` is false, display this label instead. */
  lastStepLabel: PropTypes.string,
  /** Name of the receiver. */
  receiversFullName: PropTypes.string,
  /** If last step should be counted. If false, display `lastStepLabel` label instead. */
  shouldCountLastStep: PropTypes.bool,
  /** Label to display with step count. */
  stepCountLabel: PropTypes.string,
  /** The total amount of steps in the timeline. */
  totalSteps: PropTypes.number.isRequired,
};

Progress.defaultProps = {
  lastStepLabel: LAST_STEP_LABEL,
  shouldCountLastStep: false,
  stepCountLabel: STEP_COUNT_LABEL,
};

export default Progress;

export { getProgressWidth, getLastStepBackground };
