import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../Container';
import Heading from '../Heading';
import { sizePropType, SIZE } from '../size';

const ChildrenContainer = styled.div`
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
`;

const Basic = ({ className, title, centered, size, children }) => (
  <Container className={className} centered={centered}>
    <Heading centered={centered} size={size}>
      {title}
    </Heading>
    {!!children && <ChildrenContainer>{children}</ChildrenContainer>}
  </Container>
);

Basic.defaultProps = {
  centered: false,
  size: SIZE.MEDIUM,
  children: null,
};

Basic.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
  centered: PropTypes.bool,
  size: sizePropType,
};

export default Basic;
