import styled from 'styled-components';
import theme, { colors, lineHeight, spacing, media } from '@matterapp/matter-theme';
import { Button } from '@matterapp/matter-ui';
import StepContainerComponent from './StepContainer';

export const MOBILE_RATER_STEP_SIZE = 48;
export const DESKTOP_RATER_STEP_SIZE = 64;
export const NUMBER_OF_RATINGS = 5;

export const FlowHeader = styled.div`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.S2};
  line-height: ${lineHeight.M};
  color: ${colors.blacks[80]};
  margin-bottom: ${spacing.single};
  ${media.S`
    font-size: ${theme.fontSize.M};
    line-height: 28px;
    margin-bottom: ${spacing.singleAndHalf};
  `}
`;

export const SkillNameContainer = styled.div`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.S2};
  line-height: ${lineHeight.M};
  color: ${colors.blacks[80]};
  margin-bottom: ${spacing.single};
  max-height: ${spacing.singleAndHalf};
  ${media.S`
    font-size: ${theme.fontSize.M};
    line-height: 28px;
    max-height: 28px;
  `}
`;

export const RaterContainer = styled.div`
  margin-bottom: ${spacing.double};
  height: 128px;
`;

export const StepContainer = styled(StepContainerComponent)`
  ${({ isShown }) => (isShown ? `max-height: unset;` : `max-height: 0;`)}
  ${theme.media.S`
    max-height: unset;
  `}
`;

export const NavButtonContainer = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  margin-top: ${spacing.single};
  padding: ${spacing.single} ${spacing.singleAndQuarter};
  z-index: 10;
  background: white;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.15);

  ${theme.media.S`
    border-top: 2px solid #F6F6F6;
    box-shadow: none;
    position: static;
    margin-bottom: ${spacing.double};
    margin-top: ${spacing.singleAndHalf};
    padding: 0;
    padding-top:  ${spacing.double};
    padding-bottom: ${spacing.double};
    background: transparent;
  `}
`;

export const NavButton = styled(Button.Primary.Vivid)`
  flex: 1 1 100%;
`;

export const SkillStepsNavButton = styled(Button.Minimal)`
  flex: 1 1 100%;
  &:first-child {
    margin-right: ${spacing.half};
  }
  ${theme.media.S`
    &:first-child {
      margin-right: ${spacing.double};
    }
  `}
`;

export const SidebarContainer = styled.div`
  pointer-events: none;
  position: absolute;
  top: 6vh;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
`;

export const SidebarWrapper = styled.div`
  display: flex;
  margin: auto;
  max-width: ${theme.sizes.webClient.maxContentWidth};
  width: 100%;
`;


export const StepHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  max-width: 100%;
  padding: ${spacing.single} ${spacing.singleAndQuarter};
  z-index: 10;
  font-size: ${theme.fontSize.S};
  font-weight: ${theme.fontWeight.semiBold};
  line-height: ${lineHeight.M};
  height: ${spacing.tripleAndHalf};
  text-align: left;
  background: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
  ${media.S`
    display: none;
  `}
`;

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: ${spacing.half};
  }
`;