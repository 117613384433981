import { compose, withProps } from 'recompose';
import { Resources } from '@matterapp/routing';
import withRouter from 'hocs/withRouter';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import FeedbackReceivedPage from '../components/FeedbackReceivedViewFeedbackPage';
import { FEEDBACK_RECEIVED_QUERY } from 'graphql-queries/queries';
import Toast from 'components/Toast/Toast';

const withData = compose(
  withRouter,
  withProps((ownProps) => ({
    handleStartReadyToGrow: () => {
      ownProps.history.push(Resources.readyToGrowEntry.path());
    },
    handleError: () => {
      setTimeout(() => {
        Toast.error('Oops! That feedback has been deleted');
      }, 200);
      ownProps.history.push(Resources.homeMain.path());
    },
  })),
  graphql(FEEDBACK_RECEIVED_QUERY, {
    name: 'feedbackReceivedData',
    options: ({ feedbackReceivedId }) => ({
      variables: {
        id: feedbackReceivedId,
      },
    }),
  }),
);

const FeedbackReceivedPageContainer = withData(FeedbackReceivedPage);

FeedbackReceivedPageContainer.propTypes = {
  feedbackReceivedId: PropTypes.string.isRequired,
};

export default FeedbackReceivedPageContainer;
