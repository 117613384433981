"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.zIndex = void 0;
const zIndex = {
  confirm: 101,
  modal: 100,
  modalOverlay: 99,
  navBar: 95,
  navBarMenu: 96,
  sidePanelOverlay: 97,
  sidePanel: 98,
  toast: 104,
  dropdown: 105,
  tooltip: 110
};
exports.zIndex = zIndex;
var _default = zIndex;
exports.default = _default;