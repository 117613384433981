import { parsePhoneNumber } from 'libphonenumber-js';
import {
  ERROR_CANT_LEAVE_BLANK,
  ERROR_AUTH_INVALID_US_PHONE_NUMBER,
} from '../../copy-consts';

export const isValidPhoneNumber = (phoneNumber) => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'US');
    if (!parsedPhoneNumber.isValid) { return false; }
  } catch (e) {
    return false;
  }
  return true;
};

export const getPhoneNumberValidation = (fieldName) => (values) => {
  const errors = {};
  if (!values[fieldName]) {
    errors[fieldName] = ERROR_CANT_LEAVE_BLANK;
  } else if (!isValidPhoneNumber(values[fieldName])) {
    errors[fieldName] = ERROR_AUTH_INVALID_US_PHONE_NUMBER;
  }
  return errors;
};
