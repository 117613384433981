import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Panel from '../../../Panel';
import Progress from '../../../Progress';
import AvatarHeader from '../../AvatarHeader';
import { Footer } from '../../FeedItem';
import GiveFeedbackButtons from './GiveFeedbackButtons';

const MINIMAL_PERCENTAGE_TO_DISPLAY = 7;

const ProgressBarContainer = styled.div`
  margin-left: ${theme.spacing.singleAndQuarter};
  margin-right: ${theme.spacing.singleAndQuarter};
  padding: ${theme.spacing.half} 0;
  ${theme.media.S`
    margin-left: ${theme.spacing.singleAndHalf};
    margin-right: ${theme.spacing.singleAndHalf};
  `};
  & ${Progress.Bar.Simple.Track} {
    background: linear-gradient(0deg, #FFE7EE, #FFE7EE),
    linear-gradient(0deg, #FDE8E1, #FDE8E1), #E9E9F6;
  }
  & ${Progress.Bar.Simple.Meter} {
    background: linear-gradient(0deg, #FF6588, #FF6588);
  }
`;

const RitualProgress = ({
  footerProps = {},
  mainMessage,
  secondaryMessage,
  photoUrl,
  percentProgress,
  onClickKudos,
  onClickFeedback,
  onClickDelete
}) => {
  const panelHeader = 
    <AvatarHeader
      equalPadding={false}
      canClickHeader={false}
      header={mainMessage}
      subheader={secondaryMessage}
      photoUrl={photoUrl}
      showMenu={false}
      onClickDelete={onClickDelete}
    />;
  const progressBarMinimum = percentProgress < MINIMAL_PERCENTAGE_TO_DISPLAY ? MINIMAL_PERCENTAGE_TO_DISPLAY : percentProgress;
  const percentLabel = percentProgress + '%';

  return (
    <Panel.Base header={panelHeader}>
      <ProgressBarContainer>
        <Progress.Bar.Simple percent={progressBarMinimum} percentLabel={percentLabel}/>
      </ProgressBarContainer>
      <GiveFeedbackButtons onClickKudos={onClickKudos} onClickFeedback={onClickFeedback} />
      <Footer {...footerProps} />
    </Panel.Base>
  );
};

export default RitualProgress;