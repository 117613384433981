import { graphql } from '@apollo/client/react/hoc';
import withRouter from 'hocs/withRouter';
import { compose, withProps } from 'recompose';
import PendingInvitations from '../components/PendingInvitations';
import {
  PENDING_INVITATIONS_QUERY,
  IGNORE_INVITATION_MUTATION,
  PENDING_INVITATIONS_QUERY_MIN,
} from 'graphql-queries/queries';
import withProfilePreviewCard from 'hocs/profile-preview-card/withProfilePreviewCard';

const withData = compose(
  withRouter,
  withProfilePreviewCard,
  graphql(IGNORE_INVITATION_MUTATION, {
    name: 'ignoreInvitationMutation',
  }),
  withProps(({ history, ignoreInvitationMutation }) => ({
    handleStartInvitation: ({ invitation }) => {
      history.push(invitation.path);
    },
    handleIgnoreInvitation: async ({ invitation }) => {
      await ignoreInvitationMutation({
        variables: { invitationId: invitation.id },
        update: async (store) => {
          const data = store.readQuery({
            query: PENDING_INVITATIONS_QUERY_MIN,
          });
          const invitationRemovedList = data.pendingInvitations.filter(
            ({ id }) => id !== invitation.id
          );
          const dataToWrite = {
            pendingInvitations: invitationRemovedList,
          };
          store.writeQuery({
            query: PENDING_INVITATIONS_QUERY_MIN,
            data: dataToWrite,
          });
        },
      });
    },
  })),
  graphql(PENDING_INVITATIONS_QUERY, {
    name: 'pendingInvitationsData',
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  withProps(({ pendingInvitationsData }) => {
    const getPendingInvitations = () => {
      const { pendingInvitations } = pendingInvitationsData;
      if (!pendingInvitations) { return []; }
      return pendingInvitations;
    };

    return {
      pendingInvitations: getPendingInvitations(),
    };
  })
);

export default withData(PendingInvitations);
