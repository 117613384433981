import React from 'react';
import ReactTimeAgo from 'react-timeago';
import PropTypes from 'prop-types';

const defaultFormatter = (value, unit, suffix) => {
  const computedUnit = value === 1 ? unit : `${unit}s`;
  return `${value} ${computedUnit} ${suffix}`;
};

const dateFormatter = (value, unit, suffix) => {
  if (unit === 'second' && value <= 5) {
    return 'just now';
  }
  return defaultFormatter(value, unit, suffix);
};

/**
 * This is a wrapper around the react-timeago library.
 * We extend the core library via the following enhancements:
 * - Standardized date formatting: We can now do things
 * such as displaying "just now" when the datetime shows
 * a few seconds in the past.
 */
class TimeAgo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.now = new Date();
  }

  render() {
    const { date, live } = this.props;
    return <ReactTimeAgo date={date} live={live} formatter={dateFormatter} />;
  }
}

TimeAgo.defaultProps = {
  live: false,
};

TimeAgo.propTypes = {
  date: PropTypes.string.isRequired,
  live: PropTypes.bool,
};

export default TimeAgo;
