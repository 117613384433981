const REPLACE_TEXT = '%s';

const COMPLETED_TEXT = {
  header:
    'Blast off! You’ve successfully asked 6 people for feedback. We’ll let you know when they respond.',
  content:
    'Leaders are also givers. Make someone’s day by giving feedback or sending them kudos.',
};

const CONNECT_YOUR_ACCOUNT_TEXT = {
  header: `Welcome to Matter${REPLACE_TEXT}! Matter helps you discover your superpowers and identify blind spots.`,
  content:
    'Let’s get started by connecting your account. Connecting makes requesting feedback easier.',
  contentNoPeers:
    'It takes two to tango. Get started by connecting your account. Connecting makes requesting feedback easier.',
  connect: 'Connect your account',
  later: 'I’ll do this later',
};

const INVITE_PEERS_TEXT = {
  header:
    'Discover your strengths and identify your blind spots by requesting feedback from 6 people',
  content: 'Start gathering feedback with this sharable link. Connect anytime ',
  peersLink: 'here',
};

const PEERS_SUGGESTIONS_TEXT = {
  header:
    'Boom! You’ve connected your account. You can find new people suggestions and manage requests in your Advisors page.',
  content:
    'Discover your strengths and identify your blind spots by requesting feedback from 6 people.',
  suggestions: `You have ${REPLACE_TEXT} suggestions`,
  suggestionsSingular: `You have ${REPLACE_TEXT} suggestion`,
};

export {
  COMPLETED_TEXT,
  CONNECT_YOUR_ACCOUNT_TEXT,
  INVITE_PEERS_TEXT,
  REPLACE_TEXT,
  PEERS_SUGGESTIONS_TEXT,
};
