import { SkillColorPalette } from '@matterapp/skills';
import { colorPalettes } from './skill-color-palettes';
import { normalizeSkillName } from './normalize-skill-name';

export const skills = [
  {
    name: 'Generic',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/generic_blue-1589766294183.svg",
  },
  {
    name: 'Brand Design',
    colorPalette: SkillColorPalette.Purple,
    svg: "https://static.matterapp.com/static-assets/brand_design-1589767809026.svg",
    description: 'description',
  },
  {
    name: 'Motion Design',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/motion_design-1589767881963.svg",
    description: 'description',
  },
  {
    name: 'User-Centered Design',
    colorPalette: SkillColorPalette.Yellow,
    svg: "https://static.matterapp.com/static-assets/user-centered_design-1589767922108.svg",
  },
  {
    name: 'Graphic Design',
    colorPalette: SkillColorPalette.Yellow,
    svg: "https://static.matterapp.com/static-assets/graphic_design-1589767944720.svg",
  },
  {
    name: 'Illustration',
    colorPalette: SkillColorPalette.Yellow,
    svg: "https://static.matterapp.com/static-assets/illustration-1589767967558.svg",
    description: 'description',
  },
  {
    name: 'Print Design',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/print_design-1589767988453.svg",
    description: 'description',
  },
  {
    name: 'Interaction Design',
    colorPalette: SkillColorPalette.Green,
    svg: "https://static.matterapp.com/static-assets/interaction_design-1589768014494.svg",
  },
  {
    name: 'Typography',
    colorPalette: SkillColorPalette.Red,
    svg: "https://static.matterapp.com/static-assets/typography-1589768035254.svg",
  },
  {
    name: 'Visual Design',
    colorPalette: SkillColorPalette.Green,
    svg: "https://static.matterapp.com/static-assets/visual_design-1589768058459.svg",
  },
  {
    name: 'User Research',
    colorPalette: SkillColorPalette.Purple,
    svg: "https://static.matterapp.com/static-assets/user_research-1589762689488.svg",
  },
  {
    name: 'Color Theory',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/color_theory-1589768079324.svg",
  },
  {
    name: 'Product Management',
    colorPalette: SkillColorPalette.Purple,
    svg: "https://static.matterapp.com/static-assets/product_management-1589768103239.svg",
  },
  {
    name: 'Project Management',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/project_management-1589764217101.svg",
  },
  {
    name: 'Communication',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/communication-1589763604203.svg",
  },
  {
    name: 'Grit',
    colorPalette: SkillColorPalette.Orange,
    svg: "https://static.matterapp.com/static-assets/grit-1589768127727.svg",
  },
  {
    name: 'Leadership',
    colorPalette: SkillColorPalette.Red,
    svg: "https://static.matterapp.com/static-assets/leadership-1589763878727.svg",
  },
  {
    name: 'Mentorship',
    colorPalette: SkillColorPalette.Green,
    svg: "https://static.matterapp.com/static-assets/mentorship-1589768388981.svg",
  },
  {
    name: 'Presenting',
    colorPalette: SkillColorPalette.Purple,
    svg: "https://static.matterapp.com/static-assets/presenting-1589763925703.svg",
  },
  {
    name: 'Analytical Thinking',
    colorPalette: SkillColorPalette.Yellow,
    svg: "https://static.matterapp.com/static-assets/analytical_thinking-1589763474249.svg",
  },
  {
    name: 'Strategic Thinking',
    colorPalette: SkillColorPalette.Green,
    svg: "https://static.matterapp.com/static-assets/strategic_thinking-1589764510039.svg",
  },
  {
    name: 'Technical Acumen',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/technical_acumen-1589762804470.svg",
  },
  {
    name: 'Monetization',
    colorPalette: SkillColorPalette.Green,
    svg: "https://static.matterapp.com/static-assets/monetization-1589768167243.svg",
  },
  {
    name: 'Scrappy',
    colorPalette: SkillColorPalette.Red,
    svg: "https://static.matterapp.com/static-assets/scrappy-1589768189717.svg",
  },
  {
    name: 'Vision',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/vision-1589762726884.svg",
  },
  {
    name: 'Resilience',
    colorPalette: SkillColorPalette.Orange,
    svg: "https://static.matterapp.com/static-assets/resilience-1589762598296.svg",
  },
  {
    name: 'Design Thinking',
    colorPalette: SkillColorPalette.Pink,
    svg: "https://static.matterapp.com/static-assets/design_thinking-1589763656068.svg",
  },
  {
    name: 'Naughtiness',
    colorPalette: SkillColorPalette.Red,
    svg: "https://static.matterapp.com/static-assets/naughtiness-1589768213655.svg",
  },
  {
    name: 'Fundraising',
    colorPalette: SkillColorPalette.Green,
    svg: "https://static.matterapp.com/static-assets/fundraising-1589768236046.svg",
  },
  {
    name: 'Hiring',
    colorPalette: SkillColorPalette.Purple,
    svg: "https://static.matterapp.com/static-assets/hiring-1589763723498.svg",
  },
  {
    name: 'Technical Leadership',
    colorPalette: SkillColorPalette.Yellow,
    svg: "https://static.matterapp.com/static-assets/technical_leadership-1589762767033.svg",
  },
  {
    name: 'Delegation',
    colorPalette: SkillColorPalette.Orange,
    svg: "https://static.matterapp.com/static-assets/delegation-1589760969804.svg",
  },
  {
    name: 'Public Speaking',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/public_speaking-1589764297569.svg",
  },
  {
    name: 'Algorithms',
    colorPalette: SkillColorPalette.Pink,
    svg: "https://static.matterapp.com/static-assets/algorithms-1589763370414.svg",
  },
  {
    name: 'System Design',
    colorPalette: SkillColorPalette.Orange,
    svg: "https://static.matterapp.com/static-assets/system_design-1589768263608.svg",
  },
  {
    name: 'Code Quality',
    colorPalette: SkillColorPalette.Yellow,
    svg: "https://static.matterapp.com/static-assets/code_quality-1589762529720.svg",
  },
  {
    name: 'Security',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/security-1589768286994.svg",
  },
  {
    name: 'Responsiveness',
    colorPalette: SkillColorPalette.Orange,
    svg: "https://static.matterapp.com/static-assets/responsiveness-1589764450385.svg",
  },
  {
    name: 'Supportiveness',
    colorPalette: SkillColorPalette.Pink,
    svg: "https://static.matterapp.com/static-assets/supportiveness-1589764571273.svg",
  },
  {
    name: 'Connected',
    colorPalette: SkillColorPalette.Purple,
    svg: "https://static.matterapp.com/static-assets/connected-1589768311175.svg",
  },
  {
    name: 'Candor',
    colorPalette: SkillColorPalette.Green,
    svg: "https://static.matterapp.com/static-assets/candor-1589763541902.svg",
  },
  {
    name: 'Resourcefulness',
    colorPalette: SkillColorPalette.Red,
    svg: "https://static.matterapp.com/static-assets/resourcefulness-1589764396966.svg",
  },
  {
    name: 'Influence',
    colorPalette: SkillColorPalette.Red,
    svg: "https://static.matterapp.com/static-assets/influence-1589763818789.svg",
  },
  {
    name: 'Coaching',
    colorPalette: SkillColorPalette.Orange,
    svg: "https://static.matterapp.com/static-assets/coaching-1589762300715.svg",
  },
  {
    name: 'Collaborative Leadership',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/collaborative_leadership-1589768329269.svg",
  },
  {
    name: 'Inclusiveness',
    colorPalette: SkillColorPalette.Red,
    svg: "https://static.matterapp.com/static-assets/inclusiveness-1589764651784.svg",
  },
  {
    name: 'Productivity',
    colorPalette: SkillColorPalette.Green,
    svg: "https://static.matterapp.com/static-assets/productivity-1589764713696.svg",
  },
  {
    name: 'Multitasking',
    colorPalette: SkillColorPalette.Orange,
    svg: "https://static.matterapp.com/static-assets/multitasking-1589763773742.svg",
  },
  {
    name: 'Social Media Management',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/social_media_management-1589764987027.svg",
  },
  {
    name: 'Storytelling',
    colorPalette: SkillColorPalette.Yellow,
    svg: "https://static.matterapp.com/static-assets/storytelling-1589764772259.svg",
  },
  {
    name: 'Writing',
    colorPalette: SkillColorPalette.Green,
    svg: "https://static.matterapp.com/static-assets/writing-1589768350621.svg",
  },
  {
    name: 'Body Language',
    colorPalette: SkillColorPalette.Orange,
    svg: "https://static.matterapp.com/static-assets/body_language-1589765043294.svg",
  },
  {
    name: 'Empathy',
    colorPalette: SkillColorPalette.Red,
    svg: "https://static.matterapp.com/static-assets/empathy-1589765090345.svg",
  },
  {
    name: 'Listening',
    colorPalette: SkillColorPalette.Green,
    svg: "https://static.matterapp.com/static-assets/listening-1589765261909.svg",
  },
  {
    name: 'Verbal Communication',
    colorPalette: SkillColorPalette.Blue,
    svg: "https://static.matterapp.com/static-assets/verbal_communication-1589762654049.svg",
  },
];

export const skillsListWithIds = () => {
  for (let i = 0; i < skills.length; i++) {
    skills[i].id = i + 1;
  }
  return skills;
};

export const normalizedSkillsMap = {};

skills.forEach((skill) => {
  const colorPalette = colorPalettes[skill.colorPalette];
  const { primary, background, backgroundAlt, borderColor } = colorPalette;
  const valueForMap = {
    ...skill,
    primary,
    background: background || '#EEF7FF',
    backgroundAlt: backgroundAlt || background || '#EEF7FF',
    borderColor: borderColor || 'transparent',
    text: '#FFFFFF',
  };
  const normalizedSkillName = normalizeSkillName(skill.name);
  normalizedSkillsMap[normalizedSkillName] = valueForMap;
});
