import React from 'react';
import { SVG, Path } from './consts';

const PulseIcon = (props) => (
  <SVG {...props}>
    <Path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" />
    <Path d="M12.6302 11.6667C13.3666 11.6667 13.9635 11.0697 13.9635 10.3333C13.9635 9.59695 13.3666 9 12.6302 9C11.8938 9 11.2969 9.59695 11.2969 10.3333C11.2969 11.0697 11.8938 11.6667 12.6302 11.6667Z" />
    <Path d="M19.392 4.21443C19.7789 3.87272 20.3397 3.94744 20.6445 4.38132C21.2755 5.28828 21.805 6.32794 22.2011 7.44827C22.2369 7.50913 22.2673 7.57536 22.2913 7.64643C22.7344 8.971 22.996 10.4984 23 12C22.996 13.5016 22.7344 15.029 22.2913 16.3536C22.2673 16.4246 22.2369 16.4909 22.2011 16.5517C21.805 17.6721 21.2755 18.7117 20.6445 19.6187C20.3397 20.0526 19.7789 20.1273 19.392 19.7856C19.005 19.4439 18.9383 18.8151 19.2431 18.3812C19.799 17.5983 20.2471 16.7555 20.5749 15.8507C20.5842 15.7822 20.6 15.7138 20.6228 15.6464C21.018 14.4843 21.2202 13.3117 21.2162 12C21.2202 10.6883 21.018 9.5157 20.6228 8.35362C20.6 8.28621 20.5842 8.21777 20.5749 8.14928C20.2471 7.24445 19.799 6.40172 19.2431 5.61875C18.9383 5.18487 19.005 4.55614 19.392 4.21443Z" />
    <Path d="M4.7569 5.61875C5.06167 5.18487 4.99502 4.55614 4.60805 4.21443C4.22107 3.87272 3.6603 3.94744 3.35554 4.38132C2.72446 5.28828 2.195 6.32795 1.79889 7.44828C1.76344 7.50851 1.73329 7.57399 1.70944 7.64424C1.26263 8.97101 1.00393 10.4995 1 12C1.00393 13.5005 1.26263 15.029 1.70944 16.3558C1.73329 16.426 1.76344 16.4915 1.79889 16.5517C2.195 17.672 2.72446 18.7117 3.35554 19.6187C3.6603 20.0526 4.22107 20.1273 4.60805 19.7856C4.99502 19.4439 5.06167 18.8151 4.7569 18.3812C4.201 17.5983 3.75289 16.7556 3.42506 15.8507C3.41568 15.7815 3.39963 15.7123 3.37651 15.6442C2.97899 14.4843 2.77985 13.3129 2.78378 12C2.77985 10.6871 2.97899 9.51568 3.37651 8.35581C3.39963 8.2877 3.41568 8.21851 3.42506 8.14928C3.75289 7.24445 4.201 6.40172 4.7569 5.61875Z" />
    <Path d="M7.64523 7.97055C7.95909 7.5449 7.90578 6.91457 7.52615 6.56266C7.14652 6.21075 6.58433 6.27052 6.27046 6.69617C5.76386 7.39323 5.34369 8.22565 5.04744 9.12672C5.00882 9.19434 4.9769 9.26831 4.95303 9.34787C4.71346 10.156 4.57074 11.0991 4.56757 12C4.57074 12.9009 4.71346 13.844 4.95303 14.6521C4.9769 14.7317 5.00882 14.8057 5.04744 14.8733C5.34369 15.7744 5.76386 16.6068 6.27046 17.3038C6.58433 17.7295 7.14652 17.7893 7.52615 17.4373C7.90578 17.0854 7.95909 16.4551 7.64523 16.0295C7.22614 15.471 6.90324 14.8726 6.68478 14.2206C6.67776 14.1518 6.66422 14.0828 6.64373 14.0145C6.44331 13.3559 6.34819 12.7325 6.35136 12C6.34819 11.2675 6.44331 10.6441 6.64373 9.98549C6.66422 9.9172 6.67776 9.84817 6.68478 9.77937C6.90324 9.12737 7.22614 8.52897 7.64523 7.97055Z" />
    <Path d="M17.7295 6.69617C17.4157 6.27052 16.8535 6.21075 16.4738 6.56266C16.0942 6.91457 16.0409 7.5449 16.3548 7.97055C16.7738 8.52897 17.0968 9.12737 17.3152 9.77937C17.3222 9.84818 17.3358 9.9172 17.3563 9.98549C17.5567 10.6441 17.6518 11.2675 17.6486 12C17.6518 12.7325 17.5567 13.3559 17.3563 14.0145C17.3358 14.0828 17.3222 14.1518 17.3152 14.2206C17.0968 14.8726 16.7738 15.471 16.3548 16.0295C16.0409 16.4551 16.0942 17.0854 16.4738 17.4373C16.8535 17.7893 17.4157 17.7295 17.7295 17.3038C18.2361 16.6068 18.6563 15.7744 18.9525 14.8733C18.9912 14.8057 19.0231 14.7317 19.047 14.6521C19.2865 13.844 19.4292 12.9009 19.4324 12C19.4292 11.0991 19.2865 10.156 19.047 9.34787C19.0231 9.26831 18.9912 9.19434 18.9525 9.12672C18.6563 8.22565 18.2361 7.39323 17.7295 6.69617Z" />
  </SVG>
);

export default PulseIcon;
