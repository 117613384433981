import React, { Suspense } from 'react';
import 'app-consts';
import { Container, GlobalStyles } from '@matterapp/matter-ui';
import Toast from 'components/Toast/Toast';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Helmet } from 'react-helmet';
import store, { history } from 'config/store';
import Routes from 'routes/Routes';
import SettingsRoutes from 'routes/SettingsRoutes';
import client from 'config/apolloClient';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { NotificationsProvider, QuickNoteFeedback } from 'modules';
import DocumentZoomPreventer from 'components/atoms/DocumentZoomPreventer';
import { Loader } from '@matterapp/matter-ui';
import '@matterapp/matter-fonts/build/fonts.css';
import theme from 'theme';
import { ThemeProvider } from '@mui/material/styles';
import UserContext from 'context/UserContext';
import DialogContext from 'context/DialogContext';
import PulseFiltersContext from 'context/PulseFiltersContext';
import MobileMenuContext from 'context/MobileMenuContext';
import WorkspaceSettingsContext from 'context/WorkspaceSettingsContext';
import MobileSubMenu from 'components/MobileSubMenu/MobileSubMenu';

const renderQuickNote = ({
  isEmpty,
  form,
}) => (isEmpty ? null : form);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <DocumentZoomPreventer>
        <ApolloProvider client={client}>
          <ReduxProvider store={store}>
            <NotificationsProvider>
              <Toast />
              <BrowserRouter history={history}>
                <UserContext.Provider history={history}>
                  <DialogContext.Provider>
                    <PulseFiltersContext.Provider>
                      <WorkspaceSettingsContext.Provider>
                        <MobileMenuContext.Provider>
                          <ScrollToTop>
                            <Container>
                              <GlobalStyles />
                              <Helmet
                                defaultTitle="Matter"
                                titleTemplate="%s | Matter"
                              />
                              <Suspense fallback={<Loader />}>
                                <QuickNoteFeedback>
                                  {renderQuickNote}
                                </QuickNoteFeedback>
                                <Routes />
                                <SettingsRoutes />
                                <MobileSubMenu />
                              </Suspense>
                            </Container>
                          </ScrollToTop>
                        </MobileMenuContext.Provider>
                      </WorkspaceSettingsContext.Provider>
                    </PulseFiltersContext.Provider>
                  </DialogContext.Provider>
                </UserContext.Provider>
              </BrowserRouter>
            </NotificationsProvider>
          </ReduxProvider>
        </ApolloProvider>
      </DocumentZoomPreventer>
    </ThemeProvider>
  );
}

export default App;
