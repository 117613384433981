import { FREQUENCY_NOUNS } from 'app-consts';

export const REPLACE_RATING_TEXT = '%r';
export const REPLACE_SKILL_NAME = '%s';
export const REPLACE_ABILITIES_COUNT = '%a';
export const REPLACE_FREQUENCY_TEXT = '%f';
export const REPLACE_NAME = '%n';

export const DEFAULT_NOTE = `${REPLACE_RATING_TEXT}, I left you a new score on ${REPLACE_SKILL_NAME} for the last ${REPLACE_FREQUENCY_TEXT}.`;

export const skillRatingsTitle = {
  1: 'Back to basics',
  2: 'Making strides',
  3: 'Keep it up',
  4: 'Almost there',
  5: 'Flawless',
};

/** Utils for the recurring response flow timeline. */

export const MAX_STEP_WIDTH = 620;

/**
 * The new rater scale is from 1 -5 while the old rater is 0 - 10.
 * New ratings are on a 1 - 10 scale (2, 4, 6, 8, 10), so getting
 * the value is dividing the rating by 2 and getting the ceiling value.
 * Ratings of 0 will automatically be 1.
 * @param { Number } rating: The rating to convert.
 * @returns { Number } The converted rating to display.
 */
export const getRating = (rating) => {
  const displayRating = JSON.parse(rating);
  if (displayRating == null) {
    return displayRating;
  }
  return Math.ceil(displayRating / 2) || 1;
};

/**
 * New ratings are on a 1 - 5 scale. To ensure that they are
 * compatible with old ratings, the values will be doubled.
 * @param { Number } rating: The rating to convert.
 * @returns { Number } The converted rating to set.
 */
export const setRating = (rating = 0) => {
  return rating * 2;
};

/**
 * Gets all the details needed from a skill to generate a default note.
 * @param { Object } skill: The skill to get details from.
 * @returns { Object } The formatted object of the details needed to generate a default note.
 */
export const getSkillDetailsForDefaultNote = (skill) => {
  const skillToRate = skill || {};
  const actionItems = skillToRate.actionItemSelections || [];
  const currentAbilities = actionItems.filter((ability) => ability.selected);
  const currentRating = skillToRate.currentRating || 1;
  const { name } = skillToRate.skill || {};
  return {
    currentAbilities,
    currentRating: currentRating / 2,
    skillName: name,
    frequency: skill.frequency,
  };
};

/**
 * Generates a note based on the skill level.
 * @param { Object } noteProps: {
 *   @param { Number } currentRating: The current rating of the skill.
 *   @param { String } skillName: The name of the skill.
 * }
 * @returns { String } The generated skill level note.
 */
export const getGeneratedSkillLevelNote = (noteProps = {}) => {
  const { currentRating, skillName, frequency } = noteProps;
  return DEFAULT_NOTE
    .replace(REPLACE_RATING_TEXT, skillRatingsTitle[currentRating])
    .replace(REPLACE_SKILL_NAME, skillName)
    .replace(REPLACE_FREQUENCY_TEXT, FREQUENCY_NOUNS[frequency]);
};

/**
 * Generates a default note for a skill based on the skill rating and number of abilities selected.
 * @param { Object } skill: The skill to generate a default note for.
 * @returns { String } The generated default note for the skill.
 */
export const getGeneratedDefaultNote = (skillToRate = {}) => {
  const skillProps = getSkillDetailsForDefaultNote(skillToRate);
  return getGeneratedSkillLevelNote(skillProps);
};
