import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import Container from '../Container';
import LabelContainer from '../LabelContainer';
import Comment from './Comment';

const ActionItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

class Comments extends React.PureComponent {
  render() {
    return (
      <Container
        backgroundColor={theme.colors.blues[5]}
        onClick={this.props.onClick}
      >
        <ActionItemContainer role="checkbox" tabIndex={-1}>
          <Comment isActive={false} />
          <LabelContainer>Comment</LabelContainer>
        </ActionItemContainer>
      </Container>
    );
  }
}

Comments.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Comments;
