import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { HAS_ACTIVE_STRIPE_SUBSCRIPTION } from 'graphql-queries';

export const useStripeSubscription = (params = {}) => {
  const { workspace = {} } = params;
  const { id, stripeCustomerId } = workspace;
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stripeSubscriptionMutation, { data }] = useMutation(
    HAS_ACTIVE_STRIPE_SUBSCRIPTION
  );
  let isMounted = true;

  useEffect(() => {
    if (stripeCustomerId) {
      setLoading(true);
      stripeSubscriptionMutation({
        variables: {
          workspaceId: workspace.id,
        },
      }).then(({ data }) => {
        if (isMounted) {
          setLoading(false);
          setHasActiveSubscription(data.hasActiveStripeSubscription);
        }
      });
    } else {
      if (isMounted) {
        setLoading(false);
        setHasActiveSubscription(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [id, stripeCustomerId]);

  return {
    ...data,
    hasActiveSubscription,
    showUpgrade: !!id && !loading && !hasActiveSubscription,
    loading,
  };
};
