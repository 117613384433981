import styled from 'styled-components';

const Paragraph = styled.p`
  ${() => `
    color: gray;
    font-size: 17px;
    line-height: 24px;
  `}
`;

export default Paragraph;
