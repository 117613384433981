import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8325 2.91747V10.8325H2.91747C2.2727 10.8325 1.75 11.3552 1.75 12C1.75 12.6448 2.2727 13.1675 2.91747 13.1675H10.8325V21.0825C10.8325 21.7273 11.3552 22.25 12 22.25C12.6448 22.25 13.1675 21.7273 13.1675 21.0825V13.1675H21.0825C21.7273 13.1675 22.25 12.6448 22.25 12C22.25 11.3552 21.7273 10.8325 21.0825 10.8325H13.1675V2.91747C13.1675 2.2727 12.6448 1.75 12 1.75C11.3552 1.75 10.8325 2.2727 10.8325 2.91747Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
  </svg>  
);

Icon.defaultProps = {
  fill: '#99999B',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;