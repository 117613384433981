import React from 'react';
import TextArea from './WithCount';
import { VARIANTS } from '../styles';

const Simple = (props) => {
  return (
    <TextArea
      minErrorMessage={`(Min ${props.minLength} Characters)`}
      {...props}
      allowErrorBeforeBlur
      variant={VARIANTS.SIMPLE}
      showErrorOnEmpty
      hideCharCountDenominator
      showRemainingCharacterCount={50}
    />
  );
};

export default Simple;
