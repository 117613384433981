import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { ConfettiCannon } from '../../components/Confetti';
import ThankYou from './ThankYou';
import HeaderAndSubheader from './HeaderAndSubheader';
import Spinner from './Spinner';
import Checkmark from './Checkmark';
import Shockwave from './Shockwave';
import Starburst from './Starburst';
import StarburstCTA from './StarburstCTA';

const COLORS = {
  PURPLE: 'purple',
  LIGHT: 'light',
  TRANSPARENT: 'transparent',
};

const getColorStyles = ({ color }) => {
  switch (color) {
  case COLORS.LIGHT:
    return `
      color: ${theme.colors.blacks[90]};
      background: rgba(255,255,255, 0.9);
      background-blend-mode: saturation;
      backdrop-filter: blur(5px);
    `;
  case COLORS.TRANSPARENT:
    return `
      color: ${theme.colors.blacks[90]};
      background: transparent;
    `;
  default:
    return `
      color: #ffffff;
      background: rgba(52, 55, 153, 0.95);
    `;
  }
};

const StyledOverlay = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${getColorStyles}
`;

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30vh;
  align-items: center;
  min-height: 100%;
  height: 100%;
  padding: ${theme.sizes.padding[50]};
  animation: delayedFadeIn 3s;

  @keyframes delayedFadeIn {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const getColor = ({ color, light }) => {
  if (light) {
    return COLORS.LIGHT;
  }
  return Object.values(COLORS).includes(color) ? color : COLORS.PURPLE;
};

class FullScreenOverlay extends React.PureComponent {
  static colors = COLORS;

  handleOverlayClick = (e) => {
    const { onRequestClose } = this.props;
    if (!onRequestClose) { return; }
    e.preventDefault();
    e.stopPropagation();
    const { target } = e;
    if (target && target.tagName && target.tagName.toLowerCase() === 'div') {
      onRequestClose(e);
    }
  };

  render = () => {
    const { isOpen, children, confetti } = this.props;
    const color = getColor(this.props);
    if (!isOpen) { return null; }
    return (
      <StyledOverlay onClick={this.handleOverlayClick} className="full-screen-overlay" color={color}>
        <StyledFlex>
          {confetti && <ConfettiCannon active />}
          {children}
        </StyledFlex>
      </StyledOverlay>
    );
  };
}

FullScreenOverlay.defaultProps = {
  color: COLORS.PURPLE,
  confetti: false,
  children: null,
  onRequestClose: null,
  light: false, // `light` prop is deprecated. use `color` prop instead.
};

FullScreenOverlay.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  confetti: PropTypes.bool,
  children: PropTypes.node,
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

FullScreenOverlay.HeaderAndSubheader = HeaderAndSubheader;
FullScreenOverlay.Spinner = Spinner;
FullScreenOverlay.Checkmark = Checkmark;
FullScreenOverlay.ThankYou = ThankYou;
FullScreenOverlay.Shockwave = Shockwave;
FullScreenOverlay.Starburst = Starburst;
FullScreenOverlay.StarburstCTA = StarburstCTA;

export default FullScreenOverlay;
