import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import { Confirm } from '../../Confirm';

const WithConfirmClose = (props) => {
  const {
    cancelButtonLabel,
    confirmButtonLabel,
    header,
    isConfirmOpen,
    onClickCancel,
    onClickConfirm,
    onClose,
    onCloseConfirm,
    subHeader,
  } = props;
  const [isConfirmModalOpen, setIsConfirmOpen] = useState(false);
  const confirmProps = {
    cancelButtonLabel,
    confirmButtonLabel,
    header,
    subHeader,
  };

  const handleCloseConfirm = (e) => {
    setIsConfirmOpen(false);
    onCloseConfirm(e, props);
  };

  const handleCancel = async (e) => {
    await onClickCancel(e, props);
    handleCloseConfirm(e);
  };

  const handleClose = async (e) => {
    await onClickConfirm(e, props);
    handleCloseConfirm(e);
    onClose(e, props);
  };

  const handleOpenConfirm = () => {
    setIsConfirmOpen(true);
  };

  useEffect(() => {
    setIsConfirmOpen(isConfirmOpen);
  }, [isConfirmOpen]);

  return (
    <>
      <Modal {...props} onClose={handleOpenConfirm} />
      <Confirm.Simple
        isOpen={isConfirmModalOpen}
        onClickConfirm={handleClose}
        onClickCancel={handleCancel}
        onClose={handleCloseConfirm}
        {...confirmProps}
      />
    </>
  );
};

WithConfirmClose.defaultProps = {
  confirmButtonLabel: 'Yes',
  cancelButtonLabel: 'Cancel',
  header: 'Confirm Close',
  subHeader: 'Are you sure you want to close this modal?',
  onClickCancel: () => null,
  onClickConfirm: () => null,
  onClose: () => null,
  onCloseConfirm: () => null,
};

WithConfirmClose.propTypes = {
  /** Button label for cancel button in confirm modal. */
  cancelButtonLabel: PropTypes.string,
  /** Button label for confirm button in confirm modal. */
  confirmButtonLabel: PropTypes.string,
  /** Header for confirm modal. */
  header: PropTypes.string,
  /** If confirm modal is open. */
  isConfirmOpen: PropTypes.bool,
  /** If modal is open. */
  isOpen: PropTypes.bool,
  /** Callback when cancel button is clicked in confirm modal. */
  onClickCancel: PropTypes.func.isRequired,
  /** Callback when confirm button is clicked in confirm modal. */
  onClickConfirm: PropTypes.func.isRequired,
  /** Callback when modal closes. */
  onClose: PropTypes.func.isRequired,
  /** Callback when confirm modal closes. */
  onCloseConfirm: PropTypes.func.isRequired,
  /** Sub Header for confirm modal. */
  subHeader: PropTypes.string,
};

export default WithConfirmClose;
