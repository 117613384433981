import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Buttons from '../Buttons';
import theme from '@matterapp/matter-theme';

export const DATA_ROLE = 'footer';
const borderRadius = theme.borderRadius.M;

const ChildrenContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: ${({ centered }) => (centered ? 'center' : 'normal')};
`;

const LeftContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

const RightContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

const FooterContainer = styled.div`
  align-items: stretch;
  background: ${theme.colors.white};
  border-top: ${({ showBorder }) =>
    showBorder ? `1px solid ${theme.colors.blacks[10]}` : 'none'} ;
  display: flex;
  flex-direction: row;
  padding: ${theme.spacing.single};
  position: sticky;
  white-space: nowrap;
  ${({ left, right }) => {
    if (left && right) {
      return `& ${ChildrenContainer} {
        min-width: ${theme.spacing.single};
        max-width: ${theme.spacing.single};
      }`;
    }
  }}
  ${({ left }) => {
    if (left) {
      return `& ${LeftContainer} {
        flex: 1 1 100%;

        & button, & a {
          width: 100%;
          padding-left: ${theme.spacing.threeQuarters};
          padding-right: ${theme.spacing.threeQuarters};
          overflow: hidden;
          text-overflow: ellipsis;
        }
        & a {
          display: flex;
          align-items: center;
        }
      }`;
    }
  }}
  ${({ right }) => {
    if (right) {
      return `& ${RightContainer} {
        flex: 1 1 100%;

        & button, & a {
          width: 100%;
          padding-left: ${theme.spacing.threeQuarters};
          padding-right: ${theme.spacing.threeQuarters};
          overflow: hidden;
          text-overflow: ellipsis;
        }
        & a {
          display: flex;
          align-items: center;
        }
      }`;
    }
  }}
  border-radius: 0 0 ${borderRadius} ${borderRadius};
  ${theme.media.S`
    padding: ${theme.spacing.singleAndHalf};
  `}
`;

/**
 * Footer variant to display content or buttons. Pass in a 'left' and 'right' button
 * and the container will automatically add a spacer between the buttons.
 * When passing only one button (either in 'left' or 'right'), button will
 * take full width of the footer.
 */
const Footer = ({ centered, children, className, left, right, showBorder }) => (
  <FooterContainer
    className={className}
    data-role={DATA_ROLE}
    left={!!left}
    right={!!right}
    showBorder={showBorder}
  >
    <LeftContainer>{left}</LeftContainer>
    <ChildrenContainer centered={centered}>{children}</ChildrenContainer>
    <RightContainer>{right}</RightContainer>
  </FooterContainer>
);

/**
 * Deprecated. ButtonContainer now is default Footer.
 */
Footer.ButtonContainer = Footer;

Footer.Buttons = Buttons;

Footer.ChildrenContainer = ChildrenContainer;

Footer.dataRole = Footer;

Footer.defaultProps = {
  left: null,
  right: null,
  showBorder: true,
};

Footer.propTypes = {
  /** Center align children elements. (Does not affect left or right content.) */
  centered: PropTypes.bool,
  /** Children elements. */
  children: PropTypes.node,
  /** Additional class. */
  className: PropTypes.string,
  /** Left contents. */
  left: PropTypes.node,
  /** Right contents. */
  right: PropTypes.node,
  /** Shows top border. */
  showBorder: PropTypes.bool,
};

export default Footer;
