import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.h2`
  line-height: 1.3;
  font-size: ${() => theme.sizes.fontSizes[50]};
  color: ${() => theme.colors.purple};
  font-weight: bold;

  padding: ${() => `${theme.sizes.padding[30]} 0`};
  margin-top: -${() => theme.sizes.padding[60]} !important;
  margin-bottom: 0;
`;

const Subtitle = styled.span`
  font-size: ${() => theme.sizes.fontSizes[40]};
  font-weight: normal;
  color: ${() => theme.colors.blacks[50]};
  padding-left: 5px;
`;

const Title = ({ subtitle, children }) => (
  <Container>
    <span>{children}</span>
    {subtitle ? (
      <Subtitle>
        {subtitle}
      </Subtitle>
    ) : null}
  </Container>
);

Title.defaultProps = {
  subtitle: null,
};

Title.propTypes = {
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Title;
