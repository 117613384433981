import timezones from 'timezones-list';

export const TIMEZONES_LIST = Object.values(timezones).map((timezone) => {
  const { name, tzCode, utc } = timezone;
  return {
    ...timezone,
    filterValue: `${tzCode.replace('_', ' ')},${name},${utc}`,
    label: name,
    value: tzCode,
  };
});