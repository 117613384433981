import React from 'react';
import { Colors, Thumbnail, Paths } from '../Thumbnail';

const Impressive = (props) => {
  return (
    <Thumbnail color={Colors.Red} width={312} height={112} top={-4} left={0} {...props}>
      <Paths.L30 d="M129.446 42.5515L144.476 61.4015L141.156 84.9415L125.996 66.5815L129.446 42.5515Z" />
      <Paths.L30 d="M114.077 23.3915L129.437 42.5515L125.977 66.5116L110.917 47.5815L114.077 23.3915Z" />
      <Paths.L30 d="M126.017 66.6515L141.157 85.0015L137.787 109.572L122.767 90.4715L126.017 66.6515Z" />
      <Paths.L30 d="M110.916 47.6315L125.996 66.5815L122.726 90.4515L107.586 71.7315L110.916 47.6315Z" />
      <Paths.L50 d="M184.126 27.3715L199.036 46.1315L171.516 53.7515L156.446 35.0115L184.126 27.3715Z" />
      <Paths.L50 d="M184.106 27.3215L157.776 34.5915C156.946 34.8215 156.056 34.5315 155.526 33.8515L141.406 15.8915L169.086 8.25153L184.106 27.3215Z" />
      <Paths.L50 d="M171.476 53.7715L145.886 60.9715C145.056 61.2015 144.176 60.9215 143.636 60.2515L129.486 42.5215L156.356 35.0215L171.476 53.7715Z" />
      <Paths.L50 d="M141.207 15.9015L156.347 34.9915L129.437 42.4615L114.187 23.3515L141.207 15.9015Z" />
      <Paths.L40 d="M198.747 49.8515C198.947 48.3315 197.537 47.1015 196.057 47.5015L171.637 54.2115L168.307 78.0315L194.627 70.7715C195.457 70.5415 196.067 69.8415 196.177 68.9915L198.747 49.8515Z" />
      <Paths.L40 d="M192.896 71.3115L168.296 78.1015L165.196 102.172L191.506 94.9115C192.336 94.6815 192.946 93.9815 193.056 93.1215L195.926 70.5615L192.896 71.3115Z" />
      <Paths.L40 d="M168.236 78.0715L171.606 54.2215L144.576 61.5315L141.326 85.4115L168.236 78.0715Z" />
      <Paths.L40 d="M165.037 102.252L168.237 78.1015L141.307 85.4815L138.017 109.712L165.037 102.252Z" />
      <Paths.L10
        evenOdd
        d="M200.516 45.1215L170.466 7.35153C169.966 6.72153 169.136 6.45153 168.366 6.66153L113.886 21.6915C113.116 21.9015 112.546 22.5515 112.436 23.3515L105.786 71.2515C105.706 71.8015 105.866 72.3515 106.206 72.7815L136.536 110.712C137.036 111.342 137.866 111.602 138.636 111.392L193.116 96.3615C193.896 96.1515 194.466 95.4915 194.566 94.6915L200.936 46.6315C201.006 46.1015 200.856 45.5515 200.516 45.1215ZM130.396 42.8115L156.166 35.6215L170.566 53.4815L144.716 60.7515L130.396 42.8115ZM145.046 61.9515L170.966 54.9415L167.756 77.6615L141.946 84.7015L145.046 61.9515ZM143.926 61.5515L140.806 83.6815L126.556 66.4215L129.796 43.8315L143.926 61.5515ZM129.636 41.8715L115.486 24.1415C115.546 24.1115 115.606 24.0815 115.666 24.0615L141.376 16.9715L155.436 34.7115L129.636 41.8715ZM125.626 65.2215L111.806 47.8515L114.936 25.3015L128.886 42.7015L125.626 65.2215ZM172.126 54.6315L198.056 47.5015L195.056 70.1115L168.956 77.3115L172.126 54.6315ZM171.716 53.1515L157.366 35.3015L183.466 28.1015L197.676 45.9615L171.716 53.1515ZM156.596 34.3615L142.646 16.6215L168.046 9.61152C168.396 9.51152 168.776 9.64152 169.006 9.92152L182.716 27.1515L156.596 34.3615ZM111.596 49.3315L125.436 66.7315V66.7415L122.436 88.6915L108.796 71.6315C108.636 71.4315 108.566 71.1815 108.606 70.9415L111.596 49.3315ZM123.366 89.8515L126.366 67.9215L140.586 85.1615L137.506 107.552L123.366 89.8515ZM138.956 108.452C138.876 108.472 138.796 108.482 138.716 108.482L141.786 85.8915L167.616 78.8115L164.626 101.372L138.956 108.452ZM191.326 94.0115L165.886 101.032L168.786 78.5215L194.906 71.3115L191.996 93.2515C191.936 93.6115 191.676 93.9115 191.326 94.0115Z"
      />
      <Paths.L30 d="M71.5164 68.9215L56.4863 50.0715L59.8064 26.5315L74.9664 44.8915L71.5164 68.9215Z" />
      <Paths.L30 d="M86.8761 88.0815L71.5161 68.9215L74.9762 44.9615L90.0361 63.8915L86.8761 88.0815Z" />
      <Paths.L30 d="M74.9462 44.8115L59.8062 26.4615L63.1761 1.89154L78.1962 20.9915L74.9462 44.8115Z" />
      <Paths.L30 d="M90.0463 63.8415L74.9663 44.8916L78.2362 21.0215L93.3762 39.7415L90.0463 63.8415Z" />
      <Paths.L50 d="M16.826 84.1015L1.91602 65.3415L29.436 57.7215L44.506 76.4615L16.826 84.1015Z" />
      <Paths.L50 d="M16.8564 84.1515L43.1864 76.8815C44.0164 76.6515 44.9064 76.9415 45.4364 77.6215L59.5564 95.5815L31.8763 103.222L16.8564 84.1515Z" />
      <Paths.L50 d="M29.4766 57.7015L55.0665 50.5015C55.8965 50.2715 56.7765 50.5515 57.3165 51.2215L71.4666 68.9515L44.5966 76.4515L29.4766 57.7015Z" />
      <Paths.L50 d="M59.7463 95.5715L44.6064 76.4815L71.5164 69.0115L86.7664 88.1215L59.7463 95.5715Z" />
      <Paths.L40 d="M2.20652 61.6215C2.00652 63.1415 3.41646 64.3715 4.89646 63.9715L29.3165 57.2615L32.6465 33.4415L6.32652 40.7015C5.49652 40.9315 4.88647 41.6315 4.77647 42.4815L2.20652 61.6215Z" />
      <Paths.L40 d="M8.0564 40.1615L32.6564 33.3715L35.7563 9.30151L9.44641 16.5615C8.61641 16.7915 8.00636 17.4915 7.89636 18.3515L5.02637 40.9115L8.0564 40.1615Z" />
      <Paths.L40 d="M32.7264 33.4015L29.3564 57.2515L56.3864 49.9415L59.6364 26.0615L32.7264 33.4015Z" />
      <Paths.L40 d="M35.9265 9.22154L32.7266 33.3715L59.6565 25.9915L62.9465 1.76154L35.9265 9.22154Z" />
      <Paths.L10
        evenOdd
        d="M0.436237 66.3415L30.4862 104.112C30.9862 104.742 31.8163 105.012 32.5863 104.802L87.0662 89.7715C87.8362 89.5615 88.4062 88.9115 88.5162 88.1115L95.1662 40.2115C95.2462 39.6615 95.0862 39.1115 94.7462 38.6815L64.4162 0.751523C63.9162 0.121523 63.0862 -0.13847 62.3162 0.0715301L7.83626 15.1015C7.05626 15.3115 6.48619 15.9715 6.38619 16.7715L0.0161934 64.8315C-0.0538066 65.3715 0.106237 65.9215 0.436237 66.3415ZM70.5662 68.6615L44.7962 75.8515L30.3962 57.9915L56.2462 50.7215L70.5662 68.6615ZM55.9162 49.5215L29.9962 56.5315L33.2063 33.8115L59.0162 26.7715L55.9162 49.5215ZM57.0362 49.9215L60.1562 27.7915L74.4062 45.0515L71.1662 67.6415L57.0362 49.9215ZM71.3263 69.6015L85.4763 87.3315C85.4163 87.3615 85.3562 87.3915 85.2962 87.4115L59.5863 94.5015L45.5262 76.7615L71.3263 69.6015ZM75.3263 46.2515L89.1462 63.6215L86.0162 86.1715L72.0662 68.7715L75.3263 46.2515ZM28.8363 56.8415L2.90621 63.9715L5.90621 41.3615L32.0062 34.1615L28.8363 56.8415ZM29.2462 58.3215L43.5963 76.1715L17.4962 83.3715L3.28621 65.5115L29.2462 58.3215ZM44.3662 77.1115L58.3162 94.8515L32.9162 101.862C32.5662 101.962 32.1863 101.832 31.9563 101.552L18.2462 84.3215L44.3662 77.1115ZM89.3563 62.1315L75.5162 44.7315V44.7215L78.5162 22.7715L92.1562 39.8315C92.3162 40.0315 92.3863 40.2815 92.3463 40.5215L89.3563 62.1315ZM77.5863 21.6215L74.5863 43.5515L60.3662 26.3015L63.4462 3.91153L77.5863 21.6215ZM62.0062 3.01152C62.0862 2.99152 62.1662 2.98152 62.2462 2.98152L59.1762 25.5715L33.3463 32.6515L36.3363 10.0915L62.0062 3.01152ZM9.63619 17.4615L35.0763 10.4415L32.1762 32.9515L6.05623 40.1615L8.96627 18.2215C9.02627 17.8615 9.28619 17.5615 9.63619 17.4615Z"
      />
      <Paths.L30 d="M275.786 38.6415L256.937 53.6715L233.396 50.3515L251.756 35.1915L275.786 38.6415Z" />
      <Paths.L30 d="M294.947 23.2715L275.787 38.6315L251.827 35.1715L270.757 20.1115L294.947 23.2715Z" />
      <Paths.L30 d="M251.687 35.2115L233.337 50.3515L208.767 46.9816L227.867 31.9615L251.687 35.2115Z" />
      <Paths.L30 d="M270.707 20.1116L251.757 35.1916L227.887 31.9216L246.607 16.7816L270.707 20.1116Z" />
      <Paths.L50 d="M290.966 93.3215L272.206 108.232L264.586 80.7115L283.326 65.6415L290.966 93.3215Z" />
      <Paths.L50 d="M291.017 93.3015L283.747 66.9716C283.517 66.1416 283.807 65.2516 284.487 64.7216L302.447 50.6016L310.087 78.2816L291.017 93.3015Z" />
      <Paths.L50 d="M264.566 80.6715L257.366 55.0815C257.136 54.2515 257.416 53.3715 258.086 52.8315L275.816 38.6815L283.316 65.5515L264.566 80.6715Z" />
      <Paths.L50 d="M302.437 50.4015L283.347 65.5415L275.876 38.6315L294.986 23.3815L302.437 50.4015Z" />
      <Paths.L40 d="M268.487 107.942C270.007 108.142 271.237 106.732 270.837 105.252L264.127 80.8315L240.307 77.5015L247.567 103.822C247.797 104.652 248.497 105.262 249.347 105.372L268.487 107.942Z" />
      <Paths.L40 d="M247.026 102.092L240.236 77.4915L216.167 74.3915L223.427 100.702C223.657 101.532 224.357 102.142 225.217 102.252L247.776 105.122L247.026 102.092Z" />
      <Paths.L40 d="M240.267 77.4315L264.117 80.8015L256.807 53.7715L232.927 50.5215L240.267 77.4315Z" />
      <Paths.L40 d="M216.087 74.2316L240.236 77.4315L232.857 50.5016L208.626 47.2115L216.087 74.2316Z" />
      <Paths.L10
        evenOdd
        d="M273.217 109.712L310.986 79.6615C311.616 79.1615 311.887 78.3315 311.677 77.5615L296.646 23.0815C296.436 22.3115 295.786 21.7415 294.986 21.6315L247.087 14.9815C246.537 14.9015 245.987 15.0615 245.557 15.4015L207.626 45.7315C206.996 46.2315 206.737 47.0615 206.947 47.8315L221.977 102.312C222.187 103.092 222.846 103.662 223.646 103.762L271.707 110.132C272.237 110.202 272.787 110.052 273.217 109.712ZM275.526 39.5915L282.717 65.3615L264.857 79.7615L257.587 53.9115L275.526 39.5915ZM256.386 54.2415L263.396 80.1615L240.677 76.9515L233.636 51.1415L256.386 54.2415ZM256.787 53.1215L234.656 50.0015L251.917 35.7515L274.506 38.9915L256.787 53.1215ZM276.467 38.8315L294.197 24.6815C294.227 24.7415 294.257 24.8015 294.276 24.8615L301.366 50.5715L283.626 64.6315L276.467 38.8315ZM253.116 34.8215L270.486 21.0015L293.037 24.1315L275.636 38.0815L253.116 34.8215ZM263.707 81.3215L270.837 107.252L248.227 104.252L241.026 78.1515L263.707 81.3215ZM265.187 80.9115L283.037 66.5615L290.236 92.6615L272.376 106.872L265.187 80.9115ZM283.977 65.7915L301.717 51.8415L308.727 77.2415C308.827 77.5915 308.697 77.9715 308.417 78.2015L291.187 91.9115L283.977 65.7915ZM269.006 20.7915L251.607 34.6315H251.597L229.646 31.6315L246.707 17.9915C246.897 17.8315 247.156 17.7615 247.396 17.8015L269.006 20.7915ZM228.486 32.5615L250.417 35.5615L233.177 49.7815L210.787 46.7015L228.486 32.5615ZM209.886 48.1515C209.866 48.0715 209.857 47.9915 209.857 47.9115L232.447 50.9815L239.526 76.8115L216.967 73.8215L209.886 48.1515ZM224.327 100.522L217.307 75.0815L239.817 77.9815L247.026 104.102L225.087 101.192C224.727 101.132 224.427 100.872 224.327 100.522Z"
      />
      <Paths.L30 d="M208.707 31.7815C202.777 31.7815 197.937 26.9515 197.937 21.0115C197.937 15.0715 202.777 10.2415 208.707 10.2415C214.637 10.2415 219.477 15.0715 219.477 21.0115C219.487 26.9515 214.647 31.7815 208.707 31.7815ZM208.707 15.2315C205.517 15.2315 202.917 17.8215 202.917 21.0215C202.917 24.2115 205.507 26.8115 208.707 26.8115C211.897 26.8115 214.496 24.2215 214.496 21.0215C214.506 17.8315 211.897 15.2315 208.707 15.2315Z" />
      <Paths.L30 d="M211.786 104.102C210.316 107.032 206.756 108.202 203.836 106.732C200.916 105.262 199.736 101.702 201.206 98.7815C202.676 95.8615 206.236 94.6815 209.156 96.1515C212.076 97.6215 213.256 101.192 211.786 104.102Z" />
      <Paths.L30 d="M101.667 21.5115C100.327 24.1715 97.0966 25.2415 94.4366 23.9015C91.7766 22.5615 90.7065 19.3315 92.0465 16.6715C93.3765 14.0215 96.6165 12.9415 99.2765 14.2815C101.937 15.6315 103.007 18.8615 101.667 21.5115Z" />
      <Paths.L30 d="M102.356 110.382C96.7161 110.382 92.126 105.792 92.126 100.152C92.126 94.5115 96.7161 89.9215 102.356 89.9215C107.996 89.9215 112.586 94.5115 112.586 100.152C112.586 105.792 108.006 110.382 102.356 110.382ZM102.356 94.6515C99.3261 94.6515 96.8561 97.1115 96.8561 100.152C96.8561 103.182 99.3161 105.652 102.356 105.652C105.386 105.652 107.856 103.192 107.856 100.152C107.856 97.1115 105.386 94.6515 102.356 94.6515Z" />
    </Thumbnail>
  );
};

export default Impressive;
