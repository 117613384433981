import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7295 16.6839C20.3518 17.0869 19.719 17.1073 19.3161 16.7295L12 9.87073L4.68394 16.7295C4.28102 17.1073 3.64819 17.0868 3.27046 16.6839C2.89273 16.281 2.91314 15.6482 3.31606 15.2705L12 7.12926L20.6839 15.2705C21.0869 15.6482 21.1073 16.281 20.7295 16.6839Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#3466F2',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
