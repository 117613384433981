import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Autocomplete from 'react-autocomplete';
import theme from '@matterapp/matter-theme';
import debounce from 'lodash/debounce';

import {
  InputContainer,
  InputErrorContainer,
} from '../Input/';

const AutocompleteContainer = styled.div`
  position: relative;
  font-size: ${() => theme.sizes.fontSizes[50]};
  color: ${() => theme.colors.black};
`;


const Menu = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  text-align: left;

  margin-top: -3px;
  z-index: 1;
  overflow: hidden;

  background: white;
  border-radius: 0 0 ${() => theme.inputs.borderRadius} ${() => theme.inputs.borderRadius};
  box-shadow: ${() => theme.shadows[50]};

  border-style: ${() => theme.inputs.borderStyle};
  border-width: ${() => theme.inputs.borderWidth};
  border-color: ${() => theme.inputs.borderActiveColor};
  border-top: 1px solid rgba(0,0,0, .1);

  max-height: 272px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const MenuItem = styled.div`
  padding: 15px ${() => theme.sizes.padding[50]};
  box-sizing: border-box;
  background: white;
  cursor: pointer;
  user-select: none;

  /*
    HACK: the borders are 1.5px so the inner contents overhang. The 1px fixes
  */
  width: calc(100% - 1px);

  ${({ isHighlighted }) => isHighlighted && `
    background: ${theme.colors.blacks[5]};
  `}
`;

const ItemEmail = styled.div`
  font-size: ${() => theme.sizes.fontSizes[40]};
  color: ${() => theme.colors.blacks[40]};
`;

// This allows tab to select the item
// There are PRs open to deal with this, but the maintainers havent merged them yet:
// * https://github.com/reactjs/react-autocomplete/issues/182
//
// NOTE: keep the vanilla, non-bound function.
Autocomplete.keyDownHandlers.Tab = function (event) { // eslint-disable-line
  if (this.state.highlightedIndex != null && this.state.isOpen) {
    // text entered + menu item has been highlighted + enter is hit -> update
    // value to that of selected menu item, close the menu
    event.preventDefault();
    const item = this.getFilteredItems(this.props)[this.state.highlightedIndex];
    const value = this.props.getItemValue(item);
    this.setState({
      isOpen: false,
      highlightedIndex: null,
    }, () => {
      this.refs.input.setSelectionRange(
        value.length,
        value.length
      );
      this.props.onSelect(value, item);
    });
  }
};

export default class ContactAutocomplete extends React.Component {
  constructor() {
    super();
    this.queryID = 0;
    this.state = {
      contacts: [],
    };
  }

  getNextQueryID = () => {
    this.queryID += 1;
    return this.queryID;
  }

  handleSearch = debounce((query) => {
    const { search } = this.props;

    // It is possible that a result from an earlier query can come in after the
    // result for a later query. To combat this, we ignore any results that
    // aren't from the most recent query.
    const localQueryID = this.getNextQueryID();

    return search(query).then((contacts) => {
      if (localQueryID === this.queryID) {
        this.setState({ contacts });
      }
    });
  }, 200)

  renderItem = (item, isHighlighted) => (
    <MenuItem
      key={item.email}
      isHighlighted={isHighlighted}

    >
      <div>{item.fullName || item.email}</div>
      <ItemEmail>{item.email}</ItemEmail>
    </MenuItem>
  )

  renderError = () => {
    const { errorMessage } = this.props;
    if (!errorMessage) { return null; }
    return (
      <InputErrorContainer>
        {errorMessage}
      </InputErrorContainer>
    );
  }

  renderMenu = (items, value) => {
    let contents = items;
    if (!value) {
      contents = (<MenuItem>Type the name of a contact</MenuItem>);
    } else if (items.length === 0) {
      return <div />;
    }

    return (<Menu>{contents}</Menu>);
  }

  // The lib needs a ref to the input itself, and Form.Input ||
  // SemanticInput does not provide the right thing.
  renderInput = (props) => (
    <InputContainer
      error={!!this.props.errorMessage}
      size={this.props.size}
      fluid={this.props.fluid}
    >
      <input
        type="text"
        placeholder={this.props.placeholder}
        autoComplete="off"
        className="email"
        data-rc={this.props.rcLabel}
        {...props}
      />
      {this.renderError()}
    </InputContainer>
  )

  render() {
    const { onChange, value, fluid } = this.props;
    const displayStyle = { display: fluid ? 'block' : 'inline-block' };

    return (
      <AutocompleteContainer style={displayStyle}>
        <Autocomplete
          ref={(ref) => {
            if (ref) {
              this.inputRef = ref;
            }
          }}
          wrapperStyle={displayStyle}
          getItemValue={(item) => item.email}
          items={this.state.contacts}
          value={value}
          onChange={(e) => {
            this.handleSearch(e.target.value);
            onChange(e.target.value);
          }}
          onSelect={(v) => onChange(v)}
          renderMenu={this.renderMenu}
          renderItem={this.renderItem}
          renderInput={this.renderInput}
        />
      </AutocompleteContainer>
    );
  }
}

ContactAutocomplete.defaultProps = {
  size: 'massive',
  fluid: false,
  errorMessage: null,
  placeholder: 'name@company.com',
  rcLabel: 'contact-autocomplete-input'
};

ContactAutocomplete.propTypes = {
  search: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  size: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  fluid: PropTypes.bool,
  rcLabel: PropTypes.string,
};
