import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../../Dropdown/Multiple';
import { FROM_FILTER } from '../consts';

export const formatMembersForDropdown = (members = []) => {
  const membersList = members.sort((a, b) => {
    let first = a.person.fullName || '';
    let second = b.person.fullName || '';
    if (first === second) {
      first = a.person.email || '';
      second = b.person.email || '';
    }
    return  first.toLowerCase() > second.toLowerCase() ? 1 : -1;
  });

  return membersList.map((member) => {
    const { fullName, id, person = {}, userId } = member;
    return {
      ...member,
      label: person.fullName || fullName,
      value: person.userId || userId || id,
    };
  });
};

const From = (props) => {
  const { members, ...otherProps } = props;
  return (
    <Dropdown
      {...otherProps}
      displayValue={FROM_FILTER.DROPDOWN_LABEL}
      items={formatMembersForDropdown(members)}
      menuProps={FROM_FILTER.MENU_PROPS}
    />
  );
};

From.defaultProps = {
  members: [],
};

From.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
      userId: PropTypes.string,
    })
  ),
};

export default From;
