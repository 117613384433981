import React from 'react';
import { ClickableLink } from '@matterapp/matter-ui';
import { WarningMessage } from './styles';
import { FEEDBACK_RITUAL_PANEL_TEXT, TextReplace } from '../sharedConsts';

export const ReminderOffMessage = () => (
  <WarningMessage
    showColonAfterHeader
    header={FEEDBACK_RITUAL_PANEL_TEXT.REMINDER_OFF_MESSAGE_HEADER}
    content={FEEDBACK_RITUAL_PANEL_TEXT.REMINDER_OFF_MESSAGE}
  />
);

export const RitualRecapOffMessage = () => (
  <WarningMessage
    showColonAfterHeader
    header={FEEDBACK_RITUAL_PANEL_TEXT.RITUAL_RECAP_OFF_MESSAGE_HEADER}
    content={FEEDBACK_RITUAL_PANEL_TEXT.RITUAL_RECAP_OFF_MESSAGE}
  />
);

export const SlackRequiredMessage = () => (
  <WarningMessage
    showColonAfterHeader
    header={FEEDBACK_RITUAL_PANEL_TEXT.SLACK_REQUIRED_MESSAGE_HEADER}
    content={
      <TextReplace
        text={FEEDBACK_RITUAL_PANEL_TEXT.SLACK_REQUIRED_MESSAGE}
        replaceText={
          <ClickableLink
            key={FEEDBACK_RITUAL_PANEL_TEXT.SLACK_REQUIRED_MESSAGE_REPLACE_TEXT}
            href='mailto:hello@matterapp.com?subject=Slack%20Not%20Connected%3A%20Feedback%20Friday&body=Hi%20Matter%2C%0D%0A%0D%0AI%20need%20help%20enabling%20Feedback%20Friday%20but%20it%20looks%20like%20Slack%20is%20not%20connected%20to%20my%20workspace.%20Can%20you%20help%20me%20out%3F'
          >
            {FEEDBACK_RITUAL_PANEL_TEXT.SLACK_REQUIRED_MESSAGE_REPLACE_TEXT}
          </ClickableLink>
        }
      />
    }
  />
);

export default {
  ReminderOffMessage,
  RitualRecapOffMessage,
  SlackRequiredMessage,
};
