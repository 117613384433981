import { REWARD_QUANTITY_TYPES } from '../consts';

// Function to transform custom rewards into shape needed for Peer.WithAddRemove component
export const formatToPeersList = (list) => {
  return list.map(item => {
    const { name, imageUrl, quantity, quantityType } = item;

    return {
      fullName: name,
      email: name, // Peer.List.WithAddRemove needs email to run animation
      photoUrl: imageUrl,
      disabled: quantityType === REWARD_QUANTITY_TYPES.LIMITED && quantity < 1,
      ...item,
    };
  });
};