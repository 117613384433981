import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.51 6.75C7.53798 6.75 6.75 7.53798 6.75 8.51V15.49C6.75 16.462 7.53798 17.25 8.51 17.25H15.49C16.462 17.25 17.25 16.462 17.25 15.49V8.51C17.25 7.53798 16.462 6.75 15.49 6.75H8.51ZM7.75 8.51C7.75 8.09026 8.09026 7.75 8.51 7.75H15.49C15.9097 7.75 16.25 8.09026 16.25 8.51V15.49C16.25 15.9097 15.9097 16.25 15.49 16.25H8.51C8.09026 16.25 7.75 15.9097 7.75 15.49V8.51ZM14.0605 14.0605C13.8746 14.2464 13.5731 14.2464 13.3871 14.0605L12 12.6734L10.6129 14.0605C10.4269 14.2464 10.1254 14.2464 9.93948 14.0604C9.75351 13.8745 9.75351 13.573 9.93948 13.387L11.3266 11.9999L9.93948 10.6128C9.75351 10.4269 9.75351 10.1254 9.93948 9.9394C10.1254 9.75344 10.4269 9.75344 10.6129 9.9394L12 11.3265L13.3871 9.9394C13.5731 9.75344 13.8746 9.75344 14.0605 9.9394C14.2465 10.1254 14.2465 10.4269 14.0605 10.6128L12.6734 11.9999L14.0605 13.387C14.2465 13.573 14.2465 13.8745 14.0605 14.0605Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
