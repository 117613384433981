import React from 'react';
import styled from 'styled-components';
import IconCheckmark from '../../Icon/svg/IconCheckmark';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  width: 76px;
  text-align: center;
  svg path {
    fill: ${() => theme.colors.blues[60]};
  }
`;

export default () => (
  <Container>
    <IconCheckmark />
  </Container>
);
