import styled from 'styled-components';
import { Modal } from '@matterapp/matter-ui';
import { border, colors, lineHeight, font, spacing, media } from '@matterapp/matter-theme';

export const ModalBody = styled(Modal.Body)`
  color: ${colors.blacks[80]};
  padding: ${spacing.singleAndHalf} ${spacing.single};
  ${media.S`
    padding: ${spacing.singleAndHalf};
  `}
`;

export const RewardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.S`
    align-items: flex-start;
    flex-direction: row;
  `}
`;

export const RewardImage = styled.img`
  border-radius: ${({ isCustom }) => isCustom && border.radius.L};
`;

export const RewardName = styled.div`
  font-weight: ${font.weight.bold};
  font-size: ${font.size.S2};
  line-height: ${lineHeight.M};
  color: ${colors.blacks[80]};
  margin-bottom: ${spacing.half};
  ${media.S`
    margin-bottom: ${spacing.single};
  `}
`;

export const RewardDetailsContainer = styled.div`
  width: 100%;
  margin-top: ${spacing.singleAndHalf};
  ${media.S`
    margin-top: 0;
    margin-left: ${spacing.single};
  `}
`;


export const DropdownErrorMessage = styled.div`
  margin-top: ${spacing.single};
  color: ${colors.reds[40]};
  text-align: center;
`;


export const OrderCostSection = styled.div`
  font-size: ${font.size.S2};
  line-height: ${lineHeight.M};
`;

export const RewardDescription = styled.div`
  font-size: ${font.size.XS};
  line-height: ${lineHeight.S};
  color: ${colors.blacks[90]};
  margin-top: ${spacing.singleAndHalf};
`;


export const MODAL_IMAGE_WIDTH = 200;
