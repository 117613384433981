import * as t from './actionTypes';

/** ACTIONS */
/**
 * Initializes the data for the timeline.
 * @param { Object } initializeProps: {
 *   @param { Number } currentStep: The current step to initially display the timeline on.
 *   @param { String } feedbackId: The id of the feedback.
 *   @param { Array } qualitiesToRate: List of qualities to pick in the last step of the response flow.
 *   @param { Object } receiver: The receivcer data object containing the user's name.
 *   @param { Array } skillsToRate: The list of skills that are rated in the response flow.
 * }
 * @returns { Object } initializeTimeline action.
 */
export const initializeTimeline = (initializeProps = {}) => {
  return {
    type: t.INITIALIZE_TIMELINE,
    ...initializeProps
  };
};

/**
 * Resets the timeline to empty state removing all previous user selections.
 * @return { Object } resetTimeline action.
 */
export const resetTimeline = () => {
  return { type: t.RESET_TIMELINE };
};

/**
 * Change the rating of feedback.
 * @param { Number } feedbackRating: The rating.
 * @returns { Object } changeFeedbackRating action.
 */
export const changeFeedbackRating = (feedbackRating) => {
  return { type: t.CHANGE_FEEDBACK_RATING, feedbackRating};
};

/**
 * Change the ids of selected skills to rate.
 * @param { Object } skill: The skill selected/deselected.
 * @returns { Object } changeCurrentSkillsIds action.
 */
export const changeCurrentSkillsIds = (skill) => {
  return { type: t.CHANGE_SELECTED_SKILLS_IDS, skill};
};

/**
 * Change the current step of the timeline.
 * @param { String } currentStep: The current step of the response flow timeline.
 * @returns { Object } changeCurrentStep action.
 */
export const changeCurrentStep = (currentStep) => {
  return { type: t.CHANGE_CURRENT_STEP, currentStep };
};

/**
 * Change the current recurring step of the timeline. 
 * This is different from changeCurrentStep because the 2 flows have different step logic.
 * @param { String } currentRecurringStep: The current step of the response flow timeline.
 * @returns { Object } changeCurrentRecurringStep action.
 */
export const changeCurrentRecurringStep = (currentRecurringStep) => {
  return { type: t.CHANGE_CURRENT_RECURRING_STEP, currentRecurringStep };
};

/**
 * Change the current skill step of the timeline.
 * @param { String } currentSkillStep: The current skill step of the response flow timeline.
 * @returns { Object } changeCurrentSkillStep action.
 */
export const changeCurrentSkillStep = (currentSkillStep) => {
  return { type: t.CHANGE_CURRENT_SKILL_STEP, currentSkillStep };
};

/**
 * Change the current selected qualities.
 * @param { Array } currentQualities: List of selected quality ids.
 * @returns { Object } changeCurrentQualities action.
 */
export const changeCurrentQualities = (currentQualities) => {
  return { type: t.CHANGE_CURRENT_QUALITIES, currentQualities };
};

/**
 * Updates the rating values of a given skill given the index in the list.
 * @param { Object } ratingProps: {
 *   @param { Number } index: The index of this skill in the list of skills to update.
 *   @param { Object } skillToRate: The updated skill to rate.
 * }
 * @returns { Object } changeCurrentSkillRatings action.
 */
export const changeCurrentSkillRatings = (ratingProps = {}) => {
  return { type: t.CHANGE_CURRENT_SKILL_RATINGS, ...ratingProps };
};

/**
 * Updates the current user's full name.
 * @param { Object } currentUserFullName: The updated current user name.
 * @returns { Object } changeCurrentUserFullName action.
 */
export const changeCurrentUserFullName = (currentUserFullName) => {
  return { type: t.CHANGE_CURRENT_USER_FULL_NAME, currentUserFullName };
};

/**
 * Change the visible step of the timeline.
 * @param { String } visibleStep: The visible step of the response flow timeline.
 * @returns { Object } changeCurrentSkillStep action.
 */
export const changeVisibleStep = (visibleStep) => {
  return { type: t.CHANGE_VISIBLE_STEP, visibleStep };
};

/**
 * Sets the last stored generated note of a skill.
 * Used to reference if the default note message should be
 * shown if the  default message value has not changed.
 * @param { String } generatedDefaultNote: The generated default note.
 * @returns { Object } setGeneratedDefaultNote action.
 */
export const setGeneratedDefaultNote = (generatedDefaultNote) => {
  return { type: t.SET_GENERATED_DEFAULT_NOTE, generatedDefaultNote };
};

/**
 * Sets if the giver and receiver of the feedback are working together.
 * @param { Boolean } isWorkingTogether: If giver and receiver are currently working together.
 * @returns { Object } setWorkRelationship action.
 */
export const setWorkRelationship = (isWorkingTogether) => {
  return { type: t.SET_WORK_RELATIONSHIP, isWorkingTogether };
};

/**
 * Toggles flag to show default note message when clicking continue on skill note step.
 * @param { Boolean } isDefaultNoteMessageShown: If the default message will be shown.
 * @returns { Object } toggleIsDefaultNoteMessageShown action.
 */
export const toggleIsDefaultNoteMessageShown = (isDefaultNoteMessageShown) => {
  return {
    type: t.TOGGLE_IS_DEFAULT_NOTE_MESSAGE_SHOWN,
    isDefaultNoteMessageShown,
  };
};

/**
 * Displays the default note message if true.
 * @param { Boolean } showDefaultNoteMessage: If the default message is displayed.
 * @returns { Object } toggleShowDefaultNoteMessage action.
 */
export const toggleShowDefaultNoteMessage = (showDefaultNoteMessage) => {
  return { type: t.TOGGLE_SHOW_DEFAULT_NOTE_MESSAGE, showDefaultNoteMessage };
};

/**
 * Keeps a list of hashes of current requests making saving changes.
 * @param { String } saveHash: Hash string to differentiate different saves. (Multiple saves can occur).
 * @returns { Object } saveTimeline action.
 */
export const saveTimeline = (saveHash) => {
  return { type: t.SAVE_TIMELINE, saveHash };
};

/**
 * Removes a hash from the list of saved hashes.
 * @param { String } saveHash: Hash string to remove.
 * @returns { Object } saveTimelineComplete action.
 */
export const saveTimelineComplete = (saveHash) => {
  return { type: t.SAVE_TIMELINE_COMPLETE, saveHash };
};
