import React, { useState } from 'react';
import { Form, Loader } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import IconKarotDown from '@matterapp/matter-ui/build/components/Icon/svg/IconKarotDown';
import Content from '@matterapp/matter-ui/build/components/Content';
import {
  PromoCodeHeader,
  KarotContainer,
  PromoInputContainer,
  ApplyButton,
  ErrorIcon,
  SuccessIcon,
  FormInputContainer,
  IconContainer,
  VerifyIndicatorContainer,
  ErrorMessage
} from '../styles';
import { useEffect } from 'react';

function PromoSection({ applyPromoCode, isValid, errorMessage, isLoading }) {
  const [promocode, updatePromocode] = useState('');
  const [isValidOnApply, setIsValidOnApply] = useState(isValid);
  const [displayedErrorMessage, setDisplayedErrorMessage] = useState(errorMessage);
  const [isPromoSectionOpen, togglePromoSection] = useState(false);
  const isInvalid = !isValidOnApply && isValidOnApply !== null && promocode && !isLoading;

  useEffect(() => {
    setIsValidOnApply(isValid);
    setDisplayedErrorMessage(errorMessage);
  }, [isValid, errorMessage]);

  const onClickApply = () => {
    applyPromoCode(promocode);
    if (isValidOnApply !== isValid) {
      setIsValidOnApply(isValid);
    }
    if (errorMessage !== displayedErrorMessage) {
      setDisplayedErrorMessage(errorMessage);
    }
  };

  const onPromocodeChange = (_, { value }) => {
    updatePromocode(value); 
    setDisplayedErrorMessage('');
    setIsValidOnApply(null);
  };

  return (
    <>
      <PromoCodeHeader onClick={() => togglePromoSection(!isPromoSectionOpen)} isOpen={isPromoSectionOpen}>
        <KarotContainer isOpen={isPromoSectionOpen}>
          <IconKarotDown />
        </KarotContainer>
        Have a promo code?
      </PromoCodeHeader>
      <Content.Collapse isOpened={isPromoSectionOpen}>
        <PromoInputContainer isOpen={isPromoSectionOpen}>
          <FormInputContainer>
            <VerifyIndicatorContainer>
              <IconContainer>
                {isLoading && <Loader.Inline/>}
                {isValidOnApply && !isLoading && <SuccessIcon />}
                {isInvalid && !isLoading && <ErrorIcon />}
              </IconContainer>
            </VerifyIndicatorContainer>
            <Form.Input
              className='promo-code-input'
              size='M'
              errorMessage={isInvalid ? displayedErrorMessage : null}
              onChange={onPromocodeChange}
              placeholder='Enter code'
              isHideErrorMessage
              value={promocode}
              style={{ fontWeight: 600, fontSize: 16 }}
            />
          </FormInputContainer>
          <ApplyButton color='blue' onClick={onClickApply} disabled={!promocode || isValidOnApply}>Apply</ApplyButton>
        </PromoInputContainer>
        {isInvalid  && (
          <ErrorMessage className='card-error' role='alert'>
            {displayedErrorMessage}
          </ErrorMessage>
        )}
      </Content.Collapse>
    </>
  );
}

PromoSection.propTypes = { 
  applyPromoCode: PropTypes.func,
  isValid: PropTypes.bool,
  isLoading: PropTypes.bool,
  errorMessage: PropTypes.string
};

export default PromoSection;
