import React from 'react';
import { Resources } from '@matterapp/routing';
import { compose } from 'recompose';
import { Route } from 'react-router-dom';
import {
  RequestVerificationPage,
  MagicLinkVerificationPage,
  MagicLinkAuthPage
} from 'modules';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import {
  withStepPaths,
  withPageViewTracking,
  withRouteParams,
  withQueryParams,
} from 'hocs';
import {
  AuthSubmitVerificationCodePage,
  LoginGatewayPage,
  SlackLoginGatewayPage,
  VerifyEmailGatewayPage,
} from 'routes/pages/auth';
import AuthRedirectOnLoginPage from 'routes/pages/auth/containers/AuthRedirectOnLoginViaTokenPageContainer';
import AuthSignoutPage from 'routes/pages/auth/containers/AuthSignoutPageContainer';

export const AuthRequestVerification = (
  <Route
    exact
    path={Resources.authRequestVerification.pattern}
    element={(
      <RequestVerificationPage />
    )}
  />
);

const submitVerificationCodePageHoc = compose(
  withPageViewTracking({
    pageTitle: 'auth/submit-verification',
  }),
  withStepPaths({
    fallbackPath: Resources.authRequestVerification.path(),
    nextStepPath: Resources.homeMain.path(),
  })
);

export const AuthSubmitVerificationCode = (
  <PrivateRoute
    exact
    path={Resources.authSubmitVerification.pattern}
    component={submitVerificationCodePageHoc(AuthSubmitVerificationCodePage)}
    showIntercomMessenger
  />
);

const authMagicLinkPageHoc = compose(
  withRouteParams({
    token: true,
  }),
  withPageViewTracking({
    pageTitle: 'auth/magicLink',
  }),
  withStepPaths({
    nextStepPath: Resources.authLoginGateway.path(),
    fallbackPath: Resources.authRequestVerification.path(),
  })
);

const AuthMagicLinkComponent = authMagicLinkPageHoc(MagicLinkAuthPage);

export const AuthMagicLink = (
  <Route
    exact
    path={Resources.authMagicLink.pattern}
    element={(
      <AuthMagicLinkComponent />
    )}
    showIntercomMessenger
  />
);

const authMagicLinkVerifyPageHoc = compose(
  withPageViewTracking({
    pageTitle: 'auth/magicLink/verify',
  }),
  withStepPaths({
    fallbackPath: Resources.authRequestVerification.path(),
  }),
  withQueryParams
);

const AuthMagicLinkVerificationComponent = authMagicLinkVerifyPageHoc(MagicLinkVerificationPage);

export const AuthMagicLinkVerification = (
  <Route
    exact
    path={Resources.authMagicLinkVerify.pattern}
    element={(
      <AuthMagicLinkVerificationComponent />
    )}
    showIntercomMessenger
  />
);


export const AuthSignout = (
  <Route
    exact
    path={Resources.authSignout.pattern}
    element={(
      <AuthSignoutPage />
    )}
  />
);

export const AuthLoginGateway = (
  <Route
    exact
    path={Resources.authLoginGateway.pattern}
    element={(
      <LoginGatewayPage />
    )}
  />
);

export const AuthEmailVerificationGateway = (
  <PrivateRoute
    exact
    path={Resources.authVerifyEmailGateway.pattern}
    element={VerifyEmailGatewayPage}
  />
);

export const AuthRedirectOnLoginViaToken = (
  <Route
    exact
    path={Resources.authRedirectOnLoginViaToken.pattern}
    element={<AuthRedirectOnLoginPage />}
  />
);

export const AuthSlackLoginGateway = (
  <Route 
    exact
    path={Resources.authSlackLoginGateway.pattern}
    element={withQueryParams(SlackLoginGatewayPage)}
  />
);
