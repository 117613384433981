/**
 * From a list of feedback items, the ids are extracted and encoded to a hash.
 * @param { Array } feedItemsList: List of feedback feedItems.
 * @return { String } The encoded string of the lists of ids from the feedback list.
 */
export const encode = (feedItemsList = []) => {
  const listToEncode = feedItemsList.map((feedItem) => feedItem.id);
  return window.encodeURIComponent(window.btoa(listToEncode));
};

/**
 * From an encoded list of ids, decode the values to an array.
 * @param { String } hash: The encoded list of ids.
 * @returns { Array } List of decoded values.
 */
export const decode = (hash = '') => {
  const decodedHash = window.decodeURIComponent(hash);
  return window.atob(decodedHash).split(',');
};

export default { encode, decode };