import React, { useContext } from 'react';
import { Typography, Paper, Divider, Box } from '@mui/material';
import UserContext from 'context/UserContext/UserContext';
import { Checkbox, Input } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { ACTIONS } from './reducer';
import { getRecipients } from './consts';
import moment from 'moment-timezone';
import { feedbackFridayUtils } from '@matterapp/utils';
import { RadioNoBackground } from './styles';

const StyledRadioNoBackground = styled(RadioNoBackground)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

const ToggleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.blacks[5],
  width: '100%',
  padding: theme.spacing(2),
  marginBottom: '1px',
  maxWidth: '560px'
}));

const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3)
}));
  
const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  boxSizing: 'content-box',
  '&:nth-child(even)': {
    paddingTop: 0
  }
}));

const AllowanceBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'flex-start'
}));

export default function AudienceScreen(props) {
  const { state, dispatch } = props;
  const { 
    coins, 
    notifications, 
    showErrors, 
    sendOnMyBehalf, 
    recipients, 
    sendNow, 
    sendDate, 
    sendTime, 
    closeSurveyOnDate, 
    closingDate,
    hasRewards,
    frequency
  } = state;
  const { currentWorkspace, currentUser, currentTenant, isAdminMember } = useContext(UserContext);
  const { slackWorkspace } = currentWorkspace;
  const { fullName, slackHandle } = currentUser || {};
  const { rewardsSettings = {} } = currentTenant || {};
  const { isRewardsActive } = rewardsSettings;
  const defaultRecipients = getRecipients(currentTenant, currentWorkspace);
  const numberOfRecipients = recipients[0].value === 'ALL' ? defaultRecipients.find(({ value }) => value === 'ALL')?.label : recipients.length;
  const SENDER_OPTIONS = [`My Organization (${currentTenant?.name})`, slackHandle ? `${fullName} (@${slackHandle})` : fullName];
  const showRewardsSection = isRewardsActive && isAdminMember;
  const REWARDS_OPTIONS = ['Enabled', 'Disabled'];
  const repeatedSurvey = frequency !== 'once';
  const notificationCopy = slackWorkspace 
    ? 'All notifications are sent by @MatterBot on Slack.'
    : 'All notifications are sent by Matter on Teams.';

  return (
    <Box>
      {showRewardsSection ? (
        <Paper sx={{ padding: 0, mt: 3, ml: 1, mr: 1 }}>
          <TopSection>
            <Typography variant='h5' component='p'>
              Rewards (Recommended)
            </Typography>
          </TopSection>
          <Divider sx={{ margin: 0, mb: 1 }} />
          <Section>
            <Typography variant='body1' component='p'>
              Give Matter coins to members for completing a survey. This has been shown to increase participation rates and response times by 35%.
            </Typography>
            <AllowanceBlock>
              <StyledRadioNoBackground 
                onChange={() => {
                  dispatch({ type: ACTIONS.TOGGLE_REWARDS });
                }}
                value={hasRewards ? REWARDS_OPTIONS[0] : REWARDS_OPTIONS[1]}
                values={REWARDS_OPTIONS}
              />
              <InputWrapper>
                <Input
                  style={{ width: '132px', marginLeft: '16px', marginRight: '16px', color: hasRewards ? 'inherit': '#99999B' }}
                  type="number"
                  onChange={(event) => {
                    dispatch({ type: ACTIONS.UPDATE_COINS_PER_SURVEY, payload: event.target.value });
                  }}
                  disabled={!hasRewards}
                  errorMessage={showErrors ? 'Must be between 1-100,000' : null}
                  showErrorMessageBelow
                  errorMessageClassName="allowanceInputError"
                  value={(coins || 0).toString()} 
                />
                <Box sx={{ mb: 2, mt: 2 }}>
                  <Typography variant='h6' component='p'>
                  Coins per Member per Survey Completion
                  </Typography> 
                  <Typography variant='body2'>
                    {coins} Matter Coins = {coins / 10} USD
                  </Typography>
                </Box>
              </InputWrapper>
            </AllowanceBlock>
          </Section>
        </Paper>
      ) : null}

      <Paper sx={{ padding: 0, mt: 3, ml: 1, mr: 1 }}>
        <TopSection>
          <Typography variant='h5' component='p'>
            Your Notifications
          </Typography>
        </TopSection>
        <Divider sx={{ margin: 0, mb: 1 }} />
        <Section>
          <Typography variant='body1' component='p'>
            {notificationCopy}
          </Typography>
          <Box sx={{ mt: 2, mb: 1 }}>
            <ToggleContainer>
              <Typography variant='body1' component='p' >
                New Responses
              </Typography>
              <Checkbox.Toggle
                size='S' 
                checked={notifications.response}
                onChange={() => {
                  dispatch({ type: ACTIONS.TOGGLE_COMMENTS });
                }}
              />
            </ToggleContainer>
            <ToggleContainer>
              <Typography variant='body1' component='p'>
                Weekly Digest
              </Typography>
              <Checkbox.Toggle 
                size='S' 
                checked={notifications.digest}
                onChange={() => {
                  dispatch({ type: ACTIONS.TOGGLE_DIGEST });
                }}
              />
            </ToggleContainer>
          </Box>
        </Section>
      </Paper>

      <Paper sx={{ padding: 0, mt: 3, mb: '187px', ml: 1, mr: 1 }}>
        <TopSection>
          <Typography variant='h5' component='p'>
            Review Survey Details
          </Typography>
        </TopSection>
        <Divider sx={{ margin: 0, mb: 1 }} />
        <Section>
          <Typography variant='body1' component='p' sx={{ mb: 1 }}>
            Title: <b>{state.name}</b>
          </Typography>
          <Typography variant='body1' component='p' sx={{ mb: 1 }}>
            Anonymity: <b>{state.isAnonymous ? 'Anonymous' : 'Attributed'}</b>
          </Typography>
          <Typography variant='body1' component='p' sx={{ mb: 1 }}>
            Question(s): <b>{state.questions.length} per recipient</b>
          </Typography>
          <Typography variant='body1' component='p' sx={{ mb: 1 }}>
            Sender: <b>{sendOnMyBehalf ? SENDER_OPTIONS[1] : SENDER_OPTIONS[0]}</b>
          </Typography>
          <Typography variant='body1' component='p' sx={{ mb: 1 }}>
            Recipients: <b>{numberOfRecipients}</b>
          </Typography>
          <Typography variant='body1' component='p' sx={{ mb: 1 }}>
            Delivery: <b>{sendNow ? 'Now' : `on ${moment(sendDate).format('MMMM DD, YYYY')} at ${feedbackFridayUtils.getUSFormattedTime(sendTime)}`}</b>
          </Typography>
          {closeSurveyOnDate && !repeatedSurvey && (
            <Typography variant='body1' component='p' sx={{ mb: 1 }}>
              Closing: <b>on {moment(closingDate).format('MMMM DD, YYYY')}</b>
            </Typography>
          )}
          {showRewardsSection && (
            <Typography variant='body1' component='p' sx={{ mb: 1 }}>
              Rewards: <b>{coins && hasRewards ? `${coins} coins per recipient` : 'Disabled'}</b>
            </Typography>
          )}
        </Section>
      </Paper>
    </Box>
  );
}