import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import withRouter from 'hocs/withRouter';
import { ACCOUNT_NOT_FOUND } from '@matterapp/matter-errors';
import { handleSubmitValidation } from '../../libs';
import withAuthReducer from './withAuthReducer';
import withRequestVerificationCodeMutation from './withRequestVerificationCodeMutation';
import withRequestMagicLinkToken from './withRequestMagicLinkToken';
import withGoogleLogin from './withGoogleYoloLogin';
import withCurrentUser from 'context/UserContext/withCurrentUser';
import {
  getPhoneNumberOrEmailValidation,
  testPhoneNumber,
} from '../../form-validations';

const withData = compose(
  withRequestVerificationCodeMutation,
  withRequestMagicLinkToken,
  withRouter,
  withAuthReducer,
  withGoogleLogin,
  withCurrentUser,
  reduxForm({
    validate: getPhoneNumberOrEmailValidation('phoneNumberOrEmail'),
    onSubmit: async (
      values,
      dispatch,
      {
        history,
        phoneCodeNextStepPath,
        requestVerificationCodeMutation,
        handleRequestMagicLinkToken,
        setCurrentPhoneNumber,
        noSignup,
      }
    ) => {
      const value = values.phoneNumberOrEmail;
      await handleSubmitValidation({
        fieldName: 'phoneNumberOrEmail',
        submit: async () => {
          if (testPhoneNumber(value)) {
            await requestVerificationCodeMutation({
              variables: {
                phoneNumber: value,
                noSignup,
              },
            });
            setCurrentPhoneNumber(value);
            history.push(phoneCodeNextStepPath);
          } else {
            const response = await handleRequestMagicLinkToken({
              email: value,
            });
            if (response) {
              const { graphQLErrors } = response;
              if (graphQLErrors && graphQLErrors.length > 0) {
                const [firstError] = graphQLErrors;
                if (firstError.name === ACCOUNT_NOT_FOUND) {
                  throw response;
                }
              }
            }
          }
        },
      });
    },
  })
);

export default withData;
