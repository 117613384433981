import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Container from '../../Container';
import Button from '../../../../Button';
import imageInstances from './image-instances';

const buttonColors = {
  Red: 'red',
  Green: 'green',
  Blue: 'blue',
  Purple: 'purple',
};

const buttonStyles = {
  [buttonColors.Red]: `
    background: linear-gradient(0deg, rgba(255, 171, 194, 0.3), rgba(255, 171, 194, 0.3)),
    linear-gradient(0deg, #FF6588, #FF6588);  
    &:hover {
      background: linear-gradient(0deg, #FF6588, #FF6588);
    }
    &:active {
      background: linear-gradient(0deg, #FF4369, #FF4369);
    }
  `,
  [buttonColors.Green]: `
    background: linear-gradient(0deg, rgba(33, 203, 163, 0.8), rgba(33, 203, 163, 0.8)),
    linear-gradient(0deg, #07BB87, #07BB87);   
    &:hover {
      background: linear-gradient(0deg, #07BB87, #07BB87);
    }
    &:active {
      background: linear-gradient(0deg, #05A164, #05A164);
    }
  `,
  [buttonColors.Blue]: `
    background: linear-gradient(0deg, rgba(162, 204, 255, 0.4), rgba(162, 204, 255, 0.4)),
    linear-gradient(0deg, #4584FF, #4584FF);
    &:hover {
      background: linear-gradient(0deg, #4584FF, #4584FF);
    }
    &:active {
      background: linear-gradient(0deg, #3466F2, #3466F2);
    }
  `,
  [buttonColors.Purple]: `
    background: linear-gradient(0deg, rgba(173, 175, 222, 0.6), rgba(173, 175, 222, 0.6)),
    linear-gradient(0deg, #4F52B1, #4F52B1);  
    &:hover {
      background: linear-gradient(0deg, #4F52B1, #4F52B1);
    }
    &:active {
      background: linear-gradient(0deg, #343799, #343799);
    }
  `,
};

const getButtonStyles = ({ buttonColor }) => {
  return buttonStyles[buttonColor];
};

const StyledContainer = styled(Container)`
  padding: 0 !important;
`;

const ButtonContainer = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const ActionButton = styled(Button.Primary)`
  color: white;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${theme.spacing.half} ${theme.spacing.single};
  &:hover {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15), 0px 2px 14px rgba(0, 0, 0, 0.1);
  }
  ${getButtonStyles}
`;

const ImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  border-radius: ${theme.borderRadius.L};
  width: 100%;
  height: 96px;
  padding-left: ${theme.spacing.singleAndHalf};
  padding-right: ${theme.spacing.singleAndHalf};
  background-image: url(${({ imageInstance }) => imageInstance.mobile});
  background-size: cover;
  background-repeat: no-repeat;
  ${theme.media.S`
    background-image: url(${({ imageInstance }) => imageInstance.desktop});
    height: 120px;
  `}
`;

const allImageInstances = Object.keys(imageInstances).map(
  (key) => imageInstances[key]
);

class ActionImage extends React.PureComponent {
  static imageInstances = imageInstances;

  static propTypes = {
    actionLabel: PropTypes.string.isRequired,
    onActionClick: PropTypes.func.isRequired,
    imageInstance: PropTypes.oneOfType([
      PropTypes.oneOf(allImageInstances),
      PropTypes.shape({ desktop: PropTypes.string, mobile: PropTypes.string }),
    ]),
  };

  handleClick = (e) => {
    e.stopPropagation();
    const { onActionClick } = this.props;
    onActionClick(e);
  };

  render() {
    const { actionLabel, imageInstance, buttonColor } = this.props;
    return (
      <StyledContainer onClick={this.handleClick} centered>
        <ImageDiv imageInstance={imageInstance}>
          <ButtonContainer>
            <ActionButton buttonColor={buttonColor} onClick={this.handleClick}>
              {actionLabel}
            </ActionButton>
          </ButtonContainer>
        </ImageDiv>
      </StyledContainer>
    );
  }
}

export default ActionImage;
