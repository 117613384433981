import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const ButtonBox = styled.div`
  display: flex;
  white-space: nowrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.singleAndHalf};
`;

const SubheaderContainer = styled.div`
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  margin-bottom: ${theme.spacing.single};
`;

const HeaderContainer = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.M};
  line-height: ${theme.lineHeight.M};
  margin-bottom: ${theme.spacing.half};
`;

const SimpleConfirmContainer = (props) => {
  const {
    heading,
    subheading,
    form,
    buttonRight,
    buttonLeft,
  } = props;
  const bodyContent = form ||
    <ButtonBox>
      {buttonLeft}
      {buttonRight}
    </ButtonBox>;

  return (
    <Container>
      {heading && <HeaderContainer>
        {heading}
      </HeaderContainer>}
      <SubheaderContainer>
        {subheading}
      </SubheaderContainer>
      {bodyContent}
    </Container>
  );
};

SimpleConfirmContainer.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  buttonLeft: PropTypes.node,
  buttonRight: PropTypes.node,
  form: PropTypes.node,
};

export default SimpleConfirmContainer;
