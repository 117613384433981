import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  z-index: 1000;
  height: 100vh;
`;

const MenuBackground = styled.div`
  background: ${theme.colors.black};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity ${theme.transitions.times.default};
  z-index: ${theme.zIndex.sidePanelOverlay};

  ${({ isOpen }) =>
  isOpen
    ? `opacity: 0.48;
         pointer-events: auto;
    `
    : `opacity: 0;
         pointer-events: none
    `};
`;

const MenuContainer = styled.div`
  position: absolute;
  background: ${theme.colors.white};
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  overflow-y: auto;
  transform: translateX(${({ isOpen }) => (isOpen ? '0%' : '100%')});
  transition: transform ${theme.transitions.times.default},
    opacity 0s
      ${({ isOpen }) => (isOpen ? '' : theme.transitions.times.default)};
  z-index: 200;
`;

function SlideOutMenu(props) {
  const {
    children,
    className,
    clickBackgroundToClose,
    isOpen,
    onClickClose,
  } = props;

  return (
    <Container className={className} isOpen={isOpen}>
      <MenuBackground
        isOpen={isOpen}
        onClick={clickBackgroundToClose ? onClickClose : null}
      />
      <MenuContainer isOpen={isOpen}>
        {children}
      </MenuContainer>
    </Container>
  );
};

export default SlideOutMenu;
