import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

class InputPhone extends React.Component {
  componentDidMount = () => {
    if (this.ref && this.props.autoFocus) {
      this.ref.focus();
    }
  }

  render = () => (
    <Input
      type="tel"
      size="massive"
      fluid={this.props.fluid}
      onChange={this.props.onChange}
      placeholder={this.props.placeholder}
      country="US"
      showCountrySelect={false}
      ref={(ref) => {
        if (ref) {
          this.ref = ref;
        }
      }}
      style={{
        marginBottom: this.props.marginBottom,
        ...this.props.style,
      }}
      {...this.props}
    />
  )
}

InputPhone.defaultProps = {
  placeholder: 'Enter your phone number',
  name: null,
  onChange: () => null,
  marginBottom: 0,
  autoFocus: false,
  fluid: false,
  style: null,
};

InputPhone.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  marginBottom: PropTypes.number,
  autoFocus: PropTypes.bool,
  fluid: PropTypes.bool,
  style: PropTypes.object, // eslint-disable-line
};

export default InputPhone;
