import React, { useState } from 'react';
import {
  AvatarCard,
  IconNew as Icon,
  SimpleConfirmModal,
  Button,
} from '@matterapp/matter-ui';
import { Origin, getUrlFromPathAndOrigin } from '@matterapp/routing';
import { SkillGeneratorModal } from '../../modules';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Card = styled(AvatarCard)`
  padding-left: 0;
  padding-right: 0;
`;

const GuideLink = styled.a`
  color: ${theme.colors.blacks[40]};
  border-bottom: 1px solid ${theme.colors.blacks[40]};
  margin-right: 5px;
  height: ${theme.spacing.singleAndHalf};
  font-weight: 500;

  &:hover {
    border-bottom: 1px solid ${theme.colors.purple[60]};
  }

  &:active {
    text-decoration: none;
  }
`;

const GuideLinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: ${theme.spacing.singleAndQuarter};
`;

const RightButton = styled(Button.Simple.Primary)`
  flex: 1 1 100%;
  padding: ${() => theme.spacing.half} 0;
  margin-left: ${() => theme.spacing.half};
`;

const LeftButton = styled(Button.Simple)`
  flex: 1 1 100%;
  padding: ${() => theme.spacing.half} 0;
  margin-right: ${() => theme.spacing.half};
`;

const SkillDescriptionText = styled.div`
  margin: 0 ${() => theme.spacing.singleAndHalf};
`;

function SkillAvatarCard(props) {
  const { 
    handleConfirmRemoveSkill,
    skill,
    canEdit
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function renderRemoveSkillConfirmDialog() {
    if (!isDialogOpen) { return null; }

    return (
      <SimpleConfirmModal
        transparent
        isOpen={isDialogOpen}
        buttonRight={
          <RightButton rcLabel="remove-skill-confirm" size={'M'} color={'red'} onClick={() => {
            handleConfirmRemoveSkill(skill);
            setIsDialogOpen(false);
          }}>
            Yes, Remove
          </RightButton>
        }
        buttonLeft={
          <LeftButton
            size={'M'}
            color={'black'}
            onClick={() => {
              setIsDialogOpen(false);
            }}
          >
            Cancel
          </LeftButton>
        }
        heading="Remove Skill?"
        subheading={
          <>
            Are you sure you want to remove this skill? You will no longer
            receive feedback on:
            <strong> {skill.name}</strong>.
          </>
        }
        onCancel={() => setIsDialogOpen(false)}
      />
    );
  }

  const guideLink = skill.hasGuide && getUrlFromPathAndOrigin(skill.slug, Origin.Microsite);

  return (
    <>
      {renderRemoveSkillConfirmDialog()}
      <SkillGeneratorModal>
        {({ handleStartEditSkill, renderModal }) => {
          const editSkillMenuItem = canEdit ? {
            menuLabel: 'Edit Skill',
            rcLabel: "menu-item-edit",
            handleAction: () => handleStartEditSkill(skill),
          } : null;
          const removeSkillMenuItem = {
            menuLabel: 'Remove Skill',
            rcLabel: "menu-item-remove",
            handleAction: () => setIsDialogOpen(true),
          };
          const topRightMenuContent = [
            editSkillMenuItem,
            removeSkillMenuItem,
          ];
   
          return (
            <>
              {renderModal()}
              <Card
                title={skill.name}
                iconName={skill.isCustom ? skill.colorPalette : skill.name}
                mobileCard
                rcLabel="skill-avatar-card"
                topRightMenuContent={topRightMenuContent}
              >
                <>
                  <SkillDescriptionText data-rc="avatar-card-skill-description">
                    {skill.description}
                  </SkillDescriptionText>
                  {skill.hasGuide &&
                    <GuideLinkContainer>
                      <GuideLink href={guideLink} target="_blank" rel="noopener noreferrer">
                        Open Guide
                      </GuideLink>
                      <a href={guideLink} target="_blank" rel="noopener noreferrer">
                        <Icon name="externalLink" fill={theme.colors.blacks[40]}/>
                      </a>
                    </GuideLinkContainer>
                  }
                </>

              </Card>
            </>
          );
        }}
      </SkillGeneratorModal>
    </>
  );
};

export default SkillAvatarCard;
