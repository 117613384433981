import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Sidebar from '../Sidebar';
import StepContainerComponent from '../StepContainer';
import { getSkillIcon } from '@matterapp/matter-ui';
import { MAX_STEP_WIDTH } from '../utils';
import theme from '@matterapp/matter-theme';
import {
  SidebarContainer,
  SidebarWrapper,
} from '../sharedStyles';

/**
 * On desktop, the step containerheight is 0 when not shown and on mobile
 * it always takes up the entire content space.
 */
/* istanbul ignore next */
const StepContainer = styled(StepContainerComponent)`
  ${({ isShown }) => (isShown ? `max-height: unset;` : `max-height: 0;`)}
  ${theme.media.S`
    max-height: unset;
  `}
`;

const SkillBadge = styled.img.attrs({
  height: theme.spacing.triple,
  width: theme.spacing.triple,
})`
  pointer-events: none;
  -webkit-touch-callout: none;
`;

const SkillSidebarContainerSpacer = styled.div`
  width: ${MAX_STEP_WIDTH};
`;

const SkillIconSidebar = (props) => {
  const { skillToRate, customDescription } = props;
  const { skill } = skillToRate;
  const { description, name } = skill || {};
  const icon = skill ? <SkillBadge src={getSkillIcon(skill)} /> : null;

  const headerText = name ? `${name}:` : null;
  const descriptionText = description || customDescription;
  
  return (
    <SidebarContainer>
      <SidebarWrapper>
        <StepContainer
          sidebarContent={
            <Sidebar
              header={headerText}
              icon={icon}
              isTopSidebar
              content={skill ? descriptionText : null}
            />
          }
        >
          <SkillSidebarContainerSpacer />
        </StepContainer>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

SkillIconSidebar.propTypes = {
  /** Custom skill description. */
  customDescription: PropTypes.string,
  /** The skill to rate for this step. */
  skillToRate: PropTypes.shape({
    skill: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};

SkillIconSidebar.defaultProps = {
  skillToRate: {
    skill: {},
  },
};

export default SkillIconSidebar;
