import React, { useEffect, useContext, useState } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { 
  Box,
  Typography,
  Divider,
  Icon
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useParams, useNavigate } from 'react-router-dom';
import getSurveyResults from 'graphql-queries/queries/surveys/getSurveyResults';
import getIndividualSurveyResults from 'graphql-queries/queries/surveys/getIndividualSurveyResults';
import getCustomSurvey from 'graphql-queries/queries/surveys/getCustomSurvey';
import { useQuery, useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { Resources } from '@matterapp/routing';
import getTenantMembersByPersonIds from 'graphql-queries/queries/workspace/getTenantMembersByPersonIds';
import { getRecipients } from 'routes/pages/surveys/SurveyEditor/consts';
import DarkMenu from 'components/Menu/Dark/Menu';
import useSurveyMenu from '../useSurveyMenu';
import SurveyEditorModal from '../SurveyEditor/SurveyEditorModal';
import ConfirmSurveyModal from '../ConfirmSurveyModal';
import EditNotificationsModal from '../EditNotificationsModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterBar from 'components/FilterBar';
import {
  FilterBarWrapper,
  Wrapper,
  TopWrapper,
  SummarySection,
  SummaryNumbers,
  SummaryDetails,
  SurveyName,
  FilterTabsContainer,
  FilterTab,
  MobileBreadCrumb,
  IconWrapper
} from './styles';
import ResponsesBlock from './ResponsesBlock';
import IndividualResponsesBlock from './IndividualResponsesBlock';
import { DownloadButton } from 'components/DateFilterBar/DateFilterBar';

const STATUS = {
  active: 'Active',
  closed: 'Closed',
  draft: 'Draft',
  scheduled: 'Scheduled'
};

const TABS = ['Summary', 'Individual'];

export default function SurveyResultView() {
  const { currentTenant, currentWorkspace, isAdminMember } = useContext(UserContext);
  const { timezone } = currentWorkspace || {};
  const params = useParams();
  const navigate = useNavigate();
  const { surveyId } = params || {};
  const { slackWorkspace } = currentWorkspace || {};
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [getMembers, { data: membersData }] = useLazyQuery(getTenantMembersByPersonIds);
  const { data } = useQuery(getSurveyResults, {
    variables: {
      tenantId: currentTenant?.id,
      surveyId: surveyId
    },
    fetchPolicy: 'network-only',
    skip: !currentTenant || !surveyId
  }); 

  const { data: individualResultsData } = useQuery(getIndividualSurveyResults, {
    variables: {
      tenantId: currentTenant?.id,
      surveyId: surveyId
    },
    fetchPolicy: 'network-only',
    skip: !currentTenant || !surveyId
  }); 
  const { individualSurveyResults = [] } = individualResultsData || {};

  const { data: customSurveyData, refetch } = useQuery(getCustomSurvey, {
    variables: {
      tenantId: currentTenant?.id,
      surveyId: surveyId
    },
    skip: !currentTenant || !surveyId
  }); 

  const isIndividualTab = activeTab === TABS[1];
  const { customSurvey } = customSurveyData || {};

  const { 
    editSurvey, 
    getMenuMap, 
    isEditorOpen, 
    setIsEditorOpen,
    isConfirmModalOpen, 
    confirmModalProps,
    setEditSurvey,
    setIsConfirmModalOpen,
    setConfirmModalProps,
    editNotifications,
    setEditNotifications,
    downloadingReport,
    downloadReport
  } = useSurveyMenu(customSurvey);
  const { tenantMembersByPersonIds: members } = membersData || {};
  const { status, isAnonymous, recipients = [], sentOn, closingDate, createdBy, name, activeQuestions = [], questions = [] } = customSurvey || {};
  const questionsToUse = status === 'draft' ? [...questions] : [...activeQuestions];
  const { surveyResults = {} } = data || {};
  const membersList = members?.map(({ person }) => person.fullName).join(', ') || '';
  const { responseCount, totalSent, uniqueSent, responses, enps } = surveyResults || {};
  const audienceGlobalItems = getRecipients(currentTenant, currentWorkspace, uniqueSent);

  let audience = audienceGlobalItems.filter(({ value }) => {
    return recipients.find((recipient) => recipient === value);
  }).map(({ label }) => label).join(', ');

  if (membersList?.length && audience?.length) {
    audience = `${audience}, ${membersList}`;
  } else if (membersList?.length) {
    audience = membersList;
  }

  let sentOnDate = null;
  let closesOn = null;

  if (closingDate && timezone) {
    closesOn = moment(closingDate).tz(timezone).format('MMM DD, YYYY HH:MM z');
  }

  if (sentOn && timezone) {
    sentOnDate = moment(new Date(parseInt(sentOn))).tz(timezone).format('MMM DD, YYYY HH:MM z');
  }

  useEffect(() => {
    const personIds = recipients.filter(value => parseInt(value, 10));

    if (personIds.length) {
      getMembers({
        variables: {
          personIds,
          tenantId: currentTenant?.id
        }
      });
    }
  }, [recipients]);

  return (
    <Wrapper>
      <TopWrapper>
        <MobileBreadCrumb 
          onClick={() => {
            navigate(Resources.surveys.path({ workspaceId: currentWorkspace?.id }));
          }}
        >
          <Icon 
            component={ArrowBackIcon}
            sx={{ mr: 1, color: '#828285' }}
          />
          <Typography variant='body1'><b>Surveys</b></Typography>
        </MobileBreadCrumb>
        <BreadCrumbs
          hideOnMobile
          crumps={[
            {
              label: 'Surveys',
              href: Resources.surveys.path({ workspaceId: currentWorkspace?.id })
            },
            {
              label: name,
              href: null
            }
          ]}
        />
        <Box sx={{ display: 'flex' }}>
          <DownloadButton 
            style={{ marginRight: '8px', backgroundColor: '#FFF' }}
            disabled={downloadingReport}
            onClick={() => downloadReport(customSurvey.id)}
          >
            Download Report (.XLSX)
          </DownloadButton>
          <DarkMenu 
            menuMap={getMenuMap()}
            position='bottom'
            TriggerComponent={(props) => (
              <IconWrapper {...props}>
                <Icon 
                  component={SettingsIcon}
                  sx={{ width: '16px', margin: '0 auto' }}
                />
              </IconWrapper>
            )}
          />
        </Box>
      </TopWrapper>
      <SummarySection>
        <SummaryNumbers>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='h1'>
              {responseCount}
            </Typography>
            <Typography variant='body1' color='blacks.50'>
              <b>Responses</b>
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='h1'>
              {totalSent ? Math.round((responseCount / totalSent) * 100) : 0}%
            </Typography>
            <Typography variant='body1' color='blacks.50'>
              <b>Participation</b>
            </Typography>
          </Box>
        </SummaryNumbers>
        <Divider orientation='vertical' sx={{ margin: 0, ml: 6, mr: 6 }} />
        <SummaryDetails>
          <SurveyName variant='body2' color='blacks.90' sx={{ mb: 1 }}><b>Name: </b>{name}</SurveyName>
          <Typography variant='body2' color='blacks.90' sx={{ mb: 1 }}><b>Status: </b>{STATUS[status]}</Typography>
          <Typography variant='body2' color='blacks.90' sx={{ mb: 1 }}><b>Anonymity: </b>{isAnonymous ? 'Anonymous' : 'Attributed'}</Typography>
          <Typography variant='body2' color='blacks.90' sx={{ mb: 1 }}><b>Audience: </b>{audience}</Typography>
          <Typography variant='body2' color='blacks.90' sx={{ mb: 1 }}><b>Sent on: </b>{sentOnDate}</Typography>
          <Typography variant='body2' color='blacks.90' sx={{ mb: 1 }}><b>Closes on: </b>{closesOn || 'No closing date'}</Typography>
          <Typography variant='body2' color='blacks.90' sx={{ mb: 1 }}><b>Created by: </b>{createdBy?.fullName}</Typography>
        </SummaryDetails>
      </SummarySection>
      <FilterBarWrapper>
        <FilterBar
          showDropdownOnlyOnMobile
          filterName='Responses'
          value={activeTab}
          items={TABS.map(tab => ({ label: tab, value: tab }))}
          onChange={(e, { value }) => setActiveTab(value)}
          customComponent={
            <FilterTabsContainer>
              <FilterTab
                isActive={activeTab === TABS[0]}
                onClick={() => setActiveTab(TABS[0])}
              >
                Summary
              </FilterTab>
              <FilterTab
                isActive={activeTab === TABS[1]}
                onClick={() => setActiveTab(TABS[1])}
              >
                Individual
              </FilterTab>
            </FilterTabsContainer>
          }
        />
      </FilterBarWrapper>
      {isIndividualTab ? (
        <IndividualResponsesBlock 
          questions={questionsToUse} 
          responses={individualSurveyResults} 
          timezone={timezone}
        />
      ) : (
        <ResponsesBlock 
          questions={questionsToUse} 
          responses={responses} 
          timezone={timezone}
          enps={enps}
          totalSent={totalSent}
        />
      )}
      {isEditorOpen ? (
        <SurveyEditorModal 
          isSlack={!!slackWorkspace}
          history={history}
          isOpen
          onClose={() => {
            setIsEditorOpen(false);
            setEditSurvey(null);
            refetch(({
              variables: {
                tenantId: currentTenant?.id,
                surveyId: surveyId
              }
            }));
          }}
          tenantId={currentTenant?.id}
          workspaceId={currentWorkspace?.id}
          survey={editSurvey}
          timezone={currentWorkspace?.timezone}
          isRewardsActive={currentTenant?.rewardsSettings?.isRewardsActive}
          isAdminMember={isAdminMember}
        />
      ) : null}
      <ConfirmSurveyModal
        isOpen={isConfirmModalOpen}
        onClose={() => {
          setIsConfirmModalOpen(false);
          setConfirmModalProps({});
        }}
        {...confirmModalProps}
      />
      <EditNotificationsModal 
        isOpen={!!editNotifications}
        onClose={() => setEditNotifications(null)}
        survey={editNotifications}
        tenantId={currentTenant?.id}
        currentWorkspace={currentWorkspace}
      />
    </Wrapper>
  );
}
