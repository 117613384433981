import React from 'react';
import PropTypes from 'prop-types';
import {
  UPGRADE_MESAGE,
  UPGRADE_MESAGE_VIEW_ALL_FEEDBACK,
  UPGRADE_LINK_TEXT,
  UPGRADE_LINK_VIEW_ALL_FEEDBACK_TEXT,
} from './consts';
import { LockIcon, UpgradeContainer, UpgradeLink, Text } from './styles';

const UpgradeMessage = (props) => {
  const { className, onClickUpgrade, showInPanel, upgradeLinkText, upgradeMessage } = props;
  return (
    <UpgradeContainer className={className} showInPanel={showInPanel}>
      <LockIcon />
      <Text
        replaceText={
          <UpgradeLink
            key={`upgrade-message-link-feedback-filters`}
            onClick={onClickUpgrade}
          >
            {upgradeLinkText}
          </UpgradeLink>
        }
        text={upgradeMessage}
      />
    </UpgradeContainer>
  );
};

UpgradeMessage.defaultProps = {
  showInPanel: true,
  upgradeLinkText: UPGRADE_LINK_TEXT,
  upgradeMessage: UPGRADE_MESAGE,
};

UpgradeMessage.propTypes = {
  className: PropTypes.string,
  onClickUpgrade: PropTypes.func,
  showInPanel: PropTypes.bool,
  upgradeLinkText: PropTypes.string,
  upgradeMessage: PropTypes.string,
};

UpgradeMessage.ViewAllFeedback = (props) => {
  return (
    <UpgradeMessage
      upgradeLinkText={UPGRADE_LINK_VIEW_ALL_FEEDBACK_TEXT}
      upgradeMessage={UPGRADE_MESAGE_VIEW_ALL_FEEDBACK}
      {...props}
    />
  );
};

export default UpgradeMessage;
