import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const ColoredPath = styled.path`
  fill: currentColor;
`;

export const TopCloud = styled(ColoredPath).attrs({
  d: `M2557,1194.5c-9.5-10.4-20.5-21.6-37-21.5c-3.8,0-4.2-0.5-5.8-3.6
	c-6.4-12.7-28.1-22.5-41.2-12.7c-16-23.1-52.8-28-78.4-16.3c-1.2,0.5-1.9,0.4-3-0.2c-8.4-5.6-18.7-7.3-28.7-9.4
	c-11.7-2.1-23.1,0.6-34.2,3.4c-6.4,2-12.5,4.5-16.3,9.8c-0.8,1-1.4,1.7-2.9,2.2c-4.8-5-11.1-8.4-17.9-10.9
	c-5.1-1.8-9.5-5.7-15.3-5.6c-2.9,0-4.8-0.8-6-3.5c-2.8-7.8-8.5-14.6-11.5-22.3c-3.4-7.5-13-12-20.1-16.9
	c-27.1-15.6-41.1-14.5-70.3-8.2c-18-20.9-59.9-35.9-78.6-9.7c-0.8,0.8-1.7,1-2.9,0.4c-6-2.4-12.8-0.6-19.1-1.5
	c-6.2-1.3-12.3,6.8-17.1,4c-11.9-8.3-25.4-14.3-37.3-22.7c-8.2-3.6-16.9-5.9-25.7-7.7c-3.1-0.8-6.2-1.4-9.3-1.7
	c-1.5-0.2-2.9-0.5-4.4-0.7l0,0.5c-12.6-0.3-25.5,3.2-38,8.8c-13.3-17.8-27.7-25.1-51.4-20c-9.7-25.6-37.3-39.3-67.3-36.3
	c2.1-15.9-7.3-31.2-21.1-41.5c2.3-21.4-5.9-40.7-27.7-53.2c-11.1-8.1-26-7.4-39.4-8.1c-1.8-10.2-13.3-15-22.6-19.7
	c-13.5-9.3-29.8-6.9-44.7-3.1c-17.7-18-48.6-27.6-72.1-13.9c-5.8-3.6-1.5-11-2.6-16.2c0.1-13.8-5.6-28.3-14.6-39.9
	c-15.3-14.8-47.4-25.4-69.8-22.5c-4.7-8-1.9-17.5-7.2-25.5c-2.8-5.2-7.4-9-12.1-12.7c-10.5-8.1-19.9-18-33.7-21.8
	c-19.9-6.3-42.6-8-60.6,1.9c-9.4,5.7-11.7-7.4-15.4-12.7c-2.2-8.1-12.2-10.9-17.5-17.1c-3-2.8-4.8-7.7-8.5-9.5
	c-4.2,0-7.6-4.3-11.3-6c-7.2-2.7-14.6-5.4-21.7-8.5c-11-4.9-23.1-4.6-35-5.7v0.4c-0.8-5.7-18.7-43.3-24.2-38.6
	c-11.8-12.8-27.3-23.3-44.5-27c-14.4,0.3-29.1-3.5-43.1,1.3c-7.7,2.7-15.7,4.8-23.4,6.9c-4.1,1.5-8.1,6.1-12.4,5.4
	c-4,1.6-6.5,7.2-10,10.1c-6.2,6.6-16.9,8.4-20.2,17.8c-4.5,5.7-8.5,21.1-17.5,12.8c-17.2-14.6-40.8-16.1-62.1-11.5
	c-14.7,2.4-25.7,12.9-37.5,21c-5.3,3.8-10.5,7.6-14.1,13.4c-6.5,8.8-4.9,20.6-10.7,29.5c-22.7-6.8-57,0.9-74.7,16.4
	c-10.8,12.5-18.6,29-20.2,45.6c-1.9,6.1,1.7,15.6-4.8,19c-22.3-20-55.4-13.1-76,5.7c-14.8-6.8-31.3-12.2-46.4-3.1
	c-10.1,4.3-22.6,8.3-25.8,20.2c-13.9-1.2-29-4.3-41.5,3.7c-24.1,11.6-35,33.5-35.5,59.6c-15.5,10.3-27.2,27.3-27.1,46.6
	c-30.5-8.2-60.6,4-74,33.2c-23.8-9.7-39.5-3.1-55.5,16.1c-23.5-16.6-49.6-24.5-74.9-6.4c-0.2-13-14-18.2-24.5-21.2
	c-2.7-9-3.5-20.7-11.9-26.4c-10.9-8.1-26.7-8.9-39.3-5c-3.5-11.7-14.2-18.2-25.6-21.1c-3.1-1-3.2-1.2-3.1-4.3
	c0.2-3.4-1.3-6.5-1.9-9.7c-1.9-24.8-19.9-38.2-41.1-47.4c-7.4-3.3-16.3-2.3-23.6-5.3c-19.1,1.6-36.9,14.4-48.7,29.1
	c-5.8-3.8-13.8-1-20.4-2.1c-10.7,0.8-19,10.6-23.8,19.6c-7.7-5.7-16.3-7.3-25.7-5.9c-1.2-19.4-22.3-34.6-41.1-29.6
	c-10.9-36-37-51.5-73.3-54.3c-16.4,6.4-34.4,12.2-45.2,25.6c-13.9-3.9-33,1.2-35,0.8c-12.4-2.3-24.8,3.7-35.2,9.2
	c-16.1,5.2-17,22.6-27.5,21.8c-27.3-3-59.5-1.6-77.5,20.2c-10.1,10.2-17,23.4-20,36.8c-1.4,4.6,3.6,11.8-2.8,14.3
	c-9.7,2.4-23.4,8-29.6,15.4c-3.4,4.8-6.2,9.9-8,15.4c-0.4,1.2-1,2.2-2.7,2.9c-11.2-8.1-22.8-17-38-16.2
	c-22.2-5.8-42.3,5.3-60.1,16.1c-5.1,2.3-16.1-4-22.3-4.8c-9-1-18.9,0.4-27.7,2.3c-18.3,6.6-24,9.4-32.7,26.4c-0.7,1.6-1.6,1.7-3.4,1
	c-11.1-5.1-22.7,2.3-31.7,8c-4.2,2.8-8.1,6-12.1,9c-3.3,2.6-0.9,6.6-0.2,9.8c1,3.7-2.2,6.6-6.1,6.5c-18.1,1.8-33.5,14.9-39.9,30.1
	c-22-1.5-55-2.2-71.7,12.2v0.4c-21,16.2-37,31.5-32.7,58.4c0.5,5.2-6.6,3.4-10.1,4.8c-6.3,0.3-20.3,12.6-23.6,10.5
	c-2-3.8-6.4-4.9-9.9-6.9c-0.4-0.2-1-0.4-1.2-0.7c-1.5-2.3-4.1-2.6-6.6-3.4c-32.1-11.5-31.3,11.5-39.6,5.9
	c-13.5-6.3-31.1-5.1-44.6,0.9c-14.3,5.1-16,6.1-23.7,18.6c-30.9-8.6-62.4,10-74.2,36c-0.1,0.1-0.2,0.2-0.3,0.3
	c-7-4.1-14.5-7.1-22.1-7.8l0.1-0.3c-0.9,0-1.8,0.1-2.7,0.1c-1.9-0.1-3.8,0-5.7,0.3c-5.4,0.4-10.8,1.1-15.9,2.5
	c-6,3-12.4,5.2-18.6,7.8c-8.9-2.2-17.8-4-27-2.8c-0.6,0-1.3,0.1-1.9,0.2c-9.4-15.6-35.1-10.2-47.3,0.4c-17-5.8-25.3-7.4-42.7-0.5
	c-4.6,2.3-10.7,4.2-13.3,8.3c-2.4,4.2-6.3,7.7-8.6,12c-0.9,1.5-2.1,1.8-3.8,1.6c-3.4-0.5-6.4,1.4-9.6,2.1c-4.2,0.9-8.3,2.4-11.5,4.9
	c-0.9-0.4-1.1-0.8-1.6-1.5c-1.9-3.3-5.3-5.2-9-6.8c-6.4-2.4-13-4.8-20.2-4.4c-6.1,0.5-12.4,0.7-17.9,3.3c-0.7,0.3-1.1,0.3-1.8-0.1
	c-14.4-8.6-36.8-8.4-48.1,3.6c-7.1-6.6-20.8-2.5-25.6,4.3c-1.2,1.7-1.5,1.9-3.7,1.6c-9.8-1.2-17.3,4.4-23.8,9.7
	c-0.9,0.5-1.4,1.5-2.1,2.3v0.7h240h146.4H0h1280v-0.3l1279.9,0.7l0.1-1.1C2559,1197.5,2558.4,1195.6,2557,1194.5z`,
})``;

export const BottomCloud = styled.path.attrs({
  d: `M2546.8,1192.1c-10.3-6.8-22.8,2.4-31.3-8.7c-22.5-14.4-38.9,4.4-43-3
	c-4.4-11.1-13.6-17.4-19-26c-5.9-13.6-16.5-21-28.1-22.8c0.3,0.1,0.7,0.3,1,0.5c-1.4,0.5-3.5,0.2-4.2-0.8c0.3,0,0.6,0,1,0
	c-6.3-0.6-12.8,0.5-19,3.1c-26.7-17.2-28.3,6.3-45.1-0.1c-24.5-13.8-32.3,14.4-41.3,5.1c-4.5-29.3-41.9-42.2-58.2-16.9
	c-8.7,1.4-17.3-8.9-26.9-4.7c-24.4,3.8-9.2-15.1-51.7-3.8c-2.9-8.2-7-18.1-15-19.9c-2-0.5-2-0.6-2.3-2.9
	c-3.5-13.9-15.9-23.8-27.2-27.7c-9.2-2.7-19,1.5-28,1.5c-12.4-5.1-25.2,1.2-37.3,1.9c-9.4-5.3-20.5-4.5-29.8,0.9
	c-6.6-3.6-14.5-1.7-19.6,4.4c-2.1,3.3-5.2-2.2-8.3-2.6c-11.5-4.8-22.4,3.8-30.4,12.9c-7.5-27.7-38.6-32.5-54-10.8
	c-2.7,4-3.8,9.2-4.7,14.2c-9.8-2.6-17.6,16.6-24.8,5.1c-5.4-4.3-13.3-4.4-18.6,0.3c-7.2-24.1-27.7-33.5-47.8-33.7
	c-13.7,1.7-20.6,12.6-33-1.2c-20.6-17.8-51.8-16.9-70.5,4.1c-9.6,11.7-3.4-26.6-37.9-34.5c-5.5-2.8-1.2-10.8-2.4-16
	c-2.8-17.7-10.7-34.2-21.4-46.8c-10.3-13.2-26.3-17.7-40.9-18.4c-6-1.1-13.5,2.1-19.8,1.6c-1.1-2.8-2.5-5.4-4.2-7.5
	c-8.7-17.3-22.9-25.8-37.4-26.8c0,0,0,0,0,0c-6.8-0.5-13.6,0.7-20,3.5c-2.8,0.9-5.9,4.4-8.9,6.6c-0.2,0.1-0.5,0.3-0.7,0.5
	c-2.8,1.9-5.4,2.5-7.7-1.1c-10.2-10.9-23.4-11.8-34.5-3.6c-8.6,5.3-12.4-22.4-20.8-29.2c-12.9-17.2-29.9-25.3-47.3-23
	c0.5,0.2,0.8,0.3,1,0.5c-10.9-1.4-21.5,4.8-31,12.2c-17.3-28.3-50.3-40.9-74.2-25.8c-15.6,3.8-29.4,13.5-41.5,27.9
	c-0.2-0.1-0.4-0.1-0.6-0.1v1.1c-8.7-3.3-18.7-3.7-27.7-2.1c-12.8-10.3-27.3-17.3-43.9-20c-25.4-10.9-60.2-1.8-78.6,18.5
	c-10.1-5.3-21.3-9.7-32.8-8.7c0.3-0.1,0.6-0.2,1.1-0.3c-18.4-1.6-36.4,4.2-50.1,16.5c-8.8,4.9-12.9,24.8-22,21
	c-11.8-5.9-25.8-5.3-36.5,2.6c-2.4,2.6-5.2,2.2-8.1,0.8c-0.3-0.1-0.5-0.3-0.7-0.4c-3.2-1.6-6.5-4.2-9.4-4.8
	c-21.1-6.1-47.1-1.5-60.7,16.8c-9.4,7.6-8.7,23.9-13,35.2c-12.1,0.1-24.3,1.1-34,9c-27.9,15-13.4,39.2-39.8,39.7
	c-11.2,2.3-17.4,11.2-25.9,17.9c-7.6-11.5-23.4-19.2-37.1-14.8c-7.5,2.8-15.9,4.4-21.7,10.4c-19.9-3.5-44.7-13-63.1,5.2
	c-5.7,16.3-18.5,11.6-29,22.9c-14.8-13.2-38.5-7.1-44.8,11.9c-3.5,6.8-15.3-6.1-22.5-5c-27.4-4.3-49.4,17.2-50.1,44
	c-15.9,5.6-31.9,11.7-26.8,34.3c1.1,5.6-8.5,1.2-10.7,5.3c-6.1,3.9-9.7,10.8-11.2,17.7c-3.9,1.1-10.1-0.7-12.9,4.4
	c-6,3.9-14-4.8-21.7-2.1c-24.2-27-25.5-13.6-52.6-6.8c-10.5,1.6-19.4,8.5-25,17.3c-16.1,0-22.7-8.8-36.4-16.1
	c-12.2-7.3-28.7-1-39.6-2.6c-18.8-11.7-21.6-48-50.8-39c-6.1,1.1-5.9-6.9-10.6-9.2c-3.3-5.8-11.2-7.4-14.5-12.5
	c-7.5-33.1-40.6-50.1-71-33.1c-7.4,2.6-9.7,13.8-16.5,15.4c-11.5-2-22,7.8-29.3,13.5c-3.7-2.5-7.8-4.1-12.3-2.7
	c-7.1-5.2-6.1-21.5-16.1-26.5c-7.8-5.9-18.7-4.7-27.6-4.6C33.6,1034,17.3,1026,0,1020.7v0.1c-8.2-2.8-17.7-3.2-26.4-1.9
	c-11.8-8.2-25.3-13.7-40.6-15.8c-23.5-8.6-55.8-1.4-72.7,14.6c-9.4-4.2-19.7-7.7-30.4-6.9c0.3-0.1,0.5-0.1,1-0.3
	c-17-1.3-33.7,3.3-46.3,13.1c-8.2,3.9-11.9,19.6-20.3,16.6c-10.9-4.6-23.9-4.2-33.8,2c-2.2,2.1-4.8,1.7-7.5,0.6
	c-0.2-0.1-0.5-0.2-0.7-0.3c-3-1.3-6-3.3-8.7-3.8c-6.2-1.5-12.9-2.2-19.6-2c0,0,0,0,0,0c-14.2,0.5-28.1,5.4-36.6,15.2
	c-1.7,1.2-3,2.6-4.1,4.2c-6.2,0.3-13.5-1.5-19.4-0.9c-14.3,0.4-30,3-40.1,10.4c-10.6,7.1-18.2,16.5-21,26.6c-1.2,2.9,3,7.5-2.4,9.1
	c-33.8,4.5-27.7,26.2-37.1,19.6c-18.3-11.9-48.8-12.4-69-2.3c-12.2,7.8-18.9,1.6-32.3,0.7c-19.6,0.1-39.8,5.4-46.8,19.1
	c-5.2-2.7-13-2.6-18.2-0.2c-7.1,6.5-14.7-4.4-24.3-2.9c-0.9-2.8-2-5.8-4.6-8.1c-15.1-12.4-45.6-9.6-52.9,6.1
	c-7.8-5.2-18.5-10-29.8-7.3c-3,0.2-6.1,3.3-8.1,1.5c-5-3.5-12.7-4.6-19.1-2.5c-9.1-3.1-19.9-3.5-29.2-0.5c-11.8-0.4-24.3-4-36.5-1.1
	c-8.8,0-18.3-2.4-27.4-0.8c-11.1,2.2-23.3,7.8-26.7,15.7c-0.3,1.3-0.3,1.4-2.3,1.7c-7.8,1-11.9,6.7-14.7,11.3
	c-41.7-6.4-26.8,4.3-50.7,2.2c-9.3-2.4-17.8,3.5-26.3,2.7c-16-14.4-52.6-7.1-57,9.6c-8.8,5.3-16.4-10.7-40.4-2.9
	c-16.4,3.6-18-9.7-44.1,0.1c-6-1.5-12.4-2.1-18.6-1.8c0.3,0,0.6,0,0.9,0c-0.7,0.5-2.7,0.7-4.1,0.4c0.3-0.1,0.6-0.2,1-0.3
	c-11.4,1-21.7,5.2-27.6,13c-5.3,4.9-14.4,8.5-18.6,14.8c-4,4.2-20.1-6.5-42.1,1.7c-8.4,6.3-20.6,1.1-30.7,4.9l-12.9,4.5l927.2,0v0H0
	h1279.2h0.8h333.2v0l946.8-0.1L2546.8,1192.1z M-908.4,1184.9c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.1,0.1,0.2,0.2
	C-908.1,1185.1-908.3,1185.2-908.4,1184.9z M2179.9,1173.4c0.1-0.1,0.1-0.4,0.2-0.4c0.1,0,0.3,0.1,0.4,0.2
	C2180.4,1173.8,2180.2,1173.6,2179.9,1173.4z M-1145.9,1161c-0.7,0-1.5,0.1-2.2,0.2C-1147.3,1161-1146.6,1161-1145.9,1161z`,
})`
  fill: ${theme.colors.white};
`;

export const TopDot1 = styled(ColoredPath).attrs({
  d: `M36.6,50.8c11-0.6,10.2-10.8,6.8-16.2c-6.5-5.9-17.5-1.2-16.3,8.3C28,47.1,31.9,51.7,36.6,50.8z`,
})``;
export const TopDot2 = styled(ColoredPath).attrs({
  d: `M111.8,116.3c-0.4-0.5-1.5-0.2-1.8-0.8c-7-3.7-2-14.4,5.1-11.3C121.3,107.3,119.3,117.2,111.8,116.3z`,
})``;
export const TopDot3 = styled(ColoredPath).attrs({
  d: `M222.2,54.1c10.7-1.3,13.3,13.7,2.9,16.2C215.8,70.7,212.3,55.9,222.2,54.1z`,
})``;
export const TopDot4 = styled(ColoredPath).attrs({
  d: `M518.1,56C518.1,56.1,518.1,56.1,518.1,56C518.1,56.1,518.1,56,518.1,56
	C518.1,56,518.1,56,518.1,56z M518.1,56.1c-1.1,3.9,1,10.3,6.6,10.3c6.9-0.6,10-7.6,6.7-12.3l0,0C528.1,49.1,519.8,51,518.1,56.1`,
})``;
export const TopDot5 = styled(ColoredPath).attrs({
  d: `M608.5,125.5c2.6,4.5-5.7,7.3-8.7,4.4C597.1,125.2,607,119.7,608.5,125.5z`,
})``;
export const TopDot6 = styled(ColoredPath).attrs({
  d: `M800.5,163.8c8.8-1,7.2-15.6-1.3-14.7C788.2,148.1,789.4,165.7,800.5,163.8z`,
})``;
export const TopDot7 = styled(ColoredPath).attrs({
  d: `M883.1,73.8c4-7.4,12.9,0.8,9.4,6.8C888.5,88.1,880.4,79.7,883.1,73.8z`,
})``;
export const TopDot8 = styled(ColoredPath).attrs({
  d: `M920.9,117.1C920.9,117.1,920.9,117.1,920.9,117.1c0-0.1,0-0.1,0-0.1c0-1.9-1-4.3-2.5-4.7
	c-5.1-2.2-9.1,5.2-4.1,8.1c2.6,0.5,4.1,0.6,4.9-0.2c0,0,0,0,0,0C919.9,119.8,920.9,118.6,920.9,117.1
	C920.9,117.2,920.9,117.1,920.9,117.1z`,
})``;
export const TopDot9 = styled(ColoredPath).attrs({
  d: `M1047.4,145.2c4.8,0.3,6.9,7.8,3.2,10.8C1041.9,159.4,1038.6,146.9,1047.4,145.2z`,
})``;
export const TopDot10 = styled(ColoredPath).attrs({
  d: `M1124.1,86.8c-0.4-0.4-1.5-0.2-1.8-0.8c-7-3.7-2-14.4,5.1-11.3C1133.6,77.8,1131.5,87.8,1124.1,86.8z`,
})``;
export const LeftPlanet = styled(ColoredPath).attrs({
  d: `M-75,580.6c8-76.3,100.8-80.8,124-11.9C58.7,655.8-70.5,670.3-75,580.6z`,
})``;
export const LeftDot1 = styled(ColoredPath).attrs({
  d: `M50.2,459c-2.9,0.3-5.7-3.8-2.3-6C51.8,449.9,55.2,457.8,50.2,459z`,
})``;
export const LeftDot2 = styled(ColoredPath).attrs({
  d: `M218.2,557.8c-4.7-6.8,8-13.5,11.2-6C232.6,558.4,222.2,563.1,218.2,557.8z`,
})``;
export const LeftRing = styled(ColoredPath).attrs({
  d: `M162.7,734c11.5-0.1,19.5,9.7,19.1,20c-0.3,7.4-8.3,16.9-18.1,16.2
	c-10.4-0.8-18.1-7.6-17.6-18.6C146.5,741.6,154,733.7,162.7,734z M164,764.4c6.4,0.1,12-5.5,12.1-11.2c0.1-7.1-5.8-13.1-12.9-13.2
	c-5.1-0.1-11.5,7.3-11.9,13.2C151.1,758.7,159,764.5,164,764.4z`,
})``;
export const RightRing = styled(ColoredPath).attrs({
  d: `M1217.1,268c8.8,0,17,8.4,16.9,17.4c0,9.4-8.7,17.8-18.2,17.6c-7.4-0.1-15.3-6.9-15.2-19.6
	C1200.7,275.8,1209,268,1217.1,268z M1208.8,284.2c0.2,6.1,3.6,10.2,8.3,10.1c4.8-0.1,9.1-4.4,8.9-8.9c-0.2-6-4.6-8.4-9.4-8.9
	C1212.9,276.1,1208.7,280.5,1208.8,284.2z`,
})``;
export const RightPlanet = styled(ColoredPath).attrs({
  d: `M1283.1,84.6c51.5-4.9,54.2,89.4,0.3,88.2C1220.9,179.8,1220.1,73.7,1283.1,84.6z`,
})``;
export const BottomDot1 = styled(ColoredPath).attrs({
  d: `M673.5,635.1c-3.9,0.7-7.4-3.4-4.2-6.3C673.5,625.5,678.9,632.6,673.5,635.1z`,
})``;
export const BottomDot2 = styled(ColoredPath).attrs({
  d: `M617.5,810c-6.7,0.8-13.1-8.8-5.3-13.7C621,789.2,628.8,807.1,617.5,810z`,
})``;
export const BottomRing = styled(ColoredPath).attrs({
  d: `M850.1,606.8c-1.1-10,9.2-17.9,18.2-16.7c8.9,1.2,15.6,6.1,15,18.7
	c-0.3,7.7-8.2,14.8-18.2,14.1C857.2,622.4,849.8,614.7,850.1,606.8z M865.6,597.7c-4.7,0.1-8.7,4-8.5,8.3c0.2,4.8,5.3,10.3,9.5,10
	c4.1-0.3,9.9-4.6,9.6-10.3C875.9,601.4,871.1,597.6,865.6,597.7z`,
})``;
export const RightDot1 = styled(ColoredPath).attrs({
  d: `M836.9,385.4c-2.9,0.4-5.7-3.8-2.3-6C838.5,376.3,841.9,384.2,836.9,385.4z`,
})``;
export const RightDot2 = styled(ColoredPath).attrs({
  d: `M909.1,390.8c-2.9,0.4-5.7-3.8-2.3-6C910.6,381.7,914,389.5,909.1,390.8z`,
})``;
export const RightDot3 = styled(ColoredPath).attrs({
  d: `M1012.5,526.5c-2.9,0.3-5.7-3.8-2.3-6C1014,517.4,1017.4,525.2,1012.5,526.5z M1011.7,523.3
	c-0.8,0.3-0.3,0.8,0.2,0.5C1011.7,523.7,1011.9,523.3,1011.7,523.3z M1009.1,524c0.2,0.2,0.2,0.5,0.5,0.5
	C1009.6,524.2,1009.3,524,1009.1,524z`,
})``;
export const RightDot4 = styled(ColoredPath).attrs({
  d: `M1234.3,371.7c5.5-0.6,4.4-9.7-0.8-9.1C1226.7,361.9,1227.4,372.9,1234.3,371.7z`,
})``;
export const RightDot5 = styled(ColoredPath).attrs({
  d: `M1121.6,459.6c-4.4,0.5-8.6-5.7-3.5-9C1123.9,446,1129,457.8,1121.6,459.6z`,
})``;
export const RightDot6 = styled(ColoredPath).attrs({
  d: `M1208.5,509.8c7.6,1.7,12.9-8.5,8.6-14.3c-3.2-3.6-10.4-5.2-14.2-0.8c-1.4,2.3-2.3,5-1.9,7.7
	c0.2,0,0,0.5,0.2,0.5C1201.7,506.3,1205.3,509.2,1208.5,509.8z`,
})``;
export const RightDot7 = styled(ColoredPath).attrs({
  d: `M1077.9,543.1L1077.9,543.1c-2-1.4-5.1-1-8.1,0.2c-1.7,0.7-2.7,1.8-3.3,3.6c0,0,0,0,0,0
	c0,0,0,0,0,0c-0.1,0.3-0.2,0.7-0.3,1c-0.4,1.2-0.5,2.5-0.3,3.9c0,0,0,0,0,0l0,0c0.8,5.6,6.6,11.6,13,6.4
	C1083.8,552.2,1082.3,544.9,1077.9,543.1z`,
})``;
