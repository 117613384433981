import React, { useState, useEffect } from 'react';
import { Modal, Checkbox } from '@matterapp/matter-ui';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import saveSurveyMutation from 'graphql-queries/mutations/surveys/saveCustomSurvey';
import { useMutation } from '@apollo/client';
import Toast from 'components/Toast/Toast';

const StyledModal = styled(Modal)({
  zIndex: 100
});

const ToggleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.blacks[5],
  width: '100%',
  padding: theme.spacing(2),
  marginBottom: '1px'
}));

export default function EditNotificationsModal(props) {
  const {
    isOpen,
    onClose,
    survey,
    tenantId,
    currentWorkspace
  } = props;
  const { slackWorkspace } = currentWorkspace || {};
  const [notifications, setNotifications] = useState({});
  const [saveSurvey] = useMutation(saveSurveyMutation);

  useEffect(() => {
    if (survey) {
      setNotifications({
        digest: survey.notifications.digest,
        response: survey.notifications.response
      });
    }
  }, [survey]);

  const { createdBy } = survey || {};

  const notificationCopy = slackWorkspace
    ? `All notifications are sent by @MatterBot on Slack to the survey creator (${createdBy?.fullName}).`
    : `All notifications are sent by Matter on Teams to the survey creator (${createdBy?.fullName}).`;

  return (
    <StyledModal 
      isOpen={isOpen} 
      onClose={onClose}
      size="large"
    >
      <Modal.Header
        header='Notifications'
        right={<Modal.Buttons.CloseIcon onClick={onClose} />}
      />
      <Modal.Body>
        <Box sx={{ padding: 3 }}>
          <Typography variant='body1'>
            {notificationCopy}
          </Typography>
          <Box sx={{ mt: 2, mb: 1 }}>
            <ToggleContainer>
              <Typography variant='body1' component='p' >
                New Responses
              </Typography>
              <Checkbox.Toggle
                size='S' 
                checked={notifications.response}
                onChange={() => {
                  setNotifications({
                    ...notifications,
                    response: !notifications.response
                  });
                }}
              />
            </ToggleContainer>
            <ToggleContainer>
              <Typography variant='body1' component='p'>
                Weekly Digest
              </Typography>
              <Checkbox.Toggle 
                size='S' 
                checked={notifications.digest}
                onChange={() => {
                  setNotifications({
                    ...notifications,
                    digest: !notifications.digest
                  });
                }}
              />
            </ToggleContainer>
          </Box>
        </Box>
      </Modal.Body>
      <Modal.Footer.WithCancelSave
        canClickSave
        onClickCancel={onClose}
        saveLabel='Apply Changes'
        onClickSave={async () => {
          await saveSurvey({ variables: { tenantId, id: survey.id, survey: { notifications } } });
          setNotifications({});
          onClose();
          Toast.success('Success! Notifications updated');
        }}
      />
    </StyledModal>
  );
};
