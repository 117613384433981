import React from 'react';
import styled from 'styled-components';
import UnitItem from './UnitItem';
import theme from '@matterapp/matter-theme';

const generateColumns = (responsive, numColumns) => {
  const style = [];
  const width = responsive ? `${100 / numColumns}%` : 'auto';
  for (let i = 0; i < numColumns; i++) {
    style.push(width);
  }
  return style.join(' ');
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 32px;

  display: inline-grid;
  grid-template-columns: ${({ responsive }) => generateColumns(responsive, 2)};
  grid-column-gap: 7px;
  grid-row-gap: 8px;
  justify-content: center;
  padding: ${({ responsive }) => responsive ? `0 ${theme.spacing.single}` : 0};

  & > * {
    ${({ responsive }) => responsive ? 'width: 100% !important;' : ''}
  }

  ${({ responsive }) => theme.media.medium`
    grid-template-columns: ${generateColumns(responsive, 3)};
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  `}
`;

const UnitsList = class extends React.Component {
  constructor(props) {
    super(props);
    const initialSkillIds = this.props.skillDetails.map(
      ({ skill }) => skill.id
    );
    this.initialSkillIds = new Set(initialSkillIds);
  }

  render() {
    const {
      responsiveLayout,
      skillDetails,
      skillGaugeProps,
      handleConfirmRemoveSkill,
      handleStartEditSkill,
      renderModal
    } = this.props;

    return (
      <Container responsive={responsiveLayout}>
        {skillDetails.map((skillDetail) => (
          <UnitItem
            rcLabel="matterapp-skills-unit-list"
            key={skillDetail.skill.id}
            skillDetail={skillDetail}
            skillGaugeProps={skillGaugeProps}
            isNew={!this.initialSkillIds.has(skillDetail.skill.id)}
            renderModal={renderModal}
            handleConfirmRemoveSkill={handleConfirmRemoveSkill}
            handleStartEditSkill={handleStartEditSkill}
          />
        ))}
      </Container>
    );
  }
};

UnitsList.defaultProps = {
  responsiveLayout: true,
};

export default UnitsList;
