import React from 'react';
import { 
  Box
} from '@mui/material';
import TextQuestionResponses from './TextQuestionResponses';
import EnpsScore from 'routes/pages/pulse/EnpsScore';

export default function EnpsQuestionResponses({ response, timezone, enpsData,  }) {
  const comments = response?.comments.map(({ createdAt, message }) => ({
    answer: message, 
    createdAt
  }));

  return (
    <Box>
      <EnpsScore 
        analyticsData={enpsData || {}}
        sx={{ mb: 3, boxShadow: 'none' }}
      />
      <Box sx={{ overflowY: 'scroll', maxHeight: '362px'}}>
        <TextQuestionResponses 
          response={{ results: comments }} 
          timezone={timezone} 
          noResultsText='No Comments Yet'
        />
      </Box>
    </Box>
  );
}
