import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const EmptyContainer = styled.div`
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  background: white;
  border-radius: 12px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  padding: 24px;
  padding-bottom: 32px;
`;

const EmptyHeading = styled.h3`
  color: ${() => theme.colors.blacks[60]}
  font-style: normal;
  font-weight: bold;
  line-height: 32px;
  font-size: 22px;
  margin: 0;
`;

const EmptyParagraph = styled.p`
  color: ${() => theme.colors.blacks[50]}
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 17px;
  margin: 0;
  margin-top: 8px;
`;

const Empty = () => (
  <EmptyContainer>
    <EmptyHeading>0 skills</EmptyHeading>
    <EmptyHeading>
      You have to have skills to pay the bills
      <span role="img" aria-label="moneybag emoji"> 💰</span>
      <span role="img" aria-label="stack of money with wings emoji"> 💸</span>
    </EmptyHeading>
    <EmptyParagraph>
      We recommend that you add or create between 3-8 skills. This helps to ensure that you
      receive feedback from your peers on your skillset.
    </EmptyParagraph>
  </EmptyContainer>
);

export default Empty;
