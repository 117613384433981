import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '@matterapp/matter-ui';
import { Field as ReduxFormField } from 'redux-form';

const SkillRatingNoteField = class extends React.Component {
  renderField = (reduxFormFieldProps) => (
    <TextArea.Basic
      fluid
      borderless
      rows={5}
      autoFocus={this.props.autoFocus}
      placeholder={this.props.placeholder}
      {...reduxFormFieldProps.input}
    />
  );

  render() {
    return (
      <ReduxFormField
        name="note"
        type="textarea"
        component={this.renderField}
      />
    );
  }
};

SkillRatingNoteField.defaultProps = {
  autoFocus: false,
};

SkillRatingNoteField.propTypes = {
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
};

export default SkillRatingNoteField;
