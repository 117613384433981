import { Provider } from '@matterapp/connected-accounts';
import { Resources, OAuthResourceName } from '@matterapp/routing';

const getGenericGetIsConnected = ({ resourceName }) => ({
  connectedAccountsMap,
}) => {
  if (!connectedAccountsMap) { return null; }
  const resource = connectedAccountsMap[resourceName];
  if (!resource) { return null; }
  return resource.length > 0;
};

const getGenericGetResource = ({ resourceName }) => ({
  connectedAccountsMap,
}) => {
  if (!connectedAccountsMap) { return null; }
  const resource = connectedAccountsMap[resourceName];
  if (!resource) { return null; }
  if (resource.length < 1) { return null; }
  return resource[0];
};

export const OAUTH_RESOURCE_DETAILS = {
  googleCombinedReadOnly: {
    name: 'googleCombinedReadOnly',
    userFriendlyName: 'Google account',
    disconnectSubheading: "If you disconnect Google, Matter won't be able to help you find peers.",
    overlayHeaderText: "We're connecting to your Google account...",
    overlaySubheaderText: "(This can take a while if you have a lot of contacts.)",
    connectUrl: Resources.oAuthConnect.url({ oAuthResourceName: OAuthResourceName.GoogleCombined }),
    provider: Provider.Google,
    isSyncPeerRecommendations: true,
    getIsConnected: ({ connectedAccountsMap }) => {
      const googleCalendarIsConnected = getGenericGetIsConnected({
        resourceName: 'googleCalendarReadOnly',
      });
      const googleContactsReadOnlyIsConnected = getGenericGetIsConnected({
        resourceName: 'googleContactsReadOnly',
      });
      return (
        googleCalendarIsConnected({ connectedAccountsMap }) &&
        googleContactsReadOnlyIsConnected({ connectedAccountsMap })
      );
    },
    getResource: getGenericGetResource({
      resourceName: 'googleCalendarReadOnly',
    }),
  },
  outlookCombinedReadOnly: {
    name: 'outlookCombinedReadOnly',
    userFriendlyName: 'Outlook account',
    disconnectSubheading: "If you disconnect Outlook, Matter won't be able to help you find peers.",
    overlayHeaderText: "We're connecting to your Outlook account...",
    overlaySubheaderText: "(This can take a while if you have a lot of contacts.)",
    connectUrl: Resources.oAuthConnect.url({ oAuthResourceName: OAuthResourceName.Microsoft }),
    provider: Provider.Microsoft,
    isSyncPeerRecommendations: true,
    getIsConnected: getGenericGetIsConnected({
      resourceName: 'outlookCombinedReadOnly',
    }),
    getResource: getGenericGetResource({
      resourceName: 'outlookCombinedReadOnly'
    })
  },
  slackContactsReadOnly: {
    name: 'slackContactsReadOnly',
    userFriendlyName: 'Slack account',
    disconnectSubheading: "If you disconnect Slack, Matter won't be able to help you find peers.",
    overlayHeaderText: "We're connecting to your Slack workspace...",
    overlaySubheaderText: "(This can take a while if you have a lot of contacts.)",
    connectUrl: Resources.oAuthConnect.url({ oAuthResourceName: OAuthResourceName.SlackContacts }),
    provider: Provider.Slack,
    isSyncPeerRecommendations: true,
    getIsConnected: getGenericGetIsConnected({
      resourceName: 'slackContactsReadOnly',
    }),
    getResource: getGenericGetResource({
      resourceName: 'slackContactsReadOnly'
    })
  },
  googleCalendarReadOnly: {
    name: 'googleCalendarReadOnly',
    userFriendlyName: 'Google Calendar',
    disconnectSubheading: "If you disconnect Google Calendar, Matter won't be able to help you find peers.",
    overlayHeaderText: "We're loading your calendar...",
    overlaySubheaderText: "(This can take a while if you have a lot of calendar events.)",
    connectUrl: Resources.oAuthConnect.url({ oAuthResourceName: OAuthResourceName.GoogleCalendar }),
    provider: Provider.Google,
    isSyncPeerRecommendations: true,
    getIsConnected: getGenericGetIsConnected({
      resourceName: 'googleCalendarReadOnly',
    }),
    getResource: getGenericGetResource({
      resourceName: 'googleCalendarReadOnly',
    }),
  },
  googleContactsReadOnly: {
    name: 'googleContactsReadOnly',
    userFriendlyName: 'Google Contacts',
    disconnectSubheading: "If you disconnect Google Contacts, Matter won't be able to help you find peers.",
    overlayHeaderText: "We're loading your contacts...",
    overlaySubheaderText: "(This can take a while if you have a lot of contacts.)",
    connectUrl: Resources.oAuthConnect.url({ oAuthResourceName: OAuthResourceName.GoogleContacts }),
    provider: Provider.Google,
    isSyncPeerRecommendations: true,
    getIsConnected: getGenericGetIsConnected({
      resourceName: 'googleContactsReadOnly',
    }),
    getResource: getGenericGetResource({
      resourceName: 'googleContactsReadOnly',
    }),
  },
  linkedIn: {
    name: 'linkedIn',
    userFriendlyName: 'LinkedIn',
    disconnectSubheading: "If you disconnect LinkedIn, Matter won't be able to help you find peers.",
    overlayHeaderText: 'Loading',
    overlaySubheaderText: 'Just one moment...',
    connectUrl: Resources.oAuthConnect.url({ oAuthResourceName: OAuthResourceName.LinkedIn }),
    provider: Provider.LinkedIn,
    isSyncLinkedInPhoto: true,
    getIsConnected: getGenericGetIsConnected({ resourceName: 'linkedIn' }),
    getResource: getGenericGetResource({ resourceName: 'linkedIn' }),
  },
  twitter: {
    name: 'twitter',
    userFriendlyName: 'Twitter',
    disconnectSubheading: "If you disconnect Twitter, Matter won't be able to help you find peers.",
    overlayHeaderText: 'Loading',
    overlaySubheaderText: 'Just one moment...',
    connectUrl: Resources.oAuthConnect.url({ oAuthResourceName: OAuthResourceName.Twitter }),
    provider: Provider.Twitter,
    isSyncTwitterPhoto: true,
    getIsConnected: getGenericGetIsConnected({ resourceName: 'twitter' }),
    getResource: getGenericGetResource({ resourceName: 'twitter' }),
  },
};
