import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as CommentPropTypes from './prop-types';
import Item from './Item';

const ListWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.purples[5]};
`;

class List extends React.Component {
  render() {
    const { comments, renderProfileCard } = this.props;
    if (comments.length === 0) { return null; }
    return (
      <ListWrapper>
        {comments.map((comment) => {
          return (
            <Item
              comment={comment}
              key={comment.id}
              renderProfileCard={renderProfileCard}
            />
          );
        })}
      </ListWrapper>
    );
  }
}

List.propTypes = {
  comments: PropTypes.arrayOf(CommentPropTypes.commentItem).isRequired,
  renderProfileCard: PropTypes.func.isRequired,
};

export default List;
