import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { 
  Paper,
  Typography,
  Box
} from '@mui/material';
import CustomCircularProgress from 'components/CircularProgress/CircularProgress';
import Chart from '../Chart/Chart';
import { formatAveragesToDataPoints } from '../dateFilters';
import theme from '@matterapp/matter-theme';
import { ChartsWrapper, ChartWrapper, ProgressWrapper, CategoryPaper } from '../styles';

const GroupedByTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.half};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.XS};
  line-height: ${theme.lineHeight.XS};
  color: ${theme.colors.blacks[50]};
`;

const SubcategoryPaper = styled(Paper)(({ theme, isSelected, hasScore }) => ({
  marginRight: theme.spacing(1),
  cursor: hasScore ? 'pointer' : 'inherit', 
  backgroundColor: isSelected ? theme.palette.purples[40] : theme.palette.white.main,
  flexGrow: 1,
  transitionDuration: '0.2s', 
  transitionProperty: 'background-color',
  ':hover': hasScore ? {
    backgroundColor: isSelected ? theme.palette.purples[40] :theme.palette.purples[5],
    [`> ${SubcategoryText}`]: {
      color: isSelected ? theme.palette.white.main : theme.palette.purples[40]
    }
  } : {},
  '> .MuiTypography-root': {
    color: isSelected ? 'white.main' : '#3E3E42',
  },
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(2)
  }
}));

const SubcategoryText = styled(Typography)(({ theme, isSelected, hasScore }) => ({
  color: isSelected ? theme.palette.white.main : theme.palette.blacks[80],
  fontWeight: 'bold',
  userSelect: 'none'
}));

const ScoreText = styled(SubcategoryText)(({ hasScore }) => ({
  fontWeight: hasScore ? 'bold' : 'normal',
  userSelect: 'none'
}));

const SubcategoryWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column'
  }
}));

export default function CategoryDetail({ 
  selectedSubcategories,
  setSelectedSubcategories,
  categoryData,
  startDate,
  endDate,
  xAxisMaxLabels,
  groupBy,
  isLoadingData,
  groupedBy
}) {
  const [chartData, setChartData] = useState({});
  const { name, description, score, explanation, subcategories = [], datePoints, rollingEngagementScore = [] } = categoryData || {};
  const [categoryDetails, setCategoryDetails] = useState({ name, description, subcategories });

  const hasScore = score != null;

  useEffect(() => {
    if (categoryData) {
      setCategoryDetails({ name, description, subcategories });
    }
  }, [categoryData]);

  useEffect(() => {
    if (categoryData) {
      setChartData({
        ...formatAveragesToDataPoints(rollingEngagementScore, startDate, endDate, groupBy),
        yLabels: [100, 80, 60, 40, 20, 0].map(n => `${n}%`)
      });
    }
  }, [categoryData]);

  return (
    <CategoryPaper elevation={1} sx={{ mb: 3 }}>
      <Typography variant='h4' component='p' sx={{mb: '5px' }}>{categoryDetails.name}</Typography>
      <Typography variant='body1' component='p' sx={{ color: 'blacks.50' }}>
        {categoryDetails.description}
      </Typography>
      <ChartsWrapper>
        <ProgressWrapper>
          <CustomCircularProgress 
            thickness={2}
            size={264}
            value={score}
          >
            <Typography 
              sx={{
                fontSize: hasScore || isLoadingData ? '48px' : '24px',
                fontWeight: 'bold',
                lineHeight: '48px',
                mb: '4px'
              }}
              component="span" 
              color="black"
            >
              {hasScore ? `${score}%` : isLoadingData ? ' ' : 'No score yet'}
            </Typography>
            {isLoadingData ? (
              <Typography variant='h2' >
                &nbsp;
              </Typography>
            ) : (
              <Typography variant='h2'>
                {hasScore ? explanation : ''}
              </Typography>
            )}
          </CustomCircularProgress>
        </ProgressWrapper>
        <ChartWrapper>
          <Chart
            height='312px'
            data={chartData}
            showLineAtTheEnd={false}
            showLineAtTheStart={false}
            showScatterDataStyle={false}
            xAxisMaxLabels={xAxisMaxLabels}
            generateLabels={() => datePoints}
            formatXAxis={() => {
              if (groupBy === 'month') {
                return {
                  unit: 'month',
                  displayFormats: {
                    month: 'MMM',
                  }
                };
              }  

              return {
                unit: 'day',
                displayFormats: {
                  day: 'MMM D',
                }
              };
            }}
            maxStepSize={100}
            stepSize={20}
            yAxisRender={(value) => `${value}%`}
          />      
          {chartData?.averages?.length && <GroupedByTitle> {groupedBy} </GroupedByTitle>}
        </ChartWrapper>
      </ChartsWrapper>
      {categoryDetails.subcategories.length ? (
        <Typography variant='h4' component='p' sx={{ mt: 5 }}>Subcategories</Typography>
      ) : null}
      <SubcategoryWrapper>
        {categoryDetails.subcategories.map(({ name, score }) => {
          const isSelected = selectedSubcategories.includes(name);
          const hasScore = score || score === 0;

          return (
            <SubcategoryPaper 
              elevation={1} 
              variant='outlined' 
              hasScore={hasScore}
              key={name}
              isSelected={isSelected}
              onClick={hasScore ? () => {
                if (isSelected) {
                  setSelectedSubcategories(selectedSubcategories.filter((subcategory) => subcategory !== name));
                } else {
                  setSelectedSubcategories([...selectedSubcategories, name]);
                }
              } : () => {}}
            >
              <ScoreText variant='body2' isSelected={isSelected} hasScore={hasScore}>
                {hasScore ? `${score}%` : 'No data yet'}
              </ScoreText>
              <SubcategoryText variant='body2' isSelected={isSelected}>
                {name}
              </SubcategoryText>
            </SubcategoryPaper>
          );
        })}
      </SubcategoryWrapper>
    </CategoryPaper>
  );
}
