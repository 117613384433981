import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../Container';
import theme from '@matterapp/matter-theme';

const EmptyContainer = styled(Container)`
 ${() => `background-color: ${theme.colors.blacks[10]};`}
`;

const Empty = ({
  children,
}) => (
  <EmptyContainer
    extraPadTop
    extraPadBottom
    centered
  >
    {children}
  </EmptyContainer>
);

Empty.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Empty;
