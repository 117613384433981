import React from 'react';
import Tags from '../../Tags';
import { formatItems } from '../Email';
import { isValidEmail } from '../../../../libs/emailValidator';

const EmailTags = (props) => (
  <Tags
    isValidValue={(email) => isValidEmail(email)}
    isInitiallyPristine={false}
    shouldToggleInputPristine={false}
    showValuesAsSubLabels
    {...props}
  />
);

EmailTags.formatItems = formatItems;

export default EmailTags;
