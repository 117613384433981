import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../SVG';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const DEFAULT_HEIGHT = 1190;
const DEFAULT_WIDTH = 1280;
const DEFAULT_VIEWBOX = `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`;

export const backgroundColors = {
  red: 'red',
  orange: 'orange',
  yellow: 'yellow',
  green: 'green',
  blue: 'blue',
  purple: 'purple',
  black: 'black',
  pink: 'red',
};

export const getFillColor = ({ colorizeBackground, color }) => {
  const backgroundColor = backgroundColors[color] || backgroundColors.blue;
  const baseColor = theme.colors[`${backgroundColor}s`] || theme.colors.blues;
  return `
    background-color: ${colorizeBackground ? baseColor[5] : 'transparent'};
    color: ${baseColor[5]};
    transition: color ${theme.transitions.times.default};
  `;
};

const Background = styled(SVG)`
  ${getFillColor}
  height: 100vh;
`;

const ColoredBackground = (props) => {
  const { color, ...otherProps } = props;

  return (
    <Background
      color={color}
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      preserveAspectRatio="xMidYMax meet"
      viewBox={DEFAULT_VIEWBOX}
      {...otherProps}
    />
  );
};

ColoredBackground.propTypes = {
  /** The colors of the background. */
  color: PropTypes.oneOf(Object.keys(backgroundColors)),
};

ColoredBackground.defaultProps = {
  color: backgroundColors.BLUE,
};

ColoredBackground.backgroundColors = backgroundColors;

export default ColoredBackground;
