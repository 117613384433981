import styled from 'styled-components';
import Dropdown from '../../Dropdown';
import Modal from '../../Modal';
import Peer from '../../Peer';
import { DisabledMessage } from '../sharedStyles';
import { colors, createDataRole, spacing } from '@matterapp/matter-theme';

export const MIN_MESSAGE_LENGTH = 3;
export const MAX_MESSAGE_LENGTH = 300;

export const LoadingContainer = styled.div`
  height: 50px;
  position: relative;
  margin-bottom: ${spacing.double}
`;

export const Container = styled.div``;

export const Wrapper = styled.div`
  padding: ${spacing.singleAndHalf} ${spacing.singleAndHalf} 0;
`;

export const KudosModal = styled(Modal).attrs(() => ({
  fullScreenMobile: true,
  size: Modal.sizes.XL,
}))``;

export const KudosContainer = styled.div`
  padding: ${spacing.singleAndHalf};
`;

export const UserContainer = styled.div`
  border-bottom: 1px solid ${colors.blacks[10]};
  padding: ${spacing.singleAndHalf};
`;

export const UserDropdownContainer = styled.div`
  position: relative;
`;

export const UserDropdown = styled(Dropdown.Email.Tags).attrs(() => ({
  fluid: true,
  showDropdownArrow: false,
}))`
  & + ${createDataRole(Dropdown.Menu.dataRole)} {
    max-height: 250px;
  }
`;

export const UserItem = styled(Peer.ListItem)`
  border: none;
  padding: 0;
  margin: 0;
`;

export const IsPublicDropdownContainer = styled.div`
  top: ${spacing.threeQuarters};
  right: ${spacing.quarter};
  position: absolute;
`;

export const IsPublicDropdown = styled(Dropdown).attrs(() => ({
  color: Dropdown.colors.GRAY,
  fitToValue: true,
  showMenuOverDropdown: true,
  size: Dropdown.sizes.XS,
}))`
  background: transparent !important;
  border: none;

  & input {
    min-width: 76px;
    text-transform: uppercase;
  }

  &:hover input {
    color: ${colors.purples[50]};
    -webkit-text-fill-color: ${colors.purples[50]};
  }

  &:hover svg path {
    fill: ${colors.purples[50]};
    stroke: ${colors.purples[50]};
  }

  & + ${createDataRole(Dropdown.Menu.dataRole)} {
    text-transform: uppercase;
    transform: translate(-1px, 1px);
    width: 86px;

    &[value="Private"] {
      transform: translate(-1px, -25px);
    }
  }
`;

export { DisabledMessage };
