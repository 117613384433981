import React from 'react';
import Animation from '../../Animation';
import Image from './Image';
import * as C from './classNames';

export default class KeepItUp extends Animation {
  baseClass = C.BASE;

  moveBird = () => {
    const tl = this.getDefaultTimeline({ duration: 1.2, ease: 'power1.inOut' });
    const BIRD = this.getElement(C.BIRD);

    tl.to(BIRD, { x: -360, ease: 'power1.in', duration: 4.8 }, '<');
    tl.to(BIRD, { y: -70 }, '<');
    tl.to(BIRD, { y: -30 }, '>');
    tl.to(BIRD, { y: -180, ease: 'power1.in' }, '>');
    tl.to(BIRD, { y: -310, ease: 'power1.out' }, '>');
    tl.to(BIRD, { opacity: 0, duration: 0.4 }, '>-0.4');

    tl.to(BIRD, { x: 90, y: 70, opacity: 1, duration: 0 }, '>');
    tl.to(BIRD, { x: 0, y: 0, ease: 'power1.out' }, '>');

    return tl;
  };

  moveWings = () => {
    const tl = this.getDefaultTimeline();
    const LEFT_WING = this.getElement(C.WING.LEFT);
    const RIGHT_WING = this.getElement(C.WING.RIGHT);

    const sharedProps = { transformOrigin: '0 100%', duration: 0.32 };
    const wingsUp = { scaleY: 1, rotate: 0, ...sharedProps };
    const wingsDown = { scaleY: 0.7, rotate: 18, ...sharedProps };
    const numberOfFlaps = 9;

    for (let i = 0; i <= numberOfFlaps; i++) {
      if (i < numberOfFlaps) {
        tl.to(LEFT_WING, wingsDown, '>');
        tl.to(RIGHT_WING, wingsDown, '<');
      }

      tl.to(LEFT_WING, wingsUp, '>');
      tl.to(RIGHT_WING, wingsUp, '<');
    }
    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = this.getBaseTimeline(initialize);
    if (!initialize) {
      tl.add(this.moveWings(), '<');
      tl.add(this.moveBird(), '<');
    }
    this.tl = tl;
    return this.tl;
  };

  render() {
    return <Image className={C.BASE} {...this.props} />;
  }
}
