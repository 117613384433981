import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListItem, List } from '../../List';
import Badge from '../Badge';
import { AddSkillType } from './prop-types';
import Text from '../../Typography/Text';
import omitStyled from '../../../libs/omitStyled';
import CheckMark from './CheckMark';
import theme from '@matterapp/matter-theme';

const BadgeBox = styled.div`
  width: 48px;
  margin-right: 16px;

  img {
    display: block;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const ActionBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StyledListItem = omitStyled(ListItem, [
  'isSelected',
  'isCreate',
  'isTagged',
  'canClick',
])`
  ${({ isSelected, isCreate, isTagged }) => {
    if (isSelected) {
      return `background: ${theme.colors.blacks[5]};`;
    } else if (isCreate || isTagged) {
      return `background: ${theme.colors.blues[5]};`;
    }
    return 'inherit';
  }};
`;

class AddList extends React.Component {
  renderSkillItem = (props, index) => {
    const { skill, isSelected, isCreate, isTagged } = props;
    const { onClickItem, renderSkillActions } = this.props;
    const canClickItem = typeof onClickItem === 'function';
    const textColor =
      isSelected && !canClickItem ? 'blacks[50]' : 'purples[50]';

    return (
      <StyledListItem
        key={skill.id}
        isSelected={isSelected}
        isCreate={isCreate}
        isTagged={isTagged}
        canClick={canClickItem}
        onClick={canClickItem ? (e) => onClickItem(e, props) : null}
        data-rc={`list-item-${index}`}
      >
        <BadgeBox>
          <Badge skill={skill} size="fluid" />
        </BadgeBox>
        <ContentBox>
          <Text color={textColor} textStyle={490} rcLabel="list-item-label" bold>
            {skill.name}
          </Text>
        </ContentBox>
        <ActionBox>
          {isTagged ? (
            <CheckMark />
          ) : (
            renderSkillActions({
              skill,
              isSelected,
              isCreate,
            })
          )}
        </ActionBox>
      </StyledListItem>
    );
  };

  render() {
    const { className, skills } = this.props;

    return (
      <React.Fragment>
        <List className={className} renderItem={this.renderSkillItem} items={skills} />
      </React.Fragment>
    );
  }
}

AddList.propTypes = {
  className: PropTypes.string,
  onClickItem: PropTypes.func,
  skills: PropTypes.arrayOf(AddSkillType).isRequired,
  renderSkillActions: PropTypes.func.isRequired,
};

export default AddList;
