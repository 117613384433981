import Base from './Base';
import Home from './Home';
import Workspace from './Workspace';

export const Composer = Base;
Composer.GettingStarted = Base;
Composer.Home = Home;
Composer.Workspace = Workspace;

export default Composer;
