export const BASE = 'kudosTeamPlayer';

export const JOYSTICK_1 = {
  GROUP: `${BASE}-joystick-1`,
  STICK: `${BASE}-joystick-1-stick`,
  BUTTON: `${BASE}-joystick-1-button`,
};

export const JOYSTICK_2 = {
  GROUP: `${BASE}-joystick-2`,
  STICK: `${BASE}-joystick-2-stick`,
  BUTTON: `${BASE}-joystick-2-button`,
};

export const CIRCLE = {
  BOTTOM: `${BASE}-circle-bottom`,
  LEFT_1: `${BASE}-circle-left-1`,
  LEFT_2: `${BASE}-circle-left-2`,
  LEFT_3: `${BASE}-circle-left-3`,
  RIGHT: `${BASE}-circle-right`,
  TOP_1: `${BASE}-circle-top-1`,
  TOP_2: `${BASE}-circle-top-2`,
};
