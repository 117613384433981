import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListItem from '../../ListItem';
import Button from '../../../Button';
import theme from '@matterapp/matter-theme';

const REPLACEMENT_TRANSITION_TIME = 300;
const ACTION_ERROR_MESSAGE = 'Could not complete action.';

export const componentDataType = 'list-item-container';

const AcceptButton = styled(Button.Simple.Primary).attrs({
  size: 'XS',
})`
  min-width: 56px;
`;

const DismissButton = styled(Button.Circle).attrs({
  size: 'small',
  type: 'remove',
})``;

const StyledListItem = styled(ListItem)`
  box-sizing: border-box;
  display: inline-flex;
  transition: background ${() => theme.transitions.times.default};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${() => theme.spacing.half};

  & > button {
    margin-top: 0;
  }

  ${({ isNarrow }) => theme.media.medium`
    margin-left: 0px;

    ${isNarrow &&
      `
      flex-direction: row-reverse;

      & ${DismissButton} {
        margin-right: -40px;
        transition: margin ${theme.transitions.times.default};
      }

      ${StyledListItem}:hover & ${DismissButton} {
        margin-right: 0px;
      }
    `}
  `}
`;

const CSSTransitionTime = REPLACEMENT_TRANSITION_TIME / 1000;
const CSSTransitionTimeHalf = CSSTransitionTime / 2;

const StyledReplacementListItem = styled(StyledListItem)`
  margin-left: -100%;
  transition: margin-left ${CSSTransitionTime}s,
    width ${CSSTransitionTimeHalf}s ${CSSTransitionTimeHalf}s;

  ${({ isReplaced, isReplacing }) => {
    if (isReplaced) {
      return `
        margin-left: calc(0% + ${theme.spacing.singleAndHalf});
        padding-right: 0;
        width: ${isReplacing ? '100%' : `calc(100% - ${theme.spacing.triple})`};

        & + ${StyledListItem} ${ButtonContainer} {
          opacity: 0;
        }
      `;
    } else {
      return `
        width: calc(100% - ${theme.spacing.singleAndHalf});
      `;
    }
  }}
`;

const ListItemContainer = styled.div`
  ${() => `
    max-height: 100px;
    overflow: hidden;
    white-space: nowrap;
    transition: max-height ${theme.transitions.times.default};
    z-index: 1;
  `}

  ${({ isRemoved, isAccepted }) => {
    if (isRemoved) {
      return `
          max-height: 0px;
          z-index: 0;
          border: none;
        `;
    }
    if (isAccepted) {
      return `
        border-top: 1px solid ${theme.colors.white};
        margin-top: -2px;
        padding-top: 1px;
      `;
    }
  }};
`;

const defaultRenderActions = (renderProps) => {
  const {
    acceptButtonLabel,
    handleClickAccept,
    handleClickDismiss,
    showDismissButton,
  } = renderProps;
  return (
    <>
      <AcceptButton onClick={handleClickAccept}>
        {acceptButtonLabel}
      </AcceptButton>
      {showDismissButton && <DismissButton onClick={handleClickDismiss} />}
    </>
  );
};

/**
 * Peer suggestion list item
 */
export default class PeerSuggestionsListItem extends React.Component {
  static AcceptButton = AcceptButton;
  static DismissButton = DismissButton;
  static componentDataType = componentDataType;

  static propTypes = {
    /** Label of the default accept button if `renderActions` prop func is not passed. */
    acceptButtonLabel: PropTypes.string,
    /** Content to show when peer is accepted. */
    acceptedText: PropTypes.node,
    /** When the user has no avatar image, generate default avatar of use stock avatar image (leaf). */
    defaultAvatarIsGenerated: PropTypes.bool,
    /** Content to show when peer is dismissed. */
    dismissedText: PropTypes.node,
    /** Function to get replacement peer if current peer is dismissed. */
    getReplacementPeer: PropTypes.func,
    /** If item is narrow in size. */
    isNarrow: PropTypes.bool,
    /**
     * Callback when an accept button is clicked.
     * @param { Object } e: The click event.
     * @param { Object } peerProps: Props of the item containing the accepted peer.
     * @returns { Promise } Any resolved call made to update peers list.
     */
    onClickAccept: PropTypes.func.isRequired,
    /**
     * Callback when a dismiss button is clicked.
     * @param { Object } e: The click event.
     * @param { Object } peerProps: Props of the item containing the dismissed peer.
     * @returns { Promise } Any resolved call made to update peers list.
     */
    onClickDismiss: PropTypes.func.isRequired,
    /**
     * Callback when peer is removed from the list.
     * @param { Object } peerProps: Props of the item containing the removed peer.
     * @returns { void }
     */
    onRemovePeer: PropTypes.func.isRequired,
    /** The initial peer definition object. */
    peer: PropTypes.shape({
      /** The email of the peer. */
      email: PropTypes.string.isRequired,
      /** The full name of the peer. */
      fullName: PropTypes.string,
      /** The url of the photo to display. */
      photoUrl: PropTypes.string,
      /** The source of the peer suggestion. */
      source: PropTypes.string,
    }),
    /**
     * Callback to render custom actions of the item.
     * If not passed in, a default Accept and Dismiss button are rendered.
     * @param { Object } callbackProps: {
     *   @param { String } acceptButtonLabel: The accept button label passed from props.
     *   @param { Function } handleClickAccept: When an accept type button is clicked.
     *   @param { Function } handleClickDismiss: When a dismiss type button is clicked.
     * }
     * @returns { React.Component } Rendered actions to show in the item.
     */
    renderActions: PropTypes.func,
    /** Callback function to render the profile card of the list item when hovered. */
    renderProfileCard: PropTypes.func,
    /** Show the dismiss button. */
    showDismissButton: PropTypes.bool,
  };

  static defaultProps = {
    acceptButtonLabel: 'Ask',
    acceptedText: 'Success!',
    dismissedText: 'Dismissed',
    getReplacementPeer: () => null,
    onClickAccept: () => {},
    onClickDismiss: () => {},
    onRemovePeer: () => {},
    renderActions: defaultRenderActions,
    showDismissButton: true,
  };

  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      peer: props.peer,
      replacementPeer: null,
      replacementText: '',
      isReplacing: false,
      isReplaced: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._transitionTimeout);
    clearTimeout(this._replacementTimeout);
  }

  /**
   * State triggered condition to animate the transition of replacing peer.
   * @returns { void }
   */
  triggerReplacePeerTransition = () => {
    if (this._isMounted) {
      this.setState({
        isAccepted: false,
        isReplaced: true,
        isReplacing: false,
      });
      this._replacementTimeout = setTimeout(
        this.handleReplacePeerInState,
        REPLACEMENT_TRANSITION_TIME
      );
    }
  };

  /**
   * Replaces the current peer with the peer replacement in state.
   * @returns { void }
   */
  handleReplacePeerInState = () => {
    const { peer, replacementPeer } = this.state;
    if (this._isMounted) {
      this.props.onRemovePeer({ ...this.props, peer });
      this.setState({
        clickEvent: null,
        isAccepted: false,
        isReplaced: false,
        isReplacing: false,
        peer: replacementPeer,
        replacementPeer: null,
      });
    }
  };

  /**
   * Starts the process of replacing current peer with replacement peer. If there is no replacement, then onRemovePeer gets called.
   * @param { Object } e: The click event.
   * @param { Object } replaceProps: Callback function and replacement text to display.
   * @returns { void }
   */
  handleReplacePeer = async (e, { callback, replacementText, waitToReplace }) => {
    const { getReplacementPeer, onRemovePeer } = this.props;

    if (!waitToReplace) {
      this.setState({ isReplacing: true, replacementText });
    }
    
    try {
      await callback(e, { ...this.props, peer: this.state.peer }); // Wait for `onClickAccept` or `onClickDismiss` props to resolve.
      if (waitToReplace) {
        this.setState({ isReplacing: true, replacementText });
      }
      const replacementPeer = getReplacementPeer();

      if (replacementPeer && replacementPeer.email && this._isMounted) {
        this.setState({ replacementPeer });
        this._transitionTimeout = setTimeout(
          this.triggerReplacePeerTransition,
          REPLACEMENT_TRANSITION_TIME
        );
      } else {
        this.setState({ isRemoved: true });
        onRemovePeer({ ...this.props, peer: this.state.peer });
      }
    } catch (e) {
      this.setState({ isAccepted: false, isReplacing: false });
      if (!waitToReplace) {
        console.error(ACTION_ERROR_MESSAGE, e);
      }
    }
  };

  /**
   * Callback when dismiss button is clicked.
   * @param { Object } e: The click event.
   * @returns { void }
   */
  handleClickDismiss = (e) => {
    const { dismissedText, onClickDismiss } = this.props;
    this.handleReplacePeer(e, {
      callback: onClickDismiss,
      replacementText: dismissedText,
    });
  };

  /**
   * Callback when request button is clicked.
   * @param { Object } e: The click event.
   * @returns { void }
   */
  handleClickAccept = (e) => {
    const { acceptedText, onClickAccept, waitToReplace } = this.props;
    this.setState({ isAccepted: true });
    this.handleReplacePeer(e, {
      callback: onClickAccept,
      replacementText: acceptedText,
      waitToReplace
    });
  };

  renderActions = (isReplacing) => {
    const { acceptButtonLabel, isNarrow, renderActions, showDismissButton } = this.props;
    const actions = isReplacing
      ? this.state.replacementText
      : renderActions({
        acceptButtonLabel,
        handleClickAccept: this.handleClickAccept,
        handleClickDismiss: this.handleClickDismiss,
        showDismissButton,
      });
    return <ButtonContainer isNarrow={isNarrow}>{actions}</ButtonContainer>;
  };

  render() {
    const { defaultAvatarIsGenerated } = this.props;
    const {
      isAccepted,
      isRemoved,
      isReplaced,
      isReplacing,
      peer,
      replacementPeer,
    } = this.state;
    return (
      <ListItemContainer
        isRemoved={isRemoved}
        isAccepted={isAccepted}
        data-type={componentDataType}
      >
        {replacementPeer && (
          <StyledReplacementListItem
            actions={this.renderActions()}
            defaultAvatarIsGenerated={defaultAvatarIsGenerated}
            isReplaced={isReplaced}
            isReplacing={isReplacing}
            peer={replacementPeer}
          />
        )}
        {peer && (
          <StyledListItem
            actions={this.renderActions(isReplacing)}
            defaultAvatarIsGenerated={defaultAvatarIsGenerated}
            isReplacing={isReplacing}
            isHighlighted={isAccepted}
            peer={peer}
            renderProfileCard={this.props.renderProfileCard}
          />
        )}
      </ListItemContainer>
    );
  }
}
