import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0179 3.98214C18.7083 2.67262 16.5695 2.67262 15.26 3.98214L4.3255 14.9166C3.8999 15.3422 3.86717 15.6587 3.86717 15.6587L3.01598 20.0019C2.96142 20.2747 3.04872 20.5584 3.24515 20.7549C3.44157 20.9513 3.7253 21.0386 3.99812 20.984L8.33043 20.1328C8.33043 20.1328 8.65781 20.1001 9.07249 19.6745L20.0179 8.72913C21.3383 7.41962 21.3383 5.29165 20.0179 3.98214ZM4.72926 19.2707L5.12212 17.2519L6.7481 18.8779L4.72926 19.2707ZM8.33043 18.2449L5.75505 15.6696L14.9435 6.48113L17.5189 9.05651L8.33043 18.2449ZM18.6101 7.96525L16.0348 5.38987L16.3621 5.06249C17.0715 4.35317 18.2282 4.35317 18.9375 5.06249C19.6468 5.77181 19.6468 6.92855 18.9375 7.63787L18.6101 7.96525Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
