import React from 'react';
import {
  Form,
  FormGroupHeader,
  ContactAutocomplete,
  Container,
} from '@matterapp/matter-ui';
import { propType } from 'graphql-anywhere';
import PropTypes from 'prop-types';
import {
  Field as ReduxFormField,
  FieldArray as ReduxFormFieldArray,
} from 'redux-form';
import { CONNECTED_ACCOUNTS_QUERY } from 'graphql-queries/queries';

const MultipleEmails = class extends React.Component {
  componentDidMount = () => {
    if (this.firstInput && this.props.autoFocus) {
      this.firstInput.focus();
    }
  };

  getOnChange = (reduxFormFieldProps) => (e) => {
    const { fields, index, input } = reduxFormFieldProps;
    const { autoAdd } = this.props;
    const reduxFormOnChange = input.onChange;
    if (autoAdd && index >= fields.length - 1) {
      fields.push('');
    }
    return reduxFormOnChange(e);
  };

  renderInputField = (reduxFormFieldProps) => {
    const { meta } = reduxFormFieldProps;
    const errorMessage = !meta.submitFailed ? null : meta.error;
    return (
      <Form.Input
        fluid
        ref={(input) => {
          if (
            input &&
            input.ref &&
            input.ref.inputRef &&
            reduxFormFieldProps.index === 0
          ) {
            this.firstInput = input.ref.inputRef;
          }
        }}
        errorMessage={errorMessage}
        size={this.props.size}
        placeholder={this.props.placeholder}
        type="email"
        {...reduxFormFieldProps.input}
        onChange={this.getOnChange(reduxFormFieldProps)}
      />
    );
  };

  renderAutocompleteField = (reduxFormFieldProps) => {
    const { meta } = reduxFormFieldProps;
    const errorMessage = !meta.submitFailed ? null : meta.error;

    return (
      <ContactAutocomplete
        ref={(input) => {
          if (
            input &&
            input.inputRef &&
            input.inputRef.refs &&
            input.inputRef.refs.input &&
            reduxFormFieldProps.index === 0
          ) {
            this.firstInput = input.inputRef.refs.input;
          }
        }}
        fluid
        errorMessage={errorMessage}
        size={this.props.size}
        placeholder={this.props.placeholder}
        {...reduxFormFieldProps.input}
        onChange={this.getOnChange(reduxFormFieldProps)}
        search={(query) => this.props.handleSearchContacts(query)}
      />
    );
  };

  renderField = (reduxFormFieldProps) => (
    <Container textAlign={this.props.textAlign} marginBottom={8}>
      <Form.Field>
        {this.props.isAutocompleteMode
          ? this.renderAutocompleteField(reduxFormFieldProps)
          : this.renderInputField(reduxFormFieldProps)}
      </Form.Field>
    </Container>
  );

  renderEmails = ({ fields }) => {
    const { label, footer } = this.props;
    return (
      <React.Fragment>
        {label && <FormGroupHeader title={label} />}
        {fields.map((field, index) => (
          <ReduxFormField
            key={index}
            component={this.renderField}
            name={field}
            index={index}
            fields={fields}
          />
        ))}
        {footer}
      </React.Fragment>
    );
  };

  render() {
    const { connectedAccountsData } = this.props;
    const { currentUser } = connectedAccountsData;
    if (!currentUser) { return null; }
    return <ReduxFormFieldArray name="emails" component={this.renderEmails} />;
  }
};

MultipleEmails.defaultProps = {
  autoFocus: false,
  autoAdd: false,
  placeholder: 'name@company.com',
  label: null,
  handleSearchContacts: null,
  footer: null,
  textAlign: 'center',
};

MultipleEmails.propTypes = {
  size: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  autoAdd: PropTypes.bool,
  placeholder: PropTypes.string,
  textAlign: PropTypes.string,
  label: PropTypes.string,
  isAutocompleteMode: PropTypes.bool.isRequired,
  handleSearchContacts: PropTypes.func,
  footer: PropTypes.node,
  connectedAccountsData: propType(CONNECTED_ACCOUNTS_QUERY).isRequired,
};

export default MultipleEmails;
