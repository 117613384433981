import styled from 'styled-components';

const ContentBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
  min-width: 0;
  max-width: 100%;
`;

export default ContentBox;
