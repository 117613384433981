import React from 'react';
import PropTypes from 'prop-types';
import {
  BaseBackgroundContainer,
  CloseButton,
  CloseIcon,
  Container,
  Contents,
  Header,
  HeaderContainer,
  SubHeader,
  Wrapper,
} from './styles';
import BaseBackground from './backgrounds/Base';

const Base = (props) => {
  const {
    background,
    contents,
    header,
    onClickClose,
    subHeader,
    ...otherProps
  } = props;
  return (
    <Container {...otherProps}>
      <Wrapper>
        <HeaderContainer>
          {header && <Header>{header}</Header>}
          {onClickClose && (
            <CloseButton onClick={onClickClose}>
              <CloseIcon />
            </CloseButton>
          )}
        </HeaderContainer>
        {subHeader && <SubHeader>{subHeader}</SubHeader>}
        {contents && <Contents>{contents}</Contents>}
      </Wrapper>
      {background}
    </Container>
  );
};

Base.defaultProps = {
  background: (
    <BaseBackgroundContainer>
      <BaseBackground />
    </BaseBackgroundContainer>
  ),
  header: 'Welcome',
  subHeader: 'This is your home on Matter',
};

Base.propTypes = {
  background: PropTypes.node,
  contents: PropTypes.node,
  header: PropTypes.node,
  onClickClose: PropTypes.func,
  subHeader: PropTypes.node,
};

export default Base;
