import React from 'react';
import PropTypes from 'prop-types';
import { useSettingsPanelState } from 'routes/pages/workspaces/hooks/shared';
import GiveKudosModal from 'routes/pages/workspaces/components/GiveKudosModal';

const RitualGiveKudosModal = ({
  isOpen,
  workspace,
  onClose,
}) => {
  const settingPanelProps = useSettingsPanelState(workspace);
  const { workspaceSideColumnProps } = settingPanelProps;
  const { onClickUpgrade } = workspaceSideColumnProps;

  return (
    <GiveKudosModal 
      showEmailInDropdown
      isOpen={isOpen}
      onClose={onClose}
      workspace={workspace}
      onClickUpgrade={onClickUpgrade}
      {...settingPanelProps}
    />
  );
};

RitualGiveKudosModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  workspace: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default RitualGiveKudosModal;