import * as Base from '../../styles';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled(Base.Container)``;

const BarRoot = styled(Base.BarRoot)`
  background-color: ${() => theme.colors.purples[60]};
`;

const HelperTextContainer = styled(Base.HelperTextContainer)``;

const StepCounter = styled(Base.StepCounter)`
  color: ${() => theme.colors.purples[10]};
`;

const Meter = styled(Base.Meter)`
  background: ${() => theme.colors.greens[40]};
`;

const SmoothingContainer = styled(Base.SmoothingContainer)`
  width: 359px;
`;

export default {
  Container,
  BarRoot,
  HelperTextContainer,
  StepCounter,
  Meter,
  SmoothingContainer
};
