import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
      fill={fill}
    />
    <path
      d="M16.7382 7.26318C16.2506 6.77555 15.4623 6.77555 14.9746 7.26318L7.24463 14.9932C6.757 15.4808 6.757 16.2691 7.24463 16.7567L7.24922 16.7612C7.49411 16.9939 7.80518 17.12 8.13141 17.12C8.45657 17.12 8.7705 16.9944 9.00818 16.7567L16.7382 9.02674C17.2258 8.53911 17.2258 7.75082 16.7382 7.26318Z"
      fill="white"
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#F26849',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;