import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ModalNew as Modal, EmojiList } from '../../components';
import theme from '@matterapp/matter-theme';

/** Constants */
const MIN_MODAL_DIMENSION = '358px';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${MIN_MODAL_DIMENSION};
  padding: 40px 24px 24px 24px;
  position: relative;
`;

const Heading = styled.h1`
  line-height: ${() => theme.sizes.fontSizes[60]};
  font-size: ${() => theme.sizes.fontSizes[55]};
  font-weight: bold;
  margin-bottom: ${() => theme.sizes.padding[30]};
`;

const SubHeading = styled.h2`
  border-top: ${() =>
    `${theme.inputs.borderDefault} ${theme.colors.blacks[10]}`};
  font-size: ${() => theme.sizes.fontSizes[40]};
  font-weight: bold;
  line-height: 20px;
  margin-bottom: ${() => theme.sizes.padding[30]};
  padding-top: ${() => `calc(${theme.sizes.padding[30]} * 2)`};
`;

const BodyContainer = styled.div`
  flex: 1 1 auto;
  font-size: ${() => theme.sizes.fontSizes[50]};
  line-height: ${() => theme.sizes.lineHeights[50]};
`;

const CloseButtonContainer = styled.div`
  text-align: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${() => theme.colors.blues[60]};
  font-size: ${() => theme.sizes.fontSizes[50]};
  line-height: ${() => theme.sizes.lineHeights[50]};
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

const EmojiHelpList = styled(EmojiList.Container)`
  margin-top: ${() => theme.sizes.padding[20]};

  ${() => theme.media.medium`
    margin-left: 0;
  `};
`;

export default class GiveFeedbackTip extends React.Component {
  static propTypes = {
    /**
     * Children components wrapped in a function that passes back `toggleModal`
     * callback. Call this function to open the tip modal.
     */
    children: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: () => {},
  };

  state = { isOpen: false };

  toggleModal = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;
    const bodyContent = (
      <EmojiHelpList>
        <EmojiList.ListItem emoji={'🎨'}>
          Add context by providing concrete examples.
        </EmojiList.ListItem>
        <EmojiList.ListItem emoji={'⭐️'}>
          Describe what they can start, stop, or continue doing.
        </EmojiList.ListItem>
        <EmojiList.ListItem emoji={'⚾️'}>
          {'Give examples of what a "home-run" would look like.'}
        </EmojiList.ListItem>
      </EmojiHelpList>
    );

    return (
      <Fragment>
        <Modal
          contentLabel="Tip Modal"
          customWidth={MIN_MODAL_DIMENSION}
          htmlOpenClassName="ReactInfoModal__Html--open" // Override html class to prevent window from scrolling to top
          isOpen={isOpen}
          onRequestClose={this.toggleModal}
          festive={false}
        >
          <Container>
            <Heading>Giving Amazing Feedback</Heading>
            <SubHeading>Tips to crafting an amazing feedback note</SubHeading>
            {bodyContent && <BodyContainer>{bodyContent}</BodyContainer>}
            <CloseButtonContainer>
              <CloseButton
                role="button"
                onClick={this.toggleModal}
                data-rc="closeInfoModalButton"
              >
                Close
              </CloseButton>
            </CloseButtonContainer>
          </Container>
        </Modal>
        {children({ toggleModal: this.toggleModal })}
      </Fragment>
    );
  }
}
