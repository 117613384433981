import React from 'react';
import LeftToCenter1 from '../Paths/LeftToCenter1';
import BasePath from './BasePath';

const Path8 = React.forwardRef((props, ref) => (
  <BasePath
    marginBottom={9}
    PathComponent={LeftToCenter1}
    pointProps={{ top: 105, left: 50 }}
    ref={ref}
    {...props}
  />
));

export default Path8;