import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clickable } from '../Clickable/Clickable';
import { IconNew as Icon } from '../Icon';
import theme from '@matterapp/matter-theme';

const checkmarkWidth = theme.spacing.singleAndHalf;
const transitionTime = theme.transitions.times.singleAndHalf;

const SHOW_NUM_STEP_BEFORE = 4;
const SHOW_NUM_STEP_BEFORE_MOBILE = 1;
const SHOW_NUM_STEP_AFTER = 7;

const getCheckmarkStyles = ({ isShown }) => {
  return `
    pointer-events: none;
    overflow: hidden;
    transition: margin-left ${transitionTime}, opacity ${transitionTime};
    margin-left: ${isShown ? 0 : `-${checkmarkWidth}`};
    opacity: ${isShown ? 1 : 0};
    & svg {
      transform: translateX(${isShown ? '0px' : checkmarkWidth});
      transition: transform ${transitionTime};
      path {
        fill: ${theme.colors.blacks[40]};
      }
    }
  `;
};

const getHeaderColor = ({
  hideStepAfter,
  hideStepBefore,
  hideStepBeforeMobile,
  isOpen,
  isNextStep,
  isPrevStep,
  stepDifference,
  stepNumber,
  visibleStep,
  theme,
}) => {
  const visibleStyles = `
    height: auto;
    padding: ${theme.spacing.threeQuarters} 0;
    pointer-events: all;
  `;
  const hiddenStyles = `
    height: 0;
    opacity: 0;
    padding: 0;
    pointer-events: none;
  `;

  if (
    (isPrevStep && stepNumber > visibleStep) ||
    (isNextStep && stepNumber < visibleStep + 1)
  ) {
    return hiddenStyles;
  }
  if (isOpen) {
    const responsiveStyles = theme.media.S`
      background: transparent;
      width: auto;
    `.join('');
    return `
      width: 100%;
      transition: background ${theme.transitions.times.default}
        ${theme.transitions.times.singleAndHalf},
        padding ${theme.transitions.times.singleAndHalf},
        opacity 0.3s;
      ${responsiveStyles}
    `;
  }
  if (stepNumber < visibleStep) {
    const baseOpacity =
      (SHOW_NUM_STEP_BEFORE - stepDifference) / SHOW_NUM_STEP_BEFORE;
    const additionalOpacity = 0.04 * SHOW_NUM_STEP_BEFORE;
    const opacity = hideStepBefore ? 0 : baseOpacity - additionalOpacity;

    if (hideStepBeforeMobile) {
      const responsiveStyles = theme.media.S`
        opacity: ${opacity};
        ${!hideStepBefore ? visibleStyles : hiddenStyles}
      `.join('');
      return `
        ${hiddenStyles}
        ${responsiveStyles}
      `;
    }
    return `
     ${visibleStyles};
      opacity: ${opacity};
    `;
  }
  if (stepNumber > visibleStep) {
    if (hideStepAfter) {
      return `
        opacity: 0;
        ${hiddenStyles}
      `;
    }
    const opacity =
      (SHOW_NUM_STEP_AFTER - stepDifference) / SHOW_NUM_STEP_AFTER;
    return `
      opacity: ${opacity};
      ${visibleStyles};
    `;
  }
};

const Checkmark = styled(Icon).attrs({
  name: 'checkmark',
})`
  margin-left: ${theme.spacing.half};
`;

const CheckmarkContainer = styled.div`
  ${getCheckmarkStyles}
`;

const HeaderContainer = styled(Clickable)`
  cursor: ${({ canClick }) => (canClick ? 'pointer' : 'default')};
  color: ${theme.colors.blacks[40]};
  display: flex;
  align-items: center;
  padding: ${theme.spacing.threeQuarters} 0;
  transition: all ${transitionTime} ease, opacity 0.1s;
  z-index: 1;
  ${getHeaderColor}
  ${theme.media.S`
    position: static;
  `};
`;

const Header = styled.div`
  font-size: calc(${theme.fontSize.L} - ${theme.spacing.quarter});
  font-weight: ${theme.fontWeight.medium};
  user-select: none;
  ${theme.media.S`
    font-size: ${theme.fontSize.L}
  `};
`;

export default class StepHeader extends React.PureComponent {
  static propTypes = {
    /**
     * The contents of the step.
     * If a function is passed, all the following private props below are accessible as render props.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /** The title of the step. */
    title: PropTypes.node,

    /*************************************************************************************/
    /** The following props are private props that are passed from the parent container. */
    /*************************************************************************************/
    /** If the step is clickable. */
    canClick: PropTypes.bool,
    /** If this step is completed. */
    isCompletedStep: PropTypes.bool,
    /** If this is the current step in the timeline. */
    isCurrentStep: PropTypes.bool,
    /** If this is the last step in the timeline. */
    isLastStep: PropTypes.bool,
    /** If this is a next step in the timeline. */
    isNextStep: PropTypes.bool,
    /** If this is a previous step in the timeline. */
    isPrevStep: PropTypes.bool,
    /** If the step is currently open. */
    isOpen: PropTypes.bool,
    /** Callback when header is clicked. */
    onClick: PropTypes.func,
    /** The number of the step. Automatically passed in from `SteppedTimeline` */
    stepNumber: PropTypes.number,
    /** The total numebr of steps in the timeline. */
    totalNumberOfSteps: PropTypes.number,
    /** The 'Up Next' label. */
    upNextLabel: PropTypes.string,
    /** The current visible step. */
    visibleStep: PropTypes.number,
  };

  static defaultProps = {
    showNumStepAfter: SHOW_NUM_STEP_AFTER,
    showNumStepBefore: SHOW_NUM_STEP_BEFORE,
    showNumStepBeforeMobile: SHOW_NUM_STEP_BEFORE_MOBILE,
  };

  /**
   * Callback when a step header is clicked.
   * @param { Object } e: The click event.
   * @returns { void }
   */
  handleClick = (e) => {
    const { canClick, isOpen, onClick } = this.props;
    if (canClick && !isOpen && typeof onClick === 'function') {
      onClick(e, this.props);
    }
  };

  render() {
    const {
      canClick,
      isLastStep,
      isNextStep,
      isPrevStep,
      isOpen,
      isCompletedStep,
      showNumStepAfter,
      showNumStepBefore,
      showNumStepBeforeMobile,
      stepNumber,
      title,
      totalNumberOfSteps,
      visibleStep,
    } = this.props;
    const stepDifference = Math.abs(stepNumber - visibleStep);

    return (
      <HeaderContainer
        onClick={this.handleClick}
        canClick={canClick && !isOpen}
        hideStepAfter={stepDifference >= showNumStepAfter}
        hideStepBefore={stepDifference >= showNumStepBefore}
        hideStepBeforeMobile={stepDifference >= showNumStepBeforeMobile}
        isLastStep={isLastStep}
        isOpen={isOpen}
        isPrevStep={isPrevStep}
        isNextStep={isNextStep}
        totalNumberOfSteps={totalNumberOfSteps}
        stepDifference={stepDifference}
        stepNumber={stepNumber}
        visibleStep={visibleStep}
      >
        <Header
          data-value={title}
          data-visited-step={visibleStep >= stepNumber}
        >
          {title}
        </Header>
        <CheckmarkContainer isShown={isCompletedStep}>
          <Checkmark />
        </CheckmarkContainer>
      </HeaderContainer>
    );
  }
}
