import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../SVG';

const DEFAULT_SIZE = 480;

const Starburst = (props) => {
  const { fill, size, ...otherProps } = props;
  return (
    <SVG
      size={size}
      viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}
      {...otherProps}
    >
      <path
        fill={fill}
        d="M240,0L240,0c1.105,0,2,0.895,2,2v176c0,1.105-0.895,2-2,2l0,0c-1.105,0-2-0.895-2-2V2C238,0.895,238.895,0,240,0z M240,480
          L240,480c1.105,0,2-0.895,2-2V302c0-1.105-0.895-2-2-2l0,0c-1.105,0-2,0.895-2,2v176C238,479.105,238.895,480,240,480z
          M222.739,20.678L222.739,20.678c0.551-0.043,1.032,0.368,1.075,0.918l9.886,125.612c0.043,0.551-0.368,1.032-0.918,1.075l0,0
          c-0.551,0.043-1.032-0.368-1.075-0.918L221.82,21.754C221.777,21.203,222.188,20.721,222.739,20.678z M233.096,152.272
          c0.551-0.043,1.032,0.368,1.075,0.918s-0.368,1.032-0.918,1.075s-1.032-0.368-1.075-0.918S232.545,152.315,233.096,152.272z
          M233.567,158.253c0.551-0.043,1.032,0.368,1.075,0.918s-0.368,1.032-0.918,1.075s-1.032-0.368-1.075-0.918
          S233.016,158.296,233.567,158.253z M257.261,459.322L257.261,459.322c0.551-0.043,0.962-0.525,0.918-1.075l-9.886-125.612
          c-0.043-0.551-0.525-0.962-1.075-0.918l0,0c-0.551,0.043-0.962,0.525-0.918,1.075l9.886,125.612
          C256.229,458.954,256.71,459.365,257.261,459.322z M246.905,327.729c0.551-0.043,0.962-0.525,0.918-1.075
          c-0.043-0.551-0.525-0.962-1.075-0.918s-0.962,0.525-0.918,1.075S246.354,327.772,246.905,327.729z M246.434,321.748
          c0.551-0.043,0.962-0.525,0.918-1.075c-0.043-0.551-0.525-0.962-1.075-0.918s-0.962,0.525-0.918,1.075
          S245.883,321.791,246.434,321.748z M188.642,26.079L188.642,26.079c0.537-0.129,1.077,0.202,1.206,0.739l29.414,122.519
          c0.129,0.537-0.202,1.077-0.739,1.206l0,0c-0.537,0.129-1.077-0.202-1.206-0.739L187.903,27.285
          C187.775,26.748,188.105,26.208,188.642,26.079z M219.456,154.432c0.537-0.129,1.077,0.202,1.206,0.739
          c0.129,0.537-0.202,1.077-0.739,1.206c-0.537,0.129-1.077-0.202-1.206-0.739C218.589,155.1,218.919,154.56,219.456,154.432z
          M220.857,160.266c0.537-0.129,1.077,0.202,1.206,0.739c0.129,0.537-0.202,1.077-0.739,1.206c-0.537,0.129-1.077-0.202-1.206-0.739
          C219.99,160.934,220.32,160.394,220.857,160.266z M291.357,453.922L291.357,453.922c0.537-0.129,0.868-0.669,0.739-1.206
          l-29.414-122.519c-0.129-0.537-0.669-0.868-1.206-0.739l0,0c-0.537,0.129-0.868,0.669-0.739,1.206l29.414,122.519
          C290.28,453.72,290.82,454.05,291.357,453.922z M260.543,325.569c0.537-0.129,0.868-0.669,0.739-1.206
          c-0.129-0.537-0.669-0.868-1.206-0.739c-0.537,0.129-0.868,0.669-0.739,1.206C259.466,325.367,260.006,325.697,260.543,325.569z
          M259.142,319.735c0.537-0.129,0.868-0.669,0.739-1.206c-0.129-0.537-0.669-0.868-1.206-0.739c-0.537,0.129-0.868,0.669-0.739,1.206
          C258.065,319.533,258.605,319.863,259.142,319.735z M155.81,36.747L155.81,36.747c0.51-0.211,1.095,0.031,1.307,0.541
          l48.218,116.409c0.211,0.51-0.031,1.095-0.541,1.307l0,0c-0.51,0.211-1.095-0.031-1.307-0.541L155.269,38.053
          C155.057,37.543,155.3,36.958,155.81,36.747z M206.324,158.698c0.51-0.211,1.095,0.031,1.307,0.541
          c0.211,0.51-0.031,1.095-0.541,1.307c-0.51,0.211-1.095-0.031-1.307-0.541C205.571,159.495,205.814,158.91,206.324,158.698z
          M208.62,164.242c0.51-0.211,1.095,0.031,1.307,0.541c0.211,0.51-0.031,1.095-0.541,1.307c-0.51,0.211-1.095-0.031-1.307-0.541
          C207.867,165.039,208.11,164.454,208.62,164.242z M324.19,443.253L324.19,443.253c0.51-0.211,0.753-0.796,0.541-1.307
          l-48.218-116.409c-0.211-0.51-0.796-0.753-1.307-0.541l0,0c-0.51,0.211-0.753,0.796-0.541,1.307l48.218,116.409
          C323.095,443.222,323.68,443.465,324.19,443.253z M273.676,321.301c0.51-0.211,0.753-0.796,0.541-1.307
          c-0.211-0.51-0.796-0.753-1.307-0.541c-0.51,0.211-0.753,0.796-0.541,1.307C272.581,321.27,273.166,321.513,273.676,321.301z
          M271.38,315.758c0.51-0.211,0.753-0.796,0.541-1.307c-0.211-0.51-0.796-0.753-1.307-0.541c-0.51,0.211-0.753,0.796-0.541,1.307
          C270.285,315.727,270.87,315.97,271.38,315.758z M125.051,52.419L125.051,52.419c0.471-0.289,1.087-0.141,1.375,0.33l65.835,107.433
          c0.289,0.471,0.141,1.087-0.33,1.375l0,0c-0.471,0.289-1.087,0.141-1.375-0.33L124.72,53.795
          C124.432,53.324,124.58,52.708,125.051,52.419z M194.02,164.968c0.471-0.289,1.087-0.141,1.375,0.33
          c0.289,0.471,0.141,1.087-0.33,1.375c-0.471,0.289-1.087,0.141-1.375-0.33C193.401,165.872,193.549,165.256,194.02,164.968z
          M197.155,170.084c0.471-0.289,1.087-0.141,1.375,0.33c0.289,0.471,0.141,1.087-0.33,1.375c-0.471,0.289-1.087,0.141-1.375-0.33
          C196.536,170.988,196.684,170.372,197.155,170.084z M354.95,427.582L354.95,427.582c0.471-0.289,0.619-0.904,0.33-1.375
          l-65.835-107.433c-0.289-0.471-0.904-0.619-1.375-0.33l0,0c-0.471,0.289-0.619,0.904-0.33,1.375l65.835,107.433
          C353.863,427.722,354.479,427.87,354.95,427.582z M285.98,315.033c0.471-0.289,0.619-0.904,0.33-1.375
          c-0.289-0.471-0.904-0.619-1.375-0.33c-0.471,0.289-0.619,0.904-0.33,1.375C284.893,315.173,285.509,315.321,285.98,315.033z
          M282.845,309.917c0.471-0.289,0.619-0.904,0.33-1.375c-0.289-0.471-0.904-0.619-1.375-0.33c-0.471,0.289-0.619,0.904-0.33,1.375
          C281.758,310.057,282.374,310.205,282.845,309.917z M97.122,72.711L97.122,72.711c0.42-0.359,1.051-0.309,1.41,0.111l81.83,95.811
          c0.359,0.42,0.309,1.051-0.111,1.41l0,0c-0.42,0.359-1.051,0.309-1.41-0.111l-81.83-95.811
          C96.652,73.701,96.702,73.07,97.122,72.711z M182.848,173.085c0.42-0.359,1.051-0.309,1.41,0.111c0.359,0.42,0.309,1.051-0.111,1.41
          c-0.42,0.359-1.051,0.309-1.41-0.111C182.379,174.074,182.428,173.443,182.848,173.085z M186.745,177.647
          c0.42-0.359,1.051-0.309,1.41,0.111c0.359,0.42,0.309,1.051-0.111,1.41c-0.42,0.359-1.051,0.309-1.41-0.111
          C186.276,178.636,186.325,178.005,186.745,177.647z M382.878,407.29L382.878,407.29c0.42-0.359,0.47-0.99,0.111-1.41l-81.83-95.811
          c-0.359-0.42-0.99-0.47-1.41-0.111l0,0c-0.42,0.359-0.47,0.99-0.111,1.41l81.83,95.811
          C381.827,407.599,382.458,407.648,382.878,407.29z M297.151,306.916c0.42-0.359,0.47-0.99,0.111-1.41s-0.99-0.47-1.41-0.111
          c-0.42,0.359-0.47,0.99-0.111,1.41S296.731,307.274,297.151,306.916z M293.254,302.354c0.42-0.359,0.47-0.99,0.111-1.41
          s-0.99-0.47-1.41-0.111c-0.42,0.359-0.47,0.99-0.111,1.41S292.834,302.712,293.254,302.354z M72.711,97.122L72.711,97.122
          c0.359-0.42,0.99-0.47,1.41-0.111l95.811,81.83c0.42,0.359,0.47,0.99,0.111,1.41l0,0c-0.359,0.42-0.99,0.47-1.41,0.111
          l-95.811-81.83C72.402,98.173,72.352,97.542,72.711,97.122z M173.084,182.849c0.359-0.42,0.99-0.47,1.41-0.111
          c0.42,0.359,0.47,0.99,0.111,1.41c-0.359,0.42-0.99,0.47-1.41,0.111C172.775,183.9,172.726,183.269,173.084,182.849z
          M177.646,186.746c0.359-0.42,0.99-0.47,1.41-0.111c0.42,0.359,0.47,0.99,0.111,1.41c-0.359,0.42-0.99,0.47-1.41,0.111
          C177.337,187.797,177.288,187.166,177.646,186.746z M407.289,382.879L407.289,382.879c0.359-0.42,0.309-1.051-0.111-1.41
          l-95.811-81.83c-0.42-0.359-1.051-0.309-1.41,0.111l0,0c-0.359,0.42-0.309,1.051,0.111,1.41l95.811,81.83
          C406.3,383.348,406.931,383.299,407.289,382.879z M306.915,297.152c0.359-0.42,0.309-1.051-0.111-1.41
          c-0.42-0.359-1.051-0.309-1.41,0.111s-0.309,1.051,0.111,1.41S306.557,297.572,306.915,297.152z M302.353,293.255
          c0.359-0.42,0.309-1.051-0.111-1.41c-0.42-0.359-1.051-0.309-1.41,0.111s-0.309,1.051,0.111,1.41S301.995,293.675,302.353,293.255z
          M52.419,125.05L52.419,125.05c0.289-0.471,0.904-0.619,1.375-0.33l107.433,65.835c0.471,0.289,0.619,0.904,0.33,1.375l0,0
          c-0.289,0.471-0.904,0.619-1.375,0.33L52.749,126.425C52.278,126.137,52.131,125.521,52.419,125.05z M164.967,194.02
          c0.289-0.471,0.904-0.619,1.375-0.33c0.471,0.289,0.619,0.904,0.33,1.375c-0.289,0.471-0.904,0.619-1.375,0.33
          C164.827,195.107,164.679,194.491,164.967,194.02z M170.083,197.155c0.289-0.471,0.904-0.619,1.375-0.33
          c0.471,0.289,0.619,0.904,0.33,1.375c-0.289,0.471-0.904,0.619-1.375,0.33C169.943,198.242,169.795,197.626,170.083,197.155z
          M427.581,354.95L427.581,354.95c0.289-0.471,0.141-1.087-0.33-1.375L319.818,287.74c-0.471-0.289-1.087-0.141-1.375,0.33l0,0
          c-0.289,0.471-0.141,1.087,0.33,1.375l107.433,65.835C426.676,355.569,427.292,355.421,427.581,354.95z M315.033,285.98
          c0.289-0.471,0.141-1.087-0.33-1.375c-0.471-0.289-1.087-0.141-1.375,0.33c-0.289,0.471-0.141,1.087,0.33,1.375
          C314.128,286.599,314.744,286.451,315.033,285.98z M309.917,282.845c0.289-0.471,0.141-1.087-0.33-1.375
          c-0.471-0.289-1.087-0.141-1.375,0.33c-0.289,0.471-0.141,1.087,0.33,1.375C309.012,283.464,309.628,283.316,309.917,282.845z
          M36.746,155.81L36.746,155.81c0.211-0.51,0.796-0.753,1.307-0.541l116.409,48.218c0.51,0.211,0.753,0.796,0.541,1.307l0,0
          c-0.211,0.51-0.796,0.753-1.307,0.541L37.288,157.117C36.777,156.905,36.535,156.32,36.746,155.81z M158.699,206.324
          c0.211-0.51,0.796-0.753,1.307-0.541c0.51,0.211,0.753,0.796,0.541,1.307c-0.211,0.51-0.796,0.753-1.307,0.541
          C158.73,207.419,158.487,206.834,158.699,206.324z M164.242,208.62c0.211-0.51,0.796-0.753,1.307-0.541
          c0.51,0.211,0.753,0.796,0.541,1.307c-0.211,0.51-0.796,0.753-1.307,0.541C164.273,209.715,164.03,209.13,164.242,208.62z
          M443.254,324.191L443.254,324.191c0.211-0.51-0.031-1.095-0.541-1.307l-116.409-48.218c-0.51-0.211-1.095,0.031-1.307,0.541l0,0
          c-0.211,0.51,0.031,1.095,0.541,1.307l116.409,48.218C442.457,324.944,443.042,324.701,443.254,324.191z M321.302,273.676
          c0.211-0.51-0.031-1.095-0.541-1.307c-0.51-0.211-1.095,0.031-1.307,0.541c-0.211,0.51,0.031,1.095,0.541,1.307
          C320.505,274.429,321.09,274.186,321.302,273.676z M315.758,271.38c0.211-0.51-0.031-1.095-0.541-1.307
          c-0.51-0.211-1.095,0.031-1.307,0.541c-0.211,0.51,0.031,1.095,0.541,1.307C314.961,272.133,315.546,271.89,315.758,271.38z
          M26.079,188.643L26.079,188.643c0.129-0.537,0.669-0.868,1.206-0.739l122.519,29.414c0.537,0.129,0.868,0.669,0.739,1.206l0,0
          c-0.129,0.537-0.669,0.868-1.206,0.739L26.818,189.848C26.281,189.72,25.95,189.18,26.079,188.643z M154.431,219.458
          c0.129-0.537,0.669-0.868,1.206-0.739c0.537,0.129,0.868,0.669,0.739,1.206c-0.129,0.537-0.669,0.868-1.206,0.739
          C154.633,220.535,154.303,219.995,154.431,219.458z M160.265,220.858c0.129-0.537,0.669-0.868,1.206-0.739
          c0.537,0.129,0.868,0.669,0.739,1.206c-0.129,0.537-0.669,0.868-1.206,0.739C160.467,221.935,160.137,221.395,160.265,220.858z
          M453.921,291.359L453.921,291.359c0.129-0.537-0.202-1.077-0.739-1.206l-122.519-29.414c-0.537-0.129-1.077,0.202-1.206,0.739l0,0
          c-0.129,0.537,0.202,1.077,0.739,1.206l122.519,29.414C453.253,292.226,453.793,291.896,453.921,291.359z M325.568,260.544
          c0.129-0.537-0.202-1.077-0.739-1.206c-0.537-0.129-1.077,0.202-1.206,0.739c-0.129,0.537,0.202,1.077,0.739,1.206
          C324.9,261.411,325.44,261.081,325.568,260.544z M319.734,259.143c0.129-0.537-0.202-1.077-0.739-1.206
          c-0.537-0.129-1.077,0.202-1.206,0.739c-0.129,0.537,0.202,1.077,0.739,1.206C319.066,260.01,319.606,259.68,319.734,259.143z
          M20.678,222.739L20.678,222.739c0.043-0.551,0.525-0.962,1.075-0.918l125.612,9.886c0.551,0.043,0.962,0.525,0.918,1.075l0,0
          c-0.043,0.551-0.525,0.962-1.075,0.918l-125.612-9.886C21.046,223.771,20.635,223.29,20.678,222.739z M152.271,233.096
          c0.043-0.551,0.525-0.962,1.075-0.918s0.962,0.525,0.918,1.075s-0.525,0.962-1.075,0.918S152.228,233.647,152.271,233.096z
          M158.252,233.567c0.043-0.551,0.525-0.962,1.075-0.918s0.962,0.525,0.918,1.075s-0.525,0.962-1.075,0.918
          S158.209,234.118,158.252,233.567z M459.321,257.261L459.321,257.261c0.043-0.551-0.368-1.032-0.918-1.075L332.791,246.3
          c-0.551-0.043-1.032,0.368-1.075,0.918l0,0c-0.043,0.551,0.368,1.032,0.918,1.075l125.612,9.886
          C458.797,258.223,459.278,257.812,459.321,257.261z M327.728,246.905c0.043-0.551-0.368-1.032-0.918-1.075
          c-0.551-0.043-1.032,0.368-1.075,0.918s0.368,1.032,0.918,1.075S327.685,247.456,327.728,246.905z M321.747,246.434
          c0.043-0.551-0.368-1.032-0.918-1.075c-0.551-0.043-1.032,0.368-1.075,0.918s0.368,1.032,0.918,1.075
          S321.704,246.985,321.747,246.434z M20.678,257.261L20.678,257.261c-0.043-0.551,0.368-1.032,0.918-1.075l125.612-9.886
          c0.551-0.043,1.032,0.368,1.075,0.918l0,0c0.043,0.551-0.368,1.032-0.918,1.075L21.754,258.18
          C21.203,258.223,20.721,257.812,20.678,257.261z M152.272,246.905c-0.043-0.551,0.368-1.032,0.918-1.075s1.032,0.368,1.075,0.918
          s-0.368,1.032-0.918,1.075S152.315,247.456,152.272,246.905z M158.253,246.434c-0.043-0.551,0.368-1.032,0.918-1.075
          s1.032,0.368,1.075,0.918s-0.368,1.032-0.918,1.075S158.296,246.985,158.253,246.434z M459.322,222.739L459.322,222.739
          c-0.043-0.551-0.525-0.962-1.075-0.918l-125.612,9.886c-0.551,0.043-0.962,0.525-0.918,1.075l0,0
          c0.043,0.551,0.525,0.962,1.075,0.918l125.612-9.886C458.954,223.771,459.365,223.29,459.322,222.739z M327.729,233.096
          c-0.043-0.551-0.525-0.962-1.075-0.918c-0.551,0.043-0.962,0.525-0.918,1.075s0.525,0.962,1.075,0.918
          S327.772,233.647,327.729,233.096z M321.747,233.567c-0.043-0.551-0.525-0.962-1.075-0.918c-0.551,0.043-0.962,0.525-0.918,1.075
          s0.525,0.962,1.075,0.918S321.79,234.118,321.747,233.567z M26.079,291.359L26.079,291.359c-0.129-0.537,0.202-1.077,0.739-1.206
          l122.519-29.414c0.537-0.129,1.077,0.202,1.206,0.739l0,0c0.129,0.537-0.202,1.077-0.739,1.206L27.284,292.098
          C26.747,292.226,26.207,291.896,26.079,291.359z M154.432,260.544c-0.129-0.537,0.202-1.077,0.739-1.206
          c0.537-0.129,1.077,0.202,1.206,0.739c0.129,0.537-0.202,1.077-0.739,1.206C155.1,261.411,154.56,261.081,154.432,260.544z
          M160.266,259.143c-0.129-0.537,0.202-1.077,0.739-1.206c0.537-0.129,1.077,0.202,1.206,0.739c0.129,0.537-0.202,1.077-0.739,1.206
          C160.934,260.01,160.394,259.68,160.266,259.143z M453.922,188.643L453.922,188.643c-0.129-0.537-0.669-0.868-1.206-0.739
          l-122.519,29.414c-0.537,0.129-0.868,0.669-0.739,1.206l0,0c0.129,0.537,0.669,0.868,1.206,0.739l122.519-29.414
          C453.72,189.72,454.05,189.18,453.922,188.643z M325.569,219.457c-0.129-0.537-0.669-0.868-1.206-0.739
          c-0.537,0.129-0.868,0.669-0.739,1.206c0.129,0.537,0.669,0.868,1.206,0.739C325.367,220.534,325.697,219.994,325.569,219.457z
          M319.735,220.858c-0.129-0.537-0.669-0.868-1.206-0.739c-0.537,0.129-0.868,0.669-0.739,1.206c0.129,0.537,0.669,0.868,1.206,0.739
          C319.533,221.935,319.863,221.395,319.735,220.858z M36.747,324.191L36.747,324.191c-0.211-0.51,0.031-1.095,0.541-1.307
          l116.409-48.218c0.51-0.211,1.095,0.031,1.307,0.541l0,0c0.211,0.51-0.031,1.095-0.541,1.307L38.053,324.732
          C37.543,324.944,36.958,324.701,36.747,324.191z M158.698,273.676c-0.211-0.51,0.031-1.095,0.541-1.307
          c0.51-0.211,1.095,0.031,1.307,0.541c0.211,0.51-0.031,1.095-0.541,1.307C159.495,274.429,158.91,274.186,158.698,273.676z
          M164.242,271.38c-0.211-0.51,0.031-1.095,0.541-1.307c0.51-0.211,1.095,0.031,1.307,0.541c0.211,0.51-0.031,1.095-0.541,1.307
          C165.039,272.133,164.454,271.89,164.242,271.38z M443.253,155.81L443.253,155.81c-0.211-0.51-0.796-0.753-1.307-0.541
          l-116.409,48.218c-0.51,0.211-0.753,0.796-0.541,1.307l0,0c0.211,0.51,0.796,0.753,1.307,0.541l116.409-48.218
          C443.222,156.905,443.465,156.32,443.253,155.81z M321.301,206.324c-0.211-0.51-0.796-0.753-1.307-0.541
          c-0.51,0.211-0.753,0.796-0.541,1.307c0.211,0.51,0.796,0.753,1.307,0.541C321.27,207.419,321.513,206.834,321.301,206.324z
          M315.758,208.62c-0.211-0.51-0.796-0.753-1.307-0.541c-0.51,0.211-0.753,0.796-0.541,1.307c0.211,0.51,0.796,0.753,1.307,0.541
          C315.727,209.715,315.97,209.13,315.758,208.62z M52.419,354.949L52.419,354.949c-0.289-0.471-0.141-1.087,0.33-1.375
          l107.433-65.835c0.471-0.289,1.087-0.141,1.375,0.33l0,0c0.289,0.471,0.141,1.087-0.33,1.375L53.794,355.28
          C53.323,355.568,52.708,355.42,52.419,354.949z M164.968,285.98c-0.289-0.471-0.141-1.087,0.33-1.375s1.087-0.141,1.375,0.33
          c0.289,0.471,0.141,1.087-0.33,1.375S165.256,286.451,164.968,285.98z M170.084,282.845c-0.289-0.471-0.141-1.087,0.33-1.375
          s1.087-0.141,1.375,0.33c0.289,0.471,0.141,1.087-0.33,1.375S170.372,283.316,170.084,282.845z M427.581,125.05L427.581,125.05
          c-0.289-0.471-0.904-0.619-1.375-0.33l-107.433,65.835c-0.471,0.289-0.619,0.904-0.33,1.375l0,0c0.289,0.471,0.904,0.619,1.375,0.33
          l107.433-65.835C427.721,126.137,427.869,125.521,427.581,125.05z M315.033,194.02c-0.289-0.471-0.904-0.619-1.375-0.33
          c-0.471,0.289-0.619,0.904-0.33,1.375s0.904,0.619,1.375,0.33C315.173,195.107,315.321,194.491,315.033,194.02z M309.917,197.155
          c-0.289-0.471-0.904-0.619-1.375-0.33c-0.471,0.289-0.619,0.904-0.33,1.375s0.904,0.619,1.375,0.33
          C310.057,198.242,310.205,197.626,309.917,197.155z M72.711,382.879L72.711,382.879c-0.359-0.42-0.309-1.051,0.111-1.41
          l95.811-81.83c0.42-0.359,1.051-0.309,1.41,0.111l0,0c0.359,0.42,0.309,1.051-0.111,1.41l-95.811,81.83
          C73.701,383.348,73.069,383.299,72.711,382.879z M173.085,297.152c-0.359-0.42-0.309-1.051,0.111-1.41
          c0.42-0.359,1.051-0.309,1.41,0.111c0.359,0.42,0.309,1.051-0.111,1.41S173.443,297.572,173.085,297.152z M177.647,293.255
          c-0.359-0.42-0.309-1.051,0.111-1.41c0.42-0.359,1.051-0.309,1.41,0.111c0.359,0.42,0.309,1.051-0.111,1.41
          S178.005,293.675,177.647,293.255z M407.29,97.121L407.29,97.121c-0.359-0.42-0.99-0.47-1.41-0.111l-95.811,81.83
          c-0.42,0.359-0.47,0.99-0.111,1.41l0,0c0.359,0.42,0.99,0.47,1.41,0.111l95.811-81.83C407.599,98.173,407.648,97.541,407.29,97.121z
          M306.916,182.849c-0.359-0.42-0.99-0.47-1.41-0.111c-0.42,0.359-0.47,0.99-0.111,1.41c0.359,0.42,0.99,0.47,1.41,0.111
          C307.225,183.9,307.274,183.269,306.916,182.849z M302.354,186.746c-0.359-0.42-0.99-0.47-1.41-0.111
          c-0.42,0.359-0.47,0.99-0.111,1.41c0.359,0.42,0.99,0.47,1.41,0.111C302.663,187.797,302.712,187.166,302.354,186.746z
          M97.121,407.29L97.121,407.29c-0.42-0.359-0.47-0.99-0.111-1.41l81.83-95.811c0.359-0.42,0.99-0.47,1.41-0.111l0,0
          c0.42,0.359,0.47,0.99,0.111,1.41l-81.83,95.811C98.172,407.599,97.541,407.648,97.121,407.29z M182.849,306.916
          c-0.42-0.359-0.47-0.99-0.111-1.41c0.359-0.42,0.99-0.47,1.41-0.111c0.42,0.359,0.47,0.99,0.111,1.41
          C183.9,307.225,183.269,307.274,182.849,306.916z M186.746,302.354c-0.42-0.359-0.47-0.99-0.111-1.41
          c0.359-0.42,0.99-0.47,1.41-0.111c0.42,0.359,0.47,0.99,0.111,1.41C187.797,302.663,187.166,302.712,186.746,302.354z
          M382.879,72.711L382.879,72.711c-0.42-0.359-1.051-0.309-1.41,0.111l-81.83,95.811c-0.359,0.42-0.309,1.051,0.111,1.41l0,0
          c0.42,0.359,1.051,0.309,1.41-0.111l81.83-95.811C383.348,73.701,383.299,73.07,382.879,72.711z M297.152,173.085
          c-0.42-0.359-1.051-0.309-1.41,0.111c-0.359,0.42-0.309,1.051,0.111,1.41c0.42,0.359,1.051,0.309,1.41-0.111
          S297.572,173.443,297.152,173.085z M293.255,177.647c-0.42-0.359-1.051-0.309-1.41,0.111c-0.359,0.42-0.309,1.051,0.111,1.41
          c0.42,0.359,1.051,0.309,1.41-0.111S293.675,178.005,293.255,177.647z M125.05,427.582L125.05,427.582
          c-0.471-0.289-0.619-0.904-0.33-1.375l65.835-107.433c0.289-0.471,0.904-0.619,1.375-0.33l0,0c0.471,0.289,0.619,0.904,0.33,1.375
          l-65.835,107.433C126.137,427.722,125.521,427.87,125.05,427.582z M194.02,315.033c-0.471-0.289-0.619-0.904-0.33-1.375
          c0.289-0.471,0.904-0.619,1.375-0.33s0.619,0.904,0.33,1.375C195.107,315.173,194.491,315.321,194.02,315.033z M197.155,309.917
          c-0.471-0.289-0.619-0.904-0.33-1.375c0.289-0.471,0.904-0.619,1.375-0.33s0.619,0.904,0.33,1.375
          C198.242,310.057,197.626,310.205,197.155,309.917z M354.949,52.419L354.949,52.419c-0.471-0.289-1.087-0.141-1.375,0.33
          l-65.835,107.433c-0.289,0.471-0.141,1.087,0.33,1.375l0,0c0.471,0.289,1.087,0.141,1.375-0.33L355.28,53.795
          C355.568,53.324,355.42,52.708,354.949,52.419z M285.98,164.968c-0.471-0.289-1.087-0.141-1.375,0.33s-0.141,1.087,0.33,1.375
          c0.471,0.289,1.087,0.141,1.375-0.33S286.451,165.256,285.98,164.968z M282.845,170.084c-0.471-0.289-1.087-0.141-1.375,0.33
          s-0.141,1.087,0.33,1.375c0.471,0.289,1.087,0.141,1.375-0.33S283.316,170.372,282.845,170.084z M155.809,443.254L155.809,443.254
          c-0.51-0.211-0.753-0.796-0.541-1.307l48.218-116.409c0.211-0.51,0.796-0.753,1.307-0.541l0,0c0.51,0.211,0.753,0.796,0.541,1.307
          l-48.218,116.409C156.904,443.223,156.319,443.466,155.809,443.254z M206.324,321.302c-0.51-0.211-0.753-0.796-0.541-1.307
          c0.211-0.51,0.796-0.753,1.307-0.541c0.51,0.211,0.753,0.796,0.541,1.307C207.419,321.271,206.834,321.514,206.324,321.302z
          M208.62,315.758c-0.51-0.211-0.753-0.796-0.541-1.307c0.211-0.51,0.796-0.753,1.307-0.541c0.51,0.211,0.753,0.796,0.541,1.307
          C209.715,315.727,209.13,315.97,208.62,315.758z M324.19,36.747L324.19,36.747c-0.51-0.211-1.095,0.031-1.307,0.541l-48.218,116.409
          c-0.211,0.51,0.031,1.095,0.541,1.307l0,0c0.51,0.211,1.095-0.031,1.307-0.541l48.218-116.409
          C324.943,37.543,324.7,36.958,324.19,36.747z M273.676,158.699c-0.51-0.211-1.095,0.031-1.307,0.541
          c-0.211,0.51,0.031,1.095,0.541,1.307c0.51,0.211,1.095-0.031,1.307-0.541C274.429,159.496,274.186,158.911,273.676,158.699z
          M271.38,164.242c-0.51-0.211-1.095,0.031-1.307,0.541c-0.211,0.51,0.031,1.095,0.541,1.307c0.51,0.211,1.095-0.031,1.307-0.541
          C272.133,165.039,271.89,164.454,271.38,164.242z M188.642,453.922L188.642,453.922c-0.537-0.129-0.868-0.669-0.739-1.206
          l29.414-122.519c0.129-0.537,0.669-0.868,1.206-0.739l0,0c0.537,0.129,0.868,0.669,0.739,1.206l-29.414,122.519
          C189.719,453.72,189.179,454.05,188.642,453.922z M219.457,325.569c-0.537-0.129-0.868-0.669-0.739-1.206
          c0.129-0.537,0.669-0.868,1.206-0.739c0.537,0.129,0.868,0.669,0.739,1.206C220.534,325.367,219.994,325.697,219.457,325.569z
          M220.858,319.735c-0.537-0.129-0.868-0.669-0.739-1.206c0.129-0.537,0.669-0.868,1.206-0.739c0.537,0.129,0.868,0.669,0.739,1.206
          C221.935,319.533,221.395,319.863,220.858,319.735z M291.358,26.079L291.358,26.079c-0.537-0.129-1.077,0.202-1.206,0.739
          l-29.414,122.519c-0.129,0.537,0.202,1.077,0.739,1.206l0,0c0.537,0.129,1.077-0.202,1.206-0.739l29.414-122.519
          C292.225,26.748,291.895,26.208,291.358,26.079z M260.543,154.432c-0.537-0.129-1.077,0.202-1.206,0.739
          c-0.129,0.537,0.202,1.077,0.739,1.206c0.537,0.129,1.077-0.202,1.206-0.739C261.41,155.1,261.08,154.56,260.543,154.432z
          M259.143,160.267c-0.537-0.129-1.077,0.202-1.206,0.739c-0.129,0.537,0.202,1.077,0.739,1.206c0.537,0.129,1.077-0.202,1.206-0.739
          C260.01,160.935,259.68,160.395,259.143,160.267z M222.739,459.323L222.739,459.323c-0.551-0.043-0.962-0.525-0.918-1.075
          l9.886-125.612c0.043-0.551,0.525-0.962,1.075-0.918l0,0c0.551,0.043,0.962,0.525,0.918,1.075l-9.886,125.612
          C223.771,458.955,223.29,459.366,222.739,459.323z M233.096,327.73c-0.551-0.043-0.962-0.525-0.918-1.075
          c0.043-0.551,0.525-0.962,1.075-0.918s0.962,0.525,0.918,1.075S233.647,327.773,233.096,327.73z M233.566,321.748
          c-0.551-0.043-0.962-0.525-0.918-1.075c0.043-0.551,0.525-0.962,1.075-0.918s0.962,0.525,0.918,1.075
          S234.117,321.791,233.566,321.748z M257.261,20.679L257.261,20.679c-0.551-0.043-1.032,0.368-1.075,0.918L246.3,147.209
          c-0.043,0.551,0.368,1.032,0.918,1.075l0,0c0.551,0.043,1.032-0.368,1.075-0.918l9.886-125.612
          C258.223,21.204,257.812,20.722,257.261,20.679z M246.904,152.272c-0.551-0.043-1.032,0.368-1.075,0.918s0.368,1.032,0.918,1.075
          s1.032-0.368,1.075-0.918S247.455,152.315,246.904,152.272z M246.434,158.254c-0.551-0.043-1.032,0.368-1.075,0.918
          s0.368,1.032,0.918,1.075s1.032-0.368,1.075-0.918S246.985,158.297,246.434,158.254z M213.093,70.118L213.093,70.118
          c1.091-0.173,2.115,0.572,2.288,1.663l14.392,90.867c0.173,1.091-0.572,2.115-1.663,2.288l0,0c-1.091,0.173-2.115-0.572-2.288-1.663
          l-14.392-90.867C211.258,71.315,212.002,70.29,213.093,70.118z M209.964,50.364c1.091-0.173,2.115,0.572,2.288,1.663
          c0.173,1.091-0.572,2.115-1.663,2.288c-1.091,0.173-2.115-0.572-2.288-1.663S208.873,50.537,209.964,50.364z M208.4,40.487
          c1.091-0.173,2.115,0.572,2.288,1.663c0.173,1.091-0.572,2.115-1.663,2.288c-1.091,0.173-2.115-0.572-2.288-1.663
          S207.309,40.66,208.4,40.487z M211.528,60.241c1.091-0.173,2.115,0.572,2.288,1.663c0.173,1.091-0.572,2.115-1.663,2.288
          c-1.091,0.173-2.115-0.572-2.288-1.663S210.437,60.414,211.528,60.241z M229.049,170.862c1.091-0.173,2.115,0.572,2.288,1.663
          c0.173,1.091-0.572,2.115-1.663,2.288c-1.091,0.173-2.115-0.572-2.288-1.663S227.958,171.035,229.049,170.862z M266.906,409.882
          L266.906,409.882c1.091-0.173,1.835-1.197,1.663-2.288l-14.392-90.867c-0.173-1.091-1.197-1.835-2.288-1.663l0,0
          c-1.091,0.173-1.835,1.197-1.663,2.288l14.392,90.867C264.791,409.311,265.815,410.055,266.906,409.882z M270.035,429.636
          c1.091-0.173,1.835-1.197,1.663-2.288s-1.197-1.835-2.288-1.663s-1.835,1.197-1.663,2.288S268.944,429.809,270.035,429.636z
          M271.6,439.513c1.091-0.173,1.835-1.197,1.663-2.288s-1.197-1.835-2.288-1.663s-1.835,1.197-1.663,2.288
          S270.509,439.686,271.6,439.513z M268.471,419.759c1.091-0.173,1.835-1.197,1.663-2.288s-1.197-1.835-2.288-1.663
          s-1.835,1.197-1.663,2.288S267.38,419.932,268.471,419.759z M250.95,309.138c1.091-0.173,1.835-1.197,1.663-2.288
          s-1.197-1.835-2.288-1.663s-1.835,1.197-1.663,2.288S249.859,309.311,250.95,309.138z M172.016,30.768L172.016,30.768
          c1.051-0.341,2.179,0.234,2.52,1.284l48.207,148.365c0.341,1.051-0.234,2.179-1.284,2.52l0,0c-1.051,0.341-2.179-0.234-2.52-1.284
          L170.732,33.288C170.391,32.237,170.966,31.109,172.016,30.768z M165.836,11.746c1.051-0.341,2.179,0.234,2.52,1.284
          c0.341,1.051-0.234,2.179-1.284,2.52c-1.051,0.341-2.179-0.234-2.52-1.284C164.211,13.216,164.786,12.088,165.836,11.746z
          M168.926,21.257c1.051-0.341,2.179,0.234,2.52,1.284c0.341,1.051-0.234,2.179-1.284,2.52c-1.051,0.341-2.179-0.234-2.52-1.284
          C167.301,22.727,167.876,21.598,168.926,21.257z M307.984,449.232L307.984,449.232c1.051-0.341,1.625-1.47,1.284-2.52
          l-48.207-148.365c-0.341-1.051-1.47-1.625-2.52-1.284l0,0c-1.051,0.341-1.625,1.47-1.284,2.52l48.207,148.365
          C305.805,448.998,306.934,449.573,307.984,449.232z M314.164,468.254c1.051-0.341,1.625-1.47,1.284-2.52s-1.47-1.625-2.52-1.284
          c-1.051,0.341-1.625,1.47-1.284,2.52C311.985,468.02,313.114,468.595,314.164,468.254z M311.074,458.743
          c1.051-0.341,1.625-1.47,1.284-2.52s-1.47-1.625-2.52-1.284c-1.051,0.341-1.625,1.47-1.284,2.52
          C308.895,458.509,310.024,459.084,311.074,458.743z M140.122,43.979L140.122,43.979c0.984-0.501,2.189-0.11,2.69,0.874
          l70.823,138.997c0.501,0.984,0.11,2.189-0.874,2.69l0,0c-0.984,0.501-2.189,0.11-2.69-0.874L139.248,46.669
          C138.747,45.684,139.138,44.48,140.122,43.979z M131.042,26.159c0.984-0.501,2.189-0.11,2.69,0.874
          c0.501,0.984,0.11,2.189-0.874,2.69s-2.189,0.11-2.69-0.874C129.667,27.864,130.058,26.66,131.042,26.159z M135.582,35.069
          c0.984-0.501,2.189-0.11,2.69,0.874c0.501,0.984,0.11,2.189-0.874,2.69c-0.984,0.501-2.189,0.11-2.69-0.874
          C134.207,36.774,134.598,35.57,135.582,35.069z M339.878,436.021L339.878,436.021c0.984-0.501,1.375-1.706,0.874-2.69
          l-70.823-138.997c-0.501-0.984-1.706-1.376-2.69-0.874l0,0c-0.984,0.501-1.376,1.706-0.874,2.69l70.823,138.997
          C337.689,436.131,338.894,436.522,339.878,436.021z M348.958,453.842c0.984-0.501,1.375-1.706,0.874-2.69
          c-0.501-0.984-1.706-1.375-2.69-0.874c-0.984,0.501-1.376,1.706-0.874,2.69S347.974,454.343,348.958,453.842z M344.418,444.932
          c0.984-0.501,1.375-1.706,0.874-2.69c-0.501-0.984-1.706-1.375-2.69-0.874c-0.984,0.501-1.376,1.706-0.874,2.69
          S343.434,445.433,344.418,444.932z M43.979,140.122L43.979,140.122c0.501-0.984,1.706-1.376,2.69-0.874l138.997,70.823
          c0.984,0.501,1.376,1.706,0.874,2.69l0,0c-0.501,0.984-1.706,1.375-2.69,0.874L44.853,142.812
          C43.868,142.311,43.477,141.106,43.979,140.122z M26.158,131.042c0.501-0.984,1.706-1.376,2.69-0.874
          c0.984,0.501,1.375,1.706,0.874,2.69s-1.706,1.375-2.69,0.874C26.048,133.231,25.657,132.026,26.158,131.042z M35.068,135.582
          c0.501-0.984,1.706-1.376,2.69-0.874c0.984,0.501,1.375,1.706,0.874,2.69s-1.706,1.375-2.69,0.874
          C34.958,137.771,34.567,136.566,35.068,135.582z M436.022,339.878L436.022,339.878c0.501-0.984,0.11-2.189-0.874-2.69
          l-138.997-70.823c-0.984-0.501-2.189-0.11-2.69,0.874l0,0c-0.501,0.984-0.11,2.189,0.874,2.69l138.997,70.823
          C434.316,341.253,435.521,340.862,436.022,339.878z M453.842,348.958c0.501-0.984,0.11-2.189-0.874-2.69
          c-0.984-0.501-2.189-0.11-2.69,0.874c-0.501,0.984-0.11,2.189,0.874,2.69S453.341,349.942,453.842,348.958z M444.932,344.418
          c0.501-0.984,0.11-2.189-0.874-2.69c-0.984-0.501-2.189-0.11-2.69,0.874c-0.501,0.984-0.11,2.189,0.874,2.69
          S444.431,345.402,444.932,344.418z M30.768,172.016L30.768,172.016c0.341-1.051,1.47-1.625,2.52-1.284l148.365,48.207
          c1.051,0.341,1.625,1.47,1.284,2.52l0,0c-0.341,1.051-1.47,1.625-2.52,1.284L32.052,174.536
          C31.001,174.195,30.426,173.066,30.768,172.016z M11.746,165.836c0.341-1.051,1.47-1.625,2.52-1.284
          c1.051,0.341,1.625,1.47,1.284,2.52s-1.47,1.625-2.52,1.284C11.98,168.015,11.405,166.886,11.746,165.836z M21.257,168.926
          c0.341-1.051,1.47-1.625,2.52-1.284c1.051,0.341,1.625,1.47,1.284,2.52s-1.47,1.625-2.52,1.284
          C21.491,171.105,20.916,169.976,21.257,168.926z M449.233,307.984L449.233,307.984c0.341-1.051-0.234-2.179-1.284-2.52
          l-148.365-48.207c-1.051-0.341-2.179,0.234-2.52,1.284l0,0c-0.341,1.051,0.234,2.179,1.284,2.52l148.365,48.207
          C447.763,309.609,448.892,309.034,449.233,307.984z M468.253,314.164c0.341-1.051-0.234-2.179-1.284-2.52
          c-1.051-0.341-2.179,0.234-2.52,1.284s0.234,2.179,1.284,2.52C466.783,315.789,467.912,315.214,468.253,314.164z M458.743,311.074
          c0.341-1.051-0.234-2.179-1.284-2.52c-1.051-0.341-2.179,0.234-2.52,1.284s0.234,2.179,1.284,2.52
          C457.273,312.699,458.402,312.124,458.743,311.074z M30.767,307.984L30.767,307.984c-0.341-1.051,0.234-2.179,1.284-2.52
          l148.365-48.207c1.051-0.341,2.179,0.234,2.52,1.284l0,0c0.341,1.051-0.234,2.179-1.284,2.52L33.288,309.268
          C32.237,309.609,31.109,309.034,30.767,307.984z M11.746,314.164c-0.341-1.051,0.234-2.179,1.284-2.52
          c1.051-0.341,2.179,0.234,2.52,1.284c0.341,1.051-0.234,2.179-1.284,2.52C13.216,315.789,12.088,315.214,11.746,314.164z
          M21.257,311.074c-0.341-1.051,0.234-2.179,1.284-2.52c1.051-0.341,2.179,0.234,2.52,1.284c0.341,1.051-0.234,2.179-1.284,2.52
          C22.727,312.699,21.598,312.124,21.257,311.074z M449.232,172.017L449.232,172.017c-0.341-1.051-1.47-1.625-2.52-1.284
          l-148.365,48.207c-1.051,0.341-1.625,1.47-1.284,2.52l0,0c0.341,1.051,1.47,1.625,2.52,1.284l148.365-48.207
          C448.998,174.196,449.573,173.067,449.232,172.017z M468.254,165.836c-0.341-1.051-1.47-1.625-2.52-1.284
          c-1.051,0.341-1.625,1.47-1.284,2.52c0.341,1.051,1.47,1.625,2.52,1.284C468.02,168.015,468.595,166.886,468.254,165.836z
          M458.743,168.926c-0.341-1.051-1.47-1.625-2.52-1.284c-1.051,0.341-1.625,1.47-1.284,2.52c0.341,1.051,1.47,1.625,2.52,1.284
          C458.509,171.105,459.084,169.976,458.743,168.926z M43.979,339.878L43.979,339.878c-0.501-0.984-0.11-2.189,0.874-2.69
          l138.997-70.823c0.984-0.501,2.189-0.11,2.69,0.874l0,0c0.501,0.984,0.11,2.189-0.874,2.69L46.669,340.752
          C45.684,341.253,44.48,340.862,43.979,339.878z M26.158,348.958c-0.501-0.984-0.11-2.189,0.874-2.69
          c0.984-0.501,2.189-0.11,2.69,0.874c0.501,0.984,0.11,2.189-0.874,2.69C27.864,350.333,26.66,349.942,26.158,348.958z
          M35.068,344.418c-0.501-0.984-0.11-2.189,0.874-2.69c0.984-0.501,2.189-0.11,2.69,0.874c0.501,0.984,0.11,2.189-0.874,2.69
          C36.774,345.793,35.57,345.402,35.068,344.418z M436.021,140.122L436.021,140.122c-0.501-0.984-1.706-1.376-2.69-0.874
          l-138.997,70.823c-0.984,0.501-1.376,1.706-0.874,2.69l0,0c0.501,0.984,1.706,1.375,2.69,0.874l138.997-70.823
          C436.131,142.311,436.522,141.106,436.021,140.122z M453.842,131.042c-0.501-0.984-1.706-1.376-2.69-0.874
          c-0.984,0.501-1.375,1.706-0.874,2.69s1.706,1.375,2.69,0.874C453.952,133.231,454.343,132.026,453.842,131.042z M444.931,135.582
          c-0.501-0.984-1.706-1.376-2.69-0.874c-0.984,0.501-1.375,1.706-0.874,2.69s1.706,1.375,2.69,0.874
          C445.041,137.771,445.432,136.566,444.931,135.582z M140.122,436.022L140.122,436.022c-0.984-0.501-1.376-1.706-0.874-2.69
          l70.823-138.997c0.501-0.984,1.706-1.376,2.69-0.874l0,0c0.984,0.501,1.375,1.706,0.874,2.69l-70.823,138.997
          C142.311,436.132,141.106,436.523,140.122,436.022z M131.042,453.842c-0.984-0.501-1.376-1.706-0.874-2.69
          c0.501-0.984,1.706-1.375,2.69-0.874s1.375,1.706,0.874,2.69C133.231,453.952,132.026,454.343,131.042,453.842z M135.582,444.932
          c-0.984-0.501-1.376-1.706-0.874-2.69c0.501-0.984,1.706-1.375,2.69-0.874s1.375,1.706,0.874,2.69
          C137.771,445.042,136.566,445.433,135.582,444.932z M339.878,43.979L339.878,43.979c-0.984-0.501-2.189-0.11-2.69,0.874
          L266.365,183.85c-0.501,0.984-0.11,2.189,0.874,2.69l0,0c0.984,0.501,2.189,0.11,2.69-0.874l70.823-138.997
          C341.253,45.685,340.862,44.48,339.878,43.979z M348.958,26.159c-0.984-0.501-2.189-0.11-2.69,0.874
          c-0.501,0.984-0.11,2.189,0.874,2.69c0.984,0.501,2.189,0.11,2.69-0.874C350.333,27.864,349.942,26.66,348.958,26.159z
          M344.418,35.069c-0.984-0.501-2.189-0.11-2.69,0.874c-0.501,0.984-0.11,2.189,0.874,2.69c0.984,0.501,2.189,0.11,2.69-0.874
          C345.793,36.775,345.402,35.57,344.418,35.069z M172.016,449.233L172.016,449.233c-1.051-0.341-1.625-1.47-1.284-2.52
          l48.207-148.365c0.341-1.051,1.47-1.625,2.52-1.284l0,0c1.051,0.341,1.625,1.47,1.284,2.52l-48.207,148.365
          C174.195,448.999,173.066,449.574,172.016,449.233z M165.836,468.254c-1.051-0.341-1.625-1.47-1.284-2.52
          c0.341-1.051,1.47-1.625,2.52-1.284c1.051,0.341,1.625,1.47,1.284,2.52C168.015,468.02,166.886,468.595,165.836,468.254z
          M168.926,458.743c-1.051-0.341-1.625-1.47-1.284-2.52c0.341-1.051,1.47-1.625,2.52-1.284c1.051,0.341,1.625,1.47,1.284,2.52
          C171.105,458.509,169.976,459.084,168.926,458.743z M307.984,30.768L307.984,30.768c-1.051-0.341-2.179,0.234-2.52,1.284
          l-48.207,148.365c-0.341,1.051,0.234,2.179,1.284,2.52l0,0c1.051,0.341,2.179-0.234,2.52-1.284l48.207-148.365
          C309.609,32.237,309.034,31.109,307.984,30.768z M314.164,11.747c-1.051-0.341-2.179,0.234-2.52,1.284
          c-0.341,1.051,0.234,2.179,1.284,2.52c1.051,0.341,2.179-0.234,2.52-1.284C315.789,13.216,315.214,12.088,314.164,11.747z
          M311.074,21.257c-1.051-0.341-2.179,0.234-2.52,1.284c-0.341,1.051,0.234,2.179,1.284,2.52c1.051,0.341,2.179-0.234,2.52-1.284
          C312.699,22.727,312.124,21.598,311.074,21.257z M138.901,100.849L138.901,100.849c0.894-0.649,2.144-0.451,2.794,0.442
          l54.076,74.43c0.649,0.894,0.451,2.144-0.442,2.794l0,0c-0.894,0.649-2.144,0.451-2.794-0.442l-54.076-74.43
          C137.809,102.749,138.007,101.499,138.901,100.849z M127.145,84.669c0.894-0.649,2.144-0.451,2.794,0.442
          c0.649,0.894,0.451,2.144-0.442,2.794c-0.894,0.649-2.144,0.451-2.794-0.442C126.053,86.569,126.251,85.318,127.145,84.669z
          M121.267,76.579c0.894-0.649,2.144-0.451,2.794,0.442c0.649,0.894,0.451,2.144-0.442,2.794c-0.894,0.649-2.144,0.451-2.794-0.442
          C120.175,78.479,120.373,77.228,121.267,76.579z M133.023,92.759c0.894-0.649,2.144-0.451,2.794,0.442s0.451,2.144-0.442,2.794
          c-0.894,0.649-2.144,0.451-2.794-0.442S132.129,93.408,133.023,92.759z M198.855,183.368c0.894-0.649,2.144-0.451,2.794,0.442
          s0.451,2.144-0.442,2.794s-2.144,0.451-2.794-0.442S197.961,184.018,198.855,183.368z M341.099,379.151L341.099,379.151
          c0.894-0.649,1.092-1.9,0.442-2.794l-54.076-74.43c-0.649-0.894-1.9-1.092-2.794-0.442l0,0c-0.894,0.649-1.092,1.9-0.442,2.794
          l54.076,74.43C338.955,379.603,340.205,379.801,341.099,379.151z M352.855,395.331c0.894-0.649,1.092-1.9,0.442-2.794
          c-0.649-0.894-1.9-1.092-2.794-0.442c-0.894,0.649-1.092,1.9-0.442,2.794S351.961,395.981,352.855,395.331z M358.733,403.421
          c0.894-0.649,1.092-1.9,0.442-2.794c-0.649-0.894-1.9-1.092-2.794-0.442c-0.894,0.649-1.092,1.9-0.442,2.794
          S357.839,404.071,358.733,403.421z M346.977,387.241c0.894-0.649,1.092-1.9,0.442-2.794c-0.649-0.894-1.9-1.092-2.794-0.442
          c-0.894,0.649-1.092,1.9-0.442,2.794S346.083,387.891,346.977,387.241z M281.145,296.631c0.894-0.649,1.092-1.9,0.442-2.794
          c-0.649-0.894-1.9-1.092-2.794-0.442c-0.894,0.649-1.092,1.9-0.442,2.794S280.251,297.281,281.145,296.631z M100.849,138.901
          L100.849,138.901c0.649-0.894,1.9-1.092,2.794-0.442l74.43,54.076c0.894,0.649,1.092,1.9,0.442,2.794l0,0
          c-0.649,0.894-1.9,1.092-2.794,0.442l-74.43-54.076C100.398,141.045,100.2,139.795,100.849,138.901z M84.669,127.145
          c0.649-0.894,1.9-1.092,2.794-0.442c0.894,0.649,1.092,1.9,0.442,2.794c-0.649,0.894-1.9,1.092-2.794,0.442
          S84.02,128.039,84.669,127.145z M76.579,121.267c0.649-0.894,1.9-1.092,2.794-0.442c0.894,0.649,1.092,1.9,0.442,2.794
          c-0.649,0.894-1.9,1.092-2.794,0.442C76.128,123.411,75.929,122.161,76.579,121.267z M92.759,133.023
          c0.649-0.894,1.9-1.092,2.794-0.442s1.092,1.9,0.442,2.794c-0.649,0.894-1.9,1.092-2.794,0.442S92.11,133.917,92.759,133.023z
          M183.369,198.855c0.649-0.894,1.9-1.092,2.794-0.442s1.092,1.9,0.442,2.794s-1.9,1.092-2.794,0.442
          S182.719,199.749,183.369,198.855z M379.152,341.099L379.152,341.099c0.649-0.894,0.451-2.144-0.442-2.794l-74.43-54.076
          c-0.894-0.649-2.144-0.451-2.794,0.442l0,0c-0.649,0.894-0.451,2.144,0.442,2.794l74.43,54.076
          C377.252,342.191,378.502,341.993,379.152,341.099z M395.332,352.855c0.649-0.894,0.451-2.144-0.442-2.794
          c-0.894-0.649-2.144-0.451-2.794,0.442c-0.649,0.894-0.451,2.144,0.442,2.794S394.682,353.749,395.332,352.855z M403.422,358.733
          c0.649-0.894,0.451-2.144-0.442-2.794c-0.894-0.649-2.144-0.451-2.794,0.442c-0.649,0.894-0.451,2.144,0.442,2.794
          S402.772,359.627,403.422,358.733z M387.242,346.977c0.649-0.894,0.451-2.144-0.442-2.794c-0.894-0.649-2.144-0.451-2.794,0.442
          c-0.649,0.894-0.451,2.144,0.442,2.794S386.592,347.871,387.242,346.977z M296.632,281.145c0.649-0.894,0.451-2.144-0.442-2.794
          c-0.894-0.649-2.144-0.451-2.794,0.442c-0.649,0.894-0.451,2.144,0.442,2.794S295.982,282.039,296.632,281.145z M70.118,213.094
          L70.118,213.094c0.173-1.091,1.197-1.835,2.288-1.663l90.867,14.392c1.091,0.173,1.835,1.197,1.663,2.288l0,0
          c-0.173,1.091-1.197,1.835-2.288,1.663L71.78,215.382C70.689,215.209,69.945,214.185,70.118,213.094z M50.364,209.965
          c0.173-1.091,1.197-1.835,2.288-1.663s1.835,1.197,1.663,2.288c-0.173,1.091-1.197,1.835-2.288,1.663S50.191,211.056,50.364,209.965
          z M40.487,208.401c0.173-1.091,1.197-1.835,2.288-1.663s1.835,1.197,1.663,2.288c-0.173,1.091-1.197,1.835-2.288,1.663
          S40.314,209.492,40.487,208.401z M60.241,211.529c0.173-1.091,1.197-1.835,2.288-1.663s1.835,1.197,1.663,2.288
          c-0.173,1.091-1.197,1.835-2.288,1.663S60.068,212.62,60.241,211.529z M170.862,229.05c0.173-1.091,1.197-1.835,2.288-1.663
          c1.091,0.173,1.835,1.197,1.663,2.288c-0.173,1.091-1.197,1.835-2.288,1.663S170.689,230.141,170.862,229.05z M409.883,266.907
          L409.883,266.907c0.173-1.091-0.572-2.115-1.663-2.288l-90.867-14.392c-1.091-0.173-2.115,0.572-2.288,1.663l0,0
          c-0.173,1.091,0.572,2.115,1.663,2.288l90.867,14.392C408.686,268.742,409.71,267.998,409.883,266.907z M429.636,270.036
          c0.173-1.091-0.572-2.115-1.663-2.288s-2.115,0.572-2.288,1.663s0.572,2.115,1.663,2.288S429.463,271.127,429.636,270.036z
          M439.513,271.6c0.173-1.091-0.572-2.115-1.663-2.288s-2.115,0.572-2.288,1.663s0.572,2.115,1.663,2.288
          S439.34,272.691,439.513,271.6z M419.759,268.472c0.173-1.091-0.572-2.115-1.663-2.288s-2.115,0.572-2.288,1.663
          s0.572,2.115,1.663,2.288S419.586,269.563,419.759,268.472z M309.138,250.951c0.173-1.091-0.572-2.115-1.663-2.288
          c-1.091-0.173-2.115,0.572-2.288,1.663c-0.173,1.091,0.572,2.115,1.663,2.288S308.965,252.042,309.138,250.951z M70.118,266.907
          L70.118,266.907c-0.173-1.091,0.572-2.115,1.663-2.288l90.867-14.392c1.091-0.173,2.115,0.572,2.288,1.663l0,0
          c0.173,1.091-0.572,2.115-1.663,2.288l-90.867,14.392C71.315,268.742,70.29,267.998,70.118,266.907z M50.364,270.036
          c-0.173-1.091,0.572-2.115,1.663-2.288s2.115,0.572,2.288,1.663c0.173,1.091-0.572,2.115-1.663,2.288S50.537,271.127,50.364,270.036
          z M40.487,271.6c-0.173-1.091,0.572-2.115,1.663-2.288s2.115,0.572,2.288,1.663c0.173,1.091-0.572,2.115-1.663,2.288
          S40.66,272.691,40.487,271.6z M60.241,268.472c-0.173-1.091,0.572-2.115,1.663-2.288s2.115,0.572,2.288,1.663
          s-0.572,2.115-1.663,2.288S60.413,269.563,60.241,268.472z M170.862,250.951c-0.173-1.091,0.572-2.115,1.663-2.288
          c1.091-0.173,2.115,0.572,2.288,1.663c0.173,1.091-0.572,2.115-1.663,2.288S171.035,252.042,170.862,250.951z M409.882,213.094
          L409.882,213.094c-0.173-1.091-1.197-1.835-2.288-1.663l-90.867,14.392c-1.091,0.173-1.835,1.197-1.663,2.288l0,0
          c0.173,1.091,1.197,1.835,2.288,1.663l90.867-14.392C409.311,215.209,410.055,214.185,409.882,213.094z M429.636,209.965
          c-0.173-1.091-1.197-1.835-2.288-1.663s-1.835,1.197-1.663,2.288s1.197,1.835,2.288,1.663S429.809,211.056,429.636,209.965z
          M439.513,208.401c-0.173-1.091-1.197-1.835-2.288-1.663s-1.835,1.197-1.663,2.288s1.197,1.835,2.288,1.663
          S439.686,209.492,439.513,208.401z M419.759,211.529c-0.173-1.091-1.197-1.835-2.288-1.663s-1.835,1.197-1.663,2.288
          s1.197,1.835,2.288,1.663S419.932,212.62,419.759,211.529z M309.138,229.05c-0.173-1.091-1.197-1.835-2.288-1.663
          s-1.835,1.197-1.663,2.288s1.197,1.835,2.288,1.663S309.311,230.141,309.138,229.05z M100.848,341.1L100.848,341.1
          c-0.649-0.894-0.451-2.144,0.442-2.794l74.43-54.076c0.894-0.649,2.144-0.451,2.794,0.442l0,0c0.649,0.894,0.451,2.144-0.442,2.794
          l-74.43,54.076C102.748,342.192,101.498,341.994,100.848,341.1z M84.669,352.855c-0.649-0.894-0.451-2.144,0.442-2.794
          c0.894-0.649,2.144-0.451,2.794,0.442c0.649,0.894,0.451,2.144-0.442,2.794S85.318,353.749,84.669,352.855z M76.578,358.733
          c-0.649-0.894-0.451-2.144,0.442-2.794c0.894-0.649,2.144-0.451,2.794,0.442c0.649,0.894,0.451,2.144-0.442,2.794
          S77.228,359.627,76.578,358.733z M92.759,346.977c-0.649-0.894-0.451-2.144,0.442-2.794c0.894-0.649,2.144-0.451,2.794,0.442
          c0.649,0.894,0.451,2.144-0.442,2.794S93.408,347.871,92.759,346.977z M183.368,281.146c-0.649-0.894-0.451-2.144,0.442-2.794
          c0.894-0.649,2.144-0.451,2.794,0.442s0.451,2.144-0.442,2.794S184.018,282.04,183.368,281.146z M379.15,138.901L379.15,138.901
          c-0.649-0.894-1.9-1.092-2.794-0.442l-74.43,54.076c-0.894,0.649-1.092,1.9-0.442,2.794l0,0c0.649,0.894,1.9,1.092,2.794,0.442
          l74.43-54.076C379.602,141.045,379.8,139.795,379.15,138.901z M395.331,127.146c-0.649-0.894-1.9-1.092-2.794-0.442
          c-0.894,0.649-1.092,1.9-0.442,2.794c0.649,0.894,1.9,1.092,2.794,0.442S395.981,128.04,395.331,127.146z M403.421,121.268
          c-0.649-0.894-1.9-1.092-2.794-0.442c-0.894,0.649-1.092,1.9-0.442,2.794c0.649,0.894,1.9,1.092,2.794,0.442
          C403.873,123.412,404.071,122.162,403.421,121.268z M387.241,133.024c-0.649-0.894-1.9-1.092-2.794-0.442s-1.092,1.9-0.442,2.794
          c0.649,0.894,1.9,1.092,2.794,0.442S387.891,133.918,387.241,133.024z M296.631,198.856c-0.649-0.894-1.9-1.092-2.794-0.442
          s-1.092,1.9-0.442,2.794c0.649,0.894,1.9,1.092,2.794,0.442S297.281,199.75,296.631,198.856z M138.901,379.151L138.901,379.151
          c-0.894-0.649-1.092-1.9-0.442-2.794l54.076-74.43c0.649-0.894,1.9-1.092,2.794-0.442l0,0c0.894,0.649,1.092,1.9,0.442,2.794
          l-54.076,74.43C141.045,379.603,139.795,379.801,138.901,379.151z M127.145,395.331c-0.894-0.649-1.092-1.9-0.442-2.794
          c0.649-0.894,1.9-1.092,2.794-0.442c0.894,0.649,1.092,1.9,0.442,2.794S128.039,395.981,127.145,395.331z M121.267,403.421
          c-0.894-0.649-1.092-1.9-0.442-2.794c0.649-0.894,1.9-1.092,2.794-0.442c0.894,0.649,1.092,1.9,0.442,2.794
          C123.411,403.873,122.161,404.071,121.267,403.421z M133.023,387.241c-0.894-0.649-1.092-1.9-0.442-2.794s1.9-1.092,2.794-0.442
          c0.894,0.649,1.092,1.9,0.442,2.794S133.917,387.891,133.023,387.241z M198.855,296.631c-0.894-0.649-1.092-1.9-0.442-2.794
          s1.9-1.092,2.794-0.442c0.894,0.649,1.092,1.9,0.442,2.794S199.749,297.281,198.855,296.631z M341.099,100.849L341.099,100.849
          c-0.894-0.649-2.144-0.451-2.794,0.442l-54.076,74.43c-0.649,0.894-0.451,2.144,0.442,2.794l0,0
          c0.894,0.649,2.144,0.451,2.794-0.442l54.076-74.43C342.191,102.749,341.993,101.499,341.099,100.849z M352.855,84.669
          c-0.894-0.649-2.144-0.451-2.794,0.442c-0.649,0.894-0.451,2.144,0.442,2.794c0.894,0.649,2.144,0.451,2.794-0.442
          S353.749,85.318,352.855,84.669z M358.733,76.579c-0.894-0.649-2.144-0.451-2.794,0.442c-0.649,0.894-0.451,2.144,0.442,2.794
          c0.894,0.649,2.144,0.451,2.794-0.442S359.627,77.228,358.733,76.579z M346.977,92.759c-0.894-0.649-2.144-0.451-2.794,0.442
          c-0.649,0.894-0.451,2.144,0.442,2.794c0.894,0.649,2.144,0.451,2.794-0.442S347.871,93.409,346.977,92.759z M281.145,183.369
          c-0.894-0.649-2.144-0.451-2.794,0.442c-0.649,0.894-0.451,2.144,0.442,2.794s2.144,0.451,2.794-0.442
          S282.039,184.019,281.145,183.369z M213.094,409.883L213.094,409.883c-1.091-0.173-1.835-1.197-1.663-2.288l14.392-90.867
          c0.173-1.091,1.197-1.835,2.288-1.663l0,0c1.091,0.173,1.835,1.197,1.663,2.288l-14.392,90.867
          C215.209,409.312,214.185,410.056,213.094,409.883z M209.965,429.636c-1.091-0.173-1.835-1.197-1.663-2.288s1.197-1.835,2.288-1.663
          s1.835,1.197,1.663,2.288S211.056,429.809,209.965,429.636z M208.4,439.513c-1.091-0.173-1.835-1.197-1.663-2.288
          s1.197-1.835,2.288-1.663s1.835,1.197,1.663,2.288S209.491,439.686,208.4,439.513z M211.529,419.759
          c-1.091-0.173-1.835-1.197-1.663-2.288s1.197-1.835,2.288-1.663s1.835,1.197,1.663,2.288S212.62,419.932,211.529,419.759z
          M229.05,309.138c-1.091-0.173-1.835-1.197-1.663-2.288s1.197-1.835,2.288-1.663s1.835,1.197,1.663,2.288
          S230.141,309.311,229.05,309.138z M266.907,70.118L266.907,70.118c-1.091-0.173-2.115,0.572-2.288,1.663l-14.392,90.867
          c-0.173,1.091,0.572,2.115,1.663,2.288l0,0c1.091,0.173,2.115-0.572,2.288-1.663l14.392-90.867
          C268.742,71.315,267.998,70.291,266.907,70.118z M270.036,50.364c-1.091-0.173-2.115,0.572-2.288,1.663s0.572,2.115,1.663,2.288
          c1.091,0.173,2.115-0.572,2.288-1.663S271.127,50.537,270.036,50.364z M271.6,40.487c-1.091-0.173-2.115,0.572-2.288,1.663
          s0.572,2.115,1.663,2.288c1.091,0.173,2.115-0.572,2.288-1.663S272.691,40.66,271.6,40.487z M268.472,60.241
          c-1.091-0.173-2.115,0.572-2.288,1.663s0.572,2.115,1.663,2.288s2.115-0.572,2.288-1.663S269.563,60.414,268.472,60.241z
          M250.951,170.862c-1.091-0.173-2.115,0.572-2.288,1.663c-0.173,1.091,0.572,2.115,1.663,2.288c1.091,0.173,2.115-0.572,2.288-1.663
          S252.042,171.035,250.951,170.862z M409.706,70.294L409.706,70.294c0.781,0.781,0.781,2.047,0,2.828L285.255,197.574
          c-0.781,0.781-2.047,0.781-2.828,0l0,0c-0.781-0.781-0.781-2.047,0-2.828L406.878,70.294
          C407.659,69.513,408.925,69.513,409.706,70.294z M70.294,409.705L70.294,409.705c0.781,0.781,2.047,0.781,2.828,0l124.451-124.451
          c0.781-0.781,0.781-2.047,0-2.828l0,0c-0.781-0.781-2.047-0.781-2.828,0L70.294,406.877
          C69.513,407.658,69.513,408.924,70.294,409.705z M70.294,70.294L70.294,70.294c0.781-0.781,2.047-0.781,2.828,0l124.451,124.451
          c0.781,0.781,0.781,2.047,0,2.828l0,0c-0.781,0.781-2.047,0.781-2.828,0L70.294,73.123C69.513,72.342,69.513,71.075,70.294,70.294z
          M409.706,409.706L409.706,409.706c0.781-0.781,0.781-2.047,0-2.828L285.255,282.427c-0.781-0.781-2.047-0.781-2.828,0l0,0
          c-0.781,0.781-0.781,2.047,0,2.828l124.451,124.451C407.659,410.487,408.925,410.487,409.706,409.706z M0,240L0,240
          c0-1.105,0.895-2,2-2h176c1.105,0,2,0.895,2,2l0,0c0,1.105-0.895,2-2,2H2C0.895,242,0,241.105,0,240z M480,240L480,240
          c0-1.105-0.895-2-2-2H302c-1.105,0-2,0.895-2,2l0,0c0,1.105,0.895,2,2,2h176C479.105,242,480,241.105,480,240z"
      />
    </SVG>
  );
};

export default Starburst;

Starburst.propTypes = {
  /** The fill color of the starburst. */
  fill: PropTypes.string,
  /** The height and width in pixels. */
  size: PropTypes.number,
};

Starburst.defaultProps = {
  size: DEFAULT_SIZE,
};
