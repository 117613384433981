import React from 'react';
import PropTypes from 'prop-types';
import AvatarBase from './Base';
import Multiple from './Multiple';
import WithBadge from './WithBadge';
import WithCount from './WithCount';
import WithSkill from './WithSkill';

const Avatar = (props) => {
  // For backwards compatibility, `badgeUrl` prop will render WithBadge component.
  if (props.badgeUrl) {
    return <WithBadge {...props} />;
  }
  return <AvatarBase {...props} />;
};

Avatar.Multiple = Multiple;
Avatar.WithBadge = WithBadge;
Avatar.WithCount = WithCount;
Avatar.WithSkill = WithSkill;

Avatar.propTypes = {
  ...AvatarBase.propTypes,
  badgeUrl: PropTypes.string,
};

export { Avatar };

export default Avatar;
