import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ListItem from './ListItem';
import EmptyList from './EmptyList';
import Button from '../Button';
import { FeedbackRequest } from './prop-types';
import theme from '@matterapp/matter-theme';

const EndOfList = styled.div`
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[30]};
  margin-top: ${theme.spacing.singleAndHalf};
  text-align: center;
  letter-spacing: 0.02em;
`;

const ShowAllRequestsButton = styled(Button.Simple.Minimal.Primary)`
  margin: ${theme.spacing.singleAndHalf};
  margin-bottom: ${theme.spacing.double};
  color: ${theme.colors.blacks[40]};
  &:hover {
    background: white;
    color : ${theme.colors.blacks[60]};
    border: 1px solid ${theme.colors.blacks[30]};
  }
  &:active {
    background: white;
    color : ${theme.colors.blacks[60]};
    border: 1px solid ${theme.colors.blacks[70]};
  }
`; 

const BackToTopButton = styled(Button.Simple.Minimal)`
  margin: ${theme.spacing.singleAndHalf};
  color: ${theme.colors.blacks[40]};
  &:hover {
    border: 1px solid ${theme.colors.blacks[10]};
  }
`;

export default class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      isExpanded: false,
      showBackToTopButton: false,
    };
  }

  componentDidMount() {
    const { feedbackRequests } = this.props;
    const feedbackListLength = feedbackRequests.length;
    
    if (feedbackListLength <= 10) {
      this.handleListLengthLessThan10();
    } else if (feedbackListLength <= 20) {
      this.handleListLengthLessThan20();
    }
  }

  componentDidUpdate(prevProp) {
    const { feedbackRequests } = this.props;
    const { touched } = this.state;
    const feedbackListLength = feedbackRequests.length;

    if (prevProp.feedbackRequests !== feedbackRequests) {
      if (feedbackListLength <= 10) {
        this.handleListLengthLessThan10();
      } else if (feedbackListLength <= 20) {
        this.handleListLengthLessThan20();
      } else if (feedbackListLength > 20 && !touched) {
        this.handleListLengthMoreThan20();
      } else if (feedbackListLength > 20 && touched) {
        this.handleListLengthLessThan20();
      }
    }
  }

  handleListLengthMoreThan20() {
    this.setState({ isExpanded: false, showBackToTopButton: false });
  }

  handleListLengthLessThan20() {
    this.setState({ isExpanded: true, showBackToTopButton: true });
  }
  
  handleListLengthLessThan10() {
    this.setState({ isExpanded: true, showBackToTopButton: false});
  }

  renderEndOfListComponent() {
    const { showBackToTopButton, isExpanded } = this.state;

    if (isExpanded) {
      if (showBackToTopButton) {
        return (
          <>
            <EndOfList>END OF LIST</EndOfList>
            <BackToTopButton
              data-rc="back-to-top-button"
              color="black"
              onClick={() => 
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })}
            >
              Back to top
            </BackToTopButton>
          </>
        );
      }
      return <EndOfList>END OF LIST</EndOfList>;
    }
  }

  renderShowAllRequestButton() {
    const { isExpanded, touched } = this.state;
    
    if (!(isExpanded || touched)) {
      return (
        <ShowAllRequestsButton
          data-rc="show-all-requests-button"
          color="black"
          onClick={() => 
            this.setState({ 
              touched: true,
              isExpanded: true,
              showBackToTopButton: true
            })}
        >
          Show all requests
        </ShowAllRequestsButton>
      );
    }
  }

  render() {
    const {
      currentUserFirstName,
      feedbackRequests,
      createFollowUpLink,
      handleFollowUp,
      handleSelectInvitation,
      handleUpdateInfo,
      getRenderMenuList,
    } = this.props;
    const feedbackList = this.state.isExpanded ? feedbackRequests : feedbackRequests.slice(0, 20);

    if (!feedbackRequests.length) {
      return <EmptyList />;
    }
    return (
      <>
        {feedbackList.map(request => {
          const { id, invitedPeer, feedbackRequestStatus, eventTime, replacement, recurringFeedback } = request;
          const relacementId = replacement && replacement.id ? replacement.id : null;
          return (
            <ListItem
              key={id}
              person={invitedPeer}
              recurringFeedback={recurringFeedback}
              feedbackRequestStatus={feedbackRequestStatus}
              eventTime={eventTime}
              currentUserFirstName={currentUserFirstName}
              isInvitationReplaced={relacementId}
              renderMenuMap={getRenderMenuList(request)}
              handleSelectInvitation={handleSelectInvitation(request)}
              handleUpdateInfo={handleUpdateInfo}
              handleFollowUp={handleFollowUp}
              createFollowUpLink={createFollowUpLink}
            />
          );
        })}
        {this.renderEndOfListComponent()}
        {this.renderShowAllRequestButton()}
      </>
    );
  }
}

List.propTypes = {
  feedbackRequests: PropTypes.arrayOf(FeedbackRequest.isRequired),
  getRenderMenuList: PropTypes.func.isRequired,
  handleSelectInvitation: PropTypes.func.isRequired,
  createFollowUpLink: PropTypes.func,
  handleFollowUp: PropTypes.func,
};