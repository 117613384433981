import React from 'react';
import { DISPLAY_STATES, NOTIFICATION_TYPES, TIMINGS } from './consts';
import ToastNotification from './ToastNotification';

function getDisplayStateName(state) {
  for (const p in DISPLAY_STATES) {
    if (DISPLAY_STATES[p] == state) {
      return p;
    }
  }
}

class ToastManager extends React.Component {
  static _instance = null;

  static getInstance() {
    return this._instance || <ToastManager/>;
  }

  constructor() {
    super();

    this.queue = [];
    // The currently displayed item -- presence of this prop indicates
    // a locked queue -- a ToastNotification is currently being shown
    // The queue is async polled until both unlocked and empty
    this.currentItem = null;
    // Flag for when user has mouse over the toast notification, and
    // autodismiss should be paused
    this.isAutoDismissPaused = false;
    // Ref for communicating with child
    this.notificationElement = React.createRef();
    // Timestamp for when current notification was shown -- used for
    // auto-dismiss timing
    this.notificationDisplayedAt = null;
    // When a Toast is shown, there is a timeout for hiding it. If it is
    // hidden manually by clicking the Close button, the timeout is canceled
    this.hideTimeoutHandle = null;
    // Allow preemption of after-methods
    this.afterMethodTimeoutHandle = null;

    ToastManager._instance = this;
  }

  get notification() {
    const elem = this.notificationElement;
    return (elem && elem.current) || null;
  }

  /**
   * Displays a Toast notification. (Actually queues a notification for display,
   *   and kicks the queue if it's not already going.)
   * @param {Object} item: The alert to display
   * @param {string} item.displayState: Lifcycle states related to opacity
   *    and display properties. Should be one of DISPLAY_STATES
   * @param {string} item.notificationType: The type of notification to display.
   *    Should be one of NOTIFICATION_TYPES
   * @param {string|Component} content: The content to display in the Toast
   *    notification
   * @param {boolean} autoDismiss: Whether Toast fades out on its own. Defaults
   *    to `true`
   * @param {function} callback: Callback function to execute after Toast
   *    notification is dismissed
   */
  show(item) {
    // Do not queue up immediate duplicates
    const current = this.currentItem;
    if (current && current.notificationType == item.notificationType &&
      current.content == item.content) {
      return;
    }
    // Add the item to the internal queue
    this.queue.unshift(item);
    // Kick off display and polling loop
    this.showNextItem();
  }

  /**
   * Hides a Toast notification
   */
  hide() {
    this.removeAutoDismiss();
    this.notification.setState({
      displayState: DISPLAY_STATES.hidden
    });
  }

  /**
   * Displays each item in the notification queue. Locks the queue while each
   *    item is being displayed, and recursively polls until the queue is then
   *    unlocked, when any next item in the queue is processed. While locked and
   *    polling, external invocations of this function are no-ops.
   * @param {Object} opts: The alert to display
   * @param {string} opts.isPoll: If `true`, this invocation is from
   *    a recursive polling call, not an end-user invocation
   */
  showNextItem(opts = {}) {
    const pollingInterval = TIMINGS.fadeOutAuto + TIMINGS.renderWait + 100;
    // Each call in the polling loop includes the `isPoll` CPS param
    // to signal that it's not a new invocation from the outside
    const doPoll = () => {
      setTimeout(() => {
        this.showNextItem({isPoll: true});
      }, pollingInterval);
    };

    // If there's a currentItem, the queue is locked
    if (this.currentItem) {
      // If the queue is locked, ignore all calls except ongoing polling
      // If polling, schedule the next call to check if the queue is unlocked
      if (opts.isPoll) {
        doPoll();
      }
      return;
    }

    // If there's nothing left to display, we're done
    if (!this.queue.length) {
      return;
    }

    // Get the next item and display it
    const next = this.queue.pop();

    // Queue is not locked, and there are items to display
    // so save the current item, and lock the queue
    this.currentItem = next;

    this.notificationDisplayedAt = (new Date()).getTime();

    // Update the displayState, trigger re-render
    this.notification.setState({
      displayState: DISPLAY_STATES.attached,
      ...next
    });

    // And start polling
    doPoll();
  }

  handleChange(ev) {
    let displayState = ev.exited;

    if (displayState == DISPLAY_STATES.detached) {
      this.notificationDisplayedAt = null;
    }

    displayState = getDisplayStateName(displayState);

    this.runAfterMethod(displayState);
  }

  runAfterMethod(displayState) {
    // Allow preemption
    if (this.afterMethodTimeoutHandle) {
      clearTimeout(this.afterMethodTimeoutHandle);
      this.afterMethodTimeoutHandle = null;
    }

    // TODO: Replace this with react-transition-group timings
    const timings = {
      attached: TIMINGS.renderWait,
      shown: 0,
      hidden: TIMINGS.renderWait + TIMINGS.fadeOutAuto,
      detached: 0
    };

    // Dynamic method dispatch based on name of exited displayState
    const displayStateCapitalized = displayState.charAt(0).toUpperCase() + displayState.slice(1);
    const dispatchMethodName = `after${displayStateCapitalized}`;

    this.afterMethodTimeoutHandle = setTimeout(() => {
      this[dispatchMethodName]();
    }, timings[displayState]);
  }

  handleMouseEnter() {
    const { displayState } = this.notification.state;
    this.isAutoDismissPaused = true;
    if (displayState > DISPLAY_STATES.attached) {
      this.removeAutoDismiss();
      if (displayState != DISPLAY_STATES.shown) {
        this.runAfterMethod(getDisplayStateName(DISPLAY_STATES.attached));
      }
    }
  }

  handleMouseLeave() {
    const { displayState } = this.notification.state;
    if (displayState == DISPLAY_STATES.shown ||
      displayState == DISPLAY_STATES.hidden) {
      this.isAutoDismissPaused = false;
      this.setOrPerformAutoDismiss();
    }
  }

  removeAutoDismiss() {
    if (this.hideTimeoutHandle) {
      clearTimeout(this.hideTimeoutHandle);
      this.hideTimeoutHandle = null;
    }
  }

  setOrPerformAutoDismiss() {
    const { autoDismiss } = this.currentItem;

    // If there's an existing timeout, cancel it
    this.removeAutoDismiss();

    if (autoDismiss) {
      const now = (new Date()).getTime();
      const timeDisplayed = now - this.notificationDisplayedAt;
      const timeToDisplay = TIMINGS.defaultShowPeriod - timeDisplayed;

      if (timeToDisplay < 0) {
        this.hide();
      }
      else {
        this.hideTimeoutHandle = setTimeout(() => {
          this.hide();
        }, timeToDisplay);
      }
    }
  }

  // ----------------------
  // Begin lifecycle 'after' methods -- code that needs to run after
  // rendering for each dysplay state
  // ----------------------
  // After display set to 'block', fade the Toast in
  afterAttached() {
    this.notification.setState({
      displayState: DISPLAY_STATES.shown
    });
  }

  // After fading in, set the timer for the auto-dismissal
  afterShown() {
    if (!this.isAutoDismissPaused) {
      this.setOrPerformAutoDismiss();
    }
  }

  // After fading out, set display to 'none'
  afterHidden() {
    this.notification.setState({
      displayState: DISPLAY_STATES.detached
    });
  }

  // After setting display to 'none', unlock the queue
  // and run any callback specified
  afterDetached() {
    // NOTE: component initializes in detached state, and
    // may re-render in its detatched state. In those cases, it's
    // just a UI shell that doesn't represent any actual notif item
    if (this.currentItem) {
      const { callback } = this.currentItem;
      this.currentItem = null; // Unlock the queue
      if (typeof callback == 'function') {
        callback();
      }
    }
  }
  // ----------------------
  // End lifecycle 'after' methods
  // ----------------------

  render() {
    return (<>
      <ToastNotification ref={this.notificationElement}
        handleChange={this.handleChange.bind(this)}
        handleClose={this.hide.bind(this)}
        handleMouseEnter={this.handleMouseEnter.bind(this)}
        handleMouseLeave={this.handleMouseLeave.bind(this)}
      />
    </>);
  }
}

class Toast extends React.Component {
  render() {
    return (
      <>
        <ToastManager />
      </>
    );
  }
}

// Create static methods for the external API, one for each display state
Object.keys(NOTIFICATION_TYPES).forEach((notificationType) => {
  Toast[notificationType] = function (content, opts = {}) {
    const container = ToastManager.getInstance();
    container.show({
      notificationType: notificationType,
      content: content,
      autoDismiss: (typeof opts.autoDismiss != 'undefined') ?
        opts.autoDismiss : true,
      callback: opts.callback || null
    });
  };
});

export default Toast;

