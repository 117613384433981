import React from 'react';
import Base from '../../../svgBase';

const DEFAULT_HEIGHT = 36;
const DEFAULT_WIDTH = 29;
const PATH = `M1.40256 35.9999H28.198V28.4317H14.5617V28.2272L17.8344 25.4999C25.7946 18.8692 27.823 15.426 27.823 11.3862C27.823 4.87489 22.4878 0.613525 14.1185 0.613525C5.98779 0.613525 0.703699 5.13057 0.720744 12.4772H9.72074C9.7037 9.54534 11.4935 7.90898 14.1185 7.90898C16.7264 7.90898 18.5844 9.5283 18.5844 12.2044C18.5844 14.676 16.9992 16.2783 14.4253 18.409L1.40256 29.1817V35.9999Z`;

const Two = (props) => (
  <Base height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
);

export default Two;
