import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import RewardsRequestList from './RewardsRequestList/RewardsRequestList';
import PaymentFailedBanner from './RewardsRequestList/PaymentFailedBanner';
import BalanceSection from './Funding/BalanceSection';
import { 
  Paper, 
  Typography
} from '@mui/material';
import FundingHistory from './FundingHistory/FundingHistory';
import { useQuery } from '@apollo/client';
import getRewardsFundCreditsQuery from 'graphql-queries/queries/rewards/getRewardsFundCredits';
import getRewardsFundDebitsQuery from 'graphql-queries/queries/rewards/getRewardsFundDebits';

export default function RewardsManagerOverview() {
  const { 
    currentTenant, 
    currentWorkspaceId,
    isLoadingCurrentUser,
    currentWorkspaceId: workspaceId,
    currentWorkspace,
    refetchCurrentUser
  } = useContext(UserContext);
  const { rewardsSettings, stripeCustomerId, id: tenantId, rewardsFundingSettings } = currentTenant || {};
  const { isStripePaymentFailed } = rewardsSettings || {};
  const { enabled } = rewardsFundingSettings || {};

  const { data: creditData } = useQuery(getRewardsFundCreditsQuery, { 
    variables: { 
      tenantId
    },
    skip: !tenantId || !enabled
  });

  const { data: debitData, refetch: refetchDebitRows } = useQuery(getRewardsFundDebitsQuery, { 
    variables: { 
      tenantId
    },
    skip: !tenantId || !enabled
  });

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        <>
          <PaymentFailedBanner 
            workspaceId={workspaceId}
            stripeCustomerId={stripeCustomerId}
            isStripePaymentFailed={isStripePaymentFailed}
          />
          <BalanceSection />
          <RewardsRequestList
            tenant={currentTenant} 
            workspaceId={currentWorkspaceId}
            isLoadingCurrentUser={isLoadingCurrentUser}
            refetchCurrentUser={refetchCurrentUser}
            refetchDebitRows={refetchDebitRows}
          />
          {enabled && (
            <Paper sx={{ mt: 3 }}>
              <Typography variant="h4">Reward Balance</Typography>
              <FundingHistory 
                creditRows={(creditData?.rewardsFundCredits || []).filter(({ status }) => status === 'approved')}
                debitRows={debitData?.rewardsFundDebits || []}
                tz={currentWorkspace?.timezone}
              />
            </Paper>
          )}
        </>
      }
    />
  );
};
