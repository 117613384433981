const initialGuideState = { enabled: false };

const CREATE_GUIDE = '@guides/create';
const DISMISS_GUIDE = '@guides/dismiss';

const guideReducer = (state = initialGuideState, action) => {
  switch (action.type) {
  case CREATE_GUIDE:
    return { name: action.name, enabled: true };
  default:
    return state;
  }
};

const guidesListReducer = (state = [], action) => {
  switch (action.type) {
  case CREATE_GUIDE: {
    const existingGuide = state.find(({ name }) => name === action.name);
    if (existingGuide) { return state; }
    return [...state, guideReducer(initialGuideState, action)];
  }
  case DISMISS_GUIDE:
    return [...state].filter((guide) => guide.name !== action.name);
  default:
    return state;
  }
};

export const createGuide = ({ name }) => ({
  type: CREATE_GUIDE,
  name,
});

export const dismissGuide = ({ name }) => ({
  type: DISMISS_GUIDE,
  name,
});

export default guidesListReducer;
