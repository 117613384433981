import React from 'react';
import { Box, Icon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function RemoveButton({ onClick, sx = {} }) {
  return (
    <Box 
      onClick={onClick}
      sx={{ 
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: '32px', 
        height: '32px', 
        backgroundColor: 'blacks.5', 
        borderRadius: '100%',
        ...sx
      }}>
      <Icon component={CloseIcon} sx={{ color: 'blacks.30', margin: '0 auto' }} />
    </Box>
  );
}