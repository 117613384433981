import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Panel from '../Panel';
import { Menu } from '../Menu';
import { MenuContainer } from '../Menu/Inline/styles';
import PeerAdvisorListItem from '../Peer/Advisor';

const transitionTime = 550;
export const RECURRING_LIST_HEADER_CLASSNAME = 'recurring-list-header';
export const RECURRING_LIST_ITEM_CLASSNAME = 'recurring-list-item';

export const BannerTextContainer = styled.div`
  width: 100%;
  ${theme.media.XS`
    width: 85%;
  `}
  ${theme.media.S`
    width: 435px;
  `}
  ${theme.media.M`
    width: 465px;
  `}
  ${theme.media.L`
    width: 355px;
  `}
`;

export const BannerTitle = styled.div`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.M};
  line-height: ${theme.spacing.double};
  margin-bottom: ${theme.spacing.single};
`;

export const BannerDescription = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.spacing.singleAndHalf};
`;


export const ListContainer = styled(Panel.Base)`
  overflow: hidden;
  ${theme.media.L`
    // We are allowing overflow here to show full tooltip, but we have to fix border radius of list items below
    overflow: visible;
  `}
`;

export const HeaderAddButton = styled.div`
  & svg {
    path {
      fill: ${theme.colors.blacks[40]};
    }
  }
  &:hover svg path {
    fill: ${theme.colors.purples[40]};
  }
  &:active svg path {
    fill: ${theme.colors.purples[60]};
  }
`;

export const RecurringHeader = styled(Panel.Header.Action)`
  padding: ${theme.spacing.singleAndHalf};
  .${RECURRING_LIST_HEADER_CLASSNAME} {
    color: ${theme.colors.blacks[60]};
  }  
`;

export const MembersBody = styled.div`
  height: ${({ hasAcceptedMembers }) => hasAcceptedMembers ? '208px' : '240px'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.blacks[30]};
  font-weight: ${theme.fontWeight.semiBold};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  border-top: ${({ hasAcceptedMembers }) => hasAcceptedMembers ? `1px solid ${theme.colors.blacks[10]}` : ''};
`;

export const MemberListItem = styled(PeerAdvisorListItem)`
  margin: 0;
  padding: ${theme.spacing.single} ${theme.spacing.singleAndHalf};
  width: auto;
  color: ${theme.colors.blacks[90]};
  // Because we allowed visible overflow above, we have to make sure the last list item still have a border radius
  &:last-child {
    border: none;
    border-radius: 0 0 ${theme.borderRadius.L} ${theme.borderRadius.L};
  }
  & ${Menu.Inline.Item.ItemWrapper} {
    color: ${theme.colors.blacks[60]};
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  line-height: ${theme.lineHeight.M};
  font-size: ${theme.fontSize.base};
  text-transform: capitalize;
`;

export const RecurringListContainer = styled.div`
  .${RECURRING_LIST_ITEM_CLASSNAME}-enter {
    opacity: 0;
    height: 0;
    margin-top: -1px; // this negative margin negates the border so there isn't a jump when item appears.
    padding-top: 0;
    padding-bottom: 0;
    background: ${theme.colors.yellows[1]};
    & ${MenuContainer} {
      background: transparent;
      box-shadow: none;
    }
  }
  .${RECURRING_LIST_ITEM_CLASSNAME}-enter-active {
    opacity: 1;
    height: 81.08px; // this is an exact height of each list item. Prevents jumps.
    margin: 0;
    padding-top: ${theme.spacing.single};
    padding-bottom: ${theme.spacing.single};
    background: ${theme.colors.yellows[1]};
    transition: all ${transitionTime}ms;
    & ${MenuContainer} {
      background: transparent;
      box-shadow: none;
    }
  }
  .${RECURRING_LIST_ITEM_CLASSNAME}-enter-done {
    background: white;
    transition: background 1.7s;
    & ${MenuContainer} {
      background: white;
      transition: background 1.7s, box-shadow 1.7s;
    }
  }
  .${RECURRING_LIST_ITEM_CLASSNAME}-exit {
    opacity: 1;
    height: 81.08px;
    overflow: hidden;
  }
  .${RECURRING_LIST_ITEM_CLASSNAME}-exit-active {
    opacity: 0;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    overflow: hidden;
    transition: all ${transitionTime}ms;
  }
`;

export const EditButton = styled.div`
  margin-left: 0 !important;
  color: ${theme.colors.blues[50]};
  ${theme.media.S`
    cursor: pointer;
    &:hover {
      color: ${theme.colors.purples[40]};
    }
    &:active {
      color: ${theme.colors.purples[60]};
      text-decoration: underline;
    }
  `}
`;
