import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3726 10.3604L15.2309 10.1039C14.5797 8.92515 13.3524 8.20013 11.9999 8.20013C11.0183 8.20013 10.0848 8.58821 9.39697 9.27131L10.4131 9.60368L7.42907 12.2748L6.59973 8.35654L7.85003 8.75801C8.84383 7.45073 10.3473 6.70001 11.9999 6.70001C13.9093 6.70001 15.6505 7.73077 16.5589 9.39629L16.7052 9.66446L15.3726 10.3604Z"
      fill={fill}
    />
    <path
      d="M12.0088 15.5942C12.9872 15.5942 13.9179 15.2087 14.6051 14.5297L13.5957 14.1996L16.581 11.5272L17.3988 15.4477L16.1512 15.0434C15.1574 16.3518 13.6533 17.1032 11.9999 17.1032C10.0906 17.1032 8.3485 16.0723 7.44891 14.3963L7.30542 14.1289L8.63621 13.4339L8.77792 13.6905C9.4291 14.8692 10.6564 15.5942 12.0088 15.5942Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.2002 12C2.2002 6.59428 6.59909 2.20001 12.0002 2.20001C17.4019 2.20001 21.7957 6.59475 21.8002 11.9993C21.8002 17.4048 17.4057 21.8 12.0002 21.8C6.59471 21.8 2.2002 17.4055 2.2002 12ZM12.0002 3.80001C7.48226 3.80001 3.8002 7.47842 3.8002 12C3.8002 16.5218 7.47836 20.2 12.0002 20.2C16.5218 20.2 20.1998 16.5222 20.2002 12.0007C20.1964 7.47796 16.5175 3.80001 12.0002 3.80001Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#6B6B6F',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;