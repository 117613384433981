import appReducer from './app-reducer';

export const RESET_ACTION = '@root/reset';

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const resetRedux = () => ({
  type: RESET_ACTION,
});

export default rootReducer;
