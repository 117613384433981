import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import desktop from './assets/desktop.png';
import mobile from './assets/mobile.png';
import theme, { media, spacing } from '@matterapp/matter-theme';
import { Clickable, IconNew } from '@matterapp/matter-ui';

const BannerTextContainer = styled.div`
  width: 100%;
`;

const BannerTitle = styled.div`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.M};
  line-height: ${spacing.double};
  margin-bottom: ${spacing.half};
  width: 90%;
  ${media.S`
    width: 100%;
  `}
`;

const BannerDescription = styled.div`
  font-size: ${theme.fontSize.M};
  line-height: ${spacing.double};
`;

const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${mobile});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  position: relative;
  color: white;
  ${media.S`
    background-image: url(${desktop});
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;;
    padding: ${spacing.singleAndHalf};
    height: 184px;
    border-radius: ${theme.borderRadius.L};
    margin-bottom: ${spacing.singleAndHalf};
  `}
`;

const CloseIconContainer = styled(Clickable)`
  cursor: pointer;
  position: absolute;
  top: ${spacing.singleAndHalf};
  right: ${spacing.single};
  z-index: 10;
  svg path {
    fill: white;
  }
  ${media.S`
    right: ${spacing.singleAndHalf};
  `}
`;

const CloseIcon = styled(IconNew).attrs({ name: 'close' })``;

const EmptyListBanner = ({ dismissBanner }) => {
  return (
    <Banner>
      <BannerTextContainer>
        <BannerTitle>
          Receive regular feedback from your team.
        </BannerTitle>
        <CloseIconContainer onClick={dismissBanner}>
          <CloseIcon />
        </CloseIconContainer>
        <BannerDescription>
          Set up continuous feedback to start scheduled feedback requests from the people you work with the most. 
        </BannerDescription>
      </BannerTextContainer>
    </Banner>
  );
};

EmptyListBanner.propTypes = {
  dismissBanner: PropTypes.func,
};

export default EmptyListBanner;