import React from 'react';
import PropTypes from 'prop-types';
import { QuickNote, ButtonNew as Button, Skill } from '@matterapp/matter-ui';
import { QuickNoteField } from '../../../../form-fields/quick-note';
import NextStepButton from 'components/molecules/NextStepButton';

class QuickNoteForm extends React.Component {
  renderSkillTagList = ({ skills }) => {
    const { taggedSkill } = this.props;
    return (
      <Skill.AddListUnit
        skills={skills.map((skill) => ({
          skill,
          isSelected: false,
          isTagged: !(!taggedSkill || skill.id !== taggedSkill.id),
        }))}
        renderSkillActions={this.renderSkillActions}
      />
    );
  };

  renderSkillActions = ({ skill }) => {
    const { handleTagSkill, taggedSkill } = this.props;
    const isRenderAction = !taggedSkill || skill.id !== taggedSkill.id;
    if (isRenderAction) {
      return (
        <Button
          onClick={() => {
            handleTagSkill(skill);
          }}
          size="xsmall"
          basic
        >
          Select
        </Button>
      );
    }

    return null;
  };

  renderSubmitButton = () => {
    const { handleSubmit, submitting, invalid, pristine } = this.props;
    return (
      <NextStepButton
        primary
        size="xsmall"
        onClick={handleSubmit}
        submitting={submitting}
        invalid={invalid}
        pristine={pristine}
      >
        Send
      </NextStepButton>
    );
  };

  renderTextarea = ({ receiver }) => (
    <QuickNoteField
      autoFocus
      placeholder={`What kind of feedback would you like to provide ${
        receiver.firstName
      }?`}
    />
  );

  render = () => {
    const {
      receiver,
      skills,
      taggedSkill,
      handleResetTaggedSkill,
      handleRequestDiscard,
      giver,
    } = this.props;
    return (
      <QuickNote
        submitFeedbackButton={this.renderSubmitButton()}
        skillTagList={this.renderSkillTagList({ skills })}
        textarea={this.renderTextarea({ receiver })}
        taggedSkill={taggedSkill}
        onResetTaggedSkill={handleResetTaggedSkill}
        onRequestClose={handleRequestDiscard}
        receiver={receiver}
        giver={giver}
      />
    );
  };
}

QuickNoteForm.defaultProps = {
  taggedSkill: null,
};

QuickNoteForm.propTypes = {
  receiver: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  giver: PropTypes.shape({
    photoUrl: PropTypes.string,
  }).isRequired,
  taggedSkill: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleResetTaggedSkill: PropTypes.func.isRequired,
  handleRequestDiscard: PropTypes.func.isRequired,
  handleTagSkill: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default QuickNoteForm;
