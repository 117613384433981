import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 16c0 7.18-5.82 13-13 13S3 23.18 3 16c0-1.19.16-2.343.46-3.438.253.213.471.599.51 1.288.007.12.017.235.03.342v.058c0 3.45 2.46 6.25 5.5 6.25 2.584 0 4.749-2.023 5.34-4.748l.005-.002.003-.033a7.01 7.01 0 0 0 .118-.772c.142-.646.438-1.445 1.034-1.445.596 0 .892.8 1.034 1.445.025.263.065.52.118.772l.003.033.005.002c.591 2.725 2.756 4.748 5.34 4.748 3.04 0 5.5-2.8 5.5-6.25v-.058c.013-.107.023-.221.03-.342.039-.69.257-1.075.51-1.288.3 1.095.46 2.248.46 3.438zm-1.07-5.173C25.93 6.221 21.341 3 16 3c-5.341 0-9.93 3.221-11.93 7.827A23.349 23.349 0 0 1 9.5 10.5c1.586.087 2.753.503 3.798.874.93.33 1.76.626 2.702.626.941 0 1.773-.296 2.702-.626 1.045-.371 2.213-.787 3.798-.874a23.35 23.35 0 0 1 5.43.327zM23.365 22.34a1 1 0 0 1-.089 1.411c-1.9 1.655-4.548 2.731-7.276 2.75-.545 0-1.12-.041-1.651-.122a1 1 0 0 1 .302-1.977c.45.07.874.1 1.349.099 2.342.019 4.323-.795 5.954-2.25a1 1 0 0 1 1.411.089z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
