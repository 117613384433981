import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import {
  ItemContainer,
  ItemContentWrapper,
  ItemWrapper,
  ItemIconWrapper,
  TOOLTIP_STYLES,
} from './styles';

export default class Item extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    /** If item is disabled. */
    disabled: PropTypes.bool,
    /** The icon to display for the item. */
    icon: PropTypes.node,
    /** Callback when item is clicked. */
    onClick: PropTypes.func,
    /** Content to show in tooltip of the item. */
    tooltipContent: PropTypes.node,
    /** 
     * Use responsive tooltip if list is in a container with overflow:hidden styles.
     * Not recommended for long lists with tooltips.
     */
    useResponsiveTooltip: PropTypes.bool,
  };

  static defaultProps = {
    onClick: () => {},
  };

  static ItemWrapper = ItemWrapper;

  handleClick = (e) => {
    const { disabled, onClick } = this.props;
    if (!disabled) {
      onClick(e, this.props);
    }
  };

  render() {
    const { className, children, disabled, icon, tooltipContent, useResponsiveTooltip } = this.props;
    const itemContent = (
      <>
        <ItemIconWrapper>{icon}</ItemIconWrapper>
        {children}
      </>
    );
    return (
      <ItemContainer className={className} disabled={disabled}>
        {tooltipContent && useResponsiveTooltip && (
          <Tooltip
            content={tooltipContent}
            onClick={this.handleClick}
            dark
            distanceFromTrigger={-4}
            style={TOOLTIP_STYLES}
          >
            <ItemWrapper>{itemContent}</ItemWrapper>
          </Tooltip>
        )}
        {tooltipContent && !useResponsiveTooltip && (
          <ItemWrapper onClick={this.handleClick}>
            <ItemContentWrapper>{itemContent}</ItemContentWrapper>
            <Tooltip.InlineHover ParentComponent={ItemWrapper}>
              {tooltipContent}
            </Tooltip.InlineHover>
          </ItemWrapper>
        )}
        {!tooltipContent && (
          <ItemWrapper>
            <ItemContentWrapper>{itemContent}</ItemContentWrapper>
          </ItemWrapper>
        )}
      </ItemContainer>
    );
  }
}
