import React from 'react';
import RightToLeft2 from '../Paths/RightToLeft2';
import BasePath from './BasePath';

const Path7 = React.forwardRef((props, ref) => (
  <BasePath
    PathComponent={RightToLeft2}
    pointProps={{ top: 94.7, left: 2.4 }}
    ref={ref}
    {...props}
  />
));

export default Path7;
