import React from 'react';
import gsap from 'gsap';
import BaseBackground from './BaseBackground';
import { BackgroundContainer, DarkPath, LightPath } from './styles';

export default class Background7 extends BaseBackground {
  playAnimation = (animationProps = {}) => {
    BaseBackground.killAnimation(this.tl);
    const resetProps = {
      x: 0,
      y: 0,
      opacity: 1,
      duration: 0.5,
    };

    this.tl = gsap.timeline({ defaults: { opacity: 0, duration: 0 } });
    this.tl.to(this.treeOneRef, { x: -24, y: -14 }, '<');
    this.tl.to(this.treeTwoRef, { x: -34, y: 6 }, '<');
    this.tl.to(this.treeThreeRef, { x: -5, y: 12 }, '<');
    this.tl.to(this.treeFourRef, { x: 12, y: -16 }, '<');
    this.tl.to(this.treeFiveRef, { x: 44, y: -8 }, '<');

    if (!animationProps.initialize) {
      this.tl.to(this.treeOneRef, resetProps, '<');
      this.tl.to(this.treeTwoRef, resetProps, '<');
      this.tl.to(this.treeThreeRef, resetProps, '<');
      this.tl.to(this.treeFourRef, resetProps, '<');
      this.tl.to(this.treeFiveRef, resetProps, '<');
    }
    return this.tl;
  };

  render() {
    return (
      <BackgroundContainer top={3}>
        <BaseBackground.SVG height={204}>
          <g ref={(ref) => (this.treeFiveRef = ref)}>
            <LightPath d="M287,115c0-8.84,7.163-16,16-16s16,7.16,16,16v32c0,8.84-7.163,16-16,16s-16-7.16-16-16V115z" />
            <DarkPath d="M305,138.271V186h-4v-36.296l-9.854-9.854l0.708-0.7l9.146,9.146V129h4v8.062l8.223-5.482l0.554,0.84L305,138.271z" />
          </g>
          <g ref={(ref) => (this.treeFourRef = ref)}>
            <LightPath d="M247,92c0-8.84,7.163-16,16-16s16,7.16,16,16v32c0,8.84-7.163,16-16,16s-16-7.16-16-16V92z" />
            <DarkPath d="M265,115.271V163h-4v-36.296l-9.854-9.854l0.708-0.7l9.146,9.146V106h4v8.062l8.223-5.482l0.554,0.84L265,115.271z" />
          </g>
          <g ref={(ref) => (this.treeThreeRef = ref)}>
            <LightPath d="M207,124c0-8.84,7.163-16,16-16s16,7.16,16,16v32c0,8.84-7.163,16-16,16s-16-7.16-16-16V124z" />
            <DarkPath d="M225,147.271V195h-4v-36.296l-9.854-9.854l0.708-0.7l9.146,9.146V138h4v8.062l8.223-5.482l0.554,0.84L225,147.271z" />
          </g>
          <g ref={(ref) => (this.treeTwoRef = ref)}>
            <LightPath d="M168,90c0-8.84,7.163-16,16-16s16,7.16,16,16v32c0,8.84-7.163,16-16,16s-16-7.16-16-16V90z" />
            <DarkPath d="M186,113.271V161h-4v-36.296l-9.854-9.854l0.708-0.7l9.146,9.146V104h4v8.062l8.223-5.482l0.554,0.84L186,113.271z" />
          </g>
          <g ref={(ref) => (this.treeOneRef = ref)}>
            <LightPath d="M208,26c0-8.84,7.163-16,16-16s16,7.16,16,16v32c0,8.84-7.163,16-16,16s-16-7.16-16-16V26z" />
            <DarkPath d="M226,49.271V97h-4V60.704l-9.854-9.854l0.708-0.7L222,59.296V40h4v8.062l8.223-5.482l0.554,0.84L226,49.271z" />
          </g>
        </BaseBackground.SVG>
      </BackgroundContainer>
    );
  }
}
