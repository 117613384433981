import Basic from './variants/Basic';
import Action from './variants/Action';
import Progress from './variants/Progress';
import DynamicAction from './variants/DynamicAction';
import WithAvatar from './variants/WithAvatar/index';
import { SIZE } from './size';

export default {
  Basic,
  Action,
  Progress,
  DynamicAction,
  WithAvatar,
  SIZE,
};
