import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import Dropdown from '../Dropdown';
import { MenuBottomContent, DropdownCount, MenuBottomContentContainer, NoResults } from './styles';

export const NOOP = () => null;
export const DEAFULT_MENU_MIN_WIDTH = 220;

export default class Multiple extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasSelectedItem: false,
      isOpen: false,
    };
    this.dropdownRef = React.createRef();
    this.selectedTimeout = null;
  }

  static defaultProps = {
    items: [],
    menuProps: {},
    onChange: NOOP,
    onClickApply: NOOP,
    onClickClear: NOOP,
    onMenuClose: NOOP,
    onSelect: NOOP,
    showApplyClearButtons: true,
    showSelectionCount: true,
    toggleSingleSelect: true,
    isSelected: true,
    value: [],
  };

  static propTypes = {
    /** Value to always show in dropdown. */
    displayValue: PropTypes.string,
    /** Aditional menu props to customize menu styling.s */
    menuProps: PropTypes.shape({
      position: PropTypes.oneOf(['left', 'center', 'right']),
      showBelowDropdown: PropTypes.bool,
      width: PropTypes.number,
    }),
    /** Callback when value or dropdown changes. */
    onChange: PropTypes.func,
    /** Callback when apply button is clicked. */
    onClickApply: PropTypes.func,
    /** Callback when clear button is clicked. */
    onClickClear: PropTypes.func,
    /** Callback when dropdown menu closes. */
    onMenuClose: PropTypes.func,
    /** Callback when item is selected. */
    onSelect: PropTypes.func,
    /** Displays the apply and clear buttons at bottom of menu. */
    showApplyClearButtons: PropTypes.bool,
    /** Shows the number of items selected. */
    showSelectionCount: PropTypes.bool,
    /** FORCES single selection to behave as default dropdown with multiselect styling. */
    singleSelect: PropTypes.bool,
    /** Toggle selected value on single select. */
    toggleSingleSelect: PropTypes.bool,
    /** List of selected values. */
    value: PropTypes.arrayOf(PropTypes.string),
  };

  focusDropdown = () => {
    this.dropdownRef.current.focus();
    this.setState({ hasSelectedItem: true });
  };

  applySelectedValues = (e) => {
    this.setState({ hasSelectedItem: false });
    this.props.onClickApply(e, this.props);
    this.closeMenu(e);
  };

  clearHasSelectedItem = () => {
    this.setState({ hasSelectedItem: false });
  };

  clearSelectedValues = (e) => {
    this.focusDropdown();
    this.props.onChange(e, { ...this.props, value: [] });
  };

  closeMenu = (e) => {
    this.props.onMenuClose(e, this.props);
    this.setState({ isOpen: false });
  };

  openMenu = () => {
    this.setState({ isOpen: true });
  };

  getUpdatedChangeProps = (changeProps) => {
    const { value: selectedValue } = changeProps;
    const { singleSelect, toggleSingleSelect, value } = this.props;

    // If mouse click outside of menu or on clear/apply buttons.
    // Return same value props.
    if (!selectedValue) {
      return { ...changeProps, value };
    }

    // On single select mode, toggle selected value.
    if (singleSelect) {
      if (toggleSingleSelect) {
        return {
          ...changeProps,
          value: value.includes(selectedValue) ? [] : [selectedValue],
        };
      }
      return {
        ...changeProps,
        value: [selectedValue],
      };
    }

    // If selected value is in value prop, deselect item value.
    if (value.includes(selectedValue)) {
      return {
        ...changeProps,
        value: value.filter((val) => val !== selectedValue && value),
      };
    }

    // Else add to selected value prop.
    return { ...changeProps, value: [...value, selectedValue] };
  };

  getCustomRenderItemProps = (item) => {
    const { singleSelect, value } = this.props;
    const isSelected = value.includes(item.value);
    if (singleSelect) {
      return {
        isSelected,
      };
    }
    return {
      onMouseDown: () => {
        this.focusDropdown();
      },
      isSelected,
      useCheckbox: true,
    };
  };

  getMenuProps = () => {
    return {
      position: 'left',
      showBelowDropdown: true,
      ...this.props.menuProps,
    };
  };

  getDropdownArrow = () => {
    const { showSelectionCount, size, value } = this.props;
    const numSelectedValues = value.length;
    if (!!numSelectedValues && showSelectionCount) {
      return <DropdownCount size={size}>{numSelectedValues}</DropdownCount>;
    }
    return null;
  };

  handleMenuVisibilityChange = (isOpen) => {
    if (isOpen) {
      if (!this.state.isOpen) {
        this.clearHasSelectedItem();
      }
      this.openMenu();
    } else if (this.props.singleSelect || !this.state.hasSelectedItem) {
      this.closeMenu({});
    } else {
      this.clearHasSelectedItem();
    }
  };

  handleBlur = (e) => {
    if (!this.props.singleSelect) {
      if (!this.state.hasSelectedItem) {
        this.closeMenu(e);
      } else {
        this.focusDropdown();
      }
    }
  };

  handleChange = (e, props) => {
    this.props.onChange(e, this.getUpdatedChangeProps(props));
  };

  handleSelect = (e, props) => {
    const { onSelect, singleSelect } = this.props;
    onSelect(e, this.getUpdatedChangeProps(props));
    if (!singleSelect) {
      this.focusDropdown();
      this.selectedTimeout = setTimeout(this.clearHasSelectedItem, 140);
    }
  };

  getItems = () => {
    const { items, showApplyClearButtons } = this.props;
    if (showApplyClearButtons) {
      return [
        ...items,
        {
          value: '',
          label: '',
          actionItemComponent: MenuBottomContentContainer,
          actionItemContent: (
            <>
              {items.length == 0 && <NoResults> No Results</NoResults> }
              <MenuBottomContent>
                <Button.Link
                  content={'Clear'}
                  size={Button.buttonSizes.S}
                  onMouseDown={this.focusDropdown}
                  onClick={this.clearSelectedValues}
                />
                <Button.Primary
                  content={'Apply'}
                  size={Button.buttonSizes.S}
                  onClick={this.applySelectedValues}
                  disabled={!items.length}
                />
              </MenuBottomContent>
            </>
          ),
        },
      ];
    }
    return items;
  };

  render() {
    const { displayValue } = this.props;
    return (
      <Dropdown
        {...this.props}
        color={Dropdown.colors.ALT}
        dropdownArrowComponent={this.getDropdownArrow()}
        ref={this.dropdownRef}
        isOpen={this.state.isOpen}
        items={this.getItems()}
        getCustomRenderItemProps={this.getCustomRenderItemProps}
        menuProps={this.getMenuProps()}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onMenuVisibilityChange={this.handleMenuVisibilityChange}
        onSelect={this.handleSelect}
        value={displayValue}
      />
    );
  }
}
