import React from 'react';
import { Form, Skill } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import { Field as ReduxFormField } from 'redux-form';

const SkillRatingField = class extends React.Component {
  renderField = (reduxFormFieldProps) => (
    <Form.Field>
      <Skill.Rater
        skill={reduxFormFieldProps.skill}
        initialRating={
          typeof this.props.currentRating === 'number'
            ? this.props.currentRating
            : this.props.priorRating
        }
        onChange={reduxFormFieldProps.input.onChange}
      />
      {!reduxFormFieldProps.meta.submitFailed ? null : (
        <Form.Error errorString={reduxFormFieldProps.meta.error} />
      )}
    </Form.Field>
  );

  render = () => (
    <ReduxFormField
      name="skillRating"
      type="range"
      component={this.renderField}
      skill={this.props.skill}
    />
  );
};

SkillRatingField.defaultProps = {
  priorRating: null,
  currentRating: null,
};

SkillRatingField.propTypes = {
  skill: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  priorRating: PropTypes.number,
  currentRating: PropTypes.number,
};

export default SkillRatingField;
