import React from 'react';
import PropTypes from 'prop-types';
import { Clear, Heading, Message, MessageContainer } from './sharedStyle';

const MessageBox = (props) => {
  const {
    message,
    heading,
    onChange,
    clearText,
    messageMinLength,
    messageMaxLength,
    ...otherProps
  } = props;
  return (
    <MessageContainer>
      <Heading>
        <h3>{heading}</h3>
        <Clear onClick={clearText}>Clear text</Clear>
      </Heading>
      <Message
        {...otherProps}
        onChange={onChange}
        minLength={messageMinLength}
        maxLength={messageMaxLength}
        value={message}
      />
    </MessageContainer>
  );
};

MessageBox.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  clearText: PropTypes.func.isRequired,
};

export default MessageBox;
