import FeedItem from './FeedItem';
import FeedItemsList from './FeedItemsList';
import ActionBar from './ActionBar';
import AvatarHeader from './AvatarHeader';
import * as SkillRatings from './SkillRatings';
import QualityEndorsements from './QualityEndorsements';
import FeedItemLayout from './FeedItemLayout';
import MoreDetails from './MoreDetails';
import CommentCount from './CommentCount';
import * as propTypes from './prop-types';
import Personal from './Personal';
import Workspaces from './Workspaces';
import Footer from './FeedItem';

export default {
  FeedItem,
  FeedItemsList,
  FeedItemLayout,
  ActionBar,
  AvatarHeader,
  SkillRatings,
  QualityEndorsements,
  MoreDetails,
  CommentCount,
  propTypes,
  Footer,
  Personal,
  Workspaces,
};
