import validator from 'validator';
import {
  ERROR_PROFILE_INVALID_EMAIL,
  ERROR_CANT_LEAVE_BLANK,
  ERROR_TOO_LONG,
} from '../../copy-consts';

export const validateEmail = (email) => {
  if (!email) { return ERROR_CANT_LEAVE_BLANK; }
  if (!validator.isEmail(email)) { return ERROR_PROFILE_INVALID_EMAIL; }
  if (email.length >= 255) { return ERROR_TOO_LONG; }
  return null;
};

export const isValidEmail = (email) => {
  return !validateEmail(email);
};

export const getEmailValidation = (fieldName) => (values) => {
  const errors = {
    [fieldName]: validateEmail(values[fieldName]),
  };
  return errors;
};
