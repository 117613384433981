import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7071 3.29289C13.0976 3.68342 13.0976 4.31658 12.7071 4.70711L6.41421 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H6.41421L12.7071 19.2929C13.0976 19.6834 13.0976 20.3166 12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929L11.2929 3.29289C11.6834 2.90237 12.3166 2.90237 12.7071 3.29289Z"
      fill={fill}
    />
  </svg>);

Icon.defaultProps = {
  fill: '#4584FF',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
