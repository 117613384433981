import React from 'react';
import V3 from './layouts/v3';
import TwoColumnFixedLeft from './layouts/v3/TwoColumnFixedLeft';

const Layouts = {
  Main: {
    default: V3.Main,
  },
  Profile: {
    default: V3.Profile,
  },
  TwoColumnFixedLeft: {
    default: TwoColumnFixedLeft
  },
  LeftPanelOnly: {
    default: V3.LeftPanelOnly,
  }
};

const Page = (props) => {
  const { layout } = props;
  const layoutObject = Layouts[layout];
  const LayoutInstance = layoutObject.default;

  if (!LayoutInstance) {
    console.log('Cannot find layout', layout);
  }

  return <LayoutInstance {...props} />;
};

Page.Layouts = Object.keys(Layouts).reduce((acc, cur) => {
  return { ...acc, [cur]: cur };
}, {});

export default Page;
