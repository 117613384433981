import { normalizedSkillsMap } from './skills-map';
import { normalizeSkillName } from './normalize-skill-name';
import { getPalette, defaultPalette } from './skill-color-palettes';
import { genericSVGs } from './generic-svgs-map';

const getSkillDetails = (skillName) => {
  const normalizedSkillName = normalizeSkillName(skillName);
  const skillDetails = normalizedSkillsMap[normalizedSkillName];
  if (!skillDetails) { return normalizedSkillsMap.generic; }
  return skillDetails;
};

export const getSkillColorPalette = (skillName) => {
  const normalizedSkillName = normalizeSkillName(skillName);
  const skillDetails = normalizedSkillsMap[normalizedSkillName] || normalizedSkillsMap.generic;
  return skillDetails.colorPalette;
};

export const getSkillColorPaletteFromSkill = (skill = {}) => {
  return skill.colorPalette || getSkillColorPalette(skill.name);
};

export const getSkillPrimaryColor = (skill) => {
  const { colorPalette, isCustom } = skill;
  if (colorPalette) { 
    return getPalette(colorPalette).primary; 
  }
  if (isCustom) { return defaultPalette.primary; }
  const skillDetails = getSkillDetails(skill.name);
  return skillDetails.primary;
};

export const getSkillBackgroundColor = (skill) => {
  const { colorPalette, isCustom } = skill;
  let palette;
  if (colorPalette) { 
    palette = getPalette(colorPalette);
  } else if (isCustom) {
    palette = defaultPalette;
  } else {
    palette = getSkillDetails(skill.name);
  }
  return palette.background;
};

export const getSkillBorderColor = (skill) => {
  const { colorPalette, isCustom } = skill;
  if (colorPalette) { return getPalette(colorPalette).borderColor; }
  if (isCustom) { return defaultPalette.borderColor; }
  const skillDetails = getSkillDetails(skill.name);
  return skillDetails.borderColor;
};

export const getSkillIcon = (skill) => {
  const skillDetails = getSkillDetails(skill.name);
  if (!skill.isCustom && skillDetails && skillDetails.svg) {
    return skillDetails.svg;
  }

  if (genericSVGs[skill.colorPalette]) {
    return genericSVGs[skill.colorPalette];
  }

  return genericSVGs.Blue;
};

export const SKILL_FONT_COLOR = '#FFFFFF';
