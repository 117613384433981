import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const ActionBox = styled.div`
  button {
    margin-left: 8px;
    vertical-align: top;

    &:first-child {
      margin-left: 0;
    }
  }

  > svg {
    display: block;
  }

  ${() => theme.media.medium`
    button {
      margin-left: 24px;
    }
  `}
`;

export default ActionBox;
