import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4802 4.69977H13.1774V6.29977H18.4802C19.1429 6.29977 19.6802 6.83702 19.6802 7.49976V17.4998C19.6802 18.1625 19.1429 18.6998 18.4802 18.6998H5.48018C4.81743 18.6998 4.28018 18.1625 4.28018 17.4998V8.06343H2.68018V17.4998C2.68018 19.0462 3.93378 20.2998 5.48018 20.2998H18.4802C20.0266 20.2998 21.2802 19.0462 21.2802 17.4998V7.49976C21.2802 5.95337 20.0266 4.69977 18.4802 4.69977Z"
      fill={fill}
    />
    <path
      d="M17.9202 8.72976H11.0802V10.3298H17.9202V8.72976Z"
      fill={fill}
    />
    <path
      d="M17.9402 11.7298H6.08015V13.3298H17.9402V11.7298Z"
      fill={fill}
    />
    <path
      d="M6.08015 14.7098H17.8502V16.3098H6.08015V14.7098Z"
      fill={fill}
    />
    <path
      d="M8.06579 1.49408C7.75337 1.18166 7.24684 1.18166 6.93442 1.49408L3.66442 4.76408L4.79579 5.89545L6.70012 3.99112V9.91977H8.30012V3.99116L10.2044 5.89545L11.3358 4.76408L8.06579 1.49408Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#6B6B6F',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;