import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const BACKGROUND_WIDTH = 560;

export const Container = styled.div`
  position: relative;
  width: 100%;

  & > svg {
    width: 100%;
    height: auto;
  }

  ${theme.media.S`
    width: 100%;

    & > svg {
      width: 100%;
      height: 100%;
    }
  `}
`;

export const Point = styled.div`
  background-color: ${(props) => theme.colors.purples[props.enabled ? 50 : 10]};
  border: 4px solid ${theme.colors.white};
  border-radius: 100%;
  height: 14vw;
  width: 14vw;
  max-height: 88px;
  max-width: 88px;
  min-width: 64px;
  min-height: 64px;
  opacity: ${(props) => props.pathIsShown ? 1 : 0};
  position: absolute;
  transform: translate(-50%, -50%);
  transition: background-color ${theme.transitions.times.default};
  z-index: 10;
`;

export const DarkPath = styled.path`
  fill: ${theme.colors.purples[10]};
`;

export const LightPath = styled.path`
  fill: ${theme.colors.purples[5]};
`;

export const WhitePath = styled.path`
  fill: ${theme.colors.white};
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: ${props => props.top}%;
  left: 50%;
  transform: translateX(-50%);
  width: 121%;

  & svg {
    width: 100%;
    height: auto;
  }
`;

export default {
  Container,
  Point,
  DarkPath,
  LightPath,
  WhitePath
};
