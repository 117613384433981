import moment from 'moment-timezone';

export function formatTimezone(timezone) {
  return moment.tz(new Date(), timezone).format('z');
}

export const DEFAULT_ALLOWED_COINS = 200;

export function isCelebrationCoinsAmountValid(value) {
  const parsed = parseInt(value, 10);
  
  if (!parsed) {
    return true;
  }

  if (parsed < 0 || parsed > 100000) {
    return false;
  }

  return true;
}

export function getInitialCoinsAmount(value) {
  let coins = parseInt(value);

  if (!coins && coins !== 0) {
    coins = DEFAULT_ALLOWED_COINS;
  }

  return coins;
}

export function getCoinsHelperText(coins) {
  const parsed = parseInt(coins);
  let usd = 0;
  let totalCoins = 0;

  if (parsed > 0) {
    totalCoins = parsed.toLocaleString('en-US');
    usd = (parsed / 10).toFixed(2);
    const [dollars, cents] = usd.split('.');

    if (parseInt(cents)) {
      usd = `${parseInt(dollars).toLocaleString('en-US')}.${cents}`;
    } else {
      usd = parseInt(dollars).toLocaleString('en-US');
    }
  }

  return `${totalCoins} Matter Coins = ${usd} USD`;
}
