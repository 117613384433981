import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import HelperMeter from '../../HelperMeter';

const initialSteps = 3;
const completeSteps = 6;
const COMPLETION_RESET_DELAY = 3000;
const resetText = 'Let’s keep it up! Ask 3 more people!';
const finalText = 'Your requests are on the way!';

function stepText(inviteCount, maxSteps) {
  const text = [
    'First, ask 3 people you work with!',
    'Nice, now 2 more people to go!',
    'How about 1 more person?',
    'Great, that’s 3. You’re on a streak!',
    'That’s 4! Can you think of 2 more?',
    '👋 Hi five! Try adding 1 more person.',
  ];
  if (maxSteps == completeSteps && inviteCount >= maxSteps) {
    return finalText;
  } else if (completeSteps == maxSteps && inviteCount == initialSteps) {
    return resetText;
  } else if (inviteCount <= text.length) {
    return text[inviteCount];
  } else {
    return `🎉 Way to grow! That’s ${inviteCount} requests.`;
  }
}

function countText(inviteCount, maxSteps) {
  if (maxSteps == completeSteps && inviteCount >= maxSteps) {
    return 'Done!';
  } else {
    return `${inviteCount}/${maxSteps}`;
  }
}

const WelcomeHelperMeter = (props) => {
  const { currentStep } = props;
  const [totalSteps, setTotalSteps] = useState();
  const [helperText, setHelperText] = useState();
  const [counterText, setCounterText] = useState();
  const stepResetTimeout = useRef(false);
  const prevStep = useRef();

  useEffect(() => {
    if (prevStep.current === 'undefined') { return; }
    setHelperText(stepText(currentStep, totalSteps));
    setCounterText(countText(currentStep, totalSteps));
    if (currentStep == initialSteps) {
      stepResetTimeout.current = setTimeout(() => {
        setTotalSteps(completeSteps);
        setCounterText(countText(currentStep, completeSteps));
        setHelperText(resetText);
      }, COMPLETION_RESET_DELAY);
    }

    if (currentStep === completeSteps) {
      stepResetTimeout.current = setTimeout(() => {
        setHelperText(finalText);
        setCounterText(countText(currentStep, completeSteps));
      }, COMPLETION_RESET_DELAY);
    }
    prevStep.current = currentStep;
  }, [currentStep]);

  useEffect(() => {
    const initSteps = currentStep >= initialSteps ? completeSteps : initialSteps;
    setTotalSteps(initSteps);
    setHelperText(stepText(currentStep, initSteps));
    setCounterText(countText(currentStep, initSteps));
    prevStep.current = currentStep;

    return () => {
      clearTimeout(stepResetTimeout.current);
    };
  }, []);

  if (!totalSteps) { return null; }
  return (
    <HelperMeter variant="welcome"
      {...props}
      helperText={helperText}
      totalSteps={totalSteps}
      counterText={counterText}
    />
  );
};

WelcomeHelperMeter.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

WelcomeHelperMeter.defaultProps = {
  currentStep: 0,
};

export default WelcomeHelperMeter;