import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Dotdotdot from 'react-dotdotdot';
import getSymbolFromCurrency from 'currency-symbol-map';
import { colors, spacing, media } from '@matterapp/matter-theme';
import { Avatar, IconNew as Icon, Clickable, Tooltip } from '@matterapp/matter-ui';
import { NameContainer, AvatarContainer, NameTextContainer} from './styles';
import { RIGHT_DIV } from './consts';
import { StyledTableCell } from 'components/TableStyles';
import { TableRow } from '@mui/material';

const RewardName = styled(Dotdotdot)`
  -webkit-line-clamp: 2 !important;
  ${media.M`
    -webkit-line-clamp: 1 !important;
    display: block !important;
  `}
`;

const CheckmarkIcon = styled(Icon).attrs({ 
  name: 'checkmarkSmall' 
})`
  height: ${spacing.singleAndQuarter} !important;
  width: ${spacing.singleAndQuarter}  !important;
`;

const CloseIcon = styled(Icon).attrs({
  name: 'closeSmall',
})``;

const IconContainer = styled(Clickable)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${spacing.double};
  width: ${spacing.double};
  border-radius: ${spacing.quarter};
  svg {
    height: 10px;
    width: 10px;
  }
  ${({ isDisabled }) => isDisabled ? 
    `
      border: 1px solid ${colors.blacks[20]};
      cursor: default !important;
      filter: grayscale(50%);
      svg {
        path {
          fill: ${colors.blacks[30]}} !important;
        }
      }
    ` : `
      border: 1px solid ${colors.blacks[30]};
    `}
`;

const Spacer16 = styled.div`
  width: ${spacing.single};
`;

const ApproveContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;


const Status = styled.div`
  text-transform: capitalize;
`;

const MenuButton = styled(Clickable)`
  color: ${colors.blacks[40]};
  z-index: 10;
  display: flex;
  width: ${spacing.quadruple};
  align-items: center;
  justify-content: center;
`;

const MenuIcon = styled(Icon).attrs(() => ({ name: 'more' }))`
  min-width: ${spacing.singleAndHalf};
  overflow: visible;
  pointer-events: none;
  & path {
    fill: currentColor;
  }
`;

export default function RewardRequestRow({
  isLoading,
  isPendingTab,
  tenant,
  rewardRequest,
  onClickApprove,
  onClickDecline,
  onClickMore
}) {
  const { date: rewardDate, name: rewardName, faceValue, rewardItem, status, requesterUser } = rewardRequest;

  const transformedDate = moment(Number(rewardDate)).format('L');  
  const { firstName, lastName, photoUrl } = requesterUser;
  const currencySymbol = rewardItem ? getSymbolFromCurrency(rewardItem.currencyCode) : null;

  let approveTooltipLabel, lastMobileColumn, mobileTooltipLabel;
  let declinedTooltipLabel = 'Decline'; 
  let lastDesktopColumn = null;
  let approveMargin = 0;
  let declineMargin = 0;

  if (isPendingTab) {
    const { rewardsSettings, tier } = tenant || {};
    const isMatterPro = tier === 'pro' || tier === 'enterprise';
    const isRewardsActiveAndPro = rewardsSettings.isRewardsActive && isMatterPro;
    const canApproveAndDecline = isRewardsActiveAndPro;
    const canDecline = isRewardsActiveAndPro;

    if (isRewardsActiveAndPro) {
      approveTooltipLabel = 'Approve';
    } else if (!isMatterPro) {
      declinedTooltipLabel = 'Upgrade to Matter Pro to decline this reward';
      approveTooltipLabel = 'Upgrade to Matter Pro to approve this reward';
      mobileTooltipLabel = 'Upgrade to Matter Pro to approve and decline this reward';
      approveMargin = '130px';
      declineMargin = '30px';
    } else {
      declinedTooltipLabel = 'Enable Rewards to decline this reward';
      approveTooltipLabel = 'Enable Rewards to approve this reward';
      mobileTooltipLabel = 'Enable Rewards to approve and decline this reward';
      approveMargin = '130px';
      declineMargin = '30px';
    }

    lastDesktopColumn = (
      <ApproveContainer>
        <Tooltip
          content={declinedTooltipLabel}
          style={{ textAlign: 'center', marginRight: declineMargin }}
        >
          <IconContainer
            isDisabled={!canDecline || isLoading}
            onClick={canDecline ? () => onClickDecline(rewardRequest) : () => null }
          >
            <CloseIcon fill={colors.reds[50]}/>
          </IconContainer>
        </Tooltip>
        <Spacer16 />
        <Tooltip
          content={approveTooltipLabel}
          style={{ textAlign: 'center', marginRight: approveMargin }}
        >
          <IconContainer 
            isDisabled={!canApproveAndDecline || isLoading}
            onClick={canApproveAndDecline ? () => onClickApprove(rewardRequest) : () => null}
          >
            <CheckmarkIcon fill={colors.greens[50]}/>
          </IconContainer>
        </Tooltip>
      </ApproveContainer>
    );

    lastMobileColumn = (
      canApproveAndDecline ? 
        <MenuButton className={RIGHT_DIV} onClick={() => onClickMore(rewardRequest)}>
          <MenuIcon fill={colors.blacks[40]}/>
        </MenuButton> :
        <Tooltip
          content={mobileTooltipLabel}
        >
          <MenuButton className={RIGHT_DIV} onClick={() => null}>
            <MenuIcon fill={colors.blacks[40]}/>
          </MenuButton>
        </Tooltip>
    );
  } else {
    lastDesktopColumn = <Status className={RIGHT_DIV}>{status === 'cancelled' ? 'refunded' : status}</Status>;
  }

  return (
    <TableRow>
      <StyledTableCell size='large' sx={{ width: '20%' }}>
        <NameContainer>
          <AvatarContainer><Avatar size={48} url={photoUrl} marginBottom={1} /></AvatarContainer>
          <NameTextContainer>{firstName} {lastName}</NameTextContainer>
        </NameContainer>
      </StyledTableCell>
      <StyledTableCell hideOnMobile sx={{ width: '15%' }}>{transformedDate}</StyledTableCell>
      <StyledTableCell sx={{ width: '30%' }}>
        <RewardName clamp={2}>{rewardName}</RewardName>
      </StyledTableCell>
      <StyledTableCell sx={{ width: '10%' }}>
        {faceValue ? `${currencySymbol}${faceValue} ${rewardItem.currencyCode}` : '-'}
      </StyledTableCell>
      <StyledTableCell hideOnMobile sx={{ width: '10%' }}>
        {lastDesktopColumn} 
      </StyledTableCell>
      <StyledTableCell hideOnDesktop sx={{ pl: 0, pr: 0 }}>
        {lastMobileColumn}
      </StyledTableCell>
    </TableRow>
  );
};
