import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonNew as Button,
  ModalNew as Modal,
  Confirm,
} from '@matterapp/matter-ui';
import { QuickNoteForm } from '../modules';
import { QUICK_NOTE_INNER_QUERY } from 'graphql-queries';
import { Query } from '@apollo/client/react/components';

class QuickNoteFeedbackProvider extends React.Component {
  renderConfirmSubmissionFailed = ({ receiver }) => {
    const {
      isSubmissionFailedActive,
      handleDeactivateSubmissionFailed,
    } = this.props;
    return (
      <Confirm.Old
        isOpen={isSubmissionFailedActive}
        buttonLeft={null}
        buttonRight={
          <Button basic size="small" onClick={handleDeactivateSubmissionFailed}>
            Close
          </Button>
        }
        heading="Failed to send"
        subheading={`We were unable to send your note to ${
          receiver.firstName
        }. Please try again.`}
        onCancel={handleDeactivateSubmissionFailed}
      />
    );
  };

  renderConfirmDiscard = () => {
    const {
      isDiscardConfirmActive,
      handleDeactivateDiscardConfirm,
      handleConfirmDiscard,
    } = this.props;
    return (
      <Confirm.Old
        isOpen={isDiscardConfirmActive}
        buttonLeft={
          <Button primary size="small" onClick={handleConfirmDiscard}>
            Yes, discard
          </Button>
        }
        buttonRight={
          <Button basic size="small" onClick={handleDeactivateDiscardConfirm}>
            Cancel
          </Button>
        }
        heading="Discard feedback?"
        subheading="You can't undo this and you'll lose your draft."
        onCancel={handleDeactivateDiscardConfirm}
      />
    );
  };

  renderQuickNoteModal = ({
    receiver,
    skills,
    giver,
  }) => {
    const {
      isQuickNoteFormActive,
      handleActivateSubmissionFailed,
      handleRequestDiscard,
      handleResetTaggedSkill,
      handleResetAll,
      taggedSkill,
      handleTagSkill,
    } = this.props;
    return (
      <React.Fragment>
        <Modal
          wide
          transparent
          flushOnMobile
          isOpen={isQuickNoteFormActive}
          onRequestClose={handleRequestDiscard}
          contentLabel="quick-note"
        >
          <QuickNoteForm
            receiver={receiver}
            giver={giver}
            taggedSkill={taggedSkill}
            skills={skills}
            handleResetTaggedSkill={handleResetTaggedSkill}
            handleActivateSubmissionFailed={handleActivateSubmissionFailed}
            handleRequestDiscard={handleRequestDiscard}
            handleTagSkill={handleTagSkill}
            handleResetAll={handleResetAll}
          />
        </Modal>
        {this.renderConfirmDiscard()}
        {this.renderConfirmSubmissionFailed({ receiver })}
      </React.Fragment>
    );
  };

  getSkillsList = (userSkills, recommendedSkills) => {
    const seen = new Set();
    const result = [];
    const add = (skill) => {
      if (!seen.has(skill.id)) {
        result.push(skill);
        seen.add(skill.id);
      }
    };
    userSkills.forEach(add);
    recommendedSkills.forEach(add);
    return result;
  }

  render = () => {
    const { children, handleSetCurrentPeer, currentPeer, currentUserStatus } = this.props;
    if (!currentPeer) {
      return children({
        isEmpty: true,
        isLoading: false,
        handleSetCurrentPeer,
      });
    }
    return (
      <Query query={QUICK_NOTE_INNER_QUERY} variables={{ id: currentPeer.userId }}>
        {({ data, loading }) => {
          return children({
            isEmpty: false,
            isLoading: loading,
            handleSetCurrentPeer,
            form: this.renderQuickNoteModal({
              isLoading: loading,
              receiver: {
                ...currentPeer,
                firstName: currentPeer.firstName,
                id: currentPeer.userId,
              },
              skills: loading ? [] : this.getSkillsList(data.user.skills, data.recommendedSkills),
              giver: currentUserStatus,
            }),
          });
        }}
      </Query>
    );
  };
}

QuickNoteFeedbackProvider.defaultProps = {
  currentPeer: null,
  taggedSkill: null,
};

QuickNoteFeedbackProvider.propTypes = {
  currentPeer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
  }),
  taggedSkill: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  currentUserStatus: PropTypes.shape({
    photoUrl: PropTypes.string,
  }).isRequired,
  children: PropTypes.func.isRequired,
  isDiscardConfirmActive: PropTypes.bool.isRequired,
  handleSetCurrentPeer: PropTypes.func.isRequired,
  handleResetTaggedSkill: PropTypes.func.isRequired,
  handleResetAll: PropTypes.func.isRequired,
  handleTagSkill: PropTypes.func.isRequired,
  handleRequestDiscard: PropTypes.func.isRequired,
  handleDeactivateDiscardConfirm: PropTypes.func.isRequired,
  handleDeactivateSubmissionFailed: PropTypes.func.isRequired,
  handleActivateSubmissionFailed: PropTypes.func.isRequired,
  handleConfirmDiscard: PropTypes.func.isRequired,
  isQuickNoteFormActive: PropTypes.bool.isRequired,
  isSubmissionFailedActive: PropTypes.bool.isRequired,
};

export default QuickNoteFeedbackProvider;
