const SET_NUM_INVITED = '@num-invited/set';

const reducer = (state = 0, action) => {
  switch (action.type) {
  case SET_NUM_INVITED: {
    return action.numInvited;
  }
  default:
    return state;
  }
};

export const setNumInvited = (numInvited) => ({
  type: SET_NUM_INVITED,
  numInvited,
});

export default reducer;
