import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconNew as Icon, PopoverMenu, getSkillIcon } from '@matterapp/matter-ui';
import theme, { colors, media, spacing } from '@matterapp/matter-theme';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & .popover-menu__menu {
    margin-top: ${spacing.quarter};
    margin-left: ${spacing.singleAndHalf};
    width: calc(100vw - ${spacing.quintuple});
    & > div:first-child {
      transform: translateX(-${spacing.singleAndHalf});
    }
  }

  ${media.S`
    & .popover-menu__menu {
      margin-left: calc(-${spacing.double} - ${spacing.quarter});
      width: 300px;
      & > div:first-child {
        transform: translateX(0);
      }
    }
  `}
`;

const SkillBadge = styled.img`
  height: 72px;
  width: 72px;
`;

const SkillName = styled.div`
  font-weight: ${theme.fontWeight.medium};
  font-size: 20px;
  margin-top: ${spacing.threeQuarters};
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: ${spacing.single};
  right: ${spacing.single};
  cursor: pointer;
  & svg path {
    fill: ${colors.blacks[30]};
  }
`;

const InfoIconContainer = styled.div`
  display: flex;
  padding: ${spacing.half};
  transition: opacity 1s ${theme.transitions.times.singleAndHalf};
  margin-left: ${spacing.quarter};
  & svg path {
    fill: ${({ isActive }) => colors.blacks[isActive ? 50 : 30]};
  }
  ${media.S`
    padding-top: ${spacing.threeQuarters}
  `}
`;

const Contents = styled.div`
  min-width: 100%;
  padding: ${spacing.double};
  text-align: center;
  ${media.S`
    min-width: 300px;
  `}
`;

const DescriptionText = styled.div`
  color: ${colors.blacks[80]};
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.regular};
  line-height: ${theme.lineHeight.M};
  margin-top: ${spacing.single};
`;

export default class SkillNamePopover extends React.PureComponent {
  static propTypes = {
    /** Callback when popover opens. */
    onOpenInfoPopover: PropTypes.func,
    /**  Callback when popover closes. */
    onCloseInfoPopover: PropTypes.func,
    /** The skill of the current step.  */
    skillToRate: PropTypes.shape({
      description: PropTypes.string,
      skill: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
    customDescription: PropTypes.string,
  };

  static defaultProps = {
    onOpenInfoPopover: () => null,
    onCloseInfoPopover: () => null,
    skillToRate: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClosePopover = (e) => {
    this.props.onCloseInfoPopover(e);
    this.setState({ isOpen: false });
  };

  handleOpenPopover = (e) => {
    this.props.onOpenInfoPopover(e);
    this.setState({ isOpen: true });
  };

  renderPopoverContents = () => {
    const { customDescription, skillToRate } = this.props;
    const { skill } = skillToRate;
    const { description, name } = skill;
    const iconSrc = getSkillIcon(skill);
    return (
      <Contents>
        <CloseIconContainer>
          <Icon name="close" />
        </CloseIconContainer>
        <SkillBadge src={iconSrc} />
        <SkillName>{name}</SkillName>
        <DescriptionText>{description || customDescription}</DescriptionText>
      </Contents>
    );
  };

  renderPopover = () => {
    return (
      <PopoverMenu
        hasTriangle
        isFullWidthMobile
        isOpen={this.state.isOpen}
        onOpen={this.handleOpenPopover}
        onClose={this.handleClosePopover}
        position="left"
        triggerComponent={
          <InfoIconContainer
            isActive={this.state.isOpen}
          >
            <Icon name="infoCircle" />
          </InfoIconContainer>
        }
      >
        {this.renderPopoverContents()}
      </PopoverMenu>
    );
  };

  render() {
    const { skillToRate } = this.props;
    const { skill } = skillToRate;
    const { name } = skill;
    return (
      <Container>
        {name}
        {this.renderPopover()}
      </Container>
    );
  }
}
