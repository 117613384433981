import React from 'react';
import { Colors, Thumbnail, Paths } from '../Thumbnail';

const KeepItUp = (props) => {
  return (
    <Thumbnail
      color={Colors.Blue}
      width={319}
      height={287}
      top={34}
      left={0}
      {...props}
    >
      <Paths.L20 d="M304.492 242.024C302.952 233.414 298.218 224.741 291.765 219.085C291.21 218.603 290.624 218.131 290.048 217.681C287.398 215.576 284.486 213.638 281.406 212.287C281.406 212.287 261.253 202.441 230.867 215.963C204.251 227.789 202.177 243.846 202.073 247.659C202.167 251.472 204.262 267.529 230.867 279.355C261.253 292.856 281.406 283.031 281.406 283.031C284.496 281.69 287.408 279.753 290.048 277.637C290.624 277.176 291.21 276.715 291.765 276.233C298.228 270.577 302.952 261.915 304.492 253.305C304.827 251.461 304.953 249.555 304.911 247.669C304.942 245.774 304.827 243.878 304.492 242.024Z" />
      <Paths.L30 d="M304.493 242.117C303.959 239.185 303.068 236.241 301.853 233.413C301.937 234.178 303.739 252.613 289.766 265.633C275.479 278.946 252.005 278.862 238.891 275.615C225.788 272.357 211.427 266.397 211.396 266.387C215.659 270.765 221.881 275.311 230.994 279.365C261.317 292.845 281.449 283.02 281.449 283.02C284.529 281.68 287.441 279.742 290.08 277.636C290.656 277.186 291.243 276.715 291.798 276.233C298.24 270.587 302.964 261.935 304.503 253.336C304.839 251.492 304.964 249.586 304.922 247.721C304.943 245.846 304.828 243.951 304.493 242.117Z" />
      <Paths.L50
        evenOdd
        d="M255.483 164.638L254.939 164.523C254.635 164.46 254.446 164.166 254.509 163.852C254.572 163.548 254.865 163.36 255.179 163.423L255.724 163.538C256.028 163.601 256.216 163.894 256.154 164.208C256.07 164.502 255.777 164.701 255.483 164.638Z"
      />
      <Paths.L50
        evenOdd
        d="M271.258 164.638L270.713 164.523C270.41 164.46 270.221 164.166 270.284 163.852C270.347 163.548 270.64 163.36 270.944 163.423L271.489 163.538C271.792 163.601 271.981 163.894 271.918 164.208C271.845 164.502 271.551 164.701 271.258 164.638Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 166.597L254.939 166.481C254.635 166.419 254.446 166.125 254.509 165.811C254.572 165.507 254.865 165.319 255.179 165.382L255.724 165.497C256.028 165.56 256.216 165.853 256.154 166.167C256.07 166.471 255.777 166.67 255.483 166.597Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 168.565L254.939 168.461C254.635 168.398 254.446 168.105 254.509 167.79C254.572 167.487 254.865 167.298 255.179 167.361L255.724 167.476C256.028 167.539 256.216 167.832 256.154 168.147C256.07 168.44 255.777 168.628 255.483 168.565Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 170.535L254.939 170.419C254.635 170.356 254.446 170.063 254.509 169.759C254.572 169.456 254.865 169.267 255.179 169.33L255.724 169.445C256.028 169.508 256.216 169.801 256.154 170.116C256.07 170.409 255.777 170.597 255.483 170.535Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 172.493L254.939 172.389C254.635 172.326 254.446 172.033 254.509 171.718C254.572 171.415 254.865 171.226 255.179 171.289L255.724 171.404C256.028 171.467 256.216 171.76 256.154 172.074C256.07 172.378 255.777 172.577 255.483 172.493Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 174.473L254.939 174.348C254.635 174.285 254.446 173.991 254.509 173.677C254.572 173.373 254.865 173.185 255.179 173.248L255.724 173.352C256.028 173.415 256.216 173.709 256.154 174.023C256.07 174.348 255.777 174.536 255.483 174.473Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 176.443L254.939 176.328C254.635 176.265 254.446 175.972 254.509 175.657C254.572 175.354 254.865 175.165 255.179 175.228L255.724 175.343C256.028 175.406 256.216 175.699 256.154 176.014C256.07 176.307 255.777 176.506 255.483 176.443Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 178.402L254.939 178.286C254.635 178.224 254.446 177.93 254.509 177.627C254.572 177.323 254.865 177.134 255.179 177.197L255.724 177.312C256.028 177.365 255.777 178.475 255.483 178.402Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 180.37L254.939 180.245C254.635 180.182 254.446 179.888 254.509 179.574C254.572 179.27 254.865 179.082 255.179 179.145L255.724 179.27C256.028 179.333 256.216 179.627 256.154 179.93C256.07 180.245 255.777 180.433 255.483 180.37Z"
      />
      <Paths.L50
        evenOdd
        d="M263.202 159.715L262.657 159.589C262.354 159.526 262.165 159.233 262.228 158.919C262.291 158.615 262.584 158.427 262.888 158.489L263.432 158.605C263.736 158.667 263.925 158.961 263.862 159.275C263.81 159.6 263.516 159.778 263.202 159.715Z"
      />
      <Paths.L50
        evenOdd
        d="M260.532 160.092L259.987 159.977C259.684 159.914 259.495 159.621 259.558 159.306C259.621 159.003 259.914 158.814 260.218 158.877L260.763 158.992C261.066 159.055 261.255 159.348 261.192 159.663C261.14 159.956 260.846 160.155 260.532 160.092Z"
      />
      <Paths.L50
        evenOdd
        d="M257.987 161.066L257.442 160.951C257.139 160.888 256.95 160.595 257.013 160.28C257.076 159.977 257.369 159.788 257.683 159.851L258.228 159.966C258.532 160.029 258.72 160.322 258.658 160.636C258.574 160.94 258.28 161.129 257.987 161.066Z"
      />
      <Paths.L50
        evenOdd
        d="M265.884 160.092L265.339 159.977C265.035 159.914 264.847 159.621 264.91 159.306C264.972 159.003 265.266 158.814 265.58 158.877L266.125 158.992C266.428 159.055 266.617 159.348 266.554 159.663C266.481 159.956 266.187 160.155 265.884 160.092Z"
      />
      <Paths.L50
        evenOdd
        d="M268.282 161.066L267.737 160.951C267.433 160.888 267.245 160.595 267.307 160.28C267.37 159.977 267.664 159.788 267.967 159.851L268.512 159.966C268.816 160.029 269.004 160.322 268.942 160.636C268.889 160.94 268.575 161.129 268.282 161.066Z"
      />
      <Paths.L50
        evenOdd
        d="M256.309 162.669L255.765 162.543C255.461 162.48 255.272 162.187 255.335 161.873C255.398 161.569 255.691 161.381 255.995 161.443L256.54 161.569C256.844 161.632 257.032 161.925 256.969 162.24C256.906 162.543 256.613 162.721 256.309 162.669Z"
      />
      <Paths.L50
        evenOdd
        d="M269.949 162.668L269.404 162.543C269.1 162.48 268.912 162.187 268.974 161.872C269.037 161.558 269.331 161.38 269.645 161.443L270.19 161.569C270.493 161.631 270.682 161.925 270.619 162.239C270.556 162.543 270.252 162.721 269.949 162.668Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 182.35L254.939 182.235C254.635 182.172 254.446 181.879 254.509 181.565C254.572 181.261 254.865 181.072 255.179 181.135L255.724 181.25C256.028 181.313 256.216 181.607 256.154 181.921C256.07 182.214 255.777 182.403 255.483 182.35Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 184.309L254.939 184.183C254.635 184.12 254.446 183.827 254.509 183.513C254.572 183.22 254.865 183.021 255.179 183.083L255.724 183.209C256.028 183.272 256.216 183.565 256.154 183.879C256.07 184.183 255.777 184.382 255.483 184.309Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 186.278L254.939 186.152C254.635 186.089 254.446 185.796 254.509 185.482C254.572 185.189 254.865 184.99 255.179 185.052L255.724 185.178C256.028 185.241 256.216 185.534 256.154 185.838C256.07 186.152 255.777 186.341 255.483 186.278Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 188.248L254.939 188.132C254.635 188.07 254.446 187.776 254.509 187.472C254.572 187.179 254.865 186.98 255.179 187.043L255.724 187.158C256.028 187.221 256.216 187.514 256.154 187.818C256.07 188.111 255.777 188.31 255.483 188.248Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 190.206L254.939 190.08C254.635 190.017 254.446 189.724 254.509 189.41C254.572 189.117 254.865 188.918 255.179 188.98L255.724 189.106C256.028 189.169 256.216 189.462 256.154 189.776C256.07 190.08 255.777 190.279 255.483 190.206Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 192.175L254.939 192.05C254.635 191.987 254.446 191.694 254.509 191.379C254.572 191.076 254.865 190.887 255.179 190.95L255.724 191.065C256.028 191.128 256.216 191.421 256.154 191.736C256.07 192.06 255.777 192.238 255.483 192.175Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 194.155L254.939 194.04C254.635 193.977 254.446 193.684 254.509 193.369C254.572 193.076 254.865 192.877 255.179 192.94L255.724 193.066C256.028 193.129 256.216 193.422 256.154 193.736C256.07 194.019 255.777 194.218 255.483 194.155Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 196.113L254.939 195.987C254.635 195.925 254.446 195.631 254.509 195.317C254.572 195.024 254.865 194.825 255.179 194.888L255.724 195.013C256.028 195.076 256.216 195.369 256.154 195.673C256.07 195.987 255.777 196.186 255.483 196.113Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 198.083L254.939 197.968C254.635 197.905 254.446 197.612 254.509 197.297C254.572 197.004 254.865 196.805 255.179 196.868L255.724 196.983C256.028 197.046 256.216 197.339 256.154 197.654C256.07 197.957 255.777 198.146 255.483 198.083Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 200.052L254.939 199.926C254.635 199.863 254.446 199.57 254.509 199.266C254.572 198.962 254.865 198.774 255.179 198.836L255.724 198.962C256.028 199.025 256.216 199.318 256.154 199.622C256.07 199.915 255.777 200.114 255.483 200.052Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 202.01L254.939 201.895C254.635 201.832 254.446 201.539 254.509 201.225C254.572 200.921 254.865 200.732 255.179 200.795L255.724 200.911C256.028 200.973 256.216 201.267 256.154 201.581C256.07 201.885 255.777 202.084 255.483 202.01Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 203.98L254.939 203.865C254.635 203.802 254.446 203.509 254.509 203.205C254.572 202.901 254.865 202.713 255.179 202.776L255.724 202.891C256.028 202.954 256.216 203.247 256.154 203.551C256.07 203.865 255.777 204.043 255.483 203.98Z"
      />
      <Paths.L50
        evenOdd
        d="M255.483 205.96L254.939 205.834C254.635 205.771 254.446 205.478 254.509 205.174C254.572 204.87 254.865 204.682 255.179 204.745L255.724 204.87C256.028 204.933 256.216 205.227 256.154 205.541C256.07 205.824 255.777 206.023 255.483 205.96Z"
      />
      <Paths.L50
        evenOdd
        d="M257.169 209.384C257.169 210.201 256.467 210.851 255.587 210.851C254.708 210.851 254.006 210.201 254.006 209.384C254.006 208.567 254.708 207.918 255.587 207.918C256.467 207.918 257.169 208.567 257.169 209.384Z"
      />
      <Paths.L50
        evenOdd
        d="M257.054 212.192C256.76 212.412 256.341 212.339 256.121 212.056C255.901 211.763 255.943 211.354 256.237 211.134L256.488 210.935C256.781 210.705 257.211 210.736 257.452 211.029C257.682 211.323 257.64 211.773 257.326 212.004L257.054 212.192Z"
      />
      <Paths.L50
        evenOdd
        d="M258.626 214.381C258.322 214.59 257.903 214.506 257.694 214.192C257.484 213.888 257.557 213.48 257.851 213.27L258.102 213.092C258.406 212.872 258.846 212.946 259.055 213.249C259.275 213.553 259.191 214.003 258.888 214.213L258.626 214.381Z"
      />
      <Paths.L50
        evenOdd
        d="M260.081 216.654C259.777 216.853 259.358 216.738 259.159 216.434C258.96 216.131 259.065 215.722 259.368 215.523L259.641 215.356C259.965 215.157 260.384 215.23 260.573 215.565C260.772 215.89 260.667 216.309 260.353 216.497L260.081 216.654Z"
      />
      <Paths.L50
        evenOdd
        d="M261.442 218.99C261.118 219.179 260.699 219.053 260.52 218.728C260.332 218.403 260.447 218.005 260.772 217.817L261.034 217.67C261.369 217.482 261.788 217.586 261.966 217.922C262.155 218.257 262.039 218.676 261.704 218.854L261.442 218.99Z"
      />
      <Paths.L50
        evenOdd
        d="M262.7 221.378C262.364 221.536 261.977 221.399 261.799 221.085C261.631 220.75 261.746 220.362 262.082 220.184L262.354 220.038C262.689 219.87 263.098 219.985 263.276 220.321C263.464 220.656 263.318 221.075 262.982 221.242L262.7 221.378Z"
      />
      <Paths.L50
        evenOdd
        d="M263.873 223.798C263.538 223.945 263.151 223.798 262.983 223.463C262.826 223.128 262.972 222.74 263.287 222.572L263.57 222.436C263.905 222.279 264.313 222.415 264.47 222.761C264.627 223.107 264.481 223.515 264.135 223.672L263.873 223.798Z"
      />
      <Paths.L50
        evenOdd
        d="M264.974 226.28C264.628 226.426 264.23 226.259 264.083 225.924C263.937 225.578 264.094 225.18 264.429 225.033L264.712 224.908C265.057 224.761 265.466 224.908 265.613 225.253C265.759 225.599 265.602 226.007 265.246 226.154L264.974 226.28Z"
      />
      <Paths.L50
        evenOdd
        d="M265.969 228.783C265.623 228.919 265.225 228.731 265.099 228.385C264.963 228.039 265.141 227.641 265.476 227.516L265.759 227.4C266.115 227.264 266.513 227.421 266.649 227.788C266.786 228.144 266.608 228.542 266.251 228.678L265.969 228.783Z"
      />
      <Paths.L50
        evenOdd
        d="M266.88 231.318C266.534 231.444 266.157 231.245 266.031 230.899C265.906 230.554 266.094 230.176 266.44 230.051L266.733 229.957C267.089 229.831 267.487 230.009 267.602 230.365C267.728 230.721 267.54 231.119 267.183 231.234L266.88 231.318Z"
      />
      <Paths.L50
        evenOdd
        d="M267.706 233.895C267.35 234.01 266.983 233.801 266.868 233.455C266.753 233.099 266.952 232.722 267.297 232.617L267.591 232.513C267.947 232.408 268.334 232.586 268.439 232.952C268.554 233.309 268.355 233.696 267.989 233.801L267.706 233.895Z"
      />
      <Paths.L50
        evenOdd
        d="M268.45 236.492C268.094 236.587 267.727 236.367 267.623 236.021C267.528 235.665 267.738 235.298 268.084 235.193L268.387 235.11C268.743 235.015 269.131 235.225 269.225 235.581C269.32 235.937 269.11 236.325 268.743 236.419L268.45 236.492Z"
      />
      <Paths.L50
        evenOdd
        d="M269.1 239.101C268.743 239.185 268.387 238.965 268.303 238.609C268.22 238.253 268.44 237.897 268.796 237.813L269.1 237.739C269.466 237.656 269.822 237.865 269.927 238.232C270.011 238.598 269.791 238.954 269.424 239.049L269.1 239.101Z"
      />
      <Paths.L50
        evenOdd
        d="M269.666 241.741C269.31 241.814 268.954 241.584 268.88 241.206C268.807 240.85 269.037 240.494 269.394 240.421L269.697 240.348C270.064 240.274 270.42 240.494 270.493 240.861C270.567 241.227 270.336 241.584 269.959 241.657L269.666 241.741Z"
      />
      <Paths.L50
        evenOdd
        d="M270.146 244.401C269.79 244.463 269.444 244.212 269.382 243.845C269.319 243.489 269.56 243.144 269.926 243.07L270.23 243.007C270.597 242.945 270.953 243.175 271.016 243.552C271.078 243.919 270.827 244.275 270.46 244.338L270.146 244.401Z"
      />
      <Paths.L50
        evenOdd
        d="M270.544 247.082C270.178 247.134 269.842 246.872 269.79 246.506C269.738 246.139 269.989 245.804 270.356 245.752L270.68 245.699C271.047 245.647 271.393 245.898 271.445 246.265C271.497 246.632 271.236 246.977 270.869 247.03L270.544 247.082Z"
      />
      <Paths.L50
        evenOdd
        d="M270.85 249.754C270.483 249.795 270.158 249.523 270.127 249.146C270.085 248.779 270.347 248.455 270.714 248.402L271.038 248.36C271.405 248.308 271.75 248.58 271.792 248.958C271.834 249.335 271.562 249.659 271.185 249.701L270.85 249.754Z"
      />
      <Paths.L50
        evenOdd
        d="M271.059 252.456C270.693 252.477 270.368 252.194 270.347 251.827C270.326 251.461 270.588 251.136 270.965 251.115L271.29 251.094C271.656 251.052 271.991 251.346 272.012 251.712C272.033 252.079 271.75 252.414 271.384 252.435L271.059 252.456Z"
      />
      <Paths.L50
        evenOdd
        d="M271.195 255.137C270.828 255.148 270.514 254.854 270.503 254.498C270.493 254.132 270.776 253.817 271.142 253.796L271.467 253.786C271.833 253.775 272.158 254.058 272.179 254.425C272.19 254.791 271.896 255.116 271.53 255.127L271.195 255.137Z"
      />
      <Paths.L50
        evenOdd
        d="M271.226 257.85C270.86 257.85 270.566 257.556 270.566 257.19C270.566 256.823 270.86 256.519 271.226 256.509H271.541C271.918 256.498 272.232 256.802 272.232 257.169C272.232 257.535 271.928 257.85 271.551 257.85H271.226Z"
      />
      <Paths.L50
        evenOdd
        d="M271.194 260.552C270.828 260.542 270.524 260.227 270.545 259.861C270.566 259.494 270.87 259.201 271.236 259.211H271.54C271.917 259.222 272.221 259.536 272.21 259.903C272.2 260.28 271.886 260.583 271.509 260.573L271.194 260.552Z"
      />
      <Paths.L50
        evenOdd
        d="M271.059 263.245C270.693 263.224 270.41 262.899 270.431 262.532C270.452 262.166 270.766 261.883 271.133 261.893L271.436 261.904C271.813 261.914 272.107 262.249 272.086 262.616C272.065 263.004 271.74 263.286 271.363 263.255L271.059 263.245Z"
      />
      <Paths.L50
        evenOdd
        d="M270.839 265.936C270.472 265.894 270.2 265.569 270.231 265.192C270.273 264.826 270.588 264.553 270.954 264.574L271.258 264.595C271.646 264.637 271.907 264.951 271.886 265.339C271.845 265.705 271.52 265.988 271.143 265.957L270.839 265.936Z"
      />
      <Paths.L50
        evenOdd
        d="M270.533 268.607C270.167 268.554 269.905 268.219 269.957 267.853C270.01 267.486 270.324 267.224 270.701 267.256L271.005 267.298C271.371 267.339 271.644 267.675 271.612 268.052C271.56 268.418 271.235 268.691 270.848 268.649L270.533 268.607Z"
      />
      <Paths.L50
        evenOdd
        d="M270.147 271.278C269.781 271.216 269.529 270.87 269.592 270.514C269.645 270.147 269.99 269.896 270.357 269.948L270.661 270C271.027 270.053 271.289 270.399 271.237 270.765C271.174 271.132 270.828 271.394 270.462 271.331L270.147 271.278Z"
      />
      <Paths.L50
        evenOdd
        d="M269.675 273.938C269.319 273.865 269.068 273.519 269.141 273.163C269.204 272.807 269.55 272.556 269.916 272.619L270.22 272.671C270.587 272.734 270.838 273.09 270.775 273.457C270.702 273.823 270.356 274.075 269.99 274.001L269.675 273.938Z"
      />
      <Paths.L50
        evenOdd
        d="M269.131 276.588C268.775 276.505 268.544 276.148 268.628 275.792C268.701 275.436 269.058 275.195 269.435 275.279L269.738 275.342C270.105 275.415 270.346 275.771 270.273 276.138C270.199 276.505 269.833 276.745 269.456 276.651L269.131 276.588Z"
      />
      <Paths.L60 d="M267.643 158.06C267.622 158.06 267.612 158.05 267.591 158.05C267.172 158.018 266.774 157.977 266.386 157.924C266.229 157.903 266.072 157.872 265.915 157.851C265.695 157.819 265.485 157.778 265.286 157.736C265.108 157.704 264.941 157.662 264.773 157.62C264.606 157.579 264.448 157.537 264.291 157.495C264.113 157.442 263.935 157.4 263.768 157.348C263.652 157.306 263.548 157.275 263.432 157.233C262.773 157.002 262.186 156.741 261.652 156.447C261.589 156.416 261.526 156.374 261.463 156.343C261.306 156.248 261.159 156.154 261.013 156.06C258.677 154.53 257.703 152.488 257.305 151.126C257.169 150.655 256.477 150.498 256.268 150.948C255.147 153.336 255.859 155.515 256.446 156.678C256.635 157.065 257.284 158.092 257.556 158.427C259.169 160.417 261.997 161.318 264.459 161.705C265.077 161.81 265.224 160.878 264.658 160.616C264.354 160.48 264.071 160.333 263.81 160.197H263.799C263.642 160.113 262.186 159.632 262.416 159.401C262.532 159.296 263.003 159.234 264.103 159.296C264.124 159.296 264.145 159.296 264.166 159.296C265.58 159.37 266.816 159.139 267.748 158.867C268.251 158.699 268.146 158.144 267.643 158.06Z" />
      <Paths.L60 d="M278.642 110.108C279.564 108.223 279.312 105.971 277.982 104.347L276.369 102.368C275.374 101.153 273.457 101.373 272.766 102.776L268.241 111.952C268.251 111.931 268.262 111.899 268.272 111.879L274.965 98.3351C275.898 96.4497 275.646 94.2081 274.295 92.5741L272.682 90.5944C271.718 89.4213 269.917 89.5994 269.173 90.8668L271.289 86.5722C272.211 84.6868 271.959 82.4348 270.639 80.8113L269.026 78.8316C268.031 77.6166 266.114 77.8366 265.423 79.2401L257.913 94.449L257.902 94.4595L266.774 76.4749C267.707 74.5895 267.455 72.3375 266.135 70.7139L264.522 68.7343C263.527 67.5192 261.61 67.7392 260.919 69.1428L253.377 84.4145C253.367 84.425 253.367 84.4355 253.357 84.4459L246.485 98.356C246.464 98.3874 246.443 98.4084 246.433 98.4293L235.11 121.389C234.838 121.892 234.597 122.395 234.366 122.898C233.612 124.553 233.057 126.302 232.785 128.103C232.533 129.737 232.533 131.403 232.785 133.037C233.623 138.421 237.236 143.574 242.013 146.193C242.023 146.172 250.445 148.392 254.09 147.691C257.735 146.989 264.763 142.694 266.565 139.269C268.377 135.603 268.723 133.131 268.356 130.952L270.378 126.847L278.642 110.108ZM257.871 113.733L259.369 110.695C259.327 110.789 259.296 110.873 259.254 110.967L257.892 113.733C257.882 113.733 257.871 113.733 257.871 113.733Z" />
      <Paths.L60 d="M287.617 137.374L318.904 150.498C319.826 150.886 320.036 152.101 319.302 152.781L316.527 155.337C314.987 156.762 312.756 157.149 310.818 156.343L280.652 143.679C279.206 143.072 278.871 141.165 280.023 140.107L281.898 138.369C283.448 136.955 285.679 136.567 287.617 137.374Z" />
      <Paths.L30 d="M288.738 138.107C284.695 141.857 280.348 145.292 275.75 148.34C269.413 152.551 262.406 156.196 254.885 157.673C247.197 159.171 239.362 157.788 233.318 152.614C229.202 149.094 226.164 143.91 224.897 138.609C224.75 138.012 224.279 137.562 223.671 137.478C221.712 137.174 217.627 135.739 215.197 129.162C215.04 128.722 214.684 128.386 214.234 128.25L211.814 127.507C209.677 126.847 209.468 123.914 211.489 122.95C211.846 122.783 212.118 122.479 212.254 122.102C213.008 120.007 216.109 112.685 223.807 109.396C232.742 105.563 244.264 110.276 246.956 112.905C247.008 112.958 247.05 112.999 247.113 113.041C247.7 113.492 251.46 116.393 252.947 119.179C253.178 119.598 253.576 119.902 254.058 119.965C256.561 120.332 264.501 121.735 271.152 125.454L288.477 135.719C289.346 136.221 289.472 137.415 288.738 138.107Z" />
      <Paths.L60 d="M212.139 122.248L205.844 125.17C205.215 125.464 205.278 126.385 205.949 126.595L212.851 128.721L212.139 122.248Z" />
      <Paths.L40 d="M230.395 107.95L230.416 107.929C221.67 107.929 214.327 113.334 212.379 121.295C212.044 122.426 211.866 123.62 211.866 124.856C211.866 124.971 211.866 125.087 211.866 125.202C211.866 125.328 211.866 125.464 211.866 125.589L211.887 125.568C212.232 131.717 216.988 136.671 223.042 137.331C223.555 137.457 224.079 137.53 224.603 137.53C224.718 137.53 224.833 137.499 224.938 137.446C225.922 136.954 231.579 134.095 235.59 130.607C236.135 130.125 235.779 129.235 235.046 129.266C230.824 129.475 226.656 129.193 222.581 127.8C222.099 127.632 221.596 127.454 221.104 127.307C220.423 127.098 220.371 126.155 221.031 125.872C223.534 124.814 225.975 123.694 228.206 122.07C231.296 119.828 234.145 117.241 237.413 115.23C239.11 114.183 244.986 110.831 247.039 112.947C245.08 110.957 237.769 107.647 230.395 107.95Z" />
      <Paths.L50 d="M318.182 146.412L286.549 132.282C278.337 128.365 271.319 125.118 269.486 124.552C269.434 124.531 269.371 124.51 269.318 124.489C268.857 124.269 268.397 124.06 267.936 123.851C262.238 121.358 256.382 120.3 254.183 119.975C253.628 119.891 253.093 120.184 252.863 120.698C252.235 122.112 251.198 125.034 251.889 127.527C252.821 130.868 253.722 136.766 244.483 140.055C243.834 140.285 243.499 141.008 243.74 141.668C244.337 143.239 246.809 145.428 256.581 143.364C256.613 143.364 256.644 143.354 256.665 143.343C257.472 143.113 265.663 148.832 278.662 146.339C285.962 144.936 309.98 152.079 309.98 152.079C311.897 152.938 314.128 152.592 315.71 151.21L318.538 148.717C319.282 148.036 319.093 146.821 318.182 146.412Z" />
      <Paths.L10 d="M226.384 116.634C226.552 117.985 225.577 119.221 224.226 119.389C222.875 119.557 221.639 118.582 221.471 117.231C221.304 115.88 222.278 114.644 223.629 114.476C224.991 114.319 226.227 115.283 226.384 116.634Z" />
      <Paths.L60 d="M224.991 116.98C224.897 116.131 224.122 115.524 223.252 115.618C223.179 115.628 223.116 115.649 223.053 115.66C223.378 115.765 223.63 116.037 223.661 116.393C223.724 116.885 223.368 117.325 222.886 117.388C222.446 117.441 222.058 117.158 221.933 116.749C221.88 116.938 221.859 117.147 221.891 117.357C221.995 118.216 222.771 118.823 223.63 118.718C224.488 118.614 225.096 117.839 224.991 116.98Z" />
      <Paths.L60 d="M269.455 99.5398L284.014 81.639C285.345 80.005 285.596 77.7635 284.664 75.8781L283.533 73.5841C282.841 72.1806 280.925 71.9711 279.929 73.1861L266.124 90.1443C264.794 91.7783 264.542 94.0198 265.475 95.9052L267.151 99.2885C267.601 100.179 268.816 100.315 269.455 99.5398Z" />
      <Paths.L60 d="M272.577 122.677L287.136 104.776C288.467 103.153 288.718 100.901 287.786 99.0154L286.655 96.7215C285.963 95.3179 284.046 95.1084 283.051 96.3234L277.175 103.551L286.173 92.4793C287.492 90.8558 287.744 88.6038 286.812 86.7184L285.68 84.4244C284.979 83.0209 283.072 82.8114 282.077 84.0264L268.282 100.985C266.952 102.619 266.701 104.86 267.633 106.746L269.309 110.129C269.759 111.009 270.943 111.145 271.592 110.401L269.246 113.282C267.916 114.916 267.664 117.157 268.597 119.043L270.272 122.426C270.723 123.316 271.938 123.452 272.577 122.677Z" />
      <Paths.L60 d="M288.749 111.313L287.617 109.019C286.916 107.615 285.009 107.406 284.014 108.621L270.219 125.579C268.889 127.213 268.638 129.455 269.57 131.34L271.246 134.723C271.696 135.614 272.911 135.75 273.55 134.975L288.11 117.074C289.43 115.44 289.681 113.198 288.749 111.313Z" />
      <Paths.L50 d="M255.797 148.255C259.505 148.381 267.319 145.762 269.833 142.819C274.19 137.467 273.74 134.513 272.933 130.48C272.871 129.894 272.713 129.307 272.431 128.752L270.786 125.431C270.399 124.635 269.309 124.52 268.754 125.201L268.67 125.306C269.917 123.766 270.158 121.64 269.278 119.859L267.633 116.539C267.246 115.743 266.156 115.627 265.601 116.308L265.517 116.413C266.764 114.873 267.005 112.747 266.125 110.966L264.48 107.646C264.093 106.85 263.004 106.735 262.448 107.415L262.365 107.52C263.611 105.98 263.852 103.854 262.972 102.073L261.328 98.753C260.94 97.9569 259.851 97.8417 259.296 98.5225L243.155 118.393C242.777 118.832 242.432 119.262 242.097 119.691C240.997 121.137 240.065 122.719 239.394 124.415C238.787 125.955 238.42 127.579 238.305 129.234C237.928 134.67 240.295 140.494 244.38 144.107C244.38 144.097 247.732 148.255 255.797 148.255Z" />
      <Paths.L50 d="M273.248 94.8777L258.689 112.779C258.05 113.554 256.835 113.418 256.385 112.527L254.709 109.144C253.776 107.259 254.028 105.017 255.358 103.383L269.153 86.4249C270.148 85.2098 272.065 85.4193 272.756 86.8229L273.887 89.1168C274.82 91.0022 274.568 93.2542 273.248 94.8777Z" />
      <Paths.L50 d="M275.394 105.73L260.835 123.63C260.196 124.406 258.981 124.269 258.531 123.379L256.855 119.996C255.922 118.11 256.174 115.869 257.504 114.235L271.299 97.2768C272.294 96.0617 274.2 96.2712 274.902 97.6748L276.033 99.9687C276.966 101.854 276.714 104.096 275.394 105.73Z" />
      <Paths.L50 d="M277.542 116.57L262.983 134.471C262.344 135.246 261.129 135.11 260.678 134.22L259.003 130.837C258.07 128.951 258.322 126.71 259.652 125.076L273.447 108.117C274.442 106.902 276.359 107.112 277.05 108.515L278.181 110.809C279.124 112.695 278.862 114.947 277.542 116.57Z" />
      <Paths.L50 d="M279.69 127.411L265.131 145.312C264.492 146.087 263.277 145.951 262.826 145.06L261.151 141.677C260.218 139.792 260.47 137.55 261.8 135.916L275.595 118.958C276.59 117.743 278.507 117.953 279.198 119.356L280.329 121.65C281.272 123.535 281.021 125.787 279.69 127.411Z" />
      <Paths.L60 d="M262.217 161.245C254.791 160.71 252.581 156.573 251.921 154.311C251.785 153.839 251.094 153.682 250.884 154.133C249.774 156.521 250.486 158.699 251.062 159.862C251.251 160.25 251.9 161.276 252.172 161.611C253.785 163.601 256.624 164.502 259.075 164.89C259.693 164.995 259.84 164.062 259.274 163.8C258.97 163.664 258.687 163.518 258.426 163.381C257.923 163.12 258.216 162.439 258.782 162.47C260.196 162.575 261.432 162.313 262.364 162.041C262.888 161.884 262.752 161.287 262.217 161.245Z" />
      <Paths.L20 d="M205.824 172.598C204.284 163.988 199.55 155.315 193.098 149.659C192.542 149.177 191.956 148.706 191.38 148.256C188.73 146.15 185.818 144.212 182.738 142.861C182.738 142.861 162.585 133.015 132.199 146.538C105.583 158.363 103.51 174.421 103.405 178.234C103.499 182.046 105.594 198.104 132.199 209.929C162.585 223.431 182.738 213.606 182.738 213.606C185.828 212.265 188.74 210.327 191.38 208.211C191.956 207.751 192.542 207.29 193.098 206.808C199.56 201.152 204.284 192.489 205.824 183.879C206.159 182.036 206.285 180.129 206.243 178.244C206.285 176.338 206.159 174.442 205.824 172.598Z" />
      <Paths.L30 d="M205.824 172.682C205.29 169.749 204.4 166.805 203.185 163.977C203.269 164.742 205.07 183.177 191.097 196.197C176.81 209.51 153.337 209.426 140.223 206.179C127.119 202.932 112.759 196.961 112.728 196.951C116.991 201.329 123.212 205.875 132.325 209.929C162.649 223.409 182.781 213.584 182.781 213.584C185.86 212.244 188.772 210.306 191.412 208.201C191.988 207.75 192.574 207.279 193.129 206.797C199.571 201.151 204.295 192.499 205.835 183.9C206.17 182.056 206.296 180.15 206.254 178.285C206.285 176.411 206.16 174.515 205.824 172.682Z" />
      <Paths.L50
        evenOdd
        d="M156.816 96.6901L156.271 96.5749C155.967 96.512 155.779 96.2187 155.842 95.9045C155.905 95.6007 156.198 95.4122 156.502 95.475L157.046 95.5903C157.35 95.6531 157.539 95.9464 157.476 96.2606C157.413 96.5644 157.12 96.7529 156.816 96.6901Z"
      />
      <Paths.L50
        evenOdd
        d="M172.589 96.6901L172.044 96.5749C171.74 96.512 171.552 96.2187 171.615 95.9045C171.677 95.6007 171.971 95.4122 172.274 95.475L172.819 95.5903C173.123 95.6531 173.311 95.9464 173.249 96.2606C173.186 96.5644 172.892 96.7529 172.589 96.6901Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 98.6489L156.271 98.5337C155.967 98.4709 155.779 98.1776 155.842 97.8634C155.905 97.5596 156.198 97.3711 156.502 97.4339L157.046 97.5491C157.35 97.612 157.539 97.9053 157.476 98.2195C157.413 98.5337 157.12 98.7327 156.816 98.6489Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 100.628L156.271 100.523C155.967 100.46 155.779 100.167 155.842 99.8529C155.905 99.5491 156.198 99.3606 156.502 99.4234L157.046 99.5387C157.35 99.6015 157.539 99.8948 157.476 100.209C157.413 100.502 157.12 100.691 156.816 100.628Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 102.597L156.271 102.482C155.967 102.419 155.779 102.126 155.842 101.812C155.905 101.508 156.198 101.319 156.502 101.382L157.046 101.497C157.35 101.56 157.539 101.854 157.476 102.168C157.413 102.461 157.12 102.66 156.816 102.597Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 104.556L156.271 104.451C155.967 104.388 155.779 104.095 155.842 103.781C155.905 103.477 156.198 103.289 156.502 103.351L157.046 103.467C157.35 103.529 157.539 103.823 157.476 104.137C157.413 104.43 157.12 104.629 156.816 104.556Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 106.525L156.271 106.4C155.967 106.337 155.779 106.044 155.842 105.729C155.905 105.426 156.198 105.237 156.502 105.3L157.046 105.415C157.35 105.478 157.539 105.771 157.476 106.086C157.413 106.41 157.12 106.588 156.816 106.525Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 108.506L156.271 108.39C155.967 108.328 155.779 108.034 155.842 107.72C155.905 107.416 156.198 107.228 156.502 107.291L157.046 107.406C157.35 107.469 157.539 107.762 157.476 108.076C157.413 108.369 157.12 108.558 156.816 108.506Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 110.464L156.271 110.349C155.967 110.286 155.779 109.993 155.842 109.689C155.905 109.385 156.198 109.197 156.502 109.26L157.046 109.375C157.36 109.427 157.12 110.537 156.816 110.464Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 112.433L156.271 112.307C155.967 112.244 155.779 111.951 155.842 111.637C155.905 111.333 156.198 111.144 156.502 111.207L157.046 111.333C157.35 111.396 157.539 111.689 157.476 112.003C157.413 112.307 157.12 112.496 156.816 112.433Z"
      />
      <Paths.L50
        evenOdd
        d="M164.545 91.7774L164 91.6517C163.696 91.5888 163.508 91.2955 163.571 90.9813C163.634 90.6776 163.927 90.489 164.231 90.5519L164.775 90.6671C165.079 90.7299 165.268 91.0232 165.205 91.3374C165.152 91.6517 164.849 91.8402 164.545 91.7774Z"
      />
      <Paths.L50
        evenOdd
        d="M161.875 92.1443L161.33 92.0291C161.026 91.9663 160.838 91.673 160.901 91.3588C160.964 91.055 161.257 90.8665 161.561 90.9293L162.105 91.0445C162.409 91.1074 162.598 91.4007 162.535 91.7149C162.472 92.0082 162.179 92.2072 161.875 92.1443Z"
      />
      <Paths.L50
        evenOdd
        d="M159.318 93.1182L158.773 93.0029C158.469 92.9401 158.281 92.6468 158.344 92.3326C158.406 92.0288 158.7 91.8403 159.014 91.9031L159.559 92.0183C159.862 92.0812 160.051 92.3745 159.988 92.6887C159.915 92.9925 159.611 93.181 159.318 93.1182Z"
      />
      <Paths.L50
        evenOdd
        d="M167.216 92.1443L166.671 92.0291C166.368 91.9663 166.179 91.673 166.242 91.3588C166.305 91.055 166.598 90.8665 166.912 90.9293L167.457 91.0445C167.761 91.1074 167.949 91.4007 167.887 91.7149C167.824 92.0082 167.53 92.2072 167.216 92.1443Z"
      />
      <Paths.L50
        evenOdd
        d="M169.626 93.1182L169.082 93.0029C168.778 92.9401 168.589 92.6468 168.652 92.3326C168.715 92.0288 169.008 91.8403 169.312 91.9031L169.857 92.0183C170.161 92.0812 170.349 92.3745 170.286 92.6887C170.223 92.9925 169.92 93.181 169.626 93.1182Z"
      />
      <Paths.L50
        evenOdd
        d="M157.642 94.7202L157.097 94.5945C156.794 94.5317 156.605 94.2384 156.668 93.9242C156.731 93.6204 157.024 93.4319 157.328 93.4947L157.872 93.6204C158.176 93.6833 158.365 93.9765 158.302 94.2908C158.249 94.5945 157.956 94.7831 157.642 94.7202Z"
      />
      <Paths.L50
        evenOdd
        d="M171.291 94.7202L170.747 94.5945C170.443 94.5317 170.254 94.2384 170.317 93.9242C170.38 93.6204 170.673 93.4319 170.988 93.4947L171.532 93.6204C171.836 93.6833 172.025 93.9765 171.962 94.2908C171.888 94.5945 171.595 94.7831 171.291 94.7202Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 114.403L156.271 114.287C155.967 114.224 155.779 113.931 155.842 113.617C155.905 113.313 156.198 113.125 156.502 113.188L157.046 113.303C157.35 113.366 157.539 113.659 157.476 113.973C157.413 114.266 157.12 114.465 156.816 114.403Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 116.361L156.271 116.236C155.967 116.173 155.779 115.879 155.842 115.565C155.905 115.272 156.198 115.073 156.502 115.136L157.046 115.261C157.35 115.324 157.539 115.618 157.476 115.932C157.413 116.236 157.12 116.435 156.816 116.361Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 118.33L156.271 118.205C155.967 118.142 155.779 117.848 155.842 117.534C155.905 117.241 156.198 117.042 156.502 117.105L157.046 117.23C157.35 117.293 157.539 117.587 157.476 117.901C157.413 118.215 157.12 118.393 156.816 118.33Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 120.31L156.271 120.195C155.967 120.132 155.779 119.839 155.842 119.535C155.905 119.242 156.198 119.043 156.502 119.106L157.046 119.221C157.35 119.284 157.539 119.577 157.476 119.881C157.413 120.174 157.12 120.373 156.816 120.31Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 122.268L156.271 122.143C155.967 122.08 155.779 121.787 155.842 121.472C155.905 121.179 156.198 120.98 156.502 121.043L157.046 121.169C157.35 121.231 157.539 121.525 157.476 121.839C157.413 122.143 157.12 122.342 156.816 122.268Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 124.238L156.271 124.112C155.967 124.049 155.779 123.756 155.842 123.442C155.905 123.138 156.198 122.95 156.502 123.012L157.046 123.128C157.35 123.19 157.539 123.484 157.476 123.798C157.413 124.112 157.12 124.301 156.816 124.238Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 126.207L156.271 126.092C155.967 126.029 155.779 125.736 155.842 125.422C155.905 125.128 156.198 124.929 156.502 124.992L157.046 125.118C157.35 125.181 157.539 125.474 157.476 125.788C157.413 126.071 157.12 126.27 156.816 126.207Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 128.165L156.271 128.04C155.967 127.977 155.779 127.684 155.842 127.369C155.905 127.076 156.198 126.877 156.502 126.94L157.046 127.066C157.35 127.128 157.539 127.422 157.476 127.736C157.413 128.04 157.12 128.239 156.816 128.165Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 130.146L156.271 130.03C155.967 129.967 155.779 129.674 155.842 129.36C155.905 129.067 156.198 128.868 156.502 128.93L157.046 129.046C157.35 129.109 157.539 129.402 157.476 129.716C157.413 130.02 157.12 130.198 156.816 130.146Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 132.114L156.271 131.988C155.967 131.925 155.779 131.632 155.842 131.328C155.905 131.025 156.198 130.836 156.502 130.899L157.046 131.025C157.35 131.088 157.539 131.381 157.476 131.685C157.413 131.978 157.12 132.177 156.816 132.114Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 134.073L156.271 133.958C155.967 133.895 155.779 133.601 155.842 133.287C155.905 132.983 156.198 132.795 156.502 132.858L157.046 132.973C157.35 133.036 157.539 133.329 157.476 133.643C157.413 133.947 157.12 134.146 156.816 134.073Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 136.043L156.271 135.927C155.967 135.865 155.779 135.571 155.842 135.268C155.905 134.964 156.198 134.775 156.502 134.838L157.046 134.953C157.35 135.016 157.539 135.309 157.476 135.613C157.413 135.917 157.12 136.106 156.816 136.043Z"
      />
      <Paths.L50
        evenOdd
        d="M156.816 138.012L156.271 137.886C155.967 137.823 155.779 137.53 155.842 137.226C155.905 136.923 156.198 136.734 156.502 136.797L157.046 136.923C157.35 136.986 157.539 137.279 157.476 137.593C157.413 137.876 157.12 138.075 156.816 138.012Z"
      />
      <Paths.L50
        evenOdd
        d="M158.512 139.949C158.512 140.766 157.81 141.416 156.93 141.416C156.05 141.416 155.349 140.766 155.349 139.949C155.349 139.132 156.05 138.483 156.93 138.483C157.81 138.483 158.512 139.132 158.512 139.949Z"
      />
      <Paths.L50
        evenOdd
        d="M158.397 142.756C158.104 142.976 157.685 142.903 157.465 142.62C157.245 142.327 157.298 141.918 157.58 141.698L157.832 141.499C158.125 141.269 158.555 141.3 158.796 141.593C159.026 141.887 158.984 142.337 158.67 142.568L158.397 142.756Z"
      />
      <Paths.L50
        evenOdd
        d="M159.958 144.956C159.655 145.165 159.236 145.081 159.026 144.767C158.817 144.463 158.89 144.055 159.183 143.845L159.424 143.667C159.728 143.447 160.168 143.521 160.377 143.824C160.597 144.128 160.513 144.579 160.21 144.788L159.958 144.956Z"
      />
      <Paths.L50
        evenOdd
        d="M161.413 147.218C161.109 147.417 160.69 147.302 160.491 146.998C160.292 146.695 160.397 146.286 160.701 146.087L160.973 145.92C161.298 145.721 161.717 145.794 161.906 146.129C162.105 146.454 162 146.873 161.686 147.061L161.413 147.218Z"
      />
      <Paths.L50
        evenOdd
        d="M162.785 149.565C162.46 149.754 162.041 149.628 161.863 149.303C161.675 148.979 161.79 148.581 162.115 148.392L162.377 148.245C162.712 148.057 163.131 148.162 163.309 148.497C163.497 148.832 163.382 149.251 163.047 149.429L162.785 149.565Z"
      />
      <Paths.L50
        evenOdd
        d="M164.042 151.942C163.707 152.1 163.32 151.963 163.142 151.649C162.974 151.314 163.089 150.926 163.424 150.748L163.697 150.602C164.032 150.434 164.44 150.549 164.619 150.885C164.807 151.22 164.66 151.639 164.325 151.806L164.042 151.942Z"
      />
      <Paths.L50
        evenOdd
        d="M165.216 154.373C164.881 154.52 164.493 154.373 164.326 154.038C164.169 153.703 164.315 153.315 164.629 153.148L164.912 153.011C165.247 152.854 165.656 152.991 165.813 153.336C165.97 153.682 165.824 154.09 165.478 154.247L165.216 154.373Z"
      />
      <Paths.L50
        evenOdd
        d="M166.304 156.845C165.959 156.991 165.561 156.824 165.414 156.489C165.267 156.143 165.424 155.745 165.76 155.598L166.042 155.472C166.388 155.326 166.797 155.472 166.943 155.818C167.09 156.164 166.933 156.572 166.577 156.719L166.304 156.845Z"
      />
      <Paths.L50
        evenOdd
        d="M167.311 159.348C166.966 159.484 166.568 159.296 166.442 158.95C166.306 158.604 166.484 158.206 166.819 158.081L167.102 157.965C167.458 157.829 167.856 157.986 167.992 158.353C168.128 158.709 167.95 159.107 167.594 159.243L167.311 159.348Z"
      />
      <Paths.L50
        evenOdd
        d="M168.222 161.883C167.877 162.009 167.5 161.81 167.374 161.464C167.248 161.119 167.437 160.741 167.783 160.616L168.076 160.522C168.432 160.396 168.83 160.574 168.945 160.93C169.071 161.286 168.882 161.684 168.526 161.799L168.222 161.883Z"
      />
      <Paths.L50
        evenOdd
        d="M169.038 164.46C168.682 164.575 168.316 164.366 168.2 164.02C168.085 163.664 168.284 163.287 168.63 163.182L168.923 163.077C169.279 162.973 169.667 163.151 169.771 163.517C169.887 163.874 169.688 164.261 169.321 164.366L169.038 164.46Z"
      />
      <Paths.L50
        evenOdd
        d="M169.783 167.057C169.427 167.152 169.06 166.932 168.955 166.586C168.861 166.23 169.07 165.863 169.416 165.758L169.72 165.675C170.076 165.58 170.464 165.79 170.558 166.146C170.652 166.502 170.443 166.89 170.076 166.984L169.783 167.057Z"
      />
      <Paths.L50
        evenOdd
        d="M170.432 169.676C170.076 169.76 169.72 169.54 169.636 169.184C169.552 168.828 169.772 168.472 170.128 168.388L170.432 168.315C170.799 168.231 171.155 168.44 171.26 168.807C171.343 169.174 171.123 169.53 170.757 169.624L170.432 169.676Z"
      />
      <Paths.L50
        evenOdd
        d="M171.009 172.316C170.653 172.389 170.296 172.159 170.223 171.782C170.15 171.426 170.38 171.069 170.736 170.996L171.04 170.923C171.407 170.849 171.763 171.069 171.836 171.436C171.909 171.803 171.679 172.159 171.302 172.232L171.009 172.316Z"
      />
      <Paths.L50
        evenOdd
        d="M171.479 174.976C171.123 175.039 170.777 174.787 170.714 174.421C170.651 174.064 170.892 173.719 171.259 173.645L171.562 173.583C171.929 173.52 172.285 173.75 172.348 174.127C172.411 174.494 172.16 174.85 171.793 174.913L171.479 174.976Z"
      />
      <Paths.L50
        evenOdd
        d="M171.877 177.647C171.51 177.699 171.175 177.437 171.123 177.071C171.07 176.704 171.322 176.369 171.688 176.317L172.013 176.264C172.38 176.212 172.725 176.463 172.778 176.83C172.83 177.197 172.568 177.542 172.201 177.595L171.877 177.647Z"
      />
      <Paths.L50
        evenOdd
        d="M172.18 180.318C171.814 180.359 171.489 180.087 171.458 179.71C171.416 179.343 171.677 179.019 172.044 178.966L172.369 178.924C172.735 178.872 173.081 179.144 173.123 179.521C173.165 179.899 172.893 180.223 172.515 180.265L172.18 180.318Z"
      />
      <Paths.L50
        evenOdd
        d="M172.392 183.02C172.025 183.041 171.7 182.758 171.679 182.391C171.658 182.025 171.931 181.7 172.297 181.679L172.622 181.658C172.989 181.616 173.324 181.91 173.345 182.276C173.366 182.643 173.083 182.978 172.716 182.999L172.392 183.02Z"
      />
      <Paths.L50
        evenOdd
        d="M172.527 185.702C172.16 185.713 171.846 185.419 171.836 185.063C171.825 184.697 172.108 184.382 172.475 184.361L172.799 184.351C173.166 184.34 173.491 184.623 173.512 184.99C173.522 185.356 173.229 185.681 172.862 185.692L172.527 185.702Z"
      />
      <Paths.L50
        evenOdd
        d="M172.569 188.415C172.202 188.415 171.909 188.121 171.909 187.755C171.909 187.388 172.202 187.084 172.569 187.074H172.873C173.25 187.063 173.564 187.367 173.564 187.734C173.564 188.1 173.26 188.415 172.883 188.415H172.569Z"
      />
      <Paths.L50
        evenOdd
        d="M172.527 191.117C172.16 191.107 171.857 190.792 171.877 190.426C171.898 190.059 172.202 189.766 172.569 189.776H172.873C173.25 189.787 173.553 190.101 173.532 190.468C173.522 190.845 173.208 191.148 172.831 191.138L172.527 191.117Z"
      />
      <Paths.L50
        evenOdd
        d="M172.402 193.82C172.035 193.799 171.753 193.474 171.774 193.108C171.795 192.741 172.109 192.458 172.475 192.469L172.779 192.479C173.167 192.49 173.449 192.825 173.429 193.191C173.408 193.579 173.083 193.862 172.706 193.83L172.402 193.82Z"
      />
      <Paths.L50
        evenOdd
        d="M172.172 196.501C171.805 196.459 171.533 196.134 171.564 195.757C171.606 195.391 171.92 195.118 172.287 195.139L172.59 195.16C172.978 195.202 173.24 195.516 173.219 195.904C173.177 196.27 172.852 196.553 172.475 196.522L172.172 196.501Z"
      />
      <Paths.L50
        evenOdd
        d="M171.876 199.172C171.51 199.119 171.248 198.784 171.3 198.418C171.353 198.051 171.667 197.789 172.044 197.821L172.348 197.862C172.714 197.904 172.987 198.24 172.955 198.617C172.903 198.983 172.578 199.256 172.19 199.214L171.876 199.172Z"
      />
      <Paths.L50
        evenOdd
        d="M171.48 201.854C171.113 201.791 170.862 201.445 170.925 201.089C170.977 200.722 171.323 200.471 171.689 200.523L171.993 200.576C172.36 200.628 172.622 200.974 172.569 201.34C172.506 201.707 172.161 201.969 171.794 201.906L171.48 201.854Z"
      />
      <Paths.L50
        evenOdd
        d="M171.018 204.514C170.662 204.44 170.411 204.095 170.484 203.738C170.547 203.382 170.892 203.131 171.259 203.194L171.563 203.246C171.929 203.309 172.181 203.665 172.118 204.032C172.045 204.398 171.699 204.65 171.332 204.576L171.018 204.514Z"
      />
      <Paths.L50
        evenOdd
        d="M170.474 207.164C170.117 207.08 169.887 206.724 169.971 206.367C170.044 206.011 170.4 205.77 170.767 205.854L171.071 205.917C171.437 205.99 171.678 206.347 171.605 206.713C171.532 207.08 171.165 207.321 170.788 207.226L170.474 207.164Z"
      />
      <Paths.L60 d="M168.985 90.1222C168.965 90.1222 168.954 90.1117 168.933 90.1117C168.514 90.0803 168.116 90.0384 167.729 89.986C167.571 89.9651 167.414 89.9337 167.257 89.9127C167.037 89.8813 166.828 89.8394 166.629 89.7975C166.451 89.7661 166.283 89.7242 166.115 89.6823C165.948 89.6404 165.791 89.5985 165.634 89.5566C165.456 89.5042 165.278 89.4623 165.11 89.4099C164.995 89.3681 164.89 89.3366 164.775 89.2947C164.115 89.0643 163.528 88.8024 162.994 88.5091C162.931 88.4777 162.868 88.4358 162.806 88.4044C162.648 88.3101 162.502 88.2159 162.355 88.1216C160.019 86.5923 159.045 84.5498 158.647 83.1881C158.511 82.7168 157.82 82.5597 157.61 83.0101C156.489 85.3982 157.202 87.5769 157.788 88.7396C157.977 89.1271 158.626 90.1536 158.899 90.4888C160.512 92.479 163.34 93.3798 165.801 93.7673C166.419 93.8721 166.566 92.9398 166 92.678C165.696 92.5418 165.414 92.3952 165.152 92.259H165.141C164.984 92.1752 163.528 91.6934 163.759 91.4629C163.874 91.3582 164.345 91.2954 165.445 91.3582C165.466 91.3582 165.487 91.3582 165.508 91.3582C166.922 91.4315 168.158 91.2011 169.09 90.9287C169.582 90.7507 169.478 90.1955 168.985 90.1222Z" />
      <Paths.L60 d="M179.984 42.1702C180.905 40.2848 180.654 38.0328 179.324 36.4092L177.711 34.4296C176.716 33.2145 174.799 33.4345 174.108 34.8381L169.583 44.0137C169.593 43.9927 169.603 43.9613 169.614 43.9404L176.307 30.3969C177.239 28.5115 176.988 26.27 175.637 24.6359L174.024 22.6563C173.06 21.4831 171.258 21.6612 170.515 22.9286L172.631 18.6341C173.552 16.7487 173.301 14.4967 171.981 12.8731L170.368 10.8935C169.373 9.67842 167.456 9.89839 166.765 11.302L159.255 26.5109L159.244 26.5213L168.116 8.53671C169.048 6.65131 168.797 4.3993 167.477 2.77576L165.864 0.796087C164.869 -0.418949 162.952 -0.198986 162.261 1.20459L154.719 16.4763C154.709 16.4868 154.709 16.4973 154.698 16.5078L147.827 30.4178C147.806 30.4493 147.785 30.4702 147.775 30.4912L136.452 53.4512C136.18 53.9539 135.939 54.4567 135.708 54.9595C134.954 56.6144 134.399 58.3637 134.127 60.1653C133.875 61.7993 133.875 63.4647 134.127 65.0987C134.964 70.4826 138.578 75.636 143.355 78.2547C143.365 78.2337 151.786 80.4543 155.432 79.7525C159.077 79.0507 166.105 74.7562 167.907 71.331C169.719 67.665 170.064 65.193 169.698 63.0143L171.719 58.9083L179.984 42.1702ZM159.202 45.7839L160.7 42.7463C160.658 42.8405 160.627 42.9243 160.585 43.0186L159.223 45.7839C159.223 45.7943 159.213 45.7839 159.202 45.7839Z" />
      <Paths.L60 d="M188.959 69.4354L220.247 82.5598C221.168 82.9474 221.378 84.1624 220.645 84.8433L217.869 87.399C216.329 88.8236 214.098 89.2111 212.16 88.4046L181.994 75.741C180.548 75.1334 180.213 73.2271 181.365 72.1692L183.24 70.4304C184.791 69.0164 187.022 68.6288 188.959 69.4354Z" />
      <Paths.L30 d="M190.081 70.1685C186.038 73.9184 181.691 77.354 177.092 80.4021C170.755 84.6128 163.748 88.2579 156.227 89.7348C148.539 91.2326 140.704 89.85 134.66 84.6756C130.544 81.1562 127.506 75.9714 126.239 70.6713C126.092 70.0743 125.621 69.6238 125.013 69.5401C123.055 69.2363 118.97 67.8013 116.54 61.2233C116.383 60.7834 116.026 60.4482 115.576 60.3121L113.156 59.5684C111.02 58.9085 110.81 55.9756 112.832 55.012C113.188 54.8444 113.46 54.5406 113.596 54.1636C114.35 52.0687 117.451 44.747 125.15 41.458C134.084 37.6244 145.606 42.3379 148.298 44.967C148.351 45.0194 148.392 45.0613 148.455 45.1032C149.042 45.5536 152.802 48.455 154.29 51.2412C154.52 51.6602 154.918 51.9639 155.4 52.0268C157.903 52.3934 165.843 53.797 172.494 57.5154L189.819 67.7803C190.688 68.2831 190.814 69.4772 190.081 70.1685Z" />
      <Paths.L60 d="M113.481 54.3098L107.186 57.2322C106.558 57.5255 106.621 58.4472 107.291 58.6567L114.194 60.783L113.481 54.3098Z" />
      <Paths.L40 d="M131.737 40.0123L131.758 39.9913C123.012 39.9913 115.67 45.3961 113.721 53.3567C113.386 54.4879 113.208 55.682 113.208 56.918C113.208 57.0332 113.208 57.1485 113.208 57.2637C113.208 57.3894 113.208 57.5255 113.208 57.6512L113.229 57.6303C113.575 63.7788 118.33 68.7332 124.384 69.3931C124.897 69.5188 125.421 69.5921 125.945 69.5921C126.06 69.5921 126.175 69.5607 126.28 69.5083C127.265 69.016 132.921 66.1565 136.933 62.6685C137.477 62.1867 137.121 61.2963 136.388 61.3278C132.167 61.5373 127.998 61.2544 123.923 59.8613C123.442 59.6938 122.939 59.5157 122.446 59.369C121.766 59.1596 121.713 58.2169 122.373 57.934C124.877 56.8761 127.317 55.7554 129.548 54.1318C132.638 51.8903 135.487 49.3031 138.755 47.292C140.452 46.2446 146.328 42.8927 148.381 45.0086C146.423 43.0184 139.111 39.7085 131.737 40.0123Z" />
      <Paths.L50 d="M219.524 78.4743L187.891 64.3443C179.679 60.4268 172.661 57.1798 170.828 56.6141C170.776 56.5932 170.713 56.5722 170.661 56.5513C170.2 56.3313 169.739 56.1218 169.278 55.9124C163.58 53.4194 157.725 52.3615 155.525 52.0368C154.97 51.953 154.436 52.2463 154.205 52.7595C153.577 54.1736 152.54 57.096 153.231 59.5889C154.163 62.9302 155.064 68.8273 145.826 72.1163C145.176 72.3468 144.841 73.0695 145.082 73.7294C145.679 75.3006 148.151 77.4897 157.924 75.4263C157.955 75.4263 157.987 75.4158 158.008 75.4053C158.814 75.1749 167.005 80.8939 180.004 78.401C187.305 76.9974 211.322 84.141 211.322 84.141C213.239 84.9999 215.47 84.6542 217.052 83.2716L219.88 80.7787C220.624 80.0979 220.435 78.8828 219.524 78.4743Z" />
      <Paths.L10 d="M127.726 48.696C127.894 50.0472 126.92 51.2832 125.568 51.4508C124.217 51.6184 122.981 50.6443 122.814 49.293C122.646 47.9418 123.62 46.7059 124.971 46.5383C126.333 46.3811 127.569 47.3448 127.726 48.696Z" />
      <Paths.L60 d="M126.333 49.0415C126.239 48.1931 125.464 47.5856 124.595 47.6798C124.521 47.6903 124.459 47.7113 124.396 47.7217C124.72 47.8265 124.972 48.0988 125.003 48.4549C125.066 48.9472 124.71 49.3872 124.228 49.45C123.788 49.5024 123.401 49.2196 123.275 48.8111C123.223 48.9996 123.202 49.2091 123.233 49.4186C123.338 50.2775 124.113 50.885 124.972 50.7803C125.831 50.6755 126.438 49.9004 126.333 49.0415Z" />
      <Paths.L60 d="M170.797 31.6017L185.357 13.7008C186.687 12.0668 186.938 9.8253 186.006 7.9399L184.875 5.64599C184.184 4.24241 182.267 4.03293 181.272 5.24796L167.466 22.2061C166.136 23.8401 165.885 26.0816 166.817 27.9671L168.493 31.3503C168.943 32.2406 170.158 32.3768 170.797 31.6017Z" />
      <Paths.L60 d="M173.919 54.7398L188.479 36.839C189.809 35.2154 190.06 32.9634 189.128 31.078L187.997 28.7841C187.305 27.3805 185.389 27.1711 184.394 28.3861L178.517 35.6135L187.515 24.542C188.835 22.9184 189.086 20.6664 188.154 18.781L187.023 16.4871C186.321 15.0835 184.415 14.8741 183.419 16.0891L169.625 33.0472C168.294 34.6812 168.043 36.9228 168.975 38.8082L170.651 42.1914C171.102 43.0713 172.285 43.2074 172.935 42.4638L170.588 45.3442C169.258 46.9783 169.007 49.2198 169.939 51.1052L171.615 54.4884C172.065 55.3788 173.28 55.5149 173.919 54.7398Z" />
      <Paths.L60 d="M190.091 43.3755L188.96 41.0816C188.258 39.678 186.352 39.4685 185.357 40.6836L171.562 57.6417C170.231 59.2757 169.98 61.5173 170.912 63.4027L172.588 66.7859C173.039 67.6762 174.254 67.8124 174.893 67.0373L189.452 49.1365C190.772 47.5025 191.023 45.2609 190.091 43.3755Z" />
      <Paths.L50 d="M157.139 80.3178C160.847 80.4435 168.661 77.8249 171.175 74.8816C175.533 69.5291 175.082 66.5753 174.276 62.5426C174.213 61.9561 174.056 61.3695 173.773 60.8144L172.128 57.494C171.741 56.6979 170.651 56.5827 170.096 57.2635L170.013 57.3683C171.259 55.8285 171.5 53.7022 170.62 51.9216L168.976 48.6012C168.588 47.8051 167.499 47.6899 166.944 48.3707L166.86 48.4755C168.106 46.9357 168.347 44.8094 167.467 43.0287L165.823 39.7083C165.435 38.9123 164.346 38.7971 163.791 39.4779L163.707 39.5827C164.953 38.0429 165.194 35.9166 164.314 34.1359L162.67 30.8155C162.282 30.0195 161.193 29.9043 160.638 30.5851L144.497 50.4551C144.12 50.8951 143.774 51.3245 143.439 51.754C142.339 53.1994 141.407 54.7811 140.736 56.4779C140.129 58.0177 139.762 59.6412 139.647 61.2962C139.27 66.7324 141.637 72.5562 145.722 76.1699C145.722 76.1594 149.074 80.3178 157.139 80.3178Z" />
      <Paths.L50 d="M174.591 26.9403L160.031 44.8412C159.392 45.6163 158.177 45.4801 157.727 44.5898L156.051 41.2065C155.119 39.3211 155.37 37.0796 156.7 35.4456L170.495 18.4874C171.49 17.2724 173.407 17.4819 174.098 18.8855L175.23 21.1794C176.162 23.0648 175.91 25.3168 174.591 26.9403Z" />
      <Paths.L50 d="M176.737 37.7922L162.177 55.6931C161.538 56.4682 160.323 56.332 159.873 55.4417L158.197 52.0584C157.265 50.173 157.516 47.9315 158.846 46.2975L172.641 29.3393C173.636 28.1243 175.543 28.3338 176.244 29.7374L177.376 32.0313C178.308 33.9167 178.056 36.1582 176.737 37.7922Z" />
      <Paths.L50 d="M178.885 48.6329L164.325 66.5337C163.686 67.3088 162.471 67.1726 162.021 66.2823L160.345 62.8991C159.413 61.0137 159.664 58.7721 160.994 57.1381L174.789 40.18C175.784 38.9649 177.701 39.1744 178.392 40.578L179.524 42.8719C180.466 44.7573 180.204 47.0093 178.885 48.6329Z" />
      <Paths.L50 d="M181.031 59.4735L166.471 77.3743C165.832 78.1495 164.617 78.0133 164.167 77.123L162.491 73.7397C161.559 71.8543 161.81 69.6128 163.14 67.9788L176.935 51.0206C177.93 49.8056 179.847 50.0151 180.538 51.4187L181.67 53.7126C182.612 55.598 182.361 57.85 181.031 59.4735Z" />
      <Paths.L60 d="M163.56 93.3065C156.133 92.7723 153.923 88.6349 153.263 86.3724C153.127 85.9011 152.436 85.744 152.226 86.1944C151.116 88.5825 151.828 90.7612 152.404 91.9239C152.593 92.3114 153.242 93.3379 153.515 93.6731C155.128 95.6633 157.966 96.5641 160.417 96.9516C161.035 97.0564 161.182 96.1241 160.616 95.8623C160.313 95.7261 160.03 95.5795 159.768 95.4433C159.265 95.1814 159.558 94.5006 160.124 94.532C161.538 94.6368 162.774 94.3749 163.706 94.1026C164.23 93.9454 164.094 93.3484 163.56 93.3065Z" />
      <Paths.L20 d="M102.419 221.985C100.879 213.375 96.145 204.703 89.6927 199.046C89.1376 198.565 88.551 198.093 87.9749 197.643C85.3249 195.537 82.413 193.6 79.3335 192.248C79.3335 192.248 59.1807 182.402 28.7943 195.925C2.17869 207.751 0.115219 223.808 0 227.621C0.0942701 231.433 2.18916 247.491 28.7943 259.316C59.1807 272.818 79.3335 262.993 79.3335 262.993C82.4235 261.652 85.3354 259.714 87.9749 257.599C88.551 257.138 89.1376 256.677 89.6927 256.195C96.1555 250.539 100.879 241.876 102.419 233.266C102.754 231.423 102.88 229.517 102.838 227.631C102.87 225.735 102.754 223.829 102.419 221.985Z" />
      <Paths.L30 d="M102.42 222.069C101.885 219.136 100.995 216.192 99.7801 213.364C99.8639 214.129 101.666 232.564 87.6926 245.584C73.4055 258.897 49.9322 258.813 36.8182 255.566C23.7147 252.319 9.35418 246.348 9.32275 246.338C13.5859 250.716 19.8077 255.262 28.9205 259.316C59.244 272.796 79.3759 262.971 79.3759 262.971C82.4554 261.631 85.3673 259.693 88.0068 257.587C88.5829 257.137 89.1695 256.666 89.7247 256.184C96.1664 250.538 100.89 241.886 102.43 233.287C102.765 231.443 102.891 229.537 102.849 227.672C102.87 225.808 102.755 223.902 102.42 222.069Z" />
      <Paths.L50
        evenOdd
        d="M53.4095 144.59L52.8649 144.474C52.5611 144.411 52.3726 144.118 52.4354 143.804C52.4983 143.5 52.7915 143.312 53.1058 143.375L53.6504 143.49C53.9542 143.553 54.1427 143.846 54.0799 144.16C53.9961 144.464 53.7028 144.652 53.4095 144.59Z"
      />
      <Paths.L50
        evenOdd
        d="M69.1844 144.59L68.6398 144.474C68.336 144.411 68.1475 144.118 68.2103 143.804C68.2732 143.5 68.5664 143.312 68.8702 143.375L69.4149 143.49C69.7186 143.553 69.9072 143.846 69.8443 144.16C69.771 144.464 69.4777 144.652 69.1844 144.59Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 146.548L52.8649 146.433C52.5611 146.37 52.3726 146.077 52.4354 145.763C52.4983 145.459 52.7915 145.271 53.1058 145.333L53.6504 145.449C53.9542 145.511 54.1427 145.805 54.0799 146.119C53.9961 146.433 53.7028 146.632 53.4095 146.548Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 148.527L52.8649 148.423C52.5611 148.36 52.3726 148.067 52.4354 147.752C52.4983 147.449 52.7915 147.26 53.1058 147.323L53.6504 147.438C53.9542 147.501 54.1427 147.794 54.0799 148.109C53.9961 148.402 53.7028 148.59 53.4095 148.527Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 150.497L52.8649 150.381C52.5611 150.318 52.3726 150.025 52.4354 149.721C52.4983 149.418 52.7915 149.229 53.1058 149.292L53.6504 149.407C53.9542 149.47 54.1427 149.763 54.0799 150.078C53.9961 150.36 53.7028 150.559 53.4095 150.497Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 152.455L52.8649 152.351C52.5611 152.288 52.3726 151.995 52.4354 151.68C52.4983 151.377 52.7915 151.188 53.1058 151.251L53.6504 151.366C53.9542 151.429 54.1427 151.722 54.0799 152.036C53.9961 152.33 53.7028 152.529 53.4095 152.455Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 154.425L52.8649 154.299C52.5611 154.236 52.3726 153.943 52.4354 153.629C52.4983 153.325 52.7915 153.137 53.1058 153.199L53.6504 153.304C53.9542 153.367 54.1427 153.66 54.0799 153.975C53.9961 154.31 53.7028 154.488 53.4095 154.425Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 156.405L52.8649 156.29C52.5611 156.227 52.3726 155.934 52.4354 155.619C52.4983 155.316 52.7915 155.127 53.1058 155.19L53.6504 155.305C53.9542 155.368 54.1427 155.661 54.0799 155.976C53.9961 156.269 53.7028 156.457 53.4095 156.405Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 158.364L52.8649 158.248C52.5611 158.186 52.3726 157.892 52.4354 157.589C52.4983 157.285 52.7915 157.096 53.1058 157.159L53.6504 157.274C53.9542 157.327 53.7028 158.437 53.4095 158.364Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 160.332L52.8649 160.206C52.5611 160.144 52.3726 159.85 52.4354 159.536C52.4983 159.232 52.7915 159.044 53.1058 159.107L53.6504 159.232C53.9542 159.295 54.1427 159.588 54.0799 159.892C53.9961 160.206 53.7028 160.395 53.4095 160.332Z"
      />
      <Paths.L50
        evenOdd
        d="M61.1385 139.677L60.5939 139.551C60.2901 139.488 60.1016 139.195 60.1644 138.881C60.2273 138.577 60.5205 138.388 60.8243 138.451L61.369 138.567C61.6727 138.629 61.8613 138.923 61.7984 139.237C61.7356 139.551 61.4423 139.74 61.1385 139.677Z"
      />
      <Paths.L50
        evenOdd
        d="M58.4584 140.044L57.9137 139.929C57.6099 139.866 57.4214 139.572 57.4842 139.258C57.5471 138.954 57.8404 138.766 58.1441 138.829L58.6888 138.944C58.9926 139.007 59.1811 139.3 59.1182 139.614C59.0659 139.908 58.7726 140.107 58.4584 140.044Z"
      />
      <Paths.L50
        evenOdd
        d="M55.9134 141.018L55.3688 140.902C55.065 140.84 54.8765 140.546 54.9393 140.232C55.0022 139.928 55.2954 139.74 55.6097 139.803L56.1543 139.918C56.4581 139.981 56.6466 140.274 56.5838 140.588C56.5 140.902 56.2067 141.08 55.9134 141.018Z"
      />
      <Paths.L50
        evenOdd
        d="M63.8099 140.044L63.2652 139.929C62.9615 139.866 62.7729 139.572 62.8358 139.258C62.8986 138.954 63.1919 138.766 63.5062 138.829L64.0508 138.944C64.3546 139.007 64.5431 139.3 64.4803 139.614C64.407 139.908 64.1137 140.107 63.8099 140.044Z"
      />
      <Paths.L50
        evenOdd
        d="M66.2098 141.018L65.6651 140.902C65.3614 140.84 65.1729 140.546 65.2357 140.232C65.2985 139.928 65.5918 139.74 65.8956 139.803L66.4403 139.918C66.744 139.981 66.9326 140.274 66.8697 140.588C66.8173 140.902 66.5136 141.08 66.2098 141.018Z"
      />
      <Paths.L50
        evenOdd
        d="M54.2357 142.62L53.691 142.494C53.3873 142.431 53.1987 142.138 53.2616 141.824C53.3244 141.52 53.6177 141.331 53.9215 141.394L54.4661 141.52C54.7699 141.583 54.9584 141.876 54.8956 142.19C54.8327 142.494 54.5395 142.683 54.2357 142.62Z"
      />
      <Paths.L50
        evenOdd
        d="M67.8749 142.62L67.3302 142.494C67.0264 142.431 66.8379 142.138 66.9007 141.824C66.9636 141.52 67.2569 141.331 67.5711 141.394L68.1158 141.52C68.4195 141.583 68.6081 141.876 68.5452 142.19C68.4824 142.494 68.1891 142.683 67.8749 142.62Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 162.302L52.8649 162.187C52.5611 162.124 52.3726 161.831 52.4354 161.516C52.4983 161.213 52.7915 161.024 53.1058 161.087L53.6504 161.202C53.9542 161.265 54.1427 161.558 54.0799 161.873C53.9961 162.166 53.7028 162.365 53.4095 162.302Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 164.261L52.8649 164.135C52.5611 164.072 52.3726 163.779 52.4354 163.465C52.4983 163.171 52.7915 162.972 53.1058 163.035L53.6504 163.161C53.9542 163.224 54.1427 163.517 54.0799 163.831C53.9961 164.135 53.7028 164.334 53.4095 164.261Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 166.23L52.8649 166.104C52.5611 166.041 52.3726 165.748 52.4354 165.434C52.4983 165.14 52.7915 164.941 53.1058 165.004L53.6504 165.13C53.9542 165.193 54.1427 165.486 54.0799 165.79C53.9961 166.115 53.7028 166.293 53.4095 166.23Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 168.21L52.8649 168.094C52.5611 168.032 52.3726 167.738 52.4354 167.434C52.4983 167.141 52.7915 166.942 53.1058 167.005L53.6504 167.12C53.9542 167.183 54.1427 167.476 54.0799 167.78C53.9961 168.073 53.7028 168.272 53.4095 168.21Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 170.168L52.8649 170.042C52.5611 169.979 52.3726 169.686 52.4354 169.372C52.4983 169.079 52.7915 168.88 53.1058 168.942L53.6504 169.068C53.9542 169.131 54.1427 169.424 54.0799 169.738C53.9961 170.042 53.7028 170.241 53.4095 170.168Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 172.137L52.8649 172.012C52.5611 171.949 52.3726 171.656 52.4354 171.341C52.4983 171.038 52.7915 170.849 53.1058 170.912L53.6504 171.027C53.9542 171.09 54.1427 171.383 54.0799 171.697C53.9961 172.012 53.7028 172.2 53.4095 172.137Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 174.107L52.8649 173.992C52.5611 173.929 52.3726 173.635 52.4354 173.321C52.4983 173.028 52.7915 172.829 53.1058 172.892L53.6504 173.017C53.9542 173.08 54.1427 173.374 54.0799 173.688C53.9961 173.971 53.7028 174.17 53.4095 174.107Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 176.065L52.8649 175.939C52.5611 175.876 52.3726 175.583 52.4354 175.269C52.4983 174.976 52.7915 174.777 53.1058 174.839L53.6504 174.965C53.9542 175.028 54.1427 175.321 54.0799 175.625C53.9961 175.939 53.7028 176.138 53.4095 176.065Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 178.045L52.8649 177.93C52.5611 177.867 52.3726 177.574 52.4354 177.259C52.4983 176.966 52.7915 176.767 53.1058 176.83L53.6504 176.945C53.9542 177.008 54.1427 177.301 54.0799 177.616C53.9961 177.919 53.7028 178.097 53.4095 178.045Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 180.013L52.8649 179.888C52.5611 179.825 52.3726 179.532 52.4354 179.228C52.4983 178.924 52.7915 178.736 53.1058 178.798L53.6504 178.924C53.9542 178.987 54.1427 179.28 54.0799 179.584C53.9961 179.877 53.7028 180.076 53.4095 180.013Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 181.972L52.8649 181.857C52.5611 181.794 52.3726 181.501 52.4354 181.187C52.4983 180.883 52.7915 180.694 53.1058 180.757L53.6504 180.872C53.9542 180.935 54.1427 181.229 54.0799 181.543C53.9961 181.847 53.7028 182.046 53.4095 181.972Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 183.942L52.8649 183.827C52.5611 183.764 52.3726 183.471 52.4354 183.167C52.4983 182.863 52.7915 182.675 53.1058 182.738L53.6504 182.853C53.9542 182.916 54.1427 183.209 54.0799 183.513C53.9961 183.816 53.7028 184.005 53.4095 183.942Z"
      />
      <Paths.L50
        evenOdd
        d="M53.4095 185.911L52.8649 185.786C52.5611 185.723 52.3726 185.43 52.4354 185.126C52.4983 184.822 52.7915 184.634 53.1058 184.696L53.6504 184.822C53.9542 184.885 54.1427 185.178 54.0799 185.493C53.9961 185.775 53.7028 185.974 53.4095 185.911Z"
      />
      <Paths.L50
        evenOdd
        d="M55.0954 189.336C55.0954 190.153 54.3936 190.803 53.5138 190.803C52.6339 190.803 51.9321 190.153 51.9321 189.336C51.9321 188.519 52.6339 187.87 53.5138 187.87C54.3936 187.87 55.0954 188.53 55.0954 189.336Z"
      />
      <Paths.L50
        evenOdd
        d="M54.99 192.154C54.6968 192.374 54.2778 192.301 54.0578 192.018C53.8379 191.725 53.8798 191.316 54.173 191.096L54.4244 190.897C54.7177 190.667 55.1472 190.698 55.3881 190.991C55.6185 191.285 55.5766 191.735 55.2624 191.966L54.99 192.154Z"
      />
      <Paths.L50
        evenOdd
        d="M56.552 194.344C56.2483 194.553 55.8293 194.469 55.6198 194.155C55.4103 193.851 55.4836 193.443 55.7769 193.233L56.0178 193.055C56.3216 192.835 56.7615 192.909 56.971 193.212C57.191 193.516 57.1072 193.966 56.8034 194.176L56.552 194.344Z"
      />
      <Paths.L50
        evenOdd
        d="M58.007 196.605C57.7032 196.804 57.2842 196.689 57.0852 196.385C56.8862 196.081 56.991 195.673 57.2947 195.474L57.567 195.306C57.8918 195.107 58.3107 195.181 58.4993 195.516C58.6983 195.84 58.5935 196.259 58.2793 196.448L58.007 196.605Z"
      />
      <Paths.L50
        evenOdd
        d="M59.3685 198.952C59.0438 199.141 58.6248 199.015 58.4468 198.69C58.2582 198.365 58.3734 197.967 58.6982 197.779L58.96 197.632C59.2952 197.444 59.7142 197.548 59.8922 197.884C60.0808 198.219 59.9656 198.638 59.6304 198.816L59.3685 198.952Z"
      />
      <Paths.L50
        evenOdd
        d="M60.6259 201.329C60.2908 201.486 59.9032 201.35 59.7251 201.036C59.5575 200.701 59.6728 200.313 60.0079 200.135L60.2803 199.988C60.6155 199.821 61.024 199.936 61.202 200.271C61.3906 200.606 61.2439 201.025 60.9087 201.193L60.6259 201.329Z"
      />
      <Paths.L50
        evenOdd
        d="M61.7996 203.76C61.4644 203.907 61.0768 203.76 60.9092 203.425C60.7521 203.09 60.8988 202.702 61.213 202.534L61.4958 202.398C61.831 202.241 62.2395 202.377 62.3966 202.723C62.5537 203.069 62.4071 203.477 62.0614 203.634L61.7996 203.76Z"
      />
      <Paths.L50
        evenOdd
        d="M62.8999 206.231C62.5543 206.378 62.1562 206.21 62.0096 205.875C61.863 205.53 62.0201 205.132 62.3553 204.985L62.6381 204.859C62.9837 204.713 63.3922 204.859 63.5389 205.205C63.6855 205.551 63.5284 205.959 63.1723 206.106L62.8999 206.231Z"
      />
      <Paths.L50
        evenOdd
        d="M63.8949 208.735C63.5492 208.871 63.1512 208.682 63.0255 208.337C62.8893 207.991 63.0674 207.593 63.4026 207.467L63.6854 207.352C64.0415 207.216 64.4395 207.373 64.5757 207.74C64.7119 208.096 64.5338 208.494 64.1777 208.63L63.8949 208.735Z"
      />
      <Paths.L50
        evenOdd
        d="M64.806 211.27C64.4603 211.396 64.0832 211.197 63.9575 210.851C63.8318 210.505 64.0204 210.128 64.366 210.003L64.6593 209.908C65.0154 209.783 65.4135 209.961 65.5287 210.317C65.6544 210.673 65.4658 211.071 65.1097 211.186L64.806 211.27Z"
      />
      <Paths.L50
        evenOdd
        d="M65.632 213.847C65.2759 213.962 64.9093 213.753 64.794 213.407C64.6788 213.051 64.8778 212.674 65.2235 212.569L65.5168 212.464C65.8729 212.359 66.2605 212.538 66.3652 212.904C66.4804 213.26 66.2814 213.648 65.9148 213.753L65.632 213.847Z"
      />
      <Paths.L50
        evenOdd
        d="M66.3765 216.454C66.0203 216.548 65.6537 216.329 65.549 215.983C65.4547 215.627 65.6642 215.26 66.0099 215.155L66.3136 215.072C66.6697 214.977 67.0573 215.187 67.1516 215.543C67.2458 215.899 67.0364 216.287 66.6697 216.381L66.3765 216.454Z"
      />
      <Paths.L50
        evenOdd
        d="M67.0258 219.063C66.6696 219.147 66.3135 218.927 66.2297 218.571C66.1459 218.215 66.3659 217.859 66.722 217.775L67.0258 217.701C67.3924 217.618 67.7485 217.827 67.8533 218.194C67.9371 218.56 67.7171 218.916 67.3505 219.011L67.0258 219.063Z"
      />
      <Paths.L50
        evenOdd
        d="M67.5922 221.703C67.236 221.776 66.8799 221.545 66.8066 221.168C66.7333 220.812 66.9637 220.456 67.3198 220.383L67.6236 220.31C67.9902 220.236 68.3463 220.456 68.4196 220.823C68.493 221.189 68.2625 221.546 67.8854 221.619L67.5922 221.703Z"
      />
      <Paths.L50
        evenOdd
        d="M68.0724 224.362C67.7163 224.425 67.3707 224.174 67.3078 223.807C67.245 223.451 67.4859 223.106 67.8525 223.032L68.1562 222.969C68.5228 222.907 68.879 223.137 68.9418 223.514C69.0047 223.881 68.7533 224.237 68.3867 224.3L68.0724 224.362Z"
      />
      <Paths.L50
        evenOdd
        d="M68.4705 227.034C68.1039 227.086 67.7687 226.824 67.7164 226.458C67.664 226.091 67.9154 225.756 68.282 225.703L68.6067 225.651C68.9733 225.599 69.319 225.85 69.3713 226.217C69.4237 226.583 69.1618 226.929 68.7952 226.981L68.4705 227.034Z"
      />
      <Paths.L50
        evenOdd
        d="M68.776 229.716C68.4094 229.757 68.0847 229.485 68.0532 229.108C68.0113 228.741 68.2732 228.417 68.6398 228.364L68.9645 228.322C69.3311 228.27 69.6768 228.542 69.7187 228.92C69.7606 229.297 69.4882 229.621 69.1112 229.663L68.776 229.716Z"
      />
      <Paths.L50
        evenOdd
        d="M68.9854 232.407C68.6188 232.428 68.2941 232.145 68.2731 231.778C68.2522 231.412 68.5245 231.087 68.8911 231.066L69.2158 231.045C69.5824 231.003 69.9176 231.296 69.9385 231.663C69.9595 232.03 69.6767 232.365 69.3101 232.386L68.9854 232.407Z"
      />
      <Paths.L50
        evenOdd
        d="M69.1208 235.099C68.7542 235.11 68.44 234.816 68.4295 234.46C68.419 234.094 68.7018 233.779 69.0684 233.758L69.3931 233.748C69.7597 233.737 70.0844 234.02 70.1054 234.387C70.1159 234.753 69.8226 235.078 69.456 235.089L69.1208 235.099Z"
      />
      <Paths.L50
        evenOdd
        d="M69.1526 237.801C68.786 237.801 68.4927 237.508 68.4927 237.141C68.4927 236.775 68.786 236.471 69.1526 236.461H69.4563C69.8334 236.45 70.1476 236.754 70.1476 237.12C70.1476 237.487 69.8439 237.801 69.4668 237.801H69.1526Z"
      />
      <Paths.L50
        evenOdd
        d="M69.1206 240.504C68.754 240.493 68.4503 240.179 68.4712 239.812C68.4922 239.446 68.7959 239.153 69.1625 239.163H69.4663C69.8434 239.174 70.1471 239.488 70.1262 239.854C70.1157 240.231 69.8015 240.535 69.4244 240.525L69.1206 240.504Z"
      />
      <Paths.L50
        evenOdd
        d="M68.9855 243.207C68.6189 243.186 68.3361 242.861 68.357 242.494C68.378 242.128 68.6922 241.845 69.0588 241.855L69.3626 241.866C69.7502 241.876 70.033 242.211 70.012 242.578C69.9911 242.966 69.6664 243.248 69.2893 243.217L68.9855 243.207Z"
      />
      <Paths.L50
        evenOdd
        d="M68.7653 245.888C68.3987 245.846 68.1263 245.521 68.1577 245.144C68.1996 244.777 68.5139 244.505 68.8805 244.526L69.1842 244.547C69.5718 244.589 69.8337 244.903 69.8127 245.291C69.7708 245.657 69.4461 245.94 69.069 245.909L68.7653 245.888Z"
      />
      <Paths.L50
        evenOdd
        d="M68.4719 248.569C68.1053 248.516 67.8435 248.181 67.8958 247.815C67.9482 247.448 68.2624 247.186 68.6395 247.218L68.9433 247.259C69.3099 247.301 69.5822 247.637 69.5508 248.014C69.4984 248.38 69.1737 248.653 68.7862 248.611L68.4719 248.569Z"
      />
      <Paths.L50
        evenOdd
        d="M68.0736 251.24C67.707 251.178 67.4556 250.832 67.5184 250.476C67.5708 250.109 67.9165 249.858 68.2831 249.91L68.5868 249.962C68.9534 250.015 69.2153 250.36 69.1629 250.727C69.1001 251.094 68.7544 251.356 68.3878 251.293L68.0736 251.24Z"
      />
      <Paths.L50
        evenOdd
        d="M67.6016 253.9C67.2455 253.827 66.9941 253.481 67.0674 253.125C67.1303 252.769 67.4759 252.518 67.8425 252.581L68.1463 252.633C68.5129 252.696 68.7643 253.052 68.7015 253.419C68.6281 253.785 68.2825 254.037 67.9159 253.963L67.6016 253.9Z"
      />
      <Paths.L50
        evenOdd
        d="M67.0571 256.55C66.701 256.467 66.4705 256.11 66.5543 255.754C66.6277 255.398 66.9838 255.157 67.3504 255.241L67.6542 255.304C68.0208 255.377 68.2617 255.733 68.1884 256.1C68.115 256.467 67.7484 256.707 67.3714 256.613L67.0571 256.55Z"
      />
      <Paths.L60 d="M65.5807 138.022C65.5597 138.022 65.5493 138.012 65.5283 138.012C65.1093 137.98 64.7113 137.938 64.3237 137.886C64.1666 137.865 64.0095 137.834 63.8524 137.813C63.6324 137.781 63.4229 137.739 63.2239 137.697C63.0459 137.666 62.8783 137.624 62.7107 137.582C62.5431 137.54 62.386 137.498 62.2289 137.457C62.0508 137.404 61.8727 137.362 61.7051 137.31C61.5899 137.268 61.4852 137.237 61.3699 137.195C60.7101 136.964 60.1235 136.702 59.5893 136.409C59.5264 136.378 59.4636 136.336 59.4007 136.304C59.2436 136.21 59.097 136.116 58.9503 136.022C56.6145 134.492 55.6404 132.45 55.2424 131.088C55.1062 130.617 54.4149 130.46 54.2054 130.91C53.0847 133.298 53.7969 135.477 54.3835 136.64C54.572 137.027 55.2214 138.054 55.4938 138.389C57.1068 140.379 59.9349 141.28 62.3964 141.667C63.0144 141.772 63.1611 140.84 62.5955 140.578C62.2917 140.442 62.0089 140.295 61.747 140.159H61.7365C61.5794 140.075 60.1235 139.593 60.3539 139.363C60.4691 139.258 60.9405 139.195 62.0403 139.258C62.0613 139.258 62.0822 139.258 62.1032 139.258C63.5172 139.331 64.7532 139.101 65.6854 138.829C66.1777 138.651 66.073 138.095 65.5807 138.022Z" />
      <Paths.L60 d="M76.5694 90.0701C77.4911 88.1847 77.2397 85.9327 75.9095 84.3092L74.2964 82.3295C73.3013 81.1144 71.3845 81.3344 70.6932 82.738L66.1682 91.9136C66.1787 91.8927 66.1892 91.8612 66.1996 91.8403L72.8928 78.2968C73.825 76.4114 73.5737 74.1699 72.2225 72.5359L70.6094 70.5562C69.6457 69.3831 67.8441 69.5611 67.1004 70.8285L69.2163 66.534C70.138 64.6486 69.8867 62.3966 68.5669 60.7731L66.9538 58.7934C65.9587 57.5784 64.0419 57.7983 63.3506 59.2019L55.8299 74.4108L55.8195 74.4213L64.6913 56.4366C65.6235 54.5512 65.3722 52.2992 64.0524 50.6757L62.4393 48.696C61.4442 47.481 59.5274 47.7009 58.8361 49.1045L51.2945 64.3763C51.284 64.3867 51.284 64.3972 51.2736 64.4077L44.4023 78.3178C44.3814 78.3492 44.3604 78.3701 44.3499 78.3911L33.0271 101.362C32.7547 101.864 32.5138 102.367 32.2834 102.87C31.5292 104.525 30.9741 106.274 30.7017 108.076C30.4503 109.71 30.4503 111.375 30.7017 113.009C31.5397 118.393 35.1534 123.546 39.9297 126.165C39.9402 126.144 48.3617 128.365 52.0068 127.663C55.6519 126.961 62.6802 122.667 64.4818 119.241C66.2939 115.575 66.6396 113.103 66.273 110.925L68.2945 106.819L76.5694 90.0701ZM55.7985 93.6838L57.2964 90.6462C57.2545 90.7405 57.223 90.8243 57.1811 90.9185L55.8195 93.6838C55.809 93.6943 55.7985 93.6838 55.7985 93.6838Z" />
      <Paths.L60 d="M85.5444 117.335L116.832 130.46C117.753 130.847 117.963 132.062 117.23 132.743L114.454 135.299C112.914 136.723 110.683 137.111 108.745 136.304L78.5789 123.641C77.1334 123.033 76.7982 121.127 77.9504 120.069L79.8254 118.33C81.3756 116.916 83.6066 116.529 85.5444 117.335Z" />
      <Paths.L30 d="M86.6657 118.068C82.6226 121.818 78.2757 125.254 73.6774 128.302C67.3404 132.513 60.3329 136.158 52.8123 137.635C45.124 139.133 37.2892 137.75 31.2454 132.576C27.1289 129.056 24.0913 123.871 22.8239 118.571C22.6773 117.974 22.2059 117.524 21.5984 117.44C19.6397 117.136 15.5547 115.701 13.1246 109.123C12.9675 108.683 12.6113 108.348 12.1609 108.212L9.74135 107.468C7.60456 106.808 7.39507 103.876 9.41664 102.912C9.77277 102.744 10.0451 102.441 10.1813 102.063C10.9354 99.9686 14.0359 92.647 21.7346 89.358C30.6693 85.5243 42.1912 90.2378 44.8831 92.8669C44.9355 92.9193 44.9774 92.9612 45.0402 93.0031C45.6268 93.4535 49.3871 96.3549 50.8745 99.1411C51.105 99.5601 51.503 99.8639 51.9848 99.9267C54.4882 100.293 62.4278 101.697 69.0791 105.415L86.4039 115.68C87.2732 116.183 87.3989 117.377 86.6657 118.068Z" />
      <Paths.L60 d="M10.0663 102.21L3.77119 105.132C3.14272 105.425 3.20557 106.347 3.87593 106.557L10.7786 108.683L10.0663 102.21Z" />
      <Paths.L40 d="M28.3223 87.9122L28.3432 87.8912C19.5971 87.8912 12.2545 93.296 10.3062 101.257C9.97103 102.388 9.79297 103.582 9.79297 104.818C9.79297 104.933 9.79297 105.048 9.79297 105.164C9.79297 105.289 9.79297 105.425 9.79297 105.551L9.81392 105.53C10.1596 111.679 14.915 116.633 20.9692 117.293C21.4825 117.419 22.0062 117.492 22.5299 117.492C22.6451 117.492 22.7603 117.461 22.8651 117.408C23.8497 116.916 29.5059 114.056 33.5176 110.568C34.0623 110.087 33.7061 109.196 32.9729 109.228C28.7517 109.437 24.5829 109.154 20.5083 107.761C20.0265 107.594 19.5237 107.416 19.0314 107.269C18.3506 107.059 18.2982 106.117 18.9581 105.834C21.4615 104.776 23.9021 103.655 26.1331 102.032C29.2231 99.7902 32.0721 97.203 35.3402 95.1919C37.037 94.1445 42.9132 90.7927 44.9662 92.9085C43.0075 90.9183 35.6963 87.6084 28.3223 87.9122Z" />
      <Paths.L50 d="M116.109 126.374L84.4761 112.244C76.2641 108.327 69.2462 105.08 67.4132 104.514C67.3608 104.493 67.298 104.472 67.2456 104.451C66.7847 104.231 66.3239 104.022 65.863 103.812C60.1649 101.319 54.3097 100.261 52.11 99.9367C51.5549 99.8529 51.0207 100.146 50.7903 100.659C50.1618 102.074 49.1248 104.996 49.8161 107.489C50.7484 110.83 51.6492 116.727 42.4107 120.016C41.7613 120.247 41.4261 120.969 41.667 121.629C42.2641 123.2 44.736 125.39 54.5087 123.326C54.5401 123.326 54.5715 123.316 54.5925 123.305C55.399 123.075 63.59 128.794 76.5888 126.301C83.8895 124.897 107.907 132.041 107.907 132.041C109.824 132.9 112.055 132.554 113.637 131.172L116.465 128.679C117.209 127.998 117.02 126.783 116.109 126.374Z" />
      <Paths.L10 d="M24.3112 96.5959C24.4788 97.9471 23.5046 99.1831 22.1534 99.3507C20.8022 99.5183 19.5662 98.5442 19.3986 97.1929C19.2311 95.8417 20.2052 94.6058 21.5564 94.4382C22.9181 94.2811 24.154 95.2447 24.3112 96.5959Z" />
      <Paths.L60 d="M22.9184 96.9415C22.8242 96.093 22.049 95.4855 21.1797 95.5798C21.1063 95.5902 21.0435 95.6112 20.9807 95.6217C21.3054 95.7264 21.5567 95.9987 21.5882 96.3549C21.651 96.8472 21.2949 97.2871 20.8131 97.35C20.3731 97.4023 19.9856 97.1195 19.8599 96.711C19.8075 96.8996 19.7866 97.109 19.818 97.3185C19.9227 98.1774 20.6978 98.785 21.5567 98.6802C22.4157 98.5755 23.0232 97.8004 22.9184 96.9415Z" />
      <Paths.L60 d="M67.3823 79.5016L81.9418 61.6008C83.272 59.9667 83.5234 57.7252 82.5912 55.8398L81.4599 53.5459C80.7686 52.1423 78.8518 51.9328 77.8567 53.1479L64.0514 70.106C62.7211 71.74 62.4697 73.9816 63.402 75.867L65.0779 79.2502C65.5283 80.1405 66.7433 80.2767 67.3823 79.5016Z" />
      <Paths.L60 d="M70.5051 102.639L85.0646 84.7387C86.3948 83.1151 86.6462 80.8631 85.714 78.9777L84.5828 76.6838C83.8914 75.2802 81.9746 75.0707 80.9795 76.2858L75.1034 83.5131L84.1009 72.4416C85.4207 70.8181 85.6721 68.5661 84.7399 66.6807L83.6086 64.3868C82.9068 62.9832 81.0005 62.7737 80.0054 63.9888L66.2106 80.9469C64.8803 82.5809 64.6289 84.8224 65.5612 86.7078L67.2371 90.0911C67.6875 90.971 68.8711 91.1071 69.5205 90.3634L67.1742 93.2439C65.844 94.8779 65.5926 97.1194 66.5248 99.0049L68.2007 102.388C68.6511 103.278 69.8661 103.415 70.5051 102.639Z" />
      <Paths.L60 d="M86.6774 91.2752L85.5461 88.9813C84.8443 87.5777 82.938 87.3682 81.9429 88.5833L68.1481 105.541C66.8178 107.175 66.5664 109.417 67.4987 111.302L69.1746 114.686C69.625 115.576 70.84 115.712 71.4789 114.937L86.0384 97.0362C87.3582 95.4021 87.6096 93.1606 86.6774 91.2752Z" />
      <Paths.L50 d="M53.7229 128.217C57.4309 128.343 65.2448 125.724 67.7587 122.781C72.1161 117.429 71.6657 114.475 70.8591 110.442C70.7963 109.856 70.6392 109.269 70.3564 108.714L68.7119 105.393C68.3243 104.597 67.235 104.482 66.6798 105.163L66.596 105.268C67.8425 103.728 68.0834 101.602 67.2036 99.821L65.5591 96.5006C65.1715 95.7046 64.0822 95.5894 63.527 96.2702L63.4432 96.3749C64.6897 94.8352 64.9306 92.7089 64.0508 90.9282L62.4063 87.6078C62.0187 86.8118 60.9294 86.6965 60.3742 87.3774L60.2904 87.4821C61.5369 85.9424 61.7778 83.8161 60.8979 82.0354L59.2535 78.715C58.8659 77.919 57.7766 77.8037 57.2214 78.4846L41.0803 98.3546C40.7032 98.7945 40.3575 99.224 40.0224 99.6534C38.9225 101.099 37.9903 102.681 37.3199 104.377C36.7124 105.917 36.3458 107.541 36.2306 109.196C35.8535 114.632 38.2208 120.456 42.3058 124.069C42.3058 124.059 45.6576 128.217 53.7229 128.217Z" />
      <Paths.L50 d="M71.1742 74.8398L56.6147 92.7406C55.9757 93.5157 54.7607 93.3796 54.3103 92.4892L52.6344 89.106C51.7022 87.2206 51.9536 84.979 53.2838 83.345L67.0787 66.3869C68.0737 65.1719 69.9906 65.3813 70.6819 66.7849L71.8131 69.0788C72.7453 70.9642 72.494 73.2162 71.1742 74.8398Z" />
      <Paths.L50 d="M73.3202 85.6917L58.7607 103.593C58.1217 104.368 56.9067 104.231 56.4563 103.341L54.7804 99.9579C53.8482 98.0725 54.0995 95.8309 55.4298 94.1969L69.2247 77.2388C70.2197 76.0238 72.1261 76.2332 72.8279 77.6368L73.9591 79.9307C74.8913 81.8161 74.64 84.0577 73.3202 85.6917Z" />
      <Paths.L50 d="M75.4681 96.5323L60.9086 114.433C60.2697 115.208 59.0547 115.072 58.6043 114.182L56.9283 110.799C55.9961 108.913 56.2475 106.672 57.5778 105.038L71.3726 88.0795C72.3677 86.8644 74.2845 87.0739 74.9758 88.4775L76.1071 90.7714C77.0498 92.6568 76.7879 94.9088 75.4681 96.5323Z" />
      <Paths.L50 d="M77.6141 107.373L63.0546 125.274C62.4157 126.049 61.2006 125.913 60.7502 125.022L59.0743 121.639C58.1421 119.754 58.3935 117.512 59.7237 115.878L73.5186 98.9201C74.5137 97.7051 76.4305 97.9145 77.1218 99.3181L78.2531 101.612C79.1958 103.497 78.9444 105.749 77.6141 107.373Z" />
      <Paths.L60 d="M60.1446 141.206C52.7182 140.672 50.5081 136.535 49.8482 134.272C49.7121 133.801 49.0208 133.644 48.8113 134.094C47.701 136.482 48.4132 138.661 48.9893 139.824C49.1779 140.211 49.8273 141.238 50.0996 141.573C51.7127 143.563 54.5513 144.464 57.0023 144.852C57.6203 144.956 57.7669 144.024 57.2013 143.762C56.8975 143.626 56.6147 143.479 56.3529 143.343C55.8501 143.081 56.1434 142.4 56.709 142.432C58.1231 142.537 59.359 142.275 60.2913 142.002C60.815 141.845 60.6788 141.248 60.1446 141.206Z" />
    </Thumbnail>
  );
};

export default KeepItUp;
