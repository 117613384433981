import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const PlusSizeScreen = styled.span`
  display: none;
  ${() => theme.media.S`
    display: inline;
  `}
`;

export default PlusSizeScreen;
