import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@matterapp/matter-ui';
import NotificationsPanel from './Panel';
import styled from 'styled-components';

const StyledModal = styled(Modal)({
  zIndex: 1000,
});
function NotificationsModal({ isOpen, onClose, tenantId, workspaceId }) {
  const headerProps = {
    centerOnMobile: true,
    showBorder: true,
    onClickClose: onClose,
    title: 'Notifications',
  };

  const modalProps = {
    fullScreenMobile: true,
    isOpen,
    onClose,
    size: Modal.sizes.L,
  };

  return (
    <StyledModal {...modalProps}>
      <Modal.Header.WithClose {...headerProps} />
      <Modal.Body style={{ zIndex: 1000 }}>
        <NotificationsPanel 
          tenantId={tenantId}
          workspaceId={workspaceId}
          onClickNotification={onClose}
        />
      </Modal.Body>
    </StyledModal>
  );
};

NotificationsModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default NotificationsModal;
