export const BASE = 'kudosCheers';

export const BACKGROUND = `${BASE}-background`;

export const CUP = {
  GROUP: `${BASE}-cups`,
  LEFT: `${BASE}-cup-left`,
  RIGHT: `${BASE}-cup-right`,
};

export const DASH = {
  GROUP: `${BASE}-dashes`,
  LEFT: `${BASE}-dash-left`,
  CENTER: `${BASE}-dash-center`,
  RIGHT: `${BASE}-dash-right`,
};

export const CIRCLE = {
  TOP: `${BASE}-circle-top`,
  LEFT_1: `${BASE}-circle-left-1`,
  LEFT_2: `${BASE}-circle-left-2`,
  RIGHT: `${BASE}-circle-right`,
};

export const RING = {
  LEFT: `${BASE}-ring-left`,
  RIGHT: `${BASE}-ring-right`,
  BOTTOM: `${BASE}-ring-bottom`,
};

export const PLUS = `${BASE}-plus`;