import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { propType } from 'graphql-anywhere';
import { Helmet } from 'react-helmet';
import { Resources } from '@matterapp/routing';
import { OUTBOUND_INVITATIONS_QUERY } from 'graphql-queries/queries';
import { AppLayout } from '../../../../modules';
import CompletedRequestModal from './CompletedRequestModal';
import CancelRequestModal from './CancelRequestModal';
import PendingRequestModal from './PendingRequestModal';
import UpdateInfoModal from './UpdateInfoModal';
import {
  FeedbackRequestList,
  FilterList,
  SortingBar,
} from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';
import { mixpanel } from 'libs/tracking';
import { getFollowupLink } from '@matterapp/feedback';

const FRS_EVENT = 'frs:';
const FRS_VIEW_ACTION = `${FRS_EVENT}view-modal-`;

const handleTrackEvent = {
  onViewRequestModal: mixpanel.createEventSender(
    `${FRS_VIEW_ACTION}request`
  ),
  onViewCompletedModal: mixpanel.createEventSender(
    `${FRS_VIEW_ACTION}complete`
  ),
  onViewCancelRequestModal: mixpanel.createEventSender(
    `${FRS_VIEW_ACTION}cancel-request`
  ),
  onViewUpdateInfoModal: mixpanel.createEventSender(
    `${FRS_VIEW_ACTION}update-request`
  ),
  onClickFollowUp: mixpanel.createEventSender(
    `${FRS_EVENT}follow-up-clicked`
  )
};

const VIEW_REQUEST_LABEL = 'View Request';
const VIEW_COMPLETED_LABEL = 'View Completed';
const CANCEL_REQUEST_LABEL = 'Cancel Request';

const ListHeader = styled.div`
  color: ${theme.colors.blacks[80]};
  padding: 0 ${theme.sizes.padding[60]};
  font-weight: ${theme.fontWeight.bold};
  line-height: ${theme.lineHeight.L};
  font-size: ${theme.fontSize.L};
  margin-top: ${theme.spacing.double};
  ${theme.media.S`
    margin-top: 0;
  `}
`;

const SortingBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${theme.spacing.quintuple};

  ${theme.media.S`
    justify-content: flex-start;
    padding: ${theme.spacing.single} ${theme.spacing.singleAndHalf};
  `}
`;

const SortByText = styled.div`
  display: none;
  ${theme.media.S`
    display: inline-block;
    font-size: ${theme.fontSize.S};
    line-height: ${theme.lineHeight.M};
    margin-right: ${theme.spacing.quarter};
    color: ${theme.colors.blacks[70]};
  `}
`;

const FilterAndSortContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${theme.spacing.quintuple};
`;

const ON_PAGE_LOAD_ACTION = {
  VIEW: 'view',
  UPDATE: 'update',
};

const SORT_TABS = {
  SORT_BY_RECENCY: 'Recent',
  SORT_BY_NAME: 'Name',
};

const FILTER_TABS = {
  ALL_REQUESTS: 'All Requests',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
};

const TABS_TO_STATUS_TYPES = {
  [FILTER_TABS.ALL_REQUESTS]: null,
  [FILTER_TABS.PENDING]: 'Pending',
  [FILTER_TABS.COMPLETED]: 'Completed',
  [FILTER_TABS.CANCELLED]: 'Cancelled',
};

const createTabList = (tabs) => {
  const tabsList = [];
  
  for (const i in tabs) {
    tabsList.push(tabs[i]);
  }
  return tabsList;
};

const sortTabsList = createTabList(SORT_TABS);
const filterTabsList = createTabList(FILTER_TABS);

export default class FeedbackRequestsPage extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    /* UUID from URL params */
    invitationUuid: PropTypes.string,
    outboundInvitationsData: propType(OUTBOUND_INVITATIONS_QUERY).isRequired,
    handleRevokeFeedbackInvitation: PropTypes.func.isRequired,
    handleUpdateEmailOnInvitation: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeSortTab: 0,
      activeFilterTab: 0,
      sortTabsList,
      filterTabsList,
      isCancelRequestModalOpen: false,
      isCompletedRequestModalOpen: false,
      isPendingRequestModalOpen: false,
      isUpdateInfoModalOpen: false,
      selectedInvitation: null,
    };
  }

  componentDidUpdate() {
    const { invitationUuid, outboundInvitationsData } = this.props;
    const { outboundInvitations } = outboundInvitationsData;
    const isView = window.location.pathname.includes(ON_PAGE_LOAD_ACTION.VIEW);
    const isUpdate = window.location.pathname.includes(ON_PAGE_LOAD_ACTION.UPDATE);

    if (invitationUuid && outboundInvitations) {
      const invitation = outboundInvitations.filter((invitation) => invitation.uuid === invitationUuid);
      if (!this.state.selectedInvitation) {
        this.setState({ selectedInvitation: invitation[0]});
        if (isView) {
          this.setState({ isPendingRequestModalOpen: true });
        } else if (isUpdate) {
          this.setState({ isUpdateInfoModalOpen: true });
        }
      }
    }
  }

  handleOpenCompletedRequestModal = () => {
    this.setState({ isCompletedRequestModalOpen: true });
    handleTrackEvent.onViewCompletedModal();
  }

  handleOpenPendingRequestModal = () => {
    this.setState({ isPendingRequestModalOpen: true });
    handleTrackEvent.onViewRequestModal();
  }

  handleOpenCancelRequestModal = () => {
    this.setState({ isCancelRequestModalOpen: true, isPendingRequestModalOpen: false });
    handleTrackEvent.onViewCancelRequestModal();
  }

  handleConfirmCancelRequestButton = async () => {
    const { selectedInvitation } = this.state;

    await this.props.handleRevokeFeedbackInvitation(selectedInvitation);
    this.setState({ isCancelRequestModalOpen: false });
  }

  handleOpenUpdateInfoModal = () => {
    this.setState({ isUpdateInfoModalOpen: true, isPendingRequestModalOpen: false });
    handleTrackEvent.onViewUpdateInfoModal();
  }

  handleUpdateEmail = async (email) => {
    const { selectedInvitation } = this.state;

    await this.props.handleUpdateEmailOnInvitation(selectedInvitation.id, email);
    this.setState({ isUpdateInfoModalOpen: false });
  }

  handleFollowUp = () => {
    this.setState({ isPendingRequestModalOpen: false });
  }

  createFollowupLink = (linkType) => {
    const { selectedInvitation } = this.state;

    if (selectedInvitation) {
      const { invitedPeer } = selectedInvitation;
      const { currentUser } = this.props.outboundInvitationsData;

      return getFollowupLink({ 
        to: invitedPeer.email,
        requesterFirstName: currentUser.firstName,
        giverFirstName: invitedPeer.firstName,
        linkType,
      });
    }    
  }

  handleSelectInvitation = (invitation) => () => {
    if (invitation !== this.state.selectedInvitation) {
      this.setState({ selectedInvitation: invitation });
    }
  }

  getRenderMenuList = (invitation) => () => {
    const { feedbackRequestStatus, lastCompletedFeedbackFeedItem } = invitation;
    const isCompleted = feedbackRequestStatus.type === FILTER_TABS.COMPLETED && lastCompletedFeedbackFeedItem;
    const isPending = feedbackRequestStatus.type === FILTER_TABS.PENDING;
    const viewPendingRequestMenuItem = {
      menuLabel: VIEW_REQUEST_LABEL,
      handleAction: this.handleOpenPendingRequestModal,
      rcLabel: 'view-pending-request-button',
    };
  
    const viewCompletedRequestMenuItem = {
      menuLabel: VIEW_COMPLETED_LABEL,
      handleAction: this.handleOpenCompletedRequestModal,
      rcLabel: 'view-completed-request-button',
    };
  
    const cancelRequestMenuItem = {
      menuLabel: CANCEL_REQUEST_LABEL,
      handleAction: this.handleOpenCancelRequestModal,
      rcLabel: 'cancel-request-button',
    };
  
    if (isCompleted) {
      return ([
        viewPendingRequestMenuItem,
        viewCompletedRequestMenuItem,
      ]);
    }
  
    if (isPending) {
      return ([
        viewPendingRequestMenuItem,
        cancelRequestMenuItem,
      ]);
    }
    return ([viewPendingRequestMenuItem]);
  }

  getFilteredAndSortedList() {
    const { sortTabsList, activeSortTab, filterTabsList, activeFilterTab } = this.state;
    const filterName = filterTabsList[activeFilterTab];
    const filteredList = this.filterRequestList(filterName);

    switch (sortTabsList[activeSortTab]) {
    case SORT_TABS.SORT_BY_NAME:
      return this.sortRequestsByName(filteredList);
    default:
      return filteredList;
    }
  }

  filterRequestList(filterName) {
    const { outboundInvitationsData } = this.props;
    const { outboundInvitations = [] } = outboundInvitationsData;
    const isEmpty = !outboundInvitations.length;
    const noFilter = filterName === FILTER_TABS.ALL_REQUESTS;
    if (isEmpty || noFilter) {
      return outboundInvitations;
    }
    const selectedStatusType = TABS_TO_STATUS_TYPES[filterName];
    return outboundInvitations.filter((request) => {
      return selectedStatusType === request.feedbackRequestStatus.type;
    });
  }

  createRequestListWithRequestLength() {
    const { filterTabsList } = this.state;
    const filterListWithRequestLength = [];

    for (const tabName of filterTabsList) {
      const filteredList = this.filterRequestList(tabName);
      const tab = {};
      tab.tabName = tabName;
      tab.requestLength = filteredList.length;
      filterListWithRequestLength.push(tab);
    }
    return filterListWithRequestLength;
  }

  sortRequestsByName(filteredList) {
    const requestsSortedByName =
      filteredList.slice().sort((person1, person2) => {
        const { email: email1, fullName: fullName1 } = person1.invitedPeer;
        const { email: email2, fullName: fullName2 } = person2.invitedPeer;
        const person1Name = (fullName1 || '') + email1;
        const person2Name = (fullName2 || '') + email2;
      
        if (person1Name.toLowerCase() < person2Name.toLowerCase()) {
          return -1;
        }
        return 1;
      });

    return requestsSortedByName;
  }
  
  renderFilterColumn(isMobile) {
    const { filterTabsList, activeFilterTab } = this.state;
    const filterListWithRequestLength = this.createRequestListWithRequestLength();

    return (
      <FilterList
        data-testid="filter-list"
        isMobile={isMobile}
        tabsList={filterListWithRequestLength}
        activeTab={activeFilterTab}
        activeTabName={filterTabsList[activeFilterTab]}
        onActivateTab={(activeFilterTab) => this.setState({ activeFilterTab })}
      />
    );
  }

  renderSortingBar() {
    const { sortTabsList, activeSortTab } = this.state;

    return (
      <SortingBarContainer>
        <SortByText>
          Sort by:
        </SortByText>
        <SortingBar
          data-testid="sorting-bar"
          tabsList={sortTabsList}
          activeTab={activeSortTab}
          onActivateTab={(activeSortTab) => this.setState({ activeSortTab })}
        />
      </SortingBarContainer>
    );
  }

  renderListHeaderBar() {
    const { outboundInvitationsData } = this.props;
    const { outboundInvitations = [] } = outboundInvitationsData;
    const isMobile = true;

    if (outboundInvitations.length) {
      return (
        <>
          <ListHeader>
          Your Feedback Requests
          </ListHeader>
          <FilterAndSortContainer>
            {this.renderFilterColumn(isMobile)}
            {this.renderSortingBar()}
          </FilterAndSortContainer>
        </>
      );
    }
    return null;
  }

  renderCancelRequestConfirmModal() {
    return (
      <CancelRequestModal
        isCancelRequestModalOpen={this.state.isCancelRequestModalOpen}
        handleCancelButton={() => this.setState({ isCancelRequestModalOpen: false })}
        handleConfirmButton={this.handleConfirmCancelRequestButton}
      />
    );
  }

  renderUpdateInfoModal() {
    const { selectedInvitation, isUpdateInfoModalOpen } = this.state;
    const { invitedPeer } = selectedInvitation;

    return (
      <UpdateInfoModal
        invitedPeerEmail={invitedPeer.email}
        isUpdateInfoModalOpen={isUpdateInfoModalOpen}
        handleCloseUpdateInfoModal={() => this.setState({ isUpdateInfoModalOpen: false })}
        handleUpdateEmail={this.handleUpdateEmail}
      />
    );
  }

  renderCompletedRequestModal() {
    const { selectedInvitation, isCompletedRequestModalOpen } = this.state;

    if (selectedInvitation.lastCompletedFeedbackFeedItem) {
      return (
        <CompletedRequestModal
          selectedFeedback={selectedInvitation.lastCompletedFeedbackFeedItem}
          isCompletedRequestModalOpen={isCompletedRequestModalOpen}
          handleCloseCompletedRequestModal={() => this.setState({ isCompletedRequestModalOpen: false })}
        />
      );
    }
  }

  renderPendingRequestModal() {
    const { selectedInvitation, isPendingRequestModalOpen } = this.state;
    const { outboundInvitationsData } = this.props;
    const { currentUser } = outboundInvitationsData;

    return (
      <PendingRequestModal
        currentUserFullName={currentUser && currentUser.fullName}
        selectedInvitation={selectedInvitation}
        isPendingRequestModalOpen={isPendingRequestModalOpen}
        handleClosePendingRequestModal={() => this.setState({ isPendingRequestModalOpen: false })}
        handleCancelPendingRequest={this.handleOpenCancelRequestModal}
        handleOpenUpdateInfoModal={this.handleOpenUpdateInfoModal}
        handleFollowUp={this.handleFollowUp}
        handleTrackFollowUpClick={() => handleTrackEvent.onClickFollowUp()}
        createFollowUpLink={this.createFollowupLink}
      />
    );
  }

  renderAllModals() {
    const { selectedInvitation } = this.state;
    if (selectedInvitation) {
      return (
        <>
          {this.renderPendingRequestModal()}
          {this.renderCompletedRequestModal()}
          {this.renderUpdateInfoModal()}
          {this.renderCancelRequestConfirmModal()}
        </>
      );
    }
  }

  render() {
    const { outboundInvitationsData, history } = this.props;
    const { currentUser, loading } = outboundInvitationsData;

    return (
      <>
        <Helmet>
          <title>Feedback Requests</title>
        </Helmet>
        <AppLayout
          layout={AppLayout.Layouts.TwoColumnFixedLeft}
          leftColumn={this.renderFilterColumn()}
        >
          {this.renderListHeaderBar()}
          {this.renderAllModals()}
          {!loading && (
            <FeedbackRequestList
              currentUserFirstName={currentUser && currentUser.firstName}
              feedbackRequests={this.getFilteredAndSortedList()}
              createFollowUpLink={this.createFollowupLink}
              handleFollowUp={this.handleFollowUp}
              handleUpdateInfo={this.handleOpenUpdateInfoModal}
              handleRedirectToFindYourPeersPage={() => history.push(Resources.findYourPeersConnectYourAccounts.path())}
              handleSelectInvitation={this.handleSelectInvitation}
              getRenderMenuList={this.getRenderMenuList}
            />
          )}
        </AppLayout>
      </>
    );
  }
}