import styled from 'styled-components';
import * as Base from '../Base/styles';
import theme from '@matterapp/matter-theme';

const Wrapper = styled(Base.Wrapper)`
  display: block;
  width: 327px;
`;

const Container = styled(Base.Container)`
  color: ${({theme}) => theme.colors.purples[50]};
  fontWeight: ${({theme}) => theme.fontWeight.bold};
  border: 1px solid ${() => theme.colors.blacks[10]};
  &:hover {
    border: 1px solid ${(props) => props.borderColor};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  }
  ${({selected, backgroundColor}) => selected && `
    background: ${backgroundColor};
  `
}
  &:active {
    background: ${(props) => props.backgroundColor};
  }
`;

const SkillBadgeContainer = styled(Base.SkillBadgeContainer)`
  width: 56px;
  height: 56px;
`;

const SkillName = styled(Base.SkillName)``;

const SkillPillContainer = styled(Base.SkillPillContainer)``;

export default {
  Wrapper,
  Container,
  SkillBadgeContainer,
  SkillName,
  SkillPillContainer
};