import { useEffect, useState } from 'react';
import { isValidEmail } from '../../../libs/emailValidator';

let COPY_TIMEOUT = null;

/*****************************************************************************/
/* HOOK FOR COPY LINK STATE                                                  */
/*****************************************************************************/
export const useCopyLink = () => {
  const [isCopyingLink, setIsCopyingLink] = useState('');

  useEffect(() => {
    return () => {
      clearTimeout(COPY_TIMEOUT);
    };
  }, []);

  return {
    isCopyingLink,
    onClickCopyLink: (e) => {
      setIsCopyingLink(true);
      COPY_TIMEOUT = setTimeout(() => {
        setIsCopyingLink(false);
      }, 3000);
    },
  };
};

/*****************************************************************************/
/* HOOK FOR DRAWER STATE                                                     */
/*****************************************************************************/
export const useDrawer = ({ onClickCustomize }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return {
    active: isDrawerOpen,
    closeDrawer: () => setDrawerOpen(false),
    isDrawerOpen,
    onClick: () => {
      if (!isDrawerOpen) {
        onClickCustomize();
      }
      setDrawerOpen(!isDrawerOpen);
    },
  };
};

/*****************************************************************************/
/* HOOK FOR EMAIL DROPDOWN STATE                                             */
/*****************************************************************************/
export const useDropdown = ({
  dropdownPlaceholder,
  filterItems,
  items,
  onSearchEmail,
  emptyMenuMessageNoItems,
  isLoadingResults
}) => {
  const [email, setEmail] = useState('');
  const [hasItem, setHasItem] = useState(false);

  return {
    clearDropdown: () => setEmail(''),
    email,
    emptyMenuMessageNoItems,
    filterItems,
    isValidEmail: isValidEmail(email),
    hasItem: (hasItem && filterItems) || !filterItems,
    items,
    fluid: true,
    value: email,
    onChange: (e, props) => {
      const { item, value } = props;

      setHasItem(!!item);
      setEmail(value);
    },
    isLoadingResults,
    onSearch: onSearchEmail,
    placeholder: dropdownPlaceholder || 'Enter name or email...',
  };
};

/*****************************************************************************/
/* HOOK FOR MESSAGE STATE                                                    */
/*****************************************************************************/
export const useMessage = (params) => {
  const { defaultMessage, messageMinLength, messageMaxLength } = params;
  const [message, setMessage] = useState(defaultMessage);
  const messageTooShort = message.length < messageMinLength;
  const messageTooLong = message.length > messageMaxLength;
  const isMessageValid = !(messageTooShort || messageTooLong);

  return {
    clearText: () => {
      setMessage('');
    },
    error: isMessageValid ? null : true,
    heading: 'Your message',
    hideMaxCharacterCount: !messageTooLong,
    isDefaultMessage: message === defaultMessage,
    isMessageValid,
    messageMinLength,
    messageMaxLength,
    message,
    onChange: (e, props) => {
      setMessage(props.value);
    },
    resetMessage: () => setMessage(defaultMessage),
    setMessage,
    value: message,
  };
};

/*****************************************************************************/
/* HOOK FOR SKILL SELECTION LIST STATE                                       */
/*****************************************************************************/
export const useSkillSelection = (params) => {
  const { isDrawerOpen, selectedSkillIds, userSkills } = params;
  const [isSelectedSkillsSet, setIsSelectedSKillsSet] = useState(false);
  const [skillIds, setSkillIds] = useState(skillIds || []);
  const [orderedSkills, setOrderedSkills] = useState([]);
  const [listOptions, setListOptions] = useState(isDrawerOpen);

  useEffect(() => {
    if (selectedSkillIds.length !== skillIds.length && !isSelectedSkillsSet) {
      setIsSelectedSKillsSet(true);
      setSkillIds(selectedSkillIds);
    }
  }, [selectedSkillIds]);

  useEffect(() => {
    if (!userSkills.length) {
      return;
    }
    setOrderedSkills(
      userSkills.sort((a, b) => (a.name.length > b.name.length ? 1 : -1))
    );
  }, [userSkills]);

  // delay set list options so that the user can see the animation on the user skill options.
  useEffect(() => {
    if (!isDrawerOpen) {
      setTimeout(() => {
        setListOptions(isDrawerOpen);
      }, 300);
    } else {
      setListOptions(isDrawerOpen);
    }
  }, [isDrawerOpen]);

  return {
    heading: 'Your skills',
    isAllSkillsSelected: skillIds.length === userSkills.length,
    skillIds,
    listOptions,
    orderedSkills,
    updateSelectedSkills: (skill) => {
      const { id } = skill;
      if (skillIds.includes(id)) {
        setSkillIds(skillIds.filter((skillId) => skillId !== id));
      } else {
        setSkillIds([...skillIds, id]);
      }
    },
  };
};

/*****************************************************************************/
/* HOOK FOR REQUEST FEEDBACK STATE                                           */
/*****************************************************************************/
export const useRequestFeedback = (params) => {
  const { defaultMessage, onSend } = params;
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const { clearDropdown, email, hasItem, isValidEmail, ...dropdownProps } = useDropdown(
    params
  );
  const { closeDrawer, isDrawerOpen, ...drawerProps } = useDrawer(params);
  const { isMessageValid, setMessage, ...messageProps } = useMessage(params);
  const skillsSelectionListProps = useSkillSelection({
    ...params,
    isDrawerOpen,
  });
  const { isCopyingLink, onClickCopyLink } = useCopyLink({
    ...params,
    ...skillsSelectionListProps,
    ...messageProps,
    email,
  });

  const canSend = isValidEmail && isMessageValid && hasItem;
  const hasChanges = !messageProps.isDefaultMessage || !!email;

  const handleSend = async (e) => {
    e.preventDefault();
    const { isAllSkillsSelected, skillIds } = skillsSelectionListProps;
    const { resetMessage, isDefaultMessage, message } = messageProps;
    await onSend(e, {
      ...params,
      canSend,
      email,
      isAllSkillsSelected,
      isDefaultMessage,
      isValidEmail,
      message,
      pickedPeers: [{ email }],
      skillIds,
    });
    closeDrawer();
    clearDropdown();
    resetMessage();
  };

  return {
    ...params,
    canSend,
    confirmResetModalProps: {
      isOpen: isConfirmOpen,
      onConfirm: () => {
        setConfirmOpen(false);
        setMessage(defaultMessage);
        closeDrawer();
      },
      onCancel: () => setConfirmOpen(false),
    },
    dropdownProps,
    drawerProps,
    formProps: {
      onSubmit: handleSend,
    },
    hasChanges,
    isCopyingLink,
    isDrawerOpen,
    onClickCancel: () => setConfirmOpen(true),
    onClickCopyLink,
    onClickSend: handleSend,
    messageProps,
    skillsSelectionListProps,
  };
};
