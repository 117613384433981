import React from 'react';
import PropTypes from 'prop-types';
import {
  THANK_TEXT,
  THANKED_LABEL,
  THANKS_DEFAULT_PROPS,
  THANKS_PROP_TYPE_OBJ,
} from './consts';
import { useThanks } from './hooks';
import { ThankButton, ThankedLabel } from './styles';

const Thanks = (props) => {
  const {
    isThanked,
    isThanking,
    showButton,
    thankButtonProps,
  } = useThanks(props);

  if (isThanked) {
    return <ThankedLabel isThanking={isThanking}>{THANKED_LABEL}</ThankedLabel>;
  }
  if (showButton) {
    return (
      <ThankButton
        content={THANK_TEXT}
        {...thankButtonProps}
      />
    );
  }
  return null;
};

Thanks.defaultProps = {
  ...THANKS_DEFAULT_PROPS,
  onClickThank: () => null,
};

Thanks.propTypes = {
  ...THANKS_PROP_TYPE_OBJ,
  /** First name to give thanks to. */
  recipient: PropTypes.object,
  /** Callback when thank button is clicked. */
  onClickThank: PropTypes.func,
};

export default Thanks;
