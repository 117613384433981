import styled from 'styled-components';
import { Peer, Button, IconNew, Checkbox, Input } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

export const MembersList = styled(Peer.List.WithAddRemove.Email).attrs(() => ({
  flushedEdgeOnMobile: true,
}))`
  margin: 0 -${theme.spacing.singleAndHalf};
  padding-bottom: 24px;
  & > div:last-child {
    border-bottom: none;
  }
`;

export const StyledInput = styled(Input)(() => ({
  cursor: 'pointer',
  width: '100%',
  display: 'inline-block',
  color: theme.colors.black,
  boxSizing: 'border-box',
  textAlign: 'left',
  border: `${theme.inputs.borderWidth} ${theme.inputs.borderStyle} ${theme.inputs.borderColor}`,
  borderRadius: theme.inputs.borderRadius,
  '&:focus': {
    color: theme.colors.black,
    background: 'white',
    borderColor: theme.inputs.borderActiveColor,
    boxShadow: '0px 2px 4px rgba(52, 55, 153, 0.2)',
  },
  '&::placeholder': {
    color: theme.inputs.placeholderColor
  },
  fontSize: theme.fontSize.S,
  lineHeight: theme.lineHeight.M,
  padding: theme.spacing.threeQuarters
}));

export const EditButton = styled(Button)(() => ({
  fontSize: theme.fontSize.base,
  lineHeight: theme.fontSize.base,
  padding: theme.spacing.half,
  minHeight: 0
}));

export const EditWrapper = styled.div({
  display: 'flex',
  alignItems: 'baseline'
});

export const StyledIcon = styled(IconNew)(() => ({
  marginLeft: theme.spacing.half
}));

export const SubHeader = styled.h4(() => ({
  fontWeight: 'bold',
  fontSize: theme.fontSize.S
}));

export const InputWrapper = styled.div(() => ({
  marginLeft: theme.spacing.single,
  width: '50%',
  input: {
    width: '100%',
    [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
      marginLeft: theme.spacing.half
    },
    '&:disabled': {
      color: theme.colors.blacks[50]
    }
  }
}));

export const LineWrapper = styled.div(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing.half,
  width: '100%',
  button: {
    alignSelf: 'center'
  }
}));

export const MemberSettingWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing.double,
  width: '100%',
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    padding: theme.spacing.single
  }
}));

export const MemberInfo = styled.p(() => ({
  fontWeight: 500,
  fontSize: theme.fontSize.S
}));

export const BirthdayWrapper = styled.div(() => ({
  display: 'flex',
  marginLeft: theme.spacing.single,
  width: '50%',
  '> div:first-child': {
    width: '80%',
    marginRight: theme.spacing.half
  },
  '> div:nth-child(2)': {
    width: '35%'
  }
}));

export const Divider = styled.div(() => ({
  borderTop: `1px solid ${theme.colors.blacks[10]}`,
  margin: `${theme.spacing.single} 0`
}));

export const RadioList = styled(Checkbox.RadioList.Settings)(() => ({
  [`& ${Checkbox.RadioList.Label}`]: {
    lineHeight: theme.spacing.single,
    fontWeight: 'bold'
  }
}));

export const ClearButton = styled.div(() => ({
  position: 'absolute',
  color: 'red',
  right: '-19px',
  top: '20%',
  cursor: 'pointer',
  fontSize: '17px',
  fontWeight: 'bold'
}));
