import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M 24 12C 24 16.1647 21.8784 19.8338 18.6571 21.9857L 16.7155 19.0732C 18.9972 17.549 20.5 14.95 20.5 12C 20.5 7.30557 16.6944 3.5 12 3.5C 7.30557 3.5 3.5 7.30557 3.5 12C 3.5 14.95 5.00278 17.549 7.28455 19.0732L 5.3429 21.9857C 2.12158 19.8338 0 16.1647 0 12C 0 5.37259 5.37259 0 12 0C 18.6274 0 24 5.37259 24 12ZM 12 17.5C 15.0376 17.5 17.5 15.0376 17.5 12C 17.5 8.96243 15.0376 6.5 12 6.5C 8.96243 6.5 6.5 8.96243 6.5 12C 6.5 15.0376 8.96243 17.5 12 17.5Z" transform="translate(0 1)" fill={fill} />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
