import React from 'react';
import { compose } from 'recompose';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import SkillsPage from 'routes/pages/skills/components/SkillsPage';
import SkillDetailsPage from 'routes/pages/skills/components/SkillDetailsPage';
import { withPageViewTracking } from '../../hocs';
import { Route } from 'react-router-dom';

const skillsMainHoc = withPageViewTracking({
  pageTitle: 'skills/main',
});
const SkillsMainComponent = skillsMainHoc(SkillsPage);

export const SkillsMain = (
  <Route
    exact
    path={Resources.skillsMain.pattern}
    element={(
      <PrivateRoute>
        <SkillsMainComponent />
      </PrivateRoute>
    )}
  />
);

const skillDetailsHoc = compose(
  withPageViewTracking({
    pageTitle: 'skills/details'
  })
);

const SkillDetailsComponent = skillDetailsHoc(SkillDetailsPage);

export const SkillDetails = (
  <Route
    exact
    path={Resources.skillDetails.pattern}
    element={(
      <PrivateRoute>
        <SkillDetailsComponent />
      </PrivateRoute>
    )}
  />
);
