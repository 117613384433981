import styled from 'styled-components';
import { AppFooter } from '../../../Footer';
import NavBar from '../../../NavBar';
import {
  DATA_ROLE as AvatarCardDataRole,
} from '../../../AvatarCard';
import { getPixelValue } from './utils';
import {
  border,
  colors,
  createDataRole,
  font,
  media,
  spacing,
  transitions,
  zIndex,
} from '@matterapp/matter-theme';

const MAIN_COLUMN_MAX_WIDTH = 608;
const SIDE_COLUMN_MAX_WIDTH = 358;
const CONTENT_COLUMN_WIDTH =
  MAIN_COLUMN_MAX_WIDTH + SIDE_COLUMN_MAX_WIDTH + getPixelValue(spacing.triple);

const HEADER_MIN_WIDTH = 200;
const HEADER_MAX_WIDTH = 280;
const HEADER_MOBILE_MAX_HEIGHT = 56;
const HEADER_RESPONSIVE_BREAKPOINT = CONTENT_COLUMN_WIDTH + HEADER_MIN_WIDTH;

const TABLET_CONTAINER_HEIGHT = `calc(100vh - ${spacing.triple} - ${spacing.triple} - ${HEADER_MOBILE_MAX_HEIGHT}px)`;
const DESKTOP_CONTAINER_HEIGHT = `calc(100vh - ${spacing.triple})`;
const DESKTOP_MAX_WIDTH = HEADER_MAX_WIDTH + CONTENT_COLUMN_WIDTH;
const SIDEBAR_DOCK_TOP_PADDING = 24;
const CONTAINER_BORDER_RADIUS = border.radius.L;
const TRANSITION_TIME = transitions.times.double;

export const isMainHeader = (header) => !!header && header.type === NavBar.Main;
export const getDockedTopStyles = ({ top }) => {
  const topSpacing = 0;
  return top ? `calc(${top}px - ${topSpacing})` : topSpacing;
};

export const Page = styled.div`
  background: ${colors.blacks[5]};
  padding: ${({ isMainHeader }) =>
    isMainHeader ? `0 0 ${NavBar.Main.BottomNavBarSpacing}` : '0'};
  width: 100%;

  ${media.L`
    padding: 0;
  `}
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 auto;
  max-width: 100%;
  transition: all ${TRANSITION_TIME};
  overflow: visible;

  ${media.S`
    overflow: visible;
  `}

  ${media.L`
    justify-content: center;
    overflow: visible;
    margin: 0 auto;
    flex-direction: row;
  `}

  ${media.XXL`
    max-width: ${DESKTOP_MAX_WIDTH}px;
    padding: ${spacing.singleAndHalf} 0;
    width: calc(100vw - ${spacing.triple});
  `}
`;

export const SideSubcontentContainer = styled.div`
  margin-top: -${spacing.triple};
  //TODO: Move below code to Advisors Page once old layout is deprecated
  .advisor-page-suggestions {
    ${media.M`
      box-shadow: none;
    `}
  }
  ${media.M`
    margin-top: -${spacing.double};
  `}
`;

export const FooterContainer = styled.div`
  & ${AppFooter.DataRoleSelector} {
    background: none !important;
  }

  ${media.M`
    & > * {
      box-shadow: none;
    }
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: 0px 0px ${spacing.singleAndHalf};
  transition: max-width ${transitions.times.default}, padding ${
  transitions.times.default
};

  & > ${SideSubcontentContainer} {
    order: 2;
  }

  & > ${FooterContainer} {
    order: 3;
  }

  ${media.M`
    flex: 1 1 100%;
    flex-direction: row;
    max-width: 100%;
    overflow-x: visible;
    padding: ${spacing.singleAndHalf} ${spacing.singleAndHalf} ${spacing.quintuple};

    & > ${FooterContainer} {
      display: ${({ alwaysShowFooter }) => alwaysShowFooter ? 'static' : 'none'};
    }
    & > ${SideSubcontentContainer} {
      display: none;
    }
  `}

  ${media.L`
    flex: 1 1 ${CONTENT_COLUMN_WIDTH}px;
    flex-direction: row;
    max-width: 100%;
    padding: 0;
  `}

  ${media.XXL`
    max-width: ${CONTENT_COLUMN_WIDTH}px;
  `}
`;

/*****************************************************************************/
/* MAIN: HEADER STYLES                                                       */
/*****************************************************************************/
export const HeaderContainer = styled.div`
  flex: 1 1 auto;
  position: sticky;
  top: 0;
  height: 0;
  width: 100%;
  z-index: ${zIndex.navBar};

  ${media.L`
    min-height: 100vh;
    min-width: ${HEADER_MIN_WIDTH}px;
    max-width: ${HEADER_MIN_WIDTH}px;
    height: fit-content;
    position: sticky;
    overflow: hidden;
    top: ${({ top }) => top}px;
    width: auto;
    border-right: ${({ noSpacing }) => noSpacing ? `2px solid ${colors.blacks[10]}` : ''};
    & > * {
      border-radius: 0;
      min-height: 100vh;
      transition: border-radius ${TRANSITION_TIME};
    }
  `}

  ${media.breakpoint(HEADER_RESPONSIVE_BREAKPOINT)`
    flex: 1 1 100%;
    min-width: ${HEADER_MIN_WIDTH}px;
    max-width: ${HEADER_MAX_WIDTH}px;
    transition: min-width ${TRANSITION_TIME}, max-width ${TRANSITION_TIME};
  `}

  ${media.XL`
    min-width: ${HEADER_MAX_WIDTH}px;
    max-width: ${HEADER_MAX_WIDTH}px;
    border-right: ${({ noSpacing }) => noSpacing ? `2px solid ${colors.blacks[10]}` : ''};
  `}

  ${media.XXL`
    border-radius: ${spacing.threeQuarters};
    min-height: ${DESKTOP_CONTAINER_HEIGHT};
    top: ${({ top }) => top ? `calc(${top}px - ${spacing.singleAndHalf})` : spacing.singleAndHalf};
    overflow: hidden;
    border-right: none;
    & > * {
      border-radius: ${CONTAINER_BORDER_RADIUS};
      min-height: ${DESKTOP_CONTAINER_HEIGHT};
    }
  `}
`;

/*****************************************************************************/
/* MAIN: MAIN CONTAINER STYLES                                               */
/*****************************************************************************/
export const MainContainer = styled.div`
  width: 100%;
  margin: 0 0 ${spacing.triple} 0;
  order: 1;
  transition: margin ${transitions.times.default};

  ${media.M`
    margin: 0 ${spacing.singleAndHalf} 0 0;

    & > *:last-child {
      margin-bottom: 0 auto !important;
    }
    & > *:only-child {
      margin: 0 auto !important;
    }
  `}

  ${media.L`
    margin: ${({ noSpacing }) =>  noSpacing ? '0' : spacing.singleAndHalf};
  `}

  ${media.XL`
    margin-top: ${({ noSpacing }) => noSpacing ? '0 !important' : spacing.singleAndHalf};
    margin-bottom: ${({ noSpacing }) => noSpacing ? '0' : spacing.singleAndHalf}
    margin-right: auto;
    margin-left: ${({ showRightSideColumn, noSpacing }) => showRightSideColumn ? 'auto' : noSpacing ? '0' : spacing.singleAndHalf};
  `}

  ${media.XXL`
    margin: 0 ${spacing.singleAndHalf};
  `}
`;

export const MainWrapper = styled.div`
  height: 100%;
  transition: max-width ${transitions.times.default};
  ${media.L`
    max-width: ${({ showRightSideColumn }) => showRightSideColumn ? `${MAIN_COLUMN_MAX_WIDTH}px` : `100%`}
  `}

  ${media.breakpoint(HEADER_RESPONSIVE_BREAKPOINT)`
    min-width: ${({ showRightSideColumn }) => showRightSideColumn ? `${MAIN_COLUMN_MAX_WIDTH}px` : `100%`}
    max-width: ${({ showRightSideColumn }) => showRightSideColumn ? `${MAIN_COLUMN_MAX_WIDTH}px` : `100%`};
  `}


`;

/*****************************************************************************/
/* MAIN: SIDEBAR STYLES                                                      */
/*****************************************************************************/
export const SideWrapper = styled.div`
  & ${createDataRole(AvatarCardDataRole)} {
    padding-top: 0;
    margin: 0 0 ${spacing.singleAndHalf};

    &:before {
      height: ${spacing.triple};
      top: -${spacing.half};
    }

    &[data-rc="${AvatarCardDataRole}"] h1 {
      font-size: ${font.size.XL};
      font-weight: ${font.weight.black};
    }
  }

  ${media.S`
    & ${createDataRole(AvatarCardDataRole)} {
      padding-top: ${spacing.triple};
      margin: 0 0 ${spacing.singleAndHalf};

      &:before {
        display: block;
      }
      &[data-rc="${AvatarCardDataRole}"] h1 {
        margin: ${spacing.single} 0;
      }
    }
  `}
  ${media.M`
    & > * {
      box-shadow: none;
    }
    & ${createDataRole(AvatarCardDataRole)} {
      padding-top: ${spacing.quintuple};

      &:before {
        display: none;
      }
      &[data-rc="${AvatarCardDataRole}"] h1 {
        margin: ${spacing.singleAndHalf} 0;
      }
    }
  `}
`;

export const SideColumn = styled.div`
  & > ${SideSubcontentContainer} {
    display: none;
  }
  ${media.M`
    & > ${SideSubcontentContainer} {
      display: block;
    }
  `}
`;

export const SideContainer = styled.div`
  border-radius: 0;
  display: ${({ showOnMobile }) => (showOnMobile ? 'static' : 'none')};
  order: ${({ firstOnMobile }) => (firstOnMobile ? 0 : 2)};
  transition: border-radius ${TRANSITION_TIME};
  width: 100%;

  & > ${SideSubcontentContainer} {
    display: none;
  }

  & > ${FooterContainer} {
    display: none;
  }

  ${media.M`
    background: ${colors.white};
    border-radius: ${CONTAINER_BORDER_RADIUS};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    min-height: ${TABLET_CONTAINER_HEIGHT};
    order: 2;
    min-width: ${SIDE_COLUMN_MAX_WIDTH}px;
    max-width: ${SIDE_COLUMN_MAX_WIDTH}px;
    width: 100%;

    position: sticky;
    top: 0;

    & ${AppFooter.DataRoleSelector} {
      background: none !important; 
    }

    & > ${SideSubcontentContainer} {
      display: none;
    }
      
    & > ${FooterContainer} {
      display: block;
    }
  `}

  ${media.L`
    border-radius: 0;
    min-height: 100vh;
    top: ${({ top }) => top}px;
  `}

  ${media.XXL`
    min-height: ${DESKTOP_CONTAINER_HEIGHT};
    border-radius: ${CONTAINER_BORDER_RADIUS};
    top: ${({ top }) => top || SIDEBAR_DOCK_TOP_PADDING}px;
  `}
`;

/*****************************************************************************/
/* FIXED TWO COLUMN LAYOUT STYLES                                            */
/*****************************************************************************/
export const TwoColumnContainer = styled.div`
  flex: 1 1 100%;
  max-width: 100vw;

  ${media.L`
    flex: 1 1 ${CONTENT_COLUMN_WIDTH}px;
    max-width: ${CONTENT_COLUMN_WIDTH}px;
    min-height: 100vh;
    overflow-y: visible;
  `}

  ${media.breakpoint(HEADER_RESPONSIVE_BREAKPOINT)`
    max-width: ${CONTENT_COLUMN_WIDTH}px;
    min-width: ${CONTENT_COLUMN_WIDTH}px;
  `}

  ${media.XL`
    border-left: none;
    min-height: ${DESKTOP_CONTAINER_HEIGHT};
    max-width: 100%;
    height: auto;
  `}

 ${media.XXL`
    min-height: ${DESKTOP_CONTAINER_HEIGHT};
    max-width: 100%;
    height: auto;
  `}
`;

export const TwoColumnContentContainer = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  min-width: 375px;
  min-height: calc(100vh - ${HEADER_MOBILE_MAX_HEIGHT}px - ${spacing.triple});
  padding: ${spacing.single} ${spacing.single} ${spacing.quintuple};

  ${media.S`
    padding: 0px ${spacing.single} ${spacing.quintuple};
  `}

  ${media.L`
    min-height: 100vh;
    padding: ${spacing.singleAndHalf};
    border-left: 2px solid ${colors.blacks[10]};
    max-width: calc(${CONTENT_COLUMN_WIDTH}px - ${spacing.eighth});
  `}

  ${media.XL`
    max-width: 100%;
  `}

  ${media.XXL`
    border-left: none;
    border-radius: ${CONTAINER_BORDER_RADIUS};
    min-height: ${DESKTOP_CONTAINER_HEIGHT};
    margin-left: ${spacing.singleAndHalf};
    max-width: 100%;
  `}
`;

export const TwoColumnContentContainerWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  ${media.S`
    flex-direction: row;
  `}
`;

export const TwoColumnFilterContainer = styled.div`
  display: ${({ hideFilterColumn }) => (hideFilterColumn ? 'none' : 'block')};
  ${media.S`
    display: block;
    min-width: 224px;
    margin-bottom: 0;
  `}
`;

export const TwoColumnFilterWrapper = styled.div`
  margin-bottom: ${spacing.double};

  & nav {
    transform: translateY(-${spacing.threeQuarters});
  }

  ${media.S`
    margin-left: 0;
    margin-bottom: 0;
    position: sticky;
    top: ${spacing.singleAndHalf};
  `}

  ${media.XL`
    top: ${spacing.triple};
  `}
`;

export const TwoColumnChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  ${media.S`
    padding-left: ${spacing.double};
  `}
`;
