import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tab from './Tab';
import {
  GettingStartedIcon,
  GettingStartedIconContainer,
  GettingStartedLabel,
  GettingStartedProgressContainer,
  GettingStartedProgressBar,
  Text,
} from './styles';
import theme from '@matterapp/matter-theme';

const TITLE = 'Getting Started';

const GettingStartedTab = styled(Tab)`
  ${({ isActive }) => `
    display: ${isActive ? 'none' : 'block'};
    & > a {
      display: flex !important;
      align-items: center;
    }
    `}
  ${theme.media.L`
    display: block;
    & ${Text} {
      overflow: visible;
    }
  `}
`;

const GettingStarted = (props) => {
  const { isActive, onClick, percentComplete, to } = props;
  return (
    <GettingStartedTab
      hideIconOnMobile
      isActive={isActive}
      icon={
        <GettingStartedIconContainer isActive={isActive}>
          <GettingStartedIcon />
        </GettingStartedIconContainer>
      }
      label={
        <GettingStartedLabel>
          <div>{TITLE}</div>
          <GettingStartedProgressContainer isActive={isActive}>
            <GettingStartedProgressBar width={percentComplete} />
          </GettingStartedProgressContainer>
        </GettingStartedLabel>
      }
      onClick={onClick}
      to={to}
    />
  );
};

GettingStarted.defaultProps = {
  percentComplete: 10,
};

GettingStarted.propTypes = {
  percentComplete: PropTypes.number,
};

export default GettingStarted;
