import React from 'react';
import PropTypes from 'prop-types';
import { Skill, ModalNew as Modal } from '@matterapp/matter-ui';
import {
  SKILL_DESCRIPTION_MAX_LENGTH,
  SKILL_NAME_MAX_LENGTH,
  skillColorPalettePropType,
} from '@matterapp/skills';
import { SKILL_GENERATOR_FORM_FIELD_NAMES } from '../consts';
import {
  TextareaField,
  InputField,
  ColorPaletteField,
} from '../../../form-fields';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  LeftButton,
  RightButton
} from './CustomSkillEditorStyles';

class CustomSkillEditor extends React.PureComponent {
  renderSkillNameField = () => (
    <InputField
      name={SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_NAME}
      label="Skill Name*"
      placeholder="Ex: Authentic"
      maxLength={SKILL_NAME_MAX_LENGTH}
    />
  );

  renderSkillDescriptionField = () => (
    <TextareaField
      name={SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_DESCRIPTION}
      label="Skill Description"
      placeholder="Ex: When our actions and words are congruent with our beliefs and values."
      maxLength={SKILL_DESCRIPTION_MAX_LENGTH}
      rows={3}
    />
  );

  renderColorPicker = () => (
    <ColorPaletteField
      name={SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_COLOR_PALETTE}
    />
  );

  renderColorPreview = () => (
    <Skill.Generator.IconPreview
      selectedColorPalette={this.props.currentColorPalette}
    />
  );

  renderModalBody = () => (
    <ModalBody>
      <form onSubmit={this.props.handleSubmit}>
        <Skill.Generator.EditorLayout
          skillNameField={this.renderSkillNameField()}
          skillDescriptionField={this.renderSkillDescriptionField()}
          skillColorPicker={this.renderColorPicker()}
          skillColorPreview={this.renderColorPreview()}
        />
      </form>
    </ModalBody>
  );

  renderModalHeader = () => {
    const {
      onClickBack,
      modalTitle,
      onClickClose,
    } = this.props;
    return (
      <ModalHeader
        bordered
        left={<Modal.Header.Buttons.Back onClick={onClickBack} />}
        right={<Modal.Header.Buttons.Close onClick={onClickClose} fill="#343799"/>}
        title={modalTitle}
      />
    );
  };

  renderModalFooter = () => {
    const {
      onClickClose,
      handleSubmit,
      submitting,
      invalid,
      pristine,
      isDisabledIfPristine,
      primaryButtonText } = this.props;
    return (
      <ModalFooter>
        <LeftButton
          color="black"
          size="M"
          onClick={onClickClose}
        >
          Cancel
        </LeftButton>
        <RightButton
          primary
          color="green"
          size="small"
          disabled={
            invalid || submitting || (isDisabledIfPristine && pristine)
          }
          rcLabel="custom-skill-save"
          onClick={handleSubmit}
        >
          {primaryButtonText}
        </RightButton>
      </ModalFooter>
    );
  }

  render = () => (
    <React.Fragment>
      {this.renderModalHeader()}
      {this.renderModalBody()}
      {this.renderModalFooter()}
    </React.Fragment>
  );
}

CustomSkillEditor.defaultProps = {
  currentColorPalette: null,
  isDisabledIfPristine: false,
};

CustomSkillEditor.propTypes = {
  currentColorPalette: skillColorPalettePropType,
  modalTitle: PropTypes.string.isRequired,
  primaryButtonText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isDisabledIfPristine: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onClickClose: PropTypes.func,
};

export default CustomSkillEditor;
