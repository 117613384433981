import React from 'react';
import { 
  Container, 
  CloseIconContainer,
  CloseIcon,
  Link, 
  LinkContainer 
} from './styles';
import { Resources } from '@matterapp/routing';
import { HELP_CENTER_URL } from 'app-consts/urls';
import { useNavigate } from 'react-router-dom';

function getProfileLinks(onClickOpenProfileModal, navigate) {
  return [
    {
      label: 'Profile',
      onClick: onClickOpenProfileModal,
    },
    {
      label: 'Request Status',
      onClick: () => navigate(Resources.feedbackRequestsMain.path()),
    },
    {
      label: 'Account Settings',
      onClick: () => navigate(Resources.userSettingsMain.path()),
    },
    {
      label: 'Referral',
      onClick: () => navigate(Resources.referralLink.path()),
    },
    {
      label: 'Help Center',
      href: HELP_CENTER_URL,
      linkProps: {
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    },
    {
      label: 'Sign Out',
      onClick: () => {
        navigate(Resources.authSignout.path({}, { notification: true }));
      },
    },
  ];
}
// This is the mobile version of the user settings drawer
function renderLinks({ onClickClose, onClickOpenProfileModal }, navigate) {
  return getProfileLinks(onClickOpenProfileModal, navigate).map((link, index) => {
    const { href, label, linkProps = {}, to, onClick } = link;

    return (
      <Link
        id={`profile-link-${label}`}
        key={`profile-link-${index}`}
        href={href}
        to={to}
        onClick={onClick || onClickClose}
        {...linkProps}
      >
        {label}
      </Link>
    );
  });
}
// This is the mobile slide out menu
function HeaderSlideOutMenu(props) {
  const { children, isOpen, onClickClose } = props;
  const navigate = useNavigate();

  return (
    <Container isOpen={isOpen} onClickClose={onClickClose}>
      <CloseIconContainer onClick={onClickClose}>
        <CloseIcon />
      </CloseIconContainer>
      {children}
      <LinkContainer>{renderLinks(props, navigate)}</LinkContainer>
    </Container>
  );
};

HeaderSlideOutMenu.defaultProps = {
  profileLinks: [],
};

export default HeaderSlideOutMenu;
