import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 24px;

  a {
    color: ${() => theme.colors.blacks[60]};
    font-size: 14px;
    line-height: 20px;
    ${() => theme.media.medium`
      line-height: 24px;
      font-size: 17px;
    `}
  }

  a:hover {
    color: ${() => theme.colors.blues[40]};
  }
  a:active {
    color: ${() => theme.colors.blues[60]};
  }
`;

const CommentCountContainer = class extends React.Component {
  renderHelperText = () => {
    const { commentCount } = this.props;
    const sentence =
      commentCount > 1 ? `View ${commentCount} comments` : 'View 1 comment';
    return <React.Fragment>{sentence}</React.Fragment>;
  };

  render() {
    const { commentCount, onClick, path } = this.props;
    if (commentCount === 0) { return null; }
    return (
      <Container onClick={onClick}>
        <Link to={path}>{this.renderHelperText()}</Link>
      </Container>
    );
  }
};

CommentCountContainer.propTypes = {
  commentCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  path: PropTypes.string,
};

export default CommentCountContainer;
