import React from 'react';
import PropTypes from 'prop-types';
import { Confirm } from '@matterapp/matter-ui';

export default function RemoveAllowanceModal(props) {
  const { isOpen, onClickCancel, onClickRemove, fullName } = props;

  return (
    <Confirm
      header="Remove Custom Allowance"
      subHeader={`
      You are removing the custom allowance for ${fullName}. After removal, they will revert to the default allowance.
      `}
      cancelButtonLabel="Cancel"
      confirmButtonLabel="Yes, Remove"
      confirmButtonIsDestructive
      isOpen={isOpen}
      onClickCancel={onClickCancel}
      onClickConfirm={onClickRemove}
    />
  );
}

RemoveAllowanceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickRemove: PropTypes.func,
  fullName: PropTypes.string
};
