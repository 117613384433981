export const BASE = 'kudosImpressive';

export const CUBE_3D = {
  SHAPE: `${BASE}-cube-3d`,
  SECTION_1: `${BASE}-cube-3d-section-1`,
  SECTION_2: `${BASE}-cube-3d-section-2`,
};

export const CUBE_2D = `${BASE}-cube-2d`;
export const CUBE_2D_COVER = `${BASE}-cube-2d-cover`;

export const BACKGROUND = `${BASE}-background`;

export const CIRCLE = {
  LEFT_1: `${BASE}-circle-left-1`,
  LEFT_2: `${BASE}-circle-left-2`,
  TOP: `${BASE}-circle-top`,
};

export const RING = {
  LEFT: `${BASE}-ring-left`,
  RIGHT: `${BASE}-ring-right`,
};

export const PLUS = `${BASE}-plus`;
