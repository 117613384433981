import { useState } from 'react';
import { isValidEmail } from '../../../form-validations';
import { useLazyQuery } from '@apollo/client';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import { getDropdownInviteMessage } from 'hooks/composer/shared';
/*****************************************************************************/
/* HOOK FOR SELECTING A SKILL                                                */
/*****************************************************************************/
export const useSelectSkill = (params) => {
  const { skills } = params;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState();
  const formattedSkills = skills.map((skill) => ({ skill }));

  return {
    isOpen,
    onClose: () => setIsOpen(false),
    onSelectSkill: (e, props) => setSelectedSkill(props.selectedSkill),
    selectedSkill,
    skills: formattedSkills,
    removeSkill: () => {
      setSelectedSkill(null);
    },
  };
};

/*****************************************************************************/
/* HOOK FOR SELECTING USER                                                   */
/*****************************************************************************/
export const useSelectUserDropdown = (params) => {
  const { onSelectUser, workspaceId, membersWithRecurring = [], workspace } = params;
  const { slackWorkspace } = workspace || {};
  const [value, setValue] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [searchMembers, { loading: isLoadingResults, data }] = useLazyQuery(searchWorkspaceMembers);
  const memberIdsWithRecurring = new Set(membersWithRecurring.map(({ id }) => id));
  const primaryChannel = slackWorkspace?.primaryChannel;
  function formatMembers() {
    const members = data?.searchMembers || [];

    return members
      .filter(({ id }) => !memberIdsWithRecurring.has(id))
      .map(({ id, person }) => {
        return {
          id,
          label: person.fullName,
          subLabel: person.email,
          value: person.email,
          ...person,
          person
        };
      });
  }

  return {
    formatTagLabel: (value) => {
      const userObject = formatMembers().find(({ person }) => person.email === value) || {};
      return userObject.person.fullName;
    },
    isValidValue: (email) => {
      return (
        isValidEmail(email) && !!formatMembers().find(({ person }) => person.email === email)
      );
    },
    onChange: (e, props) => {
      setValue(props.value);
      if (!props.value.length) {
        setSelectedUser({});
      }
    },
    onSearch: (value) => {
      if (value) {
        searchMembers({
          variables: {
            workspaceId,
            searchString: value,
            excludeCurrentUser: true
          }
        });
      }
    },
    emptyMenuMessageNoItems: getDropdownInviteMessage(primaryChannel),
    onSelect: (e, props) => {
      const user = props.item || {};
      onSelectUser(e, { ...props, user });
      setSelectedUser(user);
    },
    hasSelectedUser: !!selectedUser?.userId,
    items: formatMembers(),
    removeSelectedUser: () => {
      setValue([]);
      setSelectedUser({});
    },
    isLoadingResults,
    selectedUser,
    value,
    placeholder: 'Select a member',
    isInitiallyPristine: true,
    showValuesAsSubLabels: false,
    formatSearchResults: formatMembers
  };
};

/*****************************************************************************/
/* HOOK FOR GIVE FEEDBACK STATE                                              */
/*****************************************************************************/
export const useGiveFeedbackModal = (params) => {
  const { onSend } = params;
  const {
    hasSelectedUser,
    removeSelectedUser,
    selectedUser,
    ...selectUserDropdownProps
  } = useSelectUserDropdown(params);

  const {
    hasSelectedSkill,
    openTagSkillModal,
    selectedSkill,
    removeSkill,
    ...tagSkillModalProps
  } = useSelectSkill(params);

  const resetState = () => {
    removeSelectedUser();
    removeSkill();
  };

  return {
    hasChanges: hasSelectedUser || hasSelectedSkill,
    hasSelectedUser,
    onClickSend: (message) => {
      onSend({}, {
        ...params,
        message,
        note: message,
        receiverUserId: selectedUser?.userId,
        resetState,
        selectedUser,
        selectedSkill,
        skillId: selectedSkill?.id,
      });
    },
    selectedSkill,
    selectedUser,
    resetState,
    selectUserDropdownProps,
    skillTaggerProps: {
      isEmpty: !selectedSkill?.id,
      onClick: openTagSkillModal,
      onReset: removeSkill,
      skill: selectedSkill,
    },
    tagSkillModalProps,
  };
};
