import React from 'react';
import { SVG, Path } from './consts';

const Icon = (props) => (
  <SVG {...props}>
    <Path fillRule="evenodd" clipRule="evenodd" d="M11.998 4.1466C12.263 3.95113 12.6244 3.95113 12.8894 4.1466L20.5809 9.82085C21.1639 10.2509 20.8597 11.1757 20.1353 11.1757H19.2671V18.8493C19.2671 19.2639 18.931 19.6 18.5164 19.6H14.5754V14.3793C14.5754 13.9647 14.2393 13.6286 13.8246 13.6286H11.0607C10.646 13.6286 10.3099 13.9647 10.3099 14.3793V19.6H6.3685C5.95389 19.6 5.61778 19.2639 5.61778 18.8493V11.1757H4.75216C4.02771 11.1757 3.72351 10.2509 4.30649 9.82085L11.998 4.1466Z" fill="#898BC9" />
  </SVG>
);

export default Icon;




