import React from 'react';
import Base from './Base';
import Header from './Header';
import Footer from './Footer';
import Block from './Block';
import Divider from './Divider';
import Group from './Group';
import Paragraph from './Paragraph';

const Panel = () => {
  return <div />;
};
Panel.Base = Base;
Panel.Header = Header;
Panel.Footer = Footer;
Panel.Block = Block;
Panel.Divider = Divider;
Panel.Group = Group;
Panel.Paragraph = Paragraph;

export default Panel;
