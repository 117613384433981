import styled from 'styled-components';
import Button from '../../../Button';
import { Clickable } from '../../../Clickable/Clickable';
import Icon from '../../../Icon';
import Tooltip from '../../../Tooltip';
import {
  colors,
  font,
  lineHeight,
  spacing,
  transitionTime,
} from '@matterapp/matter-theme';

export const MainContainer = styled.div`
  background: ${({ showBackground }) => showBackground ? colors.white : 'transparent'};
`;

export const Container = styled.div`
  border-top: 1px solid ${({ hasComments }) => hasComments ? colors.blacks[10] : 'transparent'};
`;

export const ToolbarContainer = styled.div`
  align-items: center;
  display: flex;
  padding: ${({ append }) => append ? 0 : spacing.singleAndHalf} ${spacing.singleAndHalf} ${spacing.singleAndHalf};
`;

export const TimeContainer = styled.div`
  color: ${colors.blacks[40]};
  display: inline;
  line-height: ${lineHeight.S};
  font-size: ${font.size.XS};
  text-transform: uppercase;
  flex: 1 1 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex: 1 1 100%;
  justify-content: flex-end;
`;

export const MiddleActionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  align-items: flex-start;
  display: flex;
  padding: ${spacing.singleAndHalf} ${spacing.singleAndHalf};
`;

export const Header = styled.div`
  font-size: ${font.size.S};
  font-weight: ${font.weight.semiBold};
  flex: 1 1 100%;
  color: ${colors.blacks[90]};
`;

export const CommentsTooltip = styled(Tooltip).attrs(() => ({
  position: ['top', 'bottom'],
}))``;

export const CommentButtonNumber = styled.span`
  color: ${colors.blacks[50]};
  margin-left: ${spacing.half};
  margin-bottom: ${spacing.quarter};
  transition: color ${transitionTime.default};

  &:empty {
    margin-left: 0;
    margin-bottom: 0;
  }
`;

export const CommentIcon = styled(Icon).attrs(() => ({
  name: 'comment',
}))``;

export const CommentsButtonWrapper = styled.div``;

export const CommentButtonContainer = styled(Clickable)`
  align-items: center;
  display: flex;
  max-height: ${spacing.singleAndHalf};

  & svg path {
    fill: transparent;
    stroke: ${colors.blacks[40]};
    transition: all ${transitionTime.default};
  }

  &:hover {
    svg path {
      fill: ${colors.blues[1]};
      stroke: ${colors.blues[60]};
    }
    ${CommentButtonNumber} {
      color: ${colors.blues[60]};
    }
  }

  &:active {
    svg path {
      fill: ${colors.blues[5]};
      stroke: ${colors.blues[60]};
    }
    ${CommentButtonNumber} {
      color: ${colors.blues[60]};
    }
  }

  ${({ isActive }) => {
    if (isActive) {
      return `
        svg path {
          fill: ${colors.blues[1]} !important;
          stroke: ${colors.blues[60]} !important;
        }
        & ${CommentButtonNumber} {
          color: ${colors.blues[60]};
        }
      `;
    }
  }}
`;

export const CloseButton = styled(Button.Icon).attrs(() => ({
  iconName: 'close',
}))`
  height: ${spacing.singleAndHalf};
  width: ${spacing.singleAndHalf};
  padding: 0;

  svg path {
    fill: ${colors.blacks[30]};
  }

  &:hover svg path {
    fill: ${colors.purples[50]};
  }

  &:active svg path {
    fill: ${colors.purples[60]};
  }
`;

export const ThankButton = styled(Button.Minimal).attrs(() => ({
  size: Button.buttonSizes.S,
}))`
  margin: -${spacing.quarter} 0;
  text-transform: uppercase;
`;

export const ThankedLabel = styled.div`
  color: ${colors.greens[60]};
  pointer-events: ${({ isThanking }) => (isThanking ? 'all' : 'none')};
  transition: ${transitionTime.double};
  text-transform: uppercase;
`;
