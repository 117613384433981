import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Container';

const Basic = ({
  children,
  centered,
  solo,
  padTop,
  padBottom,
  pushBottom,
}) => (
  <Container
    centered={centered}
    solo={solo}
    padTop={padTop}
    padBottom={padBottom}
    pushBottom={pushBottom}
  >
    {children}
  </Container>
);

Basic.defaultProps = {
  centered: false,
  solo: false,
  padTop: false,
  padBottom: false,
  pushBottom: false,
};

Basic.propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
  solo: PropTypes.bool,
  padTop: PropTypes.bool,
  padBottom: PropTypes.bool,
  pushBottom: PropTypes.bool,
};

export default Basic;
