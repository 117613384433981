import React from 'react';
import BackgroundAnimation, { ANIMATIONS } from '../../BackgroundAnimation';
import ColoredBackground from '../../ColoredBackground';
import gsap from 'gsap';
import * as S from './styles';

export default class Variant3Background extends BackgroundAnimation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  /**
   * Moves the clouds in.
   * @returns { Object } The animation timeline.
   */
  moveCloudsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 1 };
    tl.to(this.topCloudRef, { x: -110 });
    tl.to(this.bottomCloudRef, { x: 120 });

    tl.to(this.topCloudRef, resetProps, '<');
    tl.to(this.bottomCloudRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the clouds out.
   * @returns { Object } The animation timeline.
   */
  moveCloudsOut = () => {
    const tl = gsap.timeline({ defaults: { duration: 0.3 } });
    const resetProps = { x: 0, y: 0, duration: 0 };
    tl.to(this.topCloudRef, resetProps);
    tl.to(this.bottomCloudRef, resetProps);

    tl.to(this.topCloudRef, { x: 110 }, '<');
    tl.to(this.bottomCloudRef, { x: -100 }, '<');
    return tl;
  };

  /**
   * Moves the diamonds in.
   * @returns { Object } The animation timeline.
   */
  moveDiamondsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = {
      rotation: 0,
      scale: 1,
      opacity: 1,
      transformOrigin: 'center',
      duration: 0.6,
    };
    const diamondRefs = {
      topRightDiamond1Ref: { rotation: 270, scale: 0 },
      topRightDiamond2Ref: { rotation: -270, scale: 0 },
      topRightDiamond3Ref: { rotation: 180, scale: 0 },
      topRightDiamond4Ref: { rotation: -360, scale: 0 },
      topRightDiamond5Ref: { rotation: 180, scale: 0 },
      topRightDiamond6Ref: { rotation: -135, scale: 0 },
      topRightDiamond7Ref: { rotation: 360, scale: 0 },
      topRightDiamond8Ref: { rotation: -300, scale: 0 },
      topRightDiamond9Ref: { rotation: 220, scale: 0 },
      topRightDiamond10Ref: { rotation: -180, scale: 0 },
      topLeftDiamondRef: { rotation: 360, scale: 0 },
    };
    const timeRefs = {
      topRightDiamond1Ref: '>0.4',
    };
    Object.keys(diamondRefs).forEach((key) => {
      tl.to(this[key], diamondRefs[key]);
    });
    Object.keys(diamondRefs).forEach((key) => {
      tl.to(this[key], resetProps, timeRefs[key] || '<');
    });
    return tl;
  };

  /**
   * Moves the rings in.
   * @returns { Object } The animation timeline.
   */
  moveRingsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 2, opacity: 0, transformOrigin: 'center' };
    const endProps = { scale: 1, opacity: 1, duration: 0.5 };
    tl.to(this.rightRing1Ref, initialProps);
    tl.to(this.rightRing2Ref, initialProps);
    tl.to(this.rightRing3Ref, initialProps);
    tl.to(this.rightRing4Ref, initialProps);

    tl.to(this.rightRing1Ref, endProps, '<');
    tl.to(this.rightRing2Ref, endProps, '<0.2');
    tl.to(this.rightRing3Ref, endProps, '<0.1');
    tl.to(this.rightRing4Ref, endProps, '<0.2');
    return tl;
  };

  /**
   * Moves the planet and galaxy in.
   * @returns { Object } The animation timeline.
   */
  movePlanetIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.to(this.planetRef, { x: 150 });
    tl.to(this.galaxyRef, {
      x: -30,
      y: -100,
      scale: 0,
      rotation: 380,
      transformOrigin: 'center',
    });
    tl.to(this.planetRef, { x: 0, duration: 1 }, '<');
    tl.to(
      this.galaxyRef,
      { x: 0, y: 0, rotation: 0, scale: 1, duration: 1 },
      '<'
    );
    return tl;
  };

  /**
   * Moves the dots in.
   * @returns { Object } The animation timeline.
   */
  moveDotsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = {
      x: 0,
      y: 0,
      scale: 1,
      transformOrigin: 'center',
      duration: 0.7,
    };
    const dotRefs = {
      topDot1Ref: { x: -65, y: 32 },
      topDot2Ref: { x: 34, y: 27 },
      centerDotRef: { x: 47, y: -33, scale: 3 },
      leftDot1Ref: { x: -17, y: 46, scale: 2 },
      leftDot2Ref: { x: 68, y: -24 },
      leftSquare1Ref: { x: 44, y: -31 },
      leftSquare2Ref: { x: -9, y: -29 },
      leftSquare3Ref: { x: 18, y: 50 },
      rightLineRef: { x: -26, y: 26 },
      bottomDot1Ref: { x: -50, y: -43 },
      bottomDot2Ref: { x: 30, y: -61, scale: 1.5 },
      bottomRightDot1Ref: { x: -60, y: -21 },
      bottomRightDot2Ref: { x: -32, y: -33 },
    };
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], dotRefs[key]);
    });
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], resetProps, '<');
    });
    return tl;
  };

  playAnimation = () => {
    const { animation } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    if (animation !== ANIMATIONS.SHOW) {
      if (animation === ANIMATIONS.OUT) {
        tl.add(this.moveCloudsOut(), '<');
      } else {
        tl.add(this.moveCloudsIn(), '<');
        tl.add(this.moveRingsIn(), '<');
        tl.add(this.movePlanetIn(), '<');
        tl.add(this.moveDiamondsIn(), '<');
        tl.add(this.moveDotsIn(), '<0.3');
      }
    }
    this.tl = tl;
    return tl;
  };

  render() {
    return (
      <ColoredBackground {...this.props}>
        <S.TopCloud ref={(ref) => (this.topCloudRef = ref)} />
        <S.BottomCloud ref={(ref) => (this.bottomCloudRef = ref)} />
        <S.BottomLeftDot ref={(ref) => (this.bottomLeftDotRef = ref)} />
        <S.BottomRightDot1 ref={(ref) => (this.bottomRightDot1Ref = ref)} />
        <S.BottomRightDot2 ref={(ref) => (this.bottomRightDot2Ref = ref)} />
        <S.BottomDot1 ref={(ref) => (this.bottomDot1Ref = ref)} />
        <S.BottomDot2 ref={(ref) => (this.bottomDot2Ref = ref)} />
        <S.Galaxy ref={(ref) => (this.galaxyRef = ref)} />
        <S.RightRing1 ref={(ref) => (this.rightRing1Ref = ref)} />
        <S.RightRing2 ref={(ref) => (this.rightRing2Ref = ref)} />
        <S.RightRing3 ref={(ref) => (this.rightRing3Ref = ref)} />
        <S.RightRing4 ref={(ref) => (this.rightRing4Ref = ref)} />
        <S.RightDot ref={(ref) => (this.planetRef = ref)} />
        <S.RightLine ref={(ref) => (this.rightLineRef = ref)} />
        <S.LeftDot1 ref={(ref) => (this.leftDot1Ref = ref)} />
        <S.LeftDot2 ref={(ref) => (this.leftDot2Ref = ref)} />
        <S.LeftSquare1 ref={(ref) => (this.leftSquare1Ref = ref)} />
        <S.LeftSquare2 ref={(ref) => (this.leftSquare2Ref = ref)} />
        <S.LeftSquare3 ref={(ref) => (this.leftSquare3Ref = ref)} />
        <S.TopDot1 ref={(ref) => (this.topDot1Ref = ref)} />
        <S.TopDot2 ref={(ref) => (this.topDot2Ref = ref)} />
        <S.CenterDot ref={(ref) => (this.centerDotRef = ref)} />
        <S.TopRightDiamond1 ref={(ref) => (this.topRightDiamond1Ref = ref)} />
        <S.TopRightDiamond2 ref={(ref) => (this.topRightDiamond2Ref = ref)} />
        <S.TopRightDiamond3 ref={(ref) => (this.topRightDiamond3Ref = ref)} />
        <S.TopRightDiamond4 ref={(ref) => (this.topRightDiamond4Ref = ref)} />
        <S.TopRightDiamond5 ref={(ref) => (this.topRightDiamond5Ref = ref)} />
        <S.TopRightDiamond6 ref={(ref) => (this.topRightDiamond6Ref = ref)} />
        <S.TopRightDiamond7 ref={(ref) => (this.topRightDiamond7Ref = ref)} />
        <S.TopRightDiamond8 ref={(ref) => (this.topRightDiamond8Ref = ref)} />
        <S.TopRightDiamond9 ref={(ref) => (this.topRightDiamond9Ref = ref)} />
        <S.TopRightDiamond10 ref={(ref) => (this.topRightDiamond10Ref = ref)} />
        <S.TopLeftDiamond ref={(ref) => (this.topLeftDiamondRef = ref)} />
      </ColoredBackground>
    );
  }
}
