import React from 'react';
import { Typography, Input, Paper, Divider, Box } from '@mui/material';
import { Radio, Advisors } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { ACTIONS, getStateTransferObject } from './reducer';
import { FREQUENCY_OPTIONS, getDeliveryTimes } from './consts';
import moment from 'moment-timezone';
import WeekdaySelector from './WeekdaySelector';
import MonthDaySelector from './MonthDaySelector';
import { useQuery } from '@apollo/client';
import getCustomSurveyReccuringDate from 'graphql-queries/queries/surveys/getCustomSurveyReccuringDate';
import { Timezone } from '@matterapp/matter-ui';

const TimezoneDropdown = styled(Timezone.Dropdown)(({ theme }) => ({
  height: '56px',
  width: '350px',
  maxWidth: '100%',
  input: {
    height: '56px'
  },
  'svg path': {
    fill: '#343799!important',
    stroke: '#343799!important'
  },
  [theme.breakpoints.down('sm')]: {
    width: '285px',
    marginTop: theme.spacing(2)
  }
}));

const InputsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(3), 
    flexWrap: 'wrap'
  }
}));

const SendOnWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexDirection: 'row',
  alignItems: 'baseline',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  marginTop: theme.spacing(2), 
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start', 
    flexDirection: 'column'
  }
}));

const DropdownWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const FrequencyWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: '50% 50%',
  gridRowGap: theme.spacing(2),
  gridColumnGap: theme.spacing(2),
  width: '70%',
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3)
}));
  
const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: '560px',
  '&:nth-child(even)': {
    paddingTop: 0
  }
}));

const StyledInput = styled(Input)(({ noValue, theme }) => ({
  cursor: 'pointer',
  width: '150px',
  'input::-webkit-calendar-picker-indicator': {
    cursor: 'pointer'
  },
  input: {
    cursor: 'pointer',
    color: noValue ? theme.palette.blacks[60] : theme.palette.blacks[90]
  }
}));

export default function ScheduleScreen(props) {
  const { state, dispatch } = props;
  const { 
    sendDate, 
    sendTime, 
    timezone,
    sendNow, 
    frequency, 
    closingDate, 
    closeSurveyOnDate,
    showErrors,
    frequencyConfig
  } = state;
  const { repeatValue = 1 } = frequencyConfig;
  const sendOnce = frequency === 'once';
  const tz = moment().tz(timezone).format('z');
  let sendDateError, closeDateError;
  let selectedTimeOption = getDeliveryTimes(sendDate, timezone).find(option => option.value === sendTime);

  if (!selectedTimeOption) {
    selectedTimeOption = getDeliveryTimes(sendDate, timezone)[0];
  }

  const { data: recurringSurveyDate } = useQuery(getCustomSurveyReccuringDate, {
    variables: {
      timezone,
      survey: getStateTransferObject(state)
    },
    skip: sendOnce
  });

  if (!sendNow && showErrors) {
    const sendDateValue = moment(`${sendDate} ${sendTime}:00 ${tz}`);

    if (sendDate && moment().tz(timezone).isAfter(sendDateValue)) {
      sendDateError = 'Please select a future date';
    } else if (!sendDate) {
      sendDateError = 'Please select a date';
    }
  }

  if (closeSurveyOnDate && showErrors) {
    if (!closingDate) {
      closeDateError = 'Please select a date';
    }

    if (moment().isAfter(moment(closingDate))) {
      closeDateError = 'Please select a future date';
    }    

    if (!sendNow && sendDate && !moment(closingDate).isAfter(moment(sendDate))) {
      closeDateError = 'Please select a date after the send date';
    }
  }

  const selectedFrequency = FREQUENCY_OPTIONS.find(option => option.value === frequency);
  const repeatOptions = [];

  if (selectedFrequency?.repeat) {
    const { min, max } = selectedFrequency.repeat;

    for (let i = min; i <= max; i++) {
      repeatOptions.push({ value: i, label: i });
    }
  }

  return (
    <Box>
      <Paper sx={{ padding: 0, mt: 3, ml: 1, mr: 1 }}>
        <TopSection>
          <Typography variant='h5' component='p'>
            Delivery
          </Typography>
        </TopSection>
        <Divider sx={{ margin: 0, mb: 1 }} />
        <Section>
          <Box>
            <Radio 
              onClick={() => {
                dispatch({ type: ACTIONS.UPDATE_SEND_NOW, payload: true });
              }}
              checked={sendNow}
            >
              <Typography variant='body1' sx={{ ml: 2, fontWeight: 500 }}>Send Now</Typography>
            </Radio>
          </Box>
          <SendOnWrapper>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Radio 
                checked={!sendNow}
                onClick={() => dispatch({ type: ACTIONS.UPDATE_SEND_NOW, payload: false })}
              />
              <Typography 
                variant='body1' 
                sx={{ fontWeight: 500, ml: 2, mr: 2 }}
                onClick={() => dispatch({ type: ACTIONS.UPDATE_SEND_NOW, payload: false })}
              >
                Send on:
              </Typography>
            </Box>
            <InputsWrapper>
              <StyledInput
                type='date'
                placeholder="MM/DD/YYYY"
                onFocus={() => {
                  const el = document.getElementById('send_picker');
                  el.showPicker();
                }}
                disableUnderline
                slotProps={{
                  input: {
                    min: moment().format('YYYY-MM-DD')
                  }
                }}
                noValue={!sendDate}
                value={sendDate} 
                id='send_picker'
                error={sendDateError}
                onChange={(e) => {
                  const hourOptions = getDeliveryTimes(e.target.value, timezone);
                  let minHour = hourOptions[0].value;

                  if (minHour === '0:00') {
                    minHour = '9:00';
                  }
                
                  dispatch({ type: ACTIONS.UPDATE_SEND_DATE, payload: { value: e.target.value, minHour } });
                }}
              />

              <Box sx={{ ml: 2, mr: 1 }}>
                <Advisors.RecurringModal.Dropdown
                  selectedOption={selectedTimeOption?.label}
                  handleChangeSelection={(choice) => {
                    dispatch({ type: ACTIONS.UPDATE_SEND_TIME, payload: choice.value });
                  }}
                  options={getDeliveryTimes(sendNow ? new Date() : sendDate, timezone)}
                />
              </Box>
              <TimezoneDropdown
                fluid
                onChange={(_, props) => {
                  dispatch({ type: ACTIONS.UPDATE_TIMEZONE, payload: props.value });
                }}
                value={timezone}
              />
            </InputsWrapper>
          </SendOnWrapper>
          {sendDateError && (
            <Typography variant='body1' sx={{ mt: 1, ml: '140px'  }} color='error'>
              {sendDateError}
            </Typography>
          )}
        </Section>
      </Paper>

      <Paper sx={{ padding: 0, mt: 3, ml: 1, mr: 1 }}>
        <TopSection>
          <Typography variant='h5' component='p'>
            Frequency
          </Typography>
        </TopSection>
        <Divider sx={{ margin: 0, mb: 1 }} />
        <Section style={{ maxWidth: '100%' }}>
          <FrequencyWrapper>
            <Box>
              <Advisors.RecurringModal.Dropdown
                selectedOption={selectedFrequency}
                handleChangeSelection={(choice) => {
                  dispatch({ type: ACTIONS.UPDATE_FREQUENCY, payload: choice.value });
                }}
                options={FREQUENCY_OPTIONS}
              />
            </Box>
            {selectedFrequency?.repeat && (
              <DropdownWrapper>
                <Typography variant='body1'>
                  Repeat every
                </Typography>
                <Box sx={{ mr: 2, ml: 2, width: '100px' }}>
                  <Advisors.RecurringModal.Dropdown
                    selectedOption={repeatValue}
                    handleChangeSelection={(choice) => {
                      dispatch({ type: ACTIONS.UPDATE_REPEAT_FREQUENCY, payload: choice.value });
                    }}
                    options={repeatOptions}
                  />
                </Box>
                <Typography variant='body1'>
                  {repeatValue === 1 ? selectedFrequency?.repeat.label : `${selectedFrequency?.repeat.label}s`}
                </Typography>
              </DropdownWrapper>
            )}
            <MonthDaySelector state={state} dispatch={dispatch} />
          </FrequencyWrapper>
          <WeekdaySelector state={state} dispatch={dispatch} />
          {recurringSurveyDate && !sendOnce ? (
            <Typography variant='body1' sx={{ mt: 2 }}>
              First survey will send {sendNow || !sendDate ? 'now' : `on ${moment(sendDate).format('MMM, D YYYY')}`}. 
              Subsequent surveys will send starting on {moment(parseInt(recurringSurveyDate.customSurveyReccuringDatePreview)).minute(0).hour(sendNow || !sendTime ? 10 : sendTime.split(':')[0]).format('MMM D, YYYY hh:mm a')} {tz}.
            </Typography>
          ) : null}
        </Section>
      </Paper>

      {sendOnce && (
        <Paper sx={{ padding: 0, mt: 3, mb: '187px', ml: 1, mr: 1 }}>
          <TopSection>
            <Typography variant='h5' component='p'>
              Closing
            </Typography>
          </TopSection>
          <Divider sx={{ margin: 0, mb: 1 }} />
          <Section>
            <Box>
              <Radio 
                onClick={() => {
                  dispatch({ type: ACTIONS.UPDATE_CLOSE_ON_DATE, payload: false });
                }}
                checked={!closeSurveyOnDate}
              >
                <Typography variant='body1' sx={{ ml: 2, fontWeight: 500, textAlign: 'left' }}>
                  Keep survey open until 100% participation
                </Typography>
              </Radio>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, cursor: 'pointer' }}>
              <Radio 
                onClick={() => dispatch({ type: ACTIONS.UPDATE_CLOSE_ON_DATE, payload: true })}
                checked={closeSurveyOnDate}
              />
              <Typography 
                variant='body1' 
                sx={{ fontWeight: 500, ml: 2, mr: 2 }}
                onClick={() => dispatch({ type: ACTIONS.UPDATE_CLOSE_ON_DATE, payload: true })}
              >
                Close survey on:
              </Typography>
              <StyledInput
                type='date'
                disableUnderline
                slotProps={{
                  input: {
                    min: moment(sendDate || new Date()).add(1, 'day').format('YYYY-MM-DD')
                  }
                }}
                noValue={!closingDate}
                onFocus={() => {
                  const el = document.getElementById('closing_picker');
                  el.showPicker();
                }}
                value={closingDate} 
                id='closing_picker'
                error={!!closeDateError}
                isHideErrorMessage
                onChange={(e) => {
                  dispatch({ type: ACTIONS.UPDATE_CLOSING_DATE, payload: e.target.value });
                }}
              />
            </Box>
            {closingDate && (
              <Typography variant='body1' sx={{ mt: 2 }}>
                The survey will close on {moment(closingDate).format('MMM DD, YYYY')} 11:59 PM, {tz}.
              </Typography>
            )}
            {closeDateError && (
              <Typography variant='body1' sx={{ mt: 1 }} color='error'>
                {closeDateError}
              </Typography>
            )}
          </Section>
        </Paper>
      )}
    </Box>
  );
}