import PropTypes from 'prop-types';

export const SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
};

const sizesArray = Object.keys(SIZE).map((key) => SIZE[key]);

export const sizePropType = PropTypes.oneOf(sizesArray);
