import React from 'react';
import RightToLeft1 from '../Paths/RightToLeft1';
import BasePath from './BasePath';

const Path4 = React.forwardRef((props, ref) => (
  <BasePath
    PathComponent={RightToLeft1}
    pointProps={{ top: 80, left: 2.3 }}
    ref={ref}
    {...props}
  />
));

export default Path4;
