import React from 'react';
import PropTypes from 'prop-types';

const fillStyle = { clipRule: 'evenodd', fillRule: 'evenodd' };

const Icons = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#21CBA3"
      d="M39.707,33.89c0.003-0.253-0.143-0.56-0.277-0.693c-0.11-0.11-0.26-0.233-0.613-0.257
  c-0.787-0.053-1.377,0.957-0.733,1.603C38.633,35.093,39.69,34.717,39.707,33.89z M38.933,37.65c-0.71-0.1-1.29,0.347-1.367,1.01
  c-0.08,0.727,0.43,1.277,1.03,1.347c0.67,0.073,1.437-0.417,1.347-1.327C39.893,38.164,39.497,37.73,38.933,37.65z M37.273,36.363
  c0.01-0.567-0.39-0.953-0.93-0.99c-0.553-0.037-1.04,0.377-1.05,0.95C35.27,37.62,37.25,37.653,37.273,36.363z M39.37,28.787
  c-0.043-0.24-0.25-0.41-0.473-0.457c-0.36-0.073-0.7,0.15-0.753,0.457C37.973,29.774,39.547,29.78,39.37,28.787z M33.89,42.894
  c-0.683-0.053-1.17,0.673-0.85,1.287c0.067,0.127,0.16,0.223,0.273,0.293c0.477-0.217,0.95-0.447,1.41-0.697c0-0.003,0-0.01,0-0.013
  C34.72,43.313,34.423,42.933,33.89,42.894z M36.48,32.09c0.67-0.163,0.703-1.237-0.04-1.387c-0.403-0.08-0.793,0.11-0.87,0.533
  C35.493,31.674,35.847,32.247,36.48,32.09z M41.29,35.237c-0.457-0.03-0.86,0.267-1.01,0.493c-0.673,1.043,0.623,2.17,1.563,1.563
  c0.12-0.08,0.297-0.273,0.337-0.337C42.687,36.177,42.097,35.29,41.29,35.237z M26.45,45.903c-0.34-0.047-0.44,0.277-0.34,0.497
  c0.207-0.02,0.41-0.043,0.617-0.07C26.777,46.147,26.707,45.937,26.45,45.903z M44.317,28.767c-0.043-0.24-0.25-0.41-0.473-0.457
  c-0.36-0.073-0.7,0.15-0.75,0.457C42.92,29.753,44.493,29.76,44.317,28.767z M42.02,31.22c-0.053-0.293-0.29-0.53-0.613-0.593
  c-0.557-0.113-1.09,0.287-0.97,0.95C40.607,32.493,42.23,32.36,42.02,31.22z M29.003,38.264c-0.393-0.09-0.81,0.203-0.713,0.693
  c0.13,0.657,1.313,0.56,1.147-0.277C29.407,38.52,29.28,38.327,29.003,38.264z M36.5,40.243c-0.577-0.09-0.977,0.17-1.167,0.553
  c-0.423,0.843,0.447,1.973,1.463,1.463c0.38-0.19,0.81-0.793,0.473-1.463C37.197,40.647,36.93,40.31,36.5,40.243z M43.763,32.924
  c-0.787-0.053-1.377,0.957-0.733,1.603c0.197,0.197,0.457,0.273,0.713,0.253c0.263-0.48,0.503-0.97,0.733-1.47
  c-0.033-0.05-0.067-0.097-0.1-0.13C44.267,33.07,44.113,32.947,43.763,32.924z M46.237,26.057c-0.603-0.097-0.54,0.77-0.06,0.77
  c0.053,0,0.1-0.01,0.143-0.03c0.027-0.227,0.057-0.453,0.077-0.68C46.353,26.09,46.3,26.067,46.237,26.057z M28.943,43.21
  c-0.743-0.083-0.843,1.107-0.08,1.127C29.56,44.36,29.667,43.29,28.943,43.21z M33.533,34.583c0.583,0.21,1.233-0.31,0.99-0.99
  c-0.077-0.213-0.24-0.403-0.593-0.457C33.02,33.004,32.723,34.293,33.533,34.583z M33.93,37.927
  c-0.563-0.073-0.887,0.283-0.99,0.593c-0.387,1.183,1.393,1.663,1.74,0.593C34.89,38.473,34.447,37.997,33.93,37.927z M31.06,36.997
  c0.38,0.15,0.817-0.09,0.93-0.377c0.03-0.073,0.1-0.303,0-0.553c-0.033-0.083-0.2-0.333-0.493-0.397
  c-0.153-0.033-0.35-0.013-0.437,0.02C30.577,35.883,30.467,36.764,31.06,36.997z M31.533,40.56c-0.537-0.107-1.05,0.263-0.93,0.91
  c0.15,0.813,1.38,0.703,1.503,0C32.187,41.027,31.937,40.64,31.533,40.56z"
    />
    <path
      fill="#FF6588"
      d="M4.91,28.767c-0.053-0.303-0.39-0.527-0.75-0.457c-0.227,0.047-0.433,0.213-0.473,0.457
  C3.507,29.76,5.08,29.753,4.91,28.767z M6.71,35.237c-0.807,0.053-1.397,0.94-0.89,1.72c0.04,0.063,0.217,0.257,0.337,0.337
  c0.94,0.607,2.237-0.52,1.563-1.563C7.573,35.504,7.167,35.207,6.71,35.237z M9.067,37.65c-0.563,0.08-0.957,0.513-1.01,1.03
  c-0.09,0.907,0.677,1.4,1.347,1.327c0.6-0.067,1.11-0.617,1.03-1.347C10.357,37.997,9.773,37.55,9.067,37.65z M7.56,31.577
  c0.12-0.663-0.41-1.063-0.97-0.95c-0.323,0.067-0.56,0.3-0.613,0.593C5.77,32.36,7.393,32.493,7.56,31.577z M9.857,28.787
  c-0.053-0.303-0.39-0.53-0.753-0.457c-0.227,0.047-0.43,0.213-0.473,0.457C8.453,29.78,10.027,29.774,9.857,28.787z M21.55,45.903
  c-0.26,0.033-0.327,0.247-0.273,0.427c0.203,0.023,0.41,0.05,0.617,0.07C21.99,46.177,21.89,45.857,21.55,45.903z M14.11,42.894
  c-0.533,0.04-0.83,0.42-0.83,0.87c0,0.003,0,0.01,0,0.013c0.46,0.25,0.93,0.48,1.41,0.697c0.11-0.073,0.207-0.17,0.273-0.293
  C15.283,43.567,14.793,42.843,14.11,42.894z M1.763,26.057c-0.063,0.01-0.117,0.033-0.16,0.063c0.02,0.227,0.05,0.453,0.077,0.68
  c0.043,0.02,0.09,0.03,0.143,0.03C2.303,26.827,2.367,25.96,1.763,26.057z M9.917,34.543c0.647-0.647,0.053-1.657-0.733-1.603
  C8.83,32.964,8.68,33.087,8.57,33.197c-0.133,0.133-0.28,0.44-0.277,0.693C8.31,34.717,9.367,35.093,9.917,34.543z M18.997,38.264
  c-0.28,0.063-0.403,0.257-0.437,0.417c-0.163,0.837,1.017,0.933,1.147,0.277C19.807,38.467,19.39,38.177,18.997,38.264z
   M16.94,36.997c0.593-0.237,0.483-1.113,0-1.307c-0.083-0.033-0.283-0.05-0.437-0.02c-0.297,0.06-0.46,0.313-0.493,0.397
  c-0.1,0.25-0.03,0.48,0,0.553C16.123,36.91,16.563,37.147,16.94,36.997z M14.467,34.583c0.81-0.293,0.513-1.58-0.397-1.443
  c-0.353,0.053-0.517,0.24-0.593,0.457C13.233,34.273,13.883,34.793,14.467,34.583z M16.467,40.56
  c-0.403,0.08-0.653,0.467-0.573,0.91c0.123,0.703,1.353,0.813,1.503,0C17.513,40.824,17.003,40.453,16.467,40.56z M19.057,43.21
  c-0.723,0.08-0.62,1.147,0.08,1.127C19.9,44.317,19.803,43.127,19.057,43.21z M12.707,36.327c-0.01-0.57-0.497-0.987-1.05-0.95
  c-0.54,0.037-0.94,0.42-0.93,0.99C10.75,37.653,12.73,37.62,12.707,36.327z M4.237,32.924c-0.353,0.023-0.503,0.147-0.613,0.257
  c-0.033,0.033-0.067,0.077-0.1,0.13c0.227,0.5,0.47,0.99,0.733,1.47c0.257,0.02,0.517-0.057,0.713-0.253
  C5.617,33.88,5.023,32.867,4.237,32.924z M11.52,32.09c0.637,0.157,0.99-0.417,0.91-0.85c-0.077-0.423-0.467-0.613-0.87-0.533
  C10.813,30.854,10.85,31.927,11.52,32.09z M14.07,37.927c-0.52,0.07-0.96,0.543-0.753,1.187c0.35,1.07,2.127,0.59,1.74-0.593
  C14.96,38.21,14.633,37.854,14.07,37.927z M11.5,40.243c-0.43,0.07-0.697,0.403-0.77,0.553c-0.337,0.67,0.097,1.273,0.473,1.463
  c1.017,0.51,1.887-0.62,1.463-1.463C12.473,40.414,12.077,40.15,11.5,40.243z"
    />
    <path
      fill="#F2A645"
      d="M35.293,11.674c0.01,0.57,0.497,0.987,1.05,0.95c0.54-0.037,0.94-0.42,0.93-0.99
  C37.25,10.347,35.27,10.38,35.293,11.674z M41.843,10.707c-0.94-0.607-2.237,0.52-1.563,1.563c0.147,0.23,0.553,0.523,1.01,0.493
  c0.807-0.053,1.397-0.94,0.89-1.72C42.14,10.98,41.963,10.783,41.843,10.707z M41.35,21.094c-0.727-0.147-0.747,1.063,0,0.89
  C41.87,21.864,41.717,21.167,41.35,21.094z M36.797,5.74c-1.017-0.51-1.887,0.62-1.463,1.463c0.193,0.383,0.59,0.647,1.167,0.553
  c0.43-0.07,0.697-0.403,0.773-0.553C37.61,6.534,37.177,5.93,36.797,5.74z M43.09,19.233c0.053,0.303,0.39,0.527,0.75,0.457
  c0.227-0.047,0.433-0.213,0.473-0.457C44.493,18.24,42.92,18.247,43.09,19.233z M33.89,5.107c0.533-0.04,0.83-0.42,0.83-0.87
  c0-0.003,0-0.01,0-0.013c-0.46-0.25-0.93-0.48-1.41-0.697C33.2,3.6,33.103,3.697,33.037,3.82C32.717,4.434,33.207,5.157,33.89,5.107
  z M43.03,13.477c-0.647,0.647-0.053,1.657,0.733,1.603c0.353-0.023,0.503-0.147,0.613-0.257c0.033-0.033,0.067-0.077,0.1-0.13
  c-0.227-0.5-0.47-0.99-0.733-1.47C43.49,13.203,43.227,13.28,43.03,13.477z M26.11,1.604c-0.1,0.22,0,0.54,0.34,0.497
  c0.26-0.033,0.327-0.247,0.273-0.427C26.52,1.647,26.313,1.62,26.11,1.604z M40.44,16.424c-0.12,0.663,0.41,1.063,0.97,0.95
  c0.323-0.067,0.56-0.3,0.613-0.593C42.23,15.64,40.607,15.507,40.44,16.424z M36.48,15.91c-0.637-0.157-0.99,0.417-0.91,0.85
  c0.077,0.423,0.467,0.613,0.87,0.533C37.187,17.147,37.15,16.073,36.48,15.91z M38.143,19.213c0.053,0.303,0.39,0.53,0.753,0.457
  c0.227-0.047,0.43-0.213,0.473-0.457C39.547,18.22,37.973,18.227,38.143,19.213z M38.083,13.457
  c-0.647,0.647-0.053,1.657,0.733,1.603c0.353-0.023,0.503-0.147,0.613-0.257c0.133-0.133,0.28-0.44,0.277-0.693
  C39.69,13.283,38.633,12.907,38.083,13.457z M28.943,4.79c0.723-0.08,0.62-1.147-0.08-1.127C28.1,3.684,28.197,4.874,28.943,4.79z
   M38.597,7.994c-0.6,0.067-1.11,0.617-1.03,1.347c0.073,0.663,0.657,1.11,1.367,1.01c0.563-0.08,0.957-0.513,1.01-1.03
  C40.037,8.414,39.267,7.92,38.597,7.994z M32.94,9.48c0.1,0.31,0.427,0.667,0.99,0.593c0.52-0.07,0.96-0.543,0.753-1.187
  C34.333,7.817,32.553,8.297,32.94,9.48z M46.32,21.2c-0.043-0.02-0.09-0.03-0.143-0.03c-0.48,0-0.543,0.867,0.06,0.77
  c0.063-0.01,0.117-0.033,0.16-0.063C46.377,21.653,46.347,21.427,46.32,21.2z M30.603,6.53c-0.12,0.647,0.393,1.017,0.93,0.91
  c0.403-0.08,0.653-0.467,0.573-0.91C31.983,5.827,30.753,5.717,30.603,6.53z M33.533,13.417c-0.81,0.293-0.513,1.58,0.397,1.443
  c0.353-0.053,0.517-0.24,0.593-0.457C34.767,13.727,34.117,13.207,33.533,13.417z M31.06,11.003c-0.593,0.237-0.483,1.113,0,1.307
  c0.083,0.033,0.283,0.05,0.437,0.02c0.297-0.06,0.46-0.313,0.493-0.397c0.1-0.25,0.03-0.48,0-0.553
  C31.877,11.09,31.437,10.854,31.06,11.003z M28.29,9.044c-0.097,0.49,0.32,0.78,0.713,0.693c0.28-0.063,0.403-0.257,0.437-0.417
  C29.603,8.484,28.42,8.387,28.29,9.044z"
    />
    <path
      fill="#F58565"
      style={fillStyle}
      d="M13.32,8.887c-0.21,0.643,0.233,1.12,0.753,1.187
  c0.563,0.073,0.887-0.283,0.99-0.593C15.447,8.297,13.667,7.817,13.32,8.887z M10.727,11.637c-0.01,0.567,0.39,0.953,0.93,0.99
  c0.553,0.037,1.04-0.377,1.05-0.95C12.73,10.38,10.75,10.347,10.727,11.637z M15.893,6.53c-0.08,0.443,0.17,0.83,0.573,0.91
  c0.537,0.107,1.05-0.263,0.93-0.91C17.247,5.717,16.017,5.827,15.893,6.53z M11.52,15.91c-0.67,0.163-0.703,1.237,0.04,1.387
  c0.403,0.08,0.793-0.11,0.87-0.533C12.507,16.327,12.153,15.753,11.52,15.91z M14.467,13.417c-0.583-0.21-1.233,0.31-0.99,0.99
  c0.077,0.213,0.24,0.403,0.593,0.457C14.98,14.997,15.277,13.707,14.467,13.417z M14.11,5.107c0.683,0.053,1.17-0.673,0.85-1.287
  c-0.067-0.127-0.16-0.223-0.273-0.293c-0.477,0.217-0.95,0.447-1.41,0.697c0,0.003,0,0.01,0,0.013
  C13.28,4.687,13.577,5.067,14.11,5.107z M21.89,1.604c-0.207,0.02-0.41,0.043-0.617,0.07C21.22,1.854,21.29,2.067,21.547,2.1
  C21.89,2.144,21.99,1.824,21.89,1.604z M19.057,4.79c0.743,0.083,0.843-1.107,0.08-1.127C18.44,3.64,18.333,4.71,19.057,4.79z
   M18.563,9.32C18.593,9.48,18.72,9.674,19,9.737c0.393,0.09,0.81-0.203,0.713-0.693C19.58,8.387,18.397,8.484,18.563,9.32z
   M16.94,11.003c-0.38-0.15-0.817,0.09-0.93,0.377c-0.03,0.073-0.1,0.303,0,0.553c0.033,0.083,0.2,0.333,0.493,0.397
  c0.153,0.033,0.35,0.013,0.437-0.02C17.423,12.117,17.533,11.237,16.94,11.003z M8.293,14.11c-0.003,0.253,0.143,0.56,0.277,0.693
  c0.11,0.11,0.26,0.233,0.613,0.257c0.787,0.053,1.377-0.957,0.733-1.603C9.367,12.907,8.31,13.283,8.293,14.11z M1.823,21.174
  c-0.053,0-0.1,0.01-0.143,0.03c-0.027,0.227-0.057,0.453-0.077,0.68c0.043,0.03,0.097,0.053,0.16,0.063
  C2.367,22.04,2.303,21.174,1.823,21.174z M9.403,7.994C8.733,7.92,7.967,8.41,8.057,9.32c0.053,0.517,0.447,0.95,1.01,1.03
  c0.71,0.1,1.29-0.347,1.367-1.01C10.513,8.61,10.003,8.06,9.403,7.994z M8.63,19.213c0.043,0.24,0.25,0.41,0.473,0.457
  c0.36,0.073,0.7-0.15,0.753-0.457C10.027,18.227,8.453,18.22,8.63,19.213z M3.683,19.233c0.043,0.24,0.25,0.41,0.473,0.457
  c0.36,0.073,0.7-0.15,0.75-0.457C5.08,18.247,3.507,18.24,3.683,19.233z M6.157,10.707c-0.12,0.08-0.297,0.273-0.337,0.337
  c-0.507,0.783,0.083,1.667,0.89,1.72c0.457,0.03,0.86-0.267,1.01-0.493C8.393,11.227,7.097,10.097,6.157,10.707z M5.98,16.78
  c0.053,0.293,0.29,0.53,0.613,0.593c0.557,0.113,1.09-0.287,0.97-0.95C7.393,15.507,5.77,15.64,5.98,16.78z M4.257,13.22
  c-0.263,0.48-0.503,0.97-0.73,1.47c0.033,0.05,0.067,0.097,0.1,0.13c0.11,0.11,0.26,0.233,0.613,0.257
  c0.787,0.053,1.377-0.957,0.733-1.603C4.773,13.28,4.51,13.203,4.257,13.22z M11.203,5.74c-0.38,0.19-0.81,0.793-0.473,1.463
  c0.077,0.15,0.343,0.487,0.77,0.553c0.577,0.09,0.977-0.17,1.167-0.553C13.09,6.36,12.22,5.23,11.203,5.74z"
    />
    <path
      fill="#F58565"
      style={fillStyle}
      d="M24.667,24.667h7.975c-0.324,4.255-3.72,7.651-7.975,7.975
  V24.667z"
    />
    <path
      fill="#F2A645"
      style={fillStyle}
      d="M15.359,24.667h7.975v7.975
  C19.078,32.318,15.682,28.922,15.359,24.667z"
    />
    <path
      fill="#FF6588"
      style={fillStyle}
      d="M32.641,23.334c-0.324-4.255-3.72-7.651-7.975-7.975v7.975
  H32.641z"
    />
    <path
      fill="#21CBA3"
      style={fillStyle}
      d="M23.333,23.334v-7.975c-4.255,0.324-7.651,3.72-7.975,7.975
  H23.333z"
    />
    <path
      fill="#343799"
      style={fillStyle}
      d="M23.333,37.667v-3.689c-4.992-0.329-8.983-4.32-9.311-9.311
  h-3.689v-1.333h3.689c0.328-4.992,4.32-8.983,9.311-9.311v-3.689h1.333v3.689c4.992,0.329,8.983,4.32,9.311,9.311h3.689v1.333
  h-3.689c-0.328,4.992-4.32,8.983-9.311,9.311v3.689H23.333z M32.641,23.333c-0.324-4.255-3.72-7.651-7.975-7.975v7.975H32.641z
   M24.667,24.667h7.975c-0.324,4.255-3.72,7.651-7.975,7.975V24.667z M23.333,23.333v-7.975c-4.255,0.324-7.651,3.72-7.975,7.975
  H23.333z M15.359,24.667h7.975v7.975C19.078,32.318,15.682,28.922,15.359,24.667z"
    />
    <path
      fill="#343799"
      d="M24,3c11.58,0,21,9.42,21,21s-9.42,21-21,21S3,35.58,3,24S12.42,3,24,3z M24,0
  C10.747,0,0,10.747,0,24s10.747,24,24,24s24-10.747,24-24S37.253,0,24,0z"
    />
  </svg>
);

Icons.defaultProps = {
  size: 48,
};

Icons.propTypes = {
  /** The height and width of the icon. */
  size: PropTypes.number,
};

export default Icons;
