import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import SkillChartUI from 'components/SkillChart/SkillChart';
import {
  Panel
} from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

const PanelBase = styled(Panel.Base)`
  z-index: 10;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  h2 {
    color: ${theme.colors.blacks[80]};
    font-size: ${theme.fontSize.S};
    line-height: ${theme.lineHeight.M};
    font-weight: bold;
    margin-bottom: 0;
  }

  span {
    color: ${theme.colors.blacks[50]};
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  ${theme.media.S`
    padding: 0 ${theme.spacing.singleAndQuarter} 0 ${theme.spacing.singleAndHalf};
  `}
`;

const GroupedByTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.half};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.XS};
  line-height: ${theme.lineHeight.XS};
  color: ${theme.colors.blacks[50]};
`;

const DEFAULT_MAX_STEP_SIZE = 20;

const SKILL_NAME_WITH_PURPLE_COLOR = "Hiring";

function formatAveragesToDataPoints(averages = [], startDate, endDate) {
  const formattedAverages = averages.map((average) => {
    return { x: average.date, y: average.kudosCount * 2 };
  });

  const getFormattedDate = (d) => {
    const date = moment(d, "YYYY-MM-DD");
    const month = date.format('MMM');
    const day = date.format('D');
    return `${month} ${day}`;
  };

  const ratings = averages.map(average => {
    const {date, startDateOfDuration, endDateOfDuration} = average;
    // default grouped by day - we get single date
    let formattedDate = getFormattedDate(date);

    if (startDateOfDuration) {
      // grouped by duration(week/month) if there's startDateOfDuration
      formattedDate = `${getFormattedDate(startDateOfDuration)} - ${getFormattedDate(endDateOfDuration)}`;
    }
    return {
      user: { fullName: formattedDate },
      onClick: () => null,
      x: average.date,
      y: average.kudosCount * 2
    };
  });

  return {
    startDate,
    endDate,
    ratings,
    averages: formattedAverages
  };
}

export default function KudosSentChart({ 
  xAxisMaxLabels, 
  groupedBy, 
  averages, 
  startDate, 
  endDate,
  datePoints
}) {
  const data = formatAveragesToDataPoints(averages, startDate, endDate);

  return (
    <PanelBase>
      <Panel.Header.Basic>
        <HeaderContent>
          <div>
            <h2>Kudos Sent</h2>
          </div>
        </HeaderContent>
      </Panel.Header.Basic>
      <ChartWrapper>
        <SkillChartUI
          data={data}
          showLineAtTheStart={false}
          showLineAtTheEnd={false}
          maxStepSize={DEFAULT_MAX_STEP_SIZE}
          showScatterDataStyle={false}
          skillName={SKILL_NAME_WITH_PURPLE_COLOR}
          xAxisMaxLabels={xAxisMaxLabels}
          generateLabels={() => datePoints}
        />
        <GroupedByTitle> {groupedBy} </GroupedByTitle>
      </ChartWrapper>
      <Panel.Footer.Empty/>
    </PanelBase>
  );
}

KudosSentChart.propTypes = {
  averages: PropTypes.array,
  groupedBy: PropTypes.string,
  startDate: PropTypes.string, 
  endDate: PropTypes.string, 
  xAxisMaxLabels: PropTypes.number,
  datePoints: PropTypes.arrayOf(PropTypes.string)
};
