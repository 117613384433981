import React from 'react';
import styled from 'styled-components';
import Base from './Base';

const DEFAULT_HEIGHT = 249.5;
const DEFAULT_WIDTH = 247.8;
const DEFAULT_VIEWBOX = `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`;

const Diamonds = styled(Base.path).attrs({
  fillColor: '#FCD7CA',
  grayScaleColor: '#E6E6E7',
  d: `M87.7,227.3c-0.4-1.4-1.3-1.7-2.6-1c-1.2,0.7-2.3,1.4-3.5,2c-3.2,1.7-6.7,2.9-9.2,5.7
	c-0.4,0.5-1,1.2-0.9,1.7c1.1,4.3,2.3,8.5,3.5,12.7c0,0.2,0.1,0.3,0.2,0.5c0.4,0.7,1.3,0.9,1.9,0.5c1.2-0.5,2.3-1.1,3.4-1.8
	c3.2-1.9,6.4-3.8,9.7-5.8c0.2-1.1,0.8-2.1,0.6-3C89.9,234.9,88.8,231.1,87.7,227.3z M88.2,240.1c-3.6,2.4-7.3,4.7-11.3,7.2
	c-1.1-3.9-2-7.3-2.9-10.6c-0.1-0.6,0-1.1,0.4-1.6c1.5-1.9,3.5-3.4,5.8-4.1c1.9-0.6,3.7-1.4,5.4-2.3c1.1,3.3,2.2,6.5,3.2,9.7
	C88.9,239.1,88.7,239.7,88.2,240.1z M64.7,169.8c-0.9-4.1-2-8.1-3.2-12.1c-0.1-0.5-1-1.1-1.4-1c-1.5,0.4-3.3,0.7-4.4,1.7
	c-2.2,2-4.8,3.6-7.5,4.8c-0.6,0.4-1.2,0.8-1.8,1.2c-0.2,0.6-0.3,1.3-0.3,2c1.2,4.3,2.5,8.6,3.8,12.8c0.4,1.5,1.1,1.7,2.3,0.8
	c3.7-2.6,7.4-5.2,11.1-7.9C64.1,171.7,64.9,170.5,64.7,169.8z M62,170.4c-2.8,2.2-5.7,4.3-8.6,6.4c-0.5,0.3-1,0.6-1.5,0.8
	c-1.2-3.8-2.4-7.3-3.5-10.9c-0.1-0.6,0.2-1.2,0.6-1.6c2.8-1.8,5.7-3.5,8.6-5.1c1.9-1,2.2-0.9,2.9,1.2c0.8,2.4,1.5,4.8,2,7.3
	C62.7,169.2,62.5,169.9,62,170.4z M113.5,210.3c-0.8-1.7-1.6-3.5-2.6-5.2c-0.6-1-1.5-1-2.1,0c-1.9,3.5-3.9,7-5.6,10.6
	c-1.1,2.3-0.8,2.8,1.6,4.9c1.3,1,3.1,2.3,4.9,3.7c1.2,1,2.2,0.7,3.2-0.3c0.9-1.1,2-2.2,3.1-3.1c0,0,0.1,0,0.1-0.1
	c1.1-0.7,1.4-2.2,0.6-3.3C115.6,215.1,114.6,212.7,113.5,210.3z M111.4,222.5c-0.3,0.1-0.7,0-1-0.2c-1.7-1.2-3.3-2.6-4.9-4
	c-0.5-0.6-0.6-1.4-0.3-2.2c1.2-2.4,2.6-4.7,3.9-7.1c0.2-0.3,0.5-0.6,0.8-0.8c0.3,0.4,0.6,0.8,0.8,1.2c1.1,2.6,2.2,5.2,3.3,7.8
	C114.7,218.7,112.9,222.2,111.4,222.5z M143.8,217.2c-1.4-1.4-2.9-2.7-4.4-4c0,0,0,0,0,0c-1.7-1.5-3.3-3-5-4.5
	c-1-0.9-1.8-0.6-2.1,0.8c-0.8,3.1-1.5,6.1-2.1,9.2c-0.2,0.9,0,1.8,0.5,2.6c2,2,4.2,3.7,6.3,5.6c1,0.9,1.9,0.6,2.7-0.3
	c1.4-1.7,2.9-3.4,4.4-5.1C145.7,219.5,145.7,218.9,143.8,217.2z M142.2,220.6c-1.2,1.3-2.3,2.6-3.6,3.8c-0.6,0.3-1.2,0.2-1.8-0.1
	c-1.5-1.3-2.9-2.8-4.2-4.3c-0.3-0.5-0.3-1.1-0.2-1.6c0.5-2.3,1-4.5,1.6-7c0.3,0.2,0.7,0.4,1,0.6c2.3,2.1,4.7,4.3,7,6.4
	C142.9,219.1,143,219.8,142.2,220.6z M41.1,126.3c-1.7-1.5-3.4-3.1-5.1-4.6c0,0,0,0,0,0c-1.6-1.5-3.2-2.9-4.9-4.4
	c-1.7-1.5-2.5-1.2-2.9,1c-0.5,2.9-1.1,5.9-1.5,8.9c-0.1,0.9,0.2,1.8,0.8,2.5c1.8,1.8,3.8,3.5,5.7,5.2c1.1,1,1.9,0.8,2.8-0.3
	c1.6-1.9,3.3-3.8,5.1-5.6C42.2,127.9,42.2,127.2,41.1,126.3z M36.9,130.8c-2.2,2.4-2,2.2-4.7,0.4c-2.9-2-3.9-4.3-2.8-7.6
	c0.4-1.5,0.7-3,0.9-4.5c2.4,2.1,4.4,3.9,6.4,5.8C39.7,127.7,39.7,127.7,36.9,130.8z M52.4,213.6c-4.1-2.5-8.2-4.8-12.3-7.1
	c-1.2-0.7-2.1-0.1-2,1.4c0.2,0.8,0.3,1.6,0.3,2.4c-0.5,5.5,3.1,8.6,6.8,11.6c0.1,0.1,0.2,0.2,0.3,0.3c0.9,0.6,2.1,0.4,2.8-0.5
	c0.9-0.9,1.8-1.8,2.7-2.7c0,0,0,0,0,0c0.6-0.7,1.2-1.3,1.8-2C54.3,215.4,54.4,214.8,52.4,213.6z M50.6,216.1c-1,1.2-2.1,2.4-3.2,3.5
	c-0.5,0.3-1,0.4-1.6,0.2c-3.6-1.8-5.6-5.8-5.1-10.5h0c3.4,1.9,6.6,3.6,9.7,5.4C50.8,215.1,50.8,215.6,50.6,216.1z M25.1,186.8
	c-4.4-1.6-8.9-3.2-13.4-4.8c-1.1-0.4-1.9-0.2-2.1,1.1c-0.2,1.7-0.5,3.5-0.7,5.2c0,0.4,0.1,0.8,0.3,1.2c2.6,1.3,4.8,2.5,7.1,3.7
	c0.2,0.1,0.5,0.1,0.7,0c2.8-1.2,5.7-2.4,8.5-3.7c0.5-0.2,1.2-1.1,1.1-1.5C26.5,187.5,25.7,187,25.1,186.8z M22.7,188.7
	c-1.9,0.8-3.7,1.7-5.6,2.5c-0.3,0.1-0.9-0.2-1.2-0.4c-1.4-0.8-2.7-1.6-4-2.5c-0.4-0.3-0.6-0.8-0.6-1.3c0.1-0.7,0.2-1.4,0.4-2.1
	c3.9,0.2,7.4,2.1,11,3.3C22.7,188.4,22.7,188.6,22.7,188.7z M28.5,168.5c-2.5-1.7-5.3-2-8.1-2.2c-0.3,0-0.9,0.9-0.9,1.3
	c0,2.5,0.2,4.9,0.3,7.4c0,0.1,0,0.2,0,0.3c0.1,1,1,1.7,1.9,1.6c1.5,0,2.9-0.2,4.4-0.4c2.1-0.4,4-5.8,2.7-7.6
	C28.8,168.7,28.6,168.6,28.5,168.5z M25.2,174.4c-0.8,0.1-1.6,0.2-2.6,0.3c-0.4-1.7-0.8-3.2-1-4.8c-0.1-0.4,0.8-1.2,0.9-1.2
	c1.5,0.4,2.9,1,4.2,1.8C27.7,171.2,26.4,174.2,25.2,174.4z M81.3,202.3c1.6-0.9,3.2-1.8,4.7-2.8c2.8-1.8,2.9-3.7,0.3-6.2
	c-0.4-0.3-0.8-0.5-1.2-0.8c-2.6-1.3-3.9-0.7-4.6,2.1c-0.5,2-0.8,4.1-1.1,6.2c-0.1,0.5,0,1.3,0.4,1.5
	C80.2,202.6,80.8,202.6,81.3,202.3z M82.4,196c0.1-0.5,0.7-1.5,0.9-1.4c0.7,0.1,1.4,0.5,1.8,1.1c0.2,0.6,0.1,1.3-0.2,1.8
	c-0.8,0.7-1.8,1.3-2.7,1.7C82.1,198.2,82.2,197.1,82.4,196z M3.4,152.6c-0.8,0.1-1.5,0.6-1.8,1.4c-0.6,2.5-1.1,5-1.5,7.6
	c-0.2,1.4,0.5,1.9,1.7,1.3c1.6-0.8,3-1.8,4.5-2.7c2.5-1.6,2.7-4.3,0.5-6.6C5.7,153.1,4.5,152.8,3.4,152.6z M4.7,158.8
	c-0.7,0.5-1.4,0.9-2.2,1.2c0.3-2,0.6-3.6,0.9-5.3c1.7,0,2.7,0.8,2.8,2.1C6.2,157.4,5.3,158.2,4.7,158.8z M138.8,196.8
	c1.3,0.2,2.5,0.2,3.8,0.2c1.1-0.1,1.5-0.8,1.5-1.8c0-1.6,0-3.3,0-4.9c-0.1-0.6-0.3-1.3-0.5-1.9c-1.9-0.3-3.7-0.6-5.6-0.7
	c-0.3,0-0.9,0.8-0.9,1.3c0,2.2,0.2,4.4,0.5,6.6C137.7,196.1,138.2,196.6,138.8,196.8z M139.4,190.9c0-0.4,0.5-1,0.8-1
	c0.6,0,1.6,0.3,1.6,0.6c0.1,1.4,0.1,2.8,0,4.1c-0.2,0.1-0.3,0.3-0.5,0.4c-0.6-0.3-1.5-0.5-1.6-1C139.5,193,139.4,191.9,139.4,190.9z
	 M39.7,200.9c0.8,0,1.5,0.2,2.3,0.1c0.7-0.1,1.4-0.2,2.1-0.4c0.2-2.4,0.2-4.9,0-7.3c-0.1-0.7-2-1.2-3.1-1.8c-0.3-0.1-0.7-0.2-1-0.3
	c-1.7-0.5-2.4-0.1-2.4,1.6c0,2,0.1,4,0.2,6c0,0.1,0,0.2,0,0.4C37.9,200.2,38.7,200.9,39.7,200.9z M40.2,193.2
	c0.6,0.4,1.7,0.8,1.8,1.3c0.1,1.3,0,2.6-0.2,3.9c-0.5-0.1-1.5-0.1-1.5-0.3c-0.3-1.5-0.5-3-0.7-4.5C39.8,193.5,40,193.4,40.2,193.2z
	 M72.5,216.4c-0.1-0.2-0.1-0.3-0.2-0.5c-1-1.9-3.3-2.7-5.2-1.8c-1.1,0.6-3.3,8.4-2.6,9.4c0.3,0.3,0.7,0.5,1.1,0.4
	c2.3-1.3,4.5-2.6,7.1-4.2C72.7,218.6,72.7,217.5,72.5,216.4z M70.6,218.5c-0.9,0.8-1.9,1.5-3,2.1c-0.2-0.1-0.3-0.3-0.5-0.4
	c0.3-1.1,0.5-2.1,0.8-3.2c0.2-0.5,0.6-1.1,1-1.2c0.6,0,1.1,0.3,1.4,0.8C70.7,217.2,70.9,218.2,70.6,218.5z M24.1,153.3
	c1.5,0,2.9-0.1,4.4-0.4c0.1,0,0.2-0.4,0.2-0.6c0.1-1.9,0.2-3.9,0.2-5.8c-0.1-0.6-0.3-1.2-0.6-1.7c-1.8-0.3-3.5-0.6-5.3-0.8
	c-0.2,0-0.8,0.7-0.8,1.1c0,2.3,0.1,4.6,0.4,6.8C22.8,152.6,23.4,153.2,24.1,153.3z M26.6,147.1c0.2,1.3,0.1,2.6-0.1,3.9
	c0,0.2-1.1,0.3-2.1,0.6c-0.1-2-0.2-3.5-0.4-5.6C25.3,146.5,26.5,146.7,26.6,147.1z`
})``;

const Background = styled(Base.path).attrs({
  fillColor: '#FFEDC0',
  grayScaleColor: '#E6E6E7',
  d: `M63.9,76c8.6-37.6,49.3-71.9,88.2-76c52.3,6.1,104.1,45.7,94.6,103.1
  c-10.7,53.3-65,88.1-118.8,75.2C85,165,63.1,116.8,63.9,76z M172.6,30.3c5.6-3,9.4-2.9,15.2-0.2C179.5,18.4,150.5,11.4,172.6,30.3
  L172.6,30.3z M145.7,133.5c3.6-1.3,1.1-3.8,0.1-6.6C138.4,112,139.1,126.3,145.7,133.5z M123,143.9c3.8,3.5,7.8,6.5,12.8,8.1
  C132.5,148.2,128.1,144.9,123,143.9L123,143.9z M195.6,114.5c0.4-4,2.4-13.3,0.9-14.6C196.1,104.1,192.1,111.3,195.6,114.5
  L195.6,114.5z M170.1,159.9c1.1,3.4,3.9,4.8,8.1,2.9C175.7,160.7,173.5,158.3,170.1,159.9L170.1,159.9z M122.8,74
  c-2.8-1.4-3.3-0.1-4.3,2.1C118,80.4,121.5,75.2,122.8,74z M148.1,24.9c1.3,1.8,5.5,3.1,7.7,5C154.4,26.7,151.3,25.3,148.1,24.9z
   M117.3,139c2,1.4,4,5.6,5.5,1.9C120.8,140.3,119.1,138.9,117.3,139z M201.9,67.3c1.1-0.8,2.1-5.3-1-4.1
  C201.2,64.6,201.6,65.9,201.9,67.3L201.9,67.3z M123.9,113.7c0.2,2.3,1,4.9,2,5.9C125.5,117.5,124.9,115.5,123.9,113.7z
   M152.8,34.8c-1.2-2-1.5-2.3-2.6-2.2C150.4,33.9,151.5,34.2,152.8,34.8L152.8,34.8z M133.9,24.8c-1,0.6-1.8,1.3-2.4,2.3
  C132.7,26.4,135.2,26.4,133.9,24.8L133.9,24.8z M127.8,123.4c-0.7-1.5-0.6-4.1-1.7-4.5C126.3,120.4,126.2,122,127.8,123.4z
   M85.6,90.3c-0.2,1.8-0.2,2.1,0.6,3.1C86.1,92.3,86.6,91.3,85.6,90.3L85.6,90.3z M199.2,71.6c0.8,0.7,1,1.8,1.8,1.5
  C201.3,71.4,201.1,71.1,199.2,71.6L199.2,71.6z M149,19.5c-0.3,0.8,1.3,0.6,2,1C150.6,19,149.6,19.6,149,19.5L149,19.5z M86.5,97.8
  c0.7-0.1,0.4-1.5-0.3-2.3C86.3,96.5,86.4,97.2,86.5,97.8z M86,94.6c0.2,0.3,0.5,0.3,0.6,0C86.4,94.2,86.2,94.1,86,94.6z M149,138.1
  c0.8,0.1,0.9-0.1,0.9-0.9C149.6,137.6,149.3,137.8,149,138.1L149,138.1z`,
})``;

const Overlay = styled(Base.path).attrs({
  fillColor: '#F9BAA5',
  grayScaleColor: '#D8D8D9',
  d: `M233.6,92.5c1.3-6.6-2.4-14.4-3.4-21.4c0.6-7.1-3.7-13.4-4.3-19.8c0.4-3.3-4.5-6-5.6-9.1
  c-1.7-6-10.7-9-11.5-14.6c-9.8-9.5-20.8-11.5-33.8-18.6C169.2,5.3,158,8.6,152,3.9c-2.4,5.1-8-3.3-12.1-0.2
  c-48.5,8.2-85.9,40.1-80.6,93c1.6,21.3,1.9-5.8,4.1-11.1c-1.9-3.2,2.4-4.3,0.7-6.8c3.4-2.4,3.8-1.2,4.1-5.9
  c-2.8-11,7.6-35.4,19.7-42.8c-2.6,4.9-3,10.3-1.4,14.5c0.5,3-1.1,4.6-1,8.3c4.3,5.9,6.3,10.5,6.9,17.5c3-0.1-0.4,7.4-0.9,4.4
  c-1.2-2.9-1.1,4.9-1.5,6c-0.4,4.2-1.1,8.3-2.3,12.4c1.2-0.9,1.2,1.9,1.3,2.8c0.1,1.5-2.5,1.4-1.1,2.8c-1.1,5.5-2.1,11.5-0.2,16.1
  c-1.7,5.4-2.3,10.9,0.5,15.7c0.6,21.9,18.4-2.5,24.9-8.2c3.5-3.8,18.8-14.6,7-7.6c0.1-1.6,2.6-2.7,3.3-4.4c8-0.8,11-11.1,4.3-14.7
  c-4.1-4.4-11.9-2.2-12.5-9c-1.2-0.7-0.6,1-0.4,1.7c-0.4,3.4-3.2-4.1-3.7-1.2c-2.1-2.8,1.1-3.7,2.6-1.4c0.8-2.8-6.5-5.6-9.4-6.4
  c-3.8,0.5,0.8,3.4-0.3,5.1c-4.2-2.8-1-7.3-3.2-10.6c-1.4-6.9-1.1-11.1-4.5-18.3c-2.4-6.8,7.9-11.4,13.5-14
  c2.3-8.1,8.1-1.9,12.4-2.2c-3.9-0.6,6.4-5.5,8.3-5.8c-0.8,0.7-5.4,4.7-1.6,3.2c4.9-1.6,4.2-11,9.8-9.4c4.1,3,4.2-5.7,5.9-7.2
  c4.6,0,5.6,4.7,9.4,6.3c1.1,5.2-2.1,9.7-0.4,14.3c-0.6-3.2,1.8-3.9,1.2-6.9c1.9-1.9,0.2,6.9,0.7,8.6c-1.1,3,0.7,7.4-3.4,7.7
  c0.5,0.3,1,0.3,1.4,0.6c0.9,2,0.7,4.7-1.2,5.5c1.4,2.9,0.5,7.8,4.1,11.1c3.3,1.4,1.6-6.5,1.6-8.9c-0.2-6.3,5.2-12.9,4.1-20.1
  c4.6-6.7,3.6-21.6,14.9-23.4c1.2,0.7-2,5.9,0.5,4.2c-0.8,3.6-0.7,7.2-2.6,10.5c3.2-1.8,1.3,2.1,1,3.6c3.1,2.1,11.2,4.2,14.1-0.1
  c15-6,24.4,8.7,25,15.2c-2.2-0.5-3.7-2.7-6.1-2.4c0.8-1.4,0-2.9-1.3-3.7c-0.8,3.8-1.6-0.6-3.5-0.8c0.7,4.4,1.4,3.8-0.4,7.5
  c3.6,6.7,5,14.5,8.8,21.6c2.4,5.3,0.8,5-1.1,0.4c-1.6,3,4.4,5.9,1.7,6.2c2.5,6.8-2.2,2.3-3.9,3.5c10.8,5.5-8.8,0.6-5.2-4.3
  c0.2-1.2,0.2-1.4-0.9-1.8c-1.6-1.6,0.7-6-2.4-7.2c-7.3-7.1-10.7-8-17.9-0.1c-1.9,1.1-4.7,9.8-1.7,5c1.1,1.4-1.3,7-2.4,4.1
  c-0.4,8.6,9.2,14.7,8.5,23.3c-3.4,9.6,3.5,18.2,1.8,27.6c0.6,10.4-18.4,36,5.1,29.7c10.4-4.4,3.4,3.6-2.1,5.8
  c-6.1,3.4-14,7-20.1,6.2c-4.4-0.6,0.4,1.5-0.9,1.5c-4,0.5-6.9-1.8-10.9-0.2c-23.6,2.9-8.2-15.6-5.3-27.9c-0.6-4.5-9.8,2-4.4,0.6
  c3.2,0.1-5.6,7.4-2.5-0.1c-6.4,3.6-14.1,6.8-17.9,13.3c-1.6,0.6-3-0.3-1.2-0.5c-0.1-5.6-8.4,0-12.5-1.3c-7.4-2.1-16.4-6.3-24.2-5.2
  c-4.3-0.4-9.2-7-9.9-3.8c1.6-4.2-4.1-9.4-1.7-2c-2.6-2.7-4-7.9-6.9-11c-1.3-4.6-12.2-33.6-7.8-14.6c8.1,28.3,7.5,19.6,25.3,40.9
  c20.8,19.4,49,15.8,75.8,18.1c12.9-3.5,32.3-12.9,38.3-21.5c7-4,12.2-10.4,14.7-18.1c2.2,1.5-1.2,4.8-0.6,6.8
  c2.7-2.8,3.3-7.3,6.1-11.6C229.1,120.2,235.2,105.8,233.6,92.5z M63.4,62.5c-0.3-0.1-0.1-0.2,0-0.4C63.6,62.2,63.5,62.4,63.4,62.5z
   M65.4,75.4c-0.2-0.1-0.3-0.2-0.1-0.3C65.5,75.1,65.5,75.3,65.4,75.4z M66.6,74.9c-0.5-0.1-0.8-0.3-0.7-0.7
  C66.4,73.6,67.1,74.3,66.6,74.9z M86.1,27.7c-0.9-1.1,1.4-2.3,2.4-2.4C88,26.4,88,26.4,86.1,27.7z M88.1,54.7
  c-0.9-1.9,0.5-3.4,1.1-1.7C89.8,54.9,90.5,55.7,88.1,54.7z M90.6,34.3c0,0.8-1,0.6-1.1,0C89.2,33,91,32.6,90.6,34.3L90.6,34.3z
   M92.3,37.3c0-0.1-0.1-0.1-0.2-0.3C92.4,36.8,92.6,37,92.3,37.3L92.3,37.3z M88.5,113.9c-0.2-0.1-0.2-0.2,0-0.3
  C88.6,113.7,88.6,113.8,88.5,113.9L88.5,113.9z M88.8,97.8c-0.1-0.1-0.2-0.3,0-0.4C89,97.6,89.1,97.7,88.8,97.8L88.8,97.8z
   M89.7,128.1c-0.5-0.6-0.3-1.2-0.1-1.8C90,126.2,90.4,128.3,89.7,128.1L89.7,128.1z M91.8,123.5c-0.1-0.1-0.1-0.2,0-0.2
  C92,123.4,91.9,123.4,91.8,123.5L91.8,123.5z M93.1,81.1c-0.4-0.3-0.3-0.3,0-0.5C93.5,80.6,93.2,80.9,93.1,81.1L93.1,81.1z
   M128.8,100.8c0.9,0.5,1.4,1.1,0.9,2.1C128.4,103.1,128.9,101.7,128.8,100.8L128.8,100.8z M125.6,103.2c-1.9-0.4-2.4-0.8-2.5-2.3
  C125.2,101.5,125.7,102,125.6,103.2L125.6,103.2z M124.6,95.1c1.9-1.2,4.6,3.3,2.2,2.6c-1.5-1.7-3.8,2.5-4.6-0.4
  C118.8,93.4,122.5,93,124.6,95.1L124.6,95.1z M114.1,108.8c-0.9,0-0.6-1.6,0.3-1.4C115.6,107.5,115.1,108.6,114.1,108.8
  L114.1,108.8z M114.3,94c9,7.1,0,13.7,0.5,7.9c3.1,1.6,3-3.8,0.1-4.1C114.4,97.7,114.3,95,114.3,94z M113.6,93.7
  c-0.4-0.5-0.6-1.2-0.6-1.8C114.1,92.5,114.1,92.6,113.6,93.7z M111.6,87.1c-0.2,0.6-0.5,0.1-0.7-0.2
  C111.1,87,111.4,87.1,111.6,87.1L111.6,87.1z M109.7,83.4c0.1,0,0.2,0.1,0.3,0.2C109.6,84,109.1,83.4,109.7,83.4L109.7,83.4z
   M108.6,88.8c0.2,0.3,0.4,0.4,0,0.7C108.3,89.2,108.2,89,108.6,88.8L108.6,88.8z M105.8,110.3c0.4-0.9,0.1-2.1,1.4-2.4
  C107.7,108.9,107.3,109.6,105.8,110.3L105.8,110.3z M106,89h1.5C106.9,89.4,106.6,89.5,106,89L106,89z M104.6,80.5
  c3-0.6,1.3,2.5,2.2,4.1C105.4,84.2,104.3,82.4,104.6,80.5L104.6,80.5z M102.9,101.5c0.4,0.4,0.4,0.4,0.2,1.1
  C102.7,102.1,102.7,102.1,102.9,101.5L102.9,101.5z M101.7,98c0,0.2,0.3,0.7-0.1,0.7C101.6,98.5,101.3,98,101.7,98L101.7,98z
   M98.1,92.8c2.6,0.4,3.9,7.3,1.2,2.8C99.2,94.1,96.9,94.6,98.1,92.8L98.1,92.8z M94,134.6c-0.4-0.1-0.1-0.3,0-0.4
  C94.3,134.2,94.1,134.5,94,134.6L94,134.6z M94.4,49c0.1-0.3,0.2-0.1,0.4,0C94.6,49.3,94.5,49.3,94.4,49L94.4,49z M94.6,33.8
  c0.2-0.2,0.4-0.3,0.6-0.5C95.4,33.8,95.4,33.8,94.6,33.8L94.6,33.8z M96.6,134.3c0.3,1.1,0.3,1.2-0.8,1.7
  C95.3,135.1,96.6,135,96.6,134.3L96.6,134.3z M94.6,109.3c-0.2-0.9-0.2-0.9,0.7-1.3C95.6,109,95.5,109.1,94.6,109.3L94.6,109.3z
   M96.8,22.5c-2.9,6.9-5.6,0.6,0.4-0.5C97,22.1,97,22.4,96.8,22.5z M100.8,23.6c0.3,0.2,0.2,0.3,0,0.5
  C100.6,24,100.6,23.9,100.8,23.6L100.8,23.6z M100.4,22.1c0,0.8-0.2,1.2-0.9,1.4C99.7,22.9,99.7,22.5,100.4,22.1L100.4,22.1z
   M99.6,24.5c0.3,0,0.3,0.4,0,0.4c-0.1,0-0.2-0.1-0.2-0.2C99.4,24.6,99.5,24.5,99.6,24.5L99.6,24.5z M98.7,31.3
  c0.2-0.5,0.4-0.7,0.8-0.5C99.7,31.3,99,31.1,98.7,31.3L98.7,31.3z M101.6,28.5c-0.4,0.5-0.8,1-1.4,1.4C97.3,31,101,25.4,101.6,28.5
  L101.6,28.5z M101.8,23.4c-0.1,0-0.2-0.1-0.3-0.2C101.9,22.8,102.3,23.2,101.8,23.4L101.8,23.4z M117,37c-0.7,0.1-0.8-0.6-1.1-1.2
  C117,35.3,118,36.2,117,37L117,37z M124.3,11.1c-0.3,0.6-0.3,0.6-1.1,0.4C123.4,10.9,123.9,11.2,124.3,11.1L124.3,11.1z
   M124.2,27.7c-1,1.3-1.5-0.4-0.5-0.7c0.2-0.1,0.5,0.1,0.5,0.3C124.3,27.4,124.2,27.5,124.2,27.7L124.2,27.7z M124.4,11.1
  c0.3-1.3,1.7-1.2,2.7-0.5C126.2,11,125.4,11.5,124.4,11.1L124.4,11.1z M127.9,10.3c-0.1,0-0.3-0.1-0.6-0.2
  C128.1,9.5,128.7,10,127.9,10.3L127.9,10.3z M130.1,10.6c-0.6,0.1-0.9-0.2-1.2-1.1C130.1,9.2,132,9.8,130.1,10.6L130.1,10.6z
   M131.8,9.5c-0.4-0.3-0.4-0.8-0.3-1.4c0.7,1,0.8,1.2,2,1.4C133,9.9,132.3,9.9,131.8,9.5L131.8,9.5z M134.3,8.9
  c-0.9,0,0.1-0.7,0.3-0.3C134.5,8.7,134.4,8.8,134.3,8.9L134.3,8.9z M138.7,25.6C139.1,25.4,138.9,25.5,138.7,25.6
  C138.4,25.4,139.2,24.9,138.7,25.6L138.7,25.6z M138.6,21.5c0.2-0.2,0.3,0,0.2,0.2C138.7,21.9,138.6,21.7,138.6,21.5L138.6,21.5z
   M141,24.2c-0.2-1-0.2-1.1,0.8-1.3C141.5,23.4,141.3,23.7,141,24.2L141,24.2z M140,8c-4.3-0.7,0.5-2.4,2.5-1.5
  C142.2,7.9,140.9,7.8,140,8L140,8z M143.6,7.4c-0.2-0.2-0.1-0.2,0.1-0.3C143.9,7.2,143.7,7.3,143.6,7.4L143.6,7.4z M149.7,8.6
  c-1.2-0.3-1.7-2.3,0-2.1C152.5,6,152.3,9.8,149.7,8.6L149.7,8.6z M150.6,18.6c-0.7-1-0.5-1.6,0.9-1.8
  C152,17.9,151.9,18.1,150.6,18.6L150.6,18.6z M153.6,12.7c-0.9,0.3-1.9-0.1-2.2-1c-0.2-0.6-0.1-1.3,0.4-1.8
  C153.1,9.3,153.9,11.6,153.6,12.7L153.6,12.7z M154.9,10.7c-1.5-1.3-2.9-5.3,0.4-3.6C155.2,8.7,156.3,9,154.9,10.7L154.9,10.7z
   M157.2,12.2c-0.1-0.9-0.7-1.6,0.5-2.1c0.5,0.5,0.5,1.3,0,1.7C157.5,12,157.3,12.1,157.2,12.2L157.2,12.2z M159.1,26.7
  C159.2,26.2,159.2,26.5,159.1,26.7C158.7,26.9,158.9,25.9,159.1,26.7L159.1,26.7z M167.4,19.2c-0.2-0.2-0.2-0.4,0.1-0.4
  C167.7,19,167.7,19.1,167.4,19.2L167.4,19.2z M168.6,15.6c-0.7,0.7-1.3-0.7-0.4-0.9C169,14.6,169.2,15.1,168.6,15.6L168.6,15.6z
   M176.5,28.2c-0.3-0.2-0.4-0.5-0.1-0.6C176.7,27.8,176.8,28,176.5,28.2L176.5,28.2z M184.9,33.2c-0.8,0.4-3.2,0.3-1.8-0.7
  C183.7,32.3,185.9,32.1,184.9,33.2L184.9,33.2z M185.1,27.1c-0.3-0.2-0.2-0.3,0-0.5C185.4,26.8,185.3,26.9,185.1,27.1L185.1,27.1z
   M183,18.1c-3.4-3.6-1.1-5.7,2.5-2.1C183.9,16.2,183.9,17.6,183,18.1L183,18.1z M191.4,24.3c-0.4-0.4-0.4-0.5,0-0.8
  C191.7,23.9,191.8,24,191.4,24.3L191.4,24.3z M193.3,26.3c-0.2,0-0.2-0.1-0.1-0.3C193.4,26.1,193.5,26.3,193.3,26.3L193.3,26.3z
   M199.7,23.6c0-0.6,0-0.6,1.5-1.3C201.3,23.1,201.1,23.3,199.7,23.6L199.7,23.6z M201.1,24.6c-0.1-0.1-0.1-0.2,0.1-0.3
  C201.3,24.4,201.3,24.5,201.1,24.6L201.1,24.6z M210.4,59.3c-0.6-0.4-0.7-1.2-0.3-1.7c0.1-0.1,0.1-0.2,0.2-0.2
  C210.7,57.9,210.8,58.7,210.4,59.3L210.4,59.3z M184.2,148.5c0.2,0.2,0.3,0.3,0,0.4C183.9,148.9,184.1,148.7,184.2,148.5
  L184.2,148.5z M184.9,151.3c-0.2,1.3-1.6,0.7-1.1-0.3C183.9,150.2,185.2,150.5,184.9,151.3L184.9,151.3z M185.3,148.3
  c-0.3-0.2-0.2-0.3,0.1-0.4C185.6,148,185.5,148.2,185.3,148.3L185.3,148.3z M186.2,147.1c-0.2-0.7,0.3-0.9,0.6-1.3
  C187.2,146.2,186.4,146.6,186.2,147.1L186.2,147.1z M187.6,143.9c-0.1-0.3-0.1-0.4,0.1-0.6C187.9,143.6,187.9,143.7,187.6,143.9
  L187.6,143.9z M191.7,63.2c-1.3,0.5-0.4,1.7-1,2.5C190.1,64.2,189.1,62,191.7,63.2L191.7,63.2z M191.4,131.2
  c-1.2-0.2-0.6-3.3,0-4.1C191.7,128.5,191.7,129.9,191.4,131.2L191.4,131.2z M188.8,64c-0.6,0.3-0.8,1.2-1.6,1
  C187.6,63.6,187.8,63.4,188.8,64L188.8,64z M186.3,65.1c0.1,0.1,0.3,0.2,0.1,0.3C186.2,65.4,186.2,65.2,186.3,65.1L186.3,65.1z
   M181.7,69.7c2.5-2.1,2.6-2.1,1.8,0.6C183,70.1,182.5,69.9,181.7,69.7L181.7,69.7z M184.5,84.4c-1.4-0.8,0.1-2.5-1.6-2.9
  C180.9,78.8,186.1,81.9,184.5,84.4L184.5,84.4z M186.5,67.2c-0.5,0.5-1.2,0.5-1.8,0.2C185.3,66.7,186.9,65.8,186.5,67.2L186.5,67.2
  z M188,107.3c-0.4,0.1-0.4-1.4,0-1.3V107.3z M189.1,72.8c-3.7,3.3-1.8-2.7,0.4-2.1C191.1,70.7,190.4,72.6,189.1,72.8L189.1,72.8z
   M190.1,79.3c0,0.2-0.1,0.3-0.3,0.2C189.9,79.3,189.9,79.1,190.1,79.3L190.1,79.3z M189.3,114.4c2.5-2.7,1.8,2.2,1.4,3.7
  C190.1,116.9,190.4,115.4,189.3,114.4L189.3,114.4z M190.8,149.2c-1.4,0.2-0.7-2.1,1.2-1.2C191.6,148.5,191.2,148.9,190.8,149.2
  L190.8,149.2z M192.3,65.8c0.1-0.7,0.2-1.3,0.3-1.8C193.7,63.6,194.1,66.1,192.3,65.8L192.3,65.8z M198.5,125.2
  c-0.4-0.1-0.5-0.3-0.2-0.5C198.6,124.8,198.9,125,198.5,125.2L198.5,125.2z M199,109.9c-0.7-0.7-0.8-1.9-0.2-2.7
  C199.8,107.3,199.5,109.1,199,109.9L199,109.9z M68.1,120.6c-0.3,0.1-0.4-0.7-0.1-0.7C68.2,120,68.3,120.3,68.1,120.6
  C68.1,120.6,68.1,120.6,68.1,120.6L68.1,120.6z M68.6,122.3c-0.2-0.1-0.2-0.3,0-0.4C68.7,122.1,68.8,122.3,68.6,122.3L68.6,122.3z
   M93.5,151c-2.8-1-3-1.6,0.2-1.2C94.8,149.8,94.5,151.5,93.5,151L93.5,151z M95.3,151.4c-0.5-0.8-0.4-0.8,0.5-0.8
  C95.6,150.9,95.5,151.1,95.3,151.4L95.3,151.4z M97.7,163.7c-0.3-0.1-0.2-0.2,0-0.4C98,163.3,97.9,163.5,97.7,163.7L97.7,163.7z
   M101.5,161.7c-5.1-4.6,1.3-1.5,3.8,0.5C103.9,162.6,102.6,162.7,101.5,161.7L101.5,161.7z M122.8,159.8c-0.7-0.1-1.5-0.1-1.7-1
  C121.5,157.9,125,159.5,122.8,159.8L122.8,159.8z M136.2,170c-0.4,0.3-0.7,0-1.1-0.2C135.8,169.5,135.8,169.5,136.2,170L136.2,170z
   M134.2,165.5c-0.5,0.6-0.9,0.4-1.3,0.2C133.3,165,133.7,165.3,134.2,165.5L134.2,165.5z M133,158.5c0,0.6-0.7,0.7-1,0H133z
   M131.6,169.1c-0.2,0.4-0.3,0.7-0.4,1C131.1,169.7,130.6,169.3,131.6,169.1L131.6,169.1z M131.2,170.1c2.4-0.3,2.3-0.4,4,0.8
  C138.3,171.9,131.6,172.1,131.2,170.1L131.2,170.1z M138.6,170.6c-0.2-0.1-0.8,0.1-0.7-0.3C138.2,170.4,138.7,170.2,138.6,170.6
  L138.6,170.6z M138,162.9c-0.7-0.8,0.4-1.1,1-1.3C139.1,162.3,138.6,162.6,138,162.9L138,162.9z M139,161.5
  c0.1-0.3,0.2-0.7,0.8-0.6C139.8,161.5,139.4,161.5,139,161.5L139,161.5z M140.2,168.5c0.4-0.9,2.5-2.6,2.9-1.2
  C143,169,141.5,169.8,140.2,168.5L140.2,168.5z M140.4,171c1.5-0.2,4.8-0.8,5,1.1C143.6,172.3,141.9,171.9,140.4,171L140.4,171z
   M205.2,106.2c-0.3-1.2-0.1-1.7,0.9-2.2C206.5,105,206.2,105.8,205.2,106.2L205.2,106.2z M209.6,87.9c0.3,0.2,0.6,0.3,1,0.5
  C209.9,88.8,209.9,88.8,209.6,87.9L209.6,87.9z M215.7,51.9c0.1,0,0.2,0.1,0.5,0.3C215.7,52.7,215.1,52.1,215.7,51.9L215.7,51.9z
   M213.6,140.3c0.2-0.6,0.4-0.9,1-0.8C214.8,140.6,214.7,140.6,213.6,140.3L213.6,140.3z M217.4,121.9c-1.1,2.2-1.6,6.5-3.7,7.6
  C214.2,127.7,217.1,119.5,217.4,121.9L217.4,121.9z M217.8,136.9v-1.1C218.4,136.2,218.4,136.2,217.8,136.9L217.8,136.9z
   M218.5,135.3c-0.2-0.9,0.4-1.5,0.9-1.1C219.2,134.5,218.9,134.8,218.5,135.3L218.5,135.3z M221.5,80.1c0.4,0,0.2,0.6,0,0.8
  C221,80.9,221.4,80.4,221.5,80.1L221.5,80.1z M220.9,129.7c0.1,0.2,0.2,0.3-0.1,0.4C220.6,129.9,220.5,129.8,220.9,129.7
  L220.9,129.7z M219.8,130.7c0.6-1.1,0.8,0.7,1.2,1.4C220.2,131.6,219.2,131.6,219.8,130.7L219.8,130.7z M219.9,132.5
  c0.1,0,0.2,0.1,0.4,0.1C220.9,133.3,219.2,133.1,219.9,132.5L219.9,132.5z M220.8,136.6c-0.8,0.1-0.6-0.9-1-1.7
  C221.1,135.3,221.4,135.5,220.8,136.6L220.8,136.6z M221.5,134.4c0-0.3-0.5-0.9-0.1-1C221.5,133.7,222,134.3,221.5,134.4
  L221.5,134.4z M223.7,122c-0.3,0.2-0.2-0.2-0.3-0.4C223.7,121.5,223.7,121.7,223.7,122L223.7,122z M224.4,93.7
  c-0.6-0.3-0.2-0.6-0.2-0.9C224.6,92.7,224.3,93.3,224.4,93.7L224.4,93.7z M231.4,99c0,0.4-0.1,0.7-0.4,1.1
  c-0.5-4.6-0.2-9.2,0.8-13.6C232.7,90.6,231.6,94.8,231.4,99L231.4,99z M85.1,42.6C85.1,42.6,85.1,42.6,85.1,42.6L85.1,42.6z
   M158.1,80.4c-12.2-7.3-4.5,9-1.2,13.9c-2.2,0.5-0.2,1.6-1,2.8c-3.5,2.7-3-0.6-2.8,4c-0.4,5.7-1.1,17.9,5.4,20.5
  c-0.1,11.1,9-10.6,8.1-14.4c0.4-3.9,0.6-8.3-3-9.9C162.9,91.8,158.7,86.6,158.1,80.4z M154.7,100.7c-0.2-0.2-0.3-0.3,0-0.5
  C155,100.3,155,100.4,154.7,100.7z M155.7,110.3c-1.2-2.5,0.4-5.3,1.6-7.4C157.3,105.4,156.8,107.9,155.7,110.3L155.7,110.3z
   M157.7,117.3c-0.4-0.7-0.4-1.5,0-2.2C158,115.8,158,116.6,157.7,117.3z M159.1,119.2C158.7,119.2,158.9,119.2,159.1,119.2
  C159.3,119.6,158.5,119.6,159.1,119.2L159.1,119.2z M155.7,95.6C155.7,95.6,155.7,95.6,155.7,95.6L155.7,95.6z M65.7,110.1
  c3.2,8.8,1.7-2.4-0.1-5.6C65.6,106.5,64.8,108.5,65.7,110.1z M64.2,98.4c0.6,1.6-0.5,4.4,1.7,4.7C65.8,101.7,66.4,94.7,64.2,98.4z
   M77.1,133.9c-1.7-1.2-1.9-8.4-3.7-7.1C73.8,128.9,78.1,138.5,77.1,133.9z M153.5,95c-0.6,2,1.5,3.5,2.1,1
  C155.8,95.4,153.9,94.4,153.5,95z M155.7,95.6L155.7,95.6C155.6,95.6,155.7,95.6,155.7,95.6z M65.8,82.1c1-0.6,1.2-1.4,0.7-3
  C66.2,80.2,64.9,80.8,65.8,82.1z M66.4,128.1c-0.7-0.5,0.6-1.3-0.7-1.5C64.8,127.6,67.6,130.8,66.4,128.1z M78.5,138.5
  c0.4-1.6,0.3-1.9-1-1.8C77.2,137.6,78.2,137.9,78.5,138.5z M152.1,54.6c0.2-0.9,0.9-3.1-0.2-3.4C152.3,52.4,151.4,53.5,152.1,54.6z
   M64.6,122.6c-0.7-1.4-1.6-0.5-0.8,0.6C64.2,123.5,64.8,123.2,64.6,122.6z M64.6,95c0,1-0.4,2.2,1.2,1.6C65.2,96,65.4,95.3,64.6,95
  z M70.4,119.8c0.4,0.9,0.7,1.6,1,2.4C71.8,121.3,71.7,121,70.4,119.8z M62.3,119.1c0,0.4,0.8,0.4,0.8,0.3
  C63.6,118.3,62.9,118.4,62.3,119.1z M61.2,108.4c0-0.4,0-0.8-0.1-1.2C60.5,107.9,60.5,107.9,61.2,108.4z M87,114.4
  c0.4-0.7-0.8-0.8-1-0.2C86.3,114.6,86.6,114.7,87,114.4z M85.1,42.6L85.1,42.6C85.1,42.6,85.1,42.6,85.1,42.6z M84,41.5
  c0.1,0.7,0.4,1.1,1.1,1.1C85.1,41.9,84.7,41.6,84,41.5z M72.7,125.9c0.5-0.4,0.4-0.9,0-1.3V125.9z M178.5,74.5
  c0.1,0.2-0.1,0.7,0.2,0.6C178.6,74.9,178.8,74.4,178.5,74.5z M152.5,48c-0.2,0.2,0,0.3,0.1,0.4C152.7,48.2,152.7,48.1,152.5,48z
   M128.3,109c-0.3,0-0.2,0.1-0.2,0.4C128.4,109.3,128.3,109.2,128.3,109z M64.5,125.6c-0.2,0-0.2,0.2-0.1,0.4
  C64.7,125.9,64.8,125.7,64.5,125.6z M91.9,71.7c-0.3-0.3-0.4,0.1-0.1,0.4C91.9,72,91.9,71.9,91.9,71.7z M65.8,78.7
  c-0.3,0-0.1,0.5,0.1,0.2C65.9,78.8,65.8,78.7,65.8,78.7z M64.9,128.5c-0.1,0.1-0.2,0.3,0,0.3C65,128.7,65,128.6,64.9,128.5z
   M64,120.5c-0.1-0.1-0.2-0.2-0.3,0C63.7,120.7,63.8,120.6,64,120.5z M123.2,96.5c-0.1-0.1-0.2-0.1-0.3,0.1
  C123,96.7,123.1,96.7,123.2,96.5z M122.8,94.7c-0.1,0-0.2-0.1-0.2,0C122.6,94.9,122.7,94.9,122.8,94.7z`,
})``;

const Jupiter = (props) => {
  return (
    <Base
      {...props}
      height={DEFAULT_HEIGHT}
      width={DEFAULT_WIDTH}
      viewBox={DEFAULT_VIEWBOX}
    >
      <Diamonds/>
      <Background />
      <Overlay />
    </Base>
  );
};

export default Jupiter;