import { useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { compose } from 'recompose';
import {
  withFeedbackInvitationTokenLogin,
} from 'hocs';
import { Resources } from '@matterapp/routing';
import Toast from 'components/Toast/Toast';
import { useNavigate } from 'react-router-dom';

function WelcomePageContainer(props) {
  const {
    queryParams,
    authorizedPath,
    handleFeedbackInvitationTokenLogin,
  } = props;
  const navigate = useNavigate();
  const { 
    currentUser, 
    isLoadingCurrentUser, 
    isAuthenticating,
    refetchCurrentUser,
    signOut
  } = useContext(UserContext);

  function shouldAttemptFeedbackTokenLogin() {
    const feedbackInvitationToken = queryParams.token;

    return !!feedbackInvitationToken;
  }

  async function proceed() {
    if (shouldAttemptFeedbackTokenLogin()) {
      await handleFeedbackInvitationTokenLogin({
        feedbackInvitationToken: queryParams.token,
        currentUser,
        signOut,
        refetchCurrentUser,
        onSuccess: () => {
          navigate(authorizedPath);
        },
        onSameUserCurrentlyLoggedIn: () => {
          navigate(authorizedPath);
        },
        onFailure: () => {
          Toast.error('Something went wrong with invitation token login');
          
          navigate(Resources.homeMain.path());
        },
      });
    } else {
      navigate(authorizedPath);
    }
  }

  useEffect(() => {
    if (!isLoadingCurrentUser && !isAuthenticating) {
      proceed();
    }
  }, [isLoadingCurrentUser, currentUser, isAuthenticating]);

  return null;
}

export default compose(
  withFeedbackInvitationTokenLogin
)(WelcomePageContainer);
