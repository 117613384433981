import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@matterapp/matter-ui';
import { PhoneNumberField } from '../../../../form-fields';
import {
  FIELD_LABEL_AUTH_PHONE_NUMBER,
  PLACEHOLDER_AUTH_PHONE_NUMBER,
  BUTTON_LABEL_LETS_GO,
} from '../../../../copy-consts';
import NextStepButton from 'components/molecules/NextStepButton';

const PhoneNumberForm = ({ handleSubmit, invalid, submitting, pristine }) => (
  <Form onSubmit={handleSubmit} centered>
    <PhoneNumberField
      autoFocus
      label={FIELD_LABEL_AUTH_PHONE_NUMBER}
      placeholder={PLACEHOLDER_AUTH_PHONE_NUMBER}
      marginBottom={24}
    />
    <NextStepButton
      onClick={handleSubmit}
      invalid={invalid}
      submitting={submitting}
      pristine={pristine}
      size="medium"
      fluid
    >
      {BUTTON_LABEL_LETS_GO}
    </NextStepButton>
  </Form>
);

PhoneNumberForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default PhoneNumberForm;
