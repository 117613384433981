import React from 'react';
import { Modal } from '@matterapp/matter-ui';
import styled from 'styled-components';
import { Box, Typography, Divider } from '@mui/material';

const StyledModalBody = styled(Modal.Body)(({ theme }) => ({
  padding: theme.spacing(3)
}));

const StyledModal = styled(Modal)({
  zIndex: 120
});

const CoinsWrapper = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(2)
}));

export default function ConfirmModal(props) {
  const { header, onClose, isOpen, coinsReceived, error, isPulse } = props;

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      style={{ zIndex: 1000}}
    >
      <Modal.Header
        header={header}
      />
      {!error && (
        <StyledModalBody>
          <Typography variant="body1" data-rc='response-sent-confirmation'>
            Your responses have been successfully sent.
          </Typography>
          {coinsReceived ? (
            <CoinsWrapper>
              <Typography color="blacks.60" variant='body2'>
                <b>Ⓜ️ Coin Reward Ⓜ️</b>
              </Typography>
              <StyledDivider />
              <Typography variant='body1'>
                Congrats! You’ve received <b>{coinsReceived}</b> coins for completing the{isPulse ? ' pulse' : ''} survey.
              </Typography>
            </CoinsWrapper>
          ) : null}
        </StyledModalBody>
      )}
      <Modal.Footer.WithClose
        onClickClose={onClose}
      />
    </StyledModal>
  );
};

