import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import CheckboxComponent from '../../Checkbox/Checkbox';
import { Clickable } from '../../Clickable/Clickable';
import TooltipComponent from '../../Tooltip';
import {
  getCheckColor,
  getItemBackgroundColor,
  getItemHighlightColor,
  getItemTextColor,
  getItemSizeStyles,
  IconContainer,
  VARIANTS,
} from '../sharedStyles';

const getBackgroundColor = ({ color, disabled, error, isSelected }) => {
  const variantBase = isSelected ? VARIANTS.SELECTED : VARIANTS.BASE;
  const variant = disabled ? VARIANTS.DISABLED : variantBase;
  return getItemBackgroundColor({
    color,
    variant: error ? VARIANTS.ERROR : variant,
  });
};

const getTextColor = ({ color, disabled, error }) => {
  const variant = disabled ? VARIANTS.DISABLED : VARIANTS.BASE;
  return getItemTextColor({ color, variant: error ? VARIANTS.ERROR : variant });
};
  

const getHighlightColor = ({ color, error }) =>
  getItemHighlightColor({
    color,
    variant: error ? VARIANTS.ERROR : VARIANTS.BASE,
  });

export const Container = styled.div`
  color: ${getTextColor};
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  background: ${getBackgroundColor};
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.blacks[20]};
  }

  ${getItemSizeStyles}

  & ${IconContainer} svg path {
    fill: ${(props) => getCheckColor({ ...props, variant: VARIANTS.BASE })};
  }

  ${({ disabled }) => disabled ? `
    cursor: default;
    &:hover {
      background: white;
    }
  ` : ``}

  ${({ color, error, isHighlighted }) =>
    isHighlighted &&
    `
    background: ${getHighlightColor({ color, error })};
  `}

  ${({ headerItem }) =>
    headerItem &&
    `
    cursor: default !important;
    border-bottom: 0 !important;
    height: ${theme.spacing.doubleAndHalf};
    color: ${theme.colors.blacks[60]} !important;
    font-weight: ${theme.fontWeight.semiBold} !important;
    font-size: ${theme.fontSize.base} !important;
    line-height: ${theme.lineHeight.S} !important;
    background-color: ${theme.colors.blacks[5]} !important;
  `}

`;

export const Wrapper = styled.div`
  display: flex;
  ${({ hasCheckbox }) => hasCheckbox ? 'align-items: center;' : ''}
`;

export const LabelContainer = styled.div`
  display: flex;
  flex: 1 1 ${({ isDisabled }) => isDisabled ? `100%` : `auto`};
  flex-direction: column;
`;

export const Label = styled.div`
  width: ${({width}) => width}px;
  ${({ noWrap }) =>
    noWrap && `
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`;

export const SubLabel = styled.div`
  font-size: ${theme.sizes.fontSizes[40]};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  pointer-events: none;
`;

export const Checkbox = styled(CheckboxComponent)`
  margin-top: 4px;
`;

export const Tooltip = TooltipComponent;

export const TooltipTrigger = styled(Clickable)`
  flex: 1 1 100%;
  text-align: left;
  text-decoration: inherit;
  width: 100%;
`;
