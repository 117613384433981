import React from 'react';
import styled from 'styled-components';
import { skillColorPalettePropType } from '@matterapp/skills';
import PropTypes from 'prop-types';
import { getPalette } from '../../../../common/libs/skill-color-palettes';
import omitStyled from '../../../../libs/omitStyled';
import { COLOR_BUTTON_CLASSNAME } from './consts';

const StyledButton = omitStyled('button', ['primaryColor', 'borderColor', 'isSelected'])`
  background: #FFFFFF;
  border: none;
  box-shadow: 0px 1px 1px rgba(52, 55, 153, 0.2);
  border-radius: 12px;
  height: 48px;
  width: 48px;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 4px rgba(52, 55, 153, 0.2);
  }

  &:active {
    box-shadow: 0px 3px 4px rgba(52, 55, 153, 0.2);
    div {
      margin-top: 2px;
    }
  }
`;

const ButtonInnerCircle = styled.div`
  border-radius: 12px;
  height: 24px;
  width: 24px;
  margin-left: 12px;
  margin-right: 12px;
  background: ${({ primaryColor }) => primaryColor};
  ${({ isSelected }) => isSelected && `
    border-radius: 16px;
    height: 32px;
    width: 32px;
    margin-left: 8px;
    margin-right: 8px;
  `}
`;

const ColorButton = ({
  onClick,
  colorPalette,
  isSelected,
}) => {
  const palette = getPalette(colorPalette);
  const primaryColor = palette && palette.primary;
  const borderColor = palette && palette.borderColor;
  return (
    <StyledButton
      className={COLOR_BUTTON_CLASSNAME}
      primaryColor={primaryColor}
      borderColor={borderColor}
      onClick={onClick}
      isSelected={isSelected}
      type="button"
    >
      <ButtonInnerCircle
        primaryColor={primaryColor}
        isSelected={isSelected}
      />
    </StyledButton>
  );
};

ColorButton.propTypes = {
  colorPalette: skillColorPalettePropType.isRequired, // eslint-disable-line
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default ColorButton;
