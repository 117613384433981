
import React from 'react';

const Icon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="38" fill="#E4FAF6" stroke="white" strokeWidth="4" />
    <path d="M42.8075 36.8342C42.1897 37.452 41.6048 38.037 40.9886 38.6532C40.7593 38.4849 40.5445 38.307 40.3127 38.1587C38.8682 37.2324 37.0133 37.2984 35.6602 38.5418C33.854 40.2034 32.0953 41.9302 30.4545 43.7539C28.9533 45.4237 29.1448 47.7382 30.7012 49.1743C32.2632 50.616 34.5829 50.5494 36.2026 48.9949C37.0345 48.1964 37.8362 47.3677 38.6522 46.5533C38.795 46.4105 38.9378 46.2677 39.1023 46.1032C39.7256 46.7265 40.3012 47.3021 40.9333 47.9342C40.4679 48.3996 40.0329 48.8648 39.5661 49.295C38.61 50.1748 37.7316 51.1867 36.6518 51.8754C34.2488 53.4071 31.0158 53.0369 29.0092 51.1796C26.8205 49.1545 26.2147 46.1047 27.5342 43.4606C27.8494 42.829 28.2866 42.228 28.7778 41.7178C30.3635 40.07 31.9803 38.4516 33.6225 36.8603C36.2394 34.3229 40.2289 34.3118 42.8075 36.8342Z" fill="#21CBA3" />
    <path d="M37.2987 42.396C37.9262 41.7686 38.5111 41.1836 39.1201 40.5747C39.3549 40.7485 39.5681 40.928 39.8031 41.0763C41.1728 41.9454 43.0218 41.9553 44.2273 40.8436C46.1827 39.0392 48.0787 37.1576 49.864 35.1846C51.1114 33.8068 50.864 31.5021 49.5562 30.1847C48.184 28.8028 45.9534 28.591 44.4655 29.799C43.5308 30.5589 42.708 31.458 41.8368 32.2958C41.6354 32.4892 41.4404 32.6906 41.2158 32.9152C40.6036 32.303 40.0272 31.7266 39.4555 31.155C39.4597 31.1094 39.4488 31.0568 39.4697 31.0375C40.6802 29.8731 41.7868 28.5661 43.1291 27.5816C45.5917 25.7741 48.9735 26.0893 51.1886 28.123C53.3853 30.14 53.9028 33.4753 52.3939 36.1275C52.0992 36.6464 51.7112 37.1298 51.2923 37.5582C49.7203 39.1684 48.1332 40.765 46.5231 42.3354C43.9783 44.8166 40.2111 44.9441 37.5715 42.6655C37.4863 42.5948 37.414 42.5113 37.2987 42.396Z" fill="#21CBA3" />
  </svg>

);

export default Icon;