import styled from 'styled-components';

export const getEase = (shouldEaseOut) =>
  shouldEaseOut ? 'power1.out' : 'none';

export const BASE_VELOCITY = 0.004;
export const SHARED_WIDTH = 460;

const shared = `
  fill: none;
  stroke-width: 16;
  stroke-linecap: round;
`;

export const Background = styled.path`
  ${shared}
  stroke: #E9E9F6;
`;

export const Path = styled.path`
  ${shared}
  stroke: #343799;
`;
