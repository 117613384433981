import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { Composer, Panel } from '@matterapp/matter-ui';
import { useWorkspacesComposer } from 'hooks/composer';
import KudosModal from './shared/KudosModal';
import TrialBanner from './TrialBanner';
import FreeTierBanner from './FreeTierBanner';
import { Resources } from '@matterapp/routing';
import { useUpgradeModal } from 'routes/pages/workspaces/hooks/shared';
import { useNavigate } from 'react-router-dom';

function ComposerWorkspaces(props) {
  const {
    composerProps,
    kudosModalProps
  } = useWorkspacesComposer(props);
  const navigate = useNavigate();
  const { currentTenant, isAdminMember, currentWorkspace, tenantAdmins } = useContext(UserContext);
  const { kudosLeft } = currentWorkspace || {};
  const upgradeModalProps = useUpgradeModal(currentWorkspace, tenantAdmins, false);

  function onClickUpgrade() {
    if (isAdminMember) {
      navigate(Resources.workspaceSettingsBilling.path({ workspaceId: currentWorkspace.id }));
    } else {
      upgradeModalProps.openUpgradeModal();
    }
  };

  const { resetFeed } = props;
  const { isExpired, expiresInDays, isTrial, isFree, isAdmin, isOwner } = currentTenant || {};
  const Banner = isFree 
    ? FreeTierBanner
    : (isTrial && expiresInDays && !isExpired ? TrialBanner : null);

  return (
    <>
      <Panel.Base>
        <Composer.Main
          {...composerProps}
          banner={Banner? 
            <Banner
              expiresInDays={expiresInDays}
              upgradeModalProps={upgradeModalProps}
              onClickUpgrade={onClickUpgrade}
              isAdminMember={isAdmin || isOwner}
            /> : null
          }
          resetFeed={resetFeed}
        />
      </Panel.Base>
      <KudosModal 
        {...kudosModalProps} 
        kudosLeft={kudosLeft} 
        isFree={isFree} 
        isAdminMember={isAdmin || isOwner}
        onClickUpgrade={onClickUpgrade}
      />
    </>
  );
};

export default ComposerWorkspaces;
