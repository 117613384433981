import React from 'react';
import Base, { Group, Path, PathEvenOdd } from '../../Image';
import * as C from './classNames';

const Image = (props) => {
  const { waterDroplets, ...otherProps } = props;
  return (
    <Base {...otherProps}>
      <Group>
        <Path
          className={C.TRIANGLE.LEFT}
          d="M87.6562 50.5625L79.7545 37.2625L76.0188 40.9217L87.6562 50.5625Z"
          fill="#21CBA3"
        />
        <Path
          className={C.TRIANGLE.CENTER}
          d="M93.8855 51.3938L90.1496 30.6125L97.6309 30.6125L93.8855 51.3938Z"
          fill="#21CBA3"
        />
        <Path
          className={C.TRIANGLE.RIGHT}
          d="M99.293 50.5625L107.759 37.2625L111.762 40.9217L99.293 50.5625Z"
          fill="#21CBA3"
        />
        <Group className={C.PLANT}>
          <PathEvenOdd
            d="M113.453 102.982H72.9121L75.6016 118.981H75.6021C75.6021 118.981 75.6021 118.981 75.6021 118.981C75.6021 122.396 83.3877 125.165 92.9918 125.165C102.596 125.165 110.381 122.396 110.381 118.981C110.381 118.981 110.381 118.98 110.381 118.979L113.453 102.982Z"
            fill="#F46A46"
          />
          <PathEvenOdd
            d="M112.925 104.576H72.9121L73.5232 108.211C77.3067 110.226 84.497 111.587 92.7447 111.587C101.209 111.587 108.56 110.154 112.258 108.051L112.925 104.576Z"
            fill="#E54D30"
          />
          <PathEvenOdd
            d="M71.0673 102.089C71.0434 102.216 71.0312 102.344 71.0312 102.472C71.0312 106.133 80.9057 109.101 93.0865 109.101C105.267 109.101 115.142 106.133 115.142 102.472C115.142 102.344 115.13 102.216 115.106 102.089H115.142V92.273V91.763H115.077C114.211 88.34 104.696 85.6436 93.0865 85.6436C81.4765 85.6436 71.9618 88.34 71.0955 91.763H71.0312V92.273V102.089H71.0673Z"
            fill="#F46A46"
          />
          <Path
            fill="#E54D30"
            d="M93.1,85.6c12.2,0,22.1,3,22.1,6.6s-9.9,6.6-22.1,6.6S71,95.9,71,92.3S80.9,85.6,93.1,85.6z"
          />
          <Path
            fill="#F78763"
            d="M93.1,85.6c12.2,0,22.1,3,22.1,6.6s-9.9,6.6-22.1,6.6S71,95.9,71,92.3S80.9,85.6,93.1,85.6z"
          />
          <Path
            d="M94.6756 89.3477L93.6464 70.5903C93.3707 70.7857 93.0846 70.9672 92.7881 71.1311L91.7832 89.3477H94.6756Z"
            fill="#F2A645"
          />
          <Path
            className={C.LEAF.LEFT}
            d="M93.5798 69.9655C93.4891 69.3201 93.3356 68.685 93.1193 68.071C92.2679 65.6251 90.471 63.5107 87.9414 62.3209C84.5745 60.7369 80.7679 61.1974 77.9277 63.1967C78.1964 66.6614 80.2689 69.8853 83.6359 71.4694C86.6749 72.8999 90.0698 72.6626 92.7913 71.1344C93.0844 70.9704 93.3705 70.789 93.6496 70.5936C93.6322 70.3807 93.6077 70.1714 93.5798 69.9655Z"
            fill="#05A164"
          />
          <Path
            className={C.LEAF.RIGHT}
            d="M101.973 76.7491C105.345 75.0356 107.27 72.0547 106.272 70.0911C105.275 68.1276 101.732 67.9249 98.3599 69.6384C94.9876 71.352 93.0627 74.3329 94.0605 76.2964C95.0582 78.26 98.6008 78.4627 101.973 76.7491Z"
            fill="#05A164"
          />
          {waterDroplets}
          <PathEvenOdd
            d="M111.478 95.9557C107.522 97.7326 100.784 98.9026 93.136 98.9026C85.488 98.9026 78.7492 97.7325 74.793 95.9554C77.8022 90.4359 84.8817 86.5601 93.1356 86.5601C101.39 86.5601 108.469 90.4361 111.478 95.9557Z"
            fill="#25237B"
          />
          <PathEvenOdd
            d="M114.189 92.2728C113.695 92.2728 113.302 92.6902 113.089 93.1361C112.802 93.7379 112.215 94.3237 111.348 94.8737C111.014 95.0858 110.637 95.2926 110.221 95.493C108.355 96.3897 105.746 97.1233 102.655 97.6228C102.418 97.6611 102.178 97.698 101.935 97.7336C98.5292 98.2322 94.7255 98.433 90.958 98.3131C90.899 98.3112 90.8401 98.3092 90.7812 98.3072C87.0786 98.1794 83.5644 97.7463 80.6282 97.0552C80.293 96.9763 79.9668 96.8944 79.65 96.8096C77.1482 96.1402 75.234 95.2929 74.0881 94.3426C73.7326 94.0478 73.455 93.7463 73.2559 93.441C73.19 93.3398 73.1326 93.2383 73.084 93.1364C72.8108 92.5646 72.8096 91.9826 73.0845 91.4081C73.1498 91.2717 73.2306 91.1357 73.3271 91.0004C74.0059 90.0481 75.4348 89.1612 77.4839 88.4129C77.8306 88.2863 78.195 88.1636 78.5766 88.0453C80.9176 87.3194 83.8196 86.782 87.0151 86.4795C87.2877 86.4537 87.5624 86.4296 87.8391 86.4072C88.0075 86.3936 88.0919 86.1961 87.9846 86.0656L87.8907 85.9513C87.8318 85.8797 87.7415 85.8411 87.649 85.8482C84.1903 86.1126 81.0036 86.6249 78.3574 87.3385C77.9769 87.4412 77.6075 87.548 77.2501 87.6588C74.9431 88.3742 73.2097 89.2342 72.1666 90.1733C72.0464 90.2815 71.9353 90.3908 71.8336 90.501C71.7169 90.6275 71.6125 90.7553 71.5206 90.8841C70.7739 91.9318 70.8848 93.0156 71.8323 94.0433C71.9839 94.2077 72.1569 94.3707 72.3513 94.5319C72.6403 94.7716 72.9743 95.0053 73.3505 95.2321C74.8082 96.1104 76.9017 96.8834 79.4893 97.4924C79.8113 97.5682 80.1396 97.6411 80.4739 97.7112C83.5196 98.3494 87.0582 98.7473 90.7634 98.8653C90.8224 98.8672 90.8813 98.869 90.9403 98.8707C94.7302 98.9821 98.548 98.7965 102.023 98.3336C102.265 98.3013 102.506 98.2677 102.744 98.2328C105.839 97.7797 108.529 97.1165 110.608 96.299C111.027 96.1346 111.42 95.9639 111.787 95.7874C112.918 95.2437 113.775 94.6565 114.339 94.0447C114.413 93.9644 114.482 93.8837 114.546 93.8027C114.712 93.5931 114.843 93.381 114.94 93.1671C115.151 92.7023 114.728 92.2728 114.218 92.2728H114.189Z"
            fill="#FABBA4"
          />
        </Group>
        <Group className={C.WATER_PAIL}>
          <Path
            d="M120.165 69.0531C120.045 68.9328 120.045 68.7377 120.165 68.6174L150.435 38.3476C150.555 38.2273 150.75 38.2273 150.871 38.3476L154.033 41.5102L123.328 72.2157L120.165 69.0531Z"
            fill="#4584FF"
          />
          <Path
            d="M123.197 72.2355L154.022 41.4975L155.26 64.5219C155.264 64.6133 155.228 64.7022 155.161 64.7644L145.901 73.3422C145.84 73.3988 145.759 73.4282 145.676 73.4238L123.197 72.2355Z"
            fill="#4584FF"
          />
          <Path
            d="M127.724 61.4453C127.603 61.325 127.579 61.1544 127.669 61.0644L150.323 38.4099C150.413 38.3198 150.584 38.3443 150.704 38.4647L153.867 41.6272L130.886 64.6078L127.724 61.4453Z"
            fill="#7DB3FF"
          />
          <Path
            d="M171.906 51.4121C172.07 48.0151 174.872 45.345 178.273 45.345H227.383C230.741 45.345 233.523 47.9493 233.744 51.2994L237.633 110.239C238.119 117.594 232.284 123.827 224.912 123.827H181.792C174.51 123.827 168.707 117.738 169.058 110.465L171.906 51.4121Z"
            fill="#4584FF"
          />
          <Path
            d="M146.404 72.7844L154.521 65.0657L203.473 106.747L186.29 123.931L146.404 72.7844Z"
            fill="#4584FF"
          />
          <PathEvenOdd
            d="M169.067 110.386L169.058 110.568C168.707 117.842 174.51 123.931 181.792 123.931H224.912C232.269 123.931 238.096 117.722 237.636 110.386H169.067Z"
            fill="#4F52B1"
          />
          <Path
            d="M169.408 102.363L170.743 78.9473L168.073 76.6879L160.986 91.1687L169.408 102.363Z"
            fill="#4F52B1"
          />
          <Path
            d="M233.647 49.2909C233.647 53.5882 219.899 57.0718 202.94 57.0718C185.981 57.0718 172.232 53.5882 172.232 49.2909C172.232 44.9936 185.981 41.51 202.94 41.51C219.899 41.51 233.647 44.9936 233.647 49.2909Z"
            fill="#4584FF"
          />
          <Path
            d="M225.781 47.9346C225.781 42.0178 223.431 36.3432 219.247 32.1593C215.063 27.9755 209.389 25.625 203.472 25.625C197.555 25.625 191.88 27.9755 187.696 32.1593C183.513 36.3432 181.162 42.0178 181.162 47.9346L189.095 47.9346C189.095 44.1218 190.61 40.4651 193.306 37.769C196.002 35.0728 199.659 33.5582 203.472 33.5582C207.285 33.5582 210.941 35.0728 213.637 37.769C216.334 40.4651 217.848 44.1218 217.848 47.9346H225.781Z"
            fill="#4584FF"
          />
          <Path
            d="M218.48 40.8833C219.265 40.519 219.613 39.5829 219.182 38.8321C217.713 36.2733 215.636 34.1055 213.125 32.5267C210.171 30.6691 206.742 29.7066 203.252 29.7554C199.762 29.8043 196.361 30.8623 193.46 32.8019C190.994 34.4502 188.978 36.6753 187.582 39.2742C187.172 40.0368 187.546 40.9628 188.341 41.3049C189.136 41.647 190.051 41.2746 190.474 40.5196C191.622 38.4737 193.239 36.7199 195.202 35.4081C197.6 33.805 200.411 32.9304 203.296 32.8901C206.18 32.8497 209.015 33.6453 211.457 35.1807C213.455 36.4371 215.121 38.1449 216.325 40.1579C216.77 40.9008 217.695 41.2475 218.48 40.8833Z"
            fill="#4F52B1"
          />
        </Group>
      </Group>
    </Base>
  );
};

export default Image;
