import moment from 'moment';

export const dateFilters = [
  {
    label: 'Last 30 Days',
    value: 'Last 30 Days',
    startDate: moment().add(-30, 'day'),
    endDate: moment(),
    groupedBy: 'GROUPED BY WEEK',
    groupBy: 'week',
    xAxisResolution: 5,
    visibleOnMobile: true
  },
  {
    label: 'Last 3 Months',
    value: 'Last 3 Months',
    startDate: moment().add(-3, 'month').startOf('month'),
    endDate: moment(),
    groupedBy: 'GROUPED BY MONTH',
    groupBy: 'month',
    xAxisResolution: 5,
    visibleOnMobile: true
  },
  {
    label: 'Last 6 Months',
    value: 'Last 6 Months',
    startDate: moment().add(-6, 'month').startOf('month'),
    endDate: moment(),
    groupedBy: 'GROUPED BY MONTH',
    groupBy: 'month',
    xAxisResolution: 7,
    visibleOnMobile: true
  },
  {
    label: 'Last 12 Months',
    value: 'Last 12 Months',
    startDate: moment().add(-12, 'month').startOf('month'),
    endDate: moment(),
    groupedBy: 'GROUPED BY MONTH',
    groupBy: 'month',
    xAxisResolution: 13,
    visibleOnMobile: false
  }
];

export function buildCustomDateRange(from, to) {
  const fromDate = moment(from);
  const toDate = moment(to);
  const diff = toDate.diff(fromDate, 'days');
  let groupedBy = 'GROUPED BY DAY';
  let groupBy = 'day';
  let xAxisResolution = 7;

  if (diff > 14 && diff < 60) {
    groupedBy = 'GROUPED BY WEEK';
    groupBy = 'week';
    xAxisResolution = 8;
  } else if (diff >= 60 && diff < 90) {
    groupedBy = 'GROUPED BY WEEK';
    groupBy = 'week';
    xAxisResolution = 10;
  } else if (diff >= 90) {
    groupedBy = 'GROUPED BY MONTH';
    groupBy = 'month';
    xAxisResolution = 10;
  }

  return {
    label: 'custom',
    value: 'custom',
    startDate: fromDate,
    endDate: toDate,
    groupedBy,
    groupBy,
    xAxisResolution
  };
}

export function formatAveragesToDataPoints(averages, startDate, endDate, groupBy) {
  if (!averages || !averages.length) {
    return [];
  }
  const formattedAverages = averages.map((average) => {
    return { x: average.date, y: average.score * 2 };
  });

  const getFormattedDate = (d) => {
    const date = moment(d, "YYYY-MM-DD");

    if (groupBy === 'month') {
      return date.format('MMMM');
    }

    return date.format('MMM D');
  };

  const ratings = averages.map((average, idx) => {
    const { date, startDate, endDate } = average;
    // default grouped by day - we get single date
    let formattedDate = getFormattedDate(date);

    if (startDate && groupBy !== 'month') {
      const to = idx === averages.length - 1 ? getFormattedDate(endDate) : getFormattedDate(moment(endDate).add(-1, 'days'));
      formattedDate = `${getFormattedDate(startDate)} - ${to}`;
    }

    return {
      user: { fullName: formattedDate },
      onClick: () => null,
      x: average.date,
      y: average.score * 2
    };
  });

  return {
    startDate,
    endDate,
    ratings,
    averages: formattedAverages
  };
}