/* istanbul ignore file */
import styled from 'styled-components';
import Button from '../../Button';
import { Clickable } from '../../Clickable/Clickable';
import theme from '@matterapp/matter-theme';

const ICON_SIZE = '16px';
const SUBHEADER_WIDTH = '240px';

export const Container = styled.div`
  max-height: 100%;
  transition: max-height ${() => theme.transitions.times.default};
  ${({ isClosed }) =>
    isClosed &&
    `
    max-height: 0;
    overflow: hidden;
  `}
`;

export const CloseButtonContainer = styled.div`
  ${({ isAnimationPlaying, showCloseButton }) => `
    display: ${showCloseButton ? 'block' : 'none'};
    position: absolute;
    left: 50%;
    bottom: 32px;
    transform: translateX(-50%);
    opacity: ${isAnimationPlaying ? '0' : '1'};
    pointer-events: ${isAnimationPlaying ? 'none' : 'all'};
    transition: opacity ${theme.transitions.times.default};
  `}
`;

export const CloseButton = styled(Button.Simple.Minimal).attrs({
  buttonType: Button.Simple.buttonTypes.BUBBLE,
  size: Button.Simple.buttonSizes.S,
})`
  ${({ currentStep }) =>
    currentStep !== 0 &&
    `
    &:not(:hover) {
      border-color: transparent;
    }
  `}
`;

export const CloseButtonTop = styled(Clickable)`
  ${({ isExpanded, showCloseButton }) => `
    display: ${!isExpanded && showCloseButton ? 'block' : 'none'};
    position: absolute;
    top: ${theme.spacing.threeQuarters};
    right: ${theme.spacing.threeQuarters};
  };
    & svg {
      height: ${ICON_SIZE};
      width: ${ICON_SIZE};
    }
    &:hover svg path {
      fill: ${theme.colors.blues[60]};
    }
  `};
  ${() => theme.media.large`
    display: none;
  `};
`;

export const FifthStepHeader = styled.div`
  width: auto;
  ${() => theme.media.large`
    margin: auto;
    width: 160px;
  `};
`;

export const ExpandToggle = styled(Clickable)`
  ${({ isExpanded, theme }) => `
      display: block;
      position: absolute;
      top: ${theme.spacing.threeQuarters};
      left: ${theme.spacing.threeQuarters};
      transition: transform ${theme.transitions.times.default};
      & svg {
        height: ${ICON_SIZE};
        width: ${ICON_SIZE};
        margin: ${isExpanded ? '-4px 0' : '-4px 0 0'};
        path {
          fill: ${theme.colors.blacks[90]};
        }
      }
      &:hover svg path {
        fill: ${theme.colors.blues[60]};
      }
      ${isExpanded && `transform: rotate(180deg);`}
  `};
  ${({ canToggleOnDesktop, theme }) => theme.media.large`
    display: ${canToggleOnDesktop ? 'block' : 'none'};
  `};
`;

export const HeaderContainer = styled.div`
  ${({ isExpanded, theme: { spacing } }) => `
    padding: ${spacing.single} ${spacing.half} ${spacing.half};
    position: ${isExpanded ? 'absolute' : 'relative'};
    top: 0;
    left: 0;
    right: 0;
  `};
  ${({ canToggleOnDesktop, isExpanded, theme }) => theme.media.large`
    padding: ${theme.spacing.singleAndHalf};
    ${(!canToggleOnDesktop || isExpanded) &&
      `
      position: absolute;
    `}
  `};
`;

export const HeaderTextContainer = styled.div`
  ${() => `
    font-size: ${theme.fontSize.S};
    font-weight: bold;
    line-height: ${theme.lineHeight.M};
    margin-bottom: ${theme.spacing.half};
    text-align: center;
  `};
`;

export const SubHeaderTextContainer = styled.div`
  ${({ isExpanded, theme }) => `
    font-size: ${theme.fontSize.base};
    line-height: ${theme.lineHeight.S};
    max-width: ${isExpanded ? SUBHEADER_WIDTH : '100%'};
    margin: auto;
    text-align: center;
  `};
  ${() => theme.media.large`
    max-width: ${SUBHEADER_WIDTH};
  `};
`;

export const ProgressContainer = styled.div`
  ${() => `
    background: ${theme.colors.oranges[5]};
    border-radius: 0;
    display: block;
    line-height: 0;
    overflow: hidden;
    position: relative;
  `};
  ${() => theme.media.large`
    border-radius: ${theme.borderRadius.L};
  `};
`;

const pillsHiddenStyles = `
  opacity: 0;
`;

export const PillsContainer = styled.div`
  ${({ isExpanded, theme }) => {
    if (isExpanded) {
      return pillsHiddenStyles;
    }
    return `
      margin-top: ${theme.spacing.single};
      opacity: 1;
    `;
  }};
  ${({ canToggleOnDesktop, theme }) => theme.media.large`
    ${!canToggleOnDesktop && pillsHiddenStyles}
  `};
`;

const rocketVisibleStyles = `
  max-height: 100%;
  opacity: 1;
  pointer-events: all;
  & svg {
    width: 100%;
    height: 100%;
  }
`;

export const RocketContainer = styled.div`
  overflow: hidden;
  ${({ isExpanded }) => {
    if (isExpanded) {
      return rocketVisibleStyles;
    }
    return `
      max-height: 0px;
      opacity: 0;
      pointer-events: none;
    `;
  }};
  ${({ canToggleOnDesktop, theme }) => theme.media.large`
    ${!canToggleOnDesktop && rocketVisibleStyles}
  `};
`;
