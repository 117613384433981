import { DesktopAndMobileImage } from '../classes/DesktopAndMobileImage';
import feedbackPreviewRequestDesktop from './feedback-preview/desktop-feedback-request.png';
import feedbackPreviewReceivedDesktop from './feedback-preview/desktop-feedback-received.png';
import feedbackPreviewRequestMobile from './feedback-preview/mobile-feedback-request.png';
import feedbackPreviewReceivedMobile from './feedback-preview/mobile-feedback-received.png';
import featurePreviewWorkspacesDesktop from './feature-preview/desktop-workspaces.png';
import featurePreviewWorkspacesMobile from './feature-preview/mobile-workspaces.png';
import featurePreviewKudosDesktop from './feature-preview/desktop-kudos.png';
import featurePreviewKudosMobile from './feature-preview/mobile-kudos.png';
import featurePreviewRecurringDesktop from './feature-preview/desktop-recurring.png';
import featurePreviewRecurringMobile from './feature-preview/mobile-recurring.png';
import featurePreviewRitualsDesktop from './feature-preview/desktop-rituals.png';
import featurePreviewRitualsMobile from './feature-preview/mobile-rituals.png';
import ritualKickoffDesktop from './feature-preview/desktop-ritual-kickoff.png';
import ritualKickoffMobile from './feature-preview/mobile-ritual-kickoff.png';

/**
 * The main purpose of these classes are making it easy to encapsulate and group
 * images that are meant to replace each other when viewed from screens of different
 * sizes.
 */
const feedbackPreviewRequest = new DesktopAndMobileImage({
  desktop: feedbackPreviewRequestDesktop,
  mobile: feedbackPreviewRequestMobile,
});

const feedbackPreviewReceived = new DesktopAndMobileImage({
  desktop: feedbackPreviewReceivedDesktop,
  mobile: feedbackPreviewReceivedMobile,
});

const featurePreviewWorkspaces = new DesktopAndMobileImage({
  desktop: featurePreviewWorkspacesDesktop,
  mobile: featurePreviewWorkspacesMobile,
});

const featurePreviewKudos = new DesktopAndMobileImage({
  desktop: featurePreviewKudosDesktop,
  mobile: featurePreviewKudosMobile,
});

const featurePreviewRecurring = new DesktopAndMobileImage({
  desktop: featurePreviewRecurringDesktop,
  mobile: featurePreviewRecurringMobile,
});

const featurePreviewRituals = new DesktopAndMobileImage({
  desktop: featurePreviewRitualsDesktop,
  mobile: featurePreviewRitualsMobile,
});

const ritualKickOffPreview = new DesktopAndMobileImage({
  desktop: ritualKickoffDesktop,
  mobile: ritualKickoffMobile,
});

export default {
  feedbackPreviewRequest,
  feedbackPreviewReceived,
  featurePreviewWorkspaces,
  featurePreviewKudos,
  featurePreviewRecurring,
  featurePreviewRituals,
  ritualKickOffPreview,
};