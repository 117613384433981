import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { IconSpinner } from '../Icon';
import FileNames from './FileNames';
import Preview from './Preview';
import UploadIcon from './UploadIcon';
import { ACCEPT_TYPES, useFiles } from './consts';
import {
  Button,
  ButtonContainer,
  ButtonSpacer,
  Container,
  Input,
  IconContainer,
  SpinnerIconContainer,
  Header,
  SubHeader,
} from './styles';

const FileUpload = (props) => {
  const {
    className,
    disabled,
    enableClick,
    enableDragAndDrop,
    header,
    icon,
    imagePreviewIsCircle,
    imagePreviewSize,
    multiple,
    onChange,
    onRemove,
    showImagePreview,
    showRemoveReplace,
    showFileNames,
    showLoading,
    subHeader,
    urls,
    loadingHeader,
    onMaxFileSizeNotMet,
    onMinFileSizeNotMet,
    onFileTypeNotMet,
    onFileAccepted,
    ...dropzoneProps
  } = props;
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    ...dropzoneProps,
    disabled,
    multiple,
    noClick: !enableClick,
    noDrag: !enableDragAndDrop,
    onDropAccepted: async (acceptedFiles) => {
      const promises = acceptedFiles.map(async (file) => {
        await onFileAccepted(file);
      });
      await Promise.all(promises);
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            onMaxFileSizeNotMet();
          }
          if (err.code === "file-too-small") {
            onMinFileSizeNotMet();
          }
          if (err.code === "file-invalid-type") {
            onFileTypeNotMet();
          }
        });
      });
    }
  });

  const { clearFiles, hasFiles, imageUrls, files } = useFiles({
    acceptedFiles,
    onChange,
    onRemove,
    urls,
  });

  const displayLoading = hasFiles && showLoading;
  const displayHeader = (header && !hasFiles) || displayLoading;
  const updatedHeader = displayLoading ?  loadingHeader : header;
  const displayFileNames = hasFiles && showFileNames && !showImagePreview;
  const displayRemoveReplace = hasFiles && showRemoveReplace;

  return (
    <Container
      {...{ className, disabled, enableClick, hasFiles, imagePreviewSize, enableDragAndDrop, multiple }}
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
    >
      <Input {...getInputProps()} />
      {icon && !hasFiles && <IconContainer>{icon}</IconContainer>}
      {displayLoading && <SpinnerIconContainer> <IconSpinner size={64} /> </SpinnerIconContainer>}
      {displayHeader && <Header>{updatedHeader}</Header>}
      {subHeader && !hasFiles && <SubHeader>{subHeader}</SubHeader>}
      {showImagePreview && (
        <Preview
          files={files}
          previewIsCircle={imagePreviewIsCircle}
          size={imagePreviewSize}
          urls={imageUrls}
        />
      )}
      {displayFileNames && <FileNames files={files} />}
      {displayRemoveReplace && (
        <ButtonContainer>
          <Button>Replace</Button>
          <ButtonSpacer />
          <Button onClick={clearFiles}>Remove</Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

FileUpload.propTypes = {
  /** Set accepted file types. */
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /** Additional style class. */
  className: PropTypes.string,
  /** Enable/disable the FileUpload. */
  disabled: PropTypes.bool,
  /** If click to select files are allowed. */
  enableClick: PropTypes.bool,
  /** If drag and drop of files are allowed. */
  enableDragAndDrop: PropTypes.bool,
  /** Header text to display inside of file uploader. */
  header: PropTypes.string,
  /** Override icon to display inside of file uploader. */
  icon: PropTypes.node,
  /** If image preview should crop to circle. */
  imagePreviewIsCircle: PropTypes.bool,
  /** Height and width of the image preview. */
  imagePreviewSize: PropTypes.number,
  /** Maximum file size (in bytes). */
  maxSize: PropTypes.number,
  /** Minimum file size (in bytes). */
  minSize: PropTypes.number,
  /** Allow drag 'n' drop (or selection from the file dialog) of multiple files. */
  multiple: PropTypes.bool,
  /** Callback when the files change in the File Upload. Function params passes list of files. */
  onChange: PropTypes.func,
  /** Callback when files are removed. */
  onRemove: PropTypes.func,
  /** Show image preview of files. */
  showImagePreview: PropTypes.bool,
  /** Show names of files. */
  showFileNames: PropTypes.bool,
  /** Show replace/remove buttons for image uploads. */
  showRemoveReplace: PropTypes.bool,
  /** Show loading spinner */
  showLoading: PropTypes.bool,
  /** Sub beader text to display inside of file uploader. */
  subHeader: PropTypes.string,
  /** Since the image uploader cannot prepopulate the field, image urls can be passed to show that existing files were selected. */
  urls: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  onMaxFileSizeNotMet: PropTypes.func,
  onMinFileSizeNotMet: PropTypes.func,
  onFileTypeNotMet: PropTypes.func,
  onFileAccepted: PropTypes.func,
};

FileUpload.defaultProps = {
  accept: ACCEPT_TYPES.FILES.ANY,
  disabled: false,
  enableClick: true,
  enableDragAndDrop: true,
  multiple: false,
  onChange: () => {},
  onRemove: () => {},
  icon: <UploadIcon />,
  showImagePreview: false,
  showFileNames: true,
  showLoading: false,
  showRemoveReplace: true,
  maxSize: 2000000,
  onMaxFileSizeNotMet: () => null,
  onMinFileSizeNotMet: () => null,
  onFileTypeNotMet: () => null,
  onFileAccepted: () => null,
};

FileUpload.AcceptTypes = ACCEPT_TYPES;

export default FileUpload;
