import { useNavigate, useResolvedPath } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import {
  useNotificationsModal,
} from './useNotifications';
import { useProfileMenuState } from './useProfileMenu';
import useActiveWorkspace from './useActiveWorkspace';
import {
  getActiveTab,
  notificationsPathsToTabs,
  profilePathsToTabs,
  LATEST_LAYOUT_VERSION,
} from './utils';

/**
 * App Header links hook to get object of callback urls.
 */
export const useAppHeaderLinks = () => {
  const navigate = useNavigate();
  return {
    onClickBrand: () => navigate(Resources.homeMain.path()),
    onClickNotifications: () =>
      navigate(Resources.notificationsMain.path()),
  };
};

/**
 * App Header Data hook.
 */
export const useAppHeaderData = (props = {}) => {
  const { pathname: path } = useResolvedPath();
  const { version, onClickUpgrade } = props;

  const {
    activeWorkspace,
    activeWorkspaceId,
    activeWorkspaceTab,
    currentWorkspaces,
    onChangeWorkspace,
  } = useActiveWorkspace({ 
    path
  });

  const { isAdmin, isOwner } = activeWorkspace || {};
  const isAdminOrOwner = isAdmin || isOwner;

  const workspacesProps = {
    onChangeWorkspace,
    onClickUpgrade,
    ...(props.workspacesProps || {}),
    activeWorkspace,
    activeId: activeWorkspace ? activeWorkspaceId : null,
    activeWorkspaceTab,
    addLink: Resources.workspacesCreateWorkspaceFlow.path(),
    getFeedLink: (workspace) =>
      Resources.workspacesActivityFeed.path({ workspaceId: workspace.id }),
    getFeedbacksLink: (workspace) =>
      Resources.feedbackMain.path({ workspaceId: workspace.id }),
    getRecurringLink: (workspace) =>
      Resources.workspacesRecurring.path({ workspaceId: workspace.id }),
    getSkillsLink: (workspace) => 
      Resources.skillsMain.path({ workspaceId: workspace.id }),
    workspaces: currentWorkspaces,
    getAnalyticsLink: (workspace) =>
      Resources.analyticsMain.path({ workspaceId: workspace.id }),
    getRewardsStoreLink: (workspace) =>
      Resources.rewardsStore.path({ workspaceId: workspace.id }),
    getRewardsManagerLink: (workspace) =>
      Resources.rewardsManager.path({ workspaceId: workspace.id }),
    isAdminOrOwner
  };

  return {
    activeProfileTab: getActiveTab(path, profilePathsToTabs),
    isLatestVersion: version === LATEST_LAYOUT_VERSION,
    tabProps: {
      // Originally !!feedbacksReceived && feedbacksReceived.length > 0,
      isHomeTabUnread: false,
    },
    sharedNavProps: {
      activeTab: getActiveTab(path),
      isNotificationsActive: !!getActiveTab(path, notificationsPathsToTabs),
      workspacesProps,
    },
  };
};

export { useNotificationsModal, useProfileMenuState };

export default useAppHeaderData;
