import { compose, withProps } from 'recompose';
import { Resources } from '@matterapp/routing';
import withRouter from 'hocs/withRouter';
import PropTypes from 'prop-types';
import {
  withPeerRecommendations,
  withToggle,
} from '../../../hocs';
import withProfilePreviewCard from 'hocs/profile-preview-card/withProfilePreviewCard';
import PeerRecommendationsList from '../components/PeerRecommendationsList';

const withData = compose(
  withRouter,
  withProfilePreviewCard,
  withToggle({
    stateName: 'isExpanded',
    initialIsOpen: false,
    handleToggleOpenName: 'handleExpand',
  }),
  withPeerRecommendations({
    peerRecommendationFilterFn: (peer) => peer.isFeedbackRecommendation,
  }),
  withProps(({ history }) => ({
    handleClickViewAll: () => {
      history.push(Resources.socialPeerSuggestions.path());
    },
  })),
  withProps(
    ({
      handleRequestFeedbackFromPeerViaPeerRecommendations,
      handleRejectPeer,
      invitationSource,
    }) => ({
      handlePickPeer: async (e, { peer }) => {
        await handleRequestFeedbackFromPeerViaPeerRecommendations({
          peer,
          source: invitationSource,
        });
      },
      handleRejectPeer: async (e, { peer }) => {
        await handleRejectPeer({
          peer,
          source: invitationSource,
        });
      },
    })
  )
);

const PeerRecommendationsListContainer = withData(PeerRecommendationsList);

PeerRecommendationsListContainer.propTypes = {
  invitationSource: PropTypes.string.isRequired,
};

export default PeerRecommendationsListContainer;
