import React from 'react';

const AddSkillIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 45.6C35.9294 45.6 45.6 35.9294 45.6 24C45.6 12.0706 35.9294 2.4 24 2.4C12.0706 2.4 2.4 12.0706 2.4 24C2.4 35.9294 12.0706 45.6 24 45.6ZM24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM22.8 22.8V12.6H25.2V22.8H35.4V25.2H25.2V35.4H22.8V25.2H12.6V22.8H22.8Z"
    />
  </svg>
);

export default AddSkillIcon;
