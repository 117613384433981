import { compose, withStateHandlers, withProps } from 'recompose';
import withAuthReducer from 'hocs/auth/withAuthReducer';
import withRequestVerificationCodeMutation from 'hocs/auth/withRequestVerificationCodeMutation';
import Toast from 'components/Toast/Toast';
import { NOTIFICATION_AUTH_RESENT_VERIFICATON_CODE_SUCCESS } from '../../../copy-consts';
import ResendCodeButton from '../components/ResendCodeButton';

const withData = compose(
  withAuthReducer,
  withRequestVerificationCodeMutation,
  withStateHandlers(
    ({ initialIsResendingVerificationCode = false }) => ({
      isResendingVerificationCode: initialIsResendingVerificationCode,
    }),
    {
      setIsResendingVerificationCode: () => (value) => ({
        isResendingVerificationCode: value,
      }),
    }
  ),
  withProps(
    ({
      requestVerificationCodeMutation,
      currentPhoneNumber,
      setIsResendingVerificationCode,
    }) => ({
      resendVerificationCode: async () => {
        setIsResendingVerificationCode(true);
        await requestVerificationCodeMutation({
          variables: { phoneNumber: currentPhoneNumber },
        });
        Toast.success(NOTIFICATION_AUTH_RESENT_VERIFICATON_CODE_SUCCESS);
        setIsResendingVerificationCode(false);
      },
    })
  )
);

const ResendCodeButtonContainer = withData(ResendCodeButton);

export default ResendCodeButtonContainer;
