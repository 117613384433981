import React from 'react';
import BackgroundAnimation, { ANIMATIONS } from '../../BackgroundAnimation';
import ColoredBackground from '../../ColoredBackground';
import gsap from 'gsap';
import * as S from './styles';

export default class StartBackground extends BackgroundAnimation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  /**
   * Moves the clouds in.
   * @returns { Object } The animation timeline.
   */
  moveCloudsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, duration: 1 };
    tl.to(this.topCloudRef, { x: 130, y: 0 });
    tl.to(this.middleCloudRef, { x: -70, y: 0 });
    tl.to(this.bottomCloudRef, { x: 90, y: 0 });

    tl.to(this.topCloudRef, resetProps, '<');
    tl.to(this.middleCloudRef, resetProps, '<');
    tl.to(this.bottomCloudRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the clouds out.
   * @returns { Object } The animation timeline.
   */
  moveCloudsOut = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    tl.to(this.topCloudRef, { x: 0, y: 0 });
    tl.to(this.middleCloudRef, { x: 0, y: 0 });
    tl.to(this.bottomCloudRef, { x: 0, y: 0 });

    tl.to(this.topCloudRef, { x: -80, y: -100, duration: 0.6 }, '<');
    tl.to(this.middleCloudRef, { x: 60, y: -50, duration: 0.6 }, '<');
    tl.to(this.bottomCloudRef, { x: -120, duration: 0.6 }, '<');
    return tl;
  };

  playAnimation = () => {
    const { animation } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    if (animation !== ANIMATIONS.SHOW) {
      if (animation === ANIMATIONS.OUT) {
        tl.add(this.moveCloudsOut(), '<');
      } else {
        tl.add(this.moveCloudsIn(), '<');
      }
    }
    this.tl = tl;
    return tl;
  };

  render() {
    return (
      <ColoredBackground {...this.props}>
        <S.TopCloud ref={(ref) => (this.topCloudRef = ref)} />
        <S.MiddleCloud ref={(ref) => (this.middleCloudRef = ref)} />
        <S.BottomCloud ref={(ref) => (this.bottomCloudRef = ref)} />
      </ColoredBackground>
    );
  }
}
