import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledFormGroup = styled.div`
  position: relative;

  input[type="text"],
  input[type="email"] {
    font-size: 17px;
    padding-right: calc(16px + 48px);
  }

  ${(props) => props.theme.media.medium`
    input[type="text"],
    input[type="email"] {
      font-size: 24px;
      padding-right: calc(16px + 92px);
    }
  `}
`;

const SubmitButtonContainer = styled.div`
  position: absolute;
    bottom: 8px; // not top, to handle error msg ux state
    right: 8px;

  button.button {
    padding: 0;
    width: 48px;
    span { display: none; }
    svg { margin: 0; }
  }

  ${(props) => props.theme.media.medium`
    button.button {
      padding: 0 16px;
      width: auto;
    span { display: initial; }
    svg { margin-left: 6px; }
  `}
`;

const InlineGroup = ({
  children,
  submitButton,
}) => (
  <StyledFormGroup>
    {children}
    <SubmitButtonContainer>
      {submitButton}
    </SubmitButtonContainer>
  </StyledFormGroup>
);

InlineGroup.propTypes = {
  children: PropTypes.node.isRequired,
  submitButton: PropTypes.node.isRequired,
};

export default InlineGroup;
