import React from 'react';
import styled from 'styled-components';
import Base from '../../../svgBase';

const DEFAULT_HEIGHT = 35;
const DEFAULT_WIDTH = 18;
const PATH = `M17.8912 0.0908203H9.16391L0.572998 5.34082V13.3863L8.20936 8.81809H8.41391V34.9999H17.8912V0.0908203Z`;

const NumberBase = styled(Base)`
  transform: translateX(-1px);
`;

const One = (props) => (
  <NumberBase height={DEFAULT_HEIGHT} width={DEFAULT_WIDTH} path={PATH} {...props} />
);

export default One;
