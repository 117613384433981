import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 2.62C3.9536 2.62 2.7 3.8736 2.7 5.42V15.42C2.7 16.9664 3.9536 18.22 5.5 18.22H10.8343V16.62H5.5C4.83726 16.62 4.3 16.0827 4.3 15.42V5.42C4.3 4.75725 4.83726 4.22 5.5 4.22H18.5C19.1627 4.22 19.7 4.75725 19.7 5.42V15.2753H21.3V5.42C21.3 3.8736 20.0464 2.62 18.5 2.62H5.5Z"
      fill={fill}
    />
    <path
      d="M17.98 8.32H6.02002V6.72H17.98V8.32Z"
      fill={fill}
    />
    <path
      d="M6.02002 11.28H17.97V9.67999H6.02002V11.28Z"
      fill={fill}
    />
    <path
      d="M6.02002 14.31H12.94V12.71H6.02002V14.31Z"
      fill={fill}
    />
    <path
      d="M17.33 18.9786L19.2343 17.0743L20.3657 18.2057L17.0957 21.4757C16.9457 21.6257 16.7422 21.71 16.53 21.71C16.3178 21.71 16.1144 21.6257 15.9643 21.4757L12.6943 18.2057L13.8257 17.0743L15.73 18.9786V13.03H17.33V18.9786Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#6B6B6F',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;