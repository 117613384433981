import React from 'react';
import Base, { Group, Path, PathEvenOdd } from '../../Image';
import * as C from './classNames';

const Image = (props) => {
  return (
    <Base {...props}>
      <Path
        className={C.CIRCLE.TOP}
        fill="#ADAFDE"
        d="M100.6,32.9c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1c-1.7,0-3.1,1.4-3.1,3.1C97.5,31.6,98.9,32.9,100.6,32.9z"
      />
      <Path
        className={C.CIRCLE.LEFT_1}
        fill="#ADAFDE"
        d="M49.1,48.7c2.2,0,3.9-1.8,3.9-3.9c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9C45.2,46.9,46.9,48.7,49.1,48.7z"
      />
      <Path
        className={C.CIRCLE.LEFT_2}
        fill="#ADAFDE"
        d="M67.4,70.5c2.2,0,3.9-1.8,3.9-3.9c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9C63.5,68.7,65.3,70.5,67.4,70.5z"
      />
      <Path
        className={C.CIRCLE.RIGHT}
        fill="#ADAFDE"
        d="M193.1,61.7c2.2,0,3.9-1.6,3.9-3.5c0-1.9-1.8-3.5-3.9-3.5c-2.2,0-3.9,1.6-3.9,3.5C189.2,60.2,190.9,61.7,193.1,61.7z"
      />
      <Path
        className={C.RING.TOP}
        fill="#ADAFDE"
        d="M79.2,40.8c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1C85.3,38.1,82.6,40.8,79.2,40.8z M79.2,31.4c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C82.5,32.9,81,31.4,79.2,31.4z"
      />
      <Path
        className={C.RING.RIGHT}
        fill="#ADAFDE"
        d="M191.3,126.3c-3.6,0-6.5-2.9-6.5-6.5c0-3.6,2.9-6.5,6.5-6.5c3.6,0,6.5,2.9,6.5,6.5C197.9,123.4,195,126.3,191.3,126.3z M191.3,116.3c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5C194.9,117.8,193.3,116.3,191.3,116.3z"
      />
      <Path
        className={C.RING.BOTTOM}
        fill="#ADAFDE"
        d="M74,117.6c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1C80.1,114.9,77.3,117.6,74,117.6z M74,108.2c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C77.2,109.7,75.8,108.2,74,108.2z"
      />
      <PathEvenOdd
        className={C.PLUS}
        fill="#ADAFDE"
        d="M183.3,39.3c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-3.1h3.1c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-3.1v-3.1c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v3.1h-3.1c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5h3.1V39.3z"
      />
      <Group className={C.DASHES}>
        <Path
          fill="#ADAFDE"
          d="M100.1,56.2l-2.3,1.2l-6.5-7.9l4.9-2.5L100.1,56.2z"
        />
        <Path
          fill="#ADAFDE"
          d="M89.7,59.7l-1.3,2l-9.1-4.3l2.9-4.4L89.7,59.7z"
        />
        <Path
          fill="#ADAFDE"
          d="M89.7,71.1l-0.2,2.4L79.2,74l0.5-5.2L89.7,71.1z"
        />
      </Group>
      <Group className={C.FACE.GROUP}>
        <Path
          fill="#FFBF61"
          d="M122.9,124.6c17.6,0,31.9-14,31.9-31.2c0-17.3-14.3-31.2-31.9-31.2S91,76.1,91,93.3C91,110.6,105.3,124.6,122.9,124.6z"
        />
        <PathEvenOdd
          fill="#F3A83E"
          d="M151.1,107.4c-7.2,15.3-25.9,21.7-41.7,14.3c-0.2-0.1-0.3-0.2-0.5-0.2c15.3,6.7,32.7,1.2,39.1-12.5c6.6-14-0.7-31.2-16.2-38.5c-12.4-5.9-26.4-3.5-34.9,4.8c8.6-11.5,24.7-15.6,38.6-9.1C151.3,73.6,158.3,92.1,151.1,107.4z"
        />
        <Path
          fill="#FFCF7A"
          d="M114.6,69.6c0.8,1.5-1.3,4.2-4.8,6.1c-3.5,1.9-6.9,2.2-7.7,0.7c-0.8-1.5,1.3-4.2,4.8-6.1C110.3,68.4,113.8,68.1,114.6,69.6z"
        />
        <PathEvenOdd
          fill="#F3A83E"
          d="M122.9,122.7c16.7,0,30.1-13.2,30.1-29.4c0-16.2-13.4-29.4-30.1-29.4S92.8,77.1,92.8,93.3C92.8,109.5,106.3,122.7,122.9,122.7z M154.9,93.3c0,17.3-14.3,31.2-31.9,31.2S91,110.6,91,93.3c0-17.3,14.3-31.2,31.9-31.2S154.9,76.1,154.9,93.3z"
        />
        <Group className={C.FACE.EYES}>
          <Path
            fill="#343799"
            d="M143.6,90.9c-0.6,0-1.2-0.4-1.4-1c-0.7-2.1-2.6-3.5-4.8-3.5c-2.2,0-4.1,1.4-4.8,3.5c-0.3,0.8-1.1,1.2-1.8,0.9c-0.7-0.3-1.2-1.1-0.9-1.8c1.1-3.3,4.1-5.5,7.6-5.5s6.5,2.2,7.6,5.5c0.3,0.8-0.1,1.6-0.9,1.8C143.9,90.9,143.8,90.9,143.6,90.9z"
          />
          <Path
            fill="#343799"
            d="M114.6,90.9c-0.6,0-1.2-0.4-1.4-1c-0.7-2.1-2.6-3.5-4.8-3.5c-2.2,0-4.1,1.4-4.8,3.5c-0.3,0.8-1.1,1.2-1.8,0.9c-0.8-0.3-1.2-1.1-0.9-1.8c1.1-3.3,4.1-5.5,7.6-5.5c3.4,0,6.5,2.2,7.6,5.5c0.3,0.8-0.2,1.6-0.9,1.8C114.9,90.9,114.8,90.9,114.6,90.9z"
          />
        </Group>
        <Path
          className={C.FACE.MOUTH}
          fill="#343799"
          d="M122.9,107.8c-5.8,0-10.9-3.7-12.8-9.3c-0.3-0.8,0.2-1.6,0.9-1.8c0.8-0.3,1.6,0.2,1.8,0.9c1.5,4.4,5.5,7.3,10,7.3c4.5,0,8.6-2.9,10-7.3c0.3-0.8,1.1-1.2,1.8-0.9c0.8,0.3,1.2,1.1,0.9,1.8C133.8,104.1,128.7,107.8,122.9,107.8z"
        />
      </Group>
      <Group className={C.HAND.GROUP}>
        <Group className={C.HAND.HAT}>
          <PathEvenOdd
            fill="#343799"
            d="M165.2,59.4c1.6-0.3,3.1-0.7,4.6-1.4c1.8-0.8,3.9-0.1,4.7,1.7c0.8,1.8,0.1,3.9-1.7,4.8c-3.6,1.7-7.4,2.3-11.3,2.3c-8.6,0-17.2-3.2-22.6-5.2l-1.7-0.6l0,0c-7.8-2.9-22.4-8.2-27.7-19.9c-0.8-1.8-0.1-3.9,1.7-4.8c1.8-0.8,3.9-0.1,4.7,1.7c0.7,1.5,1.6,2.8,2.7,4c0.1-0.4,0.3-0.8,0.4-1.2l2.6-7.4c4.6-12.9,17.2-15.4,29.7-10.8l1.1,0.4c12.5,4.6,20.5,14.7,15.9,27.6l-2.6,7.3C165.5,58.6,165.4,59,165.2,59.4z"
          />
          <Path
            fill="#25237B"
            d="M168,51.6l-3,8.5c-0.6,0-1.1,0-1.7,0c-0.6,0-1.3,0-1.9-0.1c-21.6-1.1-36.4-11.8-42.8-17.8l3.3-9.2C136.1,46,151.7,49.9,168,51.6z"
          />
          <Path
            fill="#4F4FB3"
            d="M139.8,22.2c5.5,0,9.9,1.9,9.9,4.3s-4.4,4.3-9.9,4.3s-9.9-1.9-9.9-4.3S134.4,22.2,139.8,22.2z"
          />
        </Group>
        <Group className={C.HAND.HAND}>
          <Path
            fill="#FFFFFF"
            d="M163.2,58.4l11.6,3.3c0.3,0.1,0.6,0.3,0.8,0.5l0.3,0.3l11,11.5c0.8,0.8,1.1,2,0.8,3.1l-0.2,0.8l-4.7,18c0,0.1,0,0.2,0,0.3l-12.7,6.5c-0.6-0.6-1.2-1.3-1.6-2.1c-0.5-0.9-1-1.8-1.5-2.7c-0.5-0.9-1.1-1.7-1.6-2.6c-1.1-1.7-2.2-3.4-3.4-5l-0.9-1.3c-0.4-0.6-0.6-1.4-0.4-2.2c0.3-1.3,0.5-2.6,0.7-3.8c0.2-1.3,0.4-2.6,0.6-3.8c0.2-1.3,0.3-2.6,0.5-3.8c0.1-1,0.3-2,0.4-3c-1.3-1.8-2.6-3.6-3.9-5.4c-0.5-0.7-0.5-1.7,0.1-2.4c0.7-0.8,1.9-1,2.7-0.3c2.1,1.7,4.2,3.5,6.4,5.3c0.4,0.3,0.7,0.8,0.7,1.4l0.1,0.8c0.1,1.3,0.2,2.6,0.3,3.9c0.1,1.3,0.1,2.6,0.2,3.9c0.1,1.3,0.2,2.6,0.3,3.9c0,0.3,0.1,0.7,0.1,1c1.3,0.9,2.6,1.8,3.9,2.7c0,0,0,0,0,0l5.7-10.2l-7.4-10.4l-10-4.8c-0.7-0.3-1.1-1.2-0.9-1.9C161.4,58.6,162.4,58.1,163.2,58.4z"
          />
          <PathEvenOdd
            fill="#ADAFDE"
            d="M175.6,62.2l0.3,0.3l11,11.5c0.8,0.8,1.1,2,0.8,3.1l-0.2,0.8l-4.7,18c0,0,0,0.1,0,0.1s0,0.1,0,0.1l-12.7,6.5c-0.6-0.6-1.2-1.3-1.6-2.1c-0.4-0.7-0.7-1.3-1.1-1.9c-0.1-0.2-0.3-0.5-0.4-0.7c-0.5-0.9-1.1-1.7-1.6-2.6c-1.1-1.7-2.2-3.4-3.4-5l-0.9-1.3c-0.4-0.6-0.6-1.4-0.4-2.2c0.3-1.3,0.5-2.6,0.7-3.8c0.2-1.3,0.4-2.6,0.6-3.8c0.1-0.5,0.1-1,0.2-1.4c0.1-0.8,0.2-1.6,0.3-2.4c0-0.3,0.1-0.6,0.1-0.9c0.1-0.7,0.2-1.4,0.3-2c-0.1-0.1-0.2-0.3-0.3-0.4c-1.2-1.7-2.4-3.3-3.6-5c-0.5-0.7-0.5-1.7,0.1-2.4c0.7-0.8,1.9-1,2.7-0.3c1.6,1.3,3.1,2.6,4.6,3.8c0.6,0.5,1.2,1,1.7,1.5c0.4,0.3,0.7,0.8,0.7,1.4l0.1,0.8c0.1,1,0.2,2,0.2,3c0,0.3,0,0.6,0.1,0.9c0.1,0.8,0.1,1.6,0.1,2.4c0,0.5,0.1,1,0.1,1.5c0.1,1.3,0.2,2.6,0.3,3.9c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.3,0,0.4c0.8,0.6,1.6,1.1,2.5,1.7c0.5,0.3,1,0.7,1.5,1c0,0,0,0,0,0c0,0,0,0,0,0l5.7-10.2l-7.4-10.4l-10-4.8c-0.7-0.3-1.1-1.2-0.9-1.9c0.2-0.9,1.2-1.4,2-1.1l11.6,3.3C175.1,61.8,175.4,62,175.6,62.2z M175.3,59.9C175.3,59.9,175.3,59.9,175.3,59.9c0.6,0.2,1.2,0.5,1.6,1l0,0l0.3,0.3l11,11.5c1.2,1.2,1.7,3.1,1.3,4.9l0,0l-0.2,0.8L184.6,96l0.2,1.2l-14.9,7.6l-0.9-0.8c-0.8-0.7-1.5-1.6-2-2.6l0,0l0,0c-0.3-0.6-0.7-1.2-1.1-1.8c-0.1-0.2-0.3-0.5-0.4-0.8c-0.5-0.8-1-1.7-1.6-2.5c-1.1-1.7-2.2-3.4-3.3-5l0,0l-0.9-1.3c-0.7-1-1-2.3-0.7-3.7c0.3-1.3,0.5-2.5,0.7-3.8l0,0c0.2-1.2,0.4-2.5,0.6-3.8l0,0c0.1-0.5,0.1-0.9,0.2-1.4c0.1-0.8,0.2-1.7,0.3-2.5c0-0.3,0.1-0.6,0.1-0.9c0.1-0.4,0.1-0.9,0.2-1.3c-1.2-1.6-2.4-3.2-3.5-4.9c-1-1.4-0.9-3.2,0.1-4.6v0c0.6-0.8,1.4-1.2,2.3-1.4c-0.5-0.8-0.7-1.9-0.4-2.9c0.5-1.9,2.4-2.9,4.3-2.4c0,0,0,0,0,0L175.3,59.9z M166.3,65.6l4.6,2.2l6.4,9.1l-4.2,7.5c-0.5-0.4-1.1-0.7-1.6-1.1c0-0.1,0-0.1,0-0.2v0c-0.1-1.3-0.2-2.5-0.3-3.8l0,0c0-0.5-0.1-1-0.1-1.4c0-0.8-0.1-1.6-0.1-2.4c0-0.3,0-0.6-0.1-0.9c-0.1-1-0.1-2-0.2-3v0l-0.1-0.8c0,0,0,0,0,0c-0.1-1.1-0.6-2-1.4-2.6l0,0l0,0c-0.6-0.5-1.1-1-1.7-1.4C167.1,66.2,166.7,65.9,166.3,65.6z"
          />
          <Path
            fill="#FFFFFF"
            d="M170.9,108.6c-0.9,0-1.7-0.5-2.2-1.3c-0.6-1.2-0.2-2.7,1-3.3l15-7.9c1.2-0.6,2.7-0.2,3.3,1c0.6,1.2,0.2,2.7-1,3.3l-15,7.9C171.7,108.5,171.3,108.6,170.9,108.6z"
          />
          <PathEvenOdd
            fill="#ADAFDE"
            d="M167.2,108.2L167.2,108.2c-1.1-2.1-0.3-4.7,1.8-5.8c0,0,0,0,0,0l15-7.9c0,0,0,0,0,0c0,0,0,0,0,0c2.1-1.1,4.7-0.3,5.8,1.8c1.1,2.1,0.3,4.7-1.8,5.8l-15,7.9c-0.6,0.3-1.3,0.5-2,0.5C169.4,110.5,167.9,109.6,167.2,108.2z M188.1,97.1c-0.6-1.2-2.1-1.7-3.3-1l-15,7.9c-1.2,0.6-1.7,2.1-1,3.3c0.4,0.8,1.3,1.3,2.2,1.3c0.4,0,0.8-0.1,1.1-0.3l15-7.9C188.3,99.8,188.7,98.3,188.1,97.1z"
          />
          <Path
            fill="#FFFFFF"
            d="M173.6,66.8l-8.3,6.8l1.8,13.1l9.3,4.8l6.2-12.1L177.3,67L173.6,66.8z"
          />
          <Path
            fill="#ADAFDE"
            d="M171.2,90.1c-0.3,0-0.6-0.1-0.8-0.3c-0.5-0.4-0.5-1.2-0.1-1.7l7.8-8.6l-0.7-1.4l-2.6,1.7c-4.9,2.9-8.2,1-9.3-0.5c-0.4-0.5-0.3-1.3,0.2-1.6l10-7.5c0.5-0.4,1.2-0.3,1.6,0.2c0.4,0.5,0.3,1.3-0.2,1.6l-8.8,6.6c1,0.5,2.6,0.7,5.2-0.8l3.7-2.4c0.3-0.2,0.6-0.2,0.9-0.2c0.3,0.1,0.6,0.3,0.7,0.6l1.7,3.3c0.2,0.4,0.2,1-0.2,1.3l-8.4,9.2C171.9,90,171.6,90.1,171.2,90.1z"
          />
          <Path
            fill="#ADAFDE"
            d="M168.8,77.5c-2.6,0-4.8-2.6-4.9-2.7c-0.4-0.5-0.3-1.2,0.2-1.6l9.7-8.1c0.5-0.4,1.2-0.3,1.6,0.2c0.4,0.5,0.3,1.2-0.2,1.6l-8.7,7.3c0.7,0.6,1.9,1.3,2.9,0.9c0.6-0.2,1.3,0.1,1.5,0.7c0.2,0.6-0.1,1.3-0.7,1.5C169.8,77.4,169.3,77.5,168.8,77.5z"
          />
          <Path
            fill="#343799"
            d="M167.7,66.2c-4.3,0.8-11.2,0.9-15.9-0.5v-3.9h8.1L167.7,66.2z"
          />
        </Group>
      </Group>
    </Base>
  );
};

export default Image;
