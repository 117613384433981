import React from 'react';
import { SVG, Path } from './consts';

const Skills = (props) => (
  <SVG {...props}>
    <Path
      d="M10.5,6.8c0,1-0.5,1.8-1.3,2.2h0.5c0.8,0,1.5,0.7,1.5,1.5c0,0.4-0.3,0.8-0.8,0.8H5.3c-0.4,0-0.8-0.3-0.8-0.8
      C4.5,9.7,5.2,9,6,9h0.5C5.8,8.5,5.3,7.7,5.3,6.8c0-1.4,1.2-2.6,2.6-2.6C9.3,4.1,10.5,5.3,10.5,6.8z M13.1,7.3c0-0.8,0-1.3,0.2-1.6
      c0.1-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.7-0.2,1.6-0.2h1.2c0.8,0,1.3,0,1.6,0.2c0.3,0.1,0.5,0.4,0.7,0.7c0.2,0.3,0.2,0.7,0.2,1.6v1.2
      c0,0.8,0,1.3-0.2,1.6c-0.1,0.3-0.4,0.5-0.7,0.7c-0.3,0.2-0.7,0.2-1.6,0.2h-1.2c-0.8,0-1.3,0-1.6-0.2c-0.3-0.1-0.5-0.4-0.7-0.7
      c-0.2-0.3-0.2-0.7-0.2-1.6V7.3z M4.3,16.6c0.1,0.3,0.4,0.6,1,1.2l0.8,0.8c0.6,0.6,0.9,0.9,1.2,1c0.3,0.1,0.6,0.1,0.9,0
      c0.3-0.1,0.6-0.4,1.2-1l0.8-0.8c0.6-0.6,0.9-0.9,1-1.2c0.1-0.3,0.1-0.6,0-0.9c-0.1-0.3-0.4-0.6-1-1.2l-0.8-0.8
      c-0.6-0.6-0.9-0.9-1.2-1c-0.3-0.1-0.6-0.1-0.9,0c-0.3,0.1-0.6,0.4-1.2,1l-0.8,0.8c-0.6,0.6-0.9,0.9-1,1.2C4.2,16,4.2,16.3,4.3,16.6z
      M16.1,19.5c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4s-3.4,1.5-3.4,3.4S14.3,19.5,16.1,19.5z"
    />
  </SVG>
);

export default Skills;
