import Animation from './Animation';
import Card from './Card';
import Grid from './Grid';
import Mini from './Mini';
import List from './List';
import Thumbnail from './Thumbnail';
import { isBaseKudos, KUDOS_DEFAULT_TYPE, KUDOS_OPTIONS } from './consts';

const getOptions = () => KUDOS_OPTIONS;

export { isBaseKudos, KUDOS_DEFAULT_TYPE, getOptions };

export default {
  Animation,
  Card,
  Grid,
  Mini,
  getOptions,
  isBaseKudos,
  List,
  Thumbnail,
};
