import React from 'react';
import SVG, { Path } from '../../SVG';

const SmallArrowDown = (props) => (
  <SVG height={4} width={7} viewBox="0 0 7 4" {...props}>
    <Path d="M7,0L3.5,4L0,0H7z" />
  </SVG>
);

export default SmallArrowDown;
