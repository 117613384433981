import styled, { css } from 'styled-components';
import {
  Modal,
  Input,
  Form,
  Grid,
  IconNew as Icon,
  TextArea,
} from '@matterapp/matter-ui';
import { font, spacing, colors, lineHeight, media, border } from '@matterapp/matter-theme';
import CustomColorPicker from './CustomColorPicker';
import theme from '@matterapp/matter-theme';

export const StyledColorPicker = styled(CustomColorPicker)({
  position: 'absolute',
  top: '-250px'
});

export const MainModal = styled(Modal).attrs(() => ({
  size: Modal.sizes.CUSTOM,
  customWidth: '608px',
  fullScreenMobile: true,
  overflow: 'visible',
}))``;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid ${colors.blacks[10]};
  margin-bottom: ${spacing.singleAndHalf};
`;

export const ModalBody = styled(Modal.Body)`
`;

export const DetailsContainer = styled.div`
  padding: ${spacing.singleAndHalf} ${spacing.singleAndHalf} 0 ${spacing.singleAndHalf};
`;

export const PreviewContainer = styled.div`
  padding: 0 ${spacing.singleAndHalf};
  margin-bottom: ${spacing.singleAndHalf};
`;

export const FormGroup = styled(Form.Group)`
  margin-bottom: ${spacing.singleAndHalf};
`;

export const TitleInput = styled(Input).attrs(() => ({
  fluid: true,
}))`
  font-size: ${font.size.S};
  margin-top: ${spacing.single};
`;

export const TitleDetails = styled.div`
  font-size: ${font.size.S2};
  line-height: ${lineHeight.M};
  margin-top: ${spacing.single};
  color: ${colors.blacks[60]};
`;

export const ColorPanelContainer = styled.div`
  margin-top: ${spacing.single};
`;

export const ColorGrid = styled(Grid).attrs(() => ({
  responsiveColumns: [6, 6, 10, 10, 10],
  spacing: spacing.half,
}))``;

export const Mini = styled.div`
  cursor: pointer;
  height: ${spacing.triple};
  width: ${spacing.triple};
  border-radius: ${spacing.quarter};
  background-size: cover;
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  display: flex;
  align-items: center;
  justify-content: center;
  &:active, &:hover {
    border: 1px solid ${colors.blues[50]};
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.08);
  }
`;

export const CustomMini = styled.div`
  cursor: pointer;
  height: ${spacing.triple};
  width: ${({ isCustomColor }) => isCustomColor ? spacing.septupleAndHalf : spacing.quintupleAndHalf};
  border-radius: ${spacing.quarter};
  border: 1px solid #E6E6E7;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active, &:hover {
    border: 1px solid ${colors.blues[50]};
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.08);
  }
`;

export const CheckMark = styled(Icon).attrs(() => ({
  name: 'checkmarkSmall',
}))``;

export const CheckMarkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: ${spacing.singleAndHalf};
  height: ${spacing.singleAndHalf};
  border-radius: 50%;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
`;

export const PreviewInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 240px;
  margin-top: ${spacing.single};
  border-radius ${spacing.quarter};
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  color: ${({ isLight}) => isLight ? colors.blacks[70] : colors.white};
`;


const KUDOS_TEXT_Z_INDEX = 5;
const KUDOS_ANIMATION_Z_INDEX = 10;
const IMAGE_MAX_WIDTH = 200;

export const textContainerPadding = `${spacing.singleAndHalf} `;

export const TextStyles = css`
  color: ${({ isLight, textColor }) => textColor ? textColor : (isLight ? colors.blacks[70] : colors.white)};
  font-size: ${font.size.S};
  font-weight: ${font.weight.bold};
  line-height: ${lineHeight.M};
  z-index: ${KUDOS_TEXT_Z_INDEX};

  ${media.S`
    font-size: ${font.size.L};
    line-height: ${lineHeight.L};
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  word-break: break-word;
  z-index: ${KUDOS_TEXT_Z_INDEX};

  & > * {
    flex: 1 1 auto;
    z-index: ${KUDOS_TEXT_Z_INDEX};
  }
`;

export const Text = styled.div`
  ${TextStyles}
  margin: -${spacing.quarter} 0;
  white-space: break-spaces;
`;

export const TextSpacer = styled.div`
  display: inline-block;
  width: ${IMAGE_MAX_WIDTH - 40}px;
  height: ${spacing.single};
`;

export const TextEdit = styled(TextArea).attrs(() => ({
  autoHeight: true,
  showBottomBorder: false,
}))`
  ${TextStyles}
  background: none !important;
  overflow: hidden;
  margin: -${spacing.quarter} 0;
  padding: ${textContainerPadding};
  width: 100%;

  ::placeholder {
    color: ${({ isLight, textColor }) => textColor ? textColor : (isLight ? colors.blacks[70] : colors.white)};
  }
  :focus {
    ::placeholder {
      color: ${({ isLight, textColor }) => textColor ? textColor : (isLight ? colors.blacks[70] : colors.white)};
    }
  &[disabled] {
    filter: opacity(50%);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: 128px;
  padding: 0 ${spacing.singleAndHalf} ${spacing.singleAndHalf};
  z-index: ${KUDOS_ANIMATION_Z_INDEX};

  & > svg {
    display: block;
    min-width: ${IMAGE_MAX_WIDTH}px;
    max-width: ${IMAGE_MAX_WIDTH}px;
    margin-right: -${spacing.singleAndHalf};
    margin-bottom: -${spacing.singleAndHalf};
    margin-left: -100px;
    height: auto;
    overflow: visible;
    opacity: 0.7;
  }
`;

export const Label = styled.div`
  background: ${colors.white};
  border-radius: ${border.radius.S};
  display: inline-block;
  font-size: ${font.size.XS};
  font-weight: ${font.weight.medium};
  height: ${spacing.singleAndHalf};
  padding: ${spacing.quarter} ${spacing.half};
  position: relative;
  text-transform: uppercase;
`;

export const LabelContainer = styled.div`
  flex: 1 1;
  color: ${colors.blacks[40]};
`;

export const AddImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed white;
  box-sizing: border-box;
  border-radius: ${spacing.quarter};
  height: 128px;
  width: 128px;
  background: transparent;
`;

export const AddImageText = styled.div`
  font-weight: ${font.weight.bold};
  color: ${({isLight}) => isLight ? colors.blacks[70] : colors.white};
  font-size: 11px;
  line-height: ${lineHeight.base};
`;

export const CustomColorsBlock = styled('div')(() => ({
  display: 'flex',
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    flexDirection: 'column'
  }
}));

export const CustomColorsSubBlock = styled('div')(() => ({
  display: 'flex',
  alignItems: 'baseline',
  marginRight: theme.spacing.single,
  '> p': {
    color: theme.colors.blacks[60],
    fontSize: theme.fontSize.S
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    marginRight: 0,
    marginTop: theme.spacing.single,
    justifyContent: 'space-between'
  }
}));

export const StyledInput = styled(Input)(() => ({
  width: '104px',
  border: '#7E81C8 solid 3px',
  '& input': {
    color: theme.colors.blacks[60]
  }
}));

export const ColorPickerWrapper = styled.div({
  position: 'absolute',
  top: '-220px'
});
