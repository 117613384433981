import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Clickable } from '../Clickable/Clickable';

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${theme.spacing.quintuple};
  margin-right: ${theme.spacing.quarter};
  >div:last-child {
    border: none;
  }
  ${theme.media.S`
    margin: 0;
  `}
`;

const Tab = styled(Clickable)`
  height: 100%;
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[30]};
  height: 100%;
  width: 100%;
  padding: 0 ${theme.spacing.threeQuarters};
  display: flex;
  align-items: center;
  user-select: none;
  width: ${theme.spacing.quintuple};
  &:hover {
    color: ${theme.colors.purples[50]};
  }
  ${({ isActive }) => isActive && `
    color: ${theme.colors.blacks[70]};
    font-weight: ${theme.fontWeight.bold};
  `}
`;

const Divider = styled.div`
  height: 22px;
  border: .5px solid ${theme.colors.blacks[30]};
`;

const SortingBar = ({ tabsList, activeTab, onActivateTab }) => {
  return (  
    <TabsContainer>
      {tabsList.map((tab, index) => {
        return (
          <React.Fragment key={index}>
            <Tab
              data-rc="sorting-bar-tab"
              key={tab} 
              onClick={() => onActivateTab(index, tab)}
              isActive={activeTab === index}
            >
              {tab}
            </Tab>
            <Divider/>
          </React.Fragment >
        );
      })}
    </TabsContainer>
  );
};

SortingBar.defaultProps = {
  activeTab: null,
  isActive: false,
};

SortingBar.propTypes = {
  activeTab: PropTypes.number,
  onActivateTab: PropTypes.func.isRequired,
  tabsList: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default SortingBar;
