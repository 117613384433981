import withRouter from 'hocs/withRouter';
import { withProps, compose } from 'recompose';

const resolvePath = (path, params) => {
  if (typeof path === 'function') {
    return path(params);
  }
  return path;
};

const withStepPaths = ({ ...steps }) =>
  compose(
    withRouter,
    withProps(({ ...props }) => {
      const computedPaths = {};
      Object.keys(steps).forEach((stepName) => {
        computedPaths[stepName] = resolvePath(steps[stepName], props);
      });
      return computedPaths;
    })
  );

export default withStepPaths;
