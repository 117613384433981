import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.000278076 40L14 40L20.1292 40L24 40V27H20.1282C20.1257 18.4768 22.2156 12.2038 25.7905 6.13757L13.8389 0C7.28653 5.50264 -0.0521395 16.9312 0.000279186 27.3016L0.000278076 40ZM26.2098 40H42H46.3387H52V27H46.3378C46.3353 18.4768 48.4251 12.2038 52 6.13757L40.0485 3.50115e-06C33.4961 5.50265 26.1574 16.9312 26.2098 27.3016V40Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#25277A',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
