import React from 'react';
import PropTypes from 'prop-types';
import {
  ListWithHeader,
  NotificationItem,
  Button,
  Text,
} from '@matterapp/matter-ui';

class PendingInvitations extends React.Component {
  renderPendingInvitationItem = (invitation) => {
    const { handleStartInvitation, handleIgnoreInvitation, onClickNotification } = this.props;
    return (
      <NotificationItem
        isStackedOnMobile
        key={invitation.id}
        person={invitation.owner}
        eventTime={invitation.eventTime}
        content={<Text color="black">would like to hear your feedback.</Text>}
        actions={
          <React.Fragment>
            <Button.Primary.Vivid
              key="start"
              content={'Start'}
              size={Button.buttonSizes.S}
              onClick={(e) => {
                onClickNotification(e);
                handleStartInvitation({ invitation });
              }}
            />
            <Button
              key="end"
              content={'Decline'}
              size={Button.buttonSizes.S}
              onClick={(e) => {
                onClickNotification(e);
                handleIgnoreInvitation({ invitation });
              }}
            />
          </React.Fragment>
        }
      />
    );
  };

  render = () => {
    const { pendingInvitations, bordered } = this.props;
    if (pendingInvitations.length === 0) {
      return null;
    }
    return (
      <ListWithHeader
        bordered={bordered}
        header="Awaiting Feedback"
        headerType="success"
        items={pendingInvitations}
        renderItem={this.renderPendingInvitationItem}
        rounded={false}
        showAllItemBorders
      />
    );
  };
}

PendingInvitations.defaultProps = {
  bordered: false,
  onClickNotification: () => null,
};

PendingInvitations.propTypes = {
  pendingInvitations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClickNotification: PropTypes.func,
  handleIgnoreInvitation: PropTypes.func.isRequired,
  handleStartInvitation: PropTypes.func.isRequired,
  bordered: PropTypes.bool,
};

export default PendingInvitations;
