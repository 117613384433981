import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Badge from '../Badge';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  display: inline-flex;
  height: ${theme.spacing.double};
  background: ${({ light }) => light ? theme.colors.white : theme.colors.blacks[5]};
  border-radius: ${theme.borderRadius.pill};
  padding: ${theme.spacing.quarter};
  font-weight: ${theme.fontWeight.regular};
  line-height: ${theme.lineHeight.S};
  font-size: ${theme.fontSize.base};
  color: ${theme.colors.blacks[90]};

  span {
    margin-right: ${theme.spacing.threeQuarters};
  }
`;

const SkillBadgeContainer = styled.div`
  display: inline-block;
  width: ${theme.spacing.singleAndHalf};
  height: ${theme.spacing.singleAndHalf};
  margin-right: ${theme.spacing.half};
`;

const WithBadge = ({ skill, light }) => (
  <Container light={light}>
    <SkillBadgeContainer>
      <Badge size="small" skill={skill} />
    </SkillBadgeContainer>
    <span>{skill.name}</span>
  </Container>
);

WithBadge.propTypes = {
  skill: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default WithBadge;
