import { Modal, Box, Icon, Typography } from '@mui/material';
import styled from 'styled-components';
import { Button as MatterButton, Radio } from '@matterapp/matter-ui';

export const StyledModal = styled(Modal)(({ theme }) => ({
  width: '100%'
}));

export const SaveNotification = styled(Typography)(({ theme }) => ({
  top: '-12px',
  color: theme.palette.blacks[70],
  whiteSpace: 'nowrap'
}));

export const StyledRadio = styled(Radio.List.Settings)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1)
}));

export const RadioNoBackground = styled(Radio.List)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  '> button': {
    height: '32px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    }
  }
}));

export const Content = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.grey.lighter
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  backgroundColor: theme.palette.white.main,
  borderBottom: `1px solid ${theme.palette.blacks[10]}`,
  height: '67px'
}));

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'fixed',
  zIndex: 1,
  bottom: 0,
  width: '100%',
  height: '96px',
  boxSizing: 'border-box',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  backgroundColor: theme.palette.white.main,
  borderTop: `1px solid ${theme.palette.blacks[10]}`,
  [theme.breakpoints.down('sm')]: {
    height: '80px',
    padding: theme.spacing(2)
  }
}));

export const CloseButton = styled(Icon)({
  cursor: 'pointer',
  width: '24px', 
  height: '24px'
});

export const Body = styled(Box)(({ theme }) => ({
  overflowY: 'scroll',
  height: '100%'
}));

export const BodyInternalWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '966px',
  margin: '0 auto',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(15)
}));

export const ContinueButton = styled(MatterButton.Simple.Primary.Vivid)({

});

export const PreviewButton = styled(MatterButton)(({ theme, isSlack }) => ({
  marginRight: theme.spacing(2),
  ':after': {
    content: `"Preview in ${isSlack ? 'Slack' : 'Teams'}"`,
    [theme.breakpoints.down('sm')]: {
      content: '"Preview"'
    }
  }
}));

export const ProgressBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const MobileProgressBar = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '100%'
  }
}));

export const ProgressElement = styled(Box)(({ theme, isActive, canBeClicked }) => ({
  display: 'flex',
  cursor: canBeClicked ? 'pointer' : 'default',
  alignItems: 'center',
  color: isActive ? theme.palette.blues[50] : canBeClicked ? theme.palette.blacks[80] : theme.palette.blacks[20],
  marginRight: theme.spacing(2),
  '&:first-of-type': {
    'svg:first-of-type': {
      marginRight: theme.spacing(1),
      fill: isActive ? theme.palette.blues[50] : theme.palette.blacks[80],
    }
  },
  svg: {
    fill: isActive ? theme.palette.blues[50] : canBeClicked ? theme.palette.blacks[80] : theme.palette.blacks[20],
    marginRight: theme.spacing(1)
  },
  'svg:first-of-type': {
    marginRight: theme.spacing(2),
    fill: isActive || canBeClicked ? theme.palette.blacks[80]: theme.palette.blacks[20]
  },
  p: {
    fontWeight: 'bold'
  }
}));

export const SaveWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));
