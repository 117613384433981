import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconNew as Icon } from '../../Icon';
import theme from '@matterapp/matter-theme';

const Checkmark = styled(Icon).attrs(() => ({
  name: 'checkmarkSmall',
}))``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing.single} 0;
  min-width: 160px;
  transition: opacity ${theme.transitions.times.default};

  ${({ disabled, hide }) => {
    if (hide) {
      return 'opacity: 0';
    }
    if (disabled) {
      return 'opacity: 0.5';
    }
    return '';
  }}
`;

const StepContainer = styled.div`
  background: ${theme.colors.purples[40]};
  border-radius: 100%;
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeight.bold};
  height: ${theme.spacing.singleAndHalf};
  width: ${theme.spacing.singleAndHalf};

  & > div {
    line-height: ${theme.spacing.singleAndHalf};
    height: ${theme.spacing.singleAndHalf};
    width: ${theme.spacing.singleAndHalf};
  }

  & svg {
    height: ${theme.spacing.singleAndHalf};
    & > path {
      fill: ${theme.colors.white};
    }
  }
`;

const TitleContainer = styled.div`
  font-weight: ${theme.fontWeight.bold};
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fontSize.base};

  ${StepContainer} + & {
    margin-top: ${theme.spacing.half};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fontSize.base};

  ${StepContainer} + & {
    margin-top: ${theme.spacing.half};
  }
`;

const Loops = (props) => {
  const { className, disabled, hide, isComplete, step, text, title } = props;
  return (
    <Container className={className} disabled={disabled} hide={hide}>
      <StepContainer><div>{isComplete ? <Checkmark /> : step}</div></StepContainer>
      {title && <TitleContainer>{title}</TitleContainer>}
      {text && <TextContainer>{text}</TextContainer>}
    </Container>
  );
};

Loops.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  isComplete: PropTypes.bool,
  number: PropTypes.number,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Loops;
