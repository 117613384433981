import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import { IconNew } from '../Icon';
import Text from '../Typography';
import theme from '@matterapp/matter-theme';

const RightAlignedBox = styled.div`
  text-align: right;
  margin-left: auto;
`;

const ButtonBox = styled(RightAlignedBox)`
  display: flex;
  justify-content: flex-end;
  align-items: top;

  & > * {
    margin-left: ${theme.spacing.half};
  }
`;

const Container = styled.div`
  padding: calc(${theme.spacing.single} + ${theme.spacing.quarter});
`;

const ContentContainer = styled.div`
  margin-bottom: ${theme.spacing.double};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 8px;
`;

const CloseButtonContainer = styled(Text)`
  svg {
    transform: scale(1);
    transition: ${() => theme.transitions.times.default};

    & g {
      fill: ${() => theme.colors.blues[50]};
    }
  }
  &:hover svg g {
    fill: ${() => theme.colors.purples[40]};
  }
  &:active svg {
    transform: scale(0.8);

    & g {
      fill: ${() => theme.colors.blacks[80]};
    }
  }
`;

const ConfirmContainer = (props) => {
  const { heading, subheading, buttonRight, buttonLeft, onCancel } = props;

  return (
    <Container>
      <HeaderContainer>
        <div>
          <Text color='black' textStyle={500} bold block>
            {heading}
          </Text>
        </div>
        <RightAlignedBox>
          <Button.New link onClick={onCancel}>
            <CloseButtonContainer>
              <IconNew name='close' />
            </CloseButtonContainer>
          </Button.New>
        </RightAlignedBox>
      </HeaderContainer>
      <ContentContainer>
        <Text color='black' textStyle={400}>
          {subheading}
        </Text>
      </ContentContainer>
      <ButtonBox>
        {buttonLeft}
        {buttonRight}
      </ButtonBox>
    </Container>
  );
};

ConfirmContainer.defaultProps = {
  buttonLeft: null,
};

ConfirmContainer.propTypes = {
  buttonLeft: PropTypes.node,
  buttonRight: PropTypes.node.isRequired,
  heading: PropTypes.node.isRequired,
  subheading: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmContainer;
