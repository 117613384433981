import React from 'react';
import PropTypes from 'prop-types';
import { ModalNew as Modal } from '@matterapp/matter-ui';
import { skillGeneratorStatePropType } from '../prop-types';
import {
  CreateCustomSkillMode,
  EditCustomSkillMode,
  PickAddMethodMode,
  RecommendedSkillsMode,
  CustomSkillSuccessOverlay,
} from '../modules';
import { SKILL_GENERATOR_MODES } from '../consts';

class SkillGeneratorModal extends React.PureComponent {
  renderHeader = ({
    createCustomSkillModeRenderProps,
    pickAddMethodModeRenderProps,
  }) => {
    const activeRenderProp = this.getActiveRenderProp({
      createCustomSkillModeRenderProps,
      pickAddMethodModeRenderProps,
    });
    return activeRenderProp.renderModalHeader();
  };

  renderBody = ({
    createCustomSkillModeRenderProps,
    pickAddMethodModeRenderProps,
  }) => {
    const { skillGeneratorState } = this.props;
    const { mode } = skillGeneratorState;
    switch (mode) {
    case SKILL_GENERATOR_MODES.CREATE_CUSTOM_SKILL_MODE: {
      return createCustomSkillModeRenderProps.renderModalBody();
    }
    case SKILL_GENERATOR_MODES.PICK_ADD_METHOD_MODE: {
      return pickAddMethodModeRenderProps.renderModalBody();
    }
    default:
      throw new Error(`Unrecognized skill generator mode: ${mode}`);
    }
  };

  renderRecommendedSkillsMode = () => {
    const {
      handleClickBackFromAddSkill,
      handleDismissGeneratorModal,
    } = this.props;
    return (
      <RecommendedSkillsMode
        onClickBack={handleClickBackFromAddSkill}
        onClickDone={handleDismissGeneratorModal}
        handleDismissGeneratorModal={handleDismissGeneratorModal}
      />
    );
  };

  renderPickAddMethodMode = () => {
    const {
      handleClickRecommendedSkills,
      handlePickCreateCustomSkillMode,
      handleDismissGeneratorModal,
    } = this.props;
    return (
      <PickAddMethodMode
        onClickCreateCustomSkill={handlePickCreateCustomSkillMode}
        onClickRecommendedSkills={handleClickRecommendedSkills}
        onClickClose={handleDismissGeneratorModal}
      />
    );
  };

  renderEditCustomSkillMode = () => {
    const { handleDismissGeneratorModal, skillGeneratorState } = this.props;
    return (
      <EditCustomSkillMode
        onClickBack={handleDismissGeneratorModal}
        onClickClose={handleDismissGeneratorModal}
        handleDismissGeneratorModal={handleDismissGeneratorModal}
        onClickDone={handleDismissGeneratorModal}
        skillToEdit={skillGeneratorState.skillToEdit}
      />
    );
  };

  renderCreateCustomSkillMode = ({ handleSetSkillForSuccessOverlay }) => {
    const {
      handleClickBackFromAddSkill,
      handleDismissGeneratorModal,
    } = this.props;
    return (
      <CreateCustomSkillMode
        onClickBack={handleClickBackFromAddSkill}
        onClickClose={handleDismissGeneratorModal}
        handleDismissGeneratorModal={handleDismissGeneratorModal}
        handleSetSkillForSuccessOverlay={handleSetSkillForSuccessOverlay}
      />
    );
  };

  renderInnerModal = ({ handleSetSkillForSuccessOverlay }) => {
    const { skillGeneratorState } = this.props;
    const { mode } = skillGeneratorState;
    switch (mode) {
    case SKILL_GENERATOR_MODES.PICK_ADD_METHOD_MODE: {
      return this.renderPickAddMethodMode();
    }
    case SKILL_GENERATOR_MODES.RECOMMENDED_SKILLS_MODE: {
      return this.renderRecommendedSkillsMode();
    }
    case SKILL_GENERATOR_MODES.CREATE_CUSTOM_SKILL_MODE: {
      return this.renderCreateCustomSkillMode({
        handleSetSkillForSuccessOverlay,
      });
    }
    case SKILL_GENERATOR_MODES.EDIT_CUSTOM_SKILL_MODE: {
      return this.renderEditCustomSkillMode();
    }

    default:
      throw new Error(`Unrecognized skill generator mode: ${mode}`);
    }
  };

  renderModal = () => {
    const {
      handleDismissGeneratorModal,
      skillGeneratorState,
      handlePickCreateCustomSkillMode,
    } = this.props;
    return (
      <CustomSkillSuccessOverlay
        handlePickCreateCustomSkillMode={handlePickCreateCustomSkillMode}
      >
        {(customSkillSucceseOverlayRenderProps) => (
          <>
            {customSkillSucceseOverlayRenderProps.overlay}
            <Modal
              isOpen={skillGeneratorState.isModalOpen}
              transparent
              flushOnMobile
              contentLabel="Skill Generator"
              onRequestClose={handleDismissGeneratorModal}
            >
              {this.renderInnerModal({
                handleSetSkillForSuccessOverlay:
                  customSkillSucceseOverlayRenderProps.handleSetSkill,
              })}
            </Modal>
          </>
        )}
      </CustomSkillSuccessOverlay>
    );
  };

  render = () => {
    const { children, handleStartAddSkill, handleStartEditSkill } = this.props;
    return children({
      handleStartAddSkill,
      handleStartEditSkill,
      renderModal: this.renderModal,
    });
  };
}

SkillGeneratorModal.propTypes = {
  children: PropTypes.func.isRequired,
  skillGeneratorState: skillGeneratorStatePropType.isRequired,
  handlePickCreateCustomSkillMode: PropTypes.func.isRequired,
  handleClickRecommendedSkills: PropTypes.func.isRequired,
  handleClickBackFromAddSkill: PropTypes.func.isRequired,
  handleStartAddSkill: PropTypes.func.isRequired,
  handleStartEditSkill: PropTypes.func.isRequired,
  handleDismissGeneratorModal: PropTypes.func.isRequired,
};

export default SkillGeneratorModal;
