import React from 'react';
import PropTypes from 'prop-types';

const ANIMATIONS = {
  IN: 'animateIn',
  OUT: 'animateOut',
  SHOW: 'dontAnimate',
  IN_FROM_OUT: 'animateInFromOut',
  OUT_FROM_IN: 'animateOutFromIn',
};

export default class Animation extends React.PureComponent {

  static ANIMATIONS = ANIMATIONS;

  static propTypes = {
    /** Amount of milliseconds to wait for playing animation on mount of `playAnimationOnMount` is true. */
    animationMountDelay: PropTypes.number,
    /** Plays animation when component mounts. */
    playAnimationOnMount: PropTypes.bool,
  };

  static defaultProps = {
    animationMountDelay: 0,
    playAnimationOnMount: false,
  };

  static killAnimation = (timeline) => {
    if (timeline && timeline.kill) {
      // If animation is currently playing, pause it and reset animation.
      timeline.kill();
    }
  }

  _isMounted = false;

  componentDidMount() {
    const { animationMountDelay, playAnimationOnMount } = this.props;
    this.playAnimation({ initialize: true, isMounting: true });
    if (playAnimationOnMount) {
      // Play animation if `playAnimationOnMount` passed it with the given delay time.
      setTimeout(() => {
        /** Plays animation once and sets the mounted flag after the initial animation is played. */
        this.playAnimation({ initialize: false, isMounting: true }).then(() => {
          this._isMounted = true;
        });
      }, animationMountDelay);
    } else {
      this._isMounted = true;
    }
    this.onAnimationMount();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.onAnimationUnmount();
  }

  /** Additinoal function to run when animation mounts. */
  onAnimationMount = () => null;

  /** Additinoal function to run when animation unmounts. */
  onAnimationUnmount = () => null;

  /**
   * Plays the animation.
   * @param { Object } playParams: Objected containing flags to initilize animation or signal if animation isMounting.
   * @returns { Promise } The gsap animation promise.
   */
  playAnimation = () => {
    console.error(
      'playAnimation is not defined. This function is required by all animations!',
      this
    );
  };

  resetAnimation = () => {
    console.error(
      'resetAnimation is not defined. This function is required by all animations!',
      this
    );
  };
}
