import React from 'react';
import styled from 'styled-components';
import { 
  Paper,
  Typography
} from '@mui/material';
import CustomCircularProgress from 'components/CircularProgress/CircularProgress';
import Chart from '../Chart/Chart';
import { formatAveragesToDataPoints } from '../dateFilters';
import theme from '@matterapp/matter-theme';
import { ChartsWrapper, ChartWrapper, ProgressWrapper } from '../styles';

const GroupedByTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.half};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.XS};
  line-height: ${theme.lineHeight.XS};
  color: ${theme.colors.blacks[50]};
`;

export default function QuestionDetails({ 
  category,
  questionData,
  startDate, 
  endDate,
  isLoading,
  xAxisMaxLabels,
  groupedBy,
  groupBy
}) {
  const { score, explanation, question, rollingEngagementScore = [], datePoints } = questionData || {};
  const hasScore = score !== null;
  const data = formatAveragesToDataPoints(rollingEngagementScore, startDate, endDate, groupBy);

  if (isLoading || !rollingEngagementScore?.length) {
    return null;
  }

  return (
    <Paper elevation={1} sx={{ mb: 3 }}>
      <Typography variant='h4' component='p' sx={{mb: '5px' }}>{category}: {question}</Typography>
      <ChartsWrapper>
        <ProgressWrapper>
          <CustomCircularProgress 
            thickness={2}
            size={264}
            value={score}
          >
            <Typography 
              sx={{
                fontSize: hasScore ? '48px' : '24px',
                fontWeight: 'bold',
                lineHeight: '48px',
                mb: '4px'
              }}
              component="span" 
              color="black"
            >
              {hasScore ? `${score}%` : 'No score yet'}
            </Typography>
            <Typography variant='h2' sx={{ fontSize: '24px', lineHeight: '30px' }}>
              {hasScore ? explanation : null}
            </Typography>
          </CustomCircularProgress>
        </ProgressWrapper>
        <ChartWrapper>
          <Chart
            height='320px'
            data={data}
            showLineAtTheEnd={false}
            showLineAtTheStart={false}
            showScatterDataStyle={false}
            xAxisMaxLabels={xAxisMaxLabels}
            generateLabels={() => datePoints}
            maxStepSize={100}
            formatXAxis={() => {
              if (groupBy === 'month') {
                return {
                  unit: 'month',
                  displayFormats: {
                    month: 'MMM',
                  }
                };
              }  

              return {
                unit: 'day',
                displayFormats: {
                  day: 'MMM D',
                }
              };
            }}
            stepSize={20}
            yAxisRender={(value) => `${value}%`}
          />      
          <GroupedByTitle> {groupedBy} </GroupedByTitle>
        </ChartWrapper>
      </ChartsWrapper>
    </Paper>
  );
}
