const defaultChangeProps = {
  source: {},
  destination: {},
};

const defaultListProps = { sourceList: [], destinationList: [] };

/**
 * Helper function to reorder items.
 * @param { Object } changeProps: Params passed from the DragAndDrop list containing the source and destination props.
 * @param { Array } items: The items in the list to reorder.
 * @returns { Array } The reordered list.
 */
export const handleReorder = (changeProps = defaultChangeProps, items = []) => {
  const { source, destination } = changeProps;
  const itemIndex = source.index;
  const newIndex = destination.index;
  const newItems = [...items];
  const item = newItems[itemIndex];
  newItems.splice(itemIndex, 1);
  newItems.splice(newIndex, 0, item);
  return newItems;
};

/**
 * Helper function to move items from source list to destination list.
 * @param { Object } changeProps: Params passed from the DragAndDrop list containing the source and destination props.
 * @param { Object } lists: Object containing the sourceList and destinationList.
 * @returns { Object } The new source and destination lists.
 */
export const handleMove = (
  changeProps = defaultChangeProps,
  lists = defaultListProps
) => {
  const { source, destination } = changeProps;
  const sourceIndex = source.index;
  const destinationIndex = destination.index;

  const newSourceList = lists.sourceList.filter(
    (item, index) => index !== sourceIndex
  );
  const newDestinationList = [
    ...lists.destinationList.slice(0, destinationIndex),
    lists.sourceList[sourceIndex],
    ...lists.destinationList.slice(destinationIndex),
  ];
  return { sourceList: newSourceList, destinationList: newDestinationList };
};
