import React from 'react';
import styled from 'styled-components';
import { colors, font } from '@matterapp/matter-theme';

const Container = styled.div`
  align-items: center;
  color: ${colors.blacks[30]};
  display: none;
  height: 167px;
  justify-content: center;
  font-size: ${font.size.S};
  font-weight: ${font.weight.semiBold};
  text-transform: uppercase;

  &:only-child {
    display: flex;
  }
`;

const Empty = () => (
  <Container>
    You have no unread notifications
  </Container>
);

export default Empty;
