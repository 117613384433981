import React from 'react';
import CenterToCenter1 from '../Paths/CenterToCenter1';
import BasePath from './BasePath';

const Path0 = React.forwardRef((props, ref) => (
  <BasePath
    PathComponent={CenterToCenter1}
    pointProps={{ top: 106, left: 50 }}
    ref={ref}
    {...props}
  />
));

export default Path0;
