import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill, className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0796 13.8627C11.3482 13.6108 11.3618 13.1889 11.11 12.9203L6.53744 8.04297L11.11 3.16559C11.3618 2.89699 11.3482 2.47509 11.0796 2.22327C10.811 1.97145 10.3891 1.98506 10.1373 2.25367L4.70979 8.04297L10.1373 13.8323C10.3891 14.1009 10.811 14.1145 11.0796 13.8627Z"
      fill="#6B6B6F"
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#6B6B6F',
};

Icon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
