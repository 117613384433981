import React from 'react';
import PropTypes from 'prop-types';

import TextContainer from './TextContainer';
import FlashContainer from './FlashContainer';

const FlashMessage = ({ type, intro, body }) => (intro || body ? (
  <FlashContainer type={type}>
    <TextContainer>
      {intro ? (<strong>{intro}</strong>) : null}{' '}
      {body}
    </TextContainer>
  </FlashContainer>
) : null);

FlashMessage.defaultProps = {
  intro: null,
  body: null,
  type: 'normal',
};

FlashMessage.propTypes = {
  intro: PropTypes.string,
  body: PropTypes.node,
  type: PropTypes.oneOf(['normal', 'important']),
};

export default FlashMessage;
