import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SmallScreen, PlusSizeScreen } from '../../Screen';
import HeaderContainer from './HeaderContainer';

const StepCount = styled.span`
  font-weight: bold;
`;

const HeaderStepText = ({
  currentStep,
  numTotalSteps,
  stacked,
  stepText,
  truncateOnMobile,
}) => {
  if (!currentStep) {
    return null;
  }
  const plusScreenText = `Step ${currentStep} of ${numTotalSteps}:`;
  const smallScreenText = truncateOnMobile
    ? `${currentStep}/${numTotalSteps}`
    : plusScreenText;
  return (
    <HeaderContainer stacked={stacked}>
      <StepCount>
        <SmallScreen>{smallScreenText}</SmallScreen>
        <PlusSizeScreen>{plusScreenText}</PlusSizeScreen>
      </StepCount>
      <span> {stepText}</span>
    </HeaderContainer>
  );
};

HeaderStepText.propTypes = {
  currentStep: PropTypes.number.isRequired,
  numTotalSteps: PropTypes.number.isRequired,
  stepText: PropTypes.string,
  stacked: PropTypes.bool,
  truncateOnMobile: PropTypes.bool,
};

export default HeaderStepText;
