import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const StyledBox = styled.div`
  background: ${() => theme.colors.blacks['5']};
  border-radius: 4px;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 12px;

  ${() => theme.media.medium`
    padding-top: 40px;
    padding-bottom: 40px;
  `}

  ${() => theme.media.large``}
`;

const Pane = ({ children, style }) => (
  <StyledBox style={style}>{children}</StyledBox>
);

Pane.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Pane;
