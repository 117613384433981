import styled from 'styled-components';
import Panel from '../Panel';

const GamePanelBase = styled(Panel.Base)`
  position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
  height: 288px;
  margin: 0;
`;

export default GamePanelBase;
