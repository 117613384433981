import React from 'react';
import {
  BillingFooterContainer,
  BillingFooterHeader,
  BillingFooterContent,
} from './styles';

const CONTACT_TEXT = 'Contact us here';
const CALENDLY_LINK = 'https://matterapp.schedulehero.io/campaign/free-trial';


function BillingFooter({ workspaceId, userEmail, isUpgraded }) {
  const scheduleLink = <a href={CALENDLY_LINK} target='_blank' rel='noopener noreferrer'>schedule a time</a>;
  const pricingLink = <a href='https://matterapp.com/pricing' target='_blank' rel='noopener noreferrer'>Pricing page</a>;
  const cancelLink = <a href={`https://matterapp.typeform.com/subscription#workspace_id=${workspaceId}&user_email=${userEmail}`} target='_blank' rel='noopener noreferrer'>{CONTACT_TEXT}</a>;
  const billingLink = <a href='https://help.matterapp.com/en/collections/3006655-billing-subscription' target='_blank' rel='noopener noreferrer'>Help Center Billing Articles</a>;
  let description;

  if (isUpgraded) {
    description = <>{cancelLink} to downgrade or {scheduleLink} to discuss pricing if you can no longer afford Matter.</>;
  } else {
    description = <>Pricing too expensive? <a href={CALENDLY_LINK} target='_blank' rel='noopener noreferrer'>Schedule a time</a> to discuss with a Matter team member.</>;
  }

  return (
    <BillingFooterContainer>
      <BillingFooterHeader>Need help?</BillingFooterHeader>
      <BillingFooterContent>
        To learn more visit our {pricingLink} or {billingLink}. <br />
        {description}
      </BillingFooterContent>
    </BillingFooterContainer>
  );

}

export default BillingFooter;
