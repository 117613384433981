import React from 'react';
import PropTypes from 'prop-types';
import { Resources } from '@matterapp/routing';
import {
  Container,
  SideColumnCountContainer,
  SideColumnCountRow,
  SideColumnCountKey,
  SideColumnCountValue,
  AvatarContainer,
} from '../../WorkspacesSideColumn/Styles';

export default class RecurringSideColumn extends React.Component {
  static propTypes = {
    workspace: PropTypes.shape({
      iconUrl: PropTypes.string,
      id: PropTypes.string,
      isAdmin: PropTypes.bool,
      isOwner: PropTypes.bool,
      name: PropTypes.string,
    }),
    selectedPeer: PropTypes.shape({
      userId: PropTypes.string,
      firstName: PropTypes.string,
      fullName: PropTypes.string,
      email: PropTypes.string,
      photoUrl: PropTypes.string,
      isMatterUser: PropTypes.bool,
      recurringFeedback: PropTypes.shape({
        id: PropTypes.string,
        recurrence: PropTypes.shape({
          frequency: PropTypes.string,
        })
      })
    }),
    currentUser: PropTypes.shape({
      id: PropTypes.string,
      pickedSkillDetails: PropTypes.arrayOf(PropTypes.object),
    }),
    workspaceMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedSkill: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      colorPalette: PropTypes.string,
      svg: PropTypes.string,
    }),
    history: PropTypes.object.isRequired,
    recurringLength: PropTypes.number,
    onChangeSelectedAdvisor: PropTypes.func,
    modalHandlers: PropTypes.object
  };

  static defaultProps = {
    showList: true,
  }

  constructor(props) {
    super(props);
    this.state = {
      isInfoModalOpen: false,
    };
  }

  openWorkspaceMembersTab = () => {
    const { history, workspace } = this.props;
    history.push(
      Resources.workspacesMembers.path({ workspaceId: workspace.id })
    );
  };

  handleOpenInfoModal = (member) => {
    const { modalHandlers } = this.props;
    const selectedPeer = {
      ...member.person,
      recurringFeedback: member.recurringFeedback,
    };

    this.props.onChangeSelectedAdvisor(selectedPeer);
    modalHandlers.setInfoModal(true);
  };

  renderCounts = () => {
    return (
      <AvatarContainer>
        <SideColumnCountContainer>
          <SideColumnCountRow showTopLine>
            <SideColumnCountKey>Continuous</SideColumnCountKey>
            <SideColumnCountValue>{this.props.recurringLength}</SideColumnCountValue>
          </SideColumnCountRow>
        </SideColumnCountContainer>
      </AvatarContainer>
    );
  };

  render() {
    const { workspaceMembers, currentUser } = this.props;

    if (!workspaceMembers || !currentUser) {
      return null;
    }

    return (
      <Container
        title={'Continuous'}
        subHeader={'Schedule regular feedback requests with your team.'}
        iconName={'continuousPageIcon'}
      >
        {this.renderCounts()}
      </Container>
    );
  }
}
