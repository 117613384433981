import styled from 'styled-components';
import { Checkbox } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

export const ToggleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.colors.blacks[5],
  width: '100%',
  padding: theme.spacing.single,
  marginBottom: '1px',
  fontSize: theme.fontSize.S
}));

export const ToggleWrapper = styled('div')(() => ({
  marginTop: theme.spacing.single
}));

export const ToggleUnit = styled(Checkbox.Toggle).attrs(() => ({
  size: Checkbox.Toggle.sizes.S,
}))``;
