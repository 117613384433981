import React from 'react';
import Animation from '../../Animation';
import Image from './Image';
import * as C from './classNames';

export default class AmazingJob extends Animation {
  baseClass = C.BASE;

  moveStars = (initialize) => {
    const tl = this.getDefaultTimeline({
      opacity: 0,
    });
    const defaultProps = { x: 0, y: 0, opacity: 1, rotate: 0, duration: 0.2 };
    const defaultPropsFaster = { ...defaultProps, duration: 0.15 };
    const defaultPropsSlower = { ...defaultProps, duration: 0.25 };

    const STAR_TOP_1 = this.getElement(C.STAR.TOP_1);
    const STAR_TOP_2 = this.getElement(C.STAR.TOP_2);
    const STAR_BOTTOM_1 = this.getElement(C.STAR.BOTTOM_1);
    const STAR_BOTTOM_2 = this.getElement(C.STAR.BOTTOM_2);

    const RING_TOP = this.getElement(C.RING.TOP);
    const RING_LEFT = this.getElement(C.RING.LEFT);
    const RING_RIGHT = this.getElement(C.RING.RIGHT);

    const CIRCLE_TOP = this.getElement(C.CIRCLE.TOP);
    const CIRCLE_LEFT_1 = this.getElement(C.CIRCLE.LEFT_1);
    const CIRCLE_LEFT_2 = this.getElement(C.CIRCLE.LEFT_2);
    const CIRCLE_LEFT_3 = this.getElement(C.CIRCLE.LEFT_3);

    tl.to(STAR_TOP_1, { x: -15, y: 70, rotate: 360 }, '<');
    tl.to(STAR_TOP_2, { x: -10, y: 60, rotate: -360 }, '<');
    tl.to(STAR_BOTTOM_1, { x: -40, y: -20, rotate: 360 }, '<');
    tl.to(STAR_BOTTOM_2, { x: -40, y: -20, rotate: -360 }, '<');

    tl.to(RING_TOP, { x: 10, y: 60 }, '<');
    tl.to(RING_LEFT, { x: 50, y: 30 }, '<');
    tl.to(RING_RIGHT, { x: -70, y: -16 }, '<');

    tl.to(CIRCLE_TOP, { x: 30, y: 50 }, '<');
    tl.to(CIRCLE_LEFT_1, { x: 70, y: 10 }, '<');
    tl.to(CIRCLE_LEFT_2, { x: 54, y: 8 }, '<');
    tl.to(CIRCLE_LEFT_3, { x: 40, y: -16 }, '<');

    if (!initialize) {
      tl.to(STAR_TOP_1, defaultProps, '>0.54');
      tl.to(STAR_TOP_2, defaultPropsSlower, '<');
      tl.to(STAR_BOTTOM_1, defaultProps, '<');
      tl.to(STAR_BOTTOM_2, defaultPropsFaster, '<');

      tl.to(RING_TOP, defaultProps, '<');
      tl.to(RING_LEFT, defaultPropsFaster, '<');
      tl.to(RING_RIGHT, defaultPropsSlower, '<');

      tl.to(CIRCLE_TOP, defaultProps, '<');
      tl.to(CIRCLE_LEFT_1, defaultPropsFaster, '<');
      tl.to(CIRCLE_LEFT_2, defaultProps, '<');
      tl.to(CIRCLE_LEFT_3, defaultPropsSlower, '<');
    }

    return tl;
  };

  moveStamp = (initialize) => {
    const tl = this.getDefaultTimeline();
    const STAMP = this.getElement(C.STAMP);
    const INK = this.getElement(C.INK);

    tl.to(INK, {
      opacity: 0,
      duration: initialize ? 0 : 0.2,
    });
    tl.to(STAMP, { x: 0, y: 0, scale: 1 }, 'ink');

    if (!initialize) {
      tl.to(STAMP, { x: -15, y: -20, duration: 0.12 }, '>');
      tl.to(
        STAMP,
        { x: -30, y: -10, scale: 1.1, rotate: -8, duration: 0.12 },
        '>'
      );
      tl.to(
        STAMP,
        { x: -52, y: 16, scale: 1.22, rotate: -15, duration: 0.12 },
        '>'
      );

      tl.to(INK, { opacity: 1 }, '>0.1');
      tl.to(
        STAMP,
        { x: -52, y: 16, scale: 1.22, rotate: -15, duration: 0.1 },
        '<'
      );
      tl.to(
        STAMP,
        { x: -30, y: -10, scale: 1.1, rotate: -8, duration: 0.1 },
        '>'
      );
      tl.to(STAMP, { x: -15, y: -20, duration: 0.1 }, '>');
      tl.to(STAMP, { x: 0, y: 0, scale: 1, duration: 0.1 }, '>');
    }

    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = this.getBaseTimeline(initialize);
    tl.add(this.moveStamp(initialize), '<');
    tl.add(this.moveStars(initialize), '<');
    this.tl = tl;
    return this.tl;
  };

  render() {
    return <Image className={C.BASE} {...this.props} />;
  }
}
