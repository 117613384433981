import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9001 9.71539L15.9946 9.88639L16.1678 9.79596L17.1036 9.30728L17.2841 9.21301L17.1865 9.03423C16.1531 7.13963 14.1724 5.96675 11.9994 5.96675C10.0831 5.96675 8.34416 6.85291 7.21396 8.3933L6.22765 8.07659L5.89946 7.97121L5.97084 8.30843L6.68826 11.698L6.75982 12.0361L7.01732 11.8056L9.59587 9.49736L9.85425 9.26607L9.52465 9.15826L8.68997 8.88523C9.5328 7.95199 10.7331 7.41688 11.9994 7.41688C13.6319 7.41688 15.114 8.29236 15.9001 9.71539Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.4"
    />
    <path
      d="M12.0002 17.8026C13.9175 17.8026 15.6572 16.9155 16.7874 15.3737L17.7714 15.6926L18.0992 15.7988L18.0289 15.4615L17.3219 12.072L17.251 11.7325L16.9927 11.9638L14.4141 14.272L14.1557 14.5033L14.4853 14.6111L15.3128 14.8817C14.4703 15.81 13.2733 16.3421 12.0106 16.3421C10.3781 16.3421 8.896 15.4666 8.10985 14.0436L8.01538 13.8726L7.84221 13.963L6.90644 14.4517L6.72714 14.5453L6.8228 14.7235C7.84585 16.6296 9.82719 17.8026 12.0002 17.8026Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.4"
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#6B6B6F',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;