import React, { useState } from 'react';
import {
  MenuContents,
  MenuItem,
  MenuTrigger
} from './styles';
import Tooltip from 'components/Tooltip';
import { border } from '@matterapp/matter-theme';

export default function DarkMenu(props) {
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);

  const { menuMap = [], className, TriggerComponent, position = 'left' } = props;
  
  const triggerProps = {
    className
  };

  const content = (
    <MenuContents>
      {menuMap.filter(Boolean).map(menuItem => {
        const { menuLabel, handleAction, rcLabel } = menuItem;

        return (
          <MenuItem 
            key={menuLabel} 
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              closeMenu();
              handleAction();
            }} 
            rcLabel={rcLabel} 
          >
            {menuLabel}
          </MenuItem>
        );
      })}
    </MenuContents>
  );

  return (
    <Tooltip
      arrowSize={8}
      position={position}
      style={{
        borderRadius: border.radius.M,
      }}
      clickOnly
      isOpen={isOpen}
      content={content}
      triggerProps={triggerProps}
      handlePopupOpen={setIsOpen}
      TriggerComponent={TriggerComponent || MenuTrigger}
      onClickOutside={closeMenu}
    />
  );
};

