import React from 'react';
import { Colors, Thumbnail, Paths } from '../Thumbnail';

const Meowow = (props) => {
  return (
    <Thumbnail color={Colors.Red} width={289} height={199} top={48} left={0} {...props}>
      <Paths.L40 d="M126.01 39.52C126.56 44.14 129.11 51.06 129.11 51.06C129.11 51.06 134.22 44.18 138.45 40.69C142.72 37.17 152.38 33.5099 152.38 33.5099C152.38 33.5099 144.11 29.2 139.42 27.66C136.92 26.84 133.57 26.3 130.9 25.97C128.43 25.66 126.24 27.45 126.05 29.94C125.82 32.85 125.66 36.64 126.01 39.52Z" />
      <Paths.L30
        evenOdd
        d="M143.34 25.19C148.55 27.01 155.71 30.91 157.91 32.14C163.2 30.71 168.82 29.92 174.66 29.92C181.03 29.92 187.16 30.86 192.87 32.55L192.83 32.52C192.83 32.52 201.84 27.37 208.08 25.19C212.61 23.61 218.95 22.47 223.21 21.81C225.76 21.42 228.08 23.22 228.31 25.8C228.7 30.15 229.1 36.7 228.52 41.5C227.95 46.24 225.95 52.6499 224.82 56.0099C228.57 62.1199 230.68 68.88 230.68 75.77C230.68 85.25 226.68 94.02 219.92 101.13C229.38 111.8 234.47 125.2 234.47 137.28C234.47 162.44 212.4 196.31 174.67 196.31C136.95 196.31 114.64 162.44 114.64 137.28C114.64 125.18 119.8 111.74 129.36 101.06C122.63 93.96 118.66 85.22 118.66 75.77C118.66 68.07 121.3 60.54 125.92 53.88C124.8 50.35 123.38 45.37 122.92 41.5C122.35 36.7 122.74 30.15 123.13 25.8C123.36 23.23 125.68 21.42 128.23 21.81C132.47 22.46 138.81 23.61 143.34 25.19Z"
      />
      <Paths.L10
        evenOdd
        d="M193.71 32.01C196.13 30.66 203.01 26.94 208.07 25.18C212.6 23.6 218.94 22.46 223.2 21.8C225.75 21.41 228.07 23.22 228.3 25.79C228.69 30.14 229.09 36.69 228.51 41.49C227.95 46.23 225.94 52.64 224.81 56C228.56 62.11 230.67 68.87 230.67 75.76C230.67 85.24 226.67 94.01 219.91 101.12C229.37 111.79 234.46 125.19 234.46 137.27C234.46 162.43 212.39 196.3 174.66 196.3C136.93 196.3 114.63 162.43 114.63 137.27C114.63 125.17 119.79 111.73 129.35 101.05C122.62 93.95 118.65 85.21 118.65 75.76C118.65 68.06 121.29 60.53 125.91 53.87C124.79 50.34 123.37 45.36 122.91 41.49C122.34 36.69 122.73 30.14 123.12 25.79C123.35 23.22 125.67 21.41 128.22 21.8C132.48 22.46 138.82 23.6 143.35 25.18C148.56 27 155.72 30.9 157.92 32.13C163.21 30.7 168.83 29.91 174.67 29.91C179.76 29.91 184.7 30.51 189.38 31.61C190.54 31.88 191.69 32.19 192.82 32.52C192.83 32.52 192.84 32.53 192.85 32.53C192.86 32.53 192.87 32.54 192.88 32.54L192.84 32.51C192.84 32.51 192.86 32.5 192.9 32.47C192.92 32.46 192.94 32.45 192.97 32.43C193.1 32.35 193.36 32.21 193.71 32.01ZM192.48 29.85C192.67 29.74 192.88 29.63 193.12 29.49C194.08 28.96 195.42 28.24 196.96 27.45C200.01 25.88 203.95 23.98 207.24 22.83C212 21.17 218.54 20 222.81 19.34C226.78 18.73 230.41 21.56 230.77 25.57C231.16 29.93 231.58 36.71 230.98 41.79C230.44 46.32 228.69 52.15 227.53 55.71C231.12 61.9 233.16 68.74 233.16 75.77C233.16 85.24 229.45 93.98 223.22 101.18C232.12 111.98 236.94 125.19 236.94 137.28C236.94 150.52 231.16 165.85 220.57 177.88C209.93 189.95 194.33 198.8 174.66 198.8C154.99 198.8 139.33 189.95 128.63 177.88C117.97 165.86 112.13 150.53 112.13 137.28C112.13 125.15 117.02 111.91 126.02 101.1C119.83 93.92 116.15 85.21 116.15 75.77C116.15 67.89 118.72 60.24 123.16 53.48C122.1 50.03 120.86 45.49 120.42 41.8C119.81 36.72 120.24 29.94 120.63 25.58C120.99 21.57 124.62 18.74 128.59 19.35C132.86 20.01 139.4 21.18 144.16 22.84C149.02 24.53 155.38 27.9 158.24 29.48C163.45 28.15 168.96 27.43 174.65 27.43C180.87 27.42 186.86 28.28 192.48 29.85Z"
      />
      <Paths.L50 d="M224.11 39.52C223.56 44.14 221.01 51.06 221.01 51.06C221.01 51.06 215.89 44.18 211.66 40.69C207.39 37.17 197.73 33.5099 197.73 33.5099C197.73 33.5099 206 29.2 210.69 27.66C213.19 26.84 216.54 26.3 219.21 25.97C221.68 25.66 223.87 27.45 224.06 29.94C224.3 32.85 224.46 36.64 224.11 39.52Z" />
      <Paths.L50 d="M126.99 40.15C127.54 44.77 130.09 51.69 130.09 51.69C130.09 51.69 135.2 44.81 139.43 41.32C143.7 37.8 153.36 34.1399 153.36 34.1399C153.36 34.1399 145.09 29.83 140.4 28.29C137.9 27.47 134.55 26.93 131.88 26.6C129.41 26.29 127.22 28.08 127.03 30.57C126.8 33.48 126.64 37.27 126.99 40.15Z" />
      <Paths.L60 d="M202.02 65.29C201.23 65.29 200.49 64.78 200.22 63.98C199.29 61.2 196.72 59.34 193.82 59.34C190.91 59.34 188.35 61.21 187.42 63.98C187.08 64.98 186.01 65.53 185.01 65.19C184.02 64.85 183.48 63.76 183.82 62.76C185.27 58.42 189.28 55.5 193.82 55.5C198.35 55.5 202.37 58.42 203.82 62.76C204.16 63.76 203.62 64.85 202.63 65.19C202.41 65.25 202.21 65.29 202.02 65.29Z" />
      <Paths.L60 d="M163.68 65.29C162.89 65.29 162.15 64.78 161.88 63.98C160.95 61.2 158.38 59.34 155.48 59.34C152.57 59.34 150.01 61.21 149.08 63.98C148.74 64.98 147.67 65.53 146.67 65.19C145.67 64.85 145.14 63.76 145.48 62.76C146.93 58.42 150.94 55.5 155.48 55.5C160.01 55.5 164.03 58.42 165.48 62.76C165.82 63.76 165.28 64.85 164.29 65.19C164.1 65.25 163.89 65.29 163.68 65.29Z" />
      <Paths.L60
        evenOdd
        d="M176.84 75.42C177.66 74.76 178.19 73.74 178.19 72.61C178.19 70.62 176.58 69.89 174.59 69.89C172.6 69.89 170.99 70.62 170.99 72.61C170.99 73.8 171.56 74.85 172.44 75.5V76.98H172.42C172.42 77.49 172.31 77.98 172.09 78.45C171.87 78.92 171.55 79.34 171.14 79.7C170.73 80.06 170.25 80.34 169.72 80.53C169.19 80.72 168.62 80.82 168.04 80.82C167.46 80.82 166.89 80.72 166.36 80.53C165.83 80.34 165.34 80.05 164.94 79.7C164.53 79.34 164.21 78.92 163.99 78.45C163.63 77.69 162.98 76.98 162.14 76.98H161.8C160.78 76.98 159.92 77.83 160.22 78.81C160.31 79.11 160.43 79.41 160.56 79.7C160.97 80.56 161.56 81.34 162.31 82C163.06 82.66 163.95 83.18 164.94 83.54C165.92 83.9 166.97 84.08 168.04 84.08C168.82 84.08 169.59 83.98 170.34 83.79C170.56 84.17 170.83 84.53 171.14 84.84C171.6 85.3 172.15 85.67 172.75 85.92C173.35 86.17 174 86.3 174.66 86.3C175.31 86.3 175.96 86.17 176.57 85.92C177.17 85.67 177.72 85.3 178.18 84.84C178.5 84.53 178.77 84.17 178.99 83.78C179.76 83.98 180.56 84.08 181.37 84.08C182.46 84.08 183.54 83.9 184.55 83.54C185.56 83.18 186.47 82.66 187.24 82C188.01 81.34 188.62 80.56 189.04 79.7C189.17 79.42 189.29 79.14 189.38 78.86C189.7 77.86 188.82 76.99 187.77 76.99H187.39C186.55 76.99 185.89 77.7 185.52 78.46C185.3 78.93 184.96 79.35 184.55 79.71C184.13 80.07 183.64 80.35 183.09 80.54C182.54 80.73 181.96 80.83 181.37 80.83C180.78 80.83 180.19 80.73 179.65 80.54C179.1 80.35 178.61 80.06 178.19 79.71C177.77 79.35 177.44 78.93 177.21 78.46C176.99 77.99 176.87 77.49 176.87 76.99H176.85V75.42H176.84ZM174.45 81.3H174.66H174.79C174.73 81.24 174.68 81.17 174.62 81.1C174.56 81.17 174.51 81.24 174.45 81.3Z"
      />
      <Paths.L60
        evenOdd
        d="M221.01 71.48C215.86 70.82 212.51 72.1 210.23 74.55C209.7 75.12 208.8 75.16 208.23 74.62C207.66 74.09 207.62 73.19 208.16 72.62C211.2 69.36 215.53 67.92 221.38 68.67C222.16 68.77 222.7 69.48 222.61 70.26C222.5 71.03 221.79 71.58 221.01 71.48Z"
      />
      <Paths.L60
        evenOdd
        d="M215.88 78.89C214.03 78.89 212.56 79.16 211.95 79.42C211.23 79.72 210.4 79.3901 210.09 78.6601C209.79 77.9401 210.12 77.11 210.85 76.8C211.97 76.33 213.88 76.05 215.88 76.05C217.92 76.05 220.29 76.34 222.37 77.17C223.1 77.46 223.45 78.29 223.16 79.01C222.87 79.74 222.04 80.09 221.32 79.8C219.68 79.16 217.7 78.89 215.88 78.89Z"
      />
      <Paths.L60
        evenOdd
        d="M129.38 71.48C134.53 70.82 137.88 72.1 140.16 74.55C140.69 75.12 141.59 75.16 142.16 74.62C142.73 74.09 142.77 73.19 142.23 72.62C139.19 69.36 134.86 67.92 129.01 68.67C128.23 68.77 127.68 69.48 127.78 70.26C127.9 71.03 128.61 71.58 129.38 71.48Z"
      />
      <Paths.L60
        evenOdd
        d="M134.51 78.85C136.36 78.85 137.83 79.12 138.44 79.38C139.16 79.68 139.99 79.35 140.3 78.62C140.6 77.9 140.27 77.07 139.54 76.76C138.42 76.29 136.51 76.01 134.51 76.01C132.47 76.01 130.1 76.3 128.02 77.13C127.29 77.42 126.94 78.25 127.23 78.97C127.52 79.7 128.35 80.05 129.07 79.76C130.71 79.11 132.7 78.85 134.51 78.85Z"
      />
      <Paths.L40
        evenOdd
        d="M216.89 102.77C211.07 107.16 194.36 110.33 174.66 110.33C154.96 110.33 138.25 107.16 132.43 102.77C131 103.85 130.23 105 130.23 106.2C130.23 112.27 150.12 117.19 174.66 117.19C199.2 117.19 219.1 112.27 219.1 106.2C219.09 105 218.32 103.85 216.89 102.77Z"
      />
      <Paths.L40 d="M187.01 122.62C187.01 128.79 182.01 133.79 175.84 133.79C169.67 133.79 164.67 128.79 164.67 122.62C164.67 116.45 169.67 111.45 175.84 111.45C182.01 111.46 187.01 116.45 187.01 122.62Z" />
      <Paths.L30 d="M164.03 126.11C163.14 126.11 162.42 125.39 162.52 124.51C162.66 123.32 162.97 122.16 163.43 121.05C164.09 119.44 165.07 117.99 166.3 116.76C167.53 115.53 168.99 114.56 170.59 113.89C172.2 113.23 173.92 112.88 175.66 112.88C177.4 112.88 179.12 113.22 180.73 113.89C182.34 114.55 183.79 115.53 185.02 116.76C186.25 117.99 187.22 119.45 187.89 121.05C188.35 122.16 188.65 123.33 188.8 124.51C188.91 125.39 188.18 126.11 187.29 126.11C186.4 126.11 185.7 125.39 185.56 124.51C185.44 123.74 185.22 122.99 184.92 122.27C184.42 121.05 183.68 119.95 182.75 119.02C181.82 118.09 180.71 117.35 179.5 116.85C178.28 116.35 176.98 116.09 175.66 116.09C174.34 116.09 173.04 116.35 171.82 116.85C170.6 117.35 169.5 118.09 168.57 119.02C167.64 119.95 166.9 121.06 166.4 122.27C166.1 122.99 165.89 123.74 165.76 124.51C165.62 125.39 164.92 126.11 164.03 126.11Z" />
      <Paths.L30
        evenOdd
        d="M173.09 124.04C173.09 123.26 173.73 122.62 174.51 122.62H178C178.78 122.62 179.42 123.26 179.42 124.04C179.42 124.82 178.78 125.46 178 125.46H177.56V133.73H174.72V125.46H174.51C173.73 125.46 173.09 124.83 173.09 124.04Z"
      />
      <Paths.L30
        evenOdd
        d="M255.51 18.97C255.51 22.13 254.49 24.92 252.94 26.6C252.59 28.03 251.8 29.36 250.67 30.53H250.72L252.47 99.81C252.86 111.13 243.78 120.52 232.45 120.52C226.74 120.52 222.19 115.76 222.45 110.06L225.18 30.92C223.95 29.77 223.06 28.43 222.62 26.99C220.84 25.38 219.64 22.39 219.64 18.96C219.64 13.84 222.31 9.67996 225.61 9.67996C225.67 9.67996 225.73 9.67996 225.78 9.67996C226.49 5.54996 229.26 2.46997 232.58 2.46997C234.65 2.46997 236.5 3.66998 237.78 5.56998C239.06 3.66998 240.91 2.46997 242.98 2.46997C246.3 2.46997 249.08 5.54997 249.78 9.68997C252.96 9.89997 255.51 13.97 255.51 18.97Z"
      />
      <Paths.L10
        evenOdd
        d="M252.47 99.81C252.86 111.13 243.78 120.52 232.45 120.52C226.74 120.52 222.19 115.76 222.44 110.06L225.17 30.93C223.94 29.78 223.05 28.44 222.61 27C220.83 25.39 219.63 22.4 219.63 18.97C219.63 13.85 222.3 9.69 225.6 9.69C225.66 9.69 225.72 9.69 225.77 9.69C226.47 5.56 229.25 2.47998 232.57 2.47998C233.83 2.47998 235.02 2.92999 236.04 3.70999C236.69 4.20999 237.27 4.83999 237.77 5.57999C238.27 4.83999 238.85 4.20999 239.5 3.70999C240.52 2.92999 241.71 2.47998 242.97 2.47998C246.29 2.47998 249.07 5.55998 249.77 9.69998C252.95 9.89998 255.5 13.97 255.5 18.97C255.5 22.13 254.48 24.92 252.93 26.6C252.81 27.09 252.64 27.57 252.42 28.04C252.01 28.91 251.43 29.73 250.71 30.48C250.69 30.5 250.68 30.51 250.66 30.53H250.71L252.47 99.81ZM253.24 31.38L254.96 99.73C255.39 112.46 245.19 123.02 232.45 123.02C225.32 123.02 219.64 117.07 219.96 109.96L222.64 31.9C221.71 30.86 220.95 29.67 220.46 28.38C218.33 26.13 217.16 22.63 217.16 18.98C217.16 16.01 217.93 13.21 219.3 11.07C220.34 9.45001 221.86 8.04998 223.77 7.47998C225.05 3.43998 228.21 0 232.59 0C234.58 0 236.35 0.740004 237.79 1.91C239.23 0.740004 241 0 242.99 0C247.42 0 250.61 3.52 251.85 7.63C253.6 8.3 255 9.66998 255.96 11.23C257.27 13.34 258 16.08 258 18.98C258 22.36 257 25.58 255.19 27.82C254.77 29.11 254.1 30.31 253.24 31.38Z"
      />
      <Paths.L50 d="M252.49 17.9001C252.49 20.1501 251.06 21.98 249.3 21.98C247.54 21.98 246.11 20.1501 246.11 17.9001C246.11 15.6501 247.54 13.8201 249.3 13.8201C251.06 13.8201 252.49 15.6501 252.49 17.9001Z" />
      <Paths.L50 d="M246.11 10.64C246.11 12.89 244.68 14.7201 242.92 14.7201C241.16 14.7201 239.73 12.89 239.73 10.64C239.73 8.39005 241.16 6.56006 242.92 6.56006C244.68 6.56006 246.11 8.38005 246.11 10.64Z" />
      <Paths.L50 d="M235.3 10.64C235.3 12.89 233.87 14.7201 232.11 14.7201C230.35 14.7201 228.92 12.89 228.92 10.64C228.92 8.39005 230.35 6.56006 232.11 6.56006C233.87 6.56006 235.3 8.38005 235.3 10.64Z" />
      <Paths.L50 d="M228.31 17.9001C228.31 20.1501 226.88 21.98 225.12 21.98C223.36 21.98 221.93 20.1501 221.93 17.9001C221.93 15.6501 223.36 13.8201 225.12 13.8201C226.88 13.8301 228.31 15.6501 228.31 17.9001Z" />
      <Paths.L50 d="M230.13 23.55C230.91 21.13 233.16 19.5 235.7 19.5H239.2C241.74 19.5 243.98 21.14 244.74 23.56C245.72 26.65 243.97 29.95 240.86 30.88L239.31 31.34C237.96 31.75 236.51 31.74 235.16 31.32L233.95 30.94C230.85 29.98 229.12 26.66 230.13 23.55Z" />
      <Paths.L30 d="M56.2902 6.57996L60.0302 10.79L65.6902 11.0099L62.8002 15.84L64.3401 21.24L58.8201 20.02L54.1202 23.14L53.5801 17.56L49.1401 14.09L54.3301 11.86L56.2902 6.57996Z" />
      <Paths.L30 d="M21.6802 66.94L25.4302 66.06L27.4501 62.36L29.3401 66.15L33.0602 67.2L30.4802 70.42L30.7502 74.76L27.2601 72.96L23.7202 74.61L24.1401 70.27L21.6802 66.94Z" />
      <Paths.L30 d="M272.41 82.79L278.25 84.23L283.28 80.77L283.78 87.07L288.45 91.04L282.92 93.49L280.78 99.39L276.86 94.61L270.87 94.3L273.97 88.9L272.41 82.79Z" />
      <Paths.L30 d="M95.1902 63.6799L98.2302 66.3999L102.23 65.9799L100.68 69.8299L102.29 73.6499L98.2902 73.3099L95.2902 76.0899L94.3702 72.0299L90.9102 69.9199L94.3401 67.7599L95.1902 63.6799Z" />
      <Paths.L30 d="M96.4 22.2299C99.55 22.2299 102.09 19.68 102.09 16.54C102.09 13.4 99.55 10.85 96.4 10.85C93.26 10.85 90.71 13.4 90.71 16.54C90.71 19.68 93.26 22.2299 96.4 22.2299Z" />
      <Paths.L30 d="M87.3301 45.7599C90.1801 45.7599 92.5002 43.4499 92.5002 40.5899C92.5002 37.7299 90.1901 35.4199 87.3301 35.4199C84.4701 35.4199 82.1602 37.7299 82.1602 40.5899C82.1602 43.4499 84.4701 45.7599 87.3301 45.7599Z" />
      <Paths.L30 d="M64.55 78.91C58.85 78.91 54.21 74.27 54.21 68.57C54.21 62.87 58.86 58.23 64.55 58.23C70.25 58.23 74.89 62.87 74.89 68.57C74.9 74.27 70.26 78.91 64.55 78.91ZM64.55 63.01C61.49 63.01 58.99 65.5 58.99 68.57C58.99 71.63 61.48 74.13 64.55 74.13C67.62 74.13 70.11 71.64 70.11 68.57C70.12 65.5 67.62 63.01 64.55 63.01Z" />
      <Paths.L30 d="M168.59 21.17C162.89 21.17 158.25 16.53 158.25 10.83C158.25 5.12999 162.9 0.48999 168.59 0.48999C174.29 0.48999 178.93 5.12999 178.93 10.83C178.94 16.53 174.29 21.17 168.59 21.17ZM168.59 5.26999C165.53 5.26999 163.03 7.75999 163.03 10.83C163.03 13.89 165.52 16.39 168.59 16.39C171.66 16.39 174.15 13.9 174.15 10.83C174.15 7.75999 171.65 5.26999 168.59 5.26999Z" />
      <Paths.L30 d="M276.07 62.03C270.37 62.03 265.73 57.39 265.73 51.69C265.73 45.99 270.38 41.35 276.07 41.35C281.77 41.35 286.41 45.99 286.41 51.69C286.42 57.39 281.78 62.03 276.07 62.03ZM276.07 46.13C273.01 46.13 270.51 48.62 270.51 51.69C270.51 54.75 273 57.25 276.07 57.25C279.14 57.25 281.63 54.76 281.63 51.69C281.64 48.63 279.14 46.13 276.07 46.13Z" />
      <Paths.L30 d="M14.1701 40.19C8.47011 40.19 3.83008 35.55 3.83008 29.85C3.83008 24.15 8.47011 19.51 14.1701 19.51C19.8701 19.51 24.5101 24.15 24.5101 29.85C24.5201 35.55 19.8801 40.19 14.1701 40.19ZM14.1701 24.29C11.1101 24.29 8.61011 26.78 8.61011 29.85C8.61011 32.91 11.1001 35.41 14.1701 35.41C17.2401 35.41 19.7301 32.92 19.7301 29.85C19.7401 26.78 17.2401 24.29 14.1701 24.29Z" />
      <Paths.L30
        evenOdd
        d="M81.8802 104.64C81.8802 105.92 82.9102 106.96 84.1902 106.96C85.4702 106.96 86.5002 105.92 86.5002 104.65V99.95H91.1702C92.4502 99.95 93.4802 98.91 93.4802 97.63C93.4802 96.35 92.4502 95.31 91.1702 95.31H86.5002V90.61C86.5002 89.33 85.4702 88.29 84.1902 88.29C82.9102 88.29 81.8802 89.33 81.8802 90.61V95.31H77.2001C75.9201 95.31 74.8901 96.35 74.8901 97.63C74.8901 98.91 75.9201 99.95 77.2001 99.95H81.8802V104.64Z"
      />
      <Paths.L30
        evenOdd
        d="M6.98999 96.3C6.98999 97.58 8.01999 98.62 9.29999 98.62C10.58 98.62 11.61 97.58 11.61 96.31V91.61H16.28C17.56 91.61 18.59 90.57 18.59 89.29C18.59 88.01 17.56 86.97 16.28 86.97H11.61V82.27C11.61 80.99 10.58 79.95 9.29999 79.95C8.01999 79.95 6.98999 80.99 6.98999 82.27V86.97H2.31C1.03 86.97 0 88.01 0 89.29C0 90.57 1.03 91.61 2.31 91.61H6.98999V96.3Z"
      />
      <Paths.L30 d="M41.17 48.85C44.03 48.85 46.34 46.54 46.34 43.68C46.34 40.82 44.03 38.51 41.17 38.51C38.32 38.51 36 40.82 36 43.68C35.99 46.53 38.31 48.85 41.17 48.85Z" />
      <Paths.L30 d="M43.9698 98.62C46.8298 98.62 49.1398 96.31 49.1398 93.45C49.1398 90.59 46.8298 88.28 43.9698 88.28C41.1198 88.28 38.7998 90.59 38.7998 93.45C38.7998 96.3 41.1198 98.62 43.9698 98.62Z" />
    </Thumbnail>
  );
};

export default Meowow;
