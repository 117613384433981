import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { ButtonNew as Button } from '../../../Button';
import { assetMap } from './asset-map';
import { CTA_BUTTON_CLASS_NAME } from './consts';
import { IconKarotRight } from '../../../Icon';

const IconCTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding: 16px 24px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid ${() => theme.colors.blues[10]};

  transition:
    color 0.1s ease-in-out,
    background 0.1s ease-in-out,
    border-color 0.1s ease-in-out;

  &:hover {
    .matter-iconcta-title {
      color: ${() => theme.colors.blues[60]};
    }

    svg {
      opacity: 1;
      right: 24px;
    }
  }

  :active {
    .matter-iconcta-title {
      color: ${() => theme.colors.blues[60]};
    }

    background ${() => theme.colors.blues[5]};
    border-color ${() => theme.colors.blues[60]};
  }

  ${() => theme.media.medium`
    padding: 24px;
  `}
`;

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: left;
  text-align: left;
`;

const TitleContainer = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  font-size: 17px;
  color: ${() => theme.colors.blacks[90]};
`;

const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: ${() => theme.colors.blacks[80]};
`;

const ForwardButtonContainer = styled.div`
  margin-left: 16px;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  svg {
    opacity: 0;
    position: absolute;
    right: 48px;
    transition: all 0.1s;
  }
`;

class IconCTA extends React.PureComponent {
  onClick = (e) => {
    const { onClick } = this.props;
    e.preventDefault();
    e.stopPropagation();
    return onClick(e);
  };

  renderIcon = () => {
    const { iconName } = this.props;
    const iconSrc = assetMap[iconName];

    return (
      <IconContainer>
        <img src={iconSrc} alt={iconName} />
      </IconContainer>
    );
  };

  renderText = () => {
    const { title, description } = this.props;
    return (
      <TextContainer>
        <TitleContainer>
          <Title className="matter-iconcta-title">{title}</Title>
        </TitleContainer>
        <Description>{description}</Description>
      </TextContainer>
    );
  };

  renderForwardButton = () => (
    <ForwardButtonContainer>
      <Button onClick={this.onClick} icon="karotRight" link />
    </ForwardButtonContainer>
  );

  renderActionContainer = () => (
    <ActionContainer>
      <IconKarotRight />
    </ActionContainer>
  );

  render = () => {
    const { rcLabel } = this.props;
    return (
      <IconCTAContainer onClick={this.onClick} className={CTA_BUTTON_CLASS_NAME} data-rc={rcLabel}>
        {this.renderIcon()}
        {this.renderText()}
        {this.renderActionContainer()}
      </IconCTAContainer>
    );};
}

IconCTA.assetMap = assetMap;
IconCTA.rootClassName = CTA_BUTTON_CLASS_NAME;

IconCTA.propTypes = {
  iconName: PropTypes.oneOf(Object.keys(assetMap)).isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default IconCTA;
