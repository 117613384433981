import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../Container';
import { ButtonNew } from '../../../Button';

const ActionContainer = styled(Container)`
  line-height: 32px;
  font-size: 17px;
`;

class Action extends Component {
  renderButton = () => {
    const {
      actionLabel,
      onActionClick,
      iconName,
    } = this.props;
    return (
      <ButtonNew
        link
        onClick={onActionClick}
        icon={iconName}
      >
        {actionLabel}
      </ButtonNew>
    );
  }

  render = () => {
    const { centered, className, extraPadBottom } = this.props;
    return (
      <ActionContainer
        centered={centered}
        className={className}
        extraPadBottom={extraPadBottom}
      >
        {this.renderButton()}
      </ActionContainer>
    );
  }
}

Action.defaultProps = {
  centered: true,
  extraPadBottom: false,
  iconName: null,
};

Action.propTypes = {
  className: PropTypes.string,
  extraPadBottom: PropTypes.bool,
  iconName: PropTypes.string,
  actionLabel: PropTypes.string.isRequired,
  onActionClick: PropTypes.func.isRequired,
};

export default Action;
