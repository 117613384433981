import * as t from './actionTypes';

const initialState = {
  feedbackTemplate: {},
  isCustomSurvey: false,
  isSending: false,
  isKudosSentModalOpen: false,
  isRequestFeedbackModalOpen: false,
  isGiveFeedbackModalOpen: false,
  isSendKudosModalOpen: false,
  message: '',
  modalStep: {},
  selectedKudos: null,
  selectedPeers: [],
  selectedSkillsIds: [],
  selectedSkill: null,
  selectedPeer: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case t.ADD_SKILL:
    return {
      ...state,
      selectedSkillsIds: [...state.selectedSkillsIds, action.skillId],
    };
  case t.REMOVE_SKILL:
    return {
      ...state,
      selectedSkillsIds: state.selectedSkillsIds.filter(
        (skillId) => skillId !== action.skillId
      ),
    };
  case t.INIT_USER_SKILLS_LIST:
    const { userSkills } = action;
    return { ...state, selectedSkillsIds: userSkills.map(userSkill => userSkill.id), userSkillsLength: userSkills.length};
  case t.UPDATE_SELECTED_SKILLS:
    const { skill } = action;
    let skillIdIndex, newIds;
    if (skill) {
      skillIdIndex = state.selectedSkillsIds.indexOf(skill.id);
    }
    if (skillIdIndex === -1) {
      newIds = [...state.selectedSkillsIds, skill.id];
    } else {
      if (state.selectedSkillsIds.length === 1) { return state; }
      newIds = [
        ...state.selectedSkillsIds.slice(0, skillIdIndex),
        ...state.selectedSkillsIds.slice(skillIdIndex + 1)
      ];    
    }
    const isCustomSurvey = state.userSkillsLength !== newIds.length;
    return { ...state, selectedSkillsIds: newIds, isCustomSurvey };
  case t.CHANGE_SELECTED_SKILL:
    return { ...state, selectedSkill: action.selectedSkill };
  case t.RESET_SELECTED_SKILL:
    return { ...state, selectedSkill: null };
  case t.CHANGE_MESSAGE:
    return { ...state, message: action.message };
  case t.CHANGE_SELECTED_KUDOS:
    return { ...state, selectedKudos: action.selectedKudos };
  case t.RESET_SELECTED_KUDOS:
    return { ...state, selectedKudos: null };
  case t.CHANGE_SELECTED_PEERS:
    return { ...state, selectedPeers: action.selectedPeers };
  case t.CHANGE_SELECTED_PEER:
    return {
      ...state,
      selectedPeer: action.selectedPeer,
      selectedSkill: null,
    };
  case t.CLOSE_KUDOS_SENT_MODAL:
    return { ...state, isKudosSentModalOpen: false };
  case t.CLOSE_REQUEST_FEEDBACK_MODAL:
    return { ...state, isRequestFeedbackModalOpen: false };
  case t.CLOSE_SEND_KUDOS_MODAL:
    return { ...state, isSendKudosModalOpen: false };
  case t.CLOSE_GIVE_FEEDBACK_MODAL:
    return { ...state, isGiveFeedbackModalOpen: false };
  case t.INITIALIZE:
    return {
      ...state,
      message: action.message,
      selectedPeers: [],
      selectedSkillsIds: [],
      selectedPeer: {},
      selectedSkill: null,
      userSkills: [],
    };
  case t.OPEN_KUDOS_SENT_MODAL:
    return { ...state, isKudosSentModalOpen: true };
  case t.OPEN_REQUEST_FEEDBACK_MODAL:
    return { ...state, isRequestFeedbackModalOpen: true };
  case t.OPEN_SEND_KUDOS_MODAL:
    return { ...state, isSendKudosModalOpen: true };
  case t.OPEN_GIVE_FEEDBACK_MODAL:
    return { ...state, isGiveFeedbackModalOpen: true };
  case t.SET_CUSTOM_SURVEY:
    return {
      ...state,
      isCustomSurvey: action.isCustomSurvey,
    };
  case t.SET_FEEDBACK_TEMPLATE:
    return {
      ...state,
      feedbackTemplate: {
        id: action.id,
        invitationUrl: action.invitationUrl,
      },
    };
  case t.SET_IS_SENDING:
    return { ...state, isSending: action.isSending };
  default:
    return state;
  }
};

export default reducer;
