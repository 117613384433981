import { compose, withProps } from 'recompose';
import { Resources } from '@matterapp/routing';
import withRouter from 'hocs/withRouter';
import { withPeerRecommendations } from '../../../hocs';
import PeerRecommendationsPreview from '../components/PeerRecommendationsPreview';

const withData = compose(
  withRouter,
  withPeerRecommendations({
    peerRecommendationFilterFn: (peer) => peer.isFeedbackRecommendation,
  }),
  withProps(({ history }) => ({
    viewAllLinkTo: Resources.socialPeerSuggestions.path(),
    handleClickViewAll: () => {
      history.push(Resources.socialPeerSuggestions.path());
    },
  }))
);

const PeerRecommendationsPreviewContainer = withData(
  PeerRecommendationsPreview
);

export default PeerRecommendationsPreviewContainer;
