import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  SkillColorPalette,
  skillColorPalettePropType,
} from '@matterapp/skills';
import ColorButton from './ColorButton';
import { COLOR_BUTTON_CLASSNAME } from './consts';

const ButtonsListContainer = styled.div``;

const LabelContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-size: 17px;
  margin-bottom: 8px;
  padding-left: 16px;
`;

const ListItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  .${COLOR_BUTTON_CLASSNAME} + .${COLOR_BUTTON_CLASSNAME} {
    margin-left: 8px;
  }
  height: 55px;

  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const skillColorPaletteNames = Object.keys(SkillColorPalette);

class ButtonsList extends React.PureComponent {
  renderListItems = () => {
    const { onSelectColor, selectedColorPalette } = this.props;
    return (
      <ListItemsContainer>
        {skillColorPaletteNames.map((colorPaletteName) => (
          <ColorButton
            key={colorPaletteName}
            colorPalette={colorPaletteName}
            isSelected={colorPaletteName === selectedColorPalette}
            onClick={() => {
              onSelectColor(colorPaletteName);
            }}
          />
        ))}
      </ListItemsContainer>
    );
  };

  render = () => (
    <ButtonsListContainer>
      <LabelContainer>{this.props.label}</LabelContainer>
      {this.renderListItems()}
    </ButtonsListContainer>
  );
}

ButtonsList.Button = ColorButton;

ButtonsList.defaultProps = {
  selectedColorPalette: null,
};

ButtonsList.propTypes = {
  selectedColorPalette: skillColorPalettePropType,
  onSelectColor: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default ButtonsList;
