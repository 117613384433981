import styled from 'styled-components';
import { Button, ClickableLink, Input, Modal } from '@matterapp/matter-ui';
import theme, { media } from '@matterapp/matter-theme';
import { Paper, Box } from '@mui/material';

export const ResetCoinsButton = styled(Button.Primary).attrs(() => ({
  color: Button.buttonColors.RED,
  size: Button.buttonSizes.S,
}))(() => ({
  marginTop: theme.spacing.single,
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(4)
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  width: '200px',
  marginLeft: theme.spacing(3)
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2)
}));

export const SecondaryButton = styled(Button)`
display: flex;
align-items: center;
height: 48px !important;
margin-top: 16px;
${media.S`
  margin-top: 0;
`}
`;

export const ThresholdBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(1)
}));

export const InputWrapper = styled(Box)(() => ({
  position: 'relative',
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    width: '100%'
  }
}));

export const ResetButton = styled(ClickableLink)(({ theme }) => ({
  position: 'absolute',
  top: `-${theme.spacing(4.5)}`,
  right: 0,
  zIndex: 1,
  '&:not(:hover):not(:active)': {
    color: theme.palette.blacks[50]
  }
}));

export const RewardsSettingsFooter = styled(Modal.Footer.WithActionButtons)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  zIndex: 1,
  padding: theme.spacing(3),
  borderTop: 'none',
  borderRadius: '8px'
}));
