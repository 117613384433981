import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../components';

const FormGroupHeader = ({ title, rightChildren }) => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
    <div>
      <Text color="black" textStyle={410} bold>
        {title}
      </Text>
    </div>
    <div style={{ marginLeft: 'auto' }}>{rightChildren}</div>
  </div>
);

FormGroupHeader.defaultProps = {
  title: '',
  rightChildren: null,
};

FormGroupHeader.propTypes = {
  title: PropTypes.string,
  rightChildren: PropTypes.node,
};

export default FormGroupHeader;
