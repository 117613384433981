import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CheckmarkToggle } from '../../Checkbox';
import List from '../List';
import theme from '@matterapp/matter-theme';

const CheckmarkContainer = styled.div`
  pointer-events: none;

  ${({ isSelected }) => {
    if (!isSelected) {
      return `
        [data-type='list-item']:hover & label[data-type="label"]:before {
          background: ${theme.colors.white};
        }
      `;
    }
  }}
`;

export default class SelectionList extends React.PureComponent {
  static propTypes = {
    /** Additional style class. */
    className: PropTypes.string,
    /** Link to find your peers page. Displays button if passed in. */
    findYourPeersLink: PropTypes.string,
    /** Callback when value changes. */
    onChange: PropTypes.func.isRequired,
    /** Callback when find you peers button is clicked. */
    onClickFindYourPeers: PropTypes.func,
    /**
     * List of peers to show in the list.
     */
    peers: PropTypes.arrayOf(
      PropTypes.shape({
        /** The email of the peer. */
        email: PropTypes.string.isRequired,
        /** The full name of the peer. */
        fullName: PropTypes.string,
        /** The url of the photo of the peer. */
        photoUrl: PropTypes.string,
      })
    ).isRequired,
    /** List of selected peer email values. */
    value: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    onChange: () => {},
    value: [],
  };

  /**
   * Determines if the given email is selected.
   * @param { String } email: Email to check if selected.
   * @returns { Boolean } Email is selected.
   */
  isEmailSelected = (email) => this.props.value.includes(email);

  /**
   * Callback when a peer is clicked.
   * @param { Object } e: The click event.
   * @param { Object } peerProps: The props of the clicked row containing the peer.
   * @returns {void }
   */
  handleClickPeer = (e, { peer }) => {
    const { onChange, value } = this.props;
    if (this.isEmailSelected(peer.email)) {
      onChange(e, {
        ...this.props,
        value: value.filter((email) => email !== peer.email),
      });
    } else {
      onChange(e, { ...this.props, value: [...value, peer.email] });
    }
  };

  renderPeerActions = ({ email }) => {
    const { value } = this.props;
    const isSelected = value.includes(email);
    return (
      <CheckmarkContainer isSelected={isSelected}>
        <CheckmarkToggle checked={isSelected} />
      </CheckmarkContainer>
    );
  };

  render() {
    const {
      className,
      findYourPeersLink,
      onClickFindYourPeers,
      peers,
      ...otherProps
    } = this.props;
    return (
      <List
        className={className}
        findYourPeersLink={findYourPeersLink}
        onClickFindYourPeers={onClickFindYourPeers}
        onClickPeer={this.handleClickPeer}
        peers={peers}
        renderPeerActions={this.renderPeerActions}
        {...otherProps}
      />
    );
  }
}
