import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, fill, size }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2071 0.292893C10.5976 0.683417 10.5976 1.31658 10.2071 1.70711L4.70711 7.20711C4.31658 7.59763 3.68342 7.59763 3.29289 7.20711L0.292893 4.20711C-0.0976311 3.81658 -0.0976311 3.18342 0.292893 2.79289C0.683417 2.40237 1.31658 2.40237 1.70711 2.79289L4 5.08579L8.79289 0.292893C9.18342 -0.0976311 9.81658 -0.0976311 10.2071 0.292893Z"
      transform="translate(3 4.5)"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
  size: 16,
};

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default Icon;
