import styled, { css } from 'styled-components';
import Adapter from './components/Adapter';
import theme from '@matterapp/matter-theme';
import {
  CONTENT_CLASS,
  OVERLAY_CLASS,
  OVERLAY_THEMES,
  SIZES,
  TRANSITIONS,
} from './consts';

const BORDER_RADIUS = theme.borderRadius.M;
const MODAL_TOP = '10vh';
const MODAL_TOP_LOWER = '23vh';
const OVERLAY_COLORS = {
  [OVERLAY_THEMES.DEFAULT]: 'rgba(46, 46, 51, 0.32)',
  [OVERLAY_THEMES.LIGHT]: 'rgba(246, 246, 246, 0.9)',
  [OVERLAY_THEMES.PURPLE]: 'rgba(52,55,153,.95)',
};
const TRANSITION_TIME = theme.transitions.times.singleAndHalf;
const WIDTHS = {
  [SIZES.MAX]: '1000px',
  [SIZES.XL]: '600px',
  [SIZES.L]: '580px',
  [SIZES.M]: '500px',
  [SIZES.S]: '480px',
};

const contentClassName = CONTENT_CLASS;
const overlayClassName = OVERLAY_CLASS;

/*****************************************************************************/
/* MODAL TRANSITIONS                                                         */
/*****************************************************************************/
const getDefaultModalContentStyles = ({ isAfterOpen, openLowerFromTop }) => {
  if (isAfterOpen) {
    return `top: ${openLowerFromTop ? MODAL_TOP_LOWER : MODAL_TOP};`;
  }
  return '';
};

const getDefaultModalContentStylesMobile = ({ isAfterOpen }) => {
  return `
    top: ${isAfterOpen ? 0 : '-5vh'};
  `;
};

const MODAL_TRANSITIONS = {
  [TRANSITIONS.FADE]: ({ isAfterOpen, openLowerFromTop }) => css`
    .ReactModal__Content {  
      ${getDefaultModalContentStylesMobile({ isAfterOpen, openLowerFromTop })}
      transition: opacity ${TRANSITION_TIME};
      ${theme.media.S`
        ${getDefaultModalContentStyles({ isAfterOpen, openLowerFromTop })}
      `}
    }
    .ReactModal__Content--before-close {
      opacity: 0;
    }
  `,
  [TRANSITIONS.DROP]: ({ isAfterOpen, openLowerFromTop }) => css`
    .ReactModal__Content {
      ${getDefaultModalContentStylesMobile({ isAfterOpen, openLowerFromTop })}
      transition: top ${TRANSITION_TIME} ease-out, opacity ${TRANSITION_TIME};
      ${theme.media.S`
        ${getDefaultModalContentStyles({ isAfterOpen, openLowerFromTop })}
      `}
    }
    .ReactModal__Content--before-close {
      top: -5vh !important;
      opacity: 0.5;

      ${theme.media.S`
        top: ${openLowerFromTop ? '5vh' : '0'} !important;
      `}
    }
  `,
  [TRANSITIONS.FADE_DOWN]: ({ isAfterOpen, openLowerFromTop }) => css`
    .ReactModal__Content {
      top: ${isAfterOpen ? 0 : '-1vh'};
      transition: top ${TRANSITION_TIME} ease-out; 

      ${theme.media.S`
        top: ${openLowerFromTop ? '18vh' : '8vh'};
        ${isAfterOpen ? `top: ${openLowerFromTop ? MODAL_TOP_LOWER : MODAL_TOP};` : ''};
      `}
    }
    .ReactModal__Content--before-close {
      top: -1vh !important;

      ${theme.media.S`
        top: ${openLowerFromTop ? '18vh' : '8vh'} !important;
      `}
    }
  `,
  [TRANSITIONS.ZOOM_IN]: ({ isAfterOpen, openLowerFromTop }) => css`
    .ReactModal__Content {
      top: 0;
      transform: scale(${isAfterOpen ? 1 : 0.8});
      transition: transform ${TRANSITION_TIME};
      ${theme.media.S`
        top: ${openLowerFromTop ? MODAL_TOP_LOWER : MODAL_TOP}
      `}
    }
    .ReactModal__Content--before-close {
      top: ${MODAL_TOP};
    }
  `,
};

/*****************************************************************************/
/* HELPER STYLE FUNCTIONS                                                    */
/*****************************************************************************/
export const getOverlayColor = ({ overlayTheme }) => {
  return OVERLAY_COLORS[overlayTheme] || OVERLAY_COLORS[OVERLAY_THEMES.DEFAULT];
};

export const getOverlayFilter = ({ blurOverlay }) => {
  if (blurOverlay) {
    return 'backdrop-filter: blur(2px)';
  }
  return '';
};

export const getModalWidth = (size, customWidth) => {
  if (size === SIZES.CUSTOM && customWidth) {
    return customWidth;
  }
  return WIDTHS[SIZES[size]] || WIDTHS[SIZES.M];
};

export const getModalTransition = ({ transition, ...otherProps }) => {
  const transitionFunc = MODAL_TRANSITIONS[transition] || MODAL_TRANSITIONS[TRANSITIONS.DROP];
  if (typeof transitionFunc === 'function') {
    return transitionFunc(otherProps);
  }
  return '';
};

/*****************************************************************************/
/* STYLED MODAL                                                              */
/*****************************************************************************/
export const StyledModal = styled(Adapter).attrs(
  ({ onRequestClose, onClose }) => ({
    contentClassName,
    overlayClassName,
    onClose: onRequestClose ? onRequestClose : onClose,
  })
)`
  .${overlayClassName} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${theme.zIndex.modalOverlay};
    background: ${getOverlayColor};
    overflow: auto;
    ${getOverlayFilter}
  }

  .${contentClassName} {
    ${({ fullScreenMobile }) => `
      background: ${theme.colors.white};
      border-radius: ${fullScreenMobile ? 0 : BORDER_RADIUS};
      display: flex;
      flex-direction: column;
      height: ${fullScreenMobile ? '100%' : 'auto'};
      margin: ${fullScreenMobile ? 0 : theme.spacing.singleAndQuarter};
      position: relative;
      max-height: auto;
      width: auto;
      z-index: 100;
    `}

    ${({ size, customWidth, overflow }) => theme.media.S`
      border-radius: ${BORDER_RADIUS};
      height: auto;
      margin: auto;
      max-width: ${getModalWidth(size, customWidth)};
      width: ${ customWidth || `calc(100% - ${theme.spacing.doubleAndHalf})`};
      overflow: ${overflow || 'hidden'};
    `}
  }

  ${getModalTransition}

  .ReactModal__Overlay {
    opacity: ${({ isAfterOpen }) => isAfterOpen ? '1' : '0'};
    pointer-events: ${({ isAfterOpen }) => isAfterOpen ? 'all' : 'none'};
    transition: opacity 0.15s linear;
    ${({ isAfterOpen }) => isAfterOpen ? '1' : '0'}
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;
