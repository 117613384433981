import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import omitStyled from '../../libs/omitStyled';
import {
  getTextAreaStyles,
  getTextAreaContainerStyles,
  getMediumScreenInputStyles,
  getChildrenContainerStyles,
  InnerWrapper,
  LayoutContainer,
  Wrapper,
  TEXT_AREA_CONTAINER_ROLE,
  VARIANTS,
} from './styles';
import theme from '@matterapp/matter-theme';

const TextAreaContainer = omitStyled('div', [
  'hasChildren',
  'isError',
  'variant',
])`
  display: flex;
  flex-direction: column;
  position: relative;
  ${getTextAreaContainerStyles}
`;

const StyledTextarea = omitStyled('textarea', [
  'autoHeight',
  'hasChildren',
  'isError',
  'showBottomBorder',
  'variant',
  'showBottomBorder',
])`
  ${getTextAreaStyles}
  ${getMediumScreenInputStyles}
`;

const StyledTextareaShadow = styled.div`
  ${getTextAreaStyles}
  ${getMediumScreenInputStyles}
  white-space: pre-line;
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  top: 0;
  position: absolute;
  word-break: break-word;
  z-index: -1;
`;

const TextareaShadowContainer = styled.div.attrs(() => ({
  ['data-role']: TEXT_AREA_CONTAINER_ROLE,
}))`
  position: relative;
`;

const StyledInput = omitStyled('input', [
  'hasChildren',
  'isError',
  'variant',
]).attrs({
  type: 'text',
})`
  ${getTextAreaStyles}
  ${getMediumScreenInputStyles}
`;

const LabelTextContainer = styled.div`
  background: transparent;
  margin-left: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: ${() => theme.spacing.half};
  color: ${({ error }) =>
    error ? theme.colors.reds[60] : theme.colors.black};

  &:empty {
    margin-bottom: 0;
  }
`;

const ChildrenContainer = styled.div`
  ${getChildrenContainerStyles}
`;

export default class TextArea extends React.PureComponent {
  static propTypes = {
    /** Automatically sets the height of the textarea. */
    autoHeight: PropTypes.bool,
    /** Sets the height of the text area on mount. Use if an initial value is passed. */
    autoHeightOnMount: PropTypes.bool,
    /** Force to allow error styling without explicit initial blur **/
    allowErrorBeforeBlur: PropTypes.bool,
    /** Additional class. */
    className: PropTypes.string,
    /** If text area is disabled. */
    disabled: PropTypes.bool,
    /** If the TextArea has an error. PropTypes.string is DEPRICATED. Use boolean. */
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    /** Display as a single-lined input. */
    isInput: PropTypes.bool,
    /** Label of the TextArea. */
    label: PropTypes.node,

    rcLabel: PropTypes.string,
    /** DEPRECATED: Removes padding on label style. Pass in a styled label to `label` to override styling. */
    labelFlushedLeft: PropTypes.bool,
    /** The name of the TextArea. */
    name: PropTypes.string,
    /** Callback when TextArea is blurred. */
    onBlur: PropTypes.func,
    /** Callback when TextArea value is changed. */
    onChange: PropTypes.func.isRequired,
    /** Placehold of the TextArea. */
    placeholder: PropTypes.string,
    /** Function to render content to display in the input. */
    renderInputContent: PropTypes.func,
    /** Resize controls of the TextArea. */
    resize: PropTypes.oneOf(['none', 'both', 'horizontal', 'vertical']),
    /** Number of rows in the TextArea. */
    rows: PropTypes.number,
    /** Displays bottom border, */
    showBottomBorder: PropTypes.bool,
    /** The tab index of the textarea. */
    tabIndex: PropTypes.string,
    /** The value of the TextArea. */
    value: PropTypes.string.isRequired,
    /** The style variant of the text area. */
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
  };

  static defaultProps = {
    autoHeight: false,
    allowErrorBeforeBlur: false,
    error: null,
    isInput: false,
    labelFlushedLeft: false,
    name: 'textarea',
    onBlur: () => null,
    placeholder: null,
    renderInputContent: () => null,
    resize: 'none',
    rows: 2,
    showBottomBorder: true,
    variant: VARIANTS.DEFAULT,
    onFocus: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      hadInitialError: this.props.allowErrorBeforeBlur,
      textAreaHeight: 0,
    };
    this.shadowRef = React.createRef();
  }

  componentDidMount = () => {
    if (this.props.autoHeight && this.props.autoHeightOnMount) {
      this.getShadowHeight();
    }
  };

  /**
   * Show error style if input contains an error after first blur on textarea.
   * @return { void }
   */
  handleBlur = (e) => {
    if (this.props.error && !this.state.hadInitialError) {
      this.setState({ hadInitialError: true });
    }
    this.props.onBlur(e, this.props);
  };

  handleChange = (e) => {
    const { autoHeight, onChange } = this.props;
    onChange(e, { ...this.props, value: e.target.value });

    if (autoHeight) {
      setTimeout(this.getShadowHeight, 20);
    }
  };

  getShadowHeight = () => {
    if (this.shadowRef && this.shadowRef.current) {
      this.setState({ textAreaHeight: this.shadowRef.current.offsetHeight });
    }
  };

  /**
   * Determines which value of error to use.
   * If initial error had not occur, use state value, else use the error prop state.
   * @returns { Boolean } If there's an error in the text.
   */
  hasError = () => {
    const { hadInitialError } = this.state;
    if (hadInitialError) {
      return !!this.props.error;
    }
    return hadInitialError;
  };

  renderInputInner = () => {
    const {
      autoFocus,
      autoHeight,
      className,
      disabled,
      isInput,
      name,
      onKeyPress,
      placeholder,
      resize,
      rows,
      showBottomBorder,
      tabIndex,
      value,
      variant,
      onFocus,
      rcLabel
    } = this.props;
    const { textAreaHeight } = this.state;
    const commonProps = {
      autoFocus,
      onFocus,
      className,
      disabled,
      isError: this.hasError(),
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      onKeyPress,
      placeholder,
      name,
      showBottomBorder,
      tabIndex,
      value,
      variant,
    };

    if (isInput) {
      return <StyledInput {...commonProps} data-rc={rcLabel} />;
    }

    if (autoHeight) {
      return (
        <TextareaShadowContainer>
          <StyledTextarea
            {...commonProps}
            style={{ height: textAreaHeight }}
            data-rc={rcLabel}
            resize={resize}
            rows={rows}
          />
          {autoHeight && (
            <StyledTextareaShadow
              className={className}
              variant={variant}
              ref={this.shadowRef}
            >
              {value}{"\n"}
            </StyledTextareaShadow>
          )}
        </TextareaShadowContainer>
      );
    }

    return <StyledTextarea {...commonProps} data-rc={rcLabel} resize={resize} rows={rows} />;
  };

  render = () => {
    const {
      autoHeight,
      children,
      renderInputContent,
      label,
      variant,
    } = this.props;
    return (
      <LayoutContainer>
        <Wrapper>
          <LabelTextContainer error={this.hasError()} data-role="label">
            {label}
          </LabelTextContainer>
          <TextAreaContainer
            isError={this.hasError()}
            variant={this.props.variant}
            data-role="container"
          >
            <InnerWrapper>
              {this.renderInputInner()}
              {renderInputContent({
                ...this.state,
                autoHeight,
                hasError: this.hasError(),
              })}
            </InnerWrapper>
            {children && (
              <ChildrenContainer isError={this.hasError()} variant={variant}>
                {children}
              </ChildrenContainer>
            )}
          </TextAreaContainer>
        </Wrapper>
      </LayoutContainer>
    );
  };
}
