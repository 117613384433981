import React from 'react';
import BackgroundAnimation, { ANIMATIONS } from '../../BackgroundAnimation';
import ColoredBackground from '../../ColoredBackground';
import gsap from 'gsap';
import * as S from './styles';

export default class Variant6Background extends BackgroundAnimation {
  componentWillUnmount = () => {
    this.tl.kill();
  };

  /**
   * Moves the clouds in.
   * @returns { Object } The animation timeline.
   */
  moveCloudsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 1 };
    tl.to(this.topCloudRef, { x: 130 });
    tl.to(this.bottomCloudRef, { x: -110 });

    tl.to(this.topCloudRef, resetProps, '<');
    tl.to(this.bottomCloudRef, resetProps, '<');
    return tl;
  };

  /**
   * Moves the clouds out.
   * @returns { Object } The animation timeline.
   */
  moveCloudsOut = () => {
    const tl = gsap.timeline({ defaults: { duration: 0.3 } });
    const resetProps = { x: 0, y: 0, duration: 0 };
    tl.to(this.topCloudRef, resetProps);
    tl.to(this.bottomCloudRef, resetProps);

    tl.to(this.topCloudRef, { x: -120 }, '<');
    tl.to(this.bottomCloudRef, { x: 130 }, '<');
    return tl;
  };

  /**
   * Moves the rings in.
   * @returns { Object } The animation timeline.
   */
  moveRingsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 2, opacity: 0, transformOrigin: 'center' };
    const endProps = { scale: 1, opacity: 1, duration: 0.5 };
    const ringRefs = {
      topLeftRingRef: '<0.1',
      topRightRingRef: '<0.3',
      leftRingRef: '<0.1',
      rightRing1Ref: '<-0.3',
      rightRing2Ref: '<0.1',
      bottomRing1Ref: '<',
      bottomRing2Ref: '<0.2',
    };
    Object.keys(ringRefs).forEach((key) => {
      tl.to(this[key], initialProps);
    });
    Object.keys(ringRefs).forEach((key) => {
      tl.to(this[key], endProps, ringRefs[key]);
    });
    return tl;
  };

  /**
   * Moves the dots in.
   * @returns { Object } The animation timeline.
   */
  moveDotsIn = () => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, duration: 0.7 };
    const dotRefs = {
      topDot1Ref: { x: -52, y: 37 },
      topDot2Ref: { x: 47, y: 44 },
      topRightSquare1Ref: { x: -53, y: 27 },
      topRightSquare2Ref: { x: 32, y: 4 },
      leftDot1Ref: { x: -17, y: -67 },
      leftDot2Ref: { x: -7, y: 28 },
      leftDot3Ref: { x: 47, y: 26 },
      leftDot4Ref: { x: 68, y: -55 },
      centerDotRef: { x: -22, y: -85 },
      rightLineRef: { x: 33, y: -42 },
      rightDot1Ref: { x: -11, y: -57 },
      rightDot2Ref: { x: 36, y: -28 },
      rightDot3Ref: { x: 27, y: 36 },
    };
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], dotRefs[key]);
    });
    Object.keys(dotRefs).forEach((key) => {
      tl.to(this[key], resetProps, '<');
    });
    return tl;
  };

  playAnimation = () => {
    const { animation } = this.props;
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    if (animation !== ANIMATIONS.SHOW) {
      if (animation === ANIMATIONS.OUT) {
        tl.add(this.moveCloudsOut(), '<');
      } else {
        tl.add(this.moveCloudsIn(), '<');
        tl.add(this.moveRingsIn(), '<');
        tl.add(this.moveDotsIn(), '<0.3');
      }
    }
    this.tl = tl;
    return tl;
  };

  render() {
    return (
      <ColoredBackground {...this.props}>
        <S.TopCloud ref={(ref) => (this.topCloudRef = ref)} />
        <S.BottomCloud ref={(ref) => (this.bottomCloudRef = ref)} />
        <S.TopLeftRing ref={(ref) => (this.topLeftRingRef = ref)} />
        <S.TopDot1 ref={(ref) => (this.topDot1Ref = ref)} />
        <S.TopDot2 ref={(ref) => (this.topDot2Ref = ref)} />
        <S.TopRightRing ref={(ref) => (this.topRightRingRef = ref)} />
        <S.TopRightSquare1 ref={(ref) => (this.topRightSquare1Ref = ref)} />
        <S.TopRightSquare2 ref={(ref) => (this.topRightSquare2Ref = ref)} />
        <S.LeftRing ref={(ref) => (this.leftRingRef = ref)} />
        <S.LeftDot1 ref={(ref) => (this.leftDot1Ref = ref)} />
        <S.LeftDot2 ref={(ref) => (this.leftDot2Ref = ref)} />
        <S.LeftDot3 ref={(ref) => (this.leftDot3Ref = ref)} />
        <S.LeftDot4 ref={(ref) => (this.leftDot4Ref = ref)} />
        <S.CenterDot ref={(ref) => (this.centerDotRef = ref)} />
        <S.RightLine ref={(ref) => (this.rightLineRef = ref)} />
        <S.RightRing1 ref={(ref) => (this.rightRing1Ref = ref)} />
        <S.RightRing2 ref={(ref) => (this.rightRing2Ref = ref)} />
        <S.RightDot1 ref={(ref) => (this.rightDot1Ref = ref)} />
        <S.RightDot2 ref={(ref) => (this.rightDot2Ref = ref)} />
        <S.RightDot3 ref={(ref) => (this.rightDot3Ref = ref)} />
        <S.BottomRing1 ref={(ref) => (this.bottomRing1Ref = ref)} />
        <S.BottomRing2 ref={(ref) => (this.bottomRing2Ref = ref)} />
      </ColoredBackground>
    );
  }
}
