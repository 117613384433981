import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

export const Path = styled.path`
  fill: ${theme.colors.white};
`;

export const Example = styled(Path).attrs({
  d: ``,
})``;
export const BottomLine = styled(Path).attrs({
  d: `M701.3,771.7c-0.6-0.3-1.3-0.5-1.3-0.8c-0.1-0.6,0.1-1.3,0.4-1.7c2.1-2.2,4.3-4.4,6.5-6.5
	c3.9-3.6,7.9-7.2,11.9-10.8c0.2-0.2,0.5-0.5,0.8-0.6c0.7-0.2,1.5-0.2,2.3-0.3c-0.1,0.7,0,1.5-0.2,2c-0.4,0.8-1,1.5-1.7,2.1
	c-5.5,5.1-11.1,10.1-16.7,15.2C702.7,770.8,702,771.2,701.3,771.7z`,
})``;
export const BottomDot1 = styled(Path).attrs({
  d: `M309.3,1080c0.8-5.9,3.3-8.5,7.4-7.8c1.8,0.3,4.1,3.1,4.5,5.1c0.6,3.3-3.9,7.6-6.7,6.6C312.3,1083.1,310.6,1081,309.3,1080z`,
})``;
export const BottomDot2 = styled(Path).attrs({
  d: `M399.5,1169.8c-3.5-0.1-6.9-3.7-6.8-7.2c0.2-4.2,3.2-7,7.4-6.8c4.2,0.2,7.8,3.5,7.8,7.1C408,1166.7,404.1,1169.9,399.5,1169.8z`,
})``;
export const BottomDot3 = styled(Path).attrs({
  d: `M699.6,1145.5c4.9,0,8.5,3.4,8.6,7.9c0,4.5-3.8,8.5-8.3,8.4c-4.4,0-8-3.5-8-7.8
	C691.8,1149,695,1145.5,699.6,1145.5z M700.4,1155.7c0.7-1.1,1.4-1.7,1.2-2.2c-0.1-0.6-0.9-1.1-1.4-1.6c-0.5,0.5-1.3,1-1.3,1.4
	C699,1154,699.7,1154.6,700.4,1155.7z`,
})``;
export const BottomDot4 = styled(Path).attrs({
  d: `M915.3,951.3c0-5.3,3.7-7.7,6.7-7.7c3.2,0,7.6,4,7.5,6.8c-0.2,3.8-3.7,7.7-7,7.5
	C919.3,957.7,914.4,955,915.3,951.3z M921.7,953.5c0.4,0,0.8,0,1.2,0c0.5-0.9,1.6-1.9,1.5-2.8c-0.1-0.8-1.3-1.6-2-2.3
	c-0.8,0.8-2.1,1.5-2.3,2.4C920,951.6,921.1,952.6,921.7,953.5z`,
})``;
export const BottomRing1 = styled(Path).attrs({
  d: `M179.8,1122.8c9.7,0.2,14.1,5.4,14.3,12.4c0.2,6.8-5.4,14.5-13.1,14.2
	c-7.7-0.3-13.8-5.2-13.9-12.9C167,1129,171.7,1123.2,179.8,1122.8z M181.5,1142.2c2.2,0.1,5.3-3.5,5.4-6.2c0.1-2.3-3.4-5.8-5.9-6
	c-3.1-0.2-6.3,2.9-6.5,6.3C174.4,1139.6,177.2,1141.9,181.5,1142.2z`,
})``;
export const BottomRing2 = styled(Path).attrs({
  d: `M559.5,1061.9c-1.1-10,9.2-17.9,18.2-16.7c8.9,1.2,15.6,6.1,15,18.7
	c-0.3,7.7-8.2,14.8-18.2,14.1C566.6,1077.5,559.2,1069.8,559.5,1061.9z M575,1052.8c-4.7,0.1-8.7,4-8.5,8.3c0.2,4.8,5.3,10.3,9.5,10
	c4.1-0.3,9.9-4.6,9.6-10.3C585.3,1056.4,580.5,1052.7,575,1052.8z`,
})``;
export const BottomRing3 = styled(Path).attrs({
  d: `M768.4,1038.9c-7.3-0.1-15.1-8.1-15.1-15.4c0-7.3,8.1-14.7,15.9-14.5
	c8.8,0.2,16.4,7.7,16,15.8C784.8,1032.8,777.3,1039.1,768.4,1038.9z M769.7,1015.1c-3.5,3.5-7.3,6.1-7,7.5c0.8,2.9,3.8,5.3,5.9,7.9
	c2.3-2,6.1-3.8,6.6-6.2C775.6,1022.2,772.4,1019.3,769.7,1015.1z`,
})``;
export const BottomRing4 = styled(Path).attrs({
  d: `M846.9,1109.3c11.5-0.1,19.5,9.7,19.1,20c-0.3,7.4-8.3,16.9-18.1,16.1
	c-10.4-0.8-18.1-7.6-17.6-18.6C830.7,1117,838.1,1109,846.9,1109.3z M848.2,1139.8c6.4,0.1,12-5.5,12.1-11.2
	c0.1-7.1-5.8-13.1-12.9-13.2c-5.1-0.1-11.5,7.3-11.9,13.2C835.3,1134,843.2,1139.8,848.2,1139.8z`,
})``;

export const TopRightDot = styled(Path).attrs({
  d: `M1161.7,158.9c-2.9,0.2-5.9-1.2-8.9-2.9c-1.7-0.9-3.2-2.2-4.6-3.5c-1-0.9-1.8-2.3-2.5-3.5
	c-1.3-2.2-1.8-4.6-2.4-7.1c-0.1-0.2-0.1-0.4-0.1-0.6c0.2-2.6,0.1-5.2,1.2-7.6c0.8-1.6,1.3-3.4,2.4-4.8c1.7-2.1,3.6-4,6-5.4
	c1.6-0.9,3.2-1.7,5-2.2c1.3-0.4,2.8-0.5,4.2-0.6c2.9-0.2,5.6,0.9,8.2,1.9c1.4,0.6,2.7,1.7,4,2.5c2.4,1.6,3.8,3.9,5,6.4
	c0.5,1,0.7,2.1,1,3.1c0.1,0.4,0.3,0.8,0.4,1.3c0.3,1.6,0.8,3.3,0.8,4.9c-0.1,1.9-0.6,3.8-1.1,5.7c-0.1,0.4-0.5,0.7-0.7,1.1
	c-0.2,0.3-0.3,0.6-0.5,0.9c-0.5,0.8-0.8,1.7-1.4,2.4c-1.3,1.5-2.7,2.9-4.2,4.2C1170.2,158,1166.4,158.8,1161.7,158.9z`,
})``;
export const RightDot1 = styled(Path).attrs({
  d: `M793.2,357.1c0-5.3,3.7-7.7,6.7-7.7c3.2,0,7.6,4,7.5,6.8c-0.2,3.8-3.7,7.7-7,7.5
	C797.1,363.4,792.3,360.7,793.2,357.1z M799.6,359.3c0.4,0,0.8,0,1.2,0c0.5-0.9,1.6-1.9,1.5-2.8c-0.1-0.8-1.3-1.6-2-2.3
	c-0.8,0.8-2.1,1.5-2.3,2.4C797.9,357.4,799,358.4,799.6,359.3z`,
})``;
export const RightDot2 = styled(Path).attrs({
  d: `M1005.8,349.8c0.1-5.5,2.7-8.1,8-8c4.1,0.1,7.1,3.4,7,7.8c-0.1,4.6-2.1,7.1-7.5,7.4C1008.9,357.1,1005.7,353.8,1005.8,349.8z`,
})``;
export const RightDot3 = styled(Path).attrs({
  d: `M1122.8,351c2.3,0.1,4.3,2,4.3,4.3c0,2.5-1.8,4.5-4,4.5c-2.8,0-4.9-2-4.8-4.6C1118.4,352.8,1120.4,350.9,1122.8,351z`,
})``;
export const RightDot4 = styled(Path).attrs({
  d: `M1203,303.9c-2.6,0-5.4-2.9-5.4-5.5c0-2.6,2.9-5.3,5.7-5.2c3.2,0.1,5.9,2.8,5.8,5.7
	C1208.9,301.7,1206.2,303.9,1203,303.9z M1203.5,295.3c-1.2,1.3-2.6,2.2-2.5,2.7c0.3,1.1,1.4,1.9,2.1,2.8c0.8-0.7,2.2-1.4,2.4-2.2
	C1205.7,297.8,1204.5,296.8,1203.5,295.3z`,
})``;
export const RightDot5 = styled(Path).attrs({
  d: `M921.9,456.8c-3.5,0-6-2.3-6-5.4c0-3.7,3.3-7.1,6.8-7c2.9,0.1,6,2.9,6,5.5
	C928.7,454.7,926.7,456.8,921.9,456.8z M924.9,451.4c0-0.3,0-0.6,0-0.9c-0.6-0.5-1.4-1.4-1.9-1.2c-1,0.3-1.8,1.2-2.6,1.8
	c0.6,0.6,1.2,1.6,1.7,1.6C923.1,452.5,924,451.8,924.9,451.4z`,
})``;
export const RightDot6 = styled(Path).attrs({
  d: `M1222.2,428.8c-1.6-3.7-1.6-7.5-1.1-11.4c0.3-2.4,0.8-4.8,1.4-7.2c0.1-0.5,0.8-1.1,1.3-1.1
	c4.2-0.4,8.3-0.7,12.5-0.8c0.6,0,1.2,1.1,1.8,1.7c0,0,0,0.2,0,0.2c-0.2,6.2-0.3,12.3-0.5,18.5
	C1232.5,428.8,1227.4,428.8,1222.2,428.8z`,
})``;
export const RightDot7 = styled(Path).attrs({
  d: `M949.6,546c4-7.4,12.9,0.8,9.4,6.8C955,560.3,946.9,551.9,949.6,546z`,
})``;
export const RightDot8 = styled(Path).attrs({
  d: `M1107.6,556c0,3.1-2.2,5.4-5.2,5.4c-2.8,0-5-2-5-4.8c-0.1-3.2,2.5-6.2,5.2-6.2
	C1105.4,550.4,1107.6,552.7,1107.6,556z`,
})``;
export const RightDot9 = styled(Path).attrs({
  d: `M1055.6,607.1c2.7,0.1,4.8,2.4,4.8,5.1c-0.1,2.2-3.1,5.2-5.3,5.1c-2.5-0.1-4.5-2.4-4.4-5.1
	C1050.9,609.2,1052.9,607,1055.6,607.1z`,
})``;
export const RightDot10 = styled(Path).attrs({
  d: `M859.9,642.3c4.9,0,8.5,3.4,8.6,7.9c0.1,4.5-3.8,8.5-8.3,8.5c-4.4,0-8-3.5-8-7.8
	C852.1,645.7,855.3,642.2,859.9,642.3z M860.8,652.4c0.6-1,1.3-1.7,1.2-2.1c-0.1-0.6-0.9-1.1-1.4-1.6c-0.5,0.5-1.3,1-1.3,1.5
	C859.3,650.7,860,651.3,860.8,652.4z`,
})``;
export const RightDot11 = styled(Path).attrs({
  d: `M959.8,642.4c0.5-2.4,1.4-3.1,3.2-3.1c1.8,0,3.2,0.7,3.2,2.6c0,1.9-1.2,3.5-3,3.2C961.9,644.9,960.9,643.4,959.8,642.4z`,
})``;
export const RightDot12 = styled(Path).attrs({
  d: `M1017.6,726.6c8.8-1,7.1-15.6-1.3-14.7C1005.3,710.8,1006.4,728.5,1017.6,726.6z`,
})``;
export const RightDot13 = styled(Path).attrs({
  d: `M1230.3,655.3c-2.4-0.5-4.1-1.3-3.7-3.1c0.2-1.1,1.8-2.5,3-2.7c1.6-0.3,3.2,1,2.9,2.9C1232.2,653.6,1230.9,654.5,1230.3,655.3z`,
})``;
export const RightDot14 = styled(Path).attrs({
  d: `M865.3,836.6c0,3.1-2.2,5.4-5.2,5.4c-2.8,0-5-2-5-4.8c-0.1-3.2,2.5-6.2,5.2-6.2C863.2,831,865.3,833.3,865.3,836.6z`,
})``;
export const RightDot15 = styled(Path).attrs({
  d: `M995.7,854.4c0.1-5.5,2.7-8.1,8-8c4.1,0.1,7.1,3.4,7,7.8c-0.1,4.6-2.2,7.1-7.5,7.4C998.8,861.7,995.6,858.4,995.7,854.4z`,
})``;
export const RightRing1 = styled(Path).attrs({
  d: `M1265.7,52c9.7,0.2,14.1,5.4,14.3,12.4c0.2,6.8-5.4,14.5-13.1,14.2
	c-7.7-0.3-13.8-5.2-13.9-12.9C1252.9,58.1,1257.6,52.4,1265.7,52z M1267.4,71.3c2.2,0.1,5.3-3.4,5.4-6.1c0.1-2.3-3.4-5.8-5.9-6
	c-3.1-0.2-6.3,2.9-6.5,6.3C1260.3,68.8,1263.1,71.1,1267.4,71.3z`,
})``;
export const RightRing2 = styled(Path).attrs({
  d: `M1178.4,521c8.8,0,17,8.4,16.9,17.4c0,9.4-8.7,17.8-18.2,17.6c-7.4-0.1-15.3-6.9-15.2-19.7
	C1162.1,528.8,1170.4,521,1178.4,521z M1170.2,537.2c0.2,6.1,3.6,10.2,8.3,10.2c4.8-0.1,9.1-4.4,8.9-8.9c-0.2-6-4.6-8.4-9.4-8.9
	C1174.3,529.1,1170.1,533.6,1170.2,537.2z`,
})``;
export const RightRing3 = styled(Path).attrs({
  d: `M1154.1,711.9c8.8,0,16.9,8.4,16.9,17.4c0,9.4-8.7,17.8-18.2,17.6
	c-7.4-0.1-15.3-6.9-15.2-19.7C1137.8,719.7,1146.1,711.9,1154.1,711.9z M1145.9,728.1c0.2,6.1,3.6,10.2,8.3,10.1
	c4.8-0.1,9.1-4.4,8.9-8.9c-0.2-6-4.6-8.4-9.4-8.9C1149.9,720,1145.7,724.5,1145.9,728.1z`,
})``;

export const LeftDot1 = styled(Path).attrs({
  d: `M140.9,191.4c1.1,13.4-15.1,10.9-20.7,3.8C117.3,179.1,138.8,175.2,140.9,191.4z`,
})``;
export const LeftDot2 = styled(Path).attrs({
  d: `M275.4,267.5c-1.6-3.7-1.6-7.5-1.1-11.4c0.3-2.4,0.8-4.8,1.4-7.2c0.1-0.5,0.8-1.1,1.3-1.1
	c4.2-0.4,8.3-0.7,12.5-0.8c0.6,0,1.2,1.1,1.8,1.7c0,0,0,0.2,0,0.2c-0.2,6.2-0.3,12.4-0.5,18.5C285.7,267.5,280.6,267.5,275.4,267.5z`,
})``;
export const LeftDot3 = styled(Path).attrs({
  d: `M37.7,811.8c0,3.1-2.2,5.4-5.2,5.4c-2.8,0-5-2-5-4.8c-0.1-3.2,2.5-6.2,5.2-6.2C35.6,806.2,37.7,808.5,37.7,811.8z`,
})``;
export const LeftDot4 = styled(Path).attrs({
  d: `M58.8,1005.3c-0.5,2.5-1.4,4-3.3,3.8c-0.9-0.1-2.1-1.3-2.3-2.2c-0.4-1.9,1.5-3.9,3.2-3.5C57.4,1003.6,58.2,1004.7,58.8,1005.3z`,
})``;
export const LeftDot5 = styled(Path).attrs({
  d: `M130.6,965.8c0-5.3,3.7-7.7,6.7-7.7c3.2,0,7.6,4,7.5,6.8c-0.2,3.8-3.7,7.7-7,7.5
	C134.6,972.1,129.8,969.4,130.6,965.8z M137.1,968c0.4,0,0.8,0,1.2,0c0.5-0.9,1.6-1.9,1.5-2.8c-0.1-0.8-1.3-1.5-2-2.3
	c-0.8,0.8-2.1,1.5-2.3,2.4C135.3,966.1,136.5,967.1,137.1,968z`,
})``;
export const LeftDot6 = styled(Path).attrs({
  d: `M137.7,887.6c2.7,0.1,4.8,2.4,4.8,5.1c-0.1,2.2-3.1,5.2-5.3,5.1c-2.5-0.1-4.5-2.4-4.4-5.1C133,889.6,135,887.4,137.7,887.6z`,
})``;
export const LeftDot7 = styled(Path).attrs({
  d: `M194.1,836.2c1.5,0.1,2.7,0.7,2.8,2.6c0.1,1.8-1.2,3.3-3,3.3c-1.8,0-2.7-1-2.9-2.8C191,837.5,192.2,836.3,194.1,836.2z`,
})``;
export const LeftDot8 = styled(Path).attrs({
  d: `M270.3,850.7c1.5,0.1,2.7,0.7,2.8,2.6c0.1,1.8-1.2,3.3-3,3.3c-1.8,0-2.7-1-2.9-2.8C267.1,852,268.3,850.8,270.3,850.7z`,
})``;
export const LeftDot9 = styled(Path).attrs({
  d: `M308.5,841.6c-1.1-2.1-3.7-4.9-3.1-6.2c1-2.1,4.1-3.3,6.2-4.8c1.3,1.9,3.8,3.9,3.7,5.8c-0.1,1.9-2.7,3.7-4.3,5.5C310.2,841.7,309.4,841.7,308.5,841.6z`,
})``;
export const LeftDot10 = styled(Path).attrs({
  d: `M287.8,942.9c-0.5,0.7-1.1,1.3-1.6,2c-0.3,0.4-0.2,1.1-0.6,1.3c-3.4,1.5-6.8,3-10.3,4.3
	c-2.2,0.8-3.5-0.7-4.3-2.4c-0.7-1.5-1.3-3.1-1.5-4.7c-0.7-6.4-1.4-12.9-1.8-19.3c-0.2-2.9,2.6-5,5.1-3.5c1.4,0.9,2.7,0.7,4.1,0.9
	c3,0.3,5.9,0.9,8.8,1.5c0.6,0.1,1.5,0.7,1.7,1.2c0.7,1.7,1.3,3.5,1.6,5.4c0.2,1.2-0.3,2.5-0.3,3.8c0,1.2,0,2.5,0.2,3.7
	c0.4,1.7,1.3,3.2,1.6,4.9c0.1,0.5-1.2,1.2-1.9,1.8C288.4,943.5,288.1,943.2,287.8,942.9z`,
})``;
export const LeftDot11 = styled(Path).attrs({
  d: `M450.9,928.8c0,3.1-2.2,5.4-5.2,5.4c-2.8,0-5-2-5-4.8c-0.1-3.2,2.5-6.2,5.2-6.2C448.8,923.2,450.9,925.5,450.9,928.8z`,
})``;
export const LeftDot12 = styled(Path).attrs({
  d: `M464.3,977.6c-3.5,0-6-2.3-6-5.4c0-3.7,3.3-7.1,6.8-7c2.9,0.1,6,2.9,6,5.5
	C471.1,975.5,469,977.7,464.3,977.6z M467.3,972.2c0-0.3,0-0.6,0-0.9c-0.6-0.4-1.4-1.4-1.9-1.2c-1,0.3-1.8,1.2-2.6,1.8
	c0.6,0.6,1.2,1.7,1.7,1.6C465.5,973.4,466.4,972.7,467.3,972.2z`,
})``;
export const LeftDot13 = styled(Path).attrs({
  d: `M524.4,844.2c0-5.3,3.7-7.7,6.7-7.7c3.2,0,7.6,4,7.5,6.8c-0.2,3.8-3.7,7.7-7,7.5
	C528.4,850.5,523.5,847.8,524.4,844.2z M530.8,846.4c0.4,0,0.8,0,1.2,0c0.5-0.9,1.6-1.9,1.5-2.8c-0.1-0.8-1.3-1.5-2-2.3
	c-0.8,0.8-2.1,1.5-2.3,2.4C529.1,844.5,530.2,845.5,530.8,846.4z`,
})``;
export const LeftTear1 = styled(Path).attrs({
  d: `M91,631.2c0.4-4.8,3.9-9,9.1-9.2c4.9-0.1,8.9,1.6,12.5,4.8c2.7,2.4,5.7,4.5,8.2,7.1
	c2,2,3.6,4.4,5.4,6.6c0.8,1,1.8,1.7,2.6,2.8c1.5,2,2.8,4.1,4.2,6.1c2.2,3.2,4.4,6.4,6.5,9.7c0.6,0.9,1,1.9,1.4,2.9
	c0.3,0.9-0.2,1.3-1,1c-2.4-0.9-4.8-1.9-7.1-2.8c-1.1-0.5-2.2-1-3.3-1.5c-3.3-1.5-6.7-2.8-9.9-4.5c-4.3-2.2-8.6-4.4-12.7-6.9
	c-3.8-2.4-7.8-4.5-11.1-7.8c-2.1-2.1-3.8-4.4-4.6-7.3C91.1,631.8,91.1,631.4,91,631.2z`,
})``;
export const LeftTear2 = styled(Path).attrs({
  d: `M130,688c-1-0.1-1.5,0-1.9-0.2c-2.5-1-4.9-2.1-7.3-3.2c-2.2-1-4.4-2-6.5-3.1
	c-2.6-1.3-5.2-2.8-7.9-4.2c-0.8-0.4-1.7-0.9-2.5-1.3c-3.1-1.3-5-3.8-6.4-6.6c-1.1-2.1,0.4-5.6,2.4-6.9c4.1-2.8,7.2-1.5,11.7,1.8
	c4.3,3,7.7,7.1,10.6,11.5c2.2,3.4,4.5,6.7,6.8,10C129.2,686.3,129.5,687,130,688z`,
})``;
export const LeftLine = styled(Path).attrs({
  d: `M169.5,505c-0.6-0.4-1.5-0.6-1.5-0.9c-0.1-0.6,0.1-1.6,0.5-2c2.4-2.6,4.9-5.1,7.5-7.5
	c4.5-4.2,9-8.4,13.6-12.5c0.3-0.3,0.5-0.6,0.9-0.7c0.8-0.2,1.7-0.3,2.6-0.4c-0.1,0.8,0.1,1.7-0.3,2.4c-0.5,0.9-1.2,1.7-1.9,2.4
	c-6.3,5.9-12.7,11.7-19,17.6C171.1,503.9,170.3,504.4,169.5,505z`,
})``;
export const LeftRing1 = styled(Path).attrs({
  d: `M51.1,127c13.2-0.2,22.4,11.2,21.9,23.2c-0.4,8.6-9.6,19.7-20.8,18.8
	c-11.9-1-20.8-8.8-20.2-21.6C32.6,135.9,41.1,126.7,51.1,127z M52.7,162.4c7.3,0.1,13.8-6.4,13.9-13c0.1-8.3-6.6-15.2-14.7-15.3
	c-5.8-0.1-13.2,8.5-13.6,15.3C37.8,155.7,46.9,162.4,52.7,162.4z`,
})``;
export const LeftRing2 = styled(Path).attrs({
  d: `M-5.7,306C3,306,11,314.4,11,323.4c0,9.4-8.6,17.7-18,17.6c-7.3-0.1-15.1-6.9-15-19.6
	C-21.9,313.8-13.7,306-5.7,306z M-13.9,322.1c0.2,6.1,3.6,10.2,8.2,10.1c4.7-0.1,9-4.4,8.8-8.9c-0.3-6-4.6-8.4-9.3-8.9
	C-9.9,314.1-14,318.5-13.9,322.1z`,
})``;
export const LeftRing3 = styled(Path).attrs({
  d: `M15.1,456.9c-1.2-11.3,10.4-20.1,20.8-18.7c10.2,1.3,17.7,6.8,17.1,21
	c-0.4,8.6-9.4,16.6-20.8,15.8C23.2,474.4,14.7,465.7,15.1,456.9z M32.8,446.7c-5.3,0.1-9.9,4.5-9.7,9.3c0.2,5.3,6,11.6,10.8,11.2
	c4.6-0.3,11.2-5.2,10.9-11.5C44.5,450.8,39,446.5,32.8,446.7z`,
})``;
export const LeftRing4 = styled(Path).attrs({
  d: `M331.1,434c-8.5,0-15.1-6.6-15.1-14.7c0.1-9.1,6.3-15.3,15.4-15.3c8.5,0.1,14.8,6.9,14.6,15.7
	C345.9,427.7,339.2,434,331.1,434z M323.1,419c-0.1,4.3,3.2,7.6,7.6,7.7c4.6,0.1,8-3.2,8.1-7.7c0.1-4.3-3.5-7.9-7.9-8
	C326.8,411,323.2,414.7,323.1,419z`,
})``;
export const LeftRing5 = styled(Path).attrs({
  d: `M329.1,698c13.2-0.2,22.4,11.2,21.9,23.2c-0.4,8.6-9.6,19.7-20.8,18.8
	c-11.9-1-20.8-8.8-20.2-21.6C310.6,706.9,319.1,697.6,329.1,698z M330.7,733.4c7.3,0.1,13.8-6.4,13.9-13c0.1-8.3-6.6-15.2-14.7-15.3
	c-5.8-0.1-13.2,8.5-13.6,15.3C315.8,726.7,324.9,733.4,330.7,733.4z`,
})``;
export const CenterDot = styled(Path).attrs({
  d: `M681.3,839.6c-3.5,0-6-2.3-6-5.4c0-3.7,3.3-7.1,6.8-7c2.9,0.1,6,2.9,6,5.5
	C688.2,837.5,686.1,839.7,681.3,839.6z M684.3,834.2c0-0.3,0-0.6,0-0.9c-0.6-0.4-1.4-1.4-1.9-1.2c-1,0.3-1.8,1.2-2.6,1.8
	c0.6,0.6,1.2,1.7,1.7,1.6C682.5,835.4,683.4,834.7,684.3,834.2z`,
})``;
export const TopLine = styled(Path).attrs({
  d: `M69.5,20c-1.7-1-3-1.6-4.1-2.3c-1-0.6-1.9-1.4-2.9-2.1c-2.2-1.5-4.5-3-6.8-4.3
	c-4.7-2.6-4.6-7-3.9-11.3c0.4-2.2-0.1-3.9-0.8-5.9c-0.8-2-0.9-4.3-0.9-6.5c0-0.5,1.2-1,1.9-1.5c0.3,0.6,1.1,1.4,0.9,1.7
	c-1.8,2.7,0.7,4.8,1,7.1c0.2,1.6,0.8,3.3,0.9,4.9c0.2,3.9,1.6,6.7,5.3,8.7c3.9,2.1,7.4,5,11,7.7c1.2,0.9,1.4,2-0.1,2.9
	C70.3,19.4,69.7,19.9,69.5,20z`,
})``;
export const TopRightRing = styled(Path).attrs({
  d: `M952.7,61c11.5-0.2,19.5,9.7,19.1,20c-0.3,7.4-8.3,16.9-18.1,16.2
	c-10.4-0.8-18.1-7.6-17.6-18.6C936.5,68.6,944,60.7,952.7,61z M954,91.4c6.4,0.1,12-5.5,12.1-11.2c0.1-7.1-5.8-13.1-12.9-13.2
	c-5.1-0.1-11.5,7.3-11.9,13.1C941.1,85.7,949,91.5,954,91.4z`,
})``;
export const TopLeftRing = styled(Path).attrs({
  d: `M212.9,33.2c0,8.7-4.9,14.7-12,14.8c-11,0.2-14.5-5.6-14.9-13.9c-0.3-7.4,6.5-13.8,14.3-14
	C206.1,19.8,214.2,27,212.9,33.2z M199.4,29c-2.7-0.1-5.1,2.3-5.2,5.1c-0.1,2.9,2.1,5.3,4.8,5.4c2.8,0.1,5.7-2.1,5.9-4.4
	C205,32.5,202,29.1,199.4,29z`,
})``;
export const BottomRightPlanet = styled(Path).attrs({
  d: `M1185.1,1031.4c-15.6,0-30.7,1.9-45.3,5.4l-12.5-83.2l30.6-9.2
	c-0.2,16.5,10.5,22.5,25.2,15.4c15.5-4.8,32-6.4,47.7-10.9l3.4-1l2.6-0.6l-11.9-69l-2-0.3c-1-0.6-2.5-0.7-4.4-0.7h0
	c-5,0.2-12.3,2.4-18.6,4.1l-17.3,2.7l-1.5-9.7c-0.1-0.4-0.1-0.8-0.2-1.2v0c-3.7-19.7-34.3-2.4-49.9-1.4c-9.4,2.3-12.8,3.9-14.2,4.1
	l-2.5-8.7l-4.6,1l12.8,85.3l8,85.8c-78.9,23.5-136.4,96.6-136.4,183.1c0,105.5,85.5,191.1,191.1,191.1s191.1-85.5,191.1-191.1
	S1290.6,1031.4,1185.1,1031.4z M1121.6,1120.9c-3.4,31.3-48.9,53-72.3,34.9c19.2-0.1,39,6.7,56.3-12.9c16.2-16.6,12-33.7,13.1-51.9
	C1126.1,1096.3,1122.9,1112,1121.6,1120.9z M1208.2,1153.7c1.9-1.8,4.6,0.8,3.3,2.9c-9.4,22.2-32.9,30.1-55.4,29.7
	c-3.8-0.9-18.9,0.8-15.8-5.2C1164.7,1183.9,1197.5,1181.5,1208.2,1153.7z`,
})``;
export const BottomLeftPlanet = styled(Path).attrs({
  d: `M9.2,1078.3c20.8-2,37.8,6.3,53,19.7c14.8,13.1,29,27,35,46.4c2.9,9.4,2.9,19.9,2.9,30
	c0,8.3-1.7,16.5-2.8,24.8c-1.8,13.9-11.7,23.3-18.7,34.3c-8.8,13.8-22.2,22.4-34.5,32.2c-2.3,1.8-5.4,2.7-8.3,3.7
	c-38.8,13.2-72,1.9-102.1-23.4c-16.6-13.9-25.5-31.9-29.2-52.6c-4.3-24.8,1.4-47.3,14.6-69.1c8.3-13.8,19.5-23.6,31.3-33.4
	c9.7-8,21.2-11.3,33.2-12.5C-8,1077.6,0.6,1078.3,9.2,1078.3z M-87.2,1196.5c4.4-3,8.1-5.4,11.6-8.1c8.5-6.6,16.8-13.3,25.3-20
	c3-2.4,5.9-5,9.1-7.1c1.6-1,3.7-1.3,5.6-1.8c-0.6,2.1-0.5,4.8-1.9,6.2c-4.3,4.3-9.2,8-13.8,12c-9.9,8.7-19.9,17.3-29.5,26.3
	c-1.6,1.5-2.7,5.7-1.7,7.3c4.8,8.2,9.4,16.9,15.9,23.6c7.7,7.9,17.1,14.2,26.1,20.6c3.5,2.5,7.9,4.4,12.2,5c1.9,0.2,4.3-3.4,6.5-5.4
	c12.4-11.6,24.6-23.4,37.2-34.8c13.7-12.4,27.7-24.5,41.8-36.4c3.1-2.6,6.9-4.2,10.9-6.6c0.9,6.2-6.8,13.7-19.4,23
	c-8.1,5.9-14.9,13.6-22.2,20.5c-7.5,7.1-15.1,14.1-22.5,21.3c-7.3,7.1-14.4,14.3-22.2,22c14.2,3.5,26.8,4.4,40.5,1.6
	c17.7-3.6,31.2-13.1,42.7-25.1c9.1-9.5,15.7-21.7,21.9-33.4c7.6-14.3,8.9-30.3,6.5-46.1c-2.7-17.9-11-33.3-23.5-46.5
	c-9.1-9.6-10.2-10-20.4-0.9c-1.2,1.1-2.2,2.4-3.5,3.4c-1.8,1.3-3.6,2.8-5.7,3.4c-1.4,0.4-3.2-0.8-4.8-1.2c0.7-1.5,1-3.4,2.2-4.4
	c3.5-3,7.4-5.5,11-8.5c5.7-4.8,5.5-8.1-1.3-11.5c-6.2-3.1-12.7-5.4-19.2-7.8c-5.8-2.1-11.7-2.3-16.7,1.9
	c-10.8,9.4-21.5,19-32.2,28.6c-3.9,3.5-7.8,7.2-11.8,10.5c-0.8,0.7-2.4,0.6-3.7,0.8c-0.1-1.3-0.9-3-0.3-3.9c1.7-2.7,3.7-5.3,5.9-7.7
	c10.4-11.2,21-22.4,31.1-33.2c-9.6-4.7-37.1,3.4-56.7,21.2C-82.8,1129.3-93.6,1159.6-87.2,1196.5z`,
})``;
