import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0002 3.33398C20.8447 3.33398 21.5293 4.0186 21.5293 4.86311V35.1382C21.5293 35.9827 20.8447 36.6673 20.0002 36.6673C19.1557 36.6673 18.471 35.9827 18.471 35.1382V4.86311C18.471 4.0186 19.1557 3.33398 20.0002 3.33398Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.6668 20.0007C36.6668 19.1561 35.9822 18.4715 35.1377 18.4715H4.86262C4.01811 18.4715 3.3335 19.1561 3.3335 20.0007C3.3335 20.8452 4.01811 21.5298 4.86262 21.5298H35.1377C35.9822 21.5298 36.6668 20.8452 36.6668 20.0007Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#99999B',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;