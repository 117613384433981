import React from 'react';
import styled from 'styled-components';
import { unitHeight, unitWidth } from './styles';
import theme from '@matterapp/matter-theme';

const MASK_WIDTH = theme.borderRadius.S;
const MASK_HEIGHT = theme.spacing.double;

const Container = styled.div`
  flex: 1 1 auto;
  position: relative;
  min-width: 10px;
  border-left: 1px solid ${theme.colors.white};
  border-right: 1px solid ${theme.colors.white};
`;

const sharedSideStyles = `
  text-align: left;
  height: ${unitHeight};
  min-width: ${unitWidth};
  position: absolute;
  top: 0;
  &:before, &:after {
    content: "";
    position: absolute;
    background-color: transparent;
    width: ${MASK_WIDTH};
    overflow: hidden;
  }
  &:before {
    height: ${MASK_HEIGHT};
    box-shadow: 0 -${MASK_WIDTH} 0 0 ${theme.colors.white};
  }
  &:after {
    height: ${unitHeight};
    box-shadow: 0 ${MASK_WIDTH} 0 0 ${theme.colors.white};
  }
`;

const LeftSide = styled.div`
  ${sharedSideStyles}
  &:before {
    border-top-left-radius: ${MASK_WIDTH};
  }
  &:after {
    border-bottom-left-radius: ${MASK_WIDTH};
  }
`;

const RightSide = styled.div`
  ${sharedSideStyles}
  left: 100%;
  &:before,
  &:after {
    right: 100%;
  }
  &:before {
    border-top-right-radius: ${MASK_WIDTH};
  }
  &:after {
    border-bottom-right-radius: ${MASK_WIDTH};
  }
`;

export default function Unit({ decimal }) {
  return (
    <Container>
      <LeftSide decimal={decimal} />
      <RightSide />
    </Container>
  );
}
