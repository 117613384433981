import React from 'react';
import BasePath from './BasePath';

const DEFAULT_HEIGHT = 76;
const PATH_LENGTH = 459;

const RightToLeft1 = React.forwardRef((props, ref) => (
  <BasePath
    {...props}
    ref={ref}
    height={DEFAULT_HEIGHT}
    pathLength={PATH_LENGTH}
    pathCode={
      `M425.5,65.5H314c-6.628,0-12.628-2.686-16.971-7.029C292.686,54.128,290,48.128,290,41.5v-6
      c0-6.628-2.686-12.628-7.029-16.971C278.628,14.186,272.628,11.5,266,11.5h-98c-6.628,0-12.628,2.686-16.971,7.029
      S144,28.872,144,35.5v6c0,6.628-2.686,12.628-7.029,16.971S126.628,65.5,120,65.5H34`
    }
  />
));

export default RightToLeft1;
