import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { trackExperiment } from 'hocs/tracking/trackExperiment';
import apolloClient from 'config/apolloClient';
import { logger } from 'libs';

const EXPERIMENT_QUERY = gql`
  {
    activeExperimentAssignments {
      id
      experimentName
      variantName
      isOverride
      isRegistered
      assignmentToken
    }
  }
`;

const REGISTER_EXPERIMENT_ASSIGNMENT_MUTATION = gql`
  mutation registerAssignment($token: String!) {
    registerAssignment(token: $token) {
      id
      experimentName
      variantName
      isOverride
      isRegistered
      assignmentToken
    }
  }
`;

export function useExperiment({ name }) {
  const { loading, error, data } = useQuery(EXPERIMENT_QUERY);

  function extractAssignment() {
    if (loading || error) {
      return null;
    }

    const activeAssignment = data.activeExperimentAssignments.find(
      (currentAssignment) => {
        return currentAssignment.experimentName === name;
      }
    );
    return activeAssignment || null;
  }

  const extractedAssignment = extractAssignment();

  useEffect(function () {
    if (extractedAssignment) {
      logger.info(`Assignment for experiment: "${name}" = `);
      logger.info({ extractedAssignment });
      if (!extractedAssignment.isRegistered && !extractedAssignment.isOverride) {
        apolloClient.mutate({
          mutation: REGISTER_EXPERIMENT_ASSIGNMENT_MUTATION,
          variables: {
            token: extractedAssignment.assignmentToken,
          },
        }).then(() => {
          trackExperiment({
            experimentName: extractedAssignment.experimentName,
            experimentVariantName: extractedAssignment.variantName,
          });
        }).catch((e) => {
          logger.debug(e);
        });
      }
    }
  });

  return {
    loading,
    error,
    assignment: extractedAssignment ? extractedAssignment.variantName : null,
  };
}
