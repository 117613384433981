import * as t from './actionTypes';

/** ACTIONS */
/**
 * Add a skill on multi select skills pane.
 * @param { Number } skillId: The id of the skill to add.
 * @returns { Object } addSkill action.
 */
export const addSkill = (skillId) => {
  return { type: t.ADD_SKILL, skillId };
};

export const initUserSkillsList = (userSkills) => {
  return { type: t.INIT_USER_SKILLS_LIST, userSkills};
};

export const onChangeSelectedSkillsIds = (skill) => {
  return { type: t.UPDATE_SELECTED_SKILLS, skill};
};

/**
 * Change skill on single select skills pane.
 * @param { Object } selectedSkill: The selected skill.
 * @returns { Object } selectSkill action.
 */
export const changeSelectedSkill = (selectedSkill) => {
  return { type: t.CHANGE_SELECTED_SKILL, selectedSkill };
};

/**
 * Reset the skill in the skill tag.
 * @param { Object } selectedSkill: The selected skill.
 * @returns { Object } resetSkill action.
 */
export const resetSelectedSkill = () => {
  return { type: t.RESET_SELECTED_SKILL };
};

/**
 * Change message on edit message survey pane.
 * @param { String } message: The updated message.
 * @returns { Object } changeMessage action.
 */
export const changeMessage = (message) => {
  return { type: t.CHANGE_MESSAGE, message };
};

/**
 * Change the step of the modal.
 * @param { Object } modalStep: The new modalStep.
 * @returns { Object } changeModalStep action.
 */
export const changeModalStep = (modalStep) => {
  return { type: t.CHANGE_MODAL_STEP, modalStep };
};

/**
 * Change selected kudos on kudos pane.
 * @param { String } selectedKudos: The selected kudos.
 * @returns { Object } changeSelectedKudos action.
 */
export const changeSelectedKudos = (selectedKudos) => {
  return { type: t.CHANGE_SELECTED_KUDOS, selectedKudos };
};

/**
 * Reset selected kudos.
 * @param { String } selectedKudos: The selected kudos.
 * @returns { Object } resetKudos action.
 */
export const resetSelectedKudos = () => {
  return { type: t.RESET_SELECTED_KUDOS };
};

/**
 * Change selected peers on multi select peers pane.
 * @param { Array } selectedPeers: The updated selected peers list.
 * @returns { Object } changeSelectedPeers action.
 */
export const changeSelectedPeers = (selectedPeers) => {
  return { type: t.CHANGE_SELECTED_PEERS, selectedPeers };
};

/**
 * Change selected peer on single select peers pane.
 * @param { Object } selectedPeer: The updated selected peer.
 * @returns { Object } changeSelectedPeer action.
 */
export const changeSelectedPeer = (selectedPeer) => {
  return { type: t.CHANGE_SELECTED_PEER, selectedPeer };
};

/**
 * Close the kudos sent modal.
 * @returns { Object } closeKudosSentModal action.
 */
export const closeKudosSentModal = () => {
  return { type: t.CLOSE_KUDOS_SENT_MODAL };
};

/**
 * Close the request feedback modal.
 * @returns { Object } closeRequestFeedbackModal action.
 */
export const closeRequestFeedbackModal = () => {
  return { type: t.CLOSE_REQUEST_FEEDBACK_MODAL };
};

/**
 * Close the send kudos modal.
 * @returns { Object } closeSendKudosModal action.
 */
export const closeSendKudosModal = () => {
  return { type: t.CLOSE_SEND_KUDOS_MODAL };
};

/**
 * Close the give feedback modal.
 * @returns { Object } closeGiveFeedbackModal action.
 */
export const closeGiveFeedbackModal = () => {
  return { type: t.CLOSE_GIVE_FEEDBACK_MODAL };
};

/**
 * Initilizes the composer state to initial state.
 * @returns { Object } reset action.
 */
export const initialize = ({ message } = { message: '' }) => {
  return { type: t.INITIALIZE, message };
};

/**
 * Open the kudos sent modal.
 * @returns { Object } openKudosSentModal action.
 */
export const openKudosSentModal = () => {
  return { type: t.OPEN_KUDOS_SENT_MODAL };
};

/**
 * Open the request feedback modal.
 * @returns { Object } openRequestFeedbackModal action.
 */
export const openRequestFeedbackModal = () => {
  return { type: t.OPEN_REQUEST_FEEDBACK_MODAL };
};

/**
 * Open the send kudos modal.
 * @returns { Object } openSendKudosModal action.
 */
export const openSendKudosModal = () => {
  return { type: t.OPEN_SEND_KUDOS_MODAL };
};

/**
 * Open the send give feedback modal.
 * @returns { Object } giveFeedbackModal action.
 */
export const openGiveFeedbackModal = () => {
  return { type: t.OPEN_GIVE_FEEDBACK_MODAL };
};

/**
 * Remove a skill on select skills pane.
 * @param { Number } skillId: The id of the skill to remove.
 * @returns { Object } removeSkill action.
 */
export const removeSkill = (skillId) => {
  return { type: t.REMOVE_SKILL, skillId };
};

/**
 * Sets as custom survey of the request feedback survey is a custom survey.
 * @param { Boolean } isCustomSurvey: If the survey is a custom survey.
 * @returns { Object } setCustomSurvey action.
 */
export const setCustomSurvey = (isCustomSurvey) => {
  return { type: t.SET_CUSTOM_SURVEY, isCustomSurvey };
};

/**
 * Sets the feedback template of the survey.
 * @param { Object } template: The feedback template containin the id and invitationUrl.
 * @returns { Object } setFeedbackTemplate action.
 */
export const setFeedbackTemplate = ({ id, invitationUrl }) => {
  return { type: t.SET_FEEDBACK_TEMPLATE, id, invitationUrl };
};

/**
 * Sets if composer is currently sending.
 * @param { Boolean } isSending: If composer is sending.
 * @returns { Object } setIsSending action.
 */
export const setIsSending = (isSending) => {
  return { type: t.SET_IS_SENDING, isSending };
};
