import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Resources } from '@matterapp/routing';
import { Link } from 'react-router-dom';
import { InvitationProps } from './prop-types';
import theme from '@matterapp/matter-theme';
import {
  Modal,
  Button,
  Skill,
} from '@matterapp/matter-ui';
import {
  REPLACE_FREQUENCY_TEXT,
  DEFAULT_REQUEST_MESSAGE,
  DEFAULT_RECURRING_REQUEST_MESSAGE
} from 'copy-consts';
import { FREQUENCY_NOUNS } from 'app-consts';

const colorMap = {
  Recurring: { 
    color: theme.colors.greens[60],
    colorFamily: 'greens',
  },
  Waiting: { 
    color: theme.colors.purples[40],
    colorFamily: 'purples',
  },
  Bounced: { 
    color: theme.colors.reds[60],
    colorFamily: 'reds',
  },
  Declined: { 
    color: theme.colors.oranges[60],
    colorFamily: 'oranges',
  },
  Expired: { 
    color: theme.colors.blacks[50],
    colorFamily: 'blacks',
  },
  Revoked: { 
    color: theme.colors.blacks[50],
    colorFamily: 'blacks',
  },
};

const StyledModal = styled(Modal)`
  .matter-ReactModal__Overlay {
    z-index: 100;
  }
`;


const ModalHeader = styled(Modal.Header)`
  > * {
    text-align: center;
  }
`;

const ModalBody = styled(Modal.Body)`
  & ${Modal.Body.ScrollContainer} {
    overflow: auto;
    background:
    /* Shadow covers */
    linear-gradient(white 30%, rgba(255,255,255,0)),
    linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
      
    /* Shadows */
    radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,0.06), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 5px;
    background-attachment: local, local, scroll, scroll;
  }
`;

const StatusBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  font-size: 17px;
  line-height: 24px;
  ${({ statusType }) => `
    color: ${colorMap[statusType].color};
    background: ${theme.colors[`${colorMap[statusType].colorFamily}`][5]};    
  `}
`;

const ModalSection = styled.div`
  margin: ${theme.spacing.singleAndHalf};
`;

const BodyHeader = styled.div`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.L};
  color: ${theme.colors.blacks[80]};
  margin-bottom: ${theme.spacing.single};
`;

const BodySubheader = styled.div`
  display: contents;
  font-weight: ${theme.fontWeight.semiBold};
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[60]};
`;

const BodyDescription = styled.div`
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[60]};
  margin-bottom: ${theme.spacing.half};
`;

const ModalFooter = styled(Modal.Footer)`
  display: flex;
  padding: ${theme.spacing.single};
  ${theme.media.S`
    padding: ${theme.spacing.singleAndHalf};
  `}
`;

const PeerName = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: fit-content;
  pointer-event: none;
  height: ${theme.spacing.doubleAndHalf};
  padding: 0 ${theme.spacing.single};
  border-radius: ${theme.spacing.half};
  background: ${theme.colors.blues[5]};
  color: ${theme.colors.purples[50]};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
`;

const PrimaryActionButton = styled(Button.Primary.Vivid)`
  flex: 1 1 100%;
  font-size: ${theme.fontSize.S};
  padding: ${theme.spacing.half} 0;
`;

const SecondaryActionButton = styled(Button.Simple)`
  flex: 1 1 100%;
  padding: ${theme.spacing.half} 0;
  margin-right: ${theme.spacing.single};
  ${({ isHidden }) => isHidden && `
    display: none;
  `}
`;

const SkillsSection = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-bottom: -${theme.spacing.half};
`;

const SkillPillContainer = styled.div`
  margin-right: ${theme.spacing.half};
  margin-bottom: ${theme.spacing.half};
`;

const SkillsHelperMessageContainer = styled.div`
  font-style: italic;
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.purples[50]};
  margin-top: ${theme.spacing.singleAndHalf};
`;

const LinkContainer = styled(Link)`
  color: inherit;
  text-decoration: underline;
`;

export default class PendingRequestModal extends React.Component {
  getSkillsHelperMessage() {
    const { selectedInvitation } = this.props;
    const { skillsStatus, invitedPeer } = selectedInvitation;
    const message = skillsStatus.type === 'CanModify' ? 
      <div>
        You’ll be gathering feedback on the skills listed above. If needed, click <LinkContainer to={Resources.skillsMain.path()}>here</LinkContainer> to update your selected skills.
      </div> :
      <div>
        {invitedPeer.firstName || invitedPeer.email} is in the progress of completing this request. The skills for this request can no longer be updated.
      </div>;

    return (
      <SkillsHelperMessageContainer>
        {message}
      </SkillsHelperMessageContainer>
    );
  }

  renderSkillPills(skill, index) {
    return (
      <SkillPillContainer key={index}>
        <Skill.Pill.WithBadge
          key={index}
          className="Skill Pill"
          skill={{
            name: skill.name
          }}
        />
      </SkillPillContainer>
    );
  }

  renderSkillSection() {
    const { selectedInvitation } = this.props;
    const { skills, type } = selectedInvitation.skillsStatus;
    const skillsHelperMessage = type !== 'Closed' ? this.getSkillsHelperMessage() : null;

    return (
      <ModalSection>
        <BodyHeader>
          Skills:
        </BodyHeader>
        <SkillsSection>
          {skills.map((skill, index) => this.renderSkillPills(skill, index))}
        </SkillsSection>
        {skillsHelperMessage}
      </ModalSection>
    );
  }

  renderMessageSection() {
    const { currentUserFullName, selectedInvitation } = this.props;
    const { invitedPeer, eventTime, customNote, recurringFeedback } = selectedInvitation;
    const requestSentDate = moment(eventTime).format('dddd, MMM Do YYYY');
    const customGreeting = invitedPeer.firstName ? `Hi ${invitedPeer.firstName}` : 'Hey';

    let subject = `${currentUserFullName} would like your feedback`;
    let messageBody;

    if (recurringFeedback) {
      const recurringFrequency = recurringFeedback.recurrence.frequency;
      const frequencyDeclaritiveNoun = (recurringFrequency === "biweekly" || recurringFrequency === "bimonthly") ? 'these' : 'this';
      const recurringSubjectLine = `How's it been working with ${currentUserFullName} ${frequencyDeclaritiveNoun} last ${FREQUENCY_NOUNS[recurringFrequency]}?`;
      
      if (customNote) {
        messageBody = customNote;
        subject = recurringSubjectLine;
      } else {
        const message = DEFAULT_RECURRING_REQUEST_MESSAGE.replace(REPLACE_FREQUENCY_TEXT, FREQUENCY_NOUNS[recurringFrequency]);
        const defaultRecurringMessage = message.charAt(0).toLowerCase() + message.slice(1);
        messageBody = `${customGreeting}, ${defaultRecurringMessage}`;
        subject = recurringSubjectLine;
      }
    } else {
      if (customNote) {
        messageBody = customNote;
      } else {
        messageBody = `${customGreeting}, ${DEFAULT_REQUEST_MESSAGE}`;
      }
    }

    return (
      <ModalSection>
        <BodyHeader>
          Your message:
        </BodyHeader>
        <BodyDescription>
          <BodySubheader>Sent</BodySubheader>&nbsp;&nbsp;
          {requestSentDate}
        </BodyDescription>
        <BodyDescription>
          <BodySubheader>Subject</BodySubheader>&nbsp;&nbsp;
          {subject}
        </BodyDescription>
        <BodyDescription>
          <BodySubheader>Body</BodySubheader>&nbsp;&nbsp;
          {messageBody}
        </BodyDescription>
      </ModalSection>
    );
  }

  getReminderApproximation() {
    const { selectedInvitation } = this.props;
    const { reminders } = selectedInvitation;

    switch (reminders.next) {
    case 'VerySoon':
      return 'In the next few days';
    case 'Week':
      return 'This week';
    default:
      return 'In a week';
    }
  }

  renderReminderSection() {
    const { selectedInvitation } = this.props;

    if (selectedInvitation.reminders.next !== 'Never') {      
      return (
        <ModalSection>
          <BodyHeader>
            Reminders:
          </BodyHeader>
          <BodyDescription>
            <BodySubheader>Next</BodySubheader>&nbsp;&nbsp;
            {this.getReminderApproximation()}
          </BodyDescription>
        </ModalSection>
      );
    }
  }

  renderModalBody() {
    const { selectedInvitation } = this.props;
    const { invitedPeer, feedbackRequestStatus } = selectedInvitation;
    const peerTitle = invitedPeer.fullName || invitedPeer.email;
    const statusSubType = feedbackRequestStatus.subType === 'Ignored' ? 'Declined' : feedbackRequestStatus.subType;
    const statusAdverb = feedbackRequestStatus.subType === 'Waiting' ? 'since' : 'on';
    const statusDate = feedbackRequestStatus.changedAt ? moment(feedbackRequestStatus.changedAt).calendar('DD/MM/YYYY') : null;
    const statusBanner = statusSubType ? 
      <StatusBanner statusType={statusSubType}>
        {statusSubType} {statusAdverb} {statusDate}
      </StatusBanner> : null;

    return (
      <ModalBody>
        {statusBanner}
        <ModalSection>
          <BodyHeader>
            Requesting feedback from:
          </BodyHeader>
          <PeerName>
            {peerTitle}
          </PeerName>
        </ModalSection>

        {this.renderSkillSection()}
        {this.renderMessageSection()}
        {this.renderReminderSection()}
      </ModalBody>
    );
  }

  renderPrimaryActionButton(buttonLabel, buttonAction, secondaryButtonAction) {
    const eventHandler = {};

    if (typeof buttonAction === 'string') {
      eventHandler.href = buttonAction;
      // We are using onMouseUp and onMouseDown to close the modal and handle tracking. 
      // onClick cancels out href, which also cancels out the link preview, so this is a way to overcome that.
      eventHandler.onMouseUp = secondaryButtonAction;
      eventHandler.onMouseDown = this.props.handleTrackFollowUpClick;
      eventHandler.target = "_blank";
    } else {
      eventHandler.onClick = buttonAction;
    }

    return (
      <PrimaryActionButton
        primary
        size={'S'}
        {...eventHandler}
      >
        {buttonLabel}
      </PrimaryActionButton>
    );
  }

  renderSecondaryActionButton(buttonLabel, buttonAction) {
    return (
      <SecondaryActionButton
        size={'M'} 
        color={'black'}
        onClick={buttonAction}
      >
        {buttonLabel}
      </SecondaryActionButton>
    );
  }

  getFooterPrimaryButton() {
    const {
      selectedInvitation,
      handleClosePendingRequestModal,
      handleOpenUpdateInfoModal,
      handleFollowUp,
      createFollowUpLink,
    } = this.props;
    const { feedbackRequestStatus, replacement } = selectedInvitation;
    const statusType = feedbackRequestStatus.type;
    const statusSubType = feedbackRequestStatus.subType;

    if (statusType === 'Pending' && statusSubType !== 'Waiting') {
      return this.renderPrimaryActionButton('Done', handleClosePendingRequestModal);
    }
    if (statusSubType === 'Bounced' && !replacement) {
      return this.renderPrimaryActionButton('Update Info', handleOpenUpdateInfoModal);
    } else if (statusSubType === 'Waiting' || statusSubType === 'Ignored') {
      return this.renderPrimaryActionButton('Follow Up', createFollowUpLink(statusSubType), handleFollowUp);
    }
    return this.renderPrimaryActionButton('Close', handleClosePendingRequestModal);
  }

  getFooterSecondaryButton() {
    const {
      selectedInvitation,
      handleCancelPendingRequest,
      handleClosePendingRequestModal,
    } = this.props;
    const { feedbackRequestStatus, replacement } = selectedInvitation;
    const statusType = feedbackRequestStatus.type;
    const statusSubType = feedbackRequestStatus.subType;

    if ((statusSubType === 'Bounced' && !replacement) || statusSubType === 'Ignored') {
      return this.renderSecondaryActionButton('Close', handleClosePendingRequestModal);
    }
    if (statusType === 'Pending') {
      return this.renderSecondaryActionButton('Cancel Request', handleCancelPendingRequest);
    }
    return null;
  }

  renderModalFooter() {
    const { isAdvisorPage, handleClosePendingRequestModal } = this.props;
    if (isAdvisorPage) {
      return (
        <ModalFooter>
          <PrimaryActionButton
            onClick={handleClosePendingRequestModal}
          >
            Done
          </PrimaryActionButton>
        </ModalFooter>
      );
    }
    return (
      <ModalFooter>
        {this.getFooterSecondaryButton()}
        {this.getFooterPrimaryButton()}
      </ModalFooter>
    );
  }

  render() {
    const { isPendingRequestModalOpen, handleClosePendingRequestModal } = this.props;

    return (
      <StyledModal
        transparent
        fullScreenMobile
        contentLabel="Pending Request Modal"
        isOpen={isPendingRequestModalOpen}
        onClose={handleClosePendingRequestModal}
      >
        <ModalHeader
          title="Feedback Request"
          right={<Modal.Header.Buttons.Close onClick={handleClosePendingRequestModal} />}
        />
        {this.renderModalBody()}
        {this.renderModalFooter()}
      </StyledModal>
    );
  }
}

PendingRequestModal.propTypes = {
  isAdvisorPage: PropTypes.bool,
  selectedInvitation: InvitationProps.isRequired,
  currentUserFullName: PropTypes.string,
  isPendingRequestModalOpen: PropTypes.bool.isRequired,
  handleClosePendingRequestModal: PropTypes.func.isRequired,
  handleCancelPendingRequest: PropTypes.func,
  handleOpenUpdateInfoModal: PropTypes.func,
  handleFollowUp:PropTypes.func,
  handleTrackFollowUpClick: PropTypes.func,
  createFollowUpLink: PropTypes.func,
};

PendingRequestModal.defaultProps = {
  isAdvisorPage: false,
};