import React from 'react';
import Animation from '../../Animation';
import Image from './Image';
import * as C from './classNames';

export default class Inclusive extends Animation {
  baseClass = C.BASE;

  moveBackground = (initialize) => {
    const tl = this.getDefaultTimeline({ duration: 0.3 });
    const BACKGROUND = this.getElement(C.BACKGROUND);
    const HIGHLIGHT = this.getElement(C.HIGHLIGHT);
    const TRIANGLE_TOP = this.getElement(C.TRIANGLE.TOP);
    const TRIANGLE_CENTER = this.getElement(C.TRIANGLE.CENTER);
    const TRIANGLE_BOTTOM = this.getElement(C.TRIANGLE.BOTTOM);

    const CIRCLE_TOP = this.getElement(C.CIRCLE.TOP);
    const CIRCLE_CENTER = this.getElement(C.CIRCLE.CENTER);
    const CIRCLE_BOTTOM = this.getElement(C.CIRCLE.BOTTOM);

    const PLUS = this.getElement(C.PLUS);

    const hideTriangleProps = { x: 40, opacity: 0, duration: 0 };
    const showTriangleProps = { x: 0, y: 0, opacity: 1 };
    const hideCircleProps = { x: 20, scale: 2, opacity: 0, duration: 0 };
    const showCircleProps = { x: 0, scale: 1, opacity: 0.4 };

    tl.to(HIGHLIGHT, { opacity: 0, scale: 0.98, filter: 'blur(4px)', duration: 0 }, '<');
    tl.to(BACKGROUND, { opacity: 0, duration: initialize ? 0 : 0.3 }, '<');
    tl.to(TRIANGLE_TOP, { y: 5, ...hideTriangleProps }, '>');
    tl.to(TRIANGLE_CENTER, hideTriangleProps, '<');
    tl.to(TRIANGLE_BOTTOM, { y: -5, ...hideTriangleProps }, '<');

    tl.to(CIRCLE_TOP, hideCircleProps, '<');
    tl.to(CIRCLE_CENTER, hideCircleProps, '<');
    tl.to(CIRCLE_BOTTOM, hideCircleProps, '<');

    tl.to(PLUS, { x: -30, opacity: 0, rotate: 360 }, '<');

    tl.to(BACKGROUND, { opacity: 1, duration: 0 }, '>');

    if (!initialize) {
      tl.to(TRIANGLE_TOP, showTriangleProps, '>0.9');
      tl.to(TRIANGLE_CENTER, showTriangleProps, '<');
      tl.to(TRIANGLE_BOTTOM, showTriangleProps, '<');

      tl.to(CIRCLE_TOP, showCircleProps, '<');
      tl.to(CIRCLE_CENTER, showCircleProps, '<');
      tl.to(CIRCLE_BOTTOM, showCircleProps, '<');

      tl.to(PLUS, { x: 0, opacity: 0.4, rotate: 0 }, '<');
    }

    return tl;
  };

  moveHands = () => {
    const tl = this.getDefaultTimeline({ duration: 0.4 });
    const HIGHLIGHT = this.getElement(C.HIGHLIGHT);

    const HAND_TOP_LEFT = this.getElement(C.HAND.TOP_LEFT);
    const HAND_TOP_RIGHT = this.getElement(C.HAND.TOP_RIGHT);
    const HAND_BOTTOM_LEFT = this.getElement(C.HAND.BOTTOM_LEFT);
    const HAND_BOTTOM_RIGHT = this.getElement(C.HAND.BOTTOM_RIGHT);

    const PUZZLE_TOP_LEFT = this.getElement(C.PUZZLE.TOP_LEFT);
    const PUZZLE_TOP_RIGHT = this.getElement(C.PUZZLE.TOP_RIGHT);
    const PUZZLE_BOTTOM_LEFT = this.getElement(C.PUZZLE.BOTTOM_LEFT);
    const PUZZLE_BOTTOM_RIGHT = this.getElement(C.PUZZLE.BOTTOM_RIGHT);

    const resetProps = { x: 0, y: 0, rotate: 0 };
    const hideProps = { opacity: 0 };
    const showProps = { x: 0, y: 0, opacity: 1 };

    tl.to(HAND_TOP_LEFT, { x: 10, y: 6.4, rotate: 15 }, '>');
    tl.to(PUZZLE_TOP_LEFT, hideProps, '<');

    tl.to(HAND_TOP_RIGHT, { x: -9.8, y: 6, rotate: -15 }, '>');
    tl.to(PUZZLE_TOP_RIGHT, hideProps, '<');

    tl.to(HAND_BOTTOM_LEFT, { x: 9.4, y: -7.3, rotate: -15 }, '>');
    tl.to(PUZZLE_BOTTOM_LEFT, hideProps, '<');

    tl.to(HAND_BOTTOM_RIGHT, { x: -10.1, y: -7.6, rotate: 15 }, '>');
    tl.to(PUZZLE_BOTTOM_RIGHT, hideProps, '<');

    tl.to(HIGHLIGHT, { scale: 1.4, duration: 1.4 }, '<0.3');
    tl.to(HIGHLIGHT, { opacity: 0.8 }, '<');
    tl.to(HIGHLIGHT, { opacity: 0, scale: 1.5, duration: 0.8 }, '>');
    
    tl.to(HAND_TOP_LEFT, resetProps, '<0.4');
    tl.to(HAND_TOP_RIGHT, resetProps, '<');
    tl.to(HAND_BOTTOM_LEFT, resetProps, '<');
    tl.to(HAND_BOTTOM_RIGHT, resetProps, '<');

    tl.to(PUZZLE_TOP_LEFT, showProps, '<');
    tl.to(PUZZLE_TOP_RIGHT, showProps, '<');
    tl.to(PUZZLE_BOTTOM_LEFT, showProps, '<');
    tl.to(PUZZLE_BOTTOM_RIGHT, showProps, '<');

    tl.to(HIGHLIGHT, { opacity: 0 }, '>-0.2');

    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = this.getBaseTimeline(initialize);
    tl.add(this.moveBackground(initialize), '<');

    if (!initialize) {
      tl.add(this.moveHands(), '<');
    }
    this.tl = tl;
    return this.tl;
  };

  render() {
    return <Image className={C.BASE} {...this.props} />;
  }
}
