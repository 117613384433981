import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import styled from 'styled-components';
import Button from '../../../Button';
import { InputCopyToClipboard } from '../../../Input';
import { ActionContainer, PeerSuggestionsImage } from './common/components';
import { INVITE_PEERS_TEXT } from './common/strings';
import theme from '@matterapp/matter-theme';

const ContentContainer = styled.div`
  max-width: 500px;
`;

const CopyButton = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.L};
  box-shadow: ${theme.shadows[40]};
`;

const CopyToClipboardButton = styled(InputCopyToClipboard).attrs({
  showOverlayOnCopy: true,
})`
  padding: ${theme.spacing.threeQuarters} ${theme.spacing.singleAndHalf};
  & > div {
    color: ${theme.colors.blacks[80]};
    font-size: ${theme.fontSize.S};
    text-align: center;
  }
  &:active > div {
    text-decoration: none;
  }
  & > svg path {
    fill: ${theme.colors.blacks[80]};
  }
`;

const LinkText = styled.div`
  & > span {
    color: ${theme.colors.blues[60]};
  }
  ${CopyToClipboardButton}:hover & > span {
    text-decoration: underline;
  }
`;

const ConnectYourAccountsLink = styled(Button.New).attrs({
  link: true,
})`
  text-decoration: underline;
  &:not(:hover) {
    color: ${theme.colors.blacks[80]};
  }
`;

const InvitePeers = (props) => {
  const {
    connectYourAccountsLink,
    inviteLink,
    onClickCopy,
    onClickPeersLink,
    ...otherProps
  } = props;

  const actions = (
    <ActionContainer>
      <CopyButton>
        <CopyToClipboardButton
          onCopy={onClickCopy}
          showCopyIcon
          showOverlayOnCopy
          text={
            <LinkText>
              Get an <span>invite link</span> to share
            </LinkText>
          }
          value={inviteLink}
        />
      </CopyButton>
    </ActionContainer>
  );

  const content = (
    <ContentContainer>
      <span>{INVITE_PEERS_TEXT.content}</span>
      <ConnectYourAccountsLink
        to={connectYourAccountsLink}
        onClick={onClickPeersLink}
      >
        {INVITE_PEERS_TEXT.peersLink}
      </ConnectYourAccountsLink>
    </ContentContainer>
  );

  return (
    <Base
      {...otherProps}
      actions={actions}
      bannerType={Base.bannerTypes.INFO}
      content={content}
      header={INVITE_PEERS_TEXT.header}
      image={<PeerSuggestionsImage />}
    />
  );
};

InvitePeers.propTypes = {
  /** Url to the connect your accounts page. */
  connectYourAccountsLink: PropTypes.string,
  /** The value of the invite link to copy. */
  inviteLink: PropTypes.string.isRequired,
  /** Callback if the copy link is clicked. */
  onClickCopy: PropTypes.func,
  /** Callback if the peers page link is clicked. */
  onClickPeersLink: PropTypes.func,
};

export default InvitePeers;
