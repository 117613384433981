import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 11.501c0-5.61-4.86-10.08-10.595-9.44-4.305.485-7.8 3.93-8.33 8.23a9.49 9.49 0 0 0 3.675 8.77c.775.59 1.25 1.485 1.25 2.455V23c0 1.1.9 2 2 2h1.5v-9.165L11.7 12.1a1 1 0 1 1 1.6-1.2l2.2 2.934 2.2-2.934a1 1 0 1 1 1.6 1.2l-2.8 3.734V25H18c1.1 0 2-.9 2-2v-1.488c0-.956.455-1.85 1.22-2.426A9.487 9.487 0 0 0 25 11.501zM20 28.5c0 .828-2.015 1.5-4.5 1.5s-4.5-.672-4.5-1.5 2.015-1.5 4.5-1.5 4.5.672 4.5 1.5z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
