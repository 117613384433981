import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M 0.853546 0.147442C 0.538574 -0.16753 0 0.0555534 0 0.501003L 0 17.2939C 0 18.3985 0.895416 19.2939 2 19.2939L 18.7929 19.2939C 19.2383 19.2939 19.4614 18.7553 19.1465 18.4403L 0.853546 0.147442ZM 19.1465 0.147442C 19.4614 -0.16753 20 0.0555534 20 0.501003L 20 14.0868C 20 14.5322 19.4614 14.7553 19.1465 14.4403L 12.3535 7.64744C 12.1583 7.45219 12.1583 7.1356 12.3535 6.94035L 19.1465 0.147442Z" transform="translate(2 2.70612)" fill={fill} />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
