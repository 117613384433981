import React from 'react';
import PropTypes from 'prop-types';
import {
  WEEKDAY,
  WEEKDAY_LIST_START_ON_MONDAY,
  WEEKDAY_LIST_START_ON_SUNDAY,
} from '../../common/props/weekday';
import Radio from '../Radio';

const ButtonGroup = (props) => {
  const { startOnSunday, value, ...otherProps } = props;

  const weekdayList = startOnSunday
    ? WEEKDAY_LIST_START_ON_SUNDAY
    : WEEKDAY_LIST_START_ON_MONDAY;
  const weekdays = weekdayList.map((weekday) => ({
    ...weekday,
    label: weekday.abbr,
  }));

  return <Radio.ButtonGroup {...otherProps} value={value} values={weekdays} />;
};

ButtonGroup.weekday = WEEKDAY;

ButtonGroup.defaultProps = {
  onChange: () => null,
};

ButtonGroup.propTypes = {
  /** Callback when value changes. */
  onChange: PropTypes.func,
  /** If the button group should start on Sunday. */
  startOnSunday: PropTypes.bool,
  /** Value of selected day. */
  value: PropTypes.oneOf(Object.values(WEEKDAY)),
};

export default ButtonGroup;
