import React from 'react';
import PropTypes from 'prop-types';
import * as CommentPropTypes from './prop-types';
import styled from 'styled-components';
import TimeAgo from '../TimeAgo';
import Avatar from '../Avatar';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  background: white;
  padding: 24px;
  min-width: 375px;
  white-space: pre-line;
`;

const AuthorContainer = styled.div`
  margin-right: 16px;
`;

const ContentContainer = styled.div`
  flex: 1;
  margin-top: 8px;

  color: ${() => theme.colors.blacks[90]};
  font-size: 17px;
  line-height: 24px;
`;

const AuthorFullName = styled.span`
  font-weight: bold;
`;

const MessageContainer = styled.div`
  margin-bottom: 6px;
`;

const TimeAgoContainer = styled.div`
  color: ${() => theme.colors.blacks[50]};
  line-height: 20px;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
`;

class Item extends React.PureComponent {
  render() {
    const { comment, renderProfileCard } = this.props;
    const { author, createdAt, message } = comment;
    return (
      <Container>
        <AuthorContainer>
          {renderProfileCard({
            person: author,
            anchor: <Avatar size={48} email={author.email} url={author.photoUrl} />,
          })}
        </AuthorContainer>
        <ContentContainer>
          <MessageContainer>
            {renderProfileCard({
              person: author,
              anchor: (
                <AuthorFullName>{author.fullName}</AuthorFullName>
              ),
            })}
            <span>&nbsp;</span>
            {message}
          </MessageContainer>
          <TimeAgoContainer>
            <TimeAgo date={createdAt} />
          </TimeAgoContainer>
        </ContentContainer>
      </Container>
    );
  }
}

Item.propTypes = {
  comment: CommentPropTypes.commentItem.isRequired,
  renderProfileCard: PropTypes.func.isRequired,
};

export default Item;
