import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Notifications } from '../Notification';
import { colors, media } from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  ${media.XS`
    background-color: ${colors.purples[1]};
  `}
`;

const Page = ({ children, className, renderHeader }) => (
  <Container className={className}>
    <Notifications />
    {renderHeader()}
    {children}
  </Container>
);

Page.defaultProps = {
  renderHeader: () => null,
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  renderHeader: PropTypes.func,
};

export default Page;
