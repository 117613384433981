import React from 'react';
import Animation from '../../Animation';
import Image from './Image';
import * as C from './classNames';

export default class TeamPlayer extends Animation {
  baseClass = C.BASE;

  moveButtons = () => {
    const tl = this.getDefaultTimeline({ y: 0, duration: 0.2 });
    const BUTTON_1 = this.getElement(C.JOYSTICK_1.BUTTON);
    const BUTTON_2 = this.getElement(C.JOYSTICK_2.BUTTON);

    const moveButton = (selector, yPositions = []) => {
      yPositions.forEach((y, index) => {
        tl.to(selector, { y }, index === 0 ? 'start-button' : '>');
      });
    };

    moveButton(BUTTON_1, [4, 0, 4, 0, 4, 0]);
    moveButton(BUTTON_2, [0, 4, 0, 4, 0]);

    return tl;
  };

  moveCircles = () => {
    const tl = this.getDefaultTimeline({ scale: 1, duration: 0.16 });
    const CIRCLE_BOTTOM = this.getElement(C.CIRCLE.BOTTOM);
    const CIRCLE_LEFT_1 = this.getElement(C.CIRCLE.LEFT_1);
    const CIRCLE_LEFT_2 = this.getElement(C.CIRCLE.LEFT_2);
    const CIRCLE_LEFT_3 = this.getElement(C.CIRCLE.LEFT_3);
    const CIRCLE_RIGHT = this.getElement(C.CIRCLE.RIGHT);
    const CIRCLE_TOP_1 = this.getElement(C.CIRCLE.TOP_1);
    const CIRCLE_TOP_2 = this.getElement(C.CIRCLE.TOP_2);

    const pulseCircle = (selector, scaleSizes = []) => {
      scaleSizes.forEach((scale, index) => {
        tl.to(selector, { scale }, index === 0 ? 'start-circle' : '>');
      });
    };

    pulseCircle(CIRCLE_LEFT_1, [2, 1, 1.8, 2.2, 1]);
    pulseCircle(CIRCLE_LEFT_2, [0.8, 1.6, 1, 2, 1.4, 1]);
    pulseCircle(CIRCLE_LEFT_3, [1, 1.5, 2, 1, 1.8, 1]);
    pulseCircle(CIRCLE_TOP_1, [0.6, 1.6, 1, 1.8, 0.6, 1]);
    pulseCircle(CIRCLE_TOP_2, [1.8, 2.4, 1.3, 2.2, 1]);
    pulseCircle(CIRCLE_RIGHT, [2, 1.5, 2, 1, 1.8, 1]);
    pulseCircle(CIRCLE_BOTTOM, [1.4, 1.8, 1.2, 2, 1]);

    return tl;
  };

  moveJoystick1 = () => {
    const tl = this.getDefaultTimeline({ transformOrigin: '50% 100%' });
    const JOYSTICK_1 = this.getElement(C.JOYSTICK_1.GROUP);
    const JOYSTICK_STICK_1 = this.getElement(C.JOYSTICK_1.STICK);

    tl.to(JOYSTICK_1, { y: -10, duration: 0.3 }, 'joystick1');
    tl.to(JOYSTICK_STICK_1, { rotate: -20, duration: 0.3 }, '<');
    tl.to(JOYSTICK_STICK_1, { rotate: 20, duration: 0.4 }, '>');
    tl.to(JOYSTICK_STICK_1, { rotate: 0, duration: 0.3 }, '>');
    tl.to(JOYSTICK_1, { y: 0, duration: 0.3 }, 'joystick1+=0.5');
    return tl;
  };

  moveJoystick2 = () => {
    const tl = this.getDefaultTimeline({ transformOrigin: '100% 100%' });
    const JOYSTICK_2 = this.getElement(C.JOYSTICK_2.GROUP);
    const JOYSTICK_STICK_2 = this.getElement(C.JOYSTICK_2.STICK);

    tl.to(JOYSTICK_2, { y: -15, duration: 0.4 }, 'joystick2');
    tl.to(JOYSTICK_STICK_2, { rotate: 40, duration: 0.4 }, '<');
    tl.to(JOYSTICK_STICK_2, { rotate: 0, duration: 0.4 }, '>');
    tl.to(JOYSTICK_2, { y: 0, duration: 0.3 }, 'joystick2+=0.6');
    return tl;
  };

  playAnimation = ({ initialize } = {}) => {
    const tl = this.getBaseTimeline(initialize);

    if (!initialize) {
      tl.add(this.moveJoystick1(), '<');
      tl.add(this.moveJoystick2(), '<');
      tl.add(this.moveCircles(), '<');
      tl.add(this.moveButtons(), '<');
    }

    this.tl = tl;
    return this.tl;
  };

  render() {
    return <Image className={C.BASE} {...this.props} />;
  }
}
