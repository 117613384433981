import React, { useState, useEffect } from 'react';
import { Modal, Input } from '@matterapp/matter-ui';
import resendFulfilledReward from 'graphql-queries/mutations/rewards/resendFulfilledReward';
import { useMutation } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { Typography } from '@mui/material';
import validator from 'validator';

const StyledModalBody = styled(Modal.Body)(() => ({
  padding: theme.spacing.double,
  overflow: 'visible',
  '& > div:first-child': {
    overflowY: 'visible'
  },
  h5: {
    color: theme.colors.blacks[80],
    fontSize: theme.fontSize.base,
    fontWeight: theme.fontWeight.medium,
    marginBottom: '5px'
  }
}));

export default function ResendRewardModal(props) {
  const { rewardRequestId, tenantId, isOpen, onClose } = props;
  const [email, setEmail] = useState();

  const [mutation, { loading, data: sentRewards }] = useMutation(resendFulfilledReward);

  const canSave = !!email && validator.isEmail(email);

  function resetAndClose() {
    setEmail(null);
    onClose();
  }

  useEffect(() => {
    if (!loading && sentRewards) {
      Toast.success('Reward was successfully sent.');

      resetAndClose();
    }
  }, [loading, sentRewards]);
 
  return (
    <Modal 
      isOpen={isOpen} 
      onClose={resetAndClose}
      size="large"
    >
      <Modal.Header
        header="Resend reward"
        right={<Modal.Buttons.CloseIcon onClick={resetAndClose} />}
      />
      <StyledModalBody>
        <Typography variant='body2' sx={{ mb: 2 }}>
          Need another copy of your reward? Click send to be sent another email. If you still can’t find it, we recommend using a personal email address.
        </Typography>
        <Typography variant='h7'>
          <b>Enter email:</b>
        </Typography>
        <Input
          style={{ marginTop: '16px' }}
          onChange={(e, { value }) => setEmail(value)}
          value={email}
          text="Email"
          fluid
        />
      </StyledModalBody>
      <Modal.Footer.WithCancelSave
        canClickSave={canSave && !loading}
        onClickCancel={resetAndClose}
        saveLabel={loading ? 'Sending...' : 'Send'}
        onClickSave={() => {
          mutation({
            variables: {
              tenantId,
              rewardRequestId,
              email
            }
          });
        }}
        sendIsLoading={loading}
      />
    </Modal>
  );
}
