import React from 'react';
import Animation from '../Animation';
import styled from 'styled-components';
import gsap from 'gsap';
import { SVG } from '../../../components';
import theme from '@matterapp/matter-theme';

const HandLight = styled.path`
  fill: ${() => theme.colors.white};
`;

const HandDark = styled.path`
  fill: ${() => theme.colors.purple};
`;

const TopLeftHand = styled.g``;
const TopLeftPuzzle = styled.path`
  fill: ${() => theme.colors.reds[40]};
`;

const TopRightHand = styled.g``;
const TopRightPuzzle = styled.path`
  fill: ${() => theme.colors.green};
`;

const BottomLeftHand = styled.g``;
const BottomLeftPuzzle = styled.path`
  fill: #adaee2;
`;

const BottomRightHand = styled.g``;
const BottomRightPuzzle = styled.path`
  fill: ${() => theme.colors.blues[40]};
`;

const Triangle = styled.path`
  fill: ${() => theme.colors.white};
`;

const Circle = styled.path`
  opacity: 0.4;
  fill: ${() => theme.colors.white};
  enable-background: new;
`;

const Plus = styled.path`
  opacity: 0.4;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: ${() => theme.colors.white};
  enable-background: new;
`;

const DEFAULT_HEIGHT = 192;
const DEFAULT_WIDTH = 248;

export default class KudosAmazingJob extends Animation {
  static defaultProps = {
    height: DEFAULT_HEIGHT,
    width: DEFAULT_WIDTH,
  };

  /**
   * Displays the background circles.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  displayCircles = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { scale: 0, opacity: 0, transformOrigin: 'center' };
    const midProps = { scale: 2.4, opacity: 0.8, duration: 0.1 };
    const resetProps = { scale: 1, opacity: 0.3, duration: 0.05 };

    tl.to(this.circleLeftRef, initialProps);
    tl.to(this.circleFullTopRef, initialProps);
    tl.to(this.circleFullRightRef, initialProps);
    tl.to(this.circleFullBottomRef, initialProps);
    tl.to(this.circleFullLeftRef, initialProps);
    tl.to(this.plusRef, {
      rotation: 720,
      opacity: 0,
      transformOrigin: 'center',
    });

    if (!initialize) {
      tl.to(this.circleFullLeftRef, initialProps, '>');

      tl.to(this.circleLeftRef, midProps).to(this.circleLeftRef, resetProps);
      tl.to(this.circleFullRightRef, midProps).to(
        this.circleFullRightRef,
        resetProps
      );
      tl.to(this.circleFullLeftRef, midProps).to(
        this.circleFullLeftRef,
        resetProps
      );
      tl.to(this.circleFullTopRef, midProps).to(
        this.circleFullTopRef,
        resetProps
      );
      tl.to(this.circleFullBottomRef, midProps).to(
        this.circleFullBottomRef,
        resetProps
      );
      tl.to(
        this.plusRef,
        { scale: 1.4, rotation: 360, opacity: 0.8, duration: 0.4 },
        '<-0.4'
      );
      tl.to(
        this.plusRef,
        { scale: 1, rotation: 0, opacity: 0.3, duration: 0.6 },
        '<'
      );
      return tl;
    }
  };

  /**
   * Displays the left triangles after the puzzle pieces come together.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  displayTriangles = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const resetProps = { x: 0, y: 0, opacity: 1, duration: 0.2 };
    tl.to(this.triangleTopRef, { x: 20, y: 20, opacity: 0 });
    tl.to(this.triangleMiddleRef, { x: 40, y: 0, opacity: 0 });
    tl.to(this.triangleBottomRef, { x: 20, y: -20, opacity: 0 });

    if (!initialize) {
      tl.to(this.triangleTopRef, resetProps, '< 0.1');
      tl.to(this.triangleMiddleRef, resetProps, '<');
      tl.to(this.triangleBottomRef, resetProps, '<');
    }
    return tl;
  };

  /**
   * Moves hands to join the puzzle pieces.
   * @param { Object } initializeProps: Object containing `initialize` value if animation should be initilized.
   * @returns { Object } The animation timeline.
   */
  moveHands = ({ initialize }) => {
    const tl = gsap.timeline({ defaults: { duration: 0 } });
    const initialProps = { transformOrigin: 'center', duration: 0.2 };
    const resetProps = { x: 0, y: 0, rotation: 0, duration: 0.4 };
    if (!initialize) {
      tl.to(
        this.topLeftHandRef,
        {
          x: 24,
          y: 10,
          rotation: 15,
          ...initialProps,
        },
        '<'
      );
      tl.to(
        this.topRightHandRef,
        {
          x: -24,
          y: 10,
          rotation: -15,
          ...initialProps,
        },
        '<'
      );
      tl.to(
        this.bottomLeftHandRef,
        {
          x: 27,
          y: -10,
          rotation: -15,
          ...initialProps,
        },
        '<'
      );
      tl.to(
        this.bottomRightHandRef,
        {
          x: -30,
          y: -10,
          rotation: 15,
          ...initialProps,
        },
        '<'
      );
    }
    tl.to(this.topLeftHandRef, resetProps, '<0.5');
    tl.to(this.topRightHandRef, resetProps, '<');
    tl.to(this.bottomLeftHandRef, resetProps, '<');
    tl.to(this.bottomRightHandRef, resetProps, '<');
    return tl;
  };

  playAnimation = ({ initialize, isMounting } = {}) => {
    if (this._isMounted || isMounting) {
      const tl = gsap.timeline({ defaults: { duration: 0 } });
      tl.add(this.moveHands({ initialize }));
      tl.add(this.displayTriangles({ initialize }), '<');
      tl.add(this.displayCircles({ initialize }), '<');
      this.tl = tl;
      return tl;
    }
  };

  resetAnimation = () => {
    return this.playAnimation({ initialize: true });
  };

  render() {
    const { height, width, ...otherProps } = this.props;

    return (
      <SVG
        {...otherProps}
        height={height}
        width={width}
        viewBox={`0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`}
      >
        <TopLeftHand ref={(ref) => (this.topLeftHandRef = ref)}>
          <HandLight
            d='M114.6,31.2l-16.9-8.4c-2.3-1.2-3.3-4-2.1-6.3c1.2-2.3,4-3.3,6.3-2.1c0.2,0.1,0.4,0.2,0.6,0.3L118,25.4
              c1.5,1,1.9,3.1,0.8,4.6C117.9,31.5,116.1,31.9,114.6,31.2z'
          />
          <TopLeftPuzzle
            d='M137,58.4c-0.8,0.3-1.6,0.5-2.5,0.6c-5.4,0.5-10.6-3-11.9-8.3c-1.5-5.8,2-11.8,7.9-13.4
              c0.3-0.1,0.5-0.1,0.8-0.2c1.2-0.2,1.9-1.3,1.6-2.5l-3.5-13c-0.4-1.5-1.9-2.4-3.4-2L78.6,32.4c-3.4,0.9-5.5,4.5-4.5,7.9l12.7,47.5
              c0.4,1.5,1.9,2.3,3.4,2l11.9-3.2c1.9-0.5,3.8,0.8,4,2.8c0.1,0.8,0.3,1.7,0.6,2.5c1.8,4.8,6.9,7.8,12,7.1c6.6-1,10.8-7.5,9.1-13.8
              c-0.2-0.5-0.3-1.1-0.6-1.6c-0.8-1.8,0.3-3.8,2.1-4.3l12.1-3.2c1.3-0.3,2.1-1.7,1.8-3l-3.5-13.2C139.3,58.6,138.1,58,137,58.4z'
          />
          <HandLight
            d='M118,50.8l-9.5-21.4l-3.6-11.1c-0.9-2.8-3.2-4.9-5.8-5.7l-7.9-76.9H61.3l20.4,94.7c0,0.2,0,0.5,0.1,0.7
              l5,23.5c0.3,1.5,1.6,2.5,3.1,2.5c0.2,0,0.4,0,0.7-0.1c1.7-0.4,2.8-2,2.4-3.7l-2.6-12.2l0.8-0.3l5.5,18.9c0.4,1.4,1.6,2.2,3,2.2
              c0.3,0,0.6,0,0.9-0.1c1.7-0.5,2.6-2.2,2.1-3.9l-5.6-19.1l0.9-0.3l7.5,20.8c0.5,1.3,1.7,2.1,3,2.1c0.4,0,0.7-0.1,1.1-0.2
              c1.6-0.6,2.5-2.4,1.9-4l-7.4-20.6l0.8-0.3l7.5,17c0.5,1.2,1.7,1.9,2.9,1.9c0.4,0,0.9-0.1,1.3-0.3C118,54.3,118.7,52.4,118,50.8z'
          />
        </TopLeftHand>
        <TopRightHand ref={(ref) => (this.topRightHandRef = ref)}>
          <HandDark
            d='M238.3-64.3h-30.4l-7.7,78.7c-2.6,0.8-4.8,2.8-5.8,5.7l-6.7,20.4l4.7,1.5l1.3-4.1l17.1,5.6l-1.3,4.1l4.5,1.5
              l2-9.9L238.3-64.3z'
          />
          <TopRightPuzzle
            d='M173.5,79.7c0.1-0.8,0.4-1.6,0.8-2.5c2.2-4.9,7.9-7.6,13.1-6.1c5.8,1.7,9.2,7.7,7.6,13.5
              c-0.1,0.3-0.1,0.5-0.2,0.8c-0.4,1.1,0.2,2.3,1.3,2.6l13,3.5c1.5,0.4,3-0.5,3.4-2l12.7-47.5c0.9-3.4-1.1-6.9-4.5-7.9l-47.5-12.7
              c-1.5-0.4-3,0.5-3.4,1.9l-3.2,11.9c-0.5,1.9-2.6,2.9-4.4,2.1c-0.8-0.3-1.6-0.6-2.5-0.7c-5.1-0.9-10.2,2-12.1,6.9
              c-2.4,6.3,1.1,13.1,7.4,14.8c0.5,0.2,1.1,0.2,1.6,0.3c1.9,0.2,3.2,2.1,2.7,4l-3.2,12.1c-0.4,1.3,0.4,2.7,1.8,3l13.2,3.5
              C172.2,81.6,173.3,80.9,173.5,79.7z'
          />
          <HandDark
            d='M181.2,27.3l15.5-10.8c2.1-1.5,5.1-1,6.6,1.2s1,5.1-1.2,6.6c-0.2,0.1-0.4,0.2-0.6,0.3L184.6,33
              c-1.7,0.8-3.7,0.2-4.5-1.5C179.4,30,179.9,28.2,181.2,27.3z'
          />
        </TopRightHand>
        <BottomLeftHand ref={(ref) => (this.bottomLeftHandRef = ref)}>
          <HandLight
            d='M121.4,151.2l-4.7-1l-0.9,4.2l-17.6-3.7l0.9-4.2l-4.9-1l-4.4,21c-0.6,2.9,0.3,5.8,2.2,7.8L38.5,287.7h38.8
              l40.6-125.9L121.4,151.2z'
          />
          <BottomLeftPuzzle
            d='M128.1,111.5c-0.2,0.8-0.4,1.6-0.8,2.5c-2.2,4.9-7.9,7.6-13.1,6.1c-5.8-1.7-9.2-7.7-7.6-13.5
              c0.1-0.3,0.1-0.5,0.2-0.8c0.4-1.1-0.2-2.3-1.3-2.6l-13-3.5c-1.5-0.4-3,0.5-3.4,2l-12.7,47.5c-0.9,3.4,1.1,6.9,4.5,7.9l47.5,12.7
              c1.5,0.4,3-0.5,3.4-1.9l3.2-11.9c0.5-1.9,2.6-2.9,4.4-2.1c0.8,0.3,1.6,0.6,2.5,0.7c5.1,0.9,10.2-2,12.1-6.9
              c2.4-6.3-1.1-13.1-7.4-14.8c-0.5-0.2-1.1-0.2-1.6-0.3c-1.9-0.2-3.2-2.1-2.7-4l3.2-12.1c0.4-1.3-0.4-2.7-1.8-3l-13.2-3.5
              C129.5,109.6,128.4,110.4,128.1,111.5z'
          />
          <HandLight
            d='M87.8,150.4l10.4,15.8c1.4,2.2,0.8,5.1-1.3,6.5c-2.2,1.4-5.1,0.8-6.5-1.3c-0.1-0.2-0.2-0.4-0.3-0.6l-8-17.1
              c-0.8-1.7-0.1-3.7,1.6-4.4C85.2,148.5,86.9,149.1,87.8,150.4z'
          />
        </BottomLeftHand>
        <BottomRightHand ref={(ref) => (this.bottomRightHandRef = ref)}>
          <HandDark
            d='M224,155.5l-8,17.1c-1.1,2.4-3.9,3.4-6.3,2.3s-3.4-3.9-2.3-6.3c0.1-0.2,0.2-0.4,0.3-0.6l10.4-15.8
              c1-1.5,3.1-2,4.6-0.9C224.2,152.2,224.7,154,224,155.5z'
          />
          <BottomRightPuzzle
            d='M164.6,132.9c0.8-0.3,1.6-0.5,2.5-0.6c5.4-0.5,10.6,3.1,11.9,8.3c1.5,5.8-2,11.8-7.9,13.4
              c-0.3,0.1-0.5,0.1-0.8,0.2c-1.2,0.2-1.9,1.3-1.6,2.5l3.5,12.9c0.4,1.5,1.9,2.4,3.4,1.9l47.5-12.7c3.4-0.9,5.4-4.4,4.5-7.8
              l-12.7-47.5c-0.4-1.5-1.9-2.4-3.4-2l-11.9,3.2c-1.9,0.5-3.8-0.8-4-2.8c-0.1-0.8-0.3-1.7-0.6-2.5c-1.8-4.8-6.9-7.8-12-7.1
              c-6.6,1-10.8,7.5-9.1,13.8c0.2,0.5,0.3,1.1,0.6,1.6c0.8,1.8-0.3,3.8-2.1,4.3l-12.1,3.2c-1.3,0.3-2.1,1.7-1.8,3l3.5,13.2
              C162.3,132.7,163.5,133.3,164.6,132.9z'
          />
          <HandDark
            d='M265.3,287.7L214,176.1c1.9-2,2.8-4.9,2.2-7.8l-2.4-11.4l-2.5-23.3c-0.2-1.7-1.7-3-3.5-2.8
              c-1.7,0.2-3,1.7-2.8,3.5l2,18.5l-0.8,0.2l-3.9-21.5c-0.3-1.7-1.9-2.8-3.6-2.5c-1.7,0.3-2.8,1.9-2.5,3.6l3.9,21.7l-0.9,0.2
              l-4.7-19.3c-0.4-1.7-2.1-2.7-3.8-2.3c-1.7,0.4-2.7,2.1-2.3,3.8l4.6,19.1l-0.9,0.2l-3.9-11.9c-0.5-1.6-2.3-2.5-4-2
              c-1.6,0.5-2.5,2.3-2,4l2.3,7.1l0,0l0.1,0.2l5,15.6c0.1,0.2,0.2,0.4,0.3,0.6l37.8,118.3H265.3z'
          />
        </BottomRightHand>
        <Triangle
          ref={(ref) => (this.triangleMiddleRef = ref)}
          d='M68.7,96l-33.5,5.9V90.1L68.7,96z'
        />
        <Triangle
          ref={(ref) => (this.triangleTopRef = ref)}
          d='M67.9,86.7L47.1,73.4l5.7-6.2L67.9,86.7z'
        />
        <Triangle
          ref={(ref) => (this.triangleBottomRef = ref)}
          d='M67.9,105.3l-20.7,13.2l5.7,6.2L67.9,105.3z'
        />
        <Circle
          ref={(ref) => (this.circleLeftRef = ref)}
          d='M20.5,141.1c-5.8,0-10.5-4.7-10.5-10.5c0-5.8,4.7-10.5,10.5-10.5s10.5,4.7,10.5,10.5
            C31,136.4,26.2,141.1,20.5,141.1z M20.5,125c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6
            S23.6,125,20.5,125z'
        />
        <Circle
          ref={(ref) => (this.circleFullTopRef = ref)}
          d='M51.1,26.2c3.2,0,5.8-2.6,5.8-5.8s-2.6-5.8-5.8-5.8c-3.2,0-5.8,2.6-5.8,5.8
            S47.9,26.2,51.1,26.2z'
        />
        <Circle
          ref={(ref) => (this.circleFullRightRef = ref)}
          d='M232.2,110.7c3.2,0,5.8-2.6,5.8-5.8s-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8
            S229,110.7,232.2,110.7z'
        />
        <Circle
          ref={(ref) => (this.circleFullLeftRef = ref)}
          d='M5.8,166c3.2,0,5.8-2.6,5.8-5.8s-2.6-5.8-5.8-5.8S0,156.9,0,160.1S2.6,166,5.8,166z'
        />
        <Plus
          ref={(ref) => (this.plusRef = ref)}
          d='M234.1,83.3c0,1.3,1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4v-4.9h4.9c1.3,0,2.4-1.1,2.4-2.4
            c0-1.3-1.1-2.4-2.4-2.4h-4.9v-4.9c0-1.3-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4v4.9h-4.9c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4
            h4.9V83.3z'
        />
        <Circle
          ref={(ref) => (this.circleFullBottomRef = ref)}
          d='M51.7,169.9c3.2,0,5.8-2.6,5.8-5.8s-2.6-5.8-5.8-5.8c-3.2,0-5.8,2.6-5.8,5.8
            S48.5,169.9,51.7,169.9z'
        />
      </SVG>
    );
  }
}
