import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Clickable } from '../Clickable/Clickable';
import Panel from '../Panel';

const HeaderContainer = styled(Clickable)`
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
`;

const AvatarHeader = (props) => {
  const {
    header,
    isInactive,
    subheader,
    photoUrl,
    badgeUrl,
    equalPadding,
    showMenu,
    deleteProps,
    canClickHeader,
    onClickAvatar,
    onClickDelete,
    onClickRemove,
  } = props;
  const wrappedHeader = canClickHeader ? <HeaderContainer onClick={onClickAvatar}>{header}</HeaderContainer> : header;

  return (
    <Panel.Header.WithAvatar
      showMenu={showMenu}
      equalPadding={equalPadding}
      header={wrappedHeader}
      isInactive={isInactive}
      deleteProps={deleteProps}
      subheader={subheader}
      photoUrl={photoUrl}
      badgeUrl={badgeUrl}
      onClickAvatar={onClickAvatar}
      onClickDelete={onClickDelete}
      onClickRemove={onClickRemove}
    />
  );
};

AvatarHeader.defaultProps = {
  equalPadding: false,
  canClickHeader: false,
  badgeUrl: null,
};

AvatarHeader.propTypes = {
  header: PropTypes.node,
  subheader: PropTypes.node,
  photoUrl: PropTypes.string,
  equalPadding: PropTypes.bool,
  canClickHeader: PropTypes.bool,
  onClickAvatar: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default AvatarHeader;
