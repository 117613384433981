import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { validateEmail } from '../../../../form-validations';
import theme from '@matterapp/matter-theme';
import {
  Button,
  SimpleConfirmModal,
  Form,
} from '@matterapp/matter-ui';

const ButtonContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.singleAndHalf};
`;

const UpdateButton = styled(Button.Simple.Primary)`
  flex: 1 1 100%;
  padding: ${theme.spacing.half} 0;
  margin-left: ${theme.spacing.half};
`;

const CancelButton = styled(Button.Simple)`
  flex: 1 1 100%;
  padding: ${theme.spacing.half} 0;
  margin-right: ${theme.spacing.half};
`;

const UpdateInfoModal = ({
  invitedPeerEmail,
  isUpdateInfoModalOpen,
  handleCloseUpdateInfoModal,
  handleUpdateEmail
}) => (
  <SimpleConfirmModal
    isOpen={isUpdateInfoModalOpen}
    heading="Update Info"
    subheading={
      <div>
        Your feedback request to <b>{invitedPeerEmail}</b> has
        <span style={{ color: theme.colors.reds[40], fontWeight: theme.fontWeight.semiBold}}> bounced </span>
        and was not delivered. Check for typos and update to resend.
      </div>}
    form={
      <Formik
        initialValues={{ email: '' }}
        validate={(values) => {
          return validateEmail(values.email);
        }}
        onSubmit={(values) => {
          handleUpdateEmail(values);
        }}
      >
        {({
          values,
          handleChange, 
          handleSubmit,
          isValid,
          dirty,
        }) => {
          return (
            <Form onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }} className="update-info-modal">
              <Field
                fluid
                name="email"
                type="email"
                shortInline
                size="medium"
                placeholder="Enter a new email"
                onChange={handleChange}
                value={values.email}
                component={Form.Input}
              />
              <ButtonContainer>
                <CancelButton
                  type="button"
                  size={'M'} 
                  color={'black'}
                  onClick={handleCloseUpdateInfoModal}
                >
                  Cancel
                </CancelButton>
                <UpdateButton
                  type="button"
                  size="M"
                  disabled={!(isValid && dirty)}
                  onClick={handleSubmit}
                >
                  Update
                </UpdateButton>
              </ButtonContainer>
            </Form>
          );
        }}
      </Formik>
    }
    onCancel={handleCloseUpdateInfoModal}
  />
);

UpdateInfoModal.propTypes = {
  invitedPeerEmail: PropTypes.string.isRequired,
  isUpdateInfoModalOpen: PropTypes.bool.isRequired,
  handleCloseUpdateInfoModal: PropTypes.func.isRequired,
  handleUpdateEmail: PropTypes.func.isRequired,
};

export default UpdateInfoModal;
