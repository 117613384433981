import { useEffect, useState } from 'react';

export const useThanks = (params) => {
  let thankTimeout = null;
  const { canSeeThanks, canThank, thanks, recipient, onClickThank } = params.thanks;
  const { currentUser } = params;
  const isThanked = thanks?.length && thanks.some(thank => thank.author?.id === currentUser.id);
  const [thanked, setThanked] = useState(isThanked || false);
  const [isThanking, setIsThanking] = useState(false);
  const [showButton, setShowButton] = useState(canThank);
  const thankName = recipient && (recipient.firstName || recipient.fullName);

  useEffect(() => {
    if (!showButton && canThank) {
      setShowButton(canThank);
    }
    return () => {
      if (!showButton) {
        clearTimeout(thankTimeout);
      }
    };
  }, [canThank]);

  return {
    canSeeThanks,
    canThank,
    disabled: !(canSeeThanks || canThank),
    isThanked: thanked,
    isThanking,
    showButton,
    thankButtonProps: {
      onClick: (e) => {
        setThanked(true);
        setIsThanking(true);
        setShowButton(false);
        onClickThank(e, params);
        thankTimeout = setTimeout(() => {
          setIsThanking(false);
        }, 2000);
      },
    },
    thankName,
  };
};

export const useComments = (params) => {
  const {
    autoUpdateComments,
    currentUser,
    comments: paramComments,
    id,
    onClickDeleteComment,
    onSendComment,
  } = params;
  const [comments, setComments] = useState(paramComments);

  useEffect(() => {
    if (comments.length !== paramComments.length) {
      setComments(paramComments);
    }
  }, [paramComments]);

  return {
    comments,
    commentsProps: {
      comments,
      currentUser,
      onClickSend: (e, props) => {
        const trimmedComment = props.value.trim();

        onSendComment(e, {
          ...params,
          ...props,
          value: trimmedComment,
          feedItemId: id,
          comment: props.comment,
        });
      },
      onClickDeleteComment: (e, props) => {
        onClickDeleteComment(props.comment.id);
        if (autoUpdateComments) {
          setComments(comments.filter((com) => com.id !== props.comment.id));
        }
      },
    },
  };
};

export const useFeedItemComment = (params) => {
  const { append, autoFocus, showBackground, eventTime, thanks, expanded, currentUser } = params;
  const [isOpen, setIsOpen] = useState(expanded);
  const { comments, commentsProps } = useComments(params);
  const numberOfComments = comments.length || 0;
  const hasComments = !!numberOfComments;
  return {
    comments,
    commentsProps,
    eventTime,
    hasComments,
    autoFocus,
    isOpen,
    mainContainerProps: {
      showBackground,
    },
    numberOfComments,
    onClickClose: () => setIsOpen(false),
    toggleShowComments: () => setIsOpen(!isOpen),
    toolbarProps: {
      append,
    },
    thanksProps: {thanks, currentUser}
  };
};
