import styled from 'styled-components';
import { ClickableLink, Button, Input, Modal } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';
import { Box } from '@mui/material';

export const Example = styled(ClickableLink)(() => ({
  whiteSpace: 'nowrap',
  marginLeft: theme.spacing.single
}));

export const LineWrapper = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});

export const SubText = styled.p(() => ({
  fontSize: theme.fontSize.XS,
  margin: 0,
  lineHeight: '24px'
}));

export const DropdownContainer = styled(Box)(({ theme }) => ({
  width: '400px',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const SubHeader = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.fontSize.S,
  fontWeight: theme.fontWeight.semiBold,
  marginBottom: theme.spacing.half
}));

export const ActionButton = styled(Button.Primary)(() => ({
  whiteSpace: 'nowrap',
  fontSize: theme.fontSize.base,
  lineHeight: theme.fontSize.base,
  padding: `${theme.spacing.half} ${theme.spacing.single}`,
  minHeight: 0,
  backgroundColor: theme.colors.blues[5],
  marginLeft: theme.spacing.single
}));

export const Section = styled.div(() => ({
  marginTop: theme.spacing.double
}));

export const SubSection = styled.div(() => ({
  marginTop: theme.spacing.single
}));

export const MainText = styled.p({
  margin: 0,
  maxWidth: '360px'
});

export const AllowanceBlock = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing.double,
  p: {
    color: theme.colors.blacks[80],
    fontSize: theme.fontSize.base,
    fontWeight: 'bold',
    lineHeight: '24px'
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));

export const CustomAllowanceBlock = styled('div')(() => ({
  marginBottom: theme.spacing.double,
  marginTop: theme.spacing.half
}));

export const InputWrapper = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '> span': {
    marginRight: theme.spacing.half,
    fontSize: '12px'
  },
  '.allowanceInputError': {
    position: 'absolute'
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    width: '100%',
    '> div': {
      width: '100%'
    },
    '> span': {
      display: 'none'
    },
  }
}));

export const ResetButton = styled(ClickableLink)(() => ({
  position: 'absolute',
  top: `-${theme.spacing.doubleAndHalf}`,
  right: `-${theme.spacing.threeQuarters}`,
  fontSize: theme.fontSize.base,
  padding: theme.spacing.threeQuarters,
  zIndex: 1,
  '&:not(:hover):not(:active)': {
    color: theme.colors.blacks[50]
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    top: `-${theme.spacing.half}`,
  }
}));

export const StyledInput = styled(Input)(() => ({
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    marginTop: theme.spacing.double,
    width: '100%'
  }
}));

export const CustomStyledInput = styled(StyledInput)(() => ({
  padding: 0,
  width: '62px',
  height: '54px',
  textAlign: 'center',
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    marginTop: theme.spacing.double,
    width: '100%'
  }
}));

export const TemplateButtonWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  '> button:first-child': {
    marginBottom: '10px'
  }
}));

export const CustomAmountButton = styled(Button)(() => ({
  fontSize: theme.fontSize.base,
  lineHeight: theme.fontSize.base,
  padding: theme.spacing.half,
  minHeight: 0
}));

export const CustomAmountWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  alignItems: 'center',
  marginTop: theme.spacing.double,
  p: {
    fontSize: theme.fontSize.S,
    margin: 0,
    marginRight: theme.spacing.single
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));

export const StyledModalBody = styled(Modal.Body)(() => ({
  padding: theme.spacing.double,
  overflow: 'visible',
  '& > div:first-child': {
    overflowY: 'visible'
  },
  h5: {
    color: theme.colors.blacks[80],
    fontSize: theme.fontSize.base,
    fontWeight: theme.fontWeight.medium,
    marginBottom: '5px'
  }
}));
