import React from 'react';
import PropTypes from 'prop-types';

import theme from '@matterapp/matter-theme';
import omitStyled from '../../libs/omitStyled';

const transitionParams = '150ms ease';

const TabContainerDefaults = {
  isActive: false,
  color: theme.colors.blacks[50],
  hoverColor: theme.colors.purple,
  activeColor: theme.colors.purple,
  borderSize: '2px',
  hoverBorderColor: theme.colors.purple,
  activeBorderColor: theme.colors.purple,
};

const TabContainer = omitStyled('div', Object.keys(TabContainerDefaults))`
  height: 100%;
  box-sizing: border-box;
  font-weight: bold;
  user-select: none;

  > * {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition:
      transform ${transitionParams},
      color ${transitionParams};

    color: ${({ color }) => color};
  }

  cursor: pointer;
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;

  transition: color ${transitionParams}, border-bottom ${transitionParams}, border-top ${transitionParams};
  flex-grow: 1;

  svg path {
    transition: fill ${transitionParams};
    fill: ${({ color }) => color};
  }

  &:hover {
    border-top: ${({ borderSize }) => borderSize} solid transparent;
    border-bottom: ${({ borderSize }) => borderSize} solid ${({ hoverBorderColor }) => hoverBorderColor};

    svg path {
      fill: ${({ hoverColor }) => hoverColor};
    }

    > * {
      transform: translateY(-2px);
      color: ${({ hoverColor }) => hoverColor};
    }
  }

  > *:active {
    text-decoration: none;
  }


  ${({
    isActive,
    borderSize,
    activeColor,
    activeBorderColor,
  }) => isActive && `
    color: ${activeColor} !important;
    border-top: ${borderSize} solid transparent;
    border-bottom: ${borderSize} solid ${activeBorderColor} !important;
    border-bottom: 2px solid red;

    svg path {
      fill: ${activeColor};
    }

    > * {
      color: inherit;
    }
  `}

  ${() => theme.media.medium`
    flex-grow: 0;
  `}
`;

const Tab = ({
  children,
  onActivate,
  ...others
}) => (
  <TabContainer
    onClick={onActivate}
    role="Menuitem"
    {...others}
  >
    {children}
  </TabContainer>
);

Tab.defaultProps = {
  isActive: false,
  className: null,
  ...TabContainerDefaults,
};

Tab.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  onActivate: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,

  color: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  borderSize: PropTypes.string,
  hoverBorderColor: PropTypes.string,
  activeBorderColor: PropTypes.string,
};

export default Tab;
