import React, { useState, useEffect, useRef } from 'react';
import { OpenToCloseWrapper } from './styles';
import Customization from './Customization';

const OpenToClose = (props) => {
  const { isOpen } = props;
  const bodyRef = useRef();
  const [bodyHeight, setBodyHeight] = useState();

  const setHeight = () => {
    setBodyHeight(bodyRef.current.clientHeight);
  };

  useEffect(() => {
    setHeight();
  }, [bodyRef]);

  useEffect(() => {
    window.addEventListener('resize', setHeight);
    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  return (
    <OpenToCloseWrapper
      id={'recurring-modal-body'}
      ref={bodyRef}
      isOpen={isOpen}
      height={bodyHeight}
    >
      <Customization {...props} />
    </OpenToCloseWrapper>
  );
};

export default OpenToClose;