import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FEEDBACK_RITUAL_PANEL_TEXT, TextReplace } from '../sharedConsts';
import TimezoneTooltip from 'components/TimezoneTooltip/TimezoneTooltip';

const TextWithTimezone = (props) => {
  const { time, timezone, ...otherProps } = props;
  const timezoneText = timezone
    ? `, ${moment.tz(new Date(), timezone).format('z')}`
    : '';
  return (
    <>
      <TextReplace {...otherProps} replaceText={`${time}${timezoneText}`} />
      <TimezoneTooltip />
    </>
  );
};

TextWithTimezone.Kickoff = (props) => (
  <TextWithTimezone
    text={FEEDBACK_RITUAL_PANEL_TEXT.KICKOFF_SUB_HEADER}
    {...props}
  />
);

TextWithTimezone.Reminder = (props) => (
  <TextWithTimezone
    text={FEEDBACK_RITUAL_PANEL_TEXT.REMINDER_SUB_HEADER}
    {...props}
  />
);

TextWithTimezone.RitualRecap = (props) => (
  <TextWithTimezone
    text={FEEDBACK_RITUAL_PANEL_TEXT.RITUAL_RECAP_SUB_HEADER}
    {...props}
  />
);

TextWithTimezone.Stats = (props) => (
  <TextWithTimezone
    text={FEEDBACK_RITUAL_PANEL_TEXT.STATS_MESSAGE_SUB_HEADER}
    {...props}
  />
);

TextWithTimezone.propTypes = {
  time: PropTypes.string,
  timezone: PropTypes.string,
};

export default TextWithTimezone;
