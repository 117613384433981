import React from 'react';
import PropTypes from 'prop-types';
import { skillEntityPropType } from '@matterapp/skills';
import { propType } from 'graphql-anywhere';
import { Skill, Confirm, ButtonNew as Button } from '@matterapp/matter-ui';
import { SKILLS_QUERY } from 'graphql-queries/queries';
import { Resources } from '@matterapp/routing';

class PickedSkillsList extends React.PureComponent {
  isLoading() {
    const { skillsData } = this.props;
    const { loading, currentUser } = skillsData;
    const initialCheck = loading || !currentUser;
    if (initialCheck) { return true; }
    const { pickedSkillDetails } = currentUser;
    return !pickedSkillDetails;
  }

  renderRemoveSkillConfirmDialog() {
    const {
      isRemoveSkillConfirmDialogOpen,
      handleCloseRemoveSkillConfirmDialog,
      handleConfirmRemoveSkill,
      skillToRemove,
    } = this.props;
    if (!skillToRemove) { return null; }
    return (
      <Confirm.Old
        isOpen={isRemoveSkillConfirmDialogOpen}
        buttonLeft={
          <Button onClick={handleConfirmRemoveSkill} primary size="small">
            Yes, remove
          </Button>
        }
        buttonRight={
          <Button
            basic
            onClick={handleCloseRemoveSkillConfirmDialog}
            size="small"
          >
            Cancel
          </Button>
        }
        heading="Remove skill?"
        subheading={
          <>
            Are you sure you want to remove this skill? You will no longer
            receive feedback on:
            <strong> {skillToRemove.name}</strong>.
          </>
        }
        onCancel={handleCloseRemoveSkillConfirmDialog}
      />
    );
  }

  renderPickedSkillsList = () => {
    const { skillsData, handleClickAddSkill } = this.props;
    const { currentUser } = skillsData;
    const { pickedSkillDetails } = currentUser;
    const skills = pickedSkillDetails.map(({ skill }) => {
      const onClick = () => {
        const skillPath = Resources.skillDetails.path({skillSlug: skill.slug});
        return this.props.history.push(skillPath);
      };

      return {
        ...skill,
        onClick
      };

    });
    if (this.isLoading()) { return null; }
    return (
      <>
        {this.renderRemoveSkillConfirmDialog()}
        <Skill.PickedSkillsList
          skills={skills}
          handleClickAddSkill={handleClickAddSkill}
        />
      </>
    );
  };

  render() {
    const { children } = this.props;

    const isLoading = this.isLoading();
    return children({
      isLoading,
      renderPickedSkillsList: this.renderPickedSkillsList,
    });
  }
}

PickedSkillsList.defaultProps = {
  skillToRemove: null,
};

PickedSkillsList.propTypes = {
  skillsData: propType(SKILLS_QUERY).isRequired,
  handleClickAddSkill: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  handleCloseRemoveSkillConfirmDialog: PropTypes.func.isRequired,
  handleConfirmRemoveSkill: PropTypes.func.isRequired,
  isRemoveSkillConfirmDialogOpen: PropTypes.bool.isRequired,
  skillToRemove: skillEntityPropType,
  history: PropTypes.object.isRequired,
};

export default PickedSkillsList;
