import styled from 'styled-components';

const YellowCardOverlay = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  background:none;
  pointer-events: none;
  width: 100%;
  height: 100%;
`;

export default YellowCardOverlay;
