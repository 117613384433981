import React from 'react';
import styled from 'styled-components';
import Elements from '../Elements';
import theme from '@matterapp/matter-theme';

const BackgroundContainer = styled.div`
  background: ${(props) => props.theme.colors.blacks[5]};
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -999;
  display: none;
  ${() => theme.media.S`
    display: block;
  `}
`;

const Stars = styled(Elements.Stars)`
  position: absolute;
  left: 50%;
  top: 0%;
  bottom: 0;
  width: auto;
  height: 100%;
  transform: translateX(-50%);
  z-index: -9999;
`;

const Footer = styled(Elements.Europa)`
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
`;

const Jupiter = styled(Elements.Jupiter)`
  position: absolute;
  top: -20px;
  right: -40px;
`;

const Mars = styled(Elements.Mars)`
  position: absolute;
  top: 75%;
  left: 75%;
`;

const Neptune = styled(Elements.Neptune)`
  position: absolute;
  top: 20%;
  left: 5%;
`;

const SpaceOne = () => (
  <BackgroundContainer>
    <Footer />
    <Jupiter />
    <Mars />
    <Neptune />
    <Stars />
  </BackgroundContainer>
);

export default SpaceOne;