import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconNew, NotificationItem } from '@matterapp/matter-ui';
import { Resources } from '@matterapp/routing';
import { NOTIFICATION_TYPE } from '@matterapp/notifications';
import { useRemoteNotifications } from 'hooks';
import Empty from './Empty';
import StickyNotification from '../StickyNotification';
import UnreadFeedback from '../../UnreadFeedback';
import PendingInvitations from '../../../containers/PendingInvitationsContainer';
import WorkspaceInvitations from '../../../containers/WorkspaceInvitationsContainer';
import { getNotificationDetails } from '../utils';
import { NotificationLink, PanelContainer, WorkspaceIcon, TextWithReplace } from '../styles';

function NotificationsPanel(props) {
  const { onClickNotification, remoteNotificationsData, loading, workspaceId } = props;
  const { notifications = [] } = remoteNotificationsData;
  const navigate = useNavigate();

  const workspaceInvitations = notifications.filter(
    (notification) =>
      notification.type === NOTIFICATION_TYPE.workspaceInvitation
  );

  function renderNotifications() {
    if (!notifications || loading) {
      return null;
    }

    const items = notifications.filter(
      (notification) =>
        !(notification.type === NOTIFICATION_TYPE.workspaceInvitation)
    );

    if (items.length === 0) {
      return <Empty />;
    }

    return (
      <StickyNotification.List
        headerType="normal"
        items={items}
        renderItem={renderNotificationItem}
        empty={<Empty />}
      />
    );
  };

  function renderNotificationItem(item) {
    const { eventTime, id, isViewed, payload, resourcePath } = item;
    const { subjectPerson, workspace } = payload || {};
    const hasWorkspace = !!workspace;
    const { copy, icon } = getNotificationDetails(item, hasWorkspace);

    return (
      <StickyNotification.Item highlighted={!isViewed} key={id}>
        {({ highlighted }) => {
          const content = hasWorkspace ? (
            <TextWithReplace
              replaceText={
                <NotificationLink
                  key={`${id}-workspace-${workspace.id}-notification`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  to={Resources.workspacesActivityFeed.path({
                    workspaceId: workspace.id,
                  })}
                >
                  {workspace.name}
                </NotificationLink>
              }
              text={copy}
            />
          ) : (
            copy
          );
          return (
            <NotificationItem
              onClick={(e) => {
                onClickNotification(e);
                navigate(resourcePath);
              }}
              key={id}
              person={subjectPerson}
              eventTime={eventTime}
              content={content}
              actions={
                hasWorkspace && workspace.iconUrl ? (
                  <WorkspaceIcon src={workspace.iconUrl} />
                ) : (
                  <IconNew name={icon} />
                )
              }
              highlighted={highlighted}
            />
          );
        }}
      </StickyNotification.Item>
    );
  };

  return (
    <PanelContainer>
      <UnreadFeedback onClickNotification={onClickNotification} workspaceId={workspaceId} />
      <WorkspaceInvitations invitations={workspaceInvitations} onClickNotification={onClickNotification} />
      <PendingInvitations onClickNotification={onClickNotification} />
      {renderNotifications()}
    </PanelContainer>
  );
};

const NotificationsPageContainer = (props) => (
  <NotificationsPanel {...props} {...useRemoteNotifications()} />
);

export default NotificationsPageContainer;
