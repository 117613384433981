import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ModalNew as Modal } from '../Modal';
import theme from '@matterapp/matter-theme';

const minModalDimension = '358px';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${minModalDimension};
  padding: ${() => theme.sizes.padding[60]};
  position: relative;
`;

const Heading = styled.h1`
  line-height: ${() => theme.sizes.fontSizes[60]};
  font-size: ${() => theme.sizes.fontSizes[55]};
  font-weight: bold;
  margin-bottom: ${() => theme.sizes.padding[30]};
`;

const SubHeading = styled.h2`
  border-top: ${({ theme: { colors, inputs } }) =>
    `${inputs.borderDefault} ${colors.blacks[10]}`};
  font-size: ${() => theme.sizes.fontSizes[40]};
  font-weight: bold;
  line-height: 20px;
  margin-bottom: ${() => theme.sizes.padding[30]};
  padding-top: ${() => `calc(${theme.sizes.padding[30]} * 2)`};
  &:empty {
    margin-bottom: 0;
  }
`;

const BodyParagraph = styled.p`
  color: ${() => theme.colors.blacks[60]};
  flex: 1 1 auto;
  min-height: 128px;
  font-size: ${() => theme.sizes.fontSizes[50]};
  line-height: ${() => theme.sizes.lineHeights[50]};
  padding-bottom: ${() => theme.sizes.padding[40]};
`;

const BodyContainer = styled.div`
  flex: 1 1 auto;
  font-size: ${() => theme.sizes.fontSizes[50]};
  line-height: ${() => theme.sizes.lineHeights[50]};
`;

const CloseButtonContainer = styled.div`
  text-align: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${() => theme.colors.blues[60]};
  font-size: ${() => theme.sizes.fontSizes[50]};
  line-height: ${() => theme.sizes.lineHeights[50]};
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

/**
 * Simple modal that displays additional information for an item.
 */

export default class InfoModal extends React.Component {
  static propTypes = {
    /** Additional content to display in the InfoModal. */
    bodyContent: PropTypes.node,
    /** Text to display in the InfoModal. (Wraps text in p element.) */
    bodyText: function (props, propName, componentName) {
      if (
        props['bodyContent'] == undefined &&
        (props[propName] == undefined || typeof props[propName] != 'string')
      ) {
        return new Error('Please provide bodyText or bodyContent!');
      }
    },
    /**
     * Children components wrapped in a function that passes back `toggleModal`
     * callback. Call this function to open the InfoModal.
     */
    children: PropTypes.func.isRequired,
    /** Header text of the InfoModal. */
    headingText: PropTypes.string.isRequired,
    /** Sub Header of the InfoModal. */
    subHeading: PropTypes.string,
  };

  static defaultProps = {
    bodyContent: null,
    bodyText: null,
  };

  state = { isOpen: false };

  toggleModal = () => {
    this.setState((st) => ({ isOpen: !st.isOpen }));
  };

  render() {
    const {
      children,
      headingText,
      bodyContent,
      bodyText,
      subHeading,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <Fragment>
        <Modal
          contentLabel="Ability Details"
          customWidth={minModalDimension}
          htmlOpenClassName="ReactInfoModal__Html--open" // Override html class to prevent window from scrolling to top
          isOpen={isOpen}
          onRequestClose={this.toggleModal}
          transparent
        >
          <Container>
            <Heading>{headingText}</Heading>
            <SubHeading>{subHeading}</SubHeading>
            {bodyText && <BodyParagraph>{bodyText}</BodyParagraph>}
            {bodyContent && <BodyContainer>{bodyContent}</BodyContainer>}
            <CloseButtonContainer>
              <CloseButton
                role="button"
                onClick={this.toggleModal}
                data-rc="closeInfoModalButton"
              >
                Close
              </CloseButton>
            </CloseButtonContainer>
          </Container>
        </Modal>
        {children({ toggleModal: this.toggleModal })}
      </Fragment>
    );
  }
}
