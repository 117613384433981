import ConnectYourAccounts from './ConnectYourAccounts';
import FirstUX from './FirstUX';
import Composer from './Composer';
import Peers from './Peers';
import PeersHome from './PeersHome';

export default {
  ConnectYourAccounts,
  FirstUX,
  Composer,
  Peers,
  PeersHome
};
