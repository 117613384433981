import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FooterIcon from './FooterIcon';
import theme from '@matterapp/matter-theme';

const FOOTER_DATA_ROLE = 'app-footer';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.blacks[50]};
  background: ${() => theme.colors.blacks[5]};
  padding-top: 24px;
  padding-bottom: 48px;

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    color: ${(props) => props.theme.colors.blue};
    &:hover {
      color: ${(props) => props.theme.colors.purple};
    }
    &:active {
      text-decoration: underline;
    }
  }

  a.muted {
    color: ${(props) => props.theme.colors.blacks[50]};

    &:hover {
      color: ${(props) => props.theme.colors.blacks[90]};
    }
    &:active {
      text-decoration: underline;
    }
  }

  i {
    text-transform: lowercase;
  }

  b.bullet {
    -webkit-text-stroke: 2px ${(props) => props.textColor};
  }
`;

const AppFooter = ({
  twitterUrl,
  blogUrl,
  helpCenterUrl,
  privacyUrl,
  termsUrl,
}) => (
  <FooterContainer data-role={FOOTER_DATA_ROLE}>
    <div style={{ marginBottom: '1em' }}>
      <ul>
        <li>
          <FooterIcon/>
        </li>
        <li>
          <span>Made by Matter</span>&nbsp;
          <i>in</i>&nbsp;
          <span>San Francisco</span>
        </li>
      </ul>
    </div>

    <div>
      <a
        href={twitterUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="muted"
      >
        TWITTER
      </a>
      <b className="bullet"> &bull; </b>
      <a
        href={blogUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="muted"
      >
        BLOG
      </a>
    </div>
    <div>
      <a
        href={helpCenterUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="muted"
      >
        HELP CENTER
      </a>
      <b className="bullet"> &bull; </b>
      <a href={privacyUrl} className="muted">
        Privacy
      </a>
      <b className="bullet"> &bull; </b>
      <a href={termsUrl} className="muted">
        Terms
      </a>
    </div>
    <div>© {(new Date()).getFullYear()} Matter</div>
  </FooterContainer>
);

AppFooter.DataRole = FOOTER_DATA_ROLE;
AppFooter.DataRoleSelector = `[data-role='${FOOTER_DATA_ROLE}']`;

AppFooter.propTypes = {
  twitterUrl: PropTypes.string.isRequired,
  blogUrl: PropTypes.string.isRequired,
  helpCenterUrl: PropTypes.string.isRequired,
  privacyUrl: PropTypes.string.isRequired,
  termsUrl: PropTypes.string.isRequired,
};

export default AppFooter;
