import React from 'react';
import PropTypes from 'prop-types';
import KudosModal from 'modules/composer/shared/KudosModal';
import { useGiveKudosWorkspaces } from 'hooks/composer';
import getMembers from 'graphql-queries/queries/workspace/getMembers';
import { useQuery } from '@apollo/client';

const GiveKudosModal = (props) => {
  const { closeOnClickUpgrade, isOpen, onClickUpgrade, onClose, workspace = {}, ...otherProps } = props;
  const { data: membersData } = useQuery(getMembers, {
    variables: { workspaceId: workspace?.id, limit: 50 },
    skip: !workspace?.id
  });

  const { workspaceMembers: { members: acceptedMembers } } = membersData || { workspaceMembers: { members: [] } };

  const kudosRecipients = acceptedMembers
    ?.filter((member) => !member.isSelf)
    .map((member) => member.person);
  

  const { kudosModalProps } = useGiveKudosWorkspaces({
    showKudosSelection: true,
    isKudosModalOpen: isOpen,
    onClickUpgrade: (e, params) => {
      onClickUpgrade(e, params);
      if (closeOnClickUpgrade) {
        onClose(e, params);
      }
    },
    onCloseKudosModal: onClose,
    onClose,
    workspace,
    workspaceMembers: acceptedMembers,
    users: kudosRecipients,
    hasMultipleUsers: kudosRecipients?.length >= 1,
    ...otherProps,
  });
  return <KudosModal {...kudosModalProps} />;
};

GiveKudosModal.defaultProps = {
  onClickUpgrade: () => null,
};

GiveKudosModal.propTypes = {
  closeOnClickUpgrade: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClickUpgrade: PropTypes.func,
  workspace: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default GiveKudosModal;
