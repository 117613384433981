import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const getBorderButtonStyles = () => `
  border-radius: 0 !important;
  border-top: none !important;
  border-bottom: none !important;

  &:first-child {
    border-left: none !important;
  }
  &:last-child {
    border-right: none !important;
  }
`;

export const Container = styled.div`
  display: flex;

  ${({ bordered }) => `
    border: ${bordered ? `1px solid ${theme.colors.blacks[40]}` : 'none'};
    border-radius: ${bordered ? theme.borderRadius.S : 0};
    overflow: ${bordered ? 'hidden' : 'visible'};
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    & > button {
      flex: 1 1 100%;
      ${bordered ? getBorderButtonStyles() : ''};
      padding: 0;
      z-index: 1;

      &:not(:first-child) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      } 
      &:not(:last-child) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      } 
      &:hover {
        z-index: 2;
      }
      &:active {
        z-index: 2;
      }
    }
  `}
`;
