import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid ${() => theme.colors.blacks[10]};
`;

const CloseContainer = styled.div`
  display: flex;
  min-width: 32px;
`;

const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${() => theme.colors.purples[60]};
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  margin-right: 16px;
`;

const TitleDesktop = styled.span`
  display: none;

  ${() => theme.media.medium`
    display: initial;
  `}
`;

const TitleMobile = styled.span`
  ${() => theme.media.medium`
    display: none;
  `}
`;

const SubmitContainer = styled.div`
  display: flex;
  min-width: 68px;

  button {
    height: 40px;
    min-width: 80px;
    line-height: 32px;
    font-size: 14px;
  }
`;

const Header = ({ titleDesktop, titleMobile, left, right }) => (
  <Container>
    <CloseContainer>{left}</CloseContainer>
    <Title>
      <TitleDesktop>{titleDesktop}</TitleDesktop>
      <TitleMobile>{titleMobile}</TitleMobile>
    </Title>
    <SubmitContainer>{right}</SubmitContainer>
  </Container>
);

Header.defaultProps = {
  left: null,
  right: null,
};

Header.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  titleDesktop: PropTypes.string.isRequired,
  titleMobile: PropTypes.string.isRequired,
};

export default Header;
