import React, { useContext, useEffect } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { 
  Container,
  Divider,
  Icon,
  Box,
  Typography,
  IconButton,
  Badge
} from '@mui/material';
import styled from 'styled-components';
import SlideMenuItem, { MenuItem } from './SlideMenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { Resources } from '@matterapp/routing';
import { useNavigate, useLocation, matchPath, useParams } from 'react-router-dom';
import TagIcon from '@mui/icons-material/Tag';
import { IconNew as MatterIcon } from '@matterapp/matter-ui';
import getPendingRewardRequests from 'graphql-queries/queries/rewards/getPendingRewardRequests';
import { useQuery } from '@apollo/client';
import UpgradeBanner from 'components/UpgradeBanner/UpgradeBanner';
import { useUpgradeModal } from 'routes/pages/workspaces/hooks/shared';
import UpgradeModal from 'modules/workspaces/shared/UpgradeModal';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { GET_SLACK_OAUTH_URL } from 'graphql-queries/queries';
import { useLazyQuery } from '@apollo/client';
import { useQueryParams } from 'hooks';

const createIconComponent = (name) => styled(MatterIcon).attrs(() => ({ name }))``;

const StyledIcon = styled(Icon)({
  width: '24px',
  height: '24px'
});

const StyledDivider = styled(Divider)({
  margin: 0
});

const StyledDesktopContainer = styled(Container)(({ theme }) => ({
  height: 'fit-content',
  overflow: 'hidden',
  minWidth: '280px',
  maxWidth: '280px',
  backgroundColor: theme.palette.white.main,
  padding: 0,
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}));

const StaticMenuItem = styled(Box)(({ theme, isActive }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '48px',
  margin: `${theme.spacing(2)} ${theme.spacing(3)}`,
  cursor: 'pointer',
  backgroundColor: isActive ? theme.palette.purples[50] : 'inherit',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: isActive ? theme.palette.purples[50] : theme.palette.blacks[5]
  },
  '&:active': {
    backgroundColor: isActive ? theme.palette.purples[50] : theme.palette.purples[5]
  },
  color: isActive ? theme.palette.white.main : theme.palette.blacks[60],
  transitionDuration: '0.2s',
  transitionProperty: 'background-color',
  [theme.breakpoints.down('lg')]: { 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center',
    padding: 0,
    margin: 'auto',
    color: isActive ? theme.palette.white.main : theme.palette.primary.light
  }
}));

const RewardsManagerIcon = createIconComponent('navigationRewards');
const AnalyticsIcon = createIconComponent('navigationAnalytics');
const FeedbackIcon = createIconComponent('navigationFeedbacksNew');
const SurveyIcon = createIconComponent('navigationSurvey');
const HomeIcon = createIconComponent('navigationHome');
const PulseIcon = createIconComponent('navigationPulse');

function AdminMenu({ 
  visible, 
  workspaceId, 
  currentTenant, 
  isEnterprise
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { queryParams } = useQueryParams();
  const rewardsManagerPath = Resources.rewardsManager.path({ workspaceId });
  const customRewardsPath = Resources.customRewards.path({ workspaceId });
  const rewardsFundingPath = Resources.rewardsFunding.path({ workspaceId });
  const rewardsReportsPath = Resources.rewardsReports.path({ workspaceId });
  const rewardsAddFundsPath = Resources.rewardsAddFunding.path({ workspaceId });
  const rewardsSendWirePath = Resources.rewardsSendWire.path({ workspaceId });
  const pulseDashboardPath = Resources.pulseDashboard.path({ workspaceId });

  const isAnalyticsPath = !!matchPath(Resources.analyticsMain.pattern, location.pathname);
  const settingsPath = Resources.workspaceSettingsMain.path({ workspaceId });
  const isSettingsPath = location.pathname.includes('/settings') && !queryParams.isChannelSetting;

  const { pulseSettings, rewardsSettings } = currentTenant || {};
  const { isPulseEnabled } = pulseSettings || {};
  const { isRewardsActive } = rewardsSettings || {};

  const { data: pendingRewardRequestsData } = useQuery(getPendingRewardRequests, {
    variables: {
      tenantId: currentTenant?.id
    },
    skip: !currentTenant?.id,
  });

  if (!visible || !workspaceId) {
    return null;
  }

  const numberOfRewardsNotifications = pendingRewardRequestsData?.getPendingRewardRequests?.length;

  return (
    <Box sx={{ pt: '24px' }}>
      <Typography component='p' variant='body2' sx={{ margin: '24px' }}>
        ADMIN
      </Typography>
      {isEnterprise ? (
        <SlideMenuItem 
          name='Analytics'
          icon={(
            <StyledIcon 
              component={AnalyticsIcon}
              sx={{ mr: '10px', ml: '8px', width: '20px' }}
            />
          )}
          expanded={isAnalyticsPath}
          menuItems={currentTenant?.workspaces?.map(workspace => {
            const isActive = workspace.id === workspaceId && isAnalyticsPath;

            return {
              isActive,
              key: workspace.id,
              onClick: () => navigate(Resources.analyticsMain.path({ workspaceId: workspace.id })),
              text: workspace.name
            };
          })}
        />
      ) : (
        <StaticMenuItem 
          onClick={() => navigate(Resources.analyticsMain.path({ workspaceId }))}
          isActive={isAnalyticsPath}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
            <StyledIcon 
              component={AnalyticsIcon}
              sx={{ mr: '10px', ml: '8px', width: '20px' }}
            />
            <Typography 
              variant='h6'
              sx={{ userSelect: 'none' }}
            >
              Analytics
            </Typography>
          </Box>
        </StaticMenuItem>
      )}
      {isPulseEnabled && (
        <StaticMenuItem 
          isActive={location.pathname === pulseDashboardPath}
          onClick={() => navigate(pulseDashboardPath)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
            <StyledIcon 
              component={PulseIcon}
              sx={{ mr: '10px', ml: '8px', width: '20px' }}
            />
            <Typography 
              variant='h6'
              sx={{ userSelect: 'none' }}
            >
              Pulse Survey
            </Typography>
          </Box>
        </StaticMenuItem>
      )}
      {isRewardsActive && pendingRewardRequestsData ? (
        <SlideMenuItem 
          name='Rewards Manager'
          icon={(
            <StyledIcon 
              component={RewardsManagerIcon}
              sx={{ mr: '8px', ml: '8px', width: '20px' }}
            />
          )}
          expanded={[
            rewardsManagerPath, 
            customRewardsPath, 
            rewardsReportsPath, 
            rewardsFundingPath,
            rewardsAddFundsPath,
            rewardsSendWirePath
          ].includes(location.pathname) || numberOfRewardsNotifications > 0}
          menuItems={[
            {
              key: 1,
              isActive: location.pathname === rewardsManagerPath,
              onClick: () => navigate(rewardsManagerPath),
              text: 'Overview',
              rightIcon: (
                <IconButton
                  size='large'
                  color='inherit'
                  sx={{ ml: '5px' }}
                >
                  <Badge badgeContent={numberOfRewardsNotifications} color='error' />
                </IconButton>
              )
            },
            {
              key: 2,
              isActive: location.pathname === customRewardsPath,
              onClick: () => navigate(customRewardsPath),
              text: 'Custom Rewards'
            },
            {
              key: 3,
              isActive: [
                rewardsFundingPath,
                rewardsAddFundsPath,
                rewardsSendWirePath
              ].includes(location.pathname),
              onClick: () => navigate(rewardsFundingPath),
              text: 'Funding'
            },
            {
              key: 4,
              isActive: location.pathname === rewardsReportsPath,
              onClick: () => navigate(rewardsReportsPath),
              text: 'Reports'
            }
          ]}
        />
      ) : null}
      <StaticMenuItem 
        isActive={isSettingsPath}
        onClick={() => navigate(settingsPath)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
          <StyledIcon 
            component={SettingsIcon}
            sx={{ mr: '10px', ml: '8px', width: '20px' }}
          />
          <Typography 
            variant='h6'
            sx={{ userSelect: 'none' }}
          >
            Settings
          </Typography>
        </Box>
      </StaticMenuItem>
    </Box>
  );
}

export default function SideNav() {
  const { 
    currentTenant, 
    currentWorkspace,
    updateWorkspace, 
    isAdminMember = true,
    currentWorkspaceId: workspaceId,
    isEnterprise,
    setParams,
    tenantAdmins,
    isTenantZero
  } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { queryParams } = useQueryParams();
  const showUpgradeModal = location.pathname?.includes('upgrade');
  const upgradeModalProps = useUpgradeModal(currentWorkspace, tenantAdmins, showUpgradeModal);
  const [getSlackOAuthUrl] = useLazyQuery(GET_SLACK_OAUTH_URL);

  const feedbackMainPath = Resources.feedbackMain.path({ workspaceId });
  const continuousMainPath = Resources.workspacesRecurring.path({ workspaceId });
  const skillsMainPath = Resources.skillsMain.path({ workspaceId });
  const yourSkillsPath = !!matchPath(Resources.skillDetails.pattern, location.pathname);
  
  const homePath = Resources.workspacesActivityFeed.path({ workspaceId });
  const requestsMainPath = Resources.feedbackRequestsMain.path();
  const rewardsPath = Resources.rewardsStore.path({ workspaceId });
  const surveysPath = Resources.surveys.path({ workspaceId });
  const kudosSettingsPath = Resources.workspaceSettingsKudos.path({ workspaceId });
  const isChannelSettingsPath = location.pathname.includes('/settings') && queryParams.isChannelSetting;

  const isActivityFeedPath = !!matchPath(Resources.workspacesActivityFeed.pattern, location.pathname);

  useEffect(() => {
    setParams(params);
  }, [params]);

  if (isTenantZero) {
    return (
      <StyledDesktopContainer>
        <SlideMenuItem 
          name='Matter Channels'
          icon={(
            <StyledIcon 
              component={TagIcon}
              sx={{ mr: '10px', ml: '8px', width: '20px' }}
            />
          )}
          expanded
          menuItems={[]}
        >
          <MenuItem
            onClick={async () => {
              if (currentTenant.type === 'slack') {
                const { data } = await getSlackOAuthUrl({
                  variables: {
                    inapp: false,
                    tenantId: currentTenant.id
                  }
                });

                const { slackOAuthURL } = data;

                window.open(slackOAuthURL, '_blank').focus();
              } else {
                window.open(Resources.oAuthTeamsLogin.url(), '_blank').focus();
              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon 
                component={PlayArrowIcon}
                sx={{ marginRight: '5px', width: '10px' }}
              />
              <Typography 
                sx={{ userSelect: 'none', color: '#7E81C8' }}
                variant='body2'
              >
                CREATE A CHANNEL
              </Typography>
              <StyledIcon 
                component={AddIcon}
                sx={{ color: '#7E81C8', ml: '10px' }}
              />
            </Box>
          </MenuItem>
          
        </SlideMenuItem>
      </StyledDesktopContainer>
    );
  }

  return (
    <StyledDesktopContainer>
      {isEnterprise ? (
        <SlideMenuItem 
          name='Matter Channels'
          icon={(
            <StyledIcon 
              component={TagIcon}
              sx={{ mr: '10px', ml: '8px', width: '20px' }}
            />
          )}
          expanded={isActivityFeedPath || isChannelSettingsPath}
          menuItems={currentTenant?.workspaces?.map(workspace => {
            const isActive = workspace.id === workspaceId && (isActivityFeedPath || isChannelSettingsPath);

            return {
              isActive,
              key: workspace.id,
              onClick: () => updateWorkspace(workspace.id),
              text: workspace.name,
              rightIcon: isActive && <StyledIcon 
                component={SettingsIcon}
                sx={{ marginRight: '5px', width: '20px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(Resources.workspaceSettingsMain.path({ workspaceId: workspace.id }, { isChannelSetting: true }));
                }}
              />
            };
          })}
        />
      ) : (
        <StaticMenuItem 
          isActive={location.pathname === homePath}
          onClick={() => {
            navigate(homePath);
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
            <StyledIcon 
              component={HomeIcon}
              sx={{ mr: '10px', ml: '8px', width: '20px' }}
            />
            <Typography 
              variant='h6'
              sx={{ userSelect: 'none' }}
            >
              Home
            </Typography>
          </Box>
          {!isAdminMember && (
            <StyledIcon 
              component={SettingsIcon}
              sx={{ mr: '10px', ml: '8px', width: '20px' }}
              onClick={(e) => {
                e.stopPropagation();

                navigate(kudosSettingsPath);
              }}
            />
          )}
        </StaticMenuItem>
      )}
      <StyledDivider />
      <StaticMenuItem 
        isActive={location.pathname === rewardsPath}
        onClick={() => navigate(rewardsPath)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
          <StyledIcon 
            component={RewardsManagerIcon}
            sx={{ mr: '10px', ml: '8px', width: '20px' }}
          />
          <Typography 
            variant='h6'
            sx={{ userSelect: 'none' }}
          >
            Rewards
          </Typography>
        </Box>
      </StaticMenuItem>
      <StyledDivider />
      <StaticMenuItem 
        isActive={location.pathname === surveysPath}
        onClick={() => navigate(surveysPath)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
          <Icon 
            component={SurveyIcon}
            sx={{ mr: '12px', ml: '12px', width: '18px', height: 'auto' }}
          />
          <Typography 
            variant='h6'
            sx={{ userSelect: 'none' }}
          >
            Surveys
          </Typography>
        </Box>
      </StaticMenuItem>
      <StyledDivider />
      <SlideMenuItem 
        name='Feedback'
        icon={(
          <StyledIcon 
            component={FeedbackIcon}
            sx={{ mr: '8px', ml: '10px', width: '20px' }}
          />
        )}
        expanded={[feedbackMainPath, continuousMainPath, skillsMainPath, requestsMainPath].includes(location.pathname) || yourSkillsPath}
        menuItems={[
          {
            key: 1,
            isActive: location.pathname === feedbackMainPath,
            onClick: () => navigate(feedbackMainPath),
            text: 'Activity'
          },
          {
            key: 2,
            isActive: location.pathname === continuousMainPath,
            onClick: () => navigate(continuousMainPath),
            text: 'Continuous'
          },
          {
            key: 3,
            isActive: location.pathname === skillsMainPath || yourSkillsPath,
            onClick: () => navigate(skillsMainPath),
            text: 'Skills'
          },
          {
            key: 4,
            isActive: location.pathname === requestsMainPath,
            onClick: () => navigate(requestsMainPath),
            text: 'Request Status'
          }
        ]}
      />
      <StyledDivider />
      <AdminMenu 
        visible={isAdminMember && currentTenant}
        workspaceId={workspaceId}
        currentTenant={currentTenant}
        currentWorkspace={currentWorkspace}
        isEnterprise={isEnterprise}
      />
      <UpgradeBanner 
        tier={currentTenant?.tier}
        expiresInDays={currentTenant?.expiresInDays}
        onClickUpgrade={() => {
          if (isAdminMember) {
            navigate(Resources.workspaceSettingsBilling.path({ workspaceId }));
          } else {
            upgradeModalProps.openUpgradeModal();
          }
        }}
      />
      <UpgradeModal {...upgradeModalProps} />
    </StyledDesktopContainer>
  );
}
