import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const StyledLabel = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: ${(props) => props.marginBottom}px;
`;

const Label = ({
  children,
  text,
  marginBottom,
}) => (
  <label>
    <StyledLabel
      marginBottom={marginBottom}
    >
      {text}
    </StyledLabel>
    {children}
  </label>
);

Label.defaultProps = {
  text: null,
  marginBottom: 8,
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
  marginBottom: PropTypes.number,
};

export default Label;
