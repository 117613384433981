import React from 'react';
import { SVG, Path } from './consts';

const RequestStatus = (props) => (
  <SVG {...props}>
    <Path
      d="M13.1,3.1c-4.6,0-8.5,3.5-8.9,8.1H2.9C2.4,11.1,2,11.5,2,12c0,0.5,0.4,0.9,0.9,0.9h6.8c0.3,0,0.6-0.2,0.8-0.5l0.7-1.4
        l2.3,4.4c0.1,0.3,0.4,0.5,0.8,0.5c0.3,0,0.6-0.2,0.8-0.5l1.3-2.5h1.3c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-1.8
        c-0.3,0-0.6,0.2-0.8,0.5L14.3,13L12,8.7c-0.3-0.6-1.3-0.6-1.6,0l-1.3,2.5H5.9c0.4-3.6,3.5-6.3,7.1-6.3c4,0,7.2,3.2,7.2,7.2
        c0,4-3.2,7.2-7.2,7.2c-3,0-5.6-1.9-6.7-4.5H4.5c1.2,3.6,4.5,6.2,8.5,6.2c4.9,0,8.9-4,8.9-8.9C22,7.1,18,3.1,13.1,3.1z"
    />
  </SVG>
);

export default RequestStatus;
