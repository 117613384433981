import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../SVG';

const Icon = ({ fill }) => (
  <SVG size={32} viewBox="0 0 32 32">
    <path
      d="M14.9,17.2H8.8v-2h6.1V17.2z M23.2,11.5H8.8v2h14.3V11.5z M23.2,7.9H8.8v2h14.3V7.9z M27.5,4v18
        c0,0.6-0.4,1-1,1h-5v5c0,0.4-0.2,0.8-0.6,0.9C20.8,29,20.6,29,20.5,29c-0.3,0-0.5-0.1-0.7-0.3L14.1,23H5.5c-0.6,0-1-0.4-1-1V4
        c0-0.6,0.4-1,1-1h21C27.1,3,27.5,3.4,27.5,4z M25.5,5h-19v16h8c0.3,0,0.5,0.1,0.7,0.3l4.3,4.3V22c0-0.6,0.4-1,1-1h5V5z"
      fill={fill}
    />
  </SVG>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
