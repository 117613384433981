import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ backgroundFill, fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill={backgroundFill}/>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.18031 10.2069C6.43213 9.94315 6.85402 9.92978 7.12263 10.1771L12 14.6669L16.8774 10.1771C17.146 9.92978 17.5679 9.94315 17.8197 10.2069C18.0715 10.4707 18.0579 10.8849 17.7893 11.1322L12 16.4615L6.21071 11.1322C5.9421 10.8849 5.92849 10.4707 6.18031 10.2069Z"
      fill={fill}
      stroke={fill}
      strokeLinecap="round"
    />
  </svg>
);

Icon.defaultProps = {
  backgroundFill: '#ffffff',
  fill: '#343799',
};

Icon.propTypes = {
  backgroundFill: PropTypes.string,
  fill: PropTypes.string,
};

export default Icon;