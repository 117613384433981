import React from 'react';
import PropTypes from 'prop-types';
import {
  AvatarContainer,
  Container,
  SendButton,
  SendButtonContainer,
  TextAreaContainer,
} from './styles';
import TextArea from '../../TextArea';
import { useTextArea } from './hooks';

const TextAreaInput = (props) => {
  const { avatar, hasValue, numberOfComments, sendButtonProps, textAreaProps } = useTextArea(props);

  return (
    <Container>
      <AvatarContainer>{avatar}</AvatarContainer>
      <TextAreaContainer hasValue={hasValue} numberOfComments={numberOfComments}>
        <TextArea {...textAreaProps} />
      </TextAreaContainer>
      <SendButtonContainer>
        <SendButton {...sendButtonProps} />
      </SendButtonContainer>
    </Container>
  );
};

TextAreaInput.propTypes = {
  avatar: PropTypes.node,
  canClickSend: PropTypes.bool,
  numberOfComments: PropTypes.number,
  onClickSend: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  sendOnEnter: PropTypes.bool,
  value: PropTypes.string,
};

TextAreaInput.defaultProps = {
  canClickSend: true,
  numberOfComments: 0,
  onClickSend: () => null,
  onChange: () => null,
  sendOnEnter: true,
  value: '',
};

export default TextAreaInput;
