import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  TextArea, 
  Checkbox
} from '@matterapp/matter-ui';
import { 
  Box, 
  Typography,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import styled from 'styled-components';
import createPulseQuestion from 'graphql-queries/mutations/pulse/createPulseQuestion';
import editPulseQuestion from 'graphql-queries/mutations/pulse/editPulseQuestion';
import getTenantPulseQuestions from 'graphql-queries/queries/pulse/getTenantPulseQuestions';
import previewQuestionQuery from 'graphql-queries/queries/pulse/previewQuestion';
import { useMutation, useLazyQuery } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import { StyledButton, ToggleContainer } from './styles';
import { popoverClasses } from '@mui/material/Popover';

const StyledModal = styled(Modal)({
  zIndex: 99
});

const Body = styled(Modal.Body)({

});

const List = styled.ul(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  margin: 0,
  marginBottom: theme.spacing(2)
}));

const Section = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(5)} ${theme.spacing(3)}`,
  '&:first-of-type': {
    borderBottom: `1px solid ${theme.palette.blacks[30]}`,
    paddingBottom: theme.spacing(4)
  }
}));

const QuestionWrapper = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
  width: '600px',
  maxWidth: '100%'
}));

const QuestionInput = styled(TextArea.WithCount)(({ theme }) => ({
  fontWeight: theme.fontWeight.regular,
  minHeight: '72px',
  border: 'none'
}));

const SubcategoryLink = styled.span(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.blues[50]
}));

const StyledSelect = styled(Select)(({ theme }) => ({

}));

export default function CreateQuestionModal(props) {
  const {
    isOpen,
    onClose,
    tenantId,
    category,
    onAddSubcategory,
    isEdit,
    question: existingQuestion
  } = props;
  const [createQuestion] = useMutation(createPulseQuestion);
  const [editQuestion] = useMutation(editPulseQuestion);
  const [previewQuestion] = useLazyQuery(previewQuestionQuery);
  const [question, setQuestion] = useState('');
  const [isEnabled, setIsEnabled] = useState(true);
  const [subcategory, setSubcategory] = useState('');
  const { subcategories } = category;
  const subcategoryId = subcategories?.find(({ name }) => name === subcategory)?.id;

  const subcategoryOptions = subcategories?.map(({ id, name }) => ({
    value: id, 
    label: name
  })) || [];

  subcategoryOptions.unshift({ value: null, label: 'None' });


  let canClickSave = question?.trim().length > 2 && question?.trim().length <= 255;

  if (isEdit && canClickSave) {
    canClickSave = question !== existingQuestion.question || isEnabled !== existingQuestion.isEnabled || subcategoryId !== existingQuestion.subcategory?.id;
  }

  useEffect(() => {
    if (isEdit && existingQuestion) {
      setQuestion(existingQuestion.question);
      setIsEnabled(existingQuestion.isEnabled);
      setSubcategory(existingQuestion.subcategory 
        ? existingQuestion.subcategory.name 
        : null
      );
    }
  }, [isOpen, isEdit, existingQuestion]);

  function resetAndClose() {
    setQuestion('');
    setIsEnabled(true);
    setSubcategory(null);
    onClose();
  }

  return (
    <StyledModal 
      isOpen={isOpen} 
      onClose={resetAndClose}
      size="large"
    >
      <Modal.Header
        header={isEdit ? 'Edit Question' : 'Add Question'}
        right={<Modal.Buttons.CloseIcon onClick={resetAndClose} />}
      />
      <Body>
        <Section>
          <Typography variant='h5' component='p'>
            Question
          </Typography>
          <QuestionWrapper>
            <QuestionInput 
              rcLabel='question-text-input'
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              value={question}
              maxLength={255}
              allowErrorBeforeBlur
              showErrorMessageBelow
              error={question?.length > 255}
              placeholder="Enter your question here"
            />
          </QuestionWrapper>
          <Typography variant='body2'><b>Recommendations:</b></Typography>
          <List>
            <li><Typography variant='body2'>Questions should use the agree/disagree response scale.</Typography></li>
            <li><Typography variant='body2'>Length should be between 40 and 72 characters.</Typography></li>
          </List>
          <StyledButton onClick={async () => {
            await previewQuestion({
              variables: {
                question: question.trim(),
                tenantId
              }
            });

            Toast.success('Preview sent');
          }} disabled={!question?.trim()}>
            Preview Question
          </StyledButton>
        </Section>
        {isEdit && (
          <Section sx={{ borderBottom: '1px solid', borderColor: 'blacks.30', pb: 3 }}>
            <Typography variant='h5' component='p'>
              <b>Status</b>
            </Typography>
            <ToggleContainer>
              <Typography variant='body1' component='p' >
                Question {isEnabled ? 'Enabled' : 'Disabled'}
              </Typography>
              <Checkbox.Toggle
                size='S' 
                checked={isEnabled}
                onChange={() => setIsEnabled(!isEnabled)}
              />
            </ToggleContainer>
            <Typography variant='body2'>
              Disabled questions will not be included in pulse surveys.
            </Typography>
          </Section>
        )}
        <Section>
          <Typography variant='body1'>
            <b>Subcategory</b> (Optional)
          </Typography>
          <FormControl sx={{ mt: 2, mb: 2, width: '100%' }}>
            <StyledSelect
              value={subcategory}
              onChange={(e) => setSubcategory(e.target.value)}
              displayEmpty
              sx={{
                [`&.MuiOutlinedInput-root.Mui-focused > .MuiOutlinedInput-notchedOutline`] : {
                  border: '1px solid',
                  borderColor: 'rgb(69, 132, 255)'
                }
              }}
              MenuProps={{
                sx: {
                  [`&.${popoverClasses.root}`]: {
                    '> .MuiPaper-root': {
                      borderRadius: '4px',
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      padding: 0,
                      border: '1px solid',
                      borderColor: 'rgb(153, 153, 155) rgb(69, 132, 255) rgb(69, 132, 255)',
                      marginTop: '-3px',
                      boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 4px, rgba(0, 0, 0, 0.15) 0px 1px 1px'
                    },
                    ul: {
                      padding: 0,
                      marginTop: '-3px'
                    },
                    'li.MuiMenuItem-root': {
                      padding: 2,
                      borderBottom: `1px solid`,
                      borderColor: 'blacks.50',
                      ':last-of-type': {
                        border: 'none'
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#FFF',
                        '&:hover': {
                          backgroundColor: 'blacks.5'
                        }
                      }
                    }
                  }
                },
                elevation: 0,
                slotProps: {
                  paper: {
                    square: true
                  }
                }
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {subcategories.map((subcategory) => (
                <MenuItem value={subcategory.name} key={subcategory.id}>{subcategory.name}</MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <Typography variant='body2' sx={{ mt: 2 }}>
            Subcategories can be added, edited, and deleted from <SubcategoryLink onClick={onAddSubcategory}>here</SubcategoryLink>.
          </Typography>
        </Section>
      </Body>
      <Modal.Footer.WithCancelSave
        canClickSave={canClickSave}
        onClickCancel={resetAndClose}
        onClickSave={async () => {
          if (isEdit) {
            await editQuestion({ 
              variables: { 
                id: existingQuestion.id,
                tenantId,
                question,
                isEnabled,
                subcategoryId
              },
              refetchQueries: [{
                query: getTenantPulseQuestions,
                variables: { tenantId }
              }]
            });
            resetAndClose();
            Toast.success('Question updated');
          } else {
            await createQuestion({ 
              variables: { 
                tenantId,
                question,
                categoryId: category.id,
                subcategoryId
              },
              refetchQueries: [{
                query: getTenantPulseQuestions,
                variables: { tenantId }
              }]
            });
            resetAndClose();
            Toast.success('Question added');
          }
        }}
      />
    </StyledModal>
  );
};
