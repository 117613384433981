import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ModalAdapter } from '../Modal';
import theme from '@matterapp/matter-theme';

const overlayClassName = 'ReactModal__Success__Overlay';
const contentClassName = 'ReactModal__Success__Content';
const modalSize = {
  normal: '480px',
  narrow: '430px',
};

export const ModalContainer = styled.div`
  pointer-events: all;
  position: absolute;
  top: ${theme.spacing.quad};
  left: 0;
  right: 0;
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.L};
  margin-bottom: 88px;
  overflow: hidden;

  ${(props) => props.theme.media.medium`
    top: 112px;
    max-width: ${modalSize[props.size] || modalSize.normal};
    margin: 0 auto;
    margin-bottom: 112px;
    box-shadow: 0px -1px 4px rgba(28, 28, 33, 0.25);
  `}
`;

export const ModalCloseButton = styled.button`
  display: inline;
  position: absolute;
  top: ${theme.spacing.singleAndHalf};
  right: ${theme.spacing.singleAndHalf};
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  svg {
    user-select: none;
    path {
      transition: fill 0.2s ease;
      fill: ${() => theme.colors.white};
    }
  }

  ${(props) => props.theme.media.medium`
    position: fixed;
  `}
`;

export const StyledModal = styled(ModalAdapter).attrs({
  overlayClassName,
  contentClassName,
})`
  .${overlayClassName} {
    z-index: 101;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(46, 46, 51, 0.95);
  }

  .${contentClassName} {
    z-index: 102;
    position: relative;
    width: auto;
    margin: 0 auto;
    border-radius: ${theme.borderRadius.S};
    background-color: #fff;
  }

  .ReactModal__Content {
    ${ModalContainer} {
      opacity: 0;
      transition-delay: ${theme.transitions.times.default};
    }
  }
  .ReactModal__Content--after-open {
    ${ModalContainer} {
      opacity: 1;
    }
  }
  .ReactModal__Content--before-close {
    ${ModalContainer} {
      opacity: 1;
    }
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.6s;
    -webkit-overflow-scrolling: touch;
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
    overflow: auto;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

StyledModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  contentLabel: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func,
};

StyledModal.defaultProps = {
  contentLabel: 'main card content',
  onRequestClose: () => {},
};

export default StyledModal;
