import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FallbackIcon, Icon, IconContainer } from './styles';

const WorkspaceIcon = (props) => {
  const { className, fallback, size, src } = props;
  const [hasError, setHasError] = useState(false);

  if (hasError || !src) {
    return (
      <IconContainer className={className} size={size}>
        {fallback || <FallbackIcon />}
      </IconContainer>
    );
  }
  return (
    <IconContainer className={className} size={size}>
      <Icon
        size={size}
        src={src}
        onError={() => setHasError(true)}
      />
    </IconContainer>
  );
};

WorkspaceIcon.propTypes = {
  className: PropTypes.string,
  /** Fallback icon content. */
  fallback: PropTypes.node,
  /** Size of icon in pixels. */
  size: PropTypes.number,
  /** The path to the icon. */
  src: PropTypes.string,
};

export default WorkspaceIcon;
