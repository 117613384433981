import React from 'react';
import styled from 'styled-components';
import Dropdown from '../../Dropdown';
import Skill from '../../Skill';
import TextArea from '../../TextArea';
import theme from '@matterapp/matter-theme';

export const Container = styled.div``;

export const Wrapper = styled.div`
  padding: ${theme.spacing.singleAndHalf};

  & [data-role="dropdown"] [data-role="container"] {
    height: ${theme.spacing.quadruple};
    padding: ${theme.spacing.threeQuarters};
  }
`;

export const EmailDropdown = styled(Dropdown.Email.Tags).attrs(() => ({
  fluid: true,
  filterItems: true,
  placeholder: 'Select a Matter user...',
  showDropdownArrow: false,
  singleValue: true,
}))`
  height: ${theme.spacing.quadruple};
  & input {
    height: ${theme.spacing.quadruple};
    min-width: auto !important;
  }
`;

export const MessageContainer = styled.div`
  margin: ${theme.spacing.single} 0;
`;

export const Message = styled(TextArea.WithCount).attrs(({ messageMinLength, messageMaxLength }) => ({
  autoHeight: true,
  maxLength: messageMaxLength,
  minLength: messageMinLength,
}))`
  min-height: 150px;
  font-weight: ${theme.fontWeight.regular};
`;

export const CheckmarkContainer = styled.div`
  pointer-events: none;
`;

export const SkillTagger = (props) => {
  const { isEmpty, ...otherProps } = props;
  return isEmpty ? (
    <Skill.Tagger.Empty {...otherProps} />
  ) : (
    <Skill.Tagger.Basic {...otherProps} />
  );
};

export const SkillListContainer = styled.div`
  & > div > div:last-child {
    border-radius: 0 !important;
  }
`;