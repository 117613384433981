import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  padding: 8px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 50px;
  transition:
    color 0.1s ease-in-out,
    border-color 0.1s ease-in-out,
    background 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;
`;

const SkillBadgeContainer = styled.div`
  margin-right: 8px;
`;

const SkillName = styled.div`
  flex-grow: 1;
  line-height: 24px;
  font-size: 17px;
`;

const SkillPillContainer = styled.div`
  margin-right: 8px;
`;

export {
  Wrapper,
  Container,
  SkillBadgeContainer,
  SkillName,
  SkillPillContainer
};
