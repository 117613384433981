import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clickable } from '@matterapp/matter-ui';
import { CREATE_PORTAL_SESSION } from 'graphql-queries/queries';
import { useMutation } from '@apollo/client';
import theme from '@matterapp/matter-theme';

const BannerContainer = styled.div`
  ${() => `
    display: flex;
    flex-direction: column;
    color: black;
    padding: ${theme.spacing.single};
    margin: 0;
    background: ${theme.colors.reds[5]};
    font-size: ${theme.fontSize.S};
    line-height: ${theme.spacing.singleAndHalf};
  `}

  ${() => theme.media.L`
    margin: ${theme.spacing.singleAndHalf};
  `}

  ${() => theme.media.XXL`
    margin: 0;
    margin-bottom: ${theme.spacing.single}
  `}
`;

const FixPayment = styled(Clickable)(() => ({
  cursor: 'pointer',
  color: theme.colors.blues[50],
  fontWeight: 'bold',
  ':active': {
    textDecoration: 'underline'
  }
}));

const PaymentFailedBanner = ({ stripeCustomerId, workspaceId, isStripePaymentFailed }) => {
  const [onCreatePortalSession] = useMutation(CREATE_PORTAL_SESSION);
  
  if (!isStripePaymentFailed) {
    return null;
  }
  
  async function onOpenCustomerPortal() {
    const newWindow = window.open('', '_blank');

    if (stripeCustomerId) {
      const { data } = await onCreatePortalSession({
        variables: {
          workspaceId,
        }
      });

      if (data?.createPortalSession) {
        newWindow.location = data.createPortalSession;
      }
    }
  }

  return (
    <BannerContainer>
      <b>
        Your payment method has been declined.&nbsp;
        <FixPayment onClick={onOpenCustomerPortal}>
          Click here
        </FixPayment>
         &nbsp;to update or add a credit card to your Billing Settings.
      </b>
    </BannerContainer>
  );
};

PaymentFailedBanner.propTypes = {
  stripeCustomerId: PropTypes.string,
  workspaceId: PropTypes.string,
  isStripePaymentFailed: PropTypes.bool
};

export default PaymentFailedBanner;