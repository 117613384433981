import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ScrollWatcher from '../Header/ScrollWatcher';
import theme from '@matterapp/matter-theme';
import { WordMark } from '../Brand';
import { PlusSizeScreen } from '../Screen';

import { smallBreakpoint, headerHeight, themeColors } from './consts';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${headerHeight}px;
  background-color: white;
  box-shadow: 0px 1px 0px ${theme.colors.blacks[10]}, inset 0px -1px 0px ${theme.colors.blacks[10]};

  z-index: 50;

  /*
    The ::after is the shadow that shows when scrolled.
    It is a nicer animation than animating box-shadow
  */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  /*
    isScrolled is passed through style cause react complains about props. Styled
    components doesnt give us a way to specify props for a styled component.
  */
  ${({ style }) =>
    style.isScrolled &&
    `
    &::after {
      opacity: 1;
    }
  `}

  ${() => theme.media.breakpoint(smallBreakpoint)`
    margin-bottom: 16px;
    background-color: ${theme.colors.purples[1]};
    box-shadow: none;
  `}
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${theme.sizes.webClient.maxContentWidth};
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const Left = styled.div`
  flex: 1;
  text-align: left;
`;

const Right = styled.div`
  flex: 1;
  text-align: right;
`;

const StyledWordMark = styled(WordMark)`
  display: ${({ hideLogoOnMobile }) => hideLogoOnMobile ? 'none': ''};
  ${theme.media.XS`
    display: inline-block;
  `}
`;

class Header extends React.Component {
  renderInner = () => {
    const { themeColor, hideLogoOnMobile, renderLeft, renderRight } = this.props;
    return (
      <ScrollWatcher>
        {({ isScrolled }) => (
          <Container style={{ top: 0, isScrolled }} themeColor={themeColor} >
            <InnerContainer>
              <Left>{renderLeft()}</Left>
              <StyledWordMark hideLogoOnMobile={hideLogoOnMobile} marginBottom={0} size="small" />
              <Right>{renderRight()}</Right>
            </InnerContainer>
          </Container>
        )}
      </ScrollWatcher>
    );
  };

  render() {
    const { isHideOnMobile } = this.props;
    if (isHideOnMobile) {
      return <PlusSizeScreen>{this.renderInner()}</PlusSizeScreen>;
    }
    return this.renderInner();
  }
}

Header.defaultProps = {
  renderLeft: () => null,
  renderRight: () => null,
  isHideOnMobile: false,
  hideLogoOnMobile: false,
};

Header.propTypes = {
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
  isHideOnMobile: PropTypes.bool,
  themeColor: PropTypes.oneOf(themeColors),
};

Header.themeColors = themeColors.reduce((acc, cur) => ({ [cur]: cur }), {});

export default Header;
