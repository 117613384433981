import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const SIZE = 48;
const strokeWidth = 7;

const Container = styled.div`
  svg,
  .StepCompletedContainer {
    width: ${SIZE}px;
    height: ${SIZE}px;
  }
`;

const ContainerSvg = styled.div`
  .circle-background,
  .circle-progress {
    fill: none;
  }

  .circle-background {
    stroke: ${() => theme.colors.blacks[5]};
  }

  .circle-progress {
    stroke: ${() => theme.colors.blues[50]};
  }

  .circle-text {
    fill: ${() => theme.colors.blues[60]};
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
  }
`;

class PieProgress extends React.Component {
  constructor(props) {
    super(props);
    const percentDone = 100 * (props.currentStep / props.totalSteps);
    this.state = {
      percentDone,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const percentDone = 100 * (nextProps.currentStep / nextProps.totalSteps);
    this.setState({
      percentDone,
    });
    return true;
  }

  renderCircularProgressSVG = () => {
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (SIZE - strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${SIZE} ${SIZE}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    // const dashOffset = (dashArray - dashArray) * (this.state.percentDone / 100);
    const dashOffset = (
      dashArray
      - ((dashArray * this.state.percentDone)
      / 100)
    );
    return (
      <ContainerSvg>
        <svg
          width={SIZE}
          height={SIZE}
          viewBox={viewBox}
        >
          <circle
            className="circle-background"
            cx={SIZE / 2}
            cy={SIZE / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
          />
          <circle
            className="circle-progress"
            cx={SIZE / 2}
            cy={SIZE / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${SIZE / 2} ${SIZE / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
            }}
          />
          <text
            className="circle-text"
            x="50%"
            y="50%"
            dy="6px"
            textAnchor="middle"
          >
            {this.props.currentStep}/{this.props.totalSteps}
          </text>
        </svg>
      </ContainerSvg>
    );
  }

  render = () => (
    <Container>
      {this.renderCircularProgressSVG()}
    </Container>
  );
}

PieProgress.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default PieProgress;
