import { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * This class is a wrapper around NotificationItem. It will cache
 * the first `highlighted` prop it is passed down to, and will keep
 * rendering with that same `higlighted` prop, even if the parent
 * sends different values. This is to allow unviewed notifications
 * to be highlighted, even though after the first render, we mark them
 * as rendered.
 */
const StickyNotificationItem = (props) => {
  const [highlighted] = useState(props.highlighted);
  return props.children({
    highlighted,
  });
};

StickyNotificationItem.propTypes = {
  highlighted: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
};

export default StickyNotificationItem;
