import omitStyled from '../../libs/omitStyled';
import theme from '@matterapp/matter-theme';

const Text = omitStyled('div', ['muted'])`
  font-size: 17px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ muted }) => muted && `
    font-size: 14px;
    line-height: 20px;
    color: ${theme.colors.blacks[50]};
  `}

`;

export default Text;
