import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { IconCopy } from '../Icon';
import Button from '../Button';
import InputCopyToClipboardOverlay from './InputCopyToClipboardOverlay';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ showOutsideCopyButton }) => !showOutsideCopyButton ? 'pointer' : 'default' };
  background: ${() => theme.colors.white};
  svg {
    margin-right: 8px;
    display: block;
    height: 16px;
    width: auto;

    path {
      fill: ${() => theme.colors.blacks[40]};
    }
  }

  ${({ showBorder }) => {
    if (showBorder) {
      return `
        padding: ${theme.sizes.padding[30]};
        border: ${theme.inputs.borderDefault} ${theme.colors.blacks[10]};
        border-radius: ${theme.sizes.borderRadius[50]};
        transition: border ${theme.transitions.times.default};
        &:hover {
          border-color: ${theme.colors.blacks[30]};
        }
      `;
    }
  }}
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ showCopyButton, theme, showOutsideCopyButton }) => {

    if (showOutsideCopyButton) {
      return `
        color: ${theme.colors.blacks[60]};
        line-height: ${theme.lineHeight.M};
        font-size: ${theme.fontSize.S2};
        flex: 1 1 auto;
      `;
    }
    if (showCopyButton) {
      return `
        color: ${theme.colors.blacks[80]};
        flex: 1 1 auto;
      `;
    }
    return `
      color: ${theme.colors.blacks[50]};
      font-weight: 400;
      line-height: 20px;
      font-size: ${theme.sizes.fontSizes[40]};

      &:hover {
        color: ${theme.colors.blacks[90]};
      }
      &:active {
        text-decoration: underline;
      }
    `;
  }}
`;

const CopyButton = styled.div`
  color: ${() => theme.colors.blue};
  text-transform: uppercase;
`;

const CopyButtonPrimary = styled(Button.Simple.Primary.Vivid).attrs({
  size: 'M',
})`
  width: 100%;
  background: ${() => theme.colors.greens[50]};
  margin-top: ${() => theme.spacing.half};
  ${({ copied }) =>
    copied && `
      color: ${theme.colors.greens[50]};
      background: ${theme.colors.white} !important;
      cursor: default;
      :hover {
        color: ${theme.colors.greens[50]};
      }
    `}
`;

export default class InputCopyToClipboard extends React.PureComponent {
  static propTypes = {
    /** Optional children to display. Overrides display of `text`. */
    children: PropTypes.node,
    /** Additional classes. */
    className: PropTypes.string,
    /** Text to display in the copy button (if displayed) */
    copyButtonText: PropTypes.string,
    /** Text to display in the copy button (if displayed) when text is copied. */
    copiedButtonLabel: PropTypes.string,
    /** Message to display in the copy overlay message. */
    copyOverlayMessage: PropTypes.node,
    /** Inline styles for the copy overlay. */
    copyOverlayStyle: PropTypes.object,
    /** Number of milliseconds to display the copy overlay message. */
    copyOverlayTime: PropTypes.number,
    /** Cypress label for testing applied to the input. */
    rcLabel: PropTypes.string,
    /** Callback when input is clicked to copy text. */
    onCopy: PropTypes.func,
    /** Callback when copy is complete (Same as when copy overaly dissapears. Can use to extenally control independent copy overlay). */
    onCopyComplete: PropTypes.func,
    /** Display border on copy input. */
    showBorder: PropTypes.bool,
    /** Displays the copy button in the input. */
    showCopyButton: PropTypes.bool,
    /** Displays the copy icon in the input. */
    showCopyIcon: PropTypes.bool,
    /** Displays overlay message on copy. */
    showOverlayOnCopy: PropTypes.bool,
    /** Displays copy button outside the input */
    showOutsideCopyButton: PropTypes.bool,
    /** The text that gets displayed in the input. Value is displayed if not passed in. */
    text: PropTypes.node,
    /** The value that gets copied. */
    value: PropTypes.string,
    trackLinkCopied: PropTypes.func,
  };

  static defaultProps = {
    copyButtonLabel: 'Copy',
    copiedButtonLabel: 'Copied',
    copyOverlayTime: 1000,
    onCopy: () => {},
    onCopyComplete: () => {},
    showBorder: false,
    showCopyButton: false,
    showCopyIcon: false,
    showOverlayOnCopy: false,
    value: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCopyOverlayActive: false,
    };
  }

  componentWillUnmount = () => {
    clearTimeout(this.linkCopyOverlayTimeout);
  };

  linkCopyOverlayTimeout = null;

  handleCopy = () => {
    const {
      copyOverlayTime,
      showOverlayOnCopy,
      onCopy,
      onCopyComplete,
      trackLinkCopied
    } = this.props;
    onCopy();

    if (trackLinkCopied) {
      trackLinkCopied();
    }
  
    if (showOverlayOnCopy || typeof onCopyComplete === 'function') {
      this.setState({ isCopyOverlayActive: true }, () => {
        this.linkCopyOverlayTimeout = setTimeout(() => {
          this.setState({ isCopyOverlayActive: false });
          this.linkCopyOverlayTimeout = null;
          onCopyComplete();
        }, copyOverlayTime);
      });
    }
  };

  render() {
    const {
      children,
      className,
      copyButtonLabel,
      copiedButtonLabel,
      copyOverlayMessage,
      copyOverlayStyle,
      copyOverlayTime,
      rcLabel,
      showBorder,
      showCopyButton,
      showCopyIcon,
      showOverlayOnCopy,
      showOutsideCopyButton,
      text,
      value,
    } = this.props;
    const { isCopyOverlayActive } = this.state;
    if (showOutsideCopyButton) {
      return (
      <>
        <Container
          className={className}
          showBorder={showBorder}
          showOutsideCopyButton={showOutsideCopyButton}
          data-rc={rcLabel}
        >
          <Title showOutsideCopyButton={showOutsideCopyButton} showCopyButton={showCopyButton}>{text || value}</Title>
        </Container>
        <CopyToClipboard onCopy={this.handleCopy} text={value}>
          <CopyButtonPrimary copied={isCopyOverlayActive}>{isCopyOverlayActive ? copiedButtonLabel : copyButtonLabel}</CopyButtonPrimary>
        </CopyToClipboard>
      </>
      );
    }
    return (
      <CopyToClipboard onCopy={this.handleCopy} text={value}>
        <Container
          className={className}
          showBorder={showBorder}
          data-rc={rcLabel}
        >
          {showCopyIcon && <IconCopy />}
          {children || (
            <Title showCopyButton={showCopyButton}>{text || value}</Title>
          )}
          {showCopyButton && (
            <CopyButton>
              {isCopyOverlayActive ? copiedButtonLabel : copyButtonLabel}
            </CopyButton>
          )}
          {isCopyOverlayActive && showOverlayOnCopy && (
            <InputCopyToClipboardOverlay
              animationDuration={copyOverlayTime}
              message={copyOverlayMessage}
              style={copyOverlayStyle}
            />
          )}
        </Container>
      </CopyToClipboard>
    );
  }
}
