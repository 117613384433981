import React from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Avatar from '../../../Avatar';
import Button from '../../../Button';
import Modal from '../../../Modal';
import Panel from '../../../Panel';
import AvatarPill from '../../../AvatarPill';
import AvatarHeader from '../../AvatarHeader';
import { Footer } from '../../FeedItem';

const MAX_PARTICIPANTS = 7;

const PanelBase = styled(Panel.Base)`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  >div:nth-child(2) {
    margin-top: ${theme.spacing.half};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing.singleAndHalf};
  border-top: 1px solid ${theme.colors.blacks[10]};
  margin: 0 ${theme.spacing.singleAndQuarter};
  margin-bottom: ${theme.spacing.singleAndHalf};
  ${theme.media.S`
    margin: 0 ${theme.spacing.singleAndHalf};
  `}
`;

const Title = styled.span`
  margin-bottom: ${theme.spacing.single};
`;

const Streak = styled.span`
  color: ${theme.colors.reds[40]};
  font-weight: ${theme.fontWeight.bold};
`;

const MembersContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing.singleAndQuarter};
`;

const ParticipantsContainer = styled.div`
  flex-direction: row;
  >div {
    margin: 0 ${theme.spacing.half} ${theme.spacing.half} 0;
  }
`;

const ModalBodyHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  line-height: ${theme.lineHeight.M};
  font-size: ${theme.fontSize.S};
  margin: 0 ${theme.spacing.singleAndQuarter};
  margin-bottom: ${theme.spacing.singleAndHalf};
  padding-top: ${theme.spacing.singleAndQuarter};
  padding-bottom: ${theme.spacing.single};
  ${theme.media.S`
    margin: 0 ${theme.spacing.singleAndHalf};
    padding: ${theme.spacing.singleAndHalf} 0;
  `}
`;

const Heading = styled.span`
  margin-left: ${theme.spacing.single};
  margin-right: ${theme.spacing.singleAndQuarter};
  align-self: center;
`;

const ViewRecapButton = styled(Button)`
  width: fit-content;
`;


export default class RitualRecap extends React.Component {
  static defaultProps = {
    commentProps: {},
  };

  constructor() {
    super();
    this.state = {
      isFullRecapOpen: false,
      isModalOpen: false,
    };
  }

  handleCloseModal = () => {
    this.setState({ isFullRecapOpen: false, isModalOpen: false });
  }

  handleToggleRecap = () => {
    this.setState(prevState => ({
      isFullRecapOpen: !prevState.isFullRecapOpen,
      isModalOpen: true,
    }));
  }

  renderParticipantsList(isFullListOpen) {
    const { participants } = this.props;
    const { isFullRecapOpen } = this.state;
    const participantsList = participants.length > MAX_PARTICIPANTS && !isFullRecapOpen || !isFullListOpen ? participants.slice(0, MAX_PARTICIPANTS) : participants;
    const restOfParticipantsLength = participants.length > MAX_PARTICIPANTS ? participants.length - MAX_PARTICIPANTS : null;

    return (
      <ParticipantsContainer>
        {participantsList.map(participant => (
          <AvatarPill key={participant.id} photoUrl={participant.photoUrl} color="purple">
            {participant.fullName}
          </AvatarPill>
        ))}
        {restOfParticipantsLength && !isFullRecapOpen &&
          <AvatarPill showAvatar={false} color="purple">
            +{restOfParticipantsLength} Others
          </AvatarPill>}
      </ParticipantsContainer>
    );
  }

  renderRecapBody(isModal) {
    const { winners, streakNumber, footerProps } = this.props;
    const { isFullRecapOpen } = this.state;
    const buttonLabel = isFullRecapOpen && isModal ? 'Hide Full Recap' : 'View Full Recap';

    return (
      <>
        {!!winners.length ? 
          <>
            {streakNumber && <Container>
              <Title>Team Participation Streak: <Streak>{streakNumber} Weeks!</Streak></Title>
            </Container>}
            <Container>
              <MembersContainer>
                <Title>Most kudos/feedback given:</Title>
                <ParticipantsContainer>
                  {winners.map(winner => 
                    <AvatarPill key={winner.id} photoUrl={winner.photoUrl} color="purple">
                      {winner.fullName}
                    </AvatarPill>)}
                </ParticipantsContainer>
              </MembersContainer>
              <MembersContainer>
                <Title>Gave the gift of feedback:</Title>
                {this.renderParticipantsList(isModal)}
              </MembersContainer>
              {!isModal && <ViewRecapButton color="black" size="XS" onClick={this.handleToggleRecap}>
                {buttonLabel}
              </ViewRecapButton>}
            </Container>
            <Footer append={!winners.length || isModal} expanded={isModal} autoFocus={false} {...footerProps} />
          </> :
          <Footer append={!winners.length} expanded={isModal} {...footerProps} />
        }
      </>
    );
  }

  renderRecapPanel() {
    const { mainMessage, secondaryMessage, photoUrl, winners, onClickDelete } = this.props;
    const panelHeader = 
      <AvatarHeader
        equalPadding={!winners.length}
        canClickHeader={false}
        header={mainMessage}
        subheader={secondaryMessage}
        photoUrl={photoUrl}
        showMenu={false}
        onClickDelete={onClickDelete}
      />;
    return (
      <PanelBase header={panelHeader}>
        {this.renderRecapBody(false)}
      </PanelBase>
    );
  }

  renderRecapModal() {
    const { isModalOpen } = this.state;
    const { mainMessage, secondaryMessage, photoUrl } = this.props;

    return (
      <Modal
        fullScreenMobile
        size="XL"
        isOpen={isModalOpen}
        onClose={this.handleCloseModal}
      >
        <Modal.Header
          centerOnMobile
          title="Full Recap"
          right={<Modal.Header.Buttons.Close onClick={this.handleCloseModal} />}
        />
        <Modal.Body>
          <ModalBodyHeaderContainer>
            <Avatar url={photoUrl} size={48} />
            <Heading><strong>{mainMessage}</strong> {secondaryMessage}</Heading>
          </ModalBodyHeaderContainer>
          {this.renderRecapBody(true)}
        </Modal.Body>
        <Modal.Footer.WithClose onClickClose={this.handleCloseModal} />
      </Modal>
    );
  }

  render() {
    return (
      <>
        {this.renderRecapPanel()}
        {this.renderRecapModal()}
      </>
    ); 
  }
}