import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 16c0 7.18-5.82 13-13 13S3 23.18 3 16 8.82 3 16 3s13 5.82 13 13zm-13 0L9 9v14h14V9l-7 7z"
        fill={fill}
      />
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
