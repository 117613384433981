import React from 'react';
import { Form } from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import { Field as ReduxFormField } from 'redux-form';

const CustomSkillField = class extends React.Component {
  componentDidMount() {
    if (this.props.autoFocus) {
      this.input.focus();
    }
  }

  renderField = (reduxFormFieldProps) => (
    <Form.Input
      size="massive"
      placeholder={this.props.placeholder}
      ref={(input) => {
        if (input) {
          this.input = input.ref;
        }
      }}
      type="text"
      {...reduxFormFieldProps.input}
    />
  );

  render() {
    return (
      <ReduxFormField
        name="customSkill"
        type="text"
        component={this.renderField}
      />
    );
  }
};

CustomSkillField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
};

CustomSkillField.defaultProps = {
  autoFocus: false,
};

export default CustomSkillField;
