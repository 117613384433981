import React from 'react';

const Icon = () => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.60449" width="1.5" height="19" fill="#3E3E42" />
    <circle cx="3.35449" cy="7.18372" r="2.15" fill="white" stroke="#3E3E42" strokeWidth="1.2" />
    <rect x="15.604" width="1.5" height="19" fill="#3E3E42" />
    <circle cx="16.3535" cy="7.18372" r="2.15" fill="white" stroke="#3E3E42" strokeWidth="1.2" />
    <rect x="9.10449" width="1.5" height="19" fill="#3E3E42" />
    <circle cx="9.85449" cy="12.9321" r="2.15" fill="white" stroke="#3E3E42" strokeWidth="1.2" />
  </svg>
);

export default Icon;
