import Form from './Form';
import ColorButtons from './ColorButtons';
import IconPreview from './IconPreview';
import IconCTA from './IconCTA';
import EditorLayout from './EditorLayout';

export default {
  Form,
  ColorButtons,
  IconPreview,
  IconCTA,
  EditorLayout,
};
