import React from 'react';
import styled from 'styled-components';
import { Clickable } from '../../Clickable/Clickable';
import { IconNew as Icon } from '../../Icon';
import Tab from './Tab'; 
import theme from '@matterapp/matter-theme';

export const VALUES = {
  REQUEST: 0,
  GIVE: 1,
  KUDOS: 2,
};

export const LABEL = {
  REQUEST: 'Request Feedback',
  GIVE: 'Give Feedback',
  KUDOS: 'Give Kudos',
};

export const LABEL_MOBILE = {
  REQUEST: 'Request',
  GIVE: 'Give',
  KUDOS: 'Kudos',
};

/*****************************************************************************/
/* TAB STYLES                                                                */
/*****************************************************************************/
export const TabIcon = styled.div`
  margin-bottom: ${theme.spacing.half};
  height: ${theme.spacing.singleAndHalf};
  & svg path {
    fill: currentColor;
  }
`;
export const TabLabel = styled.div`
  display: none;
  ${theme.media.M`
    display: block;
  `}
`;
export const TabLabelMobile = styled.div`
  display: block;
  ${theme.media.M`
    display: none;
  `}
`;
export const TabContainer = styled(Clickable)`
  align-items: center;
  color: ${({ isActive }) => theme.colors.blacks[isActive ? 80 : 40]};
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  font-size: ${theme.fontSize.S};
  padding: ${theme.spacing.singleAndQuarter} ${theme.spacing.half} ${theme.spacing.half};
  transition: color ${theme.transitions.times.default};

  &:not(:first-child) {
    border-left: 1px solid ${theme.colors.blacks[10]};
  }

  &:hover {
    color: ${({ isActive }) => theme.colors.blacks[isActive ? 80 : 60]};
  }

  &:active {
    color: ${theme.colors.blacks[80]};
  }

  &:only-child {
    color: ${theme.colors.blacks[80]};
    flex-direction: row;
    padding: ${theme.spacing.singleAndHalf} ${theme.spacing.singleAndHalf} 0;
    pointer-events: none;

    & ${TabIcon} {
      margin-bottom: 0;
      margin-right: ${theme.spacing.half};
    }
    & ${TabLabel} {
      display: block !important;
    }
    & ${TabLabelMobile} {
      display: none !important;
    }
  }
`;

export const RequestIcon = styled(Icon).attrs(() => ({
  name: 'requestFeedback',
}))``;
export const GiveIcon = styled(Icon).attrs(() => ({
  name: 'giveFeedback',
}))``;
export const KudosIcon = styled(Icon).attrs(() => ({
  name: 'clap',
}))``;

export const RequestTab = styled(Tab).attrs(() => ({
  icon: <RequestIcon />,
  label: LABEL.REQUEST,
  labelMobile: LABEL_MOBILE.REQUEST,
  value: VALUES.REQUEST,
}))``;
export const GiveTab = styled(Tab).attrs(() => ({
  icon: <GiveIcon />,
  label: LABEL.GIVE,
  labelMobile: LABEL_MOBILE.GIVE,
  value: VALUES.GIVE,
}))``;
export const KudosTab = styled(Tab).attrs(() => ({
  icon: <KudosIcon />,
  label: LABEL.KUDOS,
  labelMobile: LABEL_MOBILE.KUDOS,
  value: VALUES.KUDOS,
}))``;

/*****************************************************************************/
/* TABS STYLES                                                                */
/*****************************************************************************/
export const Container = styled.div`
  display: flex;
`;
