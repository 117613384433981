import * as qs from 'qs';
import { useNavigate, useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return {
    queryParams: (() => {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      return params;
    })(),
    setQueryParams: (queryParams, hash, replaceState = false) => {
      const params = qs.stringify(queryParams);

      if (hash) {
        navigate(`?${params}#${hash}`, { replace: replaceState });
      } else {
        navigate(`?${params}`, { replace: replaceState });
      }
    },
    clearErrorParams: () => {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      delete params.error;

      navigate(`?${qs.stringify(params)}${location.hash}`, { replace: true });
    },
  };
};

export default useQueryParams;
