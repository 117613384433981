import React from 'react';
import PropTypes from 'prop-types';
import WithActionButtons from './WithActionButtons';

const WithCancelSave = (props) => {
  const {
    canClickCancel,
    canClickSave,
    className,
    onClickSave,
    onClickCancel,
    saveIsDestructive,
    saveIsLoading,
    saveLabel,
    saveProps,
    cancelLabel,
    cancelProps,
    useVividSaveButton,
    ...otherProps
  } = props;

  return (
    <WithActionButtons
      {...otherProps}
      className={className}
      canClickPrimary={canClickSave}
      canClickSecondary={canClickCancel}
      onClickPrimary={onClickSave}
      onClickSecondary={onClickCancel}
      primaryIsDestructive={saveIsDestructive}
      primaryIsLoading={saveIsLoading}
      primaryLabel={saveLabel}
      primaryProps={saveProps}
      secondaryLabel={cancelLabel}
      secondaryProps={cancelProps}
      useVividPrimaryButton={useVividSaveButton}
    />
  );
};

WithCancelSave.defaultProps = {
  canClickCancel: true,
  canClickSave: true,
  saveLabel: 'Save',
  cancelLabel: 'Cancel',
  useVividSaveButton: true,
};

WithCancelSave.propTypes = {
  /** If cancel button can be clicked. */
  canClickCancel: PropTypes.bool,
  /** If save button can be clicked. */
  canClickSave: PropTypes.bool,
  /** Additional class. */
  className: PropTypes.string,
  /** Callback when save button is clicked. */
  onClickSave: PropTypes.func,
  /** Callback when cancel button is clicked. */
  onClickCancel: PropTypes.func,
  /** If save is destructive action. */
  saveIsDestructive: PropTypes.bool,
  /** If save button is loading. */
  saveIsLoading: PropTypes.bool,
  /** Save button label. */
  saveLabel: PropTypes.node,
  /** Additional props for save button. */
  saveProps: PropTypes.object,
  /** Cancel button label. */
  cancelLabel: PropTypes.node,
  /** Additional props for cancel button. */
  cancelProps: PropTypes.object,
  /** Use vivid save button. */
  useVividSaveButton: PropTypes.bool,
};

export default WithCancelSave;
