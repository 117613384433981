import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" fill="transparent" />
    <path
      d="M18.6 12.0899V16.4199V19.2899H5V5.68994H12.2"
      stroke={fill}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.6396 4H20.2396V8.6"
      stroke={fill}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M13.2403 11L20.2401 4"
      stroke={fill}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="bevel"
    />
  </svg>
);

Icon.defaultProps = {
  fill: 'black',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
