import React from 'react';
import Panel from '../../../Panel';
import AvatarHeader from '../../AvatarHeader';
import { Footer } from '../../FeedItem';

const EventsNewMembersAdded = ({
  firstMemberName,
  isInactive,
  footerProps = {},
  photoUrl,
  restOfMembersLength,
  isSelf,
  onClickAvatar,
  onClickDelete,
}) => {
  const plural = restOfMembersLength > 1 ? 'others' : 'other';
  const subheader = restOfMembersLength ? 
    <>and <strong>{restOfMembersLength} {plural}</strong> joined your workspace.</> : 
    'joined your workspace';
  const onClick = isSelf ? onClickAvatar : null;

  const panelHeader = 
    <AvatarHeader
      equalPadding
      header={firstMemberName}
      isInactive={isInactive}
      subheader={subheader}
      photoUrl={isInactive ? null : photoUrl}
      showMenu={false}
      canClickHeader={false}
      onClickAvatar={onClick}
      onClickDelete={onClickDelete}
    />;

  return (
    <Panel.Base header={panelHeader}><Footer append {...footerProps} /></Panel.Base>
  );
};

export default EventsNewMembersAdded;