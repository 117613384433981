import React, { useEffect, useState } from 'react';
import { CheckmarkContainer, CheckmarkIcon } from './styles';

export const useIsEmailSelected = (params) => {
  const { value } = params;
  return (email) => value.includes(email);
};

export const useHandleClickPeer = (params) => {
  const { onChange, value } = params;
  const isEmailSelected = useIsEmailSelected(params);

  return (e, { peer }) => {
    if (isEmailSelected(peer.email)) {
      onChange(e, {
        ...params,
        value: value.filter((email) => email !== peer.email),
      });
    } else {
      onChange(e, { ...params, value: [...value, peer.email] });
    }
  };
};

export const useSelectAll = (params) => {
  const { onChange, peers, selectedTextTitle, value } = params;
  const numberOfItemsSelected = value.length;
  const isAllSelected = peers.length === numberOfItemsSelected;
  const selectedItemsTextCount = `(${numberOfItemsSelected} Selected)`;
  return {
    selectedItemsText: selectedTextTitle
      ? `${selectedTextTitle} ${selectedItemsTextCount}`
      : selectedItemsTextCount,
    selectAllLinkProps: {
      content: isAllSelected ? 'Deselect All' : 'Select All',
      onClick: (e) => {
        if (isAllSelected) {
          onChange(e, { ...params, value: [] });
        } else {
          onChange(e, { ...params, value: peers.map(peer => peer.email) });
        }
      }
    },
  };
};

export const useRenderPeerActions = (params) => {
  const { value } = params;
  return ({ email }) => {
    const isSelected = value.includes(email);
    return (
      <CheckmarkContainer isSelected={isSelected}>
        <CheckmarkIcon checked={isSelected} />
      </CheckmarkContainer>
    );
  };
};

export const useWithFilter = (params) => {
  const { peers, placeholder, value, ...otherParams } = params;
  const { selectedItemsText, selectAllLinkProps } = useSelectAll(params);
  const [filterText, setFilterText] = useState('');
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (peers.length !== items.length) {
      setItems(peers);
    }
  }, [peers]);

  const filterItems = (filterText) => {
    if (!filterText) {
      setItems(peers);
    } else {
      const filteredPeers = peers.filter((peer) => {
        const filterValue = filterText.toLowerCase();
        const { email, fullName } = peer;
        return (
          (fullName && fullName.toLowerCase().includes(filterValue)) ||
          (email && email.toLowerCase().includes(filterValue))
        );
      });
      setItems(filteredPeers);
    }
  };

  const handleChangeFilterText = (e) => {
    const newFilterText = e.target.value;
    setFilterText(newFilterText);
    filterItems(newFilterText);
  };

  return {
    ...otherParams,
    filterBoxParams: {
      onChange: handleChangeFilterText,
      placeholder,
      value: filterText,
    },
    hasNoResults: !items.length && !!filterText,
    hasItems: items.length,
    selectAllLinkProps,
    selectedItemsText,
    peers: items,
    value,
  };
};
