import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Input } from '@matterapp/matter-ui';
import StepContainer from './StepContainer';
import theme from '@matterapp/matter-theme';

const REPLACE_NAME = '%n';
const DEFAULT_HEADER = `${REPLACE_NAME} and I currently work together`;

const BUTTON_COMPONENT = styled(Button.Simple.Minimal.Primary).attrs({
  bold: true,
  size: Button.Simple.buttonSizes.L,
})`
  border-radius: ${theme.borderRadius.M};
  padding: ${theme.spacing.singleAndHalf};
  z-index: 0;
`;

const YesComplicatedNo = styled(Input.Choices.YesComplicatedNo)`
  & ${BUTTON_COMPONENT} {
    color: ${theme.colors.blacks[60]};
  }
  &:hover ${BUTTON_COMPONENT} {
    &:not(:hover) {
      background: #f8f8f8;
      color: ${theme.colors.blacks[30]};
    }
  }
`;

export default class WorkRelationshipStep extends React.PureComponent {
  static propTypes = {
    /** Callback to change current step. */
    onChangeCurrentStep: PropTypes.func.isRequired,
    /** Callback when complicated button is clicked. */
    onClickComplicated: PropTypes.func.isRequired,
    /** Callback when no button is clicked. */
    onClickNo: PropTypes.func.isRequired,
    /** Callback when yes button is clicked. */
    onClickYes: PropTypes.func.isRequired,
    /** Callback to go to next step in timeline. */
    onClickNext: PropTypes.func,
    /** The name of the receiver. */
    receiversFullName: PropTypes.string,
    currentStep: PropTypes.number,
  }

  static defaultProps = {
    onClickNext: () => null,
  }

  /**
   * Gets the appropriate callback function for calling the next step in the timeline.
   * @param { Object } nextStepProps: {
   *   @param { Function } callback: The callback function to run.
   *   @param { Boolean } isWorkingTogether: If giver and receiver are working together.
   * }
   * @returns { Function } The next step callback.
   */
  getNextStepCallback = (nextStepProps) => {
    const { callback, isWorkingTogether } = nextStepProps;
    const { currentStep, onClickNext, onChangeCurrentStep } = this.props;
    return (e) => {
      callback(e, { ...this.props, isWorkingTogether });
      onClickNext();
      if (currentStep === 1) {
        onChangeCurrentStep(e, { currentStep: 2 });
      }
    };
  };

  /**
   * Gets callback function for clicking yes.
   * @returns { Function } The click yes callback.
   */
  getClickYesCallback = () => {
    return this.getNextStepCallback({
      callback: this.props.onClickYes,
      isWorkingTogether: true,
    });
  };

  /**
   * Gets callback function for clicking complicated.
   * @returns { Function } The click complicated callback.
   */
  getClickComplicatedCallback = () => {
    return this.getNextStepCallback({
      callback: this.props.onClickComplicated,
      isWorkingTogether: false,
    });
  };

  /**
   * Gets callback function for clicking no.
   * @returns { Function } The click no callback.
   */
  getClickNoCallback = () => {
    return this.getNextStepCallback({
      callback: this.props.onClickNo,
      isWorkingTogether: false,
    });
  };

  render = () => {
    const { receiversFullName } = this.props;
    const header = receiversFullName ? DEFAULT_HEADER.replace(REPLACE_NAME, receiversFullName) : '';
    return (
      <StepContainer header={header}>
        <YesComplicatedNo
          buttonComponent={BUTTON_COMPONENT}
          onClickYes={this.getClickYesCallback()}
          onClickNo={this.getClickComplicatedCallback()}
          onClickComplicated={this.getClickNoCallback()}
        />
      </StepContainer>
    );
  };
}
