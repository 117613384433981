import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Container from '../../Container';
import { Logo } from '../../Brand';
import { Notifications } from '../../Notification';
import {
  IconProfile,
  IconBell,
  IconHome,
  IconPeers,
} from '../../Icon';

import Tab from './Tab';
import HeaderAvatar from './HeaderAvatar';
import HeaderMenu from './HeaderMenu';
import SlideOutMenu from '../SlideOutMenu';
import ScrollWatcher from '../../Header/ScrollWatcher';
import ProfileMenu from '../../ProfileMenu';

export const height = 64;
export const mobileTabHeight = 48;
export const mobileShortThreshold = height;
export const shadowScrollThreshold = 5;

class Onboarded extends React.Component {
  static Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  `;

  static RedNotificationsCircle = styled.div`
    background: ${() => theme.colors.reds[50]};
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;

    &:hover {
      cursor: pointer;
    }

    transition: transform 0.1s ease-in-out;

    &:active {
      transform: translateY(-1.5px);
    }
  `;

  static RedNotificationsCircleText = styled.span`
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    color: white;
  `;

  static NotificationBell = styled(IconBell)`
    margin-right: 14px;

    path {
      fill: ${() => theme.colors.blues[40]};
    }

    &:hover {
      cursor: pointer;
    }

    transition: transform 0.1s ease-in-out;

    &:active {
      transform: translateY(-1.5px);

      path {
        fill: white;
      }
    }
  `;

  static HeaderMenuContainer = styled(HeaderMenu)`
    & .popover-menu__menu {
      display: ${({ showSlideMenuOnMobile }) =>
    showSlideMenuOnMobile ? 'none' : 'block'};
    }
    ${() => theme.media.M`
      & .popover-menu__menu {
        display: block;
      }
    `}
  `;

  static HeaderSlideOutMenu = styled(SlideOutMenu)`
    display: ${({ showSlideMenuOnMobile }) =>
    showSlideMenuOnMobile ? 'inherit;' : 'none'};
    & ${ProfileMenu.Link} {
      margin: ${() => theme.spacing.single} 0;
    }
    ${() => theme.media.M`
      display: none;
    `};
  `;

  static HeaderContainer = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0 ${() => theme.sizes.padding[50]};
    background: ${() => theme.colors.purple};

    color: ${() => theme.colors.blues[40]};
    font-weight: bold;
    text-transform: capitalize;
    user-select: none;

    transition: top 0.2s ease;

    z-index: 50;

    /*
    The ::after is the shadow that shows when scrolled.
    It is a nicer animation than animating box-shadow
  */
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08),
        0px 1px 1px rgba(0, 0, 0, 0.15);
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    /*
    isScrolled is passed through style cause react complains about props. Styled
    components doesnt give us a way to specify props for a styled component.
  */
    ${({ style }) =>
    style.isScrolled &&
      `
      &::after {
        opacity: 1;
      }
    `}

    ${() => theme.media.medium`
      transition: none;
      top: 0 !important;
    `}
  `;

  static LogoContainer = styled.div`
    height: ${height}px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    order: 10;

    width: 50%;

    span {
      display: inline-block;
      margin-left: 16px;
      color: white;
    }

    ${() => theme.media.medium`
      position: absolute;
      top: 0;
      left: 0;
      width: auto;

      span {
        color: ${theme.colors.blues[40]};
      }
    `}

    ${() => theme.media.large`
      position: static;

      span {
        display: none;
      }
    `}
  `;

  static ClickableBrand = styled.div`
    position: relative;
    cursor: pointer;

    img {
      transition: transform 0.05s ease;
    }

    &:active {
      img {
        transform: translateY(-2px);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 2.5px);
    }
  `;

  static TabsContainer = styled.div`
    flex: 1;
    order: 20;
    height: ${mobileTabHeight}px;

    display: flex;
    align-items: center;
    justify-content: stretch;

    margin: 0 ${() => `-${theme.sizes.padding[50]}`};

    width: 100%;

    ${() => theme.media.medium`
      height: ${height}px;
      justify-content: center;
      margin: 0;
      width: auto;
    `}

    ${() => theme.media.large`
      justify-content: flex-start;
    `}
  `;

  static AvatarContainer = styled.div`
    align-items: center;
    display: flex;
    height: ${height}px;
    justify-content: flex-end;

    width: 50%;

    order: 15;

    ${() => theme.media.medium`
      order: 30;
      width: auto;
      position: absolute;
      top: 0;
      right: 0;
    `}

    ${() => theme.media.large`
      position: static;
    `}
  `;

  static icons = {
    home: IconHome,
    notifications: IconBell,
    profile: IconProfile,
    peers: IconPeers,
  };

  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: false,
    };
  }

  renderNotifications = () => {
    const {
      hasBeenNotified,
      unreadNotificationsCount,
      onClickNotifications,
    } = this.props;

    if (!hasBeenNotified) {
      return null;
    } else if (unreadNotificationsCount > 0 && hasBeenNotified) {
      return (
        <Onboarded.RedNotificationsCircle onClick={onClickNotifications}>
          <Onboarded.RedNotificationsCircleText>
            {unreadNotificationsCount}
          </Onboarded.RedNotificationsCircleText>
        </Onboarded.RedNotificationsCircle>
      );
    } else if (unreadNotificationsCount === 0 && hasBeenNotified) {
      return <Onboarded.NotificationBell onClick={onClickNotifications} />;
    }
  };

  renderMobileLabel() {
    const {
      activeTab,
      tabs,
    } = this.props;
    if (typeof activeTab !== 'number') {
      return null;
    }
    if (tabs[activeTab].isHidden) {
      return null;
    } else {
      return (<span>{typeof activeTab == 'number' ? tabs[activeTab].label : null}</span>);
    }
  }

  render() {
    const {
      activeTab,
      user,
      onClickBrand,
      tabs,
      menu,
      slideMenu,
      showSlideMenuOnMobile,
    } = this.props;
    return (
      <ScrollWatcher
        onMobileScroll={() => this.setState({ menuIsOpen: false })}
      >
        {({ mobileTop, isScrolled }) => (
          <Onboarded.HeaderContainer style={{ top: mobileTop, isScrolled }}>
            <Notifications hasAppHeader />
            <Container
              maxWidth={theme.sizes.webClient.maxContentWidth}
              style={{ position: 'relative' }}
              width="100%"
            >
              <Onboarded.Container>
                <Onboarded.LogoContainer>
                  <Onboarded.ClickableBrand onClick={onClickBrand}>
                    <Logo type="color" size="small" marginBottom={0} />
                  </Onboarded.ClickableBrand>
                  {this.renderMobileLabel()}
                </Onboarded.LogoContainer>
                <Onboarded.TabsContainer>
                  {tabs.map((tab, i) => {
                    if (tab.isHidden) {
                      return null;
                    }
                    return (
                      <Tab
                        hasNotification={!!tab.hasNotification}
                        iconComponent={tab.iconComponent}
                        isActive={i === activeTab}
                        key={tab.label}
                        renderLink={tab.renderLink}
                      >
                        {tab.label}
                      </Tab>
                    );
                  })}
                </Onboarded.TabsContainer>
                <Onboarded.AvatarContainer>
                  {this.renderNotifications()}
                  <Onboarded.HeaderMenuContainer
                    triggerComponent={
                      <HeaderAvatar url={user.photoUrl} email={user.email} />
                    }
                    isOpen={this.state.menuIsOpen}
                    onOpen={() => this.setState({ menuIsOpen: true })}
                    onClose={() => this.setState({ menuIsOpen: false })}
                    showSlideMenuOnMobile={showSlideMenuOnMobile}
                  >
                    {menu}
                  </Onboarded.HeaderMenuContainer>
                  <Onboarded.HeaderSlideOutMenu
                    isOpen={this.state.menuIsOpen}
                    showSlideMenuOnMobile={showSlideMenuOnMobile}
                  >
                    {slideMenu || menu}
                  </Onboarded.HeaderSlideOutMenu>
                </Onboarded.AvatarContainer>
              </Onboarded.Container>
            </Container>
          </Onboarded.HeaderContainer>
        )}
      </ScrollWatcher>
    );
  }
}

Onboarded.defaultProps = {
  activeTab: null,
  unreadNotificationsCount: null,
};

Onboarded.propTypes = {
  activeTab: PropTypes.number,
  onClickBrand: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  menu: PropTypes.node.isRequired,
  slideMenu: PropTypes.node, // Override menu contents for slidemenu.
  showSlideMenuOnMobile: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      iconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
        .isRequired,
      renderLink: PropTypes.func,
      label: PropTypes.string.isRequired,
      hasNotification: PropTypes.bool,
    })
  ).isRequired,
  unreadNotificationsCount: PropTypes.number,
  hasBeenNotified: PropTypes.bool.isRequired,
  onClickNotifications: PropTypes.func.isRequired,
};

export default Onboarded;
