import React from 'react';
import { Resources } from '@matterapp/routing';
import {
  Peer,
  Panel,
  LeftRight,
  Container,
  ButtonNew as Button,
} from '@matterapp/matter-ui';
import PropTypes from 'prop-types';
import { Experiment } from 'modules/experiments';
import { PeerSuggestionsTrackOnMount } from '../../track-on-mount';
import styled from 'styled-components';

const HEADER_TITLE = 'Recommended for you';

const FindYourPeersButton = styled(Button).attrs({
  to: Resources.findYourPeersConnectYourAccounts.path(),
  primary: true,
  size: 'small',
  rcLabel: 'findYourPeersButton',
})`
  &:hover:not([disabled]) {
    color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
  }
`;

const PeerSuggestionsList = styled(Peer.Suggestions.List)`
  ${({ showAllSuggestionsLink }) =>
  !showAllSuggestionsLink &&
    `
    & [data-type=${Peer.Suggestions.ListItem.componentDataType}]:last-child {
      margin-bottom: -1px;
    }
  `}
`;

const ViewAllSuggestionsLink = styled(Panel.Footer.Action).attrs({
  centered: false,
})`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: bold;
`;

class PeerRecommendationsList extends React.Component {
  getNumberSuggestionsToShow() {
    const { numMaxPeers, numExpandedPeers, isExpanded } = this.props;
    if (numExpandedPeers === null) {
      return numMaxPeers;
    }
    if (!isExpanded) {
      return numMaxPeers;
    }
    return Math.max(numMaxPeers, numExpandedPeers);
  };

  async handleAcceptPeer(e, props) {
    const { waitToReplace, onAcceptPeer, handlePickPeer } = this.props;
    if (waitToReplace) {
      return onAcceptPeer(e, props);
    } else {
      await onAcceptPeer(e, props);
      return handlePickPeer(e, props);
    }
  }

  renderPeerSuggestionsPanelHeader() {
    return (
      <Panel.Header.Basic
        title="Find new suggestions"
        size={Panel.Header.SIZE.LARGE}
      >
        You can sync your contacts and calendar to find more Advisors and ask them for
        feedback.
      </Panel.Header.Basic>
    );
  }

  renderFindYourPeers() {
    return (
      <Panel.Base
        header={this.renderPeerSuggestionsPanelHeader()}
        footer={<Panel.Footer.Empty />}
      >
        <Panel.Block.Basic>
          <LeftRight
            right={
              <Container marginTop={16}>
                <FindYourPeersButton>Find your Advisors</FindYourPeersButton>
              </Container>
            }
          />
        </Panel.Block.Basic>
      </Panel.Base>
    );
  };

  /**
   * Renders a basic header for the peers list.
   * @param { Object } headerProps: Props of the header: {
   *   @param { String } title: The text of the header.
   *   @param { String } size: The size of the header.
   * }
   * @returns { React.Component } The header for the list.
   */
  renderBasicHeader({ title, size } = {}) {
    return (
      <React.Fragment>
        <Panel.Header.Basic
          title={title || HEADER_TITLE}
          size={size || Panel.Header.SIZE.LARGE}
        />
      </React.Fragment>
    );
  };

  renderHeader() {
    return (
      <React.Fragment>
        <Panel.Header.Basic
          flatTop
          title={HEADER_TITLE}
          size={Panel.Header.SIZE.LARGE}
        />
      </React.Fragment>
    );
  };

  /**
   * Renders the peer suggestion list.
   * @param { Object } listProps: Additional props for the list {
   *   @param { Boolean } isInRightRail: If list is rendered in the right rail.
   * }
   */
  renderList({ isInRightRail } = {}) {
    const {
      handleRejectPeer,
      peerRecommendations,
      renderProfileCard,
      showAllSuggestionsLink,
      showProfileCard,
      showDismissButton,
      waitToReplace,
      acceptButtonLabel,
    } = this.props;

    return (
      <Experiment name="default-avatar">
        {({ assignment }) => {
          return (
            <PeerSuggestionsList
              waitToReplace={waitToReplace}
              acceptButtonLabel={acceptButtonLabel || "Request"}
              defaultAvatarIsGenerated={assignment === 'treatment'}
              peers={peerRecommendations}
              isNarrow={isInRightRail}
              numberOfPeersShown={this.getNumberSuggestionsToShow()}
              onClickAccept={this.handleAcceptPeer}
              onClickDismiss={handleRejectPeer}
              renderProfileCard={showProfileCard ? renderProfileCard : null}
              showAllSuggestionsLink={showAllSuggestionsLink}
              showDismissButton={showDismissButton}
            />
          );
        }}
      </Experiment>
    );
  };

  renderTrackOnMount = () => {
    const { peerRecommendations, invitationSource } = this.props;
    if (peerRecommendations.length < 1) {
      return null;
    }
    return (
      <PeerSuggestionsTrackOnMount
        source={invitationSource}
        numPeers={peerRecommendations.length}
      />
    );
  };

  /**
   * Renders the action footer for the peers list.
   * @returns { React.Component } The footer for the list.
   */
  renderActionFooter = () => {
    const {
      numExpandedPeers,
      numMaxPeers,
      handleExpand,
      isExpanded,
      peerRecommendations,
      handleClickViewAll,
      showAllSuggestionsLink,
    } = this.props;

    const numPeers = peerRecommendations.length;
    if (numPeers <= numMaxPeers) {
      return null;
    }
    if (numExpandedPeers === null && !showAllSuggestionsLink) {
      return null;
    }
    const numberSuggestionsToShow = this.getNumberSuggestionsToShow();

    if (showAllSuggestionsLink) {
      return (
        <ViewAllSuggestionsLink
          actionLabel={`View All Suggestions (${numPeers})`}
          onActionClick={handleClickViewAll}
        />
      );
    }
    if (isExpanded && numPeers > numberSuggestionsToShow) {
      return (
        <Panel.Footer.Action
          actionLabel={'View all'}
          onActionClick={handleClickViewAll}
        />
      );
    }

    if (!isExpanded && numPeers > numberSuggestionsToShow) {
      return (
        <Panel.Footer.Action
          actionLabel="Show more results"
          onActionClick={handleExpand}
          iconName="karotDown"
        />
      );
    }
    return null;
  };

  render = () => {
    const {
      peerRecommendations,
      isPeerRecommendationsLoaded,
      children,
    } = this.props;
    if (!isPeerRecommendationsLoaded) {
      return null;
    }
    if (!peerRecommendations) {
      return null;
    }
    return (
      <React.Fragment>
        {this.renderTrackOnMount()}
        {children({
          isEmpty: peerRecommendations.length === 0,
          renderList: this.renderList,
          renderBasicHeader: this.renderBasicHeader,
          renderActionHeader: this.renderHeader,
          renderActionFooter: this.renderActionFooter,
          renderFindYourPeers: this.renderFindYourPeers,
        })}
      </React.Fragment>
    );
  };
}

PeerRecommendationsList.defaultProps = {
  onAcceptPeer: () => null,
  numMaxPeers: null,
  numExpandedPeers: null,
  goToFindYourPeersFlowOnButtonClick: true,
};

// TODO: this component is out of control. Noone can handle this many props. Let's break this monster down.
PeerRecommendationsList.propTypes = {
  /** If peer recommendations data is loaded. */
  isPeerRecommendationsLoaded: PropTypes.bool.isRequired,
  /** If peer recommendation list is expanded. */
  isExpanded: PropTypes.bool.isRequired,
  /** Children components */
  children: PropTypes.func.isRequired,
  /** List of peer pecommendations. */
  peerRecommendations: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      fullName: PropTypes.string,
      photoUrl: PropTypes.string,
    })
  ).isRequired,
  /** Callback when rejecting a peer in the peer list. */
  handleRejectPeer: PropTypes.func.isRequired,
  /** Callback when selecting a peer in the peer list. */
  handlePickPeer: PropTypes.func.isRequired,
  /** Callback when clicking the view all button. */
  handleClickViewAll: PropTypes.func.isRequired,
  /** The maximum number of peers to show.  */
  numMaxPeers: PropTypes.number,
  /** Number of peer items expanded. */
  numExpandedPeers: PropTypes.number,
  /** Source of flow */
  invitationSource: PropTypes.string.isRequired,
  /** Callback helper function to render an individual profile card. */
  renderProfileCard: PropTypes.func.isRequired,
  /** Callback when expanding peer list. */
  handleExpand: PropTypes.func.isRequired,
  /** Additional callback if a peer is accepted. Used to determine if at least one peer suggestion was clicked. */
  onAcceptPeer: PropTypes.func,
  /** Always show the view all suggestions link. */
  showAllSuggestionsLink: PropTypes.bool,
  /** Show the dismiss button on each peer suggestion item. */
  showDismissButton: PropTypes.bool,
  /** Display profile card on hover. */
  showProfileCard: PropTypes.bool,
  /** Boolean to make sure suggestion is not accepted right away */
  waitToReplace: PropTypes.bool,
  /** Overrides default accept button label */
  acceptButtonLabel: PropTypes.string,
};

export default PeerRecommendationsList;
