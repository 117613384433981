import React from 'react';
import { Confirm, IconNew as Icon, Tooltip } from '@matterapp/matter-ui';
import { colors } from '@matterapp/matter-theme';
import { mixpanel } from 'libs/tracking';
import {
  SectionHeader,
  PricingList,
  PriceDetail,
  PlanBreakDown,
  PricingDetailAmount
} from '../styles';
import { UPDATE_BILLING_PLAN, RECURRING_INTERVAL } from '../consts';
import {
  AddOn,
  StyledCheckbox
} from './styles';
import { 
  Box,
  Typography
} from '@mui/material';

const handleTracking = {
  yearlySelected: mixpanel.createEventSender('billing:billed-annually-clicked'),
  monthlySelected: mixpanel.createEventSender('billing:billed-monthly-clicked')
};

const pricingList = (selectedAddOns) => {
  const savingsText = selectedAddOns?.length ? '(33% Savings)': '(25% Savings)';
  return [{ label: 'Billed monthly', value: 'month'}, { label: `Billed annually ${savingsText}`, value: 'year'}];
};

function BillingInfo({ 
  isConfirmModalOpen, 
  updateConfirmModal, 
  updateSelectedInterval, 
  intervalSelected, 
  subscriptionPlans,
  totalMembers,
  coupon,
  primaryChannel,
  teamName,
  isEnterprise,
  addOns,
  selectedAddOns,
  toggleAddOns
}) {
  if (!subscriptionPlans.length) {
    return null;
  }

  let userCountTooltip = primaryChannel ? (
    `Users = Number of channel members in your #${primaryChannel} channel.`
  ) : (
    `Users = Number of members in your ${teamName} team.`
  );

  if (isEnterprise) {
    userCountTooltip = 'Users = Number of unique members in your company.';
  }

  const currentPlan = subscriptionPlans.find(({ interval }) => interval === intervalSelected);
  const { tierApplied, perUnit } = currentPlan || {};
  const { maxMembers, minMembers } = tierApplied || {};
  let { amountDecimal } = tierApplied || perUnit;
  const { percentOff, amountOff = 0 } = coupon || {};

  const membersText = maxMembers ? `${minMembers}-${maxMembers}` : totalMembers.toLocaleString('en-US');
  const addOnsTotalCost = addOns
    .filter(({ productId, interval }) => selectedAddOns.includes(productId) && intervalSelected === interval)
    .reduce((acc, { perUnit }) => {
      return acc + perUnit.amountDecimal;
    }, 0);

  amountDecimal += addOnsTotalCost;

  let planCost = intervalSelected === 'year' ? amountDecimal / 1200 : amountDecimal / 100;
  let planCostPerMonth = planCost.toLocaleString('en-US');
  let planBreakdown = `${planCostPerMonth}/mo ${intervalSelected === 'year' ? 'X 12mo' : '' } (${membersText} members)`;

  if (perUnit) {
    planCost = amountDecimal / 100 / (intervalSelected === 'year' ? 12 : 1);
    planCostPerMonth = planCost.toLocaleString('en-US');
    planBreakdown = `${planCostPerMonth}/user/mo ${intervalSelected === 'year' ? 'X 12mo' : '' } (${membersText} members)`;
    amountDecimal = amountDecimal * totalMembers;
  }

  const amountOffDollars = amountOff ? (amountOff / 100).toFixed(2) : null;
  const hasCoupon = coupon && amountOffDollars;
  const totalCost = hasCoupon ? ((amountDecimal - amountOff) / 100).toFixed(2) : (amountDecimal - amountOff) / 100;

  return (
    <>
      <Confirm
        header={UPDATE_BILLING_PLAN.HEADER}
        subHeader={UPDATE_BILLING_PLAN.SUBHEADER}
        isOpen={isConfirmModalOpen}
        confirmButtonLabel='Yes, I am sure'
        cancelButtonLabel='Cancel'
        onClickCancel={() => updateConfirmModal(false)}
        onClickConfirm={updateSelectedInterval}
      />
      <SectionHeader>Upgrade to Matter Pro</SectionHeader>
      <PricingList 
        className='pricing-list'
        name='billing frequency'
        values={pricingList(selectedAddOns)}
        value={(intervalSelected)}
        onChange={(e, { value }) => {
          updateSelectedInterval(value);
          
          if (value === RECURRING_INTERVAL.YEAR) {
            handleTracking.yearlySelected();
          } else {
            handleTracking.monthlySelected();
          }
        }}
      />
      {addOns
        .filter(({ interval }) => interval === intervalSelected)
        .map(({ name, productId, perUnit, interval }) => {
          const breakdown = `+$${(perUnit.amountDecimal / (interval === 'month' ? 100 : 1200)).toLocaleString('en-US')}/user/month`;

          return (
            <AddOn key={productId}>
              <StyledCheckbox 
                onChange={() => toggleAddOns(productId)}
                checked={selectedAddOns.includes(productId)}
                iconSize={30}
                checkmarkSize={24}
                light
                isCheckboxV2
                showAnimation={false}
              />
              <Typography 
                variant='body1' 
                onClick={() => toggleAddOns(productId)}
                sx={{ lineHeight: '26px', cursor: 'pointer' }}
              >
                {`Include ${name} (${breakdown})`}
              </Typography>
            </AddOn>
          );
        })}
      {currentPlan && (
        <Box sx={{ m: '24px 0' }}>
          <PriceDetail>
            <PlanBreakDown>
              ${planBreakdown}
              <div style={{ marginLeft: 4 }}>
                <Tooltip
                  content={userCountTooltip}
                >
                  <Icon
                    name="infoCircle"
                    fill={colors.blacks[30]}
                    className='info'
                  />
                </Tooltip>
              </div>
            </PlanBreakDown>
            <PricingDetailAmount>${(amountDecimal / 100).toLocaleString('en-US')} USD</PricingDetailAmount>
          </PriceDetail>
          {hasCoupon && (
            <>
              <PriceDetail>
                <div>{percentOff}% Discount</div>
                <PricingDetailAmount>-${(amountOffDollars).toLocaleString('en-US')} USD</PricingDetailAmount>
              </PriceDetail>
              <PriceDetail>
                <PricingDetailAmount>Total Due</PricingDetailAmount>
                <PricingDetailAmount>${(totalCost).toLocaleString('en-US')} USD</PricingDetailAmount>
              </PriceDetail>
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default BillingInfo;