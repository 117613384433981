import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import FilterBar from 'components/FilterBar';
import getRewardsCountries from 'graphql-queries/queries/rewards/getRewardsCountries';
import { ALL_COUNTRIES_ITEM, REWARD_TYPE_ITEMS, REWARD_TYPES } from './consts';

const RewardsFilterBar = ({ selectedCountry, setSelectedCountry, selectedType, setSelectedType, disabledRewardTypes }) => {
  const { loading, error, data } = useQuery(getRewardsCountries);

  if (loading || error) {
    return null;
  }
  let rewardTypesList = REWARD_TYPE_ITEMS.filter(({ value }) => !disabledRewardTypes?.includes(value));

  if (rewardTypesList.length === 2) {
    rewardTypesList = rewardTypesList.filter(({ value }) => value !== REWARD_TYPES.ALL);

    setSelectedType(rewardTypesList[0].value);
  }

  return (
    <FilterBar
      listsOfItems={[
        { 
          items: rewardTypesList, 
          value: selectedType,
          onChange: (e, { value }) => setSelectedType(value) 
        }, 
        {
          items: [ALL_COUNTRIES_ITEM, ...data.rewardsCountries],
          value: selectedCountry,
          onChange: (e, { value }) => setSelectedCountry(value)
        }
      ]}
      items={REWARD_TYPE_ITEMS}
      value={selectedType}
      onChange={(e, { value }) => setSelectedType(value)}
      additionalDropdownItems={[ALL_COUNTRIES_ITEM, ...data.rewardsCountries]}
      additionalItemsValue={selectedCountry}
      additionalItemsOnChange={(e, { value }) => setSelectedCountry(value)}
    />
  );
};

RewardsFilterBar.propTypes = {
  selectedCountry: PropTypes.string,
  setSelectedCountry: PropTypes.func,
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func,
  disabledRewardTypes: PropTypes.array
};

export default RewardsFilterBar;