import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { SKILL_GENERATOR_FORM_FIELD_NAMES } from '../consts';

const withData = ({ formName }) =>
  connect((state) => ({
    currentColorPalette: (() => {
      const selector = formValueSelector(formName);
      return selector(
        state,
        SKILL_GENERATOR_FORM_FIELD_NAMES.SKILL_COLOR_PALETTE
      );
    })(),
  }));

export default withData;
