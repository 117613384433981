import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '../../../../Tooltip';
import Buttons from '../Buttons';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  position: relative;
  border-bottom: 1px solid ${theme.colors.blacks[10]};
  ${({ isRecurring }) => isRecurring && `
    background: ${theme.colors.greens[5]};
    border-radius: ${theme.borderRadius.L} ${theme.borderRadius.L} 0 0;
    border-bottom: none;
  `}
`;

const CloseContainer = styled.div`
  position: absolute;
  top: ${theme.spacing.singleAndHalf};
  right: ${theme.spacing.singleAndHalf};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px;
  padding-bottom: ${theme.spacing.singleAndHalf};
  ${theme.media.S`
    align-items: flex-start;
  `}
`;

const AvatarContainer = styled.div`
  border: 4px solid white;
  border-radius: 100px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  ${theme.media.S`
    flex-direction: row;
  `}
`;

const ContentContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  ${AvatarContainer}:not(:empty) + & {
    margin: ${theme.spacing.single} 0 0 0;
  }
  ${theme.media.S`
    text-align: left;
    margin-top: 0;
    ${AvatarContainer}:not(:empty) + & {
      margin: 0 0 0 ${theme.spacing.single};
      ${({ truncated }) => truncated && 'margin-left: 20px;'}
    }
  `}
`;

const getSharedTextStyles = ({ truncated }) => {
  const shared = `
    color: ${theme.colors.blacks[90]}; 
  `;
  return truncated ? `
    ${shared}
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 280px;
    margin-left: -${theme.spacing.half};
  ` : shared;
};

const HeaderContainer = styled.div`
  font-size: ${theme.fontSize.L};
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: ${theme.spacing.half};
  line-height: ${theme.lineHeight.L};
  ${getSharedTextStyles}
`;

const SubHeaderContainer = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  ${getSharedTextStyles}
`;

const DescriptionContainer = styled.div`
  margin-top: ${theme.spacing.single};
  margin-left: 0;
  font-size: ${theme.fontSize.base};
  ${({ subHeader }) => !subHeader ? `margin-top: ${theme.spacing.half};` : null }
  ${theme.media.S`
    margin-left: 110px;
    ${({ subHeader }) => !subHeader ? 
    `margin-top: -${theme.spacing.threeQuarters};` : 
    `margin-top: ${theme.spacing.half};`}
  `}
`;

const TRUNCATED_LENGTH = 25;
const isTruncated = (string) => string && string.length > TRUNCATED_LENGTH;

const WithAvatar = ({ 
  avatar,
  className,
  description,
  header,
  onClickClose,
  subHeader,
  truncateHeader,
  isRecurringAdvisor,
}) => {
  const isHeaderTruncated = truncateHeader && isTruncated(header);
  const isSubHeaderTruncated = truncateHeader && isTruncated(subHeader);

  const truncatedHeader = isHeaderTruncated ? 
    <Tooltip
      dark
      content={header}
      style={{ maxWidth: '500px' }}
    >
      <HeaderContainer truncated>{header}</HeaderContainer>
    </Tooltip> :
    <HeaderContainer>{header}</HeaderContainer>;

  const truncatedSubHeader = isSubHeaderTruncated ? 
    <Tooltip
      dark
      content={subHeader}
      style={{ maxWidth: '500px' }}
    >
      <SubHeaderContainer truncated>{subHeader}</SubHeaderContainer>
    </Tooltip> :
    <SubHeaderContainer>{subHeader}</SubHeaderContainer>;

  return (
    <Container className={className} isRecurring={isRecurringAdvisor}>
      <CloseContainer>
        <Buttons.Close onClick={onClickClose} />
      </CloseContainer>
      <Wrapper>
        <ContentWrapper>
          <AvatarContainer>{avatar}</AvatarContainer>
          <ContentContainer truncated={isHeaderTruncated}>
            {truncatedHeader}
            {truncatedSubHeader}
          </ContentContainer>
        </ContentWrapper>
        {description && <DescriptionContainer subHeader={subHeader}>{description}</DescriptionContainer>}
      </Wrapper>
    </Container>
  );
};

WithAvatar.defaultProps = {
  truncateHeader: true,
};

WithAvatar.propTypes = {
  avatar: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  header: PropTypes.string,
  onClickClose: PropTypes.func,
  subHeader: PropTypes.string,
  truncateHeader: PropTypes.bool,
};

WithAvatar.AvatarContainer = AvatarContainer;
WithAvatar.CloseContainer = CloseContainer;
WithAvatar.HeaderContainer = HeaderContainer;

export default WithAvatar;
