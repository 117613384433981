import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import {
  height as headerHeight,
  mobileTabHeight as headerMobileTabHeight,
} from '../NavBar/Onboarded/Onboarded';

// PageContent is intended to be used with the HeaderNew. This handles padding
// that works with the fixed nav bar.

const PageContent = styled.div`
  padding-top: ${headerHeight + headerMobileTabHeight}px;

  ${() => theme.media.medium`
    padding-top: ${headerHeight}px;
  `}
`;

export default PageContent;
