
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { AvatarCard, Button, Clickable } from '@matterapp/matter-ui';
import SkillsBanner from '../skillsBanner.svg';
import { Resources } from '@matterapp/routing';

export const requestFeedbackPath = Resources.findYourPeersConnectYourAccounts.path();

export const FeedbackButton = styled(Button.Simple.Minimal).attrs({
  size: 'XS',
})`
  width: 160px;
`;

export const Background = styled.img.attrs({
  src: SkillsBanner
})`
  bottom: 0;
  right: 50%;
  display: none;
  height: 100px;
  ${theme.media.M`
    position: absolute;
    right: 0;
    transform: translateX(0%);
    display: block;
    height: 200px;
    top: 10px;
  `}
`;

export const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${theme.colors.yellows[20]};
  border: 1px solid ${theme.colors.yellows[40]};
  padding: ${theme.spacing.singleAndHalf};
  margin-bottom: ${theme.spacing.singleAndHalf};
  margin-top: -${theme.spacing.singleAndHalf};
  position: relative;
  text-align: center;
  color: ${theme.colors.blacks[80]};
  height: 192px;
  ${theme.media.S`
    justify-content: flex-start;
    padding: ${theme.spacing.singleAndHalf};
    margin-top: 0;
    text-align: left;
    border-radius: ${theme.borderRadius.L};
    height: 200px;
    overflow: hidden;
  `}
`;

export const Heading = styled.div`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.M};
  line-height: ${theme.spacing.double};
  margin-bottom: ${theme.spacing.single};
`;

export const AvatarDescription = styled.div`
  display: none;
  ${theme.media.M`
    margin: 0 50px 0;
    display: block;
  `}
`;

export const AddASkillButton = styled(Button.Simple)`
  margin: ${theme.spacing.singleAndHalf} ${theme.spacing.singleAndHalf} 0;
  width: 85%;
  font-weight: ${theme.fontWeight.medium}
  ${theme.media.M`
    margin: ${theme.spacing.singleAndHalf} ${theme.spacing.singleAndHalf} ${theme.spacing.double};
  `}
`;

export const AddASkillButtonContainer = styled.div`
  height: 88px;
  position: relative;
`;

export const FeedbackCountFooter = styled.div`
  display: flex;
  line-height: 1;
  height: ${theme.spacing.singleAndHalf}
`;

export const FeedbackCountContainer = styled.div`
  width: 100%;
  font-size: 16px;
`;

export const FeedbackCountLabel = styled.span`
  color: ${theme.colors.blacks[50]};
`;

export const FeedbackCountKey = styled.div`
  color: ${theme.colors.blacks[50]};
  text-transform: capitalize;
  font-weight: ${theme.fontWeight.regular};
`;

export const FeedbackCountValue = styled.div`
  color: ${theme.colors.blues[50]};
  font-weight: ${theme.fontWeight.bold};
`;

export const FeedbackCountRow = styled(Clickable)`
  display: flex;
  line-height: 1;
  cursor: pointer;
  ${({ onClick }) =>
  onClick
    ? `
    &:hover ${FeedbackCountKey},
    &:hover ${FeedbackCountLabel} {
      color: ${theme.colors.blues[50]};
    }
    &:active {
      text-decoration: none;
    }
  `
    : ``}
`;

export const FeedbackCountHeader = styled.div`
  color: ${theme.colors.blacks[60]};
  display: inline-flex;
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
  margin-top: ${theme.spacing.singleAndHalf};
  cursor: pointer;
  ${theme.media.M`
    display: flex;
    border-top: 1px solid ${theme.colors.blacks[10]};
    margin-bottom: 0;
    margin-top: 0;
    padding: ${theme.spacing.singleAndHalf} ${theme.spacing.singleAndHalf} ${theme.spacing.half};
    cursor: text;
  `}
`;

const avatarStylesCompact = `
  align-items: flex-start;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  & ${AvatarCard.AvatarContainer} {
    height: 100%;
    margin: 0;
    padding: ${theme.spacing.singleAndHalf};
  }
  & ${AvatarCard.Header} {
    margin: 32px 0 8px;
    padding: 0 ${theme.spacing.singleAndHalf};
    font-size: ${theme.fontSize.S};
  }
  & ${AvatarCard.Body} {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;

    ${FeedbackCountHeader} {
      display: block;
      font-size: 14px;
      padding: 0 ${theme.spacing.singleAndHalf};
    }
    ${FeedbackCountContainer} {
      padding: ${theme.spacing.singleAndHalf};
      font-size: 16px;
      border-bottom: 1px solid ${theme.colors.blacks[10]};
      margin-top: 0;
      ${FeedbackCountFooter} {
        border-bottom: none;
      }
    }
  }
`;

const getContainerStylesMobile = ({ isCompact }) => {
  if (isCompact) {
    return `
      display: flex;
      ${avatarStylesCompact}
      &:before {
        display: none;
      }
      & ${AvatarCard.Body} {
        text-align: left;
      }
      ${FeedbackCountContainer} {
        display: flex;
        ${FeedbackCountRow} {
          margin: 0 ${theme.spacing.single} 0 0;
          ${FeedbackCountLabel} {
            display: none;
          }
          ${FeedbackCountKey}:after {
            content: ':';
          }
          ${FeedbackCountKey} {
            margin: 0 ${theme.spacing.half} 0 0;
          }
        }
      }
    `;
  }
  return `
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    & ${AvatarCard.Body} {
      margin: 0;
      width: 100%;
    }
    & ${AvatarCard.Header} {
      margin: ${theme.spacing.threeQuarters} 0 ${theme.spacing.singleAndHalf};
      word-break: break-all;
    }
    ${FeedbackCountContainer} {
      display: block;
      ${FeedbackCountRow} {
        width: 100%;
        border-bottom: 1px solid ${theme.colors.blacks[10]};
        margin: 0 0 -1px 0;
        padding: ${theme.spacing.threeQuarters} ${theme.spacing.singleAndHalf};
        ${FeedbackCountKey} {
          flex: 1 1 auto;
          margin: 0;
          text-align: left;
        }
      }
    }
  `;
};

const getContainerStyles = ({ isCompact }) => {
  if (isCompact) {
    return theme.media.M`
      ${avatarStylesCompact}
    `;
  }
  return theme.media.M`
  `;
};

export const AvatarContainer = styled(AvatarCard)`
  ${getContainerStylesMobile}
  ${getContainerStyles}
`;

export const BannerContent = styled.div`
  width: 85%;
  position: absolute;
  ${theme.media.XS`
    width: 66%;
    margin: 0;
  `}
  ${theme.media.S`
    width: 435px;
    margin: 0;
  `}
  ${theme.media.M`
    width: 465px;
    position: absolute;
    top: 44px;
    left: 24px;
    margin: 0;
  `}
  ${theme.media.L`
    width: 355px;
    margin: 0;
  `}
`;

export const KarotContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: 10px;
  svg path {
    fill: ${() => theme.colors.blacks[80]};
  }

  transition: all 0.4s ease;

  ${({ isOpened }) =>
  isOpened && `
    transform: rotate(180deg);
  `};

  ${() => theme.media.M`
    display: none;
  `}
`;

export const ShowFeedbackCount = styled.div`
  display: none;
  ${theme.media.M`
    display: block;
  `}
`;

export const ShowFeedbackCountCollapse = styled.div`
  display: block;
  ${theme.media.M`
    display: none;
  `}
`;