import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { IconNew as Icon } from '../Icon';
import { Text } from '../index';

const Container = styled.div`
  ${() => {
    return `
      display: flex;
      align-items: center;
      justify-content: flex-start;
    
      & + & {
        margin-top: ${theme.sizes.padding[50]};
    
        &:last-child {
          margin-bottom: ${theme.sizes.padding[50]};
        }
      }
    `;
  }}
`;

const IconContainer = styled.div`
  ${({ iconSize }) => {
    return `
      margin-right: calc(${iconSize}px / 2);
      width: ${iconSize}px;
    `;
  }}
`;

const TextContainer = styled.div`
  text-align: left;
  width: 100%;
`;

const StyledName = styled(Text)`
  color: ${({ textColor }) => textColor};
  display: inline-block;
  ${({ hasLabel }) => {
    if (hasLabel) {
      return `
        margin-right: ${theme.sizes.padding[20]};
      `;
    }
  }}
`;

const StyledLabel = styled(StyledName)`
  padding-right: ${() => theme.sizes.padding[40]};
`;

const ConnectedAccountItem = ({
  accountName,
  button,
  className,
  iconName,
  iconSize,
  label,
  textColor,
}) => (
  <Container className={className}>
    <IconContainer iconSize={iconSize}>
      <Icon name={iconName} width={iconSize} height={iconSize} />
    </IconContainer>

    <TextContainer>
      <StyledName hasLabel={!!label} textColor={textColor} textStyle={400} bold>
        {accountName}
      </StyledName>
      {label && (
        <StyledLabel textColor={textColor} fontSize={14}>
          {label}
        </StyledLabel>
      )}
    </TextContainer>
    <div>{button}</div>
  </Container>
);

ConnectedAccountItem.propTypes = {
  /** Name of the account. */
  accountName: PropTypes.string.isRequired,
  /** Action button for the account in the list. */
  button: PropTypes.node.isRequired,
  /** Additional class. */
  className: PropTypes.string,
  /** The name of the icon to display. */
  iconName: PropTypes.string.isRequired,
  /** The size in pixels of the icon (height x width). */
  iconSize: PropTypes.number,
  /** Displays additional label text after the account name. */
  label: PropTypes.string,
  /** The color of the text in the item. */
  textColor: PropTypes.string,
};

ConnectedAccountItem.defaultProps = {
  isDisplayedInList: false,
  iconSize: 32,
  textColor: theme.colors.black,
};

export default ConnectedAccountItem;
