export const disabledCatalog = [
  {
    "brandId": "3",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b916708-300w-326ppi.png",
    "name": "Amazon.com",
  },
  {
    "brandId": "30",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b891734-300w-326ppi.png",
    "name": "Apple Gift Card",
  },
  {
    "brandId": "47",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b317391-300w-326ppi.png",
    "name": "Google Play gift code",
  },
  {
    "brandId": "50",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b781467-300w-326ppi.png",
    "name": "lululemon",
  },
  {
    "brandId": "53",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b752192-300w-326ppi.png",
    "name": "Nike",
  },
  {
    "brandId": "61",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b331047-300w-326ppi.png",
    "name": "Nintendo",
  },
  {
    "brandId": "62",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b689017-300w-326ppi.png",
    "name": "Nordstrom",
  },
  {
    "brandId": "97",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b672951-300w-326ppi.png",
    "name": "Sephora",
  },
  {
    "brandId": "65",
    "image": "https://d30s7yzk2az89n.cloudfront.net/images/brands/b353478-300w-326ppi.png",
    "name": "Spotify",
  },
  {
    "brandId": "99",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b942204-300w-326ppi.png",
    "name": "Starbucks",
  },
  {
    "brandId": "101",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b934852-300w-326ppi.png",
    "name": "Subway® Restaurants",
  },
  {
    "brandId": "102",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b663882-300w-326ppi.png",
    "name": "Target",
  },
  {
    "brandId": '108',
    "image": 'https://d30s7yzk2az89n.cloudfront.net/images/brands/b230650-300w-326ppi.png',
    "name": "Uber"
  },
  {
    "brandId": "104",
    "image": "https://dwwvg90koz96l.cloudfront.net/images/brands/b609484-300w-326ppi.png",
    "name": "Ulta Beauty",
  },
  {
    "brandId": "105",
    "image": "https://d30s7yzk2az89n.cloudfront.net/images/brands/b324076-300w-326ppi.png",
    "name": "Walmart",
  },

  {
    "brandId": "106",
    "image": "https://d30s7yzk2az89n.cloudfront.net/images/brands/b341225-300w-326ppi.png",
    "name": "(Red)",
  },
  {
    "brandId": "107",
    "image": "https://d30s7yzk2az89n.cloudfront.net/images/brands/b365559-300w-326ppi.png",
    "name": "DoorDash",
  },
  {
    "brandId": "108",
    "image": "https://d30s7yzk2az89n.cloudfront.net/images/brands/b401839-300w-326ppi.png",
    "name": "Nordstrom Rack",
  },
  {
    "brandId": "109",
    "image": "https://d30s7yzk2az89n.cloudfront.net/images/brands/b266665-300w-326ppi.png",
    "name": "Zappos",
  },
  {
    "brandId": "110",
    "image": "https://d30s7yzk2az89n.cloudfront.net/images/brands/b960035-300w-326ppi.png",
    "name": "Hope for Ukraine",
  },
  {
    "brandId": "111",
    "image": "https://d30s7yzk2az89n.cloudfront.net/images/brands/b757066-300w-326ppi.png",
    "name": "UNICEF",
  },
];