import moment from 'moment-timezone';

export function getUSFormattedTime(time) {
  if (!time) {
    return '';
  }
  
  const [hour, minutes] = time.split(':');
  const parsedHour = hour < 10 ? `0${hour}`: hour;
  const formatted =  moment(`2023-02-28T${parsedHour}:${minutes}:00`).format('h:mm a');

  return formatted;
}
