import { Person } from '@matterapp/people';

const SET_CURRENT_PEER = '@quick-note/set-current-peer';
const TAG_SKILL = '@quick-note/tag-skill';
const RESET = '@quick-note/reset';
const RESET_TAGGED_SKILL = '@quick-note/reset-tagged-skill';

const initialTaggedSkill = null;

const initialState = {
  currentPeer: null,
  taggedSkill: initialTaggedSkill,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case RESET:
    return initialState;
  case SET_CURRENT_PEER: {
    const { currentPeer } = action;
    return { ...state, currentPeer };
  }
  case TAG_SKILL: {
    const { taggedSkill } = action;
    return { ...state, taggedSkill };
  }
  case RESET_TAGGED_SKILL: {
    return { ...state, taggedSkill: initialTaggedSkill };
  }
  default:
    return state;
  }
};

export const resetQuickNote = () => ({
  type: RESET,
});

export const setQuickNoteCurrentPeer = (currentPeer) => ({
  type: SET_CURRENT_PEER,
  currentPeer: new Person(currentPeer),
});

export const tagQuickNoteSkill = (taggedSkill) => ({
  type: TAG_SKILL,
  taggedSkill,
});

export const resetTaggedQuickNoteSkill = () => ({
  type: RESET_TAGGED_SKILL,
});

export default reducer;
