import React from 'react';
import { Typography, Box } from '@mui/material';
import { Checkbox } from '@matterapp/matter-ui';
import { ACTIONS } from '../reducer';
import { QuestionWrapper, QuestionInput } from './styles';

export default function TextQuestion({ question, id, dispatch, required, maxLength, errors, showErrors }) {  
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant='h5' component='p'>
        Question:
      </Typography>
      <QuestionWrapper>
        <QuestionInput 
          rcLabel='question-text-input'
          onChange={(e) => {
            dispatch({
              type: ACTIONS.UPDATE_QUESTION_TEXT,
              payload: {
                id,
                question: e.target.value
              }
            });
          }}
          value={question}
          maxLength={maxLength}
          allowErrorBeforeBlur
          showErrorMessageBelow
          error={showErrors && !!errors[0]}
          placeholder="Type your question here..."
        />
        {showErrors && !!errors[0] && (
          <Typography variant='body2' component='p' color='error' sx={{ mt: 0.5 }}>
            {errors[0]?.message}
          </Typography>
        )}
      </QuestionWrapper>
      <Box 
        sx={{ 
          mt: 4, 
          display: 'flex', 
          alignItems: 'center', 
          cursor: 'pointer'
        }}
        data-rc='toggle-required'
        onClick={() => {
          dispatch({ type: ACTIONS.TOGGLE_QUESTION_REQUIRED, payload: { id } });
        }}
      >
        <Checkbox.Toggle
          size='S' 
          checked={required}
        />
        <Typography variant='body1' component='p' sx={{ ml: 1 }}>
          <b>Make question required</b>
        </Typography>
      </Box>
    </Box>
  );
}
