import React from 'react';
import { SVG, Path } from './consts';

const IndividualInsights = (props) => (
  <SVG {...props}>
    <Path
      d={`M76.1,61.1c0,3.2-2.6,5.8-5.8,5.8s-5.8-2.6-5.8-5.8s2.6-5.8,5.8-5.8S76.1,57.9,76.1,61.1z M52.9,85.6
          c-3.2,0-5.8,2.6-5.8,5.8c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8C58.7,88.2,56.1,85.6,52.9,85.6z M97.7,82.7c-3.2,0-5.8,2.6-5.8,5.8
          c0,3.2,2.6,5.8,5.8,5.8c3.2,0,5.8-2.6,5.8-5.8C103.4,85.3,100.8,82.7,97.7,82.7z M135.9,91.7c-1.2,1.9-2.8,4.3-4.8,7
          c-2.4,3-3.9,5-4.4,5.9c-0.7,1.1-1.4,2.2-2,3.2c-0.6,1.1-0.9,2.3-0.9,3.6l1.1,28c0.1,3.9-3,7.2-6.9,7.2H61.4c-3.5,0-6.4-2.8-6.4-6.3
          c-0.3-2.5-2.5-4.3-5-4l-3.3,0.4c-0.2,0-0.5,0-0.7,0.1l-0.4,0.1c-1.1,0.2-1.7,0.2-2.4,0.2c-5.7,0.5-11.4-1.3-13.6-5
          c-2.8-3.6-3-8.4-0.8-14.5l0.1-0.4c0,0,0,0,0,0c0,0,0-0.1,0-0.1c-3.2-2.2-4.2-4.7-2.9-7.3c0,0,0,0,0-0.1c0-0.1,0-0.3,0-0.4l-0.3-0.6
          c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.3-0.2-0.6-0.3-0.9c-0.8-2.8-1.9-5.8-4.3-7.6c-1.5-1.1-2.3-2.1-2.7-3.2
          c-0.9-2.1-0.1-5.4,2.6-9.9l6.8-10.6c0.8-1.3,0.9-2.9,0.5-4.3l-0.1-0.4c-0.2-1-0.3-2-0.2-2.9c0-0.5,0.2-0.9,0.2-1.4
          c2.1-15,7.7-27.2,16.9-36.7c10.1-10.8,24.8-14.5,39.9-15l5.9,0.1c2.8,0.1,5.3,0.3,7.6,0.7c7.3,1.1,14,3.5,19.9,7
          c11.3,6.3,19.7,17.7,22.9,30.3C144.2,66.1,142.5,80.2,135.9,91.7z M124,52c0-7.6-6.2-13.7-13.7-13.7S96.5,44.5,96.5,52
          c0,4.6,2.2,8.6,5.7,11.1l-4,11.7c-0.2,0-0.4,0-0.5,0c-2.3,0-4.5,0.6-6.5,1.6l-8.8-8.8c1-1.9,1.6-4.1,1.6-6.5
          c0-7.6-6.2-13.7-13.7-13.7c-7.6,0-13.7,6.2-13.7,13.7c0,3.6,1.4,6.9,3.7,9.4L56,78c-1-0.2-2-0.4-3.1-0.4c-7.6,0-13.7,6.2-13.7,13.7
          c0,7.6,6.2,13.7,13.7,13.7c7.6,0,13.7-6.2,13.7-13.7c0-3.6-1.4-6.9-3.7-9.4l4.3-7.5c1,0.2,2,0.4,3.1,0.4c2.3,0,4.6-0.6,6.5-1.6
          l8.8,8.8c-1,1.9-1.6,4.1-1.6,6.5c0,7.6,6.2,13.7,13.7,13.7c7.6,0,13.7-6.2,13.7-13.7c0-4.6-2.2-8.6-5.7-11.1l4-11.7
          c0.2,0,0.4,0,0.5,0C117.8,65.7,124,59.6,124,52z M110.3,46.2c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8
          S113.4,46.2,110.3,46.2z`}
    />
  </SVG>
);

export default IndividualInsights;
