/**
 * Determines if the container is scrolled at the very top or bottom.
 * @param { Object } containerRef: The ref of the container.
 * @returns { Boolean } If the container is scrolled at either end.
 */
export const isContainerScrolledAtEnd = (containerRef) => {
  if (containerRef && containerRef.current) {
    const currentScroll = containerRef.current.scrollTop;
    const totalScrollHeight =
      containerRef.current.scrollHeight - containerRef.current.offsetHeight;

    return currentScroll === 0 || currentScroll >= totalScrollHeight;
  }
  return false;
};

/**
 * Determines if swipe if permitted.
 * @param { Object } swipeProps: {
 *   containerRef: The ref of the scroll container.
 *   currentOffset: The current vertical scroll offset.
 *   isSwipeTriggered: If there is currently triggered swipe action.
 *   offet: The last stored vertical scroll offset.
 *   swipeOffet: The offset amount needed to trigger the swipe.
 * }
 * @returns { Boolean } If the swipe can happen.
 */
export const canSwipe = (swipeProps) => {
  const {
    containerRef,
    currentOffset,
    isSwipeTriggered,
    offset,
    swipeOffset,
  } = swipeProps;
  const isSwipeInThreshold = currentOffset > swipeOffset;
  const isCurrentOffsetGreater = currentOffset > offset;

  return (
    !isSwipeTriggered &&
    isSwipeInThreshold &&
    isCurrentOffsetGreater &&
    isContainerScrolledAtEnd(containerRef)
  );
};
