import { graphql } from '@apollo/client/react/hoc';
import withRouter from 'hocs/withRouter';
import { compose, withProps } from 'recompose';
import { handleSubmitValidation } from '../../libs';
import { mixpanel } from 'libs/tracking';
import { SET_EMAIL_MUTATION } from 'graphql-queries/queries';

const withSetEmailMutation = graphql(SET_EMAIL_MUTATION, {
  name: 'setEmailMutation',
});

export default compose(
  withSetEmailMutation,
  withRouter,
  withProps(({ setEmailMutation }) => ({
    setEmail: async (email) => {
      await handleSubmitValidation({
        fieldName: 'email',
        submit: async () => {
          await setEmailMutation({
            variables: { email },
          });
          mixpanel.trackPeopleData({
            '$email': email,
          });
        },
      });
    },
  }))
);
