import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${theme.spacing.half};
`;

const FileContainer = styled.div`
  padding: ${theme.spacing.half};
`;

const FileNames = (props) => {
  const files = props.files.map((file, index) => {
    return (
      <FileContainer key={`file-${file.name}-${index}`}>
        {file.name}
      </FileContainer>
    );
  });
  return <Container>{files}</Container>;
};

FileNames.defaultProps = {
  files: [],
};

FileNames.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

export default FileNames;
