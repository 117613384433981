import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Text, QualityPill } from '../../components';
import theme from '@matterapp/matter-theme';

const StyledQualityPill = styled(QualityPill)`
  margin: 0 4px 16px 4px;
`;

const StatsProfileCardContainer = styled.div`
  background: white;
  border-radius: 12px;

  ${() => theme.media.medium`
    margin-bottom: 24px;
    box-shadow: ${theme.shadows[45]};
  `}
`;

const StatsProfileCardHeader = styled.div`
  text-align: center;

  img.avatar {
    width: 80px !important;
    height: 80px !important;
  }
`;

const StatsProfileCardHeaderMobile = styled.div`
  box-sizing: border-box;
  height: 128px;
  padding: 24px;
  background-color: ${() => theme.colors.purple};

  img.avatar {
    border: 4px solid #e6f2ff;
  }

  ${() => theme.media.medium`
    display: none;
  `}
`;

const StatsProfileCardHeaderNonMobile = styled.div`
  display: none;

  ${() => theme.media.medium`
    display: block;
    padding: 32px 24px 0 24px;
  `}
`;

const StatsProfileCardBody = styled.div`
  background: #eef7ff;
  padding: 24px;
  padding-top: 60px;
  text-align: center;

  ${() => theme.media.medium`
    padding-top: 0;
    background: white;
    border-radius: 0 0 12px 12px;
  `}
`;

const BioWrapper = styled.div`
  word-wrap: break-word;
  ${() => theme.media.medium`
    padding-top: 16px;
  `}
`;

const QualitiesWrapper = styled.div`
  margin-left: -4px;
  margin-right: -4px;
  margin-top: 24px;
  margin-bottom: -16px;
  text-align: left;
  padding-bottom: 24px;
`;

class StatsProfileCard extends React.Component {
  renderHeadline = (headline) => {
    if (headline === null) {
      return null;
    }
    return (
      <Text
        color="blues[40]"
        textStyle={341}
        textAlign="center"
        uppercase
        block
      >
        {headline}
      </Text>
    );
  };

  renderBio = (bio) => {
    if (bio === null) {
      return null;
    }
    return (
      <BioWrapper>
        <Text color="blacks[90]" textStyle={410} textAlign="center" block>
          {bio}
        </Text>
      </BioWrapper>
    );
  };

  renderQualities = (qualityEndorsements) => {
    if (qualityEndorsements.length === 0) {
      return null;
    }
    return (
      <QualitiesWrapper>
        {qualityEndorsements.map((qualityEndorsement) => (
          <StyledQualityPill
            key={qualityEndorsement.quality.id}
            name={qualityEndorsement.quality.name}
            number={qualityEndorsement.numEndorsements}
          />
        ))}
      </QualitiesWrapper>
    );
  };

  render() {
    const { user, qualityEndorsements, minimal } = this.props;
    return (
      <StatsProfileCardContainer>
        <StatsProfileCardHeader>
          <StatsProfileCardHeaderMobile>
            <Text color="white" textStyle={500} textAlign="center" bold block>
              {user.fullName}
            </Text>
            {this.renderHeadline(user.headline)}
            <Avatar
              url={user.photoUrl}
              email={user.email}
              size={80}
              marginTop={8}
            />
          </StatsProfileCardHeaderMobile>

          <StatsProfileCardHeaderNonMobile>
            <Avatar
              url={user.photoUrl}
              email={user.email}
              size={80}
              marginTop={-56}
              marginBottom={16}
            />
            <Text
              color="blacks[1]"
              textStyle={500}
              textAlign="center"
              bold
              block
            >
              {user.fullName}
            </Text>
            {this.renderHeadline(user.headline)}
          </StatsProfileCardHeaderNonMobile>
        </StatsProfileCardHeader>

        <StatsProfileCardBody>
          {this.renderBio(user.bio)}
          {!minimal && this.renderQualities(qualityEndorsements)}
        </StatsProfileCardBody>
      </StatsProfileCardContainer>
    );
  }
}

StatsProfileCard.defaultProps = {
  minimal: false,
};

StatsProfileCard.propTypes = {
  minimal: PropTypes.bool,
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string,
    headline: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  qualityEndorsements: PropTypes.arrayOf(
    PropTypes.shape({
      quality: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      numEndorsements: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default StatsProfileCard;
