import React from 'react';
import PropTypes from 'prop-types';
import YesNo from './YesNo';
import { MaybeButton } from './ChoiceButtons';

const YesMaybeNo = (props) => {
  const {
    buttonComponent,
    children,
    onClickMaybe,
    maybeLabel,
    ...otherProps
  } = props;

  return (
    <YesNo buttonComponent={buttonComponent} {...otherProps}>
      <MaybeButton
        buttonComponent={buttonComponent}
        label={maybeLabel}
        onClick={onClickMaybe}
      />
      {children}
    </YesNo>
  );
};

/** Additional proptypes to <YesNo> component proptypes. */
YesMaybeNo.propTypes = {
  /** Override default button component. */
  buttonComponent: PropTypes.elementType,
  /** Additional children to show after maybe button. */
  children: PropTypes.node,
  /** Callback when maybe button is clicked. */
  onClickMaybe: PropTypes.func,
  /** Override default maybe button label. */
  maybeLabel: PropTypes.string,
};

export default YesMaybeNo;