import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import Button from '../../Button';
import { Clickable } from '../../Clickable/Clickable';
import EditMessage from '../../Composer/EditMessage';
import Kudos from '../../Kudos';
import { Modal, ModalFlow } from '../../Modal';
import { Heading } from '../../Composer/UnactivatedUserComposer/Customization/sharedStyle';
import { MESSAGE_LENGTH } from '../consts';

const Body = styled.div``;

const SelectKudosList = styled(Kudos.List)`
  padding: ${theme.spacing.single};
  ${theme.media.S`
    padding: ${theme.spacing.singleAndHalf};
  `}
  div:last-child {
    margin-bottom: 0;
  }
`;

const SendKudosContainer = styled.div`
  position: relative;
  padding: ${theme.spacing.singleAndHalf};
`;

const MessageBox = styled.div`
  margin-bottom: -${theme.spacing.singleAndHalf};
`;

const MessageBoxHeading = styled(Heading)`
  padding-top: 0;
`;

const Divider = styled.div`
  border-top: 1px solid ${theme.colors.blacks[10]};
  height: 1px;
`;

const Clear = styled(Clickable)`
  position: absolute;
  top: 21px;
  right: ${theme.spacing.double};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.base};
  &:hover {
    color: ${theme.colors.black};
  }
  &:active {
    color: ${theme.colors.purples[50]};
  }
  ${theme.media.S`
    top: ${theme.spacing.singleAndHalf};
  `}
`;

const INITIAL_STATE = {
  message: '',
  isMessageCorrectLength: false,
};

// DEPRECATED: This modal has been deprecated, use <Composer.KudosModal /> in Web-client instead.
export default class GiveKudosModal extends React.Component {
  static propTypes = {
    /** If the modal is open */
    isOpen: PropTypes.bool.isRequired,
    /** Selected kudos */
    selectedKudos: PropTypes.string,
    /** Function to select kudos. */
    handleSelectKudos: PropTypes.func.isRequired,
    /** Function to send kudos. */
    handleSendKudos: PropTypes.func.isRequired,
    /** Function to close modal. */
    handleCloseModal: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = INITIAL_STATE;
    this.kudosRef = React.createRef();
  }

  handleGoBack = ({ decrementStep }) => decrementStep();

  handleCloseModal = (decrementStep) => {
    this.props.handleCloseModal();
    decrementStep && decrementStep();
    this.handleClearMessage();
  }

  handleClearMessage = () => {
    this.setState(INITIAL_STATE);
  }

  /**
   * Callback when message changes. Automatically plays
   * kudos animation when message reaches minimum length.
   * @param { Object } e: The change event.
   * @returns { void }
   */
  handleMessageChange = (e, { message }) => {
    const { isMessageCorrectLength } = this.state;
    this.setState({ message });

    if (this.isMessageCorrectLength(message) && !isMessageCorrectLength) {
      this.setState(
        { isMessageCorrectLength: true },
        this.kudosRef.current.playAnimation
      );
    }

    if (!this.isMessageCorrectLength(message) && isMessageCorrectLength) {
      this.setState(
        { isMessageCorrectLength: false },
        this.kudosRef.current.resetAnimation
      );
    }
  }

  handleSelectKudos = (e, { value }) => {
    this.props.handleSelectKudos(e, { selectedKudos: value });
  };

  isMessageCorrectLength = (message) => {
    return (
      message.length >= MESSAGE_LENGTH.MIN &&
      message.length <= MESSAGE_LENGTH.GIVE_KUDOS_MAX
    );
  };

  renderHeader = ({ decrementStep }) => <Modal.Header.Buttons.Close onClick={() => this.handleCloseModal(decrementStep)} />

  renderFooter = ({ decrementStep }) => {
    const canSend = this.state.isMessageCorrectLength && this.props.selectedKudos;

    return (
      <Modal.Footer.ButtonContainer
        left={
          <Button.Simple
            color="black"
            onClick={() => this.handleCloseModal(decrementStep)}
          >
            Cancel
          </Button.Simple>
        }
        right={
          <Button.Simple.Primary.Vivid
            color="green"
            disabled={!canSend}
            onClick={() => {
              this.props.handleSendKudos(this.state.message);
              decrementStep();
              this.handleClearMessage();
            }}
          >
            Send
          </Button.Simple.Primary.Vivid>
        }
      />
    );
  }
  
  render() {
    const { message, isMessageCorrectLength } = this.state;
    const { isOpen, selectedKudos, handleSelectKudos } = this.props;

    return (
      <ModalFlow
        contentLabel="Give Kudos Modal"
        size="large"
        isModalOpen={isOpen}
        handleCloseModal={this.handleCloseModal}
        steps={[
          {
            renderTopRight: this.renderHeader,
            renderBody: ({ incrementStep }) => (
              <Body>
                <SelectKudosList
                  onClick={(e, { type }) => {
                    handleSelectKudos(e, { selectedKudos: type });
                    incrementStep();
                  }}/>
              </Body>
            ),
            title: "Select Kudos",
            handleGoBack: this.handleGoBack,
          },
          {
            renderTopRight: this.renderHeader,
            renderBody: () => (
              <Body>
                <SendKudosContainer>
                  <Kudos.Card
                    ref={this.kudosRef}
                    maxTextLength={150}
                    playAnimationOnClick={isMessageCorrectLength}
                    playAnimationOnHover={isMessageCorrectLength}
                    playAnimationOnMount={isMessageCorrectLength}
                    text={message}
                    type={selectedKudos}
                  />
                </SendKudosContainer>
                <Divider/>
                <SendKudosContainer>
                  <MessageBox>
                    <EditMessage
                      hideMinCharacterCount
                      hideMaxCharacterCount={false}
                      label={ 
                        <MessageBoxHeading label>
                          <h3>Your Message:</h3>
                        </MessageBoxHeading>}
                      maxLength={MESSAGE_LENGTH.GIVE_KUDOS_MAX}
                      minLength={MESSAGE_LENGTH.MIN}
                      message={message}
                      onChange={this.handleMessageChange}
                    />
                    <Clear onClick={this.handleClearMessage}>
                      Clear text
                    </Clear>
                  </MessageBox>
                </SendKudosContainer>
              </Body>
            ),
            renderFooter: this.renderFooter,
            title: "Draft a Message",
            handleGoBack: this.handleGoBack,
          },
        ]}
      />
    );
  }
}