import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { responseFlowActions } from '../reducers';

const {
  changeFeedbackRating,
  changeCurrentSkillsIds,
  changeCurrentQualities,
  changeCurrentSkillRatings,
  changeCurrentStep,
  changeCurrentRecurringStep,
  changeCurrentSkillStep,
  changeCurrentUserFullName,
  changeVisibleStep,
  initializeTimeline,
  setGeneratedDefaultNote,
  setWorkRelationship,
  toggleIsDefaultNoteMessageShown,
  toggleShowDefaultNoteMessage,
  resetTimeline,
  saveTimeline,
  saveTimelineComplete,
} = responseFlowActions;

const mapStateToProps = (state) => state.responseFlow;

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        changeFeedbackRating,
        changeCurrentSkillsIds,
        changeCurrentQualities,
        changeCurrentSkillRatings,
        changeCurrentStep,
        changeCurrentRecurringStep,
        changeCurrentSkillStep,
        changeCurrentUserFullName,
        changeVisibleStep,
        initializeTimeline,
        setGeneratedDefaultNote,
        setWorkRelationship,
        toggleIsDefaultNoteMessageShown,
        toggleShowDefaultNoteMessage,
        resetTimeline,
        saveTimeline,
        saveTimelineComplete,
      },
      dispatch
    ),
  };
};

const withData = connect(mapStateToProps, mapDispatchToProps);

export default withData;
