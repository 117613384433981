/* istanbul ignore file */
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';

const shortScreenHeight = '1000px';
const shortScreenWidth = theme.screenWidthBreakpoints.S;

export const getSVGStyles = () => `
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  max-height: 100vh;
  bottom: 100vh;

  @media screen and (max-height: ${shortScreenHeight}) and (min-width: ${shortScreenWidth}) {
    top: -100vh;
    bottom: unset;
  }

  & svg {
    position: absolute;
    overflow: overlay;
    left: 50%;
    bottom: 0;
    width: 100%;
    transform: translateX(-50%);
    height: 1200px;

    @media screen and (max-height: ${shortScreenHeight}) and (min-width: ${shortScreenWidth}) {
      top: 0;
      bottom: unset;
    }
  }
`;

export const getTransitionStyles = (props) => {
  const { isGoToNextStep, isTransitionRunning } = props;
  if (isTransitionRunning) {
    return `
      opacity: 1;
      transform: translateY(100vh);
      transition: all 0s;
    `;
  }
  return `
    opacity: 0;
    transform: translateY(${isGoToNextStep ? '0vh' : '200vh'});
    transition: transform 0.65s, opacity
      ${theme.transitions.times.double} ${theme.transitions.times.double};
    @media screen and (max-height: ${shortScreenHeight}) and (min-width: ${shortScreenWidth}) {
      transform: translateY(${isGoToNextStep ? `calc(0vh - ${shortScreenHeight})` : '200vh'});
    }
  `;
};

export const getTransitionStylesCurrent = (props) => {
  const { isGoToNextStep, isTransitionRunning } = props;
  if (!isTransitionRunning) {
    return `
      opacity: 1;
      transform: translateY(100vh);
      transition: transform 0.65s, opacity ${theme.transitions.times.double};
    `;
  }
  return `
    opacity: 0;
    transform: translateY(${isGoToNextStep ? '200vh' : '0vh'});
    transition: all 0s;
    @media screen and (max-height: ${shortScreenHeight}) and (min-width: ${shortScreenWidth}) {
      transform: translateY(${isGoToNextStep ? '200vh' : `calc(0vh - ${shortScreenHeight})`});
    }
  `;
};

export const getBackgroundColor = (props) => {
  const { currentStep, qualitiesStepNumber } = props;
  if (currentStep > qualitiesStepNumber) {
    return theme.colors.blues[5];
  }
  return 'transparent';
};

export const Container = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${getBackgroundColor};
  transition: background ${theme.transitions.times.singleAndHalf};
`;

export const BackgroundContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

export const CurrentBackground = styled.div`
  ${getSVGStyles}
  ${getTransitionStylesCurrent}
`;

export const LastBackground = styled.div`
  ${getSVGStyles}
  ${getTransitionStyles}
`;
