import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { propType } from 'graphql-anywhere';

import { compose, withProps } from 'recompose';
import MultipleEmails from './MultipleEmails';
import {
  CONNECTED_ACCOUNTS_QUERY,
  CONTACTS_LIST_MUTATION,
} from 'graphql-queries/queries';

const MultipleEmailsWrapper = (props) => {
  const { connectedAccountsData } = props;
  const { currentUser } = connectedAccountsData;
  if (!currentUser) { return null; }
  return <MultipleEmails {...props} />;
};

MultipleEmailsWrapper.propTypes = {
  connectedAccountsData: propType(CONNECTED_ACCOUNTS_QUERY).isRequired,
};

const withData = compose(
  graphql(CONNECTED_ACCOUNTS_QUERY, {
    name: 'connectedAccountsData',
  }),
  graphql(CONTACTS_LIST_MUTATION, {
    name: 'findContactsByNameOrEmailMutation',
  }),
  withProps(({ connectedAccountsData }) => {
    const { currentUser } = connectedAccountsData;
    if (!currentUser) { return { isAutocompleteMode: false }; }
    const { connectedAccountsMap } = currentUser;
    if (!connectedAccountsMap) { return { isAutocompleteMode: false }; }
    const { hasPeerRecommendationsSource } = connectedAccountsMap;
    return { isAutocompleteMode: hasPeerRecommendationsSource };
  }),
  withProps(({ findContactsByNameOrEmailMutation }) => ({
    handleSearchContacts: async (nameOrEmail) => {
      const response = await findContactsByNameOrEmailMutation({
        variables: {
          nameOrEmail,
        },
      });
      return response.data.findContactsByNameOrEmail;
    },
  }))
);

export default withData(MultipleEmailsWrapper);
