import { useQuery, useMutation } from '@apollo/client';
import {
  NEW_FEEDBACKS_QUERY,
  REMOTE_NOTIFICATIONS_QUERY,
  MARK_NOTIFICATIONS_AS_VIEWED_MUTATION,
  MARK_ALL_NOTIFICATIONS_AS_VIEWED_MUTATION,
} from 'graphql-queries/queries';

export const useNewFeedbacksQuery = () => {
  const { loading, error, data } = useQuery(NEW_FEEDBACKS_QUERY);
  return {
    data,
    error,
    loading,
    newFeedbacksData: data || {},
    newFeedbacksError: error,
    newFeedbacksLoading: loading,
  };
};

export const useRemoteNotificationsQuery = () => {
  const { loading, error, data } = useQuery(REMOTE_NOTIFICATIONS_QUERY);
  return {
    data,
    error,
    loading,
    remoteNotificationsData: data || {},
    remoteNotificationsError: error,
    remoteNotificationsLoading: loading,
  };
};

export const useMarkNotificationsAsViewedMutation = () => {
  const [mutation] = useMutation(MARK_NOTIFICATIONS_AS_VIEWED_MUTATION);
  return {
    mutation,
    markNotificationsAsViewedMutation: mutation,
  };
};

export const useMarkAllNotificationsAsViewedMutation = () => {
  const [mutation] = useMutation(MARK_ALL_NOTIFICATIONS_AS_VIEWED_MUTATION);
  return {
    mutation,
    markAllNotificationsAsViewedMutation: mutation,
  };
};

export const useRemoteNotifications = () => {
  const { remoteNotificationsData, loading } = useRemoteNotificationsQuery();

  const {
    markNotificationsAsViewedMutation,
  } = useMarkNotificationsAsViewedMutation();

  const {
    markAllNotificationsAsViewedMutation,
  } = useMarkAllNotificationsAsViewedMutation();

  return {
    loading,
    remoteNotificationsData,
    markNotificationsAsViewedMutation,
    markAllNotificationsAsViewedMutation,
  };
};

export default useRemoteNotifications;
