import { graphql } from '@apollo/client/react/hoc';
import { INVITATION_FROM_UUID_QUERY } from 'graphql-queries/queries';

const withInvitation = graphql(INVITATION_FROM_UUID_QUERY, {
  name: 'invitationData',
  options: ({ invitationUuid }) => ({
    variables: { invitationUuid },
  }),
});

export default withInvitation;
