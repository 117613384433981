import React from 'react';
import Base from './Base';
import {
  FooterContainer,
  TwoColumnContainer,
  TwoColumnContentContainer,
  TwoColumnContentContainerWrapper,
  TwoColumnFilterContainer,
  TwoColumnFilterWrapper,
  TwoColumnChildrenContainer,
} from './styles';

const TwoColumnFixedLeft = ({
  children,
  className,
  containerNavigation,
  dockLeftColumn,
  header,
  footer,
  leftColumn,
  hideLeftColumnOnMobile,
  hideFilterColumn,
  hideFooter
}) => (
  <Base className={className} header={header}>
    <TwoColumnContainer>
      <TwoColumnContentContainer>
        <TwoColumnContentContainerWrapper>
          {hideFilterColumn ? null : (
            <>
              <TwoColumnFilterContainer hideFilterColumn={hideLeftColumnOnMobile}>
                <TwoColumnFilterWrapper dock={dockLeftColumn}>
                  {containerNavigation}
                  {leftColumn}
                </TwoColumnFilterWrapper>
              </TwoColumnFilterContainer>
            </>
          )}
          <TwoColumnChildrenContainer hideFilterColumn={hideFilterColumn}>{children}</TwoColumnChildrenContainer>
        </TwoColumnContentContainerWrapper>
        {hideFooter ? null : <FooterContainer>{footer}</FooterContainer>}
      </TwoColumnContentContainer>
    </TwoColumnContainer>
  </Base>
);

TwoColumnFixedLeft.defaultProps = {
  dockLeftColumn: true,
};

export default TwoColumnFixedLeft;
