import React from 'react';
import Base from './Base';
import ButtonGroup from './ButtonGroup';
import List from './List';

const Radio = (props) => <Base {...props} />;
Radio.ButtonGroup = ButtonGroup;
Radio.List = List;

export default Radio;
