import React from 'react';
import PropTypes from 'prop-types';
import Layout from './layouts/Layout';
import { Notifications } from '../Notification';

const Page = (props) => {
  const {
    header,
    layout,
    layoutVersion,
    hideNotifications,
  } = props;
  return (
    <React.Fragment>
      {!header && !hideNotifications ? (
        <Notifications />
      ) : null}
      <Layout
        type={layout}
        version={layoutVersion}
        {...props}
      />
    </React.Fragment>
  );
};

Page.defaultProps = {
  header: null,
  content: null,
  footer: false,
  inverted: false,
  hideNotifications: false,
};

Page.propTypes = {
  header: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node,
  inverted: PropTypes.bool,
  layout: PropTypes.string.isRequired,
  layoutVersion: PropTypes.number,
  hideNotifications: PropTypes.bool,
};

Page.Layouts = Layout.Layouts;

export default Page;
