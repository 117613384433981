import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import CheckmarkToggle from '../../Checkbox/CheckmarkToggle';
import Skill from '../../Skill';
import { CheckmarkContainer, SkillListContainer } from './styles';

const TagSkillModal = (props) => {
  const { isOpen, onClickDone, onSelectSkill, onClose, skills } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} fullScreenMobile>
      <Modal.Header
        centerOnMobile
        header={'Tag A Skill'}
        right={<Modal.Buttons.CloseIcon onClick={onClose} />}
      />
      <Modal.Body>
        <SkillListContainer>
          <Skill.AddListUnit
            skills={skills}
            onClickItem={onSelectSkill}
            renderSkillActions={({ isSelected }) => {
              return (
                <CheckmarkContainer>
                  <CheckmarkToggle
                    iconUncheckedName={''}
                    checked={isSelected}
                    showBorder
                  />
                </CheckmarkContainer>
              );
            }}
          />
        </SkillListContainer>
      </Modal.Body>
      <Modal.Footer.WithCancelSave
        saveLabel={'Done'}
        onClickCancel={onClose}
        onClickSave={onClickDone}
      />
    </Modal>
  );
};

TagSkillModal.defaultProps = {};

TagSkillModal.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      skill: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
};

export default TagSkillModal;
