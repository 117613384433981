import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM4 10C4 9.44771 4.44772 9 5 9H9V5C9 4.44771 9.44771 4 10 4C10.5523 4 11 4.44772 11 5V9H15C15.5523 9 16 9.44771 16 10C16 10.5523 15.5523 11 15 11H11V15C11 15.5523 10.5523 16 10 16C9.44771 16 9 15.5523 9 15V11H5C4.44771 11 4 10.5523 4 10Z"
      fill={fill}
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#fff',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
