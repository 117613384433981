import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { minSize, maxSize } from './consts';
import { IconCheckmark } from '../../Icon';
import theme from '@matterapp/matter-theme';

const Container = styled.div`
  width: ${minSize}px;
  height: ${minSize}px;

  div {
    width: 100%;
    height: 100%;
  }
  ${() => theme.media.medium`
    width: ${maxSize}px;
    height: ${maxSize}px;
  `}
`;

const Completed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${() => theme.colors.greens[50]};

  svg {
    animation: bounceIn 0.6s 1 ease-out;
    path {
      fill: white;
    }
  }
  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.3) translate3d(0, 0, 0);
    }
    50% {
      opacity: 0.9;
      transform: scale(1.3);
    }
    80% {
      opacity: 1;
      transform: scale(0.89);
    }
    100% {
      opacity: 1;
      transform: scale(1) translate3d(0, 0, 0);
    }
  }
`;

const NotCompleted = styled.div`
  border-radius: 50%;
  background-color: ${() => theme.colors.blacks[5]};
  border: 1px solid ${() => theme.colors.blacks[10]};
`;

const Binary = ({ completed }) => (
  <Container>
    {!completed && <NotCompleted />}
    {completed && (
      <Completed>
        <IconCheckmark />
      </Completed>
    )}
  </Container>
);

Binary.propTypes = {
  completed: PropTypes.bool.isRequired,
};

export default Binary;
