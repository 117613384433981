import React from 'react';
import Panel from '../../../Panel';
import AvatarHeader from '../../AvatarHeader';
import { Footer } from '../../FeedItem';

const EventsCreation = ({
  footerProps = {},
  userFullName,
  isInactive,
  photoUrl,
  workspaceName,
  isSelf,
  onClickAvatar,
  onClickDelete
}) => {
  const subheader = <>created the channel <strong>{workspaceName}</strong>.</>;
  const onClick = isSelf ? onClickAvatar : null;
  const panelHeader = 
    <AvatarHeader
      equalPadding
      header={userFullName}
      isInactive={isInactive}
      subheader={subheader}
      photoUrl={isInactive ? null : photoUrl}
      showMenu={false}
      canClickHeader={false}
      onClickAvatar={onClick}
      onClickDelete={onClickDelete}
    />;

  return (
    <Panel.Base header={panelHeader}><Footer append {...footerProps} /></Panel.Base>
  );
};

export default EventsCreation;