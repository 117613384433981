import styled from 'styled-components';
import { Clickable } from '../../Clickable/Clickable';
import theme from '@matterapp/matter-theme';

export const MINI_KUDOS_HEIGHT = 64;
export const IMAGE_HEIGHT = 64;
export const IMAGE_WIDTH = 176;

export const ThumbnailContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  height: auto;
  padding: ${theme.spacing.half};
  position: absolute;
  transition: transform ${theme.transitions.times.default};
  transform: scale(1);
  user-select: none;

  & svg {
    height: auto;
    width: 100%;
  }
`;

export const LabelOnlyContainer = styled.div`
  height: auto;
  padding: ${theme.spacing.half};
  position: absolute;
  max-width: 136px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Label = styled.div`
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.S};
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.base};
  padding: ${theme.spacing.quarter};
  pointer-events: none;
  text-transform: uppercase;
  transition: color ${theme.transitions.times.default};
  white-space: nowrap;
  user-select: none;
  z-index: 1;
  width: 112px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Container = styled(Clickable)`
  ${({ minHeight, textColor }) => `
    align-items: center;
    border-radius: ${theme.borderRadius.S};
    display: flex;
    height: ${minHeight}px;
    justify-content: center;
    overflow: hidden;
    padding: ${theme.spacing.singleAndQuarter} ${theme.spacing.double};
    position: relative;
    transition: background-color ${theme.transitions.times.default}, border ${theme.transitions.times.default};
    &:hover {
      & ${Label} {
        color: ${textColor};
      }
    }

    &:active {
      & ${ThumbnailContainer} {
        transform: scale(1.2);
      }
    }
  `}
  ${({ isCustom, hoverBgColor, hoverTextColor, bgColor }) => {
    if (isCustom) {
      if (hoverBgColor) {
        return `
          background-color: ${bgColor};
          padding: 0;
          &:hover {
            background-color: ${hoverBgColor};
            & ${Label} {
              color: ${hoverTextColor};
            }
          }
      `;
      } else {
        return `
          background-color: ${bgColor};
          padding: 0;
          opacity: 70%;
          &:hover {
            transition: opacity 0.3s ease-in-out;
            opacity: 100%;
          }
      `;
      }
    }
  }}
`;

export const getLabelColors = (color) => {
  if (color == `${theme.colors.purples[30]}` || color == `${theme.colors.purples[10]}` ) {
    return {
      hoverBgColor: `${theme.colors.purples[30]}`,
      hoverTextColor: `${theme.colors.purples[30]}`,
      bgColor: `${theme.colors.purples[10]}`
    };
  }
  if (color == `${theme.colors.blues[30]}` || color == `${theme.colors.blues[10]}` ) {
    return {
      hoverBgColor: `${theme.colors.blues[30]}`,
      hoverTextColor: `${theme.colors.blues[30]}`,
      bgColor: `${theme.colors.blues[10]}`
    };
  }
  if (color == `${theme.colors.reds[30]}` || color == `${theme.colors.reds[10]}` ) {
    return {
      hoverBgColor: `${theme.colors.reds[30]}`,
      hoverTextColor: `${theme.colors.reds[30]}`,
      bgColor: `${theme.colors.reds[10]}`
    };
  }
  if (color == `${theme.colors.oranges[30]}` || color == `${theme.colors.oranges[10]}` ) {
    return {
      hoverBgColor: `${theme.colors.oranges[30]}`,
      hoverTextColor: `${theme.colors.oranges[30]}`,
      bgColor: `${theme.colors.oranges[10]}`
    };
  }
  if (color == `${theme.colors.greens[30]}` || color == `${theme.colors.greens[10]}` ) {
    return {
      hoverBgColor: `${theme.colors.greens[30]}`,
      hoverTextColor: `${theme.colors.greens[30]}`,
      bgColor: `${theme.colors.greens[10]}`
    };
  }
  if (color == `${theme.colors.blacks[30]}` || color == `${theme.colors.blacks[10]}` ) {
    return {
      hoverBgColor: `${theme.colors.blacks[30]}`,
      hoverTextColor: `${theme.colors.blacks[30]}`,
      bgColor: `${theme.colors.blacks[10]}`
    };
  }
  if (color == `${theme.colors.yellows[30]}` || color == `${theme.colors.yellows[10]}` ) {
    return {
      hoverBgColor: `${theme.colors.yellows[30]}`,
      hoverTextColor: `${theme.colors.yellows[30]}`,
      bgColor: `${theme.colors.yellows[10]}`
    };
  }

  return {
    bgColor: color,
    hoverTextColor: '#999999'
  };
};
