export const BASE = 'kudosThankYou';

export const FACE = {
  GROUP: `${BASE}-face`,
  EYES: `${BASE}-eyes`,
  MOUTH: `${BASE}-mouth`,
};

export const HAND = {
  GROUP: `${BASE}-hat-and-hand`,
  HAT: `${BASE}-hat`,
  HAND: `${BASE}-hand`,
};

export const DASHES = `${BASE}-dashes`;
export const PLUS = `${BASE}-plus`;

export const CIRCLE = {
  LEFT_1: `${BASE}-circle-left-1`,
  LEFT_2: `${BASE}-circle-left-2`,
  TOP: `${BASE}-circle-top`,
  RIGHT: `${BASE}-circle-right`
};

export const RING = {
  TOP: `${BASE}-ring-top`,
  BOTTOM: `${BASE}-ring-bottom`,
  RIGHT: `${BASE}-ring-right`,
};
