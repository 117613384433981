import PropTypes from 'prop-types';

export const qualitySkillPropType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.id,
});

export const simpleUserPropType = PropTypes.shape({ fullName: PropTypes.string });

export const skillToRatePropType = PropTypes.shape({
  skill: qualitySkillPropType,
  currentNote: PropTypes.string,
  currentRating: PropTypes.number,
  previousRating: PropTypes.number,
});

export const skillsToRatePropType = PropTypes.arrayOf(skillToRatePropType);

export const statePropTypes = PropTypes.shape({
  receiver: PropTypes.shape({
    fullName: PropTypes.string,
  }),
  skillsToRate: skillsToRatePropType,
});