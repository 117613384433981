import theme from '@matterapp/matter-theme';
/** Utils for the response flow timeline. */

export const MAX_STEP_WIDTH = 620;
export const NUM_OF_PREV_STEPS_OVERFLOW = 4;
export const TIMELINE_TRANSITION_TIME = theme.transitions.times.singleAndHalf;

/**
 * The first skill in the timeline is the second step since the first step is work relationship.
 * This would make the offset in the skill array 2 units since step counts start at 1.
 */
export const SKILL_START_INDEX_OFFSET = 2;

export const SKILL_STEPS = {
  RATING: 1,
  ABILITIES: 2,
  NOTE: 3,
};

export const NUMBER_OF_SKILL_STEPS = Object.keys(SKILL_STEPS).length;

export const getTimelinePaddingTop = () => {
  const desktopWidth = '640px';
  const desktopHeights = {
    M: '950px',
    S: '850px',
  };
  const desktopHeightLevel = {
    M: 1,
    S: 2,
  };

  const createHeightBreakpoint = (desktopHeightSize) => {
    const maxHeight = desktopHeights[desktopHeightSize];
    const heightLevel = desktopHeightLevel[desktopHeightSize];
    return `
      @media screen and (min-width: ${desktopWidth}) and (max-height: ${maxHeight}) {
        margin-top: calc(20px - (51px * ${heightLevel}));
      }
    `;
  };

  return `
    transition: margin-top 0.2s;
    margin-top: 0px;
    padding-top: ${theme.spacing.triple};

    ${theme.media.S`
      padding-top: 0px;
    `.join('')}

    ${createHeightBreakpoint('M')}
    ${createHeightBreakpoint('S')}
  `;
};

export const getSkillSidebarTopPosition = () => {
  return `
    transition: top ${TIMELINE_TRANSITION_TIME} ease;
    top: ${theme.spacing.triple};
    ${theme.media.S`
      top: 0px;
    `.join('')}
  `;
};

/**
 * Gets the skill step number.
 * @param { Number } index: The index of the skill in the list.
 * @returns { Number } The step number of the skill.
 */
export const getSkillStepNumber = (index) => {
  return index + SKILL_START_INDEX_OFFSET;
};

/**
 * Determines if the skill has selectable abilities.
 * Custom skills currently do not have abilities.
 * @param { Object } skill: The skill to check if it has abilities.
 * @returns { Boolean } If the skill has abilities.
 */
export const skillHasAbilities = (skill) => {
  const { actionItemSelections } = skill || {};
  return actionItemSelections && actionItemSelections.length;
};

/**
 * Gets the number of steps for the skill.
 * @param { Object } skill: The skill to check to determine the number of skill steps in the timeline.
 * @returns { Number } The number of steps for the skill.
 */
export const getNumberOfSkillSteps = (skill) => {
  return skillHasAbilities(skill) ? SKILL_STEPS.NOTE : SKILL_STEPS.ABILITIES;
};

/**
 * Gets the step number for the note skill step.
 * @param { Object } skill: The skill to check to determine the note step number.
 * @returns { Number } The step number for the note step.
 */
export const getSkillNoteStepNumber = (skill) => {
  return getNumberOfSkillSteps(skill);
};

/**
 * Gets the step number for the abilities skill step. If skill has
 * no abilities, the step number should be the same as the previous step.
 * @param { Object } skill: The skill to check to determine the abilities step number.
 * @returns { Number } The step number for the abilities step.
 */
export const getSkillAbilitiesStepNumber = (skill) => {
  return skillHasAbilities(skill) ? SKILL_STEPS.ABILITIES : SKILL_STEPS.RATING;
};

/**
 * Gets the matching skill from the given step in the timeline.
 * Since skills in the skill list start at index 0 and skills in the timeline
 * start at step 2, the offset is 2 (SKILL_START_INDEX_OFFSET).
 * @param { Array } skillList: The list of skills in the timeline.
 * @param { Number } stepNumber: The step number of the skill.
 * @returns { Object } The matching skill object;
 */
export const getSkillOfStep = (skillList = [], stepNumber) => {
  return skillList[stepNumber - SKILL_START_INDEX_OFFSET] || {};
};

/**
 * The new rater scale is from 1 -5 while the old rater is 0 - 10.
 * New ratings are on a 1 - 10 scale (2, 4, 6, 8, 10), so getting
 * the value is dividing the rating by 2 and getting the ceiling value.
 * Ratings of 0 will automatically be 1.
 * @param { Number } rating: The rating to convert.
 * @returns { Number } The converted rating to display.
 */
export const getRating = (rating) => {
  if (rating == null) {
    return rating;
  }
  return Math.ceil(rating / 2) || 1;
};

/**
 * New ratings are on a 1 - 5 scale. To ensure that they are
 * compatible with old ratings, the values will be doubled.
 * @param { Number } rating: The rating to convert.
 * @returns { Number } The converted rating to set.
 */
export const setRating = (rating = 0) => {
  return rating * 2;
};

/**
 *
 * @param { Object } skillProps: {
 *   @param { Object } skill: The skill to check.
 *   @param { Number } minNoteLength: The min length of the custom note.
 *   @param { Number } maxNoteLength: The max length of the custom note.
 * }
 */
export const checkSkillForCompleteness = (skillProps) => {
  const { skill, minNoteLength, maxNoteLength } = skillProps;
  const { actionItemSelections, currentNote, currentRating } = skill;
  const currentAbilities =
    actionItemSelections &&
    actionItemSelections.filter((ability) => ability.selected);
  const skillHasRating = !!currentRating;
  const skillHasAbilities = currentAbilities && !!currentAbilities.length;
  const skillHasNote = !!currentNote && !!currentNote.length;
  const skillNoteIsInLimit =
    skillHasNote &&
    currentNote.length >= minNoteLength &&
    currentNote.length <= maxNoteLength;

  return {
    skillHasRating,
    skillHasAbilities,
    skillHasNote: skillNoteIsInLimit,
    skillIsComplete: !!(skillHasRating && skillHasNote && skillNoteIsInLimit),
  };
};
