import React from 'react';
import { Colors, Thumbnail, Paths } from '../Thumbnail';

const Cheers = (props) => {
  return (
    <Thumbnail color={Colors.Blue} width={289} height={87} top={0} left={0} {...props}>
      <Paths.L40
        evenOdd
        d="M220.858 24.142C222.589 19.7337 211.732 11.3411 196.607 5.40439C181.483 -0.532299 167.816 -1.7691 166.085 2.64807C166.085 2.64807 166.085 2.65692 166.076 2.65692L146.154 53.4279C146.154 53.4368 146.145 53.4456 146.145 53.4632C143.787 59.4706 154.141 69.1531 169.265 75.0898C184.389 81.0265 198.56 80.9646 200.918 74.9573L220.858 24.142Z"
      />
      <Paths.L50
        evenOdd
        d="M205.637 56.2296L200.187 70.1261H200.178C200.169 70.1791 200.151 70.2233 200.133 70.2763C199.003 73.1475 186.882 71.089 173.065 65.6647C159.239 60.2404 148.947 53.5175 150.078 50.6375C150.096 50.5845 150.122 50.5403 150.149 50.4962H150.14L155.591 36.5997C156.722 33.7286 168.842 35.787 182.659 41.2112C196.476 46.6267 206.768 53.3585 205.637 56.2296Z"
      />
      <Paths.L60
        evenOdd
        d="M207.52 51.4311C209.896 42.8617 202.891 43.7275 193.023 44.9467C192.546 45.0085 192.06 45.0703 191.565 45.1233C183.164 46.1481 156.466 39.6814 155.256 37.4021L150.6 49.2666H150.609C150.565 49.355 150.529 49.4433 150.494 49.5405C148.4 54.8764 157.906 63.6048 171.732 69.0291C185.558 74.4534 198.456 74.5241 200.558 69.1793C200.594 69.091 200.629 68.9938 200.656 68.9054H200.664L207.52 51.4311Z"
      />
      <g opacity="0.5">
        <Paths.L20
          opacity="0.5"
          evenOdd
          d="M206.654 26.1291C214.304 27.4631 219.879 26.8889 220.939 24.1768L201 74.9919C199.551 78.6847 193.641 80.1335 185.787 79.3208L206.654 26.1291Z"
        />
      </g>
      <Paths.L20
        evenOdd
        d="M166.158 2.7014C164.709 6.47367 172.421 13.1348 184.118 18.6916L190.214 3.15196C177.863 -0.726319 167.686 -1.08857 166.176 2.66603L166.158 2.7014Z"
      />
      <Paths.L20
        evenOdd
        d="M217.086 24.6721C218.65 24.3099 219.1 23.771 219.206 23.4883C219.312 23.2144 219.356 22.5165 218.455 21.1825C217.589 19.9016 216.088 18.3909 213.967 16.7565C209.745 13.4966 203.41 9.98942 195.954 7.06525C188.507 4.14108 181.475 2.4007 176.156 1.92365C173.488 1.68512 171.359 1.77344 169.857 2.11798C168.294 2.48019 167.843 3.01911 167.737 3.30181C167.631 3.57567 167.587 4.27357 168.488 5.60756C169.354 6.88854 170.856 8.39923 172.976 10.0336C177.199 13.2935 183.533 16.8007 190.989 19.7248C198.437 22.649 205.469 24.3894 210.787 24.8664C213.455 25.1138 215.584 25.0255 217.086 24.6721ZM190.335 21.3857C205.46 27.3224 219.127 28.5592 220.858 24.142C222.59 19.7337 211.732 11.3411 196.608 5.40439C181.483 -0.532299 167.817 -1.7691 166.085 2.64807C164.354 7.06525 175.211 15.449 190.335 21.3857Z"
      />
      <Paths.L40
        evenOdd
        d="M210.373 29.1519C208.58 24.5846 219.826 15.9004 235.489 9.75167C251.152 3.60296 265.305 2.32199 267.098 6.89819C267.098 6.89819 267.098 6.90703 267.107 6.90703L287.744 59.4891C287.744 59.4979 287.753 59.5156 287.753 59.5244C290.191 65.7438 279.475 75.7708 263.812 81.9195C248.149 88.0682 233.466 88.0064 231.028 81.7782L210.373 29.1519Z"
      />
      <Paths.L50
        evenOdd
        d="M226.142 62.386L231.787 76.786H231.796C231.805 76.839 231.822 76.892 231.849 76.945C233.015 79.9222 245.569 77.7843 259.889 72.1656C274.201 66.547 284.864 59.5767 283.698 56.5995C283.68 56.5465 283.654 56.5023 283.627 56.4493H283.636L277.991 42.0493C276.825 39.0721 264.271 41.2101 249.951 46.8287C235.63 52.4385 224.976 59.4088 226.142 62.386Z"
      />
      <Paths.L60
        evenOdd
        d="M224.19 57.4126C221.734 48.5341 228.987 49.4351 239.208 50.6985C239.703 50.7603 240.206 50.8222 240.719 50.884C249.421 51.9441 277.072 45.2477 278.326 42.8889L283.15 55.1775H283.141C283.185 55.2658 283.23 55.363 283.265 55.4602C285.438 60.9905 275.588 70.0281 261.267 75.6467C246.956 81.2654 233.589 81.336 231.416 75.8057C231.381 75.7086 231.345 75.6114 231.319 75.5142H231.31L224.19 57.4126Z"
      />
      <g opacity="0.5">
        <Paths.L20
          opacity="0.5"
          evenOdd
          d="M225.09 31.2085C217.165 32.5955 211.396 31.9948 210.292 29.1854L230.947 81.8205C232.449 85.6458 238.571 87.1388 246.707 86.2996L225.09 31.2085Z"
        />
      </g>
      <Paths.L20
        evenOdd
        d="M267.037 6.95106C268.53 10.8558 260.553 17.7555 248.441 23.5066L242.125 7.41041C254.917 3.39078 265.456 3.01978 267.02 6.9069L267.037 6.95106Z"
      />
      <Paths.L20
        evenOdd
        d="M214.287 29.7085C212.661 29.3286 212.202 28.772 212.087 28.4805C211.972 28.189 211.937 27.4734 212.864 26.0864C213.756 24.7612 215.311 23.1975 217.511 21.5014C221.884 18.1266 228.448 14.4957 236.16 11.4655C243.882 8.43535 251.161 6.63317 256.665 6.13845C259.43 5.89108 261.639 5.97941 263.193 6.34162C264.819 6.7215 265.278 7.27807 265.393 7.56961C265.508 7.86114 265.543 8.57669 264.616 9.96368C263.724 11.2888 262.169 12.8525 259.969 14.5487C255.596 17.9234 249.032 21.5543 241.32 24.5845C233.598 27.6147 226.319 29.4169 220.815 29.9117C218.05 30.159 215.841 30.0707 214.287 29.7085ZM241.982 26.2984C226.319 32.4471 212.166 33.7281 210.373 29.1519C208.58 24.5757 219.826 15.9004 235.489 9.75167C251.152 3.60296 265.305 2.32199 267.098 6.89819C268.901 11.4655 257.654 20.1497 241.982 26.2984Z"
      />
      <Paths.L40
        evenOdd
        d="M75.047 24.1765C76.7785 19.7682 65.9211 11.3755 50.7967 5.43881C35.6723 -0.497875 22.0055 -1.73468 20.274 2.6825C20.274 2.6825 20.274 2.69134 20.2651 2.69134L0.343616 53.4624C0.343616 53.4712 0.334881 53.4801 0.334881 53.4977C-2.02389 59.5051 8.3299 69.1875 23.4543 75.1242C38.5787 81.0609 52.7491 80.999 55.1079 74.9917L75.047 24.1765Z"
      />
      <Paths.L50
        evenOdd
        d="M59.8249 56.254L54.374 70.1504H54.3652C54.3564 70.2034 54.3388 70.2476 54.3211 70.3006C53.1903 73.1717 41.0695 71.1134 27.2526 65.6891C13.4268 60.2648 3.13481 53.5418 4.2656 50.6618C4.28327 50.6088 4.30974 50.5647 4.33624 50.5205H4.3274L9.77823 36.624C10.909 33.7529 23.0298 35.8113 36.8467 41.2356C50.6636 46.651 60.9557 53.3828 59.8249 56.254Z"
      />
      <Paths.L60
        evenOdd
        d="M61.7074 51.4583C64.0839 42.889 57.0782 43.7547 47.2102 44.9738C46.7332 45.0357 46.2472 45.0975 45.7525 45.1505C37.351 46.1753 10.6537 39.7086 9.44334 37.4293L4.78762 49.2939H4.79647C4.75229 49.3822 4.71695 49.4705 4.68161 49.5677C2.58787 54.9037 12.0937 63.632 25.9194 69.0563C39.7452 74.4806 52.6434 74.5513 54.7459 69.2065C54.7813 69.1181 54.8166 69.021 54.8431 68.9326H54.8519L61.7074 51.4583Z"
      />
      <Paths.L20
        opacity="0.5"
        evenOdd
        d="M60.8433 26.1564C68.4939 27.4904 74.0683 26.9161 75.1284 24.204L55.1893 75.0192C53.7405 78.712 47.8303 80.1608 39.9766 79.3481L60.8433 26.1564Z"
      />
      <Paths.L20
        evenOdd
        d="M20.3434 2.72762C18.8946 6.49989 26.6071 13.161 38.3038 18.7178L44.3994 3.17818C32.0489 -0.700103 21.8718 -1.0623 20.3611 2.6923L20.3434 2.72762Z"
      />
      <Paths.L20
        evenOdd
        d="M71.2761 24.7072C72.8398 24.345 73.2904 23.8061 73.3964 23.5234C73.5024 23.2496 73.5466 22.5516 72.6455 21.2176C71.7797 19.9366 70.2778 18.426 68.1576 16.7917C63.9347 13.5318 57.6005 10.0245 50.1443 7.10034C42.6969 4.17617 35.6649 2.43579 30.3466 1.95874C27.6786 1.72021 25.5495 1.80858 24.0477 2.15312C22.484 2.51533 22.0334 3.0542 21.9274 3.3369C21.8214 3.61076 21.7772 4.30866 22.6783 5.64265C23.5441 6.92363 25.046 8.43431 27.1662 10.0687C31.3891 13.3285 37.7232 16.8358 45.1794 19.76C52.6268 22.6842 59.6589 24.4245 64.9772 24.9016C67.6452 25.1401 69.7743 25.0517 71.2761 24.7072ZM44.5345 21.412C59.6589 27.3487 73.3257 28.5855 75.0572 24.1683C76.7888 19.76 65.9313 11.3673 50.8069 5.43063C35.6825 -0.506053 22.0158 -1.74286 20.2842 2.67432C18.5439 7.0915 29.4012 15.4842 44.5345 21.412Z"
      />
      <Paths.L40
        evenOdd
        d="M64.5702 29.1878C62.7768 24.6205 74.0229 15.9363 89.6862 9.78756C105.35 3.63885 119.502 2.35788 121.296 6.93408C121.296 6.93408 121.296 6.94292 121.304 6.94292L141.941 59.525C141.941 59.5339 141.95 59.5515 141.95 59.5603C144.389 65.7797 133.673 75.8067 118.009 81.9554C102.346 88.1041 87.6632 88.0423 85.2249 81.8141L64.5702 29.1878Z"
      />
      <Paths.L50
        evenOdd
        d="M80.3314 62.4121L85.9766 76.8121H85.9854C85.9943 76.8651 86.0119 76.9181 86.0384 76.9711C87.2045 79.9483 99.7582 77.8104 114.079 72.1917C128.39 66.5731 139.053 59.6028 137.887 56.6256C137.87 56.5726 137.843 56.5284 137.817 56.4754H137.825L132.18 42.0754C131.014 39.0983 118.461 41.2361 104.14 46.8548C89.8196 52.4646 79.1653 59.4349 80.3314 62.4121Z"
      />
      <Paths.L60
        evenOdd
        d="M78.379 57.4486C75.923 48.5701 83.1761 49.4712 93.3974 50.7345C93.8921 50.7964 94.3957 50.8582 94.908 50.92C103.61 51.9801 131.261 45.2837 132.516 42.9249L137.339 55.2135H137.331C137.375 55.3019 137.419 55.3991 137.454 55.4963C139.628 61.0266 129.777 70.0641 115.457 75.6827C101.145 81.3014 87.7787 81.3721 85.6054 75.8417C85.5701 75.7446 85.5348 75.6474 85.5083 75.5502H85.4994L78.379 57.4486Z"
      />
      <g opacity="0.5">
        <Paths.L20
          opacity="0.5"
          evenOdd
          d="M79.2868 31.2367C71.3624 32.6237 65.5936 32.0229 64.4893 29.2136L85.144 81.8487C86.6458 85.674 92.768 87.167 100.904 86.3277L79.2868 31.2367Z"
        />
      </g>
      <Paths.L20
        evenOdd
        d="M121.225 6.97631C122.718 10.8811 114.741 17.7807 102.629 23.5319L96.3125 7.43571C109.105 3.41608 119.644 3.04503 121.208 6.93214L121.225 6.97631Z"
      />
      <Paths.L20
        evenOdd
        d="M68.4749 29.7337C66.8494 29.3539 66.39 28.7973 66.2752 28.5058C66.1603 28.2142 66.125 27.4987 67.0526 26.1117C67.9449 24.7865 69.4997 23.2228 71.6995 21.5267C76.0725 18.1519 82.6364 14.521 90.3488 11.4908C98.07 8.46065 105.35 6.65842 110.853 6.1637C113.619 5.91633 115.827 6.00466 117.382 6.36687C119.008 6.74675 119.467 7.30332 119.582 7.59486C119.697 7.88639 119.732 8.60199 118.804 9.98899C117.912 11.3141 116.357 12.8778 114.157 14.574C109.784 17.9487 103.221 21.5796 95.5082 24.6098C87.7869 27.64 80.5073 29.4422 75.0035 29.9369C72.2384 30.1843 70.0298 30.096 68.4749 29.7337ZM96.1796 26.3237C80.5163 32.4724 66.3636 33.7534 64.5702 29.1772C62.7768 24.6098 74.0229 15.9257 89.6862 9.77697C105.35 3.62826 119.502 2.34724 121.296 6.92344C123.089 11.4908 111.843 20.1838 96.1796 26.3237Z"
      />
    </Thumbnail>
  );
};

export default Cheers;
