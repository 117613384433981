import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
      fill={fill}
    />
    <path 
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.343 13.6783H13.6589L13.9993 6.35303C14.0119 6.1639 13.848 6 13.6589 6H10.3304C10.1413 6 9.97736 6.1639 10.0026 6.35303L10.343 13.6783ZM10.2305 16.6643C10.2305 17.4081 10.8357 18.0133 11.5795 18.0133H12.4369C13.1807 18.0133 13.7859 17.4081 13.7859 16.6643V16.3491C13.7859 15.6052 13.1807 15 12.4369 15H11.5795C10.8357 15 10.2305 15.6052 10.2305 16.3491V16.6643Z"
      fill="white"
    />
  </svg>
);

Icon.defaultProps = {
  fill: '#FF4369',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;