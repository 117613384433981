import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Page, Container, Form, Text } from '@matterapp/matter-ui';
import ResendCodeButtonContainer from '../../containers/ResendCodeButtonContainer';
import LogoAndWordMark from 'components/molecules/LogoAndWordMark';
import InvisibleSubmitButton from 'components/molecules/InvisibleSubmitButton';
import { FIELD_LABEL_AUTH_VERIFY_NUMBER } from '../../../../copy-consts';
import { VerificationCodeField } from '../../../../form-fields';

const SubmitVerificationCodePage = (props) => {
  const {
    handleSubmit,
    onCodeInputChange,
    submitting,
    currentPhoneNumber,
    component,
  } = props;
  if (component) { return component(props); }
  return (
    <React.Fragment>
      <Helmet>
        <title>Enter verification code</title>
      </Helmet>
      <Page layout="SingleColumnV2">
        <Container maxWidth={315} textAlign="center">
          <LogoAndWordMark />
          <Text
            textStyle={500}
            marginBottom={48}
            textAlign="center"
            color="blacks[50]"
            block
          >
            {FIELD_LABEL_AUTH_VERIFY_NUMBER(currentPhoneNumber)}
          </Text>
          <Form onSubmit={handleSubmit} centered>
            <VerificationCodeField
              autoFocus
              disabled={submitting}
              onChange={onCodeInputChange}
              fields={5}
            />
            <InvisibleSubmitButton />
          </Form>
          <ResendCodeButtonContainer />
        </Container>
      </Page>
    </React.Fragment>
  );
};

SubmitVerificationCodePage.propTypes = {
  currentPhoneNumber: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCodeInputChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  component: PropTypes.func,
};

SubmitVerificationCodePage.defaultProps = {
  component: null,
};

export default SubmitVerificationCodePage;
