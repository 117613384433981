import React from 'react';
import { Resources } from '@matterapp/routing';
import {
  PageNotFoundPage,
  AccountClosedPage,
} from '../pages/misc';
import TeamsInstallRedirectPage from 'routes/pages/misc/teamsInstallRedirect';
import TestAuthPage from 'routes/pages/misc/test_auth';
import { Route } from 'react-router-dom';

export const PageNotFound = (
  <Route path="/404" element={<PageNotFoundPage />} />
);

export const AccountClosed = (
  <Route 
    path={Resources.accountClosed.pattern} 
    element={<AccountClosedPage />}
  />
);

export const TestAuth = (
  <Route 
    path={Resources.testAuth.pattern} 
    element={<TestAuthPage />}
  />
);

export const TeamsInstallRedirect = (
  <Route 
    path={Resources.teamsInstallRedirect.pattern} 
    element={<TeamsInstallRedirectPage />}
  />
);

