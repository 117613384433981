import { compose, withProps, lifecycle } from 'recompose';
import WorkRelationshipGame from '../components/WorkRelationshipGame';
import {
  withPeerRecommendations,
  withSetWorkRelationshipWithPeer,
  withLock,
} from 'hocs';
import { mixpanel } from 'libs/tracking';

const handleTrack = {
  acceptPeer: mixpanel.createEventSender('workRelationshipRecommendations:acceptPeer'),
  rejectPeer: mixpanel.createEventSender('workRelationshipRecommendations:rejectPeer'),
  viewRequestFeedbackMode: mixpanel.createEventSender('workRelationshipRecommendations:viewRequestFeedbackMode'),
};

function componentDidUpdate(prevProps) {
  const {
    isPeerRecommendationsLoaded,
    peerRecommendations,
    handleLockIsWorkRelationshipPeerRecommendationsFirstPopulated,
  } = this.props;
  if (!isPeerRecommendationsLoaded) { return; }
  if (peerRecommendations.length !== prevProps.peerRecommendations.length) {
    handleLockIsWorkRelationshipPeerRecommendationsFirstPopulated();
  }
}

const withData = compose(
  withLock({
    handleAttemptLockName:
      'handleLockIsWorkRelationshipPeerRecommendationsFirstPopulated',
    lockPropName: 'isWorkRelationshipPeerRecommendationsFirstPopulated',
  }),
  withSetWorkRelationshipWithPeer,
  withPeerRecommendations({
    peerRecommendationFilterFn: (peer) => peer.isWorkRelationshipRecommendation,
  }),
  lifecycle({ componentDidUpdate }),
  withProps(
    ({
      setWorkRelationshipWithPeerMutation,
      handleRequestFeedbackFromPeerViaPeerRecommendations,
      handleRejectPeer,
      source,
    }) => ({
      handleWorkRelationshipPeerAccept: async ({ peer }) => {
        await setWorkRelationshipWithPeerMutation({
          variables: {
            isWorkingTogether: true,
            email: peer.email,
          },
        });
        handleTrack.acceptPeer({
          pickedPeer: peer,
        });
      },
      handleViewRequestFeedbackMode: ({ peer }) => {
        handleTrack.viewRequestFeedbackMode({
          peer,
          source,
        });
      },
      handleRequestFeedbackPeerAccept: async ({ peer }) => {
        await handleRequestFeedbackFromPeerViaPeerRecommendations({
          peer,
          source,
        });
      },
      handleRequestFeedbackPeerReject: async ({ peer }) => {
        await handleRejectPeer({
          peer,
          source,
        });
      },
      handleWorkRelationshipPeerReject: async ({ peer }) => {
        await setWorkRelationshipWithPeerMutation({
          variables: {
            isWorkingTogether: false,
            email: peer.email,
          },
        });
        handleTrack.rejectPeer({
          pickedPeer: peer,
        });
      },
    })
  )
);

export default withData(WorkRelationshipGame);
