import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ConfettiCannon } from '../../../components/Confetti';

const STEP_ADVANCE_TIMEOUT = 200;
const ZERO_STATE_PERCENTAGE = 11.699;
import ClassicStyle from './variants/Classic/styles';
import WelcomeStyle from './variants/Welcome/styles';

const styleVariantMap = {
  'classic': ClassicStyle,
  'welcome': WelcomeStyle,
};

class HelperMeter extends Component {
  constructor(props) {
    super(props);
    const { currentStep, totalSteps, variant } = props;
    this.state = {
      currentStep,
      initCompleted: currentStep >= totalSteps,
      styleVariant: this.getStyleVariant(variant)
    };
  }

  componentDidUpdate() {
    const { currentStep } = this.props;
    this.stepAdvanceTimeout = setTimeout(() => {
      this.setState({
        currentStep,
      });
    }, STEP_ADVANCE_TIMEOUT);
  }

  componentWillUnmount = () => {
    clearTimeout(this.stepAdvanceTimeout);
  };

  getPercentCompletion = () => {
    const { currentStep } = this.state;
    const { totalSteps } = this.props;
    return Math.ceil((100 * currentStep) / totalSteps);
  };

  renderStepCounter = () => {
    const { currentStep } = this.state;
    const { totalSteps, counterText } = this.props;
    const { styleVariant } = this.state;
    const StepCounter = styleVariant.StepCounter;
    const renderText = counterText ? counterText : `${currentStep}/${totalSteps}`;
    return <StepCounter>{renderText}</StepCounter>;
  };

  renderHelperText = ({ finalPercentage }) => {
    const { helperText } = this.props;
    const { styleVariant } = this.state;

    const HelperTextContainer = styleVariant.HelperTextContainer;

    return (
      <HelperTextContainer percentCompletion={finalPercentage}>
        {helperText}
      </HelperTextContainer>
    );
  };

  getStyleVariant() {
    const variant = this.props.variant;
    const styles = styleVariantMap[variant];
    if (!styles) {
      throw Error(`${this.props.variant} is not a valid variant for HelperMeter component`);
    }
    return styles;
  }

  render = () => {
    const { className } = this.props;
    const percentCompletion = this.getPercentCompletion();
    const translatedPercentage =
      ZERO_STATE_PERCENTAGE +
      ((100 - ZERO_STATE_PERCENTAGE) * percentCompletion) / 100;
    const pickedPercentage = Math.max(percentCompletion, translatedPercentage);
    const finalPercentage = Math.min(pickedPercentage, 100);
    const { styleVariant, initCompleted } = this.state;
    const Container = styleVariant.Container;
    const BarRoot = styleVariant.BarRoot;
    const Meter = styleVariant.Meter;
    const SmoothingContainer = styleVariant.SmoothingContainer;

    return (
      <SmoothingContainer>
        <Container className={className}>
          <ConfettiCannon active={finalPercentage === 100 && !initCompleted} zIndex={3} />
          <BarRoot>
            {this.renderHelperText({ finalPercentage })}
            {this.renderStepCounter()}
            <Meter percentCompletion={finalPercentage} />
          </BarRoot>
        </Container>
      </SmoothingContainer>
    );
  };
}

HelperMeter.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  helperText: PropTypes.string.isRequired,
  counterText: PropTypes.string,
};

HelperMeter.defaultProps = {
  variant: "classic"
};
export default HelperMeter;
