import React, { useState, useEffect } from 'react';
import UserContext from './UserContext';
import currentUserQuery from 'graphql-queries/queries/user/getCurrentUser';
import getCurrentMemberProperties from 'graphql-queries/queries/rewards/getCurrentMemberProperties';
import getCurrentUserNotifications from 'graphql-queries/queries/user/getCurrentUserNotifications';
import getWorkspace from 'graphql-queries/queries/workspace/getWorkspace';
import getTenantAdmins from 'graphql-queries/queries/tenant/getTenantAdmins';
import loginWithUserToken from 'graphql-queries/mutations/user/loginWithUserToken';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';
import { useWorkspaceLocalStorageAndMetadata } from 'hooks/workspaces';
import Toast from 'components/Toast/Toast';
import { bootIntercom } from 'libs/tracking/intercom';
import apolloClient from 'config/apolloClient';
import { mixpanel } from 'libs/tracking';
import { clearLocalStorage } from 'app-consts';
import { LOGOUT_MUTATION } from 'graphql-queries/queries';
import { gtagCall } from 'libs/tracking/googleTagManager';
import { updateIntercom } from 'libs/tracking/intercom';
import { useQueryParams } from 'hooks';
import saveAttributionParams from 'graphql-queries/mutations/user/saveAttributionParams';

export default function UserProvider({ children, history }) {
  const { queryParams } = useQueryParams();
  const [saveAttributionParamsMutation] = useMutation(saveAttributionParams);

  const [aborterRef, setAbortRef] = useState(new AbortController());
  const { loading: isLoadingCurrentUser, data, refetch: refetchCurrentUser } = useQuery(currentUserQuery, {
    context: {
      fetchOptions: {
        signal: aborterRef.signal
      }
    },
    notifyOnNetworkStatusChange: true
  });
  const currentUser = data?.currentUser;

  const { data: notificationData, refetch: refetchNotifications } = useQuery(getCurrentUserNotifications, {
    skip: !currentUser
  });
  const [getCurrentWorkspace, { loading: isLoadingCurrentWorkspace, data: workspaceData, error: workspaceError }] = useLazyQuery(getWorkspace);
  const [currentTenant, setCurrentTenant] = useState();
  const [currentWorkspace, setCurrentWorkspace] = useState();
  const [loginWithTokenMutation, { data: loginWithTokenData }] = useMutation(loginWithUserToken);
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { data: tenantAdminData } = useQuery(getTenantAdmins, {
    variables: { 
      tenantId: currentTenant?.id
    },
    skip: !currentTenant
  });
  const { data: memberPropertiesData, refetch: refetchMemberCoins, loading: loadingMemberProperties } = useQuery(getCurrentMemberProperties, {
    variables: {
      tenantId: currentTenant?.id
    },
    skip: !currentTenant || !currentUser,
    fetchPolicy: 'network-only'
  });
  const [tenants, setTenants] = useState([]);
  const { getWorkspaceId, setWorkspaceId } = useWorkspaceLocalStorageAndMetadata();
  const [notificationCount, setNotificationCount] = useState(0);
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState();
  const [isTenantZero, setTenantZero] = useState(false);
  const [params, setParams] = useState();
  const navigate = useNavigate();

  async function handleAuthTokenLogin(token) {
    setIsAuthenticating(true);

    await loginWithTokenMutation({
      variables: {
        token
      }
    });
  }

  async function completeAuth() {
    await refetchCurrentUser();
    setIsAuthenticating(false);
  }

  useEffect(() => {
    if (params?.tenantId) {
      return;
    }

    let { workspaceId } = params || {};

    if (queryParams?.workspaceId) {
      workspaceId = queryParams.workspaceId;
    } 

    if (!workspaceId) {
      workspaceId = getWorkspaceId();
    }

    if (workspaceId) {
      setCurrentWorkspaceId(workspaceId);
    }
  }, [params, tenants, queryParams]);

  useEffect(() => {
    if (currentUser && workspaceError?.message === 'You are not authorized to access this resource') {
      const workspace = currentTenant?.workspaces[0];
      
      if (workspace) {
        Toast.error('You do not have permission to view this channel');
        navigate(Resources.workspacesActivityFeed.path({ workspaceId: workspace.id }));
      }
    }
  }, [workspaceError, currentTenant]);

  useEffect(() => {
    if (loginWithTokenData?.login) {
      const { login: { user } } = loginWithTokenData;

      if (user) {
        completeAuth();
      }
    }
  }, [loginWithTokenData]);

  useEffect(() => {
    if (data?.currentUser) {
      const { currentUser } = data;
      const { tenants } = currentUser;

      Sentry.configureScope(scope => {
        scope.setUser(currentUser);
      });

      gtagCall('config', 'AW-801897506', {
        'user_id': currentUser.id
      });

      updateIntercom(currentUser);

      setTenants(tenants || []);
    }
  }, [data]);

  useEffect(() => {
    if (notificationData) {
      const { notifications, feedbackReceivedFeedItems: unreadFeedback, pendingInvitations } = notificationData;
      const unreadNotificationsCount = notifications.filter(
        (notification) => !notification.isViewed
      ).length;
  
    
      setNotificationCount(unreadNotificationsCount + unreadFeedback.length + pendingInvitations.length);
    }
  }, [notificationData]);

  useEffect(() => {
    if (workspaceData) {
      const { workspace } = workspaceData;

      setCurrentWorkspace(workspace);

      const attributionParams = localStorage.getItem(
        'matters-teams-attribution-params'
      );

      if (attributionParams) {
        localStorage.removeItem('matters-teams-attribution-params');

        saveAttributionParamsMutation({
          variables: {
            workspaceId: workspace.id,
            source: 'teams', // we only save teams params to local storage
            params: attributionParams
          }
        });
      
      }
    }
  }, [workspaceData]);

  useEffect(() => {
    if (currentWorkspaceId && currentUser) {
      getCurrentWorkspace({
        variables: {
          workspaceId: currentWorkspaceId
        }
      });
    }
  }, [currentWorkspaceId, currentUser]);

  useEffect(() => {
    let tenant;

    if (params?.tenantId) {
      setCurrentTenant(tenants.find(t => t.id === params.tenantId));
    } else if (tenants.length && currentWorkspaceId) {
      tenant = tenants.find(t => t.workspaces.find(w => w.id === currentWorkspaceId)) || tenants[0];

      setCurrentTenant(tenant);
      setTenantZero(false);
    } else if (tenants.length) {
      tenant = tenants[0];

      setCurrentTenant(tenant);
      setCurrentWorkspaceId(tenant.workspaces[0]?.id);
      setTenantZero(false);
    }
    
    if (tenant && !tenant.workspaces.length) {
      navigate(Resources.tenantZero.path({ tenantId: tenant.id }));
    } 
  }, [currentWorkspaceId, tenants, params]);

  return (
    <UserContext.Provider
      value={{
        history,
        currentUser,
        tenantAdmins: tenantAdminData?.tenantAdmins || [],
        notificationCount,
        isTenantZero,
        setTenantZero,
        isLoadingCurrentWorkspace,
        isAdminMember: currentTenant?.isAdmin || currentTenant?.isOwner,
        isChannelAdmin: currentWorkspace?.isChannelAdmin,
        currentTenant,
        currentTenantWorkspaceIds: currentTenant?.workspaces.map(w => parseInt(w.id)),
        isEnterprise: currentTenant?.isEnterprise,
        isLoadingCurrentUser,
        isAuthenticating,
        setIsAuthenticating,
        tenants,
        currentWorkspace,
        currentWorkspaceId,
        setParams,
        signOut: async function signOut() {
          await apolloClient.clearStore();
          await logoutMutation();
          aborterRef.abort();
          setAbortRef(new AbortController());
          clearLocalStorage();
          bootIntercom(); // bootIntercom also clears intercom.
          mixpanel.reset();
          setWorkspaceId(null);
        },
        refetchNotifications,
        handleAuthTokenLogin,
        refetchMemberCoins,
        memberProperties: memberPropertiesData?.memberProperties,
        loadingMemberProperties,
        updateTenant: (id) => {
          const tenant = tenants.find(t => t.id === id);
          const workspace = tenant?.workspaces[0];

          if (!workspace) {
            navigate(Resources.tenantZero.path({ tenantId: tenant.id }));
          } else {
            navigate(
              Resources.workspacesActivityFeed.path({
                workspaceId: workspace.id,
              })
            );
          }
        },
        updateWorkspace: (id) => {
          const workspace = currentTenant?.workspaces.find(w => w.id === id);

          navigate(
            Resources.workspacesActivityFeed.path({
              workspaceId: workspace.id,
            })
          );
        },
        refetchCurrentUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
