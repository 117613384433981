import React from 'react';
import PropTypes from 'prop-types';
import SVG, { Path } from '../../SVG';

const DEFAULT_SIZE = 24;

const Icon = ({ size }) => (
  <SVG size={size} viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}>
    <Path
      d="M9.78022 13.3483C11.3622 13.3483 12.6447 12.0658 12.6447 10.4838C12.6447 8.90182 11.3622 7.61935 9.78022 7.61935C8.19822 7.61935 6.91575 8.90182 6.91575 10.4838C6.91575 12.0658 8.19822 13.3483 9.78022 13.3483Z"
    />
    <Path
      d="M9.78022 13.3483C11.3622 13.3483 12.6447 12.0658 12.6447 10.4838C12.6447 8.90182 11.3622 7.61935 9.78022 7.61935C8.19822 7.61935 6.91575 8.90182 6.91575 10.4838C6.91575 12.0658 8.19822 13.3483 9.78022 13.3483Z"
    />
    <Path
      d="M8.4884 14.3593C6.68926 14.3593 5.23077 15.7675 5.23077 17.5046V18.2909C5.23077 18.7252 5.59539 19.0772 6.04518 19.0772H14.1893C14.639 19.0772 15.0037 18.7252 15.0037 18.2909V17.5046C15.0037 15.7675 13.5452 14.3593 11.746 14.3593H8.4884Z"
    />
    <Path
      d="M8.4884 14.3593C6.68926 14.3593 5.23077 15.7675 5.23077 17.5046V18.2909C5.23077 18.7252 5.59539 19.0772 6.04518 19.0772H14.1893C14.639 19.0772 15.0037 18.7252 15.0037 18.2909V17.5046C15.0037 15.7675 13.5452 14.3593 11.746 14.3593H8.4884Z"
    />
    <Path
      d="M17.1144 7.78824C17.1144 9.23217 15.9438 10.4027 14.4999 10.4027C14.4133 10.4027 14.3277 10.3985 14.2434 10.3903C14.2111 8.82509 13.3728 7.45821 12.1277 6.68736C12.5434 5.7932 13.4496 5.17377 14.4999 5.17377C15.9438 5.17377 17.1144 6.3443 17.1144 7.78824Z"
    />
    <Path
      d="M18.5719 16.1316H16.398C15.955 14.6928 14.8359 13.5637 13.4296 13.0554C13.6747 12.7083 13.8716 12.3244 14.0104 11.9137H16.1287C17.7981 11.9137 19.1364 13.2182 19.1364 14.809V15.5953C19.1364 15.8833 18.892 16.1316 18.5719 16.1316Z"
    />
  </SVG>
);

Icon.defaultProps = {
  size: DEFAULT_SIZE,
};

Icon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default Icon;
