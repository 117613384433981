import React, { useState, useContext } from 'react';
import { 
  Box,
  Typography,
  TableRow
} from '@mui/material';
import { Confirm } from '@matterapp/matter-ui';
import styled from 'styled-components';
import UserContext from 'context/UserContext/UserContext';
import DarkMenu from 'components/Menu/Dark/Menu';
import getTenantPulseQuestions from 'graphql-queries/queries/pulse/getTenantPulseQuestions';
import Toast from 'components/Toast/Toast';
import { useMutation } from '@apollo/client';
import deletePulseQuestion from 'graphql-queries/mutations/pulse/deletePulseQuestion';
import CreateQuestionModal from './CreateQuestionModal';
import previewQuestionQuery from 'graphql-queries/queries/pulse/previewQuestion';
import { useLazyQuery } from '@apollo/client';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';
import { StyledTableCell } from './styles';

const TableCell = styled(StyledTableCell)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0`,
  marginBottom: theme.spacing(2),
  '&:first-of-type': {
    width: '55.5%',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  }
}));

const StyledConfirm = styled(Confirm)(({
  zIndex: 101
}));

export default function QuestionDetails({ 
  question,
  allQuestions,
  category,
  onAddSubcategory
}) {
  const { currentTenant, currentWorkspace } = useContext(UserContext);
  const { id: tenantId, pulseSettings } = currentTenant || {};
  const { id: workspaceId } = currentWorkspace || {};
  const { isPulseUnlocked = false } = pulseSettings || {};
  const { subcategory, isEnabled } = question;
  const { name: subcategoryName  = '' } = subcategory || {};
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteQuestion] = useMutation(deletePulseQuestion);
  const [editingQuestion, setEditingQuestion] = useState(false);
  const [previewQuestion] = useLazyQuery(previewQuestionQuery);
  const navigate = useNavigate();

  const menuMap = [
    {
      menuLabel: 'Edit',
      rcLabel: 'edit-question-menu',
      handleAction: () => {
        setEditingQuestion(true);
      },
    },
    {
      menuLabel: 'Preview',
      rcLabel: 'preview-question-menu',
      handleAction: async () => {
        await previewQuestion({
          variables: {
            question: question.question,
            tenantId
          }
        });

        Toast.success('Preview sent');
      },
    },
    {
      menuLabel: 'View Results',
      rcLabel: 'view-results-question-menu',
      handleAction: () => {
        if (isPulseUnlocked) {
          navigate(
            Resources.pulseCategoryDetails.path({ workspaceId, categoryId: category.id }, { questionId: question.id })
          );
        } else {
          Toast.error('You can access your Pulse Survey results once 3 members have completed the survey.');
        }
      },
    },
    {
      menuLabel: 'Delete',
      rcLabel: 'delete-question-menu',
      handleAction: () => {
        setIsDeleteModalOpen(true);
      },
    }
  ];

  return (
    <TableRow sx={{ width: '100%' }}>
      <CreateQuestionModal 
        isOpen={editingQuestion}
        isEdit
        onClose={() => setEditingQuestion(false)}
        onAddSubcategory={onAddSubcategory}
        tenantId={tenantId}
        category={category}
        question={question}
      />
      <StyledConfirm
        header='Delete Question'
        subHeader={<>
          You are removing the Question: <b>{question.question}</b>, and all associated data. This cannot be undone.
        </>}
        isOpen={isDeleteModalOpen}
        confirmButtonLabel='Yes, Delete'
        confirmButtonIsDestructive
        cancelButtonLabel='Cancel'
        onClickCancel={() => setIsDeleteModalOpen(false)}
        onClickConfirm={() => {
          if (allQuestions.length <= 3) {
            Toast.error('You must have at least 3 questions in your Pulse Survey');
            setIsDeleteModalOpen(false);
            return;
          }
          
          deleteQuestion({
            variables: {
              questionId: question.id,
              tenantId
            },
            refetchQueries: [
              {
                query: getTenantPulseQuestions,
                variables: { tenantId }
              }
            ]
          }).then(() => {
            Toast.success('Question deleted');
            setIsDeleteModalOpen(false);
          });
        }}
      />
      <TableCell visibleOnMobile>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography variant='body1' sx={{ width: '100%', textOverflow: 'ellipsis' }}>
            <b>{question.question}</b>
          </Typography>
          <Typography variant='body2'>
            {subcategoryName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ width: '36.8%' }}>
        {isEnabled ? 'Enabled' : 'Disabled'}
      </TableCell>
      <TableCell visibleOnMobile>
        <DarkMenu menuMap={menuMap} />
      </TableCell>
    </TableRow>
  );
}
