import React from 'react';
import PropTypes from 'prop-types';

import theme from '@matterapp/matter-theme';

const SolidPath = ({ fill }) => (
  <path
    d="M1.75 8C1.75 5.1005 4.10051 2.75 7 2.75H17C19.8995 2.75 22.25 5.10051 22.25 8V12C22.25 14.8995 19.8995 17.25 17 17.25H8.64093C8.06777 17.25 7.50893 17.4291 7.04253 17.7622L2.14531 21.2602C1.97984 21.3784 1.75 21.2602 1.75 21.0568V8Z"
    fill={fill}
    stroke={fill}
    strokeWidth="1.5"
  />
);

SolidPath.propTypes = {
  fill: PropTypes.string.isRequired,
};

const OutlinePath = ({ fill }) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17 3.5H7C4.51472 3.5 2.5 5.51472 2.5 8V20.0852L6.6066 17.1519C7.2002 16.7279 7.91145 16.5 8.64093 16.5H17C19.4853 16.5 21.5 14.4853 21.5 12V8C21.5 5.51472 19.4853 3.5 17 3.5ZM7 2C3.68629 2 1 4.68629 1 8V21.0568C1 21.8702 1.91937 22.3433 2.58124 21.8705L7.47845 18.3725C7.81766 18.1302 8.22408 18 8.64093 18H17C20.3137 18 23 15.3137 23 12V8C23 4.68629 20.3137 2 17 2H7Z"
    fill={fill}
  />
);

OutlinePath.propTypes = {
  fill: PropTypes.string.isRequired,
};

const Icon = ({ fill, outline }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    {outline ? <OutlinePath fill={fill} /> : <SolidPath fill={fill} />}
  </svg>
);

Icon.defaultProps = {
  fill: theme.colors.blues[50],
  outline: false,
};

Icon.propTypes = {
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default Icon;
