import React from 'react';

const Icon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.2002 12.0002C2.2002 6.59447 6.59909 2.2002 12.0002 2.2002C17.4019 2.2002 21.7957 6.59493 21.8002 11.9995C21.8002 17.405 17.4057 21.8002 12.0002 21.8002C6.59471 21.8002 2.2002 17.4057 2.2002 12.0002ZM12.0002 3.8002C7.48226 3.8002 3.8002 7.4786 3.8002 12.0002C3.8002 16.522 7.47836 20.2002 12.0002 20.2002C16.5218 20.2002 20.1998 16.5224 20.2002 12.0009C20.1964 7.47814 16.5175 3.8002 12.0002 3.8002Z" fill="#343799"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.2002 12.0002C2.2002 6.59447 6.59909 2.2002 12.0002 2.2002C17.4019 2.2002 21.7957 6.59493 21.8002 11.9995C21.8002 17.405 17.4057 21.8002 12.0002 21.8002C6.59471 21.8002 2.2002 17.4057 2.2002 12.0002ZM12.0002 3.8002C7.48226 3.8002 3.8002 7.4786 3.8002 12.0002C3.8002 16.522 7.47836 20.2002 12.0002 20.2002C16.5218 20.2002 20.1998 16.5224 20.2002 12.0009C20.1964 7.47814 16.5175 3.8002 12.0002 3.8002Z" fill="#6B6B6F"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.1002 7.05045C11.1002 6.55339 11.5031 6.15045 12.0002 6.15045C12.4973 6.15045 12.9002 6.55339 12.9002 7.05045V11.1002H16.9499C17.447 11.1002 17.8499 11.5031 17.8499 12.0002C17.8499 12.4973 17.447 12.9002 16.9499 12.9002H12.9002V16.9499C12.9002 17.447 12.4972 17.8499 12.0002 17.8499C11.5031 17.8499 11.1002 17.447 11.1002 16.9499V12.9002H7.05045C6.55339 12.9002 6.15045 12.4973 6.15045 12.0002C6.15045 11.5031 6.55339 11.1002 7.05045 11.1002H11.1002V7.05045Z" fill="#343799"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.1002 7.05045C11.1002 6.55339 11.5031 6.15045 12.0002 6.15045C12.4973 6.15045 12.9002 6.55339 12.9002 7.05045V11.1002H16.9499C17.447 11.1002 17.8499 11.5031 17.8499 12.0002C17.8499 12.4973 17.447 12.9002 16.9499 12.9002H12.9002V16.9499C12.9002 17.447 12.4972 17.8499 12.0002 17.8499C11.5031 17.8499 11.1002 17.447 11.1002 16.9499V12.9002H7.05045C6.55339 12.9002 6.15045 12.4973 6.15045 12.0002C6.15045 11.5031 6.55339 11.1002 7.05045 11.1002H11.1002V7.05045Z" fill="#6B6B6F"/>
  </svg>

);

export default Icon;
