import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Buttons from '../Buttons';
import theme from '@matterapp/matter-theme';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: solid 1px ${() => theme.colors.blacks[10]};
  ${({ showBorder }) => !showBorder && `border-bottom: none;`} // Don't know why but 'showBorder && solid 1px ${() => theme.colors.blacks[10]}' doesn't work.
`;

const LeftContainer = styled.div`
  display: flex;
  position: absolute;
  left: 24px;
`;

const RightContainer = styled.div`
  display: flex;
  position: absolute;
  right: 24px;
`;

const TitleBox = styled.div`
  flex: 1;
  color: ${() => theme.colors.purples[60]};
  font-weight: bold;
  margin-left: 36px;
  margin-right: 36px;
  font-size: ${({ shrinkOnMobile }) =>
    theme.sizes.fontSizes[shrinkOnMobile ? 50 : 55]};

  ${LeftContainer}:empty + & {
    ${({ centerOnMobile }) => {
    if (!centerOnMobile) {
      return `margin-left: 0;`;
    }
  }}
  }

  ${({ centerOnMobile }) => {
    if (centerOnMobile) {
      return `text-align: center;`;
    }
  }}

  ${() => theme.media.medium`
    text-align: center;
    font-size: ${theme.sizes.fontSizes[55]};
    line-height: ${theme.sizes.lineHeights[60]};

    &, ${LeftContainer}:empty + & {
      margin-left: 36px;
    }
  `}
`;

const Header = ({
  centerOnMobile,
  className,
  left,
  title,
  right,
  shrinkOnMobile,
  showBorder
}) => (
  <HeaderContainer className={className} showBorder={showBorder}>
    <LeftContainer>{left}</LeftContainer>
    <TitleBox centerOnMobile={centerOnMobile} shrinkOnMobile={shrinkOnMobile}>{title}</TitleBox>
    <RightContainer>{right}</RightContainer>
  </HeaderContainer>
);

Header.Buttons = Buttons;

Header.defaultProps = {
  centerOnMobile: false,
  left: null,
  right: null,
  shrinkOnMobile: false,
  showBorder: true,
};

Header.propTypes = {
  centerOnMobile: PropTypes.bool,
  className: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  title: PropTypes.string.isRequired,
  /** Scale down title on mobile. */
  shrinkOnMobile: PropTypes.bool,
};

export default Header;
