import React from 'react';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';

const Icon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M 6 0C 5.44772 0 5 0.447708 5 1L 5 3L 3.5 3C 1.56702 3 0 4.567 0 6.5C 0 8.433 1.56702 10 3.5 10L 5.45963 10C 6.35159 12.3321 8.45499 14.0651 11 14.4291L 11 16C 9.34314 16 8 17.3431 8 19C 8 19.5523 8.44772 20 9 20L 15 20C 15.5523 20 16 19.5523 16 19C 16 17.3431 14.6569 16 13 16L 13 14.4291C 15.545 14.0651 17.6484 12.3321 18.5404 10L 20.5 10C 22.433 10 24 8.433 24 6.5C 24 4.567 22.433 3 20.5 3L 19 3L 19 1C 19 0.447708 18.5523 0 18 0L 6 0ZM 5 5L 3.5 5C 2.67157 5 2 5.67157 2 6.5C 2 7.32843 2.67157 8 3.5 8L 5.01758 8C 5.00592 7.83485 5 7.66812 5 7.5L 5 5ZM 18.9824 8C 18.9941 7.83485 19 7.66812 19 7.5L 19 5L 20.5 5C 21.3284 5 22 5.67157 22 6.5C 22 7.32843 21.3284 8 20.5 8L 18.9824 8Z" transform="translate(0 2)" fill={fill} />
  </svg>
);

Icon.defaultProps = {
  fill: theme.colors.yellows[50],
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
