import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Deprecated. Use new Form.Group component.
 */

const StyledFormGroup = styled.div`
  margin-bottom: ${(props) => props.marginBottom}px;
`;

const FormGroup = ({
  children,
  marginBottom,
}) => (
  <StyledFormGroup
    marginBottom={marginBottom}
  >
    {children}
  </StyledFormGroup>
);

FormGroup.defaultProps = {
  marginBottom: 32,
};

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  marginBottom: PropTypes.number,
};

export default FormGroup;
