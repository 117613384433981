import { parsePhoneNumber } from 'libphonenumber-js';
import { AUTH_RESET_SIGNIN_DATA } from './auth-actions';

const AUTH_SET_CURRENT_PHONE_NUMBER = '@auth/set-current-phone-number';

const reducer = (state = '', action) => {
  switch (action.type) {
  case AUTH_SET_CURRENT_PHONE_NUMBER: {
    const parsedNumber = parsePhoneNumber(action.phoneNumber, 'US');
    const { country } = parsedNumber;
    if (country === 'US') { return parsedNumber.formatNational(); }
    return parsedNumber.formatInternational();
  }
  case AUTH_RESET_SIGNIN_DATA:
    return '';
  default:
    return state;
  }
};

export default reducer;

export const setCurrentPhoneNumber = (phoneNumber) => ({
  phoneNumber,
  type: AUTH_SET_CURRENT_PHONE_NUMBER,
});
