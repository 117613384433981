const TAB_VALUES = {
  DELETE: 'delete',
  GENERAL: 'general',
  KUDOS: 'kudos',
  MEMBERS: 'members',
  INVITATIONS: 'invitations',
  FEEDBACK_RITUAL: 'feedback-ritual',
  REWARDS: 'rewards',
  CELEBRATIONS: 'celebrations',
  BILLING: 'billing',
  PULSE: 'pulse',
  HRIS: 'hris',
  UPGRADE_TO_PRO: 'upgrade-to-pro',
};

const URL_PARAMS = {
  PANEL: 'section',
  SETTINGS: 'settings',
  TOKEN: 'token',
};

const URL_PARAM_VALUES = {
  PANEL: {
    ...TAB_VALUES,
  },
  SETTINGS: {
    OPEN: 'show',
  },
};

const USER_STATUS = {
  ACCEPTED: 'accepted',
  IGNORED: 'ignored',
  PENDING: 'pending',
  REMOVED: 'removed',
};

export const WORKSPACES = {
  TAB_VALUES,
  URL_PARAMS,
  URL_PARAM_VALUES,
  USER_STATUS,
};

export const clearLocalStorage = () => {
  window.localStorage.removeItem('loginType');
  window.localStorage.removeItem('inviteeOnboardingFlowId');
};